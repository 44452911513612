import "core-js/modules/es.array.concat";
var zealsAdmin = {
  collection: function collection(_ref) {
    var customFormId = _ref.customFormId,
        headlessConfigId = _ref.headlessConfigId;
    return "/zeals_admin/api/custom_forms/".concat(customFormId, "/headless_configs/").concat(headlessConfigId, "/headless_pages");
  },
  member: function member(_ref2) {
    var customFormId = _ref2.customFormId,
        headlessConfigId = _ref2.headlessConfigId,
        headlessPageId = _ref2.headlessPageId;
    return "".concat(zealsAdmin.collection({
      customFormId: customFormId,
      headlessConfigId: headlessConfigId
    }), "/").concat(headlessPageId);
  },
  updateHeadlessStepRanks: function updateHeadlessStepRanks(_ref3) {
    var customFormId = _ref3.customFormId,
        headlessConfigId = _ref3.headlessConfigId,
        headlessPageId = _ref3.headlessPageId;
    return "".concat(zealsAdmin.member({
      customFormId: customFormId,
      headlessConfigId: headlessConfigId,
      headlessPageId: headlessPageId
    }), "/update_headless_step_ranks");
  }
};
export default {
  zealsAdmin: zealsAdmin
};