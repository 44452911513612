var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
/**
 * This atoms will reset CSS for prevent
 * from blurry text when use overflow: auto (chrome issue only)
 *
 * It will wrap Modal component when it need setting scroll auto
 */
var AutoScrollModalContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  position: absolute;\n  width: 100%;\n  height: 100%;\n  top: 0px;\n  left: 0px;\n\n  > div[class^='Container-sc-'] {\n    overflow: auto;\n\n    // remove transform setting, reason of blurry text issue\n    transform: translateZ(0);\n    top: unset;\n    left: unset;\n  }\n"], ["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  position: absolute;\n  width: 100%;\n  height: 100%;\n  top: 0px;\n  left: 0px;\n\n  > div[class^='Container-sc-'] {\n    overflow: auto;\n\n    // remove transform setting, reason of blurry text issue\n    transform: translateZ(0);\n    top: unset;\n    left: unset;\n  }\n"])));
export default AutoScrollModalContainer;
var templateObject_1;
