import uniqBy from 'lodash/uniqBy';
import changeItem from './changeItem';
/**
 * This method will find all postback action connect to other scenario
 * and build connectedOtherScenarios field
 *
 * @params scenario - is scenario get from server
 *
 */
export default function convertToScenarioData(scenario) {
    var connectedOtherScenarios = [];
    changeItem(scenario, 'postback_action', function (postbackAction) {
        if (postbackAction === null || postbackAction === void 0 ? void 0 : postbackAction.scenario_id) {
            connectedOtherScenarios = connectedOtherScenarios.concat([
                {
                    id: postbackAction.scenario_id,
                    name: postbackAction.scenario_name,
                },
            ]);
        }
        return postbackAction;
    });
    return {
        mainScenario: scenario,
        connectedOtherScenarios: uniqBy(connectedOtherScenarios, 'id'),
    };
}
