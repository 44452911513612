import React, { memo } from 'react';
import LayerNode from './LayerNode';
var ScenarioNode = function (_a) {
    var _b = _a.data, scenario = _b.scenario, updateBoundaryNode = _b.updateBoundaryNode;
    return (React.createElement("div", { key: "scenario-" + scenario.id, ref: function (ref) {
            if (!ref || !updateBoundaryNode)
                return;
            updateBoundaryNode('scenarios', scenario.id, ref.getBoundingClientRect());
        }, "data-testid": "ScenarioNode_Container" },
        React.createElement("div", { className: "node-scenario-name" }, scenario === null || scenario === void 0 ? void 0 : scenario.name),
        updateBoundaryNode && (React.createElement("div", null, ((scenario === null || scenario === void 0 ? void 0 : scenario.layers) || []).map(function (layer) { return (React.createElement(LayerNode, { key: "layer-" + layer.id, data: { layer: layer, updateBoundaryNode: updateBoundaryNode } })); })))));
};
export default memo(ScenarioNode);
