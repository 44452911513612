/* eslint-disable camelcase */
import { generatePath } from 'react-router-dom';
import { chatTypes } from '@src/lib/chat-types';
export function getUsage(params) {
    var usage = params ? 'Path' : 'Link';
    return usage;
}
export var transpiler = function (_a) {
    var path = _a.path, params = _a.params;
    var usage = params ? 'Link' : 'Path';
    if (usage === 'Link') {
        return generatePath(path, params);
    }
    return path;
};
/**
 * /dashboard/ad/chatbots/:chatbotId/talk/end_users/:endUserId
 */
export var dashboardAdChatbots_chatbotId_TalkEnd_Users_endUserId_ = function (params) {
    var path = "/dashboard/ad/chatbots/:chatbotId/talk/end_users/:endUserId";
    return transpiler({ path: path, params: params });
};
/**
 * /dashboard/ad/chatbots/:chatbotId/talk/end_users
 */
export var dashboardAdChatbots_chatbotId_TalkEnd_Users = function (params) {
    var path = "/dashboard/ad/chatbots/:chatbotId/talk/end_users";
    return transpiler({ path: path, params: params });
};
/**
 * /dashboard/ad/chatbots/:chatbotId/talk
 */
export var dashboardAdChatbots_chatbotId_Talk = function (params) {
    var path = "/dashboard/ad/chatbots/:chatbotId/talk";
    return transpiler({ params: params, path: path });
};
/**
 * /dashboard/ad/chatbots/:chatbotId/field-attributes
 */
export var dashboardAdChatbots_chatbotId_FieldAttributes = function (params) {
    var path = "/dashboard/ad/chatbots/:chatbotId/field-attributes";
    return transpiler({ params: params, path: path });
};
/**
 * /dashboard/ad/chatbots/:chatbotId/User-segment
 */
export var dashboardAdChatbots_chatbotId_UserSegment = function (params) {
    var path = "/dashboard/ad/chatbots/:chatbotId/usersegment";
    return transpiler({ params: params, path: path });
};
/**
 * /dashboard/ad/chatbots/:chatbotId/user-labels
 */
export var dashboardAdChatbots_chatbotId_UserLabel = function (params) {
    var path = "/dashboard/ad/chatbots/:chatbotId/user-labels";
    return transpiler({ params: params, path: path });
};
/**
 * /dashboard/ad/chatbots/:chatbotId/userdb/:page?
 */
export var dashboardAdChatbots_chatbotId_Userdb_page__ = function (params) {
    var path = "/dashboard/ad/chatbots/:chatbotId/userdb/:page?";
    return transpiler({ params: params, path: path });
};
/**
 * /dashboard/ad/chatbots/:chatbotId/chatlinks/:chatlinkId
 */
export var dashboardAdChatbots_chatbotId_Chatlinks_chatlinkId_ = function (params) {
    var path = "/dashboard/ad/chatbots/:chatbotId/chatlinks/:chatlinkId";
    return transpiler({ params: params, path: path });
};
/**
 * /dashboard/ad/chatbots/:chatbotId/chatlinks/new
 */
export var dashboardAdChatbots_chatbotId_ChatlinksNew = function (params) {
    var path = "/dashboard/ad/chatbots/:chatbotId/chatlinks/new";
    return transpiler({ params: params, path: path });
};
/**
 * /dashboard/ad/chatbots/:chatbotId/chatlinks
 */
export var dashboardAdChatbots_chatbotId_Chatlinks = function (params) {
    var path = '/dashboard/ad/chatbots/:chatbotId/chatlinks';
    return transpiler({ params: params, path: path });
};
/**
 * /dashboard/ad/chatbots/:chatbotId/settings/privacy-policies
 */
export var dashboardAdChatbots_chatbotId_SettingsPrivacPolicies = function (params) {
    var path = '/dashboard/ad/chatbots/:chatbotId/settings/privacy-policies';
    return transpiler({ params: params, path: path });
};
/**
 * /dashboard/ad/chatbots/:chatbotId/settings/plugin
 */
export var dashboardAdChatbots_chatbotId_SettingsPlugin = function (params) {
    var path = '/dashboard/ad/chatbots/:chatbotId/settings/plugin';
    return transpiler({ params: params, path: path });
};
/**
 * /dashboard/ad/chatbots/:chatbotId/settings/aggregate
 */
export var dashboardAdChatbots_chatbotId_SettingsAggregate = function (params) {
    var path = '/dashboard/ad/chatbots/:chatbotId/settings/aggregate';
    return transpiler({ params: params, path: path });
};
/**
 * /dashboard/ad/chatbots/:chatbotId/settings/webchat_setting
 */
export var dashboardAdChatbots_chatbotId_SettingsWebchatSetting = function (params) {
    var path = '/dashboard/ad/chatbots/:chatbotId/settings/webchat_setting';
    return transpiler({ params: params, path: path });
};
/**
 * /dashboard/ad/chatbots/:chatbotId/settings
 */
export var dashboardAdChatbots_chatbotId_Settings = function (params) {
    var path = '/dashboard/ad/chatbots/:chatbotId/settings';
    return transpiler({ params: params, path: path });
};
export var dashboardAdChatRetention = "/dashboard/ad/chatbots/:chatbotId/" + chatTypes.RETENTION;
/**
 * /dashboard/ad/chatbots/:chatbotId/settings/default_rich_menu
 */
export var dashboardAdChatbots_chatbotId_SettingsDefaultRichMenu = function (params) {
    var path = '/dashboard/ad/chatbots/:chatbotId/settings/default_rich_menu';
    return transpiler({ params: params, path: path });
};
/**
 * /dashboard/ad/chatbots/:chatbotId/retention/target/:targetId/campaign/:campaignId/content/:contentId?
 */
export var dashboardAdChatbots_chatbotId_RetentionTarget_targetId_Campaign_campaignId_Content_contentId__ = function (params) {
    var path = dashboardAdChatRetention + "/target/:targetId/campaign/:campaignId/content/:contentId?";
    return transpiler({ params: params, path: path });
};
/**
 * /dashboard/ad/chatbots/:chatbotId/retention/target/:targetId/campaign/:campaignId?
 */
export var dashboardAdChatbots_chatbotId_RetentionTarget_targetId_Campaign_campaignId__ = function (params) {
    var path = dashboardAdChatRetention + "/target/:targetId/campaign/:campaignId?";
    return transpiler({ params: params, path: path });
};
/**
 * /dashboard/ad/chatbots/:chatbotId/retention/target/:targetId?
 */
export var dashboardAdChatbots_chatbotId_RetentionTarget_targetId__ = function (params) {
    var path = dashboardAdChatRetention + "/target/:targetId?";
    return transpiler({ params: params, path: path });
};
/**
 * /dashboard/ad/chatbots/:chatbotId/:chatTypeParam/chatbots/:chatbotId?/list
 */
export var dashboardAdChatbots_chatbotId__chatTypeParam_Chatbots_chatbotId__List = function (params) {
    var path = '/dashboard/ad/chatbots/:chatbotId/:chatTypeParam/chatbots/:chatbotId?/list';
    return transpiler({ params: params, path: path });
};
/**
 * /dashboard/ad/chatbots/:chatbotId/tracks/new
 */
export var dashboardAdChatbots_chatbotId_TracksNew = function (params) {
    var path = '/dashboard/ad/chatbots/:chatbotId/tracks/new';
    return transpiler({ params: params, path: path });
};
/**
 * /dashboard/ad/chatbots/:chatbotId/tracks/targets/:targetId?
 */
export var dashboardAdChatbots_chatbotId_TracksTargets_targetId__ = function (params) {
    var path = '/dashboard/ad/chatbots/:chatbotId/tracks/targets/:targetId?';
    return transpiler({ params: params, path: path });
};
/**
 * /dashboard/ad/chatbots/:chatbotId/tracks
 */
export var dashboardAdChatbots_chatbotId_Tracks = function (params) {
    var path = '/dashboard/ad/chatbots/:chatbotId/tracks';
    return transpiler({ params: params, path: path });
};
/**
 * /dashboard/ad/chatbots/:chatbotId
 */
export var dashboardAdChatbots_chatbotId = function (params) {
    var path = '/dashboard/ad/chatbots/:chatbotId';
    return transpiler({ params: params, path: path });
};
/**
 * /dashboard/ad/chatbots/:chatbotId/*
 */
export var dashboardAdChatbots_chatbotId_all = function (params) {
    var path = '/dashboard/ad/chatbots/:chatbotId/*';
    return transpiler({ params: params, path: path });
};
/**
 * /dashboard/ad/:chatTypeParam/chatbots/:chatbotId/scenarios/:scenarioId/layers/:layerId/blocks/:blockId/rank/:rank
 */
export var dashboardAd_chatTypeParam_Chatbots_chatbotId_Scenarios_scenarioId_Layers_layerId_Blocks_blockId_Rank_rank_ = function (params) {
    var path = '/dashboard/ad/:chatTypeParam/chatbots/:chatbotId/scenarios/:scenarioId/layers/:layerId/blocks/:blockId/rank/:rank';
    return transpiler({ params: params, path: path });
};
/**
 * /dashboard/ad/:chatTypeParam/chatbots/:chatbotId/scenarios/:scenarioId/layers/:layerId/blocks/:blockId
 */
export var dashboardAd_chatTypeParam_Chatbots_chatbotId_Scenarios_scenarioId_Layers_layerId_Blocks_blockId_ = function (params) {
    var path = '/dashboard/ad/:chatTypeParam/chatbots/:chatbotId/scenarios/:scenarioId/layers/:layerId/blocks/:blockId';
    return transpiler({ params: params, path: path });
};
/**
 * /dashboard/ad/:chatTypeParam/chatbots/:chatbotId/scenarios/:scenarioId/layers/:layerId
 */
export var dashboardAd_chatTypeParam_Chatbots_chatbotId_Scenarios_scenarioId_Layers_layerId_ = function (params) {
    var path = '/dashboard/ad/:chatTypeParam/chatbots/:chatbotId/scenarios/:scenarioId/layers/:layerId';
    return transpiler({ params: params, path: path });
};
/**
 * /dashboard/ad/:chatTypeParam/chatbots/:chatbotId/scenarios/:scenarioId
 */
export var dashboardAd_chatTypeParam_Chatbots_chatbotId_Scenarios_scenarioId_ = function (params) {
    var path = '/dashboard/ad/:chatTypeParam/chatbots/:chatbotId/scenarios/:scenarioId';
    return transpiler({ params: params, path: path });
};
/**
 * /dashboard/ad/:chatTypeParam/chatbots/:chatbotId
 */
export var dashboardAd_chatTypeParam_Chatbots_chatbotId_ = function (params) {
    var path = '/dashboard/ad/:chatTypeParam/chatbots/:chatbotId';
    return transpiler({ params: params, path: path });
};
/**
 * /dashboard/accounts/chatbots/:chatbotId/pagelist_line
 */
export var dashboardAccountsChatbots_chatbotId_PagelistLine = function (params) {
    var path = '/dashboard/accounts/chatbots/:chatbotId/pagelist_line';
    return transpiler({ params: params, path: path });
};
/**
 * /dashboard/accounts/chatbots/:chatbotId/pagelist_webchat
 */
export var dashboardAccountsChatbots_chatbotId_PagelistWebchat = function (params) {
    var path = '/dashboard/accounts/chatbots/:chatbotId/pagelist_webchat';
    return transpiler({ params: params, path: path });
};
/**
 * /dashboard/accounts/chatbots/:chatbotId/instagram_page_lists
 */
export var dashboardAccountsChatbots_chatbotId_PagelistInstagram = function (params) {
    var path = '/dashboard/accounts/chatbots/:chatbotId/instagram_page_lists';
    return transpiler({ params: params, path: path });
};
/**
 * /dashboard/accounts/chatbots/:chatbotId/pagelist
 */
export var dashboardAccountsChatbots_chatbotId_Pagelist = function (params) {
    var path = '/dashboard/accounts/chatbots/:chatbotId/pagelist';
    return transpiler({ params: params, path: path });
};
/**
 * /dashboard/accounts/chatbots/:chatbotId/banners/:bannerId
 */
export var dashboardAccountsChatbots_chatbotId_Banners_bannerId_ = function (params) {
    var path = '/dashboard/accounts/chatbots/:chatbotId/banners/:bannerId';
    return transpiler({ params: params, path: path });
};
export var dashboardAccountsBannersNew = '/dashboard/accounts/banners/new';
/**
 * /dashboard/accounts/chatbots/:chatbotId/modals/:modalId
 */
export var dashboardAccountsChatbots_chatbotId_Modals_modalId_ = function (params) {
    var path = '/dashboard/accounts/chatbots/:chatbotId/modals/:modalId';
    return transpiler({ params: params, path: path });
};
export var dashboardAccountsChatbots = '/dashboard/accounts/chatbots';
export var dashboardLogin = '/dashboard/login';
export var dashboardSignup = '/dashboard/signup';
export var dashboardResetPassword = '/dashboard/reset-password';
export var dashboardNewAccount = '/dashboard/new-account';
export var dashboardGroup = '/dashboard/group';
export var dashboardAd = '/dashboard/ad';
export var dashboard = '/dashboard';
/**
 * /dashboard/accounts/modals/:modalId
 */
export var dashboardAccountsModals_modalId_ = function (params) {
    var path = '/dashboard/accounts/modals/:modalId';
    return transpiler({ params: params, path: path });
};
export var dashboardAccountsModals = '/dashboard/accounts/modals';
export var dashboardAccountsModalsNew = '/dashboard/accounts/modals/new';
/**
 * /dashboard/accounts/banners/:bannerId
 */
export var dashboardAccountsBanners_bannerId_ = function (params) {
    var path = '/dashboard/accounts/banners/:bannerId';
    return transpiler({ params: params, path: path });
};
export var dashboardAccountsBanners = '/dashboard/accounts/banners';
export var dashboardAccountsAccount = '/dashboard/accounts/account';
/**
 * /dashboard/accounts/:accountId/plugins/new
 */
export var dashboardAccounts_accountId_PluginsNew = function (params) {
    var path = '/dashboard/accounts/:accountId/plugins/new';
    return transpiler({ params: params, path: path });
};
/**
 * /dashboard/accounts/:accountId/plugins/:pluginId/paths/:pathId/events/:eventId
 */
export var dashboardAccounts_accountId_Plugins_pluginId_Paths_pathId_Events_eventId_ = function (params) {
    var path = '/dashboard/accounts/:accountId/plugins/:pluginId/paths/:pathId/events/:eventId';
    return transpiler({ params: params, path: path });
};
/**
 * /dashboard/accounts/:accountId/plugins/:pluginId/paths/:pathId
 */
export var dashboardAccounts_accountId_Plugins_pluginId_Paths_pathId = function (params) {
    var path = '/dashboard/accounts/:accountId/plugins/:pluginId/paths/:pathId';
    return transpiler({ params: params, path: path });
};
/**
 * /dashboard/accounts/:accountId/plugins/:pluginId
 */
export var dashboardAccounts_accountId_Plugins_pluginId_ = function (params) {
    var path = '/dashboard/accounts/:accountId/plugins/:pluginId';
    return transpiler({ params: params, path: path });
};
/**
 * /dashboard/accounts/:accountId/plugins
 */
export var dashboardAccounts_accountId_Plugins = function (params) {
    var path = '/dashboard/accounts/:accountId/plugins';
    return transpiler({ params: params, path: path });
};
/**
 * /dashboard/accounts/:accountId/members
 */
export var dashboardAccounts_accountId_Members = function (params) {
    var path = '/dashboard/accounts/:accountId/members';
    return transpiler({ params: params, path: path });
};
export var dashboardAccounts = '/dashboard/accounts';
/**
 * /dashboard/form-templates/:templateId
 */
export var dashboardAdChatbots_chatbotId_FormTemplates_templateId_ = function (params) {
    var path = '/dashboard/ad/chatbots/:chatbotId/form-templates/:templateId';
    return transpiler({ params: params, path: path });
};
export var dashboardAdChatbots_chatbotId_RpaSettings = function (params) {
    var path = '/dashboard/ad/chatbots/:chatbotId/rpa-settings';
    return transpiler({ params: params, path: path });
};
export var dashboardAdChatbots_chatbotId_FormBuilder_formBuildId = function (params) {
    var path = '/dashboard/ad/chatbots/:chatbotId/form-builder/:formBuildId';
    return transpiler({ params: params, path: path });
};
export var dashboardAdChatbots_chatbotId_FormBuilder_formBuildId_FormSettings = function (params) {
    var path = '/dashboard/ad/chatbots/:chatbotId/form-builder/:formBuildId/form-setting';
    return transpiler({ params: params, path: path });
};
export var dashboardAdChatbots_chatbotId_FormBuilder_formBuildId_FormConversation_Select = function (params) {
    var path = '/dashboard/ad/chatbots/:chatbotId/form-builder/:formBuildId/form-conversation/select';
    return transpiler({ params: params, path: path });
};
export var dashboardAdChatbots_chatbotId_FormBuilder_formBuildId_FormConversation_Create_scenarioId_ = function (params) {
    var path = '/dashboard/ad/chatbots/:chatbotId/form-builder/:formBuildId/form-conversation/create/:scenarioId';
    return transpiler({ params: params, path: path });
};
export var dashboardAdChatbots_chatbotId_FormBuilder_formBuildId_FormConversation_Edit_formScenarioId_ = function (params) {
    var path = '/dashboard/ad/chatbots/:chatbotId/form-builder/:formBuildId/form-conversation/edit/:formScenarioId';
    return transpiler({ params: params, path: path });
};
/**
 * /dashboard/form-editing/:formBuilderId/form-automation
 */
export var dashboardAdChatbots_chatbotId_FormBuilder_formBuildId_FormAutomation = function (params) {
    var path = '/dashboard/ad/chatbots/:chatbotId/form-builder/:formBuildId/form-automation';
    return transpiler({ params: params, path: path });
};
/**
 * /dashboard/ad/chatbots/:chatbotId/formBuild/:formBuildId/webform-setting/:formId
 */
export var dashboardAdChatbots_chatbotId_FormBuild_formBuildId_WebformSetting_formId_ = function (params) {
    var path = '/dashboard/ad/chatbots/:chatbotId/formBuild/:formBuildId/webform-setting/:formId';
    return transpiler({ params: params, path: path });
};
export var dashboardAdChatbots_chatbotId_FormBuilder_formBuildId_PostLogs = function (params) {
    var path = '/dashboard/ad/chatbots/:chatbotId/form-builder/:formBuildId/post-logs';
    return transpiler({ params: params, path: path });
};
