var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { injectIntl } from 'react-intl';
import Button from '@src/components/atoms/Button';
import { Input } from '@src/components/atoms';
import buttonTypes from '@src/lib/buttonTypes';
import { TextReplacementType, } from 'zeals-protobuf/zeals/web/entities/form_automation_pb';
import { textReplacementTypeToString } from '@src/lib/formAutomation';
import SelectFilterDropdown from '@src/components/atoms/SelectFilterDropdown';
import UserFieldControl from '@src/components/molecules/UserFieldControl';
var replacementTypeItems = Object.values(TextReplacementType)
    .filter(function (type) { return type !== TextReplacementType.TEXT_REPLACEMENT_UNSPECIFIED; })
    .map(function (type) {
    var label = textReplacementTypeToString(type);
    return {
        label: label,
        value: type,
    };
});
export default injectIntl(function FormAutomationTextFieldSection(_a) {
    var intl = _a.intl, regexp = _a.regexp, replacementGroupsList = _a.replacementGroupsList, onRegexpChange = _a.onRegexpChange, onAddReplacementGroup = _a.onAddReplacementGroup, onRemoveReplacementGroup = _a.onRemoveReplacementGroup, onReplacementGroupChange = _a.onReplacementGroupChange, errors = _a.errors;
    return (React.createElement("div", { "data-testid": "FormAutomationTextFieldSection" },
        React.createElement("h3", { className: "title" }, intl.formatMessage({ id: "+7jm7L", defaultMessage: "Text Input Action" })),
        React.createElement("div", { className: "select-container" },
            React.createElement("span", { className: "subtitle" }, intl.formatMessage({ id: "0ahMzP", defaultMessage: "Select characters from the value (e.g. phone number)" })),
            React.createElement("div", { className: "field" },
                React.createElement(Input, { className: "item", value: regexp, placeholder: intl.formatMessage({ id: "KHKeek", defaultMessage: "Please enter a Regexp" }), onChange: function (e) { return onRegexpChange(e.target.value); } }),
                errors.regexp && React.createElement("span", { className: "error" }, errors.regexp)),
            Boolean(replacementGroupsList.length) && (React.createElement("div", { className: "section" }, replacementGroupsList.map(function (replacementGroup, i) {
                return (React.createElement("div", { className: "replacement-group", key: "ReplacementGroup-" + (i + 1) },
                    React.createElement("div", { className: "replacement-group-title" },
                        "Group ",
                        i + 1,
                        React.createElement(UserFieldControl, { className: "user-field-control", onClickDelete: function () { return onRemoveReplacementGroup(i); } })),
                    React.createElement("h4", { className: "replacement-group-subtitle" }, intl.formatMessage({ id: "zU+tl5", defaultMessage: "Replace text in the value" })),
                    React.createElement("div", { className: "field" },
                        React.createElement("label", { htmlFor: "regexp" }, intl.formatMessage({ id: "wQPGlz", defaultMessage: "Replace type" })),
                        React.createElement(SelectFilterDropdown, { value: replacementGroup.replacement, items: replacementTypeItems, onChange: function (_a) {
                                var value = _a.value;
                                return onReplacementGroupChange(i, __assign(__assign({}, replacementGroup), { replacement: value }));
                            } })),
                    React.createElement("div", { className: "field" },
                        React.createElement("label", { htmlFor: "regexp" }, intl.formatMessage({ id: "jAkc1E", defaultMessage: "Regexp" })),
                        React.createElement(Input, { className: "item", value: replacementGroup.regexp, placeholder: intl.formatMessage({ id: "jAkc1E", defaultMessage: "Regexp" }), onChange: function (e) {
                                return onReplacementGroupChange(i, __assign(__assign({}, replacementGroup), { regexp: e.target.value }));
                            } })),
                    replacementGroup.replacement ===
                        TextReplacementType.TEXT_REPLACEMENT_FIXED && (React.createElement("div", { className: "field" },
                        React.createElement("label", { htmlFor: "fixed-value" }, intl.formatMessage({ id: "q3Gdr7", defaultMessage: "Replacement Text" })),
                        React.createElement(Input, { className: "item", value: replacementGroup.fixedString, placeholder: intl.formatMessage({ id: "cWrDlu", defaultMessage: "Fixed Value" }), onChange: function (e) {
                                return onReplacementGroupChange(i, __assign(__assign({}, replacementGroup), { fixedString: e.target.value }));
                            } })))));
            }))),
            errors.replacementGroupsList && (React.createElement("span", { className: "error" }, intl.formatMessage({ id: "bHRG6k", defaultMessage: "Please enter a Regexp in all the replacement groups" }))),
            React.createElement(Button, { className: "add-item-button", onClick: onAddReplacementGroup, buttonType: buttonTypes.SETTING },
                React.createElement("span", { className: "adicon_plus02" }),
                intl.formatMessage({ id: "22YtXH", defaultMessage: "Add Replacement Group" })))));
});
