var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { handleAPIErrors } from '@src/lib/helpers';
import { sampleUsers } from '@src/redux/fanp/lib/users';
import * as endUserApi from '@src/services/api/endUser';
import * as userTagAssociationApi from '@src/services/api/userTagAssociation';
import * as zealsAdminRightApi from '@src/services/zeals_admin_api/zealsAdminRight';
import { UserActionTypes } from '../types/user';
// action to set data to state
export var setUsers = function (users) {
    // TODO: delete this in favor of actual tags from backend
    var augmentedUsers = __assign(__assign({}, users), { end_users: users.end_users.map(function (endUser) {
            return __assign(__assign({}, endUser), { userTags: [], checked: false });
        }) });
    return {
        type: UserActionTypes.SET_USERS,
        users: augmentedUsers,
    };
};
export var updateAutoTag = function (_a) {
    var userId = _a.userId, tag = _a.tag;
    return {
        type: UserActionTypes.UPDATE_AUTO_TAG,
        userId: userId,
        tag: tag,
    };
};
export var updateUserTag = function (_a) {
    var userId = _a.userId, tag = _a.tag;
    return {
        type: UserActionTypes.UPDATE_USER_TAG,
        userId: userId,
        tag: tag,
    };
};
export var setUser = function (user) {
    return {
        type: UserActionTypes.SET_USER,
        user: user,
    };
};
export var setUserScenarios = function (scenarios) { return ({
    type: UserActionTypes.SET_USER_SCENARIOS,
    scenarios: scenarios,
}); };
export var setUserQuickReplies = function (quickReplies) { return ({
    type: UserActionTypes.SET_USER_QUICK_REPLIES,
    quickReplies: quickReplies,
}); };
export var setHasUsers = function (hasUsers) { return ({
    type: UserActionTypes.SET_HAS_USERS,
    hasUsers: hasUsers,
}); };
export var setCheckedAllUsers = function (checkedUsers) { return ({
    type: UserActionTypes.SET_CHECKED_ALL_USERS,
    checkedUsers: checkedUsers,
}); };
export var removePropOfUser = function (key) { return ({
    type: UserActionTypes.REMOVE_PROP_OF_USER,
    key: key,
}); };
export var handleUpdateUser = function (userIterator, userObject) { return ({
    type: UserActionTypes.HANDLE_UPDATE_USER,
    userIterator: userIterator,
    userObject: userObject,
}); };
export var toggleCheckedUser = function (userIterator) { return ({
    type: UserActionTypes.TOGGLE_CHECKED_USER,
    userIterator: userIterator,
}); };
export var updatePropUser = function (_a) {
    var chatbotId = _a.chatbotId, userId = _a.userId, userIterator = _a.userIterator, key = _a.key, value = _a.value;
    return ({
        type: UserActionTypes.UPDATE_PROP_OF_USER,
        chatbotId: chatbotId,
        userId: userId,
        userIterator: userIterator,
        key: key,
        value: value,
    });
};
export var setAccessRight = function (canAccessAdmin) { return ({
    type: UserActionTypes.SET_ADMIN_ACCESS,
    canAccessAdmin: canAccessAdmin,
}); };
export var fetchUserList = function (_a) {
    var chatbotId = _a.chatbotId, page = _a.page, endUserIds = _a.endUserIds;
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        var _a, status, totalUsers, lastPage, endUsers, e_1;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, endUserApi.getUserList(chatbotId, {
                            page: page,
                            endUserIds: endUserIds,
                        })];
                case 1:
                    _a = _b.sent(), status = _a.status, totalUsers = _a.end_user_number, lastPage = _a.last_page, endUsers = _a.end_users;
                    if (status === 404) {
                        throw new Error('ユーザーがありません');
                    }
                    if (endUsers.length === 0) {
                        dispatch(setHasUsers(false));
                        dispatch(setUsers({ totalUsers: totalUsers, last_page: lastPage, end_users: sampleUsers }));
                        return [2 /*return*/];
                    }
                    dispatch(setHasUsers(true));
                    dispatch(setUsers({ totalUsers: totalUsers, last_page: lastPage, end_users: endUsers }));
                    return [3 /*break*/, 3];
                case 2:
                    e_1 = _b.sent();
                    dispatch(setHasUsers(false));
                    dispatch(setUsers({
                        totalUsers: 0,
                        last_page: 1,
                        end_users: sampleUsers,
                    }));
                    handleAPIErrors(e_1);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
};
export var toggleCheckAllUsers = function (chatbotId) { return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
    var endUsers, e_2;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                dispatch({ type: UserActionTypes.TOGGLE_CHECK_ALL_USERS });
                return [4 /*yield*/, endUserApi.getUserListAll(chatbotId)];
            case 1:
                endUsers = _a.sent();
                dispatch(setCheckedAllUsers(endUsers));
                return [3 /*break*/, 3];
            case 2:
                e_2 = _a.sent();
                handleAPIErrors("Couldn't fetch all users", e_2);
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); }; };
export var fetchUser = function (chatbotId, userId, errMessageFunction) { return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, endUser, tags, userScenarios, userQuickReplies, e_3;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 2, , 3]);
                return [4 /*yield*/, endUserApi.getUserListMember(chatbotId, userId)];
            case 1:
                _a = _b.sent(), endUser = _a.end_user, tags = _a.tags, userScenarios = _a.user_scenarios, userQuickReplies = _a.user_quick_replies;
                dispatch(setUser(__assign(__assign({}, endUser), { tags: tags })));
                dispatch(setUserScenarios(userScenarios));
                dispatch(setUserQuickReplies(userQuickReplies));
                return [3 /*break*/, 3];
            case 2:
                e_3 = _b.sent();
                errMessageFunction();
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); }; };
export var updatePropOfUser = function (_a) {
    var chatbotId = _a.chatbotId, userId = _a.userId, userIterator = _a.userIterator, key = _a.key, value = _a.value;
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        var endUser, e_4;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 3, , 4]);
                    dispatch(updatePropUser({
                        chatbotId: chatbotId,
                        userId: userId,
                        userIterator: userIterator,
                        key: key,
                        value: value,
                    }));
                    if (!(key === 'active')) return [3 /*break*/, 2];
                    return [4 /*yield*/, endUserApi.updateEndUser(chatbotId, userId, {
                            active: value,
                        })];
                case 1:
                    endUser = _a.sent();
                    dispatch(removePropOfUser('toBeChanged'));
                    dispatch(handleUpdateUser(userIterator, endUser));
                    _a.label = 2;
                case 2: return [3 /*break*/, 4];
                case 3:
                    e_4 = _a.sent();
                    handleAPIErrors('There was an error in updating the user.', e_4);
                    dispatch({ type: UserActionTypes.UNDO_USER });
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
};
export var addTagToMulitpleUsers = function (_a) {
    var chatbotId = _a.chatbotId, endUserIds = _a.endUserIds, tagName = _a.tagName;
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        var body, tag, e_5;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    body = {
                        end_user_ids: endUserIds,
                        name: tagName,
                    };
                    return [4 /*yield*/, userTagAssociationApi.createUserTagAssociation(chatbotId, endUserIds[0], body)];
                case 1:
                    tag = _a.sent();
                    dispatch({
                        type: UserActionTypes.ADD_TAG_TO_MULTIPLE_USERS,
                        chatbotId: chatbotId,
                        endUserIds: endUserIds,
                        tag: tag,
                    });
                    return [3 /*break*/, 3];
                case 2:
                    e_5 = _a.sent();
                    handleAPIErrors('There was an error in updating the users.', e_5);
                    dispatch({ type: UserActionTypes.UNDO_USER });
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
};
export var blockMultipleUsers = function (_a) {
    var chatbotId = _a.chatbotId, endUserIds = _a.endUserIds;
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        var res, e_6;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, endUserApi.updateUsersChecked(chatbotId, endUserIds)];
                case 1:
                    res = _a.sent();
                    if (res.status !== 200) {
                        throw new Error(res.message);
                    }
                    dispatch({
                        type: UserActionTypes.BLOCK_MULTIPLE_USERS,
                        chatbotId: chatbotId,
                        endUserIds: endUserIds,
                    });
                    return [3 /*break*/, 3];
                case 2:
                    e_6 = _a.sent();
                    handleAPIErrors('There was an error in updating the users.', e_6);
                    dispatch({ type: UserActionTypes.UNDO_USER });
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
};
export var addAutoTag = function (_a) {
    var userId = _a.userId, tag = _a.tag, chatbotId = _a.chatbotId;
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        var body, resTag, e_7;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    dispatch({
                        type: UserActionTypes.ADD_AUTO_TAG,
                        chatbotId: chatbotId,
                        userId: userId,
                        tag: tag,
                    });
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    body = {
                        end_user_ids: [userId],
                        name: tag.name,
                    };
                    return [4 /*yield*/, userTagAssociationApi.createUserTagAssociation(chatbotId, userId, body)];
                case 2:
                    resTag = _a.sent();
                    dispatch(updateAutoTag({
                        userId: userId,
                        tag: resTag,
                    }));
                    return [3 /*break*/, 4];
                case 3:
                    e_7 = _a.sent();
                    handleAPIErrors('There was an error in creating a new tag.', e_7);
                    dispatch({ type: UserActionTypes.UNDO_USER });
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
};
export var addUserTag = function (_a) {
    var userId = _a.userId, tag = _a.tag, chatbotId = _a.chatbotId;
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        var body, resTag, e_8;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    dispatch({
                        type: UserActionTypes.ADD_USER_TAG,
                        chatbotId: chatbotId,
                        userId: userId,
                        tag: tag,
                    });
                    body = {
                        end_user_ids: [userId],
                        name: tag.name,
                    };
                    return [4 /*yield*/, userTagAssociationApi.createUserTagAssociation(chatbotId, userId, body)];
                case 1:
                    resTag = _a.sent();
                    dispatch(updateUserTag({
                        userId: userId,
                        tag: resTag,
                    }));
                    return [3 /*break*/, 3];
                case 2:
                    e_8 = _a.sent();
                    handleAPIErrors('There was an error in creating a new tag.', e_8);
                    dispatch({ type: UserActionTypes.UNDO_USER });
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
};
export var removeAutoTag = function (_a) {
    var userId = _a.userId, autoTagId = _a.autoTagId, chatbotId = _a.chatbotId, name = _a.name;
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        var e_9;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    dispatch({
                        type: UserActionTypes.REMOVE_AUTO_TAG,
                        chatbotId: chatbotId,
                        userId: userId,
                        autoTagId: autoTagId,
                        name: name,
                    });
                    return [4 /*yield*/, userTagAssociationApi.deleteUserTagAssociation(chatbotId, userId, {
                            name: name,
                        })];
                case 1:
                    _a.sent();
                    return [3 /*break*/, 3];
                case 2:
                    e_9 = _a.sent();
                    handleAPIErrors('There was an error in removing a tag.', e_9);
                    dispatch({ type: UserActionTypes.UNDO_USER });
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
};
// HERE
export var filterUserList = function (_a) {
    var chatbotId = _a.chatbotId, filterString = _a.filterString;
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        var res, payload, e_10;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, endUserApi.searchUserList(chatbotId, {
                            filterString: filterString,
                        })];
                case 1:
                    res = _a.sent();
                    payload = {
                        totalUsers: res.end_user_number,
                        last_page: res.last_page,
                        end_users: res.end_users || [],
                    };
                    dispatch(setUsers(payload));
                    return [3 /*break*/, 3];
                case 2:
                    e_10 = _a.sent();
                    handleAPIErrors('request failed', e_10);
                    dispatch({ type: UserActionTypes.UNDO_USER });
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
};
export var fetchZealsAdminRights = function () { return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
    var adminRight, e_11;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, zealsAdminRightApi.getRights()];
            case 1:
                adminRight = _a.sent();
                dispatch(setAccessRight(adminRight.rights.can_access_urakanrigamen));
                return [3 /*break*/, 3];
            case 2:
                e_11 = _a.sent();
                console.error(e_11.message);
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); }; };
