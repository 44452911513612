import React, { Fragment } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { makeJpFormattedDateStr } from '@src/lib/helpers';
import botImage from '@src/assets/images/bot-logo.png';
import { Spinner } from '@src/components/atoms';
import { colors } from '@src/colors';
import { ProfilePic, Stats } from '@src/components/organisms/fanp/EndUser';
import UserContent from '@src/components/organisms/fanp/UserContent';
import TagList from '@src/components/molecules/TagList';
var propTypes = {
  showUserModal: PropTypes.bool,
  onCancel: PropTypes.func,
  user: PropTypes.object,
  chatbotIdParam: PropTypes.number,
  addAutoTag: PropTypes.func,
  removeAutoTag: PropTypes.func,
  hasUsers: PropTypes.bool,
  adScenarios: PropTypes.array,
  conversationMessages: PropTypes.array
};
var Container = styled.div.withConfig({
  displayName: "Container",
  componentId: "sc-11gsq21-0"
})(["width:100%;max-height:240px;overflow:scroll;padding:16px;border-bottom:1px solid ", ";display:grid;grid-template-columns:auto 2fr;background:", ";> .prof_pic{align-self:auto;}"], colors.border.default, colors.background.main_menu);
var UserStats = styled.div.withConfig({
  displayName: "UserStats",
  componentId: "sc-11gsq21-1"
})(["display:grid;grid-template-rows:auto auto 1fr;grid-gap:8px 0;margin-left:16px;font-size:12px;> span:first-of-type{font-size:20px;}.stats{grid-row:1;}"]);

var UserInfoContent = function UserInfoContent(_ref) {
  var showUserModal = _ref.showUserModal,
      user = _ref.user,
      adScenarios = _ref.adScenarios,
      conversationMessages = _ref.conversationMessages;
  return React.createElement(React.Fragment, null, user ? React.createElement(React.Fragment, null, React.createElement(Container, null, React.createElement(ProfilePic, {
    className: "prof_pic",
    gender: user && user.gender,
    image: user.profile_photo_url ? user.profile_photo_url : botImage
  }), React.createElement(UserStats, null, React.createElement(FormattedMessage, {
    id: "poZTbd",
    defaultMessage: [{
      "type": 1,
      "value": "lastName"
    }, {
      "type": 0,
      "value": " "
    }, {
      "type": 1,
      "value": "firstName"
    }],
    values: {
      firstName: user.first_name,
      lastName: user.last_name
    }
  }), React.createElement(Stats, null, React.createElement("p", {
    className: "stats"
  }, "\u6D41\u5165\u65E5\uFF1A".concat(makeJpFormattedDateStr(user.created_at))), React.createElement("p", {
    className: "stats"
  }, "CV\u65E5\uFF1A".concat(user.conversion_at ? makeJpFormattedDateStr(user.conversion_at) : '-'))), React.createElement(TagList, {
    user: user,
    showUserModal: showUserModal,
    displayOnly: !!showUserModal
  }))), adScenarios ? React.createElement(UserContent, {
    adScenarios: user.scenarios || [],
    quickReplies: user.quick_replies || [],
    conversationMessages: conversationMessages
  }) : React.createElement(Spinner, null)) : React.createElement(Spinner, {
    className: "user_info_spinner"
  }));
};

UserInfoContent.propTypes = propTypes;
export default injectIntl(UserInfoContent);