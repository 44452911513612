var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import Pagination from 'react-js-pagination';
import styled from 'styled-components';
import { Input } from '@src/components/atoms';
import { colors } from '@src/colors';
import capitalize from 'lodash/capitalize';
import { injectIntl } from 'react-intl';
var TaskTable = styled.table(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  border: 1px solid #444;\n  border-spacing: 0;\n  width: 100%;\n  text-align: center;\n  th,\n  td {\n    padding: 0.5rem 1rem;\n  }\n  th {\n    background-color: #888;\n    color: #fff;\n  }\n  tr {\n    border: none;\n    &.active {\n      td {\n        border-top: 1px solid ", ";\n        border-bottom: 1px solid ", ";\n      }\n    }\n    &:nth-child(even) {\n      background: #ccc;\n    }\n    &:nth-child(odd) {\n      background: #fff;\n    }\n  }\n"], ["\n  border: 1px solid #444;\n  border-spacing: 0;\n  width: 100%;\n  text-align: center;\n  th,\n  td {\n    padding: 0.5rem 1rem;\n  }\n  th {\n    background-color: #888;\n    color: #fff;\n  }\n  tr {\n    border: none;\n    &.active {\n      td {\n        border-top: 1px solid ", ";\n        border-bottom: 1px solid ", ";\n      }\n    }\n    &:nth-child(even) {\n      background: #ccc;\n    }\n    &:nth-child(odd) {\n      background: #fff;\n    }\n  }\n"])), colors.custom_form_admin.hover_secondary, colors.custom_form_admin.hover_secondary);
var PaginationContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  text-align: center;\n"], ["\n  text-align: center;\n"])));
var DefaultTableCellInner = function (_a) {
    var value = _a.value;
    return React.createElement("span", null, value);
};
var TableItem = function (_a) {
    var item = _a.item, activeId = _a.activeId, rowOnClick = _a.rowOnClick, columns = _a.columns;
    return (React.createElement("tr", { key: "table-row-" + item.id, className: activeId && activeId === item.id ? 'active' : '', onClick: rowOnClick ? function (e) { return rowOnClick(e, item); } : null }, Object.keys(columns).map(function (columnKey) {
        var value = item[columnKey];
        var column = columns[columnKey];
        var TableCellInner = column.component || DefaultTableCellInner;
        return (React.createElement("td", { key: "table-item-" + columnKey + "-" + item.id },
            React.createElement(TableCellInner, __assign({ key: "table-item-inner-" + columnKey + "-" + item.id, value: value, item: item }, column.spread))));
    })));
};
var Table = function (_a) {
    var title = _a.title, columns = _a.columns, items = _a.items, itemTotal = _a.itemTotal, changePage = _a.changePage, activePageNumber = _a.activePageNumber, activeId = _a.activeId, per = _a.per, rowOnClick = _a.rowOnClick, search = _a.search, intl = _a.intl;
    return (React.createElement(React.Fragment, null,
        React.createElement("h2", null, title),
        search && (React.createElement(Input, { type: "text", placeholder: search.placeholder || intl.formatMessage({ id: "xmcVZ0", defaultMessage: "Search" }), value: search.value, onChange: search.onChange, dataTest: "Table_SearchInput" })),
        React.createElement(PaginationContainer, null,
            React.createElement(Pagination, { activePage: activePageNumber, totalItemsCount: itemTotal, itemsCountPerPage: Number(per) || 10, onChange: changePage, innerClass: "zeals-admin-pagination", pageRangeDisplayed: 10, "data-testid": "Table_Pagination" })),
        React.createElement(TaskTable, null,
            React.createElement("thead", null,
                React.createElement("tr", null, Object.keys(columns).map(function (key) {
                    var c = columns[key];
                    return (React.createElement("th", { key: "table-heading-" + key }, c.heading || key.split('_').map(capitalize).join(' ')));
                }))),
            React.createElement("tbody", null, (items || []).map(function (item) { return (React.createElement(TableItem, { activeId: activeId, item: item, rowOnClick: rowOnClick, columns: columns })); })))));
};
export default injectIntl(Table);
var templateObject_1, templateObject_2;
