var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import { ConnectedBlock } from '@src/components/atoms';
var ConnectedBlockContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  width: 100%;\n  padding: 0 16px;\n\n  .blank {\n    min-height: 16px;\n  }\n"], ["\n  display: flex;\n  width: 100%;\n  padding: 0 16px;\n\n  .blank {\n    min-height: 16px;\n  }\n"])));
var ConnectedBlocks = function (_a) {
    var blocks = _a.blocks, top = _a.top, onClick = _a.onClick;
    return blocks.length ? (React.createElement(ConnectedBlockContainer, null, blocks.map(function (block, i) {
        return (React.createElement(ConnectedBlock, { block: block, top: top, key: "prev-next-block-" + i, onClick: function () {
                return onClick &&
                    onClick({
                        blockId: block.id,
                        scenarioChatType: block.chat_type || null,
                        connectedObj: block,
                    });
            } }));
    }))) : (React.createElement(ConnectedBlockContainer, null,
        React.createElement("div", { className: "blank" })));
};
ConnectedBlocks.defaultProps = { blocks: [] };
export default ConnectedBlocks;
var templateObject_1;
