import "core-js/modules/es.array.map";
import _extends from "@babel/runtime/helpers/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
import React from 'react';
import styled from 'styled-components';
import { SortableContainer } from 'react-sortable-hoc';
import QuickReplyChoice from './QuickReplyChoice';
var ListWrapper = styled.div.withConfig({
  displayName: "QuickReplyChoiceList__ListWrapper",
  componentId: "sc-1fiinof-0"
})(["display:flex;"]);

var QuickReplyChoiceList = function QuickReplyChoiceList(_ref) {
  var quickReplyChoices = _ref.quickReplyChoices,
      undefinedBlock = _ref.undefinedBlock,
      toggleQuickReplyDeleteModal = _ref.toggleQuickReplyDeleteModal,
      errors = _ref.errors,
      rest = _objectWithoutProperties(_ref, ["quickReplyChoices", "undefinedBlock", "toggleQuickReplyDeleteModal", "errors"]);

  return React.createElement(ListWrapper, null, quickReplyChoices.map(function (quickReplyChoice, i) {
    return React.createElement(QuickReplyChoice, _extends({
      key: "".concat(quickReplyChoice.id, "-quick_reply_choice"),
      quickReplyChoice: quickReplyChoice,
      quickReplyChoiceIterator: i,
      choicesLength: quickReplyChoices ? quickReplyChoices.length : 0,
      toggleQuickReplyDeleteModal: undefinedBlock ? null : toggleQuickReplyDeleteModal,
      errors: errors
    }, rest, {
      index: i
    }));
  }));
};

export default SortableContainer(QuickReplyChoiceList);