import React from 'react';
import InputRadioBox from '@src/components/atoms/InputRadioBox';
var RadioGroup = function (_a) {
    var value = _a.value, title = _a.title, options = _a.options, fontSize = _a.fontSize, onClick = _a.onClick;
    return (React.createElement("div", { className: "select-item-wrapper", style: { textAlign: 'center' } },
        React.createElement("p", { className: "select-item-title" }, title),
        options &&
            options.map(function (option) {
                return (React.createElement(InputRadioBox, { key: option, checked: value === option, onClick: function () { return onClick(option); }, onChange: function () { }, text: option, fontSize: fontSize || '32px' }));
            })));
};
export default RadioGroup;
