import "core-js/modules/es.function.name";
import "core-js/modules/es.string.small";
import _extends from "@babel/runtime/helpers/extends";
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors, shadows } from '@src/colors';
import { hoverShadowMid } from '@src/lib/mixins';
import { DeleteIcon } from '@src/components/atoms';
import Article from '@src/components/organisms/fanp/Article';
import TalkRoomButtonTemplate from '@src/components/organisms/fanp/TalkRoomButtonTemplate';
import { genericMessageStructShape, genericMessageInterfaceShape, genericItemStructShape, genericItemInterfaceShape, ButtonTemplateStruct, ButtonTemplateInterface, buttonItemInterfaceShape, ArticleStruct, ArticleInterface } from '@src/lib/definitions';
import FileUploadTemplate from '@src/components/molecules/FileUploadTemplate';
import FileUploadUI from '@src/components/molecules/FileUploadUI';
import TagTemplate from '../TagTemplate';
var GenericItemContainer = styled.div.withConfig({
  displayName: "GenericItem__GenericItemContainer",
  componentId: "sc-1e8j2c5-0"
})(["width:260px;height:fit-content;position:relative;flex-shrink:0;margin:16px 0;box-shadow:", ";border:1px solid ", ";border-radius:", ";", ";&:not(:nth-child(1)){margin-left:8px;}&:hover{> .adicon_delete{display:block;}}"], shadows.small.light, colors.placeholder, function (props) {
  return props.borderRadius ? props.borderRadius : 0;
}, hoverShadowMid);
var ArticleWrapper = styled.div.withConfig({
  displayName: "GenericItem__ArticleWrapper",
  componentId: "sc-1e8j2c5-1"
})(["padding:8px;border:1px solid ", ";border-left:0;border-right:0;", ";"], colors.border.default, function (props) {
  return props.hasBottomBorder ? '' : "border-bottom: none;";
});
var propTypes = {
  genericMessageStruct: PropTypes.shape(genericMessageStructShape).isRequired,
  genericMessageInterface: PropTypes.shape(genericMessageInterfaceShape),
  genericItemStruct: PropTypes.shape(genericItemStructShape).isRequired,
  genericItemInterface: PropTypes.shape(genericItemInterfaceShape),
  buttonItemInterface: PropTypes.shape(buttonItemInterfaceShape),
  borderRadius: PropTypes.string,
  scenarios: PropTypes.object,
  layers: PropTypes.object,
  blocks: PropTypes.object,
  platform: PropTypes.string
};

var GenericItem = function GenericItem(props) {
  var borderRadius = props.borderRadius,
      _props$genericMessage = props.genericMessageStruct,
      chatbotId = _props$genericMessage.chatbotId,
      scenarioId = _props$genericMessage.scenarioId,
      message = _props$genericMessage.message,
      schema = _props$genericMessage.schema,
      readOnly = _props$genericMessage.readOnly,
      limits = _props$genericMessage.limits,
      _props$genericItemStr = props.genericItemStruct,
      messageId = _props$genericItemStr.messageId,
      item = _props$genericItemStr.item,
      iterator = _props$genericItemStr.iterator,
      messageType = _props$genericItemStr.messageType,
      scrapingChecked = _props$genericItemStr.scrapingChecked,
      genericMessageInterface = props.genericMessageInterface,
      onUpdateArticle = props.genericItemInterface.onUpdateArticle,
      buttonItemInterface = props.buttonItemInterface,
      scenarios = props.scenarios,
      layers = props.layers,
      blocks = props.blocks,
      platform = props.platform;
  var buttonTemplateStruct = ButtonTemplateStruct({
    buttonTemplate: item,
    readOnly: readOnly,
    messageType: messageType,
    scenarios: scenarios,
    layers: layers,
    blocks: blocks
  });
  var buttonTemplateInterface = ButtonTemplateInterface();
  var articleStruct = ArticleStruct({
    messageId: messageId,
    item: item,
    scrapingChecked: scrapingChecked,
    messageType: messageType
  });
  var articleInterface = ArticleInterface({
    onChange: onUpdateArticle
  });
  var hasButtons = item.generic_item_buttons && item.generic_item_buttons.length;
  return React.createElement(GenericItemContainer, {
    borderRadius: borderRadius
  }, !readOnly && message.generic_template.generic_items.length > 1 && React.createElement(DeleteIcon, {
    onClick: function onClick() {
      return genericMessageInterface.onRemoveItem({
        chatbotId: chatbotId,
        scenarioId: scenarioId,
        messageId: messageId,
        messageType: messageType,
        itemId: item.id
      });
    }
  }), React.createElement(FileUploadTemplate, _extends({
    keyName: "image_url",
    messageStruct: props.genericMessageStruct,
    articleStruct: articleStruct,
    articleInterface: articleInterface,
    schema: schema,
    readOnly: readOnly
  }, props), React.createElement(FileUploadUI, {
    itemIterator: iterator,
    readOnly: readOnly
  })), React.createElement(ArticleWrapper, {
    hasBottomBorder: !readOnly || hasButtons
  }, React.createElement(Article, {
    limits: limits,
    schema: schema,
    readOnly: readOnly,
    article: item && item.article,
    scrapingChecked: scrapingChecked,
    messageType: messageType,
    onChange: function onChange(e) {
      onUpdateArticle({
        chatbotId: chatbotId,
        scenarioId: scenarioId,
        messageId: messageId,
        messageType: messageType,
        itemId: item.id,
        articleId: item && item.article.id,
        name: e.target.name,
        value: e.target.value,
        scrapingChecked: scrapingChecked
      });
    }
  }, React.createElement(Article.Title, {
    height: 47
  }), React.createElement(Article.Subtitle, null), React.createElement(Article.Url, null)), React.createElement(TagTemplate, _extends({
    messageStruct: props.genericMessageStruct,
    articleStruct: articleStruct,
    tags: item.tags,
    genericItemInterface: props.genericItemInterface
  }, props))), buttonTemplateStruct && React.createElement(TalkRoomButtonTemplate, {
    messageStruct: props.genericMessageStruct,
    genericItemStruct: props.genericItemStruct,
    buttonTemplateStruct: buttonTemplateStruct,
    buttonTemplateInterface: buttonTemplateInterface,
    buttonItemInterface: buttonItemInterface,
    platform: platform
  }));
};

GenericItem.propTypes = propTypes;
export default GenericItem;