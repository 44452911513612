var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import React, { Component } from 'react';
import { Composition } from 'atomic-layout';
import { makeFetchInit, handleAPIErrors } from '@src/lib/helpers/ajaxFuncs';
import styled from 'styled-components';
import { colors } from '@src/colors';
import endpoints from '@src/endpoints';
import { EmptySplash } from '@src/components/molecules';
import { PluginPathCreate } from '@src/components/organisms/fanp';
import PluginSidebar from '@src/components/organisms/fanp/plugins/PluginSidebar';
import { hoverDelete, centeredFlex } from '@src/lib/mixins';
import newPluginPathImage from '@src/assets/images/installation_flow.png';
import HOCWithRouter from '@src/components/hoc/HOCWithRouter';
import * as pathLinks from '@src/routes/Fanp/pathLinks';
var styledContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  .main_content {\n    background: ", ";\n  }\n"], ["\n  .main_content {\n    background: ", ";\n  }\n"])), colors.background.light_purple);
var PathItemContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  border-bottom: 1px ridge;\n  align-items: center;\n  cursor: pointer;\n\n  p {\n    margin: 0; /* Overwrite p style, the style of p has been changed for some reason(maybe bootstrap?) */\n  }\n\n  .plugin-path-delete-btn {\n    width: 40px;\n    ", ";\n    ", ";\n  }\n\n  .color-selected {\n    color: ", ";\n    font-weight: 500;\n  }\n"], ["\n  display: flex;\n  border-bottom: 1px ridge;\n  align-items: center;\n  cursor: pointer;\n\n  p {\n    margin: 0; /* Overwrite p style, the style of p has been changed for some reason(maybe bootstrap?) */\n  }\n\n  .plugin-path-delete-btn {\n    width: 40px;\n    ", ";\n    ", ";\n  }\n\n  .color-selected {\n    color: ", ";\n    font-weight: 500;\n  }\n"])), centeredFlex, hoverDelete, colors.default_text);
var List = styled.p(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  width: 90%;\n  background-color: ", ";\n  padding: 20px 20px 20px 20px;\n  word-break: break-all;\n  text-align: left;\n\n  &:hover {\n    background: ", ";\n  }\n"], ["\n  width: 90%;\n  background-color: ", ";\n  padding: 20px 20px 20px 20px;\n  word-break: break-all;\n  text-align: left;\n\n  &:hover {\n    background: ", ";\n  }\n"])), colors.white, colors.background.off_white_background);
var PluginInfo = /** @class */ (function (_super) {
    __extends(PluginInfo, _super);
    function PluginInfo() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            plugin: [],
            pluginEvents: [],
            pluginPaths: [],
            pathModalOpen: false,
            newUrlRegex: '',
            errorMsg: '',
            isLoading: true,
        };
        _this.togglePathModal = function () {
            _this.setState({ pathModalOpen: !_this.state.pathModalOpen });
        };
        _this.handleUrlRegex = function (e) {
            try {
                if (_this.state.errorMsg)
                    _this.setState({ errorMsg: '' });
                new RegExp(e.currentTarget.value);
            }
            catch (e) {
                _this.setState({ errorMsg: '間違った正規表現です' });
            }
            _this.setState({ newUrlRegex: e.currentTarget.value });
        };
        _this.fetchPluginPath = function () {
            var _a = _this.props.params, accountId = _a.accountId, pluginId = _a.pluginId;
            var newUrlRegex = _this.state.newUrlRegex;
            var url = endpoints.plugins.components.paths.collection({ pluginId: pluginId });
            var body = { url_regex: newUrlRegex };
            fetch(url, makeFetchInit('POST', body))
                .then(function (response) {
                if (!response.ok)
                    throw new Error();
                return response.json();
            })
                .then(function (pluginPath) {
                _this.setState({
                    pluginPaths: __spreadArray([pluginPath], __read(_this.state.pluginPaths)),
                    pathModalOpen: false,
                    isLoading: false,
                });
                _this.props.browserHistory.push(pathLinks.dashboardAccounts_accountId_Plugins_pluginId_Paths_pathId({
                    accountId: parseInt(accountId),
                    pluginId: parseInt(pluginId),
                    pathId: pluginPath.id,
                }));
            })
                .catch(function () { return handleAPIErrors('同じパターンは作成できません'); });
        };
        _this.fetchDeletePluginPath = function (pathId) {
            if (confirm('URLパターンを削除しますか?')) {
                var _a = _this.props.params, accountId_1 = _a.accountId, pluginId_1 = _a.pluginId;
                var url = endpoints.plugins.components.paths.member({
                    pluginId: pluginId_1,
                    pathId: pathId,
                });
                fetch(url, makeFetchInit('DELETE'))
                    .then(function (response) {
                    if (!response.ok)
                        throw new Error();
                })
                    .then(function () {
                    var afterDeletePaths = _this.state.pluginPaths.filter(function (path) { return path.id !== pathId; });
                    _this.setState({
                        pluginPaths: afterDeletePaths,
                        isLoading: false,
                    });
                    if (afterDeletePaths.length) {
                        var firstPathId = afterDeletePaths[0].id;
                        _this.props.browserHistory.push(pathLinks.dashboardAccounts_accountId_Plugins_pluginId_Paths_pathId({
                            accountId: parseInt(accountId_1),
                            pluginId: parseInt(pluginId_1),
                            pathId: firstPathId,
                        }));
                    }
                    else {
                        _this.props.browserHistory.push(pathLinks.dashboardAccounts_accountId_Plugins_pluginId_({
                            accountId: parseInt(accountId_1),
                            pluginId: parseInt(pluginId_1),
                        }));
                    }
                })
                    .catch(function () { return handleAPIErrors('削除できませんでした'); });
            }
        };
        _this.renderPathItem = function (item) {
            var _a = _this.props.params, accountId = _a.accountId, pluginId = _a.pluginId, pathId = _a.pathId;
            return (React.createElement(PathItemContainer, { key: "path-item-container-" + item.id },
                React.createElement(List, { onClick: function () {
                        pathLinks.dashboardAccounts_accountId_Plugins_pluginId_Paths_pathId({
                            accountId: parseInt(accountId),
                            pluginId: parseInt(pluginId),
                            pathId: item.id,
                        });
                    }, key: "path-item-list-" + item.id, className: "" + (parseInt(pathId) === item.id ? 'color-selected' : '') }, item.url_regex),
                React.createElement("div", { className: "plugin-path-delete-btn" },
                    React.createElement("span", { className: "adicon_delete icon_style", onClick: function () { return _this.fetchDeletePluginPath(item.id); } }))));
        };
        return _this;
    }
    PluginInfo.prototype.componentDidMount = function () {
        var _this = this;
        var pluginId = this.props.params.pluginId;
        var url = endpoints.plugins.components.paths.collection({ pluginId: pluginId });
        fetch(url, makeFetchInit('GET'))
            .then(function (response) { return response.json(); })
            .then(function (_a) {
            var paths = _a.paths;
            if (!paths)
                throw new Error('Can not get paths.');
            _this.setState({
                pluginPaths: paths,
                pathModalOpen: false,
                isLoading: false,
            });
        })
            .catch(handleAPIErrors);
    };
    PluginInfo.prototype.render = function () {
        var _this = this;
        var _a = this.state, pluginPaths = _a.pluginPaths, pathModalOpen = _a.pathModalOpen, newUrlRegex = _a.newUrlRegex, errorMsg = _a.errorMsg, isLoading = _a.isLoading;
        var children = this.props.children;
        var childrenWithProps = React.Children.map(children, function (child) {
            return React.cloneElement(child, {});
        });
        var areasPC = "\n      sideBar mainContent\n    ";
        return (React.createElement(React.Fragment, null,
            !isLoading && !pluginPaths.length ? (React.createElement(EmptySplash, { image: newPluginPathImage, text: "\u30D7\u30E9\u30B0\u30A4\u30F3\u304C\u767A\u706B\u3059\u308BURL\u30D1\u30BF\u30FC\u30F3\u3092\u4F5C\u6210\u3057\u3088\u3046", buttonText: "\u30D1\u30BF\u30FC\u30F3\u4F5C\u6210", onClick: function () { return _this.togglePathModal(); } })) : (React.createElement(Composition, { as: styledContainer, areas: areasPC, templateCols: "250px 1fr" }, function (_a) {
                var SideBar = _a.SideBar, MainContent = _a.MainContent;
                return (React.createElement(React.Fragment, null,
                    React.createElement(SideBar, null,
                        React.createElement(PluginSidebar, { text: "URL\u30D1\u30BF\u30FC\u30F3\u3092\u8FFD\u52A0", togglePathModal: _this.togglePathModal }, pluginPaths.map(_this.renderPathItem))),
                    React.createElement(MainContent, { className: "main_content" }, childrenWithProps)));
            })),
            pathModalOpen ? (React.createElement(PluginPathCreate, { value: newUrlRegex, onConfirm: this.fetchPluginPath, onCancel: this.togglePathModal, onChange: this.handleUrlRegex, errorMsg: errorMsg })) : null));
    };
    return PluginInfo;
}(Component));
export default HOCWithRouter(PluginInfo);
var templateObject_1, templateObject_2, templateObject_3;
