var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import styled from 'styled-components';
import { colors } from '@src/colors';
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  height: calc(100vh - 60px);\n  background: ", ";\n  display: flex;\n  flex-direction: column;\n"], ["\n  height: calc(100vh - 60px);\n  background: ", ";\n  display: flex;\n  flex-direction: column;\n"])), colors.background.content);
var PluginModals = function (props) { return (React.createElement(Container, null, React.Children.map(props.children, function (child) {
    return React.cloneElement(child, __assign({}, props));
}))); };
export default PluginModals;
var templateObject_1;
