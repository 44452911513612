var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { useState } from 'react';
export var initialNotify = {
    message: undefined,
    severity: 'success',
};
var useSnackbarNotify = function () {
    var _a = __read(useState(false), 2), open = _a[0], setOpen = _a[1];
    var _b = __read(useState(initialNotify), 2), notify = _b[0], setNotify = _b[1];
    var clearNotify = function () { return setOpen(false); };
    return {
        notify: notify,
        open: open,
        setOpen: setOpen,
        setNotify: setNotify,
        clearNotify: clearNotify,
    };
};
export default useSnackbarNotify;
