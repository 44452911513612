import "core-js/modules/es.array.concat";
// This method convert hex number to string with 2 charater (because line emoji
// is store on 2 charater)
// params hexNumber is hex code representing an emoji
// return a string with 2 charater
export var convertHexToEmoji = function convertHexToEmoji(hexNumber) {
  var offset = hexNumber - 0x10000; // eslint-disable-next-line no-bitwise

  var lead = 0xd800 + (offset >> 10); // eslint-disable-next-line no-bitwise

  var trail = 0xdc00 + (offset & 0x3ff);
  return "".concat(String.fromCharCode(lead)).concat(String.fromCharCode(trail));
};