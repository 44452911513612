var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import { grey, blue, navy } from '@zeals-co-ltd/washi-styles/lib/colors';
import { TextField } from '@zeals-co-ltd/washi-components';
// Used to search for a certain "item" in the scenario and block tabs
var SearchInput = styled(TextField)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  .MuiInputBase-root {\n    input {\n      &::placeholder {\n        color: ", ";\n        opacity: 1;\n      }\n    }\n    fieldset {\n      background-color: ", ";\n      border-color: ", ";\n    }\n  }\n  svg {\n    position: relative;\n    z-index: 1;\n  }\n  .MuiOutlinedInput-root:hover {\n    fieldset.MuiOutlinedInput-notchedOutline {\n      border-color: ", ";\n    }\n  }\n  input {\n    position: relative;\n    z-index: 1;\n    padding-left: 8px;\n    font-size: 14px;\n    line-height: 20px;\n  }\n"], ["\n  width: 100%;\n  .MuiInputBase-root {\n    input {\n      &::placeholder {\n        color: ", ";\n        opacity: 1;\n      }\n    }\n    fieldset {\n      background-color: ", ";\n      border-color: ", ";\n    }\n  }\n  svg {\n    position: relative;\n    z-index: 1;\n  }\n  .MuiOutlinedInput-root:hover {\n    fieldset.MuiOutlinedInput-notchedOutline {\n      border-color: ", ";\n    }\n  }\n  input {\n    position: relative;\n    z-index: 1;\n    padding-left: 8px;\n    font-size: 14px;\n    line-height: 20px;\n  }\n"])), navy[100], grey[100], grey[100], blue[500]);
export default SearchInput;
var templateObject_1;
