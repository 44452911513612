import React from 'react';
import { Composition } from 'atomic-layout';
import { HeaderAccount } from '@src/components/organisms/fanp';
import { useLocation } from 'react-router-dom';
var areas = "\n  header\n  body\n";
export default function AccountMain(_a) {
    var children = _a.children, router = _a.router, updateLoggedIn = _a.updateLoggedIn;
    var childrenWithProps = React.Children.map(children, function (child) {
        if (React.isValidElement(child))
            return React.cloneElement(child, { router: router });
        return null;
    });
    var location = useLocation();
    return (React.createElement(Composition, { height: "100vh", areas: areas, templateRows: "180px auto", templateRowsLg: "60px auto" }, function (_a) {
        var Header = _a.Header, Body = _a.Body;
        return (React.createElement(React.Fragment, null,
            React.createElement(Header, null,
                React.createElement(HeaderAccount, { router: router, pathName: location.pathname, updateLoggedIn: updateLoggedIn })),
            React.createElement(Body, null, childrenWithProps)));
    }));
}
