var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { colors } from '@src/colors';
import { messageTypes, tabTypes } from '@src/lib/app-constants';
import { ModalPortal, Input, CharacterCount, AutoScrollModalContainer, } from '@src/components/atoms';
import Modal from '@src/components/molecules/Modal';
import useActionFormValidator from '@src/components/hooks/useActionFormValidator';
import richMenuAreaModalSchema from '@src/lib/helpers/form_modal_schema/richMenuAreaModalSchema';
import ActionSelector from '@src/components/molecules/ActionSelector';
import { AlertModalContext } from '@src/components/molecules/AlertModal';
var ErrorMessages = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  text-align: left;\n  color: ", ";\n"], ["\n  text-align: left;\n  color: ", ";\n"])), colors.error);
var RichMenuAreaHead = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  .rich_menu_area_head_input {\n    width: 260px;\n  }\n"], ["\n  .rich_menu_area_head_input {\n    width: 260px;\n  }\n"])));
var ModalContainer = styled(Modal)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  .new-block {\n    display: ", ";\n  }\n"], ["\n  .new-block {\n    display: ", ";\n  }\n"])), function (props) { return (props.isDefaultRichMenu ? 'none' : ''); });
var RichMenuAreaModal = function (_a) {
    var rank = _a.rank, richMenuArea = _a.richMenuArea, richMenuAreaArr = _a.richMenuAreaArr, activeBlocks = _a.activeBlocks, createNewBlock = _a.createNewBlock, richMenuAreaUpdateHandler = _a.richMenuAreaUpdateHandler, chatbotIdParam = _a.chatbotIdParam, adScenarioIdParam = _a.adScenarioIdParam, rankParam = _a.rankParam, blockIdParam = _a.blockIdParam, chatTypeParam = _a.chatTypeParam, defaultMessageId = _a.defaultMessageId, platform = _a.platform, isDefaultRichMenu = _a.isDefaultRichMenu, intl = _a.intl, props = __rest(_a, ["rank", "richMenuArea", "richMenuAreaArr", "activeBlocks", "createNewBlock", "richMenuAreaUpdateHandler", "chatbotIdParam", "adScenarioIdParam", "rankParam", "blockIdParam", "chatTypeParam", "defaultMessageId", "platform", "isDefaultRichMenu", "intl"]);
    var handleModal = React.useContext(AlertModalContext).handleModal;
    var _b = useActionFormValidator({
        intl: intl,
        platform: platform,
        object: {
            name: richMenuArea === null || richMenuArea === void 0 ? void 0 : richMenuArea.label,
            postback_action: richMenuArea === null || richMenuArea === void 0 ? void 0 : richMenuArea.postback_action,
        },
        schema: richMenuAreaModalSchema(richMenuArea === null || richMenuArea === void 0 ? void 0 : richMenuArea.id, richMenuAreaArr),
    }), isValidating = _b.isValidating, values = _b.values, errors = _b.errors, touched = _b.touched, handleChange = _b.handleChange, handleChangePostbackAction = _b.handleChangePostbackAction, initActionSelectorValue = _b.initActionSelectorValue, actionSelectorOnChange = _b.actionSelectorOnChange;
    useEffect(function () { return handleChangePostbackAction(richMenuArea === null || richMenuArea === void 0 ? void 0 : richMenuArea.postback_action); }, [
        richMenuArea === null || richMenuArea === void 0 ? void 0 : richMenuArea.postback_action,
    ]);
    var ignoreEffects = [tabTypes.STOCK, tabTypes.SHARE];
    var handleNewBlockClick = function (blockName) {
        createNewBlock({
            richMenuAreaId: richMenuArea.id,
            name: blockName,
            chatbotId: chatbotIdParam,
            adScenarioId: adScenarioIdParam,
            blockId: blockIdParam,
            layer_rank: rankParam,
        }).catch(function (e) { return handleModal(e.message); });
        if (richMenuArea.url)
            richMenuAreaUpdateHandler({
                chatbotId: chatbotIdParam,
                scenarioId: adScenarioIdParam,
                richMenuId: richMenuArea.rich_menu_id,
                richMenuAreaId: richMenuArea.id,
                messageId: defaultMessageId,
                url: null,
                label: values.name,
                layer_rank: rank,
                blockId: blockIdParam,
            });
    };
    var handleBlockClick = function (_a) {
        var _b = _a.blockId, blockId = _b === void 0 ? null : _b, _c = _a.nextScenarioId, nextScenarioId = _c === void 0 ? null : _c;
        return richMenuAreaUpdateHandler({
            chatbotId: chatbotIdParam,
            scenarioId: adScenarioIdParam,
            richMenuId: richMenuArea.rich_menu_id,
            richMenuAreaId: richMenuArea.id,
            messageId: defaultMessageId,
            url: null,
            label: values.name,
            layer_rank: rank,
            blockId: blockId,
            nextScenarioId: nextScenarioId,
        });
    };
    var onConfirm = function () {
        var _a, _b, _c, _d;
        return richMenuAreaUpdateHandler({
            chatbotId: chatbotIdParam,
            scenarioId: adScenarioIdParam,
            richMenuId: richMenuArea.rich_menu_id,
            richMenuAreaId: richMenuArea.id,
            messageId: defaultMessageId,
            label: values.name,
            url: (_a = values.postback_action) === null || _a === void 0 ? void 0 : _a.url,
            nextScenarioId: (_b = richMenuArea === null || richMenuArea === void 0 ? void 0 : richMenuArea.postback_action) === null || _b === void 0 ? void 0 : _b.scenario_id,
            blockId: (_c = richMenuArea === null || richMenuArea === void 0 ? void 0 : richMenuArea.postback_action) === null || _c === void 0 ? void 0 : _c.block_id,
            layer_rank: rank,
            postback_action_id: (_d = richMenuArea === null || richMenuArea === void 0 ? void 0 : richMenuArea.postback_action) === null || _d === void 0 ? void 0 : _d.id,
        });
    };
    return (React.createElement(ModalPortal, null,
        React.createElement(AutoScrollModalContainer, null,
            React.createElement(ModalContainer, __assign({ isDefaultRichMenu: isDefaultRichMenu, "data-testid": "rich-menu-area-auto-scroll-modal" }, props, { type: "SELECT", text: intl.formatMessage({ id: "hmipwE", defaultMessage: "Area Settings" }), hasError: Boolean(Object.keys(errors).length), onConfirm: onConfirm }),
                React.createElement(RichMenuAreaHead, null,
                    React.createElement("div", { className: "message-wrapper primary-label" }, intl.formatMessage({ id: "753yX5", defaultMessage: "Label" })),
                    React.createElement(Input, { className: "rich_menu_area_head_input", placeholder: intl.formatMessage({ id: "hKMh1h", defaultMessage: "Please enter a Label for the Area" }), name: "name", value: values.name || '', hasError: Boolean(touched.name && errors.name), onChange: handleChange },
                        React.createElement(CharacterCount, { value: values.name, platform: platform, path: messageTypes.RICH_MENU + ".area.label" })),
                    touched.name && errors.name ? (React.createElement(ErrorMessages, { className: "message-wrapper" }, errors.name)) : null),
                React.createElement(ActionSelector, { initActionSelectorValue: initActionSelectorValue, onChange: actionSelectorOnChange({
                        newBlock: handleNewBlockClick,
                        selectBlock: function (blockId) { return handleBlockClick({ blockId: blockId }); },
                        disconnectBlock: function () { return handleBlockClick({ blockId: null }); },
                        selectScenario: function (nextScenarioId) {
                            return handleBlockClick({ nextScenarioId: nextScenarioId });
                        },
                        disconnectScenario: function () {
                            return handleBlockClick({ nextScenarioId: null });
                        },
                    }), label: values.name || '', connectedObject: richMenuArea, blocks: activeBlocks, errors: Object(errors).postback_action, ignoreEffects: ignoreEffects, isValidating: isValidating })))));
};
export default RichMenuAreaModal;
var templateObject_1, templateObject_2, templateObject_3;
