var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import styled from 'styled-components';
import { object, string } from 'yup';
import isEqual from 'lodash/isEqual';
import { colors } from '@src/colors';
import { addMaxLength } from '@src/lib/helpers';
import { ModalPortal } from '@src/components/atoms';
import Modal from '@src/components/molecules/Modal';
import modalTypes from '@src/lib/modalTypes';
import { setUserTagGroup, fetchTargetUserTagsGroup, createUserTagGroup, createUserTag, setSelectedUserTagGroupId, } from '@src/redux/fanp/actions/ad-scenario';
import { createQuickReplyWithUserTags } from '@src/redux/fanp/actions/quickReply';
import get from 'lodash/get';
import limitations from '@src/lib/platforms/limitations';
import UserTags from './UserTags';
import UserTagGroups from './UserTagGroups';
var userTagSchema = function (intl, platform) {
    return object({
        name: addMaxLength(string().required('ラベル名が必須です。'), intl, 'postback_action.label', platform),
    });
};
var QuickFromUserTagGroupContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  margin-bottom: 40px;\n  width: 100%;\n  height: calc(100vh - 326px);\n  &:nth-child(1) {\n    flex: 1;\n  }\n  &:nth-child(2) {\n    flex: 2;\n  }\n  .error-message {\n    display: flex;\n    align-items: center;\n    margin-top: 20px;\n    font-weight: bold;\n    color: ", ";\n  }\n"], ["\n  display: flex;\n  margin-bottom: 40px;\n  width: 100%;\n  height: calc(100vh - 326px);\n  &:nth-child(1) {\n    flex: 1;\n  }\n  &:nth-child(2) {\n    flex: 2;\n  }\n  .error-message {\n    display: flex;\n    align-items: center;\n    margin-top: 20px;\n    font-weight: bold;\n    color: ", ";\n  }\n"])), colors.error);
var UserTagGroupModalWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  .user-tag-group-modal {\n    overflow: hidden;\n  }\n"], ["\n  .user-tag-group-modal {\n    overflow: hidden;\n  }\n"])));
// Export this clas for testing, because it wrapped by intl and connect so
// can not access state, when refactor to function component, may be it no
// need to export here
var QuickFromUserTagGroupModal = /** @class */ (function (_super) {
    __extends(QuickFromUserTagGroupModal, _super);
    function QuickFromUserTagGroupModal(props) {
        var _this = _super.call(this, props) || this;
        _this.toggleSelectedTagId = function (selectedUserTag) {
            var selectedUserTags = _this.state.selectedUserTags;
            var hasSelectedUserTag = function (userTag) {
                return selectedUserTags.map(function (_a) {
                    var id = _a.id;
                    return id;
                }).includes(userTag.id);
            };
            if (hasSelectedUserTag(selectedUserTag)) {
                var newSelectedUserTags = selectedUserTags.filter(function (userTag) { return userTag.id !== selectedUserTag.id; });
                return _this.setState({ selectedUserTags: newSelectedUserTags });
            }
            return _this.setState({
                selectedUserTags: __spreadArray(__spreadArray([], __read(selectedUserTags)), [
                    __assign(__assign({}, selectedUserTag), { name: selectedUserTag.label }),
                ]),
            });
        };
        _this.toggleSelectAllTags = function () {
            var userTagGroup = _this.props.userTagGroup;
            var userTags = userTagGroup.user_tags;
            if (!userTags.length)
                return;
            var selectedUserTags = _this.state.selectedUserTags;
            var newSelectedTags = selectedUserTags.length
                ? []
                : userTags.map(function (userTag) { return (__assign(__assign({}, userTag), { name: userTag.label })); });
            _this.setState({ selectedUserTags: newSelectedTags });
        };
        _this.clearSelectedTags = function () { return _this.setState({ selectedUserTags: [] }); };
        _this.searchTagGroup = function (e) { return _this.setState({ searchTerm: e.target.value }); };
        _this.onQuickReplyLabelChange = function (e, userTag) {
            var selectedUserTags = _this.state.selectedUserTags;
            var newTags = selectedUserTags.map(function (tag) {
                return tag.id === userTag.id ? __assign(__assign({}, tag), { name: e.target.value }) : tag;
            });
            _this.setState({ selectedUserTags: newTags });
        };
        _this.getMaxQuickReplyChoice = function () {
            var platform = _this.props.platform;
            return get(limitations[platform], 'MAX_QUICK_REPLY_CHOICE', 0);
        };
        _this.handleConfirmTags = function () {
            var selectedUserTags = _this.state.selectedUserTags;
            var _a = _this.props, toggleQuickFromUserTagGroupModal = _a.toggleQuickFromUserTagGroupModal, selectedUserTagGroupId = _a.selectedUserTagGroupId;
            var maxQuickReplyChoice = _this.getMaxQuickReplyChoice();
            if (!selectedUserTagGroupId) {
                alert('タググループを選択してください。');
                toggleQuickFromUserTagGroupModal(true);
            }
            else if (!selectedUserTags.length) {
                alert('タグを選択してください。');
                toggleQuickFromUserTagGroupModal(true);
            }
            else if (selectedUserTags.length > maxQuickReplyChoice) {
                alert(maxQuickReplyChoice + "\u500B\u4EE5\u4E0A\u306E\u30BF\u30B0\u306F\u9078\u629E\u3067\u304D\u307E\u305B\u3093\u3002");
                toggleQuickFromUserTagGroupModal(true);
            }
            else {
                _this.sendUserTags();
            }
        };
        _this.sendUserTags = function () {
            var _a = _this.props, blockIdParam = _a.blockIdParam, chatbotIdParam = _a.chatbotIdParam, adScenarioIdParam = _a.adScenarioIdParam, createQuickReplyWithUserTags = _a.createQuickReplyWithUserTags, onCancel = _a.onCancel, selectedUserTagGroupId = _a.selectedUserTagGroupId;
            var selectedUserTags = _this.state.selectedUserTags;
            if (selectedUserTagGroupId && selectedUserTags.length) {
                createQuickReplyWithUserTags({
                    chatbotId: chatbotIdParam,
                    scenarioId: adScenarioIdParam,
                    blockId: blockIdParam,
                    userTagGroupId: selectedUserTagGroupId,
                    userTags: selectedUserTags,
                });
                _this.clearSelectedTags();
                onCancel();
            }
        };
        _this.validateSelectedUserTags = function (callback) {
            var _a = _this.props, intl = _a.intl, platform = _a.platform;
            var selectedUserTags = _this.state.selectedUserTags;
            // This step create schema for metaobj of list user tag, key is id
            var userTagsSchema = function (intl, platform) {
                return object(selectedUserTags.reduce(function (schemaObject, userTag) {
                    var _a;
                    return __assign(__assign({}, schemaObject), (_a = {}, _a[userTag.id] = userTagSchema(intl, platform), _a));
                }, {}));
            };
            userTagsSchema(intl, platform)
                .validate(selectedUserTags.reduce(function (userTags, userTag) {
                var _a;
                return __assign(__assign({}, userTags), (_a = {}, _a[userTag.id] = userTag, _a));
            }, {}), { abortEarly: false })
                .then(function () { return callback([]); })
                .catch(function (e) {
                if (!e.inner)
                    return callback([]);
                var errors = [];
                e.inner.forEach(function (errorDetail) {
                    var id = Number(errorDetail.path.match(/\d+/)[0]);
                    errors[id] = errorDetail.message;
                });
                return callback(errors);
            });
        };
        _this.handleCreateTagGroup = function () {
            var _a = _this.props, chatbotIdParam = _a.chatbotIdParam, createUserTagGroup = _a.createUserTagGroup;
            createUserTagGroup(_this.state.searchTerm, chatbotIdParam);
            _this.setState({ searchTerm: '' });
        };
        _this.handleEnterKeyDown = function (e) {
            if (e.keyCode === 13) {
                _this.handleCreateTagGroup();
            }
        };
        _this.state = {
            selectedUserTags: [],
            selectedUserTagErrors: [],
            searchTerm: '',
        };
        return _this;
    }
    QuickFromUserTagGroupModal.prototype.componentDidUpdate = function (_, prevState) {
        var _this = this;
        if (isEqual(this.state.selectedUserTags, prevState.selectedUserTags))
            return;
        this.validateSelectedUserTags(function (errors) {
            return _this.setState({ selectedUserTagErrors: errors });
        });
    };
    QuickFromUserTagGroupModal.prototype.render = function () {
        var _a = this.props, userTagGroup = _a.userTagGroup, userTagGroups = _a.userTagGroups, fetchTargetUserTagsGroup = _a.fetchTargetUserTagsGroup, chatbotIdParam = _a.chatbotIdParam, createUserTagGroup = _a.createUserTagGroup, createUserTag = _a.createUserTag, setSelectedUserTagGroupId = _a.setSelectedUserTagGroupId, selectedUserTagGroupId = _a.selectedUserTagGroupId, intl = _a.intl, platform = _a.platform;
        var _b = this.state, selectedUserTags = _b.selectedUserTags, selectedUserTagErrors = _b.selectedUserTagErrors, searchTerm = _b.searchTerm;
        var maxQuickReplyChoice = this.getMaxQuickReplyChoice();
        var isOverLimit = selectedUserTags.length > maxQuickReplyChoice;
        var isNotSelected = !selectedUserTags.length;
        var filteredUserTagGroups = searchTerm
            ? userTagGroups.filter(function (aq) { return aq.label && aq.label.includes(searchTerm); })
            : userTagGroups;
        var hasError = Boolean(selectedUserTagErrors.length) || isOverLimit || isNotSelected;
        return (React.createElement(ModalPortal, null,
            React.createElement(UserTagGroupModalWrapper, null,
                React.createElement(Modal, __assign({ "data-testid": "QuickFromUserTagGroupModal", className: "user-tag-group-modal", type: modalTypes.SELECT, width: "80%", minWidth: "800px", maxWidth: "1000px", text: intl.formatMessage({ id: "w0TQjt", defaultMessage: "Create a Quick Reply with Tag" }) }, this.props, { onConfirm: this.handleConfirmTags, hasError: hasError }),
                    React.createElement(QuickFromUserTagGroupContainer, null,
                        React.createElement(UserTagGroups, { deselectOtherTags: this.clearSelectedTags, userTagGroups: filteredUserTagGroups, fetchTargetUserTagsGroup: fetchTargetUserTagsGroup, chatbotIdParam: chatbotIdParam, selectedUserTagGroupId: selectedUserTagGroupId, setSelectedUserTagGroupId: setSelectedUserTagGroupId, searchTagGroup: this.searchTagGroup, searchTerm: searchTerm, createUserTagGroup: createUserTagGroup, handleCreateTagGroup: this.handleCreateTagGroup, handleEnterKeyDown: this.handleEnterKeyDown }),
                        selectedUserTagGroupId &&
                            userTagGroups.some(function (_a) {
                                var id = _a.id;
                                return id === selectedUserTagGroupId;
                            }) ? (React.createElement(UserTags, { userTags: userTagGroup.user_tags, chatbotIdParam: chatbotIdParam, selectedUserTagGroupId: selectedUserTagGroupId, selectedUserTags: selectedUserTags, selectedUserTagErrors: selectedUserTagErrors, toggleSelectedTagId: this.toggleSelectedTagId, toggleSelectAllTags: this.toggleSelectAllTags, createUserTag: createUserTag, onQuickReplyLabelChange: this.onQuickReplyLabelChange, maxQuickReplyChoice: maxQuickReplyChoice, platform: platform })) : (React.createElement("div", { className: "error-message", "data-testid": "QuickFromUserTagGroupModal-ErrorMessage" },
                            React.createElement("div", null, intl.formatMessage({ id: "gbMyDG", defaultMessage: "\u2190 First, search a Tag Group and then Create a Quick Reply with the selected Tags" })))))))));
    };
    return QuickFromUserTagGroupModal;
}(Component));
export { QuickFromUserTagGroupModal };
var mapStateToProps = function (state) { return ({
    userTagGroups: state.adScenario.present.userTagGroups,
    userTagGroup: state.adScenario.present.userTagGroup,
}); };
export default connect(mapStateToProps, {
    setUserTagGroup: setUserTagGroup,
    fetchTargetUserTagsGroup: fetchTargetUserTagsGroup,
    createQuickReplyWithUserTags: createQuickReplyWithUserTags,
    createUserTagGroup: createUserTagGroup,
    createUserTag: createUserTag,
    setSelectedUserTagGroupId: setSelectedUserTagGroupId,
})(injectIntl(QuickFromUserTagGroupModal));
var templateObject_1, templateObject_2;
