var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState } from 'react';
import styled from 'styled-components';
import { colors } from '@src/colors';
import Validator from '@src/components/hoc/Validator';
import { EmojiSelector, CharacterCount } from '@src/components/atoms';
import { article as articleNameGenerator } from '@src/lib/messageIdGenerator';
import { ArticleName } from '@src/typings/enums';
import { setWidthHeight } from '@src/lib/mixins';
import { messageTypes } from '@src/lib/app-constants';
import { injectIntl } from 'react-intl';
var ValidatedTextArea = styled.textarea(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  border: 1px solid transparent;\n  ", ";\n  width: ", ";\n  font-size: ", ";\n"], ["\n  border: 1px solid transparent;\n  ", ";\n  width: ", ";\n  font-size: ", ";\n"])), setWidthHeight, function (props) { return (props.width ? props.width + "px" : '100%'); }, function (props) { return (props.fontSize ? props.fontSize + "px" : '12px'); });
var ValidatedField = styled(ValidatedTextArea).attrs({
    as: 'input',
})(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  border: 1px solid\n    ", " !important;\n  padding: 4px 8px;\n  transition: 0.3s;\n  &:hover {\n    border: 1px solid\n      ", " !important;\n  }\n"], ["\n  border: 1px solid\n    ", " !important;\n  padding: 4px 8px;\n  transition: 0.3s;\n  &:hover {\n    border: 1px solid\n      ", " !important;\n  }\n"])), function (props) { return (props.hasError ? colors.error : 'transparent'); }, function (props) { return (props.hasError ? colors.error : colors.border.default); });
var TextAreaWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  border-radius: 3px;\n  border: 1px solid\n    ", " !important;\n  padding: ", ";\n  margin: ", ";\n  transition: 0.3s;\n  &:hover {\n    border: 1px solid\n      ", " !important;\n  }\n  .emoji-selector {\n    text-align: right;\n  }\n"], ["\n  border-radius: 3px;\n  border: 1px solid\n    ", " !important;\n  padding: ", ";\n  margin: ", ";\n  transition: 0.3s;\n  &:hover {\n    border: 1px solid\n      ", " !important;\n  }\n  .emoji-selector {\n    text-align: right;\n  }\n"])), function (props) { return (props.hasError ? colors.error : 'transparent'); }, function (props) { return props.padding || 'unset'; }, function (props) { return props.margin || 'unset'; }, function (props) { return (props.hasError ? colors.error : colors.border.default); });
var Article = function (_a) {
    var children = _a.children, rest = __rest(_a, ["children"]);
    return React.Children.map(children, function (child) {
        return React.cloneElement(child, __assign({}, rest));
    });
};
var Title = function (_a) {
    var article = _a.article, schema = _a.schema, readOnly = _a.readOnly, height = _a.height, onChange = _a.onChange, platform = _a.platform, intl = _a.intl;
    var _b = __read(useState(article.title.length), 2), cursorPosition = _b[0], setCursorPosition = _b[1];
    var _c = __read(useState(article.title), 2), title = _c[0], setTitle = _c[1];
    return readOnly ? (React.createElement(ValidatedField, { name: "title", placeholder: "title", value: article.title || '', readOnly: readOnly })) : (React.createElement(Validator, { name: articleNameGenerator(article, ArticleName.Title), schema: schema, initialValue: article.title }, function (_a) {
        var errors = _a.errors, getValidatorProps = _a.getValidatorProps, touched = _a.touched;
        return (React.createElement(React.Fragment, null,
            React.createElement(TextAreaWrapper, { hasError: touched && errors.length > 0, padding: "4px 8px" },
                React.createElement(ValidatedTextArea, __assign({ name: ArticleName.Title, placeholder: intl.formatMessage({ id: "9a9+ww", defaultMessage: "Title" }), hasError: touched && errors.length > 0 }, getValidatorProps({
                    onChange: readOnly
                        ? null
                        : function (e) {
                            if (onChange)
                                onChange(e);
                            setTitle(e.target.value);
                        },
                }), { height: height, fontSize: 14, value: title, readOnly: readOnly, onBlur: function (e) { return setCursorPosition(e.target.selectionStart); } })),
                React.createElement(EmojiSelector, { onChange: function (value, name, emoji) {
                        if (readOnly)
                            return;
                        onChange({ target: { value: value, name: name } });
                        setTitle(value);
                        setCursorPosition(cursorPosition + emoji.native.length);
                    }, text: article.title, name: ArticleName.Title, cursorPosition: cursorPosition, className: "emoji-selector" })),
            React.createElement(CharacterCount, { value: article.title, platform: platform, path: messageTypes.GENERIC + ".title" }, touched && errors.length ? (React.createElement("div", { className: "message-wrapper error" }, errors.join(', '))) : null)));
    }));
};
var Subtitle = function (_a) {
    var article = _a.article, schema = _a.schema, readOnly = _a.readOnly, onChange = _a.onChange, platform = _a.platform, intl = _a.intl;
    var _b = __read(useState(article.subtitle.length), 2), cursorPosition = _b[0], setCursorPosition = _b[1];
    var _c = __read(useState(article.subtitle), 2), subtitle = _c[0], setSubtitle = _c[1];
    return readOnly ? (React.createElement(ValidatedField, { name: ArticleName.Subtitle, placeholder: "subtitle", value: article.subtitle || '', readOnly: readOnly })) : (React.createElement(Validator, { name: articleNameGenerator(article, ArticleName.Subtitle), schema: schema, initialValue: article.subtitle }, function (_a) {
        var errors = _a.errors, getValidatorProps = _a.getValidatorProps, touched = _a.touched;
        return (React.createElement(React.Fragment, null,
            React.createElement(TextAreaWrapper, { hasError: touched && errors.length > 0, padding: "4px 8px" },
                React.createElement(ValidatedTextArea, __assign({ name: ArticleName.Subtitle, placeholder: intl.formatMessage({ id: "DJvCG/", defaultMessage: "Subtitle" }), value: subtitle.replace('ㅤ', ''), hasError: touched && errors.length > 0, readOnly: readOnly }, getValidatorProps({
                    onChange: readOnly
                        ? null
                        : function (e) {
                            if (onChange)
                                onChange(e);
                            setSubtitle(e.target.value);
                        },
                }), { onBlur: function (e) {
                        setCursorPosition(e.target.selectionStart);
                    } })),
                React.createElement(EmojiSelector, { onChange: function (value, name, emoji) {
                        if (readOnly)
                            return;
                        onChange({ target: { value: value, name: name } });
                        setSubtitle(value);
                        setCursorPosition(cursorPosition + emoji.native.length);
                    }, text: article.subtitle, name: ArticleName.Subtitle, className: "emoji-selector", cursorPosition: cursorPosition })),
            React.createElement(CharacterCount, { value: article.subtitle.replace('ㅤ', ''), platform: platform, path: messageTypes.GENERIC + ".subtitle" }, touched && errors.length ? (React.createElement("div", { className: "message-wrapper error" }, errors.join(', '))) : null)));
    }));
};
var Url = function (_a) {
    var article = _a.article, readOnly = _a.readOnly, onChange = _a.onChange, schema = _a.schema, platform = _a.platform;
    var _b = __read(useState(article.url), 2), url = _b[0], setUrl = _b[1];
    return (React.createElement(Validator, { name: articleNameGenerator(article, ArticleName.Url), schema: schema, initialValue: article.url, attrName: ArticleName.Url, onValid: function (e) {
            if (url !== article.url && onChange)
                onChange(e);
        } }, function (_a) {
        var errors = _a.errors, getValidatorProps = _a.getValidatorProps, touched = _a.touched;
        return (React.createElement(React.Fragment, null,
            React.createElement(ValidatedField, __assign({ name: ArticleName.Url, placeholder: "URL", hasError: touched && errors.length > 0 }, getValidatorProps({
                onChange: readOnly
                    ? null
                    : function (e) {
                        setUrl(e.target.value);
                    },
            }), { value: url || '', readOnly: readOnly })),
            React.createElement(CharacterCount, { value: article.url || '', platform: platform, path: messageTypes.GENERIC + ".url" }, touched && errors.length ? (React.createElement("div", { className: "message-wrapper error" }, errors.join(', '))) : null)));
    }));
};
Article.Title = injectIntl(Title);
Article.Subtitle = injectIntl(Subtitle);
Article.Url = Url;
export default Article;
var templateObject_1, templateObject_2, templateObject_3;
