var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import { handleAPIErrors } from '@src/lib/helpers';
import endpoints from '@src/endpoints';
import { defaultAdScenariosQueryParams, } from '@src/lib/adHelpers';
import { adScenariosNormalizer } from '@src/lib/dataNormalizer';
import { adScenario } from '@src/redux/fanp/constants';
import { updateIsFetching } from '@src/redux/fanp/actions/application';
import { setAdScenariosFetching, setScenariosList, setPaginatedScenarioIds, setAdScenarios, setAdScenario, setBlockMessages, setPrevBlocks, } from '@src/redux/fanp/actions/ad-scenario/setActions';
import arrToObj from '@src/lib/helpers/arrToObj';
import * as blockApi from '@src/services/api/block';
import * as scenarioApi from '@src/services/api/scenario';
import * as userTagApi from '@src/services/api/userTag';
import * as pathLinks from '@src/routes/Fanp/pathLinks';
var SET_ATTRIBUTE_QUESTIONS = adScenario.SET_ATTRIBUTE_QUESTIONS, SET_USER_TAG_GROUPS = adScenario.SET_USER_TAG_GROUPS, SET_TARGET_USER_TAG_GROUP = adScenario.SET_TARGET_USER_TAG_GROUP, SET_SCENARIO_TEMPLATES = adScenario.SET_SCENARIO_TEMPLATES;
export var setAttributeQuestions = function (attributeQuestions) { return ({
    type: SET_ATTRIBUTE_QUESTIONS,
    attributeQuestions: attributeQuestions,
}); };
export var setUserTagGroups = function (userTagGroups) { return ({
    type: SET_USER_TAG_GROUPS,
    userTagGroups: userTagGroups,
}); };
export var setUserTagGroup = function (userTagGroup) { return ({
    type: SET_TARGET_USER_TAG_GROUP,
    userTagGroup: userTagGroup,
}); };
export var setScenarioTemplates = function (scenarioTemplates) { return ({
    type: SET_SCENARIO_TEMPLATES,
    scenarioTemplates: scenarioTemplates,
}); };
// THUNKS
export var fetchUserTagGroups = function (chatbotId) { return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
    var userTagGroups, e_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, userTagApi.getUserTagGroups(chatbotId)];
            case 1:
                userTagGroups = _a.sent();
                dispatch(setUserTagGroups(userTagGroups));
                return [3 /*break*/, 3];
            case 2:
                e_1 = _a.sent();
                handleAPIErrors(e_1.message);
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); }; };
export var fetchTargetUserTagsGroup = function (chatbotId, userTagGroupId) { return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
    var userTagGroup, e_2;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, userTagApi.getUserTags(chatbotId, userTagGroupId)];
            case 1:
                userTagGroup = _a.sent();
                dispatch(setUserTagGroup(userTagGroup));
                return [3 /*break*/, 3];
            case 2:
                e_2 = _a.sent();
                handleAPIErrors(e_2.message);
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); }; };
export var fetchScenarioTemplates = function () { return function (dispatch) {
    return fetch(endpoints.scenarioTemplates.index())
        .then(function (res) { return res.json(); })
        .then(function (scenarioTemplates) {
        return dispatch(setScenarioTemplates(scenarioTemplates));
    })
        .catch(handleAPIErrors);
}; };
export function fetchAdScenario(_a) {
    var _this = this;
    var chatbotId = _a.chatbotId, adScenarioId = _a.adScenarioId;
    return function (dispatch) { return __awaiter(_this, void 0, void 0, function () {
        var scenario;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, scenarioApi.getScenario(chatbotId, adScenarioId)];
                case 1:
                    scenario = _a.sent();
                    dispatch(setAdScenario(scenario));
                    return [2 /*return*/, scenario];
            }
        });
    }); };
}
/**
 * If a scenario with id adScenarioId is not in fetchedScenarios,
 * it will fetch the individual scenario and return a merged array.
 *
 * @param fetchedScenarios - existing scenarios
 * @param chatbotId - chatbotid
 * @param adScenarioId - scenario to fetch if missing
 * @returns Either the original fetchedScenarios array or a merged list containing
 * the missing scenario
 *
 */
var fetchSelectedScenarioIfMissing = function (fetchedScenarios, adScenarioId, chatbotId) { return __awaiter(void 0, void 0, Promise, function () {
    var updatedScenarios, scenario;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                updatedScenarios = __spreadArray([], __read(fetchedScenarios));
                if (!!fetchedScenarios.some(function (s) { return Number(s.id) === Number(adScenarioId); })) return [3 /*break*/, 2];
                return [4 /*yield*/, scenarioApi.getScenario(chatbotId, adScenarioId)];
            case 1:
                scenario = _a.sent();
                if (scenario) {
                    updatedScenarios.unshift(scenario);
                }
                _a.label = 2;
            case 2: return [2 /*return*/, updatedScenarios];
        }
    });
}); };
export var fetchAdScenarios = function (_a, browserHistory) {
    var chatTypeParam = _a.chatTypeParam, chatbotId = _a.chatbotId, adScenarioId = _a.adScenarioId, _b = _a.pushUrl, pushUrl = _b === void 0 ? true : _b, // optional, will push URL if necessary
    _c = _a.preventScenarioDeliveryUpdate, // optional, will push URL if necessary
    preventScenarioDeliveryUpdate = _c === void 0 ? false : _c, // optional, will prevent updates of scenario delivery
    _d = _a.search, // optional, will prevent updates of scenario delivery
    search = _d === void 0 ? defaultAdScenariosQueryParams.search : _d, _e = _a.page, page = _e === void 0 ? defaultAdScenariosQueryParams.page : _e, _f = _a.per, per = _f === void 0 ? defaultAdScenariosQueryParams.per : _f, location = _a.location;
    return function (dispatch) { return __awaiter(void 0, void 0, Promise, function () {
        var scenarioList, scenariosObj, _a, scenarios, totalScenarios, dataWithSelectedScenario, normalizedData, scenarios_1, layers, adScenarioId_1, layerId, currentLayer, locationSearch, adUrl, isTesting;
        var _b, _c, _d;
        return __generator(this, function (_e) {
            switch (_e.label) {
                case 0:
                    dispatch(setAdScenariosFetching(true));
                    return [4 /*yield*/, scenarioApi.getScenarios(chatbotId)];
                case 1:
                    scenarioList = _e.sent();
                    scenariosObj = arrToObj(scenarioList.filter(function (s) { return s.chat_type !== 'plain'; }));
                    dispatch(setScenariosList(scenariosObj));
                    return [4 /*yield*/, scenarioApi.getScenariosPaginated(chatbotId, {
                            search: search,
                            page: page,
                            per: per,
                            chatType: chatTypeParam,
                        })];
                case 2:
                    _a = _e.sent(), scenarios = _a.scenarios, totalScenarios = _a.totalScenarios;
                    dataWithSelectedScenario = scenarios;
                    if (!(adScenarioId && !search)) return [3 /*break*/, 4];
                    return [4 /*yield*/, fetchSelectedScenarioIfMissing(scenarios, adScenarioId, chatbotId)];
                case 3:
                    dataWithSelectedScenario = _e.sent();
                    _e.label = 4;
                case 4:
                    if (!adScenarioId && dataWithSelectedScenario.length > 0 && pushUrl) {
                        normalizedData = adScenariosNormalizer(dataWithSelectedScenario);
                        scenarios_1 = normalizedData.scenarios, layers = normalizedData.layers;
                        adScenarioId_1 = (_b = Object.values(scenarios_1).slice(-1)[0]) === null || _b === void 0 ? void 0 : _b.id;
                        layerId = (_c = Object.values(scenarios_1).slice(-1)[0]) === null || _c === void 0 ? void 0 : _c.layers[0];
                        currentLayer = layers === null || layers === void 0 ? void 0 : layers[layerId];
                        locationSearch = search || '';
                        adUrl = pathLinks.dashboardAd_chatTypeParam_Chatbots_chatbotId_Scenarios_scenarioId_Layers_layerId_Blocks_blockId_Rank_rank_({
                            chatTypeParam: chatTypeParam,
                            chatbotId: chatbotId,
                            layerId: layerId,
                            rank: currentLayer === null || currentLayer === void 0 ? void 0 : currentLayer.rank,
                            blockId: (_d = currentLayer === null || currentLayer === void 0 ? void 0 : currentLayer.blocks) === null || _d === void 0 ? void 0 : _d[0],
                            scenarioId: adScenarioId_1,
                        });
                        isTesting = typeof jest !== 'undefined';
                        if (!isTesting && browserHistory) {
                            browserHistory.push(adUrl + "?" + locationSearch);
                        }
                    }
                    dispatch(setAdScenarios(dataWithSelectedScenario, preventScenarioDeliveryUpdate));
                    dispatch(setPaginatedScenarioIds({
                        ids: dataWithSelectedScenario.map(function (s) { return s.id; }),
                        totalScenarios: totalScenarios,
                        search: search,
                        page: page,
                        per: per,
                    }));
                    dispatch(setAdScenariosFetching(false));
                    dispatch(updateIsFetching(false));
                    return [2 /*return*/];
            }
        });
    }); };
};
export var fetchBlock = function (_a) {
    var chatbotIdParam = _a.chatbotIdParam, adScenarioIdParam = _a.adScenarioIdParam, blockIdParam = _a.blockIdParam, isSlim = _a.isSlim;
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        var block, e_3;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, blockApi.getBlock(chatbotIdParam, adScenarioIdParam, blockIdParam, isSlim)];
                case 1:
                    block = _a.sent();
                    dispatch(setBlockMessages(block));
                    return [3 /*break*/, 3];
                case 2:
                    e_3 = _a.sent();
                    handleAPIErrors('ブロックが取得できませんでした。', e_3);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
};
export var fetchPrevBlocks = function (_a) {
    var chatbotId = _a.chatbotId, adScenarioId = _a.adScenarioId, blockId = _a.blockId;
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        var prevBlocks;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, blockApi.getPreviousBlocks(chatbotId, adScenarioId, blockId)];
                case 1:
                    prevBlocks = _a.sent();
                    dispatch(setPrevBlocks(prevBlocks));
                    return [2 /*return*/];
            }
        });
    }); };
};
