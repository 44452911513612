var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { forwardRef, useImperativeHandle } from 'react';
import shortid from 'shortid';
import styled from 'styled-components';
import { AlertMessage } from '@src/components/atoms';
var DEFAULT_OFFSET = 0;
var DEFAULT_TIME = 500;
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: fixed;\n  top: 0;\n  left: 0;\n  text-align: center;\n  width: 100%;\n  z-index: 100;\n"], ["\n  position: fixed;\n  top: 0;\n  left: 0;\n  text-align: center;\n  width: 100%;\n  z-index: 100;\n"])));
var AlertTypes;
(function (AlertTypes) {
    AlertTypes["SUCCESS"] = "success";
    AlertTypes["ERROR"] = "error";
    AlertTypes["INFO"] = "info";
})(AlertTypes || (AlertTypes = {}));
var AlertContainer = forwardRef(function (_a, ref) {
    var _b = _a.offset, offset = _b === void 0 ? DEFAULT_OFFSET : _b, _c = _a.time, time = _c === void 0 ? DEFAULT_TIME : _c;
    var _d = __read(React.useState([]), 2), alerts = _d[0], setAlerts = _d[1];
    var success = function (message, options) {
        if (message === void 0) { message = ''; }
        if (options === void 0) { options = {}; }
        options.type = AlertTypes.SUCCESS;
        return show(message, options);
    };
    var error = function (message, options) {
        if (message === void 0) { message = ''; }
        if (options === void 0) { options = {}; }
        options.type = AlertTypes.ERROR;
        return show(message, options);
    };
    var info = function (message, options) {
        if (message === void 0) { message = ''; }
        if (options === void 0) { options = {}; }
        options.type = AlertTypes.INFO;
        return show(message, options);
    };
    var show = function (message, options) {
        if (message === void 0) { message = ''; }
        if (options === void 0) { options = {}; }
        var alert = {
            id: shortid.generate(),
            message: message,
            time: options.time,
            type: options.type,
        };
        setAlerts(alerts.concat(alert));
        return alert.id;
    };
    var removeAll = function () {
        var alertsRemoved = alerts;
        setAlerts([]);
        alertsRemoved.forEach(function (alert) { return alert.onClose && alert.onClose(); });
    };
    var removeAlert = function (id) {
        var alertRemoved = alerts.find(function (alert) { return alert.id === id; });
        if (!alertRemoved)
            return;
        setAlerts(alerts.filter(function (alert) { return alert.id !== id; }));
        if (alertRemoved.onClose)
            alertRemoved.onClose();
    };
    useImperativeHandle(ref, function () { return ({
        success: success,
        error: error,
        info: info,
        show: show,
        removeAll: removeAll,
        removeAlert: removeAlert,
    }); });
    return (React.createElement(Container, { "data-testid": "FlashMessage" }, alerts.map(function (alert) {
        return (React.createElement(AlertMessage, __assign({ key: alert.id, time: alert.time }, alert, { onRemoveAlert: removeAlert })));
    })));
});
export default AlertContainer;
var templateObject_1;
