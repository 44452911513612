var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import React from 'react';
import { injectIntl } from 'react-intl';
import ModalPortal from '@src/components/atoms/ModalPortal';
import ModalWrapper from '@src/components/atoms/ModalWrapper';
import { Modal } from '@src/components/molecules';
import Input from '@src/components/atoms/Input';
import modalTypes from '@src/lib/modalTypes';
import { valueTypeToString, valueMapValidation } from '@src/lib/formAutomation';
import { FormAutomationStepValueType, } from 'zeals-protobuf/zeals/web/entities/form_automation_pb';
import { Formik } from 'formik';
import * as Yup from 'yup';
import FormAutomationFixedValueSection from './FormAutomationFixedValueSection';
import FormAutomationValueMap from './FormAutomationValueMap';
var valueTypeItems = [];
Object.values(FormAutomationStepValueType).forEach(function (type) {
    if (type !==
        FormAutomationStepValueType.FORM_AUTOMATION_STEP_VALUE_TYPE_UNSPECIFIED) {
        var label = valueTypeToString(type);
        valueTypeItems.push({
            label: label,
            value: type,
        });
    }
});
var FormAutomationClickByValueActionModal = function (_a) {
    var initialClickByValueStep = _a.initialClickByValueStep, field = _a.field, intl = _a.intl, onCancel = _a.onCancel, onChange = _a.onChange;
    var initialFormikState = {
        valueType: initialClickByValueStep === null || initialClickByValueStep === void 0 ? void 0 : initialClickByValueStep.valueType,
        htmlAttr: initialClickByValueStep === null || initialClickByValueStep === void 0 ? void 0 : initialClickByValueStep.htmlAttr,
        fixedValue: initialClickByValueStep === null || initialClickByValueStep === void 0 ? void 0 : initialClickByValueStep.fixedValue,
        valueMap: initialClickByValueStep === null || initialClickByValueStep === void 0 ? void 0 : initialClickByValueStep.valueMapMap,
    };
    var schema = Yup.object({
        fixedValue: Yup.string().test('FixedValue', 
        // eslint-disable-next-line no-template-curly-in-string
        '${path} is not valid', function (value, context) {
            switch (context.parent.valueType) {
                case FormAutomationStepValueType.FORM_AUTOMATION_STEP_VALUE_TYPE_FIELD:
                    return true;
                case FormAutomationStepValueType.FORM_AUTOMATION_STEP_VALUE_TYPE_FIXED:
                    return Boolean(value);
                default:
                    return true;
            }
        }),
        valueMap: Yup.array().test('ValueMap', 
        // eslint-disable-next-line no-template-curly-in-string
        '${path} is not valid', function (valueMap, context) { return valueMapValidation(valueMap, context, field); }),
    });
    function updateMap(setFieldValue, valueMap, i, entry) {
        setFieldValue('valueMap', valueMap.map(function (originalEntry, index) {
            return index === i ? entry : originalEntry;
        }));
    }
    function addEntryToMap(setFieldValue, valueMap) {
        setFieldValue('valueMap', __spreadArray(__spreadArray([], __read(valueMap)), [['', '']]));
    }
    function removeEntryFromMap(setFieldValue, valueMap, i) {
        setFieldValue('valueMap', valueMap.filter(function (_, index) { return i !== index; }));
    }
    return (React.createElement(Formik, { initialValues: initialFormikState, validationSchema: schema, onSubmit: function () { return undefined; } }, function (_a) {
        var values = _a.values, errors = _a.errors, touched = _a.touched, isValid = _a.isValid, setFieldValue = _a.setFieldValue;
        return (React.createElement(ModalPortal, null,
            React.createElement(ModalWrapper, null,
                React.createElement(Modal, { className: "form-automation-modal", type: modalTypes.EDIT, onCancel: onCancel, onConfirm: function () {
                        return onChange(__assign(__assign({}, initialClickByValueStep), { valueType: values.valueType, valueMapMap: values.valueMap, fixedValue: values.fixedValue, htmlAttr: values.htmlAttr }));
                    }, hasError: !isValid },
                    React.createElement("h3", { className: "title" }, intl.formatMessage({ id: "djk1+7", defaultMessage: "Click By Value Action" })),
                    values.valueType ===
                        FormAutomationStepValueType.FORM_AUTOMATION_STEP_VALUE_TYPE_FIELD && (React.createElement(FormAutomationValueMap, { field: field, valueMap: values.valueMap, isValid: isValid, onAddMapEntry: function () {
                            return addEntryToMap(setFieldValue, values.valueMap);
                        }, onRemoveMapEntry: function (index) {
                            return removeEntryFromMap(setFieldValue, values.valueMap, index);
                        }, onUpdateMapEntry: function (index, entry) {
                            return updateMap(setFieldValue, values.valueMap, index, entry);
                        }, error: errors.valueMap })),
                    values.valueType ===
                        FormAutomationStepValueType.FORM_AUTOMATION_STEP_VALUE_TYPE_FIXED && (React.createElement(FormAutomationFixedValueSection, { value: values.fixedValue, onChange: function (fixedValue) {
                            return setFieldValue('fixedValue', fixedValue);
                        }, error: errors.fixedValue })),
                    React.createElement("div", { className: "html-attribute-container" },
                        React.createElement("span", { className: "subtitle" }, intl.formatMessage({ id: "O550Fn", defaultMessage: "HTML Attribute" })),
                        React.createElement("div", { className: "field field-html-attribute" },
                            React.createElement(Input, { value: values.htmlAttr, onChange: function (e) {
                                    return setFieldValue('htmlAttr', e.target.value);
                                }, placeholder: intl.formatMessage({ id: "Q1krJ7", defaultMessage: "Please enter an HTML Attribute" }) })))))));
    }));
};
export default injectIntl(FormAutomationClickByValueActionModal);
