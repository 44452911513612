var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { handleAPIErrors } from '@src/lib/helpers';
import * as settingsApi from '@src/services/api/settings';
import * as chatbotApi from '@src/services/api/chatbot';
import { SettingTypes, } from '../types/settings';
export var toggleWelcomeBoard = function () {
    return { type: SettingTypes.TOGGLE_WELCOME_BOARD };
};
export var toggleMainMenu = function () {
    return { type: SettingTypes.TOGGLE_MAIN_MENU };
};
export var toggleDefaultMessage = function () {
    return { type: SettingTypes.TOGGLE_DEFAULT_MESSAGE };
};
export var toggleDisconnectMessage = function () {
    return { type: SettingTypes.TOGGLE_DISCONNECT_MESSAGE };
};
export var getWelcomeMessageText = function (text) {
    return {
        type: SettingTypes.GET_WELCOME_MESSAGE_TEXT,
        text: text,
    };
};
export var getDefaultMessageText = function (text) {
    return {
        type: SettingTypes.GET_DEFAULT_MSG_TEXT,
        text: text,
    };
};
export var changeDefaultOption = function (optionValue) {
    var defaultAction = !!(parseInt(optionValue) === 0 || parseInt(optionValue) === 1);
    return {
        type: SettingTypes.CHANGE_DEFAULT_OPTION,
        optionValue: optionValue,
        defaultAction: defaultAction,
    };
};
export var toggleFetchingSettings = function () { return ({
    type: SettingTypes.TOGGLE_FETCHING_SETTINGS,
}); };
export var setPageList = function (pageList) { return ({
    type: SettingTypes.SET_PAGE_LIST,
    pageList: pageList,
}); };
export var handleChatbotUpdate = function (chatbot) { return ({
    type: SettingTypes.HANDLE_CHATBOT_UPDATE,
    chatbot: chatbot,
}); };
export var setDefaultOptionSelected = function (defaultOptionSelected) { return ({
    type: SettingTypes.SET_DEFAULT_OPTION_SELECTED,
    defaultOptionSelected: defaultOptionSelected,
}); };
export var updateChatbotStatus = function (chatbotId, status) { return ({
    type: SettingTypes.UPDATE_CHATBOT_STATUS,
    chatbotId: chatbotId,
    status: status,
}); };
export var updatePluginMessage = function (message) {
    return {
        type: SettingTypes.UPDATE_PLUGIN_MESSAGE,
        message: message,
    };
};
export var updatePluginTitle = function (title) {
    return {
        type: SettingTypes.UPDATE_PLUGIN_TITLE,
        title: title,
    };
};
export var updatePluginButton = function (button) {
    return {
        type: SettingTypes.UPDATE_PLUGIN_BUTTON,
        button: button,
    };
};
export var fetchConnectPage = function (_a) {
    var chatbotId = _a.chatbotId, payload = _a.payload, _b = _a.redirect, redirect = _b === void 0 ? true : _b;
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        var chatbot, url;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, settingsApi.connectPageToChatbot(parseInt(chatbotId), payload)];
                case 1:
                    chatbot = _a.sent();
                    dispatch(handleChatbotUpdate(chatbot));
                    url = "/dashboard/ad/initial/chatbots/" + chatbot.id;
                    if (redirect) {
                        if (window.globalRouterHistory) {
                            window.globalRouterHistory.push(url);
                        }
                    }
                    return [2 /*return*/];
            }
        });
    }); };
};
export var fetchFacebookPageList = function (chatbotId) { return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
    var pages, e_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                dispatch(toggleFetchingSettings());
                return [4 /*yield*/, settingsApi.getFacebookPageList(parseInt(chatbotId))];
            case 1:
                pages = _a.sent();
                dispatch(toggleFetchingSettings());
                dispatch(setPageList(pages));
                return [3 /*break*/, 3];
            case 2:
                e_1 = _a.sent();
                handleAPIErrors(e_1);
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); }; };
export var fetchInstagramPageList = function (chatbotId) { return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
    var pages, e_2;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                dispatch(toggleFetchingSettings());
                return [4 /*yield*/, settingsApi.getInstagramPageList(parseInt(chatbotId))];
            case 1:
                pages = _a.sent();
                dispatch(toggleFetchingSettings());
                dispatch(setPageList(pages));
                return [3 /*break*/, 3];
            case 2:
                e_2 = _a.sent();
                handleAPIErrors(e_2);
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); }; };
export var getChatbot = function (chatbotId) { return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
    var chatbot, defaultText, defaultAction, defaultOptionSelected, e_3;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, settingsApi.getChatbotSettings(parseInt(chatbotId))];
            case 1:
                chatbot = _a.sent();
                if ((chatbot === null || chatbot === void 0 ? void 0 : chatbot.status) !== 'active' || !chatbot) {
                    throw new Error('Chatbot is not active');
                }
                defaultText = chatbot.default_text, defaultAction = chatbot.default_action;
                defaultOptionSelected = '0';
                if (!defaultText && defaultAction) {
                    defaultOptionSelected = '1';
                }
                else if (!defaultText && !defaultAction) {
                    defaultOptionSelected = '2';
                }
                dispatch(setDefaultOptionSelected(defaultOptionSelected));
                dispatch(handleChatbotUpdate(chatbot));
                return [3 /*break*/, 3];
            case 2:
                e_3 = _a.sent();
                handleAPIErrors(e_3.message);
                // THIS IS TEMPORARY FIX, WE NEED TO COME UP WITH SAFER WAY TO HANDLE THE AUTHENTICATION TO DEAL WITH BOTH RAILS AND MICROSERVICES
                if (window.globalRouterHistory && process.env.NODE_ENV === 'production') {
                    window.globalRouterHistory.push('/dashboard');
                }
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); }; };
export var submitWelcomeBoardText = function (_a) {
    var chatbotId = _a.chatbotId, welcomeBoardText = _a.welcomeBoardText, successMessage = _a.successMessage, errorMessage = _a.errorMessage;
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        var body, chatbot, e_4;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    body = { greeting: welcomeBoardText };
                    return [4 /*yield*/, chatbotApi.updateChatbot(parseInt(chatbotId), body)];
                case 1:
                    chatbot = _a.sent();
                    successMessage();
                    dispatch(handleChatbotUpdate(chatbot));
                    return [3 /*break*/, 3];
                case 2:
                    e_4 = _a.sent();
                    errorMessage();
                    handleAPIErrors(e_4);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
};
export var fetchUpdateChatbotStatus = function (chatbotId, status) { return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
    var body, chatbot, e_5;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                body = { status: status };
                return [4 /*yield*/, chatbotApi.updateChatbot(parseInt(chatbotId), body)];
            case 1:
                chatbot = _a.sent();
                if ((chatbot === null || chatbot === void 0 ? void 0 : chatbot.status) === 'deleted') {
                    if (window.globalRouterHistory) {
                        window.globalRouterHistory.push('/dashboard');
                    }
                }
                dispatch(handleChatbotUpdate(chatbot));
                return [3 /*break*/, 3];
            case 2:
                e_5 = _a.sent();
                handleAPIErrors('チャットボットを更新できませんでした。', e_5);
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); }; };
export var fetchDefaultMessage = function (_a) {
    var chatbotId = _a.chatbotId, successMessage = _a.successMessage, errorMessage = _a.errorMessage, defaultOptionSelected = _a.defaultOptionSelected, defaultAction = _a.defaultAction, activeChatbot = _a.activeChatbot;
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        var defaultText, body, chatbot, e_6;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    defaultText = (activeChatbot || {}).default_text;
                    body = parseInt(defaultOptionSelected) === 0
                        ? {
                            default_action: defaultAction,
                            default_text: defaultText,
                        }
                        : { default_action: defaultAction };
                    return [4 /*yield*/, chatbotApi.updateChatbot(chatbotId, body)];
                case 1:
                    chatbot = _a.sent();
                    successMessage();
                    dispatch(handleChatbotUpdate(chatbot));
                    return [3 /*break*/, 3];
                case 2:
                    e_6 = _a.sent();
                    errorMessage();
                    handleAPIErrors(e_6);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
};
export var adSetPersistentMenuItems = function (persistentMenuItems) { return ({
    type: SettingTypes.AD_SET_PERSISTENT_MENU_ITEMS,
    persistentMenuItems: persistentMenuItems,
}); };
export var adHandleNewPersistentMenuItem = function (_a) {
    var chatbotId = _a.chatbotId, parentPersistentMenuIterator = _a.parentPersistentMenuIterator, persistentMenuIterator = _a.persistentMenuIterator, persistentMenuObject = _a.persistentMenuObject, parentId = _a.parentId, selectedTab = _a.selectedTab;
    return ({
        type: SettingTypes.AD_HANDLE_NEW_PERSISTENT_MENU_ITEM,
        chatbotId: chatbotId,
        parentPersistentMenuIterator: parentPersistentMenuIterator,
        persistentMenuIterator: persistentMenuIterator,
        persistentMenuObject: persistentMenuObject,
        parentId: parentId,
        selectedTab: selectedTab,
    });
};
export var adDeletePersistentMenuItem = function (_a) {
    var chatbotId = _a.chatbotId, parentPersistentMenuIterator = _a.parentPersistentMenuIterator, persistentMenuIterator = _a.persistentMenuIterator, persistentMenuId = _a.persistentMenuId;
    return ({
        type: SettingTypes.AD_DELETE_PERSISTENT_MENU_ITEM,
        chatbotId: chatbotId,
        parentPersistentMenuIterator: parentPersistentMenuIterator,
        persistentMenuIterator: persistentMenuIterator,
        persistentMenuId: persistentMenuId,
    });
};
export var fetchDeletePersistentMenuItem = function (_a) {
    var chatbotId = _a.chatbotId, parentPersistentMenuIterator = _a.parentPersistentMenuIterator, persistentMenuIterator = _a.persistentMenuIterator, persistentMenuId = _a.persistentMenuId;
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        var e_7;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, settingsApi.deletePersistentMenu(parseInt(chatbotId), parseInt(persistentMenuId))];
                case 1:
                    _a.sent();
                    dispatch(adDeletePersistentMenuItem({
                        chatbotId: chatbotId,
                        parentPersistentMenuIterator: parentPersistentMenuIterator,
                        persistentMenuIterator: persistentMenuIterator,
                        persistentMenuId: persistentMenuId,
                    }));
                    return [3 /*break*/, 3];
                case 2:
                    e_7 = _a.sent();
                    handleAPIErrors('メニューアイテムを更新できませんでした。', e_7);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
};
export var adUpdatePersistentMenuItem = function (_a) {
    var chatbotId = _a.chatbotId, adScenarioId = _a.adScenarioId, parentPersistentMenuIterator = _a.parentPersistentMenuIterator, persistentMenuIterator = _a.persistentMenuIterator, persistentMenuId = _a.persistentMenuId, persistentMenuObject = _a.persistentMenuObject, selectedTab = _a.selectedTab;
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        var body, persistentMenuItem, e_8;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    body = {
                        title: persistentMenuObject.title,
                        url: persistentMenuObject.url,
                        effect: persistentMenuObject.effect,
                        scenario_id: persistentMenuObject.url || persistentMenuObject.effect
                            ? null
                            : adScenarioId,
                    };
                    return [4 /*yield*/, settingsApi.updatePersistentMenu(parseInt(chatbotId), parseInt(persistentMenuId), body)];
                case 1:
                    persistentMenuItem = _a.sent();
                    dispatch(adHandleNewPersistentMenuItem({
                        chatbotId: chatbotId,
                        parentPersistentMenuIterator: parentPersistentMenuIterator,
                        persistentMenuIterator: persistentMenuIterator,
                        persistentMenuObject: persistentMenuItem,
                        selectedTab: selectedTab,
                    }));
                    return [3 /*break*/, 3];
                case 2:
                    e_8 = _a.sent();
                    handleAPIErrors('メニューアイテムを更新できませんでした。', e_8);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
};
export var fetchPersistentMenuList = function (chatbotId) { return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
    var persistentMenuItems, e_9;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, settingsApi.getPersistentMenuList(parseInt(chatbotId))];
            case 1:
                persistentMenuItems = _a.sent();
                dispatch(adSetPersistentMenuItems(persistentMenuItems.slice(0, 2)));
                return [3 /*break*/, 3];
            case 2:
                e_9 = _a.sent();
                handleAPIErrors(e_9);
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); }; };
export var fetchNewPersistentMenuItem = function (_a) {
    var chatbotId = _a.chatbotId, adScenarioId = _a.adScenarioId, persistentMenuObject = _a.persistentMenuObject, parentPersistentMenuIterator = _a.parentPersistentMenuIterator, persistentMenuIterator = _a.persistentMenuIterator, persistentMenuId = _a.persistentMenuId;
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        var body, _a, status, message, persistentMenuItem, e_10;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, , 3]);
                    body = __assign(__assign({}, persistentMenuObject), { scenario_id: adScenarioId, parent_id: persistentMenuId || null });
                    return [4 /*yield*/, settingsApi.createPersistentMenu(parseInt(chatbotId), body)];
                case 1:
                    _a = _b.sent(), status = _a.status, message = _a.message, persistentMenuItem = _a.persistentMenuItem;
                    if (status !== 200) {
                        throw new Error(message);
                    }
                    dispatch(adHandleNewPersistentMenuItem({
                        chatbotId: chatbotId,
                        parentPersistentMenuIterator: parentPersistentMenuIterator,
                        persistentMenuIterator: persistentMenuIterator,
                        persistentMenuObject: persistentMenuItem,
                        parentId: persistentMenuId,
                    }));
                    return [3 /*break*/, 3];
                case 2:
                    e_10 = _b.sent();
                    handleAPIErrors('メニューアイテムを取得できませんでした。', e_10);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
};
