var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import modalTypes from '@src/lib/modalTypes';
import styled from 'styled-components';
import { mediaSchema } from '@src/lib/helpers';
import { ModalPortal, Input, CharacterCount } from '@src/components/atoms';
import { Modal } from '@src/components/molecules';
import { mediaTypeMessages } from '@src/i18n/translations';
var ErrorsMessageContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding-left: 16px;\n  margin-top: 4px;\n"], ["\n  padding-left: 16px;\n  margin-top: 4px;\n"])));
var ChangeMediaDetailsModal = /** @class */ (function (_super) {
    __extends(ChangeMediaDetailsModal, _super);
    function ChangeMediaDetailsModal() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            url: _this.props.initialUrl || '',
            name: _this.props.initialName || '',
            errors: {},
        };
        _this.validate = function (field) {
            if (field === void 0) { field = null; }
            var _a = _this.state, url = _a.url, name = _a.name;
            var _b = _this.props, mediaType = _b.mediaType, platform = _b.platform, intl = _b.intl;
            mediaSchema
                .validate({
                url: url,
                name: name,
                type: mediaType,
            }, {
                abortEarly: false,
                context: {
                    platform: platform,
                    intl: intl,
                },
            })
                .then(function () { return _this.setState({ errors: {} }); })
                .catch(function (e) {
                var newErrors = __assign({}, _this.state.errors);
                if (field)
                    newErrors[field] = null;
                e.inner.forEach(function (errorDetail) {
                    if (!field || field === errorDetail.path)
                        newErrors[errorDetail.path] = errorDetail.errors.join(', ');
                });
                _this.setState({ errors: newErrors });
            });
        };
        _this.handleInput = function (e, field) {
            var _a;
            return _this.setState((_a = {}, _a[field] = e.target.value, _a), function () {
                return _this.validate(field);
            });
        };
        _this.handleConfirm = function () {
            var _a = _this.state, url = _a.url, name = _a.name;
            var onConfirm = _this.props.onConfirm;
            if (onConfirm)
                onConfirm({
                    url: url,
                    name: name,
                });
        };
        return _this;
    }
    ChangeMediaDetailsModal.prototype.fillAllValues = function () {
        var _a = this.state, url = _a.url, name = _a.name;
        return url !== '' && name !== '';
    };
    ChangeMediaDetailsModal.prototype.componentDidMount = function () {
        if (this.fillAllValues())
            this.validate();
    };
    ChangeMediaDetailsModal.prototype.renderErrors = function (field) {
        var errors = this.state.errors;
        return (errors[field] && (React.createElement(ErrorsMessageContainer, null,
            React.createElement("div", { className: "message-wrapper error" }, errors[field]))));
    };
    ChangeMediaDetailsModal.prototype.render = function () {
        var _this = this;
        var _a = this.props, onCancel = _a.onCancel, intl = _a.intl, mediaType = _a.mediaType, platform = _a.platform;
        var _b = this.state, name = _b.name, url = _b.url, errors = _b.errors;
        var mediaTypeText = intl.formatMessage(mediaTypeMessages[mediaType]);
        return (React.createElement(ModalPortal, null,
            React.createElement(Modal, { "data-testid": "change-mdeia-details-modal", text: mediaTypeText + "URL", onCancel: onCancel, onConfirm: this.handleConfirm, type: modalTypes.SELECT, hasError: !this.fillAllValues() || Object.keys(errors).length !== 0 },
                React.createElement("div", { className: "message-wrapper primary-label", style: { marginBottom: '12px' } }, mediaTypeText + "\u306EURL"),
                React.createElement(Input, { type: "text", placeholder: "\u5FC5\u9808", onChange: function (e) {
                        return _this.handleInput(e, 'url');
                    }, onBlur: function () { return _this.validate('url'); }, value: url, "data-testid": "ChangeMediaDetailsModal-UrlInput-" + mediaTypeText },
                    React.createElement(CharacterCount, { value: url, platform: platform, path: "common.url" })),
                this.renderErrors('url'),
                React.createElement("div", { className: "message-wrapper primary-label" }, "\u30D5\u30A1\u30A4\u30EB\u540D"),
                React.createElement(Input, { type: "text", placeholder: "\u5FC5\u9808", onChange: function (e) {
                        return _this.handleInput(e, 'name');
                    }, onBlur: function () { return _this.validate('name'); }, value: name, "data-testid": "ChangeMediaDetailsModal-NameInput-" + mediaTypeText }),
                this.renderErrors('name'))));
    };
    return ChangeMediaDetailsModal;
}(Component));
export default injectIntl(ChangeMediaDetailsModal);
var templateObject_1;
