var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import axios from 'axios';
import axiosInstance, { handleNormalResponse } from './axiosInstance';
import transformResponse from './transformResponse';
var SCHEDULE_HOST = process.env.DEFAULT_RICH_MENU_HOST.split('/richmenu/')[0];
var STATUS_UNAUTHORIZED = 401;
var ACCESS_TOKEN_KEY = 'default-rich-menu-access-token';
var REFRESH_TOKEN_KEY = 'default-rich-menu-refresh-token';
var CLIENT_SECRET = process.env.DEFAULT_RICH_MENU_CLIENT_SECRET;
var fetchingToken = false;
var axiosInstanceCustom = axios.create({
    baseURL: SCHEDULE_HOST,
    withCredentials: true,
});
axiosInstanceCustom.defaults.headers.common['X-Requested-With'] =
    'XMLHttpRequest';
axiosInstanceCustom.interceptors.response.use(handleNormalResponse, function (error) { return __awaiter(void 0, void 0, void 0, function () {
    var accessToken;
    var _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (!(((_a = error.response) === null || _a === void 0 ? void 0 : _a.status) === STATUS_UNAUTHORIZED)) return [3 /*break*/, 2];
                return [4 /*yield*/, refreshToken()];
            case 1:
                _b.sent();
                accessToken = window.sessionStorage.getItem(ACCESS_TOKEN_KEY);
                error.config.headers['Access-Token'] = accessToken;
                if (error.config.data) {
                    error.config.data = JSON.parse("{\"" + error.config.data.slice(2, error.config.data.length));
                }
                return [2 /*return*/, axios.request(error.config)];
            case 2: return [2 /*return*/, error];
        }
    });
}); });
function fetchedToken() {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, new Promise(function (resolve, reject) {
                    var intervalId = setInterval(function () {
                        if (!fetchingToken) {
                            resolve(fetchingToken);
                            clearInterval(intervalId);
                        }
                    }, 100);
                })];
        });
    });
}
function requestConfig() {
    return __awaiter(this, void 0, void 0, function () {
        var accessToken;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    accessToken = window.sessionStorage.getItem(ACCESS_TOKEN_KEY);
                    if (!!accessToken) return [3 /*break*/, 2];
                    return [4 /*yield*/, getAccessToken()];
                case 1:
                    _a.sent();
                    accessToken = window.sessionStorage.getItem(ACCESS_TOKEN_KEY);
                    _a.label = 2;
                case 2: return [2 /*return*/, {
                        baseURL: SCHEDULE_HOST,
                        headers: {
                            'Client-Secret': CLIENT_SECRET,
                            'Access-Token': accessToken,
                        },
                    }];
            }
        });
    });
}
function getAccessToken() {
    return __awaiter(this, void 0, Promise, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!fetchingToken) return [3 /*break*/, 2];
                    return [4 /*yield*/, fetchedToken()];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
                case 2:
                    fetchingToken = true;
                    return [4 /*yield*/, transformResponse(axiosInstance.post('/token', {}, { baseURL: SCHEDULE_HOST, headers: { 'Client-Secret': CLIENT_SECRET } }))];
                case 3:
                    res = _a.sent();
                    if (res.access_token) {
                        window.sessionStorage.setItem(ACCESS_TOKEN_KEY, res.access_token);
                        window.sessionStorage.setItem(REFRESH_TOKEN_KEY, res.refresh_token);
                    }
                    fetchingToken = false;
                    return [2 /*return*/];
            }
        });
    });
}
function refreshToken() {
    return __awaiter(this, void 0, Promise, function () {
        var refreshToken, res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!fetchingToken) return [3 /*break*/, 2];
                    return [4 /*yield*/, fetchedToken()];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
                case 2:
                    fetchingToken = true;
                    refreshToken = window.sessionStorage.getItem(REFRESH_TOKEN_KEY);
                    return [4 /*yield*/, transformResponse(axiosInstance.post('/token', {}, {
                            baseURL: SCHEDULE_HOST,
                            headers: {
                                'Client-Secret': CLIENT_SECRET,
                                'Refresh-Token': refreshToken,
                            },
                        }))];
                case 3:
                    res = _a.sent();
                    if (res.access_token) {
                        window.sessionStorage.setItem(ACCESS_TOKEN_KEY, res.access_token);
                    }
                    fetchingToken = false;
                    return [2 /*return*/];
            }
        });
    });
}
export function createSchedule(chatbotID, accessToken, schedule) {
    return __awaiter(this, void 0, Promise, function () {
        var res, _a, _b, _c, _d;
        return __generator(this, function (_e) {
            switch (_e.label) {
                case 0:
                    _a = transformResponse;
                    _c = (_b = axiosInstanceCustom).post;
                    _d = [chatbotID + "/schedules", __assign({ line_access_token: accessToken }, schedule)];
                    return [4 /*yield*/, requestConfig()];
                case 1: return [4 /*yield*/, _a.apply(void 0, [_c.apply(_b, _d.concat([_e.sent()]))])];
                case 2:
                    res = _e.sent();
                    return [2 /*return*/, res.message === undefined];
            }
        });
    });
}
export function getSchedules(chatbotID) {
    return __awaiter(this, void 0, Promise, function () {
        var res, _a, _b, _c, _d;
        return __generator(this, function (_e) {
            switch (_e.label) {
                case 0:
                    _a = transformResponse;
                    _c = (_b = axiosInstanceCustom).get;
                    _d = [chatbotID + "/schedules"];
                    return [4 /*yield*/, requestConfig()];
                case 1: return [4 /*yield*/, _a.apply(void 0, [_c.apply(_b, _d.concat([_e.sent()]))])];
                case 2:
                    res = _e.sent();
                    return [2 /*return*/, res];
            }
        });
    });
}
export function deleteSchedule(chatbotID, lineRichMenuId) {
    return __awaiter(this, void 0, Promise, function () {
        var res, _a, _b, _c, _d;
        return __generator(this, function (_e) {
            switch (_e.label) {
                case 0:
                    _a = transformResponse;
                    _c = (_b = axiosInstanceCustom).delete;
                    _d = [chatbotID + "/schedules/" + lineRichMenuId];
                    return [4 /*yield*/, requestConfig()];
                case 1: return [4 /*yield*/, _a.apply(void 0, [_c.apply(_b, _d.concat([_e.sent()]))])];
                case 2:
                    res = _e.sent();
                    return [2 /*return*/, res.message === undefined];
            }
        });
    });
}
export function getLineRichMenuIds(chatbotID) {
    return __awaiter(this, void 0, Promise, function () {
        var res, _a, _b, _c, _d;
        return __generator(this, function (_e) {
            switch (_e.label) {
                case 0:
                    _a = transformResponse;
                    _c = (_b = axiosInstanceCustom).get;
                    _d = [chatbotID + "/line_rich_menu_ids"];
                    return [4 /*yield*/, requestConfig()];
                case 1: return [4 /*yield*/, _a.apply(void 0, [_c.apply(_b, _d.concat([_e.sent()]))])];
                case 2:
                    res = _e.sent();
                    return [2 /*return*/, res];
            }
        });
    });
}
export function inActiveRichMenuTab(accessToken, defaultRichMenu) {
    return __awaiter(this, void 0, Promise, function () {
        var res, _a, _b, _c, _d;
        return __generator(this, function (_e) {
            switch (_e.label) {
                case 0:
                    _a = transformResponse;
                    _c = (_b = axiosInstance).post;
                    _d = ['/richmenu/inactive',
                        {
                            line_rich_menu_id: defaultRichMenu.richMenus[0].line_rich_menu_id,
                            line_access_token: accessToken,
                        }];
                    return [4 /*yield*/, requestConfig()];
                case 1: return [4 /*yield*/, _a.apply(void 0, [_c.apply(_b, _d.concat([_e.sent()]))])];
                case 2:
                    res = _e.sent();
                    return [2 /*return*/, (res === null || res === void 0 ? void 0 : res.message) === undefined];
            }
        });
    });
}
