var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import Modal from '@src/components/molecules/Modal';
import { ModalPortal } from '@src/components/atoms';
import modalTypes from '@src/lib/modalTypes';
import { injectIntl } from 'react-intl';
var DeleteConfirmModalWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  .delete-confirm-modal {\n    overflow: hidden;\n\n    .section-controls {\n      padding-bottom: 5px;\n    }\n  }\n"], ["\n  .delete-confirm-modal {\n    overflow: hidden;\n\n    .section-controls {\n      padding-bottom: 5px;\n    }\n  }\n"])));
var DeleteConfirmModal = function (_a) {
    var intl = _a.intl, _b = _a.text, text = _b === void 0 ? intl.formatMessage({ id: "usNPpx", defaultMessage: "Are you sure you want to delete this item ?" }) : _b, onCancel = _a.onCancel, onConfirm = _a.onConfirm;
    return (React.createElement(ModalPortal, null,
        React.createElement(DeleteConfirmModalWrapper, null,
            React.createElement(Modal, { "data-testid": "delete-confirm-modal", className: "delete-confirm-modal", text: text, type: modalTypes.DELETE, onCancel: onCancel, onConfirm: onConfirm }))));
};
export default injectIntl(DeleteConfirmModal);
var templateObject_1;
