import { denormalize, normalize, schema } from 'normalizr';
var emptyResult = {
    scenarios: {},
    layers: {},
    blocks: {},
};
// Define a block schema
var blockSchema = new schema.Entity('blocks');
// Define a layer schema
var layerSchema = new schema.Entity('layers', { blocks: [blockSchema] });
// Define a scenario schema
var scenarioSchema = new schema.Entity('scenarios', {
    layers: [layerSchema],
});
var scenarioList = new schema.Array(scenarioSchema);
export var adScenariosNormalizer = function (adScenarios) {
    if (adScenarios.length === 0) {
        return emptyResult;
    }
    var normalizedData = normalize(adScenarios, scenarioList);
    return normalizedData.entities;
};
export var singleAdScenarioNormalizer = function (adScenario) {
    var normalizedData = normalize(adScenario, scenarioSchema);
    return normalizedData.entities;
};
export var singleBlockNormalizer = function (blockData) {
    var message = new schema.Entity('messages');
    var messageSection = new schema.Entity('message_sections', {
        messages: [message],
    });
    var block = new schema.Entity('blocks', {
        message_sections: [messageSection],
    });
    var normalizedData = normalize(blockData, block);
    return normalizedData.entities;
};
export function singleScenarioDenormalizer(scenarios, layers, blocks) {
    var scenario = scenarios[Object.keys(scenarios)[0]];
    var entities = {
        layers: layers,
        blocks: blocks,
    };
    return denormalize(scenario, scenarioSchema, entities);
}
// schemas for zip user profile
var answer = new schema.Entity('answers');
var question = new schema.Entity('questions', { answers: [answer] });
var questionGroup = new schema.Entity('questionGroups', {
    questions: [question],
});
var questionGroupArray = new schema.Array(questionGroup);
export var customFormNormalizer = function (questionGroups) {
    var normalizedData = normalize(questionGroups, questionGroupArray);
    return normalizedData.entities;
};
export var customFormDenormalizer = function (answers, questions, questionGroups) {
    var input = Object.keys(questionGroups);
    var entities = {
        answers: answers,
        questions: questions,
        questionGroups: questionGroups,
    };
    return denormalize(input, questionGroupArray, entities);
};
