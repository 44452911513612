var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import { shadows } from '@src/colors';
var PreviewContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  border-radius: 8px;\n  box-shadow: ", ";\n  width: ", ";\n  min-width: 180px;\n\n  img {\n    width: 100%;\n    border-radius: 8px;\n  }\n"], ["\n  border-radius: 8px;\n  box-shadow: ", ";\n  width: ", ";\n  min-width: 180px;\n\n  img {\n    width: 100%;\n    border-radius: 8px;\n  }\n"])), shadows.small.mid, function (props) { return (props.width ? props.width : '20%'); });
var PluginImageActionPreview = function (_a) {
    var modal = _a.modal, banner = _a.banner, width = _a.width;
    return (React.createElement(PreviewContainer, { width: width }, !modal ? React.createElement("img", { src: banner.image_url }) : React.createElement("img", { src: modal.image_url })));
};
export default PluginImageActionPreview;
var templateObject_1;
