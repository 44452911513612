var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Component } from 'react';
import onClickOutside from 'react-onclickoutside';
import styled from 'styled-components';
import { colors } from '@src/colors';
import { hoverHighlight } from '@src/lib/mixins';
var ItemMenuContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: inline-block;\n  position: absolute;\n  top: 0;\n  left: 0;\n  z-index: 1;\n  padding: 0.7rem;\n  min-width: 80px;\n  background: ", ";\n  border: 1px solid ", ";\n  border-radius: 4px;\n  text-align: left;\n  color: ", ";\n  div.desc {\n    font-size: 0.4rem;\n  }\n  span {\n    display: block;\n    padding: 0.3rem 0;\n    font-size: 0.85rem;\n    ", ";\n  }\n"], ["\n  display: inline-block;\n  position: absolute;\n  top: 0;\n  left: 0;\n  z-index: 1;\n  padding: 0.7rem;\n  min-width: 80px;\n  background: ", ";\n  border: 1px solid ", ";\n  border-radius: 4px;\n  text-align: left;\n  color: ", ";\n  div.desc {\n    font-size: 0.4rem;\n  }\n  span {\n    display: block;\n    padding: 0.3rem 0;\n    font-size: 0.85rem;\n    ", ";\n  }\n"])), colors.background.main_menu, colors.border.default, colors.text_pale, hoverHighlight);
var ItemMenu = /** @class */ (function (_super) {
    __extends(ItemMenu, _super);
    function ItemMenu() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        // This is for react-onclickoutside library
        _this.handleClickOutside = function () {
            _this.props.handleClickOutside();
        };
        return _this;
    }
    ItemMenu.prototype.render = function () {
        var _a = this.props, className = _a.className, desc = _a.desc, children = _a.children;
        return (React.createElement(ItemMenuContainer, { className: className, "data-testid": "ItemMenu" },
            desc && (React.createElement("div", { className: "desc", "data-testid": "ItemMenu-Description" }, desc)),
            children));
    };
    return ItemMenu;
}(Component));
export default onClickOutside(ItemMenu);
var templateObject_1;
