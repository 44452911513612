var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import { colors } from '@src/colors';
import { AddTag, Tag } from '@src/components/atoms';
import { injectIntl } from 'react-intl';
export var Tags = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  color: ", ";\n  display: flex;\n  grid-row: 3;\n  overflow: auto;\n  max-height: ", ";\n  flex-wrap: wrap;\n\n  > span {\n    flex-shrink: 0;\n    padding-top: 5px;\n    margin-right: 4px;\n  }\n"], ["\n  color: ", ";\n  display: flex;\n  grid-row: 3;\n  overflow: auto;\n  max-height: ", ";\n  flex-wrap: wrap;\n\n  > span {\n    flex-shrink: 0;\n    padding-top: 5px;\n    margin-right: 4px;\n  }\n"])), colors.text_pale, function (props) { return (props.disableMaxHeight ? 'none' : '200px'); });
var TagList = function (_a) {
    var user = _a.user, handleCreationOfTag = _a.handleCreationOfTag, handleDeleteTag = _a.handleDeleteTag, showUserModal = _a.showUserModal, displayOnly = _a.displayOnly, disableMaxHeight = _a.disableMaxHeight, intl = _a.intl;
    var tags = user && user.tags ? user.tags : [];
    return (React.createElement(Tags, { disableMaxHeight: disableMaxHeight },
        React.createElement("span", { className: "tag-span" },
            intl.formatMessage({ id: "1EYCdR", defaultMessage: "Tags" }),
            "\uFF1A"),
        showUserModal ? null : React.createElement(AddTag, { onSubmit: handleCreationOfTag }),
        tags.map(function (tag) { return (React.createElement(Tag, { className: "tag_list_item", key: tag.id, name: tag.name || tag.label || 'No Title', onRemove: function (e) { return handleDeleteTag(e, tag); }, iconClass: !displayOnly && 'adicon_close' })); })));
};
export default injectIntl(TagList);
var templateObject_1;
