import { TemplateType, } from '../types/template';
import { Template } from '@src/typings/enums';
export var initialState = {
    showModal: false,
    templateType: Template.Horizontal,
    newScenario: false,
};
export default (function (state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case TemplateType.TOGGLE_MODAL: // toggles the modal depending on the state
            return Object.assign({}, state, { showModal: !state.showModal });
        case TemplateType.SHOW_MODAL:
            return Object.assign({}, state, { showModal: action.isShow });
        case TemplateType.CHANGE_TEMPLATE_STATE: // changes the template state in new scenario modal
            return Object.assign({}, state, {
                templateType: action.selectedState,
            });
        case TemplateType.SET_NEW_STATE:
            return Object.assign({}, state, {
                newScenario: action.isNewScenario,
            });
        case TemplateType.TOGGLE_NEW_SCENARIO:
            return Object.assign({}, state, {
                newScenario: !state.newScenario,
            });
        default:
            return state;
    }
});
