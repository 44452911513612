var toCamel = function (s) {
    return s.replace(/([-_][a-z])/gi, function ($1) {
        return $1.toUpperCase().replace('-', '').replace('_', '');
    });
};
var isArray = function (a) {
    return Array.isArray(a);
};
var isObject = function (o) {
    return o === Object(o) && !isArray(o) && typeof o !== 'function';
};
var keysToCamel = function (o) {
    if (isObject(o)) {
        var n_1 = {};
        Object.keys(o).forEach(function (k) {
            n_1[toCamel(k)] = keysToCamel(o[k]);
        });
        return n_1;
    }
    if (isArray(o)) {
        return o.map(function (i) {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            return keysToCamel(i);
        });
    }
    return o;
};
export default keysToCamel;
