var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import { colors, shadows } from '@src/colors';
import { transitions } from '@src/lib/animations';
var SwitchWrapper = styled.input(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  -webkit-appearance: none;\n  border-radius: 50px;\n  background: ", " !important;\n  width: 38px;\n  margin: 0 8px !important;\n  transition: ", ";\n  &:hover {\n    cursor: default;\n  }\n\n  &::-webkit-slider-thumb {\n    -webkit-appearance: none;\n    width: 20px;\n    height: 20px;\n    border-radius: 50%;\n    background: ", " !important;\n    box-shadow: ", " !important;\n  }\n"], ["\n  -webkit-appearance: none;\n  border-radius: 50px;\n  background: ",
    " !important;\n  width: 38px;\n  margin: 0 8px !important;\n  transition: ", ";\n  &:hover {\n    cursor: default;\n  }\n\n  &::-webkit-slider-thumb {\n    -webkit-appearance: none;\n    width: 20px;\n    height: 20px;\n    border-radius: 50%;\n    background: ",
    " !important;\n    box-shadow: ",
    " !important;\n  }\n"])), function (props) {
    return props.isActive ? colors.range.on_bg : colors.range.off_bg;
}, transitions.normal, function (props) {
    return props.isActive
        ? colors.range.on_btn_bg
        : colors.range.off_btn_bg;
}, function (props) {
    return props.isActive ? shadows.small.mid : 'none';
});
var Switch = function (_a) {
    var className = _a.className, isActive = _a.isActive, onChange = _a.onChange, name = _a.name, onClick = _a.onClick;
    return (React.createElement(SwitchWrapper, { className: className, isActive: isActive, type: "range", min: "0", max: "1", value: isActive ? '1' : '0', onChange: onChange, onClick: onClick, readOnly: !onChange, name: name, "aria-label": "switch-wrapper" }));
};
Switch.defaultProps = { isActive: false };
export default Switch;
var templateObject_1;
