var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState } from 'react';
import { TabbedContent, Input } from '@src/components/atoms';
import PhoneNumberInput from '@src/components/molecules/PhoneNumberInput';
import { tabTypes } from '@src/lib/app-constants';
import { injectIntl, defineMessages } from 'react-intl';
import { Typography } from '@mui/material';
import { TextField } from '@zeals-co-ltd/washi-components';
import BlockSelector from './BlockSelector';
import ScenarioSelector from './ScenarioSelector';
export var ActiveActionSelector;
(function (ActiveActionSelector) {
    ActiveActionSelector["BLOCK"] = "Block";
    ActiveActionSelector["SCENARIO"] = "Scenario";
    ActiveActionSelector["URL"] = "URL";
    ActiveActionSelector["STOCK"] = "Stock";
    ActiveActionSelector["SHARE"] = "Share";
    ActiveActionSelector["PHONE_NUMBER"] = "Phone Number";
    ActiveActionSelector["EMAIL"] = "Email";
})(ActiveActionSelector || (ActiveActionSelector = {}));
var actionSelectorMessages = defineMessages({ Block: { id: "Up5U7K", defaultMessage: "Block" }, Scenario: { id: "5m4j06", defaultMessage: "Scenario" }, URL: { id: "bWjdfa", defaultMessage: "URL" }, Stock: { id: "vuKrlW", defaultMessage: "Stock" }, Share: { id: "OKhRC6", defaultMessage: "Share" }, 'Phone Number': { id: "mXiD5u", defaultMessage: "Phone Number" }, Email: { id: "sy+pv5", defaultMessage: "Email" } });
export var Url = function (_a) {
    var _b = _a.hasError, hasError = _b === void 0 ? false : _b, url = _a.url, onChange = _a.onChange, intl = _a.intl;
    return (React.createElement(React.Fragment, null,
        React.createElement(Typography, { variant: "body1", className: "ImageMapAreaSettingLabel" }, intl.formatMessage({ id: "QGLi0O", defaultMessage: "URL address" })),
        React.createElement(TextField, { fullWidth: true, error: hasError, placeholder: intl.formatMessage({ id: "s7ZM0P", defaultMessage: "Please enter a URL" }), onChange: function (e) { return onChange(e.target.value); }, value: url || '' })));
};
export var Email = function (_a) {
    var hasError = _a.hasError, email = _a.email, onChange = _a.onChange, intl = _a.intl;
    return (React.createElement(Input, { hasError: hasError, placeholder: intl.formatMessage({ id: "8qFwc3", defaultMessage: "Please enter an Email" }), onChange: function (e) { return onChange("mailto:" + e.target.value, e.target.value); }, value: email || '' }));
};
function showError(errorMessage) {
    return (errorMessage && React.createElement("div", { className: "message-wrapper error" }, errorMessage));
}
var ActionSelector = function (_a) {
    var _b;
    var _c = _a.initActionSelectorValue, initActionSelectorValue = _c === void 0 ? {} : _c, onChange = _a.onChange, connectedObject = _a.connectedObject, _d = _a.blocks, blocks = _d === void 0 ? [] : _d, _e = _a.label, label = _e === void 0 ? '' : _e, errors = _a.errors, _f = _a.isValidating, isValidating = _f === void 0 ? false : _f, _g = _a.ignoreEffects, ignoreEffects = _g === void 0 ? [] : _g, children = _a.children, intl = _a.intl;
    var _h = __read(useState(initActionSelectorValue.effect || tabTypes.BLOCK), 2), effect = _h[0], setEffect = _h[1];
    var _j = __read(useState(initActionSelectorValue.url), 2), url = _j[0], setUrl = _j[1];
    var _k = __read(useState(initActionSelectorValue.phoneNumber), 2), phoneNumber = _k[0], setPhoneNumber = _k[1];
    var _l = __read(useState(initActionSelectorValue.email), 2), email = _l[0], setEmail = _l[1];
    // FIXME: Basically, these types for RichMenu and the real data being passed in are not one to one.
    // The compiler says data exists, but runtime is a different story,
    // Please clean up Types surrounding RichMenu and be careful with the type assertions in TS.
    // 1. Using 'in' will allow typescript to infer the correct variable type (NOT IN HERE)
    // 2. Or Choose to use optional chaining syntax: connectedObject?.rich_menu_id
    var isRichMenuArea = Boolean((_b = connectedObject) === null || _b === void 0 ? void 0 : _b.rich_menu_id);
    return (React.createElement("div", { className: "ActionSelector" },
        React.createElement(TabbedContent, { navs: Object.keys(ActiveActionSelector)
                .filter(function (key) { return !ignoreEffects.includes(tabTypes[key]); })
                .map(function (key) { return ({
                id: tabTypes[key],
                text: intl.formatMessage(actionSelectorMessages[ActiveActionSelector[key]]),
            }); }), onNavClick: function (newEffect) {
                if (newEffect === effect)
                    return;
                setEffect(newEffect);
                onChange({ effect: newEffect });
            }, defaultSelectedTab: initActionSelectorValue.effect }, (errors === null || errors === void 0 ? void 0 : errors.effect) ? (React.createElement("div", { id: effect }, showError(errors === null || errors === void 0 ? void 0 : errors.effect))) : ([
            React.createElement("div", { id: tabTypes.BLOCK, key: tabTypes.BLOCK },
                children,
                React.createElement(BlockSelector, { hasError: Boolean(errors === null || errors === void 0 ? void 0 : errors.block_id), connectedObject: connectedObject, blocks: blocks, label: label, isRichMenuArea: isRichMenuArea, handleNewBlockClick: function (blockName) {
                        if (blockName)
                            onChange({ blockName: blockName });
                    }, handleBlockClick: function (blockId) {
                        if (blockId)
                            onChange({ blockId: blockId });
                    }, disconnectBlock: function () {
                        onChange({ blockId: null });
                    } })),
            React.createElement("div", { id: tabTypes.SCENARIO, key: tabTypes.SCENARIO },
                children,
                React.createElement(ScenarioSelector, { hasError: Boolean(errors === null || errors === void 0 ? void 0 : errors.scenario_id), connectedObject: connectedObject, label: label, isRichMenuArea: isRichMenuArea, handleScenarioClick: function (nextScenarioId) {
                        return onChange({ scenarioId: nextScenarioId });
                    }, disconnectScenario: function () {
                        onChange({ scenarioId: null });
                    } })),
            React.createElement("div", { id: tabTypes.URL, key: tabTypes.URL },
                React.createElement(Url, { hasError: Boolean(errors === null || errors === void 0 ? void 0 : errors.url), url: url, onChange: function (url) {
                        onChange({ url: url });
                        setUrl(url);
                    }, intl: intl }),
                showError(errors === null || errors === void 0 ? void 0 : errors.url)),
            React.createElement("div", { id: tabTypes.STOCK, key: tabTypes.STOCK },
                React.createElement("div", { className: "TabbedContent--text" }, intl.formatMessage({ id: "TJoHGt", defaultMessage: "No Configured Item" }))),
            React.createElement("div", { id: tabTypes.SHARE, key: tabTypes.SHARE },
                React.createElement("div", { className: "TabbedContent--text" }, intl.formatMessage({ id: "TJoHGt", defaultMessage: "No Configured Item" }))),
            React.createElement("div", { id: tabTypes.PHONE_NUMBER, key: tabTypes.PHONE_NUMBER },
                React.createElement(PhoneNumberInput, { hasError: Boolean(errors === null || errors === void 0 ? void 0 : errors.url), initValue: phoneNumber, onChange: function (phoneNumber, _, originalPhoneNumber) {
                        onChange({
                            phoneNumber: originalPhoneNumber
                                ? "tel:" + phoneNumber
                                : '',
                        });
                        setPhoneNumber(phoneNumber);
                    } }),
                showError(errors === null || errors === void 0 ? void 0 : errors.url)),
            React.createElement("div", { id: tabTypes.EMAIL, key: tabTypes.EMAIL },
                React.createElement(Email, { hasError: Boolean(errors === null || errors === void 0 ? void 0 : errors.url), email: email, onChange: function (email, originalEmail) {
                        onChange({ email: originalEmail ? email : '' });
                        setEmail(originalEmail);
                    }, intl: intl }),
                showError(errors === null || errors === void 0 ? void 0 : errors.url)),
        ]))));
};
export default injectIntl(ActionSelector);
