import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { colors } from '@src/colors';
import { injectIntl } from 'react-intl';
var propTypes = {
  currentTitle: PropTypes.string
};
var StepBlock = styled.div.withConfig({
  displayName: "StepBlock",
  componentId: "sc-1djqxl6-0"
})(["ol{display:flex;justify-content:center;}list-style-type:none;margin-left:500px;display:table;width:100%;padding:0;margin:0;margin-bottom:50px;overflow:hidden;li{display:table-cell;position:relative;background:", ";padding:1em 0.5em 1em 2em;color:", ";}li:before,li:after{content:'';position:absolute;width:0;height:0;margin:auto;}li:before{top:-15px;right:-1em;border-style:solid;border-color:transparent transparent transparent ", ";border-width:40px 0 40px 1em;z-index:10;}li:after{top:-15px;right:-0.8em;border-style:solid;border-color:transparent transparent transparent ", ";border-width:40px 0 40px 1em;z-index:10;}"], colors.main_color, colors.white, colors.white, colors.main_color);

var Step = function Step(props) {
  var intl = props.intl;
  return React.createElement("div", null, React.createElement(StepBlock, null, React.createElement("ol", null, React.createElement("li", null, "STEP1:", ' ', intl.formatMessage({
    id: "LW76Sz",
    defaultMessage: [{
      "type": 0,
      "value": "Select Delivery Type"
    }]
  })), React.createElement("li", null, "STEP2:", ' ', intl.formatMessage({
    id: "CPXFdm",
    defaultMessage: [{
      "type": 0,
      "value": "Set Delivery Time"
    }]
  })), React.createElement("li", null, "STEP3:", ' ', intl.formatMessage({
    id: "OsemAt",
    defaultMessage: [{
      "type": 0,
      "value": "Select Scenario"
    }]
  })))), React.createElement("h1", null, props.currentTitle));
};

Step.propTypes = propTypes;
export default injectIntl(Step);