var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import uniqBy from 'lodash/uniqBy';
import { updateUserDetails } from '@src/redux/fanp/lib/talkRoom';
import { TalkType } from '../types/talk';
export var initialState = {
    isFetching: false,
    isModalOpen: false,
    activeEndUser: {},
    endUsers: [],
    filteredEndUsers: [],
    eventLogs: [],
    isLoadingLogHistory: false,
    isLoadingLogs: false,
    isLoadingUser: false,
    isLoadingUsers: false,
    isLoadedEndUsers: false,
    scenarios: {},
    layers: {},
    blocks: {},
    filterText: '',
};
export default (function (state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        // END USERS
        case TalkType.UPDATE_USER_TAG_TALKROOM: {
            var activeEndUser = state.activeEndUser;
            var tags = action.deleteTag
                ? activeEndUser.tags.filter(function (tag) { return tag.name !== action.tag.name; })
                : __spreadArray(__spreadArray([], __read(activeEndUser.tags)), [action.tag]);
            return Object.assign({}, state, {
                activeEndUser: __assign(__assign({}, activeEndUser), { tags: tags }),
            });
        }
        case TalkType.SET_FILTER_TEXT:
            return Object.assign({}, state, { filterText: action.filterText });
        case TalkType.SET_ACTIVE_END_USER:
            return Object.assign({}, state, {
                activeEndUser: Object.assign({}, action.endUser),
            });
        case TalkType.RESET_END_USERS:
            return Object.assign({}, state, { endUsers: [] });
        case TalkType.SET_TALK_END_USERS:
            return Object.assign({}, state, { endUsers: action.endUsers });
        case TalkType.SET_LOADED_END_USERS:
            return Object.assign({}, state, {
                isLoadedEndUsers: action.isLoading,
            });
        case TalkType.PUSH_NEW_TALK_END_USERS:
            return Object.assign({}, state, {
                endUsers: __spreadArray(__spreadArray([], __read(state.endUsers)), __read(action.newEndUsers)),
            });
        case TalkType.UNSHIFT_NEW_TALK_END_USERS: {
            var diffedEndUsers = uniqBy(__spreadArray(__spreadArray([], __read(action.newEndUsers)), __read(state.endUsers)), 'id');
            return Object.assign({}, state, { endUsers: diffedEndUsers });
        }
        // filter
        case TalkType.REMOVE_FILTERED_END_USERS:
            return Object.assign({}, state, { filteredEndUsers: [] });
        case TalkType.SET_FILTERED_END_USERS:
            return Object.assign({}, state, {
                filteredEndUsers: action.endUsers,
            });
        case TalkType.PUSH_FILTERED_END_USERS:
            return Object.assign({}, state, {
                filteredEndUsers: __spreadArray(__spreadArray([], __read(state.filteredEndUsers)), __read(action.endUsers)),
            });
        // EVENT LOGS
        case TalkType.SET_EVENT_LOGS:
            return Object.assign({}, state, { eventLogs: action.eventLogs });
        case TalkType.PUSH_EVENT_LOG: {
            return Object.assign({}, state, {
                eventLogs: __spreadArray(__spreadArray([], __read(state.eventLogs)), [action.eventLog]),
            });
        }
        case TalkType.PUSH_EVENT_LOGS:
            return Object.assign({}, state, {
                eventLogs: __spreadArray(__spreadArray([], __read(state.eventLogs)), __read(action.eventLogs)),
            });
        case TalkType.SHIFT_EVENT_LOGS:
            return Object.assign({}, state, {
                eventLogs: __spreadArray(__spreadArray([], __read(action.eventLogs)), __read(state.eventLogs)),
            });
        case TalkType.SET_IS_LOADING_LOG_HISTORY:
            return Object.assign({}, state, {
                isLoadingLogHistory: action.isLoading,
            });
        case TalkType.SET_IS_LOADING_LOGS:
            return Object.assign({}, state, {
                isLoadingLogs: action.isLoading,
            });
        case TalkType.SET_IS_LOADING_USER:
            return Object.assign({}, state, {
                isLoadingUser: action.isLoading,
            });
        case TalkType.SET_IS_LOADING_USERS:
            return Object.assign({}, state, {
                isLoadingUsers: action.isLoading,
            });
        case TalkType.UPDATE_USER_DETAILS: {
            var endUsers = updateUserDetails(state.endUsers, action);
            return Object.assign({}, state, {
                activeEndUser: __assign(__assign({}, state.activeEndUser), action.endUser),
                endUsers: endUsers,
                filteredEndUsers: updateUserDetails(state.filteredEndUsers, action),
            });
        }
        // SCENARIOS
        case TalkType.SET_SCENARIOS_FOR_TALK:
            return Object.assign({}, state, {
                scenarios: action.scenarios,
                layers: action.layers,
                blocks: action.blocks,
            });
        default:
            return state;
    }
});
