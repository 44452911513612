var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { UserDossierServiceClient } from 'zeals-protobuf/zeals/web/services/User_dossierServiceClientPb';
import * as user_dossierPbService from 'zeals-protobuf/zeals/web/services/user_dossier_pb';
import { Int64Value, StringValue, } from 'google-protobuf/google/protobuf/wrappers_pb';
import { FieldCollectionType, } from 'zeals-protobuf/zeals/web/entities/user_dossier_pb';
import { PROFILE_FIELD_COLLECTION_NAME, findProfileFieldCollection, } from '@src/lib/userDossier';
import { userLabelFromObj } from '@src/lib/userLabel';
var getHeaders = function () {
    return { Authorization: localStorage.getItem('GRPC_JWT_TOKEN') };
};
function createUserDossierServiceClient() {
    var userDossierServiceURL = localStorage.getItem('USER_DOSSIER_SERVICE_URL');
    if (!userDossierServiceURL) {
        throw new Error('createUserDossierServiceClient userDossierServiceURL is not setted ');
    }
    return new UserDossierServiceClient(userDossierServiceURL, null, null);
}
export function listFieldCollections(chatbotId) {
    return __awaiter(this, void 0, void 0, function () {
        var request, client, res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    request = new user_dossierPbService.ListFieldCollectionsRequest();
                    request.setChatbotId(chatbotId);
                    client = createUserDossierServiceClient();
                    return [4 /*yield*/, client.listFieldCollections(request, getHeaders())];
                case 1:
                    res = _a.sent();
                    return [2 /*return*/, res.toObject()];
            }
        });
    });
}
export function getFieldCollection(fieldCollectionId) {
    return __awaiter(this, void 0, void 0, function () {
        var request, client, res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    request = new user_dossierPbService.GetFieldCollectionRequest();
                    request.setFieldCollectionId(fieldCollectionId);
                    client = createUserDossierServiceClient();
                    return [4 /*yield*/, client.getFieldCollection(request, getHeaders())];
                case 1:
                    res = _a.sent();
                    return [2 /*return*/, res.toObject()];
            }
        });
    });
}
export var searchUserLabels = function (keyword, chatbotId) { return __awaiter(void 0, void 0, void 0, function () {
    var request, chatbotIDInt64Value, client, res, userLabelsList;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                request = new user_dossierPbService.SearchUserLabelsRequest();
                chatbotIDInt64Value = new Int64Value();
                chatbotIDInt64Value.setValue(chatbotId);
                request.setChatbotId(chatbotIDInt64Value);
                if (keyword) {
                    request.setKeyword(keyword);
                }
                client = createUserDossierServiceClient();
                return [4 /*yield*/, client.searchUserLabels(request, getHeaders())];
            case 1:
                res = _a.sent();
                userLabelsList = res.toObject().userLabelsList;
                return [2 /*return*/, userLabelsList];
        }
    });
}); };
export var searchUserSegment = function (keyword, chatbotId) { return __awaiter(void 0, void 0, void 0, function () {
    var request, chatbotIDInt64Value, client, res, userSegmentsList;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                request = new user_dossierPbService.SearchUserSegmentsRequest();
                chatbotIDInt64Value = new Int64Value();
                chatbotIDInt64Value.setValue(chatbotId);
                request.setChatbotId(chatbotIDInt64Value);
                if (keyword) {
                    request.setKeyword(keyword);
                }
                client = createUserDossierServiceClient();
                return [4 /*yield*/, client.searchUserSegments(request, getHeaders())];
            case 1:
                res = _a.sent();
                userSegmentsList = res.toObject().userSegmentsList;
                return [2 /*return*/, userSegmentsList];
        }
    });
}); };
export var searchFields = function (keyword, chatbotId) { return __awaiter(void 0, void 0, void 0, function () {
    var request, chatbotIDInt64Value, client, res, fieldsList;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                request = new user_dossierPbService.SearchFieldsRequest();
                if (chatbotId) {
                    chatbotIDInt64Value = new Int64Value();
                    chatbotIDInt64Value.setValue(chatbotId);
                    request.setChatbotId(chatbotIDInt64Value);
                }
                if (keyword) {
                    request.setKeyword(keyword);
                }
                client = createUserDossierServiceClient();
                return [4 /*yield*/, client.searchFields(request, getHeaders())];
            case 1:
                res = _a.sent();
                fieldsList = res.toObject().fieldsList;
                return [2 /*return*/, fieldsList];
        }
    });
}); };
/**
 * Gets the "Profile" FieldCollection used in RPA Config
 * Throws error if function cannot get the collection, or create the collection
 * as backup
 */
// TODO: use GetFieldCollectionRequest to replace this one
export function getProfileFieldCollection(chatbotId) {
    return __awaiter(this, void 0, Promise, function () {
        var listFieldCollectionsResponse, profileFieldCollection, fieldCollection;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, listFieldCollections(chatbotId)];
                case 1:
                    listFieldCollectionsResponse = _a.sent();
                    profileFieldCollection = findProfileFieldCollection(listFieldCollectionsResponse.fieldCollectionsList);
                    if (profileFieldCollection)
                        return [2 /*return*/, profileFieldCollection];
                    return [4 /*yield*/, createFieldCollection(FieldCollectionType.FIELD_COLLECTION_TYPE_UNIQUE, PROFILE_FIELD_COLLECTION_NAME, chatbotId)];
                case 2:
                    fieldCollection = _a.sent();
                    if (!fieldCollection)
                        throw new Error('Could not create "Profile" Field Collection');
                    return [2 /*return*/, fieldCollection];
            }
        });
    });
}
export function createFieldCollection(type, name, chatbotId, fieldsList) {
    return __awaiter(this, void 0, Promise, function () {
        var request, client, res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    request = new user_dossierPbService.CreateFieldCollectionRequest();
                    request.setName(name);
                    request.setChatbotId(chatbotId);
                    request.setFieldCollectionType(type);
                    client = createUserDossierServiceClient();
                    return [4 /*yield*/, client.createFieldCollection(request, getHeaders())];
                case 1:
                    res = _a.sent();
                    return [2 /*return*/, res.toObject()];
            }
        });
    });
}
export var createField = function (data) { return __awaiter(void 0, void 0, void 0, function () {
    var chatbotId, name, inputType, dataType, fieldCollectionType, request, chatbotIDInt64Value, client, res;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                chatbotId = data.chatbotId, name = data.name, inputType = data.inputType, dataType = data.dataType, fieldCollectionType = data.fieldCollectionType;
                request = new user_dossierPbService.CreateFieldRequest();
                request.setName(name);
                request.setInputType(inputType);
                request.setDataType(dataType);
                request.setFieldCollectionType(fieldCollectionType);
                if (chatbotId) {
                    chatbotIDInt64Value = new Int64Value();
                    chatbotIDInt64Value.setValue(chatbotId);
                    request.setChatbotId(chatbotIDInt64Value);
                }
                client = createUserDossierServiceClient();
                return [4 /*yield*/, client.createField(request, getHeaders())];
            case 1:
                res = _a.sent();
                return [2 /*return*/, res.toObject()];
        }
    });
}); };
export var createUserLabel = function (chatbotid, data) { return __awaiter(void 0, void 0, void 0, function () {
    var request, name, operator, chatbotId, chatbotIDInt64Value, f, client, res;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                request = new user_dossierPbService.CreateUserLabelRequest();
                name = data.name, operator = data.operator, chatbotId = data.chatbotId;
                request.setName(name);
                request.setOperator(operator);
                if (chatbotid) {
                    chatbotIDInt64Value = new Int64Value();
                    chatbotIDInt64Value.setValue(chatbotid);
                    request.setChatbotId(chatbotIDInt64Value);
                }
                f = userLabelFromObj(data);
                request.setConditionsList(f.getConditionsList());
                client = createUserDossierServiceClient();
                return [4 /*yield*/, client.createUserLabel(request, getHeaders())];
            case 1:
                res = _a.sent();
                return [2 /*return*/, res.toObject()];
        }
    });
}); };
export function updateUserLabel(data) {
    return __awaiter(this, void 0, void 0, function () {
        var request, id, name, operator, nameStringValue, f, client, res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    request = new user_dossierPbService.UpdateUserLabelRequest();
                    id = data.id, name = data.name, operator = data.operator;
                    if (data.name) {
                        nameStringValue = new StringValue();
                        nameStringValue.setValue(name);
                        request.setName(nameStringValue);
                    }
                    request.setOperator(operator);
                    request.setUserLabelId(id);
                    f = userLabelFromObj(data);
                    request.setConditionsList(f.getConditionsList());
                    client = createUserDossierServiceClient();
                    return [4 /*yield*/, client.updateUserLabel(request, getHeaders())];
                case 1:
                    res = _a.sent();
                    return [2 /*return*/, res.toObject()];
            }
        });
    });
}
export function updateField(data) {
    return __awaiter(this, void 0, void 0, function () {
        var request, nameStringValue, client, res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    request = new user_dossierPbService.UpdateFieldRequest();
                    if (data.name) {
                        nameStringValue = new StringValue();
                        nameStringValue.setValue(data.name);
                        request.setName(nameStringValue);
                    }
                    request.setFieldId(data.id);
                    request.setDataType(data.dataType);
                    request.setFieldCollectionType(data.fieldCollectionType);
                    request.setInputType(data.inputType);
                    client = createUserDossierServiceClient();
                    return [4 /*yield*/, client.updateField(request, getHeaders())];
                case 1:
                    res = _a.sent();
                    return [2 /*return*/, res.toObject()];
            }
        });
    });
}
export function linkFieldToFieldCollection(fieldCollectionId, fieldId) {
    return __awaiter(this, void 0, void 0, function () {
        var request, client, res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    request = new user_dossierPbService.LinkFieldRequest();
                    request.setFieldCollectionId(fieldCollectionId);
                    request.setFieldId(fieldId);
                    client = createUserDossierServiceClient();
                    return [4 /*yield*/, client.linkField(request, getHeaders())];
                case 1:
                    res = _a.sent();
                    return [2 /*return*/, res.toObject()];
            }
        });
    });
}
export function deleteField(fieldId) {
    return __awaiter(this, void 0, void 0, function () {
        var request, client, res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    request = new user_dossierPbService.DeleteFieldRequest();
                    request.setFieldId(fieldId);
                    client = createUserDossierServiceClient();
                    return [4 /*yield*/, client.deleteField(request, getHeaders())];
                case 1:
                    res = _a.sent();
                    return [2 /*return*/, res.toObject()];
            }
        });
    });
}
export var createUserSegment = function (chatbotid, data) { return __awaiter(void 0, void 0, void 0, function () {
    var request, name, operator, userLabelsList, chatbotIDInt64Value, UserLabelId, client, res;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                request = new user_dossierPbService.CreateUserSegmentRequest();
                name = data.name, operator = data.operator, userLabelsList = data.userLabelsList;
                request.setName(name);
                request.setOperator(operator);
                if (chatbotid) {
                    chatbotIDInt64Value = new Int64Value();
                    chatbotIDInt64Value.setValue(chatbotid);
                    request.setChatbotId(chatbotIDInt64Value);
                }
                UserLabelId = [];
                userLabelsList.map(function (item) {
                    UserLabelId.push(item.id);
                    return null;
                });
                request.setUserLabelIdsList(UserLabelId);
                client = createUserDossierServiceClient();
                return [4 /*yield*/, client.createUserSegment(request, getHeaders())];
            case 1:
                res = _a.sent();
                return [2 /*return*/, res.toObject()];
        }
    });
}); };
export function updateUserSegment(data) {
    return __awaiter(this, void 0, void 0, function () {
        var request, id, name, operator, userLabelsList, nameStringValue, UserLabelId, client, res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    request = new user_dossierPbService.UpdateUserSegmentRequest();
                    id = data.id, name = data.name, operator = data.operator, userLabelsList = data.userLabelsList;
                    if (data.name) {
                        nameStringValue = new StringValue();
                        nameStringValue.setValue(name);
                        request.setName(nameStringValue);
                    }
                    request.setOperator(operator);
                    request.setUserSegmentId(id);
                    UserLabelId = [];
                    userLabelsList.map(function (item) {
                        UserLabelId.push(item.id);
                        return null;
                    });
                    request.setUserLabelIdsList(UserLabelId);
                    client = createUserDossierServiceClient();
                    return [4 /*yield*/, client.updateUserSegment(request, getHeaders())];
                case 1:
                    res = _a.sent();
                    return [2 /*return*/, res.toObject()];
            }
        });
    });
}
export function deleteUserSegment(userSegmentlId) {
    return __awaiter(this, void 0, void 0, function () {
        var request, client, res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    request = new user_dossierPbService.DeleteUserSegmentRequest();
                    request.setUserSegmentId(userSegmentlId);
                    client = createUserDossierServiceClient();
                    return [4 /*yield*/, client.deleteUserSegment(request, getHeaders())];
                case 1:
                    res = _a.sent();
                    return [2 /*return*/, res.toObject()];
            }
        });
    });
}
export function deleteUserLabel(userlabelId) {
    return __awaiter(this, void 0, void 0, function () {
        var request, client, res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    request = new user_dossierPbService.DeleteUserLabelRequest();
                    request.setUserLabelId(userlabelId);
                    client = createUserDossierServiceClient();
                    return [4 /*yield*/, client.deleteUserLabel(request, getHeaders())];
                case 1:
                    res = _a.sent();
                    return [2 /*return*/, res.toObject()];
            }
        });
    });
}
export function getAttributeCollection(endUserId, fieldCollectionId) {
    return __awaiter(this, void 0, void 0, function () {
        var request, client, res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    request = new user_dossierPbService.GetAttributeCollectionRequest();
                    request.setEndUserId(endUserId);
                    request.setFieldCollectionId(fieldCollectionId);
                    client = createUserDossierServiceClient();
                    return [4 /*yield*/, client.getAttributeCollection(request, getHeaders())];
                case 1:
                    res = _a.sent();
                    return [2 /*return*/, res.toObject()];
            }
        });
    });
}
