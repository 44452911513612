var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { colors } from '@src/colors';
import { Button, Spinner, AlertMessage } from '@src/components/atoms';
import FilterList from '@src/components/molecules/FilterList';
import { centeredFlex } from '@src/lib/mixins';
import { ModifyFilterForm } from '@src/components/organisms/fanp';
import { columnFlex, hoverDelete } from '@src/lib/mixins';
import { handleOpenFilterForm, fetchMessageFilterTags, resetDoneStatus, handleProcessing, } from '@src/redux/fanp/actions/messageFilter';
import buttonTypes from '@src/lib/buttonTypes';
import { injectIntl } from 'react-intl';
import * as attributeQuestionApi from '@src/services/api/attributeQuestion';
import { handleAPIErrors } from '@src/lib/helpers';
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  font-size: 35px;\n  width: 380px;\n  .adicon_close {\n    justify-self: end;\n    font-size: 24px;\n    color: ", ";\n    ", ";\n  }\n\n  .filter_side_spinner {\n    height: 240px;\n  }\n"], ["\n  ", ";\n  font-size: 35px;\n  width: 380px;\n  .adicon_close {\n    justify-self: end;\n    font-size: 24px;\n    color: ", ";\n    ", ";\n  }\n\n  .filter_side_spinner {\n    height: 240px;\n  }\n"])), columnFlex, colors.placeholder, hoverDelete);
var FormContainer = styled(Container)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 100%;\n  padding: 16px;\n  align-items: flex-start;\n  .filter-side-area-dropdown {\n    li {\n      &:nth-child(2) {\n        padding: 4px;\n      }\n    }\n  }\n"], ["\n  width: 100%;\n  padding: 16px;\n  align-items: flex-start;\n  .filter-side-area-dropdown {\n    li {\n      &:nth-child(2) {\n        padding: 4px;\n      }\n    }\n  }\n"])));
var StepTitle = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-size: 20px;\n  color: ", ";\n  font-weight: bold;\n  margin: 0 0 12px;\n"], ["\n  font-size: 20px;\n  color: ", ";\n  font-weight: bold;\n  margin: 0 0 12px;\n"])), colors.icon);
var ContentContainer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  ", "\n  ", "\n  text-align: center;\n  font-size: 16px;\n  height: 300px;\n  background: #fff;\n  margin: 0 auto;\n  color: ", ";\n  vertical-align: middle;\n\n  .content-text {\n    font-weight: bold;\n    margin: 8px 0;\n  }\n  .content-subtext {\n    font-weight: normal;\n  }\n  .adicon_selected {\n    color: ", ";\n    font-size: 40px;\n  }\n"], ["\n  ", "\n  ", "\n  text-align: center;\n  font-size: 16px;\n  height: 300px;\n  background: #fff;\n  margin: 0 auto;\n  color: ", ";\n  vertical-align: middle;\n\n  .content-text {\n    font-weight: bold;\n    margin: 8px 0;\n  }\n  .content-subtext {\n    font-weight: normal;\n  }\n  .adicon_selected {\n    color: ", ";\n    font-size: 40px;\n  }\n"])), columnFlex, centeredFlex, colors.main_color, colors.accent_color);
var AlertMessageContainer = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  position: absolute;\n  right: 24px;\n  width: 224px;\n  height: 50px;\n  top: 100px;\n  box-shadow: 0 1px 20px rgba(0, 0, 0, 0.2);\n\n  .json-alert-message {\n    border-radius: 5px;\n    background: rgba(30, 193, 141, 1);\n\n    .icon-wrapper {\n      display: none;\n    }\n\n    .message-wrapper {\n      font-weight: 700;\n      font-size: 12px;\n      color: white;\n    }\n  }\n"], ["\n  position: absolute;\n  right: 24px;\n  width: 224px;\n  height: 50px;\n  top: 100px;\n  box-shadow: 0 1px 20px rgba(0, 0, 0, 0.2);\n\n  .json-alert-message {\n    border-radius: 5px;\n    background: rgba(30, 193, 141, 1);\n\n    .icon-wrapper {\n      display: none;\n    }\n\n    .message-wrapper {\n      font-weight: 700;\n      font-size: 12px;\n      color: white;\n    }\n  }\n"])));
var Contents = function (_a) {
    var text = _a.text, subtext = _a.subtext;
    return (React.createElement(ContentContainer, null,
        React.createElement("div", null,
            React.createElement("span", { className: "adicon_selected" }),
            React.createElement("p", { className: "content-text" }, text),
            React.createElement("br", null)),
        React.createElement("div", null,
            React.createElement("p", { className: "content-subtext" }, subtext))));
};
var FilterSideArea = function (_a) {
    var chatTypeParam = _a.chatTypeParam, chatbotId = _a.chatbotId, intl = _a.intl;
    var _b = useSelector(function (_a) {
        var messageFilter = _a.messageFilter;
        return messageFilter;
    }), selectedFilterId = _b.selectedFilterId, done = _b.done, isOpenFilterForm = _b.isOpenFilterForm, isProcessing = _b.isProcessing;
    var dispatch = useDispatch();
    var _c = __read(useState(''), 2), lastSuccessMessage = _c[0], setLastSuccessMessage = _c[1];
    var _d = __read(useState(0), 2), lastSelectedFilterId = _d[0], setLastSelectedFilterId = _d[1];
    // lastSuccessMessage, lastSelectedFilterId store last not null
    // done and selectedFilterId value to handle open filter just edited or created
    // on filter list
    useEffect(function () {
        if (!done)
            return;
        setLastSuccessMessage(done);
        setTimeout(function () { return dispatch(resetDoneStatus()); }, 3000);
    }, [done]);
    useEffect(function () {
        if (!selectedFilterId)
            return;
        setLastSelectedFilterId(selectedFilterId);
    }, [selectedFilterId]);
    useEffect(function () {
        dispatch(fetchMessageFilterTags(chatbotId));
        fetchAttributeQuestions();
        // It is only the for cleanup
        return function () { };
    }, []);
    var _e = __read(useState(false), 2), isFocusTag = _e[0], setIsFocusTag = _e[1];
    var _f = __read(useState([]), 2), attributeQuestions = _f[0], setAttributeQuestions = _f[1];
    var fetchAttributeQuestions = function () { return __awaiter(void 0, void 0, void 0, function () {
        var attributeQuestions_1, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    dispatch(handleProcessing(true));
                    return [4 /*yield*/, attributeQuestionApi.getAttributeQuestions(chatbotId)];
                case 1:
                    attributeQuestions_1 = _a.sent();
                    setAttributeQuestions(attributeQuestions_1);
                    dispatch(handleProcessing(false));
                    return [3 /*break*/, 3];
                case 2:
                    e_1 = _a.sent();
                    handleAPIErrors(e_1.message);
                    dispatch(handleProcessing(false));
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var renderSuccessMessage = function () {
        switch (done) {
            case 'create':
                return intl.formatMessage({ id: "ljhrf4", defaultMessage: "Filter successfully created" });
            case 'update':
                return intl.formatMessage({ id: "z9qI2k", defaultMessage: "Filter successfully edited" });
            case 'delete':
                return intl.formatMessage({ id: "eRo6ch", defaultMessage: "Filter successfully deleted" });
            default:
                return null;
        }
    };
    return (React.createElement(Container, null,
        React.createElement(FormContainer, null,
            React.createElement(StepTitle, null, !isOpenFilterForm
                ? intl.formatMessage({ id: "e625LU", defaultMessage: "Filter Summary" })
                : selectedFilterId
                    ? intl.formatMessage({ id: "u0zBXD", defaultMessage: "Edit Filter" })
                    : intl.formatMessage({ id: "Q0U05i", defaultMessage: "New Filter" })),
            done && (React.createElement(AlertMessageContainer, null,
                React.createElement(AlertMessage, { time: 3000, message: renderSuccessMessage(), onRemoveAlert: function () { }, type: "", className: "json-alert-message" }))),
            !isOpenFilterForm && (React.createElement(Button, { buttonType: isProcessing ? buttonTypes.DISABLED : buttonTypes.SETTING, text: intl.formatMessage({ id: "CVm3PB", defaultMessage: "Create New" }), width: 104, minWidth: "unset", margin: "16px 0 0 0", fontWeight: "700", backgroundColor: isProcessing ? colors.background.disabled : colors.icon, onClick: function () {
                    return !isProcessing && dispatch(handleOpenFilterForm(true));
                } })),
            isOpenFilterForm && (React.createElement(ModifyFilterForm, { chatbotId: chatbotId, isFocusTag: isFocusTag, setIsFocusTag: setIsFocusTag, attributeQuestions: attributeQuestions })),
            !isOpenFilterForm &&
                (isProcessing ? (React.createElement(Spinner, { className: "filter_side_spinner" })) : (React.createElement(FilterList, { chatTypeParam: chatTypeParam, chatbotId: chatbotId, setIsFocusTag: setIsFocusTag, lastSuccessMessage: lastSuccessMessage, lastSelectedFilterId: lastSelectedFilterId }))))));
};
export default injectIntl(FilterSideArea);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
