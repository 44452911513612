var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled, { css } from 'styled-components';
import { Input } from '@src/components/atoms';
import { colors } from '@src/colors';
import { injectIntl } from 'react-intl';
var scenarioInfoStyled = css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  .scenario-info {\n    font-size: 14px;\n    padding: 4px 0;\n\n    span {\n      display: inline-block;\n    }\n\n    span:nth-child(1) {\n      width: 12%;\n    }\n\n    span:nth-child(2) {\n      width: 76%;\n    }\n\n    span:nth-child(3) {\n      width: 12%;\n    }\n\n    .test-mode-on {\n      color: ", ";\n    }\n\n    .test-mode-off {\n      color: ", ";\n    }\n  }\n\n  .header {\n    font-size: 10px;\n    font-weight: 700;\n    color: ", ";\n    padding-top: 10px;\n  }\n\n  .scenario-name {\n    text-overflow: ellipsis;\n    overflow: hidden;\n    white-space: nowrap;\n  }\n"], ["\n  .scenario-info {\n    font-size: 14px;\n    padding: 4px 0;\n\n    span {\n      display: inline-block;\n    }\n\n    span:nth-child(1) {\n      width: 12%;\n    }\n\n    span:nth-child(2) {\n      width: 76%;\n    }\n\n    span:nth-child(3) {\n      width: 12%;\n    }\n\n    .test-mode-on {\n      color: ", ";\n    }\n\n    .test-mode-off {\n      color: ", ";\n    }\n  }\n\n  .header {\n    font-size: 10px;\n    font-weight: 700;\n    color: ", ";\n    padding-top: 10px;\n  }\n\n  .scenario-name {\n    text-overflow: ellipsis;\n    overflow: hidden;\n    white-space: nowrap;\n  }\n"])), colors.akatsuki, colors.success, colors.text_navy);
var Container = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  max-height: 200px;\n  overflow-y: scroll;\n  overflow-x: hidden;\n\n  ", "\n\n  .scenario-info {\n    padding: 0.5rem 0.3rem 0.5rem 1rem;\n    height: 45px;\n    cursor: pointer;\n\n    &:hover {\n      background-color: ", ";\n\n      span:not(:last-of-type) {\n        color: ", ";\n      }\n    }\n  }\n\n  .scenario-info:not(:last-of-type) {\n    border-bottom: 1px solid ", ";\n  }\n"], ["\n  max-height: 200px;\n  overflow-y: scroll;\n  overflow-x: hidden;\n\n  ", "\n\n  .scenario-info {\n    padding: 0.5rem 0.3rem 0.5rem 1rem;\n    height: 45px;\n    cursor: pointer;\n\n    &:hover {\n      background-color: ", ";\n\n      span:not(:last-of-type) {\n        color: ", ";\n      }\n    }\n  }\n\n  .scenario-info:not(:last-of-type) {\n    border-bottom: 1px solid ", ";\n  }\n"])), scenarioInfoStyled, colors.background.off_white_background, colors.blue_zeals, colors.background.off_white_background);
var Result = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  padding: 0.5rem;\n  font-size: 0.95rem;\n"], ["\n  padding: 0.5rem;\n  font-size: 0.95rem;\n"])));
var Section = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: inline-block;\n  margin-top: 12px;\n  padding: 0 9px;\n  border-bottom: 1px solid ", ";\n  width: 100%;\n\n  .adicon_search {\n    input {\n      height: 40px;\n    }\n    border: unset;\n    background: ", ";\n    padding: 9px;\n  }\n\n  ", "\n\n  .header {\n    padding: 10px 6px;\n  }\n"], ["\n  display: inline-block;\n  margin-top: 12px;\n  padding: 0 9px;\n  border-bottom: 1px solid ", ";\n  width: 100%;\n\n  .adicon_search {\n    input {\n      height: 40px;\n    }\n    border: unset;\n    background: ", ";\n    padding: 9px;\n  }\n\n  ", "\n\n  .header {\n    padding: 10px 6px;\n  }\n"])), colors.border.default, colors.background.cloud_nine, scenarioInfoStyled);
var Selected = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  margin-top: 12px;\n  padding: 0.5rem 1rem;\n  background: ", ";\n  border-radius: 5px;\n  .label {\n    font-size: unset;\n    padding: unset;\n    color: unset;\n  }\n\n  ", "\n\n  .scenario-info {\n    position: relative;\n\n    span:nth-child(2) {\n      width: 70%;\n    }\n    span:nth-child(3) {\n      width: 12%;\n    }\n    span:nth-child(4) {\n      position: absolute;\n      width: 40px;\n      font-size: 26px;\n      margin-top: 5px;\n      height: 40px;\n      border-radius: 5px;\n      z-index: 1;\n      cursor: pointer;\n\n      :before {\n        position: absolute;\n        left: 7px;\n        top: 8px;\n      }\n\n      :hover {\n        background-color: ", ";\n\n        :before {\n          color: ", ";\n        }\n      }\n    }\n  }\n"], ["\n  margin-top: 12px;\n  padding: 0.5rem 1rem;\n  background: ", ";\n  border-radius: 5px;\n  .label {\n    font-size: unset;\n    padding: unset;\n    color: unset;\n  }\n\n  ", "\n\n  .scenario-info {\n    position: relative;\n\n    span:nth-child(2) {\n      width: 70%;\n    }\n    span:nth-child(3) {\n      width: 12%;\n    }\n    span:nth-child(4) {\n      position: absolute;\n      width: 40px;\n      font-size: 26px;\n      margin-top: 5px;\n      height: 40px;\n      border-radius: 5px;\n      z-index: 1;\n      cursor: pointer;\n\n      :before {\n        position: absolute;\n        left: 7px;\n        top: 8px;\n      }\n\n      :hover {\n        background-color: ", ";\n\n        :before {\n          color: ", ";\n        }\n      }\n    }\n  }\n"])), colors.background.cloud_nine, scenarioInfoStyled, colors.akatsuki, colors.white);
var ListScenario = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  margin-top: 8px;\n  border: 1px solid ", ";\n  border-radius: 5px;\n"], ["\n  margin-top: 8px;\n  border: 1px solid ", ";\n  border-radius: 5px;\n"])), colors.border.default);
var ContentScenarioSearchArea = function (_a) {
    var scenarioSearch = _a.scenarioSearch, filteredScenarios = _a.filteredScenarios, scenario = _a.scenario, handleScenarioSearchUpdate = _a.handleScenarioSearchUpdate, handleScenarioUpdate = _a.handleScenarioUpdate, handleClearSelectedScenario = _a.handleClearSelectedScenario, intl = _a.intl;
    var handledScenarios = scenario
        ? filteredScenarios.filter(function (filteredScenario) { return filteredScenario.id !== scenario.id; })
        : filteredScenarios;
    var InfoHeader = function (props) { return (React.createElement("div", { className: "scenario-info header" },
        React.createElement("span", null, "ID"),
        React.createElement("span", null, intl.formatMessage({ id: "sDv/7f", defaultMessage: "Scenario Name" })),
        React.createElement("span", null, intl.formatMessage({ id: "xJwUmX", defaultMessage: "Test Mode" })),
        props.children)); };
    var renderScenarioInfo = function (s, updateFunc) {
        var testModeValue = s.testing
            ? intl.formatMessage({ id: "Zh+5A6", defaultMessage: "On" })
            : intl.formatMessage({ id: "OvzONl", defaultMessage: "Off" });
        return (React.createElement("div", { "aria-label": "scenario-info-button", role: "button", tabIndex: 0, className: "scenario-info", key: s.id, onClick: function () { return updateFunc && updateFunc(s); } },
            React.createElement("span", null, s.id),
            React.createElement("span", { className: "scenario-name", title: s.name }, s.name),
            React.createElement("span", { className: s.testing ? 'test-mode-on' : 'test-mode-off', "data-testid": "ContentScenarioSearchArea-TestMode" }, testModeValue)));
    };
    return (React.createElement("div", { className: "section" },
        React.createElement("div", { className: "section scenario-select" },
            scenario && (React.createElement(Selected, { onClick: handleClearSelectedScenario, "data-testid": "ContentScenarioSearchArea-Selected" },
                React.createElement("div", { className: "label" }, intl.formatMessage({ id: "1kNe37", defaultMessage: "Selected Scenario" })),
                React.createElement(InfoHeader, null,
                    React.createElement("span", { role: "button", tabIndex: 0, "aria-label": "Clear selected scenario", className: "adicon_delete", onClick: handleClearSelectedScenario })),
                renderScenarioInfo(scenario, null))),
            React.createElement(ListScenario, null,
                React.createElement(Section, null,
                    React.createElement(Input, { className: "adicon_search", value: scenarioSearch, onChange: handleScenarioSearchUpdate, placeholder: intl.formatMessage({ id: "2diSuC", defaultMessage: "Search by Scenario Name/ID" }) }),
                    React.createElement(InfoHeader, null)),
                React.createElement("div", { className: "scenario-select-table child" }, handledScenarios.length ? (React.createElement(Container, null, handledScenarios.map(function (s) {
                    return renderScenarioInfo(s, handleScenarioUpdate);
                }))) : (React.createElement(Result, null,
                    React.createElement("p", { className: "condition", "data-testid": "ContentScenarioSearchArea-Condition" }, intl.formatMessage({ id: "E3gIst", defaultMessage: "No Scenario Name/ID" })))))))));
};
export default injectIntl(ContentScenarioSearchArea);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
