var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import styled from 'styled-components';
import { centeredFlex } from '@src/lib/mixins';
import { colors } from '@src/colors';
var ButtonContainer = styled.button(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", "\n  width: 32px;\n  height: ", ";\n  border-radius: 16px;\n  font-size: 14px;\n  padding: 10px;\n  border: none;\n  background-color: ", ";\n  color: ", ";\n  margin: 10px 10px 0 0;\n"], ["\n  ", "\n  width: 32px;\n  height: ", ";\n  border-radius: 16px;\n  font-size: 14px;\n  padding: 10px;\n  border: none;\n  background-color: ", ";\n  color: ", ";\n  margin: 10px 10px 0 0;\n"])), centeredFlex, function (props) { return props.height + "px"; }, colors.background.off_white_background, colors.text_pale);
var VerticalButton = function (_a) {
    var children = _a.children, height = _a.height, ref = _a.ref, type = _a.type, as = _a.as, htmlButtonProps = __rest(_a, ["children", "height", "ref", "type", "as"]);
    return (React.createElement(ButtonContainer, __assign({}, htmlButtonProps, { height: height }), children));
};
export default VerticalButton;
var templateObject_1;
