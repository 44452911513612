var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import React, { Component } from 'react';
import styled from 'styled-components';
import { Spinner } from '@src/components/atoms';
import { WebchatPanel } from '@src/components/organisms/fanp_webchat';
// @ts-ignore
import { colors, shadows } from '@src/colors';
var BETWEEN_MSG_WAIT_TIME = 500;
var PhoneContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  align-self: center;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  margin: auto;\n  height: 620px;\n  width: 360px;\n  border-radius: 45px;\n  padding: 20px;\n  box-shadow: ", ";\n  background-color: ", ";\n  .phones {\n    height: 10px;\n    width: 30%;\n    border-radius: 5px;\n    margin-bottom: 15px;\n    box-shadow: ", ";\n  }\n  .message-section {\n    border: 2px solid ", ";\n    width: 100%;\n    height: 85%;\n    flex: 8;\n    overflow-y: auto;\n  }\n  .home-button {\n    margin-top: 10px;\n    height: 45px;\n    width: 45px;\n    border-radius: 50%;\n    box-shadow: ", ";\n  }\n"], ["\n  align-self: center;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  margin: auto;\n  height: 620px;\n  width: 360px;\n  border-radius: 45px;\n  padding: 20px;\n  box-shadow: ", ";\n  background-color: ", ";\n  .phones {\n    height: 10px;\n    width: 30%;\n    border-radius: 5px;\n    margin-bottom: 15px;\n    box-shadow: ", ";\n  }\n  .message-section {\n    border: 2px solid ", ";\n    width: 100%;\n    height: 85%;\n    flex: 8;\n    overflow-y: auto;\n  }\n  .home-button {\n    margin-top: 10px;\n    height: 45px;\n    width: 45px;\n    border-radius: 50%;\n    box-shadow: ", ";\n  }\n"])), shadows.small.mid, colors.background.main_menu, shadows.small.mid, colors.osx.controls.urlBar.border, shadows.small.mid);
var WebchatPhone = /** @class */ (function (_super) {
    __extends(WebchatPhone, _super);
    function WebchatPhone() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            messages: [],
            displayedMessages: [],
            OnAnswerHandler: '',
            status: '',
            scenario: null,
            countClick: 0,
            isLoading: false,
        };
        _this.fetchScenario = function () {
            var _a = _this.props, chatbotId = _a.chatbotId, scenarioToken = _a.scenarioToken, scenarioId = _a.scenarioId;
            var authHeader = new Headers();
            authHeader.append('Content-Type', 'application/json; charset=utf-8');
            var configInit = {
                method: 'GET',
                headers: authHeader,
                cache: 'default',
            };
            _this.setState({ isLoading: true });
            // 管理画面でシナリオを取得するときにend_userがcreateされないようにするために0を渡している。
            fetch("/webchats/chatbots/" + chatbotId + "/scenarios/" + scenarioId + "/?token=" + scenarioToken + "&end_user_id=0", configInit)
                .then(function (res) { return res.json(); })
                .then(function (_a) {
                var scenario = _a.scenario;
                _this.setState({ scenario: scenario, isLoading: false });
                _this.setInitialMessages(scenario);
            });
        };
        _this.setInitialMessages = function (scenario) {
            _this.setState({ messages: scenario.messages, displayedMessages: [] }, function () {
                return _this.defaultMessages();
            });
        };
        _this.onHandleAnswer = function (answerInfo) {
            var answer = answerInfo.answer;
            var displayedMessages = _this.state.displayedMessages;
            _this.handleValidAnswer(displayedMessages, answerInfo, answer);
            return;
        };
        _this.createMessageFromAnswer = function (answer, messageId, botAnswerError) {
            var lineFlexMsg = {
                answer: answer,
                messageId: messageId,
            };
            var botFlexMsg = {
                botAnswerError: botAnswerError,
                messageId: messageId,
            };
            var answerMsg = {
                isChatbot: answer ? false : true,
                line_flex_message: answer ? lineFlexMsg : botFlexMsg,
            };
            return answerMsg;
        };
        _this.pushBotMsg = function (messageId) {
            var _a = _this.state, messages = _a.messages, displayedMessages = _a.displayedMessages;
            var message = messages.find(function (m) { return m.id === messageId; });
            var newMessages = messages.filter(function (m) { return m.id !== messageId; });
            var newDisplayedMessages = __spreadArray(__spreadArray([], __read(displayedMessages)), [message]);
            _this.setState({
                messages: newMessages,
                displayedMessages: newDisplayedMessages,
            });
        };
        _this.pushBotBlock = function (blockId) {
            var messages = _this.state.messages;
            var nextBotMsgs = messages
                .filter(function (m) { return m.block_id === Number(blockId); })
                .sort(function (m1, m2) { return (m1.rank > m2.rank ? 1 : -1); })
                .map(function (m) { return m.id; });
            nextBotMsgs.forEach(function (messageId, i) {
                setTimeout(function () {
                    _this.pushBotMsg(messageId);
                    _this.setState({ status: '' });
                }, BETWEEN_MSG_WAIT_TIME * i);
            });
        };
        _this.handleValidAnswer = function (displayedMessages, answerInfo, text) {
            var userMsg = _this.createMessageFromAnswer(text, answerInfo.messageId, null);
            _this.setState({ displayedMessages: __spreadArray(__spreadArray([], __read(displayedMessages)), [userMsg]) }, function () {
                setTimeout(function () {
                    _this.setState({ status: 'LOADING' });
                    setTimeout(function () {
                        _this.pushBotBlock(Number(answerInfo.blockId));
                        _this.setState({ status: '' });
                    }, BETWEEN_MSG_WAIT_TIME * 2);
                }, BETWEEN_MSG_WAIT_TIME);
            });
        };
        _this.pushDisplayedMessage = function (message) {
            var displayedMessages = _this.state.displayedMessages;
            _this.setState({
                status: '',
                displayedMessages: __spreadArray(__spreadArray([], __read(displayedMessages)), [message]),
            });
        };
        _this.defaultMessages = function () {
            var _a = _this.state.scenario, messages = _a.messages, first_message_id = _a.first_message_id;
            var firstMsg = messages.find(function (m) { return m.id === first_message_id; });
            _this.pushBotBlock(firstMsg.block_id);
        };
        return _this;
    }
    WebchatPhone.prototype.componentDidMount = function () {
        this.fetchScenario();
    };
    WebchatPhone.prototype.render = function () {
        var webchatApp = this.props.webchatApp;
        var _a = this.state, displayedMessages = _a.displayedMessages, status = _a.status, isLoading = _a.isLoading;
        return (React.createElement(PhoneContainer, null,
            React.createElement("div", { className: "phones" }),
            React.createElement("div", { className: "message-section" }, isLoading ? (React.createElement(Spinner, null)) : (React.createElement(WebchatPanel, { webchatOpened: true, clickHandle: null, displayedMessages: displayedMessages, status: status, webchatApp: webchatApp, onHandleAnswer: this.onHandleAnswer }))),
            React.createElement("div", { className: "home-button" })));
    };
    return WebchatPhone;
}(Component));
export default WebchatPhone;
var templateObject_1;
