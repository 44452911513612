var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import { colors } from '@src/colors';
import { lineFlexMessageStyles } from '@src/lib/app-constants';
function getColor(buttonType, color) {
    var defaultColor = buttonType === 'primary'
        ? colors.line_flex_primary
        : colors.line_flex_default;
    return color || defaultColor;
}
var ButtonContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  flex: ", ";\n  background-color: ", ";\n  color: ", ";\n  min-height: ", ";\n  font-size: 13px;\n  border-radius: 8px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n"], ["\n  width: 100%;\n  flex: ", ";\n  background-color: ",
    ";\n  color: ",
    ";\n  min-height: ", ";\n  font-size: 13px;\n  border-radius: 8px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n"])), function (props) { return props.flex; }, function (props) {
    return props.buttonType === 'primary'
        ? getColor(props.buttonType, props.color)
        : colors.white;
}, function (props) {
    return props.buttonType === 'primary'
        ? colors.white
        : getColor(props.buttonType, props.color);
}, function (props) { return lineFlexMessageStyles.buttonHeights[props.height]; });
var ButtonNode = function (_a) {
    var _b;
    var lineButton = _a.lineButton, updateBoundaryNode = _a.updateBoundaryNode;
    var postbackAction = (_b = lineButton.action) === null || _b === void 0 ? void 0 : _b.postback_action;
    var hasConnection = Boolean((postbackAction === null || postbackAction === void 0 ? void 0 : postbackAction.block_id) ||
        (postbackAction === null || postbackAction === void 0 ? void 0 : postbackAction.scenario_id) ||
        (postbackAction === null || postbackAction === void 0 ? void 0 : postbackAction.url));
    return (React.createElement(ButtonContainer, { flex: lineButton.flex, buttonType: lineButton.style, height: lineButton.height, color: lineButton.color, ref: function (ref) {
            if (!ref || !updateBoundaryNode || !hasConnection)
                return;
            updateBoundaryNode('postbackActions', lineButton.action.postback_action.id, ref.getBoundingClientRect());
        } }, lineButton.action.label));
};
export default ButtonNode;
var templateObject_1;
