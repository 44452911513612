var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Component } from 'react';
import styled from 'styled-components';
import { colors, shadows } from '@src/colors';
import { hoverMenuBg } from '@src/lib/mixins';
import { UploadedFiles } from '@src/fanp/enterprise/talk_customize/components/right_menu_tab_content';
import { mediaTypes } from '@src/lib/app-constants';
var RightMenuContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  transition: 0.3s all;\n  display: flex;\n  position: fixed;\n  right: 0;\n"], ["\n  transition: 0.3s all;\n  display: flex;\n  position: fixed;\n  right: 0;\n"])));
var RightMenuTab = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  color: ", ";\n  text-align: center;\n  align-items: center;\n  height: 40px;\n  width: 40px;\n  box-shadow: ", ";\n  border-right: 0;\n  border-radius: 10px 0 0 10px;\n  font-size: 20px;\n  background: ", ";\n  ", ";\n"], ["\n  color: ", ";\n  text-align: center;\n  align-items: center;\n  height: 40px;\n  width: 40px;\n  box-shadow: ", ";\n  border-right: 0;\n  border-radius: 10px 0 0 10px;\n  font-size: 20px;\n  background: ", ";\n  ", ";\n"])), function (_a) {
    var active = _a.active;
    return (active ? colors.main_color : colors.icon);
}, shadows.small.light, colors.background.main_menu, hoverMenuBg);
var RightMenuArea = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  min-width: 400px;\n  background: ", ";\n  box-shadow: ", ";\n  padding: 24px;\n"], ["\n  min-width: 400px;\n  background: ", ";\n  box-shadow: ", ";\n  padding: 24px;\n"])), colors.background.main_menu, shadows.small.light);
var ChatlinkRightMenu = /** @class */ (function (_super) {
    __extends(ChatlinkRightMenu, _super);
    function ChatlinkRightMenu() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = { isRightMenuExpanded: false };
        _this.handleExpandClick = function () {
            return _this.setState({ isRightMenuExpanded: !_this.state.isRightMenuExpanded });
        };
        return _this;
    }
    ChatlinkRightMenu.prototype.render = function () {
        var isRightMenuExpanded = this.state.isRightMenuExpanded;
        return (React.createElement(RightMenuContainer, null,
            React.createElement(RightMenuTab, { onClick: this.handleExpandClick, active: isRightMenuExpanded },
                React.createElement("span", { className: "adicon_placeholder_image" })),
            isRightMenuExpanded && (React.createElement(RightMenuArea, null,
                React.createElement(UploadedFiles, { key: "uploaded_images", chatbotIdParam: this.props.chatbotId, mediaType: mediaTypes.IMAGE })))));
    };
    return ChatlinkRightMenu;
}(Component));
export default ChatlinkRightMenu;
var templateObject_1, templateObject_2, templateObject_3;
