var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import { colors, shadows } from '@src/colors';
import { transitions } from '@src/lib/animations';
import { injectIntl } from 'react-intl';
var AddItemButton = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  padding: 8px 12px 8px 8px;\n  text-align: center;\n  border-radius: 50px;\n  margin-left: 4px;\n  color: ", ";\n  transition: ", ";\n  .button-wrapper {\n    display: block;\n  }\n  .adicon_plus01,\n  .adicon_plus03,\n  .adicon_minus01,\n  .adicon_minus03 {\n    font-size: 20px;\n    position: relative;\n    margin-right: 4px;\n  }\n  .selector-text {\n    font-size: 14px;\n    font-weight: bold;\n  }\n  &:hover {\n    background: ", ";\n  }\n"], ["\n  display: flex;\n  align-items: center;\n  padding: 8px 12px 8px 8px;\n  text-align: center;\n  border-radius: 50px;\n  margin-left: 4px;\n  color: ", ";\n  transition: ", ";\n  .button-wrapper {\n    display: block;\n  }\n  .adicon_plus01,\n  .adicon_plus03,\n  .adicon_minus01,\n  .adicon_minus03 {\n    font-size: 20px;\n    position: relative;\n    margin-right: 4px;\n  }\n  .selector-text {\n    font-size: 14px;\n    font-weight: bold;\n  }\n  &:hover {\n    background: ", ";\n  }\n"])), function (props) { return props.color; }, transitions.normal, colors.background.light_purple);
var TrackPushTag = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  > li {\n    background-color: ", ";\n    border: 1px solid ", ";\n    box-shadow: ", ";\n    border-radius: 5px;\n    padding: 0 12px 0 16px;\n    display: flex;\n    margin-bottom: 8px;\n    transition: ", ";\n    &:hover {\n      box-shadow: ", ";\n    }\n    .tag-label {\n      line-height: 48px;\n      display: flex;\n      flex-direction: row;\n      align-items: center;\n    }\n    .adicon_tag {\n      font-size: 16px;\n    }\n    .tag-name {\n      font-size: 12px;\n    }\n    .buttons {\n      display: flex;\n      margin-left: auto;\n      padding: 4px 0;\n      > div {\n        &:hover {\n          cursor: pointer;\n        }\n      }\n    }\n  }\n"], ["\n  > li {\n    background-color: ", ";\n    border: 1px solid ", ";\n    box-shadow: ", ";\n    border-radius: 5px;\n    padding: 0 12px 0 16px;\n    display: flex;\n    margin-bottom: 8px;\n    transition: ", ";\n    &:hover {\n      box-shadow: ", ";\n    }\n    .tag-label {\n      line-height: 48px;\n      display: flex;\n      flex-direction: row;\n      align-items: center;\n    }\n    .adicon_tag {\n      font-size: 16px;\n    }\n    .tag-name {\n      font-size: 12px;\n    }\n    .buttons {\n      display: flex;\n      margin-left: auto;\n      padding: 4px 0;\n      > div {\n        &:hover {\n          cursor: pointer;\n        }\n      }\n    }\n  }\n"])), colors.white, colors.border.default, shadows.small.light, transitions.normal, shadows.medium.mid);
var TrackPushTagList = function (_a) {
    var selectedAttributeAnswerId = _a.selectedAttributeAnswerId, targetAttributeAnswers = _a.targetAttributeAnswers, onAddTag = _a.onAddTag, onRemoveTag = _a.onRemoveTag, searchTerm = _a.searchTerm, intl = _a.intl;
    var searchAttributeAnswersData = function () {
        return targetAttributeAnswers.filter(function (targetAttributeAnswer) {
            return (targetAttributeAnswer.label &&
                targetAttributeAnswer.label.includes(searchTerm));
        });
    };
    var isSelectedTag = function (tagId) {
        return tagId === selectedAttributeAnswerId;
    };
    return (React.createElement(TrackPushTag, { "data-testid": "attributeAnswer_items_container" }, targetAttributeAnswers &&
        searchAttributeAnswersData().map(function (targetAttributeAnswer) {
            return (React.createElement("li", { "data-testid": "attributeAnswer_item", key: targetAttributeAnswer.id },
                React.createElement("span", { className: "tag-label" },
                    React.createElement("span", { className: "adicon_tag" }),
                    React.createElement("span", { "data-testid": "attributeAnswer-name", className: "tag-name" }, targetAttributeAnswer.label || 'labelなし')),
                React.createElement("div", { className: "buttons" },
                    React.createElement(AddItemButton, { "data-testid": "include-button", color: isSelectedTag(targetAttributeAnswer.id)
                            ? colors.error
                            : colors.accent_color, onClick: function () {
                            return isSelectedTag(targetAttributeAnswer.id)
                                ? onRemoveTag()
                                : onAddTag(targetAttributeAnswer.id);
                        } },
                        isSelectedTag(targetAttributeAnswer.id) ? (React.createElement("span", { className: "adicon_minus01", "data-testid": "TrackPushTagList_MinusIcon" })) : (React.createElement("span", { className: "adicon_plus01", "data-testid": "TrackPushTagList_PlusIcon" })),
                        React.createElement("span", { className: "selector-text" },
                            ' ', "" + (isSelectedTag(targetAttributeAnswer.id)
                            ? intl.formatMessage({ id: "3w8uQ9", defaultMessage: "Delete Selection" })
                            : intl.formatMessage({ id: "kQAf2d", defaultMessage: "Select" })))))));
        })));
};
export default injectIntl(TrackPushTagList);
var templateObject_1, templateObject_2;
