export default {
    AD_SET_QUICK_REPLY: 'AD_SET_QUICK_REPLY',
    ADD_CREATED_QUICK_REPLY: 'ADD_CREATED_QUICK_REPLY',
    AD_REMOVE_QUICK_REPLY: 'AD_REMOVE_QUICK_REPLY',
    AD_HANDLE_QUICK_REPLY_CHOICE: 'AD_HANDLE_QUICK_REPLY_CHOICE',
    AD_HANDLE_REMOVE_QUICK_REPLY_CHOICE: 'AD_HANDLE_REMOVE_QUICK_REPLY_CHOICE',
    DESELECT_ALL_CHOICES: 'DESELECT_ALL_CHOICES',
    AD_SET_QUICK_REPLY_CHOICE: 'AD_SET_QUICK_REPLY_CHOICE',
    AD_SET_QUICK_REPLY_CHOICES: 'AD_SET_QUICK_REPLY_CHOICES',
    AD_UPDATE_QUICK_REPLY_CHOICE: 'AD_UPDATE_QUICK_REPLY_CHOICE',
};
