var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import { colors } from '@src/colors';
import { hoverActionBasic } from '@src/lib/mixins';
var FilterTagContainer = styled.span(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  height: 48px;\n  border: 1px solid\n    ", ";\n  display: flex;\n  align-items: center;\n  border-radius: 5px;\n  padding: 8px;\n  overflow: auto;\n\n  .show-all {\n    color: ", ";\n    font-size: 14px;\n    margin-left: auto;\n    flex-shrink: 0;\n    padding-right: 8px;\n    ", ";\n  }\n"], ["\n  width: 100%;\n  height: 48px;\n  border: 1px solid\n    ", ";\n  display: flex;\n  align-items: center;\n  border-radius: 5px;\n  padding: 8px;\n  overflow: auto;\n\n  .show-all {\n    color: ", ";\n    font-size: 14px;\n    margin-left: auto;\n    flex-shrink: 0;\n    padding-right: 8px;\n    ", ";\n  }\n"])), function (props) { return (props.hasError ? colors.border.error : colors.border.default); }, colors.main_color, hoverActionBasic);
export default FilterTagContainer;
var templateObject_1;
