import React from 'react';
import PropTypes from 'prop-types';
import { colors } from '@src/colors';
import styled from 'styled-components';
import { injectIntl } from 'react-intl';
import Campaigns from './Campaigns';
var CampaignDisplayTable = styled.table.withConfig({
  displayName: "CampaignDisplayTable",
  componentId: "sc-1xvf3tt-0"
})(["table-layout:fixed;width:100%;margin-left:auto;margin-right:auto;border-spacing:0;border-collapse:collapse;border-top:1px solid ", ";thead{width:inherit;font-size:0.75rem;font-weight:bold;color:", ";th{padding:0.5rem 0;border-bottom:1px solid ", ";background:", ";&:nth-child(1){width:5%;}&:nth-child(2){width:15%;}&:nth-child(3){width:6%;}&:nth-child(4){width:15%;}&:nth-child(5){width:6%;}&:nth-child(6){width:13%;}&:nth-child(7){width:40%;}}}tr{border-bottom:1px solid ", ";td{padding:0.5rem;}}"], colors.border.default, colors.text_pale, colors.border.default, colors.white, colors.border.default);

var CampaignTable = function CampaignTable(_ref) {
  var targetId = _ref.targetId,
      retentionCampaigns = _ref.retentionCampaigns,
      chatbotId = _ref.chatbotId,
      handleDeleteCampaign = _ref.handleDeleteCampaign,
      updateCampaign = _ref.updateCampaign,
      intl = _ref.intl;
  return React.createElement(CampaignDisplayTable, null, React.createElement("thead", null, React.createElement("tr", null, React.createElement("th", null), React.createElement("th", null), React.createElement("th", null, intl.formatMessage({
    id: "XMpO69",
    defaultMessage: [{
      "type": 0,
      "value": "Condition"
    }]
  })), React.createElement("th", null, intl.formatMessage({
    id: "xy13gr",
    defaultMessage: [{
      "type": 0,
      "value": "Delivery Timing"
    }]
  })), React.createElement("th", null, intl.formatMessage({
    id: "cz9m9O",
    defaultMessage: [{
      "type": 0,
      "value": "Delivery Time"
    }]
  })), React.createElement("th", null, intl.formatMessage({
    id: "6fpLnn",
    defaultMessage: [{
      "type": 0,
      "value": "Option"
    }]
  })), React.createElement("th", null, intl.formatMessage({
    id: "86ZDUc",
    defaultMessage: [{
      "type": 0,
      "value": "Delivered Scenario"
    }]
  })))), React.createElement(Campaigns, {
    retentionCampaigns: retentionCampaigns,
    targetId: targetId,
    chatbotId: chatbotId,
    handleDeleteCampaign: handleDeleteCampaign,
    updateCampaign: updateCampaign
  }));
};

CampaignTable.propTypes = {
  targetId: PropTypes.string,
  chatbotId: PropTypes.string,
  retentionCampaigns: PropTypes.array,
  handleDeleteCampaign: PropTypes.func,
  updateCampaign: PropTypes.func
};
export default injectIntl(CampaignTable);