export var TalkType;
(function (TalkType) {
    // END USER,
    TalkType["SET_TALK_END_USERS"] = "SET_TALK_END_USERS";
    TalkType["SET_ACTIVE_END_USER"] = "SET_ACTIVE_END_USER";
    TalkType["REFRESH_END_USERS"] = "REFRESH_END_USERS";
    TalkType["CREATE_END_USER_FILTER"] = "CREATE_END_USER_FILTER";
    TalkType["UPDATE_USER_DETAILS"] = "UPDATE_USER_DETAILS";
    TalkType["SET_FILTER_TEXT"] = "SET_FILTER_TEXT";
    TalkType["RESET_END_USERS"] = "RESET_END_USERS";
    TalkType["PUSH_NEW_TALK_END_USERS"] = "PUSH_NEW_TALK_END_USERS";
    TalkType["UNSHIFT_NEW_TALK_END_USERS"] = "UNSHIFT_NEW_TALK_END_USERS";
    TalkType["SET_FILTERED_END_USERS"] = "SET_FILTERED_END_USERS";
    TalkType["PUSH_FILTERED_END_USERS"] = "PUSH_FILTERED_END_USERS";
    TalkType["REMOVE_FILTERED_END_USERS"] = "REMOVE_FILTERED_END_USERS";
    // TAG
    TalkType["UPDATE_USER_TAG_TALKROOM"] = "UPDATE_USER_TAG_TALKROOM";
    // EVENT LOGS
    TalkType["SET_EVENT_LOGS"] = "SET_EVENT_LOGS";
    TalkType["PUSH_EVENT_LOG"] = "PUSH_EVENT_LOG";
    TalkType["PUSH_EVENT_LOGS"] = "PUSH_EVENT_LOGS";
    TalkType["SHIFT_EVENT_LOGS"] = "SHIFT_EVENT_LOGS";
    TalkType["UPDATE_LAST_MESSAGE_READ"] = "UPDATE_LAST_MESSAGE_READ";
    // SCENARIOS
    TalkType["SET_SCENARIOS_FOR_TALK"] = "SET_SCENARIOS_FOR_TALK";
    TalkType["SET_SCENARIOS_FOR_MODAL"] = "SET_SCENARIOS_FOR_MODAL";
    // LOADING
    TalkType["SET_IS_LOADING_LOG_HISTORY"] = "SET_IS_LOADING_LOG_HISTORY";
    TalkType["SET_IS_LOADING_LOGS"] = "SET_IS_LOADING_LOGS";
    TalkType["SET_IS_LOADING_USER"] = "SET_IS_LOADING_USER";
    TalkType["SET_IS_LOADING_USERS"] = "SET_IS_LOADING_USERS";
    TalkType["SET_LOADED_END_USERS"] = "SET_LOADED_END_USERS";
    TalkType["UNDO_TALK"] = "UNDO_TALK";
})(TalkType || (TalkType = {}));
