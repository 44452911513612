import "core-js/modules/es.symbol";
import "core-js/modules/es.array.concat";
import "core-js/modules/es.array.filter";
import "core-js/modules/es.object.get-own-property-descriptor";
import "core-js/modules/es.object.get-own-property-descriptors";
import "core-js/modules/es.object.keys";
import "core-js/modules/web.dom-collections.for-each";
import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import withContentRenderer from './withContentRenderer';
var paddings = {
  header: '20px 20px 20px 20px',
  footer: '10px 10px 10px 10px',
  body: '5px 10px 5px 10px',
  hero: 0
};
var Container = styled.div.withConfig({
  displayName: "Block__Container",
  componentId: "sc-7ovx3m-0"
})(["padding:", ";background-color:", ";"], function (props) {
  return paddings[props.type];
}, function (props) {
  return props.style.background_color || '#FFFFFF';
});

var Block = function Block(props) {
  var content = props.content,
      type = props.type,
      style = props.style,
      renderComponents = props.renderComponents,
      chatbotIdParam = props.chatbotIdParam,
      readOnly = props.readOnly,
      initials = props.initials,
      isFirstComponent = props.isFirstComponent,
      isLastComponent = props.isLastComponent,
      setLineFlexMessageComponent = props.setLineFlexMessageComponent,
      rest = _objectWithoutProperties(props, ["content", "type", "style", "renderComponents", "chatbotIdParam", "readOnly", "initials", "isFirstComponent", "isLastComponent", "setLineFlexMessageComponent"]);

  var spacingClass = content && content.spacing;
  var contents = type === 'hero' ? [content] : content.contents;
  var layout = content && content.layout || 'vertical';
  var spacing = content.spacing || 'none';
  return content ? React.createElement(Container, {
    className: "lfm-block space-".concat(spacingClass, " layout-").concat(layout),
    type: type,
    style: style
  }, renderComponents(_objectSpread({
    blockType: type,
    contents: contents,
    layout: layout,
    spacing: spacing,
    chatbotIdParam: chatbotIdParam,
    readOnly: readOnly,
    initials: initials,
    isFirstComponent: isFirstComponent,
    isLastComponent: isLastComponent,
    setLineFlexMessageComponent: setLineFlexMessageComponent
  }, rest))) : null;
};

Block.propTypes = {
  content: PropTypes.object,
  type: PropTypes.string.isRequired,
  style: PropTypes.object,
  renderComponents: PropTypes.func,
  chatbotIdParam: PropTypes.number,
  readOnly: PropTypes.bool,
  initials: PropTypes.arrayOf(PropTypes.object),
  isFirstComponent: PropTypes.bool,
  isLastComponent: PropTypes.bool
};
export default withContentRenderer(Block);