var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState, useMemo, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { handleAPIErrors } from '@src/lib/helpers';
import { Spinner } from '@src/components/atoms';
import { colors } from '@src/colors';
import { ModalPortal } from '@src/components/atoms';
import { centeredFlex, hoverShadowDark } from '@src/lib/mixins';
import Modal from '@src/components/molecules/Modal';
import modalTypes from '@src/lib/modalTypes';
import * as scenarioApi from '@src/services/api/scenario';
var InputWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin-top: 16px;\n  width: 100%;\n  ", ";\n  input {\n    flex: 1;\n    margin-left: 20px;\n    border: 1px solid ", ";\n    padding: 5px 8px;\n  }\n"], ["\n  margin-top: 16px;\n  width: 100%;\n  ", ";\n  input {\n    flex: 1;\n    margin-left: 20px;\n    border: 1px solid ", ";\n    padding: 5px 8px;\n  }\n"])), centeredFlex, colors.placeholder);
var ScenarioListWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin-top: 16px;\n  display: flex;\n  height: 250px;\n  overflow-x: auto;\n  flex-direction: column;\n  padding: 24px;\n  width: 100%;\n\n  button {\n    border-radius: 4px;\n    margin: 5px 0;\n    line-height: 2;\n    border: 1px solid ", ";\n    background: ", ";\n    ", ";\n    :focus {\n      background: ", ";\n      color: ", ";\n    }\n  }\n"], ["\n  margin-top: 16px;\n  display: flex;\n  height: 250px;\n  overflow-x: auto;\n  flex-direction: column;\n  padding: 24px;\n  width: 100%;\n\n  button {\n    border-radius: 4px;\n    margin: 5px 0;\n    line-height: 2;\n    border: 1px solid ", ";\n    background: ", ";\n    ", ";\n    :focus {\n      background: ", ";\n      color: ", ";\n    }\n  }\n"])), colors.placeholder, colors.white, hoverShadowDark, colors.background.confirm, colors.white);
var Message = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  margin-top: 16px;\n  font-size: 12px;\n  color: ", ";\n"], ["\n  margin-top: 16px;\n  font-size: 12px;\n  color: ",
    ";\n"])), function (_a) {
    var hasScenarioByName = _a.hasScenarioByName;
    return hasScenarioByName ? colors.success : colors.error;
});
var ScenarioModal = function (_a) {
    var chatbotId = _a.chatbotId, endUserId = _a.endUserId, appendLatestEventLogs = _a.appendLatestEventLogs, updateScenarioModalOpen = _a.updateScenarioModalOpen;
    var _b = __read(useState(), 2), scenarioId = _b[0], setScenarioId = _b[1];
    var _c = __read(useState(false), 2), isFetching = _c[0], setIsFetching = _c[1];
    var _d = __read(useState([]), 2), scenarios = _d[0], setScenarios = _d[1];
    var _e = __read(useState(''), 2), scenarioName = _e[0], setScenarioName = _e[1];
    useEffect(function () {
        setIsFetching(true);
        scenarioApi
            .listScenarios(chatbotId)
            .then(setScenarios)
            .catch(handleAPIErrors)
            .finally(function () { return setIsFetching(false); });
    }, [chatbotId]);
    var handleScenarioSelect = useCallback(function (scenarioId) { return setScenarioId(scenarioId); }, [scenarios]);
    var handleScenarioSend = function () { return __awaiter(void 0, void 0, void 0, function () {
        var eventLogs, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!scenarioId) return [3 /*break*/, 4];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, scenarioApi.talkSend(chatbotId, scenarioId, endUserId)];
                case 2:
                    eventLogs = (_a.sent()).eventLogs;
                    if (eventLogs === null || eventLogs === void 0 ? void 0 : eventLogs.length) {
                        return [2 /*return*/, appendLatestEventLogs(eventLogs)];
                    }
                    return [3 /*break*/, 4];
                case 3:
                    e_1 = _a.sent();
                    handleAPIErrors(e_1.message);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var onChangeScenarioName = function (e) {
        setScenarioName(e.target.value);
    };
    var _f = __read(useMemo(function () {
        if (scenarios.length) {
            var regex_1 = new RegExp(scenarioName + '.+$', 'i');
            var newScenarios = scenarios.filter(function (e) { return e.name.search(regex_1) !== -1 || e.name === scenarioName; });
            return [newScenarios, (newScenarios === null || newScenarios === void 0 ? void 0 : newScenarios.length) ? true : false];
        }
        return [[], false];
    }, [scenarioName, scenarios]), 2), filteredScenarios = _f[0], hasScenarioByName = _f[1];
    return (React.createElement(ModalPortal, null,
        React.createElement(Modal, { "data-testid": "scenario-modal", type: modalTypes.SEND_MESSAGE, onConfirm: handleScenarioSend, text: "\u30B7\u30CA\u30EA\u30AA\u3092\u9001\u4FE1", onCancel: function () { return updateScenarioModalOpen(false); }, hasError: !scenarioId }, isFetching ? (React.createElement("div", { style: { height: 100 } },
            React.createElement(Spinner, { spinnerOnly: true, strokeWidth: 2, wide: true }))) : (React.createElement(React.Fragment, null,
            React.createElement(Message, { "data-testid": "announcement-msg", hasScenarioByName: hasScenarioByName }, hasScenarioByName ? '配信可能です' : '存在しない名です'),
            React.createElement(InputWrapper, null,
                React.createElement("span", { "data-testid": "input-label" }, "\u30B7\u30CA\u30EA\u30AA\u691C\u7D22"),
                React.createElement("input", { type: "text", "data-testid": "input", placeholder: "\u30B7\u30CA\u30EA\u30AA\u306E\u540D\u3092\u5165\u529B\u3057\u3066\u304F\u3060\u3055\u3044", value: scenarioName, onChange: onChangeScenarioName })),
            React.createElement(ScenarioListWrapper, null, filteredScenarios.map(function (scenario) { return (React.createElement("button", { type: "button", "data-testid": "scenario", key: scenario.id, onClick: function () { return handleScenarioSelect(scenario.id); } }, scenario.name)); })))))));
};
export default ScenarioModal;
var templateObject_1, templateObject_2, templateObject_3;
