import { useEffect, useRef } from 'react';
/**
 * Returns the previous value of the thing passed in.
 *
 * @remarks
 * const previousName = usePrevious("aadasd");
 *
 * @param value - value to store previous value of
 * @returns The previous value of value
 *
 */
export var usePrevious = function (value) {
    var previous = useRef(undefined);
    useEffect(function () {
        previous.current = value;
    });
    return previous.current;
};
