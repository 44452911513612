var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import styled from 'styled-components';
import { messageTypes } from '@src/lib/app-constants';
import { styledTemplate, customImageMapTemplateFrame } from '@src/lib/helpers';
var RichMenuMessageContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin: 20px 10px;\n  position: relative;\n  background: ", ";\n  background-repeat: no-repeat;\n  background-position: center;\n  background-size: contain;\n\n  ", "\n"], ["\n  margin: 20px 10px;\n  position: relative;\n  background: ", ";\n  background-repeat: no-repeat;\n  background-position: center;\n  background-size: contain;\n\n  ",
    "\n"])), function (props) { return "url(" + props.imageUrl + ")" || 'unset'; }, function (props) {
    return styledTemplate(__assign(__assign({}, props), { messageType: messageTypes.RICH_MENU, scaleByWidth: true, customTemplateFrame: customImageMapTemplateFrame(props) }));
});
var RichMenuNode = function (_a) {
    var _b = _a.data, message = _b.message, updateBoundaryNode = _b.updateBoundaryNode;
    var rich_menu = message.rich_menu;
    var template_content = rich_menu.template_content;
    var areas = template_content.areas.map(function (_a) {
        var bounds = _a.bounds;
        return bounds;
    });
    return (React.createElement(RichMenuMessageContainer, { key: "rich-menu-" + rich_menu.id, areas: areas, templateWidth: template_content.size.width, templateHeight: template_content.size.height, templateNum: rich_menu.template, imageUrl: rich_menu.proxy_image_url, "data-testid": "RichMenuNode_RichMenuMessageContainer" },
        React.createElement("div", { className: "template-areas" }, rich_menu.rich_menu_areas.map(function (area, areaIndex) { return (React.createElement("div", { key: "rich-menu-area-" + area.id, className: "area-" + areaIndex, "data-testid": "RichMenuNode_RichMenuArea" + areaIndex, ref: function (ref) {
                if (!ref || !updateBoundaryNode || !area.postback_action)
                    return;
                updateBoundaryNode('postbackActions', area.postback_action.id, ref.getBoundingClientRect());
            } })); }))));
};
export default RichMenuNode;
var templateObject_1;
