var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import { injectIntl } from 'react-intl';
import camelCase from 'lodash/camelCase';
import styled from 'styled-components';
import { mediaFileSchema, mediaUploadValidation } from '@src/lib/helpers';
import { colors } from '@src/colors';
import { mediaTypes, messageTypes, mediaAcceptTypes, mediaPlaceholderIcons, tabTypes, } from '@src/lib/app-constants';
import platformLimitations from '@src/lib/platforms/limitations';
import { Input, DefaultThumbnail, CharacterCount } from '@src/components/atoms';
import Tabs from '@src/components/molecules/Tabs';
import { centeredFlex } from '@src/lib/mixins';
import { transitions } from '@src/lib/animations';
import { mediaTypeMessages } from '@src/i18n/translations';
import UploadEditOptions from './UploadEditOptions';
var MediaContainerModal = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  max-height: 140px;\n  overflow: hidden;\n  user-select: none;\n  position: relative;\n  border-radius: 5px;\n  border: 1px solid ", ";\n  margin: 0;\n  height: fit-content;\n  min-width: 262px;\n  min-height: 140px;\n  ", ";\n  transition: ", ";\n  .image-wrapper {\n    max-width: fit-content;\n    img {\n      display: block;\n      margin: 0 auto;\n    }\n  }\n  .default-thumb,\n  .no-file-uploaded {\n    height: 138px;\n  }\n  &:hover {\n    border-color: transparent;\n    .default-thumb {\n      display: none;\n    }\n    .no-file-uploaded,\n    .file-uploaded-options {\n      border-radius: 5px;\n      display: flex;\n      background: ", ";\n      width: 100%;\n      position: absolute;\n      top: 0;\n    }\n  }\n"], ["\n  width: 100%;\n  max-height: 140px;\n  overflow: hidden;\n  user-select: none;\n  position: relative;\n  border-radius: 5px;\n  border: 1px solid ", ";\n  margin: 0;\n  height: fit-content;\n  min-width: 262px;\n  min-height: 140px;\n  ", ";\n  transition: ", ";\n  .image-wrapper {\n    max-width: fit-content;\n    img {\n      display: block;\n      margin: 0 auto;\n    }\n  }\n  .default-thumb,\n  .no-file-uploaded {\n    height: 138px;\n  }\n  &:hover {\n    border-color: transparent;\n    .default-thumb {\n      display: none;\n    }\n    .no-file-uploaded,\n    .file-uploaded-options {\n      border-radius: 5px;\n      display: flex;\n      background: ", ";\n      width: 100%;\n      position: absolute;\n      top: 0;\n    }\n  }\n"])), colors.placeholder, centeredFlex, transitions.normal, colors.background.help);
var VideoNameContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", "\n  > div {\n    ", "\n    padding: 0 16px;\n    &:before {\n      font-size: 1.75rem;\n      position: relative;\n    }\n    > .file-name {\n      font-size: 1rem;\n      line-height: 1.4rem;\n      display: -webkit-box;\n      -webkit-line-clamp: 3;\n      -webkit-box-orient: vertical;\n      overflow: hidden;\n      word-break: break-all;\n    }\n  }\n"], ["\n  ", "\n  > div {\n    ", "\n    padding: 0 16px;\n    &:before {\n      font-size: 1.75rem;\n      position: relative;\n    }\n    > .file-name {\n      font-size: 1rem;\n      line-height: 1.4rem;\n      display: -webkit-box;\n      -webkit-line-clamp: 3;\n      -webkit-box-orient: vertical;\n      overflow: hidden;\n      word-break: break-all;\n    }\n  }\n"])), centeredFlex, centeredFlex);
var MediaInputWrapper = function (_a) {
    var intl = _a.intl, imageMapVideo = _a.imageMapVideo, mediaType = _a.mediaType, limits = _a.limits, handleChange = _a.handleChange, handleChanges = _a.handleChanges;
    var id = imageMapVideo.id, original_content_url = imageMapVideo.original_content_url, originalContentFile = imageMapVideo.originalContentFile, preview_image_url = imageMapVideo.preview_image_url, previewImage = imageMapVideo.previewImage, previewImageFile = imageMapVideo.previewImageFile, name = imageMapVideo.name, activeVideoTab = imageMapVideo.activeVideoTab, activePreviewTab = imageMapVideo.activePreviewTab;
    var fileInput = React.createRef();
    var uploadClick = function () { return fileInput.current.click(); };
    var mediaTypeText = function () {
        return intl.formatMessage(mediaTypeMessages[mediaType]);
    };
    var videoNameOfOriginalContentFile = originalContentFile ? name : '';
    var previewSrc = activePreviewTab === tabTypes.FILE ? previewImage : preview_image_url;
    var videoName = activeVideoTab === tabTypes.FILE ? videoNameOfOriginalContentFile : '';
    var mediaIcon = function () {
        switch (mediaType) {
            case mediaTypes.IMAGE:
                return (React.createElement("div", { className: "image-wrapper" },
                    React.createElement("img", { src: previewSrc, alt: "" })));
            case mediaTypes.VIDEO:
                return (React.createElement(VideoNameContainer, null,
                    React.createElement("div", { className: mediaPlaceholderIcons[mediaType] },
                        React.createElement("span", { className: "file-name" }, videoName))));
            default:
                return null;
        }
    };
    var isDisplayContent = function () {
        var isNew = !id;
        switch (mediaType) {
            case mediaTypes.IMAGE:
                return Boolean(isNew ? previewImageFile : preview_image_url);
            case mediaTypes.VIDEO:
                return Boolean(isNew ? originalContentFile : original_content_url);
            default:
                return false;
        }
    };
    var fileOnChange = function (e) {
        e.preventDefault();
        var file = e.target.files[0];
        var isPreview = mediaType === mediaTypes.IMAGE;
        mediaFileSchema(limits)
            .validate({
            size: file.size,
            type: file.type,
        }, {
            abortEarly: false,
            context: { isPreview: isPreview, mediaType: mediaType },
        })
            .then(function (response) {
            if (!response)
                return;
            var reader = new FileReader();
            if (isPreview) {
                reader.onloadend = function (e) {
                    return handleChanges({
                        previewImage: e.target.result,
                        previewImageFile: file,
                    });
                };
            }
            else {
                reader.onload = function (e) {
                    return mediaUploadValidation({
                        intl: intl,
                        type: mediaType,
                        src: e.target.result,
                        limitations: limits,
                        onerror: function (errorMessage) { return alert(errorMessage); },
                        onloadedmetadata: function (_a) {
                            var duration = _a.duration, videoWidth = _a.videoWidth, videoHeight = _a.videoHeight;
                            return handleChanges({
                                name: file.name,
                                originalContentFile: file,
                                duration: Math.round(duration * 1000),
                                width: Math.round(videoWidth),
                                height: Math.round(videoHeight),
                            });
                        },
                    });
                };
            }
            reader.readAsDataURL(file);
        })
            .catch(function (e) { return alert(e.errors.join(' \n')); });
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(MediaContainerModal, null,
            isDisplayContent() ? (React.createElement(React.Fragment, null,
                mediaIcon(),
                React.createElement(UploadEditOptions, { onUploadClick: uploadClick, type: mediaType }))) : (React.createElement(React.Fragment, null,
                React.createElement(DefaultThumbnail, { text: mediaTypeText() + "\u3092\u9078\u629E\u3057\u3066\u304F\u3060\u3055\u3044", mediaType: mediaType, intl: intl }),
                React.createElement(UploadEditOptions, { type: mediaType, onUploadClick: uploadClick }))),
            React.createElement("input", { name: "url", accept: mediaAcceptTypes[mediaType], type: "file", style: { display: 'none' }, ref: fileInput, onChange: fileOnChange }))));
};
var ImageMapVideoSetting = function (_a) {
    var className = _a.className, intl = _a.intl, imageMapVideo = _a.imageMapVideo, _b = _a.errors, errors = _b === void 0 ? {} : _b, _c = _a.touched, touched = _c === void 0 ? {} : _c, platform = _a.platform, handleChange = _a.handleChange, handleChanges = _a.handleChanges;
    var label = imageMapVideo.label, linkUri = imageMapVideo.link_uri;
    var isNew = !imageMapVideo.id;
    var limits = platform && platformLimitations[platform]
        ? platformLimitations[platform]
        : {};
    var subTabItems = function (mediaType) {
        var field = mediaType === mediaTypes.IMAGE ? 'preview_image' : 'original_content';
        var fieldUrl = field + "_url";
        var onBlurField = camelCase(fieldUrl + "Touched");
        return [
            {
                name: 'ファイルアップロード',
                content: (React.createElement(MediaInputWrapper, { intl: intl, imageMapVideo: imageMapVideo, mediaType: mediaType, limits: limits, handleChange: handleChange, handleChanges: handleChanges })),
            },
            {
                name: 'URL入力',
                content: (React.createElement(React.Fragment, null,
                    React.createElement(Input, { placeholder: "\u5FC5\u9808", value: imageMapVideo[fieldUrl], name: "imageMapVideo." + fieldUrl, onChange: handleChange },
                        React.createElement(CharacterCount, { value: imageMapVideo[fieldUrl], platform: platform, path: "common.url" })),
                    touched[fieldUrl] && errors[fieldUrl] && (React.createElement("div", { style: { margin: '4px 0 0 0' }, className: "message-wrapper error" }, errors[fieldUrl])))),
            },
        ];
    };
    var updateActiveTab = function (changes, mediaType) {
        var _a;
        var activeTabType = changes.openIndexes[0] === 0 ? tabTypes.FILE : tabTypes.URL;
        var currentTab = mediaType === mediaTypes.IMAGE ? 'activePreviewTab' : 'activeVideoTab';
        handleChanges((_a = {}, _a[currentTab] = activeTabType, _a));
        return changes;
    };
    return (React.createElement("div", { className: className },
        React.createElement("div", { className: "message-wrapper primary-label" }, "\u30E9\u30D9\u30EB"),
        React.createElement(Input, { placeholder: "\u5FC5\u9808", value: label, name: "imageMapVideo.label", onChange: handleChange },
            React.createElement(CharacterCount, { value: label, platform: platform, path: messageTypes.IMAGE_MAP + ".video.label" })),
        touched.label && errors.label && (React.createElement("div", { style: { margin: '4px 0 0 0' }, className: "message-wrapper error" }, errors.label)),
        React.createElement("div", { className: "message-wrapper primary-label" }, "URL"),
        React.createElement(Input, { placeholder: "\u5FC5\u9808", value: linkUri, name: "imageMapVideo.link_uri", onChange: handleChange },
            React.createElement(CharacterCount, { value: linkUri, platform: platform, path: "common.url" })),
        touched.link_uri && errors.link_uri && (React.createElement("div", { style: { margin: '4px 0 0 0' }, className: "message-wrapper error" }, errors.link_uri)),
        React.createElement("div", { className: "message-wrapper primary-label" }, "\u52D5\u753B\uFF08\u5FC5\u9808\uFF09"),
        React.createElement(Tabs, { tabItems: subTabItems(mediaTypes.VIDEO), initTabIndex: isNew ? 0 : 1, tabButtonColor: colors.label.green_blue, isSubTab: true, stateReducer: function (state, changes) {
                return updateActiveTab(changes, mediaTypes.VIDEO);
            } }),
        React.createElement("div", { className: "message-wrapper primary-label" }, "\u30D7\u30EC\u30D3\u30E5\u30FC\u753B\u50CF\uFF08\u5FC5\u9808\uFF09"),
        React.createElement(Tabs, { tabItems: subTabItems(mediaTypes.IMAGE), initTabIndex: isNew ? 0 : 1, tabButtonColor: colors.label.green_blue, isSubTab: true, stateReducer: function (state, changes) {
                return updateActiveTab(changes, mediaTypes.IMAGE);
            } })));
};
export default injectIntl(ImageMapVideoSetting);
var templateObject_1, templateObject_2;
