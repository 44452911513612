var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import React, { Component } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { colors } from '@src/colors';
import { Spinner } from '@src/components/atoms';
import { makeJpFormattedDateStr } from '@src/lib/helpers';
import platformLimitations from '@src/lib/platforms/limitations';
import { MessageModal, Messages, ScenarioModal, } from '@src/components/organisms/fanp';
import SendMessage from '@src/components/organisms/fanp/SendMessage';
import * as talkRoomApi from '@src/services/api/talkRoom';
import { EVENT_LOGS_POLLING_INTERVAL, TALK_MODALS, } from '@src/lib/talkConstants';
import { contentFromMessage } from '@src/lib/talkUtil';
var InboxContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  border-right: 1px solid ", ";\n  text-align: center;\n  color: ", ";\n  width: 20%;\n  height: 100%;\n"], ["\n  border-right: 1px solid ", ";\n  text-align: center;\n  color: ", ";\n  width: 20%;\n  height: 100%;\n"])), colors.off_white_border, colors.header_font);
var ConversationContainer = styled(InboxContainer)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  flex: 3;\n  border: 0;\n  display: flex;\n  flex-direction: column;\n\n  .message-divider {\n    border-top: 1px solid ", ";\n    padding: 5px 0;\n  }\n"], ["\n  flex: 3;\n  border: 0;\n  display: flex;\n  flex-direction: column;\n\n  .message-divider {\n    border-top: 1px solid ", ";\n    padding: 5px 0;\n  }\n"])), colors.off_white_border);
var FIRST_EVENT_LOGS_PAGE = 2;
var EventLogs = /** @class */ (function (_super) {
    __extends(EventLogs, _super);
    function EventLogs() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            scenarioModalOpen: false,
            messageModalOpen: false,
            modalName: '',
            modalMessageType: '',
            eventLogsInterval: null,
            eventLogsPage: FIRST_EVENT_LOGS_PAGE,
        };
        _this.updateEventLogsPage = function (page) { return _this.setState({ eventLogsPage: page }); };
        _this.updateEventLogsInterval = function () {
            var _a = _this.props, eventLogs = _a.eventLogs, fetchLatestEventLog = _a.fetchLatestEventLog, chatbotId = _a.chatbotId, endUserId = _a.endUserId;
            var latestCreatedAt = eventLogs && eventLogs[eventLogs.length - 1].created_at;
            fetchLatestEventLog({
                chatbotId: chatbotId,
                endUserId: endUserId,
                latestCreatedAt: latestCreatedAt,
            });
        };
        _this.handleSendMessageSubmit = function (message) { return __awaiter(_this, void 0, void 0, function () {
            var _a, chatbotId, endUserId, pushEventLog, updateEndUserDetails, endUsers, filteredEndUsers, undo, eventLog, endUser, e_1;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this.props, chatbotId = _a.chatbotId, endUserId = _a.endUserId, pushEventLog = _a.pushEventLog, updateEndUserDetails = _a.updateEndUserDetails, endUsers = _a.endUsers, filteredEndUsers = _a.filteredEndUsers, undo = _a.undo;
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, talkRoomApi.sendMessage(chatbotId, endUserId, message)];
                    case 2:
                        eventLog = _b.sent();
                        pushEventLog(eventLog);
                        endUser = __spreadArray(__spreadArray([], __read(endUsers)), __read(filteredEndUsers)).find(function (u) { return u.id === endUserId; });
                        updateEndUserDetails(__assign(__assign({}, endUser), { last_message: {
                                end_user_read: false,
                                created_at: makeJpFormattedDateStr(null, true, '-'),
                                content: contentFromMessage(eventLog.message),
                                id: eventLog.message.id,
                            } }));
                        return [3 /*break*/, 4];
                    case 3:
                        e_1 = _b.sent();
                        if (message)
                            undo();
                        console.log({ e: e_1 });
                        alert('メッセージを送信できませんでした');
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        }); };
        _this.changeModal = function (modalType) {
            _this.setState({ modalMessageType: modalType }, function () {
                _this.setState({ messageModalOpen: !_this.state.messageModalOpen });
            });
        };
        _this.updateScenarioModalOpen = function (open) {
            return _this.setState({ scenarioModalOpen: open });
        };
        _this.displayEventLog = function (_a) {
            var isLoadingLogs = _a.isLoadingLogs, isLoadedEndUsers = _a.isLoadedEndUsers, eventLogs = _a.eventLogs;
            if (isLoadingLogs || !isLoadedEndUsers) {
                return (React.createElement("div", { style: { flex: 1 } },
                    React.createElement(Spinner, null)));
            }
            else if (!eventLogs.length) {
                return (React.createElement("div", { style: { flex: 1 } },
                    React.createElement("span", { style: {
                            position: 'fixed',
                            top: '50%',
                            marginLeft: '-300px',
                        } }, "\u30ED\u30B0\u304C\u3042\u308A\u307E\u305B\u3093\u3002\u30C6\u30B9\u30C8\u30E2\u30FC\u30C9\u30AA\u30D5\u306B\u3057\u3066\u30B7\u30CA\u30EA\u30AA\u3092\u30DC\u30C3\u30C8\u3067\u767A\u8A71\u3055\u305B\u3066\u304F\u3060\u3055\u3044")));
            }
            else {
                return null;
            }
        };
        _this.getPlatformLimits = function () {
            var platform = _this.props.activeEndUser.platform;
            var limits = platform && platformLimitations[platform];
            return limits || {};
        };
        _this.sendingTimeLimitOk = function () {
            var _a, _b;
            var limits = _this.getPlatformLimits();
            if (!limits.TALK_DISABLE_SENDING_MESSAGES_AFTER_MS)
                return true;
            var activeEndUser = _this.props.activeEndUser;
            if (!((_a = activeEndUser === null || activeEndUser === void 0 ? void 0 : activeEndUser.last_end_user_message) === null || _a === void 0 ? void 0 : _a.created_at))
                return false;
            var now = moment();
            var lastUserMessageAt = moment((_b = activeEndUser === null || activeEndUser === void 0 ? void 0 : activeEndUser.last_end_user_message) === null || _b === void 0 ? void 0 : _b.created_at);
            if (!lastUserMessageAt.isValid())
                return false;
            var diff = now.valueOf() - lastUserMessageAt.valueOf();
            return diff >= limits.TALK_DISABLE_SENDING_MESSAGES_AFTER_MS;
        };
        _this.sendingIsDisabled = function () {
            var activeEndUser = _this.props.activeEndUser;
            return !_this.sendingTimeLimitOk() && Boolean(activeEndUser === null || activeEndUser === void 0 ? void 0 : activeEndUser.blocked_at);
        };
        _this.supportedTalkModals = function () {
            var limits = _this.getPlatformLimits();
            var allTalkModals = Object.values(TALK_MODALS);
            if (!limits) {
                return allTalkModals;
            }
            return allTalkModals.filter(function (modal) {
                var _a;
                return (_a = limits.TALK_CAN_SEND) === null || _a === void 0 ? void 0 : _a[modal.type];
            });
        };
        return _this;
    }
    EventLogs.prototype.componentDidMount = function () {
        var _a = this.props, chatbotId = _a.chatbotId, endUserId = _a.endUserId, fetchEventLogs = _a.fetchEventLogs;
        if (endUserId) {
            fetchEventLogs({
                chatbotId: chatbotId,
                endUserId: endUserId,
            });
        }
    };
    EventLogs.prototype.componentDidUpdate = function (prevProps) {
        // JS Quirk: NaN !== NaN
        if (isNaN(this.props.endUserId) && isNaN(prevProps.endUserId))
            return;
        if (this.props.endUserId && this.props.endUserId !== prevProps.endUserId) {
            this.props.fetchEventLogs({
                chatbotId: this.props.chatbotId,
                endUserId: this.props.endUserId,
            });
            this.updateEventLogsPage(FIRST_EVENT_LOGS_PAGE);
        }
        if (!this.state.eventLogsInterval &&
            this.props.eventLogs &&
            this.props.eventLogs.length &&
            this.props.endUserId) {
            var eventLogsInterval = setInterval(this.updateEventLogsInterval, EVENT_LOGS_POLLING_INTERVAL);
            this.setState({ eventLogsInterval: eventLogsInterval });
        }
    };
    EventLogs.prototype.componentWillUnmount = function () {
        if (this.state.eventLogsInterval)
            clearInterval(this.state.eventLogsInterval);
    };
    EventLogs.prototype.render = function () {
        var _this = this;
        var _a = this.props, chatbotId = _a.chatbotId, endUserId = _a.endUserId, eventLogs = _a.eventLogs, activeEndUser = _a.activeEndUser, scenarios = _a.scenarios, layers = _a.layers, blocks = _a.blocks, settings = _a.settings, isLoadingLogHistory = _a.isLoadingLogHistory, isLoadingLogs = _a.isLoadingLogs, appendLatestEventLogs = _a.appendLatestEventLogs, fetchEventLogs = _a.fetchEventLogs, isLoadedEndUsers = _a.isLoadedEndUsers;
        var platform = activeEndUser.platform;
        var _b = this.state, scenarioModalOpen = _b.scenarioModalOpen, messageModalOpen = _b.messageModalOpen, modalMessageType = _b.modalMessageType, eventLogsPage = _b.eventLogsPage;
        var LIMITS = platform && platformLimitations[platform];
        return (React.createElement(ConversationContainer, null,
            scenarioModalOpen ? (React.createElement(ScenarioModal, { chatbotId: chatbotId, endUserId: endUserId, updateScenarioModalOpen: this.updateScenarioModalOpen, appendLatestEventLogs: appendLatestEventLogs })) : null,
            messageModalOpen ? (React.createElement(MessageModal, { chatbotId: chatbotId, endUserId: endUserId, messageType: modalMessageType, changeModal: this.changeModal, activeChatbot: settings.activeChatbot, scenarios: scenarios, layers: layers, blocks: blocks, limits: LIMITS, sendMessage: this.handleSendMessageSubmit })) : null,
            !isLoadingLogs && platform ? (React.createElement(React.Fragment, null,
                React.createElement(Messages, { chatbotId: chatbotId, endUserId: endUserId, eventLogsPage: eventLogsPage, limits: LIMITS, scenarios: scenarios, layers: layers, blocks: blocks, eventLogs: eventLogs || [], isLoadingLogHistory: isLoadingLogHistory, isLoadingLogs: isLoadingLogs, updateEventLogsPage: this.updateEventLogsPage, fetchEventLogs: fetchEventLogs }),
                isLoadingLogHistory ? React.createElement(Spinner, null) : null)) : null,
            this.displayEventLog({
                isLoadingLogs: isLoadingLogs,
                isLoadedEndUsers: isLoadedEndUsers,
                eventLogs: eventLogs,
            }),
            React.createElement(SendMessage, { modals: this.supportedTalkModals(), onScenarioItemClick: function () { return _this.updateScenarioModalOpen(true); }, disabled: this.sendingIsDisabled(), onSubmit: this.handleSendMessageSubmit, onModalItemClick: this.changeModal })));
    };
    return EventLogs;
}(Component));
export default EventLogs;
var templateObject_1, templateObject_2;
