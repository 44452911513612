var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useCallback, useEffect, useState, useRef } from 'react';
import Moment from 'moment';
import { connect } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import debounce from 'lodash/debounce';
import modalTypes from '@src/lib/modalTypes';
import { deliveryTypes, statusColors, DEBOUNCE_TIME_SMALL, } from '@src/lib/app-constants';
import { chatTypes } from '@src/lib/chat-types';
import { defaultAdScenariosQueryParams, getAdScenariosUrlQueryString, makeAdUrl, } from '@src/lib/adHelpers';
import buttonTypes from '@src/lib/buttonTypes';
import { ModalPortal, Button, Input } from '@src/components/atoms';
import SelectTemplateScenario from '@src/components/organisms/fanp/SelectTemplateScenario';
import { hideScrollbar, centeredFlex, hoverMenuBg, hoverHighlight, } from '@src/lib/mixins';
import { colors, shadows } from '@src/colors';
import { transitions } from '@src/lib/animations';
import { showModal } from '@src/redux/fanp/actions/modal';
import { fetchAdScenarios, createNewAdScenario, createTemplateScenario, deleteScenario, deleteScenarios, editScenarioMainSetting, moveBlockToOtherLayer, adSetLayerField, adToggleLayerField, adSetUndefinedBlocksLayerField, adToggleUndefinedBlocksLayerField, adOpenAllLayers, adToggleAllLayersField, } from '@src/redux/fanp/actions/ad-scenario';
import { setHighlightFilterId } from '@src/redux/fanp/actions/messageFilter';
import { removeScenarioIdsChange } from '@src/redux/fanp/actions/ad-scenario/deleteActions';
import { Modal, CreateScenarioModal } from '@src/components/molecules';
import Pagination from 'react-js-pagination';
import { AlertModalContext } from '@src/components/molecules/AlertModal';
import { injectIntl } from 'react-intl';
import { getABTestDetails } from '@src/components/organisms/fanp/initialConversation/common';
import ScenarioItem from './ScenarioItem';
import ScenarioItemMenu from './ScenarioItemMenu';
var PAGE_RANGE_DISPLAYED = 5;
// TODO: have to be chacked for new changes
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row-reverse;\n"], ["\n  display: flex;\n  flex-direction: row-reverse;\n"])));
var styledPagination = function (totalItem) {
    if (totalItem >= PAGE_RANGE_DISPLAYED)
        return null;
    var marginPercentage = (PAGE_RANGE_DISPLAYED + 2) / ((PAGE_RANGE_DISPLAYED - totalItem) / 2);
    return css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    margin-left: calc(100% / ", ");\n    margin-right: calc(-100% / ", ");\n  "], ["\n    margin-left: calc(100% / ", ");\n    margin-right: calc(-100% / ", ");\n  "])), marginPercentage, marginPercentage);
};
var PaginationContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  padding: 0 4px;\n  justify-content: center;\n  .pagination {\n    display: flex;\n    margin: 0;\n    ", ";\n    .pagination-item > a {\n      border-top: none;\n      border-bottom: none;\n      padding: 6px 0px;\n      width: 100%;\n      text-align: center;\n    }\n    .pagination-item:not(:last-of-type) > a {\n      border-right: none;\n    }\n    .pagination-item {\n      width: calc(100% / ", ");\n    }\n  }\n"], ["\n  padding: 0 4px;\n  justify-content: center;\n  .pagination {\n    display: flex;\n    margin: 0;\n    ", ";\n    .pagination-item > a {\n      border-top: none;\n      border-bottom: none;\n      padding: 6px 0px;\n      width: 100%;\n      text-align: center;\n    }\n    .pagination-item:not(:last-of-type) > a {\n      border-right: none;\n    }\n    .pagination-item {\n      width: calc(100% / ", ");\n    }\n  }\n"])), function (props) { return styledPagination(props.totalItem); }, PAGE_RANGE_DISPLAYED + 2);
var LeftMenuTab = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  ", "\n  height: 40px;\n  width: 40px;\n  margin-top: 16px;\n  background-color: white;\n  box-shadow: ", ";\n  border-left: 0;\n  border-radius: 0 10px 10px 0;\n  transition: ", " all;\n  flex: 1;\n  span {\n    font-size: 24px;\n    display: flex;\n    color: ", ";\n  }\n  ", ";\n"], ["\n  ", "\n  height: 40px;\n  width: 40px;\n  margin-top: 16px;\n  background-color: white;\n  box-shadow: ", ";\n  border-left: 0;\n  border-radius: 0 10px 10px 0;\n  transition: ", " all;\n  flex: 1;\n  span {\n    font-size: 24px;\n    display: flex;\n    color: ", ";\n  }\n  ", ";\n"])), centeredFlex, shadows.small.light, transitions.normal, colors.icon, hoverMenuBg);
var ScenarioContainer = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  position: relative;\n  min-width: 240px;\n  max-width: 240px;\n  overflow: auto;\n  max-height: 100vh;\n  background: ", ";\n  box-shadow: ", ";\n  color: ", ";\n  order: 1;\n  z-index: 9;\n  ", ";\n\n  .new-scenario {\n    padding: 1rem;\n    text-align: center;\n  }\n  .scenarios-list {\n    max-height: 72%;\n    overflow-y: scroll;\n  }\n  .delete-scenario {\n    padding: 1rem;\n  }\n  .adicon_search {\n    border-radius: unset;\n    border-right: unset;\n    border-left: unset;\n    &::before {\n      margin-left: 8px;\n    }\n  }\n"], ["\n  position: relative;\n  min-width: 240px;\n  max-width: 240px;\n  overflow: auto;\n  max-height: 100vh;\n  background: ", ";\n  box-shadow: ", ";\n  color: ", ";\n  order: 1;\n  z-index: 9;\n  ", ";\n\n  .new-scenario {\n    padding: 1rem;\n    text-align: center;\n  }\n  .scenarios-list {\n    max-height: 72%;\n    overflow-y: scroll;\n  }\n  .delete-scenario {\n    padding: 1rem;\n  }\n  .adicon_search {\n    border-radius: unset;\n    border-right: unset;\n    border-left: unset;\n    &::before {\n      margin-left: 8px;\n    }\n  }\n"])), colors.background.main_menu, shadows.small.light, colors.text_pale, hideScrollbar);
var StepPageBack = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  text-align: left;\n  font-size: 0.875rem;\n  font-weight: bold;\n  color: ", ";\n  ", ";\n\n  &::before {\n    font-size: 20px;\n    margin-right: 4px;\n  }\n"], ["\n  text-align: left;\n  font-size: 0.875rem;\n  font-weight: bold;\n  color: ", ";\n  ", ";\n\n  &::before {\n    font-size: 20px;\n    margin-right: 4px;\n  }\n"])), colors.placeholder, hoverHighlight);
var DeleteScenariosModal = function (_a) {
    var onCancel = _a.onCancel, onConfirm = _a.onConfirm, intl = _a.intl;
    return (React.createElement(ModalPortal, null,
        React.createElement(Modal, { "data-testid": "talk-customize-left-modal", text: intl.formatMessage({ id: "usNPpx", defaultMessage: "Are you sure you want to delete this item ?" }), type: modalTypes.DELETE, onCancel: onCancel, onConfirm: onConfirm })));
};
var TalkCustomizeLeft = function (props) {
    var scenariosContainer = useRef(null);
    var _a = __read(useState(0), 2), popupMenuTop = _a[0], setPopupMenuTop = _a[1];
    var _b = __read(useState(0), 2), popupMenuLeft = _b[0], setPopupMenuLeft = _b[1];
    var _c = __read(useState(0), 2), popupMenuBottom = _c[0], setPopupMenuBottom = _c[1];
    var _d = __read(useState(false), 2), isPopupMenuVisible = _d[0], setIsPopupMenuVisible = _d[1];
    var _e = __read(useState(false), 2), isDeleteMode = _e[0], setIsDeleteMode = _e[1];
    var _f = __read(useState(true), 2), isTalkCustomizeVisible = _f[0], setIsTalkCustomizeVisible = _f[1];
    var _g = __read(useState(false), 2), newScenarioModalOpen = _g[0], setNewScenarioModalOpen = _g[1];
    var _h = __read(useState(false), 2), deleteScenariosModalOpen = _h[0], setDeleteScenariosModalOpen = _h[1];
    var _j = __read(useState(false), 2), templateScenarioModalOpen = _j[0], setTemplateScenarioModalOpen = _j[1];
    var _k = __read(useState(props.search || ''), 2), searchInputValue = _k[0], setSearchInputValue = _k[1];
    var _l = __read(useState(''), 2), scenarioName = _l[0], setScenarioName = _l[1];
    var _m = __read(useState("TemplateScenario " + Moment().format('YYYY/MM/DD HH:mm:ss')), 2), newScenarioName = _m[0], setNewScenarioName = _m[1];
    var _o = __read(useState(true), 2), isOnComposition = _o[0], setIsOnComposition = _o[1];
    var _p = __read(useState(null), 2), selectedTemplate = _p[0], setSelectedTemplate = _p[1];
    // This flag will prevent focus after click block on filter detail
    // (on right menu) after mouse hover
    var _q = __read(useState(false), 2), isHover = _q[0], setIsHover = _q[1];
    var handleModal = React.useContext(AlertModalContext).handleModal;
    var browserHistory = useHistory();
    useEffect(function () { return setIsHover(false); }, [props.blockIdParam]);
    var hidePopupMenu = function () {
        setIsPopupMenuVisible(false);
    };
    var showPopupMenu = function () {
        setIsPopupMenuVisible(true);
    };
    useEffect(function () {
        if (props.search !== searchInputValue) {
            setSearchInputValue(props.search);
        }
    }, [props.search]);
    useEffect(function () {
        var _a;
        var handleScroll = function () {
            hidePopupMenu();
        };
        (_a = scenariosContainer === null || scenariosContainer === void 0 ? void 0 : scenariosContainer.current) === null || _a === void 0 ? void 0 : _a.addEventListener('scroll', handleScroll);
        return function cleanup() {
            var _a;
            (_a = scenariosContainer === null || scenariosContainer === void 0 ? void 0 : scenariosContainer.current) === null || _a === void 0 ? void 0 : _a.removeEventListener('scroll', handleScroll);
        };
    }, [scenariosContainer.current]);
    var debouncedSearchScenarios = useCallback(debounce(function (search) {
        browserHistory.push("" + props.location.pathname + getAdScenariosUrlQueryString({
            page: defaultAdScenariosQueryParams.page,
            per: props.per,
            search: search,
        }));
    }, DEBOUNCE_TIME_SMALL), 
    // eslint-disable-next-line react/destructuring-assignment
    [props.per, props.location.pathname]);
    var toggleTalkCustomizeVisible = function () {
        return setIsTalkCustomizeVisible(!isTalkCustomizeVisible);
    };
    var toggleDeleteMode = function () {
        setIsDeleteMode(!isDeleteMode);
    };
    var toggleDeleteScenariosModal = function () {
        setDeleteScenariosModalOpen(!deleteScenariosModalOpen);
    };
    var toggleNewScenarioNameModal = function () {
        var createNewName = function () {
            var _a;
            var chatTypeParam = props.chatTypeParam;
            var dateTimeNow = Moment();
            var dateStr = dateTimeNow.format('YYYY/MM/DD HH:mm:ss');
            var typeDict = (_a = {},
                _a[chatTypes.INITIAL] = '初期会話',
                _a[chatTypes.FORM] = 'フォーム会話',
                _a[chatTypes.PATTERN] = 'パターン会話',
                _a[chatTypes.SHOT] = 'ショット配信',
                _a);
            return "" + typeDict[chatTypeParam] + dateStr;
        };
        setNewScenarioModalOpen(!newScenarioModalOpen);
        setScenarioName(createNewName());
    };
    var toggleTemplateScenarioModal = function () {
        setTemplateScenarioModalOpen(!templateScenarioModalOpen);
    };
    var handleScenarioItemClick = function (adScenarioId) {
        return function () {
            var _a, _b, _c, _d;
            var chatTypeParam = props.chatTypeParam, chatbotIdParam = props.chatbotIdParam, adScenario = props.adScenario, location = props.location, setHighlightFilterId = props.setHighlightFilterId, blockIdParam = props.blockIdParam;
            var scenarios = adScenario.scenarios, layers = adScenario.layers;
            var firstLayerId = (_b = (_a = scenarios[adScenarioId]) === null || _a === void 0 ? void 0 : _a.layers) === null || _b === void 0 ? void 0 : _b[0];
            var firstBlockId = (_d = (_c = layers[firstLayerId]) === null || _c === void 0 ? void 0 : _c.blocks) === null || _d === void 0 ? void 0 : _d[0];
            var url = makeAdUrl({
                chatTypeParam: chatTypeParam,
                chatbotId: chatbotIdParam,
                adScenarioId: adScenarioId,
                layerId: firstLayerId,
                blockId: firstBlockId,
                rank: layers && layers[firstLayerId] ? layers[firstLayerId].rank : null,
                locationSearch: location.search,
            });
            browserHistory.push(url);
            if (blockIdParam !== firstBlockId)
                setHighlightFilterId(null);
        };
    };
    var updatePopupMenuPosition = function (top, left) {
        setPopupMenuTop(top);
        setPopupMenuLeft(left);
    };
    var handlePopupMenuOutside = function () {
        setPopupMenuTop(0);
        setPopupMenuBottom(window.innerHeight - popupMenuTop + 17);
    };
    var IsScenarioInABTest = function (scenarioId) {
        var _a = (props.activeABTesting
            ? getABTestDetails(props.activeABTesting, props.allAvailableScenarios)
            : {}).ABTestScenarios, ABTestScenarios = _a === void 0 ? [] : _a;
        if (props.activeInitialConversation) {
            return ABTestScenarios.some(function (item) { return scenarioId === item.id; });
        }
        return false;
    };
    var getStatusColor = function (item) {
        var status = getScenarioStatus(item);
        var color;
        var chatTypeParam = props.chatTypeParam;
        if (chatTypeParam === chatTypes.INITIAL) {
            color = IsScenarioInABTest(item.id) ? statusColors.BLUE : '';
        }
        else if (chatTypeParam === chatTypes.STEP) {
            color = item.scenario_delivery ? statusColors.YELLOW : '';
        }
        else {
            switch (status) {
                case deliveryTypes.DELIVERED:
                    color = statusColors.BLUE;
                    break;
                case deliveryTypes.AWAITING:
                    color = statusColors.YELLOW;
                    break;
                default:
                    color = '';
            }
        }
        return color;
    };
    var handleCreateNewName = function (e) {
        setScenarioName(e.target.value);
    };
    var handleSubmitCreateName = function () { return __awaiter(void 0, void 0, void 0, function () {
        var createNewAdScenario, chatbotIdParam, chatTypeParam, isValid, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    createNewAdScenario = props.createNewAdScenario, chatbotIdParam = props.chatbotIdParam, chatTypeParam = props.chatTypeParam;
                    isValid = scenarioName.length > 0;
                    if (!isValid) return [3 /*break*/, 4];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, createNewAdScenario({
                            chatTypeParam: chatTypeParam,
                            chatbotId: chatbotIdParam,
                            newAdScenarioName: scenarioName,
                        })];
                case 2:
                    _a.sent();
                    return [3 /*break*/, 4];
                case 3:
                    e_1 = _a.sent();
                    handleModal(e_1.message);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    // BORDER BETWEEN SCENARIO AND TEMPLATE-SCENARIO
    var handleTemplateName = function (e) {
        setNewScenarioName(e.currentTarget.value);
    };
    var handleSelectedTemplate = function (template) {
        setSelectedTemplate(template);
    };
    var selectTemplateScenario = function () { return __awaiter(void 0, void 0, void 0, function () {
        var createTemplateScenario, chatbotIdParam, e_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!newScenarioName || !selectedTemplate)
                        return [2 /*return*/];
                    createTemplateScenario = props.createTemplateScenario, chatbotIdParam = props.chatbotIdParam;
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, createTemplateScenario({
                            chatbotId: chatbotIdParam,
                            templateId: selectedTemplate.value,
                            newScenarioName: newScenarioName,
                        })];
                case 2:
                    _a.sent();
                    return [3 /*break*/, 4];
                case 3:
                    e_2 = _a.sent();
                    handleModal(e_2.message);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var handleScenariosDeleteConfirm = function () {
        var deleteScenarios = props.deleteScenarios, adScenario = props.adScenario, chatbotIdParam = props.chatbotIdParam;
        var scenarios = adScenario.scenarios, deleteScenarioIds = adScenario.deleteScenarioIds;
        if (Object.keys(scenarios).length === deleteScenarioIds.length) {
            return alert('全てのシナリオを削除することはできません');
        }
        deleteScenarios(chatbotIdParam, deleteScenarioIds);
        return null;
    };
    var getScenarioStatus = function (scenario) {
        var scenario_delivery = scenario.scenario_delivery;
        if (!scenario_delivery)
            return '';
        if (!scenario_delivery.delivered && scenario_delivery.id)
            return deliveryTypes.AWAITING;
        if (scenario_delivery.delivered)
            return deliveryTypes.DELIVERED;
        return null;
    };
    var onChangePage = function (event) {
        var search = props.search, per = props.per, /* chatTypeParam, */ setHighlightFilterId = props.setHighlightFilterId;
        browserHistory.push({
            search: getAdScenariosUrlQueryString({
                page: event,
                per: per,
                search: search,
            }),
        });
        setHighlightFilterId(null);
    };
    var adScenario = props.adScenario, chatTypeParam = props.chatTypeParam, chatbotIdParam = props.chatbotIdParam, adScenarioIdParam = props.adScenarioIdParam, layerIdParam = props.layerIdParam, blockIdParam = props.blockIdParam, deleteScenario = props.deleteScenario, editScenarioMainSetting = props.editScenarioMainSetting, moveBlockToOtherLayer = props.moveBlockToOtherLayer, removeScenarioIdsChange = props.removeScenarioIdsChange, adSetLayerField = props.adSetLayerField, adToggleLayerField = props.adToggleLayerField, adSetUndefinedBlocksLayerField = props.adSetUndefinedBlocksLayerField, adToggleUndefinedBlocksLayerField = props.adToggleUndefinedBlocksLayerField, adOpenAllLayers = props.adOpenAllLayers, adToggleAllLayersField = props.adToggleAllLayersField, per = props.per, page = props.page, filteredScenarios = props.filteredScenarios, intl = props.intl;
    var errors = adScenario.errors, scenarios = adScenario.scenarios, layers = adScenario.layers, blocks = adScenario.blocks, deleteScenarioIds = adScenario.deleteScenarioIds, scenarioTemplates = adScenario.scenarioTemplates, totalScenarios = adScenario.totalScenarios;
    var isStepChat = chatTypeParam === chatTypes.STEP;
    var hasErrors = Object.keys(errors).length > 0;
    var isInitialChat = chatTypeParam === chatTypes.INITIAL;
    var filteredActiveScenarios = isStepChat
        ? Object.values(scenarios).filter(function (s) { return s.id === adScenarioIdParam; })
        : filteredScenarios;
    var showPagination = totalScenarios > per;
    var handleComposition = function (e) {
        if (e.type === 'compositionend') {
            setIsOnComposition(true);
            debouncedSearchScenarios(e.target.value);
        }
        else {
            setIsOnComposition(false);
        }
    };
    return (React.createElement(Container, { onMouseOver: function () { return setIsHover(true); } },
        React.createElement("div", { className: "new-scenario" },
            isStepChat ? (React.createElement(Link, { to: {
                    pathname: "/dashboard/ad/step/chatbots/" + chatbotIdParam + "/list",
                } },
                React.createElement(StepPageBack, { className: "adicon_pageback" }, "\u623B\u308B"))) : null,
            React.createElement(LeftMenuTab, { onClick: toggleTalkCustomizeVisible, "data-testid": "TalkCustomizeLeft-LeftMenuTab" }, isTalkCustomizeVisible ? (React.createElement("span", { className: "adicon_page_open" })) : (React.createElement("span", { className: "adicon_page_close" })))),
        isTalkCustomizeVisible && (React.createElement(ScenarioContainer, { ref: scenariosContainer },
            React.createElement("div", null,
                React.createElement("div", { className: "new-scenario" }, isStepChat ? (React.createElement(Link, { to: {
                        pathname: "/dashboard/ad/step/chatbots/" + chatbotIdParam + "/list",
                    } },
                    React.createElement(StepPageBack, { className: "adicon_pageback" }, "\u4E00\u89A7\u3078\u623B\u308B"))) : (React.createElement("div", null,
                    React.createElement(Button, { dataTest: "create-scenario", buttonType: buttonTypes.MAIN, text: intl.formatMessage({ id: "oUvahT", defaultMessage: "New Scenario" }), hasErrors: hasErrors, onClick: toggleNewScenarioNameModal }))))),
            React.createElement(Input, { dataTest: "talk-customize-left-search-input", value: searchInputValue, className: "adicon_search", placeholder: intl.formatMessage({ id: "1hUhPQ", defaultMessage: "Search Scenario by Name/ID" }), onCompositionEnd: handleComposition, onCompositionStart: handleComposition, onChange: function (e) {
                    setSearchInputValue(e.target.value);
                    if (isOnComposition) {
                        debouncedSearchScenarios(e.target.value);
                    }
                } }),
            showPagination && (React.createElement(PaginationContainer, { "data-testid": "scenario-pagination", totalItem: Math.ceil(totalScenarios / per) },
                React.createElement(Pagination, { hideFirstLastPages: true, activePage: Number(page), itemsCountPerPage: per, totalItemsCount: totalScenarios, onChange: onChangePage, pageRangeDisplayed: PAGE_RANGE_DISPLAYED, innerClass: "pagination", itemClass: "pagination-item" }))),
            React.createElement("div", { "data-testid": "scenarios-list", className: "scenarios-list" }, filteredActiveScenarios.map(function (item, i) {
                if (!item.layers)
                    return null;
                var scenarioLayers = item.layers;
                var relatedLayers = scenarioLayers.map(function (layer) {
                    if (typeof layer === 'number') {
                        return layers[layer];
                    }
                    return layers[layer.id];
                });
                var scenarioStatus = getScenarioStatus(item);
                var color = getStatusColor(item);
                // Find out which scenario to show
                var isActive = item.id === adScenarioIdParam;
                return (React.createElement(ScenarioItem, __assign({}, item, { per: adScenario.per, page: adScenario.page, search: adScenario.search, allLayers: adScenario.layers, layers: relatedLayers, blocks: blocks, key: item.id + "-scenario-item", updatePopupMenuPosition: updatePopupMenuPosition, showPopupMenu: showPopupMenu, isActive: isActive, chatTypeParam: chatTypeParam, chatbotIdParam: chatbotIdParam, adScenarioIdParam: adScenarioIdParam, layerIdParam: layerIdParam, blockIdParam: blockIdParam, handleScenarioItemClick: handleScenarioItemClick, color: color, status: scenarioStatus, moveBlockToOtherLayer: moveBlockToOtherLayer, removeScenarioIdsChange: removeScenarioIdsChange, isDeleteChecked: deleteScenarioIds.indexOf(item.id) >= 0, isDeleteMode: isDeleteMode, adSetLayerField: adSetLayerField, adToggleLayerField: adToggleLayerField, adSetUndefinedBlocksLayerField: adSetUndefinedBlocksLayerField, adToggleUndefinedBlocksLayerField: adToggleUndefinedBlocksLayerField, adOpenAllLayers: adOpenAllLayers, adToggleAllLayersField: adToggleAllLayersField, isHover: isHover })));
            })),
            templateScenarioModalOpen && (React.createElement(SelectTemplateScenario, { value: newScenarioName, selectValue: selectedTemplate, scenarioTemplates: scenarioTemplates, onCancel: toggleTemplateScenarioModal, onChange: handleTemplateName, onConfirm: selectTemplateScenario, onSelectedChange: handleSelectedTemplate, className: "template-name " + (newScenarioName ? '' : 'valid') })),
            newScenarioModalOpen && (React.createElement(CreateScenarioModal, { onCancel: toggleNewScenarioNameModal, value: scenarioName, onChange: handleCreateNewName, onConfirm: handleSubmitCreateName, onClickFromTemplate: function () {
                    toggleNewScenarioNameModal();
                    toggleTemplateScenarioModal();
                } })),
            deleteScenariosModalOpen && (React.createElement(DeleteScenariosModal, { onCancel: toggleDeleteScenariosModal, onConfirm: handleScenariosDeleteConfirm, intl: intl })),
            isPopupMenuVisible && (React.createElement(ScenarioItemMenu, { top: popupMenuTop, left: popupMenuLeft, bottom: popupMenuBottom, handlePopupMenuOutside: handlePopupMenuOutside, scenarios: scenarios, hidePopupMenu: hidePopupMenu, chatbotIdParam: chatbotIdParam, adScenarioIdParam: adScenarioIdParam, editScenarioMainSetting: editScenarioMainSetting, chatTypeParam: chatTypeParam, scenarioStatus: null })),
            React.createElement("div", { className: "delete-scenario" },
                isDeleteMode && (React.createElement(Button, { dataTest: "talk-customize-left-delete-btn", buttonType: buttonTypes.DELETE, text: intl.formatMessage({ id: "K3r6DQ", defaultMessage: "Delete" }), margin: "0 0 16px 0", onClick: toggleDeleteScenariosModal })),
                React.createElement(Button, { dataTest: "talk-customize-left-toggle-delete-btn", buttonType: buttonTypes.SETTING, onClick: toggleDeleteMode, text: isDeleteMode
                        ? intl.formatMessage({ id: "1DuvSj", defaultMessage: "Stop Delete" })
                        : intl.formatMessage({ id: "oxHRd9", defaultMessage: "Select and Delete" }) }))))));
};
var mapStateToProps = function (state) {
    return {
        modal: state.modal.present,
        mainScenarioId: state.settings.present.activeChatbot.default_scenario_id,
        activeInitialConversation: state.initialConversation.getActiveInitialConversation,
        allAvailableScenarios: state.initialConversation.scenarios,
        activeABTesting: state.initialConversation.ABTesting.active,
    };
};
export default connect(mapStateToProps, {
    showModal: showModal,
    fetchAdScenarios: fetchAdScenarios,
    createNewAdScenario: createNewAdScenario,
    createTemplateScenario: createTemplateScenario,
    deleteScenario: deleteScenario,
    deleteScenarios: deleteScenarios,
    editScenarioMainSetting: editScenarioMainSetting,
    moveBlockToOtherLayer: moveBlockToOtherLayer,
    removeScenarioIdsChange: removeScenarioIdsChange,
    adSetLayerField: adSetLayerField,
    adToggleLayerField: adToggleLayerField,
    adSetUndefinedBlocksLayerField: adSetUndefinedBlocksLayerField,
    adToggleUndefinedBlocksLayerField: adToggleUndefinedBlocksLayerField,
    adOpenAllLayers: adOpenAllLayers,
    adToggleAllLayersField: adToggleAllLayersField,
    setHighlightFilterId: setHighlightFilterId,
})(injectIntl(TalkCustomizeLeft));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
