var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React, { memo } from 'react';
import { Handle, Position } from 'react-flow-renderer';
import styled from 'styled-components';
import { colors, shadows } from '@src/colors';
import { copyToClipboard } from '@src/lib/helpers';
import { useDispatch } from 'react-redux';
import { handleChangeHoverNode, handleChangeAlertMessage, } from '@src/redux/fanp/actions/scenarioMap';
import TalkTriggerNode from './TalkTriggerNode';
import QuickReplyNode from './QuickReplyNode';
import MessageNode from './MessageNode';
var BlockContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: relative;\n  border-style: solid;\n  min-width: 260px;\n  margin: 20px;\n  padding: 5px;\n  border-radius: 10px;\n  background: ", ";\n  box-shadow: ", ";\n  border-width: 1px;\n  border-color: ", ";\n  width: ", ";\n  height: ", ";\n\n  > div:last-of-type {\n    position: absolute;\n    width: ", ";\n    height: ", ";\n    top: 0px;\n    left: 0px;\n    border-radius: 10px;\n  }\n\n  .block_name {\n    z-index: 1;\n    position: relative;\n    cursor: pointer;\n    padding: 0 8px;\n\n    &:hover {\n      font-weight: bold;\n    }\n  }\n"], ["\n  position: relative;\n  border-style: solid;\n  min-width: 260px;\n  margin: 20px;\n  padding: 5px;\n  border-radius: 10px;\n  background: ", ";\n  box-shadow: ", ";\n  border-width: 1px;\n  border-color: ", ";\n  width: ", ";\n  height: ", ";\n\n  > div:last-of-type {\n    position: absolute;\n    width: ", ";\n    height: ", ";\n    top: 0px;\n    left: 0px;\n    border-radius: 10px;\n  }\n\n  .block_name {\n    z-index: 1;\n    position: relative;\n    cursor: pointer;\n    padding: 0 8px;\n\n    &:hover {\n      font-weight: bold;\n    }\n  }\n"])), colors.background.white, shadows.medium.mid, colors.border.default, function (props) { return (props.width ? props.width : 'unset'); }, function (props) { return (props.height ? props.height : 'unset'); }, function (props) { return (props.width ? props.width : 'unset'); }, function (props) { return (props.height ? props.height : 'unset'); });
var BlockNode = function (_a) {
    var _b = _a.data, block = _b.block, updateBoundaryNode = _b.updateBoundaryNode, _c = _b.style, style = _c === void 0 ? {} : _c;
    var dispatch;
    if (!updateBoundaryNode) {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        dispatch = useDispatch();
    }
    var handleMouseEvent = function (hoveredNode) {
        if (updateBoundaryNode)
            return;
        dispatch(handleChangeHoverNode(hoveredNode));
    };
    return (React.createElement(React.Fragment, null,
        !updateBoundaryNode && (React.createElement(Handle, { type: "target", position: Position.Top, style: { top: 17 }, "data-testid": "BlockNode_Handle" })),
        React.createElement(BlockContainer, { width: style.width, height: style.height, ref: function (ref) {
                if (!ref || !updateBoundaryNode)
                    return;
                updateBoundaryNode('blocks', block.id, ref.getBoundingClientRect());
            }, onMouseEnter: function () { return handleMouseEvent({ blockId: block.id }); }, onMouseLeave: function () { return handleMouseEvent({ blockId: null }); }, "data-testid": "BlockNode_BlockContainer" },
            React.createElement("span", { className: "block_name", onClick: function () {
                    copyToClipboard(String(block.id));
                    dispatch(handleChangeAlertMessage('ブロックIDを取得しました'));
                }, role: "button", tabIndex: 0 }, block.name + "(" + block.id + ")"),
            (block.messages || []).map(function (message) { return (React.createElement(MessageNode, { key: "message-" + message.id, data: { message: message, updateBoundaryNode: updateBoundaryNode } })); }),
            block.quick_reply && (React.createElement(QuickReplyNode, { data: { quickReply: block.quick_reply, updateBoundaryNode: updateBoundaryNode } })),
            block.talk_trigger && (React.createElement(TalkTriggerNode, { data: { talkTrigger: block.talk_trigger, updateBoundaryNode: updateBoundaryNode } })),
            React.createElement("div", null))));
};
export default memo(BlockNode);
var templateObject_1;
