import "core-js/modules/es.array.map";
import styled from 'styled-components';
import { colors } from '@src/colors';
import { transitions } from '@src/lib/animations'; // QUICK REPLY

export var QuickReplyContainer = styled.div.withConfig({
  displayName: "styles__QuickReplyContainer",
  componentId: "sc-10it63f-0"
})(["display:flex;width:fit-content;margin-top:40px;.priority{opacity:0;}.label_input{width:240px;}&:hover{.priority{opacity:1;display:flex;}}"]);
export var ContentContainer = styled.div.withConfig({
  displayName: "styles__ContentContainer",
  componentId: "sc-10it63f-1"
})(["display:flex;"]);
export var AddQuickButton = styled.div.withConfig({
  displayName: "styles__AddQuickButton",
  componentId: "sc-10it63f-2"
})(["width:38px;height:38px;border-radius:50%;border:1px solid ", ";background:", ";font-size:20px;color:", ";text-align:center;padding-top:5px;&:hover{cursor:pointer;background:", ";color:", ";}"], colors.quick_reply, colors.white, colors.quick_reply, colors.quick_reply, colors.white); // QUICK REPLY CHOICE

export var InputContainer = styled.div.withConfig({
  displayName: "styles__InputContainer",
  componentId: "sc-10it63f-3"
})(["display:flex;flex-direction:column;margin-right:8px;position:relative;p{margin:0;font-size:12px;color:", ";white-space:nowrap;&::before{font-size:24px;color:", ";}}"], colors.text_pale, colors.default_text);
export var Wrapper = styled.div.withConfig({
  displayName: "styles__Wrapper",
  componentId: "sc-10it63f-4"
})(["width:fit-content;border:", ";border-radius:25px;height:32px;display:flex;justify-content:center;align-items:center;align-self:flex-start;padding:0 22px;position:relative;background:", ";-webkit-transition:", ";-moz-transition:", ";-o-transition:", ";transition:", ";opacity:1;", " &.being-deleted{opacity:0.5;}&:hover{.adicon_delete{display:block;}}"], function (props) {
  var validations = props.errors[props.getKey()];
  var isValid = validations ? validations.map(function (v) {
    return v.isValid;
  }).indexOf(false) === -1 : true;
  return isValid ? "1px solid ".concat(colors.quick_reply) : "1px solid ".concat(colors.border.error);
}, colors.background.main_menu, transitions.normal, transitions.normal, transitions.normal, transitions.normal, function (props) {
  return props.alignSelf ? 'align-self: center;' : '';
});
export var QuickReplyText = styled.span.withConfig({
  displayName: "styles__QuickReplyText",
  componentId: "sc-10it63f-5"
})(["position:fixed;visibility:hidden;white-space:pre;border:0;display:inline-block;"]);
export var QuickReplyInput = styled.input.withConfig({
  displayName: "styles__QuickReplyInput",
  componentId: "sc-10it63f-6"
})(["position:relative;width:", ";font-size:14px;color:", ";border:none;text-align:", ";"], function (props) {
  var spanWidth = props['data-spanwidth'] ? props['data-spanwidth'] : props.spanWidth;
  var defaultWidth = props['data-defaultwidth'] ? props['data-defaultwidth'] : props.defaultWidth;
  return props.value.length ? "".concat(spanWidth + 5, "px") : "".concat(defaultWidth, "px");
}, colors.quick_reply, function (props) {
  return props.value.length < 1 ? 'left' : 'center';
});