var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
// TODO: FIX BELOW ESLINT ERRORS
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, Input } from '@src/components/atoms';
import buttonTypes from '@src/lib/buttonTypes';
import { fetchSignup } from '@src/lib/adHelpers';
import { setCurrentUser } from '@src/redux/fanp/actions/account';
import { validateEmailAddress, validatePassword } from '@src/lib/helpers';
import * as pathLinks from '@src/routes/Fanp/pathLinks';
import { handleAPIErrors } from '@src/lib/helpers/ajaxFuncs';
import { LoginWrapper } from './Login';
var LoginSignup = function (_a) {
    var setCurrentUser = _a.setCurrentUser;
    var _b = __read(useState(''), 2), email = _b[0], setEmail = _b[1];
    var _c = __read(useState(''), 2), password = _c[0], setPassword = _c[1];
    var _d = __read(useState(''), 2), passwordConfirm = _d[0], setPasswordConfirm = _d[1];
    var _e = __read(useState(''), 2), errorMsg = _e[0], setErrorMsg = _e[1];
    var browserHistory = useHistory();
    var hasEmailError = !email || !validateEmailAddress(email);
    var hasPasswordUnmatchError = password !== passwordConfirm;
    var hasPasswordValidationError = !validatePassword(password);
    var hasPasswordError = hasPasswordUnmatchError || hasPasswordValidationError;
    function handleSubmit(e) {
        e.preventDefault();
        if (hasEmailError) {
            setErrorMsg('メールアドレスは不正です');
            return;
        }
        if (hasPasswordValidationError) {
            setErrorMsg('パスワードは8文字以上必要です。大文字、小文字、および数字を含める必要があります。');
            return;
        }
        if (hasPasswordUnmatchError) {
            setErrorMsg('パスワードは一致していません');
            return;
        }
        fetchSignup(email, password)
            .then(function (_a) {
            var ok = _a.ok, clientUser = _a.clientUser;
            if (ok) {
                setCurrentUser(clientUser);
                browserHistory.push(pathLinks.dashboardNewAccount);
            }
            else {
                setErrorMsg('登録できませんでした');
            }
        })
            .catch(function (e) {
            console.error(e);
            handleAPIErrors(e.message);
        });
    }
    return (React.createElement(LoginWrapper, { maxHeight: "1000px" },
        React.createElement("div", { className: "login-panel-container" },
            React.createElement("h2", null, "\u767B\u9332 / Sign Up"),
            errorMsg && React.createElement("div", { className: "error" }, errorMsg),
            React.createElement("form", { onSubmit: handleSubmit },
                React.createElement("div", { className: "form-body" },
                    React.createElement("label", { htmlFor: "email" }, "\u30E1\u30FC\u30EB\u30A2\u30C9\u30EC\u30B9 / email address"),
                    React.createElement(Input, { className: "login_form", placeholder: "\u30E1\u30FC\u30EB\u30A2\u30C9\u30EC\u30B9", hasError: hasEmailError, name: "email", value: email, onChange: function (e) { return setEmail(e.target.value); } }),
                    React.createElement("label", { htmlFor: "password" }, "\u30D1\u30B9\u30EF\u30FC\u30C9"),
                    React.createElement("label", { htmlFor: "password rules" },
                        "8\u6587\u5B57\u4EE5\u4E0A\u5FC5\u8981\u3067\u3059\u3002\u5927\u6587\u5B57\u3001\u5C0F\u6587\u5B57\u3001",
                        React.createElement("br", null),
                        "\u304A\u3088\u3073\u6570\u5B57\u3092\u542B\u3081\u308B\u5FC5\u8981\u304C\u3042\u308A\u307E\u3059\u3002"),
                    React.createElement("label", null, "Password"),
                    React.createElement("label", { htmlFor: "password rules english" },
                        "Must be at least 8 characters, and include",
                        React.createElement("br", null),
                        "uppercase, lowercase and numbers."),
                    React.createElement(Input, { className: "login_form", placeholder: "\u30D1\u30B9\u30EF\u30FC\u30C9", hasError: hasPasswordError, name: "password", value: password, onChange: function (e) { return setPassword(e.target.value); }, type: "password" }),
                    React.createElement("label", { htmlFor: "confirm password" }, "\u30D1\u30B9\u30EF\u30FC\u30C9\u306E\u78BA\u8A8D / confirm password"),
                    React.createElement(Input, { className: "login_form", placeholder: "\u30D1\u30B9\u30EF\u30FC\u30C9\u306E\u78BA\u8A8D", name: "password-confirm", hasError: hasPasswordError, value: passwordConfirm, onChange: function (e) { return setPasswordConfirm(e.target.value); }, type: "password" })),
                React.createElement("div", { className: "submit-body" },
                    React.createElement(Button, { buttonType: buttonTypes.MAIN, text: "\u767B\u9332 / Sign Up", height: 70 }))))));
};
export default connect(function () { }, { setCurrentUser: setCurrentUser })(LoginSignup);
