import { object, string, number } from 'yup';
import { tabTypes } from '@src/lib/app-constants';
import { urlReg, emailReg, phoneNumReg } from '@src/lib/helpers/validate';
import { addMaxLength } from '@src/lib/helpers/formSchema';
import { uiMessages } from '@src/i18n/translations';
import generateEffect from './generateEffect';
/**
 * This schema is validate for postback action (that change from action selector)
 *
 * @param intl - to do i18n
 * @param platform - to set max length for input text field
 * @param context - will contain effect value to validate url
 * @block_id_required for required block_id default is true
 * @scenario_id_required for required scenario_id default is true
 *
 * someSchema = object({
 *   ...,
 *   postback_action: PostbackActionSchema(intl, platform, context)
 * })
 *
 * it will return erros object:
 * effect - is errors when click change tab on action selector
 * block_id - errors when effect is block and not select any block
 * scenario_id - errors when effect is scenario and not select any scenario
 * url - erros when effect is url, phone_number or email, depend on effect, it will
 * test with regex
 */
export default (function (intl, platform, context, _a, activeTab) {
    var _b = _a.block_id_required, block_id_required = _b === void 0 ? true : _b, _c = _a.scenario_id_required, scenario_id_required = _c === void 0 ? true : _c;
    var activeGeneratedEffect = '';
    return object()
        .shape({
        effect: string()
            .when(['block_id', 'scenario_id', 'url'], {
            is: function (block_id, scenario_id, url) {
                if (!(block_id || scenario_id || url))
                    return false;
                var gEffect = generateEffect(block_id, scenario_id, url);
                activeGeneratedEffect = gEffect || tabTypes.BLOCK;
                if (context.effect === gEffect)
                    return false;
                // if (!gEffect) return false;
                return true;
            },
            // 'THEN' METHOD CAN'T HAVE ARGS LIKE 'IS' METHOD ABOVE
            then: function () {
                var gEffectJp = intl.formatMessage(uiMessages[activeGeneratedEffect.toUpperCase()]);
                var contextEffectJp = intl.formatMessage(uiMessages[context.effect.toUpperCase()]);
                return string().matches(new RegExp("^" + activeGeneratedEffect + "/$"), {
                    message: gEffectJp + " " + intl.formatMessage({ id: "UynOPg", defaultMessage: "is currently being edited so" }) + " " + contextEffectJp + " " + intl.formatMessage({ id: "xO1AT9", defaultMessage: "cannot be added" }),
                });
            },
        })
            .nullable(),
        block_id: number()
            .nullable()
            .when([], {
            is: function () { return context.effect === tabTypes.BLOCK; },
            then: block_id_required ? number().required() : number(),
        }),
        scenario_id: number()
            .nullable()
            .when([], {
            is: function () { return context.effect === tabTypes.SCENARIO; },
            then: scenario_id_required ? number().required() : number(),
        }),
        url: string()
            .nullable()
            .when([], {
            is: function () { return context.effect === tabTypes.PHONE_NUMBER; },
            then: string()
                .nullable()
                .required(intl.formatMessage({ id: "RNI5xy", defaultMessage: "Please enter a Phone Number" }))
                .test('customPhoneNumberReg', intl.formatMessage({ id: "iewZug", defaultMessage: "Please enter a valid Phone Number" }), function (phoneNumber) {
                return phoneNumber &&
                    phoneNumber.startsWith('tel:') &&
                    phoneNumReg.test(phoneNumber.replace('tel:', ''));
            }),
        })
            .when([], {
            is: function () { return context.effect === tabTypes.EMAIL; },
            then: string()
                .nullable()
                .required(intl.formatMessage({ id: "8qFwc3", defaultMessage: "Please enter an Email" }))
                .test('customEmailReg', intl.formatMessage({ id: "1524ij", defaultMessage: "Please enter a valid Email" }), function (email) {
                return email &&
                    email.startsWith('mailto:') &&
                    emailReg.test(email.replace('mailto:', ''));
            }),
        })
            .when([], {
            is: function () { return context.effect === tabTypes.URL; },
            then: addMaxLength(string()
                .nullable()
                .required(intl.formatMessage({ id: "rTUZkk", defaultMessage: "URL Required" }))
                .matches(urlReg, intl.formatMessage({ id: "bPdOC1", defaultMessage: "URL is invalid" })), intl, 'common.url', platform),
        }),
    })
        .nullable();
});
