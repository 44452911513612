import platformLimitations from '@src/lib/platforms/limitations';
export var messageSectionsInBlockCount = function messageSectionsInBlockCount(block) {
  return (block.message_sections || []).length;
};
export var isExceedMaximumMessageCount = function isExceedMaximumMessageCount(messagesLength, platform) {
  if (!platform) return false;
  var addedMessageMaximum = platformLimitations[platform].BLOCK_MESSAGES_MAX;
  if (addedMessageMaximum === undefined) return true;
  return messagesLength > parseInt(addedMessageMaximum);
};
export var isAllowAddMessage = function isAllowAddMessage(messagesLength, platform) {
  if (!platform) return false;
  var addedMessageMaximum = platformLimitations[platform].BLOCK_MESSAGES_MAX;
  if (addedMessageMaximum === undefined) return true;
  return messagesLength < parseInt(addedMessageMaximum);
};