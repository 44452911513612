import { SelectedTab } from '@src/redux/fanp/types/rightMenu';
import featureFlags from '@src/lib/feature_flags/featureFlags';
var rightMenuTabs = [
    {
        tabName: SelectedTab.Preview,
        icon: 'adicon_preview',
        testId: 'right-menu-tab-preview',
    },
    featureFlags.SCENARIO_MAP_V1_SHOWN && {
        tabName: SelectedTab.ScenarioMapV1,
        icon: 'adicon_flow',
        testId: 'right-menu-tab-scenario-mapv1',
    },
    featureFlags.SCENARIO_MAP_V2_SHOWN && {
        tabName: SelectedTab.ScenarioMapV2,
        icon: 'adicon_flow',
        testId: 'right-menu-tab-scenario-mapv2',
    },
    {
        tabName: SelectedTab.Send,
        icon: 'adicon_setting_send',
        testId: 'right-menu-tab-send',
    },
    {
        tabName: SelectedTab.UploadedImages,
        icon: 'adicon_placeholder_image',
        testId: 'right-menu-tab-uploaded-images',
    },
    {
        tabName: SelectedTab.UploadedVideos,
        icon: 'adicon_placeholder_video',
        testId: 'right-menu-tab-uploaded-videos',
    },
    {
        tabName: SelectedTab.UploadedAudio,
        icon: 'adicon_placeholder_audio',
        testId: 'right-menu-tab-uploaded-audio',
    },
    {
        tabName: SelectedTab.FlexMessageTemplate,
        icon: 'adicon_msg_flex',
        testId: 'right-menu-tab-flex-message-template',
    },
    {
        tabName: SelectedTab.MessageFilter,
        icon: 'adicon_filter',
        testId: 'right-menu-tab-message-filter',
    },
    {
        tabName: SelectedTab.ScenarioComments,
        icon: 'adicon_comment',
        testId: 'right-menu-tab-scenario-comments',
    },
].filter(Boolean);
export default rightMenuTabs;
