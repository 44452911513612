var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { colors, shadows } from '@src/colors';
import { hoverMenuBg } from '@src/lib/mixins';
import useComponentVisible from '@src/components/hooks/useComponentVisible';
var DEFAULT_HEIGHT = 30;
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: relative;\n  height: fit-content;\n  width: fit-content;\n  font-size: 14px;\n  .adicon {\n    ", ";\n    height: 100%;\n    width: 100%;\n    color: ", ";\n    background-color: ", ";\n    border: none;\n    :hover {\n      color: ", ";\n      background-color: #d1d7ed;\n      border-radius: ", "px;\n    }\n  }\n"], ["\n  position: relative;\n  height: fit-content;\n  width: fit-content;\n  font-size: 14px;\n  .adicon {\n    ",
    ";\n    height: 100%;\n    width: 100%;\n    color: ",
    ";\n    background-color: ", ";\n    border: none;\n    :hover {\n      color: ", ";\n      background-color: #d1d7ed;\n      border-radius: ", "px;\n    }\n  }\n"])), function (props) {
    return props.hasLabel
        ? 'padding: 6px 16px; :before {margin-right: 10px}'
        : 'padding: 6px';
}, function (props) {
    return props.iconColor ? props.iconColor : "" + colors.text_navy;
}, colors.white, colors.main_color, DEFAULT_HEIGHT);
var ItemContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  position: absolute;\n  z-index: 1;\n  display: ", ";\n  ", ";\n  ", ";\n  background: ", ";\n  color: ", ";\n  box-shadow: ", ";\n  min-width: 120px;\n  border-radius: 10px;\n  button {\n    padding: 0.5rem;\n    font-size: 0.775rem;\n    transition: 0.3s;\n    width: 100%;\n    text-align: left;\n    background-color: ", ";\n    border: none;\n    ", ";\n  }\n"], ["\n  position: absolute;\n  z-index: 1;\n  display: ", ";\n  ",
    ";\n  ", ";\n  background: ", ";\n  color: ", ";\n  box-shadow: ", ";\n  min-width: 120px;\n  border-radius: 10px;\n  button {\n    padding: 0.5rem;\n    font-size: 0.775rem;\n    transition: 0.3s;\n    width: 100%;\n    text-align: left;\n    background-color: ", ";\n    border: none;\n    ", ";\n  }\n"])), function (props) { return (!props.isOverWindowHeight ? '' : 'inline-block'); }, function (props) {
    return props.isOverWindowHeight
        ? "bottom: " + (props.dropdownBtnHeight + 8 || DEFAULT_HEIGHT) + "px"
        : "top: " + (props.dropdownBtnHeight + 8 || DEFAULT_HEIGHT) + "px";
}, function (props) { return (props.isOverWindowWidth ? 'right: 0px' : 'left: 0px'); }, colors.background.main_menu, colors.text_navy, shadows.medium.light, colors.white, hoverMenuBg);
/**
 *
 * @param {string} [adicon] the icon of button dropdown
 * @param {React.CSSProperties} [wrapperStyle] the prop that you can modify the Dropdown Wrapper from outside
 * @param {string} [dropdownLabel] the label of button dropdown
 * @param {{label: String, onClick: () => void}[]} [dropdownOptions] the dropdown options
 */
var Dropdown = function (_a) {
    var _b = _a.adicon, adicon = _b === void 0 ? 'adicon_other' : _b, dropdownOptions = _a.dropdownOptions, wrapperStyle = _a.wrapperStyle, dropdownLabel = _a.dropdownLabel, iconColor = _a.iconColor;
    var _c = useComponentVisible(false), ref = _c.ref, optionsToggle = _c.isComponentVisible, setOptionsToggle = _c.setIsComponentVisible;
    var _d = __read(useState(false), 2), isOverWindowHeight = _d[0], setIsOverWindowHeight = _d[1];
    var _e = __read(useState(false), 2), isOverWindowWidth = _e[0], setIsOverWindowWidth = _e[1];
    var _f = __read(useState(), 2), dropdownBtnHeight = _f[0], setDropdownBtnHeight = _f[1];
    useEffect(function () {
        var _a;
        if (ref === null || ref === void 0 ? void 0 : ref.current) {
            var height = ((_a = ref) === null || _a === void 0 ? void 0 : _a.current.getBoundingClientRect()).height;
            setDropdownBtnHeight(height);
        }
    }, [ref]);
    var optionChildren = useMemo(function () {
        if (!optionsToggle)
            return null;
        return (React.createElement(ItemContainer, { id: "dropdown-option-wrapper", "aria-label": "dropdown-option-wrapper", isOverWindowHeight: isOverWindowHeight, isOverWindowWidth: isOverWindowWidth, dropdownBtnHeight: dropdownBtnHeight, ref: function (e) { return handleOptionPosition(e); } }, dropdownOptions.map(function (_a) {
            var label = _a.label, onClick = _a.onClick;
            if (!label)
                return null;
            return (React.createElement("button", { type: "button", className: "dropdown-option", "data-testid": "DropdownOption", key: label, onClick: function () {
                    onClick();
                    setOptionsToggle(false);
                } }, label));
        })));
    }, [dropdownOptions, isOverWindowHeight, isOverWindowWidth, optionsToggle]);
    var toggleOptions = function () {
        setIsOverWindowHeight(false);
        setIsOverWindowWidth(false);
        setOptionsToggle(!optionsToggle);
    };
    var handleOptionPosition = function (e) {
        if (e && e.getBoundingClientRect().bottom > window.innerHeight) {
            setIsOverWindowHeight(true);
        }
        if (e && e.getBoundingClientRect().right > window.innerWidth) {
            setIsOverWindowWidth(true);
        }
    };
    return (React.createElement(Container, { ref: ref, "data-testid": "Dropdown", style: wrapperStyle, hasLabel: !!dropdownLabel, iconColor: iconColor },
        React.createElement("button", { type: "button", "data-testid": "DropdownButton", "aria-label": "adicon " + adicon, className: "adicon " + adicon, id: adicon, onClick: toggleOptions }, dropdownLabel),
        optionChildren));
};
export default Dropdown;
var templateObject_1, templateObject_2;
