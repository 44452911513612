import { fieldValue } from '@src/redux/fanp/constants';
var SET_SELECTED_FIELD = fieldValue.SET_SELECTED_FIELD, SET_FIELDS = fieldValue.SET_FIELDS;
export var setSelectedField = function (payload) { return ({
    type: SET_SELECTED_FIELD,
    payload: payload,
}); };
export var setFields = function (payload) { return ({
    type: SET_FIELDS,
    payload: payload,
}); };
