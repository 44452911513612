var collection = function (headlessConfigId, errorCheckId) {
    return "/zeals_admin/api/headless_config/" + headlessConfigId + "/headless_error_checks/" + errorCheckId + "/headless_error_handlers";
};
var member = function (headlessConfigId, errorCheckId, id) {
    return collection(headlessConfigId, errorCheckId) + "/" + id;
};
export default {
    collection: collection,
    member: member,
};
