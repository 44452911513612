export var FormAutomationTypes;
(function (FormAutomationTypes) {
    FormAutomationTypes["SET_FORM_CONFIG"] = "SET_FORM_CONFIG";
    FormAutomationTypes["SET_FORM_CONFIGS"] = "SET_FORM_CONFIGS";
    FormAutomationTypes["SET_FORM_CONFIG_URL"] = "SET_FORM_CONFIG_URL";
    FormAutomationTypes["SET_FORM_AUTOMATION_STEP"] = "SET_FORM_AUTOMATION_STEP";
    FormAutomationTypes["SET_STEP_ORDER"] = "SET_STEP_ORDER";
    FormAutomationTypes["DELETE_STEP"] = "DELETE_STEP";
    FormAutomationTypes["ADD_STEP_TO_CONFIG"] = "ADD_STEP_TO_CONFIG";
    FormAutomationTypes["ADD_STEP_TO_CONFIG_WITH_INDEX"] = "ADD_STEP_TO_CONFIG_WITH_INDEX";
    FormAutomationTypes["SET_STEP_ERRORS"] = "SET_STEP_ERRORS";
    FormAutomationTypes["REMOVE_FORM_AUTOMATION_CONFIG"] = "REMOVE_FORM_AUTOMATION_CONFIG";
})(FormAutomationTypes || (FormAutomationTypes = {}));
