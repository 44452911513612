import "core-js/modules/es.array.map";
import "core-js/modules/es.function.name";
import _extends from "@babel/runtime/helpers/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
import React from 'react';
import styled from 'styled-components';
import { colors } from '@src/colors';
import { centeredFlex, hoverHighlight } from '@src/lib/mixins';
import { getAdIconNextBlockType } from '@src/lib/adHelpers';
import { injectIntl } from 'react-intl';
import TalkCustomizeAddButtonModal from './TalkCustomizeAddButtonModal';
import TalkCustomizeButton from './TalkCustomizeButton';
import { v4 as uuid } from 'uuid';
var AddButton = styled.div.withConfig({
  displayName: "AddButton",
  componentId: "sc-ro3ssb-0"
})(["", ";padding:15px 0;color:", ";height:59px;", ";&:before{font-size:20px;}"], centeredFlex, colors.placeholder, hoverHighlight);

var TalkCustomizeButtonList = function TalkCustomizeButtonList(_ref) {
  var scenarios = _ref.scenarios,
      blocks = _ref.blocks,
      getSubText = _ref.getSubText,
      _removeButton = _ref.removeButton,
      buttons = _ref.buttons,
      toggleModal = _ref.toggleModal,
      newButtonModalIsOpen = _ref.newButtonModalIsOpen,
      message = _ref.message,
      limits = _ref.limits,
      addNewButton = _ref.addNewButton,
      intl = _ref.intl,
      rest = _objectWithoutProperties(_ref, ["scenarios", "blocks", "getSubText", "removeButton", "buttons", "toggleModal", "newButtonModalIsOpen", "message", "limits", "addNewButton", "intl"]);

  return React.createElement(React.Fragment, null, React.createElement("div", null, buttons && buttons.map(function (buttonItem, i) {
    var hasBlock = buttonItem.button.postback_action && (!!buttonItem.button.postback_action.block_id || !!buttonItem.button.postback_action.scenario_id);
    return React.createElement(TalkCustomizeButton, _extends({
      key: "button-item-".concat(uuid),
      buttonItem: buttonItem
    }, buttonItem.button, {
      text: buttonItem.button.name,
      subText: getSubText(buttonItem.button),
      hasBlock: hasBlock,
      nextBlockType: hasBlock ? getAdIconNextBlockType({
        adScenarios: scenarios,
        blocks: blocks,
        connectedObject: buttonItem.button
      }) : '',
      removeButton: function removeButton() {
        return _removeButton(buttonItem);
      },
      buttonIterator: i,
      messageId: message.id
    }, rest));
  }), (buttons && buttons.length < limits.MAX_CAROUSEL_BUTTON_AMOUNT || !buttons) && React.createElement(React.Fragment, null, React.createElement(AddButton, {
    className: "adicon_plus02",
    onClick: toggleModal,
    "data-testid": "TalkCustomizeButtonList_Addbutton"
  }, intl.formatMessage({
    id: "wLQd60",
    defaultMessage: [{
      "type": 0,
      "value": "Add Button"
    }]
  })), newButtonModalIsOpen && React.createElement(TalkCustomizeAddButtonModal, _extends({
    onConfirm: addNewButton,
    onCancel: toggleModal,
    messageId: message.id,
    buttons: buttons
  }, rest)))));
};

export default injectIntl(TalkCustomizeButtonList);