var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { colors } from '@src/colors';
import { hoverHighlightText } from '@src/lib/mixins';
import { makeBlockUrl } from '@src/lib/adHelpers';
import { setHighlightFilterId } from '@src/redux/fanp/actions/messageFilter';
var BlockContainer = styled.li(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  cursor: pointer;\n  padding: 4px 0 4px 40px;\n  font-size: 0.75rem;\n  ", ";\n  .adicon_other {\n    color: ", ";\n    font-weight: normal;\n  }\n  span {\n    color: ", ";\n    font-weight: ", ";\n  }\n"], ["\n  cursor: pointer;\n  padding: 4px 0 4px 40px;\n  font-size: 0.75rem;\n  ", ";\n  .adicon_other {\n    color: ", ";\n    font-weight: normal;\n  }\n  span {\n    color: ", ";\n    font-weight: ", ";\n  }\n"])), hoverHighlightText, function (props) { return (props.active ? colors.default_text : 'inherit'); }, function (props) { return (props.active ? colors.default_text : 'inherit'); }, function (props) { return (props.active ? 'bold' : 'unset'); });
var MAX_CHARS = 13;
var Block = function (props) {
    var dispatch = useDispatch();
    var browserHistory = useHistory();
    var location = useLocation();
    var handleIconOtherClick = function (e) {
        var layerIdParam = props.layerIdParam, id = props.id, layers = props.layers, showBlockItemMenu = props.showBlockItemMenu;
        var rect = e.target.getBoundingClientRect();
        showBlockItemMenu(layerIdParam, id, layers, rect);
    };
    var showBlock = function () {
        var blockUrl = makeBlockUrl({
            chatTypeParam: props.chatTypeParam,
            chatbotIdParam: props.chatbotIdParam,
            adScenarioIdParam: props.adScenarioIdParam,
            layerIdParam: props.layerIdParam,
            id: props.id,
            layerRank: props.layerRank,
            locationSearch: location.search,
        });
        browserHistory.push(blockUrl);
        if (props.blockIdParam !== props.id)
            dispatch(setHighlightFilterId(null));
    };
    var formatName = function () {
        var name = props.name;
        return name.length > MAX_CHARS
            ? name.substring(0, MAX_CHARS) + "..."
            : name;
    };
    var id = props.id, blockIdParam = props.blockIdParam, isHover = props.isHover;
    return (React.createElement(BlockContainer, { active: id === blockIdParam, onClick: showBlock, ref: function (ref) {
            if (!ref || blockIdParam !== id || isHover)
                return;
            // When select block from filter detail, it need to focus on scenarios
            // list (talkcustomize left)
            ref.scrollIntoView({ behavior: 'smooth', block: 'center' });
        } },
        React.createElement("span", null, formatName()),
        React.createElement("span", { className: "adicon_other", "aria-label": "Open options menu", role: "button", tabIndex: 0, onClick: handleIconOtherClick, "data-testid": "Block_OptionsMenuButton" })));
};
export default Block;
var templateObject_1;
