var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import styled from 'styled-components';
import { colors } from '@src/colors';
var LabelMessageContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: ", ";\n  display: inline-flex;\n  border-radius: 30px;\n  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1);\n  background-color: ", ";\n  color: ", ";\n"], ["\n  padding: ", ";\n  display: inline-flex;\n  border-radius: 30px;\n  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1);\n  background-color: ", ";\n  color: ", ";\n"])), function (props) { return props.padding || '8px'; }, function (props) { return props.backgroundColor || colors.white; }, function (props) { return props.color || colors.white; });
var IconPlaceholder = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin-right: 3px;\n"], ["\n  margin-right: 3px;\n"])));
var Label = styled.span(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  text-align: center;\n"], ["\n  text-align: center;\n"])));
var LabeledMessage = function (props) {
    return (React.createElement(LabelMessageContainer, __assign({}, props),
        props.classNameIcon && (React.createElement(IconPlaceholder, null,
            React.createElement("span", { className: props.classNameIcon }))),
        React.createElement(Label, null, props.text)));
};
export { LabeledMessage as default };
var templateObject_1, templateObject_2, templateObject_3;
