/**
 * A function which will handle displaying the flash portal for the specified time plus one second
 * The extra second ensures that any animations have time to run
 * @param setFlashStateFn A function that can set the flash state, like a hook fn
 * @param secondsVisible The length of time in seconds the flash should be visible
 */
export function displayFlash(setFlashStateFn, secondsVisible) {
    setFlashStateFn(true);
    setTimeout(function () { return setFlashStateFn(false); }, (secondsVisible + 1) * 1000);
}
