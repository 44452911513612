import head from 'lodash/head';
export var MESSAGE_TYPES = {
    TEXT: 'text',
    END_USER: 'end_user',
    CLIENT: 'client',
    QUICK_REPLY: 'quick_reply',
    GENERIC: 'generic',
    LIST: 'list',
    MEDIA: 'media',
    CHOICE: 'choice',
    LINE_FLEX_MESSAGE: 'line_flex_message',
};
export function determineMsgType(_a) {
    var eventLog = _a.eventLog, message = _a.message;
    var hasMsg = (eventLog && !!eventLog.message) || !!message;
    var msg = (eventLog && eventLog.message) || message;
    if (message && (message.text || message.text === '')) {
        return MESSAGE_TYPES.TEXT;
    }
    if (eventLog && eventLog.end_user_message) {
        return MESSAGE_TYPES.END_USER;
    }
    if ((hasMsg && msg.text) ||
        (hasMsg && msg.text === '') ||
        (hasMsg && msg.button_template)) {
        return MESSAGE_TYPES.CLIENT;
    }
    if (hasMsg && msg.generic_template) {
        return MESSAGE_TYPES.GENERIC;
    }
    if (hasMsg && msg.list_template) {
        return MESSAGE_TYPES.LIST;
    }
    if (hasMsg && msg.medium) {
        return MESSAGE_TYPES.MEDIA;
    }
    if (hasMsg && msg.line_flex_message) {
        return MESSAGE_TYPES.LINE_FLEX_MESSAGE;
    }
    return null;
}
export function latestMsgFromEventLogs(endUsers) {
    return endUsers.reduce(function (latestEventLog, endUser) {
        if (!latestEventLog.created_at && endUser.last_message)
            return endUser.last_message;
        if (endUser.last_message &&
            new Date(latestEventLog.created_at) <
                new Date(endUser.last_message.created_at)) {
            return endUser.last_message;
        }
        return latestEventLog;
    }, {});
}
export function contentFromMessage(message) {
    if (message.text) {
        return message.text;
    }
    if (message.generic_template) {
        return head(message.generic_template.generic_items).article.title;
    }
    if (message.medium) {
        return message.medium.url;
    }
    if (message.button_template) {
        return message.button_template.title;
    }
    return '';
}
