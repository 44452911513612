var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import React from 'react';
import { injectIntl } from 'react-intl';
import { Modal, Button } from '@zeals-co-ltd/washi-components';
import { valueTypeToString, valueMapValidation } from '@src/lib/formAutomation';
import { FormAutomationStepValueType, } from 'zeals-protobuf/zeals/web/entities/form_automation_pb';
import { Formik } from 'formik';
import { createStyles, makeStyles } from '@mui/styles';
import styled from 'styled-components';
import { grey } from '@zeals-co-ltd/washi-styles/lib/colors';
import * as Yup from 'yup';
import FormAutomationFixedValueSection from './FormAutomationFixedValueSection';
import FormAutomationValueMap from './FormAutomationValueMap';
var useModalStyles = makeStyles(function () {
    return createStyles({
        root: {
            '& .WashiModalHeader-root': {
                padding: '0 0 24px',
                // TODO update when Washi modal is updated
                '& .MuiDialogTitle-root': {
                    color: "" + grey[900],
                    fontWeight: 700,
                    fontSize: '19px',
                    lineHeight: '24px',
                    letterSpacing: '0.15px',
                },
                '& button': {
                    display: 'none',
                },
            },
            '& .MuiDialog-paper': {
                minWidth: '800px',
                padding: '24px',
            },
            '& .MuiDialogContent-root': {
                padding: '0',
                overflow: 'visible',
            },
            '& .MuiDialogActions-root': {
                padding: '8px 0 0',
            },
        },
    });
});
var ButtonRow = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  justify-content: flex-end;\n  gap: 16px;\n"], ["\n  display: flex;\n  justify-content: flex-end;\n  gap: 16px;\n"])));
var Label = styled.label(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin-bottom: ", ";\n"], ["\n  margin-bottom: ", ";\n"])), function (props) { return (props.hasSubtitle ? '8px' : '16px'); });
var valueTypeItems = [];
Object.values(FormAutomationStepValueType).forEach(function (type) {
    if (type !==
        FormAutomationStepValueType.FORM_AUTOMATION_STEP_VALUE_TYPE_UNSPECIFIED) {
        var label = valueTypeToString(type);
        valueTypeItems.push({
            label: label,
            value: type,
        });
    }
});
var FormAutomationCheckboxInputActionModal = function (_a) {
    var initialCheckboxInputStep = _a.initialCheckboxInputStep, field = _a.field, intl = _a.intl, onCancel = _a.onCancel, onChange = _a.onChange;
    var modalStyles = useModalStyles();
    var initialFormikState = {
        valueType: initialCheckboxInputStep === null || initialCheckboxInputStep === void 0 ? void 0 : initialCheckboxInputStep.valueType,
        // TODO: Uncomment when supported
        // htmlAttr: initialCheckboxInputStep?.htmlAttr,
        fixedValue: initialCheckboxInputStep === null || initialCheckboxInputStep === void 0 ? void 0 : initialCheckboxInputStep.fixedValue,
        valueMap: initialCheckboxInputStep === null || initialCheckboxInputStep === void 0 ? void 0 : initialCheckboxInputStep.valueMapMap,
    };
    var schema = Yup.object({
        fixedValue: Yup.string().test('FixedValue', 
        // eslint-disable-next-line no-template-curly-in-string
        '${path} is not valid', function (value, context) {
            switch (context.parent.valueType) {
                case FormAutomationStepValueType.FORM_AUTOMATION_STEP_VALUE_TYPE_FIELD:
                    return true;
                case FormAutomationStepValueType.FORM_AUTOMATION_STEP_VALUE_TYPE_FIXED:
                    return Boolean(value);
                default:
                    return true;
            }
        }),
        valueMap: Yup.array().test('ValueMap', 
        // eslint-disable-next-line no-template-curly-in-string
        '${path} is not valid', function (valueMap, context) { return valueMapValidation(valueMap, context, field); }),
    });
    function updateMap(setFieldValue, valueMap, i, entry) {
        setFieldValue('valueMap', valueMap.map(function (originalEntry, index) {
            return index === i ? entry : originalEntry;
        }));
    }
    function addEntryToMap(setFieldValue, valueMap) {
        setFieldValue('valueMap', __spreadArray(__spreadArray([], __read(valueMap)), [['', '']]));
    }
    function removeEntryFromMap(setFieldValue, valueMap, i) {
        setFieldValue('valueMap', valueMap.filter(function (_, index) { return i !== index; }));
    }
    // eslint-disable-next-line react/no-unstable-nested-components
    var renderButtonSet = function (formikValues, errors) {
        return (React.createElement(ButtonRow, null,
            React.createElement(Button, { variant: "text", onClick: onCancel, "data-testid": "TextFormElementSettingsModal_CancelButton" }, intl.formatMessage({ id: "J7fd3T", defaultMessage: "\u30AD\u30E3\u30F3\u30BB\u30EB" })),
            React.createElement(Button, { color: "primary", variant: "contained", onClick: function () {
                    onChange(__assign(__assign({}, initialCheckboxInputStep), { valueType: formikValues.valueType, valueMapMap: formikValues.valueMap, fixedValue: formikValues.fixedValue }));
                    onCancel();
                }, "data-testid": "TextFormElementSettingsModal_ConfirmButton", disabled: !!errors.valueMap }, intl.formatMessage({ id: "Z+5fuZ", defaultMessage: "\u8A2D\u5B9A\u3059\u308B" }))));
    };
    return (React.createElement(Formik, { initialValues: initialFormikState, validationSchema: schema, onSubmit: function () { return undefined; } }, function (_a) {
        var values = _a.values, errors = _a.errors, isValid = _a.isValid, setFieldValue = _a.setFieldValue;
        return (React.createElement(Modal, { open: true, onClose: onCancel, actions: renderButtonSet(values, errors), title: intl.formatMessage({ id: "lfELhr", defaultMessage: "\u30C1\u30A7\u30C3\u30AF\u30DC\u30C3\u30AF\u30B9\u5165\u529B ACTION" }), className: modalStyles.root },
            values.valueType ===
                FormAutomationStepValueType.FORM_AUTOMATION_STEP_VALUE_TYPE_FIELD && (React.createElement(FormAutomationValueMap, { field: field, valueMap: values.valueMap, isValid: isValid, onAddMapEntry: function () {
                    return addEntryToMap(setFieldValue, values.valueMap);
                }, onRemoveMapEntry: function (index) {
                    return removeEntryFromMap(setFieldValue, values.valueMap, index);
                }, onUpdateMapEntry: function (index, entry) {
                    return updateMap(setFieldValue, values.valueMap, index, entry);
                }, error: errors.valueMap })),
            values.valueType ===
                FormAutomationStepValueType.FORM_AUTOMATION_STEP_VALUE_TYPE_FIXED && (React.createElement(FormAutomationFixedValueSection, { value: values.fixedValue, onChange: function (fixedValue) {
                    return setFieldValue('fixedValue', fixedValue);
                }, error: errors.fixedValue }))));
    }));
};
export default injectIntl(FormAutomationCheckboxInputActionModal);
var templateObject_1, templateObject_2;
