var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { ChatbotListType, } from '../types/chatbotList';
import endpoints from '@src/endpoints';
import { makeFetchInit, handleAPIErrors } from '@src/lib/helpers';
import { handleChatbotUpdate } from '@src/redux/fanp/actions/settings';
import * as chatbotApi from '@src/services/api/chatbot';
// Actions
export var toggleFetchingChatbot = function () { return ({
    type: ChatbotListType.TOGGLE_FETCHING_CHATBOT,
}); };
export var setChatbotList = function (chatbots) { return ({
    type: ChatbotListType.SET_CHATBOT_LIST,
    chatbots: chatbots,
}); };
export var setChatbotItem = function (chatbot) { return ({
    type: ChatbotListType.SET_CHATBOT_ITEM,
    chatbot: chatbot,
}); };
export var setMaxChatbotCount = function (maxCount) { return ({
    type: ChatbotListType.SET_MAX_CHATBOT_COUNT,
    maxCount: maxCount,
}); };
export var setChatbotStatistics = function (chatbotStatistics) { return ({
    type: ChatbotListType.SET_CHATBOT_STATISTICS,
    chatbotStatistics: chatbotStatistics,
}); };
export var deleteChatbot = function (chatbotId) { return ({
    type: ChatbotListType.DELETE_CHATBOT,
    chatbotId: chatbotId,
}); };
// Thunk
export var fetchChatbots = function () {
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        var _a, chatbots, max_chatbot_count, chatbot_statistics, e_1;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    dispatch(toggleFetchingChatbot());
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 3, 4, 5]);
                    return [4 /*yield*/, chatbotApi.getChatbot()];
                case 2:
                    _a = _b.sent(), chatbots = _a.chatbots, max_chatbot_count = _a.max_chatbot_count, chatbot_statistics = _a.chatbot_statistics;
                    dispatch(setChatbotList(chatbots));
                    dispatch(setMaxChatbotCount(max_chatbot_count));
                    dispatch(setChatbotStatistics(chatbot_statistics));
                    return [3 /*break*/, 5];
                case 3:
                    e_1 = _b.sent();
                    handleAPIErrors('cannot get chatbots');
                    return [3 /*break*/, 5];
                case 4:
                    dispatch(toggleFetchingChatbot());
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/];
            }
        });
    }); };
};
export var fetchUpdateChatbot = function (chatbotId, update) {
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        var res, _a, chatbot, status, message;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, fetch(endpoints.chatbots.member(chatbotId), makeFetchInit('PUT', update))];
                case 1:
                    res = _b.sent();
                    return [4 /*yield*/, res.json()];
                case 2:
                    _a = _b.sent(), chatbot = _a.chatbot, status = _a.status, message = _a.message;
                    if (status === 422)
                        throw new Error(message);
                    dispatch(setChatbotItem(chatbot));
                    return [2 /*return*/];
            }
        });
    }); };
};
export var addNewChatbot = function (platformType) {
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        var platformDict, chatbot, e_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    platformDict = {
                        facebook: 'pagelist',
                        line: 'pagelist_line',
                        webchat: 'pagelist_webchat',
                        instagram: 'instagram_page_lists',
                    };
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, chatbotApi.createChatbot()];
                case 2:
                    chatbot = _a.sent();
                    dispatch(handleChatbotUpdate(chatbot));
                    if (window.globalRouterHistory) {
                        window.globalRouterHistory.push("/dashboard/accounts/chatbots/" + chatbot.id + "/" + platformDict[platformType]);
                    }
                    return [3 /*break*/, 4];
                case 3:
                    e_2 = _a.sent();
                    handleAPIErrors(e_2.message);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
};
// CURRENTLY NOT BEING USED
export var fetchDeleteChatbot = function (chatbotId) {
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        var e_3;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, chatbotApi.deleteChatbot(chatbotId)];
                case 1:
                    _a.sent();
                    dispatch(deleteChatbot(chatbotId));
                    return [3 /*break*/, 3];
                case 2:
                    e_3 = _a.sent();
                    handleAPIErrors('チャットボットを削除できませんでした。', e_3);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
};
