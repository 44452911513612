export var chatTypes = {
    INITIAL: 'initial',
    FORM: 'form',
    PATTERN: 'pattern',
    STEP: 'step',
    RETENTION: 'retention',
    TRACK: 'track',
    SHOT: 'shot',
    PLAIN: 'plain',
};
export var chatTypesTranslated = {
    INITIAL: {
        en: 'initial',
        jp: '初期会話',
    },
    FORM: {
        en: 'form',
        jp: 'フォーム',
    },
    PATTERN: {
        en: 'pattern',
        jp: 'パータン',
    },
    STEP: {
        en: 'step',
        jp: 'ステップ',
    },
    RETENTION: {
        en: 'track_push',
        jp: 'トラックプッシュ',
    },
    TRACK: {
        en: 'new_track_push',
        jp: '新トラックプッシュ',
    },
    SHOT: {
        en: 'shot',
        jp: 'ショット',
    },
    PLAIN: {
        en: 'plain',
        jp: 'プレイン',
    },
};
export var retentionTabs = {
    target: {
        url: function (_a) {
            var chatbotId = _a.chatbotId, targetId = _a.targetId;
            return targetId
                ? "/dashboard/ad/chatbots/" + chatbotId + "/retention/target/" + targetId
                : "/dashboard/ad/chatbots/" + chatbotId + "/retention/target";
        },
    },
    campaign: {
        url: function (_a) {
            var chatbotId = _a.chatbotId, targetId = _a.targetId, campaignId = _a.campaignId;
            return targetId
                ? "/dashboard/ad/chatbots/" + chatbotId + "/retention/target/" + targetId + "/campaign/" + (campaignId || '')
                : '';
        },
    },
    content: {
        url: function (_a) {
            var chatbotId = _a.chatbotId, targetId = _a.targetId, campaignId = _a.campaignId, contentId = _a.contentId;
            return campaignId
                ? "/dashboard/ad/chatbots/" + chatbotId + "/retention/target/" + targetId + "/campaign/" + campaignId + "/content/" + (contentId || '')
                : '';
        },
    },
};
export default ['initial', 'form', 'pattern', 'step', 'shot'];
