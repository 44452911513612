import { object, string, array, boolean, number, ref } from 'yup';
import { messageTypes } from '@src/lib/app-constants';
import { addMaxLength } from '@src/lib/helpers/formSchema';
export default (function (intl, platform, firstRichMenuId, usedLabels, schedules) {
    var defaultRichMenuItemSchema = object({
        id: number(),
        image_url: string().required(),
        label: string().when('id', {
            is: firstRichMenuId,
            then: function () {
                return addMaxLength(string()
                    .required(intl.formatMessage({ id: "QmVHJ0", defaultMessage: "RichMenuLabel required" }))
                    .notOneOf(usedLabels, intl.formatMessage({ id: "5yaExT", defaultMessage: "Name is already in use" })), intl, messageTypes.RICH_MENU + ".label", platform);
            },
        }),
        chat_bar_text: string().when('id', {
            is: firstRichMenuId,
            then: function () {
                return addMaxLength(string().required(intl.formatMessage({ id: "8jeME7", defaultMessage: "Chatbar Text required" })), intl, messageTypes.RICH_MENU + ".chatBarText", platform);
            },
        }),
        rich_menu_areas: array().of(object({
            isTabArea: boolean(),
            postback_action: object().when('isTabArea', {
                is: false,
                then: object({
                    block_id: number().nullable(),
                    scenario_id: number().nullable(),
                    url: string().nullable(),
                    effect: string()
                        .nullable()
                        .when(['block_id', 'scenario_id', 'url'], {
                        is: function (block_id, scenario_id, url) {
                            return Boolean(block_id || scenario_id || url);
                        },
                        then: string().nullable(),
                        otherwise: string().required(),
                    }),
                }),
            }),
        })),
    });
    return object({
        schedule: object({
            start_time: checkOverlapSchedule(number()
                .typeError(intl.formatMessage({ id: "KutgyP", defaultMessage: "Some required settings have not been completed." }))
                .required(intl.formatMessage({ id: "KutgyP", defaultMessage: "Some required settings have not been completed." }))
                .lessThan(ref('end_time'), function (params) {
                return params.less
                    ? intl.formatMessage({ id: "Jw/qzl", defaultMessage: "The From time is higher than the To time." })
                    : intl.formatMessage({ id: "KutgyP", defaultMessage: "Some required settings have not been completed." });
            })
                .moreThan(new Date().getTime(), intl.formatMessage({ id: "vQKU4X", defaultMessage: "It is not possible to select Date or Time in the past." })), schedules, intl),
            end_time: number()
                .typeError(intl.formatMessage({ id: "KutgyP", defaultMessage: "Some required settings have not been completed." }))
                .required(intl.formatMessage({ id: "KutgyP", defaultMessage: "Some required settings have not been completed." }))
                .moreThan(ref('start_time'), intl.formatMessage({ id: "Jw/qzl", defaultMessage: "The From time is higher than the To time." })),
        }).nullable(),
        richMenus: array().of(defaultRichMenuItemSchema),
    });
});
var checkOverlapSchedule = function (schema, schedules, intl) {
    if (!schedules.length)
        return schema;
    return schema.test('checkOverlapSchedule', intl.formatMessage({ id: "0JxUZ5", defaultMessage: "This time range has been already selected by another Rich Menu Default." }), function (value, context) {
        return schedules.every(function (schedule) {
            return value > schedule.end_time ||
                context.parent.end_time < schedule.start_time;
        });
    });
};
