var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { injectIntl } from 'react-intl';
import { Tooltip, Autocomplete, Icon } from '@zeals-co-ltd/washi-components';
import { useStepItemAutocompleteStyles } from '@src/components/molecules/FormAutomationStepItems/FormAutomationStepItem.styles';
import { useWebformFieldAutocompleteStyles } from '@src/lib/webformFieldItemStyles';
export var FormAutomationStepFieldSelector = injectIntl(function (_a) {
    var _b = _a.fields, fields = _b === void 0 ? [] : _b, _c = _a.fieldId, fieldId = _c === void 0 ? '' : _c, _d = _a.onChange, onChange = _d === void 0 ? function () { return undefined; } : _d, intl = _a.intl, isReadOnly = _a.isReadOnly;
    var autocompleteStyles = useWebformFieldAutocompleteStyles();
    var stepItemDropdownStyles = useStepItemAutocompleteStyles();
    var items = fields.map(function (f) { return ({
        value: f.id,
        label: f.name,
    }); });
    var selectedField = items === null || items === void 0 ? void 0 : items.find(function (field) { return field.value === fieldId; });
    return (React.createElement(Autocomplete, { isOptionEqualToValue: function (option, value) {
            return option.value === value.value;
        }, "data-testid": "FormAutomationStepItem-FieldSelectionDropdown", onChange: function (e, value) { return onChange(value); }, className: autocompleteStyles.root + " " + stepItemDropdownStyles.root, options: items, value: selectedField || '', getOptionLabel: function (dropdownOption) {
            return dropdownOption.label || '';
        }, size: "medium", textFieldProps: {
            placeholder: "" + intl.formatMessage({ id: "f2pRcj", defaultMessage: "Please select a field" }),
        }, readOnly: isReadOnly, popupIcon: React.createElement(Icon, { name: "adicon_down_chev" }), disableClearable: true, classes: { paper: autocompleteStyles.paper }, renderOption: function (props, option) { return (React.createElement("li", __assign({}, props),
            React.createElement(Tooltip, { arrow: true, placement: "right", title: option.label },
                React.createElement("span", null, option.label)))); } }));
});
