var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, lazy, Suspense } from 'react';
import { useLocation, useHistory, Route, Switch, Redirect, } from 'react-router-dom';
import { LoadingScreen, NotFound } from '@src/components/atoms';
import { useSelector } from 'react-redux';
import moment from 'moment';
import featureFlags from '@src/lib/feature_flags/featureFlags';
import * as pathLinks from './pathLinks';
var RetentionCampaign = lazy(function () { return import('@src/components/organisms/fanp/RetentionCampaign'); });
var RetentionContent = lazy(function () { return import('@src/components/organisms/fanp/RetentionContent'); });
var RetentionTarget = lazy(function () { return import('@src/components/organisms/fanp/RetentionTarget'); });
var ScenarioWindow = lazy(function () { return import('@src/components/organisms/fanp/ScenarioWindow'); });
var SettingsAggregate = lazy(function () { return import('@src/components/organisms/fanp/SettingsAggregate'); });
var SettingsGeneral = lazy(function () { return import('@src/components/organisms/fanp/SettingsGeneral'); });
var SettingsDefaultRichMenu = lazy(function () { return import('@src/components/organisms/fanp/SettingsDefaultRichMenu'); });
var SettingsLinePageList = lazy(function () { return import('@src/components/organisms/fanp/SettingsLinePageList'); });
var SettingsInstagramPageList = lazy(function () { return import('@src/components/organisms/fanp/SettingsInstagramPageList'); });
var SettingsPageList = lazy(function () { return import('@src/components/organisms/fanp/SettingsPageList'); });
var SettingsPrivacyPolicies = lazy(function () { return import('@src/components/organisms/fanp/SettingsPrivacyPolicies'); });
var SettingsPlugin = lazy(function () { return import('@src/components/organisms/fanp/SettingsPlugin'); });
var SettingsWebchat = lazy(function () { return import('@src/components/organisms/fanp/SettingsWebchat'); });
var SettingsWebchatPageList = lazy(function () { return import('@src/components/organisms/fanp/SettingsWebchatPageList'); });
var Target = lazy(function () { return import('@src/components/organisms/fanp/Target'); });
var TrackPushIndex = lazy(function () { return import('@src/components/organisms/fanp/TrackPushIndex'); });
var PluginNew = lazy(function () { return import('@src/components/organisms/fanp/plugins/PluginNew'); });
var PluginEventListContainer = lazy(function () { return import('@src/components/organisms/fanp/PluginEventListContainer'); });
var PluginEventIndex = lazy(function () { return import('@src/components/organisms/fanp/plugins/PluginEventIndex'); });
var PluginEventInfo = lazy(function () { return import('@src/components/organisms/fanp/plugins/PluginEventInfo'); });
var ChatbotList = lazy(function () { return import('@src/components/pages/fanp/ChatbotList'); });
var Account = lazy(function () { return import('@src/components/pages/fanp/Account'); });
var Members = lazy(function () { return import('@src/components/pages/fanp/Members'); });
var AccountMain = lazy(function () { return import('@src/components/pages/fanp/AccountMain'); });
var AccountNew = lazy(function () { return import('@src/components/pages/fanp/AccountNew'); });
var Chatlinks = lazy(function () { return import('@src/components/pages/fanp/Chatlinks'); });
var ChatlinkTable = lazy(function () { return import('@src/components/pages/fanp/ChatlinkTable'); });
var RetentionDelivery = lazy(function () { return import('@src/components/pages/fanp/RetentionDelivery'); });
var SettingsMain = lazy(function () { return import('@src/components/pages/fanp/SettingsMain'); });
var StepScenarioList = lazy(function () { return import('@src/components/pages/fanp/StepScenarioList'); });
var TalkCustomize = lazy(function () { return import('@src/components/pages/fanp/TalkCustomize'); });
var TalkRoom = lazy(function () { return import('@src/components/pages/fanp/TalkRoom'); });
var TrackPushSetting = lazy(function () { return import('@src/components/pages/fanp/TrackPushSetting'); });
var TrackPushTable = lazy(function () { return import('@src/components/pages/fanp/TrackPushTable'); });
var UserList = lazy(function () { return import('@src/components/pages/fanp/UserList'); });
var PluginList = lazy(function () { return import('@src/components/pages/fanp/PluginList'); });
var PluginInfo = lazy(function () { return import('@src/components/pages/fanp/PluginInfo'); });
var PluginModals = lazy(function () { return import('@src/components/pages/fanp/PluginModals'); });
var PluginBanners = lazy(function () { return import('@src/components/pages/fanp/PluginBanners'); });
var AccountPluginModalTable = lazy(function () { return import('@src/components/pages/fanp/AccountPluginModalTable'); });
var AccountPluginBannerTable = lazy(function () { return import('@src/components/pages/fanp/AccountPluginBannerTable'); });
var PluginModalContent = lazy(function () { return import('@src/components/pages/fanp/PluginModalContent'); });
var PluginBannerContent = lazy(function () { return import('@src/components/pages/fanp/PluginBannerContent'); });
var Login = lazy(function () { return import('@src/components/pages/fanp/Login'); });
var LoginSignup = lazy(function () { return import('@src/components/pages/fanp/LoginSignup'); });
var LoginResetPassword = lazy(function () { return import('@src/components/pages/fanp/LoginResetPassword'); });
var ChatlinkContent = lazy(function () { return import('@src/components/pages/fanp/ChatlinkContent'); });
var RpaSettingsList = lazy(function () { return import('@src/components/pages/fanp/FormBuildsList'); });
var FormBuilder = lazy(function () { return import('@src/components/pages/fanp/FormBuilder'); });
var FormConversationScenarioSelection = lazy(function () { return import('@src/components/pages/fanp/FormConversationScenarioSelection'); });
var FormConversationFormQuestionsSetup = lazy(function () { return import('@src/components/pages/fanp/FormConversationFormQuestionsSetup'); });
var WebformSettingPage = lazy(function () { return import('@src/components/pages/fanp/WebformSettingPage'); });
var FormAutomationPage = lazy(function () { return import('@src/components/pages/fanp/FormAutomationPage'); });
var FormBuilderFormSettings = lazy(function () { return import('@src/components/organisms/fanp/FormBuilderFormSettings'); });
var FormBuilderPostLogs = lazy(function () { return import('@src/components/organisms/fanp/FormBuilderPostLogs'); });
var FieldAttributes = lazy(function () { return import('@src/components/pages/fanp/FieldAttributes'); });
var UserSegment = lazy(function () { return import('@src/components/pages/fanp/UserSegment'); });
var UserLabels = lazy(function () { return import('@src/components/pages/fanp/UserLabels'); });
var AdRoutes = function (props) {
    var authenticateUser = props.authenticateUser;
    var location = useLocation();
    var history = useHistory();
    var language = useSelector(function (state) { return state.account.language; });
    useEffect(function () {
        authenticateUser(location, history);
        moment.locale(language);
    }, []);
    return (React.createElement(TalkCustomize, null,
        React.createElement(Switch, null,
            React.createElement(Route, { path: pathLinks.dashboardAdChatbots_chatbotId_TalkEnd_Users_endUserId_(), component: TalkRoom }),
            React.createElement(Route, { path: pathLinks.dashboardAdChatbots_chatbotId_TalkEnd_Users(), component: TalkRoom }),
            React.createElement(Route, { path: pathLinks.dashboardAdChatbots_chatbotId_Talk(), component: TalkRoom }),
            React.createElement(Route, { path: pathLinks.dashboardAdChatbots_chatbotId_Userdb_page__(), component: function () { return React.createElement(UserList, null); } }),
            featureFlags.UUD_FIELD_ATTRIBUTES && (React.createElement(Route, { path: pathLinks.dashboardAdChatbots_chatbotId_FieldAttributes(), component: function () { return React.createElement(FieldAttributes, null); } })),
            featureFlags.UUD_USER_SEGMENT && (React.createElement(Route, { path: pathLinks.dashboardAdChatbots_chatbotId_UserSegment(), component: function () { return React.createElement(UserSegment, null); } })),
            featureFlags.UUD_USER_LABEL && (React.createElement(Route, { path: pathLinks.dashboardAdChatbots_chatbotId_UserLabel(), component: function () { return React.createElement(UserLabels, null); } })),
            React.createElement(Route, { path: pathLinks.dashboardAdChatbots_chatbotId_Chatlinks_chatlinkId_() },
                React.createElement(Chatlinks, null,
                    React.createElement(ChatlinkContent, null))),
            React.createElement(Route, { path: pathLinks.dashboardAdChatbots_chatbotId_Chatlinks() },
                React.createElement(Chatlinks, null,
                    React.createElement(ChatlinkTable, null))),
            React.createElement(Route, { path: pathLinks.dashboardAdChatbots_chatbotId_SettingsPrivacPolicies() },
                React.createElement(SettingsMain, null,
                    React.createElement(SettingsPrivacyPolicies, null))),
            React.createElement(Route, { path: pathLinks.dashboardAdChatbots_chatbotId_SettingsPlugin() },
                React.createElement(SettingsMain, null,
                    React.createElement(SettingsPlugin, null))),
            React.createElement(Route, { path: pathLinks.dashboardAdChatbots_chatbotId_SettingsAggregate() },
                React.createElement(SettingsMain, null,
                    React.createElement(SettingsAggregate, null))),
            React.createElement(Route, { path: pathLinks.dashboardAdChatbots_chatbotId_SettingsWebchatSetting() },
                React.createElement(SettingsMain, null,
                    React.createElement(SettingsWebchat, null))),
            React.createElement(Route, { path: pathLinks.dashboardAdChatbots_chatbotId_SettingsDefaultRichMenu() },
                React.createElement(SettingsDefaultRichMenu, null)),
            React.createElement(Route, { path: pathLinks.dashboardAdChatbots_chatbotId_Settings() },
                React.createElement(SettingsMain, null,
                    React.createElement(SettingsGeneral, null))),
            featureFlags.TRACK_PUSH_V1_SHOWN && (React.createElement(Route, { path: pathLinks.dashboardAdChatbots_chatbotId_RetentionTarget_targetId_Campaign_campaignId_Content_contentId__() },
                React.createElement(RetentionDelivery, null,
                    React.createElement(RetentionContent, null)))),
            featureFlags.TRACK_PUSH_V1_SHOWN && (React.createElement(Route, { path: pathLinks.dashboardAdChatbots_chatbotId_RetentionTarget_targetId_Campaign_campaignId__() },
                React.createElement(RetentionDelivery, null,
                    React.createElement(RetentionCampaign, null)))),
            featureFlags.TRACK_PUSH_V1_SHOWN && (React.createElement(Route, { path: pathLinks.dashboardAdChatbots_chatbotId_RetentionTarget_targetId__() },
                React.createElement(RetentionDelivery, null,
                    React.createElement(RetentionTarget, null)))),
            featureFlags.TRACK_PUSH_V1_SHOWN && (React.createElement(Route, { path: pathLinks.dashboardAdChatRetention },
                React.createElement(RetentionDelivery, null,
                    React.createElement(RetentionTarget, null)))),
            featureFlags.TRACK_PUSH_V1_SHOWN && (React.createElement(Route, { path: pathLinks.dashboardAdChatbots_chatbotId__chatTypeParam_Chatbots_chatbotId__List(), component: StepScenarioList })),
            featureFlags.TRACK_PUSH_V2_SHOWN && (React.createElement(Route, { path: pathLinks.dashboardAdChatbots_chatbotId_TracksNew(), component: TrackPushSetting })),
            featureFlags.TRACK_PUSH_V2_SHOWN && (React.createElement(Route, { path: pathLinks.dashboardAdChatbots_chatbotId_TracksTargets_targetId__(), component: function (props) {
                    return (React.createElement(TrackPushTable, __assign({}, props),
                        React.createElement(Target, null)));
                } })),
            featureFlags.TRACK_PUSH_V2_SHOWN && (React.createElement(Route, { path: pathLinks.dashboardAdChatbots_chatbotId_Tracks(), component: function (props) {
                    return (React.createElement(TrackPushTable, __assign({}, props),
                        React.createElement(TrackPushIndex, null)));
                } })),
            featureFlags.RPA_V1 && (React.createElement(Route, { path: pathLinks.dashboardAdChatbots_chatbotId_RpaSettings(), component: RpaSettingsList })),
            featureFlags.RPA_FORM_BUILDER && (React.createElement(Route, { exact: true, path: pathLinks.dashboardAdChatbots_chatbotId_FormBuilder_formBuildId(), render: function (_a) {
                    var params = _a.match.params;
                    return (React.createElement(Redirect, { to: pathLinks.dashboardAdChatbots_chatbotId_FormBuilder_formBuildId_FormSettings(params) }));
                } })),
            featureFlags.RPA_FORM_BUILDER && (React.createElement(Route, { exact: true, path: pathLinks.dashboardAdChatbots_chatbotId_FormBuilder_formBuildId_FormSettings(), component: function () { return (React.createElement(FormBuilder, null,
                    React.createElement(FormBuilderFormSettings, null))); } })),
            featureFlags.RPA_FORM_BUILDER && (React.createElement(Route, { path: pathLinks.dashboardAdChatbots_chatbotId_FormBuilder_formBuildId_PostLogs(), component: function () { return (React.createElement(FormBuilder, null,
                    React.createElement(FormBuilderPostLogs, null))); } })),
            featureFlags.RPA_FORM_BUILDER && (React.createElement(Route, { exact: true, path: pathLinks.dashboardAdChatbots_chatbotId_FormBuilder_formBuildId_FormConversation_Select(), component: FormConversationScenarioSelection })),
            featureFlags.RPA_FORM_BUILDER && (React.createElement(Route, { exact: true, path: [
                    pathLinks.dashboardAdChatbots_chatbotId_FormBuilder_formBuildId_FormConversation_Create_scenarioId_(),
                    pathLinks.dashboardAdChatbots_chatbotId_FormBuilder_formBuildId_FormConversation_Edit_formScenarioId_(),
                ], component: FormConversationFormQuestionsSetup })),
            featureFlags.RPA_V1 && (React.createElement(Route, { path: pathLinks.dashboardAdChatbots_chatbotId_FormBuild_formBuildId_WebformSetting_formId_(), component: WebformSettingPage })),
            featureFlags.RPA_V1 && (React.createElement(Route, { path: pathLinks.dashboardAdChatbots_chatbotId_FormBuilder_formBuildId_FormAutomation(), component: FormAutomationPage })),
            React.createElement(Route, { path: pathLinks.dashboardAdChatbots_chatbotId_all(), component: NotFound }),
            React.createElement(Route, { path: pathLinks.dashboardAd_chatTypeParam_Chatbots_chatbotId_Scenarios_scenarioId_Layers_layerId_Blocks_blockId_Rank_rank_(), component: ScenarioWindow }),
            React.createElement(Route, { path: pathLinks.dashboardAd_chatTypeParam_Chatbots_chatbotId_Scenarios_scenarioId_Layers_layerId_Blocks_blockId_(), component: ScenarioWindow }),
            React.createElement(Route, { path: pathLinks.dashboardAd_chatTypeParam_Chatbots_chatbotId_Scenarios_scenarioId_Layers_layerId_(), component: ScenarioWindow }),
            React.createElement(Route, { path: pathLinks.dashboardAd_chatTypeParam_Chatbots_chatbotId_Scenarios_scenarioId_(), component: ScenarioWindow }),
            React.createElement(Route, { path: pathLinks.dashboardAd_chatTypeParam_Chatbots_chatbotId_(), component: ScenarioWindow }))));
};
var AccountsRoute = function (props) {
    var updateLoggedIn = props.updateLoggedIn, authenticateUser = props.authenticateUser;
    var location = useLocation();
    var history = useHistory();
    // /dashboard/accounts
    useEffect(function () {
        authenticateUser(location, history);
    }, []);
    return (React.createElement(Suspense, { fallback: React.createElement("div", null) },
        React.createElement(AccountMain, __assign({ updateLoggedIn: updateLoggedIn }, props),
            React.createElement(Switch, null,
                React.createElement(Route, { path: pathLinks.dashboardAccountsChatbots_chatbotId_PagelistLine(), component: SettingsLinePageList }),
                React.createElement(Route, { path: pathLinks.dashboardAccountsChatbots_chatbotId_PagelistInstagram(), component: SettingsInstagramPageList }),
                React.createElement(Route, { path: pathLinks.dashboardAccountsChatbots_chatbotId_PagelistWebchat(), component: SettingsWebchatPageList }),
                React.createElement(Route, { path: pathLinks.dashboardAccountsChatbots_chatbotId_Pagelist(), component: SettingsPageList }),
                React.createElement(Route, { path: pathLinks.dashboardAccountsChatbots_chatbotId_Banners_bannerId_(), component: PluginBannerContent }),
                React.createElement(Route, { path: pathLinks.dashboardAccountsChatbots_chatbotId_Modals_modalId_(), component: PluginModalContent }),
                React.createElement(Route, { path: pathLinks.dashboardAccountsChatbots, component: ChatbotList }),
                React.createElement(Route, { path: pathLinks.dashboardAccountsModals_modalId_(), component: function () { return (React.createElement(PluginModals, null,
                        React.createElement(PluginModalContent, null))); } }),
                React.createElement(Route, { path: pathLinks.dashboardAccountsModals, component: function () { return (React.createElement(PluginModals, null,
                        React.createElement(AccountPluginModalTable, null))); } }),
                React.createElement(Route, { path: pathLinks.dashboardAccountsBanners_bannerId_() },
                    React.createElement(PluginBanners, null,
                        React.createElement(PluginBannerContent, null))),
                React.createElement(Route, { path: pathLinks.dashboardAccountsBanners },
                    React.createElement(PluginBanners, null,
                        React.createElement(AccountPluginBannerTable, null))),
                React.createElement(Route, { path: pathLinks.dashboardAccountsAccount, component: Account }),
                React.createElement(Route, { path: pathLinks.dashboardAccounts_accountId_PluginsNew(), component: PluginNew }),
                React.createElement(Route, { path: pathLinks.dashboardAccounts_accountId_Plugins_pluginId_Paths_pathId_Events_eventId_(), component: function (props) { return (React.createElement(PluginInfo, __assign({}, props),
                        React.createElement(PluginEventInfo, null))); } }),
                React.createElement(Route, { path: pathLinks.dashboardAccounts_accountId_Plugins_pluginId_Paths_pathId(), component: function (props) { return (React.createElement(PluginInfo, __assign({}, props),
                        React.createElement(PluginEventListContainer, null))); } }),
                React.createElement(Route, { path: pathLinks.dashboardAccounts_accountId_Plugins_pluginId_(), component: function (props) { return (React.createElement(PluginInfo, __assign({}, props),
                        React.createElement(PluginEventIndex, null))); } }),
                React.createElement(Route, { path: pathLinks.dashboardAccounts_accountId_Plugins(), component: PluginList }),
                React.createElement(Route, { path: pathLinks.dashboardAccounts_accountId_Members(), component: Members }),
                React.createElement(Route, { exact: true, path: pathLinks.dashboardAccounts },
                    React.createElement(Redirect, { to: pathLinks.dashboardAccountsAccount }))))));
};
var DashboardRoutes = function (props) {
    var authenticateUser = props.authenticateUser, checkLoggedIn = props.checkLoggedIn, updateLoggedIn = props.updateLoggedIn, bootstrapApp = props.bootstrapApp, isBootstrapping = props.isBootstrapping, setWebGrpcServiceEndpoints = props.setWebGrpcServiceEndpoints;
    var location = useLocation();
    var history = useHistory();
    useEffect(function () {
        bootstrapApp(location, history);
    }, []);
    // /dashboard
    return (React.createElement(Suspense, { fallback: React.createElement("div", null) },
        React.createElement(Switch, null,
            React.createElement(Route, { exact: true, path: pathLinks.dashboard },
                React.createElement(Redirect, { to: pathLinks.dashboardAccountsChatbots })),
            React.createElement(Route, { path: pathLinks.dashboardLogin, component: function () { return (React.createElement(Login, { setWebGrpcServiceEndpoints: setWebGrpcServiceEndpoints })); }, onEnter: checkLoggedIn }),
            React.createElement(Route, { path: pathLinks.dashboardSignup, component: LoginSignup }),
            React.createElement(Route, { path: pathLinks.dashboardResetPassword, component: LoginResetPassword }),
            React.createElement(Route, { path: pathLinks.dashboardNewAccount, component: AccountNew }),
            isBootstrapping ? (React.createElement(LoadingScreen, null)) : (React.createElement(Switch, null,
                React.createElement(Route, { exact: true, path: pathLinks.dashboardGroup },
                    React.createElement(Redirect, { to: pathLinks.dashboardAccountsChatbots })),
                React.createElement(Route, { path: pathLinks.dashboardAccounts },
                    React.createElement(AccountsRoute, { authenticateUser: authenticateUser, checkLoggedIn: checkLoggedIn, updateLoggedIn: updateLoggedIn })),
                React.createElement(Route, { path: pathLinks.dashboardAd },
                    React.createElement(AdRoutes, { authenticateUser: authenticateUser })))),
            React.createElement(Route, { path: "/*", component: NotFound }))));
};
/*
copy code here
*/
export default DashboardRoutes;
