import "core-js/modules/es.array.concat";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";

var collection = function collection(_ref) {
  var pluginId = _ref.pluginId;
  return "/integration_plugins/plugins/".concat(pluginId, "/paths");
};

var member = function member(_ref2) {
  var pathId = _ref2.pathId,
      rest = _objectWithoutProperties(_ref2, ["pathId"]);

  return "".concat(collection(rest), "/").concat(pathId);
};

export default {
  collection: collection,
  member: member
};