var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { Component, Fragment } from 'react';
import { isEqual } from 'lodash';
import { connect } from 'react-redux';
import styled from 'styled-components';
import moment from 'moment';
import { injectIntl } from 'react-intl';
import { colors } from '@src/colors';
import { centeredFlex } from '@src/lib/mixins';
import { SendType } from '@src/typings/enums';
import { chatTypes } from '@src/lib/chat-types';
import { SHOT_DELIVER_LIMIT } from '@src/lib/app-constants';
import { getInitTimeShotDelivery, handleAPIErrors } from '@src/lib/helpers';
import { ModalPortal, Spinner } from '@src/components/atoms';
import { Modal } from '@src/components/molecules';
import { UserFilter } from '@src/components/organisms/fanp';
import modalTypes from '@src/lib/modalTypes';
import platformLimitations from '@src/lib/platforms/limitations';
import { fetchQuickFilter, fetchAttributeQuestions, fetchFilterTags, fetchFilteredUsers, adHandleDeleteFilter, adCreateNewUserFilter, fetchUserFilter, getMessageUsageAndLimitNumber, } from '@src/redux/fanp/actions/userFilter';
import * as scenarioDeliveryApi from '@src/services/api/scenarioDelivery';
import { handleNewScenarioDelivery } from '@src/redux/fanp/actions/ad-scenario';
import { updateCampaignContentDelivery } from '@src/redux/fanp/actions/ad-scenario/editActions';
import TestingSelect from './TestingSelect';
import StepDeliveryWindow from './StepDeliveryWindow';
import ShotDeliveryWindow from './ShotDeliveryWindow';
import DeliveryConfirm from './DeliveryConfirm';
var FlexboxContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n"], ["\n  ", ";\n"])), centeredFlex);
var Container = styled(FlexboxContainer)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  flex-direction: column;\n\n  .filtered-users {\n    font-size: 2rem;\n    font-weight: bold;\n  }\n"], ["\n  flex-direction: column;\n\n  .filtered-users {\n    font-size: 2rem;\n    font-weight: bold;\n  }\n"])));
var FormContainer = styled(Container)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  width: 100%;\n  align-items: flex-start;\n\n  .adicon_down {\n    position: relative;\n    font-size: 24px;\n    color: ", ";\n  }\n"], ["\n  width: 100%;\n  align-items: flex-start;\n\n  .adicon_down {\n    position: relative;\n    font-size: 24px;\n    color: ", ";\n  }\n"])), colors.text_pale);
var SendDelivery = /** @class */ (function (_super) {
    __extends(SendDelivery, _super);
    function SendDelivery() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            userFilterModalOpen: false,
            overLimitConfirmModelOpen: false,
            scheduleDeliveryConfirmModalOpen: false,
            stepScheduleEditModalOpen: false,
            stepModalOpen: false,
            loading: true,
        };
        _this.fetchNecessaryData = function () { return __awaiter(_this, void 0, void 0, function () {
            var _a, getMessageUsageAndLimitNumber, chatbotIdParam, currentScenario, fetchUserFilter, fetchAttributeQuestions, fetchFilterTags, fetchQuickFilter, chatTypeParam, _b, chatbotPlatform, err_1;
            var _c;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        _a = this.props, getMessageUsageAndLimitNumber = _a.getMessageUsageAndLimitNumber, chatbotIdParam = _a.chatbotIdParam, currentScenario = _a.currentScenario, fetchUserFilter = _a.fetchUserFilter, fetchAttributeQuestions = _a.fetchAttributeQuestions, fetchFilterTags = _a.fetchFilterTags, fetchQuickFilter = _a.fetchQuickFilter, chatTypeParam = _a.chatTypeParam, _b = _a.chatbotPlatform, chatbotPlatform = _b === void 0 ? 'line' : _b;
                        _d.label = 1;
                    case 1:
                        _d.trys.push([1, 12, , 13]);
                        return [4 /*yield*/, this.fetchDeliveryContent()];
                    case 2:
                        _d.sent();
                        return [4 /*yield*/, this.fetchUsers()];
                    case 3:
                        _d.sent();
                        if (!(chatTypeParam === chatTypes.SHOT &&
                            ((_c = platformLimitations[chatbotPlatform]) === null || _c === void 0 ? void 0 : _c.CAN_REQUEST_QUOTA_API))) return [3 /*break*/, 5];
                        return [4 /*yield*/, getMessageUsageAndLimitNumber(chatbotIdParam)];
                    case 4:
                        _d.sent();
                        _d.label = 5;
                    case 5:
                        if (!(chatTypeParam === chatTypes.SHOT)) return [3 /*break*/, 8];
                        return [4 /*yield*/, fetchAttributeQuestions(chatbotIdParam)];
                    case 6:
                        _d.sent();
                        return [4 /*yield*/, fetchFilterTags(chatbotIdParam)];
                    case 7:
                        _d.sent();
                        _d.label = 8;
                    case 8:
                        if (!(currentScenario.filter_id && chatTypeParam === chatTypes.SHOT)) return [3 /*break*/, 11];
                        return [4 /*yield*/, fetchUserFilter({
                                chatbotId: chatbotIdParam,
                                filterId: currentScenario.filter_id,
                            })];
                    case 9:
                        _d.sent();
                        return [4 /*yield*/, fetchQuickFilter({ filterId: currentScenario.filter_id })];
                    case 10:
                        _d.sent();
                        _d.label = 11;
                    case 11:
                        this.setState({ loading: false });
                        return [3 /*break*/, 13];
                    case 12:
                        err_1 = _d.sent();
                        console.error(err_1);
                        alert('配信データ読み込めなかったです');
                        return [3 /*break*/, 13];
                    case 13: return [2 /*return*/];
                }
            });
        }); };
        _this.fetchUsers = function () {
            var _a = _this.props, chatbotIdParam = _a.chatbotIdParam, currentScenario = _a.currentScenario, fetchFilteredUsers = _a.fetchFilteredUsers;
            var filterId = currentScenario ? currentScenario.filter_id : null;
            return fetchFilteredUsers(chatbotIdParam, filterId);
        };
        _this.fetchDeliveryContent = function () {
            var chatTypeParam = _this.props.chatTypeParam;
            // Any particular processing for a certain chatType should be added here
            switch (chatTypeParam) {
                case chatTypes.STEP:
                    return _this.fetchStepDeliveryContent();
                default:
                    return Promise.resolve();
            }
        };
        _this.fetchStepDeliveryContent = function () {
            var _a = _this.props, adSetStepDaysAfter = _a.adSetStepDaysAfter, adSetStepDeliveryTime = _a.adSetStepDeliveryTime, currentScenario = _a.currentScenario;
            if (currentScenario && currentScenario.campaign_content) {
                var _b = currentScenario.campaign_content, delivery_time = _b.delivery_time, days_after = _b.days_after;
                var stepDeliveryTime = moment(delivery_time).format('HH:mm').split(':');
                var hour = stepDeliveryTime[0];
                var minute = stepDeliveryTime[1];
                adSetStepDaysAfter(days_after);
                adSetStepDeliveryTime('hour', parseInt(hour));
                adSetStepDeliveryTime('minute', parseInt(minute));
            }
            return Promise.resolve();
        };
        _this.toggleUserFilterModal = function () {
            return _this.setState({ userFilterModalOpen: !_this.state.userFilterModalOpen });
        };
        _this.toggleOverLimitConfirmModal = function () {
            return _this.setState({
                overLimitConfirmModelOpen: !_this.state.overLimitConfirmModelOpen,
            });
        };
        _this.toggleScheduleDeliveryConfirmModal = function () {
            return _this.setState({
                scheduleDeliveryConfirmModalOpen: !_this.state
                    .scheduleDeliveryConfirmModalOpen,
            });
        };
        _this.toggleStepScheduleEditModal = function () {
            return _this.setState({
                stepScheduleEditModalOpen: !_this.state.stepScheduleEditModalOpen,
            });
        };
        _this.toggleStepModal = function () {
            return _this.setState({ stepModalOpen: !_this.state.stepModalOpen });
        };
        _this.handleScenarioDeliveryConfirm = function () { return __awaiter(_this, void 0, void 0, function () {
            var _a, currentScenario, handleNewScenarioDelivery, chatbotIdParam, adScenarioIdParam, handleModal, scenario_delivery, scenarioDelivery, e_1;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this.props, currentScenario = _a.currentScenario, handleNewScenarioDelivery = _a.handleNewScenarioDelivery, chatbotIdParam = _a.chatbotIdParam, adScenarioIdParam = _a.adScenarioIdParam, handleModal = _a.handleModal;
                        scenario_delivery = currentScenario.scenario_delivery;
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, scenarioDeliveryApi.submitScenarioDelivery(chatbotIdParam, adScenarioIdParam, scenario_delivery.scheduled_at)];
                    case 2:
                        scenarioDelivery = _b.sent();
                        handleNewScenarioDelivery(adScenarioIdParam, scenarioDelivery);
                        return [3 /*break*/, 4];
                    case 3:
                        e_1 = _b.sent();
                        handleModal(e_1.message);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        }); };
        _this.handleUserFilterClick = function () {
            _this.toggleUserFilterModal();
        };
        _this.handleSelectChange = function (e) {
            var handleDeliveryOptionChange = _this.props.handleDeliveryOptionChange;
            handleDeliveryOptionChange(e.target.value);
        };
        _this.handleScheduledScenarioDelivery = function () {
            // initializes a scheduled_at prop for the scenario delivery object
            var _a = _this.props, chatbotIdParam = _a.chatbotIdParam, adScenarioIdParam = _a.adScenarioIdParam, createNewScenarioDelivery = _a.createNewScenarioDelivery, currentScenario = _a.currentScenario;
            var d;
            var scenarioDelivery = currentScenario && currentScenario.scenario_delivery;
            if (scenarioDelivery) {
                d = moment(scenarioDelivery.scheduled_at);
            }
            else {
                // Get nearest multiple of 3 minutes (up)
                d = getInitTimeShotDelivery();
            }
            createNewScenarioDelivery({
                chatbotId: chatbotIdParam,
                scenarioId: adScenarioIdParam,
                scheduledAt: d.format(),
            });
        };
        // TO DO FIX DISPLAY OF 流入中
        _this.handleStepDeliveryConfirm = function () {
            var _a = _this.props, updateCampaignContentDelivery = _a.updateCampaignContentDelivery, adScenario = _a.adScenario, chatbotIdParam = _a.chatbotIdParam, adScenarioIdParam = _a.adScenarioIdParam, currentScenario = _a.currentScenario;
            var step = adScenario.step;
            var daysAfter = step.daysAfter, deliveryTime = step.deliveryTime;
            var campaign_content = currentScenario.campaign_content, draft = currentScenario.draft;
            var hour = deliveryTime.hour, minute = deliveryTime.minute;
            if (hour < 10)
                hour = "0" + hour;
            if (minute < 10)
                minute = "0" + minute;
            var stepDeliveryTime = moment(hour + ":" + minute, 'HH:mm').format();
            if (daysAfter || hour || minute) {
                updateCampaignContentDelivery({
                    chatbotId: chatbotIdParam,
                    adScenarioId: adScenarioIdParam,
                    campaignId: campaign_content.campaign_id,
                    campaignContentsId: campaign_content.id,
                    campaignContent: {
                        daysAfter: daysAfter,
                        deliveryTime: stepDeliveryTime,
                    },
                    draft: !draft,
                });
            }
        };
        _this.updateTriggerTime = function (moment) {
            // on change react date-time calendar
            var _a = _this.props, adScenarioIdParam = _a.adScenarioIdParam, isValidTriggerTime = _a.isValidTriggerTime, adEditScheduledAt = _a.adEditScheduledAt;
            var isValid = false, time;
            if (typeof moment !== 'string') {
                isValid = _this.checkIfTriggerTimeIsValid(moment);
                time = moment.format('YYYY-MM-DD HH:mm');
            }
            isValidTriggerTime(adScenarioIdParam, isValid);
            isValid && adEditScheduledAt(adScenarioIdParam, time);
        };
        _this.handleScheduleDeliveryBtnClick = function () {
            // when user clicks on 配信 button
            var filteredUsers = _this.props.adUserFilter.filteredUsers;
            if (filteredUsers.length >= SHOT_DELIVER_LIMIT) {
                _this.toggleOverLimitConfirmModal();
            }
            else {
                _this.toggleScheduleDeliveryConfirmModal();
            }
        };
        _this.handleStepDateChange = function (e) {
            var _a = _this.props, adSetStepDaysAfter = _a.adSetStepDaysAfter, adSetStepDeliveryTime = _a.adSetStepDeliveryTime;
            var name = e.target.name;
            var value = e.target.value;
            if (name === 'daysAfter') {
                value === ''
                    ? adSetStepDaysAfter('')
                    : adSetStepDaysAfter(parseInt(value));
            }
            else {
                adSetStepDeliveryTime(name, parseInt(value));
            }
        };
        _this.handleStepScheduleDelivery = function () {
            var currentScenario = _this.props.currentScenario;
            if (currentScenario.scenario_delivery &&
                currentScenario.scenario_delivery.delivered) {
                alert('配信済みのシナリオは編集できません');
            }
            if (currentScenario.scenario_delivery) {
                _this.toggleStepScheduleEditModal();
            }
            _this.toggleStepModal();
        };
        _this.onFilterSelectChange = function (e) { return __awaiter(_this, void 0, void 0, function () {
            var _a, chatbotIdParam, currentScenario, adCreateNewUserFilter, adHandleDeleteFilter, fetchFilteredUsers, getMessageUsageAndLimitNumber, chatTypeParam, _b, chatbotPlatform, value;
            var _c, _d;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        _a = this.props, chatbotIdParam = _a.chatbotIdParam, currentScenario = _a.currentScenario, adCreateNewUserFilter = _a.adCreateNewUserFilter, adHandleDeleteFilter = _a.adHandleDeleteFilter, fetchFilteredUsers = _a.fetchFilteredUsers, getMessageUsageAndLimitNumber = _a.getMessageUsageAndLimitNumber, chatTypeParam = _a.chatTypeParam, _b = _a.chatbotPlatform, chatbotPlatform = _b === void 0 ? 'line' : _b;
                        value = e.target.value;
                        if (!(value === SendType.All)) return [3 /*break*/, 5];
                        return [4 /*yield*/, adHandleDeleteFilter({
                                chatbotId: chatbotIdParam,
                                scenarioId: currentScenario.id,
                                filterId: currentScenario.filter_id,
                            })];
                    case 1:
                        _e.sent();
                        return [4 /*yield*/, fetchFilteredUsers(chatbotIdParam, currentScenario.filter_id)];
                    case 2:
                        _e.sent();
                        if (!(chatTypeParam === chatTypes.SHOT &&
                            ((_c = platformLimitations[chatbotPlatform]) === null || _c === void 0 ? void 0 : _c.CAN_REQUEST_QUOTA_API))) return [3 /*break*/, 4];
                        return [4 /*yield*/, getMessageUsageAndLimitNumber(chatbotIdParam)];
                    case 3:
                        _e.sent();
                        _e.label = 4;
                    case 4: return [3 /*break*/, 9];
                    case 5: return [4 /*yield*/, adCreateNewUserFilter({
                            chatbotId: chatbotIdParam,
                            scenarioId: currentScenario.id,
                        })];
                    case 6:
                        _e.sent();
                        return [4 /*yield*/, fetchFilteredUsers(chatbotIdParam, currentScenario.filter_id)];
                    case 7:
                        _e.sent();
                        if (!(chatTypeParam === chatTypes.SHOT &&
                            ((_d = platformLimitations[chatbotPlatform]) === null || _d === void 0 ? void 0 : _d.CAN_REQUEST_QUOTA_API))) return [3 /*break*/, 9];
                        return [4 /*yield*/, getMessageUsageAndLimitNumber(chatbotIdParam)];
                    case 8:
                        _e.sent();
                        _e.label = 9;
                    case 9: return [2 /*return*/];
                }
            });
        }); };
        _this.handleTestingChange = function (SelectValue) { return __awaiter(_this, void 0, void 0, function () {
            var _a, chatbotIdParam, adScenarioIdParam, adSetScenarioTesting, testing, e_2;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this.props, chatbotIdParam = _a.chatbotIdParam, adScenarioIdParam = _a.adScenarioIdParam, adSetScenarioTesting = _a.adSetScenarioTesting;
                        testing = Boolean(SelectValue === 'true');
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, adSetScenarioTesting(chatbotIdParam, adScenarioIdParam, testing)];
                    case 2:
                        _b.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        e_2 = _b.sent();
                        handleAPIErrors(e_2.message);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        }); };
        _this.renderMessageUsageData = function (messageLimit, messageUsage) {
            var intl = _this.props.intl;
            return (React.createElement("p", { className: "delivery-limit" }, intl.formatMessage({ id: "/vFsdb", defaultMessage: "This Month's remaining deliveries (Maximum Count)" }) + ": " + (messageLimit - messageUsage) + " (" + (messageLimit < 0 ? '∞' : messageLimit) + ")"));
        };
        return _this;
    }
    SendDelivery.prototype.componentDidMount = function () {
        this.fetchNecessaryData();
    };
    SendDelivery.prototype.componentDidUpdate = function (prevProps) {
        var _a;
        var currentScenario = this.props.currentScenario;
        if (currentScenario &&
            !isEqual(currentScenario === null || currentScenario === void 0 ? void 0 : currentScenario.id, (_a = prevProps.currentScenario) === null || _a === void 0 ? void 0 : _a.id)) {
            this.fetchNecessaryData();
        }
    };
    SendDelivery.prototype.dateValid = function (current) {
        var todayDay = moment().subtract(1, 'day').hours(0).minutes(0).seconds(0);
        return current.isAfter(todayDay);
    };
    SendDelivery.prototype.checkIfTriggerTimeIsValid = function (current) {
        // Where current is a moment object
        var now = moment().seconds(0).milliseconds(0);
        return current.isAfter(now);
    };
    SendDelivery.prototype.render = function () {
        var _this = this;
        var _a = this.props, currentScenario = _a.currentScenario, chatTypeParam = _a.chatTypeParam, filteredUsers = _a.adUserFilter.filteredUsers, _b = _a.readOnly, readOnly = _b === void 0 ? false : _b, chatbotIdParam = _a.chatbotIdParam, adScenarioIdParam = _a.adScenarioIdParam, onReadOnlyClick = _a.onReadOnlyClick, chatbotPlatform = _a.chatbotPlatform, intl = _a.intl;
        return currentScenario && !this.state.loading ? (React.createElement(Container, { className: "send_delivery_container" },
            React.createElement(FormContainer, { className: readOnly ? 'readonly' : '' },
                chatTypeParam === chatTypes.STEP ? (React.createElement(StepDeliveryWindow, __assign({}, this.props, { onFilterSelectChange: this.onFilterSelectChange, handleUserFilterClick: this.handleUserFilterClick, handleTestingChange: this.handleTestingChange, handleStepScheduleDelivery: this.handleStepScheduleDelivery, handleStepDateChange: this.handleStepDateChange }))) : null,
                chatTypeParam === chatTypes.SHOT ? (React.createElement(ShotDeliveryWindow, __assign({}, this.props, { filterId: currentScenario.filter_id, dateValid: this.dateValid, onFilterSelectChange: this.onFilterSelectChange, handleUserFilterClick: this.handleUserFilterClick, handleSelectChange: this.handleSelectChange, handleTestingChange: this.handleTestingChange, handleScheduledScenarioDelivery: this.handleScheduledScenarioDelivery, updateTriggerTime: this.updateTriggerTime, onScheduleDeliverySubmit: function () {
                        return onReadOnlyClick(_this.handleScheduleDeliveryBtnClick);
                    }, getMessageUsageAndLimitNumber: getMessageUsageAndLimitNumber, renderMessageUsageData: this.renderMessageUsageData }))) : null,
                chatTypeParam !== chatTypes.STEP &&
                    chatTypeParam !== chatTypes.SHOT ? (React.createElement(Fragment, null,
                    React.createElement(TestingSelect, { handleTestingChange: this.handleTestingChange, testing: currentScenario.testing, intl: intl, scenarioId: currentScenario.id }))) : null),
            this.state.userFilterModalOpen ? (React.createElement(ModalPortal, null,
                React.createElement(Modal, { "data-testid": "send-delivery-user-filter-modal", type: modalTypes.NONE, width: "90%", minWidth: "900px", maxWidth: "1200px", isModalCloseFlex: true, onCancel: this.toggleUserFilterModal, overFlow: true },
                    React.createElement(UserFilter, { chatbotIdParam: chatbotIdParam, adScenarioIdParam: adScenarioIdParam, filterId: currentScenario.filter_id, onCancel: this.toggleUserFilterModal, getMessageUsageAndLimitNumber: getMessageUsageAndLimitNumber, footerPadding: "2.5rem 0 0 0;", readOnly: readOnly, chatbotPlatform: chatbotPlatform, renderMessageUsageData: this.renderMessageUsageData })))) : null,
            this.state.overLimitConfirmModelOpen ? (React.createElement(ModalPortal, null,
                React.createElement(Modal, { "data-testid": "send-delivery-people-delivered", text: React.createElement("div", null,
                        React.createElement("h1", null, "\u6CE8\u610F\uFF01\uFF01"),
                        React.createElement("div", null,
                            "\u914D\u4FE1\u4EBA\u6570\u304C",
                            SHOT_DELIVER_LIMIT,
                            "\u4EBA\u3092\u8D85\u3048\u3066\u3044\u307E\u3059!"),
                        React.createElement("div", null,
                            "\u914D\u4FE1\u4EBA\u6570\u306F",
                            filteredUsers.length,
                            "\u4EBA\u3067\u3059")), type: modalTypes.CUSTOM, onCancel: this.toggleOverLimitConfirmModal, onConfirm: this.handleScenarioDeliveryConfirm, btnText: "\u8A73\u7D30\u78BA\u8A8D\u3078" }))) : null,
            this.state.scheduleDeliveryConfirmModalOpen ? (React.createElement(ModalPortal, null,
                React.createElement(Modal, { "data-testid": "send-delivery-to-user", type: modalTypes.DELIVERY, onCancel: this.toggleScheduleDeliveryConfirmModal, onConfirm: this.handleScenarioDeliveryConfirm },
                    React.createElement(DeliveryConfirm, { filteredUsers: filteredUsers, text: "\u4E0B\u8A18\u306E\u30E6\u30FC\u30B6\u30FC\u306B\u914D\u4FE1\u3057\u307E\u3059\u3002", scenario: currentScenario, isStep: false })))) : null,
            this.state.stepModalOpen ? (React.createElement(ModalPortal, null,
                React.createElement(Modal, { "data-testid": "send-delivery-set-step-time", type: modalTypes.CUSTOM, btnText: "\u8A2D\u5B9A", onCancel: this.toggleStepModal, onConfirm: this.handleStepDeliveryConfirm },
                    React.createElement(DeliveryConfirm, { filteredUsers: filteredUsers, text: "\u4E0A\u8A18\u306E\u65E5\u6642\u3067\u3001step\u914D\u4FE1\u3092\u8A2D\u5B9A\u3057\u307E\u3059\u3002", scenario: currentScenario, isStep: true })))) : null,
            this.state.stepScheduleEditModalOpen ? (React.createElement(ModalPortal, null,
                React.createElement(Modal, { "data-testid": "send-delivery-edit-modal", text: "\u8A2D\u5B9A\u304C\u89E3\u9664\u3055\u308C\u307E\u3059\u3002\u7DE8\u96C6\u3057\u307E\u3059\u304B", type: modalTypes.CUSTOM, btnText: "\u7DE8\u96C6", onCancel: this.toggleStepScheduleEditModal, onConfirm: this.handleStepDeliveryConfirm }))) : null)) : (React.createElement(Spinner, null));
    };
    return SendDelivery;
}(Component));
var mapStateToProps = function (state) {
    return {
        adUserFilter: state.adUserFilter.present,
        adScenario: state.adScenario.present,
    };
};
export default connect(mapStateToProps, {
    handleNewScenarioDelivery: handleNewScenarioDelivery,
    updateCampaignContentDelivery: updateCampaignContentDelivery,
    fetchQuickFilter: fetchQuickFilter,
    fetchFilteredUsers: fetchFilteredUsers,
    adHandleDeleteFilter: adHandleDeleteFilter,
    adCreateNewUserFilter: adCreateNewUserFilter,
    fetchAttributeQuestions: fetchAttributeQuestions,
    getMessageUsageAndLimitNumber: getMessageUsageAndLimitNumber,
    fetchFilterTags: fetchFilterTags,
    fetchUserFilter: fetchUserFilter,
})(injectIntl(SendDelivery));
var templateObject_1, templateObject_2, templateObject_3;
