var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import { injectIntl } from 'react-intl';
import { colors } from '@src/colors';
import { Switch } from '@src/components/atoms';
import { useHistory } from 'react-router-dom';
import { chatlinkMessages } from '@src/i18n/translations';
import * as pathLinks from '@src/routes/Fanp/pathLinks';
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  p {\n    color: ", ";\n  }\n  .Chatlink_switch {\n    margin: 0 !important;\n  }\n"], ["\n  p {\n    color: ", ";\n  }\n  .Chatlink_switch {\n    margin: 0 !important;\n  }\n"])), colors.text_pale);
var Chatlink = function (_a) {
    var chatbotIdParam = _a.chatbotIdParam, chatLink = _a.chatLink, intl = _a.intl;
    var browserHistory = useHistory();
    return (React.createElement(Wrapper, { className: "row item", onClick: function () {
            browserHistory.push(pathLinks.dashboardAdChatbots_chatbotId_Chatlinks_chatlinkId_({
                chatlinkId: chatLink.id,
                chatbotId: chatbotIdParam,
            }));
        } },
        React.createElement("p", null, chatLink.id),
        React.createElement("p", { className: "left" }, chatLink.name),
        React.createElement("p", null, intl.formatMessage(chatlinkMessages[chatLink.modal_type.toUpperCase()])),
        React.createElement("p", { "data-testid": "triggerType" }, chatLink.modal_type !== 'banner' &&
            intl.formatMessage(chatlinkMessages[chatLink.trigger.toUpperCase()])),
        React.createElement(Switch, { className: "Chatlink_switch", isActive: !!chatLink.active }),
        React.createElement(Switch, { className: "Chatlink_switch", isActive: !!chatLink.display_interval_control_flag }),
        React.createElement("p", null, intl.formatMessage(chatlinkMessages[chatLink.device_type.toUpperCase()]))));
};
export default injectIntl(Chatlink);
var templateObject_1;
