var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import { colors } from '@src/colors';
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  height: calc(100vh - 60px);\n  background: ", ";\n  display: flex;\n  flex-direction: column;\n"], ["\n  height: calc(100vh - 60px);\n  background: ", ";\n  display: flex;\n  flex-direction: column;\n"])), colors.background.content);
var Chatlinks = function (_a) {
    var children = _a.children;
    return (React.createElement(Container, null, children));
};
export default Chatlinks;
var templateObject_1;
