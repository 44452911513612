import { select } from 'd3';
import { colors } from '@src/colors';
import QuickReply from './QuickReply';
import TalkTrigger from './TalkTrigger';
import { BLOCK_WIDTH, MSG_MARGIN, MSG_PADDING, MSG_QUICK_MARGIN, BORDER_RADIUS, TALK_TRIGGER_MARGIN, } from './constants';
// interface MessageInterface {
//   getWidth(): number;
//   getHeight(): number;
//   getMessageHeightPadding(): number;
//   getMessagePadding(): number;
//   getMessageMargin(): number;
//   getContentHeight(): number;
//   getContentX(x: number): number;
//   getContentY(y: number): number;
//   render(x: number, y: number, renderInitialContainer: boolean): void;
// }
var Message = /** @class */ (function () {
    function Message(block, message, msgIterator) {
        this.block = block;
        this.message = message;
        this.msgIterator = msgIterator;
        this.msgType = 'default';
        this.quickReply = message.quickReply
            ? new QuickReply(this.block, message.quickReply)
            : null;
        this.talkTrigger = message.talkTrigger
            ? new TalkTrigger(this.block, message.talkTrigger)
            : null;
    }
    Message.prototype.getWidth = function () {
        return BLOCK_WIDTH;
    };
    Message.prototype.getHeight = function () {
        return this.getMessageHeightPadding() + this.getContentHeight();
    };
    Message.prototype.getMessageHeightPadding = function () {
        return this.getMessagePadding() + this.getMessageMargin();
    };
    Message.prototype.getMessagePadding = function () {
        return MSG_PADDING * 2;
    };
    Message.prototype.getMessageMargin = function () {
        return MSG_MARGIN * 2;
    };
    Message.prototype.getContentHeight = function () {
        return 0;
    };
    Message.prototype.getContentX = function (x) {
        return x + MSG_PADDING;
    };
    Message.prototype.getContentY = function (y) {
        return y + MSG_MARGIN + MSG_PADDING;
    };
    Message.prototype.render = function (x, y, renderInitialContainer) {
        if (renderInitialContainer === void 0) { renderInitialContainer = true; }
        // Render container
        if (renderInitialContainer) {
            select(this.block)
                .append('rect')
                .classed('message', true)
                .on('mousedown', function (e) {
                e.stopPropagation();
            })
                .attr('x', x)
                .attr('y', y + MSG_MARGIN)
                .attr('rx', BORDER_RADIUS)
                .attr('ry', BORDER_RADIUS)
                .attr('width', this.getWidth())
                .attr('height', this.getContentHeight() + MSG_PADDING * 2)
                .attr('fill', colors.background.content)
                .style('stroke', colors.border.default);
        }
        if (this.quickReply) {
            this.quickReply.render(x, y + this.getHeight() + MSG_QUICK_MARGIN);
        }
        if (this.talkTrigger) {
            this.talkTrigger.render(x, y + this.getHeight() + MSG_QUICK_MARGIN + TALK_TRIGGER_MARGIN);
        }
    };
    return Message;
}());
export default Message;
