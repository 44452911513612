var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';
import { Composition } from 'atomic-layout';
import { injectIntl } from 'react-intl';
import styled from 'styled-components';
import { colors } from '@src/colors';
import { copyToClipboard } from '@src/lib/helpers';
import { LoadingScreen } from '@src/components/atoms';
import { FlashMessage, SubMenu } from '@src/components/molecules';
import { fetchAdScenarios } from '@src/redux/fanp/actions/ad-scenario';
import { toggleWelcomeBoard, toggleMainMenu, toggleDefaultMessage, toggleDisconnectMessage, getDefaultMessageText, getWelcomeMessageText, changeDefaultOption, toggleFetchingSettings, fetchUpdateChatbotStatus, getChatbot, updatePluginMessage, updatePluginTitle, updatePluginButton, fetchPersistentMenuList, } from '@src/redux/fanp/actions/settings';
import { updateIsFetching } from '@src/redux/fanp/actions/application';
import { hoverActionBasic } from '@src/lib/mixins';
import { AlertModalContext } from '@src/components/molecules/AlertModal';
var SubMenuContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  background-color: #fff;\n  width: 240px;\n  border-right: 1px solid #d8d8e0;\n  padding: 1rem;\n  height: 100%;\n\n  .button_submit {\n    display: block;\n  }\n"], ["\n  background-color: #fff;\n  width: 240px;\n  border-right: 1px solid #d8d8e0;\n  padding: 1rem;\n  height: 100%;\n\n  .button_submit {\n    display: block;\n  }\n"])));
var ContentArea = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  background: ", ";\n  height: 100%;\n  padding: 2rem 3rem;\n  span.adicon_close {\n    position: absolute;\n    top: 24px;\n    right: 24px;\n    color: ", ";\n    ", ";\n    font-size: 24px;\n    vertical-align: middle;\n    &.plugin {\n      top: 20px;\n      right: 12px;\n    }\n  }\n"], ["\n  background: ", ";\n  height: 100%;\n  padding: 2rem 3rem;\n  span.adicon_close {\n    position: absolute;\n    top: 24px;\n    right: 24px;\n    color: ", ";\n    ", ";\n    font-size: 24px;\n    vertical-align: middle;\n    &.plugin {\n      top: 20px;\n      right: 12px;\n    }\n  }\n"])), colors.bg_darker, colors.gray_lighter, hoverActionBasic);
var SettingsMain = function (_a) {
    var children = _a.children, intl = _a.intl, updatePluginButton = _a.updatePluginButton, updatePluginTitle = _a.updatePluginTitle, updatePluginMessage = _a.updatePluginMessage, getChatbot = _a.getChatbot, toggleWelcomeBoard = _a.toggleWelcomeBoard, toggleMainMenu = _a.toggleMainMenu, toggleDefaultMessage = _a.toggleDefaultMessage, toggleDisconnectMessage = _a.toggleDisconnectMessage, getDefaultMessageText = _a.getDefaultMessageText, getWelcomeMessageText = _a.getWelcomeMessageText, changeDefaultOption = _a.changeDefaultOption, fetchUpdateChatbotStatus = _a.fetchUpdateChatbotStatus, persistentMenuList = _a.persistentMenuList, settings = _a.settings, application = _a.application, fetchAdScenarios = _a.fetchAdScenarios, fetchPersistentMenuList = _a.fetchPersistentMenuList, updateIsFetching = _a.updateIsFetching;
    var msg = useRef(null);
    var params = useParams();
    var browserLocatoin = useLocation();
    var _b = __read(useState(false), 2), isCodeCopyNotificationVisible = _b[0], setCodeCopyNotificationVisible = _b[1];
    var handleModal = React.useContext(AlertModalContext).handleModal;
    useEffect(function () {
        var chatbotId = params.chatbotId;
        fetchAdScenarios({
            chatbotId: chatbotId,
            pushUrl: false,
        }).catch(function (e) { return handleModal(e.message); });
        fetchPersistentMenuList(chatbotId);
    }, []);
    var fetchSettings = function () {
        getChatbot(params.chatbotId);
    };
    var handlePluginMessageUpdate = function (ev) {
        updatePluginMessage(ev.target.value);
    };
    var handlePluginTitleUpdate = function (ev) {
        updatePluginTitle(ev.target.value);
    };
    var handlePluginButtonUpdate = function (ev) {
        updatePluginButton(ev.target.value);
    };
    var showErrorAlert = function () {
        var message = intl.formatMessage({ id: "HbVhj0", defaultMessage: "There was an error." });
        msg.current.show(message, {
            time: 2000,
            type: 'error',
        });
    };
    var showUpdateSuccessAlert = function () {
        var message = intl.formatMessage({ id: "N8TDIh", defaultMessage: "Update successful." });
        msg.current.show(message, {
            time: 2000,
            type: 'info',
        });
    };
    var showSaveSuccessAlert = function () {
        var message = 'マガジンが保存されました';
        msg.current.show(message, {
            time: 2000,
            type: 'info',
        });
    };
    var copyCodeToClipboard = function (code) {
        var text = code ||
            "<script id=\"fanp_aggregate\" src=\"https://fanp.me/plugin/fanp_aggregate.js\"></script>";
        copyToClipboard(text, function () {
            setCodeCopyNotificationVisible(true);
            setTimeout(function () {
                setCodeCopyNotificationVisible(false);
            }, 2000);
        });
    };
    var activeChatbot = settings.activeChatbot, defaultMessageText = settings.defaultMessageText, defaultOptionSelected = settings.defaultOptionSelected, showDefaultMessage = settings.showDefaultMessage, showDisconnectMessage = settings.showDisconnectMessage, showMainMenu = settings.showMainMenu, showWelcomeBoard = settings.showWelcomeBoard;
    var areas = "\n        subMenu mainContent\n    ";
    var applicationIsFetching = application.isFetching;
    var lastRoute = browserLocatoin.pathname.split('/').slice(-1)[0];
    return applicationIsFetching ? (React.createElement(LoadingScreen, null)) : (React.createElement(React.Fragment, null,
        React.createElement(FlashMessage, { ref: msg }),
        React.createElement(Composition, { areas: areas, templateCols: "240px auto", height: "100%" }, function (Areas) { return (React.createElement(React.Fragment, null,
            React.createElement(Areas.SubMenu, null,
                React.createElement(SubMenuContainer, null,
                    React.createElement(SubMenu, { route: lastRoute, location: true, chatbotId: params.chatbotId, settings: settings }))),
            React.createElement(Areas.MainContent, { style: {
                    background: "" + colors.bg_darker,
                } },
                React.createElement(ContentArea, null, React.cloneElement(children, {
                    toggleWelcomeBoard: toggleWelcomeBoard,
                    toggleMainMenu: toggleMainMenu,
                    toggleDefaultMessage: toggleDefaultMessage,
                    toggleDisconnectMessage: toggleDisconnectMessage,
                    getDefaultMessageText: getDefaultMessageText,
                    getWelcomeMessageText: getWelcomeMessageText,
                    changeDefaultOption: changeDefaultOption,
                    fetchAdScenarios: fetchAdScenarios,
                    fetchPersistentMenuList: fetchPersistentMenuList,
                    updateIsFetching: updateIsFetching,
                    location: browserLocatoin,
                    params: params,
                    fetchUpdateChatbotStatus: fetchUpdateChatbotStatus,
                    defaultMessageText: defaultMessageText,
                    defaultOptionSelected: defaultOptionSelected,
                    persistentMenuList: persistentMenuList,
                    showDefaultMessage: showDefaultMessage,
                    showDisconnectMessage: showDisconnectMessage,
                    showMainMenu: showMainMenu,
                    showWelcomeBoard: showWelcomeBoard,
                    activeChatbot: activeChatbot,
                    isCodeCopyNotificationVisible: isCodeCopyNotificationVisible,
                    handlePluginMessageUpdate: handlePluginMessageUpdate,
                    handlePluginTitleUpdate: handlePluginTitleUpdate,
                    handlePluginButtonUpdate: handlePluginButtonUpdate,
                    showErrorAlert: showErrorAlert,
                    showUpdateSuccessAlert: showUpdateSuccessAlert,
                    showSaveSuccessAlert: showSaveSuccessAlert,
                    copyCodeToClipboard: copyCodeToClipboard,
                    fetchSettings: fetchSettings,
                    chatbotId: activeChatbot.id,
                    webchatAppId: activeChatbot.page.webchat_app_id,
                }))))); })));
};
var mapStateToProps = function (_a) {
    var application = _a.application, settings = _a.settings;
    return ({
        application: application,
        settings: settings.present,
    });
};
export default connect(mapStateToProps, {
    toggleFetchingSettings: toggleFetchingSettings,
    toggleWelcomeBoard: toggleWelcomeBoard,
    toggleMainMenu: toggleMainMenu,
    toggleDefaultMessage: toggleDefaultMessage,
    toggleDisconnectMessage: toggleDisconnectMessage,
    getDefaultMessageText: getDefaultMessageText,
    getWelcomeMessageText: getWelcomeMessageText,
    changeDefaultOption: changeDefaultOption,
    fetchAdScenarios: fetchAdScenarios,
    fetchUpdateChatbotStatus: fetchUpdateChatbotStatus,
    getChatbot: getChatbot,
    updatePluginMessage: updatePluginMessage,
    updatePluginTitle: updatePluginTitle,
    updatePluginButton: updatePluginButton,
    updateIsFetching: updateIsFetching,
    fetchPersistentMenuList: fetchPersistentMenuList,
})(injectIntl(SettingsMain));
var templateObject_1, templateObject_2;
