export default {
  // tags
  SET_RETENTION_TAGS_AND_TOTAL: 'SET_RETENTION_TAGS_AND_TOTAL',
  // targets
  SET_RETENTION_TARGETS: 'SET_RETENTION_TARGETS',
  ADD_RETENTION_TARGET: 'ADD_RETENTION_TARGET',
  UPDATE_RETENTION_TARGET: 'UPDATE_RETENTION_TARGET',
  DELETE_RETENTION_TARGET: 'DELETE_RETENTION_TARGET',
  // campaigns
  SET_RETENTION_CAMPAIGNS: 'SET_RETENTION_CAMPAIGNS',
  ADD_RETENTION_CAMPAIGNS: 'ADD_RETENTION_CAMPAIGNS',
  UPDATE_RETENTION_CAMPAIGN: 'UPDATE_RETENTION_CAMPAIGN',
  DELETE_RETENTION_CAMPAIGN: 'DELETE_RETENTION_CAMPAIGN',
  // contents
  SET_RETENTION_CONTENTS: 'SET_RETENTION_CONTENTS',
  SET_RETENTION_CONTENT: 'SET_RETENTION_CONTENT',
  SET_RETENTION_SCENARIOS: 'SET_RETENTION_SCENARIOS',
  ADD_RETENTION_CONTENT: 'ADD_RETENTION_CONTENT',
  DELETE_RETENTION_CONTENT: 'DELETE_RETENTION_CONTENT'
};