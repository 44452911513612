var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import { intersection } from 'lodash';
var textSpanProperties = {
    size: {
        draftValues: [
            'xxs',
            'xs',
            'sm',
            'md',
            'lg',
            'xl',
            'xxl',
            '3xl',
            '4xl',
            '5xl',
        ],
        defaultValue: 'sm',
    },
    weight: {
        draftValues: ['BOLD'],
        defaultValue: 'REGULAR',
    },
    style: {
        draftValues: ['ITALIC'],
        defaultValue: 'NORMAL',
    },
    decoration: {
        draftValues: ['UNDERLINE', 'STRIKETHROUGH'],
        defaultValue: 'NONE',
    },
};
var defaultColor = '#ffffff';
// Define originalLength, customSlice string function because
// when exists UTF-16 charater on string, it will count length is 2 but
// data from draftJS return count length is 1, solution is convert to array
// of string by Array.from(...)
function originalLength(str) {
    return Array.from(str).length;
}
function customSlice(str, start, end) {
    return Array.from(str).slice(start, end).join('');
}
function wrapped(block) {
    var lineTexts = block.text.split('\\n');
    var inlineStyleRanges = block.inlineStyleRanges;
    var offset = 0;
    lineTexts.forEach(function (lineText, index) {
        if (index === lineTexts.length - 1)
            return;
        offset += originalLength(lineText);
        inlineStyleRanges.push({
            offset: offset,
            length: 2,
            style: 'BREAK',
        });
    });
    return __assign(__assign({}, block), { inlineStyleRanges: inlineStyleRanges });
}
function buildNodes(text, inlineStyleRanges) {
    var nodes = [];
    nodes[0] = {};
    nodes[originalLength(text)] = {};
    inlineStyleRanges.forEach(function (inlineStyleRange) {
        var offset = inlineStyleRange.offset, length = inlineStyleRange.length;
        var nodeStart = nodes[offset] || {};
        var nodeEnd = nodes[offset + length] || {};
        nodeStart[inlineStyleRange.style] = nodeStart[inlineStyleRange.style] || 1;
        nodeEnd[inlineStyleRange.style] = nodeEnd[inlineStyleRange.style] || -1;
        nodes[offset] = nodeStart;
        nodes[offset + length] = nodeEnd;
    });
    return nodes;
}
function draftValuesConverted(properties) {
    if (properties.indexOf('line-through') >= 0)
        properties.push('strikethrough');
    return intersection(properties.filter(Boolean).map(function (propery) { return propery.toLocaleUpperCase(); }), [].concat.apply([], __spreadArray([], __read(Object.values(textSpanProperties).map(function (textSpanProperty) { return textSpanProperty.draftValues; })))));
}
function styled(offset, span) {
    var size = span.size, weight = span.weight, decoration = span.decoration, style = span.style, text = span.text;
    var color = span.color;
    if (color === defaultColor)
        color = null;
    var properties = draftValuesConverted([weight, decoration, style])
        .join(' ')
        .toLocaleUpperCase()
        .split(' ')
        .concat(color)
        .concat(size)
        .filter(Boolean);
    return properties.map(function (property) {
        return {
            offset: offset,
            style: property,
            length: originalLength(text),
        };
    });
}
function propertiesConverted(properties) {
    var result = {
        type: 'span',
        text: null,
        size: null,
        weight: null,
        style: null,
        decoration: null,
        color: null,
    };
    Object.keys(textSpanProperties).forEach(function (key) {
        result[key] = (intersection(textSpanProperties[key].draftValues, properties)[0] ||
            textSpanProperties[key].defaultValue).toLowerCase();
        if (result[key] === 'strikethrough')
            result[key] = 'line-through';
    });
    var color = properties.filter(function (property) { return property.startsWith('#'); })[0] ||
        defaultColor;
    return __assign(__assign({}, result), { color: color });
}
function spansConverted(block) {
    var _a = wrapped(block), text = _a.text, inlineStyleRanges = _a.inlineStyleRanges;
    if (!text.length)
        return [];
    var nodes = buildNodes(text, inlineStyleRanges);
    var keys = Object.keys(nodes);
    var property = {};
    return keys
        .map(function (key, index) {
        if (index === keys.length - 1)
            return null;
        property = __assign(__assign({}, property), nodes[key]);
        var properties = property['BREAK'] === 1
            ? ['BREAK']
            : Object.keys(property).filter(function (propertyKey) {
                return property[propertyKey] === 1;
            });
        var spanText = customSlice(text, parseInt(key), parseInt(keys[index + 1]));
        if (properties.indexOf('BREAK') >= 0) {
            if (spanText === '\\' || spanText === '\\n') {
                spanText = '\\n';
            }
            else
                return null;
        }
        return __assign(__assign({}, propertiesConverted(properties)), { text: spanText });
    })
        .filter(Boolean);
}
export var convertToSpans = function (blocks) {
    var spans = [];
    blocks.forEach(function (block, index) {
        spans = spans.concat(spansConverted(block));
        if (index < blocks.length - 1) {
            spans = spans.concat(__assign(__assign({}, propertiesConverted([])), { text: '\\n' }));
        }
    });
    return spans;
};
export var convertFromSpans = function (spans) {
    var defaultBlock = {
        text: '',
        inlineStyleRanges: [],
    };
    var block = __assign({}, defaultBlock);
    var blocks = [];
    spans.forEach(function (span, index) {
        if (span.text === '\\n') {
            blocks.push(block);
            block = __assign({}, defaultBlock);
        }
        else {
            block.inlineStyleRanges = block.inlineStyleRanges.concat(styled(originalLength(block.text), span));
            block.text = "" + block.text + span.text;
            if (index + 1 === spans.length)
                blocks.push(block);
        }
    });
    return {
        blocks: blocks,
        entityMap: [],
    };
};
export var retrieveContentLineFromBlock = function (block) {
    var inlineStyleRanges = block.inlineStyleRanges
        .filter(function (i) { return i.style.startsWith('BLOCK-'); })
        .sort(function (a, b) { return a.offset - b.offset; });
    var text = Array.from(block.text);
    var blockTags = [];
    var blockNamesLengh = 0;
    inlineStyleRanges.forEach(function (i) {
        blockTags = blockTags.concat({
            index: i.offset - blockNamesLengh,
            block_id: Number(i.style.split('-')[1]),
        });
        text = __spreadArray(__spreadArray([], __read(text.slice(0, i.offset - blockNamesLengh))), __read(text.slice(i.offset - blockNamesLengh + i.length, text.length)));
        blockNamesLengh = blockNamesLengh + i.length;
    });
    return {
        text: text.join(''),
        block_tags: blockTags,
    };
};
