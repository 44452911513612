export default {
  SUCCESS_FULFILLED: 'SUCCESS_FULFILLED',
  UNDO_AD_SCENARIO: 'UNDO_AD_SCENARIO',
  // OTHER
  ADD_AD_SCENARIO: 'ADD_AD_SCENARIO',
  TOGGLE_SCENARIO_NAME_MODAL: 'TOGGLE_SCENARIO_NAME_MODAL',
  COPY_SCENARIO: 'COPY_SCENARIO',
  TOGGLE_JSON_MODAL: 'TOGGLE_JSON_MODAL',
  CONFIRM_DELIVERY: 'CONFIRM_DELIVERY',
  TOGGLE_QUICK_REPLY_SELECT_MODE: 'TOGGLE_QUICK_REPLY_SELECT_MODE',
  CREATE_NEW_FILTER_MESSAGE: 'CREATE_NEW_FILTER_MESSAGE',
  // message section
  UPDATE_MESSAGE_FILTER: 'UPDATE_MESSAGE_FILTER',
  AD_DUPLICATE_BUBBLE: 'AD_DUPLICATE_BUBBLE',
  UPDATE_SHOW_UP_MESSAGE: 'UPDATE_SHOW_UP_MESSAGE',
  UPDATE_HIDE_MESSAGE: 'UPDATE_HIDE_MESSAGE',
  // ADD
  SET_USER_TAG_GROUP: 'SET_USER_TAG_GROUP',
  AD_CREATE_CAMPAIGN_CONTENT: 'AD_CREATE_CAMPAIGN_CONTENT',
  AD_ADD_QUICK_REPLY_CHOICE: 'AD_ADD_QUICK_REPLY_CHOICE',
  AD_ADD_LIST_HEAD_ITEM: 'AD_ADD_LIST_HEAD_ITEM',
  AD_ADD_NEW_BUTTON_TO_TEXT: 'AD_ADD_NEW_BUTTON_TO_TEXT',
  AD_ADD_NEW_BUTTON_TO_GENERIC: 'AD_ADD_NEW_BUTTON_TO_GENERIC',
  AD_ADD_BLOCK_TO_BUTTON: 'AD_ADD_BLOCK_TO_BUTTON',
  AD_CREATE_NEW_SCENARIO_DELIVERY: 'AD_CREATE_NEW_SCENARIO_DELIVERY',
  AD_ADD_FREE_INPUT: 'AD_ADD_FREE_INPUT',
  AD_ADD_FREE_INPUT_FROM_FIELD: 'AD_ADD_FREE_INPUT_FROM_FIELD',
  AD_TEMPLATE_SCENARIO: 'AD_TEMPLATE_SCENARIO',
  // HANDLE AJAX RESPONSE
  ADD_NEW_ITEM: 'ADD_NEW_ITEM',
  ADD_CREATED_TAG: 'ADD_CREATED_TAG',
  AD_HANDLE_ADD_NEW_TEXT_BUTTON: 'AD_HANDLE_ADD_NEW_TEXT_BUTTON',
  AD_HANDLE_ADD_NEW_GENERIC_BUTTON: 'AD_HANDLE_ADD_NEW_GENERIC_BUTTON',
  // AD_HANDLE_ADD_NEW_TEXT_BUTTON: 'AD_HANDLE_ADD_NEW_TEXT_BUTTON',
  AD_HANDLE_NEW_SCENARIO_DELIVERY: 'AD_HANDLE_NEW_SCENARIO_DELIVERY',
  AD_HANDLE_STEP_CAMPAIGN_CONTENT: 'AD_HANDLE_STEP_CAMPAIGN_CONTENT',
  AD_HANDLE_UPDATED_SCENARIO: 'AD_HANDLE_UPDATED_SCENARIO',
  AD_HANDLE_AJAX_FREE_INPUT: 'AD_HANDLE_AJAX_FREE_INPUT',
  // EDIT
  AD_EDIT_TEXT_MESSAGE: 'AD_EDIT_TEXT_MESSAGE',
  AD_EDIT_ARTICLE: 'AD_EDIT_ARTICLE',
  ADD_NEW_MESSAGE_SECTION: 'ADD_NEW_MESSAGE_SECTION',
  AD_EDIT_QUICK_REPLY_CHOICE: 'AD_EDIT_QUICK_REPLY_CHOICE',
  CONNECT_BLOCK_TO_BUTTON: 'CONNECT_BLOCK_TO_BUTTON',
  AD_EDIT_MEDIA: 'AD_EDIT_MEDIA',
  AD_UPDATE_TEXT_BUTTON: 'AD_UPDATE_TEXT_BUTTON',
  AD_EDIT_SCHEDULED_AT: 'AD_EDIT_SCHEDULED_AT',
  AD_EDIT_CAMPAIGN_CONTENT_SETTING: 'AD_EDIT_CAMPAIGN_CONTENT_SETTING',
  AD_EDIT_FREE_INPUT: 'AD_EDIT_FREE_INPUT',
  CHANGE_IMAGE_ASPECT_RATIO: 'CHANGE_IMAGE_ASPECT_RATIO',
  EDIT_SCENARIO_PROPERTY: 'EDIT_SCENARIO_PROPERTY',
  AD_SET_MESSAGE: 'AD_SET_MESSAGE',
  // DELETE
  DELETE_SCENARIO_DELIVERY: 'DELETE_SCENARIO_DELIVERY',
  AD_REMOVE_MESSAGE: 'AD_REMOVE_MESSAGE',
  AD_DELETE_SCENARIO: 'AD_DELETE_SCENARIO',
  AD_DELETE_SCENARIOS: 'AD_DELETE_SCENARIOS',
  AD_REMOVE_TAG: 'AD_REMOVE_TAG',
  AD_REMOVE_MESSAGE_ITEM: 'AD_REMOVE_MESSAGE_ITEM',
  REMOVE_BLOCK: 'REMOVE_BLOCK',
  AD_REMOVE_BUTTON_FROM_TEXT: 'AD_REMOVE_BUTTON_FROM_TEXT',
  AD_REMOVE_BUTTON_FROM_GENERIC: 'AD_REMOVE_BUTTON_FROM_GENERIC',
  AD_REMOVE_FREE_INPUT: 'AD_REMOVE_FREE_INPUT',
  AD_REMOVE_IDS_CHANGE: 'AD_REMOVE_IDS_CHANGE',
  AD_REMOVE_BUBBLE: 'AD_REMOVE_BUBBLE',
  REMOVE_SEARCH_SCENARIOS: 'REMOVE_SEARCH_SCENARIOS',
  // SET
  AD_HANDLE_DATA_FOR_MESSAGE_INSERT: 'AD_HANDLE_DATA_FOR_MESSAGE_INSERT',
  TOGGLE_CHOICE_SELECTED: 'TOGGLE_CHOICE_SELECTED',
  SET_AD_SCENARIOS: 'SET_AD_SCENARIOS',
  SET_AD_SCENARIO: 'SET_AD_SCENARIO',
  SET_MAIN_SCENARIO: 'SET_MAIN_SCENARIO',
  AD_SET_BLOCK_MESSAGES: 'AD_SET_BLOCK_MESSAGES',
  SET_BLOCK_NAME: 'SET_BLOCK_NAME',
  AD_IS_VALID_TRIGGER_TIME: 'AD_IS_VALID_TRIGGER_TIME',
  AD_SET_STEP_DAYS_AFTER: 'AD_SET_STEP_DAYS_AFTER',
  AD_SET_STEP_DELIVERY_TIME: 'AD_SET_STEP_DELIVERY_TIME',
  AD_SET_CONNECTED_BLOCKS: 'AD_SET_CONNECTED_BLOCKS',
  AD_TOGGLE_IMAGE_LOADING: 'AD_TOGGLE_IMAGE_LOADING',
  AD_SET_QUICK_REPLY_DUPLICATED: 'AD_SET_QUICK_REPLY_DUPLICATED',
  AD_SET_PREV_BLOCKS: 'AD_SET_PREV_BLOCKS',
  AD_SET_SCENARIO_TESTING: 'AD_SET_SCENARIO_TESTING',
  AD_SET_GENERIC_LIST_BTN: 'AD_SET_GENERIC_LIST_BTN',
  AD_SET_LAYER_FIELD: 'AD_SET_LAYER_FIELD',
  AD_TOGGLE_LAYER_FIELD: 'AD_TOGGLE_LAYER_FIELD',
  AD_SET_UNDEFINED_BLOCKS_LAYER_FIELD: 'AD_SET_UNDEFINED_BLOCKS_LAYER_FIELD',
  AD_TOGGLE_UNDEFINED_BLOCKS_LAYER_FIELD: 'AD_TOGGLE_UNDEFINED_BLOCKS_LAYER_FIELD',
  AD_OPEN_ALL_LAYERS: 'AD_OPEN_ALL_LAYERS',
  AD_TOGGLE_ALL_LAYERS_FIELD: 'AD_TOGGLE_ALL_LAYERS_FIELD',
  SET_ATTRIBUTE_QUESTIONS: 'SET_ATTRIBUTE_QUESTIONS',
  SET_USER_TAG_GROUPS: 'SET_USER_TAG_GROUPS',
  SET_TARGET_USER_TAG_GROUP: 'SET_TARGET_USER_TAG_GROUP',
  SELECT_ALL_CHOICES: 'SELECT_ALL_CHOICES',
  SET_SCENARIO_TEMPLATES: 'SET_SCENARIO_TEMPLATES',
  SET_SELECTED_USER_TAG_GROUP_ID: 'SET_SELECTED_USER_TAG_GROUP_ID',
  UPDATE_SELECTED_USER_TAG_GROUP: 'UPDATE_SELECTED_USER_TAG_GROUP',
  UPDATE_QUICK_REPLY_USER_TAG_GROUP: 'UPDATE_QUICK_REPLY_USER_TAG_GROUP',
  SET_GENERIC_ITEM: 'SET_GENERIC_ITEM',
  SET_AD_SCENARIOS_LIST: 'SET_AD_SCENARIOS_LIST',
  SET_PAGINATED_SCENARIO_IDS: 'SET_PAGINATED_SCENARIO_IDS',
  // VALIDATIONS
  UPDATE_VALIDATION: 'UPDATE_VALIDATION',
  UPDATE_VALIDATIONS: 'UPDATE_VALIDATIONS',
  UPDATE_BLOCK_VALIDATIONS: 'UPDATE_BLOCK_VALIDATIONS',
  // LOADINg
  LOADING_ITEM: 'LOADING_ITEM',
  SWAP_GENERIC_ITEM_RANKS: 'SWAP_GENERIC_ITEM_RANKS',
  HANDLE_SWAP_GENERIC_ITEM_RANKS: 'HANDLE_SWAP_GENERIC_ITEM_RANKS',
  SET_AD_SCENARIOS_FETCHING: 'SET_AD_SCENARIOS_FETCHING',
  SET_LINE_FLEX_MESSAGE_COMPONENT: 'SET_LINE_FLEX_MESSAGE_COMPONENT'
};