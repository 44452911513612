import "core-js/modules/es.array.concat";
var zealsAdmin = {
  collection: function collection(_ref) {
    var customFormId = _ref.customFormId,
        headlessConfigId = _ref.headlessConfigId,
        headlessPageId = _ref.headlessPageId;
    return "/zeals_admin/api/custom_forms/".concat(customFormId, "/headless_configs/").concat(headlessConfigId, "/headless_pages/").concat(headlessPageId, "/headless_steps");
  },
  member: function member(_ref2) {
    var customFormId = _ref2.customFormId,
        headlessConfigId = _ref2.headlessConfigId,
        headlessPageId = _ref2.headlessPageId,
        id = _ref2.id;
    return "".concat(zealsAdmin.collection({
      customFormId: customFormId,
      headlessConfigId: headlessConfigId,
      headlessPageId: headlessPageId
    }), "/").concat(id);
  },
  attachQuestion: function attachQuestion(_ref3) {
    var customFormId = _ref3.customFormId,
        headlessConfigId = _ref3.headlessConfigId,
        headlessPageId = _ref3.headlessPageId,
        id = _ref3.id;
    return "".concat(zealsAdmin.member({
      customFormId: customFormId,
      headlessConfigId: headlessConfigId,
      headlessPageId: headlessPageId,
      id: id
    }), "/attach_question");
  },
  removeQuestion: function removeQuestion(_ref4) {
    var customFormId = _ref4.customFormId,
        headlessConfigId = _ref4.headlessConfigId,
        headlessPageId = _ref4.headlessPageId,
        id = _ref4.id;
    return "".concat(zealsAdmin.member({
      customFormId: customFormId,
      headlessConfigId: headlessConfigId,
      headlessPageId: headlessPageId,
      id: id
    }), "/remove_question");
  },
  createExtraStepValue: function createExtraStepValue(_ref5) {
    var customFormId = _ref5.customFormId,
        headlessConfigId = _ref5.headlessConfigId,
        headlessPageId = _ref5.headlessPageId,
        id = _ref5.id;
    return "".concat(zealsAdmin.member({
      customFormId: customFormId,
      headlessConfigId: headlessConfigId,
      headlessPageId: headlessPageId,
      id: id
    }), "/create_extra_step_value");
  },
  deleteExtraStepValue: function deleteExtraStepValue(_ref6) {
    var customFormId = _ref6.customFormId,
        headlessConfigId = _ref6.headlessConfigId,
        headlessPageId = _ref6.headlessPageId,
        id = _ref6.id;
    return "".concat(zealsAdmin.member({
      customFormId: customFormId,
      headlessConfigId: headlessConfigId,
      headlessPageId: headlessPageId,
      id: id
    }), "/destroy_extra_step_value");
  },
  deleteExtraStep: function deleteExtraStep(_ref7) {
    var customFormId = _ref7.customFormId,
        headlessConfigId = _ref7.headlessConfigId,
        headlessPageId = _ref7.headlessPageId,
        id = _ref7.id;
    return "".concat(zealsAdmin.member({
      customFormId: customFormId,
      headlessConfigId: headlessConfigId,
      headlessPageId: headlessPageId,
      id: id
    }), "/destroy_extra_step");
  }
};
export default {
  zealsAdmin: zealsAdmin
};