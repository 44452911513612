import "core-js/modules/es.array.map";
import _extends from "@babel/runtime/helpers/extends";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import DragAndDropHOC from '@src/components/hoc/DragAndDropHOC';
import BubbleWrapper from './BubbleWrapper';
var CarouselWrapper = styled.div.attrs({
  className: 'lfm-carousel'
}).withConfig({
  displayName: "Carousel__CarouselWrapper",
  componentId: "sc-1maaank-0"
})(["overflow-x:", ";"], function (props) {
  return props.editing ? 'unset' : 'scroll';
});

var Carousel = function Carousel(_ref) {
  var optionDisable = _ref.optionDisable,
      content = _ref.content,
      chatbotIdParam = _ref.chatbotIdParam,
      readOnly = _ref.readOnly,
      initials = _ref.initials,
      swapBubbleRanks = _ref.swapBubbleRanks,
      setLineFlexMessageComponent = _ref.setLineFlexMessageComponent,
      rest = _objectWithoutProperties(_ref, ["optionDisable", "content", "chatbotIdParam", "readOnly", "initials", "swapBubbleRanks", "setLineFlexMessageComponent"]);

  var _useState = useState(0),
      _useState2 = _slicedToArray(_useState, 2),
      editingBubbleId = _useState2[0],
      _setEditingBubbleId = _useState2[1];

  return React.createElement(CarouselWrapper, {
    editing: editingBubbleId
  }, React.createElement(DragAndDropHOC, null, content.contents.map(function (contentDetail, index) {
    return React.createElement(BubbleWrapper, _extends({
      "data-testid": "Carousel-BubbleWrapper-".concat(index),
      key: contentDetail.id,
      optionDisable: optionDisable,
      content: contentDetail,
      chatbotIdParam: chatbotIdParam,
      readOnly: readOnly,
      initials: initials,
      swapBubbleRanks: swapBubbleRanks,
      editing: editingBubbleId === contentDetail.id,
      setEditingBubbleId: function setEditingBubbleId() {
        return _setEditingBubbleId(contentDetail.id);
      },
      removeEditingBubbleId: function removeEditingBubbleId() {
        return _setEditingBubbleId(0);
      },
      setLineFlexMessageComponent: setLineFlexMessageComponent,
      isLast: index === content.contents.length - 1
    }, rest));
  })));
};

Carousel.propTypes = {
  optionDisable: PropTypes.bool,
  chatbotIdParam: PropTypes.number,
  readOnly: PropTypes.bool,
  initials: PropTypes.arrayOf(PropTypes.object),
  content: PropTypes.shape({
    contents: PropTypes.array.isRequired,
    type: PropTypes.string.isRequired
  }),
  swapBubbleRanks: PropTypes.func
};
export default Carousel;