var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import { ActionsType } from '@src/redux/fanp/constants/initialConversation';
import { WeightScenarioVariantGroupFunctionType, } from 'zeals-protobuf/zeals/web/entities/scenario_group_pb';
var initialState = {
    selectedScenarios: [],
    scenarios: [],
    getActiveInitialConversation: false,
    getDraftInitialConversation: false,
    ABTesting: {
        active: undefined,
        draft: undefined,
    },
};
var preventEmptyDraftABSetting = function (state) {
    if (!state.ABTesting.draft) {
        state.ABTesting.draft = [
            {
                scenarioVariantGroup: {
                    weightScenarioVariantGroup: {},
                },
            },
        ];
    }
};
export default (function (state, action) {
    if (state === void 0) { state = initialState; }
    var type = action.type, payload = action.payload;
    switch (type) {
        case ActionsType.SET_SCENARIOS: {
            var newState = __assign(__assign({}, state), { scenarios: payload });
            return newState;
        }
        case ActionsType.SET_SELECTED_SCENARIOS: {
            var newState = __assign(__assign({}, state), { selectedScenarios: payload });
            return newState;
        }
        case ActionsType.SET_SELECTED_SCENARIOS_WEIGHT_TO_EQUALITY: {
            preventEmptyDraftABSetting(state);
            state.ABTesting.draft[0].scenarioVariantGroup.weightScenarioVariantGroup.functionType =
                WeightScenarioVariantGroupFunctionType.WEIGHT_SCENARIO_VARIANT_GROUP_FN_TYPE_EQUAL_WEIGHT;
            var count = state.selectedScenarios.length;
            var weight_1 = 100 / count;
            state.selectedScenarios = state.selectedScenarios.map(function (scenario) { return (__assign(__assign({}, scenario), { weight: weight_1 })); });
            var newState = __assign(__assign({}, state), { scenarios: __spreadArray([], __read(state.scenarios)) });
            return newState;
        }
        case ActionsType.SET_SELECTED_SCENARIOS_WEIGHT_TO_CUSTOM: {
            preventEmptyDraftABSetting(state);
            state.ABTesting.draft[0].scenarioVariantGroup.weightScenarioVariantGroup.functionType =
                WeightScenarioVariantGroupFunctionType.WEIGHT_SCENARIO_VARIANT_GROUP_FN_TYPE_CUSTOM_WEIGHT;
            var newState = __assign(__assign({}, state), { scenarios: __spreadArray([], __read(state.scenarios)) });
            return newState;
        }
        case ActionsType.SET_ACTIVE_AB_TESTING: {
            var newState = __assign(__assign({}, state), { ABTesting: __assign(__assign({}, state.ABTesting), { active: payload }) });
            return newState;
        }
        case ActionsType.SET_DRAFT_AB_TESTING: {
            var newState = __assign(__assign({}, state), { ABTesting: __assign(__assign({}, state.ABTesting), { draft: payload }) });
            return newState;
        }
        case ActionsType.SET_ACTIVE_INITIAL_CONVERSATION: {
            var newState = __assign(__assign({}, state), { getActiveInitialConversation: payload });
            return newState;
        }
        case ActionsType.SET_DRAFT_INITIAL_CONVERSATION: {
            var newState = __assign(__assign({}, state), { getDraftInitialConversation: payload });
            return newState;
        }
        default:
            return state;
    }
});
