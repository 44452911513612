var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { TalkType } from '../types/talk';
import { makeFetchInit, handleAPIErrors, fanpFetch, objToQuery, } from '@src/lib/helpers';
import { adScenariosNormalizer } from '@src/lib/dataNormalizer';
import endpoints from '@src/endpoints';
import * as scenarioApi from '@src/services/api/scenario';
import * as endUserApi from '@src/services/api/endUser';
import * as endUserTagAssosiationApi from '@src/services/api/endUserTagAssosiation';
// HANDLE UNDO
export var undo = function () { return ({ type: TalkType.UNDO_TALK }); };
// HANDLE LOADING
export var setIsLoadingLogHistory = function (isLoading) { return ({
    type: TalkType.SET_IS_LOADING_LOG_HISTORY,
    isLoading: isLoading,
}); };
export var setIsLoadingLogs = function (isLoading) { return ({
    type: TalkType.SET_IS_LOADING_LOGS,
    isLoading: isLoading,
}); };
export var setIsLoadingUser = function (isLoading) { return ({
    type: TalkType.SET_IS_LOADING_USER,
    isLoading: isLoading,
}); };
export var setIsLoadingUsers = function (isLoading) { return ({
    type: TalkType.SET_IS_LOADING_USERS,
    isLoading: isLoading,
}); };
export var setLoadedEndUsers = function (isLoading) { return ({
    type: TalkType.SET_LOADED_END_USERS,
    isLoading: isLoading,
}); };
// HANDLE EVENT LOG
export var setEventLogs = function (eventLogs) { return ({
    type: TalkType.SET_EVENT_LOGS,
    eventLogs: eventLogs,
}); };
export var pushEventLog = function (eventLog) { return ({
    type: TalkType.PUSH_EVENT_LOG,
    eventLog: eventLog,
}); };
export var shiftEventLogs = function (eventLogs) { return ({
    type: TalkType.SHIFT_EVENT_LOGS,
    eventLogs: eventLogs,
}); };
export var appendLatestEventLogs = function (eventLogs) { return ({
    type: TalkType.PUSH_EVENT_LOGS,
    eventLogs: eventLogs,
}); };
// HANDLE END_USER
export var resetEndUsers = function () { return ({
    type: TalkType.RESET_END_USERS,
}); };
export var setEndUsers = function (endUsers) { return ({
    type: TalkType.SET_TALK_END_USERS,
    endUsers: endUsers,
}); };
export var setActiveEndUser = function (endUser) { return ({
    type: TalkType.SET_ACTIVE_END_USER,
    endUser: endUser,
}); };
export var updateEndUserDetails = function (endUser) { return ({
    type: TalkType.UPDATE_USER_DETAILS,
    endUser: endUser,
}); };
export var pushTalkEndUsers = function (newEndUsers) { return ({
    type: TalkType.PUSH_NEW_TALK_END_USERS,
    newEndUsers: newEndUsers,
}); };
export var unshiftTalkEndUsers = function (newEndUsers) { return ({
    type: TalkType.UNSHIFT_NEW_TALK_END_USERS,
    newEndUsers: newEndUsers,
}); };
// HANDLE FILTER
export var removeFilteredEndUsers = function () { return ({
    type: TalkType.REMOVE_FILTERED_END_USERS,
}); };
export var setFilteredEndUsers = function (endUsers) { return ({
    type: TalkType.SET_FILTERED_END_USERS,
    endUsers: endUsers,
}); };
export var pushFilteredEndUsers = function (endUsers) { return ({
    type: TalkType.PUSH_FILTERED_END_USERS,
    endUsers: endUsers,
}); };
export var setFilterText = function (chatbotId, filterText, page) {
    if (page === void 0) { page = 1; }
    return ({
        type: TalkType.SET_FILTER_TEXT,
        chatbotId: chatbotId,
        filterText: filterText,
        page: page,
    });
};
export var updateUserTagTalkroom = function (_a) {
    var userId = _a.userId, tag = _a.tag, _b = _a.deleteTag, deleteTag = _b === void 0 ? false : _b;
    return {
        type: TalkType.UPDATE_USER_TAG_TALKROOM,
        userId: userId,
        tag: tag,
        deleteTag: deleteTag,
    };
};
// TODO adScenario is type for now, fix it later
export var setScenariosForTalk = function (adScenarios) {
    var normalizedData = adScenariosNormalizer(adScenarios);
    var scenarios = normalizedData.scenarios, layers = normalizedData.layers, blocks = normalizedData.blocks;
    return {
        type: TalkType.SET_SCENARIOS_FOR_TALK,
        scenarios: scenarios,
        layers: layers,
        blocks: blocks,
    };
};
export var fetchEndUsers = function (_a) {
    var chatbotId = _a.chatbotId, _b = _a.page, page = _b === void 0 ? 1 : _b, searchTerm = _a.searchTerm;
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        var endUsers, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    dispatch(setIsLoadingUsers(true));
                    return [4 /*yield*/, endUserApi.getTalkRoomEndUsers(chatbotId, {
                            page: page,
                            searchTerm: searchTerm,
                        })];
                case 1:
                    endUsers = _a.sent();
                    dispatch(setIsLoadingUsers(false));
                    if (page > 1) {
                        if (searchTerm) {
                            dispatch(pushFilteredEndUsers(endUsers));
                            return [2 /*return*/];
                        }
                        dispatch(pushTalkEndUsers(endUsers));
                        return [2 /*return*/];
                    }
                    if (searchTerm) {
                        dispatch(setFilteredEndUsers(endUsers));
                        return [2 /*return*/];
                    }
                    dispatch(setEndUsers(endUsers));
                    dispatch(setLoadedEndUsers(true));
                    return [3 /*break*/, 3];
                case 2:
                    e_1 = _a.sent();
                    handleAPIErrors(e_1);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
};
export var fetchPollEndUsers = function (_a) {
    var chatbotId = _a.chatbotId, latestCreatedAt = _a.latestCreatedAt;
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        var endUsers, e_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 3, , 4]);
                    if (!latestCreatedAt) return [3 /*break*/, 2];
                    return [4 /*yield*/, endUserApi.getTalkRoomEndUsers(chatbotId, {
                            latestCreatedAt: latestCreatedAt,
                        })];
                case 1:
                    endUsers = _a.sent();
                    dispatch(unshiftTalkEndUsers(endUsers));
                    _a.label = 2;
                case 2: return [3 /*break*/, 4];
                case 3:
                    e_2 = _a.sent();
                    handleAPIErrors(e_2);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
};
export var filterEndUsers = function (_a) {
    var chatbotId = _a.chatbotId, _b = _a.page, page = _b === void 0 ? 1 : _b, filtersObject = _a.filtersObject;
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        var body, query, endUsers, e_3;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    dispatch(setIsLoadingUsers(true));
                    body = { filters: filtersObject };
                    query = { page: page };
                    return [4 /*yield*/, endUserApi.makeTalkRoomFilterEndUsers(chatbotId, query, body)];
                case 1:
                    endUsers = _a.sent();
                    dispatch(setIsLoadingUsers(false));
                    if (page > 1) {
                        dispatch(pushFilteredEndUsers(endUsers));
                        return [2 /*return*/];
                    }
                    dispatch(setFilteredEndUsers(endUsers));
                    return [3 /*break*/, 3];
                case 2:
                    e_3 = _a.sent();
                    handleAPIErrors(e_3);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
};
export var fetchEventLogs = function (_a) {
    var chatbotId = _a.chatbotId, endUserId = _a.endUserId, _b = _a.page, page = _b === void 0 ? 1 : _b, _c = _a.shift, shift = _c === void 0 ? false : _c;
    return function (dispatch) {
        if (!shift)
            dispatch(setIsLoadingLogs(true));
        else
            dispatch(setIsLoadingLogHistory(true));
        var url = endpoints.eventLogs.talk.collection({
            chatbotId: chatbotId,
            endUserId: endUserId,
        });
        var query = objToQuery({ page: page });
        return fanpFetch(url + "?" + query, makeFetchInit('GET'))
            .then(function (_a) {
            var event_logs = _a.event_logs;
            dispatch(setIsLoadingLogs(false));
            dispatch(setIsLoadingLogHistory(false));
            if (shift)
                return dispatch(shiftEventLogs(event_logs));
            return dispatch(setEventLogs(event_logs));
        })
            .catch(handleAPIErrors);
    };
};
export var fetchLatestEventLog = function (_a) {
    var chatbotId = _a.chatbotId, endUserId = _a.endUserId, latestCreatedAt = _a.latestCreatedAt;
    return function (dispatch) {
        var query = objToQuery({ latest_created_at: latestCreatedAt });
        var url = endpoints.eventLogs.talk.speeches({
            chatbotId: chatbotId,
            endUserId: endUserId,
        });
        return fanpFetch(url + "?" + query, makeFetchInit('GET'))
            .then(function (_a) {
            var event_logs = _a.event_logs;
            dispatch(appendLatestEventLogs(event_logs));
        })
            .catch(handleAPIErrors);
    };
};
export var getScenariosForTalk = function (chatbotId) {
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        var scenariosList;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, scenarioApi.listScenarios(chatbotId)];
                case 1:
                    scenariosList = _a.sent();
                    dispatch(setScenariosForTalk(scenariosList));
                    return [2 /*return*/];
            }
        });
    }); };
};
export var addUserTag = function (_a) {
    var userId = _a.userId, chatbotId = _a.chatbotId, name = _a.name;
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        var body, tag, e_4;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    body = {
                        end_user_ids: [userId],
                        name: name,
                    };
                    return [4 /*yield*/, endUserTagAssosiationApi.addUserTag(chatbotId, userId, body)];
                case 1:
                    tag = _a.sent();
                    dispatch(updateUserTagTalkroom({ userId: userId, tag: tag }));
                    return [3 /*break*/, 3];
                case 2:
                    e_4 = _a.sent();
                    console.error('Can not attach the tag: ', e_4);
                    handleAPIErrors(e_4);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
};
export var deleteUserTag = function (_a) {
    var userId = _a.userId, chatbotId = _a.chatbotId, tag = _a.tag;
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        var query, status, e_5;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    query = { name: tag.name };
                    return [4 /*yield*/, endUserTagAssosiationApi.deleteUserTag(chatbotId, userId, query)];
                case 1:
                    status = _a.sent();
                    if (status && status !== 200) {
                        throw new Error('タグが削除できませんでした');
                    }
                    dispatch(updateUserTagTalkroom({
                        userId: userId,
                        tag: tag,
                        deleteTag: true,
                    }));
                    return [3 /*break*/, 3];
                case 2:
                    e_5 = _a.sent();
                    console.error('Can not delete the tag: ', e_5);
                    handleAPIErrors(e_5);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
};
export var updateEndUser = function (body, chatbotId, endUserId) {
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        var endUser, e_6;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, endUserApi.updateTalkRoomEndUser(chatbotId, endUserId, body)];
                case 1:
                    endUser = _a.sent();
                    dispatch(updateEndUserDetails(endUser));
                    return [3 /*break*/, 3];
                case 2:
                    e_6 = _a.sent();
                    handleAPIErrors(e_6);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
};
export var fetchEndUser = function (chatbotId, endUserId) {
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        var endUser, e_7;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    dispatch(setIsLoadingUser(true));
                    return [4 /*yield*/, endUserApi.getTalkRoomEndUser(chatbotId, endUserId)];
                case 1:
                    endUser = _a.sent();
                    dispatch(setActiveEndUser(endUser));
                    dispatch(setIsLoadingUser(false));
                    return [3 /*break*/, 3];
                case 2:
                    e_7 = _a.sent();
                    handleAPIErrors(e_7);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
};
