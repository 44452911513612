var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import styled from 'styled-components';
import { colors } from '@src/colors';
import { messageTypes } from '@src/lib/app-constants';
import { styledTemplate, customImageMapTemplateFrame } from '@src/lib/helpers';
import imageMapTemplates from '@src/lib/ImageMapTemplates';
var ImageMapMessageContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin: 20px 10px;\n  position: relative;\n  background: ", ";\n  background-repeat: no-repeat;\n  background-position: center;\n  background-size: contain;\n\n  ", "\n\n  .template-areas {\n    .label {\n      color: white;\n      font-size: 100%;\n      font-weight: unset;\n      position: absolute;\n      bottom: 6px;\n      width: calc(100% - 10px);\n      text-align: left;\n      background: ", ";\n    }\n  }\n"], ["\n  margin: 20px 10px;\n  position: relative;\n  background: ",
    ";\n  background-repeat: no-repeat;\n  background-position: center;\n  background-size: contain;\n\n  ",
    "\n\n  .template-areas {\n    .label {\n      color: white;\n      font-size: 100%;\n      font-weight: unset;\n      position: absolute;\n      bottom: 6px;\n      width: calc(100% - 10px);\n      text-align: left;\n      background: ", ";\n    }\n  }\n"])), function (props) {
    return "url(" + ((props.imageMapVideo && props.imageMapVideo.proxy_image_url) ||
        props.imageUrl) + ")" || 'unset';
}, function (props) {
    return styledTemplate(__assign(__assign({}, props), { messageType: messageTypes.IMAGE_MAP, scaleByWidth: true, customTemplateFrame: customImageMapTemplateFrame(props) }));
}, colors.background.main_color);
var ImageMapNode = function (_a) {
    var _b = _a.data, message = _b.message, updateBoundaryNode = _b.updateBoundaryNode;
    var image_map = message.image_map;
    var templateGroup = imageMapTemplates.find(function (imageMapTemplate) {
        return Object.keys(imageMapTemplate.templates).includes(String(image_map.template));
    });
    if (!templateGroup)
        return null;
    var template = templateGroup.templates[image_map.template];
    return (React.createElement(ImageMapMessageContainer, { "data-testid": "ImageMapNode_ImageMapMessageContainer", key: "image-map-" + image_map.id, areas: template.areas, templateWidth: templateGroup.width, templateHeight: templateGroup.height, templateNum: image_map.template, imageMapVideo: image_map.image_map_video, imageUrl: image_map.proxy_image_url },
        React.createElement("div", { className: "template-areas", "data-testid": "ImageMapNode_TemplateAreas" }, image_map.image_map_areas.map(function (area, areaIndex) {
            var postback_action = area.postback_action;
            var label = !(postback_action === null || postback_action === void 0 ? void 0 : postback_action.scenario_id) &&
                !(postback_action === null || postback_action === void 0 ? void 0 : postback_action.block_id) &&
                !(postback_action === null || postback_action === void 0 ? void 0 : postback_action.url)
                ? 'No action'
                : area.label;
            return (React.createElement("div", { "data-testid": "ImageMapNode_ImageMapArea", key: "image-map-area-" + area.id, className: "area-" + areaIndex, ref: function (ref) {
                    if (!ref || !updateBoundaryNode || !area.postback_action)
                        return;
                    updateBoundaryNode('postbackActions', area.postback_action.id, ref.getBoundingClientRect());
                } },
                React.createElement("div", { className: "label" }, !(image_map === null || image_map === void 0 ? void 0 : image_map.image_map_video) ? label : '')));
        }))));
};
export default ImageMapNode;
var templateObject_1;
