var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
import { string, number, object, mixed } from 'yup';
import { FormAutomationStepAction, FormAutomationStepValueType, } from 'zeals-protobuf/zeals/web/entities/form_automation_pb';
var fieldIdSchema = string().when('valueType', {
    is: FormAutomationStepValueType.FORM_AUTOMATION_STEP_VALUE_TYPE_FIELD,
    then: string()
        .typeError('Field is required when using the "field" setting')
        .required('Field is required when using the "field" setting'),
    otherwise: string().nullable(),
});
var fixedValueSchema = string().when('valueType', {
    is: FormAutomationStepValueType.FORM_AUTOMATION_STEP_VALUE_TYPE_FIXED,
    then: string().typeError('Fixed value is required when using "fixed" setting'),
    otherwise: string().nullable(),
});
var schemas = {
    timeWait: object().shape({
        waitS: number()
            .required('Wait time is required')
            .positive('Wait time must be positive')
            .integer('Wait time must be an integer'),
    }),
    click: object().shape({
        querySelector: string().required().min(1),
    }),
    clickByValue: object().shape({
        querySelector: string().required().min(1),
        // valueMapMap: Array<[string, string]>, // NOTE: implement at some point
        fieldId: fieldIdSchema,
        fixedValue: fixedValueSchema,
        htmlAttr: string().nullable(),
    }),
    input: object().shape({
        querySelector: string().required().min(1),
        // valueType: FormAutomationStepValueType,
        regexp: mixed().nullable(),
        // replacementGroupsList: Array<TextReplacementGroup.AsObject>,
        fieldId: fieldIdSchema,
        fixedValue: fixedValueSchema,
        dateFormat: number().nullable(),
    }),
    select: object().shape({
        querySelector: string().required().min(1),
        // valueType: FormAutomationStepValueType,
        // valueMapMap: Array<[string, string]>,
        fieldId: fieldIdSchema,
        fixedValue: fixedValueSchema,
        dateFormat: number().nullable(),
    }),
    elementWait: object().shape({
        querySelector: string().required().min(1),
    }),
    radio: object().shape({
        querySelector: string().required().min(1),
        // valueType: FormAutomationStepValueType,
        // valueMapMap: Array<[string, string]>,
        fieldId: fieldIdSchema,
        fixedValue: fixedValueSchema,
    }),
    navigation: object().shape({
        waitS: number().nullable(),
    }),
    magicalClick: object().shape({
        fieldId: string()
            .typeError('Field is required')
            .required('Field is required'),
        magicalClickType: number().positive(),
    }),
};
function validate(schema, action) {
    try {
        schema.validateSync(action);
    }
    catch (e) {
        return e.errors;
    }
    return [];
}
export default function validateSteps(steps) {
    var e_1, _a;
    var errors = {};
    try {
        for (var steps_1 = __values(steps), steps_1_1 = steps_1.next(); !steps_1_1.done; steps_1_1 = steps_1.next()) {
            var step = steps_1_1.value;
            var stepErrorMsgs = [];
            switch (step.actionType) {
                case FormAutomationStepAction.FORM_AUTOMATION_STEP_ACTION_CLICK: {
                    stepErrorMsgs = validate(schemas.click, step.clickAction);
                    break;
                }
                case FormAutomationStepAction.FORM_AUTOMATION_STEP_ACTION_TEXT_INPUT: {
                    stepErrorMsgs = validate(schemas.input, step.inputAction);
                    break;
                }
                case FormAutomationStepAction.FORM_AUTOMATION_STEP_ACTION_SELECT_INPUT: {
                    stepErrorMsgs = validate(schemas.select, step.selectAction);
                    break;
                }
                case FormAutomationStepAction.FORM_AUTOMATION_STEP_ACTION_TIME_WAIT: {
                    stepErrorMsgs = validate(schemas.timeWait, step.timeWaitAction);
                    break;
                }
                case FormAutomationStepAction.FORM_AUTOMATION_STEP_ACTION_ELEMENT_WAIT: {
                    stepErrorMsgs = validate(schemas.elementWait, step.elementWaitAction);
                    break;
                }
                case FormAutomationStepAction.FORM_AUTOMATION_STEP_ACTION_RADIO_INPUT: {
                    stepErrorMsgs = validate(schemas.radio, step.radioAction);
                    break;
                }
                case FormAutomationStepAction.FORM_AUTOMATION_STEP_ACTION_NAVIGATION_WAIT: {
                    stepErrorMsgs = validate(schemas.navigation, step.navigationWaitAction);
                    break;
                }
                case FormAutomationStepAction.FORM_AUTOMATION_STEP_ACTION_MAGICAL_CLICK: {
                    stepErrorMsgs = validate(schemas.magicalClick, step.magicalClickAction);
                    break;
                }
                case FormAutomationStepAction.FORM_AUTOMATION_STEP_ACTION_CLICK_BY_VALUE: {
                    stepErrorMsgs = validate(schemas.clickByValue, step.clickByValueAction);
                    break;
                }
                default:
            }
            errors[step.id] = stepErrorMsgs.join('\n');
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (steps_1_1 && !steps_1_1.done && (_a = steps_1.return)) _a.call(steps_1);
        }
        finally { if (e_1) throw e_1.error; }
    }
    return errors;
}
