var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { useState, useEffect, useRef } from 'react';
export default function useComponentVisible(initialIsVisible) {
    var _a = __read(useState(initialIsVisible), 2), isComponentVisible = _a[0], setIsComponentVisible = _a[1];
    var ref = useRef(null);
    var handleHideDropdown = function (event) {
        if (event.key === 'Escape') {
            setIsComponentVisible(false);
        }
    };
    var handleClickOutside = function (event) {
        if (ref.current && !ref.current.contains(event.target)) {
            setIsComponentVisible(false);
        }
    };
    useEffect(function () {
        document.addEventListener('keydown', handleHideDropdown, true);
        document.addEventListener('click', handleClickOutside, true);
        return function () {
            document.removeEventListener('keydown', handleHideDropdown, true);
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);
    return { ref: ref, isComponentVisible: isComponentVisible, setIsComponentVisible: setIsComponentVisible };
}
