import React, { Fragment } from 'react';
import { mediaTypes, mediaPlaceholderIcons } from '@src/lib/app-constants';
import buttonTypes from '@src/lib/buttonTypes';
import { Button } from '@src/components/atoms';
import Moment from 'moment';
import { injectIntl } from 'react-intl';
var CopyableFile = function (_a) {
    var uploadedFileData = _a.uploadedFileData, copied = _a.copied, copyToClipboard = _a.copyToClipboard, opened = _a.opened, openUrlInNewTab = _a.openUrlInNewTab, placeholderIcon = _a.placeholderIcon, intl = _a.intl;
    return (React.createElement("div", { className: "grid-cell-file" },
        React.createElement("div", { className: "file-container " + placeholderIcon },
            React.createElement("div", { className: "file-info" },
                React.createElement("div", { className: "file-info-name " + (opened ? 'opened' : ''), onClick: function () { return openUrlInNewTab(uploadedFileData.url); } }, uploadedFileData.name),
                React.createElement("div", { className: "file-info-duration" }, uploadedFileData.duration
                    ? "(" + Moment(uploadedFileData.duration).format('mm:ss') + ")"
                    : null))),
        React.createElement("div", { className: "button-copy " + (copied ? 'copied' : 'adicon_big_link'), onClick: function () { return copyToClipboard(uploadedFileData.url); } },
            ' ',
            copied
                ? intl.formatMessage({ id: "50V7qu", defaultMessage: "URL Copied !" })
                : intl.formatMessage({ id: "P8QaSQ", defaultMessage: "Copy URL" }))));
};
var CopyableImages = function (_a) {
    var uploadedFileData = _a.uploadedFileData, copied = _a.copied, copyToClipboard = _a.copyToClipboard, intl = _a.intl;
    return (React.createElement("div", { className: "grid-cell-image" },
        React.createElement("div", { className: "image-content", onClick: function () { return copyToClipboard(uploadedFileData.url); } },
            React.createElement("div", { className: "overlay " + (copied ? 'copied_overlay' : 'hover_overlay') },
                React.createElement("div", { className: "overlay_content" },
                    React.createElement("div", { className: "" + (copied ? 'adicon_success' : 'adicon_big_link') }),
                    copied ? (React.createElement("span", null, intl.formatMessage({ id: "50V7qu", defaultMessage: "URL Copied !" }))) : (React.createElement("span", null, intl.formatMessage({ id: "P8QaSQ", defaultMessage: "Copy URL" }))))),
            React.createElement("img", { src: uploadedFileData.url, className: "each-file" })),
        React.createElement("div", { className: "filename" }, uploadedFileData.name)));
};
var CopyMode = function (_a) {
    var mediaType = _a.mediaType, mediaTypeText = _a.mediaTypeText, toggleSelectMode = _a.toggleSelectMode, uploadedFileDatas = _a.uploadedFileDatas, copyToClipboard = _a.copyToClipboard, copiedFileUrl = _a.copiedFileUrl, openUrlInNewTab = _a.openUrlInNewTab, openedFileUrl = _a.openedFileUrl, intl = _a.intl;
    var isImage = mediaType === mediaTypes.IMAGE;
    var placeholderIcon = mediaPlaceholderIcons[mediaType];
    return (React.createElement(Fragment, null,
        React.createElement("div", { className: "button-title" },
            React.createElement("p", null, intl.formatMessage({ id: "LwdDVa", defaultMessage: "Uploaded" }) + " " + mediaTypeText + " " + intl.formatMessage({ id: "RrCui3", defaultMessage: "Summary" })),
            React.createElement(Button, { text: intl.formatMessage({ id: "oxHRd9", defaultMessage: "Select and Delete" }), buttonType: buttonTypes.SETTING, onClick: toggleSelectMode, fontSize: "12px", width: 100 })),
        React.createElement("div", { className: "files-container" },
            React.createElement("p", { className: "copyable-file" },
                React.createElement("span", { className: "adicon_question" }), "" + mediaTypeText + (mediaType === mediaTypes.AUDIO
                ? intl.formatMessage({ id: "gyrIEl", defaultMessage: "File" })
                : '') + " " + intl.formatMessage({ id: "qwkVmf", defaultMessage: "URL will be copied when clicking on it" })),
            React.createElement("div", { className: "files-grid-list " + (isImage ? 'has-images' : '') }, uploadedFileDatas.map(function (data) {
                var copied = data.url === copiedFileUrl;
                var opened = data.url === openedFileUrl;
                return isImage ? (React.createElement(CopyableImages, { key: "updated-image-" + data.id, uploadedFileData: data, copied: copied, copyToClipboard: copyToClipboard, opened: opened, placeholderIcon: null, openUrlInNewTab: function () { }, intl: intl })) : (React.createElement(CopyableFile, { key: "updated-file-" + data.id, uploadedFileData: data, copied: copied, copyToClipboard: copyToClipboard, opened: opened, placeholderIcon: placeholderIcon, openUrlInNewTab: openUrlInNewTab, intl: intl }));
            })))));
};
export default injectIntl(CopyMode);
