import { LocalStorageItemKey } from '@src/typings/enums';
export var SUPPORTED_LANGUAGES = [
    {
        value: 'ja',
        label: '日本語',
    },
    {
        value: 'en',
        label: 'English',
    },
];
export function getLanguageFromStorage() {
    var localStorageLanguage = localStorage.getItem(LocalStorageItemKey.Language);
    if (!(isSupportedLanguage(localStorageLanguage) || localStorageLanguage)) {
        return getNavigatorLanguage();
    }
    return localStorageLanguage;
}
export function setLanguageInStorage(language) {
    language = isSupportedLanguage(language) ? language : 'en';
    localStorage.setItem(LocalStorageItemKey.Language, language);
    return language;
}
function getNavigatorLanguage() {
    if (!window.navigator)
        return 'en';
    var language = window.navigator.language;
    switch (language) {
        case 'ja':
            return 'ja';
        default:
            return 'en';
    }
}
function isSupportedLanguage(value) {
    if (value === void 0) { value = ''; }
    return Boolean(SUPPORTED_LANGUAGES.find(function (lang) { return lang.value === value; }));
}
