var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
export var getCurrentTarget = function (retentionTargets, targetId) {
    return (retentionTargets &&
        retentionTargets.find(function (t) { return t.id === parseInt(targetId); }));
};
export var getInitialGroups = function (currentTarget) {
    var quick_filters = (currentTarget && currentTarget.filter.quick_filters) || [];
    return quick_filters.map(function (quick_filter) { return ({
        tag: Object.assign(quick_filter.attribute_answer, {
            not: quick_filter.not,
        }),
    }); });
};
export var getCurrentCampaign = function (retentionCampaigns, campaignId) {
    return (retentionCampaigns &&
        retentionCampaigns.find(function (c) { return c.id === parseInt(campaignId); }));
};
export var getCurrentContent = function (retentionContents, contentId) {
    if (retentionContents) {
        return retentionContents.find(function (c) { return c.id === parseInt(contentId); });
    }
    return {};
};
export var getCurrentQuickFilter = function (currentTarget) {
    return currentTarget && currentTarget.quick_filters.shift();
};
export var removeHasError = function (arr) {
    return arr.map(function (a) {
        if (a) {
            var cloned = __assign({}, a);
            delete cloned.hasError;
            return cloned;
        }
    });
};
export var filterRecordsBySearch = function (scenarios, searchTerm, stringKey) {
    if (stringKey === void 0) { stringKey = 'name'; }
    RegExp.quote = function (str) {
        return str.replace(/([.?*+^$[\]\\(){}|-])/g, '\\$1');
    };
    var filterFunc = isNaN(parseInt(searchTerm))
        ? function (scenario) {
            return scenario[stringKey].match(new RegExp(RegExp.quote(searchTerm), 'gi'));
        }
        : function (scenario) {
            return scenario.id.toString().match(new RegExp(searchTerm.toString()), 'gi');
        };
    return scenarios.filter(filterFunc);
};
