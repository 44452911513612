var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import { colors } from '@src/colors';
var TextMessageContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin: 20px 10px;\n  border-radius: 16px;\n  width: 260px;\n  max-height: 500px;\n  padding: 8px;\n  word-break: break-all;\n  white-space: break-spaces;\n  border: 1px solid ", ";\n  background: ", ";\n  overflow: hidden;\n"], ["\n  margin: 20px 10px;\n  border-radius: 16px;\n  width: 260px;\n  max-height: 500px;\n  padding: 8px;\n  word-break: break-all;\n  white-space: break-spaces;\n  border: 1px solid ", ";\n  background: ", ";\n  overflow: hidden;\n"])), colors.border.default, colors.background.off_white_background);
var TextNode = function (_a) {
    var message = _a.data.message;
    return (React.createElement(TextMessageContainer, { key: "text-" + message.id }, message.text));
};
export default TextNode;
var templateObject_1;
