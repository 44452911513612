var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { Component } from 'react';
import isNil from 'lodash/isNil';
import styled from 'styled-components';
import { colors } from '@src/colors';
import { MessageType } from '@src/typings/enums';
import { messageTypes, mediaTypes } from '@src/lib/app-constants';
import { generateYupSchema } from '@src/lib/yupSchemaCreator';
import { MessageStruct, MessageInterface, MediaMessageStruct, MediaMessageInterface, } from '@src/lib/definitions';
import TextMessage from '@src/fanp/enterprise/talk_customize/components/TextMessage';
import GenericMessage from '@src/fanp/enterprise/talk_customize/components/GenericMessage';
import LineFlexMessage from '@src/fanp/enterprise/talk_customize/components/line_flex_message/LineFlexMessage';
import { ImageMapTemplateContainer, RichMenuTemplateContainer, MediaMessage, AddFilterMessageButton, } from '@src/components/molecules';
import { handleAPIErrors } from '@src/lib/helpers';
import MessageFilterOption from './MessageFilterOption';
import { injectIntl } from 'react-intl';
import LIMITS from '@src/lib/platforms/limitations';
var MessageSectionWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  transition: 0.3s;\n  display: flex;\n  margin-top: 20px;\n  margin-bottom: 20px;\n  .message-section-wrapper {\n    margin: 0 20px 0 0;\n    padding: 0 20px 0 0;\n    border-right: 1px solid ", ";\n  }\n"], ["\n  transition: 0.3s;\n  display: flex;\n  margin-top: 20px;\n  margin-bottom: 20px;\n  .message-section-wrapper {\n    margin: 0 20px 0 0;\n    padding: 0 20px 0 0;\n    border-right: 1px solid ", ";\n  }\n"])), colors.border.default);
function hasMessageControls(messages, limits) {
    if (!limits.MESSAGE_SECTION_ENABLED)
        return false;
    var messageTypes = messages.map(getMessageType);
    var containsInvalidMessage = messageTypes.some(function (messageType) {
        return [
            MessageType.Generic,
            MessageType.List,
            // MessageType.Flex,
            MessageType.ImageMap,
            MessageType.RichMenu,
        ].includes(messageType);
    });
    return !containsInvalidMessage;
}
function getMessageType(message) {
    if (message.rich_menu)
        return MessageType.RichMenu;
    if (message.image_map)
        return MessageType.ImageMap;
    if (message.text !== null ||
        (message.button_template && message.button_template !== null))
        return MessageType.Text;
    if (message.generic_template)
        return MessageType.Generic;
    if (message.list_template)
        return MessageType.List;
    if (message.line_flex_message)
        return MessageType.Flex;
    if (message.medium)
        return MessageType.Medium;
    return null;
}
var MessageSection = /** @class */ (function (_super) {
    __extends(MessageSection, _super);
    function MessageSection() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.commonProps = {
            chatTypeParam: _this.props.chatTypeParam,
            chatbotIdParam: _this.props.chatbotIdParam,
            editTextMessage: _this.props.editTextMessage,
            adScenarioIdParam: _this.props.adScenarioIdParam,
            blockIdParam: _this.props.blockIdParam,
            rank: _this.props.rank,
            errors: _this.props.errors,
            limits: _this.props.limits,
            // actions
            showModal: _this.props.showModal,
            setModalData: _this.props.setModalData,
            createNewBlock: _this.props.createNewBlock,
            fetchAdScenarios: _this.props.fetchAdScenarios,
            removeMessage: _this.props.removeMessage,
            handleOnMessageDeleteClick: _this.props.handleOnMessageDeleteClick,
            handleOnFilterDeleteClick: _this.props.handleOnFilterDeleteClick,
        };
        _this.removeMessage = function (message) {
            var _a = _this.props, chatbotIdParam = _a.chatbotIdParam, adScenarioIdParam = _a.adScenarioIdParam, sectionMessages = _a.sectionMessages, blockIdParam = _a.blockIdParam, removeMessageHandler = _a.removeMessageHandler, default_message_id = _a.default_message_id;
            var messageParams = {
                chatbotId: chatbotIdParam,
                scenarioId: adScenarioIdParam,
                adScenarioId: adScenarioIdParam,
                messageId: message.id,
            };
            var isDefaultMessage = default_message_id === message.id;
            if (isDefaultMessage) {
                if (sectionMessages.length > 1) {
                    alert('フィルターを持っているメッセジが削除できない');
                    return false;
                }
                removeMessageHandler(__assign(__assign({}, messageParams), { blockId: blockIdParam }));
                return true;
            }
            else {
                removeMessageHandler(__assign(__assign({}, messageParams), { blockId: blockIdParam }));
                return true;
            }
        };
        _this.renderMessages = function () {
            var _a = _this.props, 
            // props
            id = _a.id, default_message_id = _a.default_message_id, sectionMessages = _a.sectionMessages, blockIdParam = _a.blockIdParam, chatbotIdParam = _a.chatbotIdParam, adScenarioIdParam = _a.adScenarioIdParam, chatTypeParam = _a.chatTypeParam, messages = _a.messages, platform = _a.platform, intl = _a.intl, rank = _a.rank, readOnly = _a.readOnly, limits = _a.limits, messageFilter = _a.messageFilter, activeBlocks = _a.activeBlocks, 
            // actions
            handleBottomTabClick = _a.handleBottomTabClick, setFilterToMessage = _a.setFilterToMessage, 
            // actions of Image Map
            createNewBlock = _a.createNewBlock, createImageMap = _a.createImageMap, updateImageMap = _a.updateImageMap, uploadImageToImageMap = _a.uploadImageToImageMap, updateImageMapAreas = _a.updateImageMapAreas, uploadImageMapVideo = _a.uploadImageMapVideo, deleteImageMap = _a.deleteImageMap, 
            // actions of TextMessage
            addNewButtonToText = _a.addNewButtonToText, removeButtonFromText = _a.removeButtonFromText, editTextButton = _a.editTextButton, 
            // actions of Rich Menu
            uploadRichMenuToLineHandler = _a.uploadRichMenuToLineHandler, richMenuUpdateHandler = _a.richMenuUpdateHandler, richMenuUpdateImageHandler = _a.richMenuUpdateImageHandler, richMenuAreaUpdateHandler = _a.richMenuAreaUpdateHandler, richMenuDeleteHandler = _a.richMenuDeleteHandler, 
            // actions of line flex message
            removeMessageHandler = _a.removeMessageHandler, removeBubbleFlexMessage = _a.removeBubbleFlexMessage, duplicateBubbleFlexMessage = _a.duplicateBubbleFlexMessage, 
            // actions of medium message
            mediaMessageUpdateHandler = _a.mediaMessageUpdateHandler, setLineFlexMessageComponent = _a.setLineFlexMessageComponent, highlightFilterId = _a.highlightFilterId, focusMessageId = _a.focusMessageId, showMessageSectionMessage = _a.showMessageSectionMessage, hideMessageAction = _a.hideMessageAction;
            var checkMessageType = function (message) {
                if (getMessageType(message)) {
                    return getMessageType(message);
                }
                else {
                    return message === null || message === void 0 ? void 0 : message.type;
                }
            };
            var allMessagesOfSection = sectionMessages.map(function (sectionMessage, index) {
                var _a;
                var isDefaultMessage = default_message_id === sectionMessage.id;
                var messageParams = {
                    chatbotId: chatbotIdParam,
                    scenarioId: adScenarioIdParam,
                    adScenarioId: adScenarioIdParam,
                    messageId: sectionMessage.id,
                };
                var schema = generateYupSchema(sectionMessage, platform, intl);
                var messageStruct = MessageStruct({
                    message: sectionMessage,
                    limits: limits,
                    schema: schema,
                    chatbotId: chatbotIdParam,
                    scenarioId: adScenarioIdParam,
                });
                var msgInterface = MessageInterface({
                    onRemove: function () {
                        if (isDefaultMessage) {
                            if (sectionMessages.length > 1) {
                                alert('フィルターを持っているメッセジが削除できない');
                                return false;
                            }
                            removeMessageHandler(__assign(__assign({}, messageParams), { blockId: blockIdParam }));
                            return true;
                        }
                        else {
                            removeMessageHandler(__assign(__assign({}, messageParams), { blockId: blockIdParam }));
                            return true;
                        }
                    },
                });
                var checkTextMessageShowUp = function () {
                    return (!isNil(sectionMessage === null || sectionMessage === void 0 ? void 0 : sectionMessage.text) ||
                        !isNil(sectionMessage.button_template));
                };
                var highlight = highlightFilterId === ((_a = sectionMessage.filter) === null || _a === void 0 ? void 0 : _a.id);
                var focus = focusMessageId === sectionMessage.id;
                var displayMessage = function () { return (React.createElement("div", { className: "message-wrapper sub-label", style: { minWidth: '265px' } }, intl.formatMessage({ id: "eOkND7", defaultMessage: "Press \"Display\" to display this message" }))); };
                switch (checkMessageType(sectionMessage)) {
                    case MessageType.Text:
                        return (React.createElement("div", { className: "message-section-wrapper", key: sectionMessage.id + "-message-wrapper", "data-testid": "MessageSection-MessageTextWrapper" },
                            React.createElement(MessageFilterOption, { messageSectionId: id, chatbotId: chatbotIdParam, scenarioId: adScenarioIdParam, readOnly: isDefaultMessage, messageId: sectionMessage.id, message: sectionMessage, messageFilter: messageFilter, onRemove: _this.removeMessage, 
                                // actions
                                setFilterToMessage: setFilterToMessage, handleBottomTabClick: handleBottomTabClick, highlight: highlight, isDisplay: checkTextMessageShowUp(), showMessageSectionMessage: showMessageSectionMessage, hideMessageAction: hideMessageAction }),
                            checkTextMessageShowUp() ? (React.createElement(TextMessage, __assign({}, _this.commonProps, { key: sectionMessage.id + "-message", platform: platform, messageId: sectionMessage.id, message: sectionMessage, onRemove: _this.removeMessage, activeBlocks: activeBlocks, sectionMessages: sectionMessages, addNewButtonToText: addNewButtonToText, removeButtonFromText: removeButtonFromText, editTextButton: editTextButton, schema: schema, isDefaultMessage: isDefaultMessage, focus: focus, highlight: highlight }))) : (React.createElement(React.Fragment, null, displayMessage()))));
                    case MessageType.Medium: {
                        var mediaMessageStruct = MediaMessageStruct(messageStruct);
                        var mediaMessageInterface = MediaMessageInterface({
                            messageInterface: msgInterface,
                            onChange: mediaMessageUpdateHandler,
                        });
                        return (React.createElement("div", { className: "message-section-wrapper", key: sectionMessage.id + "-message-wrapper", "data-testid": "MessageSection-MessageMediumWrapper" },
                            React.createElement(MessageFilterOption, { messageSectionId: id, chatbotId: chatbotIdParam, scenarioId: adScenarioIdParam, readOnly: isDefaultMessage, messageId: sectionMessage.id, message: sectionMessage, messageFilter: messageFilter, onRemove: _this.removeMessage, 
                                // action
                                setFilterToMessage: setFilterToMessage, handleBottomTabClick: handleBottomTabClick, highlight: highlight, isDisplay: !isNil(sectionMessage === null || sectionMessage === void 0 ? void 0 : sectionMessage.medium), showMessageSectionMessage: showMessageSectionMessage, hideMessageAction: hideMessageAction }),
                            !isNil(sectionMessage === null || sectionMessage === void 0 ? void 0 : sectionMessage.medium) ? (React.createElement(MediaMessage, { key: sectionMessage.id + "-message", platform: platform, messageId: sectionMessage.id, message: sectionMessage, messages: sectionMessages, mediaMessageStruct: mediaMessageStruct, mediaMessageInterface: mediaMessageInterface, activeBlocks: activeBlocks, isDefaultMessage: isDefaultMessage, focus: focus, highlight: highlight })) : (React.createElement(React.Fragment, null, displayMessage()))));
                    }
                    case MessageType.Generic:
                        return (React.createElement("div", { key: sectionMessage.id + "-message-wrapper", "data-testid": "MessageSection-MessageGenericWrapper" },
                            React.createElement(GenericMessage, __assign({}, _this.commonProps, { key: sectionMessage.id + "-message", platform: platform, messageId: sectionMessage.id, message: sectionMessage, rank: rank, schema: schema, activeBlocks: activeBlocks, isDefaultMessage: isDefaultMessage }))));
                    case 'line_flex_message':
                    case MessageType.Flex:
                        return (React.createElement("div", { className: "message-section-wrapper", key: sectionMessage.id + "-message-wrapper", "data-testid": "MessageSection-MessageFlexWrapper" },
                            React.createElement(MessageFilterOption, { messageSectionId: id, chatbotId: chatbotIdParam, scenarioId: adScenarioIdParam, readOnly: isDefaultMessage, messageId: sectionMessage.id, message: sectionMessage, messageFilter: messageFilter, onRemove: _this.removeMessage, 
                                // actions
                                setFilterToMessage: setFilterToMessage, handleBottomTabClick: handleBottomTabClick, highlight: highlight, isDisplay: !isNil(sectionMessage === null || sectionMessage === void 0 ? void 0 : sectionMessage.line_flex_message), showMessageSectionMessage: showMessageSectionMessage, hideMessageAction: hideMessageAction }),
                            !isNil(sectionMessage === null || sectionMessage === void 0 ? void 0 : sectionMessage.line_flex_message) ? (React.createElement(LineFlexMessage, __assign({}, _this.props, _this.commonProps, { readOnly: readOnly, key: sectionMessage.id + "-message", schema: schema, messageId: sectionMessage.id, message: sectionMessage, messages: messages, activeBlocks: activeBlocks, removeMessageHandler: removeMessageHandler, isDefaultMessage: isDefaultMessage, setLineFlexMessageComponent: setLineFlexMessageComponent, removeBubbleFlexMessage: removeBubbleFlexMessage, duplicateBubbleFlexMessage: duplicateBubbleFlexMessage }))) : (React.createElement(React.Fragment, null, displayMessage()))));
                    case MessageType.ImageMap:
                        return (React.createElement("div", { key: sectionMessage.id + "-message-wrapper", "data-testid": "MessageSection-MessageImagemapWrapper" },
                            React.createElement(ImageMapTemplateContainer, __assign({ key: sectionMessage.id + "-message" }, sectionMessage, { chatbotIdParam: chatbotIdParam, adScenarioIdParam: adScenarioIdParam, blockIdParam: blockIdParam, rank: rank, schema: schema, activeBlocks: activeBlocks, isDefaultMessage: isDefaultMessage, createNewBlock: function (params) {
                                    createNewBlock(__assign(__assign(__assign({}, messageParams), { blockId: blockIdParam, layer_rank: rank }), params)).catch(handleAPIErrors);
                                }, uploadImageMap: function () {
                                    return createImageMap(__assign(__assign({}, messageParams), { imageMapId: sectionMessage.image_map.id }));
                                }, onSubmitImageMapUpdate: updateImageMap, onSubmitUploadImage: uploadImageToImageMap, onSubmitAreaUpdate: function (params) {
                                    return updateImageMapAreas(__assign(__assign(__assign({}, messageParams), { imageMapId: sectionMessage.image_map.id }), params));
                                }, onSubmitVideoUpdate: uploadImageMapVideo, deleteHandler: function (params) {
                                    return deleteImageMap(__assign(__assign(__assign({}, messageParams), { imageMapId: sectionMessage.image_map.id, layerRank: rank, blockId: blockIdParam, chatTypeParam: chatTypeParam }), params));
                                } }, _this.props))));
                    case MessageType.RichMenu:
                        return (React.createElement("div", { key: sectionMessage.id + "-message-wrapper", "data-testid": "MessageSection-MessageRichmenuWrapper" },
                            React.createElement(RichMenuTemplateContainer, __assign({ key: sectionMessage.id + "-message" }, sectionMessage, { rank: rank, schema: schema, platform: platform, chatbotIdParam: chatbotIdParam, adScenarioIdParam: adScenarioIdParam, blockIdParam: blockIdParam, activeBlocks: activeBlocks, isDefaultMessage: isDefaultMessage, uploadRichMenu: function () {
                                    return uploadRichMenuToLineHandler(__assign(__assign({}, messageParams), { richMenuId: sectionMessage.rich_menu.id }));
                                }, richMenuUpdateHandler: richMenuUpdateHandler, richMenuUpdateImageHandler: richMenuUpdateImageHandler, richMenuAreaUpdateHandler: function (params) {
                                    return richMenuAreaUpdateHandler(__assign(__assign(__assign({}, messageParams), { richMenuId: sectionMessage.rich_menu.id }), params));
                                }, deleteHandler: function () {
                                    return richMenuDeleteHandler(__assign(__assign({}, messageParams), { richMenuId: sectionMessage.rich_menu.id, blockId: blockIdParam, layerRank: rank }));
                                }, createNewBlock: function (params) {
                                    createNewBlock(__assign(__assign(__assign({}, messageParams), { blockId: blockIdParam, layer_rank: rank }), params)).catch(handleAPIErrors);
                                } }, _this.props))));
                    default:
                        return null;
                }
            });
            return allMessagesOfSection;
        };
        return _this;
    }
    MessageSection.prototype.render = function () {
        var _a = this.props, id = _a.id, sectionMessages = _a.sectionMessages, messageSectionIterator = _a.messageSectionIterator, addNewMessageSectionMessage = _a.addNewMessageSectionMessage, setFlexTemplateModalOpen = _a.setFlexTemplateModalOpen, platform = _a.platform, setSubFlexMessageModalOpen = _a.setSubFlexMessageModalOpen;
        var limits = LIMITS[platform];
        var dropdownListOptions = [
            {
                label: 'JSONから追加',
                onClickItem: function (messageSectionId) {
                    return setSubFlexMessageModalOpen(true, messageSectionId);
                },
            },
            {
                label: 'テンプレから追加',
                onClickItem: function (messageSectionId) {
                    return setFlexTemplateModalOpen(true, messageSectionId);
                },
            },
        ];
        return (React.createElement(MessageSectionWrapper, { className: "message-section", "data-testid": "MessageSection-MessageSectionWrapper-" + messageSectionIterator, messageSectionIterator: messageSectionIterator },
            this.renderMessages(),
            hasMessageControls(sectionMessages, limits) && (React.createElement("div", { className: "message-controls", "data-testid": "MessageSection-MessageControls-" + messageSectionIterator },
                React.createElement(AddFilterMessageButton, { "data-testid": "message-section-add-filter-message-button", platform: platform, messageSectionId: id, onClickItem: addNewMessageSectionMessage, showDropdownListButtonType: messageTypes.FLEX_MESSAGE, dropdownListOptions: dropdownListOptions, buttonOptions: [
                        messageTypes.TEXT,
                        mediaTypes.AUDIO,
                        mediaTypes.IMAGE,
                        messageTypes.FLEX_MESSAGE,
                        mediaTypes.VIDEO,
                    ] })))));
    };
    return MessageSection;
}(Component));
export default injectIntl(MessageSection);
var templateObject_1;
