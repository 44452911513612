var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React from 'react';
import { injectIntl } from 'react-intl';
import styled from 'styled-components';
import * as washiColors from '@zeals-co-ltd/washi-styles';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Tooltip } from '@zeals-co-ltd/washi-components';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import ReviewAbTestingList from '@src/components/organisms/fanp/initialConversation/ReviewAbTestingList';
import { setInitialScenarios } from '@src/redux/fanp/actions/initialConversation';
import differenceBy from 'lodash/differenceBy';
import { useFetchActiveInitialConversationABTestGroups } from './hooks';
import { getABTestDetails } from './common';
var ReviewHeader = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n"], ["\n  display: flex;\n"])));
var Container = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  width: 100%;\n  justify-content: space-between;\n  gap: 1em;\n  & > div {\n    width: 50%;\n  }\n"], ["\n  display: flex;\n  width: 100%;\n  justify-content: space-between;\n  gap: 1em;\n  & > div {\n    width: 50%;\n  }\n"])));
var ActiveABTestingCartridge = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  background: #fff;\n  min-height: calc(100vh - 156px);\n  margin: 1em 0.5em 0 1em;\n  padding: 1em;\n  border-radius: 8px;\n\n  h1 {\n    margin: 0;\n    display: flex;\n    font-weight: bold;\n    color: ", ";\n    font-size: 19px;\n    line-height: 28px;\n    letter-spacing: 0.15px;\n  }\n  Button {\n    margin-left: auto;\n  }\n\n  .abtestgroupbtn {\n    margin-left: auto;\n    margin-bottom: 32px;\n  }\n"], ["\n  background: #fff;\n  min-height: calc(100vh - 156px);\n  margin: 1em 0.5em 0 1em;\n  padding: 1em;\n  border-radius: 8px;\n\n  h1 {\n    margin: 0;\n    display: flex;\n    font-weight: bold;\n    color: ", ";\n    font-size: 19px;\n    line-height: 28px;\n    letter-spacing: 0.15px;\n  }\n  Button {\n    margin-left: auto;\n  }\n\n  .abtestgroupbtn {\n    margin-left: auto;\n    margin-bottom: 32px;\n  }\n"])), washiColors.blue[900]);
var statusTypeValues;
(function (statusTypeValues) {
    statusTypeValues["ACTIVE"] = "Active";
    statusTypeValues["DRAFT"] = "Draft";
})(statusTypeValues || (statusTypeValues = {}));
var ActiveABTestingPanel = function (_a) {
    var intl = _a.intl, isInitialConversationActive = _a.isInitialConversationActive, onTabChange = _a.onTabChange;
    useFetchActiveInitialConversationABTestGroups();
    var _b = useSelector(function (state) {
        return {
            activeABTesting: state.initialConversation.ABTesting.active,
            allAvailableScenarios: state.initialConversation.scenarios,
            draftABTesting: state.initialConversation.ABTesting.draft,
            draftInitialConversation: state.initialConversation.getDraftInitialConversation,
        };
    }), activeABTesting = _b.activeABTesting, allAvailableScenarios = _b.allAvailableScenarios, draftABTesting = _b.draftABTesting, draftInitialConversation = _b.draftInitialConversation;
    var _c = getABTestDetails(activeABTesting, allAvailableScenarios), ABTestScenarios = _c.ABTestScenarios, scenarioVariantGroup = _c.scenarioVariantGroup;
    var ABTestDraftData = getABTestDetails(draftABTesting, allAvailableScenarios);
    var ABTestDraftScenarios = ABTestDraftData.ABTestScenarios;
    var _d = __read(React.useState(false), 2), open = _d[0], setOpen = _d[1];
    var handleTooltipClose = function () {
        setOpen(false);
    };
    var handleTooltipOpen = function () {
        setOpen(true);
    };
    var id = scenarioVariantGroup ? scenarioVariantGroup.id : '';
    var allAvailableScenario = useSelector(function (state) { return state.initialConversation.scenarios; });
    var dispatch = useDispatch();
    var ABTestScenarioObjMap = [];
    var newABTest = function () {
        var AvailableScenarios = differenceBy(allAvailableScenario, ABTestScenarioObjMap, 'id');
        dispatch(setInitialScenarios(AvailableScenarios));
        isInitialConversationActive(false);
    };
    return (React.createElement(ActiveABTestingCartridge, { "data-testid": "ActiveABTestingPanel" },
        React.createElement(ReviewHeader, null,
            React.createElement("h1", { "data-testid": "ABTestManagementPanel_Title" }, intl.formatMessage({ id: "R0e3gT", defaultMessage: "A/B Test management panel" })),
            draftInitialConversation ? (React.createElement(ClickAwayListener, { onClickAway: handleTooltipClose },
                React.createElement("div", { onClick: handleTooltipOpen, className: "abtestgroupbtn", role: "button", tabIndex: -1 },
                    React.createElement(Tooltip, { title: intl.formatMessage({ id: "H+sPzt", defaultMessage: "If a draft already exists, a new A/B test cannot be created." }), onClose: handleTooltipClose, open: open, leaveDelay: 2000, placement: "top", "data-testid": "ActiveABTestingPanel_tooltip", arrow: true },
                        React.createElement("div", null,
                            React.createElement(Button, { color: "primary", variant: "contained", onClick: newABTest, "data-testid": "ActiveABTestingPanel_NewABTestButton", disabled: true }, intl.formatMessage({ id: "htetlx", defaultMessage: "New A/B Test group" }))))))) : (React.createElement(Button, { color: "primary", variant: "contained", className: "abtestgroupbtn", onClick: newABTest, "data-testid": "ActiveABTestingPanel_NewABTestButton" }, intl.formatMessage({ id: "htetlx", defaultMessage: "New A/B Test group" })))),
        React.createElement(Container, null,
            React.createElement("div", null,
                React.createElement(ReviewAbTestingList, { type: statusTypeValues.ACTIVE, ABTestScenarios: ABTestScenarios, activeABTestingScenarios: activeABTesting, scenarioVariantId: id, onTabChange: onTabChange })),
            React.createElement("div", null,
                React.createElement(ReviewAbTestingList, { type: statusTypeValues.DRAFT, ABTestScenarios: ABTestDraftScenarios, activeABTestingScenarios: draftABTesting, isInitialConversationActive: isInitialConversationActive, onTabChange: onTabChange })))));
};
export default injectIntl(ActiveABTestingPanel);
var templateObject_1, templateObject_2, templateObject_3;
