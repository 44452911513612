var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Component } from 'react';
import styled from 'styled-components';
import { Button, Input } from '@src/components/atoms';
import buttonTypes from '@src/lib/buttonTypes';
import { centeredFlex } from '@src/lib/mixins';
import { injectIntl } from 'react-intl';
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  margin: 12px 0 16px;\n"], ["\n  ", ";\n  margin: 12px 0 16px;\n"])), centeredFlex);
var KeyValueContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  margin-bottom: 16px;\n  > div {\n    margin: 0 4px;\n  }\n"], ["\n  display: flex;\n  margin-bottom: 16px;\n  > div {\n    margin: 0 4px;\n  }\n"])));
var defaultProps = {
    onSubmit: function () { return undefined; },
};
var MetaHash = /** @class */ (function (_super) {
    __extends(MetaHash, _super);
    function MetaHash(props) {
        var _this = _super.call(this, props) || this;
        _this.initialiseState = function () {
            return _this.setState({
                metaKeyEdit: '',
                metaValueEdit: '',
                editActive: false,
            });
        };
        _this.KeyValueEdit = function () {
            var _a = _this.props, onSubmit = _a.onSubmit, intl = _a.intl;
            var _b = _this.state, metaKeyEdit = _b.metaKeyEdit, metaValueEdit = _b.metaValueEdit;
            var valid = metaKeyEdit && metaValueEdit;
            return (React.createElement(KeyValueContainer, null,
                React.createElement(Input, { type: "text", dataTest: "AttributeNameInput", placeholder: intl.formatMessage({ id: "DRMMDs", defaultMessage: "Attribute Name" }), className: "key", value: metaKeyEdit, onChange: function (e) { return _this.setState({ metaKeyEdit: e.target.value }); } }),
                React.createElement(Input, { type: "text", dataTest: "AttributeValueInput", placeholder: intl.formatMessage({ id: "KYprxY", defaultMessage: "Attribute Value" }), className: "value", value: metaValueEdit, onChange: function (e) { return _this.setState({ metaValueEdit: e.target.value }); } }),
                React.createElement(Button, { dataTest: "EditOrCancelButton", buttonType: valid ? buttonTypes.SAVE : buttonTypes.CANCEL, text: valid
                        ? intl.formatMessage({ id: "qDYGja", defaultMessage: "Save Settings" })
                        : intl.formatMessage({ id: "47FYwb", defaultMessage: "Cancel" }), onClick: function () {
                        onSubmit(metaKeyEdit, metaValueEdit);
                        _this.initialiseState();
                    } })));
        };
        _this.state = {
            metaKeyEdit: '',
            metaValueEdit: '',
            editActive: false,
        };
        return _this;
    }
    MetaHash.prototype.render = function () {
        var _this = this;
        var editActive = this.state.editActive;
        var intl = this.props.intl;
        return (React.createElement(Container, null,
            editActive && this.KeyValueEdit(),
            !editActive && (React.createElement("div", null,
                React.createElement(Button, { dataTest: "AddButton", buttonType: buttonTypes.SETTING, text: intl.formatMessage({ id: "2/2yg+", defaultMessage: "Add" }), onClick: function () { return _this.setState({ editActive: true }); } })))));
    };
    return MetaHash;
}(Component));
export default injectIntl(MetaHash);
var templateObject_1, templateObject_2;
