import "core-js/modules/es.array.concat";
export function text(message) {
  if (!message.id) return 'string';
  return "".concat(message.id).concat(message.button_template ? 'button-template' : 'text');
}
export function article(article, articleField) {
  return "".concat(article.id, "article-").concat(articleField);
}
export function flexMessage(flexMessage, flexMessageField) {
  return "".concat(flexMessage.id, "flex-message-").concat(flexMessageField);
}
export function buttonLabel(buttonId) {
  return "button-".concat(buttonId);
}
export function quickReply(quickReplyId) {
  return "quick-reply".concat(quickReplyId);
}
export function quickReplyChoice(quickReplyChoiceId) {
  return "quick-reply-choice".concat(quickReplyChoiceId);
}
export function talkTrigger(talkTriggerId) {
  return "talk_trigger".concat(talkTriggerId);
}
export function medium(message, articleField) {
  var type = message.medium ? 'medium' : 'medium';
  return "".concat(message.id).concat(type, "-message").concat(message.medium.id).concat(articleField);
}
export function imageMap(imageMap, imageMapField) {
  return "".concat(imageMap.id, "image-map-").concat(imageMapField);
}
export function richMenu(richMenu, richMenuField) {
  return "".concat(richMenu.id, "rich-menu-").concat(richMenuField);
}