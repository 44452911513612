var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { v4 as uuid } from 'uuid';
import moment from 'moment';
import { Form, FormElement, FormElementType, FormPage, FormStyles, FormCalendarType, FormCalendarRemoteType, FormElementTextUsage, FormCreditCardElement, FormSelectRepresentationType, FormBuildSegment, FormScenario, FormQuestion, FormTextValidation, FormNumericValidation, CompletionStatus, FormAutomationTrigger, } from 'zeals-protobuf/zeals/web/entities/form_pb';
import * as formPbEntities from 'zeals-protobuf/zeals/web/entities/form_pb';
import { Time, Date, Pagination, } from 'zeals-protobuf/zeals/web/entities/common_pb';
import { Record as RecordEntity } from 'zeals-protobuf/zeals/web/entities/entities_pb';
import * as google_protobuf_timestamp_pb from 'google-protobuf/google/protobuf/timestamp_pb';
import { StringValue, Int64Value, } from 'google-protobuf/google/protobuf/wrappers_pb';
import * as google_protobuf_wrappers_pb from 'google-protobuf/google/protobuf/wrappers_pb';
import { Question, QuestionType, } from 'zeals-protobuf/zeals/web/entities/message_pb';
import { ScenarioQuestionType, } from '@src/typings/entities/fanp/scenarioQuestions';
export function formElementTypeToString(dataType) {
    switch (dataType) {
        case FormElementType.FORM_ELEMENT_TYPE_TEXT:
            return 'テキスト (String)';
        case FormElementType.FORM_ELEMENT_TYPE_NUMERIC:
            return '数字 (Number)';
        case FormElementType.FORM_ELEMENT_TYPE_DATE_PICKER:
            return '期間 (DatePicker)';
        case FormElementType.FORM_ELEMENT_TYPE_TIME_PICKER:
            return '期間 (TimePicker)';
        case FormElementType.FORM_ELEMENT_TYPE_DATETIME_PICKER:
            return '期間 (DateTimePicker)';
        case FormElementType.FORM_ELEMENT_TYPE_SELECT:
            return '選択 (Select)';
        case FormElementType.FORM_ELEMENT_TYPE_CALENDAR:
            return 'Calendar';
        case FormElementType.FORM_ELEMENT_TYPE_CREDIT_CARD:
            return 'クレジットカード';
        case FormElementType.FORM_ELEMENT_TYPE_UNSPECIFIED:
            // TODO: is this correct?
            return 'Unspecified';
        default:
            return '';
    }
}
export function flattenFormElements(formElements, chatbotId) {
    return formElements.map(function (element) { return ({
        id: element.id,
        name: element.name,
        type: element.type,
        chatbotId: chatbotId,
    }); });
}
export function getFormElementContentKeyName(elementType) {
    switch (elementType) {
        case FormElementType.FORM_ELEMENT_TYPE_TEXT:
            return 'text';
        case FormElementType.FORM_ELEMENT_TYPE_CALENDAR:
            return 'calendar';
        case FormElementType.FORM_ELEMENT_TYPE_CREDIT_CARD:
            return 'creditCard';
        case FormElementType.FORM_ELEMENT_TYPE_DATETIME_PICKER:
            return 'datetimePicker';
        case FormElementType.FORM_ELEMENT_TYPE_DATE_PICKER:
            return 'datePicker';
        case FormElementType.FORM_ELEMENT_TYPE_NUMERIC:
            return 'numeric';
        case FormElementType.FORM_ELEMENT_TYPE_SELECT:
            return 'select';
        case FormElementType.FORM_ELEMENT_TYPE_TIME_PICKER:
            return 'timePicker';
        default:
            return '';
    }
}
/**
 *  oneof options {
 *    // Options for the `table` or `available` calendar types
 *    FormCalendarOption calendar = 20;
 *    // Options the `question` calendar type
 *    FormCalendarQuestionOption question = 21;
 *  }
 */
export function modifyCalendarFormElementByType(formElement, newCalendarType, chatbotId) {
    switch (newCalendarType) {
        case FormCalendarType.FORM_CALENDAR_TYPE_AVAILABLE: {
            var newFormElement = __assign({}, formElement);
            var formCalendarElement = {
                type: newCalendarType,
                remote: {
                    type: FormCalendarRemoteType.FORM_CALENDAR_REMOTE_TYPE_CACHE,
                    cache: {
                        chatbotId: chatbotId,
                    },
                    scrape: {
                        scrapePath: '',
                    },
                    dateFormat: '',
                },
                parentElementId: {
                    value: '',
                },
                calendar: {
                    limitDays: 0,
                    calendarDays: 0,
                    hasTimeSlot: true,
                },
            };
            newFormElement.calendar = formCalendarElement;
            return newFormElement;
        }
        case FormCalendarType.FORM_CALENDAR_TYPE_TABLE: {
            var newFormElement = __assign({}, formElement);
            var formCalendarElement = {
                type: newCalendarType,
                remote: {
                    type: FormCalendarRemoteType.FORM_CALENDAR_REMOTE_TYPE_CACHE,
                    cache: {
                        chatbotId: chatbotId,
                    },
                    scrape: {
                        scrapePath: '',
                    },
                    dateFormat: '',
                },
                parentElementId: {
                    value: '',
                },
                calendar: {
                    limitDays: 0,
                    calendarDays: 0,
                    hasTimeSlot: true,
                },
            };
            newFormElement.calendar = formCalendarElement;
            return newFormElement;
        }
        case FormCalendarType.FORM_CALENDAR_TYPE_QUESTION: {
            var newFormElement = __assign({}, formElement);
            var formCalendarElement = {
                type: newCalendarType,
                remote: {
                    type: FormCalendarRemoteType.FORM_CALENDAR_REMOTE_TYPE_CACHE,
                    cache: {
                        chatbotId: chatbotId,
                    },
                    scrape: {
                        scrapePath: '',
                    },
                    dateFormat: '',
                },
                parentElementId: {
                    value: '',
                },
                question: {
                    type: 0, // FormSelectRepresentationType
                },
            };
            newFormElement.calendar = formCalendarElement;
            return newFormElement;
        }
        default:
            return null;
    }
}
export function modifyFormElementByElementType(formElement, newElementType, chatbotId) {
    switch (newElementType) {
        case FormElementType.FORM_ELEMENT_TYPE_TEXT: {
            var formerTypeContentKeyName = getFormElementContentKeyName(formElement.type);
            if (formerTypeContentKeyName) {
                var newFormElement = __assign({}, formElement);
                newFormElement.text = {
                    placeholder: '',
                    usage: FormElementTextUsage.FORM_ELEMENT_TEXT_USAGE_UNSPECIFIED,
                    validation: {
                        regexp: '',
                    },
                };
                newFormElement[formerTypeContentKeyName] = undefined;
                newFormElement.type = newElementType;
                return newFormElement;
            }
            return null;
        }
        /**
         *  oneof options {
         *    // Options for the `table` or `available` calendar types
         *    FormCalendarOption calendar = 20;
         *    // Options the `question` calendar type
         *    FormCalendarQuestionOption question = 21;
         *  }
         */
        case FormElementType.FORM_ELEMENT_TYPE_CALENDAR: {
            var formerTypeContentKeyName = getFormElementContentKeyName(formElement.type);
            if (formerTypeContentKeyName) {
                var newFormElement = __assign({}, formElement);
                // TODO: Confirm the logic of calendar property(how to handle type inside of calendar obj)
                var formCalendarElement = {
                    type: FormCalendarType.FORM_CALENDAR_TYPE_AVAILABLE,
                    remote: {
                        type: FormCalendarRemoteType.FORM_CALENDAR_REMOTE_TYPE_CACHE,
                        cache: {
                            chatbotId: chatbotId,
                        },
                        scrape: {
                            scrapePath: '',
                        },
                        dateFormat: '',
                    },
                    parentElementId: {
                        value: '',
                    },
                    calendar: {
                        limitDays: 0,
                        calendarDays: 0,
                        hasTimeSlot: true,
                    },
                };
                newFormElement.calendar = formCalendarElement;
                newFormElement[formerTypeContentKeyName] = undefined;
                newFormElement.type = newElementType;
                return newFormElement;
            }
            return null;
        }
        case FormElementType.FORM_ELEMENT_TYPE_CREDIT_CARD: {
            var formerTypeContentKeyName = getFormElementContentKeyName(formElement.type);
            if (formerTypeContentKeyName) {
                var newFormElement = __assign({}, formElement);
                var formCreditCardElement = {
                    providersList: [],
                };
                newFormElement.creditCard = formCreditCardElement;
                newFormElement[formerTypeContentKeyName] = undefined;
                newFormElement.type = newElementType;
                return newFormElement;
            }
            return null;
        }
        case FormElementType.FORM_ELEMENT_TYPE_DATETIME_PICKER: {
            var formerTypeContentKeyName = getFormElementContentKeyName(formElement.type);
            if (formerTypeContentKeyName) {
                var newFormElement = __assign({}, formElement);
                var formDateTimePickerElement = {
                    disableBefore: {
                        seconds: 0,
                        nanos: 0,
                    },
                    disableAfter: {
                        seconds: 0,
                        nanos: 0,
                    },
                };
                newFormElement.datetimePicker = formDateTimePickerElement;
                newFormElement[formerTypeContentKeyName] = undefined;
                newFormElement.type = newElementType;
                return newFormElement;
            }
            return null;
        }
        case FormElementType.FORM_ELEMENT_TYPE_DATE_PICKER: {
            var formerTypeContentKeyName = getFormElementContentKeyName(formElement.type);
            if (formerTypeContentKeyName) {
                var newFormElement = __assign({}, formElement);
                var formDatePickerElement = {
                    disableBefore: {
                        year: 0,
                        month: 0,
                        day: 0,
                    },
                    disableAfter: {
                        year: 0,
                        month: 0,
                        day: 0,
                    },
                    openingDaysList: [],
                };
                newFormElement.datePicker = formDatePickerElement;
                newFormElement[formerTypeContentKeyName] = undefined;
                newFormElement.type = newElementType;
                return newFormElement;
            }
            return null;
        }
        case FormElementType.FORM_ELEMENT_TYPE_NUMERIC: {
            var formerTypeContentKeyName = getFormElementContentKeyName(formElement.type);
            if (formerTypeContentKeyName) {
                var newFormElement = __assign({}, formElement);
                var formNumericElement = {
                    step: 0,
                    pb_default: 0,
                    validation: {
                        min: 0,
                        max: 0,
                    },
                };
                newFormElement.numeric = formNumericElement;
                newFormElement[formerTypeContentKeyName] = undefined;
                newFormElement.type = newElementType;
                return newFormElement;
            }
            return null;
        }
        case FormElementType.FORM_ELEMENT_TYPE_SELECT: {
            var formerTypeContentKeyName = getFormElementContentKeyName(formElement.type);
            if (formerTypeContentKeyName) {
                var newFormElement = __assign({}, formElement);
                var formSelectElement = {
                    representation: 0,
                    optionsList: [
                        {
                            label: '',
                            value: '',
                        },
                        {
                            label: '',
                            value: '',
                        },
                    ],
                    editable: true,
                };
                newFormElement.select = formSelectElement;
                newFormElement[formerTypeContentKeyName] = undefined;
                newFormElement.type = newElementType;
                return newFormElement;
            }
            return null;
        }
        case FormElementType.FORM_ELEMENT_TYPE_TIME_PICKER: {
            var formerTypeContentKeyName = getFormElementContentKeyName(formElement.type);
            if (formerTypeContentKeyName) {
                var newFormElement = __assign({}, formElement);
                var formTimePickerElement = {
                    disableBefore: {
                        hours: 0,
                        minutes: 0,
                        seconds: 0,
                        nanos: 0,
                    },
                    disableAfter: {
                        hours: 0,
                        minutes: 0,
                        seconds: 0,
                        nanos: 0,
                    },
                };
                newFormElement.timePicker = formTimePickerElement;
                newFormElement[formerTypeContentKeyName] = undefined;
                newFormElement.type = newElementType;
                return newFormElement;
            }
            return null;
        }
        default:
            return null;
    }
}
// Backend does not provide a way to add a FormElement
// You must add the formElements on the frontend, and then sync to each form at some point
// This function exhaustively takes some formElement type and then returns a blank version
// of the formElement
export function addBlankFormElement(formElementType, templateId, chatbotId) {
    var id = uuid();
    switch (formElementType) {
        case FormElementType.FORM_ELEMENT_TYPE_TEXT: {
            var formTextElement = {
                placeholder: '',
                usage: FormElementTextUsage.FORM_ELEMENT_TEXT_USAGE_UNSPECIFIED,
                validation: {
                    regexp: '',
                },
            };
            var blankTextFormElement = {
                id: id,
                name: 'Default Name',
                description: '',
                type: formElementType,
                fieldId: '',
                required: false,
                templateId: templateId,
                text: formTextElement,
                numeric: undefined,
                select: undefined,
                creditCard: undefined,
                datePicker: undefined,
                timePicker: undefined,
                datetimePicker: undefined,
                calendar: undefined,
                errorMessage: '',
            };
            return blankTextFormElement;
        }
        case FormElementType.FORM_ELEMENT_TYPE_NUMERIC: {
            var formNumericElement = {
                step: 0,
                pb_default: 0,
                validation: {
                    min: 0,
                    max: 0,
                },
            };
            var blankNumericFormElement = {
                id: id,
                name: 'Default Name',
                description: '',
                type: formElementType,
                fieldId: '',
                required: false,
                templateId: templateId,
                text: undefined,
                numeric: formNumericElement,
                select: undefined,
                creditCard: undefined,
                datePicker: undefined,
                timePicker: undefined,
                datetimePicker: undefined,
                calendar: undefined,
                errorMessage: '',
            };
            return blankNumericFormElement;
        }
        case FormElementType.FORM_ELEMENT_TYPE_SELECT: {
            var formSelectElement = {
                representation: 0,
                optionsList: [
                    {
                        label: 'default option 1',
                        value: 'default option 1',
                    },
                    {
                        label: 'default option 2',
                        value: 'default option 2',
                    },
                ],
                editable: true,
            };
            var blankSelectFormElement = {
                id: id,
                name: 'Default Name',
                description: '',
                type: formElementType,
                fieldId: '',
                required: false,
                templateId: templateId,
                text: undefined,
                numeric: undefined,
                select: formSelectElement,
                creditCard: undefined,
                datePicker: undefined,
                timePicker: undefined,
                datetimePicker: undefined,
                calendar: undefined,
                errorMessage: '',
            };
            return blankSelectFormElement;
        }
        case FormElementType.FORM_ELEMENT_TYPE_CREDIT_CARD: {
            var formCreditCardElement = {
                providersList: [],
            };
            var blankCreditCardFormElement = {
                id: id,
                name: 'Default Name',
                description: '',
                type: formElementType,
                fieldId: '',
                required: false,
                templateId: templateId,
                text: undefined,
                numeric: undefined,
                select: undefined,
                creditCard: formCreditCardElement,
                datePicker: undefined,
                timePicker: undefined,
                datetimePicker: undefined,
                calendar: undefined,
                errorMessage: '',
            };
            return blankCreditCardFormElement;
        }
        case FormElementType.FORM_ELEMENT_TYPE_TIME_PICKER: {
            var formTimePickerElement = {
                disableBefore: {
                    hours: 0,
                    minutes: 0,
                    seconds: 0,
                    nanos: 0,
                },
                disableAfter: {
                    hours: 0,
                    minutes: 0,
                    seconds: 0,
                    nanos: 0,
                },
            };
            var blankTimePickerFormElement = {
                id: id,
                name: 'Default Name',
                description: '',
                type: formElementType,
                fieldId: '',
                required: false,
                templateId: templateId,
                text: undefined,
                numeric: undefined,
                select: undefined,
                creditCard: undefined,
                datePicker: undefined,
                timePicker: formTimePickerElement,
                datetimePicker: undefined,
                calendar: undefined,
                errorMessage: '',
            };
            return blankTimePickerFormElement;
        }
        case FormElementType.FORM_ELEMENT_TYPE_DATE_PICKER: {
            var formDatePickerElement = {
                disableBefore: {
                    year: 0,
                    month: 0,
                    day: 0,
                },
                disableAfter: {
                    year: 0,
                    month: 0,
                    day: 0,
                },
                openingDaysList: [],
            };
            var blankDatePickerFormElement = {
                id: id,
                name: 'Default Name',
                description: '',
                type: formElementType,
                fieldId: '',
                required: false,
                templateId: templateId,
                text: undefined,
                numeric: undefined,
                select: undefined,
                creditCard: undefined,
                datePicker: formDatePickerElement,
                timePicker: undefined,
                datetimePicker: undefined,
                calendar: undefined,
                errorMessage: '',
            };
            return blankDatePickerFormElement;
        }
        case FormElementType.FORM_ELEMENT_TYPE_DATETIME_PICKER: {
            var formDateTimePickerElement = {
                disableBefore: {
                    seconds: 0,
                    nanos: 0,
                },
                disableAfter: {
                    seconds: 0,
                    nanos: 0,
                },
            };
            var blankDateTimePickerFormElement = {
                id: id,
                name: 'Default Name',
                description: '',
                type: formElementType,
                fieldId: '',
                required: false,
                templateId: templateId,
                text: undefined,
                numeric: undefined,
                select: undefined,
                creditCard: undefined,
                datePicker: undefined,
                timePicker: undefined,
                datetimePicker: formDateTimePickerElement,
                calendar: undefined,
                errorMessage: '',
            };
            return blankDateTimePickerFormElement;
        }
        /**
         *  oneof options {
         *    // Options for the `table` or `available` calendar types
         *    FormCalendarOption calendar = 20;
         *    // Options the `question` calendar type
         *    FormCalendarQuestionOption question = 21;
         *  }
         */
        case FormElementType.FORM_ELEMENT_TYPE_CALENDAR: {
            // TODO: Confirm the logic of calendar property(how to handle type inside of calendar obj)
            var formCalendarElement = {
                type: FormCalendarType.FORM_CALENDAR_TYPE_QUESTION,
                remote: {
                    type: FormCalendarRemoteType.FORM_CALENDAR_REMOTE_TYPE_SCRAPE,
                    cache: undefined,
                    scrape: {
                        scrapePath: '',
                    },
                    dateFormat: '',
                },
                parentElementId: {
                    value: '',
                },
                calendar: undefined,
                question: {
                    type: FormSelectRepresentationType.FORM_SELECT_REPRESENTATION_TYPE_SELECT,
                },
            };
            var blankCalendarFormElement = {
                id: id,
                name: 'Default Name',
                description: '',
                type: formElementType,
                fieldId: '',
                required: false,
                templateId: templateId,
                text: undefined,
                numeric: undefined,
                select: undefined,
                creditCard: undefined,
                datePicker: undefined,
                timePicker: undefined,
                datetimePicker: undefined,
                calendar: formCalendarElement,
                errorMessage: '',
            };
            return blankCalendarFormElement;
        }
        case FormElementType.FORM_ELEMENT_TYPE_UNSPECIFIED:
            return null;
        default:
            return null;
    }
}
export function styleFromObj(styleObj) {
    var style = new FormStyles();
    // Default string for Theme Color for now
    style.setThemeColor(styleObj.themeColor || 'defaultThemeColor');
    return style;
}
export function formNumericValidationFromObj(obj) {
    var formNumericValidation = new formPbEntities.FormNumericValidation();
    formNumericValidation.setMax(obj.max);
    formNumericValidation.setMin(obj.min);
    return formNumericValidation;
}
export function formNumericElementFromObj(obj) {
    var formNumericElement = new formPbEntities.FormNumericElement();
    formNumericElement.setDefault(obj.pb_default);
    formNumericElement.setStep(obj.step);
    formNumericElement.setValidation(formNumericValidationFromObj(obj.validation));
    return formNumericElement;
}
export function formSelectOptionFromObj(obj) {
    var formSelectOption = new formPbEntities.FormSelectOption();
    formSelectOption.setValue(obj.value);
    formSelectOption.setLabel(obj.label);
    return formSelectOption;
}
export function formSelectElementFromObj(obj) {
    var formSelectElement = new formPbEntities.FormSelectElement();
    formSelectElement.setEditable(obj.editable);
    formSelectElement.setRepresentation(obj.representation);
    formSelectElement.setOptionsList(obj.optionsList.map(formSelectOptionFromObj));
    return formSelectElement;
}
export function formTextValidationFromObj(obj) {
    var formTextValidation = new formPbEntities.FormTextValidation();
    formTextValidation.setRegexp(obj.regexp);
    return formTextValidation;
}
export function formTextElementFromObj(obj) {
    var formTextElement = new formPbEntities.FormTextElement();
    formTextElement.setUsage(obj.usage);
    formTextElement.setPlaceholder(obj.placeholder);
    formTextElement.setValidation(formTextValidationFromObj(obj.validation));
    return formTextElement;
}
export function timeFromObj(obj) {
    var time = new Time();
    time.setHours(obj.hours);
    time.setMinutes(obj.minutes);
    time.setNanos(obj.nanos);
    time.setSeconds(obj.seconds);
    return time;
}
export function formTimePickerElementFromObj(obj) {
    var formTimePickerElement = new formPbEntities.FormTimePickerElement();
    formTimePickerElement.setDisableBefore(timeFromObj(obj.disableBefore));
    formTimePickerElement.setDisableAfter(timeFromObj(obj.disableAfter));
    return formTimePickerElement;
}
export function dateFromObj(obj) {
    var date = new Date();
    date.setYear(obj.year);
    date.setMonth(obj.month);
    date.setDay(obj.day);
    return date;
}
export function timestampFromObj(obj) {
    var timestamp = new google_protobuf_timestamp_pb.Timestamp();
    timestamp.setNanos(obj.nanos);
    timestamp.setSeconds(obj.seconds);
    return timestamp;
}
export function displayStatusText(status, intl) {
    switch (status) {
        case CompletionStatus.COMPLETION_STATUS_COMPLETE:
            return intl.formatMessage({ id: "BxbTnL", defaultMessage: "\u8A2D\u5B9A\u5B8C\u4E86" });
        case CompletionStatus.COMPLETION_STATUS_ERROR:
            return intl.formatMessage({ id: "eUo0C4", defaultMessage: "\u30A8\u30E9\u30FC" });
        default:
            return intl.formatMessage({ id: "e7defV", defaultMessage: "\u4F5C\u6210\u4E2D" });
    }
}
export function dateFromTimeStampObj(obj, formatStr) {
    if (formatStr === void 0) { formatStr = 'YYYY-MM-DD'; }
    var timeStampEntity = new google_protobuf_timestamp_pb.Timestamp();
    timeStampEntity.setNanos(obj.nanos);
    timeStampEntity.setSeconds(obj.seconds);
    return moment(timeStampEntity.toDate()).format(formatStr);
}
export function formDatePickerElementFromObj(obj) {
    var formDatePickerElement = new formPbEntities.FormDatePickerElement();
    formDatePickerElement.setDisableBefore(dateFromObj(obj.disableBefore));
    formDatePickerElement.setDisableAfter(dateFromObj(obj.disableAfter));
    formDatePickerElement.setOpeningDaysList(obj.openingDaysList);
    return formDatePickerElement;
}
export function formCalendarRemoteFromObj(obj) {
    var formCalendarRemote = new formPbEntities.FormCalendarRemote();
    formCalendarRemote.setType(obj.type);
    switch (obj.type) {
        case FormCalendarRemoteType.FORM_CALENDAR_REMOTE_TYPE_CACHE:
            formCalendarRemote.setCache(FormCalendarRemoteCacheOptionFromObj(obj.cache));
            break;
        case FormCalendarRemoteType.FORM_CALENDAR_REMOTE_TYPE_SCRAPE:
            formCalendarRemote.setScrape(FormCalendarRemoteScrapeOptionFromObj(obj.scrape));
            break;
        default:
            console.error('Unspecified remote service.');
            break;
    }
    return formCalendarRemote;
}
export function FormCalendarRemoteScrapeOptionFromObj(obj) {
    var formCalendarRemoteScrapeOption = new formPbEntities.FormCalendarRemoteScrapeOption();
    formCalendarRemoteScrapeOption.setScrapePath(obj.scrapePath);
    return formCalendarRemoteScrapeOption;
}
export function FormCalendarRemoteCacheOptionFromObj(obj) {
    var formCalendarRemoteCacheOption = new formPbEntities.FormCalendarRemoteCacheOption();
    formCalendarRemoteCacheOption.setChatbotId(obj.chatbotId);
    return formCalendarRemoteCacheOption;
}
export function FormCalendarOptionFromObj(obj) {
    var formCalendarOption = new formPbEntities.FormCalendarOption();
    formCalendarOption.setCalendarDays(obj.calendarDays);
    formCalendarOption.setHasTimeSlot(obj.hasTimeSlot);
    return formCalendarOption;
}
export function FormCalendarQuestionOptionFromObj(obj) {
    var formCalendarQuestionOption = new formPbEntities.FormCalendarQuestionOption();
    formCalendarQuestionOption.setType(obj.type);
    return formCalendarQuestionOption;
}
export function stringValueFromObj(obj) {
    var stringValue = new StringValue();
    stringValue.setValue(obj.value);
    return stringValue;
}
export function formCalendarElementFromObj(obj) {
    var formCalendarElement = new formPbEntities.FormCalendarElement();
    formCalendarElement.setType(obj.type);
    formCalendarElement.setRemote(formCalendarRemoteFromObj(obj.remote));
    if (obj.parentElementId) {
        formCalendarElement.setParentElementId(stringValueFromObj(obj.parentElementId));
    }
    switch (obj.type) {
        case FormCalendarType.FORM_CALENDAR_TYPE_AVAILABLE:
        case FormCalendarType.FORM_CALENDAR_TYPE_TABLE:
            if (obj.calendar)
                formCalendarElement.setCalendar(FormCalendarOptionFromObj(obj.calendar));
            break;
        case FormCalendarType.FORM_CALENDAR_TYPE_QUESTION:
            if (obj.question)
                formCalendarElement.setQuestion(FormCalendarQuestionOptionFromObj(obj.question));
            break;
        default:
            console.error('unspecified calendar_type should result in an error');
            break;
    }
    return formCalendarElement;
}
export function formDatetimePickerElementFromObj(obj) {
    var formDatetimePickerElement = new formPbEntities.FormDatetimePickerElement();
    formDatetimePickerElement.setDisableAfter(timestampFromObj(obj.disableAfter));
    formDatetimePickerElement.setDisableBefore(timestampFromObj(obj.disableBefore));
    return formDatetimePickerElement;
}
export function formCreditCardElementFromObj(obj) {
    var creditCardElement = new FormCreditCardElement();
    creditCardElement.setProvidersList(obj.providersList);
    return creditCardElement;
}
export function elementFromObj(elObj) {
    var formElement = new FormElement();
    formElement.setId(elObj.id);
    formElement.setName(elObj.name);
    formElement.setRequired(elObj.required);
    formElement.setTemplateId(elObj.templateId);
    formElement.setType(elObj.type);
    formElement.setDescription(elObj.description);
    formElement.setFieldId(elObj.fieldId);
    formElement.setErrorMessage(elObj.errorMessage);
    switch (elObj.type) {
        case FormElementType.FORM_ELEMENT_TYPE_TEXT:
            formElement.setText(formTextElementFromObj(elObj.text));
            break;
        case FormElementType.FORM_ELEMENT_TYPE_NUMERIC:
            formElement.setNumeric(formNumericElementFromObj(elObj.numeric));
            break;
        case FormElementType.FORM_ELEMENT_TYPE_SELECT:
            formElement.setSelect(formSelectElementFromObj(elObj.select));
            break;
        case FormElementType.FORM_ELEMENT_TYPE_TIME_PICKER:
            formElement.setTimePicker(formTimePickerElementFromObj(elObj.timePicker));
            break;
        case FormElementType.FORM_ELEMENT_TYPE_DATETIME_PICKER:
            formElement.setDatetimePicker(formDatetimePickerElementFromObj(elObj.datetimePicker));
            break;
        case FormElementType.FORM_ELEMENT_TYPE_DATE_PICKER:
            formElement.setDatePicker(formDatePickerElementFromObj(elObj.datePicker));
            break;
        case FormElementType.FORM_ELEMENT_TYPE_CREDIT_CARD:
            formElement.setCreditCard(formCreditCardElementFromObj(elObj.creditCard));
            break;
        case FormElementType.FORM_ELEMENT_TYPE_CALENDAR:
            formElement.setCalendar(formCalendarElementFromObj(elObj.calendar));
            break;
        default:
            console.error('unspecified form_element');
            break;
    }
    return formElement;
}
export function formPageFromObj(formPageObj) {
    var formPage = new FormPage();
    formPage.setElementsList(formPageObj.elementsList.map(elementFromObj));
    return formPage;
}
export function recordFromObj(obj) {
    var record = new RecordEntity();
    record.setCreateTime(timestampFromObj(obj.createTime));
    record.setUpdateTime(timestampFromObj(obj.updateTime));
    return record;
}
export function formFromObj(formObj) {
    var form = new Form();
    form.setId(formObj.id);
    form.setFormBuildId(formObj.formBuildId);
    form.setName(formObj.name);
    form.setFormBuildId(formObj.formBuildId);
    form.setRecord(recordFromObj(formObj.record));
    form.setTemplateId(formObj.templateId);
    form.setUsage(formObj.usage);
    form.setLogoUri(formObj.logoUri || '');
    form.setPagesList(formObj.pagesList.map(formPageFromObj));
    form.setTriggerFormAutomation(formObj.triggerFormAutomation);
    form.setStyles(styleFromObj(formObj.styles));
    form.setCompletionStatus(formObj.completionStatus);
    return form;
}
export function int64ValueFromObj(obj) {
    var int64Value = new Int64Value();
    int64Value.setValue(obj.value);
    return int64Value;
}
export function formQuestionsFromObj(obj) {
    var formQuestion = new FormQuestion();
    formQuestion.setId(obj.id);
    formQuestion.setFormBuildId(obj.formBuildId);
    formQuestion.setTriggerFormAutomation(obj.triggerFormAutomation);
    if (obj.scenarioQuestion) {
        formQuestion.setScenarioQuestion(questionFromObj(obj.scenarioQuestion));
    }
    if (obj === null || obj === void 0 ? void 0 : obj.textValidation) {
        formQuestion.setTextValidation(formQuestionTextValidationFromObj(obj.textValidation));
    }
    if (obj === null || obj === void 0 ? void 0 : obj.numericValidation) {
        formQuestion.setNumericValidation(formQuestionNumericValidationFromObj(obj.numericValidation));
    }
    return formQuestion;
}
export function formBuildSegmentFormScenarioFromObj(obj) {
    var formScenario = new FormScenario();
    formScenario.setId(obj.id);
    // TODO: Uncomment this when backend makes the change to allow update Form Build without Form Scenario name
    // formScenario.setName(obj.name);
    formScenario.setName('フォーム会話');
    formScenario.setScenarioId(obj.scenarioId);
    formScenario.setFormBuildId(obj.formBuildId);
    formScenario.setCompletionStatus(obj.completionStatus);
    formScenario.setFormQuestionsList(obj.formQuestionsList.map(formQuestionsFromObj));
    return formScenario;
}
export function formBuildSegmentFromObj(obj) {
    var formBuildSegment = new FormBuildSegment();
    formBuildSegment.setType(obj.type);
    formBuildSegment.setName(obj.name);
    if (obj.formScenario) {
        formBuildSegment.setFormScenario(formBuildSegmentFormScenarioFromObj(obj.formScenario));
    }
    if (obj.webForm) {
        formBuildSegment.setWebForm(formFromObj(obj.webForm));
    }
    return formBuildSegment;
}
export function paginationFromObj(obj) {
    var pagination = new Pagination();
    pagination.setLimit(obj.limit);
    pagination.setOffset(obj.offset);
    return pagination;
}
export function questionFromScenarioQuestion(obj) {
    var question = new Question();
    question.setId(obj.id);
    question.setScenarioId(obj.scenarioId);
    question.setType(questionTypeFromScenarioQuestionType(obj.type));
    question.setFieldId(obj.fieldId);
    question.setText(obj.text);
    if (obj.type === ScenarioQuestionType.QUESTION_TYPE_QUICK_REPLY &&
        obj.responseId) {
        var quickReplyId = new google_protobuf_wrappers_pb.Int64Value();
        quickReplyId.setValue(obj.responseId);
        question.setQuickReplyId(quickReplyId);
    }
    if (obj.type === ScenarioQuestionType.QUESTION_TYPE_TALK_TRIGGER &&
        obj.responseId) {
        var talkTriggerId = new google_protobuf_wrappers_pb.Int64Value();
        talkTriggerId.setValue(obj.responseId);
        question.setTalkTriggerId(talkTriggerId);
    }
    return question;
}
export function questionFromObj(scenarioQuestion) {
    var question = new Question();
    question.setId(scenarioQuestion.id);
    question.setScenarioId(scenarioQuestion.scenarioId);
    question.setType(scenarioQuestion.type);
    question.setFieldId(scenarioQuestion.fieldId);
    question.setText(scenarioQuestion.text);
    if (scenarioQuestion.quickReplyId) {
        var quickReplyId = new google_protobuf_wrappers_pb.Int64Value();
        quickReplyId.setValue(scenarioQuestion.quickReplyId.value);
        question.setQuickReplyId(quickReplyId);
    }
    if (scenarioQuestion.talkTriggerId) {
        var talkTriggerId = new google_protobuf_wrappers_pb.Int64Value();
        talkTriggerId.setValue(scenarioQuestion.talkTriggerId.value);
        question.setTalkTriggerId(talkTriggerId);
    }
    return question;
}
export function formQuestionTextValidationFromObj(validation) {
    var formTextValidation = new FormTextValidation();
    formTextValidation.setRegexp(validation.regexp);
    return formTextValidation;
}
export function formQuestionNumericValidationFromObj(validation) {
    var numericValidation = new FormNumericValidation();
    numericValidation.setMin(validation.min);
    numericValidation.setMax(validation.max);
    return numericValidation;
}
export function formQuestionFromObj(value) {
    var formQuestion = new FormQuestion();
    formQuestion.setId(value === null || value === void 0 ? void 0 : value.id);
    formQuestion.setFormBuildId(value === null || value === void 0 ? void 0 : value.formBuildId);
    formQuestion.setTriggerFormAutomation(value === null || value === void 0 ? void 0 : value.triggerFormAutomation);
    if (value === null || value === void 0 ? void 0 : value.scenarioQuestion) {
        formQuestion.setScenarioQuestion(questionFromObj(value.scenarioQuestion));
    }
    if (value === null || value === void 0 ? void 0 : value.textValidation) {
        formQuestion.setTextValidation(formQuestionTextValidationFromObj(value.textValidation));
    }
    if (value === null || value === void 0 ? void 0 : value.numericValidation) {
        formQuestion.setNumericValidation(formQuestionNumericValidationFromObj(value.numericValidation));
    }
    if (value.errorMessage) {
        formQuestion.setErrorMessage(value.errorMessage);
    }
    return formQuestion;
}
export function questionTypeFromScenarioQuestionType(type) {
    var questionType;
    if (type === ScenarioQuestionType.QUESTION_TYPE_QUICK_REPLY) {
        questionType = QuestionType.QUESTION_TYPE_QUICK_REPLY;
    }
    if (type === ScenarioQuestionType.QUESTION_TYPE_TALK_TRIGGER) {
        questionType = QuestionType.QUESTION_TYPE_TALK_TRIGGER;
    }
    return questionType;
}
export function formAutomationTriggerFromObj(obj) {
    var formAutomationTrigger = new FormAutomationTrigger();
    formAutomationTrigger.setType(obj.type);
    if (obj.formQuestionId) {
        formAutomationTrigger.setFormQuestionId(stringValueFromObj(obj.formQuestionId));
    }
    if (obj.webFormId) {
        formAutomationTrigger.setWebFormId(stringValueFromObj(obj.webFormId));
    }
    return formAutomationTrigger;
}
