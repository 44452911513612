var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import changeItem from './changeItem';
/**
 * This method will remove duplicate block from scenario, it will keep block
 * on first layer contain
 *
 * @params scenario - is scenario data
 */
export default function (scenario) {
    var existingBlockIds = [];
    return changeItem(scenario, 'layers', function (layers) {
        return layers
            .map(function (layer) {
            var newLayer = __assign(__assign({}, layer), { blocks: layer.blocks.filter(function (block) { return !existingBlockIds.includes(block.id); }) });
            existingBlockIds = existingBlockIds.concat(newLayer.blocks.map(function (_a) {
                var id = _a.id;
                return id;
            }));
            return newLayer.blocks.length ? newLayer : null;
        })
            .filter(Boolean);
    });
}
