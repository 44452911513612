import "core-js/modules/es.string.small";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
import React from 'react';
import styled from 'styled-components';
import { colors, shadows } from '@src/colors';
import { hoverCss } from '@src/lib/mixins';
var TitleWrapper = styled.div.withConfig({
  displayName: "TitleWrapper",
  componentId: "sc-1cnu9ok-0"
})(["display:flex;justify-content:space-between;align-items:center;padding:1rem 2rem 1rem 2rem;background:", ";color:", ";font-weight:bold;box-shadow:", ";.clickable{", ";}.plugin_content_spinner{width:33px;height:33px;margin:0 2.5rem 0 0;.spinner{width:33px;height:33px;margin:0;top:unset;left:unset;}}"], colors.background.help, colors.white, shadows.small.mid, hoverCss);

var ChatlinkHeader = function ChatlinkHeader(_ref) {
  var text = _ref.text,
      children = _ref.children,
      rest = _objectWithoutProperties(_ref, ["text", "children"]);

  return React.createElement(TitleWrapper, rest, text, children);
};

export default ChatlinkHeader;