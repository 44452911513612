var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import { colors } from '@src/colors';
var ButtonTemplateMessageContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin: 20px 10px;\n  border-radius: 16px;\n  width: 260px;\n  border: 1px solid ", ";\n  overflow: hidden;\n\n  .text {\n    max-height: 500px;\n    padding: 8px;\n    word-break: break-all;\n    white-space: break-spaces;\n    overflow: hidden;\n    background: ", ";\n  }\n\n  .button {\n    text-align: center;\n    padding: 8px;\n    border-top: solid 1px ", ";\n  }\n"], ["\n  margin: 20px 10px;\n  border-radius: 16px;\n  width: 260px;\n  border: 1px solid ", ";\n  overflow: hidden;\n\n  .text {\n    max-height: 500px;\n    padding: 8px;\n    word-break: break-all;\n    white-space: break-spaces;\n    overflow: hidden;\n    background: ", ";\n  }\n\n  .button {\n    text-align: center;\n    padding: 8px;\n    border-top: solid 1px ", ";\n  }\n"])), colors.border.default, colors.background.off_white_background, colors.border.default);
var ButtonTemplateNode = function (_a) {
    var _b = _a.data, message = _b.message, updateBoundaryNode = _b.updateBoundaryNode;
    return (React.createElement(ButtonTemplateMessageContainer, { key: "button-template-" + message.button_template.id, "data-testid": "ButtonTemplateNode_ButtonTemplateMessageContainer" },
        React.createElement("div", { className: "text" }, message.button_template.title || 'No button title'),
        message.button_template.button_items.map(function (buttonItem) { return (React.createElement("div", { key: "button-item-" + buttonItem.id, className: "button", role: "button", ref: function (ref) {
                if (!ref ||
                    !updateBoundaryNode ||
                    !buttonItem.button.postback_action)
                    return;
                updateBoundaryNode('postbackActions', buttonItem.button.postback_action.id, ref.getBoundingClientRect());
            } }, buttonItem.button.name)); })));
};
export default ButtonTemplateNode;
var templateObject_1;
