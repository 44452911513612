var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import { useState } from 'react';
// https://codesandbox.io/s/924217jvro Accordion Example
var Accordion = function (_a) {
    var children = _a.children, stateReducer = _a.stateReducer, openIndexes = _a.openIndexes, initTabIndex = _a.initTabIndex;
    var _b = __read(useState({
        openIndexes: [initTabIndex || 0],
    }), 2), state = _b[0], setState = _b[1];
    function getState(s) {
        if (s === void 0) { s = state; }
        // gets the current state of the Accordion
        return {
            openIndexes: !openIndexes ? s.openIndexes : openIndexes,
        };
    }
    function internalSetState(changes) {
        // this is a custom setState which sets state based on the stateReducer that gets passed through props
        var allChanges;
        setState(function (state) {
            var actualState = getState(state);
            var changesObject = typeof changes === 'function' ? changes(actualState) : changes;
            allChanges = stateReducer(actualState, changesObject);
            return allChanges;
        });
    }
    var handleItemClick = function (index) {
        // on item click, sets the new state
        internalSetState(function (state) {
            var closing = state.openIndexes.includes(index);
            return {
                type: closing ? 'closing' : 'opening',
                openIndexes: closing
                    ? state.openIndexes.filter(function (i) { return i !== index; })
                    : __spreadArray(__spreadArray([], __read(state.openIndexes)), [index]),
            };
        });
    };
    return children({
        openIndexes: getState().openIndexes,
        handleItemClick: handleItemClick,
    });
};
export default Accordion;
