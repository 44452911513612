import { chatTypes } from '@src/lib/chat-types';
import featureFlags from '@src/lib/feature_flags/featureFlags';
import LIMITS from '@src/lib/platforms/limitations';
var createMainMenu = function (chatbotId, intl, limits) {
    return {
        icon: 'adicon_menu_main',
        url: "/dashboard/ad/" + chatTypes.INITIAL + "/chatbots/" + chatbotId,
        navName: {
            message: intl.formatMessage({ id: "Tqxx1H", defaultMessage: "Main Scenario" }),
        },
        keywords: [chatTypes.INITIAL, chatTypes.FORM, chatTypes.PATTERN],
        children: [
            limits.INITIAL_CONVERSATION_ENABLED && {
                message: intl.formatMessage({ id: "wuFAhS", defaultMessage: "Initial Conversation" }),
                url: "/dashboard/ad/" + chatTypes.INITIAL + "/chatbots/" + chatbotId,
            },
            limits.FORM_CONVERSATION_ENABLED && {
                message: intl.formatMessage({ id: "+cq91e", defaultMessage: "Form Conversation" }),
                url: "/dashboard/ad/" + chatTypes.FORM + "/chatbots/" + chatbotId,
            },
            limits.PATTERN_CONVERSATION_ENABLED && {
                message: intl.formatMessage({ id: "N2GMeZ", defaultMessage: "Pattern Conversation" }),
                url: "/dashboard/ad/" + chatTypes.PATTERN + "/chatbots/" + chatbotId,
            },
        ].filter(Boolean),
    };
};
var createPersonalizeMenu = function (chatbotId, intl, limits) {
    var children = [
        featureFlags.TRACK_PUSH_V2_SHOWN &&
            limits.TRACK_PUSH_V1_ENABLED && {
            message: intl.formatMessage({ id: "sGx/7E", defaultMessage: "New Track Push Delivery" }),
            url: "/dashboard/ad/chatbots/" + chatbotId + "/tracks",
        },
        featureFlags.TRACK_PUSH_V1_SHOWN &&
            limits.TRACK_PUSH_V2_ENABLED && {
            message: intl.formatMessage({ id: "kPtaeY", defaultMessage: "Track Push Delivery" }),
            url: "/dashboard/ad/chatbots/" + chatbotId + "/" + chatTypes.RETENTION + "/target",
        },
        limits.SHOT_CONVERSATION_ENABLED && {
            message: intl.formatMessage({ id: "O7jBLd", defaultMessage: "Shot Delivery" }),
            url: "/dashboard/ad/" + chatTypes.SHOT + "/chatbots/" + chatbotId,
        },
    ].filter(Boolean);
    if (!children.length)
        return null;
    return {
        icon: 'adicon_menu_personalize',
        url: children[0].url,
        navName: {
            message: intl.formatMessage({ id: "Ls1V02", defaultMessage: "Personalized Delivery" }),
        },
        keywords: ['tracks', 'retention', 'shot', chatTypes.STEP],
        children: children,
    };
};
var createUserMenu = function (chatbotId, intl, limits) { return ({
    icon: 'adicon_menu_userdatabase',
    url: "/dashboard/ad/chatbots/" + chatbotId + "/userdb",
    navName: {
        message: intl.formatMessage({ id: "YDMrKK", defaultMessage: "Users" }),
    },
    keywords: [
        'userdb',
        'talk',
        'field-attributes',
        'user-labels',
        'user-segment',
    ],
    children: [
        limits.USER_DB_ENABLED && {
            message: intl.formatMessage({ id: "Ui1suJ", defaultMessage: "User Database" }),
            url: "/dashboard/ad/chatbots/" + chatbotId + "/userdb",
        },
        limits.TALKROOM_ENABLED && {
            message: intl.formatMessage({ id: "lPKI/x", defaultMessage: "Talk Room" }),
            url: "/dashboard/ad/chatbots/" + chatbotId + "/talk",
        },
        featureFlags.UUD_FIELD_ATTRIBUTES &&
            limits.FIELD_ATTRIBUTES && {
            message: 'Field Attributes',
            url: "/dashboard/ad/chatbots/" + chatbotId + "/field-attributes",
        },
        featureFlags.UUD_USER_LABEL &&
            limits.USER_LABEL && {
            message: intl.formatMessage({ id: "cwZdW6", defaultMessage: "User Label" }),
            url: "/dashboard/ad/chatbots/" + chatbotId + "/user-labels",
        },
        featureFlags.UUD_USER_SEGMENT &&
            limits.USER_SEGMENT && {
            message: intl.formatMessage({ id: "PU5M9+", defaultMessage: "User Segment" }),
            url: "/dashboard/ad/chatbots/" + chatbotId + "/usersegment",
        },
    ].filter(Boolean),
}); };
var createChatlinksMenu = function (chatbotId, intl) { return ({
    icon: 'adicon_big_link',
    url: "/dashboard/ad/chatbots/" + chatbotId + "/chatlinks",
    keywords: ['chatlinks'],
    navName: {
        message: intl.formatMessage({ id: "OKyeMu", defaultMessage: "Chatlinks" }),
    },
}); };
var createSettingsMenu = function (chatbotId, intl) { return ({
    icon: 'adicon_menu_setting',
    url: "/dashboard/ad/chatbots/" + chatbotId + "/settings",
    keywords: ['settings'],
    navName: {
        message: intl.formatMessage({ id: "D3idYv", defaultMessage: "Settings" }),
    },
}); };
var createRPAMenu = function (chatbotId, intl) { return ({
    icon: 'adicon_userdata',
    url: "/dashboard/ad/chatbots/" + chatbotId + "/rpa-settings",
    keywords: [
        'rpa-settings',
        'form-builder',
        'form-templates',
        'webform-setting',
        'form-automation',
    ],
    navName: {
        message: intl.formatMessage({ id: "amPwqj", defaultMessage: "RPA Settings" }),
    },
}); };
export var createNavItems = function (chatbotId, intl, platform) {
    var limits = LIMITS[platform];
    if (!limits)
        return [];
    return [
        createMainMenu(chatbotId, intl, limits),
        createPersonalizeMenu(chatbotId, intl, limits),
        createUserMenu(chatbotId, intl, limits),
        limits.CHATLINKS_ENABLED && createChatlinksMenu(chatbotId, intl),
        createSettingsMenu(chatbotId, intl),
        createRPAMenu(chatbotId, intl),
    ].filter(Boolean);
};
