var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import { colors, shadows } from '@src/colors';
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  top: 50px;\n  right: 50px;\n  position: fixed;\n  display: inline-block;\n  padding: 2rem;\n  color: ", ";\n  background: ", ";\n  box-shadow: ", ";\n  border-radius: 10px;\n  z-index: 20;\n  -webkit-transition: 0.5s;\n  -moz-transition: 0.5s;\n  -o-transition: 0.5s;\n  transition: 0.5s;\n  span {\n    padding-top: 5px;\n    display: inline-block;\n  }\n"], ["\n  top: 50px;\n  right: 50px;\n  position: fixed;\n  display: inline-block;\n  padding: 2rem;\n  color: ", ";\n  background: ", ";\n  box-shadow: ", ";\n  border-radius: 10px;\n  z-index: 20;\n  -webkit-transition: 0.5s;\n  -moz-transition: 0.5s;\n  -o-transition: 0.5s;\n  transition: 0.5s;\n  span {\n    padding-top: 5px;\n    display: inline-block;\n  }\n"])), colors.text_pale, colors.background.main_menu, shadows.medium.light);
var NotificationMessage = function (_a) {
    var children = _a.children, iconName = _a.iconName, className = _a.className;
    return (React.createElement(Container, { className: className, "data-testid": "NotificationMessageContainer" },
        iconName && React.createElement("span", { className: iconName }),
        children));
};
export default NotificationMessage;
var templateObject_1;
