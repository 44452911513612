var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';
import EventLogList from './EventLogList';
var ConversationArea = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  flex: 10;\n  padding: 24px 56px;\n  overflow: scroll;\n  position: relative;\n  opacity: ", ";\n"], ["\n  flex: 10;\n  padding: 24px 56px;\n  overflow: scroll;\n  position: relative;\n  opacity: ", ";\n"])), function (props) { return (props.isLoadingLogHistory ? 0 : 1); });
var Messages = function (props) {
    var eventLogContainer = useRef(null);
    var messagesEnd = useRef(null);
    var oldEventLogs = useRef(null);
    function lastEventLogUpdated() {
        var eventLogs = props.eventLogs;
        if (!oldEventLogs.current)
            return false;
        var lastOld = oldEventLogs.current[oldEventLogs.current.length - 1];
        var lastNew = eventLogs[eventLogs.length - 1];
        if (lastNew && !lastOld)
            return true;
        if (!(lastOld && lastNew))
            return false;
        return lastOld.id !== lastNew.id;
    }
    useEffect(function () { return scrollToBottom(); }, []);
    useEffect(function () {
        if (lastEventLogUpdated()) {
            scrollToBottom();
        }
        oldEventLogs.current = props.eventLogs;
    });
    var scrollToBottom = function () {
        messagesEnd.current.scrollIntoView();
    };
    var onScroll = function () {
        var fetchEventLogs = props.fetchEventLogs, updateEventLogsPage = props.updateEventLogsPage, eventLogsPage = props.eventLogsPage, isLoadingLogs = props.isLoadingLogs, chatbotId = props.chatbotId, endUserId = props.endUserId;
        var scrollTop = eventLogContainer.current.scrollTop;
        if (scrollTop === 0 && !isLoadingLogs) {
            fetchEventLogs({
                chatbotId: chatbotId,
                endUserId: endUserId,
                page: eventLogsPage,
                shift: true,
            });
            updateEventLogsPage(eventLogsPage + 1);
        }
    };
    var chatbotId = props.chatbotId, endUserId = props.endUserId, eventLogs = props.eventLogs, limits = props.limits, scenarios = props.scenarios, layers = props.layers, blocks = props.blocks, isLoadingLogHistory = props.isLoadingLogHistory;
    return (React.createElement(ConversationArea, { className: "conversation-area", isLoadingLogHistory: isLoadingLogHistory, ref: eventLogContainer, onScroll: onScroll },
        React.createElement(EventLogList, { chatbotId: chatbotId, endUserId: endUserId, eventLogs: eventLogs, limits: limits, scenarios: scenarios, layers: layers, blocks: blocks }),
        React.createElement("div", { style: { float: 'left', clear: 'both' }, ref: messagesEnd })));
};
export default Messages;
var templateObject_1;
