export default {
    MAIN: 'MAIN',
    SAVE: 'SAVE',
    CONFIRM: 'CONFIRM',
    CONFIRM_CANCEL: 'CONFIRM_CANCEL',
    SETTING: 'SETTING',
    SETTING_WHITE: 'SETTING_WHITE',
    SETTING_GREEN: 'SETTING_GREEN',
    CANCEL: 'CANCEL',
    DELETE: 'DELETE',
    DISABLED: 'DISABLED',
    NONE: 'NONE',
    ADMIN_GRAY: 'ADMIN_GRAY',
    ADMIN_RED_GRAD: 'ADMIN_RED_GRAD',
    ADMIN_NAVY: 'ADMIN_NAVY',
    FACEBOOK: 'ADMIN_NAVY',
    LIGHT_THEM_UP: 'LIGHT_THEM_UP',
    BLUE_ZEALS: 'BLUE_ZEALS',
    // https://www.figma.com/file/a7DwDWCz5o5rSBew3BJ6R6/Payment-RPA?node-id=342%3A809
    PAYMENT_RPA_DUO_NEXT: 'PAYMENT_RPA_DUO_NEXT',
    PAYMENT_RPA_DUO_SUBMIT: 'PAYMENT_RPA_DUO_SUBMIT',
    PAYMENT_RPA_CANADEL_NEXT: 'PAYMENT_RPA_CANADEL_NEXT',
    PAYMENT_RPA_CANADEL_SUBMIT: 'PAYMENT_RPA_CANADEL_SUBMIT',
    OFF_WHITE: 'OFF_WHITE',
    OFF_WHITE_DISABLED: 'OFF_WHITE_DISABLED',
    LIGHT_GREY: 'LIGHT_GREY',
};
