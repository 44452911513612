import _defineProperty from "@babel/runtime/helpers/defineProperty";

var _CAN_SEND, _MEDIA_CAN_SEND, _TALK_CAN_SEND, _MAX_LENGTH_INPUT_FIE;

import { mediaTypes, messageTypes, K_BYTES } from '@src/lib/app-constants';
import { LINE_EMOJI_DATA } from '../line_emoji_data';
export default {
  MAX_TEXT_BUTTON_AMOUNT: 4,
  MAX_CAROUSEL_BUTTON_AMOUNT: 4,
  MAX_CAROUSEL_ITEM: 10,
  MAX_DEFAULT_REPLY_TEXT_LENGTH: 5000,
  MAX_QUICK_REPLY_CHOICE: 13,
  MAX_MEDIA_FILE_SIZE_BYTES: 200 * K_BYTES * K_BYTES,
  MAX_PREVIEW_IMAGE_FILE_SIZE_BYTES: K_BYTES * K_BYTES,
  PREVIEW_IMAGE_TYPES: 'image/jpg,image/jpeg',
  CAN_SEND: (_CAN_SEND = {}, _defineProperty(_CAN_SEND, messageTypes.TEXT, true), _defineProperty(_CAN_SEND, messageTypes.FLEX_MESSAGE, true), _defineProperty(_CAN_SEND, messageTypes.GENERIC, true), _defineProperty(_CAN_SEND, messageTypes.RICH_MENU, true), _defineProperty(_CAN_SEND, messageTypes.IMAGE_MAP, true), _defineProperty(_CAN_SEND, messageTypes.QUICK_REPLY, true), _defineProperty(_CAN_SEND, messageTypes.MEDIA, true), _defineProperty(_CAN_SEND, messageTypes.LIST, false), _defineProperty(_CAN_SEND, messageTypes.FREE_INPUT, true), _CAN_SEND),
  CAN_PHONE_NUMBER: true,
  CAN_SHOW_MESSAGE_USAGE: true,
  CAN_REQUEST_QUOTA_API: true,
  CAN_EMAIL: true,
  MEDIA_CAN_SEND: (_MEDIA_CAN_SEND = {}, _defineProperty(_MEDIA_CAN_SEND, mediaTypes.AUDIO, true), _defineProperty(_MEDIA_CAN_SEND, mediaTypes.VIDEO, true), _defineProperty(_MEDIA_CAN_SEND, mediaTypes.IMAGE, true), _defineProperty(_MEDIA_CAN_SEND, mediaTypes.FILE, false), _MEDIA_CAN_SEND),
  TALK_CAN_SEND: (_TALK_CAN_SEND = {}, _defineProperty(_TALK_CAN_SEND, messageTypes.TEXT, true), _defineProperty(_TALK_CAN_SEND, messageTypes.GENERIC, true), _defineProperty(_TALK_CAN_SEND, messageTypes.MEDIA, true), _defineProperty(_TALK_CAN_SEND, messageTypes.QUICK_REPLY, true), _defineProperty(_TALK_CAN_SEND, messageTypes.BUTTON_TEMPLATE, true), _defineProperty(_TALK_CAN_SEND, "SCENARIO", false), _TALK_CAN_SEND),
  // https://github.com/zeals-co-ltd/jupiter/issues/11093
  TALK_DISABLE_SENDING_MESSAGES_AFTER_MS: false,
  MIN_AMOUNT_OF_MESSAGES: 1,
  MIN_AMOUNT_OF_QUICK_REPLY_CHOICES: 1,
  LINE_EMOJI_DATA_COLUMN: 44,
  LINE_EMOJI_DATA_ROW: 4,
  LINE_EMOJI_DATA: LINE_EMOJI_DATA,
  MAX_LENGTH_INPUT_FIELD: (_MAX_LENGTH_INPUT_FIE = {}, _defineProperty(_MAX_LENGTH_INPUT_FIE, messageTypes.TEXT, {
    text: 5000
  }), _defineProperty(_MAX_LENGTH_INPUT_FIE, messageTypes.BUTTON_TEMPLATE, {
    title: 160
  }), _defineProperty(_MAX_LENGTH_INPUT_FIE, messageTypes.GENERIC, {
    title: 40,
    subtitle: 60,
    url: 1000
  }), _defineProperty(_MAX_LENGTH_INPUT_FIE, messageTypes.FLEX_MESSAGE, {
    altText: 400
  }), _defineProperty(_MAX_LENGTH_INPUT_FIE, messageTypes.IMAGE_MAP, {
    altText: 400,
    area: {
      label: 50
    },
    video: {
      label: 30
    }
  }), _defineProperty(_MAX_LENGTH_INPUT_FIE, messageTypes.RICH_MENU, {
    label: 255,
    chatBarText: 14,
    area: {
      label: 20
    }
  }), _defineProperty(_MAX_LENGTH_INPUT_FIE, "postback_action", {
    label: 20
  }), _defineProperty(_MAX_LENGTH_INPUT_FIE, "common", {
    url: 255
  }), _MAX_LENGTH_INPUT_FIE),
  MAX_LINE_INPUT_FIELD: _defineProperty({}, messageTypes.BUTTON_TEMPLATE, {
    title: 8
  }),
  BLOCK_MESSAGES_MAX: 5,
  MESSAGE_SECTION_ENABLED: true,
  INITIAL_CONVERSATION_ENABLED: true,
  FORM_CONVERSATION_ENABLED: true,
  PATTERN_CONVERSATION_ENABLED: true,
  SHOT_CONVERSATION_ENABLED: true,
  TRACK_PUSH_V1_ENABLED: true,
  TRACK_PUSH_V2_ENABLED: true,
  USER_DB_ENABLED: true,
  TALKROOM_ENABLED: true,
  FIELD_ATTRIBUTES: true,
  USER_SEGMENT: true,
  USER_LABEL: true,
  CHATLINKS_ENABLED: true,
  SETTINGS_WELCOME_BOARD_ENABLED: true,
  SETTINGS_DEFAULT_MESSAGE_ENABLED: true,
  SETTINGS_DEFAULT_RICH_MENU: true,
  PRIVACY_POLICY_ENABLED: true,
  AGGREGATION_CODE_ENABLED: true
};