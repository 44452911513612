var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Composition } from 'atomic-layout';
import { Formik } from 'formik';
import styled from 'styled-components';
import { FlashPortal, Spinner } from '@src/components/atoms';
import Flash from '@src/components/atoms/Flash';
import { colors } from '@src/colors';
import { displayFlash } from '@src/lib/helpers';
import smartReplacesAPI from '@src/endpoints/smartReplaces';
import chatbotsAPI from '@src/endpoints/chatbots';
import { makeFetchInit } from '@src/lib/helpers/ajaxFuncs';
import { mergeSmartReplaces } from '@src/lib/helpers/smartReplace';
import { fetchChatbots, fetchUpdateChatbot, } from '@src/redux/fanp/actions/chatbotList';
import PrivacyPolicySettings from './PrivacyPolicySettings';
import PrivacyPolicyPreview from './PrivacyPolicyPreview';
import { createPrivacyPolicySchema } from '@src/lib/helpers/formSchema';
import { useParams } from 'react-router-dom';
var SettingsPrivacyPoliciesContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  background: #fff;\n  border-radius: 8px;\n\n  .settings-wrapper {\n    display: block;\n  }\n  button {\n    display: inline-block;\n  }\n  .label {\n    font-size: 12px;\n    color: ", ";\n    margin-bottom: 8px;\n  }\n  iframe {\n    height: 100%;\n    width: 100%;\n  }\n  .preview-url {\n    display: inline-block;\n    overflow: hidden;\n    width: 50%;\n    white-space: nowrap;\n  }\n  .privacy-policy-err-msg {\n    padding: 2rem;\n  }\n"], ["\n  background: #fff;\n  border-radius: 8px;\n\n  .settings-wrapper {\n    display: block;\n  }\n  button {\n    display: inline-block;\n  }\n  .label {\n    font-size: 12px;\n    color: ", ";\n    margin-bottom: 8px;\n  }\n  iframe {\n    height: 100%;\n    width: 100%;\n  }\n  .preview-url {\n    display: inline-block;\n    overflow: hidden;\n    width: 50%;\n    white-space: nowrap;\n  }\n  .privacy-policy-err-msg {\n    padding: 2rem;\n  }\n"])), colors.default_text);
function createSmartReplace(chatbotId, pattern, value) {
    return __awaiter(this, void 0, Promise, function () {
        var url, res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    url = smartReplacesAPI.collection(chatbotId);
                    return [4 /*yield*/, fetch(url, makeFetchInit('POST', { value: value, pattern: pattern }))];
                case 1:
                    res = _a.sent();
                    if (res.status !== 201)
                        throw new Error('Could not update smart replace.');
                    return [4 /*yield*/, res.json()];
                case 2: return [2 /*return*/, _a.sent()];
            }
        });
    });
}
function updateSmartReplace(chatbotId, id, pattern, value) {
    return __awaiter(this, void 0, Promise, function () {
        var url, res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    url = smartReplacesAPI.member(chatbotId, id);
                    return [4 /*yield*/, fetch(url, makeFetchInit('PUT', { value: value, pattern: pattern }))];
                case 1:
                    res = _a.sent();
                    if (res.status !== 200)
                        throw new Error('Could not update smart replace.');
                    return [4 /*yield*/, res.json()];
                case 2: return [2 /*return*/, _a.sent()];
            }
        });
    });
}
function fetchPrivacyPolicy(chatbotId) {
    return __awaiter(this, void 0, Promise, function () {
        var url, res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    url = chatbotsAPI.privacyPolicy(chatbotId);
                    return [4 /*yield*/, fetch(url, makeFetchInit())];
                case 1:
                    res = _a.sent();
                    if (res.status === 404)
                        console.info('No privacy policy associated with this chatbot');
                    return [4 /*yield*/, res.json()];
                case 2: return [2 /*return*/, _a.sent()];
            }
        });
    });
}
/**
 * Shows the entire Privacy Policy Setting screen (both Smart Replace and Preview)
 */
var SettingsPrivacyPolicies = function (props) {
    var params = useParams();
    var _a = __read(useState(true), 2), loading = _a[0], setLoading = _a[1];
    var _b = __read(useState(null), 2), privacyPolicy = _b[0], setPrivacyPolicy = _b[1];
    var _c = __read(useState(0), 2), iframeRefresher = _c[0], setIframeRefresher = _c[1];
    var _d = __read(useState(false), 2), successFlashVisible = _d[0], setSuccessFlashVisible = _d[1];
    var _e = __read(useState(false), 2), errorFlashVisible = _e[0], setErrorFlashVisible = _e[1];
    var _f = __read(useState(''), 2), errorMsg = _f[0], setErrorMsg = _f[1];
    var _g = __read(useState([]), 2), smartReplaces = _g[0], setSmartReplaces = _g[1];
    var _h = __read(useState([]), 2), smartReplacePlaceholders = _h[0], setSmartReplacePlaceholders = _h[1];
    var chatbot = useSelector(function (state) {
        return state.chatbotList.present.chatbotList.find(function (c) { return c.id === Number(params.chatbotId); });
    });
    var dispatch = useDispatch();
    function setSmartReplace(smartReplace) {
        setSmartReplaces(smartReplaces.map(function (s) { return (s.id === smartReplace.id ? smartReplace : s); }));
    }
    function pushSmartReplace(smartReplace) {
        setSmartReplaces(__spreadArray(__spreadArray([], __read(smartReplaces)), [smartReplace]));
    }
    function refreshIFrame() {
        setIframeRefresher(iframeRefresher + 1);
    }
    function fetchSmartReplaces() {
        var url = smartReplacesAPI.collection(params.chatbotId);
        return fetch(url, makeFetchInit())
            .then(function (res) { return res.json(); })
            .then(function (_a) {
            var data = _a.data, meta = _a.meta, status = _a.status, message = _a.message;
            if (status && status === 404)
                throw new Error(message);
            setSmartReplaces(data);
            setSmartReplacePlaceholders(meta.placeholders);
        })
            .catch(function (e) {
            setErrorMsg(e.message);
            return e;
        });
    }
    function fetchSmartReplaceUpdate(pattern, value) {
        return __awaiter(this, void 0, void 0, function () {
            var smartReplace, smartReplacePlaceholder, id, pattern_1, _a, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        smartReplace = smartReplaces.find(function (s) { return s.pattern === pattern; });
                        smartReplacePlaceholder = smartReplacePlaceholders.find(function (s) { return s.pattern === pattern; });
                        if (!(smartReplace && smartReplace.value !== value)) return [3 /*break*/, 2];
                        id = smartReplace.id, pattern_1 = smartReplace.pattern;
                        _a = setSmartReplace;
                        return [4 /*yield*/, updateSmartReplace(params.chatbotId, id, pattern_1, value)];
                    case 1:
                        _a.apply(void 0, [_c.sent()]);
                        return [3 /*break*/, 4];
                    case 2:
                        if (!(!smartReplace && value !== smartReplacePlaceholder.value)) return [3 /*break*/, 4];
                        _b = pushSmartReplace;
                        return [4 /*yield*/, createSmartReplace(params.chatbotId, pattern, value)];
                    case 3:
                        _b.apply(void 0, [_c.sent()]);
                        _c.label = 4;
                    case 4: return [2 /*return*/];
                }
            });
        });
    }
    function saveToBackend(values, _a) {
        var resetForm = _a.resetForm;
        return __awaiter(this, void 0, void 0, function () {
            var url, smartReplaces, e_1;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        url = values.url, smartReplaces = __rest(values, ["url"]);
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 4, , 5]);
                        return [4 /*yield*/, Promise.all(Object.entries(smartReplaces).map(function (_a) {
                                var _b = __read(_a, 2), pattern = _b[0], value = _b[1];
                                return fetchSmartReplaceUpdate(pattern, String(value));
                            }))];
                    case 2:
                        _b.sent();
                        return [4 /*yield*/, dispatch(fetchUpdateChatbot(params.chatbotId, { bot_url: url || null }))];
                    case 3:
                        _b.sent();
                        displayFlash(setSuccessFlashVisible, flashSeconds);
                        refreshIFrame();
                        resetForm({ values: values });
                        return [3 /*break*/, 5];
                    case 4:
                        e_1 = _b.sent();
                        displayFlash(setErrorFlashVisible, flashSeconds);
                        setErrorMsg(e_1.message);
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        });
    }
    function getPrivacyPolicy() {
        return fetchPrivacyPolicy(params.chatbotId).then(setPrivacyPolicy);
    }
    useEffect(function () {
        setLoading(true);
        Promise.all([
            dispatch(fetchChatbots()),
            fetchSmartReplaces(),
            getPrivacyPolicy(),
        ]).then(function () {
            setLoading(false);
        });
    }, []);
    var areas = "settings preview";
    var areasLg = "settings preview";
    var flashSeconds = 2;
    var mergedSmartReplaces = mergeSmartReplaces(smartReplaces, smartReplacePlaceholders);
    var sReplaceInit = mergedSmartReplaces.reduce(function (obj, sReplace) {
        obj[sReplace.pattern] = sReplace.value;
        return obj;
    }, {});
    var initValues = __assign(__assign({}, sReplaceInit), { url: (chatbot === null || chatbot === void 0 ? void 0 : chatbot.bot_url) || '' });
    return loading ? (React.createElement(Spinner, null)) : (React.createElement(React.Fragment, null,
        successFlashVisible && (React.createElement(FlashPortal, null,
            React.createElement(Flash, { type: "success", secondsVisible: flashSeconds }, "\u30DC\u30C3\u30C8\u306E\u30D7\u30E9\u30A4\u30D0\u30B7\u30FC\u30DD\u30EA\u30B7\u30FC\u8A2D\u5B9A\u3092\u4FDD\u5B58\u3055\u308C\u307E\u3057\u305F\uFF01"))),
        errorFlashVisible && (React.createElement(FlashPortal, null,
            React.createElement(Flash, { type: "error", secondsVisible: flashSeconds }, errorMsg))),
        React.createElement(Composition, { as: SettingsPrivacyPoliciesContainer, className: "SettingsPrivacyPolicies", height: "100%", areas: areas, areasLg: areasLg, templateColsLg: "50% 50%" }, function (Areas) { return (React.createElement(Formik, { validateOnMount: true, initialValues: initValues, validationSchema: createPrivacyPolicySchema(smartReplaces), onSubmit: saveToBackend }, function (formikInnerProps) {
            return (React.createElement(React.Fragment, null,
                React.createElement(Areas.Settings, { margin: "2rem", "data-testid": "privacy-policy-settings-container" },
                    React.createElement(PrivacyPolicySettings, { smartReplaces: mergedSmartReplaces, formikInnerProps: formikInnerProps })),
                React.createElement(Areas.Preview, { "data-testid": "privacy-policy-preview-container" }, (privacyPolicy === null || privacyPolicy === void 0 ? void 0 : privacyPolicy.url) ? (React.createElement(PrivacyPolicyPreview, { key: iframeRefresher, privacyPolicyUrl: privacyPolicy.url, disabled: !formikInnerProps.isValid || formikInnerProps.dirty })) : (React.createElement("div", { className: "privacy-policy-err-msg" }, "No Privacy Policy associated with this Chatbot")))));
        })); })));
};
export default SettingsPrivacyPolicies;
var templateObject_1;
