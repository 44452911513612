export default {
  UNDO_USER: 'UNDO_USER',
  SET_USERS: 'SET_USERS',
  SET_USER: 'SET_USER',
  SET_USER_SCENARIOS: 'SET_USER_SCENARIOS',
  SET_USER_QUICK_REPLIES: 'SET_USER_QUICK_REPLIES',
  SET_HAS_USERS: 'SET_HAS_USERS',
  SET_CHECKED_ALL_USERS: 'SET_CHECKED_ALL_USERS',
  SET_ADMIN_ACCESS: 'SET_ADMIN_ACCESS',
  UPDATE_PROP_OF_USER: 'UPDATE_PROP_OF_USER',
  ADD_TAG_TO_MULTIPLE_USERS: 'ADD_TAG_TO_MULTIPLE_USERS',
  BLOCK_MULTIPLE_USERS: 'BLOCK_MULTIPLE_USERS',
  REMOVE_PROP_OF_USER: 'REMOVE_PROP_OF_USER',
  HANDLE_UPDATE_USER: 'HANDLE_UPDATE_USER',
  ADD_AUTO_TAG: 'ADD_AUTO_TAG',
  UPDATE_AUTO_TAG: 'UPDATE_AUTO_TAG',
  ADD_USER_TAG: 'ADD_USER_TAG',
  REMOVE_AUTO_TAG: 'REMOVE_AUTO_TAG',
  UPDATE_USER_TAG: 'UPDATE_USER_TAG',
  UPDATE_FULFILLED: 'UPDATE_FULFILLED',
  TOGGLE_CHECKED_USER: 'TOGGLE_CHECKED_USER',
  TOGGLE_CHECK_ALL_USERS: 'TOGGLE_CHECK_ALL_USERS'
};