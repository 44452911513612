var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import React from 'react';
import { colors } from '@src/colors';
import styled from 'styled-components';
import { Select, Icon } from '@zeals-co-ltd/washi-components';
import { chatTypes } from '@src/lib/chat-types';
import { useParams } from 'react-router-dom';
import { useFetchActiveInitialConversationABTestGroups } from '@src/components/organisms/fanp/initialConversation/hooks';
import { getABTestDetails } from '@src/components/organisms/fanp/initialConversation/common';
import { useSelector } from 'react-redux';
var IconComponentStyled = styled(Icon)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  path {\n    fill: ", ";\n  }\n"], ["\n  path {\n    fill: ", ";\n  }\n"])), colors.off_white_disabled);
var ScenarioAddedLabel = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  color: ", ";\n  font-size: 14px;\n  line-height: 20px;\n  letter-spacing: 0.25px;\n  margin-left: 5px;\n"], ["\n  color: ", ";\n  font-size: 14px;\n  line-height: 20px;\n  letter-spacing: 0.25px;\n  margin-left: 5px;\n"])), colors.washiDark900);
var TestingSelect = function (_a) {
    var testing = _a.testing, handleTestingChange = _a.handleTestingChange, intl = _a.intl, scenarioId = _a.scenarioId;
    var chatTypeParam = useParams().chatTypeParam;
    var valueTesting = testing ? 'true' : 'false';
    var isInitialChat = chatTypeParam === chatTypes.INITIAL;
    useFetchActiveInitialConversationABTestGroups();
    var activeInitialConversation = useSelector(function (state) { return state.initialConversation.getActiveInitialConversation; });
    var draftInitialConversation = useSelector(function (state) { return state.initialConversation.getDraftInitialConversation; });
    var draftABTesting = useSelector(function (state) { return state.initialConversation.ABTesting.draft; });
    var activeABTesting = useSelector(function (state) { return state.initialConversation.ABTesting.active; });
    var allAvailableScenarios = useSelector(function (state) { return state.initialConversation.scenarios; });
    var ABTestScenarioDraft = getABTestDetails(draftABTesting, allAvailableScenarios).ABTestScenarios;
    var ABTestScenarioActive = getABTestDetails(activeABTesting, allAvailableScenarios).ABTestScenarios;
    var checkTestModeStatus = __spreadArray(__spreadArray([], __read(ABTestScenarioActive)), __read(ABTestScenarioDraft));
    var isScenarioTesting = function (scenarioId, ABTest) {
        if (activeInitialConversation || draftInitialConversation) {
            return ABTest.some(function (item) { return scenarioId === item.id; });
        }
        return false;
    };
    var iconValue = isInitialChat && isScenarioTesting(scenarioId, checkTestModeStatus)
        ? 'adicon_no_access'
        : 'adicon_down';
    var IconComponent = function () { return (React.createElement(IconComponentStyled, { name: iconValue, className: "disabled" })); };
    return (React.createElement(React.Fragment, null,
        React.createElement(Select, { onChange: handleTestingChange, value: valueTesting, disabled: isInitialChat && isScenarioTesting(scenarioId, checkTestModeStatus), IconComponent: IconComponent },
            React.createElement("option", { value: "true" }, intl.formatMessage({ id: "gkTA5G", defaultMessage: "Test Mode On" })),
            React.createElement("option", { value: "false" }, intl.formatMessage({ id: "H46lid", defaultMessage: "Test Mode Off" }))),
        isInitialChat && isScenarioTesting(scenarioId, ABTestScenarioActive) ? (React.createElement(ScenarioAddedLabel, null, intl.formatMessage({ id: "+DQ78y", defaultMessage: "The scenario is currently set in active A/B test group, test mode must be off." }))) : (isInitialChat &&
            isScenarioTesting(scenarioId, ABTestScenarioDraft) && (React.createElement(ScenarioAddedLabel, null, intl.formatMessage({ id: "ns45pn", defaultMessage: "The scenario is currently set in draft A/B test group, test mode must be off." }))))));
};
export default TestingSelect;
var templateObject_1, templateObject_2;
