export var urlReg = /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i;
export var emailReg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export var passwordReg = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,70}$/;
export var phoneNumReg = /^(\+\d{1,2}?)?1?-?\d{3,4}?[-]?\d{3,4}[-]?\d{4}$/;
export var hostReg = /^$|^[a-z0-9]+(([-]{1,2}|[.]{1})[a-z0-9]+)*\.[a-z]{2,5}$/; // validated phone number:
// +11231231234
// +112312341234
// +1123-123-1234
// +1123-1234-1234
// symbol '-' will be removed in the component phoneNumberInput.tsx

export var validateUrl = function validateUrl(value) {
  return urlReg.test(value);
};
export var validateEmailAddress = function validateEmailAddress(email) {
  return emailReg.test(email);
};
export var validatePassword = function validatePassword(password) {
  return passwordReg.test(password);
};