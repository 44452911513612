/**
 * @fileoverview gRPC-Web generated client stub for
 * @enhanceable
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck
import * as grpcWeb from 'grpc-web';
import * as entities_entities_pb from '../entities/entities_pb';
import * as entities_user_dossier_pb from '../entities/user_dossier_pb';
import * as services_user_dossier_pb from '../services/user_dossier_pb';
var UserDossierServiceClient = /** @class */ (function () {
    function UserDossierServiceClient(hostname, credentials, options) {
        this.methodInfoCreateField = new grpcWeb.AbstractClientBase.MethodInfo(entities_user_dossier_pb.Field, function (request) {
            return request.serializeBinary();
        }, entities_user_dossier_pb.Field.deserializeBinary);
        this.methodInfoListFields = new grpcWeb.AbstractClientBase.MethodInfo(services_user_dossier_pb.ListFieldsResponse, function (request) {
            return request.serializeBinary();
        }, services_user_dossier_pb.ListFieldsResponse.deserializeBinary);
        this.methodInfoGetField = new grpcWeb.AbstractClientBase.MethodInfo(entities_user_dossier_pb.Field, function (request) {
            return request.serializeBinary();
        }, entities_user_dossier_pb.Field.deserializeBinary);
        this.methodInfoSearchFields = new grpcWeb.AbstractClientBase.MethodInfo(services_user_dossier_pb.SearchFieldsResponse, function (request) {
            return request.serializeBinary();
        }, services_user_dossier_pb.SearchFieldsResponse.deserializeBinary);
        this.methodInfoUpdateField = new grpcWeb.AbstractClientBase.MethodInfo(entities_user_dossier_pb.Field, function (request) {
            return request.serializeBinary();
        }, entities_user_dossier_pb.Field.deserializeBinary);
        this.methodInfoDeleteField = new grpcWeb.AbstractClientBase.MethodInfo(entities_entities_pb.Empty, function (request) {
            return request.serializeBinary();
        }, entities_entities_pb.Empty.deserializeBinary);
        this.methodInfoCreateUserLabel = new grpcWeb.AbstractClientBase.MethodInfo(entities_user_dossier_pb.UserLabel, function (request) {
            return request.serializeBinary();
        }, entities_user_dossier_pb.UserLabel.deserializeBinary);
        this.methodInfoSearchUserLabels = new grpcWeb.AbstractClientBase.MethodInfo(services_user_dossier_pb.SearchUserLabelsResponse, function (request) {
            return request.serializeBinary();
        }, services_user_dossier_pb.SearchUserLabelsResponse.deserializeBinary);
        this.methodInfoUpdateUserLabel = new grpcWeb.AbstractClientBase.MethodInfo(entities_user_dossier_pb.UserLabel, function (request) {
            return request.serializeBinary();
        }, entities_user_dossier_pb.UserLabel.deserializeBinary);
        this.methodInfoDeleteUserLabel = new grpcWeb.AbstractClientBase.MethodInfo(entities_entities_pb.Empty, function (request) {
            return request.serializeBinary();
        }, entities_entities_pb.Empty.deserializeBinary);
        this.methodInfoCreateFieldCollection = new grpcWeb.AbstractClientBase.MethodInfo(entities_user_dossier_pb.FieldCollection, function (request) {
            return request.serializeBinary();
        }, entities_user_dossier_pb.FieldCollection.deserializeBinary);
        this.methodInfoGetFieldCollection = new grpcWeb.AbstractClientBase.MethodInfo(entities_user_dossier_pb.FieldCollection, function (request) {
            return request.serializeBinary();
        }, entities_user_dossier_pb.FieldCollection.deserializeBinary);
        this.methodInfoListFieldCollections = new grpcWeb.AbstractClientBase.MethodInfo(services_user_dossier_pb.ListFieldCollectionsResponse, function (request) {
            return request.serializeBinary();
        }, services_user_dossier_pb.ListFieldCollectionsResponse.deserializeBinary);
        this.methodInfoUpdateFieldCollection = new grpcWeb.AbstractClientBase.MethodInfo(entities_user_dossier_pb.FieldCollection, function (request) {
            return request.serializeBinary();
        }, entities_user_dossier_pb.FieldCollection.deserializeBinary);
        this.methodInfoLinkField = new grpcWeb.AbstractClientBase.MethodInfo(entities_entities_pb.Empty, function (request) {
            return request.serializeBinary();
        }, entities_entities_pb.Empty.deserializeBinary);
        this.methodInfoUnlinkField = new grpcWeb.AbstractClientBase.MethodInfo(entities_entities_pb.Empty, function (request) {
            return request.serializeBinary();
        }, entities_entities_pb.Empty.deserializeBinary);
        this.methodInfoDeleteFieldCollection = new grpcWeb.AbstractClientBase.MethodInfo(entities_entities_pb.Empty, function (request) {
            return request.serializeBinary();
        }, entities_entities_pb.Empty.deserializeBinary);
        this.methodInfoCreateUserSegment = new grpcWeb.AbstractClientBase.MethodInfo(entities_user_dossier_pb.UserSegment, function (request) {
            return request.serializeBinary();
        }, entities_user_dossier_pb.UserSegment.deserializeBinary);
        this.methodInfoSearchUserSegments = new grpcWeb.AbstractClientBase.MethodInfo(services_user_dossier_pb.SearchUserSegmentsResponse, function (request) {
            return request.serializeBinary();
        }, services_user_dossier_pb.SearchUserSegmentsResponse.deserializeBinary);
        this.methodInfoUpdateUserSegment = new grpcWeb.AbstractClientBase.MethodInfo(entities_user_dossier_pb.UserSegment, function (request) {
            return request.serializeBinary();
        }, entities_user_dossier_pb.UserSegment.deserializeBinary);
        this.methodInfoDeleteUserSegment = new grpcWeb.AbstractClientBase.MethodInfo(entities_entities_pb.Empty, function (request) {
            return request.serializeBinary();
        }, entities_entities_pb.Empty.deserializeBinary);
        this.methodInfoUpsertAttributes = new grpcWeb.AbstractClientBase.MethodInfo(entities_entities_pb.Empty, function (request) {
            return request.serializeBinary();
        }, entities_entities_pb.Empty.deserializeBinary);
        this.methodInfoGetAttributeCollection = new grpcWeb.AbstractClientBase.MethodInfo(entities_user_dossier_pb.AttributeCollection, function (request) {
            return request.serializeBinary();
        }, entities_user_dossier_pb.AttributeCollection.deserializeBinary);
        this.methodInfoCreateAttributeCollection = new grpcWeb.AbstractClientBase.MethodInfo(entities_entities_pb.Empty, function (request) {
            return request.serializeBinary();
        }, entities_entities_pb.Empty.deserializeBinary);
        this.methodInfoListAttributeCollections = new grpcWeb.AbstractClientBase.MethodInfo(services_user_dossier_pb.ListAttributeCollectionsResponse, function (request) {
            return request.serializeBinary();
        }, services_user_dossier_pb.ListAttributeCollectionsResponse.deserializeBinary);
        this.methodInfoUpdateAttributeCollection = new grpcWeb.AbstractClientBase.MethodInfo(entities_entities_pb.Empty, function (request) {
            return request.serializeBinary();
        }, entities_entities_pb.Empty.deserializeBinary);
        this.methodInfoDeleteAttributeCollection = new grpcWeb.AbstractClientBase.MethodInfo(entities_entities_pb.Empty, function (request) {
            return request.serializeBinary();
        }, entities_entities_pb.Empty.deserializeBinary);
        this.methodInfoSearchUsers = new grpcWeb.AbstractClientBase.MethodInfo(services_user_dossier_pb.SearchUsersResponse, function (request) {
            return request.serializeBinary();
        }, services_user_dossier_pb.SearchUsersResponse.deserializeBinary);
        if (!options)
            options = {};
        if (!credentials)
            credentials = {};
        options['format'] = 'text';
        this.client_ = new grpcWeb.GrpcWebClientBase(options);
        this.hostname_ = hostname;
        this.credentials_ = credentials;
        this.options_ = options;
    }
    UserDossierServiceClient.prototype.createField = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/UserDossierService/CreateField', request, metadata || {}, this.methodInfoCreateField, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/UserDossierService/CreateField', request, metadata || {}, this.methodInfoCreateField);
    };
    UserDossierServiceClient.prototype.listFields = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/UserDossierService/ListFields', request, metadata || {}, this.methodInfoListFields, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/UserDossierService/ListFields', request, metadata || {}, this.methodInfoListFields);
    };
    UserDossierServiceClient.prototype.getField = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/UserDossierService/GetField', request, metadata || {}, this.methodInfoGetField, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/UserDossierService/GetField', request, metadata || {}, this.methodInfoGetField);
    };
    UserDossierServiceClient.prototype.searchFields = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/UserDossierService/SearchFields', request, metadata || {}, this.methodInfoSearchFields, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/UserDossierService/SearchFields', request, metadata || {}, this.methodInfoSearchFields);
    };
    UserDossierServiceClient.prototype.updateField = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/UserDossierService/UpdateField', request, metadata || {}, this.methodInfoUpdateField, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/UserDossierService/UpdateField', request, metadata || {}, this.methodInfoUpdateField);
    };
    UserDossierServiceClient.prototype.deleteField = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/UserDossierService/DeleteField', request, metadata || {}, this.methodInfoDeleteField, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/UserDossierService/DeleteField', request, metadata || {}, this.methodInfoDeleteField);
    };
    UserDossierServiceClient.prototype.createUserLabel = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/UserDossierService/CreateUserLabel', request, metadata || {}, this.methodInfoCreateUserLabel, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/UserDossierService/CreateUserLabel', request, metadata || {}, this.methodInfoCreateUserLabel);
    };
    UserDossierServiceClient.prototype.searchUserLabels = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/UserDossierService/SearchUserLabels', request, metadata || {}, this.methodInfoSearchUserLabels, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/UserDossierService/SearchUserLabels', request, metadata || {}, this.methodInfoSearchUserLabels);
    };
    UserDossierServiceClient.prototype.updateUserLabel = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/UserDossierService/UpdateUserLabel', request, metadata || {}, this.methodInfoUpdateUserLabel, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/UserDossierService/UpdateUserLabel', request, metadata || {}, this.methodInfoUpdateUserLabel);
    };
    UserDossierServiceClient.prototype.deleteUserLabel = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/UserDossierService/DeleteUserLabel', request, metadata || {}, this.methodInfoDeleteUserLabel, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/UserDossierService/DeleteUserLabel', request, metadata || {}, this.methodInfoDeleteUserLabel);
    };
    UserDossierServiceClient.prototype.createFieldCollection = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/UserDossierService/CreateFieldCollection', request, metadata || {}, this.methodInfoCreateFieldCollection, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/UserDossierService/CreateFieldCollection', request, metadata || {}, this.methodInfoCreateFieldCollection);
    };
    UserDossierServiceClient.prototype.getFieldCollection = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/UserDossierService/GetFieldCollection', request, metadata || {}, this.methodInfoGetFieldCollection, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/UserDossierService/GetFieldCollection', request, metadata || {}, this.methodInfoGetFieldCollection);
    };
    UserDossierServiceClient.prototype.listFieldCollections = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/UserDossierService/ListFieldCollections', request, metadata || {}, this.methodInfoListFieldCollections, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/UserDossierService/ListFieldCollections', request, metadata || {}, this.methodInfoListFieldCollections);
    };
    UserDossierServiceClient.prototype.updateFieldCollection = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/UserDossierService/UpdateFieldCollection', request, metadata || {}, this.methodInfoUpdateFieldCollection, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/UserDossierService/UpdateFieldCollection', request, metadata || {}, this.methodInfoUpdateFieldCollection);
    };
    UserDossierServiceClient.prototype.linkField = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/UserDossierService/LinkField', request, metadata || {}, this.methodInfoLinkField, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/UserDossierService/LinkField', request, metadata || {}, this.methodInfoLinkField);
    };
    UserDossierServiceClient.prototype.unlinkField = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/UserDossierService/UnlinkField', request, metadata || {}, this.methodInfoUnlinkField, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/UserDossierService/UnlinkField', request, metadata || {}, this.methodInfoUnlinkField);
    };
    UserDossierServiceClient.prototype.deleteFieldCollection = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/UserDossierService/DeleteFieldCollection', request, metadata || {}, this.methodInfoDeleteFieldCollection, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/UserDossierService/DeleteFieldCollection', request, metadata || {}, this.methodInfoDeleteFieldCollection);
    };
    UserDossierServiceClient.prototype.createUserSegment = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/UserDossierService/CreateUserSegment', request, metadata || {}, this.methodInfoCreateUserSegment, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/UserDossierService/CreateUserSegment', request, metadata || {}, this.methodInfoCreateUserSegment);
    };
    UserDossierServiceClient.prototype.searchUserSegments = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/UserDossierService/SearchUserSegments', request, metadata || {}, this.methodInfoSearchUserSegments, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/UserDossierService/SearchUserSegments', request, metadata || {}, this.methodInfoSearchUserSegments);
    };
    UserDossierServiceClient.prototype.updateUserSegment = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/UserDossierService/UpdateUserSegment', request, metadata || {}, this.methodInfoUpdateUserSegment, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/UserDossierService/UpdateUserSegment', request, metadata || {}, this.methodInfoUpdateUserSegment);
    };
    UserDossierServiceClient.prototype.deleteUserSegment = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/UserDossierService/DeleteUserSegment', request, metadata || {}, this.methodInfoDeleteUserSegment, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/UserDossierService/DeleteUserSegment', request, metadata || {}, this.methodInfoDeleteUserSegment);
    };
    UserDossierServiceClient.prototype.upsertAttributes = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/UserDossierService/UpsertAttributes', request, metadata || {}, this.methodInfoUpsertAttributes, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/UserDossierService/UpsertAttributes', request, metadata || {}, this.methodInfoUpsertAttributes);
    };
    UserDossierServiceClient.prototype.getAttributeCollection = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/UserDossierService/GetAttributeCollection', request, metadata || {}, this.methodInfoGetAttributeCollection, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/UserDossierService/GetAttributeCollection', request, metadata || {}, this.methodInfoGetAttributeCollection);
    };
    UserDossierServiceClient.prototype.createAttributeCollection = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/UserDossierService/CreateAttributeCollection', request, metadata || {}, this.methodInfoCreateAttributeCollection, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/UserDossierService/CreateAttributeCollection', request, metadata || {}, this.methodInfoCreateAttributeCollection);
    };
    UserDossierServiceClient.prototype.listAttributeCollections = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/UserDossierService/ListAttributeCollections', request, metadata || {}, this.methodInfoListAttributeCollections, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/UserDossierService/ListAttributeCollections', request, metadata || {}, this.methodInfoListAttributeCollections);
    };
    UserDossierServiceClient.prototype.updateAttributeCollection = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/UserDossierService/UpdateAttributeCollection', request, metadata || {}, this.methodInfoUpdateAttributeCollection, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/UserDossierService/UpdateAttributeCollection', request, metadata || {}, this.methodInfoUpdateAttributeCollection);
    };
    UserDossierServiceClient.prototype.deleteAttributeCollection = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/UserDossierService/DeleteAttributeCollection', request, metadata || {}, this.methodInfoDeleteAttributeCollection, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/UserDossierService/DeleteAttributeCollection', request, metadata || {}, this.methodInfoDeleteAttributeCollection);
    };
    UserDossierServiceClient.prototype.searchUsers = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/UserDossierService/SearchUsers', request, metadata || {}, this.methodInfoSearchUsers, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/UserDossierService/SearchUsers', request, metadata || {}, this.methodInfoSearchUsers);
    };
    return UserDossierServiceClient;
}());
export { UserDossierServiceClient };
