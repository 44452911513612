import "core-js/modules/es.array.concat";
import _extends from "@babel/runtime/helpers/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
import React from 'react';
import styled from 'styled-components';
import { colors } from '@src/colors';
import { ModalPortal } from '@src/components/atoms';
import Modal from '@src/components/molecules/Modal';
var ScriptContainer = styled.div.withConfig({
  displayName: "ScriptContainer",
  componentId: "sc-3lrnd9-0"
})(["font-size:10px;text-align:center;padding:20px;border:1px solid ", ";border-radius:5px;margin-top:24px;"], colors.border.default);

var ScriptBannerTag = function ScriptBannerTag(_ref) {
  var chatlink = _ref.chatlink,
      props = _objectWithoutProperties(_ref, ["chatlink"]);

  return React.createElement(ModalPortal, null, React.createElement(Modal, _extends({
    "data-testid": "script-banner-tag-modal",
    text: "\u30BF\u30B0"
  }, props), React.createElement(ScriptContainer, null, "<a href=\"".concat(chatlink.bot_url, "\"><img class=\"fanp-img-plugin\" src=\"").concat(document.location.origin, "/chatbots/").concat(chatlink.chatbot_id, "/chat_links/").concat(chatlink.id, "/banner\"></a>\n          <script\n            id=\"fanp-tag-plugin\"\n            src=\"").concat(document.location.origin, "/plugin/fanp-line-modal/image_banner_tag.min.js\"\n            data-chat-link-id=\"").concat(chatlink.id, "\"\n          ></script>"))));
};

export default ScriptBannerTag;