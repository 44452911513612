import React from 'react';
import { injectIntl } from 'react-intl';
import get from 'lodash/get';
import { colors } from '@src/colors';
import makeStyles from '@mui/styles/makeStyles';
import Modal from '@zeals-co-ltd/washi-components/lib/Modal/Modal';
import Button from '@zeals-co-ltd/washi-components/lib/Button/Button';
var useStyles = makeStyles(function () { return ({
    paper: {
        width: '480px',
        padding: '24px 16px 16px 16px',
        maxWidth: '480px',
        color: function (props) { return get(props, 'titleColor'); },
        '& > div:first-child, h2': {
            display: 'block',
        },
        '& .MuiDialogContent-root': {
            color: colors.blue_purple_dark,
            paddingBottom: '24px',
            paddingTop: '0px',
            whiteSpace: 'pre-line',
            wordBreak: 'break-word',
        },
        '& .MuiButton-root': {
            borderRadius: 8,
            padding: '8px 16px',
            height: '40px',
        },
        '& .MuiButton-contained': {
            marginLeft: 16,
        },
        '& .MuiButton-containedSecondary': {
            backgroundColor: colors.yellow,
            color: colors.blue_purple_dark,
        },
        '& .MuiTypography-root': {
            textAlign: 'left',
        },
    },
}); });
var DefaultRichMenuConfirmModal = function (_a) {
    var intl = _a.intl, open = _a.open, title = _a.title, _b = _a.titleColor, titleColor = _b === void 0 ? colors.midnight : _b, message = _a.message, onClose = _a.onClose, onConfirm = _a.onConfirm, btnType = _a.btnType, btnText = _a.btnText;
    var classes = useStyles({ titleColor: titleColor });
    var actions = (React.createElement(React.Fragment, null,
        React.createElement(Button, { color: "primary", onClick: onClose }, intl.formatMessage({ id: "47FYwb", defaultMessage: "Cancel" })),
        React.createElement(Button, { color: btnType, size: "large", variant: "contained", onClick: function () {
                onClose();
                onConfirm();
            } }, btnText)));
    return (React.createElement(Modal, { onClose: onClose, title: title, actions: actions, open: open, classes: classes, hideCloseIcon: true }, message));
};
export default injectIntl(DefaultRichMenuConfirmModal);
