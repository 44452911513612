import React from 'react';
import { injectIntl } from 'react-intl';
import { Input } from '@src/components/atoms';
export default injectIntl(function FormAutomationFixedValueSection(_a) {
    var intl = _a.intl, value = _a.value, onChange = _a.onChange, error = _a.error;
    return (React.createElement(React.Fragment, null,
        React.createElement("h4", { className: "title" }, intl.formatMessage({ id: "XwpwFg", defaultMessage: "Fixed Value Settings" })),
        React.createElement("span", { className: "subtitle" }, intl.formatMessage({ id: "hE8836", defaultMessage: "Please enter the fixed (unchanging) value for this step" })),
        React.createElement("div", { className: "fixed-value-container" },
            React.createElement(Input, { className: "item", value: value, placeholder: intl.formatMessage({ id: "iEY5Kx", defaultMessage: "Fixed value" }), onChange: function (e) { return onChange(e.target.value); } })),
        error && (React.createElement("span", { className: "error" }, intl.formatMessage({ id: "sVyxte", defaultMessage: "Empty fixed value is not allowed" })))));
});
