var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import ModalPortal from '@src/components/atoms/ModalPortal';
import { Modal } from '@src/components/molecules';
import modalTypes from '@src/lib/modalTypes';
import { dateFormatExampleMap } from '@src/lib/formAutomation';
import { FormAutomationStepValueType, TextReplacementType, DateFormat, } from 'zeals-protobuf/zeals/web/entities/form_automation_pb';
import { DataType, } from 'zeals-protobuf/zeals/web/entities/user_dossier_pb';
import ModalWrapper from '@src/components/atoms/ModalWrapper';
import Button from '@src/components/atoms/Button';
import buttonTypes from '@src/lib/buttonTypes';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { FormAutomationMode } from '@src/typings/enums';
import FormAutomationFixedValueSection from './FormAutomationFixedValueSection';
import FormAutomationTextFieldSection from './FormAutomationTextFieldSection';
import FormAutomationDateFormatSection from './FormAutomationDateFormatSection';
function blankTextReplacementGroup() {
    return {
        replacement: TextReplacementType.TEXT_REPLACEMENT_FIXED,
        regexp: '',
        fixedString: '',
    };
}
var FormAutomationTextInputActionModal = function (_a) {
    var _b, _c, _d, _e, _f;
    var initialStep = _a.initialStep, intl = _a.intl, field = _a.field, onCancel = _a.onCancel, onChange = _a.onChange;
    var initialFormikState = {
        valueType: (_b = initialStep === null || initialStep === void 0 ? void 0 : initialStep.inputAction) === null || _b === void 0 ? void 0 : _b.valueType,
        dateFormat: ((_c = initialStep === null || initialStep === void 0 ? void 0 : initialStep.inputAction) === null || _c === void 0 ? void 0 : _c.dateFormat) ||
            DateFormat.DATE_FORMAT_UNSPECIFIED,
        fixedValue: (_d = initialStep === null || initialStep === void 0 ? void 0 : initialStep.inputAction) === null || _d === void 0 ? void 0 : _d.fixedValue,
        regexp: (_e = initialStep === null || initialStep === void 0 ? void 0 : initialStep.inputAction) === null || _e === void 0 ? void 0 : _e.regexp,
        replacementGroupsList: (_f = initialStep === null || initialStep === void 0 ? void 0 : initialStep.inputAction) === null || _f === void 0 ? void 0 : _f.replacementGroupsList,
    };
    var schema = Yup.object({
        fixedValue: Yup.string().test('FixedValue', 
        // Disable ESLint to accept yup error message customization, see https://github.com/jquense/yup#error-message-customization
        // eslint-disable-next-line
        '${path} is not valid', function (value, context) {
            switch (context.parent.valueType) {
                case FormAutomationStepValueType.FORM_AUTOMATION_STEP_VALUE_TYPE_FIELD:
                    return true;
                case FormAutomationStepValueType.FORM_AUTOMATION_STEP_VALUE_TYPE_FIXED:
                    return Boolean(value);
                default:
                    return true;
            }
        }),
        regexp: Yup.string()
            .nullable()
            // Disable ESLint to accept yup error message customization, see https://github.com/jquense/yup#error-message-customization
            // eslint-disable-next-line
            .test('Regexp', '${path} is not valid', function (value, context) {
            switch (context.parent.valueType) {
                case FormAutomationStepValueType.FORM_AUTOMATION_STEP_VALUE_TYPE_FIELD:
                    try {
                        var regexp = new RegExp(value);
                        return Boolean(regexp);
                    }
                    catch (_a) {
                        return false;
                    }
                case FormAutomationStepValueType.FORM_AUTOMATION_STEP_VALUE_TYPE_FIXED:
                    return true;
                default:
                    return true;
            }
        }),
        replacementGroupsList: Yup.array().test('ReplacementGroupsList', 
        // Disable ESLint to accept yup error message customization, see https://github.com/jquense/yup#error-message-customization
        // eslint-disable-next-line
        '${path} is not valid', function (replacementGroupsList, context) {
            switch (context.parent.valueType) {
                case FormAutomationStepValueType.FORM_AUTOMATION_STEP_VALUE_TYPE_FIELD:
                    return replacementGroupsList.every(function (replacementGroup) {
                        try {
                            var regexp = new RegExp(replacementGroup.regexp);
                            return Boolean(regexp);
                        }
                        catch (_a) {
                            return false;
                        }
                    });
                case FormAutomationStepValueType.FORM_AUTOMATION_STEP_VALUE_TYPE_FIXED:
                    return true;
                default:
                    return true;
            }
        }),
        dateFormat: Yup.number().test('DateFormat', 
        // Disable ESLint to accept yup error message customization, see https://github.com/jquense/yup#error-message-customization
        // eslint-disable-next-line
        '${path} is not valid', function (value, context) {
            if ((field === null || field === void 0 ? void 0 : field.dataType) === DataType.DATA_TYPE_DATETIME &&
                value === DateFormat.DATE_FORMAT_UNSPECIFIED) {
                switch (context.parent.valueType) {
                    case FormAutomationStepValueType.FORM_AUTOMATION_STEP_VALUE_TYPE_FIELD:
                        return value !== DateFormat.DATE_FORMAT_UNSPECIFIED;
                    case FormAutomationStepValueType.FORM_AUTOMATION_STEP_VALUE_TYPE_FIXED:
                        return true;
                    default:
                        return true;
                }
            }
            else {
                return true;
            }
        }),
    });
    var _g = __read(useState(FormAutomationMode.Normal), 2), mode = _g[0], setMode = _g[1];
    function addReplacementGroup(setFieldValue, replacementGroupsList) {
        setFieldValue('replacementGroupsList', __spreadArray(__spreadArray([], __read(replacementGroupsList)), [
            blankTextReplacementGroup(),
        ]));
    }
    function removeReplacementGroup(setFieldValue, replacementGroupsList, i) {
        setFieldValue('replacementGroupsList', replacementGroupsList.filter(function (_, index) { return i !== index; }));
    }
    function setReplacementGroup(i, updatedGroup, setFieldValue, replacementGroupsList) {
        setFieldValue('replacementGroupsList', replacementGroupsList.map(function (replacementGroup, idx) {
            return idx === i ? updatedGroup : replacementGroup;
        }));
    }
    return (React.createElement(Formik, { initialValues: initialFormikState, validationSchema: schema, onSubmit: function () { return undefined; } }, function (_a) {
        var values = _a.values, errors = _a.errors, isValid = _a.isValid, setFieldValue = _a.setFieldValue;
        return (React.createElement(ModalPortal, null,
            React.createElement(ModalWrapper, null,
                mode === FormAutomationMode.Normal && (React.createElement(Modal, { className: "form-automation-modal", type: modalTypes.EDIT, onCancel: onCancel, onConfirm: function () {
                        return onChange(__assign(__assign({}, initialStep), { inputAction: __assign(__assign({}, initialStep.inputAction), { valueType: values.valueType, fixedValue: values.fixedValue, regexp: values.regexp, replacementGroupsList: values.replacementGroupsList, dateFormat: values.dateFormat }) }));
                    }, hasError: !isValid },
                    values.valueType ===
                        FormAutomationStepValueType.FORM_AUTOMATION_STEP_VALUE_TYPE_FIELD && (React.createElement(FormAutomationTextFieldSection, { regexp: values.regexp, replacementGroupsList: values.replacementGroupsList, onRegexpChange: function (regexp) {
                            return setFieldValue('regexp', regexp);
                        }, onAddReplacementGroup: function () {
                            return addReplacementGroup(setFieldValue, values.replacementGroupsList);
                        }, onRemoveReplacementGroup: function (i) {
                            return removeReplacementGroup(setFieldValue, values.replacementGroupsList, i);
                        }, onReplacementGroupChange: function (i, replacementGroup) {
                            return setReplacementGroup(i, replacementGroup, setFieldValue, values.replacementGroupsList);
                        }, errors: errors })),
                    values.valueType ===
                        FormAutomationStepValueType.FORM_AUTOMATION_STEP_VALUE_TYPE_FIXED && (React.createElement(FormAutomationFixedValueSection, { value: values.fixedValue, error: errors.fixedValue, onChange: function (fixedValue) {
                            return setFieldValue('fixedValue', fixedValue);
                        } })),
                    (field === null || field === void 0 ? void 0 : field.dataType) === DataType.DATA_TYPE_DATETIME &&
                        values.valueType ===
                            FormAutomationStepValueType.FORM_AUTOMATION_STEP_VALUE_TYPE_FIELD && (React.createElement("div", { className: "date-format-button" },
                        React.createElement("span", null, "Select the date format you want to set"),
                        React.createElement("div", { className: "date-format-card" },
                            React.createElement("div", { className: "date-format-title" }, "Date Format"),
                            React.createElement("div", { className: "date-format-value" }, dateFormatExampleMap[values.dateFormat] ||
                                'Not selected')),
                        errors.dateFormat && (React.createElement("span", { className: "error" }, intl.formatMessage({ id: "tQhtAm", defaultMessage: "Empty Date Format is not allowed" }))),
                        React.createElement(Button, { buttonType: buttonTypes.SETTING, className: "date-format", onClick: function () { return setMode(FormAutomationMode.Date); } },
                            React.createElement("span", { className: "adicon_date" }),
                            dateFormatExampleMap[values.dateFormat]
                                ? intl.formatMessage({ id: "Q6NY27", defaultMessage: "Change Date Format" })
                                : intl.formatMessage({ id: "XELbEm", defaultMessage: "Select Date Format" })))))),
                mode === FormAutomationMode.Date && (React.createElement(Modal, { className: "form-automation-input-date-format-modal", hasError: !isValid, width: "50%", onCancel: function () { return setMode(FormAutomationMode.Normal); } },
                    React.createElement(FormAutomationDateFormatSection, { initialDateFormat: values.dateFormat, onCancel: function () { return setMode(FormAutomationMode.Normal); }, onSubmit: function (df) {
                            setFieldValue('dateFormat', df);
                            setMode(FormAutomationMode.Normal);
                        } }))))));
    }));
};
export default injectIntl(FormAutomationTextInputActionModal);
