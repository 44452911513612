var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { objToQuery } from '@src/lib/helpers';
import { ChatType } from '@src/typings/enums';
import axiosInstance from './axiosInstance';
import transformResponse from './transformResponse';
var collection = function (chatbotId) { return "/chatbots/" + chatbotId + "/ad_scenarios"; };
var list = function (chatbotId) { return collection(chatbotId) + "/list"; };
var oldCollection = function (chatbotId) { return "/chatbots/" + chatbotId + "/scenarios"; };
var oldMember = function (chatbotId, id) { return oldCollection(chatbotId) + "/" + id; };
var member = function (chatbotId, id) { return collection(chatbotId) + "/" + id; };
var validate = function (chatbotId, id) { return member(chatbotId, id) + "/validate"; };
var template = function (chatbotId) {
    return collection(chatbotId) + "/create_template_scenario";
};
var duplicate = function (chatbotId, id) { return member(chatbotId, id) + "/duplicate"; };
var deleteScenariosUrl = function (chatbotId) {
    return "/chatbots/" + chatbotId + "/ad_scenarios/multi_destroy";
};
var scenarioMap = {
    collection: function (chatbotId) {
        return "/scenario_map/api/chatbots/" + chatbotId + "/scenarios";
    },
    member: function (chatbotId, scenarioId, token) {
        return scenarioMap.collection(chatbotId) + "/" + scenarioId + "?token=" + token;
    },
    map: function (chatbotId, scenarioId, token) {
        return scenarioMap.collection(chatbotId) + "/" + scenarioId + "/map?token=" + token;
    },
};
export function getScenario(chatbotId, scenarioId) {
    return __awaiter(this, void 0, void 0, function () {
        var url, res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    url = member(chatbotId, scenarioId);
                    return [4 /*yield*/, transformResponse(axiosInstance.get(url))];
                case 1:
                    res = _a.sent();
                    return [2 /*return*/, res.scenario];
            }
        });
    });
}
export function getScenarios(chatbotId, params) {
    if (params === void 0) { params = {}; }
    return __awaiter(this, void 0, void 0, function () {
        var url, res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    url = list(chatbotId);
                    return [4 /*yield*/, transformResponse(axiosInstance.get(url, { params: params }))];
                case 1:
                    res = _a.sent();
                    return [2 /*return*/, res];
            }
        });
    });
}
export function listScenarios(chatbotId) {
    return __awaiter(this, void 0, void 0, function () {
        var url, res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    url = collection(chatbotId) + "/list";
                    return [4 /*yield*/, transformResponse(axiosInstance.get(url))];
                case 1:
                    res = _a.sent();
                    return [2 /*return*/, res];
            }
        });
    });
}
export function getScenariosPaginated(chatbotId, options) {
    return __awaiter(this, void 0, void 0, function () {
        var query, url, res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    query = objToQuery({
                        search_value: options.search,
                        page: options.page,
                        per: options.per,
                        chat_type: options.chatType,
                    });
                    url = collection(chatbotId) + "?" + query;
                    return [4 /*yield*/, transformResponse(axiosInstance.get(url))];
                case 1:
                    res = _a.sent();
                    return [2 /*return*/, {
                            scenarios: res.data,
                            totalScenarios: res.meta.total_items,
                        }];
            }
        });
    });
}
export function getInitialScenarios(chatbotId) {
    return __awaiter(this, void 0, void 0, function () {
        var query, url, res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    query = objToQuery({
                        chat_type: ChatType.Initial,
                        pagination: 'false',
                    });
                    url = collection(chatbotId) + "?" + query;
                    return [4 /*yield*/, transformResponse(axiosInstance.get(url))];
                case 1:
                    res = _a.sent();
                    return [2 /*return*/, {
                            scenarios: res,
                        }];
            }
        });
    });
}
/**
 * Update a Scenario in the backend, will 422 if you try to create a scenario with the same name
 * @return {Scenario} Scenario otherwise
 */
export function updateScenario(chatbotId, scenarioId, options) {
    return __awaiter(this, void 0, Promise, function () {
        var url, body, res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    url = member(chatbotId, scenarioId);
                    body = { name: options.name, testing: options.testing };
                    return [4 /*yield*/, transformResponse(axiosInstance.put(url, body))];
                case 1:
                    res = _a.sent();
                    return [2 /*return*/, res.scenario];
            }
        });
    });
}
export function createScenario(chatbotId, options) {
    return __awaiter(this, void 0, Promise, function () {
        var url, body, res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    url = collection(chatbotId);
                    body = { chat_type: options.chatType };
                    return [4 /*yield*/, transformResponse(axiosInstance.post(url, body))];
                case 1:
                    res = _a.sent();
                    return [2 /*return*/, res.scenario];
            }
        });
    });
}
export function validateScenario(chatbotId, scenarioId) {
    return __awaiter(this, void 0, Promise, function () {
        var url;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    url = validate(chatbotId, scenarioId);
                    return [4 /*yield*/, transformResponse(axiosInstance.post(url))];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    });
}
export function createScenarioFromTemplate(chatbotId, options) {
    return __awaiter(this, void 0, Promise, function () {
        var url, body, res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    url = template(chatbotId);
                    body = {
                        scenario_name: options.name,
                        chat_type: options.chatType,
                        template_id: options.templateId,
                    };
                    return [4 /*yield*/, transformResponse(axiosInstance.post(url, body))];
                case 1:
                    res = _a.sent();
                    return [2 /*return*/, res.scenario];
            }
        });
    });
}
export function duplicateScenario(chatbotId, scenarioId) {
    return __awaiter(this, void 0, Promise, function () {
        var url;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    url = duplicate(chatbotId, scenarioId);
                    return [4 /*yield*/, transformResponse(axiosInstance.post(url))];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    });
}
export function deleteScenario(chatbotId, scenarioId, chatType) {
    return __awaiter(this, void 0, Promise, function () {
        var url, body;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    url = member(chatbotId, scenarioId);
                    body = { chat_type: chatType };
                    return [4 /*yield*/, transformResponse(axiosInstance.delete(url, { data: body }))];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    });
}
export function deleteScenarios(chatbotId, scenarioIds) {
    return __awaiter(this, void 0, Promise, function () {
        var url, query;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    url = deleteScenariosUrl(chatbotId);
                    query = objToQuery({ ad_scenario_ids: scenarioIds });
                    return [4 /*yield*/, transformResponse(axiosInstance.post(url + "?" + query))];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    });
}
export function getScenarios__LEGACY(chatbotId) {
    return __awaiter(this, void 0, Promise, function () {
        var url, res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    url = oldCollection(chatbotId);
                    return [4 /*yield*/, transformResponse(axiosInstance.get(url))];
                case 1:
                    res = _a.sent();
                    return [2 /*return*/, res.scenarios];
            }
        });
    });
}
export function getScenarioMap(chatbotId, scenarioId, token) {
    return __awaiter(this, void 0, Promise, function () {
        var url, res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    url = scenarioMap.map(chatbotId, scenarioId, token);
                    return [4 /*yield*/, transformResponse(axiosInstance.get(url))];
                case 1:
                    res = _a.sent();
                    return [2 /*return*/, res];
            }
        });
    });
}
export function getScenarioMap__LEGACY(chatbotId, scenarioId, token) {
    return __awaiter(this, void 0, Promise, function () {
        var url, res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    url = scenarioMap.member(chatbotId, scenarioId, token);
                    return [4 /*yield*/, transformResponse(axiosInstance.get(url))];
                case 1:
                    res = _a.sent();
                    return [2 /*return*/, res];
            }
        });
    });
}
export function getShareToken(chatbotId, scenarioId) {
    return __awaiter(this, void 0, void 0, function () {
        var url, res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    url = member(chatbotId, scenarioId) + "/scenario_share_tokens";
                    return [4 /*yield*/, transformResponse(axiosInstance.post(url))];
                case 1:
                    res = _a.sent();
                    return [2 /*return*/, {
                            chatbotId: res.scenario_share_token.chatbot_id,
                            scenarioId: res.scenario_share_token.scenario_id,
                            token: res.scenario_share_token.token,
                        }];
            }
        });
    });
}
export function talkSend(chatbotId, scenarioId, endUserId) {
    return __awaiter(this, void 0, void 0, function () {
        var url, res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    url = "/chatbots/" + chatbotId + "/talk_room/scenarios/" + scenarioId + "/send_scenario";
                    return [4 /*yield*/, transformResponse(axiosInstance.post(url, { end_user_id: endUserId }))];
                case 1:
                    res = _a.sent();
                    return [2 /*return*/, {
                            status: res.status,
                            message: res.message,
                            eventLogs: res.end_user_event_logs,
                        }];
            }
        });
    });
}
export function getScenarioJson(chatbotId, scenarioId, metaHash) {
    return __awaiter(this, void 0, void 0, function () {
        var url, body, res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    url = member(chatbotId, scenarioId) + "/retrieve_json";
                    body = {
                        meta_hash: metaHash,
                    };
                    return [4 /*yield*/, transformResponse(axiosInstance.post(url, body))];
                case 1:
                    res = _a.sent();
                    return [2 /*return*/, res];
            }
        });
    });
}
export function getAnswer(chatbotId, scenarioId, body) {
    return __awaiter(this, void 0, Promise, function () {
        var url, res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    url = "webchats/chatbots/" + chatbotId + "/scenarios/" + scenarioId + "/user_response";
                    return [4 /*yield*/, transformResponse(axiosInstance.post(url, body))];
                case 1:
                    res = _a.sent();
                    return [2 /*return*/, res];
            }
        });
    });
}
