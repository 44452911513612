var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState } from 'react';
import * as washiColors from '@zeals-co-ltd/washi-styles';
import { useParams } from 'react-router-dom';
import { Dialog, Button, Snackbar } from '@zeals-co-ltd/washi-components';
import Grid from '@mui/material/Grid';
import { injectIntl } from 'react-intl';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { createInitialConversationABTestGroupAsDraft, updateDraftInitialConversation, } from '@src/services/api/initialConversation';
import useSnackbarNotify from '@src/components/hooks/useSnackbarNotify';
import ABTestingModalTable from './ABTestSettingTable';
import SelectedScenariosList from './SelectedScenariosList';
import { getDraftFunctionType } from './common';
var InitialConversationContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  background: ", ";\n  border-radius: 8px;\n  margin: 1em 0.5em 1em 1em;\n  padding: 1.5em 1.5em 1em 1.5em;\n  min-height: calc(100vh - 156px);\n  flex-wrap: wrap;\n  display: flex;\n\n  .addSelectedBtn {\n    margin-left: auto;\n  }\n\n  .deleteTextcolor {\n    color: red;\n  }\n"], ["\n  background: ", ";\n  border-radius: 8px;\n  margin: 1em 0.5em 1em 1em;\n  padding: 1.5em 1.5em 1em 1.5em;\n  min-height: calc(100vh - 156px);\n  flex-wrap: wrap;\n  display: flex;\n\n  .addSelectedBtn {\n    margin-left: auto;\n  }\n\n  .deleteTextcolor {\n    color: red;\n  }\n"])), washiColors.grey[50]);
var ABTestSettingModal = function (_a) {
    var intl = _a.intl, isInitialConversationActive = _a.isInitialConversationActive, onTabChange = _a.onTabChange;
    var params = useParams();
    var chatbotId = parseInt(params.chatbotId);
    var _b = useSnackbarNotify(), notify = _b.notify, setNotify = _b.setNotify, clearNotify = _b.clearNotify, open = _b.open, setOpen = _b.setOpen;
    var _c = useSelector(function (state) {
        var functionType = getDraftFunctionType(state.initialConversation);
        return {
            activeInitialConversation: state.initialConversation.getActiveInitialConversation,
            activeDraftConversation: state.initialConversation.getDraftInitialConversation,
            functionType: functionType,
        };
    }), activeInitialConversation = _c.activeInitialConversation, activeDraftConversation = _c.activeDraftConversation, functionType = _c.functionType;
    var selectedScenarios = useSelector(function (state) { return state.initialConversation.selectedScenarios; });
    var allAvailableScenario = useSelector(function (state) { return state.initialConversation.scenarios; });
    var draftInitialConversation = useSelector(function (state) { return state.initialConversation.getDraftInitialConversation; });
    var draftScenarios = useSelector(function (state) { return state.initialConversation.ABTesting.draft; });
    var payload = function (_a) {
        var functionType = _a.functionType, selectedScenarios = _a.selectedScenarios;
        return {
            functionType: functionType,
            name: 'A/B Test1',
            variants: selectedScenarios.map(function (s) {
                var weight = s.weight, id = s.id;
                return {
                    scenario_id: id,
                    weight: weight / 100,
                };
            }),
            chatbotId: chatbotId,
        };
    };
    var setAsDraft = function () { return __awaiter(void 0, void 0, void 0, function () {
        var body, err_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    body = payload({ functionType: functionType, selectedScenarios: selectedScenarios });
                    return [4 /*yield*/, createInitialConversationABTestGroupAsDraft(body)];
                case 1:
                    _a.sent();
                    isInitialConversationActive(true);
                    return [3 /*break*/, 3];
                case 2:
                    err_1 = _a.sent();
                    setNotify({
                        severity: 'error',
                        message: err_1.message,
                    });
                    setOpen(true);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var updateDraft = function () { return __awaiter(void 0, void 0, void 0, function () {
        var body, draftInitialConversationId, err_2;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, , 3]);
                    body = payload({ functionType: functionType, selectedScenarios: selectedScenarios });
                    draftInitialConversationId = (_a = draftScenarios[0]) === null || _a === void 0 ? void 0 : _a.id;
                    return [4 /*yield*/, updateDraftInitialConversation(body, draftInitialConversationId)];
                case 1:
                    _b.sent();
                    isInitialConversationActive(true);
                    return [3 /*break*/, 3];
                case 2:
                    err_2 = _b.sent();
                    setNotify({
                        severity: 'error',
                        message: err_2.message,
                    });
                    setOpen(true);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    // Redux state end TODO API integration
    var _d = __read(useState(false), 2), cancelConfirmation = _d[0], setCancelConfirmation = _d[1];
    var handleInActiveIcCancelEven = function () {
        isInitialConversationActive(true);
    };
    return (React.createElement(InitialConversationContainer, { className: "InitialConversationContainer", "data-testid": "InitialConversation" },
        React.createElement(Snackbar, { open: open, alertProps: { severity: notify.severity }, onClose: clearNotify },
            React.createElement("span", null, notify.message)),
        React.createElement(Grid, { container: true, columnSpacing: { xs: 2 } },
            React.createElement(Grid, { item: true, xs: 7 },
                React.createElement(ABTestingModalTable, { allAvailableScenario: allAvailableScenario })),
            React.createElement(Grid, { item: true, xs: 5 },
                React.createElement(SelectedScenariosList, { selectedScenario: selectedScenarios }))),
        React.createElement(Grid, { sx: { margin: 'auto 0 0', flex: '100', paddingTop: '24px' } },
            React.createElement(Grid, { container: true, justifyContent: "flex-end", columnSpacing: { xs: 2 } },
                React.createElement(Grid, { item: true, sx: { display: 'flex' } },
                    React.createElement(Button, { className: "addSelectedBtn", color: "primary", "data-testid": "ABTestSettingModal_CancelSaveOrUpdateDaft", onClick: function () { return setCancelConfirmation(true); } }, intl.formatMessage({ id: "47FYwb", defaultMessage: "Cancel" }))),
                React.createElement(Grid, { item: true },
                    React.createElement(Button, { className: "addSelectedBtn", variant: "outlined", color: "primary", "data-testid": "ABTestSettingModal_SaveOrUpdateDaft", disabled: selectedScenarios.length < 1, onClick: function () {
                            return draftInitialConversation ? updateDraft() : setAsDraft();
                        } }, draftInitialConversation
                        ? intl.formatMessage({ id: "+1SyDy", defaultMessage: "Update Draft" })
                        : intl.formatMessage({ id: "JHJJAH", defaultMessage: "Save as draft" }))))),
        React.createElement(Dialog, { fullWidth: true, maxWidth: "xs", title: intl.formatMessage({ id: "EcQJkQ", defaultMessage: "Setting not completed yet." }), supportText: intl.formatMessage({ id: "+udiNc", defaultMessage: "Is it OK to go back to the A/B TEST SETTING page?" }), dialogType: "error", hideDividers: false, open: cancelConfirmation, "data-testid": "field-attribute-local-delete-confirmation-modal", mainButtonLabel: intl.formatMessage({ id: "VNX4fn", defaultMessage: "Proceed" }), mainButtonOnClick: activeInitialConversation || activeDraftConversation
                ? handleInActiveIcCancelEven
                : onTabChange, cancelButtonLabel: intl.formatMessage({ id: "47FYwb", defaultMessage: "Cancel" }), onClose: function () { return setCancelConfirmation(false); }, classes: { paper: 'deleteTextcolor' } })));
};
export default injectIntl(ABTestSettingModal);
var templateObject_1;
