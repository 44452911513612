var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import { Composition } from 'atomic-layout';
import { Button } from '@src/components/atoms';
import { injectIntl } from 'react-intl';
import { copyToClipboard } from '@src/lib/helpers';
import buttonTypes from '@src/lib/buttonTypes';
var PrivacyPolicyPreviewContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  .preview-url {\n    font-size: 0.8rem;\n  }\n"], ["\n  .preview-url {\n    font-size: 0.8rem;\n  }\n"])));
function PrivacyPolicyPreview(_a) {
    var intl = _a.intl, privacyPolicyUrl = _a.privacyPolicyUrl, disabled = _a.disabled;
    return (React.createElement(Composition, { height: "100%", padding: "2rem", areas: "title\n        previewWindow\n        controls", templateRows: "24px auto 40px", as: PrivacyPolicyPreviewContainer }, function (PreviewAreas) { return (React.createElement(React.Fragment, null,
        React.createElement(PreviewAreas.Title, null,
            React.createElement("div", { className: "label" }, intl.formatMessage({ id: "TJo5E6", defaultMessage: "Preview" }))),
        React.createElement(PreviewAreas.PreviewWindow, null,
            React.createElement("iframe", { src: privacyPolicyUrl, frameBorder: "0" })),
        React.createElement(PreviewAreas.Controls, { className: "pull-right" },
            !disabled && (React.createElement("span", { className: "preview-url" }, privacyPolicyUrl)),
            React.createElement(Button, { buttonType: disabled ? buttonTypes.DISABLED : buttonTypes.SETTING_WHITE, text: intl.formatMessage({ id: "4l6vz1", defaultMessage: "Copy" }), margin: "10px 0 0 10px", width: 'inherit', onClick: function () { return copyToClipboard(privacyPolicyUrl); }, iconClassName: "adicon_duplicate", disabled: disabled, dataTest: "copy-button" })))); }));
}
export default injectIntl(PrivacyPolicyPreview);
var templateObject_1;
