import "core-js/modules/es.symbol";
import "core-js/modules/es.array.filter";
import "core-js/modules/es.array.find";
import "core-js/modules/es.array.map";
import "core-js/modules/es.object.get-own-property-descriptor";
import "core-js/modules/es.object.get-own-property-descriptors";
import "core-js/modules/es.object.keys";
import "core-js/modules/web.dom-collections.for-each";
import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

export function filterEndUsers(endUsers, idToFilter) {
  return endUsers.map(function (endUser) {
    var _endUser$last_message;

    if (endUser.id === idToFilter && Object.keys(endUser.last_message || {}).length && (endUser === null || endUser === void 0 ? void 0 : (_endUser$last_message = endUser.last_message) === null || _endUser$last_message === void 0 ? void 0 : _endUser$last_message.client_read) === false) {
      return _objectSpread({}, endUser, {
        last_message: _objectSpread({}, endUser.last_message || {}, {
          end_user_read: undefined,
          client_read: true
        })
      });
    }

    return endUser;
  });
}
export function updateUserDetails(endUsers, action) {
  if (!endUsers.length) return [];
  var endUserExists = endUsers.find(function (u) {
    return u.id === action.endUser.id;
  });
  if (!endUserExists) return endUsers;
  return endUsers.map(function (eu) {
    if (eu.id === action.endUser.id) return _objectSpread({}, eu, {}, action.endUser);
    return eu;
  });
}
export var updateEndUsers = function updateEndUsers(state, action) {
  return state.endUsers.map(function (endUser) {
    if (endUser.id === action.endUserId) {
      return _objectSpread({}, action.newEndUser);
    }

    return endUser;
  });
};