var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import { Input } from '@src/components/atoms';
import { injectIntl } from 'react-intl';
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  .frequency-row {\n    display: inline-flex;\n    align-items: center;\n    margin-top: 12px;\n    .frequency-input {\n      width: 80px;\n      margin-right: 8px;\n    }\n  }\n"], ["\n  .frequency-row {\n    display: inline-flex;\n    align-items: center;\n    margin-top: 12px;\n    .frequency-input {\n      width: 80px;\n      margin-right: 8px;\n    }\n  }\n"])));
var defaultProps = {
    frequencyMinutes: '10',
};
var FrequencyTime = function (_a) {
    var frequencyMinutes = _a.frequencyMinutes, onChangeFrequencyMinutes = _a.onChangeFrequencyMinutes, intl = _a.intl;
    return (React.createElement(Container, null,
        React.createElement("div", { className: "message-wrapper primary-label" }, intl.formatMessage({ id: "Lb57eH", defaultMessage: "Time (minutes) for the second distribution ater sending via retargeting" })),
        React.createElement("div", { className: "frequency-row" },
            React.createElement(Input, { className: "frequency-input", type: "number", value: frequencyMinutes, onChange: function (e) { return onChangeFrequencyMinutes(e.target.value); } }),
            React.createElement("span", null, "\u5206"))));
};
export default injectIntl(FrequencyTime);
var templateObject_1;
