export var SettingTypes;
(function (SettingTypes) {
    SettingTypes["TOGGLE_FETCHING_SETTINGS"] = "TOGGLE_FETCHING_SETTINGS";
    SettingTypes["TOGGLE_WELCOME_BOARD"] = "TOGGLE_WELCOME_BOARD";
    SettingTypes["TOGGLE_MAIN_MENU"] = "TOGGLE_MAIN_MENU";
    SettingTypes["TOGGLE_DEFAULT_MESSAGE"] = "TOGGLE_DEFAULT_MESSAGE";
    SettingTypes["TOGGLE_DISCONNECT_MESSAGE"] = "TOGGLE_DISCONNECT_MESSAGE";
    SettingTypes["GET_WELCOME_MESSAGE_TEXT"] = "GET_WELCOME_MESSAGE_TEXT";
    SettingTypes["GET_DEFAULT_MSG_TEXT"] = "GET_DEFAULT_MSG_TEXT";
    SettingTypes["CHANGE_DEFAULT_OPTION"] = "CHANGE_DEFAULT_OPTION";
    SettingTypes["SET_PAGE_LIST"] = "SET_PAGE_LIST";
    SettingTypes["SET_DEFAULT_OPTION_SELECTED"] = "SET_DEFAULT_OPTION_SELECTED";
    SettingTypes["HANDLE_CHATBOT_UPDATE"] = "HANDLE_CHATBOT_UPDATE";
    SettingTypes["UPDATE_PERSISTENT_MENU"] = "UPDATE_PERSISTENT_MENU";
    SettingTypes["UPDATE_CHATBOT_STATUS"] = "UPDATE_CHATBOT_STATUS";
    SettingTypes["UPDATE_FULFILLED"] = "UPDATE_FULFILLED";
    SettingTypes["REMOVE_FULFILLED"] = "REMOVE_FULFILLED";
    SettingTypes["CONNECT_PAGE"] = "CONNECT_PAGE";
    SettingTypes["API_FAILED"] = "API_FAILED";
    SettingTypes["UPDATE_PLUGIN_MESSAGE"] = "UPDATE_PLUGIN_MESSAGE";
    SettingTypes["UPDATE_PLUGIN_TITLE"] = "UPDATE_PLUGIN_TITLE";
    SettingTypes["UPDATE_PLUGIN_BUTTON"] = "UPDATE_PLUGIN_BUTTON";
    SettingTypes["AD_HANDLE_NEW_PERSISTENT_MENU_ITEM"] = "AD_HANDLE_NEW_PERSISTENT_MENU_ITEM";
    SettingTypes["AD_SET_PERSISTENT_MENU_ITEMS"] = "AD_SET_PERSISTENT_MENU_ITEMS";
    SettingTypes["AD_DELETE_PERSISTENT_MENU_ITEM"] = "AD_DELETE_PERSISTENT_MENU_ITEM";
})(SettingTypes || (SettingTypes = {}));
