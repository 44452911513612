var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import { lineFlexMessageStyles } from '@src/lib/app-constants';
import { getFlex } from '@src/lib/helpers';
var BoxContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: ", ";\n  align-items: ", ";\n  flex: ", ";\n"], ["\n  display: flex;\n  flex-direction: ",
    ";\n  align-items: ", ";\n  flex: ", ";\n"])), function (props) {
    return lineFlexMessageStyles.flexDirections[props.layout];
}, function (props) { return props.layout || 'unset'; }, function (props) { return getFlex(props.flex, props.parentLayout); });
var BoxNode = function (_a) {
    var lineBox = _a.lineBox, parentLayout = _a.parentLayout, contentsRenderer = _a.contentsRenderer, updateBoundaryNode = _a.updateBoundaryNode;
    return (React.createElement(BoxContainer, { layout: lineBox.layout, flex: lineBox.flex, parentLayout: parentLayout }, contentsRenderer(lineBox.contents, lineBox.layout, updateBoundaryNode)));
};
export default BoxNode;
var templateObject_1;
