var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { colors } from '@src/colors';
import { centeredFlex } from '@src/lib/mixins';
import { createTalkTrigger, deleteTalkTrigger, } from '@src/redux/custom_form_admin/actions/workPanel';
var SectionContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n"], ["\n  ", ";\n"])), centeredFlex);
var ScenarioTalkTriggers = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  flex: 3;\n  width: 100%;\n  padding-right: 10px;\n  border-right: 2px solid ", ";\n"], ["\n  flex: 3;\n  width: 100%;\n  padding-right: 10px;\n  border-right: 2px solid ", ";\n"])), colors.border.default);
var TalkTriggerContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  flex: 5;\n  margin-left: 1.5rem;\n"], ["\n  flex: 5;\n  margin-left: 1.5rem;\n"])));
var TalkTriggerOverlay = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  ", ";\n  background: ", ";\n  opacity: 0.7;\n  width: 100%;\n  height: 100%;\n  position: absolute;\n  left: 0;\n  top: 0;\n  border-radius: 5px;\n  display: none;\n\n  &.adicon_plus01 {\n    font-size: 40px;\n    color: white;\n  }\n"], ["\n  ", ";\n  background: ", ";\n  opacity: 0.7;\n  width: 100%;\n  height: 100%;\n  position: absolute;\n  left: 0;\n  top: 0;\n  border-radius: 5px;\n  display: none;\n\n  &.adicon_plus01 {\n    font-size: 40px;\n    color: white;\n  }\n"])), centeredFlex, colors.custom_form_admin.main_color);
var TalkTriggerWrapper = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  margin-top: 0.5rem;\n  border: 1px solid\n    ", ";\n  padding: 0.5rem;\n  border-radius: 5px;\n  position: relative;\n  transition: 0.3s;\n\n  .talk-trigger-label {\n    font-weight: bold;\n    margin-bottom: 0.5rem;\n\n    .talk-trigger-id {\n      float: right;\n      font-weight: normal;\n    }\n  }\n\n  .previous-message-label {\n    font-size: 12px;\n    font-style: italic;\n  }\n\n  &:hover {\n    .adicon_plus01 {\n      display: flex;\n    }\n  }\n"], ["\n  margin-top: 0.5rem;\n  border: 1px solid\n    ",
    ";\n  padding: 0.5rem;\n  border-radius: 5px;\n  position: relative;\n  transition: 0.3s;\n\n  .talk-trigger-label {\n    font-weight: bold;\n    margin-bottom: 0.5rem;\n\n    .talk-trigger-id {\n      float: right;\n      font-weight: normal;\n    }\n  }\n\n  .previous-message-label {\n    font-size: 12px;\n    font-style: italic;\n  }\n\n  &:hover {\n    .adicon_plus01 {\n      display: flex;\n    }\n  }\n"])), function (props) {
    return props.isConnected ? colors.custom_form_admin.hover : 'transparent';
});
var PreviousMessageText = styled.p(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  border: 1px solid ", ";\n  padding: 1rem;\n  background: ", ";\n  border-radius: 5px;\n  overflow: auto;\n  width: ", ";\n"], ["\n  border: 1px solid ", ";\n  padding: 1rem;\n  background: ", ";\n  border-radius: 5px;\n  overflow: auto;\n  width: ", ";\n"])), colors.placeholder, colors.background.tag, function (props) { return (props.isConnected ? '95%' : '100%'); });
var TalkTriggersHeader = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  font-size: 14px;\n  p {\n    margin-top: 0.5rem;\n  }\n  .trigger-label {\n    font-weight: bold;\n    margin-right: 0.5rem;\n  }\n"], ["\n  font-size: 14px;\n  p {\n    margin-top: 0.5rem;\n  }\n  .trigger-label {\n    font-weight: bold;\n    margin-right: 0.5rem;\n  }\n"])));
var DeleteIcon = styled.span(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  color: ", ";\n  font-size: 20px;\n  position: absolute;\n  top: 50px;\n  right: 5px;\n  transition: 0.3s;\n\n  &:hover {\n    cursor: pointer;\n    border-color: ", ";\n    &:before {\n      color: ", ";\n    }\n  }\n"], ["\n  color: ", ";\n  font-size: 20px;\n  position: absolute;\n  top: 50px;\n  right: 5px;\n  transition: 0.3s;\n\n  &:hover {\n    cursor: pointer;\n    border-color: ", ";\n    &:before {\n      color: ", ";\n    }\n  }\n"])), colors.icon, colors.background.error, colors.background.error);
var TalkTrigger = function (_a) {
    var isConnected = _a.isConnected, talkTriggerId = _a.talkTriggerId, prevMessageText = _a.prevMessageText, handleDelete = _a.handleDelete, blockName = _a.blockName, onClick = _a.onClick;
    return (React.createElement(TalkTriggerWrapper, { isConnected: isConnected, onClick: onClick, "data-testid": "TalkTrigger-Wrapper" },
        isConnected ? (React.createElement(DeleteIcon, { className: "adicon_minus01", onClick: handleDelete, "data-testid": "TalkTrigger-DeleteButton" })) : (React.createElement(TalkTriggerOverlay, { className: "adicon_plus01" })),
        React.createElement("p", { className: "talk-trigger-label" },
            blockName,
            React.createElement("span", { className: "talk-trigger-id" }, talkTriggerId)),
        React.createElement(PreviousMessageText, { isConnected: isConnected }, prevMessageText)));
};
var TalkTriggerSection = function (_a) {
    var connectedTalkTriggers = _a.connectedTalkTriggers, talkTriggers = _a.talkTriggers, selectedScenario = _a.selectedScenario, selectedForm = _a.selectedForm, questionGroup = _a.questionGroup, id = _a.id;
    var dispatch = useDispatch();
    talkTriggers = talkTriggers || [];
    var filteredTalkTriggers = talkTriggers.filter(function (trigger) { return !connectedTalkTriggers.some(function (item) { return item.id === trigger.id; }); });
    var questionId = id;
    return (React.createElement(SectionContainer, { "data-testid": "TalkTriggerSection" },
        React.createElement(ScenarioTalkTriggers, null,
            selectedScenario ? (React.createElement(TalkTriggersHeader, null,
                React.createElement("p", null,
                    React.createElement("span", { className: "trigger-label" }, "Scenario: "),
                    selectedScenario.name),
                React.createElement("p", null,
                    React.createElement("span", { className: "trigger-label" }, "ID: "),
                    selectedScenario.id),
                React.createElement("div", { className: "message-wrapper primary-label", style: { margin: '16px 0 0 8px' } }, "Talk Trigger List"))) : (React.createElement("p", null, "\u30B7\u30CA\u30EA\u30AA\u304C\u9078\u629E\u3055\u308C\u3066\u3044\u307E\u305B\u3093")),
            filteredTalkTriggers.length
                ? filteredTalkTriggers.map(function (_a) {
                    var id = _a.id, block = _a.block, prev_message = _a.prev_message;
                    return (React.createElement(TalkTrigger, { "data-testid": "TalkTrigger", key: "talk-trigger-" + id, talkTriggerId: id, blockName: block.name, prevMessageText: prev_message.text, onClick: function () {
                            return dispatch(createTalkTrigger({
                                selectedForm: selectedForm,
                                questionGroup: questionGroup,
                                questionId: questionId,
                                talkTriggerId: id,
                            }));
                        } }));
                })
                : null),
        React.createElement(TalkTriggerContainer, null,
            React.createElement("div", { className: "message-wrapper primary-label" }, "Connected Talk Triggers"),
            connectedTalkTriggers.length
                ? connectedTalkTriggers.map(function (_a) {
                    var id = _a.id, block = _a.block, prev_message = _a.prev_message;
                    return (React.createElement(TalkTrigger, { key: "connected-talk-trigger-" + id, talkTriggerId: id, blockName: block.name, prevMessageText: prev_message.text, isConnected: true, handleDelete: function () {
                            return dispatch(deleteTalkTrigger({
                                selectedForm: selectedForm,
                                questionGroup: questionGroup,
                                questionId: questionId,
                                talkTriggerId: id,
                            }));
                        } }));
                })
                : null)));
};
export default TalkTriggerSection;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8;
