import "core-js/modules/es.array.concat";
import "core-js/modules/es.array.map";
import "core-js/modules/es.object.entries";
import "core-js/modules/es.string.small";
import _extends from "@babel/runtime/helpers/extends";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
import React from 'react';
import styled from 'styled-components';
import { colors, shadows } from '@src/colors';
import PluginModalForm from './PluginModalForm';
var SettingsContainer = styled.div.withConfig({
  displayName: "SettingsContainer",
  componentId: "sc-899oxs-0"
})(["background:", ";overflow:auto;padding:1rem 2.5rem;border-radius:8px 0 0 8px;box-shadow:", ";.adicon_menu_setting{color:", ";&:before{font-size:20px;}}"], colors.white, shadows.small.mid, colors.icon);
var ErrorContainer = styled.div.withConfig({
  displayName: "ErrorContainer",
  componentId: "sc-899oxs-1"
})(["font-size:10px;margin-top:10px;color:", ";"], colors.error);

var PluginModalSettings = function PluginModalSettings(_ref) {
  var handleColorCodeChange = _ref.handleColorCodeChange,
      handleModalTypeChange = _ref.handleModalTypeChange,
      handleChatbotIdChange = _ref.handleChatbotIdChange,
      selectChatbotOptions = _ref.selectChatbotOptions,
      formikProps = _objectWithoutProperties(_ref, ["handleColorCodeChange", "handleModalTypeChange", "handleChatbotIdChange", "selectChatbotOptions"]);

  return React.createElement(SettingsContainer, null, React.createElement("p", {
    className: "adicon_menu_setting"
  }, "\u8A2D\u5B9A"), React.createElement(ErrorContainer, null, Object.entries(formikProps.errors).map(function (_ref2, i) {
    var _ref3 = _slicedToArray(_ref2, 2),
        key = _ref3[0],
        val = _ref3[1];

    return React.createElement("p", {
      key: "modal-form-error-".concat(i)
    }, "".concat(key, ": ").concat(val));
  })), React.createElement(PluginModalForm, _extends({}, formikProps, {
    handleColorCodeChange: handleColorCodeChange,
    handleModalTypeChange: handleModalTypeChange,
    handleChatbotIdChange: handleChatbotIdChange,
    selectChatbotOptions: selectChatbotOptions
  })));
};

export default PluginModalSettings;