var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import { colors } from '@src/colors';
import { injectIntl } from 'react-intl';
var AddConditionalGroupContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin: 1rem 0 0;\n  padding: 0.2rem 0 0.5rem;\n  border-top: 1px solid ", ";\n  border-bottom: 1px solid ", ";\n  cursor: pointer;\n"], ["\n  margin: 1rem 0 0;\n  padding: 0.2rem 0 0.5rem;\n  border-top: 1px solid ", ";\n  border-bottom: 1px solid ", ";\n  cursor: pointer;\n"])), colors.border.default, colors.border.default);
var defaultProps = {
    onClick: function () { return undefined; },
};
var AddConditionalGroup = function (_a) {
    var onClick = _a.onClick, intl = _a.intl;
    return (React.createElement(AddConditionalGroupContainer, { onClick: onClick, "data-testid": "AddConditionalGroupContainer" },
        React.createElement("span", { className: "adicon_plus02" }),
        React.createElement("span", null, intl.formatMessage({ id: "9KRRe8", defaultMessage: "Add Condition" }))));
};
export default injectIntl(AddConditionalGroup);
var templateObject_1;
