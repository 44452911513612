var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import { Input } from '@src/components/atoms';
import { colors } from '@src/colors';
import { centeredFlex } from '@src/lib/mixins';
var GeneralPropertyContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  margin: 20px 0;\n  label {\n    flex: 1;\n    font-weight: bold;\n    font-size: 16px;\n    color: ", ";\n  }\n  span {\n    font-size: 12px;\n    color: ", ";\n    opacity: 0.5;\n  }\n  > div {\n    flex: 3;\n  }\n  .fix-top {\n    padding-top: 8px;\n    display: flex;\n    align-self: flex-start;\n  }\n"], ["\n  display: flex;\n  align-items: center;\n  margin: 20px 0;\n  label {\n    flex: 1;\n    font-weight: bold;\n    font-size: 16px;\n    color: ", ";\n  }\n  span {\n    font-size: 12px;\n    color: ", ";\n    opacity: 0.5;\n  }\n  > div {\n    flex: 3;\n  }\n  .fix-top {\n    padding-top: 8px;\n    display: flex;\n    align-self: flex-start;\n  }\n"])), colors.custom_form_admin.main_color, colors.custom_form_admin.main_color);
var InputWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 48%;\n  display: flex;\n  .general_property_input {\n    border-radius: 5px 0 0 5px;\n    width: 85%;\n  }\n"], ["\n  width: 48%;\n  display: flex;\n  .general_property_input {\n    border-radius: 5px 0 0 5px;\n    width: 85%;\n  }\n"])));
var TwoColumnWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  justify-content: space-between;\n"], ["\n  display: flex;\n  justify-content: space-between;\n"])));
var Button = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  width: 15%;\n  height: 38px;\n  background-color: ", ";\n  ", ";\n  border-top-right-radius: 5px;\n  border-bottom-right-radius: 5px;\n  color: white;\n  cursor: pointer;\n"], ["\n  width: 15%;\n  height: 38px;\n  background-color: ", ";\n  ", ";\n  border-top-right-radius: 5px;\n  border-bottom-right-radius: 5px;\n  color: white;\n  cursor: pointer;\n"])), colors.custom_form_admin.main_color, centeredFlex);
var ExtraValueWrapper = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  width: 48%;\n  display: flex;\n  flex-direction: column;\n"], ["\n  width: 48%;\n  display: flex;\n  flex-direction: column;\n"])));
var ExtraValue = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  height: 38px;\n  margin-bottom: 10px;\n  padding-left: 10px;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  background-color: ", ";\n  border-radius: 5px;\n  p {\n    display: flex;\n    align-items: center;\n  }\n  div {\n    display: flex;\n    align-items: center;\n  }\n"], ["\n  height: 38px;\n  margin-bottom: 10px;\n  padding-left: 10px;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  background-color: ", ";\n  border-radius: 5px;\n  p {\n    display: flex;\n    align-items: center;\n  }\n  div {\n    display: flex;\n    align-items: center;\n  }\n"])), colors.custom_form_admin.transparent_color);
var ExtraValueButton = styled.button(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  width: 20px;\n  height: 20px;\n  margin-right: 10px;\n  border-radius: 50%;\n  text-align: center;\n  color: ", ";\n  background-color: ", ";\n  cursor: pointer;\n"], ["\n  width: 20px;\n  height: 20px;\n  margin-right: 10px;\n  border-radius: 50%;\n  text-align: center;\n  color: ", ";\n  background-color: ", ";\n  cursor: pointer;\n"])), colors.custom_form_admin.main_color, colors.custom_form_admin.transparent_color);
var ExtraValues = function (_a) {
    var extraValues = _a.extraValues, removeExtraStepValue = _a.removeExtraStepValue, headlessPage = _a.headlessPage, originalStepId = _a.originalStepId;
    return (React.createElement(ExtraValueWrapper, null, extraValues.length ? (extraValues.map(function (extra) { return (React.createElement(ExtraValue, { key: extra },
        React.createElement("p", null, extra),
        React.createElement("div", null,
            React.createElement(ExtraValueButton, { className: "adicon_minus02", onClick: function () {
                    return removeExtraStepValue(originalStepId, headlessPage, extra);
                } })))); })) : (React.createElement(ExtraValue, null,
        React.createElement("p", null, "Value\u304C\u63A5\u7D9A\u3057\u3066\u3044\u307E\u305B\u3093")))));
};
var GeneralProperty = function (_a) {
    var name = _a.name, subName = _a.subName, value = _a.value, onChange = _a.onChange, _b = _a.type, type = _b === void 0 ? 'text' : _b, isAddExtraValue = _a.isAddExtraValue, onClick = _a.onClick, onFocus = _a.onFocus, placeholder = _a.placeholder, extraValues = _a.extraValues, originalStepId = _a.originalStepId, removeExtraStepValue = _a.removeExtraStepValue, headlessPage = _a.headlessPage;
    return (React.createElement(GeneralPropertyContainer, null, isAddExtraValue ? (React.createElement(React.Fragment, null,
        React.createElement("label", { className: "fix-top", htmlFor: "input-name" },
            name,
            React.createElement("span", null, subName)),
        React.createElement(TwoColumnWrapper, null,
            React.createElement(InputWrapper, null,
                React.createElement(Input, { className: "general_property_input", placeholder: placeholder, type: type, value: value, onChange: onChange, onFocus: onFocus, "data-testid": "GeneralProperty-input" }),
                React.createElement(Button, { onClick: onClick, className: "adicon_plus02", role: "button" })),
            React.createElement(ExtraValues, { extraValues: extraValues, originalStepId: originalStepId, removeExtraStepValue: removeExtraStepValue, headlessPage: headlessPage })))) : (React.createElement(React.Fragment, null,
        React.createElement("label", { htmlFor: "input-name" },
            name,
            React.createElement("span", null, subName)),
        React.createElement(Input, { placeholder: placeholder, type: type, value: value, onChange: onChange, onFocus: onFocus, "data-testid": "GeneralProperty-input" })))));
};
export default GeneralProperty;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
