var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import Pagination from 'react-js-pagination';
import { zealsPaginationCss } from '@src/lib/mixins';
import { Button } from '@src/components/atoms';
import buttonTypes from '@src/lib/buttonTypes';
import { colors } from '@src/colors';
var InputSearchBoxContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  text-align: center;\n\n  > div:not(:last-child) {\n    margin-bottom: 0.5rem;\n  }\n  .submit-btn {\n    border-radius: 5px !important;\n  }\n  .input-search-box {\n    display: flex;\n\n    input {\n      flex: 3;\n      border-radius: 5px;\n      height: 40px;\n      border: 1px solid ", ";\n      margin-right: 0.5rem;\n      padding: 0.5rem;\n      font-size: 12px;\n    }\n    button {\n      flex: 1;\n    }\n  }\n\n  .item-count {\n    margin-top: 0.5rem;\n    font-size: 12px;\n    font-weight: bold;\n  }\n\n  ", ";\n"], ["\n  text-align: center;\n\n  > div:not(:last-child) {\n    margin-bottom: 0.5rem;\n  }\n  .submit-btn {\n    border-radius: 5px !important;\n  }\n  .input-search-box {\n    display: flex;\n\n    input {\n      flex: 3;\n      border-radius: 5px;\n      height: 40px;\n      border: 1px solid ", ";\n      margin-right: 0.5rem;\n      padding: 0.5rem;\n      font-size: 12px;\n    }\n    button {\n      flex: 1;\n    }\n  }\n\n  .item-count {\n    margin-top: 0.5rem;\n    font-size: 12px;\n    font-weight: bold;\n  }\n\n  ", ";\n"])), colors.border.default, zealsPaginationCss);
var InputSearchBox = function (_a) {
    var placeholder = _a.placeholder, searchTerm = _a.searchTerm, children = _a.children, totalItems = _a.totalItems, _b = _a.itemsCountPerPage, itemsCountPerPage = _b === void 0 ? 20 : _b, activePage = _a.activePage, searchTermChangeFunc = _a.searchTermChangeFunc, searchFunc = _a.searchFunc;
    return (React.createElement(InputSearchBoxContainer, { className: "input-search-container" },
        React.createElement("form", { onSubmit: searchFunc(1), className: "input-search-box" },
            React.createElement("input", { type: "text", placeholder: placeholder, value: searchTerm, onChange: searchTermChangeFunc, "data-testid": "InputSearchBox-search-input" }),
            React.createElement(Button, { text: "\u691C\u7D22", buttonType: buttonTypes.SETTING, onClick: searchFunc(1), className: "submit-btn" })),
        React.createElement("div", { className: "item-count" }, totalItems ? React.createElement("div", null,
            totalItems,
            " \u4EF6\u304C\u898B\u3064\u304B\u308A\u307E\u3057\u305F") : null),
        totalItems ? (React.createElement("div", null,
            React.createElement(Pagination, { activePage: activePage, totalItemsCount: totalItems, itemsCountPerPage: itemsCountPerPage, onChange: function (page) {
                    var initiateSearch = searchFunc(page);
                    initiateSearch(undefined);
                }, innerClass: "zeals-admin-pagination", pageRangeDisplayed: 3 }))) : null,
        React.createElement("div", null, children),
        totalItems ? (React.createElement("div", null,
            React.createElement(Pagination, { activePage: activePage, totalItemsCount: totalItems, itemsCountPerPage: itemsCountPerPage, onChange: function (page) {
                    var initiateSearch = searchFunc(page);
                    initiateSearch(undefined);
                }, innerClass: "zeals-admin-pagination", pageRangeDisplayed: 3 }))) : null));
};
export default InputSearchBox;
var templateObject_1;
