var fontColor = '12,27,108';
export var colors = {
  main_color: '#1739A5',
  accent_color: '#1EC18D',
  default_text: '#091F6C',
  old_navy: '#091F6C',
  area_mask: 'rgba(0,0,0,0.5)',
  text_pale: 'rgba(12,27,108,0.5)',
  text_navy: ' #9DA5C4',
  icon: '#1739A5',
  placeholder: 'rgba(12,27,108,0.2)',
  quick_reply: '#1357DC',
  error: '#E5004B',
  success: '#1EC18D',
  white: '#FFFFFF',
  readonly: '#C4C4C4',
  lock: 'rgba(72,85,145,0.5)',
  grey: '#D8D8D8',
  light_grey: '#898999',
  mid_grey: '#E0E0E0',
  neutral_grey: '#F5F5F5',
  dark: '#071751',
  drop_shadow: '0px 1px 6px 0px rgba(51,51,51,0.3)',
  block_tag: '#00A3FF',
  line_flex_primary: '#17c950',
  line_flex_default: '#42659a',
  light_them_up: '#075CDC',
  midnight: '#0E0E2C',
  silver_lightning: '#D1D7ED',
  light_blue: '#0D44DA',
  blue_zeals: '#0501BF',
  blue_zealsRGB: '5, 1, 191',
  silver_lightening: '#D1D7ED',
  young_navy: '#6C79A7',
  washiWarning50: '#FFE8EE',
  washiWarning900: '#C8000C',
  washiApproval50: '#E5FEF3',
  washiApproval900: '#009255',
  washiSecondary50: '#FEF9E0',
  washiSecondary900: '#FF7900',
  washiPrimary50: '#E7E9FB',
  washiPrimary900: '#0009A6',
  akatsuki: '#E5004B',
  washiPrimaryDark50: '#E3E5EA',
  washiPrimary100: '#C3C7F4',
  washiPrimary: '#0009a6',
  washiPrimary500: '#0D44DA',
  washiDark100: '#BABECD',
  washiDark900: '#0E0E2C',
  washiNeutral500: '#9E9E9E',
  washiNeutral300: '#E0E0E0',
  washiNeutral100: '#F5F5F5',
  washiNeutral50: '#FAFAFA',
  washiWarning400: '#FF1745',
  mgrey: '#828282',
  approval_50: '#E5FEF3',
  approval_600: '#00DB89',
  warning_50: '#FFE8EE',
  warning_600: '#FF0026',
  secondary_50: '#FEF9E0',
  secondary_600: '#FCBD00',
  off_white_disabled: '#9DA5C4',
  washiSecondary100: '#FCF0B1',
  washiWarning100: '#FFC6D1',
  washiApproval100: '#E5FEF3',
  status_grey: '#8E94AB',
  status_green: '#00C77A',
  blue_purple_dark: '#2B3460',
  disabled: '#BDBDBD',
  yellow: '#FACB00',
  light_navy: '#4761E1',
  mid_navy: '#0009A6',
  black: '#000000',
  border_line: '#E0E0E0',
  red_alert: '#E40022',
  disabled_grey2: '#9E9E9E',
  background: {
    main_color: '#1739A5',
    accent_color: '#1EC18D',
    main_menu: '#FFFFFF',
    input: 'rgb(233,233,233)',
    light_purple: 'rgba(23,57,165,0.05)',
    hover_purple: 'rgba(23,57,165,0.2)',
    content: 'rgba(23,57,165,0.1)',
    off_white_background: '#F2F4F7',
    help: 'rgba(71,87,145,0.8)',
    tag: 'rgba(9,31,108,0.1)',
    tag_hover: 'rgba(9,31,108,0.2)',
    active: 'rgba(23,57,165,0.1)',
    active_border: '#1739A5',
    block: '#FFFFFF',
    white: '#FFFFFF',
    neutral_white: '#FAFAFA',
    pale_white: 'rgba(255,255,255,0.2)',
    error: 'linear-gradient(90deg, #E5004B, #BF003F)',
    ok: '#1739A5',
    confirm: 'linear-gradient(90deg, #1CC08D, #127F5D)',
    disabled: '#D8D8D8',
    dark_grey: '#A9A9A9',
    setting: '#091F6C',
    dark: '#071751',
    warning: '#D22E4F',
    tooltip: '#555',
    uploading: '#0E0E2C',
    cloud_nine: '#F2F4F7',
    light_grey: '#FAFAFA',
    blue: '#0501BF',
    lavender: '#E7E9FB',
    cornsilk: '#FEF9E0',
    honeydew: '#E5FEF3',
    darkpurple: '#9AA3ED',
    preview_grey: '#949494'
  },
  shadows: {
    light: 'rgba(0, 0, 0, 0.1)',
    mid: 'rgba(0, 0, 0, 0.2)',
    dark: 'rgba(0, 0, 0, 0.4)',
    white: 'rgba(0, 0, 0, 0.08)'
  },
  border: {
    button: '#1739A5',
    default: 'rgba(23,57,165,0.2)',
    highlight: 'rgba(23,57,165,0.5)',
    table: '#CED2E2',
    pale: 'rgba(23,57,165,0.1)',
    confirm: '#1EC18D',
    error: '#E5004B',
    edit: '#1357DC',
    focus: '#BDE0FF',
    green: '#28D042',
    gray: '#D1D7ED',
    off_white: '#E3E5EA',
    off_dark: '#BABECD'
  },
  hover: {
    main_color: '#1EC18D',
    confirm: 'linear-gradient(90deg, #127F5D, #127F5D)',
    setting: '#1739A5',
    white: '#F2F4F7',
    error: 'linear-gradient(90deg, #BF003F, #BF003F)',
    disabled: '#A9A9A9',
    gray: '#3A455180',
    red_grad: 'linear-gradient(90deg, #EA0090)',
    navy: '#3A3956',
    red: '#E5004B'
  },
  label: {
    black: '#3A3956',
    purple: '#8A7DFF',
    yellow: '#FFB64E',
    blue: '#7FE7FB',
    green_blue: '#1EC18D',
    error: '#E5004B',
    font_dark: 'rgba(0,0,0,0.5)',
    font_light: 'rgba(255,255,255,0.5)',
    cyan_color: '#74B7DD',
    light_pink: '#D58EE0'
  },
  range: {
    off_btn_bg: 'rgba(23,57,165,0.2)',
    off_bg: 'rgba(23,57,165,0.1)',
    off_bg_hover: 'rgba(23,57,165,0.2)',
    on_btn_bg: '#FFFFFF',
    on_bg: '#1EC18D',
    on_bg_hover: '#127F5D'
  },
  filter: {
    red: '#B44444',
    blue: '#3A3856'
  },
  gender: {
    male: '#51A9F0',
    female: '#FF8ECC',
    other: '#EBEBEE'
  },
  platforms: {
    line: '#00c300',
    messenger: '#0084FF',
    webchat: '#A9A9A9'
  },
  line: {
    main: '#00c300'
  },
  instagram: {
    background: "linear-gradient(\n      45deg,\n      #ef7e31 0%,\n      #fd3424 22%,\n      #cc2b20 40%,\n      #ad256b 70%,\n      #8939ae 100%\n    )"
  },
  facebook: {
    messenger: '#3179E7',
    main: '#3B5998'
  },
  custom_form_admin: {
    main_color: '#293039',
    border_dark: '#222222',
    secondary_color: '#647283',
    body_pale: '#F2F4F7',
    hover: '#516071',
    hover_secondary: '#fb5556',
    icon: '#647283',
    form_bg: '#eff2f6',
    white: '#FFFFFF',
    gray_white: '#e7e8ea',
    gray: '#b8b6b6',
    row: {
      odd: '#E3E5E6',
      even: '#F2F2F2'
    },
    light_gray: '#3A4551',
    call_api_button: {
      dark_gray: '#8a94a2',
      blue: '#1357DC',
      green: '#1CC08D',
      yellow: '#dc9400'
    },
    span_gray: '#d1d4d7',
    transparent_color: 'rgba(58, 69, 81, 0.2)'
  },
  osx: {
    controls: {
      bar: {
        background: 'linear-gradient(rgba(232, 230, 232, 1), rgba(210, 208, 210, 1))',
        border: 'rgba(187, 185, 187, 1)'
      },
      urlBar: {
        background: 'rgba(255, 255, 255, 1)',
        border: 'rgba(218, 217, 218, 1)'
      },
      body: {
        background: 'rgba(255, 255, 255, 1)',
        border: 'rgba(186,186,186,1)'
      },
      inner: {
        red: 'rgba(237, 110, 98, 1)',
        yellow: 'rgba(246, 194, 81, 1)',
        green: 'rgba(41, 206, 66, 1)'
      },
      border: {
        red: 'rgba(210, 94, 83, 1)',
        yellow: 'rgba(215, 161, 67, 1)',
        green: 'rgba(25, 183, 45, 1)'
      }
    }
  },
  webchat: {
    drop_shadow: '0 2px 3px 0 #B3B3B3',
    background: 'rgb(245,245,245)',
    backgroundGradient: "linear-gradient(\n      40deg,\n      #9DA5C4 0%,\n      #D1D7ED 100%\n    )"
  },
  chat_link: {
    close: {
      normal: '#333',
      image: '#fff'
    }
  },
  kireimo: {
    // used in WebviewButton for WorkoutBody & ButtonList components
    blue: '#00a7ea'
  },
  lava: {
    main: 'rgba(222,88,13,1)',
    orange: '#f08c00',
    red: '#b71b21'
  },
  // used in Calendar.tsx
  regina: {
    text: '#A37B51',
    brown: '#9a531f',
    buttonColor: '#a37b51',
    weekend: '#ccc'
  },
  workout: {
    blue: '#03236b',
    gold: '#a27300',
    red: '#cc2222',
    grey: '#333333',
    error: '#FAE1E2',
    valid: 'rgb(3, 213, 161)'
  },
  frey_a: {
    blue: '#d6f1ff',
    dark_blue: '#071855',
    off_white: '#f8f8f8',
    grey: '#B1B1B1',
    red: '#D30000'
  },
  zeals_admin: {
    gray: '#3A455133',
    red_grad: 'linear-gradient(90deg, #FB5557 0%, #EA0090 100%)',
    navy: '#3A4551',
    pink: '#FB5557',
    yellow: '#F7C451',
    green: '#28D042'
  },
  payment_rpa: {
    blue: '#2F9CE1',
    grey: 'rgba(0, 0, 0, 0.5)',
    takami_grey: 'rgba(34, 34, 34, 1)',
    policy_color: '#003F73',
    duo_submit: '#BC7164',
    duo_next: '#5FA051',
    canadel_next: '#525798',
    canadel_submit: '#AF8D3F'
  },
  rpa_settings: {
    border_line: '#d1d7ed',
    selected_list_item_background: '#f2f4f7',
    selected_color: '#091f6c',
    blue_zeal: '#0501BF',
    mid_night: '#0E0E2C',
    young_navy: '#9DA5C4',
    old_navy: '#091F6C',
    hover_border_blue: '#075CDC'
  },
  // colors:font
  font_user_comment_date: '#9b9b9b',
  gray_lightest: "rgba(".concat(fontColor, ",0.2)"),
  gray_lighter: "rgba(".concat(fontColor, ",0.4)"),
  gray_light: "rgba(".concat(fontColor, ",0.6)"),
  gray_normal: 'rgba(151,151,151,.2)',
  gray_border2: 'rgba(160,160,160,.6)',
  gray_border3: 'rgba(58, 57, 86, 0.2)',
  gray_text: '#C9CACB',
  bg_darker: 'rgba(23,57,165,0.05)',
  header_font: '#b3b2cf',
  bg_select_hover: "rgba(".concat(fontColor, ",0.06)"),
  hover_delete: '#BF003F',
  off_white_border: '#EEEEEE',
  gray_border: '#C8C9CA',
  // colors:設定ページのセクション枠線
  border_setting_section: '#edf0f5',
  border_analytics: 'rgba(58,57,86,0.1)'
};
export var measurements = {
  message_width: '260px'
};
export var shadows = {
  smallV2: {
    blue_zeals: "0 1px 8px rgba(".concat(colors.blue_zealsRGB, ",0.06)")
  },
  mediumV2: {
    blue_zeals: "0 1px 8px rgba(".concat(colors.blue_zealsRGB, ",0.16)")
  },
  small: {
    light: '0 1px 5px rgba(0,0,0,0.1)',
    mid: '0 1px 5px rgba(0,0,0,0.2)',
    dark: '0 1px 5px rgba(0,0,0,0.4)'
  },
  medium: {
    light: '0 1px 10px rgba(0,0,0,0.1)',
    mid: '0 1px 10px rgba(0,0,0,0.2)',
    dark: '0 1px 10px rgba(0,0,0,0.4)'
  },
  big: {
    light: '0 1px 20px rgba(0,0,0,0.1)',
    mid: '0 1px 20px rgba(0,0,0,0.2)',
    dark: '0 1px 20px rgba(0,0,0,0.4)'
  }
};