import "core-js/modules/es.symbol";
import "core-js/modules/es.array.filter";
import "core-js/modules/es.array.map";
import "core-js/modules/es.array.splice";
import "core-js/modules/es.object.get-own-property-descriptor";
import "core-js/modules/es.object.get-own-property-descriptors";
import "core-js/modules/es.object.keys";
import "core-js/modules/web.dom-collections.for-each";
import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

export var removeTag = function removeTag(state, action) {
  var messageId = action.messageId,
      itemId = action.itemId,
      tagId = action.tagId;
  var msg = state.messages[messageId];
  return {
    messages: _objectSpread({}, state.messages, _defineProperty({}, messageId, _objectSpread({}, msg, {
      generic_template: _objectSpread({}, msg.generic_template, {
        generic_items: msg.generic_template.generic_items.map(function (item) {
          if (item.id === itemId) {
            var tags = item.tags.filter(function (tag) {
              return tag.id !== tagId;
            });
            return _objectSpread({}, item, {
              tags: tags
            });
          }

          return item;
        })
      })
    })))
  };
};
export var deleteTag = function deleteTag(state, action) {
  var messageId = action.messageId,
      itemId = action.itemId,
      tagId = action.tagId;
  var msg = state.messages[messageId];
  return {
    messages: _objectSpread({}, state.messages, _defineProperty({}, messageId, _objectSpread({}, msg, {
      generic_template: _objectSpread({}, msg.generic_template, {
        generic_items: msg.generic_template.generic_items.map(function (item) {
          if (item.id === itemId) {
            var remainingTags = item.tags.filter(function (tag) {
              return tag.id !== tagId;
            });
            return _objectSpread({}, item, {
              tags: remainingTags
            });
          }

          return item;
        })
      })
    })))
  };
};
export var removeGenericListItem = function removeGenericListItem(state, action) {
  var messages = state.messages;
  var messageId = action.messageId,
      itemId = action.itemId;
  var m = messages[messageId];
  return {
    messages: _objectSpread({}, messages, _defineProperty({}, messageId, _objectSpread({}, m, {
      generic_template: _objectSpread({}, m.generic_template, {
        generic_items: m.generic_template.generic_items.filter(function (item) {
          return item.id !== itemId;
        })
      })
    })))
  };
};
export var removeQuickReply = function removeQuickReply(state, action) {
  var blockId = action.blockId;
  var blocks = state.blocks;
  if (!blockId) throw new Error('Requires a blockId to remove message');
  var currentBlock = blocks[blockId];
  return {
    blocks: _objectSpread({}, blocks, _defineProperty({}, blockId, _objectSpread({}, currentBlock, {
      quick_reply: null
    })))
  };
};
export var removeQuickReplyChoice = function removeQuickReplyChoice(state, action) {
  var blocks = state.blocks;
  var quickReplyChoiceId = action.quickReplyChoiceId,
      blockId = action.blockId;
  var currentBlock = blocks[blockId];
  return {
    blocks: _objectSpread({}, blocks, _defineProperty({}, blockId, _objectSpread({}, currentBlock, {
      quick_reply: _objectSpread({}, currentBlock.quick_reply, {
        quick_reply_choices: currentBlock.quick_reply.quick_reply_choices.filter(function (quickReplyChoice) {
          return quickReplyChoice.id !== quickReplyChoiceId;
        })
      })
    })))
  };
};
export var removeButtonFromText = function removeButtonFromText(state, action) {
  var messageId = action.messageId,
      buttonItemId = action.buttonItemId;
  var msg = state.messages[messageId];
  var title = msg.button_template.title;
  var isLastButton = msg.button_template.button_items.length === 1;
  return {
    messages: _objectSpread({}, state.messages, _defineProperty({}, messageId, isLastButton ? _objectSpread({}, msg, {
      text: title,
      button_template: null
    }) : _objectSpread({}, msg, {
      button_template: _objectSpread({}, msg.button_template, {
        button_items: msg.button_template.button_items.filter(function (buttonItem) {
          return buttonItem.id !== buttonItemId;
        })
      })
    })))
  };
};
export var removeButtonFromGeneric = function removeButtonFromGeneric(state, action) {
  var messageId = action.messageId,
      listIterator = action.listIterator,
      buttonItemId = action.buttonItemId;
  var msg = state.messages[messageId];
  return {
    messages: _objectSpread({}, state.messages, _defineProperty({}, messageId, _objectSpread({}, msg, {
      generic_template: _objectSpread({}, msg.generic_template, {
        generic_items: msg.generic_template.generic_items.map(function (item, i) {
          if (i === listIterator) {
            return _objectSpread({}, item, {
              generic_item_buttons: item.generic_item_buttons.filter(function (btn) {
                return btn.id !== buttonItemId;
              })
            });
          }

          return item;
        })
      })
    })))
  };
};
export var removeTalkTrigger = function removeTalkTrigger(state, action) {
  var blockIdParam = action.blockIdParam;
  var blocks = state.blocks;
  var targetBlock = state.blocks[blockIdParam];
  return _objectSpread({}, state, {
    blocks: _objectSpread({}, blocks, _defineProperty({}, blockIdParam, _objectSpread({}, targetBlock, {
      talk_trigger: null
    })))
  });
};
export var removeIdsChange = function removeIdsChange(state, action) {
  var _deleteScenarioIds = state.deleteScenarioIds;
  var scenarioId = action.scenarioId;

  var deleteIdIndex = _deleteScenarioIds.indexOf(scenarioId);

  if (deleteIdIndex >= 0) {
    // remove checked
    _deleteScenarioIds.splice(deleteIdIndex, 1);
  } else {
    // add checked
    _deleteScenarioIds.push(scenarioId);
  }

  return _objectSpread({}, state, {
    deleteScenarioIds: _deleteScenarioIds
  });
};