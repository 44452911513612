var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import { injectIntl } from 'react-intl';
import get from 'lodash/get';
import { colors, shadows } from '@src/colors';
import { ItemMenu } from '@src/components/atoms';
import DefaultRichMenuPreview from '@src/components/molecules/DefaultRichMenuPreview';
import { hoverMenuBg, hoverShadowMid } from '@src/lib/mixins';
import Icon from '@zeals-co-ltd/washi-components/lib/Icon/Icon';
import { blue } from '@zeals-co-ltd/washi-styles';
var DefaultRichMenuItemContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: relative;\n  cursor: pointer;\n\n  .adicon_other {\n    position: absolute;\n    right: 24px;\n    cursor: pointer;\n    bottom: 10px;\n\n    &:before {\n      font-size: 1.5rem;\n    }\n  }\n\n  .default {\n    background: ", ";\n    ", ";\n  }\n"], ["\n  position: relative;\n  cursor: pointer;\n\n  .adicon_other {\n    position: absolute;\n    right: 24px;\n    cursor: pointer;\n    bottom: 10px;\n\n    &:before {\n      font-size: 1.5rem;\n    }\n  }\n\n  .default {\n    background: ", ";\n    ", ";\n  }\n"])), colors.background.neutral_white, hoverShadowMid);
var DefaultRichMenuItemMenuContainer = styled(ItemMenu)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  position: absolute;\n  right: 0px;\n  top: 30px;\n  left: unset;\n  padding: 8px 0;\n  width: 260px;\n  background: ", ";\n  box-shadow: ", ";\n  border-radius: 8px;\n  border-width: 0px;\n"], ["\n  position: absolute;\n  right: 0px;\n  top: 30px;\n  left: unset;\n  padding: 8px 0;\n  width: 260px;\n  background: ", ";\n  box-shadow: ", ";\n  border-radius: 8px;\n  border-width: 0px;\n"])), colors.background.main_menu, shadows.medium.light);
var MenuItemWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: block;\n  padding: 0.5rem;\n  font-size: 0.875rem;\n  user-select: none;\n  display: flex;\n  ", ";\n\n  .MuiSvgIcon-root {\n    margin: 8px 5px;\n  }\n"], ["\n  display: block;\n  padding: 0.5rem;\n  font-size: 0.875rem;\n  user-select: none;\n  display: flex;\n  ", ";\n\n  .MuiSvgIcon-root {\n    margin: 8px 5px;\n  }\n"])), hoverMenuBg);
var MenuItemContentWrapper = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  margin-left: 10px;\n  font-size: 12px;\n  display: grid;\n\n  .menu_title {\n    color: ", ";\n    font-size: 14px;\n  }\n"], ["\n  margin-left: 10px;\n  font-size: 12px;\n  display: grid;\n\n  .menu_title {\n    color: ", ";\n    font-size: 14px;\n  }\n"])), colors.midnight);
var IconButton = styled.button(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  background-color: transparent;\n  border: none;\n  color: ", ";\n"], ["\n  background-color: transparent;\n  border: none;\n  color: ", ";\n"])), blue[500]);
var DefaultRichMenuItemMenu = function (_a) {
    var intl = _a.intl, handleClickOutside = _a.handleClickOutside, onClickSetting = _a.onClickSetting, onClickDuplicate = _a.onClickDuplicate, onClickDelete = _a.onClickDelete;
    return (React.createElement(DefaultRichMenuItemMenuContainer, { handleClickOutside: handleClickOutside },
        React.createElement(MenuItemWrapper, { className: "menu_item", role: "button", tabIndex: 0, onClick: onClickSetting },
            React.createElement(Icon, { name: "adicon_menu_settings", color: "primary" }),
            React.createElement(MenuItemContentWrapper, null,
                React.createElement("span", { className: "menu_title" }, intl.formatMessage({ id: "D3idYv", defaultMessage: "Settings" })),
                intl.formatMessage({ id: "Iximeu", defaultMessage: "Preview and edit this rich menu" }))),
        React.createElement(MenuItemWrapper, { className: "menu_item", role: "button", tabIndex: 0, onClick: onClickDuplicate },
            React.createElement(Icon, { name: "adicon_duplicate", color: "primary" }),
            React.createElement(MenuItemContentWrapper, null,
                React.createElement("span", { className: "menu_title" }, intl.formatMessage({ id: "4fHiNl", defaultMessage: "Duplicate" })),
                intl.formatMessage({ id: "l5a3zV", defaultMessage: "Make a copy" }))),
        React.createElement(MenuItemWrapper, { role: "button", tabIndex: 0, onClick: onClickDelete, className: "menu_item" },
            React.createElement(Icon, { name: "adicon_delete", color: "primary" }),
            React.createElement(MenuItemContentWrapper, null,
                React.createElement("span", { className: "menu_title" }, intl.formatMessage({ id: "K3r6DQ", defaultMessage: "Delete" })),
                intl.formatMessage({ id: "llYOUd", defaultMessage: "This cannot be undone" })))));
};
var DefaultRichMenuItem = function (_a) {
    var intl = _a.intl, defaultRichMenu = _a.defaultRichMenu, onClickSetting = _a.onClickSetting, onClickDuplicate = _a.onClickDuplicate, onClickDelete = _a.onClickDelete, dataTestId = _a.dataTestId, children = _a.children;
    var _b = __read(useState(false), 2), showMenu = _b[0], setShowMenu = _b[1];
    var iconButtonElement = useRef(null);
    var handleClickOutside = function (e) {
        if (iconButtonElement && iconButtonElement.current.contains(e.target))
            return;
        setShowMenu(false);
    };
    var handleClickItem = function (e) {
        if (iconButtonElement && !iconButtonElement.current.contains(e.target))
            onClickSetting();
    };
    var richMenuId = get(defaultRichMenu, 'richMenus[0].line_rich_menu_id');
    return (React.createElement(DefaultRichMenuItemContainer, { onClick: handleClickItem, "data-testid": dataTestId },
        React.createElement(DefaultRichMenuPreview, { defaultRichMenu: defaultRichMenu }),
        children,
        React.createElement(IconButton, { ref: iconButtonElement, "data-testid": "DefaultRichMenuItem_SettingsButton", className: "adicon_other", onClick: function () { return setShowMenu(!showMenu); } }, showMenu && (React.createElement(DefaultRichMenuItemMenu, { intl: intl, handleClickOutside: handleClickOutside, onClickSetting: onClickSetting, onClickDuplicate: onClickDuplicate, onClickDelete: function () { return onClickDelete(richMenuId); } })))));
};
export default injectIntl(DefaultRichMenuItem);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
