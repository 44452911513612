import "core-js/modules/es.array.concat";

var collection = function collection(chatbotId) {
  return "/chatbots/".concat(chatbotId, "/end_users");
};

var member = function member(chatbotId, endUserId) {
  return "/chatbots/".concat(chatbotId, "/end_users/").concat(endUserId);
}; // requires filter_id query param to use


var filter = function filter(chatbotId) {
  return "".concat(collection(chatbotId), "/filter");
};

var blockMultipleUsers = function blockMultipleUsers(chatbotId) {
  return "/chatbots/".concat(chatbotId, "/end_users/block_selected_users");
};

var userList = function userList(_ref) {
  var chatbotId = _ref.chatbotId;
  return "/chatbots/".concat(chatbotId, "/user_list");
};

var userListAll = function userListAll(_ref2) {
  var chatbotId = _ref2.chatbotId;
  return "/chatbots/".concat(chatbotId, "/user_list/render_all_users");
};

var userListMember = function userListMember(chatbotId, userId) {
  return "/chatbots/".concat(chatbotId, "/user_list/").concat(userId);
};

var userListFilter = function userListFilter(chatbotId, filterString) {
  return "/chatbots/".concat(chatbotId, "/user_list/search_name_and_message?word=").concat(filterString);
};

var userTagAssociation = function userTagAssociation(_ref3) {
  var chatbotId = _ref3.chatbotId,
      userId = _ref3.userId;
  return "/chatbots/".concat(chatbotId, "/user_list/").concat(userId, "/user_tag_associations");
};

var talk = {
  collection: function collection(chatbotId) {
    return "/chatbots/".concat(chatbotId, "/talk_room/end_users");
  },
  member: function member(chatbotId, id) {
    return "".concat(talk.collection(chatbotId), "/").concat(id);
  },
  filter: function filter(chatbotId) {
    return "".concat(talk.collection(chatbotId), "/filter");
  }
};
var zealsAdmin = {
  collection: function collection() {
    return "/zeals_admin/api/end_users";
  },
  member: function member(id) {
    return "".concat(zealsAdmin.collection()).concat(id);
  }
};
export default {
  collection: collection,
  member: member,
  filter: filter,
  blockMultipleUsers: blockMultipleUsers,
  userList: userList,
  userListAll: userListAll,
  userListMember: userListMember,
  userListFilter: userListFilter,
  userTagAssociation: userTagAssociation,
  talk: talk,
  zealsAdmin: zealsAdmin
};