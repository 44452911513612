import MESSENGER from './messenger';
import INSTAGRAM from './instagram';
import LINE from './line';
import WEBCHAT from './webchat';
export default {
    messenger: MESSENGER,
    instagram: INSTAGRAM,
    line: LINE,
    webchat: WEBCHAT,
};
