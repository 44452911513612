var _a;
import PropTypes from 'prop-types';
import { messageTypes } from '@src/lib/app-constants';
import { MESSAGE_TYPES } from '@src/lib/talkUtil';
var second = 1000;
export var USER_LIST_SEARCH_DEBOUNCE_TIME = 5 * second;
export var EVENT_LOGS_POLLING_INTERVAL = 25 * second;
export var USER_LIST_POLLING_INTERVAL = 50 * second;
export var TALK_MODALS = (_a = {},
    _a[messageTypes.TEXT] = {
        width: '30%',
        name: 'Text',
        type: MESSAGE_TYPES.TEXT,
        icon: 'adicon_msg_textbox',
    },
    _a[messageTypes.GENERIC] = {
        width: '75%',
        name: 'Generic',
        type: MESSAGE_TYPES.GENERIC,
        icon: 'adicon_msg_carousel',
    },
    _a[messageTypes.MEDIA] = {
        width: '30%',
        name: 'File',
        type: MESSAGE_TYPES.MEDIA,
        icon: 'adicon_msg_file',
    },
    _a);
export var eventLogShape = {
    chosen_choice: PropTypes.object,
    created_at: PropTypes.string,
    end_user_id: PropTypes.number,
    end_user_message: PropTypes.string,
    id: PropTypes.number,
    message: PropTypes.object,
    opened_date: PropTypes.string,
};
