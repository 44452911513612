var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState, useRef, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import uniqueId from 'lodash/uniqueId';
import styled from 'styled-components';
import { colors } from '@src/colors';
var ListTemplateGroupTitlesWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 225px;\n  margin-left: -40px;\n  padding-left: 40px;\n  padding-right: 40px;\n  padding-top: 40px;\n  padding-bottom: 40px;\n  box-shadow: 3px 0px 3px ", ";\n\n  div {\n    padding: 8px;\n    margin: 4px 0;\n    border-radius: 5px;\n    cursor: pointer;\n  }\n\n  > div:last-of-type {\n    margin-bottom: 40px;\n  }\n\n  div:not(.spacer) {\n    &:hover {\n      background-color: ", ";\n    }\n  }\n\n  .scroll-to-size {\n    color: ", ";\n    display: none;\n  }\n\n  .active {\n    background: ", ";\n    font-weight: bold;\n  }\n\n  .spacer {\n    border-bottom: 1px solid ", ";\n    border-radius: 0px;\n    padding: 0;\n    margin: 8px 0;\n  }\n"], ["\n  width: 225px;\n  margin-left: -40px;\n  padding-left: 40px;\n  padding-right: 40px;\n  padding-top: 40px;\n  padding-bottom: 40px;\n  box-shadow: 3px 0px 3px ", ";\n\n  div {\n    padding: 8px;\n    margin: 4px 0;\n    border-radius: 5px;\n    cursor: pointer;\n  }\n\n  > div:last-of-type {\n    margin-bottom: 40px;\n  }\n\n  div:not(.spacer) {\n    &:hover {\n      background-color: ", ";\n    }\n  }\n\n  .scroll-to-size {\n    color: ", ";\n    display: none;\n  }\n\n  .active {\n    background: ", ";\n    font-weight: bold;\n  }\n\n  .spacer {\n    border-bottom: 1px solid ", ";\n    border-radius: 0px;\n    padding: 0;\n    margin: 8px 0;\n  }\n"])), colors.shadows.light, colors.background.hover_purple, colors.status_grey, colors.background.light_purple, colors.border.default);
var TemplateGroupTitle = function (_a) {
    var isHover = _a.isHover, index = _a.index, templateGroupTitle = _a.templateGroupTitle, chosenTemplateGroup = _a.chosenTemplateGroup, indexSpacers = _a.indexSpacers, handleTemplateGroupSelect = _a.handleTemplateGroupSelect;
    var templateGroupTitleRef = useRef(null);
    useEffect(function () {
        if (!templateGroupTitleRef || index !== chosenTemplateGroup || isHover)
            return;
        // When chosenTemplateGroup change, it mean that ListTemplateGroup has scroll
        // and it need to focus (if list too long)
        templateGroupTitleRef.current.scrollIntoView({ block: 'end' });
    }, [chosenTemplateGroup]);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { ref: templateGroupTitleRef, role: "button", tabIndex: 0, className: index === chosenTemplateGroup ? 'active' : '', onClick: function () { return handleTemplateGroupSelect(index); } }, templateGroupTitle),
        indexSpacers.includes(index) && React.createElement("div", { className: "spacer" })));
};
var ListTemplateGroupTitles = function (_a) {
    var intl = _a.intl, chosenTemplateGroup = _a.chosenTemplateGroup, templateGroupTitles = _a.templateGroupTitles, _b = _a.indexSpacers, indexSpacers = _b === void 0 ? [] : _b, handleTemplateGroupSelect = _a.handleTemplateGroupSelect;
    // This flag handle ListTemplateGroupTitles is hover or not, if it ss hover,
    // when chosenTemplateGroup change, no need to focus templateGroupTitle
    var _c = __read(useState(false), 2), isHover = _c[0], setIsHover = _c[1];
    return (React.createElement(ListTemplateGroupTitlesWrapper, { onMouseLeave: function () { return setIsHover(false); }, onMouseEnter: function () { return setIsHover(true); }, className: "list-template-group-titles" },
        React.createElement("span", { className: "scroll-to-size" }, intl.formatMessage({ id: "QvICBQ", defaultMessage: "Scroll to Size" })),
        templateGroupTitles.map(function (templateGroupTitle, index) { return (React.createElement(TemplateGroupTitle, { key: "template-group-name-" + uniqueId(), isHover: isHover, index: index, templateGroupTitle: templateGroupTitle, chosenTemplateGroup: chosenTemplateGroup, indexSpacers: indexSpacers, handleTemplateGroupSelect: handleTemplateGroupSelect })); })));
};
export default injectIntl(ListTemplateGroupTitles);
var templateObject_1;
