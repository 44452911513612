var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { handleAPIErrors } from '@src/lib/helpers';
import * as quickReplyApi from '@src/services/api/quickReplies';
import { quickReplyChoiceFieldSelectableOptionAssociations, updateInformationAssociatedWithQuickReplyChoice, } from '@src/services/api/quickReplies';
import { quickReply } from '@src/redux/fanp/constants';
import { fetchAdScenario, fetchBlock, } from '@src/redux/fanp/actions/ad-scenario/getActions';
var AD_SET_QUICK_REPLY = quickReply.AD_SET_QUICK_REPLY, ADD_CREATED_QUICK_REPLY = quickReply.ADD_CREATED_QUICK_REPLY, AD_REMOVE_QUICK_REPLY = quickReply.AD_REMOVE_QUICK_REPLY, AD_HANDLE_QUICK_REPLY_CHOICE = quickReply.AD_HANDLE_QUICK_REPLY_CHOICE, AD_HANDLE_REMOVE_QUICK_REPLY_CHOICE = quickReply.AD_HANDLE_REMOVE_QUICK_REPLY_CHOICE, DESELECT_ALL_CHOICES = quickReply.DESELECT_ALL_CHOICES, AD_SET_QUICK_REPLY_CHOICE = quickReply.AD_SET_QUICK_REPLY_CHOICE, AD_SET_QUICK_REPLY_CHOICES = quickReply.AD_SET_QUICK_REPLY_CHOICES, AD_UPDATE_QUICK_REPLY_CHOICE = quickReply.AD_UPDATE_QUICK_REPLY_CHOICE;
export var setQuickReply = function (_a) {
    var blockId = _a.blockId, quickReply = _a.quickReply;
    return ({
        type: AD_SET_QUICK_REPLY,
        blockId: blockId,
        quickReply: quickReply,
    });
};
export var adSetQuickReplyChoices = function (blockId, quickReplyChoices) { return ({
    type: AD_SET_QUICK_REPLY_CHOICES,
    blockId: blockId,
    quickReplyChoices: quickReplyChoices,
}); };
export var deselectAllChoices = function () { return ({ type: DESELECT_ALL_CHOICES }); };
export var adSetQuickReplyChoice = function (_a) {
    var blockId = _a.blockId, quickReplyChoiceId = _a.quickReplyChoiceId, quickReplyChoice = _a.quickReplyChoice;
    return {
        type: AD_SET_QUICK_REPLY_CHOICE,
        blockId: blockId,
        quickReplyChoiceId: quickReplyChoiceId,
        quickReplyChoice: quickReplyChoice,
    };
};
export var updateQuickReplyChoiceAction = function (quickParams) { return (__assign({ type: AD_UPDATE_QUICK_REPLY_CHOICE }, quickParams)); };
export var adHandleQuickReplyChoice = function (quickParams) { return (__assign({ type: AD_HANDLE_QUICK_REPLY_CHOICE }, quickParams)); };
export var handleRemoveQuickReplyChoice = function (_a) {
    var messageId = _a.messageId, quickReplyId = _a.quickReplyId, quickReplyChoiceId = _a.quickReplyChoiceId, blockId = _a.blockId;
    return ({
        type: AD_HANDLE_REMOVE_QUICK_REPLY_CHOICE,
        messageId: messageId,
        quickReplyId: quickReplyId,
        quickReplyChoiceId: quickReplyChoiceId,
        blockId: blockId,
    });
};
export var addCreatedQuickReply = function (quickParams, blockId) { return (__assign({ type: ADD_CREATED_QUICK_REPLY, blockId: blockId }, quickParams)); };
export var removeQuickReplyAction = function (_a) {
    var chatbotId = _a.chatbotId, scenarioId = _a.scenarioId, quickReplyId = _a.quickReplyId, blockId = _a.blockId, layerId = _a.layerId;
    return ({
        type: AD_REMOVE_QUICK_REPLY,
        chatbotId: chatbotId,
        scenarioId: scenarioId,
        quickReplyId: quickReplyId,
        blockId: blockId,
        layerId: layerId,
    });
};
export var createQuickReplyWithUserTags = function (_a) {
    var chatbotId = _a.chatbotId, scenarioId = _a.scenarioId, blockId = _a.blockId, userTagGroupId = _a.userTagGroupId, userTags = _a.userTags;
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        var quickReplyChoice, body, quickReply_1, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    quickReplyChoice = userTags.map(function (userTag) {
                        return {
                            user_tag_id: userTag.id,
                            name: userTag.name || userTag.label || '',
                        };
                    });
                    body = {
                        choices: quickReplyChoice,
                        user_tag_group_id: userTagGroupId,
                    };
                    return [4 /*yield*/, quickReplyApi.createQuickReplyWithTags(chatbotId, scenarioId, blockId, body)];
                case 1:
                    quickReply_1 = _a.sent();
                    dispatch(setQuickReply({
                        blockId: blockId,
                        quickReply: quickReply_1,
                    }));
                    return [3 /*break*/, 3];
                case 2:
                    e_1 = _a.sent();
                    console.error(e_1);
                    handleAPIErrors("\u30AF\u30A4\u30C3\u30AF\u30EA\u30D7\u30E9\u30A4\u3092\u4F5C\u6210\u3067\u304D\u307E\u305B\u3093\u3067\u3057\u305F: " + e_1.message);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
};
export var createQuickReplyWithFieldOptions = function (_a) {
    var chatbotId = _a.chatbotId, scenarioId = _a.scenarioId, blockId = _a.blockId, fieldId = _a.fieldId, fieldSelectedOptions = _a.fieldSelectedOptions;
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        var createdQuickReplyChoices;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, quickReplyChoiceFieldSelectableOptionAssociations({
                        chatbotId: chatbotId,
                        fieldId: fieldId,
                        block_id: blockId,
                        quick_reply_choice_selectable_option_associations: fieldSelectedOptions.map(function (selectedOption) { return ({
                            selectable_option_id: selectedOption.id,
                            quick_reply_choice_label: selectedOption.label,
                        }); }),
                    })];
                case 1:
                    createdQuickReplyChoices = _a.sent();
                    return [4 /*yield*/, dispatch(fetchBlock({
                            chatbotIdParam: chatbotId,
                            adScenarioIdParam: scenarioId,
                            blockIdParam: blockId,
                            isSlim: undefined,
                        }))];
                case 2:
                    _a.sent();
                    return [2 /*return*/, createdQuickReplyChoices];
            }
        });
    }); };
};
export var addQuickReply = function (_a) {
    var chatbotId = _a.chatbotId, adScenarioId = _a.adScenarioId, layerId = _a.layerId, blockId = _a.blockId;
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        var body, quickReply_2, e_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    body = {
                        label: '',
                        layer_id: layerId,
                    };
                    return [4 /*yield*/, quickReplyApi.createQuickReply(chatbotId, adScenarioId, blockId, body)];
                case 1:
                    quickReply_2 = _a.sent();
                    dispatch(addCreatedQuickReply({ quickReply: quickReply_2 }, blockId));
                    return [3 /*break*/, 3];
                case 2:
                    e_2 = _a.sent();
                    console.error(e_2);
                    handleAPIErrors("\u30AF\u30A4\u30C3\u30AF\u30EA\u30D7\u30E9\u30A4\u3092\u4F5C\u6210\u3067\u304D\u307E\u305B\u3093\u3067\u3057\u305F: " + e_2.message);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
};
export var removeQuickReply = function (_a) {
    var chatbotId = _a.chatbotId, scenarioId = _a.scenarioId, quickReplyId = _a.quickReplyId, blockId = _a.blockId, layerId = _a.layerId;
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        var res, e_3;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, quickReplyApi.removeQuickReply(chatbotId, scenarioId, quickReplyId, blockId, layerId)];
                case 1:
                    res = _a.sent();
                    if (res.status !== 200) {
                        throw new Error("status code: " + res.status);
                    }
                    dispatch(removeQuickReplyAction({
                        chatbotId: chatbotId,
                        scenarioId: scenarioId,
                        quickReplyId: quickReplyId,
                        blockId: blockId,
                        layerId: layerId,
                    }));
                    dispatch(fetchAdScenario({
                        chatbotId: chatbotId,
                        adScenarioId: scenarioId,
                    }));
                    return [3 /*break*/, 3];
                case 2:
                    e_3 = _a.sent();
                    console.error(e_3);
                    handleAPIErrors("There was an error deleting the quick reply: " + e_3.message);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
};
export var fetchUpdateQuickReplyChoiceRanks = function (_a) {
    var chatbotId = _a.chatbotId, adScenarioId = _a.adScenarioId, blockId = _a.blockId, quickReplyId = _a.quickReplyId, choices = _a.choices;
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        var body, quickReplyChoices, e_4;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    body = {
                        choices: choices,
                    };
                    return [4 /*yield*/, quickReplyApi.updateQuickReplyChoiceRank(chatbotId, adScenarioId, blockId, quickReplyId, body)];
                case 1:
                    quickReplyChoices = _a.sent();
                    dispatch(adSetQuickReplyChoices(blockId, quickReplyChoices.sort(function (a, b) { return a.rank - b.rank; })));
                    return [3 /*break*/, 3];
                case 2:
                    e_4 = _a.sent();
                    console.error(e_4);
                    handleAPIErrors("Quick reply choice error: " + e_4.message);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
};
export var updateQuickReplyChoice = function (payload) { return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
    var updatedQuickReplyChoice;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, updateInformationAssociatedWithQuickReplyChoice(payload)];
            case 1:
                updatedQuickReplyChoice = _a.sent();
                if (!updatedQuickReplyChoice) {
                    throw new Error('クイックリプライを作成できませんでした。');
                }
                dispatch(updateQuickReplyChoiceAction({
                    blockId: payload.blockId,
                    quickReplyChoice: updatedQuickReplyChoice,
                }));
                return [2 /*return*/];
        }
    });
}); }; };
export var addQuickReplyChoice = function (_a) {
    var chatbotId = _a.chatbotId, scenarioId = _a.scenarioId, blockId = _a.blockId, quickReplyId = _a.quickReplyId;
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        var body, quickReplyChoices, e_5;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    body = {};
                    return [4 /*yield*/, quickReplyApi.addQuickReplyChoice(chatbotId, scenarioId, blockId, quickReplyId, body)];
                case 1:
                    quickReplyChoices = _a.sent();
                    if (!quickReplyChoices) {
                        throw new Error('クイックリプライを作成できませんでした。');
                    }
                    dispatch(adHandleQuickReplyChoice({
                        blockId: blockId,
                        quickReplyChoices: quickReplyChoices.sort(function (a, b) { return a.rank - b.rank; }),
                    }));
                    return [3 /*break*/, 3];
                case 2:
                    e_5 = _a.sent();
                    console.error(e_5);
                    handleAPIErrors("Quick reply choice error: " + e_5.message);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
};
export var removeQuickReplyChoice = function (_a) {
    var chatbotId = _a.chatbotId, scenarioId = _a.scenarioId, blockId = _a.blockId, quickReplyId = _a.quickReplyId, quickReplyChoiceId = _a.quickReplyChoiceId, layerRank = _a.layerRank;
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        var queryObj, status, e_6;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    queryObj = { layer_rank: layerRank };
                    return [4 /*yield*/, quickReplyApi.removeQuickReplyChoice(chatbotId, scenarioId, blockId, quickReplyId, quickReplyChoiceId, queryObj)];
                case 1:
                    status = (_a.sent()).status;
                    if (status && status === 200) {
                        dispatch(handleRemoveQuickReplyChoice({
                            quickReplyId: quickReplyId,
                            quickReplyChoiceId: quickReplyChoiceId,
                            blockId: blockId,
                        }));
                        dispatch(fetchAdScenario({
                            chatbotId: chatbotId,
                            adScenarioId: scenarioId,
                        }));
                    }
                    else {
                        throw new Error('クイックリプライを削除できませんでした。');
                    }
                    return [3 /*break*/, 3];
                case 2:
                    e_6 = _a.sent();
                    console.error(e_6);
                    handleAPIErrors("Quick reply choice error: " + e_6.message);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
};
export var connectQuickReplyChoicesToBlock = function (_a) {
    var chatbotIdParam = _a.chatbotIdParam, adScenarioIdParam = _a.adScenarioIdParam, blockId = _a.blockId, quickReplyId = _a.quickReplyId, quickReplyIds = _a.quickReplyIds, nextBlockId = _a.nextBlockId, nextScenarioId = _a.nextScenarioId, rank = _a.rank, deselectQuickReplyChoice = _a.deselectQuickReplyChoice;
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        var body, quickReplyChoices, e_7;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    body = {
                        layer_rank: rank,
                        next_block_id: nextBlockId || null,
                        next_scenario_id: nextScenarioId || null,
                    };
                    if (quickReplyIds) {
                        body.quick_reply_ids = quickReplyIds;
                    }
                    return [4 /*yield*/, quickReplyApi.connectQuickReplyChoices(chatbotIdParam, adScenarioIdParam, blockId, quickReplyId, body)];
                case 1:
                    quickReplyChoices = _a.sent();
                    dispatch(adSetQuickReplyChoices(blockId, quickReplyChoices));
                    dispatch(fetchAdScenario({
                        chatbotId: chatbotIdParam,
                        adScenarioId: adScenarioIdParam,
                    }));
                    if (deselectQuickReplyChoice)
                        dispatch(deselectAllChoices());
                    return [3 /*break*/, 3];
                case 2:
                    e_7 = _a.sent();
                    console.error(e_7);
                    handleAPIErrors("Error connecting quick reply to block: " + e_7.message);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
};
export var connectQuickReplyChoiceToBlock = function (_a) {
    var chatbotIdParam = _a.chatbotIdParam, adScenarioIdParam = _a.adScenarioIdParam, blockId = _a.blockId, quickReplyId = _a.quickReplyId, quickReplyChoiceId = _a.quickReplyChoiceId, nextBlockId = _a.nextBlockId, nextScenarioId = _a.nextScenarioId, rank = _a.rank, name = _a.name;
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        var body, quickReplyChoice, e_8;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    if (!name) {
                        throw new Error('Please input the label name');
                    }
                    body = {
                        name: name,
                        layer_rank: rank,
                        next_block_id: nextBlockId,
                        next_scenario_id: nextScenarioId,
                    };
                    return [4 /*yield*/, quickReplyApi.connectQuickReplyChoice(chatbotIdParam, adScenarioIdParam, blockId, quickReplyId, quickReplyChoiceId, body)];
                case 1:
                    quickReplyChoice = _a.sent();
                    dispatch(adSetQuickReplyChoice({
                        blockId: blockId,
                        quickReplyChoiceId: quickReplyChoiceId,
                        quickReplyChoice: quickReplyChoice,
                    }));
                    dispatch(fetchAdScenario({
                        chatbotId: chatbotIdParam,
                        adScenarioId: adScenarioIdParam,
                    }));
                    return [3 /*break*/, 3];
                case 2:
                    e_8 = _a.sent();
                    console.error(e_8);
                    handleAPIErrors('Error connecting quick reply to block:', e_8);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
};
export var editQuickReplyChoice = function (_a) {
    var name = _a.name, chatbotId = _a.chatbotId, scenarioId = _a.scenarioId, blockId = _a.blockId, quickReplyId = _a.quickReplyId, quickReplyChoiceId = _a.quickReplyChoiceId;
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        var body, quickReplyChoice, e_9;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    body = { name: name };
                    return [4 /*yield*/, quickReplyApi.editQuickReplyChoice(chatbotId, scenarioId, blockId, quickReplyId, quickReplyChoiceId, body)];
                case 1:
                    quickReplyChoice = _a.sent();
                    dispatch(adSetQuickReplyChoice({
                        blockId: blockId,
                        quickReplyChoiceId: quickReplyChoiceId,
                        quickReplyChoice: quickReplyChoice,
                    }));
                    return [3 /*break*/, 3];
                case 2:
                    e_9 = _a.sent();
                    console.error(e_9);
                    handleAPIErrors('クイックリプライチョイスを更新できませんでした', e_9);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
};
export var editQuickReplyChoiceUserTag = function (_a) {
    var name = _a.name, chatbotId = _a.chatbotId, scenarioId = _a.scenarioId, blockId = _a.blockId, quickReplyId = _a.quickReplyId, quickReplyChoiceId = _a.quickReplyChoiceId, userTagId = _a.userTagId;
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        var body, quickReplyChoice, e_10;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    if (!name) {
                        throw new Error('Please input the label name');
                    }
                    body = {
                        name: name,
                        user_tag_id: userTagId,
                    };
                    return [4 /*yield*/, quickReplyApi.editQuickReplyChoiceUserTag(chatbotId, scenarioId, blockId, quickReplyId, quickReplyChoiceId, body)];
                case 1:
                    quickReplyChoice = _a.sent();
                    dispatch(adSetQuickReplyChoice({
                        blockId: blockId,
                        quickReplyChoiceId: quickReplyChoiceId,
                        quickReplyChoice: quickReplyChoice,
                    }));
                    return [3 /*break*/, 3];
                case 2:
                    e_10 = _a.sent();
                    console.error(e_10);
                    handleAPIErrors('クイックリプライチョイスを更新できませんでした', e_10);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
};
