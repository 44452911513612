import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb';
import { UserLabel, LogicalOperator, Condition, ClauseCondition, Field, Value, } from 'zeals-protobuf/zeals/web/entities/user_dossier_pb';
export var emptyUserLabel = {
    id: undefined,
    chatbotId: undefined,
    name: '',
    conditionsList: [],
    operator: LogicalOperator.LOGICAL_OPERATOR_AND,
};
export function fieldvalue(obj) {
    var field = new Field();
    field.setChatbotId(obj.chatbotId);
    field.setDataType(obj.dataType);
    field.setFieldCollectionType(obj.fieldCollectionType);
    field.setInputType(obj.inputType);
    field.setName(obj.name);
    field.setId(obj.id);
    return field;
}
export function Values(obj) {
    var value = new Value();
    switch (obj.type) {
        case 1:
            value.setStringValue(obj.stringValue);
            break;
        case 2:
            value.setIntegerValue(obj.integerValue);
            break;
        case 3:
            value.setDatetimeValue(datetime(obj.datetimeValue));
            break;
        case 4:
            value.setDoubleValue(obj.doubleValue);
            break;
        case 5:
            value.setBoolValue(obj.boolValue);
            break;
        case 6:
            value.setEncryptedValue(obj.encryptedValue);
            break;
    }
    value.setType(obj.type);
    return value;
}
export function datetime(obj) {
    var timestamp = new Timestamp();
    timestamp.setNanos(obj.nanos);
    timestamp.setSeconds(obj.seconds);
    return timestamp;
}
export function clauseCondition(obj) {
    var clauseCondition = new ClauseCondition();
    clauseCondition.setField(fieldvalue(obj.field));
    clauseCondition.setOperator(obj.operator);
    clauseCondition.setValue(Values(obj.value));
    return clauseCondition;
}
export function conditionFromObj(Conditionobj) {
    var condition = new Condition();
    condition.setType(Conditionobj.type);
    condition.setClauseCondition(clauseCondition(Conditionobj.clauseCondition));
    return condition;
}
export function userLabelFromObj(obj) {
    var userLabel = new UserLabel();
    userLabel.setId(obj.id);
    userLabel.setChatbotId(obj.chatbotId);
    userLabel.setName(obj.name);
    userLabel.setOperator(obj.operator);
    userLabel.setConditionsList(obj.conditionsList.map(conditionFromObj));
    return userLabel;
}
