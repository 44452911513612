import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
import React from 'react';
import styled from 'styled-components';
import { colors } from '@src/colors';
import { PluginImageActionPreview, Switch } from '@src/components/atoms';
import { hoverDelete, centeredFlex } from '@src/lib/mixins';
var Container = styled.div.withConfig({
  displayName: "Container",
  componentId: "sc-1kabrwj-0"
})(["display:flex;p{color:", ";}.PluginBanner_switch{margin:0 !important;}.banner-delete-btn{", ";width:80px;font-size:1.5rem;", ";}"], colors.text_pale, centeredFlex, hoverDelete);
var PreviewWrapper = styled.div.withConfig({
  displayName: "PreviewWrapper",
  componentId: "sc-1kabrwj-1"
})(["display:flex;justify-content:center;"]);

var PluginBanner = function PluginBanner(_ref) {
  var onClick = _ref.onClick,
      onDelete = _ref.onDelete,
      banner = _objectWithoutProperties(_ref, ["onClick", "onDelete"]);

  return React.createElement(Container, {
    className: "row item",
    onClick: onClick
  }, React.createElement("p", null, banner.id), React.createElement("p", null, React.createElement(PreviewWrapper, null, React.createElement(PluginImageActionPreview, {
    banner: banner
  }))), React.createElement("p", {
    className: "banner-title"
  }, banner.title), React.createElement("p", {
    className: "banner-interval-ctrl"
  }, React.createElement(Switch, {
    className: "PluginBanner_switch",
    isActive: banner.display_interval_control_flag === 'active'
  })), React.createElement("div", {
    className: "banner-delete-btn"
  }, React.createElement("span", {
    className: "adicon_delete icon_style",
    onClick: function onClick(e) {
      e.stopPropagation();
      onDelete();
    }
  })));
};

export default PluginBanner;