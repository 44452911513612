export default {
  UNDO_AD_USER_FILTER: 'UNDO_AD_USER_FILTER',
  ATTRIBUTTE_QUESTIONS_AJAX_FULFILLED: 'ATTRIBUTTE_QUESTIONS_AJAX_FULFILLED',
  ATTRIBUTTE_ANSWERS_AJAX_FULFILLED: 'ATTRIBUTTE_ANSWERS_AJAX_FULFILLED',
  AD_GET_QUICK_FILTERS: 'AD_GET_QUICK_FILTERS',
  // SET
  AD_SET_SEARCHED_USERS: 'AD_SET_SEARCHED_USERS',
  SET_ERROR_GAP: 'SET_ERROR_GAP',
  SET_MIN_END_USERS: 'SET_MIN_END_USERS',
  SET_MAX_END_USERS: 'SET_MAX_END_USERS',
  SET_USAGE_NUMBER: 'SET_USAGE_NUMBER',
  SET_LIMIT_NUMBER: 'SET_LIMIT_NUMBER',
  // ADD
  AD_CREATE_NEW_USER_FILTER: 'AD_CREATE_NEW_USER_FILTER',
  AD_ADD_QUICK_FILTER: 'AD_ADD_QUICK_FILTER',
  AD_ADD_TAG_TO_FILTER: 'AD_ADD_TAG_TO_FILTER',
  AD_ADD_QUICK_FILTER_TO_FILTER: 'AD_ADD_QUICK_FILTER_TO_FILTER',
  // HANDLE AJAX RESPONSE
  AD_HANDLE_USER_FILTER: 'AD_HANDLE_USER_FILTER',
  AD_HANDLE_NEW_USER_FILTER: 'AD_HANDLE_NEW_USER_FILTER',
  AD_SET_FILTER_TAGS: 'AD_SET_FILTER_TAGS',
  AD_SET_ATTRIBUTE_QUESTIONS: 'AD_SET_ATTRIBUTE_QUESTIONS',
  AD_UPDATE_QUICK_FILTER: 'AD_UPDATE_QUICK_FILTER',
  AD_UPDATE_QUICK_FILTER_SETTINGS: 'AD_UPDATE_QUICK_FILTER_SETTINGS',
  // EDIT
  AD_UPDATE_FILTER_PROP: 'AD_UPDATE_FILTER_PROP',
  SET_UPDATED_FILTER: 'SET_UPDATED_FILTER',
  // DELETE
  AD_HANDLE_DELETE_FILTER_ACTION: 'AD_HANDLE_DELETE_FILTER_ACTION',
  AD_DELETE_QUICK_FILTER_FROM_FILTER: 'AD_DELETE_QUICK_FILTER_FROM_FILTER'
};