import React, { useMemo } from 'react';
import { filterObjectsByText } from '@src/lib/helpers';
import SelectorWrapper from '@src/components/atoms/SelectorWrapper';
import { injectIntl } from 'react-intl';
import PostbackConnectorGroup, { ConnectorType, } from './PostbackConnectorGroup';
var BlockPostbackConnector = function (_a) {
    var _b = _a.blocks, blocks = _b === void 0 ? [] : _b, _c = _a.filterText, filterText = _c === void 0 ? '' : _c, onPostbackItemClick = _a.onPostbackItemClick, onNewBlockClick = _a.onNewBlockClick, intl = _a.intl;
    var filteredOptions = useMemo(function () { return filterObjectsByText(blocks, 'name', filterText); }, [filterText, blocks]);
    return (React.createElement(SelectorWrapper, null,
        blocks && (React.createElement(PostbackConnectorGroup, { title: intl.formatMessage({ id: "9QJSjZ", defaultMessage: "Blocks in this scenario" }), postbackActionConnectors: filteredOptions, connectorType: ConnectorType.Block, handlePostbackItemClick: onPostbackItemClick, connectorIconClassName: "adicon_nextblock" })),
        filterText && onNewBlockClick && (React.createElement(React.Fragment, null,
            filteredOptions.length > 0 && React.createElement("hr", { "data-testid": "break-line" }),
            React.createElement("div", { "data-testid": "add-new-block-button", className: "adicon_plus02 new-block " + (filterText ? '' : 'disable-click'), onClick: function () { return onNewBlockClick(filterText); }, role: "button", tabIndex: -1 }, intl.formatMessage({ id: "hJ1df7", defaultMessage: "Add a Block with this Name" }))))));
};
export default injectIntl(BlockPostbackConnector);
