var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { TextField } from '@zeals-co-ltd/washi-components';
import { DataType } from 'zeals-protobuf/zeals/web/entities/user_dossier_pb';
export var FieldTypeInput = function (props) {
    var error = props.error, helperText = props.helperText, placeholder = props.placeholder, value = props.value, onChange = props.onChange, dataTestId = props.dataTestId, dataType = props.dataType, InputProps = props.InputProps, disabled = props.disabled, name = props.name;
    var PreventKeys = function (e) {
        switch (dataType) {
            case DataType.DATA_TYPE_INTEGER:
                return ['e', 'E', '.'].includes(e.key) && e.preventDefault();
            default:
                return ['e', 'E'].includes(e.key) && e.preventDefault();
        }
    };
    switch (dataType) {
        case DataType.DATA_TYPE_DATETIME:
            return (React.createElement(TextField, { name: name, "data-testid": dataTestId, disabled: disabled, hideDisabledIcon: true, placeholder: placeholder, value: value, fullWidth: true, onChange: onChange, type: "datetime-local", InputLabelProps: __assign({}, InputProps) }));
        case DataType.DATA_TYPE_DOUBLE:
            return (React.createElement(TextField, { name: name, disabled: disabled, hideDisabledIcon: true, placeholder: placeholder, value: value, fullWidth: true, onChange: onChange, onKeyDown: PreventKeys, "data-testid": dataTestId, InputProps: __assign({}, InputProps), error: error, helperText: helperText, type: "number" }));
        case DataType.DATA_TYPE_INTEGER:
            return (React.createElement(TextField, { name: name, disabled: disabled, hideDisabledIcon: true, placeholder: placeholder, value: value, fullWidth: true, onChange: onChange, onKeyDown: PreventKeys, "data-testid": dataTestId, InputProps: InputProps, error: error, helperText: helperText, type: "number" }));
        default:
            return (React.createElement(TextField, { name: name, disabled: disabled, hideDisabledIcon: true, placeholder: placeholder, value: value, fullWidth: true, onChange: onChange, "data-testid": dataTestId, InputProps: InputProps, error: error, helperText: helperText }));
    }
};
export default FieldTypeInput;
