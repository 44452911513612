var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled, { css } from 'styled-components';
import { Button } from '@src/components/atoms';
import buttonTypes from '@src/lib/buttonTypes';
var Control = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  justify-content: space-between;\n  width: 150px;\n  top: 0;\n  ", ";\n"], ["\n  display: flex;\n  justify-content: space-between;\n  width: 150px;\n  top: 0;\n  ",
    ";\n"])), function (props) {
    return props.direction === 'right' && css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      position: absolute;\n      right: 0;\n    "], ["\n      position: absolute;\n      right: 0;\n    "])));
});
export default function UserFieldControl(_a) {
    var className = _a.className, direction = _a.direction, onClickUp = _a.onClickUp, onClickDown = _a.onClickDown, onClickDelete = _a.onClickDelete;
    var upArrow = '↑';
    var downArrow = '↓';
    return (React.createElement(Control, { "data-testid": "field-controls", className: className, direction: direction },
        onClickUp && (React.createElement(Button, { "data-testid": "UserFieldControl-UpArrow", onClick: onClickUp, buttonType: buttonTypes.SETTING_WHITE, text: upArrow, width: "40", minWidth: "40px", height: "40" })),
        onClickDown && (React.createElement(Button, { "data-testid": "UserFieldControl-DownArrow", onClick: onClickDown, buttonType: buttonTypes.SETTING_WHITE, text: downArrow, width: "40", minWidth: "40px", height: "40" })),
        onClickDelete && (React.createElement(Button, { "data-testid": "UserFieldControl-Delete", className: "adicon_delete", onClick: onClickDelete, buttonType: buttonTypes.DELETE, width: "40", minWidth: "40px", height: "40" }))));
}
var templateObject_1, templateObject_2;
