var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import { Input, InputWrapper } from '@src/components/atoms';
import { MINUTES } from '@src/lib/helpers';
import { injectIntl } from 'react-intl';
var Container = styled.span(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  .delay-settings {\n    display: inline-flex;\n    margin-top: 12px;\n    .after-minutes-input {\n      width: 80px;\n    }\n    .select-delay {\n      padding: 0 4px;\n      span {\n        &:after {\n          position: relative;\n          font-size: 1rem;\n        }\n      }\n    }\n  }\n  .time-settings {\n    display: inline-flex;\n    margin-top: 12px;\n    width: 40%;\n    text-align: left;\n    align-items: center;\n    .start-hour {\n      width: 80px;\n      margin: 0 8px 0 0;\n      text-align: left;\n    }\n    .start-minute {\n      width: 80px;\n      margin: 0 8px 0 16px;\n      text-align: left;\n    }\n  }\n"], ["\n  .delay-settings {\n    display: inline-flex;\n    margin-top: 12px;\n    .after-minutes-input {\n      width: 80px;\n    }\n    .select-delay {\n      padding: 0 4px;\n      span {\n        &:after {\n          position: relative;\n          font-size: 1rem;\n        }\n      }\n    }\n  }\n  .time-settings {\n    display: inline-flex;\n    margin-top: 12px;\n    width: 40%;\n    text-align: left;\n    align-items: center;\n    .start-hour {\n      width: 80px;\n      margin: 0 8px 0 0;\n      text-align: left;\n    }\n    .start-minute {\n      width: 80px;\n      margin: 0 8px 0 16px;\n      text-align: left;\n    }\n  }\n"])));
var defaultProps = {
    afterMinutes: 0,
    startMinutes: 0,
    startHour: 0,
    termType: 'min',
    handleChangeAfterMinutes: function (e) { return console.log(e.target.value); },
    handleChangeTermType: function (e) { return console.log(e.target.value); },
    handleChangeHour: function (e) { return console.log(e.target.value); },
    handleChangeMinutes: function (e) { return console.log(e.target.value); },
};
var AfterMinutes = function (_a) {
    var afterMinutes = _a.afterMinutes, startMinutes = _a.startMinutes, termType = _a.termType, startHour = _a.startHour, handleChangeAfterMinutes = _a.handleChangeAfterMinutes, handleChangeTermType = _a.handleChangeTermType, handleChangeHour = _a.handleChangeHour, handleChangeMinutes = _a.handleChangeMinutes, intl = _a.intl;
    return (React.createElement(Container, null,
        React.createElement("div", { className: "message-wrapper primary-label" }, intl.formatMessage({ id: "r04bCT", defaultMessage: "After fulfulling the conditions, deliver the scenario until this time" })),
        React.createElement("div", { className: "delay-settings" },
            React.createElement(Input, { className: "after-minutes-input", type: "text", value: afterMinutes / MINUTES[termType].value, onChange: function (e) {
                    var value = e.target.value;
                    if (!isNaN(Number(value))) {
                        handleChangeAfterMinutes(e);
                    }
                } }),
            React.createElement(InputWrapper, { width: "fit-content", margin: "0 0 0 8px", padding: "0 8px" },
                React.createElement("select", { role: "menu", className: "select-delay", value: termType, onChange: handleChangeTermType }, Object.keys(MINUTES).map(function (time, i) {
                    return (React.createElement("option", { value: time, key: time + "-" + (i + 1) }, intl.formatMessage(MINUTES[time].title)));
                })),
                React.createElement("span", { className: "adicon_arrow_down_after" }))),
        termType === 'day' && (React.createElement("div", null,
            React.createElement("div", { className: "message-wrapper primary-label" }, intl.formatMessage({ id: "sdJ1MP", defaultMessage: "Delivery Time Settings" })),
            React.createElement("div", { className: "time-settings", "data-testid": "AfterMinutes-TimeSettings" },
                React.createElement(Input, { "aria-label": "start-hour-input", className: "select-time start-hour", type: "number", value: startHour, onChange: handleChangeHour }),
                React.createElement("span", null, intl.formatMessage({ id: "IkG8j8", defaultMessage: "h" })),
                React.createElement(Input, { "aria-label": "start-minutes-input", className: "select-time start-minute", type: "number", value: startMinutes, onChange: handleChangeMinutes }),
                React.createElement("span", null, intl.formatMessage({ id: "8Uquag", defaultMessage: "m" })))))));
};
export default React.memo(injectIntl(AfterMinutes));
var templateObject_1;
