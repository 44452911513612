var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import { FormAutomationTypes, } from '../types/formAutomation';
export var initialState = {
    formAutomationConfigs: {},
    formAutomationSteps: {},
    stepErrors: {},
};
// The Reducer responsible for state related to our RPA Form Automation Settings Screen
export default (function (state, action) {
    var _a, _b, _c, _d, _e, _f, _g;
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case FormAutomationTypes.SET_FORM_CONFIG:
            return __assign(__assign({}, state), { formAutomationConfigs: __assign(__assign({}, state.formAutomationConfigs), action.formAutomationConfigs), formAutomationSteps: __assign(__assign({}, state.formAutomationSteps), action.formAutomationSteps) });
        case FormAutomationTypes.SET_FORM_CONFIGS:
            return __assign(__assign({}, state), { formAutomationConfigs: __assign(__assign({}, state.formAutomationConfigs), action.formAutomationConfigs), formAutomationSteps: __assign(__assign({}, state.formAutomationSteps), action.formAutomationSteps) });
        case FormAutomationTypes.SET_FORM_CONFIG_URL:
            return __assign(__assign({}, state), { formAutomationConfigs: __assign(__assign({}, state.formAutomationConfigs), (_a = {}, _a[action.formAutomationConfigId] = __assign(__assign({}, state.formAutomationConfigs[action.formAutomationConfigId]), { url: action.url }), _a)) });
        case FormAutomationTypes.SET_FORM_AUTOMATION_STEP:
            return __assign(__assign({}, state), { formAutomationSteps: __assign(__assign({}, state.formAutomationSteps), (_b = {}, _b[action.formAutomationStep.id] = action.formAutomationStep, _b)) });
        case FormAutomationTypes.SET_STEP_ORDER: {
            var from = action.from, to = action.to;
            var formAutomationConfig = state.formAutomationConfigs[action.formAutomationConfigId];
            var newSteps = __spreadArray([], __read(formAutomationConfig.stepsList));
            var lowerBound = 0;
            var upperBound = formAutomationConfig.stepsList.length - 1;
            // Check that from and to are in bounds
            if (from < lowerBound ||
                to < lowerBound ||
                from > upperBound ||
                to > upperBound)
                return state;
            var movedStep = newSteps[from];
            newSteps.splice(from, 1);
            newSteps.splice(to, 0, movedStep);
            return __assign(__assign({}, state), { formAutomationConfigs: __assign(__assign({}, state.formAutomationConfigs), (_c = {}, _c[action.formAutomationConfigId] = __assign(__assign({}, formAutomationConfig), { stepsList: newSteps }), _c)) });
        }
        case FormAutomationTypes.DELETE_STEP: {
            var formAutomationConfigs_1 = __assign({}, state.formAutomationConfigs);
            var formAutomationSteps = __assign({}, state.formAutomationSteps);
            // delete from steps
            delete formAutomationSteps[action.formAutomationStepId];
            // delete from each config
            Object.keys(formAutomationConfigs_1).forEach(function (configId) {
                formAutomationConfigs_1[configId].stepsList = formAutomationConfigs_1[configId].stepsList.filter(function (id) { return id !== action.formAutomationStepId; });
            });
            return __assign(__assign({}, state), { formAutomationConfigs: formAutomationConfigs_1,
                formAutomationSteps: formAutomationSteps });
        }
        case FormAutomationTypes.ADD_STEP_TO_CONFIG: {
            var config = state.formAutomationConfigs[action.formAutomationConfigId];
            if (!(config || action.formAutomationStep))
                return state;
            return __assign(__assign({}, state), { formAutomationConfigs: __assign(__assign({}, state.formAutomationConfigs), (_d = {}, _d[action.formAutomationConfigId] = __assign(__assign({}, config), { stepsList: __spreadArray(__spreadArray([], __read(config.stepsList)), [action.formAutomationStep.id]) }), _d)), formAutomationSteps: __assign(__assign({}, state.formAutomationSteps), (_e = {}, _e[action.formAutomationStep.id] = action.formAutomationStep, _e)) });
        }
        case FormAutomationTypes.ADD_STEP_TO_CONFIG_WITH_INDEX: {
            var config = state.formAutomationConfigs[action.formAutomationConfigId];
            if (!(config || action.formAutomationStep))
                return state;
            var tempConfigStepList = __spreadArray([], __read(config.stepsList));
            tempConfigStepList.splice(action.destinationIndex, 0, action.formAutomationStep.id);
            return __assign(__assign({}, state), { formAutomationConfigs: __assign(__assign({}, state.formAutomationConfigs), (_f = {}, _f[action.formAutomationConfigId] = __assign(__assign({}, config), { stepsList: __spreadArray([], __read(tempConfigStepList)) }), _f)), formAutomationSteps: __assign(__assign({}, state.formAutomationSteps), (_g = {}, _g[action.formAutomationStep.id] = action.formAutomationStep, _g)) });
        }
        case FormAutomationTypes.SET_STEP_ERRORS:
            return __assign(__assign({}, state), { stepErrors: action.stepErrors });
        case FormAutomationTypes.REMOVE_FORM_AUTOMATION_CONFIG: {
            var formAutomationConfigs = Object.assign({}, state.formAutomationConfigs);
            delete formAutomationConfigs[action.formAutomationConfigId];
            return __assign(__assign({}, state), { formAutomationConfigs: formAutomationConfigs });
        }
        default:
            return state;
    }
});
