var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import styled from 'styled-components';
import { colors } from '@src/colors';
import { PluginImageActionPreview, PluginModalPreview, Switch, } from '@src/components/atoms';
import { hoverDelete, centeredFlex } from '@src/lib/mixins';
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  p {\n    color: ", ";\n  }\n\n  .modal-delete-btn {\n    ", ";\n    width: 80px;\n    font-size: 1.5rem;\n    ", ";\n  }\n  .PluginModal_switch {\n    margin: 0 !important;\n  }\n"], ["\n  display: flex;\n  p {\n    color: ", ";\n  }\n\n  .modal-delete-btn {\n    ", ";\n    width: 80px;\n    font-size: 1.5rem;\n    ", ";\n  }\n  .PluginModal_switch {\n    margin: 0 !important;\n  }\n"])), colors.text_pale, centeredFlex, hoverDelete);
var PreviewWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n"], ["\n  display: flex;\n  justify-content: center;\n"])));
var PluginModal = function (_a) {
    var onClick = _a.onClick, onDelete = _a.onDelete, modal = __rest(_a, ["onClick", "onDelete"]);
    return (React.createElement(Container, { className: "row item", onClick: onClick },
        React.createElement("p", null, modal.id),
        React.createElement(PreviewWrapper, null, modal.modal_type === 'normal' ? (React.createElement(PluginModalPreview, { modal: modal })) : (React.createElement(PluginImageActionPreview, { modal: modal }))),
        React.createElement("p", { className: "modal-title" }, modal.title),
        React.createElement("p", { className: "modal-type" }, modal.modal_type),
        React.createElement("p", { className: "modal-interval-ctrl" },
            React.createElement(Switch, { className: "PluginModal_switch", isActive: modal.display_interval_control_flag === 'active' })),
        React.createElement("div", { className: "modal-delete-btn" },
            React.createElement("span", { role: "button", tabIndex: 0, "aria-label": "Delete", className: "adicon_delete icon_style", onClick: function (e) {
                    e.stopPropagation();
                    onDelete();
                } }))));
};
export default PluginModal;
var templateObject_1, templateObject_2;
