var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Component } from 'react';
import styled from 'styled-components';
import { Spinner } from '@src/components/atoms';
import { WebchatConversation } from '@src/components/organisms/fanp_webchat';
export var Header = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: table;\n  background: ", ";\n  width: 100%;\n  height: 6vh;\n  text-align: left;\n  border-radius: 10px 10px 0px 0px;\n  @media only screen and (max-width: 600px) {\n    border-radius: 0px;\n  }\n  .header-text {\n    display: table-cell;\n    padding-left: 15px;\n    vertical-align: middle;\n    color: #ffffff;\n    font-size: 18px;\n  }\n\n  .close-button {\n    vertical-align: right;\n    position: absolute;\n    top: 0px;\n    right: 15px;\n    color: #ffffff;\n    font-size: 24px;\n    font-weight: bold;\n    margin-top: 1vh;\n    cursor: pointer;\n    @media only screen and (max-width: 500px) {\n      font-size: 18px;\n    }\n  }\n"], ["\n  display: table;\n  background: ",
    ";\n  width: 100%;\n  height: 6vh;\n  text-align: left;\n  border-radius: 10px 10px 0px 0px;\n  @media only screen and (max-width: 600px) {\n    border-radius: 0px;\n  }\n  .header-text {\n    display: table-cell;\n    padding-left: 15px;\n    vertical-align: middle;\n    color: #ffffff;\n    font-size: 18px;\n  }\n\n  .close-button {\n    vertical-align: right;\n    position: absolute;\n    top: 0px;\n    right: 15px;\n    color: #ffffff;\n    font-size: 24px;\n    font-weight: bold;\n    margin-top: 1vh;\n    cursor: pointer;\n    @media only screen and (max-width: 500px) {\n      font-size: 18px;\n    }\n  }\n"])), function (_a) {
    var webchatApp = _a.webchatApp;
    return webchatApp && webchatApp.header_background_color
        ? webchatApp.header_background_color
        : '#0501bf';
});
var HeaderPanel = function (_a) {
    var webchatApp = _a.webchatApp, clickHandle = _a.clickHandle;
    return (React.createElement(Header, { webchatApp: webchatApp },
        React.createElement("div", { className: "header-text" }, webchatApp && webchatApp.header_text),
        React.createElement("div", { className: "close-button" },
            React.createElement("div", { onClick: function () { return clickHandle(); } }, "\u00D7"))));
};
var ConversationContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  overflow-y: auto;\n  height: 100%;\n  background-color: ", ";\n  .webchat_panel_spinner {\n    .spinner {\n      .path {\n        stroke: ", ";\n      }\n    }\n  }\n"], ["\n  overflow-y: auto;\n  height: 100%;\n  background-color: ", ";\n  .webchat_panel_spinner {\n    .spinner {\n      .path {\n        stroke: ", ";\n      }\n    }\n  }\n"])), function (props) { return props.webchatApp.background_color; }, function (props) { return props.webchatApp.header_background_color; });
var WebchatPanel = /** @class */ (function (_super) {
    __extends(WebchatPanel, _super);
    function WebchatPanel() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            talk_triggers: null,
            quick_reply: null,
            isTriggerMessage: false,
        };
        _this.setDisplayedMessages = function (displayedMessages, stateTalkTriggers) {
            if (displayedMessages &&
                displayedMessages.length >= 1 &&
                !stateTalkTriggers) {
                var _a = displayedMessages[displayedMessages.length - 1], talk_triggers = _a.talk_triggers, quick_reply = _a.quick_reply;
                var isTriggerMessage = !talk_triggers && quick_reply !== {};
                _this.setState({ talk_triggers: talk_triggers, quick_reply: quick_reply, isTriggerMessage: isTriggerMessage });
            }
        };
        return _this;
    }
    WebchatPanel.prototype.componentDidUpdate = function (prevProps, prevState) {
        var displayedMessages = this.props.displayedMessages;
        if (!displayedMessages.length ||
            prevProps.displayedMessages.length !== displayedMessages.length) {
            this.setDisplayedMessages(displayedMessages, this.state.talk_triggers);
        }
    };
    WebchatPanel.prototype.render = function () {
        var _a = this.props, displayedMessages = _a.displayedMessages, clickHandle = _a.clickHandle, webchatApp = _a.webchatApp, webchatOpened = _a.webchatOpened, onHandleAnswer = _a.onHandleAnswer, status = _a.status, isLoading = _a.isLoading;
        var isTriggerMessage = this.state.isTriggerMessage;
        return (React.createElement(React.Fragment, null,
            React.createElement(HeaderPanel, { clickHandle: clickHandle, webchatApp: webchatApp }),
            webchatOpened ? (React.createElement(ConversationContainer, { isTriggerMessage: isTriggerMessage, webchatApp: webchatApp }, isLoading ? (React.createElement(Spinner, { className: "webchat_panel_spinner" })) : (React.createElement(WebchatConversation, { displayedMessages: displayedMessages, onHandleAnswer: onHandleAnswer, status: status, webchatOpened: webchatOpened, webchatApp: webchatApp })))) : null));
    };
    return WebchatPanel;
}(Component));
export default WebchatPanel;
var templateObject_1, templateObject_2;
