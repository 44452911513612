import { ChatbotListType, } from '../types/chatbotList';
export var initialState = {
    isFetching: false,
    chatbotList: [],
    maxChatbotCount: 0,
};
export default (function (state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case ChatbotListType.TOGGLE_FETCHING_CHATBOT:
            return Object.assign({}, state, { isFetching: !state.isFetching });
        case ChatbotListType.SET_CHATBOT_LIST:
            return Object.assign({}, state, { chatbotList: action.chatbots });
        case ChatbotListType.SET_CHATBOT_ITEM:
            return Object.assign({}, state, {
                chatbotList: state.chatbotList.map(function (c) {
                    return c.id === action.chatbot.id ? action.chatbot : c;
                }),
            });
        case ChatbotListType.SET_MAX_CHATBOT_COUNT:
            return Object.assign({}, state, {
                maxChatbotCount: action.maxCount,
            });
        case ChatbotListType.SET_CHATBOT_STATISTICS:
            return Object.assign({}, state, {
                chatbotStatistics: action.chatbotStatistics,
            });
        case ChatbotListType.DELETE_CHATBOT:
            return Object.assign({}, state, {
                chatbotList: state.chatbotList.filter(function (chatbot) { return chatbot.id !== action.chatbotId; }),
            });
        default:
            return state;
    }
});
