var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import React, { useState, useMemo, useCallback } from 'react';
import { injectIntl } from 'react-intl';
import styled from 'styled-components';
import * as washiColors from '@zeals-co-ltd/washi-styles';
import { colors } from '@src/colors';
import ReactNumericInput from 'react-number-format';
import { Modal, Button, Table } from '@zeals-co-ltd/washi-components';
import { getIsNotSameWeightValue, newFormatWeight } from './common';
var WeightStatus;
(function (WeightStatus) {
    WeightStatus["Balance"] = "Balance";
    WeightStatus["Exceeded"] = "Exceeded";
    WeightStatus["Left"] = "Left";
})(WeightStatus || (WeightStatus = {}));
var StyledReactNumericInput = styled(ReactNumericInput)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  &[disabled] {\n    opacity: 0.6;\n  }\n  background: white;\n  border: 1px solid ", ";\n  color: ", ";\n  box-sizing: border-box;\n  padding: 12px 4px;\n  text-align: center;\n  gap: 8px;\n  width: 60px;\n  height: 44px;\n  border-radius: 8px;\n  font-weight: 400;\n  font-size: 14px;\n  line-height: 20px;\n"], ["\n  &[disabled] {\n    opacity: 0.6;\n  }\n  background: white;\n  border: 1px solid ", ";\n  color: ", ";\n  box-sizing: border-box;\n  padding: 12px 4px;\n  text-align: center;\n  gap: 8px;\n  width: 60px;\n  height: 44px;\n  border-radius: 8px;\n  font-weight: 400;\n  font-size: 14px;\n  line-height: 20px;\n"])), colors.silver_lightening, colors.old_navy);
var ONE_HUNDRED = 100;
var useWeightGap = function (status, total) {
    var weightGap = 0;
    switch (status) {
        case WeightStatus.Exceeded:
            weightGap = total - ONE_HUNDRED;
            break;
        case WeightStatus.Left:
            weightGap = ONE_HUNDRED - total;
            break;
        default:
            weightGap = 0;
            break;
    }
    return { weightGap: weightGap };
};
var StyledModal = styled(Modal)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  .WashiModalHeader-root {\n    padding-bottom: 8px;\n  }\n\n  .MuiDialogContent-root {\n    padding-top: 0;\n    padding-bottom: 0;\n  }\n"], ["\n  .WashiModalHeader-root {\n    padding-bottom: 8px;\n  }\n\n  .MuiDialogContent-root {\n    padding-top: 0;\n    padding-bottom: 0;\n  }\n"])));
var StatusLabelWrapper = styled.span(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-weight: 500;\n  line-height: 14px;\n  letter-spacing: 1.5px;\n  display: inline-block;\n  text-transform: uppercase;\n  padding: 6px 8px;\n  border-radius: 48px;\n  font-size: 10px;\n  margin: auto auto auto 0;\n  ", ";\n"], ["\n  font-weight: 500;\n  line-height: 14px;\n  letter-spacing: 1.5px;\n  display: inline-block;\n  text-transform: uppercase;\n  padding: 6px 8px;\n  border-radius: 48px;\n  font-size: 10px;\n  margin: auto auto auto 0;\n  ",
    ";\n"])), function (props) {
    switch (props.weightStatus) {
        case WeightStatus.Exceeded:
            return "\n        background:" + washiColors.red[100] + ";\n        color:" + washiColors.red[900] + ";\n        ";
        case WeightStatus.Left:
            return "\n        background: " + washiColors.orange[100] + ";\n        color:" + washiColors.orange[900] + ";\n        ";
        case WeightStatus.Balance:
        default:
            return "\n        background:" + washiColors.green[50] + ";\n        color:" + washiColors.green[900] + ";\n        ";
    }
});
var StatusWrapper = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  justify-content: space-between;\n  margin-bottom: 16px;\n"], ["\n  display: flex;\n  justify-content: space-between;\n  margin-bottom: 16px;\n"])));
var StyledTable = styled(Table)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  min-width: 475px;\n  border-radius: 8px;\n  padding: 8px;\n  background: ", ";\n  .MuiOutlinedInput-root {\n    padding: 0;\n  }\n  tr.MuiTableRow-root td.MuiTableCell-root {\n    border: 0;\n  }\n\n  td.MuiTableCell-root {\n    color: ", ";\n  }\n\n  tbody.MuiTableBody-root {\n    border: 0;\n  }\n\n  tr.MuiTableRow-root {\n  }\n\n  tr.MuiTableRow-root th.MuiTableCell-root {\n    border: 0;\n  }\n\n  thead.MuiTableHead-root {\n    display: none;\n  }\n\n  tr.MuiTableRow-root td.MuiTableCell-root:last-child {\n    text-align: right;\n  }\n"], ["\n  min-width: 475px;\n  border-radius: 8px;\n  padding: 8px;\n  background: ", ";\n  .MuiOutlinedInput-root {\n    padding: 0;\n  }\n  tr.MuiTableRow-root td.MuiTableCell-root {\n    border: 0;\n  }\n\n  td.MuiTableCell-root {\n    color: ", ";\n  }\n\n  tbody.MuiTableBody-root {\n    border: 0;\n  }\n\n  tr.MuiTableRow-root {\n  }\n\n  tr.MuiTableRow-root th.MuiTableCell-root {\n    border: 0;\n  }\n\n  thead.MuiTableHead-root {\n    display: none;\n  }\n\n  tr.MuiTableRow-root td.MuiTableCell-root:last-child {\n    text-align: right;\n  }\n"])), washiColors.grey[100], washiColors.navy[900]);
var TablesWrapper = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: flex;\n  overflow: visible;\n  & > div:nth-child(2) {\n    margin-left: 8px;\n  }\n"], ["\n  display: flex;\n  overflow: visible;\n  & > div:nth-child(2) {\n    margin-left: 8px;\n  }\n"])));
var StatusLabel = function (props) {
    var status = props.status, total = props.total, intl = props.intl;
    var weightGap = useWeightGap(status, total).weightGap;
    var weightGapFixed = newFormatWeight(weightGap);
    var totalFixed = newFormatWeight(total);
    var totalWeight100Text = intl.formatMessage({ id: "wd8U9c", defaultMessage: "total weight: 100%" });
    var weightExceededText = intl.formatMessage({ id: "mnPbOT", defaultMessage: "Weight exceeded by:" });
    var weightLeftText = intl.formatMessage({ id: "l1ztSs", defaultMessage: "Weight left:" });
    return (React.createElement(StatusLabelWrapper, { className: "status", weightStatus: status },
        status === WeightStatus.Balance && totalWeight100Text,
        status === WeightStatus.Exceeded &&
            weightExceededText + " " + weightGapFixed + "% (" + totalFixed + "% set)",
        status === WeightStatus.Left &&
            weightLeftText + " " + weightGapFixed + "% (" + totalFixed + "% set)"));
};
var SettingWeightModal = function (props) {
    var _a = __read(useState(__spreadArray([], __read(props.scenarios)).map(function (item) { return (__assign({}, item)); })), 2), scenarios = _a[0], setScenarios = _a[1];
    var intl = props.intl;
    var _b = useMemo(function () {
        var allWeight = ONE_HUNDRED;
        var weightStatus = WeightStatus.Balance;
        var isNotSameWeight = getIsNotSameWeightValue(scenarios);
        if (isNotSameWeight) {
            allWeight = newFormatWeight(scenarios.reduce(function (count, scenario) { return (scenario.weight || 0) + count; }, 0));
            weightStatus =
                allWeight > ONE_HUNDRED ? WeightStatus.Exceeded : weightStatus;
            weightStatus = allWeight < ONE_HUNDRED ? WeightStatus.Left : weightStatus;
        }
        return { weightStatus: weightStatus, allWeight: allWeight };
    }, [scenarios]), weightStatus = _b.weightStatus, allWeight = _b.allWeight;
    var ableToSave = weightStatus === WeightStatus.Balance;
    var hasTwoParts = scenarios.length > 5;
    var firstFive = scenarios.slice(0, 5);
    var secondFive = scenarios.slice(5, scenarios.length);
    var onNumberChange = useCallback(function (value, scenarioId) {
        var changedScenarios = scenarios.map(function (s) {
            if (s.id === scenarioId) {
                return __assign(__assign({}, s), { weight: value });
            }
            return __assign(__assign({}, s), { weight: newFormatWeight(s.weight) });
        });
        setScenarios(changedScenarios);
    }, [scenarios]);
    var onClickDivideEqually = function () {
        var allWeight = ONE_HUNDRED;
        var newScenarios = scenarios.map(function (s) { return (__assign(__assign({}, s), { weight: allWeight / scenarios.length })); });
        setScenarios(newScenarios);
    };
    var onWeightModalConfirm = function () {
        var isNotSameWeight = getIsNotSameWeightValue(scenarios);
        props.onConfirm({ scenarios: scenarios, divideEqually: !isNotSameWeight });
    };
    return (React.createElement(StyledModal, { onClose: props.onClose, open: true, title: intl.formatMessage({ id: "4UmnD0", defaultMessage: "Weight Setting" }), maxWidth: false, actions: React.createElement(React.Fragment, null,
            React.createElement(Button, { "data-testid": "SettingWeightModal_CancelButton", onClick: props.onClose }, intl.formatMessage({ id: "47FYwb", defaultMessage: "Cancel" })),
            React.createElement(Button, { disabled: !ableToSave, onClick: onWeightModalConfirm, variant: "contained", "data-testid": "SettingWeightModal_ButtonApply" }, intl.formatMessage({ id: "EWw/tK", defaultMessage: "Apply" }))) },
        React.createElement(StatusWrapper, null,
            React.createElement(StatusLabel, { intl: intl, status: weightStatus, total: allWeight }),
            React.createElement(Button, { type: "button", variant: "text", size: "small", "data-testid": "DivideEquallyButton", onClick: onClickDivideEqually, color: "error" }, intl.formatMessage({ id: "jCcH5Q", defaultMessage: "divide equally" }))),
        React.createElement(TablesWrapper, null, [firstFive, secondFive].map(function (part) {
            return part.length ? (React.createElement(StyledTable, { style: hasTwoParts
                    ? {
                        background: washiColors.grey[100],
                    }
                    : undefined, columns: [
                    {
                        header: '',
                        name: 'name',
                    },
                    {
                        name: '',
                        header: '',
                        cell: function (scenario) {
                            return (React.createElement(StyledReactNumericInput, { suffix: "%", "data-testid": "SettingWeightModal_WeightInput" + scenario.id, onValueChange: function (_a) {
                                    var floatValue = _a.floatValue;
                                    onNumberChange(floatValue, scenario.id);
                                }, decimalScale: 1, value: (scenario === null || scenario === void 0 ? void 0 : scenario.weight) || 0 }));
                        },
                    },
                ], data: part, includeHeaders: true })) : undefined;
        }))));
};
export default injectIntl(SettingWeightModal);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
