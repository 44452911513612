import "core-js/modules/es.symbol";
import "core-js/modules/es.array.concat";
import "core-js/modules/es.array.filter";
import "core-js/modules/es.array.slice";
import "core-js/modules/es.object.get-own-property-descriptor";
import "core-js/modules/es.object.get-own-property-descriptors";
import "core-js/modules/es.object.keys";
import "core-js/modules/web.dom-collections.for-each";
import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

export var updatePersistentMenuItem = function updatePersistentMenuItem(state, action) {
  var persistentMenuItems = state.persistentMenuItems;
  var persistentMenuIterator = action.persistentMenuIterator,
      persistentMenuObject = action.persistentMenuObject,
      adScenarioId = action.adScenarioId,
      selectedTab = action.selectedTab;

  var persistentMenuWithChildren = _objectSpread({}, persistentMenuItems[persistentMenuIterator], {
    title: persistentMenuObject.title,
    url: persistentMenuObject.url,
    effect: persistentMenuObject.effect,
    scenario_id: persistentMenuObject.url || persistentMenuObject.effect || selectedTab === 'Submenu' ? null : adScenarioId
  });

  var persistentMenuNoChildren = _objectSpread({}, persistentMenuWithChildren);

  delete persistentMenuNoChildren.children;
  return _objectSpread({}, state, {
    persistentMenuItems: [].concat(_toConsumableArray(persistentMenuItems.slice(0, persistentMenuIterator)), [selectedTab === 'Submenu' ? persistentMenuWithChildren : persistentMenuNoChildren], _toConsumableArray(persistentMenuItems.slice(persistentMenuIterator + 1)))
  });
};
export var updateChildPersistentMenuItem = function updateChildPersistentMenuItem(state, action) {
  var persistentMenuItems = state.persistentMenuItems;
  var parentPersistentMenuIterator = action.parentPersistentMenuIterator,
      persistentMenuIterator = action.persistentMenuIterator,
      persistentMenuObject = action.persistentMenuObject,
      adScenarioId = action.adScenarioId;
  var title = persistentMenuObject.title,
      url = persistentMenuObject.url,
      effect = persistentMenuObject.effect;
  var parentPersistentMenuItem = persistentMenuItems[parentPersistentMenuIterator];
  return _objectSpread({}, state, {
    persistentMenuItems: [].concat(_toConsumableArray(persistentMenuItems.slice(0, parentPersistentMenuIterator)), [_objectSpread({}, persistentMenuItems[parentPersistentMenuIterator], {
      children: [].concat(_toConsumableArray(parentPersistentMenuItem.children.slice(0, persistentMenuIterator)), [_objectSpread({}, parentPersistentMenuItem[persistentMenuIterator], {
        title: title,
        url: url,
        effect: effect,
        scenario_id: persistentMenuObject.url || persistentMenuObject.effect ? null : adScenarioId
      })], _toConsumableArray(parentPersistentMenuItem.children.slice(persistentMenuIterator + 1)))
    })], _toConsumableArray(persistentMenuItems.slice(parentPersistentMenuIterator + 1)))
  });
};
export var handlePersistentMenuItem = function handlePersistentMenuItem(state, action) {
  var persistentMenuItems = state.persistentMenuItems;
  var persistentMenuIterator = action.persistentMenuIterator,
      persistentMenuObject = action.persistentMenuObject,
      selectedTab = action.selectedTab;
  return _objectSpread({}, state, {
    persistentMenuItems: [].concat(_toConsumableArray(persistentMenuItems.slice(0, persistentMenuIterator)), [_objectSpread({}, persistentMenuItems[persistentMenuIterator], {}, persistentMenuObject, {
      scenario_id: selectedTab === 'Submenu' ? null : persistentMenuObject.scenario_id
    })], _toConsumableArray(persistentMenuItems.slice(persistentMenuIterator + 1)))
  });
};
export var handleChildPersistentMenuItem = function handleChildPersistentMenuItem(state, action) {
  var persistentMenuItems = state.persistentMenuItems;
  var parentPersistentMenuIterator = action.parentPersistentMenuIterator,
      persistentMenuIterator = action.persistentMenuIterator,
      persistentMenuObject = action.persistentMenuObject;
  var parentPersistentMenuItem = persistentMenuItems[parentPersistentMenuIterator];
  return _objectSpread({}, state, {
    persistentMenuItems: [].concat(_toConsumableArray(persistentMenuItems.slice(0, parentPersistentMenuIterator)), [_objectSpread({}, persistentMenuItems[parentPersistentMenuIterator], {
      children: [].concat(_toConsumableArray(parentPersistentMenuItem.children.slice(0, persistentMenuIterator)), [_objectSpread({}, parentPersistentMenuItem[persistentMenuIterator], {}, persistentMenuObject)], _toConsumableArray(parentPersistentMenuItem.children.slice(persistentMenuIterator + 1)))
    })], _toConsumableArray(persistentMenuItems.slice(parentPersistentMenuIterator + 1)))
  });
};
export var deletePersistentMenuItem = function deletePersistentMenuItem(state, action) {
  var persistentMenuItems = state.persistentMenuItems;
  var persistentMenuIterator = action.persistentMenuIterator;
  return _objectSpread({}, state, {
    persistentMenuItems: [].concat(_toConsumableArray(persistentMenuItems.slice(0, persistentMenuIterator)), _toConsumableArray(persistentMenuItems.slice(persistentMenuIterator + 1)))
  });
};
export var deleteChildPersistentMenuItem = function deleteChildPersistentMenuItem(state, action) {
  var persistentMenuItems = state.persistentMenuItems;
  var parentPersistentMenuIterator = action.parentPersistentMenuIterator,
      persistentMenuIterator = action.persistentMenuIterator;
  return _objectSpread({}, state, {
    persistentMenuItems: [].concat(_toConsumableArray(persistentMenuItems.slice(0, parentPersistentMenuIterator)), [_objectSpread({}, persistentMenuItems[parentPersistentMenuIterator], {
      children: [].concat(_toConsumableArray(persistentMenuItems[parentPersistentMenuIterator].children.slice(0, persistentMenuIterator)), _toConsumableArray(persistentMenuItems[parentPersistentMenuIterator].children.slice(persistentMenuIterator + 1)))
    })], _toConsumableArray(persistentMenuItems.slice(parentPersistentMenuIterator + 1)))
  });
};