import "core-js/modules/es.array.concat";

var collection = function collection() {
  return "/integration_plugins/actions";
};

var member = function member(_ref) {
  var actionId = _ref.actionId;
  return "".concat(collection(), "/").concat(actionId);
};

export default {
  member: member,
  collection: collection
};