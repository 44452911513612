var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState, forwardRef } from 'react';
import styled from 'styled-components';
import { colors } from '@src/colors';
import { transitions } from '@src/lib/animations';
import { centeredFlex, pseudoBefore } from '@src/lib/mixins';
var getBorderCSS = function (props) {
    var showBorderOnFocus = props.showBorderOnFocus, focused = props.focused, type = props.type, hasError = props.hasError;
    if (type === 'checkbox')
        return 'none';
    if (showBorderOnFocus && !focused)
        return 'none';
    return hasError
        ? "1px solid " + colors.border.error
        : "1px solid " + colors.border.default;
};
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: relative;\n  width: 100%;\n  height: 40px;\n  margin: 0 auto;\n  -webkit-padding-start: 16px;\n  padding: 0 16px;\n  color: ", ";\n  background: ", ";\n  border: ", ";\n  border-width: 1px;\n  border-radius: 5px;\n  ", ";\n  ", ";\n  display: inline-flex;\n  transition: ", ";\n  &::before {\n    position: relative;\n    margin-right: 8px;\n  }\n  input,\n  select {\n    width: 100%;\n    height: 100%;\n    font-size: 12px;\n    color: ", ";\n    letter-spacing: 0;\n    line-height: 14px;\n  }\n  input {\n    &::placeholder {\n      color: ", ";\n    }\n  }\n  &:hover {\n    box-shadow: none;\n  }\n"], ["\n  position: relative;\n  width: 100%;\n  height: 40px;\n  margin: 0 auto;\n  -webkit-padding-start: 16px;\n  padding: 0 16px;\n  color: ", ";\n  background: ", ";\n  border: ", ";\n  border-width: 1px;\n  border-radius: 5px;\n  ", ";\n  ", ";\n  display: inline-flex;\n  transition: ", ";\n  &::before {\n    position: relative;\n    margin-right: 8px;\n  }\n  input,\n  select {\n    width: 100%;\n    height: 100%;\n    font-size: 12px;\n    color: ", ";\n    letter-spacing: 0;\n    line-height: 14px;\n  }\n  input {\n    &::placeholder {\n      color: ", ";\n    }\n  }\n  &:hover {\n    box-shadow: none;\n  }\n"])), colors.default_text, colors.background.main_menu, function (props) { return getBorderCSS(props); }, centeredFlex, pseudoBefore, transitions.normal, colors.default_text, colors.placeholder);
var Input = forwardRef(function (_a, ref) {
    var value = _a.value, defaultValue = _a.defaultValue, onBlur = _a.onBlur, onFocus = _a.onFocus, type = _a.type, className = _a.className, iconClassName = _a.iconClassName, children = _a.children, isReadonly = _a.isReadonly, showBorderOnFocus = _a.showBorderOnFocus, maxLength = _a.maxLength, hasError = _a.hasError, dataTest = _a.dataTest, props = __rest(_a, ["value", "defaultValue", "onBlur", "onFocus", "type", "className", "iconClassName", "children", "isReadonly", "showBorderOnFocus", "maxLength", "hasError", "dataTest"]);
    var _b = __read(useState(false), 2), focused = _b[0], updateFocused = _b[1];
    return (React.createElement(Container, { type: type, className: (className || '') + " " + (iconClassName || ''), focused: focused, showBorderOnFocus: showBorderOnFocus, hasError: hasError },
        React.createElement("input", __assign({ value: value, type: type, defaultValue: defaultValue, maxLength: maxLength, onFocus: function (e) {
                if (onFocus) {
                    onFocus(e);
                }
                updateFocused(true);
            }, onBlur: function (e) {
                if (onBlur) {
                    onBlur(e);
                }
                updateFocused(false);
            }, readOnly: isReadonly, ref: ref, "data-testid": dataTest }, props)),
        children));
});
Input.defaultProps = { hasError: false, showBorderOnFocus: false };
export default Input;
var templateObject_1;
