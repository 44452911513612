var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import styled from 'styled-components';
import { colors } from '@src/colors';
import RichMenuArea from '@src/components/molecules/RichMenuArea';
import { createInitialUrlValue } from '@src/lib/helpers/createInitialUrlValue';
import { messageTypes } from '@src/lib/app-constants';
import { styledTemplate, sortAreaTemplate } from '@src/lib/helpers';
// This template grid has same logic with rich menu, all of it move to
// styledTemplate method on helper
var TemplateGrid = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 340px;\n  height: 244px;\n  background: ", ";\n  background-repeat: no-repeat;\n  background-position: center;\n  background-size: contain;\n  position: relative;\n\n  > div:not(.adicon_delete) {\n    font-size: 0.8rem;\n    padding: 0 5px 5px;\n    color: ", ";\n    display: flex;\n    flex-direction: column;\n    justify-content: flex-end;\n  }\n\n  ", "\n"], ["\n  width: 340px;\n  height: 244px;\n  background: ", ";\n  background-repeat: no-repeat;\n  background-position: center;\n  background-size: contain;\n  position: relative;\n\n  > div:not(.adicon_delete) {\n    font-size: 0.8rem;\n    padding: 0 5px 5px;\n    color: ", ";\n    display: flex;\n    flex-direction: column;\n    justify-content: flex-end;\n  }\n\n  ",
    "\n"])), function (props) { return "url(" + props.imageUrl + ")" || 'unset'; }, colors.white, function (props) {
    return styledTemplate(__assign(__assign({}, props), { scaleByWidth: true, messageType: messageTypes.RICH_MENU }));
});
var RichMenuTemplate = function (_a) {
    var areas = _a.areas, templateNum = _a.templateNum, richMenuAreas = _a.richMenuAreas, isPreview = _a.isPreview, richMenuAreaUpdateHandler = _a.richMenuAreaUpdateHandler, rest = __rest(_a, ["areas", "templateNum", "richMenuAreas", "isPreview", "richMenuAreaUpdateHandler"]);
    var richMenuAreaArr = isPreview
        ? Array(areas.length).fill(0)
        : richMenuAreas;
    return (React.createElement(TemplateGrid, __assign({ isPreview: isPreview, areas: areas }, rest),
        rest.children,
        React.createElement("div", { className: "template-areas" }, richMenuAreaArr.map(function (rma, i) { return (React.createElement(RichMenuArea, __assign({ key: "rich-menu-template-" + (rma.id || templateNum) + "-" + areas[i].color, index: i, area: sortAreaTemplate(areas)[i] || {}, richMenuArea: rma, initialLabel: rma.label, initialUrl: createInitialUrlValue('url', rma), initialPhoneNumber: createInitialUrlValue('tel', rma), initialEmailAddress: createInitialUrlValue('mailto', rma), isPreview: isPreview, richMenuAreaUpdateHandler: richMenuAreaUpdateHandler, richMenuAreaArr: richMenuAreaArr }, rest))); }))));
};
export default RichMenuTemplate;
var templateObject_1;
