var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import { CompletionStatus, FormUsage, FormElementType, FormElementTextUsage, FormSelectRepresentationType, SegmentType, } from 'zeals-protobuf/zeals/web/entities/form_pb';
import { name, internet, hacker } from 'faker';
import * as google_protobuf_timestamp_pb from 'google-protobuf/google/protobuf/timestamp_pb';
import { DayOfWeek } from 'zeals-protobuf/zeals/web/entities/common_pb';
import { v4 as uuid } from 'uuid';
import { QuestionType } from 'zeals-protobuf/zeals/node/entities/message_pb';
import { FormAutomationTriggerType } from 'zeals-protobuf/zeals/node/entities/form_pb';
export var mockFormList = function (formCount // : Form.AsObject[]
) { return __spreadArray([], __read(Array(formCount))).map(function (_, index) { return mockForm(index + 1); }); };
// TODO Update type with protobuf changes
export function mockForm(
// eslint-disable-next-line default-param-last
id, formName, completionStatus) {
    if (id === void 0) { id = 1; }
    var timestamp = new google_protobuf_timestamp_pb.Timestamp();
    timestamp.fromDate(new Date());
    return {
        id: id.toString(),
        name: formName || 'Webform Test',
        formBuildId: uuid(),
        pagesList: [mockPage(id)],
        logoUri: internet.url(),
        styles: {
            themeColor: 'red',
        },
        templateId: id.toString(),
        usage: FormUsage.FORM_USAGE_NORMAL,
        completionStatus: completionStatus || CompletionStatus.COMPLETION_STATUS_UNSPECIFIED,
        triggerFormAutomation: false,
        record: {
            createTime: timestamp.toObject(),
            updateTime: timestamp.toObject(),
        },
    };
}
export function mockFormTemplates(numOfTemplates) {
    return new Array(numOfTemplates)
        .fill(null)
        .map(function (_, i) { return mockFormTemplate(i + 1); });
}
export function mockFormTemplate(id) {
    return {
        id: id.toString(),
        name: name.firstName(),
        pagesList: [mockPageForTemplate1(id)],
        usage: FormUsage.FORM_USAGE_NORMAL,
    };
}
export function mockFormElementTemplate(id) {
    return {
        id: id.toString(),
        name: name.firstName(),
        description: hacker.phrase(),
        formElement: mockFormElement(id),
    };
}
function mockPage(id) {
    return {
        elementsList: [mockFormElement(id)],
    };
}
function mockPageForTemplate1(id) {
    return {
        elementsList: [mockFormElementDifferentName1(id)],
    };
}
export function mockFormElement(id, type) {
    if (type === void 0) { type = FormElementType.FORM_ELEMENT_TYPE_TEXT; }
    var timestamp = new google_protobuf_timestamp_pb.Timestamp();
    var timeMS = Date.now();
    timestamp.setSeconds(timeMS / 1000);
    timestamp.setNanos((timeMS % 1000) * 1e6);
    var obj = {
        id: id.toString(),
        name: name.firstName(),
        description: 'description',
        type: type,
        fieldId: id.toString(),
        required: true,
        templateId: id.toString(),
        errorMessage: '',
        text: undefined,
        numeric: undefined,
        select: undefined,
        datePicker: undefined,
        datetimePicker: undefined,
        timePicker: undefined,
        creditCard: undefined,
        calendar: undefined,
    };
    // Note: Non-exhuastive, add types as necessary
    switch (type) {
        case FormElementType.FORM_ELEMENT_TYPE_TEXT:
            obj.text = {
                placeholder: 'placeholder',
                usage: FormElementTextUsage.FORM_ELEMENT_TEXT_USAGE_EMAIL,
                validation: {
                    regexp: '^mock$',
                },
            };
            break;
        case FormElementType.FORM_ELEMENT_TYPE_NUMERIC:
            obj.numeric = {
                step: 1,
                pb_default: 1,
                validation: {
                    min: 1,
                    max: 2,
                },
            };
            break;
        case FormElementType.FORM_ELEMENT_TYPE_SELECT:
            obj.select = {
                representation: FormSelectRepresentationType.FORM_SELECT_REPRESENTATION_TYPE_UNSPECIFIED,
                optionsList: [
                    {
                        label: name.firstName(),
                        value: name.firstName(),
                    },
                    {
                        label: name.firstName(),
                        value: name.firstName(),
                    },
                ],
                editable: false,
            };
            break;
        case FormElementType.FORM_ELEMENT_TYPE_DATE_PICKER:
            obj.datePicker = {
                disableBefore: {
                    year: 2021,
                    month: 10,
                    day: 31,
                },
                disableAfter: {
                    year: 9999,
                    month: 10,
                    day: 31,
                },
                openingDaysList: [DayOfWeek.DAY_OF_WEEK_UNSPECIFIED],
            };
            break;
        case FormElementType.FORM_ELEMENT_TYPE_DATETIME_PICKER:
            obj.datetimePicker = {
                disableBefore: timestamp.toObject(),
                disableAfter: timestamp.toObject(),
            };
            break;
        case FormElementType.FORM_ELEMENT_TYPE_TIME_PICKER:
            obj.timePicker = {
                disableBefore: {
                    hours: 2,
                    minutes: 20,
                    seconds: 200,
                    nanos: 2000,
                },
                disableAfter: {
                    hours: 4,
                    minutes: 40,
                    seconds: 400,
                    nanos: 4000,
                },
            };
            break;
        case FormElementType.FORM_ELEMENT_TYPE_CREDIT_CARD:
            obj.creditCard = {
                providersList: [name.firstName(), name.firstName()],
            };
            break;
        case FormElementType.FORM_ELEMENT_TYPE_CALENDAR:
            obj.calendar = {
                calendar: undefined,
                parentElementId: {
                    value: '',
                },
                question: { type: 2 },
                remote: {
                    type: 1,
                    cache: undefined,
                    scrape: {
                        scrapePath: '/v1/calenders/lacoco/shops?prefecture=prefecture',
                    },
                    dateFormat: '',
                },
                type: 10,
            };
            break;
        default:
        // Do nothing
    }
    return obj;
}
function mockFormElementDifferentName1(id) {
    return {
        id: id.toString(),
        name: '氏',
        description: 'description',
        type: FormElementType.FORM_ELEMENT_TYPE_TEXT,
        fieldId: id.toString(),
        required: true,
        templateId: id.toString(),
        errorMessage: '',
        text: {
            placeholder: 'placeholder',
            usage: FormElementTextUsage.FORM_ELEMENT_TEXT_USAGE_EMAIL,
            validation: {
                regexp: '',
            },
        },
    };
}
export function mockFormBuild(segmentCompletionStatus) {
    var timestamp = new google_protobuf_timestamp_pb.Timestamp();
    var timeMS = Date.now();
    timestamp.setSeconds(timeMS / 1000);
    timestamp.setNanos((timeMS % 1000) * 1e6);
    return {
        id: '1',
        fieldCollectionId: '1',
        segmentsList: [
            mockFormBuildSegment(SegmentType.SEGMENT_TYPE_FORM_SCENARIO, segmentCompletionStatus),
            mockFormBuildSegment(SegmentType.SEGMENT_TYPE_WEB_FORM, segmentCompletionStatus),
        ],
        chatbotId: 1,
        name: 'Test Form Build',
        completionStatus: CompletionStatus.COMPLETION_STATUS_ERROR,
        authorName: 'Basile',
        authorEmail: 'Basileeeeee@gmail.com',
        editorName: 'Micah',
        editorEmail: 'Micahhhhh@gmail.com',
        record: {
            createTime: timestamp.toObject(),
            updateTime: timestamp.toObject(),
        },
        formAutomationTrigger: {
            type: FormAutomationTriggerType.FORM_AUTOMATION_TRIGGER_TYPE_UNSPECIFIED,
        },
    };
}
export function mockFormBuildSegment(type, segmentCompletionStatus) {
    if (type === SegmentType.SEGMENT_TYPE_FORM_SCENARIO) {
        return {
            type: type,
            name: 'Form Conversation Test',
            formScenario: mockFormConversation(segmentCompletionStatus),
        };
    }
    if (type === SegmentType.SEGMENT_TYPE_WEB_FORM) {
        return {
            type: type,
            name: 'Webform Test',
            webForm: mockForm(segmentCompletionStatus),
        };
    }
    return undefined;
}
export function mockFormConversation(completionStatus) {
    return {
        name: 'mock scenario name',
        id: uuid(),
        formBuildId: uuid(),
        scenarioId: 1,
        formQuestionsList: mockFormQuestions(10),
        completionStatus: completionStatus || CompletionStatus.COMPLETION_STATUS_UNSPECIFIED,
    };
}
export function mockFormQuestion(questionType, // QuestionType.QUESTION_TYPE_TALK_TRIGGER
fieldId, regexp) {
    if (questionType === void 0) { questionType = 1; }
    if (fieldId === void 0) { fieldId = '1'; }
    if (regexp === void 0) { regexp = ''; }
    return {
        id: uuid(),
        formBuildId: uuid(),
        triggerFormAutomation: false,
        textValidation: {
            regexp: regexp,
        },
        scenarioQuestion: mockQuestion(questionType, String(fieldId)),
        errorMessage: '',
    };
}
export function mockFormQuestions(numOfFormQuestions) {
    return new Array(numOfFormQuestions)
        .fill(null)
        .map(function (_, i) { return mockFormQuestion(i + 1, String(i + 1)); });
}
export function mockTalkTrigger() {
    return {
        id: uuid(),
        talkTriggerId: 1,
    };
}
export function mockQuickReply() {
    return {
        id: uuid(),
        quickReplyId: 1,
    };
}
export function mockQuestion(questionType, fieldId) {
    return {
        id: 1,
        scenarioId: 1,
        type: questionType === 1
            ? QuestionType.QUESTION_TYPE_TALK_TRIGGER
            : QuestionType.QUESTION_TYPE_QUICK_REPLY,
        fieldId: fieldId,
        text: uuid(),
        quickReplyId: questionType !== 1 && { value: 1 },
        talkTriggerId: questionType === 1 && { value: 1 },
    };
}
