import { object, string } from 'yup';
import { addMaxLength } from '@src/lib/helpers';
import { getErrorMessage } from '@src/lib/yupSchemaCreator';
import postbackActionSchema from './postbackActionSchema';
/**
 * This schema for QuickTriggerModal, it will return errors object to
 * use for validate on this modal
 * this errors object will contain name and postback_action
 *
 * @param intl - to do i18n
 * @param platform - to set max length for input text field
 * @param context - will contain effect value pass to postback_action schema
 */
export default (function (intl, platform, context) {
    return object({
        name: addMaxLength(string().required(getErrorMessage(intl, 'QuickReplyChoice')), intl, 'postback_action.label', platform),
        postback_action: postbackActionSchema(intl, platform, context, {
            block_id_required: false,
            scenario_id_required: false,
        }),
    });
});
