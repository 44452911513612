var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { colors } from '@src/colors';
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: 1rem 3rem;\n  background: ", ";\n  min-height: 90vh;\n\n  .tabs {\n    position: relative;\n    z-index: 0;\n\n    .tab {\n      font-size: 0.8rem;\n      display: inline-block;\n      padding: 0.3rem 1rem;\n      border: 1px solid ", ";\n      border-bottom: none;\n      background: ", ";\n      color: ", ";\n      min-width: 9rem;\n      border-radius: 5px 21px 0 0;\n\n      &.active {\n        background: ", ";\n        color: ", ";\n      }\n      &.disabled {\n        opacity: 0.5;\n      }\n    }\n  }\n  .tab-content {\n    position: relative;\n    z-index: 1;\n  }\n"], ["\n  padding: 1rem 3rem;\n  background: ", ";\n  min-height: 90vh;\n\n  .tabs {\n    position: relative;\n    z-index: 0;\n\n    .tab {\n      font-size: 0.8rem;\n      display: inline-block;\n      padding: 0.3rem 1rem;\n      border: 1px solid ", ";\n      border-bottom: none;\n      background: ", ";\n      color: ", ";\n      min-width: 9rem;\n      border-radius: 5px 21px 0 0;\n\n      &.active {\n        background: ", ";\n        color: ", ";\n      }\n      &.disabled {\n        opacity: 0.5;\n      }\n    }\n  }\n  .tab-content {\n    position: relative;\n    z-index: 1;\n  }\n"])), colors.background.content, colors.background.tag, colors.background.tag, colors.text_pale, colors.background.main_menu, colors.label.black);
var TabContainerMaker = function (_a) {
    var tabData = _a.tabData, children = _a.children, pathname = _a.pathname;
    return (React.createElement(Container, null,
        React.createElement("div", { className: "tabs" }, tabData.map(function (tabDatum, i) {
            var active = tabDatum.url.replace(/\d+/g, '') === pathname.replace(/\d+/g, '');
            return tabDatum.url ? (React.createElement(Link, { "data-testid": "TabContainerMaker-Link", key: tabDatum.name + "-" + i, className: "tab " + (active ? 'active' : ''), to: tabDatum.url }, tabDatum.text)) : (React.createElement("a", { key: tabDatum.name + "-" + i, className: "tab disabled", "data-testid": "TabContainerMaker-Link" }, tabDatum.text));
        })),
        React.createElement("div", { className: "tab-content", "data-testid": "TabContainerMaker-Content" }, children)));
};
export default TabContainerMaker;
var templateObject_1;
