import transform from 'lodash/transform';
/**
 * This method change item on nested object by item key, it will find all item
 * on object by item key and update item value by result of function pass into
 *
 * @params obj - is object contain item that will update
 * @params itemKey - is key of item
 * @params changeFunc - is function call with value of field that key same as
 * itemKey params
 *
 */
var changeItem = function (object, itemKey, changeFunc) {
    if (!object)
        return null;
    return transform(object, function (result, value, key) {
        if (String(key) === itemKey)
            result[key] = changeFunc(value);
        else if (Array.isArray(value))
            result[key] = value.map(function (v) { return changeItem(v, itemKey, changeFunc); });
        else if (typeof value === 'object')
            result[key] = changeItem(value, itemKey, changeFunc);
        else
            result[key] = value;
    }, {});
};
export default changeItem;
