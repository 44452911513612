var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Component, createRef } from 'react';
import styled from 'styled-components';
import { colors } from '@src/colors';
var WebchatInputContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: ", ";\n  border-radius: 6px;\n  text-align: center;\n  width: 100%;\n  min-width: 246px;\n  margin: 16px 16px 16px 8px;\n  bottom: 10px;\n  background-color: ", ";\n  box-shadow: 0 0 2px\n    ", ";\n  form:focus-within {\n    border-radius: 6px;\n    box-shadow: 0 0 4px\n      ", ";\n  }\n  .input-section {\n    font-size: 0.85rem;\n    line-height: 1.5;\n    background: none;\n    padding: 4px 4px;\n    width: 85%;\n    border: none;\n    ::placeholder {\n      color: ", ";\n    }\n  }\n"], ["\n  display: ", ";\n  border-radius: 6px;\n  text-align: center;\n  width: 100%;\n  min-width: 246px;\n  margin: 16px 16px 16px 8px;\n  bottom: 10px;\n  background-color: ", ";\n  box-shadow: 0 0 2px\n    ", ";\n  form:focus-within {\n    border-radius: 6px;\n    box-shadow: 0 0 4px\n      ", ";\n  }\n  .input-section {\n    font-size: 0.85rem;\n    line-height: 1.5;\n    background: none;\n    padding: 4px 4px;\n    width: 85%;\n    border: none;\n    ::placeholder {\n      color: ", ";\n    }\n  }\n"])), function (props) { return (props.webchatOpened ? 'block' : 'none'); }, colors.webchat.background, function (props) { return (props.isValid ? colors.text_pale : colors.error); }, function (props) { return (props.isValid ? colors.text_pale : colors.error); }, function (props) { return (props.isValid ? 'none' : colors.error); });
var Button = styled.input(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin-left: 4px;\n  z-index: 999999;\n  cursor: pointer;\n  background: ", ";\n  color: ", ";\n  width: 1.5rem;\n  height: 1.5rem;\n  border-radius: 16px;\n  right: 0;\n"], ["\n  margin-left: 4px;\n  z-index: 999999;\n  cursor: pointer;\n  background: ", ";\n  color: ", ";\n  width: 1.5rem;\n  height: 1.5rem;\n  border-radius: 16px;\n  right: 0;\n"])), function (_a) {
    var webchatApp = _a.webchatApp;
    return webchatApp.header_background_color;
}, colors.webchat.background);
var WebchatTalkTrigger = /** @class */ (function (_super) {
    __extends(WebchatTalkTrigger, _super);
    function WebchatTalkTrigger() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            value: '',
            validMessage: '項目入力が必須です。',
            placeholder: 'ご入力ください。',
            isValid: true,
        };
        _this.talkTriggerInput = createRef();
        _this.handleSubmit = function (e) {
            var _a = _this.state, value = _a.value, validMessage = _a.validMessage;
            var _b = _this.props, onHandleAnswer = _b.onHandleAnswer, messageId = _b.messageId, talkTrigger = _b.talkTrigger;
            e.preventDefault();
            var isValid = value.trim();
            if (!isValid) {
                _this.talkTriggerInput.current.focus();
                _this.setState({
                    placeholder: validMessage,
                    isValid: isValid,
                    value: '',
                });
            }
            else {
                onHandleAnswer({
                    answer: value,
                    blockId: talkTrigger.postback_action.block_id,
                    messageId: messageId,
                    quick_reply_choice_id: null,
                    talk_trigger_id: talkTrigger.id,
                });
            }
        };
        _this.handleChange = function (e) { return _this.setState({ value: e.target.value, isValid: true }); };
        return _this;
    }
    WebchatTalkTrigger.prototype.componentDidMount = function () {
        this.talkTriggerInput.current.focus();
    };
    WebchatTalkTrigger.prototype.render = function () {
        var _a = this.props, webchatOpened = _a.webchatOpened, webchatApp = _a.webchatApp;
        var _b = this.state, isValid = _b.isValid, placeholder = _b.placeholder, value = _b.value;
        var arrowUpIcon = '⬆';
        return (React.createElement(WebchatInputContainer, { webchatOpened: webchatOpened, isValid: isValid, webchatApp: webchatApp },
            React.createElement("form", { onSubmit: this.handleSubmit },
                React.createElement("input", { ref: this.talkTriggerInput, className: "input-section", value: value, onChange: this.handleChange, placeholder: placeholder }),
                React.createElement(Button, { type: "submit", value: arrowUpIcon, webchatApp: webchatApp }))));
    };
    return WebchatTalkTrigger;
}(Component));
export default WebchatTalkTrigger;
var templateObject_1, templateObject_2;
