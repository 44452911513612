import flattenDeep from 'lodash/flattenDeep';
function messagesFromBlock(currentBlock, messageSections, messages) {
    if (!currentBlock.message_sections)
        return [];
    var activeMessageSections = currentBlock.message_sections.map(function (msId) { return messageSections[msId]; });
    return flattenDeep(activeMessageSections.map(function (section) {
        return section.messages.map(function (mId) { return messages[mId]; });
    }));
}
export default messagesFromBlock;
