var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import Select from 'react-select';
import { colors } from '@src/colors';
import modalTypes from '@src/lib/modalTypes';
import { ModalPortal } from '@src/components/atoms';
import { Modal } from '@src/components/molecules';
var ContentContainer = styled.span(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  text-align: left;\n  .selector {\n    width: 100%;\n    font-size: 0.8rem;\n    margin-top: 12px;\n  }\n  .template-name {\n    display: block;\n    width: 100%;\n    height: 40px;\n    padding: 0 1rem;\n    font-size: 12px;\n    border: 1px solid ", ";\n    border-radius: 5px;\n    margin-top: 12px;\n  }\n  .valid {\n    border-color: ", ";\n  }\n"], ["\n  text-align: left;\n  .selector {\n    width: 100%;\n    font-size: 0.8rem;\n    margin-top: 12px;\n  }\n  .template-name {\n    display: block;\n    width: 100%;\n    height: 40px;\n    padding: 0 1rem;\n    font-size: 12px;\n    border: 1px solid ", ";\n    border-radius: 5px;\n    margin-top: 12px;\n  }\n  .valid {\n    border-color: ", ";\n  }\n"])), colors.border.default, colors.border.error);
var SelectTemplateScenario = function (_a) {
    var scenarioTemplates = _a.scenarioTemplates, selectValue = _a.selectValue, value = _a.value, className = _a.className, 
    // funcs
    onConfirm = _a.onConfirm, onCancel = _a.onCancel, onSelectedChange = _a.onSelectedChange, onChange = _a.onChange;
    return (React.createElement(ModalPortal, null,
        React.createElement(Modal, { "data-testid": "select-template-scenario-modal", text: "\u30C6\u30F3\u30D7\u30EC\u30FC\u30C8\u304B\u3089\u30B7\u30CA\u30EA\u30AA\u3092\u4F5C\u6210", type: modalTypes.CREATE, onConfirm: onConfirm, onCancel: onCancel },
            React.createElement(ContentContainer, null,
                React.createElement("div", { className: "body" },
                    React.createElement("div", { className: "message-wrapper primary-label" }, "\u30C6\u30F3\u30D7\u30EC\u9078\u629E"),
                    React.createElement(Select, { selectValue: selectValue, onChange: onSelectedChange, options: (scenarioTemplates || []).map(function (obj) { return ({
                            value: obj.id,
                            label: obj.title,
                        }); }), placeholder: "\u30C6\u30F3\u30D7\u30EC\u304C\u9078\u629E\u3055\u308C\u3066\u3044\u307E\u305B\u3093", className: "selector" }),
                    React.createElement("div", { className: "message-wrapper primary-label" }, "\u30B7\u30CA\u30EA\u30AA\u540D"),
                    React.createElement("input", { type: "text", placeholder: "\u5FC5\u9808", onChange: onChange, value: value, className: className, "data-testid": "SelectTemplateScenario_input" }))))));
};
export default SelectTemplateScenario;
var templateObject_1;
