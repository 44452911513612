// Responsible for converting FormService structures to/from our Form Redux structures
import { denormalize, normalize, schema } from 'normalizr';
// An empty result for Form serialization from server
var emptyFormResult = {
    forms: {},
    formElements: {},
};
// An empty result for FormTemplate serialization from server
var emptyFormTemplateResult = {
    formTemplates: {},
    formElements: {},
};
// An empty result for FormTemplateElement response serialization from server
var emptyFormTemplateElementResult = {
    formElementTemplates: {},
    formElements: {},
};
// // Schemas for FormService
var formElementSchema = new schema.Entity('formElements');
var pageSchema = new schema.Object({
    elementsList: new schema.Array(formElementSchema),
});
var formSchema = new schema.Entity('forms', {
    pagesList: [pageSchema],
});
var formListSchema = new schema.Array(formSchema);
var formTemplateSchema = new schema.Entity('formTemplates', {
    pagesList: [pageSchema],
});
var formTemplateListSchema = new schema.Array(formTemplateSchema);
var formElementTemplateSchema = new schema.Entity('formElementTemplates', {
    formElement: formElementSchema,
});
var formElementTemplateListSchema = new schema.Array(formElementTemplateSchema);
export default {
    normalize: {
        form: function (form) {
            var normalizedData = normalize(form, formSchema);
            return normalizedData.entities;
        },
        forms: function (forms) {
            if (forms.length === 0) {
                return emptyFormResult;
            }
            var normalizedData = normalize(forms, formListSchema);
            return normalizedData.entities;
        },
        formTemplate: function (formTemplate) {
            var normalizedData = normalize(formTemplate, formTemplateSchema);
            return normalizedData.entities;
        },
        formTemplates: function (formTemplates) {
            var normalizedData = normalize(formTemplates, formTemplateListSchema);
            if (formTemplates.length === 0) {
                return emptyFormTemplateResult;
            }
            return normalizedData.entities;
        },
        formElementTemplate: function (formElementTemplate) {
            var normalizedData = normalize(formElementTemplate, formElementTemplateSchema);
            return normalizedData.entities;
        },
        formElementTemplates: function (formElementTemplates) {
            if (formElementTemplates.length === 0) {
                return emptyFormTemplateElementResult;
            }
            var normalizedData = normalize(formElementTemplates, formElementTemplateListSchema);
            return normalizedData.entities;
        },
    },
    denormalize: {
        form: function (form, formElements) {
            var entities = {
                formElements: formElements,
            };
            return denormalize(form, formSchema, entities);
        },
        formTemplate: function (formTemplate, formElements) {
            var entities = {
                formElements: formElements,
            };
            return denormalize(formTemplate, formTemplateSchema, entities);
        },
        formElementTemplate: function (formElementTemplate, formElements) {
            var entities = {
                formElements: formElements,
            };
            return denormalize(formElementTemplate, formElementTemplateSchema, entities);
        },
    },
};
