var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import { Button } from '@src/components/atoms';
import buttonTypes from '@src/lib/buttonTypes';
var EmptySplashContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  .splash-inner {\n    text-align: center;\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    -moz-transform: translate(-50%, -50%);\n    -ms-transform: translate(-50%, -50%);\n    -webkit-transform: translate(-50%, -50%);\n    transform: translate(-50%, -50%);\n\n    p {\n      margin-bottom: 3rem;\n      font-size: 1rem;\n    }\n\n    img {\n      width: 313px;\n      margin: 3rem auto 1.5rem;\n      display: block;\n    }\n  }\n"], ["\n  .splash-inner {\n    text-align: center;\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    -moz-transform: translate(-50%, -50%);\n    -ms-transform: translate(-50%, -50%);\n    -webkit-transform: translate(-50%, -50%);\n    transform: translate(-50%, -50%);\n\n    p {\n      margin-bottom: 3rem;\n      font-size: 1rem;\n    }\n\n    img {\n      width: 313px;\n      margin: 3rem auto 1.5rem;\n      display: block;\n    }\n  }\n"])));
var EmptySplash = function (_a) {
    var text = _a.text, image = _a.image, buttonText = _a.buttonText, modal = _a.modal, onClick = _a.onClick, isModalOpened = _a.isModalOpened;
    return (React.createElement(EmptySplashContainer, null,
        isModalOpened && modal,
        React.createElement("div", { className: "splash-inner" },
            React.createElement("p", { className: "empty-splash-text" }, text),
            React.createElement("img", { className: "empty-splash-image", src: image, alt: "empty splash" }),
            React.createElement(Button, { "data-testid": "create-first-scenario-button", className: "button_submit", onClick: onClick, text: buttonText, buttonType: buttonTypes.SETTING }))));
};
export default EmptySplash;
var templateObject_1;
