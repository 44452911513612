import "core-js/modules/es.symbol";
import "core-js/modules/es.array.concat";
import "core-js/modules/es.array.filter";
import "core-js/modules/es.array.map";
import "core-js/modules/es.function.name";
import "core-js/modules/es.object.get-own-property-descriptor";
import "core-js/modules/es.object.get-own-property-descriptors";
import "core-js/modules/es.object.keys";
import "core-js/modules/web.dom-collections.for-each";
import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

export var addUserTags = function addUserTags(action, state, reduxStateVar) {
  return _objectSpread({}, state, {
    users: state.users.map(function (user) {
      if (user.id === action.userId) {
        return _objectSpread({}, user, _defineProperty({}, reduxStateVar, [].concat(_toConsumableArray(user[reduxStateVar]), [action.tag])));
      }

      return user;
    })
  });
};
export var updateUserTags = function updateUserTags(action, state, reduxStateVar, userKeyVar) {
  return _objectSpread({}, state, {
    users: state.users.map(function (user) {
      if (user.id === action.userId) {
        return _objectSpread({}, user, _defineProperty({}, reduxStateVar, user[reduxStateVar].map(function (tag) {
          if (tag.name === action.tag.name) {
            return action[userKeyVar];
          }

          return tag;
        })));
      }

      return user;
    })
  });
};
export var removeUserTags = function removeUserTags(action, state, reducStateVar, tagIdVar) {
  return _objectSpread({}, state, {
    users: state.users.map(function (user) {
      if (user.id === action.userId) {
        // if user wants to remove a tag with an id or without an id
        return _objectSpread({}, user, _defineProperty({}, reducStateVar, user[reducStateVar].filter(function (t) {
          return t.id !== action[tagIdVar] && t.id !== null;
        })));
      }

      return user;
    })
  });
};