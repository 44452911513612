var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { colors } from '@src/colors';
import { injectIntl } from 'react-intl';
import * as pathLinks from '@src/routes/Fanp/pathLinks';
import { handleAPIErrors } from '@src/lib/helpers';
import { useSelector, useDispatch } from 'react-redux';
import AutosizeInput from 'react-input-autosize';
import { useLocation, useParams, useHistory } from 'react-router-dom';
import * as formsGrpc from '@src/services/zeals_grpc/forms';
import { Link, Typography } from '@mui/material';
import { Button, Breadcrumbs, Icon, Dialog, Snackbar, MUICircularProgress, } from '@zeals-co-ltd/washi-components';
import { blue } from '@zeals-co-ltd/washi-styles';
import { createStyles, makeStyles } from '@mui/styles';
import { setFormBuildAction } from '@src/redux/fanp/actions/form';
var useBreadcrumbStyles = makeStyles(function () {
    return createStyles({
        root: {
            '& li': {
                lineHeight: 1,
            },
        },
    });
});
var useSnackbarStyles = makeStyles(function () {
    return createStyles({
        root: {
            top: '90px',
        },
    });
});
var HeaderRow = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  justify-content: space-between;\n  width: 100%;\n  padding: ", ";\n  height: ", ";\n"], ["\n  display: flex;\n  justify-content: space-between;\n  width: 100%;\n  padding: ", ";\n  height: ", ";\n"])), function (props) { return (props.$isFormBuilderPage ? '16px 0' : '16px 0 0 0'); }, function (props) { return (props.$isFormBuilderPage ? '64px' : '99px'); });
var HeaderMain = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  height: 36px;\n"], ["\n  display: flex;\n  height: 36px;\n"])));
var FormBuildHeaderContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  padding: 0 0 0 24px;\n  justify-content: center;\n  height: fit-content;\n"], ["\n  display: flex;\n  flex-direction: column;\n  padding: 0 0 0 24px;\n  justify-content: center;\n  height: fit-content;\n"])));
var FormBuildNameContainer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  width: fit-content;\n  height: 36px;\n  font-size: 24px;\n  font-weight: 700;\n  letter-spacing: -0.02;\n  color: ", ";\n  .form-build-name {\n    padding: 2px 8px;\n  }\n"], ["\n  display: flex;\n  align-items: center;\n  width: fit-content;\n  height: 36px;\n  font-size: 24px;\n  font-weight: 700;\n  letter-spacing: -0.02;\n  color: ", ";\n  .form-build-name {\n    padding: 2px 8px;\n  }\n"])), blue[500]);
var FormBuildNameInput = styled(AutosizeInput)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  position: relative;\n  height: auto;\n  z-index: 2;\n  display: flex !important;\n  border-radius: 4px;\n  transition: border 0.1s ease-in;\n  border: 1px solid transparent;\n  padding: 2px 8px;\n  border-radius: 4px;\n  ", "\n"], ["\n  position: relative;\n  height: auto;\n  z-index: 2;\n  display: flex !important;\n  border-radius: 4px;\n  transition: border 0.1s ease-in;\n  border: 1px solid transparent;\n  padding: 2px 8px;\n  border-radius: 4px;\n  ", "\n"])), function (props) { return props.$isEditable && "border: 1px solid  " + blue[500] + ";"; });
var FormSettingsTitle = styled.h1(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  font-size: 24px;\n  line-height: 1.45;\n  font-weight: 700;\n  letter-spacing: -0.02;\n  color: ", ";\n  margin: 0;\n"], ["\n  font-size: 24px;\n  line-height: 1.45;\n  font-weight: 700;\n  letter-spacing: -0.02;\n  color: ", ";\n  margin: 0;\n"])), blue[500]);
var FormBuildEditButton = styled.button(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  border: none;\n  background: none;\n  display: flex;\n  align-items: center;\n  margin-left: 4px;\n\n  .adicon_edit_after {\n    color: ", ";\n    font-size: 24px;\n    transition: all 0.1s ease-in;\n  }\n\n  .adicon_edit_after:hover {\n    color: ", ";\n  }\n"], ["\n  border: none;\n  background: none;\n  display: flex;\n  align-items: center;\n  margin-left: 4px;\n\n  .adicon_edit_after {\n    color: ", ";\n    font-size: 24px;\n    transition: all 0.1s ease-in;\n  }\n\n  .adicon_edit_after:hover {\n    color: ", ";\n  }\n"])), colors.rpa_settings.young_navy, colors.old_navy);
var FormEditContainer = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  position: fixed;\n  z-index: 1;\n  inset: 0;\n  min-height: 100vh;\n"], ["\n  position: fixed;\n  z-index: 1;\n  inset: 0;\n  min-height: 100vh;\n"])));
var ReturnToFormBuilderModal = function (_a) {
    var onConfirm = _a.onConfirm, onClose = _a.onClose, title = _a.title, cancelLabel = _a.cancelLabel, confirmLabel = _a.confirmLabel, content = _a.content;
    return (React.createElement(Dialog, { dialogType: "secondary", open: true, mainButtonOnClick: onConfirm, onClose: onClose, hasIcon: true, title: title, cancelButtonLabel: cancelLabel, mainButtonLabel: confirmLabel, supportText: content }));
};
var FormBuildNavigationHeader = function (_a) {
    var intl = _a.intl, formBuildId = _a.formBuildId, breadcrumbItems = _a.breadcrumbItems, isFormBuilderPage = _a.isFormBuilderPage, _b = _a.hideEditableTitle, hideEditableTitle = _b === void 0 ? false : _b, hasMadeChanges = _a.hasMadeChanges;
    var classes = useBreadcrumbStyles();
    var snackbarStyles = useSnackbarStyles();
    var chatbotId = useParams().chatbotId;
    var location = useLocation();
    var browserHistory = useHistory();
    var dispatch = useDispatch();
    var formBuildNameInput = useRef(null);
    var _c = __read(useState(''), 2), formBuildName = _c[0], setFormBuildName = _c[1];
    var _d = __read(useState(false), 2), isEditable = _d[0], setIsEditable = _d[1];
    var _e = __read(useState(false), 2), isModalOpen = _e[0], setIsModalOpen = _e[1];
    var _f = __read(useState(false), 2), displayErrorSnackbar = _f[0], setDisplayErrorSnackbar = _f[1];
    var currentUser = useSelector(function (state) { return state.account.current_user; });
    var formBuilds = useSelector(function (state) { return state.form.present.formBuilds; });
    var _g = __read(useState(false), 2), isFetching = _g[0], setIsFetching = _g[1];
    var _h = __read(useState(''), 2), href = _h[0], setHref = _h[1];
    var errorSnackbarProps = {
        severity: 'error',
    };
    var targetFormBuild = (formBuilds === null || formBuilds === void 0 ? void 0 : formBuilds.find(function (formBuild) { return formBuild.id === formBuildId; })) || undefined;
    var fetchFormBuild = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var fetchedFormBuild, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, 3, 4]);
                    setIsFetching(true);
                    return [4 /*yield*/, formsGrpc.getFormBuild(formBuildId)];
                case 1:
                    fetchedFormBuild = _a.sent();
                    dispatch(setFormBuildAction(fetchedFormBuild));
                    setFormBuildName(fetchedFormBuild === null || fetchedFormBuild === void 0 ? void 0 : fetchedFormBuild.name);
                    return [3 /*break*/, 4];
                case 2:
                    e_1 = _a.sent();
                    handleAPIErrors(e_1.message);
                    return [3 /*break*/, 4];
                case 3:
                    setIsFetching(false);
                    return [7 /*endfinally*/];
                case 4: return [2 /*return*/];
            }
        });
    }); }, [dispatch, formBuildId]);
    useEffect(function () {
        if (!targetFormBuild) {
            fetchFormBuild();
        }
    }, [fetchFormBuild, formBuildId, targetFormBuild]);
    useEffect(function () {
        setFormBuildName(targetFormBuild === null || targetFormBuild === void 0 ? void 0 : targetFormBuild.name);
    }, [targetFormBuild === null || targetFormBuild === void 0 ? void 0 : targetFormBuild.name]);
    useEffect(function () {
        var _a;
        if (isEditable && formBuildNameInput.current !== document.activeElement) {
            (_a = formBuildNameInput === null || formBuildNameInput === void 0 ? void 0 : formBuildNameInput.current) === null || _a === void 0 ? void 0 : _a.focus();
        }
    }, [isEditable]);
    var handleEditClick = function (newName) { return __awaiter(void 0, void 0, void 0, function () {
        var newUpdatedFormBuild, e_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 3, 4, 5]);
                    if (!newName) return [3 /*break*/, 2];
                    return [4 /*yield*/, formsGrpc.updateFormBuild(targetFormBuild.id, targetFormBuild.segmentsList, newName, currentUser === null || currentUser === void 0 ? void 0 : currentUser.name, currentUser === null || currentUser === void 0 ? void 0 : currentUser.email)];
                case 1:
                    newUpdatedFormBuild = _a.sent();
                    dispatch(setFormBuildAction(newUpdatedFormBuild));
                    _a.label = 2;
                case 2: return [3 /*break*/, 5];
                case 3:
                    e_2 = _a.sent();
                    setFormBuildName(targetFormBuild.name);
                    setDisplayErrorSnackbar(true);
                    console.error(e_2.message);
                    return [3 /*break*/, 5];
                case 4:
                    setIsEditable(!isEditable);
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    // Get Segment Title based on route name
    var getSegmentTitle = function () {
        var path = location.pathname;
        if (path.includes('webform')) {
            return intl.formatMessage({ id: "HCuh5z", defaultMessage: "Web\u30D5\u30A9\u30FC\u30E0\u8A2D\u5B9A" });
        }
        if (path.includes('form-conversation')) {
            return intl.formatMessage({ id: "zs51u/", defaultMessage: "\u30D5\u30A9\u30FC\u30E0\u4F1A\u8A71\u8A2D\u5B9A" });
        }
        if (path.includes('form-automation')) {
            return intl.formatMessage({ id: "OoI62V", defaultMessage: "Form Automation\u8A2D\u5B9A" });
        }
        return '';
    };
    var handleReturnToFormBuilderFromWarningModal = function () {
        if (href) {
            return (window.location.href = href);
        }
        if (isFormBuilderPage) {
            return (window.location.href = pathLinks.dashboardAdChatbots_chatbotId_RpaSettings({ chatbotId: chatbotId }));
        }
        return (window.location.href = pathLinks.dashboardAdChatbots_chatbotId_FormBuilder_formBuildId_FormSettings({
            chatbotId: chatbotId,
            formBuildId: formBuildId,
        }));
    };
    var handleReturnToFormBuilder = function () {
        if (href) {
            return browserHistory.push(href);
        }
        if (isFormBuilderPage) {
            return browserHistory.push(pathLinks.dashboardAdChatbots_chatbotId_RpaSettings({ chatbotId: chatbotId }));
        }
        return browserHistory.push(pathLinks.dashboardAdChatbots_chatbotId_FormBuilder_formBuildId_FormSettings({
            chatbotId: chatbotId,
            formBuildId: formBuildId,
        }));
    };
    var handleClickBackButton = function () {
        if (hasMadeChanges) {
            setIsModalOpen(true);
        }
        else {
            handleReturnToFormBuilder();
        }
    };
    var handleBreadcrumbClick = function (e, href) {
        e.preventDefault();
        if (href) {
            setHref(href);
        }
        if (hasMadeChanges) {
            setIsModalOpen(true);
        }
        else {
            browserHistory.push(href);
        }
    };
    return (React.createElement(React.Fragment, null,
        isModalOpen && (React.createElement(ReturnToFormBuilderModal, { onConfirm: function () { return handleReturnToFormBuilderFromWarningModal(); }, onClose: function () { return setIsModalOpen(false); }, title: intl.formatMessage({ id: "GAYbdw", defaultMessage: "\u8A2D\u5B9A\u304C\u4FDD\u5B58\u3055\u308C\u3066\u3044\u307E\u305B\u3093\uFF01" }), cancelLabel: intl.formatMessage({ id: "uxzgSu", defaultMessage: "\u3044\u3044\u3048\u3001\u8A2D\u5B9A\u3092\u7D9A\u3051\u308B" }), confirmLabel: intl.formatMessage({ id: "PMuvxE", defaultMessage: "\u306F\u3044\u3001\u30D5\u30A9\u30FC\u30E0\u8A2D\u5B9A\u306B\u623B\u308B" }), content: intl.formatMessage({ id: "iEBbaT", defaultMessage: "\u8A2D\u5B9A\u3057\u305F\u5185\u5BB9\u306F\u3059\u3079\u3066\u5931\u308F\u308C\u3001RPA\u30D3\u30EB\u30C0\u30FC\u306E\u300C\u30D5\u30A9\u30FC\u30E0\u8A2D\u5B9A\u300D\u306B\u623B\u308A\u307E\u3059\u3002\u3088\u308D\u3057\u3044\u3067\u3057\u3087\u3046\u304B\uFF1F" }) })),
        React.createElement(HeaderRow, { "$isFormBuilderPage": isFormBuilderPage, "data-testid": "form-builder-header" },
            React.createElement(HeaderMain, null,
                React.createElement(Button, { onClick: function () { return handleClickBackButton(); }, "data-testid": "return-button", variant: "text", size: "small", startIcon: React.createElement(Icon, { name: "adicon_left_chev", color: "primary" }) }, intl.formatMessage({ id: "cyR7Kh", defaultMessage: "Back" })),
                isFetching ? (React.createElement(MUICircularProgress, null)) : (React.createElement(FormBuildHeaderContainer, null,
                    React.createElement(FormBuildNameContainer, null, !hideEditableTitle ? (React.createElement(React.Fragment, null,
                        isEditable ? (React.createElement(FormBuildNameInput, { "$isEditable": isEditable, "data-testid": "form-build-name-input", value: formBuildName || '', onChange: function (e) { return setFormBuildName(e.target.value); }, ref: function (input) {
                                formBuildNameInput.current = input;
                            }, onKeyDown: function (e) {
                                return e.code === 'Enter' && handleEditClick(formBuildName);
                            } })) : (React.createElement("span", { className: "form-build-name", "data-testid": "form-build-name" }, formBuildName)),
                        React.createElement(FormBuildEditButton, { "data-testid": "edit-button", onClick: function () { return handleEditClick(); } },
                            React.createElement("span", { className: "adicon_edit_after" })),
                        isEditable && (React.createElement(FormEditContainer, { onClick: function () { return handleEditClick(formBuildName); } })))) : (React.createElement(FormSettingsTitle, null, getSegmentTitle()))),
                    (breadcrumbItems === null || breadcrumbItems === void 0 ? void 0 : breadcrumbItems.length) && (React.createElement(Breadcrumbs, { className: classes.root }, breadcrumbItems.map(function (_a) {
                        var href = _a.href, text = _a.text;
                        if (href) {
                            return (React.createElement(Link, { className: "link-primary", href: href, onClick: function (e) { return handleBreadcrumbClick(e, href); }, key: href }, text));
                        }
                        return (React.createElement(Typography, { className: "active-link-primary", key: text }, text));
                    }))))))),
        React.createElement(Snackbar, { className: snackbarStyles.root, onClose: function () { return setDisplayErrorSnackbar(false); }, open: displayErrorSnackbar, alertProps: errorSnackbarProps, anchorOrigin: { vertical: 'top', horizontal: 'center' }, autoHideDuration: 3000 },
            React.createElement(React.Fragment, null, intl.formatMessage({ id: "u3SyPT", defaultMessage: "RPA\u8A2D\u5B9A\u540D\u304C\u5909\u66F4\u3067\u304D\u307E\u305B\u3093\u3067\u3057\u305F\u3002" })))));
};
export default injectIntl(FormBuildNavigationHeader);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8;
