import { defineMessage } from 'react-intl';
var group1000x131 = {
    width: 1000,
    height: 131,
    templates: {
        15: {
            sort: 1,
            areas: [{ x: 0, y: 0, width: 1000, height: 131, color: 0 }],
        },
        16: {
            sort: 2,
            areas: [
                { x: 0, y: 0, width: 500, height: 131, color: 0 },
                { x: 500, y: 0, width: 500, height: 131, color: 1 },
            ],
        },
        17: {
            sort: 3,
            areas: [
                { x: 0, y: 0, width: 333, height: 131, color: 0 },
                { x: 333, y: 0, width: 334, height: 131, color: 1 },
                { x: 667, y: 0, width: 333, height: 131, color: 2 },
            ],
        },
        18: {
            sort: 4,
            areas: [
                { x: 0, y: 0, width: 250, height: 131, color: 0 },
                { x: 250, y: 0, width: 250, height: 131, color: 1 },
                { x: 500, y: 0, width: 250, height: 131, color: 2 },
                { x: 750, y: 0, width: 250, height: 131, color: 3 },
            ],
        },
        19: {
            sort: 5,
            areas: [
                { x: 0, y: 0, width: 200, height: 131, color: 0 },
                { x: 200, y: 0, width: 200, height: 131, color: 1 },
                { x: 400, y: 0, width: 200, height: 131, color: 2 },
                { x: 600, y: 0, width: 200, height: 131, color: 3 },
                { x: 800, y: 0, width: 200, height: 131, color: 4 },
            ],
        },
    },
};
var group1040x1040 = {
    width: 1040,
    height: 1040,
    templates: {
        1: {
            sort: 1,
            areas: [{ x: 0, y: 0, width: 1040, height: 1040, color: 0 }],
        },
        14: {
            sort: 2,
            areas: [{ x: 0, y: 0, width: 1040, height: 1040, color: 0 }],
        },
        35: {
            sort: 3,
            areas: [
                { x: 0, y: 0, width: 520, height: 1040, color: 0 },
                { x: 520, y: 0, width: 520, height: 1040, color: 2 },
            ],
        },
        36: {
            sort: 4,
            areas: [
                { x: 0, y: 0, width: 1040, height: 520, color: 0 },
                { x: 0, y: 520, width: 1040, height: 520, color: 2 },
            ],
        },
        11: {
            sort: 8,
            areas: [
                { x: 0, y: 0, width: 1040, height: 634, color: 0 },
                { x: 0, y: 634, width: 1040, height: 406, color: 1 },
            ],
        },
        2: {
            sort: 9,
            areas: [
                { x: 0, y: 0, width: 520, height: 520, color: 0 },
                { x: 520, y: 0, width: 520, height: 520, color: 1 },
                { x: 0, y: 520, width: 520, height: 520, color: 2 },
                { x: 520, y: 520, width: 520, height: 520, color: 3 },
            ],
        },
        10: {
            sort: 10,
            areas: [
                { x: 0, y: 0, width: 520, height: 438, color: 0 },
                { x: 520, y: 0, width: 520, height: 438, color: 1 },
                { x: 0, y: 438, width: 520, height: 301, color: 2 },
                { x: 520, y: 438, width: 520, height: 301, color: 3 },
                { x: 0, y: 739, width: 520, height: 301, color: 4 },
                { x: 520, y: 739, width: 520, height: 301, color: 5 },
            ],
        },
        12: {
            sort: 11,
            areas: [
                { x: 0, y: 0, width: 520, height: 347, color: 0 },
                { x: 520, y: 0, width: 520, height: 347, color: 1 },
                { x: 0, y: 347, width: 520, height: 347, color: 2 },
                { x: 520, y: 347, width: 520, height: 347, color: 3 },
                { x: 0, y: 694, width: 520, height: 346, color: 4 },
                { x: 520, y: 694, width: 520, height: 346, color: 5 },
            ],
        },
        3: {
            sort: 12,
            areas: [
                { x: 0, y: 0, width: 347, height: 520, color: 0 },
                { x: 347, y: 0, width: 347, height: 520, color: 1 },
                { x: 694, y: 0, width: 346, height: 520, color: 2 },
                { x: 0, y: 520, width: 347, height: 520, color: 3 },
                { x: 347, y: 520, width: 347, height: 520, color: 4 },
                { x: 694, y: 520, width: 346, height: 520, color: 5 },
            ],
        },
    },
};
var group1040x1160 = {
    width: 1040,
    height: 1160,
    templates: {
        5: {
            sort: 13,
            areas: [{ x: 0, y: 0, width: 1040, height: 1160, color: 0 }],
        },
    },
};
var group1040x1200 = {
    width: 1040,
    height: 1200,
    templates: {
        6: {
            sort: 14,
            areas: [{ x: 0, y: 0, width: 1040, height: 1200, color: 0 }],
        },
    },
};
var group1040x1250 = {
    width: 1040,
    height: 1250,
    templates: {
        7: {
            sort: 15,
            areas: [{ x: 0, y: 0, width: 1040, height: 1250, color: 0 }],
        },
    },
};
var group1040x1300 = {
    width: 1040,
    height: 1300,
    templates: {
        8: {
            sort: 16,
            areas: [{ x: 0, y: 0, width: 1040, height: 1300, color: 0 }],
        },
    },
};
var group1040x1430 = {
    width: 1040,
    height: 1430,
    templates: {
        9: {
            sort: 17,
            areas: [{ x: 0, y: 0, width: 1040, height: 1430, color: 0 }],
        },
    },
};
var group1040x1500 = {
    width: 1040,
    height: 1500,
    templates: {
        13: {
            sort: 18,
            areas: [{ x: 0, y: 0, width: 1040, height: 1500, color: 0 }],
        },
    },
};
var group1040x2000 = {
    width: 1040,
    height: 2000,
    templates: {
        4: {
            sort: 19,
            areas: [
                { x: 0, y: 0, width: 1040, height: 1424, color: 0 },
                { x: 0, y: 1424, width: 1040, height: 192, color: 1 },
                { x: 0, y: 1616, width: 1040, height: 192, color: 2 },
                { x: 0, y: 1808, width: 1040, height: 192, color: 3 },
            ],
        },
    },
};
var group1040x689 = {
    width: 1040,
    height: 689,
    templates: {
        21: {
            sort: 21,
            areas: [{ x: 0, y: 0, width: 1040, height: 689, color: 0 }],
        },
        22: {
            sort: 22,
            areas: [
                { x: 0, y: 0, width: 520, height: 344, color: 0 },
                { x: 520, y: 0, width: 520, height: 344, color: 1 },
                { x: 0, y: 344, width: 520, height: 345, color: 2 },
                { x: 520, y: 344, width: 520, height: 345, color: 3 },
            ],
        },
        23: {
            sort: 23,
            areas: [
                { x: 0, y: 0, width: 347, height: 344, color: 0 },
                { x: 347, y: 0, width: 347, height: 344, color: 1 },
                { x: 694, y: 0, width: 346, height: 344, color: 2 },
                { x: 0, y: 344, width: 347, height: 345, color: 3 },
                { x: 347, y: 344, width: 347, height: 345, color: 4 },
                { x: 694, y: 344, width: 346, height: 345, color: 5 },
            ],
        },
        24: {
            sort: 24,
            areas: [
                { x: 0, y: 0, width: 520, height: 172, color: 0 },
                { x: 520, y: 0, width: 520, height: 172, color: 1 },
                { x: 0, y: 172, width: 520, height: 172, color: 2 },
                { x: 520, y: 172, width: 520, height: 172, color: 3 },
                { x: 0, y: 344, width: 520, height: 172, color: 4 },
                { x: 520, y: 344, width: 520, height: 172, color: 5 },
                { x: 0, y: 516, width: 520, height: 173, color: 6 },
                { x: 520, y: 516, width: 520, height: 173, color: 7 },
            ],
        },
    },
};
var group1040x545 = {
    width: 1040,
    height: 545,
    templates: {
        25: {
            sort: 25,
            areas: [{ x: 0, y: 0, width: 1040, height: 545, color: 0 }],
        },
        26: {
            sort: 26,
            areas: [
                { x: 0, y: 0, width: 520, height: 272, color: 0 },
                { x: 520, y: 0, width: 520, height: 272, color: 1 },
                { x: 0, y: 272, width: 520, height: 273, color: 2 },
                { x: 520, y: 272, width: 520, height: 273, color: 3 },
            ],
        },
        27: {
            sort: 27,
            areas: [
                { x: 0, y: 0, width: 347, height: 272, color: 0 },
                { x: 347, y: 0, width: 347, height: 272, color: 1 },
                { x: 694, y: 0, width: 346, height: 272, color: 2 },
                { x: 0, y: 272, width: 347, height: 273, color: 3 },
                { x: 347, y: 272, width: 347, height: 273, color: 4 },
                { x: 694, y: 272, width: 346, height: 273, color: 5 },
            ],
        },
        28: {
            sort: 28,
            areas: [
                { x: 0, y: 0, width: 520, height: 136, color: 0 },
                { x: 520, y: 0, width: 520, height: 136, color: 1 },
                { x: 0, y: 136, width: 520, height: 136, color: 2 },
                { x: 520, y: 136, width: 520, height: 136, color: 3 },
                { x: 0, y: 272, width: 520, height: 136, color: 4 },
                { x: 520, y: 272, width: 520, height: 136, color: 5 },
                { x: 0, y: 408, width: 520, height: 137, color: 6 },
                { x: 520, y: 408, width: 520, height: 137, color: 7 },
            ],
        },
    },
};
var group1040x260 = {
    width: 1040,
    height: 260,
    templates: {
        29: {
            sort: 29,
            areas: [{ x: 0, y: 0, width: 1040, height: 260, color: 0 }],
        },
        30: {
            sort: 30,
            areas: [
                { x: 0, y: 0, width: 520, height: 130, color: 0 },
                { x: 520, y: 0, width: 520, height: 130, color: 1 },
                { x: 0, y: 130, width: 520, height: 130, color: 2 },
                { x: 520, y: 130, width: 520, height: 130, color: 3 },
            ],
        },
        31: {
            sort: 31,
            areas: [
                { x: 0, y: 0, width: 347, height: 130, color: 0 },
                { x: 347, y: 0, width: 347, height: 130, color: 1 },
                { x: 694, y: 0, width: 346, height: 130, color: 2 },
                { x: 0, y: 130, width: 520, height: 130, color: 3 },
                { x: 520, y: 130, width: 520, height: 130, color: 4 },
            ],
        },
        32: {
            sort: 32,
            areas: [
                { x: 0, y: 0, width: 347, height: 130, color: 0 },
                { x: 347, y: 0, width: 347, height: 130, color: 1 },
                { x: 694, y: 0, width: 346, height: 130, color: 2 },
                { x: 0, y: 130, width: 347, height: 130, color: 3 },
                { x: 347, y: 130, width: 347, height: 130, color: 4 },
                { x: 694, y: 130, width: 346, height: 130, color: 5 },
            ],
        },
        33: {
            sort: 33,
            areas: [
                { x: 0, y: 0, width: 260, height: 130, color: 0 },
                { x: 260, y: 0, width: 260, height: 130, color: 1 },
                { x: 520, y: 0, width: 260, height: 130, color: 2 },
                { x: 780, y: 0, width: 260, height: 130, color: 3 },
                { x: 0, y: 130, width: 260, height: 130, color: 4 },
                { x: 260, y: 130, width: 260, height: 130, color: 5 },
                { x: 520, y: 130, width: 260, height: 130, color: 6 },
                { x: 780, y: 130, width: 260, height: 130, color: 7 },
            ],
        },
    },
};
var groupMap1040x1040 = {
    title: defineMessage({ id: "zuqMfZ", defaultMessage: "Map" }),
    width: 1040,
    height: 1040,
    templates: {
        34: {
            sort: 34,
            backgroundImageName: 'map_template_1040x1040',
            areas: [
                {
                    x: 38,
                    y: 49,
                    width: 256,
                    height: 99,
                    title: defineMessage({ id: "ROWDlc", defaultMessage: "Okinawa" }),
                    sort: 9,
                    color: 2,
                },
                {
                    x: 192,
                    y: 312,
                    width: 345,
                    height: 99,
                    title: defineMessage({ id: "Si3yJE", defaultMessage: "Hokuriku\u30FBK\u014Dshinetsu" }),
                    sort: 8,
                    color: 2,
                },
                {
                    x: 193,
                    y: 476,
                    width: 253,
                    height: 99,
                    title: defineMessage({ id: "CG/b/T", defaultMessage: "Kinki" }),
                    sort: 7,
                    color: 2,
                },
                {
                    x: 39,
                    y: 618,
                    width: 284,
                    height: 99,
                    title: defineMessage({ id: "mcfZV0", defaultMessage: "Ch\u016Bgoku\u30FBShikoku" }),
                    sort: 6,
                    color: 2,
                },
                {
                    x: 723,
                    y: 49,
                    width: 254,
                    height: 99,
                    title: defineMessage({ id: "EuLVxS", defaultMessage: "Hokkaid\u014D" }),
                    sort: 1,
                    color: 2,
                },
                {
                    x: 723,
                    y: 415,
                    width: 254,
                    height: 99,
                    title: defineMessage({ id: "i9LyT0", defaultMessage: "T\u014Dhoku" }),
                    sort: 2,
                    color: 2,
                },
                {
                    x: 723,
                    y: 618,
                    width: 254,
                    height: 99,
                    title: defineMessage({ id: "/G692N", defaultMessage: "Kant\u014D" }),
                    sort: 3,
                    color: 2,
                },
                {
                    x: 498,
                    y: 791,
                    width: 254,
                    height: 99,
                    title: defineMessage({ id: "G8ve40", defaultMessage: "T\u014Dkai" }),
                    sort: 4,
                    color: 2,
                },
                {
                    x: 193,
                    y: 893,
                    width: 254,
                    height: 99,
                    title: defineMessage({ id: "9WRVVv", defaultMessage: "Ky\u016Bsh\u016B" }),
                    sort: 5,
                    color: 2,
                },
            ],
        },
    },
};
export default [
    group1000x131,
    group1040x1040,
    group1040x1160,
    group1040x1200,
    group1040x1250,
    group1040x1300,
    group1040x1430,
    group1040x1500,
    group1040x2000,
    group1040x689,
    group1040x545,
    group1040x260,
    groupMap1040x1040,
];
