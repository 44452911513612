var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import omit from 'lodash/omit';
import cloneDeep from 'lodash/cloneDeep';
import flatten from 'lodash/flatten';
import { FormTypes, CompletedSetting, } from '@src/redux/fanp/types/form';
export var initialState = {
    formBuilds: [],
    paginatedFormIds: [],
    forms: {},
    formElementTemplates: {},
    formElements: {},
    formTemplates: {},
    fieldCollections: {},
    webformFieldsList: [],
    formQuestions: [],
    newFormName: '',
    isSaveSuccess: [false, CompletedSetting.UNDEFINED],
    formElementErrors: {},
};
// The Reducer responsible for state related to our RPA Form Settings Screen
//
// Requirements:
// 1. Ability to differentiate between a "saved" and "draft" Form
// 2. Ability to moved a draft Form to a saved Form
// 3. Limited nesting to avoid really awful Redux updates
// 4. Ability to add/delete/update entities on a draft with little pain
// 5. Ability to deserialize data from server to serialize data back to server
export default (function (state, action) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x;
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case FormTypes.SET_FIELD_ORDER: {
            var from = action.from, to = action.to;
            var fieldCollection = state.fieldCollections[action.fieldCollectionId];
            var newFieldsList = __spreadArray([], __read(fieldCollection.fieldsList));
            var lowerBound = 0;
            var upperBound = fieldCollection.fieldsList.length - 1;
            // Check that from and to are in bounds
            if (from < lowerBound ||
                to < lowerBound ||
                from > upperBound ||
                to > upperBound)
                return state;
            // Update state by swapping indexes from/to
            _a = __read([
                newFieldsList[to],
                newFieldsList[from],
            ], 2), newFieldsList[from] = _a[0], newFieldsList[to] = _a[1];
            return __assign(__assign({}, state), { fieldCollections: __assign(__assign({}, state.fieldCollections), (_b = {}, _b[action.fieldCollectionId] = __assign(__assign({}, fieldCollection), { fieldsList: newFieldsList }), _b)) });
        }
        case FormTypes.SET_FIELD_COLLECTION:
            return __assign(__assign({}, state), { fieldCollections: __assign(__assign({}, state.fieldCollections), (_c = {}, _c[action.fieldCollection.id] = __assign({}, action.fieldCollection), _c)) });
        case FormTypes.ADD_FIELD:
            return __assign(__assign({}, state), { fieldCollections: __assign(__assign({}, state.fieldCollections), (_d = {}, _d[action.fieldCollectionId] = __assign(__assign({}, state.fieldCollections[action.fieldCollectionId]), { fieldsList: __spreadArray(__spreadArray([], __read(state.fieldCollections[action.fieldCollectionId].fieldsList)), [
                        action.field,
                    ]) }), _d)) });
        case FormTypes.UPDATE_FIELD: {
            var updatedField_1 = Object.values(state.fieldCollections[action.fieldCollectionId].fieldsList).find(function (f) { return f.id === action.fieldId; });
            updatedField_1.name = action.name;
            return __assign(__assign({}, state), { fieldCollections: __assign(__assign({}, state.fieldCollections), (_e = {}, _e[action.fieldCollectionId] = __assign(__assign({}, state.fieldCollections[action.fieldCollectionId]), Object.values(state.fieldCollections[action.fieldCollectionId].fieldsList).map(function (field) {
                    return field.id === updatedField_1.id ? updatedField_1 : field;
                })), _e)) });
        }
        case FormTypes.DELETE_FIELD:
            return __assign(__assign({}, state), { fieldCollections: __assign(__assign({}, state.fieldCollections), (_f = {}, _f[action.fieldCollectionId] = __assign(__assign({}, state.fieldCollections[action.fieldCollectionId]), { fieldsList: Object.values(state.fieldCollections[action.fieldCollectionId].fieldsList).filter(function (field) { return field.id !== action.fieldId; }) }), _f)) });
        case FormTypes.SET_PAGINATION_FORM_IDS:
            return __assign(__assign({}, state), { paginatedFormIds: action.paginatedFormIds });
        case FormTypes.SAVE_NEW_FORM_NAME:
            return __assign(__assign({}, state), { newFormName: action.name });
        case FormTypes.SET_FORMS_IS_DRAFT:
            return __assign(__assign({}, state), { forms: __assign(__assign({}, state.forms), action.formIds.reduce(function (obj, formId) {
                    var _a;
                    return (__assign(__assign({}, obj), (_a = {}, _a[formId] = __assign(__assign({}, state.forms[formId]), { isDraft: true }), _a)));
                }, {})) });
        case FormTypes.ADD_FORMS:
            return __assign(__assign({}, state), { forms: __assign(__assign({}, state.forms), action.forms), formElements: __assign(__assign({}, state.formElements), action.formElements) });
        case FormTypes.SET_FORM_ELEMENT_TEMPLATES:
            return __assign(__assign({}, state), { formElementTemplates: __assign(__assign({}, state.formElementTemplates), action.formElementTemplates), formElements: __assign(__assign({}, state.formElements), action.formElements) });
        case FormTypes.SET_FORM:
            return __assign(__assign({}, state), { forms: action.forms, formElements: action.formElements });
        case FormTypes.SET_SELECT_INPUT_OPTIONS: {
            var formElementId = action.formElementId, selectFormElementOptions = action.selectFormElementOptions;
            var targetFormElement = state.formElements[formElementId];
            targetFormElement.select.optionsList = selectFormElementOptions;
            return __assign(__assign({}, state), { formElements: __assign(__assign({}, state.formElements), (_g = {}, _g[formElementId] = __assign({}, targetFormElement), _g)) });
        }
        case FormTypes.UPDATE_WEBFORM_NAME: {
            var formId = action.formId, value = action.value;
            var targetForm = state.forms[formId];
            targetForm.name = value;
            return __assign(__assign({}, state), { forms: __assign(__assign({}, state.forms), (_h = {}, _h[formId] = __assign({}, targetForm), _h)) });
        }
        case FormTypes.UPDATE_WEBFORM_LOGO_URL: {
            var formId = action.formId, value = action.value;
            var targetForm = state.forms[formId];
            targetForm.logoUri = value;
            return __assign(__assign({}, state), { forms: __assign(__assign({}, state.forms), (_j = {}, _j[formId] = __assign({}, targetForm), _j)) });
        }
        case FormTypes.REMOVE_EXIST_FORM_ELEMENT: {
            var webformId = action.webformId, tabId = action.tabId;
            var targetForm = state.forms[webformId];
            var targetPage = targetForm.pagesList[tabId];
            var newElements = targetPage.elementsList.filter(function (elementId) { return elementId !== action.formElementId; });
            targetPage.elementsList = newElements;
            var newFormElements_1 = __assign({}, state.formElements);
            newFormElements_1 = Object.keys(newFormElements_1)
                .filter(function (key) { return key !== action.formElementId; })
                .reduce(function (obj, key) {
                obj[key] = newFormElements_1[key];
                return obj;
            }, {});
            return __assign(__assign({}, state), { forms: __assign(__assign({}, state.forms), (_k = {}, _k[webformId] = __assign({}, targetForm), _k)), formElements: newFormElements_1 });
        }
        case FormTypes.SET_WEBFORM_TABLE_ROW_ORDER_SCRAPER_GROUP: {
            var webformId = action.webformId, from = action.from, to = action.to;
            var targetForm = state.forms[webformId];
            var page = targetForm.pagesList[0];
            var newElements = cloneDeep(page.elementsList);
            if (Array.isArray(to) && Array.isArray(from)) {
                var toGroupIds = to.map(function (element) { return element.id; });
                var fromGroupIds = from.map(function (element) { return element.id; });
                var toIndex = newElements.indexOf(toGroupIds[0]);
                var fromIndex = newElements.indexOf(fromGroupIds[0]);
                // remove
                newElements.splice(fromIndex, fromGroupIds.length);
                // add
                if (fromGroupIds.length > 1 && fromIndex < toIndex) {
                    newElements.splice.apply(newElements, __spreadArray([toIndex - (fromGroupIds.length - 1),
                        0], __read(fromGroupIds)));
                }
                else {
                    newElements.splice.apply(newElements, __spreadArray([toIndex, 0], __read(fromGroupIds)));
                }
                newElements = flatten(newElements);
            }
            else if (Array.isArray(to)) {
                var toGroupIds = to.map(function (element) { return element.id; });
                var fromIndex = newElements.indexOf(from.id);
                var toIndex = newElements.indexOf(toGroupIds[0]);
                var movedElement = newElements[fromIndex];
                // remove
                newElements.splice(fromIndex, 1);
                // add
                newElements.splice(toIndex, 0, movedElement);
                newElements = flatten(newElements);
            }
            else if (Array.isArray(from)) {
                var fromGroupIds = from.map(function (element) { return element.id; });
                var fromIndex = newElements.indexOf(fromGroupIds[0]);
                var toIndex = newElements.indexOf(to.id);
                // remove
                newElements.splice(fromIndex, fromGroupIds.length);
                // add
                if (fromGroupIds.length > 1 && fromIndex < toIndex) {
                    newElements.splice.apply(newElements, __spreadArray([toIndex - (fromGroupIds.length - 1),
                        0], __read(fromGroupIds)));
                }
                else {
                    newElements.splice.apply(newElements, __spreadArray([toIndex, 0], __read(fromGroupIds)));
                }
                newElements = flatten(newElements);
            }
            else {
                var toIndex = newElements.indexOf(to.id);
                var fromIndex = newElements.indexOf(from.id);
                var movedElement = newElements[fromIndex];
                newElements.splice(fromIndex, 1);
                newElements.splice(toIndex, 0, movedElement);
            }
            page.elementsList = newElements;
            return __assign(__assign({}, state), { forms: __assign(__assign({}, state.forms), (_l = {}, _l[webformId] = __assign({}, targetForm), _l)) });
        }
        case FormTypes.REMOVE_EXISTING_SCRAPER_GROUP: {
            var webformId = action.webformId, tabId = action.tabId, scraperGroup_1 = action.scraperGroup;
            var targetForm = state.forms[webformId];
            var page = targetForm.pagesList[tabId];
            var newElements_1 = [];
            page.elementsList.forEach(function (newElementId) {
                var scraperElement = scraperGroup_1.find(function (element) { return element.id === newElementId; });
                if (!scraperElement) {
                    newElements_1.push(newElementId);
                }
            });
            page.elementsList = newElements_1;
            var newFormElements_2 = cloneDeep(state.formElements);
            Object.keys(state.formElements).forEach(function (formElementId) {
                var scraperElement = scraperGroup_1.find(function (element) { return element.id === formElementId; });
                if (scraperElement)
                    delete newFormElements_2[formElementId];
            });
            return __assign(__assign({}, state), { forms: __assign(__assign({}, state.forms), (_m = {}, _m[webformId] = __assign({}, targetForm), _m)), formElements: newFormElements_2 });
        }
        case FormTypes.HANDLE_NEW_SCRAPER_ELEMENTS: {
            var webformId = action.webformId, tabId = action.tabId, oldTargetScraperElements = action.oldTargetScraperElements, newTargetScraperElements = action.newTargetScraperElements;
            var targetForm = state.forms[webformId];
            var page = targetForm.pagesList[tabId];
            var newElements = cloneDeep(page.elementsList);
            var oldTargetScraperElementIds_1 = oldTargetScraperElements.map(function (element) {
                return element.id;
            });
            var newTargetScraperElementIds = newTargetScraperElements.map(function (element) {
                return element.id;
            });
            var newFormElementsObj_1 = {};
            newTargetScraperElements.forEach(function (element) {
                newFormElementsObj_1[element.id] = element;
            });
            var firstIndex = newElements.indexOf(oldTargetScraperElementIds_1[0]);
            newElements = newElements.filter(function (id) {
                return oldTargetScraperElementIds_1.indexOf(id) < 0;
            });
            newElements.splice(firstIndex, 0, newTargetScraperElementIds);
            newElements = flatten(newElements);
            page.elementsList = newElements;
            var newFormElements_3 = cloneDeep(state.formElements);
            Object.keys(newFormElements_3).forEach(function (formElementId) {
                var scraperElement = oldTargetScraperElementIds_1.find(function (element) { return element === formElementId; });
                if (scraperElement)
                    delete newFormElements_3[formElementId];
            });
            newFormElements_3 = Object.assign(newFormElements_3, newFormElementsObj_1);
            return __assign(__assign({}, state), { forms: __assign(__assign({}, state.forms), (_o = {}, _o[webformId] = __assign({}, targetForm), _o)), formElements: newFormElements_3 });
        }
        case FormTypes.SET_NEW_BLANK_FORM_ELEMENT: {
            var webformId = action.webformId, tabId = action.tabId;
            var targetForm = state.forms[webformId];
            if (targetForm.pagesList.length === 0) {
                var newPageList = { elementsList: [] };
                var newElements_2 = __spreadArray(__spreadArray([], __read(newPageList.elementsList)), [
                    action.newFormElement.id,
                ]);
                newPageList.elementsList = newElements_2;
                targetForm.pagesList.push(newPageList);
                return __assign(__assign({}, state), { forms: __assign(__assign({}, state.forms), (_p = {}, _p[webformId] = __assign({}, targetForm), _p)), formElements: __assign(__assign({}, state.formElements), (_q = {}, _q[action.newFormElement.id] = action.newFormElement, _q)) });
            }
            var targetPage = targetForm.pagesList[tabId];
            var newElements = __spreadArray(__spreadArray([], __read(targetPage.elementsList)), [
                action.newFormElement.id,
            ]);
            targetPage.elementsList = newElements;
            return __assign(__assign({}, state), { forms: __assign(__assign({}, state.forms), (_r = {}, _r[webformId] = __assign({}, targetForm), _r)), formElements: __assign(__assign({}, state.formElements), (_s = {}, _s[action.newFormElement.id] = action.newFormElement, _s)) });
        }
        case FormTypes.SET_NEW_BLANK_FORM_ELEMENT_WITH_INDEX: {
            var webformId = action.webformId, tabId = action.tabId, newFormElement = action.newFormElement, targetPositionElement = action.targetPositionElement;
            var targetForm = state.forms[webformId];
            var targetPage = targetForm.pagesList[tabId];
            var newElements = cloneDeep(targetPage.elementsList);
            if (Array.isArray(targetPositionElement)) {
                var toIndex = newElements.indexOf(targetPositionElement[0].id);
                newElements.splice(toIndex, 0, action.newFormElement.id);
            }
            else if (targetPositionElement) {
                var toIndex = newElements.indexOf(targetPositionElement.id);
                newElements.splice(toIndex, 0, action.newFormElement.id);
            }
            else {
                newElements.push(action.newFormElement.id);
            }
            targetPage.elementsList = newElements;
            return __assign(__assign({}, state), { forms: __assign(__assign({}, state.forms), (_t = {}, _t[webformId] = __assign({}, targetForm), _t)), formElements: __assign(__assign({}, state.formElements), (_u = {}, _u[action.newFormElement.id] = action.newFormElement, _u)) });
        }
        case FormTypes.SET_FORM_TEMPLATES:
            return __assign(__assign({}, state), { formTemplates: __assign(__assign({}, state.formTemplates), action.formTemplates), formElements: __assign(__assign({}, state.formElements), action.formElements) });
        case FormTypes.UPDATE_FORM_NAME:
            return __assign(__assign({}, state), { forms: __assign(__assign({}, state.forms), (_v = {}, _v[action.id] = __assign(__assign({}, state.forms[action.id]), { name: action.name }), _v)) });
        case FormTypes.UPDATE_FORM:
            return __assign(__assign({}, state), { forms: __assign(__assign({}, state.forms), (_w = {}, _w[action.formId] = action.forms[action.formId], _w)), formElements: __assign(__assign({}, state.formElements), action.formElements) });
        case FormTypes.DELETE_FORM:
            return __assign(__assign({}, state), { forms: omit(state.forms, [action.id]), paginatedFormIds: state.paginatedFormIds.filter(function (formId) { return formId !== action.id; }) });
        case FormTypes.SET_FORM_ELEMENT:
            return __assign(__assign({}, state), { formElements: __assign(__assign({}, state.formElements), (_x = {}, _x[action.formElement.id] = action.formElement, _x)) });
        case FormTypes.SET_IS_SAVE_SUCCESS:
            return __assign(__assign({}, state), { isSaveSuccess: [action.isSaveSuccess, action.completedSetting] });
        case FormTypes.SET_FORM_ELEMENT_ERRORS:
            return __assign(__assign({}, state), { formElementErrors: action.formElementErrors });
        case FormTypes.SET_FORM_BUILDS: {
            return __assign(__assign({}, state), { formBuilds: action.formBuilds });
        }
        case FormTypes.SET_NEW_FORM_BUILD: {
            return __assign(__assign({}, state), { formBuilds: __spreadArray(__spreadArray([], __read(state.formBuilds)), [action.formBuild]) });
        }
        case FormTypes.UPDATE_FORM_BUILD: {
            var tempFormBuilds = cloneDeep(state.formBuilds);
            tempFormBuilds.forEach(function (formBuild) {
                if (formBuild.id === action.formBuild.id) {
                    formBuild = __assign({}, action.formBuild);
                }
            });
            return __assign(__assign({}, state), { formBuilds: __spreadArray([], __read(tempFormBuilds)) });
        }
        case FormTypes.DELETE_FORM_SCENARIO_SEGMENT: {
            var tempFormBuilds = cloneDeep(state.formBuilds);
            var newFormBuilds = tempFormBuilds.map(function (formBuild) {
                if (formBuild.id === action.updatedFormBuild.id) {
                    return action.updatedFormBuild;
                }
                return formBuild;
            });
            return __assign(__assign({}, state), { formBuilds: __spreadArray([], __read(newFormBuilds)) });
        }
        case FormTypes.DELETE_WEB_FORM_SEGMENT: {
            var tempFormBuilds = cloneDeep(state.formBuilds);
            var newFormBuilds = tempFormBuilds.map(function (formBuild) {
                if (formBuild.id === action.updatedFormBuild.id) {
                    return action.updatedFormBuild;
                }
                return formBuild;
            });
            return __assign(__assign({}, state), { formBuilds: __spreadArray([], __read(newFormBuilds)) });
        }
        case FormTypes.SET_FORM_SCENARIO: {
            var targetFormBuild_1 = state.formBuilds.find(function (formBuild) { return formBuild.id === action.formBuildId; });
            var targetFormBuildSegments = cloneDeep(targetFormBuild_1.segmentsList);
            var existingSegment = targetFormBuildSegments.find(function (seg) {
                return seg.formScenario && seg.formScenario.id === action.formScenario.id;
            });
            var newFormBuildsSegments_1;
            if (existingSegment) {
                newFormBuildsSegments_1 = targetFormBuildSegments.map(function (segment) {
                    if (segment.formScenario &&
                        segment.formScenario.id === action.formScenario.id) {
                        var targetFormBuildSegment = cloneDeep(segment);
                        targetFormBuildSegment.formScenario = action.formScenario;
                        return targetFormBuildSegment;
                    }
                    return segment;
                });
            }
            else {
                newFormBuildsSegments_1 = targetFormBuildSegments.map(function (seg) {
                    if (seg.id === action.segmentId) {
                        var targetFormBuildSegment = cloneDeep(seg);
                        targetFormBuildSegment.formScenario = action.formScenario;
                        return targetFormBuildSegment;
                    }
                    return seg;
                });
            }
            var tempFormBuilds = cloneDeep(state.formBuilds);
            tempFormBuilds.forEach(function (formBuild) {
                if (formBuild.id === targetFormBuild_1.id) {
                    formBuild.segmentsList = newFormBuildsSegments_1;
                }
            });
            return __assign(__assign({}, state), { formBuilds: __spreadArray([], __read(tempFormBuilds)) });
        }
        case FormTypes.DELETE_EMPTY_SEGMENT: {
            var targetFormBuild_2 = state.formBuilds.find(function (formBuild) { return formBuild.id === action.formBuildId; });
            var targetFormBuildSegments = cloneDeep(targetFormBuild_2.segmentsList);
            var newTargetFormBuildSegments_1 = targetFormBuildSegments.filter(
            // This type assert just for the type error, actually segment have two types:
            // one is FormBuildSegment.AsObject for the real segment data
            // another one is BlankFormBuildSegment for the new segment create from frontend;
            function (segment) { return segment.id !== action.id; });
            var tempFormBuilds = cloneDeep(state.formBuilds);
            tempFormBuilds.forEach(function (formBuild) {
                if (formBuild.id === targetFormBuild_2.id) {
                    formBuild.segmentsList = newTargetFormBuildSegments_1;
                }
            });
            return __assign(__assign({}, state), { formBuilds: __spreadArray([], __read(tempFormBuilds)) });
        }
        case FormTypes.ADD_BLANK_FORM_BUILD_SEGMENT: {
            var tempFormBuilds = cloneDeep(state.formBuilds);
            tempFormBuilds.forEach(function (formBuild) {
                if (formBuild.id === action.targetFormBuildId) {
                    formBuild.segmentsList.push(action.blankFormBuildSegment);
                }
            });
            return __assign(__assign({}, state), { formBuilds: __spreadArray([], __read(tempFormBuilds)) });
        }
        case FormTypes.SET_FORM_BUILD: {
            var tempFormBuilds = cloneDeep(state.formBuilds);
            var newFormBuilds = [];
            if (tempFormBuilds.length > 0) {
                newFormBuilds = tempFormBuilds.map(function (formBuild) {
                    if (formBuild.id === action.formBuild.id) {
                        return action.formBuild;
                    }
                    return formBuild;
                });
            }
            else {
                newFormBuilds.push(action.formBuild);
            }
            return __assign(__assign({}, state), { formBuilds: __spreadArray([], __read(newFormBuilds)) });
        }
        case FormTypes.DELETE_FORM_BUILD:
            return __assign(__assign({}, state), { formBuilds: state.formBuilds.filter(function (formBuild) { return formBuild.id !== action.id; }) });
        case FormTypes.SET_WEBFORM_FIELDS_LIST: {
            return __assign(__assign({}, state), { webformFieldsList: action.fieldsList });
        }
        case FormTypes.SET_FORM_QUESTIONS_LIST: {
            return __assign(__assign({}, state), { formQuestions: action.formQuestions });
        }
        default:
            return state;
    }
});
