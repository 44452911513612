var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import { colors } from '@src/colors';
import { ModalPortal } from '@src/components/atoms';
import modalTypes from '@src/lib/modalTypes';
import Modal from '@src/components/molecules/Modal';
var ContentContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  input {\n    width: 100%;\n    border: 1px solid ", ";\n    border-radius: 3px;\n    padding: 10px;\n    margin-top: 12px;\n    transition: 0.3s;\n    &:hover,\n    &:focus {\n      border: 1px solid ", ";\n    }\n  }\n"], ["\n  input {\n    width: 100%;\n    border: 1px solid ", ";\n    border-radius: 3px;\n    padding: 10px;\n    margin-top: 12px;\n    transition: 0.3s;\n    &:hover,\n    &:focus {\n      border: 1px solid ", ";\n    }\n  }\n"])), colors.border.default, colors.border.highlight);
var PluginPathCreate = function (_a) {
    var onConfirm = _a.onConfirm, onCancel = _a.onCancel, onChange = _a.onChange, value = _a.value, className = _a.className, errorMsg = _a.errorMsg;
    return (React.createElement(ModalPortal, null,
        React.createElement(Modal, { "data-testid": "plugin-path-create-modal", text: "\u30D1\u30BF\u30FC\u30F3\u4F5C\u6210", type: modalTypes.SELECT, onConfirm: onConfirm, onCancel: onCancel },
            React.createElement(ContentContainer, null,
                React.createElement("div", { className: "message-wrapper primary-label" }, "\u8868\u793A\u3059\u308BURL\u30D1\u30BF\u30FC\u30F3"),
                React.createElement("div", { className: "message-wrapper sub-label" }, "\u6B63\u898F\u8868\u73FE"),
                React.createElement("input", { type: "text", placeholder: "\u5FC5\u9808", onChange: onChange, value: value, className: className }),
                errorMsg && React.createElement("div", { className: "message-wrapper error" }, errorMsg)))));
};
export default PluginPathCreate;
var templateObject_1;
