import "core-js/modules/es.symbol";
import "core-js/modules/es.array.filter";
import "core-js/modules/es.array.find";
import "core-js/modules/es.array.map";
import "core-js/modules/es.function.name";
import "core-js/modules/es.object.get-own-property-descriptor";
import "core-js/modules/es.object.get-own-property-descriptors";
import "core-js/modules/es.object.keys";
import "core-js/modules/web.dom-collections.for-each";
import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { blockTypes } from '@src/lib/app-constants';

var getSelectedBlock = function getSelectedBlock(_ref) {
  var layers = _ref.layers,
      blocks = _ref.blocks,
      activeBlocks = _ref.activeBlocks,
      forms = _ref.forms,
      patterns = _ref.patterns,
      blockId = _ref.blockId;
  var selectedBlock; // IS BLOCK

  var selectedBlockContents = activeBlocks.find(function (b) {
    return b.id === blockId;
  });

  if (selectedBlockContents) {
    selectedBlock = _objectSpread({}, selectedBlockContents, {
      type: blockTypes.BLOCK
    });
  } // IS FORM


  if (!selectedBlock) {
    var formBlocks = forms.map(function (form) {
      var layer = layers[form.layers[0]];
      var firstBlockId = layer ? blocks[layer.blocks[0]].id : false;
      return firstBlockId ? {
        id: firstBlockId,
        name: form.name,
        type: blockTypes.FORM
      } : false;
    }).filter(function (b) {
      return b;
    });
    selectedBlock = formBlocks.find(function (b) {
      return b.id === blockId;
    });
  } // IS PATTERN


  if (!selectedBlock) {
    var patternBlocks = patterns.map(function (pattern) {
      var layer = layers[pattern.layers[0]];
      var firstBlockId = layer ? blocks[layer.blocks[0]].id : false;
      return firstBlockId ? {
        id: firstBlockId,
        name: pattern.name,
        type: blockTypes.PATTERN
      } : undefined;
    }).filter(function (b) {
      return b;
    });
    selectedBlock = patternBlocks.find(function (b) {
      return b.id === blockId;
    });
  }

  return selectedBlock || {};
};

export default getSelectedBlock;