export var SelectedTab;
(function (SelectedTab) {
    SelectedTab[SelectedTab["Preview"] = 0] = "Preview";
    SelectedTab[SelectedTab["ScenarioMapV1"] = 1] = "ScenarioMapV1";
    SelectedTab[SelectedTab["ScenarioMapV2"] = 2] = "ScenarioMapV2";
    SelectedTab[SelectedTab["UploadedImages"] = 3] = "UploadedImages";
    SelectedTab[SelectedTab["UploadedVideos"] = 4] = "UploadedVideos";
    SelectedTab[SelectedTab["UploadedAudio"] = 5] = "UploadedAudio";
    SelectedTab[SelectedTab["FlexMessageTemplate"] = 6] = "FlexMessageTemplate";
    SelectedTab[SelectedTab["Send"] = 7] = "Send";
    SelectedTab[SelectedTab["MessageFilter"] = 8] = "MessageFilter";
    SelectedTab[SelectedTab["ScenarioComments"] = 9] = "ScenarioComments";
    SelectedTab[SelectedTab["None"] = 10] = "None";
})(SelectedTab || (SelectedTab = {}));
export var RightMenuType;
(function (RightMenuType) {
    RightMenuType["TOGGLE_RIGHT_MENU"] = "TOGGLE_RIGHT_MENU";
    RightMenuType["CHANGE_RIGHT_MENU_TAB"] = "CHANGE_RIGHT_MENU_TAB";
    RightMenuType["HANDLE_DELIVERY_OPTION_CHANGE"] = "HANDLE_DELIVERY_OPTION_CHANGE";
})(RightMenuType || (RightMenuType = {}));
