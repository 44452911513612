var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { TAB_0_COLOR } from '@src/lib/app-constants';
export var sortAreaTemplate = function (areas) {
    var sortedColors = areas
        .map(function (_a) {
        var color = _a.color;
        return (color < TAB_0_COLOR ? color + 1 : null);
    })
        .filter(Boolean)
        .sort();
    var newAreas = areas.map(function (area) {
        return __assign(__assign({}, area), { sort: sortedColors.indexOf(area.color + 1) });
    });
    return newAreas;
};
