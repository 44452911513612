var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { Component } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { setConnected } from '@src/redux/fanp/actions/application';
import { fetchAccount } from '@src/redux/fanp/actions/account';
import { ModalPortal } from '@src/components/atoms';
import { Modal } from '@src/components/molecules';
import { AlertModalProvider } from '@src/components/molecules/AlertModal';
import modalTypes from '@src/lib/modalTypes';
import { LocalStorageItemKey } from '@src/typings/enums';
import * as microServiceEndpointsApi from '@src/services/api/microServiceEndpoints';
import * as pathLinks from '@src/routes/Fanp/pathLinks';
import GlobalBrowserRouterHistory from '@src/components/atoms/GlobalBrowserRouterHistory';
import Rollbar from '@src/components/molecules/Rollbar';
import { MUIThemeProvider, EmotionThemeProvider, } from '@zeals-co-ltd/washi-components';
import { StyledEngineProvider } from '@mui/material/styles';
import { baseTheme } from '@zeals-co-ltd/washi-styles';
import DashboardRoutes from './Dashboard';
var ErrorContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: 1rem;\n\n  img {\n    width: 100%;\n  }\n"], ["\n  padding: 1rem;\n\n  img {\n    width: 100%;\n  }\n"])));
var ErrorScreen = function (_a) {
    var onCancel = _a.onCancel;
    return (React.createElement(ModalPortal, null,
        React.createElement(Modal, { type: modalTypes.NONE, onCancel: onCancel, "data-testid": "error-screen-modal" },
            React.createElement(ErrorContainer, null,
                React.createElement("h1", null, "An unhandled exception occured.."),
                React.createElement("h2", null, "Please refresh the browser."),
                React.createElement("h2", null, "If the error persists, please contact the ZEALS engineering team.")))));
};
var Routes = /** @class */ (function (_super) {
    __extends(Routes, _super);
    function Routes(props) {
        var _this = _super.call(this, props) || this;
        _this.updateLoggedIn = function (loggedIn) { return _this.setState({ loggedIn: loggedIn }); };
        _this.closeModal = function () { return window.location.reload(); };
        _this.authenticateUser = function (_a, browserHistory) {
            var pathname = _a.pathname, location = _a.location;
            return __awaiter(_this, void 0, void 0, function () {
                var fetchAccount, accountData, e_1;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            fetchAccount = this.props.fetchAccount;
                            window.localStorage.setItem(LocalStorageItemKey.LastPathname, pathname || location.pathname);
                            _b.label = 1;
                        case 1:
                            _b.trys.push([1, 4, , 5]);
                            if (!!this.state.loggedIn) return [3 /*break*/, 3];
                            return [4 /*yield*/, fetchAccount()];
                        case 2:
                            accountData = _b.sent();
                            if (accountData) {
                                this.setState({ loggedIn: true });
                            }
                            else {
                                browserHistory.push(pathLinks.dashboardNewAccount);
                            }
                            _b.label = 3;
                        case 3: return [3 /*break*/, 5];
                        case 4:
                            e_1 = _b.sent();
                            console.error(e_1);
                            browserHistory.push(pathLinks.dashboardLogin);
                            return [3 /*break*/, 5];
                        case 5: return [2 /*return*/];
                    }
                });
            });
        };
        _this.checkLoggedIn = function (props, browserHistory) {
            _this.authenticateUser(props, browserHistory).then(function () {
                if (_this.state.loggedIn) {
                    browserHistory.push(pathLinks.dashboardAccountsChatbots);
                }
            });
        };
        _this.bootstrapApp = function (props, history) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, Promise.all([
                            this.authenticateUser(props, history),
                            this.setWebGrpcServiceEndpoints(),
                        ])];
                    case 1:
                        _a.sent();
                        this.setState({ isBootstrapping: false });
                        return [2 /*return*/];
                }
            });
        }); };
        _this.setWebGrpcServiceEndpoints = function () { return __awaiter(_this, void 0, void 0, function () {
            var res, token, e_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        console.log('getting endpoints');
                        return [4 /*yield*/, microServiceEndpointsApi.getMicroServiceEndpoints()];
                    case 1:
                        res = _a.sent();
                        token = process.env.NODE_ENV === 'development'
                            ? 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjUyMjgxMzQ0ODUsImlhdCI6MTYyODEzODA4NSwiaXNzIjoiemVhbHMiLCJpZCI6MTIzLCJlbWFpbCI6ImVkaXRvcisxMjNAemVhbHMuY28uanAiLCJyb2xlcyI6WyJlZGl0b3IiXX0.OxcF7hZ7dyozbWmOTXAtXlCKfPGdge62XqeNF-0trrM'
                            : res.jwtToken;
                        localStorage.setItem('FORM_SERVICE_URL', res.formServiceUrl);
                        localStorage.setItem('FORM_AUTOMATION_SERVICE_URL', res.formAutomationServiceUrl);
                        localStorage.setItem('USER_DOSSIER_SERVICE_URL', res.userDosserServiceUrl);
                        localStorage.setItem('GRPC_JWT_TOKEN', token);
                        return [3 /*break*/, 3];
                    case 2:
                        e_2 = _a.sent();
                        console.error(e_2);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        _this.state = {
            loggedIn: false,
            isBootstrapping: true,
        };
        return _this;
    }
    Routes.prototype.componentDidMount = function () {
        var setConnected = this.props.setConnected;
        // Check to see the browser is online
        setConnected(navigator.onLine);
        // Add events to window to ensure that this state is always up to date
        window.addEventListener('online', function () {
            setConnected(true);
        });
        window.addEventListener('offline', function () {
            setConnected(false);
        });
    };
    // If we pass the routes directly into the router, an error appears in the console:
    // Warning: [react-router] You cannot change <Router routes>; it will be ignored
    Routes.prototype.render = function () {
        var _this = this;
        var isBootstrapping = this.state.isBootstrapping;
        return (React.createElement(Rollbar, { fallbackUI: function () { return React.createElement(ErrorScreen, { onCancel: _this.closeModal }); } },
            React.createElement("div", { className: "react-app" },
                React.createElement(StyledEngineProvider, { injectFirst: true },
                    React.createElement(MUIThemeProvider, { theme: baseTheme },
                        React.createElement(EmotionThemeProvider, { theme: baseTheme },
                            React.createElement(AlertModalProvider, null,
                                React.createElement(BrowserRouter, null,
                                    React.createElement(GlobalBrowserRouterHistory, null),
                                    React.createElement(DashboardRoutes, { authenticateUser: this.authenticateUser, checkLoggedIn: this.checkLoggedIn, updateLoggedIn: this.updateLoggedIn, bootstrapApp: this.bootstrapApp, isBootstrapping: isBootstrapping, setWebGrpcServiceEndpoints: this.setWebGrpcServiceEndpoints })))))))));
    };
    return Routes;
}(Component));
export default connect(function () { return ({}); }, {
    setConnected: setConnected,
    fetchAccount: fetchAccount,
})(Routes);
var templateObject_1;
