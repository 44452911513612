var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Composition } from 'atomic-layout';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import * as pathLinks from '@src/routes/Fanp/pathLinks';
import { useSelector, useDispatch } from 'react-redux';
import { injectIntl } from 'react-intl';
import FormBuilderNavigationHeader from '@src/components/molecules/FormBuilderNavigationHeader';
import { Icon, Snackbar, Tab } from '@zeals-co-ltd/washi-components';
import Tabs from '@mui/material/Tabs';
import { colors } from '@src/colors';
import { createStyles, makeStyles } from '@mui/styles';
import { CompletionStatus } from 'zeals-protobuf/zeals/web/entities/form_pb';
import { setIsSaveSuccess } from '@src/redux/fanp/actions/form';
import { displayStatusText } from '@src/lib/form';
import { CompletedSetting, } from '@src/redux/fanp/types/form';
var useSnackbarStyles = makeStyles(function () {
    return createStyles({
        root: {
            top: '90px',
        },
    });
});
var StyledComposition = styled(Composition)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: 0 32px;\n  background-color: ", ";\n  height: 100%;\n  grid-template-rows: auto 1fr;\n\n  .MuiBox-root {\n    border: none;\n  }\n\n  .MuiTypography-root {\n    margin-top: 24px;\n  }\n\n  .MuiTabs-root {\n    margin-bottom: 20px;\n  }\n\n  .MuiTabs-flexContainer {\n    min-height: 64px;\n  }\n\n  .MuiTab-root a {\n    outline: none;\n    color: inherit;\n  }\n"], ["\n  padding: 0 32px;\n  background-color: ", ";\n  height: 100%;\n  grid-template-rows: auto 1fr;\n\n  .MuiBox-root {\n    border: none;\n  }\n\n  .MuiTypography-root {\n    margin-top: 24px;\n  }\n\n  .MuiTabs-root {\n    margin-bottom: 20px;\n  }\n\n  .MuiTabs-flexContainer {\n    min-height: 64px;\n  }\n\n  .MuiTab-root a {\n    outline: none;\n    color: inherit;\n  }\n"])), colors.background.light_grey);
var StatusContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin-left: -3px;\n  font-weight: 700;\n  font-size: 10px;\n  line-height: 1.4;\n  display: flex;\n  align-items: center;\n  letter-spacing: 0.03em;\n  color: ", ";\n\n  span {\n    margin-right: 4px;\n  }\n\n  p,\n  svg,\n  span {\n    color: ", ";\n  }\n\n  span.adicon_in_progress::before {\n    font-size: 17px;\n  }\n\n  .MuiSvgIcon-root {\n    margin-right: 4px;\n  }\n\n  svg {\n    width: 20px;\n    height: auto;\n  }\n"], ["\n  margin-left: -3px;\n  font-weight: 700;\n  font-size: 10px;\n  line-height: 1.4;\n  display: flex;\n  align-items: center;\n  letter-spacing: 0.03em;\n  color: ", ";\n\n  span {\n    margin-right: 4px;\n  }\n\n  p,\n  svg,\n  span {\n    color: ",
    ";\n  }\n\n  span.adicon_in_progress::before {\n    font-size: 17px;\n  }\n\n  .MuiSvgIcon-root {\n    margin-right: 4px;\n  }\n\n  svg {\n    width: 20px;\n    height: auto;\n  }\n"])), colors.success, function (props) {
    // TODO: Use updated colors like in the Form Builds list
    switch (props.completionStatus) {
        case CompletionStatus.COMPLETION_STATUS_COMPLETE:
            return colors.success;
        case CompletionStatus.COMPLETION_STATUS_IN_PROGRESS:
            return colors.secondary_600;
        case CompletionStatus.COMPLETION_STATUS_ERROR:
            return colors.akatsuki;
        default:
            return undefined;
    }
});
var context = {
    displayStatusContent: function (status, intl) {
        switch (status) {
            case CompletionStatus.COMPLETION_STATUS_COMPLETE:
                return (React.createElement(StatusContainer, { completionStatus: status },
                    React.createElement(Icon, { name: "adicon_check_filled", color: "success" }),
                    React.createElement("p", null, displayStatusText(status, intl))));
            case CompletionStatus.COMPLETION_STATUS_IN_PROGRESS:
                return (React.createElement(StatusContainer, { completionStatus: status },
                    React.createElement("span", { className: "adicon_in_progress", color: "secondary" }),
                    React.createElement("p", null, displayStatusText(status, intl))));
            case CompletionStatus.COMPLETION_STATUS_ERROR:
                return (React.createElement(StatusContainer, { completionStatus: status },
                    React.createElement(Icon, { name: "adicon_alert_filled", color: "error" }),
                    React.createElement("p", null, displayStatusText(status, intl))));
            default:
                return undefined;
        }
    },
};
export var FormBuilderContext = React.createContext(context);
var TabValue;
(function (TabValue) {
    TabValue["FormSetting"] = "form-setting";
    TabValue["CVTest"] = "cv-test";
    TabValue["PostLogs"] = "post-logs";
    TabValue["UserResponse"] = "user-response";
})(TabValue || (TabValue = {}));
var FormBuilder = function (_a) {
    var intl = _a.intl, children = _a.children;
    var _b = useParams(), chatbotId = _b.chatbotId, formBuildId = _b.formBuildId;
    var browserHistory = useHistory();
    var location = useLocation();
    var dispatch = useDispatch();
    var snackbarStyles = useSnackbarStyles();
    var _c = __read(useState(true), 2), isFetching = _c[0], setIsFetching = _c[1];
    var _d = __read(useState(false), 2), displaySnackbar = _d[0], setDisplaySnackbar = _d[1];
    var _e = __read(useState(''), 2), snackbarText = _e[0], setSnackbarText = _e[1];
    var _f = __read(useState(TabValue.FormSetting), 2), tabValue = _f[0], setTabValue = _f[1];
    var formBuilds = useSelector(function (state) { return state.form.present.formBuilds; });
    var isSaveSuccess = useSelector(function (state) { return state.form.present.isSaveSuccess; });
    var targetFormBuild = (formBuilds === null || formBuilds === void 0 ? void 0 : formBuilds.find(function (formBuild) { return formBuild.id === formBuildId; })) || undefined;
    var snackbarProps = {
        severity: 'success',
    };
    var handleSnackbarText = function () {
        switch (isSaveSuccess[1]) {
            case CompletedSetting.WEBFORM_SETTING:
                return setSnackbarText(intl.formatMessage({ id: "VEvD1G", defaultMessage: "Web\u30D5\u30A9\u30FC\u30E0\u306E\u8A2D\u5B9A\u304C\u5B8C\u4E86\u3057\u307E\u3057\u305F" }));
            case CompletedSetting.FORM_CONVERSATION:
                return setSnackbarText(intl.formatMessage({ id: "S0wMwE", defaultMessage: "\u30D5\u30A9\u30FC\u30E0\u4F1A\u8A71\u306E\u8A2D\u5B9A\u304C\u5B8C\u4E86\u3057\u307E\u3057\u305F" }));
            case CompletedSetting.FORM_AUTOMATION:
                return setSnackbarText(intl.formatMessage({ id: "PJGtCS", defaultMessage: "Form Automation\u306E\u8A2D\u5B9A\u304C\u5B8C\u4E86\u3057\u307E\u3057\u305F" }));
            default:
                return undefined;
        }
    };
    if (isSaveSuccess[0]) {
        handleSnackbarText();
        setDisplaySnackbar(true);
        dispatch(setIsSaveSuccess(false, CompletedSetting.UNDEFINED));
    }
    useEffect(function () {
        if (targetFormBuild) {
            setIsFetching(false);
        }
    }, [targetFormBuild]);
    // TODO: CHANGE THE IMPLEMENTATION
    useEffect(function () {
        if (location.pathname.includes(TabValue.PostLogs)) {
            return setTabValue(TabValue.PostLogs);
        }
        return setTabValue(TabValue.FormSetting);
    }, [location.pathname]);
    var tabItems = [
        {
            label: intl.formatMessage({ id: "oxhtrB", defaultMessage: "RPA\u30D3\u30EB\u30C0\u30FC" }),
            // FormBuilderFormSettings.tsx
            path: pathLinks.dashboardAdChatbots_chatbotId_FormBuilder_formBuildId_FormSettings({
                chatbotId: chatbotId,
                formBuildId: formBuildId,
            }),
            value: TabValue.FormSetting,
            disabled: false,
        },
        {
            label: intl.formatMessage({ id: "OvOB+A", defaultMessage: "CV Test" }),
            value: TabValue.CVTest,
            disabled: true,
        },
        {
            label: intl.formatMessage({ id: "4z0cn+", defaultMessage: "Post Logs" }),
            // FormBuilderPostLogs.tsx
            path: pathLinks.dashboardAdChatbots_chatbotId_FormBuilder_formBuildId_PostLogs({ chatbotId: chatbotId, formBuildId: formBuildId }),
            value: TabValue.PostLogs,
            disabled: false,
        },
        {
            label: intl.formatMessage({ id: "8Ddt+N", defaultMessage: "User Responses" }),
            value: TabValue.UserResponse,
            disabled: true,
        },
    ];
    var handleChangeTab = function (e, value) {
        setTabValue(value);
    };
    return (React.createElement(FormBuilderContext.Provider, { value: context },
        React.createElement(StyledComposition, { areas: "\n          header\n          main\n        ", "data-testid": "FormBuilder" }, function (Areas) { return (React.createElement(React.Fragment, null,
            React.createElement(Areas.Header, null,
                React.createElement(FormBuilderNavigationHeader, { formBuildId: formBuildId, isFormBuilderPage: true }),
                React.createElement(Tabs, { indicatorColor: "primary", textColor: "primary", value: tabValue, onChange: handleChangeTab }, tabItems.map(function (tab) {
                    return (React.createElement(Tab, { key: tab.value, label: tab.label, disabled: tab.disabled, onClick: function () { return browserHistory.push(tab.path); }, value: tab.value }));
                }))),
            React.createElement(Areas.Main, { minHeight: "83vh" }, children))); }),
        React.createElement(Snackbar, { className: snackbarStyles.root, onClose: function () { return setDisplaySnackbar(false); }, open: displaySnackbar, alertProps: snackbarProps, anchorOrigin: { vertical: 'top', horizontal: 'center' }, autoHideDuration: 3000 },
            React.createElement("div", null, snackbarText))));
};
export default injectIntl(FormBuilder);
var templateObject_1, templateObject_2;
