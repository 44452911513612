import "core-js/modules/es.array.concat";
var zealsAdmin = {
  collection: function collection(customFormId) {
    return "/zeals_admin/api/custom_forms/".concat(customFormId, "/headless_configs");
  },
  member: function member(customFormId, id) {
    return "".concat(zealsAdmin.collection(customFormId), "/").concat(id);
  }
};
export default {
  zealsAdmin: zealsAdmin
};