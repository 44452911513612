export function makeJpFormattedDateStr(dateStr, includeTime, divider) {
    if (includeTime === void 0) { includeTime = false; }
    if (divider === void 0) { divider = '/'; }
    var d = dateStr ? new Date(dateStr) : new Date();
    var year = d.getFullYear();
    var month = d.getMonth() + 1;
    var day = d.getDate();
    var time = d.toLocaleTimeString();
    return "" + year + divider + month
        .toString()
        .padStart(2, '0') + divider + day.toString().padStart(2, '0') + (includeTime ? "T" + time + ".000+09:00" : '');
}
