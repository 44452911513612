import React from 'react';
import NextBlock from '@src/components/molecules/NextBlock';
import NextScenario from '@src/components/molecules/NextScenario';
import { getConnectedIds } from '@src/lib/adHelpers';
// Wrapper component for NextScenario and NextBlock
// In situations where you have to show either a connected block or scenario
// this component is useful because you pass the necessary data and it decides which to show
export default function NextPostback(_a) {
    var connectedObject = _a.connectedObject, scenarios = _a.scenarios, blocks = _a.blocks, forModal = _a.forModal, isQuickReplyChoice = _a.isQuickReplyChoice, disconnectScenario = _a.disconnectScenario, disconnectBlock = _a.disconnectBlock;
    var _b = getConnectedIds(connectedObject), blockId = _b.blockId, scenarioId = _b.scenarioId, nextScenarioId = _b.nextScenarioId;
    if (blockId) {
        return (React.createElement(NextBlock, { connectedObject: connectedObject, blocks: blocks, forModal: forModal, isQuickReplyChoice: isQuickReplyChoice, disconnectBlock: disconnectBlock }));
    }
    if (scenarioId || nextScenarioId) {
        return (React.createElement(NextScenario, { connectedObject: connectedObject, scenarios: scenarios, forModal: forModal, isQuickReplyChoice: isQuickReplyChoice, disconnectScenario: disconnectScenario }));
    }
    return null;
}
