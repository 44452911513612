import "core-js/modules/es.array.concat";
import _extends from "@babel/runtime/helpers/extends";
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import withContentRender from '../withContentRenderer'; // eslint-disable-line import/no-cycle
// Constants

var FLEX_DIRECTIONS = {
  vertical: 'column',
  baseline: 'row',
  horizontal: 'row'
};
var MARGINS = {
  none: 'none',
  xs: '2px',
  sm: '4px',
  md: '8px',
  lg: '12px',
  xl: '16px',
  xxl: '20px'
}; // Style fns

function getFlex(flex, boxLayout) {
  if (flex !== undefined && flex !== null) {
    return "".concat(flex, " ").concat(flex, " auto");
  }

  if (boxLayout === 'vertical') {
    return '0 0 auto';
  }

  return '1 1 auto';
}

function getMargin(boxLayout, marginType) {
  boxLayout === 'vertical' ? "".concat(MARGINS[marginType], " 0 0 0") : "0 0 0 ".concat(MARGINS[marginType]);
} // Wrapper


var Wrapper = styled.div.withConfig({
  displayName: "Box__Wrapper",
  componentId: "sc-1tlkhux-0"
})(["flex-direction:", ";flex:", ";margin:", " !important;align-items:", ";"], function (props) {
  return FLEX_DIRECTIONS[props.layout];
}, function (props) {
  return getFlex(props.flex, props.boxLayout);
}, function (props) {
  return getMargin(props.boxLayout, props.marginType);
}, function (props) {
  return props.layout === 'baseline' ? 'baseline' : undefined;
}); // Main Component

var Box = function Box(props) {
  var spacing = props.spacing,
      layout = props.layout,
      contents = props.contents,
      renderComponents = props.renderComponents,
      readOnly = props.readOnly,
      chatbotIdParam = props.chatbotIdParam,
      isFirstComponent = props.isFirstComponent,
      isLastComponent = props.isLastComponent,
      setEditingBubbleId = props.setEditingBubbleId,
      removeEditingBubbleId = props.removeEditingBubbleId,
      setLineFlexMessageComponent = props.setLineFlexMessageComponent,
      blockType = props.blockType,
      messageId = props.messageId;
  return React.createElement(Wrapper, _extends({
    className: "component lfm-box space-".concat(spacing, " layout-").concat(layout)
  }, props), renderComponents({
    contents: contents,
    layout: layout,
    spacing: spacing,
    readOnly: readOnly,
    chatbotIdParam: chatbotIdParam,
    isFirstComponent: isFirstComponent,
    isLastComponent: isLastComponent,
    setEditingBubbleId: setEditingBubbleId,
    removeEditingBubbleId: removeEditingBubbleId,
    setLineFlexMessageComponent: setLineFlexMessageComponent,
    blockType: blockType,
    messageId: messageId
  }));
};

Box.propTypes = {
  contents: PropTypes.array.isRequired,
  chatbotIdParam: PropTypes.number.isRequired,
  flex: PropTypes.number,
  blockType: PropTypes.string,
  layout: PropTypes.string,
  spacing: PropTypes.string,
  margin: PropTypes.string,
  action: PropTypes.object,
  boxLayout: PropTypes.string,
  renderComponents: PropTypes.func,
  readOnly: PropTypes.bool,
  isFirstComponent: PropTypes.bool,
  isLastComponent: PropTypes.bool,
  setEditingBubbleId: PropTypes.func,
  removeEditingBubbleId: PropTypes.func,
  setLineFlexMessageComponent: PropTypes.func
};
Box.defaultProps = {
  spacing: 'none',
  boxSpacing: 'none',
  layout: 'vertical',
  boxLayout: 'vertical'
};
export default withContentRender(Box);