import { combineReducers } from 'redux';
import undoable, { includeAction } from 'redux-undo';
import { adScenario as adScenarioActions } from '@src/redux/fanp/constants';
import { user, account, chatbotList, application, adScenario, adUserFilter, form, formAutomation, rightMenu, modal, retentionDelivery, talk, scenario, template, settings, messageFilter, scenarioMap, fieldValue, userSegment, userLabel, initialConversation } from '@src/redux/fanp/reducers';
var AD_CREATE_CAMPAIGN_CONTENT = adScenarioActions.AD_CREATE_CAMPAIGN_CONTENT,
    AD_DELETE_SCENARIO = adScenarioActions.AD_DELETE_SCENARIO,
    AD_DELETE_SCENARIOS = adScenarioActions.AD_DELETE_SCENARIOS,
    AD_REMOVE_MESSAGE = adScenarioActions.AD_REMOVE_MESSAGE,
    AD_REMOVE_BUTTON_FROM_TEXT = adScenarioActions.AD_REMOVE_BUTTON_FROM_TEXT,
    AD_REMOVE_QUICK_REPLY = adScenarioActions.AD_REMOVE_QUICK_REPLY,
    AD_SET_PREV_BLOCKS = adScenarioActions.AD_SET_PREV_BLOCKS,
    AD_SET_BLOCK_MESSAGES = adScenarioActions.AD_SET_BLOCK_MESSAGES,
    AD_HANDLE_NEW_SCENARIO_DELIVERY = adScenarioActions.AD_HANDLE_NEW_SCENARIO_DELIVERY,
    AD_EDIT_ARTICLE = adScenarioActions.AD_EDIT_ARTICLE;
export default combineReducers({
  application: application,
  account: account,
  scenario: scenario,
  chatbotList: undoable(chatbotList, {
    undoType: 'UNDO_CHATBOT_LIST'
  }),
  retentionDelivery: undoable(retentionDelivery, {
    undoType: 'UNDO_RETENTION_DELIVERY'
  }),
  template: template,
  form: undoable(form, {
    undoType: 'UNDO_FORM'
  }),
  formAutomation: undoable(formAutomation, {
    undoType: 'UNDO_FORM_AUTOMATION'
  }),
  user: undoable(user, {
    undoType: 'UNDO_USER'
  }),
  settings: undoable(settings, {
    undoType: 'UNDO_SETTINGS'
  }),
  talk: undoable(talk, {
    undoType: 'UNDO_TALK'
  }),
  adScenario: undoable(adScenario, {
    undoType: 'UNDO_AD_SCENARIO',
    filter: includeAction([AD_CREATE_CAMPAIGN_CONTENT, AD_DELETE_SCENARIO, AD_DELETE_SCENARIOS, AD_REMOVE_MESSAGE, AD_REMOVE_BUTTON_FROM_TEXT, AD_REMOVE_QUICK_REPLY, AD_SET_PREV_BLOCKS, AD_SET_BLOCK_MESSAGES, AD_EDIT_ARTICLE, AD_HANDLE_NEW_SCENARIO_DELIVERY])
  }),
  modal: undoable(modal, {
    undoType: 'UNDO_MODAL'
  }),
  rightMenu: undoable(rightMenu, {
    undoType: 'UNDO_RIGHT_MENU'
  }),
  adUserFilter: undoable(adUserFilter, {
    undoType: 'UNDO_AD_USER_FILTER'
  }),
  messageFilter: messageFilter,
  scenarioMap: scenarioMap,
  fieldValue: fieldValue,
  userSegment: userSegment,
  userLabel: userLabel,
  initialConversation: initialConversation
});