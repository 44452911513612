var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Tooltip } from '@zeals-co-ltd/washi-components';
import React from 'react';
import { NotRequiredField } from './FormAutomationStepItem';
export default function ClickStep(_a) {
    var step = _a.step, intl = _a.intl, onChange = _a.onChange;
    var clickAction = step.clickAction;
    var newStep = function (selectKey, selectValue) {
        var _a;
        return __assign(__assign({}, step), { clickAction: __assign(__assign({}, step.clickAction), (_a = {}, _a[selectKey] = selectValue, _a)) });
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "field not-required" },
            React.createElement(NotRequiredField, null)),
        React.createElement("div", { className: "css-selector" },
            React.createElement(Tooltip, { arrow: true, title: clickAction.querySelector },
                React.createElement("input", { "data-testid": "FormAutomationStepItem-CssSelectorInput", value: clickAction.querySelector, placeholder: intl.formatMessage({ id: "lsZfK4", defaultMessage: "Please enter a CSS selector" }), onChange: function (e) { return onChange(newStep('querySelector', e.target.value)); } }))),
        React.createElement("div", { className: "type not-required" },
            React.createElement(NotRequiredField, null)),
        React.createElement("div", { className: "options not-required" },
            React.createElement(NotRequiredField, null))));
}
