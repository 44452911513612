var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Composition } from 'atomic-layout';
import styled from 'styled-components';
import buttonTypes from '@src/lib/buttonTypes';
import { colors } from '@src/colors';
import { injectIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { Button, FacebookPageThumb, PageListImage, ModalPortal, } from '@src/components/atoms';
import Modal from '@src/components/molecules/Modal';
import ButtonSet from '@src/components/molecules/ButtonSet';
import DefaultRichMenuPreview from '@src/components/molecules/DefaultRichMenuPreview';
import * as pathLinks from '@src/routes/Fanp/pathLinks';
import modalTypes from '@src/lib/modalTypes';
import featureFlags from '@src/lib/feature_flags/featureFlags';
import LIMITS from '@src/lib/platforms/limitations';
import useDefaultRichMenu from '@src/components/hooks/useDefaultRichMenu';
var SettingsSection = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  background-color: ", ";\n  border-radius: 10px;\n  border: 1px solid ", ";\n"], ["\n  background-color: ", ";\n  border-radius: 10px;\n  border: 1px solid ", ";\n"])), colors.white, colors.border_setting_section);
var StyledContentArea = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  .page_info {\n    text-align: center;\n    margin: 0 0 2.5rem;\n\n    .fb_page_name {\n      font-size: 1rem;\n      margin: 1rem auto;\n      white-space: nowrap;\n      text-overflow: ellipsis;\n      overflow: hidden;\n      width: 12.5rem;\n    }\n  }\n  li {\n    display: flex;\n    padding: 1.25rem 0;\n    border-top: 1px solid ", ";\n\n    span {\n      width: 100%;\n      margin-right: 2.5rem;\n      line-height: 2.5rem;\n      font-weight: bold;\n      color: ", ";\n\n      &.first_text {\n        white-space: nowrap;\n        overflow: hidden;\n        text-overflow: ellipsis;\n        font-weight: normal;\n        color: ", ";\n      }\n    }\n  }\n"], ["\n  .page_info {\n    text-align: center;\n    margin: 0 0 2.5rem;\n\n    .fb_page_name {\n      font-size: 1rem;\n      margin: 1rem auto;\n      white-space: nowrap;\n      text-overflow: ellipsis;\n      overflow: hidden;\n      width: 12.5rem;\n    }\n  }\n  li {\n    display: flex;\n    padding: 1.25rem 0;\n    border-top: 1px solid ", ";\n\n    span {\n      width: 100%;\n      margin-right: 2.5rem;\n      line-height: 2.5rem;\n      font-weight: bold;\n      color: ", ";\n\n      &.first_text {\n        white-space: nowrap;\n        overflow: hidden;\n        text-overflow: ellipsis;\n        font-weight: normal;\n        color: ", ";\n      }\n    }\n  }\n"])), colors.border.default, colors.default_text, colors.text_pale);
var ButtonWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: inline-flex;\n  align-items: flex-end;\n"], ["\n  display: inline-flex;\n  align-items: flex-end;\n"])));
var DeactivateContainer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  height: 120px;\n  margin-top: 1rem;\n  padding: 2.5rem;\n  background-color: ", ";\n  border-radius: 10px;\n\n  span {\n    margin-right: 2.5rem;\n    line-height: 2.5rem;\n    font-weight: bold;\n    color: ", ";\n  }\n\n  button {\n    justify-self: end;\n  }\n"], ["\n  height: 120px;\n  margin-top: 1rem;\n  padding: 2.5rem;\n  background-color: ", ";\n  border-radius: 10px;\n\n  span {\n    margin-right: 2.5rem;\n    line-height: 2.5rem;\n    font-weight: bold;\n    color: ", ";\n  }\n\n  button {\n    justify-self: end;\n  }\n"])), colors.white, colors.text_pale);
var Status;
(function (Status) {
    Status["Active"] = "active";
    Status["Inactive"] = "inactive";
})(Status || (Status = {}));
var ContentArea = function (_a) {
    var chatbotId = _a.chatbotId, activeChatbot = _a.activeChatbot, intl = _a.intl, toggleWelcomeBoard = _a.toggleWelcomeBoard, toggleMainMenu = _a.toggleMainMenu, toggleDefaultMessage = _a.toggleDefaultMessage, fetchUpdateChatbotStatus = _a.fetchUpdateChatbotStatus;
    var accessToken = useSelector(function (_a) {
        var page = _a.settings.present.activeChatbot.page;
        return page.line_app_access_token;
    });
    var limits = LIMITS[activeChatbot.platform];
    var _b = __read(useState(false), 2), isConfirmModalOpen = _b[0], setConfirmModalOpen = _b[1];
    var activeDefaultRichMenu = useDefaultRichMenu(accessToken).activeDefaultRichMenu;
    var toggleConfirmModal = function () {
        setConfirmModalOpen(!isConfirmModalOpen);
    };
    var handleChatbotStatusChange = function (status) {
        return function () {
            return fetchUpdateChatbotStatus(chatbotId, status);
        };
    };
    var browserHistory = useHistory();
    var areas = "\n    settingsMenu\n    connectSetting\n  ";
    var areasOfSettingsMenu = "\n    pageInfo\n    settingMenuList\n  ";
    return (React.createElement(React.Fragment, null,
        isConfirmModalOpen && (React.createElement(ModalPortal, null,
            React.createElement(Modal, { "data-testid": "content-area-modal", type: modalTypes === null || modalTypes === void 0 ? void 0 : modalTypes.NONE, text: "\u672C\u5F53\u306BBOT\u3092\u505C\u6B62\u3057\u3066\u3088\u308D\u3057\u3044\u3067\u3059\u304B\uFF1F", color: colors === null || colors === void 0 ? void 0 : colors.error, onCancel: toggleConfirmModal },
                React.createElement(ButtonSet, { buttonProps: {
                        type: buttonTypes === null || buttonTypes === void 0 ? void 0 : buttonTypes.CANCEL,
                        text: 'キャンセル',
                    }, button2Props: {
                        type: buttonTypes === null || buttonTypes === void 0 ? void 0 : buttonTypes.DELETE,
                        text: '停止する',
                    }, float: "right", onConfirm: handleChatbotStatusChange(Status.Inactive), onCancel: toggleConfirmModal })))),
        React.createElement(Composition, { as: StyledContentArea, areas: areas, templateRows: "70% 30%", gap: "3rem" }, function (Areas) { return (React.createElement(React.Fragment, null,
            React.createElement(Areas.SettingsMenu, null,
                React.createElement(Composition, { as: SettingsSection, areas: areasOfSettingsMenu, padding: "2.5rem" }, function (Areas) {
                    var _a, _b, _c;
                    return (React.createElement(React.Fragment, null,
                        React.createElement(Areas.PageInfo, { className: "page_info" },
                            React.createElement(FacebookPageThumb, null,
                                React.createElement(PageListImage, { className: "page-list-img", src: (activeChatbot === null || activeChatbot === void 0 ? void 0 : activeChatbot.page) &&
                                        ((_a = activeChatbot === null || activeChatbot === void 0 ? void 0 : activeChatbot.page) === null || _a === void 0 ? void 0 : _a.thumbnail_url)
                                        ? decodeURIComponent((_b = activeChatbot === null || activeChatbot === void 0 ? void 0 : activeChatbot.page) === null || _b === void 0 ? void 0 : _b.thumbnail_url)
                                        : '/static/images/icon_mouse.png' })),
                            React.createElement("p", { className: "fb_page_name" }, (activeChatbot === null || activeChatbot === void 0 ? void 0 : activeChatbot.page) && ((_c = activeChatbot === null || activeChatbot === void 0 ? void 0 : activeChatbot.page) === null || _c === void 0 ? void 0 : _c.name))),
                        React.createElement(Areas.SettingMenuList, null,
                            limits.SETTINGS_WELCOME_BOARD_ENABLED && (React.createElement("li", { className: "clearfix" },
                                React.createElement("span", { className: "settings-label" }, intl === null || intl === void 0 ? void 0 : intl.formatMessage({ id: "Be2jUj", defaultMessage: "Welcome Board" })),
                                React.createElement(ButtonWrapper, null,
                                    React.createElement(Button, { buttonType: buttonTypes === null || buttonTypes === void 0 ? void 0 : buttonTypes.SETTING, onClick: toggleWelcomeBoard, text: intl === null || intl === void 0 ? void 0 : intl.formatMessage({ id: "7OW8BT", defaultMessage: "Configuration" }) })))),
                            featureFlags.PERSISTENT_MENU_SHOWN && (React.createElement("li", { className: "clearfix" },
                                React.createElement("span", { className: "settings-label" }, intl === null || intl === void 0 ? void 0 : intl.formatMessage({ id: "WNwt9Z", defaultMessage: "Main Menu" })),
                                React.createElement("span", { className: "first_text" }, (intl === null || intl === void 0 ? void 0 : intl.formatMessage({ id: "Tm51AE", defaultMessage: "View saved article" })) + ",..."),
                                React.createElement(ButtonWrapper, null,
                                    React.createElement(Button, { buttonType: buttonTypes === null || buttonTypes === void 0 ? void 0 : buttonTypes.SETTING, onClick: toggleMainMenu, text: intl === null || intl === void 0 ? void 0 : intl.formatMessage({ id: "7OW8BT", defaultMessage: "Configuration" }) })))),
                            limits.SETTINGS_DEFAULT_MESSAGE_ENABLED && (React.createElement("li", { className: "clearfix" },
                                React.createElement("span", { className: "settings-label" }, intl === null || intl === void 0 ? void 0 : intl.formatMessage({ id: "4AY6/l", defaultMessage: "Default Message" })),
                                React.createElement(ButtonWrapper, null,
                                    React.createElement(Button, { buttonType: buttonTypes === null || buttonTypes === void 0 ? void 0 : buttonTypes.SETTING, onClick: toggleDefaultMessage, text: intl === null || intl === void 0 ? void 0 : intl.formatMessage({ id: "7OW8BT", defaultMessage: "Configuration" }) })))),
                            limits.SETTINGS_DEFAULT_RICH_MENU && (React.createElement("div", null,
                                React.createElement("li", { className: "clearfix" },
                                    React.createElement("span", { className: "settings-label" }, intl === null || intl === void 0 ? void 0 : intl.formatMessage({ id: "XxvLPK", defaultMessage: "Default Rich Menu" })),
                                    React.createElement(ButtonWrapper, null,
                                        React.createElement(Button, { buttonType: buttonTypes === null || buttonTypes === void 0 ? void 0 : buttonTypes.SETTING, onClick: function () {
                                                browserHistory.push(pathLinks.dashboardAdChatbots_chatbotId_SettingsDefaultRichMenu({
                                                    chatbotId: chatbotId,
                                                }));
                                            }, text: intl === null || intl === void 0 ? void 0 : intl.formatMessage({ id: "7OW8BT", defaultMessage: "Configuration" }) }))),
                                React.createElement(DefaultRichMenuPreview, { defaultRichMenu: activeDefaultRichMenu() }))))));
                })),
            featureFlags.BOT_DEACTIVATION && (React.createElement(Areas.ConnectSetting, null,
                React.createElement(Composition, { as: DeactivateContainer, templateCols: "50% 50%" },
                    React.createElement("span", null, "\u63A5\u7D9A\u8A2D\u5B9A"),
                    React.createElement(Button, { buttonType: (activeChatbot === null || activeChatbot === void 0 ? void 0 : activeChatbot.status) === 'active'
                            ? buttonTypes === null || buttonTypes === void 0 ? void 0 : buttonTypes.DELETE
                            : buttonTypes === null || buttonTypes === void 0 ? void 0 : buttonTypes.SAVE, width: 120, text: (activeChatbot === null || activeChatbot === void 0 ? void 0 : activeChatbot.status) === 'active'
                            ? intl === null || intl === void 0 ? void 0 : intl.formatMessage({ id: "q/uwLT", defaultMessage: "Stop" })
                            : intl.formatMessage({ id: "iMCRKp", defaultMessage: "Start Up" }), onClick: (activeChatbot === null || activeChatbot === void 0 ? void 0 : activeChatbot.status) === 'active'
                            ? toggleConfirmModal
                            : handleChatbotStatusChange(Status.Active) })))))); })));
};
export default injectIntl(ContentArea);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
