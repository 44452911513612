import React, { Fragment } from 'react';
import { mediaTypes, mediaPlaceholderIcons } from '@src/lib/app-constants';
import buttonTypes from '@src/lib/buttonTypes';
import { RadioCheckbox } from '@src/components/atoms';
import { ButtonSet } from '@src/components/molecules';
import Moment from 'moment';
import { injectIntl } from 'react-intl';
var SelectableFiles = function (_a) {
    var uploadedFileData = _a.uploadedFileData, deleteFileIds = _a.deleteFileIds, placeholderIcon = _a.placeholderIcon, handleCheckboxClick = _a.handleCheckboxClick;
    return (React.createElement("div", { className: "grid-cell-file", id: uploadedFileData.id.toString(), onClick: function (e) { return handleCheckboxClick(e); } },
        React.createElement(RadioCheckbox, { checked: deleteFileIds.includes(uploadedFileData.id.toString()), changeCheckedState: handleCheckboxClick, id: uploadedFileData.id }),
        React.createElement("div", { className: "file-container " + placeholderIcon },
            React.createElement("div", { className: "file-info" },
                React.createElement("div", { className: "file-info-name", style: { width: '300px' } }, uploadedFileData.name),
                React.createElement("div", { className: "file-info-duration" }, uploadedFileData.duration
                    ? "(" + Moment(uploadedFileData.duration).format('mm:ss') + ")"
                    : null)))));
};
var SelectableImages = function (_a) {
    var uploadedFileData = _a.uploadedFileData, deleteFileIds = _a.deleteFileIds, handleCheckboxClick = _a.handleCheckboxClick;
    return (React.createElement("div", { className: "grid-cell-image", id: uploadedFileData.id.toString(), onClick: function (e) { return handleCheckboxClick(e); } },
        React.createElement("div", { className: "image-content" },
            React.createElement("img", { src: uploadedFileData.url, className: "each-file " + (deleteFileIds.includes(uploadedFileData.id.toString())
                    ? 'checked'
                    : '') }),
            React.createElement(RadioCheckbox, { checked: deleteFileIds.includes(uploadedFileData.id.toString()), changeCheckedState: handleCheckboxClick, id: uploadedFileData.id })),
        React.createElement("div", { className: "filename" }, uploadedFileData.name)));
};
var SelectMode = function (_a) {
    var mediaType = _a.mediaType, uploadedFileDatas = _a.uploadedFileDatas, deleteFileIds = _a.deleteFileIds, handleCheckboxClick = _a.handleCheckboxClick, toggleDeleteConfirmationModal = _a.toggleDeleteConfirmationModal, toggleSelectMode = _a.toggleSelectMode, intl = _a.intl;
    var isImage = mediaType === mediaTypes.IMAGE;
    var placeholderIcon = mediaPlaceholderIcons[mediaType];
    return (React.createElement(Fragment, null,
        React.createElement(ButtonSet, { buttonProps: {
                type: buttonTypes.SETTING,
                text: intl.formatMessage({ id: "1DuvSj", defaultMessage: "Stop Delete" }),
            }, button2Props: {
                type: buttonTypes.DELETE,
                text: intl.formatMessage({ id: "K3r6DQ", defaultMessage: "Delete" }),
            }, preventOnCancel: true, hasError: !deleteFileIds.length, onConfirm: toggleDeleteConfirmationModal, onCancel: toggleSelectMode }),
        React.createElement("div", { className: "files-container" },
            React.createElement("div", { className: "files-grid-list select-mode " + (isImage ? 'has-images' : '') }, uploadedFileDatas.map(function (data) {
                return isImage ? (React.createElement(SelectableImages, { key: "updated-image-" + data.id, uploadedFileData: data, deleteFileIds: deleteFileIds, handleCheckboxClick: handleCheckboxClick, placeholderIcon: null })) : (React.createElement(SelectableFiles, { key: "updated-file-" + data.id, uploadedFileData: data, deleteFileIds: deleteFileIds, handleCheckboxClick: handleCheckboxClick, placeholderIcon: placeholderIcon }));
            })))));
};
export default injectIntl(SelectMode);
