import "core-js/modules/es.symbol";
import "core-js/modules/es.array.filter";
import "core-js/modules/es.array.from";
import "core-js/modules/es.array.includes";
import "core-js/modules/es.array.iterator";
import "core-js/modules/es.array.join";
import "core-js/modules/es.object.get-own-property-descriptor";
import "core-js/modules/es.object.get-own-property-descriptors";
import "core-js/modules/es.object.keys";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.regexp.constructor";
import "core-js/modules/es.regexp.to-string";
import "core-js/modules/es.string.includes";
import "core-js/modules/es.string.iterator";
import "core-js/modules/es.string.match";
import "core-js/modules/es.string.split";
import "core-js/modules/web.dom-collections.for-each";
import "core-js/modules/web.dom-collections.iterator";
import "core-js/modules/web.url";
import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { object, string, number, array
/* , mixed */
} from 'yup';
import { get } from 'lodash';
import { mediaTypes, mediaAcceptTypes, googleStoreUrl, K_BYTES // tabTypes,
// URL_SMART_REPLACE_LIST,
} from '@src/lib/app-constants';
import limitations from '@src/lib/platforms/limitations'; // import { phoneNumReg } from '@src/lib/helpers/validate';

import { hostReg } from '@src/lib/helpers/validate';
export var createPrivacyPolicySchema = function createPrivacyPolicySchema(sReplaces) {
  return object().shape(_objectSpread({
    url: string().url().required('必須')
  }, sReplaces.reduce(function (schema, sReplace) {
    schema[sReplace.pattern] = string().required('必須');
    return schema;
  }, {})));
};
export var chatlinkSchema = object().shape({
  name: string().required('必須'),
  title: string(),
  color_code: string(),
  body: string(),
  remark: string(),
  image_url: string().url().required('必須'),
  button_text: string(),
  bot_url: string().url().required('必須'),
  active: number().required('必須'),
  trigger: string().required('必須'),
  display_interval_control_flag: number().required('必須'),
  modal_type: string().required('必須'),
  device_type: string()
});
export var schemas = {
  normal: object().shape({
    name: string().required('必須'),
    title: string().required('必須'),
    color_code: string().required('必須'),
    body: string().required('必須'),
    remark: string(),
    image_url: string().url().required('必須'),
    button_text: string().required('必須'),
    bot_url: string().url().required('必須'),
    active: number().required('必須'),
    trigger: string().required('必須'),
    display_interval_control_flag: number().required('必須'),
    modal_type: string().required('必須'),
    device_type: string()
  }),
  image: object().shape({
    name: string().required('必須'),
    title: string(),
    color_code: string(),
    body: string(),
    remark: string(),
    image_url: string().url().required('必須'),
    button_text: string(),
    bot_url: string().url().required('必須'),
    active: number().required('必須'),
    trigger: string().required('必須'),
    display_interval_control_flag: number().required('必須'),
    modal_type: string().required('必須'),
    device_type: string()
  }),
  banner: object().shape({
    name: string().required('必須'),
    title: string(),
    color_code: string(),
    body: string(),
    remark: string(),
    image_url: string().url().required('必須'),
    button_text: string(),
    bot_url: string().url().required('必須'),
    active: number().required('必須'),
    trigger: string().required('必須'),
    display_interval_control_flag: number().required('必須'),
    modal_type: string().required('必須'),
    device_type: string()
  })
};
export var pluginSchemas = {
  normal: object().shape({
    modal_type: string().required('必須'),
    title: string().required('必須'),
    header_text: string().required('必須'),
    color_code: string().required('必須'),
    body: string().required('必須'),
    remark: string(),
    image_url: string().url().required('必須'),
    button_text: string().required('必須'),
    display_interval_control_flag: number().required('必須'),
    chatbot_id: number().required('必須')
  }),
  image: object().shape({
    modal_type: string().required('必須'),
    title: string().required('必須'),
    header_text: string(),
    color_code: string(),
    body: string(),
    remark: string(),
    image_url: string().url().required('必須'),
    button_text: string(),
    display_interval_control_flag: number().required('必須'),
    chatbot_id: number().required('必須')
  }),
  banner: object().shape({
    title: string().required('必須'),
    header_text: string(),
    color_code: string(),
    body: string(),
    remark: string(),
    image_url: string().url().required('必須'),
    url: string().url().required('必須'),
    button_text: string(),
    chatbot_id: number().required('必須'),
    display_interval_control_flag: number().required('必須')
  })
};
export var mediaSchema = object({
  type: string(),
  url: string().url().required('必須').when('type', {
    is: mediaTypes.AUDIO,
    then: string().matches(new RegExp("^(".concat(googleStoreUrl[process.env.NODE_ENV], "audios)")), '外部URLは入力できません')
  }).when('type', {
    is: mediaTypes.VIDEO,
    then: string().matches(new RegExp("^(".concat(googleStoreUrl[process.env.NODE_ENV], "videos)")), '外部URLは入力できません')
  }).when(['$platform', '$intl'], function (platform, intl, schema) {
    return addMaxLength(schema, intl, 'common.url', platform);
  }),
  name: string().required('必須')
});

var maxFileSizeErrorMessage = function maxFileSizeErrorMessage(maxFileSize) {
  return "\u30D5\u30A1\u30A4\u30EB\u30B5\u30A4\u30BA\u304C".concat(maxFileSize / (K_BYTES * K_BYTES), "MB\u4EE5\u4E0B\u3067\u3042\u308B\u5FC5\u8981\u304C\u3042\u308A\u307E\u3059");
};

export var mediaFileSchema = function mediaFileSchema(_ref) {
  var MAX_PREVIEW_IMAGE_FILE_SIZE_BYTES = _ref.MAX_PREVIEW_IMAGE_FILE_SIZE_BYTES,
      MAX_MEDIA_FILE_SIZE_BYTES = _ref.MAX_MEDIA_FILE_SIZE_BYTES,
      PREVIEW_IMAGE_TYPES = _ref.PREVIEW_IMAGE_TYPES;
  return object({
    size: number().when('$isPreview', {
      is: function is(isPreview) {
        return isPreview && MAX_PREVIEW_IMAGE_FILE_SIZE_BYTES;
      },
      then: number().max(MAX_PREVIEW_IMAGE_FILE_SIZE_BYTES, maxFileSizeErrorMessage(MAX_PREVIEW_IMAGE_FILE_SIZE_BYTES))
    }).when(['$isPreview', '$mediaType'], {
      is: function is(isPreview, mediaType) {
        return !isPreview && [mediaTypes.AUDIO, mediaTypes.VIDEO].includes(mediaType) && MAX_MEDIA_FILE_SIZE_BYTES;
      },
      then: number().max(MAX_MEDIA_FILE_SIZE_BYTES, maxFileSizeErrorMessage(MAX_MEDIA_FILE_SIZE_BYTES))
    }),
    type: string().when('$isPreview', {
      is: function is(isPreview) {
        return isPreview && PREVIEW_IMAGE_TYPES;
      },
      then: string().matches(new RegExp((PREVIEW_IMAGE_TYPES || '').split(',').join('|')), "\u30D5\u30A1\u30A4\u30EB\u5F62\u5F0F\u306F".concat(PREVIEW_IMAGE_TYPES, "\u3067\u3042\u308B\u5FC5\u8981\u304C\u3042\u308A\u307E\u3059"))
    }).when('$mediaType', {
      is: function is(mediaType) {
        return mediaType === mediaTypes.IMAGE;
      },
      then: string().matches(new RegExp(mediaAcceptTypes[mediaTypes.IMAGE].split(',').join('|')), "\u5BFE\u5FDC\u3057\u3066\u3044\u306A\u3044\u62E1\u5F35\u5B50\u3067\u3059")
    })
  });
};
export var mediaFilesSchema = function mediaFilesSchema(limits) {
  return object({
    files: array().of(mediaFileSchema(limits))
  });
}; // Add validate max length for schema

export var addMaxLength = function addMaxLength(schema, intl, path, platform) {
  var maxLength = get(limitations[platform], "MAX_LENGTH_INPUT_FIELD.".concat(path));
  if (!maxLength) return schema;
  var errorMessage = intl.formatMessage({
    id: "xdJSSH",
    defaultMessage: [{
      "type": 0,
      "value": "Please set within "
    }, {
      "type": 1,
      "value": "maxLength"
    }, {
      "type": 0,
      "value": " characters."
    }]
  }, {
    maxLength: maxLength
  }); // Because when string contain emoji charater, it need to count length by
  // convert to array, emoji character will count length as 1

  return schema.test('customMaxLength', errorMessage, function (value) {
    return Array.from(value || '').length <= maxLength;
  });
};
export var addMaxLine = function addMaxLine(schema, intl, path, platform) {
  var maxLine = get(limitations[platform], "MAX_LINE_INPUT_FIELD.".concat(path));
  if (!maxLine) return schema;
  var errorMessage = intl.formatMessage({
    id: "02u3Cm",
    defaultMessage: [{
      "type": 0,
      "value": "please limit the number of lines to "
    }, {
      "type": 1,
      "value": "maxLine"
    }, {
      "type": 0,
      "value": " lines"
    }]
  }, {
    maxLine: maxLine
  });
  return schema.test('checkMaxLine', errorMessage, function (value) {
    return (((value === null || value === void 0 ? void 0 : value.toString()) || '').match(/\n/g) || []).length < maxLine;
  });
};
export var filterSchema = function filterSchema(_ref2) {
  var otherFilterNames = _ref2.otherFilterNames,
      intl = _ref2.intl;
  return object({
    name: string().required(intl.formatMessage({
      id: "HGJGjB",
      defaultMessage: [{
        "type": 0,
        "value": "Please enter a Filter Name"
      }]
    })).notOneOf(otherFilterNames, intl.formatMessage({
      id: "agwvFz",
      defaultMessage: [{
        "type": 0,
        "value": "This Filter Name already exists."
      }]
    })),
    attributeAnswerIds: array().min(1, intl.formatMessage({
      id: "/n2QWd",
      defaultMessage: [{
        "type": 0,
        "value": "Please select a Tag"
      }]
    }))
  });
};
export var checkHostUrl = function checkHostUrl(schema, intl) {
  return schema.test('checkHostMatch', intl.formatMessage({
    id: "aiDaR5",
    defaultMessage: [{
      "type": 0,
      "value": "Please enter a valid URL."
    }]
  }), function (value) {
    var host;

    try {
      var url = new URL(value);
      host = url.hostname;
    } catch (e) {
      host = '';
    }

    return hostReg.test(host);
  });
};