var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import styled from 'styled-components';
import { colors } from '@src/colors';
import { centeredFlex, setWidthHeight, setMargin, pseudoBefore, } from '@src/lib/mixins';
// return normal or error border
var getBorderCSS = function (border, hasError) {
    return hasError ? "1px solid " + colors.border.error : border;
};
var getColor = function (isButton, color) {
    if (color)
        return color;
    return isButton ? "" + colors.facebook.messenger : "" + colors.text_pale;
};
var getTextAlign = function (isButton, textAlign) {
    if (textAlign)
        return textAlign;
    return isButton ? 'center' : 'unset';
};
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: relative;\n  width: ", ";\n  height: ", ";\n  margin: ", ";\n  padding: ", ";\n  color: ", ";\n  background: ", ";\n  border: ", ";\n  border-width: ", ";\n  border-radius: ", ";\n  justify-content: ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  display: inline-flex;\n  &::before {\n    position: relative;\n    margin-right: 8px;\n  }\n  #leading-icon,\n  #leading-icon {\n    font-size: 14px;\n  }\n  input,\n  select {\n    width: 100%;\n    height: 100%;\n    padding: ", ";\n    font-size: ", ";\n    color: ", ";\n    letter-spacing: 0;\n    line-height: 14px;\n    pointer-events: ", ";\n  }\n  input {\n    width: 100%;\n    height: 100%;\n    padding: ", ";\n    text-align: ", ";\n    &::placeholder {\n      color: ", ";\n    }\n  }\n"], ["\n  position: relative;\n  width: ", ";\n  height: ", ";\n  margin: ", ";\n  padding: ", ";\n  color: ", ";\n  background: ", ";\n  border: ",
    ";\n  border-width: ",
    ";\n  border-radius: ", ";\n  justify-content: ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  display: inline-flex;\n  &::before {\n    position: relative;\n    margin-right: 8px;\n  }\n  #leading-icon,\n  #leading-icon {\n    font-size: 14px;\n  }\n  input,\n  select {\n    width: 100%;\n    height: 100%;\n    padding: ", ";\n    font-size: ", ";\n    color: ", ";\n    letter-spacing: 0;\n    line-height: 14px;\n    pointer-events: ", ";\n  }\n  input {\n    width: 100%;\n    height: 100%;\n    padding: ", ";\n    text-align: ", ";\n    &::placeholder {\n      color: ", ";\n    }\n  }\n"])), function (props) { return (props.width ? "" + props.width : ''); }, function (props) { return (props.height ? props.height + "px" : ''); }, function (props) { return (props.margin ? "" + props.margin : ''); }, function (props) { return (props.padding ? "" + props.padding : ''); }, function (props) { return getColor(props.isButton, props.color); }, colors.background.main_menu, function (props) {
    var showBorderOnFocus = props.showBorderOnFocus, focused = props.focused;
    var currentBorder = getBorderCSS(props.border, props.hasError);
    if (showBorderOnFocus) {
        return focused ? currentBorder : 'none';
    }
    return currentBorder;
}, function (props) {
    return props.borderWidth ? "" + props.borderWidth : '1px';
}, function (props) { return props.borderRadius || '5px'; }, function (props) { return (props.isButton ? 'center' : 'unset'); }, setWidthHeight, setMargin, centeredFlex, pseudoBefore, function (props) { return (props.padding ? "" + props.padding : ''); }, function (props) { return props.fontSize || '12px'; }, function (props) { return getColor(props.isButton, props.color); }, function (props) { return (props.displayOnly ? 'none' : 'unset'); }, function (props) { return (props.padding ? "" + props.padding : ''); }, function (props) { return getTextAlign(props.isButton, props.textAlign); }, colors.placeholder);
var defaultProps = {
    width: '100%',
    height: 40,
    padding: '0 12px',
    margin: '0 auto',
    border: "1px solid " + colors.border.default,
    color: "" + colors.default_text,
    textAlign: 'unset',
    hasError: false,
};
var Input = function (_a) {
    var _b = _a.className, className = _b === void 0 ? '' : _b, children = _a.children, props = __rest(_a, ["className", "children"]);
    return (React.createElement(Container, __assign({}, props, { className: className }), children));
};
Input.defaultProps = defaultProps;
export { Input as default };
var templateObject_1;
