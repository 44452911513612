var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { DatePicker, TimePicker } from '@zeals-co-ltd/washi-components';
import styled from 'styled-components';
import { injectIntl } from 'react-intl';
import * as washiColors from '@zeals-co-ltd/washi-styles';
import moment from 'moment';
var datePickerStyle = {
    zIndex: 1300,
};
var DateTimeRangeContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  @media (min-width: 1320px) {\n    display: flex;\n  }\n\n  @media (max-width: 1319px) {\n    > div:nth-child(2) {\n      margin-top: 8px;\n    }\n  }\n\n  .MuiFormControl-root {\n    pointer-events: ", ";\n\n    input {\n      color: ", ";\n    }\n  }\n\n  .MuiSvgIcon-root {\n    font-size: 20px;\n  }\n\n  .MuiOutlinedInput-input {\n    padding: 12px 14px 12px 12px;\n    font-size: 14px;\n  }\n\n  .MuiOutlinedInput-root {\n    padding-right: 10px;\n  }\n\n  > div:nth-child(1) {\n    margin-right: 24px;\n  }\n\n  > div {\n    > div:first-child {\n      font-size: 14px;\n    }\n  }\n"], ["\n  @media (min-width: 1320px) {\n    display: flex;\n  }\n\n  @media (max-width: 1319px) {\n    > div:nth-child(2) {\n      margin-top: 8px;\n    }\n  }\n\n  .MuiFormControl-root {\n    pointer-events: ", ";\n\n    input {\n      color: ", ";\n    }\n  }\n\n  .MuiSvgIcon-root {\n    font-size: 20px;\n  }\n\n  .MuiOutlinedInput-input {\n    padding: 12px 14px 12px 12px;\n    font-size: 14px;\n  }\n\n  .MuiOutlinedInput-root {\n    padding-right: 10px;\n  }\n\n  > div:nth-child(1) {\n    margin-right: 24px;\n  }\n\n  > div {\n    > div:first-child {\n      font-size: 14px;\n    }\n  }\n"])), function (props) { return (props.showOnly ? 'none' : 'auto'); }, function (props) { return (props.showOnly ? washiColors.grey[500] : ''); });
var DateTimePickerContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n\n  > div:first-child {\n    margin-right: 6px;\n  }\n"], ["\n  display: flex;\n\n  > div:first-child {\n    margin-right: 6px;\n  }\n"])));
var DescriptionText = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-size: 12px;\n  font-weight: 400;\n  letter-spacing: 0.8px;\n  color: ", ";\n  margin-bottom: 16px;\n"], ["\n  font-size: 12px;\n  font-weight: 400;\n  letter-spacing: 0.8px;\n  color: ", ";\n  margin-bottom: 16px;\n"])), washiColors.grey[600]);
var toTimeString = function (time) {
    return time ? moment(time).utcOffset('+09:00').format('HH:mm') : null;
};
var DateTimeRange = function (_a) {
    var showOnly = _a.showOnly, handleChangeSchedule = _a.handleChangeSchedule, schedule = _a.schedule, intl = _a.intl;
    var initStartDate = schedule ? new Date(schedule === null || schedule === void 0 ? void 0 : schedule.start_time) : null;
    var initEndDate = schedule ? new Date(schedule === null || schedule === void 0 ? void 0 : schedule.end_time) : null;
    var _b = __read(useState(initStartDate), 2), startDate = _b[0], setStartDate = _b[1];
    var _c = __read(useState(initEndDate), 2), endDate = _c[0], setEndDate = _c[1];
    var _d = __read(useState(toTimeString(schedule === null || schedule === void 0 ? void 0 : schedule.start_time)), 2), startTime = _d[0], setStartTime = _d[1];
    var _e = __read(useState(toTimeString(schedule === null || schedule === void 0 ? void 0 : schedule.start_time)), 2), endTime = _e[0], setEndTime = _e[1];
    var _f = __read(useState(false), 2), openStartTimeOptions = _f[0], setOpenStartTimeOptions = _f[1];
    var _g = __read(useState(false), 2), openEndTimeOptions = _g[0], setOpenEndTimeOptions = _g[1];
    var language = useSelector(function (state) { return state.account.language; });
    var handleChangeScheduleTime = function (date, time, attr) {
        if (!date || !time) {
            handleChangeSchedule("schedule." + attr, null);
            return;
        }
        var hourAndMinutes = time.split(':');
        var second = attr === 'start_time' ? 5 : 0;
        var dateMoment = moment(date).utcOffset('+09:00').set({
            hour: hourAndMinutes[0],
            minute: hourAndMinutes[1],
            second: second,
        });
        handleChangeSchedule("schedule." + attr, dateMoment.toDate().getTime());
    };
    var handleDateChange = function (e, isStartDate) {
        var isChangeEvent = 'type' in e;
        var isDate = 'getMonth' in e;
        if (isChangeEvent) {
            var event = e;
            var date = event.target.value === '' ? null : new Date(event.target.value);
            if (isStartDate) {
                setStartDate(date);
            }
            else {
                setEndDate(date);
            }
        }
        if (isDate) {
            if (isStartDate) {
                setStartDate(e);
            }
            else {
                setEndDate(e);
            }
        }
    };
    var handleTimeChange = function (e, isStartTime) {
        var eventTarget = e.target;
        if (isStartTime) {
            setStartTime(eventTarget.value);
        }
        else {
            setEndTime(eventTarget.value);
        }
    };
    useEffect(function () { return handleChangeScheduleTime(startDate, startTime, 'start_time'); }, [startDate, startTime]);
    useEffect(function () { return handleChangeScheduleTime(endDate, endTime, 'end_time'); }, [
        endDate,
        endTime,
    ]);
    useEffect(function () {
        if (!schedule)
            return;
        setStartDate(initStartDate);
        setEndDate(initEndDate);
        setStartTime(toTimeString(schedule === null || schedule === void 0 ? void 0 : schedule.start_time));
        setEndTime(toTimeString(schedule === null || schedule === void 0 ? void 0 : schedule.end_time));
    }, [showOnly]);
    return (React.createElement(React.Fragment, null,
        React.createElement(DescriptionText, null, intl.formatMessage({ id: "EMdVYv", defaultMessage: "Set an optional Default Rich Menu schedule." })),
        React.createElement(DateTimeRangeContainer, { "data-testid": "DateTimeRange", showOnly: showOnly },
            React.createElement("div", null,
                React.createElement("div", null, intl.formatMessage({ id: "dM+p3/", defaultMessage: "From" })),
                React.createElement(DateTimePickerContainer, null,
                    React.createElement(DatePicker, { onChange: function (e) { return handleDateChange(e, true); }, selectedDate: startDate, popperStyle: datePickerStyle, locale: language, "data-testid": "DateTimeRange_StartDatePicker", isDisablePast: true }),
                    React.createElement(TimePicker, { minuteIncrement: "half", disabled: showOnly, onTimeChange: function (e) { return handleTimeChange(e, true); }, onTimeClick: function () { return setOpenStartTimeOptions(true); }, onClickOut: function () { return setOpenStartTimeOptions(false); }, timeInput: startTime, isDisplayTimeOptions: openStartTimeOptions, "data-testid": "DateTimeRange_StartTimePicker" }))),
            React.createElement("div", null,
                React.createElement("div", null, intl.formatMessage({ id: "9j3hXO", defaultMessage: "To" })),
                React.createElement(DateTimePickerContainer, null,
                    React.createElement(DatePicker, { selectedDate: endDate, onChange: function (e) { return handleDateChange(e, false); }, popperStyle: datePickerStyle, locale: language, "data-testid": "DateTimeRange_EndDatePicker", isDisablePast: true }),
                    React.createElement(TimePicker, { minuteIncrement: "half", disabled: showOnly, onTimeChange: function (e) { return handleTimeChange(e, false); }, onTimeClick: function () { return setOpenEndTimeOptions(true); }, onClickOut: function () { return setOpenEndTimeOptions(false); }, timeInput: endTime, isDisplayTimeOptions: openEndTimeOptions, "data-testid": "DateTimeRange_EndTimePicker" }))))));
};
export default injectIntl(DateTimeRange);
var templateObject_1, templateObject_2, templateObject_3;
