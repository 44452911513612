var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState } from 'react';
import { injectIntl, useIntl } from 'react-intl';
import { formAutomationMessages } from '@src/i18n/translations';
import { Tooltip, Checkbox, Dialog } from '@zeals-co-ltd/washi-components';
import { FormAutomationStepAction } from 'zeals-protobuf/zeals/web/entities/form_automation_pb';
import { automationActionToString } from '@src/lib/formAutomation';
import UserFieldControl from '../UserFieldControl';
import TextInputStep from './TextInputStep';
import SelectInputStep from './SelectInputStep';
import RadioInputStep from './RadioInputStep';
import ClickStep from './ClickStep';
import NavigationWaitStep from './NavigationWaitStep';
import ClickByValueStep from './ClickByValueStep';
import MagicalClickStep from './MagicalClickStep';
import TimeWaitStep from './TimeWaitStep';
import ElementWaitStep from './ElementWaitStep';
import { Container, GreyText, useCheckboxStyles, } from './FormAutomationStepItem.styles';
import CheckboxInputStep from './CheckboxInputStep';
export var findField = function (fields, fieldId) { return fields === null || fields === void 0 ? void 0 : fields.find(function (f) { return f.id === fieldId; }); };
var ActionIcon = injectIntl(function (props) {
    var actionStr = automationActionToString(props.type);
    return (React.createElement(Tooltip, { arrow: true, title: props.intl.formatMessage(formAutomationMessages[actionStr]) },
        React.createElement("div", { className: "step-icon" },
            React.createElement("div", { className: "step-icon-body" },
                React.createElement("span", { className: "adicon_" + actionStr })))));
});
export var NotRequiredField = injectIntl(function (_a) {
    var intl = _a.intl;
    return (React.createElement(React.Fragment, null,
        React.createElement(GreyText, { "data-testid": "FormAutomationStepItem-NotRequired" }, intl.formatMessage({ id: "WlfPoK", defaultMessage: "Not required" })),
        React.createElement("span", { className: "adicon_block" })));
});
export function SettingsText(props) {
    var completed = props.completed;
    var intl = useIntl();
    return (React.createElement(React.Fragment, null,
        completed ? (React.createElement("span", { className: "value-type-text" }, intl.formatMessage({ id: "NGpBaV", defaultMessage: "Settings completed" }))) : (React.createElement(GreyText, null, intl.formatMessage({ id: "p5LNtB", defaultMessage: "Not set" }))),
        React.createElement("span", { className: "adicon_edit_after" })));
}
export function StepItemsHeader() {
    var intl = useIntl();
    return (React.createElement(Container, { hasError: false, className: "step-items-header" },
        React.createElement("div", { className: "step-label icon-header" }, intl.formatMessage({ id: "dANS8k", defaultMessage: "Flow" })),
        React.createElement("div", { className: "step-label action-header" }, intl.formatMessage({ id: "AbvQFb", defaultMessage: "Field" })),
        React.createElement("div", { className: "step-label css-selector-header" }, intl.formatMessage({ id: "fQ/91p", defaultMessage: "CSS Selector" })),
        React.createElement("div", { className: "step-label type-header" }, intl.formatMessage({ id: "+U6ozc", defaultMessage: "Type" })),
        React.createElement("div", { className: "step-label options-header" }, intl.formatMessage({ id: "NDV5Mq", defaultMessage: "Options" })),
        React.createElement("div", { className: "step-label skippable-header" }, intl.formatMessage({ id: "oN2yPt", defaultMessage: "Skippable" }))));
}
// Each FormAutomationStep Component Wrapper displays data depending on the details
// of the FormAutomationStep interface
// Each also provides one common onChange prop which provides the updated data
// as the same FormAutomationStep interface
export function SwitchStepContent(props) {
    switch (props.step.actionType) {
        case FormAutomationStepAction.FORM_AUTOMATION_STEP_ACTION_TEXT_INPUT:
            return React.createElement(TextInputStep, __assign({}, props));
        case FormAutomationStepAction.FORM_AUTOMATION_STEP_ACTION_SELECT_INPUT:
            return React.createElement(SelectInputStep, __assign({}, props));
        case FormAutomationStepAction.FORM_AUTOMATION_STEP_ACTION_CLICK:
            return React.createElement(ClickStep, __assign({}, props));
        case FormAutomationStepAction.FORM_AUTOMATION_STEP_ACTION_TIME_WAIT:
            return React.createElement(TimeWaitStep, __assign({}, props));
        case FormAutomationStepAction.FORM_AUTOMATION_STEP_ACTION_ELEMENT_WAIT:
            return React.createElement(ElementWaitStep, __assign({}, props));
        case FormAutomationStepAction.FORM_AUTOMATION_STEP_ACTION_RADIO_INPUT:
            return React.createElement(RadioInputStep, __assign({}, props));
        case FormAutomationStepAction.FORM_AUTOMATION_STEP_ACTION_NAVIGATION_WAIT:
            return React.createElement(NavigationWaitStep, __assign({}, props));
        case FormAutomationStepAction.FORM_AUTOMATION_STEP_ACTION_CLICK_BY_VALUE:
            return React.createElement(ClickByValueStep, __assign({}, props));
        case FormAutomationStepAction.FORM_AUTOMATION_STEP_ACTION_MAGICAL_CLICK:
            return React.createElement(MagicalClickStep, __assign({}, props));
        case FormAutomationStepAction.FORM_AUTOMATION_STEP_ACTION_CHECKBOX_INPUT:
            return React.createElement(CheckboxInputStep, __assign({}, props));
        case FormAutomationStepAction.FORM_AUTOMATION_STEP_ACTION_UNSPECIFIED:
            return null;
        default:
            return null;
    }
}
export default injectIntl(function (props) {
    var _a = __read(useState(false), 2), isModalOpen = _a[0], setIsModalOpen = _a[1];
    var onChange = props.onChange, step = props.step, errorMsg = props.errorMsg, onDeleteClick = props.onDeleteClick, intl = props.intl, itemRef = props.itemRef;
    var checkboxStyles = useCheckboxStyles();
    var actionStr = automationActionToString(step.actionType);
    var toggleDisplayDeleteModal = function () {
        setIsModalOpen(!isModalOpen);
    };
    return (React.createElement(Container, { hasError: Boolean(errorMsg), className: "form-automation-step-item step-" + actionStr, "data-testid": "FormAutomationStepItem-Row-" + actionStr, ref: itemRef },
        isModalOpen && (React.createElement(Dialog, { open: true, hasIcon: true, dialogType: "error", onClose: function () { return toggleDisplayDeleteModal(); }, title: intl.formatMessage({ id: "y4CLsH", defaultMessage: "Form Automation Step\u3092\u524A\u9664" }), mainButtonLabel: intl.formatMessage({ id: "OWdvZ4", defaultMessage: "\u306F\u3044\u3001Form Automation Step\u3092\u524A\u9664" }), mainButtonOnClick: function () { return onDeleteClick(step.id); }, cancelButtonLabel: intl.formatMessage({ id: "Ychdd4", defaultMessage: "\u3044\u3044\u3048" }), supportText: intl.formatMessage({ id: "tRCF5F", defaultMessage: "Are you sure you want to delete this step?" }) })),
        React.createElement("div", { className: "icon" },
            React.createElement(ActionIcon, { type: step.actionType })),
        React.createElement(SwitchStepContent, __assign({}, props)),
        React.createElement("div", { className: "skippable" },
            React.createElement(Checkbox, { labelClassName: checkboxStyles.root, color: "primary", "data-testid": "FormAutomationStepItem-SkippableCheckbox", checked: step === null || step === void 0 ? void 0 : step.skippable, onChange: function () {
                    return onChange(__assign(__assign({}, step), { skippable: !(step === null || step === void 0 ? void 0 : step.skippable) }));
                } })),
        React.createElement("div", { className: "delete-icon" },
            React.createElement(UserFieldControl, { "data-testid": "FormAutomationStepItem-DeleteButton", className: "user-field-control", onClickDelete: function () { return toggleDisplayDeleteModal(); } })),
        errorMsg && React.createElement("p", { className: "error" }, errorMsg)));
});
