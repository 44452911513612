export var FormTypes;
(function (FormTypes) {
    FormTypes["SET_PAGINATION_FORM_IDS"] = "SET_PAGINATION_FORM_IDS";
    FormTypes["SET_FIELD_COLLECTION"] = "SET_FIELD_COLLECTION";
    FormTypes["UPDATE_FIELD"] = "UPDATE_FIELD";
    FormTypes["DELETE_FIELD"] = "DELETE_FIELD";
    FormTypes["SET_FORM"] = "SET_FORM";
    FormTypes["SET_FORMS"] = "SET_FORMS";
    FormTypes["SET_FORM_ELEMENT"] = "SET_FORM_ELEMENT";
    FormTypes["SET_SELECT_INPUT_OPTIONS"] = "SET_SELECT_INPUT_OPTIONS";
    FormTypes["SET_FORM_TEMPLATES"] = "SET_FORM_TEMPLATES";
    FormTypes["ADD_FORMS"] = "ADD_FORMS";
    FormTypes["UPDATE_FORM_NAME"] = "UPDATE_FORM_NAME";
    FormTypes["UPDATE_FORM"] = "UPDATE_FORM";
    FormTypes["DELETE_FORM"] = "DELETE_FORM";
    FormTypes["SET_PAGINATION"] = "SET_PAGINATION";
    FormTypes["SET_FORMS_IS_DRAFT"] = "SET_FORMS_IS_DRAFT";
    FormTypes["FILTER_FORMS"] = "FILTER_FORMS";
    FormTypes["SET_FIELD_ORDER"] = "SET_FIELD_ORDER";
    FormTypes["ADD_FIELD"] = "ADD_FIELD";
    FormTypes["UPDATE_WEBFORM_NAME"] = "UPDATE_WEBFORM_NAME";
    FormTypes["SET_WEBFORM_TABLE_ROW_ORDER_SCRAPER_GROUP"] = "SET_WEBFORM_TABLE_ROW_ORDER_SCRAPER_GROUP";
    FormTypes["SET_FORM_ELEMENT_TEMPLATES"] = "SET_FORM_ELEMENT_TEMPLATES";
    FormTypes["SAVE_NEW_FORM_NAME"] = "SAVE_NEW_FORM_NAME";
    FormTypes["SET_IS_SAVE_SUCCESS"] = "SET_IS_SAVE_SUCCESS";
    FormTypes["SET_FORM_ELEMENT_ERRORS"] = "SET_FORM_ELEMENT_ERRORS";
    FormTypes["UPDATE_IS_FETCHING_FORMS"] = "UPDATE_IS_FETCHING_FORMS";
    FormTypes["UPDATE_WEBFORM_LOGO_URL"] = "UPDATE_WEBFORM_LOGO_URL";
    FormTypes["SET_NEW_BLANK_FORM_ELEMENT"] = "SET_NEW_BLANK_FORM_ELEMENT";
    FormTypes["SET_NEW_BLANK_FORM_ELEMENT_WITH_INDEX"] = "SET_NEW_BLANK_FORM_ELEMENT_WITH_INDEX";
    FormTypes["REMOVE_EXIST_FORM_ELEMENT"] = "REMOVE_EXIST_FORM_ELEMENT";
    FormTypes["REMOVE_EXISTING_SCRAPER_GROUP"] = "REMOVE_EXISTING_SCRAPER_GROUP";
    FormTypes["HANDLE_NEW_SCRAPER_ELEMENTS"] = "HANDLE_NEW_SCRAPER_ELEMENTS";
    FormTypes["SET_FORM_BUILDS"] = "SET_FORM_BUILDS";
    FormTypes["DELETE_FORM_BUILD"] = "DELETE_FORM_BUILD";
    FormTypes["SET_NEW_FORM_BUILD"] = "SET_NEW_FORM_BUILD";
    FormTypes["UPDATE_FORM_BUILD"] = "UPDATE_FORM_BUILD";
    FormTypes["DELETE_FORM_SCENARIO_SEGMENT"] = "DELETE_FORM_SCENARIO_SEGMENT";
    FormTypes["DELETE_WEB_FORM_SEGMENT"] = "DELETE_WEB_FORM_SEGMENT";
    FormTypes["DELETE_EMPTY_SEGMENT"] = "DELETE_EMPTY_SEGMENT";
    FormTypes["ADD_BLANK_FORM_BUILD_SEGMENT"] = "ADD_BLANK_FORM_BUILD_SEGMENT";
    FormTypes["SET_FORM_BUILD"] = "SET_FORM_BUILD";
    FormTypes["SET_FORM_SCENARIO"] = "SET_FORM_SCENARIO";
    FormTypes["SET_WEBFORM_FIELDS_LIST"] = "SET_WEBFORM_FIELDS_LIST";
    FormTypes["SET_FORM_QUESTIONS_LIST"] = "SET_FORM_QUESTIONS_LIST";
})(FormTypes || (FormTypes = {}));
export var CompletedSetting;
(function (CompletedSetting) {
    CompletedSetting[CompletedSetting["UNDEFINED"] = 0] = "UNDEFINED";
    CompletedSetting[CompletedSetting["WEBFORM_SETTING"] = 1] = "WEBFORM_SETTING";
    CompletedSetting[CompletedSetting["FORM_CONVERSATION"] = 2] = "FORM_CONVERSATION";
    CompletedSetting[CompletedSetting["FORM_AUTOMATION"] = 3] = "FORM_AUTOMATION";
})(CompletedSetting || (CompletedSetting = {}));
