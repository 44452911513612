import "core-js/modules/es.symbol";
import "core-js/modules/es.array.concat";
import "core-js/modules/es.array.filter";
import "core-js/modules/es.array.map";
import "core-js/modules/es.object.get-own-property-descriptor";
import "core-js/modules/es.object.get-own-property-descriptors";
import "core-js/modules/es.object.keys";
import "core-js/modules/web.dom-collections.for-each";
import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

export var makeMsgWithNewArticle = function makeMsgWithNewArticle(_ref) {
  var message = _ref.message,
      itemId = _ref.itemId,
      updatedArticle = _ref.updatedArticle,
      key = _ref.key,
      val = _ref.val;
  return _objectSpread({}, message, {
    generic_template: _objectSpread({}, message.generic_template, {
      generic_items: message.generic_template.generic_items.map(function (item) {
        if (item.id === itemId) {
          return _objectSpread({}, item, {
            article: updatedArticle || _objectSpread({}, item.article, _defineProperty({}, key, val))
          });
        }

        return item;
      })
    })
  });
};
export var makeMsgWithNewText = function makeMsgWithNewText(_ref2) {
  var message = _ref2.message,
      text = _ref2.text;

  if (message.button_template) {
    return _objectSpread({}, message, {
      button_template: _objectSpread({}, message.button_template, {
        title: text
      })
    });
  }

  return _objectSpread({}, message, {
    text: text
  });
};
export var makeMsgWithNewItem = function makeMsgWithNewItem(_ref3) {
  var message = _ref3.message,
      updatedItem = _ref3.updatedItem,
      key = _ref3.key,
      val = _ref3.val;
  return _objectSpread({}, message, {
    generic_template: _objectSpread({}, message.generic_template, {
      generic_items: message.generic_template.generic_items.map(function (item) {
        if (item.id === updatedItem.id) {
          return updatedItem || _objectSpread({}, item, _defineProperty({}, key, val));
        }

        return item;
      })
    })
  });
};
export var makeMsgAddGenericItemButton = function makeMsgAddGenericItemButton(_ref4) {
  var message = _ref4.message,
      itemId = _ref4.itemId,
      genericItemButton = _ref4.genericItemButton;
  return _objectSpread({}, message, {
    generic_template: _objectSpread({}, message.generic_template, {
      generic_items: message.generic_template.generic_items.map(function (item) {
        if (item.id === itemId) {
          return _objectSpread({}, item, {
            generic_item_buttons: [].concat(_toConsumableArray(item.generic_item_buttons), [genericItemButton])
          });
        }

        return item;
      })
    })
  });
};
export var makeMsgRemoveGenericItemButton = function makeMsgRemoveGenericItemButton(_ref5) {
  var message = _ref5.message,
      itemId = _ref5.itemId,
      genericItemButtonId = _ref5.genericItemButtonId;
  return _objectSpread({}, message, {
    generic_template: _objectSpread({}, message.generic_template, {
      generic_items: message.generic_template.generic_items.map(function (item) {
        if (item.id === itemId) {
          return _objectSpread({}, item, {
            generic_item_buttons: item.generic_item_buttons.filter(function (btn) {
              return btn.id !== genericItemButtonId;
            })
          });
        }

        return item;
      })
    })
  });
};
export var makeMsgUpdateGenericItemButton = function makeMsgUpdateGenericItemButton(_ref6) {
  var message = _ref6.message,
      itemId = _ref6.itemId,
      genericItemButton = _ref6.genericItemButton;
  return _objectSpread({}, message, {
    generic_template: _objectSpread({}, message.generic_template, {
      generic_items: message.generic_template.generic_items.map(function (item) {
        if (item.id === itemId) {
          return _objectSpread({}, item, {
            generic_item_buttons: item.generic_item_buttons.map(function (btn) {
              if (btn.id === genericItemButton.id) return genericItemButton;
              return btn;
            })
          });
        }

        return item;
      })
    })
  });
};
export var makeMsgAddNewItem = function makeMsgAddNewItem(_ref7) {
  var message = _ref7.message,
      newItem = _ref7.newItem;
  return _objectSpread({}, message, {
    generic_template: _objectSpread({}, message.generic_template, {
      generic_items: [].concat(_toConsumableArray(message.generic_template.generic_items), [newItem])
    })
  });
};
export var makeMsgSetItems = function makeMsgSetItems(_ref8) {
  var message = _ref8.message,
      genericItems = _ref8.genericItems;
  return _objectSpread({}, message, {
    generic_template: _objectSpread({}, message.generic_template, {
      generic_items: genericItems
    })
  });
};
export var makeMsgWithNewMedium = function makeMsgWithNewMedium(_ref9) {
  var message = _ref9.message,
      medium = _ref9.medium,
      key = _ref9.key,
      val = _ref9.val;
  return _objectSpread({}, message, {
    medium: medium || _objectSpread({}, message.medium, _defineProperty({}, key, val))
  });
};
export var makeMsgButtonTemplate = function makeMsgButtonTemplate(_ref10) {
  var message = _ref10.message,
      buttonItem = _ref10.buttonItem;

  if (message.button_template) {
    return _objectSpread({}, message, {
      button_template: _objectSpread({}, message.button_template, {
        button_items: [].concat(_toConsumableArray(message.button_template.button_items), [buttonItem])
      })
    });
  }

  return _objectSpread({}, message, {
    button_template: {
      id: null,
      title: message.text || '',
      button_items: [buttonItem]
    }
  });
};
export var makeMsgButtonTemplateUpdate = function makeMsgButtonTemplateUpdate(_ref11) {
  var message = _ref11.message,
      buttonItem = _ref11.buttonItem;
  return _objectSpread({}, message, {
    button_template: _objectSpread({}, message.button_template, {
      button_items: message.button_template.button_items.map(function (item) {
        if (item.id === buttonItem.id) return buttonItem;
        return item;
      })
    })
  });
};
export var makeMsgRemoveButton = function makeMsgRemoveButton(_ref12) {
  var message = _ref12.message,
      buttonItemId = _ref12.buttonItemId;

  if (message.button_template && message.button_template.button_items && message.button_template.button_items.length > 1) {
    return _objectSpread({}, message, {
      button_template: _objectSpread({}, message.button_template, {
        button_items: message.button_template.button_items.filter(function (item) {
          return item.id !== buttonItemId;
        })
      })
    });
  }

  return _objectSpread({}, message, {
    text: message.button_template.title,
    button_template: null
  });
};