import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { injectIntl } from 'react-intl';
import { ModalPortal } from '@src/components/atoms';
import modalTypes from '@src/lib/modalTypes';
import Modal from '@src/components/molecules/Modal';
import MainMenu from './MainMenu';
var BotSettingSampleMain = styled.div.withConfig({
  displayName: "BotSettingSampleMain",
  componentId: "sc-cfc8y7-0"
})(["display:inline-block;width:320px;height:570px;background-repeat:no-repeat;background-size:100%;background-image:url(/images/img_persistent_menu_sample.png);"]);

var MainMenuModal = function MainMenuModal(props) {
  var showMainMenu = props.showMainMenu,
      toggleMainMenu = props.toggleMainMenu,
      chatbotId = props.chatbotId;
  return React.createElement("div", null, showMainMenu && React.createElement(ModalPortal, null, React.createElement(Modal, {
    "data-testid": "main-menu-modal",
    width: "960px",
    height: "730px",
    maxWidth: "1000px",
    type: modalTypes.TEST,
    onCancel: toggleMainMenu,
    overFlow: true,
    disableOnClickOutside: true
  }, React.createElement(MainMenu, {
    chatbotIdParam: parseInt(chatbotId)
  }), React.createElement(BotSettingSampleMain, null))));
};

MainMenuModal.propTypes = {
  intl: PropTypes.object,
  showMainMenu: PropTypes.bool.isRequired,
  toggleMainMenu: PropTypes.func.isRequired,
  chatbotId: PropTypes.string,
  persistentMenuList: PropTypes.array,
  showUpdateSuccessAlert: PropTypes.func,
  fetchSettings: PropTypes.func
};
export default injectIntl(MainMenuModal);