var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { makeFetchInit, handleAPIErrors, } from '@src/lib/helpers';
import endpoints from '@src/endpoints';
import { adUserFilter } from '@src/redux/fanp/constants';
import { editScenarioProperty } from '@src/redux/fanp/actions/ad-scenario';
import * as attributeQuestionApi from '@src/services/api/attributeQuestion';
import * as attributeAnswerApi from '@src/services/api/attributeAnswer';
import * as quickFilterApi from '@src/services/api/quickFilters';
import * as endUserApi from '@src/services/api/endUser';
import * as filterApi from '@src/services/api/filters';
var ATTRIBUTTE_QUESTIONS_AJAX_FULFILLED = adUserFilter.ATTRIBUTTE_QUESTIONS_AJAX_FULFILLED, ATTRIBUTTE_ANSWERS_AJAX_FULFILLED = adUserFilter.ATTRIBUTTE_ANSWERS_AJAX_FULFILLED, AD_GET_QUICK_FILTERS = adUserFilter.AD_GET_QUICK_FILTERS, 
// SET
AD_SET_FILTER_TAGS = adUserFilter.AD_SET_FILTER_TAGS, AD_SET_SEARCHED_USERS = adUserFilter.AD_SET_SEARCHED_USERS, SET_ERROR_GAP = adUserFilter.SET_ERROR_GAP, SET_MIN_END_USERS = adUserFilter.SET_MIN_END_USERS, SET_MAX_END_USERS = adUserFilter.SET_MAX_END_USERS, SET_USAGE_NUMBER = adUserFilter.SET_USAGE_NUMBER, SET_LIMIT_NUMBER = adUserFilter.SET_LIMIT_NUMBER, 
// ADD
AD_ADD_QUICK_FILTER = adUserFilter.AD_ADD_QUICK_FILTER, AD_ADD_QUICK_FILTER_TO_FILTER = adUserFilter.AD_ADD_QUICK_FILTER_TO_FILTER, 
// HANDLE AJAX RESPONSE
AD_HANDLE_USER_FILTER = adUserFilter.AD_HANDLE_USER_FILTER, AD_HANDLE_NEW_USER_FILTER = adUserFilter.AD_HANDLE_NEW_USER_FILTER, AD_SET_ATTRIBUTE_QUESTIONS = adUserFilter.AD_SET_ATTRIBUTE_QUESTIONS, AD_UPDATE_QUICK_FILTER = adUserFilter.AD_UPDATE_QUICK_FILTER, AD_UPDATE_QUICK_FILTER_SETTINGS = adUserFilter.AD_UPDATE_QUICK_FILTER_SETTINGS, 
// EDIT
AD_UPDATE_FILTER_PROP = adUserFilter.AD_UPDATE_FILTER_PROP, SET_UPDATED_FILTER = adUserFilter.SET_UPDATED_FILTER, 
// DELETE
AD_HANDLE_DELETE_FILTER_ACTION = adUserFilter.AD_HANDLE_DELETE_FILTER_ACTION, AD_DELETE_QUICK_FILTER_FROM_FILTER = adUserFilter.AD_DELETE_QUICK_FILTER_FROM_FILTER;
export var attributteQuestionsAjaxFulfilled = function (boolValue) { return ({
    type: ATTRIBUTTE_QUESTIONS_AJAX_FULFILLED,
    value: boolValue,
}); };
export var attributteAnswersAjaxFulfilled = function (boolValue) { return ({
    type: ATTRIBUTTE_ANSWERS_AJAX_FULFILLED,
    value: boolValue,
}); };
export var getQuickFilters = function (quickFilters) { return ({
    type: AD_GET_QUICK_FILTERS,
    quickFilters: quickFilters,
}); };
// SET
export var setSearchedUsers = function (endUserCount) { return ({
    type: AD_SET_SEARCHED_USERS,
    endUserCount: endUserCount,
}); };
// Add
export var addQuickFilterToFilter = function (quickFilter) { return ({
    type: AD_ADD_QUICK_FILTER_TO_FILTER,
    quickFilter: quickFilter,
}); };
// Handle
export var adHandleUserFilter = function (_a) {
    var filter = _a.filter;
    return {
        type: AD_HANDLE_USER_FILTER,
        filter: filter,
    };
};
export var adHandleNewUserFilter = function (filter) {
    return {
        type: AD_HANDLE_NEW_USER_FILTER,
        filter: filter,
    };
};
export var setAttributeAnswers = function (attributeAnswers) { return ({
    type: AD_SET_FILTER_TAGS,
    attributeAnswers: attributeAnswers,
}); };
export var setAttributeQuestions = function (attributeQuestions) {
    return {
        type: AD_SET_ATTRIBUTE_QUESTIONS,
        attributeQuestions: attributeQuestions,
    };
};
export var adUpdateQuickFilter = function (quickFilter, iterator) {
    return {
        type: AD_UPDATE_QUICK_FILTER,
        quickFilter: quickFilter,
        iterator: iterator,
    };
};
export var adUpdateQuickFilterSettings = function (quickFilter) { return ({
    type: AD_UPDATE_QUICK_FILTER_SETTINGS,
    quickFilter: quickFilter,
}); };
// Edit
export var setUpdatedFilter = function (filter) { return ({
    type: SET_UPDATED_FILTER,
    filter: filter,
}); };
export var adUpdateFilterProp = function (updatedPropObject, prop) {
    return {
        type: AD_UPDATE_FILTER_PROP,
        updatedPropObject: updatedPropObject,
        prop: prop,
    };
};
// Delete
export var settingDeleteQuickFilterFromFilter = function (quickFilter) { return ({
    type: AD_DELETE_QUICK_FILTER_FROM_FILTER,
    quickFilter: quickFilter,
}); };
export var adHandleDeleteFilterAction = function (filter) { return ({
    type: AD_HANDLE_DELETE_FILTER_ACTION,
    filter: filter,
}); };
// New get quick filters
export var fetchQuickFilter = function (_a) {
    var filterId = _a.filterId;
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        var quickFilters, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!filterId)
                        throw new Error('Fetching quick filters required a FilterID');
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, quickFilterApi.getFilters(filterId)];
                case 2:
                    quickFilters = _a.sent();
                    dispatch(getQuickFilters(quickFilters));
                    return [3 /*break*/, 4];
                case 3:
                    e_1 = _a.sent();
                    handleAPIErrors('Could not get quickFilters', e_1);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
};
// update Quick filter
export var fetchUpdateQuickFilter = function (_a) {
    var attributeAnswerId = _a.attributeAnswerId, quickFilterId = _a.quickFilterId, not = _a.not;
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        var quickFilter, e_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, quickFilterApi.updateFilter(attributeAnswerId, quickFilterId, not)];
                case 1:
                    quickFilter = _a.sent();
                    dispatch(adUpdateQuickFilterSettings(quickFilter));
                    return [3 /*break*/, 3];
                case 2:
                    e_2 = _a.sent();
                    handleAPIErrors('Could not update quickFilter', e_2);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
};
export var fetchUserFilter = function (_a) {
    var chatbotId = _a.chatbotId, filterId = _a.filterId;
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        var filter, e_3;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, filterApi.getUserFilter(chatbotId, filterId)];
                case 1:
                    filter = _a.sent();
                    dispatch(adHandleUserFilter({ filter: filter }));
                    return [3 /*break*/, 3];
                case 2:
                    e_3 = _a.sent();
                    console.error(e_3);
                    handleAPIErrors('Can not get user filter:', e_3.message);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
};
export var adHandleDeleteFilter = function (_a) {
    var chatbotId = _a.chatbotId, filterId = _a.filterId, scenarioId = _a.scenarioId;
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        var filter, e_4;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    if (!chatbotId || !filterId) {
                        throw new Error('Cannot delete filter without chatbot or filter');
                    }
                    return [4 /*yield*/, filterApi.deleteUserFilter(chatbotId, filterId)];
                case 1:
                    filter = _a.sent();
                    dispatch(adHandleDeleteFilterAction(filter));
                    if (scenarioId) {
                        dispatch(editScenarioProperty(scenarioId, {
                            filter_id: null,
                        }));
                    }
                    return [3 /*break*/, 3];
                case 2:
                    e_4 = _a.sent();
                    console.error(e_4);
                    handleAPIErrors('Can not delete user filter:', e_4.message);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
};
export var fetchFilterTags = function (chatbotId) { return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
    var attributeAnswers, e_5;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                dispatch(attributteAnswersAjaxFulfilled(true));
                _a.label = 1;
            case 1:
                _a.trys.push([1, 3, 4, 5]);
                return [4 /*yield*/, attributeAnswerApi.getFiltertags(chatbotId)];
            case 2:
                attributeAnswers = _a.sent();
                dispatch(setAttributeAnswers(attributeAnswers));
                return [3 /*break*/, 5];
            case 3:
                e_5 = _a.sent();
                handleAPIErrors('Tagsを取得できませんでした:', e_5);
                return [3 /*break*/, 5];
            case 4:
                dispatch(attributteAnswersAjaxFulfilled(false));
                return [7 /*endfinally*/];
            case 5: return [2 /*return*/];
        }
    });
}); }; };
export var fetchAttributeQuestions = function (chatbotId) { return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
    var attributeQuestions, e_6;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                dispatch(attributteQuestionsAjaxFulfilled(true));
                _a.label = 1;
            case 1:
                _a.trys.push([1, 3, , 4]);
                return [4 /*yield*/, attributeQuestionApi.getAttributeQuestions(chatbotId)];
            case 2:
                attributeQuestions = _a.sent();
                dispatch(setAttributeQuestions(attributeQuestions));
                dispatch(attributteQuestionsAjaxFulfilled(false));
                return [3 /*break*/, 4];
            case 3:
                e_6 = _a.sent();
                handleAPIErrors('Attribute Questionを取得できませんでした:', e_6.message);
                return [3 /*break*/, 4];
            case 4: return [2 /*return*/];
        }
    });
}); }; };
// New userFilter actions
export var setErrorGap = function (errorGap) { return ({
    type: SET_ERROR_GAP,
    errorGap: errorGap,
}); };
export var setMinEndUsers = function (minEndUsers) { return ({
    type: SET_MIN_END_USERS,
    minEndUsers: minEndUsers,
}); };
export var setMaxEndUsers = function (maxEndUsers) { return ({
    type: SET_MAX_END_USERS,
    maxEndUsers: maxEndUsers,
}); };
export var setUsageNumber = function (usageNumber) { return ({
    type: SET_USAGE_NUMBER,
    usageNumber: usageNumber,
}); };
export var setLimitNumber = function (limitNumber) { return ({
    type: SET_LIMIT_NUMBER,
    limitNumber: limitNumber,
}); };
// THUNKS
export var addTagToFilter = function (filterId, attributeAnswer, not) { return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
    var quickFilter, e_7;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, quickFilterApi.addTag(filterId, attributeAnswer, not)];
            case 1:
                quickFilter = _a.sent();
                dispatch(addQuickFilterToFilter(quickFilter));
                return [3 /*break*/, 3];
            case 2:
                e_7 = _a.sent();
                handleAPIErrors('タグフィルターが作成できませんでした: ', e_7);
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); }; };
// Delete quick filter from filter
export var deleteQuickFilterFromFilter = function (filter) { return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
    var quickFilter, e_8;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, quickFilterApi.removeTag(filter)];
            case 1:
                quickFilter = _a.sent();
                dispatch(settingDeleteQuickFilterFromFilter(quickFilter));
                return [3 /*break*/, 3];
            case 2:
                e_8 = _a.sent();
                handleAPIErrors('There was an error deleting a tag filter.', e_8);
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); }; };
// @param tag: {
//   id: number
//   label: string
//   quickFilterId: number
//   not?: boolean
// }
export var getMessageUsageAndLimitNumber = function (chatbotId) { return function (dispatch) {
    var url = endpoints.chatbot.collection(chatbotId);
    return fetch(url, makeFetchInit('GET'))
        .then(function (res) { return res.json(); })
        .then(function (_a) {
        var usage = _a.usage, limit = _a.limit;
        dispatch(setUsageNumber(usage || 0));
        dispatch(setLimitNumber(limit || 0));
    })
        .catch(function (e) {
        handleAPIErrors('There was an error getting message usage and limit', e);
        dispatch(setUsageNumber(0));
        dispatch(setLimitNumber(0));
    });
}; };
export var fetchFilteredUsers = function (chatbotId, filterId) { return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, end_user_count, error_gap, min, max, status, message, e_9;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 2, , 3]);
                return [4 /*yield*/, endUserApi.getFilteredEndUsers(chatbotId, { filterId: filterId })];
            case 1:
                _a = _b.sent(), end_user_count = _a.end_user_count, error_gap = _a.error_gap, min = _a.min, max = _a.max, status = _a.status, message = _a.message;
                if (status === 422) {
                    console.error(message);
                    throw new Error('Can not fetch any end_users!');
                }
                dispatch(setSearchedUsers(end_user_count || 0));
                dispatch(setErrorGap(error_gap || 0));
                dispatch(setMinEndUsers(min || 0));
                dispatch(setMaxEndUsers(max || 0));
                return [3 /*break*/, 3];
            case 2:
                e_9 = _b.sent();
                handleAPIErrors('There was an error getting end users', e_9);
                dispatch(setSearchedUsers(0));
                dispatch(setErrorGap(0));
                dispatch(setMinEndUsers(0));
                dispatch(setMaxEndUsers(0));
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); }; };
export var updateDateFilter = function (updatedPropObject, propName, chatbotId, filter) { return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
    var body, updatedFilter, e_10;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                filter[propName] = updatedPropObject[propName];
                body = {
                    filter: __assign({}, filter),
                };
                return [4 /*yield*/, filterApi.updateUserFilterDate(chatbotId, filter.id, body)];
            case 1:
                updatedFilter = _a.sent();
                dispatch(setUpdatedFilter(updatedFilter));
                return [3 /*break*/, 3];
            case 2:
                e_10 = _a.sent();
                console.error(e_10);
                handleAPIErrors('Can not update user filter date:', e_10.message);
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); }; };
export var updateFilter = function (chatbotId, propName, label, filter) { return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
    var body, updatedFilter, e_11;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                filter[propName] = label;
                body = __assign({}, filter);
                return [4 /*yield*/, filterApi.updateUserFilter(chatbotId, filter.id, body)];
            case 1:
                updatedFilter = _a.sent();
                dispatch(setUpdatedFilter(updatedFilter));
                return [3 /*break*/, 3];
            case 2:
                e_11 = _a.sent();
                console.error(e_11);
                handleAPIErrors('Can not update user filter:', e_11.message);
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); }; };
export var adCreateNewUserFilter = function (_a) {
    var chatbotId = _a.chatbotId, scenarioId = _a.scenarioId;
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        var filter, e_12;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, filterApi.createNewUserFilter(chatbotId, scenarioId)];
                case 1:
                    filter = _a.sent();
                    dispatch(adHandleNewUserFilter(filter));
                    if (scenarioId) {
                        dispatch(editScenarioProperty(scenarioId, { filter_id: filter === null || filter === void 0 ? void 0 : filter.id }));
                    }
                    return [3 /*break*/, 3];
                case 2:
                    e_12 = _a.sent();
                    console.error(e_12);
                    handleAPIErrors('Can not create new filter:', e_12);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
};
