var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import { colors, shadows } from '@src/colors';
import { transitions } from '@src/lib/animations';
import { injectIntl } from 'react-intl';
var ContentTagContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin-top: 12px;\n  border: 1px solid ", ";\n  border-radius: 5px;\n  padding: 8px;\n"], ["\n  margin-top: 12px;\n  border: 1px solid ", ";\n  border-radius: 5px;\n  padding: 8px;\n"])), colors.border.default);
var Container = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  .scenario-select {\n    margin-top: 12px;\n    max-height: 200px;\n    overflow: scroll;\n    width: 100%;\n  }\n"], ["\n  .scenario-select {\n    margin-top: 12px;\n    max-height: 200px;\n    overflow: scroll;\n    width: 100%;\n  }\n"])));
var TagWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  border: 1px solid ", ";\n  color: #091f6c;\n  border-radius: 5px;\n  margin-bottom: 8px;\n  width: 100%;\n  height: 50px;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  padding: 5px 10px;\n  box-shadow: ", ";\n  transition: ", ";\n  &:hover {\n    box-shadow: ", ";\n  }\n  .adicon_tag {\n    display: inline-flex;\n    font-size: 16px;\n    margin-right: 4px;\n  }\n  .buttons {\n    display: flex;\n    height: 100%;\n  }\n"], ["\n  border: 1px solid ", ";\n  color: #091f6c;\n  border-radius: 5px;\n  margin-bottom: 8px;\n  width: 100%;\n  height: 50px;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  padding: 5px 10px;\n  box-shadow: ", ";\n  transition: ", ";\n  &:hover {\n    box-shadow: ", ";\n  }\n  .adicon_tag {\n    display: inline-flex;\n    font-size: 16px;\n    margin-right: 4px;\n  }\n  .buttons {\n    display: flex;\n    height: 100%;\n  }\n"])), colors.border.default, shadows.small.light, transitions.normal, shadows.medium.mid);
var AddItemButton = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  border-radius: 50px;\n  margin-left: 4px;\n  color: ", ";\n  transition: ", ";\n  padding: 5px;\n  span {\n    font-size: 18px;\n    font-weight: bold;\n  }\n  .selector-text {\n    margin-left: 4px;\n    font-size: 14px;\n  }\n  &:hover {\n    background: ", ";\n    cursor: pointer;\n  }\n"], ["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  border-radius: 50px;\n  margin-left: 4px;\n  color: ", ";\n  transition: ", ";\n  padding: 5px;\n  span {\n    font-size: 18px;\n    font-weight: bold;\n  }\n  .selector-text {\n    margin-left: 4px;\n    font-size: 14px;\n  }\n  &:hover {\n    background: ", ";\n    cursor: pointer;\n  }\n"])), function (props) { return props.color; }, transitions.normal, colors.background.light_purple);
var DeliveryTagArea = function (_a) {
    var selectedAttributeQuestion = _a.selectedAttributeQuestion, attributeAnswers = _a.attributeAnswers, selectedTags = _a.selectedTags, searchTerm = _a.searchTerm, intl = _a.intl, handleAddSelectTag = _a.handleAddSelectTag;
    var getSelectedTag = function (tag) {
        return selectedTags.find(function (selectedTag) { return selectedTag.id === tag.id; });
    };
    var getTargetTagsWithGroupId = function (attributeAnswers) {
        if (selectedAttributeQuestion === 'all') {
            return attributeAnswers;
        }
        return attributeAnswers.filter(function (attributeAnswer) {
            return attributeAnswer.attribute_question_id === selectedAttributeQuestion;
        });
    };
    return (React.createElement(ContentTagContainer, null,
        React.createElement(Container, null,
            React.createElement("div", { className: "scenario-select" },
                React.createElement("div", null, 
                // TODO: attribute_answer_label(attribute_question_label)の形で表示したいがJSONが重すぎたので改善して実装したい
                getTargetTagsWithGroupId(attributeAnswers)
                    .filter(function (attributeAnswer) {
                    return attributeAnswer.label.includes(searchTerm);
                })
                    .map(function (attributeAnswer) {
                    var selectedTag = getSelectedTag(attributeAnswer);
                    return (React.createElement(TagWrapper, { key: "attributeAnswer-" + attributeAnswer.id + "-" + attributeAnswer.label },
                        React.createElement("div", { className: "label-wrapper" },
                            React.createElement("span", { className: "adicon_tag" }),
                            attributeAnswer.label),
                        React.createElement("div", { className: "buttons" },
                            React.createElement(AddItemButton, { color: colors.accent_color, onClick: function () {
                                    return handleAddSelectTag(attributeAnswer, false);
                                } },
                                selectedTag && !selectedTag.not ? (React.createElement("span", { className: "adicon_plus03", role: "img" })) : (React.createElement("span", { className: "adicon_plus01", role: "img" })),
                                React.createElement("span", { className: "selector-text" }, intl.formatMessage({ id: "2IacYt", defaultMessage: "Attached" }))),
                            React.createElement(AddItemButton, { color: colors.error, onClick: function () {
                                    return handleAddSelectTag(attributeAnswer, true);
                                } },
                                selectedTag && selectedTag.not ? (React.createElement("span", { className: "adicon_minus03", role: "img" })) : (React.createElement("span", { className: "adicon_minus01", role: "img" })),
                                React.createElement("span", { className: "selector-text" }, intl.formatMessage({ id: "Fbtw5x", defaultMessage: "Not Attached" }))))));
                }))))));
};
export default injectIntl(DeliveryTagArea);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
