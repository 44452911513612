// This module is responsible for taking plain-data and local app types
// associated with the Form Configuration functionality, communicating with
// the FormService, and returning types back as app types
//
// Presently this form service is a gRPC implementation
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { FormServiceClient } from 'zeals-protobuf/zeals/web/services/FormServiceClientPb';
import * as formPbService from 'zeals-protobuf/zeals/web/services/form_pb';
import { CompletionStatus, } from 'zeals-protobuf/zeals/web/entities/form_pb';
import { formFromObj, formPageFromObj, paginationFromObj, formBuildSegmentFromObj, questionFromScenarioQuestion, formQuestionFromObj, formAutomationTriggerFromObj, formNumericValidationFromObj, formTextValidationFromObj, } from '@src/lib/form';
function createFormServiceClient() {
    return new FormServiceClient(localStorage.getItem('FORM_SERVICE_URL'), null, null);
}
var getHeaders = function () {
    return { Authorization: localStorage.getItem('GRPC_JWT_TOKEN') };
};
// listFormBuildsRequest list form builds.
export function listFormBuildsRequest(chatbotId, searchTerm, options, completionStatus) {
    return __awaiter(this, void 0, Promise, function () {
        var client, request, res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    client = createFormServiceClient();
                    request = new formPbService.ListFormBuildsRequest();
                    request.setChatbotId(chatbotId);
                    if (searchTerm) {
                        request.setSearchKeyword(searchTerm);
                    }
                    if (completionStatus) {
                        request.setCompletionStatus(completionStatus);
                    }
                    if (options) {
                        request.setPagination(paginationFromObj(options));
                    }
                    return [4 /*yield*/, client.listFormBuilds(request, getHeaders())];
                case 1:
                    res = _a.sent();
                    return [2 /*return*/, res.toObject().formBuildsList];
            }
        });
    });
}
export function listConversionTasksRequest(_a) {
    var formBuildId = _a.formBuildId, endUserId = _a.endUserId, statusList = _a.statusList, startDate = _a.startDate, endDate = _a.endDate, options = _a.options, _b = _a.reversedOrder, reversedOrder = _b === void 0 ? false : _b;
    return __awaiter(this, void 0, Promise, function () {
        var client, request, res;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    client = createFormServiceClient();
                    request = new formPbService.ListConversionTasksRequest();
                    request.setFormBuildId(formBuildId);
                    // if endUserId does not exist, set it as 0
                    if (endUserId) {
                        request.setEndUserId(endUserId);
                    }
                    else {
                        request.setEndUserId(0);
                    }
                    if (statusList)
                        request.setStatusesList(statusList);
                    if (startDate)
                        request.setStartDate(startDate);
                    if (endDate)
                        request.setEndDate(endDate);
                    if (options)
                        request.setPagination(paginationFromObj(options));
                    if (reversedOrder)
                        request.setReversedOrder(true);
                    return [4 /*yield*/, client.listConversionTasks(request, getHeaders())];
                case 1:
                    res = _c.sent();
                    return [2 /*return*/, {
                            conversionTasksList: res.toObject().conversionTasksList,
                            total: res.toObject().total,
                        }];
            }
        });
    });
}
// GetForm retrieves a form entity by the given ID.
export function getForm(formId) {
    return __awaiter(this, void 0, Promise, function () {
        var client, request, res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    client = createFormServiceClient();
                    request = new formPbService.GetFormRequest();
                    request.setId(formId);
                    return [4 /*yield*/, client.getForm(request, getHeaders())];
                case 1:
                    res = _a.sent();
                    return [2 /*return*/, res.toObject()];
            }
        });
    });
}
var defaultLogo = 'https://zeals.co.jp/wp-content/themes/zeals2021/img/common/logo.svg';
// CreateForm create a form entity by CreateFormRequest.
export function createForm(name, formBuildId, editorName, editorEmail, pageList) {
    return __awaiter(this, void 0, Promise, function () {
        var client, request, pages, res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    client = createFormServiceClient();
                    request = new formPbService.CreateFormRequest();
                    request.setName(name);
                    request.setLogoUri(defaultLogo);
                    request.setFormBuildId(formBuildId);
                    request.setEditorName(editorName);
                    request.setEditorEmail(editorEmail);
                    if (pageList) {
                        pages = pageList.map(formPageFromObj);
                        request.setPagesList(pages);
                    }
                    else {
                        request.setPagesList([]);
                    }
                    request.setCompletionStatus(CompletionStatus.COMPLETION_STATUS_IN_PROGRESS);
                    return [4 /*yield*/, client.createForm(request, getHeaders())];
                case 1:
                    res = _a.sent();
                    return [2 /*return*/, res.toObject()];
            }
        });
    });
}
// UpdateForm update a form entity by UpdateFormRequest.
export function updateForm(form, editorName, editorEmail) {
    return __awaiter(this, void 0, void 0, function () {
        var client, request, f, res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    client = createFormServiceClient();
                    request = new formPbService.UpdateFormRequest();
                    request.setId(form.id);
                    request.setName(form.name);
                    request.setLogoUri(form.logoUri || defaultLogo);
                    request.setEditorName(editorName);
                    request.setEditorEmail(editorEmail);
                    f = formFromObj(form);
                    request.setPagesList(f.getPagesList());
                    request.setStyles(f.getStyles());
                    request.setTriggerFormAutomation(f.getTriggerFormAutomation());
                    request.setCompletionStatus(f.getCompletionStatus());
                    return [4 /*yield*/, client.updateForm(request, getHeaders())];
                case 1:
                    res = _a.sent();
                    return [2 /*return*/, res.toObject()];
            }
        });
    });
}
// deleteForm delete a form entity by DeleteFormRequest.
export function deleteForm(id) {
    return __awaiter(this, void 0, void 0, function () {
        var client, request, res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    client = createFormServiceClient();
                    request = new formPbService.DeleteFormRequest();
                    request.setId(id);
                    return [4 /*yield*/, client.deleteForm(request, getHeaders())];
                case 1:
                    res = _a.sent();
                    return [2 /*return*/, res.toObject()];
            }
        });
    });
}
// deleteFormScenario delete a form entity by DeleteFormScenarioRequest.
export function deleteFormScenario(id) {
    return __awaiter(this, void 0, void 0, function () {
        var client, request, res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    client = createFormServiceClient();
                    request = new formPbService.DeleteFormScenarioRequest();
                    request.setId(id);
                    return [4 /*yield*/, client.deleteFormScenario(request, getHeaders())];
                case 1:
                    res = _a.sent();
                    return [2 /*return*/, res.toObject()];
            }
        });
    });
}
// ListFormElementTemplates list form element templates by certain conditions.
export function listFormElementTemplates(formElementType) {
    return __awaiter(this, void 0, void 0, function () {
        var client, request, res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    client = createFormServiceClient();
                    request = new formPbService.ListFormElementTemplatesRequest();
                    request.setElementType(formElementType);
                    return [4 /*yield*/, client.listFormElementTemplates(request, getHeaders())];
                case 1:
                    res = _a.sent();
                    return [2 /*return*/, res.toObject()];
            }
        });
    });
}
// ListFormElementValidations list form element validations by certain conditions.
export function listFormElementValidations(formElementType) {
    return __awaiter(this, void 0, void 0, function () {
        var client, request, res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    client = createFormServiceClient();
                    request = new formPbService.ListFormElementValidationsRequest();
                    request.setType(formElementType);
                    return [4 /*yield*/, client.listFormElementValidations(request, getHeaders())];
                case 1:
                    res = _a.sent();
                    return [2 /*return*/, res.toObject().formElementValidationsList];
            }
        });
    });
}
export function updateFormBuild(id, segmentsList, name, editorName, editorEmail) {
    return __awaiter(this, void 0, Promise, function () {
        var client, request, res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    client = createFormServiceClient();
                    request = new formPbService.UpdateFormBuildRequest();
                    request.setId(id);
                    request.setSegmentsList(segmentsList.map(formBuildSegmentFromObj));
                    request.setName(name);
                    request.setEditorName(editorName);
                    request.setEditorEmail(editorEmail);
                    return [4 /*yield*/, client.updateFormBuild(request, getHeaders())];
                case 1:
                    res = _a.sent();
                    return [2 /*return*/, res.toObject()];
            }
        });
    });
}
export function deleteFormBuild(id) {
    return __awaiter(this, void 0, void 0, function () {
        var client, request;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    client = createFormServiceClient();
                    request = new formPbService.DeleteFormBuildRequest();
                    request.setId(id);
                    return [4 /*yield*/, client.deleteFormBuild(request, {
                            Authorization: localStorage.getItem('GRPC_JWT_TOKEN'),
                        })];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    });
}
// Get Form Conversation Questions by scenarioID or formScenarioID
export function getFormConversationQuestions(_a) {
    var scenarioId = _a.scenarioId, formScenarioId = _a.formScenarioId;
    return __awaiter(this, void 0, Promise, function () {
        var client, request, res;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    client = createFormServiceClient();
                    request = new formPbService.GetFormQuestionListsRequest();
                    // create
                    if (scenarioId && !formScenarioId) {
                        request.setScenarioId(scenarioId);
                    }
                    // edit
                    if (formScenarioId) {
                        request.setFormScenarioId(formScenarioId);
                    }
                    return [4 /*yield*/, client.getFormQuestionLists(request, getHeaders())];
                case 1:
                    res = _b.sent();
                    // Returns in-use questions list and unused questions list
                    return [2 /*return*/, res.toObject()];
            }
        });
    });
}
export function updateFormQuestion(_a) {
    var id = _a.id, triggerFormAutomation = _a.triggerFormAutomation, textValidation = _a.textValidation, numericValidation = _a.numericValidation, errorMessage = _a.errorMessage;
    return __awaiter(this, void 0, Promise, function () {
        var client, request, res;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    client = createFormServiceClient();
                    request = new formPbService.UpdateFormQuestionRequest();
                    request.setId(id);
                    request.setTriggerFormAutomation(triggerFormAutomation);
                    if (textValidation) {
                        request.setTextValidation(formTextValidationFromObj(textValidation));
                    }
                    if (numericValidation) {
                        request.setNumericValidation(formNumericValidationFromObj(numericValidation));
                    }
                    request.setErrorMessage(errorMessage);
                    return [4 /*yield*/, client.updateFormQuestion(request, getHeaders())];
                case 1:
                    res = _b.sent();
                    return [2 /*return*/, res.toObject()];
            }
        });
    });
}
export function createFormScenario(_a) {
    var name = _a.name, formBuildId = _a.formBuildId, scenarioId = _a.scenarioId, formQuestionsList = _a.formQuestionsList, completionStatus = _a.completionStatus, editorName = _a.editorName, editorEmail = _a.editorEmail;
    return __awaiter(this, void 0, void 0, function () {
        var client, request, res;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    client = createFormServiceClient();
                    request = new formPbService.CreateFormScenarioRequest();
                    request.setFormBuildId(formBuildId);
                    request.setScenarioId(scenarioId);
                    request.setFormQuestionsList(formQuestionsList.map(formQuestionFromObj));
                    request.setCompletionStatus(completionStatus);
                    request.setEditorName(editorName);
                    request.setEditorEmail(editorEmail);
                    request.setName(name);
                    return [4 /*yield*/, client.createFormScenario(request, getHeaders())];
                case 1:
                    res = _b.sent();
                    return [2 /*return*/, res.toObject()];
            }
        });
    });
}
export function updateFormScenario(_a) {
    var formScenarioId = _a.formScenarioId, formQuestionsList = _a.formQuestionsList, completionStatus = _a.completionStatus, editorName = _a.editorName, editorEmail = _a.editorEmail;
    return __awaiter(this, void 0, void 0, function () {
        var client, request, res;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    client = createFormServiceClient();
                    request = new formPbService.UpdateFormScenarioRequest();
                    request.setId(formScenarioId);
                    request.setFormQuestionsList(formQuestionsList.map(formQuestionFromObj));
                    request.setCompletionStatus(completionStatus);
                    request.setEditorName(editorName);
                    request.setEditorEmail(editorEmail);
                    return [4 /*yield*/, client.updateFormScenario(request, getHeaders())];
                case 1:
                    res = _b.sent();
                    return [2 /*return*/, res.toObject()];
            }
        });
    });
}
// CreateFormBuild creates a Form Build entity by CreateFormBuildRequest.
export function createFormBuild(name, chatbotId, authorName, authorEmail) {
    return __awaiter(this, void 0, Promise, function () {
        var client, request, res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    client = createFormServiceClient();
                    request = new formPbService.CreateFormBuildRequest();
                    request.setChatbotId(chatbotId);
                    request.setName(name);
                    request.setAuthorName(authorName);
                    request.setAuthorEmail(authorEmail);
                    return [4 /*yield*/, client.createFormBuild(request, getHeaders())];
                case 1:
                    res = _a.sent();
                    return [2 /*return*/, res.toObject()];
            }
        });
    });
}
// upsertFormQuestions upserts Form Questions from UpsertFormQuestionsRequest.
export function upsertFormQuestions(questionsList) {
    return __awaiter(this, void 0, void 0, function () {
        var client, request, res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    client = createFormServiceClient();
                    request = new formPbService.UpsertFormQuestionsRequest();
                    request.setQuestionsList(questionsList.map(questionFromScenarioQuestion));
                    return [4 /*yield*/, client.upsertFormQuestions(request, getHeaders())];
                case 1:
                    res = _a.sent();
                    return [2 /*return*/, res.toObject()];
            }
        });
    });
}
// GetFormBuild retrieves a FormBuild entity by the given ID.
export function getFormBuild(formBuildId) {
    return __awaiter(this, void 0, Promise, function () {
        var client, request, res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    client = createFormServiceClient();
                    request = new formPbService.GetFormBuildRequest();
                    request.setId(formBuildId);
                    return [4 /*yield*/, client.getFormBuild(request, getHeaders())];
                case 1:
                    res = _a.sent();
                    return [2 /*return*/, res.toObject()];
            }
        });
    });
}
// DeleteFormBuild deletes a FormBuild entity by DeleteFormRequest.
export function deleteFormBuildRequest(id) {
    return __awaiter(this, void 0, Promise, function () {
        var client, request, res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    client = createFormServiceClient();
                    request = new formPbService.DeleteFormBuildRequest();
                    request.setId(id);
                    return [4 /*yield*/, client.deleteFormBuild(request, getHeaders())];
                case 1:
                    res = _a.sent();
                    return [2 /*return*/, res.toObject()];
            }
        });
    });
}
export function setFormAutomationTrigger(id, formAutomationTrigger) {
    return __awaiter(this, void 0, Promise, function () {
        var client, request, res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    client = createFormServiceClient();
                    request = new formPbService.SetFormAutomationTriggerRequest();
                    request.setId(id);
                    request.setFormAutomationTrigger(formAutomationTriggerFromObj(formAutomationTrigger));
                    return [4 /*yield*/, client.setFormAutomationTrigger(request, getHeaders())];
                case 1:
                    res = _a.sent();
                    return [2 /*return*/, res.toObject()];
            }
        });
    });
}
