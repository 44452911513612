var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import { Tooltip } from '@zeals-co-ltd/washi-components';
import { FormWrapper, Button } from '@src/components/atoms';
import CustomField from '@src/components/molecules/CustomField';
import { injectIntl } from 'react-intl';
import buttonTypes from '@src/lib/buttonTypes';
import { colors } from '@src/colors';
var PrivacyPolicySettingsContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  background: #fff;\n  border-radius: 8px;\n\n  .settings-wrapper {\n    display: block;\n  }\n  button {\n    display: inline-block;\n  }\n  .adicon_help_line {\n    font-size: 16px;\n    position: relative;\n    top: -3px;\n  }\n"], ["\n  background: #fff;\n  border-radius: 8px;\n\n  .settings-wrapper {\n    display: block;\n  }\n  button {\n    display: inline-block;\n  }\n  .adicon_help_line {\n    font-size: 16px;\n    position: relative;\n    top: -3px;\n  }\n"])));
var SmartReplaceIcon = styled.span(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: inline-block;\n  height: 10px;\n  width: 10px;\n  border-radius: 2px;\n  background-color: ", ";\n"], ["\n  display: inline-block;\n  height: 10px;\n  width: 10px;\n  border-radius: 2px;\n  background-color: ", ";\n"])), function (_a) {
    var backgroundColor = _a.backgroundColor;
    return backgroundColor || '#FFEF60';
});
/**
 * The settings for the Privacy Policy (smart replace only for now)
 */
function PrivacyPolicySettings(_a) {
    var smartReplaces = _a.smartReplaces, intl = _a.intl, _b = _a.formikInnerProps, isValid = _b.isValid, dirty = _b.dirty, handleChange = _b.handleChange, errors = _b.errors, touched = _b.touched, resetForm = _b.resetForm, initialValues = _b.initialValues, handleSubmit = _b.handleSubmit;
    if (!smartReplaces.length)
        return null;
    return (React.createElement(PrivacyPolicySettingsContainer, { "data-testid": "privacy-policy-settings" },
        React.createElement(FormWrapper, { className: "settings-wrapper" },
            smartReplaces.map(function (smartReplace) { return (React.createElement(CustomField, { key: smartReplace.pattern, fieldLabel: React.createElement(React.Fragment, null,
                    smartReplace.pattern,
                    ' ',
                    React.createElement(SmartReplaceIcon, { backgroundColor: smartReplace.highlight_color })), type: "text", name: smartReplace.pattern, placeholder: intl.formatMessage({ id: "ibLVzI", defaultMessage: "Please enter {field}" }, {
                    field: smartReplace.pattern,
                }), hasError: !!touched[smartReplace.pattern] && !!errors[smartReplace.pattern], onChange: handleChange, componentType: smartReplace.pattern ===
                    intl.formatMessage({ id: "4TGjTc", defaultMessage: "Bot Name" })
                    ? 'p'
                    : 'text' })); }),
            React.createElement(CustomField, { fieldLabel: React.createElement(React.Fragment, null,
                    intl.formatMessage({ id: "5UfGrE", defaultMessage: "Bot Friend URL" }),
                    ' ',
                    React.createElement(Tooltip, { enterDelay: 1000, placement: "bottom", title: React.createElement(React.Fragment, null,
                            "Official Account Manager",
                            React.createElement("br", null),
                            "\u3067\u53D6\u5F97\u3067\u304D\u307E\u3059") },
                        React.createElement("span", { className: "adicon_help_line" }))), name: "url", placeholder: intl.formatMessage({ id: "ibLVzI", defaultMessage: "Please enter {field}" }, {
                    field: intl.formatMessage({ id: "5UfGrE", defaultMessage: "Bot Friend URL" }),
                }), hasError: !!touched.url && !!errors.url, onChange: handleChange }),
            React.createElement("div", { className: "submit-controls pull-right" },
                React.createElement(Button, { buttonType: buttonTypes.SETTING_WHITE, onClick: function () { return resetForm({ values: initialValues }); }, text: intl.formatMessage({ id: "jm/spn", defaultMessage: "Reset" }), margin: "0 10px 0 0", width: 'inherit' }),
                React.createElement(Button, { buttonType: buttonTypes.SAVE, onClick: handleSubmit, text: intl.formatMessage({ id: "jvo0vs", defaultMessage: "Save" }), width: 'inherit', backgroundColor: colors.background.accent_color, disabled: !isValid || !dirty, dataTest: "save-button" })))));
}
export default injectIntl(PrivacyPolicySettings);
var templateObject_1, templateObject_2;
