import { isEmpty } from 'lodash';
// This function will get all connectionIds that has relation from hoveredNode
export default function (hoveredNode, nodeEdges) {
    if (!hoveredNode)
        return [];
    if (!hoveredNode.blockId && !hoveredNode.scenarioId)
        return [];
    var lineConnectionIds = [];
    var tempNodes = [];
    var hoveredNodeTargetName = hoveredNode.blockId
        ? "block-" + hoveredNode.blockId
        : "connected-other-scenario-" + hoveredNode.scenarioId;
    tempNodes.push(hoveredNodeTargetName);
    var _loop_1 = function () {
        var tempNode = tempNodes.pop();
        var tempConnectionLines = nodeEdges.filter(function (connection) { return connection.target === tempNode; });
        tempConnectionLines.forEach(function (connection) {
            if (!lineConnectionIds.includes(connection.id)) {
                lineConnectionIds.push(connection.id);
                tempNodes.push(connection.sourceBlock);
            }
        });
    };
    while (!isEmpty(tempNodes)) {
        _loop_1();
    }
    return lineConnectionIds;
}
