// source: entities/user_dossier.proto

/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

/* eslint-disable */
// @ts-nocheck
var jspb = require('google-protobuf');

var goog = jspb;
var global = Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');

goog.object.extend(proto, google_protobuf_timestamp_pb);
goog.exportSymbol('proto.e.AggregateCondition', null, global);
goog.exportSymbol('proto.e.AggregateFunction', null, global);
goog.exportSymbol('proto.e.Attribute', null, global);
goog.exportSymbol('proto.e.AttributeCollection', null, global);
goog.exportSymbol('proto.e.ClauseCondition', null, global);
goog.exportSymbol('proto.e.ComparisonOperator', null, global);
goog.exportSymbol('proto.e.Condition', null, global);
goog.exportSymbol('proto.e.Condition.ConditionCase', null, global);
goog.exportSymbol('proto.e.ConditionType', null, global);
goog.exportSymbol('proto.e.CountCondition', null, global);
goog.exportSymbol('proto.e.DataType', null, global);
goog.exportSymbol('proto.e.Field', null, global);
goog.exportSymbol('proto.e.FieldCollection', null, global);
goog.exportSymbol('proto.e.FieldCollectionType', null, global);
goog.exportSymbol('proto.e.InputType', null, global);
goog.exportSymbol('proto.e.LogicalOperator', null, global);
goog.exportSymbol('proto.e.UserLabel', null, global);
goog.exportSymbol('proto.e.UserSegment', null, global);
goog.exportSymbol('proto.e.Value', null, global);
goog.exportSymbol('proto.e.Value.ValueCase', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */

proto.e.FieldCollection = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.e.FieldCollection.repeatedFields_, null);
};

goog.inherits(proto.e.FieldCollection, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.e.FieldCollection.displayName = 'proto.e.FieldCollection';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.e.Field = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.e.Field, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.e.Field.displayName = 'proto.e.Field';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.e.AttributeCollection = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.e.AttributeCollection.repeatedFields_, null);
};

goog.inherits(proto.e.AttributeCollection, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.e.AttributeCollection.displayName = 'proto.e.AttributeCollection';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.e.Attribute = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.e.Attribute, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.e.Attribute.displayName = 'proto.e.Attribute';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.e.UserSegment = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.e.UserSegment.repeatedFields_, null);
};

goog.inherits(proto.e.UserSegment, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.e.UserSegment.displayName = 'proto.e.UserSegment';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.e.UserLabel = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.e.UserLabel.repeatedFields_, null);
};

goog.inherits(proto.e.UserLabel, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.e.UserLabel.displayName = 'proto.e.UserLabel';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.e.Condition = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.e.Condition.oneofGroups_);
};

goog.inherits(proto.e.Condition, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.e.Condition.displayName = 'proto.e.Condition';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.e.AggregateCondition = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.e.AggregateCondition, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.e.AggregateCondition.displayName = 'proto.e.AggregateCondition';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.e.CountCondition = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.e.CountCondition, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.e.CountCondition.displayName = 'proto.e.CountCondition';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.e.ClauseCondition = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.e.ClauseCondition, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.e.ClauseCondition.displayName = 'proto.e.ClauseCondition';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.e.Value = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.e.Value.oneofGroups_);
};

goog.inherits(proto.e.Value, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.e.Value.displayName = 'proto.e.Value';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */


proto.e.FieldCollection.repeatedFields_ = [4];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.e.FieldCollection.prototype.toObject = function (opt_includeInstance) {
    return proto.e.FieldCollection.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.e.FieldCollection} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.e.FieldCollection.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, ""),
      type: jspb.Message.getFieldWithDefault(msg, 2, 0),
      name: jspb.Message.getFieldWithDefault(msg, 3, ""),
      fieldsList: jspb.Message.toObjectList(msg.getFieldsList(), proto.e.Field.toObject, includeInstance),
      chatbotId: jspb.Message.getFieldWithDefault(msg, 5, 0),
      createTime: (f = msg.getCreateTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
      lastUpdateTime: (f = msg.getLastUpdateTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
      description: jspb.Message.getFieldWithDefault(msg, 16, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.e.FieldCollection}
 */


proto.e.FieldCollection.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.e.FieldCollection();
  return proto.e.FieldCollection.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.e.FieldCollection} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.e.FieldCollection}
 */


proto.e.FieldCollection.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setId(value);
        break;

      case 2:
        var value =
        /** @type {!proto.e.FieldCollectionType} */
        reader.readEnum();
        msg.setType(value);
        break;

      case 3:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setName(value);
        break;

      case 4:
        var value = new proto.e.Field();
        reader.readMessage(value, proto.e.Field.deserializeBinaryFromReader);
        msg.addFields(value);
        break;

      case 5:
        var value =
        /** @type {number} */
        reader.readInt64();
        msg.setChatbotId(value);
        break;

      case 6:
        var value = new google_protobuf_timestamp_pb.Timestamp();
        reader.readMessage(value, google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
        msg.setCreateTime(value);
        break;

      case 7:
        var value = new google_protobuf_timestamp_pb.Timestamp();
        reader.readMessage(value, google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
        msg.setLastUpdateTime(value);
        break;

      case 16:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setDescription(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.e.FieldCollection.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.e.FieldCollection.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.e.FieldCollection} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.e.FieldCollection.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getId();

  if (f.length > 0) {
    writer.writeString(1, f);
  }

  f = message.getType();

  if (f !== 0.0) {
    writer.writeEnum(2, f);
  }

  f = message.getName();

  if (f.length > 0) {
    writer.writeString(3, f);
  }

  f = message.getFieldsList();

  if (f.length > 0) {
    writer.writeRepeatedMessage(4, f, proto.e.Field.serializeBinaryToWriter);
  }

  f = message.getChatbotId();

  if (f !== 0) {
    writer.writeInt64(5, f);
  }

  f = message.getCreateTime();

  if (f != null) {
    writer.writeMessage(6, f, google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter);
  }

  f = message.getLastUpdateTime();

  if (f != null) {
    writer.writeMessage(7, f, google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter);
  }

  f = message.getDescription();

  if (f.length > 0) {
    writer.writeString(16, f);
  }
};
/**
 * optional string id = 1;
 * @return {string}
 */


proto.e.FieldCollection.prototype.getId = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 1, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.e.FieldCollection} returns this
 */


proto.e.FieldCollection.prototype.setId = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional FieldCollectionType type = 2;
 * @return {!proto.e.FieldCollectionType}
 */


proto.e.FieldCollection.prototype.getType = function () {
  return (
    /** @type {!proto.e.FieldCollectionType} */
    jspb.Message.getFieldWithDefault(this, 2, 0)
  );
};
/**
 * @param {!proto.e.FieldCollectionType} value
 * @return {!proto.e.FieldCollection} returns this
 */


proto.e.FieldCollection.prototype.setType = function (value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};
/**
 * optional string name = 3;
 * @return {string}
 */


proto.e.FieldCollection.prototype.getName = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 3, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.e.FieldCollection} returns this
 */


proto.e.FieldCollection.prototype.setName = function (value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};
/**
 * repeated Field fields = 4;
 * @return {!Array<!proto.e.Field>}
 */


proto.e.FieldCollection.prototype.getFieldsList = function () {
  return (
    /** @type{!Array<!proto.e.Field>} */
    jspb.Message.getRepeatedWrapperField(this, proto.e.Field, 4)
  );
};
/**
 * @param {!Array<!proto.e.Field>} value
 * @return {!proto.e.FieldCollection} returns this
*/


proto.e.FieldCollection.prototype.setFieldsList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};
/**
 * @param {!proto.e.Field=} opt_value
 * @param {number=} opt_index
 * @return {!proto.e.Field}
 */


proto.e.FieldCollection.prototype.addFields = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.e.Field, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.e.FieldCollection} returns this
 */


proto.e.FieldCollection.prototype.clearFieldsList = function () {
  return this.setFieldsList([]);
};
/**
 * optional int64 chatbot_id = 5;
 * @return {number}
 */


proto.e.FieldCollection.prototype.getChatbotId = function () {
  return (
    /** @type {number} */
    jspb.Message.getFieldWithDefault(this, 5, 0)
  );
};
/**
 * @param {number} value
 * @return {!proto.e.FieldCollection} returns this
 */


proto.e.FieldCollection.prototype.setChatbotId = function (value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};
/**
 * optional google.protobuf.Timestamp create_time = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */


proto.e.FieldCollection.prototype.getCreateTime = function () {
  return (
    /** @type{?proto.google.protobuf.Timestamp} */
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6)
  );
};
/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.e.FieldCollection} returns this
*/


proto.e.FieldCollection.prototype.setCreateTime = function (value) {
  return jspb.Message.setWrapperField(this, 6, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.e.FieldCollection} returns this
 */


proto.e.FieldCollection.prototype.clearCreateTime = function () {
  return this.setCreateTime(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.e.FieldCollection.prototype.hasCreateTime = function () {
  return jspb.Message.getField(this, 6) != null;
};
/**
 * optional google.protobuf.Timestamp last_update_time = 7;
 * @return {?proto.google.protobuf.Timestamp}
 */


proto.e.FieldCollection.prototype.getLastUpdateTime = function () {
  return (
    /** @type{?proto.google.protobuf.Timestamp} */
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 7)
  );
};
/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.e.FieldCollection} returns this
*/


proto.e.FieldCollection.prototype.setLastUpdateTime = function (value) {
  return jspb.Message.setWrapperField(this, 7, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.e.FieldCollection} returns this
 */


proto.e.FieldCollection.prototype.clearLastUpdateTime = function () {
  return this.setLastUpdateTime(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.e.FieldCollection.prototype.hasLastUpdateTime = function () {
  return jspb.Message.getField(this, 7) != null;
};
/**
 * optional string description = 16;
 * @return {string}
 */


proto.e.FieldCollection.prototype.getDescription = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 16, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.e.FieldCollection} returns this
 */


proto.e.FieldCollection.prototype.setDescription = function (value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.e.Field.prototype.toObject = function (opt_includeInstance) {
    return proto.e.Field.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.e.Field} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.e.Field.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, ""),
      name: jspb.Message.getFieldWithDefault(msg, 2, ""),
      dataType: jspb.Message.getFieldWithDefault(msg, 3, 0),
      inputType: jspb.Message.getFieldWithDefault(msg, 4, 0),
      fieldCollectionType: jspb.Message.getFieldWithDefault(msg, 5, 0),
      createTime: (f = msg.getCreateTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
      lastUpdateTime: (f = msg.getLastUpdateTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
      chatbotId: jspb.Message.getFieldWithDefault(msg, 16, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.e.Field}
 */


proto.e.Field.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.e.Field();
  return proto.e.Field.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.e.Field} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.e.Field}
 */


proto.e.Field.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setId(value);
        break;

      case 2:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setName(value);
        break;

      case 3:
        var value =
        /** @type {!proto.e.DataType} */
        reader.readEnum();
        msg.setDataType(value);
        break;

      case 4:
        var value =
        /** @type {!proto.e.InputType} */
        reader.readEnum();
        msg.setInputType(value);
        break;

      case 5:
        var value =
        /** @type {!proto.e.FieldCollectionType} */
        reader.readEnum();
        msg.setFieldCollectionType(value);
        break;

      case 6:
        var value = new google_protobuf_timestamp_pb.Timestamp();
        reader.readMessage(value, google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
        msg.setCreateTime(value);
        break;

      case 7:
        var value = new google_protobuf_timestamp_pb.Timestamp();
        reader.readMessage(value, google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
        msg.setLastUpdateTime(value);
        break;

      case 16:
        var value =
        /** @type {number} */
        reader.readInt64();
        msg.setChatbotId(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.e.Field.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.e.Field.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.e.Field} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.e.Field.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getId();

  if (f.length > 0) {
    writer.writeString(1, f);
  }

  f = message.getName();

  if (f.length > 0) {
    writer.writeString(2, f);
  }

  f = message.getDataType();

  if (f !== 0.0) {
    writer.writeEnum(3, f);
  }

  f = message.getInputType();

  if (f !== 0.0) {
    writer.writeEnum(4, f);
  }

  f = message.getFieldCollectionType();

  if (f !== 0.0) {
    writer.writeEnum(5, f);
  }

  f = message.getCreateTime();

  if (f != null) {
    writer.writeMessage(6, f, google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter);
  }

  f = message.getLastUpdateTime();

  if (f != null) {
    writer.writeMessage(7, f, google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter);
  }

  f = message.getChatbotId();

  if (f !== 0) {
    writer.writeInt64(16, f);
  }
};
/**
 * optional string id = 1;
 * @return {string}
 */


proto.e.Field.prototype.getId = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 1, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.e.Field} returns this
 */


proto.e.Field.prototype.setId = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional string name = 2;
 * @return {string}
 */


proto.e.Field.prototype.getName = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 2, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.e.Field} returns this
 */


proto.e.Field.prototype.setName = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};
/**
 * optional DataType data_type = 3;
 * @return {!proto.e.DataType}
 */


proto.e.Field.prototype.getDataType = function () {
  return (
    /** @type {!proto.e.DataType} */
    jspb.Message.getFieldWithDefault(this, 3, 0)
  );
};
/**
 * @param {!proto.e.DataType} value
 * @return {!proto.e.Field} returns this
 */


proto.e.Field.prototype.setDataType = function (value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};
/**
 * optional InputType input_type = 4;
 * @return {!proto.e.InputType}
 */


proto.e.Field.prototype.getInputType = function () {
  return (
    /** @type {!proto.e.InputType} */
    jspb.Message.getFieldWithDefault(this, 4, 0)
  );
};
/**
 * @param {!proto.e.InputType} value
 * @return {!proto.e.Field} returns this
 */


proto.e.Field.prototype.setInputType = function (value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};
/**
 * optional FieldCollectionType field_collection_type = 5;
 * @return {!proto.e.FieldCollectionType}
 */


proto.e.Field.prototype.getFieldCollectionType = function () {
  return (
    /** @type {!proto.e.FieldCollectionType} */
    jspb.Message.getFieldWithDefault(this, 5, 0)
  );
};
/**
 * @param {!proto.e.FieldCollectionType} value
 * @return {!proto.e.Field} returns this
 */


proto.e.Field.prototype.setFieldCollectionType = function (value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};
/**
 * optional google.protobuf.Timestamp create_time = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */


proto.e.Field.prototype.getCreateTime = function () {
  return (
    /** @type{?proto.google.protobuf.Timestamp} */
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6)
  );
};
/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.e.Field} returns this
*/


proto.e.Field.prototype.setCreateTime = function (value) {
  return jspb.Message.setWrapperField(this, 6, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.e.Field} returns this
 */


proto.e.Field.prototype.clearCreateTime = function () {
  return this.setCreateTime(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.e.Field.prototype.hasCreateTime = function () {
  return jspb.Message.getField(this, 6) != null;
};
/**
 * optional google.protobuf.Timestamp last_update_time = 7;
 * @return {?proto.google.protobuf.Timestamp}
 */


proto.e.Field.prototype.getLastUpdateTime = function () {
  return (
    /** @type{?proto.google.protobuf.Timestamp} */
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 7)
  );
};
/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.e.Field} returns this
*/


proto.e.Field.prototype.setLastUpdateTime = function (value) {
  return jspb.Message.setWrapperField(this, 7, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.e.Field} returns this
 */


proto.e.Field.prototype.clearLastUpdateTime = function () {
  return this.setLastUpdateTime(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.e.Field.prototype.hasLastUpdateTime = function () {
  return jspb.Message.getField(this, 7) != null;
};
/**
 * optional int64 chatbot_id = 16;
 * @return {number}
 */


proto.e.Field.prototype.getChatbotId = function () {
  return (
    /** @type {number} */
    jspb.Message.getFieldWithDefault(this, 16, 0)
  );
};
/**
 * @param {number} value
 * @return {!proto.e.Field} returns this
 */


proto.e.Field.prototype.setChatbotId = function (value) {
  return jspb.Message.setProto3IntField(this, 16, value);
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */


proto.e.AttributeCollection.repeatedFields_ = [3];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.e.AttributeCollection.prototype.toObject = function (opt_includeInstance) {
    return proto.e.AttributeCollection.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.e.AttributeCollection} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.e.AttributeCollection.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, ""),
      endUserId: jspb.Message.getFieldWithDefault(msg, 2, 0),
      attributesList: jspb.Message.toObjectList(msg.getAttributesList(), proto.e.Attribute.toObject, includeInstance),
      createTime: (f = msg.getCreateTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
      lastUpdateTime: (f = msg.getLastUpdateTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.e.AttributeCollection}
 */


proto.e.AttributeCollection.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.e.AttributeCollection();
  return proto.e.AttributeCollection.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.e.AttributeCollection} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.e.AttributeCollection}
 */


proto.e.AttributeCollection.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setId(value);
        break;

      case 2:
        var value =
        /** @type {number} */
        reader.readInt64();
        msg.setEndUserId(value);
        break;

      case 3:
        var value = new proto.e.Attribute();
        reader.readMessage(value, proto.e.Attribute.deserializeBinaryFromReader);
        msg.addAttributes(value);
        break;

      case 4:
        var value = new google_protobuf_timestamp_pb.Timestamp();
        reader.readMessage(value, google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
        msg.setCreateTime(value);
        break;

      case 5:
        var value = new google_protobuf_timestamp_pb.Timestamp();
        reader.readMessage(value, google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
        msg.setLastUpdateTime(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.e.AttributeCollection.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.e.AttributeCollection.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.e.AttributeCollection} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.e.AttributeCollection.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getId();

  if (f.length > 0) {
    writer.writeString(1, f);
  }

  f = message.getEndUserId();

  if (f !== 0) {
    writer.writeInt64(2, f);
  }

  f = message.getAttributesList();

  if (f.length > 0) {
    writer.writeRepeatedMessage(3, f, proto.e.Attribute.serializeBinaryToWriter);
  }

  f = message.getCreateTime();

  if (f != null) {
    writer.writeMessage(4, f, google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter);
  }

  f = message.getLastUpdateTime();

  if (f != null) {
    writer.writeMessage(5, f, google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter);
  }
};
/**
 * optional string id = 1;
 * @return {string}
 */


proto.e.AttributeCollection.prototype.getId = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 1, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.e.AttributeCollection} returns this
 */


proto.e.AttributeCollection.prototype.setId = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional int64 end_user_id = 2;
 * @return {number}
 */


proto.e.AttributeCollection.prototype.getEndUserId = function () {
  return (
    /** @type {number} */
    jspb.Message.getFieldWithDefault(this, 2, 0)
  );
};
/**
 * @param {number} value
 * @return {!proto.e.AttributeCollection} returns this
 */


proto.e.AttributeCollection.prototype.setEndUserId = function (value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};
/**
 * repeated Attribute attributes = 3;
 * @return {!Array<!proto.e.Attribute>}
 */


proto.e.AttributeCollection.prototype.getAttributesList = function () {
  return (
    /** @type{!Array<!proto.e.Attribute>} */
    jspb.Message.getRepeatedWrapperField(this, proto.e.Attribute, 3)
  );
};
/**
 * @param {!Array<!proto.e.Attribute>} value
 * @return {!proto.e.AttributeCollection} returns this
*/


proto.e.AttributeCollection.prototype.setAttributesList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};
/**
 * @param {!proto.e.Attribute=} opt_value
 * @param {number=} opt_index
 * @return {!proto.e.Attribute}
 */


proto.e.AttributeCollection.prototype.addAttributes = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.e.Attribute, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.e.AttributeCollection} returns this
 */


proto.e.AttributeCollection.prototype.clearAttributesList = function () {
  return this.setAttributesList([]);
};
/**
 * optional google.protobuf.Timestamp create_time = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */


proto.e.AttributeCollection.prototype.getCreateTime = function () {
  return (
    /** @type{?proto.google.protobuf.Timestamp} */
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4)
  );
};
/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.e.AttributeCollection} returns this
*/


proto.e.AttributeCollection.prototype.setCreateTime = function (value) {
  return jspb.Message.setWrapperField(this, 4, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.e.AttributeCollection} returns this
 */


proto.e.AttributeCollection.prototype.clearCreateTime = function () {
  return this.setCreateTime(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.e.AttributeCollection.prototype.hasCreateTime = function () {
  return jspb.Message.getField(this, 4) != null;
};
/**
 * optional google.protobuf.Timestamp last_update_time = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */


proto.e.AttributeCollection.prototype.getLastUpdateTime = function () {
  return (
    /** @type{?proto.google.protobuf.Timestamp} */
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5)
  );
};
/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.e.AttributeCollection} returns this
*/


proto.e.AttributeCollection.prototype.setLastUpdateTime = function (value) {
  return jspb.Message.setWrapperField(this, 5, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.e.AttributeCollection} returns this
 */


proto.e.AttributeCollection.prototype.clearLastUpdateTime = function () {
  return this.setLastUpdateTime(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.e.AttributeCollection.prototype.hasLastUpdateTime = function () {
  return jspb.Message.getField(this, 5) != null;
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.e.Attribute.prototype.toObject = function (opt_includeInstance) {
    return proto.e.Attribute.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.e.Attribute} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.e.Attribute.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      fieldId: jspb.Message.getFieldWithDefault(msg, 1, ""),
      value: (f = msg.getValue()) && proto.e.Value.toObject(includeInstance, f),
      createTime: (f = msg.getCreateTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
      lastUpdateTime: (f = msg.getLastUpdateTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.e.Attribute}
 */


proto.e.Attribute.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.e.Attribute();
  return proto.e.Attribute.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.e.Attribute} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.e.Attribute}
 */


proto.e.Attribute.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setFieldId(value);
        break;

      case 2:
        var value = new proto.e.Value();
        reader.readMessage(value, proto.e.Value.deserializeBinaryFromReader);
        msg.setValue(value);
        break;

      case 3:
        var value = new google_protobuf_timestamp_pb.Timestamp();
        reader.readMessage(value, google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
        msg.setCreateTime(value);
        break;

      case 4:
        var value = new google_protobuf_timestamp_pb.Timestamp();
        reader.readMessage(value, google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
        msg.setLastUpdateTime(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.e.Attribute.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.e.Attribute.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.e.Attribute} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.e.Attribute.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getFieldId();

  if (f.length > 0) {
    writer.writeString(1, f);
  }

  f = message.getValue();

  if (f != null) {
    writer.writeMessage(2, f, proto.e.Value.serializeBinaryToWriter);
  }

  f = message.getCreateTime();

  if (f != null) {
    writer.writeMessage(3, f, google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter);
  }

  f = message.getLastUpdateTime();

  if (f != null) {
    writer.writeMessage(4, f, google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter);
  }
};
/**
 * optional string field_id = 1;
 * @return {string}
 */


proto.e.Attribute.prototype.getFieldId = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 1, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.e.Attribute} returns this
 */


proto.e.Attribute.prototype.setFieldId = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional Value value = 2;
 * @return {?proto.e.Value}
 */


proto.e.Attribute.prototype.getValue = function () {
  return (
    /** @type{?proto.e.Value} */
    jspb.Message.getWrapperField(this, proto.e.Value, 2)
  );
};
/**
 * @param {?proto.e.Value|undefined} value
 * @return {!proto.e.Attribute} returns this
*/


proto.e.Attribute.prototype.setValue = function (value) {
  return jspb.Message.setWrapperField(this, 2, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.e.Attribute} returns this
 */


proto.e.Attribute.prototype.clearValue = function () {
  return this.setValue(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.e.Attribute.prototype.hasValue = function () {
  return jspb.Message.getField(this, 2) != null;
};
/**
 * optional google.protobuf.Timestamp create_time = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */


proto.e.Attribute.prototype.getCreateTime = function () {
  return (
    /** @type{?proto.google.protobuf.Timestamp} */
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3)
  );
};
/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.e.Attribute} returns this
*/


proto.e.Attribute.prototype.setCreateTime = function (value) {
  return jspb.Message.setWrapperField(this, 3, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.e.Attribute} returns this
 */


proto.e.Attribute.prototype.clearCreateTime = function () {
  return this.setCreateTime(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.e.Attribute.prototype.hasCreateTime = function () {
  return jspb.Message.getField(this, 3) != null;
};
/**
 * optional google.protobuf.Timestamp last_update_time = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */


proto.e.Attribute.prototype.getLastUpdateTime = function () {
  return (
    /** @type{?proto.google.protobuf.Timestamp} */
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4)
  );
};
/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.e.Attribute} returns this
*/


proto.e.Attribute.prototype.setLastUpdateTime = function (value) {
  return jspb.Message.setWrapperField(this, 4, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.e.Attribute} returns this
 */


proto.e.Attribute.prototype.clearLastUpdateTime = function () {
  return this.setLastUpdateTime(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.e.Attribute.prototype.hasLastUpdateTime = function () {
  return jspb.Message.getField(this, 4) != null;
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */


proto.e.UserSegment.repeatedFields_ = [3];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.e.UserSegment.prototype.toObject = function (opt_includeInstance) {
    return proto.e.UserSegment.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.e.UserSegment} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.e.UserSegment.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, ""),
      name: jspb.Message.getFieldWithDefault(msg, 2, ""),
      userLabelsList: jspb.Message.toObjectList(msg.getUserLabelsList(), proto.e.UserLabel.toObject, includeInstance),
      operator: jspb.Message.getFieldWithDefault(msg, 4, 0),
      createTime: (f = msg.getCreateTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
      lastUpdateTime: (f = msg.getLastUpdateTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
      chatbotId: jspb.Message.getFieldWithDefault(msg, 16, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.e.UserSegment}
 */


proto.e.UserSegment.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.e.UserSegment();
  return proto.e.UserSegment.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.e.UserSegment} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.e.UserSegment}
 */


proto.e.UserSegment.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setId(value);
        break;

      case 2:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setName(value);
        break;

      case 3:
        var value = new proto.e.UserLabel();
        reader.readMessage(value, proto.e.UserLabel.deserializeBinaryFromReader);
        msg.addUserLabels(value);
        break;

      case 4:
        var value =
        /** @type {!proto.e.LogicalOperator} */
        reader.readEnum();
        msg.setOperator(value);
        break;

      case 5:
        var value = new google_protobuf_timestamp_pb.Timestamp();
        reader.readMessage(value, google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
        msg.setCreateTime(value);
        break;

      case 6:
        var value = new google_protobuf_timestamp_pb.Timestamp();
        reader.readMessage(value, google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
        msg.setLastUpdateTime(value);
        break;

      case 16:
        var value =
        /** @type {number} */
        reader.readInt64();
        msg.setChatbotId(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.e.UserSegment.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.e.UserSegment.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.e.UserSegment} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.e.UserSegment.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getId();

  if (f.length > 0) {
    writer.writeString(1, f);
  }

  f = message.getName();

  if (f.length > 0) {
    writer.writeString(2, f);
  }

  f = message.getUserLabelsList();

  if (f.length > 0) {
    writer.writeRepeatedMessage(3, f, proto.e.UserLabel.serializeBinaryToWriter);
  }

  f = message.getOperator();

  if (f !== 0.0) {
    writer.writeEnum(4, f);
  }

  f = message.getCreateTime();

  if (f != null) {
    writer.writeMessage(5, f, google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter);
  }

  f = message.getLastUpdateTime();

  if (f != null) {
    writer.writeMessage(6, f, google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter);
  }

  f = message.getChatbotId();

  if (f !== 0) {
    writer.writeInt64(16, f);
  }
};
/**
 * optional string id = 1;
 * @return {string}
 */


proto.e.UserSegment.prototype.getId = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 1, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.e.UserSegment} returns this
 */


proto.e.UserSegment.prototype.setId = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional string name = 2;
 * @return {string}
 */


proto.e.UserSegment.prototype.getName = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 2, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.e.UserSegment} returns this
 */


proto.e.UserSegment.prototype.setName = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};
/**
 * repeated UserLabel user_labels = 3;
 * @return {!Array<!proto.e.UserLabel>}
 */


proto.e.UserSegment.prototype.getUserLabelsList = function () {
  return (
    /** @type{!Array<!proto.e.UserLabel>} */
    jspb.Message.getRepeatedWrapperField(this, proto.e.UserLabel, 3)
  );
};
/**
 * @param {!Array<!proto.e.UserLabel>} value
 * @return {!proto.e.UserSegment} returns this
*/


proto.e.UserSegment.prototype.setUserLabelsList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};
/**
 * @param {!proto.e.UserLabel=} opt_value
 * @param {number=} opt_index
 * @return {!proto.e.UserLabel}
 */


proto.e.UserSegment.prototype.addUserLabels = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.e.UserLabel, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.e.UserSegment} returns this
 */


proto.e.UserSegment.prototype.clearUserLabelsList = function () {
  return this.setUserLabelsList([]);
};
/**
 * optional LogicalOperator operator = 4;
 * @return {!proto.e.LogicalOperator}
 */


proto.e.UserSegment.prototype.getOperator = function () {
  return (
    /** @type {!proto.e.LogicalOperator} */
    jspb.Message.getFieldWithDefault(this, 4, 0)
  );
};
/**
 * @param {!proto.e.LogicalOperator} value
 * @return {!proto.e.UserSegment} returns this
 */


proto.e.UserSegment.prototype.setOperator = function (value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};
/**
 * optional google.protobuf.Timestamp create_time = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */


proto.e.UserSegment.prototype.getCreateTime = function () {
  return (
    /** @type{?proto.google.protobuf.Timestamp} */
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5)
  );
};
/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.e.UserSegment} returns this
*/


proto.e.UserSegment.prototype.setCreateTime = function (value) {
  return jspb.Message.setWrapperField(this, 5, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.e.UserSegment} returns this
 */


proto.e.UserSegment.prototype.clearCreateTime = function () {
  return this.setCreateTime(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.e.UserSegment.prototype.hasCreateTime = function () {
  return jspb.Message.getField(this, 5) != null;
};
/**
 * optional google.protobuf.Timestamp last_update_time = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */


proto.e.UserSegment.prototype.getLastUpdateTime = function () {
  return (
    /** @type{?proto.google.protobuf.Timestamp} */
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6)
  );
};
/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.e.UserSegment} returns this
*/


proto.e.UserSegment.prototype.setLastUpdateTime = function (value) {
  return jspb.Message.setWrapperField(this, 6, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.e.UserSegment} returns this
 */


proto.e.UserSegment.prototype.clearLastUpdateTime = function () {
  return this.setLastUpdateTime(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.e.UserSegment.prototype.hasLastUpdateTime = function () {
  return jspb.Message.getField(this, 6) != null;
};
/**
 * optional int64 chatbot_id = 16;
 * @return {number}
 */


proto.e.UserSegment.prototype.getChatbotId = function () {
  return (
    /** @type {number} */
    jspb.Message.getFieldWithDefault(this, 16, 0)
  );
};
/**
 * @param {number} value
 * @return {!proto.e.UserSegment} returns this
 */


proto.e.UserSegment.prototype.setChatbotId = function (value) {
  return jspb.Message.setProto3IntField(this, 16, value);
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */


proto.e.UserLabel.repeatedFields_ = [3];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.e.UserLabel.prototype.toObject = function (opt_includeInstance) {
    return proto.e.UserLabel.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.e.UserLabel} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.e.UserLabel.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, ""),
      name: jspb.Message.getFieldWithDefault(msg, 2, ""),
      conditionsList: jspb.Message.toObjectList(msg.getConditionsList(), proto.e.Condition.toObject, includeInstance),
      operator: jspb.Message.getFieldWithDefault(msg, 4, 0),
      createTime: (f = msg.getCreateTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
      lastUpdateTime: (f = msg.getLastUpdateTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
      chatbotId: jspb.Message.getFieldWithDefault(msg, 16, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.e.UserLabel}
 */


proto.e.UserLabel.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.e.UserLabel();
  return proto.e.UserLabel.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.e.UserLabel} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.e.UserLabel}
 */


proto.e.UserLabel.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setId(value);
        break;

      case 2:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setName(value);
        break;

      case 3:
        var value = new proto.e.Condition();
        reader.readMessage(value, proto.e.Condition.deserializeBinaryFromReader);
        msg.addConditions(value);
        break;

      case 4:
        var value =
        /** @type {!proto.e.LogicalOperator} */
        reader.readEnum();
        msg.setOperator(value);
        break;

      case 5:
        var value = new google_protobuf_timestamp_pb.Timestamp();
        reader.readMessage(value, google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
        msg.setCreateTime(value);
        break;

      case 6:
        var value = new google_protobuf_timestamp_pb.Timestamp();
        reader.readMessage(value, google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
        msg.setLastUpdateTime(value);
        break;

      case 16:
        var value =
        /** @type {number} */
        reader.readInt64();
        msg.setChatbotId(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.e.UserLabel.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.e.UserLabel.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.e.UserLabel} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.e.UserLabel.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getId();

  if (f.length > 0) {
    writer.writeString(1, f);
  }

  f = message.getName();

  if (f.length > 0) {
    writer.writeString(2, f);
  }

  f = message.getConditionsList();

  if (f.length > 0) {
    writer.writeRepeatedMessage(3, f, proto.e.Condition.serializeBinaryToWriter);
  }

  f = message.getOperator();

  if (f !== 0.0) {
    writer.writeEnum(4, f);
  }

  f = message.getCreateTime();

  if (f != null) {
    writer.writeMessage(5, f, google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter);
  }

  f = message.getLastUpdateTime();

  if (f != null) {
    writer.writeMessage(6, f, google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter);
  }

  f = message.getChatbotId();

  if (f !== 0) {
    writer.writeInt64(16, f);
  }
};
/**
 * optional string id = 1;
 * @return {string}
 */


proto.e.UserLabel.prototype.getId = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 1, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.e.UserLabel} returns this
 */


proto.e.UserLabel.prototype.setId = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional string name = 2;
 * @return {string}
 */


proto.e.UserLabel.prototype.getName = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 2, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.e.UserLabel} returns this
 */


proto.e.UserLabel.prototype.setName = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};
/**
 * repeated Condition conditions = 3;
 * @return {!Array<!proto.e.Condition>}
 */


proto.e.UserLabel.prototype.getConditionsList = function () {
  return (
    /** @type{!Array<!proto.e.Condition>} */
    jspb.Message.getRepeatedWrapperField(this, proto.e.Condition, 3)
  );
};
/**
 * @param {!Array<!proto.e.Condition>} value
 * @return {!proto.e.UserLabel} returns this
*/


proto.e.UserLabel.prototype.setConditionsList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};
/**
 * @param {!proto.e.Condition=} opt_value
 * @param {number=} opt_index
 * @return {!proto.e.Condition}
 */


proto.e.UserLabel.prototype.addConditions = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.e.Condition, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.e.UserLabel} returns this
 */


proto.e.UserLabel.prototype.clearConditionsList = function () {
  return this.setConditionsList([]);
};
/**
 * optional LogicalOperator operator = 4;
 * @return {!proto.e.LogicalOperator}
 */


proto.e.UserLabel.prototype.getOperator = function () {
  return (
    /** @type {!proto.e.LogicalOperator} */
    jspb.Message.getFieldWithDefault(this, 4, 0)
  );
};
/**
 * @param {!proto.e.LogicalOperator} value
 * @return {!proto.e.UserLabel} returns this
 */


proto.e.UserLabel.prototype.setOperator = function (value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};
/**
 * optional google.protobuf.Timestamp create_time = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */


proto.e.UserLabel.prototype.getCreateTime = function () {
  return (
    /** @type{?proto.google.protobuf.Timestamp} */
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5)
  );
};
/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.e.UserLabel} returns this
*/


proto.e.UserLabel.prototype.setCreateTime = function (value) {
  return jspb.Message.setWrapperField(this, 5, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.e.UserLabel} returns this
 */


proto.e.UserLabel.prototype.clearCreateTime = function () {
  return this.setCreateTime(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.e.UserLabel.prototype.hasCreateTime = function () {
  return jspb.Message.getField(this, 5) != null;
};
/**
 * optional google.protobuf.Timestamp last_update_time = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */


proto.e.UserLabel.prototype.getLastUpdateTime = function () {
  return (
    /** @type{?proto.google.protobuf.Timestamp} */
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6)
  );
};
/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.e.UserLabel} returns this
*/


proto.e.UserLabel.prototype.setLastUpdateTime = function (value) {
  return jspb.Message.setWrapperField(this, 6, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.e.UserLabel} returns this
 */


proto.e.UserLabel.prototype.clearLastUpdateTime = function () {
  return this.setLastUpdateTime(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.e.UserLabel.prototype.hasLastUpdateTime = function () {
  return jspb.Message.getField(this, 6) != null;
};
/**
 * optional int64 chatbot_id = 16;
 * @return {number}
 */


proto.e.UserLabel.prototype.getChatbotId = function () {
  return (
    /** @type {number} */
    jspb.Message.getFieldWithDefault(this, 16, 0)
  );
};
/**
 * @param {number} value
 * @return {!proto.e.UserLabel} returns this
 */


proto.e.UserLabel.prototype.setChatbotId = function (value) {
  return jspb.Message.setProto3IntField(this, 16, value);
};
/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */


proto.e.Condition.oneofGroups_ = [[2, 3, 4]];
/**
 * @enum {number}
 */

proto.e.Condition.ConditionCase = {
  CONDITION_NOT_SET: 0,
  AGGREGATE_CONDITION: 2,
  CLAUSE_CONDITION: 3,
  COUNT_CONDITION: 4
};
/**
 * @return {proto.e.Condition.ConditionCase}
 */

proto.e.Condition.prototype.getConditionCase = function () {
  return (
    /** @type {proto.e.Condition.ConditionCase} */
    jspb.Message.computeOneofCase(this, proto.e.Condition.oneofGroups_[0])
  );
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.e.Condition.prototype.toObject = function (opt_includeInstance) {
    return proto.e.Condition.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.e.Condition} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.e.Condition.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      type: jspb.Message.getFieldWithDefault(msg, 1, 0),
      aggregateCondition: (f = msg.getAggregateCondition()) && proto.e.AggregateCondition.toObject(includeInstance, f),
      clauseCondition: (f = msg.getClauseCondition()) && proto.e.ClauseCondition.toObject(includeInstance, f),
      countCondition: (f = msg.getCountCondition()) && proto.e.CountCondition.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.e.Condition}
 */


proto.e.Condition.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.e.Condition();
  return proto.e.Condition.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.e.Condition} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.e.Condition}
 */


proto.e.Condition.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value =
        /** @type {!proto.e.ConditionType} */
        reader.readEnum();
        msg.setType(value);
        break;

      case 2:
        var value = new proto.e.AggregateCondition();
        reader.readMessage(value, proto.e.AggregateCondition.deserializeBinaryFromReader);
        msg.setAggregateCondition(value);
        break;

      case 3:
        var value = new proto.e.ClauseCondition();
        reader.readMessage(value, proto.e.ClauseCondition.deserializeBinaryFromReader);
        msg.setClauseCondition(value);
        break;

      case 4:
        var value = new proto.e.CountCondition();
        reader.readMessage(value, proto.e.CountCondition.deserializeBinaryFromReader);
        msg.setCountCondition(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.e.Condition.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.e.Condition.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.e.Condition} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.e.Condition.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getType();

  if (f !== 0.0) {
    writer.writeEnum(1, f);
  }

  f = message.getAggregateCondition();

  if (f != null) {
    writer.writeMessage(2, f, proto.e.AggregateCondition.serializeBinaryToWriter);
  }

  f = message.getClauseCondition();

  if (f != null) {
    writer.writeMessage(3, f, proto.e.ClauseCondition.serializeBinaryToWriter);
  }

  f = message.getCountCondition();

  if (f != null) {
    writer.writeMessage(4, f, proto.e.CountCondition.serializeBinaryToWriter);
  }
};
/**
 * optional ConditionType type = 1;
 * @return {!proto.e.ConditionType}
 */


proto.e.Condition.prototype.getType = function () {
  return (
    /** @type {!proto.e.ConditionType} */
    jspb.Message.getFieldWithDefault(this, 1, 0)
  );
};
/**
 * @param {!proto.e.ConditionType} value
 * @return {!proto.e.Condition} returns this
 */


proto.e.Condition.prototype.setType = function (value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};
/**
 * optional AggregateCondition aggregate_condition = 2;
 * @return {?proto.e.AggregateCondition}
 */


proto.e.Condition.prototype.getAggregateCondition = function () {
  return (
    /** @type{?proto.e.AggregateCondition} */
    jspb.Message.getWrapperField(this, proto.e.AggregateCondition, 2)
  );
};
/**
 * @param {?proto.e.AggregateCondition|undefined} value
 * @return {!proto.e.Condition} returns this
*/


proto.e.Condition.prototype.setAggregateCondition = function (value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.e.Condition.oneofGroups_[0], value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.e.Condition} returns this
 */


proto.e.Condition.prototype.clearAggregateCondition = function () {
  return this.setAggregateCondition(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.e.Condition.prototype.hasAggregateCondition = function () {
  return jspb.Message.getField(this, 2) != null;
};
/**
 * optional ClauseCondition clause_condition = 3;
 * @return {?proto.e.ClauseCondition}
 */


proto.e.Condition.prototype.getClauseCondition = function () {
  return (
    /** @type{?proto.e.ClauseCondition} */
    jspb.Message.getWrapperField(this, proto.e.ClauseCondition, 3)
  );
};
/**
 * @param {?proto.e.ClauseCondition|undefined} value
 * @return {!proto.e.Condition} returns this
*/


proto.e.Condition.prototype.setClauseCondition = function (value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.e.Condition.oneofGroups_[0], value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.e.Condition} returns this
 */


proto.e.Condition.prototype.clearClauseCondition = function () {
  return this.setClauseCondition(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.e.Condition.prototype.hasClauseCondition = function () {
  return jspb.Message.getField(this, 3) != null;
};
/**
 * optional CountCondition count_condition = 4;
 * @return {?proto.e.CountCondition}
 */


proto.e.Condition.prototype.getCountCondition = function () {
  return (
    /** @type{?proto.e.CountCondition} */
    jspb.Message.getWrapperField(this, proto.e.CountCondition, 4)
  );
};
/**
 * @param {?proto.e.CountCondition|undefined} value
 * @return {!proto.e.Condition} returns this
*/


proto.e.Condition.prototype.setCountCondition = function (value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.e.Condition.oneofGroups_[0], value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.e.Condition} returns this
 */


proto.e.Condition.prototype.clearCountCondition = function () {
  return this.setCountCondition(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.e.Condition.prototype.hasCountCondition = function () {
  return jspb.Message.getField(this, 4) != null;
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.e.AggregateCondition.prototype.toObject = function (opt_includeInstance) {
    return proto.e.AggregateCondition.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.e.AggregateCondition} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.e.AggregateCondition.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      aggregateFunction: jspb.Message.getFieldWithDefault(msg, 1, 0),
      operator: jspb.Message.getFieldWithDefault(msg, 2, 0),
      value: (f = msg.getValue()) && proto.e.Value.toObject(includeInstance, f),
      field: (f = msg.getField()) && proto.e.Field.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.e.AggregateCondition}
 */


proto.e.AggregateCondition.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.e.AggregateCondition();
  return proto.e.AggregateCondition.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.e.AggregateCondition} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.e.AggregateCondition}
 */


proto.e.AggregateCondition.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value =
        /** @type {!proto.e.AggregateFunction} */
        reader.readEnum();
        msg.setAggregateFunction(value);
        break;

      case 2:
        var value =
        /** @type {!proto.e.ComparisonOperator} */
        reader.readEnum();
        msg.setOperator(value);
        break;

      case 3:
        var value = new proto.e.Value();
        reader.readMessage(value, proto.e.Value.deserializeBinaryFromReader);
        msg.setValue(value);
        break;

      case 4:
        var value = new proto.e.Field();
        reader.readMessage(value, proto.e.Field.deserializeBinaryFromReader);
        msg.setField(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.e.AggregateCondition.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.e.AggregateCondition.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.e.AggregateCondition} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.e.AggregateCondition.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getAggregateFunction();

  if (f !== 0.0) {
    writer.writeEnum(1, f);
  }

  f = message.getOperator();

  if (f !== 0.0) {
    writer.writeEnum(2, f);
  }

  f = message.getValue();

  if (f != null) {
    writer.writeMessage(3, f, proto.e.Value.serializeBinaryToWriter);
  }

  f = message.getField();

  if (f != null) {
    writer.writeMessage(4, f, proto.e.Field.serializeBinaryToWriter);
  }
};
/**
 * optional AggregateFunction aggregate_function = 1;
 * @return {!proto.e.AggregateFunction}
 */


proto.e.AggregateCondition.prototype.getAggregateFunction = function () {
  return (
    /** @type {!proto.e.AggregateFunction} */
    jspb.Message.getFieldWithDefault(this, 1, 0)
  );
};
/**
 * @param {!proto.e.AggregateFunction} value
 * @return {!proto.e.AggregateCondition} returns this
 */


proto.e.AggregateCondition.prototype.setAggregateFunction = function (value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};
/**
 * optional ComparisonOperator operator = 2;
 * @return {!proto.e.ComparisonOperator}
 */


proto.e.AggregateCondition.prototype.getOperator = function () {
  return (
    /** @type {!proto.e.ComparisonOperator} */
    jspb.Message.getFieldWithDefault(this, 2, 0)
  );
};
/**
 * @param {!proto.e.ComparisonOperator} value
 * @return {!proto.e.AggregateCondition} returns this
 */


proto.e.AggregateCondition.prototype.setOperator = function (value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};
/**
 * optional Value value = 3;
 * @return {?proto.e.Value}
 */


proto.e.AggregateCondition.prototype.getValue = function () {
  return (
    /** @type{?proto.e.Value} */
    jspb.Message.getWrapperField(this, proto.e.Value, 3)
  );
};
/**
 * @param {?proto.e.Value|undefined} value
 * @return {!proto.e.AggregateCondition} returns this
*/


proto.e.AggregateCondition.prototype.setValue = function (value) {
  return jspb.Message.setWrapperField(this, 3, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.e.AggregateCondition} returns this
 */


proto.e.AggregateCondition.prototype.clearValue = function () {
  return this.setValue(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.e.AggregateCondition.prototype.hasValue = function () {
  return jspb.Message.getField(this, 3) != null;
};
/**
 * optional Field field = 4;
 * @return {?proto.e.Field}
 */


proto.e.AggregateCondition.prototype.getField = function () {
  return (
    /** @type{?proto.e.Field} */
    jspb.Message.getWrapperField(this, proto.e.Field, 4)
  );
};
/**
 * @param {?proto.e.Field|undefined} value
 * @return {!proto.e.AggregateCondition} returns this
*/


proto.e.AggregateCondition.prototype.setField = function (value) {
  return jspb.Message.setWrapperField(this, 4, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.e.AggregateCondition} returns this
 */


proto.e.AggregateCondition.prototype.clearField = function () {
  return this.setField(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.e.AggregateCondition.prototype.hasField = function () {
  return jspb.Message.getField(this, 4) != null;
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.e.CountCondition.prototype.toObject = function (opt_includeInstance) {
    return proto.e.CountCondition.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.e.CountCondition} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.e.CountCondition.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      clauseCondition: (f = msg.getClauseCondition()) && proto.e.ClauseCondition.toObject(includeInstance, f),
      operator: jspb.Message.getFieldWithDefault(msg, 2, 0),
      value: jspb.Message.getFieldWithDefault(msg, 3, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.e.CountCondition}
 */


proto.e.CountCondition.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.e.CountCondition();
  return proto.e.CountCondition.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.e.CountCondition} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.e.CountCondition}
 */


proto.e.CountCondition.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value = new proto.e.ClauseCondition();
        reader.readMessage(value, proto.e.ClauseCondition.deserializeBinaryFromReader);
        msg.setClauseCondition(value);
        break;

      case 2:
        var value =
        /** @type {!proto.e.ComparisonOperator} */
        reader.readEnum();
        msg.setOperator(value);
        break;

      case 3:
        var value =
        /** @type {number} */
        reader.readInt64();
        msg.setValue(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.e.CountCondition.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.e.CountCondition.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.e.CountCondition} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.e.CountCondition.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getClauseCondition();

  if (f != null) {
    writer.writeMessage(1, f, proto.e.ClauseCondition.serializeBinaryToWriter);
  }

  f = message.getOperator();

  if (f !== 0.0) {
    writer.writeEnum(2, f);
  }

  f = message.getValue();

  if (f !== 0) {
    writer.writeInt64(3, f);
  }
};
/**
 * optional ClauseCondition clause_condition = 1;
 * @return {?proto.e.ClauseCondition}
 */


proto.e.CountCondition.prototype.getClauseCondition = function () {
  return (
    /** @type{?proto.e.ClauseCondition} */
    jspb.Message.getWrapperField(this, proto.e.ClauseCondition, 1)
  );
};
/**
 * @param {?proto.e.ClauseCondition|undefined} value
 * @return {!proto.e.CountCondition} returns this
*/


proto.e.CountCondition.prototype.setClauseCondition = function (value) {
  return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.e.CountCondition} returns this
 */


proto.e.CountCondition.prototype.clearClauseCondition = function () {
  return this.setClauseCondition(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.e.CountCondition.prototype.hasClauseCondition = function () {
  return jspb.Message.getField(this, 1) != null;
};
/**
 * optional ComparisonOperator operator = 2;
 * @return {!proto.e.ComparisonOperator}
 */


proto.e.CountCondition.prototype.getOperator = function () {
  return (
    /** @type {!proto.e.ComparisonOperator} */
    jspb.Message.getFieldWithDefault(this, 2, 0)
  );
};
/**
 * @param {!proto.e.ComparisonOperator} value
 * @return {!proto.e.CountCondition} returns this
 */


proto.e.CountCondition.prototype.setOperator = function (value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};
/**
 * optional int64 value = 3;
 * @return {number}
 */


proto.e.CountCondition.prototype.getValue = function () {
  return (
    /** @type {number} */
    jspb.Message.getFieldWithDefault(this, 3, 0)
  );
};
/**
 * @param {number} value
 * @return {!proto.e.CountCondition} returns this
 */


proto.e.CountCondition.prototype.setValue = function (value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.e.ClauseCondition.prototype.toObject = function (opt_includeInstance) {
    return proto.e.ClauseCondition.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.e.ClauseCondition} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.e.ClauseCondition.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      field: (f = msg.getField()) && proto.e.Field.toObject(includeInstance, f),
      operator: jspb.Message.getFieldWithDefault(msg, 2, 0),
      value: (f = msg.getValue()) && proto.e.Value.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.e.ClauseCondition}
 */


proto.e.ClauseCondition.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.e.ClauseCondition();
  return proto.e.ClauseCondition.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.e.ClauseCondition} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.e.ClauseCondition}
 */


proto.e.ClauseCondition.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value = new proto.e.Field();
        reader.readMessage(value, proto.e.Field.deserializeBinaryFromReader);
        msg.setField(value);
        break;

      case 2:
        var value =
        /** @type {!proto.e.ComparisonOperator} */
        reader.readEnum();
        msg.setOperator(value);
        break;

      case 3:
        var value = new proto.e.Value();
        reader.readMessage(value, proto.e.Value.deserializeBinaryFromReader);
        msg.setValue(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.e.ClauseCondition.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.e.ClauseCondition.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.e.ClauseCondition} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.e.ClauseCondition.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getField();

  if (f != null) {
    writer.writeMessage(1, f, proto.e.Field.serializeBinaryToWriter);
  }

  f = message.getOperator();

  if (f !== 0.0) {
    writer.writeEnum(2, f);
  }

  f = message.getValue();

  if (f != null) {
    writer.writeMessage(3, f, proto.e.Value.serializeBinaryToWriter);
  }
};
/**
 * optional Field field = 1;
 * @return {?proto.e.Field}
 */


proto.e.ClauseCondition.prototype.getField = function () {
  return (
    /** @type{?proto.e.Field} */
    jspb.Message.getWrapperField(this, proto.e.Field, 1)
  );
};
/**
 * @param {?proto.e.Field|undefined} value
 * @return {!proto.e.ClauseCondition} returns this
*/


proto.e.ClauseCondition.prototype.setField = function (value) {
  return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.e.ClauseCondition} returns this
 */


proto.e.ClauseCondition.prototype.clearField = function () {
  return this.setField(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.e.ClauseCondition.prototype.hasField = function () {
  return jspb.Message.getField(this, 1) != null;
};
/**
 * optional ComparisonOperator operator = 2;
 * @return {!proto.e.ComparisonOperator}
 */


proto.e.ClauseCondition.prototype.getOperator = function () {
  return (
    /** @type {!proto.e.ComparisonOperator} */
    jspb.Message.getFieldWithDefault(this, 2, 0)
  );
};
/**
 * @param {!proto.e.ComparisonOperator} value
 * @return {!proto.e.ClauseCondition} returns this
 */


proto.e.ClauseCondition.prototype.setOperator = function (value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};
/**
 * optional Value value = 3;
 * @return {?proto.e.Value}
 */


proto.e.ClauseCondition.prototype.getValue = function () {
  return (
    /** @type{?proto.e.Value} */
    jspb.Message.getWrapperField(this, proto.e.Value, 3)
  );
};
/**
 * @param {?proto.e.Value|undefined} value
 * @return {!proto.e.ClauseCondition} returns this
*/


proto.e.ClauseCondition.prototype.setValue = function (value) {
  return jspb.Message.setWrapperField(this, 3, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.e.ClauseCondition} returns this
 */


proto.e.ClauseCondition.prototype.clearValue = function () {
  return this.setValue(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.e.ClauseCondition.prototype.hasValue = function () {
  return jspb.Message.getField(this, 3) != null;
};
/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */


proto.e.Value.oneofGroups_ = [[2, 3, 4, 5, 6, 7]];
/**
 * @enum {number}
 */

proto.e.Value.ValueCase = {
  VALUE_NOT_SET: 0,
  STRING_VALUE: 2,
  INTEGER_VALUE: 3,
  DATETIME_VALUE: 4,
  DOUBLE_VALUE: 5,
  BOOL_VALUE: 6,
  ENCRYPTED_VALUE: 7
};
/**
 * @return {proto.e.Value.ValueCase}
 */

proto.e.Value.prototype.getValueCase = function () {
  return (
    /** @type {proto.e.Value.ValueCase} */
    jspb.Message.computeOneofCase(this, proto.e.Value.oneofGroups_[0])
  );
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.e.Value.prototype.toObject = function (opt_includeInstance) {
    return proto.e.Value.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.e.Value} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.e.Value.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      type: jspb.Message.getFieldWithDefault(msg, 1, 0),
      stringValue: jspb.Message.getFieldWithDefault(msg, 2, ""),
      integerValue: jspb.Message.getFieldWithDefault(msg, 3, 0),
      datetimeValue: (f = msg.getDatetimeValue()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
      doubleValue: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
      boolValue: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
      encryptedValue: jspb.Message.getFieldWithDefault(msg, 7, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.e.Value}
 */


proto.e.Value.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.e.Value();
  return proto.e.Value.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.e.Value} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.e.Value}
 */


proto.e.Value.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value =
        /** @type {!proto.e.DataType} */
        reader.readEnum();
        msg.setType(value);
        break;

      case 2:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setStringValue(value);
        break;

      case 3:
        var value =
        /** @type {number} */
        reader.readInt64();
        msg.setIntegerValue(value);
        break;

      case 4:
        var value = new google_protobuf_timestamp_pb.Timestamp();
        reader.readMessage(value, google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
        msg.setDatetimeValue(value);
        break;

      case 5:
        var value =
        /** @type {number} */
        reader.readDouble();
        msg.setDoubleValue(value);
        break;

      case 6:
        var value =
        /** @type {boolean} */
        reader.readBool();
        msg.setBoolValue(value);
        break;

      case 7:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setEncryptedValue(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.e.Value.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.e.Value.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.e.Value} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.e.Value.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getType();

  if (f !== 0.0) {
    writer.writeEnum(1, f);
  }

  f =
  /** @type {string} */
  jspb.Message.getField(message, 2);

  if (f != null) {
    writer.writeString(2, f);
  }

  f =
  /** @type {number} */
  jspb.Message.getField(message, 3);

  if (f != null) {
    writer.writeInt64(3, f);
  }

  f = message.getDatetimeValue();

  if (f != null) {
    writer.writeMessage(4, f, google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter);
  }

  f =
  /** @type {number} */
  jspb.Message.getField(message, 5);

  if (f != null) {
    writer.writeDouble(5, f);
  }

  f =
  /** @type {boolean} */
  jspb.Message.getField(message, 6);

  if (f != null) {
    writer.writeBool(6, f);
  }

  f =
  /** @type {string} */
  jspb.Message.getField(message, 7);

  if (f != null) {
    writer.writeString(7, f);
  }
};
/**
 * optional DataType type = 1;
 * @return {!proto.e.DataType}
 */


proto.e.Value.prototype.getType = function () {
  return (
    /** @type {!proto.e.DataType} */
    jspb.Message.getFieldWithDefault(this, 1, 0)
  );
};
/**
 * @param {!proto.e.DataType} value
 * @return {!proto.e.Value} returns this
 */


proto.e.Value.prototype.setType = function (value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};
/**
 * optional string string_value = 2;
 * @return {string}
 */


proto.e.Value.prototype.getStringValue = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 2, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.e.Value} returns this
 */


proto.e.Value.prototype.setStringValue = function (value) {
  return jspb.Message.setOneofField(this, 2, proto.e.Value.oneofGroups_[0], value);
};
/**
 * Clears the field making it undefined.
 * @return {!proto.e.Value} returns this
 */


proto.e.Value.prototype.clearStringValue = function () {
  return jspb.Message.setOneofField(this, 2, proto.e.Value.oneofGroups_[0], undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.e.Value.prototype.hasStringValue = function () {
  return jspb.Message.getField(this, 2) != null;
};
/**
 * optional int64 integer_value = 3;
 * @return {number}
 */


proto.e.Value.prototype.getIntegerValue = function () {
  return (
    /** @type {number} */
    jspb.Message.getFieldWithDefault(this, 3, 0)
  );
};
/**
 * @param {number} value
 * @return {!proto.e.Value} returns this
 */


proto.e.Value.prototype.setIntegerValue = function (value) {
  return jspb.Message.setOneofField(this, 3, proto.e.Value.oneofGroups_[0], value);
};
/**
 * Clears the field making it undefined.
 * @return {!proto.e.Value} returns this
 */


proto.e.Value.prototype.clearIntegerValue = function () {
  return jspb.Message.setOneofField(this, 3, proto.e.Value.oneofGroups_[0], undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.e.Value.prototype.hasIntegerValue = function () {
  return jspb.Message.getField(this, 3) != null;
};
/**
 * optional google.protobuf.Timestamp datetime_value = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */


proto.e.Value.prototype.getDatetimeValue = function () {
  return (
    /** @type{?proto.google.protobuf.Timestamp} */
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4)
  );
};
/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.e.Value} returns this
*/


proto.e.Value.prototype.setDatetimeValue = function (value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.e.Value.oneofGroups_[0], value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.e.Value} returns this
 */


proto.e.Value.prototype.clearDatetimeValue = function () {
  return this.setDatetimeValue(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.e.Value.prototype.hasDatetimeValue = function () {
  return jspb.Message.getField(this, 4) != null;
};
/**
 * optional double double_value = 5;
 * @return {number}
 */


proto.e.Value.prototype.getDoubleValue = function () {
  return (
    /** @type {number} */
    jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0)
  );
};
/**
 * @param {number} value
 * @return {!proto.e.Value} returns this
 */


proto.e.Value.prototype.setDoubleValue = function (value) {
  return jspb.Message.setOneofField(this, 5, proto.e.Value.oneofGroups_[0], value);
};
/**
 * Clears the field making it undefined.
 * @return {!proto.e.Value} returns this
 */


proto.e.Value.prototype.clearDoubleValue = function () {
  return jspb.Message.setOneofField(this, 5, proto.e.Value.oneofGroups_[0], undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.e.Value.prototype.hasDoubleValue = function () {
  return jspb.Message.getField(this, 5) != null;
};
/**
 * optional bool bool_value = 6;
 * @return {boolean}
 */


proto.e.Value.prototype.getBoolValue = function () {
  return (
    /** @type {boolean} */
    jspb.Message.getBooleanFieldWithDefault(this, 6, false)
  );
};
/**
 * @param {boolean} value
 * @return {!proto.e.Value} returns this
 */


proto.e.Value.prototype.setBoolValue = function (value) {
  return jspb.Message.setOneofField(this, 6, proto.e.Value.oneofGroups_[0], value);
};
/**
 * Clears the field making it undefined.
 * @return {!proto.e.Value} returns this
 */


proto.e.Value.prototype.clearBoolValue = function () {
  return jspb.Message.setOneofField(this, 6, proto.e.Value.oneofGroups_[0], undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.e.Value.prototype.hasBoolValue = function () {
  return jspb.Message.getField(this, 6) != null;
};
/**
 * optional string encrypted_value = 7;
 * @return {string}
 */


proto.e.Value.prototype.getEncryptedValue = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 7, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.e.Value} returns this
 */


proto.e.Value.prototype.setEncryptedValue = function (value) {
  return jspb.Message.setOneofField(this, 7, proto.e.Value.oneofGroups_[0], value);
};
/**
 * Clears the field making it undefined.
 * @return {!proto.e.Value} returns this
 */


proto.e.Value.prototype.clearEncryptedValue = function () {
  return jspb.Message.setOneofField(this, 7, proto.e.Value.oneofGroups_[0], undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.e.Value.prototype.hasEncryptedValue = function () {
  return jspb.Message.getField(this, 7) != null;
};
/**
 * @enum {number}
 */


proto.e.FieldCollectionType = {
  FIELD_COLLECTION_TYPE_UNSPECIFIED: 0,
  FIELD_COLLECTION_TYPE_UNIQUE: 1,
  FIELD_COLLECTION_TYPE_COMMON: 2
};
/**
 * @enum {number}
 */

proto.e.ConditionType = {
  CONDITION_TYPE_UNSPECIFIED: 0,
  CONDITION_TYPE_AGGREGATE: 1,
  CONDITION_TYPE_COMPARISON: 2,
  CONDITION_TYPE_COUNT: 3
};
/**
 * @enum {number}
 */

proto.e.LogicalOperator = {
  LOGICAL_OPERATOR_UNSPECIFIED: 0,
  LOGICAL_OPERATOR_AND: 1,
  LOGICAL_OPERATOR_OR: 2
};
/**
 * @enum {number}
 */

proto.e.AggregateFunction = {
  AGGREGATE_FUNCTION_UNSPECIFIED: 0,
  AGGREGATE_FUNCTION_MIN: 1,
  AGGREGATE_FUNCTION_MAX: 2,
  AGGREGATE_FUNCTION_SUM: 3
};
/**
 * @enum {number}
 */

proto.e.ComparisonOperator = {
  OPERATOR_TYPE_UNSPECIFIED: 0,
  OPERATOR_TYPE_EQUAL: 1,
  OPERATOR_TYPE_NOT_EQUAL: 2,
  OPERATOR_TYPE_GREATER_THAN: 3,
  OPERATOR_TYPE_LESS_THAN: 4,
  OPERATOR_TYPE_LARGER_THAN_OR_EQUAL_TO: 5,
  OPERATOR_TYPE_LESS_THAN_OR_EQUAL_TO: 6
};
/**
 * @enum {number}
 */

proto.e.DataType = {
  DATA_TYPE_UNSPECIFIED: 0,
  DATA_TYPE_STRING: 1,
  DATA_TYPE_INTEGER: 2,
  DATA_TYPE_DATETIME: 3,
  DATA_TYPE_DOUBLE: 4,
  DATA_TYPE_BOOLEAN: 5,
  DATA_TYPE_ENCRYPTED: 6
};
/**
 * @enum {number}
 */

proto.e.InputType = {
  INPUT_TYPE_UNSPECIFIED: 0,
  INPUT_TYPE_FREE_INPUT: 1,
  INPUT_TYPE_SELECT_INPUT: 2
};
goog.object.extend(exports, proto.e);