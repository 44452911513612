import React from 'react';
import WebviewButton from '@src/components/atoms/WebviewButton';
var ButtonList = function (_a) {
    var options = _a.options, title = _a.title, backgroundColor = _a.backgroundColor, onClick = _a.onClick;
    return (React.createElement("div", null,
        title && React.createElement("p", null, title),
        options &&
            options.map(function (_a, i) {
                var value = _a.value, text = _a.text;
                return (React.createElement(WebviewButton, { className: "webview-button", key: "select-" + value + "-" + i, onClick: function () { return onClick(text); }, height: "auto", backgroundColor: backgroundColor, shadowColor: backgroundColor, noWrap: true }, text));
            })));
};
export default ButtonList;
