var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import axiosInstance from './axiosInstance';
import transformResponse from './transformResponse';
import { objToQuery } from '@src/lib/helpers';
// EndUser endpoints
var collection = function (chatbotId) { return "/chatbots/" + chatbotId + "/end_users"; };
var member = function (chatbotId, endUserId) {
    return collection(chatbotId) + "/" + endUserId;
};
var filter = function (chatbotId) { return collection(chatbotId) + "/filter"; };
var blockMultipleUsers = function (chatbotId) {
    return collection(chatbotId) + "/block_selected_users";
};
// UserList EndUser endpoints
var userListCollection = function (chatbotId) {
    return "/chatbots/" + chatbotId + "/user_list";
};
var userListAll = function (chatbotId) {
    return userListCollection(chatbotId) + "/render_all_users";
};
var userListMember = function (chatbotId, userId) {
    return userListCollection(chatbotId) + "/" + userId;
};
var userListFilter = function (chatbotId) {
    return userListCollection(chatbotId) + "/search_name_and_message";
};
var talk = {
    collection: function (chatbotId) {
        return "/chatbots/" + chatbotId + "/talk_room/end_users";
    },
    member: function (chatbotId, id) {
        return talk.collection(chatbotId) + "/" + id;
    },
    filter: function (chatbotId) { return talk.collection(chatbotId) + "/filter"; },
};
export function searchUserList(chatbotId, options) {
    return __awaiter(this, void 0, Promise, function () {
        var queryString, url;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    queryString = objToQuery({ word: options === null || options === void 0 ? void 0 : options.filterString });
                    url = userListFilter(chatbotId) + "?" + queryString;
                    return [4 /*yield*/, transformResponse(axiosInstance.get(url))];
                case 1: return [2 /*return*/, _a.sent()];
            }
        });
    });
}
export function updateUsersChecked(chatbotId, endUserIds) {
    return __awaiter(this, void 0, Promise, function () {
        var url, body, res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    url = blockMultipleUsers(chatbotId);
                    body = { end_user_ids: endUserIds };
                    return [4 /*yield*/, transformResponse(axiosInstance.put(url, body))];
                case 1:
                    res = _a.sent();
                    return [2 /*return*/, res];
            }
        });
    });
}
export function getUserListMember(chatbotId, endUserId) {
    return __awaiter(this, void 0, Promise, function () {
        var url;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    url = userListMember(chatbotId, endUserId);
                    return [4 /*yield*/, transformResponse(axiosInstance.get(url))];
                case 1: return [2 /*return*/, _a.sent()];
            }
        });
    });
}
export function getUserListAll(chatbotId) {
    return __awaiter(this, void 0, Promise, function () {
        var url, res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    url = userListAll(chatbotId);
                    return [4 /*yield*/, transformResponse(axiosInstance.get(url))];
                case 1:
                    res = _a.sent();
                    return [2 /*return*/, res.end_users];
            }
        });
    });
}
export function getUserList(chatbotId, options) {
    return __awaiter(this, void 0, Promise, function () {
        var queryString, url, res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    queryString = objToQuery({
                        page: options === null || options === void 0 ? void 0 : options.page,
                        end_user_ids: options === null || options === void 0 ? void 0 : options.endUserIds,
                    });
                    url = userListCollection(chatbotId) + "?" + queryString;
                    return [4 /*yield*/, transformResponse(axiosInstance.get(url))];
                case 1:
                    res = _a.sent();
                    return [2 /*return*/, {
                            status: res.status,
                            end_user_number: res.end_user_number,
                            last_page: res.last_page,
                            end_users: res.end_users,
                        }];
            }
        });
    });
}
// EndUser Controller
export function updateEndUser(chatbotId, userId, update) {
    return __awaiter(this, void 0, Promise, function () {
        var url, res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    url = member(chatbotId, userId);
                    return [4 /*yield*/, transformResponse(axiosInstance.put(url, update))];
                case 1:
                    res = _a.sent();
                    return [2 /*return*/, res.end_user];
            }
        });
    });
}
export function getFilteredEndUsers(chatbotId, otpions) {
    return __awaiter(this, void 0, Promise, function () {
        var queryString, url;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    queryString = objToQuery({ filter_id: otpions === null || otpions === void 0 ? void 0 : otpions.filterId });
                    url = filter(chatbotId) + "?" + queryString;
                    return [4 /*yield*/, transformResponse(axiosInstance.get(url))];
                case 1: return [2 /*return*/, _a.sent()];
            }
        });
    });
}
// TalkRoom EndUser Controller
export function getTalkRoomEndUsers(chatbotId, options) {
    return __awaiter(this, void 0, Promise, function () {
        var queryString, url, res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    queryString = objToQuery({
                        page: options === null || options === void 0 ? void 0 : options.page,
                        search_term: options === null || options === void 0 ? void 0 : options.searchTerm,
                        latest_created_at: options === null || options === void 0 ? void 0 : options.latestCreatedAt,
                    });
                    url = talk.collection(chatbotId) + "?" + queryString;
                    return [4 /*yield*/, transformResponse(axiosInstance.get(url))];
                case 1:
                    res = _a.sent();
                    return [2 /*return*/, res.end_users];
            }
        });
    });
}
export function getTalkRoomEndUser(chatbotId, endUserId) {
    return __awaiter(this, void 0, Promise, function () {
        var url, res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    url = talk.member(chatbotId, endUserId);
                    return [4 /*yield*/, transformResponse(axiosInstance.get(url))];
                case 1:
                    res = _a.sent();
                    return [2 /*return*/, res.end_user];
            }
        });
    });
}
export function makeTalkRoomFilterEndUsers(chatbotId, options, body) {
    return __awaiter(this, void 0, Promise, function () {
        var queryString, url, res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    queryString = objToQuery(options);
                    url = talk.filter(chatbotId) + "?" + queryString;
                    return [4 /*yield*/, transformResponse(axiosInstance.post(url, body))];
                case 1:
                    res = _a.sent();
                    return [2 /*return*/, res.end_users];
            }
        });
    });
}
export function updateTalkRoomEndUser(chatbotId, endUserId, body) {
    return __awaiter(this, void 0, Promise, function () {
        var url, res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    url = talk.member(chatbotId, endUserId);
                    return [4 /*yield*/, transformResponse(axiosInstance.put(url, body))];
                case 1:
                    res = _a.sent();
                    return [2 /*return*/, res.end_user];
            }
        });
    });
}
