var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import { colors } from '@src/colors';
var StepDateSelectorContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  color: ", ";\n  align-items: center;\n  justify-content: space-between;\n  margin-bottom: 0.2rem;\n  width: 350px;\n  margin: 12px auto 0;\n\n  input {\n    padding: 0.5rem;\n    border-radius: 5px;\n  }\n\n  select {\n    padding: 10px;\n    border-radius: 5px;\n\n    option:first-child {\n      color: ", ";\n    }\n  }\n\n  > span {\n    position: relative;\n    display: inline-block;\n    border: 1px solid ", ";\n    border-radius: 3px;\n    width: 80px;\n\n    input,\n    select {\n      width: 100%;\n      height: 100%;\n      font-size: 14px;\n      text-align: right;\n    }\n\n    &.adicon_down:before {\n      position: absolute;\n      right: 8px;\n      top: 5px;\n      font-size: 24px;\n    }\n\n    &:first-child {\n      width: 100px;\n      height: 38px;\n    }\n  }\n"], ["\n  display: flex;\n  color: ", ";\n  align-items: center;\n  justify-content: space-between;\n  margin-bottom: 0.2rem;\n  width: 350px;\n  margin: 12px auto 0;\n\n  input {\n    padding: 0.5rem;\n    border-radius: 5px;\n  }\n\n  select {\n    padding: 10px;\n    border-radius: 5px;\n\n    option:first-child {\n      color: ", ";\n    }\n  }\n\n  > span {\n    position: relative;\n    display: inline-block;\n    border: 1px solid ", ";\n    border-radius: 3px;\n    width: 80px;\n\n    input,\n    select {\n      width: 100%;\n      height: 100%;\n      font-size: 14px;\n      text-align: right;\n    }\n\n    &.adicon_down:before {\n      position: absolute;\n      right: 8px;\n      top: 5px;\n      font-size: 24px;\n    }\n\n    &:first-child {\n      width: 100px;\n      height: 38px;\n    }\n  }\n"])), colors.text_pale, colors.text_pale, colors.border.default);
var HourSelect = function (_a) {
    var handleStepDateChange = _a.handleStepDateChange, hour = _a.hour;
    return (React.createElement("span", { className: "adicon_down" },
        React.createElement("select", { name: "hour", onChange: handleStepDateChange, defaultValue: hour },
            React.createElement("option", { value: "", disabled: true, hidden: true }, "Hr"),
            Array.from(Array(24).keys()).map(function (num, i) {
                return (React.createElement("option", { key: i + "-step-hour", value: num }, num));
            }))));
};
var MinuteSelect = function (_a) {
    var handleStepDateChange = _a.handleStepDateChange, minute = _a.minute;
    return (React.createElement("span", { className: "adicon_down" },
        React.createElement("select", { name: "minute", onChange: handleStepDateChange, defaultValue: minute },
            React.createElement("option", { value: "", disabled: true, hidden: true }, "Min"),
            Array.from(Array(60).keys()).map(function (num, i) {
                return (React.createElement("option", { key: i + "-step-minute", value: num }, num));
            }))));
};
var StepDateSelector = function (_a) {
    var handleStepDateChange = _a.handleStepDateChange, daysAfter = _a.daysAfter, hour = _a.hour, minute = _a.minute;
    return (React.createElement(StepDateSelectorContainer, null,
        React.createElement("span", null,
            React.createElement("input", { name: "daysAfter", type: "number", placeholder: "3", value: daysAfter, onChange: handleStepDateChange })),
        "\u65E5\u5F8C",
        React.createElement(HourSelect, { handleStepDateChange: handleStepDateChange, hour: hour }),
        "\uFF1A",
        React.createElement(MinuteSelect, { handleStepDateChange: handleStepDateChange, minute: minute })));
};
export default StepDateSelector;
var templateObject_1;
