import { select } from 'd3';
import { colors } from '@src/colors';
import { BUTTON_HEIGHT, CHOICE_HEIGHT, CHOICE_WIDTH, CHOICE_MARGIN, CHOICE_PADDING, BORDER_RADIUS, FONT_SIZE, } from './constants';
// interface TalkTriggerInterface {
//   getWidth(): number;
//   getHeight(): number;
//   calcChoiceX(prevX: number, choiceWidth: number): number;
//   render(x: number, y: number): void;
// }
var TalkTrigger = /** @class */ (function () {
    function TalkTrigger(block, talkTrigger) {
        this.block = block;
        this.talkTrigger = talkTrigger;
        this.msgType = 'talkTrigger';
    }
    TalkTrigger.prototype.getWidth = function () {
        return CHOICE_WIDTH + CHOICE_MARGIN;
    };
    TalkTrigger.prototype.getHeight = function () {
        return CHOICE_HEIGHT;
    };
    TalkTrigger.prototype.calcChoiceX = function (msgX, triggerIterator) {
        return msgX + (triggerIterator * CHOICE_WIDTH + CHOICE_MARGIN);
    };
    TalkTrigger.prototype.render = function (x, y) {
        var _this = this;
        select(this.block)
            .append('rect')
            .classed('message', true)
            .on('mousedown', function (e) {
            e.stopPropagation();
        })
            .classed('talk-trigger', true)
            .attr('id', "button-" + this.talkTrigger.id + "-trigger")
            .attr('x', this.calcChoiceX(x, 0))
            .attr('y', y)
            .attr('rx', BORDER_RADIUS)
            .attr('ry', BORDER_RADIUS)
            .attr('width', CHOICE_WIDTH)
            .attr('height', BUTTON_HEIGHT)
            .attr('fill', colors.default_text)
            .style('stroke', colors.default_text);
        // choice text
        select(this.block)
            .append('text')
            .on('mousedown', function (e) {
            e.stopPropagation();
        })
            .attr('class', 'messageText')
            .text(function () {
            return (_this.talkTrigger.block && _this.talkTrigger.block.name) || 'No Title';
        })
            .attr('fill', 'white')
            .attr('x', this.calcChoiceX(x, 0) + CHOICE_PADDING)
            .attr('y', y + CHOICE_PADDING + FONT_SIZE / 2);
    };
    return TalkTrigger;
}());
export default TalkTrigger;
