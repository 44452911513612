import React from 'react';
import { ModalPortal } from '@src/components/atoms';
import { Modal } from '@src/components/molecules';
import modalTypes from '@src/lib/modalTypes';
var BlockDeleteModal = function (_a) {
    var blockValidations = _a.blockValidations, onCancel = _a.onCancel;
    var msg = blockValidations.map(function (v) { return (React.createElement("p", { key: "block-err-" + v.errTxt }, v.errTxt)); });
    return (React.createElement(ModalPortal, null,
        React.createElement(Modal, { type: modalTypes.TEST, text: msg, onCancel: onCancel, "data-testid": "block-delete-modal" })));
};
export default BlockDeleteModal;
