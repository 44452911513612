import "core-js/modules/es.array.concat";
var zealsAdmin = {
  collection: function collection(formId, groupId) {
    return "/zeals_admin/api/custom_forms/".concat(formId, "/custom_form_question_groups/").concat(groupId, "/custom_form_questions");
  },
  member: function member(formId, groupId, id) {
    return "".concat(zealsAdmin.collection(formId, groupId), "/").concat(id);
  },
  createChoice: function createChoice(formId, groupId, questionId) {
    return "".concat(zealsAdmin.member(formId, groupId, questionId), "/create_choice");
  },
  updateChoice: function updateChoice(formId, groupId, questionId) {
    return "".concat(zealsAdmin.member(formId, groupId, questionId), "/update_choice");
  },
  destroyChoice: function destroyChoice(formId, groupId, questionId) {
    return "".concat(zealsAdmin.member(formId, groupId, questionId), "/destroy_choice");
  },
  createTrigger: function createTrigger(formId, groupId, questionId) {
    return "".concat(zealsAdmin.member(formId, groupId, questionId), "/create_trigger");
  },
  destroyTrigger: function destroyTrigger(formId, groupId, questionId) {
    return "".concat(zealsAdmin.member(formId, groupId, questionId), "/destroy_trigger");
  }
};
export default {
  zealsAdmin: zealsAdmin
};