var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { injectIntl } from 'react-intl';
import styled from 'styled-components';
import { colors } from '@src/colors';
import { centeredFlex } from '@src/lib/mixins';
import { mediaTypes } from '@src/lib/app-constants';
import { UploadLabel } from '@src/components/atoms';
import { mediaTypeMessages } from '@src/i18n/translations';
var DefaultThumb = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  height: 100%;\n  color: ", ";\n  padding: 0 25px;\n"], ["\n  ", ";\n  height: 100%;\n  color: ", ";\n  padding: 0 25px;\n"])), centeredFlex, colors.placeholder);
var UploadThumb = styled(DefaultThumb)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: none;\n  color: white;\n  justify-content: space-between;\n\n  span {\n    text-align: center;\n  }\n"], ["\n  display: none;\n  color: white;\n  justify-content: space-between;\n\n  span {\n    text-align: center;\n  }\n"])));
var UploadEditOptions = function (_a) {
    var 
    // when there's a media url already existing
    intl = _a.intl, onUploadClick = _a.onUploadClick, onDeleteClick = _a.onDeleteClick, onURLUploadClick = _a.onURLUploadClick, onAudioDetailsClick = _a.onAudioDetailsClick, onActionUrlEditClick = _a.onActionUrlEditClick, _b = _a.className, className = _b === void 0 ? 'file-uploaded-options' : _b, _c = _a.type, type = _c === void 0 ? mediaTypes.IMAGE : _c, props = __rest(_a, ["intl", "onUploadClick", "onDeleteClick", "onURLUploadClick", "onAudioDetailsClick", "onActionUrlEditClick", "className", "type"]);
    var uploadThumbText = intl.formatMessage(mediaTypeMessages[type]);
    return (React.createElement(UploadThumb, { className: className },
        onURLUploadClick && (React.createElement(UploadLabel, { "data-testid": "UploadEditOptions-IconUploadUrl-" + type, adicon: "adicon_edit", text: uploadThumbText + "URL", onClick: onURLUploadClick })),
        onUploadClick && (React.createElement(UploadLabel, { "data-testid": "UploadEditOptions-IconUploadFile-" + type, adicon: "adicon_upload", text: uploadThumbText + " " + intl.formatMessage({ id: "p4N05H", defaultMessage: "Upload" }), onClick: onUploadClick })),
        onDeleteClick && (React.createElement(UploadLabel, { "data-testid": "UploadEditOptions-IconDelete-" + type, adicon: "adicon_delete", text: "\u524A\u9664", onClick: onDeleteClick })),
        onActionUrlEditClick && (React.createElement(UploadLabel, { "data-testid": "UploadEditOptions-IconEditUrl-" + type, adicon: "adicon_edit", text: "URL\u5909\u66F4", onClick: onActionUrlEditClick }))));
};
export default injectIntl(UploadEditOptions);
var templateObject_1, templateObject_2;
