var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import { Button } from '@src/components/atoms';
import buttonTypes from '@src/lib/buttonTypes';
var ListContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 250px;\n  height: calc(100vh - 60px);\n  text-align: center;\n  border-right: 1px ridge;\n"], ["\n  width: 250px;\n  height: calc(100vh - 60px);\n  text-align: center;\n  border-right: 1px ridge;\n"])));
var ButtonContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  border-bottom: 1px ridge;\n  padding: 10px;\n"], ["\n  border-bottom: 1px ridge;\n  padding: 10px;\n"])));
var PluginSidebar = function (_a) {
    var text = _a.text, togglePathModal = _a.togglePathModal, children = _a.children;
    return (React.createElement(ListContainer, null,
        React.createElement(ButtonContainer, null,
            React.createElement(Button, { text: text, buttonType: buttonTypes.SETTING, onClick: togglePathModal })),
        children));
};
export default PluginSidebar;
var templateObject_1, templateObject_2;
