var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState } from 'react';
import styled from 'styled-components';
import { Input } from '@src/components/atoms';
import NextScenario from '@src/components/molecules/NextScenario';
import { getConnectedIds } from '@src/lib/adHelpers';
import { injectIntl } from 'react-intl';
var TalkRoomConnectSelectorWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  position: relative;\n  margin: ", ";\n"], ["\n  width: 100%;\n  position: relative;\n  margin: ", ";\n"])), function (props) { return (props.margin ? props.margin : 0); });
var TalkRoomConnectSelector = function (_a) {
    var scenarios = _a.scenarios, connectedObject = _a.connectedObject, isPersistentItem = _a.isPersistentItem, postbackActionType = _a.postbackActionType, margin = _a.margin, intl = _a.intl, rest = __rest(_a, ["scenarios", "connectedObject", "isPersistentItem", "postbackActionType", "margin", "intl"]);
    var _b = __read(useState(''), 2), filterText = _b[0], setFilterText = _b[1];
    var filterTextOnChange = function (e) {
        setFilterText(e.target.value);
    };
    var _c = getConnectedIds(connectedObject), blockId = _c.blockId, nextScenarioId = _c.nextScenarioId, scenarioId = _c.scenarioId;
    var connected = blockId || nextScenarioId || scenarioId;
    var inputPlaceholder = isPersistentItem
        ? intl.formatMessage({ id: "Ks5AQN", defaultMessage: "Please select a Scenario" })
        : intl.formatMessage({ id: "8aNEyr", defaultMessage: "Please enter the block name" });
    return connected ? (React.createElement(NextScenario, __assign({ connectedObject: connectedObject, scenarios: scenarios, forModal: true }, rest))) : (React.createElement(TalkRoomConnectSelectorWrapper, { className: "connect_selector_selector_wrapper", margin: margin },
        React.createElement(Input, { placeholder: inputPlaceholder, onChange: filterTextOnChange, dataTest: "TalkRoomConnectSelector_Input" })));
};
export default injectIntl(TalkRoomConnectSelector);
var templateObject_1;
