import "core-js/modules/es.array.concat";

var collection = function collection() {
  return "/chatbots";
};

var member = function member(chatbotId) {
  return "".concat(collection(), "/").concat(chatbotId);
};

var privacyPolicy = function privacyPolicy(chatbotId) {
  return "".concat(collection(), "/").concat(chatbotId, "/privacy_policy");
};

var pageLists = function pageLists(chatbotId) {
  return "".concat(member(chatbotId), "/page_lists");
};

var pageListsInstagram = function pageListsInstagram(chatbotId) {
  return "".concat(member(chatbotId), "/instagram_page_lists");
};

var connectPage = function connectPage(chatbotId) {
  return "".concat(member(chatbotId), "/connect_page");
};

var settings = function settings(chatbotId) {
  return "".concat(member(chatbotId), "/settings");
};

var mainSetting = function mainSetting(chatbotId) {
  return "".concat(member(chatbotId), "/update_initial_setting");
};

var zealsAdmin = {
  collection: function collection() {
    return "/zeals_admin/api/chatbots";
  }
};
export default {
  collection: collection,
  member: member,
  privacyPolicy: privacyPolicy,
  connectPage: connectPage,
  pageLists: pageLists,
  pageListsInstagram: pageListsInstagram,
  settings: settings,
  mainSetting: mainSetting,
  zealsAdmin: zealsAdmin
};