var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState } from 'react';
import styled from 'styled-components';
import { Input } from '@src/components/atoms';
import { colors, shadows } from '@src/colors';
import { transitions } from '@src/lib/animations';
import { injectIntl } from 'react-intl';
var SelectableListContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  .adicon_search {\n    padding-left: 8px;\n  }\n  .header {\n    margin-bottom: 16px;\n    > h3 {\n      margin-right: 16px;\n      line-height: 40px;\n      white-space: nowrap;\n    }\n  }\n"], ["\n  .adicon_search {\n    padding-left: 8px;\n  }\n  .header {\n    margin-bottom: 16px;\n    > h3 {\n      margin-right: 16px;\n      line-height: 40px;\n      white-space: nowrap;\n    }\n  }\n"])));
var SelectableListHTMLList = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  border: 1px solid ", ";\n  box-shadow: ", ";\n  border-radius: 5px;\n  padding: 10px;\n  .message-wrapper {\n    &.primary-label {\n      margin: 0 0 8px;\n    }\n  }\n  .selectableList-result-item {\n    cursor: pointer;\n    border-radius: 5px;\n    font-size: 14px;\n    margin-top: 8px;\n    overflow: auto;\n    transition: ", ";\n    &:hover {\n      background-color: ", ";\n      font-weight: bold;\n    }\n    &.active {\n      background-color: ", ";\n      font-weight: bold;\n    }\n  }\n  .selectableList-search {\n    input {\n      &::placeholder {\n        color: ", ";\n      }\n    }\n    &:hover {\n      box-shadow: none;\n      border: 1px solid ", ";\n    }\n  }\n  .adicon_search:before {\n    font-size: 20px;\n    margin-right: 8px;\n  }\n  .selectableList-result {\n    overflow: auto;\n    height: calc(100vh - 465px);\n  }\n"], ["\n  border: 1px solid ", ";\n  box-shadow: ", ";\n  border-radius: 5px;\n  padding: 10px;\n  .message-wrapper {\n    &.primary-label {\n      margin: 0 0 8px;\n    }\n  }\n  .selectableList-result-item {\n    cursor: pointer;\n    border-radius: 5px;\n    font-size: 14px;\n    margin-top: 8px;\n    overflow: auto;\n    transition: ", ";\n    &:hover {\n      background-color: ", ";\n      font-weight: bold;\n    }\n    &.active {\n      background-color: ", ";\n      font-weight: bold;\n    }\n  }\n  .selectableList-search {\n    input {\n      &::placeholder {\n        color: ", ";\n      }\n    }\n    &:hover {\n      box-shadow: none;\n      border: 1px solid ", ";\n    }\n  }\n  .adicon_search:before {\n    font-size: 20px;\n    margin-right: 8px;\n  }\n  .selectableList-result {\n    overflow: auto;\n    height: calc(100vh - 465px);\n  }\n"])), colors.border.default, shadows.small.light, transitions.normal, colors.background.light_purple, colors.background.active, colors.young_navy, colors.border.default);
var SelectableListHTMLButton = styled.button(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: block;\n  width: 100%;\n  padding: 10px;\n  margin: 0;\n  background: transparent;\n  border: 0;\n  text-align: left;\n"], ["\n  display: block;\n  width: 100%;\n  padding: 10px;\n  margin: 0;\n  background: transparent;\n  border: 0;\n  text-align: left;\n"])));
var SelectableList = function (_a) {
    var itemList = _a.itemList, title = _a.title, searchPlaceholder = _a.searchPlaceholder, searchTerm = _a.searchTerm, setSearchTerm = _a.setSearchTerm, sendSelectedItem = _a.sendSelectedItem, style = _a.style;
    var _b = __read(useState(), 2), selectedItemId = _b[0], setselectedItemId = _b[1];
    var filteredItemList = searchTerm
        ? itemList.filter(function (aq) { return aq.name && aq.name.includes(searchTerm); })
        : itemList;
    var onClickHandler = function (item) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            setselectedItemId(item.id);
            sendSelectedItem(item);
            return [2 /*return*/];
        });
    }); };
    var onChangeHandler = function (e) {
        setSearchTerm(e.currentTarget.value);
    };
    return (React.createElement(SelectableListContainer, { style: style, "data-testid": "SelectableList" },
        React.createElement(SelectableListHTMLList, null,
            title ? (React.createElement("div", { className: "message-wrapper primary-label" }, title)) : null,
            React.createElement("div", null,
                React.createElement(Input, { type: "text", className: "selectableList-search", iconClassName: "adicon_search", placeholder: searchPlaceholder || 'Search', value: searchTerm || '', onChange: onChangeHandler })),
            React.createElement("div", { className: "selectableList-result" }, filteredItemList.map(function (item) {
                if (item.name) {
                    return (React.createElement("div", { "data-testid": "SelectableList-Item", title: item.name, key: item.id, className: "question selectableList-result-item " + (selectedItemId === item.id ? 'active' : '') },
                        React.createElement(SelectableListHTMLButton, { onClick: function () { return onClickHandler(item); } }, item.name)));
                }
                return null;
            })))));
};
export default injectIntl(SelectableList);
var templateObject_1, templateObject_2, templateObject_3;
