import "core-js/modules/es.array.concat";
var zealsAdmin = {
  collection: function collection(customFormId) {
    return "/zeals_admin/api/custom_forms/".concat(customFormId, "/custom_form_conversion_tasks");
  },
  member: function member(customFormId, id) {
    return "".concat(zealsAdmin.collection(customFormId), "/").concat(id);
  },
  reset: function reset(customFormId, id) {
    return "".concat(zealsAdmin.collection(customFormId), "/").concat(id, "/reset");
  }
};
export default {
  zealsAdmin: zealsAdmin
};