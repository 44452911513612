var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import omit from 'lodash/omit';
import { colors } from '@src/colors';
import { tabTypes } from '@src/lib/app-constants';
import { AutoScrollModalContainer, Input, SelectFilterDropdown, CharacterCount, } from '@src/components/atoms';
import { createUserTagInQuickReplyChoice } from '@src/redux/fanp/actions/ad-scenario';
import { connectQuickReplyChoiceToBlock } from '@src/redux/fanp/actions/quickReply';
import { Modal, Button } from '@zeals-co-ltd/washi-components';
import ActionSelector from '@src/components/molecules/ActionSelector';
import useActionFormValidator from '@src/components/hooks/useActionFormValidator';
import quickTriggerModalSchema from '@src/lib/helpers/form_modal_schema/quickTriggerModalSchema';
import SelectableOptionSelector from '@src/fanp/enterprise/talk_customize/components/quick_reply/SelectableOptionSelector';
// import { updateInformationAssociatedWithQuickReplyChoice } from '@src/services/api/quickReplies';
import { createNewBlock } from '@src/redux/fanp/actions/ad-scenario/otherActions';
import { AlertModalContext } from '@src/components/molecules/AlertModal';
var MAX_WIDTH = 300;
var MIN_WIDTH = 250;
export var QuickReplyTypeEnum;
(function (QuickReplyTypeEnum) {
    QuickReplyTypeEnum["TagGroup"] = "TagGroup";
    QuickReplyTypeEnum["UserField"] = "UserField";
    QuickReplyTypeEnum["SingleItem"] = "SingleItem";
})(QuickReplyTypeEnum || (QuickReplyTypeEnum = {}));
var QuickTriggerModalWrapper = styled(AutoScrollModalContainer)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  > div.quick_trigger_modal_modal {\n    @media only screen and (max-width: 1440px) {\n      .section-controls {\n        padding-bottom: 30px;\n      }\n    }\n  }\n"], ["\n  > div.quick_trigger_modal_modal {\n    @media only screen and (max-width: 1440px) {\n      .section-controls {\n        padding-bottom: 30px;\n      }\n    }\n  }\n"])));
var QuickReplyChoiceWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  .quick_trigger_modal_dropdown {\n    width: 300px;\n    margin: 10px 0 0 0;\n  }\n  .emoji-selector {\n    display: inline-block;\n    margin-left: 8px;\n    margin-top: 12px;\n    line-height: 40px;\n  }\n  .label-bold {\n    font-weight: bold;\n  }\n  .adicon_tag::before {\n    font-size: 16px;\n    font-weight: normal;\n    margin-right: 4px;\n    display: inline-flex;\n    align-items: center;\n    justify-content: center;\n  }\n  .submit_button {\n    border-bottom: 1px solid ", ";\n  }\n  .error {\n    text-align: left;\n    margin: 4px 0 0 4px;\n  }\n"], ["\n  .quick_trigger_modal_dropdown {\n    width: 300px;\n    margin: 10px 0 0 0;\n  }\n  .emoji-selector {\n    display: inline-block;\n    margin-left: 8px;\n    margin-top: 12px;\n    line-height: 40px;\n  }\n  .label-bold {\n    font-weight: bold;\n  }\n  .adicon_tag::before {\n    font-size: 16px;\n    font-weight: normal;\n    margin-right: 4px;\n    display: inline-flex;\n    align-items: center;\n    justify-content: center;\n  }\n  .submit_button {\n    border-bottom: 1px solid ", ";\n  }\n  .error {\n    text-align: left;\n    margin: 4px 0 0 4px;\n  }\n"])), colors.border.default);
var ModalQuickReplyChoiceInput = styled(Input)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  margin: 12px 0 0 0;\n  border-color: ", ";\n  border-radius: 5px;\n  width: ", ";\n\n  input {\n    color: ", ";\n  }\n"], ["\n  margin: 12px 0 0 0;\n  border-color: ",
    ";\n  border-radius: 5px;\n  width: ", ";\n\n  input {\n    color: ", ";\n  }\n"])), function (props) {
    return props.hasError ? colors.error : colors.border.default;
}, function (props) { return (props.width > MAX_WIDTH ? '94%' : props.width + "px"); }, colors.quick_reply);
export var getQuickReplyType = function (quickReply) {
    var userTagGroupExist = !!(quickReply === null || quickReply === void 0 ? void 0 : quickReply.user_tag_group);
    var fieldIdExist = !!(quickReply === null || quickReply === void 0 ? void 0 : quickReply.field_id);
    var quickReplyType;
    quickReplyType = userTagGroupExist
        ? QuickReplyTypeEnum.TagGroup
        : quickReplyType;
    quickReplyType = fieldIdExist ? QuickReplyTypeEnum.UserField : quickReplyType;
    quickReplyType = quickReplyType || QuickReplyTypeEnum.SingleItem;
    return { userTagGroupExist: userTagGroupExist, fieldIdExist: fieldIdExist, quickReplyType: quickReplyType };
};
var QuickTriggerModal = function (props) {
    var _a, _b;
    var _c;
    var intl = props.intl, platform = props.platform, quickReply = props.quickReply, quickReplyChoice = props.quickReplyChoice, talkTrigger = props.talkTrigger, activeBlocks = props.activeBlocks, chatbotIdParam = props.chatbotIdParam, adScenarioIdParam = props.adScenarioIdParam, blockIdParam = props.blockIdParam, quickReplyId = props.quickReplyId, rank = props.rank, editQuickReplyChoiceUserTag = props.editQuickReplyChoiceUserTag, connectToBlock = props.connectToBlock, onConfirm = props.onConfirm, onCancel = props.onCancel, open = props.open, rest = __rest(props, ["intl", "platform", "quickReply", "quickReplyChoice", "talkTrigger", "activeBlocks", "chatbotIdParam", "adScenarioIdParam", "blockIdParam", "quickReplyId", "rank", "editQuickReplyChoiceUserTag", "connectToBlock", "onConfirm", "onCancel", "open"]);
    var dispatch = useDispatch();
    var handleModal = React.useContext(AlertModalContext).handleModal;
    var connectedObj = (quickReplyChoice ||
        talkTrigger);
    var _d = useActionFormValidator({
        intl: intl,
        platform: platform,
        object: {
            name: connectedObj.name,
            postback_action: connectedObj.postback_action,
        },
        schema: quickTriggerModalSchema,
    }), isValidating = _d.isValidating, values = _d.values, errors = _d.errors, touched = _d.touched, handleChange = _d.handleChange, handleChangePostbackAction = _d.handleChangePostbackAction, initActionSelectorValue = _d.initActionSelectorValue, actionSelectorOnChange = _d.actionSelectorOnChange;
    var _e = __read(useState(), 2), fieldSelectableOption = _e[0], setFieldSelectableOption = _e[1];
    var ignoreEffects = Object.values(omit(tabTypes, 'SCENARIO', 'BLOCK'));
    var _f = __read(useState(0), 2), cursorPosition = _f[0], setCursorPosition = _f[1];
    var _g = __read(useState(''), 2), searchTerm = _g[0], setSearchTerm = _g[1];
    var _h = __read(useState(false), 2), showSubmitButton = _h[0], setShowSubmitButton = _h[1];
    var handledUserTags = (!talkTrigger && ((_c = quickReply === null || quickReply === void 0 ? void 0 : quickReply.user_tag_group) === null || _c === void 0 ? void 0 : _c.user_tags)) || [];
    useEffect(function () {
        handleChangePostbackAction(connectedObj === null || connectedObj === void 0 ? void 0 : connectedObj.postback_action);
    }, [connectedObj === null || connectedObj === void 0 ? void 0 : connectedObj.postback_action]);
    var labelName = talkTrigger
        ? intl.formatMessage({ id: "n8IXxQ", defaultMessage: "User free input" })
        : values.name;
    var setUpQuickReplyChoiceUserTag = function (value) {
        if (!labelName)
            return alert('ラベルを入力してください');
        editQuickReplyChoiceUserTag({
            name: labelName,
            chatbotId: chatbotIdParam,
            scenarioId: adScenarioIdParam,
            blockId: blockIdParam,
            quickReplyId: quickReplyId,
            quickReplyChoiceId: quickReplyChoice.id,
            userTagId: value,
        });
        return undefined;
    };
    var handleSubmit = function () {
        var user_tag_group = quickReply.user_tag_group;
        dispatch(createUserTagInQuickReplyChoice(searchTerm, chatbotIdParam, user_tag_group.id, blockIdParam));
        setSearchTerm('');
    };
    var handleEnterKeyDown = function (e) {
        if (e.keyCode === 13)
            handleSubmit();
    };
    var handleNewBlockClick = function (blockName) {
        try {
            dispatch(createNewBlock({
                chatbotId: chatbotIdParam,
                adScenarioId: adScenarioIdParam,
                blockId: blockIdParam,
                quickReplyChoiceId: quickReplyChoice === null || quickReplyChoice === void 0 ? void 0 : quickReplyChoice.id,
                talkTriggerId: talkTrigger === null || talkTrigger === void 0 ? void 0 : talkTrigger.id,
                label: labelName,
                name: blockName,
                layer_rank: rank,
            }));
            setSearchTerm('');
        }
        catch (e) {
            handleModal(e.message);
        }
    };
    var handleBlockClick = function (_a) {
        var _b = _a.blockId, blockId = _b === void 0 ? null : _b, _c = _a.nextScenarioId, nextScenarioId = _c === void 0 ? null : _c;
        if (quickReplyChoice) {
            dispatch(connectQuickReplyChoiceToBlock({
                chatbotIdParam: chatbotIdParam,
                adScenarioIdParam: adScenarioIdParam,
                blockId: blockIdParam,
                quickReplyId: quickReplyId,
                quickReplyChoiceId: quickReplyChoice === null || quickReplyChoice === void 0 ? void 0 : quickReplyChoice.id,
                nextBlockId: blockId,
                nextScenarioId: nextScenarioId,
                rank: rank,
                name: labelName,
            }));
        }
        else {
            connectToBlock({
                chatbotIdParam: chatbotIdParam,
                adScenarioIdParam: adScenarioIdParam,
                quickReplyId: quickReplyId,
                quickReplyChoiceId: quickReplyChoice === null || quickReplyChoice === void 0 ? void 0 : quickReplyChoice.id,
                talkTriggerId: talkTrigger === null || talkTrigger === void 0 ? void 0 : talkTrigger.id,
                blockId: blockId,
                nextScenarioId: nextScenarioId,
                rank: rank,
                name: labelName,
            });
        }
    };
    var handleConfirm = function () {
        onConfirm(__assign({ fieldSelectableOption: fieldSelectableOption }, values));
    };
    var _j = getQuickReplyType(quickReply), userTagGroupExist = _j.userTagGroupExist, fieldIdExist = _j.fieldIdExist, quickReplyType = _j.quickReplyType;
    var userTagEnabled = quickReplyType === QuickReplyTypeEnum.TagGroup;
    var selectableOptionEnabled = quickReplyType === QuickReplyTypeEnum.UserField;
    // In case of create or update detection
    var mode = !connectedObj.name ? 'create' : 'update';
    var createText = (_a = {},
        _a[QuickReplyTypeEnum.UserField] = intl.formatMessage({ id: "Vdw/Mi", defaultMessage: "Add QuickReplyChoice" }),
        _a[QuickReplyTypeEnum.SingleItem] = intl.formatMessage({ id: "HmIgY0", defaultMessage: "Quick Reply Choice Settings" }),
        _a[QuickReplyTypeEnum.TagGroup] = intl.formatMessage({ id: "HmIgY0", defaultMessage: "Quick Reply Choice Settings" }),
        _a);
    var updateText = (_b = {},
        _b[QuickReplyTypeEnum.UserField] = intl.formatMessage({ id: "s2O6LK", defaultMessage: "Update QuickReplyChoice" }),
        _b[QuickReplyTypeEnum.SingleItem] = intl.formatMessage({ id: "HmIgY0", defaultMessage: "Quick Reply Choice Settings" }),
        _b[QuickReplyTypeEnum.TagGroup] = intl.formatMessage({ id: "HmIgY0", defaultMessage: "Quick Reply Choice Settings" }),
        _b);
    var text = mode === 'create' ? createText : updateText;
    var labelTitleForSelectableOptions = mode === 'create'
        ? intl.formatMessage({ id: "m0XdGt", defaultMessage: "Input QuickReplyChoice Label" })
        : intl.formatMessage({ id: "wlLrAq", defaultMessage: "Rename QuickReplyChoice Label" });
    var postbackActionError = Object(errors).postback_action;
    var subLabel = mode === 'create'
        ? intl.formatMessage({ id: "UMvxU+", defaultMessage: "Please input a QuickReplyChoice Label" })
        : intl.formatMessage({ id: "hZ1rNv", defaultMessage: "Current name of Selectable Option" });
    return (React.createElement(React.Fragment, null,
        React.createElement(Modal, __assign({ fullWidth: true, className: "quick_trigger_modal_modal", "data-testid": "quick-trigger-modal", open: true, title: talkTrigger
                ? intl.formatMessage({ id: "gkXuFL", defaultMessage: "Update Free Input" })
                : text[quickReplyType], actions: talkTrigger ? (React.createElement(Button, { variant: "outlined", color: "inherit", onClick: onCancel }, intl.formatMessage({ id: "rbrahO", defaultMessage: "Close" }))) : (React.createElement(React.Fragment, null,
                React.createElement(Button, { variant: "outlined", color: "primary", onClick: onCancel, style: {
                        minWidth: '120px',
                        textTransform: 'none',
                    } }, intl.formatMessage({ id: "47FYwb", defaultMessage: "Cancel" })),
                React.createElement(Button, { color: "success", variant: "contained", disabled: Boolean(touched.name && errors.name) ||
                        !values.name ||
                        !!postbackActionError, onClick: handleConfirm, style: {
                        minWidth: '120px',
                        textTransform: 'none',
                    } }, quickReplyType === QuickReplyTypeEnum.UserField
                    ? intl.formatMessage({ id: "BWpuKl", defaultMessage: "Update" })
                    : intl.formatMessage({ id: "qDYGja", defaultMessage: "Save Settings" })))), onClose: function () { return onCancel(); } }, rest),
            talkTrigger ? (React.createElement(Button, { color: "primary", variant: "contained" }, intl.formatMessage({ id: "j5BleN", defaultMessage: "User Free Input" }))) : (React.createElement(QuickReplyChoiceWrapper, null,
                React.createElement("div", { className: "message-wrapper primary-label", style: { marginTop: 0 } }, quickReplyType === 'UserField'
                    ? labelTitleForSelectableOptions
                    : intl.formatMessage({ id: "s3K2Ho", defaultMessage: "Label (Required)" })),
                React.createElement(ModalQuickReplyChoiceInput, { className: "quick_trigger_modal_modal_quick_reply_choice_input", hasError: Boolean(touched.name && errors.name), width: MIN_WIDTH, onChange: handleChange, onBlur: function (e) { return setCursorPosition(e.target.selectionStart); }, name: "name", value: values.name, platform: platform, path: "postback_action.label", paddingTop: "2px", placeholder: quickReplyType === 'UserField'
                        ? intl.formatMessage({ id: "m0XdGt", defaultMessage: "Input QuickReplyChoice Label" })
                        : intl.formatMessage({ id: "EL5PQ6", defaultMessage: "Please enter a Label" }) },
                    React.createElement(CharacterCount, { value: values.name, platform: platform, path: "postback_action.label", paddingTop: "2px" })),
                touched.name && errors.name ? (React.createElement("div", { className: "message-wrapper error" }, intl.formatMessage({ id: "x0rm8u", defaultMessage: "Label is required! And max length is 20 characters." }))) : (React.createElement("div", { className: "message-wrapper sub-label" }, quickReplyType === 'UserField' && subLabel)),
                selectableOptionEnabled && (React.createElement(React.Fragment, null,
                    React.createElement("div", { className: "message-wrapper primary-label" }, intl.formatMessage({ id: "JkgWMq", defaultMessage: "Connect with an existing SelectableOption" })),
                    React.createElement(SelectableOptionSelector, { placeholder: intl.formatMessage({ id: "jMBmla", defaultMessage: "Search for an existing SelectableOption" }), chatbotId: chatbotIdParam, quickReplyId: quickReplyId, blockId: blockIdParam, scenarioId: adScenarioIdParam, defaultValue: undefined, defaultSelectedSelectableOptionId: connectedObj.selectable_option_id, onChange: function (fieldSelectableOption) {
                            setFieldSelectableOption(fieldSelectableOption);
                            // TODO: Do selection
                        } }),
                    React.createElement("div", { className: "message-wrapper sub-label" }, intl.formatMessage({ id: "tz6mAP", defaultMessage: "You can use an existing SelectableOption name" })))),
                (quickReply === null || quickReply === void 0 ? void 0 : quickReply.label) && userTagEnabled && (React.createElement("div", null,
                    React.createElement("div", { className: "message-wrapper primary-label adicon_tag label-bold" }, intl.formatMessage({ id: "4kQFVm", defaultMessage: "User Tag" })),
                    React.createElement("div", { className: "message-wrapper sub-label" }, "\u30BF\u30B0\u3092\u691C\u7D22\u30FB\u8FFD\u52A0\u3059\u308B"),
                    React.createElement(SelectFilterDropdown, { className: "quick_trigger_modal_dropdown", adicon: "adicon_tag", readOnly: Boolean(!values.name), value: quickReplyChoice &&
                            (quickReplyChoice.user_tag
                                ? quickReplyChoice.user_tag.id
                                : null), onChange: function (_a) {
                            var value = _a.value;
                            return setUpQuickReplyChoiceUserTag(value);
                        }, itemType: "\u30BF\u30B0\u3092\u9078\u629E\u3057\u3066\u304F\u3060\u3055\u3044", items: handledUserTags.map(function (_a) {
                            var label = _a.label, id = _a.id;
                            return ({
                                value: id,
                                label: label,
                            });
                        }) || [], onToggle: function () { return setSearchTerm(''); }, showNoSearchResults: true, searchBox: {
                            placeholder: 'タグ検索・追加',
                            initialSearchText: '',
                            onChange: function (searchTerm, filteredItems) {
                                setSearchTerm(searchTerm);
                                setShowSubmitButton(filteredItems.length === 0);
                            },
                            onKeyDown: handleEnterKeyDown,
                        } }, showSubmitButton && (React.createElement("div", { className: "submit_button" },
                        React.createElement("div", { onClick: handleSubmit, tabIndex: 0, role: "button" }, "+ \u30E6\u30FC\u30B6\u30FC\u30BF\u30B0")))))))),
            !values.name && userTagEnabled && (quickReply === null || quickReply === void 0 ? void 0 : quickReply.label) ? (React.createElement("div", { className: "message-wrapper error" }, intl.formatMessage({ id: "G+LzUg", defaultMessage: "Please enter the label first." }))) : null,
            React.createElement(ActionSelector, { initActionSelectorValue: initActionSelectorValue, onChange: actionSelectorOnChange({
                    newBlock: handleNewBlockClick,
                    selectBlock: function (blockId) { return handleBlockClick({ blockId: blockId }); },
                    disconnectBlock: function () { return handleBlockClick({ blockId: null }); },
                    selectScenario: function (nextScenarioId) {
                        return handleBlockClick({ nextScenarioId: nextScenarioId });
                    },
                    disconnectScenario: function () {
                        return handleBlockClick({ nextScenarioId: null });
                    },
                }), label: labelName || '', connectedObject: connectedObj, blocks: activeBlocks, errors: postbackActionError, ignoreEffects: ignoreEffects, isValidating: isValidating }))));
};
export default QuickTriggerModal;
var templateObject_1, templateObject_2, templateObject_3;
