import "core-js/modules/es.array.concat";
import _extends from "@babel/runtime/helpers/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
import React from 'react';
import styled from 'styled-components';
import { colors } from '@src/colors';
import { ModalPortal } from '@src/components/atoms';
import Modal from '@src/components/molecules/Modal';
var ScriptContainer = styled.div.withConfig({
  displayName: "ScriptContainer",
  componentId: "sc-1q6f71l-0"
})(["font-size:10px;text-align:center;padding:20px;border:1px solid ", ";border-radius:5px;margin-top:24px;"], colors.border.default);

var ScriptModal = function ScriptModal(_ref) {
  var chatlink = _ref.chatlink,
      props = _objectWithoutProperties(_ref, ["chatlink"]);

  return React.createElement(ModalPortal, null, React.createElement(Modal, _extends({
    "data-testid": "script-modal",
    text: "\u30BF\u30B0"
  }, props), React.createElement(ScriptContainer, null, "<script\n            id=\"fanp-modal-plugin\"\n            src=\"".concat(document.location.origin, "/chat_links/").concat(chatlink.id, "/plugin.js\"\n          >\n          </script>"))));
};

export default ScriptModal;