var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import { colors } from '@src/colors';
import buttonTypes from '@src/lib/buttonTypes';
import { Button } from '@src/components/atoms';
var ListGroupItemContainer = styled.li(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  padding: 16px 24px;\n\n  .list-group__picture {\n    border: solid 1px ", ";\n    border-radius: 50%;\n    margin-right: 16px;\n    width: 56px;\n    height: 56px;\n  }\n\n  .list-group__body {\n    color: ", ";\n  }\n\n  .list-group__action {\n    margin-left: auto;\n    width: auto;\n    min-width: auto;\n    padding: 6px 16px;\n    line-height: 24px;\n    text-transform: uppercase;\n  }\n"], ["\n  display: flex;\n  align-items: center;\n  padding: 16px 24px;\n\n  .list-group__picture {\n    border: solid 1px ", ";\n    border-radius: 50%;\n    margin-right: 16px;\n    width: 56px;\n    height: 56px;\n  }\n\n  .list-group__body {\n    color: ", ";\n  }\n\n  .list-group__action {\n    margin-left: auto;\n    width: auto;\n    min-width: auto;\n    padding: 6px 16px;\n    line-height: 24px;\n    text-transform: uppercase;\n  }\n"])), colors.silver_lightening, colors.midnight);
var ListGroupItem = function (_a) {
    var ImageURL = _a.ImageURL, TextContent = _a.TextContent, setpageToConnect = _a.setpageToConnect, page = _a.page, buttonLabel = _a.buttonLabel;
    return (React.createElement(ListGroupItemContainer, { className: "list-group__item" },
        ImageURL && (React.createElement("img", { src: ImageURL, alt: "", className: "list-group__picture" })),
        React.createElement("span", { className: "list-group__body" }, TextContent),
        React.createElement(Button, { text: buttonLabel, className: "list-group__action", buttonType: buttonTypes.BLUE_ZEALS, fontSize: "14px", padding: "6px 16px", borderRadius: "50px", lineHeight: "24px", onClick: function () { return setpageToConnect(page); } })));
};
export default ListGroupItem;
var templateObject_1;
