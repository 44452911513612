import "core-js/modules/es.array.map";
import React, { Fragment } from 'react';
import styled from 'styled-components';
import { colors } from '@src/colors';
import Select from 'react-select';
import { ModalPortal, PluginImageActionPreview, PluginModalPreview } from '@src/components/atoms';
import modalTypes from '@src/lib/modalTypes';
import { actionTypeOptions, pluginActionTypes, insertAdjacentOptions } from '@src/lib/app-constants';
import Modal from '@src/components/molecules/Modal';
var InputContainer = styled.div.withConfig({
  displayName: "InputContainer",
  componentId: "sc-1lty6zg-0"
})(["padding:3px;.input_content{height:280px;}.action_score_input{font-size:1.1rem;width:85px;padding:5px 10px;border:1px solid #ddd;border-radius:3px;margin-right:10px;text-align:center;}.x_path_input{font-size:1.1rem;width:100%;padding:5px 10px;border:1px solid #ddd;border-radius:3px;margin-right:10px;text-align:center;}.selected{border:2px solid ", ";border-radius:3px;}"], colors.border.error);
var ActionItemGroup = styled.div.withConfig({
  displayName: "ActionItemGroup",
  componentId: "sc-1lty6zg-1"
})(["margin-top:15px;.chabot_group_label{font-size:0.8rem;padding:10px 0;margin-bottom:15px;&:after{display:inline-block;content:'';background:#ddd;height:1px;width:80%;margin:0 0 3px 1rem;}}"]);
var ActionItemPreviewList = styled.div.withConfig({
  displayName: "ActionItemPreviewList",
  componentId: "sc-1lty6zg-2"
})(["display:flex;align-items:start;justify-content:space-evenly;flex-wrap:wrap;padding:15px 0;"]);
var PreviewWrapper = styled.div.withConfig({
  displayName: "PreviewWrapper",
  componentId: "sc-1lty6zg-3"
})(["width:30%;"]);

var AddActionModal = function AddActionModal(_ref) {
  var onConfirm = _ref.onConfirm,
      onCancel = _ref.onCancel,
      selectActionType = _ref.selectActionType,
      handleActionScore = _ref.handleActionScore,
      actionType = _ref.actionType,
      actionScore = _ref.actionScore,
      selectBanner = _ref.selectBanner,
      selectModal = _ref.selectModal,
      handleXPath = _ref.handleXPath,
      selectInsertAdjust = _ref.selectInsertAdjust,
      accountBanners = _ref.accountBanners,
      accountModals = _ref.accountModals,
      pluginBannerId = _ref.pluginBannerId,
      pluginModalId = _ref.pluginModalId,
      hasError = _ref.hasError;
  return React.createElement(ModalPortal, null, React.createElement(Modal, {
    "data-testid": "add-action-modal",
    width: "50%",
    text: "\u30A2\u30AF\u30B7\u30E7\u30F3\u30BF\u30A4\u30D7\u3068\u30A2\u30AF\u30B7\u30E7\u30F3\u3092\u9078\u629E\u3057\u3066\u304F\u3060\u3055\u3044",
    overFlow: true,
    type: modalTypes.SELECT,
    onConfirm: onConfirm,
    onCancel: onCancel,
    hasError: hasError
  }, React.createElement(InputContainer, null, React.createElement("div", {
    className: "input_content"
  }, React.createElement("div", {
    className: "message-wrapper primary-label",
    style: {
      margin: '24px 0 12px 8px'
    }
  }, "\u30A2\u30AF\u30B7\u30E7\u30F3\u30BF\u30A4\u30D7"), React.createElement(Select, {
    onChange: selectActionType,
    options: actionTypeOptions,
    placeholder: "\u30A2\u30AF\u30B7\u30E7\u30F3\u30BF\u30A4\u30D7\u3092\u9078\u629E\u3057\u3066\u304F\u3060\u3055\u3044",
    className: "selector"
  })), actionType && actionType !== 'conversion' && actionType !== 'get_action_history' && actionType !== 'connection_client_member' ? React.createElement(Fragment, null, React.createElement("div", {
    className: "input_content"
  }, React.createElement("div", {
    className: "message-wrapper primary-label",
    style: {
      margin: '24px 0 12px 8px'
    }
  }, "AB\u914D\u4FE1\u5272\u5408"), React.createElement("input", {
    type: "number",
    placeholder: "ex: 100",
    min: "0",
    max: "100",
    onChange: handleActionScore,
    val: actionScore,
    className: "action_score_input"
  }), "\uFF05"), React.createElement("div", {
    className: "input_content"
  }, React.createElement("div", {
    className: "message-wrapper primary-label",
    style: {
      margin: '24px 0 12px 8px'
    }
  }, "\u8868\u793A\u3059\u308B".concat(pluginActionTypes[actionType], "\u3092\u9078\u629E")), actionType === 'modal' ? React.createElement(Fragment, null, accountModals.map(function (chatbot) {
    return React.createElement(ActionItemGroup, {
      key: "account-modal-chatbot-".concat(chatbot.id)
    }, React.createElement("div", {
      className: "chabot_group_label"
    }, chatbot.page_name), React.createElement(ActionItemPreviewList, null, chatbot.modals.map(function (modal) {
      return React.createElement(PreviewWrapper, {
        key: "account-modal-".concat(modal.id),
        onClick: function onClick() {
          return selectModal(modal.id);
        },
        className: pluginModalId === modal.id ? 'selected' : ''
      }, modal.modal_type === 'normal' ? React.createElement(PluginModalPreview, {
        modal: modal,
        width: '100%'
      }) : React.createElement(PluginImageActionPreview, {
        modal: modal,
        width: '100%'
      }));
    })));
  })) : React.createElement(Fragment, null, accountBanners.map(function (chatbot) {
    return React.createElement(ActionItemGroup, {
      key: "account-modal-chatbot-".concat(chatbot.id)
    }, React.createElement("div", {
      className: "chabot_group_label"
    }, chatbot.page_name), React.createElement(ActionItemPreviewList, null, chatbot.banners.map(function (banner) {
      return React.createElement(PreviewWrapper, {
        key: "account-banner-".concat(banner.id),
        onClick: function onClick() {
          return selectBanner(banner.id);
        },
        className: pluginBannerId === banner.id ? 'selected' : ''
      }, React.createElement(PluginImageActionPreview, {
        banner: banner,
        width: '100%'
      }));
    })));
  }), React.createElement("div", {
    className: "input_content"
  }, React.createElement("div", {
    className: "message-wrapper primary-label",
    style: {
      margin: '24px 0 12px 8px'
    }
  }, "\u8868\u793A\u3059\u308B\u5834\u6240X-path\u3067\u6307\u5B9A\u3059\u308B"), React.createElement("input", {
    type: "text",
    placeholder: "/html/body/div/div[@class='banner_place']",
    onChange: handleXPath,
    className: "x_path_input"
  })), React.createElement("div", {
    className: "input_content"
  }, React.createElement("div", {
    className: "message-wrapper primary-label",
    style: {
      margin: '24px 0 12px 8px'
    }
  }, "\u633F\u5165\u4F4D\u7F6E"), React.createElement(Select, {
    onChange: selectInsertAdjust,
    options: insertAdjacentOptions,
    placeholder: "\u633F\u5165\u4F4D\u7F6E\u3092\u9078\u629E\u3057\u3066\u304F\u3060\u3055\u3044",
    className: "selector"
  }))))) : null)));
};

export default AddActionModal;