/**
 * A panel component that displays data/content/other components (e.g. tag)
 * and controls whether it can be closed with the readOnly property
 *
 * @title The title property is the subject of the panel, this property is optional,
 *        when the title property does not exist, the subject of the panel will not be displayed
 * @children The children property is required, it can be other components or simple data
 * @readOnly The readOnly property is optional, when readOnly is false or not exist,
 *           the panel can be expanded and closed (and will also display the arrow icon),
 *           when readOnly is true, the panel is just a display panel,
 *           it cannot be closed (and will not display the arrow icon)
 * @className The readOnly property is optional, This property can be used to override the component style
 */
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React from 'react';
import styled from 'styled-components';
import { icon } from '@src/lib/mixins';
var ExpandPanelWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  & + * {\n    margin-top: 0.5em;\n  }\n\n  .accordion-item {\n    overflow: hidden;\n    transition: max-height 0.3s cubic-bezier(1, 0, 1, 0);\n    height: auto;\n    max-height: 9999px;\n    &.collapsed {\n      max-height: 0;\n      transition: max-height 0.35s cubic-bezier(0, 1, 0, 1);\n    }\n  }\n\n  .accordion-title {\n    font-weight: 600;\n    cursor: pointer;\n    color: #091f6c;\n    padding: 0.5em 1.5em;\n    border: solid 1px #d1d7ed;\n    border-radius: ", ";\n    display: flex-start;\n    justify-content: space-between;\n    align-items: center;\n  }\n  .adicon_down.adicon_up {\n    ", "\n  }\n  .accordion-content {\n    padding: 1em 1.5em;\n    background: #f3f4f7;\n    border-radius: 0 0 8px 8px;\n  }\n"], ["\n  width: 100%;\n  & + * {\n    margin-top: 0.5em;\n  }\n\n  .accordion-item {\n    overflow: hidden;\n    transition: max-height 0.3s cubic-bezier(1, 0, 1, 0);\n    height: auto;\n    max-height: 9999px;\n    &.collapsed {\n      max-height: 0;\n      transition: max-height 0.35s cubic-bezier(0, 1, 0, 1);\n    }\n  }\n\n  .accordion-title {\n    font-weight: 600;\n    cursor: pointer;\n    color: #091f6c;\n    padding: 0.5em 1.5em;\n    border: solid 1px #d1d7ed;\n    border-radius: ", ";\n    display: flex-start;\n    justify-content: space-between;\n    align-items: center;\n  }\n  .adicon_down.adicon_up {\n    ", "\n  }\n  .accordion-content {\n    padding: 1em 1.5em;\n    background: #f3f4f7;\n    border-radius: 0 0 8px 8px;\n  }\n"])), function (props) { return (props.isOpen ? '8px 8px 0 0' : '8px'); }, icon);
var ExpandPanel = function (_a) {
    var _b = _a.title, title = _b === void 0 ? '' : _b, children = _a.children, _c = _a.readOnly, readOnly = _c === void 0 ? false : _c, className = _a.className;
    var _d = __read(React.useState(readOnly), 2), isOpen = _d[0], setOpen = _d[1];
    return (React.createElement(ExpandPanelWrapper, { className: className, isOpen: isOpen },
        React.createElement("div", { className: "accordion-title " + (isOpen ? 'open' : ''), onClick: function () { return !readOnly && setOpen(!isOpen); }, "data-testid": "title-container" },
            !readOnly && (React.createElement("span", { "data-testid": "icon", className: isOpen ? 'adicon_up' : 'adicon_down' })),
            React.createElement("span", { "data-testid": "title", className: "title-text" }, title)),
        React.createElement("div", { className: "accordion-item " + (!isOpen ? 'collapsed' : '') },
            React.createElement("div", { "data-testid": "content", className: "accordion-content" }, children))));
};
export default ExpandPanel;
var templateObject_1;
