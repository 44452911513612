var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import { colors } from '@src/colors';
var QuickReplyContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin: 20px 0px;\n  border-radius: 16px;\n\n  .quick-reply-label {\n    margin: 0px 10px;\n    border-radius: 30px;\n    padding: 5px 10px;\n    background: ", ";\n  }\n\n  .quick-reply-choices {\n    display: flex;\n  }\n"], ["\n  margin: 20px 0px;\n  border-radius: 16px;\n\n  .quick-reply-label {\n    margin: 0px 10px;\n    border-radius: 30px;\n    padding: 5px 10px;\n    background: ", ";\n  }\n\n  .quick-reply-choices {\n    display: flex;\n  }\n"])), colors.background.off_white_background);
var QuickReplyChoiceWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin: 10px 10px;\n  padding: 8px;\n  border-radius: 10px;\n  border: 1px solid ", ";\n  overflow: hidden;\n\n  .user-tag {\n    .adicon_tag {\n      padding-right: 16px;\n      position: relative;\n\n      &:before {\n        position: absolute;\n        top: 0px;\n      }\n    }\n  }\n\n  .name {\n    border-radius: 25px;\n    margin: 10px 0;\n    padding: 8px;\n    background: ", ";\n    color: ", ";\n    text-align: center;\n    width: 100%;\n    min-width: 50px;\n  }\n"], ["\n  margin: 10px 10px;\n  padding: 8px;\n  border-radius: 10px;\n  border: 1px solid ", ";\n  overflow: hidden;\n\n  .user-tag {\n    .adicon_tag {\n      padding-right: 16px;\n      position: relative;\n\n      &:before {\n        position: absolute;\n        top: 0px;\n      }\n    }\n  }\n\n  .name {\n    border-radius: 25px;\n    margin: 10px 0;\n    padding: 8px;\n    background: ", ";\n    color: ", ";\n    text-align: center;\n    width: 100%;\n    min-width: 50px;\n  }\n"])), colors.border.default, colors.quick_reply, colors.white);
var QuickReplyNode = function (_a) {
    var _b = _a.data, quickReply = _b.quickReply, updateBoundaryNode = _b.updateBoundaryNode;
    return (React.createElement(QuickReplyContainer, { "data-testid": "QuickReplyNode_QuickReplyContainer" },
        React.createElement("div", { className: "quick-reply-label" }, quickReply.label),
        React.createElement("div", { className: "quick-reply-choices" }, quickReply.quick_reply_choices.map(function (quickReplyChoice) {
            var _a;
            return (React.createElement(QuickReplyChoiceWrapper, { key: "quick-reply-choice-" + quickReplyChoice.id, ref: function (ref) {
                    if (!ref ||
                        !updateBoundaryNode ||
                        !quickReplyChoice.postback_action)
                        return;
                    updateBoundaryNode('postbackActions', quickReplyChoice.postback_action.id, ref.getBoundingClientRect());
                } },
                React.createElement("div", { className: "user-tag" },
                    React.createElement("span", { className: "adicon_tag" }), (_a = quickReplyChoice.user_tag) === null || _a === void 0 ? void 0 :
                    _a.label),
                React.createElement("div", { className: "name" }, quickReplyChoice.name)));
        }))));
};
export default QuickReplyNode;
var templateObject_1, templateObject_2;
