import components from './components';

var collection = function collection() {
  return "/integration_plugins/plugins";
};

var member = function member(_ref) {
  var pluginId = _ref.pluginId;
  return "/integration_plugins/plugins/".concat(pluginId);
};

export default {
  collection: collection,
  member: member,
  components: components
};