// source: entities/entities.proto

/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

/* eslint-disable */
// @ts-nocheck
var jspb = require('google-protobuf');

var goog = jspb;
var global = Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');

goog.object.extend(proto, google_protobuf_timestamp_pb);
goog.exportSymbol('proto.e.AutomationRuleStatus', null, global);
goog.exportSymbol('proto.e.Chatbot', null, global);
goog.exportSymbol('proto.e.CreditCard', null, global);
goog.exportSymbol('proto.e.Empty', null, global);
goog.exportSymbol('proto.e.EndUser', null, global);
goog.exportSymbol('proto.e.Filter', null, global);
goog.exportSymbol('proto.e.Filter.ConversionType', null, global);
goog.exportSymbol('proto.e.Filter.MessageReadStatusType', null, global);
goog.exportSymbol('proto.e.Filter.SetOperationType', null, global);
goog.exportSymbol('proto.e.OriginalUser', null, global);
goog.exportSymbol('proto.e.PlatformType', null, global);
goog.exportSymbol('proto.e.QuickFilter', null, global);
goog.exportSymbol('proto.e.RPAConfig', null, global);
goog.exportSymbol('proto.e.RPAInputType', null, global);
goog.exportSymbol('proto.e.RPAPage', null, global);
goog.exportSymbol('proto.e.RPAStep', null, global);
goog.exportSymbol('proto.e.RPAStepType', null, global);
goog.exportSymbol('proto.e.Record', null, global);
goog.exportSymbol('proto.e.UserTag', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */

proto.e.Empty = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.e.Empty, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.e.Empty.displayName = 'proto.e.Empty';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.e.Record = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.e.Record, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.e.Record.displayName = 'proto.e.Record';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.e.Chatbot = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.e.Chatbot, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.e.Chatbot.displayName = 'proto.e.Chatbot';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.e.EndUser = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.e.EndUser.repeatedFields_, null);
};

goog.inherits(proto.e.EndUser, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.e.EndUser.displayName = 'proto.e.EndUser';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.e.OriginalUser = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.e.OriginalUser, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.e.OriginalUser.displayName = 'proto.e.OriginalUser';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.e.UserTag = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.e.UserTag, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.e.UserTag.displayName = 'proto.e.UserTag';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.e.CreditCard = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.e.CreditCard, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.e.CreditCard.displayName = 'proto.e.CreditCard';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.e.Filter = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.e.Filter.repeatedFields_, null);
};

goog.inherits(proto.e.Filter, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.e.Filter.displayName = 'proto.e.Filter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.e.QuickFilter = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.e.QuickFilter, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.e.QuickFilter.displayName = 'proto.e.QuickFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.e.RPAConfig = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.e.RPAConfig.repeatedFields_, null);
};

goog.inherits(proto.e.RPAConfig, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.e.RPAConfig.displayName = 'proto.e.RPAConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.e.RPAPage = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.e.RPAPage.repeatedFields_, null);
};

goog.inherits(proto.e.RPAPage, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.e.RPAPage.displayName = 'proto.e.RPAPage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.e.RPAStep = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.e.RPAStep.repeatedFields_, null);
};

goog.inherits(proto.e.RPAStep, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.e.RPAStep.displayName = 'proto.e.RPAStep';
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.e.Empty.prototype.toObject = function (opt_includeInstance) {
    return proto.e.Empty.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.e.Empty} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.e.Empty.toObject = function (includeInstance, msg) {
    var f,
        obj = {};

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.e.Empty}
 */


proto.e.Empty.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.e.Empty();
  return proto.e.Empty.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.e.Empty} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.e.Empty}
 */


proto.e.Empty.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.e.Empty.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.e.Empty.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.e.Empty} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.e.Empty.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.e.Record.prototype.toObject = function (opt_includeInstance) {
    return proto.e.Record.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.e.Record} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.e.Record.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      createTime: (f = msg.getCreateTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
      updateTime: (f = msg.getUpdateTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.e.Record}
 */


proto.e.Record.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.e.Record();
  return proto.e.Record.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.e.Record} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.e.Record}
 */


proto.e.Record.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value = new google_protobuf_timestamp_pb.Timestamp();
        reader.readMessage(value, google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
        msg.setCreateTime(value);
        break;

      case 2:
        var value = new google_protobuf_timestamp_pb.Timestamp();
        reader.readMessage(value, google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
        msg.setUpdateTime(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.e.Record.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.e.Record.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.e.Record} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.e.Record.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getCreateTime();

  if (f != null) {
    writer.writeMessage(1, f, google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter);
  }

  f = message.getUpdateTime();

  if (f != null) {
    writer.writeMessage(2, f, google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter);
  }
};
/**
 * optional google.protobuf.Timestamp create_time = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */


proto.e.Record.prototype.getCreateTime = function () {
  return (
    /** @type{?proto.google.protobuf.Timestamp} */
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1)
  );
};
/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.e.Record} returns this
*/


proto.e.Record.prototype.setCreateTime = function (value) {
  return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.e.Record} returns this
 */


proto.e.Record.prototype.clearCreateTime = function () {
  return this.setCreateTime(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.e.Record.prototype.hasCreateTime = function () {
  return jspb.Message.getField(this, 1) != null;
};
/**
 * optional google.protobuf.Timestamp update_time = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */


proto.e.Record.prototype.getUpdateTime = function () {
  return (
    /** @type{?proto.google.protobuf.Timestamp} */
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2)
  );
};
/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.e.Record} returns this
*/


proto.e.Record.prototype.setUpdateTime = function (value) {
  return jspb.Message.setWrapperField(this, 2, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.e.Record} returns this
 */


proto.e.Record.prototype.clearUpdateTime = function () {
  return this.setUpdateTime(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.e.Record.prototype.hasUpdateTime = function () {
  return jspb.Message.getField(this, 2) != null;
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.e.Chatbot.prototype.toObject = function (opt_includeInstance) {
    return proto.e.Chatbot.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.e.Chatbot} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.e.Chatbot.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, 0),
      pageId: jspb.Message.getFieldWithDefault(msg, 2, 0),
      platform: jspb.Message.getFieldWithDefault(msg, 3, 0),
      originalPlatformId: jspb.Message.getFieldWithDefault(msg, 4, ""),
      secretKey: jspb.Message.getFieldWithDefault(msg, 5, ""),
      name: jspb.Message.getFieldWithDefault(msg, 6, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.e.Chatbot}
 */


proto.e.Chatbot.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.e.Chatbot();
  return proto.e.Chatbot.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.e.Chatbot} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.e.Chatbot}
 */


proto.e.Chatbot.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value =
        /** @type {number} */
        reader.readInt64();
        msg.setId(value);
        break;

      case 2:
        var value =
        /** @type {number} */
        reader.readInt64();
        msg.setPageId(value);
        break;

      case 3:
        var value =
        /** @type {!proto.e.PlatformType} */
        reader.readEnum();
        msg.setPlatform(value);
        break;

      case 4:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setOriginalPlatformId(value);
        break;

      case 5:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setSecretKey(value);
        break;

      case 6:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setName(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.e.Chatbot.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.e.Chatbot.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.e.Chatbot} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.e.Chatbot.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getId();

  if (f !== 0) {
    writer.writeInt64(1, f);
  }

  f = message.getPageId();

  if (f !== 0) {
    writer.writeInt64(2, f);
  }

  f = message.getPlatform();

  if (f !== 0.0) {
    writer.writeEnum(3, f);
  }

  f = message.getOriginalPlatformId();

  if (f.length > 0) {
    writer.writeString(4, f);
  }

  f = message.getSecretKey();

  if (f.length > 0) {
    writer.writeString(5, f);
  }

  f = message.getName();

  if (f.length > 0) {
    writer.writeString(6, f);
  }
};
/**
 * optional int64 id = 1;
 * @return {number}
 */


proto.e.Chatbot.prototype.getId = function () {
  return (
    /** @type {number} */
    jspb.Message.getFieldWithDefault(this, 1, 0)
  );
};
/**
 * @param {number} value
 * @return {!proto.e.Chatbot} returns this
 */


proto.e.Chatbot.prototype.setId = function (value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};
/**
 * optional int64 page_id = 2;
 * @return {number}
 */


proto.e.Chatbot.prototype.getPageId = function () {
  return (
    /** @type {number} */
    jspb.Message.getFieldWithDefault(this, 2, 0)
  );
};
/**
 * @param {number} value
 * @return {!proto.e.Chatbot} returns this
 */


proto.e.Chatbot.prototype.setPageId = function (value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};
/**
 * optional PlatformType platform = 3;
 * @return {!proto.e.PlatformType}
 */


proto.e.Chatbot.prototype.getPlatform = function () {
  return (
    /** @type {!proto.e.PlatformType} */
    jspb.Message.getFieldWithDefault(this, 3, 0)
  );
};
/**
 * @param {!proto.e.PlatformType} value
 * @return {!proto.e.Chatbot} returns this
 */


proto.e.Chatbot.prototype.setPlatform = function (value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};
/**
 * optional string original_platform_id = 4;
 * @return {string}
 */


proto.e.Chatbot.prototype.getOriginalPlatformId = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 4, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.e.Chatbot} returns this
 */


proto.e.Chatbot.prototype.setOriginalPlatformId = function (value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};
/**
 * optional string secret_key = 5;
 * @return {string}
 */


proto.e.Chatbot.prototype.getSecretKey = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 5, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.e.Chatbot} returns this
 */


proto.e.Chatbot.prototype.setSecretKey = function (value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};
/**
 * optional string name = 6;
 * @return {string}
 */


proto.e.Chatbot.prototype.getName = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 6, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.e.Chatbot} returns this
 */


proto.e.Chatbot.prototype.setName = function (value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */


proto.e.EndUser.repeatedFields_ = [3];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.e.EndUser.prototype.toObject = function (opt_includeInstance) {
    return proto.e.EndUser.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.e.EndUser} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.e.EndUser.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, 0),
      pageId: jspb.Message.getFieldWithDefault(msg, 2, 0),
      attributeIdsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
      platform: jspb.Message.getFieldWithDefault(msg, 4, 0),
      originalUserId: jspb.Message.getFieldWithDefault(msg, 5, ""),
      displayName: jspb.Message.getFieldWithDefault(msg, 6, ""),
      hashId: jspb.Message.getFieldWithDefault(msg, 7, ""),
      blockTime: (f = msg.getBlockTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
      profilePhotoUrl: jspb.Message.getFieldWithDefault(msg, 9, ""),
      record: (f = msg.getRecord()) && proto.e.Record.toObject(includeInstance, f),
      firstName: jspb.Message.getFieldWithDefault(msg, 16, ""),
      lastName: jspb.Message.getFieldWithDefault(msg, 17, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.e.EndUser}
 */


proto.e.EndUser.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.e.EndUser();
  return proto.e.EndUser.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.e.EndUser} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.e.EndUser}
 */


proto.e.EndUser.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value =
        /** @type {number} */
        reader.readInt64();
        msg.setId(value);
        break;

      case 2:
        var value =
        /** @type {number} */
        reader.readInt64();
        msg.setPageId(value);
        break;

      case 3:
        var values =
        /** @type {!Array<number>} */
        reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()];

        for (var i = 0; i < values.length; i++) {
          msg.addAttributeIds(values[i]);
        }

        break;

      case 4:
        var value =
        /** @type {!proto.e.PlatformType} */
        reader.readEnum();
        msg.setPlatform(value);
        break;

      case 5:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setOriginalUserId(value);
        break;

      case 6:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setDisplayName(value);
        break;

      case 7:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setHashId(value);
        break;

      case 8:
        var value = new google_protobuf_timestamp_pb.Timestamp();
        reader.readMessage(value, google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
        msg.setBlockTime(value);
        break;

      case 9:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setProfilePhotoUrl(value);
        break;

      case 15:
        var value = new proto.e.Record();
        reader.readMessage(value, proto.e.Record.deserializeBinaryFromReader);
        msg.setRecord(value);
        break;

      case 16:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setFirstName(value);
        break;

      case 17:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setLastName(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.e.EndUser.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.e.EndUser.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.e.EndUser} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.e.EndUser.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getId();

  if (f !== 0) {
    writer.writeInt64(1, f);
  }

  f = message.getPageId();

  if (f !== 0) {
    writer.writeInt64(2, f);
  }

  f = message.getAttributeIdsList();

  if (f.length > 0) {
    writer.writePackedInt64(3, f);
  }

  f = message.getPlatform();

  if (f !== 0.0) {
    writer.writeEnum(4, f);
  }

  f = message.getOriginalUserId();

  if (f.length > 0) {
    writer.writeString(5, f);
  }

  f = message.getDisplayName();

  if (f.length > 0) {
    writer.writeString(6, f);
  }

  f = message.getHashId();

  if (f.length > 0) {
    writer.writeString(7, f);
  }

  f = message.getBlockTime();

  if (f != null) {
    writer.writeMessage(8, f, google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter);
  }

  f = message.getProfilePhotoUrl();

  if (f.length > 0) {
    writer.writeString(9, f);
  }

  f = message.getRecord();

  if (f != null) {
    writer.writeMessage(15, f, proto.e.Record.serializeBinaryToWriter);
  }

  f = message.getFirstName();

  if (f.length > 0) {
    writer.writeString(16, f);
  }

  f = message.getLastName();

  if (f.length > 0) {
    writer.writeString(17, f);
  }
};
/**
 * optional int64 id = 1;
 * @return {number}
 */


proto.e.EndUser.prototype.getId = function () {
  return (
    /** @type {number} */
    jspb.Message.getFieldWithDefault(this, 1, 0)
  );
};
/**
 * @param {number} value
 * @return {!proto.e.EndUser} returns this
 */


proto.e.EndUser.prototype.setId = function (value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};
/**
 * optional int64 page_id = 2;
 * @return {number}
 */


proto.e.EndUser.prototype.getPageId = function () {
  return (
    /** @type {number} */
    jspb.Message.getFieldWithDefault(this, 2, 0)
  );
};
/**
 * @param {number} value
 * @return {!proto.e.EndUser} returns this
 */


proto.e.EndUser.prototype.setPageId = function (value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};
/**
 * repeated int64 attribute_ids = 3;
 * @return {!Array<number>}
 */


proto.e.EndUser.prototype.getAttributeIdsList = function () {
  return (
    /** @type {!Array<number>} */
    jspb.Message.getRepeatedField(this, 3)
  );
};
/**
 * @param {!Array<number>} value
 * @return {!proto.e.EndUser} returns this
 */


proto.e.EndUser.prototype.setAttributeIdsList = function (value) {
  return jspb.Message.setField(this, 3, value || []);
};
/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.e.EndUser} returns this
 */


proto.e.EndUser.prototype.addAttributeIds = function (value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.e.EndUser} returns this
 */


proto.e.EndUser.prototype.clearAttributeIdsList = function () {
  return this.setAttributeIdsList([]);
};
/**
 * optional PlatformType platform = 4;
 * @return {!proto.e.PlatformType}
 */


proto.e.EndUser.prototype.getPlatform = function () {
  return (
    /** @type {!proto.e.PlatformType} */
    jspb.Message.getFieldWithDefault(this, 4, 0)
  );
};
/**
 * @param {!proto.e.PlatformType} value
 * @return {!proto.e.EndUser} returns this
 */


proto.e.EndUser.prototype.setPlatform = function (value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};
/**
 * optional string original_user_id = 5;
 * @return {string}
 */


proto.e.EndUser.prototype.getOriginalUserId = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 5, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.e.EndUser} returns this
 */


proto.e.EndUser.prototype.setOriginalUserId = function (value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};
/**
 * optional string display_name = 6;
 * @return {string}
 */


proto.e.EndUser.prototype.getDisplayName = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 6, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.e.EndUser} returns this
 */


proto.e.EndUser.prototype.setDisplayName = function (value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};
/**
 * optional string hash_id = 7;
 * @return {string}
 */


proto.e.EndUser.prototype.getHashId = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 7, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.e.EndUser} returns this
 */


proto.e.EndUser.prototype.setHashId = function (value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};
/**
 * optional google.protobuf.Timestamp block_time = 8;
 * @return {?proto.google.protobuf.Timestamp}
 */


proto.e.EndUser.prototype.getBlockTime = function () {
  return (
    /** @type{?proto.google.protobuf.Timestamp} */
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 8)
  );
};
/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.e.EndUser} returns this
*/


proto.e.EndUser.prototype.setBlockTime = function (value) {
  return jspb.Message.setWrapperField(this, 8, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.e.EndUser} returns this
 */


proto.e.EndUser.prototype.clearBlockTime = function () {
  return this.setBlockTime(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.e.EndUser.prototype.hasBlockTime = function () {
  return jspb.Message.getField(this, 8) != null;
};
/**
 * optional string profile_photo_url = 9;
 * @return {string}
 */


proto.e.EndUser.prototype.getProfilePhotoUrl = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 9, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.e.EndUser} returns this
 */


proto.e.EndUser.prototype.setProfilePhotoUrl = function (value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};
/**
 * optional Record record = 15;
 * @return {?proto.e.Record}
 */


proto.e.EndUser.prototype.getRecord = function () {
  return (
    /** @type{?proto.e.Record} */
    jspb.Message.getWrapperField(this, proto.e.Record, 15)
  );
};
/**
 * @param {?proto.e.Record|undefined} value
 * @return {!proto.e.EndUser} returns this
*/


proto.e.EndUser.prototype.setRecord = function (value) {
  return jspb.Message.setWrapperField(this, 15, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.e.EndUser} returns this
 */


proto.e.EndUser.prototype.clearRecord = function () {
  return this.setRecord(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.e.EndUser.prototype.hasRecord = function () {
  return jspb.Message.getField(this, 15) != null;
};
/**
 * optional string first_name = 16;
 * @return {string}
 */


proto.e.EndUser.prototype.getFirstName = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 16, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.e.EndUser} returns this
 */


proto.e.EndUser.prototype.setFirstName = function (value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};
/**
 * optional string last_name = 17;
 * @return {string}
 */


proto.e.EndUser.prototype.getLastName = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 17, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.e.EndUser} returns this
 */


proto.e.EndUser.prototype.setLastName = function (value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.e.OriginalUser.prototype.toObject = function (opt_includeInstance) {
    return proto.e.OriginalUser.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.e.OriginalUser} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.e.OriginalUser.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, ""),
      displayName: jspb.Message.getFieldWithDefault(msg, 2, ""),
      profilePhotoUrl: jspb.Message.getFieldWithDefault(msg, 3, ""),
      record: (f = msg.getRecord()) && proto.e.Record.toObject(includeInstance, f),
      firstName: jspb.Message.getFieldWithDefault(msg, 16, ""),
      lastName: jspb.Message.getFieldWithDefault(msg, 17, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.e.OriginalUser}
 */


proto.e.OriginalUser.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.e.OriginalUser();
  return proto.e.OriginalUser.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.e.OriginalUser} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.e.OriginalUser}
 */


proto.e.OriginalUser.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setId(value);
        break;

      case 2:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setDisplayName(value);
        break;

      case 3:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setProfilePhotoUrl(value);
        break;

      case 15:
        var value = new proto.e.Record();
        reader.readMessage(value, proto.e.Record.deserializeBinaryFromReader);
        msg.setRecord(value);
        break;

      case 16:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setFirstName(value);
        break;

      case 17:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setLastName(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.e.OriginalUser.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.e.OriginalUser.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.e.OriginalUser} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.e.OriginalUser.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getId();

  if (f.length > 0) {
    writer.writeString(1, f);
  }

  f = message.getDisplayName();

  if (f.length > 0) {
    writer.writeString(2, f);
  }

  f = message.getProfilePhotoUrl();

  if (f.length > 0) {
    writer.writeString(3, f);
  }

  f = message.getRecord();

  if (f != null) {
    writer.writeMessage(15, f, proto.e.Record.serializeBinaryToWriter);
  }

  f = message.getFirstName();

  if (f.length > 0) {
    writer.writeString(16, f);
  }

  f = message.getLastName();

  if (f.length > 0) {
    writer.writeString(17, f);
  }
};
/**
 * optional string id = 1;
 * @return {string}
 */


proto.e.OriginalUser.prototype.getId = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 1, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.e.OriginalUser} returns this
 */


proto.e.OriginalUser.prototype.setId = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional string display_name = 2;
 * @return {string}
 */


proto.e.OriginalUser.prototype.getDisplayName = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 2, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.e.OriginalUser} returns this
 */


proto.e.OriginalUser.prototype.setDisplayName = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};
/**
 * optional string profile_photo_url = 3;
 * @return {string}
 */


proto.e.OriginalUser.prototype.getProfilePhotoUrl = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 3, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.e.OriginalUser} returns this
 */


proto.e.OriginalUser.prototype.setProfilePhotoUrl = function (value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};
/**
 * optional Record record = 15;
 * @return {?proto.e.Record}
 */


proto.e.OriginalUser.prototype.getRecord = function () {
  return (
    /** @type{?proto.e.Record} */
    jspb.Message.getWrapperField(this, proto.e.Record, 15)
  );
};
/**
 * @param {?proto.e.Record|undefined} value
 * @return {!proto.e.OriginalUser} returns this
*/


proto.e.OriginalUser.prototype.setRecord = function (value) {
  return jspb.Message.setWrapperField(this, 15, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.e.OriginalUser} returns this
 */


proto.e.OriginalUser.prototype.clearRecord = function () {
  return this.setRecord(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.e.OriginalUser.prototype.hasRecord = function () {
  return jspb.Message.getField(this, 15) != null;
};
/**
 * optional string first_name = 16;
 * @return {string}
 */


proto.e.OriginalUser.prototype.getFirstName = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 16, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.e.OriginalUser} returns this
 */


proto.e.OriginalUser.prototype.setFirstName = function (value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};
/**
 * optional string last_name = 17;
 * @return {string}
 */


proto.e.OriginalUser.prototype.getLastName = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 17, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.e.OriginalUser} returns this
 */


proto.e.OriginalUser.prototype.setLastName = function (value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.e.UserTag.prototype.toObject = function (opt_includeInstance) {
    return proto.e.UserTag.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.e.UserTag} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.e.UserTag.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, ""),
      label: jspb.Message.getFieldWithDefault(msg, 2, ""),
      record: (f = msg.getRecord()) && proto.e.Record.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.e.UserTag}
 */


proto.e.UserTag.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.e.UserTag();
  return proto.e.UserTag.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.e.UserTag} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.e.UserTag}
 */


proto.e.UserTag.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setId(value);
        break;

      case 2:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setLabel(value);
        break;

      case 15:
        var value = new proto.e.Record();
        reader.readMessage(value, proto.e.Record.deserializeBinaryFromReader);
        msg.setRecord(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.e.UserTag.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.e.UserTag.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.e.UserTag} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.e.UserTag.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getId();

  if (f.length > 0) {
    writer.writeString(1, f);
  }

  f = message.getLabel();

  if (f.length > 0) {
    writer.writeString(2, f);
  }

  f = message.getRecord();

  if (f != null) {
    writer.writeMessage(15, f, proto.e.Record.serializeBinaryToWriter);
  }
};
/**
 * optional string id = 1;
 * @return {string}
 */


proto.e.UserTag.prototype.getId = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 1, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.e.UserTag} returns this
 */


proto.e.UserTag.prototype.setId = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional string label = 2;
 * @return {string}
 */


proto.e.UserTag.prototype.getLabel = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 2, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.e.UserTag} returns this
 */


proto.e.UserTag.prototype.setLabel = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};
/**
 * optional Record record = 15;
 * @return {?proto.e.Record}
 */


proto.e.UserTag.prototype.getRecord = function () {
  return (
    /** @type{?proto.e.Record} */
    jspb.Message.getWrapperField(this, proto.e.Record, 15)
  );
};
/**
 * @param {?proto.e.Record|undefined} value
 * @return {!proto.e.UserTag} returns this
*/


proto.e.UserTag.prototype.setRecord = function (value) {
  return jspb.Message.setWrapperField(this, 15, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.e.UserTag} returns this
 */


proto.e.UserTag.prototype.clearRecord = function () {
  return this.setRecord(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.e.UserTag.prototype.hasRecord = function () {
  return jspb.Message.getField(this, 15) != null;
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.e.CreditCard.prototype.toObject = function (opt_includeInstance) {
    return proto.e.CreditCard.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.e.CreditCard} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.e.CreditCard.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      pan: jspb.Message.getFieldWithDefault(msg, 3, 0),
      cvv: jspb.Message.getFieldWithDefault(msg, 4, 0),
      expireMonth: jspb.Message.getFieldWithDefault(msg, 5, 0),
      expireYear: jspb.Message.getFieldWithDefault(msg, 6, 0),
      name: jspb.Message.getFieldWithDefault(msg, 7, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.e.CreditCard}
 */


proto.e.CreditCard.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.e.CreditCard();
  return proto.e.CreditCard.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.e.CreditCard} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.e.CreditCard}
 */


proto.e.CreditCard.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 3:
        var value =
        /** @type {number} */
        reader.readInt64();
        msg.setPan(value);
        break;

      case 4:
        var value =
        /** @type {number} */
        reader.readInt32();
        msg.setCvv(value);
        break;

      case 5:
        var value =
        /** @type {number} */
        reader.readInt32();
        msg.setExpireMonth(value);
        break;

      case 6:
        var value =
        /** @type {number} */
        reader.readInt32();
        msg.setExpireYear(value);
        break;

      case 7:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setName(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.e.CreditCard.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.e.CreditCard.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.e.CreditCard} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.e.CreditCard.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getPan();

  if (f !== 0) {
    writer.writeInt64(3, f);
  }

  f = message.getCvv();

  if (f !== 0) {
    writer.writeInt32(4, f);
  }

  f = message.getExpireMonth();

  if (f !== 0) {
    writer.writeInt32(5, f);
  }

  f = message.getExpireYear();

  if (f !== 0) {
    writer.writeInt32(6, f);
  }

  f = message.getName();

  if (f.length > 0) {
    writer.writeString(7, f);
  }
};
/**
 * optional int64 pan = 3;
 * @return {number}
 */


proto.e.CreditCard.prototype.getPan = function () {
  return (
    /** @type {number} */
    jspb.Message.getFieldWithDefault(this, 3, 0)
  );
};
/**
 * @param {number} value
 * @return {!proto.e.CreditCard} returns this
 */


proto.e.CreditCard.prototype.setPan = function (value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};
/**
 * optional int32 cvv = 4;
 * @return {number}
 */


proto.e.CreditCard.prototype.getCvv = function () {
  return (
    /** @type {number} */
    jspb.Message.getFieldWithDefault(this, 4, 0)
  );
};
/**
 * @param {number} value
 * @return {!proto.e.CreditCard} returns this
 */


proto.e.CreditCard.prototype.setCvv = function (value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};
/**
 * optional int32 expire_month = 5;
 * @return {number}
 */


proto.e.CreditCard.prototype.getExpireMonth = function () {
  return (
    /** @type {number} */
    jspb.Message.getFieldWithDefault(this, 5, 0)
  );
};
/**
 * @param {number} value
 * @return {!proto.e.CreditCard} returns this
 */


proto.e.CreditCard.prototype.setExpireMonth = function (value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};
/**
 * optional int32 expire_year = 6;
 * @return {number}
 */


proto.e.CreditCard.prototype.getExpireYear = function () {
  return (
    /** @type {number} */
    jspb.Message.getFieldWithDefault(this, 6, 0)
  );
};
/**
 * @param {number} value
 * @return {!proto.e.CreditCard} returns this
 */


proto.e.CreditCard.prototype.setExpireYear = function (value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};
/**
 * optional string name = 7;
 * @return {string}
 */


proto.e.CreditCard.prototype.getName = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 7, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.e.CreditCard} returns this
 */


proto.e.CreditCard.prototype.setName = function (value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */


proto.e.Filter.repeatedFields_ = [4];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.e.Filter.prototype.toObject = function (opt_includeInstance) {
    return proto.e.Filter.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.e.Filter} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.e.Filter.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, 0),
      createdAfterTime: (f = msg.getCreatedAfterTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
      createdBeforeTime: (f = msg.getCreatedBeforeTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
      quickFiltersList: jspb.Message.toObjectList(msg.getQuickFiltersList(), proto.e.QuickFilter.toObject, includeInstance),
      type: jspb.Message.getFieldWithDefault(msg, 5, 0),
      messageReadStatusType: jspb.Message.getFieldWithDefault(msg, 6, 0),
      conversion: jspb.Message.getFieldWithDefault(msg, 7, 0),
      binSize: jspb.Message.getFieldWithDefault(msg, 8, 0),
      minPercentile: jspb.Message.getFieldWithDefault(msg, 9, 0),
      maxPercentile: jspb.Message.getFieldWithDefault(msg, 10, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.e.Filter}
 */


proto.e.Filter.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.e.Filter();
  return proto.e.Filter.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.e.Filter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.e.Filter}
 */


proto.e.Filter.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value =
        /** @type {number} */
        reader.readInt64();
        msg.setId(value);
        break;

      case 2:
        var value = new google_protobuf_timestamp_pb.Timestamp();
        reader.readMessage(value, google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
        msg.setCreatedAfterTime(value);
        break;

      case 3:
        var value = new google_protobuf_timestamp_pb.Timestamp();
        reader.readMessage(value, google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
        msg.setCreatedBeforeTime(value);
        break;

      case 4:
        var value = new proto.e.QuickFilter();
        reader.readMessage(value, proto.e.QuickFilter.deserializeBinaryFromReader);
        msg.addQuickFilters(value);
        break;

      case 5:
        var value =
        /** @type {!proto.e.Filter.SetOperationType} */
        reader.readEnum();
        msg.setType(value);
        break;

      case 6:
        var value =
        /** @type {!proto.e.Filter.MessageReadStatusType} */
        reader.readEnum();
        msg.setMessageReadStatusType(value);
        break;

      case 7:
        var value =
        /** @type {!proto.e.Filter.ConversionType} */
        reader.readEnum();
        msg.setConversion(value);
        break;

      case 8:
        var value =
        /** @type {number} */
        reader.readInt32();
        msg.setBinSize(value);
        break;

      case 9:
        var value =
        /** @type {number} */
        reader.readInt32();
        msg.setMinPercentile(value);
        break;

      case 10:
        var value =
        /** @type {number} */
        reader.readInt32();
        msg.setMaxPercentile(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.e.Filter.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.e.Filter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.e.Filter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.e.Filter.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getId();

  if (f !== 0) {
    writer.writeInt64(1, f);
  }

  f = message.getCreatedAfterTime();

  if (f != null) {
    writer.writeMessage(2, f, google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter);
  }

  f = message.getCreatedBeforeTime();

  if (f != null) {
    writer.writeMessage(3, f, google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter);
  }

  f = message.getQuickFiltersList();

  if (f.length > 0) {
    writer.writeRepeatedMessage(4, f, proto.e.QuickFilter.serializeBinaryToWriter);
  }

  f = message.getType();

  if (f !== 0.0) {
    writer.writeEnum(5, f);
  }

  f = message.getMessageReadStatusType();

  if (f !== 0.0) {
    writer.writeEnum(6, f);
  }

  f = message.getConversion();

  if (f !== 0.0) {
    writer.writeEnum(7, f);
  }

  f = message.getBinSize();

  if (f !== 0) {
    writer.writeInt32(8, f);
  }

  f = message.getMinPercentile();

  if (f !== 0) {
    writer.writeInt32(9, f);
  }

  f = message.getMaxPercentile();

  if (f !== 0) {
    writer.writeInt32(10, f);
  }
};
/**
 * @enum {number}
 */


proto.e.Filter.SetOperationType = {
  SET_OPERATION_TYPE_UNSPECIFIED: 0,
  INTERSECTION: 1,
  UNION: 2
};
/**
 * @enum {number}
 */

proto.e.Filter.MessageReadStatusType = {
  MESSAGE_READ_STATUS_TYPE_UNSPECIFIED: 0,
  MESSAGE_READ: 1,
  MESSAGE_UNREAD: 2
};
/**
 * @enum {number}
 */

proto.e.Filter.ConversionType = {
  CONVERSION_TYPE_UNSPECIFIED: 0,
  CONVERSION_ONLY: 1,
  CONVERSION_NONE: 2
};
/**
 * optional int64 id = 1;
 * @return {number}
 */

proto.e.Filter.prototype.getId = function () {
  return (
    /** @type {number} */
    jspb.Message.getFieldWithDefault(this, 1, 0)
  );
};
/**
 * @param {number} value
 * @return {!proto.e.Filter} returns this
 */


proto.e.Filter.prototype.setId = function (value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};
/**
 * optional google.protobuf.Timestamp created_after_time = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */


proto.e.Filter.prototype.getCreatedAfterTime = function () {
  return (
    /** @type{?proto.google.protobuf.Timestamp} */
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2)
  );
};
/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.e.Filter} returns this
*/


proto.e.Filter.prototype.setCreatedAfterTime = function (value) {
  return jspb.Message.setWrapperField(this, 2, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.e.Filter} returns this
 */


proto.e.Filter.prototype.clearCreatedAfterTime = function () {
  return this.setCreatedAfterTime(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.e.Filter.prototype.hasCreatedAfterTime = function () {
  return jspb.Message.getField(this, 2) != null;
};
/**
 * optional google.protobuf.Timestamp created_before_time = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */


proto.e.Filter.prototype.getCreatedBeforeTime = function () {
  return (
    /** @type{?proto.google.protobuf.Timestamp} */
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3)
  );
};
/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.e.Filter} returns this
*/


proto.e.Filter.prototype.setCreatedBeforeTime = function (value) {
  return jspb.Message.setWrapperField(this, 3, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.e.Filter} returns this
 */


proto.e.Filter.prototype.clearCreatedBeforeTime = function () {
  return this.setCreatedBeforeTime(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.e.Filter.prototype.hasCreatedBeforeTime = function () {
  return jspb.Message.getField(this, 3) != null;
};
/**
 * repeated QuickFilter quick_filters = 4;
 * @return {!Array<!proto.e.QuickFilter>}
 */


proto.e.Filter.prototype.getQuickFiltersList = function () {
  return (
    /** @type{!Array<!proto.e.QuickFilter>} */
    jspb.Message.getRepeatedWrapperField(this, proto.e.QuickFilter, 4)
  );
};
/**
 * @param {!Array<!proto.e.QuickFilter>} value
 * @return {!proto.e.Filter} returns this
*/


proto.e.Filter.prototype.setQuickFiltersList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};
/**
 * @param {!proto.e.QuickFilter=} opt_value
 * @param {number=} opt_index
 * @return {!proto.e.QuickFilter}
 */


proto.e.Filter.prototype.addQuickFilters = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.e.QuickFilter, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.e.Filter} returns this
 */


proto.e.Filter.prototype.clearQuickFiltersList = function () {
  return this.setQuickFiltersList([]);
};
/**
 * optional SetOperationType type = 5;
 * @return {!proto.e.Filter.SetOperationType}
 */


proto.e.Filter.prototype.getType = function () {
  return (
    /** @type {!proto.e.Filter.SetOperationType} */
    jspb.Message.getFieldWithDefault(this, 5, 0)
  );
};
/**
 * @param {!proto.e.Filter.SetOperationType} value
 * @return {!proto.e.Filter} returns this
 */


proto.e.Filter.prototype.setType = function (value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};
/**
 * optional MessageReadStatusType message_read_status_type = 6;
 * @return {!proto.e.Filter.MessageReadStatusType}
 */


proto.e.Filter.prototype.getMessageReadStatusType = function () {
  return (
    /** @type {!proto.e.Filter.MessageReadStatusType} */
    jspb.Message.getFieldWithDefault(this, 6, 0)
  );
};
/**
 * @param {!proto.e.Filter.MessageReadStatusType} value
 * @return {!proto.e.Filter} returns this
 */


proto.e.Filter.prototype.setMessageReadStatusType = function (value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};
/**
 * optional ConversionType conversion = 7;
 * @return {!proto.e.Filter.ConversionType}
 */


proto.e.Filter.prototype.getConversion = function () {
  return (
    /** @type {!proto.e.Filter.ConversionType} */
    jspb.Message.getFieldWithDefault(this, 7, 0)
  );
};
/**
 * @param {!proto.e.Filter.ConversionType} value
 * @return {!proto.e.Filter} returns this
 */


proto.e.Filter.prototype.setConversion = function (value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};
/**
 * optional int32 bin_size = 8;
 * @return {number}
 */


proto.e.Filter.prototype.getBinSize = function () {
  return (
    /** @type {number} */
    jspb.Message.getFieldWithDefault(this, 8, 0)
  );
};
/**
 * @param {number} value
 * @return {!proto.e.Filter} returns this
 */


proto.e.Filter.prototype.setBinSize = function (value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};
/**
 * optional int32 min_percentile = 9;
 * @return {number}
 */


proto.e.Filter.prototype.getMinPercentile = function () {
  return (
    /** @type {number} */
    jspb.Message.getFieldWithDefault(this, 9, 0)
  );
};
/**
 * @param {number} value
 * @return {!proto.e.Filter} returns this
 */


proto.e.Filter.prototype.setMinPercentile = function (value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};
/**
 * optional int32 max_percentile = 10;
 * @return {number}
 */


proto.e.Filter.prototype.getMaxPercentile = function () {
  return (
    /** @type {number} */
    jspb.Message.getFieldWithDefault(this, 10, 0)
  );
};
/**
 * @param {number} value
 * @return {!proto.e.Filter} returns this
 */


proto.e.Filter.prototype.setMaxPercentile = function (value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.e.QuickFilter.prototype.toObject = function (opt_includeInstance) {
    return proto.e.QuickFilter.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.e.QuickFilter} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.e.QuickFilter.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      exclude: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
      attributeId: jspb.Message.getFieldWithDefault(msg, 2, 0),
      attributeLabel: jspb.Message.getFieldWithDefault(msg, 3, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.e.QuickFilter}
 */


proto.e.QuickFilter.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.e.QuickFilter();
  return proto.e.QuickFilter.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.e.QuickFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.e.QuickFilter}
 */


proto.e.QuickFilter.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value =
        /** @type {boolean} */
        reader.readBool();
        msg.setExclude(value);
        break;

      case 2:
        var value =
        /** @type {number} */
        reader.readInt64();
        msg.setAttributeId(value);
        break;

      case 3:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setAttributeLabel(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.e.QuickFilter.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.e.QuickFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.e.QuickFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.e.QuickFilter.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getExclude();

  if (f) {
    writer.writeBool(1, f);
  }

  f = message.getAttributeId();

  if (f !== 0) {
    writer.writeInt64(2, f);
  }

  f = message.getAttributeLabel();

  if (f.length > 0) {
    writer.writeString(3, f);
  }
};
/**
 * optional bool exclude = 1;
 * @return {boolean}
 */


proto.e.QuickFilter.prototype.getExclude = function () {
  return (
    /** @type {boolean} */
    jspb.Message.getBooleanFieldWithDefault(this, 1, false)
  );
};
/**
 * @param {boolean} value
 * @return {!proto.e.QuickFilter} returns this
 */


proto.e.QuickFilter.prototype.setExclude = function (value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};
/**
 * optional int64 attribute_id = 2;
 * @return {number}
 */


proto.e.QuickFilter.prototype.getAttributeId = function () {
  return (
    /** @type {number} */
    jspb.Message.getFieldWithDefault(this, 2, 0)
  );
};
/**
 * @param {number} value
 * @return {!proto.e.QuickFilter} returns this
 */


proto.e.QuickFilter.prototype.setAttributeId = function (value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};
/**
 * optional string attribute_label = 3;
 * @return {string}
 */


proto.e.QuickFilter.prototype.getAttributeLabel = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 3, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.e.QuickFilter} returns this
 */


proto.e.QuickFilter.prototype.setAttributeLabel = function (value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */


proto.e.RPAConfig.repeatedFields_ = [4];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.e.RPAConfig.prototype.toObject = function (opt_includeInstance) {
    return proto.e.RPAConfig.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.e.RPAConfig} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.e.RPAConfig.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, 0),
      customFormId: jspb.Message.getFieldWithDefault(msg, 2, 0),
      url: jspb.Message.getFieldWithDefault(msg, 3, ""),
      pagesList: jspb.Message.toObjectList(msg.getPagesList(), proto.e.RPAPage.toObject, includeInstance),
      record: (f = msg.getRecord()) && proto.e.Record.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.e.RPAConfig}
 */


proto.e.RPAConfig.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.e.RPAConfig();
  return proto.e.RPAConfig.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.e.RPAConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.e.RPAConfig}
 */


proto.e.RPAConfig.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value =
        /** @type {number} */
        reader.readInt64();
        msg.setId(value);
        break;

      case 2:
        var value =
        /** @type {number} */
        reader.readInt64();
        msg.setCustomFormId(value);
        break;

      case 3:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setUrl(value);
        break;

      case 4:
        var value = new proto.e.RPAPage();
        reader.readMessage(value, proto.e.RPAPage.deserializeBinaryFromReader);
        msg.addPages(value);
        break;

      case 5:
        var value = new proto.e.Record();
        reader.readMessage(value, proto.e.Record.deserializeBinaryFromReader);
        msg.setRecord(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.e.RPAConfig.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.e.RPAConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.e.RPAConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.e.RPAConfig.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getId();

  if (f !== 0) {
    writer.writeInt64(1, f);
  }

  f = message.getCustomFormId();

  if (f !== 0) {
    writer.writeInt64(2, f);
  }

  f = message.getUrl();

  if (f.length > 0) {
    writer.writeString(3, f);
  }

  f = message.getPagesList();

  if (f.length > 0) {
    writer.writeRepeatedMessage(4, f, proto.e.RPAPage.serializeBinaryToWriter);
  }

  f = message.getRecord();

  if (f != null) {
    writer.writeMessage(5, f, proto.e.Record.serializeBinaryToWriter);
  }
};
/**
 * optional int64 id = 1;
 * @return {number}
 */


proto.e.RPAConfig.prototype.getId = function () {
  return (
    /** @type {number} */
    jspb.Message.getFieldWithDefault(this, 1, 0)
  );
};
/**
 * @param {number} value
 * @return {!proto.e.RPAConfig} returns this
 */


proto.e.RPAConfig.prototype.setId = function (value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};
/**
 * optional int64 custom_form_id = 2;
 * @return {number}
 */


proto.e.RPAConfig.prototype.getCustomFormId = function () {
  return (
    /** @type {number} */
    jspb.Message.getFieldWithDefault(this, 2, 0)
  );
};
/**
 * @param {number} value
 * @return {!proto.e.RPAConfig} returns this
 */


proto.e.RPAConfig.prototype.setCustomFormId = function (value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};
/**
 * optional string url = 3;
 * @return {string}
 */


proto.e.RPAConfig.prototype.getUrl = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 3, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.e.RPAConfig} returns this
 */


proto.e.RPAConfig.prototype.setUrl = function (value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};
/**
 * repeated RPAPage pages = 4;
 * @return {!Array<!proto.e.RPAPage>}
 */


proto.e.RPAConfig.prototype.getPagesList = function () {
  return (
    /** @type{!Array<!proto.e.RPAPage>} */
    jspb.Message.getRepeatedWrapperField(this, proto.e.RPAPage, 4)
  );
};
/**
 * @param {!Array<!proto.e.RPAPage>} value
 * @return {!proto.e.RPAConfig} returns this
*/


proto.e.RPAConfig.prototype.setPagesList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};
/**
 * @param {!proto.e.RPAPage=} opt_value
 * @param {number=} opt_index
 * @return {!proto.e.RPAPage}
 */


proto.e.RPAConfig.prototype.addPages = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.e.RPAPage, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.e.RPAConfig} returns this
 */


proto.e.RPAConfig.prototype.clearPagesList = function () {
  return this.setPagesList([]);
};
/**
 * optional Record record = 5;
 * @return {?proto.e.Record}
 */


proto.e.RPAConfig.prototype.getRecord = function () {
  return (
    /** @type{?proto.e.Record} */
    jspb.Message.getWrapperField(this, proto.e.Record, 5)
  );
};
/**
 * @param {?proto.e.Record|undefined} value
 * @return {!proto.e.RPAConfig} returns this
*/


proto.e.RPAConfig.prototype.setRecord = function (value) {
  return jspb.Message.setWrapperField(this, 5, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.e.RPAConfig} returns this
 */


proto.e.RPAConfig.prototype.clearRecord = function () {
  return this.setRecord(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.e.RPAConfig.prototype.hasRecord = function () {
  return jspb.Message.getField(this, 5) != null;
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */


proto.e.RPAPage.repeatedFields_ = [7];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.e.RPAPage.prototype.toObject = function (opt_includeInstance) {
    return proto.e.RPAPage.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.e.RPAPage} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.e.RPAPage.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, 0),
      rpaConfigId: jspb.Message.getFieldWithDefault(msg, 2, 0),
      formSelector: jspb.Message.getFieldWithDefault(msg, 3, ""),
      submitSelector: jspb.Message.getFieldWithDefault(msg, 4, ""),
      forceSubmit: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
      rank: jspb.Message.getFieldWithDefault(msg, 6, 0),
      stepsList: jspb.Message.toObjectList(msg.getStepsList(), proto.e.RPAStep.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.e.RPAPage}
 */


proto.e.RPAPage.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.e.RPAPage();
  return proto.e.RPAPage.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.e.RPAPage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.e.RPAPage}
 */


proto.e.RPAPage.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value =
        /** @type {number} */
        reader.readInt64();
        msg.setId(value);
        break;

      case 2:
        var value =
        /** @type {number} */
        reader.readInt64();
        msg.setRpaConfigId(value);
        break;

      case 3:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setFormSelector(value);
        break;

      case 4:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setSubmitSelector(value);
        break;

      case 5:
        var value =
        /** @type {boolean} */
        reader.readBool();
        msg.setForceSubmit(value);
        break;

      case 6:
        var value =
        /** @type {number} */
        reader.readInt64();
        msg.setRank(value);
        break;

      case 7:
        var value = new proto.e.RPAStep();
        reader.readMessage(value, proto.e.RPAStep.deserializeBinaryFromReader);
        msg.addSteps(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.e.RPAPage.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.e.RPAPage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.e.RPAPage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.e.RPAPage.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getId();

  if (f !== 0) {
    writer.writeInt64(1, f);
  }

  f = message.getRpaConfigId();

  if (f !== 0) {
    writer.writeInt64(2, f);
  }

  f = message.getFormSelector();

  if (f.length > 0) {
    writer.writeString(3, f);
  }

  f = message.getSubmitSelector();

  if (f.length > 0) {
    writer.writeString(4, f);
  }

  f = message.getForceSubmit();

  if (f) {
    writer.writeBool(5, f);
  }

  f = message.getRank();

  if (f !== 0) {
    writer.writeInt64(6, f);
  }

  f = message.getStepsList();

  if (f.length > 0) {
    writer.writeRepeatedMessage(7, f, proto.e.RPAStep.serializeBinaryToWriter);
  }
};
/**
 * optional int64 id = 1;
 * @return {number}
 */


proto.e.RPAPage.prototype.getId = function () {
  return (
    /** @type {number} */
    jspb.Message.getFieldWithDefault(this, 1, 0)
  );
};
/**
 * @param {number} value
 * @return {!proto.e.RPAPage} returns this
 */


proto.e.RPAPage.prototype.setId = function (value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};
/**
 * optional int64 rpa_config_id = 2;
 * @return {number}
 */


proto.e.RPAPage.prototype.getRpaConfigId = function () {
  return (
    /** @type {number} */
    jspb.Message.getFieldWithDefault(this, 2, 0)
  );
};
/**
 * @param {number} value
 * @return {!proto.e.RPAPage} returns this
 */


proto.e.RPAPage.prototype.setRpaConfigId = function (value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};
/**
 * optional string form_selector = 3;
 * @return {string}
 */


proto.e.RPAPage.prototype.getFormSelector = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 3, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.e.RPAPage} returns this
 */


proto.e.RPAPage.prototype.setFormSelector = function (value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};
/**
 * optional string submit_selector = 4;
 * @return {string}
 */


proto.e.RPAPage.prototype.getSubmitSelector = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 4, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.e.RPAPage} returns this
 */


proto.e.RPAPage.prototype.setSubmitSelector = function (value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};
/**
 * optional bool force_submit = 5;
 * @return {boolean}
 */


proto.e.RPAPage.prototype.getForceSubmit = function () {
  return (
    /** @type {boolean} */
    jspb.Message.getBooleanFieldWithDefault(this, 5, false)
  );
};
/**
 * @param {boolean} value
 * @return {!proto.e.RPAPage} returns this
 */


proto.e.RPAPage.prototype.setForceSubmit = function (value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};
/**
 * optional int64 rank = 6;
 * @return {number}
 */


proto.e.RPAPage.prototype.getRank = function () {
  return (
    /** @type {number} */
    jspb.Message.getFieldWithDefault(this, 6, 0)
  );
};
/**
 * @param {number} value
 * @return {!proto.e.RPAPage} returns this
 */


proto.e.RPAPage.prototype.setRank = function (value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};
/**
 * repeated RPAStep steps = 7;
 * @return {!Array<!proto.e.RPAStep>}
 */


proto.e.RPAPage.prototype.getStepsList = function () {
  return (
    /** @type{!Array<!proto.e.RPAStep>} */
    jspb.Message.getRepeatedWrapperField(this, proto.e.RPAStep, 7)
  );
};
/**
 * @param {!Array<!proto.e.RPAStep>} value
 * @return {!proto.e.RPAPage} returns this
*/


proto.e.RPAPage.prototype.setStepsList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};
/**
 * @param {!proto.e.RPAStep=} opt_value
 * @param {number=} opt_index
 * @return {!proto.e.RPAStep}
 */


proto.e.RPAPage.prototype.addSteps = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.e.RPAStep, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.e.RPAPage} returns this
 */


proto.e.RPAPage.prototype.clearStepsList = function () {
  return this.setStepsList([]);
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */


proto.e.RPAStep.repeatedFields_ = [14];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.e.RPAStep.prototype.toObject = function (opt_includeInstance) {
    return proto.e.RPAStep.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.e.RPAStep} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.e.RPAStep.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, 0),
      stepType: jspb.Message.getFieldWithDefault(msg, 2, 0),
      inputType: jspb.Message.getFieldWithDefault(msg, 3, 0),
      waitingTime: jspb.Message.getFieldWithDefault(msg, 4, 0),
      nameAttr: jspb.Message.getFieldWithDefault(msg, 5, ""),
      selector: jspb.Message.getFieldWithDefault(msg, 6, ""),
      htmlAttr: jspb.Message.getFieldWithDefault(msg, 7, ""),
      testValue: jspb.Message.getFieldWithDefault(msg, 8, ""),
      fixedValue: jspb.Message.getFieldWithDefault(msg, 9, ""),
      disableLogging: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
      rank: jspb.Message.getFieldWithDefault(msg, 11, 0),
      skippable: jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
      rpaStepId: jspb.Message.getFieldWithDefault(msg, 13, 0),
      extraStepsList: jspb.Message.toObjectList(msg.getExtraStepsList(), proto.e.RPAStep.toObject, includeInstance),
      separator: jspb.Message.getFieldWithDefault(msg, 16, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.e.RPAStep}
 */


proto.e.RPAStep.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.e.RPAStep();
  return proto.e.RPAStep.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.e.RPAStep} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.e.RPAStep}
 */


proto.e.RPAStep.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value =
        /** @type {number} */
        reader.readInt64();
        msg.setId(value);
        break;

      case 2:
        var value =
        /** @type {!proto.e.RPAStepType} */
        reader.readEnum();
        msg.setStepType(value);
        break;

      case 3:
        var value =
        /** @type {!proto.e.RPAInputType} */
        reader.readEnum();
        msg.setInputType(value);
        break;

      case 4:
        var value =
        /** @type {number} */
        reader.readInt32();
        msg.setWaitingTime(value);
        break;

      case 5:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setNameAttr(value);
        break;

      case 6:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setSelector(value);
        break;

      case 7:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setHtmlAttr(value);
        break;

      case 8:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setTestValue(value);
        break;

      case 9:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setFixedValue(value);
        break;

      case 10:
        var value =
        /** @type {boolean} */
        reader.readBool();
        msg.setDisableLogging(value);
        break;

      case 11:
        var value =
        /** @type {number} */
        reader.readInt32();
        msg.setRank(value);
        break;

      case 12:
        var value =
        /** @type {boolean} */
        reader.readBool();
        msg.setSkippable(value);
        break;

      case 13:
        var value =
        /** @type {number} */
        reader.readInt64();
        msg.setRpaStepId(value);
        break;

      case 14:
        var value = new proto.e.RPAStep();
        reader.readMessage(value, proto.e.RPAStep.deserializeBinaryFromReader);
        msg.addExtraSteps(value);
        break;

      case 16:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setSeparator(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.e.RPAStep.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.e.RPAStep.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.e.RPAStep} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.e.RPAStep.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getId();

  if (f !== 0) {
    writer.writeInt64(1, f);
  }

  f = message.getStepType();

  if (f !== 0.0) {
    writer.writeEnum(2, f);
  }

  f = message.getInputType();

  if (f !== 0.0) {
    writer.writeEnum(3, f);
  }

  f = message.getWaitingTime();

  if (f !== 0) {
    writer.writeInt32(4, f);
  }

  f = message.getNameAttr();

  if (f.length > 0) {
    writer.writeString(5, f);
  }

  f = message.getSelector();

  if (f.length > 0) {
    writer.writeString(6, f);
  }

  f = message.getHtmlAttr();

  if (f.length > 0) {
    writer.writeString(7, f);
  }

  f = message.getTestValue();

  if (f.length > 0) {
    writer.writeString(8, f);
  }

  f = message.getFixedValue();

  if (f.length > 0) {
    writer.writeString(9, f);
  }

  f = message.getDisableLogging();

  if (f) {
    writer.writeBool(10, f);
  }

  f = message.getRank();

  if (f !== 0) {
    writer.writeInt32(11, f);
  }

  f = message.getSkippable();

  if (f) {
    writer.writeBool(12, f);
  }

  f = message.getRpaStepId();

  if (f !== 0) {
    writer.writeInt64(13, f);
  }

  f = message.getExtraStepsList();

  if (f.length > 0) {
    writer.writeRepeatedMessage(14, f, proto.e.RPAStep.serializeBinaryToWriter);
  }

  f = message.getSeparator();

  if (f.length > 0) {
    writer.writeString(16, f);
  }
};
/**
 * optional int64 id = 1;
 * @return {number}
 */


proto.e.RPAStep.prototype.getId = function () {
  return (
    /** @type {number} */
    jspb.Message.getFieldWithDefault(this, 1, 0)
  );
};
/**
 * @param {number} value
 * @return {!proto.e.RPAStep} returns this
 */


proto.e.RPAStep.prototype.setId = function (value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};
/**
 * optional RPAStepType step_type = 2;
 * @return {!proto.e.RPAStepType}
 */


proto.e.RPAStep.prototype.getStepType = function () {
  return (
    /** @type {!proto.e.RPAStepType} */
    jspb.Message.getFieldWithDefault(this, 2, 0)
  );
};
/**
 * @param {!proto.e.RPAStepType} value
 * @return {!proto.e.RPAStep} returns this
 */


proto.e.RPAStep.prototype.setStepType = function (value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};
/**
 * optional RPAInputType input_type = 3;
 * @return {!proto.e.RPAInputType}
 */


proto.e.RPAStep.prototype.getInputType = function () {
  return (
    /** @type {!proto.e.RPAInputType} */
    jspb.Message.getFieldWithDefault(this, 3, 0)
  );
};
/**
 * @param {!proto.e.RPAInputType} value
 * @return {!proto.e.RPAStep} returns this
 */


proto.e.RPAStep.prototype.setInputType = function (value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};
/**
 * optional int32 waiting_time = 4;
 * @return {number}
 */


proto.e.RPAStep.prototype.getWaitingTime = function () {
  return (
    /** @type {number} */
    jspb.Message.getFieldWithDefault(this, 4, 0)
  );
};
/**
 * @param {number} value
 * @return {!proto.e.RPAStep} returns this
 */


proto.e.RPAStep.prototype.setWaitingTime = function (value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};
/**
 * optional string name_attr = 5;
 * @return {string}
 */


proto.e.RPAStep.prototype.getNameAttr = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 5, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.e.RPAStep} returns this
 */


proto.e.RPAStep.prototype.setNameAttr = function (value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};
/**
 * optional string selector = 6;
 * @return {string}
 */


proto.e.RPAStep.prototype.getSelector = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 6, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.e.RPAStep} returns this
 */


proto.e.RPAStep.prototype.setSelector = function (value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};
/**
 * optional string html_attr = 7;
 * @return {string}
 */


proto.e.RPAStep.prototype.getHtmlAttr = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 7, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.e.RPAStep} returns this
 */


proto.e.RPAStep.prototype.setHtmlAttr = function (value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};
/**
 * optional string test_value = 8;
 * @return {string}
 */


proto.e.RPAStep.prototype.getTestValue = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 8, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.e.RPAStep} returns this
 */


proto.e.RPAStep.prototype.setTestValue = function (value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};
/**
 * optional string fixed_value = 9;
 * @return {string}
 */


proto.e.RPAStep.prototype.getFixedValue = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 9, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.e.RPAStep} returns this
 */


proto.e.RPAStep.prototype.setFixedValue = function (value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};
/**
 * optional bool disable_logging = 10;
 * @return {boolean}
 */


proto.e.RPAStep.prototype.getDisableLogging = function () {
  return (
    /** @type {boolean} */
    jspb.Message.getBooleanFieldWithDefault(this, 10, false)
  );
};
/**
 * @param {boolean} value
 * @return {!proto.e.RPAStep} returns this
 */


proto.e.RPAStep.prototype.setDisableLogging = function (value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};
/**
 * optional int32 rank = 11;
 * @return {number}
 */


proto.e.RPAStep.prototype.getRank = function () {
  return (
    /** @type {number} */
    jspb.Message.getFieldWithDefault(this, 11, 0)
  );
};
/**
 * @param {number} value
 * @return {!proto.e.RPAStep} returns this
 */


proto.e.RPAStep.prototype.setRank = function (value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};
/**
 * optional bool skippable = 12;
 * @return {boolean}
 */


proto.e.RPAStep.prototype.getSkippable = function () {
  return (
    /** @type {boolean} */
    jspb.Message.getBooleanFieldWithDefault(this, 12, false)
  );
};
/**
 * @param {boolean} value
 * @return {!proto.e.RPAStep} returns this
 */


proto.e.RPAStep.prototype.setSkippable = function (value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};
/**
 * optional int64 rpa_step_id = 13;
 * @return {number}
 */


proto.e.RPAStep.prototype.getRpaStepId = function () {
  return (
    /** @type {number} */
    jspb.Message.getFieldWithDefault(this, 13, 0)
  );
};
/**
 * @param {number} value
 * @return {!proto.e.RPAStep} returns this
 */


proto.e.RPAStep.prototype.setRpaStepId = function (value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};
/**
 * repeated RPAStep extra_steps = 14;
 * @return {!Array<!proto.e.RPAStep>}
 */


proto.e.RPAStep.prototype.getExtraStepsList = function () {
  return (
    /** @type{!Array<!proto.e.RPAStep>} */
    jspb.Message.getRepeatedWrapperField(this, proto.e.RPAStep, 14)
  );
};
/**
 * @param {!Array<!proto.e.RPAStep>} value
 * @return {!proto.e.RPAStep} returns this
*/


proto.e.RPAStep.prototype.setExtraStepsList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 14, value);
};
/**
 * @param {!proto.e.RPAStep=} opt_value
 * @param {number=} opt_index
 * @return {!proto.e.RPAStep}
 */


proto.e.RPAStep.prototype.addExtraSteps = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 14, opt_value, proto.e.RPAStep, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.e.RPAStep} returns this
 */


proto.e.RPAStep.prototype.clearExtraStepsList = function () {
  return this.setExtraStepsList([]);
};
/**
 * optional string separator = 16;
 * @return {string}
 */


proto.e.RPAStep.prototype.getSeparator = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 16, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.e.RPAStep} returns this
 */


proto.e.RPAStep.prototype.setSeparator = function (value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};
/**
 * @enum {number}
 */


proto.e.PlatformType = {
  PLATFORM_UNSPECIFIED: 0,
  FACEBOOK: 1,
  LINE: 2,
  INSTAGRAM: 3,
  PLATFORM_PARTNER_VENDOR: 4
};
/**
 * @enum {number}
 */

proto.e.RPAStepType = {
  RPA_STEP_UNSPECIFIED: 0,
  RPA_STEP_FILL: 1,
  RPA_STEP_KEY_TYPE: 2,
  RPA_STEP_CLICK: 3,
  RPA_STEP_SELECT: 4,
  RPA_STEP_CHECKBOX: 5,
  RPA_STEP_RADIO: 6,
  RPA_STEP_WAIT: 7,
  RPA_STEP_WAIT_FOR: 8,
  RPA_STEP_JS_EXEC: 9
};
/**
 * @enum {number}
 */

proto.e.RPAInputType = {
  RPA_INPUT_UNSPECIFIED: 0,
  RPA_INPUT_RADIO: 1,
  RPA_INPUT_TEXT: 2,
  RPA_INPUT_SELECT: 3,
  RPA_INPUT_CHECKBOX: 4,
  RPA_INPUT_HIDDEN: 5
};
/**
 * @enum {number}
 */

proto.e.AutomationRuleStatus = {
  AUTOMATION_RULE_UNSPECIFIED: 0,
  AUTOMATION_RULE_PUBLISHED: 1,
  AUTOMATION_RULE_UNPUBLISHED: 2,
  AUTOMATION_RULE_ARCHIVED: 3
};
goog.object.extend(exports, proto.e);