import "core-js/modules/es.array.map";
import _extends from "@babel/runtime/helpers/extends";
import React from 'react';
import PropTypes from 'prop-types';
import Campaign from './Campaign';

var Campaigns = function Campaigns(_ref) {
  var chatbotId = _ref.chatbotId,
      targetId = _ref.targetId,
      handleDeleteCampaign = _ref.handleDeleteCampaign,
      retentionCampaigns = _ref.retentionCampaigns,
      updateCampaign = _ref.updateCampaign;
  return React.createElement("tbody", null, retentionCampaigns.map(function (campaign) {
    return React.createElement(Campaign, _extends({}, campaign, {
      chatbotId: chatbotId,
      targetId: targetId,
      key: "campaign-".concat(campaign.id),
      handleDeleteCampaign: handleDeleteCampaign,
      updateCampaign: updateCampaign,
      campaign: campaign
    }));
  }));
};

Campaigns.propTypes = {
  targetId: PropTypes.string,
  chatbotId: PropTypes.string,
  retentionCampaigns: PropTypes.array,
  handleDeleteCampaign: PropTypes.func,
  updateCampaign: PropTypes.func
};
export default Campaigns;