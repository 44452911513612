var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import styled from 'styled-components';
import { ModalPortal, Input, CharacterCount, Spinner, } from '@src/components/atoms';
import { RichMenuTemplate, Modal, Tabs, ListTemplateGroupTitles, ListTemplateGroups, } from '@src/components/molecules';
import { string } from 'yup';
import { colors } from '@src/colors';
import { handleAPIErrors } from '@src/lib/helpers';
import { addMaxLength } from '@src/lib/helpers';
import { messageTypes } from '@src/lib/app-constants';
import { richMenuMessages } from '@src/i18n/translations';
import * as richMenuApi from '@src/services/api/richMenu';
var TemplateContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: grid;\n  grid-template-columns: repeat(auto-fill, 340px);\n  grid-gap: 16px;\n  justify-items: center;\n  > div {\n    text-align: center;\n  }\n"], ["\n  display: grid;\n  grid-template-columns: repeat(auto-fill, 340px);\n  grid-gap: 16px;\n  justify-items: center;\n  > div {\n    text-align: center;\n  }\n"])));
var TemplateModalWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  .rich-menu-template-modal {\n    overflow: hidden;\n    .rich-menu-label {\n      margin: 12px 0 0 0;\n      width: 240px;\n      .character-count {\n        padding-left: 3px;\n      }\n    }\n\n    @media only screen and (max-width: 1440px) {\n      max-width: 1010px;\n    }\n\n    @media only screen and (min-width: 1441px) {\n      max-width: 1400px;\n    }\n  }\n\n  .heading-element {\n    margin-left: -40px;\n    padding-left: 40px;\n    margin-right: -40px;\n    padding-right: 40px;\n    padding-bottom: 24px;\n    box-shadow: 0px 3px 3px ", ";\n  }\n\n  .section-controls {\n    position: absolute;\n    right: 40px;\n    bottom: 40px;\n    div {\n      overflow: visible;\n    }\n  }\n\n  .rich-menu-label {\n    .character-count {\n      padding-left: 3px;\n    }\n  }\n"], ["\n  .rich-menu-template-modal {\n    overflow: hidden;\n    .rich-menu-label {\n      margin: 12px 0 0 0;\n      width: 240px;\n      .character-count {\n        padding-left: 3px;\n      }\n    }\n\n    @media only screen and (max-width: 1440px) {\n      max-width: 1010px;\n    }\n\n    @media only screen and (min-width: 1441px) {\n      max-width: 1400px;\n    }\n  }\n\n  .heading-element {\n    margin-left: -40px;\n    padding-left: 40px;\n    margin-right: -40px;\n    padding-right: 40px;\n    padding-bottom: 24px;\n    box-shadow: 0px 3px 3px ", ";\n  }\n\n  .section-controls {\n    position: absolute;\n    right: 40px;\n    bottom: 40px;\n    div {\n      overflow: visible;\n    }\n  }\n\n  .rich-menu-label {\n    .character-count {\n      padding-left: 3px;\n    }\n  }\n"])), colors.shadows.light);
var ModalBody = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  margin-bottom: -40px;\n\n  .rich_menu_template_spinner {\n    height: 400px;\n  }\n"], ["\n  display: flex;\n  margin-bottom: -40px;\n\n  .rich_menu_template_spinner {\n    height: 400px;\n  }\n"])));
var Templates = function (_a) {
    var templateWidth = _a.templateWidth, templateHeight = _a.templateHeight, templates = _a.templates, chosenTemplate = _a.chosenTemplate, handleTemplateSelect = _a.handleTemplateSelect;
    return (React.createElement(TemplateContainer, null, Object.entries(templates).map(function (_a, index) {
        var _b = __read(_a, 2), templateIdStr = _b[0], areas = _b[1];
        var templateId = templateIdStr.replace('template', '');
        return (React.createElement(RichMenuTemplate, { onClick: function () { return handleTemplateSelect(Number(templateId)); }, "data-testid": "RichMenuTemplateModal-Template-" + index, key: "rich-menu-template-" + templateId, chosen: chosenTemplate === Number(templateId), templateWidth: templateWidth, templateHeight: templateHeight, areas: areas, isPreview: true }));
    })));
};
var labelSchema = function (intl, platform) {
    return addMaxLength(string().required('リッチメニューのラベルが必須です。'), intl, messageTypes.RICH_MENU + ".label", platform);
};
var RichMenuTemplateModal = /** @class */ (function (_super) {
    __extends(RichMenuTemplateModal, _super);
    function RichMenuTemplateModal() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            chosenTemplate: 1,
            chosenTemplateGroup: 0,
            templateLabel: '',
            errorMsg: '',
            templates: [],
            isLoading: false,
        };
        _this.componentDidMount = function () { return __awaiter(_this, void 0, void 0, function () {
            var templates, e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.setState({ isLoading: true });
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, richMenuApi.getRichMenuTemplates()];
                    case 2:
                        templates = _a.sent();
                        this.setState({ templates: templates, isLoading: false });
                        return [3 /*break*/, 4];
                    case 3:
                        e_1 = _a.sent();
                        console.error('Can not get RichMenu templates: ', e_1);
                        handleAPIErrors(e_1.message);
                        this.setState({ templates: [], isLoading: false });
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        }); };
        _this.handleTemplateSelect = function (value) {
            return _this.setState({ chosenTemplate: value });
        };
        _this.handleTemplateGroupSelect = function (value) {
            return _this.setState({ chosenTemplateGroup: value });
        };
        _this.handleLabelChange = function (_a) {
            var value = _a.target.value;
            _this.setState({ templateLabel: value }, function () {
                var _a = _this.props, intl = _a.intl, platform = _a.platform;
                labelSchema(intl, platform)
                    .validate(value)
                    .then(function (value) {
                    return _this.setState({ templateLabel: value, errorMsg: '' });
                })
                    .catch(function (e) { return _this.setState({ errorMsg: e.message }); });
            });
        };
        _this.confirmTemplateSelect = function () {
            var _a = _this.props, chatbotIdParam = _a.chatbotIdParam, adScenarioIdParam = _a.adScenarioIdParam, blockIdParam = _a.blockIdParam, richMenuCreateHandler = _a.richMenuCreateHandler;
            var _b = _this.state, chosenTemplate = _b.chosenTemplate, templateLabel = _b.templateLabel;
            richMenuCreateHandler({
                chatbotId: chatbotIdParam,
                scenarioId: adScenarioIdParam,
                blockId: blockIdParam,
                template: chosenTemplate,
                label: templateLabel,
            });
        };
        _this.TemplateTabs = function (templateGroup) {
            var width = templateGroup.width, height = templateGroup.height;
            var tabItems = ['tab0', 'tab2', 'tab3'].map(function (tabKey) { return ({
                name: _this.props.intl.formatMessage(richMenuMessages[tabKey]),
                content: (React.createElement(Templates, { templateWidth: width, templateHeight: height, templates: templateGroup[tabKey], chosenTemplate: _this.state.chosenTemplate, handleTemplateSelect: _this.handleTemplateSelect })),
            }); });
            return (React.createElement(Tabs, { tabBorderColor: colors.label.green_blue, tabButtonColor: colors.label.green_blue, tabItems: tabItems, isTabTextCenter: true, withTabs: true, isSubTab: true }));
        };
        return _this;
    }
    // Rich menu template modal has same logic with image map template modal
    // It shared ListTemplateGroupTitles and ListTemplateGroups
    RichMenuTemplateModal.prototype.render = function () {
        var _a = this.state, templateLabel = _a.templateLabel, chosenTemplate = _a.chosenTemplate, errorMsg = _a.errorMsg, chosenTemplateGroup = _a.chosenTemplateGroup, templates = _a.templates, isLoading = _a.isLoading;
        return (React.createElement(ModalPortal, null,
            React.createElement(TemplateModalWrapper, null,
                React.createElement(Modal, __assign({ "data-testid": "rich-menu-template-modal", className: "rich-menu-template-modal", type: "SELECT", text: this.props.intl.formatMessage({ id: "rjK9sv", defaultMessage: "Create a Rich Menu" }), width: "100%" }, this.props, { onConfirm: this.confirmTemplateSelect, hasError: !(templateLabel && !errorMsg), headingElement: React.createElement("div", { className: "heading-element" },
                        React.createElement("div", { className: "message-wrapper primary-label" }, this.props.intl.formatMessage({ id: "dNZB+/", defaultMessage: "Label (required)" })),
                        React.createElement(Input, { className: "rich-menu-label", hasError: !!errorMsg, placeholder: this.props.intl.formatMessage({ id: "EL5PQ6", defaultMessage: "Please enter a Label" }), value: templateLabel, onChange: this.handleLabelChange, "data-testid": "RichMenuTemplateModal-LabelInput" },
                            React.createElement(CharacterCount, { className: "character-count", value: templateLabel, platform: this.props.platform, path: messageTypes.RICH_MENU + ".label" })),
                        errorMsg && (React.createElement("div", { style: { margin: '4px auto 0' }, className: "message-wrapper error" }, errorMsg))) }),
                    React.createElement(ModalBody, null, isLoading ? (React.createElement(Spinner, { className: "rich_menu_template_spinner" })) : (React.createElement(React.Fragment, null,
                        React.createElement(ListTemplateGroupTitles, { chosenTemplateGroup: chosenTemplateGroup, templateGroupTitles: templates.map(function (_a) {
                                var width = _a.width, height = _a.height;
                                return width + " x " + height;
                            }), indexSpacers: [2], handleTemplateGroupSelect: this.handleTemplateGroupSelect }),
                        React.createElement(ListTemplateGroups, { chosenTemplateGroup: chosenTemplateGroup, templateGroups: templates, setChosenTemplateGroup: this.handleTemplateGroupSelect }, this.TemplateTabs))))))));
    };
    return RichMenuTemplateModal;
}(Component));
export default injectIntl(RichMenuTemplateModal);
var templateObject_1, templateObject_2, templateObject_3;
