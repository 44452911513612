import "core-js/modules/es.array.concat";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
import components from './components';

var collection = function collection(_ref) {
  var chatbotId = _ref.chatbotId;
  return "/chatbots/".concat(chatbotId, "/line_flex_messages");
};

var member = function member(_ref2) {
  var id = _ref2.id,
      rest = _objectWithoutProperties(_ref2, ["id"]);

  return "".concat(collection(rest), "/").concat(id);
};

var swapBubbles = function swapBubbles(_ref3) {
  var id = _ref3.id,
      chatbotId = _ref3.chatbotId;
  return "".concat(member({
    id: id,
    chatbotId: chatbotId
  }), "/swap_bubbles");
};

var swap = function swap(_ref4) {
  var id = _ref4.id,
      chatbotId = _ref4.chatbotId;
  return "".concat(member({
    id: id,
    chatbotId: chatbotId
  }), "/swap");
};

export default {
  collection: collection,
  member: member,
  components: components,
  swapBubbles: swapBubbles,
  swap: swap
};