var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import posed from 'react-pose';
import { colors } from '@src/colors';
var AnimatedContainer = posed.div({
    visible: {
        opacity: 1,
        transition: { duration: 300 },
    },
    hidden: {
        opacity: 0,
        transition: { duration: 300 },
    },
});
function backgroundColor(_a) {
    var type = _a.type;
    return ({
        success: colors.success,
        error: colors.error,
        info: colors.background.main_color,
        warning: colors.background.warning,
    }[type] || 'none');
}
function color(_a) {
    var type = _a.type;
    return ({
        success: colors.white,
        error: colors.white,
        info: colors.background.help,
        warning: colors.gray_normal,
    }[type] || 'none');
}
var FlashContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: inline-block;\n  border-radius: 10px;\n  background-color: ", ";\n  color: ", ";\n  padding: 1rem;\n  transition: opacity 0.3s ease-out;\n  opacity: 1;\n  &.hide {\n    opacity: 0;\n  }\n"], ["\n  display: inline-block;\n  border-radius: 10px;\n  background-color: ", ";\n  color: ", ";\n  padding: 1rem;\n  transition: opacity 0.3s ease-out;\n  opacity: 1;\n  &.hide {\n    opacity: 0;\n  }\n"])), backgroundColor, color);
/**
 * This component is meant to flash on the screen with a message for the user
 * @param {string} [type] - Bootstrap style type (e.g. "success")
 * @param {React.ReactNode} [children] - React Child
 * @param {number} [secondsVisible=2] - fade out after the amount of time specified
 */
export default function Flash(_a) {
    var type = _a.type, children = _a.children, className = _a.className, _b = _a.secondsVisible, secondsVisible = _b === void 0 ? 2 : _b;
    var _c = __read(useState(true), 2), visible = _c[0], setVisible = _c[1];
    useEffect(function () {
        setTimeout(function () {
            setVisible(false);
        }, secondsVisible * 1000);
    }, []);
    return (React.createElement(AnimatedContainer, { pose: visible ? 'visible' : 'hidden' },
        React.createElement(FlashContainer, { className: "Flash " + (className || ''), type: type }, children)));
}
var templateObject_1;
