var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState } from 'react';
import styled from 'styled-components';
import { getConnectedIds } from '@src/lib/adHelpers';
import NextBlock from '@src/components/molecules/NextBlock';
import SearchInput from '@src/components/molecules/SearchInput';
import { injectIntl } from 'react-intl';
import { grey } from '@zeals-co-ltd/washi-styles/lib/colors';
import { Icon } from '@zeals-co-ltd/washi-components';
import BlockPostbackConnector from './BlockPostbackConnector';
var SelectorWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  height: 260px;\n  position: relative;\n  margin: 0;\n  padding: 8px;\n  border: 1px solid ", ";\n  background-color: #fff;\n  border-radius: 8px;\n  overflow: auto;\n"], ["\n  width: 100%;\n  height: 260px;\n  position: relative;\n  margin: 0;\n  padding: 8px;\n  border: 1px solid ", ";\n  background-color: #fff;\n  border-radius: 8px;\n  overflow: auto;\n"])), grey[300]);
var BlockSelector = function (_a) {
    var blocks = _a.blocks, isRichMenuArea = _a.isRichMenuArea, hasError = _a.hasError, connectedObject = _a.connectedObject, handleNewBlockClick = _a.handleNewBlockClick, handleBlockClick = _a.handleBlockClick, disconnectBlock = _a.disconnectBlock, label = _a.label, intl = _a.intl;
    var _b = __read(useState(''), 2), filterText = _b[0], setFilterText = _b[1];
    if (!connectedObject)
        return null;
    var block_id = getConnectedIds(connectedObject).block_id;
    return connectedObject && block_id ? (React.createElement(NextBlock, { blocks: blocks, connectedObject: connectedObject, disconnectBlock: disconnectBlock, forModal: true })) : (React.createElement(SelectorWrapper, { "data-testid": "component-container", className: "connect_selector_selector_wrapper" },
        React.createElement(SearchInput, { placeholder: intl.formatMessage({ id: "TE/M/Q", defaultMessage: "Search/Add a Block" }), "data-testid": "search-input", InputProps: {
                startAdornment: (React.createElement(Icon, { name: "adicon_search", color: "primary", fontSize: "small" })),
            }, onChange: function (e) { return setFilterText(e.target.value); }, error: hasError }),
        hasError && (React.createElement("div", { "data-testid": "search-input-error", className: "message-wrapper error" }, intl.formatMessage({ id: "8xiciW", defaultMessage: "Please configure your Block" }))),
        React.createElement(BlockPostbackConnector, { filterText: filterText, blocks: blocks, onNewBlockClick: function (blockName) {
                if (handleNewBlockClick)
                    handleNewBlockClick(blockName);
            }, onPostbackItemClick: function (params) {
                if (!handleBlockClick)
                    return;
                if (label || (!label && isRichMenuArea)) {
                    handleBlockClick(params);
                }
                else {
                    alert(intl.formatMessage({ id: "jzc7Ii", defaultMessage: "Button Label required" }));
                }
            } })));
};
export default injectIntl(BlockSelector);
var templateObject_1;
