var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { flatMap } from 'lodash';
import { chatTypes } from '@src/lib/chat-types';
import { RadioCheckbox, ExpandButton } from '@src/components/atoms';
import { colors } from '@src/colors';
import { hoverCss, centeredFlex } from '@src/lib/mixins';
import { handleAPIErrors } from '@src/lib/helpers';
import { injectIntl } from 'react-intl';
import Layer from './Layer';
import DeliverySection from './DeliverySection';
import BlockItemMenu from './BlockItemMenu';
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  border-bottom: 1px solid ", ";\n  > .title {\n    > .text {\n      ", ";\n    }\n  }\n\n  &:first-child {\n    border-top: 1px solid ", ";\n  }\n"], ["\n  display: flex;\n  border-bottom: 1px solid ", ";\n  > .title {\n    > .text {\n      ", ";\n    }\n  }\n\n  &:first-child {\n    border-top: 1px solid ", ";\n  }\n"])), colors.border.default, hoverCss, colors.border.default);
var RadioCheckboxConrainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", ";\n  padding-left: 8px;\n"], ["\n  ", ";\n  padding-left: 8px;\n"])), centeredFlex);
var ItemContainer = styled.li(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  position: relative;\n  width: 100%;\n  > .title {\n    cursor: pointer;\n    padding: ", ";\n    background: ", ";\n    .test-mode {\n      margin: ", ";\n      font-size: 0.75rem;\n      display: flex;\n      flex-direction: row;\n      align-items: center;\n      padding: 0px;\n      position: static;\n      flex: none;\n      align-self: stretch;\n      > .status {\n        position: static;\n        font-weight: 500;\n        font-size: 10px;\n        line-height: 14px;\n        color: ", ";\n      }\n      > button {\n        color: ", ";\n      }\n    }\n    > .text {\n      display: inline-block;\n      color: ", ";\n      &:hover {\n        cursor: pointer;\n        color: ", ";\n      }\n      span {\n        &:nth-child(2) {\n          display: inline-block;\n          max-width: 182px;\n        }\n      }\n    }\n  }\n  > .layers {\n    > .body {\n      padding: 0.5rem;\n      background: ", ";\n    }\n  }\n\n  .adicon_other {\n    position: absolute;\n    right: 0;\n    margin-right: 1rem;\n    cursor: pointer;\n\n    &:before {\n      font-size: 1.5rem;\n    }\n  }\n"], ["\n  position: relative;\n  width: 100%;\n  > .title {\n    cursor: pointer;\n    padding: ",
    ";\n    background: ",
    ";\n    .test-mode {\n      margin: ", ";\n      font-size: 0.75rem;\n      display: flex;\n      flex-direction: row;\n      align-items: center;\n      padding: 0px;\n      position: static;\n      flex: none;\n      align-self: stretch;\n      > .status {\n        position: static;\n        font-weight: 500;\n        font-size: 10px;\n        line-height: 14px;\n        color: ",
    ";\n      }\n      > button {\n        color: ", ";\n      }\n    }\n    > .text {\n      display: inline-block;\n      color: ", ";\n      &:hover {\n        cursor: pointer;\n        color: ", ";\n      }\n      span {\n        &:nth-child(2) {\n          display: inline-block;\n          max-width: 182px;\n        }\n      }\n    }\n  }\n  > .layers {\n    > .body {\n      padding: 0.5rem;\n      background: ", ";\n    }\n  }\n\n  .adicon_other {\n    position: absolute;\n    right: 0;\n    margin-right: 1rem;\n    cursor: pointer;\n\n    &:before {\n      font-size: 1.5rem;\n    }\n  }\n"])), function (props) {
    return props.isInitialPage ? '16px 16px 32px' : '16px 16px 32px 24px';
}, function (props) {
    return props.isActive ? colors.background.active : colors.background.main_menu;
}, function (props) { return (props.isInitialPage ? '8px 16px 0' : '8px 0 0'); }, function (props) {
    return props.testing ? colors.label.error : colors.label.green_blue;
}, colors.young_navy, colors.old_navy, colors.midnight, colors.background.main_menu);
var StatusMark = styled.span(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: inline-block;\n  font-size: 0.75rem;\n  color: ", ";\n  vertical-align: baseline;\n\n  &::before {\n    content: '';\n    display: inline-block;\n    width: 8px;\n    height: 8px;\n    border-radius: 4px;\n    margin: 0 8px 0 0;\n    background: ", ";\n  }\n"], ["\n  display: inline-block;\n  font-size: 0.75rem;\n  color: ", ";\n  vertical-align: baseline;\n\n  &::before {\n    content: '';\n    display: inline-block;\n    width: 8px;\n    height: 8px;\n    border-radius: 4px;\n    margin: 0 8px 0 0;\n    background: ",
    ";\n  }\n"])), colors.text_pale, function (props) {
    return props.color ? colors.label[props.color] : 'none';
});
var IconButton = styled.button(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  background-color: transparent;\n  border: none;\n"], ["\n  background-color: transparent;\n  border: none;\n"])));
var ScenarioItem = /** @class */ (function (_super) {
    __extends(ScenarioItem, _super);
    function ScenarioItem(props) {
        var _this = _super.call(this, props) || this;
        _this.handleIconOtherClick = function (e) {
            var _a = _this.props, updatePopupMenuPosition = _a.updatePopupMenuPosition, showPopupMenu = _a.showPopupMenu;
            var rect = e.target.getBoundingClientRect();
            var top = rect.top, left = rect.left;
            updatePopupMenuPosition(top + rect.height, left);
            showPopupMenu();
        };
        _this.getBlocksFromLayer = function (layers, blocks, i) {
            var layer = layers[i];
            if (!layer)
                return [];
            var prevLayers = layers.slice(0, i);
            var prevBlockIds = flatMap(prevLayers.map(function (layer) { return layer.blocks; }));
            var theBlocks = layer.blocks
                .filter(function (blockId) { return prevBlockIds.indexOf(blockId) === -1; })
                .map(function (blockId) { return blocks[blockId]; });
            return theBlocks;
        };
        _this.showBlockItemMenu = function (layerId, blockId, layers, rect) {
            _this.setState({
                blockItemMenuTop: rect.top + rect.height,
                blockItemMenuLeft: rect.left,
                blockItemMenuLayerId: layerId,
                blockItemMenuBlockId: blockId,
                // eslint-disable-next-line react/no-unused-state
                blockItemMenuLayers: layers,
                isBlockItemMenuVisible: true,
            });
        };
        _this.handleBlockItemMenuOutside = function () {
            _this.setState(function (prevState) { return ({
                blockItemMenuBottom: window.innerHeight - prevState.blockItemMenuTop + 17,
                blockItemMenuTop: 0,
            }); });
        };
        _this.hideBlockItemMenu = function () {
            _this.setState({ isBlockItemMenuVisible: false });
        };
        _this.openLayer = function (layerId) {
            _this.props.adSetLayerField(layerId, { isExpanded: true });
        };
        _this.closeLayer = function (layerId) {
            _this.props.adSetLayerField(layerId, { isExpanded: false });
        };
        _this.toggleLayer = function (layerId) {
            _this.props.adToggleLayerField(layerId, 'isExpanded');
        };
        _this.openUndefinedBlocksLayer = function () {
            _this.setUndefinedBlocksLayerField({ isExpanded: true });
        };
        _this.closeUndefinedBlocksLayer = function () {
            _this.setUndefinedBlocksLayerField({ isExpanded: false });
        };
        _this.toggleUndefinedBlocksLayer = function () {
            var _a = _this.props, id = _a.id, adToggleUndefinedBlocksLayerField = _a.adToggleUndefinedBlocksLayerField;
            adToggleUndefinedBlocksLayerField(id, 'isExpanded');
        };
        _this.openAllLayers = function () {
            var _a = _this.props, id = _a.id, adOpenAllLayers = _a.adOpenAllLayers;
            adOpenAllLayers(id);
            _this.openUndefinedBlocksLayer();
        };
        _this.toggleAllLayers = function () {
            var _a = _this.props, id = _a.id, adToggleAllLayersField = _a.adToggleAllLayersField, layerIdParam = _a.layerIdParam;
            var allLayerVisible = _this.state.allLayerVisible;
            adToggleAllLayersField(id, { isExpanded: !allLayerVisible });
            _this.setState({ allLayerVisible: !allLayerVisible }, _this.callbackToggleLayers);
        };
        _this.callbackToggleLayers = function () {
            var _a = _this.props, layerIdParam = _a.layerIdParam, undefined_blocks = _a.undefined_blocks, blockIdParam = _a.blockIdParam;
            if (_this.state.allLayerVisible) {
                _this.openUndefinedBlocksLayer();
            }
            else {
                _this.closeUndefinedBlocksLayer();
            }
            _this.openLayer(layerIdParam);
            if (undefined_blocks.find(function (x) { return x.id === blockIdParam; })) {
                _this.openUndefinedBlocksLayer();
            }
        };
        // end layer actions
        _this.handleMoveBlockToOtherLayer = function (layerId, blockId, targetLayerId) { return __awaiter(_this, void 0, void 0, function () {
            var _a, moveBlockToOtherLayer, chatTypeParam, chatbotIdParam, adScenarioIdParam, per, page, search, e_1;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this.props, moveBlockToOtherLayer = _a.moveBlockToOtherLayer, chatTypeParam = _a.chatTypeParam, chatbotIdParam = _a.chatbotIdParam, adScenarioIdParam = _a.adScenarioIdParam, per = _a.per, page = _a.page, search = _a.search;
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, moveBlockToOtherLayer({
                                chatType: chatTypeParam,
                                chatbotId: chatbotIdParam,
                                adScenarioId: adScenarioIdParam,
                                layerId: layerId,
                                blockId: blockId,
                                targetLayerId: targetLayerId,
                                per: per,
                                page: page,
                                search: search,
                            })];
                    case 2:
                        _b.sent();
                        this.hideBlockItemMenu();
                        return [3 /*break*/, 4];
                    case 3:
                        e_1 = _b.sent();
                        handleAPIErrors(e_1);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        }); };
        _this.clickCheckBox = function () {
            _this.props.removeScenarioIdsChange(_this.props.id);
        };
        _this.renderItemContainer = function () {
            var _a = _this.props, name = _a.name, id = _a.id, chatbotIdParam = _a.chatbotIdParam, adScenarioIdParam = _a.adScenarioIdParam, layerIdParam = _a.layerIdParam, blockIdParam = _a.blockIdParam, layers = _a.layers, undefinedBlocksLayer = _a.undefinedBlocksLayer, blocks = _a.blocks, isActive = _a.isActive, color = _a.color, handleScenarioItemClick = _a.handleScenarioItemClick, chatTypeParam = _a.chatTypeParam, undefined_blocks = _a.undefined_blocks, scenario_delivery = _a.scenario_delivery, status = _a.status, campaign_content = _a.campaign_content, searched_users_num = _a.searched_users_num, testing = _a.testing, isHover = _a.isHover, intl = _a.intl;
            var isInitialPage = chatTypeParam === chatTypes.INITIAL;
            var isShotPage = chatTypeParam === chatTypes.SHOT;
            var isStepPage = chatTypeParam === chatTypes.STEP;
            return (React.createElement(ItemContainer, { "data-testid": "scenario-item-" + id, isInitialPage: isInitialPage, isActive: isActive, testing: testing, 
                // used for testing
                // eslint-disable-next-line jsx-a11y/aria-role
                role: "scenario-item" },
                React.createElement("div", { role: "button", tabIndex: 0, className: "title", onClick: handleScenarioItemClick(id), onDoubleClick: function () { return _this.openAllLayers(); } },
                    React.createElement("div", { className: "text" },
                        isInitialPage ? (React.createElement(StatusMark, { color: isInitialPage ? color : '' })) : null,
                        React.createElement("span", { "data-testid": "scenario-item-name" }, name),
                        React.createElement("div", { className: "test-mode" },
                            React.createElement("div", { className: "status" },
                                intl.formatMessage({ id: "xJwUmX", defaultMessage: "Test Mode" }),
                                ' : ',
                                testing
                                    ? intl.formatMessage({ id: "Zh+5A6", defaultMessage: "On" })
                                    : intl.formatMessage({ id: "OvzONl", defaultMessage: "Off" }),
                                React.createElement(StatusMark, { color: testing ? 'error' : 'green_blue' })),
                            React.createElement(IconButton, { className: "adicon_other", onClick: _this.handleIconOtherClick, "data-testid": "ScenarioItem_OptionsMenuButton" })))),
                isShotPage || isStepPage ? (React.createElement(DeliverySection, { color: color, isShotPage: isShotPage, isStepPage: isStepPage, scenario_delivery: scenario_delivery, campaign_content: campaign_content, status: status, searched_users_num: searched_users_num })) : null,
                isActive ? (React.createElement("div", { className: "layers" },
                    React.createElement(ExpandButton, { allLayerVisible: _this.state.allLayerVisible, onClick: _this.toggleAllLayers, "data-testid": "ScenarioItem_ExpandButton" }),
                    React.createElement("ul", { className: "body" },
                        layers.map(function (layer, i) {
                            var theBlocks = _this.getBlocksFromLayer(layers, blocks, i);
                            if (theBlocks.length === 0)
                                return null;
                            return (React.createElement(Layer, __assign({}, layer, { iterator: i, blocks: theBlocks, layers: layers, key: layer.id + "-menu-layer", chatTypeParam: chatTypeParam, chatbotIdParam: chatbotIdParam, adScenarioIdParam: adScenarioIdParam, layerIdParam: layerIdParam, blockIdParam: blockIdParam, showBlockItemMenu: _this.showBlockItemMenu, openLayer: _this.openLayer, closeLayer: _this.closeLayer, onClick: _this.toggleLayer, isHover: isHover })));
                        }),
                        undefined_blocks && undefined_blocks.length > 0 ? (React.createElement(Layer, __assign({}, undefinedBlocksLayer, { disconnectedBlock: true, blocks: undefined_blocks, chatTypeParam: chatTypeParam, chatbotIdParam: chatbotIdParam, adScenarioIdParam: adScenarioIdParam, layerIdParam: layerIdParam, blockIdParam: blockIdParam, showBlockItemMenu: _this.showBlockItemMenu, openLayer: _this.openUndefinedBlocksLayer, closeLayer: _this.closeUndefinedBlocksLayer, onClick: _this.toggleUndefinedBlocksLayer, isHover: isHover, intl: intl }))) : null))) : null,
                _this.state.isBlockItemMenuVisible ? (React.createElement(BlockItemMenu, { top: _this.state.blockItemMenuTop, left: _this.state.blockItemMenuLeft, bottom: _this.state.blockItemMenuBottom, handleBlockItemMenuOutside: _this.handleBlockItemMenuOutside, layerId: _this.state.blockItemMenuLayerId, blockId: _this.state.blockItemMenuBlockId, layers: layers, hideBlockItemMenu: _this.hideBlockItemMenu, handleMoveBlockToOtherLayer: _this.handleMoveBlockToOtherLayer, intl: intl })) : null));
        };
        _this.state = {
            // blockIetmMenu
            isBlockItemMenuVisible: false,
            blockItemMenuTop: 0,
            blockItemMenuLeft: 0,
            blockItemMenuBottom: 0,
            blockItemMenuLayerId: null,
            blockItemMenuBlockId: null,
            // eslint-disable-next-line react/no-unused-state
            blockItemMenuLayers: null,
            allLayerVisible: false,
        };
        return _this;
    }
    ScenarioItem.prototype.componentDidUpdate = function (nextProps) {
        var layers = this.props.layers;
        if (layers.length !== nextProps.layers.length && layers.length) {
            // when there is a new layer added automatically open it
            this.openLastLayer();
        }
    };
    // layer actions
    ScenarioItem.prototype.openLastLayer = function () {
        var _a = this.props, layers = _a.layers, adSetLayerField = _a.adSetLayerField;
        adSetLayerField(layers[layers.length - 1].id, { isExpanded: true });
    };
    ScenarioItem.prototype.setUndefinedBlocksLayerField = function (field) {
        var _a = this.props, id = _a.id, adSetUndefinedBlocksLayerField = _a.adSetUndefinedBlocksLayerField;
        adSetUndefinedBlocksLayerField(id, field);
    };
    ScenarioItem.prototype.render = function () {
        var _a = this.props, isDeleteChecked = _a.isDeleteChecked, isDeleteMode = _a.isDeleteMode;
        return (React.createElement(Container, null,
            isDeleteMode && (React.createElement(RadioCheckboxConrainer, null,
                React.createElement(RadioCheckbox, { changeCheckedState: this.clickCheckBox, checked: isDeleteChecked }))),
            this.renderItemContainer()));
    };
    return ScenarioItem;
}(PureComponent));
export default injectIntl(ScenarioItem);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
