var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import { tabTypes, buttonTypes } from '@src/lib/app-constants';
import { EmojiSelector, ModalPortal, Input, CharacterCount, } from '@src/components/atoms';
import Modal from '@src/components/molecules/Modal';
import ActionSelector from '@src/components/molecules/ActionSelector';
import talkCustomizeAddButtonModalSchema from '@src/lib/helpers/form_modal_schema/talkCustomizeAddButtonModalSchema';
import useActionFormValidator from '@src/components/hooks/useActionFormValidator';
import featureFlags from '@src/lib/feature_flags/featureFlags';
import limitations from '@src/lib/platforms/limitations';
import { AlertModalContext } from '@src/components/molecules/AlertModal';
// default button values is create for new button
var defaultButton = {
    id: null,
    name: '',
    url: '',
    postback_action: {
        effect: tabTypes.BLOCK,
    },
};
var TalkCustomizeAddButtonModal = function (_a) {
    var _b = _a.button, button = _b === void 0 ? defaultButton : _b, platform = _a.platform, intl = _a.intl, activeBlocks = _a.activeBlocks, chatbotIdParam = _a.chatbotIdParam, adScenarioIdParam = _a.adScenarioIdParam, messageId = _a.messageId, itemId = _a.itemId, itemIterator = _a.itemIterator, buttonItemId = _a.buttonItemId, buttonIterator = _a.buttonIterator, parentPersistentMenuIterator = _a.parentPersistentMenuIterator, persistentMenuIterator = _a.persistentMenuIterator, persistentMenuItemIdParam = _a.persistentMenuItemIdParam, rank = _a.rank, listType = _a.listType, messageIterator = _a.messageIterator, isFlexButton = _a.isFlexButton, createNewBlock = _a.createNewBlock, addBlockToButtonTemplate = _a.addBlockToButtonTemplate, addBlockToGenericButton = _a.addBlockToGenericButton, onConfirm = _a.onConfirm, onCancel = _a.onCancel;
    var handleModal = React.useContext(AlertModalContext).handleModal;
    var _c = __read(useState(0), 2), cursorPosition = _c[0], setCursorPosition = _c[1];
    var _d = useActionFormValidator({
        intl: intl,
        platform: platform,
        object: __assign(__assign({}, button), { name: (button === null || button === void 0 ? void 0 : button.name) || (button === null || button === void 0 ? void 0 : button.title) }),
        schema: talkCustomizeAddButtonModalSchema,
    }), isValidating = _d.isValidating, values = _d.values, errors = _d.errors, touched = _d.touched, handleChange = _d.handleChange, initActionSelectorValue = _d.initActionSelectorValue, actionSelectorOnChange = _d.actionSelectorOnChange;
    // Flex message doesn't support the phone number and email feature for now
    // PhoneNumber & Email feature is only available with Line platform
    var ignoreEffects = [
        isFlexButton || !limitations[platform].CAN_PHONE_NUMBER
            ? tabTypes.PHONE_NUMBER
            : null,
        isFlexButton || !limitations[platform].CAN_EMAIL ? tabTypes.EMAIL : null,
    ].filter(Boolean);
    // featureFlags will defined ignore using mailto or tel
    if (!featureFlags.TEL_MAILTO_UPDATE_BUTTON)
        ignoreEffects = ignoreEffects.concat([
            tabTypes.PHONE_NUMBER,
            tabTypes.EMAIL,
        ]);
    var getEffect = function () {
        if (values.postback_action.effect === tabTypes.PHONE_NUMBER) {
            return buttonTypes.CALL;
        }
        if (values.postback_action.effect === tabTypes.URL) {
            return null;
        }
        return values.postback_action.effect;
    };
    var handleConfirm = function () {
        var _a;
        var buttonTemplate = {
            name: values.name,
            effect: getEffect(),
            url: null,
            blockId: null,
            layerRank: null,
        };
        switch (values.postback_action.effect) {
            case tabTypes.URL:
            case tabTypes.PHONE_NUMBER:
            case tabTypes.EMAIL:
                buttonTemplate.url = values.postback_action.url;
                break;
            default:
                buttonTemplate.blockId = (_a = button.postback_action) === null || _a === void 0 ? void 0 : _a.block_id;
                buttonTemplate.layerRank = rank;
        }
        var persistentMenuObject = {
            title: values.name,
            url: values.postback_action.url,
            effect: isFlexButton ? values.postback_action.effect : null,
        };
        onConfirm({
            chatbotId: chatbotIdParam,
            scenarioId: adScenarioIdParam,
            adScenarioId: button.scenario_id,
            messageId: messageId,
            itemId: itemId,
            listIterator: itemIterator,
            buttonItemId: buttonItemId,
            buttonIterator: buttonIterator,
            buttonTemplate: buttonTemplate,
            persistentMenuObject: persistentMenuObject,
            parentPersistentMenuIterator: parentPersistentMenuIterator,
            persistentMenuIterator: persistentMenuIterator,
            persistentMenuId: persistentMenuItemIdParam,
            selectedTab: values.postback_action.effect,
            postbackActionId: button.postback_action
                ? button.postback_action.id
                : null,
        });
    };
    var handleNewBlockClick = function (filterText) { return __awaiter(void 0, void 0, void 0, function () {
        var paramObj, isGenericMessage, params;
        return __generator(this, function (_a) {
            paramObj = {
                chatbotId: chatbotIdParam,
                adScenarioId: adScenarioIdParam,
                messageIterator: messageIterator,
                itemIterator: itemIterator,
                layer_rank: rank,
            };
            try {
                if (button.id) {
                    // button.id must be an int
                    // creates a new block and adds it to the button
                    createNewBlock(__assign(__assign({}, paramObj), { label: values.name, name: filterText, buttonId: button.id, messageId: messageId }));
                }
                else {
                    isGenericMessage = listType === 'generic';
                    params = {
                        chatbotId: chatbotIdParam,
                        adScenarioId: adScenarioIdParam,
                        messageId: messageId,
                        itemId: itemId,
                        layer_rank: rank,
                        effect: tabTypes.BLOCK,
                        name: values.name || null,
                        blockName: filterText,
                        itemIterator: itemIterator,
                    };
                    if (isGenericMessage) {
                        addBlockToGenericButton(params);
                    }
                    else {
                        addBlockToButtonTemplate(params);
                    }
                }
            }
            catch (e) {
                handleModal(e.message);
            }
            onCancel();
            return [2 /*return*/];
        });
    }); };
    var handleBlockClick = function (_a) {
        var _b = _a.blockId, blockId = _b === void 0 ? null : _b, _c = _a.nextScenarioId, nextScenarioId = _c === void 0 ? null : _c, _d = _a.scenarioId, scenarioId = _d === void 0 ? null : _d;
        var buttonTemplate = {
            name: values.name,
            effect: tabTypes.BLOCK,
            blockId: blockId,
            layerRank: rank,
        };
        var persistentMenuObject = {
            title: values.name,
            url: values.postback_action.effect === tabTypes.URL
                ? values.postback_action.url
                : '',
            effect: values.postback_action.effect,
        };
        onConfirm({
            chatbotId: chatbotIdParam,
            scenarioId: adScenarioIdParam,
            adScenarioId: scenarioId || nextScenarioId,
            messageId: messageId,
            messageIterator: messageIterator,
            itemId: itemId,
            listIterator: itemIterator,
            buttonItemId: buttonItemId,
            buttonIterator: buttonIterator,
            buttonTemplate: buttonTemplate,
            blockId: blockId,
            nextScenarioId: nextScenarioId,
            persistentMenuObject: persistentMenuObject,
            parentPersistentMenuIterator: parentPersistentMenuIterator,
            persistentMenuIterator: persistentMenuIterator,
            persistentMenuId: persistentMenuItemIdParam,
            selectedTab: values.postback_action.effect,
            layerRank: rank,
        });
        onCancel();
    };
    return (React.createElement(ModalPortal, null,
        React.createElement(Modal, { "data-testid": "talk-customize-add-button-modal", type: "SELECT", text: intl.formatMessage({ id: "7w7QNO", defaultMessage: "Button Settings" }), hasError: Boolean(Object.keys(errors).length), onConfirm: handleConfirm, onCancel: onCancel },
            React.createElement("div", { className: "message-wrapper primary-label" }, intl.formatMessage({ id: "753yX5", defaultMessage: "Label" })),
            React.createElement(Input, { placeholder: intl.formatMessage({ id: "Seanpx", defaultMessage: "Required" }), name: "name", value: values.name || '', hasError: Boolean(touched.name && errors.name), onChange: handleChange, onBlur: function (e) { return setCursorPosition(e.target.selectionStart); }, "data-testid": "TalkCustomizeAddButtonModal_LabelInput" },
                React.createElement(CharacterCount, { value: values.name, platform: platform, path: "postback_action.label" }),
                React.createElement(EmojiSelector, { onChange: function (value, _, emoji) {
                        handleChange({
                            target: {
                                name: 'name',
                                value: value,
                            },
                        });
                        setCursorPosition(cursorPosition + emoji.native.length);
                    }, text: values.name || '', cursorPosition: cursorPosition })),
            touched.name && errors.name ? (React.createElement("div", { className: "message-wrapper error", style: { textAlign: 'left' } }, errors.name)) : null,
            React.createElement(ActionSelector, { initActionSelectorValue: initActionSelectorValue, onChange: actionSelectorOnChange({
                    newBlock: handleNewBlockClick,
                    selectBlock: function (blockId) { return handleBlockClick({ blockId: blockId }); },
                    disconnectBlock: function () { return handleBlockClick({ blockId: null }); },
                    selectScenario: function (nextScenarioId) {
                        return handleBlockClick({ nextScenarioId: nextScenarioId });
                    },
                    disconnectScenario: function () {
                        return handleBlockClick({ nextScenarioId: null });
                    },
                }), label: values.name || '', connectedObject: button, blocks: activeBlocks, errors: Object(errors).postback_action, ignoreEffects: ignoreEffects, isValidating: isValidating }))));
};
export default injectIntl(TalkCustomizeAddButtonModal);
