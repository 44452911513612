export default {
    wordStyle: [
        {
            value: 'Bold',
            style: 'BOLD',
        },
        {
            value: 'Italic',
            style: 'ITALIC',
        },
        {
            value: 'Underline',
            style: 'UNDERLINE',
        },
        {
            value: 'Line-through',
            style: 'STRIKETHROUGH',
        },
    ],
    inlineStyles: {
        xxs: { fontSize: '10px' },
        xs: { fontSize: '11.7px' },
        sm: { fontSize: '12.6px' },
        md: { fontSize: '14px' },
        lg: { fontSize: '17px' },
        xl: { fontSize: '20px' },
        xxl: { fontSize: '26px' },
        '3xl': { fontSize: '31px' },
        '4xl': { fontSize: '43px' },
        '#FF0000': { color: '#FF0000' },
        '#00FF00': { color: '#00FF00' },
        '#0000FF': { color: '#0000FF' },
        '#000000': { color: '#000000' },
    },
};
