var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState } from 'react';
import styled from 'styled-components';
import { colors, shadows } from '@src/colors';
import { hoverMenuBg } from '@src/lib/mixins';
import { countries } from '@src/lib/app-constants';
import { Input } from '@src/components/atoms';
import { injectIntl } from 'react-intl';
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  span {\n    font-size: 14px;\n    color: ", ";\n  }\n  .phone-number-input {\n    padding: 0;\n    display: flex;\n    flex-direction: row-reverse;\n  }\n  .country-btn {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    padding: 12px;\n    width: 25%;\n    height: 100%;\n    margin-right: 16px;\n    border: none;\n    border-right: 1px solid ", ";\n    background: ", ";\n  }\n  .country-list {\n    width: 50%;\n    margin: 5px 0 0 0;\n    background: ", ";\n    box-shadow: ", ";\n    border-radius: 10px;\n    .country-item {\n      ", "\n      padding: 10px;\n      display: flex;\n      align-items: center;\n      justify-content: space-between;\n      height: 40px;\n    }\n  }\n  .flag {\n    margin-right: 10px;\n    font-size: 18px;\n  }\n"], ["\n  span {\n    font-size: 14px;\n    color: ", ";\n  }\n  .phone-number-input {\n    padding: 0;\n    display: flex;\n    flex-direction: row-reverse;\n  }\n  .country-btn {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    padding: 12px;\n    width: 25%;\n    height: 100%;\n    margin-right: 16px;\n    border: none;\n    border-right: 1px solid ", ";\n    background: ", ";\n  }\n  .country-list {\n    width: 50%;\n    margin: 5px 0 0 0;\n    background: ", ";\n    box-shadow: ", ";\n    border-radius: 10px;\n    .country-item {\n      ", "\n      padding: 10px;\n      display: flex;\n      align-items: center;\n      justify-content: space-between;\n      height: 40px;\n    }\n  }\n  .flag {\n    margin-right: 10px;\n    font-size: 18px;\n  }\n"])), colors.default_text, colors.border.default, colors.bg_darker, colors.white, shadows.medium.mid, hoverMenuBg);
var japan = countries[0];
function findCountry(noSymbolValue) {
    return countries.find(function (_a) {
        var code = _a.code;
        return noSymbolValue.startsWith(code);
    }) || japan;
}
/**
 * Returns back a local phone number and a `Country`
 * If an invalid international phone number is passed
 * Then it will return back the default value of
 * { phoneNumber: '', country: japan }
 */
function removeSymbols(phoneNumber) {
    return phoneNumber.replace(/-|\+/g, '');
}
export function separatePhone(value) {
    if (!value)
        return { phoneNumber: '', country: japan };
    var noSymbolValue = removeSymbols(value);
    var country = findCountry(noSymbolValue);
    var phoneNumber = noSymbolValue.slice(country.code.length);
    return { phoneNumber: phoneNumber, country: country };
}
/**
 * Takes an international phone number in the form a. `+818012341234`
 * or with hyphens b. `+81-80-1234-1234` (will remove hyphens if provided)
 * And will return updates to consumer in the form of (a).
 */
var PhoneNumberInput = function (_a) {
    var hasError = _a.hasError, onChange = _a.onChange, initValue = _a.initValue, intl = _a.intl;
    var _b = separatePhone(initValue), initPhoneNumber = _b.phoneNumber, initCountry = _b.country;
    var _c = __read(useState(initPhoneNumber), 2), phoneNumber = _c[0], setPhoneNumber = _c[1];
    var _d = __read(useState(initCountry), 2), country = _d[0], setCountry = _d[1];
    var _e = __read(useState(false), 2), dropdown = _e[0], setDropdown = _e[1];
    function handleOnChange(phoneNumber, country) {
        var validNumber = removeSymbols(phoneNumber);
        var fullPhoneNumber = validNumber.length
            ? "+" + country.code + validNumber
            : '';
        onChange(fullPhoneNumber, country, phoneNumber);
    }
    var onChooseCountry = function (country) {
        handleOnChange(phoneNumber, country);
        setCountry(country);
        setDropdown(!dropdown);
    };
    var onChangePhoneNumber = function (phoneNumber) {
        setPhoneNumber(phoneNumber);
        handleOnChange(phoneNumber, country);
    };
    return (React.createElement(Container, null,
        React.createElement(Input, { className: "phone-number-input", hasError: hasError, placeholder: intl.formatMessage({ id: "RNI5xy", defaultMessage: "Please enter a Phone Number" }), onChange: function (e) { return onChangePhoneNumber(e.target.value); }, value: phoneNumber },
            React.createElement("button", { type: "button", "data-testid": "country-toggle", onClick: function () { return setDropdown(!dropdown); }, className: "country-btn" },
                React.createElement("span", { className: "flag" }, country.flag),
                React.createElement("span", null,
                    "+",
                    country.code),
                React.createElement("span", { className: dropdown ? 'adicon_arrow_up' : 'adicon_arrow_down' }))),
        dropdown && (React.createElement("ul", { className: "country-list", "data-testid": "country-list" }, countries === null || countries === void 0 ? void 0 : countries.map(function (country) { return (
        // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
        React.createElement("li", { key: country.code, className: "country-item", onClick: function () { return onChooseCountry(country); } },
            React.createElement("span", null,
                React.createElement("span", { className: "flag" }, country.flag),
                React.createElement("span", null, country.name)),
            React.createElement("span", null, country.code))); })))));
};
export default injectIntl(PhoneNumberInput);
var templateObject_1;
