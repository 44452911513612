var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { Icon, TextField } from '@zeals-co-ltd/washi-components';
import MUIChip from '@mui/material/Chip';
import MUIBox from '@mui/material/Box';
import { injectIntl } from 'react-intl';
import Spinner from '@src/components/atoms/Spinner';
import styled from 'styled-components';
import { grey } from '@zeals-co-ltd/washi-styles';
import UserSegmentCard from '@src/components/molecules/UserSegmentCard';
var UserSegmentWrapper = styled(MUIBox)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n  position: relative;\n  border-top-left-radius: 8px;\n  border-bottom-left-radius: 8px;\n  border: 1px solid ", ";\n"], ["\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n  position: relative;\n  border-top-left-radius: 8px;\n  border-bottom-left-radius: 8px;\n  border: 1px solid ", ";\n"])), grey[300]);
var UserSegmentHeader = styled(MUIBox)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  height: 30px;\n  border-top-left-radius: 8px;\n  padding: 8px;\n  background: ", ";\n  border-bottom: 1px solid ", ";\n  text-align: center;\n  font-weight: 500;\n  font-size: 10px;\n  line-height: 14px;\n  letter-spacing: 1.5px;\n  text-transform: uppercase;\n"], ["\n  height: 30px;\n  border-top-left-radius: 8px;\n  padding: 8px;\n  background: ", ";\n  border-bottom: 1px solid ", ";\n  text-align: center;\n  font-weight: 500;\n  font-size: 10px;\n  line-height: 14px;\n  letter-spacing: 1.5px;\n  text-transform: uppercase;\n"])), grey[50], grey[300]);
var UserSegmentBody = styled(MUIBox)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  height: 100%;\n  padding: 0 8px;\n  display: flex;\n  flex-direction: column;\n  label {\n    margin: 2px;\n  }\n  label:first-child {\n    margin-top: 0px;\n  }\n  label:last-child {\n    margin-bottom: 0px;\n  }\n"], ["\n  height: 100%;\n  padding: 0 8px;\n  display: flex;\n  flex-direction: column;\n  label {\n    margin: 2px;\n  }\n  label:first-child {\n    margin-top: 0px;\n  }\n  label:last-child {\n    margin-bottom: 0px;\n  }\n"])));
var FilterButtonWrapper = styled(MUIBox)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  gap: 8px;\n  margin-top: 16px;\n  margin-bottom: 16px;\n  div {\n    font-size: 10px;\n    line-height: 14px;\n    text-align: center;\n    letter-spacing: 1.5px;\n    text-transform: uppercase;\n  }\n"], ["\n  display: flex;\n  justify-content: center;\n  gap: 8px;\n  margin-top: 16px;\n  margin-bottom: 16px;\n  div {\n    font-size: 10px;\n    line-height: 14px;\n    text-align: center;\n    letter-spacing: 1.5px;\n    text-transform: uppercase;\n  }\n"])));
var UserSegmentsWrapper = styled(MUIBox)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  margin-top: 16px;\n  margin-bottom: 16px;\n  display: flex;\n  flex-direction: column;\n  overflow: auto;\n  height: calc(100% - 160px);\n"], ["\n  margin-top: 16px;\n  margin-bottom: 16px;\n  display: flex;\n  flex-direction: column;\n  overflow: auto;\n  height: calc(100% - 160px);\n"])));
var UserSegmentFilters = function (_a) {
    var isFetching = _a.isFetching, searchTerm = _a.searchTerm, setSearchTerm = _a.setSearchTerm, resetUserSegmentIds = _a.resetUserSegmentIds, selectedUserSegmentIds = _a.selectedUserSegmentIds, userSegments = _a.userSegments, onChangeUserSegmentIds = _a.onChangeUserSegmentIds, intl = _a.intl;
    var hasSelectedSegments = Boolean(selectedUserSegmentIds.size);
    return (React.createElement(UserSegmentWrapper, null,
        React.createElement(UserSegmentHeader, null, intl.formatMessage({ id: "PU5M9+", defaultMessage: "User Segment" })),
        React.createElement(UserSegmentBody, null, isFetching ? (React.createElement(Spinner, null)) : (React.createElement(React.Fragment, null,
            React.createElement(FilterButtonWrapper, null,
                React.createElement(MUIChip
                // TODO: correct logic onClick function later
                , __assign({ 
                    // TODO: correct logic onClick function later
                    color: "primary", label: intl.formatMessage({ id: "byP6IC", defaultMessage: "Selected" }), onDelete: resetUserSegmentIds }, (hasSelectedSegments
                    ? {
                        color: 'primary',
                        label: intl.formatMessage({ id: "byP6IC", defaultMessage: "Selected" }),
                        onDelete: resetUserSegmentIds,
                    }
                    : {
                        color: 'default',
                        disabled: true,
                        label: intl.formatMessage({ id: "HeX7TW", defaultMessage: "None Selected" }),
                        onDelete: undefined,
                    }))),
                React.createElement(MUIChip, { label: intl.formatMessage({ id: "IgwDhg", defaultMessage: "No fit segment" }) })),
            React.createElement(TextField, { placeholder: intl.formatMessage({ id: "u6nWkB", defaultMessage: "Search By Segment Name" }), value: searchTerm, onChange: function (e) {
                    setSearchTerm(e.target.value);
                }, InputProps: {
                    startAdornment: React.createElement(Icon, { name: "adicon_search" }),
                    endAdornment: searchTerm && (React.createElement(Icon, { name: "adicon_close_circle", style: { cursor: 'pointer' }, "data-testid": "user-database-search-cancel-button", onClick: function () {
                            setSearchTerm('');
                        } })),
                }, "data-testid": "user-segment-searching" }),
            React.createElement(UserSegmentsWrapper, null, userSegments.map(function (us) { return (React.createElement(UserSegmentCard, { key: us === null || us === void 0 ? void 0 : us.id, checked: selectedUserSegmentIds.has(us === null || us === void 0 ? void 0 : us.id), userSegment: us, onChangeUserSegmentIds: onChangeUserSegmentIds })); })))))));
};
export default injectIntl(UserSegmentFilters);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
