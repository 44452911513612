var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { magicalClickTypeToString } from '@src/lib/formAutomation';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import { Icon } from '@zeals-co-ltd/washi-components';
import React from 'react';
import { MagicalClickType } from 'zeals-protobuf/zeals/web/entities/form_automation_pb';
import { FormAutomationStepFieldSelector, } from '@src/components/atoms/FormAutomationStepFieldSelector';
import { NotRequiredField } from './FormAutomationStepItem';
import { useStepItemAutocompleteStyles } from './FormAutomationStepItem.styles';
export default function MagicalClickStep(_a) {
    var step = _a.step, fields = _a.fields, onChange = _a.onChange;
    var stepItemDropdownStyles = useStepItemAutocompleteStyles();
    var magicalClickAction = step.magicalClickAction;
    var newStep = function (selectKey, selectValue) {
        var _a;
        return __assign(__assign({}, step), { magicalClickAction: __assign(__assign({}, magicalClickAction), (_a = {}, _a[selectKey] = selectValue, _a)) });
    };
    var selectableMagicalClickTypes = [
        MagicalClickType.MAGICAL_CLICK_TYPE_HONDA,
        MagicalClickType.MAGICAL_CLICK_TYPE_YOYAKU,
    ];
    var items = selectableMagicalClickTypes.map(function (type) { return ({
        label: magicalClickTypeToString(type),
        value: type,
    }); });
    var selectedItem = items === null || items === void 0 ? void 0 : items.find(function (field) { return field.value === magicalClickAction.magicalClickType; });
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "field field-selector" },
            React.createElement(FormAutomationStepFieldSelector, { fieldId: magicalClickAction.fieldId, fields: fields, onChange: function (item) {
                    return onChange(newStep('fieldId', item.value));
                } })),
        React.createElement("div", { className: "css-selector not-required" },
            React.createElement(NotRequiredField, null)),
        React.createElement("div", { className: "type not-required" },
            React.createElement(NotRequiredField, null)),
        React.createElement("div", { className: "options magical-click-type-selection" },
            React.createElement(Select, { "data-testid": "FormAutomationStepItem-TypeSelectionDropdown", className: " " + stepItemDropdownStyles.root, value: selectedItem.value, onChange: function (e) {
                    onChange(newStep('magicalClickType', e.target.value));
                }, MenuProps: {
                    disablePortal: true,
                }, IconComponent: function (props) { return React.createElement(Icon, __assign({ name: "adicon_down_chev" }, props)); } }, items.map(function (item) { return (React.createElement(MenuItem, { key: item.value, value: item.value },
                React.createElement(ListItemText, { primary: item.label }))); })))));
}
