var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import { uniqueId, omit, isEqual, difference } from 'lodash';
import { TAB_0_COLOR, TRIGGER_DEFAULT_RICH_MENU } from '@src/lib/app-constants';
function tabCount(templateAreas) {
    return templateAreas.filter(function (_a) {
        var color = _a.color;
        return color > TAB_0_COLOR;
    }).length || 1;
}
function findTemplateId(lineRichMenu, templateGroups) {
    var templateGroup = templateGroups.find(function (_a) {
        var width = _a.width, height = _a.height;
        return Number(width) === lineRichMenu.size.width &&
            Number(height) === lineRichMenu.size.height;
    });
    if (!templateGroup)
        return [null, null];
    var lineRMareas = lineRichMenu.areas.map(function (_a) {
        var bounds = _a.bounds;
        return ({ bounds: bounds });
    });
    var tab0 = templateGroup.tab0, tab2 = templateGroup.tab2, tab3 = templateGroup.tab3;
    var templates = __assign(__assign(__assign({}, tab0), tab2), tab3);
    var templateId = Object.keys(templates).find(function (key) {
        var templateAreas = templates[key].map(function (area) {
            return ({ bounds: omit(area, ['color']) });
        });
        return isEqual(lineRMareas, templateAreas);
    });
    return [templateId, templates[templateId]];
}
function findTemplate(templateId, templateGroups) {
    var templateGroup = templateGroups.find(function (_a) {
        var tab0 = _a.tab0, tab2 = _a.tab2, tab3 = _a.tab3;
        return Object.keys(__assign(__assign(__assign({}, tab0), tab2), tab3)).includes("template" + templateId);
    });
    var tab0 = templateGroup.tab0, tab2 = templateGroup.tab2, tab3 = templateGroup.tab3;
    return __assign(__assign(__assign({}, tab0), tab2), tab3)["template" + templateId];
}
function convertToRichMenu(lineRichMenu, templateGroups) {
    var _a = __read(findTemplateId(lineRichMenu, templateGroups), 2), templateId = _a[0], templateAreas = _a[1];
    if (!templateId)
        return null;
    var richMenuId = Number(uniqueId());
    var richMenu = {
        id: richMenuId,
        chat_bar_text: lineRichMenu.chatBarText,
        label: lineRichMenu.name,
        line_rich_menu_id: lineRichMenu.richMenuId,
        image_url: process.env.DEFAULT_RICH_MENU_HOST + "richmenu/" + lineRichMenu.richMenuId + "/content",
        template: Number(templateId.replace('template', '')),
        rich_menu_areas: [],
        template_content: {
            areas: [],
            size: lineRichMenu.size,
        },
    };
    lineRichMenu.areas.forEach(function (_a, index) {
        var action = _a.action;
        var postbackAction = {};
        if (action.type === 'uri') {
            postbackAction.url = action.uri;
        }
        else if (action.type === 'richmenuswitch') {
            postbackAction.rich_menu_alias_id = action.richMenuAliasId;
            postbackAction.data = action.data;
        }
        else if (action.type === 'postback') {
            var postbackActionData = JSON.parse(action.data);
            postbackAction.effect = postbackActionData.effect;
            postbackAction.block_id = postbackActionData.scenario_id
                ? null
                : postbackActionData.next_block_id;
            postbackAction.scenario_first_block_id = postbackActionData.next_block_id;
            postbackAction.scenario_id = postbackActionData.scenario_id;
        }
        var richMenuArea = {
            id: Number(uniqueId()),
            rich_menu_id: richMenuId,
            rank: index,
            postback_action: postbackAction,
            bounds: templateAreas[index],
            isTabArea: templateAreas[index].color > TAB_0_COLOR,
            label: action.label || null,
            url: null,
        };
        var templateAreaItem = {
            bounds: templateAreas[index],
        };
        richMenu.template_content.areas.push(templateAreaItem);
        richMenu.rich_menu_areas.push(richMenuArea);
    });
    return richMenu;
}
function convertToRichMenus(lineRichMenus, templateGroups) {
    return Object.keys(lineRichMenus)
        .map(function (key) { return convertToRichMenu(lineRichMenus[key], templateGroups); })
        .filter(Boolean);
}
function findAliasIds(lineRichMenus, richMenuAliases, lineRichMenuIds, lineRichMenuIdsStored) {
    var resultLineRichMenuIds = lineRichMenuIdsStored;
    lineRichMenuIds.forEach(function (lrmId) {
        var lineRichMenu = lineRichMenus.find(function (_a) {
            var richMenuId = _a.richMenuId;
            return richMenuId === lrmId;
        });
        if (!lineRichMenu)
            return;
        var aliasIds = [];
        lineRichMenu.areas.forEach(function (_a) {
            var action = _a.action;
            if (action.type === 'richmenuswitch') {
                aliasIds.push(action.richMenuAliasId);
            }
        });
        var newLineRichMenuIds = aliasIds
            .map(function (aliasId) {
            var rmAlias = richMenuAliases.find(function (_a) {
                var richMenuAliasId = _a.richMenuAliasId;
                return richMenuAliasId === aliasId;
            });
            return rmAlias === null || rmAlias === void 0 ? void 0 : rmAlias.richMenuId;
        })
            .filter(Boolean);
        newLineRichMenuIds = difference(newLineRichMenuIds, lineRichMenuIdsStored);
        if (!newLineRichMenuIds.length)
            return;
        resultLineRichMenuIds.push.apply(resultLineRichMenuIds, __spreadArray([], __read(newLineRichMenuIds)));
        resultLineRichMenuIds = findAliasIds(lineRichMenus, richMenuAliases, newLineRichMenuIds, resultLineRichMenuIds);
    });
    return resultLineRichMenuIds;
}
function setupPostbackActionPayload(postbackAction) {
    return JSON.stringify({
        scenario_id: postbackAction.scenario_id,
        next_block_id: postbackAction.scenario_first_block_id || postbackAction.block_id,
        effect: postbackAction.effect,
        trigger: TRIGGER_DEFAULT_RICH_MENU,
    });
}
export function buildDefaultRichMenu(width, height, templateId, templateAreas) {
    var richMenus = Array(tabCount(templateAreas))
        .fill(null)
        .map(function () {
        var richMenuId = Number(uniqueId());
        var richMenu = {
            id: richMenuId,
            template: templateId,
            rich_menu_areas: [],
            template_content: {
                areas: [],
                size: {
                    width: width,
                    height: height,
                },
            },
        };
        templateAreas.forEach(function (templateArea, index) {
            var richMenuArea = {
                id: Number(uniqueId()),
                rich_menu_id: richMenuId,
                rank: index,
                label: null,
                isTabArea: templateArea.color > TAB_0_COLOR,
                postback_action: {},
            };
            richMenu.rich_menu_areas.push(richMenuArea);
            richMenu.template_content.areas.push({
                bounds: templateArea,
            });
        });
        return richMenu;
    });
    return {
        isActive: false,
        schedule: null,
        selected: false,
        richMenus: richMenus,
    };
}
export function convertToDefaultRichMenus(lineRichMenus, richMenuAliases, defaultRichMenuId, templateGroups, schedules, lineRichMenuIds) {
    var groupAliasIds = [];
    var lineRichMenuIdsStored = [];
    lineRichMenus
        .filter(function (_a) {
        var richMenuId = _a.richMenuId, selected = _a.selected;
        return !selected || lineRichMenuIds.includes(richMenuId);
    })
        .forEach(function (lineRichMenu) {
        if (lineRichMenuIdsStored.includes(lineRichMenu.richMenuId))
            return;
        var newLineRichMenuIds = findAliasIds(lineRichMenus, richMenuAliases, [lineRichMenu.richMenuId], [lineRichMenu.richMenuId]);
        groupAliasIds.push(newLineRichMenuIds);
        lineRichMenuIdsStored.push.apply(lineRichMenuIdsStored, __spreadArray([], __read(newLineRichMenuIds)));
    });
    return groupAliasIds
        .map(function (aliasIds) {
        var lRichMenus = lineRichMenus.filter(function (_a) {
            var richMenuId = _a.richMenuId;
            return aliasIds.includes(richMenuId);
        });
        var richMenus = convertToRichMenus(lRichMenus.slice(0, 3), templateGroups);
        if (!richMenus.length)
            return null;
        richMenus = richMenus.sort(function (a, b) {
            var _a, _b;
            var aRMAliasId = (_a = richMenuAliases.find(function (_a) {
                var richMenuId = _a.richMenuId;
                return richMenuId === a.line_rich_menu_id;
            })) === null || _a === void 0 ? void 0 : _a.richMenuAliasId;
            var bRMAliasId = (_b = richMenuAliases.find(function (_a) {
                var richMenuId = _a.richMenuId;
                return richMenuId === b.line_rich_menu_id;
            })) === null || _b === void 0 ? void 0 : _b.richMenuAliasId;
            return aRMAliasId < bRMAliasId ? -1 : 1;
        });
        var schedule = getDefaultSchedule(aliasIds, schedules) || null;
        return {
            isActive: aliasIds.includes(defaultRichMenuId),
            richMenus: richMenus,
            schedule: schedule,
            selected: lRichMenus[0].selected,
        };
    })
        .filter(Boolean);
}
export function getDefaultSchedule(aliasIds, schedules) {
    return schedules.find(function (schedule) {
        return aliasIds.includes(schedule.line_rich_menu_id);
    });
}
export function convertToLineRichMenus(defaultRichMenu, templateGroups, richMenuAliasId) {
    var _a = defaultRichMenu.richMenus[0], label = _a.label, chat_bar_text = _a.chat_bar_text, template = _a.template, template_content = _a.template_content;
    return defaultRichMenu.richMenus.map(function (richMenu) {
        var templateAreas = findTemplate(template, templateGroups);
        var tabAreas = richMenu.rich_menu_areas.filter(function (_a) {
            var isTabArea = _a.isTabArea;
            return isTabArea;
        });
        var areas = richMenu.rich_menu_areas.map(function (area, index) {
            var lArea = {
                bounds: templateAreas[index],
            };
            var tIndex = tabAreas.indexOf(area);
            if (tIndex >= 0) {
                lArea.action = {
                    type: 'richmenuswitch',
                    richMenuAliasId: richMenuAliasId + "-tab" + (tIndex + 1),
                    data: "richmenu-changed-to-tab" + (tIndex + 1),
                };
            }
            else {
                var postback_action = area.postback_action;
                if (postback_action === null || postback_action === void 0 ? void 0 : postback_action.url) {
                    lArea.action = {
                        type: 'uri',
                        uri: postback_action === null || postback_action === void 0 ? void 0 : postback_action.url,
                        label: area.label,
                        displayText: area.label,
                    };
                }
                else {
                    lArea.action = {
                        type: 'postback',
                        label: area.label,
                        displayText: area.label,
                        data: setupPostbackActionPayload(postback_action),
                    };
                }
            }
            return lArea;
        });
        return {
            name: label,
            chatBarText: chat_bar_text,
            selected: defaultRichMenu.selected,
            size: template_content.size,
            areas: areas,
        };
    });
}
export function fetchImageWithAuthorization(imageUrl, token) {
    return function (e) {
        var target = e.target;
        if (target.dataset.loaded === 'true') {
            return;
        }
        fetch(imageUrl, { headers: { Authorization: "Bearer " + token } })
            .then(function (res) { return res.blob(); })
            .then(function (data) {
            target.src = window.URL.createObjectURL(data);
            target.dataset.loaded = 'true';
        })
            .catch(function () {
            target.dataset.loaded = 'true';
        });
    };
}
export function getRichMenuId(defaultRichMenu, richMenuAliases) {
    var _a, _b, _c;
    var aliasId = (_b = (_a = defaultRichMenu === null || defaultRichMenu === void 0 ? void 0 : defaultRichMenu.richMenus[0].rich_menu_areas.find(function (area) { return area.isTabArea; })) === null || _a === void 0 ? void 0 : _a.postback_action) === null || _b === void 0 ? void 0 : _b.rich_menu_alias_id;
    if (!aliasId)
        return defaultRichMenu.richMenus[0].line_rich_menu_id;
    return (_c = richMenuAliases.find(function (alias) { return alias.richMenuAliasId === aliasId; })) === null || _c === void 0 ? void 0 : _c.richMenuId;
}
export function getNewDefaultRichMenu(defaultRichMenus, richMenuId) {
    return defaultRichMenus.find(function (drm) {
        return drm.richMenus.map(function (rm) { return rm.line_rich_menu_id; }).includes(richMenuId);
    });
}
