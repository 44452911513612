var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { MessageFilterType, Status, } from '../types/messageFilter';
import { makeFetchInit, handleAPIErrors } from '@src/lib/helpers';
import endpoints from '@src/endpoints';
import * as attributeAnswerApi from '@src/services/api/attributeAnswer';
// Message Section Actions
export var resetSelectedTags = function (initSelectedTagIds) {
    if (initSelectedTagIds === void 0) { initSelectedTagIds = []; }
    return ({
        type: MessageFilterType.RESET_SELECTED_TAGS,
        initSelectedTagIds: initSelectedTagIds,
    });
};
export var resetSelectedFilter = function () { return ({
    type: MessageFilterType.RESET_SELECTED_FILTER,
}); };
export var resetDoneStatus = function () { return ({
    type: MessageFilterType.RESET_DONE_STATUS,
}); };
export var setMessageFilterTags = function (tags) { return ({
    type: MessageFilterType.AD_SET_MESSAGE_FILTER_TAGS,
    tags: tags,
}); };
export var setDoneStatus = function (operation) { return ({
    type: MessageFilterType.SET_DONE_STATUS,
    operation: operation,
}); };
export var handleProcessing = function (boolValue) { return ({
    type: MessageFilterType.HANDLE_PROCESSING,
    boolValue: boolValue,
}); };
export var handleOpenFilterForm = function (boolValue) {
    return {
        type: MessageFilterType.HANDLE_OPEN_FILTER_FORM,
        boolValue: boolValue,
    };
};
export var searchingTags = function (searchTerm) {
    return {
        type: MessageFilterType.SEARCH_TAG_ACTION,
        searchTerm: searchTerm,
    };
};
export var selectTagGroupId = function (id) {
    return {
        type: MessageFilterType.SELECT_TAG_GROUP_ID,
        selectedAttributeQuestionGroupId: id,
    };
};
export var selectNewTag = function (newTagId) {
    return {
        type: MessageFilterType.SELECT_NEW_TAG,
        newTagId: newTagId,
    };
};
export var setMessageFilters = function (filters) {
    return {
        type: MessageFilterType.SET_MESSAGE_FILTERS,
        filters: filters,
    };
};
export var removeSelectedTag = function (selectedTagId) { return ({
    type: MessageFilterType.REMOVE_SELECTED_TAG,
    selectedTagId: selectedTagId,
}); };
export var selectMessageFilter = function (id) {
    return {
        type: MessageFilterType.SELECT_MESSAGE_FILTER,
        id: id,
    };
};
export var createNewFilterList = function (_a) {
    var filter = _a.filter;
    return {
        type: MessageFilterType.CREATE_NEW_FILTER_LIST,
        filter: filter,
    };
};
export var updateFilterList = function (filter) {
    return {
        type: MessageFilterType.UPDATE_FILTER_LIST,
        filter: filter,
    };
};
export var setHighlightFilterId = function (filterId) {
    return {
        type: MessageFilterType.SET_HIGHLIGHT_FILTER_ID,
        filterId: filterId,
    };
};
// THUNKS
export var fetchMessageFilterList = function (chatbotId) {
    return function (dispatch) {
        var url = endpoints.messageFilter.collection(chatbotId);
        return fetch(url, makeFetchInit())
            .then(function (res) { return res.json(); })
            .then(function (_a) {
            var filters = _a.filters;
            dispatch(setMessageFilters(filters));
        })
            .catch(handleAPIErrors);
    };
};
export var fetchMessageFilterTags = function (chatbotId) {
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        var attributeAnswers, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    dispatch(handleProcessing(true));
                    return [4 /*yield*/, attributeAnswerApi.getFiltertags(chatbotId)];
                case 1:
                    attributeAnswers = _a.sent();
                    dispatch(setMessageFilterTags(attributeAnswers));
                    dispatch(handleProcessing(false));
                    return [3 /*break*/, 3];
                case 2:
                    e_1 = _a.sent();
                    handleAPIErrors(e_1.message);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
};
export var createNewMessageFilter = function (chatbotId, messageFilterName, attributeAnswersIds) {
    return function (dispatch) {
        dispatch(handleProcessing(true));
        var url = endpoints.messageFilter.collection(chatbotId);
        var body = {
            chatbot_id: chatbotId,
            name: messageFilterName,
            attribute_answer_ids: attributeAnswersIds,
        };
        return fetch(url, makeFetchInit('POST', body))
            .then(function (res) { return res.json(); })
            .then(function (res) {
            if (res.status) {
                // If failed, will alert message
                throw new Error(res.message);
            }
            dispatch(createNewFilterList(res));
            dispatch(setDoneStatus(Status.Create));
            dispatch(handleProcessing(false));
        })
            .catch(function (e) {
            handleAPIErrors(e);
            dispatch(resetSelectedFilter());
            dispatch(handleProcessing(false));
        });
    };
};
export var updateNewMessageFilter = function (chatbotId, messageFilterName, messageFilterId, attributeAnswersIds) {
    return function (dispatch) {
        dispatch(handleProcessing(true));
        var url = endpoints.messageFilter.member(chatbotId, messageFilterId);
        var body = {
            chatbot_id: chatbotId,
            message_filter_id: messageFilterId,
            name: messageFilterName,
            attribute_answer_ids: attributeAnswersIds,
        };
        return fetch(url, makeFetchInit('PUT', body))
            .then(function (res) { return res.json(); })
            .then(function (res) {
            if (res.status) {
                // If failed, will alert message
                throw new Error(res.message);
            }
            dispatch(updateFilterList(res.filter));
            dispatch(resetSelectedFilter());
            dispatch(setDoneStatus(Status.Update));
            dispatch(handleProcessing(false));
        })
            .catch(function (e) {
            handleAPIErrors(e);
            dispatch(resetSelectedFilter());
            dispatch(handleProcessing(false));
        });
    };
};
export var deleteMessageFilter = function (chatbotId, messageFilterId) {
    return function (dispatch) {
        var url = endpoints.messageFilter.member(chatbotId, messageFilterId);
        var body = {
            chatbot_id: chatbotId,
            message_filter_id: messageFilterId,
        };
        return fetch(url, makeFetchInit('DELETE', body))
            .then(function (res) { return res.json(); })
            .then(function (res) {
            if (res.status !== 200) {
                throw new Error(res.message);
            }
            dispatch(fetchMessageFilterList(chatbotId));
        })
            .then(function () {
            dispatch(resetSelectedTags());
            dispatch(resetSelectedFilter());
            dispatch(setDoneStatus(Status.Delete));
        })
            .catch(handleAPIErrors);
    };
};
