export function getUrlType(url) {
    if (!url)
        return 'url';
    var isCall = url.includes('tel:');
    var isEmail = url.includes('mailto:');
    if (isCall)
        return 'tel';
    if (isEmail)
        return 'mailto';
    return 'url';
}
export function getIcon(url, hasBlock) {
    var isCall = url && url.includes('tel:');
    var isEmail = url && url.includes('mailto:');
    if (hasBlock)
        return 'adicon_nextblock';
    if (isCall)
        return 'adicon_phone';
    if (isEmail)
        return 'adicon_mail';
    if (url)
        return 'adicon_big_link';
    return '';
}
export function createInitialUrlValue(compareKey, postBackAction) {
    var _a;
    if (!postBackAction || !(postBackAction && postBackAction.url))
        return '';
    var url = (postBackAction || {}).url;
    var isCall = url.includes('tel:');
    var isEmail = url.includes('mailto:');
    if (compareKey === 'url' && !(isCall || isEmail))
        return postBackAction.url;
    var key = url.slice(0, url.indexOf(':'));
    var value = url.slice(url.indexOf(':') + 1);
    var urlObj = (_a = {}, _a[key] = value, _a);
    return urlObj[compareKey];
}
