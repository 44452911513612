var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { ModalPortal, Input, InputWrapper } from '@src/components/atoms';
import { Modal } from '@src/components/molecules';
import { injectIntl } from 'react-intl';
var defaultProps = {
    targetName: 'test',
    not: 'false',
    handleChangeFilterNot: function (e) { return console.log(e); },
    handleChangeTargetName: function (e) { return console.log(e); },
};
var TargetUpdateModal = function (props) {
    var targetName = props.targetName, not = props.not, handleChangeFilterNot = props.handleChangeFilterNot, handleChangeTargetName = props.handleChangeTargetName, intl = props.intl;
    return (React.createElement(ModalPortal, null,
        React.createElement(Modal, __assign({ "data-testid": "talk-room-target-update-modal", type: "SELECT", text: intl.formatMessage({ id: "pJ2ol+", defaultMessage: "Campaign Settings" }) }, props),
            React.createElement("div", null,
                React.createElement("div", { className: "message-wrapper primary-label" }, intl.formatMessage({ id: "cny9xC", defaultMessage: "Modification Name" })),
                React.createElement(Input, { type: "text", value: targetName, onChange: function (e) { return handleChangeTargetName(e); }, dataTest: "TargetUpdateModal_Input" })),
            React.createElement("div", { style: { marginTop: '30px' } },
                React.createElement("div", { className: "message-wrapper primary-label" }, intl.formatMessage({ id: "f5LonG", defaultMessage: "Tag Modification" })),
                React.createElement(InputWrapper, { margin: "12px 0 0 0" },
                    React.createElement("select", { value: not, onChange: function (e) { return handleChangeFilterNot(e); }, "data-testid": "TargetUpdateModal_select" },
                        React.createElement("option", { value: "false" }, intl.formatMessage({ id: "em8u+F", defaultMessage: "Deliver to CV Users" })),
                        React.createElement("option", { value: "true" }, intl.formatMessage({ id: "zegf1I", defaultMessage: "Deliver to non-CV Users" })),
                        React.createElement("option", { value: "" }, intl.formatMessage({ id: "5bZ6oI", defaultMessage: "Deliver to All Users" }))))))));
};
export default injectIntl(TargetUpdateModal);
