var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { Component } from 'react';
import styled from 'styled-components';
import { Composition } from 'atomic-layout';
import { LoadingScreen } from '@src/components/atoms';
import { EmptySplash } from '@src/components/molecules';
import Plugin from '@src/components/organisms/fanp/plugins/Plugin';
import { makeFetchInit } from '@src/lib/helpers/ajaxFuncs';
import { colors, shadows } from '@src/colors';
import { hoverMenuBg } from '@src/lib/mixins';
import endpoints from '@src/endpoints';
import newPluginImage from '@src/assets/images/installation_flow.png';
import HOCWithRouter from '@src/components/hoc/HOCWithRouter';
import * as pathLinks from '@src/routes/Fanp/pathLinks';
var StyledPluginList = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  border-radius: 8px;\n  box-shadow: ", ";\n  overflow: auto;\n"], ["\n  border-radius: 8px;\n  box-shadow: ", ";\n  overflow: auto;\n"])), shadows.small.mid);
var Header = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  background: ", ";\n  p {\n    font-weight: bold;\n    color: ", ";\n    margin: 0;\n  }\n"], ["\n  background: ", ";\n  p {\n    font-weight: bold;\n    color: ", ";\n    margin: 0;\n  }\n"])), colors.main_color, colors.background.main_menu);
var CreateNewPluginButton = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  text-align: center;\n  font-size: 24px;\n  border-bottom: 1px solid ", ";\n  background: ", ";\n  padding: 10px 0;\n  ", ";\n"], ["\n  text-align: center;\n  font-size: 24px;\n  border-bottom: 1px solid ", ";\n  background: ", ";\n  padding: 10px 0;\n  ", ";\n"])), colors.border.table, colors.background.main_menu, hoverMenuBg);
var PluginList = /** @class */ (function (_super) {
    __extends(PluginList, _super);
    function PluginList() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            pluginList: [],
            isLoading: true,
        };
        _this.deletePlugin = function (event, pluginId) {
            event.stopPropagation();
            if (confirm('本当にプラグインを削除しますか？')) {
                var url = endpoints.plugins.member({ pluginId: pluginId });
                fetch(url, makeFetchInit('DELETE'))
                    .then(function (response) {
                    if (!response.ok)
                        throw new Error();
                })
                    .then(function () {
                    var afterDeletePlugins = _this.state.pluginList.filter(function (plugin) { return plugin.id !== pluginId; });
                    _this.setState({ pluginList: afterDeletePlugins });
                })
                    .catch(function () { return alert('プラグインを削除できませんでした'); });
            }
        };
        return _this;
    }
    PluginList.prototype.componentDidMount = function () {
        var _this = this;
        var url = endpoints.plugins.collection();
        fetch(url, makeFetchInit('GET'))
            .then(function (response) {
            if (!response.ok)
                throw new Error();
            return response.json();
        })
            .then(function (_a) {
            var plugins = _a.plugins;
            _this.setState({
                pluginList: plugins,
                isLoading: false,
            });
        })
            .catch(function () { return _this.setState({ isLoading: false }); });
    };
    PluginList.prototype.render = function () {
        var _this = this;
        var accountId = this.props.params.accountId;
        var _a = this.state, pluginList = _a.pluginList, isLoading = _a.isLoading;
        var areas = "\n      header\n      plusButton\n      list\n    ";
        return (React.createElement(React.Fragment, null,
            isLoading && React.createElement(LoadingScreen, null),
            !isLoading && !pluginList.length ? (React.createElement(EmptySplash, { image: newPluginImage, text: "\u30D7\u30E9\u30B0\u30A4\u30F3\u3092\u4F5C\u6210\u3057\u3088\u3046", buttonText: "\u65B0\u898F\u4F5C\u6210", onClick: function () {
                    _this.props.browserHistory.push(pathLinks.dashboardAccounts_accountId_PluginsNew({
                        accountId: parseInt(accountId.toString()),
                    }));
                } })) : (React.createElement(Composition, { as: StyledPluginList, areas: areas, width: '60%', height: '80%', margin: "50px auto", templateRows: "55px 55px auto;" }, function (Areas) { return (React.createElement(React.Fragment, null,
                React.createElement(Areas.Header, null,
                    React.createElement(Composition, { as: Header, templateCols: "5% 40% 40% 15%", alignItems: "center", justifyItems: "center", padding: '1rem', height: "100%" },
                        React.createElement("p", null, "ID"),
                        React.createElement("p", null, "\u30D7\u30E9\u30B0\u30A4\u30F3\u540D"),
                        React.createElement("p", null, "uid"))),
                React.createElement(Areas.PlusButton, null,
                    React.createElement(CreateNewPluginButton, { onClick: function () {
                            _this.props.browserHistory.push(pathLinks.dashboardAccounts_accountId_PluginsNew({
                                accountId: parseInt(accountId.toString()),
                            }));
                        } }, "+")),
                React.createElement(Areas.List, null, pluginList.map(function (plugin) { return (React.createElement(Plugin, __assign({ key: "plugin-" + plugin.id, onClick: function () {
                        _this.props.browserHistory.push(pathLinks.dashboardAccounts_accountId_Plugins_pluginId_({
                            accountId: parseInt(accountId.toString()),
                            pluginId: plugin.id,
                        }));
                    }, onDelete: function (e) { return _this.deletePlugin(e, plugin.id); } }, plugin))); })))); }))));
    };
    return PluginList;
}(Component));
export default HOCWithRouter(PluginList);
var templateObject_1, templateObject_2, templateObject_3;
