var flatten = require('lodash/flatten');
var jpReg = /[\u3000-\u303f\u3040-\u309f\u30a0-\u30ff\uff00-\uff9f\u4e00-\u9faf\u3400-\u4dbf]/g;
export function isJp(str) {
    return !!str.match(jpReg);
}
export function groupByLanguage(text) {
    var groupsWithCharArrays = Array.from(text).reduce(function (groups, char) {
        if (!groups.length) {
            groups.push([]);
            groups[groups.length - 1].push(char);
            return groups;
        }
        var charIsJp = isJp(char);
        var curGroup = groups[groups.length - 1];
        var curGroupIsJp = isJp(curGroup[0]);
        if (charIsJp === curGroupIsJp) {
            groups[groups.length - 1].push(char);
        }
        else {
            groups.push([]);
            groups[groups.length - 1].push(char);
        }
        return groups;
    }, []);
    return groupsWithCharArrays.map(function (charGroup) { return charGroup.join(''); });
}
export function joinByLanguage(textArr) {
    return textArr.reduce(function (grouped, str) {
        if (!grouped.length) {
            grouped.push(str);
            return grouped;
        }
        var groupIsJp = isJp(str);
        if (!groupIsJp) {
            grouped.push(str);
            return grouped;
        }
        var lastElementIsJp = isJp(grouped[grouped.length - 1]);
        if (lastElementIsJp) {
            grouped[grouped.length - 1] = grouped[grouped.length - 1] + str;
        }
        else {
            grouped.push(str);
        }
        return flatten(grouped);
    }, []);
}
