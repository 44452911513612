var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import { updateUserTags, removeUserTags, addUserTags, } from '@src/redux/fanp/lib/helpers';
import { UserActionTypes } from '../types/user';
export var initialState = {
    lastPage: 1,
    users: [],
    checkedUsers: [],
    totalUsers: null,
    user: {
        id: null,
        cv_date: null,
        first_name: null,
        gender: null,
        inflow_date: null,
        last_name: null,
        profile_photo_url: null,
        checked: false,
        tags: [],
        scenarios: [],
        quick_replies: [],
    },
    hasUsers: false,
    checkAllUsers: false,
    canAccessAdmin: false,
};
export default (function (state, action) {
    var _a;
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case UserActionTypes.SET_USERS:
            return __assign(__assign({}, state), { totalUsers: action.users.totalUsers, lastPage: action.users.last_page, users: action.users.end_users.map(function (endUser) { return (__assign(__assign({}, endUser), { checked: state.checkAllUsers })); }) });
        case UserActionTypes.SET_USER:
            return __assign(__assign({}, state), { user: action.user });
        case UserActionTypes.SET_USER_SCENARIOS:
            return __assign(__assign({}, state), { user: __assign(__assign({}, state.user), { scenarios: action.scenarios }) });
        case UserActionTypes.SET_USER_QUICK_REPLIES:
            return __assign(__assign({}, state), { user: __assign(__assign({}, state.user), { quick_replies: action.quickReplies }) });
        case UserActionTypes.SET_HAS_USERS:
            return __assign(__assign({}, state), { hasUsers: action.hasUsers });
        case UserActionTypes.SET_CHECKED_ALL_USERS:
            return __assign(__assign({}, state), { checkedUsers: state.checkAllUsers ? action.checkedUsers : [] });
        case UserActionTypes.UPDATE_PROP_OF_USER:
            return __assign(__assign({}, state), { users: __spreadArray(__spreadArray(__spreadArray([], __read(state.users.slice(0, action.userIterator))), [
                    __assign(__assign({}, state.users[action.userIterator]), (_a = {}, _a[action.key] = action.value, _a))
                ]), __read(state.users.slice(action.userIterator + 1))) });
        case UserActionTypes.SET_ADMIN_ACCESS:
            return __assign(__assign({}, state), { canAccessAdmin: action.canAccessAdmin });
        case UserActionTypes.ADD_TAG_TO_MULTIPLE_USERS: {
            return __assign(__assign({}, state), { users: state.users.map(function (user) {
                    // goes through endUserIds array to see if user needs to be updated
                    var isUserFound = action.endUserIds.includes(user.id);
                    if (isUserFound) {
                        return __assign(__assign({}, user), { tags: __spreadArray(__spreadArray([], __read(user.tags)), [action.tag]) });
                    }
                    return user;
                }), checkedUsers: [], checkAllUsers: false });
        }
        case UserActionTypes.BLOCK_MULTIPLE_USERS: {
            return __assign(__assign({}, state), { users: state.users.map(function (user) {
                    // goes through endUserIds array to see if user needs to be updated
                    var isUserFound = action.endUserIds.includes(user.id);
                    if (isUserFound) {
                        return __assign(__assign({}, user), { active: false });
                    }
                    return user;
                }), checkedUsers: [], checkAllUsers: false });
        }
        case UserActionTypes.REMOVE_PROP_OF_USER:
            return __assign(__assign({}, state), { users: state.users.map(function (user) {
                    if (user[action.key]) {
                        var updatedUser = __assign({}, user);
                        delete updatedUser[action.key];
                        return updatedUser;
                    }
                    return user;
                }) });
        case UserActionTypes.HANDLE_UPDATE_USER:
            return __assign(__assign({}, state), { users: __spreadArray(__spreadArray(__spreadArray([], __read(state.users.slice(0, action.userIterator))), [
                    __assign(__assign({}, state.users[action.userIterator]), action.userObject)
                ]), __read(state.users.slice(action.userIterator + 1))) });
        case UserActionTypes.ADD_AUTO_TAG:
            return addUserTags(action, state, 'tags');
        case UserActionTypes.ADD_USER_TAG:
            return addUserTags(action, state, 'userTags');
        case UserActionTypes.UPDATE_AUTO_TAG:
            return updateUserTags(action, state, 'tags', 'tag');
        case UserActionTypes.UPDATE_USER_TAG:
            return updateUserTags(action, state, 'userTags', 'userTag');
        case UserActionTypes.REMOVE_AUTO_TAG:
            return removeUserTags(action, state, 'tags', 'autoTagId');
        case UserActionTypes.TOGGLE_CHECKED_USER: {
            var checkedUser = state.checkedUsers.find(function (user) { return user.id === state.users[action.userIterator].id; });
            var checkedUsers = checkedUser
                ? state.checkedUsers.filter(function (user) { return user.id !== state.users[action.userIterator].id; })
                : __spreadArray(__spreadArray([], __read(state.checkedUsers)), [state.users[action.userIterator]]);
            return __assign(__assign({}, state), { checkedUsers: checkedUsers });
        }
        case UserActionTypes.TOGGLE_CHECK_ALL_USERS:
            return __assign(__assign({}, state), { checkAllUsers: !state.checkAllUsers, checkedUsers: !state.checkAllUsers ? state.users : [] });
        default:
            return state;
    }
});
