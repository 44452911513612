// source: entities/common.proto

/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

/* eslint-disable */
// @ts-nocheck
var jspb = require('google-protobuf');

var goog = jspb;
var global = Function('return this')();
goog.exportSymbol('proto.e.Date', null, global);
goog.exportSymbol('proto.e.DayOfWeek', null, global);
goog.exportSymbol('proto.e.Pagination', null, global);
goog.exportSymbol('proto.e.Time', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */

proto.e.Time = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.e.Time, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.e.Time.displayName = 'proto.e.Time';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.e.Date = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.e.Date, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.e.Date.displayName = 'proto.e.Date';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.e.Pagination = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.e.Pagination, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.e.Pagination.displayName = 'proto.e.Pagination';
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.e.Time.prototype.toObject = function (opt_includeInstance) {
    return proto.e.Time.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.e.Time} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.e.Time.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      hours: jspb.Message.getFieldWithDefault(msg, 1, 0),
      minutes: jspb.Message.getFieldWithDefault(msg, 2, 0),
      seconds: jspb.Message.getFieldWithDefault(msg, 3, 0),
      nanos: jspb.Message.getFieldWithDefault(msg, 4, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.e.Time}
 */


proto.e.Time.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.e.Time();
  return proto.e.Time.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.e.Time} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.e.Time}
 */


proto.e.Time.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value =
        /** @type {number} */
        reader.readInt32();
        msg.setHours(value);
        break;

      case 2:
        var value =
        /** @type {number} */
        reader.readInt32();
        msg.setMinutes(value);
        break;

      case 3:
        var value =
        /** @type {number} */
        reader.readInt32();
        msg.setSeconds(value);
        break;

      case 4:
        var value =
        /** @type {number} */
        reader.readInt32();
        msg.setNanos(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.e.Time.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.e.Time.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.e.Time} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.e.Time.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getHours();

  if (f !== 0) {
    writer.writeInt32(1, f);
  }

  f = message.getMinutes();

  if (f !== 0) {
    writer.writeInt32(2, f);
  }

  f = message.getSeconds();

  if (f !== 0) {
    writer.writeInt32(3, f);
  }

  f = message.getNanos();

  if (f !== 0) {
    writer.writeInt32(4, f);
  }
};
/**
 * optional int32 hours = 1;
 * @return {number}
 */


proto.e.Time.prototype.getHours = function () {
  return (
    /** @type {number} */
    jspb.Message.getFieldWithDefault(this, 1, 0)
  );
};
/**
 * @param {number} value
 * @return {!proto.e.Time} returns this
 */


proto.e.Time.prototype.setHours = function (value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};
/**
 * optional int32 minutes = 2;
 * @return {number}
 */


proto.e.Time.prototype.getMinutes = function () {
  return (
    /** @type {number} */
    jspb.Message.getFieldWithDefault(this, 2, 0)
  );
};
/**
 * @param {number} value
 * @return {!proto.e.Time} returns this
 */


proto.e.Time.prototype.setMinutes = function (value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};
/**
 * optional int32 seconds = 3;
 * @return {number}
 */


proto.e.Time.prototype.getSeconds = function () {
  return (
    /** @type {number} */
    jspb.Message.getFieldWithDefault(this, 3, 0)
  );
};
/**
 * @param {number} value
 * @return {!proto.e.Time} returns this
 */


proto.e.Time.prototype.setSeconds = function (value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};
/**
 * optional int32 nanos = 4;
 * @return {number}
 */


proto.e.Time.prototype.getNanos = function () {
  return (
    /** @type {number} */
    jspb.Message.getFieldWithDefault(this, 4, 0)
  );
};
/**
 * @param {number} value
 * @return {!proto.e.Time} returns this
 */


proto.e.Time.prototype.setNanos = function (value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.e.Date.prototype.toObject = function (opt_includeInstance) {
    return proto.e.Date.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.e.Date} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.e.Date.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      year: jspb.Message.getFieldWithDefault(msg, 1, 0),
      month: jspb.Message.getFieldWithDefault(msg, 2, 0),
      day: jspb.Message.getFieldWithDefault(msg, 3, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.e.Date}
 */


proto.e.Date.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.e.Date();
  return proto.e.Date.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.e.Date} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.e.Date}
 */


proto.e.Date.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value =
        /** @type {number} */
        reader.readInt32();
        msg.setYear(value);
        break;

      case 2:
        var value =
        /** @type {number} */
        reader.readInt32();
        msg.setMonth(value);
        break;

      case 3:
        var value =
        /** @type {number} */
        reader.readInt32();
        msg.setDay(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.e.Date.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.e.Date.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.e.Date} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.e.Date.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getYear();

  if (f !== 0) {
    writer.writeInt32(1, f);
  }

  f = message.getMonth();

  if (f !== 0) {
    writer.writeInt32(2, f);
  }

  f = message.getDay();

  if (f !== 0) {
    writer.writeInt32(3, f);
  }
};
/**
 * optional int32 year = 1;
 * @return {number}
 */


proto.e.Date.prototype.getYear = function () {
  return (
    /** @type {number} */
    jspb.Message.getFieldWithDefault(this, 1, 0)
  );
};
/**
 * @param {number} value
 * @return {!proto.e.Date} returns this
 */


proto.e.Date.prototype.setYear = function (value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};
/**
 * optional int32 month = 2;
 * @return {number}
 */


proto.e.Date.prototype.getMonth = function () {
  return (
    /** @type {number} */
    jspb.Message.getFieldWithDefault(this, 2, 0)
  );
};
/**
 * @param {number} value
 * @return {!proto.e.Date} returns this
 */


proto.e.Date.prototype.setMonth = function (value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};
/**
 * optional int32 day = 3;
 * @return {number}
 */


proto.e.Date.prototype.getDay = function () {
  return (
    /** @type {number} */
    jspb.Message.getFieldWithDefault(this, 3, 0)
  );
};
/**
 * @param {number} value
 * @return {!proto.e.Date} returns this
 */


proto.e.Date.prototype.setDay = function (value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.e.Pagination.prototype.toObject = function (opt_includeInstance) {
    return proto.e.Pagination.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.e.Pagination} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.e.Pagination.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      limit: jspb.Message.getFieldWithDefault(msg, 1, 0),
      offset: jspb.Message.getFieldWithDefault(msg, 2, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.e.Pagination}
 */


proto.e.Pagination.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.e.Pagination();
  return proto.e.Pagination.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.e.Pagination} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.e.Pagination}
 */


proto.e.Pagination.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value =
        /** @type {number} */
        reader.readInt32();
        msg.setLimit(value);
        break;

      case 2:
        var value =
        /** @type {number} */
        reader.readInt32();
        msg.setOffset(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.e.Pagination.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.e.Pagination.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.e.Pagination} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.e.Pagination.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getLimit();

  if (f !== 0) {
    writer.writeInt32(1, f);
  }

  f = message.getOffset();

  if (f !== 0) {
    writer.writeInt32(2, f);
  }
};
/**
 * optional int32 limit = 1;
 * @return {number}
 */


proto.e.Pagination.prototype.getLimit = function () {
  return (
    /** @type {number} */
    jspb.Message.getFieldWithDefault(this, 1, 0)
  );
};
/**
 * @param {number} value
 * @return {!proto.e.Pagination} returns this
 */


proto.e.Pagination.prototype.setLimit = function (value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};
/**
 * optional int32 offset = 2;
 * @return {number}
 */


proto.e.Pagination.prototype.getOffset = function () {
  return (
    /** @type {number} */
    jspb.Message.getFieldWithDefault(this, 2, 0)
  );
};
/**
 * @param {number} value
 * @return {!proto.e.Pagination} returns this
 */


proto.e.Pagination.prototype.setOffset = function (value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};
/**
 * @enum {number}
 */


proto.e.DayOfWeek = {
  DAY_OF_WEEK_UNSPECIFIED: 0,
  MONDAY: 1,
  TUESDAY: 2,
  WEDNESDAY: 3,
  THURSDAY: 4,
  FRIDAY: 5,
  SATURDAY: 6,
  SUNDAY: 7
};
goog.object.extend(exports, proto.e);