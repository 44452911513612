var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import { colors } from '@src/colors';
import { centeredFlex } from '@src/lib/mixins';
import { mediaTypes, mediaPlaceholderIcons } from '@src/lib/app-constants';
import UploadLabel from './UploadLabel';
var DefaultThumb = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  height: 100%;\n  color: ", ";\n  padding: 0 25px;\n"], ["\n  ", ";\n  height: 100%;\n  color: ", ";\n  padding: 0 25px;\n"])), centeredFlex, colors.placeholder);
// Default component for the File upload
var DefaultThumbnail = function (_a) {
    var intl = _a.intl, _b = _a.text, text = _b === void 0 ? intl.formatMessage({ id: "XKyo5X", defaultMessage: "File Upload" }) : _b, _c = _a.mediaType, mediaType = _c === void 0 ? mediaTypes.ADD : _c, _d = _a.className, className = _d === void 0 ? 'default-thumb' : _d;
    return (React.createElement(DefaultThumb, { className: className },
        React.createElement(UploadLabel, { adicon: mediaPlaceholderIcons[mediaType], text: text })));
};
export { DefaultThumbnail as default };
var templateObject_1;
