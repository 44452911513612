import moment from 'moment';
/**
 * Return moment within 2 case of minutes below:
 * return minutes =  currentMin + 3 if remainder of currentMin/3 === 0
 * Overwise return minutes = nearestMultipleThreeMin - currentMin + 3
 */
export default (function () {
    var now = moment();
    var currentMin = now.minute();
    var remainder = currentMin % 3;
    var nearestMultipleThreeMin = !remainder
        ? currentMin
        : Math.ceil(currentMin / 3) * 3;
    var minutesForAdding = nearestMultipleThreeMin - currentMin + 3;
    if (minutesForAdding === 60) {
        return now.add(1, 'hours').minutes(0).seconds(0).milliseconds(0);
    }
    return now
        .add(nearestMultipleThreeMin - currentMin + 3, 'minutes')
        .seconds(0)
        .milliseconds(0);
});
