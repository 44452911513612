import React from 'react';
import { injectIntl } from 'react-intl';
import ModalPortal from '@src/components/atoms/ModalPortal';
import ModalWrapper from '@src/components/atoms/ModalWrapper';
import { Modal } from '@src/components/molecules';
import modalTypes from '@src/lib/modalTypes';
import { Formik } from 'formik';
import * as Yup from 'yup';
var FormAutomationTimeActionModal = function (_a) {
    var initialWaitS = _a.initialWaitS, intl = _a.intl, onCancel = _a.onCancel, onUpdateWaitTime = _a.onUpdateWaitTime;
    var initialFormikState = {
        waitS: (initialWaitS === null || initialWaitS === void 0 ? void 0 : initialWaitS.toString()) || '0',
    };
    var schema = Yup.object({
        // Disable ESLint to accept yup error message customization, see https://github.com/jquense/yup#error-message-customization
        // eslint-disable-next-line
        waitS: Yup.string().test('waitS', '${path} is not valid', function (value) {
            return Boolean(value);
        }),
    });
    return (React.createElement(Formik, { initialValues: initialFormikState, validationSchema: schema, onSubmit: function () { return undefined; } }, function (_a) {
        var values = _a.values, errors = _a.errors, isValid = _a.isValid, setFieldValue = _a.setFieldValue;
        return (React.createElement(ModalPortal, null,
            React.createElement(ModalWrapper, null,
                React.createElement(Modal, { className: "form-automation-time-modal", type: modalTypes.EDIT, onCancel: onCancel, onConfirm: function () { return onUpdateWaitTime(parseInt(values.waitS)); }, hasError: !isValid },
                    React.createElement("h3", { className: "title" }, intl.formatMessage({ id: "zVLkN4", defaultMessage: "Time Wait Action" })),
                    React.createElement("div", { className: "input-container" },
                        React.createElement("span", { className: "subtitle" }, intl.formatMessage({ id: "pBEcg/", defaultMessage: "Enter the time for the \"Wait\" action" })),
                        React.createElement("div", { className: "time-input-wrapper" },
                            React.createElement("input", { "aria-label": "time-input", className: "time-input", type: "number", value: values.waitS, placeholder: intl.formatMessage({ id: "BhBHf8", defaultMessage: "Example: 3" }), min: "0", onChange: function (e) { return setFieldValue('waitS', e.target.value); } }),
                            React.createElement("p", null, intl.formatMessage({ id: "N7EdjU", defaultMessage: "Seconds" })))),
                    errors && React.createElement("span", { className: "error" }, errors.waitS)))));
    }));
};
export default injectIntl(FormAutomationTimeActionModal);
