import { capitalize } from 'lodash';
import { ActionType, } from '@src/redux/fanp/types/account';
import { getLanguageFromStorage } from '@src/i18n/localeSettings';
export var initialState = {
    id: null,
    name: '',
    isNameValid: true,
    isFetching: false,
    corporate_user: false,
    payment_card_status: '',
    pricing: 'free',
    language: getLanguageFromStorage(),
    current_user: {
        id: null,
        email: '',
        facebook_name: '',
        email_name: '',
        accounts: [],
        profile_picture_url: '',
    },
};
export default (function (state, action) {
    var _a, _b;
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case ActionType.SET_ACCOUNT:
            return Object.assign({}, state, action.data, {
                current_user: Object.assign({}, action.data.current_user, {
                    isEmailValid: true,
                    isNameValid: true,
                }),
            });
        case ActionType.SET_UPDATED_ACCOUNT:
            var data_1 = action.data;
            var tempState = Object.assign({}, state);
            tempState.current_user.accounts.forEach(function (account) {
                if (account.id === data_1.id) {
                    account = data_1;
                }
            });
            tempState.corporate_user = data_1.corporate_user;
            tempState.current_user.isEmailValid = true;
            tempState.current_user.isNameValid = true;
            return Object.assign({}, state, tempState);
        case ActionType.SET_CURRENT_USER:
            return Object.assign({}, state, {
                current_user: Object.assign({}, action.currentUser, {
                    isEmailValid: true,
                    isNameValid: true,
                }),
            });
        case ActionType.SET_FETCHING_ACCOUNT:
            return Object.assign({}, state, { isFetching: action.isFetching });
        case ActionType.UPDATE_ACCOUNT_PROPERTY: {
            var validationKey = "is" + capitalize(action.key) + "Valid";
            return Object.assign({}, state, (_a = {},
                _a[action.key] = action.value,
                _a[validationKey] = action.validation,
                _a));
        }
        case ActionType.UPDATE_CLIENT_PROPERTY: {
            var validationKey = "is" + capitalize(action.key) + "Valid";
            return Object.assign({}, state, {
                current_user: Object.assign({}, state.current_user, (_b = {},
                    _b[action.key] = action.value,
                    _b[validationKey] = action.validation,
                    _b)),
            });
        }
        case ActionType.SET_LANGUAGE:
            return Object.assign({}, state, { language: action.language });
        default:
            return state;
    }
});
