var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { css } from 'styled-components';
import { colors, shadows } from '@src/colors';
import { transitions } from '@src/lib/animations';
import { blockTypes } from '@src/lib/app-constants';
export var centeredFlex = css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n"], ["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n"])));
export var columnFlex = css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n"], ["\n  display: flex;\n  flex-direction: column;\n"])));
export var rowFlex = css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n"], ["\n  display: flex;\n  flex-direction: row;\n"])));
// Color & text style
export var instagramBorderGradient = css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  z-index: 0;\n  border: none;\n  position: relative;\n  // usefull if there is no content for height\n  &:after {\n    content: '';\n    display: inline-block;\n    padding-top: 100%;\n  }\n  &:before {\n    content: '';\n    position: absolute;\n    z-index: -1;\n    top: 0;\n    left: 0;\n    right: 0;\n    bottom: 0;\n    background: ", ";\n    -webkit-mask: linear-gradient(#fff 0 0) content-box,\n      linear-gradient(#fff 0 0);\n    -webkit-mask-composite: destination-out;\n    mask-composite: exclude;\n  }\n"], ["\n  z-index: 0;\n  border: none;\n  position: relative;\n  // usefull if there is no content for height\n  &:after {\n    content: '';\n    display: inline-block;\n    padding-top: 100%;\n  }\n  &:before {\n    content: '';\n    position: absolute;\n    z-index: -1;\n    top: 0;\n    left: 0;\n    right: 0;\n    bottom: 0;\n    background: ", ";\n    -webkit-mask: linear-gradient(#fff 0 0) content-box,\n      linear-gradient(#fff 0 0);\n    -webkit-mask-composite: destination-out;\n    mask-composite: exclude;\n  }\n"])), colors.instagram.background);
export var preTitle = css(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  font-size: 0.625rem;\n  color: ", ";\n"], ["\n  font-size: 0.625rem;\n  color: ", ";\n"])), colors.young_navy);
export var h2v2 = css(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  font-weight: bold;\n  font-size: 40px;\n  letter-spacing: -0.02em;\n  color: ", ";\n"], ["\n  font-weight: bold;\n  font-size: 40px;\n  letter-spacing: -0.02em;\n  color: ", ";\n"])), colors.midnight);
export var h3v2 = css(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  font-weight: 900;\n  font-size: 24px;\n  letter-spacing: -0.02em;\n  color: ", ";\n"], ["\n  font-weight: 900;\n  font-size: 24px;\n  letter-spacing: -0.02em;\n  color: ", ";\n"])), colors.midnight);
// Hover Actions
export var hoverCss = css(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  transition: ", ";\n  &:hover {\n    cursor: pointer;\n    opacity: 0.5;\n  }\n"], ["\n  transition: ", ";\n  &:hover {\n    cursor: pointer;\n    opacity: 0.5;\n  }\n"])), transitions.normal);
export var customFormHoverCss = css(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  transition: ", ";\n  &:hover {\n    cursor: pointer;\n    background-color: ", ";\n  }\n"], ["\n  transition: ", ";\n  &:hover {\n    cursor: pointer;\n    background-color: ", ";\n  }\n"])), transitions.normal, colors.custom_form_admin.hover);
export var hoverActionBasic = css(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  transition: ", ";\n  &:hover {\n    cursor: pointer;\n    opacity: 0.8;\n  }\n"], ["\n  transition: ", ";\n  &:hover {\n    cursor: pointer;\n    opacity: 0.8;\n  }\n"])), transitions.normal);
export var hoverHighlight = css(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  transition: ", ";\n  &:hover {\n    cursor: pointer;\n    color: ", ";\n  }\n"], ["\n  transition: ", ";\n  &:hover {\n    cursor: pointer;\n    color: ", ";\n  }\n"])), transitions.normal, colors.icon);
export var hoverHighlightText = css(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  transition: ", ";\n  &:hover {\n    cursor: pointer;\n    color: ", ";\n  }\n"], ["\n  transition: ", ";\n  &:hover {\n    cursor: pointer;\n    color: ", ";\n  }\n"])), transitions.normal, colors.default_text);
export var hoverMenuBg = css(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  transition: ", ";\n  &:hover {\n    cursor: pointer;\n    background-color: ", ";\n    color: ", ";\n  }\n"], ["\n  transition: ", ";\n  &:hover {\n    cursor: pointer;\n    background-color: ", ";\n    color: ", ";\n  }\n"])), transitions.normal, colors.background.off_white_background, colors.icon);
export var hoverTag = css(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n  transition: ", ";\n  &:hover {\n    cursor: pointer;\n    color: ", ";\n    background-color: ", ";\n  }\n"], ["\n  transition: ", ";\n  &:hover {\n    cursor: pointer;\n    color: ", ";\n    background-color: ", ";\n  }\n"])), transitions.normal, colors.default_text, colors.background.tag_hover);
export var hoverDelete = css(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n  transition: ", ";\n  &:hover {\n    cursor: pointer;\n    color: ", ";\n  }\n"], ["\n  transition: ", ";\n  &:hover {\n    cursor: pointer;\n    color: ", ";\n  }\n"])), transitions.normal, colors.error);
export var hoverShadowMid = css(templateObject_16 || (templateObject_16 = __makeTemplateObject(["\n  transition: ", ";\n  &:hover {\n    cursor: pointer;\n    box-shadow: ", ";\n  }\n"], ["\n  transition: ", ";\n  &:hover {\n    cursor: pointer;\n    box-shadow: ", ";\n  }\n"])), transitions.normal, shadows.medium.mid);
export var hoverShadowDark = css(templateObject_17 || (templateObject_17 = __makeTemplateObject(["\n  transition: ", ";\n  &:hover {\n    cursor: pointer;\n    box-shadow: ", ";\n  }\n"], ["\n  transition: ", ";\n  &:hover {\n    cursor: pointer;\n    box-shadow: ", ";\n  }\n"])), transitions.normal, shadows.medium.dark);
export var hoverShadowDarkv2 = css(templateObject_18 || (templateObject_18 = __makeTemplateObject(["\n  transition: ", ";\n  &:hover {\n    cursor: pointer;\n    box-shadow: ", ";\n  }\n"], ["\n  transition: ", ";\n  &:hover {\n    cursor: pointer;\n    box-shadow: ", ";\n  }\n"])), transitions.normal, shadows.smallV2.blue_zeals);
export var setWidthHeight = css(templateObject_19 || (templateObject_19 = __makeTemplateObject(["\n  width: ", ";\n  height: ", ";\n"], ["\n  width: ", ";\n  height: ", ";\n"])), function (props) { return (props.width ? props.width + "px" : 'fit-content'); }, function (props) { return (props.height ? props.height + "px" : 'auto'); });
export var setMargin = css(templateObject_20 || (templateObject_20 = __makeTemplateObject(["\n  margin: ", ";\n"], ["\n  margin: ", ";\n"])), function (props) { return props.margin || 0; });
export var pseudoBefore = css(templateObject_21 || (templateObject_21 = __makeTemplateObject(["\n  &::before {\n    position: absolute;\n    font-size: 24px;\n    color: ", ";\n  }\n"], ["\n  &::before {\n    position: absolute;\n    font-size: 24px;\n    color: ", ";\n  }\n"])), colors.icon);
export var hideScrollbar = css(templateObject_22 || (templateObject_22 = __makeTemplateObject(["\n  &::-webkit-scrollbar {\n    width: 0px;\n    height: 0px;\n    background: transparent; /* make scrollbar transparent */\n  }\n"], ["\n  &::-webkit-scrollbar {\n    width: 0px;\n    height: 0px;\n    background: transparent; /* make scrollbar transparent */\n  }\n"])));
export var circleTag = css(templateObject_23 || (templateObject_23 = __makeTemplateObject(["\n  display: inline-block;\n  width: 26px;\n  height: 26px;\n  border-radius: 50%;\n  border: solid 4px ", ";\n  margin-top: -0.2em;\n  &.size-l {\n    border-width: 5px;\n    width: 30px;\n    height: 30px;\n    margin-top: -0.1em;\n  }\n  &.size-xl {\n    border-width: 6px;\n    width: 36px;\n    height: 36px;\n    margin-top: -0.2em;\n  }\n  &.success-color {\n    border-color: ", ";\n  }\n  &.messenger-color {\n    border-color: ", ";\n  }\n  &.instagram-color {\n    ", ";\n    &:before {\n      padding: 4px;\n      border-radius: 50%;\n    }\n  }\n"], ["\n  display: inline-block;\n  width: 26px;\n  height: 26px;\n  border-radius: 50%;\n  border: solid 4px ", ";\n  margin-top: -0.2em;\n  &.size-l {\n    border-width: 5px;\n    width: 30px;\n    height: 30px;\n    margin-top: -0.1em;\n  }\n  &.size-xl {\n    border-width: 6px;\n    width: 36px;\n    height: 36px;\n    margin-top: -0.2em;\n  }\n  &.success-color {\n    border-color: ", ";\n  }\n  &.messenger-color {\n    border-color: ", ";\n  }\n  &.instagram-color {\n    ", ";\n    &:before {\n      padding: 4px;\n      border-radius: 50%;\n    }\n  }\n"])), colors.midnight, colors.accent_color, colors.facebook.messenger, instagramBorderGradient);
export var adiconNextBlock = css(templateObject_24 || (templateObject_24 = __makeTemplateObject(["\n  .adicon_nextblock,\n  .adicon_phone,\n  .adicon_mail,\n  .adicon_big_link {\n    font-family: 'Noto Sans JP', 'Roboto', Arial, sans-serif !important;\n    font-size: 12px;\n    margin: ", ";\n    &::before {\n      font-family: 'ad_icons';\n      font-size: 16px;\n      margin-right: 4px;\n      color: ", ";\n    }\n  }\n"], ["\n  .adicon_nextblock,\n  .adicon_phone,\n  .adicon_mail,\n  .adicon_big_link {\n    font-family: 'Noto Sans JP', 'Roboto', Arial, sans-serif !important;\n    font-size: 12px;\n    margin: ", ";\n    &::before {\n      font-family: 'ad_icons';\n      font-size: 16px;\n      margin-right: 4px;\n      color: ",
    ";\n    }\n  }\n"])), function (props) { return props.margin || "4px 0px 0px"; }, function (props) {
    var scenarioId = props.connectedObject &&
        props.connectedObject.postback_action &&
        props.connectedObject.postback_action.scenario_id;
    if (props.nextBlockType === blockTypes.INITIAL &&
        props.connectedObject &&
        scenarioId)
        return colors.label.purple;
    switch (props.nextBlockType) {
        case blockTypes.FORM:
            return colors.label.yellow;
        case blockTypes.PATTERN:
            return colors.label.blue;
        default: {
            return colors.label.black;
        }
    }
});
export var HrLine = styled.hr(templateObject_25 || (templateObject_25 = __makeTemplateObject(["\n  border: 0;\n  border-top: 1px solid ", ";\n  ", ";\n"], ["\n  border: 0;\n  border-top: 1px solid ", ";\n  ", ";\n"])), colors.border.pale, setMargin);
export var zealsPaginationCss = css(templateObject_26 || (templateObject_26 = __makeTemplateObject(["\n  .zeals-admin-pagination {\n    font-size: 14px;\n    color: ", ";\n    line-height: 32px;\n\n    li {\n      border: 1px solid ", ";\n      display: inline-block;\n      min-width: 3rem;\n      text-align: center;\n      ", ";\n\n      &.active {\n        a {\n          color: ", ";\n          font-weight: bold;\n        }\n      }\n      &.disabled {\n        a {\n          opacity: 0.5;\n        }\n      }\n    }\n  }\n"], ["\n  .zeals-admin-pagination {\n    font-size: 14px;\n    color: ", ";\n    line-height: 32px;\n\n    li {\n      border: 1px solid ", ";\n      display: inline-block;\n      min-width: 3rem;\n      text-align: center;\n      ", ";\n\n      &.active {\n        a {\n          color: ", ";\n          font-weight: bold;\n        }\n      }\n      &.disabled {\n        a {\n          opacity: 0.5;\n        }\n      }\n    }\n  }\n"])), colors.main_color, colors.border.default, hoverMenuBg, colors.accent_color);
export var truncateText = css(templateObject_27 || (templateObject_27 = __makeTemplateObject(["\n  overflow: hidden;\n  text-overflow: ellipsis;\n  word-break: break-all;\n  white-space: nowrap;\n"], ["\n  overflow: hidden;\n  text-overflow: ellipsis;\n  word-break: break-all;\n  white-space: nowrap;\n"])));
export var borderHighlight = css(templateObject_28 || (templateObject_28 = __makeTemplateObject(["\n  border: solid 2px ", ";\n\n  &:hover {\n    border: solid 2px ", ";\n  }\n"], ["\n  border: solid 2px ", ";\n\n  &:hover {\n    border: solid 2px ", ";\n  }\n"])), colors.main_color, colors.main_color);
export var fontStyleNormal = css(templateObject_29 || (templateObject_29 = __makeTemplateObject(["\n  font-style: normal;\n  font-weight: normal;\n"], ["\n  font-style: normal;\n  font-weight: normal;\n"])));
export var fontStyleBold = css(templateObject_30 || (templateObject_30 = __makeTemplateObject(["\n  font-style: normal;\n  font-weight: 600;\n"], ["\n  font-style: normal;\n  font-weight: 600;\n"])));
export var icon = css(templateObject_31 || (templateObject_31 = __makeTemplateObject(["\n  position: relative;\n  font-size: 24px;\n  color: rgba(9, 31, 108, 0.5);\n"], ["\n  position: relative;\n  font-size: 24px;\n  color: rgba(9, 31, 108, 0.5);\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15, templateObject_16, templateObject_17, templateObject_18, templateObject_19, templateObject_20, templateObject_21, templateObject_22, templateObject_23, templateObject_24, templateObject_25, templateObject_26, templateObject_27, templateObject_28, templateObject_29, templateObject_30, templateObject_31;
