var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import { injectIntl } from 'react-intl';
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: relative;\n  transition: 0.3s;\n\n  > textarea {\n    display: block;\n    width: 100%;\n    min-height: 50vh;\n  }\n\n  .json-displayer-controls {\n    display: none;\n    position: absolute;\n    top: 8px;\n    right: 8px;\n\n    .copy-button {\n      margin-left: 8px;\n    }\n  }\n\n  &:hover {\n    > .json-displayer-controls {\n      display: inline-block;\n    }\n  }\n"], ["\n  position: relative;\n  transition: 0.3s;\n\n  > textarea {\n    display: block;\n    width: 100%;\n    min-height: 50vh;\n  }\n\n  .json-displayer-controls {\n    display: none;\n    position: absolute;\n    top: 8px;\n    right: 8px;\n\n    .copy-button {\n      margin-left: 8px;\n    }\n  }\n\n  &:hover {\n    > .json-displayer-controls {\n      display: inline-block;\n    }\n  }\n"])));
var JSONDisplayer = function (_a) {
    var data = _a.data, intl = _a.intl;
    var _b = __read(useState(true), 2), isPrettified = _b[0], updateIsPrettified = _b[1];
    var textareaEl = useRef(null);
    var onCopy = function () { return __awaiter(void 0, void 0, void 0, function () {
        var e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, navigator.clipboard.writeText(textareaEl.current.value)];
                case 1:
                    _a.sent();
                    return [3 /*break*/, 3];
                case 2:
                    e_1 = _a.sent();
                    console.log(e_1);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var formatData = function () {
        if (!data)
            return '';
        return isPrettified ? JSON.stringify(data, null, 4) : JSON.stringify(data);
    };
    return (React.createElement(Container, { className: "json-displayer" },
        React.createElement("textarea", { ref: textareaEl, value: formatData(), readOnly: true }),
        React.createElement("div", { className: "json-displayer-controls" },
            React.createElement("button", { onClick: function () { return updateIsPrettified(!isPrettified); }, type: "button" }, intl.formatMessage({ id: "QNc9zb", defaultMessage: "Prettify" })),
            React.createElement("button", { className: "copy-button", onClick: onCopy, type: "button", "data-testid": "JSONDisplayer_CopyButton" }, intl.formatMessage({ id: "R7+jj0", defaultMessage: "\u30B3\u30D4\u30FC" })))));
};
export default injectIntl(JSONDisplayer);
var templateObject_1;
