import "core-js/modules/es.symbol";
import "core-js/modules/es.array.concat";
import "core-js/modules/es.array.filter";
import "core-js/modules/es.array.map";
import "core-js/modules/es.object.assign";
import "core-js/modules/es.object.get-own-property-descriptor";
import "core-js/modules/es.object.get-own-property-descriptors";
import "core-js/modules/es.object.keys";
import "core-js/modules/web.dom-collections.for-each";
import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import * as yup from 'yup';
import { messageTypes } from '@src/lib/app-constants';
import { addMaxLength } from '@src/lib/helpers';
import * as idGenerator from './messageIdGenerator';
import { addMaxLine, checkHostUrl } from './helpers/formSchema'; // Creates error message for the component that gets passed in

export function getErrorMessage(intl, component) {
  if (!intl) return '';
  return intl.formatMessage({
    id: "zquo18",
    defaultMessage: [{
      "type": 1,
      "value": "component"
    }, {
      "type": 0,
      "value": " is required"
    }]
  }, {
    component: component
  });
}
/*
  INDIVIDUAL SCHEMA FUNCS
*/

function applySchema(otherSchema) {
  var schemas = [otherSchema];
  return Object.assign.apply({}, schemas);
}

function talkTriggerSchema(talkTrigger, intl) {
  var schema = _defineProperty({}, idGenerator.talkTrigger(talkTrigger.id), yup.string(getErrorMessage(intl, 'TalkTrigger')));

  return _objectSpread({}, applySchema(schema));
}

function buttonLabelSchema(button, platform, intl) {
  var schema = _defineProperty({}, idGenerator.buttonLabel(button.id), addMaxLength(yup.string().required(getErrorMessage(intl, 'ボタンのラベル')), intl, 'postback_action.label', platform));

  return _objectSpread({}, applySchema(schema));
}

function quickReplySchema(quickReply
/* , intl */
) {
  var quickSchema = _defineProperty({}, idGenerator.quickReply(quickReply.id), yup.string());

  return _objectSpread({}, applySchema(quickSchema));
}

function quickReplyChoiceSchema(quickReplyChoice, platform, intl) {
  var schema = _defineProperty({}, idGenerator.quickReplyChoice(quickReplyChoice.id), addMaxLength(yup.string().required(getErrorMessage(intl, 'QuickReplyChoice')), intl, 'postback_action.label', platform));

  return _objectSpread({}, applySchema(schema));
}

function textSchema(message, platform, intl) {
  var path = message.button_template ? "".concat(messageTypes.BUTTON_TEMPLATE, ".title") : "".concat(messageTypes.TEXT, ".text");

  var schema = _defineProperty({}, idGenerator.text(message), addMaxLength(addMaxLine(yup.string().required(getErrorMessage(intl, intl.formatMessage({
    id: "aA8bDw",
    defaultMessage: [{
      "type": 0,
      "value": "Text"
    }]
  }))), intl, path, platform), intl, path, platform));

  return _objectSpread({}, applySchema(schema));
}

function messengerGenericStatements(intl, article) {
  var _ref;

  return _ref = {}, _defineProperty(_ref, idGenerator.article(article, 'title'), yup.string(getErrorMessage(intl, intl.formatMessage({
    id: "9a9+ww",
    defaultMessage: [{
      "type": 0,
      "value": "Title"
    }]
  }))).min(1, getErrorMessage(intl, intl.formatMessage({
    id: "9a9+ww",
    defaultMessage: [{
      "type": 0,
      "value": "Title"
    }]
  })))), _defineProperty(_ref, idGenerator.article(article, 'subtitle'), yup.string(getErrorMessage(intl, intl.formatMessage({
    id: "DJvCG/",
    defaultMessage: [{
      "type": 0,
      "value": "Subtitle"
    }]
  }))).nullable()), _ref;
}

function lineGenericStatements(intl, article) {
  var _ref2;

  return _ref2 = {}, _defineProperty(_ref2, idGenerator.article(article, 'title'), yup.string(getErrorMessage(intl, intl.formatMessage({
    id: "9a9+ww",
    defaultMessage: [{
      "type": 0,
      "value": "Title"
    }]
  }))).nullable()), _defineProperty(_ref2, idGenerator.article(article, 'subtitle'), yup.string(getErrorMessage(intl, intl.formatMessage({
    id: "DJvCG/",
    defaultMessage: [{
      "type": 0,
      "value": "Subtitle"
    }]
  }))).min(1, getErrorMessage(intl, intl.formatMessage({
    id: "DJvCG/",
    defaultMessage: [{
      "type": 0,
      "value": "Subtitle"
    }]
  })))), _ref2;
}

function instagramGenericStatements(intl, article) {
  var _ref3;

  return _ref3 = {}, _defineProperty(_ref3, idGenerator.article(article, 'image_url'), yup.string(getErrorMessage(intl, intl.formatMessage({
    id: "B9mB91",
    defaultMessage: [{
      "type": 0,
      "value": "Image URL"
    }]
  }))).min(1, getErrorMessage(intl, intl.formatMessage({
    id: "B9mB91",
    defaultMessage: [{
      "type": 0,
      "value": "Image URL"
    }]
  })))), _defineProperty(_ref3, idGenerator.article(article, 'title'), yup.string(getErrorMessage(intl, intl.formatMessage({
    id: "9a9+ww",
    defaultMessage: [{
      "type": 0,
      "value": "Title"
    }]
  }))).min(1, getErrorMessage(intl, intl.formatMessage({
    id: "9a9+ww",
    defaultMessage: [{
      "type": 0,
      "value": "Title"
    }]
  })))), _defineProperty(_ref3, idGenerator.article(article, 'subtitle'), yup.string(getErrorMessage(intl, intl.formatMessage({
    id: "DJvCG/",
    defaultMessage: [{
      "type": 0,
      "value": "Subtitle"
    }]
  }))).nullable()), _ref3;
}

function defaultGenericStatements(intl, article) {
  var _ref4;

  return _ref4 = {}, _defineProperty(_ref4, idGenerator.article(article, 'url'), checkHostUrl(yup.string(getErrorMessage(intl, 'URL')).url(getErrorMessage(intl, 'URL')).nullable(), intl)), _defineProperty(_ref4, idGenerator.article(article, 'image_url'), yup.string(getErrorMessage(intl, 'URL')).url(getErrorMessage(intl, 'URL')).nullable()), _ref4;
}
/*
  Yup Schema comments:
  - yup.string().min(1, '...') => that field is required
*/


function genericSchema(message, platform, intl) {
  var articles = message.generic_template.generic_items.map(function (item) {
    return item.article;
  });
  var genericSchemaArr = articles.map(function (article) {
    var statements = defaultGenericStatements(intl, article);

    switch (platform) {
      case 'messenger':
        statements = _objectSpread({}, statements, {}, messengerGenericStatements(intl, article));
        break;

      case 'line':
        statements = _objectSpread({}, statements, {}, lineGenericStatements(intl, article));
        break;

      case 'instagram':
        statements = _objectSpread({}, statements, {}, instagramGenericStatements(intl, article));
        break;

      default:
    }

    ['subtitle', 'title', 'url'].forEach(function (field) {
      var path = "".concat(messageTypes.GENERIC, ".").concat(field);
      statements[idGenerator.article(article, field)] = addMaxLength(statements[idGenerator.article(article, field)], intl, path, platform);
    });
    return statements;
  });
  var schema = Object.assign.apply(Object, [{}].concat(_toConsumableArray(genericSchemaArr)));
  return _objectSpread({}, applySchema(schema));
}

function flexMessageSchema(message, platform, intl) {
  var schema = _defineProperty({}, idGenerator.flexMessage(message, 'altText'), addMaxLength(yup.string().required('AltTextは必須です。'), intl, "".concat(messageTypes.FLEX_MESSAGE, ".altText"), platform));

  return _objectSpread({}, applySchema(schema));
}

function listSchema(message, intl) {
  var listArticles = message.list_template.list_items.map(function (item) {
    return item.article;
  });
  var listArticleSchemaArr = listArticles.map(function (article) {
    var _ref5;

    return _ref5 = {}, _defineProperty(_ref5, idGenerator.article(article, 'title'), yup.string(getErrorMessage(intl, intl.formatMessage({
      id: "9a9+ww",
      defaultMessage: [{
        "type": 0,
        "value": "Title"
      }]
    }))).min(1, getErrorMessage(intl, intl.formatMessage({
      id: "9a9+ww",
      defaultMessage: [{
        "type": 0,
        "value": "Title"
      }]
    })))), _defineProperty(_ref5, idGenerator.article(article, 'subtitle'), yup.string(getErrorMessage(intl, intl.formatMessage({
      id: "DJvCG/",
      defaultMessage: [{
        "type": 0,
        "value": "Subtitle"
      }]
    }))).nullable()), _defineProperty(_ref5, idGenerator.article(article, 'url'), yup.string(getErrorMessage(intl, 'URL')).url(getErrorMessage(intl, 'URL')).nullable()), _defineProperty(_ref5, idGenerator.article(article, 'image_url'), yup.string(getErrorMessage(intl, 'URL')).url(getErrorMessage(intl, 'URL')).nullable()), _ref5;
  });
  var schema = Object.assign.apply(Object, [{}].concat(_toConsumableArray(listArticleSchemaArr)));
  return _objectSpread({}, applySchema(schema));
}

function mediumSchema(message, intl) {
  var schema = _defineProperty({}, idGenerator.medium(message, 'url'), yup.string(getErrorMessage(intl, 'URL')).url(getErrorMessage(intl, 'URL')).min(1, getErrorMessage(intl, 'URL')));

  return _objectSpread({}, applySchema(schema));
}

function buttonSchema(message, intl) {
  var schema = _defineProperty({}, message.button_template ? "".concat(message.id, "button_message") : 'button', yup.string(getErrorMessage(intl, 'Button')).min(1, getErrorMessage(intl, 'Button')));

  return _objectSpread({}, applySchema(schema));
}

function imageMapSchema(message, platform, intl) {
  var schema = _defineProperty({}, idGenerator.imageMap(message.image_map, 'altText'), addMaxLength(yup.string().required(intl.formatMessage({
    id: "RXXmvV",
    defaultMessage: [{
      "type": 0,
      "value": "AltText required"
    }]
  })), intl, "".concat(messageTypes.IMAGE_MAP, ".altText"), platform));

  return _objectSpread({}, applySchema(schema));
}

function richMenuSchema(message, platform, intl) {
  var _schema9;

  var schema = (_schema9 = {}, _defineProperty(_schema9, idGenerator.richMenu(message.rich_menu, 'label'), addMaxLength(yup.string().required(intl.formatMessage({
    id: "QmVHJ0",
    defaultMessage: [{
      "type": 0,
      "value": "RichMenuLabel required"
    }]
  })), intl, "".concat(messageTypes.RICH_MENU, ".label"), platform)), _defineProperty(_schema9, idGenerator.richMenu(message.rich_menu, 'chatBarText'), addMaxLength(yup.string().required(intl.formatMessage({
    id: "8jeME7",
    defaultMessage: [{
      "type": 0,
      "value": "Chatbar Text required"
    }]
  })), intl, "".concat(messageTypes.RICH_MENU, ".chatBarText"), platform)), _schema9);
  return _objectSpread({}, applySchema(schema));
}
/*
  EXPORTED SCHEMA FUNC
*/
// Generates a yup schema
// message => message object,
// platform => which platform the user is using (LINE/Messenger)
// intl => intl object that formats the error message for internalization


export function generateYupSchema(message, platform, intl) {
  var shape = function (msg) {
    if (msg.rich_menu) {
      return richMenuSchema(msg, platform, intl);
    }

    if (typeof msg.text === 'string' || msg.button_template) {
      return textSchema(msg, platform, intl);
    }

    if (msg.generic_template) {
      return genericSchema(msg, platform, intl);
    }

    if (msg.line_flex_message) {
      return flexMessageSchema(msg, platform, intl);
    }

    if (msg.list_template) {
      return listSchema(msg, intl);
    }

    if (msg.medium) {
      return mediumSchema(msg, intl);
    }

    if (msg.button_template) {
      return buttonSchema(msg, intl);
    }

    if (msg.image_map) {
      return imageMapSchema(msg, platform, intl);
    }

    return textSchema(msg, platform, intl);
  }(message);

  return yup.object().shape(shape);
}
export function generateTriggerSchema(talkTrigger, intl) {
  var shape = talkTriggerSchema(talkTrigger, intl);
  return yup.object().shape(shape);
}
export function generateButtonSchema(button, platform, intl) {
  var shape = buttonLabelSchema(button, platform, intl);
  return yup.object().shape(shape);
}
export function generateQuickReplySchema(quickReply, intl) {
  var shape = quickReplySchema(quickReply, intl);
  return yup.object().shape(shape);
}
export function generateQuickReplyChoiceSchema(quickReplyChoice, platform, intl) {
  var shape = quickReplyChoiceSchema(quickReplyChoice, platform, intl);
  return yup.object().shape(shape);
}