var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getInitialConversationABTestGroupActive, getInitialConversationABTestGroupDraft, } from '@src/services/api/initialConversation';
import { WeightScenarioVariantGroupFunctionType } from 'zeals-protobuf/zeals/web/entities/scenario_group_pb';
import { setActiveABTesting, setActiveInitialConversation, setSelectedScenarios, setInitialScenarios, setDraftABTesting, setDraftInitialConversation, } from '@src/redux/fanp/actions/initialConversation';
import { useParams } from 'react-router-dom';
import * as scenarioApi from '@src/services/api/scenario';
import { getABTestDetails } from './common';
export var Status;
(function (Status) {
    Status["Pending"] = "Pending";
    Status["Success"] = "Success";
    Status["Error"] = "Error";
})(Status || (Status = {}));
export var useFetchActiveInitialConversationABTestGroups = function () {
    var adScenarioPresent = useSelector(function (state) { var _a, _b; return (_b = (_a = state === null || state === void 0 ? void 0 : state.adScenario) === null || _a === void 0 ? void 0 : _a.present) === null || _b === void 0 ? void 0 : _b.scenarios; });
    var dispatch = useDispatch();
    var params = useParams();
    var chatbotId = parseInt(params.chatbotId);
    var _a = __read(useState(Status.Pending), 2), status = _a[0], setStatus = _a[1];
    var _b = __read(useState(), 2), error = _b[0], setError = _b[1];
    var getScenarios = useCallback(function (chatbotId) { return __awaiter(void 0, void 0, Promise, function () {
        var scenarios, temp, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, scenarioApi.getInitialScenarios(chatbotId)];
                case 1:
                    scenarios = (_a.sent()).scenarios;
                    temp = __spreadArray([], __read(scenarios));
                    dispatch(setInitialScenarios(temp));
                    return [2 /*return*/, temp];
                case 2:
                    e_1 = _a.sent();
                    setStatus(Status.Error);
                    setError(e_1);
                    return [2 /*return*/, []];
                case 3: return [2 /*return*/];
            }
        });
    }); }, [dispatch]);
    var fillEmptyWeightForEqualType = function (res) {
        var resFunctionType = parseInt(WeightScenarioVariantGroupFunctionType[res[0].scenarioVariantGroup.weightScenarioVariantGroup.functionType]);
        var equalWeightFunctionType = WeightScenarioVariantGroupFunctionType.WEIGHT_SCENARIO_VARIANT_GROUP_FN_TYPE_EQUAL_WEIGHT;
        var equalWeight = resFunctionType === equalWeightFunctionType;
        res[0].scenarioVariantGroup.weightScenarioVariantGroup.functionType = resFunctionType;
        if (equalWeight) {
            var count_1 = res[0].scenarioVariantGroup.variants.length;
            res[0].scenarioVariantGroup.variants = res[0].scenarioVariantGroup.variants.map(function (v) {
                return __assign(__assign({}, v), { weightScenarioVariant: __assign(__assign({}, v.weightScenarioVariant), { weight: 100 / count_1 / 100 }) });
            });
        }
    };
    var getInitialConversationABTestGroups = useCallback(function (chatbotId) { return __awaiter(void 0, void 0, Promise, function () {
        var res, e_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, getInitialConversationABTestGroupActive(chatbotId)];
                case 1:
                    res = _a.sent();
                    fillEmptyWeightForEqualType(res);
                    dispatch(setActiveInitialConversation(true));
                    dispatch(setActiveABTesting(res));
                    setStatus(Status.Success);
                    return [2 /*return*/, res];
                case 2:
                    e_2 = _a.sent();
                    dispatch(setActiveInitialConversation(false));
                    setStatus(Status.Error);
                    setError(e_2);
                    return [2 /*return*/, undefined];
                case 3: return [2 /*return*/];
            }
        });
    }); }, [dispatch]);
    var getInitialConversationABTestGroupsDraft = useCallback(function (chatbotId) { return __awaiter(void 0, void 0, Promise, function () {
        var res, e_3;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, getInitialConversationABTestGroupDraft(chatbotId)];
                case 1:
                    res = _a.sent();
                    fillEmptyWeightForEqualType(res);
                    dispatch(setDraftInitialConversation(true));
                    dispatch(setDraftABTesting(res));
                    setStatus(Status.Success);
                    return [2 /*return*/, res];
                case 2:
                    e_3 = _a.sent();
                    dispatch(setDraftInitialConversation(false));
                    setStatus(Status.Error);
                    setError(e_3);
                    return [2 /*return*/, undefined];
                case 3: return [2 /*return*/];
            }
        });
    }); }, [dispatch]);
    var fetchAndSetScenariosAndActiveDraftABTest = useCallback(function () { return __awaiter(void 0, void 0, Promise, function () {
        var scenarios, draftABTesting, ABTestDraftData, ABTestDraftScenarios;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getScenarios(chatbotId)];
                case 1:
                    scenarios = _a.sent();
                    return [4 /*yield*/, getInitialConversationABTestGroupsDraft(chatbotId)];
                case 2:
                    draftABTesting = _a.sent();
                    getInitialConversationABTestGroups(chatbotId);
                    ABTestDraftData = getABTestDetails(draftABTesting, scenarios);
                    ABTestDraftScenarios = ABTestDraftData.ABTestScenarios;
                    dispatch(setSelectedScenarios(ABTestDraftScenarios));
                    return [2 /*return*/];
            }
        });
    }); }, [dispatch, getScenarios, getInitialConversationABTestGroups, chatbotId]);
    useEffect(function () {
        fetchAndSetScenariosAndActiveDraftABTest();
    }, [
        dispatch,
        chatbotId,
        adScenarioPresent,
        fetchAndSetScenariosAndActiveDraftABTest,
    ]);
    return { status: status, error: error };
};
