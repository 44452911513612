var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import React, { Component } from 'react';
import endpoints from '@src/endpoints';
import styled from 'styled-components';
import { filterRecordsBySearch } from '@src/lib/helpers';
import { makeFetchInit, handleAPIErrors } from '@src/lib/helpers/ajaxFuncs';
import { InputRadioBox, ModalPortal, SectionalContainer, } from '@src/components/atoms';
import { colors } from '@src/colors';
import { Modal, ContentScenarioSearchArea } from '@src/components/molecules';
import DeliveryTagArea from './DeliveryTagArea';
import SelectedDeliveryTagArea from './SelectedDeliveryTagArea';
import * as scenarioApi from '@src/services/api/scenario';
import { injectIntl } from 'react-intl';
import * as attributeQuestionApi from '@src/services/api/attributeQuestion';
import MenuButtonList from '@src/components/organisms/fanp/MenuButtonList';
import SearchableList from '@src/components/molecules/SearchableList';
var ContentBody = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  .not-include-label {\n    color: ", ";\n  }\n"], ["\n  .not-include-label {\n    color: ", ";\n  }\n"])), colors.error);
var AttributeContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  height: 300px;\n  display: flex;\n  margin: 12px 0 0 0;\n  overflow: hidden;\n\n  .attribute_questions_spinner {\n    background: ", ";\n    height: 300px;\n  }\n\n  .attribute_answers_spinner {\n    background: ", ";\n    height: 300px;\n  }\n\n  > div {\n    &:nth-child(1) {\n      flex: 1;\n    }\n    &:nth-child(2) {\n      flex: 2;\n    }\n  }\n"], ["\n  height: 300px;\n  display: flex;\n  margin: 12px 0 0 0;\n  overflow: hidden;\n\n  .attribute_questions_spinner {\n    background: ", ";\n    height: 300px;\n  }\n\n  .attribute_answers_spinner {\n    background: ", ";\n    height: 300px;\n  }\n\n  > div {\n    &:nth-child(1) {\n      flex: 1;\n    }\n    &:nth-child(2) {\n      flex: 2;\n    }\n  }\n"])), colors.background.active, colors.background.light_purple);
var RetentionContentModal = /** @class */ (function (_super) {
    __extends(RetentionContentModal, _super);
    function RetentionContentModal() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            scenarios: [],
            scenario: _this.props.initialScenario || null,
            scenarioSearch: '',
            again: _this.props.initialAgain || false,
            searchFilter: _this.props.initialAggregation || 'AND',
            attributeAnswers: [],
            attributeQuestions: [],
            searchTerm: '',
            selectedTags: _this.props.initialselectedTags || [],
            selectedAttributeQuestion: 'all',
        };
        _this.componentDidMount = function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.fetchAttributeAnswers()];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.fetchAttributeQuestions()];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, this.fetchScenarios()];
                    case 3:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        _this.fetchAttributeAnswers = function () {
            var chatbotId = _this.props.chatbotId;
            var attributeAnswersUrl = endpoints.tags.search(chatbotId);
            fetch(attributeAnswersUrl, makeFetchInit('GET'))
                .then(function (res) { return res.json(); })
                .then(function (_a) {
                var attribute_answers = _a.data.attribute_answers;
                return _this.setState({ attributeAnswers: attribute_answers });
            })
                .catch(handleAPIErrors);
        };
        _this.fetchScenarios = function () { return __awaiter(_this, void 0, void 0, function () {
            var chatbotId, scenarios, e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        chatbotId = this.props.chatbotId;
                        return [4 /*yield*/, scenarioApi.getScenarios__LEGACY(Number(chatbotId))];
                    case 1:
                        scenarios = _a.sent();
                        this.setState({ scenarios: scenarios });
                        return [3 /*break*/, 3];
                    case 2:
                        e_1 = _a.sent();
                        handleAPIErrors(e_1.message);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        _this.fetchAttributeQuestions = function () { return __awaiter(_this, void 0, void 0, function () {
            var chatbotId, attributeQuestions, e_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        chatbotId = this.props.chatbotId;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, attributeQuestionApi.getAttributeQuestions(chatbotId)];
                    case 2:
                        attributeQuestions = _a.sent();
                        this.setState({
                            attributeQuestions: attributeQuestions,
                        });
                        return [3 /*break*/, 4];
                    case 3:
                        e_2 = _a.sent();
                        handleAPIErrors(e_2.message);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        }); };
        _this.handleSelectAttributeQuestion = function (questionId) {
            _this.setState({ selectedAttributeQuestion: questionId });
        };
        _this.resetState = function () {
            _this.setState({
                scenarios: [],
                scenario: _this.props.initialScenario || null,
                scenarioSearch: '',
                again: _this.props.initialAgain || false,
                searchFilter: _this.props.initialAggregation || 'AND',
                attributeAnswers: [],
                searchTerm: '',
                selectedTags: _this.props.initialselectedTags || [],
            });
        };
        _this.handleFilterChange = function (e) { return _this.setState({ searchFilter: e.target.value }); };
        _this.handleScenarioUpdate = function (scenario) { return _this.setState({ scenario: scenario }); };
        _this.handleScenarioSearchUpdate = function (e) {
            return _this.setState({ scenarioSearch: e.target.value });
        };
        _this.handleClearSelectedScenario = function () { return _this.setState({ scenario: null }); };
        _this.handleUpdateSearchTerm = function (e) { return _this.setState({ searchTerm: e.target.value }); };
        _this.handleDeliveryRepetition = function () { return _this.setState({ again: !_this.state.again }); };
        _this.handleAddSelectTag = function (attributeAnswer, not) {
            var selectedTags = _this.state.selectedTags;
            var tempSelectedTags = __spreadArray([], __read(selectedTags));
            var existingSelectedTag = tempSelectedTags.find(function (selectedTag) { return selectedTag.id === attributeAnswer.id; });
            if (existingSelectedTag) {
                if (existingSelectedTag.not !== not) {
                    tempSelectedTags.forEach(function (selectedTag) {
                        if (selectedTag.id === existingSelectedTag.id) {
                            selectedTag.not = !selectedTag.not;
                        }
                    });
                    _this.setState({ selectedTags: tempSelectedTags });
                }
            }
            else {
                _this.setState({
                    selectedTags: _this.state.selectedTags.concat({
                        attribute_question_id: attributeAnswer.attribute_question_id,
                        id: attributeAnswer.id,
                        label: attributeAnswer.label,
                        not: not,
                    }),
                });
            }
        };
        _this.handleDeleteSelectTag = function (tagId) {
            var newSelectedTags = _this.state.selectedTags.filter(function (tag) { return tag.id !== tagId; });
            return _this.setState({ selectedTags: newSelectedTags });
        };
        _this.fetchContent = function (url, httpMethod, body) {
            var updateContent = _this.props.updateContent;
            fetch(url, makeFetchInit(httpMethod, body))
                .then(function (res) { return res.json(); })
                .then(function (_a) {
                var retention_content = _a.retention_content;
                updateContent(retention_content);
            })
                .catch(function (e) {
                alert(e.message);
                _this.resetState();
            });
        };
        _this.confirmSubmit = function () {
            if (!_this.state.scenario) {
                window.confirm('配信するシナリオを選択してください');
            }
            else {
                if (_this.state.scenario && _this.state.scenario.testing) {
                    // when the scenario is testing
                    window.confirm('テストモードがonです。本当に配信してもよろしいですか？') && _this.contentSubmit();
                }
                else {
                    _this.contentSubmit();
                }
            }
        };
        _this.contentSubmit = function () {
            var _a = _this.props, chatbotId = _a.chatbotId, targetId = _a.targetId, campaignId = _a.campaignId, id = _a.id, onCancel = _a.onCancel;
            var _b = _this.state, scenario = _b.scenario, searchFilter = _b.searchFilter, selectedTags = _b.selectedTags, again = _b.again;
            var isNew = !id;
            var aggregation = searchFilter;
            var quick_filters = selectedTags.map(function (tag) { return ({
                id: tag.id,
                not: tag.not,
            }); });
            var body = {
                quick_filters: quick_filters,
                aggregation: aggregation,
                scenario_id: scenario && scenario.id,
                again: again,
            };
            if (isNew && scenario.id) {
                // make new
                var url = endpoints.retentionContents.trackPush.collection(chatbotId, targetId, campaignId);
                _this.fetchContent(url, 'POST', body);
            }
            else {
                var url = endpoints.retentionContents.trackPush.member(chatbotId, targetId, campaignId, id);
                _this.fetchContent(url, 'PATCH', body);
            }
            onCancel();
        };
        return _this;
    }
    RetentionContentModal.prototype.render = function () {
        var _a = this.state, scenarios = _a.scenarios, scenarioSearch = _a.scenarioSearch, scenario = _a.scenario, selectedTags = _a.selectedTags, attributeAnswers = _a.attributeAnswers, searchFilter = _a.searchFilter, searchTerm = _a.searchTerm, attributeQuestions = _a.attributeQuestions, selectedAttributeQuestion = _a.selectedAttributeQuestion;
        var intl = this.props.intl;
        var filteredScenarios = filterRecordsBySearch(scenarios, scenarioSearch);
        return (React.createElement(ModalPortal, null,
            React.createElement(Modal, __assign({ "data-testid": "retention-content-modal", type: "SELECT", text: intl.formatMessage({ id: "BsCsHS", defaultMessage: "Scenario Delivery Settings" }), width: "80%", minWidth: "800px", maxWidth: "1000px", onConfirm: this.confirmSubmit, overFlow: true }, this.props),
                React.createElement(SectionalContainer, null,
                    React.createElement(ContentBody, { className: "body" },
                        React.createElement("div", { className: "message-wrapper primary-label" }, intl.formatMessage({ id: "Ks5AQN", defaultMessage: "Please select a Scenario" })),
                        React.createElement(ContentScenarioSearchArea, { scenario: scenario, scenarioSearch: scenarioSearch, filteredScenarios: filteredScenarios, handleScenarioSearchUpdate: this.handleScenarioSearchUpdate, handleClearSelectedScenario: this.handleClearSelectedScenario, handleScenarioUpdate: this.handleScenarioUpdate }),
                        React.createElement("div", { className: "section" },
                            React.createElement("div", { className: "message-wrapper primary-label" }, intl.formatMessage({ id: "LH7Qxj", defaultMessage: "Deliver the same scenario to the same users ?" })),
                            React.createElement("div", { className: "radios" },
                                React.createElement(InputRadioBox, { checked: !this.state.again, onChange: this.handleDeliveryRepetition, text: intl.formatMessage({ id: "5yH7x4", defaultMessage: "One time only" }) }),
                                React.createElement(InputRadioBox, { checked: this.state.again, onChange: this.handleDeliveryRepetition, text: intl.formatMessage({ id: "YP3fzx", defaultMessage: "Any number of times" }) }))),
                        React.createElement("div", { className: "section" },
                            React.createElement("div", { className: "message-wrapper sub-header" }, intl.formatMessage({ id: "C97QUT", defaultMessage: "Linked with tags Delivery Target Settings" })),
                            React.createElement("div", { className: "message-wrapper primary-label" }, intl.formatMessage({ id: "om2QS/", defaultMessage: "Link Condition" })),
                            React.createElement("div", { className: "radios" },
                                React.createElement(InputRadioBox, { value: "AND", checked: searchFilter === 'AND', onChange: this.handleFilterChange, text: "AND" }),
                                React.createElement(InputRadioBox, { value: "OR", checked: searchFilter === 'OR', onChange: this.handleFilterChange, text: "OR" }),
                                searchFilter === 'AND'
                                    ? intl.formatMessage({ id: "8KvsGs", defaultMessage: "Deliver to all users with the selected tags" })
                                    : intl.formatMessage({ id: "54N+C2", defaultMessage: "Deliver to users with more than 1 of the selected tags" }))),
                        React.createElement("div", { className: "message-wrapper sub-label" }, intl.formatMessage({ id: "3mdOr+", defaultMessage: "If the AND condition is applied, will deliver to all users included with the selected tags" })),
                        React.createElement("div", { className: "message-wrapper sub-label" },
                            "\u300C",
                            React.createElement("span", { className: "not-include-label" }, intl.formatMessage({ id: "bQb9lv", defaultMessage: "Not Included" })),
                            "\u300D",
                            intl.formatMessage({ id: "M9mTK9", defaultMessage: "condition will deliver to users that are not included with the selected tags" })),
                        React.createElement(SelectedDeliveryTagArea, { attributeQuestions: attributeQuestions, searchFilter: searchFilter, selectedTags: selectedTags, handleDeleteSelectTag: this.handleDeleteSelectTag }),
                        React.createElement(AttributeContainer, null,
                            React.createElement(SearchableList, { title: intl.formatMessage({ id: "1yjfcG", defaultMessage: "Tag Groups" }), placeholder: intl.formatMessage({ id: "tQ6AtB", defaultMessage: "Search by Tag Group" }), margin: "0 12px 0 0", isTagGroup: true },
                                React.createElement(MenuButtonList, { selectedAttributeQuestion: selectedAttributeQuestion, attributeQuestions: attributeQuestions, handleSelectAttributeQuestion: this.handleSelectAttributeQuestion })),
                            React.createElement(SearchableList, { isTagGroup: false, title: intl.formatMessage({ id: "1EYCdR", defaultMessage: "Tags" }), placeholder: intl.formatMessage({ id: "3DV02d", defaultMessage: "Search Tag" }) },
                                React.createElement(DeliveryTagArea, { selectedAttributeQuestion: selectedAttributeQuestion, attributeAnswers: attributeAnswers, selectedTags: selectedTags, searchTerm: searchTerm, handleAddSelectTag: this.handleAddSelectTag }))))))));
    };
    return RetentionContentModal;
}(Component));
export default injectIntl(RetentionContentModal);
var templateObject_1, templateObject_2;
