import React from 'react';
import TextNode from './messages/TextNode';
import ButtonTemplateNode from './messages/ButtonTemplateNode';
import GenericTemplateNode from './messages/GenericTemplateNode';
import FileNode from './messages/FileNode';
import ImageMapNode from './messages/ImageMapNode';
import RichMenuNode from './messages/RichMenuNode';
import LineFlexNode from './messages/LineFlexNode';
var MessageNode = function (_a) {
    var _b = _a.data, message = _b.message, updateBoundaryNode = _b.updateBoundaryNode;
    if (message.text)
        return React.createElement(TextNode, { data: { message: message } });
    if (message.button_template)
        return React.createElement(ButtonTemplateNode, { data: { message: message, updateBoundaryNode: updateBoundaryNode } });
    if (message.generic_template)
        return React.createElement(GenericTemplateNode, { data: { message: message, updateBoundaryNode: updateBoundaryNode } });
    if (message.medium)
        return React.createElement(FileNode, { data: { message: message } });
    if (message.image_map)
        return React.createElement(ImageMapNode, { data: { message: message, updateBoundaryNode: updateBoundaryNode } });
    if (message.rich_menu)
        return React.createElement(RichMenuNode, { data: { message: message, updateBoundaryNode: updateBoundaryNode } });
    if (message.line_flex_message)
        return React.createElement(LineFlexNode, { data: { message: message, updateBoundaryNode: updateBoundaryNode } });
    return null;
};
export default MessageNode;
