import "core-js/modules/es.array.iterator";
import "core-js/modules/es.array.map";
import "core-js/modules/es.object.to-string";
import "core-js/modules/web.dom-collections.iterator";
import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Button } from '@src/components/atoms';
import buttonTypes from '@src/lib/buttonTypes';
import { injectIntl } from 'react-intl';
var propTypes = {
  chatbotId: PropTypes.string,
  handleChangeHour: PropTypes.func,
  handleChangeMinute: PropTypes.func,
  handleChangeDeliveryType: PropTypes.func
};
var Container = styled.div.withConfig({
  displayName: "Container",
  componentId: "sc-1yde367-0"
})(["margin-left:10px;display:inline-flex;"]);
var SelectTimeField = styled.div.withConfig({
  displayName: "SelectTimeField",
  componentId: "sc-1yde367-1"
})(["width:100px;position:relative;border-radius:6px;cursor:pointer;vertical-align:bottom;display:inline-block;margin:0 10px;border:1px solid;margin-top:150px;padding:5px;font-weight:bold;"]);

var SelectDeliveryTime = function SelectDeliveryTime(props) {
  var chatbotId = props.chatbotId,
      intl = props.intl;
  return React.createElement("div", null, React.createElement(SelectTimeField, null, React.createElement("select", {
    onChange: props.handleChangeHour
  }, _toConsumableArray(Array(24).keys()).map(function (time) {
    return React.createElement("option", {
      value: "".concat(time),
      key: "select-delivery-".concat(time)
    }, time);
  }))), "\u6642", React.createElement(SelectTimeField, null, React.createElement("select", {
    onChange: props.handleChangeMinute
  }, ['00', '15', '30', '45'].map(function (minute) {
    return React.createElement("option", {
      value: "".concat(minute),
      key: "select-delivery-".concat(minute)
    }, minute);
  }))), "\u5206", React.createElement(Container, null, React.createElement(Button, {
    onClick: function onClick() {
      return props.handleChangeDeliveryType('just', chatbotId);
    },
    text: intl.formatMessage({
      id: "JiClN1",
      defaultMessage: [{
        "type": 0,
        "value": "Exactly at"
      }]
    }),
    buttonType: buttonTypes.SETTING,
    width: 100,
    margin: "0 5px"
  }), React.createElement(Button, {
    onClick: function onClick() {
      return props.handleChangeDeliveryType('random', chatbotId);
    },
    text: intl.formatMessage({
      id: "yYTkST",
      defaultMessage: [{
        "type": 0,
        "value": "+- 15min randomly"
      }]
    }),
    buttonType: buttonTypes.SETTING,
    width: 200
  })));
};

SelectDeliveryTime.propTypes = propTypes;
export default injectIntl(SelectDeliveryTime);