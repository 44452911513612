var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Snackbar } from '@zeals-co-ltd/washi-components';
import useSnackbarNotify from '@src/components/hooks/useSnackbarNotify';
import { setInitialScenarios } from '@src/redux/fanp/actions/initialConversation';
import ABTestSettingModal from './ABTestSettingModal';
import ActiveABTestingPanel from './ActiveABTestingPanel';
var InitialConversation = function (props) {
    var onTabChange = props.onTabChange;
    var dispatch = useDispatch();
    var _a = useSnackbarNotify(), notify = _a.notify, clearNotify = _a.clearNotify, open = _a.open;
    var activeInitialConversation = useSelector(function (state) { return state.initialConversation.getActiveInitialConversation; });
    var draftInitialConversation = useSelector(function (state) { return state.initialConversation.getDraftInitialConversation; });
    var allAvailableScenario = useSelector(function (state) { return state.initialConversation.scenarios; });
    dispatch(setInitialScenarios(allAvailableScenario));
    var _b = __read(useState(activeInitialConversation || draftInitialConversation), 2), isIntialActive = _b[0], setIsInitialActive = _b[1];
    return (React.createElement(React.Fragment, null,
        React.createElement(Snackbar, { open: open, alertProps: { severity: notify.severity }, onClose: clearNotify },
            React.createElement("span", null, notify.message)),
        isIntialActive ? (React.createElement(ActiveABTestingPanel, { isInitialConversationActive: setIsInitialActive, onTabChange: onTabChange })) : (React.createElement(ABTestSettingModal, { onTabChange: onTabChange, isInitialConversationActive: setIsInitialActive }))));
};
export default injectIntl(InitialConversation);
