var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import { colors } from '@src/colors';
var GenericItemContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin: 20px 0px;\n  display: flex;\n"], ["\n  margin: 20px 0px;\n  display: flex;\n"])));
var ArticleWrapper = styled.div.attrs({ className: 'article' })(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 260px;\n  min-height: 200px;\n  margin: 20px 10px;\n  border-radius: 16px;\n  border: 1px solid ", ";\n  overflow: hidden;\n\n  .image {\n    background: url(", ");\n    background-position-x: 0%;\n    background-position-y: 0%;\n    background-position: center;\n    background-size: contain;\n    background-repeat: no-repeat;\n    height: 260px;\n    overflow: hidden;\n    text-align: center;\n    transition: 0.3s;\n    border-bottom: solid 1px ", ";\n  }\n\n  .title,\n  .subtitle,\n  .url {\n    padding: 8px;\n    word-break: break-all;\n    white-space: break-spaces;\n  }\n\n  .button {\n    text-align: center;\n    padding: 8px;\n    border-top: solid 1px ", ";\n  }\n"], ["\n  width: 260px;\n  min-height: 200px;\n  margin: 20px 10px;\n  border-radius: 16px;\n  border: 1px solid ", ";\n  overflow: hidden;\n\n  .image {\n    background: url(", ");\n    background-position-x: 0%;\n    background-position-y: 0%;\n    background-position: center;\n    background-size: contain;\n    background-repeat: no-repeat;\n    height: 260px;\n    overflow: hidden;\n    text-align: center;\n    transition: 0.3s;\n    border-bottom: solid 1px ", ";\n  }\n\n  .title,\n  .subtitle,\n  .url {\n    padding: 8px;\n    word-break: break-all;\n    white-space: break-spaces;\n  }\n\n  .button {\n    text-align: center;\n    padding: 8px;\n    border-top: solid 1px ", ";\n  }\n"])), colors.border.default, function (props) { return props.image_url; }, colors.border.default, colors.border.default);
var GenericTemplateNode = function (_a) {
    var _b = _a.data, message = _b.message, updateBoundaryNode = _b.updateBoundaryNode;
    return (React.createElement(GenericItemContainer, { key: "generic-template-" + message.generic_template.id, width: message.generic_template.generic_items.length * 260, "data-testid": "GenericTemplateNode_GenericTemplateContainer" }, message.generic_template.generic_items.map(function (_a) {
        var _b = _a.article, id = _b.id, proxy_image_url = _b.proxy_image_url, title = _b.title, subtitle = _b.subtitle, url = _b.url, generic_item_buttons = _a.generic_item_buttons;
        var genericItemButtons = generic_item_buttons || [];
        return (React.createElement(ArticleWrapper, { key: "article-" + id, image_url: proxy_image_url, "data-testid": "GenericTemplateNode_ArticleWrapper" },
            React.createElement("div", { className: "image" }),
            React.createElement("div", { className: "title" }, (title || '').trim() || 'No title'),
            React.createElement("div", { className: "subtitle" }, (subtitle || '').trim() !== 'ㅤ' ? subtitle : 'No subtitle'),
            React.createElement("div", { className: "url" }, (url || '').trim() || 'No url'),
            genericItemButtons.map(function (_a) {
                var button = _a.button;
                return (React.createElement("div", { key: "generic-item-button-" + button.id, className: "button", role: "button", ref: function (ref) {
                        if (!ref || !updateBoundaryNode || !button.postback_action)
                            return;
                        updateBoundaryNode('postbackActions', button.postback_action.id, ref.getBoundingClientRect());
                    } }, button.name));
            })));
    })));
};
export default GenericTemplateNode;
var templateObject_1, templateObject_2;
