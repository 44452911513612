var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React from 'react';
import { injectIntl } from 'react-intl';
import { Button, Icon, TextField } from '@zeals-co-ltd/washi-components';
import styled from 'styled-components';
import { colors } from '@src/colors';
import { styled as MUIStyled } from '@mui/material/styles';
import { Typography } from '@mui/material';
import { navy, red } from '@zeals-co-ltd/washi-styles/lib/colors';
var StyledTextField = MUIStyled(TextField)(function () { return ({
    width: '100%',
    flex: 1,
    '& .MuiInputBase-root': {
        height: '56px',
    },
    '&.left-input fieldset': {
        borderTopRightRadius: 'unset',
        borderBottomRightRadius: 'unset',
    },
    '&.right-input fieldset': {
        borderTopLeftRadius: 'unset',
        borderBottomLeftRadius: 'unset',
    },
    '& .MuiInputBase-input': {
        fontSize: '14px',
        lineHeight: '20px',
        padding: '16px',
        color: colors.midnight,
        '&::placeholder': {
            color: colors.rpa_settings.young_navy,
            opacity: 1,
        },
    },
}); });
var DeleteButton = styled(Button)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  height: 40px;\n  width: 40px;\n  min-width: unset;\n  margin-left: 8px;\n  .adicon_delete {\n    margin: 0;\n  }\n"], ["\n  height: 40px;\n  width: 40px;\n  min-width: unset;\n  margin-left: 8px;\n  .adicon_delete {\n    margin: 0;\n  }\n"])));
var AddValueButton = styled(Button)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  height: 32px;\n  margin-bottom: 24px;\n  width: fit-content;\n"], ["\n  height: 32px;\n  margin-bottom: 24px;\n  width: fit-content;\n"])));
var InputContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  width: 100%;\n  margin-bottom: 16px;\n  border-bottom: 0;\n"], ["\n  display: flex;\n  align-items: center;\n  width: 100%;\n  margin-bottom: 16px;\n  border-bottom: 0;\n"])));
var BottomContainer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n"], ["\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n"])));
var Label = styled.label(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  margin-bottom: ", ";\n"], ["\n  margin-bottom: ", ";\n"])), function (props) { return (props.hasSubtitle ? '8px' : '16px'); });
export default injectIntl(function (_a) {
    var intl = _a.intl, valueMap = _a.valueMap, onAddMapEntry = _a.onAddMapEntry, onRemoveMapEntry = _a.onRemoveMapEntry, onUpdateMapEntry = _a.onUpdateMapEntry, error = _a.error;
    return (React.createElement("div", { className: "value-map-container" },
        React.createElement(Label, { htmlFor: "FormElementSettingsModal-LabelInput" },
            React.createElement(Typography, { variant: "body1", component: "span", color: navy[900] }, intl.formatMessage({ id: "sZyufG", defaultMessage: "Please enter the value of each Input option from the client side" }))),
        Boolean(valueMap.length) && (React.createElement("div", { className: "field-values-wrapper" }, valueMap.map(function (_a, i) {
            var _b = __read(_a, 2), key = _b[0], value = _b[1];
            return (React.createElement(InputContainer, { key: "value-map-entry-" + (i + 1) },
                React.createElement(StyledTextField, { key: "value-map-key-" + (i + 1), className: "left-input", placeholder: intl.formatMessage({ id: "2mWy2L", defaultMessage: "Enter the value of your field" }), value: key, onChange: function (e) {
                        return onUpdateMapEntry(i, [e.target.value, value]);
                    } }),
                React.createElement(StyledTextField, { key: "value-map-value-" + (i + 1), className: "right-input", value: value, placeholder: intl.formatMessage({ id: "n3tXqs", defaultMessage: "Enter the value to use in automation" }), onChange: function (e) { return onUpdateMapEntry(i, [key, e.target.value]); } }),
                React.createElement(DeleteButton, { variant: "text", color: "error", fontIconClass: "adicon_delete", onClick: function () { return onRemoveMapEntry(i); }, disabled: i === 0 && valueMap.length === 1 })));
        }))),
        React.createElement(BottomContainer, null,
            error && (React.createElement(Typography, { variant: "body1", component: "span", color: red[500] }, error)),
            React.createElement(AddValueButton, { color: "primary", variant: "outlined", onClick: onAddMapEntry, startIcon: React.createElement(Icon, { name: "adicon_plus", color: "primary" }) }, intl.formatMessage({ id: "x1Q83m", defaultMessage: "Add More Items" })))));
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
