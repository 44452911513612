import { ApplicationType, } from '../types/application';
export var initialState = {
    connected: true,
    isFetching: false,
    isHeaderFetching: false,
};
export default (function (state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case ApplicationType.SET_CONNECTED:
            return Object.assign({}, state, { connected: action.connected });
        case ApplicationType.UPDATE_IS_FETCHING: {
            return Object.assign({}, state, { isFetching: action.isFetching });
        }
        case ApplicationType.UPDATE_HEADER_IS_FETCHING: {
            return Object.assign({}, state, {
                isHeaderFetching: action.isHeaderFetching,
            });
        }
        default:
            return state;
    }
});
