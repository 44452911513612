var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled, { keyframes } from 'styled-components';
var rotate = keyframes(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  100% {\n    transform: rotate(360deg);\n  }\n"], ["\n  100% {\n    transform: rotate(360deg);\n  }\n"])));
var dash = keyframes(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  0% {\n    stroke-dasharray: 1, 150;\n    stroke-dashoffset: 0;\n  }\n  50% {\n    stroke-dasharray: 90, 150;\n    stroke-dashoffset: -35;\n  }\n  100% {\n    stroke-dasharray: 90, 150;\n    stroke-dashoffset: -124;\n  }\n"], ["\n  0% {\n    stroke-dasharray: 1, 150;\n    stroke-dashoffset: 0;\n  }\n  50% {\n    stroke-dasharray: 90, 150;\n    stroke-dashoffset: -35;\n  }\n  100% {\n    stroke-dasharray: 90, 150;\n    stroke-dashoffset: -124;\n  }\n"])));
var SpinnerContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  position: relative;\n  height: 100%;\n  width: 100%;\n  z-index: 5;\n  margin: 0;\n  min-height: 50px;\n\n  .spinner {\n    position: absolute;\n    animation: ", " 2s linear infinite;\n    z-index: 2;\n    top: 50%;\n    left: 50%;\n    margin: -25px 0 0 -25px;\n    width: 50px;\n    height: 50px;\n\n    & .path {\n      stroke: #1739a5;\n      stroke-linecap: round;\n      animation: ", " 1.5s ease-in-out infinite;\n    }\n  }\n"], ["\n  position: relative;\n  height: 100%;\n  width: 100%;\n  z-index: 5;\n  margin: 0;\n  min-height: 50px;\n\n  .spinner {\n    position: absolute;\n    animation: ", " 2s linear infinite;\n    z-index: 2;\n    top: 50%;\n    left: 50%;\n    margin: -25px 0 0 -25px;\n    width: 50px;\n    height: 50px;\n\n    & .path {\n      stroke: #1739a5;\n      stroke-linecap: round;\n      animation: ", " 1.5s ease-in-out infinite;\n    }\n  }\n"])), rotate, dash);
var Spinner = function (_a) {
    var _b = _a.className, className = _b === void 0 ? '' : _b, _c = _a.strokeWidth, strokeWidth = _c === void 0 ? 7 : _c;
    return (React.createElement(SpinnerContainer, { className: className, "data-testid": "spinner", "aria-label": "Loading", "aria-busy": "true", "aria-live": "polite", role: "status" },
        React.createElement("svg", { className: "spinner", viewBox: "0 0 50 50" },
            React.createElement("circle", { className: "path", cx: "25", cy: "25", r: "20", fill: "none", strokeWidth: strokeWidth }))));
};
export default Spinner;
var templateObject_1, templateObject_2, templateObject_3;
