import "core-js/modules/es.symbol";
import "core-js/modules/es.array.filter";
import "core-js/modules/es.object.get-own-property-descriptor";
import "core-js/modules/es.object.get-own-property-descriptors";
import "core-js/modules/es.object.keys";
import "core-js/modules/web.dom-collections.for-each";
import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import PropTypes from 'prop-types';
import { PostbackActionEffect, ChatType } from '@src/typings/enums';
/*
  MESSAGE
*/

var messageStructShape = {
  message: PropTypes.object.isRequired,
  limits: PropTypes.object.isRequired,
  chatbotId: PropTypes.number.isRequired,
  scenarioId: PropTypes.number,
  schema: PropTypes.object,
  readOnly: PropTypes.bool
};

function MessageStruct(_ref) {
  var message = _ref.message,
      schema = _ref.schema,
      limits = _ref.limits,
      chatbotId = _ref.chatbotId,
      scenarioId = _ref.scenarioId,
      _ref$readOnly = _ref.readOnly,
      readOnly = _ref$readOnly === void 0 ? false : _ref$readOnly;
  // Editable components require a schema
  return {
    message: message,
    schema: schema,
    limits: limits,
    chatbotId: chatbotId,
    scenarioId: scenarioId,
    readOnly: readOnly
  };
}

var messageInterfaceShape = {
  onRemove: PropTypes.oneOfType([PropTypes.func, PropTypes.bool])
};

function MessageInterface(_ref2) {
  var onRemove = _ref2.onRemove;
  return {
    // @params: chatbotId, scenarioId, messageId
    onRemove: onRemove
  };
}
/*
  TEXT
*/


var textMessageStructShape = _objectSpread({}, messageStructShape);

function TextMessageStruct(messageStruct) {
  return _objectSpread({}, messageStruct);
}

var textMessageInterfaceShape = _objectSpread({}, messageInterfaceShape, {
  onChange: PropTypes.func
});

function TextMessageInterface(_ref3) {
  var messageInterface = _ref3.messageInterface,
      onChange = _ref3.onChange;
  return _objectSpread({}, messageInterface, {
    // @params: chatbotId, scenarioId, messageId, text
    onChange: onChange
  });
}
/*
  GENERIC
*/


var genericMessageStructShape = _objectSpread({}, messageStructShape, {
  scenarios: PropTypes.object,
  layers: PropTypes.object,
  blocks: PropTypes.object
});

function GenericMessageStruct(_ref4) {
  var messageStruct = _ref4.messageStruct,
      scenarios = _ref4.scenarios,
      layers = _ref4.layers,
      blocks = _ref4.blocks;
  return _objectSpread({}, messageStruct, {
    scenarios: scenarios,
    layers: layers,
    blocks: blocks
  });
}

var genericMessageInterfaceShape = _objectSpread({}, messageInterfaceShape, {
  onUpdate: PropTypes.func.isRequired,
  onUpdateArticle: PropTypes.func.isRequired,
  onAddItem: PropTypes.func.isRequired,
  onRemoveItem: PropTypes.func.isRequired,
  onAddTag: PropTypes.func,
  onRemoveTag: PropTypes.func
});

function GenericMessageInterface(_ref5) {
  var messageInterface = _ref5.messageInterface,
      onUpdate = _ref5.onUpdate,
      onAddItem = _ref5.onAddItem,
      onRemoveItem = _ref5.onRemoveItem,
      onUpdateArticle = _ref5.onUpdateArticle,
      onAddTag = _ref5.onAddTag,
      onRemoveTag = _ref5.onRemoveTag;
  return _objectSpread({}, messageInterface, {
    onUpdate: onUpdate,
    // @params: chatbotId, scenarioId, messageId, messageType
    onAddItem: onAddItem,
    // @params: chatbotId, scenarioId, messageId, messageType, itemId
    onRemoveItem: onRemoveItem,
    // @params: name, value, messageId, messageType, itemId, articleId, formData, scrapingChecked,
    onUpdateArticle: onUpdateArticle,
    onAddTag: onAddTag,
    onRemoveTag: onRemoveTag
  });
}

var genericItemStructShape = {
  messageId: PropTypes.number.isRequired,
  messageType: PropTypes.string,
  item: PropTypes.object,
  iterator: PropTypes.number,
  scrapingChecked: PropTypes.bool
};

function GenericItemStruct(_ref6) {
  var messageId = _ref6.messageId,
      item = _ref6.item,
      iterator = _ref6.iterator,
      scrapingChecked = _ref6.scrapingChecked;
  return {
    messageId: messageId,
    item: item,
    iterator: iterator,
    messageType: 'generic',
    scrapingChecked: scrapingChecked
  };
}

var genericItemInterfaceShape = {
  onUpdateArticle: PropTypes.func.isRequired,
  onAddTag: PropTypes.func,
  onRemoveTag: PropTypes.func
};

function GenericItemInterface(_ref7) {
  var genericMessageInterface = _ref7.genericMessageInterface,
      onUpdateArticle = _ref7.onUpdateArticle,
      onAddTag = _ref7.onAddTag,
      onRemoveTag = _ref7.onRemoveTag;
  return {
    onUpdateArticle: onUpdateArticle || genericMessageInterface && genericMessageInterface.onUpdateArticle,
    // @params: chatbotId, scenarioId, messageId, itemId, messageType, name, errMsg,
    onAddTag: onAddTag || genericMessageInterface && genericMessageInterface.onAddTag,
    onRemoveTag: onRemoveTag || genericMessageInterface && genericMessageInterface.onRemoveTag
  };
}
/*
  ARTICLE
*/


var articleStructShape = {
  messageId: PropTypes.number.isRequired,
  item: PropTypes.object.isRequired,
  scrapingChecked: PropTypes.bool,
  messageType: PropTypes.string
};

function ArticleStruct(_ref8) {
  var messageId = _ref8.messageId,
      item = _ref8.item,
      scrapingChecked = _ref8.scrapingChecked,
      messageType = _ref8.messageType;
  return {
    messageId: messageId,
    item: item,
    scrapingChecked: scrapingChecked,
    messageType: messageType
  };
}

var articleInterfaceShape = {
  onUpdate: PropTypes.func
};

function ArticleInterface(_ref9) {
  var onChange = _ref9.onChange;
  return {
    onChange: onChange
  };
}
/*
  BUTTON
*/


var buttonTemplateStructShape = {
  buttonTemplate: PropTypes.object,
  activeBlocks: PropTypes.array,
  forms: PropTypes.array,
  patterns: PropTypes.array,
  scenarios: PropTypes.object,
  layers: PropTypes.object,
  blocks: PropTypes.object,
  messageType: PropTypes.string
};

function ButtonTemplateStruct(_ref10) {
  var buttonTemplate = _ref10.buttonTemplate,
      messageType = _ref10.messageType,
      activeBlocks = _ref10.activeBlocks,
      layers = _ref10.layers,
      blocks = _ref10.blocks,
      scenarios = _ref10.scenarios;
  if (!scenarios || !layers || !blocks) return false;
  return {
    buttonTemplate: buttonTemplate,
    scenarios: scenarios,
    layers: layers,
    blocks: blocks,
    messageType: messageType,
    activeBlocks: activeBlocks
  };
}

var buttonTemplateInterfaceShape = {};

var ButtonTemplateInterface = function ButtonTemplateInterface() {
  return {};
};

var buttonItemStructShape = {
  buttonItem: PropTypes.object.isRequired
};

var ButtonItemStruct = function ButtonItemStruct(_ref11) {
  var buttonItem = _ref11.buttonItem;
  return {
    buttonItem: buttonItem
  };
};

var buttonItemInterfaceShape = {
  // @params chatbotId, scenarioId, messageId, itemId, buttonItemId, (needs to have 'message_type')
  onRemove: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onAttachBlock: PropTypes.func.isRequired
};

var ButtonItemInterface = function ButtonItemInterface(_ref12) {
  var onAdd = _ref12.onAdd,
      onUpdate = _ref12.onUpdate,
      onRemove = _ref12.onRemove,
      onAttachBlock = _ref12.onAttachBlock;
  return {
    onAdd: onAdd,
    // @params: chatbotId, scenarioId, messageId, buttonItemId
    onUpdate: onUpdate,
    // @params: chatbotId, scenarioId, messageId, buttonItemId
    onRemove: onRemove,
    onAttachBlock: onAttachBlock
  };
};
/*
  MEDIA
*/


var mediaMessageStructShape = _objectSpread({}, messageStructShape);

function MediaMessageStruct(messageStruct) {
  return _objectSpread({}, messageStruct);
}

var mediaMessageInterfaceShape = _objectSpread({}, messageInterfaceShape, {
  onChange: PropTypes.func
});

function MediaMessageInterface(_ref13) {
  var messageInterface = _ref13.messageInterface,
      onChange = _ref13.onChange;
  return _objectSpread({}, messageInterface, {
    // @params: chatbotId, scenarioId, mediumId, mediumObject: { type (e.g. 'image'), url }, formData
    onChange: onChange
  });
}
/*
  QUICK REPLY
*/


var quickReplyInterfaceShape = {
  // @params chatbotId, scenarioId, messageId, quickReplyId, update: { key: '', value: ''}
  onChange: PropTypes.func,
  // @params chatbotId, scenarioId, messageId, quickReplyId
  onRemove: PropTypes.func,
  // @params chatbotId, scenarioId, messageId, quickReplyId
  onAddChoice: PropTypes.func,
  // @params chatbotId, scenarioId, messageId, quickReplyId, quickReplyChoiceId, name
  onUpdateChoice: PropTypes.func,
  // @params chatbotId, scenarioId, messageId, quickReplyId, quickReplyChoiceId
  onRemoveChoice: PropTypes.func
};

function QuickReplyInterface(_ref14) {
  var onChange = _ref14.onChange,
      onRemove = _ref14.onRemove,
      onAddChoice = _ref14.onAddChoice,
      onUpdateChoice = _ref14.onUpdateChoice,
      onRemoveChoice = _ref14.onRemoveChoice,
      onConnectChoice = _ref14.onConnectChoice,
      onCreateBlock = _ref14.onCreateBlock;
  return {
    onChange: onChange,
    onRemove: onRemove,
    onAddChoice: onAddChoice,
    onUpdateChoice: onUpdateChoice,
    onRemoveChoice: onRemoveChoice,
    onConnectChoice: onConnectChoice,
    onCreateBlock: onCreateBlock
  };
}

var QuickReplyChoiceType = {
  id: PropTypes.number,
  name: PropTypes.string,
  rank: PropTypes.number,
  block_name: PropTypes.string,
  block_id: PropTypes.number,
  choice_id: PropTypes.number,
  user_tag: PropTypes.UserTag,
  selectable_option_id: PropTypes.number,
  selectable_option_label: PropTypes.string
};
var QuickReplyType = {
  id: PropTypes.number,
  label: PropTypes.string,
  quick_reply_choices: PropTypes.arrayOf(PropTypes.shape(QuickReplyChoiceType)),
  user_tag_group: PropTypes.UserTagGroup,
  field_id: PropTypes.string
};
/*
  TAG
*/

var UserTag = {
  id: PropTypes.number,
  name: PropTypes.string,
  label: PropTypes.string,
  attribute_question_id: PropTypes.number,
  selectedUserTags: PropTypes.arrayOf(PropTypes.number)
};
var UserTagGroup = {
  id: PropTypes.number,
  label: PropTypes.string,
  user_tags: PropTypes.arrayOf(PropTypes.shape(UserTag))
};
/*
  MODAL
*/

var modalInterfaceShape = {
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func
};

function ModalInterface(_ref15) {
  var onCancel = _ref15.onCancel,
      onConfirm = _ref15.onConfirm;
  return {
    onCancel: onCancel,
    onConfirm: onConfirm
  };
}
/*
  MISC
*/


var Form = {
  id: PropTypes.number,
  chat_type: PropTypes.string,
  name: PropTypes.string
};
var Pattern = {
  id: PropTypes.number,
  name: PropTypes.string,
  chat_type: PropTypes.string
};
var Layer = {
  id: PropTypes.number,
  rank: PropTypes.number,
  blocks: PropTypes.arrayOf(PropTypes.number),
  isExpanded: PropTypes.bool
};
var PostbackActionType = {
  id: PropTypes.number,
  scenario_id: PropTypes.number,
  scenario_name: PropTypes.string,
  chatbot_id: PropTypes.number,
  message_id: PropTypes.number,
  block_id: PropTypes.number,
  effect: PropTypes.oneOf(Object.keys(PostbackActionEffect)),
  url: PropTypes.string,
  rich_menu_id: PropTypes.number,
  parent_block_id: PropTypes.number
};
var TalkTriggerType = {
  id: PropTypes.number,
  postback_action: PropTypes.shape(PostbackActionType),
  field_id: PropTypes.string
};
var Message = {
  block_id: PropTypes.number,
  button_template: PropTypes.object,
  displayed: PropTypes.bool,
  id: PropTypes.number,
  message_section_id: PropTypes.objenumberct,
  rank: PropTypes.number,
  scenario_id: PropTypes.number,
  text: PropTypes.string
};
var MessageSection = {
  id: PropTypes.number,
  rank: PropTypes.number,
  default_message_id: PropTypes.number,
  default_message: PropTypes.shape(Message),
  messages: PropTypes.arrayOf(PropTypes.shape(Message))
};
var ActiveBlock = {
  id: PropTypes.number,
  message_sections: PropTypes.array,
  name: PropTypes.string,
  quick_reply: PropTypes.object
};
var Block = {
  id: PropTypes.number,
  name: PropTypes.string,
  quick_reply: PropTypes.shape(QuickReplyType),
  talk_trigger: PropTypes.shape(TalkTriggerType),
  message_sections: PropTypes.arrayOf(PropTypes.shape(MessageSection)),
  scenario_id: PropTypes.number,
  first_message_id: PropTypes.number,
  created_at: PropTypes.string,
  updated_at: PropTypes.string
};
var ScenarioDelivery = {
  id: PropTypes.number,
  scenario_id: PropTypes.number,
  scheduled_at: PropTypes.string,
  delivered: PropTypes.boolean,
  receiver_count: PropTypes.number,
  delivering: PropTypes.number,
  filter_id: PropTypes.number
};
var Scenario = {
  id: PropTypes.number,
  chatbot_id: PropTypes.number,
  filter_id: PropTypes.number,
  name: PropTypes.string,
  draft: PropTypes.boolean,
  first_message_id: PropTypes.number,
  chat_type: PropTypes.oneOf(Object.keys(ChatType)),
  testing: PropTypes.boolean,
  main_setting: PropTypes.boolean,
  undefined_blocks: PropTypes.arrayOf(PropTypes.shape(Block)),
  scenario_delivery: PropTypes.ScenarioDelivery,
  layers: PropTypes.arrayOf(PropTypes.shape(Layer))
};
var ImageMapAreaType = {
  attribute_answer_id: PropTypes.number,
  attribute_question_id: PropTypes.number,
  id: PropTypes.number,
  image_map_id: PropTypes.number,
  text: PropTypes.string,
  rank: PropTypes.number,
  label: PropTypes.string
};
var EndUser = {
  id: PropTypes.number,
  auto_reply: PropTypes.boolean,
  first_name: PropTypes.string,
  last_name: PropTypes.string,
  profile_photo_url: PropTypes.string,
  gender: PropTypes.string,
  active: PropTypes.boolean,
  important: PropTypes.boolean,
  pinned: PropTypes.boolean,
  memo: PropTypes.string,
  open_rate: PropTypes.arrayOf(PropTypes.number),
  matching_rate: PropTypes.arrayOf(PropTypes.number),
  conversion_at: PropTypes.string,
  blocked_at: PropTypes.string,
  blocked_by_persistent_menu_at: PropTypes.string,
  last_event_at: PropTypes.string,
  platform: PropTypes.string,
  page_id: PropTypes.number,
  messenger_user_id: PropTypes.number,
  line_user_id: PropTypes.number,
  instagram_user_id: PropTypes.number,
  created_at: PropTypes.string
};
var latestEventLogDatetime = {
  client_read: PropTypes.boolean,
  content: PropTypes.string,
  created_at: PropTypes.string,
  end_user_read: PropTypes.boolean,
  event_log_id: PropTypes.number,
  id: PropTypes.number
};
export {
/* Message */
messageStructShape, messageInterfaceShape, MessageStruct, MessageInterface, Message,
/* Text message */
textMessageStructShape, textMessageInterfaceShape, TextMessageStruct, TextMessageInterface,
/* Generic */
genericMessageStructShape, genericMessageInterfaceShape, GenericMessageStruct, GenericMessageInterface, genericItemStructShape, genericItemInterfaceShape, GenericItemStruct, GenericItemInterface,
/* Article */
articleStructShape, articleInterfaceShape, ArticleStruct, ArticleInterface,
/* Button */
buttonItemStructShape, buttonItemInterfaceShape, buttonTemplateStructShape, buttonTemplateInterfaceShape, ButtonItemStruct, ButtonItemInterface, ButtonTemplateStruct, ButtonTemplateInterface,
/* Media message */
mediaMessageStructShape, mediaMessageInterfaceShape, MediaMessageStruct, MediaMessageInterface,
/* Tag */
UserTag, UserTagGroup,
/* Modal */
modalInterfaceShape, ModalInterface,
/* QuickReply */
quickReplyInterfaceShape, QuickReplyInterface, QuickReplyType, QuickReplyChoiceType,
/* Misc */
EndUser, latestEventLogDatetime, Form, Pattern, Layer, PostbackActionType, TalkTriggerType, MessageSection, Block, ActiveBlock, ScenarioDelivery, Scenario, ImageMapAreaType };