var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { transitions } from '@src/lib/animations';
import { ModalPortal, DeleteIcon, Button } from '@src/components/atoms';
import { fetchEditFreeInput } from '@src/redux/fanp/actions/ad-scenario/editActions';
import buttonTypes from '@src/lib/buttonTypes';
import Modal from '@src/components/molecules/Modal';
import NextPostback from '@src/components/molecules/NextPostback';
import { getConnectedIds } from '@src/lib/adHelpers';
import { injectIntl } from 'react-intl';
import { colors } from '@src/colors';
import { useSearchFields } from '@src/components/hooks/fieldAttributes';
import { setFields } from '@src/redux/fanp/actions/fieldValue';
import QuickTriggerModal from '@src/fanp/enterprise/talk_customize/components/quick_reply/QuickTriggerModal';
import omit from 'lodash/omit';
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  width: fit-content;\n  margin-top: 40px;\n  margin-left: 24px;\n  flex-direction: column;\n"], ["\n  display: flex;\n  width: fit-content;\n  margin-top: 40px;\n  margin-left: 24px;\n  flex-direction: column;\n"])));
var TalkTriggerBaseChip = styled.span(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: inline-block;\n  padding: 11px 14px;\n  border-radius: 50px;\n  font-size: 11px;\n  font-weight: bold;\n  color: ", ";\n  background-color: ", ";\n"], ["\n  display: inline-block;\n  padding: 11px 14px;\n  border-radius: 50px;\n  font-size: 11px;\n  font-weight: bold;\n  color: ", ";\n  background-color: ", ";\n"])), colors.light_grey, colors.background.cloud_nine);
var ButtonGroup = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  width: inherit;\n"], ["\n  width: inherit;\n"])));
var Wrapper = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  width: fit-content;\n  position: relative;\n  transition: ", ";\n\n  &:hover {\n    cursor: pointer;\n\n    .adicon_delete {\n      display: block;\n    }\n    &.isOnTalkTriggerBaseChip {\n      .adicon_delete {\n        display: inline-block;\n        position: relative;\n        top: 0;\n        left: 4px;\n      }\n    }\n  }\n"], ["\n  width: fit-content;\n  position: relative;\n  transition: ", ";\n\n  &:hover {\n    cursor: pointer;\n\n    .adicon_delete {\n      display: block;\n    }\n    &.isOnTalkTriggerBaseChip {\n      .adicon_delete {\n        display: inline-block;\n        position: relative;\n        top: 0;\n        left: 4px;\n      }\n    }\n  }\n"])), transitions.normal);
var FreeInput = function (props) {
    var activeBlocks = props.activeBlocks, blocks = props.blocks, scenarios = props.scenarios, chatbotIdParam = props.chatbotIdParam, adScenarioIdParam = props.adScenarioIdParam, blockIdParam = props.blockIdParam, chatTypeParam = props.chatTypeParam, rank = props.rank, removeFreeInput = props.removeFreeInput, talkTrigger = props.talkTrigger, intl = props.intl, platform = props.platform;
    var _a = __read(useState(false), 2), showTriggerModal = _a[0], setShowTriggerModal = _a[1];
    var _b = __read(useState(false), 2), deleteFreeInputModalOpen = _b[0], setDeleteFreeInputModalOpen = _b[1];
    var _c = __read(useState(''), 2), deleteTxt = _c[0], setDeleteTxt = _c[1];
    var dispatch = useDispatch();
    var toggleTriggerModal = function () {
        setShowTriggerModal(!showTriggerModal);
    };
    var toggleDeleteFreeInputModal = function () {
        return setDeleteFreeInputModalOpen(!deleteFreeInputModalOpen);
    };
    var handleDeleteFreeInputClick = function () {
        removeFreeInput({
            chatbotId: chatbotIdParam,
            adScenarioId: adScenarioIdParam,
            talkTriggerId: talkTrigger.id,
            rank: rank,
            talkTrigger: talkTrigger,
            chatTypeParam: chatTypeParam,
            blockIdParam: blockIdParam,
        });
    };
    var updateTalkTrigger = function (_a) {
        var blockId = _a.blockId, nextScenarioId = _a.nextScenarioId;
        return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, dispatch(fetchEditFreeInput({
                            chatbotId: chatbotIdParam,
                            adScenarioId: adScenarioIdParam,
                            blockIdParam: blockIdParam,
                            talkTriggerId: talkTrigger.id,
                            nextBlockId: blockId,
                            nextScenarioId: nextScenarioId,
                            rank: rank,
                        }))];
                    case 1:
                        _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    var fieldList = useSearchFields(chatbotIdParam).fieldList;
    useEffect(function () {
        dispatch(setFields(fieldList));
    }, [fieldList]);
    var talkTriggerWithoutBlock = omit(talkTrigger, 'block');
    var isConnectedToBlock = Boolean(getConnectedIds(talkTriggerWithoutBlock).blockId);
    useEffect(function () {
        setDeleteTxt("" + (isConnectedToBlock
            ? intl.formatMessage({ id: "Ol6qif", defaultMessage: "The block\u2019s connection will be disconnected. Are you sure you want to delete?" })
            : intl.formatMessage({ id: "y7o4Rn", defaultMessage: "Are you sure you want to delete?" })));
    }, [deleteFreeInputModalOpen]);
    return (React.createElement(Container, { "data-testid": "FreeInput_Container" },
        showTriggerModal ? (React.createElement(QuickTriggerModal, __assign({}, {
            intl: intl,
            platform: platform,
            activeBlocks: activeBlocks,
            chatbotIdParam: chatbotIdParam,
            adScenarioIdParam: adScenarioIdParam,
            blockIdParam: blockIdParam,
            rank: rank,
        }, { onCancel: function () { return toggleTriggerModal(); }, connectToBlock: updateTalkTrigger, talkTrigger: talkTriggerWithoutBlock }))) : null,
        deleteFreeInputModalOpen ? (React.createElement(ModalPortal, null,
            React.createElement(Modal, { "data-testid": "free-input-modal", type: "DELETE", onConfirm: handleDeleteFreeInputClick, onCancel: toggleDeleteFreeInputModal, text: deleteTxt }))) : null,
        React.createElement(Wrapper, { "data-testid": "FreeInput_FreeInputWrapper", className: "free_input_wrapper + " + (talkTrigger.field_id
                ? 'isOnTalkTriggerBaseChip isFreeInputFromField'
                : ''), onClick: function () {
                toggleTriggerModal();
            } },
            talkTrigger.field_id && (React.createElement(TalkTriggerBaseChip, null, fieldList === null || fieldList === void 0 ? void 0 : fieldList.find(function (x) { return x.id === talkTrigger.field_id; }).name)),
            React.createElement(DeleteIcon, { onClick: function (e) {
                    e.stopPropagation();
                    setDeleteFreeInputModalOpen(!deleteFreeInputModalOpen);
                } }),
            React.createElement(ButtonGroup, null,
                React.createElement(Button, { buttonType: buttonTypes.SETTING, text: intl.formatMessage({ id: "j5BleN", defaultMessage: "User Free Input" }), width: 160, margin: "12px 0", fontSize: "14px" }),
                React.createElement(NextPostback, { connectedObject: talkTriggerWithoutBlock, scenarios: scenarios, blocks: Object.values(blocks) })))));
};
export default injectIntl(FreeInput);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
