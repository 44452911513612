import "core-js/modules/es.array.concat";
import "core-js/modules/es.array.filter";
import "core-js/modules/es.array.join";
import "core-js/modules/es.array.map";
import "core-js/modules/es.object.keys";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import _typeof from "@babel/runtime/helpers/typeof";

function encodeKeyValue(key, value) {
  return [encodeURIComponent(key), encodeURIComponent(value)];
}

export default (function (obj) {
  if (!obj || _typeof(obj) !== 'object') return '';
  var keys = Object.keys(obj);
  if (!keys.length) return '';
  return keys.filter(function (k) {
    return obj[k];
  }).map(function (k) {
    if (Array.isArray(obj[k])) {
      return obj[k].filter(Boolean).map(function (value) {
        var _encodeKeyValue = encodeKeyValue(k, value),
            _encodeKeyValue2 = _slicedToArray(_encodeKeyValue, 2),
            key = _encodeKeyValue2[0],
            encodedValue = _encodeKeyValue2[1];

        return "".concat(key, "[]=").concat(encodedValue);
      }).join('&');
    }

    var _encodeKeyValue3 = encodeKeyValue(k, obj[k]),
        _encodeKeyValue4 = _slicedToArray(_encodeKeyValue3, 2),
        key = _encodeKeyValue4[0],
        value = _encodeKeyValue4[1];

    return "".concat(key, "=").concat(value);
  }).join('&');
});