var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import { getFlex, aspectRatioToPercent } from '@src/lib/helpers';
import { lineFlexMessageStyles } from '@src/lib/app-constants';
var ImageContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  flex-direction: column;\n  width: 100%;\n  position: relative;\n  display: inherit;\n  flex: ", ";\n  margin-left: ", ";\n  margin-top: ", ";\n  padding-bottom: ", ";\n\n  > span {\n    position: absolute;\n    display: block;\n    left: 0;\n    right: 0;\n    top: 0;\n    bottom: 0;\n    background-repeat: no-repeat;\n    background-position: center center;\n    background-size: cover;\n    background-image: url(", ");\n  }\n"], ["\n  display: flex;\n  align-items: center;\n  flex-direction: column;\n  width: 100%;\n  position: relative;\n  display: inherit;\n  flex: ", ";\n  margin-left: ",
    ";\n  margin-top: ",
    ";\n  padding-bottom: ", ";\n\n  > span {\n    position: absolute;\n    display: block;\n    left: 0;\n    right: 0;\n    top: 0;\n    bottom: 0;\n    background-repeat: no-repeat;\n    background-position: center center;\n    background-size: cover;\n    background-image: url(", ");\n  }\n"])), function (props) { return getFlex(props.flex, props.parentLayout); }, function (props) {
    return props.parentLayout === 'vertical'
        ? 'unset'
        : lineFlexMessageStyles.margins[props.margin];
}, function (props) {
    return props.parentLayout === 'vertical'
        ? lineFlexMessageStyles.margins[props.margin]
        : 'unset';
}, function (props) { return aspectRatioToPercent(props.aspectRatio); }, function (props) { return props.url; });
var ImageNode = function (_a) {
    var lineImage = _a.lineImage, parentLayout = _a.parentLayout;
    return (React.createElement(ImageContainer, { flex: lineImage.flex, margin: lineImage.margin, url: lineImage.proxy_image_url || lineImage.url, aspectRatio: lineImage.aspectRatio, parentLayout: parentLayout, "data-testid": "ImageNode_ImageContainer" },
        React.createElement("span", null)));
};
export default ImageNode;
var templateObject_1;
