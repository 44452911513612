import React from 'react';
import { ModalPortal } from '@src/components/atoms';
import { Modal } from '@src/components/molecules';
import modalTypes from '@src/lib/modalTypes';
var CustomizedAlert = function (_a) {
    var alertMessage = _a.alertMessage, buttonText = _a.buttonText, onCancel = _a.onCancel, onConfirm = _a.onConfirm;
    return (React.createElement(ModalPortal, null,
        React.createElement(Modal, { "data-testid": "customized-alert-modal", text: alertMessage, type: modalTypes.CUSTOM, onCancel: onCancel, onConfirm: onConfirm, btnText: buttonText ? buttonText : '編集' })));
};
export default CustomizedAlert;
