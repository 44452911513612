import "core-js/modules/es.symbol";
import "core-js/modules/es.array.concat";
import "core-js/modules/es.array.filter";
import "core-js/modules/es.object.get-own-property-descriptor";
import "core-js/modules/es.object.get-own-property-descriptors";
import "core-js/modules/es.object.keys";
import "core-js/modules/web.dom-collections.for-each";
import _extends from "@babel/runtime/helpers/extends";
import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import React, { Fragment } from 'react';
import styled from 'styled-components';
import { colors } from '@src/colors';
import { transitions } from '@src/lib/animations';
import { Spinner, DefaultThumbnail } from '@src/components/atoms';
import { mediaTypes } from '@src/lib/app-constants';
import { injectIntl } from 'react-intl';
import UploadEditOptions from './UploadEditOptions';
var ThumbnailWrapper = styled.div.withConfig({
  displayName: "ThumbnailWrapper",
  componentId: "sc-gu4wy0-0"
})(["background:url(", ");background-position:center;background-size:cover;background-repeat:no-repeat;height:", ";border-radius:", ";overflow:auto;text-align:center;transition:", ";> div{height:100%;}&:hover{.default-thumb{display:none;}.no-file-uploaded,.file-uploaded-options{display:flex;background:", ";}}"], function (props) {
  return props.thumbnail ? props.thumbnail : 'none';
}, function (props) {
  return props.thumbnailHeight ? "".concat(props.thumbnailHeight, "px") : '135px';
}, function (props) {
  return props.borderRadius ? props.borderRadius : 0;
}, transitions.normal, colors.background.help);
ThumbnailWrapper.displayName = 'ThumbnailWrapper'; // Creates the UI depending on if there's an image_url passed in or not

var FileUploadUI = function FileUploadUI(_ref) {
  var imagePreviewUrl = _ref.imagePreviewUrl,
      itemIterator = _ref.itemIterator,
      readOnly = _ref.readOnly,
      thumbnailHeight = _ref.thumbnailHeight,
      _ref$text = _ref.text,
      text = _ref$text === void 0 ? '画像' : _ref$text,
      _ref$mediaType = _ref.mediaType,
      mediaType = _ref$mediaType === void 0 ? mediaTypes.IMAGE : _ref$mediaType,
      isImageLoading = _ref.isImageLoading,
      intl = _ref.intl,
      restOfProps = _objectWithoutProperties(_ref, ["imagePreviewUrl", "itemIterator", "readOnly", "thumbnailHeight", "text", "mediaType", "isImageLoading", "intl"]);

  var omit = function omit(obj, uselessKeys) {
    return uselessKeys.reduce(function (acc, key) {
      return _objectSpread({}, acc, _defineProperty({}, key, undefined));
    }, obj);
  };

  var otherProps = _objectSpread({}, restOfProps);

  var handledProps = omit(otherProps, ['onDeleteClick']);
  return isImageLoading ? React.createElement(ThumbnailWrapper, {
    thumbnail: imagePreviewUrl,
    borderRadius: itemIterator === 0 ? '16px 4px 0 0' : '4px 4px 0 0',
    className: "file-upload-container",
    thumbnailHeight: thumbnailHeight
  }, React.createElement(Spinner, {
    spinnerOnly: true,
    wide: true
  })) : React.createElement(ThumbnailWrapper, {
    thumbnail: imagePreviewUrl,
    borderRadius: itemIterator === 0 ? '16px 4px 0 0' : '4px 4px 0 0',
    className: "file-upload-container",
    thumbnailHeight: thumbnailHeight
  }, !readOnly && React.createElement(Fragment, null, !imagePreviewUrl && React.createElement(DefaultThumbnail, {
    text: "".concat(text, " ").concat(intl.formatMessage({
      id: "XKyo5X",
      defaultMessage: [{
        "type": 0,
        "value": "File Upload"
      }]
    })),
    intl: intl,
    mediaType: mediaType
  }), imagePreviewUrl ? React.createElement(UploadEditOptions, _extends({}, restOfProps, {
    type: mediaType
  })) : React.createElement(UploadEditOptions, _extends({
    type: mediaType
  }, handledProps))));
};

export default injectIntl(FileUploadUI);