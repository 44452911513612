var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import { colors } from '@src/colors';
import { ModalPortal } from '@src/components/atoms';
import { Modal } from '@src/components/molecules';
import { columnFlex } from '@src/lib/mixins';
import { injectIntl } from 'react-intl';
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin-top: 24px;\n"], ["\n  margin-top: 24px;\n"])));
var FlexMessageModalWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  .modal-msg {\n    color: ", " !important;\n  }\n\n  .button-set-btn-confirm {\n    min-width: 76px;\n  }\n"], ["\n  .modal-msg {\n    color: ", " !important;\n  }\n\n  .button-set-btn-confirm {\n    min-width: 76px;\n  }\n"])), colors.main_color);
var FlexMessageContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  margin-top: 12px;\n  ", ";\n  color: ", ";\n  align-content: center;\n  justify-content: center;\n  text-align: center;\n  textarea {\n    height: 200px;\n    width: 100%;\n    border-radius: 5px;\n    border: 1px solid ", ";\n    font-size: 1rem;\n    padding: 1rem;\n    ::placeholder {\n      opacity: 20%;\n    }\n  }\n  div {\n    margin-top: 4px;\n    font-size: 12px;\n    font-weight: bold;\n  }\n  .message-warning {\n    color: ", ";\n  }\n"], ["\n  margin-top: 12px;\n  ", ";\n  color: ", ";\n  align-content: center;\n  justify-content: center;\n  text-align: center;\n  textarea {\n    height: 200px;\n    width: 100%;\n    border-radius: 5px;\n    border: 1px solid ", ";\n    font-size: 1rem;\n    padding: 1rem;\n    ::placeholder {\n      opacity: 20%;\n    }\n  }\n  div {\n    margin-top: 4px;\n    font-size: 12px;\n    font-weight: bold;\n  }\n  .message-warning {\n    color: ", ";\n  }\n"])), columnFlex, colors.default_text, colors.border.default, colors.error);
var FlexMessageModal = /** @class */ (function (_super) {
    __extends(FlexMessageModal, _super);
    function FlexMessageModal() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            userJson: '',
            isJsonValid: false,
        };
        _this.checkForValidJSON = function (jsonStr) {
            try {
                JSON.parse(jsonStr);
            }
            catch (e) {
                return _this.setState({ isJsonValid: false });
            }
            return _this.setState({ isJsonValid: true });
        };
        _this.updateJsonTextField = function (e) {
            _this.setState({ userJson: e.target.value }, function () {
                _this.checkForValidJSON(_this.state.userJson);
            });
        };
        return _this;
    }
    FlexMessageModal.prototype.render = function () {
        var _this = this;
        var _a = this.state, isJsonValid = _a.isJsonValid, userJson = _a.userJson;
        var _b = this.props, onConfirm = _b.onConfirm, onCancel = _b.onCancel, title = _b.title, label = _b.label, placeholder = _b.placeholder, type = _b.type, intl = _b.intl;
        return (React.createElement(ModalPortal, null,
            React.createElement(FlexMessageModalWrapper, null,
                React.createElement(Modal, __assign({ "data-testid": "flex-message-modal", type: type, text: title, width: "50%", maxWidth: "1000px", overFlow: true }, this.props, { preventOnCancel: true, onCancel: onCancel, onConfirm: function () {
                        if (isJsonValid)
                            onConfirm(userJson);
                    }, hasError: !isJsonValid, headingElement: React.createElement(Container, null,
                        React.createElement("div", { className: "message-wrapper primary-label" }, label),
                        React.createElement(Fragment, null,
                            React.createElement(FlexMessageContainer, null,
                                React.createElement("textarea", { placeholder: placeholder, onChange: function (e) { return _this.updateJsonTextField(e); } }),
                                React.createElement("div", { className: "" + (!isJsonValid ? 'message-warning' : '') }, isJsonValid
                                    ? intl.formatMessage({ id: "JA+eCy", defaultMessage: "JSON is valid" })
                                    : intl.formatMessage({ id: "xyZ5I1", defaultMessage: "JSON is not valid" }))))) })))));
    };
    return FlexMessageModal;
}(Component));
export default injectIntl(FlexMessageModal);
var templateObject_1, templateObject_2, templateObject_3;
