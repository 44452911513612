var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import Moment from 'moment';
import styled from 'styled-components';
import { colors } from '@src/colors';
import { deliveryTypes } from '@src/lib/app-constants';
var DeliverySectionContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin: ", ";\n  font-size: 0.8rem;\n  > .endUser {\n    margin-bottom: 8px;\n    text-align: right;\n  }\n\n  > .bottom {\n    display: flex;\n    width: 100%;\n    justify-content: flex-start;\n    ", "\n\n    .status {\n      flex: 1;\n      text-align: left;\n    }\n\n    .date {\n      flex: 1;\n      text-align: right;\n      margin-right: 8px;\n    }\n\n    .stepTime {\n      flex: 1;\n      text-align: right;\n    }\n  }\n"], ["\n  margin: ", ";\n  font-size: 0.8rem;\n  > .endUser {\n    margin-bottom: 8px;\n    text-align: right;\n  }\n\n  > .bottom {\n    display: flex;\n    width: 100%;\n    justify-content: flex-start;\n    ", "\n\n    .status {\n      flex: 1;\n      text-align: left;\n    }\n\n    .date {\n      flex: 1;\n      text-align: right;\n      margin-right: 8px;\n    }\n\n    .stepTime {\n      flex: 1;\n      text-align: right;\n    }\n  }\n"])), function (props) { return (props.isShotPage ? '8px' : '8px 8px 0'); }, function (props) { return (props.isShotPage && !props.color ? 'height: 0;' : ''); });
var StatusMark = styled.span(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: inline-block;\n  font-size: 0.75rem;\n  color: ", ";\n  vertical-align: baseline;\n\n  &::before {\n    content: '';\n    display: inline-block;\n    width: 8px;\n    height: 8px;\n    border-radius: 4px;\n    margin: 0 8px 0 0;\n    background: ", ";\n  }\n"], ["\n  display: inline-block;\n  font-size: 0.75rem;\n  color: ", ";\n  vertical-align: baseline;\n\n  &::before {\n    content: '';\n    display: inline-block;\n    width: 8px;\n    height: 8px;\n    border-radius: 4px;\n    margin: 0 8px 0 0;\n    background: ",
    ";\n  }\n"])), colors.text_pale, function (props) {
    return props.color ? colors.label[props.color] : 'none';
});
var DeliverySection = function (_a) {
    var color = _a.color, isShotPage = _a.isShotPage, isStepPage = _a.isStepPage, scenario_delivery = _a.scenario_delivery, status = _a.status, campaign_content = _a.campaign_content;
    var statusStr;
    if (isShotPage) {
        switch (status) {
            case deliveryTypes.AWAITING:
                statusStr = '配信待ち';
                break;
            case deliveryTypes.DELIVERED:
                statusStr = '配信済み';
                break;
            default:
                statusStr = '';
        }
    }
    else {
        statusStr = color ? '流入中' : '';
    }
    return (React.createElement(DeliverySectionContainer, { isShotPage: isShotPage, color: color },
        React.createElement("div", { className: "bottom" },
            React.createElement("span", { className: "status" },
                React.createElement(StatusMark, { color: color }),
                statusStr),
            isShotPage && !!statusStr ? (React.createElement("span", null,
                React.createElement("span", { className: "date" }, scenario_delivery
                    ? Moment(scenario_delivery.scheduled_at).format('YYYY/MM/DD')
                    : ''),
                React.createElement("span", { className: "time" }, scenario_delivery
                    ? Moment(scenario_delivery.scheduled_at).format('HH:mm')
                    : ''))) : null,
            campaign_content && isStepPage ? (React.createElement("span", { className: "stepTime" },
                campaign_content.days_after,
                "\u65E5\u5F8C ",
                Moment(campaign_content.delivery_time).format('HH:mm'))) : null)));
};
export default DeliverySection;
var templateObject_1, templateObject_2;
