var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ReactFlow, { MiniMap, Controls, ReactFlowProvider,
// eslint-disable-next-line import/no-unresolved
 } from 'react-flow-renderer';
import styled, { css } from 'styled-components';
import isEmpty from 'lodash/isEmpty';
import { colors } from '@src/colors';
import { objToQuery, buildElements, draftRendering, removeDuplicateBlocks, convertToScenarioData, copyToClipboard, getLineConnectionIds, } from '@src/lib/helpers';
import * as scenarioApi from '@src/services/api/scenario';
import html2PDF from 'jspdf-html2canvas';
import ScenarioNode from '@src/components/molecules/scenario_map_nodes/ScenarioNode';
import ConnectedOtherScenarioNode from '@src/components/molecules/scenario_map_nodes/ConnectedOtherScenarioNode';
import LayerNode from '@src/components/molecules/scenario_map_nodes/LayerNode';
import BlockNode from '@src/components/molecules/scenario_map_nodes/BlockNode';
import PostbackActionNode from '@src/components/molecules/scenario_map_nodes/PostbackActionNode';
import { handleChangeAlertMessage } from '@src/redux/fanp/actions/scenarioMap';
import { Switch, Spinner, AlertMessage } from '@src/components/atoms';
import { useHOCWithRouterLocation } from '@src/components/hoc/HOCWithRouter';
var styledHoveredPostbackAction = function (hoveredPostbackAction) {
    if (!hoveredPostbackAction)
        return null;
    var hoverColor = colors.border.green;
    var hoverTargetClassName = hoveredPostbackAction.block_id
        ? ".block-" + hoveredPostbackAction.block_id + " > div:last-of-type > div:last-of-type"
        : ".connected-other-scenario-" + hoveredPostbackAction.scenario_id + " > div:last-of-type";
    return css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    .react-flow__edges {\n      .edges-postback-action-", " {\n        path {\n          stroke-width: 5px;\n          stroke: ", " !important;\n          stroke-dasharray: unset;\n        }\n      }\n\n      g[class^='react-flow__edge react-flow__edge-step edges-postback-action-']:not(.edges-postback-action-", ") {\n        path {\n          animation: unset;\n        }\n      }\n    }\n\n    .react-flow__nodes {\n      .postback-action-", " {\n        > div > div {\n          border: solid 5px ", ";\n          border-radius: 10px;\n        }\n      }\n\n      ", " {\n        border: solid 5px ", ";\n      }\n    }\n  "], ["\n    .react-flow__edges {\n      .edges-postback-action-", " {\n        path {\n          stroke-width: 5px;\n          stroke: ", " !important;\n          stroke-dasharray: unset;\n        }\n      }\n\n      g[class^='react-flow__edge react-flow__edge-step edges-postback-action-']:not(.edges-postback-action-", ") {\n        path {\n          animation: unset;\n        }\n      }\n    }\n\n    .react-flow__nodes {\n      .postback-action-", " {\n        > div > div {\n          border: solid 5px ", ";\n          border-radius: 10px;\n        }\n      }\n\n      ", " {\n        border: solid 5px ", ";\n      }\n    }\n  "])), hoveredPostbackAction.id, colors.hover.red, hoveredPostbackAction.id, hoveredPostbackAction.id, hoverColor, hoverTargetClassName, hoverColor);
};
var styledHoverBlock = function (hoveredNode, transitionLines) {
    if (!hoveredNode)
        return null;
    var highlightSelectedNode = function (nodeClassName) {
        return css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n      .react-flow__nodes {\n        ", " {\n          border: solid 5px ", ";\n        }\n      }\n    "], ["\n      .react-flow__nodes {\n        ", " {\n          border: solid 5px ", ";\n        }\n      }\n    "])), nodeClassName, colors.border.green);
    };
    var hoverTargetClassName = hoveredNode.blockId
        ? ".block-" + hoveredNode.blockId + " > div:last-of-type > div:last-of-type"
        : ".connected-other-scenario-" + hoveredNode.scenarioId + " > div:last-of-type";
    if (isEmpty(transitionLines)) {
        return highlightSelectedNode(hoverTargetClassName);
    }
    var cssTransitionLines = transitionLines.map(function (transitionLine) {
        return css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n      .", " {\n        path {\n          stroke-width: 5px;\n          stroke: ", " !important;\n          stroke-dasharray: unset;\n        }\n      }\n\n      ", "\n    "], ["\n      .", " {\n        path {\n          stroke-width: 5px;\n          stroke: ", " !important;\n          stroke-dasharray: unset;\n        }\n      }\n\n      ",
            "\n    "])), transitionLine.id, colors.hover.red, highlightSelectedNode("." + transitionLine.sourceBlock + " > div:last-of-type > div:last-of-type"));
    });
    return css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    ", "\n    ", "\n  "], ["\n    ", "\n    ", "\n  "])), highlightSelectedNode(hoverTargetClassName), cssTransitionLines);
};
var styledHideContentForClient = function (hideContentForClient) {
    if (!hideContentForClient)
        return css(templateObject_5 || (templateObject_5 = __makeTemplateObject([""], [""])));
    return css(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    .node-scenario-name {\n      display: none;\n    }\n\n    .quick-reply-label {\n      background-color: white;\n      color: white;\n    }\n  "], ["\n    .node-scenario-name {\n      display: none;\n    }\n\n    .quick-reply-label {\n      background-color: white;\n      color: white;\n    }\n  "])));
};
var FlowWrapper = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  background-color: ", ";\n  min-width: ", ";\n  ", "\n  height: ", ";\n\n  ", "\n\n  .client-map-button {\n    top: 7px;\n    left: 20px;\n    z-index: 4;\n    background-color: #0501bf;\n    border-radius: 20px;\n    height: 35px;\n    color: white;\n    border: none;\n  }\n\n  .button-as-link {\n    border: none;\n    outline: none;\n    cursor: pointer;\n    padding: 11px;\n    padding-top: 7px;\n  }\n\n  .button-as-copy-url {\n    left: 135px;\n    width: 101px;\n    cursor: pointer;\n  }\n\n  .button-as-copy-id {\n    left: 240px;\n    width: 90px;\n    cursor: pointer;\n    margin-right: auto;\n  }\n\n  .button-as-client-map {\n    width: 105px;\n    padding-left: 12px;\n    padding-bottom: 6px;\n    padding-right: 11px;\n  }\n\n  .button-as-link:disabled {\n    opacity: 0.5;\n    cursor: not-allowed;\n  }\n\n  .react-flow {\n    ", "\n  }\n\n  .react-flow__nodes {\n    height: unset;\n  }\n\n  .react-flow__edges {\n    z-index: 1000;\n\n    * {\n      pointer-events: none;\n    }\n  }\n\n  .react-flow__minimap {\n    background-color: ", ";\n  }\n\n  .scenario-map-switch {\n    width: 45px;\n  }\n\n  *,\n  *:focus,\n  *:hover {\n    outline: none;\n  }\n\n  ", "\n  ", "\n  ", "\n"], ["\n  background-color: ", ";\n  min-width: ", ";\n  ",
    "\n  height: ",
    ";\n\n  ",
    "\n\n  .client-map-button {\n    top: 7px;\n    left: 20px;\n    z-index: 4;\n    background-color: #0501bf;\n    border-radius: 20px;\n    height: 35px;\n    color: white;\n    border: none;\n  }\n\n  .button-as-link {\n    border: none;\n    outline: none;\n    cursor: pointer;\n    padding: 11px;\n    padding-top: 7px;\n  }\n\n  .button-as-copy-url {\n    left: 135px;\n    width: 101px;\n    cursor: pointer;\n  }\n\n  .button-as-copy-id {\n    left: 240px;\n    width: 90px;\n    cursor: pointer;\n    margin-right: auto;\n  }\n\n  .button-as-client-map {\n    width: 105px;\n    padding-left: 12px;\n    padding-bottom: 6px;\n    padding-right: 11px;\n  }\n\n  .button-as-link:disabled {\n    opacity: 0.5;\n    cursor: not-allowed;\n  }\n\n  .react-flow {\n    ",
    "\n  }\n\n  .react-flow__nodes {\n    height: unset;\n  }\n\n  .react-flow__edges {\n    z-index: 1000;\n\n    * {\n      pointer-events: none;\n    }\n  }\n\n  .react-flow__minimap {\n    background-color: ", ";\n  }\n\n  .scenario-map-switch {\n    width: 45px;\n  }\n\n  *,\n  *:focus,\n  *:hover {\n    outline: none;\n  }\n\n  ", "\n  ", "\n  ", "\n"])), colors.background.off_white_background, function (props) { return props.minWidth; }, function (props) {
    return props.clientView
        ? "position: absolute; width: " + (props.containerWidthForPdf
            ? props.containerWidthForPdf + "px"
            : '100%') + ";"
        : 'position: relative;';
}, function (props) {
    return props.clientView && Boolean(props.containerHeightForPdf)
        ? props.containerHeightForPdf + "px"
        : '100%';
}, function (props) {
    return props.clientView &&
        "&:before {\n    position: fixed;\n    inset: 0\n    background: #f2f4f7;\n    content: '';\n  }\n\n  .scenario-map-spinner {\n    position: fixed;\n    inset: 0;\n\n    &:before {\n      position: fixed;\n      inset: 0;\n      background: rgba(255, 255, 255, 0.5);\n      content: '';\n    }\n  }";
}, function (props) {
    return props.clientView
        ? 'margin-top: 60px; height: calc(100% - 60px);'
        : 'height: calc(100% - 49px);';
}, colors.background.content, function (props) { return styledHoveredPostbackAction(props.hoveredPostbackAction); }, function (props) { return styledHoverBlock(props.hoveredNode, props.transitionLines); }, function (props) { return styledHideContentForClient(props.hideContentForClient); });
var AllMapWrapper = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  position: absolute;\n  display: flex;\n\n  .connected-other-scenarios {\n    margin-left: 50px;\n  }\n"], ["\n  position: absolute;\n  display: flex;\n\n  .connected-other-scenarios {\n    margin-left: 50px;\n  }\n"])));
var OptionButtonsWrapper = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  border-bottom: 1px solid rgba(12, 27, 108, 0.2);\n  padding: 8px;\n  ", "\n  width: 100%;\n  background-color: rgba(23, 57, 165, 0.1);\n  display: flex;\n  align-items: center;\n\n  button {\n    margin-right: 8px;\n  }\n"], ["\n  border-bottom: 1px solid rgba(12, 27, 108, 0.2);\n  padding: 8px;\n  ",
    "\n  width: 100%;\n  background-color: rgba(23, 57, 165, 0.1);\n  display: flex;\n  align-items: center;\n\n  button {\n    margin-right: 8px;\n  }\n"])), function (props) {
    return props.clientView
        ? 'position: fixed;'
        : 'position: relative; padding-bottom: 11px;';
});
var AlertMessageContainer = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  position: fixed;\n  bottom: 95px;\n  left: 47%;\n  box-shadow: 0 1px 20px rgba(0, 0, 0, 0.2);\n  z-index: 10;\n\n  .json-alert-message {\n    border-radius: 5px;\n    background: ", ";\n\n    .icon-wrapper {\n      display: none;\n    }\n\n    .message-wrapper {\n      padding: 8px 16px;\n      margin-right: 0px;\n      font-size: 12px;\n      color: white;\n    }\n  }\n"], ["\n  position: fixed;\n  bottom: 95px;\n  left: 47%;\n  box-shadow: 0 1px 20px rgba(0, 0, 0, 0.2);\n  z-index: 10;\n\n  .json-alert-message {\n    border-radius: 5px;\n    background: ", ";\n\n    .icon-wrapper {\n      display: none;\n    }\n\n    .message-wrapper {\n      padding: 8px 16px;\n      margin-right: 0px;\n      font-size: 12px;\n      color: white;\n    }\n  }\n"])), colors.success);
var ScenarioMapV2 = function (_a) {
    var _b, _c;
    var chatbotIdParam = _a.chatbotIdParam, adScenarioIdParam = _a.adScenarioIdParam, token = _a.token, clientView = _a.clientView, fetchToken = _a.fetchToken;
    var dispatch = useDispatch();
    var location = useHOCWithRouterLocation();
    // loading is condition flag, when it is true, it mean elements on creating,
    // when it is done it mean that scenario map ready to render
    var _d = __read(useState(true), 2), loading = _d[0], setLoading = _d[1];
    var _e = __read(useState(false), 2), generatingPdf = _e[0], setGeneratingPdf = _e[1];
    var _f = __read(useState(0), 2), containerWidthForPdf = _f[0], setContainerWidthForPdf = _f[1];
    var _g = __read(useState(0), 2), containerHeightForPdf = _g[0], setContainerHeightForPdf = _g[1];
    var _h = __read(useState(null), 2), scenarioData = _h[0], setScenarioData = _h[1];
    // elements is for ReactFlow data to render map
    var _j = __read(useState([]), 2), elements = _j[0], setElements = _j[1];
    var _k = __read(useState(clientView), 2), hideContentForClient = _k[0], setHideContentForClient = _k[1];
    // boundaryNodes store all boundary node of scenario, layers, blocks, messages
    var _l = __read(useState({
        scenarios: {},
        connectedOtherScenarios: {},
        layers: {},
        blocks: {},
        postbackActions: {},
    }), 2), boundaryNodes = _l[0], setBoundaryNodes = _l[1];
    var _m = __read(useState(null), 2), draftContainer = _m[0], setDraftContainer = _m[1];
    var buildBoundaryNodes = function (data) {
        var convertedData = convertToScenarioData(removeDuplicateBlocks(data));
        setScenarioData(convertedData);
        var newBoundaryNodes = {
            scenarios: {},
            connectedOtherScenarios: {},
            layers: {},
            blocks: {},
            postbackActions: {},
        };
        // This method will update ref info to local state when render all scenario map
        var updateBoundaryNode = function (type, id, boundaryNode) {
            newBoundaryNodes[type][id] = boundaryNode;
            setBoundaryNodes(newBoundaryNodes);
        };
        setDraftContainer(draftRendering(allMap(updateBoundaryNode, convertedData)));
    };
    var fetchData = function () { return __awaiter(void 0, void 0, void 0, function () {
        var data, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, scenarioApi.getScenarioMap(chatbotIdParam, adScenarioIdParam, token)];
                case 1:
                    data = _a.sent();
                    buildBoundaryNodes(data);
                    return [3 /*break*/, 3];
                case 2:
                    e_1 = _a.sent();
                    alert("\u30A8\u30E9\u30FC\u304C\u767A\u751F\u3057\u307E\u3057\u305F\n" + e_1.message);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    // Generating scenario map PDF
    var exportAsPdf = function () { return __awaiter(void 0, void 0, void 0, function () {
        var map, mapSize;
        return __generator(this, function (_a) {
            setGeneratingPdf(true);
            map = document.querySelector('.react-flow__renderer');
            mapSize = [1600, 1400];
            setContainerWidthForPdf(mapSize[0]);
            setContainerHeightForPdf(mapSize[1]);
            // timeout needs to get fitted content of the scenario map
            setTimeout(function () { return __awaiter(void 0, void 0, void 0, function () {
                var fitViewBtn;
                return __generator(this, function (_a) {
                    fitViewBtn = document.querySelector('.react-flow__controls-fitview');
                    if (fitViewBtn)
                        fitViewBtn.click();
                    setTimeout(function () { return __awaiter(void 0, void 0, void 0, function () {
                        var _a;
                        return __generator(this, function (_b) {
                            switch (_b.label) {
                                case 0: return [4 /*yield*/, html2PDF(map, {
                                        jsPDF: {
                                            orientation: 'landscape',
                                            compress: true,
                                            format: [mapSize[0], mapSize[1]],
                                        },
                                        html2canvas: {
                                            scale: 7,
                                            useCORS: true,
                                            scrollX: 0,
                                        },
                                        pagebreak: { mode: 'avoid-all' },
                                        imageType: 'image/jpeg',
                                        output: "scenario_map_" + chatbotIdParam + "_" + adScenarioIdParam + "_(" + (((_a = scenarioData === null || scenarioData === void 0 ? void 0 : scenarioData.mainScenario) === null || _a === void 0 ? void 0 : _a.name) || '') + ").pdf",
                                    })];
                                case 1:
                                    _b.sent();
                                    setGeneratingPdf(false);
                                    setContainerWidthForPdf(0);
                                    setContainerHeightForPdf(0);
                                    return [2 /*return*/];
                            }
                        });
                    }); }, 100);
                    return [2 /*return*/];
                });
            }); }, 100);
            return [2 /*return*/];
        });
    }); };
    var handleCopyURL = function () {
        var scenarioMapUrl = window.location.href;
        copyToClipboard(scenarioMapUrl);
        dispatch(handleChangeAlertMessage('シナリオURLを取得しました'));
    };
    var handleCopyID = function () {
        copyToClipboard("" + adScenarioIdParam);
        dispatch(handleChangeAlertMessage('シナリオIDを取得しました'));
    };
    var handleDisplayContentForClient = function () {
        setHideContentForClient(!hideContentForClient);
    };
    useEffect(function () {
        if (clientView)
            return;
        window.open(createParamUrl(), '_blank');
    }, []);
    useEffect(function () {
        if (!fetchToken || !adScenarioIdParam || !chatbotIdParam)
            return;
        setElements([]);
        setBoundaryNodes({
            scenarios: {},
            connectedOtherScenarios: {},
            layers: {},
            blocks: {},
            postbackActions: {},
        });
        setLoading(true);
        fetchToken();
    }, [adScenarioIdParam]);
    useEffect(function () {
        fetchData();
    }, [token]);
    useEffect(function () {
        if (isEmpty(elements))
            return;
        setLoading(false);
    }, [elements]);
    useEffect(function () {
        if (!draftContainer || Object.keys(boundaryNodes.scenarios).length === 0)
            return;
        document.body.removeChild(draftContainer);
    }, [boundaryNodes]);
    // When store all ref info (width, height, x, y) of all scenario, layers,
    // blocks  it will prepare elements
    useEffect(function () {
        if (!(scenarioData === null || scenarioData === void 0 ? void 0 : scenarioData.mainScenario))
            return;
        if (scenarioData === null || scenarioData === void 0 ? void 0 : scenarioData.mainScenario.layers.some(function (layer) {
            return Boolean(!(boundaryNodes === null || boundaryNodes === void 0 ? void 0 : boundaryNodes.layers[layer.id]) ||
                layer.blocks.some(function (block) { return !(boundaryNodes === null || boundaryNodes === void 0 ? void 0 : boundaryNodes.blocks[block.id]); }));
        }))
            return;
        setElements(buildElements(scenarioData, boundaryNodes));
    }, [JSON.stringify(boundaryNodes)]);
    var allMap = function (updateBoundaryNode, data) {
        return (React.createElement(AllMapWrapper, null,
            React.createElement(ScenarioNode, { data: { scenario: data.mainScenario, updateBoundaryNode: updateBoundaryNode } }),
            React.createElement("div", { className: "connected-other-scenarios" }, (data.connectedOtherScenarios || []).map(function (connectedOtherScenario) { return (React.createElement(ConnectedOtherScenarioNode, { key: "other-scenario-" + connectedOtherScenario.id, data: { connectedOtherScenario: connectedOtherScenario, updateBoundaryNode: updateBoundaryNode } })); }))));
    };
    var createParamUrl = function () {
        var baseUrl = "/scenario_map/chatbots/" + chatbotIdParam + "/scenarios/" + adScenarioIdParam;
        var query = location.query;
        if (token) {
            query.token = token;
        }
        return baseUrl + "?" + objToQuery(query);
    };
    var hoveredPostbackAction = useSelector(function (_a) {
        var scenarioMap = _a.scenarioMap;
        return scenarioMap.hoveredPostbackAction;
    });
    var hoveredNode = useSelector(function (_a) {
        var scenarioMap = _a.scenarioMap;
        return scenarioMap.hoveredNode;
    });
    var alertMessage = useSelector(function (_a) {
        var scenarioMap = _a.scenarioMap;
        return scenarioMap.alertMessage;
    });
    var connectionLines = elements.filter(function (element) {
        return element.className.includes('edges-postback-action');
    });
    var transistionLineIds = getLineConnectionIds(hoveredNode, connectionLines);
    var transitionLines = elements.filter(function (connection) {
        return transistionLineIds.includes(connection.id);
    });
    return (React.createElement(FlowWrapper, { clientView: clientView, minWidth: ((_c = boundaryNodes === null || boundaryNodes === void 0 ? void 0 : boundaryNodes.scenarios[(_b = scenarioData === null || scenarioData === void 0 ? void 0 : scenarioData.mainScenario) === null || _b === void 0 ? void 0 : _b.id]) === null || _c === void 0 ? void 0 : _c.width) > 600
            ? '800px'
            : '100%', containerWidthForPdf: containerWidthForPdf, containerHeightForPdf: containerHeightForPdf, hoveredPostbackAction: hoveredPostbackAction, transitionLines: transitionLines, hoveredNode: hoveredNode, hideContentForClient: hideContentForClient },
        (loading || generatingPdf) && (React.createElement(Spinner, { className: "scenario-map-spinner" })),
        !loading && (React.createElement(React.Fragment, null,
            React.createElement(OptionButtonsWrapper, { clientView: clientView },
                clientView && (React.createElement(React.Fragment, null,
                    React.createElement("button", { type: "button", className: "button-as-link client-map-button", onClick: exportAsPdf, disabled: generatingPdf }, "Download PDF"),
                    React.createElement("button", { type: "button", className: "button-as-copy-url client-map-button", onClick: handleCopyURL }, "COPY URL"),
                    React.createElement("button", { type: "button", className: "button-as-copy-id client-map-button", onClick: handleCopyID }, "COPY ID"),
                    clientView && (React.createElement(Switch, { className: "scenario-map-switch", isActive: !hideContentForClient, onClick: handleDisplayContentForClient })))),
                !clientView && (React.createElement("a", { className: "button-as-client-map client-map-button", href: createParamUrl(), target: "_blank", rel: "noopener noreferrer" },
                    React.createElement("span", null, "Client Map")))),
            React.createElement(ReactFlowProvider, null,
                React.createElement(ReactFlow, { elements: elements, nodesDraggable: false, elementsSelectable: false, paneMoveable: true, onLoad: function (reactFlowInstance) { return reactFlowInstance.fitView(); }, minZoom: 0.01, maxZoom: 10, 
                    // This field set to false because scenario map zoom all when open
                    // then all nodes rendered, no need to check and rerender (in case
                    // scenario has to many layer and block, it is reason of slow performance)
                    onlyRenderVisibleElements: false, nodeTypes: {
                        scenarioNode: ScenarioNode,
                        connectedOtherScenarioNode: ConnectedOtherScenarioNode,
                        layerNode: LayerNode,
                        blockNode: BlockNode,
                        postbackActionNode: PostbackActionNode,
                    } },
                    alertMessage && (React.createElement(AlertMessageContainer, null,
                        React.createElement(AlertMessage, { time: 3000, message: alertMessage, onRemoveAlert: function () { return dispatch(handleChangeAlertMessage('')); }, className: "json-alert-message" }))),
                    React.createElement(MiniMap, null),
                    React.createElement(Controls, { showInteractive: false })))))));
};
export default ScenarioMapV2;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10;
