var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { injectIntl } from 'react-intl';
import { useFormik } from 'formik';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { colors } from '@src/colors';
import { RequiredText, CharacterCount, Input } from '@src/components/atoms';
import Tabs from '@zeals-co-ltd/washi-components/lib/Tabs/Tabs';
import TemplateInfo from '@src/components/molecules/TemplateInfo';
import RichMenuTemplate from '@src/components/molecules/RichMenuTemplate';
import { messageTypes, mediaTypes, mediaAcceptTypes, } from '@src/lib/app-constants';
import { Button, Icon } from '@zeals-co-ltd/washi-components';
import defaultRichMenuFormSchema from '@src/lib/helpers/form_modal_schema/defaultRichMenuFormSchema';
import { richMenuMessages } from '@src/i18n/translations';
import { mediaFileSchema, fetchImageWithAuthorization } from '@src/lib/helpers';
import platformLimitations from '@src/lib/platforms/limitations';
import generateEffect from '@src/lib/helpers/form_modal_schema/generateEffect';
import * as washiColors from '@zeals-co-ltd/washi-styles';
import ScheduleDateTime from '@src/components/molecules/ScheduleDateTime';
var DefaultRichMenuFormContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  input,\n  .area-info {\n    color: ", ";\n  }\n"], ["\n  input,\n  .area-info {\n    color: ", ";\n  }\n"])), function (props) { return (props.showOnly ? colors.disabled_grey2 : ''); });
var TabContentWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  margin-top: 44px;\n"], ["\n  display: flex;\n  margin-top: 44px;\n"])));
var LabelField = styled.span(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-weight: 400;\n  font-size: 16px;\n  line-height: 23px;\n  text-align: left;\n  letter-spacing: 0.5px;\n  color: ", ";\n"], ["\n  font-weight: 400;\n  font-size: 16px;\n  line-height: 23px;\n  text-align: left;\n  letter-spacing: 0.5px;\n  color: ", ";\n"])), colors.midnight);
var TextDescription = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: block;\n  vertical-align: bottom;\n  font-weight: 400;\n  font-size: 12px;\n  line-height: 19px;\n  color: ", ";\n  margin-bottom: 16px;\n  letter-spacing: 0.8px;\n\n  & > span {\n    vertical-align: bottom;\n  }\n\n  .MuiSvgIcon-root {\n    font-size: 20px;\n    margin-right: 4px;\n  }\n"], ["\n  display: block;\n  vertical-align: bottom;\n  font-weight: 400;\n  font-size: 12px;\n  line-height: 19px;\n  color: ", ";\n  margin-bottom: 16px;\n  letter-spacing: 0.8px;\n\n  & > span {\n    vertical-align: bottom;\n  }\n\n  .MuiSvgIcon-root {\n    font-size: 20px;\n    margin-right: 4px;\n  }\n"])), colors.status_grey);
var WarningMessageWrapper = styled.div.attrs({
    'data-testid': 'DefaultRichMenuForm_WarningMessage',
})(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  min-height: 24px;\n  text-align: right;\n  font-weight: 400;\n  font-size: 12px;\n  line-height: 19px;\n  margin-top: 10.5px;\n  letter-spacing: 0.8px;\n\n  .MuiSvgIcon-root {\n    position: absolute;\n    font-size: 24px;\n    margin-left: -24px;\n  }\n\n  & > div {\n    display: inline;\n  }\n"], ["\n  min-height: 24px;\n  text-align: right;\n  font-weight: 400;\n  font-size: 12px;\n  line-height: 19px;\n  margin-top: 10.5px;\n  letter-spacing: 0.8px;\n\n  .MuiSvgIcon-root {\n    position: absolute;\n    font-size: 24px;\n    margin-left: -24px;\n  }\n\n  & > div {\n    display: inline;\n  }\n"])));
var ImageAreaWrapper = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  width: 40%;\n\n  button {\n    border-radius: 8px;\n  }\n\n  & > div:nth-child(5) {\n    margin-top: 24px;\n    margin-bottom: 16px;\n  }\n\n  .title span {\n    text-transform: uppercase;\n    color: ", ";\n  }\n\n  .image-area-error {\n    border-color: ", ";\n  }\n\n  ", "\n"], ["\n  width: 40%;\n\n  button {\n    border-radius: 8px;\n  }\n\n  & > div:nth-child(5) {\n    margin-top: 24px;\n    margin-bottom: 16px;\n  }\n\n  .title span {\n    text-transform: uppercase;\n    color: ", ";\n  }\n\n  .image-area-error {\n    border-color: ", ";\n  }\n\n  ",
    "\n"])), colors.border.off_dark, colors.error, function (props) {
    return props.showOnly
        ? "\n    Button {\n      pointer-events: none;\n      color: " + colors.disabled_grey2 + ";\n    }\n  "
        : '';
});
var TemplateAreaWrapper = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  width: 40%;\n  padding-left: 24px;\n\n  button {\n    border-radius: 8px;\n  }\n\n  & > div {\n    margin-top: 16px;\n  }\n\n  img {\n    color: ", ";\n  }\n"], ["\n  width: 40%;\n  padding-left: 24px;\n\n  button {\n    border-radius: 8px;\n  }\n\n  & > div {\n    margin-top: 16px;\n  }\n\n  img {\n    color: ", ";\n  }\n"])), colors.background.active);
var TemplateWrapper = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  border: solid ", " 1px;\n  border-radius: 8px;\n  height: 48px;\n  max-width: 488px;\n  display: flex;\n  overflow: hidden;\n\n  div:nth-child(1) {\n    width: 59.5%;\n    border-right: solid ", " 1px;\n    background-color: ", ";\n    color: #646c8a;\n    font-weight: 400;\n    font-size: 14px;\n    line-height: 24px;\n    padding: 12px 16px;\n  }\n\n  .MuiButton-root {\n    font-weight: 500;\n    font-size: 14px;\n    width: 40.5%;\n    padding: 8px;\n    border-radius: 0px;\n    letter-spacing: 1.25px;\n    font-feature-settings: 'kern' off;\n    color: ", ";\n  }\n"], ["\n  border: solid ", " 1px;\n  border-radius: 8px;\n  height: 48px;\n  max-width: 488px;\n  display: flex;\n  overflow: hidden;\n\n  div:nth-child(1) {\n    width: 59.5%;\n    border-right: solid ", " 1px;\n    background-color: ", ";\n    color: #646c8a;\n    font-weight: 400;\n    font-size: 14px;\n    line-height: 24px;\n    padding: 12px 16px;\n  }\n\n  .MuiButton-root {\n    font-weight: 500;\n    font-size: 14px;\n    width: 40.5%;\n    padding: 8px;\n    border-radius: 0px;\n    letter-spacing: 1.25px;\n    font-feature-settings: 'kern' off;\n    color: ",
    ";\n  }\n"])), colors.border.off_dark, colors.border.off_dark, colors.off_white_border, function (props) {
    return props.showOnly ? colors.disabled_grey2 : colors.light_blue;
});
var TitleChatBarText = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  display: flex;\n  margin-top: 24px;\n\n  & > div {\n    width: 40%;\n  }\n\n  .error-messages {\n    text-align: right;\n    font-size: 10px;\n    padding-top: 5px;\n    height: 20px;\n    color: ", ";\n  }\n"], ["\n  display: flex;\n  margin-top: 24px;\n\n  & > div {\n    width: 40%;\n  }\n\n  .error-messages {\n    text-align: right;\n    font-size: 10px;\n    padding-top: 5px;\n    height: 20px;\n    color: ", ";\n  }\n"])), colors.error);
var TabsWrapper = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  margin-top: 40px;\n  padding: 24px;\n  border-radius: 16px;\n  background: ", ";\n  width: 85%;\n\n  .MuiTabs-flexContainer {\n    width: fit-content;\n    border-radius: 4px;\n    border: 1px solid ", ";\n    padding: 2px;\n\n    button {\n      min-height: 31px;\n      padding: 0 8px;\n      width: 49px;\n      margin: 2px;\n      color: ", ";\n      font-size: 10px;\n      font-weight: 500;\n      text-transform: uppercase;\n      justify-content: center;\n\n      &:nth-child(n + ", ") {\n        color: ", ";\n        pointer-events: none;\n      }\n\n      &.Mui-selected {\n        border-radius: 4px;\n        background: ", ";\n        color: ", ";\n      }\n    }\n  }\n\n  .MuiBox-root {\n    .MuiTabs-indicator {\n      display: none;\n    }\n\n    border-width: 0px;\n  }\n"], ["\n  margin-top: 40px;\n  padding: 24px;\n  border-radius: 16px;\n  background: ", ";\n  width: 85%;\n\n  .MuiTabs-flexContainer {\n    width: fit-content;\n    border-radius: 4px;\n    border: 1px solid ", ";\n    padding: 2px;\n\n    button {\n      min-height: 31px;\n      padding: 0 8px;\n      width: 49px;\n      margin: 2px;\n      color: ", ";\n      font-size: 10px;\n      font-weight: 500;\n      text-transform: uppercase;\n      justify-content: center;\n\n      &:nth-child(n + ", ") {\n        color: ", ";\n        pointer-events: none;\n      }\n\n      &.Mui-selected {\n        border-radius: 4px;\n        background: ", ";\n        color: ", ";\n      }\n    }\n  }\n\n  .MuiBox-root {\n    .MuiTabs-indicator {\n      display: none;\n    }\n\n    border-width: 0px;\n  }\n"])), washiColors.grey[100], colors.status_grey, colors.blue_purple_dark, function (props) { return props.tabCount + 1; }, colors.disabled, colors.blue_purple_dark, colors.white);
var ChatBarText = styled.div(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  padding-left: 24px;\n"], ["\n  padding-left: 24px;\n"])));
var IMAGE_WIDTH = 340.0;
var GeneralAttributes = function (_a) {
    var intl = _a.intl, platform = _a.platform, values = _a.values, errors = _a.errors, handleChange = _a.handleChange, openTemplateModal = _a.openTemplateModal, touched = _a.touched, setFieldTouched = _a.setFieldTouched, getError = _a.getError, showOnly = _a.showOnly;
    var _b = get(values, 'richMenus[0].template_content.size'), width = _b.width, height = _b.height;
    // TODO eslint
    // eslint-disable-next-line react/no-unstable-nested-components
    var ErrorMessages = function (_a) {
        var errorMessages = _a.errorMessages;
        return React.createElement("div", { className: "error-messages" }, errorMessages);
    };
    var onChange = function (e, field) {
        handleChange(e);
        setFieldTouched(field);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", null,
            React.createElement(LabelField, null, intl.formatMessage({ id: "KTnkWM", defaultMessage: "Size, Tabs & Template" })),
            React.createElement(RequiredText, null)),
        React.createElement(TextDescription, null,
            React.createElement(Icon, { name: "adicon_alert", color: "disabled" }),
            React.createElement("span", null, intl.formatMessage({ id: "+WL17j", defaultMessage: "Changing the template will reset all the settings on this page." }))),
        React.createElement(TemplateWrapper, { showOnly: showOnly },
            React.createElement("div", null, width + "x" + height + " " + intl.formatMessage({ id: "ZaEi2e", defaultMessage: "({numberOfTabs} Tabs)" }, { numberOfTabs: get(values, 'richMenus').length })),
            React.createElement(Button, { tabIndex: 0, onClick: openTemplateModal, startIcon: React.createElement(Icon, { name: "adicon_msg_richmenu", color: showOnly ? 'disabled' : 'primary' }), disabled: showOnly, "data-testid": "DefaultRichMenuForm_ChangeTemplateButton" }, intl.formatMessage({ id: "oilDZh", defaultMessage: "CHANGE TEMPLATE" }))),
        React.createElement(TitleChatBarText, null,
            React.createElement("div", { "data-testid": "DefaultRichMenuForm_Title" },
                React.createElement("div", null,
                    React.createElement(LabelField, null, intl.formatMessage({ id: "9a9+ww", defaultMessage: "Title" })),
                    React.createElement(RequiredText, null)),
                React.createElement(TextDescription, null, intl.formatMessage({ id: "T6cQo7", defaultMessage: "Write a title that will help you identify this rich menu." })),
                React.createElement(Input, { value: get(values, 'richMenus[0].label', ''), name: "richMenus[0].label", onChange: function (e) { return onChange(e, 'richMenus[0].label'); }, onBlur: function () { return setFieldTouched('richMenus[0].label'); }, hasError: Boolean(getError('richMenus[0].label')), placeholder: intl.formatMessage({ id: "9a9+ww", defaultMessage: "Title" }), isReadonly: showOnly },
                    React.createElement(CharacterCount, { value: get(values, 'richMenus[0].label', ''), platform: platform, path: messageTypes.RICH_MENU + ".label", dataTestId: "DefaultRichMenuForm_TitleCharacterCount" })),
                React.createElement(ErrorMessages, { errorMessages: getError('richMenus[0].label') })),
            React.createElement(ChatBarText, { "data-testid": "DefaultRichMenuForm_ChatBarText" },
                React.createElement("div", null,
                    React.createElement(LabelField, null, intl.formatMessage({ id: "uh6H56", defaultMessage: "ChatBar Text" })),
                    React.createElement(RequiredText, null)),
                React.createElement(TextDescription, null, intl.formatMessage({ id: "EgJ0LM", defaultMessage: "This text will display in the end user\u2019s chat screen." })),
                React.createElement(Input, { value: get(values, 'richMenus[0].chat_bar_text', ''), name: "richMenus[0].chat_bar_text", onChange: function (e) { return onChange(e, 'richMenus[0].chat_bar_text'); }, onBlur: function () { return setFieldTouched('richMenus[0].chat_bar_text'); }, hasError: Boolean(getError('richMenus[0].chat_bar_text')), placeholder: intl.formatMessage({ id: "uh6H56", defaultMessage: "ChatBar Text" }), isReadonly: showOnly },
                    React.createElement(CharacterCount, { value: get(values, 'richMenus[0].chat_bar_text', ''), platform: platform, path: messageTypes.RICH_MENU + ".chatBarText", dataTestId: "DefaultRichMenuForm_ChatBarTextCharacterCount" })),
                React.createElement(ErrorMessages, { errorMessages: getError('richMenus[0].chat_bar_text') })))));
};
var WarningMessage = function (_a) {
    var intl = _a.intl, formValid = _a.formValid, showOnly = _a.showOnly, isActive = _a.isActive;
    if (isActive && showOnly)
        return React.createElement(WarningMessageWrapper, null);
    var messageValidate = formValid ? (React.createElement("div", null,
        React.createElement(Icon, { name: "adicon_check_filled", color: "success" }),
        intl.formatMessage({ id: "3BCwxV", defaultMessage: "All required settings have been completed" }))) : (React.createElement("div", null,
        React.createElement(Icon, { name: "adicon_alert", color: "error" }),
        intl.formatMessage({ id: "mJLRML", defaultMessage: "Some required settings have not been completed" })));
    var textMessage = showOnly ? (React.createElement("div", null,
        React.createElement(Icon, { name: "adicon_check_filled", color: "success" }),
        intl.formatMessage({ id: "+MRdp3", defaultMessage: "Uploaded to LINE" }))) : (messageValidate);
    return (React.createElement(WarningMessageWrapper, { formValid: formValid }, textMessage));
};
var DefaultRichMenuForm = function (_a) {
    var intl = _a.intl, defaultRichMenu = _a.defaultRichMenu, blocks = _a.blocks, openTemplateModal = _a.openTemplateModal, updateSubmitValues = _a.updateSubmitValues, usedLabels = _a.usedLabels, _b = _a.showOnly, showOnly = _b === void 0 ? false : _b, schedules = _a.schedules;
    var platform = useSelector(function (state) { return state.settings.present.activeChatbot; }).platform;
    var accessToken = useSelector(function (_a) {
        var page = _a.settings.present.activeChatbot.page;
        return page.line_app_access_token;
    });
    var fileInput = React.createRef();
    var uploadClick = function () { return fileInput.current.click(); };
    var _c = __read(useState(false), 2), editModalOpened = _c[0], setEditModalOpened = _c[1];
    var _d = __read(useState(null), 2), hoverAreaId = _d[0], setHoverAreaId = _d[1];
    var limits = platform && platformLimitations[platform]
        ? platformLimitations[platform]
        : {};
    var _e = useFormik({
        initialValues: defaultRichMenu,
        validationSchema: defaultRichMenuFormSchema(intl, platform, defaultRichMenu.richMenus[0].id, usedLabels, schedules),
        onSubmit: function () { return undefined; },
    }), values = _e.values, errors = _e.errors, setValues = _e.setValues, handleChange = _e.handleChange, setFieldTouched = _e.setFieldTouched, touched = _e.touched, setTouched = _e.setTouched, isValidating = _e.isValidating;
    var getError = function (field) {
        return get(touched, field) && get(errors, field);
    };
    var scenarios = useSelector(function (state) { return state.adScenario.present.scenarios; });
    var language = useSelector(function (state) { return state.account.language; });
    useEffect(function () {
        setValues(defaultRichMenu).then(function () {
            if (defaultRichMenu.richMenus[0].label) {
                setFieldTouched('richMenus[0].label');
            }
        });
    }, [defaultRichMenu, showOnly]);
    useEffect(function () {
        return updateSubmitValues({
            values: values,
            isValid: isEmpty(errors),
        });
    }, [values, errors, updateSubmitValues]);
    useEffect(function () {
        setTouched(touched);
    }, [language]);
    var updateArea = function (richmenuId, richMenuAreaId, blockId, nextScenarioId, url, label) {
        var _a;
        var richMenuIndex = values.richMenus.findIndex(function (_a) {
            var id = _a.id;
            return id === richmenuId;
        });
        var richMenuAreaIndex = values.richMenus[richMenuIndex].rich_menu_areas.findIndex(function (_a) {
            var id = _a.id;
            return id === richMenuAreaId;
        });
        var postback_action = {
            effect: generateEffect(blockId, nextScenarioId, url),
            block_id: null,
            scenario_first_block_id: null,
            scenario_id: null,
            url: null,
        };
        if (blockId) {
            postback_action.block_id = blockId;
        }
        else if (nextScenarioId) {
            postback_action.scenario_id = nextScenarioId;
            postback_action.scenario_first_block_id =
                (_a = scenarios[nextScenarioId]) === null || _a === void 0 ? void 0 : _a.first_block_id;
        }
        else {
            postback_action.url = url;
        }
        handleChange({
            target: {
                name: "richMenus[" + richMenuIndex + "].rich_menu_areas[" + richMenuAreaIndex + "].postback_action",
                value: postback_action,
            },
        });
        handleChange({
            target: {
                name: "richMenus[" + richMenuIndex + "].rich_menu_areas[" + richMenuAreaIndex + "].label",
                value: label,
            },
        });
    };
    var fileOnChange = function (e, index) {
        e.preventDefault();
        var file = e.target.files[0];
        var reader = new FileReader();
        mediaFileSchema(limits)
            .validate({
            size: file.size,
        }, {
            abortEarly: false,
            context: { isPreview: true },
        })
            .then(function (response) {
            if (!response)
                return;
            var img = new Image();
            var _a = get(values, 'richMenus[0].template_content.size'), width = _a.width, height = _a.height;
            img.src = window.URL.createObjectURL(file);
            img.onload = function () {
                if (img.width !== Number(width) || img.height !== Number(height)) {
                    alert(intl.formatMessage({ id: "KKaMB4", defaultMessage: "Image width x height should be {imageSize}." }, { imageSize: width + " x " + height }));
                    e.target.value = null;
                    return;
                }
                handleChange({
                    target: {
                        name: "richMenus[" + index + "].image_file_content",
                        value: file,
                    },
                });
                handleChange({
                    target: {
                        name: "richMenus[" + index + "].image_url",
                        value: img.src,
                    },
                });
            };
        })
            .catch(function (err) {
            e.target.value = null;
            alert(err.errors.join(' \n'));
        });
        reader.readAsDataURL(file);
    };
    var createTabItems = function (values) {
        var richMenu;
        return ['tab_1', 'tab_2', 'tab_3'].map(function (tabKey, index) {
            var _a;
            var tabText = intl.formatMessage(richMenuMessages[tabKey]);
            richMenu = values.richMenus[index];
            var errorClassName = getError("richMenus[" + index + "].image_url")
                ? 'image-area-error'
                : '';
            var _b = ((_a = richMenu === null || richMenu === void 0 ? void 0 : richMenu.template_content) === null || _a === void 0 ? void 0 : _a.size) || {}, width = _b.width, height = _b.height;
            return {
                label: tabText,
                children: richMenu ? (React.createElement(TabContentWrapper, null,
                    React.createElement(ImageAreaWrapper, { showOnly: showOnly, "data-testid": "DefaultRichMenuForm_Image" },
                        React.createElement("div", null,
                            React.createElement(LabelField, null, intl.formatMessage({ id: "+0zv6g", defaultMessage: "Image" })),
                            React.createElement(RequiredText, null)),
                        React.createElement(TextDescription, null, intl.formatMessage({ id: "+ZYvE/", defaultMessage: "Upload a base image the same size as your selected template." })),
                        React.createElement(Button, { variant: "outlined", onClick: function () {
                                uploadClick();
                                setFieldTouched("richMenus[" + index + "].image_url");
                            }, className: errorClassName, fullWidth: true },
                            React.createElement(Icon, { name: "adicon_image", color: "primary" }),
                            intl.formatMessage({ id: "p4N05H", defaultMessage: "Upload" })),
                        React.createElement("input", { name: "url", accept: mediaAcceptTypes[mediaTypes.IMAGE], type: "file", style: { display: 'none' }, ref: fileInput, onChange: function (event) { return fileOnChange(event, index); }, "data-testid": "DefaultRichMenuForm_FileInput" }),
                        React.createElement("div", null,
                            React.createElement(LabelField, null, intl.formatMessage({ id: "hmipwE", defaultMessage: "Area Settings" })),
                            React.createElement(RequiredText, null)),
                        React.createElement(TemplateInfo, { "data-testid": "rich-menu-template-container-template-info", areas: richMenu.rich_menu_areas, templateAreas: richMenu.template_content.areas, blocks: blocks, scenarios: scenarios, hoverAreaId: hoverAreaId, setHoverAreaId: setHoverAreaId, setEditModalOpened: !showOnly && setEditModalOpened, numberOfTabs: values.richMenus.length, isDefaultRichMenu: true })),
                    React.createElement(TemplateAreaWrapper, { "data-testid": "DefaultRichMenuForm_Template" },
                        React.createElement(LabelField, null, intl.formatMessage({ id: "TJo5E6", defaultMessage: "Preview" })),
                        React.createElement(RichMenuTemplate, { "data-testid": "rich-menu-template-container-template-info-image-preview", richMenuAreas: richMenu.rich_menu_areas, templateNum: richMenu.template, richMenuAreaUpdateHandler: function (_a) {
                                var richMenuId = _a.richMenuId, richMenuAreaId = _a.richMenuAreaId, blockId = _a.blockId, nextScenarioId = _a.nextScenarioId, url = _a.url, label = _a.label;
                                return updateArea(richMenuId, richMenuAreaId, blockId, nextScenarioId, url, label);
                            }, templateWidth: width, templateHeight: height, areas: richMenu.template_content.areas.map(function (area) { return area.bounds; }), hoverAreaId: hoverAreaId, setHoverAreaId: setHoverAreaId, editModalOpened: showOnly ? false : editModalOpened, setEditModalOpened: !showOnly && setEditModalOpened, activeBlocks: blocks, isDefaultRichMenu: true, platform: platform }, richMenu.image_url && (React.createElement("img", { alt: "rich_menu_image", width: IMAGE_WIDTH + "px", height: (height * IMAGE_WIDTH) / width + "px", src: richMenu.image_url &&
                                richMenu.image_url.startsWith('http')
                                ? ''
                                : richMenu.image_url, onError: fetchImageWithAuthorization(richMenu.image_url, accessToken) })))))) : null,
            };
        });
    };
    return (React.createElement(DefaultRichMenuFormContainer, { showOnly: showOnly },
        React.createElement(WarningMessage, { intl: intl, formValid: isEmpty(errors), showOnly: showOnly, isActive: defaultRichMenu.isActive }),
        React.createElement(GeneralAttributes, { intl: intl, platform: platform, values: values, errors: errors, touched: touched, setFieldTouched: setFieldTouched, handleChange: handleChange, getError: getError, openTemplateModal: openTemplateModal, showOnly: showOnly }),
        React.createElement(ScheduleDateTime, { showOnly: showOnly, defaultRichMenu: defaultRichMenu, handleChange: showOnly || isValidating ? function () { return undefined; } : handleChange, errors: errors, values: values }),
        React.createElement(TabsWrapper, { tabCount: values.richMenus.length },
            React.createElement(Tabs, { key: defaultRichMenu.richMenus[0].id, tabItems: createTabItems(values), "data-testid": "DefaultRichMenuForm_Tabs" }))));
};
export default injectIntl(DefaultRichMenuForm);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11;
