import "core-js/modules/es.array.concat";
export var collection = function collection(chatbotId, targetId) {
  return "/chatbots/".concat(chatbotId, "/retention_targets/").concat(targetId, "/retention_campaigns");
};
export var member = function member(chatbotId, targetId, id) {
  return "".concat(collection(chatbotId, targetId), "/").concat(id);
};
export var contentRanks = function contentRanks(chatbotId, targetId, id) {
  return "".concat(member(chatbotId, targetId, id), "/content_ranks");
};
export var trackCollection = function trackCollection(chatbotId, retentionTargetId) {
  return "/chatbots/".concat(chatbotId, "/track_push/retention_targets/").concat(retentionTargetId, "/retention_campaigns");
};
export var trackMember = function trackMember(chatbotId, retentionTargetId, retentionCampaignId) {
  return "".concat(trackCollection(chatbotId, retentionTargetId), "/").concat(retentionCampaignId);
};
export default {
  collection: collection,
  member: member,
  contentRanks: contentRanks,
  trackPush: {
    collection: trackCollection,
    member: trackMember
  }
};