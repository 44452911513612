var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { Field } from 'formik';
function InputValue(_a) {
    var field = _a.field, // { name, value, onChange, onBlur }
    componentType = _a.componentType, hasError = _a.hasError, props = __rest(_a, ["field", "componentType", "hasError"]);
    if (componentType === 'textarea') {
        return (React.createElement("textarea", __assign({ className: hasError ? 'has-error' : '' }, field, props)));
    }
    if (componentType === 'p') {
        return React.createElement("p", { className: hasError ? 'has-error' : '' }, field.value);
    }
    return (React.createElement("input", __assign({ type: "text", className: hasError ? 'has-error' : '' }, field, props)));
}
var CustomInputComponent = function (_a) {
    var field = _a.field, // { name, value, onChange, onBlur }
    componentType = _a.componentType, _b = _a.form, touched = _b.touched, errors = _b.errors, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    hasError = _a.hasError, props = __rest(_a, ["field", "componentType", "form", "hasError"]);
    return (React.createElement("div", null,
        React.createElement(InputValue, __assign({ field: field, componentType: componentType, hasError: hasError }, props)),
        touched[field.name] && errors[field.name] && (React.createElement("div", { className: "message-wrapper error" }, errors[field.name]))));
};
export default function CustomField(_a) {
    var fieldLabel = _a.fieldLabel, children = _a.children, props = __rest(_a, ["fieldLabel", "children"]);
    return (React.createElement("div", { className: "field" },
        React.createElement("p", { className: "label" }, fieldLabel),
        React.createElement(Field, __assign({}, props, { component: CustomInputComponent })),
        children));
}
