var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import get from 'lodash/get';
import kebabCase from 'lodash/kebabCase';
import changeItem from './changeItem';
import { colors } from '@src/colors';
/**
 * This method build elements base on boundaryNodes, it will build elements
 * with all nodes with position, size get from boundaryNodes, build edges by
 * find postback action on each message
 */
var INCREASE_NUMBER = 20;
function buildElement(objectKey, object, boundaryNodes, options) {
    var _a;
    if (options === void 0) { options = {}; }
    var boundaryNode = get(boundaryNodes, objectKey + "s." + object['id']);
    if (!boundaryNode)
        return null;
    // There are issue that postback action boundary node is need
    // increate x, y position to fix postback item (image map area, button ...)
    var increaseNumber = objectKey === 'postbackAction' ? INCREASE_NUMBER : 0;
    return __assign({ id: kebabCase(objectKey) + "-" + object['id'], className: kebabCase(objectKey) + "-" + object['id'], type: objectKey + "Node", data: (_a = {},
            _a[objectKey] = object,
            _a.style = {
                width: boundaryNode.width + "px",
                height: boundaryNode.height + "px",
            },
            _a), position: {
            x: boundaryNode.x + increaseNumber,
            y: boundaryNode.y + increaseNumber,
        } }, options);
}
export default function buildElements(scenarioData, boundaryNodes) {
    var scenario = scenarioData.mainScenario;
    var elements = [
        buildElement('scenario', scenario, boundaryNodes),
    ];
    scenario.layers.forEach(function (layer) {
        elements = elements.concat([buildElement('layer', layer, boundaryNodes)]);
        layer.blocks.forEach(function (block) {
            elements = elements.concat([
                buildElement('block', block, boundaryNodes, {
                    selectable: true,
                }),
            ]);
        });
    });
    (scenarioData.connectedOtherScenarios || []).forEach(function (otherScenario) {
        elements = elements.concat([
            buildElement('connectedOtherScenario', otherScenario, boundaryNodes, {
                selectable: true,
            }),
        ]);
    });
    var postbackActions = [];
    var connectionLines = [];
    // This step will get all postback action object from scenario
    scenario.layers.forEach(function (layer) {
        layer.blocks.forEach(function (block) {
            changeItem(block, 'postback_action', function (postbackAction) {
                if (postbackAction)
                    postbackActions = postbackActions.concat([
                        __assign({ parent_block_id: block.id }, postbackAction),
                    ]);
                return postbackAction;
            });
        });
    });
    postbackActions.forEach(function (postbackAction) {
        if (!boundaryNodes.postbackActions[postbackAction.id])
            return;
        elements = elements.concat([
            buildElement('postbackAction', postbackAction, boundaryNodes, {
                selectable: true,
            }),
        ]);
        if (!postbackAction.scenario_id && !postbackAction.block_id)
            return;
        connectionLines = connectionLines.concat([
            {
                id: "edges-postback-action-" + postbackAction.id,
                source: "postback-action-" + postbackAction.id,
                sourceBlock: "block-" + postbackAction.parent_block_id,
                className: "edges-postback-action edges-postback-action-" + postbackAction.id,
                sourceHandle: "bottom",
                target: postbackAction.block_id
                    ? "block-" + postbackAction.block_id
                    : "connected-other-scenario-" + postbackAction.scenario_id,
                type: 'step',
                animated: true,
                style: {
                    stroke: postbackAction.block_id
                        ? colors.hover.red
                        : colors.border.green,
                },
            },
        ]);
    });
    return elements.concat(connectionLines).filter(Boolean);
}
