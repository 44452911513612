import "core-js/modules/es.array.map";
import "core-js/modules/es.string.small";
import React from 'react';
import styled from 'styled-components';
import { injectIntl } from 'react-intl';
import { Button, DeleteIcon } from '@src/components/atoms';
import buttonTypes from '@src/lib/buttonTypes';
import { colors, shadows } from '@src/colors';
import { transitions } from '@src/lib/animations';
import { deviceTypeMessages, triggerTypeMessages } from '@src/i18n/translations';
import ActionPreviewItem from './plugins/ActionPreviewItem';
var Container = styled.div.withConfig({
  displayName: "Container",
  componentId: "sc-v4oqny-0"
})(["display:flex;border-radius:5px;box-shadow:", ";padding:10px 25px;background:", ";margin-bottom:15px;position:relative;transition:", ";&:hover{cursor:pointer;background-color:", ";.adicon_delete{display:block;}}&:last-child{margin-bottom:0;}"], shadows.small.light, colors.white, transitions.normal, colors.background.content);
var ActionPreviewList = styled.div.withConfig({
  displayName: "ActionPreviewList",
  componentId: "sc-v4oqny-1"
})(["width:75%;display:inline-flex;justify-content:space-around;align-items:center;"]);
var TriggerInfo = styled.div.withConfig({
  displayName: "TriggerInfo",
  componentId: "sc-v4oqny-2"
})(["width:60%;display:flex;justify-content:space-around;align-items:center;"]);
var TriggerInfoItem = styled.div.withConfig({
  displayName: "TriggerInfoItem",
  componentId: "sc-v4oqny-3"
})(["display:flex;flex-direction:column;justify-content:center;.trigger_info_title{font-size:12px;font-weight:bold;color:", ";margin-bottom:16px;}.trigger_info_body{font-size:1.2rem;> .spacer{height:28px;}}"], colors.text_pale);

var PluginEventListItem = function PluginEventListItem(_ref) {
  var event = _ref.event,
      intl = _ref.intl,
      onLinkEventItem = _ref.onLinkEventItem,
      onDelete = _ref.onDelete;
  return React.createElement(Container, null, React.createElement(DeleteIcon, {
    onClick: onDelete
  }), React.createElement(ActionPreviewList, null, event.actions.map(function (action, i) {
    return React.createElement(ActionPreviewItem, {
      key: "event-action-".concat(i),
      action: action
    });
  })), React.createElement(TriggerInfo, null, React.createElement(TriggerInfoItem, null, React.createElement("div", {
    className: "trigger_info_title"
  }, "\u30C8\u30EA\u30AC\u30FC\u30BF\u30A4\u30D7"), React.createElement("div", {
    className: "trigger_info_body"
  }, intl.formatMessage(triggerTypeMessages[event.trigger.trigger_type.toUpperCase()]))), React.createElement(TriggerInfoItem, null, React.createElement("div", {
    className: "trigger_info_title"
  }, "\u6761\u4EF6"), React.createElement("div", {
    className: "trigger_info_body"
  }, React.createElement("div", {
    className: "spacer"
  }, !event.trigger.integer_condition ? event.trigger.string_condition : event.trigger.integer_condition))), React.createElement(TriggerInfoItem, null, React.createElement("div", {
    className: "trigger_info_title"
  }, "\u30C7\u30D0\u30A4\u30B9\u30BF\u30A4\u30D7"), React.createElement("div", {
    className: "trigger_info_body"
  }, intl.formatMessage(deviceTypeMessages[event.trigger.device_type.toUpperCase()]))), React.createElement(Button, {
    width: 100,
    onClick: function onClick() {
      return onLinkEventItem(event.id);
    },
    text: "\u7DE8\u96C6",
    buttonType: buttonTypes.SETTING
  })));
};

export default injectIntl(PluginEventListItem);