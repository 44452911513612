var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import { colors, shadows } from '@src/colors';
import { WebchatTalkTrigger, QuickReply } from '@src/components/atoms';
import Carousel from '@src/fanp/enterprise/talk_customize/components/line_flex_message/chat/Carousel';
import Bubble from '@src/fanp/enterprise/talk_customize/components/line_flex_message/chat/Bubble';
import FlexMessageContainer from '@src/fanp/enterprise/talk_customize/components/line_flex_message/FlexMessageContainer';
var Answer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  font-size: 0.9rem;\n  text-align: left;\n  margin-right: ", ";\n  margin-left: ", ";\n  color: unset;\n  max-width: 200px;\n  word-break: break-all;\n  border: unset;\n\n  &.", " {\n    width: max-content;\n    border-radius: 20px;\n    color: ", ";\n    background: ", ";\n    box-shadow: ", ";\n    padding: 0.4rem 0.8rem;\n    animation: animate-answer 0.5s;\n  }\n  @keyframes animate-answer {\n    0% {\n      padding: 0;\n    }\n    100% {\n      padding: 0.4rem 0.8rem;\n    }\n  }\n"], ["\n  font-size: 0.9rem;\n  text-align: left;\n  margin-right: ", ";\n  margin-left: ", ";\n  color: unset;\n  max-width: 200px;\n  word-break: break-all;\n  border: unset;\n\n  &.", " {\n    width: max-content;\n    border-radius: 20px;\n    color: ", ";\n    background: ",
    ";\n    box-shadow: ", ";\n    padding: 0.4rem 0.8rem;\n    animation: animate-answer 0.5s;\n  }\n  @keyframes animate-answer {\n    0% {\n      padding: 0;\n    }\n    100% {\n      padding: 0.4rem 0.8rem;\n    }\n  }\n"])), function (props) { return (!props.isChatbot ? '16px' : 'auto'); }, function (props) { return (!props.isChatbot ? 'auto' : '16px'); }, function (props) { return props.className; }, colors.white, function (_a) {
    var webchatApp = _a.webchatApp;
    return webchatApp && webchatApp.header_background_color
        ? webchatApp.header_background_color
        : colors.webchat.background;
}, shadows.small.mid);
var MessageWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  animation: animate-message 0.5s;\n  @keyframes animate-message {\n    0% {\n      margin-left: -100px;\n      opacity: 0;\n    }\n    100% {\n      margin-left: 0;\n      opacity: 1;\n    }\n  }\n"], ["\n  animation: animate-message 0.5s;\n  @keyframes animate-message {\n    0% {\n      margin-left: -100px;\n      opacity: 0;\n    }\n    100% {\n      margin-left: 0;\n      opacity: 1;\n    }\n  }\n"])));
var UserControlsContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  transition: all 0.3s linear;\n"], ["\n  transition: all 0.3s linear;\n"])));
var LoadingWraper = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  height: 30px;\n  background: ", ";\n  box-shadow: ", ";\n  padding-left: 20px;\n  padding-top: 10px;\n  width: 90px;\n  border-radius: 20px;\n  margin-left: 16px;\n"], ["\n  height: 30px;\n  background: ", ";\n  box-shadow: ", ";\n  padding-left: 20px;\n  padding-top: 10px;\n  width: 90px;\n  border-radius: 20px;\n  margin-left: 16px;\n"])), colors.webchat.background, shadows.small.mid);
var BotIcon = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  height: 30px;\n  width: 30px;\n  border-radius: 50%;\n  border: 2px solid ", ";\n  position: absolute;\n  left: -30px;\n  .webchat-icon {\n    height: 30px;\n    width: 30px;\n    max-width: 30px;\n    border-radius: 50%;\n  }\n"], ["\n  height: 30px;\n  width: 30px;\n  border-radius: 50%;\n  border: 2px solid ", ";\n  position: absolute;\n  left: -30px;\n  .webchat-icon {\n    height: 30px;\n    width: 30px;\n    max-width: 30px;\n    border-radius: 50%;\n  }\n"])), function (_a) {
    var webchatApp = _a.webchatApp;
    return webchatApp.header_background_color;
});
var Loading = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: flex;\n  width: auto;\n  div {\n    width: 10px;\n    height: 10px;\n    background-color: ", ";\n    border-radius: 50%;\n    margin: auto 3px;\n  }\n  div:nth-child(2) {\n    position: absolute;\n  }\n  div:nth-child(1) {\n    animation: first 0.6s infinite;\n  }\n  div:nth-child(2) {\n    animation: second 0.6s infinite;\n  }\n  div:nth-child(3) {\n    animation: second 0.6s infinite;\n  }\n  div:nth-child(4) {\n    animation: third 0.6s infinite;\n  }\n  @keyframes first {\n    0% {\n      transform: scale(0);\n    }\n    100% {\n      transform: scale(1);\n    }\n  }\n  @keyframes third {\n    0% {\n      transform: scale(1);\n    }\n    100% {\n      transform: scale(0);\n    }\n  }\n  @keyframes second {\n    0% {\n      transform: translate(0, 0);\n    }\n    100% {\n      transform: translate(17px, 0);\n    }\n  }\n"], ["\n  display: flex;\n  width: auto;\n  div {\n    width: 10px;\n    height: 10px;\n    background-color: ", ";\n    border-radius: 50%;\n    margin: auto 3px;\n  }\n  div:nth-child(2) {\n    position: absolute;\n  }\n  div:nth-child(1) {\n    animation: first 0.6s infinite;\n  }\n  div:nth-child(2) {\n    animation: second 0.6s infinite;\n  }\n  div:nth-child(3) {\n    animation: second 0.6s infinite;\n  }\n  div:nth-child(4) {\n    animation: third 0.6s infinite;\n  }\n  @keyframes first {\n    0% {\n      transform: scale(0);\n    }\n    100% {\n      transform: scale(1);\n    }\n  }\n  @keyframes third {\n    0% {\n      transform: scale(1);\n    }\n    100% {\n      transform: scale(0);\n    }\n  }\n  @keyframes second {\n    0% {\n      transform: translate(0, 0);\n    }\n    100% {\n      transform: translate(17px, 0);\n    }\n  }\n"])), colors.area_mask);
var Message = function (_a) {
    var message = _a.message, webchatApp = _a.webchatApp;
    if (!message)
        return null;
    if (!message.line_flex_message)
        return null;
    var _b = message.line_flex_message, contents = _b.contents, answer = _b.answer, messageId = _b.messageId, botAnswerError = _b.botAnswerError;
    var isChatbot = message.isChatbot;
    if (contents && contents.contents) {
        return (React.createElement(React.Fragment, null,
            React.createElement(BotIcon, { webchatApp: webchatApp },
                React.createElement("img", { className: "webchat-icon", src: webchatApp.thumbnail_url })),
            React.createElement(MessageWrapper, null,
                React.createElement(Carousel, { content: contents, readOnly: true }))));
    }
    if (contents) {
        return React.createElement(Bubble, { content: contents, readOnly: true });
    }
    if (!isChatbot) {
        return (React.createElement(Answer, { isChatbot: isChatbot, webchatApp: webchatApp, className: 'botAnswer' + messageId }, answer));
    }
    if (isChatbot) {
        return (React.createElement(Answer, { isChatbot: isChatbot, webchatApp: webchatApp, className: 'botAnswer' + messageId }, botAnswerError));
    }
    return null;
};
var UserControls = function (_a) {
    var lastMessage = _a.lastMessage, isQuickReply = _a.isQuickReply, isTalkTrigger = _a.isTalkTrigger, status = _a.status, webchatOpened = _a.webchatOpened, onHandleAnswer = _a.onHandleAnswer, webchatApp = _a.webchatApp;
    if (status === 'LOADING') {
        return (React.createElement(LoadingWraper, null,
            React.createElement(Loading, null,
                React.createElement("div", null),
                React.createElement("div", null),
                React.createElement("div", null),
                React.createElement("div", null))));
    }
    if (isQuickReply) {
        return (React.createElement(QuickReply, { choices: lastMessage.quick_reply.quick_reply_choices, onHandleAnswer: function (answerInfo) {
                if (!answerInfo.messageId) {
                    throw new Error('Webchat Quick Reply answer handle requires messageId');
                }
                onHandleAnswer(answerInfo);
            }, messageId: lastMessage.id }));
    }
    if (isTalkTrigger) {
        return (React.createElement(WebchatTalkTrigger, { webchatOpened: webchatOpened, talkTrigger: lastMessage.talk_trigger, webchatApp: webchatApp, onHandleAnswer: function (answerInfo) {
                if (!answerInfo.messageId) {
                    throw new Error('Webchat Talk Trigger answer handle requires messageId');
                }
                onHandleAnswer(answerInfo);
            }, messageId: lastMessage.id }));
    }
    return null;
};
// defaultでPropsがコンポーネントに渡されますので、Propsからmessagesを切り取っていきます
var FlexMessages = function (_a) {
    var messages = _a.messages, status = _a.status, onHandleAnswer = _a.onHandleAnswer, webchatOpened = _a.webchatOpened, webchatApp = _a.webchatApp;
    // reactで親のリアクトコンポーネントリターンする必要があるので、Spanでラップした
    var lastMessage = messages[messages.length - 1];
    var isQuickReply = lastMessage &&
        lastMessage.quick_reply &&
        !!lastMessage.quick_reply.quick_reply_choices.length;
    var isTalkTrigger = lastMessage && lastMessage.talk_trigger;
    return (React.createElement(React.Fragment, null,
        messages &&
            messages.map(function (message, i) { return (React.createElement(FlexMessageContainer, { key: i },
                React.createElement(Message, { message: message, webchatApp: webchatApp }))); }),
        React.createElement(UserControlsContainer, null,
            React.createElement(UserControls, { webchatApp: webchatApp, lastMessage: lastMessage, isQuickReply: isQuickReply, isTalkTrigger: isTalkTrigger, status: status, webchatOpened: webchatOpened, onHandleAnswer: onHandleAnswer }))));
};
export default FlexMessages;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
