var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState } from 'react';
import styled from 'styled-components';
import omit from 'lodash/omit';
import sortBy from 'lodash/sortBy';
import { colors } from '@src/colors';
import imageMapTemplates from '@src/lib/ImageMapTemplates';
import ImageMapTemplate from '@src/components/molecules/ImageMapTemplate';
import Modal from '@src/components/molecules/Modal';
import ListTemplateGroupTitles from '@src/components/molecules/ListTemplateGroupTitles';
import ListTemplateGroups from '@src/components/molecules/ListTemplateGroups';
import ModalPortal from '@src/components/atoms/ModalPortal';
import { injectIntl } from 'react-intl';
var DEFAULT_CHOSEN_TEMPLATE = 15;
var TemplateContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: grid;\n  grid-template-columns: repeat(auto-fill, 340px);\n  grid-gap: 16px;\n  justify-items: center;\n  > div {\n    text-align: center;\n  }\n"], ["\n  display: grid;\n  grid-template-columns: repeat(auto-fill, 340px);\n  grid-gap: 16px;\n  justify-items: center;\n  > div {\n    text-align: center;\n  }\n"])));
var TemplateModalWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  .image-map-template-modal {\n    overflow: hidden;\n\n    .modal_body {\n      > div {\n        height: calc(75vh - 80px);\n      }\n\n      > div:first-of-type {\n        overflow-y: auto;\n      }\n    }\n\n    @media only screen and (max-width: 1440px) {\n      max-width: 1010px;\n\n      .modal_body {\n        .multiple-templates {\n          grid-column: 1 / 3;\n        }\n      }\n    }\n\n    @media only screen and (min-width: 1441px) {\n      max-width: 1400px;\n\n      .modal_body {\n        .multiple-templates {\n          grid-column: 1 / 4;\n        }\n      }\n    }\n  }\n\n  .heading-element {\n    margin-left: -40px;\n    padding-left: 40px;\n    margin-right: -40px;\n    padding-right: 40px;\n    padding-bottom: 24px;\n    box-shadow: 0px 3px 3px ", ";\n  }\n\n  .section-controls {\n    position: absolute;\n    right: 40px;\n    bottom: 40px;\n    div {\n      overflow: visible;\n    }\n  }\n"], ["\n  .image-map-template-modal {\n    overflow: hidden;\n\n    .modal_body {\n      > div {\n        height: calc(75vh - 80px);\n      }\n\n      > div:first-of-type {\n        overflow-y: auto;\n      }\n    }\n\n    @media only screen and (max-width: 1440px) {\n      max-width: 1010px;\n\n      .modal_body {\n        .multiple-templates {\n          grid-column: 1 / 3;\n        }\n      }\n    }\n\n    @media only screen and (min-width: 1441px) {\n      max-width: 1400px;\n\n      .modal_body {\n        .multiple-templates {\n          grid-column: 1 / 4;\n        }\n      }\n    }\n  }\n\n  .heading-element {\n    margin-left: -40px;\n    padding-left: 40px;\n    margin-right: -40px;\n    padding-right: 40px;\n    padding-bottom: 24px;\n    box-shadow: 0px 3px 3px ", ";\n  }\n\n  .section-controls {\n    position: absolute;\n    right: 40px;\n    bottom: 40px;\n    div {\n      overflow: visible;\n    }\n  }\n"])), colors.shadows.light);
var ModalBody = styled.div.attrs({ className: 'modal_body' })(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  margin-bottom: -40px;\n\n  > div:last-of-type {\n    display: grid;\n    grid-template-columns: repeat(auto-fill, 340px);\n    grid-gap: 16px;\n\n    .template-group {\n      .title {\n        margin-bottom: 10px;\n      }\n    }\n  }\n"], ["\n  display: flex;\n  margin-bottom: -40px;\n\n  > div:last-of-type {\n    display: grid;\n    grid-template-columns: repeat(auto-fill, 340px);\n    grid-gap: 16px;\n\n    .template-group {\n      .title {\n        margin-bottom: 10px;\n      }\n    }\n  }\n"])));
var ImageMapTemplateModal = function (_a) {
    var chatbotIdParam = _a.chatbotIdParam, adScenarioIdParam = _a.adScenarioIdParam, blockIdParam = _a.blockIdParam, onImageMapCreate = _a.onImageMapCreate, intl = _a.intl, rest = __rest(_a, ["chatbotIdParam", "adScenarioIdParam", "blockIdParam", "onImageMapCreate", "intl"]);
    var _b = __read(useState(DEFAULT_CHOSEN_TEMPLATE), 2), chosenTemplate = _b[0], setChosenTemplate = _b[1];
    var _c = __read(useState(0), 2), chosenTemplateGroup = _c[0], setChosenTemplateGroup = _c[1];
    var confirmTemplateSelect = function () {
        return onImageMapCreate({
            chatbotId: chatbotIdParam,
            scenarioId: adScenarioIdParam,
            blockId: blockIdParam,
            template: chosenTemplate,
        });
    };
    // This method will be rendered template group
    var TemplateGroup = function (templateGroup) {
        var shortedTemplateIds = sortBy(Object.keys(templateGroup.templates).map(function (key) { return ({
            id: key,
            sort: templateGroup.templates[key].sort,
        }); }), ['sort']).map(function (template) { return Number(template.id); });
        return (React.createElement(TemplateContainer, null, shortedTemplateIds.map(function (templateNum, index) { return (React.createElement(ImageMapTemplate, { key: "image-map-template-" + templateNum, "data-testid": "ImageMapTemplateModal-ImageMapTemplate-" + index, templateWidth: templateGroup.width, templateHeight: templateGroup.height, templateNum: templateNum, chosen: templateNum === chosenTemplate, onClick: function () { return setChosenTemplate(templateNum); }, isPreview: true })); })));
    };
    // Image map template modal has same logic with rich menu template modal
    // It shared ListTemplateGroupTitles and ListTemplateGroups
    return (React.createElement(ModalPortal, null,
        React.createElement(TemplateModalWrapper, null,
            React.createElement(Modal, __assign({ "data-testid": "image-map-template-modal", className: "image-map-template-modal", type: "SELECT", text: intl.formatMessage({ id: "3sbr4C", defaultMessage: "Create an Image Map" }), width: "100%" }, omit(rest, 'onImageMapCreate'), { onConfirm: confirmTemplateSelect, hasError: false, headingElement: React.createElement("div", { className: "heading-element" }) }),
                React.createElement(ModalBody, null,
                    React.createElement(ListTemplateGroupTitles, { chosenTemplateGroup: chosenTemplateGroup, templateGroupTitles: imageMapTemplates.map(function (_a) {
                            var title = _a.title, width = _a.width, height = _a.height;
                            return title ? intl.formatMessage(title) : width + " x " + height;
                        }), handleTemplateGroupSelect: setChosenTemplateGroup, indexSpacers: [11] }),
                    React.createElement(ListTemplateGroups, { chosenTemplateGroup: chosenTemplateGroup, templateGroups: imageMapTemplates, setChosenTemplateGroup: setChosenTemplateGroup }, TemplateGroup))))));
};
export default injectIntl(ImageMapTemplateModal);
var templateObject_1, templateObject_2, templateObject_3;
