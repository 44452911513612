var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { singleAdScenarioNormalizer, singleScenarioDenormalizer, } from '@src/lib/dataNormalizer';
function scenarioLayerBlockStateWithNewScenarioProperties(state, newScenarioProperties, scenarioId) {
    var scenarios = state.scenarios, blocks = state.blocks, layers = state.layers;
    var scenarioData = [scenarios[scenarioId]];
    var scenario = singleScenarioDenormalizer(scenarioData, layers, blocks);
    Object.entries(newScenarioProperties).forEach(function (_a) {
        var _b = __read(_a, 2), key = _b[0], val = _b[1];
        scenario[key] = val;
    });
    var scenarioNormaliazer = singleAdScenarioNormalizer(scenario);
    return {
        scenarios: Object.assign({}, state.scenarios, scenarioNormaliazer.scenarios),
        layers: Object.assign({}, state.layers, scenarioNormaliazer.layers),
        blocks: Object.keys(state.blocks).reduce(function (blocks, blockId) {
            var currentBlock = state.blocks[blockId];
            blocks[blockId] = currentBlock;
            if (blocks[blockId]) {
                blocks[blockId] = Object.assign({}, currentBlock, blocks[blockId]);
            }
            return blocks;
        }, scenarioNormaliazer.blocks),
    };
}
export default scenarioLayerBlockStateWithNewScenarioProperties;
