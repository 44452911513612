// source: entities/scenario_group.proto

/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

/* eslint-disable */
// @ts-nocheck
var jspb = require('google-protobuf');

var goog = jspb;
var global = Function('return this')();

var entities_entities_pb = require('../entities/entities_pb.js');

goog.object.extend(proto, entities_entities_pb);

var entities_message_pb = require('../entities/message_pb.js');

goog.object.extend(proto, entities_message_pb);

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');

goog.object.extend(proto, google_protobuf_timestamp_pb);
goog.exportSymbol('proto.e.InitialConversation', null, global);
goog.exportSymbol('proto.e.InitialConversationABTestGroup', null, global);
goog.exportSymbol('proto.e.InitialConversationABTestGroupStatusType', null, global);
goog.exportSymbol('proto.e.InitialConversationStatus', null, global);
goog.exportSymbol('proto.e.ScenarioVariant', null, global);
goog.exportSymbol('proto.e.ScenarioVariant.DetailsCase', null, global);
goog.exportSymbol('proto.e.ScenarioVariantCategoryType', null, global);
goog.exportSymbol('proto.e.ScenarioVariantGroup', null, global);
goog.exportSymbol('proto.e.ScenarioVariantGroup.DetailsCase', null, global);
goog.exportSymbol('proto.e.WeightScenarioVariant', null, global);
goog.exportSymbol('proto.e.WeightScenarioVariantGroup', null, global);
goog.exportSymbol('proto.e.WeightScenarioVariantGroupFunctionType', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */

proto.e.InitialConversationABTestGroup = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.e.InitialConversationABTestGroup, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.e.InitialConversationABTestGroup.displayName = 'proto.e.InitialConversationABTestGroup';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.e.InitialConversation = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.e.InitialConversation, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.e.InitialConversation.displayName = 'proto.e.InitialConversation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.e.ScenarioVariantGroup = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.e.ScenarioVariantGroup.repeatedFields_, proto.e.ScenarioVariantGroup.oneofGroups_);
};

goog.inherits(proto.e.ScenarioVariantGroup, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.e.ScenarioVariantGroup.displayName = 'proto.e.ScenarioVariantGroup';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.e.WeightScenarioVariantGroup = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.e.WeightScenarioVariantGroup, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.e.WeightScenarioVariantGroup.displayName = 'proto.e.WeightScenarioVariantGroup';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.e.ScenarioVariant = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.e.ScenarioVariant.oneofGroups_);
};

goog.inherits(proto.e.ScenarioVariant, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.e.ScenarioVariant.displayName = 'proto.e.ScenarioVariant';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.e.WeightScenarioVariant = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.e.WeightScenarioVariant, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.e.WeightScenarioVariant.displayName = 'proto.e.WeightScenarioVariant';
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.e.InitialConversationABTestGroup.prototype.toObject = function (opt_includeInstance) {
    return proto.e.InitialConversationABTestGroup.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.e.InitialConversationABTestGroup} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.e.InitialConversationABTestGroup.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, 0),
      chatbotId: jspb.Message.getFieldWithDefault(msg, 2, 0),
      scenarioVariantGroup: (f = msg.getScenarioVariantGroup()) && proto.e.ScenarioVariantGroup.toObject(includeInstance, f),
      statusType: jspb.Message.getFieldWithDefault(msg, 4, 0),
      record: (f = msg.getRecord()) && entities_entities_pb.Record.toObject(includeInstance, f),
      activateTime: (f = msg.getActivateTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
      inactivateTime: (f = msg.getInactivateTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.e.InitialConversationABTestGroup}
 */


proto.e.InitialConversationABTestGroup.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.e.InitialConversationABTestGroup();
  return proto.e.InitialConversationABTestGroup.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.e.InitialConversationABTestGroup} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.e.InitialConversationABTestGroup}
 */


proto.e.InitialConversationABTestGroup.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value =
        /** @type {number} */
        reader.readInt64();
        msg.setId(value);
        break;

      case 2:
        var value =
        /** @type {number} */
        reader.readInt64();
        msg.setChatbotId(value);
        break;

      case 3:
        var value = new proto.e.ScenarioVariantGroup();
        reader.readMessage(value, proto.e.ScenarioVariantGroup.deserializeBinaryFromReader);
        msg.setScenarioVariantGroup(value);
        break;

      case 4:
        var value =
        /** @type {!proto.e.InitialConversationABTestGroupStatusType} */
        reader.readEnum();
        msg.setStatusType(value);
        break;

      case 15:
        var value = new entities_entities_pb.Record();
        reader.readMessage(value, entities_entities_pb.Record.deserializeBinaryFromReader);
        msg.setRecord(value);
        break;

      case 16:
        var value = new google_protobuf_timestamp_pb.Timestamp();
        reader.readMessage(value, google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
        msg.setActivateTime(value);
        break;

      case 17:
        var value = new google_protobuf_timestamp_pb.Timestamp();
        reader.readMessage(value, google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
        msg.setInactivateTime(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.e.InitialConversationABTestGroup.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.e.InitialConversationABTestGroup.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.e.InitialConversationABTestGroup} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.e.InitialConversationABTestGroup.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getId();

  if (f !== 0) {
    writer.writeInt64(1, f);
  }

  f = message.getChatbotId();

  if (f !== 0) {
    writer.writeInt64(2, f);
  }

  f = message.getScenarioVariantGroup();

  if (f != null) {
    writer.writeMessage(3, f, proto.e.ScenarioVariantGroup.serializeBinaryToWriter);
  }

  f = message.getStatusType();

  if (f !== 0.0) {
    writer.writeEnum(4, f);
  }

  f = message.getRecord();

  if (f != null) {
    writer.writeMessage(15, f, entities_entities_pb.Record.serializeBinaryToWriter);
  }

  f = message.getActivateTime();

  if (f != null) {
    writer.writeMessage(16, f, google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter);
  }

  f = message.getInactivateTime();

  if (f != null) {
    writer.writeMessage(17, f, google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter);
  }
};
/**
 * optional int64 id = 1;
 * @return {number}
 */


proto.e.InitialConversationABTestGroup.prototype.getId = function () {
  return (
    /** @type {number} */
    jspb.Message.getFieldWithDefault(this, 1, 0)
  );
};
/**
 * @param {number} value
 * @return {!proto.e.InitialConversationABTestGroup} returns this
 */


proto.e.InitialConversationABTestGroup.prototype.setId = function (value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};
/**
 * optional int64 chatbot_id = 2;
 * @return {number}
 */


proto.e.InitialConversationABTestGroup.prototype.getChatbotId = function () {
  return (
    /** @type {number} */
    jspb.Message.getFieldWithDefault(this, 2, 0)
  );
};
/**
 * @param {number} value
 * @return {!proto.e.InitialConversationABTestGroup} returns this
 */


proto.e.InitialConversationABTestGroup.prototype.setChatbotId = function (value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};
/**
 * optional ScenarioVariantGroup scenario_variant_group = 3;
 * @return {?proto.e.ScenarioVariantGroup}
 */


proto.e.InitialConversationABTestGroup.prototype.getScenarioVariantGroup = function () {
  return (
    /** @type{?proto.e.ScenarioVariantGroup} */
    jspb.Message.getWrapperField(this, proto.e.ScenarioVariantGroup, 3)
  );
};
/**
 * @param {?proto.e.ScenarioVariantGroup|undefined} value
 * @return {!proto.e.InitialConversationABTestGroup} returns this
*/


proto.e.InitialConversationABTestGroup.prototype.setScenarioVariantGroup = function (value) {
  return jspb.Message.setWrapperField(this, 3, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.e.InitialConversationABTestGroup} returns this
 */


proto.e.InitialConversationABTestGroup.prototype.clearScenarioVariantGroup = function () {
  return this.setScenarioVariantGroup(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.e.InitialConversationABTestGroup.prototype.hasScenarioVariantGroup = function () {
  return jspb.Message.getField(this, 3) != null;
};
/**
 * optional InitialConversationABTestGroupStatusType status_type = 4;
 * @return {!proto.e.InitialConversationABTestGroupStatusType}
 */


proto.e.InitialConversationABTestGroup.prototype.getStatusType = function () {
  return (
    /** @type {!proto.e.InitialConversationABTestGroupStatusType} */
    jspb.Message.getFieldWithDefault(this, 4, 0)
  );
};
/**
 * @param {!proto.e.InitialConversationABTestGroupStatusType} value
 * @return {!proto.e.InitialConversationABTestGroup} returns this
 */


proto.e.InitialConversationABTestGroup.prototype.setStatusType = function (value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};
/**
 * optional Record record = 15;
 * @return {?proto.e.Record}
 */


proto.e.InitialConversationABTestGroup.prototype.getRecord = function () {
  return (
    /** @type{?proto.e.Record} */
    jspb.Message.getWrapperField(this, entities_entities_pb.Record, 15)
  );
};
/**
 * @param {?proto.e.Record|undefined} value
 * @return {!proto.e.InitialConversationABTestGroup} returns this
*/


proto.e.InitialConversationABTestGroup.prototype.setRecord = function (value) {
  return jspb.Message.setWrapperField(this, 15, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.e.InitialConversationABTestGroup} returns this
 */


proto.e.InitialConversationABTestGroup.prototype.clearRecord = function () {
  return this.setRecord(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.e.InitialConversationABTestGroup.prototype.hasRecord = function () {
  return jspb.Message.getField(this, 15) != null;
};
/**
 * optional google.protobuf.Timestamp activate_time = 16;
 * @return {?proto.google.protobuf.Timestamp}
 */


proto.e.InitialConversationABTestGroup.prototype.getActivateTime = function () {
  return (
    /** @type{?proto.google.protobuf.Timestamp} */
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 16)
  );
};
/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.e.InitialConversationABTestGroup} returns this
*/


proto.e.InitialConversationABTestGroup.prototype.setActivateTime = function (value) {
  return jspb.Message.setWrapperField(this, 16, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.e.InitialConversationABTestGroup} returns this
 */


proto.e.InitialConversationABTestGroup.prototype.clearActivateTime = function () {
  return this.setActivateTime(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.e.InitialConversationABTestGroup.prototype.hasActivateTime = function () {
  return jspb.Message.getField(this, 16) != null;
};
/**
 * optional google.protobuf.Timestamp inactivate_time = 17;
 * @return {?proto.google.protobuf.Timestamp}
 */


proto.e.InitialConversationABTestGroup.prototype.getInactivateTime = function () {
  return (
    /** @type{?proto.google.protobuf.Timestamp} */
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 17)
  );
};
/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.e.InitialConversationABTestGroup} returns this
*/


proto.e.InitialConversationABTestGroup.prototype.setInactivateTime = function (value) {
  return jspb.Message.setWrapperField(this, 17, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.e.InitialConversationABTestGroup} returns this
 */


proto.e.InitialConversationABTestGroup.prototype.clearInactivateTime = function () {
  return this.setInactivateTime(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.e.InitialConversationABTestGroup.prototype.hasInactivateTime = function () {
  return jspb.Message.getField(this, 17) != null;
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.e.InitialConversation.prototype.toObject = function (opt_includeInstance) {
    return proto.e.InitialConversation.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.e.InitialConversation} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.e.InitialConversation.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, 0),
      chatbotId: jspb.Message.getFieldWithDefault(msg, 2, 0),
      scenarioVariantGroup: (f = msg.getScenarioVariantGroup()) && proto.e.ScenarioVariantGroup.toObject(includeInstance, f),
      status: jspb.Message.getFieldWithDefault(msg, 4, 0),
      record: (f = msg.getRecord()) && entities_entities_pb.Record.toObject(includeInstance, f),
      activateTime: (f = msg.getActivateTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
      inactivateTime: (f = msg.getInactivateTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.e.InitialConversation}
 */


proto.e.InitialConversation.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.e.InitialConversation();
  return proto.e.InitialConversation.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.e.InitialConversation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.e.InitialConversation}
 */


proto.e.InitialConversation.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value =
        /** @type {number} */
        reader.readInt64();
        msg.setId(value);
        break;

      case 2:
        var value =
        /** @type {number} */
        reader.readInt64();
        msg.setChatbotId(value);
        break;

      case 3:
        var value = new proto.e.ScenarioVariantGroup();
        reader.readMessage(value, proto.e.ScenarioVariantGroup.deserializeBinaryFromReader);
        msg.setScenarioVariantGroup(value);
        break;

      case 4:
        var value =
        /** @type {!proto.e.InitialConversationStatus} */
        reader.readEnum();
        msg.setStatus(value);
        break;

      case 15:
        var value = new entities_entities_pb.Record();
        reader.readMessage(value, entities_entities_pb.Record.deserializeBinaryFromReader);
        msg.setRecord(value);
        break;

      case 16:
        var value = new google_protobuf_timestamp_pb.Timestamp();
        reader.readMessage(value, google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
        msg.setActivateTime(value);
        break;

      case 17:
        var value = new google_protobuf_timestamp_pb.Timestamp();
        reader.readMessage(value, google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
        msg.setInactivateTime(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.e.InitialConversation.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.e.InitialConversation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.e.InitialConversation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.e.InitialConversation.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getId();

  if (f !== 0) {
    writer.writeInt64(1, f);
  }

  f = message.getChatbotId();

  if (f !== 0) {
    writer.writeInt64(2, f);
  }

  f = message.getScenarioVariantGroup();

  if (f != null) {
    writer.writeMessage(3, f, proto.e.ScenarioVariantGroup.serializeBinaryToWriter);
  }

  f = message.getStatus();

  if (f !== 0.0) {
    writer.writeEnum(4, f);
  }

  f = message.getRecord();

  if (f != null) {
    writer.writeMessage(15, f, entities_entities_pb.Record.serializeBinaryToWriter);
  }

  f = message.getActivateTime();

  if (f != null) {
    writer.writeMessage(16, f, google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter);
  }

  f = message.getInactivateTime();

  if (f != null) {
    writer.writeMessage(17, f, google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter);
  }
};
/**
 * optional int64 id = 1;
 * @return {number}
 */


proto.e.InitialConversation.prototype.getId = function () {
  return (
    /** @type {number} */
    jspb.Message.getFieldWithDefault(this, 1, 0)
  );
};
/**
 * @param {number} value
 * @return {!proto.e.InitialConversation} returns this
 */


proto.e.InitialConversation.prototype.setId = function (value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};
/**
 * optional int64 chatbot_id = 2;
 * @return {number}
 */


proto.e.InitialConversation.prototype.getChatbotId = function () {
  return (
    /** @type {number} */
    jspb.Message.getFieldWithDefault(this, 2, 0)
  );
};
/**
 * @param {number} value
 * @return {!proto.e.InitialConversation} returns this
 */


proto.e.InitialConversation.prototype.setChatbotId = function (value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};
/**
 * optional ScenarioVariantGroup scenario_variant_group = 3;
 * @return {?proto.e.ScenarioVariantGroup}
 */


proto.e.InitialConversation.prototype.getScenarioVariantGroup = function () {
  return (
    /** @type{?proto.e.ScenarioVariantGroup} */
    jspb.Message.getWrapperField(this, proto.e.ScenarioVariantGroup, 3)
  );
};
/**
 * @param {?proto.e.ScenarioVariantGroup|undefined} value
 * @return {!proto.e.InitialConversation} returns this
*/


proto.e.InitialConversation.prototype.setScenarioVariantGroup = function (value) {
  return jspb.Message.setWrapperField(this, 3, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.e.InitialConversation} returns this
 */


proto.e.InitialConversation.prototype.clearScenarioVariantGroup = function () {
  return this.setScenarioVariantGroup(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.e.InitialConversation.prototype.hasScenarioVariantGroup = function () {
  return jspb.Message.getField(this, 3) != null;
};
/**
 * optional InitialConversationStatus status = 4;
 * @return {!proto.e.InitialConversationStatus}
 */


proto.e.InitialConversation.prototype.getStatus = function () {
  return (
    /** @type {!proto.e.InitialConversationStatus} */
    jspb.Message.getFieldWithDefault(this, 4, 0)
  );
};
/**
 * @param {!proto.e.InitialConversationStatus} value
 * @return {!proto.e.InitialConversation} returns this
 */


proto.e.InitialConversation.prototype.setStatus = function (value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};
/**
 * optional Record record = 15;
 * @return {?proto.e.Record}
 */


proto.e.InitialConversation.prototype.getRecord = function () {
  return (
    /** @type{?proto.e.Record} */
    jspb.Message.getWrapperField(this, entities_entities_pb.Record, 15)
  );
};
/**
 * @param {?proto.e.Record|undefined} value
 * @return {!proto.e.InitialConversation} returns this
*/


proto.e.InitialConversation.prototype.setRecord = function (value) {
  return jspb.Message.setWrapperField(this, 15, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.e.InitialConversation} returns this
 */


proto.e.InitialConversation.prototype.clearRecord = function () {
  return this.setRecord(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.e.InitialConversation.prototype.hasRecord = function () {
  return jspb.Message.getField(this, 15) != null;
};
/**
 * optional google.protobuf.Timestamp activate_time = 16;
 * @return {?proto.google.protobuf.Timestamp}
 */


proto.e.InitialConversation.prototype.getActivateTime = function () {
  return (
    /** @type{?proto.google.protobuf.Timestamp} */
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 16)
  );
};
/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.e.InitialConversation} returns this
*/


proto.e.InitialConversation.prototype.setActivateTime = function (value) {
  return jspb.Message.setWrapperField(this, 16, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.e.InitialConversation} returns this
 */


proto.e.InitialConversation.prototype.clearActivateTime = function () {
  return this.setActivateTime(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.e.InitialConversation.prototype.hasActivateTime = function () {
  return jspb.Message.getField(this, 16) != null;
};
/**
 * optional google.protobuf.Timestamp inactivate_time = 17;
 * @return {?proto.google.protobuf.Timestamp}
 */


proto.e.InitialConversation.prototype.getInactivateTime = function () {
  return (
    /** @type{?proto.google.protobuf.Timestamp} */
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 17)
  );
};
/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.e.InitialConversation} returns this
*/


proto.e.InitialConversation.prototype.setInactivateTime = function (value) {
  return jspb.Message.setWrapperField(this, 17, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.e.InitialConversation} returns this
 */


proto.e.InitialConversation.prototype.clearInactivateTime = function () {
  return this.setInactivateTime(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.e.InitialConversation.prototype.hasInactivateTime = function () {
  return jspb.Message.getField(this, 17) != null;
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */


proto.e.ScenarioVariantGroup.repeatedFields_ = [4];
/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */

proto.e.ScenarioVariantGroup.oneofGroups_ = [[30]];
/**
 * @enum {number}
 */

proto.e.ScenarioVariantGroup.DetailsCase = {
  DETAILS_NOT_SET: 0,
  WEIGHT_SCENARIO_VARIANT_GROUP: 30
};
/**
 * @return {proto.e.ScenarioVariantGroup.DetailsCase}
 */

proto.e.ScenarioVariantGroup.prototype.getDetailsCase = function () {
  return (
    /** @type {proto.e.ScenarioVariantGroup.DetailsCase} */
    jspb.Message.computeOneofCase(this, proto.e.ScenarioVariantGroup.oneofGroups_[0])
  );
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.e.ScenarioVariantGroup.prototype.toObject = function (opt_includeInstance) {
    return proto.e.ScenarioVariantGroup.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.e.ScenarioVariantGroup} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.e.ScenarioVariantGroup.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, 0),
      name: jspb.Message.getFieldWithDefault(msg, 2, ""),
      type: jspb.Message.getFieldWithDefault(msg, 3, 0),
      variantsList: jspb.Message.toObjectList(msg.getVariantsList(), proto.e.ScenarioVariant.toObject, includeInstance),
      record: (f = msg.getRecord()) && entities_entities_pb.Record.toObject(includeInstance, f),
      weightScenarioVariantGroup: (f = msg.getWeightScenarioVariantGroup()) && proto.e.WeightScenarioVariantGroup.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.e.ScenarioVariantGroup}
 */


proto.e.ScenarioVariantGroup.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.e.ScenarioVariantGroup();
  return proto.e.ScenarioVariantGroup.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.e.ScenarioVariantGroup} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.e.ScenarioVariantGroup}
 */


proto.e.ScenarioVariantGroup.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value =
        /** @type {number} */
        reader.readInt64();
        msg.setId(value);
        break;

      case 2:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setName(value);
        break;

      case 3:
        var value =
        /** @type {!proto.e.ScenarioVariantCategoryType} */
        reader.readEnum();
        msg.setType(value);
        break;

      case 4:
        var value = new proto.e.ScenarioVariant();
        reader.readMessage(value, proto.e.ScenarioVariant.deserializeBinaryFromReader);
        msg.addVariants(value);
        break;

      case 15:
        var value = new entities_entities_pb.Record();
        reader.readMessage(value, entities_entities_pb.Record.deserializeBinaryFromReader);
        msg.setRecord(value);
        break;

      case 30:
        var value = new proto.e.WeightScenarioVariantGroup();
        reader.readMessage(value, proto.e.WeightScenarioVariantGroup.deserializeBinaryFromReader);
        msg.setWeightScenarioVariantGroup(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.e.ScenarioVariantGroup.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.e.ScenarioVariantGroup.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.e.ScenarioVariantGroup} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.e.ScenarioVariantGroup.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getId();

  if (f !== 0) {
    writer.writeInt64(1, f);
  }

  f = message.getName();

  if (f.length > 0) {
    writer.writeString(2, f);
  }

  f = message.getType();

  if (f !== 0.0) {
    writer.writeEnum(3, f);
  }

  f = message.getVariantsList();

  if (f.length > 0) {
    writer.writeRepeatedMessage(4, f, proto.e.ScenarioVariant.serializeBinaryToWriter);
  }

  f = message.getRecord();

  if (f != null) {
    writer.writeMessage(15, f, entities_entities_pb.Record.serializeBinaryToWriter);
  }

  f = message.getWeightScenarioVariantGroup();

  if (f != null) {
    writer.writeMessage(30, f, proto.e.WeightScenarioVariantGroup.serializeBinaryToWriter);
  }
};
/**
 * optional int64 id = 1;
 * @return {number}
 */


proto.e.ScenarioVariantGroup.prototype.getId = function () {
  return (
    /** @type {number} */
    jspb.Message.getFieldWithDefault(this, 1, 0)
  );
};
/**
 * @param {number} value
 * @return {!proto.e.ScenarioVariantGroup} returns this
 */


proto.e.ScenarioVariantGroup.prototype.setId = function (value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};
/**
 * optional string name = 2;
 * @return {string}
 */


proto.e.ScenarioVariantGroup.prototype.getName = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 2, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.e.ScenarioVariantGroup} returns this
 */


proto.e.ScenarioVariantGroup.prototype.setName = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};
/**
 * optional ScenarioVariantCategoryType type = 3;
 * @return {!proto.e.ScenarioVariantCategoryType}
 */


proto.e.ScenarioVariantGroup.prototype.getType = function () {
  return (
    /** @type {!proto.e.ScenarioVariantCategoryType} */
    jspb.Message.getFieldWithDefault(this, 3, 0)
  );
};
/**
 * @param {!proto.e.ScenarioVariantCategoryType} value
 * @return {!proto.e.ScenarioVariantGroup} returns this
 */


proto.e.ScenarioVariantGroup.prototype.setType = function (value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};
/**
 * repeated ScenarioVariant variants = 4;
 * @return {!Array<!proto.e.ScenarioVariant>}
 */


proto.e.ScenarioVariantGroup.prototype.getVariantsList = function () {
  return (
    /** @type{!Array<!proto.e.ScenarioVariant>} */
    jspb.Message.getRepeatedWrapperField(this, proto.e.ScenarioVariant, 4)
  );
};
/**
 * @param {!Array<!proto.e.ScenarioVariant>} value
 * @return {!proto.e.ScenarioVariantGroup} returns this
*/


proto.e.ScenarioVariantGroup.prototype.setVariantsList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};
/**
 * @param {!proto.e.ScenarioVariant=} opt_value
 * @param {number=} opt_index
 * @return {!proto.e.ScenarioVariant}
 */


proto.e.ScenarioVariantGroup.prototype.addVariants = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.e.ScenarioVariant, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.e.ScenarioVariantGroup} returns this
 */


proto.e.ScenarioVariantGroup.prototype.clearVariantsList = function () {
  return this.setVariantsList([]);
};
/**
 * optional Record record = 15;
 * @return {?proto.e.Record}
 */


proto.e.ScenarioVariantGroup.prototype.getRecord = function () {
  return (
    /** @type{?proto.e.Record} */
    jspb.Message.getWrapperField(this, entities_entities_pb.Record, 15)
  );
};
/**
 * @param {?proto.e.Record|undefined} value
 * @return {!proto.e.ScenarioVariantGroup} returns this
*/


proto.e.ScenarioVariantGroup.prototype.setRecord = function (value) {
  return jspb.Message.setWrapperField(this, 15, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.e.ScenarioVariantGroup} returns this
 */


proto.e.ScenarioVariantGroup.prototype.clearRecord = function () {
  return this.setRecord(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.e.ScenarioVariantGroup.prototype.hasRecord = function () {
  return jspb.Message.getField(this, 15) != null;
};
/**
 * optional WeightScenarioVariantGroup weight_scenario_variant_group = 30;
 * @return {?proto.e.WeightScenarioVariantGroup}
 */


proto.e.ScenarioVariantGroup.prototype.getWeightScenarioVariantGroup = function () {
  return (
    /** @type{?proto.e.WeightScenarioVariantGroup} */
    jspb.Message.getWrapperField(this, proto.e.WeightScenarioVariantGroup, 30)
  );
};
/**
 * @param {?proto.e.WeightScenarioVariantGroup|undefined} value
 * @return {!proto.e.ScenarioVariantGroup} returns this
*/


proto.e.ScenarioVariantGroup.prototype.setWeightScenarioVariantGroup = function (value) {
  return jspb.Message.setOneofWrapperField(this, 30, proto.e.ScenarioVariantGroup.oneofGroups_[0], value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.e.ScenarioVariantGroup} returns this
 */


proto.e.ScenarioVariantGroup.prototype.clearWeightScenarioVariantGroup = function () {
  return this.setWeightScenarioVariantGroup(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.e.ScenarioVariantGroup.prototype.hasWeightScenarioVariantGroup = function () {
  return jspb.Message.getField(this, 30) != null;
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.e.WeightScenarioVariantGroup.prototype.toObject = function (opt_includeInstance) {
    return proto.e.WeightScenarioVariantGroup.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.e.WeightScenarioVariantGroup} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.e.WeightScenarioVariantGroup.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      functionType: jspb.Message.getFieldWithDefault(msg, 1, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.e.WeightScenarioVariantGroup}
 */


proto.e.WeightScenarioVariantGroup.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.e.WeightScenarioVariantGroup();
  return proto.e.WeightScenarioVariantGroup.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.e.WeightScenarioVariantGroup} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.e.WeightScenarioVariantGroup}
 */


proto.e.WeightScenarioVariantGroup.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value =
        /** @type {!proto.e.WeightScenarioVariantGroupFunctionType} */
        reader.readEnum();
        msg.setFunctionType(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.e.WeightScenarioVariantGroup.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.e.WeightScenarioVariantGroup.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.e.WeightScenarioVariantGroup} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.e.WeightScenarioVariantGroup.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getFunctionType();

  if (f !== 0.0) {
    writer.writeEnum(1, f);
  }
};
/**
 * optional WeightScenarioVariantGroupFunctionType function_type = 1;
 * @return {!proto.e.WeightScenarioVariantGroupFunctionType}
 */


proto.e.WeightScenarioVariantGroup.prototype.getFunctionType = function () {
  return (
    /** @type {!proto.e.WeightScenarioVariantGroupFunctionType} */
    jspb.Message.getFieldWithDefault(this, 1, 0)
  );
};
/**
 * @param {!proto.e.WeightScenarioVariantGroupFunctionType} value
 * @return {!proto.e.WeightScenarioVariantGroup} returns this
 */


proto.e.WeightScenarioVariantGroup.prototype.setFunctionType = function (value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};
/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */


proto.e.ScenarioVariant.oneofGroups_ = [[30]];
/**
 * @enum {number}
 */

proto.e.ScenarioVariant.DetailsCase = {
  DETAILS_NOT_SET: 0,
  WEIGHT_SCENARIO_VARIANT: 30
};
/**
 * @return {proto.e.ScenarioVariant.DetailsCase}
 */

proto.e.ScenarioVariant.prototype.getDetailsCase = function () {
  return (
    /** @type {proto.e.ScenarioVariant.DetailsCase} */
    jspb.Message.computeOneofCase(this, proto.e.ScenarioVariant.oneofGroups_[0])
  );
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.e.ScenarioVariant.prototype.toObject = function (opt_includeInstance) {
    return proto.e.ScenarioVariant.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.e.ScenarioVariant} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.e.ScenarioVariant.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      scenario: (f = msg.getScenario()) && entities_message_pb.Scenario.toObject(includeInstance, f),
      type: jspb.Message.getFieldWithDefault(msg, 2, 0),
      record: (f = msg.getRecord()) && entities_entities_pb.Record.toObject(includeInstance, f),
      weightScenarioVariant: (f = msg.getWeightScenarioVariant()) && proto.e.WeightScenarioVariant.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.e.ScenarioVariant}
 */


proto.e.ScenarioVariant.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.e.ScenarioVariant();
  return proto.e.ScenarioVariant.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.e.ScenarioVariant} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.e.ScenarioVariant}
 */


proto.e.ScenarioVariant.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value = new entities_message_pb.Scenario();
        reader.readMessage(value, entities_message_pb.Scenario.deserializeBinaryFromReader);
        msg.setScenario(value);
        break;

      case 2:
        var value =
        /** @type {!proto.e.ScenarioVariantCategoryType} */
        reader.readEnum();
        msg.setType(value);
        break;

      case 15:
        var value = new entities_entities_pb.Record();
        reader.readMessage(value, entities_entities_pb.Record.deserializeBinaryFromReader);
        msg.setRecord(value);
        break;

      case 30:
        var value = new proto.e.WeightScenarioVariant();
        reader.readMessage(value, proto.e.WeightScenarioVariant.deserializeBinaryFromReader);
        msg.setWeightScenarioVariant(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.e.ScenarioVariant.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.e.ScenarioVariant.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.e.ScenarioVariant} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.e.ScenarioVariant.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getScenario();

  if (f != null) {
    writer.writeMessage(1, f, entities_message_pb.Scenario.serializeBinaryToWriter);
  }

  f = message.getType();

  if (f !== 0.0) {
    writer.writeEnum(2, f);
  }

  f = message.getRecord();

  if (f != null) {
    writer.writeMessage(15, f, entities_entities_pb.Record.serializeBinaryToWriter);
  }

  f = message.getWeightScenarioVariant();

  if (f != null) {
    writer.writeMessage(30, f, proto.e.WeightScenarioVariant.serializeBinaryToWriter);
  }
};
/**
 * optional Scenario scenario = 1;
 * @return {?proto.e.Scenario}
 */


proto.e.ScenarioVariant.prototype.getScenario = function () {
  return (
    /** @type{?proto.e.Scenario} */
    jspb.Message.getWrapperField(this, entities_message_pb.Scenario, 1)
  );
};
/**
 * @param {?proto.e.Scenario|undefined} value
 * @return {!proto.e.ScenarioVariant} returns this
*/


proto.e.ScenarioVariant.prototype.setScenario = function (value) {
  return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.e.ScenarioVariant} returns this
 */


proto.e.ScenarioVariant.prototype.clearScenario = function () {
  return this.setScenario(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.e.ScenarioVariant.prototype.hasScenario = function () {
  return jspb.Message.getField(this, 1) != null;
};
/**
 * optional ScenarioVariantCategoryType type = 2;
 * @return {!proto.e.ScenarioVariantCategoryType}
 */


proto.e.ScenarioVariant.prototype.getType = function () {
  return (
    /** @type {!proto.e.ScenarioVariantCategoryType} */
    jspb.Message.getFieldWithDefault(this, 2, 0)
  );
};
/**
 * @param {!proto.e.ScenarioVariantCategoryType} value
 * @return {!proto.e.ScenarioVariant} returns this
 */


proto.e.ScenarioVariant.prototype.setType = function (value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};
/**
 * optional Record record = 15;
 * @return {?proto.e.Record}
 */


proto.e.ScenarioVariant.prototype.getRecord = function () {
  return (
    /** @type{?proto.e.Record} */
    jspb.Message.getWrapperField(this, entities_entities_pb.Record, 15)
  );
};
/**
 * @param {?proto.e.Record|undefined} value
 * @return {!proto.e.ScenarioVariant} returns this
*/


proto.e.ScenarioVariant.prototype.setRecord = function (value) {
  return jspb.Message.setWrapperField(this, 15, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.e.ScenarioVariant} returns this
 */


proto.e.ScenarioVariant.prototype.clearRecord = function () {
  return this.setRecord(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.e.ScenarioVariant.prototype.hasRecord = function () {
  return jspb.Message.getField(this, 15) != null;
};
/**
 * optional WeightScenarioVariant weight_scenario_variant = 30;
 * @return {?proto.e.WeightScenarioVariant}
 */


proto.e.ScenarioVariant.prototype.getWeightScenarioVariant = function () {
  return (
    /** @type{?proto.e.WeightScenarioVariant} */
    jspb.Message.getWrapperField(this, proto.e.WeightScenarioVariant, 30)
  );
};
/**
 * @param {?proto.e.WeightScenarioVariant|undefined} value
 * @return {!proto.e.ScenarioVariant} returns this
*/


proto.e.ScenarioVariant.prototype.setWeightScenarioVariant = function (value) {
  return jspb.Message.setOneofWrapperField(this, 30, proto.e.ScenarioVariant.oneofGroups_[0], value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.e.ScenarioVariant} returns this
 */


proto.e.ScenarioVariant.prototype.clearWeightScenarioVariant = function () {
  return this.setWeightScenarioVariant(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.e.ScenarioVariant.prototype.hasWeightScenarioVariant = function () {
  return jspb.Message.getField(this, 30) != null;
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.e.WeightScenarioVariant.prototype.toObject = function (opt_includeInstance) {
    return proto.e.WeightScenarioVariant.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.e.WeightScenarioVariant} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.e.WeightScenarioVariant.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      weight: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.e.WeightScenarioVariant}
 */


proto.e.WeightScenarioVariant.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.e.WeightScenarioVariant();
  return proto.e.WeightScenarioVariant.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.e.WeightScenarioVariant} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.e.WeightScenarioVariant}
 */


proto.e.WeightScenarioVariant.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value =
        /** @type {number} */
        reader.readFloat();
        msg.setWeight(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.e.WeightScenarioVariant.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.e.WeightScenarioVariant.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.e.WeightScenarioVariant} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.e.WeightScenarioVariant.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getWeight();

  if (f !== 0.0) {
    writer.writeFloat(1, f);
  }
};
/**
 * optional float weight = 1;
 * @return {number}
 */


proto.e.WeightScenarioVariant.prototype.getWeight = function () {
  return (
    /** @type {number} */
    jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0)
  );
};
/**
 * @param {number} value
 * @return {!proto.e.WeightScenarioVariant} returns this
 */


proto.e.WeightScenarioVariant.prototype.setWeight = function (value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};
/**
 * @enum {number}
 */


proto.e.InitialConversationABTestGroupStatusType = {
  INITIAL_CONVERSATION_AB_TEST_GROUP_STATUS_TYPE_UNSPECIFIED: 0,
  INITIAL_CONVERSATION_AB_TEST_GROUP_STATUS_TYPE_DRAFT: 1,
  INITIAL_CONVERSATION_AB_TEST_GROUP_STATUS_TYPE_ACTIVE: 2,
  INITIAL_CONVERSATION_AB_TEST_GROUP_STATUS_TYPE_INACTIVE: 3
};
/**
 * @enum {number}
 */

proto.e.InitialConversationStatus = {
  INITIAL_CONVERSATION_STATUS_UNSPECIFIED: 0,
  INITIAL_CONVERSATION_STATUS_DRAFT: 1,
  INITIAL_CONVERSATION_STATUS_ACTIVE: 2,
  INITIAL_CONVERSATION_STATUS_INACTIVE: 3
};
/**
 * @enum {number}
 */

proto.e.ScenarioVariantCategoryType = {
  SCENARIO_VARIANT_CATEGORY_TYPE_UNSPECIFIED: 0,
  SCENARIO_VARIANT_CATEGORY_TYPE_WEIGHT: 1
};
/**
 * @enum {number}
 */

proto.e.WeightScenarioVariantGroupFunctionType = {
  WEIGHT_SCENARIO_VARIANT_GROUP_FN_TYPE_UNSPECIFIED: 0,
  WEIGHT_SCENARIO_VARIANT_GROUP_FN_TYPE_EQUAL_WEIGHT: 1,
  WEIGHT_SCENARIO_VARIANT_GROUP_FN_TYPE_CUSTOM_WEIGHT: 2
};
goog.object.extend(exports, proto.e);