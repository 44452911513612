import endpoints from '@src/endpoints';
import { makeFetchInit } from '@src/lib/helpers';
import { SendType } from '@src/typings/enums';
import { clampWithDefault } from '@src/lib/helpers/clamp';
export var defaultAdScenariosQueryParams = {
    per: 10,
    page: 1,
    search: '',
};
export var MAX_SCENARIOS_PAGE = 10000;
export var MIN_SCENARIOS_PER = 10;
export var MAX_SCENARIOS_PER = 100;
export var getSafeAdScenariosQueryParams = function (_a) {
    var page = _a.page, per = _a.per, search = _a.search;
    var clampPage = clampWithDefault(1, MAX_SCENARIOS_PAGE, defaultAdScenariosQueryParams.page);
    var clampPer = clampWithDefault(MIN_SCENARIOS_PER, MAX_SCENARIOS_PER, defaultAdScenariosQueryParams.per);
    return {
        page: clampPage(page),
        per: clampPer(per),
        search: search || defaultAdScenariosQueryParams.search,
    };
};
export var getAdScenariosUrlQueryString = function (queryParams) {
    var _a = getSafeAdScenariosQueryParams(queryParams), page = _a.page, per = _a.per, search = _a.search;
    var pagination = "?per=" + per + "&page=" + page;
    return search ? pagination + "&search=" + search : pagination;
};
export var makeBlockUrl = function (_a) {
    var chatTypeParam = _a.chatTypeParam, chatbotIdParam = _a.chatbotIdParam, adScenarioIdParam = _a.adScenarioIdParam, layerIdParam = _a.layerIdParam, id = _a.id, layerRank = _a.layerRank, locationSearch = _a.locationSearch;
    var blockUrl = "/dashboard/ad/" + chatTypeParam + "/chatbots/" + chatbotIdParam + "/scenarios/" + adScenarioIdParam + "/layers/" + layerIdParam + "/blocks/" + id + "/rank/" + layerRank;
    return locationSearch ? "" + blockUrl + locationSearch : blockUrl;
};
export var makeAdUrl = function (_a) {
    var chatTypeParam = _a.chatTypeParam, chatbotId = _a.chatbotId, adScenarioId = _a.adScenarioId, layerId = _a.layerId, blockId = _a.blockId, rank = _a.rank, locationSearch = _a.locationSearch;
    if (!(chatTypeParam && chatbotId && adScenarioId)) {
        throw new Error('chatTypeParam, chatbotId, and adScenarioId are required');
    }
    var uri;
    if (rank && typeof rank === 'number') {
        uri = "/dashboard/ad/" + chatTypeParam + "/chatbots/" + chatbotId + "/scenarios/" + adScenarioId + "/layers/" + layerId + "/blocks/" + blockId + "/rank/" + rank;
    }
    else if (blockId) {
        uri = "/dashboard/ad/" + chatTypeParam + "/chatbots/" + chatbotId + "/scenarios/" + adScenarioId + "/layers/" + layerId + "/blocks/" + blockId;
    }
    else if (layerId) {
        uri = "/dashboard/ad/" + chatTypeParam + "/chatbots/" + chatbotId + "/scenarios/" + adScenarioId + "/layers/" + layerId;
    }
    else {
        uri = "/dashboard/ad/" + chatTypeParam + "/chatbots/" + chatbotId + "/scenarios/" + adScenarioId;
    }
    return locationSearch ? "" + uri + locationSearch : uri;
};
export var renderInputImage = function (_a) {
    var checked = _a.checked, disabled = _a.disabled, checkedImg = _a.checkedImg, emptyImg = _a.emptyImg, disabledImg = _a.disabledImg;
    if (disabled)
        return disabledImg;
    if (checked)
        return checkedImg;
    return emptyImg;
};
// This function will get you all the "connectedIds" of a "connected object"
// "connected" in this context means the connected Block or Scenario
// This is usually used to check whether something should display some "connected" UI
export function getConnectedIds(connectedObject) {
    var res = {
        block_id: null,
        next_scenario_id: null,
        scenario_id: null,
        scenarioId: null,
        blockId: null,
        nextScenarioId: null,
    };
    if ('block_id' in connectedObject) {
        res.block_id = connectedObject.block_id;
        res.blockId = connectedObject.block_id;
    }
    if ('next_scenario_id' in connectedObject) {
        res.scenario_id = connectedObject.next_scenario_id;
        res.scenarioId = connectedObject.next_scenario_id;
        res.next_scenario_id = connectedObject.next_scenario_id;
        res.nextScenarioId = connectedObject.next_scenario_id;
    }
    if ('block' in connectedObject && connectedObject.block) {
        res.block_id = connectedObject.block.id;
        res.blockId = connectedObject.block.id;
    }
    if ('postback_action' in connectedObject && connectedObject.postback_action) {
        res.block_id = connectedObject.postback_action.block_id;
        res.blockId = res.block_id;
        res.scenario_id = connectedObject.postback_action.scenario_id;
        res.scenarioId = res.scenario_id;
        res.next_scenario_id = res.scenario_id;
        res.nextScenarioId = res.scenario_id;
    }
    return res;
}
export function isPostbackConnected(postbackAction) {
    return (!!postbackAction &&
        (!!postbackAction.block_id || !!postbackAction.scenario_id));
}
export var getAdIconNextBlockType = function (_a) {
    var adScenarios = _a.adScenarios, connectedObject = _a.connectedObject;
    var _b = getConnectedIds(connectedObject), blockId = _b.blockId, scenarioId = _b.scenarioId, nextScenarioId = _b.nextScenarioId;
    if (blockId)
        return false;
    var id = nextScenarioId || scenarioId;
    if (id) {
        var scenario = adScenarios[id];
        return scenario && scenario.chat_type;
    }
    return false;
};
export function getSendType(scenario) {
    return scenario.filter_id ? SendType.Filtered : SendType.All;
}
export function fetchLogin(email, password) {
    var url = endpoints.login.emailUser();
    var body = { email_user: { email: email, password: password } };
    return fetch(url, makeFetchInit('POST', body))
        .then(function (res) { return res.json(); })
        .then(function (_a) {
        var ok = _a.ok, account = _a.account, msg = _a.msg;
        // Account is returned if already log
        if (!(ok || account))
            return { ok: false, msg: msg };
        var response = {
            ok: true,
            account: account,
        };
        return response;
    });
}
export function fetchFbLogin(redirect_path) {
    var url = endpoints.login.fb();
    var body = redirect_path;
    return fetch(url, makeFetchInit('POST', body))
        .then(function (res) { return res.json(); })
        .then(function (_a) {
        var url = _a.url;
        document.location.href = url;
        return url;
    });
}
export function fetchSignup(email, password) {
    var url = endpoints.login.collection();
    var body = { email_user: { email: email, password: password } };
    return fetch(url, makeFetchInit('POST', body))
        .then(function (res) { return res.json(); })
        .then(function (_a) {
        var ok = _a.ok, client_user = _a.client_user, status = _a.status, message = _a.message;
        if (status === 422) {
            throw new Error(message);
        }
        var response = { ok: ok, clientUser: client_user };
        return response;
    });
}
export function fetchEmailPassword(email) {
    var url = endpoints.login.password();
    var body = { email_user: { email: email } };
    return fetch(url, makeFetchInit('POST', body)).then(function (res) { return res.json(); });
}
