var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import uniq from 'lodash/uniq';
import flattenDeep from 'lodash/flattenDeep';
function getButtonNextConnectedIds(buttonItems, connectionKeyName) {
    // gets the button connected ids
    return buttonItems.reduce(function (acc, buttonItem) {
        if (buttonItem.button.postback_action &&
            buttonItem.button.postback_action[connectionKeyName])
            return __spreadArray(__spreadArray([], __read(acc)), [buttonItem.button.postback_action[connectionKeyName]]);
        return acc;
    }, []);
}
function getTextMessageNextConnectedIds(messages, connectionKeyName) {
    if (connectionKeyName === void 0) { connectionKeyName = 'block_id'; }
    // filter all messages with button templates and add the button items to the array
    var buttonItems = messages
        .filter(function (message) { return message.button_template; })
        .map(function (textMessage) { return textMessage.button_template.button_items; });
    return getButtonNextConnectedIds(flattenDeep(buttonItems), connectionKeyName);
}
function getGenericMessageNextConnectedIds(messages, connectionKeyName) {
    if (connectionKeyName === void 0) { connectionKeyName = 'block_id'; }
    var buttonItems = messages
        .filter(function (message) { return message.generic_template; }) // filters only generic messages
        .reduce(function (acc, genericMessage) {
        var genericItems = genericMessage.generic_template.generic_items;
        // for each generic message go through the items and grab only the items with buttons
        var genericItemsWithButtons = genericItems
            .filter(function (genericItem) { return genericItem.generic_item_buttons.length; })
            .map(function (genericItemWithButtons) {
            return genericItemWithButtons.generic_item_buttons;
        });
        // if that generic item has buttons then add the array to the accumulator array
        if (genericItemsWithButtons.length)
            return __spreadArray(__spreadArray([], __read(acc)), [genericItemsWithButtons]);
        return acc;
    }, []);
    // flatten out the array of button item arrays so that it will be an array of button item objects
    // and get the connected ids
    return getButtonNextConnectedIds(flattenDeep(buttonItems), connectionKeyName);
}
function getAreaNextConnectedIds(areas, connectionKeyName) {
    // gets the area connected ids
    return areas.reduce(function (acc, area) {
        if (area.postback_action && area.postback_action[connectionKeyName])
            return __spreadArray(__spreadArray([], __read(acc)), [area.postback_action[connectionKeyName]]);
        return acc;
    }, []);
}
function getImageMapMessageNextConnectedIds(messages, connectionKeyName) {
    if (connectionKeyName === void 0) { connectionKeyName = 'block_id'; }
    // filter all messages with image maps and add the image map areas to the array
    var imageMapAreas = messages
        .filter(function (message) { return message.image_map; })
        .map(function (imageMapMessage) { return imageMapMessage.image_map.image_map_areas; });
    return getAreaNextConnectedIds(flattenDeep(imageMapAreas), connectionKeyName);
}
function getRichMenuMessageNextConnectedIds(messages, connectionKeyName) {
    if (connectionKeyName === void 0) { connectionKeyName = 'block_id'; }
    // filter all messages with rich menus and add the rich menu areas to the array
    var richMenuAreas = messages
        .filter(function (message) { return message.rich_menu; })
        .map(function (richMenuMessage) { return richMenuMessage.rich_menu.rich_menu_areas; });
    return getAreaNextConnectedIds(flattenDeep(richMenuAreas), connectionKeyName);
}
function getFlexMessageNextConnectedIds(messages, connectionKeyName) {
    if (connectionKeyName === void 0) { connectionKeyName = 'block_id'; }
    // filter all messages with flex message and add the buttons to the array
    var lineFlexMessages = messages.filter(function (message) { return message.line_flex_message && message.line_flex_message.contents; });
    var connectionIds = [];
    lineFlexMessages.forEach(function (lineFlexMessage) {
        var contents = lineFlexMessage.line_flex_message.contents.contents;
        contents.forEach(function (content) {
            ['body', 'footer'].forEach(function (field) {
                if (!content[field])
                    return;
                content[field].contents.forEach(function (_content) {
                    if (_content.type !== 'button')
                        return;
                    var pa = _content.action.postback_action;
                    if (!pa[connectionKeyName])
                        return;
                    connectionIds.push(pa[connectionKeyName]);
                });
            });
        });
    });
    return connectionIds;
}
function getQuickReplyNextConnectedIds(currentBlock, connectionKeyName) {
    if (connectionKeyName === void 0) { connectionKeyName = 'block_id'; }
    return currentBlock.quick_reply.quick_reply_choices
        .filter(function (choice) {
        return choice.postback_action && choice.postback_action[connectionKeyName];
    })
        .map(function (choice) { return choice.postback_action[connectionKeyName]; })
        .reduce(function (ids, id) {
        if (ids.indexOf(id) === -1)
            ids.push(id);
        return ids;
    }, []);
}
function getTalkTriggerNextConnectedIds(trigger, connectionKeyName) {
    // next scenario hasn't been implemented on the backend
    return [
        trigger.postback_action && trigger.postback_action[connectionKeyName],
    ];
}
function getNextConnections(activeBlock, activeMessages, adScenario, connectionKeyName) {
    if (connectionKeyName === void 0) { connectionKeyName = 'block_id'; }
    var blocks = adScenario.blocks, scenarios = adScenario.scenarios;
    var nextConnectedIds = [];
    if (activeBlock && activeBlock.quick_reply && blocks) {
        var quickReplyNextConnectedIds = getQuickReplyNextConnectedIds(activeBlock, connectionKeyName);
        nextConnectedIds = __spreadArray(__spreadArray([], __read(nextConnectedIds)), __read(quickReplyNextConnectedIds));
    }
    if (activeBlock && activeBlock.talk_trigger && blocks) {
        var talkTriggerNextConnectedIds = getTalkTriggerNextConnectedIds(activeBlock.talk_trigger, connectionKeyName);
        nextConnectedIds = __spreadArray(__spreadArray([], __read(nextConnectedIds)), __read(talkTriggerNextConnectedIds));
    }
    nextConnectedIds = __spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray([], __read(nextConnectedIds)), __read(getTextMessageNextConnectedIds(activeMessages, connectionKeyName))), __read(getGenericMessageNextConnectedIds(activeMessages, connectionKeyName))), __read(getImageMapMessageNextConnectedIds(activeMessages, connectionKeyName))), __read(getRichMenuMessageNextConnectedIds(activeMessages, connectionKeyName))), __read(getFlexMessageNextConnectedIds(activeMessages, connectionKeyName)));
    // after getting all the next block ids, make sure all of them are unique
    return uniq(nextConnectedIds)
        .map(function (id) {
        return connectionKeyName === 'block_id' ? blocks[id] : scenarios[id];
    })
        .filter(function (connectedObj) { return connectedObj; });
}
export { getNextConnections };
