import { SelectedTab, RightMenuType, } from '../types/rightMenu';
export var initialState = {
    isRightMenuExpanded: false,
    rightMenuSelectedTab: SelectedTab.None,
    deliveryOption: 'scheduled',
};
export default (function (state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case RightMenuType.TOGGLE_RIGHT_MENU:
            return Object.assign({}, state, {
                isRightMenuExpanded: action.boolValue,
            });
        case RightMenuType.CHANGE_RIGHT_MENU_TAB:
            return Object.assign({}, state, {
                rightMenuSelectedTab: action.tabName,
            });
        case RightMenuType.HANDLE_DELIVERY_OPTION_CHANGE:
            return Object.assign({}, state, {
                deliveryOption: action.deliveryOption,
            });
        default:
            return state;
    }
});
