var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import { colors, shadows } from '@src/colors';
import { transitions } from '@src/lib/animations';
import { hoverHighlight } from '@src/lib/mixins';
import useComponentVisible from '@src/components/hooks/useComponentVisible';
var IconButton = styled.span(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  font-size: 1.2rem;\n  color: ", ";\n  vertical-align: baseline;\n  ", ";\n"], ["\n  font-size: 1.2rem;\n  color: ", ";\n  vertical-align: baseline;\n  ", ";\n"])), colors.placeholder, hoverHighlight);
var renderBorderRadius = function (tagLength) {
    if (tagLength === 1) {
        return "\n      .box {\n        border-radius: 8px;\n        box-shadow: " + shadows.medium.light + ";\n      }\n    ";
    }
    else {
        return "\n      .box:first-child {\n        border-top-left-radius: 8px;\n        border-top-right-radius: 8px;\n      }\n\n      .box:last-child {\n        border-bottom-left-radius: 8px;\n        border-bottom-right-radius: 8px;\n      }\n    ";
    }
};
var TagsWrapper = styled.span(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  position: absolute;\n  z-index: 1000;\n  left: 270px;\n  box-shadow: ", ";\n  border-radius: 8px;\n\n  .box {\n    width: 150px;\n    background-color: ", ";\n    color: #1739a5;\n    font-size: 14px;\n    box-sizing: border-box;\n    position: relative;\n    line-height: 50px;\n    vertical-align: middle;\n    text-align: center;\n  }\n\n  ", "\n\n  .box:first-child:before {\n    content: '';\n    position: absolute;\n    top: 20px;\n    left: -5px;\n    width: 10px;\n    height: 10px;\n    margin-top: -10px;\n    background: inherit;\n    transform: rotate(45deg);\n  }\n\n  .box:hover {\n    transition: ", ";\n    cursor: pointer;\n    background-color: ", ";\n    color: ", ";\n  }\n\n  .box:hover:before {\n    transition: ", ";\n    cursor: pointer;\n    background-color: ", ";\n    color: ", ";\n  }\n"], ["\n  position: absolute;\n  z-index: 1000;\n  left: 270px;\n  box-shadow: ", ";\n  border-radius: 8px;\n\n  .box {\n    width: 150px;\n    background-color: ", ";\n    color: #1739a5;\n    font-size: 14px;\n    box-sizing: border-box;\n    position: relative;\n    line-height: 50px;\n    vertical-align: middle;\n    text-align: center;\n  }\n\n  ", "\n\n  .box:first-child:before {\n    content: '';\n    position: absolute;\n    top: 20px;\n    left: -5px;\n    width: 10px;\n    height: 10px;\n    margin-top: -10px;\n    background: inherit;\n    transform: rotate(45deg);\n  }\n\n  .box:hover {\n    transition: ", ";\n    cursor: pointer;\n    background-color: ", ";\n    color: ", ";\n  }\n\n  .box:hover:before {\n    transition: ", ";\n    cursor: pointer;\n    background-color: ", ";\n    color: ", ";\n  }\n"])), shadows.medium.light, colors.white, function (props) { return renderBorderRadius(props.tagLength); }, transitions.normal, colors.background.off_white_background, colors.icon, transitions.normal, colors.background.off_white_background, colors.icon);
var SmartReplace = function (_a) {
    var className = _a.className, tags = _a.tags, onSelect = _a.onSelect;
    var _b = useComponentVisible(false), ref = _b.ref, isComponentVisible = _b.isComponentVisible, setIsComponentVisible = _b.setIsComponentVisible;
    return (React.createElement("div", { ref: ref, className: className },
        isComponentVisible && (React.createElement(TagsWrapper, { tagLength: tags.length }, tags.map(function (tag) {
            return (React.createElement("div", { className: "box", key: tag, onClick: function () {
                    onSelect(tag);
                    setIsComponentVisible(false);
                } }, tag));
        }))),
        React.createElement(IconButton, { className: "adicon_curly-brackets", onClick: function () { return setIsComponentVisible(true); } })));
};
export default SmartReplace;
var templateObject_1, templateObject_2;
