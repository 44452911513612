import { RightMenuType, } from '../types/rightMenu';
export var toggleRightMenu = function (boolValue) { return ({
    type: RightMenuType.TOGGLE_RIGHT_MENU,
    boolValue: boolValue,
}); };
export function changeRightMenuTab(tabName) {
    return {
        type: RightMenuType.CHANGE_RIGHT_MENU_TAB,
        tabName: tabName,
    };
}
export var handleDeliveryOptionChange = function (deliveryOption) { return ({
    type: RightMenuType.HANDLE_DELIVERY_OPTION_CHANGE,
    deliveryOption: deliveryOption,
}); };
