var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import React, { Component } from 'react';
import { Composition } from 'atomic-layout';
import endpoints from '@src/endpoints';
import { generalApiFn } from '@src/lib/helpers';
import { LoadingScreen } from '@src/components/atoms';
import { List, EmptySplash, Chatlink } from '@src/components/molecules';
import { ChatlinkHeader } from '@src/components/organisms/fanp';
import { UpdateBtn } from './ChatlinkContent';
import HOCWithRouter from '@src/components/hoc/HOCWithRouter';
import * as pathLinks from '@src/routes/Fanp/pathLinks';
var phoneImg = require('@src/assets/images/img_phone_message.png');
function ChatlinkList(props) {
    var chatlinks = props.chatlinks, chatbotId = props.chatbotId;
    return (React.createElement(List, { gridTemplateColumns: "50px 30% 150px 150px 120px 120px 120px", headerItems: [
            { text: 'ID' },
            {
                text: 'チャットリンク名',
                className: 'left',
            },
            { text: 'タイプ' },
            { text: 'トリガー' },
            { text: 'アクティブ' },
            { text: '表示間隔制御' },
            { text: '表示デバイス' },
        ] }, chatlinks.map(function (chatLink) { return (React.createElement(Chatlink, { key: "chat-link-" + chatLink.id, chatLink: chatLink, chatbotIdParam: chatbotId })); })));
}
var ChatlinkTable = /** @class */ (function (_super) {
    __extends(ChatlinkTable, _super);
    function ChatlinkTable() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            chatlinks: [],
            isLoading: false,
            isModalOpened: false,
        };
        _this.openModal = function (isOpened) { return _this.setState({ isModalOpened: isOpened }); };
        _this.createChatlink = function (values) {
            var chatbotId = _this.props.params.chatbotId;
            var url = endpoints.chatlinks.collection(chatbotId);
            generalApiFn(url, 'POST', values, function (data) {
                return _this.setState({ chatlinks: __spreadArray(__spreadArray([], __read(_this.state.chatlinks)), [data]) });
            });
        };
        return _this;
    }
    ChatlinkTable.prototype.componentDidMount = function () {
        var _this = this;
        var chatbotId = this.props.params.chatbotId;
        this.setState({ isLoading: true });
        var url = endpoints.chatlinks.collection(chatbotId);
        generalApiFn(url, 'GET', null, function (data) {
            return _this.setState({
                chatlinks: data,
                isLoading: false,
            });
        });
    };
    ChatlinkTable.prototype.render = function () {
        var _this = this;
        var _a = this.state, chatlinks = _a.chatlinks, isLoading = _a.isLoading, isModalOpened = _a.isModalOpened;
        var chatbotId = this.props.params.chatbotId;
        if (isLoading)
            return React.createElement(LoadingScreen, null);
        return chatlinks.length ? (React.createElement(Composition, { height: "100%", width: "100%", areas: "\n            header\n            table\n          ", templateRows: "60px auto" }, function (Area) { return (React.createElement(React.Fragment, null,
            React.createElement(Area.Header, null,
                React.createElement(ChatlinkHeader, { text: "\u30C1\u30E3\u30C3\u30C8\u30EA\u30F3\u30AF" },
                    React.createElement(UpdateBtn, { className: "clickable", onClick: function () {
                            _this.props.browserHistory.push(pathLinks.dashboardAdChatbots_chatbotId_ChatlinksNew({
                                chatbotId: parseInt(chatbotId),
                            }));
                        } }, "\u65B0\u898F\u4F5C\u6210"))),
            React.createElement(Area.Table, null,
                React.createElement(ChatlinkList, { chatlinks: chatlinks, chatbotId: chatbotId })))); })) : (React.createElement(EmptySplash, { image: phoneImg, text: "\u30C1\u30E3\u30C3\u30C8\u30EA\u30F3\u30AF\u3092\u4F5C\u6210\u3057\u3088\u3046\uFF01", buttonText: "\u65B0\u898F\u4F5C\u6210", onClick: function () {
                _this.props.browserHistory.push(pathLinks.dashboardAdChatbots_chatbotId_ChatlinksNew({
                    chatbotId: parseInt(chatbotId),
                }));
            }, isModalOpened: isModalOpened }));
    };
    return ChatlinkTable;
}(Component));
export default HOCWithRouter(ChatlinkTable);
