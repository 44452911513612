// source: entities/form_automation.proto

/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

/* eslint-disable */
// @ts-nocheck
var jspb = require('google-protobuf');

var goog = jspb;
var global = Function('return this')();

var entities_entities_pb = require('../entities/entities_pb.js');

goog.object.extend(proto, entities_entities_pb);

var entities_form_pb = require('../entities/form_pb.js');

goog.object.extend(proto, entities_form_pb);
goog.exportSymbol('proto.e.DateConfig', null, global);
goog.exportSymbol('proto.e.DateFormat', null, global);
goog.exportSymbol('proto.e.DatePart', null, global);
goog.exportSymbol('proto.e.FormAutomationCheckboxInputStep', null, global);
goog.exportSymbol('proto.e.FormAutomationCheckboxInputStep.ValueCase', null, global);
goog.exportSymbol('proto.e.FormAutomationClickByValueStep', null, global);
goog.exportSymbol('proto.e.FormAutomationClickByValueStep.ValueCase', null, global);
goog.exportSymbol('proto.e.FormAutomationClickStep', null, global);
goog.exportSymbol('proto.e.FormAutomationConfig', null, global);
goog.exportSymbol('proto.e.FormAutomationElementWaitStep', null, global);
goog.exportSymbol('proto.e.FormAutomationMagicalClickStep', null, global);
goog.exportSymbol('proto.e.FormAutomationNavigationWaitStep', null, global);
goog.exportSymbol('proto.e.FormAutomationRadioInputStep', null, global);
goog.exportSymbol('proto.e.FormAutomationRadioInputStep.ValueCase', null, global);
goog.exportSymbol('proto.e.FormAutomationSelectInputStep', null, global);
goog.exportSymbol('proto.e.FormAutomationSelectInputStep.ValueCase', null, global);
goog.exportSymbol('proto.e.FormAutomationStep', null, global);
goog.exportSymbol('proto.e.FormAutomationStep.ActionCase', null, global);
goog.exportSymbol('proto.e.FormAutomationStepAction', null, global);
goog.exportSymbol('proto.e.FormAutomationStepValueType', null, global);
goog.exportSymbol('proto.e.FormAutomationTextInputStep', null, global);
goog.exportSymbol('proto.e.FormAutomationTextInputStep.ValueCase', null, global);
goog.exportSymbol('proto.e.FormAutomationTimeWaitStep', null, global);
goog.exportSymbol('proto.e.MagicalClickType', null, global);
goog.exportSymbol('proto.e.TextReplacementGroup', null, global);
goog.exportSymbol('proto.e.TextReplacementType', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */

proto.e.FormAutomationStep = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.e.FormAutomationStep.oneofGroups_);
};

goog.inherits(proto.e.FormAutomationStep, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.e.FormAutomationStep.displayName = 'proto.e.FormAutomationStep';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.e.TextReplacementGroup = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.e.TextReplacementGroup, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.e.TextReplacementGroup.displayName = 'proto.e.TextReplacementGroup';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.e.DateConfig = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.e.DateConfig, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.e.DateConfig.displayName = 'proto.e.DateConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.e.FormAutomationTextInputStep = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.e.FormAutomationTextInputStep.repeatedFields_, proto.e.FormAutomationTextInputStep.oneofGroups_);
};

goog.inherits(proto.e.FormAutomationTextInputStep, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.e.FormAutomationTextInputStep.displayName = 'proto.e.FormAutomationTextInputStep';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.e.FormAutomationSelectInputStep = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.e.FormAutomationSelectInputStep.oneofGroups_);
};

goog.inherits(proto.e.FormAutomationSelectInputStep, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.e.FormAutomationSelectInputStep.displayName = 'proto.e.FormAutomationSelectInputStep';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.e.FormAutomationRadioInputStep = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.e.FormAutomationRadioInputStep.oneofGroups_);
};

goog.inherits(proto.e.FormAutomationRadioInputStep, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.e.FormAutomationRadioInputStep.displayName = 'proto.e.FormAutomationRadioInputStep';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.e.FormAutomationCheckboxInputStep = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.e.FormAutomationCheckboxInputStep.oneofGroups_);
};

goog.inherits(proto.e.FormAutomationCheckboxInputStep, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.e.FormAutomationCheckboxInputStep.displayName = 'proto.e.FormAutomationCheckboxInputStep';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.e.FormAutomationClickStep = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.e.FormAutomationClickStep, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.e.FormAutomationClickStep.displayName = 'proto.e.FormAutomationClickStep';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.e.FormAutomationMagicalClickStep = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.e.FormAutomationMagicalClickStep, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.e.FormAutomationMagicalClickStep.displayName = 'proto.e.FormAutomationMagicalClickStep';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.e.FormAutomationClickByValueStep = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.e.FormAutomationClickByValueStep.oneofGroups_);
};

goog.inherits(proto.e.FormAutomationClickByValueStep, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.e.FormAutomationClickByValueStep.displayName = 'proto.e.FormAutomationClickByValueStep';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.e.FormAutomationTimeWaitStep = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.e.FormAutomationTimeWaitStep, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.e.FormAutomationTimeWaitStep.displayName = 'proto.e.FormAutomationTimeWaitStep';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.e.FormAutomationElementWaitStep = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.e.FormAutomationElementWaitStep, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.e.FormAutomationElementWaitStep.displayName = 'proto.e.FormAutomationElementWaitStep';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.e.FormAutomationNavigationWaitStep = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.e.FormAutomationNavigationWaitStep, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.e.FormAutomationNavigationWaitStep.displayName = 'proto.e.FormAutomationNavigationWaitStep';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.e.FormAutomationConfig = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.e.FormAutomationConfig.repeatedFields_, null);
};

goog.inherits(proto.e.FormAutomationConfig, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.e.FormAutomationConfig.displayName = 'proto.e.FormAutomationConfig';
}
/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */


proto.e.FormAutomationStep.oneofGroups_ = [[16, 17, 18, 19, 20, 21, 22, 23, 24, 25]];
/**
 * @enum {number}
 */

proto.e.FormAutomationStep.ActionCase = {
  ACTION_NOT_SET: 0,
  INPUT_ACTION: 16,
  SELECT_ACTION: 17,
  CLICK_ACTION: 18,
  TIME_WAIT_ACTION: 19,
  ELEMENT_WAIT_ACTION: 20,
  RADIO_ACTION: 21,
  NAVIGATION_WAIT_ACTION: 22,
  MAGICAL_CLICK_ACTION: 23,
  CLICK_BY_VALUE_ACTION: 24,
  CHECKBOX_ACTION: 25
};
/**
 * @return {proto.e.FormAutomationStep.ActionCase}
 */

proto.e.FormAutomationStep.prototype.getActionCase = function () {
  return (
    /** @type {proto.e.FormAutomationStep.ActionCase} */
    jspb.Message.computeOneofCase(this, proto.e.FormAutomationStep.oneofGroups_[0])
  );
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.e.FormAutomationStep.prototype.toObject = function (opt_includeInstance) {
    return proto.e.FormAutomationStep.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.e.FormAutomationStep} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.e.FormAutomationStep.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      actionType: jspb.Message.getFieldWithDefault(msg, 1, 0),
      skippable: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
      description: jspb.Message.getFieldWithDefault(msg, 3, ""),
      errorMessage: jspb.Message.getFieldWithDefault(msg, 4, ""),
      inputAction: (f = msg.getInputAction()) && proto.e.FormAutomationTextInputStep.toObject(includeInstance, f),
      selectAction: (f = msg.getSelectAction()) && proto.e.FormAutomationSelectInputStep.toObject(includeInstance, f),
      clickAction: (f = msg.getClickAction()) && proto.e.FormAutomationClickStep.toObject(includeInstance, f),
      timeWaitAction: (f = msg.getTimeWaitAction()) && proto.e.FormAutomationTimeWaitStep.toObject(includeInstance, f),
      elementWaitAction: (f = msg.getElementWaitAction()) && proto.e.FormAutomationElementWaitStep.toObject(includeInstance, f),
      radioAction: (f = msg.getRadioAction()) && proto.e.FormAutomationRadioInputStep.toObject(includeInstance, f),
      navigationWaitAction: (f = msg.getNavigationWaitAction()) && proto.e.FormAutomationNavigationWaitStep.toObject(includeInstance, f),
      magicalClickAction: (f = msg.getMagicalClickAction()) && proto.e.FormAutomationMagicalClickStep.toObject(includeInstance, f),
      clickByValueAction: (f = msg.getClickByValueAction()) && proto.e.FormAutomationClickByValueStep.toObject(includeInstance, f),
      checkboxAction: (f = msg.getCheckboxAction()) && proto.e.FormAutomationCheckboxInputStep.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.e.FormAutomationStep}
 */


proto.e.FormAutomationStep.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.e.FormAutomationStep();
  return proto.e.FormAutomationStep.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.e.FormAutomationStep} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.e.FormAutomationStep}
 */


proto.e.FormAutomationStep.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value =
        /** @type {!proto.e.FormAutomationStepAction} */
        reader.readEnum();
        msg.setActionType(value);
        break;

      case 2:
        var value =
        /** @type {boolean} */
        reader.readBool();
        msg.setSkippable(value);
        break;

      case 3:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setDescription(value);
        break;

      case 4:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setErrorMessage(value);
        break;

      case 16:
        var value = new proto.e.FormAutomationTextInputStep();
        reader.readMessage(value, proto.e.FormAutomationTextInputStep.deserializeBinaryFromReader);
        msg.setInputAction(value);
        break;

      case 17:
        var value = new proto.e.FormAutomationSelectInputStep();
        reader.readMessage(value, proto.e.FormAutomationSelectInputStep.deserializeBinaryFromReader);
        msg.setSelectAction(value);
        break;

      case 18:
        var value = new proto.e.FormAutomationClickStep();
        reader.readMessage(value, proto.e.FormAutomationClickStep.deserializeBinaryFromReader);
        msg.setClickAction(value);
        break;

      case 19:
        var value = new proto.e.FormAutomationTimeWaitStep();
        reader.readMessage(value, proto.e.FormAutomationTimeWaitStep.deserializeBinaryFromReader);
        msg.setTimeWaitAction(value);
        break;

      case 20:
        var value = new proto.e.FormAutomationElementWaitStep();
        reader.readMessage(value, proto.e.FormAutomationElementWaitStep.deserializeBinaryFromReader);
        msg.setElementWaitAction(value);
        break;

      case 21:
        var value = new proto.e.FormAutomationRadioInputStep();
        reader.readMessage(value, proto.e.FormAutomationRadioInputStep.deserializeBinaryFromReader);
        msg.setRadioAction(value);
        break;

      case 22:
        var value = new proto.e.FormAutomationNavigationWaitStep();
        reader.readMessage(value, proto.e.FormAutomationNavigationWaitStep.deserializeBinaryFromReader);
        msg.setNavigationWaitAction(value);
        break;

      case 23:
        var value = new proto.e.FormAutomationMagicalClickStep();
        reader.readMessage(value, proto.e.FormAutomationMagicalClickStep.deserializeBinaryFromReader);
        msg.setMagicalClickAction(value);
        break;

      case 24:
        var value = new proto.e.FormAutomationClickByValueStep();
        reader.readMessage(value, proto.e.FormAutomationClickByValueStep.deserializeBinaryFromReader);
        msg.setClickByValueAction(value);
        break;

      case 25:
        var value = new proto.e.FormAutomationCheckboxInputStep();
        reader.readMessage(value, proto.e.FormAutomationCheckboxInputStep.deserializeBinaryFromReader);
        msg.setCheckboxAction(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.e.FormAutomationStep.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.e.FormAutomationStep.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.e.FormAutomationStep} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.e.FormAutomationStep.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getActionType();

  if (f !== 0.0) {
    writer.writeEnum(1, f);
  }

  f = message.getSkippable();

  if (f) {
    writer.writeBool(2, f);
  }

  f = message.getDescription();

  if (f.length > 0) {
    writer.writeString(3, f);
  }

  f = message.getErrorMessage();

  if (f.length > 0) {
    writer.writeString(4, f);
  }

  f = message.getInputAction();

  if (f != null) {
    writer.writeMessage(16, f, proto.e.FormAutomationTextInputStep.serializeBinaryToWriter);
  }

  f = message.getSelectAction();

  if (f != null) {
    writer.writeMessage(17, f, proto.e.FormAutomationSelectInputStep.serializeBinaryToWriter);
  }

  f = message.getClickAction();

  if (f != null) {
    writer.writeMessage(18, f, proto.e.FormAutomationClickStep.serializeBinaryToWriter);
  }

  f = message.getTimeWaitAction();

  if (f != null) {
    writer.writeMessage(19, f, proto.e.FormAutomationTimeWaitStep.serializeBinaryToWriter);
  }

  f = message.getElementWaitAction();

  if (f != null) {
    writer.writeMessage(20, f, proto.e.FormAutomationElementWaitStep.serializeBinaryToWriter);
  }

  f = message.getRadioAction();

  if (f != null) {
    writer.writeMessage(21, f, proto.e.FormAutomationRadioInputStep.serializeBinaryToWriter);
  }

  f = message.getNavigationWaitAction();

  if (f != null) {
    writer.writeMessage(22, f, proto.e.FormAutomationNavigationWaitStep.serializeBinaryToWriter);
  }

  f = message.getMagicalClickAction();

  if (f != null) {
    writer.writeMessage(23, f, proto.e.FormAutomationMagicalClickStep.serializeBinaryToWriter);
  }

  f = message.getClickByValueAction();

  if (f != null) {
    writer.writeMessage(24, f, proto.e.FormAutomationClickByValueStep.serializeBinaryToWriter);
  }

  f = message.getCheckboxAction();

  if (f != null) {
    writer.writeMessage(25, f, proto.e.FormAutomationCheckboxInputStep.serializeBinaryToWriter);
  }
};
/**
 * optional FormAutomationStepAction action_type = 1;
 * @return {!proto.e.FormAutomationStepAction}
 */


proto.e.FormAutomationStep.prototype.getActionType = function () {
  return (
    /** @type {!proto.e.FormAutomationStepAction} */
    jspb.Message.getFieldWithDefault(this, 1, 0)
  );
};
/**
 * @param {!proto.e.FormAutomationStepAction} value
 * @return {!proto.e.FormAutomationStep} returns this
 */


proto.e.FormAutomationStep.prototype.setActionType = function (value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};
/**
 * optional bool skippable = 2;
 * @return {boolean}
 */


proto.e.FormAutomationStep.prototype.getSkippable = function () {
  return (
    /** @type {boolean} */
    jspb.Message.getBooleanFieldWithDefault(this, 2, false)
  );
};
/**
 * @param {boolean} value
 * @return {!proto.e.FormAutomationStep} returns this
 */


proto.e.FormAutomationStep.prototype.setSkippable = function (value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};
/**
 * optional string description = 3;
 * @return {string}
 */


proto.e.FormAutomationStep.prototype.getDescription = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 3, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.e.FormAutomationStep} returns this
 */


proto.e.FormAutomationStep.prototype.setDescription = function (value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};
/**
 * optional string error_message = 4;
 * @return {string}
 */


proto.e.FormAutomationStep.prototype.getErrorMessage = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 4, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.e.FormAutomationStep} returns this
 */


proto.e.FormAutomationStep.prototype.setErrorMessage = function (value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};
/**
 * optional FormAutomationTextInputStep input_action = 16;
 * @return {?proto.e.FormAutomationTextInputStep}
 */


proto.e.FormAutomationStep.prototype.getInputAction = function () {
  return (
    /** @type{?proto.e.FormAutomationTextInputStep} */
    jspb.Message.getWrapperField(this, proto.e.FormAutomationTextInputStep, 16)
  );
};
/**
 * @param {?proto.e.FormAutomationTextInputStep|undefined} value
 * @return {!proto.e.FormAutomationStep} returns this
*/


proto.e.FormAutomationStep.prototype.setInputAction = function (value) {
  return jspb.Message.setOneofWrapperField(this, 16, proto.e.FormAutomationStep.oneofGroups_[0], value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.e.FormAutomationStep} returns this
 */


proto.e.FormAutomationStep.prototype.clearInputAction = function () {
  return this.setInputAction(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.e.FormAutomationStep.prototype.hasInputAction = function () {
  return jspb.Message.getField(this, 16) != null;
};
/**
 * optional FormAutomationSelectInputStep select_action = 17;
 * @return {?proto.e.FormAutomationSelectInputStep}
 */


proto.e.FormAutomationStep.prototype.getSelectAction = function () {
  return (
    /** @type{?proto.e.FormAutomationSelectInputStep} */
    jspb.Message.getWrapperField(this, proto.e.FormAutomationSelectInputStep, 17)
  );
};
/**
 * @param {?proto.e.FormAutomationSelectInputStep|undefined} value
 * @return {!proto.e.FormAutomationStep} returns this
*/


proto.e.FormAutomationStep.prototype.setSelectAction = function (value) {
  return jspb.Message.setOneofWrapperField(this, 17, proto.e.FormAutomationStep.oneofGroups_[0], value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.e.FormAutomationStep} returns this
 */


proto.e.FormAutomationStep.prototype.clearSelectAction = function () {
  return this.setSelectAction(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.e.FormAutomationStep.prototype.hasSelectAction = function () {
  return jspb.Message.getField(this, 17) != null;
};
/**
 * optional FormAutomationClickStep click_action = 18;
 * @return {?proto.e.FormAutomationClickStep}
 */


proto.e.FormAutomationStep.prototype.getClickAction = function () {
  return (
    /** @type{?proto.e.FormAutomationClickStep} */
    jspb.Message.getWrapperField(this, proto.e.FormAutomationClickStep, 18)
  );
};
/**
 * @param {?proto.e.FormAutomationClickStep|undefined} value
 * @return {!proto.e.FormAutomationStep} returns this
*/


proto.e.FormAutomationStep.prototype.setClickAction = function (value) {
  return jspb.Message.setOneofWrapperField(this, 18, proto.e.FormAutomationStep.oneofGroups_[0], value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.e.FormAutomationStep} returns this
 */


proto.e.FormAutomationStep.prototype.clearClickAction = function () {
  return this.setClickAction(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.e.FormAutomationStep.prototype.hasClickAction = function () {
  return jspb.Message.getField(this, 18) != null;
};
/**
 * optional FormAutomationTimeWaitStep time_wait_action = 19;
 * @return {?proto.e.FormAutomationTimeWaitStep}
 */


proto.e.FormAutomationStep.prototype.getTimeWaitAction = function () {
  return (
    /** @type{?proto.e.FormAutomationTimeWaitStep} */
    jspb.Message.getWrapperField(this, proto.e.FormAutomationTimeWaitStep, 19)
  );
};
/**
 * @param {?proto.e.FormAutomationTimeWaitStep|undefined} value
 * @return {!proto.e.FormAutomationStep} returns this
*/


proto.e.FormAutomationStep.prototype.setTimeWaitAction = function (value) {
  return jspb.Message.setOneofWrapperField(this, 19, proto.e.FormAutomationStep.oneofGroups_[0], value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.e.FormAutomationStep} returns this
 */


proto.e.FormAutomationStep.prototype.clearTimeWaitAction = function () {
  return this.setTimeWaitAction(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.e.FormAutomationStep.prototype.hasTimeWaitAction = function () {
  return jspb.Message.getField(this, 19) != null;
};
/**
 * optional FormAutomationElementWaitStep element_wait_action = 20;
 * @return {?proto.e.FormAutomationElementWaitStep}
 */


proto.e.FormAutomationStep.prototype.getElementWaitAction = function () {
  return (
    /** @type{?proto.e.FormAutomationElementWaitStep} */
    jspb.Message.getWrapperField(this, proto.e.FormAutomationElementWaitStep, 20)
  );
};
/**
 * @param {?proto.e.FormAutomationElementWaitStep|undefined} value
 * @return {!proto.e.FormAutomationStep} returns this
*/


proto.e.FormAutomationStep.prototype.setElementWaitAction = function (value) {
  return jspb.Message.setOneofWrapperField(this, 20, proto.e.FormAutomationStep.oneofGroups_[0], value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.e.FormAutomationStep} returns this
 */


proto.e.FormAutomationStep.prototype.clearElementWaitAction = function () {
  return this.setElementWaitAction(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.e.FormAutomationStep.prototype.hasElementWaitAction = function () {
  return jspb.Message.getField(this, 20) != null;
};
/**
 * optional FormAutomationRadioInputStep radio_action = 21;
 * @return {?proto.e.FormAutomationRadioInputStep}
 */


proto.e.FormAutomationStep.prototype.getRadioAction = function () {
  return (
    /** @type{?proto.e.FormAutomationRadioInputStep} */
    jspb.Message.getWrapperField(this, proto.e.FormAutomationRadioInputStep, 21)
  );
};
/**
 * @param {?proto.e.FormAutomationRadioInputStep|undefined} value
 * @return {!proto.e.FormAutomationStep} returns this
*/


proto.e.FormAutomationStep.prototype.setRadioAction = function (value) {
  return jspb.Message.setOneofWrapperField(this, 21, proto.e.FormAutomationStep.oneofGroups_[0], value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.e.FormAutomationStep} returns this
 */


proto.e.FormAutomationStep.prototype.clearRadioAction = function () {
  return this.setRadioAction(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.e.FormAutomationStep.prototype.hasRadioAction = function () {
  return jspb.Message.getField(this, 21) != null;
};
/**
 * optional FormAutomationNavigationWaitStep navigation_wait_action = 22;
 * @return {?proto.e.FormAutomationNavigationWaitStep}
 */


proto.e.FormAutomationStep.prototype.getNavigationWaitAction = function () {
  return (
    /** @type{?proto.e.FormAutomationNavigationWaitStep} */
    jspb.Message.getWrapperField(this, proto.e.FormAutomationNavigationWaitStep, 22)
  );
};
/**
 * @param {?proto.e.FormAutomationNavigationWaitStep|undefined} value
 * @return {!proto.e.FormAutomationStep} returns this
*/


proto.e.FormAutomationStep.prototype.setNavigationWaitAction = function (value) {
  return jspb.Message.setOneofWrapperField(this, 22, proto.e.FormAutomationStep.oneofGroups_[0], value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.e.FormAutomationStep} returns this
 */


proto.e.FormAutomationStep.prototype.clearNavigationWaitAction = function () {
  return this.setNavigationWaitAction(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.e.FormAutomationStep.prototype.hasNavigationWaitAction = function () {
  return jspb.Message.getField(this, 22) != null;
};
/**
 * optional FormAutomationMagicalClickStep magical_click_action = 23;
 * @return {?proto.e.FormAutomationMagicalClickStep}
 */


proto.e.FormAutomationStep.prototype.getMagicalClickAction = function () {
  return (
    /** @type{?proto.e.FormAutomationMagicalClickStep} */
    jspb.Message.getWrapperField(this, proto.e.FormAutomationMagicalClickStep, 23)
  );
};
/**
 * @param {?proto.e.FormAutomationMagicalClickStep|undefined} value
 * @return {!proto.e.FormAutomationStep} returns this
*/


proto.e.FormAutomationStep.prototype.setMagicalClickAction = function (value) {
  return jspb.Message.setOneofWrapperField(this, 23, proto.e.FormAutomationStep.oneofGroups_[0], value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.e.FormAutomationStep} returns this
 */


proto.e.FormAutomationStep.prototype.clearMagicalClickAction = function () {
  return this.setMagicalClickAction(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.e.FormAutomationStep.prototype.hasMagicalClickAction = function () {
  return jspb.Message.getField(this, 23) != null;
};
/**
 * optional FormAutomationClickByValueStep click_by_value_action = 24;
 * @return {?proto.e.FormAutomationClickByValueStep}
 */


proto.e.FormAutomationStep.prototype.getClickByValueAction = function () {
  return (
    /** @type{?proto.e.FormAutomationClickByValueStep} */
    jspb.Message.getWrapperField(this, proto.e.FormAutomationClickByValueStep, 24)
  );
};
/**
 * @param {?proto.e.FormAutomationClickByValueStep|undefined} value
 * @return {!proto.e.FormAutomationStep} returns this
*/


proto.e.FormAutomationStep.prototype.setClickByValueAction = function (value) {
  return jspb.Message.setOneofWrapperField(this, 24, proto.e.FormAutomationStep.oneofGroups_[0], value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.e.FormAutomationStep} returns this
 */


proto.e.FormAutomationStep.prototype.clearClickByValueAction = function () {
  return this.setClickByValueAction(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.e.FormAutomationStep.prototype.hasClickByValueAction = function () {
  return jspb.Message.getField(this, 24) != null;
};
/**
 * optional FormAutomationCheckboxInputStep checkbox_action = 25;
 * @return {?proto.e.FormAutomationCheckboxInputStep}
 */


proto.e.FormAutomationStep.prototype.getCheckboxAction = function () {
  return (
    /** @type{?proto.e.FormAutomationCheckboxInputStep} */
    jspb.Message.getWrapperField(this, proto.e.FormAutomationCheckboxInputStep, 25)
  );
};
/**
 * @param {?proto.e.FormAutomationCheckboxInputStep|undefined} value
 * @return {!proto.e.FormAutomationStep} returns this
*/


proto.e.FormAutomationStep.prototype.setCheckboxAction = function (value) {
  return jspb.Message.setOneofWrapperField(this, 25, proto.e.FormAutomationStep.oneofGroups_[0], value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.e.FormAutomationStep} returns this
 */


proto.e.FormAutomationStep.prototype.clearCheckboxAction = function () {
  return this.setCheckboxAction(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.e.FormAutomationStep.prototype.hasCheckboxAction = function () {
  return jspb.Message.getField(this, 25) != null;
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.e.TextReplacementGroup.prototype.toObject = function (opt_includeInstance) {
    return proto.e.TextReplacementGroup.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.e.TextReplacementGroup} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.e.TextReplacementGroup.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      replacement: jspb.Message.getFieldWithDefault(msg, 1, 0),
      regexp: jspb.Message.getFieldWithDefault(msg, 2, ""),
      fixedString: jspb.Message.getFieldWithDefault(msg, 16, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.e.TextReplacementGroup}
 */


proto.e.TextReplacementGroup.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.e.TextReplacementGroup();
  return proto.e.TextReplacementGroup.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.e.TextReplacementGroup} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.e.TextReplacementGroup}
 */


proto.e.TextReplacementGroup.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value =
        /** @type {!proto.e.TextReplacementType} */
        reader.readEnum();
        msg.setReplacement(value);
        break;

      case 2:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setRegexp(value);
        break;

      case 16:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setFixedString(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.e.TextReplacementGroup.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.e.TextReplacementGroup.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.e.TextReplacementGroup} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.e.TextReplacementGroup.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getReplacement();

  if (f !== 0.0) {
    writer.writeEnum(1, f);
  }

  f = message.getRegexp();

  if (f.length > 0) {
    writer.writeString(2, f);
  }

  f = message.getFixedString();

  if (f.length > 0) {
    writer.writeString(16, f);
  }
};
/**
 * optional TextReplacementType replacement = 1;
 * @return {!proto.e.TextReplacementType}
 */


proto.e.TextReplacementGroup.prototype.getReplacement = function () {
  return (
    /** @type {!proto.e.TextReplacementType} */
    jspb.Message.getFieldWithDefault(this, 1, 0)
  );
};
/**
 * @param {!proto.e.TextReplacementType} value
 * @return {!proto.e.TextReplacementGroup} returns this
 */


proto.e.TextReplacementGroup.prototype.setReplacement = function (value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};
/**
 * optional string regexp = 2;
 * @return {string}
 */


proto.e.TextReplacementGroup.prototype.getRegexp = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 2, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.e.TextReplacementGroup} returns this
 */


proto.e.TextReplacementGroup.prototype.setRegexp = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};
/**
 * optional string fixed_string = 16;
 * @return {string}
 */


proto.e.TextReplacementGroup.prototype.getFixedString = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 16, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.e.TextReplacementGroup} returns this
 */


proto.e.TextReplacementGroup.prototype.setFixedString = function (value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.e.DateConfig.prototype.toObject = function (opt_includeInstance) {
    return proto.e.DateConfig.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.e.DateConfig} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.e.DateConfig.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      datePart: jspb.Message.getFieldWithDefault(msg, 1, 0),
      zeroPadding: jspb.Message.getBooleanFieldWithDefault(msg, 16, false)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.e.DateConfig}
 */


proto.e.DateConfig.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.e.DateConfig();
  return proto.e.DateConfig.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.e.DateConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.e.DateConfig}
 */


proto.e.DateConfig.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value =
        /** @type {!proto.e.DatePart} */
        reader.readEnum();
        msg.setDatePart(value);
        break;

      case 16:
        var value =
        /** @type {boolean} */
        reader.readBool();
        msg.setZeroPadding(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.e.DateConfig.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.e.DateConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.e.DateConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.e.DateConfig.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getDatePart();

  if (f !== 0.0) {
    writer.writeEnum(1, f);
  }

  f = message.getZeroPadding();

  if (f) {
    writer.writeBool(16, f);
  }
};
/**
 * optional DatePart date_part = 1;
 * @return {!proto.e.DatePart}
 */


proto.e.DateConfig.prototype.getDatePart = function () {
  return (
    /** @type {!proto.e.DatePart} */
    jspb.Message.getFieldWithDefault(this, 1, 0)
  );
};
/**
 * @param {!proto.e.DatePart} value
 * @return {!proto.e.DateConfig} returns this
 */


proto.e.DateConfig.prototype.setDatePart = function (value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};
/**
 * optional bool zero_padding = 16;
 * @return {boolean}
 */


proto.e.DateConfig.prototype.getZeroPadding = function () {
  return (
    /** @type {boolean} */
    jspb.Message.getBooleanFieldWithDefault(this, 16, false)
  );
};
/**
 * @param {boolean} value
 * @return {!proto.e.DateConfig} returns this
 */


proto.e.DateConfig.prototype.setZeroPadding = function (value) {
  return jspb.Message.setProto3BooleanField(this, 16, value);
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */


proto.e.FormAutomationTextInputStep.repeatedFields_ = [4];
/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */

proto.e.FormAutomationTextInputStep.oneofGroups_ = [[16, 17]];
/**
 * @enum {number}
 */

proto.e.FormAutomationTextInputStep.ValueCase = {
  VALUE_NOT_SET: 0,
  FIELD_ID: 16,
  FIXED_VALUE: 17
};
/**
 * @return {proto.e.FormAutomationTextInputStep.ValueCase}
 */

proto.e.FormAutomationTextInputStep.prototype.getValueCase = function () {
  return (
    /** @type {proto.e.FormAutomationTextInputStep.ValueCase} */
    jspb.Message.computeOneofCase(this, proto.e.FormAutomationTextInputStep.oneofGroups_[0])
  );
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.e.FormAutomationTextInputStep.prototype.toObject = function (opt_includeInstance) {
    return proto.e.FormAutomationTextInputStep.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.e.FormAutomationTextInputStep} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.e.FormAutomationTextInputStep.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      querySelector: jspb.Message.getFieldWithDefault(msg, 1, ""),
      valueType: jspb.Message.getFieldWithDefault(msg, 2, 0),
      regexp: jspb.Message.getFieldWithDefault(msg, 3, ""),
      replacementGroupsList: jspb.Message.toObjectList(msg.getReplacementGroupsList(), proto.e.TextReplacementGroup.toObject, includeInstance),
      source: jspb.Message.getFieldWithDefault(msg, 5, 0),
      fieldId: jspb.Message.getFieldWithDefault(msg, 16, ""),
      fixedValue: jspb.Message.getFieldWithDefault(msg, 17, ""),
      dateFormat: jspb.Message.getFieldWithDefault(msg, 18, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.e.FormAutomationTextInputStep}
 */


proto.e.FormAutomationTextInputStep.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.e.FormAutomationTextInputStep();
  return proto.e.FormAutomationTextInputStep.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.e.FormAutomationTextInputStep} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.e.FormAutomationTextInputStep}
 */


proto.e.FormAutomationTextInputStep.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setQuerySelector(value);
        break;

      case 2:
        var value =
        /** @type {!proto.e.FormAutomationStepValueType} */
        reader.readEnum();
        msg.setValueType(value);
        break;

      case 3:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setRegexp(value);
        break;

      case 4:
        var value = new proto.e.TextReplacementGroup();
        reader.readMessage(value, proto.e.TextReplacementGroup.deserializeBinaryFromReader);
        msg.addReplacementGroups(value);
        break;

      case 5:
        var value =
        /** @type {!proto.e.SegmentType} */
        reader.readEnum();
        msg.setSource(value);
        break;

      case 16:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setFieldId(value);
        break;

      case 17:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setFixedValue(value);
        break;

      case 18:
        var value =
        /** @type {!proto.e.DateFormat} */
        reader.readEnum();
        msg.setDateFormat(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.e.FormAutomationTextInputStep.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.e.FormAutomationTextInputStep.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.e.FormAutomationTextInputStep} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.e.FormAutomationTextInputStep.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getQuerySelector();

  if (f.length > 0) {
    writer.writeString(1, f);
  }

  f = message.getValueType();

  if (f !== 0.0) {
    writer.writeEnum(2, f);
  }

  f = message.getRegexp();

  if (f.length > 0) {
    writer.writeString(3, f);
  }

  f = message.getReplacementGroupsList();

  if (f.length > 0) {
    writer.writeRepeatedMessage(4, f, proto.e.TextReplacementGroup.serializeBinaryToWriter);
  }

  f = message.getSource();

  if (f !== 0.0) {
    writer.writeEnum(5, f);
  }

  f =
  /** @type {string} */
  jspb.Message.getField(message, 16);

  if (f != null) {
    writer.writeString(16, f);
  }

  f =
  /** @type {string} */
  jspb.Message.getField(message, 17);

  if (f != null) {
    writer.writeString(17, f);
  }

  f = message.getDateFormat();

  if (f !== 0.0) {
    writer.writeEnum(18, f);
  }
};
/**
 * optional string query_selector = 1;
 * @return {string}
 */


proto.e.FormAutomationTextInputStep.prototype.getQuerySelector = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 1, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.e.FormAutomationTextInputStep} returns this
 */


proto.e.FormAutomationTextInputStep.prototype.setQuerySelector = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional FormAutomationStepValueType value_type = 2;
 * @return {!proto.e.FormAutomationStepValueType}
 */


proto.e.FormAutomationTextInputStep.prototype.getValueType = function () {
  return (
    /** @type {!proto.e.FormAutomationStepValueType} */
    jspb.Message.getFieldWithDefault(this, 2, 0)
  );
};
/**
 * @param {!proto.e.FormAutomationStepValueType} value
 * @return {!proto.e.FormAutomationTextInputStep} returns this
 */


proto.e.FormAutomationTextInputStep.prototype.setValueType = function (value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};
/**
 * optional string regexp = 3;
 * @return {string}
 */


proto.e.FormAutomationTextInputStep.prototype.getRegexp = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 3, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.e.FormAutomationTextInputStep} returns this
 */


proto.e.FormAutomationTextInputStep.prototype.setRegexp = function (value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};
/**
 * repeated TextReplacementGroup replacement_groups = 4;
 * @return {!Array<!proto.e.TextReplacementGroup>}
 */


proto.e.FormAutomationTextInputStep.prototype.getReplacementGroupsList = function () {
  return (
    /** @type{!Array<!proto.e.TextReplacementGroup>} */
    jspb.Message.getRepeatedWrapperField(this, proto.e.TextReplacementGroup, 4)
  );
};
/**
 * @param {!Array<!proto.e.TextReplacementGroup>} value
 * @return {!proto.e.FormAutomationTextInputStep} returns this
*/


proto.e.FormAutomationTextInputStep.prototype.setReplacementGroupsList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};
/**
 * @param {!proto.e.TextReplacementGroup=} opt_value
 * @param {number=} opt_index
 * @return {!proto.e.TextReplacementGroup}
 */


proto.e.FormAutomationTextInputStep.prototype.addReplacementGroups = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.e.TextReplacementGroup, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.e.FormAutomationTextInputStep} returns this
 */


proto.e.FormAutomationTextInputStep.prototype.clearReplacementGroupsList = function () {
  return this.setReplacementGroupsList([]);
};
/**
 * optional SegmentType source = 5;
 * @return {!proto.e.SegmentType}
 */


proto.e.FormAutomationTextInputStep.prototype.getSource = function () {
  return (
    /** @type {!proto.e.SegmentType} */
    jspb.Message.getFieldWithDefault(this, 5, 0)
  );
};
/**
 * @param {!proto.e.SegmentType} value
 * @return {!proto.e.FormAutomationTextInputStep} returns this
 */


proto.e.FormAutomationTextInputStep.prototype.setSource = function (value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};
/**
 * optional string field_id = 16;
 * @return {string}
 */


proto.e.FormAutomationTextInputStep.prototype.getFieldId = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 16, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.e.FormAutomationTextInputStep} returns this
 */


proto.e.FormAutomationTextInputStep.prototype.setFieldId = function (value) {
  return jspb.Message.setOneofField(this, 16, proto.e.FormAutomationTextInputStep.oneofGroups_[0], value);
};
/**
 * Clears the field making it undefined.
 * @return {!proto.e.FormAutomationTextInputStep} returns this
 */


proto.e.FormAutomationTextInputStep.prototype.clearFieldId = function () {
  return jspb.Message.setOneofField(this, 16, proto.e.FormAutomationTextInputStep.oneofGroups_[0], undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.e.FormAutomationTextInputStep.prototype.hasFieldId = function () {
  return jspb.Message.getField(this, 16) != null;
};
/**
 * optional string fixed_value = 17;
 * @return {string}
 */


proto.e.FormAutomationTextInputStep.prototype.getFixedValue = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 17, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.e.FormAutomationTextInputStep} returns this
 */


proto.e.FormAutomationTextInputStep.prototype.setFixedValue = function (value) {
  return jspb.Message.setOneofField(this, 17, proto.e.FormAutomationTextInputStep.oneofGroups_[0], value);
};
/**
 * Clears the field making it undefined.
 * @return {!proto.e.FormAutomationTextInputStep} returns this
 */


proto.e.FormAutomationTextInputStep.prototype.clearFixedValue = function () {
  return jspb.Message.setOneofField(this, 17, proto.e.FormAutomationTextInputStep.oneofGroups_[0], undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.e.FormAutomationTextInputStep.prototype.hasFixedValue = function () {
  return jspb.Message.getField(this, 17) != null;
};
/**
 * optional DateFormat date_format = 18;
 * @return {!proto.e.DateFormat}
 */


proto.e.FormAutomationTextInputStep.prototype.getDateFormat = function () {
  return (
    /** @type {!proto.e.DateFormat} */
    jspb.Message.getFieldWithDefault(this, 18, 0)
  );
};
/**
 * @param {!proto.e.DateFormat} value
 * @return {!proto.e.FormAutomationTextInputStep} returns this
 */


proto.e.FormAutomationTextInputStep.prototype.setDateFormat = function (value) {
  return jspb.Message.setProto3EnumField(this, 18, value);
};
/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */


proto.e.FormAutomationSelectInputStep.oneofGroups_ = [[16, 17]];
/**
 * @enum {number}
 */

proto.e.FormAutomationSelectInputStep.ValueCase = {
  VALUE_NOT_SET: 0,
  FIELD_ID: 16,
  FIXED_VALUE: 17
};
/**
 * @return {proto.e.FormAutomationSelectInputStep.ValueCase}
 */

proto.e.FormAutomationSelectInputStep.prototype.getValueCase = function () {
  return (
    /** @type {proto.e.FormAutomationSelectInputStep.ValueCase} */
    jspb.Message.computeOneofCase(this, proto.e.FormAutomationSelectInputStep.oneofGroups_[0])
  );
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.e.FormAutomationSelectInputStep.prototype.toObject = function (opt_includeInstance) {
    return proto.e.FormAutomationSelectInputStep.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.e.FormAutomationSelectInputStep} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.e.FormAutomationSelectInputStep.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      querySelector: jspb.Message.getFieldWithDefault(msg, 1, ""),
      valueType: jspb.Message.getFieldWithDefault(msg, 2, 0),
      valueMapMap: (f = msg.getValueMapMap()) ? f.toObject(includeInstance, undefined) : [],
      source: jspb.Message.getFieldWithDefault(msg, 4, 0),
      fieldId: jspb.Message.getFieldWithDefault(msg, 16, ""),
      fixedValue: jspb.Message.getFieldWithDefault(msg, 17, ""),
      dateFormat: jspb.Message.getFieldWithDefault(msg, 18, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.e.FormAutomationSelectInputStep}
 */


proto.e.FormAutomationSelectInputStep.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.e.FormAutomationSelectInputStep();
  return proto.e.FormAutomationSelectInputStep.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.e.FormAutomationSelectInputStep} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.e.FormAutomationSelectInputStep}
 */


proto.e.FormAutomationSelectInputStep.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setQuerySelector(value);
        break;

      case 2:
        var value =
        /** @type {!proto.e.FormAutomationStepValueType} */
        reader.readEnum();
        msg.setValueType(value);
        break;

      case 3:
        var value = msg.getValueMapMap();
        reader.readMessage(value, function (message, reader) {
          jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
        });
        break;

      case 4:
        var value =
        /** @type {!proto.e.SegmentType} */
        reader.readEnum();
        msg.setSource(value);
        break;

      case 16:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setFieldId(value);
        break;

      case 17:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setFixedValue(value);
        break;

      case 18:
        var value =
        /** @type {!proto.e.DateFormat} */
        reader.readEnum();
        msg.setDateFormat(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.e.FormAutomationSelectInputStep.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.e.FormAutomationSelectInputStep.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.e.FormAutomationSelectInputStep} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.e.FormAutomationSelectInputStep.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getQuerySelector();

  if (f.length > 0) {
    writer.writeString(1, f);
  }

  f = message.getValueType();

  if (f !== 0.0) {
    writer.writeEnum(2, f);
  }

  f = message.getValueMapMap(true);

  if (f && f.getLength() > 0) {
    f.serializeBinary(3, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }

  f = message.getSource();

  if (f !== 0.0) {
    writer.writeEnum(4, f);
  }

  f =
  /** @type {string} */
  jspb.Message.getField(message, 16);

  if (f != null) {
    writer.writeString(16, f);
  }

  f =
  /** @type {string} */
  jspb.Message.getField(message, 17);

  if (f != null) {
    writer.writeString(17, f);
  }

  f = message.getDateFormat();

  if (f !== 0.0) {
    writer.writeEnum(18, f);
  }
};
/**
 * optional string query_selector = 1;
 * @return {string}
 */


proto.e.FormAutomationSelectInputStep.prototype.getQuerySelector = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 1, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.e.FormAutomationSelectInputStep} returns this
 */


proto.e.FormAutomationSelectInputStep.prototype.setQuerySelector = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional FormAutomationStepValueType value_type = 2;
 * @return {!proto.e.FormAutomationStepValueType}
 */


proto.e.FormAutomationSelectInputStep.prototype.getValueType = function () {
  return (
    /** @type {!proto.e.FormAutomationStepValueType} */
    jspb.Message.getFieldWithDefault(this, 2, 0)
  );
};
/**
 * @param {!proto.e.FormAutomationStepValueType} value
 * @return {!proto.e.FormAutomationSelectInputStep} returns this
 */


proto.e.FormAutomationSelectInputStep.prototype.setValueType = function (value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};
/**
 * map<string, string> value_map = 3;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */


proto.e.FormAutomationSelectInputStep.prototype.getValueMapMap = function (opt_noLazyCreate) {
  return (
    /** @type {!jspb.Map<string,string>} */
    jspb.Message.getMapField(this, 3, opt_noLazyCreate, null)
  );
};
/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.e.FormAutomationSelectInputStep} returns this
 */


proto.e.FormAutomationSelectInputStep.prototype.clearValueMapMap = function () {
  this.getValueMapMap().clear();
  return this;
};
/**
 * optional SegmentType source = 4;
 * @return {!proto.e.SegmentType}
 */


proto.e.FormAutomationSelectInputStep.prototype.getSource = function () {
  return (
    /** @type {!proto.e.SegmentType} */
    jspb.Message.getFieldWithDefault(this, 4, 0)
  );
};
/**
 * @param {!proto.e.SegmentType} value
 * @return {!proto.e.FormAutomationSelectInputStep} returns this
 */


proto.e.FormAutomationSelectInputStep.prototype.setSource = function (value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};
/**
 * optional string field_id = 16;
 * @return {string}
 */


proto.e.FormAutomationSelectInputStep.prototype.getFieldId = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 16, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.e.FormAutomationSelectInputStep} returns this
 */


proto.e.FormAutomationSelectInputStep.prototype.setFieldId = function (value) {
  return jspb.Message.setOneofField(this, 16, proto.e.FormAutomationSelectInputStep.oneofGroups_[0], value);
};
/**
 * Clears the field making it undefined.
 * @return {!proto.e.FormAutomationSelectInputStep} returns this
 */


proto.e.FormAutomationSelectInputStep.prototype.clearFieldId = function () {
  return jspb.Message.setOneofField(this, 16, proto.e.FormAutomationSelectInputStep.oneofGroups_[0], undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.e.FormAutomationSelectInputStep.prototype.hasFieldId = function () {
  return jspb.Message.getField(this, 16) != null;
};
/**
 * optional string fixed_value = 17;
 * @return {string}
 */


proto.e.FormAutomationSelectInputStep.prototype.getFixedValue = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 17, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.e.FormAutomationSelectInputStep} returns this
 */


proto.e.FormAutomationSelectInputStep.prototype.setFixedValue = function (value) {
  return jspb.Message.setOneofField(this, 17, proto.e.FormAutomationSelectInputStep.oneofGroups_[0], value);
};
/**
 * Clears the field making it undefined.
 * @return {!proto.e.FormAutomationSelectInputStep} returns this
 */


proto.e.FormAutomationSelectInputStep.prototype.clearFixedValue = function () {
  return jspb.Message.setOneofField(this, 17, proto.e.FormAutomationSelectInputStep.oneofGroups_[0], undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.e.FormAutomationSelectInputStep.prototype.hasFixedValue = function () {
  return jspb.Message.getField(this, 17) != null;
};
/**
 * optional DateFormat date_format = 18;
 * @return {!proto.e.DateFormat}
 */


proto.e.FormAutomationSelectInputStep.prototype.getDateFormat = function () {
  return (
    /** @type {!proto.e.DateFormat} */
    jspb.Message.getFieldWithDefault(this, 18, 0)
  );
};
/**
 * @param {!proto.e.DateFormat} value
 * @return {!proto.e.FormAutomationSelectInputStep} returns this
 */


proto.e.FormAutomationSelectInputStep.prototype.setDateFormat = function (value) {
  return jspb.Message.setProto3EnumField(this, 18, value);
};
/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */


proto.e.FormAutomationRadioInputStep.oneofGroups_ = [[16, 17]];
/**
 * @enum {number}
 */

proto.e.FormAutomationRadioInputStep.ValueCase = {
  VALUE_NOT_SET: 0,
  FIELD_ID: 16,
  FIXED_VALUE: 17
};
/**
 * @return {proto.e.FormAutomationRadioInputStep.ValueCase}
 */

proto.e.FormAutomationRadioInputStep.prototype.getValueCase = function () {
  return (
    /** @type {proto.e.FormAutomationRadioInputStep.ValueCase} */
    jspb.Message.computeOneofCase(this, proto.e.FormAutomationRadioInputStep.oneofGroups_[0])
  );
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.e.FormAutomationRadioInputStep.prototype.toObject = function (opt_includeInstance) {
    return proto.e.FormAutomationRadioInputStep.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.e.FormAutomationRadioInputStep} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.e.FormAutomationRadioInputStep.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      querySelector: jspb.Message.getFieldWithDefault(msg, 1, ""),
      valueType: jspb.Message.getFieldWithDefault(msg, 2, 0),
      valueMapMap: (f = msg.getValueMapMap()) ? f.toObject(includeInstance, undefined) : [],
      source: jspb.Message.getFieldWithDefault(msg, 4, 0),
      fieldId: jspb.Message.getFieldWithDefault(msg, 16, ""),
      fixedValue: jspb.Message.getFieldWithDefault(msg, 17, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.e.FormAutomationRadioInputStep}
 */


proto.e.FormAutomationRadioInputStep.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.e.FormAutomationRadioInputStep();
  return proto.e.FormAutomationRadioInputStep.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.e.FormAutomationRadioInputStep} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.e.FormAutomationRadioInputStep}
 */


proto.e.FormAutomationRadioInputStep.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setQuerySelector(value);
        break;

      case 2:
        var value =
        /** @type {!proto.e.FormAutomationStepValueType} */
        reader.readEnum();
        msg.setValueType(value);
        break;

      case 3:
        var value = msg.getValueMapMap();
        reader.readMessage(value, function (message, reader) {
          jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
        });
        break;

      case 4:
        var value =
        /** @type {!proto.e.SegmentType} */
        reader.readEnum();
        msg.setSource(value);
        break;

      case 16:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setFieldId(value);
        break;

      case 17:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setFixedValue(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.e.FormAutomationRadioInputStep.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.e.FormAutomationRadioInputStep.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.e.FormAutomationRadioInputStep} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.e.FormAutomationRadioInputStep.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getQuerySelector();

  if (f.length > 0) {
    writer.writeString(1, f);
  }

  f = message.getValueType();

  if (f !== 0.0) {
    writer.writeEnum(2, f);
  }

  f = message.getValueMapMap(true);

  if (f && f.getLength() > 0) {
    f.serializeBinary(3, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }

  f = message.getSource();

  if (f !== 0.0) {
    writer.writeEnum(4, f);
  }

  f =
  /** @type {string} */
  jspb.Message.getField(message, 16);

  if (f != null) {
    writer.writeString(16, f);
  }

  f =
  /** @type {string} */
  jspb.Message.getField(message, 17);

  if (f != null) {
    writer.writeString(17, f);
  }
};
/**
 * optional string query_selector = 1;
 * @return {string}
 */


proto.e.FormAutomationRadioInputStep.prototype.getQuerySelector = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 1, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.e.FormAutomationRadioInputStep} returns this
 */


proto.e.FormAutomationRadioInputStep.prototype.setQuerySelector = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional FormAutomationStepValueType value_type = 2;
 * @return {!proto.e.FormAutomationStepValueType}
 */


proto.e.FormAutomationRadioInputStep.prototype.getValueType = function () {
  return (
    /** @type {!proto.e.FormAutomationStepValueType} */
    jspb.Message.getFieldWithDefault(this, 2, 0)
  );
};
/**
 * @param {!proto.e.FormAutomationStepValueType} value
 * @return {!proto.e.FormAutomationRadioInputStep} returns this
 */


proto.e.FormAutomationRadioInputStep.prototype.setValueType = function (value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};
/**
 * map<string, string> value_map = 3;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */


proto.e.FormAutomationRadioInputStep.prototype.getValueMapMap = function (opt_noLazyCreate) {
  return (
    /** @type {!jspb.Map<string,string>} */
    jspb.Message.getMapField(this, 3, opt_noLazyCreate, null)
  );
};
/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.e.FormAutomationRadioInputStep} returns this
 */


proto.e.FormAutomationRadioInputStep.prototype.clearValueMapMap = function () {
  this.getValueMapMap().clear();
  return this;
};
/**
 * optional SegmentType source = 4;
 * @return {!proto.e.SegmentType}
 */


proto.e.FormAutomationRadioInputStep.prototype.getSource = function () {
  return (
    /** @type {!proto.e.SegmentType} */
    jspb.Message.getFieldWithDefault(this, 4, 0)
  );
};
/**
 * @param {!proto.e.SegmentType} value
 * @return {!proto.e.FormAutomationRadioInputStep} returns this
 */


proto.e.FormAutomationRadioInputStep.prototype.setSource = function (value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};
/**
 * optional string field_id = 16;
 * @return {string}
 */


proto.e.FormAutomationRadioInputStep.prototype.getFieldId = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 16, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.e.FormAutomationRadioInputStep} returns this
 */


proto.e.FormAutomationRadioInputStep.prototype.setFieldId = function (value) {
  return jspb.Message.setOneofField(this, 16, proto.e.FormAutomationRadioInputStep.oneofGroups_[0], value);
};
/**
 * Clears the field making it undefined.
 * @return {!proto.e.FormAutomationRadioInputStep} returns this
 */


proto.e.FormAutomationRadioInputStep.prototype.clearFieldId = function () {
  return jspb.Message.setOneofField(this, 16, proto.e.FormAutomationRadioInputStep.oneofGroups_[0], undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.e.FormAutomationRadioInputStep.prototype.hasFieldId = function () {
  return jspb.Message.getField(this, 16) != null;
};
/**
 * optional string fixed_value = 17;
 * @return {string}
 */


proto.e.FormAutomationRadioInputStep.prototype.getFixedValue = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 17, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.e.FormAutomationRadioInputStep} returns this
 */


proto.e.FormAutomationRadioInputStep.prototype.setFixedValue = function (value) {
  return jspb.Message.setOneofField(this, 17, proto.e.FormAutomationRadioInputStep.oneofGroups_[0], value);
};
/**
 * Clears the field making it undefined.
 * @return {!proto.e.FormAutomationRadioInputStep} returns this
 */


proto.e.FormAutomationRadioInputStep.prototype.clearFixedValue = function () {
  return jspb.Message.setOneofField(this, 17, proto.e.FormAutomationRadioInputStep.oneofGroups_[0], undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.e.FormAutomationRadioInputStep.prototype.hasFixedValue = function () {
  return jspb.Message.getField(this, 17) != null;
};
/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */


proto.e.FormAutomationCheckboxInputStep.oneofGroups_ = [[16, 17]];
/**
 * @enum {number}
 */

proto.e.FormAutomationCheckboxInputStep.ValueCase = {
  VALUE_NOT_SET: 0,
  FIELD_ID: 16,
  FIXED_VALUE: 17
};
/**
 * @return {proto.e.FormAutomationCheckboxInputStep.ValueCase}
 */

proto.e.FormAutomationCheckboxInputStep.prototype.getValueCase = function () {
  return (
    /** @type {proto.e.FormAutomationCheckboxInputStep.ValueCase} */
    jspb.Message.computeOneofCase(this, proto.e.FormAutomationCheckboxInputStep.oneofGroups_[0])
  );
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.e.FormAutomationCheckboxInputStep.prototype.toObject = function (opt_includeInstance) {
    return proto.e.FormAutomationCheckboxInputStep.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.e.FormAutomationCheckboxInputStep} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.e.FormAutomationCheckboxInputStep.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      querySelector: jspb.Message.getFieldWithDefault(msg, 1, ""),
      valueType: jspb.Message.getFieldWithDefault(msg, 2, 0),
      valueMapMap: (f = msg.getValueMapMap()) ? f.toObject(includeInstance, undefined) : [],
      source: jspb.Message.getFieldWithDefault(msg, 4, 0),
      fieldId: jspb.Message.getFieldWithDefault(msg, 16, ""),
      fixedValue: jspb.Message.getFieldWithDefault(msg, 17, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.e.FormAutomationCheckboxInputStep}
 */


proto.e.FormAutomationCheckboxInputStep.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.e.FormAutomationCheckboxInputStep();
  return proto.e.FormAutomationCheckboxInputStep.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.e.FormAutomationCheckboxInputStep} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.e.FormAutomationCheckboxInputStep}
 */


proto.e.FormAutomationCheckboxInputStep.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setQuerySelector(value);
        break;

      case 2:
        var value =
        /** @type {!proto.e.FormAutomationStepValueType} */
        reader.readEnum();
        msg.setValueType(value);
        break;

      case 3:
        var value = msg.getValueMapMap();
        reader.readMessage(value, function (message, reader) {
          jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
        });
        break;

      case 4:
        var value =
        /** @type {!proto.e.SegmentType} */
        reader.readEnum();
        msg.setSource(value);
        break;

      case 16:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setFieldId(value);
        break;

      case 17:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setFixedValue(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.e.FormAutomationCheckboxInputStep.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.e.FormAutomationCheckboxInputStep.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.e.FormAutomationCheckboxInputStep} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.e.FormAutomationCheckboxInputStep.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getQuerySelector();

  if (f.length > 0) {
    writer.writeString(1, f);
  }

  f = message.getValueType();

  if (f !== 0.0) {
    writer.writeEnum(2, f);
  }

  f = message.getValueMapMap(true);

  if (f && f.getLength() > 0) {
    f.serializeBinary(3, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }

  f = message.getSource();

  if (f !== 0.0) {
    writer.writeEnum(4, f);
  }

  f =
  /** @type {string} */
  jspb.Message.getField(message, 16);

  if (f != null) {
    writer.writeString(16, f);
  }

  f =
  /** @type {string} */
  jspb.Message.getField(message, 17);

  if (f != null) {
    writer.writeString(17, f);
  }
};
/**
 * optional string query_selector = 1;
 * @return {string}
 */


proto.e.FormAutomationCheckboxInputStep.prototype.getQuerySelector = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 1, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.e.FormAutomationCheckboxInputStep} returns this
 */


proto.e.FormAutomationCheckboxInputStep.prototype.setQuerySelector = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional FormAutomationStepValueType value_type = 2;
 * @return {!proto.e.FormAutomationStepValueType}
 */


proto.e.FormAutomationCheckboxInputStep.prototype.getValueType = function () {
  return (
    /** @type {!proto.e.FormAutomationStepValueType} */
    jspb.Message.getFieldWithDefault(this, 2, 0)
  );
};
/**
 * @param {!proto.e.FormAutomationStepValueType} value
 * @return {!proto.e.FormAutomationCheckboxInputStep} returns this
 */


proto.e.FormAutomationCheckboxInputStep.prototype.setValueType = function (value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};
/**
 * map<string, string> value_map = 3;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */


proto.e.FormAutomationCheckboxInputStep.prototype.getValueMapMap = function (opt_noLazyCreate) {
  return (
    /** @type {!jspb.Map<string,string>} */
    jspb.Message.getMapField(this, 3, opt_noLazyCreate, null)
  );
};
/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.e.FormAutomationCheckboxInputStep} returns this
 */


proto.e.FormAutomationCheckboxInputStep.prototype.clearValueMapMap = function () {
  this.getValueMapMap().clear();
  return this;
};
/**
 * optional SegmentType source = 4;
 * @return {!proto.e.SegmentType}
 */


proto.e.FormAutomationCheckboxInputStep.prototype.getSource = function () {
  return (
    /** @type {!proto.e.SegmentType} */
    jspb.Message.getFieldWithDefault(this, 4, 0)
  );
};
/**
 * @param {!proto.e.SegmentType} value
 * @return {!proto.e.FormAutomationCheckboxInputStep} returns this
 */


proto.e.FormAutomationCheckboxInputStep.prototype.setSource = function (value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};
/**
 * optional string field_id = 16;
 * @return {string}
 */


proto.e.FormAutomationCheckboxInputStep.prototype.getFieldId = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 16, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.e.FormAutomationCheckboxInputStep} returns this
 */


proto.e.FormAutomationCheckboxInputStep.prototype.setFieldId = function (value) {
  return jspb.Message.setOneofField(this, 16, proto.e.FormAutomationCheckboxInputStep.oneofGroups_[0], value);
};
/**
 * Clears the field making it undefined.
 * @return {!proto.e.FormAutomationCheckboxInputStep} returns this
 */


proto.e.FormAutomationCheckboxInputStep.prototype.clearFieldId = function () {
  return jspb.Message.setOneofField(this, 16, proto.e.FormAutomationCheckboxInputStep.oneofGroups_[0], undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.e.FormAutomationCheckboxInputStep.prototype.hasFieldId = function () {
  return jspb.Message.getField(this, 16) != null;
};
/**
 * optional string fixed_value = 17;
 * @return {string}
 */


proto.e.FormAutomationCheckboxInputStep.prototype.getFixedValue = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 17, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.e.FormAutomationCheckboxInputStep} returns this
 */


proto.e.FormAutomationCheckboxInputStep.prototype.setFixedValue = function (value) {
  return jspb.Message.setOneofField(this, 17, proto.e.FormAutomationCheckboxInputStep.oneofGroups_[0], value);
};
/**
 * Clears the field making it undefined.
 * @return {!proto.e.FormAutomationCheckboxInputStep} returns this
 */


proto.e.FormAutomationCheckboxInputStep.prototype.clearFixedValue = function () {
  return jspb.Message.setOneofField(this, 17, proto.e.FormAutomationCheckboxInputStep.oneofGroups_[0], undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.e.FormAutomationCheckboxInputStep.prototype.hasFixedValue = function () {
  return jspb.Message.getField(this, 17) != null;
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.e.FormAutomationClickStep.prototype.toObject = function (opt_includeInstance) {
    return proto.e.FormAutomationClickStep.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.e.FormAutomationClickStep} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.e.FormAutomationClickStep.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      querySelector: jspb.Message.getFieldWithDefault(msg, 1, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.e.FormAutomationClickStep}
 */


proto.e.FormAutomationClickStep.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.e.FormAutomationClickStep();
  return proto.e.FormAutomationClickStep.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.e.FormAutomationClickStep} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.e.FormAutomationClickStep}
 */


proto.e.FormAutomationClickStep.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setQuerySelector(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.e.FormAutomationClickStep.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.e.FormAutomationClickStep.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.e.FormAutomationClickStep} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.e.FormAutomationClickStep.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getQuerySelector();

  if (f.length > 0) {
    writer.writeString(1, f);
  }
};
/**
 * optional string query_selector = 1;
 * @return {string}
 */


proto.e.FormAutomationClickStep.prototype.getQuerySelector = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 1, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.e.FormAutomationClickStep} returns this
 */


proto.e.FormAutomationClickStep.prototype.setQuerySelector = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.e.FormAutomationMagicalClickStep.prototype.toObject = function (opt_includeInstance) {
    return proto.e.FormAutomationMagicalClickStep.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.e.FormAutomationMagicalClickStep} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.e.FormAutomationMagicalClickStep.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      magicalClickType: jspb.Message.getFieldWithDefault(msg, 1, 0),
      fieldId: jspb.Message.getFieldWithDefault(msg, 2, ""),
      source: jspb.Message.getFieldWithDefault(msg, 3, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.e.FormAutomationMagicalClickStep}
 */


proto.e.FormAutomationMagicalClickStep.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.e.FormAutomationMagicalClickStep();
  return proto.e.FormAutomationMagicalClickStep.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.e.FormAutomationMagicalClickStep} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.e.FormAutomationMagicalClickStep}
 */


proto.e.FormAutomationMagicalClickStep.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value =
        /** @type {!proto.e.MagicalClickType} */
        reader.readEnum();
        msg.setMagicalClickType(value);
        break;

      case 2:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setFieldId(value);
        break;

      case 3:
        var value =
        /** @type {!proto.e.SegmentType} */
        reader.readEnum();
        msg.setSource(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.e.FormAutomationMagicalClickStep.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.e.FormAutomationMagicalClickStep.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.e.FormAutomationMagicalClickStep} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.e.FormAutomationMagicalClickStep.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getMagicalClickType();

  if (f !== 0.0) {
    writer.writeEnum(1, f);
  }

  f = message.getFieldId();

  if (f.length > 0) {
    writer.writeString(2, f);
  }

  f = message.getSource();

  if (f !== 0.0) {
    writer.writeEnum(3, f);
  }
};
/**
 * optional MagicalClickType magical_click_type = 1;
 * @return {!proto.e.MagicalClickType}
 */


proto.e.FormAutomationMagicalClickStep.prototype.getMagicalClickType = function () {
  return (
    /** @type {!proto.e.MagicalClickType} */
    jspb.Message.getFieldWithDefault(this, 1, 0)
  );
};
/**
 * @param {!proto.e.MagicalClickType} value
 * @return {!proto.e.FormAutomationMagicalClickStep} returns this
 */


proto.e.FormAutomationMagicalClickStep.prototype.setMagicalClickType = function (value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};
/**
 * optional string field_id = 2;
 * @return {string}
 */


proto.e.FormAutomationMagicalClickStep.prototype.getFieldId = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 2, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.e.FormAutomationMagicalClickStep} returns this
 */


proto.e.FormAutomationMagicalClickStep.prototype.setFieldId = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};
/**
 * optional SegmentType source = 3;
 * @return {!proto.e.SegmentType}
 */


proto.e.FormAutomationMagicalClickStep.prototype.getSource = function () {
  return (
    /** @type {!proto.e.SegmentType} */
    jspb.Message.getFieldWithDefault(this, 3, 0)
  );
};
/**
 * @param {!proto.e.SegmentType} value
 * @return {!proto.e.FormAutomationMagicalClickStep} returns this
 */


proto.e.FormAutomationMagicalClickStep.prototype.setSource = function (value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};
/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */


proto.e.FormAutomationClickByValueStep.oneofGroups_ = [[16, 17]];
/**
 * @enum {number}
 */

proto.e.FormAutomationClickByValueStep.ValueCase = {
  VALUE_NOT_SET: 0,
  FIELD_ID: 16,
  FIXED_VALUE: 17
};
/**
 * @return {proto.e.FormAutomationClickByValueStep.ValueCase}
 */

proto.e.FormAutomationClickByValueStep.prototype.getValueCase = function () {
  return (
    /** @type {proto.e.FormAutomationClickByValueStep.ValueCase} */
    jspb.Message.computeOneofCase(this, proto.e.FormAutomationClickByValueStep.oneofGroups_[0])
  );
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.e.FormAutomationClickByValueStep.prototype.toObject = function (opt_includeInstance) {
    return proto.e.FormAutomationClickByValueStep.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.e.FormAutomationClickByValueStep} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.e.FormAutomationClickByValueStep.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      querySelector: jspb.Message.getFieldWithDefault(msg, 1, ""),
      valueType: jspb.Message.getFieldWithDefault(msg, 2, 0),
      valueMapMap: (f = msg.getValueMapMap()) ? f.toObject(includeInstance, undefined) : [],
      source: jspb.Message.getFieldWithDefault(msg, 4, 0),
      fieldId: jspb.Message.getFieldWithDefault(msg, 16, ""),
      fixedValue: jspb.Message.getFieldWithDefault(msg, 17, ""),
      htmlAttr: jspb.Message.getFieldWithDefault(msg, 18, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.e.FormAutomationClickByValueStep}
 */


proto.e.FormAutomationClickByValueStep.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.e.FormAutomationClickByValueStep();
  return proto.e.FormAutomationClickByValueStep.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.e.FormAutomationClickByValueStep} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.e.FormAutomationClickByValueStep}
 */


proto.e.FormAutomationClickByValueStep.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setQuerySelector(value);
        break;

      case 2:
        var value =
        /** @type {!proto.e.FormAutomationStepValueType} */
        reader.readEnum();
        msg.setValueType(value);
        break;

      case 3:
        var value = msg.getValueMapMap();
        reader.readMessage(value, function (message, reader) {
          jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
        });
        break;

      case 4:
        var value =
        /** @type {!proto.e.SegmentType} */
        reader.readEnum();
        msg.setSource(value);
        break;

      case 16:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setFieldId(value);
        break;

      case 17:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setFixedValue(value);
        break;

      case 18:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setHtmlAttr(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.e.FormAutomationClickByValueStep.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.e.FormAutomationClickByValueStep.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.e.FormAutomationClickByValueStep} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.e.FormAutomationClickByValueStep.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getQuerySelector();

  if (f.length > 0) {
    writer.writeString(1, f);
  }

  f = message.getValueType();

  if (f !== 0.0) {
    writer.writeEnum(2, f);
  }

  f = message.getValueMapMap(true);

  if (f && f.getLength() > 0) {
    f.serializeBinary(3, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }

  f = message.getSource();

  if (f !== 0.0) {
    writer.writeEnum(4, f);
  }

  f =
  /** @type {string} */
  jspb.Message.getField(message, 16);

  if (f != null) {
    writer.writeString(16, f);
  }

  f =
  /** @type {string} */
  jspb.Message.getField(message, 17);

  if (f != null) {
    writer.writeString(17, f);
  }

  f = message.getHtmlAttr();

  if (f.length > 0) {
    writer.writeString(18, f);
  }
};
/**
 * optional string query_selector = 1;
 * @return {string}
 */


proto.e.FormAutomationClickByValueStep.prototype.getQuerySelector = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 1, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.e.FormAutomationClickByValueStep} returns this
 */


proto.e.FormAutomationClickByValueStep.prototype.setQuerySelector = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional FormAutomationStepValueType value_type = 2;
 * @return {!proto.e.FormAutomationStepValueType}
 */


proto.e.FormAutomationClickByValueStep.prototype.getValueType = function () {
  return (
    /** @type {!proto.e.FormAutomationStepValueType} */
    jspb.Message.getFieldWithDefault(this, 2, 0)
  );
};
/**
 * @param {!proto.e.FormAutomationStepValueType} value
 * @return {!proto.e.FormAutomationClickByValueStep} returns this
 */


proto.e.FormAutomationClickByValueStep.prototype.setValueType = function (value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};
/**
 * map<string, string> value_map = 3;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */


proto.e.FormAutomationClickByValueStep.prototype.getValueMapMap = function (opt_noLazyCreate) {
  return (
    /** @type {!jspb.Map<string,string>} */
    jspb.Message.getMapField(this, 3, opt_noLazyCreate, null)
  );
};
/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.e.FormAutomationClickByValueStep} returns this
 */


proto.e.FormAutomationClickByValueStep.prototype.clearValueMapMap = function () {
  this.getValueMapMap().clear();
  return this;
};
/**
 * optional SegmentType source = 4;
 * @return {!proto.e.SegmentType}
 */


proto.e.FormAutomationClickByValueStep.prototype.getSource = function () {
  return (
    /** @type {!proto.e.SegmentType} */
    jspb.Message.getFieldWithDefault(this, 4, 0)
  );
};
/**
 * @param {!proto.e.SegmentType} value
 * @return {!proto.e.FormAutomationClickByValueStep} returns this
 */


proto.e.FormAutomationClickByValueStep.prototype.setSource = function (value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};
/**
 * optional string field_id = 16;
 * @return {string}
 */


proto.e.FormAutomationClickByValueStep.prototype.getFieldId = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 16, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.e.FormAutomationClickByValueStep} returns this
 */


proto.e.FormAutomationClickByValueStep.prototype.setFieldId = function (value) {
  return jspb.Message.setOneofField(this, 16, proto.e.FormAutomationClickByValueStep.oneofGroups_[0], value);
};
/**
 * Clears the field making it undefined.
 * @return {!proto.e.FormAutomationClickByValueStep} returns this
 */


proto.e.FormAutomationClickByValueStep.prototype.clearFieldId = function () {
  return jspb.Message.setOneofField(this, 16, proto.e.FormAutomationClickByValueStep.oneofGroups_[0], undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.e.FormAutomationClickByValueStep.prototype.hasFieldId = function () {
  return jspb.Message.getField(this, 16) != null;
};
/**
 * optional string fixed_value = 17;
 * @return {string}
 */


proto.e.FormAutomationClickByValueStep.prototype.getFixedValue = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 17, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.e.FormAutomationClickByValueStep} returns this
 */


proto.e.FormAutomationClickByValueStep.prototype.setFixedValue = function (value) {
  return jspb.Message.setOneofField(this, 17, proto.e.FormAutomationClickByValueStep.oneofGroups_[0], value);
};
/**
 * Clears the field making it undefined.
 * @return {!proto.e.FormAutomationClickByValueStep} returns this
 */


proto.e.FormAutomationClickByValueStep.prototype.clearFixedValue = function () {
  return jspb.Message.setOneofField(this, 17, proto.e.FormAutomationClickByValueStep.oneofGroups_[0], undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.e.FormAutomationClickByValueStep.prototype.hasFixedValue = function () {
  return jspb.Message.getField(this, 17) != null;
};
/**
 * optional string html_attr = 18;
 * @return {string}
 */


proto.e.FormAutomationClickByValueStep.prototype.getHtmlAttr = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 18, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.e.FormAutomationClickByValueStep} returns this
 */


proto.e.FormAutomationClickByValueStep.prototype.setHtmlAttr = function (value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.e.FormAutomationTimeWaitStep.prototype.toObject = function (opt_includeInstance) {
    return proto.e.FormAutomationTimeWaitStep.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.e.FormAutomationTimeWaitStep} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.e.FormAutomationTimeWaitStep.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      waitS: jspb.Message.getFieldWithDefault(msg, 1, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.e.FormAutomationTimeWaitStep}
 */


proto.e.FormAutomationTimeWaitStep.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.e.FormAutomationTimeWaitStep();
  return proto.e.FormAutomationTimeWaitStep.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.e.FormAutomationTimeWaitStep} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.e.FormAutomationTimeWaitStep}
 */


proto.e.FormAutomationTimeWaitStep.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value =
        /** @type {number} */
        reader.readInt64();
        msg.setWaitS(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.e.FormAutomationTimeWaitStep.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.e.FormAutomationTimeWaitStep.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.e.FormAutomationTimeWaitStep} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.e.FormAutomationTimeWaitStep.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getWaitS();

  if (f !== 0) {
    writer.writeInt64(1, f);
  }
};
/**
 * optional int64 wait_s = 1;
 * @return {number}
 */


proto.e.FormAutomationTimeWaitStep.prototype.getWaitS = function () {
  return (
    /** @type {number} */
    jspb.Message.getFieldWithDefault(this, 1, 0)
  );
};
/**
 * @param {number} value
 * @return {!proto.e.FormAutomationTimeWaitStep} returns this
 */


proto.e.FormAutomationTimeWaitStep.prototype.setWaitS = function (value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.e.FormAutomationElementWaitStep.prototype.toObject = function (opt_includeInstance) {
    return proto.e.FormAutomationElementWaitStep.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.e.FormAutomationElementWaitStep} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.e.FormAutomationElementWaitStep.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      querySelector: jspb.Message.getFieldWithDefault(msg, 1, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.e.FormAutomationElementWaitStep}
 */


proto.e.FormAutomationElementWaitStep.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.e.FormAutomationElementWaitStep();
  return proto.e.FormAutomationElementWaitStep.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.e.FormAutomationElementWaitStep} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.e.FormAutomationElementWaitStep}
 */


proto.e.FormAutomationElementWaitStep.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setQuerySelector(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.e.FormAutomationElementWaitStep.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.e.FormAutomationElementWaitStep.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.e.FormAutomationElementWaitStep} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.e.FormAutomationElementWaitStep.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getQuerySelector();

  if (f.length > 0) {
    writer.writeString(1, f);
  }
};
/**
 * optional string query_selector = 1;
 * @return {string}
 */


proto.e.FormAutomationElementWaitStep.prototype.getQuerySelector = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 1, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.e.FormAutomationElementWaitStep} returns this
 */


proto.e.FormAutomationElementWaitStep.prototype.setQuerySelector = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.e.FormAutomationNavigationWaitStep.prototype.toObject = function (opt_includeInstance) {
    return proto.e.FormAutomationNavigationWaitStep.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.e.FormAutomationNavigationWaitStep} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.e.FormAutomationNavigationWaitStep.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      waitS: jspb.Message.getFieldWithDefault(msg, 1, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.e.FormAutomationNavigationWaitStep}
 */


proto.e.FormAutomationNavigationWaitStep.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.e.FormAutomationNavigationWaitStep();
  return proto.e.FormAutomationNavigationWaitStep.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.e.FormAutomationNavigationWaitStep} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.e.FormAutomationNavigationWaitStep}
 */


proto.e.FormAutomationNavigationWaitStep.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value =
        /** @type {number} */
        reader.readInt64();
        msg.setWaitS(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.e.FormAutomationNavigationWaitStep.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.e.FormAutomationNavigationWaitStep.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.e.FormAutomationNavigationWaitStep} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.e.FormAutomationNavigationWaitStep.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getWaitS();

  if (f !== 0) {
    writer.writeInt64(1, f);
  }
};
/**
 * optional int64 wait_s = 1;
 * @return {number}
 */


proto.e.FormAutomationNavigationWaitStep.prototype.getWaitS = function () {
  return (
    /** @type {number} */
    jspb.Message.getFieldWithDefault(this, 1, 0)
  );
};
/**
 * @param {number} value
 * @return {!proto.e.FormAutomationNavigationWaitStep} returns this
 */


proto.e.FormAutomationNavigationWaitStep.prototype.setWaitS = function (value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */


proto.e.FormAutomationConfig.repeatedFields_ = [4];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.e.FormAutomationConfig.prototype.toObject = function (opt_includeInstance) {
    return proto.e.FormAutomationConfig.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.e.FormAutomationConfig} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.e.FormAutomationConfig.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, ""),
      url: jspb.Message.getFieldWithDefault(msg, 2, ""),
      formBuildId: jspb.Message.getFieldWithDefault(msg, 3, ""),
      stepsList: jspb.Message.toObjectList(msg.getStepsList(), proto.e.FormAutomationStep.toObject, includeInstance),
      completionStatus: jspb.Message.getFieldWithDefault(msg, 5, 0),
      record: (f = msg.getRecord()) && entities_entities_pb.Record.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.e.FormAutomationConfig}
 */


proto.e.FormAutomationConfig.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.e.FormAutomationConfig();
  return proto.e.FormAutomationConfig.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.e.FormAutomationConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.e.FormAutomationConfig}
 */


proto.e.FormAutomationConfig.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setId(value);
        break;

      case 2:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setUrl(value);
        break;

      case 3:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setFormBuildId(value);
        break;

      case 4:
        var value = new proto.e.FormAutomationStep();
        reader.readMessage(value, proto.e.FormAutomationStep.deserializeBinaryFromReader);
        msg.addSteps(value);
        break;

      case 5:
        var value =
        /** @type {!proto.e.CompletionStatus} */
        reader.readEnum();
        msg.setCompletionStatus(value);
        break;

      case 15:
        var value = new entities_entities_pb.Record();
        reader.readMessage(value, entities_entities_pb.Record.deserializeBinaryFromReader);
        msg.setRecord(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.e.FormAutomationConfig.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.e.FormAutomationConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.e.FormAutomationConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.e.FormAutomationConfig.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getId();

  if (f.length > 0) {
    writer.writeString(1, f);
  }

  f = message.getUrl();

  if (f.length > 0) {
    writer.writeString(2, f);
  }

  f = message.getFormBuildId();

  if (f.length > 0) {
    writer.writeString(3, f);
  }

  f = message.getStepsList();

  if (f.length > 0) {
    writer.writeRepeatedMessage(4, f, proto.e.FormAutomationStep.serializeBinaryToWriter);
  }

  f = message.getCompletionStatus();

  if (f !== 0.0) {
    writer.writeEnum(5, f);
  }

  f = message.getRecord();

  if (f != null) {
    writer.writeMessage(15, f, entities_entities_pb.Record.serializeBinaryToWriter);
  }
};
/**
 * optional string id = 1;
 * @return {string}
 */


proto.e.FormAutomationConfig.prototype.getId = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 1, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.e.FormAutomationConfig} returns this
 */


proto.e.FormAutomationConfig.prototype.setId = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional string url = 2;
 * @return {string}
 */


proto.e.FormAutomationConfig.prototype.getUrl = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 2, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.e.FormAutomationConfig} returns this
 */


proto.e.FormAutomationConfig.prototype.setUrl = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};
/**
 * optional string form_build_id = 3;
 * @return {string}
 */


proto.e.FormAutomationConfig.prototype.getFormBuildId = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 3, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.e.FormAutomationConfig} returns this
 */


proto.e.FormAutomationConfig.prototype.setFormBuildId = function (value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};
/**
 * repeated FormAutomationStep steps = 4;
 * @return {!Array<!proto.e.FormAutomationStep>}
 */


proto.e.FormAutomationConfig.prototype.getStepsList = function () {
  return (
    /** @type{!Array<!proto.e.FormAutomationStep>} */
    jspb.Message.getRepeatedWrapperField(this, proto.e.FormAutomationStep, 4)
  );
};
/**
 * @param {!Array<!proto.e.FormAutomationStep>} value
 * @return {!proto.e.FormAutomationConfig} returns this
*/


proto.e.FormAutomationConfig.prototype.setStepsList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};
/**
 * @param {!proto.e.FormAutomationStep=} opt_value
 * @param {number=} opt_index
 * @return {!proto.e.FormAutomationStep}
 */


proto.e.FormAutomationConfig.prototype.addSteps = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.e.FormAutomationStep, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.e.FormAutomationConfig} returns this
 */


proto.e.FormAutomationConfig.prototype.clearStepsList = function () {
  return this.setStepsList([]);
};
/**
 * optional CompletionStatus completion_status = 5;
 * @return {!proto.e.CompletionStatus}
 */


proto.e.FormAutomationConfig.prototype.getCompletionStatus = function () {
  return (
    /** @type {!proto.e.CompletionStatus} */
    jspb.Message.getFieldWithDefault(this, 5, 0)
  );
};
/**
 * @param {!proto.e.CompletionStatus} value
 * @return {!proto.e.FormAutomationConfig} returns this
 */


proto.e.FormAutomationConfig.prototype.setCompletionStatus = function (value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};
/**
 * optional Record record = 15;
 * @return {?proto.e.Record}
 */


proto.e.FormAutomationConfig.prototype.getRecord = function () {
  return (
    /** @type{?proto.e.Record} */
    jspb.Message.getWrapperField(this, entities_entities_pb.Record, 15)
  );
};
/**
 * @param {?proto.e.Record|undefined} value
 * @return {!proto.e.FormAutomationConfig} returns this
*/


proto.e.FormAutomationConfig.prototype.setRecord = function (value) {
  return jspb.Message.setWrapperField(this, 15, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.e.FormAutomationConfig} returns this
 */


proto.e.FormAutomationConfig.prototype.clearRecord = function () {
  return this.setRecord(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.e.FormAutomationConfig.prototype.hasRecord = function () {
  return jspb.Message.getField(this, 15) != null;
};
/**
 * @enum {number}
 */


proto.e.FormAutomationStepAction = {
  FORM_AUTOMATION_STEP_ACTION_UNSPECIFIED: 0,
  FORM_AUTOMATION_STEP_ACTION_CLICK: 1,
  FORM_AUTOMATION_STEP_ACTION_TEXT_INPUT: 2,
  FORM_AUTOMATION_STEP_ACTION_SELECT_INPUT: 3,
  FORM_AUTOMATION_STEP_ACTION_TIME_WAIT: 4,
  FORM_AUTOMATION_STEP_ACTION_ELEMENT_WAIT: 5,
  FORM_AUTOMATION_STEP_ACTION_RADIO_INPUT: 6,
  FORM_AUTOMATION_STEP_ACTION_NAVIGATION_WAIT: 7,
  FORM_AUTOMATION_STEP_ACTION_MAGICAL_CLICK: 8,
  FORM_AUTOMATION_STEP_ACTION_CLICK_BY_VALUE: 9,
  FORM_AUTOMATION_STEP_ACTION_CHECKBOX_INPUT: 10
};
/**
 * @enum {number}
 */

proto.e.TextReplacementType = {
  TEXT_REPLACEMENT_UNSPECIFIED: 0,
  TEXT_REPLACEMENT_UUID_V4: 1,
  TEXT_REPLACEMENT_FIXED: 2
};
/**
 * @enum {number}
 */

proto.e.FormAutomationStepValueType = {
  FORM_AUTOMATION_STEP_VALUE_TYPE_UNSPECIFIED: 0,
  FORM_AUTOMATION_STEP_VALUE_TYPE_FIELD: 1,
  FORM_AUTOMATION_STEP_VALUE_TYPE_FIXED: 2
};
/**
 * @enum {number}
 */

proto.e.DatePart = {
  DATE_PART_UNSPECIFIED: 0,
  DATE_PART_YEAR: 1,
  DATE_PART_MONTH: 2,
  DATE_PART_DAY: 3,
  DATE_PART_HOUR: 4,
  DATE_PART_MINUTE: 5,
  DATE_PART_SECOND: 6
};
/**
 * @enum {number}
 */

proto.e.DateFormat = {
  DATE_FORMAT_UNSPECIFIED: 0,
  DATE_FORMAT_DEFAULT_YEAR: 1,
  DATE_FORMAT_DEFAULT_MONTH: 2,
  DATE_FORMAT_DEFAULT_DAY: 3,
  DATE_FORMAT_DEFAULT_MONTH_DAY: 4,
  DATE_FORMAT_DEFAULT_YEAR_MONTH_DAY: 5,
  DATE_FORMAT_DEFAULT_HOUR: 6,
  DATE_FORMAT_DEFAULT_MINUTE: 7,
  DATE_FORMAT_DEFAULT_SECOND: 8,
  DATE_FORMAT_DEFAULT_HOUR_MINUTE: 9,
  DATE_FORMAT_DEFAULT_HOUR_MINUTE_SECOND: 10,
  DATE_FORMAT_ZERO_PADDED_MONTH: 11,
  DATE_FORMAT_ZERO_PADDED_DAY: 12,
  DATE_FORMAT_LACOCO_YEAR: 13,
  DATE_FORMAT_CURVES_YEAR_MONTH_DAY: 14,
  DATE_FORMAT_CURVES_TIME: 15,
  DATE_FORMAT_HYPHEN_ZERO_PADDED_YEAR_MONTH_DAY: 16
};
/**
 * @enum {number}
 */

proto.e.MagicalClickType = {
  MAGICAL_CLICK_TYPE_UNSPECIFIED: 0,
  MAGICAL_CLICK_TYPE_HONDA: 1,
  MAGICAL_CLICK_TYPE_YOYAKU: 2
};
goog.object.extend(exports, proto.e);