// source: entities/message.proto

/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

/* eslint-disable */
// @ts-nocheck
var jspb = require('google-protobuf');

var goog = jspb;
var global = Function('return this')();

var entities_element_pb = require('../entities/element_pb.js');

goog.object.extend(proto, entities_element_pb);

var entities_entities_pb = require('../entities/entities_pb.js');

goog.object.extend(proto, entities_entities_pb);

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');

goog.object.extend(proto, google_protobuf_timestamp_pb);

var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');

goog.object.extend(proto, google_protobuf_wrappers_pb);
goog.exportSymbol('proto.e.Action', null, global);
goog.exportSymbol('proto.e.ActionType', null, global);
goog.exportSymbol('proto.e.Block', null, global);
goog.exportSymbol('proto.e.Message', null, global);
goog.exportSymbol('proto.e.Question', null, global);
goog.exportSymbol('proto.e.Question.ResponseIdCase', null, global);
goog.exportSymbol('proto.e.QuestionType', null, global);
goog.exportSymbol('proto.e.Scenario', null, global);
goog.exportSymbol('proto.e.UIComponent', null, global);
goog.exportSymbol('proto.e.UIComponentType', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */

proto.e.UIComponent = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.e.UIComponent, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.e.UIComponent.displayName = 'proto.e.UIComponent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.e.Action = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.e.Action, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.e.Action.displayName = 'proto.e.Action';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.e.Scenario = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.e.Scenario, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.e.Scenario.displayName = 'proto.e.Scenario';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.e.Block = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.e.Block.repeatedFields_, null);
};

goog.inherits(proto.e.Block, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.e.Block.displayName = 'proto.e.Block';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.e.Message = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.e.Message, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.e.Message.displayName = 'proto.e.Message';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.e.Question = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.e.Question.oneofGroups_);
};

goog.inherits(proto.e.Question, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.e.Question.displayName = 'proto.e.Question';
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.e.UIComponent.prototype.toObject = function (opt_includeInstance) {
    return proto.e.UIComponent.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.e.UIComponent} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.e.UIComponent.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, 0),
      type: jspb.Message.getFieldWithDefault(msg, 2, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.e.UIComponent}
 */


proto.e.UIComponent.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.e.UIComponent();
  return proto.e.UIComponent.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.e.UIComponent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.e.UIComponent}
 */


proto.e.UIComponent.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value =
        /** @type {number} */
        reader.readInt64();
        msg.setId(value);
        break;

      case 2:
        var value =
        /** @type {!proto.e.UIComponentType} */
        reader.readEnum();
        msg.setType(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.e.UIComponent.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.e.UIComponent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.e.UIComponent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.e.UIComponent.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getId();

  if (f !== 0) {
    writer.writeInt64(1, f);
  }

  f = message.getType();

  if (f !== 0.0) {
    writer.writeEnum(2, f);
  }
};
/**
 * optional int64 id = 1;
 * @return {number}
 */


proto.e.UIComponent.prototype.getId = function () {
  return (
    /** @type {number} */
    jspb.Message.getFieldWithDefault(this, 1, 0)
  );
};
/**
 * @param {number} value
 * @return {!proto.e.UIComponent} returns this
 */


proto.e.UIComponent.prototype.setId = function (value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};
/**
 * optional UIComponentType type = 2;
 * @return {!proto.e.UIComponentType}
 */


proto.e.UIComponent.prototype.getType = function () {
  return (
    /** @type {!proto.e.UIComponentType} */
    jspb.Message.getFieldWithDefault(this, 2, 0)
  );
};
/**
 * @param {!proto.e.UIComponentType} value
 * @return {!proto.e.UIComponent} returns this
 */


proto.e.UIComponent.prototype.setType = function (value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.e.Action.prototype.toObject = function (opt_includeInstance) {
    return proto.e.Action.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.e.Action} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.e.Action.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, 0),
      type: jspb.Message.getFieldWithDefault(msg, 2, 0),
      url: jspb.Message.getFieldWithDefault(msg, 3, ""),
      blockId: jspb.Message.getFieldWithDefault(msg, 4, 0),
      scenarioId: jspb.Message.getFieldWithDefault(msg, 5, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.e.Action}
 */


proto.e.Action.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.e.Action();
  return proto.e.Action.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.e.Action} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.e.Action}
 */


proto.e.Action.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value =
        /** @type {number} */
        reader.readInt64();
        msg.setId(value);
        break;

      case 2:
        var value =
        /** @type {!proto.e.ActionType} */
        reader.readEnum();
        msg.setType(value);
        break;

      case 3:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setUrl(value);
        break;

      case 4:
        var value =
        /** @type {number} */
        reader.readInt64();
        msg.setBlockId(value);
        break;

      case 5:
        var value =
        /** @type {number} */
        reader.readInt64();
        msg.setScenarioId(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.e.Action.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.e.Action.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.e.Action} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.e.Action.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getId();

  if (f !== 0) {
    writer.writeInt64(1, f);
  }

  f = message.getType();

  if (f !== 0.0) {
    writer.writeEnum(2, f);
  }

  f = message.getUrl();

  if (f.length > 0) {
    writer.writeString(3, f);
  }

  f = message.getBlockId();

  if (f !== 0) {
    writer.writeInt64(4, f);
  }

  f = message.getScenarioId();

  if (f !== 0) {
    writer.writeInt64(5, f);
  }
};
/**
 * optional int64 id = 1;
 * @return {number}
 */


proto.e.Action.prototype.getId = function () {
  return (
    /** @type {number} */
    jspb.Message.getFieldWithDefault(this, 1, 0)
  );
};
/**
 * @param {number} value
 * @return {!proto.e.Action} returns this
 */


proto.e.Action.prototype.setId = function (value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};
/**
 * optional ActionType type = 2;
 * @return {!proto.e.ActionType}
 */


proto.e.Action.prototype.getType = function () {
  return (
    /** @type {!proto.e.ActionType} */
    jspb.Message.getFieldWithDefault(this, 2, 0)
  );
};
/**
 * @param {!proto.e.ActionType} value
 * @return {!proto.e.Action} returns this
 */


proto.e.Action.prototype.setType = function (value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};
/**
 * optional string url = 3;
 * @return {string}
 */


proto.e.Action.prototype.getUrl = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 3, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.e.Action} returns this
 */


proto.e.Action.prototype.setUrl = function (value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};
/**
 * optional int64 block_id = 4;
 * @return {number}
 */


proto.e.Action.prototype.getBlockId = function () {
  return (
    /** @type {number} */
    jspb.Message.getFieldWithDefault(this, 4, 0)
  );
};
/**
 * @param {number} value
 * @return {!proto.e.Action} returns this
 */


proto.e.Action.prototype.setBlockId = function (value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};
/**
 * optional int64 scenario_id = 5;
 * @return {number}
 */


proto.e.Action.prototype.getScenarioId = function () {
  return (
    /** @type {number} */
    jspb.Message.getFieldWithDefault(this, 5, 0)
  );
};
/**
 * @param {number} value
 * @return {!proto.e.Action} returns this
 */


proto.e.Action.prototype.setScenarioId = function (value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.e.Scenario.prototype.toObject = function (opt_includeInstance) {
    return proto.e.Scenario.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.e.Scenario} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.e.Scenario.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, 0),
      chatbotId: jspb.Message.getFieldWithDefault(msg, 2, 0),
      name: jspb.Message.getFieldWithDefault(msg, 3, ""),
      isTest: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
      firstBlockId: jspb.Message.getFieldWithDefault(msg, 5, 0),
      firstLayerId: jspb.Message.getFieldWithDefault(msg, 6, 0),
      deleteTime: (f = msg.getDeleteTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
      record: (f = msg.getRecord()) && entities_entities_pb.Record.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.e.Scenario}
 */


proto.e.Scenario.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.e.Scenario();
  return proto.e.Scenario.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.e.Scenario} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.e.Scenario}
 */


proto.e.Scenario.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value =
        /** @type {number} */
        reader.readInt64();
        msg.setId(value);
        break;

      case 2:
        var value =
        /** @type {number} */
        reader.readInt64();
        msg.setChatbotId(value);
        break;

      case 3:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setName(value);
        break;

      case 4:
        var value =
        /** @type {boolean} */
        reader.readBool();
        msg.setIsTest(value);
        break;

      case 5:
        var value =
        /** @type {number} */
        reader.readInt64();
        msg.setFirstBlockId(value);
        break;

      case 6:
        var value =
        /** @type {number} */
        reader.readInt64();
        msg.setFirstLayerId(value);
        break;

      case 14:
        var value = new google_protobuf_timestamp_pb.Timestamp();
        reader.readMessage(value, google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
        msg.setDeleteTime(value);
        break;

      case 15:
        var value = new entities_entities_pb.Record();
        reader.readMessage(value, entities_entities_pb.Record.deserializeBinaryFromReader);
        msg.setRecord(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.e.Scenario.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.e.Scenario.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.e.Scenario} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.e.Scenario.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getId();

  if (f !== 0) {
    writer.writeInt64(1, f);
  }

  f = message.getChatbotId();

  if (f !== 0) {
    writer.writeInt64(2, f);
  }

  f = message.getName();

  if (f.length > 0) {
    writer.writeString(3, f);
  }

  f = message.getIsTest();

  if (f) {
    writer.writeBool(4, f);
  }

  f = message.getFirstBlockId();

  if (f !== 0) {
    writer.writeInt64(5, f);
  }

  f = message.getFirstLayerId();

  if (f !== 0) {
    writer.writeInt64(6, f);
  }

  f = message.getDeleteTime();

  if (f != null) {
    writer.writeMessage(14, f, google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter);
  }

  f = message.getRecord();

  if (f != null) {
    writer.writeMessage(15, f, entities_entities_pb.Record.serializeBinaryToWriter);
  }
};
/**
 * optional int64 id = 1;
 * @return {number}
 */


proto.e.Scenario.prototype.getId = function () {
  return (
    /** @type {number} */
    jspb.Message.getFieldWithDefault(this, 1, 0)
  );
};
/**
 * @param {number} value
 * @return {!proto.e.Scenario} returns this
 */


proto.e.Scenario.prototype.setId = function (value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};
/**
 * optional int64 chatbot_id = 2;
 * @return {number}
 */


proto.e.Scenario.prototype.getChatbotId = function () {
  return (
    /** @type {number} */
    jspb.Message.getFieldWithDefault(this, 2, 0)
  );
};
/**
 * @param {number} value
 * @return {!proto.e.Scenario} returns this
 */


proto.e.Scenario.prototype.setChatbotId = function (value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};
/**
 * optional string name = 3;
 * @return {string}
 */


proto.e.Scenario.prototype.getName = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 3, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.e.Scenario} returns this
 */


proto.e.Scenario.prototype.setName = function (value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};
/**
 * optional bool is_test = 4;
 * @return {boolean}
 */


proto.e.Scenario.prototype.getIsTest = function () {
  return (
    /** @type {boolean} */
    jspb.Message.getBooleanFieldWithDefault(this, 4, false)
  );
};
/**
 * @param {boolean} value
 * @return {!proto.e.Scenario} returns this
 */


proto.e.Scenario.prototype.setIsTest = function (value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};
/**
 * optional int64 first_block_id = 5;
 * @return {number}
 */


proto.e.Scenario.prototype.getFirstBlockId = function () {
  return (
    /** @type {number} */
    jspb.Message.getFieldWithDefault(this, 5, 0)
  );
};
/**
 * @param {number} value
 * @return {!proto.e.Scenario} returns this
 */


proto.e.Scenario.prototype.setFirstBlockId = function (value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};
/**
 * optional int64 first_layer_id = 6;
 * @return {number}
 */


proto.e.Scenario.prototype.getFirstLayerId = function () {
  return (
    /** @type {number} */
    jspb.Message.getFieldWithDefault(this, 6, 0)
  );
};
/**
 * @param {number} value
 * @return {!proto.e.Scenario} returns this
 */


proto.e.Scenario.prototype.setFirstLayerId = function (value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};
/**
 * optional google.protobuf.Timestamp delete_time = 14;
 * @return {?proto.google.protobuf.Timestamp}
 */


proto.e.Scenario.prototype.getDeleteTime = function () {
  return (
    /** @type{?proto.google.protobuf.Timestamp} */
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 14)
  );
};
/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.e.Scenario} returns this
*/


proto.e.Scenario.prototype.setDeleteTime = function (value) {
  return jspb.Message.setWrapperField(this, 14, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.e.Scenario} returns this
 */


proto.e.Scenario.prototype.clearDeleteTime = function () {
  return this.setDeleteTime(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.e.Scenario.prototype.hasDeleteTime = function () {
  return jspb.Message.getField(this, 14) != null;
};
/**
 * optional Record record = 15;
 * @return {?proto.e.Record}
 */


proto.e.Scenario.prototype.getRecord = function () {
  return (
    /** @type{?proto.e.Record} */
    jspb.Message.getWrapperField(this, entities_entities_pb.Record, 15)
  );
};
/**
 * @param {?proto.e.Record|undefined} value
 * @return {!proto.e.Scenario} returns this
*/


proto.e.Scenario.prototype.setRecord = function (value) {
  return jspb.Message.setWrapperField(this, 15, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.e.Scenario} returns this
 */


proto.e.Scenario.prototype.clearRecord = function () {
  return this.setRecord(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.e.Scenario.prototype.hasRecord = function () {
  return jspb.Message.getField(this, 15) != null;
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */


proto.e.Block.repeatedFields_ = [3];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.e.Block.prototype.toObject = function (opt_includeInstance) {
    return proto.e.Block.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.e.Block} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.e.Block.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, 0),
      scenarioId: jspb.Message.getFieldWithDefault(msg, 2, 0),
      messagesList: jspb.Message.toObjectList(msg.getMessagesList(), proto.e.Message.toObject, includeInstance),
      record: (f = msg.getRecord()) && entities_entities_pb.Record.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.e.Block}
 */


proto.e.Block.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.e.Block();
  return proto.e.Block.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.e.Block} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.e.Block}
 */


proto.e.Block.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value =
        /** @type {number} */
        reader.readInt64();
        msg.setId(value);
        break;

      case 2:
        var value =
        /** @type {number} */
        reader.readInt64();
        msg.setScenarioId(value);
        break;

      case 3:
        var value = new proto.e.Message();
        reader.readMessage(value, proto.e.Message.deserializeBinaryFromReader);
        msg.addMessages(value);
        break;

      case 15:
        var value = new entities_entities_pb.Record();
        reader.readMessage(value, entities_entities_pb.Record.deserializeBinaryFromReader);
        msg.setRecord(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.e.Block.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.e.Block.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.e.Block} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.e.Block.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getId();

  if (f !== 0) {
    writer.writeInt64(1, f);
  }

  f = message.getScenarioId();

  if (f !== 0) {
    writer.writeInt64(2, f);
  }

  f = message.getMessagesList();

  if (f.length > 0) {
    writer.writeRepeatedMessage(3, f, proto.e.Message.serializeBinaryToWriter);
  }

  f = message.getRecord();

  if (f != null) {
    writer.writeMessage(15, f, entities_entities_pb.Record.serializeBinaryToWriter);
  }
};
/**
 * optional int64 id = 1;
 * @return {number}
 */


proto.e.Block.prototype.getId = function () {
  return (
    /** @type {number} */
    jspb.Message.getFieldWithDefault(this, 1, 0)
  );
};
/**
 * @param {number} value
 * @return {!proto.e.Block} returns this
 */


proto.e.Block.prototype.setId = function (value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};
/**
 * optional int64 scenario_id = 2;
 * @return {number}
 */


proto.e.Block.prototype.getScenarioId = function () {
  return (
    /** @type {number} */
    jspb.Message.getFieldWithDefault(this, 2, 0)
  );
};
/**
 * @param {number} value
 * @return {!proto.e.Block} returns this
 */


proto.e.Block.prototype.setScenarioId = function (value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};
/**
 * repeated Message messages = 3;
 * @return {!Array<!proto.e.Message>}
 */


proto.e.Block.prototype.getMessagesList = function () {
  return (
    /** @type{!Array<!proto.e.Message>} */
    jspb.Message.getRepeatedWrapperField(this, proto.e.Message, 3)
  );
};
/**
 * @param {!Array<!proto.e.Message>} value
 * @return {!proto.e.Block} returns this
*/


proto.e.Block.prototype.setMessagesList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};
/**
 * @param {!proto.e.Message=} opt_value
 * @param {number=} opt_index
 * @return {!proto.e.Message}
 */


proto.e.Block.prototype.addMessages = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.e.Message, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.e.Block} returns this
 */


proto.e.Block.prototype.clearMessagesList = function () {
  return this.setMessagesList([]);
};
/**
 * optional Record record = 15;
 * @return {?proto.e.Record}
 */


proto.e.Block.prototype.getRecord = function () {
  return (
    /** @type{?proto.e.Record} */
    jspb.Message.getWrapperField(this, entities_entities_pb.Record, 15)
  );
};
/**
 * @param {?proto.e.Record|undefined} value
 * @return {!proto.e.Block} returns this
*/


proto.e.Block.prototype.setRecord = function (value) {
  return jspb.Message.setWrapperField(this, 15, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.e.Block} returns this
 */


proto.e.Block.prototype.clearRecord = function () {
  return this.setRecord(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.e.Block.prototype.hasRecord = function () {
  return jspb.Message.getField(this, 15) != null;
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.e.Message.prototype.toObject = function (opt_includeInstance) {
    return proto.e.Message.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.e.Message} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.e.Message.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, ""),
      element: (f = msg.getElement()) && entities_element_pb.Element.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.e.Message}
 */


proto.e.Message.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.e.Message();
  return proto.e.Message.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.e.Message} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.e.Message}
 */


proto.e.Message.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setId(value);
        break;

      case 2:
        var value = new entities_element_pb.Element();
        reader.readMessage(value, entities_element_pb.Element.deserializeBinaryFromReader);
        msg.setElement(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.e.Message.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.e.Message.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.e.Message} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.e.Message.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getId();

  if (f.length > 0) {
    writer.writeString(1, f);
  }

  f = message.getElement();

  if (f != null) {
    writer.writeMessage(2, f, entities_element_pb.Element.serializeBinaryToWriter);
  }
};
/**
 * optional string id = 1;
 * @return {string}
 */


proto.e.Message.prototype.getId = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 1, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.e.Message} returns this
 */


proto.e.Message.prototype.setId = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional Element element = 2;
 * @return {?proto.e.Element}
 */


proto.e.Message.prototype.getElement = function () {
  return (
    /** @type{?proto.e.Element} */
    jspb.Message.getWrapperField(this, entities_element_pb.Element, 2)
  );
};
/**
 * @param {?proto.e.Element|undefined} value
 * @return {!proto.e.Message} returns this
*/


proto.e.Message.prototype.setElement = function (value) {
  return jspb.Message.setWrapperField(this, 2, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.e.Message} returns this
 */


proto.e.Message.prototype.clearElement = function () {
  return this.setElement(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.e.Message.prototype.hasElement = function () {
  return jspb.Message.getField(this, 2) != null;
};
/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */


proto.e.Question.oneofGroups_ = [[18, 19]];
/**
 * @enum {number}
 */

proto.e.Question.ResponseIdCase = {
  RESPONSE_ID_NOT_SET: 0,
  QUICK_REPLY_ID: 18,
  TALK_TRIGGER_ID: 19
};
/**
 * @return {proto.e.Question.ResponseIdCase}
 */

proto.e.Question.prototype.getResponseIdCase = function () {
  return (
    /** @type {proto.e.Question.ResponseIdCase} */
    jspb.Message.computeOneofCase(this, proto.e.Question.oneofGroups_[0])
  );
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.e.Question.prototype.toObject = function (opt_includeInstance) {
    return proto.e.Question.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.e.Question} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.e.Question.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, 0),
      scenarioId: jspb.Message.getFieldWithDefault(msg, 2, 0),
      type: jspb.Message.getFieldWithDefault(msg, 3, 0),
      fieldId: jspb.Message.getFieldWithDefault(msg, 4, ""),
      text: jspb.Message.getFieldWithDefault(msg, 5, ""),
      quickReplyId: (f = msg.getQuickReplyId()) && google_protobuf_wrappers_pb.Int64Value.toObject(includeInstance, f),
      talkTriggerId: (f = msg.getTalkTriggerId()) && google_protobuf_wrappers_pb.Int64Value.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.e.Question}
 */


proto.e.Question.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.e.Question();
  return proto.e.Question.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.e.Question} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.e.Question}
 */


proto.e.Question.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value =
        /** @type {number} */
        reader.readInt64();
        msg.setId(value);
        break;

      case 2:
        var value =
        /** @type {number} */
        reader.readInt64();
        msg.setScenarioId(value);
        break;

      case 3:
        var value =
        /** @type {!proto.e.QuestionType} */
        reader.readEnum();
        msg.setType(value);
        break;

      case 4:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setFieldId(value);
        break;

      case 5:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setText(value);
        break;

      case 18:
        var value = new google_protobuf_wrappers_pb.Int64Value();
        reader.readMessage(value, google_protobuf_wrappers_pb.Int64Value.deserializeBinaryFromReader);
        msg.setQuickReplyId(value);
        break;

      case 19:
        var value = new google_protobuf_wrappers_pb.Int64Value();
        reader.readMessage(value, google_protobuf_wrappers_pb.Int64Value.deserializeBinaryFromReader);
        msg.setTalkTriggerId(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.e.Question.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.e.Question.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.e.Question} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.e.Question.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getId();

  if (f !== 0) {
    writer.writeInt64(1, f);
  }

  f = message.getScenarioId();

  if (f !== 0) {
    writer.writeInt64(2, f);
  }

  f = message.getType();

  if (f !== 0.0) {
    writer.writeEnum(3, f);
  }

  f = message.getFieldId();

  if (f.length > 0) {
    writer.writeString(4, f);
  }

  f = message.getText();

  if (f.length > 0) {
    writer.writeString(5, f);
  }

  f = message.getQuickReplyId();

  if (f != null) {
    writer.writeMessage(18, f, google_protobuf_wrappers_pb.Int64Value.serializeBinaryToWriter);
  }

  f = message.getTalkTriggerId();

  if (f != null) {
    writer.writeMessage(19, f, google_protobuf_wrappers_pb.Int64Value.serializeBinaryToWriter);
  }
};
/**
 * optional int64 id = 1;
 * @return {number}
 */


proto.e.Question.prototype.getId = function () {
  return (
    /** @type {number} */
    jspb.Message.getFieldWithDefault(this, 1, 0)
  );
};
/**
 * @param {number} value
 * @return {!proto.e.Question} returns this
 */


proto.e.Question.prototype.setId = function (value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};
/**
 * optional int64 scenario_id = 2;
 * @return {number}
 */


proto.e.Question.prototype.getScenarioId = function () {
  return (
    /** @type {number} */
    jspb.Message.getFieldWithDefault(this, 2, 0)
  );
};
/**
 * @param {number} value
 * @return {!proto.e.Question} returns this
 */


proto.e.Question.prototype.setScenarioId = function (value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};
/**
 * optional QuestionType type = 3;
 * @return {!proto.e.QuestionType}
 */


proto.e.Question.prototype.getType = function () {
  return (
    /** @type {!proto.e.QuestionType} */
    jspb.Message.getFieldWithDefault(this, 3, 0)
  );
};
/**
 * @param {!proto.e.QuestionType} value
 * @return {!proto.e.Question} returns this
 */


proto.e.Question.prototype.setType = function (value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};
/**
 * optional string field_id = 4;
 * @return {string}
 */


proto.e.Question.prototype.getFieldId = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 4, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.e.Question} returns this
 */


proto.e.Question.prototype.setFieldId = function (value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};
/**
 * optional string text = 5;
 * @return {string}
 */


proto.e.Question.prototype.getText = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 5, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.e.Question} returns this
 */


proto.e.Question.prototype.setText = function (value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};
/**
 * optional google.protobuf.Int64Value quick_reply_id = 18;
 * @return {?proto.google.protobuf.Int64Value}
 */


proto.e.Question.prototype.getQuickReplyId = function () {
  return (
    /** @type{?proto.google.protobuf.Int64Value} */
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int64Value, 18)
  );
};
/**
 * @param {?proto.google.protobuf.Int64Value|undefined} value
 * @return {!proto.e.Question} returns this
*/


proto.e.Question.prototype.setQuickReplyId = function (value) {
  return jspb.Message.setOneofWrapperField(this, 18, proto.e.Question.oneofGroups_[0], value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.e.Question} returns this
 */


proto.e.Question.prototype.clearQuickReplyId = function () {
  return this.setQuickReplyId(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.e.Question.prototype.hasQuickReplyId = function () {
  return jspb.Message.getField(this, 18) != null;
};
/**
 * optional google.protobuf.Int64Value talk_trigger_id = 19;
 * @return {?proto.google.protobuf.Int64Value}
 */


proto.e.Question.prototype.getTalkTriggerId = function () {
  return (
    /** @type{?proto.google.protobuf.Int64Value} */
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int64Value, 19)
  );
};
/**
 * @param {?proto.google.protobuf.Int64Value|undefined} value
 * @return {!proto.e.Question} returns this
*/


proto.e.Question.prototype.setTalkTriggerId = function (value) {
  return jspb.Message.setOneofWrapperField(this, 19, proto.e.Question.oneofGroups_[0], value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.e.Question} returns this
 */


proto.e.Question.prototype.clearTalkTriggerId = function () {
  return this.setTalkTriggerId(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.e.Question.prototype.hasTalkTriggerId = function () {
  return jspb.Message.getField(this, 19) != null;
};
/**
 * @enum {number}
 */


proto.e.UIComponentType = {
  UI_TYPE_UNSPECIFIED: 0,
  UI_BUTTON: 1,
  UI_GENERIC_ITEM: 2,
  UI_PERSISTENT_MENU: 3,
  UI_QUICK_REPLY: 4,
  UI_ARTICLE: 5,
  UI_RICH_MENU_AREA: 6,
  UI_FLEX_BOX: 7,
  UI_FLEX_BUTTON: 8,
  UI_FLEX_TEXT: 9,
  UI_FLEX_IMAGE: 10,
  UI_IMAGE_MAP_AREA: 11,
  UI_IMAGE_MAP_VIDEO: 12
};
/**
 * @enum {number}
 */

proto.e.ActionType = {
  ACTION_TYPE_UNSPECIFIED: 0
};
/**
 * @enum {number}
 */

proto.e.QuestionType = {
  QUESTION_TYPE_UNSPECIFIED: 0,
  QUESTION_TYPE_TALK_TRIGGER: 1,
  QUESTION_TYPE_QUICK_REPLY: 2
};
goog.object.extend(exports, proto.e);