var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import React, { useState } from 'react';
import { Checkbox } from '@zeals-co-ltd/washi-components';
import InputRadioBox from '@src/components/atoms/InputRadioBox';
import { DateFormat } from 'zeals-protobuf/zeals/web/entities/form_automation_pb';
import isEqual from 'lodash/isEqual';
import { dateFormatExampleMap } from '@src/lib/formAutomation';
import { injectIntl } from 'react-intl';
import buttonTypes from '@src/lib/buttonTypes';
import { Button } from '@src/components/atoms';
// : TODO MOVE THIS LOGIC TO THE BACKEND
var searchMap = new Map();
searchMap.set(DateFormat.DATE_FORMAT_DEFAULT_YEAR, ['year']);
searchMap.set(DateFormat.DATE_FORMAT_DEFAULT_MONTH, ['month']);
searchMap.set(DateFormat.DATE_FORMAT_DEFAULT_DAY, ['day']);
searchMap.set(DateFormat.DATE_FORMAT_DEFAULT_MONTH_DAY, ['month', 'day']);
searchMap.set(DateFormat.DATE_FORMAT_DEFAULT_YEAR_MONTH_DAY, [
    'year',
    'month',
    'day',
]);
searchMap.set(DateFormat.DATE_FORMAT_DEFAULT_HOUR, ['hour']);
searchMap.set(DateFormat.DATE_FORMAT_DEFAULT_MINUTE, ['minute']);
searchMap.set(DateFormat.DATE_FORMAT_DEFAULT_SECOND, ['second']);
searchMap.set(DateFormat.DATE_FORMAT_DEFAULT_HOUR_MINUTE, ['hour', 'minute']);
searchMap.set(DateFormat.DATE_FORMAT_DEFAULT_HOUR_MINUTE_SECOND, [
    'hour',
    'minute',
    'second',
]);
searchMap.set(DateFormat.DATE_FORMAT_ZERO_PADDED_MONTH, ['month']);
searchMap.set(DateFormat.DATE_FORMAT_ZERO_PADDED_DAY, ['day']);
searchMap.set(DateFormat.DATE_FORMAT_HYPHEN_ZERO_PADDED_YEAR_MONTH_DAY, [
    'year',
    'month',
    'day',
]);
searchMap.set(DateFormat.DATE_FORMAT_LACOCO_YEAR, ['year']);
searchMap.set(DateFormat.DATE_FORMAT_CURVES_YEAR_MONTH_DAY, [
    'year',
    'month',
    'day',
]);
searchMap.set(DateFormat.DATE_FORMAT_CURVES_TIME, ['hour']);
function getDateFormatSuggestions(searchedTimeParts) {
    return __spreadArray([], __read(searchMap.entries())).filter(function (_a) {
        var _b = __read(_a, 2), _ = _b[0], tp = _b[1];
        return isEqual(tp, searchedTimeParts);
    })
        .map(function (_a) {
        var _b = __read(_a, 1), dp = _b[0];
        return dp;
    });
}
function FormAutomationDateFormatSection(_a) {
    var intl = _a.intl, initialDateFormat = _a.initialDateFormat, onCancel = _a.onCancel, onSubmit = _a.onSubmit;
    var _b = __read(useState(initialDateFormat), 2), dateFormat = _b[0], setDateFormat = _b[1];
    var _c = __read(useState({
        year: false,
        month: false,
        day: false,
        hour: false,
        minute: false,
        second: false,
    }), 2), timesState = _c[0], setTimesState = _c[1];
    var isDateFormatShown = Object.values(timesState).some(Boolean);
    function updateTime(e, timeVal, timeKey) {
        var _a;
        e.preventDefault();
        setTimesState(__assign(__assign({}, timesState), (_a = {}, _a[timeKey] = timeVal, _a)));
    }
    var chosenTimes = Object.keys(timesState).filter(function (key) { return timesState[key]; });
    var searchedDateFormats = getDateFormatSuggestions(chosenTimes);
    var isValid = dateFormat !== DateFormat.DATE_FORMAT_UNSPECIFIED;
    return (React.createElement("div", { className: "date-part-section-container", "data-testid": "FormAutomationDatePartSettingSection" },
        React.createElement("h4", { className: "title date-format-modal-title" }, intl.formatMessage({ id: "VmMFqS", defaultMessage: "Date Format Setting" })),
        React.createElement("div", { className: "subtitle subtitle-date-format" }, intl.formatMessage({ id: "MR4ufS", defaultMessage: "Select all the parts of the date you would like to include" })),
        React.createElement("div", { className: "date-parts-container" },
            React.createElement("div", { className: "times-container" },
                React.createElement("ul", { className: "times" }, Object.entries(timesState).map(function (_a) {
                    var _b = __read(_a, 2), timeKey = _b[0], timeVal = _b[1];
                    return (React.createElement("div", { className: "time-item", key: timeKey },
                        React.createElement(Checkbox, { "data-testid": "FormAutomationDateFormatSection-Checkbox", label: timeKey, color: "primary", checked: timeVal, size: "small", onClick: function (e) { return updateTime(e, !timeVal, timeKey); } })));
                }))),
            isDateFormatShown && (React.createElement("div", { className: "date-format-container" },
                React.createElement("div", { className: "date-format__title" }, intl.formatMessage({ id: "/lQahO", defaultMessage: "Select the date format which you would like to input into the client's form" })),
                searchedDateFormats.map(function (df) { return (React.createElement("div", { className: "date-format-radio", key: df },
                    React.createElement(InputRadioBox, { name: "date-format", value: df, checked: df === dateFormat, onChange: function () { return setDateFormat(df); }, text: dateFormatExampleMap[df] }))); })))),
        React.createElement("div", { className: "date-format-section-controls" },
            React.createElement(Button, { buttonType: buttonTypes.CANCEL, text: intl.formatMessage({ id: "47FYwb", defaultMessage: "Cancel" }), onClick: onCancel, className: "date-format-btn-cancel" }),
            React.createElement(Button, { buttonType: buttonTypes.SETTING, text: intl.formatMessage({ id: "qDYGja", defaultMessage: "Save Settings" }), onClick: function () { return onSubmit(dateFormat); }, className: "date-format-btn-confirm", disabled: !isValid }))));
}
export default injectIntl(FormAutomationDateFormatSection);
