import "core-js/modules/es.array.from";
import "core-js/modules/es.array.iterator";
import "core-js/modules/es.array.map";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.string.iterator";
import "core-js/modules/web.dom-collections.iterator";
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors } from '@src/colors';
import { centeredFlex } from '@src/lib/mixins';
import { SelectComponent } from '@src/lib/HtmlElements';
var propTypes = {
  step: PropTypes.object,
  handleStepDateChange: PropTypes.func
};
var FlexboxContainer = styled.div.withConfig({
  displayName: "FlexboxContainer",
  componentId: "sc-1svaagh-0"
})(["", ";"], centeredFlex);
var TimeSelectionContainer = styled(FlexboxContainer).withConfig({
  displayName: "TimeSelectionContainer",
  componentId: "sc-1svaagh-1"
})(["justify-content:space-between;margin:0 0 16px 0;width:100%;font-size:14px;input{width:118px;height:38px;padding:12px 16px;text-align:right;font-size:14px;border-radius:3px;border:1px solid ", ";}> div{width:80px;margin:0;}"], function (props) {
  return props.daysAfter <= 0 || props.daysAfter === '' ? "".concat(colors.border.error) : "".concat(colors.border.default);
});

var StepTimeSelector = function StepTimeSelector(_ref) {
  var step = _ref.step,
      handleStepDateChange = _ref.handleStepDateChange;
  return React.createElement(TimeSelectionContainer, {
    daysAfter: step.daysAfter
  }, React.createElement("input", {
    value: step.daysAfter,
    name: "daysAfter",
    onChange: handleStepDateChange
  }), "\u65E5\u5F8C", React.createElement(SelectComponent, {
    color: colors.text_pale,
    margin: "0 0 16px 0"
  }, React.createElement("select", {
    value: step.deliveryTime.hour,
    name: "hour",
    onChange: handleStepDateChange
  }, React.createElement("option", {
    value: "",
    disabled: true,
    hidden: true
  }, "Hr"), Array.from(Array(24).keys()).map(function (num, i) {
    return React.createElement("option", {
      key: "".concat(i, "-step-hour"),
      value: num
    }, num);
  })), React.createElement("span", {
    className: "adicon_down"
  })), ":", React.createElement(SelectComponent, {
    color: colors.text_pale,
    margin: "0 0 16px 0"
  }, React.createElement("select", {
    value: step.deliveryTime.minute,
    name: "minute",
    onChange: handleStepDateChange
  }, React.createElement("option", {
    value: "",
    disabled: true,
    hidden: true
  }, "Min"), Array.from(Array(60).keys()).map(function (num, i) {
    return React.createElement("option", {
      key: "".concat(i, "-step-minute"),
      value: num
    }, num);
  })), React.createElement("span", {
    className: "adicon_down"
  })));
};

StepTimeSelector.propTypes = propTypes;
export default StepTimeSelector;