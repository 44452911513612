import { object, string, number } from 'yup';
import { tabTypes, messageTypes } from '@src/lib/app-constants';
import { addMaxLength } from '@src/lib/helpers';
import postbackActionSchema from './postbackActionSchema';
/**
 * This schema for QuickTriggerModal, it will return errors object to
 * use for validate on this modal
 * this errors object will contain label, text and postback_action
 *
 * @param imageMapAreaId - id of image map area
 * @param imageMapAreaArr - all area of image map
 * @param intl - to do i18n
 * @param platform - to set max length for input text field
 * @param context - will contain effect value pass to postback_action schema
 */
export default (function (imageMapAreaId, imageMapAreaArr, intl, platform, context, activeTab) {
    var isWithUserTag = activeTab === tabTypes.AREA_SETTING_WITH_USER_TAG;
    return object({
        label: !isWithUserTag &&
            addMaxLength(string()
                .nullable()
                .notOneOf(imageMapAreaArr
                .filter(function (imageMapArea) { return imageMapArea.id !== imageMapAreaId; })
                .map(function (imageMapArea) { return imageMapArea.label && imageMapArea.label; }), 'このラベルは、他のテンプレートで使用されています。'), intl, messageTypes.IMAGE_MAP + ".area.label", platform),
        text: [tabTypes.BLOCK, tabTypes.SCENARIO].includes(context.effect)
            ? string()
                .required('テキストメッセージを入力してください')
                .notOneOf(imageMapAreaArr
                .filter(function (imageMapArea) { return imageMapArea.id !== imageMapAreaId; })
                .map(function (imageMapArea) { return imageMapArea.text && imageMapArea.text; }), 'このテキストメッセージが既に使用されています。')
                .nullable()
            : string().nullable(),
        attribute_question_id: isWithUserTag
            ? number().required()
            : number().nullable(),
        attribute_answer_id: isWithUserTag
            ? number().required()
            : number().nullable(),
        postback_action: postbackActionSchema(intl, platform, context, {}, activeTab),
    });
});
