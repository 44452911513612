var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import merge from 'lodash/merge';
import { colors } from '@src/colors';
import { TAB_0_COLOR } from '@src/lib/app-constants';
import NextPostback from '@src/components/molecules/NextPostback';
import { createInitialUrlValue, getUrlType, getIcon, } from '@src/lib/helpers/createInitialUrlValue';
import { injectIntl, } from 'react-intl';
import { richMenuMessages } from '@src/i18n/translations';
import { Icon } from '@zeals-co-ltd/washi-components';
import { useParams } from 'react-router-dom';
import flatten from 'lodash/flatten';
import reduce from 'lodash/reduce';
var TemplateInfoWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 360px;\n  max-height: 300px;\n  margin-right: 40px;\n  border-radius: 10px;\n  background: #ffffff;\n  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1);\n  cursor: pointer;\n  overflow-y: auto;\n  > div:not(:last-child) {\n    border-bottom: solid 1px ", ";\n  }\n  .area-info:first-of-type {\n    border-top-left-radius: 10px;\n    border-top-right-radius: 10px;\n  }\n  .area-info:last-of-type {\n    border-bottom-left-radius: 10px;\n    border-bottom-right-radius: 10px;\n  }\n"], ["\n  width: 360px;\n  max-height: 300px;\n  margin-right: 40px;\n  border-radius: 10px;\n  background: #ffffff;\n  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1);\n  cursor: pointer;\n  overflow-y: auto;\n  > div:not(:last-child) {\n    border-bottom: solid 1px ", ";\n  }\n  .area-info:first-of-type {\n    border-top-left-radius: 10px;\n    border-top-right-radius: 10px;\n  }\n  .area-info:last-of-type {\n    border-bottom-left-radius: 10px;\n    border-bottom-right-radius: 10px;\n  }\n"])), colors.border.pale);
var AreaInfoWrapper = styled.div.attrs({ className: 'area-info' })(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  min-height: 48px;\n  border: 2px solid white;\n  border-color: ", ";\n  ", ";\n  .settings-required {\n    display: flex;\n    position: relative;\n    width: 100%;\n    justify-content: space-between;\n    margin-top: 16px;\n    font-size: 10px;\n    .area-name {\n      margin-left: 8px;\n      color: #babecd;\n    }\n    .setting-text {\n      text-transform: uppercase;\n    }\n    .icon-class {\n      width: 16px;\n      height: 16px;\n      margin-right: 16px;\n    }\n  }\n  .title {\n    display: flex;\n    position: relative;\n    color: ", ";\n    font-size: 10px;\n    padding: 4px 12px;\n    .area-name {\n      padding-left: 16px;\n      color: ", ";\n    }\n    .tag-icon {\n      width: 12px;\n      height: 12px;\n      margin-top: auto;\n      margin-right: 4px;\n    }\n    .adicon_attention {\n      &::before {\n        display: none;\n      }\n      &::after {\n        position: absolute;\n        right: 10px;\n        font-size: 1rem;\n        color: ", ";\n      }\n    }\n  }\n  .detail {\n    .adicon_nextblock,\n    .adicon_phone,\n    .adicon_mail,\n    .adicon_tag,\n    .adicon_big_link {\n      width: 100%;\n      font-size: 12px;\n      padding-left: 12px;\n      text-align: left;\n      text-overflow: ellipsis;\n      white-space: nowrap;\n      overflow: hidden;\n      &:before {\n        font-size: 16px;\n      }\n      > span {\n        font-family: Noto Sans JP;\n        padding-bottom: 5px;\n      }\n    }\n    .adicon_nextblock {\n      margin-top: 0px;\n    }\n  }\n"], ["\n  min-height: 48px;\n  border: 2px solid white;\n  border-color: ", ";\n  ",
    ";\n  .settings-required {\n    display: flex;\n    position: relative;\n    width: 100%;\n    justify-content: space-between;\n    margin-top: 16px;\n    font-size: 10px;\n    .area-name {\n      margin-left: 8px;\n      color: #babecd;\n    }\n    .setting-text {\n      text-transform: uppercase;\n    }\n    .icon-class {\n      width: 16px;\n      height: 16px;\n      margin-right: 16px;\n    }\n  }\n  .title {\n    display: flex;\n    position: relative;\n    color: ", ";\n    font-size: 10px;\n    padding: 4px 12px;\n    .area-name {\n      padding-left: 16px;\n      color: ", ";\n    }\n    .tag-icon {\n      width: 12px;\n      height: 12px;\n      margin-top: auto;\n      margin-right: 4px;\n    }\n    .adicon_attention {\n      &::before {\n        display: none;\n      }\n      &::after {\n        position: absolute;\n        right: 10px;\n        font-size: 1rem;\n        color: ", ";\n      }\n    }\n  }\n  .detail {\n    .adicon_nextblock,\n    .adicon_phone,\n    .adicon_mail,\n    .adicon_tag,\n    .adicon_big_link {\n      width: 100%;\n      font-size: 12px;\n      padding-left: 12px;\n      text-align: left;\n      text-overflow: ellipsis;\n      white-space: nowrap;\n      overflow: hidden;\n      &:before {\n        font-size: 16px;\n      }\n      > span {\n        font-family: Noto Sans JP;\n        padding-bottom: 5px;\n      }\n    }\n    .adicon_nextblock {\n      margin-top: 0px;\n    }\n  }\n"])), function (props) { return (props.isHover ? '#1EC18D;' : 'white'); }, function (props) {
    return props.isHover ? 'border-bottom: 2px solid #1EC18D !important;' : '';
}, colors.placeholder, colors.text_pale, colors.error);
var TemplateInfo = function (props) {
    var _a = props.noAction, noAction = _a === void 0 ? false : _a, areas = props.areas, templateAreas = props.templateAreas, blocks = props.blocks, scenarios = props.scenarios, hoverAreaId = props.hoverAreaId, setHoverAreaId = props.setHoverAreaId, setEditModalOpened = props.setEditModalOpened, intl = props.intl, restProps = __rest(props, ["noAction", "areas", "templateAreas", "blocks", "scenarios", "hoverAreaId", "setHoverAreaId", "setEditModalOpened", "intl"]);
    var _b = __read(useState(null), 2), templateElementRect = _b[0], setTemplateInfoElement = _b[1];
    var dispatch = useDispatch();
    var URLParams = useParams();
    var mergedAreas = merge(templateAreas, areas);
    var tabCount = mergedAreas.filter(function (_a) {
        var color = _a.color;
        return color > TAB_0_COLOR;
    })
        .length;
    var tabAreaValid = mergedAreas.filter(function (_a) {
        var color = _a.color, url = _a.url, postback_action = _a.postback_action;
        return color > TAB_0_COLOR && postback_action === null && url === null;
    }).length <= 1;
    // This method will calculate templateInfoElement position and compare with
    // templateElementRect to set scrollTop
    var focusTemplateInfo = function (areaInfoElement) {
        if (!areaInfoElement || !templateElementRect)
            return;
        var areaInfoElementRect = areaInfoElement.getBoundingClientRect();
        var templateElementRectRect = templateElementRect.getBoundingClientRect();
        var scrollTop = templateElementRect.scrollTop;
        if (areaInfoElementRect.top < templateElementRectRect.top)
            templateElementRect.scrollTop =
                scrollTop - (templateElementRectRect.top - areaInfoElementRect.top);
        else if (areaInfoElementRect.bottom > templateElementRectRect.bottom)
            templateElementRect.scrollTop =
                scrollTop +
                    (areaInfoElementRect.bottom - templateElementRectRect.bottom) +
                    1;
    };
    var userTags = useSelector(function (state) {
        return flatten(state.adScenario.present.userTagGroups.map(function (_a) {
            var user_tags = _a.user_tags;
            return user_tags;
        }));
    }).filter(Boolean);
    var userTagsObj = reduce(userTags, function (tagsObj, userTag) {
        var _a;
        return (__assign(__assign({}, tagsObj), (_a = {}, _a[userTag.id] = userTag.label, _a)));
    }, {});
    var RenderAreaInfo = function (area, index) {
        var color = area.color, url = area.url, postback_action = area.postback_action, label = area.label, attribute_answer_id = area.attribute_answer_id, attribute_question_id = area.attribute_question_id;
        var validArea = url ||
            attribute_answer_id ||
            (postback_action &&
                (postback_action.block_id ||
                    postback_action.scenario_id ||
                    postback_action.url));
        var titleText = '';
        if (color <= TAB_0_COLOR) {
            titleText = area.title
                ? intl.formatMessage(area.title)
                : intl.formatMessage({ id: "8o8Qs2", defaultMessage: "Area" }) + " " + (index - tabCount + 1);
        }
        else {
            titleText = "" + intl.formatMessage({ id: "18HJlm", defaultMessage: "Tag" }) + (index + 1);
        }
        var areaIndex = index >= restProps.numberOfTabs && restProps.numberOfTabs > 1
            ? index - restProps.numberOfTabs + 1
            : index + 1;
        var titleDefaultRichMenu = area.isTabArea
            ? intl.formatMessage(richMenuMessages["tab_" + (index + 1)])
            : intl.formatMessage({ id: "8o8Qs2", defaultMessage: "Area" }) + " " + areaIndex;
        var modalOpenable = !noAction && (!restProps.isDefaultRichMenu || !area.isTabArea);
        var noSettingText = area.isTabArea
            ? intl.formatMessage({ id: "csma8H", defaultMessage: "Setting not required" })
            : intl.formatMessage({ id: "DbIrtk", defaultMessage: "No Settings" });
        var areaName = '';
        if (noAction) {
            areaName = 'No Action';
        }
        else {
            if (restProps.isDefaultRichMenu || area.rich_menu_id) {
                areaName = validArea ? label : noSettingText;
            }
            else if (validArea) {
                if (label) {
                    areaName = label;
                }
                else if (attribute_answer_id) {
                    areaName = userTagsObj[attribute_answer_id];
                }
            }
        }
        return (React.createElement(AreaInfoWrapper, { key: "template-info-" + area.id, isHover: hoverAreaId === area.id, onMouseOver: function () { return setHoverAreaId(area.id); }, ref: function (ref) { return hoverAreaId === area.id && focusTemplateInfo(ref); }, onClick: function () { return modalOpenable && setEditModalOpened(true); } },
            !(restProps.isDefaultRichMenu || area.rich_menu_id) &&
                !validArea &&
                !noAction && (React.createElement("div", { className: "settings-required" },
                React.createElement("span", { className: "area-name" }, titleText),
                React.createElement("span", { className: "setting-text" }, intl.formatMessage({ id: "3pQbkG", defaultMessage: "setting required" })),
                React.createElement(Icon, { name: "adicon_alert", className: "icon-class", color: "error" }))),
            React.createElement("div", { className: "title" },
                restProps.isDefaultRichMenu || area.rich_menu_id ? (React.createElement(Fragment, null,
                    React.createElement("span", null, restProps.isDefaultRichMenu ? titleDefaultRichMenu : titleText),
                    React.createElement("div", { className: "area-name" }, areaName))) : (React.createElement(Fragment, null,
                    (validArea || noAction) && React.createElement("span", null, titleText),
                    React.createElement("div", { className: "area-name" },
                        attribute_answer_id && (React.createElement(Icon, { name: "adicon_tag", color: "disabled", className: "tag-icon" })),
                        areaName))),
                !noAction &&
                    !validArea &&
                    (!tabAreaValid || color <= TAB_0_COLOR) && (React.createElement("div", { className: "adicon_attention" }))),
            React.createElement("div", { className: "detail" }, (postback_action || {}).url ? (React.createElement("div", { className: getIcon(postback_action.url, postback_action.block_id) },
                React.createElement("span", { "data-testid": "TemplateInfo_AreaDetail" }, createInitialUrlValue(getUrlType(postback_action.url), postback_action)))) : (React.createElement(NextPostback, { connectedObject: area, scenarios: scenarios, blocks: Object.values(blocks) })))));
    };
    return (React.createElement(TemplateInfoWrapper, __assign({}, restProps, { ref: setTemplateInfoElement }), mergedAreas.map(RenderAreaInfo)));
};
export default injectIntl(TemplateInfo);
var templateObject_1, templateObject_2;
