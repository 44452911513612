import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment';
import { colors } from '@src/colors';
import TextMessage from '@src/components/messages/TextMessage';
import GenericMessage from '@src/components/messages/generic_message/GenericMessage';
import MessageBottom from '@src/components/messages/MessageBottom';
import { MessageStruct, TextMessageStruct, GenericMessageStruct, MediaMessageStruct } from '@src/lib/definitions';
import { MESSAGE_TYPES, determineMsgType } from '@src/lib/talkUtil';
import Carousel from '@src/fanp/enterprise/talk_customize/components/line_flex_message/chat/Carousel';
import FlexMessageContainer from '@src/fanp/enterprise/talk_customize/components/line_flex_message/FlexMessageContainer';
import MediaMessage from '@src/components/molecules/MediaMessage';
import PlaceholderMessage from '@src/components/molecules/PlaceholderMessage';
var MessageContainer = styled.div.withConfig({
  displayName: "MessageContainer",
  componentId: "sc-93io0o-0"
})(["display:flex;margin:8px 0;justify-content:", ";> div{display:flex;flex-direction:column;align-items:flex-end;max-width:70%;p{align-self:flex-start;font-size:12px;}}"], function (props) {
  return props.isClient ? 'flex-end' : 'flex-start';
});
var EventLogContainer = styled.li.withConfig({
  displayName: "EventLogContainer",
  componentId: "sc-93io0o-1"
})(["display:flex;flex-direction:column;"]);
var DateText = styled.p.withConfig({
  displayName: "DateText",
  componentId: "sc-93io0o-2"
})(["color:", ";font-size:0.7rem;text-align:", ";margin:10px 0px 10px 0px;"], colors.text_pale, function (props) {
  return props.isClient ? 'right' : 'left';
});

var DateCreated = function DateCreated(_ref) {
  var eventLog = _ref.eventLog;
  var isClient = !!eventLog.message;
  var dateStr = moment(eventLog.created_at).format('YYYY年MM月DD日 HH:mm');
  return React.createElement(DateText, {
    isClient: isClient
  }, "".concat(dateStr));
};

DateCreated.propTypes = {
  eventLog: PropTypes.object
};

var EventLogToMessage = function EventLogToMessage(_ref2) {
  var eventLog = _ref2.eventLog,
      chatbotId = _ref2.chatbotId,
      isClient = _ref2.isClient,
      limits = _ref2.limits,
      scenarios = _ref2.scenarios,
      layers = _ref2.layers,
      blocks = _ref2.blocks;
  var message = eventLog.end_user_message ? {
    text: eventLog.end_user_message
  } : eventLog.message;
  var messageStruct = MessageStruct({
    message: message,
    limits: limits,
    chatbotId: chatbotId,
    scenarioId: message && message.scenario_id,
    readOnly: true
  });
  var Bottom = React.createElement(MessageBottom, {
    messageStruct: messageStruct,
    eventLog: eventLog,
    scenarios: scenarios,
    blocks: blocks
  });

  switch (determineMsgType({
    eventLog: eventLog
  })) {
    case MESSAGE_TYPES.END_USER:
      {
        return React.createElement(TextMessage, {
          textMessageStruct: TextMessageStruct(messageStruct),
          isClient: isClient,
          scenarios: scenarios,
          layers: layers,
          blocks: blocks
        });
      }

    case MESSAGE_TYPES.CLIENT:
      {
        return React.createElement(TextMessage, {
          textMessageStruct: TextMessageStruct(messageStruct),
          isClient: isClient,
          scenarios: scenarios,
          layers: layers,
          blocks: blocks
        }, Bottom);
      }

    case MESSAGE_TYPES.GENERIC:
      {
        var genericMessageStruct = GenericMessageStruct({
          messageStruct: messageStruct,
          scenarios: scenarios,
          layers: layers,
          blocks: blocks
        });
        return React.createElement(GenericMessage, {
          genericMessageStruct: genericMessageStruct,
          isClient: isClient
        }, Bottom);
      }

    case MESSAGE_TYPES.LIST:
      {
        return React.createElement(PlaceholderMessage, {
          message: message
        }, Bottom);
      }

    case MESSAGE_TYPES.MEDIA:
      {
        return React.createElement(MediaMessage, {
          mediaMessageStruct: MediaMessageStruct(messageStruct),
          isClient: isClient
        }, Bottom);
      }

    case MESSAGE_TYPES.LINE_FLEX_MESSAGE:
      {
        return React.createElement(FlexMessageContainer, null, React.createElement(Carousel, {
          content: messageStruct.message.line_flex_message.contents,
          readOnly: true
        }));
      }

    default:
      return null;
  }
};

var EventLog = function EventLog(props) {
  var eventLog = props.eventLog,
      chatbotId = props.chatbotId,
      limits = props.limits,
      scenarios = props.scenarios,
      layers = props.layers,
      blocks = props.blocks;
  var isClient = !eventLog.end_user_message;
  return React.createElement(EventLogContainer, null, React.createElement(DateCreated, {
    eventLog: eventLog
  }), React.createElement(MessageContainer, {
    isClient: isClient
  }, React.createElement("div", null, React.createElement(EventLogToMessage, {
    eventLog: eventLog,
    chatbotId: chatbotId,
    isClient: isClient,
    limits: limits,
    scenarios: scenarios,
    layers: layers,
    blocks: blocks
  }), React.createElement("p", null, eventLog.opened_date && isClient ? "\u958B\u5C01: ".concat(moment(eventLog.opened_date).format('YY.MM.DD HH:mm')) : null))));
};

EventLog.propTypes = {
  eventLog: PropTypes.object.isRequired,
  chatbotId: PropTypes.number.isRequired,
  limits: PropTypes.object.isRequired,
  scenarios: PropTypes.object,
  layers: PropTypes.object,
  blocks: PropTypes.object
};
export default EventLog;