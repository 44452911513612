var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import checkedImg from '@src/assets/images/icon_selectbox_checked.png';
import emptyCheckedImg from '@src/assets/images/icon_selectbox_empty.png';
import { colors } from '@src/colors';
import { transitions } from '@src/lib/animations';
export var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  font-size: 12px;\n  display: inline-block;\n  align-self: center;\n  margin-right: ", ";\n\n  input {\n    border: none !important;\n  }\n"], ["\n  font-size: 12px;\n  display: inline-block;\n  align-self: center;\n  margin-right: ", ";\n\n  input {\n    border: none !important;\n  }\n"])), function (props) { return props.marginRight || 'none'; });
export var LabelDiv = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-size: 12px;\n  background-image: ", ";\n  background-repeat: no-repeat;\n  background-position: 0 50%;\n  background-size: 1.5rem;\n  display: inline-block;\n  width: ", ";\n  height: ", ";\n  padding: ", ";\n  user-select: none;\n  transition: ", ";\n  &:hover {\n    color: ", ";\n    cursor: pointer;\n  }\n"], ["\n  font-size: 12px;\n  background-image: ", ";\n  background-repeat: no-repeat;\n  background-position: 0 50%;\n  background-size: 1.5rem;\n  display: inline-block;\n  width: ", ";\n  height: ", ";\n  padding: ", ";\n  user-select: none;\n  transition: ", ";\n  &:hover {\n    color: ", ";\n    cursor: pointer;\n  }\n"])), function (props) { return "url(" + props.labelImage + ")"; }, function (props) { return (props.text ? 'auto' : '18px'); }, function (props) { return (props.text ? 'auto' : '18px'); }, function (props) { return (props.text ? '5px 0 4px 22px' : 0); }, transitions.normal, colors.placeholder);
var RadioCheckbox = function (_a) {
    var checked = _a.checked, changeCheckedState = _a.changeCheckedState, text = _a.text, id = _a.id, marginRight = _a.marginRight;
    return (React.createElement(Container, { className: "radio-checkbox", marginRight: marginRight },
        React.createElement("input", { type: "checkbox", defaultChecked: checked }),
        React.createElement(LabelDiv, { labelImage: checked ? checkedImg : emptyCheckedImg, onClick: changeCheckedState, text: text, id: id }, text)));
};
export { RadioCheckbox as default };
var templateObject_1, templateObject_2;
