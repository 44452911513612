import { object, string, number, mixed } from 'yup';
import { messageTypes, tabTypes, googleStoreUrl } from '@src/lib/app-constants';
import { addMaxLength } from '@src/lib/helpers';
/**
 * This schema for imageMapVideo, it will return errors object to
 * use for validate on this modal
 * this errors object will contain field of imageMapVideo
 *
 * @param intl - to do i18n
 * @param platform - to set max length for input text field
 */
export default (function (intl, platform) {
    return object({
        id: number().nullable(),
        activeVideoTab: string().nullable(),
        activePreviewTab: string().nullable(),
        label: addMaxLength(string().required('ラベルは必須です。'), intl, messageTypes.IMAGE_MAP + ".video.label", platform),
        link_uri: addMaxLength(string()
            .url('URLを修正してください。')
            .required('こちらのURLは必須です。'), intl, 'common.url', platform),
        original_content_url: addMaxLength(string().when('activeVideoTab', {
            is: tabTypes.URL,
            then: string()
                .required('こちらのURLは必須です。')
                .matches(new RegExp("^(" + googleStoreUrl[process.env.NODE_ENV] + "videos)"), {
                message: '外部URLは入力できません',
                excludeEmptyString: true,
            }),
        }), intl, 'common.url', platform),
        originalContentFile: mixed().when(['id', 'activeVideoTab'], {
            is: function (id, activeVideoTab) { return !id && activeVideoTab === tabTypes.FILE; },
            then: mixed().required('ファイルは必須です'),
        }),
        preview_image_url: addMaxLength(string().when('activePreviewTab', {
            is: tabTypes.URL,
            then: string()
                .required('こちらのURLは必須です。')
                .matches(new RegExp("^(" + googleStoreUrl[process.env.NODE_ENV] + "images)"), {
                message: '外部URLは入力できません',
                excludeEmptyString: true,
            }),
        }), intl, 'common.url', platform),
        previewImageFile: mixed().when(['id', 'activePreviewTab'], {
            is: function (id, activePreviewTab) { return !id && activePreviewTab === tabTypes.FILE; },
            then: mixed().required('ファイルは必須です'),
        }),
    });
});
