import articles from './articles';
import campaignContents from './campaignContents';
import chatbots from './chatbots';
import chatlinks from './chatlinks';
import customFormAnswers from './customFormAnswers';
import customFormConversionTasks from './customFormConversionTasks';
import customForms from './customForms';
import customFormQuestionGroups from './customFormQuestionGroups';
import customFormQuestions from './customFormQuestions';
import customFormRichMenuItems from './customFormRichMenuItems';
import endUsers from './endUsers';
import eventLogs from './eventLogs';
import headlessConfigs from './headlessConfigs';
import headlessErrorChecks from './headlessErrorChecks';
import headlessErrorHandlers from './headlessErrorHandlers';
import headlessPages from './headlessPages';
import headlessSteps from './headlessSteps';
import invitation from './invitation';
import lineFlexMessages from './line_flex_messages';
import login from './login';
import flexMessageTemplates from './flexMessageTemplates';
import members from './members';
import messages from './messages';
import persistentMenuItems from './persistentMenuItems';
import plugins from './plugins';
import retentionCampaigns from './retentionCampaigns';
import retentionContents from './retentionContents';
import retentionTargets from './retentionTargets';
import scenarioTemplates from './scenarioTemplates';
import smartReplaces from './smartReplaces';
import tags from './tags';
import uploadedFiles from './uploadedFiles';
import validations from './validations';
import validationElements from './validationElements';
import customFormHeadlessBrowserLogs from './customFormHeadlessBrowserLogs';
import messageFilter from './messageFilter';
import chatbot from './chatbot';
export default {
  articles: articles,
  campaignContents: campaignContents,
  chatbots: chatbots,
  chatlinks: chatlinks,
  customFormAnswers: customFormAnswers,
  customFormConversionTasks: customFormConversionTasks,
  customForms: customForms,
  customFormQuestionGroups: customFormQuestionGroups,
  customFormQuestions: customFormQuestions,
  customFormRichMenuItems: customFormRichMenuItems,
  endUsers: endUsers,
  eventLogs: eventLogs,
  plugins: plugins,
  headlessConfigs: headlessConfigs,
  headlessErrorChecks: headlessErrorChecks,
  headlessErrorHandlers: headlessErrorHandlers,
  headlessPages: headlessPages,
  headlessSteps: headlessSteps,
  invitation: invitation,
  lineFlexMessages: lineFlexMessages,
  login: login,
  flexMessageTemplates: flexMessageTemplates,
  members: members,
  messages: messages,
  persistentMenuItems: persistentMenuItems,
  retentionCampaigns: retentionCampaigns,
  retentionContents: retentionContents,
  retentionTargets: retentionTargets,
  smartReplaces: smartReplaces,
  tags: tags,
  uploadedFiles: uploadedFiles,
  scenarioTemplates: scenarioTemplates,
  validations: validations,
  validationElements: validationElements,
  customFormHeadlessBrowserLogs: customFormHeadlessBrowserLogs,
  messageFilter: messageFilter,
  chatbot: chatbot
};