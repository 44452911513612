import "core-js/modules/es.string.small";
import React from 'react';
import styled from 'styled-components';
import { centeredFlex, hoverCss } from '@src/lib/mixins';
import { colors, shadows } from '@src/colors';
import { CloseIcon } from '@src/components/atoms';
var PreviewContainer = styled.div.withConfig({
  displayName: "PreviewContainer",
  componentId: "sc-2sudo9-0"
})(["background:", ";border-radius:0 8px 8px 0;box-shadow:", ";display:flex;"], colors.area_mask, shadows.small.mid);
var PreviewModal = styled.div.withConfig({
  displayName: "PreviewModal",
  componentId: "sc-2sudo9-1"
})(["background:", ";margin:auto;padding:0;", ";flex-direction:column;position:relative;img{width:300px;border-radius:8px;}.fanp-modal-close{position:absolute;right:-25px;top:-25px;color:", ";", ";}.modal-powered-by{width:100%;background:", ";border-radius:0 0 8px 8px;text-align:center;color:", ";font-size:8px;}.powered-top-radius{border-radius:8px 8px 0 0;}"], colors.background.main_menu, centeredFlex, colors.chat_link.close.image, hoverCss, function (props) {
  return props.poweredColor || 'unset';
}, colors.white);

var ImagePreview = function ImagePreview(_ref) {
  var chatlink = _ref.chatlink;
  return React.createElement(PreviewContainer, null, React.createElement(PreviewModal, {
    poweredColor: chatlink.powered_color_code
  }, React.createElement(CloseIcon, null), React.createElement("img", {
    src: chatlink.image_url,
    className: chatlink.powered == 1 && 'powered-top-radius'
  }), chatlink.powered == 1 && React.createElement("div", {
    className: "modal-powered-by"
  }, "Powered by Zeals")));
};

export default ImagePreview;