var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import { colors, shadows } from '@src/colors';
import { transitions } from '@src/lib/animations';
import { injectIntl } from 'react-intl';
var AddItemButton = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  padding: 8px 12px 8px 8px;\n  text-align: center;\n  border-radius: 50px;\n  margin-left: 4px;\n  color: ", ";\n  transition: ", ";\n  .button-wrapper {\n    display: block;\n  }\n  .adicon_plus01,\n  .adicon_plus03,\n  .adicon_minus01,\n  .adicon_minus03 {\n    font-size: 20px;\n    position: relative;\n    margin-right: 4px;\n  }\n  .selector-text {\n    font-size: 14px;\n    font-weight: bold;\n  }\n  &:hover {\n    background: ", ";\n  }\n"], ["\n  display: flex;\n  align-items: center;\n  padding: 8px 12px 8px 8px;\n  text-align: center;\n  border-radius: 50px;\n  margin-left: 4px;\n  color: ", ";\n  transition: ", ";\n  .button-wrapper {\n    display: block;\n  }\n  .adicon_plus01,\n  .adicon_plus03,\n  .adicon_minus01,\n  .adicon_minus03 {\n    font-size: 20px;\n    position: relative;\n    margin-right: 4px;\n  }\n  .selector-text {\n    font-size: 14px;\n    font-weight: bold;\n  }\n  &:hover {\n    background: ", ";\n  }\n"])), function (props) { return props.color; }, transitions.normal, colors.background.light_purple);
var MenuListContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: max-content;\n  > li {\n    background-color: ", ";\n    border: 1px solid ", ";\n    box-shadow: ", ";\n    border-radius: 5px;\n    padding: 0 12px 0 16px;\n    display: flex;\n    margin-bottom: 8px;\n    transition: ", ";\n    &:hover {\n      box-shadow: ", ";\n    }\n    .tag-label {\n      line-height: 48px;\n      display: flex;\n      flex-direction: row;\n      align-items: center;\n    }\n    .adicon_tag {\n      font-size: 16px;\n    }\n    .tag-name {\n      font-size: 12px;\n    }\n    .buttons {\n      display: flex;\n      margin-left: auto;\n      padding: 4px 0;\n      > div {\n        &:hover {\n          cursor: pointer;\n        }\n      }\n    }\n  }\n"], ["\n  width: max-content;\n  > li {\n    background-color: ", ";\n    border: 1px solid ", ";\n    box-shadow: ", ";\n    border-radius: 5px;\n    padding: 0 12px 0 16px;\n    display: flex;\n    margin-bottom: 8px;\n    transition: ", ";\n    &:hover {\n      box-shadow: ", ";\n    }\n    .tag-label {\n      line-height: 48px;\n      display: flex;\n      flex-direction: row;\n      align-items: center;\n    }\n    .adicon_tag {\n      font-size: 16px;\n    }\n    .tag-name {\n      font-size: 12px;\n    }\n    .buttons {\n      display: flex;\n      margin-left: auto;\n      padding: 4px 0;\n      > div {\n        &:hover {\n          cursor: pointer;\n        }\n      }\n    }\n  }\n"])), colors.white, colors.border.default, shadows.small.light, transitions.normal, shadows.medium.mid);
var MenuList = function (_a) {
    var chatbotId = _a.chatbotId, filterId = _a.filterId, quickFilters = _a.quickFilters, addTagToFilter = _a.addTagToFilter, targetAttributeAnswers = _a.targetAttributeAnswers, fetchUpdateQuickFilter = _a.fetchUpdateQuickFilter, searchTerm = _a.searchTerm, intl = _a.intl;
    var searchAttributeAnswersData = function () {
        return targetAttributeAnswers.filter(function (targetAttributeAnswer) {
            return (targetAttributeAnswer.label &&
                targetAttributeAnswer.label.includes(searchTerm));
        });
    };
    var getSelectedQuickFilter = function (targetAttributeAnswer) {
        return quickFilters.find(function (quickFilter) {
            return quickFilter.attribute_answer_id === targetAttributeAnswer.id;
        });
    };
    return (React.createElement(MenuListContainer, { "data-testid": "attributeAnswer_items_container" }, targetAttributeAnswers &&
        searchAttributeAnswersData().map(function (targetAttributeAnswer) {
            var selectedTag = getSelectedQuickFilter(targetAttributeAnswer);
            return (React.createElement("li", { "data-testid": "attributeAnswer_item", key: targetAttributeAnswer.id },
                React.createElement("span", { className: "tag-label" },
                    React.createElement("span", { className: "adicon_tag" }),
                    React.createElement("span", { "data-testid": "attributeAnswer-name", className: "tag-name" }, targetAttributeAnswer.label || 'labelなし')),
                React.createElement("div", { className: "buttons" },
                    React.createElement(AddItemButton, { "data-testid": "include-button", color: colors.accent_color, selected: selectedTag && !selectedTag.not, onClick: function () {
                            if (selectedTag) {
                                if (selectedTag.not === true) {
                                    fetchUpdateQuickFilter({
                                        attributeAnswerId: targetAttributeAnswer.id,
                                        quickFilterId: selectedTag.id,
                                        not: !selectedTag.not,
                                        filterId: filterId,
                                        chatbotId: chatbotId,
                                    });
                                }
                            }
                            else {
                                addTagToFilter(filterId, targetAttributeAnswer, false, chatbotId);
                            }
                        } },
                        selectedTag && !selectedTag.not ? (React.createElement("span", { className: "adicon_plus03" })) : (React.createElement("span", { className: "adicon_plus01" })),
                        React.createElement("span", { className: "selector-text" }, intl.formatMessage({ id: "SJMcZ6", defaultMessage: "Include" }))),
                    React.createElement(AddItemButton, { "data-testid": "exclude-button", color: colors.error, selected: selectedTag && selectedTag.not, onClick: function () {
                            if (selectedTag) {
                                if (selectedTag.not === false) {
                                    fetchUpdateQuickFilter({
                                        attributeAnswerId: targetAttributeAnswer.id,
                                        quickFilterId: selectedTag.id,
                                        not: !selectedTag.not,
                                        filterId: filterId,
                                        chatbotId: chatbotId,
                                    });
                                }
                            }
                            else {
                                addTagToFilter(filterId, targetAttributeAnswer, true, chatbotId);
                            }
                        } },
                        selectedTag && selectedTag.not ? (React.createElement("span", { className: "adicon_minus03" })) : (React.createElement("span", { className: "adicon_minus01" })),
                        React.createElement("span", { className: "selector-text" }, intl.formatMessage({ id: "+lb7bv", defaultMessage: "Exclude" }))))));
        })));
};
export default injectIntl(MenuList);
var templateObject_1, templateObject_2;
