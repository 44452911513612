var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import { colors } from '@src/colors';
var CardContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: 40px;\n  text-align: left;\n  border-radius: 16px;\n  background-color: ", ";\n\n  .card__pretitle {\n    font-size: 12px;\n    line-height: 18px;\n    text-transform: uppercase;\n    color: ", ";\n  }\n\n  .card__content {\n    color: ", ";\n    font-size: 16px;\n    line-height: 24px;\n    margin-top: 8px;\n    * {\n      vertical-align: top;\n    }\n    a {\n      color: ", ";\n    }\n  }\n"], ["\n  padding: 40px;\n  text-align: left;\n  border-radius: 16px;\n  background-color: ", ";\n\n  .card__pretitle {\n    font-size: 12px;\n    line-height: 18px;\n    text-transform: uppercase;\n    color: ", ";\n  }\n\n  .card__content {\n    color: ", ";\n    font-size: 16px;\n    line-height: 24px;\n    margin-top: 8px;\n    * {\n      vertical-align: top;\n    }\n    a {\n      color: ", ";\n    }\n  }\n"])), colors.background.cloud_nine, colors.text_navy, colors.default_text, colors.light_them_up);
var Card = function (_a) {
    var preTitle = _a.preTitle, content = _a.content, children = _a.children;
    return (React.createElement(CardContainer, null,
        preTitle && React.createElement("div", { className: "card__pretitle" }, preTitle),
        content && React.createElement("div", { className: "card__content" }, content),
        React.createElement("div", { className: "card__content" }, children)));
};
export default Card;
var templateObject_1;
