var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import { lineFlexMessageStyles } from '@src/lib/app-constants';
import { getSpanStyle, getFlex } from '@src/lib/helpers';
function getWidth(flex, parentLayout) {
    if (parentLayout === 'baseline') {
        return flex ? flex + "px" : 'unset' || 'auto';
    }
    return 'auto';
}
function getMarginTop(margin, parentLayout) {
    return parentLayout === 'vertical'
        ? lineFlexMessageStyles.margins[margin]
        : 'none';
}
function getMarginLeft(margin, parentLayout) {
    return parentLayout === 'vertical'
        ? 'none'
        : lineFlexMessageStyles.margins[margin];
}
var TextContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  word-wrap: break-word;\n  white-space: pre-line;\n  font-weight: regular;\n  color: ", ";\n  text-align: ", ";\n  ", ";\n\n  flex: ", " !important;\n  margin-left: ", ";\n  margin-top: ", ";\n  width: ", ";\n"], ["\n  word-wrap: break-word;\n  white-space: pre-line;\n  font-weight: regular;\n  color: ", ";\n  text-align: ", ";\n  ", ";\n\n  flex: ", " !important;\n  margin-left: ", ";\n  margin-top: ", ";\n  width: ", ";\n"])), function (props) { return props.color; }, function (props) { return props.align; }, function (props) { return (props.isWrap ? '' : 'white-space: nowrap;'); }, function (props) { return getFlex(props.flex, props.parentLayout); }, function (props) { return getMarginLeft(props.margin, props.parentLayout); }, function (props) { return getMarginTop(props.margin, props.parentLayout); }, function (props) { return getWidth(props.flex, props.parentLayout); });
function renderSpansElement(contents) {
    return (React.createElement("div", { className: "spans_wrapper", "data-testid": "TextNode_SpansWrapper" }, contents.map(function (content, index) {
        return (content.text &&
            // Here check text is \\n will break line
            (content.text === '\\n' ? (
            // eslint-disable-next-line react/no-array-index-key
            React.createElement("br", { key: content.text + "-" + index })) : (React.createElement("span", { 
                // eslint-disable-next-line react/no-array-index-key
                key: content.text + "-" + index, style: getSpanStyle(content) }, content.text))));
    })));
}
var TextNode = function (_a) {
    var parentLayout = _a.parentLayout, lineText = _a.lineText;
    return (React.createElement(TextContainer, { color: lineText.color, align: lineText.align, isWrap: lineText.wrap, flex: lineText.flex, margin: lineText.margin, parentLayout: parentLayout, "data-testid": "TextNode_TextContainer" }, lineText.contents ? renderSpansElement(lineText.contents) : null));
};
export default TextNode;
var templateObject_1;
