import { defineMessage } from 'react-intl';
export var toDoubleDigits = function (num) {
    var coersionNum = String(num);
    if (coersionNum.length === 1) {
        coersionNum = coersionNum.padStart(2, '0');
    }
    return coersionNum;
};
export var ONE_DAY_FOR_MINUTES = 1440;
export var ONE_DAY = 1;
export var MINUTES = {
    min: {
        value: 1,
        title: defineMessage({ id: "TZr4xU", defaultMessage: "min" }),
    },
    day: {
        value: 24 * 60,
        title: defineMessage({ id: "aVqg31", defaultMessage: "day" }),
    },
};
