var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { tabTypes } from '@src/lib/app-constants';
import { Tabs, Dialog } from '@zeals-co-ltd/washi-components';
import { useDispatch, useSelector } from 'react-redux';
import ImageMapAreaSetting from '@src/components/molecules/ImageMapAreaSetting';
import ImageMapVideoSetting from '@src/components/molecules/ImageMapVideoSetting';
import useActionFormValidator from '@src/components/hooks/useActionFormValidator';
import imageMapAreaModalSchema from '@src/lib/helpers/form_modal_schema/imageMapAreaModalSchema';
import { ImageMapAreaActionType, } from '@src/typings/enums';
import { AlertModalContext } from '@src/components/molecules/AlertModal';
import { fetchTargetUserTagsGroup, fetchUserTagGroups, } from '@src/redux/fanp/actions/ad-scenario';
import generateEffect from '@src/lib/helpers/form_modal_schema/generateEffect';
import { injectIntl } from 'react-intl';
import ImageMapAreaWithUserTagSetting from './ImageMapAreaWithUserTagSetting';
var ImageMapAreaModalContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  .MuiBox-root {\n    border-bottom: none;\n  }\n"], ["\n  .MuiBox-root {\n    border-bottom: none;\n  }\n"])));
var StyledTabs = styled(Tabs)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  && div > button {\n    padding: 0;\n    & > span:first-child {\n      width: 100%;\n      height: 100%;\n      display: inline-flex;\n      align-items: center;\n      justify-content: center;\n    }\n  }\n"], ["\n  && div > button {\n    padding: 0;\n    & > span:first-child {\n      width: 100%;\n      height: 100%;\n      display: inline-flex;\n      align-items: center;\n      justify-content: center;\n    }\n  }\n"])));
var ImageMapAreaModal = function (_a) {
    var _b, _c;
    var intl = _a.intl, templateNum = _a.templateNum, imageMapArea = _a.imageMapArea, imageMapVideo = _a.imageMapVideo, imageMapAreaArr = _a.imageMapAreaArr, chatbotIdParam = _a.chatbotIdParam, adScenarioIdParam = _a.adScenarioIdParam, blockIdParam = _a.blockIdParam, rankParam = _a.rankParam, defaultMessageId = _a.default_message_id, rank = _a.rank, platform = _a.platform, activeBlocks = _a.activeBlocks, updateImageMapAreas = _a.updateImageMapAreas, createNewBlock = _a.createNewBlock, onSubmitVideoUpdate = _a.onSubmitVideoUpdate, setPreventOnCancel = _a.setPreventOnCancel, onCancel = _a.onCancel, editModalOpened = _a.editModalOpened, rest = __rest(_a, ["intl", "templateNum", "imageMapArea", "imageMapVideo", "imageMapAreaArr", "chatbotIdParam", "adScenarioIdParam", "blockIdParam", "rankParam", "default_message_id", "rank", "platform", "activeBlocks", "updateImageMapAreas", "createNewBlock", "onSubmitVideoUpdate", "setPreventOnCancel", "onCancel", "editModalOpened"]);
    var handleModal = React.useContext(AlertModalContext).handleModal;
    var _d = imageMapArea || {}, initText = _d.text, initPostbackAction = _d.postback_action;
    var _e = initPostbackAction || {}, initScenarioId = _e.scenario_id, initBlockId = _e.block_id, url = _e.url;
    var dispatch = useDispatch();
    var _f = __read(useState(tabTypes.AREA_SETTING), 2), activeTabType = _f[0], setActiveTabType = _f[1];
    var _g = __read(useState({}), 2), customInitActionSelectorValue = _g[0], setCustomInitActionSelectorValue = _g[1];
    // GET list user tag groups and user tags
    var userTagGroups = useSelector(function (state) { return state.adScenario.present.userTagGroups; });
    var userTags = useSelector(function (state) { return state.adScenario.present.userTagGroup.user_tags; });
    // Fetch User Tag Group & User Tag to get their labels and passe them to ImageMapSettings
    useEffect(function () {
        if (imageMapArea.attribute_answer_id &&
            imageMapArea.attribute_question_id) {
            dispatch(fetchTargetUserTagsGroup(chatbotIdParam, imageMapArea.attribute_question_id));
        }
    }, [
        imageMapArea.attribute_answer_id,
        imageMapArea.attribute_question_id,
        dispatch,
        chatbotIdParam,
    ]);
    var initValues = {
        activeTab: activeTabType,
        imageMapArea: imageMapArea,
        imageMapVideo: __assign({ activeVideoTab: imageMapVideo.id ? tabTypes.URL : tabTypes.FILE, activePreviewTab: imageMapVideo.id ? tabTypes.URL : tabTypes.FILE }, imageMapVideo),
    };
    var _h = useActionFormValidator({
        intl: intl,
        platform: platform,
        postbackActionPath: 'imageMapArea.postback_action',
        object: initValues,
        schema: imageMapAreaModalSchema(imageMapArea.id, imageMapAreaArr),
    }), isValidating = _h.isValidating, values = _h.values, isValid = _h.isValid, errors = _h.errors, touched = _h.touched, setValues = _h.setValues, handleChange = _h.handleChange, handleChangePostbackAction = _h.handleChangePostbackAction, initActionSelectorValue = _h.initActionSelectorValue, actionSelectorOnChange = _h.actionSelectorOnChange;
    var _j = __read(useMemo(function () {
        var _a = values.imageMapArea, changedAttributeAnswerId = _a.attribute_answer_id, changedAttributeQuestionId = _a.attribute_question_id;
        var userTagGroup = userTagGroups.find(function (utg) { return utg.id === changedAttributeQuestionId; }) ||
            null;
        var userTag = userTags.find(function (userTag) { return userTag.id === changedAttributeAnswerId; }) ||
            null;
        return [userTag, userTagGroup];
    }, [values.imageMapArea]), 2), tagValue = _j[0], tagGroupValue = _j[1];
    var _k = __read(useMemo(function () {
        var postbackAction = (values.imageMapArea || {}).postback_action;
        var currentActionType = [
            tabTypes.URL,
            tabTypes.PHONE_NUMBER,
            tabTypes.EMAIL,
        ].includes(postbackAction === null || postbackAction === void 0 ? void 0 : postbackAction.effect)
            ? ImageMapAreaActionType.Url
            : ImageMapAreaActionType.Message;
        return [currentActionType, values.imageMapArea];
    }, [values]), 2), actionType = _k[0], currentImageMapArea = _k[1];
    var _l = values.imageMapArea, label = _l.label, postbackAction = _l.postback_action, text = _l.text, attributeAnswerId = _l.attribute_answer_id, attributeQuestionId = _l.attribute_question_id;
    var isWithUserTag = activeTabType === tabTypes.AREA_SETTING_WITH_USER_TAG;
    var submitValues = {
        chatbotId: chatbotIdParam,
        scenarioId: adScenarioIdParam,
        imageMapId: imageMapArea === null || imageMapArea === void 0 ? void 0 : imageMapArea.image_map_id,
        imageMapAreaId: imageMapArea === null || imageMapArea === void 0 ? void 0 : imageMapArea.id,
        messageId: defaultMessageId,
        label: isWithUserTag ? null : label,
        url: postbackAction === null || postbackAction === void 0 ? void 0 : postbackAction.url,
        nextScenarioId: postbackAction === null || postbackAction === void 0 ? void 0 : postbackAction.scenario_id,
        blockId: postbackAction === null || postbackAction === void 0 ? void 0 : postbackAction.block_id,
        text: text,
        action_type: actionType,
        layer_rank: rank,
        postback_action_id: postbackAction === null || postbackAction === void 0 ? void 0 : postbackAction.id,
        attribute_answer_id: isWithUserTag ? attributeAnswerId : null,
        attribute_question_id: isWithUserTag ? attributeQuestionId : null,
    };
    // legacy code
    useEffect(function () { return handleChangePostbackAction(imageMapArea === null || imageMapArea === void 0 ? void 0 : imageMapArea.postback_action); }, [
        imageMapArea === null || imageMapArea === void 0 ? void 0 : imageMapArea.postback_action,
    ]);
    // legacy code
    useEffect(function () {
        var _a, _b;
        setPreventOnCancel(Boolean(values.activeTab !== tabTypes.AREA_SETTING &&
            ((_a = values.imageMapVideo) === null || _a === void 0 ? void 0 : _a.activeVideoTab) === tabTypes.FILE &&
            ((_b = values.imageMapVideo) === null || _b === void 0 ? void 0 : _b.originalContentFile)));
    }, [
        values.activeTab,
        (_b = values.imageMapVideo) === null || _b === void 0 ? void 0 : _b.activeVideoTab,
        (_c = values.imageMapVideo) === null || _c === void 0 ? void 0 : _c.originalContentFile,
    ]);
    var handleChangeUserTag = function (_a) {
        var attributeQuestionId = _a.attributeQuestionId, attributeAnswerId = _a.attributeAnswerId;
        return __awaiter(void 0, void 0, Promise, function () {
            var changes, newImageMapAreaWithUserTag;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        changes = {
                            attribute_answer_id: attributeAnswerId,
                            attribute_question_id: attributeQuestionId,
                        };
                        newImageMapAreaWithUserTag = __assign(__assign({}, currentImageMapArea), changes);
                        return [4 /*yield*/, setValues(__assign(__assign({}, values), { imageMapArea: newImageMapAreaWithUserTag }), true)];
                    case 1:
                        _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    var handleChanges = function (changes) {
        Object.keys(changes).forEach(function (key) {
            handleChange({
                target: {
                    name: "imageMapVideo." + key,
                    value: changes[key],
                },
            });
        });
    };
    var onChangeTab = function (newActiveTabType) {
        var _a, _b;
        if (activeTabType === newActiveTabType)
            return;
        setActiveTabType(newActiveTabType);
        // ToDO : Refactor formik object with the required values
        var resetValues = initValues;
        resetValues.activeTab = newActiveTabType;
        var postbackAction = (resetValues.imageMapArea || {}).postback_action;
        var _c = postbackAction || {}, scenarioId = _c.scenario_id, blockId = _c.block_id;
        var url = (postbackAction || {}).url;
        var gEffect = generateEffect(blockId, scenarioId, url);
        if (tabTypes.VIDEO_SETTING !== newActiveTabType) {
            // In this step, check case newActiveTabType is area setting with user tag
            // and gEffect is not block, scenario or url, set gEffect to block and
            // url to null
            if (!gEffect ||
                (tabTypes.AREA_SETTING_WITH_USER_TAG === newActiveTabType &&
                    ![tabTypes.BLOCK, tabTypes.SCENARIO, tabTypes.URL].includes(gEffect))) {
                gEffect = tabTypes.BLOCK;
                url = null;
            }
            resetValues = __assign(__assign({}, resetValues), { imageMapArea: __assign(__assign({}, resetValues.imageMapArea), { postback_action: __assign(__assign({}, (resetValues.imageMapArea.postback_action || {})), { url: url, effect: gEffect }) }) });
        }
        setValues(__assign({}, resetValues), ((_b = (_a = values.imageMapArea) === null || _a === void 0 ? void 0 : _a.postback_action) === null || _b === void 0 ? void 0 : _b.effect) === gEffect);
        handleChangeActionSelector({ effect: gEffect });
        setCustomInitActionSelectorValue({ effect: gEffect });
    };
    var handleNewBlockClick = function (filterText) {
        return createNewBlock({
            imageMapAreaId: imageMapArea.id,
            name: filterText,
            chatbotId: chatbotIdParam,
            adScenarioId: adScenarioIdParam,
            blockId: blockIdParam,
            layer_rank: rankParam,
        }).catch(function (e) { return handleModal(e.message); });
    };
    var handleBlockClick = useCallback(function (_a) {
        var _b = _a.blockId, blockId = _b === void 0 ? null : _b, _c = _a.nextScenarioId, nextScenarioId = _c === void 0 ? null : _c, _d = _a.url, url = _d === void 0 ? null : _d;
        var postbackAction = (values.imageMapArea || {}).postback_action;
        // TODO: do something here
        handleChangePostbackAction(__assign(__assign({}, postbackAction), { block_id: blockId, scenario_id: nextScenarioId, url: url }));
    }, [currentImageMapArea]);
    var handleSubmitAreaUpdate = function () {
        updateImageMapAreas(submitValues);
        dispatch(fetchUserTagGroups(chatbotIdParam));
        onCancel();
    };
    var handleSubmitVideoUpdate = function () {
        var imageMapId = values.imageMapArea.image_map_id, _a = values.imageMapVideo, id = _a.id, label = _a.label, linkUri = _a.link_uri, originalContentFile = _a.originalContentFile, originalContentUrl = _a.original_content_url, previewImageFile = _a.previewImageFile, priviewImageUrl = _a.preview_image_url, activeVideoTab = _a.activeVideoTab, activePreviewTab = _a.activePreviewTab, duration = _a.duration, width = _a.width, height = _a.height;
        var formData = new FormData();
        formData.append('label', label);
        formData.append('link_uri', linkUri);
        if (activeVideoTab === tabTypes.URL) {
            formData.append('video_url', originalContentUrl);
        }
        else if (originalContentFile !== null) {
            formData.append('video_file', originalContentFile);
            formData.append('duration', String(duration));
            formData.append('width', String(width));
            formData.append('height', String(height));
        }
        if (activePreviewTab === tabTypes.URL) {
            formData.append('preview_url', priviewImageUrl);
        }
        else if (previewImageFile !== null) {
            formData.append('preview_file', previewImageFile);
        }
        onSubmitVideoUpdate({
            chatbotId: chatbotIdParam,
            scenarioId: adScenarioIdParam,
            imageMapId: imageMapId,
            imageMapVideoId: id,
            messageId: defaultMessageId,
            formData: formData,
        });
        onCancel();
    };
    // Submit data function
    var handleSubmit = function () {
        if (activeTabType === tabTypes.VIDEO_SETTING) {
            handleSubmitVideoUpdate();
            return;
        }
        handleSubmitAreaUpdate();
    };
    var handleDisconnectBlock = function () {
        return updateImageMapAreas(__assign(__assign({}, submitValues), { disconnect_block: true }));
    };
    // change action selector
    var handleChangeActionSelector = actionSelectorOnChange({
        newBlock: handleNewBlockClick,
        selectBlock: function (blockId) { return handleBlockClick({ blockId: blockId }); },
        disconnectBlock: handleDisconnectBlock,
        selectScenario: function (nextScenarioId) { return handleBlockClick({ nextScenarioId: nextScenarioId }); },
        disconnectScenario: function () { return handleBlockClick({ nextScenarioId: null }); },
    });
    var onCloseModal = function () {
        if (initText) {
            handleChangeActionSelector({
                scenarioId: initScenarioId || null,
                blockId: initBlockId || null,
                url: url || null,
            });
        }
        onCancel();
    };
    return (React.createElement(Dialog, { fullWidth: true, maxWidth: "sm", title: intl.formatMessage({ id: "3PLpRj", defaultMessage: "Image Area Settings" }), "data-testid": "image-map-area-modal", dialogType: "primary", cancelButtonLabel: intl.formatMessage({ id: "47FYwb", defaultMessage: "Cancel" }), mainButtonLabel: intl.formatMessage({ id: "daowld", defaultMessage: "Save" }), open: editModalOpened, onClose: onCloseModal, mainButtonOnClick: handleSubmit, mainButtonDisabled: !isValid },
        React.createElement(ImageMapAreaModalContainer, null,
            React.createElement(StyledTabs, { variant: "fullWidth", 
                // TODO: fix washi to solve type error
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                tabItems: [
                    {
                        children: (React.createElement(ImageMapAreaSetting, { className: "defaultClass", imageMapArea: values.imageMapArea, errors: errors.imageMapArea, touched: touched.imageMapArea, handleChange: handleChange, initActionSelectorValue: __assign(__assign({}, initActionSelectorValue), customInitActionSelectorValue), actionSelectorOnChange: handleChangeActionSelector, activeBlocks: activeBlocks, isValidating: isValidating, platform: platform })),
                        label: (React.createElement("span", { role: "button", tabIndex: 0, onClick: function () {
                                onChangeTab(tabTypes.AREA_SETTING);
                            } }, intl.formatMessage({ id: "VDIbB3", defaultMessage: "Without User Tag" }))),
                    },
                    templateNum === 1 && {
                        children: (React.createElement(ImageMapVideoSetting, { className: "videoTab", platform: platform, imageMapVideo: values.imageMapVideo, errors: errors.imageMapVideo, touched: touched.imageMapVideo, handleChange: handleChange, handleChanges: handleChanges })),
                        label: (React.createElement("span", { role: "button", tabIndex: 0, onClick: function () { return onChangeTab(tabTypes.VIDEO_SETTING); } }, intl.formatMessage({ id: "3hXiYB", defaultMessage: "Video Area" }))),
                    },
                    {
                        children: (React.createElement(ImageMapAreaWithUserTagSetting, { className: "withUserTag", imageMapArea: values.imageMapArea, errors: errors.imageMapArea, touched: touched.imageMapArea, handleChange: handleChange, initActionSelectorValue: {
                                effect: [
                                    tabTypes.BLOCK,
                                    tabTypes.SCENARIO,
                                    tabTypes.URL,
                                ].includes(initActionSelectorValue.effect)
                                    ? initActionSelectorValue.effect
                                    : tabTypes.BLOCK,
                                url: initActionSelectorValue.url,
                            }, actionSelectorOnChange: handleChangeActionSelector, activeBlocks: activeBlocks, isValidating: isValidating, handleChangeUserTag: handleChangeUserTag, tagValue: tagValue, tagGroupValue: tagGroupValue })),
                        label: (React.createElement("span", { role: "button", tabIndex: 0, onClick: function () {
                                return onChangeTab(tabTypes.AREA_SETTING_WITH_USER_TAG);
                            } }, intl.formatMessage({ id: "J+plsD", defaultMessage: "With User Tag" }))),
                    },
                ].filter(Boolean) }))));
};
export default injectIntl(ImageMapAreaModal);
var templateObject_1, templateObject_2;
