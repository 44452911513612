// Replace substring of one string with two indices
var replaceBetween = function (str, start, end, what) {
    return str.substring(0, start) + what + str.substring(end);
};
// Insert subString into particular position of string
export var splice = function (str, start, newStr) {
    return str.slice(0, start) + newStr + str.slice(start);
};
/**
 * Remove smartReplace tag from string if cursorPosition is after or inside a smartReplace tag
 * @return null if nothing was removed, the new string if something was removed
 */
export var removeSmartReplaceFromString = function (str, cursorPosition, list) {
    var openBracketPos = str.lastIndexOf('{', cursorPosition);
    var closeBracketPos = str.indexOf('}', cursorPosition);
    // When the cursor position is directly after closing bracket
    var strFound = str.substring(openBracketPos, cursorPosition);
    // handle the corner case: if the cursor is directly before opening bracket
    if (str[openBracketPos] === '{' && !strFound)
        return null;
    // check found string whether one of list
    if (list.includes(strFound)) {
        return replaceBetween(str, openBracketPos, openBracketPos + strFound.length, '');
    }
    // When the cursor anywhere inside brackets
    strFound = str.substring(openBracketPos, closeBracketPos + 1);
    // check found string whether one of list
    if (list.includes(strFound)) {
        return replaceBetween(str, openBracketPos, closeBracketPos + 1, '');
    }
    return null;
};
export function mergeSmartReplaces(smartReplaces, smartReplacePlaceholders) {
    return smartReplacePlaceholders.map(function (sRepPlaceholder) {
        var smartReplace = smartReplaces.find(function (s) { return s.pattern === sRepPlaceholder.pattern; });
        return smartReplace || sRepPlaceholder;
    });
}
