var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import { colors } from '@src/colors';
import { transitions } from '@src/lib/animations';
import { centeredFlex } from '@src/lib/mixins';
var LabelWrapper = styled.label(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  flex: 1;\n  flex-direction: column;\n  font-size: 12px;\n  transition: ", ";\n  > span:first-child {\n    font-size: 24px;\n  }\n  &:hover {\n    cursor: pointer;\n    color: ", ";\n  }\n"], ["\n  ", ";\n  flex: 1;\n  flex-direction: column;\n  font-size: 12px;\n  transition: ", ";\n  > span:first-child {\n    font-size: 24px;\n  }\n  &:hover {\n    cursor: pointer;\n    color: ",
    ";\n  }\n"])), centeredFlex, transitions.normal, function (props) {
    return props.adicon === 'adicon_delete' ? colors.error : colors.default_text;
});
var UploadLabel = function (_a) {
    var className = _a.className, adicon = _a.adicon, text = _a.text, onClick = _a.onClick;
    return (React.createElement(LabelWrapper, { "data-testid": "UploadLabel-LabelWrapper-" + adicon, className: className, adicon: adicon, onClick: onClick },
        React.createElement("span", { className: adicon }),
        React.createElement("span", null, text)));
};
export default UploadLabel;
var templateObject_1;
