var _a;
export var ActionsType;
(function (ActionsType) {
    ActionsType["SET_SCENARIOS"] = "SET_SCENARIOS";
    ActionsType["SET_SELECTED_SCENARIOS"] = "SET_SELECTED_SCENARIOS";
    ActionsType["SET_ACTIVE_INITIAL_CONVERSATION"] = "SET_ACTIVE_INITIAL_CONVERSATION";
    ActionsType["SET_DRAFT_AB_TESTING"] = "SET_DRAFT_AB_TESTING";
    ActionsType["SET_ACTIVE_AB_TESTING"] = "SET_ACTIVE_AB_TESTING";
    ActionsType["SET_SELECTED_SCENARIOS_WEIGHT_TO_EQUALITY"] = "SET_SELECTED_SCENARIOS_WEIGHT_TO_EQUALITY";
    ActionsType["SET_SELECTED_SCENARIOS_WEIGHT_TO_CUSTOM"] = "SET_SELECTED_SCENARIOS_WEIGHT_TO_CUSTOM";
    ActionsType["SET_DRAFT_INITIAL_CONVERSATION"] = "SET_DRAFT_INITIAL_CONVERSATION";
})(ActionsType || (ActionsType = {}));
export default (_a = {},
    _a[ActionsType.SET_SCENARIOS] = ActionsType.SET_SCENARIOS,
    _a[ActionsType.SET_SELECTED_SCENARIOS] = ActionsType.SET_SELECTED_SCENARIOS,
    _a[ActionsType.SET_ACTIVE_INITIAL_CONVERSATION] = ActionsType.SET_ACTIVE_INITIAL_CONVERSATION,
    _a[ActionsType.SET_DRAFT_AB_TESTING] = ActionsType.SET_DRAFT_AB_TESTING,
    _a[ActionsType.SET_ACTIVE_AB_TESTING] = ActionsType.SET_ACTIVE_AB_TESTING,
    _a[ActionsType.SET_SELECTED_SCENARIOS_WEIGHT_TO_EQUALITY] = ActionsType.SET_SELECTED_SCENARIOS_WEIGHT_TO_EQUALITY,
    _a[ActionsType.SET_SELECTED_SCENARIOS_WEIGHT_TO_CUSTOM] = ActionsType.SET_SELECTED_SCENARIOS_WEIGHT_TO_CUSTOM,
    _a);
