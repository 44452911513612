export var ActionType;
(function (ActionType) {
    ActionType["SET_ACCOUNT"] = "SET_ACCOUNT";
    ActionType["SET_CURRENT_USER"] = "SET_CURRENT_USER";
    ActionType["SET_FETCHING_ACCOUNT"] = "SET_FETCHING_ACCOUNT";
    ActionType["UPDATE_ACCOUNT_PROPERTY"] = "UPDATE_ACCOUNT_PROPERTY";
    ActionType["UPDATE_CLIENT_PROPERTY"] = "UPDATE_CLIENT_PROPERTY";
    ActionType["SET_UPDATED_ACCOUNT"] = "SET_UPDATED_ACCOUNT";
    ActionType["SET_LANGUAGE"] = "SET_LANGUAGE";
})(ActionType || (ActionType = {}));
