var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { injectIntl } from 'react-intl';
import * as pathLinks from '@src/routes/Fanp/pathLinks';
import { colors } from '@src/colors';
import DefaultRichMenuForm from '@src/components/molecules/DefaultRichMenuForm';
import { Icon, Button } from '@zeals-co-ltd/washi-components';
import WashiBreadcrumbs from '@zeals-co-ltd/washi-components/lib/Breadcrumbs/Breadcrumbs';
import { truncateText } from '@src/lib/mixins';
import { setScenariosList } from '@src/redux/fanp/actions/ad-scenario/setActions';
import * as scenarioApi from '@src/services/api/scenario';
import flatten from 'lodash/flatten';
import uniq from 'lodash/uniq';
import { handleAPIErrors } from '@src/lib/helpers';
import { useDispatch } from 'react-redux';
import arrToObj from '@src/lib/helpers/arrToObj';
var EditDefaultRichMenuContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: 40px;\n"], ["\n  padding: 40px;\n"])));
var HeaderTextsWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  position: relative;\n\n  .MuiBreadcrumbs-li:nth-child(-2n + 3) {\n    a {\n      color: ", ";\n      text-decoration: unset;\n    }\n  }\n\n  .MuiBreadcrumbs-li:nth-child(5) {\n    a {\n      color: ", ";\n    }\n  }\n\n  p.MuiTypography-root {\n    max-width: 60vw;\n    ", "\n  }\n"], ["\n  position: relative;\n\n  .MuiBreadcrumbs-li:nth-child(-2n + 3) {\n    a {\n      color: ", ";\n      text-decoration: unset;\n    }\n  }\n\n  .MuiBreadcrumbs-li:nth-child(5) {\n    a {\n      color: ", ";\n    }\n  }\n\n  p.MuiTypography-root {\n    max-width: 60vw;\n    ", "\n  }\n"])), colors.light_navy, colors.mid_navy, truncateText);
var HeaderButtonsWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  position: absolute;\n  bottom: 0px;\n  right: 0px;\n\n  div {\n    display: flex;\n\n    Button {\n      margin: 0 12px;\n    }\n\n    Button:nth-last-child(1) {\n      border-radius: 8px;\n      text-transform: uppercase;\n      font-size: 14px;\n      font-style: normal;\n      font-weight: 500;\n      line-height: 24px;\n      letter-spacing: 1.25px;\n      white-space: nowrap;\n      padding: 8px 16px;\n      min-width: unset;\n      border: none;\n      margin-right: 0px;\n\n      .MuiSvgIcon-root {\n        color: inherit;\n      }\n    }\n\n    .MuiSvgIcon-root {\n      font-size: 20px;\n    }\n  }\n"], ["\n  position: absolute;\n  bottom: 0px;\n  right: 0px;\n\n  div {\n    display: flex;\n\n    Button {\n      margin: 0 12px;\n    }\n\n    Button:nth-last-child(1) {\n      border-radius: 8px;\n      text-transform: uppercase;\n      font-size: 14px;\n      font-style: normal;\n      font-weight: 500;\n      line-height: 24px;\n      letter-spacing: 1.25px;\n      white-space: nowrap;\n      padding: 8px 16px;\n      min-width: unset;\n      border: none;\n      margin-right: 0px;\n\n      .MuiSvgIcon-root {\n        color: inherit;\n      }\n    }\n\n    .MuiSvgIcon-root {\n      font-size: 20px;\n    }\n  }\n"])));
var HeaderButtonsShowOnly = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  .MuiButton-containedSecondary {\n    background-color: ", ";\n    color: ", ";\n  }\n\n  .MuiButton-containedPrimary {\n    background-color: ", ";\n  }\n"], ["\n  .MuiButton-containedSecondary {\n    background-color: ", ";\n    color: ", ";\n  }\n\n  .MuiButton-containedPrimary {\n    background-color: ", ";\n  }\n"])), colors.yellow, colors.blue_purple_dark, colors.light_blue);
var BackWrapper = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  margin-top: 24px;\n\n  & > button {\n    font-weight: 500;\n    font-size: 14px;\n    line-height: 24px;\n    color: ", ";\n    text-transform: uppercase;\n  }\n\n  & > span {\n    margin-left: 16px;\n    font-weight: 500;\n    font-size: 24px;\n    line-height: 35px;\n    padding-bottom: 6px;\n    max-width: 45vw;\n    display: inline-block;\n    ", "\n  }\n\n  .MuiButton-startIcon {\n    margin-top: 2px;\n    margin-right: 4px;\n  }\n"], ["\n  margin-top: 24px;\n\n  & > button {\n    font-weight: 500;\n    font-size: 14px;\n    line-height: 24px;\n    color: ", ";\n    text-transform: uppercase;\n  }\n\n  & > span {\n    margin-left: 16px;\n    font-weight: 500;\n    font-size: 24px;\n    line-height: 35px;\n    padding-bottom: 6px;\n    max-width: 45vw;\n    display: inline-block;\n    ", "\n  }\n\n  .MuiButton-startIcon {\n    margin-top: 2px;\n    margin-right: 4px;\n  }\n"])), colors.light_blue, truncateText);
var ButtonUploadToLineWrapper = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  .MuiButton-textInherit {\n    color: ", ";\n    background: ", ";\n    pointer-events: none;\n  }\n"], ["\n  .MuiButton-textInherit {\n    color: ", ";\n    background: ", ";\n    pointer-events: none;\n  }\n"])), colors.disabled_grey2, colors.mid_grey);
var HeaderTexts = function (_a) {
    var intl = _a.intl, chatbotId = _a.chatbotId, backToListPage = _a.backToListPage, menuText = _a.menuText, showOnly = _a.showOnly, children = _a.children, showBackModal = _a.showBackModal;
    return (React.createElement(HeaderTextsWrapper, null,
        React.createElement(WashiBreadcrumbs, { breadcrumbItems: [
                {
                    href: pathLinks.dashboardAdChatbots_chatbotId_Settings({
                        chatbotId: chatbotId,
                    }),
                    text: intl.formatMessage({ id: "SBGnjA", defaultMessage: "Bot settings" }),
                },
                {
                    href: pathLinks.dashboardAdChatbots_chatbotId_SettingsDefaultRichMenu({
                        chatbotId: chatbotId,
                    }),
                    text: intl.formatMessage({ id: "XxvLPK", defaultMessage: "Default Rich Menu" }),
                },
                {
                    text: menuText,
                },
            ], separator: React.createElement(Icon, { name: "adicon_right_chev", color: "disabled" }) }),
        React.createElement(BackWrapper, null,
            React.createElement(Button, { variant: "text", color: "primary", startIcon: React.createElement(Icon, { name: "adicon_left_chev", color: "primary" }), onClick: showOnly ? backToListPage : showBackModal }, intl.formatMessage({ id: "cyR7Kh", defaultMessage: "Back" })),
            React.createElement("span", null, menuText),
            children)));
};
var HeaderButtons = function (_a) {
    var intl = _a.intl, showOnly = _a.showOnly, isValid = _a.isValid, defaultRichMenu = _a.defaultRichMenu, showActiveModal = _a.showActiveModal, showInActiveModal = _a.showInActiveModal, showUploadModal = _a.showUploadModal, showDuplicateModal = _a.showDuplicateModal, editDefaultRichMenu = _a.editDefaultRichMenu, showDeleteModal = _a.showDeleteModal;
    return (React.createElement(HeaderButtonsWrapper, null, showOnly ? (React.createElement(HeaderButtonsShowOnly, null,
        React.createElement(Button, { color: "error", variant: "text", size: "medium", startIcon: React.createElement(Icon, { name: "adicon_delete", color: "error" }), onClick: function () { return showDeleteModal(defaultRichMenu); }, "data-testid": "EditDefaultRichMenu_DeteteButton" }, intl.formatMessage({ id: "K3r6DQ", defaultMessage: "Delete" })),
        React.createElement(Button, { variant: "text", size: "medium", startIcon: React.createElement(Icon, { name: "adicon_duplicate", color: "primary" }), onClick: function () { return showDuplicateModal(defaultRichMenu); }, "data-testid": "EditDefaultRichMenu_DuplicateButton" }, intl.formatMessage({ id: "4fHiNl", defaultMessage: "Duplicate" })),
        React.createElement(Button, { variant: "text", size: "medium", onClick: editDefaultRichMenu, startIcon: React.createElement(Icon, { name: "adicon_edit", color: "primary" }), "data-testid": "EditDefaultRichMenu_EditButton" }, intl.formatMessage({ id: "wEQDC6", defaultMessage: "Edit" })),
        defaultRichMenu.isActive ? (React.createElement(Button, { onClick: function () { return showInActiveModal(defaultRichMenu); }, color: "secondary", size: "medium", variant: "contained", startIcon: React.createElement(Icon, { name: "adicon_close", color: "inherit" }), "data-testid": "EditDefaultRichMenu_SetInActiveButton" }, intl.formatMessage({ id: "t1fc3C", defaultMessage: "Set as inactive" }))) : (React.createElement(Button, { color: "primary", variant: "contained", startIcon: React.createElement(Icon, { name: "adicon_msg_richmenu", color: "white" }), onClick: function () { return showActiveModal(defaultRichMenu); }, "data-testid": "EditDefaultRichMenu_SetActiveButton" }, intl.formatMessage({ id: "ZLuR/o", defaultMessage: "Set as active" }))))) : (React.createElement(ButtonUploadToLineWrapper, null,
        React.createElement(Button, { color: isValid ? 'success' : 'inherit', variant: isValid ? 'contained' : 'text', startIcon: React.createElement(Icon, { name: "adicon_upload", color: isValid ? 'white' : 'disabled' }), onClick: function () { return isValid && showUploadModal(); }, "data-testid": "EditDefaultRichMenu_UploadToLineButton" }, intl.formatMessage({ id: "Cv7GYx", defaultMessage: "Upload to Line" }))))));
};
var EditDefaultRichMenu = function (_a) {
    var intl = _a.intl, chatbotId = _a.chatbotId, defaultRichMenu = _a.defaultRichMenu, _b = _a.usedLabels, usedLabels = _b === void 0 ? [] : _b, backToListPage = _a.backToListPage, openTemplateModal = _a.openTemplateModal, _c = _a.showOnly, showOnly = _c === void 0 ? false : _c, showActiveModal = _a.showActiveModal, showInActiveModal = _a.showInActiveModal, showUploadModal = _a.showUploadModal, showDuplicateModal = _a.showDuplicateModal, showBackModal = _a.showBackModal, showDeleteModal = _a.showDeleteModal, editDefaultRichMenu = _a.editDefaultRichMenu, schedules = _a.schedules;
    var dispatch = useDispatch();
    var _d = __read(useState(defaultRichMenu), 2), submitValues = _d[0], setSubmitValues = _d[1];
    var _e = __read(useState(false), 2), isValid = _e[0], setIsValid = _e[1];
    var _f = __read(useState([]), 2), blocks = _f[0], setBlocks = _f[1];
    var fetchScenarios = function () { return __awaiter(void 0, void 0, void 0, function () {
        var data, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, scenarioApi.getScenarios(chatbotId, {
                            default_rich_menu: true,
                        })];
                case 1:
                    data = _a.sent();
                    dispatch(setScenariosList(arrToObj(data)));
                    setBlocks(uniq(flatten(data.map(function (_a) {
                        var blocks = _a.blocks;
                        return blocks;
                    }))));
                    return [3 /*break*/, 3];
                case 2:
                    e_1 = _a.sent();
                    handleAPIErrors(e_1.message);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        fetchScenarios();
    }, [chatbotId]);
    var menuText = defaultRichMenu.richMenus[0].line_rich_menu_id
        ? defaultRichMenu.richMenus[0].label
        : intl.formatMessage({ id: "G7hxUi", defaultMessage: "Create New Default Rich Menu" });
    return (React.createElement(EditDefaultRichMenuContainer, null,
        React.createElement(HeaderTexts, { intl: intl, chatbotId: chatbotId, menuText: menuText, backToListPage: backToListPage, showBackModal: showBackModal, showOnly: showOnly },
            React.createElement(HeaderButtons, { intl: intl, showOnly: showOnly, isValid: isValid, defaultRichMenu: defaultRichMenu, showActiveModal: showActiveModal, showInActiveModal: showInActiveModal, showUploadModal: function () { return showUploadModal(submitValues); }, showDuplicateModal: showDuplicateModal, showDeleteModal: showDeleteModal, editDefaultRichMenu: editDefaultRichMenu })),
        React.createElement(DefaultRichMenuForm, { defaultRichMenu: defaultRichMenu, blocks: blocks, openTemplateModal: openTemplateModal, updateSubmitValues: function (data) {
                setSubmitValues(data.values);
                setIsValid(data.isValid);
            }, usedLabels: usedLabels, showOnly: showOnly, schedules: schedules })));
};
export default injectIntl(EditDefaultRichMenu);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
