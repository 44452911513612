import "core-js/modules/es.array.map";
import _extends from "@babel/runtime/helpers/extends";
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { showModal } from '@src/redux/fanp/actions/modal';
import { fetchAdScenarios } from '@src/redux/fanp/actions/ad-scenario';
import { fetchNewPersistentMenuItem, adUpdatePersistentMenuItem } from '@src/redux/fanp/actions/settings';
import PersistentMenuItem from './PersistentMenuItem';
var Container = styled.div.withConfig({
  displayName: "Container",
  componentId: "sc-14tps58-0"
})(["padding:40px;font-size:14px;overflow:auto;"]);
var PersistentMenu = styled.div.withConfig({
  displayName: "PersistentMenu",
  componentId: "sc-14tps58-1"
})(["margin-top:38px;"]);
var PersistentMenuItemWrapper = styled.div.withConfig({
  displayName: "PersistentMenuItemWrapper",
  componentId: "sc-14tps58-2"
})(["display:flex;flex-direction:column;align-items:flex-end;"]);

var MainMenu = function MainMenu(_ref) {
  var chatbotIdParam = _ref.chatbotIdParam,
      adScenario = _ref.adScenario,
      application = _ref.application,
      settings = _ref.settings,
      adUpdatePersistentMenuItem = _ref.adUpdatePersistentMenuItem,
      fetchNewPersistentMenuItem = _ref.fetchNewPersistentMenuItem,
      showModal = _ref.showModal;
  var scenarios = adScenario.scenarios;
  var isFetching = application.isFetching;
  var persistentMenuItems = settings.persistentMenuItems;
  var newPersistentMenuItem = {
    title: '',
    url: '',
    postback_action: null
  };
  return React.createElement(Container, null, React.createElement("div", null, "\u30C1\u30E3\u30C3\u30C8\u30B9\u30AF\u30EA\u30FC\u30F3\u306E\u4E0B\u306B\u3042\u308B\u30E1\u30CB\u30E5\u30FC\u306E\u5185\u5BB9\u3092\u7DE8\u96C6\u3067\u304D\u307E\u3059\u3002"), React.createElement(PersistentMenu, null, persistentMenuItems.map(function (persistentMenuItem, i) {
    var effect = persistentMenuItem.effect,
        scenario_id = persistentMenuItem.scenario_id,
        url = persistentMenuItem.url;
    return React.createElement(PersistentMenuItemWrapper, {
      key: "".concat(i, "-persistent-menu")
    }, React.createElement(PersistentMenuItem, _extends({
      persistentMenuItem: persistentMenuItem,
      iterator: i,
      chatbotIdParam: chatbotIdParam,
      scenarios: scenarios,
      isFetching: isFetching
    }, persistentMenuItem, {
      showModal: showModal,
      onConfirm: adUpdatePersistentMenuItem
    })), !effect && !scenario_id && !url && persistentMenuItem.children && persistentMenuItem.children.length > 0 ? persistentMenuItem.children.map(function (persistentMenuItemChild, j) {
      return React.createElement(PersistentMenuItem, _extends({
        persistentMenuItem: persistentMenuItemChild,
        key: "".concat(j, "-persistent-menu-child"),
        parentIterator: i,
        iterator: j,
        chatbotIdParam: chatbotIdParam,
        scenarios: scenarios,
        isFetching: isFetching
      }, persistentMenuItemChild, {
        onConfirm: adUpdatePersistentMenuItem,
        isChild: true
      }));
    }) : null, !effect && !scenario_id && !url && persistentMenuItem.children && persistentMenuItem.children.length < 5 ? React.createElement(PersistentMenuItem, {
      persistentMenuItem: newPersistentMenuItem,
      className: "adicon_plus02",
      title: "\u30E1\u30CB\u30E5\u30FC\u3092\u8FFD\u52A0\u3059\u308B",
      chatbotIdParam: chatbotIdParam,
      parentIterator: i,
      iterator: persistentMenuItem.children.length,
      scenarios: scenarios,
      isFetching: isFetching,
      onConfirm: fetchNewPersistentMenuItem,
      isStatic: true,
      isChild: true
    }) : null);
  }), persistentMenuItems.length < 2 ? React.createElement(PersistentMenuItem, {
    persistentMenuItem: newPersistentMenuItem,
    className: "adicon_plus02",
    title: "\u30E1\u30CB\u30E5\u30FC\u3092\u8FFD\u52A0\u3059\u308B",
    chatbotIdParam: chatbotIdParam,
    iterator: persistentMenuItems.length,
    scenarios: scenarios,
    isFetching: isFetching,
    onConfirm: fetchNewPersistentMenuItem,
    isStatic: true
  }) : null, React.createElement(PersistentMenuItem, {
    className: "readonly",
    title: "Powered by Zeals",
    isStatic: true
  })));
};

MainMenu.defaultProps = {
  persistentMenuItems: []
};
MainMenu.propTypes = {
  chatbotIdParam: PropTypes.number,
  persistentMenuItems: PropTypes.array,
  fetchAdScenarios: PropTypes.func,
  adScenario: PropTypes.object,
  application: PropTypes.object,
  settings: PropTypes.object,
  fetchNewPersistentMenuItem: PropTypes.func,
  adUpdatePersistentMenuItem: PropTypes.func,
  showModal: PropTypes.func
};

var mapStateToProps = function mapStateToProps(state) {
  return {
    adScenario: state.adScenario.present,
    application: state.application,
    settings: state.settings.present
  };
};

export default connect(mapStateToProps, {
  fetchAdScenarios: fetchAdScenarios,
  fetchNewPersistentMenuItem: fetchNewPersistentMenuItem,
  adUpdatePersistentMenuItem: adUpdatePersistentMenuItem,
  showModal: showModal
})(MainMenu);