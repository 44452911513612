import "core-js/modules/es.array.concat";

var collection = function collection(chatbotId, scenarioId) {
  return "/chatbots/".concat(chatbotId, "/scenarios/").concat(scenarioId, "/messages");
};

var member = function member(chatbotId, scenarioId, id) {
  return "".concat(collection(chatbotId, scenarioId), "/").concat(id);
};

var talkCollection = function talkCollection(chatbotId) {
  return "/chatbots/".concat(chatbotId, "/talk_room/messages");
};

export default {
  collection: collection,
  member: member,
  talkCollection: talkCollection
};