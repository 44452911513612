import { ScenarioMapType, } from '../types/scenarioMap';
export var initialState = {
    hoveredPostbackAction: null,
    hoveredNode: null,
    alertMessage: '',
};
export default (function (state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case ScenarioMapType.HANDLE_CHANGE_HOVERED_POSTBACK_ACTION:
            return Object.assign({}, state, {
                hoveredPostbackAction: action.postbackAction,
            });
        case ScenarioMapType.HANDLE_CHANGE_HOVER_NODE:
            return Object.assign({}, state, {
                hoveredNode: action.hoveredNode,
            });
        case ScenarioMapType.HANDLE_CHANGE_ALERT_MESSAGE:
            return Object.assign({}, state, {
                alertMessage: action.alertMessage,
            });
        default:
            return state;
    }
});
