import "core-js/modules/es.array.map";
import React from 'react';
import styled from 'styled-components';
import buttonTypes from '@src/lib/buttonTypes';
import { Button } from '@src/components/atoms';
import { colors, shadows } from '@src/colors';
import PluginActionListItem from '@src/components/molecules/PluginActionListItem';
var Container = styled.div.withConfig({
  displayName: "Container",
  componentId: "sc-kknpoy-0"
})(["background:", ";margin:24px 0;border-radius:10px;box-shadow:", ";padding:24px;h3{margin-bottom:16px;}"], colors.white, shadows.medium.light);
var PluginActionListHeader = styled.div.withConfig({
  displayName: "PluginActionListHeader",
  componentId: "sc-kknpoy-1"
})(["display:flex;justify-content:space-between;margin-top:24px;padding:16px 0;border-top:1px solid ", ";.action_header_list_item{font-weight:bold;}.preview_item{width:35%;}"], colors.border.table);

var PluginActionList = function PluginActionList(_ref) {
  var actions = _ref.actions,
      onClickListItem = _ref.onClickListItem,
      updateActionScore = _ref.updateActionScore,
      onAddAction = _ref.onAddAction,
      onActionDelete = _ref.onActionDelete;
  return React.createElement(Container, null, React.createElement("h3", null, "\u30A2\u30AF\u30B7\u30E7\u30F3"), React.createElement(Button, {
    onClick: onAddAction,
    text: "\uFF0B \u30A2\u30AF\u30B7\u30E7\u30F3\u3092\u8FFD\u52A0\u3059\u308B",
    width: 180,
    buttonType: buttonTypes.SETTING
  }), React.createElement(PluginActionListHeader, null, React.createElement("div", {
    className: "action_header_list_item preview_item"
  }, "\u30D7\u30EC\u30D3\u30E5\u30FC"), React.createElement("div", {
    className: "action_header_list_item"
  }, "\u30A2\u30AF\u30B7\u30E7\u30F3\u30BF\u30A4\u30D7"), React.createElement("div", {
    className: "action_header_list_item"
  }, "AB\u914D\u4FE1\u5272\u5408"), React.createElement("div", {
    className: "action_header_list_item"
  }, "\u30A2\u30AF\u30C6\u30A3\u30D6")), React.createElement("div", null, actions && actions.map(function (action) {
    return React.createElement(PluginActionListItem, {
      key: "action-item-".concat(action.id),
      action: action,
      onClick: onClickListItem,
      updateActionScore: updateActionScore,
      onDelete: onActionDelete
    });
  })));
};

export default PluginActionList;