var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import styled from 'styled-components';
import { colors } from '@src/colors';
import makeStyles from '@mui/styles/makeStyles';
import { Spinner } from '@src/components/atoms';
import RichMenuTemplate from '@src/components/molecules/RichMenuTemplate';
import ListTemplateGroupTitles from '@src/components/molecules/ListTemplateGroupTitles';
import ListTemplateGroups from '@src/components/molecules/ListTemplateGroups';
import { handleAPIErrors } from '@src/lib/helpers';
import * as richMenuApi from '@src/services/api/richMenu';
import { richMenuMessages } from '@src/i18n/translations';
import Modal from '@zeals-co-ltd/washi-components/lib/Modal/Modal';
import Button from '@zeals-co-ltd/washi-components/lib/Button/Button';
import Tabs from '@zeals-co-ltd/washi-components/lib/Tabs/Tabs';
var useStyles = makeStyles(function () { return ({
    paper: {
        overflow: 'hidden',
        '@media only screen and (max-width: 1440px)': {
            maxWidth: '1010px',
            minWidth: '1010px',
            '& .MuiDialogContent-root, .list-template-group-titles + div': {
                height: '65vh',
            },
        },
        '@media only screen and (min-width: 1441px)': {
            maxWidth: '1400px',
            minWidth: '1400px',
            '& .MuiDialogContent-root, .list-template-group-titles + div': {
                height: '55vh',
            },
        },
        '& .MuiDialogTitle-root': {
            color: colors.black,
            '& .MuiTypography-root': {
                fontWeight: 500,
            },
        },
        '& .MuiDialogContent-root': {
            padding: '0px 40px',
            overflow: 'hidden',
            borderBottom: "1px solid " + colors.border_line,
        },
        '& .MuiButton-contained': {
            borderRadius: 8,
            marginLeft: 20,
        },
        '& > div': {
            borderBottom: "1px solid " + colors.border_line,
        },
        '& .list-template-group-titles': {
            boxShadow: 'unset',
            '& .scroll-to-size': {
                textTransform: 'uppercase',
                fontSize: '10px',
                fontWeight: 500,
                letterSpacing: '1.5px',
                paddingBottom: '5px',
            },
            '& .active': {
                backgroundColor: colors.mid_grey,
            },
        },
    },
}); });
var TemplateContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: grid;\n  grid-template-columns: repeat(auto-fill, 340px);\n  grid-gap: 16px;\n  justify-items: center;\n  > div {\n    text-align: center;\n  }\n"], ["\n  display: grid;\n  grid-template-columns: repeat(auto-fill, 340px);\n  grid-gap: 16px;\n  justify-items: center;\n  > div {\n    text-align: center;\n  }\n"])));
var ModalBody = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  margin-bottom: -40px;\n\n  .rich_menu_template_spinner {\n    height: 55vh;\n  }\n\n  .list-template-group-titles {\n    padding: 20px;\n    margin: 50px 0;\n    background-color: ", ";\n    border-radius: 16px;\n    color: ", ";\n\n    .scroll-to-size {\n      display: block;\n    }\n\n    .spacer {\n      border-bottom: 1px solid ", ";\n    }\n\n    > div:last-of-type {\n      margin-bottom: 0px;\n    }\n  }\n\n  .multiple-templates {\n    .MuiTabs-indicator {\n      background: transparent;\n    }\n\n    .MuiTab-wrapper {\n      text-transform: uppercase;\n      font-size: 14px;\n      font-weight: 500;\n      line-height: 24px;\n      letter-spacing: 1.25px;\n      text-align: left;\n    }\n\n    .MuiTab-root {\n      height: 50px;\n      padding-left: 8px;\n    }\n\n    .MuiBox-root {\n      border-width: 0px;\n    }\n\n    .title {\n      color: ", ";\n    }\n  }\n"], ["\n  display: flex;\n  margin-bottom: -40px;\n\n  .rich_menu_template_spinner {\n    height: 55vh;\n  }\n\n  .list-template-group-titles {\n    padding: 20px;\n    margin: 50px 0;\n    background-color: ", ";\n    border-radius: 16px;\n    color: ", ";\n\n    .scroll-to-size {\n      display: block;\n    }\n\n    .spacer {\n      border-bottom: 1px solid ", ";\n    }\n\n    > div:last-of-type {\n      margin-bottom: 0px;\n    }\n  }\n\n  .multiple-templates {\n    .MuiTabs-indicator {\n      background: transparent;\n    }\n\n    .MuiTab-wrapper {\n      text-transform: uppercase;\n      font-size: 14px;\n      font-weight: 500;\n      line-height: 24px;\n      letter-spacing: 1.25px;\n      text-align: left;\n    }\n\n    .MuiTab-root {\n      height: 50px;\n      padding-left: 8px;\n    }\n\n    .MuiBox-root {\n      border-width: 0px;\n    }\n\n    .title {\n      color: ", ";\n    }\n  }\n"])), colors.neutral_grey, colors.midnight, colors.black, colors.midnight);
var Templates = function (_a) {
    var templateWidth = _a.templateWidth, templateHeight = _a.templateHeight, templates = _a.templates, chosenTemplate = _a.chosenTemplate, handleTemplateSelect = _a.handleTemplateSelect;
    return (React.createElement(TemplateContainer, null, Object.entries(templates).map(function (_a) {
        var _b = __read(_a, 2), templateIdStr = _b[0], areas = _b[1];
        var templateId = templateIdStr.replace('template', '');
        return (React.createElement(RichMenuTemplate, { onClick: function () { return handleTemplateSelect(Number(templateId)); }, key: "rich-menu-template-" + templateId, chosen: chosenTemplate === Number(templateId), templateWidth: templateWidth, templateHeight: templateHeight, areas: areas, templateNum: Number(templateId), richMenuAreas: [], richMenuAreaUpdateHandler: function () { return undefined; }, isPreview: true, "data-testid": "DefaultRichMenuTemplateModal_RichMenuTemplate_" + templateId }));
    })));
};
var DefaultRichMenuTemplateModal = function (_a) {
    var intl = _a.intl, onCancel = _a.onCancel, onConfirm = _a.onConfirm;
    var _b = __read(useState(1), 2), chosenTemplate = _b[0], setChosenTemplate = _b[1];
    var _c = __read(useState(0), 2), chosenTemplateGroup = _c[0], setChosenTemplateGroup = _c[1];
    var _d = __read(useState([]), 2), templateGroups = _d[0], setTemplateGroups = _d[1];
    var _e = __read(useState(false), 2), isLoading = _e[0], setIsLoading = _e[1];
    var fetchData = function () {
        setIsLoading(true);
        richMenuApi
            .getRichMenuTemplates()
            .then(function (data) {
            setTemplateGroups(data);
            setIsLoading(false);
        })
            .catch(function (e) {
            console.error('Can not get RichMenu templates: ', e);
            handleAPIErrors(e.message);
            setTemplateGroups([]);
            setIsLoading(false);
        });
    };
    var handleChooseTemplate = function () {
        var templateGroup = templateGroups.find(function (tGroup) {
            var tab0 = tGroup.tab0, tab2 = tGroup.tab2, tab3 = tGroup.tab3;
            return Object.keys(__assign(__assign(__assign({}, tab0), tab2), tab3)).includes("template" + chosenTemplate);
        });
        var width = templateGroup.width, height = templateGroup.height, tab0 = templateGroup.tab0, tab2 = templateGroup.tab2, tab3 = templateGroup.tab3;
        onConfirm(width, height, chosenTemplate, __assign(__assign(__assign({}, tab0), tab2), tab3)["template" + chosenTemplate]);
    };
    useEffect(fetchData, []);
    var TemplateTabs = function (templateGroup) {
        var width = templateGroup.width, height = templateGroup.height;
        var tabItems = [0, 2, 3].map(function (tabKey) { return ({
            label: intl.formatMessage(richMenuMessages["tabs_" + tabKey]),
            children: (React.createElement(Templates, { templateWidth: width, templateHeight: height, templates: templateGroup["tab" + tabKey], chosenTemplate: chosenTemplate, handleTemplateSelect: function (value) { return setChosenTemplate(value); } })),
        }); });
        return React.createElement(Tabs, { tabItems: tabItems, disableRipple: true });
    };
    var actions = (React.createElement(React.Fragment, null,
        React.createElement(Button, { color: "primary", onClick: onCancel }, intl.formatMessage({ id: "47FYwb", defaultMessage: "Cancel" })),
        React.createElement(Button, { color: "primary", size: "large", variant: "contained", onClick: function () {
                onCancel();
                handleChooseTemplate();
            } }, intl.formatMessage({ id: "sc2DN9", defaultMessage: "Use Template" }))));
    var classes = useStyles();
    return (React.createElement(Modal, { "data-testid": "rich-menu-template-modal", onClose: onCancel, title: intl.formatMessage({ id: "dPg/FC", defaultMessage: "Select Size, Tabs and Template" }), actions: actions, open: true, classes: classes },
        React.createElement(ModalBody, null, isLoading ? (React.createElement(Spinner, { className: "rich_menu_template_spinner" })) : (React.createElement(React.Fragment, null,
            React.createElement(ListTemplateGroupTitles, { chosenTemplateGroup: chosenTemplateGroup, templateGroupTitles: templateGroups.map(function (_a) {
                    var width = _a.width, height = _a.height;
                    return width + " x " + height;
                }), indexSpacers: [2], handleTemplateGroupSelect: function (value) {
                    return setChosenTemplateGroup(value);
                } }),
            React.createElement(ListTemplateGroups, { chosenTemplateGroup: chosenTemplateGroup, templateGroups: templateGroups, setChosenTemplateGroup: function (value) { return setChosenTemplateGroup(value); } }, TemplateTabs))))));
};
export default injectIntl(DefaultRichMenuTemplateModal);
var templateObject_1, templateObject_2;
