// source: entities/style.proto

/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

/* eslint-disable */
// @ts-nocheck
var jspb = require('google-protobuf');

var goog = jspb;
var global = Function('return this')();
goog.exportSymbol('proto.e.AlignItems', null, global);
goog.exportSymbol('proto.e.AlignSelf', null, global);
goog.exportSymbol('proto.e.CSSProperties', null, global);
goog.exportSymbol('proto.e.Display', null, global);
goog.exportSymbol('proto.e.FlexDirection', null, global);
goog.exportSymbol('proto.e.FontStyle', null, global);
goog.exportSymbol('proto.e.JustifyContent', null, global);
goog.exportSymbol('proto.e.JustifySelf', null, global);
goog.exportSymbol('proto.e.ObjectFit', null, global);
goog.exportSymbol('proto.e.TextDecoration', null, global);
goog.exportSymbol('proto.e.WhiteSpace', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */

proto.e.CSSProperties = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.e.CSSProperties, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.e.CSSProperties.displayName = 'proto.e.CSSProperties';
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.e.CSSProperties.prototype.toObject = function (opt_includeInstance) {
    return proto.e.CSSProperties.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.e.CSSProperties} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.e.CSSProperties.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      display: jspb.Message.getFieldWithDefault(msg, 1, 0),
      height: jspb.Message.getFieldWithDefault(msg, 2, 0),
      width: jspb.Message.getFieldWithDefault(msg, 3, 0),
      color: jspb.Message.getFieldWithDefault(msg, 4, ""),
      margin: jspb.Message.getFieldWithDefault(msg, 5, 0),
      backgroundColor: jspb.Message.getFieldWithDefault(msg, 6, ""),
      borderWidth: jspb.Message.getFieldWithDefault(msg, 7, 0),
      borderRadius: jspb.Message.getFieldWithDefault(msg, 8, 0),
      flexDirection: jspb.Message.getFieldWithDefault(msg, 9, 0),
      justifyContent: jspb.Message.getFieldWithDefault(msg, 10, 0),
      alignItems: jspb.Message.getFieldWithDefault(msg, 11, 0),
      flex: jspb.Message.getFieldWithDefault(msg, 12, 0),
      padding: jspb.Message.getFieldWithDefault(msg, 13, 0),
      gap: jspb.Message.getFieldWithDefault(msg, 14, 0),
      fontStyle: jspb.Message.getFieldWithDefault(msg, 15, 0),
      fontWeight: jspb.Message.getFieldWithDefault(msg, 16, 0),
      fontSize: jspb.Message.getFieldWithDefault(msg, 17, 0),
      textDecoration: jspb.Message.getFieldWithDefault(msg, 18, 0),
      lineClamp: jspb.Message.getFieldWithDefault(msg, 19, 0),
      whiteSpace: jspb.Message.getFieldWithDefault(msg, 20, 0),
      alignSelf: jspb.Message.getFieldWithDefault(msg, 21, 0),
      justifySelf: jspb.Message.getFieldWithDefault(msg, 22, 0),
      aspectRatio: jspb.Message.getFieldWithDefault(msg, 23, ""),
      objectFit: jspb.Message.getFieldWithDefault(msg, 24, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.e.CSSProperties}
 */


proto.e.CSSProperties.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.e.CSSProperties();
  return proto.e.CSSProperties.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.e.CSSProperties} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.e.CSSProperties}
 */


proto.e.CSSProperties.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value =
        /** @type {!proto.e.Display} */
        reader.readEnum();
        msg.setDisplay(value);
        break;

      case 2:
        var value =
        /** @type {number} */
        reader.readUint32();
        msg.setHeight(value);
        break;

      case 3:
        var value =
        /** @type {number} */
        reader.readUint32();
        msg.setWidth(value);
        break;

      case 4:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setColor(value);
        break;

      case 5:
        var value =
        /** @type {number} */
        reader.readUint32();
        msg.setMargin(value);
        break;

      case 6:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setBackgroundColor(value);
        break;

      case 7:
        var value =
        /** @type {number} */
        reader.readUint32();
        msg.setBorderWidth(value);
        break;

      case 8:
        var value =
        /** @type {number} */
        reader.readUint32();
        msg.setBorderRadius(value);
        break;

      case 9:
        var value =
        /** @type {!proto.e.FlexDirection} */
        reader.readEnum();
        msg.setFlexDirection(value);
        break;

      case 10:
        var value =
        /** @type {!proto.e.JustifyContent} */
        reader.readEnum();
        msg.setJustifyContent(value);
        break;

      case 11:
        var value =
        /** @type {!proto.e.AlignItems} */
        reader.readEnum();
        msg.setAlignItems(value);
        break;

      case 12:
        var value =
        /** @type {number} */
        reader.readUint32();
        msg.setFlex(value);
        break;

      case 13:
        var value =
        /** @type {number} */
        reader.readUint32();
        msg.setPadding(value);
        break;

      case 14:
        var value =
        /** @type {number} */
        reader.readUint32();
        msg.setGap(value);
        break;

      case 15:
        var value =
        /** @type {!proto.e.FontStyle} */
        reader.readEnum();
        msg.setFontStyle(value);
        break;

      case 16:
        var value =
        /** @type {number} */
        reader.readUint32();
        msg.setFontWeight(value);
        break;

      case 17:
        var value =
        /** @type {number} */
        reader.readUint32();
        msg.setFontSize(value);
        break;

      case 18:
        var value =
        /** @type {!proto.e.TextDecoration} */
        reader.readEnum();
        msg.setTextDecoration(value);
        break;

      case 19:
        var value =
        /** @type {number} */
        reader.readUint32();
        msg.setLineClamp(value);
        break;

      case 20:
        var value =
        /** @type {!proto.e.WhiteSpace} */
        reader.readEnum();
        msg.setWhiteSpace(value);
        break;

      case 21:
        var value =
        /** @type {!proto.e.AlignSelf} */
        reader.readEnum();
        msg.setAlignSelf(value);
        break;

      case 22:
        var value =
        /** @type {!proto.e.JustifySelf} */
        reader.readEnum();
        msg.setJustifySelf(value);
        break;

      case 23:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setAspectRatio(value);
        break;

      case 24:
        var value =
        /** @type {!proto.e.ObjectFit} */
        reader.readEnum();
        msg.setObjectFit(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.e.CSSProperties.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.e.CSSProperties.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.e.CSSProperties} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.e.CSSProperties.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getDisplay();

  if (f !== 0.0) {
    writer.writeEnum(1, f);
  }

  f = message.getHeight();

  if (f !== 0) {
    writer.writeUint32(2, f);
  }

  f = message.getWidth();

  if (f !== 0) {
    writer.writeUint32(3, f);
  }

  f = message.getColor();

  if (f.length > 0) {
    writer.writeString(4, f);
  }

  f = message.getMargin();

  if (f !== 0) {
    writer.writeUint32(5, f);
  }

  f = message.getBackgroundColor();

  if (f.length > 0) {
    writer.writeString(6, f);
  }

  f = message.getBorderWidth();

  if (f !== 0) {
    writer.writeUint32(7, f);
  }

  f = message.getBorderRadius();

  if (f !== 0) {
    writer.writeUint32(8, f);
  }

  f = message.getFlexDirection();

  if (f !== 0.0) {
    writer.writeEnum(9, f);
  }

  f = message.getJustifyContent();

  if (f !== 0.0) {
    writer.writeEnum(10, f);
  }

  f = message.getAlignItems();

  if (f !== 0.0) {
    writer.writeEnum(11, f);
  }

  f = message.getFlex();

  if (f !== 0) {
    writer.writeUint32(12, f);
  }

  f = message.getPadding();

  if (f !== 0) {
    writer.writeUint32(13, f);
  }

  f = message.getGap();

  if (f !== 0) {
    writer.writeUint32(14, f);
  }

  f = message.getFontStyle();

  if (f !== 0.0) {
    writer.writeEnum(15, f);
  }

  f = message.getFontWeight();

  if (f !== 0) {
    writer.writeUint32(16, f);
  }

  f = message.getFontSize();

  if (f !== 0) {
    writer.writeUint32(17, f);
  }

  f = message.getTextDecoration();

  if (f !== 0.0) {
    writer.writeEnum(18, f);
  }

  f = message.getLineClamp();

  if (f !== 0) {
    writer.writeUint32(19, f);
  }

  f = message.getWhiteSpace();

  if (f !== 0.0) {
    writer.writeEnum(20, f);
  }

  f = message.getAlignSelf();

  if (f !== 0.0) {
    writer.writeEnum(21, f);
  }

  f = message.getJustifySelf();

  if (f !== 0.0) {
    writer.writeEnum(22, f);
  }

  f = message.getAspectRatio();

  if (f.length > 0) {
    writer.writeString(23, f);
  }

  f = message.getObjectFit();

  if (f !== 0.0) {
    writer.writeEnum(24, f);
  }
};
/**
 * optional Display display = 1;
 * @return {!proto.e.Display}
 */


proto.e.CSSProperties.prototype.getDisplay = function () {
  return (
    /** @type {!proto.e.Display} */
    jspb.Message.getFieldWithDefault(this, 1, 0)
  );
};
/**
 * @param {!proto.e.Display} value
 * @return {!proto.e.CSSProperties} returns this
 */


proto.e.CSSProperties.prototype.setDisplay = function (value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};
/**
 * optional uint32 height = 2;
 * @return {number}
 */


proto.e.CSSProperties.prototype.getHeight = function () {
  return (
    /** @type {number} */
    jspb.Message.getFieldWithDefault(this, 2, 0)
  );
};
/**
 * @param {number} value
 * @return {!proto.e.CSSProperties} returns this
 */


proto.e.CSSProperties.prototype.setHeight = function (value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};
/**
 * optional uint32 width = 3;
 * @return {number}
 */


proto.e.CSSProperties.prototype.getWidth = function () {
  return (
    /** @type {number} */
    jspb.Message.getFieldWithDefault(this, 3, 0)
  );
};
/**
 * @param {number} value
 * @return {!proto.e.CSSProperties} returns this
 */


proto.e.CSSProperties.prototype.setWidth = function (value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};
/**
 * optional string color = 4;
 * @return {string}
 */


proto.e.CSSProperties.prototype.getColor = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 4, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.e.CSSProperties} returns this
 */


proto.e.CSSProperties.prototype.setColor = function (value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};
/**
 * optional uint32 margin = 5;
 * @return {number}
 */


proto.e.CSSProperties.prototype.getMargin = function () {
  return (
    /** @type {number} */
    jspb.Message.getFieldWithDefault(this, 5, 0)
  );
};
/**
 * @param {number} value
 * @return {!proto.e.CSSProperties} returns this
 */


proto.e.CSSProperties.prototype.setMargin = function (value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};
/**
 * optional string background_color = 6;
 * @return {string}
 */


proto.e.CSSProperties.prototype.getBackgroundColor = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 6, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.e.CSSProperties} returns this
 */


proto.e.CSSProperties.prototype.setBackgroundColor = function (value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};
/**
 * optional uint32 border_width = 7;
 * @return {number}
 */


proto.e.CSSProperties.prototype.getBorderWidth = function () {
  return (
    /** @type {number} */
    jspb.Message.getFieldWithDefault(this, 7, 0)
  );
};
/**
 * @param {number} value
 * @return {!proto.e.CSSProperties} returns this
 */


proto.e.CSSProperties.prototype.setBorderWidth = function (value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};
/**
 * optional uint32 border_radius = 8;
 * @return {number}
 */


proto.e.CSSProperties.prototype.getBorderRadius = function () {
  return (
    /** @type {number} */
    jspb.Message.getFieldWithDefault(this, 8, 0)
  );
};
/**
 * @param {number} value
 * @return {!proto.e.CSSProperties} returns this
 */


proto.e.CSSProperties.prototype.setBorderRadius = function (value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};
/**
 * optional FlexDirection flex_direction = 9;
 * @return {!proto.e.FlexDirection}
 */


proto.e.CSSProperties.prototype.getFlexDirection = function () {
  return (
    /** @type {!proto.e.FlexDirection} */
    jspb.Message.getFieldWithDefault(this, 9, 0)
  );
};
/**
 * @param {!proto.e.FlexDirection} value
 * @return {!proto.e.CSSProperties} returns this
 */


proto.e.CSSProperties.prototype.setFlexDirection = function (value) {
  return jspb.Message.setProto3EnumField(this, 9, value);
};
/**
 * optional JustifyContent justify_content = 10;
 * @return {!proto.e.JustifyContent}
 */


proto.e.CSSProperties.prototype.getJustifyContent = function () {
  return (
    /** @type {!proto.e.JustifyContent} */
    jspb.Message.getFieldWithDefault(this, 10, 0)
  );
};
/**
 * @param {!proto.e.JustifyContent} value
 * @return {!proto.e.CSSProperties} returns this
 */


proto.e.CSSProperties.prototype.setJustifyContent = function (value) {
  return jspb.Message.setProto3EnumField(this, 10, value);
};
/**
 * optional AlignItems align_items = 11;
 * @return {!proto.e.AlignItems}
 */


proto.e.CSSProperties.prototype.getAlignItems = function () {
  return (
    /** @type {!proto.e.AlignItems} */
    jspb.Message.getFieldWithDefault(this, 11, 0)
  );
};
/**
 * @param {!proto.e.AlignItems} value
 * @return {!proto.e.CSSProperties} returns this
 */


proto.e.CSSProperties.prototype.setAlignItems = function (value) {
  return jspb.Message.setProto3EnumField(this, 11, value);
};
/**
 * optional uint32 flex = 12;
 * @return {number}
 */


proto.e.CSSProperties.prototype.getFlex = function () {
  return (
    /** @type {number} */
    jspb.Message.getFieldWithDefault(this, 12, 0)
  );
};
/**
 * @param {number} value
 * @return {!proto.e.CSSProperties} returns this
 */


proto.e.CSSProperties.prototype.setFlex = function (value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};
/**
 * optional uint32 padding = 13;
 * @return {number}
 */


proto.e.CSSProperties.prototype.getPadding = function () {
  return (
    /** @type {number} */
    jspb.Message.getFieldWithDefault(this, 13, 0)
  );
};
/**
 * @param {number} value
 * @return {!proto.e.CSSProperties} returns this
 */


proto.e.CSSProperties.prototype.setPadding = function (value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};
/**
 * optional uint32 gap = 14;
 * @return {number}
 */


proto.e.CSSProperties.prototype.getGap = function () {
  return (
    /** @type {number} */
    jspb.Message.getFieldWithDefault(this, 14, 0)
  );
};
/**
 * @param {number} value
 * @return {!proto.e.CSSProperties} returns this
 */


proto.e.CSSProperties.prototype.setGap = function (value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};
/**
 * optional FontStyle font_style = 15;
 * @return {!proto.e.FontStyle}
 */


proto.e.CSSProperties.prototype.getFontStyle = function () {
  return (
    /** @type {!proto.e.FontStyle} */
    jspb.Message.getFieldWithDefault(this, 15, 0)
  );
};
/**
 * @param {!proto.e.FontStyle} value
 * @return {!proto.e.CSSProperties} returns this
 */


proto.e.CSSProperties.prototype.setFontStyle = function (value) {
  return jspb.Message.setProto3EnumField(this, 15, value);
};
/**
 * optional uint32 font_weight = 16;
 * @return {number}
 */


proto.e.CSSProperties.prototype.getFontWeight = function () {
  return (
    /** @type {number} */
    jspb.Message.getFieldWithDefault(this, 16, 0)
  );
};
/**
 * @param {number} value
 * @return {!proto.e.CSSProperties} returns this
 */


proto.e.CSSProperties.prototype.setFontWeight = function (value) {
  return jspb.Message.setProto3IntField(this, 16, value);
};
/**
 * optional uint32 font_size = 17;
 * @return {number}
 */


proto.e.CSSProperties.prototype.getFontSize = function () {
  return (
    /** @type {number} */
    jspb.Message.getFieldWithDefault(this, 17, 0)
  );
};
/**
 * @param {number} value
 * @return {!proto.e.CSSProperties} returns this
 */


proto.e.CSSProperties.prototype.setFontSize = function (value) {
  return jspb.Message.setProto3IntField(this, 17, value);
};
/**
 * optional TextDecoration text_decoration = 18;
 * @return {!proto.e.TextDecoration}
 */


proto.e.CSSProperties.prototype.getTextDecoration = function () {
  return (
    /** @type {!proto.e.TextDecoration} */
    jspb.Message.getFieldWithDefault(this, 18, 0)
  );
};
/**
 * @param {!proto.e.TextDecoration} value
 * @return {!proto.e.CSSProperties} returns this
 */


proto.e.CSSProperties.prototype.setTextDecoration = function (value) {
  return jspb.Message.setProto3EnumField(this, 18, value);
};
/**
 * optional uint32 line_clamp = 19;
 * @return {number}
 */


proto.e.CSSProperties.prototype.getLineClamp = function () {
  return (
    /** @type {number} */
    jspb.Message.getFieldWithDefault(this, 19, 0)
  );
};
/**
 * @param {number} value
 * @return {!proto.e.CSSProperties} returns this
 */


proto.e.CSSProperties.prototype.setLineClamp = function (value) {
  return jspb.Message.setProto3IntField(this, 19, value);
};
/**
 * optional WhiteSpace white_space = 20;
 * @return {!proto.e.WhiteSpace}
 */


proto.e.CSSProperties.prototype.getWhiteSpace = function () {
  return (
    /** @type {!proto.e.WhiteSpace} */
    jspb.Message.getFieldWithDefault(this, 20, 0)
  );
};
/**
 * @param {!proto.e.WhiteSpace} value
 * @return {!proto.e.CSSProperties} returns this
 */


proto.e.CSSProperties.prototype.setWhiteSpace = function (value) {
  return jspb.Message.setProto3EnumField(this, 20, value);
};
/**
 * optional AlignSelf align_self = 21;
 * @return {!proto.e.AlignSelf}
 */


proto.e.CSSProperties.prototype.getAlignSelf = function () {
  return (
    /** @type {!proto.e.AlignSelf} */
    jspb.Message.getFieldWithDefault(this, 21, 0)
  );
};
/**
 * @param {!proto.e.AlignSelf} value
 * @return {!proto.e.CSSProperties} returns this
 */


proto.e.CSSProperties.prototype.setAlignSelf = function (value) {
  return jspb.Message.setProto3EnumField(this, 21, value);
};
/**
 * optional JustifySelf justify_self = 22;
 * @return {!proto.e.JustifySelf}
 */


proto.e.CSSProperties.prototype.getJustifySelf = function () {
  return (
    /** @type {!proto.e.JustifySelf} */
    jspb.Message.getFieldWithDefault(this, 22, 0)
  );
};
/**
 * @param {!proto.e.JustifySelf} value
 * @return {!proto.e.CSSProperties} returns this
 */


proto.e.CSSProperties.prototype.setJustifySelf = function (value) {
  return jspb.Message.setProto3EnumField(this, 22, value);
};
/**
 * optional string aspect_ratio = 23;
 * @return {string}
 */


proto.e.CSSProperties.prototype.getAspectRatio = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 23, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.e.CSSProperties} returns this
 */


proto.e.CSSProperties.prototype.setAspectRatio = function (value) {
  return jspb.Message.setProto3StringField(this, 23, value);
};
/**
 * optional ObjectFit object_fit = 24;
 * @return {!proto.e.ObjectFit}
 */


proto.e.CSSProperties.prototype.getObjectFit = function () {
  return (
    /** @type {!proto.e.ObjectFit} */
    jspb.Message.getFieldWithDefault(this, 24, 0)
  );
};
/**
 * @param {!proto.e.ObjectFit} value
 * @return {!proto.e.CSSProperties} returns this
 */


proto.e.CSSProperties.prototype.setObjectFit = function (value) {
  return jspb.Message.setProto3EnumField(this, 24, value);
};
/**
 * @enum {number}
 */


proto.e.Display = {
  DISPLAY_UNSPECIFIED: 0,
  DISPLAY_FLEX: 1
};
/**
 * @enum {number}
 */

proto.e.FlexDirection = {
  FLEX_DIRECTION_UNSPECIFIED: 0,
  FLEX_DIRECTION_COLUMN: 1,
  FLEX_DIRECTION_ROW: 2
};
/**
 * @enum {number}
 */

proto.e.JustifyContent = {
  JUSTIFY_CONTENT_UNSPECIFIED: 0,
  JUSTIFY_CONTENT_CENTER: 1,
  JUSTIFY_CONTENT_FLEX_START: 2,
  JUSTIFY_CONTENT_FLEX_END: 3,
  JUSTIFY_CONTENT_SPACE_BETWEEN: 4,
  JUSTIFY_CONTENT_SPACE_AROUND: 5,
  JUSTIFY_CONTENT_SPACE_EVENLY: 6
};
/**
 * @enum {number}
 */

proto.e.AlignItems = {
  ALIGN_ITEMS_UNSPECIFIED: 0,
  ALIGN_ITEMS_CENTER: 1,
  ALIGN_ITEMS_FLEX_START: 2,
  ALIGN_ITEMS_FLEX_END: 3
};
/**
 * @enum {number}
 */

proto.e.AlignSelf = {
  ALIGN_SELF_UNSPECIFIED: 0,
  ALIGN_SELF_CENTER: 1,
  ALIGN_SELF_FLEX_START: 2,
  ALIGN_SELF_FLEX_END: 3
};
/**
 * @enum {number}
 */

proto.e.JustifySelf = {
  JUSTIFY_SELF_UNSPECIFIED: 0,
  JUSTIFY_SELF_CENTER: 1,
  JUSTIFY_SELF_FLEX_START: 2,
  JUSTIFY_SELF_FLEX_END: 3
};
/**
 * @enum {number}
 */

proto.e.FontStyle = {
  FONT_STYLE_UNSPECIFIED: 0,
  FONT_STYLE_ITALIC: 1,
  FONT_STYLE_OBLIQUE: 2
};
/**
 * @enum {number}
 */

proto.e.TextDecoration = {
  TEXT_DECORATION_UNSPECIFIED: 0,
  TEXT_DECORATION_UNDERLINE: 1,
  TEXT_DECORATION_LINE_THROUGH: 2
};
/**
 * @enum {number}
 */

proto.e.ObjectFit = {
  OBJECT_FIT_UNSPECIFIED: 0,
  OBJECT_FIT_FILL: 1,
  OBJECT_FIT_CONTAIN: 2,
  OBJECT_FIT_COVER: 3,
  OBJECT_FIT_SCALE_DOWN: 4
};
/**
 * @enum {number}
 */

proto.e.WhiteSpace = {
  WHITE_SPACE_UNSPECIFIED: 0,
  WHITE_SPACE_NORMAL: 1,
  WHITE_SPACE_NO_WRAP: 2,
  WHITE_SPACE_PRE: 3,
  WHITE_SPACE_PRE_WRAP: 4,
  WHITE_SPACE_PRE_LINE: 5,
  WHITE_SPACE_BREAK_SPACES: 6
};
goog.object.extend(exports, proto.e);