var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { Composition } from 'atomic-layout';
import styled from 'styled-components';
import { colors } from '@src/colors';
import { hoverMenuBg, hoverDelete } from '@src/lib/mixins';
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  border-bottom: 1px solid ", ";\n  background: ", ";\n"], ["\n  border-bottom: 1px solid ", ";\n  background: ", ";\n"])), colors.border.default, colors.background.main_menu);
var DeletePluginButtonWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  padding: 1rem;\n  text-align: center;\n  font-size: 1.5rem;\n  ", ";\n"], ["\n  padding: 1rem;\n  text-align: center;\n  font-size: 1.5rem;\n  ", ";\n"])), hoverDelete);
var Wrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  ", ";\n  p {\n    color: ", ";\n    margin: 0;\n  }\n"], ["\n  ", ";\n  p {\n    color: ", ";\n    margin: 0;\n  }\n"])), hoverMenuBg, colors.text_pale);
var Plugin = function (_a) {
    var onClick = _a.onClick, onDelete = _a.onDelete, plugin = __rest(_a, ["onClick", "onDelete"]);
    return (React.createElement(Container, null,
        React.createElement(Composition, { as: Wrapper, templateCols: "5% 40% 40% 15%", alignItems: "center", justifyItems: "center", padding: '0.5rem', onClick: onClick },
            React.createElement("p", null, plugin.id),
            React.createElement("p", null, plugin.name),
            React.createElement("p", null, plugin.uid),
            React.createElement(DeletePluginButtonWrapper, null,
                React.createElement("span", { className: "adicon_delete icon_style", onClick: onDelete })))));
};
export default Plugin;
var templateObject_1, templateObject_2, templateObject_3;
