var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import API from '@src/endpoints';
import { DragSource, DropTarget, } from 'react-dnd';
import flow from 'lodash/flow';
import { centeredFlex, hoverDelete, hoverHighlight } from '@src/lib/mixins';
import { colors } from '@src/colors';
import { transitions } from '@src/lib/animations';
import { fanpFetch, makeFetchInit, handleAPIErrors } from '@src/lib/helpers';
import QuestionModal from '@src/components/organisms/custom_form_admin/modals/QuestionModal';
var QuestionWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  transition: ", ";\n  &:active {\n    cursor: grabbing;\n  }\n  .adicon_setting,\n  .adicon_delete {\n    display: flex;\n    color: ", ";\n    font-size: 24px;\n    margin-left: 1rem;\n    border-radius: 50%;\n    height: 40px;\n    width: 40px;\n    padding: 8px;\n    &:hover {\n      background: ", ";\n    }\n  }\n  .adicon_setting {\n    ", "\n  }\n  .adicon_delete {\n    ", ";\n  }\n"], ["\n  ", ";\n  transition: ", ";\n  &:active {\n    cursor: grabbing;\n  }\n  .adicon_setting,\n  .adicon_delete {\n    display: flex;\n    color: ", ";\n    font-size: 24px;\n    margin-left: 1rem;\n    border-radius: 50%;\n    height: 40px;\n    width: 40px;\n    padding: 8px;\n    &:hover {\n      background: ", ";\n    }\n  }\n  .adicon_setting {\n    ", "\n  }\n  .adicon_delete {\n    ", ";\n  }\n"])), centeredFlex, transitions.normal, colors.custom_form_admin.icon, colors.custom_form_admin.gray_white, hoverHighlight, hoverDelete);
var getType = function (_a) {
    var sortable = _a.sortable;
    return sortable ? 'SORTABLE_QUESTION' : 'QUESTION';
};
var questionSource = {
    beginDrag: function (props) {
        return {
            name: props.name,
            index: props.index,
        };
    },
    endDrag: function (props, monitor) {
        if (monitor.getItemType() === 'SORTABLE_QUESTION') {
            var customFormId = props.customFormId, questionGroup = props.questionGroup;
            var url = API.customFormQuestionGroups.zealsAdmin.updateRanks(customFormId, questionGroup.id);
            fanpFetch(url, makeFetchInit('PUT', {
                custom_form_questions: questionGroup.custom_form_questions,
            })).catch(function (e) { return handleAPIErrors('error', e); });
        }
    },
};
var questionTarget = {
    hover: function (props, monitor, component) {
        var selectedForm = props.selectedForm, questionGroup = props.questionGroup;
        var dragIndex = monitor.getItem().index;
        var hoverIndex = props.index;
        if (dragIndex === hoverIndex) {
            return;
        }
        // eslint-disable-next-line react/no-find-dom-node
        var domNode = ReactDOM.findDOMNode(component);
        // Determine rectangle on screen
        var hoverBoundingRect = domNode.getBoundingClientRect();
        // Get vertical middle
        var hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
        // Determine mouse position
        var clientOffset = monitor.getClientOffset();
        // Get pixels to the top
        var hoverClientY = clientOffset.y - hoverBoundingRect.top;
        // Only perform the move when the mouse has crossed half of the items height
        // When dragging downwards, only move when the cursor is below 50%
        // When dragging upwards, only move when the cursor is above 50%
        // Dragging downwards
        if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
            return;
        }
        // Dragging upwards
        if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
            return;
        }
        // Time to actually perform the action
        if (props.moveQuestion) {
            props.moveQuestion({
                formId: selectedForm.id,
                questionGroupId: questionGroup.id,
                dragIndex: dragIndex,
                hoverIndex: hoverIndex,
            });
        }
        // Note: we're mutating the monitor item here!
        // Generally it's better to avoid mutations,
        // but it's good here for the sake of performance
        // to avoid expensive index searches.
        monitor.getItem().index = hoverIndex;
    },
};
function collect(connect, monitor) {
    return {
        connectDragSource: connect.dragSource(),
        connectDragPreview: connect.dragPreview(),
        isDragging: monitor.isDragging,
    };
}
var Question = /** @class */ (function (_super) {
    __extends(Question, _super);
    function Question(props) {
        var _this = _super.call(this, props) || this;
        _this.toggleUpdateModal = function () {
            return _this.setState(function (prevState) { return ({
                updateModalOpened: !prevState.updateModalOpened,
            }); });
        };
        _this.state = { updateModalOpened: false };
        return _this;
    }
    Question.prototype.render = function () {
        var _a = this.props, connectDragSource = _a.connectDragSource, connectDropTarget = _a.connectDropTarget, sortable = _a.sortable, question = _a.question, removeQuestion = _a.removeQuestion, children = _a.children, isQuestionList = _a.isQuestionList, rest = __rest(_a, ["connectDragSource", "connectDropTarget", "sortable", "question", "removeQuestion", "children", "isQuestionList"]);
        return (React.createElement(React.Fragment, null,
            this.state.updateModalOpened ? (React.createElement(QuestionModal, __assign({ onCancel: this.toggleUpdateModal }, question, rest))) : null,
            connectDragSource(connectDropTarget(isQuestionList ? (React.createElement("tr", null,
                React.createElement("td", null, question && question.id),
                React.createElement("td", null, question && question.label),
                React.createElement("td", null, question && question.data_type),
                React.createElement("td", null, question && question.validation_type),
                React.createElement("td", null, question && question.validation_name),
                React.createElement("td", null, question && question.max_answer_num),
                React.createElement("td", { className: "pull_right" },
                    React.createElement(QuestionWrapper, null, sortable ? (React.createElement(React.Fragment, null,
                        React.createElement("span", { role: "button", tabIndex: 0, "aria-label": "Open Update Modal", className: "adicon_setting", onClick: this.toggleUpdateModal }),
                        React.createElement("span", { role: "button", tabIndex: 0, "aria-label": "Delete question", className: "adicon_delete", onClick: function () {
                                var confirmMsg = 'このquestion 本当に削除してもよろしいですか？';
                                if (window.confirm(confirmMsg)) {
                                    removeQuestion();
                                }
                            } }))) : null)))) : (React.createElement("div", null,
                React.createElement(QuestionWrapper, null, children)))))));
    };
    return Question;
}(Component));
export default flow(DragSource(getType, questionSource, collect), DropTarget(getType, questionTarget, function (connect) { return ({
    connectDropTarget: connect.dropTarget(),
}); }))(Question);
var templateObject_1;
