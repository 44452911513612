import { select } from 'd3';
import { colors } from '@src/colors';
import { BUTTON_HEIGHT, CHOICE_HEIGHT, CHOICE_WIDTH, CHOICE_MARGIN, CHOICE_PADDING, BORDER_RADIUS, FONT_SIZE, } from './constants';
// interface QuickReplyInterface {
//   getWidth(): number;
//   getHeight(): number;
//   calcChoiceX(prevX: number, choiceWidth: number): number;
//   render(x: number, y: number): void;
// }
var QuickReply = /** @class */ (function () {
    function QuickReply(block, quickReply) {
        this.block = block;
        this.quickReply = quickReply;
        this.msgType = 'quickReply';
    }
    QuickReply.prototype.getWidth = function () {
        return (this.quickReply.quick_reply_choices.length *
            (CHOICE_WIDTH + CHOICE_MARGIN));
    };
    QuickReply.prototype.getHeight = function () {
        return CHOICE_HEIGHT;
    };
    QuickReply.prototype.calcChoiceX = function (prevX, choiceWidth) {
        return prevX + choiceWidth + CHOICE_MARGIN;
    };
    QuickReply.prototype.render = function (x, y) {
        var _this = this;
        var prevX = x;
        var choiceWidth = 0;
        this.quickReply.quick_reply_choices.forEach(function (choice) {
            var choiceX = _this.calcChoiceX(prevX, choiceWidth);
            // choice container
            select(_this.block)
                .append('rect')
                .classed('message', true)
                .on('mousedown', function (e) {
                e.stopPropagation();
            })
                .classed('quick-reply', true)
                .attr('id', "quick-reply-container-" + choice.id)
                .attr('x', choiceX)
                .attr('y', y)
                .attr('rx', BORDER_RADIUS)
                .attr('ry', BORDER_RADIUS)
                .attr('height', BUTTON_HEIGHT)
                .attr('fill', colors.quick_reply)
                .style('stroke', colors.quick_reply);
            // choice text
            select(_this.block)
                .append('text')
                .attr('id', "quick-reply-text-" + choice.id)
                .on('mousedown', function (e) {
                e.stopPropagation();
            })
                .attr('class', 'messageText')
                .text(function () { return choice.name || 'No Title'; })
                .attr('fill', 'white')
                .attr('x', choiceX + CHOICE_PADDING)
                .attr('y', y + CHOICE_PADDING + FONT_SIZE / 2);
            choiceWidth =
                select("#quick-reply-text-" + choice.id).node().getBoundingClientRect()
                    .width +
                    CHOICE_PADDING * 2;
            select("#quick-reply-container-" + choice.id).attr('width', choiceWidth);
            prevX = choiceX;
        });
    };
    return QuickReply;
}());
export default QuickReply;
