var collection = function collection(_ref) {
  var chatbotId = _ref.chatbotId;
  return "/chatbots/".concat(chatbotId, "/uploaded_files");
};

var member = function member(_ref2) {
  var chatbotId = _ref2.chatbotId;
  return "".concat(collection({
    chatbotId: chatbotId
  }), "/bulk_destroy");
};

export default {
  collection: collection,
  member: member
};