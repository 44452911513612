var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a, _b, _c;
import { defineMessages } from 'react-intl';
// TODO it will convert to type script later
export var RETRY_AMOUNT = 3;
export var FEW_RETRY_AMOUNT = 1;
export var DEBOUNCE_TIME = 1000;
export var DEBOUNCE_TIME_SMALL = 500;
export var K_BYTES = 1024;
export var TAB_0_COLOR = 8;
export var RICH_MENU_LABEL_MAX_LENGTH_FROM_JR_MODEL = 255;
export var IMAGE_MAP_ALT_TEXT_MAX_LENGTH_FROM_JR_MODEL = 400;
export var IMAGE_MAP_TEMPLATE_NUMBER_NO_ACTION = 14;
export var IMAGE_MAP_TEMPLATE_NUMBER_NO_ACTION_GROUP_INDEX = 1;
export var URL_SMART_REPLACE_LIST = [
    '{hash_id}',
    '{end_user_id}',
    '{chatbot_id}',
];
export var HIGHLIGHT_MESSAGE_DURATION = 3;
export var IMAGE_MAP_TEMPLATE_NUMBER_COUNTRY_MAP = 34;
export var COUNTRY_MAP_IMAGE_URL = '/static/images/map_template_1040x1040.png';
export var buttonTypes = {
    SCENARIO_START: 'scenario_start',
    URL: null,
    BLOCK: 'block',
    SCENARIO: 'scenario',
    CALL: 'call',
    EMAIL: 'email',
    STOCK: 'stock',
    SHARE: 'share',
    SETTINGS_BLOCK: 'delivery_lock',
    SUBMENU: 'submenu',
};
export var postbackActionTypes = {
    BLOCK: 'Block',
    SCENARIO: 'Scenario',
};
export var messageTypes = {
    TEXT: 'text',
    BUTTON_TEMPLATE: 'button_template',
    GENERIC: 'generic',
    RICH_MENU: 'rich_menu',
    MEDIA: 'media',
    LIST: 'list',
    QUICK_REPLY: 'quick_reply',
    FREE_INPUT: 'FREE_INPUT',
    IMAGE_MAP: 'image_map',
    FLEX_MESSAGE: 'flex_message',
};
export var mediaTypes = {
    AUDIO: 'audio',
    VIDEO: 'video',
    IMAGE: 'image',
    FILE: 'file',
    ADD: 'add',
};
export var menuIconClasses = (_a = {},
    _a[messageTypes.TEXT] = 'adicon_msg_text',
    _a[messageTypes.GENERIC] = 'adicon_msg_carousel',
    _a[messageTypes.RICH_MENU] = 'adicon_msg_richmenu',
    _a[messageTypes.IMAGE_MAP] = 'adicon_big_placeholder_image',
    _a[messageTypes.MEDIA] = 'adicon_msg_file',
    _a[messageTypes.FLEX_MESSAGE] = 'adicon_msg_flex',
    _a[messageTypes.LIST] = 'adicon_msg_list',
    _a[messageTypes.QUICK_REPLY] = 'adicon_msg_quickreplay',
    _a[messageTypes.FREE_INPUT] = 'adicon_msg_input',
    _a[mediaTypes.ADD] = 'adicon_plus02',
    _a[mediaTypes.IMAGE] = 'adicon_placeholder_image',
    _a[mediaTypes.VIDEO] = 'adicon_placeholder_video',
    _a[mediaTypes.AUDIO] = 'adicon_placeholder_audio',
    _a);
export var templateNames = {
    TEXT: 'textMessage',
    GENERIC: 'genericMessage',
    MEDIA: 'mediaMessage',
    LIST: 'listMessage',
    QUICK_REPLY: 'quickReply',
    FREE_INPUT: 'freeInput',
    RICH_MENU: 'richMenu',
    IMAGE_MAP: 'image_map',
    FLEX_MESSAGE: 'flex_message',
};
export var statusColors = {
    PURPLE: 'purple',
    BLUE: 'blue',
    YELLOW: 'yellow',
};
export var articleTypes = {
    TITLE: 'title',
    IMAGE_URL: 'image_url',
    URL: 'url',
};
export var deliveryTypes = {
    AWAITING: 'awaiting',
    DELIVERED: 'delivered',
};
export var blockTypes = {
    BLOCK: 'block',
    FORM: 'form',
    PATTERN: 'pattern',
    INITIAL: 'initial',
};
export var talkRoomGeneric = {
    TITLE: '{title}',
    SUBTITLE: '{company_name} {salary}万円',
    URL: '{row_url}',
    IMAGE_URL: '{image_url}',
};
// Maximumステップの「日後」という数字です
// これがなければ、ユーザーがでかい数字を入れれて、
// rubyのActiveModelのバリデーションがおかしくなります
export var MAX_AFTER_DAYS = 10000;
export var SHOT_DELIVER_LIMIT = 2000;
export var buttonTabs = {
    block: {
        jp: 'ブロック',
        en: 'Block',
    },
    scenario: {
        jp: 'シナリオ',
        en: 'Scenario',
    },
    url: {
        jp: 'URL',
        en: 'URL',
    },
    call: {
        jp: 'コール',
        en: 'Call',
    },
    stock: {
        jp: 'ストック',
        en: 'Stock',
    },
    share: {
        jp: 'シェア',
        en: 'Share',
    },
    email: {
        jp: 'メール',
        en: 'Email',
    },
};
export var settingButtonTabs = {
    scenario_start: {
        jp: 'シナリオスタート',
        en: 'Scenario_start',
    },
    url: {
        jp: 'URL',
        en: 'URL',
    },
    stock: {
        jp: 'ストック',
        en: 'Stock',
    },
    settings_block: {
        jp: 'ブロック',
        en: 'Delivery_lock',
    },
    submenu: {
        jp: 'サブメニュー',
        en: 'Submenu',
    },
};
export var accountCorporateArr = function (intl) { return [
    {
        className: 'corporate',
        defaultRadioValue: true,
        formId: 'id_group_form-is_corporate_use_1',
        businessStatusIntlMessage: intl.formatMessage({ id: "Ded4zl", defaultMessage: "Corporate" }),
    },
    {
        className: 'individual',
        defaultRadioValue: false,
        formId: 'id_group_form-is_corporate_use_0',
        businessStatusIntlMessage: intl.formatMessage({ id: "PEmgVF", defaultMessage: "Privately Owned" }),
    },
]; };
export var EmailAccountInputArr = function (intl) { return [
    {
        message: intl.formatMessage({ id: "HAlOn1", defaultMessage: "Name" }),
        refName: 'emailName',
        currentUserKey: 'email_name',
    },
    {
        message: intl.formatMessage({ id: "sy+pv5", defaultMessage: "Email" }),
        refName: 'emailInput',
        currentUserKey: 'email',
    },
]; };
export var FBAccountInputArr = function (intl) { return [
    {
        message: intl.formatMessage({ id: "HAlOn1", defaultMessage: "Name" }),
        refName: 'facebookName',
        currentUserKey: 'facebook_name',
    },
]; };
export var dimensionsImageAspectRatio = {
    SQUARE: 'square',
    HORIZONTAL: 'horizontal',
};
export var adScenarioTypes = defineMessages({ initial: { id: "wuFAhS", defaultMessage: "Initial Conversation" }, form: { id: "+cq91e", defaultMessage: "Form Conversation" }, pattern: { id: "N2GMeZ", defaultMessage: "Pattern Conversation" }, step: { id: "YFdsQK", defaultMessage: "Step Delivery" }, shot: { id: "9hVRIn", defaultMessage: "Short Delivery" }, plain: { id: "pl2Ee4", defaultMessage: "Plain" } });
export var mediaAcceptTypes = (_b = {},
    _b[mediaTypes.IMAGE] = 'image/png,image/jpg,image/gif,image/jpeg',
    _b[mediaTypes.VIDEO] = 'video/mp4',
    _b[mediaTypes.AUDIO] = 'audio/x-m4a',
    _b);
export var mediaPlaceholderIcons = (_c = {},
    _c[mediaTypes.ADD] = 'adicon_plus02',
    _c[mediaTypes.IMAGE] = 'adicon_big_placeholder_image',
    _c[mediaTypes.VIDEO] = 'adicon_big_placeholder_video',
    _c[mediaTypes.AUDIO] = 'adicon_big_placeholder_audio',
    _c);
export var actionTypeOptions = [
    {
        value: 'modal',
        label: 'モーダル',
    },
    {
        value: 'banner',
        label: 'バナー',
    },
    {
        value: 'conversion',
        label: 'CV計測',
    },
    {
        value: 'get_action_history',
        label: '行動履歴取得(URL)',
    },
    {
        value: 'connection_client_member',
        label: '会員ID連携',
    },
    // 今使わないので stay
    // {
    //   value: 'webchat',
    //   label: 'webチャット',
    // },
];
export var insertAdjacentOptions = [
    {
        value: 'beforebegin',
        label: 'タグの前',
    },
    {
        value: 'afterbegin',
        label: 'タグの内側の最初',
    },
    {
        value: 'beforeend',
        label: 'タグの内側の最後',
    },
    {
        value: 'afterend',
        label: 'タグの後',
    },
];
export var pluginActionTypes = {
    modal: 'モーダル',
    banner: 'バナー',
    conversion: 'CV計測',
    get_action_history: '行動履歴取得(URL)',
    connection_client_member: '会員ID連携取得',
    webchat: 'webチャット',
};
export var googleStoreUrl = {
    sandbox: 'https://storage.googleapis.com/zeals-sandbox-jupiter/',
    development: 'https://storage.googleapis.com/zeals-sandbox-jupiter/',
    staging: 'https://storage.googleapis.com/jupiter-static-stg/',
    production: 'https://storage.googleapis.com/jupiter-static-prd/',
};
export var tabTypes = {
    AREA_SETTING: 'area_setting',
    VIDEO_SETTING: 'video_setting',
    AREA_SETTING_WITH_USER_TAG: 'area_setting_with_user_tag',
    URL: 'url',
    CALL: 'call',
    PHONE_NUMBER: 'phone_number',
    EMAIL: 'email',
    FILE: 'file',
    BLOCK: 'block',
    SCENARIO: 'scenario',
    STOCK: 'stock',
    SHARE: 'share',
};
export var templateFrame = {
    width: 340,
    offsetWidth: 80,
    height: 200,
    offsetHeight: 30,
    borderWidth: 5,
};
export var imageMapVerticalTemplateFrame = __assign(__assign({}, templateFrame), { scaleByWidth: true, offsetWidth: 30, messageWidth: 500 });
export var imageMapCountryMapTemplateFrame = __assign(__assign({}, templateFrame), { scaleByWidth: true, height: 340, offsetWidth: 30 });
export var countries = [
    {
        flag: '🇯🇵',
        name: '日本',
        code: '81',
    },
    {
        flag: '🇹🇼',
        name: '台湾',
        code: '886',
    },
    {
        flag: '🇹🇭',
        name: 'タイ',
        code: '66',
    },
    {
        flag: '🇵🇭',
        name: 'フィリピン',
        code: '63',
    },
    {
        flag: '🇻🇳',
        name: 'ベトナム',
        code: '84',
    },
    {
        flag: '🇮🇩',
        name: 'インドネシア',
        code: '62',
    },
    {
        flag: '🇮🇳',
        name: 'インド',
        code: '91',
    },
    {
        flag: '🇸🇬',
        name: 'シンガポール',
        code: '65',
    },
    {
        flag: '🇲🇾',
        name: 'マレーシア',
        code: '60',
    },
    {
        flag: '🇲🇲',
        name: 'ミャンマー',
        code: '95',
    },
    {
        flag: '🇺🇸',
        name: 'アメリカ',
        code: '1',
    },
];
export var uploadStatuses = {
    UPLOADING: 'uploading',
    SUCCESS: 'success',
    FAILED: 'failed',
};
export var triggerTypeOptions = [
    {
        value: 'browser_back',
        label: 'ブラウザバック',
    },
    {
        value: 'onetime_load',
        label: 'ページ遷移のみ',
    },
    {
        value: 'page_load',
        label: 'ページロード時',
    },
    {
        value: 'wait',
        label: '滞在時間',
    },
    {
        value: 'scroll',
        label: 'スクロールの量',
    },
    {
        value: 'click',
        label: '対象HTML要素のクリック',
    },
    {
        value: 'transition_count',
        label: '遷移数（同じドメイン以下）',
    },
    {
        value: 'referer',
        label: '遷移元のURL',
    },
    {
        value: 'metatag',
        label: 'ZEALSのmetaタグが存在したら',
    },
];
export var lineFlexMessageStyles = {
    margins: {
        none: 'none',
        xs: '2px',
        sm: '4px',
        md: '8px',
        lg: '12px',
        xl: '16px',
        xxl: '20px',
    },
    flexDirections: {
        vertical: 'column',
        baseline: 'row',
        horizontal: 'row',
    },
    buttonHeights: {
        sm: '36px',
        md: '47px',
    },
    imageSizes: {
        xxs: '36px',
        xs: '54px',
        sm: '72px',
        md: '90px',
        lg: '108px',
        xl: '126px',
        xxl: '144px',
        '3xl': '162px',
        '4xl': '180px',
        full: '100%',
    },
};
export var bubbleChildTypes = {
    HEADER: 'header',
    HERO: 'hero',
    BODY: 'body',
    FOOTER: 'footer',
};
export var RELEASE_NOTE_URL = 'https://zeals-japan.atlassian.net/wiki/spaces/CP/pages/121700365/Template+Release+Notes+Page?focusedCommentId=146505787#comment-146505787';
export var TRIGGER_DEFAULT_RICH_MENU = 'default_rich_menu';
