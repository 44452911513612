/**
 * Serializes some Array of objects with IDs to a Record style object where objects can be looked up by
 * their IDs as keys
 */
var arrToObj = function (arr) {
    return arr.reduce(function (obj, a) {
        obj[a['id']] = a;
        return obj;
    }, {});
};
export default arrToObj;
