var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { fanpFetch, makeFetchInit, handleAPIErrors } from '@src/lib/helpers';
import endpoints from '@src/endpoints';
import * as scenarioApi from '@src/services/zeals_admin_api/scenario';
import { WorkPanelType } from '../types/workPanel';
export var setScenario = function (scenario) { return ({
    type: WorkPanelType.SET_SCENARIO,
    scenario: scenario,
}); };
export var setEndUsers = function (endUsers, totalEndUsers) { return ({
    type: WorkPanelType.SET_END_USERS,
    endUsers: endUsers,
    totalEndUsers: totalEndUsers,
}); };
export var setScenarios = function (scenarios) { return ({
    type: WorkPanelType.SET_SCENARIOS,
    scenarios: scenarios,
}); };
export var setCustomForm = function (customForm) { return ({
    type: WorkPanelType.SET_CUSTOM_FORM,
    customForm: customForm,
}); };
export var addCustomForm = function (customForm) { return ({
    type: WorkPanelType.ADD_CUSTOM_FORM,
    customForm: customForm,
}); };
export var removeForm = function (formId) { return ({
    type: WorkPanelType.REMOVE_FORM,
    formId: formId,
}); };
export var setQuestionGroup = function (formId, questionGroup) { return ({
    type: WorkPanelType.SET_QUESTION_GROUP,
    formId: formId,
    questionGroup: questionGroup,
}); };
export var moveQuestionGroup = function (_a) {
    var formId = _a.formId, dragIndex = _a.dragIndex, hoverIndex = _a.hoverIndex;
    return ({
        type: WorkPanelType.MOVE_QUESTION_GROUP,
        formId: formId,
        dragIndex: dragIndex,
        hoverIndex: hoverIndex,
    });
};
export var moveQuestion = function (_a) {
    var formId = _a.formId, questionGroupId = _a.questionGroupId, dragIndex = _a.dragIndex, hoverIndex = _a.hoverIndex;
    return ({
        type: WorkPanelType.MOVE_QUESTION,
        formId: formId,
        questionGroupId: questionGroupId,
        dragIndex: dragIndex,
        hoverIndex: hoverIndex,
    });
};
export var addQuestionGroup = function (formId, questionGroup) { return ({
    type: WorkPanelType.ADD_QUESTION_GROUP,
    formId: formId,
    questionGroup: questionGroup,
}); };
export var addQuestion = function (formId, questionGroupId, question) { return ({
    type: WorkPanelType.ADD_QUESTION,
    formId: formId,
    questionGroupId: questionGroupId,
    question: question,
}); };
export var removeQuestionGroup = function (formId, questionGroupId) { return ({
    type: WorkPanelType.REMOVE_QUESTION_GROUP,
    formId: formId,
    questionGroupId: questionGroupId,
}); };
export var removeQuestion = function (formId, questionGroupId, questionId) { return ({
    type: WorkPanelType.REMOVE_QUESTION,
    formId: formId,
    questionGroupId: questionGroupId,
    questionId: questionId,
}); };
export var updateQuestionVals = function (_a) {
    var formId = _a.formId, questionGroupId = _a.questionGroupId, questionId = _a.questionId, question = _a.question;
    return ({
        type: WorkPanelType.UPDATE_QUESTION,
        formId: formId,
        questionGroupId: questionGroupId,
        questionId: questionId,
        question: question,
    });
};
export var disconnectTalkTrigger = function (_a) {
    var formId = _a.formId, groupId = _a.groupId, questionId = _a.questionId, talkTriggerId = _a.talkTriggerId;
    return ({
        type: WorkPanelType.DISCONNECT_TALK_TRIGGER,
        formId: formId,
        groupId: groupId,
        questionId: questionId,
        talkTriggerId: talkTriggerId,
    });
};
export var updateCustomFormProperty = function (_a) {
    var customFormId = _a.customFormId, key = _a.key, val = _a.val;
    return ({
        type: WorkPanelType.UPDATE_CUSTOM_FORM_PROPERTY,
        customFormId: customFormId,
        key: key,
        val: val,
    });
};
export var hoverHeadlessStep = function (customFormId, headlessPageId, focusedHeadlessStepId, hoverHeadlessStepId, addBelow) { return ({
    type: WorkPanelType.HOVER_HEADLESS_STEP,
    customFormId: customFormId,
    headlessPageId: headlessPageId,
    focusedHeadlessStepId: focusedHeadlessStepId,
    hoverHeadlessStepId: hoverHeadlessStepId,
    addBelow: addBelow,
}); };
export var createHeadlessConfig = function (customFormId, values) {
    return function (dispatch) {
        var url = endpoints.headlessConfigs.zealsAdmin.collection(customFormId);
        var payload = Object.keys(values).reduce(function (object, key) {
            var val = values[key];
            object[key] = val || null;
            return object;
        }, {});
        return fanpFetch(url, makeFetchInit('POST', payload))
            .then(function (_a) {
            var headless_config = _a.headless_config;
            dispatch(updateCustomFormProperty({
                customFormId: customFormId,
                key: 'headless_config',
                val: headless_config,
            }));
        })
            .catch(handleAPIErrors);
    };
};
export var updateHeadlessConfig = function (customFormId, headlessConfigId, updateObject) {
    return function (dispatch) {
        var url = endpoints.headlessConfigs.zealsAdmin.member(customFormId, headlessConfigId);
        return fanpFetch(url, makeFetchInit('PUT', updateObject))
            .then(function (_a) {
            var headless_config = _a.headless_config;
            dispatch(updateCustomFormProperty({
                customFormId: customFormId,
                key: 'headless_config',
                val: headless_config,
            }));
            return headless_config;
        })
            .catch(handleAPIErrors);
    };
};
export var copyHeadlessConfig = function (customFormId, customFormToCopyId) {
    return function (dispatch) {
        var url = endpoints.customForms.zealsAdmin.copyHeadless(customFormId);
        return fanpFetch(url, makeFetchInit('POST', { custom_form_id: customFormToCopyId }))
            .then(function (_a) {
            var headless_config = _a.headless_config;
            dispatch(updateCustomFormProperty({
                customFormId: customFormId,
                key: 'headless_config',
                val: headless_config,
            }));
            return headless_config;
        })
            .catch(handleAPIErrors);
    };
};
export var deleteHeadlessConfig = function (customFormId, headlessConfigId) {
    return function (dispatch) {
        var url = endpoints.headlessConfigs.zealsAdmin.member(customFormId, headlessConfigId);
        return fanpFetch(url, makeFetchInit('DELETE'))
            .then(function (custom_form) {
            if (custom_form.id) {
                dispatch(updateCustomFormProperty({
                    customFormId: customFormId,
                    key: 'headless_config',
                    val: null,
                }));
            }
        })
            .catch(handleAPIErrors);
    };
};
export var createHeadlessPage = function (_a) {
    var customFormId = _a.customFormId, headlessConfigId = _a.headlessConfigId, formParams = _a.formParams;
    return function (dispatch) {
        var url = endpoints.headlessPages.zealsAdmin.collection({
            customFormId: customFormId,
            headlessConfigId: headlessConfigId,
        });
        return fanpFetch(url, makeFetchInit('POST', formParams))
            .then(function (_a) {
            var headless_config = _a.headless_config;
            dispatch(updateCustomFormProperty({
                customFormId: customFormId,
                key: 'headless_config',
                val: headless_config,
            }));
        })
            .catch(handleAPIErrors);
    };
};
export var deleteHeadlessPage = function (_a) {
    var customFormId = _a.customFormId, headlessConfigId = _a.headlessConfigId, headlessPageId = _a.headlessPageId;
    return function (dispatch) {
        var url = endpoints.headlessPages.zealsAdmin.member({
            customFormId: customFormId,
            headlessConfigId: headlessConfigId,
            headlessPageId: headlessPageId,
        });
        fanpFetch(url, makeFetchInit('DELETE'))
            .then(function (_a) {
            var headless_config = _a.headless_config;
            dispatch(updateCustomFormProperty({
                customFormId: customFormId,
                key: 'headless_config',
                val: headless_config,
            }));
        })
            .catch(handleAPIErrors);
    };
};
export var updateHeadlessPage = function (_a) {
    var customFormId = _a.customFormId, headlessConfigId = _a.headlessConfigId, headlessPageId = _a.headlessPageId, formBody = _a.formBody;
    return function (dispatch) {
        var url = endpoints.headlessPages.zealsAdmin.member({
            customFormId: customFormId,
            headlessConfigId: headlessConfigId,
            headlessPageId: headlessPageId,
        });
        return fanpFetch(url, makeFetchInit('PUT', formBody))
            .then(function (_a) {
            var headless_config = _a.headless_config;
            dispatch(updateCustomFormProperty({
                customFormId: customFormId,
                key: 'headless_config',
                val: headless_config,
            }));
        })
            .catch(handleAPIErrors);
    };
};
export var createHeadlessStep = function (_a) {
    var customFormId = _a.customFormId, headlessConfigId = _a.headlessConfigId, headlessPageId = _a.headlessPageId, formParams = _a.formParams;
    return function (dispatch) {
        var url = endpoints.headlessSteps.zealsAdmin.collection({
            customFormId: customFormId,
            headlessConfigId: headlessConfigId,
            headlessPageId: headlessPageId,
        });
        return fanpFetch(url, makeFetchInit('POST', formParams))
            .then(function (_a) {
            var headless_config = _a.headless_config;
            dispatch(updateCustomFormProperty({
                customFormId: customFormId,
                key: 'headless_config',
                val: headless_config,
            }));
        })
            .catch(handleAPIErrors);
    };
};
export var deleteHeadlessStep = function (_a) {
    var customFormId = _a.customFormId, headlessConfigId = _a.headlessConfigId, headlessPageId = _a.headlessPageId, id = _a.id;
    return function (dispatch) {
        var url = endpoints.headlessSteps.zealsAdmin.member({
            customFormId: customFormId,
            headlessConfigId: headlessConfigId,
            headlessPageId: headlessPageId,
            id: id,
        });
        return fanpFetch(url, makeFetchInit('DELETE'))
            .then(function (_a) {
            var headless_config = _a.headless_config;
            dispatch(updateCustomFormProperty({
                customFormId: customFormId,
                key: 'headless_config',
                val: headless_config,
            }));
        })
            .catch(handleAPIErrors);
    };
};
export var updateHeadlessStep = function (_a) {
    var customFormId = _a.customFormId, headlessConfigId = _a.headlessConfigId, headlessPageId = _a.headlessPageId, id = _a.id, formBody = _a.formBody;
    return function (dispatch) {
        var url = endpoints.headlessSteps.zealsAdmin.member({
            customFormId: customFormId,
            headlessConfigId: headlessConfigId,
            headlessPageId: headlessPageId,
            id: id,
        });
        return fanpFetch(url, makeFetchInit('PUT', { headless_step: formBody }))
            .then(function (_a) {
            var message = _a.message, headless_config = _a.headless_config;
            if (!headless_config)
                throw new Error(message);
            dispatch(updateCustomFormProperty({
                customFormId: customFormId,
                key: 'headless_config',
                val: headless_config,
            }));
        })
            .catch(handleAPIErrors);
    };
};
export var attachHeadlessStepQuestion = function (_a) {
    var customFormId = _a.customFormId, headlessConfigId = _a.headlessConfigId, headlessPageId = _a.headlessPageId, id = _a.id, customFormQuestionId = _a.customFormQuestionId;
    return function (dispatch) {
        var url = endpoints.headlessSteps.zealsAdmin.attachQuestion({
            customFormId: customFormId,
            headlessConfigId: headlessConfigId,
            headlessPageId: headlessPageId,
            id: id,
        });
        return fanpFetch(url, makeFetchInit('POST', customFormQuestionId))
            .then(function (_a) {
            var headless_config = _a.headless_config;
            dispatch(updateCustomFormProperty({
                customFormId: customFormId,
                key: 'headless_config',
                val: headless_config,
            }));
        })
            .catch(handleAPIErrors);
    };
};
export var removeHeadlessStepQuestion = function (_a) {
    var customFormId = _a.customFormId, headlessConfigId = _a.headlessConfigId, headlessPageId = _a.headlessPageId, id = _a.id, customFormQuestionId = _a.customFormQuestionId;
    return function (dispatch) {
        var url = endpoints.headlessSteps.zealsAdmin.removeQuestion({
            customFormId: customFormId,
            headlessConfigId: headlessConfigId,
            headlessPageId: headlessPageId,
            id: id,
        });
        return fanpFetch(url, makeFetchInit('POST', customFormQuestionId))
            .then(function (_a) {
            var headless_config = _a.headless_config;
            dispatch(updateCustomFormProperty({
                customFormId: customFormId,
                key: 'headless_config',
                val: headless_config,
            }));
        })
            .catch(handleAPIErrors);
    };
};
export var deleteHeadlessExtraStep = function (_a) {
    var customFormId = _a.customFormId, headlessConfigId = _a.headlessConfigId, headlessPageId = _a.headlessPageId, id = _a.id;
    return function (dispatch) {
        var url = endpoints.headlessSteps.zealsAdmin.deleteExtraStep({
            customFormId: customFormId,
            headlessConfigId: headlessConfigId,
            headlessPageId: headlessPageId,
            id: id,
        });
        return fanpFetch(url, makeFetchInit('DELETE'))
            .then(function (_a) {
            var headless_config = _a.headless_config;
            dispatch(updateCustomFormProperty({
                customFormId: customFormId,
                key: 'headless_config',
                val: headless_config,
            }));
        })
            .catch(handleAPIErrors);
    };
};
export var createHeadlessExtraStep = function (_a) {
    var customFormId = _a.customFormId, headlessConfigId = _a.headlessConfigId, headlessPageId = _a.headlessPageId, formParams = _a.formParams;
    return function (dispatch) {
        var url = endpoints.headlessSteps.zealsAdmin.collection({
            customFormId: customFormId,
            headlessConfigId: headlessConfigId,
            headlessPageId: headlessPageId,
        });
        return fanpFetch(url, makeFetchInit('POST', formParams))
            .then(function (_a) {
            var headless_config = _a.headless_config;
            dispatch(updateCustomFormProperty({
                customFormId: customFormId,
                key: 'headless_config',
                val: headless_config,
            }));
        })
            .catch(handleAPIErrors);
    };
};
export var createHeadlessExtraStepValue = function (_a) {
    var customFormId = _a.customFormId, headlessConfigId = _a.headlessConfigId, headlessPageId = _a.headlessPageId, id = _a.id, formParams = _a.formParams;
    return function (dispatch) {
        var url = endpoints.headlessSteps.zealsAdmin.createExtraStepValue({
            customFormId: customFormId,
            headlessConfigId: headlessConfigId,
            headlessPageId: headlessPageId,
            id: id,
        });
        return fanpFetch(url, makeFetchInit('POST', formParams))
            .then(function (_a) {
            var headless_config = _a.headless_config;
            dispatch(updateCustomFormProperty({
                customFormId: customFormId,
                key: 'headless_config',
                val: headless_config,
            }));
        })
            .catch(handleAPIErrors);
    };
};
export var deleteHeadlessExtraStepValue = function (_a) {
    var customFormId = _a.customFormId, headlessConfigId = _a.headlessConfigId, headlessPageId = _a.headlessPageId, id = _a.id, formParams = _a.formParams;
    return function (dispatch) {
        var url = endpoints.headlessSteps.zealsAdmin.deleteExtraStepValue({
            customFormId: customFormId,
            headlessConfigId: headlessConfigId,
            headlessPageId: headlessPageId,
            id: id,
        });
        return fanpFetch(url, makeFetchInit('DELETE', formParams))
            .then(function (_a) {
            var headless_config = _a.headless_config;
            dispatch(updateCustomFormProperty({
                customFormId: customFormId,
                key: 'headless_config',
                val: headless_config,
            }));
        })
            .catch(handleAPIErrors);
    };
};
export var getScenarios = function (chatbotId, query) { return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
    var res;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, scenarioApi.getScenarios(chatbotId, query)];
            case 1:
                res = _a.sent();
                dispatch(setScenarios(res.scenarios));
                return [2 /*return*/, res.totalItems];
        }
    });
}); }; };
export var getScenario = function (chatbotId, scenarioId) {
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        var scenario;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, scenarioApi.getScenario(chatbotId, scenarioId)];
                case 1:
                    scenario = _a.sent();
                    dispatch(setScenario(scenario));
                    return [2 /*return*/];
            }
        });
    }); };
};
export var showCustomForm = function (customFormId) {
    return function (dispatch) {
        var url = endpoints.customForms.zealsAdmin.member(customFormId);
        return fanpFetch(url, makeFetchInit())
            .then(function (_a) {
            var custom_form = _a.custom_form;
            return dispatch(setCustomForm(custom_form));
        })
            .catch(function (err) { return handleAPIErrors("Couldn't select Form", err); });
    };
};
export var createCustomForm = function (name, chatbotId, scenarioId) {
    return function (dispatch) {
        var url = endpoints.customForms.zealsAdmin.collection();
        var body = {
            name: name,
            chatbot_id: chatbotId,
            scenario_id: scenarioId,
        };
        return fanpFetch(url, makeFetchInit('POST', body))
            .then(function (_a) {
            var custom_form = _a.custom_form, status = _a.status, message = _a.message;
            if (status === 422)
                throw new Error(message);
            dispatch(addCustomForm(custom_form));
            if (window.globalRouterHistory) {
                window.globalRouterHistory.push("/zeals_admin/custom_form_admin/custom_forms/" + custom_form.id);
            }
        })
            .catch(function (err) { return handleAPIErrors('Could not post Custom Form', err); });
    };
};
export var deleteCustomForm = function (selectedForm, lastFormId) {
    return function (dispatch) {
        var url = endpoints.customForms.zealsAdmin.member(selectedForm.id);
        return fanpFetch(url, makeFetchInit('DELETE', selectedForm))
            .then(function (_a) {
            var status = _a.status;
            if (status === 200) {
                dispatch(removeForm(selectedForm.id));
                var newPath = lastFormId
                    ? "/zeals_admin/custom_form_admin/custom_forms/" + lastFormId
                    : "/zeals_admin/custom_form_admin/custom_forms";
                if (window.globalRouterHistory) {
                    window.globalRouterHistory.push(newPath);
                }
            }
        })
            .catch(function (err) { return handleAPIErrors('Could not delete Custom Form', err); });
    };
};
export var updateCustomForm = function (customFormId, updates) {
    return function (dispatch) {
        var url = endpoints.customForms.zealsAdmin.member(customFormId);
        return fanpFetch(url, makeFetchInit('PUT', updates))
            .then(function (_a) {
            var custom_form = _a.custom_form;
            return dispatch(setCustomForm(custom_form));
        })
            .catch(function (err) { return handleAPIErrors('Could not update form name', err); });
    };
};
// UPDATE_GROUP_RANKS
export var updateQuestionGroupRank = function (revisedQuestionGroups, selectedForm) {
    var revisedForm = revisedQuestionGroups;
    return function (dispatch) {
        var url = endpoints.customForms.zealsAdmin.updateGroupRanks(selectedForm.id);
        return fanpFetch(url, makeFetchInit('PUT', revisedForm))
            .then(function (_a) {
            var custom_form = _a.custom_form;
            return dispatch(setCustomForm(custom_form));
        })
            .catch(function (err) {
            return handleAPIErrors('Could not update question group rank', err);
        });
    };
};
// QUESTION GROUPS
// POST
export var createQuestionGroup = function (selectedForm) {
    return function (dispatch) {
        var url = endpoints.customFormQuestionGroups.zealsAdmin.collection(selectedForm.id);
        return fanpFetch(url, makeFetchInit('POST', {}))
            .then(function (_a) {
            var custom_form_question_group = _a.custom_form_question_group;
            dispatch(addQuestionGroup(selectedForm.id, custom_form_question_group));
        })
            .catch(function (err) { return handleAPIErrors('Could not post Question Group', err); });
    };
};
export var updateQuestionGroup = function (selectedForm, questionGroup, updates) {
    return function (dispatch) {
        var url = endpoints.customFormQuestionGroups.zealsAdmin.member(selectedForm.id, questionGroup.id);
        return fanpFetch(url, makeFetchInit('PUT', updates))
            .then(function (_a) {
            var custom_form_question_group = _a.custom_form_question_group;
            return dispatch(setQuestionGroup(selectedForm.id, custom_form_question_group));
        })
            .catch(function (err) { return handleAPIErrors('Could not update form name', err); });
    };
};
// DELETE
export var deleteQuestionGroup = function (selectedForm, selectedQuestionGroup) {
    return function (dispatch) {
        var url = endpoints.customFormQuestionGroups.zealsAdmin.member(selectedForm.id, selectedQuestionGroup.id);
        return fanpFetch(url, makeFetchInit('DELETE', selectedQuestionGroup))
            .then(function (_a) {
            var status = _a.status;
            if (status === 200) {
                dispatch(removeQuestionGroup(selectedForm.id, selectedQuestionGroup.id));
            }
        })
            .catch(function (err) { return handleAPIErrors('Could not remove Question Group', err); });
    };
};
export var createQuestion = function (_a) {
    var selectedForm = _a.selectedForm, questionGroup = _a.questionGroup, formValues = _a.formValues;
    return function (dispatch) {
        var url = endpoints.customFormQuestions.zealsAdmin.collection(selectedForm.id, questionGroup.id);
        return fanpFetch(url, makeFetchInit('POST', formValues))
            .then(function (_a) {
            var custom_form_question = _a.custom_form_question;
            dispatch(addQuestion(selectedForm.id, questionGroup.id, custom_form_question));
        })
            .catch(function (err) { return handleAPIErrors('Could not post question.', err); });
    };
};
export var deleteQuestion = function (_a) {
    var selectedForm = _a.selectedForm, questionGroup = _a.questionGroup, questionId = _a.questionId;
    return function (dispatch) {
        var url = endpoints.customFormQuestions.zealsAdmin.member(selectedForm.id, questionGroup.id, questionId);
        return fanpFetch(url, makeFetchInit('DELETE', {}))
            .then(function (_a) {
            var status = _a.status;
            if (status === 200) {
                dispatch(removeQuestion(selectedForm.id, questionGroup.id, questionId));
            }
        })
            .catch(function (err) { return handleAPIErrors('Could not remove question.', err); });
    };
};
export var updateQuestion = function (_a) {
    var selectedFormId = _a.selectedFormId, questionGroupId = _a.questionGroupId, questionId = _a.questionId, formValues = _a.formValues;
    return function (dispatch) {
        var url = endpoints.customFormQuestions.zealsAdmin.member(selectedFormId, questionGroupId, questionId);
        return fanpFetch(url, makeFetchInit('PUT', formValues))
            .then(function (_a) {
            var custom_form_question = _a.custom_form_question;
            dispatch(updateQuestionVals({
                formId: selectedFormId,
                questionGroupId: questionGroupId,
                questionId: questionId,
                question: custom_form_question,
            }));
        })
            .catch(function (err) { return handleAPIErrors('Could not remove Question Group', err); });
    };
};
export var createChoice = function (_a) {
    var selectedFormId = _a.selectedFormId, questionGroupId = _a.questionGroupId, questionId = _a.questionId, choiceData = _a.choiceData, chatbotId = _a.chatbotId, selectedScenarioId = _a.selectedScenarioId;
    return function (dispatch) {
        var url = endpoints.customFormQuestions.zealsAdmin.createChoice(selectedFormId, questionGroupId, questionId);
        return fanpFetch(url, makeFetchInit('POST', choiceData))
            .then(function (_a) {
            var custom_form_question = _a.custom_form_question;
            dispatch(updateQuestionVals({
                formId: selectedFormId,
                questionGroupId: questionGroupId,
                questionId: questionId,
                question: custom_form_question,
            }));
            if (chatbotId && selectedScenarioId) {
                dispatch(getScenario(chatbotId, selectedScenarioId));
            }
        })
            .catch(function (err) {
            return handleAPIErrors('Could not create connected choice.', err);
        });
    };
};
export var updateChoice = function (_a) {
    var selectedFormId = _a.selectedFormId, questionGroupId = _a.questionGroupId, questionId = _a.questionId, body = _a.body;
    return function (dispatch) {
        var url = endpoints.customFormQuestions.zealsAdmin.updateChoice(selectedFormId, questionGroupId, questionId);
        return fanpFetch(url, makeFetchInit('PUT', body))
            .then(function (_a) {
            var custom_form_question = _a.custom_form_question;
            dispatch(updateQuestionVals({
                formId: selectedFormId,
                questionGroupId: questionGroupId,
                questionId: questionId,
                question: custom_form_question,
            }));
        })
            .catch(function (err) {
            return handleAPIErrors('Could not update connected choice.', err);
        });
    };
};
export var deleteChoice = function (_a) {
    var selectedFormId = _a.selectedFormId, questionGroupId = _a.questionGroupId, questionId = _a.questionId, body = _a.body;
    return function (dispatch) {
        var url = endpoints.customFormQuestions.zealsAdmin.destroyChoice(selectedFormId, questionGroupId, questionId);
        return fanpFetch(url, makeFetchInit('DELETE', body))
            .then(function (_a) {
            var custom_form_question = _a.custom_form_question;
            dispatch(updateQuestionVals({
                formId: selectedFormId,
                questionGroupId: questionGroupId,
                questionId: questionId,
                question: custom_form_question,
            }));
        })
            .catch(function (err) {
            return handleAPIErrors('Could not remove connected choice.', err);
        });
    };
};
export var createTalkTrigger = function (_a) {
    var selectedForm = _a.selectedForm, questionGroup = _a.questionGroup, questionId = _a.questionId, talkTriggerId = _a.talkTriggerId;
    return function (dispatch) {
        var url = endpoints.customFormQuestions.zealsAdmin.createTrigger(selectedForm.id, questionGroup.id, questionId);
        return fanpFetch(url, makeFetchInit('POST', { talk_trigger_id: talkTriggerId }))
            .then(function (_a) {
            var custom_form_question = _a.custom_form_question;
            return dispatch(updateQuestionVals({
                formId: selectedForm.id,
                questionGroupId: questionGroup.id,
                questionId: questionId,
                question: custom_form_question,
            }));
        })
            .catch(function (err) { return handleAPIErrors('Could not post talk trigger.', err); });
    };
};
export var deleteTalkTrigger = function (_a) {
    var selectedForm = _a.selectedForm, questionGroup = _a.questionGroup, questionId = _a.questionId, talkTriggerId = _a.talkTriggerId;
    return function (dispatch) {
        var url = endpoints.customFormQuestions.zealsAdmin.destroyTrigger(selectedForm.id, questionGroup.id, questionId);
        return fanpFetch(url, makeFetchInit('DELETE', { talk_trigger_id: talkTriggerId }))
            .then(function () {
            return dispatch(disconnectTalkTrigger({
                formId: selectedForm.id,
                groupId: questionGroup.id,
                questionId: questionId,
                talkTriggerId: talkTriggerId,
            }));
        })
            .catch(function (err) { return handleAPIErrors('Could not remove talk trigger.', err); });
    };
};
// Rich menu thunks
export var createRichMenu = function (selectedFormId, questionGroupId, questionId, body) {
    return function (dispatch) {
        var url = endpoints.customFormRichMenuItems.zealsAdmin.createRichMenu(selectedFormId, questionGroupId, questionId);
        fanpFetch(url, makeFetchInit('POST', body))
            .then(function (_a) {
            var custom_form_question = _a.custom_form_question;
            return dispatch(updateQuestionVals({
                formId: selectedFormId,
                questionGroupId: questionGroupId,
                questionId: questionId,
                question: custom_form_question,
            }));
        })
            .catch(function (err) { return handleAPIErrors('Could not post rich menu item.', err); });
    };
};
export var deleteRichMenu = function (selectedFormId, questionGroupId, questionId, body) {
    return function (dispatch) {
        var url = endpoints.customFormRichMenuItems.zealsAdmin.destroyRichMenu(selectedFormId, questionGroupId, questionId);
        fanpFetch(url, makeFetchInit('DELETE', body))
            .then(function (_a) {
            var custom_form_question = _a.custom_form_question;
            return dispatch(updateQuestionVals({
                formId: selectedFormId,
                questionGroupId: questionGroupId,
                questionId: questionId,
                question: custom_form_question,
            }));
        })
            .catch(function (err) { return handleAPIErrors('Could not delete rich menu item.', err); });
    };
};
export var updateRichMenu = function (selectedFormId, questionGroupId, questionId, body) {
    return function (dispatch) {
        var url = endpoints.customFormRichMenuItems.zealsAdmin.updateRichMenu(selectedFormId, questionGroupId, questionId);
        return fanpFetch(url, makeFetchInit('PUT', body))
            .then(function (_a) {
            var custom_form_question = _a.custom_form_question;
            return dispatch(updateQuestionVals({
                formId: selectedFormId,
                questionGroupId: questionGroupId,
                questionId: questionId,
                question: custom_form_question,
            }));
        })
            .catch(function (err) { return handleAPIErrors('Could not update rich menu item.', err); });
    };
};
