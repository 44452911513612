var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { FormTypes, } from '@src/redux/fanp/types/form';
import normalizer from '@src/lib/normalizers/forms';
import { addBlankFormElement } from '@src/lib/form';
import * as formsGrpc from '@src/services/zeals_grpc/forms';
import * as userDossierGrpc from '@src/services/zeals_grpc/userDossier';
export function setWebformTableRowOrderWithScraperGroup(webformId, from, to) {
    return {
        type: FormTypes.SET_WEBFORM_TABLE_ROW_ORDER_SCRAPER_GROUP,
        webformId: webformId,
        from: from,
        to: to,
    };
}
export function removeExistingScraperGroup(webformId, tabId, scraperGroup) {
    return {
        type: FormTypes.REMOVE_EXISTING_SCRAPER_GROUP,
        webformId: webformId,
        tabId: tabId,
        scraperGroup: scraperGroup,
    };
}
export function removeExistingFormElement(webformId, tabId, formElementId) {
    return {
        type: FormTypes.REMOVE_EXIST_FORM_ELEMENT,
        webformId: webformId,
        tabId: tabId,
        formElementId: formElementId,
    };
}
export function handleNewScraperElements(webformId, tabId, oldTargetScraperElements, newTargetScraperElements) {
    return {
        type: FormTypes.HANDLE_NEW_SCRAPER_ELEMENTS,
        webformId: webformId,
        tabId: tabId,
        oldTargetScraperElements: oldTargetScraperElements,
        newTargetScraperElements: newTargetScraperElements,
    };
}
export function setNewBlankFormElementWithDragAndDrop(webformId, tabId, draggableId, targetPositionElement, templateId, chatbotId) {
    return {
        type: FormTypes.SET_NEW_BLANK_FORM_ELEMENT_WITH_INDEX,
        webformId: webformId,
        tabId: tabId,
        newFormElement: addBlankFormElement(draggableId, templateId, chatbotId),
        targetPositionElement: targetPositionElement,
    };
}
export function setNewBlankFormElement(webformId, tabId, newFormElement) {
    return {
        type: FormTypes.SET_NEW_BLANK_FORM_ELEMENT,
        webformId: webformId,
        tabId: tabId,
        newFormElement: newFormElement,
    };
}
export function setPaginatedFormIds(ids) {
    return {
        type: FormTypes.SET_PAGINATION_FORM_IDS,
        paginatedFormIds: ids,
    };
}
export function setFormsIsDraft(formIds) {
    return {
        type: FormTypes.SET_FORMS_IS_DRAFT,
        formIds: formIds,
    };
}
export var setFormTemplates = function (formTemplates) {
    var templatesNormalized = normalizer.normalize.formTemplates(formTemplates);
    return {
        type: FormTypes.SET_FORM_TEMPLATES,
        formTemplates: templatesNormalized.formTemplates,
        formElements: templatesNormalized.formElements,
    };
};
export var setFormElementTemplates = function (formElementTemplates) {
    var formElementTemplatesNormalized = normalizer.normalize.formElementTemplates(formElementTemplates);
    return {
        type: FormTypes.SET_FORM_ELEMENT_TEMPLATES,
        formElementTemplates: formElementTemplatesNormalized.formElementTemplates,
        formElements: formElementTemplatesNormalized.formElements,
    };
};
export var setForm = function (form) {
    var normalized = normalizer.normalize.form(form);
    return {
        type: FormTypes.SET_FORM,
        forms: normalized.forms,
        formElements: normalized.formElements,
    };
};
export var setFormBuildAction = function (formBuild) {
    return {
        type: FormTypes.SET_FORM_BUILD,
        formBuild: formBuild,
    };
};
export var saveNewFormName = function (name) {
    return {
        type: FormTypes.SAVE_NEW_FORM_NAME,
        name: name,
    };
};
export var addForms = function (forms) {
    var normalized = normalizer.normalize.forms(forms);
    return {
        type: FormTypes.ADD_FORMS,
        forms: normalized.forms,
        formElements: normalized.formElements,
    };
};
export var updateWebformName = function (formId, value) {
    return {
        type: FormTypes.UPDATE_WEBFORM_NAME,
        formId: formId,
        value: value,
    };
};
export var updateWebformLogoUrl = function (formId, value) {
    return {
        type: FormTypes.UPDATE_WEBFORM_LOGO_URL,
        formId: formId,
        value: value,
    };
};
export var setSelectFormElementOptions = function (formElementId, selectFormElementOptions) {
    return {
        type: FormTypes.SET_SELECT_INPUT_OPTIONS,
        formElementId: formElementId,
        selectFormElementOptions: selectFormElementOptions,
    };
};
export var updateForm = function (form) {
    var normalized = normalizer.normalize.form(form);
    return {
        type: FormTypes.UPDATE_FORM,
        formId: form.id,
        forms: normalized.forms,
        formElements: normalized.formElements,
    };
};
export var updateFormName = function (id, name) {
    return {
        type: FormTypes.UPDATE_FORM_NAME,
        id: id,
        name: name,
    };
};
export var deleteForm = function (id) {
    return {
        type: FormTypes.DELETE_FORM,
        id: id,
    };
};
export var setFieldCollection = function (fieldCollection) {
    return {
        type: FormTypes.SET_FIELD_COLLECTION,
        fieldCollection: fieldCollection,
    };
};
export var addField = function (fieldCollectionId, field) {
    return {
        type: FormTypes.ADD_FIELD,
        fieldCollectionId: fieldCollectionId,
        field: field,
    };
};
export var updateFieldName = function (fieldCollectionId, fieldId, name) {
    return {
        type: FormTypes.UPDATE_FIELD,
        fieldCollectionId: fieldCollectionId,
        fieldId: fieldId,
        name: name,
    };
};
export var deleteField = function (fieldCollectionId, fieldId) {
    return {
        type: FormTypes.DELETE_FIELD,
        fieldCollectionId: fieldCollectionId,
        fieldId: fieldId,
    };
};
export var fetchDeleteForm = function (id) { return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
    var status, e_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, formsGrpc.deleteForm(id)];
            case 1:
                status = _a.sent();
                if (status === 'successful') {
                    dispatch(deleteForm(id));
                }
                return [3 /*break*/, 3];
            case 2:
                e_1 = _a.sent();
                alert("Can not delete forms: " + e_1.message);
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); }; };
export var filterForms = function (forms, status) { return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
    var filteredForms, formIds;
    return __generator(this, function (_a) {
        filteredForms = Object.values(forms).filter(function (form) { return form.isDraft === status; });
        formIds = filteredForms.map(function (f) { return f.id; });
        dispatch(setPaginatedFormIds(formIds));
        return [2 /*return*/];
    });
}); }; };
export var fetchDeleteField = function (fieldCollectionId, fieldId) { return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
    var status, e_2;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, userDossierGrpc.deleteField(fieldId)];
            case 1:
                status = _a.sent();
                if (status === 'successful') {
                    dispatch(deleteField(fieldCollectionId, fieldId));
                }
                return [3 /*break*/, 3];
            case 2:
                e_2 = _a.sent();
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); }; };
export function setFieldOrder(fieldCollectionId, from, to) {
    return {
        type: FormTypes.SET_FIELD_ORDER,
        fieldCollectionId: fieldCollectionId,
        from: from,
        to: to,
    };
}
export function setFormElement(formElement) {
    return {
        type: FormTypes.SET_FORM_ELEMENT,
        formElement: formElement,
    };
}
export function setIsSaveSuccess(isSaveSuccess, completedSetting) {
    return {
        type: FormTypes.SET_IS_SAVE_SUCCESS,
        isSaveSuccess: isSaveSuccess,
        completedSetting: completedSetting,
    };
}
export var setFormElementErrors = function (formElementErrors) {
    return {
        type: FormTypes.SET_FORM_ELEMENT_ERRORS,
        formElementErrors: formElementErrors,
    };
};
export var setFormsBuilds = function (formBuilds) {
    return {
        type: FormTypes.SET_FORM_BUILDS,
        formBuilds: formBuilds,
    };
};
export var deleteFormBuild = function (id) {
    return {
        type: FormTypes.DELETE_FORM_BUILD,
        id: id,
    };
};
export var setNewFormBuild = function (formBuild) {
    return {
        type: FormTypes.SET_NEW_FORM_BUILD,
        formBuild: formBuild,
    };
};
export var setFormScenarioAction = function (formBuildId, formScenario, segmentId) {
    return {
        type: FormTypes.SET_FORM_SCENARIO,
        formBuildId: formBuildId,
        formScenario: formScenario,
        segmentId: segmentId,
    };
};
export var updateFormBuildAction = function (formBuild) {
    return {
        type: FormTypes.UPDATE_FORM_BUILD,
        formBuild: formBuild,
    };
};
export var deleteFormScenarioSegmentAction = function (updatedFormBuild) {
    return {
        type: FormTypes.DELETE_FORM_SCENARIO_SEGMENT,
        updatedFormBuild: updatedFormBuild,
    };
};
export var deleteWebformSegmentAction = function (updatedFormBuild) {
    return {
        type: FormTypes.DELETE_WEB_FORM_SEGMENT,
        updatedFormBuild: updatedFormBuild,
    };
};
export var deleteEmptySegmentAction = function (formBuildId, id) {
    return {
        type: FormTypes.DELETE_EMPTY_SEGMENT,
        formBuildId: formBuildId,
        id: id,
    };
};
export var addBlankFormBuildSegment = function (targetFormBuildId, blankFormBuildSegment) {
    return {
        type: FormTypes.ADD_BLANK_FORM_BUILD_SEGMENT,
        targetFormBuildId: targetFormBuildId,
        blankFormBuildSegment: blankFormBuildSegment,
    };
};
export var setWebformFieldsList = function (fieldsList) {
    return {
        type: FormTypes.SET_WEBFORM_FIELDS_LIST,
        fieldsList: fieldsList,
    };
};
export var setFormQuestionsList = function (formQuestions) {
    return {
        type: FormTypes.SET_FORM_QUESTIONS_LIST,
        formQuestions: formQuestions,
    };
};
