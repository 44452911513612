var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import { grey } from '@zeals-co-ltd/washi-styles/lib/colors';
import { transitions } from '@src/lib/animations';
import { centeredFlex, adiconNextBlock, hideScrollbar } from '@src/lib/mixins';
// A styled-component which wraps the text inside of NextBlock or NextScenario
var NextPostbackText = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: relative;\n  ", ";\n  width: 100%;\n  transition: ", ";\n  ", ";\n  .adicon_delete {\n    top: 6px;\n    right: 4px;\n  }\n  .adicon_nextblock {\n    ", ";\n    overflow: auto;\n    white-space: pre-wrap;\n    ", ";\n  }\n  cursor: default;\n  &:hover {\n    ", ";\n    .adicon_delete {\n      display: block;\n    }\n  }\n"], ["\n  position: relative;\n  ", ";\n  width: 100%;\n  transition: ", ";\n  ", ";\n  .adicon_delete {\n    top: 6px;\n    right: 4px;\n  }\n  .adicon_nextblock {\n    ",
    ";\n    overflow: auto;\n    white-space: pre-wrap;\n    ", ";\n  }\n  cursor: default;\n  &:hover {\n    ", ";\n    .adicon_delete {\n      display: block;\n    }\n  }\n"])), function (props) { return (props.forModal ? '' : "" + centeredFlex); }, transitions.normal, adiconNextBlock, function (props) {
    return props.forModal
        ? "\n      height: 44px;\n      line-height: 40px;\n      border: solid 1px " + grey[300] + ";\n      margin-top: 0;\n      padding-left: 16px;\n      width: 100%;\n      text-align: left;\n      border-radius: 8px;\n    "
        : "\n      width: auto;\n      text-align: center;\n    ";
}, hideScrollbar, function (props) { return (props.isQuickReplyChoice ? 'cursor: pointer' : ''); });
export default NextPostbackText;
var templateObject_1;
