var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Tooltip } from '@zeals-co-ltd/washi-components';
import React from 'react';
import { FormAutomationStepValueType } from 'zeals-protobuf/zeals/web/entities/form_automation_pb';
import { FormAutomationStepFieldSelector, } from '@src/components/atoms/FormAutomationStepFieldSelector';
import { FormAutomationStepValueTypeSelector } from '@src/components/atoms/FormAutomationStepValueTypeSelector';
import { findField, NotRequiredField, SettingsText, } from './FormAutomationStepItem';
export default function TextInputStep(_a) {
    var _b;
    var step = _a.step, fields = _a.fields, intl = _a.intl, onChange = _a.onChange, handleModal = _a.handleModal;
    var inputAction = (step || {}).inputAction;
    var valueType = (inputAction || {}).valueType;
    var newStep = function (selectKey, selectValue) {
        var _a;
        return __assign(__assign({}, step), { inputAction: __assign(__assign({}, inputAction), (_a = {}, _a[selectKey] = selectValue, _a)) });
    };
    var field = findField(fields, inputAction.fieldId);
    var isTextInputStepFixed = valueType ===
        FormAutomationStepValueType.FORM_AUTOMATION_STEP_VALUE_TYPE_FIXED;
    var isTextInputStepField = valueType ===
        FormAutomationStepValueType.FORM_AUTOMATION_STEP_VALUE_TYPE_FIELD;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "field " + (isTextInputStepField ? 'field-selector' : '') }, isTextInputStepField ? (React.createElement(FormAutomationStepFieldSelector, { fieldId: inputAction.fieldId, fields: fields, onChange: function (item) {
                return onChange(newStep('fieldId', item.value));
            } })) : (React.createElement(NotRequiredField, null))),
        React.createElement("div", { className: "css-selector" },
            React.createElement(Tooltip, { arrow: true, title: inputAction.querySelector },
                React.createElement("input", { "data-testid": "FormAutomationStepItem-CssSelectorInput", value: inputAction.querySelector, placeholder: intl.formatMessage({ id: "lsZfK4", defaultMessage: "Please enter a CSS selector" }), onChange: function (e) { return onChange(newStep('querySelector', e.target.value)); } }))),
        React.createElement("div", { className: "type" },
            React.createElement(FormAutomationStepValueTypeSelector, { step: step, onChange: onChange })),
        React.createElement("div", { "data-testid": "FormAutomationStepItem-OptionsButton", role: "button", tabIndex: 0, className: "options", onClick: function () { return handleModal(true, step, field, inputAction); } },
            React.createElement(SettingsText, { completed: (isTextInputStepFixed && (inputAction === null || inputAction === void 0 ? void 0 : inputAction.fixedValue.length) > 0) ||
                    (isTextInputStepField && ((_b = inputAction.regexp) === null || _b === void 0 ? void 0 : _b.length) > 0) }))));
}
