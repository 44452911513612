var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Component } from 'react';
import styled from 'styled-components';
// @ts-ignore
import { colors, shadows } from '@src/colors';
var QuickReplyChoiceContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: fit-content;\n  padding: 6px 10px;\n  border-radius: 20px;\n  margin: 20px 10px 5px 10px;\n  background-color: ", ";\n  box-shadow: ", ";\n  padding: 6px 10px 6px 10px;\n  font-size: 0.9rem;\n  color: ", ";\n  justify-content: center;\n  align-items: center;\n  transition: 0.2s all;\n  white-space: nowrap;\n  &:hover {\n    cursor: pointer;\n    box-shadow: none;\n  }\n"], ["\n  width: fit-content;\n  padding: 6px 10px;\n  border-radius: 20px;\n  margin: 20px 10px 5px 10px;\n  background-color: ", ";\n  box-shadow: ", ";\n  padding: 6px 10px 6px 10px;\n  font-size: 0.9rem;\n  color: ", ";\n  justify-content: center;\n  align-items: center;\n  transition: 0.2s all;\n  white-space: nowrap;\n  &:hover {\n    cursor: pointer;\n    box-shadow: none;\n  }\n"])), colors.webchat.background, shadows.small.mid, colors.area_mask);
var QuickReplyContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin: 0px 10px 10px 0px;\n  display: flex;\n  align-items: flex-start;\n  width: 100%;\n  position: sticky;\n  overflow-x: scroll;\n  text-align: center;\n  word-break: break-word;\n"], ["\n  margin: 0px 10px 10px 0px;\n  display: flex;\n  align-items: flex-start;\n  width: 100%;\n  position: sticky;\n  overflow-x: scroll;\n  text-align: center;\n  word-break: break-word;\n"])));
var QuickReply = /** @class */ (function (_super) {
    __extends(QuickReply, _super);
    function QuickReply() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            clicked: false,
        };
        _this.onHandleChoiceAnswer = function (quickReplyText, blockId, choiceId) {
            var _a = _this.props, messageId = _a.messageId, onHandleAnswer = _a.onHandleAnswer;
            _this.setState({ clicked: true });
            onHandleAnswer({
                answer: quickReplyText,
                blockId: blockId.toString() || '',
                messageId: messageId,
                quick_reply_choice_id: Number(choiceId),
                talk_trigger_id: null,
            });
        };
        return _this;
    }
    QuickReply.prototype.render = function () {
        var _this = this;
        var choices = this.props.choices;
        var clicked = this.state.clicked;
        return (React.createElement(QuickReplyContainer, null, choices.map(function (choice, i) {
            var hasBlockId = choice.postback_action && choice.postback_action.block_id;
            return (React.createElement(QuickReplyChoiceContainer, { id: choice.id, key: choice.id, onClick: function (_) {
                    clicked
                        ? null
                        : _this.onHandleChoiceAnswer(choice.name, hasBlockId ? choice.postback_action.block_id : '', choice.id);
                } }, choice.name.slice(0, 20)));
        })));
    };
    return QuickReply;
}(Component));
export default QuickReply;
var templateObject_1, templateObject_2;
