export var Status;
(function (Status) {
    Status["Create"] = "create";
    Status["Update"] = "update";
    Status["Delete"] = "delete";
})(Status || (Status = {}));
export var MessageFilterType;
(function (MessageFilterType) {
    MessageFilterType["RESET_SELECTED_FILTER"] = "RESET_SELECTED_FILTER";
    MessageFilterType["UPDATE_FILTER_LIST"] = "UPDATE_FILTER_LIST";
    MessageFilterType["SELECT_MESSAGE_FILTER"] = "SELECT_MESSAGE_FILTER";
    MessageFilterType["SET_MESSAGE_FILTERS"] = "SET_MESSAGE_FILTERS";
    MessageFilterType["HANDLE_OPEN_FILTER_FORM"] = "HANDLE_OPEN_FILTER_FORM";
    MessageFilterType["AD_SET_MESSAGE_FILTER_TAGS"] = "AD_SET_MESSAGE_FILTER_TAGS";
    MessageFilterType["HANDLE_PROCESSING"] = "HANDLE_PROCESSING";
    MessageFilterType["SEARCH_TAG_ACTION"] = "SEARCH_TAG_ACTION";
    MessageFilterType["SELECT_NEW_TAG"] = "SELECT_NEW_TAG";
    MessageFilterType["REMOVE_SELECTED_TAG"] = "REMOVE_SELECTED_TAG";
    MessageFilterType["RESET_SELECTED_TAGS"] = "RESET_SELECTED_TAGS";
    MessageFilterType["SET_DONE_STATUS"] = "SET_DONE_STATUS";
    MessageFilterType["RESET_DONE_STATUS"] = "RESET_DONE_STATUS";
    MessageFilterType["CREATE_NEW_FILTER_LIST"] = "CREATE_NEW_FILTER_LIST";
    MessageFilterType["SET_HIGHLIGHT_FILTER_ID"] = "SET_HIGHLIGHT_FILTER_ID";
    MessageFilterType["SELECT_TAG_GROUP_ID"] = "SELECT_TAG_GROUP_ID";
})(MessageFilterType || (MessageFilterType = {}));
