var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { CustomPicker } from 'react-color';
import { Hue, Saturation } from 'react-color/lib/components/common';
export var MyPicker = function (_a) {
    var onChange = _a.onChange, height = _a.height, margin = _a.margin, props = __rest(_a, ["onChange", "height", "margin"]);
    var styles = {
        saturation: {
            height: height || '200px',
            position: 'relative',
            margin: margin || '0px',
            cursor: 'pointer',
        },
        hue: {
            position: 'relative',
            margin: margin || '0px',
            height: '10px',
            cursor: 'pointer',
        },
        pointer: {
            width: '12px',
            height: '12px',
            borderRadius: '6px',
            transform: 'translate(-6px, -1px)',
            backgroundColor: 'rgb(248, 248, 248)',
            boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.37)',
            cursor: 'pointer',
        },
    };
    var Pointer = function () {
        return React.createElement("div", { style: styles.pointer });
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { role: "button", tabIndex: 0, style: styles.saturation, onMouseDown: function (e) { return e.preventDefault(); }, "data-testid": "ColorPicker-Saturation" },
            React.createElement(Saturation, __assign({ pointer: Pointer, onChange: onChange }, props))),
        React.createElement("div", { role: "button", tabIndex: 0, style: styles.hue, onMouseDown: function (e) { return e.preventDefault(); }, "data-testid": "ColorPicker-Hue" },
            React.createElement(Hue, __assign({ pointer: Pointer, onChange: onChange }, props)))));
};
export default CustomPicker(MyPicker);
