import "core-js/modules/es.array.concat";
export var collection = function collection(chatbotId, targetId, campaignId) {
  return "/chatbots/".concat(chatbotId, "/retention_targets/").concat(targetId, "/retention_campaigns/").concat(campaignId, "/retention_contents");
};
export var member = function member(chatbotId, targetId, campaignId, id) {
  return "".concat(collection(chatbotId, targetId, campaignId), "/").concat(id);
};
export var trackCollection = function trackCollection(chatbotId, retentionTargetId, retentionCampaignId) {
  return "/chatbots/".concat(chatbotId, "/track_push/retention_targets/").concat(retentionTargetId, "/retention_campaigns/").concat(retentionCampaignId, "/retention_contents");
};
export var trackMember = function trackMember(chatbotId, retentionTargetId, retentionCampaignId, retentionContentId) {
  return "".concat(trackCollection(chatbotId, retentionTargetId, retentionCampaignId), "/").concat(retentionContentId);
};
export default {
  collection: collection,
  member: member,
  trackPush: {
    collection: trackCollection,
    member: trackMember
  }
};