// TODO Will add 5xl (with key and value)
export var fontSizes = {
    xxs: '10px',
    xs: '11.7px',
    sm: '12.6px',
    md: '14px',
    lg: '17px',
    xl: '20px',
    xxl: '26px',
    '3xl': '31px',
    '4xl': '43px',
};
export var defaultStyles = {
    color: '#000000',
    fontStyle: 'normal',
    textDecoration: 'none',
    fontWeight: 'regular',
    fontSize: fontSizes.md,
};
export var getSpanStyle = function (content) {
    var color = content.color, style = content.style, decoration = content.decoration, weight = content.weight, size = content.size;
    return {
        color: color || defaultStyles.color,
        fontStyle: style || defaultStyles.fontStyle,
        textDecoration: decoration || defaultStyles.textDecoration,
        fontWeight: weight || defaultStyles.fontWeight,
        fontSize: fontSizes[size] || defaultStyles.fontSize,
    };
};
