var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import styled, { css } from 'styled-components';
import { centeredFlex, setWidthHeight } from '@src/lib/mixins';
import { colors, shadows } from '@src/colors';
import { transitions } from '@src/lib/animations';
import buttonTypes from '@src/lib/buttonTypes';
import { sizes } from '@src/lib/sizes';
var getColor = function (buttonType) {
    var _a;
    var color = (_a = {},
        _a[buttonTypes.MAIN] = colors.white,
        _a[buttonTypes.CONFIRM] = colors.background.off_white_background,
        _a[buttonTypes.CONFIRM_CANCEL] = colors.background.main_color,
        _a[buttonTypes.SAVE] = colors.white,
        _a[buttonTypes.SETTING] = colors.white,
        _a[buttonTypes.SETTING_GREEN] = colors.white,
        _a[buttonTypes.SETTING_WHITE] = colors.default_text,
        _a[buttonTypes.CANCEL] = colors.text_pale,
        _a[buttonTypes.DELETE] = colors.white,
        _a[buttonTypes.DISABLED] = colors.label.font_light,
        _a[buttonTypes.ADMIN_GRAY] = colors.white,
        _a[buttonTypes.ADMIN_RED_GRAD] = colors.white,
        _a[buttonTypes.ADMIN_NAVY] = colors.white,
        _a[buttonTypes.FACEBOOK] = colors.white,
        _a[buttonTypes.PAYMENT_RPA_DUO_NEXT] = colors.white,
        _a[buttonTypes.PAYMENT_RPA_DUO_SUBMIT] = colors.white,
        _a[buttonTypes.PAYMENT_RPA_CANADEL_NEXT] = colors.white,
        _a[buttonTypes.PAYMENT_RPA_CANADEL_SUBMIT] = colors.white,
        _a[buttonTypes.LIGHT_THEM_UP] = colors.white,
        _a[buttonTypes.BLUE_ZEALS] = colors.white,
        _a[buttonTypes.OFF_WHITE] = colors.akatsuki,
        _a[buttonTypes.OFF_WHITE_DISABLED] = colors.off_white_disabled,
        _a[buttonTypes.LIGHT_GREY] = colors.light_grey,
        _a)[buttonType];
    return color || 'none';
};
var getBGColor = function (buttonType) {
    var _a;
    var color = (_a = {},
        _a[buttonTypes.MAIN] = colors.background.main_color,
        _a[buttonTypes.CONFIRM] = colors.background.accent_color,
        _a[buttonTypes.CONFIRM_CANCEL] = colors.white,
        _a[buttonTypes.SAVE] = colors.background.confirm,
        _a[buttonTypes.SETTING] = colors.background.setting,
        _a[buttonTypes.SETTING_GREEN] = colors.background.accent_color,
        _a[buttonTypes.SETTING_WHITE] = colors.white,
        _a[buttonTypes.CANCEL] = colors.background.white,
        _a[buttonTypes.DELETE] = colors.background.error,
        _a[buttonTypes.DISABLED] = colors.background.disabled,
        _a[buttonTypes.ADMIN_GRAY] = colors.zeals_admin.gray,
        _a[buttonTypes.ADMIN_RED_GRAD] = colors.zeals_admin.red_grad,
        _a[buttonTypes.ADMIN_NAVY] = colors.zeals_admin.navy,
        _a[buttonTypes.FACEBOOK] = colors.facebook.main,
        _a[buttonTypes.PAYMENT_RPA_DUO_NEXT] = colors.payment_rpa.duo_next,
        _a[buttonTypes.PAYMENT_RPA_DUO_SUBMIT] = colors.payment_rpa.duo_submit,
        _a[buttonTypes.PAYMENT_RPA_CANADEL_NEXT] = colors.payment_rpa.canadel_next,
        _a[buttonTypes.PAYMENT_RPA_CANADEL_SUBMIT] = colors.payment_rpa.canadel_submit,
        _a[buttonTypes.LIGHT_THEM_UP] = colors.light_them_up,
        _a[buttonTypes.BLUE_ZEALS] = colors.blue_zeals,
        _a[buttonTypes.OFF_WHITE] = colors.background.off_white_background,
        _a[buttonTypes.OFF_WHITE_DISABLED] = colors.background.white,
        _a[buttonTypes.LIGHT_GREY] = colors.background.disabled,
        _a)[buttonType];
    return color || 'none';
};
var getHoverBGColor = function (buttonType) {
    var _a;
    var color = (_a = {},
        _a[buttonTypes.MAIN] = colors.hover.main_color,
        _a[buttonTypes.CONFIRM] = 'none',
        _a[buttonTypes.SAVE] = colors.hover.confirm,
        _a[buttonTypes.SETTING] = colors.hover.setting,
        _a[buttonTypes.SETTING_GREEN] = colors.background.accent_color,
        _a[buttonTypes.SETTING_WHITE] = colors.background.off_white_background,
        _a[buttonTypes.CANCEL] = colors.hover.white,
        _a[buttonTypes.DELETE] = colors.hover.error,
        _a[buttonTypes.DISABLED] = colors.hover.disabled,
        _a[buttonTypes.ADMIN_GRAY] = colors.hover.gray,
        _a[buttonTypes.ADMIN_RED_GRAD] = colors.hover.red_grad,
        _a[buttonTypes.ADMIN_NAVY] = colors.hover.navy,
        _a[buttonTypes.FACEBOOK] = colors.facebook.main,
        _a[buttonTypes.LIGHT_THEM_UP] = colors.light_them_up,
        _a[buttonTypes.BLUE_ZEALS] = colors.blue_zeals,
        _a)[buttonType];
    return color || 'none';
};
var getBorder = function (buttonType) {
    switch (buttonType) {
        case buttonTypes.CANCEL:
            return "1px solid " + colors.text_pale;
        case buttonTypes.SETTING_WHITE:
            return "1px solid " + colors.border.default;
        default:
            return 'none';
    }
};
var getHoverShadow = function (buttonType) {
    switch (buttonType) {
        case buttonTypes.DISABLED:
            return "" + shadows.medium.light;
        case buttonTypes.CONFIRM_CANCEL:
            return 'none';
        default:
            return "" + shadows.big.mid;
    }
};
var getFontSize = function (props) { return props.fontSize || '0.875rem'; };
var Container = styled.button(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  margin: ", ";\n  margin-right: ", ";\n  padding: ", ";\n  width: ", ";\n  min-width: ", ";\n  max-width: ", ";\n  height: ", ";\n  background: ", ";\n  font-size: ", ";\n  font-weight: ", ";\n  box-shadow: ", ";\n  box-sizing: border-box;\n  border: ", ";\n  border-radius: ", ";\n  color: ", ";\n  letter-spacing: 0.44px;\n  text-align: center;\n  pointer-events: ", ";\n  opacity: ", ";\n  transition: all ", " ease-in-out;\n  .icon {\n    font-size: ", ";\n    top: -4px;\n    position: relative;\n  }\n  ", "\n  &:focus {\n    outline: 0;\n    border: 3px solid ", ";\n  }\n  &:active {\n    box-shadow: none;\n  }\n"], ["\n  ", ";\n  ", ";\n  margin: ", ";\n  margin-right: ", ";\n  padding: ",
    ";\n  width: ", ";\n  min-width: ", ";\n  max-width: ", ";\n  height: ", ";\n  background: ",
    ";\n  font-size: ", ";\n  font-weight: ", ";\n  box-shadow: ", ";\n  box-sizing: border-box;\n  border: ", ";\n  border-radius: ", ";\n  color: ", ";\n  letter-spacing: 0.44px;\n  text-align: center;\n  pointer-events: ", ";\n  opacity: ", ";\n  transition: all ", " ease-in-out;\n  .icon {\n    font-size: ", ";\n    top: -4px;\n    position: relative;\n  }\n  ",
    "\n  &:focus {\n    outline: 0;\n    border: 3px solid ", ";\n  }\n  &:active {\n    box-shadow: none;\n  }\n"])), centeredFlex, setWidthHeight, function (props) { return props.margin || 'none'; }, function (props) { return props.marginRight || 'none'; }, function (props) {
    return props.width === sizes.button.circle ? '0' : '0 .25rem';
}, function (props) { return (props.width ? props.width + "px" : '100%'); }, function (props) { return (props.minWidth ? props.minWidth + "px" : '120px'); }, function (props) { return (props.maxWidth ? props.maxWidth + "px" : '500px'); }, function (props) { return (props.height ? props.height + "px" : '40px'); }, function (props) {
    return props.backgroundColor || getBGColor(props.buttonType);
}, getFontSize, function (props) { return props.fontWeight || 'normal'; }, shadows.small.light, function (props) { return getBorder(props.buttonType); }, function (props) { return props.borderRadius || '40px'; }, function (props) { return props.color || getColor(props.buttonType); }, function (props) { return (props.hasError ? 'none' : ''); }, function (_a) {
    var disabled = _a.disabled;
    return (disabled ? 0.5 : 1);
}, transitions.normal, getFontSize, function (props) {
    return !props.noHover && css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      &:hover {\n        cursor: ", ";\n        box-shadow: ", ";\n        background: ", ";\n      }\n    "], ["\n      &:hover {\n        cursor: ",
        ";\n        box-shadow: ", ";\n        background: ", ";\n      }\n    "])), props.buttonType === buttonTypes.DISABLED
        ? 'no-drop'
        : 'pointer', getHoverShadow(props.buttonType), getHoverBGColor(props.buttonType));
}, colors.border.focus);
var defaultProps = {
    text: '',
    height: 40,
    buttonType: buttonTypes.MAIN,
    onClick: function () { return console.log('Placeholder click function'); },
};
var Button = function (_a) {
    var _b = _a.text, text = _b === void 0 ? '' : _b, children = _a.children, dataTest = _a.dataTest, iconClassName = _a.iconClassName, props = __rest(_a, ["text", "children", "dataTest", "iconClassName"]);
    return (React.createElement(Container, __assign({}, props, { "data-testid": dataTest }),
        iconClassName && React.createElement("span", { className: "icon " + iconClassName }),
        text,
        children));
};
Button.defaultProps = defaultProps;
export default Button;
var templateObject_1, templateObject_2;
