import { ApplicationType } from '../types/application';
export var setConnected = function (connected) {
    return {
        type: ApplicationType.SET_CONNECTED,
        connected: connected,
    };
};
export var updateIsFetching = function (isFetching) {
    return {
        type: ApplicationType.UPDATE_IS_FETCHING,
        isFetching: isFetching,
    };
};
export var updateHeaderIsFetching = function (isHeaderFetching) {
    return {
        type: ApplicationType.UPDATE_HEADER_IS_FETCHING,
        isHeaderFetching: isHeaderFetching,
    };
};
