var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React from 'react';
import { injectIntl } from 'react-intl';
import SelectFilterDropdown from '@src/components/atoms/SelectFilterDropdown';
import styled from 'styled-components';
import { Button, Icon } from '@zeals-co-ltd/washi-components';
var DeleteButton = styled(Button)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  height: 40px;\n  width: 40px;\n  min-width: unset;\n  margin-left: 8px;\n  .adicon_delete {\n    margin: 0;\n  }\n"], ["\n  height: 40px;\n  width: 40px;\n  min-width: unset;\n  margin-left: 8px;\n  .adicon_delete {\n    margin: 0;\n  }\n"])));
var AddValueButton = styled(Button)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  height: 32px;\n  margin-bottom: 24px;\n"], ["\n  height: 32px;\n  margin-bottom: 24px;\n"])));
export default injectIntl(function (_a) {
    var intl = _a.intl, valueMap = _a.valueMap, onAddMapEntry = _a.onAddMapEntry, onRemoveMapEntry = _a.onRemoveMapEntry, onUpdateMapEntry = _a.onUpdateMapEntry, error = _a.error;
    var boolValueItems = [
        {
            label: 'true',
            value: 'true',
        },
        {
            label: 'false',
            value: 'false',
        },
    ];
    return (React.createElement("div", { className: "value-map-container" },
        React.createElement("div", { className: "subtitle-value-map" }, intl.formatMessage({ id: "sZyufG", defaultMessage: "Please enter the value of each Input option from the client side" })),
        Boolean(valueMap.length) && (React.createElement("div", { className: "field-values-wrapper" }, valueMap.map(function (_a, i) {
            var _b = __read(_a, 2), key = _b[0], value = _b[1];
            return (React.createElement("div", { className: "value-container-wrapper", key: i + 1 + "-entry" },
                React.createElement("div", { className: "browser-value-card" },
                    React.createElement("div", { className: "browser-value-container-field" },
                        React.createElement(SelectFilterDropdown, { className: "field-value-field", value: key, items: boolValueItems, itemType: intl.formatMessage({ id: "B1sA8J", defaultMessage: "select value of field" }), onChange: function (_a) {
                                var label = _a.label;
                                return onUpdateMapEntry(i, [label, value]);
                            } })),
                    React.createElement("div", { className: "browser-value-container-automation" },
                        React.createElement("input", { className: "item item-value-input", type: "text", value: value, placeholder: intl.formatMessage({ id: "SOaj9Z", defaultMessage: "Enter the value of browser automation" }), onChange: function (e) {
                                return onUpdateMapEntry(i, [key, e.target.value]);
                            } }))),
                React.createElement(DeleteButton, { variant: "text", color: "error", fontIconClass: "adicon_delete", onClick: function () { return onRemoveMapEntry(i); }, disabled: i === 0 && valueMap.length === 1 })));
        }))),
        error && React.createElement("span", { className: "error" }, error),
        React.createElement(AddValueButton, { color: "primary", variant: "outlined", onClick: onAddMapEntry, startIcon: React.createElement(Icon, { name: "adicon_plus", color: "primary" }) }, intl.formatMessage({ id: "x1Q83m", defaultMessage: "Add More Items" }))));
});
var templateObject_1, templateObject_2;
