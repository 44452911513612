import { ModalType } from '../types/modal';
export var initialState = {
    visibleBody: false,
    isExpanded: false,
    data: {},
};
export default (function (state, action) {
    var _a;
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case ModalType.CLOSE_MODAL:
            return Object.assign({}, state, { visibleBody: false });
        case ModalType.EXPAND_MODAL:
            return Object.assign({}, state, { isExpanded: action.isExpanded });
        case ModalType.SET_MODAL_DATA:
            return Object.assign({}, state, {
                data: Object.assign({}, state.data, (_a = {},
                    _a[action.key] = action.value,
                    _a)),
            });
        case ModalType.SHOW_MODAL: {
            return Object.assign({}, state, {
                visibleBody: action.visibleBody,
            });
        }
        default:
            return state;
    }
});
