// source: services/user_dossier.proto

/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

/* eslint-disable */
// @ts-nocheck
var jspb = require('google-protobuf');

var goog = jspb;
var global = Function('return this')();

var entities_entities_pb = require('../entities/entities_pb.js');

goog.object.extend(proto, entities_entities_pb);

var entities_user_dossier_pb = require('../entities/user_dossier_pb.js');

goog.object.extend(proto, entities_user_dossier_pb);

var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');

goog.object.extend(proto, google_protobuf_wrappers_pb);
goog.exportSymbol('proto.CreateAttributeCollectionRequest', null, global);
goog.exportSymbol('proto.CreateFieldCollectionRequest', null, global);
goog.exportSymbol('proto.CreateFieldRequest', null, global);
goog.exportSymbol('proto.CreateUserLabelRequest', null, global);
goog.exportSymbol('proto.CreateUserSegmentRequest', null, global);
goog.exportSymbol('proto.DeleteAttributeCollectionRequest', null, global);
goog.exportSymbol('proto.DeleteFieldCollectionRequest', null, global);
goog.exportSymbol('proto.DeleteFieldRequest', null, global);
goog.exportSymbol('proto.DeleteUserLabelRequest', null, global);
goog.exportSymbol('proto.DeleteUserSegmentRequest', null, global);
goog.exportSymbol('proto.GetAttributeCollectionRequest', null, global);
goog.exportSymbol('proto.GetFieldCollectionRequest', null, global);
goog.exportSymbol('proto.GetFieldRequest', null, global);
goog.exportSymbol('proto.GetFieldRequest.ByCase', null, global);
goog.exportSymbol('proto.LinkFieldRequest', null, global);
goog.exportSymbol('proto.ListAttributeCollectionsRequest', null, global);
goog.exportSymbol('proto.ListAttributeCollectionsResponse', null, global);
goog.exportSymbol('proto.ListFieldCollectionsRequest', null, global);
goog.exportSymbol('proto.ListFieldCollectionsResponse', null, global);
goog.exportSymbol('proto.ListFieldsRequest', null, global);
goog.exportSymbol('proto.ListFieldsResponse', null, global);
goog.exportSymbol('proto.SearchFieldsRequest', null, global);
goog.exportSymbol('proto.SearchFieldsResponse', null, global);
goog.exportSymbol('proto.SearchUserLabelsRequest', null, global);
goog.exportSymbol('proto.SearchUserLabelsResponse', null, global);
goog.exportSymbol('proto.SearchUserSegmentsRequest', null, global);
goog.exportSymbol('proto.SearchUserSegmentsResponse', null, global);
goog.exportSymbol('proto.SearchUsersRequest', null, global);
goog.exportSymbol('proto.SearchUsersResponse', null, global);
goog.exportSymbol('proto.UnlinkFieldRequest', null, global);
goog.exportSymbol('proto.UpdateAttributeCollectionRequest', null, global);
goog.exportSymbol('proto.UpdateFieldCollectionRequest', null, global);
goog.exportSymbol('proto.UpdateFieldRequest', null, global);
goog.exportSymbol('proto.UpdateUserLabelRequest', null, global);
goog.exportSymbol('proto.UpdateUserSegmentRequest', null, global);
goog.exportSymbol('proto.UpsertAttributesRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */

proto.CreateFieldRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.CreateFieldRequest, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.CreateFieldRequest.displayName = 'proto.CreateFieldRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.ListFieldsRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.ListFieldsRequest, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ListFieldsRequest.displayName = 'proto.ListFieldsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.ListFieldsResponse = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ListFieldsResponse.repeatedFields_, null);
};

goog.inherits(proto.ListFieldsResponse, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ListFieldsResponse.displayName = 'proto.ListFieldsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.SearchFieldsRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.SearchFieldsRequest, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.SearchFieldsRequest.displayName = 'proto.SearchFieldsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.SearchFieldsResponse = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.SearchFieldsResponse.repeatedFields_, null);
};

goog.inherits(proto.SearchFieldsResponse, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.SearchFieldsResponse.displayName = 'proto.SearchFieldsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.GetFieldRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.GetFieldRequest.oneofGroups_);
};

goog.inherits(proto.GetFieldRequest, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GetFieldRequest.displayName = 'proto.GetFieldRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.UpdateFieldRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.UpdateFieldRequest, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UpdateFieldRequest.displayName = 'proto.UpdateFieldRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.DeleteFieldRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.DeleteFieldRequest, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.DeleteFieldRequest.displayName = 'proto.DeleteFieldRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.CreateFieldCollectionRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.CreateFieldCollectionRequest, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.CreateFieldCollectionRequest.displayName = 'proto.CreateFieldCollectionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.GetFieldCollectionRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.GetFieldCollectionRequest, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GetFieldCollectionRequest.displayName = 'proto.GetFieldCollectionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.ListFieldCollectionsRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.ListFieldCollectionsRequest, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ListFieldCollectionsRequest.displayName = 'proto.ListFieldCollectionsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.ListFieldCollectionsResponse = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ListFieldCollectionsResponse.repeatedFields_, null);
};

goog.inherits(proto.ListFieldCollectionsResponse, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ListFieldCollectionsResponse.displayName = 'proto.ListFieldCollectionsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.UpdateFieldCollectionRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.UpdateFieldCollectionRequest, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UpdateFieldCollectionRequest.displayName = 'proto.UpdateFieldCollectionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.LinkFieldRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.LinkFieldRequest, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.LinkFieldRequest.displayName = 'proto.LinkFieldRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.UnlinkFieldRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.UnlinkFieldRequest, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UnlinkFieldRequest.displayName = 'proto.UnlinkFieldRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.DeleteFieldCollectionRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.DeleteFieldCollectionRequest, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.DeleteFieldCollectionRequest.displayName = 'proto.DeleteFieldCollectionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.CreateUserLabelRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.CreateUserLabelRequest.repeatedFields_, null);
};

goog.inherits(proto.CreateUserLabelRequest, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.CreateUserLabelRequest.displayName = 'proto.CreateUserLabelRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.SearchUserLabelsRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.SearchUserLabelsRequest, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.SearchUserLabelsRequest.displayName = 'proto.SearchUserLabelsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.UpdateUserLabelRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.UpdateUserLabelRequest.repeatedFields_, null);
};

goog.inherits(proto.UpdateUserLabelRequest, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UpdateUserLabelRequest.displayName = 'proto.UpdateUserLabelRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.SearchUserLabelsResponse = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.SearchUserLabelsResponse.repeatedFields_, null);
};

goog.inherits(proto.SearchUserLabelsResponse, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.SearchUserLabelsResponse.displayName = 'proto.SearchUserLabelsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.DeleteUserLabelRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.DeleteUserLabelRequest, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.DeleteUserLabelRequest.displayName = 'proto.DeleteUserLabelRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.CreateUserSegmentRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.CreateUserSegmentRequest.repeatedFields_, null);
};

goog.inherits(proto.CreateUserSegmentRequest, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.CreateUserSegmentRequest.displayName = 'proto.CreateUserSegmentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.SearchUserSegmentsRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.SearchUserSegmentsRequest, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.SearchUserSegmentsRequest.displayName = 'proto.SearchUserSegmentsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.SearchUserSegmentsResponse = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.SearchUserSegmentsResponse.repeatedFields_, null);
};

goog.inherits(proto.SearchUserSegmentsResponse, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.SearchUserSegmentsResponse.displayName = 'proto.SearchUserSegmentsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.UpdateUserSegmentRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.UpdateUserSegmentRequest.repeatedFields_, null);
};

goog.inherits(proto.UpdateUserSegmentRequest, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UpdateUserSegmentRequest.displayName = 'proto.UpdateUserSegmentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.DeleteUserSegmentRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.DeleteUserSegmentRequest, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.DeleteUserSegmentRequest.displayName = 'proto.DeleteUserSegmentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.CreateAttributeCollectionRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.CreateAttributeCollectionRequest.repeatedFields_, null);
};

goog.inherits(proto.CreateAttributeCollectionRequest, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.CreateAttributeCollectionRequest.displayName = 'proto.CreateAttributeCollectionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.ListAttributeCollectionsRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.ListAttributeCollectionsRequest, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ListAttributeCollectionsRequest.displayName = 'proto.ListAttributeCollectionsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.ListAttributeCollectionsResponse = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ListAttributeCollectionsResponse.repeatedFields_, null);
};

goog.inherits(proto.ListAttributeCollectionsResponse, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ListAttributeCollectionsResponse.displayName = 'proto.ListAttributeCollectionsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.UpsertAttributesRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.UpsertAttributesRequest.repeatedFields_, null);
};

goog.inherits(proto.UpsertAttributesRequest, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UpsertAttributesRequest.displayName = 'proto.UpsertAttributesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.GetAttributeCollectionRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.GetAttributeCollectionRequest, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GetAttributeCollectionRequest.displayName = 'proto.GetAttributeCollectionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.SearchUsersRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.SearchUsersRequest, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.SearchUsersRequest.displayName = 'proto.SearchUsersRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.UpdateAttributeCollectionRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.UpdateAttributeCollectionRequest.repeatedFields_, null);
};

goog.inherits(proto.UpdateAttributeCollectionRequest, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UpdateAttributeCollectionRequest.displayName = 'proto.UpdateAttributeCollectionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.DeleteAttributeCollectionRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.DeleteAttributeCollectionRequest, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.DeleteAttributeCollectionRequest.displayName = 'proto.DeleteAttributeCollectionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.SearchUsersResponse = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.SearchUsersResponse.repeatedFields_, null);
};

goog.inherits(proto.SearchUsersResponse, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.SearchUsersResponse.displayName = 'proto.SearchUsersResponse';
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.CreateFieldRequest.prototype.toObject = function (opt_includeInstance) {
    return proto.CreateFieldRequest.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.CreateFieldRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.CreateFieldRequest.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      name: jspb.Message.getFieldWithDefault(msg, 1, ""),
      dataType: jspb.Message.getFieldWithDefault(msg, 2, 0),
      fieldCollectionType: jspb.Message.getFieldWithDefault(msg, 3, 0),
      inputType: jspb.Message.getFieldWithDefault(msg, 4, 0),
      chatbotId: (f = msg.getChatbotId()) && google_protobuf_wrappers_pb.Int64Value.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.CreateFieldRequest}
 */


proto.CreateFieldRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.CreateFieldRequest();
  return proto.CreateFieldRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.CreateFieldRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.CreateFieldRequest}
 */


proto.CreateFieldRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setName(value);
        break;

      case 2:
        var value =
        /** @type {!proto.e.DataType} */
        reader.readEnum();
        msg.setDataType(value);
        break;

      case 3:
        var value =
        /** @type {!proto.e.FieldCollectionType} */
        reader.readEnum();
        msg.setFieldCollectionType(value);
        break;

      case 4:
        var value =
        /** @type {!proto.e.InputType} */
        reader.readEnum();
        msg.setInputType(value);
        break;

      case 16:
        var value = new google_protobuf_wrappers_pb.Int64Value();
        reader.readMessage(value, google_protobuf_wrappers_pb.Int64Value.deserializeBinaryFromReader);
        msg.setChatbotId(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.CreateFieldRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.CreateFieldRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.CreateFieldRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.CreateFieldRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getName();

  if (f.length > 0) {
    writer.writeString(1, f);
  }

  f = message.getDataType();

  if (f !== 0.0) {
    writer.writeEnum(2, f);
  }

  f = message.getFieldCollectionType();

  if (f !== 0.0) {
    writer.writeEnum(3, f);
  }

  f = message.getInputType();

  if (f !== 0.0) {
    writer.writeEnum(4, f);
  }

  f = message.getChatbotId();

  if (f != null) {
    writer.writeMessage(16, f, google_protobuf_wrappers_pb.Int64Value.serializeBinaryToWriter);
  }
};
/**
 * optional string name = 1;
 * @return {string}
 */


proto.CreateFieldRequest.prototype.getName = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 1, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.CreateFieldRequest} returns this
 */


proto.CreateFieldRequest.prototype.setName = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional e.DataType data_type = 2;
 * @return {!proto.e.DataType}
 */


proto.CreateFieldRequest.prototype.getDataType = function () {
  return (
    /** @type {!proto.e.DataType} */
    jspb.Message.getFieldWithDefault(this, 2, 0)
  );
};
/**
 * @param {!proto.e.DataType} value
 * @return {!proto.CreateFieldRequest} returns this
 */


proto.CreateFieldRequest.prototype.setDataType = function (value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};
/**
 * optional e.FieldCollectionType field_collection_type = 3;
 * @return {!proto.e.FieldCollectionType}
 */


proto.CreateFieldRequest.prototype.getFieldCollectionType = function () {
  return (
    /** @type {!proto.e.FieldCollectionType} */
    jspb.Message.getFieldWithDefault(this, 3, 0)
  );
};
/**
 * @param {!proto.e.FieldCollectionType} value
 * @return {!proto.CreateFieldRequest} returns this
 */


proto.CreateFieldRequest.prototype.setFieldCollectionType = function (value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};
/**
 * optional e.InputType input_type = 4;
 * @return {!proto.e.InputType}
 */


proto.CreateFieldRequest.prototype.getInputType = function () {
  return (
    /** @type {!proto.e.InputType} */
    jspb.Message.getFieldWithDefault(this, 4, 0)
  );
};
/**
 * @param {!proto.e.InputType} value
 * @return {!proto.CreateFieldRequest} returns this
 */


proto.CreateFieldRequest.prototype.setInputType = function (value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};
/**
 * optional google.protobuf.Int64Value chatbot_id = 16;
 * @return {?proto.google.protobuf.Int64Value}
 */


proto.CreateFieldRequest.prototype.getChatbotId = function () {
  return (
    /** @type{?proto.google.protobuf.Int64Value} */
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int64Value, 16)
  );
};
/**
 * @param {?proto.google.protobuf.Int64Value|undefined} value
 * @return {!proto.CreateFieldRequest} returns this
*/


proto.CreateFieldRequest.prototype.setChatbotId = function (value) {
  return jspb.Message.setWrapperField(this, 16, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.CreateFieldRequest} returns this
 */


proto.CreateFieldRequest.prototype.clearChatbotId = function () {
  return this.setChatbotId(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.CreateFieldRequest.prototype.hasChatbotId = function () {
  return jspb.Message.getField(this, 16) != null;
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.ListFieldsRequest.prototype.toObject = function (opt_includeInstance) {
    return proto.ListFieldsRequest.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.ListFieldsRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.ListFieldsRequest.toObject = function (includeInstance, msg) {
    var f,
        obj = {};

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ListFieldsRequest}
 */


proto.ListFieldsRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ListFieldsRequest();
  return proto.ListFieldsRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ListFieldsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ListFieldsRequest}
 */


proto.ListFieldsRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.ListFieldsRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.ListFieldsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ListFieldsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.ListFieldsRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */


proto.ListFieldsResponse.repeatedFields_ = [1];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.ListFieldsResponse.prototype.toObject = function (opt_includeInstance) {
    return proto.ListFieldsResponse.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.ListFieldsResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.ListFieldsResponse.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      fieldsList: jspb.Message.toObjectList(msg.getFieldsList(), entities_user_dossier_pb.Field.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ListFieldsResponse}
 */


proto.ListFieldsResponse.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ListFieldsResponse();
  return proto.ListFieldsResponse.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ListFieldsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ListFieldsResponse}
 */


proto.ListFieldsResponse.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value = new entities_user_dossier_pb.Field();
        reader.readMessage(value, entities_user_dossier_pb.Field.deserializeBinaryFromReader);
        msg.addFields(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.ListFieldsResponse.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.ListFieldsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ListFieldsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.ListFieldsResponse.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getFieldsList();

  if (f.length > 0) {
    writer.writeRepeatedMessage(1, f, entities_user_dossier_pb.Field.serializeBinaryToWriter);
  }
};
/**
 * repeated e.Field fields = 1;
 * @return {!Array<!proto.e.Field>}
 */


proto.ListFieldsResponse.prototype.getFieldsList = function () {
  return (
    /** @type{!Array<!proto.e.Field>} */
    jspb.Message.getRepeatedWrapperField(this, entities_user_dossier_pb.Field, 1)
  );
};
/**
 * @param {!Array<!proto.e.Field>} value
 * @return {!proto.ListFieldsResponse} returns this
*/


proto.ListFieldsResponse.prototype.setFieldsList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};
/**
 * @param {!proto.e.Field=} opt_value
 * @param {number=} opt_index
 * @return {!proto.e.Field}
 */


proto.ListFieldsResponse.prototype.addFields = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.e.Field, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ListFieldsResponse} returns this
 */


proto.ListFieldsResponse.prototype.clearFieldsList = function () {
  return this.setFieldsList([]);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.SearchFieldsRequest.prototype.toObject = function (opt_includeInstance) {
    return proto.SearchFieldsRequest.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.SearchFieldsRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.SearchFieldsRequest.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      keyword: jspb.Message.getFieldWithDefault(msg, 1, ""),
      chatbotId: (f = msg.getChatbotId()) && google_protobuf_wrappers_pb.Int64Value.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SearchFieldsRequest}
 */


proto.SearchFieldsRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.SearchFieldsRequest();
  return proto.SearchFieldsRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SearchFieldsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SearchFieldsRequest}
 */


proto.SearchFieldsRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setKeyword(value);
        break;

      case 16:
        var value = new google_protobuf_wrappers_pb.Int64Value();
        reader.readMessage(value, google_protobuf_wrappers_pb.Int64Value.deserializeBinaryFromReader);
        msg.setChatbotId(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.SearchFieldsRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.SearchFieldsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SearchFieldsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.SearchFieldsRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getKeyword();

  if (f.length > 0) {
    writer.writeString(1, f);
  }

  f = message.getChatbotId();

  if (f != null) {
    writer.writeMessage(16, f, google_protobuf_wrappers_pb.Int64Value.serializeBinaryToWriter);
  }
};
/**
 * optional string keyword = 1;
 * @return {string}
 */


proto.SearchFieldsRequest.prototype.getKeyword = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 1, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.SearchFieldsRequest} returns this
 */


proto.SearchFieldsRequest.prototype.setKeyword = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional google.protobuf.Int64Value chatbot_id = 16;
 * @return {?proto.google.protobuf.Int64Value}
 */


proto.SearchFieldsRequest.prototype.getChatbotId = function () {
  return (
    /** @type{?proto.google.protobuf.Int64Value} */
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int64Value, 16)
  );
};
/**
 * @param {?proto.google.protobuf.Int64Value|undefined} value
 * @return {!proto.SearchFieldsRequest} returns this
*/


proto.SearchFieldsRequest.prototype.setChatbotId = function (value) {
  return jspb.Message.setWrapperField(this, 16, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.SearchFieldsRequest} returns this
 */


proto.SearchFieldsRequest.prototype.clearChatbotId = function () {
  return this.setChatbotId(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.SearchFieldsRequest.prototype.hasChatbotId = function () {
  return jspb.Message.getField(this, 16) != null;
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */


proto.SearchFieldsResponse.repeatedFields_ = [1];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.SearchFieldsResponse.prototype.toObject = function (opt_includeInstance) {
    return proto.SearchFieldsResponse.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.SearchFieldsResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.SearchFieldsResponse.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      fieldsList: jspb.Message.toObjectList(msg.getFieldsList(), entities_user_dossier_pb.Field.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SearchFieldsResponse}
 */


proto.SearchFieldsResponse.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.SearchFieldsResponse();
  return proto.SearchFieldsResponse.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SearchFieldsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SearchFieldsResponse}
 */


proto.SearchFieldsResponse.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value = new entities_user_dossier_pb.Field();
        reader.readMessage(value, entities_user_dossier_pb.Field.deserializeBinaryFromReader);
        msg.addFields(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.SearchFieldsResponse.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.SearchFieldsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SearchFieldsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.SearchFieldsResponse.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getFieldsList();

  if (f.length > 0) {
    writer.writeRepeatedMessage(1, f, entities_user_dossier_pb.Field.serializeBinaryToWriter);
  }
};
/**
 * repeated e.Field fields = 1;
 * @return {!Array<!proto.e.Field>}
 */


proto.SearchFieldsResponse.prototype.getFieldsList = function () {
  return (
    /** @type{!Array<!proto.e.Field>} */
    jspb.Message.getRepeatedWrapperField(this, entities_user_dossier_pb.Field, 1)
  );
};
/**
 * @param {!Array<!proto.e.Field>} value
 * @return {!proto.SearchFieldsResponse} returns this
*/


proto.SearchFieldsResponse.prototype.setFieldsList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};
/**
 * @param {!proto.e.Field=} opt_value
 * @param {number=} opt_index
 * @return {!proto.e.Field}
 */


proto.SearchFieldsResponse.prototype.addFields = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.e.Field, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.SearchFieldsResponse} returns this
 */


proto.SearchFieldsResponse.prototype.clearFieldsList = function () {
  return this.setFieldsList([]);
};
/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */


proto.GetFieldRequest.oneofGroups_ = [[1, 2]];
/**
 * @enum {number}
 */

proto.GetFieldRequest.ByCase = {
  BY_NOT_SET: 0,
  FIELD_ID: 1,
  EXACT_NAME: 2
};
/**
 * @return {proto.GetFieldRequest.ByCase}
 */

proto.GetFieldRequest.prototype.getByCase = function () {
  return (
    /** @type {proto.GetFieldRequest.ByCase} */
    jspb.Message.computeOneofCase(this, proto.GetFieldRequest.oneofGroups_[0])
  );
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.GetFieldRequest.prototype.toObject = function (opt_includeInstance) {
    return proto.GetFieldRequest.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.GetFieldRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.GetFieldRequest.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      fieldId: jspb.Message.getFieldWithDefault(msg, 1, ""),
      exactName: jspb.Message.getFieldWithDefault(msg, 2, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GetFieldRequest}
 */


proto.GetFieldRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GetFieldRequest();
  return proto.GetFieldRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GetFieldRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GetFieldRequest}
 */


proto.GetFieldRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setFieldId(value);
        break;

      case 2:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setExactName(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.GetFieldRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.GetFieldRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GetFieldRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.GetFieldRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f =
  /** @type {string} */
  jspb.Message.getField(message, 1);

  if (f != null) {
    writer.writeString(1, f);
  }

  f =
  /** @type {string} */
  jspb.Message.getField(message, 2);

  if (f != null) {
    writer.writeString(2, f);
  }
};
/**
 * optional string field_id = 1;
 * @return {string}
 */


proto.GetFieldRequest.prototype.getFieldId = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 1, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.GetFieldRequest} returns this
 */


proto.GetFieldRequest.prototype.setFieldId = function (value) {
  return jspb.Message.setOneofField(this, 1, proto.GetFieldRequest.oneofGroups_[0], value);
};
/**
 * Clears the field making it undefined.
 * @return {!proto.GetFieldRequest} returns this
 */


proto.GetFieldRequest.prototype.clearFieldId = function () {
  return jspb.Message.setOneofField(this, 1, proto.GetFieldRequest.oneofGroups_[0], undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.GetFieldRequest.prototype.hasFieldId = function () {
  return jspb.Message.getField(this, 1) != null;
};
/**
 * optional string exact_name = 2;
 * @return {string}
 */


proto.GetFieldRequest.prototype.getExactName = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 2, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.GetFieldRequest} returns this
 */


proto.GetFieldRequest.prototype.setExactName = function (value) {
  return jspb.Message.setOneofField(this, 2, proto.GetFieldRequest.oneofGroups_[0], value);
};
/**
 * Clears the field making it undefined.
 * @return {!proto.GetFieldRequest} returns this
 */


proto.GetFieldRequest.prototype.clearExactName = function () {
  return jspb.Message.setOneofField(this, 2, proto.GetFieldRequest.oneofGroups_[0], undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.GetFieldRequest.prototype.hasExactName = function () {
  return jspb.Message.getField(this, 2) != null;
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.UpdateFieldRequest.prototype.toObject = function (opt_includeInstance) {
    return proto.UpdateFieldRequest.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.UpdateFieldRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.UpdateFieldRequest.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      fieldId: jspb.Message.getFieldWithDefault(msg, 1, ""),
      name: (f = msg.getName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
      dataType: jspb.Message.getFieldWithDefault(msg, 17, 0),
      inputType: jspb.Message.getFieldWithDefault(msg, 18, 0),
      fieldCollectionType: jspb.Message.getFieldWithDefault(msg, 19, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UpdateFieldRequest}
 */


proto.UpdateFieldRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UpdateFieldRequest();
  return proto.UpdateFieldRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UpdateFieldRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UpdateFieldRequest}
 */


proto.UpdateFieldRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setFieldId(value);
        break;

      case 16:
        var value = new google_protobuf_wrappers_pb.StringValue();
        reader.readMessage(value, google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
        msg.setName(value);
        break;

      case 17:
        var value =
        /** @type {!proto.e.DataType} */
        reader.readEnum();
        msg.setDataType(value);
        break;

      case 18:
        var value =
        /** @type {!proto.e.InputType} */
        reader.readEnum();
        msg.setInputType(value);
        break;

      case 19:
        var value =
        /** @type {!proto.e.FieldCollectionType} */
        reader.readEnum();
        msg.setFieldCollectionType(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.UpdateFieldRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.UpdateFieldRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UpdateFieldRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.UpdateFieldRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getFieldId();

  if (f.length > 0) {
    writer.writeString(1, f);
  }

  f = message.getName();

  if (f != null) {
    writer.writeMessage(16, f, google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter);
  }

  f = message.getDataType();

  if (f !== 0.0) {
    writer.writeEnum(17, f);
  }

  f = message.getInputType();

  if (f !== 0.0) {
    writer.writeEnum(18, f);
  }

  f = message.getFieldCollectionType();

  if (f !== 0.0) {
    writer.writeEnum(19, f);
  }
};
/**
 * optional string field_id = 1;
 * @return {string}
 */


proto.UpdateFieldRequest.prototype.getFieldId = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 1, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.UpdateFieldRequest} returns this
 */


proto.UpdateFieldRequest.prototype.setFieldId = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional google.protobuf.StringValue name = 16;
 * @return {?proto.google.protobuf.StringValue}
 */


proto.UpdateFieldRequest.prototype.getName = function () {
  return (
    /** @type{?proto.google.protobuf.StringValue} */
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 16)
  );
};
/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.UpdateFieldRequest} returns this
*/


proto.UpdateFieldRequest.prototype.setName = function (value) {
  return jspb.Message.setWrapperField(this, 16, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.UpdateFieldRequest} returns this
 */


proto.UpdateFieldRequest.prototype.clearName = function () {
  return this.setName(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.UpdateFieldRequest.prototype.hasName = function () {
  return jspb.Message.getField(this, 16) != null;
};
/**
 * optional e.DataType data_type = 17;
 * @return {!proto.e.DataType}
 */


proto.UpdateFieldRequest.prototype.getDataType = function () {
  return (
    /** @type {!proto.e.DataType} */
    jspb.Message.getFieldWithDefault(this, 17, 0)
  );
};
/**
 * @param {!proto.e.DataType} value
 * @return {!proto.UpdateFieldRequest} returns this
 */


proto.UpdateFieldRequest.prototype.setDataType = function (value) {
  return jspb.Message.setProto3EnumField(this, 17, value);
};
/**
 * optional e.InputType input_type = 18;
 * @return {!proto.e.InputType}
 */


proto.UpdateFieldRequest.prototype.getInputType = function () {
  return (
    /** @type {!proto.e.InputType} */
    jspb.Message.getFieldWithDefault(this, 18, 0)
  );
};
/**
 * @param {!proto.e.InputType} value
 * @return {!proto.UpdateFieldRequest} returns this
 */


proto.UpdateFieldRequest.prototype.setInputType = function (value) {
  return jspb.Message.setProto3EnumField(this, 18, value);
};
/**
 * optional e.FieldCollectionType field_collection_type = 19;
 * @return {!proto.e.FieldCollectionType}
 */


proto.UpdateFieldRequest.prototype.getFieldCollectionType = function () {
  return (
    /** @type {!proto.e.FieldCollectionType} */
    jspb.Message.getFieldWithDefault(this, 19, 0)
  );
};
/**
 * @param {!proto.e.FieldCollectionType} value
 * @return {!proto.UpdateFieldRequest} returns this
 */


proto.UpdateFieldRequest.prototype.setFieldCollectionType = function (value) {
  return jspb.Message.setProto3EnumField(this, 19, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.DeleteFieldRequest.prototype.toObject = function (opt_includeInstance) {
    return proto.DeleteFieldRequest.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.DeleteFieldRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.DeleteFieldRequest.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      fieldId: jspb.Message.getFieldWithDefault(msg, 1, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.DeleteFieldRequest}
 */


proto.DeleteFieldRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.DeleteFieldRequest();
  return proto.DeleteFieldRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.DeleteFieldRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.DeleteFieldRequest}
 */


proto.DeleteFieldRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setFieldId(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.DeleteFieldRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.DeleteFieldRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.DeleteFieldRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.DeleteFieldRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getFieldId();

  if (f.length > 0) {
    writer.writeString(1, f);
  }
};
/**
 * optional string field_id = 1;
 * @return {string}
 */


proto.DeleteFieldRequest.prototype.getFieldId = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 1, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.DeleteFieldRequest} returns this
 */


proto.DeleteFieldRequest.prototype.setFieldId = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.CreateFieldCollectionRequest.prototype.toObject = function (opt_includeInstance) {
    return proto.CreateFieldCollectionRequest.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.CreateFieldCollectionRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.CreateFieldCollectionRequest.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      fieldCollectionType: jspb.Message.getFieldWithDefault(msg, 1, 0),
      chatbotId: jspb.Message.getFieldWithDefault(msg, 2, 0),
      name: jspb.Message.getFieldWithDefault(msg, 3, ""),
      description: (f = msg.getDescription()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.CreateFieldCollectionRequest}
 */


proto.CreateFieldCollectionRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.CreateFieldCollectionRequest();
  return proto.CreateFieldCollectionRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.CreateFieldCollectionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.CreateFieldCollectionRequest}
 */


proto.CreateFieldCollectionRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value =
        /** @type {!proto.e.FieldCollectionType} */
        reader.readEnum();
        msg.setFieldCollectionType(value);
        break;

      case 2:
        var value =
        /** @type {number} */
        reader.readInt64();
        msg.setChatbotId(value);
        break;

      case 3:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setName(value);
        break;

      case 16:
        var value = new google_protobuf_wrappers_pb.StringValue();
        reader.readMessage(value, google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
        msg.setDescription(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.CreateFieldCollectionRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.CreateFieldCollectionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.CreateFieldCollectionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.CreateFieldCollectionRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getFieldCollectionType();

  if (f !== 0.0) {
    writer.writeEnum(1, f);
  }

  f = message.getChatbotId();

  if (f !== 0) {
    writer.writeInt64(2, f);
  }

  f = message.getName();

  if (f.length > 0) {
    writer.writeString(3, f);
  }

  f = message.getDescription();

  if (f != null) {
    writer.writeMessage(16, f, google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter);
  }
};
/**
 * optional e.FieldCollectionType field_collection_type = 1;
 * @return {!proto.e.FieldCollectionType}
 */


proto.CreateFieldCollectionRequest.prototype.getFieldCollectionType = function () {
  return (
    /** @type {!proto.e.FieldCollectionType} */
    jspb.Message.getFieldWithDefault(this, 1, 0)
  );
};
/**
 * @param {!proto.e.FieldCollectionType} value
 * @return {!proto.CreateFieldCollectionRequest} returns this
 */


proto.CreateFieldCollectionRequest.prototype.setFieldCollectionType = function (value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};
/**
 * optional int64 chatbot_id = 2;
 * @return {number}
 */


proto.CreateFieldCollectionRequest.prototype.getChatbotId = function () {
  return (
    /** @type {number} */
    jspb.Message.getFieldWithDefault(this, 2, 0)
  );
};
/**
 * @param {number} value
 * @return {!proto.CreateFieldCollectionRequest} returns this
 */


proto.CreateFieldCollectionRequest.prototype.setChatbotId = function (value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};
/**
 * optional string name = 3;
 * @return {string}
 */


proto.CreateFieldCollectionRequest.prototype.getName = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 3, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.CreateFieldCollectionRequest} returns this
 */


proto.CreateFieldCollectionRequest.prototype.setName = function (value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};
/**
 * optional google.protobuf.StringValue description = 16;
 * @return {?proto.google.protobuf.StringValue}
 */


proto.CreateFieldCollectionRequest.prototype.getDescription = function () {
  return (
    /** @type{?proto.google.protobuf.StringValue} */
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 16)
  );
};
/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.CreateFieldCollectionRequest} returns this
*/


proto.CreateFieldCollectionRequest.prototype.setDescription = function (value) {
  return jspb.Message.setWrapperField(this, 16, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.CreateFieldCollectionRequest} returns this
 */


proto.CreateFieldCollectionRequest.prototype.clearDescription = function () {
  return this.setDescription(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.CreateFieldCollectionRequest.prototype.hasDescription = function () {
  return jspb.Message.getField(this, 16) != null;
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.GetFieldCollectionRequest.prototype.toObject = function (opt_includeInstance) {
    return proto.GetFieldCollectionRequest.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.GetFieldCollectionRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.GetFieldCollectionRequest.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      fieldCollectionId: jspb.Message.getFieldWithDefault(msg, 1, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GetFieldCollectionRequest}
 */


proto.GetFieldCollectionRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GetFieldCollectionRequest();
  return proto.GetFieldCollectionRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GetFieldCollectionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GetFieldCollectionRequest}
 */


proto.GetFieldCollectionRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setFieldCollectionId(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.GetFieldCollectionRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.GetFieldCollectionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GetFieldCollectionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.GetFieldCollectionRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getFieldCollectionId();

  if (f.length > 0) {
    writer.writeString(1, f);
  }
};
/**
 * optional string field_collection_id = 1;
 * @return {string}
 */


proto.GetFieldCollectionRequest.prototype.getFieldCollectionId = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 1, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.GetFieldCollectionRequest} returns this
 */


proto.GetFieldCollectionRequest.prototype.setFieldCollectionId = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.ListFieldCollectionsRequest.prototype.toObject = function (opt_includeInstance) {
    return proto.ListFieldCollectionsRequest.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.ListFieldCollectionsRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.ListFieldCollectionsRequest.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      chatbotId: jspb.Message.getFieldWithDefault(msg, 1, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ListFieldCollectionsRequest}
 */


proto.ListFieldCollectionsRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ListFieldCollectionsRequest();
  return proto.ListFieldCollectionsRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ListFieldCollectionsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ListFieldCollectionsRequest}
 */


proto.ListFieldCollectionsRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value =
        /** @type {number} */
        reader.readInt64();
        msg.setChatbotId(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.ListFieldCollectionsRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.ListFieldCollectionsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ListFieldCollectionsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.ListFieldCollectionsRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getChatbotId();

  if (f !== 0) {
    writer.writeInt64(1, f);
  }
};
/**
 * optional int64 chatbot_id = 1;
 * @return {number}
 */


proto.ListFieldCollectionsRequest.prototype.getChatbotId = function () {
  return (
    /** @type {number} */
    jspb.Message.getFieldWithDefault(this, 1, 0)
  );
};
/**
 * @param {number} value
 * @return {!proto.ListFieldCollectionsRequest} returns this
 */


proto.ListFieldCollectionsRequest.prototype.setChatbotId = function (value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */


proto.ListFieldCollectionsResponse.repeatedFields_ = [1];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.ListFieldCollectionsResponse.prototype.toObject = function (opt_includeInstance) {
    return proto.ListFieldCollectionsResponse.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.ListFieldCollectionsResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.ListFieldCollectionsResponse.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      fieldCollectionsList: jspb.Message.toObjectList(msg.getFieldCollectionsList(), entities_user_dossier_pb.FieldCollection.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ListFieldCollectionsResponse}
 */


proto.ListFieldCollectionsResponse.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ListFieldCollectionsResponse();
  return proto.ListFieldCollectionsResponse.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ListFieldCollectionsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ListFieldCollectionsResponse}
 */


proto.ListFieldCollectionsResponse.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value = new entities_user_dossier_pb.FieldCollection();
        reader.readMessage(value, entities_user_dossier_pb.FieldCollection.deserializeBinaryFromReader);
        msg.addFieldCollections(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.ListFieldCollectionsResponse.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.ListFieldCollectionsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ListFieldCollectionsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.ListFieldCollectionsResponse.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getFieldCollectionsList();

  if (f.length > 0) {
    writer.writeRepeatedMessage(1, f, entities_user_dossier_pb.FieldCollection.serializeBinaryToWriter);
  }
};
/**
 * repeated e.FieldCollection field_collections = 1;
 * @return {!Array<!proto.e.FieldCollection>}
 */


proto.ListFieldCollectionsResponse.prototype.getFieldCollectionsList = function () {
  return (
    /** @type{!Array<!proto.e.FieldCollection>} */
    jspb.Message.getRepeatedWrapperField(this, entities_user_dossier_pb.FieldCollection, 1)
  );
};
/**
 * @param {!Array<!proto.e.FieldCollection>} value
 * @return {!proto.ListFieldCollectionsResponse} returns this
*/


proto.ListFieldCollectionsResponse.prototype.setFieldCollectionsList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};
/**
 * @param {!proto.e.FieldCollection=} opt_value
 * @param {number=} opt_index
 * @return {!proto.e.FieldCollection}
 */


proto.ListFieldCollectionsResponse.prototype.addFieldCollections = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.e.FieldCollection, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ListFieldCollectionsResponse} returns this
 */


proto.ListFieldCollectionsResponse.prototype.clearFieldCollectionsList = function () {
  return this.setFieldCollectionsList([]);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.UpdateFieldCollectionRequest.prototype.toObject = function (opt_includeInstance) {
    return proto.UpdateFieldCollectionRequest.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.UpdateFieldCollectionRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.UpdateFieldCollectionRequest.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      fieldCollectionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
      name: (f = msg.getName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
      description: (f = msg.getDescription()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UpdateFieldCollectionRequest}
 */


proto.UpdateFieldCollectionRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UpdateFieldCollectionRequest();
  return proto.UpdateFieldCollectionRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UpdateFieldCollectionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UpdateFieldCollectionRequest}
 */


proto.UpdateFieldCollectionRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setFieldCollectionId(value);
        break;

      case 16:
        var value = new google_protobuf_wrappers_pb.StringValue();
        reader.readMessage(value, google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
        msg.setName(value);
        break;

      case 17:
        var value = new google_protobuf_wrappers_pb.StringValue();
        reader.readMessage(value, google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
        msg.setDescription(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.UpdateFieldCollectionRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.UpdateFieldCollectionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UpdateFieldCollectionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.UpdateFieldCollectionRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getFieldCollectionId();

  if (f.length > 0) {
    writer.writeString(1, f);
  }

  f = message.getName();

  if (f != null) {
    writer.writeMessage(16, f, google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter);
  }

  f = message.getDescription();

  if (f != null) {
    writer.writeMessage(17, f, google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter);
  }
};
/**
 * optional string field_collection_id = 1;
 * @return {string}
 */


proto.UpdateFieldCollectionRequest.prototype.getFieldCollectionId = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 1, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.UpdateFieldCollectionRequest} returns this
 */


proto.UpdateFieldCollectionRequest.prototype.setFieldCollectionId = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional google.protobuf.StringValue name = 16;
 * @return {?proto.google.protobuf.StringValue}
 */


proto.UpdateFieldCollectionRequest.prototype.getName = function () {
  return (
    /** @type{?proto.google.protobuf.StringValue} */
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 16)
  );
};
/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.UpdateFieldCollectionRequest} returns this
*/


proto.UpdateFieldCollectionRequest.prototype.setName = function (value) {
  return jspb.Message.setWrapperField(this, 16, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.UpdateFieldCollectionRequest} returns this
 */


proto.UpdateFieldCollectionRequest.prototype.clearName = function () {
  return this.setName(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.UpdateFieldCollectionRequest.prototype.hasName = function () {
  return jspb.Message.getField(this, 16) != null;
};
/**
 * optional google.protobuf.StringValue description = 17;
 * @return {?proto.google.protobuf.StringValue}
 */


proto.UpdateFieldCollectionRequest.prototype.getDescription = function () {
  return (
    /** @type{?proto.google.protobuf.StringValue} */
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 17)
  );
};
/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.UpdateFieldCollectionRequest} returns this
*/


proto.UpdateFieldCollectionRequest.prototype.setDescription = function (value) {
  return jspb.Message.setWrapperField(this, 17, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.UpdateFieldCollectionRequest} returns this
 */


proto.UpdateFieldCollectionRequest.prototype.clearDescription = function () {
  return this.setDescription(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.UpdateFieldCollectionRequest.prototype.hasDescription = function () {
  return jspb.Message.getField(this, 17) != null;
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.LinkFieldRequest.prototype.toObject = function (opt_includeInstance) {
    return proto.LinkFieldRequest.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.LinkFieldRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.LinkFieldRequest.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      fieldCollectionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
      fieldId: jspb.Message.getFieldWithDefault(msg, 2, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.LinkFieldRequest}
 */


proto.LinkFieldRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.LinkFieldRequest();
  return proto.LinkFieldRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.LinkFieldRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.LinkFieldRequest}
 */


proto.LinkFieldRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setFieldCollectionId(value);
        break;

      case 2:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setFieldId(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.LinkFieldRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.LinkFieldRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.LinkFieldRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.LinkFieldRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getFieldCollectionId();

  if (f.length > 0) {
    writer.writeString(1, f);
  }

  f = message.getFieldId();

  if (f.length > 0) {
    writer.writeString(2, f);
  }
};
/**
 * optional string field_collection_id = 1;
 * @return {string}
 */


proto.LinkFieldRequest.prototype.getFieldCollectionId = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 1, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.LinkFieldRequest} returns this
 */


proto.LinkFieldRequest.prototype.setFieldCollectionId = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional string field_id = 2;
 * @return {string}
 */


proto.LinkFieldRequest.prototype.getFieldId = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 2, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.LinkFieldRequest} returns this
 */


proto.LinkFieldRequest.prototype.setFieldId = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.UnlinkFieldRequest.prototype.toObject = function (opt_includeInstance) {
    return proto.UnlinkFieldRequest.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.UnlinkFieldRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.UnlinkFieldRequest.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      fieldCollectionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
      fieldId: jspb.Message.getFieldWithDefault(msg, 2, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UnlinkFieldRequest}
 */


proto.UnlinkFieldRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UnlinkFieldRequest();
  return proto.UnlinkFieldRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UnlinkFieldRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UnlinkFieldRequest}
 */


proto.UnlinkFieldRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setFieldCollectionId(value);
        break;

      case 2:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setFieldId(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.UnlinkFieldRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.UnlinkFieldRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UnlinkFieldRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.UnlinkFieldRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getFieldCollectionId();

  if (f.length > 0) {
    writer.writeString(1, f);
  }

  f = message.getFieldId();

  if (f.length > 0) {
    writer.writeString(2, f);
  }
};
/**
 * optional string field_collection_id = 1;
 * @return {string}
 */


proto.UnlinkFieldRequest.prototype.getFieldCollectionId = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 1, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.UnlinkFieldRequest} returns this
 */


proto.UnlinkFieldRequest.prototype.setFieldCollectionId = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional string field_id = 2;
 * @return {string}
 */


proto.UnlinkFieldRequest.prototype.getFieldId = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 2, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.UnlinkFieldRequest} returns this
 */


proto.UnlinkFieldRequest.prototype.setFieldId = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.DeleteFieldCollectionRequest.prototype.toObject = function (opt_includeInstance) {
    return proto.DeleteFieldCollectionRequest.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.DeleteFieldCollectionRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.DeleteFieldCollectionRequest.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      fieldCollectionId: jspb.Message.getFieldWithDefault(msg, 1, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.DeleteFieldCollectionRequest}
 */


proto.DeleteFieldCollectionRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.DeleteFieldCollectionRequest();
  return proto.DeleteFieldCollectionRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.DeleteFieldCollectionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.DeleteFieldCollectionRequest}
 */


proto.DeleteFieldCollectionRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setFieldCollectionId(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.DeleteFieldCollectionRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.DeleteFieldCollectionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.DeleteFieldCollectionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.DeleteFieldCollectionRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getFieldCollectionId();

  if (f.length > 0) {
    writer.writeString(1, f);
  }
};
/**
 * optional string field_collection_id = 1;
 * @return {string}
 */


proto.DeleteFieldCollectionRequest.prototype.getFieldCollectionId = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 1, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.DeleteFieldCollectionRequest} returns this
 */


proto.DeleteFieldCollectionRequest.prototype.setFieldCollectionId = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */


proto.CreateUserLabelRequest.repeatedFields_ = [2];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.CreateUserLabelRequest.prototype.toObject = function (opt_includeInstance) {
    return proto.CreateUserLabelRequest.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.CreateUserLabelRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.CreateUserLabelRequest.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      name: jspb.Message.getFieldWithDefault(msg, 1, ""),
      conditionsList: jspb.Message.toObjectList(msg.getConditionsList(), entities_user_dossier_pb.Condition.toObject, includeInstance),
      operator: jspb.Message.getFieldWithDefault(msg, 3, 0),
      chatbotId: (f = msg.getChatbotId()) && google_protobuf_wrappers_pb.Int64Value.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.CreateUserLabelRequest}
 */


proto.CreateUserLabelRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.CreateUserLabelRequest();
  return proto.CreateUserLabelRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.CreateUserLabelRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.CreateUserLabelRequest}
 */


proto.CreateUserLabelRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setName(value);
        break;

      case 2:
        var value = new entities_user_dossier_pb.Condition();
        reader.readMessage(value, entities_user_dossier_pb.Condition.deserializeBinaryFromReader);
        msg.addConditions(value);
        break;

      case 3:
        var value =
        /** @type {!proto.e.LogicalOperator} */
        reader.readEnum();
        msg.setOperator(value);
        break;

      case 16:
        var value = new google_protobuf_wrappers_pb.Int64Value();
        reader.readMessage(value, google_protobuf_wrappers_pb.Int64Value.deserializeBinaryFromReader);
        msg.setChatbotId(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.CreateUserLabelRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.CreateUserLabelRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.CreateUserLabelRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.CreateUserLabelRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getName();

  if (f.length > 0) {
    writer.writeString(1, f);
  }

  f = message.getConditionsList();

  if (f.length > 0) {
    writer.writeRepeatedMessage(2, f, entities_user_dossier_pb.Condition.serializeBinaryToWriter);
  }

  f = message.getOperator();

  if (f !== 0.0) {
    writer.writeEnum(3, f);
  }

  f = message.getChatbotId();

  if (f != null) {
    writer.writeMessage(16, f, google_protobuf_wrappers_pb.Int64Value.serializeBinaryToWriter);
  }
};
/**
 * optional string name = 1;
 * @return {string}
 */


proto.CreateUserLabelRequest.prototype.getName = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 1, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.CreateUserLabelRequest} returns this
 */


proto.CreateUserLabelRequest.prototype.setName = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * repeated e.Condition conditions = 2;
 * @return {!Array<!proto.e.Condition>}
 */


proto.CreateUserLabelRequest.prototype.getConditionsList = function () {
  return (
    /** @type{!Array<!proto.e.Condition>} */
    jspb.Message.getRepeatedWrapperField(this, entities_user_dossier_pb.Condition, 2)
  );
};
/**
 * @param {!Array<!proto.e.Condition>} value
 * @return {!proto.CreateUserLabelRequest} returns this
*/


proto.CreateUserLabelRequest.prototype.setConditionsList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};
/**
 * @param {!proto.e.Condition=} opt_value
 * @param {number=} opt_index
 * @return {!proto.e.Condition}
 */


proto.CreateUserLabelRequest.prototype.addConditions = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.e.Condition, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.CreateUserLabelRequest} returns this
 */


proto.CreateUserLabelRequest.prototype.clearConditionsList = function () {
  return this.setConditionsList([]);
};
/**
 * optional e.LogicalOperator operator = 3;
 * @return {!proto.e.LogicalOperator}
 */


proto.CreateUserLabelRequest.prototype.getOperator = function () {
  return (
    /** @type {!proto.e.LogicalOperator} */
    jspb.Message.getFieldWithDefault(this, 3, 0)
  );
};
/**
 * @param {!proto.e.LogicalOperator} value
 * @return {!proto.CreateUserLabelRequest} returns this
 */


proto.CreateUserLabelRequest.prototype.setOperator = function (value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};
/**
 * optional google.protobuf.Int64Value chatbot_id = 16;
 * @return {?proto.google.protobuf.Int64Value}
 */


proto.CreateUserLabelRequest.prototype.getChatbotId = function () {
  return (
    /** @type{?proto.google.protobuf.Int64Value} */
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int64Value, 16)
  );
};
/**
 * @param {?proto.google.protobuf.Int64Value|undefined} value
 * @return {!proto.CreateUserLabelRequest} returns this
*/


proto.CreateUserLabelRequest.prototype.setChatbotId = function (value) {
  return jspb.Message.setWrapperField(this, 16, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.CreateUserLabelRequest} returns this
 */


proto.CreateUserLabelRequest.prototype.clearChatbotId = function () {
  return this.setChatbotId(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.CreateUserLabelRequest.prototype.hasChatbotId = function () {
  return jspb.Message.getField(this, 16) != null;
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.SearchUserLabelsRequest.prototype.toObject = function (opt_includeInstance) {
    return proto.SearchUserLabelsRequest.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.SearchUserLabelsRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.SearchUserLabelsRequest.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      keyword: jspb.Message.getFieldWithDefault(msg, 1, ""),
      chatbotId: (f = msg.getChatbotId()) && google_protobuf_wrappers_pb.Int64Value.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SearchUserLabelsRequest}
 */


proto.SearchUserLabelsRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.SearchUserLabelsRequest();
  return proto.SearchUserLabelsRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SearchUserLabelsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SearchUserLabelsRequest}
 */


proto.SearchUserLabelsRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setKeyword(value);
        break;

      case 16:
        var value = new google_protobuf_wrappers_pb.Int64Value();
        reader.readMessage(value, google_protobuf_wrappers_pb.Int64Value.deserializeBinaryFromReader);
        msg.setChatbotId(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.SearchUserLabelsRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.SearchUserLabelsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SearchUserLabelsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.SearchUserLabelsRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getKeyword();

  if (f.length > 0) {
    writer.writeString(1, f);
  }

  f = message.getChatbotId();

  if (f != null) {
    writer.writeMessage(16, f, google_protobuf_wrappers_pb.Int64Value.serializeBinaryToWriter);
  }
};
/**
 * optional string keyword = 1;
 * @return {string}
 */


proto.SearchUserLabelsRequest.prototype.getKeyword = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 1, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.SearchUserLabelsRequest} returns this
 */


proto.SearchUserLabelsRequest.prototype.setKeyword = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional google.protobuf.Int64Value chatbot_id = 16;
 * @return {?proto.google.protobuf.Int64Value}
 */


proto.SearchUserLabelsRequest.prototype.getChatbotId = function () {
  return (
    /** @type{?proto.google.protobuf.Int64Value} */
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int64Value, 16)
  );
};
/**
 * @param {?proto.google.protobuf.Int64Value|undefined} value
 * @return {!proto.SearchUserLabelsRequest} returns this
*/


proto.SearchUserLabelsRequest.prototype.setChatbotId = function (value) {
  return jspb.Message.setWrapperField(this, 16, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.SearchUserLabelsRequest} returns this
 */


proto.SearchUserLabelsRequest.prototype.clearChatbotId = function () {
  return this.setChatbotId(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.SearchUserLabelsRequest.prototype.hasChatbotId = function () {
  return jspb.Message.getField(this, 16) != null;
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */


proto.UpdateUserLabelRequest.repeatedFields_ = [17];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.UpdateUserLabelRequest.prototype.toObject = function (opt_includeInstance) {
    return proto.UpdateUserLabelRequest.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.UpdateUserLabelRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.UpdateUserLabelRequest.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      userLabelId: jspb.Message.getFieldWithDefault(msg, 1, ""),
      name: (f = msg.getName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
      conditionsList: jspb.Message.toObjectList(msg.getConditionsList(), entities_user_dossier_pb.Condition.toObject, includeInstance),
      operator: jspb.Message.getFieldWithDefault(msg, 18, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UpdateUserLabelRequest}
 */


proto.UpdateUserLabelRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UpdateUserLabelRequest();
  return proto.UpdateUserLabelRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UpdateUserLabelRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UpdateUserLabelRequest}
 */


proto.UpdateUserLabelRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setUserLabelId(value);
        break;

      case 16:
        var value = new google_protobuf_wrappers_pb.StringValue();
        reader.readMessage(value, google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
        msg.setName(value);
        break;

      case 17:
        var value = new entities_user_dossier_pb.Condition();
        reader.readMessage(value, entities_user_dossier_pb.Condition.deserializeBinaryFromReader);
        msg.addConditions(value);
        break;

      case 18:
        var value =
        /** @type {!proto.e.LogicalOperator} */
        reader.readEnum();
        msg.setOperator(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.UpdateUserLabelRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.UpdateUserLabelRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UpdateUserLabelRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.UpdateUserLabelRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getUserLabelId();

  if (f.length > 0) {
    writer.writeString(1, f);
  }

  f = message.getName();

  if (f != null) {
    writer.writeMessage(16, f, google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter);
  }

  f = message.getConditionsList();

  if (f.length > 0) {
    writer.writeRepeatedMessage(17, f, entities_user_dossier_pb.Condition.serializeBinaryToWriter);
  }

  f = message.getOperator();

  if (f !== 0.0) {
    writer.writeEnum(18, f);
  }
};
/**
 * optional string user_label_id = 1;
 * @return {string}
 */


proto.UpdateUserLabelRequest.prototype.getUserLabelId = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 1, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.UpdateUserLabelRequest} returns this
 */


proto.UpdateUserLabelRequest.prototype.setUserLabelId = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional google.protobuf.StringValue name = 16;
 * @return {?proto.google.protobuf.StringValue}
 */


proto.UpdateUserLabelRequest.prototype.getName = function () {
  return (
    /** @type{?proto.google.protobuf.StringValue} */
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 16)
  );
};
/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.UpdateUserLabelRequest} returns this
*/


proto.UpdateUserLabelRequest.prototype.setName = function (value) {
  return jspb.Message.setWrapperField(this, 16, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.UpdateUserLabelRequest} returns this
 */


proto.UpdateUserLabelRequest.prototype.clearName = function () {
  return this.setName(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.UpdateUserLabelRequest.prototype.hasName = function () {
  return jspb.Message.getField(this, 16) != null;
};
/**
 * repeated e.Condition conditions = 17;
 * @return {!Array<!proto.e.Condition>}
 */


proto.UpdateUserLabelRequest.prototype.getConditionsList = function () {
  return (
    /** @type{!Array<!proto.e.Condition>} */
    jspb.Message.getRepeatedWrapperField(this, entities_user_dossier_pb.Condition, 17)
  );
};
/**
 * @param {!Array<!proto.e.Condition>} value
 * @return {!proto.UpdateUserLabelRequest} returns this
*/


proto.UpdateUserLabelRequest.prototype.setConditionsList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 17, value);
};
/**
 * @param {!proto.e.Condition=} opt_value
 * @param {number=} opt_index
 * @return {!proto.e.Condition}
 */


proto.UpdateUserLabelRequest.prototype.addConditions = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 17, opt_value, proto.e.Condition, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.UpdateUserLabelRequest} returns this
 */


proto.UpdateUserLabelRequest.prototype.clearConditionsList = function () {
  return this.setConditionsList([]);
};
/**
 * optional e.LogicalOperator operator = 18;
 * @return {!proto.e.LogicalOperator}
 */


proto.UpdateUserLabelRequest.prototype.getOperator = function () {
  return (
    /** @type {!proto.e.LogicalOperator} */
    jspb.Message.getFieldWithDefault(this, 18, 0)
  );
};
/**
 * @param {!proto.e.LogicalOperator} value
 * @return {!proto.UpdateUserLabelRequest} returns this
 */


proto.UpdateUserLabelRequest.prototype.setOperator = function (value) {
  return jspb.Message.setProto3EnumField(this, 18, value);
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */


proto.SearchUserLabelsResponse.repeatedFields_ = [1];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.SearchUserLabelsResponse.prototype.toObject = function (opt_includeInstance) {
    return proto.SearchUserLabelsResponse.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.SearchUserLabelsResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.SearchUserLabelsResponse.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      userLabelsList: jspb.Message.toObjectList(msg.getUserLabelsList(), entities_user_dossier_pb.UserLabel.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SearchUserLabelsResponse}
 */


proto.SearchUserLabelsResponse.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.SearchUserLabelsResponse();
  return proto.SearchUserLabelsResponse.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SearchUserLabelsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SearchUserLabelsResponse}
 */


proto.SearchUserLabelsResponse.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value = new entities_user_dossier_pb.UserLabel();
        reader.readMessage(value, entities_user_dossier_pb.UserLabel.deserializeBinaryFromReader);
        msg.addUserLabels(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.SearchUserLabelsResponse.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.SearchUserLabelsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SearchUserLabelsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.SearchUserLabelsResponse.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getUserLabelsList();

  if (f.length > 0) {
    writer.writeRepeatedMessage(1, f, entities_user_dossier_pb.UserLabel.serializeBinaryToWriter);
  }
};
/**
 * repeated e.UserLabel user_labels = 1;
 * @return {!Array<!proto.e.UserLabel>}
 */


proto.SearchUserLabelsResponse.prototype.getUserLabelsList = function () {
  return (
    /** @type{!Array<!proto.e.UserLabel>} */
    jspb.Message.getRepeatedWrapperField(this, entities_user_dossier_pb.UserLabel, 1)
  );
};
/**
 * @param {!Array<!proto.e.UserLabel>} value
 * @return {!proto.SearchUserLabelsResponse} returns this
*/


proto.SearchUserLabelsResponse.prototype.setUserLabelsList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};
/**
 * @param {!proto.e.UserLabel=} opt_value
 * @param {number=} opt_index
 * @return {!proto.e.UserLabel}
 */


proto.SearchUserLabelsResponse.prototype.addUserLabels = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.e.UserLabel, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.SearchUserLabelsResponse} returns this
 */


proto.SearchUserLabelsResponse.prototype.clearUserLabelsList = function () {
  return this.setUserLabelsList([]);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.DeleteUserLabelRequest.prototype.toObject = function (opt_includeInstance) {
    return proto.DeleteUserLabelRequest.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.DeleteUserLabelRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.DeleteUserLabelRequest.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      userLabelId: jspb.Message.getFieldWithDefault(msg, 1, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.DeleteUserLabelRequest}
 */


proto.DeleteUserLabelRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.DeleteUserLabelRequest();
  return proto.DeleteUserLabelRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.DeleteUserLabelRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.DeleteUserLabelRequest}
 */


proto.DeleteUserLabelRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setUserLabelId(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.DeleteUserLabelRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.DeleteUserLabelRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.DeleteUserLabelRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.DeleteUserLabelRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getUserLabelId();

  if (f.length > 0) {
    writer.writeString(1, f);
  }
};
/**
 * optional string user_label_id = 1;
 * @return {string}
 */


proto.DeleteUserLabelRequest.prototype.getUserLabelId = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 1, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.DeleteUserLabelRequest} returns this
 */


proto.DeleteUserLabelRequest.prototype.setUserLabelId = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */


proto.CreateUserSegmentRequest.repeatedFields_ = [2];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.CreateUserSegmentRequest.prototype.toObject = function (opt_includeInstance) {
    return proto.CreateUserSegmentRequest.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.CreateUserSegmentRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.CreateUserSegmentRequest.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      name: jspb.Message.getFieldWithDefault(msg, 1, ""),
      userLabelIdsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
      operator: jspb.Message.getFieldWithDefault(msg, 3, 0),
      chatbotId: (f = msg.getChatbotId()) && google_protobuf_wrappers_pb.Int64Value.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.CreateUserSegmentRequest}
 */


proto.CreateUserSegmentRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.CreateUserSegmentRequest();
  return proto.CreateUserSegmentRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.CreateUserSegmentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.CreateUserSegmentRequest}
 */


proto.CreateUserSegmentRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setName(value);
        break;

      case 2:
        var value =
        /** @type {string} */
        reader.readString();
        msg.addUserLabelIds(value);
        break;

      case 3:
        var value =
        /** @type {!proto.e.LogicalOperator} */
        reader.readEnum();
        msg.setOperator(value);
        break;

      case 16:
        var value = new google_protobuf_wrappers_pb.Int64Value();
        reader.readMessage(value, google_protobuf_wrappers_pb.Int64Value.deserializeBinaryFromReader);
        msg.setChatbotId(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.CreateUserSegmentRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.CreateUserSegmentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.CreateUserSegmentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.CreateUserSegmentRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getName();

  if (f.length > 0) {
    writer.writeString(1, f);
  }

  f = message.getUserLabelIdsList();

  if (f.length > 0) {
    writer.writeRepeatedString(2, f);
  }

  f = message.getOperator();

  if (f !== 0.0) {
    writer.writeEnum(3, f);
  }

  f = message.getChatbotId();

  if (f != null) {
    writer.writeMessage(16, f, google_protobuf_wrappers_pb.Int64Value.serializeBinaryToWriter);
  }
};
/**
 * optional string name = 1;
 * @return {string}
 */


proto.CreateUserSegmentRequest.prototype.getName = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 1, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.CreateUserSegmentRequest} returns this
 */


proto.CreateUserSegmentRequest.prototype.setName = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * repeated string user_label_ids = 2;
 * @return {!Array<string>}
 */


proto.CreateUserSegmentRequest.prototype.getUserLabelIdsList = function () {
  return (
    /** @type {!Array<string>} */
    jspb.Message.getRepeatedField(this, 2)
  );
};
/**
 * @param {!Array<string>} value
 * @return {!proto.CreateUserSegmentRequest} returns this
 */


proto.CreateUserSegmentRequest.prototype.setUserLabelIdsList = function (value) {
  return jspb.Message.setField(this, 2, value || []);
};
/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.CreateUserSegmentRequest} returns this
 */


proto.CreateUserSegmentRequest.prototype.addUserLabelIds = function (value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.CreateUserSegmentRequest} returns this
 */


proto.CreateUserSegmentRequest.prototype.clearUserLabelIdsList = function () {
  return this.setUserLabelIdsList([]);
};
/**
 * optional e.LogicalOperator operator = 3;
 * @return {!proto.e.LogicalOperator}
 */


proto.CreateUserSegmentRequest.prototype.getOperator = function () {
  return (
    /** @type {!proto.e.LogicalOperator} */
    jspb.Message.getFieldWithDefault(this, 3, 0)
  );
};
/**
 * @param {!proto.e.LogicalOperator} value
 * @return {!proto.CreateUserSegmentRequest} returns this
 */


proto.CreateUserSegmentRequest.prototype.setOperator = function (value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};
/**
 * optional google.protobuf.Int64Value chatbot_id = 16;
 * @return {?proto.google.protobuf.Int64Value}
 */


proto.CreateUserSegmentRequest.prototype.getChatbotId = function () {
  return (
    /** @type{?proto.google.protobuf.Int64Value} */
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int64Value, 16)
  );
};
/**
 * @param {?proto.google.protobuf.Int64Value|undefined} value
 * @return {!proto.CreateUserSegmentRequest} returns this
*/


proto.CreateUserSegmentRequest.prototype.setChatbotId = function (value) {
  return jspb.Message.setWrapperField(this, 16, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.CreateUserSegmentRequest} returns this
 */


proto.CreateUserSegmentRequest.prototype.clearChatbotId = function () {
  return this.setChatbotId(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.CreateUserSegmentRequest.prototype.hasChatbotId = function () {
  return jspb.Message.getField(this, 16) != null;
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.SearchUserSegmentsRequest.prototype.toObject = function (opt_includeInstance) {
    return proto.SearchUserSegmentsRequest.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.SearchUserSegmentsRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.SearchUserSegmentsRequest.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      keyword: jspb.Message.getFieldWithDefault(msg, 1, ""),
      chatbotId: (f = msg.getChatbotId()) && google_protobuf_wrappers_pb.Int64Value.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SearchUserSegmentsRequest}
 */


proto.SearchUserSegmentsRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.SearchUserSegmentsRequest();
  return proto.SearchUserSegmentsRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SearchUserSegmentsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SearchUserSegmentsRequest}
 */


proto.SearchUserSegmentsRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setKeyword(value);
        break;

      case 16:
        var value = new google_protobuf_wrappers_pb.Int64Value();
        reader.readMessage(value, google_protobuf_wrappers_pb.Int64Value.deserializeBinaryFromReader);
        msg.setChatbotId(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.SearchUserSegmentsRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.SearchUserSegmentsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SearchUserSegmentsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.SearchUserSegmentsRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getKeyword();

  if (f.length > 0) {
    writer.writeString(1, f);
  }

  f = message.getChatbotId();

  if (f != null) {
    writer.writeMessage(16, f, google_protobuf_wrappers_pb.Int64Value.serializeBinaryToWriter);
  }
};
/**
 * optional string keyword = 1;
 * @return {string}
 */


proto.SearchUserSegmentsRequest.prototype.getKeyword = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 1, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.SearchUserSegmentsRequest} returns this
 */


proto.SearchUserSegmentsRequest.prototype.setKeyword = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional google.protobuf.Int64Value chatbot_id = 16;
 * @return {?proto.google.protobuf.Int64Value}
 */


proto.SearchUserSegmentsRequest.prototype.getChatbotId = function () {
  return (
    /** @type{?proto.google.protobuf.Int64Value} */
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int64Value, 16)
  );
};
/**
 * @param {?proto.google.protobuf.Int64Value|undefined} value
 * @return {!proto.SearchUserSegmentsRequest} returns this
*/


proto.SearchUserSegmentsRequest.prototype.setChatbotId = function (value) {
  return jspb.Message.setWrapperField(this, 16, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.SearchUserSegmentsRequest} returns this
 */


proto.SearchUserSegmentsRequest.prototype.clearChatbotId = function () {
  return this.setChatbotId(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.SearchUserSegmentsRequest.prototype.hasChatbotId = function () {
  return jspb.Message.getField(this, 16) != null;
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */


proto.SearchUserSegmentsResponse.repeatedFields_ = [1];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.SearchUserSegmentsResponse.prototype.toObject = function (opt_includeInstance) {
    return proto.SearchUserSegmentsResponse.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.SearchUserSegmentsResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.SearchUserSegmentsResponse.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      userSegmentsList: jspb.Message.toObjectList(msg.getUserSegmentsList(), entities_user_dossier_pb.UserSegment.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SearchUserSegmentsResponse}
 */


proto.SearchUserSegmentsResponse.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.SearchUserSegmentsResponse();
  return proto.SearchUserSegmentsResponse.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SearchUserSegmentsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SearchUserSegmentsResponse}
 */


proto.SearchUserSegmentsResponse.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value = new entities_user_dossier_pb.UserSegment();
        reader.readMessage(value, entities_user_dossier_pb.UserSegment.deserializeBinaryFromReader);
        msg.addUserSegments(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.SearchUserSegmentsResponse.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.SearchUserSegmentsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SearchUserSegmentsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.SearchUserSegmentsResponse.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getUserSegmentsList();

  if (f.length > 0) {
    writer.writeRepeatedMessage(1, f, entities_user_dossier_pb.UserSegment.serializeBinaryToWriter);
  }
};
/**
 * repeated e.UserSegment user_segments = 1;
 * @return {!Array<!proto.e.UserSegment>}
 */


proto.SearchUserSegmentsResponse.prototype.getUserSegmentsList = function () {
  return (
    /** @type{!Array<!proto.e.UserSegment>} */
    jspb.Message.getRepeatedWrapperField(this, entities_user_dossier_pb.UserSegment, 1)
  );
};
/**
 * @param {!Array<!proto.e.UserSegment>} value
 * @return {!proto.SearchUserSegmentsResponse} returns this
*/


proto.SearchUserSegmentsResponse.prototype.setUserSegmentsList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};
/**
 * @param {!proto.e.UserSegment=} opt_value
 * @param {number=} opt_index
 * @return {!proto.e.UserSegment}
 */


proto.SearchUserSegmentsResponse.prototype.addUserSegments = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.e.UserSegment, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.SearchUserSegmentsResponse} returns this
 */


proto.SearchUserSegmentsResponse.prototype.clearUserSegmentsList = function () {
  return this.setUserSegmentsList([]);
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */


proto.UpdateUserSegmentRequest.repeatedFields_ = [18];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.UpdateUserSegmentRequest.prototype.toObject = function (opt_includeInstance) {
    return proto.UpdateUserSegmentRequest.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.UpdateUserSegmentRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.UpdateUserSegmentRequest.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      userSegmentId: jspb.Message.getFieldWithDefault(msg, 1, ""),
      name: (f = msg.getName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
      description: (f = msg.getDescription()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
      userLabelIdsList: (f = jspb.Message.getRepeatedField(msg, 18)) == null ? undefined : f,
      operator: jspb.Message.getFieldWithDefault(msg, 19, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UpdateUserSegmentRequest}
 */


proto.UpdateUserSegmentRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UpdateUserSegmentRequest();
  return proto.UpdateUserSegmentRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UpdateUserSegmentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UpdateUserSegmentRequest}
 */


proto.UpdateUserSegmentRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setUserSegmentId(value);
        break;

      case 16:
        var value = new google_protobuf_wrappers_pb.StringValue();
        reader.readMessage(value, google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
        msg.setName(value);
        break;

      case 17:
        var value = new google_protobuf_wrappers_pb.StringValue();
        reader.readMessage(value, google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
        msg.setDescription(value);
        break;

      case 18:
        var value =
        /** @type {string} */
        reader.readString();
        msg.addUserLabelIds(value);
        break;

      case 19:
        var value =
        /** @type {!proto.e.LogicalOperator} */
        reader.readEnum();
        msg.setOperator(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.UpdateUserSegmentRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.UpdateUserSegmentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UpdateUserSegmentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.UpdateUserSegmentRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getUserSegmentId();

  if (f.length > 0) {
    writer.writeString(1, f);
  }

  f = message.getName();

  if (f != null) {
    writer.writeMessage(16, f, google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter);
  }

  f = message.getDescription();

  if (f != null) {
    writer.writeMessage(17, f, google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter);
  }

  f = message.getUserLabelIdsList();

  if (f.length > 0) {
    writer.writeRepeatedString(18, f);
  }

  f = message.getOperator();

  if (f !== 0.0) {
    writer.writeEnum(19, f);
  }
};
/**
 * optional string user_segment_id = 1;
 * @return {string}
 */


proto.UpdateUserSegmentRequest.prototype.getUserSegmentId = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 1, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.UpdateUserSegmentRequest} returns this
 */


proto.UpdateUserSegmentRequest.prototype.setUserSegmentId = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional google.protobuf.StringValue name = 16;
 * @return {?proto.google.protobuf.StringValue}
 */


proto.UpdateUserSegmentRequest.prototype.getName = function () {
  return (
    /** @type{?proto.google.protobuf.StringValue} */
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 16)
  );
};
/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.UpdateUserSegmentRequest} returns this
*/


proto.UpdateUserSegmentRequest.prototype.setName = function (value) {
  return jspb.Message.setWrapperField(this, 16, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.UpdateUserSegmentRequest} returns this
 */


proto.UpdateUserSegmentRequest.prototype.clearName = function () {
  return this.setName(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.UpdateUserSegmentRequest.prototype.hasName = function () {
  return jspb.Message.getField(this, 16) != null;
};
/**
 * optional google.protobuf.StringValue description = 17;
 * @return {?proto.google.protobuf.StringValue}
 */


proto.UpdateUserSegmentRequest.prototype.getDescription = function () {
  return (
    /** @type{?proto.google.protobuf.StringValue} */
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 17)
  );
};
/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.UpdateUserSegmentRequest} returns this
*/


proto.UpdateUserSegmentRequest.prototype.setDescription = function (value) {
  return jspb.Message.setWrapperField(this, 17, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.UpdateUserSegmentRequest} returns this
 */


proto.UpdateUserSegmentRequest.prototype.clearDescription = function () {
  return this.setDescription(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.UpdateUserSegmentRequest.prototype.hasDescription = function () {
  return jspb.Message.getField(this, 17) != null;
};
/**
 * repeated string user_label_ids = 18;
 * @return {!Array<string>}
 */


proto.UpdateUserSegmentRequest.prototype.getUserLabelIdsList = function () {
  return (
    /** @type {!Array<string>} */
    jspb.Message.getRepeatedField(this, 18)
  );
};
/**
 * @param {!Array<string>} value
 * @return {!proto.UpdateUserSegmentRequest} returns this
 */


proto.UpdateUserSegmentRequest.prototype.setUserLabelIdsList = function (value) {
  return jspb.Message.setField(this, 18, value || []);
};
/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.UpdateUserSegmentRequest} returns this
 */


proto.UpdateUserSegmentRequest.prototype.addUserLabelIds = function (value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 18, value, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.UpdateUserSegmentRequest} returns this
 */


proto.UpdateUserSegmentRequest.prototype.clearUserLabelIdsList = function () {
  return this.setUserLabelIdsList([]);
};
/**
 * optional e.LogicalOperator operator = 19;
 * @return {!proto.e.LogicalOperator}
 */


proto.UpdateUserSegmentRequest.prototype.getOperator = function () {
  return (
    /** @type {!proto.e.LogicalOperator} */
    jspb.Message.getFieldWithDefault(this, 19, 0)
  );
};
/**
 * @param {!proto.e.LogicalOperator} value
 * @return {!proto.UpdateUserSegmentRequest} returns this
 */


proto.UpdateUserSegmentRequest.prototype.setOperator = function (value) {
  return jspb.Message.setProto3EnumField(this, 19, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.DeleteUserSegmentRequest.prototype.toObject = function (opt_includeInstance) {
    return proto.DeleteUserSegmentRequest.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.DeleteUserSegmentRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.DeleteUserSegmentRequest.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      userSegmentId: jspb.Message.getFieldWithDefault(msg, 1, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.DeleteUserSegmentRequest}
 */


proto.DeleteUserSegmentRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.DeleteUserSegmentRequest();
  return proto.DeleteUserSegmentRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.DeleteUserSegmentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.DeleteUserSegmentRequest}
 */


proto.DeleteUserSegmentRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setUserSegmentId(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.DeleteUserSegmentRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.DeleteUserSegmentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.DeleteUserSegmentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.DeleteUserSegmentRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getUserSegmentId();

  if (f.length > 0) {
    writer.writeString(1, f);
  }
};
/**
 * optional string user_segment_id = 1;
 * @return {string}
 */


proto.DeleteUserSegmentRequest.prototype.getUserSegmentId = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 1, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.DeleteUserSegmentRequest} returns this
 */


proto.DeleteUserSegmentRequest.prototype.setUserSegmentId = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */


proto.CreateAttributeCollectionRequest.repeatedFields_ = [3];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.CreateAttributeCollectionRequest.prototype.toObject = function (opt_includeInstance) {
    return proto.CreateAttributeCollectionRequest.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.CreateAttributeCollectionRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.CreateAttributeCollectionRequest.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      fieldCollectionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
      endUserId: jspb.Message.getFieldWithDefault(msg, 2, 0),
      attributesList: jspb.Message.toObjectList(msg.getAttributesList(), entities_user_dossier_pb.Attribute.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.CreateAttributeCollectionRequest}
 */


proto.CreateAttributeCollectionRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.CreateAttributeCollectionRequest();
  return proto.CreateAttributeCollectionRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.CreateAttributeCollectionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.CreateAttributeCollectionRequest}
 */


proto.CreateAttributeCollectionRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setFieldCollectionId(value);
        break;

      case 2:
        var value =
        /** @type {number} */
        reader.readInt64();
        msg.setEndUserId(value);
        break;

      case 3:
        var value = new entities_user_dossier_pb.Attribute();
        reader.readMessage(value, entities_user_dossier_pb.Attribute.deserializeBinaryFromReader);
        msg.addAttributes(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.CreateAttributeCollectionRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.CreateAttributeCollectionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.CreateAttributeCollectionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.CreateAttributeCollectionRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getFieldCollectionId();

  if (f.length > 0) {
    writer.writeString(1, f);
  }

  f = message.getEndUserId();

  if (f !== 0) {
    writer.writeInt64(2, f);
  }

  f = message.getAttributesList();

  if (f.length > 0) {
    writer.writeRepeatedMessage(3, f, entities_user_dossier_pb.Attribute.serializeBinaryToWriter);
  }
};
/**
 * optional string field_collection_id = 1;
 * @return {string}
 */


proto.CreateAttributeCollectionRequest.prototype.getFieldCollectionId = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 1, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.CreateAttributeCollectionRequest} returns this
 */


proto.CreateAttributeCollectionRequest.prototype.setFieldCollectionId = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional int64 end_user_id = 2;
 * @return {number}
 */


proto.CreateAttributeCollectionRequest.prototype.getEndUserId = function () {
  return (
    /** @type {number} */
    jspb.Message.getFieldWithDefault(this, 2, 0)
  );
};
/**
 * @param {number} value
 * @return {!proto.CreateAttributeCollectionRequest} returns this
 */


proto.CreateAttributeCollectionRequest.prototype.setEndUserId = function (value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};
/**
 * repeated e.Attribute attributes = 3;
 * @return {!Array<!proto.e.Attribute>}
 */


proto.CreateAttributeCollectionRequest.prototype.getAttributesList = function () {
  return (
    /** @type{!Array<!proto.e.Attribute>} */
    jspb.Message.getRepeatedWrapperField(this, entities_user_dossier_pb.Attribute, 3)
  );
};
/**
 * @param {!Array<!proto.e.Attribute>} value
 * @return {!proto.CreateAttributeCollectionRequest} returns this
*/


proto.CreateAttributeCollectionRequest.prototype.setAttributesList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};
/**
 * @param {!proto.e.Attribute=} opt_value
 * @param {number=} opt_index
 * @return {!proto.e.Attribute}
 */


proto.CreateAttributeCollectionRequest.prototype.addAttributes = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.e.Attribute, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.CreateAttributeCollectionRequest} returns this
 */


proto.CreateAttributeCollectionRequest.prototype.clearAttributesList = function () {
  return this.setAttributesList([]);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.ListAttributeCollectionsRequest.prototype.toObject = function (opt_includeInstance) {
    return proto.ListAttributeCollectionsRequest.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.ListAttributeCollectionsRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.ListAttributeCollectionsRequest.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      fieldCollectionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
      endUserId: jspb.Message.getFieldWithDefault(msg, 2, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ListAttributeCollectionsRequest}
 */


proto.ListAttributeCollectionsRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ListAttributeCollectionsRequest();
  return proto.ListAttributeCollectionsRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ListAttributeCollectionsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ListAttributeCollectionsRequest}
 */


proto.ListAttributeCollectionsRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setFieldCollectionId(value);
        break;

      case 2:
        var value =
        /** @type {number} */
        reader.readInt64();
        msg.setEndUserId(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.ListAttributeCollectionsRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.ListAttributeCollectionsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ListAttributeCollectionsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.ListAttributeCollectionsRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getFieldCollectionId();

  if (f.length > 0) {
    writer.writeString(1, f);
  }

  f = message.getEndUserId();

  if (f !== 0) {
    writer.writeInt64(2, f);
  }
};
/**
 * optional string field_collection_id = 1;
 * @return {string}
 */


proto.ListAttributeCollectionsRequest.prototype.getFieldCollectionId = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 1, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.ListAttributeCollectionsRequest} returns this
 */


proto.ListAttributeCollectionsRequest.prototype.setFieldCollectionId = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional int64 end_user_id = 2;
 * @return {number}
 */


proto.ListAttributeCollectionsRequest.prototype.getEndUserId = function () {
  return (
    /** @type {number} */
    jspb.Message.getFieldWithDefault(this, 2, 0)
  );
};
/**
 * @param {number} value
 * @return {!proto.ListAttributeCollectionsRequest} returns this
 */


proto.ListAttributeCollectionsRequest.prototype.setEndUserId = function (value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */


proto.ListAttributeCollectionsResponse.repeatedFields_ = [2];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.ListAttributeCollectionsResponse.prototype.toObject = function (opt_includeInstance) {
    return proto.ListAttributeCollectionsResponse.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.ListAttributeCollectionsResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.ListAttributeCollectionsResponse.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      attributeCollectionsList: jspb.Message.toObjectList(msg.getAttributeCollectionsList(), entities_user_dossier_pb.AttributeCollection.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ListAttributeCollectionsResponse}
 */


proto.ListAttributeCollectionsResponse.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ListAttributeCollectionsResponse();
  return proto.ListAttributeCollectionsResponse.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ListAttributeCollectionsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ListAttributeCollectionsResponse}
 */


proto.ListAttributeCollectionsResponse.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 2:
        var value = new entities_user_dossier_pb.AttributeCollection();
        reader.readMessage(value, entities_user_dossier_pb.AttributeCollection.deserializeBinaryFromReader);
        msg.addAttributeCollections(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.ListAttributeCollectionsResponse.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.ListAttributeCollectionsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ListAttributeCollectionsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.ListAttributeCollectionsResponse.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getAttributeCollectionsList();

  if (f.length > 0) {
    writer.writeRepeatedMessage(2, f, entities_user_dossier_pb.AttributeCollection.serializeBinaryToWriter);
  }
};
/**
 * repeated e.AttributeCollection attribute_collections = 2;
 * @return {!Array<!proto.e.AttributeCollection>}
 */


proto.ListAttributeCollectionsResponse.prototype.getAttributeCollectionsList = function () {
  return (
    /** @type{!Array<!proto.e.AttributeCollection>} */
    jspb.Message.getRepeatedWrapperField(this, entities_user_dossier_pb.AttributeCollection, 2)
  );
};
/**
 * @param {!Array<!proto.e.AttributeCollection>} value
 * @return {!proto.ListAttributeCollectionsResponse} returns this
*/


proto.ListAttributeCollectionsResponse.prototype.setAttributeCollectionsList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};
/**
 * @param {!proto.e.AttributeCollection=} opt_value
 * @param {number=} opt_index
 * @return {!proto.e.AttributeCollection}
 */


proto.ListAttributeCollectionsResponse.prototype.addAttributeCollections = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.e.AttributeCollection, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ListAttributeCollectionsResponse} returns this
 */


proto.ListAttributeCollectionsResponse.prototype.clearAttributeCollectionsList = function () {
  return this.setAttributeCollectionsList([]);
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */


proto.UpsertAttributesRequest.repeatedFields_ = [3];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.UpsertAttributesRequest.prototype.toObject = function (opt_includeInstance) {
    return proto.UpsertAttributesRequest.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.UpsertAttributesRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.UpsertAttributesRequest.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      fieldCollectionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
      endUserId: jspb.Message.getFieldWithDefault(msg, 2, 0),
      attributesList: jspb.Message.toObjectList(msg.getAttributesList(), entities_user_dossier_pb.Attribute.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UpsertAttributesRequest}
 */


proto.UpsertAttributesRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UpsertAttributesRequest();
  return proto.UpsertAttributesRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UpsertAttributesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UpsertAttributesRequest}
 */


proto.UpsertAttributesRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setFieldCollectionId(value);
        break;

      case 2:
        var value =
        /** @type {number} */
        reader.readInt64();
        msg.setEndUserId(value);
        break;

      case 3:
        var value = new entities_user_dossier_pb.Attribute();
        reader.readMessage(value, entities_user_dossier_pb.Attribute.deserializeBinaryFromReader);
        msg.addAttributes(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.UpsertAttributesRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.UpsertAttributesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UpsertAttributesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.UpsertAttributesRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getFieldCollectionId();

  if (f.length > 0) {
    writer.writeString(1, f);
  }

  f = message.getEndUserId();

  if (f !== 0) {
    writer.writeInt64(2, f);
  }

  f = message.getAttributesList();

  if (f.length > 0) {
    writer.writeRepeatedMessage(3, f, entities_user_dossier_pb.Attribute.serializeBinaryToWriter);
  }
};
/**
 * optional string field_collection_id = 1;
 * @return {string}
 */


proto.UpsertAttributesRequest.prototype.getFieldCollectionId = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 1, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.UpsertAttributesRequest} returns this
 */


proto.UpsertAttributesRequest.prototype.setFieldCollectionId = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional int64 end_user_id = 2;
 * @return {number}
 */


proto.UpsertAttributesRequest.prototype.getEndUserId = function () {
  return (
    /** @type {number} */
    jspb.Message.getFieldWithDefault(this, 2, 0)
  );
};
/**
 * @param {number} value
 * @return {!proto.UpsertAttributesRequest} returns this
 */


proto.UpsertAttributesRequest.prototype.setEndUserId = function (value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};
/**
 * repeated e.Attribute attributes = 3;
 * @return {!Array<!proto.e.Attribute>}
 */


proto.UpsertAttributesRequest.prototype.getAttributesList = function () {
  return (
    /** @type{!Array<!proto.e.Attribute>} */
    jspb.Message.getRepeatedWrapperField(this, entities_user_dossier_pb.Attribute, 3)
  );
};
/**
 * @param {!Array<!proto.e.Attribute>} value
 * @return {!proto.UpsertAttributesRequest} returns this
*/


proto.UpsertAttributesRequest.prototype.setAttributesList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};
/**
 * @param {!proto.e.Attribute=} opt_value
 * @param {number=} opt_index
 * @return {!proto.e.Attribute}
 */


proto.UpsertAttributesRequest.prototype.addAttributes = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.e.Attribute, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.UpsertAttributesRequest} returns this
 */


proto.UpsertAttributesRequest.prototype.clearAttributesList = function () {
  return this.setAttributesList([]);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.GetAttributeCollectionRequest.prototype.toObject = function (opt_includeInstance) {
    return proto.GetAttributeCollectionRequest.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.GetAttributeCollectionRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.GetAttributeCollectionRequest.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      fieldCollectionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
      endUserId: jspb.Message.getFieldWithDefault(msg, 2, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GetAttributeCollectionRequest}
 */


proto.GetAttributeCollectionRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GetAttributeCollectionRequest();
  return proto.GetAttributeCollectionRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GetAttributeCollectionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GetAttributeCollectionRequest}
 */


proto.GetAttributeCollectionRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setFieldCollectionId(value);
        break;

      case 2:
        var value =
        /** @type {number} */
        reader.readInt64();
        msg.setEndUserId(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.GetAttributeCollectionRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.GetAttributeCollectionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GetAttributeCollectionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.GetAttributeCollectionRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getFieldCollectionId();

  if (f.length > 0) {
    writer.writeString(1, f);
  }

  f = message.getEndUserId();

  if (f !== 0) {
    writer.writeInt64(2, f);
  }
};
/**
 * optional string field_collection_id = 1;
 * @return {string}
 */


proto.GetAttributeCollectionRequest.prototype.getFieldCollectionId = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 1, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.GetAttributeCollectionRequest} returns this
 */


proto.GetAttributeCollectionRequest.prototype.setFieldCollectionId = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional int64 end_user_id = 2;
 * @return {number}
 */


proto.GetAttributeCollectionRequest.prototype.getEndUserId = function () {
  return (
    /** @type {number} */
    jspb.Message.getFieldWithDefault(this, 2, 0)
  );
};
/**
 * @param {number} value
 * @return {!proto.GetAttributeCollectionRequest} returns this
 */


proto.GetAttributeCollectionRequest.prototype.setEndUserId = function (value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.SearchUsersRequest.prototype.toObject = function (opt_includeInstance) {
    return proto.SearchUsersRequest.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.SearchUsersRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.SearchUsersRequest.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      userSegmentId: jspb.Message.getFieldWithDefault(msg, 1, ""),
      chatbotId: jspb.Message.getFieldWithDefault(msg, 2, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SearchUsersRequest}
 */


proto.SearchUsersRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.SearchUsersRequest();
  return proto.SearchUsersRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SearchUsersRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SearchUsersRequest}
 */


proto.SearchUsersRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setUserSegmentId(value);
        break;

      case 2:
        var value =
        /** @type {number} */
        reader.readInt64();
        msg.setChatbotId(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.SearchUsersRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.SearchUsersRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SearchUsersRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.SearchUsersRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getUserSegmentId();

  if (f.length > 0) {
    writer.writeString(1, f);
  }

  f = message.getChatbotId();

  if (f !== 0) {
    writer.writeInt64(2, f);
  }
};
/**
 * optional string user_segment_id = 1;
 * @return {string}
 */


proto.SearchUsersRequest.prototype.getUserSegmentId = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 1, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.SearchUsersRequest} returns this
 */


proto.SearchUsersRequest.prototype.setUserSegmentId = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional int64 chatbot_id = 2;
 * @return {number}
 */


proto.SearchUsersRequest.prototype.getChatbotId = function () {
  return (
    /** @type {number} */
    jspb.Message.getFieldWithDefault(this, 2, 0)
  );
};
/**
 * @param {number} value
 * @return {!proto.SearchUsersRequest} returns this
 */


proto.SearchUsersRequest.prototype.setChatbotId = function (value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */


proto.UpdateAttributeCollectionRequest.repeatedFields_ = [2];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.UpdateAttributeCollectionRequest.prototype.toObject = function (opt_includeInstance) {
    return proto.UpdateAttributeCollectionRequest.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.UpdateAttributeCollectionRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.UpdateAttributeCollectionRequest.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      attributeCollectionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
      attributesList: jspb.Message.toObjectList(msg.getAttributesList(), entities_user_dossier_pb.Attribute.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UpdateAttributeCollectionRequest}
 */


proto.UpdateAttributeCollectionRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UpdateAttributeCollectionRequest();
  return proto.UpdateAttributeCollectionRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UpdateAttributeCollectionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UpdateAttributeCollectionRequest}
 */


proto.UpdateAttributeCollectionRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setAttributeCollectionId(value);
        break;

      case 2:
        var value = new entities_user_dossier_pb.Attribute();
        reader.readMessage(value, entities_user_dossier_pb.Attribute.deserializeBinaryFromReader);
        msg.addAttributes(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.UpdateAttributeCollectionRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.UpdateAttributeCollectionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UpdateAttributeCollectionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.UpdateAttributeCollectionRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getAttributeCollectionId();

  if (f.length > 0) {
    writer.writeString(1, f);
  }

  f = message.getAttributesList();

  if (f.length > 0) {
    writer.writeRepeatedMessage(2, f, entities_user_dossier_pb.Attribute.serializeBinaryToWriter);
  }
};
/**
 * optional string attribute_collection_id = 1;
 * @return {string}
 */


proto.UpdateAttributeCollectionRequest.prototype.getAttributeCollectionId = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 1, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.UpdateAttributeCollectionRequest} returns this
 */


proto.UpdateAttributeCollectionRequest.prototype.setAttributeCollectionId = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * repeated e.Attribute attributes = 2;
 * @return {!Array<!proto.e.Attribute>}
 */


proto.UpdateAttributeCollectionRequest.prototype.getAttributesList = function () {
  return (
    /** @type{!Array<!proto.e.Attribute>} */
    jspb.Message.getRepeatedWrapperField(this, entities_user_dossier_pb.Attribute, 2)
  );
};
/**
 * @param {!Array<!proto.e.Attribute>} value
 * @return {!proto.UpdateAttributeCollectionRequest} returns this
*/


proto.UpdateAttributeCollectionRequest.prototype.setAttributesList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};
/**
 * @param {!proto.e.Attribute=} opt_value
 * @param {number=} opt_index
 * @return {!proto.e.Attribute}
 */


proto.UpdateAttributeCollectionRequest.prototype.addAttributes = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.e.Attribute, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.UpdateAttributeCollectionRequest} returns this
 */


proto.UpdateAttributeCollectionRequest.prototype.clearAttributesList = function () {
  return this.setAttributesList([]);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.DeleteAttributeCollectionRequest.prototype.toObject = function (opt_includeInstance) {
    return proto.DeleteAttributeCollectionRequest.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.DeleteAttributeCollectionRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.DeleteAttributeCollectionRequest.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      attributeCollectionId: jspb.Message.getFieldWithDefault(msg, 1, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.DeleteAttributeCollectionRequest}
 */


proto.DeleteAttributeCollectionRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.DeleteAttributeCollectionRequest();
  return proto.DeleteAttributeCollectionRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.DeleteAttributeCollectionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.DeleteAttributeCollectionRequest}
 */


proto.DeleteAttributeCollectionRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setAttributeCollectionId(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.DeleteAttributeCollectionRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.DeleteAttributeCollectionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.DeleteAttributeCollectionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.DeleteAttributeCollectionRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getAttributeCollectionId();

  if (f.length > 0) {
    writer.writeString(1, f);
  }
};
/**
 * optional string attribute_collection_id = 1;
 * @return {string}
 */


proto.DeleteAttributeCollectionRequest.prototype.getAttributeCollectionId = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 1, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.DeleteAttributeCollectionRequest} returns this
 */


proto.DeleteAttributeCollectionRequest.prototype.setAttributeCollectionId = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */


proto.SearchUsersResponse.repeatedFields_ = [1];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.SearchUsersResponse.prototype.toObject = function (opt_includeInstance) {
    return proto.SearchUsersResponse.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.SearchUsersResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.SearchUsersResponse.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      endUserIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SearchUsersResponse}
 */


proto.SearchUsersResponse.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.SearchUsersResponse();
  return proto.SearchUsersResponse.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SearchUsersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SearchUsersResponse}
 */


proto.SearchUsersResponse.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var values =
        /** @type {!Array<number>} */
        reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()];

        for (var i = 0; i < values.length; i++) {
          msg.addEndUserIds(values[i]);
        }

        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.SearchUsersResponse.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.SearchUsersResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SearchUsersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.SearchUsersResponse.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getEndUserIdsList();

  if (f.length > 0) {
    writer.writePackedInt64(1, f);
  }
};
/**
 * repeated int64 end_user_ids = 1;
 * @return {!Array<number>}
 */


proto.SearchUsersResponse.prototype.getEndUserIdsList = function () {
  return (
    /** @type {!Array<number>} */
    jspb.Message.getRepeatedField(this, 1)
  );
};
/**
 * @param {!Array<number>} value
 * @return {!proto.SearchUsersResponse} returns this
 */


proto.SearchUsersResponse.prototype.setEndUserIdsList = function (value) {
  return jspb.Message.setField(this, 1, value || []);
};
/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.SearchUsersResponse} returns this
 */


proto.SearchUsersResponse.prototype.addEndUserIds = function (value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.SearchUsersResponse} returns this
 */


proto.SearchUsersResponse.prototype.clearEndUserIdsList = function () {
  return this.setEndUserIdsList([]);
};

goog.object.extend(exports, proto);