var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState } from 'react';
import styled from 'styled-components';
import { Input } from '@src/components/atoms';
import { colors } from '@src/colors';
var SearchableListContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  overflow: auto;\n  background-color: ", ";\n  margin: ", ";\n  .item-container {\n    max-height: 260px;\n    overflow: auto;\n    border: 1px solid ", ";\n    border-radius: 5px;\n    padding: 8px;\n  }\n  .title-wrapper {\n    display: flex;\n    margin-bottom: 10px;\n    margin-left: 8px;\n    color: ", ";\n    .adicon_tag {\n      font-size: 12px;\n      margin-top: 2px;\n    }\n  }\n  .column-title {\n    margin-top: 2px;\n    font-size: 10px;\n    font-weight: bold;\n  }\n  .adicon_search {\n    border: none;\n    margin-bottom: 8px;\n    background: ", ";\n    &:before {\n      margin: 0;\n      font-size: 20px;\n    }\n  }\n"], ["\n  overflow: auto;\n  background-color: ", ";\n  margin: ", ";\n  .item-container {\n    max-height: 260px;\n    overflow: auto;\n    border: 1px solid ", ";\n    border-radius: 5px;\n    padding: 8px;\n  }\n  .title-wrapper {\n    display: flex;\n    margin-bottom: 10px;\n    margin-left: 8px;\n    color: ", ";\n    .adicon_tag {\n      font-size: 12px;\n      margin-top: 2px;\n    }\n  }\n  .column-title {\n    margin-top: 2px;\n    font-size: 10px;\n    font-weight: bold;\n  }\n  .adicon_search {\n    border: none;\n    margin-bottom: 8px;\n    background: ", ";\n    &:before {\n      margin: 0;\n      font-size: 20px;\n    }\n  }\n"])), colors.background.white, function (props) { return props.margin || '0'; }, colors.border.default, colors.text_pale, colors.background.light_purple);
// SearchableList
// shows a search box with the title over it (if passed in)
// The state of the searched text is passed to the children
var SearchableList = function (_a) {
    var title = _a.title, placeholder = _a.placeholder, children = _a.children, isTagGroup = _a.isTagGroup, rest = __rest(_a, ["title", "placeholder", "children", "isTagGroup"]);
    var _b = __read(useState(''), 2), searchTerm = _b[0], setSearchTerm = _b[1];
    return (React.createElement(SearchableListContainer, __assign({}, rest),
        React.createElement("div", { className: "item-container" },
            title && (React.createElement("div", { className: "title-wrapper" },
                isTagGroup ? null : React.createElement("span", { className: "adicon_tag" }),
                React.createElement("span", { className: "column-title" }, title))),
            React.createElement(Input, { iconClassName: "adicon_search", placeholder: placeholder, value: searchTerm, onChange: function (e) { return setSearchTerm(e.target.value); } }),
            React.Children.map(children, function (child) {
                if (React.isValidElement(child)) {
                    return React.cloneElement(child, { searchTerm: searchTerm });
                }
                return undefined;
            }))));
};
export default SearchableList;
var templateObject_1;
