var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import Datetime from 'react-datetime';
import styled from 'styled-components';
import { colors } from '@src/colors';
import { centeredFlex } from '@src/lib/mixins';
import moment from 'moment';
import { doesScenarioHaveErrors } from '@src/lib/helpers';
import { Button } from '@src/components/atoms';
var FlexboxContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n"], ["\n  ", ";\n"])), centeredFlex);
var DeliveryMessage = styled.p(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  color: ", ";\n  font-weight: bold;\n  font-size: 12px;\n  margin: 10px 0 0 10px;\n"], ["\n  color: ", ";\n  font-weight: bold;\n  font-size: 12px;\n  margin: 10px 0 0 10px;\n"])), function (props) { return props.textColor; });
var MessagesContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  width: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n"], ["\n  width: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n"])));
var ScheduledContainer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  width: 100%;\n"], ["\n  width: 100%;\n"])));
var SubmitButtonContainer = styled(FlexboxContainer)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  margin-top: 50px;\n  button {\n    margin-right: auto;\n  }\n"], ["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  margin-top: 50px;\n  button {\n    margin-right: auto;\n  }\n"])));
var DateTimeContainer = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  font-size: 24px;\n  color: ", ";\n  border: 1px solid ", ";\n  border-radius: 3px;\n  padding: 8px;\n  margin-bottom: 16px;\n  height: 44px;\n\n  .form-control {\n    height: unset;\n    padding: 0;\n  }\n\n  .rdtPicker {\n    font-size: 14px;\n  }\n\n  &::before {\n    margin-right: 8px;\n  }\n\n  &::placeholder {\n    color: ", ";\n  }\n"], ["\n  display: flex;\n  align-items: center;\n  font-size: 24px;\n  color: ", ";\n  border: 1px solid ", ";\n  border-radius: 3px;\n  padding: 8px;\n  margin-bottom: 16px;\n  height: 44px;\n\n  .form-control {\n    height: unset;\n    padding: 0;\n  }\n\n  .rdtPicker {\n    font-size: 14px;\n  }\n\n  &::before {\n    margin-right: 8px;\n  }\n\n  &::placeholder {\n    color: ", ";\n  }\n"])), colors.icon, colors.placeholder, colors.placeholder);
export var ShotDeliveryBtn = function (_a) {
    var intl = _a.intl, dateValid = _a.dateValid, hasScheduledDelivery = _a.hasScheduledDelivery, // boolean if there is a scheduled delivery
    delivered = _a.delivered, scheduledAt = _a.scheduledAt, errorMsg = _a.errorMsg, onDatetimeFocus = _a.onDatetimeFocus, onDatetimeChange = _a.onDatetimeChange, onSubmit = _a.onSubmit;
    // scheduled delivery color for button
    var sendScheduledDeliveryColor = errorMsg
        ? "" + colors.background.main_color
        : "" + colors.background.confirm;
    // avoid bugs when no focus time input but click submit button
    var initScheduledDelivery = scheduledAt && !errorMsg ? moment(scheduledAt) : '';
    return (React.createElement(ScheduledContainer, { className: "shot_delivery_btn_scheduled_container" },
        React.createElement(DateTimeContainer, { className: "adicon_date" },
            React.createElement(Datetime, { dateFormat: "YYYY/MM/DD", timeFormat: "HH:mm", locale: intl.formatMessage({ id: "Qd11MH", defaultMessage: "en" }), inputProps: { placeholder: '0000/00/00 00:00' }, onFocus: onDatetimeFocus, onChange: onDatetimeChange, isValidDate: dateValid, value: initScheduledDelivery, timeConstraints: { minutes: { min: 0, max: 59, step: 3 } } })),
        !delivered && (React.createElement(SubmitButtonContainer, { className: "submit_button_container" },
            React.createElement("span", { style: { pointerEvents: 'all' } },
                React.createElement(Button, { disabled: !initScheduledDelivery, text: hasScheduledDelivery
                        ? intl.formatMessage({ id: "GBEOdL", defaultMessage: "Cancel Delivery" })
                        : intl.formatMessage({ id: "fsAt9o", defaultMessage: "Edit Delivery" }), fontWeight: "bold", width: 160, height: 38, minWidth: "unset", fontSize: "12px", backgroundColor: !errorMsg
                        ? "" + colors.background.main_menu
                        : sendScheduledDeliveryColor, borderColor: hasScheduledDelivery ? "" + colors.placeholder : '', color: !errorMsg
                        ? "" + colors.text_pale
                        : "" + colors.background.main_menu, onClick: onSubmit })))),
        React.createElement(MessagesContainer, null,
            errorMsg && (React.createElement(DeliveryMessage, { textColor: colors.error }, errorMsg)),
            hasScheduledDelivery ? (React.createElement(DeliveryMessage, { textColor: colors.label.green_blue }, moment(scheduledAt).format('YYYY/MM/DD HH:mm') + " \u306B" + (delivered ? '配信完了' : '配信予定'))) : null)));
};
export var StepDeliveryBtn = function (_a) {
    var scenarioErrors = _a.scenarioErrors, currentScenario = _a.currentScenario, step = _a.step, handleStepScheduleDelivery = _a.handleStepScheduleDelivery;
    var scenarioDelivery = currentScenario && currentScenario.scenario_delivery;
    var hasErrors = doesScenarioHaveErrors(scenarioErrors);
    var campaign_content = currentScenario && currentScenario.campaign_content;
    // boolean to see if user can change the campaign contents
    var canChangeCampaignContents = campaign_content && !hasErrors;
    // scheduled delivery color for button
    var changeCampaignContentColor = canChangeCampaignContents
        ? "" + colors.background.confirm
        : "" + colors.border.pale;
    return (React.createElement(SubmitButtonContainer, { className: step.daysAfter <= 0 || step.daysAfter === '' ? 'readonly' : '' },
        React.createElement(Button, { text: !scenarioDelivery ? '設定' : '解除', fontWeight: "bold", width: 112, height: 38, minWidth: "unset", fontSize: "12px", backgroundColor: !scenarioDelivery
                ? changeCampaignContentColor
                : "" + colors.background.main_menu, borderColor: scenarioDelivery ? "" + colors.placeholder : '', color: scenarioDelivery
                ? "" + colors.text_pale
                : "" + colors.background.main_menu, onClick: handleStepScheduleDelivery }),
        !campaign_content && hasErrors ? (React.createElement(DeliveryMessage, { textColor: colors.error }, "\u30A8\u30E9\u30FC\u304C\u3042\u308B\u305F\u3081\u914D\u4FE1\u3067\u304D\u307E\u305B\u3093")) : null,
        campaign_content && scenarioDelivery ? (React.createElement(DeliveryMessage, { textColor: colors.label.green_blue }, campaign_content.days_after + "\u65E5\u5F8C " + moment(campaign_content.delivery_time).format('HH:mm') + "\u306B\u914D\u4FE1")) : null));
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
