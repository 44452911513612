export var UserActionTypes;
(function (UserActionTypes) {
    UserActionTypes["SET_USERS"] = "SET_USERS";
    UserActionTypes["SET_USER"] = "SET_USER";
    UserActionTypes["SET_USER_SCENARIOS"] = "SET_USER_SCENARIOS";
    UserActionTypes["SET_USER_QUICK_REPLIES"] = "SET_USER_QUICK_REPLIES";
    UserActionTypes["SET_HAS_USERS"] = "SET_HAS_USERS";
    UserActionTypes["SET_CHECKED_ALL_USERS"] = "SET_CHECKED_ALL_USERS";
    UserActionTypes["SET_ADMIN_ACCESS"] = "SET_ADMIN_ACCESS";
    UserActionTypes["UPDATE_PROP_OF_USER"] = "UPDATE_PROP_OF_USER";
    UserActionTypes["ADD_TAG_TO_MULTIPLE_USERS"] = "ADD_TAG_TO_MULTIPLE_USERS";
    UserActionTypes["BLOCK_MULTIPLE_USERS"] = "BLOCK_MULTIPLE_USERS";
    UserActionTypes["REMOVE_PROP_OF_USER"] = "REMOVE_PROP_OF_USER";
    UserActionTypes["HANDLE_UPDATE_USER"] = "HANDLE_UPDATE_USER";
    UserActionTypes["ADD_AUTO_TAG"] = "ADD_AUTO_TAG";
    UserActionTypes["ADD_USER_TAG"] = "ADD_USER_TAG";
    UserActionTypes["REMOVE_AUTO_TAG"] = "REMOVE_AUTO_TAG";
    UserActionTypes["UPDATE_USER_TAG"] = "UPDATE_USER_TAG";
    UserActionTypes["UPDATE_AUTO_TAG"] = "UPDATE_AUTO_TAG";
    UserActionTypes["UPDATE_FULFILLED"] = "UPDATE_FULFILLED";
    UserActionTypes["TOGGLE_CHECKED_USER"] = "TOGGLE_CHECKED_USER";
    UserActionTypes["TOGGLE_CHECK_ALL_USERS"] = "TOGGLE_CHECK_ALL_USERS";
    UserActionTypes["UNDO_USER"] = "UNDO_USER";
})(UserActionTypes || (UserActionTypes = {}));
