import { ScenarioMapType } from '../types/scenarioMap';
export var handleChangeHoveredPostbackAction = function (postbackAction) { return ({
    type: ScenarioMapType.HANDLE_CHANGE_HOVERED_POSTBACK_ACTION,
    postbackAction: postbackAction,
}); };
export var handleChangeHoverNode = function (hoveredNode) { return ({
    type: ScenarioMapType.HANDLE_CHANGE_HOVER_NODE,
    hoveredNode: hoveredNode,
}); };
export var handleChangeAlertMessage = function (alertMessage) { return ({
    type: ScenarioMapType.HANDLE_CHANGE_ALERT_MESSAGE,
    alertMessage: alertMessage,
}); };
