var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Component } from 'react';
import { colors } from '@src/colors';
import { transitions } from '@src/lib/animations';
import styled from 'styled-components';
import { columnFlex, centeredFlex, hoverMenuBg } from '@src/lib/mixins';
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  min-height: 40px;\n  height: ", ";\n  transition: ", " all;\n  border: 1px solid ", ";\n  margin-top: 12px;\n  border-radius: 5px;\n  overflow: auto;\n"], ["\n  ", ";\n  min-height: 40px;\n  height: ",
    ";\n  transition: ", " all;\n  border: 1px solid ", ";\n  margin-top: 12px;\n  border-radius: 5px;\n  overflow: auto;\n"])), columnFlex, function (props) {
    return props.isOpen ? 40 + props.options.length * 40 + "px" : '40px';
}, transitions.normal, function (props) { return props.borderColor || colors.border.default; });
var DropDown = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", ";\n  flex-direction: row;\n  font-size: 0.9rem;\n  width: 100%;\n  height: 40px;\n  padding: 0 16px;\n  background-color: ", ";\n  cursor: pointer;\n\n  .title {\n    flex: 8;\n  }\n\n  .adicon_arrow_down,\n  .adicon_arrow_up {\n    margin-left: 8px;\n    font-size: 1.5rem;\n    color: ", ";\n  }\n"], ["\n  ", ";\n  flex-direction: row;\n  font-size: 0.9rem;\n  width: 100%;\n  height: 40px;\n  padding: 0 16px;\n  background-color: ", ";\n  cursor: pointer;\n\n  .title {\n    flex: 8;\n  }\n\n  .adicon_arrow_down,\n  .adicon_arrow_up {\n    margin-left: 8px;\n    font-size: 1.5rem;\n    color: ", ";\n  }\n"])), centeredFlex, function (props) { return props.mainColor || colors.main_color; }, function (props) { return props.iconColor || colors.background.content; });
var DropDownItem = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  ", ";\n  width: 100%;\n  height: 40px;\n  border-bottom: 1px solid\n    ", ";\n  background-color: ", ";\n  transition: ", " all;\n  cursor: pointer;\n\n  &:nth-child(2) {\n    border-top: 1px solid\n      ", ";\n  }\n\n  &:last-child {\n    border: none;\n  }\n  ", ";\n"], ["\n  ", ";\n  width: 100%;\n  height: 40px;\n  border-bottom: 1px solid\n    ", ";\n  background-color: ", ";\n  transition: ", " all;\n  cursor: pointer;\n\n  &:nth-child(2) {\n    border-top: 1px solid\n      ", ";\n  }\n\n  &:last-child {\n    border: none;\n  }\n  ", ";\n"])), centeredFlex, function (props) { return props.borderColor || colors.border.default; }, function (props) { return props.itemColor || colors.background.content; }, transitions.normal, function (props) { return props.borderColor || colors.border.default; }, hoverMenuBg);
var StyledDropDown = /** @class */ (function (_super) {
    __extends(StyledDropDown, _super);
    function StyledDropDown(props) {
        var _this = _super.call(this, props) || this;
        _this.handleSelect = function (item) {
            _this.setState({ currentSelection: item });
        };
        _this.handleOpen = function () {
            _this.setState(function (prevState) { return ({ isOpen: !prevState.isOpen }); });
        };
        _this.state = {
            isOpen: false,
            currentSelection: '',
        };
        return _this;
    }
    StyledDropDown.prototype.render = function () {
        var _this = this;
        var _a = this.props, title = _a.title, textKey = _a.textKey, options = _a.options, mainColor = _a.mainColor, itemColor = _a.itemColor, iconColor = _a.iconColor, hoverColor = _a.hoverColor, borderColor = _a.borderColor, onSelect = _a.onSelect;
        var _b = this.state, isOpen = _b.isOpen, currentSelection = _b.currentSelection;
        return (React.createElement(Container, { borderColor: borderColor, isOpen: isOpen, options: options },
            React.createElement(DropDown, { "data-testid": "StyledDropDown", className: "drop-down", mainColor: mainColor, iconColor: iconColor, borderColor: borderColor, onClick: function () {
                    if (isOpen) {
                        _this.handleSelect(title);
                        onSelect(title);
                    }
                    _this.handleOpen();
                } },
                React.createElement("span", { className: "title" }, isOpen ? title : currentSelection || title),
                isOpen ? (React.createElement("span", { className: "adicon_arrow_up", "data-testid": "StyledDropDown_Up" })) : (React.createElement("span", { className: "adicon_arrow_down", "data-testid": "StyledDropDown_Down" }))),
            isOpen
                ? options.map(function (option) {
                    var text = typeof option === 'string' ? option : option[textKey];
                    return (React.createElement(DropDownItem, { className: "drop-down-item", borderColor: borderColor, itemColor: itemColor, hoverColor: hoverColor, key: text, role: "option", onClick: function () {
                            _this.handleOpen();
                            _this.handleSelect(text);
                            onSelect(text, option);
                        } }, text));
                })
                : null));
    };
    return StyledDropDown;
}(Component));
export default StyledDropDown;
var templateObject_1, templateObject_2, templateObject_3;
