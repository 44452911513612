import "core-js/modules/es.string.small";
import _extends from "@babel/runtime/helpers/extends";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useDrag, useDrop } from 'react-dnd';
import styled from 'styled-components';
import { colors, shadows } from '@src/colors';
import { hoverShadowMid } from '@src/lib/mixins';
import { DeleteIcon } from '@src/components/atoms';
import { FileUploadUI } from '@src/components/molecules';
import { Article, TalkCustomizeFileUploadTemplate, TalkCustomizeButtonTemplate } from '@src/components/organisms/fanp';
import { itemTypes } from '@src/redux/fanp/constants/otherConstants';
import TagTemplate from './TagTemplate';
var GenericItemContainer = styled.div.withConfig({
  displayName: "GenericItemContainer",
  componentId: "sc-1xfn2t9-0"
})(["width:260px;height:fit-content;position:relative;flex-shrink:0;margin:16px 0;box-shadow:", ";border:1px solid ", ";border-radius:", ";", ";&:not(:nth-child(2)){margin-left:8px;}&:hover{> .adicon_delete{display:block;}}"], shadows.small.light, colors.placeholder, function (props) {
  return props.borderRadius ? props.borderRadius : 0;
}, hoverShadowMid);
var ArticleWrapper = styled.div.withConfig({
  displayName: "ArticleWrapper",
  componentId: "sc-1xfn2t9-1"
})(["padding:8px;border:1px solid ", ";border-left:0;border-right:0;"], colors.border.default);
var propTypes = {
  item: PropTypes.object.isRequired,
  borderRadius: PropTypes.string,
  itemIterator: PropTypes.number.isRequired,
  scrapingChecked: PropTypes.bool.isRequired,
  schema: PropTypes.object.isRequired,
  openDeleteConfirmModal: PropTypes.func.isRequired,
  handleArticleChange: PropTypes.func.isRequired,
  platform: PropTypes.string
};

var GenericItem = function GenericItem(props) {
  var item = props.item,
      borderRadius = props.borderRadius,
      itemIterator = props.itemIterator,
      moveGenericItems = props.moveGenericItems,
      schema = props.schema,
      handleArticleChange = props.handleArticleChange,
      platform = props.platform,
      restProps = _objectWithoutProperties(props, ["item", "borderRadius", "itemIterator", "moveGenericItems", "schema", "handleArticleChange", "platform"]);

  var ref = useRef(null);

  var _useDrop = useDrop({
    accept: itemTypes.GENERIC_ITEM,
    drop: function drop(dragItem) {
      if (!ref.current) return;
      var resourceItemRank = dragItem.resourceItemRank,
          resourceItemId = dragItem.resourceItemId;
      var targetItemId = item.id;
      var targetItemRank = item.rank;
      if (resourceItemId === targetItemId) return;
      var hoverBoundingRect = ref.current.getBoundingClientRect();
      var hoverMiddleX = (hoverBoundingRect.right + hoverBoundingRect.left) / 2;
      if (resourceItemRank < targetItemRank && hoverBoundingRect.right < hoverMiddleX) return;
      if (resourceItemRank > targetItemRank && hoverBoundingRect.left > hoverMiddleX) return;
      moveGenericItems(resourceItemId, resourceItemRank, targetItemId, targetItemRank);
    }
  }),
      _useDrop2 = _slicedToArray(_useDrop, 2),
      drop = _useDrop2[1];

  var _useDrag = useDrag({
    item: {
      type: itemTypes.GENERIC_ITEM,
      resourceItemId: item.id,
      resourceItemRank: item.rank
    }
  }),
      _useDrag2 = _slicedToArray(_useDrag, 2),
      drag = _useDrag2[1];

  drag(drop(ref));
  return React.createElement("div", _extends({}, restProps, {
    ref: ref
  }), React.createElement(GenericItemContainer, {
    borderRadius: borderRadius
  }, React.createElement(DeleteIcon, {
    onClick: function onClick() {
      return props.openDeleteConfirmModal(item.id);
    }
  }), React.createElement(TalkCustomizeFileUploadTemplate, _extends({
    url: item.article.image_url,
    messageType: "generic",
    itemId: item.id,
    itemIterator: itemIterator,
    articleId: item.article.id,
    keyName: "image_url",
    scrapingChecked: props.scrapingChecked,
    isImageLoading: item.isImageLoading
  }, props), React.createElement(FileUploadUI, {
    itemIterator: itemIterator
  })), React.createElement(ArticleWrapper, null, React.createElement(Article, {
    article: item.article,
    schema: schema,
    platform: platform,
    onChange: function onChange(e) {
      handleArticleChange(e, item);
    }
  }, React.createElement(Article.Title, {
    height: 47
  }), React.createElement(Article.Subtitle, null), React.createElement(Article.Url, null)), React.createElement(TagTemplate, _extends({
    tags: item.tags,
    messageType: "generic",
    itemId: item.id,
    itemIterator: itemIterator
  }, props))), React.createElement(TalkCustomizeButtonTemplate, _extends({
    buttons: item.generic_item_buttons,
    messageType: "generic",
    itemId: item.id,
    itemIterator: itemIterator
  }, props))));
};

GenericItem.propTypes = propTypes;
export default GenericItem;