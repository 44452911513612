var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { Icon } from '@zeals-co-ltd/washi-components';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import { FormAutomationStepAction } from 'zeals-protobuf/zeals/node/entities/form_automation_pb';
import { valueTypeToString } from '@src/lib/formAutomation';
import { FormAutomationStepValueType } from 'zeals-protobuf/zeals/web/entities/form_automation_pb';
import { useStepItemAutocompleteStyles } from '@src/components/molecules/FormAutomationStepItems/FormAutomationStepItem.styles';
export var FormAutomationStepValueTypeSelector = function (_a) {
    var step = _a.step, onChange = _a.onChange;
    var stepItemDropdownStyles = useStepItemAutocompleteStyles();
    var valueTypeItems = [];
    Object.values(FormAutomationStepValueType).forEach(function (type) {
        if (type !==
            FormAutomationStepValueType.FORM_AUTOMATION_STEP_VALUE_TYPE_UNSPECIFIED) {
            var label = valueTypeToString(type);
            valueTypeItems.push({
                label: label,
                value: type,
            });
        }
    });
    var _b = step || {}, inputAction = _b.inputAction, selectAction = _b.selectAction, radioAction = _b.radioAction, clickByValueAction = _b.clickByValueAction, checkboxAction = _b.checkboxAction;
    var getValueType = function () {
        switch (step.actionType) {
            case FormAutomationStepAction.FORM_AUTOMATION_STEP_ACTION_TEXT_INPUT:
                return inputAction.valueType;
            case FormAutomationStepAction.FORM_AUTOMATION_STEP_ACTION_SELECT_INPUT:
                return selectAction.valueType;
            case FormAutomationStepAction.FORM_AUTOMATION_STEP_ACTION_RADIO_INPUT:
                return radioAction.valueType;
            case FormAutomationStepAction.FORM_AUTOMATION_STEP_ACTION_CLICK_BY_VALUE:
                return clickByValueAction.valueType;
            case FormAutomationStepAction.FORM_AUTOMATION_STEP_ACTION_CHECKBOX_INPUT:
                return checkboxAction.valueType;
            default:
                return undefined;
        }
    };
    var newStep = function (selectKey, selectValue) {
        var _a, _b, _c, _d, _e;
        switch (step.actionType) {
            case FormAutomationStepAction.FORM_AUTOMATION_STEP_ACTION_TEXT_INPUT:
                return __assign(__assign({}, step), { inputAction: __assign(__assign({}, inputAction), (_a = {}, _a[selectKey] = selectValue, _a)) });
            case FormAutomationStepAction.FORM_AUTOMATION_STEP_ACTION_SELECT_INPUT:
                return __assign(__assign({}, step), { selectAction: __assign(__assign({}, selectAction), (_b = {}, _b[selectKey] = selectValue, _b)) });
            case FormAutomationStepAction.FORM_AUTOMATION_STEP_ACTION_RADIO_INPUT:
                return __assign(__assign({}, step), { radioAction: __assign(__assign({}, radioAction), (_c = {}, _c[selectKey] = selectValue, _c)) });
            case FormAutomationStepAction.FORM_AUTOMATION_STEP_ACTION_CLICK_BY_VALUE:
                return __assign(__assign({}, step), { clickByValueAction: __assign(__assign({}, clickByValueAction), (_d = {}, _d[selectKey] = selectValue, _d)) });
            case FormAutomationStepAction.FORM_AUTOMATION_STEP_ACTION_CHECKBOX_INPUT:
                return __assign(__assign({}, step), { checkboxAction: __assign(__assign({}, checkboxAction), (_e = {}, _e[selectKey] = selectValue, _e)) });
            default:
                return undefined;
        }
    };
    var selectedField = valueTypeItems === null || valueTypeItems === void 0 ? void 0 : valueTypeItems.find(function (field) { return field.value === getValueType(); });
    return (React.createElement(Select, { "data-testid": "FormAutomationStepItem-ValueTypeSelectionDropdown", className: "" + stepItemDropdownStyles.root, value: selectedField.value, onChange: function (e) {
            onChange(newStep('valueType', e.target.value));
        }, MenuProps: {
            disablePortal: true,
        }, 
        // eslint-disable-next-line react/no-unstable-nested-components
        IconComponent: function (props) { return React.createElement(Icon, __assign({ name: "adicon_down_chev" }, props)); } }, valueTypeItems.map(function (item) { return (React.createElement(MenuItem, { key: item.value, value: item.value },
        React.createElement(ListItemText, { primary: item.label }))); })));
};
