import "core-js/modules/es.array.concat";
import "core-js/modules/es.object.values";
import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
export default function (errorObj) {
  // takes an errorObj and returns an array with unique error messages
  var errorMessages = Object.values(errorObj);
  return errorMessages.reduce(function (filteredErrors, errMessage) {
    if (filteredErrors.indexOf(errMessage) === -1) return [].concat(_toConsumableArray(filteredErrors), [errMessage]);
    return filteredErrors;
  }, []);
}