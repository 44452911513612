var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import { injectIntl } from 'react-intl';
import styled from 'styled-components';
import { colors } from '@src/colors';
import { PageListImage } from '@src/components/atoms';
var PhoneAnswer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: relative;\n  clear: both;\n\n  .icon {\n    display: inline-block;\n    position: absolute;\n    bottom: 8px;\n    height: 20px;\n    width: 20px;\n    border: 1px solid black;\n    border-radius: 1rem;\n    overflow: hidden;\n  }\n\n  .answer {\n    display: inline-block;\n    background: ", ";\n    width: 200px;\n    margin: 10px 0 0 30px;\n    padding: 10px 15px;\n    clear: both;\n    border-radius: 1rem;\n\n    .answer_text_area {\n      word-wrap: break-word;\n      font-size: 12px;\n      max-height: 300px;\n      overflow: hidden;\n      text-overflow: ellipsis;\n    }\n  }\n"], ["\n  position: relative;\n  clear: both;\n\n  .icon {\n    display: inline-block;\n    position: absolute;\n    bottom: 8px;\n    height: 20px;\n    width: 20px;\n    border: 1px solid black;\n    border-radius: 1rem;\n    overflow: hidden;\n  }\n\n  .answer {\n    display: inline-block;\n    background: ", ";\n    width: 200px;\n    margin: 10px 0 0 30px;\n    padding: 10px 15px;\n    clear: both;\n    border-radius: 1rem;\n\n    .answer_text_area {\n      word-wrap: break-word;\n      font-size: 12px;\n      max-height: 300px;\n      overflow: hidden;\n      text-overflow: ellipsis;\n    }\n  }\n"])), colors.bg_select_hover);
PhoneAnswer.displayName = 'PhoneAnswer';
var PhoneContent = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  position: absolute;\n  height: 350px;\n  width: 270px;\n  background: ", ";\n  top: 80px;\n  left: 4px;\n\n  .time {\n    height: 30px;\n    text-align: center;\n    color: ", ";\n    font-size: 12px;\n  }\n"], ["\n  position: absolute;\n  height: 350px;\n  width: 270px;\n  background: ", ";\n  top: 80px;\n  left: 4px;\n\n  .time {\n    height: 30px;\n    text-align: center;\n    color: ", ";\n    font-size: 12px;\n  }\n"])), colors.white, colors.gray_light);
PhoneContent.displayName = 'PhoneContent';
var PhoneQuestion = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  width: 87px;\n  height: 25px;\n  padding: 0 10px;\n  margin-bottom: 20px;\n  background: ", ";\n  float: right;\n  border-radius: 1rem;\n  line-height: 23px;\n\n  .text {\n    color: ", ";\n    font-size: 12px;\n    margin-left: 3px;\n  }\n"], ["\n  width: 87px;\n  height: 25px;\n  padding: 0 10px;\n  margin-bottom: 20px;\n  background: ", ";\n  float: right;\n  border-radius: 1rem;\n  line-height: 23px;\n\n  .text {\n    color: ", ";\n    font-size: 12px;\n    margin-left: 3px;\n  }\n"])), colors.platforms.messenger, colors.white);
PhoneQuestion.displayName = 'PhoneQuestion';
var ContentWrapper = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  border: 1px solid rgba(58, 57, 86, 0.15);\n  border-radius: 1rem;\n  height: 490px;\n  width: 280px;\n  position: absolute;\n  top: 62px;\n  left: 20px;\n\n  .phone_header {\n    position: absolute;\n    border-bottom: 1px solid rgba(58, 57, 86, 0.15);\n    height: 65px;\n    width: 278px;\n    background: ", ";\n    border-radius: 1rem 1rem 0 0;\n  }\n"], ["\n  border: 1px solid rgba(58, 57, 86, 0.15);\n  border-radius: 1rem;\n  height: 490px;\n  width: 280px;\n  position: absolute;\n  top: 62px;\n  left: 20px;\n\n  .phone_header {\n    position: absolute;\n    border-bottom: 1px solid rgba(58, 57, 86, 0.15);\n    height: 65px;\n    width: 278px;\n    background: ", ";\n    border-radius: 1rem 1rem 0 0;\n  }\n"])), colors.bg_select_hover);
ContentWrapper.displayName = 'ContentWrapper';
var DefaultMessageContent = function (_a) {
    var intl = _a.intl, selectedOption = _a.selectedOption, imgSrc = _a.imgSrc, textAreaValue = _a.textAreaValue;
    return (React.createElement(ContentWrapper, { "data-testid": "DefaultMessageContent_Wrapper" },
        React.createElement("div", { className: "phone_header" }),
        React.createElement(PhoneContent, null,
            React.createElement("div", { className: "time" }, "15:17"),
            React.createElement(PhoneQuestion, null,
                React.createElement("div", { className: "text" }, intl.formatMessage({ id: "NhX4DJ", defaultMessage: "Hello" }))),
            React.createElement(PhoneAnswer, { className: "" + (selectedOption === '2' ? 'hidden' : '') },
                React.createElement("div", { className: "icon" },
                    React.createElement(PageListImage, { src: (imgSrc && decodeURIComponent(imgSrc)) ||
                            '/static/images/icon_mouse.png' })),
                React.createElement("div", { className: "answer" },
                    React.createElement("div", { className: "answer_text_area" }, selectedOption === '0'
                        ? textAreaValue
                        : intl.formatMessage({ id: "rYAC3z", defaultMessage: "The previous content is displayed." })))))));
};
export default injectIntl(DefaultMessageContent);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
