import "core-js/modules/es.array.concat";
var zealsAdmin = {
  collection: function collection() {
    return "/zeals_admin/api/custom_forms";
  },
  member: function member(id) {
    return "".concat(zealsAdmin.collection(), "/").concat(id);
  },
  updateGroupRanks: function updateGroupRanks(id) {
    return "".concat(zealsAdmin.member(id), "/update_group_ranks");
  },
  copyHeadless: function copyHeadless(id) {
    return "".concat(zealsAdmin.member(id), "/copy_headless");
  }
};
var webview = {
  collection: function collection(endUserId) {
    return "/webview/api/end_users/".concat(endUserId, "/custom_forms");
  },
  show: function show(endUserId, customFormId) {
    return "".concat(webview.collection(endUserId), "/").concat(customFormId);
  },
  update: function update(endUserId, customFormId) {
    return "/webview/api/end_users/".concat(endUserId, "/custom_forms/").concat(customFormId, "/update_custom_form_answers");
  }
};
export default {
  zealsAdmin: zealsAdmin,
  webview: webview
};