var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import onClickOutside from 'react-onclickoutside';
import styled from 'styled-components';
import platformLimitations from '@src/lib/platforms/limitations';
import { messageTypes, mediaTypes, menuIconClasses, } from '@src/lib/app-constants';
import { colors, shadows } from '@src/colors';
import { transitions } from '@src/lib/animations';
import { messagesTypeMessages, mediaTypeMessages, } from '@src/i18n/translations';
var MSGSELECT_LEFT = 44;
var BUTTON_WIDTH = 130;
var BUTTON_HEIGHT = 38;
var MessageMenuContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: absolute;\n  top: ", ";\n  left: ", ";\n  width: max-content;\n  background: ", ";\n  border-radius: 8px;\n  box-shadow: ", ";\n  z-index: 100;\n"], ["\n  position: absolute;\n  top: ", ";\n  left: ", ";\n  width: max-content;\n  background: ", ";\n  border-radius: 8px;\n  box-shadow: ", ";\n  z-index: 100;\n"])), function (props) { return props.ulTop + "px"; }, function (props) { return props.ulLeft + "px"; }, colors.main_color, shadows.medium.dark);
var InnerMessageMenuContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  width: ", "px;\n  height: ", "px;\n  padding: 0.625rem;\n  font-size: 0.75rem;\n  white-space: nowrap;\n  transition: ", ";\n\n  .sub-menu {\n    visibility: hidden;\n  }\n\n  &:first-child {\n    border-top-left-radius: 8px;\n    border-top-right-radius: 8px;\n  }\n\n  &:last-child {\n    border-bottom-left-radius: 8px;\n    border-bottom-right-radius: 8px;\n  }\n\n  &::before {\n    font-size: 1.125rem;\n    margin-right: 5px;\n  }\n\n  &:hover {\n    cursor: pointer;\n    background-color: ", ";\n\n    .sub-menu {\n      visibility: visible;\n    }\n  }\n\n  .adicon_arrow_right_after {\n    font-size: 1.125rem;\n\n    &::after {\n      vertical-align: unset;\n    }\n  }\n"], ["\n  display: flex;\n  align-items: center;\n  width: ", "px;\n  height: ", "px;\n  padding: 0.625rem;\n  font-size: 0.75rem;\n  white-space: nowrap;\n  transition: ", ";\n\n  .sub-menu {\n    visibility: hidden;\n  }\n\n  &:first-child {\n    border-top-left-radius: 8px;\n    border-top-right-radius: 8px;\n  }\n\n  &:last-child {\n    border-bottom-left-radius: 8px;\n    border-bottom-right-radius: 8px;\n  }\n\n  &::before {\n    font-size: 1.125rem;\n    margin-right: 5px;\n  }\n\n  &:hover {\n    cursor: pointer;\n    background-color: ", ";\n\n    .sub-menu {\n      visibility: visible;\n    }\n  }\n\n  .adicon_arrow_right_after {\n    font-size: 1.125rem;\n\n    &::after {\n      vertical-align: unset;\n    }\n  }\n"])), BUTTON_WIDTH, BUTTON_HEIGHT, transitions.normal, colors.default_text);
var quickAddMenuTypes = [
    { type: messageTypes.TEXT },
    { type: messageTypes.FLEX_MESSAGE },
    { type: messageTypes.GENERIC },
    { type: messageTypes.IMAGE_MAP },
    {
        type: messageTypes.MEDIA,
        items: [
            { type: mediaTypes.IMAGE },
            { type: mediaTypes.VIDEO },
            { type: mediaTypes.AUDIO },
        ],
    },
];
var MessageMenu = function (_a) {
    var intl = _a.intl, platform = _a.platform, messageTypes = _a.messageTypes, _b = _a.ulTop, ulTop = _b === void 0 ? 0 : _b, _c = _a.ulLeft, ulLeft = _c === void 0 ? MSGSELECT_LEFT : _c, onItemClick = _a.onItemClick;
    var limitations = platformLimitations[platform];
    if (!limitations)
        return null;
    var displayableTypes = __assign(__assign({}, limitations.CAN_SEND), limitations.MEDIA_CAN_SEND);
    var translations = __assign(__assign({}, mediaTypeMessages), messagesTypeMessages);
    return (React.createElement(MessageMenuContainer, { ulTop: ulTop, ulLeft: ulLeft, role: "menu" }, messageTypes
        .filter(function (_a) {
        var type = _a.type;
        return displayableTypes[type];
    })
        .map(function (_a, i) {
        var type = _a.type, items = _a.items;
        var text = intl.formatMessage(translations[type]);
        return (React.createElement(InnerMessageMenuContainer, { key: "menu_item-" + type, className: menuIconClasses[type], onClick: function () { return onItemClick(type, i); }, role: "button", tabIndex: 0 },
            text,
            items && React.createElement("span", { className: "adicon_arrow_right_after" }),
            items && (React.createElement("span", { className: "sub-menu" },
                React.createElement(MessageMenu, { intl: intl, platform: platform, messageTypes: items, ulLeft: BUTTON_WIDTH, ulTop: i * BUTTON_HEIGHT, onItemClick: onItemClick })))));
    })));
};
// onClickOutside must be used with a class component - more likely to replace
// this component than refactor it
var MessageSelector = /** @class */ (function (_super) {
    __extends(MessageSelector, _super);
    function MessageSelector() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.handleClickToItem = function (messageType) {
            var _a = _this.props, handleMessageInsert = _a.handleMessageInsert, handleMessageInsertFlex = _a.handleMessageInsertFlex, handleMessageInsertImageMap = _a.handleMessageInsertImageMap;
            switch (messageType) {
                case messageTypes.TEXT:
                    return handleMessageInsert('text');
                case messageTypes.GENERIC:
                    return handleMessageInsert('generic');
                case mediaTypes.IMAGE:
                case mediaTypes.VIDEO:
                case mediaTypes.AUDIO:
                    return handleMessageInsert('media', messageType);
                case messageTypes.FLEX_MESSAGE:
                    return handleMessageInsertFlex();
                case messageTypes.IMAGE_MAP:
                    return handleMessageInsertImageMap();
                default:
                    return null;
            }
        };
        return _this;
    }
    MessageSelector.prototype.handleClickOutside = function (e) {
        this.props.onClickOutside(e);
    };
    MessageSelector.prototype.render = function () {
        var _a = this.props, platform = _a.platform, intl = _a.intl;
        // NOTE: This MessageSelector must wrap MessageMenu due to recursion
        return (React.createElement(MessageMenu, { intl: intl, platform: platform, messageTypes: quickAddMenuTypes, onItemClick: this.handleClickToItem }));
    };
    return MessageSelector;
}(Component));
export default injectIntl(onClickOutside(MessageSelector));
var templateObject_1, templateObject_2;
