import "core-js/modules/es.array.concat";
var zealsAdmin = {
  collection: function collection(formId, groupId, questionId) {
    return "/zeals_admin/api/custom_forms/".concat(formId, "/custom_form_question_groups/").concat(groupId, "/custom_form_questions/").concat(questionId);
  },
  createRichMenu: function createRichMenu(formId, groupId, questionId) {
    return "".concat(zealsAdmin.collection(formId, groupId, questionId), "/create_rich_menu");
  },
  destroyRichMenu: function destroyRichMenu(formId, groupId, questionId) {
    return "".concat(zealsAdmin.collection(formId, groupId, questionId), "/destroy_rich_menu");
  },
  updateRichMenu: function updateRichMenu(formId, groupId, questionId) {
    return "".concat(zealsAdmin.collection(formId, groupId, questionId), "/update_rich_menu");
  }
};
export default {
  zealsAdmin: zealsAdmin
};