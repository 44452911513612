import "core-js/modules/es.symbol";
import "core-js/modules/es.array.concat";
import "core-js/modules/es.array.filter";
import "core-js/modules/es.array.find";
import "core-js/modules/es.array.includes";
import "core-js/modules/es.array.map";
import "core-js/modules/es.array.splice";
import "core-js/modules/es.function.name";
import "core-js/modules/es.number.constructor";
import "core-js/modules/es.object.get-own-property-descriptor";
import "core-js/modules/es.object.get-own-property-descriptors";
import "core-js/modules/es.object.keys";
import "core-js/modules/es.object.values";
import "core-js/modules/es.string.includes";
import "core-js/modules/es.string.search";
import "core-js/modules/web.dom-collections.for-each";
import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import omit from 'lodash/omit';
import pick from 'lodash/pick';
import { singleBlockNormalizer } from '@src/lib/dataNormalizer';
import { defaultAdScenariosQueryParams } from '@src/lib/adHelpers';
import { adScenario, talkCustomize, quickReply } from '@src/redux/fanp/constants';
import { messageToMessageSectionNormalized } from '@src/lib/typeConversions';
import { addTag, pushGenericListItem, addQuickReply, addTextButton, addGenericButton, addFreeInput, addFreeInputFromField } from '@src/redux/fanp/lib/creates';
import { updateScenarioDelivery, updateScenarioCampaignContent, updateFreeInput, updateButton, updateButtonItem, updateGenericButton as updateGenericButtonAjax } from '@src/redux/fanp/lib/handleAjaxResponse';
import { updateText, updateArticle, updateScheduledAt, updateIsValidTriggerTime, updateStepDeliveryTime, updateScenarioMainSetting, updateTalkTrigger, updateMessageItem, updateImageAspectRatio, updateListGenericItem, updateLineFlexMessageComponent, updateLineFlexMessage } from '@src/redux/fanp/lib/updates';
import { removeTag, removeGenericListItem, removeQuickReply, removeButtonFromText, removeButtonFromGeneric, removeQuickReplyChoice, removeTalkTrigger, removeIdsChange } from '@src/redux/fanp/lib/deletes';
import { scenarioLayerBlockStateWithNewScenarioProperties } from '@src/lib/helpers';
var AD_SET_QUICK_REPLY = quickReply.AD_SET_QUICK_REPLY,
    ADD_CREATED_QUICK_REPLY = quickReply.ADD_CREATED_QUICK_REPLY,
    AD_REMOVE_QUICK_REPLY = quickReply.AD_REMOVE_QUICK_REPLY,
    AD_HANDLE_QUICK_REPLY_CHOICE = quickReply.AD_HANDLE_QUICK_REPLY_CHOICE,
    AD_HANDLE_REMOVE_QUICK_REPLY_CHOICE = quickReply.AD_HANDLE_REMOVE_QUICK_REPLY_CHOICE,
    DESELECT_ALL_CHOICES = quickReply.DESELECT_ALL_CHOICES,
    AD_SET_QUICK_REPLY_CHOICE = quickReply.AD_SET_QUICK_REPLY_CHOICE,
    AD_SET_QUICK_REPLY_CHOICES = quickReply.AD_SET_QUICK_REPLY_CHOICES,
    AD_UPDATE_QUICK_REPLY_CHOICE = quickReply.AD_UPDATE_QUICK_REPLY_CHOICE;
var SUCCESS_FULFILLED = adScenario.SUCCESS_FULFILLED,
    ADD_AD_SCENARIO = adScenario.ADD_AD_SCENARIO,
    TOGGLE_SCENARIO_NAME_MODAL = adScenario.TOGGLE_SCENARIO_NAME_MODAL,
    COPY_SCENARIO = adScenario.COPY_SCENARIO,
    AD_DELETE_SCENARIO = adScenario.AD_DELETE_SCENARIO,
    AD_DELETE_SCENARIOS = adScenario.AD_DELETE_SCENARIOS,
    TOGGLE_JSON_MODAL = adScenario.TOGGLE_JSON_MODAL,
    TOGGLE_QUICK_REPLY_SELECT_MODE = adScenario.TOGGLE_QUICK_REPLY_SELECT_MODE,
    CREATE_NEW_FILTER_MESSAGE = adScenario.CREATE_NEW_FILTER_MESSAGE,
    UPDATE_MESSAGE_FILTER = adScenario.UPDATE_MESSAGE_FILTER,
    SET_USER_TAG_GROUP = adScenario.SET_USER_TAG_GROUP,
    AD_CREATE_NEW_SCENARIO_DELIVERY = adScenario.AD_CREATE_NEW_SCENARIO_DELIVERY,
    AD_ADD_FREE_INPUT = adScenario.AD_ADD_FREE_INPUT,
    AD_ADD_FREE_INPUT_FROM_FIELD = adScenario.AD_ADD_FREE_INPUT_FROM_FIELD,
    SET_USER_TAG_GROUPS = adScenario.SET_USER_TAG_GROUPS,
    SET_ATTRIBUTE_QUESTIONS = adScenario.SET_ATTRIBUTE_QUESTIONS,
    SET_TARGET_USER_TAG_GROUP = adScenario.SET_TARGET_USER_TAG_GROUP,
    SET_SCENARIO_TEMPLATES = adScenario.SET_SCENARIO_TEMPLATES,
    SET_LINE_FLEX_MESSAGE_COMPONENT = adScenario.SET_LINE_FLEX_MESSAGE_COMPONENT,
    TOGGLE_CHOICE_SELECTED = adScenario.TOGGLE_CHOICE_SELECTED,
    SELECT_ALL_CHOICES = adScenario.SELECT_ALL_CHOICES,
    ADD_NEW_ITEM = adScenario.ADD_NEW_ITEM,
    ADD_CREATED_TAG = adScenario.ADD_CREATED_TAG,
    AD_HANDLE_ADD_NEW_TEXT_BUTTON = adScenario.AD_HANDLE_ADD_NEW_TEXT_BUTTON,
    AD_HANDLE_ADD_NEW_GENERIC_BUTTON = adScenario.AD_HANDLE_ADD_NEW_GENERIC_BUTTON,
    AD_HANDLE_NEW_SCENARIO_DELIVERY = adScenario.AD_HANDLE_NEW_SCENARIO_DELIVERY,
    AD_HANDLE_STEP_CAMPAIGN_CONTENT = adScenario.AD_HANDLE_STEP_CAMPAIGN_CONTENT,
    AD_HANDLE_UPDATED_SCENARIO = adScenario.AD_HANDLE_UPDATED_SCENARIO,
    AD_HANDLE_AJAX_FREE_INPUT = adScenario.AD_HANDLE_AJAX_FREE_INPUT,
    AD_EDIT_TEXT_MESSAGE = adScenario.AD_EDIT_TEXT_MESSAGE,
    AD_EDIT_ARTICLE = adScenario.AD_EDIT_ARTICLE,
    ADD_NEW_MESSAGE_SECTION = adScenario.ADD_NEW_MESSAGE_SECTION,
    AD_UPDATE_TEXT_BUTTON = adScenario.AD_UPDATE_TEXT_BUTTON,
    AD_EDIT_SCHEDULED_AT = adScenario.AD_EDIT_SCHEDULED_AT,
    AD_EDIT_CAMPAIGN_CONTENT_SETTING = adScenario.AD_EDIT_CAMPAIGN_CONTENT_SETTING,
    DELETE_SCENARIO_DELIVERY = adScenario.DELETE_SCENARIO_DELIVERY,
    AD_EDIT_FREE_INPUT = adScenario.AD_EDIT_FREE_INPUT,
    CHANGE_IMAGE_ASPECT_RATIO = adScenario.CHANGE_IMAGE_ASPECT_RATIO,
    HANDLE_SWAP_GENERIC_ITEM_RANKS = adScenario.HANDLE_SWAP_GENERIC_ITEM_RANKS,
    EDIT_SCENARIO_PROPERTY = adScenario.EDIT_SCENARIO_PROPERTY,
    AD_REMOVE_MESSAGE = adScenario.AD_REMOVE_MESSAGE,
    AD_REMOVE_TAG = adScenario.AD_REMOVE_TAG,
    AD_REMOVE_MESSAGE_ITEM = adScenario.AD_REMOVE_MESSAGE_ITEM,
    AD_REMOVE_BUTTON_FROM_TEXT = adScenario.AD_REMOVE_BUTTON_FROM_TEXT,
    AD_REMOVE_BUTTON_FROM_GENERIC = adScenario.AD_REMOVE_BUTTON_FROM_GENERIC,
    AD_REMOVE_FREE_INPUT = adScenario.AD_REMOVE_FREE_INPUT,
    AD_REMOVE_IDS_CHANGE = adScenario.AD_REMOVE_IDS_CHANGE,
    AD_REMOVE_BUBBLE = adScenario.AD_REMOVE_BUBBLE,
    AD_SET_BLOCK_MESSAGES = adScenario.AD_SET_BLOCK_MESSAGES,
    AD_SET_MESSAGE = adScenario.AD_SET_MESSAGE,
    SET_AD_SCENARIOS = adScenario.SET_AD_SCENARIOS,
    SET_AD_SCENARIO = adScenario.SET_AD_SCENARIO,
    SET_MAIN_SCENARIO = adScenario.SET_MAIN_SCENARIO,
    SET_BLOCK_NAME = adScenario.SET_BLOCK_NAME,
    UPDATE_SELECTED_USER_TAG_GROUP = adScenario.UPDATE_SELECTED_USER_TAG_GROUP,
    UPDATE_QUICK_REPLY_USER_TAG_GROUP = adScenario.UPDATE_QUICK_REPLY_USER_TAG_GROUP,
    SET_SELECTED_USER_TAG_GROUP_ID = adScenario.SET_SELECTED_USER_TAG_GROUP_ID,
    AD_IS_VALID_TRIGGER_TIME = adScenario.AD_IS_VALID_TRIGGER_TIME,
    AD_SET_STEP_DAYS_AFTER = adScenario.AD_SET_STEP_DAYS_AFTER,
    AD_SET_STEP_DELIVERY_TIME = adScenario.AD_SET_STEP_DELIVERY_TIME,
    AD_SET_CONNECTED_BLOCKS = adScenario.AD_SET_CONNECTED_BLOCKS,
    CONNECT_BLOCK_TO_BUTTON = adScenario.CONNECT_BLOCK_TO_BUTTON,
    AD_TOGGLE_IMAGE_LOADING = adScenario.AD_TOGGLE_IMAGE_LOADING,
    AD_SET_QUICK_REPLY_DUPLICATED = adScenario.AD_SET_QUICK_REPLY_DUPLICATED,
    AD_SET_PREV_BLOCKS = adScenario.AD_SET_PREV_BLOCKS,
    AD_SET_GENERIC_LIST_BTN = adScenario.AD_SET_GENERIC_LIST_BTN,
    AD_SET_LAYER_FIELD = adScenario.AD_SET_LAYER_FIELD,
    AD_TOGGLE_LAYER_FIELD = adScenario.AD_TOGGLE_LAYER_FIELD,
    AD_SET_UNDEFINED_BLOCKS_LAYER_FIELD = adScenario.AD_SET_UNDEFINED_BLOCKS_LAYER_FIELD,
    AD_TOGGLE_UNDEFINED_BLOCKS_LAYER_FIELD = adScenario.AD_TOGGLE_UNDEFINED_BLOCKS_LAYER_FIELD,
    AD_OPEN_ALL_LAYERS = adScenario.AD_OPEN_ALL_LAYERS,
    AD_TOGGLE_ALL_LAYERS_FIELD = adScenario.AD_TOGGLE_ALL_LAYERS_FIELD,
    SET_GENERIC_ITEM = adScenario.SET_GENERIC_ITEM,
    AD_SET_SCENARIO_TESTING = adScenario.AD_SET_SCENARIO_TESTING,
    SET_AD_SCENARIOS_LIST = adScenario.SET_AD_SCENARIOS_LIST,
    SET_PAGINATED_SCENARIO_IDS = adScenario.SET_PAGINATED_SCENARIO_IDS,
    UPDATE_VALIDATION = adScenario.UPDATE_VALIDATION,
    UPDATE_VALIDATIONS = adScenario.UPDATE_VALIDATIONS,
    UPDATE_BLOCK_VALIDATIONS = adScenario.UPDATE_BLOCK_VALIDATIONS,
    LOADING_ITEM = adScenario.LOADING_ITEM,
    SET_AD_SCENARIOS_FETCHING = adScenario.SET_AD_SCENARIOS_FETCHING,
    AD_DUPLICATE_BUBBLE = adScenario.AD_DUPLICATE_BUBBLE,
    UPDATE_SHOW_UP_MESSAGE = adScenario.UPDATE_SHOW_UP_MESSAGE,
    UPDATE_HIDE_MESSAGE = adScenario.UPDATE_HIDE_MESSAGE;
var SET_MESSAGE = talkCustomize.SET_MESSAGE,
    PUSH_MESSAGE = talkCustomize.PUSH_MESSAGE,
    UPDATE_MESSAGE = talkCustomize.UPDATE_MESSAGE,
    DELETE_MESSAGE = talkCustomize.DELETE_MESSAGE;
export var defaultState = {
  loadingItem: false,
  scenarios: {},
  paginatedScenarioIds: [],
  layers: {},
  blocks: {},
  messageSections: {},
  messages: {},
  attributeQuestions: [],
  attributeQuestion: {
    attribute_answers: []
  },
  userTagGroups: [],
  userTagGroup: {
    user_tags: []
  },
  errors: {},
  blockValidations: [],
  step: {
    daysAfter: '',
    deliveryTime: {
      hour: '',
      minute: ''
    }
  },
  prevBlocks: [],
  scenarioTemplates: null,
  deleteScenarioIds: [],
  selectedQuickReplies: [],
  isQuickReplySelectMode: false,
  // Message section filters
  messageSectionFilters: [],
  selectedUserTagGroupId: null,
  selectedAnswerIds: [],
  search: defaultAdScenariosQueryParams.search,
  page: defaultAdScenariosQueryParams.page,
  per: defaultAdScenariosQueryParams.per
};

function expandLayers(state, action) {
  return Object.values(action.layers).reduce(function (allLayers, layer) {
    var _ref = state.layers[layer.id] || {},
        isExpanded = _ref.isExpanded;

    return _objectSpread({}, allLayers, _defineProperty({}, layer.id, _objectSpread({}, layer, {
      isExpanded: isExpanded
    })));
  }, {});
}

function reduceBlocks(state, action) {
  return Object.keys(state.blocks).reduce(function (allBlocks, blockId) {
    var currentBlock = state.blocks[blockId];
    return _objectSpread({}, allBlocks, _defineProperty({}, blockId, action.blocks[blockId] != null ? _objectSpread({}, currentBlock, {}, action.blocks[blockId]) : _objectSpread({}, currentBlock)));
  }, action.blocks);
}

export default (function () {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : defaultState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case SUCCESS_FULFILLED:
      return state;
    // Set

    case SET_GENERIC_ITEM:
      {
        var messageId = action.messageId,
            itemId = action.itemId;
        var message = state.messages[messageId];
        return _objectSpread({}, state, {
          messages: _objectSpread({}, state.messages, _defineProperty({}, messageId, _objectSpread({}, message, {
            generic_template: _objectSpread({}, message.generic_template, {
              generic_items: message.generic_template.generic_items.map(function (item) {
                return item.id === itemId ? action.item : item;
              })
            })
          })))
        });
      }

    case SET_BLOCK_NAME:
      {
        var _action$block = action.block,
            id = _action$block.id,
            name = _action$block.name;
        return _objectSpread({}, state, {
          blocks: _objectSpread({}, state.blocks, _defineProperty({}, id, _objectSpread({}, state.blocks[id], {
            name: name
          })))
        });
      }

    case SET_AD_SCENARIOS_FETCHING:
      {
        return _objectSpread({}, state, {
          isFetchingScenarios: action.isFetchingScenarios
        });
      }

    case SET_AD_SCENARIOS:
      {
        var scenarios = action.scenarios;
        var allScenarios = scenarios;

        if (action.preventScenarioDeliveryUpdate) {
          allScenarios = Object.values(scenarios).reduce(function (all, scenario) {
            return _objectSpread({}, all, _defineProperty({}, scenario.id, _objectSpread({}, scenario, {
              scenario_delivery: state.scenarios[scenario.id].scenario_delivery
            })));
          }, {});
        }

        return _objectSpread({}, state, {
          scenarios: _objectSpread({}, state.scenarios, {}, allScenarios),
          layers: expandLayers(state, action),
          // QuickReplyがBlockにに紐付くようになり、TalkCustmizeMainに描画されているBlockに関しては
          // 別途このように追加している
          blocks: reduceBlocks(state, action)
        });
      }

    case SET_AD_SCENARIO:
      return _objectSpread({}, state, {
        scenarios: _objectSpread({}, state.scenarios, {}, action.scenarios),
        layers: _objectSpread({}, state.layers, {}, action.layers),
        blocks: reduceBlocks(state, action)
      });

    case SET_MAIN_SCENARIO:
      return state;

    case UPDATE_MESSAGE_FILTER:
      {
        var _message = action.message;
        var _id = _message.id,
            filter = _message.filter; // messageId

        var targetMessage = state.messages[_id];
        return _objectSpread({}, state, {
          messages: _objectSpread({}, state.messages, _defineProperty({}, _id, _objectSpread({}, targetMessage, {
            filter: filter
          })))
        });
      }

    case UPDATE_SELECTED_USER_TAG_GROUP:
      {
        var userTag = action.userTag;

        var newUserTagGroup = _objectSpread({}, state.userTagGroup);

        newUserTagGroup.user_tags.unshift(userTag);
        return _objectSpread({}, state, {
          userTagGroup: newUserTagGroup
        });
      }

    case UPDATE_QUICK_REPLY_USER_TAG_GROUP:
      {
        var blockId = action.blockId,
            _userTag = action.userTag;
        var targetBlock = state.blocks[blockId];

        var tempTargetBlock = _objectSpread({}, targetBlock);

        tempTargetBlock.quick_reply.user_tag_group.user_tags.push(_userTag);
        return _objectSpread({}, state, {
          blocks: _objectSpread({}, state.blocks, _defineProperty({}, blockId, tempTargetBlock))
        });
      }

    case SET_SELECTED_USER_TAG_GROUP_ID:
      {
        return _objectSpread({}, state, {
          selectedUserTagGroupId: action.id
        });
      }

    case AD_IS_VALID_TRIGGER_TIME:
      return updateIsValidTriggerTime(state, action);

    case AD_SET_STEP_DAYS_AFTER:
      return _objectSpread({}, state, {
        step: _objectSpread({}, state.step, {
          daysAfter: action.daysAfter
        })
      });

    case AD_SET_STEP_DELIVERY_TIME:
      return updateStepDeliveryTime(state, action);

    case AD_SET_CONNECTED_BLOCKS:
      return updateStepDeliveryTime(state, action);

    case CONNECT_BLOCK_TO_BUTTON:
      return action.listType === 'generic' ? updateGenericButtonAjax(state, action) : updateButton(state, action);

    case AD_TOGGLE_IMAGE_LOADING:
      return updateMessageItem(state, action);

    case AD_SET_QUICK_REPLY_DUPLICATED:
      return _objectSpread({}, state, {
        scenarios: _objectSpread({}, state.scenarios, _defineProperty({}, action.adScenarioId, _objectSpread({}, state.scenarios[action.adScenarioId], {
          quick_reply_list: _objectSpread({}, state.scenarios[action.adScenarioId].quick_reply_list, {
            quickReplyIdDuplicated: action.quickReplyId
          })
        })))
      });

    case AD_SET_QUICK_REPLY_CHOICES:
      {
        var _blockId = action.blockId,
            quickReplyChoices = action.quickReplyChoices;
        var _targetBlock = state.blocks[_blockId];
        return _objectSpread({}, state, {
          blocks: _objectSpread({}, state.blocks, _defineProperty({}, _blockId, _objectSpread({}, _targetBlock, {
            quick_reply: _objectSpread({}, _targetBlock.quick_reply, {
              quick_reply_choices: quickReplyChoices
            })
          })))
        });
      }

    case AD_SET_PREV_BLOCKS:
      return _objectSpread({}, state, {
        prevBlocks: action.prevBlocks
      });

    case AD_SET_SCENARIO_TESTING:
      return _objectSpread({}, state, {
        scenarios: _objectSpread({}, state.scenarios, _defineProperty({}, action.scenarioId, _objectSpread({}, state.scenarios[action.scenarioId], {
          testing: action.testing
        })))
      });

    case AD_SET_GENERIC_LIST_BTN:
      {
        var _messageId = action.messageId,
            _itemId = action.itemId,
            buttonItemId = action.buttonItemId,
            updatedButton = action.updatedButton,
            scenarioId = action.scenarioId;
        var _message2 = state.messages[_messageId];
        var layers = updatedButton.layers;
        var newScenarioProperties = {
          layers: layers,
          undefined_blocks: updatedButton.undefined_blocks
        };
        var newScenarioLayerBlockState = scenarioLayerBlockStateWithNewScenarioProperties(state, newScenarioProperties, scenarioId);
        return _objectSpread({}, state, {
          messages: _objectSpread({}, state.messages, _defineProperty({}, _messageId, _objectSpread({}, _message2, {
            generic_template: _objectSpread({}, _message2.generic_template, {
              generic_items: _message2.generic_template.generic_items.map(function (item) {
                if (item.id === _itemId) {
                  return _objectSpread({}, item, {
                    generic_item_buttons: item.generic_item_buttons.map(function (buttonItem) {
                      if (buttonItem.id === buttonItemId) {
                        return _objectSpread({}, buttonItem, {}, updatedButton);
                      }

                      return buttonItem;
                    })
                  });
                }

                return item;
              })
            })
          })))
        }, newScenarioLayerBlockState);
      }

    case ADD_AD_SCENARIO:
      {
        var newScenarioIds = Object.values(action.scenarios).map(function (s) {
          return s.id;
        });
        return _objectSpread({}, state, {
          scenarios: _objectSpread({}, state.scenarios, {}, action.scenarios),
          layers: _objectSpread({}, state.layers, {}, action.layers),
          blocks: _objectSpread({}, state.blocks, {}, action.blocks),
          paginatedScenarioIds: [].concat(_toConsumableArray(newScenarioIds), _toConsumableArray(state.paginatedScenarioIds)),
          search: ''
        });
      }

    case TOGGLE_SCENARIO_NAME_MODAL:
      return state;

    case COPY_SCENARIO:
      return state;

    case AD_DELETE_SCENARIO:
      {
        var adScenarioId = action.adScenarioId;

        var _scenarios = Object.keys(state.scenarios).filter(function (id) {
          return Number(id) !== adScenarioId;
        }).reduce(function (allScenarios, id) {
          return _objectSpread({}, allScenarios, _defineProperty({}, id, state.scenarios[id]));
        }, {});

        return _objectSpread({}, state, {
          scenarios: _scenarios,
          paginatedScenarioIds: state.paginatedScenarioIds.filter(function (scenarioId) {
            return scenarioId !== adScenarioId;
          })
        });
      }

    case AD_DELETE_SCENARIOS:
      {
        return _objectSpread({}, state, {
          scenarios: Object.keys(state.scenarios).reduce(function (allScenarios, key) {
            var updated = _objectSpread({}, allScenarios);

            if (action.adScenarioIds.indexOf(Number(key)) === -1) {
              updated[key] = state.scenarios[key];
            }

            return updated;
          }, {}),
          paginatedScenarioIds: state.paginatedScenarioIds.filter(function (scenarioId) {
            return action.adScenarioIds.indexOf(scenarioId) === -1;
          }),
          deleteScenarioIds: []
        });
      }

    case TOGGLE_JSON_MODAL:
      return state;

    case TOGGLE_CHOICE_SELECTED:
      {
        var _blockId2 = action.blockId,
            choiceId = action.choiceId;
        var blocks = state.blocks;
        if (!(blocks[_blockId2] && blocks[_blockId2].quick_reply)) return state;
        var _quickReplyChoices = blocks[_blockId2].quick_reply.quick_reply_choices;
        var _selectedQuickReplies = state.selectedQuickReplies;

        if (_selectedQuickReplies.find(function (qrc) {
          return qrc.id === choiceId;
        })) {
          _selectedQuickReplies = _selectedQuickReplies.filter(function (qrc) {
            return qrc.id !== choiceId;
          });
        } else {
          var quickReplyChoice = _quickReplyChoices.find(function (choice) {
            return choice.id === choiceId;
          });

          _selectedQuickReplies.push(quickReplyChoice);
        }

        return _objectSpread({}, state, {
          selectedQuickReplies: _selectedQuickReplies
        });
      }

    case SELECT_ALL_CHOICES:
      {
        var _blockId3 = action.blockId;
        var _blocks = state.blocks;
        if (!(_blocks[_blockId3] && _blocks[_blockId3].quick_reply)) return state;
        var _selectedQuickReplies2 = _blocks[_blockId3].quick_reply.quick_reply_choices;
        return _objectSpread({}, state, {
          selectedQuickReplies: _selectedQuickReplies2
        });
      }
    // Add

    case AD_HANDLE_ADD_NEW_TEXT_BUTTON:
      return _objectSpread({}, state, {}, addTextButton(state, action));

    case AD_HANDLE_ADD_NEW_GENERIC_BUTTON:
      return _objectSpread({}, state, {}, addGenericButton(state, action));

    case ADD_CREATED_QUICK_REPLY:
      return _objectSpread({}, state, {}, addQuickReply(state, action));

    case AD_CREATE_NEW_SCENARIO_DELIVERY:
      return updateScheduledAt(state, action);

    case AD_ADD_FREE_INPUT:
      return addFreeInput(state, action);

    case AD_ADD_FREE_INPUT_FROM_FIELD:
      return addFreeInputFromField(state, action);
    // Handle

    case ADD_NEW_ITEM:
      return _objectSpread({}, state, {}, pushGenericListItem(state, action));

    case ADD_CREATED_TAG:
      return _objectSpread({}, state, {}, addTag(state, action));

    case AD_HANDLE_NEW_SCENARIO_DELIVERY:
      {
        return _objectSpread({}, state, {}, updateScenarioDelivery(state, action));
      }

    case AD_HANDLE_STEP_CAMPAIGN_CONTENT:
      return updateScenarioCampaignContent(state, action);

    case AD_HANDLE_AJAX_FREE_INPUT:
      return updateFreeInput(state, action);
    // Edit

    case AD_EDIT_TEXT_MESSAGE:
      return _objectSpread({}, state, {}, updateText(state, action));

    case AD_EDIT_ARTICLE:
      return _objectSpread({}, state, {}, updateArticle(state, action));

    case ADD_NEW_MESSAGE_SECTION:
      {
        var _message3 = action.message;
        var _id2 = _message3.id;
        var _targetBlock2 = state.blocks[_message3.block_id];

        function updateBlock(block, msgSectionId) {
          var newMessageSectionIds = [].concat(_toConsumableArray(block.message_sections), [msgSectionId]);
          return _objectSpread({}, block, {
            message_sections: block.message_sections.includes(_message3.message_section_id) ? block.message_sections : newMessageSectionIds
          });
        }

        var newMessageSection = messageToMessageSectionNormalized(_message3);
        var newBlock = updateBlock(_targetBlock2, newMessageSection.id);
        return _objectSpread({}, state, {
          blocks: _objectSpread({}, state.blocks, _defineProperty({}, _message3.block_id, newBlock)),
          messageSections: _objectSpread({}, state.messageSections, _defineProperty({}, _message3.message_section_id, newMessageSection)),
          messages: _objectSpread({}, state.messages, _defineProperty({}, _id2, _message3))
        });
      }

    case AD_SET_QUICK_REPLY_CHOICE:
      {
        var _blockId4 = action.blockId;
        var _targetBlock3 = state.blocks[_blockId4];
        return _objectSpread({}, state, {
          blocks: _objectSpread({}, state.blocks, _defineProperty({}, _blockId4, _objectSpread({}, _targetBlock3, {
            quick_reply: _objectSpread({}, _targetBlock3.quick_reply, {
              quick_reply_choices: _targetBlock3.quick_reply.quick_reply_choices.map(function (quickReplyChoice) {
                if (quickReplyChoice.id === action.quickReplyChoiceId) {
                  return action.quickReplyChoice;
                }

                return quickReplyChoice;
              })
            })
          }))),
          selectedQuickReplies: []
        });
      }

    case AD_HANDLE_QUICK_REPLY_CHOICE:
      {
        var _blockId5 = action.blockId;
        var _targetBlock4 = state.blocks[_blockId5];
        return _objectSpread({}, state, {
          blocks: _objectSpread({}, state.blocks, _defineProperty({}, _blockId5, _objectSpread({}, _targetBlock4, {
            quick_reply: _objectSpread({}, _targetBlock4.quick_reply, {
              quick_reply_choices: action.quickReplyChoices
            })
          })))
        });
      }

    case AD_UPDATE_QUICK_REPLY_CHOICE:
      {
        var _blockId6 = action.blockId,
            _quickReplyChoice = action.quickReplyChoice;
        var _targetBlock5 = state.blocks[_blockId6];
        return _objectSpread({}, state, {
          blocks: _objectSpread({}, state.blocks, _defineProperty({}, _blockId6, _objectSpread({}, _targetBlock5, {
            quick_reply: _objectSpread({}, _targetBlock5.quick_reply, {
              quick_reply_choices: _targetBlock5.quick_reply.quick_reply_choices.map(function (targetBlockQuickReplyChoice) {
                if (targetBlockQuickReplyChoice.id === _quickReplyChoice.id) {
                  return _quickReplyChoice;
                }

                return targetBlockQuickReplyChoice;
              })
            })
          })))
        });
      }

    case AD_UPDATE_TEXT_BUTTON:
      return _objectSpread({}, state, {}, updateButtonItem(state, action));

    case AD_EDIT_SCHEDULED_AT:
      return updateScheduledAt(state, action);

    case AD_HANDLE_UPDATED_SCENARIO:
      return updateScenarioMainSetting(state, action);

    case AD_EDIT_CAMPAIGN_CONTENT_SETTING:
      return _objectSpread({}, state, {
        scenarios: _objectSpread({}, state.scenarios, _defineProperty({}, action.adScenarioId, _objectSpread({}, state.scenarios[action.adScenarioId], {
          draft: action.value
        })))
      });

    case DELETE_SCENARIO_DELIVERY:
      return _objectSpread({}, state, {
        scenarios: _objectSpread({}, state.scenarios, _defineProperty({}, action.scenarioId, _objectSpread({}, state.scenarios[action.scenarioId], {
          scenario_delivery: null
        })))
      });

    case AD_EDIT_FREE_INPUT:
      return updateTalkTrigger(state, action);

    case EDIT_SCENARIO_PROPERTY:
      return _objectSpread({}, state, {
        scenarios: _objectSpread({}, state.scenarios, _defineProperty({}, action.scenarioId, _objectSpread({}, state.scenarios[action.scenarioId], {}, action.diff)))
      });

    case CHANGE_IMAGE_ASPECT_RATIO:
      return updateImageAspectRatio(state, action);
    // Delete

    case AD_REMOVE_MESSAGE:
      {
        var _blocks2 = state.blocks,
            messages = state.messages,
            messageSections = state.messageSections;
        var _messageId2 = action.messageId,
            messageSectionId = action.messageSectionId,
            _blockId7 = action.blockId;
        var messageSection = messageSections[messageSectionId];
        var msgsLength = Object.keys(messageSection.messages).length;
        var newMessages = omit(messages, _messageId2); // If last message, then delete the whole section

        if (msgsLength === 1) {
          return _objectSpread({}, state, {
            blocks: _objectSpread({}, _blocks2, _defineProperty({}, _blockId7, _objectSpread({}, _blocks2[_blockId7], {
              message_sections: _blocks2[_blockId7].message_sections.filter(function (sectionId) {
                return sectionId !== messageSectionId;
              })
            }))),
            messageSections: omit(messageSections, messageSectionId),
            messages: newMessages
          });
        } // else only delete that message from the section


        return _objectSpread({}, state, {
          messageSections: _objectSpread({}, messageSections, _defineProperty({}, messageSectionId, _objectSpread({}, messageSection, {
            messages: messageSection.messages.filter(function (id) {
              return id !== _messageId2;
            })
          }))),
          messages: newMessages
        });
      }

    case AD_REMOVE_TAG:
      return _objectSpread({}, state, {}, removeTag(state, action));

    case AD_SET_BLOCK_MESSAGES:
      {
        var _singleBlockNormalize = singleBlockNormalizer(action.block),
            _blocks3 = _singleBlockNormalize.blocks;

        var _singleBlockNormalize2 = singleBlockNormalizer(action.block),
            _messageSections = _singleBlockNormalize2.message_sections;

        var _singleBlockNormalize3 = singleBlockNormalizer(action.block),
            _messages = _singleBlockNormalize3.messages;

        if (!_messages) {
          _messages = {};
        }

        Object.keys(_messageSections).forEach(function (messageSectionKey) {
          if (_messageSections[messageSectionKey].default_message) {
            _messages[_messageSections[messageSectionKey].default_message.id] = _messageSections[messageSectionKey].default_message;
          }
        });
        Object.keys(_messageSections).forEach(function (messageSectionKey) {
          if (_messageSections[messageSectionKey].default_message) {
            _messageSections[messageSectionKey].messages.unshift(_messageSections[messageSectionKey].default_message.id);
          }
        });
        return _objectSpread({}, state, {
          messageSections: _objectSpread({}, state.messageSections, {}, _messageSections),
          messages: _objectSpread({}, state.messages, {}, _messages),
          blocks: _objectSpread({}, state.blocks, {}, _blocks3)
        });
      }

    case AD_SET_MESSAGE:
      {
        var _message4 = action.message;
        return _objectSpread({}, state, {
          messages: _objectSpread({}, state.messages, _defineProperty({}, _message4.id, _message4))
        });
      }

    case UPDATE_VALIDATION:
      return _objectSpread({}, state, {
        errors: _objectSpread({}, state.errors, _defineProperty({}, action.key, action.validationObjects))
      });

    case UPDATE_VALIDATIONS:
      {
        var filteredValidations = Object.keys(state.errors).filter(function (prop) {
          return prop !== action.key;
        }).reduce(function (resObj, prop) {
          return _objectSpread({}, resObj, _defineProperty({}, prop, state.errors[prop]));
        }, {});
        return _objectSpread({}, state, {
          errors: filteredValidations
        });
      }

    case UPDATE_BLOCK_VALIDATIONS:
      return _objectSpread({}, state, {
        blockValidations: action.blockValidations
      });

    case AD_REMOVE_MESSAGE_ITEM:
      return _objectSpread({}, state, {}, removeGenericListItem(state, action));

    case AD_REMOVE_QUICK_REPLY:
      return _objectSpread({}, state, {}, removeQuickReply(state, action));

    case AD_HANDLE_REMOVE_QUICK_REPLY_CHOICE:
      return _objectSpread({}, state, {}, removeQuickReplyChoice(state, action));

    case AD_REMOVE_BUTTON_FROM_TEXT:
      return _objectSpread({}, state, {}, removeButtonFromText(state, action));

    case AD_REMOVE_BUTTON_FROM_GENERIC:
      return _objectSpread({}, state, {}, removeButtonFromGeneric(state, action));

    case AD_REMOVE_FREE_INPUT:
      return _objectSpread({}, state, {}, removeTalkTrigger(state, action));

    case AD_REMOVE_IDS_CHANGE:
      return _objectSpread({}, state, {}, removeIdsChange(state, action));

    case LOADING_ITEM:
      return _objectSpread({}, state, {
        loadingItem: action.isLoading
      });

    case SET_USER_TAG_GROUP:
      {
        var userTagGroups = state.userTagGroups;
        var userTagGroup = action.userTagGroup;
        var _newUserTagGroup = {
          id: userTagGroup.id,
          label: userTagGroup.label
        };
        userTagGroups.splice(0, 0, _newUserTagGroup);
        return _objectSpread({}, state, {
          // TODO: Need to check again when backend work done
          userTagGroups: userTagGroups,
          userTagGroup: userTagGroup
        });
      }

    case SET_ATTRIBUTE_QUESTIONS:
      return _objectSpread({}, state, {
        attributeQuestions: action.attributeQuestions
      });

    case SET_USER_TAG_GROUPS:
      return _objectSpread({}, state, {
        userTagGroups: action.userTagGroups
      });

    case SET_TARGET_USER_TAG_GROUP:
      return _objectSpread({}, state, {
        userTagGroup: action.userTagGroup
      });

    case AD_SET_QUICK_REPLY:
      {
        var _blockId8 = action.blockId;
        var actionQuickReply = action.quickReply;
        var _targetBlock6 = state.blocks[_blockId8];
        return _objectSpread({}, state, {
          blocks: _objectSpread({}, state.blocks, _defineProperty({}, _blockId8, _objectSpread({}, _targetBlock6, {
            quick_reply: _objectSpread({}, _targetBlock6.quick_reply, {}, actionQuickReply)
          })))
        });
      }

    case SET_MESSAGE:
      return _objectSpread({}, state, {
        messages: _objectSpread({}, state.messages, _defineProperty({}, action.messageId, action.message))
      });

    case UPDATE_SHOW_UP_MESSAGE:
      {
        var _message5 = action.message;
        return _objectSpread({}, state, {
          messages: _objectSpread({}, state.messages, _defineProperty({}, _message5.id, _message5))
        });
      }

    case UPDATE_HIDE_MESSAGE:
      {
        var _message6 = action.message;
        var _messages2 = state.messages;
        var _targetMessage = _messages2[_message6.id];
        var hiddenMessage = pick(_targetMessage, ['id', 'type', 'filter']);
        return _objectSpread({}, state, {
          messages: _objectSpread({}, state.messages, _defineProperty({}, _message6.id, hiddenMessage))
        });
      }

    case SET_SCENARIO_TEMPLATES:
      return _objectSpread({}, state, {
        scenarioTemplates: action.scenarioTemplates
      });

    case PUSH_MESSAGE:
      {
        var _action$message = action.message,
            _id3 = _action$message.id,
            _messageSectionId = _action$message.message_section_id,
            _blockId9 = _action$message.block_id;
        var _targetBlock7 = state.blocks[_blockId9];
        var MessageSections = _targetBlock7.message_sections;
        var tempMessageSections = state.messageSections;
        var lastMessageSection = tempMessageSections[Object.keys(tempMessageSections)[Object.keys(tempMessageSections).length - 1]];
        tempMessageSections = _objectSpread({}, tempMessageSections, {}, tempMessageSections, _defineProperty({}, _messageSectionId, {
          id: _messageSectionId,
          rank: Number(lastMessageSection.rank) + 1,
          default_message_id: _id3,
          messages: [_id3]
        }));
        return _objectSpread({}, state, {
          blocks: _objectSpread({}, state.blocks, _defineProperty({}, _blockId9, _objectSpread({}, _targetBlock7, {
            message_sections: [].concat(_toConsumableArray(MessageSections), [_messageSectionId])
          }))),
          messageSections: tempMessageSections,
          messages: _objectSpread({}, state.messages, _defineProperty({}, _id3, action.message))
        });
      }

    case UPDATE_MESSAGE:
      {
        var _message7 = state.messages[action.messageId];
        return _objectSpread({}, state, {
          messages: _objectSpread({}, state.messages, _defineProperty({}, action.messageId, _objectSpread({}, _message7, _defineProperty({}, action.property, action.value))))
        });
      }

    case DELETE_MESSAGE:
      {
        var _blocks4 = state.blocks,
            _messages3 = state.messages,
            _messageSections2 = state.messageSections;
        var _messageId3 = action.messageId,
            _messageSectionId2 = action.messageSectionId,
            _blockId10 = action.blockId;
        var _messageSection = _messageSections2[_messageSectionId2];
        var _msgsLength = Object.keys(_messageSection.messages).length;

        var _newMessages = omit(_messages3, _messageId3); // If last message, then delete the whole section


        if (_msgsLength === 0) {
          return _objectSpread({}, state, {
            blocks: _objectSpread({}, _blocks4, _defineProperty({}, _blockId10, _objectSpread({}, _blocks4[_blockId10], {
              message_sections: _blocks4[_blockId10].message_sections.filter(function (sectionId) {
                return sectionId !== _messageSectionId2;
              })
            }))),
            messageSections: omit(_messageSections2, _messageSectionId2),
            messages: _newMessages
          });
        } // else only delete that message from the section


        return _objectSpread({}, state, {
          messageSections: _objectSpread({}, _messageSections2, _defineProperty({}, _messageSectionId2, _objectSpread({}, _messageSection, {
            messages: _messageSection.messages.filter(function (id) {
              return id !== _messageId3;
            })
          }))),
          messages: _newMessages
        });
      }

    case DESELECT_ALL_CHOICES:
      return _objectSpread({}, state, {
        selectedQuickReplies: []
      });

    case TOGGLE_QUICK_REPLY_SELECT_MODE:
      return _objectSpread({}, state, {
        isQuickReplySelectMode: !state.isQuickReplySelectMode
      });

    case AD_SET_LAYER_FIELD:
      {
        var layerId = action.layerId,
            field = action.field;
        return _objectSpread({}, state, {
          layers: _objectSpread({}, state.layers, _defineProperty({}, layerId, _objectSpread({}, state.layers[layerId], {}, field)))
        });
      }

    case AD_TOGGLE_LAYER_FIELD:
      {
        var _layerId = action.layerId,
            key = action.key;
        var _field = {};
        _field[key] = !state.layers[_layerId][key];
        return _objectSpread({}, state, {
          layers: _objectSpread({}, state.layers, _defineProperty({}, _layerId, _objectSpread({}, state.layers[_layerId], {}, _field)))
        });
      }

    case AD_SET_UNDEFINED_BLOCKS_LAYER_FIELD:
      {
        var _scenarioId = action.scenarioId,
            _field2 = action.field;
        var undefinedBlocksLayer = state.scenarios[_scenarioId].undefinedBlocksLayer || {};
        undefinedBlocksLayer = _objectSpread({}, undefinedBlocksLayer, {}, _field2);
        return _objectSpread({}, state, {
          scenarios: _objectSpread({}, state.scenarios, _defineProperty({}, _scenarioId, _objectSpread({}, state.scenarios[_scenarioId], {
            undefinedBlocksLayer: undefinedBlocksLayer
          })))
        });
      }

    case AD_TOGGLE_UNDEFINED_BLOCKS_LAYER_FIELD:
      {
        var _scenarioId2 = action.scenarioId,
            _key = action.key;

        var _undefinedBlocksLayer = state.scenarios[_scenarioId2].undefinedBlocksLayer || {};

        _undefinedBlocksLayer[_key] = !_undefinedBlocksLayer[_key];
        return _objectSpread({}, state, {
          scenarios: _objectSpread({}, state.scenarios, _defineProperty({}, _scenarioId2, _objectSpread({}, state.scenarios[_scenarioId2], {
            undefinedBlocksLayer: _undefinedBlocksLayer
          })))
        });
      }

    case AD_OPEN_ALL_LAYERS:
      {
        var _scenarioId3 = action.scenarioId;
        var layerIds = state.scenarios[_scenarioId3].layers;

        var _layers = Object.values(state.layers).reduce(function (allLayers, layer) {
          return _objectSpread({}, allLayers, _defineProperty({}, layer.id, layerIds.indexOf(layer.id) < 0 ? layer : _objectSpread({}, layer, {
            isExpanded: true
          })));
        }, {});

        return _objectSpread({}, state, {
          layers: _layers
        });
      }

    case AD_TOGGLE_ALL_LAYERS_FIELD:
      {
        var _scenarioId4 = action.scenarioId,
            _field3 = action.field;
        var _layerIds = state.scenarios[_scenarioId4].layers;

        var _layers2 = Object.values(state.layers).reduce(function (allLayers, layer) {
          return _objectSpread({}, allLayers, _defineProperty({}, layer.id, _layerIds.indexOf(layer.id) < 0 ? layer : _objectSpread({}, state.layers[layer.id], {}, _field3)));
        }, {});

        return _objectSpread({}, state, {
          layers: _layers2
        });
      }

    case HANDLE_SWAP_GENERIC_ITEM_RANKS:
      return _objectSpread({}, state, {}, updateListGenericItem(state, action));

    case CREATE_NEW_FILTER_MESSAGE:
      {
        var _action$message2 = action.message,
            _messageSectionId3 = _action$message2.message_section_id,
            _id4 = _action$message2.id;
        var _tempMessageSections = state.messageSections;

        if (_tempMessageSections) {
          Object.keys(_tempMessageSections).forEach(function (msgId) {
            if (msgId === String(_messageSectionId3)) {
              _tempMessageSections[msgId].messages.push(_id4);
            }
          });
        }

        return _objectSpread({}, state, {
          messageSections: _tempMessageSections,
          messages: _objectSpread({}, state.messages, _defineProperty({}, _id4, action.message))
        });
      }
    // TODO: Convert to action constants

    case SET_PAGINATED_SCENARIO_IDS:
      return _objectSpread({}, state, {
        paginatedScenarioIds: action.ids,
        totalScenarios: action.totalScenarios,
        search: action.search,
        page: action.page,
        per: action.per
      });

    case SET_AD_SCENARIOS_LIST:
      {
        return _objectSpread({}, state, {
          scenarios: action.scenarios
        });
      }

    case SET_LINE_FLEX_MESSAGE_COMPONENT:
      {
        var _messageId4 = action.messageId;
        var _message8 = state.messages[_messageId4];
        var updatedlineFlexMessage = updateLineFlexMessageComponent(_message8, action);
        return updateLineFlexMessage(state, _messageId4, updatedlineFlexMessage);
      }

    case AD_REMOVE_BUBBLE:
      {
        var _messageId5 = action.messageId,
            bubbleId = action.bubbleId;
        var _message9 = state.messages[_messageId5];
        var lineFlexMessage = _message9.line_flex_message;
        var filteredBubbles = lineFlexMessage.contents.contents.filter(function (bubble) {
          return bubble.id !== bubbleId;
        });

        var _updatedlineFlexMessage = _objectSpread({}, lineFlexMessage, {
          contents: _objectSpread({}, lineFlexMessage.contents, {
            contents: filteredBubbles
          })
        });

        return updateLineFlexMessage(state, _messageId5, _updatedlineFlexMessage);
      }

    case AD_DUPLICATE_BUBBLE:
      {
        var _messageId6 = action.messageId,
            duplicatedBubble = action.duplicatedBubble;
        var _message10 = state.messages[_messageId6];
        var _lineFlexMessage = _message10.line_flex_message;

        var _updatedlineFlexMessage2 = _objectSpread({}, _lineFlexMessage, {
          contents: _objectSpread({}, _lineFlexMessage.contents, {
            contents: [].concat(_toConsumableArray(_lineFlexMessage.contents.contents), [duplicatedBubble])
          })
        });

        return updateLineFlexMessage(state, _messageId6, _updatedlineFlexMessage2);
      }

    default:
      return state;
  }
});