var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { Component } from 'react';
import { colors } from '@src/colors';
import { hoverMenuBg } from '@src/lib/mixins';
import { transitions } from '@src/lib/animations';
import styled from 'styled-components';
import { ModalPortal, Input } from '@src/components/atoms';
import Modal from '@src/components/molecules/Modal';
import { NeedsCallApi } from '@src/typings/enums';
import { Checkbox } from '@zeals-co-ltd/washi-components';
var DropMenu = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  border: 1px solid ", ";\n  border-radius: 5px;\n  line-height: 40px !important;\n  height: 40px;\n  width: 100%;\n  padding: 0 1rem;\n  margin-top: 0.75rem;\n  color: ", ";\n  background-color: ", ";\n\n  &.adicon_arrow_down_after::after {\n    float: right;\n  }\n"], ["\n  border: 1px solid ", ";\n  border-radius: 5px;\n  line-height: 40px !important;\n  height: 40px;\n  width: 100%;\n  padding: 0 1rem;\n  margin-top: 0.75rem;\n  color: ", ";\n  background-color: ", ";\n\n  &.adicon_arrow_down_after::after {\n    float: right;\n  }\n"])), colors.border.default, colors.default_text, colors.white);
var DropItem = styled(DropMenu)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  transition: ", ";\n  ", ";\n"], ["\n  transition: ", ";\n  ", ";\n"])), transitions.normal, hoverMenuBg);
// Modal for creating a custom form
var QuestionGroupDetailsModal = /** @class */ (function (_super) {
    __extends(QuestionGroupDetailsModal, _super);
    function QuestionGroupDetailsModal(props) {
        var _this = _super.call(this, props) || this;
        _this.handleSubmitUpdate = function () {
            var _a = _this.props, selectedForm = _a.selectedForm, questionGroup = _a.questionGroup;
            var _b = _this.state, initialLabel = _b.initialLabel, initialDescription = _b.initialDescription, initialMaxAnswers = _b.initialMaxAnswers, initialNested = _b.initialNested, initialApiType = _b.initialApiType, selectedApiType = _b.selectedApiType;
            if (initialLabel) {
                var updates = {};
                updates.nested = initialNested;
                if (initialLabel)
                    updates.label = initialLabel;
                if (initialDescription)
                    updates.description = initialDescription;
                if (initialMaxAnswers)
                    updates.max_answer_num = initialMaxAnswers;
                if (selectedApiType) {
                    updates.needs_call_api = selectedApiType;
                }
                else {
                    updates.needs_call_api = initialApiType;
                }
                _this.props.setValuesFromModal(updates.label, updates.description);
                _this.props.updateQuestionGroup(selectedForm, questionGroup, updates);
            }
        };
        _this.apiTypes = [
            NeedsCallApi.DoNothing,
            NeedsCallApi.CallApi,
            NeedsCallApi.InsertCvTask,
            NeedsCallApi.CallDxAPI,
        ];
        _this.state = {
            inputTouched: false,
            initialLabel: _this.props.label,
            initialDescription: _this.props.description,
            initialMaxAnswers: _this.props.questionGroup.max_answer_num,
            initialNested: _this.props.questionGroup.nested,
            initialApiType: _this.props.questionGroup.needs_call_api,
            dropOpened: false,
            selectedApiType: null,
        };
        return _this;
    }
    QuestionGroupDetailsModal.prototype.render = function () {
        var _this = this;
        var _a = this.state, inputTouched = _a.inputTouched, initialLabel = _a.initialLabel, initialDescription = _a.initialDescription, initialMaxAnswers = _a.initialMaxAnswers, initialNested = _a.initialNested, initialApiType = _a.initialApiType, dropOpened = _a.dropOpened, selectedApiType = _a.selectedApiType;
        return (React.createElement(ModalPortal, null,
            React.createElement(Modal, __assign({ "data-testid": "question-group-details-modal", type: "SELECT", text: "Custom Form Question Group \u8A2D\u5B9A", hasError: !initialLabel }, this.props, { onConfirm: this.handleSubmitUpdate }),
                React.createElement("div", null,
                    React.createElement("div", { className: "message-wrapper primary-label" }, "Label"),
                    React.createElement("div", { className: "message-wrapper sub-label" }, "\u82F1\u8A9E\uFF1AHTML\u306B\u5165\u308C\u308B\u90E8\u5206"),
                    React.createElement(Input, { hasError: !initialLabel && inputTouched, placeholder: "Label", onChange: function (e) { return _this.setState({ initialLabel: e.target.value }); }, onBlur: function () { return _this.setState({ inputTouched: true }); }, value: initialLabel || '' })),
                !this.state.initialLabel && this.state.inputTouched ? (React.createElement("div", { className: "message-wrapper error" }, "Label Required")) : null,
                React.createElement("div", null,
                    React.createElement("div", { className: "message-wrapper primary-label" }, "Description"),
                    React.createElement("div", { className: "message-wrapper sub-label" }, "\u65E5\u672C\u8A9E\uFF1A\u30E6\u30FC\u30B6\u30FC\u304C\u898B\u3048\u308B\u90E8\u5206"),
                    React.createElement(Input, { hasError: false, placeholder: "Description", onChange: function (e) {
                            return _this.setState({
                                initialDescription: e.target.value,
                            });
                        }, value: initialDescription || '' })),
                React.createElement("div", null,
                    React.createElement("div", { className: "message-wrapper primary-label" }, "Max Answers"),
                    React.createElement(Input, { hasError: false, placeholder: "1", onChange: function (e) {
                            return _this.setState({
                                initialMaxAnswers: e.target.value,
                            });
                        }, value: initialMaxAnswers || '', type: "number", style: { margin: '12px 0 0 0', width: '80px' } })),
                React.createElement("div", null,
                    React.createElement("div", { className: "message-wrapper primary-label" }, "Nested"),
                    React.createElement("div", { className: "message-wrapper sub-label" }, "\u30B0\u30EB\u30FC\u30D7\u304C1\u3064\u4EE5\u4E0A\u306E\u8CEA\u554F\u304C\u3042\u308B\u5834\u5408"),
                    React.createElement(Checkbox, { "data-testid": "QuestionGroupDetailsModal-Checkbox", color: "primary", checked: initialNested, size: "small", onClick: function () {
                            return _this.setState(function (prevState) { return ({
                                initialNested: !prevState.initialNested,
                            }); });
                        } })),
                React.createElement("div", null,
                    React.createElement("div", { className: "message-wrapper primary-label" }, "API \u8A2D\u5B9A"),
                    React.createElement(DropMenu, { margin: "12px 0 0 0", className: "adicon_arrow_down_after", onClick: function () { return _this.setState({ dropOpened: !dropOpened }); } }, selectedApiType || initialApiType),
                    dropOpened
                        ? this.apiTypes.map(function (item) {
                            return (React.createElement(DropItem, { hasError: false, key: item, onClick: function () {
                                    return _this.setState({
                                        dropOpened: !dropOpened,
                                        selectedApiType: item,
                                    });
                                } },
                                React.createElement("input", { className: "drop-item", readOnly: true, value: item })));
                        })
                        : null))));
    };
    return QuestionGroupDetailsModal;
}(Component));
export default QuestionGroupDetailsModal;
var templateObject_1, templateObject_2;
