var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import { colors } from '@src/colors';
import { hoverShadowDark } from '@src/lib/mixins';
import { transitions } from '@src/lib/animations';
var TagListContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  .header {\n    display: flex;\n    width: 100%;\n    align-items: center;\n    margin-top: 24px;\n    font-weight: bold;\n    color: ", ";\n    > span {\n      font-size: 28px;\n    }\n  }\n  .tag-list-description {\n    margin: 4px 0 8px;\n    font-size: 12px;\n    color: ", ";\n  }\n  .no-contents-container {\n    font-size: 12px;\n    color: ", ";\n  }\n"], ["\n  .header {\n    display: flex;\n    width: 100%;\n    align-items: center;\n    margin-top: 24px;\n    font-weight: bold;\n    color: ", ";\n    > span {\n      font-size: 28px;\n    }\n  }\n  .tag-list-description {\n    margin: 4px 0 8px;\n    font-size: 12px;\n    color: ", ";\n  }\n  .no-contents-container {\n    font-size: 12px;\n    color: ", ";\n  }\n"])), function (props) { return props.color || colors.accent_color; }, colors.text_pale, colors.border.table);
var TagListItemWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: inline-flex;\n  justify-content: space-between;\n  cursor: pointer;\n  width: 100%;\n  height: 50px;\n  background-color: ", ";\n  color: ", ";\n  border-radius: 5px;\n  padding: 8px 8px 8px 16px;\n  margin-top: 4px;\n  align-items: center;\n  ", "\n  overflow: visible;\n  .label-container {\n    .attribute-question-label {\n      font-size: 10px;\n      color: ", ";\n      margin: 0;\n    }\n    .attribute-answer-label {\n      display: flex;\n      align-items: center;\n      color: ", ";\n      margin: 0;\n    }\n    .adicon_tag {\n      font-size: 16px;\n    }\n    .tag-name {\n      font-size: 16px;\n      font-weight: bold;\n    }\n  }\n  .remove_tag {\n    display: flex;\n    padding: 8px;\n    border-radius: 50%;\n    transition: ", ";\n    &:hover {\n      background: ", ";\n    }\n  }\n"], ["\n  display: inline-flex;\n  justify-content: space-between;\n  cursor: pointer;\n  width: 100%;\n  height: 50px;\n  background-color: ",
    ";\n  color: ", ";\n  border-radius: 5px;\n  padding: 8px 8px 8px 16px;\n  margin-top: 4px;\n  align-items: center;\n  ", "\n  overflow: visible;\n  .label-container {\n    .attribute-question-label {\n      font-size: 10px;\n      color: ", ";\n      margin: 0;\n    }\n    .attribute-answer-label {\n      display: flex;\n      align-items: center;\n      color: ", ";\n      margin: 0;\n    }\n    .adicon_tag {\n      font-size: 16px;\n    }\n    .tag-name {\n      font-size: 16px;\n      font-weight: bold;\n    }\n  }\n  .remove_tag {\n    display: flex;\n    padding: 8px;\n    border-radius: 50%;\n    transition: ", ";\n    &:hover {\n      background: ", ";\n    }\n  }\n"])), function (props) {
    return props.itemBackgroundColor || colors.accent_color;
}, colors.white, hoverShadowDark, colors.label.font_light, colors.white, transitions.normal, colors.background.pale_white);
var SelectedTagList = function (_a) {
    var chatbotId = _a.chatbotId, filterId = _a.filterId, quickFilters = _a.quickFilters, attributeQuestions = _a.attributeQuestions, attributeAnswers = _a.attributeAnswers, color = _a.color, borderColor = _a.borderColor, headerIconColor = _a.headerIconColor, itemBackgroundColor = _a.itemBackgroundColor, descriptionContent = _a.descriptionContent, title = _a.title, icon = _a.icon, deleteQuickFilterFromFilter = _a.deleteQuickFilterFromFilter;
    var findAnswer = function (quickFilter) {
        var attribute_answer_id = quickFilter.attribute_answer_id;
        var targetAnswer = attributeAnswers &&
            attributeAnswers.find(function (attributeAnswer) { return attributeAnswer.id === attribute_answer_id; });
        return targetAnswer;
    };
    var findQuestionLabel = function (quickFilter) {
        var targetAnswer = findAnswer(quickFilter);
        if (!targetAnswer)
            return 'None';
        var attribute_question_id = targetAnswer.attribute_question_id;
        var targetQuestion = attributeQuestions &&
            attributeQuestions.find(function (attributeQuestion) { return attributeQuestion.id === attribute_question_id; });
        if (targetQuestion) {
            var label = targetQuestion.label;
            return label || 'labelない';
        }
        else {
            return 'None';
        }
    };
    return (React.createElement(TagListContainer, { color: color, borderColor: borderColor, headerIconColor: headerIconColor },
        React.createElement("div", { className: "header" },
            React.createElement("span", { className: icon ? 'adicon_minus03' : 'adicon_plus03' }),
            title || '含む'),
        React.createElement("div", { className: "tag-list-description" }, descriptionContent || '選択されたタグを含むユーザーが対象になります'),
        React.createElement("div", { className: "tag-list-item-container" }, quickFilters.length ? (quickFilters.map(function (quickFilter) {
            return (React.createElement(TagListItemWrapper, { key: quickFilter.id, className: "question", onClick: function () { }, itemBackgroundColor: itemBackgroundColor },
                React.createElement("div", { className: "label-container" },
                    React.createElement("p", { className: "attribute-question-label" }, findQuestionLabel(quickFilter)),
                    React.createElement("p", { className: "attribute-answer-label" },
                        React.createElement("span", { className: "adicon_tag" }),
                        React.createElement("span", { className: "tag-name" }, findAnswer(quickFilter)
                            ? findAnswer(quickFilter).label
                            : 'None'))),
                React.createElement("div", { className: "adicon_close remove_tag", style: {
                        fontSize: '20px',
                        color: colors.white,
                    }, onClick: function () {
                        return deleteQuickFilterFromFilter(quickFilter, filterId, chatbotId);
                    } })));
        })) : (React.createElement("div", { className: "no-contents-container" }, "\u30BF\u30B0\u304C\u9078\u629E\u3055\u308C\u3066\u3044\u307E\u305B\u3093")))));
};
export default SelectedTagList;
var templateObject_1, templateObject_2;
