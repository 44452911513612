var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import styled from 'styled-components';
import { object, string } from 'yup';
import { colors } from '@src/colors';
import { addMaxLength } from '@src/lib/helpers';
import { Spinner } from '@src/components/atoms';
import { createQuickReplyWithFieldOptions } from '@src/redux/fanp/actions/quickReply';
import get from 'lodash/get';
import limitations from '@src/lib/platforms/limitations';
import { Snackbar, Modal, Button } from '@zeals-co-ltd/washi-components';
import useSnackbarNotify from '@src/components/hooks/useSnackbarNotify';
import { InputType } from 'zeals-protobuf/zeals/web/entities/user_dossier_pb';
import { useSelector, useDispatch } from 'react-redux';
import { setFields, setSelectedField, } from '@src/redux/fanp/actions/fieldValue';
import { useSearchFields } from '@src/components/hooks/fieldAttributes';
import UserFieldSelectableOptions from './UserFieldSelectableOptions';
import UserFields from './UserFields';
// TODO: this will be changed to handled on backend with api
export var filterFieldListToJustHaveSelectInputOnes = function (userFieldList) {
    return userFieldList.filter(function (field) {
        if (field.fieldType === 'Global') {
            return true;
        }
        return field.inputType === InputType.INPUT_TYPE_SELECT_INPUT;
    });
};
var QuickFromUserFieldContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  margin-bottom: 40px;\n  width: 100%;\n  min-width: 920px;\n  max-width: 920px;\n  height: calc(100vh - 326px);\n  .user-field-selectable-options {\n    width: 70%;\n  }\n  .selectableList {\n    height: 100%;\n    width: 30%;\n    max-width: 30%;\n    min-width: 30%;\n    flex: 1;\n    margin-right: 20px;\n    margin-top: 24px;\n    ul {\n      height: 100%;\n    }\n  }\n  &:nth-child(2) {\n    flex: 2;\n  }\n  .error-message {\n    display: flex;\n    align-items: center;\n    margin-top: 20px;\n    font-weight: bold;\n    color: ", ";\n  }\n"], ["\n  display: flex;\n  margin-bottom: 40px;\n  width: 100%;\n  min-width: 920px;\n  max-width: 920px;\n  height: calc(100vh - 326px);\n  .user-field-selectable-options {\n    width: 70%;\n  }\n  .selectableList {\n    height: 100%;\n    width: 30%;\n    max-width: 30%;\n    min-width: 30%;\n    flex: 1;\n    margin-right: 20px;\n    margin-top: 24px;\n    ul {\n      height: 100%;\n    }\n  }\n  &:nth-child(2) {\n    flex: 2;\n  }\n  .error-message {\n    display: flex;\n    align-items: center;\n    margin-top: 20px;\n    font-weight: bold;\n    color: ", ";\n  }\n"])), colors.error);
var QuickFromUserFieldModal = function (props) {
    var chatbotIdParam = props.chatbotIdParam, intl = props.intl, platform = props.platform;
    var _a = __read(useState([]), 2), selectedOptions = _a[0], setSelectedOptions = _a[1];
    var _b = __read(useState([]), 2), selectedOptionsErrors = _b[0], setSelectedOptionsErrors = _b[1];
    var dispatch = useDispatch();
    var _c = useSearchFields(chatbotIdParam), fieldList = _c.fieldList, term = _c.term, setTerm = _c.setTerm;
    var snackbarNotify = useSnackbarNotify();
    useEffect(function () {
        dispatch(setFields(fieldList));
    }, [fieldList]);
    var userFieldSelectableOptionSchema = function (intl, platform) {
        return object({
            label: addMaxLength(string().required(intl.formatMessage({ id: "3KGFyF", defaultMessage: "Label is required" })), intl, 'postback_action.label', platform),
        });
    };
    var userFieldListFromState = useSelector(function (state) { return state.fieldValue.fields; });
    var userFieldList = userFieldListFromState
        ? filterFieldListToJustHaveSelectInputOnes(userFieldListFromState)
        : undefined;
    var selectedField = useSelector(function (state) { return state.fieldValue.selectedField; });
    useEffect(function () {
        var validateSelectedOptions = function (callback) {
            var userFieldSelectableOptionsSchema = function (intl, platform) {
                return object(selectedOptions.reduce(function (schemaObject, userSelectableOption) {
                    var _a;
                    return __assign(__assign({}, schemaObject), (_a = {}, _a[userSelectableOption.id] = userFieldSelectableOptionSchema(intl, platform), _a));
                }, {}));
            };
            userFieldSelectableOptionsSchema(intl, platform)
                .validate(selectedOptions.reduce(function (userSelectableOptions, userSelectableOption) {
                var _a;
                return __assign(__assign({}, userSelectableOptions), (_a = {}, _a[userSelectableOption.id] = userSelectableOption, _a));
            }, {}), { abortEarly: false })
                .then(function () { return callback([]); })
                .catch(function (e) {
                if (!e.inner)
                    return callback([]);
                var errors = [];
                e.inner.forEach(function (errorDetail) {
                    var id = Number(errorDetail.path.match(/\d+/)[0]);
                    errors[id] = errorDetail.message;
                });
                return callback(errors);
            });
        };
        validateSelectedOptions(function (errors) { return setSelectedOptionsErrors(errors); });
    }, [intl, platform, selectedOptions]);
    var getMaxQuickReplyChoice = function () {
        var platform = props.platform;
        return get(limitations[platform], 'MAX_QUICK_REPLY_CHOICE', 0);
    };
    var toggleSelectedFieldOption = function (selectedOption) {
        var hasSelectedOption = function (option) {
            return selectedOptions.map(function (_a) {
                var id = _a.id;
                return id;
            }).includes(option.id);
        };
        if (hasSelectedOption(selectedOption)) {
            var newSelectedOptions = selectedOptions.filter(function (option) { return option.id !== selectedOption.id; });
            return setSelectedOptions(newSelectedOptions);
        }
        return setSelectedOptions(__spreadArray(__spreadArray([], __read(selectedOptions)), [selectedOption]));
    };
    var toggleSelectAllOptions = function () {
        var selectableOptions = (selectedField === null || selectedField === void 0 ? void 0 : selectedField.selectableOptions) || [];
        if (!selectableOptions.length)
            return;
        var newSelectedOptions = selectedOptions.length
            ? []
            : selectableOptions.slice(0, maxQuickReplyChoice);
        setSelectedOptions(newSelectedOptions);
    };
    var onOptionLabelChange = function (e, userSelectableOption) {
        var value = e.target.value;
        var newSelectedOptions = selectedOptions.map(function (selectableOption) {
            return selectableOption.id === userSelectableOption.id
                ? __assign(__assign({}, selectableOption), { label: value }) : selectableOption;
        });
        setSelectedOptions(newSelectedOptions);
    };
    var maxQuickReplyChoice = getMaxQuickReplyChoice();
    var isOverLimit = selectedOptions.length > maxQuickReplyChoice;
    var isNotSelected = !selectedOptions.length;
    var hasError = Boolean(selectedOptionsErrors.length) || isOverLimit || isNotSelected;
    var clearSelectedOptions = function () { return setSelectedOptions([]); };
    var close = function () {
        setSelectedOptions([]);
        dispatch(setSelectedField(undefined));
        props.onCancel();
    };
    var handleConfirm = function () { return __awaiter(void 0, void 0, void 0, function () {
        var blockIdParam, chatbotIdParam, adScenarioIdParam, _a, onCreated, createdQuickReplyChoices, error_1;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    blockIdParam = props.blockIdParam, chatbotIdParam = props.chatbotIdParam, adScenarioIdParam = props.adScenarioIdParam, _a = props.onCreated, onCreated = _a === void 0 ? function () {
                        /* Empty func */
                    } : _a;
                    if (!(selectedField && selectedOptions.length)) return [3 /*break*/, 4];
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, createQuickReplyWithFieldOptions({
                            chatbotId: chatbotIdParam,
                            scenarioId: adScenarioIdParam,
                            blockId: blockIdParam,
                            fieldId: selectedField.id,
                            fieldSelectedOptions: selectedOptions,
                        })(dispatch)];
                case 2:
                    createdQuickReplyChoices = _b.sent();
                    clearSelectedOptions();
                    onCreated(createdQuickReplyChoices);
                    close();
                    return [3 /*break*/, 4];
                case 3:
                    error_1 = _b.sent();
                    snackbarNotify.setOpen(true);
                    snackbarNotify.setNotify({
                        message: error_1.message,
                        severity: 'error',
                    });
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement(Modal, { "data-testid": "quick-form-user-field-modal", className: "user-field-modal", open: true, maxWidth: "lg", title: intl.formatMessage({ id: "ErHiF5", defaultMessage: "Create QuickReply" }), onClose: close, actions: React.createElement(React.Fragment, null,
            React.createElement(Button, { variant: "outlined", color: "primary", "data-testid": "create-quickreply-cancel", style: {
                    textTransform: 'none',
                }, onClick: function () {
                    setSelectedOptions([]);
                    dispatch(setSelectedField(undefined));
                    props.onCancel();
                } }, intl.formatMessage({ id: "47FYwb", defaultMessage: "Cancel" })),
            React.createElement(Button, { "data-testid": "create-quickreply-submit", color: "success", style: {
                    textTransform: 'none',
                }, variant: "contained", disabled: hasError, onClick: handleConfirm }, intl.formatMessage({ id: "VzzYJk", defaultMessage: "Create" }))) },
        React.createElement(QuickFromUserFieldContainer, null,
            userFieldList ? (React.createElement(UserFields, { userFieldList: userFieldList, chatbotId: chatbotIdParam, selectedUserFieldId: selectedField === null || selectedField === void 0 ? void 0 : selectedField.id, clearSelectedOptions: clearSelectedOptions, setSearchTerm: setTerm, searchTerm: term, classes: "selectableList" })) : (React.createElement(Spinner, null)),
            userFieldList && selectedField && (React.createElement(React.Fragment, null,
                React.createElement(UserFieldSelectableOptions, { selectedField: selectedField, selectedOptions: selectedOptions, selectedOptionsErrors: selectedOptionsErrors, toggleSelectedFieldOption: toggleSelectedFieldOption, toggleSelectAllOptions: toggleSelectAllOptions, onOptionLabelChange: onOptionLabelChange, maxQuickReplyChoice: maxQuickReplyChoice, platform: platform }))),
            userFieldList && !selectedField && (React.createElement("div", { className: "error-message", "data-testid": "error-message" },
                React.createElement("div", null, intl.formatMessage({ id: "lbl0Is", defaultMessage: "\u2190 First, search a User Field and then Create a Quick Reply with Selectable Options" }))))),
        React.createElement(Snackbar, { open: snackbarNotify.open, alertProps: { severity: snackbarNotify.notify.severity, icon: '' }, onClose: snackbarNotify.clearNotify },
            React.createElement("span", null, snackbarNotify.notify.message))));
};
export default injectIntl(QuickFromUserFieldModal);
var templateObject_1;
