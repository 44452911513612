var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import { colors } from '@src/colors';
var HelpContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  height: 12%;\n  margin: 24px;\n  border-radius: 8px;\n  background: ", ";\n  position: absolute;\n  bottom: 0;\n  left: 0;\n  .help {\n    padding: 20px;\n    display: flex;\n    .icon-container {\n      width: 20px;\n      .icon {\n        width: 16px;\n        height: 16px;\n        border-radius: 50%;\n        background: ", ";\n        color: #fff;\n        font-size: 10px;\n        font-style: normal;\n        font-weight: 500;\n        line-height: 16px;\n        letter-spacing: 0em;\n        text-align: center;\n        margin-right: 10px;\n        margin-top: 3px;\n      }\n    }\n    p {\n      margin: 0;\n      font-size: 14px;\n      font-style: normal;\n      font-weight: 400;\n      line-height: 20px;\n      letter-spacing: 0em;\n      text-align: left;\n    }\n  }\n"], ["\n  height: 12%;\n  margin: 24px;\n  border-radius: 8px;\n  background: ", ";\n  position: absolute;\n  bottom: 0;\n  left: 0;\n  .help {\n    padding: 20px;\n    display: flex;\n    .icon-container {\n      width: 20px;\n      .icon {\n        width: 16px;\n        height: 16px;\n        border-radius: 50%;\n        background: ", ";\n        color: #fff;\n        font-size: 10px;\n        font-style: normal;\n        font-weight: 500;\n        line-height: 16px;\n        letter-spacing: 0em;\n        text-align: center;\n        margin-right: 10px;\n        margin-top: 3px;\n      }\n    }\n    p {\n      margin: 0;\n      font-size: 14px;\n      font-style: normal;\n      font-weight: 400;\n      line-height: 20px;\n      letter-spacing: 0em;\n      text-align: left;\n    }\n  }\n"])), colors.rpa_settings.border_line, colors.rpa_settings.selected_color);
var ComponentDescription = function (_a) {
    var description = _a.description, className = _a.className;
    return (React.createElement(HelpContainer, { className: className },
        React.createElement("div", { className: "help" },
            React.createElement("div", { className: "icon-container" },
                React.createElement("div", { className: "icon" }, "i")),
            React.createElement("p", null, description))));
};
export default ComponentDescription;
var templateObject_1;
