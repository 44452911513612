import { ModalType } from '../types/modal';
// TODO should rename function name for make it sence
export var modalCancel = function () {
    return { type: ModalType.CLOSE_MODAL };
};
export var modalExpand = function (isExpanded) { return ({
    type: ModalType.EXPAND_MODAL,
    isExpanded: isExpanded,
}); };
// TODO confirm with another member about this function
export var setModalData = function (key, value) { return ({
    type: ModalType.SET_MODAL_DATA,
    key: key,
    value: value,
}); };
export var showModal = function (visibleBody) { return ({
    type: ModalType.SHOW_MODAL,
    visibleBody: visibleBody,
}); };
