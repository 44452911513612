var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import ModalPortal from '@src/components/atoms/ModalPortal';
import ModalWrapper from '@src/components/atoms/ModalWrapper';
import { Modal } from '@src/components/molecules';
import modalTypes from '@src/lib/modalTypes';
import { valueTypeToString, dateFormatExampleMap, valueMapValidation, } from '@src/lib/formAutomation';
import { FormAutomationStepValueType, DateFormat, } from 'zeals-protobuf/zeals/web/entities/form_automation_pb';
import { DataType, } from 'zeals-protobuf/zeals/web/entities/user_dossier_pb';
import Button from '@src/components/atoms/Button';
import buttonTypes from '@src/lib/buttonTypes';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { FormAutomationMode } from '@src/typings/enums';
import FormAutomationFixedValueSection from './FormAutomationFixedValueSection';
import FormAutomationDateFormatSection from './FormAutomationDateFormatSection';
import FormAutomationValueMap from './FormAutomationValueMap';
var valueTypeItems = [];
Object.values(FormAutomationStepValueType).forEach(function (type) {
    if (type !==
        FormAutomationStepValueType.FORM_AUTOMATION_STEP_VALUE_TYPE_UNSPECIFIED) {
        var label = valueTypeToString(type);
        valueTypeItems.push({
            label: label,
            value: type,
        });
    }
});
var FormAutomationSelectActionModal = function (_a) {
    var initialSelectStep = _a.initialSelectStep, field = _a.field, intl = _a.intl, onCancel = _a.onCancel, onChange = _a.onChange;
    var initialFormikState = {
        valueType: initialSelectStep === null || initialSelectStep === void 0 ? void 0 : initialSelectStep.valueType,
        dateFormat: initialSelectStep === null || initialSelectStep === void 0 ? void 0 : initialSelectStep.dateFormat,
        fixedValue: initialSelectStep === null || initialSelectStep === void 0 ? void 0 : initialSelectStep.fixedValue,
        valueMap: initialSelectStep === null || initialSelectStep === void 0 ? void 0 : initialSelectStep.valueMapMap,
    };
    var schema = Yup.object({
        fixedValue: Yup.string().test('FixedValue', 
        // Disable ESLint to accept yup error message customization, see https://github.com/jquense/yup#error-message-customization
        // eslint-disable-next-line
        '${path} is not valid', function (value, context) {
            switch (context.parent.valueType) {
                case FormAutomationStepValueType.FORM_AUTOMATION_STEP_VALUE_TYPE_FIELD:
                    return true;
                case FormAutomationStepValueType.FORM_AUTOMATION_STEP_VALUE_TYPE_FIXED:
                    return Boolean(value);
                default:
                    return true;
            }
        }),
        dateFormat: Yup.number().test('DateFormat', 
        // Disable ESLint to accept yup error message customization, see https://github.com/jquense/yup#error-message-customization
        // eslint-disable-next-line
        '${path} is not valid', function (value, context) {
            if ((field === null || field === void 0 ? void 0 : field.dataType) === DataType.DATA_TYPE_DATETIME &&
                value === DateFormat.DATE_FORMAT_UNSPECIFIED) {
                switch (context.parent.valueType) {
                    case FormAutomationStepValueType.FORM_AUTOMATION_STEP_VALUE_TYPE_FIELD:
                        return value !== DateFormat.DATE_FORMAT_UNSPECIFIED;
                    case FormAutomationStepValueType.FORM_AUTOMATION_STEP_VALUE_TYPE_FIXED:
                        return true;
                    default:
                        return true;
                }
            }
            else {
                return true;
            }
        }),
        valueMap: Yup.array().test('ValueMap', 
        // Disable ESLint to accept yup error message customization, see https://github.com/jquense/yup#error-message-customization
        // eslint-disable-next-line
        '${path} is not valid', function (valueMap, context) { return valueMapValidation(valueMap, context, field); }),
    });
    var _b = __read(useState(FormAutomationMode.Normal), 2), mode = _b[0], setMode = _b[1];
    function updateMap(setFieldValue, valueMap, i, entry) {
        setFieldValue('valueMap', valueMap.map(function (originalEntry, index) {
            return index === i ? entry : originalEntry;
        }));
    }
    function addEntryToMap(setFieldValue, valueMap) {
        setFieldValue('valueMap', __spreadArray(__spreadArray([], __read(valueMap)), [['', '']]));
    }
    function removeEntryFromMap(setFieldValue, valueMap, i) {
        setFieldValue('valueMap', valueMap.filter(function (_, index) { return i !== index; }));
    }
    return (React.createElement(Formik, { initialValues: initialFormikState, validationSchema: schema, onSubmit: function () { return undefined; } }, function (_a) {
        var values = _a.values, errors = _a.errors, isValid = _a.isValid, setFieldValue = _a.setFieldValue;
        return (React.createElement(ModalPortal, null,
            React.createElement(ModalWrapper, null,
                mode === FormAutomationMode.Normal && (React.createElement(Modal, { className: "form-automation-modal", type: modalTypes.EDIT, onCancel: onCancel, onConfirm: function () {
                        return onChange(__assign(__assign({}, initialSelectStep), { valueType: values.valueType, valueMapMap: values.valueMap, fixedValue: values.fixedValue, dateFormat: values.dateFormat }));
                    }, hasError: !isValid },
                    React.createElement("h3", { className: "title" }, "Select Input Action"),
                    values.valueType ===
                        FormAutomationStepValueType.FORM_AUTOMATION_STEP_VALUE_TYPE_FIELD && (React.createElement(FormAutomationValueMap, { field: field, valueMap: values.valueMap, isValid: isValid, onAddMapEntry: function () {
                            return addEntryToMap(setFieldValue, values.valueMap);
                        }, onRemoveMapEntry: function (index) {
                            return removeEntryFromMap(setFieldValue, values.valueMap, index);
                        }, onUpdateMapEntry: function (index, entry) {
                            return updateMap(setFieldValue, values.valueMap, index, entry);
                        }, error: errors.valueMap })),
                    values.valueType ===
                        FormAutomationStepValueType.FORM_AUTOMATION_STEP_VALUE_TYPE_FIXED && (React.createElement(FormAutomationFixedValueSection, { value: values.fixedValue, onChange: function (fixedValue) {
                            return setFieldValue('fixedValue', fixedValue);
                        }, error: errors.fixedValue })),
                    (field === null || field === void 0 ? void 0 : field.dataType) === DataType.DATA_TYPE_DATETIME &&
                        values.valueType ===
                            FormAutomationStepValueType.FORM_AUTOMATION_STEP_VALUE_TYPE_FIELD && (React.createElement("div", { className: "date-format-button" },
                        React.createElement("span", null, "Select the date format you want to set"),
                        React.createElement("div", { className: "date-format-card" },
                            React.createElement("div", { className: "date-format-title" }, "Date Format"),
                            React.createElement("div", { className: "date-format-value" }, dateFormatExampleMap[values.dateFormat] ||
                                'Not selected')),
                        errors.dateFormat && (React.createElement("span", { className: "error" }, intl.formatMessage({ id: "tQhtAm", defaultMessage: "Empty Date Format is not allowed" }))),
                        React.createElement(Button, { buttonType: buttonTypes.SETTING, className: "date-format", onClick: function () { return setMode(FormAutomationMode.Date); } },
                            React.createElement("span", { className: "adicon_date" }),
                            dateFormatExampleMap[values.dateFormat]
                                ? intl.formatMessage({ id: "Q6NY27", defaultMessage: "Change Date Format" })
                                : intl.formatMessage({ id: "XELbEm", defaultMessage: "Select Date Format" })))))),
                mode === FormAutomationMode.Date && (React.createElement(Modal, { className: "form-automation-select-date-format-modal", hasError: !isValid, width: "50%", onCancel: function () { return setMode(FormAutomationMode.Normal); } },
                    React.createElement(FormAutomationDateFormatSection, { initialDateFormat: values.dateFormat, onCancel: function () { return setMode(FormAutomationMode.Normal); }, onSubmit: function (df) {
                            setFieldValue('dateFormat', df);
                            setMode(FormAutomationMode.Normal);
                        } }))))));
    }));
};
export default injectIntl(FormAutomationSelectActionModal);
