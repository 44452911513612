import "core-js/modules/es.array.map";
import "core-js/modules/es.function.name";
import _extends from "@babel/runtime/helpers/extends";
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { colors } from '@src/colors';
import { ModalPortal, Button, RadioCheckbox } from '@src/components/atoms';
import buttonTypes from '@src/lib/buttonTypes';
import { hoverHighlightText } from '@src/lib/mixins';
import Modal from '@src/components/molecules/Modal';
var propTypes = {
  checkAllUsers: PropTypes.bool,
  toggleCheckAllUsers: PropTypes.func,
  checkedUsers: PropTypes.array,
  chatbotIdParam: PropTypes.string
};
var Container = styled.div.withConfig({
  displayName: "Container",
  componentId: "sc-hjdt1p-0"
})(["margin-bottom:1rem;font-size:14px;color:", ";display:flex;flex-wrap:wrap;align-items:center;button{width:auto;white-space:nowrap;margin-bottom:12px;padding:0 12px;}> span:first-of-type{margin-right:16px;}.radio-checkbox > div{font-size:14px;margin:0 2rem 0 1rem;width:120px;", ";}"], colors.text_pale, hoverHighlightText);
export var DropDownContainer = styled.div.withConfig({
  displayName: "DropDownContainer",
  componentId: "sc-hjdt1p-1"
})(["display:flex;align-items:center;position:relative;margin-right:16px;white-space:nowrap;&:nth-child(5){margin:0 2rem;}select{width:160px;height:38px;padding:0 1rem 0 0.5rem;font-family:Noto Sans JP;font-size:12px;color:", ";}&:before{position:absolute;right:4px;font-size:24px;}"], colors.default_text);

var BulkTagConfirmModal = function BulkTagConfirmModal(props) {
  return React.createElement(ModalPortal, null, React.createElement(Modal, _extends({
    "data-testid": "bulk-tag-confirm-modal",
    type: "TEST"
  }, props, {
    text: "\u672ACV\u30E6\u30FC\u30B6\u30FC\u306B\u30BF\u30B0\u3092\u4ED8\u4E0E\u3057\u307E\u3057\u305F",
    onCancel: function onCancel() {
      location.reload();
      props.onCancel();
    }
  })));
};

var BulkTagModal = function BulkTagModal(props) {
  return React.createElement(ModalPortal, null, React.createElement(Modal, _extends({
    "data-testid": "bulk-tag-modal",
    type: "SELECT"
  }, props, {
    text: "\u672ACV\u30E6\u30FC\u30B6\u30FC\u306B\u30BF\u30B0\u3092\u4ED8\u4E0E\u3057\u3066\u3082\u3044\u3044\u3067\u3059\u304B\uFF1F"
  })));
};

var DropDownOption = function DropDownOption(_ref) {
  var text = _ref.text,
      options = _ref.options;
  return React.createElement(DropDownContainer, {
    className: "adicon_down"
  }, React.createElement("span", null, text), React.createElement("select", null, options.map(function (option, i) {
    return React.createElement("option", {
      key: "".concat(i, "-user-filter-dropdown"),
      value: option.value
    }, option.name);
  })));
};

DropDownOption.propTypes = {
  text: PropTypes.string,
  options: PropTypes.array
};

var UserOption = function UserOption(_ref2) {
  var checkAllUsers = _ref2.checkAllUsers,
      toggleCheckAllUsers = _ref2.toggleCheckAllUsers,
      checkedUsers = _ref2.checkedUsers,
      chatbotIdParam = _ref2.chatbotIdParam,
      toggleTagsForUserListModal = _ref2.toggleTagsForUserListModal,
      toggleBlockUserModal = _ref2.toggleBlockUserModal,
      toggleBulkTagModal = _ref2.toggleBulkTagModal,
      toggleBulkTagConfirmModal = _ref2.toggleBulkTagConfirmModal,
      isBulkTagModalOpen = _ref2.isBulkTagModalOpen,
      isBulkTagConfirmModalOpen = _ref2.isBulkTagConfirmModalOpen,
      submitBulkTag = _ref2.submitBulkTag,
      tags = _ref2.tags;
  var areUsersChecked = checkedUsers.length || checkAllUsers;
  return React.createElement(Container, null, React.createElement(RadioCheckbox, {
    checked: checkAllUsers,
    changeCheckedState: function changeCheckedState() {
      return toggleCheckAllUsers(chatbotIdParam);
    },
    text: "\u5168\u3066\u3092\u30C1\u30A7\u30C3\u30AF"
  }), React.createElement(Button, {
    text: "\u30BF\u30B0\u3092\u3064\u3051\u308B",
    onClick: toggleTagsForUserListModal,
    buttonType: areUsersChecked ? buttonTypes.SETTING : buttonTypes.DISABLED,
    hasError: !areUsersChecked,
    margin: "0 0.5rem"
  }), React.createElement(Button, {
    text: "\u30D6\u30ED\u30C3\u30AF\u3059\u308B",
    onClick: toggleBlockUserModal,
    buttonType: areUsersChecked ? buttonTypes.DELETE : buttonTypes.DISABLED,
    hasError: !areUsersChecked,
    margin: "0 0.5rem"
  }), React.createElement(Button, {
    text: "\u672ACV\u30E6\u30FC\u30B6\u30FC\u306B\u4E00\u62ECtag\u4ED8\u3051",
    onClick: toggleBulkTagModal,
    buttonType: tags.length ? buttonTypes.SETTING : buttonTypes.DISABLED,
    hasError: !tags.length,
    margin: "0 0.5rem"
  }), isBulkTagConfirmModalOpen ? React.createElement(BulkTagConfirmModal, {
    onCancel: toggleBulkTagConfirmModal
  }) : null, isBulkTagModalOpen ? React.createElement(BulkTagModal, {
    onCancel: toggleBulkTagModal,
    onConfirm: submitBulkTag
  }) : null, React.createElement(DropDownOption, {
    text: "\u4E26\u3073\u66FF\u3048\uFF1A",
    options: [{
      value: '',
      name: '流入順'
    }]
  }), React.createElement(DropDownOption, {
    text: "\u8868\u793A\u6570\uFF1A",
    options: [{
      value: '',
      name: '50件'
    }]
  }));
};

UserOption.propTypes = propTypes;
export default UserOption;