var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import { MediumType } from '@src/typings/enums';
import { colors } from '@src/colors';
var BaseContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin: 20px 10px;\n  border-radius: 16px;\n  width: 260px;\n  border: 1px solid ", ";\n  overflow: hidden;\n"], ["\n  margin: 20px 10px;\n  border-radius: 16px;\n  width: 260px;\n  border: 1px solid ", ";\n  overflow: hidden;\n"])), colors.border.default);
var ImageMessageContainer = styled(BaseContainer)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  height: 260px;\n  padding: 8px;\n  background: url(", ");\n  background-position-x: 0%;\n  background-position-y: 0%;\n  background-repeat: no-repeat;\n  background-position: center;\n  background-size: contain;\n"], ["\n  height: 260px;\n  padding: 8px;\n  background: url(", ");\n  background-position-x: 0%;\n  background-position-y: 0%;\n  background-repeat: no-repeat;\n  background-position: center;\n  background-size: contain;\n"])), function (props) { return props.url; });
var AudioMessageContainer = styled(BaseContainer)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  height: 135px;\n  padding: 8px;\n  text-align: center;\n  word-break: break-all;\n  .adicon_big_placeholder_audio {\n    font-size: 56px;\n  }\n"], ["\n  height: 135px;\n  padding: 8px;\n  text-align: center;\n  word-break: break-all;\n  .adicon_big_placeholder_audio {\n    font-size: 56px;\n  }\n"])));
var VideoMessageContainer = styled(BaseContainer)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  > div {\n    height: 135px;\n    overflow: hidden;\n  }\n\n  .video {\n    padding: 8px;\n    text-align: center;\n    word-break: break-all;\n    border-bottom: 1px solid ", ";\n\n    .adicon_big_placeholder_video {\n      font-size: 56px;\n    }\n  }\n\n  .preview {\n    background: url(", ");\n    background-position-x: 0%;\n    background-position-y: 0%;\n    background-repeat: repeat;\n    background-size: auto;\n    background-position: center;\n    background-size: cover;\n    background-repeat: no-repeat;\n  }\n"], ["\n  > div {\n    height: 135px;\n    overflow: hidden;\n  }\n\n  .video {\n    padding: 8px;\n    text-align: center;\n    word-break: break-all;\n    border-bottom: 1px solid ", ";\n\n    .adicon_big_placeholder_video {\n      font-size: 56px;\n    }\n  }\n\n  .preview {\n    background: url(", ");\n    background-position-x: 0%;\n    background-position-y: 0%;\n    background-repeat: repeat;\n    background-size: auto;\n    background-position: center;\n    background-size: cover;\n    background-repeat: no-repeat;\n  }\n"])), colors.border.default, function (props) { return props.preview_url; });
var FileNode = function (_a) {
    var message = _a.data.message;
    switch (message.medium.type) {
        case MediumType.Image:
            return (React.createElement(ImageMessageContainer, { key: "file-" + message.medium.id, url: message.medium.proxy_image_url, "data-testid": "FileNode_ImageMessageContainer" }));
        case MediumType.Audio:
            return (React.createElement(AudioMessageContainer, { key: "file-" + message.medium.id, "data-testid": "FileNode_AudioMessageContainer" },
                React.createElement("div", { className: "adicon_big_placeholder_audio" }),
                message.medium.name));
        case MediumType.Video:
            return (React.createElement(VideoMessageContainer, { key: "file-" + message.medium.id, preview_url: message.medium.proxy_image_url, "data-testid": "FileNode_VideoMessageContainer" },
                React.createElement("div", { className: "video" },
                    React.createElement("div", { className: "adicon_big_placeholder_video" }),
                    message.medium.name),
                React.createElement("div", { className: "preview" })));
        default:
            return null;
    }
};
export default FileNode;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
