export var LINE_EMOJI_DATA = [{
  code: 0x100078,
  tag_jp: 'キュンキュン',
  tag_en: 'love',
  tag_kr: '하트뿅'
}, {
  code: 0x100079,
  tag_jp: 'ぎゃはは',
  tag_en: 'hahaha',
  tag_kr: '하하하'
}, {
  code: 0x10007a,
  tag_jp: 'おねがい',
  tag_en: 'please!',
  tag_kr: '부탁해'
}, {
  code: 0x10007b,
  tag_jp: 'えっ!?',
  tag_en: 'shocked',
  tag_kr: '헉!'
}, {
  code: 0x10007c,
  tag_jp: 'うぅっ',
  tag_en: 'sad',
  tag_kr: '흑흑'
}, {
  code: 0x10007d,
  tag_jp: 'ゴーン',
  tag_en: 'oh no!',
  tag_kr: '이럴수가!'
}, {
  code: 0x10007e,
  tag_jp: '激怒',
  tag_en: 'super angry',
  tag_kr: '열받아'
}, {
  code: 0x10008c,
  tag_jp: 'イヒッ',
  tag_en: 'hee',
  tag_kr: '큭큭'
}, {
  code: 0x10008d,
  tag_jp: 'えへへ',
  tag_en: 'moon grin',
  tag_kr: '좋아'
}, {
  code: 0x10008e,
  tag_jp: 'あちゃー',
  tag_en: 'oops',
  tag_kr: '헤헷'
}, {
  code: 0x10008f,
  tag_jp: 'ナイス',
  tag_en: 'moon wink',
  tag_kr: '찡끗'
}, {
  code: 0x100090,
  tag_jp: 'ホッ',
  tag_en: 'content',
  tag_kr: '안심'
}, {
  code: 0x100091,
  tag_jp: 'なんと!?',
  tag_en: 'gasp!',
  tag_kr: '헙!'
}, {
  code: 0x100092,
  tag_jp: 'しゅん',
  tag_en: 'blue',
  tag_kr: '시무룩'
}, {
  code: 0x100093,
  tag_jp: 'もぐもぐ',
  tag_en: 'nom nom',
  tag_kr: '우걱우걱'
}, {
  code: 0x100094,
  tag_jp: 'たらりー',
  tag_en: 'ah...',
  tag_kr: '추르르'
}, {
  code: 0x100095,
  tag_jp: 'やった〜',
  tag_en: 'at last!',
  tag_kr: '해냈어!'
}, {
  code: 0x10007f,
  tag_jp: 'あはは',
  tag_en: 'haha',
  tag_kr: '코호호'
}, {
  code: 0x100080,
  tag_jp: 'きらら',
  tag_en: 'sparkling eyes',
  tag_kr: '초롱초롱'
}, {
  code: 0x100081,
  tag_jp: 'ぶちゅー',
  tag_en: 'kiss me',
  tag_kr: '쪽-'
}, {
  code: 0x100082,
  tag_jp: 'べぇー',
  tag_en: 'tongue out',
  tag_kr: '메롱'
}, {
  code: 0x100083,
  tag_jp: '固まる',
  tag_en: 'frozen',
  tag_kr: '허걱'
}, {
  code: 0x100096,
  tag_jp: 'ちゅっ',
  tag_en: 'cony kiss',
  tag_kr: '츄-'
}, {
  code: 0x100097,
  tag_jp: 'フッ',
  tag_en: 'hmph',
  tag_kr: '에헵!'
}, {
  code: 0x100098,
  tag_jp: 'ぶるっ',
  tag_en: 'brr',
  tag_kr: '끄응~'
}, {
  code: 0x100099,
  tag_jp: 'げっそり',
  tag_en: 'half dead',
  tag_kr: '헬슥'
}, {
  code: 0x10009a,
  tag_jp: 'ギラーン',
  tag_en: 'sharp',
  tag_kr: '으흠!'
}, {
  code: 0x10009b,
  tag_jp: 'げっ!?',
  tag_en: 'panic',
  tag_kr: '허거덕'
}, {
  code: 0x10009c,
  tag_jp: 'ねむい',
  tag_en: 'doze off',
  tag_kr: '졸림'
}, {
  code: 0x10009d,
  tag_jp: 'デレー',
  tag_en: 'aww',
  tag_kr: '부끄'
}, {
  code: 0x10009e,
  tag_jp: 'ピキッ',
  tag_en: 'argh!',
  tag_kr: '부르르'
}, {
  code: 0x100084,
  tag_jp: 'ポッ',
  tag_en: 'hello',
  tag_kr: '발그레'
}, {
  code: 0x100085,
  tag_jp: 'ハッ!?',
  tag_en: 'eh?!',
  tag_kr: '헛'
}, {
  code: 0x100086,
  tag_jp: 'おやすみ',
  tag_en: 'goodnight',
  tag_kr: '졸려움'
}, {
  code: 0x100087,
  tag_jp: 'むかーっ',
  tag_en: 'stressed',
  tag_kr: '불안불안'
}, {
  code: 0x100088,
  tag_jp: 'やつれる',
  tag_en: 'worn out',
  tag_kr: '폐인'
}, {
  code: 0x100089,
  tag_jp: 'なにっ!?',
  tag_en: 'what?!',
  tag_kr: '뭐라?'
}, {
  code: 0x10008a,
  tag_jp: 'Jウィンク',
  tag_en: 'james wink',
  tag_kr: '윙크윙크'
}, {
  code: 0x10008b,
  tag_jp: '一目惚れ',
  tag_en: 'attracted',
  tag_kr: '반했어요'
}, {
  code: 0x10009f,
  tag_jp: '知らんぷり',
  tag_en: 'who, me?',
  tag_kr: '쩝'
}, {
  code: 0x100001,
  tag_jp: 'わーい',
  tag_en: 'happy',
  tag_kr: '방긋'
}, {
  code: 0x100002,
  tag_jp: 'にこ',
  tag_en: 'smile',
  tag_kr: '미소'
}, {
  code: 0x100003,
  tag_jp: '笑顔',
  tag_en: 'laugh',
  tag_kr: '웃음'
}, {
  code: 0x100004,
  tag_jp: 'きゅん',
  tag_en: 'blush',
  tag_kr: '귀엽'
}, {
  code: 0x100005,
  tag_jp: 'ういんく',
  tag_en: 'wink',
  tag_kr: '윙크'
}, {
  code: 0x100006,
  tag_jp: 'うれしい',
  tag_en: 'glad',
  tag_kr: '행복'
}, {
  code: 0x100007,
  tag_jp: 'ハートキス',
  tag_en: 'blowkiss',
  tag_kr: '사랑의키스'
}, {
  code: 0x100008,
  tag_jp: 'キス',
  tag_en: 'kiss',
  tag_kr: '키스'
}, {
  code: 0x100009,
  tag_jp: 'ぽっ',
  tag_en: 'baffled',
  tag_kr: '당황'
}, {
  code: 0x10000a,
  tag_jp: 'ほっ',
  tag_en: 'relief',
  tag_kr: '편안'
}, {
  code: 0x10000b,
  tag_jp: 'にやり',
  tag_en: 'grin',
  tag_kr: '씨익'
}, {
  code: 0x10000c,
  tag_jp: 'あっかんべ',
  tag_en: 'kidding',
  tag_kr: '헤헤'
}, {
  code: 0x10000d,
  tag_jp: 'べー',
  tag_en: 'funny',
  tag_kr: '하하'
}, {
  code: 0x10000e,
  tag_jp: 'しらー',
  tag_en: 'unamused',
  tag_kr: '지루함'
}, {
  code: 0x10000f,
  tag_jp: 'にた',
  tag_en: 'smirk',
  tag_kr: '히죽~'
}, {
  code: 0x100010,
  tag_jp: 'たらー',
  tag_en: 'bittersmile',
  tag_kr: '민망'
}, {
  code: 0x100011,
  tag_jp: 'うーん',
  tag_en: 'hm',
  tag_kr: '생각'
}, {
  code: 0x100012,
  tag_jp: 'えー',
  tag_en: 'disappointed',
  tag_kr: '실망'
}, {
  code: 0x100013,
  tag_jp: 'もうやだ',
  tag_en: 'unbearable',
  tag_kr: '으으'
}, {
  code: 0x100014,
  tag_jp: 'あせ',
  tag_en: 'coldsweat',
  tag_kr: '식은땀'
}, {
  code: 0x100015,
  tag_jp: '困る',
  tag_en: 'exasperated',
  tag_kr: '곤란'
}, {
  code: 0x100016,
  tag_jp: 'がーん',
  tag_en: 'anguished',
  tag_kr: '엥?'
}, {
  code: 0x100017,
  tag_jp: 'ごめん',
  tag_en: 'tired',
  tag_kr: '피곤'
}, {
  code: 0x100018,
  tag_jp: '涙',
  tag_en: 'tear',
  tag_kr: '훌쩍'
}, {
  code: 0x100019,
  tag_jp: 'えーん',
  tag_en: 'crying',
  tag_kr: '엉엉'
}, {
  code: 0x10001a,
  tag_jp: 'うれし泣き',
  tag_en: 'tearsofjoy',
  tag_kr: '너무 웃겨'
}, {
  code: 0x10001b,
  tag_jp: 'ふらふら',
  tag_en: 'astonished',
  tag_kr: '놀람'
}, {
  code: 0x10001c,
  tag_jp: 'さいあく',
  tag_en: 'scream',
  tag_kr: '무서워'
}, {
  code: 0x10001d,
  tag_jp: 'むっ',
  tag_en: 'pouting',
  tag_kr: '흠'
}, {
  code: 0x10001e,
  tag_jp: '怒る',
  tag_en: 'angry',
  tag_kr: '버럭'
}, {
  code: 0x10001f,
  tag_jp: '居眠り',
  tag_en: 'dozing',
  tag_kr: '드르렁'
}, {
  code: 0x100020,
  tag_jp: 'ごほごほ',
  tag_en: 'mask',
  tag_kr: '콜록콜록'
}, {
  code: 0x100021,
  tag_jp: 'ぼー',
  tag_en: 'dazed',
  tag_kr: '흥'
}, {
  code: 0x100022,
  tag_jp: 'にゃー',
  tag_en: 'catface',
  tag_kr: '쿄쿄'
}, {
  code: 0x100023,
  tag_jp: 'うまー',
  tag_en: 'yummy',
  tag_kr: '냠냠'
}, {
  code: 0x10005d,
  tag_jp: 'ぶた',
  tag_en: 'pig',
  tag_kr: '돼지'
}, {
  code: 0x10005f,
  tag_jp: 'ねこ',
  tag_en: 'cat',
  tag_kr: '고양이'
}, {
  code: 0x10005e,
  tag_jp: 'いぬ',
  tag_en: 'dog',
  tag_kr: '개'
}, {
  code: 0x1000a0,
  tag_jp: 'おばけ',
  tag_en: 'ghost',
  tag_kr: '유령'
}, {
  code: 0x1000a1,
  tag_jp: 'ハロウィン',
  tag_en: 'halloween',
  tag_kr: '할로윈'
}, {
  code: 0x100024,
  tag_jp: 'あくま',
  tag_en: 'imp',
  tag_kr: '악마'
}, {
  code: 0x1000a2,
  tag_jp: 'どくろ',
  tag_en: 'skull',
  tag_kr: '해골'
}, {
  code: 0x1000a3,
  tag_jp: 'うんち',
  tag_en: 'poop',
  tag_kr: '똥'
}, {
  code: 0x1000a4,
  tag_jp: 'ファイヤー',
  tag_en: 'fire',
  tag_kr: '불'
}, {
  code: 0x1000a5,
  tag_jp: 'yes',
  tag_en: 'yes',
  tag_kr: 'yes'
}, {
  code: 0x1000a6,
  tag_jp: 'no',
  tag_en: 'no',
  tag_kr: 'no'
}, {
  code: 0x1000a7,
  tag_jp: 'トイレ',
  tag_en: 'toilet',
  tag_kr: '변기'
}, {
  code: 0x100026,
  tag_jp: 'むかっ',
  tag_en: 'rage',
  tag_kr: '분노'
}, {
  code: 0x100027,
  tag_jp: 'びくっ',
  tag_en: 'surprise',
  tag_kr: '깜짝'
}, {
  code: 0x100029,
  tag_jp: 'しずく',
  tag_en: 'drop',
  tag_kr: '물방울'
}, {
  code: 0x10002a,
  tag_jp: 'ダッシュ',
  tag_en: 'dash',
  tag_kr: '슝'
}, {
  code: 0x10002b,
  tag_jp: '眠い',
  tag_en: 'sleepy',
  tag_kr: '졸려'
}, {
  code: 0x10002c,
  tag_jp: '唇',
  tag_en: 'lips',
  tag_kr: '입술'
}, {
  code: 0x10002d,
  tag_jp: 'きらきら',
  tag_en: 'shiny',
  tag_kr: '반짝반짝'
}, {
  code: 0x10002e,
  tag_jp: '目',
  tag_en: 'eyes',
  tag_kr: '눈'
}, {
  code: 0x10002f,
  tag_jp: '耳',
  tag_en: 'ear',
  tag_kr: '귀'
}, {
  code: 0x10003a,
  tag_jp: '雷',
  tag_en: 'thunder',
  tag_kr: '번개'
}, {
  code: 0x1000a8,
  tag_jp: '三日月',
  tag_en: 'moon',
  tag_kr: '달'
}, {
  code: 0x1000a9,
  tag_jp: '太陽',
  tag_en: 'sun',
  tag_kr: '해'
}, {
  code: 0x1000aa,
  tag_jp: '雨',
  tag_en: 'rain',
  tag_kr: '비'
}, {
  code: 0x1000ab,
  tag_jp: '雪',
  tag_en: 'snow',
  tag_kr: '함박눈'
}, {
  code: 0x1000ac,
  tag_jp: '雲',
  tag_en: 'cloud',
  tag_kr: '구름'
}, {
  code: 0x100033,
  tag_jp: 'OK',
  tag_en: 'ok',
  tag_kr: '최고'
}, {
  code: 0x1000ad,
  tag_jp: 'ブーイング',
  tag_en: 'boo',
  tag_kr: '루저'
}, {
  code: 0x100030,
  tag_jp: 'チョキ',
  tag_en: 'scissors',
  tag_kr: '가위'
}, {
  code: 0x100031,
  tag_jp: 'パー',
  tag_en: 'paper',
  tag_kr: '보'
}, {
  code: 0x100032,
  tag_jp: 'グー',
  tag_en: 'rock',
  tag_kr: '바위'
}, {
  code: 0x1000ae,
  tag_jp: '拍手',
  tag_en: 'clap',
  tag_kr: '박수'
}, {
  code: 0x100035,
  tag_jp: '!',
  tag_en: '!',
  tag_kr: '!'
}, {
  code: 0x100036,
  tag_jp: '?',
  tag_en: '?',
  tag_kr: '?'
}, {
  code: 0x100039,
  tag_jp: '音符',
  tag_en: 'eighthnote',
  tag_kr: '음표'
}, {
  code: 0x100037,
  tag_jp: 'ハート',
  tag_en: 'heart',
  tag_kr: '하트'
}, {
  code: 0x100038,
  tag_jp: '失恋',
  tag_en: 'brokenheart',
  tag_kr: '실연'
}, {
  code: 0x1000af,
  tag_jp: '1ハート',
  tag_en: '1 heart',
  tag_kr: '하트1개'
}, {
  code: 0x1000b0,
  tag_jp: '3ハート',
  tag_en: '3 hearts',
  tag_kr: '하트3개'
}, {
  code: 0x1000b1,
  tag_jp: '2ハート',
  tag_en: '2 hearts',
  tag_kr: '하트2개'
}, {
  code: 0x1000b2,
  tag_jp: 'スター',
  tag_en: 'star',
  tag_kr: '별'
}, {
  code: 0x1000b3,
  tag_jp: '2スター',
  tag_en: '2 stars',
  tag_kr: '별2개'
}, {
  code: 0x10003b,
  tag_jp: 'PC',
  tag_en: 'PC',
  tag_kr: '컴퓨터'
}, {
  code: 0x10003c,
  tag_jp: '電話',
  tag_en: 'phone',
  tag_kr: '전화기'
}, {
  code: 0x10003d,
  tag_jp: '携帯',
  tag_en: 'cellphone',
  tag_kr: '핸드폰'
}, {
  code: 0x1000b4,
  tag_jp: '電卓',
  tag_en: 'calculator',
  tag_kr: '계산기'
}, {
  code: 0x100040,
  tag_jp: 'ラブレター',
  tag_en: 'loveletter',
  tag_kr: '연애편지'
}, {
  code: 0x100041,
  tag_jp: 'えんぴつ',
  tag_en: 'pencil',
  tag_kr: '연필'
}, {
  code: 0x100042,
  tag_jp: '野球',
  tag_en: 'baseball',
  tag_kr: '야구'
}, {
  code: 0x100043,
  tag_jp: 'ゴルフ',
  tag_en: 'golfball',
  tag_kr: '골프'
}, {
  code: 0x100044,
  tag_jp: 'テニス',
  tag_en: 'tennisball',
  tag_kr: '테니스'
}, {
  code: 0x100045,
  tag_jp: 'サッカー',
  tag_en: 'soccerball',
  tag_kr: '축구'
}, {
  code: 0x1000b5,
  tag_jp: 'ベッド',
  tag_en: 'bed',
  tag_kr: '침대'
}, {
  code: 0x100047,
  tag_jp: '電車',
  tag_en: 'train',
  tag_kr: '기차'
}, {
  code: 0x100049,
  tag_jp: '車',
  tag_en: 'car',
  tag_kr: '자동차'
}, {
  code: 0x10004a,
  tag_jp: '飛行機',
  tag_en: 'plane',
  tag_kr: '비행기'
}, {
  code: 0x10004b,
  tag_jp: 'チャリ',
  tag_en: 'bicycle',
  tag_kr: '자전거'
}, {
  code: 0x10004c,
  tag_jp: 'ビル',
  tag_en: 'building',
  tag_kr: '빌딩'
}, {
  code: 0x10004d,
  tag_jp: '郵便局',
  tag_en: 'postoffice',
  tag_kr: '우체국'
}, {
  code: 0x10004e,
  tag_jp: '病院',
  tag_en: 'hospital',
  tag_kr: '병원'
}, {
  code: 0x10004f,
  tag_jp: '学校',
  tag_en: 'school',
  tag_kr: '학교'
}, {
  code: 0x100050,
  tag_jp: '銀行',
  tag_en: 'bank',
  tag_kr: '은행'
}, {
  code: 0x100051,
  tag_jp: '温泉',
  tag_en: 'spa',
  tag_kr: '온천'
}, {
  code: 0x100053,
  tag_jp: 'スタンド',
  tag_en: 'gasstation',
  tag_kr: '주유소'
}, {
  code: 0x100054,
  tag_jp: 'うどん',
  tag_en: 'noodles',
  tag_kr: '국수'
}, {
  code: 0x100055,
  tag_jp: 'バーガー',
  tag_en: 'hamburger',
  tag_kr: '햄버거'
}, {
  code: 0x100056,
  tag_jp: 'ケーキ',
  tag_en: 'cake',
  tag_kr: '케익'
}, {
  code: 0x1000b6,
  tag_jp: 'チョコケーキ',
  tag_en: 'chocolate cake',
  tag_kr: '케이크'
}, {
  code: 0x100057,
  tag_jp: 'カクテル',
  tag_en: 'cocktail',
  tag_kr: '칵테일'
}, {
  code: 0x100058,
  tag_jp: 'ビール',
  tag_en: 'beer',
  tag_kr: '맥주'
}, {
  code: 0x100059,
  tag_jp: 'コーヒー',
  tag_en: 'coffee',
  tag_kr: '커피'
}, {
  code: 0x1000b7,
  tag_jp: 'くすり',
  tag_en: 'medicine',
  tag_kr: '약'
}, {
  code: 0x10005b,
  tag_jp: 'りんご',
  tag_en: 'apple',
  tag_kr: '사과'
}, {
  code: 0x10005c,
  tag_jp: 'ひよこ',
  tag_en: 'chick',
  tag_kr: '병아리'
}, {
  code: 0x100060,
  tag_jp: '花',
  tag_en: 'flower',
  tag_kr: '꽃'
}, {
  code: 0x100061,
  tag_jp: 'よつば',
  tag_en: 'fourleafclover',
  tag_kr: '네잎클로버'
}, {
  code: 0x100062,
  tag_jp: 'チューリップ',
  tag_en: 'tulip',
  tag_kr: '튤립'
}, {
  code: 0x1000b8,
  tag_jp: 'バラ',
  tag_en: 'rose',
  tag_kr: '장미'
}, {
  code: 0x1000b9,
  tag_jp: 'バラ1輪',
  tag_en: 'rose stalk',
  tag_kr: '장미 한 송이'
}, {
  code: 0x100064,
  tag_jp: '波',
  tag_en: 'wave',
  tag_kr: '파도'
}, {
  code: 0x100065,
  tag_jp: 'たばこ',
  tag_en: 'cigar',
  tag_kr: '담배'
}, {
  code: 0x100066,
  tag_jp: '禁煙',
  tag_en: 'nosmoking',
  tag_kr: '금연'
}, {
  code: 0x100067,
  tag_jp: 'ヒール',
  tag_en: 'highheels',
  tag_kr: '하이힐'
}, {
  code: 0x100068,
  tag_jp: 'リップ',
  tag_en: 'lipstick',
  tag_kr: '립스틱'
}, {
  code: 0x100069,
  tag_jp: 'リボン',
  tag_en: 'ribbon',
  tag_kr: '리본'
}, {
  code: 0x10006a,
  tag_jp: 'カメラ',
  tag_en: 'camera',
  tag_kr: '카메라'
}, {
  code: 0x10006b,
  tag_jp: 'バッグ',
  tag_en: 'bag',
  tag_kr: '가방'
}, {
  code: 0x10006c,
  tag_jp: '本',
  tag_en: 'book',
  tag_kr: '책'
}, {
  code: 0x10006d,
  tag_jp: 'TV',
  tag_en: 'TV',
  tag_kr: '텔레비전'
}, {
  code: 0x10006e,
  tag_jp: 'ゲーム',
  tag_en: 'videogame',
  tag_kr: '게임'
}, {
  code: 0x10006f,
  tag_jp: '映画',
  tag_en: 'cinema',
  tag_kr: '영화'
}, {
  code: 0x100070,
  tag_jp: 'ヘッドホン',
  tag_en: 'headphones',
  tag_kr: '헤드폰'
}, {
  code: 0x100071,
  tag_jp: '時計',
  tag_en: 'clock',
  tag_kr: '시계'
}, {
  code: 0x100072,
  tag_jp: '財布',
  tag_en: 'purse',
  tag_kr: '지갑'
}, {
  code: 0x100073,
  tag_jp: '冠',
  tag_en: 'crown',
  tag_kr: '왕관'
}, {
  code: 0x100074,
  tag_jp: 'リング',
  tag_en: 'ring',
  tag_kr: '반지'
}, {
  code: 0x100075,
  tag_jp: 'ギフト',
  tag_en: 'gift',
  tag_kr: '선물'
}, {
  code: 0x100076,
  tag_jp: 'バースデー',
  tag_en: 'birthday',
  tag_kr: '생일'
}, {
  code: 0x100077,
  tag_jp: 'ぴかっ',
  tag_en: 'lightbulb',
  tag_kr: '조명'
}];