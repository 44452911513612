var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { makeFetchInit, handleAPIErrors } from '@src/lib/helpers';
import Moment from 'moment';
import endpoints from '@src/endpoints';
import debounce from 'lodash/debounce';
import { DEBOUNCE_TIME, articleTypes, buttonTypes, } from '@src/lib/app-constants';
import { adScenario } from '@src/redux/fanp/constants';
import { submitScenarioDelivery } from '@src/services/api/scenarioDelivery';
import * as scenarioApi from '@src/services/api/scenario';
import * as buttonItemApi from '@src/services/api/buttonItems';
import * as blockApi from '@src/services/api/block';
import * as layerApi from '@src/services/api/layer';
import * as genericMessageApi from '@src/services/api/genericMessage';
import * as talkTriggerApi from '@src/services/api/talkTrigger';
import { updateTextMessage } from '@src/services/api/message';
import { setBlockName, adSetStepDeliveryTime, adSetStepDaysAfter, adToggleImageLoading, setGenericItem, adSetGenericListButton, } from './setActions';
import { handleStepCampaignContent, handleUpdatedScenario, handleAjaxFreeInput, handleNewScenarioDelivery, } from './ajaxActions';
import { fetchAdScenarios, fetchAdScenario } from './getActions';
var AD_EDIT_TEXT_MESSAGE = adScenario.AD_EDIT_TEXT_MESSAGE, AD_EDIT_ARTICLE = adScenario.AD_EDIT_ARTICLE, AD_UPDATE_TEXT_BUTTON = adScenario.AD_UPDATE_TEXT_BUTTON, AD_EDIT_SCHEDULED_AT = adScenario.AD_EDIT_SCHEDULED_AT, AD_EDIT_CAMPAIGN_CONTENT_SETTING = adScenario.AD_EDIT_CAMPAIGN_CONTENT_SETTING, AD_EDIT_FREE_INPUT = adScenario.AD_EDIT_FREE_INPUT, EDIT_SCENARIO_PROPERTY = adScenario.EDIT_SCENARIO_PROPERTY, CONNECT_BLOCK_TO_BUTTON = adScenario.CONNECT_BLOCK_TO_BUTTON, CHANGE_IMAGE_ASPECT_RATIO = adScenario.CHANGE_IMAGE_ASPECT_RATIO, UNDO_AD_SCENARIO = adScenario.UNDO_AD_SCENARIO, HANDLE_SWAP_GENERIC_ITEM_RANKS = adScenario.HANDLE_SWAP_GENERIC_ITEM_RANKS;
export var editScenarioProperty = function (scenarioId, diff) {
    if (diff === void 0) { diff = {}; }
    return ({
        type: EDIT_SCENARIO_PROPERTY,
        scenarioId: scenarioId,
        diff: diff,
    });
};
export var editArticle = function (_a) {
    var chatbotId = _a.chatbotId, scenarioId = _a.scenarioId, messageId = _a.messageId, itemId = _a.itemId, articleId = _a.articleId, scrapingChecked = _a.scrapingChecked, formData = _a.formData, article = _a.article;
    return ({
        type: AD_EDIT_ARTICLE,
        chatbotId: chatbotId,
        scenarioId: scenarioId,
        messageId: messageId,
        itemId: itemId,
        articleId: articleId,
        scrapingChecked: scrapingChecked,
        formData: formData,
        article: article,
    });
};
export var adEditScheduledAt = function (scenarioId, scheduledAt) { return ({
    type: AD_EDIT_SCHEDULED_AT,
    scenarioId: scenarioId,
    scheduledAt: scheduledAt,
}); };
var editCampaignContentSetting = function (chatbotId, adScenarioId, value, campaignId) { return ({
    type: AD_EDIT_CAMPAIGN_CONTENT_SETTING,
    chatbotId: chatbotId,
    adScenarioId: adScenarioId,
    value: value,
    campaign_id: campaignId,
}); };
/* FreeInputParams = {
        chatbotId,
        adScenarioId,
        messageId,
        talkTriggerId,
        nextBlockId,
        nextScenarioId,
        rank,
    }
*/
export var editFreeInput = function (freeInputParams) { return (__assign({ type: AD_EDIT_FREE_INPUT }, freeInputParams)); };
export var connectBlockToButton = function (_a) {
    var blockId = _a.blockId, messageId = _a.messageId, buttonId = _a.buttonId, itemIterator = _a.itemIterator, name = _a.name, listType = _a.listType;
    return ({
        type: CONNECT_BLOCK_TO_BUTTON,
        messageId: messageId,
        itemIterator: itemIterator,
        blockId: blockId,
        name: name,
        buttonId: buttonId,
        listType: listType,
    });
};
/*
  chatbotId,
  adScenarioId,
  messageId,
  carouselTemplateId,
  imageAspectRatio,
*/
var changeImageAspectRatio = function (params) { return (__assign({ type: CHANGE_IMAGE_ASPECT_RATIO }, params)); };
export var debouncedEditBlockName = debounce(function (dispatch, _a) {
    var blockName = _a.blockName, chatbotId = _a.chatbotId, adScenarioId = _a.adScenarioId, blockId = _a.blockId;
    return __awaiter(void 0, void 0, void 0, function () {
        var block;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, blockApi.editBlock(chatbotId, adScenarioId, blockId, {
                        name: blockName,
                    })];
                case 1:
                    block = _b.sent();
                    dispatch(setBlockName(block));
                    return [2 /*return*/];
            }
        });
    });
}, DEBOUNCE_TIME);
export var editBlockName = function (args) { return function (dispatch) {
    dispatch(setBlockName({
        id: args.blockId,
        name: args.blockName,
    }));
    debouncedEditBlockName(dispatch, args);
}; };
export var editScenario = function (chatbotId, scenarioId, options) { return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
    var scenario, update;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, scenarioApi.updateScenario(chatbotId, scenarioId, options)];
            case 1:
                scenario = _a.sent();
                update = Object.keys(options).reduce(function (update, key) {
                    update[key] = scenario[key];
                    return update;
                }, {});
                dispatch(editScenarioProperty(scenarioId, update));
                return [2 /*return*/, scenario];
        }
    });
}); }; };
export var updateCampaignContentDelivery = function (_a) {
    var chatbotId = _a.chatbotId, adScenarioId = _a.adScenarioId, campaignId = _a.campaignId, campaignContentsId = _a.campaignContentsId, campaignContent = _a.campaignContent, draft = _a.draft;
    return function (dispatch) {
        var daysAfter = campaignContent.daysAfter, deliveryTime = campaignContent.deliveryTime;
        var url = endpoints.campaignContents.member({
            chatbotId: chatbotId,
            adScenarioId: adScenarioId,
            campaignContentsId: campaignContentsId,
        });
        var body = {
            days_after: daysAfter,
            delivery_time: deliveryTime,
            chatbot_id: chatbotId,
            ad_scenario_id: adScenarioId,
            id: campaignContentsId,
            campaign_id: campaignId,
        };
        fetch(url, makeFetchInit('PUT', body))
            .then(function (res) { return res.json(); })
            .then(function (_a) {
            var resDaysAfter = _a.days_after, resDeliveryTime = _a.delivery_time;
            var stepDeliveryTime = Moment(resDeliveryTime)
                .format('HH:mm')
                .split(':');
            var hour = stepDeliveryTime[0];
            var minute = stepDeliveryTime[1];
            dispatch(adSetStepDaysAfter(resDaysAfter));
            dispatch(adSetStepDeliveryTime('hour', parseInt(hour)));
            dispatch(adSetStepDeliveryTime('minute', parseInt(minute)));
            dispatch(handleStepCampaignContent(adScenarioId, {
                days_after: resDaysAfter,
                delivery_time: resDeliveryTime,
            }));
            dispatch(editCampaignContentSetting(chatbotId, adScenarioId, draft));
        })
            .catch(function (e) { return handleAPIErrors('Error updating campaign contents:', e); });
    };
};
export var editScenarioMainSetting = function (_a) {
    var chatbotId = _a.chatbotId, adScenarioId = _a.adScenarioId;
    return function (dispatch) {
        var url = endpoints.chatbots.mainSetting(chatbotId);
        var body = { ad_scenario_id: adScenarioId };
        fetch(url, makeFetchInit('PUT', body))
            .then(function (res) { return res.json(); })
            .then(function (_a) {
            var scenario = _a.scenario;
            dispatch(handleUpdatedScenario(adScenarioId, scenario));
        })
            .catch(function (e) { return handleAPIErrors('Error setting main scenario:', e); });
    };
};
export var fetchEditCampaignContentSetting = function (_a) {
    var chatbotId = _a.chatbotId, adScenarioId = _a.adScenarioId, value = _a.value, campaignId = _a.campaign_id;
    return function (dispatch) {
        var url = endpoints.campaignContents.updateSetting(chatbotId, adScenarioId);
        var body = {
            draft: value,
            campaign_id: campaignId,
        };
        fetch(url, makeFetchInit('PUT', body))
            .then(function (res) { return res.json(); })
            .then(function () {
            dispatch(editCampaignContentSetting(chatbotId, adScenarioId, value, campaignId));
        })
            .catch(function (e) {
            return handleAPIErrors('Error updating campaign contents setting:', e);
        });
    };
};
export var fetchEditFreeInput = function (_a) {
    var chatbotId = _a.chatbotId, adScenarioId = _a.adScenarioId, talkTriggerId = _a.talkTriggerId, nextScenarioId = _a.nextScenarioId, nextBlockId = _a.nextBlockId, rank = _a.rank, blockIdParam = _a.blockIdParam;
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        var talkTrigger;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, talkTriggerApi.updateTalkTrigger(chatbotId, adScenarioId, blockIdParam, talkTriggerId, rank, {
                        nextScenarioId: nextScenarioId,
                        nextBlockId: nextBlockId,
                    })];
                case 1:
                    talkTrigger = _a.sent();
                    dispatch(handleAjaxFreeInput(talkTrigger, blockIdParam));
                    return [2 /*return*/, dispatch(fetchAdScenario({
                            chatbotId: chatbotId,
                            adScenarioId: adScenarioId,
                        }))];
            }
        });
    }); };
};
export var fetchChangeImageAspectRatio = function (_a) {
    var chatbotId = _a.chatbotId, adScenarioId = _a.adScenarioId, messageId = _a.messageId, genericTemplateId = _a.genericTemplateId, imageAspectRatio = _a.imageAspectRatio;
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        var e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, genericMessageApi.updateImeage({
                            chatbotId: chatbotId,
                            adScenarioId: adScenarioId,
                            genericTemplateId: genericTemplateId,
                            imageAspectRatio: imageAspectRatio,
                        })];
                case 1:
                    _a.sent();
                    dispatch(changeImageAspectRatio({
                        chatbotId: chatbotId,
                        adScenarioId: adScenarioId,
                        messageId: messageId,
                        genericTemplateId: genericTemplateId,
                        imageAspectRatio: imageAspectRatio,
                    }));
                    return [3 /*break*/, 3];
                case 2:
                    e_1 = _a.sent();
                    handleAPIErrors('Error updating image_aspect_ratio:', e_1);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
};
export var moveBlockToOtherLayer = function (_a) {
    var chatType = _a.chatType, chatbotId = _a.chatbotId, layerId = _a.layerId, blockId = _a.blockId, adScenarioId = _a.adScenarioId, targetLayerId = _a.targetLayerId, per = _a.per, page = _a.page, search = _a.search;
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!layerId) return [3 /*break*/, 2];
                    return [4 /*yield*/, blockApi.moveBlock(chatbotId, adScenarioId, layerId, blockId, targetLayerId)];
                case 1:
                    _a.sent();
                    return [3 /*break*/, 4];
                case 2: return [4 /*yield*/, layerApi.assignUnconnectedBlock(targetLayerId, chatbotId, adScenarioId, blockId)];
                case 3:
                    _a.sent();
                    _a.label = 4;
                case 4: return [4 /*yield*/, dispatch(fetchAdScenarios({
                        chatTypeParam: chatType,
                        chatbotId: chatbotId,
                        adScenarioId: adScenarioId,
                        pushUrl: false,
                        preventScenarioDeliveryUpdate: true,
                        per: per,
                        page: page,
                        search: search,
                    }))];
                case 5:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
};
export var handleSwapGenericItemRanks = function (genericItemsParams) { return (__assign({ type: HANDLE_SWAP_GENERIC_ITEM_RANKS }, genericItemsParams)); };
export var swapGenericItemRanks = function (_a) {
    var chatbotId = _a.chatbotId, scenarioId = _a.scenarioId, messageId = _a.messageId, resourceItemId = _a.resourceItemId, resourceItemRank = _a.resourceItemRank, targetItemId = _a.targetItemId, targetItemRank = _a.targetItemRank;
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        var e_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, genericMessageApi.updateItemRank({
                            chatbotId: chatbotId,
                            scenarioId: scenarioId,
                            messageId: messageId,
                            resourceItemId: resourceItemId,
                            resourceItemRank: resourceItemRank,
                            targetItemId: targetItemId,
                            targetItemRank: targetItemRank,
                        })];
                case 1:
                    _a.sent();
                    dispatch(handleSwapGenericItemRanks({
                        messageId: messageId,
                        resourceItemId: resourceItemId,
                        targetItemId: targetItemId,
                        resourceItemRank: targetItemRank,
                        targetItemRank: resourceItemRank,
                    }));
                    return [3 /*break*/, 3];
                case 2:
                    e_2 = _a.sent();
                    handleAPIErrors('Update generic item rank error', e_2);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
};
export var adSubmitScenarioDelivery = function (_a) {
    var chatbotId = _a.chatbotId, scenarioId = _a.scenarioId, scheduledAt = _a.scheduledAt;
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        var scenarioDelivery;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, submitScenarioDelivery(chatbotId, scenarioId, scheduledAt)];
                case 1:
                    scenarioDelivery = _a.sent();
                    dispatch(handleNewScenarioDelivery(scenarioId, scenarioDelivery));
                    return [2 /*return*/];
            }
        });
    }); };
};
export var debouncedFetchEditArticle = debounce(function (dispatch, _a) {
    var chatbotId = _a.chatbotId, scenarioId = _a.scenarioId, messageId = _a.messageId, itemId = _a.itemId, articleId = _a.articleId, scrapingChecked = _a.scrapingChecked, formData = _a.formData, article = _a.article;
    var url = endpoints.articles.member({
        chatbotId: parseInt(chatbotId),
        scenarioId: parseInt(scenarioId),
        messageId: messageId,
        itemId: itemId,
        id: articleId,
    });
    var body = {};
    var json = true;
    if (formData) {
        body = formData;
        json = false;
        dispatch(adToggleImageLoading({
            messageId: messageId,
            itemId: itemId,
            isImageLoading: true,
        }));
    }
    else {
        body = article;
        if (article[articleTypes.URL] === '') {
            body[articleTypes.URL] = null;
        }
        if (scrapingChecked) {
            body.auto_fetch = scrapingChecked;
        }
    }
    fetch(url, makeFetchInit('PUT', body, json, formData))
        .then(function (res) {
        if (res.status !== 200)
            throw new Error('記事を更新できませんでした');
        return res.json();
    })
        .then(function (_a) {
        var genericItem = _a.generic_item, message = _a.message;
        if (message)
            throw new Error(message);
        dispatch(setGenericItem(messageId, itemId, genericItem));
        dispatch(adToggleImageLoading({
            messageId: messageId,
            itemId: itemId,
            isImageLoading: false,
        }));
    })
        .catch(function (err) {
        handleAPIErrors('Article update', err);
        dispatch({ type: UNDO_AD_SCENARIO });
    });
}, DEBOUNCE_TIME);
export var editArticleThunk = function (args) { return function (dispatch) {
    if (!args.formData) {
        dispatch(editArticle(args));
    }
    debouncedFetchEditArticle(dispatch, args);
}; };
export var adEditTextMessage = function (_a) {
    var chatbotId = _a.chatbotId, scenarioId = _a.scenarioId, messageId = _a.messageId, value = _a.value, _b = _a.buttons, buttons = _b === void 0 ? [] : _b;
    return ({
        type: AD_EDIT_TEXT_MESSAGE,
        chatbotId: chatbotId,
        scenarioId: scenarioId,
        messageId: messageId,
        value: value,
        buttons: buttons,
    });
};
var debouncedFetchEditTextMessage = debounce(function (_a) {
    var value = _a.value, chatbotId = _a.chatbotId, scenarioId = _a.scenarioId, messageId = _a.messageId, dispatch = _a.dispatch;
    return __awaiter(void 0, void 0, void 0, function () {
        var res, e_3;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, updateTextMessage(parseInt(chatbotId), parseInt(scenarioId), messageId, value)];
                case 1:
                    res = _b.sent();
                    return [3 /*break*/, 3];
                case 2:
                    e_3 = _b.sent();
                    handleAPIErrors('テキストを更新できませんでした', e_3);
                    dispatch({ type: UNDO_AD_SCENARIO });
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    });
}, DEBOUNCE_TIME);
export var editTextMessage = function (args) { return function (dispatch) {
    dispatch(adEditTextMessage(args));
    debouncedFetchEditTextMessage(__assign(__assign({}, args), { dispatch: dispatch }));
}; };
var updateTextButton = function (buttonParams) { return (__assign({ type: AD_UPDATE_TEXT_BUTTON }, buttonParams)); };
export var editTextButton = function (_a) {
    var chatbotId = _a.chatbotId, scenarioId = _a.scenarioId, messageId = _a.messageId, buttonItemId = _a.buttonItemId, buttonTemplate = _a.buttonTemplate, nextScenarioId = _a.nextScenarioId, postbackActionId = _a.postbackActionId;
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        var body, buttonItem, e_4;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    switch (buttonTemplate.effect) {
                        case buttonTypes.URL:
                        case buttonTypes.CALL:
                        case buttonTypes.EMAIL:
                            body = {
                                name: buttonTemplate.name,
                                url: buttonTemplate.url,
                            };
                            break;
                        case buttonTypes.STOCK:
                            body = {
                                name: buttonTemplate.name,
                                effect: buttonTemplate.effect,
                            };
                            break;
                        case buttonTypes.SHARE:
                            body = {
                                name: 'シェアする',
                                effect: buttonTemplate.effect,
                            };
                            break;
                        case buttonTypes.BLOCK:
                            if (buttonTemplate.blockId) {
                                body = {
                                    name: buttonTemplate.name,
                                    effect: null,
                                    next_block_id: buttonTemplate.blockId,
                                    postback_action_id: postbackActionId,
                                    layer_rank: buttonTemplate.layerRank,
                                };
                            }
                            else {
                                body = {
                                    name: buttonTemplate.name,
                                    effect: null,
                                    next_scenario_id: nextScenarioId,
                                    postback_action_id: postbackActionId,
                                    layer_rank: buttonTemplate.layerRank,
                                };
                            }
                            break;
                        default:
                            break;
                    }
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, buttonItemApi.editTextMessageButton(chatbotId, scenarioId, messageId, buttonItemId, body)];
                case 2:
                    buttonItem = _a.sent();
                    dispatch(updateTextButton({
                        messageId: messageId,
                        buttonItemId: buttonItemId,
                        ajaxButtonItem: buttonItem,
                        scenarioId: scenarioId,
                    }));
                    return [3 /*break*/, 4];
                case 3:
                    e_4 = _a.sent();
                    console.error('テキストのボタンを更新できませんでした', e_4);
                    handleAPIErrors('テキストのボタンを更新できませんでした。', e_4);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
};
export var editGenericButton = function (_a) {
    var chatbotId = _a.chatbotId, scenarioId = _a.scenarioId, messageId = _a.messageId, itemId = _a.itemId, buttonItemId = _a.buttonItemId, buttonTemplate = _a.buttonTemplate, nextScenarioId = _a.nextScenarioId;
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        var genericButtonItem, e_5;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, genericMessageApi.updateButton({
                            chatbotId: chatbotId,
                            scenarioId: scenarioId,
                            messageId: messageId,
                            genericItemId: itemId,
                            id: buttonItemId,
                            buttonTemplate: buttonTemplate,
                            nextScenarioId: nextScenarioId,
                        })];
                case 1:
                    genericButtonItem = _a.sent();
                    dispatch(adSetGenericListButton({
                        messageId: messageId,
                        itemId: itemId,
                        buttonItemId: buttonItemId,
                        listType: 'generic',
                        updatedButton: genericButtonItem,
                        scenarioId: scenarioId,
                    }));
                    return [3 /*break*/, 3];
                case 2:
                    e_5 = _a.sent();
                    handleAPIErrors('カルーセルボタンを更新できませんでした。', e_5);
                    dispatch({ type: UNDO_AD_SCENARIO });
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
};
