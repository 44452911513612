var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { colors, shadows } from '@src/colors';
import { injectIntl } from 'react-intl';
import { hoverHighlight, hoverMenuBg, instagramBorderGradient, } from '@src/lib/mixins';
import { fetchDeleteChatbot } from '@src/redux/fanp/actions/chatbotList';
import { BotName } from './BotName';
var CircleImg = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100px;\n  height: 100px;\n  border: 1px solid ", ";\n  border-radius: 50%;\n  margin: 0 auto 20px;\n  overflow: hidden;\n  position: relative;\n\n  img {\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    -moz-transform: translate(-50%, -50%);\n    -ms-transform: translate(-50%, -50%);\n    -webkit-transform: translate(-50%, -50%);\n    transform: translate(-50%, -50%);\n  }\n"], ["\n  width: 100px;\n  height: 100px;\n  border: 1px solid ", ";\n  border-radius: 50%;\n  margin: 0 auto 20px;\n  overflow: hidden;\n  position: relative;\n\n  img {\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    -moz-transform: translate(-50%, -50%);\n    -ms-transform: translate(-50%, -50%);\n    -webkit-transform: translate(-50%, -50%);\n    transform: translate(-50%, -50%);\n  }\n"])), colors.placeholder);
var BotId = styled.p(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-weight: bold;\n  color: ", ";\n  font-size: 0.75rem;\n  margin-bottom: 8px;\n"], ["\n  font-weight: bold;\n  color: ", ";\n  font-size: 0.75rem;\n  margin-bottom: 8px;\n"])), colors.midnight);
var SettingIcon = styled.span(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  color: ", ";\n  position: absolute;\n  top: 0;\n  right: 0;\n  margin-top: 1rem;\n  margin-right: 1rem;\n  ", ";\n\n  &::before {\n    font-size: 1.25rem;\n    content: '\\e925';\n    font-family: 'ad_icons';\n    vertical-align: middle;\n  }\n"], ["\n  color: ", ";\n  position: absolute;\n  top: 0;\n  right: 0;\n  margin-top: 1rem;\n  margin-right: 1rem;\n  ", ";\n\n  &::before {\n    font-size: 1.25rem;\n    content: '\\\\e925';\n    font-family: 'ad_icons';\n    vertical-align: middle;\n  }\n"])), colors.placeholder, hoverHighlight);
export var BotContainer = styled.li(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  &.instagram-border {\n    border-radius: 10px;\n    ", "\n    &:before {\n      padding: 2px;\n      border-radius: 10px;\n    }\n  }\n"], ["\n  &.instagram-border {\n    border-radius: 10px;\n    ", "\n    &:before {\n      padding: 2px;\n      border-radius: 10px;\n    }\n  }\n"])), instagramBorderGradient);
export var BotSettingPullDown = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: none;\n  position: absolute;\n  width: 8.75rem;\n  top: 0;\n  right: 0;\n  margin-top: 2rem;\n  z-index: 1;\n  background-color: ", ";\n  border-radius: 5px;\n  box-shadow: ", ";\n\n  li a {\n    display: block;\n    font-size: 0.875rem;\n    padding: 0.625rem 0;\n    color: ", ";\n    border-radius: 5px;\n    ", ";\n  }\n"], ["\n  display: none;\n  position: absolute;\n  width: 8.75rem;\n  top: 0;\n  right: 0;\n  margin-top: 2rem;\n  z-index: 1;\n  background-color: ", ";\n  border-radius: 5px;\n  box-shadow: ", ";\n\n  li a {\n    display: block;\n    font-size: 0.875rem;\n    padding: 0.625rem 0;\n    color: ", ";\n    border-radius: 5px;\n    ", ";\n  }\n"])), colors.white, shadows.small.light, colors.text_pale, hoverMenuBg);
var Bot = function (_a) {
    var chatbotId = _a.chatbotId, botName = _a.botName, numberOfUsers = _a.numberOfUsers, numberOfActiveUsers = _a.numberOfActiveUsers, imgSrc = _a.imgSrc, status = _a.status, pricing = _a.pricing, platform = _a.platform, intl = _a.intl;
    var dispatch = useDispatch();
    var _b = __read(useState(false), 2), showSetting = _b[0], setShowSetting = _b[1];
    var handleSettingClick = function () {
        setShowSetting(!showSetting);
    };
    var handleDeleteChatbot = function () {
        var isDelete = window.confirm('本当に削除しますか？削除したボットは元に戻せません。');
        if (isDelete)
            dispatch(fetchDeleteChatbot(chatbotId));
    };
    var fanpLink = pricing === 'enterprise'
        ? "/dashboard/ad/initial/chatbots/" + chatbotId + "/"
        : "/dashboard/accounts/chatbots/" + chatbotId + "/scenarios";
    var chatbotClickLink = status === 'active'
        ? fanpLink
        : "/dashboard/accounts/chatbots/" + chatbotId + "/pagelist";
    var activeUsersPercentage = numberOfUsers
        ? ((numberOfActiveUsers / numberOfUsers) * 100).toFixed(1)
        : '0.0';
    return (React.createElement(BotContainer, { className: platform === 'instagram' ? 'instagram-border' : '', style: {
            borderColor: platform ? "" + colors.platforms[platform] : 'inherit',
        } },
        React.createElement(Link, { "data-testid": "chatbot", to: chatbotClickLink },
            React.createElement(CircleImg, null,
                React.createElement("img", { src: status === 'active'
                        ? imgSrc || '/static/images/icon_mouse.png'
                        : '/images/not_connect.png', alt: "avatar_image" })),
            React.createElement(BotId, null, "Bot ID: " + chatbotId),
            React.createElement(BotName, { "data-testid": "bot-name" }, status === 'active' ? botName : 'ページ未接続'),
            numberOfUsers !== null && (React.createElement("div", null,
                React.createElement("div", { className: "chatbot-content" },
                    React.createElement("span", { className: "chatbot-content__label" }, intl.formatMessage({ id: "CAKCzU", defaultMessage: "Users\uFF08Active)" })),
                    React.createElement("span", { className: "chatbot-content__value" },
                        numberOfActiveUsers,
                        "\u4EBA (",
                        numberOfUsers,
                        "\u4EBA)")),
                React.createElement("div", { className: "chatbot-content" },
                    React.createElement("span", { className: "chatbot-content__label" }, intl.formatMessage({ id: "KFacRB", defaultMessage: "Ratio" })),
                    React.createElement("span", { className: "chatbot-content__value" },
                        activeUsersPercentage,
                        "%"))))),
        status !== 'active' && (React.createElement(SettingIcon, { className: "icon_setting", onClick: handleSettingClick },
            React.createElement(BotSettingPullDown, { className: showSetting ? 'show' : '' },
                React.createElement("ul", null,
                    React.createElement("li", null,
                        React.createElement("button", { type: "button", onClick: handleDeleteChatbot }, "BOT\u3092\u524A\u9664"))))))));
};
export default injectIntl(Bot);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
