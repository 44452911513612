var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { colors } from '@src/colors';
import { hoverCss } from '@src/lib/mixins';
import { filterObjectsByText } from '@src/lib/helpers';
import { adScenarioTypes } from '@src/lib/app-constants';
import SelectorWrapper from '@src/components/atoms/SelectorWrapper';
import isEmpty from 'lodash/isEmpty';
import { injectIntl } from 'react-intl';
import PostbackConnectorGroup, { ConnectorType, } from './PostbackConnectorGroup';
var Section = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  text-align: left;\n  padding: 8px;\n  > p:first-child {\n    color: ", ";\n  }\n  &.no-more-result {\n    padding-top: 0;\n    padding-left: 0;\n    p {\n      margin-left: 4px;\n      margin-top: 6px;\n    }\n  }\n"], ["\n  text-align: left;\n  padding: 8px;\n  > p:first-child {\n    color: ", ";\n  }\n  &.no-more-result {\n    padding-top: 0;\n    padding-left: 0;\n    p {\n      margin-left: 4px;\n      margin-top: 6px;\n    }\n  }\n"])), colors.default_text);
var SectionText = styled.p(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  padding: 0 !important;\n  font-size: 12px;\n  margin-bottom: 4px;\n  color: ", ";\n  text-align: left !important;\n  ", ";\n  &.adicon_nextblock {\n    transition: 0.3s;\n    &:hover {\n      opacity: 1;\n      cursor: default;\n    }\n  }\n  &.initial {\n    &::before {\n      color: ", ";\n    }\n  }\n  &.form {\n    &::before {\n      color: ", ";\n    }\n  }\n  &.pattern {\n    &::before {\n      color: ", ";\n    }\n  }\n  &::before {\n    font-size: 18px;\n  }\n"], ["\n  padding: 0 !important;\n  font-size: 12px;\n  margin-bottom: 4px;\n  color: ", ";\n  text-align: left !important;\n  ", ";\n  &.adicon_nextblock {\n    transition: 0.3s;\n    &:hover {\n      opacity: 1;\n      cursor: default;\n    }\n  }\n  &.initial {\n    &::before {\n      color: ", ";\n    }\n  }\n  &.form {\n    &::before {\n      color: ", ";\n    }\n  }\n  &.pattern {\n    &::before {\n      color: ", ";\n    }\n  }\n  &::before {\n    font-size: 18px;\n  }\n"])), colors.text_pale, hoverCss, colors.label.green_blue, colors.label.yellow, colors.label.blue);
function makeSectionsObject(scenarios) {
    return Object.values(scenarios).reduce(function (sectionsObj, scenario) {
        var chatType = (scenario || {}).chat_type;
        sectionsObj[chatType] = sectionsObj[chatType] || [];
        sectionsObj[chatType].push(scenario);
        return sectionsObj;
    }, {});
}
var ScenarioPostbackConnector = function (_a) {
    var scenarios = _a.scenarios, _b = _a.filterText, filterText = _b === void 0 ? '' : _b, onPostbackItemClick = _a.onPostbackItemClick, intl = _a.intl;
    var scenarioSections = useMemo(function () {
        var initScenarios = scenarios && makeSectionsObject(scenarios);
        if (initScenarios) {
            Object.values(ConnectorType).forEach(function (sectionKey) {
                var section = initScenarios[sectionKey];
                if (!section)
                    return;
                var filteredOptions = filterObjectsByText(section, 'name', filterText);
                if (!(filteredOptions === null || filteredOptions === void 0 ? void 0 : filteredOptions.length)) {
                    delete initScenarios[sectionKey];
                }
                else {
                    initScenarios[sectionKey] = filteredOptions;
                }
            });
        }
        return initScenarios;
    }, [scenarios, filterText]);
    return (React.createElement(SelectorWrapper, null, !isEmpty(scenarioSections) ? (React.createElement(React.Fragment, null, Object.values(ConnectorType).map(function (sectionKey) {
        var _a;
        if (!((_a = scenarioSections[sectionKey]) === null || _a === void 0 ? void 0 : _a.length))
            return null;
        return (React.createElement(PostbackConnectorGroup, { key: "postback-group-" + sectionKey, title: intl.formatMessage(adScenarioTypes[sectionKey]), postbackActionConnectors: scenarioSections[sectionKey], connectorType: sectionKey, connectorIconClassName: "adicon_filter", filterText: filterText, handlePostbackItemClick: onPostbackItemClick }));
    }))) : (React.createElement(Section, null,
        React.createElement(SectionText, { "data-testid": "no-sceanrios" }, "No results.")))));
};
export default injectIntl(ScenarioPostbackConnector);
var templateObject_1, templateObject_2;
