import "core-js/modules/es.symbol";
import "core-js/modules/es.array.filter";
import "core-js/modules/es.array.find";
import "core-js/modules/es.array.includes";
import "core-js/modules/es.array.map";
import "core-js/modules/es.array.reverse";
import "core-js/modules/es.object.get-own-property-descriptor";
import "core-js/modules/es.object.get-own-property-descriptors";
import "core-js/modules/es.object.keys";
import "core-js/modules/es.string.includes";
import "core-js/modules/es.string.starts-with";
import "core-js/modules/es.string.small";
import "core-js/modules/web.dom-collections.for-each";
import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import React, { useState, useRef, useEffect, Fragment } from 'react';
import inlineStyleButtons from '@src/lib/inlineStyleButtons';
import { colors, shadows } from '@src/colors';
import { Editor, EditorState, RichUtils, getDefaultKeyBinding, KeyBindingUtil, Modifier, convertFromRaw, convertToRaw } from 'draft-js';
import isEqual from 'lodash/isEqual';
import { convertFromSpans, convertToSpans } from '@src/lib/helpers';
import styled from 'styled-components';
import onClickOutside from 'react-onclickoutside';
import { columnFlex, rowFlex } from '@src/lib/mixins';
import { SelectFilterDropdown } from '@src/components/atoms';
import ColorPickerForm from './ColorPickerForm';
var TextAreaWrapper = styled.div.withConfig({
  displayName: "TextAreaWrapper",
  componentId: "sc-msogsp-0"
})(["border-radius:3px;position:relative;padding:5px;animation-duration:4s;", " &:nth-child(1){flex:1;}&:nth-child(2){flex:1;}.DraftEditor-editorContainer{height:80px;overflow-y:auto;background-color:", ";border-radius:3px;font-size:15px;padding:3px;}.text-edit-form-dropdown{ul{height:auto;}}"], columnFlex, colors.grey);
var ButtonColor = styled.input.withConfig({
  displayName: "ButtonColor",
  componentId: "sc-msogsp-1"
})(["width:15px;height:15px;margin:2px;background-color:", ";border:solid 1px rgba(0,0,0,0.05);cursor:pointer;border-radius:3px;font-size:20px;transition:all 0.2s ease-in-out;box-shadow:0 0 0 ", "pt #48a7ee;&:hover{transform:scale(1.1);}"], function (props) {
  return props.color;
}, function (props) {
  return props.active ? '1.5' : '0';
});
var ButtonFontSize = styled.input.withConfig({
  displayName: "ButtonFontSize",
  componentId: "sc-msogsp-2"
})(["box-shadow:", ";background:", ";width:20px;margin:2px;background-color:", ";border:", ";cursor:pointer;border-radius:3px;font-size:12px;transition:all 0.2s ease-in-out;&:hover{transform:scale(1.1);}"], function (props) {
  return props.active ? 'none' : shadows.small.mid;
}, function (props) {
  return props.active ? colors.platforms.line : 0;
}, function (props) {
  return props.active ? 'none' : props.color;
}, function (props) {
  return props.active ? "solid 2px ".concat(props.color) : 0;
});
var FontStyler = styled.div.withConfig({
  displayName: "FontStyler",
  componentId: "sc-msogsp-3"
})(["background:", ";padding:5px;color:", ";font-size:10px;transition:all 0.2s ease-in-out;&:nth-child(1){font-weight:bold;border-top-left-radius:4px;border-bottom-left-radius:4px;}&:nth-child(2){font-style:italic;}&:nth-child(3){text-decoration:underline;}&:nth-child(4){text-decoration:line-through;border-top-right-radius:4px;border-bottom-right-radius:4px;}&:hover{background:", ";color:", ";}"], function (props) {
  return props.active ? colors.background.main_color : 0;
}, function (props) {
  return props.active ? colors.white : colors.text_pale;
}, colors.background.light_purple, colors.main_color);
var ButtonWrapper = styled.div.withConfig({
  displayName: "ButtonWrapper",
  componentId: "sc-msogsp-4"
})(["", " div{&:nth-child(1){flex:1;}&:nth-child(2){flex:1;}}padding-bottom:10px;.button-style{justify-content:space-evenly;margin-bottom:5px;margin-top:5px;max-width:50%;display:grid;grid-template-columns:repeat(4,1fr);grid-gap:1px;padding:1px;box-shadow:", ";border-radius:5px;}.button-color{margin-bottom:5px;margin-top:5px;margin-left:10px;width:12%;padding:1px;box-shadow:", ";border-radius:5px;border:solid 3px white;background-color:", ";}.button-fontsize{margin-bottom:5px;margin-top:5px;margin-left:10px;max-width:30%;display:grid;grid-template-columns:repeat(3,1fr);box-shadow:", ";border-radius:5px;.fontsize-label{position:relative;padding-bottom:10px;margin-bottom:4px;margin-left:-6px;color:", ";> span{&:nth-child(1){font-size:8px;bottom:1px;position:absolute;}&:nth-child(2){font-size:12px;position:absolute;bottom:0px;left:19px;}}}.fontsize-select{grid-column:2 / 4;background:", ";margin-left:5px;border-top-right-radius:5px;border-bottom-right-radius:5px;.select-filter-dropdown-container{height:100%;border:unset;.adicon_arrow_down_after{color:", ";top:7px;right:5px;}option{margin-left:-8px;overflow:hidden;}ul{display:flex;width:unset;margin-right:-37px;top:35px;box-shadow:", ";li{padding:0px 5px;height:24px;font-size:10px;border-bottom:unset;}}}}}"], rowFlex, shadows.small.mid, shadows.small.mid, function (props) {
  return props.currentColor;
}, shadows.small.mid, colors.text_pale, colors.background.light_purple, colors.icon, shadows.small.mid);
var LOCAL_STYLES = 'localStyles';

var TextEditForm = function TextEditForm(_ref) {
  var contents = _ref.contents,
      closeEditTextForm = _ref.closeEditTextForm;

  var keyBindingFunction = function keyBindingFunction(event) {
    // when use Japan keyboard app to type Japan text, it will return null,
    // 'Process', 'Unidentified' it handle if Japan keyboard
    // app is processing
    setLegalChange(!['Process', 'Unidentified'].includes(event.key));
    setKeyPress(event.key);

    if (KeyBindingUtil.hasCommandModifier(event) && event.shiftKey && event.key === 'x') {
      return 'strikethrough';
    }

    return getDefaultKeyBinding(event);
  }; // Get inline styles in localStorage


  var localStyles = JSON.parse(window.localStorage.getItem(LOCAL_STYLES)); // take all color from contents: <Array<LineSpan>>

  var initColors = contents.reduce(function (initColors, span) {
    initColors[span.color] = {
      color: span.color
    };
    return initColors;
  }, {});
  var initContents = convertFromRaw(convertFromSpans(contents));

  var _useState = useState(EditorState.createWithContent(initContents)),
      _useState2 = _slicedToArray(_useState, 2),
      editorState = _useState2[0],
      setEditorState = _useState2[1];

  var _useState3 = useState(_objectSpread({}, inlineStyleButtons.inlineStyles, {}, localStyles, {}, initColors)),
      _useState4 = _slicedToArray(_useState3, 2),
      inlineStyles = _useState4[0],
      setInlineStyles = _useState4[1];

  var _useState5 = useState(false),
      _useState6 = _slicedToArray(_useState5, 2),
      clickOutside = _useState6[0],
      setClickOutside = _useState6[1];

  var _useState7 = useState(''),
      _useState8 = _slicedToArray(_useState7, 2),
      selectedColor = _useState8[0],
      setSelectedColor = _useState8[1];

  var _useState9 = useState(false),
      _useState10 = _slicedToArray(_useState9, 2),
      showColorPickerForm = _useState10[0],
      setShowColorPickerForm = _useState10[1];

  var _useState11 = useState(null),
      _useState12 = _slicedToArray(_useState11, 2),
      buttonColorElement = _useState12[0],
      setButtonColorElement = _useState12[1];

  var _useState13 = useState([]),
      _useState14 = _slicedToArray(_useState13, 2),
      currentStyles = _useState14[0],
      setCurrentStyles = _useState14[1];

  var _useState15 = useState(0),
      _useState16 = _slicedToArray(_useState15, 2),
      lastFocusOfset = _useState16[0],
      setLastFocusOfset = _useState16[1];

  var _useState17 = useState(false),
      _useState18 = _slicedToArray(_useState17, 2),
      legalChange = _useState18[0],
      setLegalChange = _useState18[1];

  var _useState19 = useState([]),
      _useState20 = _slicedToArray(_useState19, 2),
      oldDraftJsBlocks = _useState20[0],
      setOldDraftJsBlocks = _useState20[1];

  var _useState21 = useState(''),
      _useState22 = _slicedToArray(_useState21, 2),
      keyPress = _useState22[0],
      setKeyPress = _useState22[1];

  var focusOffset = function focusOffset() {
    return editorState.getSelection().getFocusOffset();
  };

  var isSafariBrowser = function isSafariBrowser() {
    return navigator.userAgent.indexOf('Safari') >= 0;
  };

  TextEditForm.handleClickOutside = function () {
    return setClickOutside(true);
  };

  useEffect(function () {
    if (clickOutside) {
      var _convertToRaw = convertToRaw(editorState.getCurrentContent()),
          blocks = _convertToRaw.blocks;

      var _contents = convertToSpans(blocks);

      closeEditTextForm(_contents);
    } else {
      var newDraftJsBlocks = convertToRaw(editorState.getCurrentContent()).blocks;

      if (legalChange && (!isSafariBrowser() || keyPress !== 'Enter' || newDraftJsBlocks.length !== oldDraftJsBlocks.length)) {
        setLegalChange(false);
        setLastFocusOfset(focusOffset());
        var newCurrentStyles = editorState.getCurrentInlineStyle().filter(Boolean);
        if (newCurrentStyles.size) setCurrentStyles(newCurrentStyles);
      } else {
        if (needFixInlineStyles()) fixInlineStyles();
        if (!isEqual(newDraftJsBlocks, oldDraftJsBlocks) && newDraftJsBlocks.length === oldDraftJsBlocks.length) fixCurrentStyles();
      }

      if (newDraftJsBlocks[0].text === '') fixInlineStyles();
      setOldDraftJsBlocks(newDraftJsBlocks);
    }

    return setClickOutside(false);
  }, [editorState]); // when Japan keyboard app change style unexpectedly it need return to last
  // styles that stored before

  var needFixInlineStyles = function needFixInlineStyles() {
    return currentStyles.some(function (style) {
      return !editorState.getCurrentInlineStyle().has(style);
    });
  }; // this method check current inline style any style difirently with
  // currentStyles and update


  var fixInlineStyles = function fixInlineStyles() {
    if (!editorState.getSelection().isCollapsed()) return;
    var nextEditorState = editorState;
    editorState.getCurrentInlineStyle().forEach(function (style) {
      if (currentStyles.includes(style)) return;
      nextEditorState = RichUtils.toggleInlineStyle(nextEditorState, style);
    });
    currentStyles.forEach(function (style) {
      if (editorState.getCurrentInlineStyle().has(style)) return;
      nextEditorState = RichUtils.toggleInlineStyle(nextEditorState, style);
    });
    setEditorState(nextEditorState);
  }; // this method will call when Japan keyboard app hit enter (insert Japan text)
  // to input field, it will update styles that stored


  var fixCurrentStyles = function fixCurrentStyles() {
    if (!editorState.getSelection().isCollapsed()) return;
    var selection;
    var nextContentState = editorState.getCurrentContent();
    selection = editorState.getSelection().merge({
      anchorOffset: lastFocusOfset,
      focusOffset: focusOffset()
    });
    editorState.getCurrentInlineStyle().forEach(function (style) {
      if (currentStyles.includes(style)) return;
      nextContentState = Modifier.removeInlineStyle(nextContentState, selection, style);
    });
    currentStyles.forEach(function (style) {
      return nextContentState = Modifier.applyInlineStyle(nextContentState, selection, style);
    });
    selection = editorState.getSelection().merge({
      anchorOffset: focusOffset(),
      focusOffset: focusOffset()
    });
    nextContentState = Modifier.applyInlineStyle(nextContentState, selection, '');
    var nextEditorState = EditorState.push(editorState, nextContentState, 'insert-characters');
    setEditorState(nextEditorState);
  };

  var handleChangeInputType = function handleChangeInputType(typeInput) {
    setEditorState(RichUtils.toggleInlineStyle(editorState, typeInput));
  };

  var handleChangeStyles = function handleChangeStyles(style, styles) {
    var selection = editorState.getSelection();
    var nextContentState = styles.reduce(function (contentState, style) {
      return Modifier.removeInlineStyle(contentState, selection, style);
    }, editorState.getCurrentContent());
    var nextEditorState = EditorState.push(editorState, nextContentState, 'change-inline-style');
    var currentStyle = editorState.getCurrentInlineStyle();

    if (selection.isCollapsed()) {
      var previousStyle = styles.find(function (s) {
        return currentStyle.has(s);
      });

      if (previousStyle) {
        nextEditorState = RichUtils.toggleInlineStyle(editorState, previousStyle);
      }
    }

    if (!currentStyle.has(style)) {
      nextEditorState = RichUtils.toggleInlineStyle(nextEditorState, style);
    }

    setEditorState(nextEditorState);
  };

  var inlineColors = function inlineColors() {
    return Object.keys(inlineStyles).filter(function (item) {
      return item.startsWith('#');
    });
  };

  var inlineFontSizes = function inlineFontSizes() {
    return Object.keys(inlineStyles).filter(function (item) {
      return !item.startsWith('#');
    });
  };

  var handleChangeColor = function handleChangeColor(color) {
    // Fix bug choose color and remove
    setSelectedColor(color);
    handleChangeStyles(color, inlineColors());
  };

  var handleChangeFontSize = function handleChangeFontSize(fontSize) {
    setKeyPress('');
    setLegalChange(true);
    handleChangeStyles(fontSize, inlineFontSizes());
  };

  var toggleInlineStyle = function toggleInlineStyle(e) {
    // it mean that any changed style by click on button change style
    setKeyPress('');
    setLegalChange(true);
    e.preventDefault();
    var value = e.target.id;

    if (!Object.keys(inlineStyles).includes(value)) {
      handleChangeInputType(value);
    } else if (value.startsWith('#')) {
      handleChangeColor(value);
    } else {
      handleChangeFontSize(value);
    }
  };

  var getCurrenrStyle = function getCurrenrStyle() {
    return editorState.getCurrentInlineStyle();
  };

  var InlineStyleButton = function InlineStyleButton(button) {
    var style = button.style,
        value = button.value;
    var inlineStyle = getCurrenrStyle();
    return React.createElement(FontStyler, {
      key: value,
      id: style,
      active: inlineStyle.has(style),
      onMouseDown: toggleInlineStyle
    }, style[0]);
  };

  var ColorButton = function ColorButton(key) {
    var inlineStyle = getCurrenrStyle();
    return React.createElement(ButtonColor, {
      type: "button",
      key: key,
      value: ' ',
      color: key,
      id: key,
      active: inlineStyle.has(key),
      onMouseDown: toggleInlineStyle
    });
  };

  var FontSizeButton = function FontSizeButton(key) {
    var inlineStyle = getCurrenrStyle();
    return React.createElement(ButtonFontSize, {
      type: "button",
      key: key,
      value: key,
      id: key,
      active: inlineStyle.has(key),
      onMouseDown: toggleInlineStyle
    });
  };

  var textInput = useRef(null);

  var focusTextInput = function focusTextInput() {
    return textInput.current.focus();
  };

  useEffect(function () {
    focusTextInput();
  }, []); // This is set inline styles to localstorage when inlineStyles(State) be changed

  useEffect(function () {
    window.localStorage.removeItem(LOCAL_STYLES);
    window.localStorage.setItem(LOCAL_STYLES, JSON.stringify(inlineStyles));
  }, [inlineStyles]); // handle hot key

  var handleKeyCommand = function handleKeyCommand(command) {
    var newState = RichUtils.handleKeyCommand(editorState, command);

    if (!newState && command === 'strikethrough') {
      newState = RichUtils.toggleInlineStyle(editorState, 'STRIKETHROUGH');
    }

    if (newState) {
      setEditorState(newState);
      return 'handled';
    }

    return 'not-handled';
  };

  var handleSelectedColor = function handleSelectedColor(e) {
    if (e && e.hex) {
      return setSelectedColor(e.hex.toUpperCase());
    }

    return setSelectedColor(e.target.value.toUpperCase());
  };

  var addColor = function addColor(e) {
    // it mean that any changed style by click on button add color
    setKeyPress('');
    setLegalChange(true);
    e.preventDefault();
    var newColor = selectedColor.charAt(0) !== '#' ? "#".concat(selectedColor) : selectedColor;
    var hexColorRegex = /^#[0-9a-fA-F]{6}$/; // TODO: do something to tell user know the color is invalid

    if (hexColorRegex.test(newColor)) {
      setInlineStyles(function (prevState) {
        return _objectSpread({}, prevState, _defineProperty({}, newColor, {
          color: newColor
        }));
      });
    }

    focusTextInput();
    handleChangeColor(newColor);
  };

  return React.createElement(React.Fragment, null, React.createElement(TextAreaWrapper, null, showColorPickerForm && React.createElement(ColorPickerForm, {
    colorButtons: inlineColors().reverse().map(ColorButton),
    selectedColor: selectedColor,
    isCollapsed: editorState.getSelection().isCollapsed(),
    handleSelectedColor: handleSelectedColor,
    addColor: addColor,
    handleClickOutside: function handleClickOutside(e) {
      if (buttonColorElement.contains(e.target)) return;
      setShowColorPickerForm(false);
      focusTextInput();
    }
  }), React.createElement(ButtonWrapper, {
    currentColor: inlineColors().find(function (color) {
      return getCurrenrStyle().has(color);
    })
  }, React.createElement("div", {
    className: "button-style"
  }, inlineStyleButtons.wordStyle.map(InlineStyleButton)), React.createElement("div", {
    className: "button-fontsize"
  }, React.createElement("div", {
    className: "fontsize-label"
  }, React.createElement("span", null, "A"), React.createElement("span", null, "A")), React.createElement("div", {
    className: "fontsize-select"
  }, React.createElement(SelectFilterDropdown, {
    className: "text-edit-form-dropdown",
    items: inlineFontSizes().map(function (size) {
      return {
        label: size,
        value: size
      };
    }),
    value: inlineFontSizes().find(function (size) {
      return getCurrenrStyle().has(size);
    }) || 'sm',
    handleOnMouseDown: true,
    onChange: function onChange(_ref2) {
      var value = _ref2.value;
      return handleChangeFontSize(value);
    },
    onToggle: focusTextInput
  }))), React.createElement("div", {
    className: "button-color",
    onClick: function onClick() {
      focusTextInput();
      setShowColorPickerForm(!showColorPickerForm);
    },
    ref: function ref(_ref3) {
      return setButtonColorElement(_ref3);
    }
  })), React.createElement("div", {
    onMouseDown: function onMouseDown() {
      setKeyPress('');
      setLegalChange(true);
    }
  }, React.createElement(Editor, {
    customStyleMap: inlineStyles,
    onChange: setEditorState,
    editorState: editorState,
    handleKeyCommand: handleKeyCommand,
    ref: textInput,
    keyBindingFn: keyBindingFunction
  }))));
};

var clickOutsideConfig = {
  handleClickOutside: function handleClickOutside() {
    return TextEditForm.handleClickOutside;
  }
};
export default onClickOutside(TextEditForm, clickOutsideConfig);