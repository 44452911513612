var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { SettingTypes } from '../types/settings';
import { handlePersistentMenuItem, handleChildPersistentMenuItem, deletePersistentMenuItem, deleteChildPersistentMenuItem, } from '@src/redux/fanp/lib/persistents';
import { Platform } from '@src/typings/enums';
export var initialState = {
    isFetching: false,
    pageList: [],
    showWelcomeBoard: false,
    showMainMenu: false,
    showDefaultMessage: false,
    showDisconnectMessage: false,
    welcomeBoardText: '',
    defaultMessageText: '',
    defaultOptionSelected: '0',
    defaultAction: true,
    activeChatbot: {
        pluginMessage: 'Facebook Messengerで最新の情報をお届けします。',
        pluginTitle: 'messengerマガジン',
        pluginButton: 'クリックして登録',
        page: {
            id: null,
            name: null,
            thumbnail_url: null,
            facebook_page_id: null,
            webchat_app_id: null,
            instagram_page_id: null,
        },
        default_scenario_id: 0,
        platform: Platform.Line,
    },
};
export default (function (state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case SettingTypes.TOGGLE_WELCOME_BOARD:
            return __assign(__assign({}, state), { showWelcomeBoard: !state.showWelcomeBoard });
        case SettingTypes.TOGGLE_MAIN_MENU:
            return __assign(__assign({}, state), { showMainMenu: !state.showMainMenu });
        case SettingTypes.TOGGLE_FETCHING_SETTINGS:
            return __assign(__assign({}, state), { isFetching: !state.isFetching });
        case SettingTypes.TOGGLE_DISCONNECT_MESSAGE:
            return __assign(__assign({}, state), { showDisconnectMessage: !state.showDisconnectMessage });
        case SettingTypes.TOGGLE_DEFAULT_MESSAGE:
            return __assign(__assign({}, state), { showDefaultMessage: !state.showDefaultMessage });
        case SettingTypes.GET_WELCOME_MESSAGE_TEXT:
            return __assign(__assign({}, state), { welcomeBoardText: action.text });
        case SettingTypes.GET_DEFAULT_MSG_TEXT:
            return __assign(__assign({}, state), { activeChatbot: __assign(__assign({}, state.activeChatbot), { default_text: action.text }) });
        case SettingTypes.CHANGE_DEFAULT_OPTION:
            return __assign(__assign({}, state), { defaultOptionSelected: action.optionValue, defaultAction: action.defaultAction });
        case SettingTypes.SET_PAGE_LIST:
            return __assign(__assign({}, state), { pageList: action.pageList });
        case SettingTypes.UPDATE_CHATBOT_STATUS:
            return __assign(__assign({}, state), { activeChatbot: __assign(__assign({}, state.activeChatbot), { status: action.status }) });
        case SettingTypes.HANDLE_CHATBOT_UPDATE:
            return __assign(__assign({}, state), { activeChatbot: __assign(__assign({}, state.activeChatbot), action.chatbot) });
        case SettingTypes.SET_DEFAULT_OPTION_SELECTED:
            return __assign(__assign({}, state), { defaultOptionSelected: action.defaultOptionSelected });
        case SettingTypes.UPDATE_PLUGIN_MESSAGE:
            return __assign(__assign({}, state), { activeChatbot: __assign(__assign({}, state.activeChatbot), { pluginMessage: action.message }) });
        case SettingTypes.UPDATE_PLUGIN_TITLE:
            return __assign(__assign({}, state), { activeChatbot: __assign(__assign({}, state.activeChatbot), { pluginTitle: action.title }) });
        case SettingTypes.UPDATE_PLUGIN_BUTTON:
            return __assign(__assign({}, state), { activeChatbot: __assign(__assign({}, state.activeChatbot), { pluginButton: action.button }) });
        case SettingTypes.AD_HANDLE_NEW_PERSISTENT_MENU_ITEM: {
            if (action.parentPersistentMenuIterator >= 0)
                return handleChildPersistentMenuItem(state, action);
            return handlePersistentMenuItem(state, action);
        }
        case SettingTypes.AD_DELETE_PERSISTENT_MENU_ITEM:
            if (action.parentPersistentMenuIterator >= 0)
                return deleteChildPersistentMenuItem(state, action);
            return deletePersistentMenuItem(state, action);
        default:
            return state;
    }
});
