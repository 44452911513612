import "core-js/modules/es.array.concat";

var collection = function collection(chatbotId) {
  return "/chatbots/".concat(chatbotId, "/chat_links");
};

var member = function member(_ref) {
  var chatbotId = _ref.chatbotId,
      chatlinkId = _ref.chatlinkId;
  return "".concat(collection(chatbotId), "/").concat(chatlinkId);
};

export default {
  collection: collection,
  member: member
};