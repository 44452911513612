var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState } from 'react';
import styled from 'styled-components';
import { injectIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { colors } from '@src/colors';
import * as pathLinks from '@src/routes/Fanp/pathLinks';
import DefaultRichMenuPreview from '@src/components/molecules/DefaultRichMenuPreview';
import DefaultRichMenuItem from '@src/components/molecules/DefaultRichMenuItem';
import { Button, Breadcrumbs, Icon } from '@zeals-co-ltd/washi-components';
import sortBy from 'lodash/sortBy';
var ListDefaultRichMenusContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  grid-template-areas:\n    'header'\n    'body';\n  height: calc(100vh - 60px);\n  padding: 30px 40px 0 40px;\n\n  button .MuiSvgIcon-root {\n    padding-right: 3px;\n  }\n"], ["\n  grid-template-areas:\n    'header'\n    'body';\n  height: calc(100vh - 60px);\n  padding: 30px 40px 0 40px;\n\n  button .MuiSvgIcon-root {\n    padding-right: 3px;\n  }\n"])));
var HeaderWrapper = styled.div.attrs({ className: 'header' })(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  grid-area: header;\n  position: relative;\n  padding: 8px 15px;\n\n  > nav.MuiTypography-root {\n    font-size: 17px;\n    font-style: normal;\n    font-weight: 400;\n    line-height: 23px;\n\n    .MuiBreadcrumbs-li > a,\n    .MuiBreadcrumbs-separator {\n      color: ", ";\n      text-decoration: unset;\n    }\n\n    .MuiBreadcrumbs-li:last-of-type > p {\n      color: ", ";\n    }\n  }\n\n  .MuiBreadcrumbs-separator .MuiSvgIcon-root {\n    margin-top: 6px;\n    padding-bottom: 3px;\n  }\n\n  > p {\n    color: ", ";\n    text-transform: uppercase;\n    padding-top: 8px;\n    padding-left: 92px;\n    font-size: 10px;\n    font-style: normal;\n    font-weight: 500;\n    line-height: 14px;\n    letter-spacing: 1.5px;\n  }\n\n  .create-form-button {\n    right: 0px;\n    bottom: 5px;\n    position: absolute;\n  }\n"], ["\n  grid-area: header;\n  position: relative;\n  padding: 8px 15px;\n\n  > nav.MuiTypography-root {\n    font-size: 17px;\n    font-style: normal;\n    font-weight: 400;\n    line-height: 23px;\n\n    .MuiBreadcrumbs-li > a,\n    .MuiBreadcrumbs-separator {\n      color: ", ";\n      text-decoration: unset;\n    }\n\n    .MuiBreadcrumbs-li:last-of-type > p {\n      color: ", ";\n    }\n  }\n\n  .MuiBreadcrumbs-separator .MuiSvgIcon-root {\n    margin-top: 6px;\n    padding-bottom: 3px;\n  }\n\n  > p {\n    color: ", ";\n    text-transform: uppercase;\n    padding-top: 8px;\n    padding-left: 92px;\n    font-size: 10px;\n    font-style: normal;\n    font-weight: 500;\n    line-height: 14px;\n    letter-spacing: 1.5px;\n  }\n\n  .create-form-button {\n    right: 0px;\n    bottom: 5px;\n    position: absolute;\n  }\n"])), colors.light_navy, colors.mid_navy, colors.text_navy);
var TitleWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  padding: 20px 0 0 5px;\n\n  .MuiSvgIcon-root {\n    margin-top: 11px;\n    color: ", ";\n    padding-bottom: 3px;\n    margin-right: 4px;\n  }\n\n  .MuiButton-startIcon svg {\n    padding-bottom: 0px;\n    margin-top: 2px;\n    padding-right: 0px;\n    margin-right: 0px;\n  }\n\n  button {\n    text-transform: uppercase;\n    min-width: unset;\n\n    &:hover {\n      background: transparent;\n    }\n  }\n\n  > span {\n    padding-left: 10px;\n    font-size: 24px;\n    line-height: 35px;\n    font-style: normal;\n    font-weight: 500;\n    letter-spacing: 0.15px;\n    text-align: left;\n    padding-bottom: 6px;\n    color: ", ";\n  }\n"], ["\n  display: flex;\n  padding: 20px 0 0 5px;\n\n  .MuiSvgIcon-root {\n    margin-top: 11px;\n    color: ", ";\n    padding-bottom: 3px;\n    margin-right: 4px;\n  }\n\n  .MuiButton-startIcon svg {\n    padding-bottom: 0px;\n    margin-top: 2px;\n    padding-right: 0px;\n    margin-right: 0px;\n  }\n\n  button {\n    text-transform: uppercase;\n    min-width: unset;\n\n    &:hover {\n      background: transparent;\n    }\n  }\n\n  > span {\n    padding-left: 10px;\n    font-size: 24px;\n    line-height: 35px;\n    font-style: normal;\n    font-weight: 500;\n    letter-spacing: 0.15px;\n    text-align: left;\n    padding-bottom: 6px;\n    color: ", ";\n  }\n"])), colors.light_blue, colors.midnight);
var EmptyWrapper = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  padding-top: 80px;\n  display: flex;\n  align-items: center;\n  height: 100%;\n  flex-direction: column;\n  color: ", ";\n\n  .create-desc {\n    padding: 10px 0;\n    margin-bottom: 20px;\n  }\n\n  button {\n    height: 36px;\n    padding: 6px 16px;\n    border-radius: 8px;\n  }\n"], ["\n  padding-top: 80px;\n  display: flex;\n  align-items: center;\n  height: 100%;\n  flex-direction: column;\n  color: ", ";\n\n  .create-desc {\n    padding: 10px 0;\n    margin-bottom: 20px;\n  }\n\n  button {\n    height: 36px;\n    padding: 6px 16px;\n    border-radius: 8px;\n  }\n"])), colors.text_navy);
var ListWrapper = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  margin-top: 20px;\n  overflow-y: auto;\n\n  @media only screen and (max-width: 1440px) {\n    height: 70%;\n  }\n\n  @media only screen and (min-width: 1441px) {\n    height: 80%;\n  }\n\n  .active-form-button {\n    position: absolute;\n    top: 30px;\n    right: 24px;\n    border-radius: 8px;\n    width: unset;\n    isolation: isolate;\n\n    span {\n      padding: 0 5px;\n    }\n  }\n\n  .inactive {\n    margin-top: 30px;\n    top: 48px;\n  }\n\n  .upload_to_line {\n    position: absolute;\n    top: 80px;\n    right: 24px;\n    font-size: 12px;\n    color: ", ";\n    line-height: 19px;\n    letter-spacing: 0.8px;\n\n    svg {\n      color: ", ";\n      margin: 0px 6px 6px 0px;\n    }\n  }\n\n  button {\n    padding: 6px 12px 6px 8px;\n  }\n"], ["\n  margin-top: 20px;\n  overflow-y: auto;\n\n  @media only screen and (max-width: 1440px) {\n    height: 70%;\n  }\n\n  @media only screen and (min-width: 1441px) {\n    height: 80%;\n  }\n\n  .active-form-button {\n    position: absolute;\n    top: 30px;\n    right: 24px;\n    border-radius: 8px;\n    width: unset;\n    isolation: isolate;\n\n    span {\n      padding: 0 5px;\n    }\n  }\n\n  .inactive {\n    margin-top: 30px;\n    top: 48px;\n  }\n\n  .upload_to_line {\n    position: absolute;\n    top: 80px;\n    right: 24px;\n    font-size: 12px;\n    color: ", ";\n    line-height: 19px;\n    letter-spacing: 0.8px;\n\n    svg {\n      color: ", ";\n      margin: 0px 6px 6px 0px;\n    }\n  }\n\n  button {\n    padding: 6px 12px 6px 8px;\n  }\n"])), colors.midnight, colors.status_green);
var PAGE_ITEMS = 20;
var ListDefaultRichMenus = function (_a) {
    var intl = _a.intl, chatbotId = _a.chatbotId, defaultRichMenus = _a.defaultRichMenus, openTemplateModal = _a.openTemplateModal, onClickSetting = _a.onClickSetting, onClickDuplicate = _a.onClickDuplicate, onClickDelete = _a.onClickDelete, showActiveModal = _a.showActiveModal, showInActiveModal = _a.showInActiveModal;
    var browserHistory = useHistory();
    var _b = __read(useState(1), 2), page = _b[0], setPage = _b[1];
    var ActiveDefaultRichMenu = defaultRichMenus.find(function (defaultRichMenu) { return defaultRichMenu.isActive; });
    var showList = function () { return (React.createElement(ListWrapper, { onScroll: function (_a) {
            var target = _a.target;
            var _b = target, scrollTop = _b.scrollTop, scrollHeight = _b.scrollHeight, clientHeight = _b.clientHeight;
            if (scrollTop >= scrollHeight - clientHeight &&
                page * PAGE_ITEMS <= defaultRichMenus.length) {
                setPage(page + 1);
            }
        } },
        ActiveDefaultRichMenu === undefined ? (React.createElement(DefaultRichMenuPreview, null)) : (React.createElement(DefaultRichMenuItem, { defaultRichMenu: ActiveDefaultRichMenu, onClickSetting: function () { return onClickSetting(ActiveDefaultRichMenu); }, onClickDuplicate: function () { return onClickDuplicate(ActiveDefaultRichMenu); }, onClickDelete: function () { return onClickDelete(ActiveDefaultRichMenu); }, dataTestId: "ListDefaultRichMenus_DefaultRichMenuItemActive" },
            React.createElement(Button, { className: "inactive active-form-button", color: "secondary", variant: "contained", size: "small", onClick: function (e) {
                    e.stopPropagation();
                    showInActiveModal(ActiveDefaultRichMenu);
                }, startIcon: React.createElement(Icon, { name: "adicon_close" }), "data-testid": "DefaultRichMenuItem_Active_SetInActiveButton" }, intl.formatMessage({ id: "t1fc3C", defaultMessage: "Set as inactive" })))),
        sortBy(defaultRichMenus.map(function (d) {
            var _a;
            return (__assign(__assign({}, d), { sort: Math.abs((((_a = d.schedule) === null || _a === void 0 ? void 0 : _a.start_time) || 0) - new Date().getTime()) }));
        }), ['sort', 'richMenus[0].label'])
            .filter(function (_a) {
            var isActive = _a.isActive;
            return !isActive;
        })
            .slice(0, page * PAGE_ITEMS)
            .map(function (defaultRichMenu, index) { return (React.createElement(DefaultRichMenuItem, { defaultRichMenu: defaultRichMenu, onClickSetting: function () { return onClickSetting(defaultRichMenu); }, onClickDuplicate: function () { return onClickDuplicate(defaultRichMenu); }, onClickDelete: function () { return onClickDelete(defaultRichMenu); }, dataTestId: "ListDefaultRichMenus_DefaultRichMenuItem_" + index, key: defaultRichMenu.richMenus[0].id },
            React.createElement(Button, { className: "active-form-button", color: "primary", variant: "contained", onClick: function (e) {
                    e.stopPropagation();
                    showActiveModal(defaultRichMenu);
                }, size: "small", startIcon: React.createElement(Icon, { name: "adicon_msg_richmenu", color: "white" }), "data-testid": "DefaultRichMenuItem_" + index + "_SetActiveButton" }, intl.formatMessage({ id: "ZLuR/o", defaultMessage: "Set as active" })),
            React.createElement("div", { className: "upload_to_line" },
                React.createElement(Icon, { name: "adicon_check_filled", color: "success" }),
                intl.formatMessage({ id: "+MRdp3", defaultMessage: "Uploaded to LINE" })))); }))); };
    var showEmpty = function () { return (React.createElement(EmptyWrapper, { "data-testid": "ListDefaultRichMenus-EmptyWrapper" },
        React.createElement(Icon, { name: "adicon_info", fontSize: "large", color: "disabled" }),
        React.createElement("h3", { className: "" }, intl.formatMessage({ id: "1nUuqr", defaultMessage: "No default rich menus" })),
        React.createElement("span", { className: "create-desc" }, intl.formatMessage({ id: "VhAYYz", defaultMessage: "Create one from the green button below" })),
        React.createElement(Button, { className: "create-form-button", color: "success", variant: "contained", onClick: openTemplateModal, startIcon: React.createElement(Icon, { name: "adicon_plus", color: "white" }) }, intl.formatMessage({ id: "CVm3PB", defaultMessage: "Create New" })))); };
    return (React.createElement(ListDefaultRichMenusContainer, null,
        React.createElement(HeaderWrapper, null,
            React.createElement(Breadcrumbs, { breadcrumbItems: [
                    {
                        href: pathLinks.dashboardAdChatbots_chatbotId_Settings({
                            chatbotId: chatbotId,
                        }),
                        text: intl.formatMessage({ id: "R1XcWk", defaultMessage: "Bot Settings" }),
                    },
                    {
                        href: null,
                        text: intl.formatMessage({ id: "XxvLPK", defaultMessage: "Default Rich Menu" }),
                    },
                ], separator: React.createElement(Icon, { name: "adicon_right_chev", color: "disabled" }) }),
            React.createElement(TitleWrapper, null,
                React.createElement(Button, { color: "primary", onClick: function () {
                        return browserHistory.push(pathLinks.dashboardAdChatbots_chatbotId_Settings({
                            chatbotId: chatbotId,
                        }));
                    }, startIcon: React.createElement(Icon, { name: "adicon_left_chev" }) }, intl.formatMessage({ id: "cyR7Kh", defaultMessage: "Back" })),
                React.createElement("span", null, intl.formatMessage({ id: "XxvLPK", defaultMessage: "Default Rich Menu" }))),
            React.createElement("p", null, intl.formatMessage({ id: "TGxdKR", defaultMessage: "Set up rich menus that will display in a user's chat by default" })),
            defaultRichMenus.length ? (React.createElement(Button, { className: "create-form-button", color: "success", variant: "contained", onClick: openTemplateModal, startIcon: React.createElement(Icon, { name: "adicon_plus", color: "white" }) }, intl.formatMessage({ id: "CVm3PB", defaultMessage: "Create New" }))) : null),
        defaultRichMenus.length ? showList() : showEmpty()));
};
export default injectIntl(ListDefaultRichMenus);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
