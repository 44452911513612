var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { transitions } from '@src/lib/animations';
var FullModalOverlay = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: block;\n  position: fixed;\n  top: 0;\n  left: 0;\n  z-index: ", ";\n  width: 100%;\n  height: 100%;\n  background-color: rgba(74, 74, 74, 0.8);\n  opacity: 1;\n  transition: opacity ", " ease-in, z-index 0.6s ease-out;\n"], ["\n  display: block;\n  position: fixed;\n  top: 0;\n  left: 0;\n  z-index: ", ";\n  width: 100%;\n  height: 100%;\n  background-color: rgba(74, 74, 74, 0.8);\n  opacity: 1;\n  transition: opacity ", " ease-in, z-index 0.6s ease-out;\n"])), function (props) { return props.zIndex || 99; }, transitions.fast);
/**
 * This component be used as Overlay component to wrapper Modal component
 * @param required children: ReactNode or Array ReactNode
 * @param optional zIndex
 */
var ModalPortal = /** @class */ (function (_super) {
    __extends(ModalPortal, _super);
    function ModalPortal() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.appRoot = document.getElementById('root');
        _this.el = document.createElement('div');
        return _this;
    }
    ModalPortal.prototype.componentDidMount = function () {
        this.appRoot.appendChild(this.el);
    };
    ModalPortal.prototype.componentWillUnmount = function () {
        this.appRoot.removeChild(this.el);
    };
    ModalPortal.parentIsPortal = function (node) {
        if (!node.parentNode)
            return false;
        if (node.parentNode.id === 'modal-portal')
            return true;
        return ModalPortal.parentIsPortal(node.parentNode);
    };
    ModalPortal.prototype.render = function () {
        return ReactDOM.createPortal(React.createElement("span", { id: "modal-portal" },
            React.createElement(FullModalOverlay, { zIndex: this.props.zIndex, "data-testid": "ModalPortal_FullModalOverlay" }),
            this.props.children), this.el);
    };
    return ModalPortal;
}(Component));
export default ModalPortal;
var templateObject_1;
