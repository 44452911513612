var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React from 'react';
var Select = function (_a) {
    var options = _a.options, value = _a.value, className = _a.className, _b = _a.emptyOptionText, emptyOptionText = _b === void 0 ? '' : _b, rest = __rest(_a, ["options", "value", "className", "emptyOptionText"]);
    return (React.createElement("select", __assign({ value: value || '', className: "Select " + className }, rest),
        React.createElement("option", { value: "" }, emptyOptionText),
        Object.entries(options).map(function (_a, index) {
            var _b = __read(_a, 2), k = _b[0], v = _b[1];
            return (React.createElement("option", { key: name + "-" + index, value: v }, k));
        })));
};
export default Select;
