var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import styled from 'styled-components';
import { colors, shadows } from '@src/colors';
import { transitions } from '@src/lib/animations';
import { injectIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { setSelectedField } from '@src/redux/fanp/actions/fieldValue';
import { Snackbar, TextField } from '@zeals-co-ltd/washi-components';
import useSnackbarNotify from '@src/components/hooks/useSnackbarNotify';
import { assignFieldSelectableOptions } from './common';
var UserFieldContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  .adicon_search {\n    padding-left: 8px;\n  }\n  .header {\n    margin-bottom: 16px;\n    > h3 {\n      margin-right: 16px;\n      line-height: 40px;\n      white-space: nowrap;\n    }\n  }\n"], ["\n  .adicon_search {\n    padding-left: 8px;\n  }\n  .header {\n    margin-bottom: 16px;\n    > h3 {\n      margin-right: 16px;\n      line-height: 40px;\n      white-space: nowrap;\n    }\n  }\n"])));
var UserFieldList = styled.ul(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  border: 1px solid ", ";\n  box-shadow: ", ";\n  border-radius: 5px;\n  padding: 10px;\n  .message-wrapper {\n    &.primary-label {\n      margin: 0 0 8px 0;\n    }\n  }\n  li {\n    cursor: pointer;\n    border-radius: 5px;\n    font-size: 14px;\n    padding: 10px;\n    margin-top: 8px;\n    overflow: auto;\n    transition: ", ";\n    &:hover {\n      background-color: ", ";\n      font-weight: bold;\n    }\n    &.active {\n      background-color: ", ";\n      font-weight: bold;\n    }\n    &.disabled {\n      opacity: 0.6;\n    }\n  }\n  .user-field-search {\n    input {\n      padding: 10px 0px;\n      &::placeholder {\n        color: ", ";\n      }\n    }\n  }\n  .adicon_search:before {\n    font-size: 20px;\n    margin-right: 8px;\n  }\n  .user-field-result {\n    overflow: auto;\n    height: calc(100% - 70px);\n  }\n"], ["\n  border: 1px solid ", ";\n  box-shadow: ", ";\n  border-radius: 5px;\n  padding: 10px;\n  .message-wrapper {\n    &.primary-label {\n      margin: 0 0 8px 0;\n    }\n  }\n  li {\n    cursor: pointer;\n    border-radius: 5px;\n    font-size: 14px;\n    padding: 10px;\n    margin-top: 8px;\n    overflow: auto;\n    transition: ", ";\n    &:hover {\n      background-color: ", ";\n      font-weight: bold;\n    }\n    &.active {\n      background-color: ", ";\n      font-weight: bold;\n    }\n    &.disabled {\n      opacity: 0.6;\n    }\n  }\n  .user-field-search {\n    input {\n      padding: 10px 0px;\n      &::placeholder {\n        color: ", ";\n      }\n    }\n  }\n  .adicon_search:before {\n    font-size: 20px;\n    margin-right: 8px;\n  }\n  .user-field-result {\n    overflow: auto;\n    height: calc(100% - 70px);\n  }\n"])), colors.border.default, shadows.small.light, transitions.normal, colors.background.light_purple, colors.background.active, colors.young_navy);
var UserFields = function (_a) {
    var userFieldList = _a.userFieldList, selectedUserFieldId = _a.selectedUserFieldId, searchTerm = _a.searchTerm, setSearchTerm = _a.setSearchTerm, clearSelectedOptions = _a.clearSelectedOptions, intl = _a.intl, classes = _a.classes;
    var dispatch = useDispatch();
    var _b = useSnackbarNotify(), notify = _b.notify, setNotify = _b.setNotify, clearNotify = _b.clearNotify, open = _b.open, setOpen = _b.setOpen;
    var filteredUserFieldList = searchTerm
        ? userFieldList.filter(function (aq) { return aq.name && aq.name.includes(searchTerm); })
        : userFieldList;
    var onClick = function (field) { return __awaiter(void 0, void 0, void 0, function () {
        var fieldWithSelectableOptions, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, assignFieldSelectableOptions(field)];
                case 1:
                    fieldWithSelectableOptions = _a.sent();
                    clearSelectedOptions();
                    dispatch(setSelectedField(fieldWithSelectableOptions));
                    return [3 /*break*/, 3];
                case 2:
                    error_1 = _a.sent();
                    setOpen(true);
                    setNotify({
                        message: intl.formatMessage({ id: "BUlTsB", defaultMessage: "An error happened!!" }),
                        severity: 'error',
                    });
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    function onChangeHandler(e) {
        setSearchTerm(e.target.value);
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(UserFieldContainer, { "data-testid": "user-field-container", className: classes },
            React.createElement(UserFieldList, null,
                React.createElement("div", { className: "message-wrapper primary-label" }, intl.formatMessage({ id: "dUZ/2a", defaultMessage: "Select a field" })),
                React.createElement("div", null,
                    React.createElement(TextField, { type: "text", className: "user-field-search", InputProps: {
                            startAdornment: React.createElement("i", { className: "adicon_search" }),
                        }, size: "small", placeholder: intl.formatMessage({ id: "VXDQBw", defaultMessage: "Search for a field" }), value: searchTerm || '', 
                        // eslint-disable-next-line react/jsx-no-bind
                        onChange: onChangeHandler })),
                React.createElement("div", { className: "user-field-result" }, filteredUserFieldList.map(function (field) {
                    var selected = selectedUserFieldId === field.id;
                    if (field.name) {
                        return (React.createElement("li", { role: "row", title: field.name, key: field.id, className: "question " + (selected && 'active'), onClick: function () { return onClick(field); } }, field.name));
                    }
                    return null;
                })))),
        React.createElement(Snackbar, { open: open, alertProps: { severity: notify.severity }, onClose: clearNotify },
            React.createElement("span", null, notify.message))));
};
export default injectIntl(UserFields);
var templateObject_1, templateObject_2;
