import "core-js/modules/es.symbol";
import "core-js/modules/es.array.concat";
import "core-js/modules/es.array.filter";
import "core-js/modules/es.array.find";
import "core-js/modules/es.array.slice";
import "core-js/modules/es.function.name";
import "core-js/modules/es.object.get-own-property-descriptor";
import "core-js/modules/es.object.get-own-property-descriptors";
import "core-js/modules/es.object.keys";
import "core-js/modules/es.string.link";
import "core-js/modules/web.dom-collections.for-each";
import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { zoom, select, selectAll, event, scaleOrdinal, schemeCategory10, forceSimulation, forceLink, drag, forceY, forceX, forceCollide, zoomIdentity } from 'd3';
import { openBlockMessages, closeBlockMessages } from './map_messages/scenarioMapMessages';
import { STANDARD_NAME_LENGTH, NODE_DISTANCE_Y, QUICK_LINK_OPACITY, BUTTON_LINK_OPACITY, MAX_TITLE_SIZE, MESSAGE_CONTAINER_OPACITY, BORDER_RADIUS, BASE_COLLIDE_DISTANCE, BASE_BLOCK_DISTANCE, PADDING_FOR_BUTTON_LINKS, DEFAULT_STRENGTH, MESSAGE_CONTAINER_DEFAULT_X, BUTTON_HEIGHT, CHOICE_MARGIN } from './map_messages/messages/constants';
var nodeLocationParams = []; // Used for updating and saving node positions.

function addMessageContainer(element, d, openedNodes, defaultBlockY) {
  var messageBoundry = select(element).node().getBBox();
  var nodeWidth = messageBoundry.width + BORDER_RADIUS;
  select(element).append('rect').attr('class', 'message-container').attr('id', "message-container-".concat(d.id)).attr('x', MESSAGE_CONTAINER_DEFAULT_X).attr('y', defaultBlockY).attr('rx', BORDER_RADIUS).attr('ry', BORDER_RADIUS).attr('width', nodeWidth).attr('height', messageBoundry.height).attr('fill', '#1739A5').attr('fill-opacity', MESSAGE_CONTAINER_OPACITY).attr('stroke', 'black').attr('stroke-opacity', MESSAGE_CONTAINER_OPACITY).on('mousedown', function (event) {
    event.stopPropagation();
  }).lower();
  openedNodes.push({
    id: d.id,
    group: d.group,
    width: nodeWidth
  });
} // Util


var blockClick = function blockClick(blockInformation) {
  var d = blockInformation.d,
      element = blockInformation.element,
      openedNodes = blockInformation.openedNodes;
  var blockMessagesAreOpen = element.classList.contains('open');
  if (d.onlyShowScenarioName) return;

  if (blockMessagesAreOpen) {
    // Do close stuff
    select(element).select('.message-container').remove();
    closeBlockMessages(element);
    select(element).classed('open', false);
  } else {
    // Do open stuff
    var defaultBlockY = d.isConnectedOtherScenario ? 18 : 0;
    select(element).select('.message-container').remove();
    openBlockMessages(d, element, defaultBlockY);
    select(element).classed('open', true);
    addMessageContainer(element, d, openedNodes, defaultBlockY);
  }
};

function closeAllBlocks() {
  selectAll('.nodes').selectAll('.node').classed('open', true).selectAll('.block-rect').dispatch('click');
}

function copyUrlToClipboard() {
  var dummy = document.createElement('input');
  var href = document.location.href;
  document.body.appendChild(dummy);
  dummy.value = "".concat(href);
  dummy.select();
  document.execCommand('copy');
  document.body.removeChild(dummy);
  alert('クリップボードにURLをコピーしました。');
}

function createSmlBtn(_ref) {
  var x = _ref.x,
      y = _ref.y,
      text = _ref.text,
      cb = _ref.cb,
      url = _ref.url;
  // container
  select('svg').append('rect').classed('button', true).attr('width', 100).attr('height', 40).attr('x', x).attr('y', y).attr('rx', 18).attr('ry', 18).on('click', cb.bind(this, url)); // text

  select('svg').append('text').text(text).attr('fill', 'white').attr('x', x + 15).attr('y', y + 24).attr('cursor', 'pointer').on('click', cb.bind(this, url));
} // Public


export default function createScenarioMap(scenarioData, clientView, chatbotIdParam, adScenarioIdParam, scenarioMapUrl, updateNodeLocationParams, nodeLocations, openedNodes) {
  var decodedLocations = nodeLocations && decodeURIComponent(nodeLocations);
  var nodeLocationsArr = decodedLocations && JSON.parse(decodedLocations);

  if (nodeLocationsArr) {
    // updates node location array if params exist
    nodeLocationParams = nodeLocationsArr;
    updateNodeLocationParams(nodeLocationParams);
  } else {
    nodeLocationParams = [];
    updateNodeLocationParams(nodeLocationParams);
  }

  var formattedScenarioData = this.formatScenarioData(scenarioData);
  select('.svg').remove();
  var svg;

  if (clientView) {
    svg = select('.wrapper').append('svg').attr('class', 'svg').attr('id', 'tree').attr('width', '2000').attr('height', '1200');
  } else {
    svg = select('.wrapper').append('svg').attr('class', 'svg').attr('id', 'tree').attr('width', '800').attr('height', '800');
  }

  var svgElement = select('svg').attr('id', 'main-svg');
  var color = scaleOrdinal(schemeCategory10);

  function checkNodeLocation(d) {
    return nodeLocationsArr && nodeLocationsArr.find(function (node) {
      return node.id === d.id;
    });
  }

  var simulation = forceSimulation().force('link', forceLink().id(function (d) {
    return d.id;
  }).distance(NODE_DISTANCE_Y).strength(0)).force('yPosition', forceY(function (d) {
    return checkNodeLocation(d) ? nodeLocationsArr.find(function (node) {
      return node.id === d.id;
    }).y : d.layer * NODE_DISTANCE_Y;
  }).strength(DEFAULT_STRENGTH)).force('xPosition', forceX(function (d) {
    return checkNodeLocation(d) ? nodeLocationsArr.find(function (node) {
      return node.id === d.id;
    }).x : BASE_BLOCK_DISTANCE * d.blockRank;
  }).strength(DEFAULT_STRENGTH)).alpha(2);

  function updateForces() {
    simulation.force('link', forceLink().id(function (d) {
      return d.id;
    }).distance(NODE_DISTANCE_Y).strength(0));
    simulation.force('center', null);
    simulation.force('yPosition', forceY(null).strength(0));
    simulation.force('xPosition', forceX(null).strength(0));
    simulation.force('collision', forceCollide(BASE_COLLIDE_DISTANCE));
    simulation.alpha(0).restart();
  }

  var graph = formattedScenarioData;

  function getOtherPostBackLinkPostition(openNodesSelection, link, type) {
    if (link.buttonId && openNodesSelection && type) {
      var currentNodeForSelect = openNodesSelection.filter(function (d) {
        return d.id === link.source.id;
      });

      if (currentNodeForSelect && link.isButton) {
        var openNode = currentNodeForSelect.select("#button-".concat(link.buttonId, "-").concat(type));
        if (openNode.empty()) return {};
        return {
          x: link.source.x + parseInt(openNode.attr('x')) + PADDING_FOR_BUTTON_LINKS,
          y: link.source.y + parseInt(openNode.attr('y')) + 10
        };
      }

      if (currentNodeForSelect && type === 'image_map') {
        var _openNode = currentNodeForSelect.select("#image-map-".concat(link.buttonId));

        if (_openNode.empty()) return {};
        return {
          x: link.source.x + parseInt(_openNode.attr('x')) + PADDING_FOR_BUTTON_LINKS,
          y: link.source.y + parseInt(_openNode.attr('y'))
        };
      }

      if (currentNodeForSelect && type === 'rich_menu') {
        var _openNode2 = currentNodeForSelect.select("#rich-menu-".concat(link.buttonId));

        if (_openNode2.empty()) return {};
        return {
          x: link.source.x + parseInt(_openNode2.attr('x')) + PADDING_FOR_BUTTON_LINKS,
          y: link.source.y + parseInt(_openNode2.attr('y'))
        };
      }

      if (currentNodeForSelect && type === 'quick') {
        var currentQuickReply = currentNodeForSelect.select("#quick-reply-container-".concat(link.choiceId));
        if (currentQuickReply.empty()) return {};
        var openNodeY = currentQuickReply.attr('y');
        var openNodeX = currentQuickReply.attr('x');
        return {
          x: link.source.x + parseInt(openNodeX) + currentQuickReply.attr('width') / 2,
          y: link.source.y + parseInt(openNodeY)
        };
      }

      if (currentNodeForSelect && type === 'flex') {
        var currentButtonFlex = currentNodeForSelect.select("#flex-postback-container-".concat(link.postBackId));
        if (currentButtonFlex.empty()) return {};
        return {
          x: link.source.x + parseInt(currentButtonFlex.attr('x')) + PADDING_FOR_BUTTON_LINKS,
          y: link.source.y + parseInt(currentButtonFlex.attr('y')) + 5
        };
      }
    }

    return {};
  }

  function updateLinksForPostBacks(updateObject) {
    var link = updateObject.link,
        openNodesSelection = updateObject.openNodesSelection,
        adjustedLinks = updateObject.adjustedLinks; // Checks to see if link is a button, generic or talk-trigger link.

    var _getOtherPostBackLink = getOtherPostBackLinkPostition(openNodesSelection, link, link.type),
        x = _getOtherPostBackLink.x,
        y = _getOtherPostBackLink.y;

    adjustedLinks.push({
      source: _objectSpread({}, link.source, {
        x: x || link.source.x,
        y: y || link.source.y
      }),
      target: link.target,
      type: link.type
    });
  }

  function updateLinkData(originalData, openNodesSelection) {
    var links = originalData.links;
    var openNodes = openNodesSelection.data();

    if (openNodes.length) {
      var newLinks = links.reduce(function (adjustedLinks, link) {
        var currentNodeOpen = openNodes.find(function (n) {
          return n.id === link.source.id;
        });

        if (currentNodeOpen) {
          updateLinksForPostBacks({
            link: link,
            openNodesSelection: openNodesSelection,
            adjustedLinks: adjustedLinks
          });
        } else {
          adjustedLinks.push({
            source: link.source,
            target: link.target,
            type: link.type
          });
        }

        return adjustedLinks;
      }, []);

      if (newLinks.length) {
        return newLinks;
      }
    }

    return originalData.links;
  }

  var scenarioStates = {};
  scenarioStates[graph.nodes[0].ownedByScenarioId] = true;
  scenarioData.connected_other_scenarios.forEach(function (otherScenario) {
    scenarioStates[otherScenario.id] = false;
  });
  var group = svgElement.append('g').attr('class', 'everything').attr('transform', 'translate(200, -500)'); // Below creates and Arrow svg element that is appended to the END of a link.

  select('#main-svg').append('defs').append('marker').attr('id', 'arrowhead').attr('refX', 30).attr('refY', 0).attr('viewBox', '-0 -5 10 10').attr('orient', 'auto').attr('markerWidth', 8).attr('markerHeight', 8).attr('xoverflow', 'visible').append('svg:path').attr('d', 'M 0,-5 L 10 ,0 L 0,5').attr('fill', '#1739A5'); // Below creates and Arrow svg element that is appended to the START of a link.

  select('#main-svg').append('defs').append('marker').attr('id', 'nodeStart').attr('refX', 0).attr('refY', 0).attr('viewBox', '-0 -5 10 10').attr('orient', 'auto').attr('markerWidth', 3).attr('markerHeight', 3).attr('xoverflow', 'visible').append('svg:path').attr('d', 'M 0,-5 L 10 ,0 L 0,5').attr('fill', '#0084FF');
  drawGraph(); // Method drawGraph:
  // Requirement: have object graph contain all links and all nodes for scenario and other connected scenario
  // First, it will close all other connected scenario, only display name of this other connected scenario
  // When user click to open other scenario, base on original graph.links and graph.nodes,
  // it filter and only collect node, link of main scenario and open other connected scenario,
  // and name of close other connected scenario
  // When user click to close, filter is the same logic
  //
  // It will add correct object links and nodes and pass to simulation to draw map again,
  // and can not auto remove or add a node and a link to map
  // more explain: https://github.com/zeals-co-ltd/jupiter/pull/9293/files#r510553522

  function drawGraph() {
    var dataNodes = graph.nodes.filter(function (_node) {
      return scenarioStates[_node.ownedByScenarioId] && _node.showWhenOpenScenario || !scenarioStates[_node.ownedByScenarioId] && !_node.showWhenOpenScenario;
    });
    var node = group.append('g').attr('class', 'nodes').selectAll('g').data(dataNodes).enter().append('g').attr('class', function (d) {
      if (d.onlyShowScenarioName) {
        return "node other-scenario-name-".concat(d.scenarioId);
      }

      if (d.isConnectedOtherScenario) {
        return "node other-scenario-block other-scenario-".concat(d.scenarioId);
      }

      return 'node';
    }).attr('id', function (d) {
      return "other-".concat(d.id);
    }).call(drag().on('start', dragstarted).on('drag', dragged).on('end', dragended)); // blocks

    node.append('rect').attr('class', function (d) {
      return d.isScenario ? 'scenario-rect' : 'block-rect';
    }).attr('cursor', 'pointer').attr('x', function (d) {
      return d.name && d.name.length > STANDARD_NAME_LENGTH ? -60 : -50;
    }).attr('y', function (d) {
      return d.isConnectedOtherScenario ? 8 : -12;
    }).attr('width', function (d) {
      return d.name && d.name.length > STANDARD_NAME_LENGTH ? 120 : 100;
    }).attr('height', 20).attr('fill', function (d) {
      if (d.isScenario) return 'orange';
      return d.parent ? '#1739A5' : color(d.group);
    }).on('click', function (d) {
      if (d.onlyShowScenarioName) {
        openedNodes.push({
          id: d.id,
          group: d.group,
          width: 100
        });
        return;
      }

      if (d.isScenario) {
        scenarioClick(d.ownedByScenarioId);
        ticked();
      } else {
        blockClick({
          d: d,
          element: this.parentNode,
          openedNodes: openedNodes
        });
        ticked();
      }
    }); // blockLabels

    node.append('text').attr('cursor', 'pointer').attr('y', function (d) {
      return d.isConnectedOtherScenario ? 20 : 0;
    }).text(function (d) {
      return "".concat(d.name.slice(0, MAX_TITLE_SIZE));
    }).style('text-anchor', 'middle').on('click', function (d) {
      if (d.onlyShowScenarioName) {
        openedNodes.push({
          id: d.id,
          group: d.group
        });
        return;
      }

      if (d.isScenario) {
        scenarioClick(d.ownedByScenarioId);
        ticked();
      } else {
        blockClick({
          d: d,
          element: this.parentNode,
          openedNodes: openedNodes
        });
        ticked();
      }
    });
    node.append('rect').attr('class', 'other-scenario-rect').attr('cursor', 'pointer').attr('x', function (d) {
      return d.isMainConnectedOtherScenario ? -50 : 0;
    }).attr('y', function (d) {
      return d.isMainConnectedOtherScenario ? -12 : 0;
    }).attr('width', function (d) {
      return d.isMainConnectedOtherScenario ? 100 : 0;
    }).attr('height', function (d) {
      return d.isMainConnectedOtherScenario ? 20 : 0;
    }).attr('fill', 'orange').on('click', function (d) {
      if (d.isMainConnectedOtherScenario) {
        scenarioClick(d.scenarioId);
        ticked();
      }
    });
    node.append('text').attr('cursor', 'pointer').attr('y', 0).text(function (d) {
      return d.isMainConnectedOtherScenario ? "".concat(d.scenarioName.slice(0, MAX_TITLE_SIZE)) : '';
    }).style('text-anchor', 'middle').on('click', function (d) {
      if (d.isMainConnectedOtherScenario) {
        scenarioClick(d.scenarioId);
        ticked();
      }
    });
    node.append('title').text(function (d) {
      return "Layer: ".concat(d.layer + 1);
    });
    simulation.nodes(graph.nodes).on('tick', ticked);
    var openLinks = graph.links.filter(function (_link) {
      return scenarioStates[_link.ownedByScenarioId] && _link.showWhenOpenScenario && scenarioStates[_link.sourceScenarioId] || !scenarioStates[_link.ownedByScenarioId] && !_link.showWhenOpenScenario && scenarioStates[_link.sourceScenarioId];
    });
    var link = group.append('g').attr('class', 'links').attr('id', 'all-links').selectAll('line').data(openLinks).enter().append('line').attr('class', function (d) {
      return d.isConnectedOtherScenarioLink ? "other-link-scenario-".concat(d.sourceScenarioId) : '';
    }).attr('marker-end', 'url(#arrowhead)').attr('marker-start', 'url(#nodeStart)').attr('stroke', function (d) {
      if (d.otherScenarioLink) return 'orange';
      return d.isButton ? color(d.buttonId) : color(d.postBackId);
    }).attr('stroke-opacity', function (d) {
      return d.isButton ? BUTTON_LINK_OPACITY : QUICK_LINK_OPACITY;
    }).style('stroke-dasharray', function (d) {
      return d.isButton ? '10,10' : '3, 3';
    }).attr('stroke-width', function (d) {
      return Math.sqrt(d.strokeWidth);
    });
    simulation.force('link').links(graph.links);

    function ticked() {
      var openNodesSelection = selectAll('g.open');
      link.data(updateLinkData({
        links: openLinks
      }, openNodesSelection));
      link.attr('x1', function (d) {
        return d.source.x;
      }).attr('y1', function (d) {
        return d.type === 'quick' ? d.source.y + BUTTON_HEIGHT + CHOICE_MARGIN : d.source.y;
      }).attr('x2', function (d) {
        return d.target.x;
      }).attr('y2', function (d) {
        return d.target.y;
      });
      node.attr('transform', function (d) {
        return "translate(".concat(d.x, ",").concat(d.y, ")");
      });
    }
  } // user click to close scenario node, will close all block of this scenario


  function closeAllBlockInScenario(scenarioId) {
    scenarioStates[scenarioId] = false;
    select('.nodes').remove();
    select('.links').remove();
    drawGraph();
    selectAll('.nodes').selectAll('.node').classed('open', false).selectAll('.block-rect').dispatch('click');
  } // user click to open scenario node, will open all block of this scenario


  function openAllBlockInScenario(scenarioId) {
    scenarioStates[scenarioId] = true;
    select('.links').remove();
    select('.nodes').remove();
    drawGraph();
    selectAll('.nodes').selectAll('.node').classed('open', false).selectAll('.block-rect').dispatch('click');
  } // handle user click other scenario node


  function scenarioClick(scenarioId) {
    if (scenarioStates[scenarioId]) {
      closeAllBlockInScenario(scenarioId);
    } else {
      openAllBlockInScenario(scenarioId);
    }
  }

  function dragstarted(d) {
    if (!event.active) simulation.alphaTarget(0.3).restart();
    d.fx = d.x;
    d.fy = d.y;
    updateForces();
  }

  function dragged(d) {
    d.fx = event.x;
    d.fy = event.y;
  }

  function dragended() {
    selectAll('.nodes').selectAll('.node').each(function (d) {
      var draggedNodeInArray = nodeLocationParams.find(function (node) {
        return node.id === d.id;
      });
      var nodeIndex = nodeLocationParams.indexOf(draggedNodeInArray);

      if (!draggedNodeInArray) {
        nodeLocationParams.push({
          id: d.id,
          x: Math.round(d.fx || d.x),
          y: Math.round(d.fy || d.y)
        });
      } else {
        nodeLocationParams[nodeIndex].x = Math.round(d.fx || d.x);
        nodeLocationParams[nodeIndex].y = Math.round(d.fy || d.y);
      }

      updateNodeLocationParams(nodeLocationParams);
      if (!event.active) simulation.alphaTarget(0);
      d.fx = null;
      d.fy = null;
    });
  } // Zoom functionality


  function zoomActions() {
    group.attr('transform', event.transform);
  } // function calculateXPositionForCurrentNode(group, index) {
  //   if (index === 0) return 0;
  //   const prevNode = group[index - 1];
  //   const messageWidth = prevNode.width;
  //   return prevNode.x + messageWidth + BASE_COLLIDE_DISTANCE;
  // }
  // NOTE: Usage was commented out, so the fn will be commented out for now
  // function rebuildPositionForNodes(nodes) {
  //   const groupNodes = {};
  //   nodes.forEach((node) => {
  //     groupNodes[node.group] = groupNodes[node.group] || [];
  //     groupNodes[node.group].push(node);
  //   });
  //   Object.keys(groupNodes).forEach(function (key) {
  //     const group = groupNodes[key];
  //     group.forEach(function (node, index) {
  //       node.x = calculateXPositionForCurrentNode(group, index);
  //     });
  //   });
  //   simulation.force(
  //     'xPosition',
  //     forceX((d) => (nodes.find((node) => node.id === d.id) || {}).x).strength(
  //       DEFAULT_STRENGTH
  //     )
  //   );
  //   simulation.alpha(2).restart();
  // }


  function openAllBlocks() {
    closeAllBlocks();
    selectAll('.nodes').selectAll('.node').classed('open', false).selectAll('.block-rect').dispatch('click'); // rebuildPositionForNodes(openedNodes);
  }

  var zoomHandler = zoom().on('zoom', zoomActions).scaleExtent([1 / 4, 10]);
  zoomHandler(svg);
  svg.call(zoomHandler.transform, zoomIdentity.translate(200, -500));
  select('svg').on('dblclick.zoom', null); // openAllButton

  createSmlBtn({
    x: 20,
    y: 10,
    text: 'Open All',
    cb: openAllBlocks
  }); // closeAllButton

  createSmlBtn({
    x: 140,
    y: 10,
    text: 'Close All',
    cb: closeAllBlocks
  }); // copyUrl

  createSmlBtn({
    x: 260,
    y: 10,
    text: 'Copy URL',
    cb: copyUrlToClipboard,
    url: scenarioMapUrl
  });
  openAllBlocks();
}