var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import last from 'lodash/last';
import styled from 'styled-components';
import { DragSource, DropTarget, } from 'react-dnd';
import flow from 'lodash/flow';
import { colors, shadows } from '@src/colors';
import { columnFlex } from '@src/lib/mixins';
import { fanpFetch, makeFetchInit, handleAPIErrors } from '@src/lib/helpers';
import API from '@src/endpoints';
import GroupHeader from './GroupHeader';
import Question from './Question';
var QuestionGroupContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  width: 80%;\n  max-width: 1080px;\n  min-width: 405px;\n  margin: 40px auto 0;\n  background-color: ", ";\n  box-shadow: ", ";\n  border: ", ";\n  border-radius: 10px;\n"], ["\n  ", ";\n  width: 80%;\n  max-width: 1080px;\n  min-width: 405px;\n  margin: 40px auto 0;\n  background-color: ", ";\n  box-shadow: ", ";\n  border: ",
    ";\n  border-radius: 10px;\n"])), columnFlex, colors.custom_form_admin.form_bg, shadows.small.mid, function (props) {
    return props.isNotQuestionLabel ? "1px solid " + colors.error : 'unset';
});
var QuestionSection = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  overflow: auto;\n  max-height: 400px;\n"], ["\n  overflow: auto;\n  max-height: 400px;\n"])));
var TableQuestion = styled.table(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  width: 100%;\n  border-collapse: ", ";\n  padding: 0 10px;\n\n  th {\n    width: 16.67%;\n    color: ", ";\n    font-size: 12px;\n    text-align: left;\n  }\n\n  th:last-child {\n    text-align: right;\n  }\n\n  .pull_right {\n    text-align: right;\n  }\n\n  td,\n  th {\n    padding: 10px 20px;\n  }\n\n  tr:nth-child(odd) {\n    background-color: ", ";\n  }\n\n  tr:nth-child(even),\n  tr:first-child {\n    background-color: ", ";\n  }\n\n  tr:last-child td:last-child {\n    border-bottom-right-radius: 10px;\n  }\n\n  tr:last-child td:first-child {\n    border-bottom-left-radius: 10px;\n  }\n\n  tr.empty-question-group > td {\n    text-align: center;\n    border: 2px dashed ", ";\n    color: ", ";\n  }\n"], ["\n  width: 100%;\n  border-collapse: ", ";\n  padding: 0 10px;\n\n  th {\n    width: 16.67%;\n    color: ", ";\n    font-size: 12px;\n    text-align: left;\n  }\n\n  th:last-child {\n    text-align: right;\n  }\n\n  .pull_right {\n    text-align: right;\n  }\n\n  td,\n  th {\n    padding: 10px 20px;\n  }\n\n  tr:nth-child(odd) {\n    background-color: ", ";\n  }\n\n  tr:nth-child(even),\n  tr:first-child {\n    background-color: ", ";\n  }\n\n  tr:last-child td:last-child {\n    border-bottom-right-radius: 10px;\n  }\n\n  tr:last-child td:first-child {\n    border-bottom-left-radius: 10px;\n  }\n\n  tr.empty-question-group > td {\n    text-align: center;\n    border: 2px dashed ", ";\n    color: ", ";\n  }\n"])), function (props) { return (props.isHaveQuestion ? 'collapse' : 'unset'); }, colors.custom_form_admin.gray, colors.custom_form_admin.row.odd, colors.custom_form_admin.row.even, colors.custom_form_admin.gray, colors.custom_form_admin.gray);
var questionGroupSource = {
    beginDrag: function (props) {
        return { index: props.index };
    },
    endDrag: function (props) {
        var customFormId = props.customFormId, selectedForm = props.selectedForm;
        var url = API.customForms.zealsAdmin.updateGroupRanks(customFormId);
        fanpFetch(url, makeFetchInit('PUT', {
            custom_form_question_groups: selectedForm.custom_form_question_groups,
        })).catch(function (e) { return handleAPIErrors('error', e); });
    },
};
var questionGroupTarget = {
    drop: function (props, monitor, component) {
        var item = monitor.getItem();
        var questionGroup = props.questionGroup;
        var elementDropped = monitor.isOver({ shallow: true });
        var handleDrop = component.getDecoratedComponentInstance().handleDrop;
        var defaultQuestionStr = '例: name';
        var hasQuestions = questionGroup && questionGroup.custom_form_questions.length;
        var hasDefaultQuestionLabel = hasQuestions &&
            last(questionGroup.custom_form_questions).label === defaultQuestionStr;
        if (hasDefaultQuestionLabel) {
            return alert('Last Question needs Label');
        }
        var isDroppingQuestionGroup = elementDropped && monitor.getItemType() === 'QUESTION_GROUP';
        if (isDroppingQuestionGroup) {
            return undefined;
        }
        handleDrop(item);
        return {
            moved: true,
            sortable: true,
        };
    },
    hover: function (props, monitor, component) {
        if (monitor.getItemType() === 'QUESTION')
            return;
        var dragIndex = monitor.getItem().index;
        var hoverIndex = props.index;
        if (dragIndex === hoverIndex) {
            return;
        }
        // eslint-disable-next-line react/no-find-dom-node
        var domNode = ReactDOM.findDOMNode(component);
        // Determine rectangle on screen
        var hoverBoundingRect = domNode.getBoundingClientRect();
        // Get vertical middle
        var hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
        // Determine mouse position
        var clientOffset = monitor.getClientOffset();
        // Get pixels to the top
        var hoverClientY = clientOffset.y - hoverBoundingRect.top;
        // Only perform the move when the mouse has crossed half of the items height
        // When dragging downwards, only move when the cursor is below 50%
        // When dragging upwards, only move when the cursor is above 50%
        // Dragging downwards
        if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
            return;
        }
        // Dragging upwards
        if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
            return;
        }
        // Time to actually perform the action
        if (props.moveQuestionGroup) {
            props.moveQuestionGroup({
                formId: props.customFormId,
                dragIndex: dragIndex,
                hoverIndex: hoverIndex,
            });
        }
        // Note: we're mutating the monitor item here!
        // Generally it's better to avoid mutations,
        // but it's good here for the sake of performance
        // to avoid expensive index searches.
        monitor.getItem().index = hoverIndex;
    },
};
function collect(connect) {
    return { connectDragSource: connect.dragSource() };
}
var QuestionGroup = /** @class */ (function (_super) {
    __extends(QuestionGroup, _super);
    function QuestionGroup() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        // eslint-disable-next-line react/no-unused-class-component-methods
        _this.handleDrop = function () {
            var _a = _this.props, createQuestion = _a.createQuestion, selectedForm = _a.selectedForm, questionGroup = _a.questionGroup;
            createQuestion({
                selectedForm: selectedForm,
                questionGroup: questionGroup,
                formValues: {
                    label: '例: name',
                    description: '例: 名前',
                    placeholder: '例: 田中',
                    data_type: 'text',
                },
            });
        };
        _this.removeQuestion = function (questionId) {
            var _a = _this.props, selectedForm = _a.selectedForm, questionGroup = _a.questionGroup, deleteQuestion = _a.deleteQuestion;
            deleteQuestion({
                selectedForm: selectedForm,
                questionGroup: questionGroup,
                questionId: questionId,
            });
        };
        return _this;
    }
    QuestionGroup.prototype.render = function () {
        var _this = this;
        var _a = this.props, connectDragSource = _a.connectDragSource, connectDropTarget = _a.connectDropTarget, questionGroup = _a.questionGroup, updateQuestionGroup = _a.updateQuestionGroup, selectedForm = _a.selectedForm, selectedScenario = _a.selectedScenario, rest = __rest(_a, ["connectDragSource", "connectDropTarget", "questionGroup", "updateQuestionGroup", "selectedForm", "selectedScenario"]);
        var isHaveQuestion = questionGroup.custom_form_questions &&
            questionGroup.custom_form_questions.length;
        return connectDragSource(connectDropTarget(React.createElement("div", null,
            React.createElement(QuestionGroupContainer, { isNotQuestionLabel: !questionGroup.label },
                React.createElement(GroupHeader, { selectedForm: selectedForm, questionGroup: questionGroup, submitDescription: this.submitDescription, updateQuestionGroup: updateQuestionGroup }),
                React.createElement(QuestionSection, null,
                    React.createElement(TableQuestion, { isHaveQuestion: isHaveQuestion },
                        React.createElement("tbody", null,
                            React.createElement("tr", null,
                                React.createElement("th", null, "ID"),
                                React.createElement("th", null, "Label"),
                                React.createElement("th", null, "Data Type"),
                                React.createElement("th", null, "Validation Type"),
                                React.createElement("th", null, "Validation"),
                                React.createElement("th", null, "Max Answers"),
                                React.createElement("th", null, "\u8A2D\u5B9A")),
                            isHaveQuestion ? (questionGroup.custom_form_questions
                                .sort(function (a, b) { return a.rank - b.rank; }) // sorts by rank
                                .map(function (question, i) { return (
                            // renders based on element that was dropped
                            React.createElement(Question, __assign({ key: "question-" + question.id, question: question, sortable: true, removeQuestion: function () {
                                    return _this.removeQuestion(question.id);
                                }, moveQuestion: _this.moveQuestion, questionGroup: questionGroup, selectedForm: selectedForm, selectedScenario: selectedScenario, isQuestionList: true }, rest, { index: i }))); })) : (React.createElement("tr", { className: "empty-question-group" },
                                React.createElement("td", { colSpan: 6 }, "Drag and drop from build menu"))))))),
            !questionGroup.label && (React.createElement("div", { style: {
                    margin: '4px auto 0',
                    fontSize: '12px',
                    textAlign: 'center',
                    width: '80%',
                }, className: "message-wrapper error" }, "Question Group\u306E\u8A2D\u5B9A\u304B\u3089\u30EC\u30D9\u30EB\u3092\u5165\u529B\u3057\u3066\u304F\u3060\u3055\u3044")))));
    };
    return QuestionGroup;
}(Component));
export default flow(DragSource('QUESTION_GROUP', questionGroupSource, collect), DropTarget(['QUESTION', 'QUESTION_GROUP'], questionGroupTarget, function (connect) { return ({ connectDropTarget: connect.dropTarget() }); }))(QuestionGroup);
var templateObject_1, templateObject_2, templateObject_3;
