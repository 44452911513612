var _a, _b, _c, _d;
import { mediaTypes, messageTypes, K_BYTES } from '@src/lib/app-constants';
export default {
    MAX_TEXT_BUTTON_AMOUNT: 4,
    MAX_CAROUSEL_BUTTON_AMOUNT: 3,
    MAX_CAROUSEL_ITEM: 10,
    MAX_DEFAULT_REPLY_TEXT_LENGTH: 1000,
    MAX_QUICK_REPLY_CHOICE: 13,
    MAX_MEDIA_FILE_SIZE_BYTES: 200 * K_BYTES * K_BYTES,
    MAX_PREVIEW_IMAGE_FILE_SIZE_BYTES: K_BYTES * K_BYTES,
    PREVIEW_IMAGE_TYPES: 'image/jpg,image/jpeg',
    CAN_SEND: (_a = {},
        _a[messageTypes.TEXT] = true,
        _a[messageTypes.FLEX_MESSAGE] = false,
        _a[messageTypes.GENERIC] = true,
        _a[messageTypes.RICH_MENU] = false,
        _a[messageTypes.IMAGE_MAP] = false,
        _a[messageTypes.QUICK_REPLY] = true,
        _a[messageTypes.MEDIA] = true,
        _a[messageTypes.LIST] = false,
        _a[messageTypes.FREE_INPUT] = true,
        _a),
    CAN_PHONE_NUMBER: false,
    CAN_SHOW_MESSAGE_USAGE: false,
    CAN_REQUEST_QUOTA_API: false,
    CAN_EMAIL: false,
    MEDIA_CAN_SEND: (_b = {},
        _b[mediaTypes.AUDIO] = false,
        _b[mediaTypes.VIDEO] = false,
        _b[mediaTypes.IMAGE] = true,
        _b[mediaTypes.FILE] = false,
        _b),
    TALK_CAN_SEND: (_c = {},
        _c[messageTypes.TEXT] = true,
        _c[messageTypes.GENERIC] = false,
        _c[messageTypes.MEDIA] = false,
        _c[messageTypes.QUICK_REPLY] = false,
        _c[messageTypes.BUTTON_TEMPLATE] = false,
        _c.SCENARIO = false,
        _c),
    // https://github.com/zeals-co-ltd/jupiter/issues/11093
    TALK_DISABLE_SENDING_MESSAGES_AFTER_MS: 24 * 60 * 60 * 1000,
    MIN_AMOUNT_OF_MESSAGES: 1,
    MIN_AMOUNT_OF_QUICK_REPLY_CHOICES: 1,
    MAX_LENGTH_INPUT_FIELD: (_d = {},
        _d[messageTypes.TEXT] = { text: 1000 },
        _d[messageTypes.BUTTON_TEMPLATE] = { title: 160 },
        _d[messageTypes.GENERIC] = {
            title: 40,
            subtitle: 60,
            url: 1000,
        },
        _d.postback_action = { label: 20 },
        _d.common = { url: 255 },
        _d),
    BLOCK_MESSAGES_MAX: 5,
    MESSAGE_SECTION_ENABLED: false,
    INITIAL_CONVERSATION_ENABLED: false,
    FORM_CONVERSATION_ENABLED: true,
    PATTERN_CONVERSATION_ENABLED: true,
    SHOT_CONVERSATION_ENABLED: true,
    TRACK_PUSH_V1_ENABLED: true,
    TRACK_PUSH_V2_ENABLED: true,
    USER_DB_ENABLED: true,
    TALKROOM_ENABLED: true,
    FIELD_ATTRIBUTES: true,
    USER_SEGMENT: true,
    USER_LABEL: true,
    CHATLINKS_ENABLED: false,
    SETTINGS_WELCOME_BOARD_ENABLED: false,
    SETTINGS_DEFAULT_MESSAGE_ENABLED: true,
    PRIVACY_POLICY_ENABLED: false,
    AGGREGATION_CODE_ENABLED: false,
};
