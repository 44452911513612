var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React, { memo } from 'react';
import styled from 'styled-components';
import BlockNode from './BlockNode';
var LayerContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin: 30px 10px;\n  padding: 10px 50px;\n  border-width: 1px;\n  border-style: dashed;\n  border-radius: 10px;\n  ", "\n  ", "\n"], ["\n  margin: 30px 10px;\n  padding: 10px 50px;\n  border-width: 1px;\n  border-style: dashed;\n  border-radius: 10px;\n  ", "\n  ", "\n"])), function (props) { return (props.width ? "width: " + props.width + ";" : ''); }, function (props) { return (props.height ? "height: " + props.height + ";" : ''); });
var BlocksWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n"], ["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n"])));
var LayerNode = function (_a) {
    var _b = _a.data, layer = _b.layer, updateBoundaryNode = _b.updateBoundaryNode, _c = _b.style, style = _c === void 0 ? {} : _c;
    return (React.createElement(LayerContainer, { width: style.width, height: style.height, ref: function (ref) {
            if (!ref || !updateBoundaryNode)
                return;
            updateBoundaryNode('layers', layer.id, ref.getBoundingClientRect());
        } },
        "\u30EC\u30A4\u30E4\u30FC",
        layer.rank,
        updateBoundaryNode && (React.createElement(BlocksWrapper, null, layer.blocks.map(function (block) { return (React.createElement(BlockNode, { key: "block-" + block.id, data: { block: block, updateBoundaryNode: updateBoundaryNode } })); })))));
};
export default memo(LayerNode);
var templateObject_1, templateObject_2;
