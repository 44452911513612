import { mediaTypes } from '@src/lib/app-constants';
// Returns any mediaTypes given the current platform
// `limits` comes from @src/lib/platforms/limitations
export function platformMediaTypes(limits) {
    if (!limits)
        return [];
    return Object.keys(mediaTypes)
        .filter(function (mediaKey) { return limits.MEDIA_CAN_SEND[mediaTypes[mediaKey]]; })
        .map(function (mediaKey) { return mediaTypes[mediaKey]; });
}
