var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import { colors } from '@src/colors';
import { transitions } from '@src/lib/animations';
import { hoverDelete } from '@src/lib/mixins';
import { DeleteIcon } from '@src/components/atoms';
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  font-size: 24px;\n  color: ", ";\n  margin: 0;\n\n  .adicon_delete {\n    display: block;\n    position: unset;\n    border: none;\n    top: 0;\n    right: 0;\n    transition: ", ";\n    margin-top: 8px;\n    &::before {\n      vertical-align: baseline;\n    }\n    ", ";\n    &:hover {\n      cursor: pointer;\n      border: none;\n      box-shadow: none;\n    }\n  }\n"], ["\n  display: flex;\n  flex-direction: column;\n  font-size: 24px;\n  color: ", ";\n  margin: 0;\n\n  .adicon_delete {\n    display: block;\n    position: unset;\n    border: none;\n    top: 0;\n    right: 0;\n    transition: ", ";\n    margin-top: 8px;\n    &::before {\n      vertical-align: baseline;\n    }\n    ", ";\n    &:hover {\n      cursor: pointer;\n      border: none;\n      box-shadow: none;\n    }\n  }\n"])), colors.icon, transitions.normal, hoverDelete);
var DeleteButton = function (_a) {
    var onClick = _a.onClick, className = _a.className;
    return (React.createElement(Container, { className: className },
        React.createElement(DeleteIcon, { onClick: onClick })));
};
export default DeleteButton;
var templateObject_1;
