var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import { colors } from '@src/colors';
import { Button, Input } from '@src/components/atoms';
import buttonTypes from '@src/lib/buttonTypes';
import { hoverDelete } from '@src/lib/mixins';
var MainTitleContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  width: 100%;\n  height: 46px;\n  padding-bottom: 8px;\n  border-bottom: 1px solid ", ";\n  align-items: center;\n  .main_title_input {\n    width: 240px;\n    border: none;\n    > input {\n      padding: 5px;\n    }\n  }\n\n  .header-buttons {\n    width: fit-content;\n    display: inline-flex;\n    float: right;\n    align-items: center;\n  }\n"], ["\n  display: flex;\n  width: 100%;\n  height: 46px;\n  padding-bottom: 8px;\n  border-bottom: 1px solid ", ";\n  align-items: center;\n  .main_title_input {\n    width: 240px;\n    border: none;\n    > input {\n      padding: 5px;\n    }\n  }\n\n  .header-buttons {\n    width: fit-content;\n    display: inline-flex;\n    float: right;\n    align-items: center;\n  }\n"])), colors.placeholder);
var HomeBtn = styled.span(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-size: 20px;\n  color: ", ";\n  margin-right: 4px;\n"], ["\n  font-size: 20px;\n  color: ", ";\n  margin-right: 4px;\n"])), colors.icon);
var DeleteBtn = styled(HomeBtn)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  margin-left: 8px;\n  margin-right: 0;\n  line-height: 46px;\n  ", ";\n"], ["\n  margin-left: 8px;\n  margin-right: 0;\n  line-height: 46px;\n  ", ";\n"])), hoverDelete);
var MainTitleContainerRight = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  width: 100%;\n  display: lefts;\n  justify-content: flex-end;\n"], ["\n  width: 100%;\n  display: lefts;\n  justify-content: flex-end;\n"])));
var MainColumnHeader = function (_a) {
    var isConnected = _a.isConnected, messagesLength = _a.messagesLength, rankParam = _a.rankParam, activeBlock = _a.activeBlock, handleEditBlockName = _a.handleEditBlockName, handleDeleteBlock = _a.handleDeleteBlock, handleDuplicateBlockClick = _a.handleDuplicateBlockClick, handleAllForm = _a.handleAllForm, errors = _a.errors, _b = _a.readOnly, readOnly = _b === void 0 ? false : _b, intl = _a.intl;
    if (!activeBlock)
        return null;
    var isFirstBlock = rankParam === 1;
    var blockName = activeBlock.name || '';
    var isForm = window.location.href.split('/').includes('form');
    return (React.createElement(MainTitleContainer, { className: readOnly ? 'readonly' : '' },
        isFirstBlock ? React.createElement(HomeBtn, { className: "adicon_home" }) : null,
        React.createElement(Input, { className: "main_title_input", hasError: blockName.length === 0, value: blockName, onChange: activeBlock && handleEditBlockName(activeBlock.undefined_block), showBorderOnFocus: true, "data-testid": "MainColumnHeader-input" }),
        React.createElement(MainTitleContainerRight, { "data-testid": "main-title-container-right" },
            React.createElement("div", { className: "header-buttons" },
                isFirstBlock && isForm ? (React.createElement(Button, { width: 120, onClick: function () { return handleAllForm(); }, text: intl.formatMessage({ id: "6TlqCf", defaultMessage: "Form List" }), buttonType: buttonTypes.SETTING, margin: "0 5px", dataTest: "main-title-container-right-btn-form-list" })) : null,
                React.createElement(Button, { width: 70, buttonType: !Object.keys(errors).length
                        ? buttonTypes.SETTING
                        : buttonTypes.DISABLED, hasErrors: errors, onClick: function () { return handleDuplicateBlockClick(); }, text: intl.formatMessage({ id: "4fHiNl", defaultMessage: "Duplicate" }), dataTest: "main-title-container-right-btn-duplicate" }),
                (messagesLength && !isFirstBlock && !isConnected) ||
                    activeBlock.undefined_block ? (React.createElement(DeleteBtn, { className: "adicon_delete", onClick: handleDeleteBlock(isFirstBlock), "data-testid": "main-title-container-right-btn-delete" })) : null))));
};
export default MainColumnHeader;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
