var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { injectIntl } from 'react-intl';
var ListTemplateGroupsWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  max-height: 50%;\n  margin-bottom: 120px;\n  padding-left: 40px;\n  margin-bottom: 0px;\n  margin-right: -40px;\n  overflow-y: auto;\n\n  @media only screen and (max-width: 1440px) {\n    height: 55vh;\n  }\n\n  @media only screen and (min-width: 1441px) {\n    height: 70vh;\n  }\n\n  .template-group {\n    padding-top: 35px;\n\n    .title {\n      padding: 8px;\n      margin-bottom: -15px;\n      font-weight: bold;\n    }\n  }\n\n  .template-group:last-of-type {\n    padding-bottom: 120px;\n  }\n"], ["\n  width: 100%;\n  max-height: 50%;\n  margin-bottom: 120px;\n  padding-left: 40px;\n  margin-bottom: 0px;\n  margin-right: -40px;\n  overflow-y: auto;\n\n  @media only screen and (max-width: 1440px) {\n    height: 55vh;\n  }\n\n  @media only screen and (min-width: 1441px) {\n    height: 70vh;\n  }\n\n  .template-group {\n    padding-top: 35px;\n\n    .title {\n      padding: 8px;\n      margin-bottom: -15px;\n      font-weight: bold;\n    }\n  }\n\n  .template-group:last-of-type {\n    padding-bottom: 120px;\n  }\n"])));
var TemplateGroupDetail = function (_a) {
    var chosenTemplateGroup = _a.chosenTemplateGroup, templateGroup = _a.templateGroup, index = _a.index, scrollTop = _a.scrollTop, handleScroll = _a.handleScroll, handleFocus = _a.handleFocus, children = _a.children, intl = _a.intl;
    var _b = __read(useState(null), 2), templateGroupElement = _b[0], setTemplateGroupElement = _b[1];
    var width = templateGroup.width, height = templateGroup.height;
    useEffect(function () { return handleScroll(templateGroupElement, index); }, [scrollTop]);
    useEffect(function () {
        if (chosenTemplateGroup !== index)
            return;
        handleFocus(templateGroupElement);
    }, [chosenTemplateGroup]);
    var isSingleTemplate = Object.keys(templateGroup.templates || {})
        .length === 1;
    return (React.createElement("div", { className: "template-group " + (isSingleTemplate ? 'single-template' : 'multiple-templates'), key: "template-group-" + index, ref: setTemplateGroupElement },
        React.createElement("div", { className: "title" }, (templateGroup.title
            ? intl.formatMessage(templateGroup.title)
            : '') + " " + width + " x " + height),
        children(templateGroup)));
};
var ListTemplateGroups = function (_a) {
    var chosenTemplateGroup = _a.chosenTemplateGroup, templateGroups = _a.templateGroups, children = _a.children, setChosenTemplateGroup = _a.setChosenTemplateGroup, intl = _a.intl;
    var _b = __read(useState(null), 2), listTemplatesElement = _b[0], setListTemplatesElement = _b[1];
    var _c = __read(useState(0), 2), scrollTop = _c[0], setScrollTop = _c[1];
    // This flag controll list template group is hover or not, if it is on hover
    // it will not handle focus template group if chosenTemplateGroup change
    var _d = __read(useState(false), 2), isHover = _d[0], setIsHover = _d[1];
    // There are special case when more than one template group in one line, that
    // mean when check focus, all template group in this line in focus so
    // template group title need to hight light (all of them)
    // Solution is if one of template group in line has chosen, no one else on
    // this line can reselect, if it focus, it only set value on chosenTGQueue,
    // if chosen template group not in this line, it will chosenTGQueue to
    // chosenTemplateGroup
    var _e = __read(useState(0), 2), chosenTGQueue = _e[0], setChosenTGQueue = _e[1];
    var _f = __read(useState(false), 2), isChosenWrong = _f[0], setIsChosenWrong = _f[1];
    useEffect(function () {
        if (!isChosenWrong)
            return;
        setChosenTemplateGroup(chosenTGQueue);
        setIsChosenWrong(false);
    }, [isChosenWrong]);
    // this method target is check if templateGroup is focus or not
    var handleScroll = function (templateGroupElement, index) {
        if (!listTemplatesElement || !templateGroupElement)
            return;
        if (templateGroupElement.offsetTop <
            listTemplatesElement.clientHeight / 2 + scrollTop &&
            templateGroupElement.offsetTop + templateGroupElement.clientHeight >
                listTemplatesElement.clientHeight / 2 + scrollTop)
            setChosenTGQueue(index);
        else if (index === chosenTemplateGroup)
            setIsChosenWrong(true);
    };
    // this method calculate scroll position of listemplate element
    // to show focus template group
    var handleFocus = function (templateGroupElement) {
        if (!listTemplatesElement || !templateGroupElement)
            return;
        if (templateGroupElement.offsetTop <
            listTemplatesElement.clientHeight / 2 + scrollTop &&
            templateGroupElement.offsetTop + templateGroupElement.clientHeight >
                listTemplatesElement.clientHeight / 2 + scrollTop)
            return;
        if (templateGroupElement.clientHeight >
            listTemplatesElement.clientHeight / 2)
            listTemplatesElement.scrollTop = templateGroupElement.offsetTop;
        else
            listTemplatesElement.scrollTop =
                templateGroupElement.offsetTop -
                    (listTemplatesElement.clientHeight -
                        templateGroupElement.clientHeight) /
                        2;
    };
    return (React.createElement(ListTemplateGroupsWrapper, { ref: setListTemplatesElement, onScroll: function (e) { return setScrollTop(e.target['scrollTop']); }, onMouseLeave: function () { return setIsHover(false); }, onMouseEnter: function () { return setIsHover(true); } }, templateGroups.map(function (templateGroup, index) { return (React.createElement(TemplateGroupDetail, { key: "template-" + index, chosenTemplateGroup: chosenTemplateGroup, templateGroup: templateGroup, index: index, scrollTop: scrollTop, handleScroll: handleScroll, handleFocus: isHover ? function () { } : handleFocus, children: children, intl: intl })); })));
};
export default injectIntl(ListTemplateGroups);
var templateObject_1;
