var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import omit from 'lodash/omit';
import { tabTypes } from '@src/lib/app-constants';
import { ModalPortal, AutoScrollModalContainer, Button as ButtonComponent, } from '@src/components/atoms';
import Modal from '@src/components/molecules/Modal';
import modalTypes from '@src/lib/modalTypes';
import { HrLine, centeredFlex } from '@src/lib/mixins';
import { connectQuickReplyChoicesToBlock } from '@src/redux/fanp/actions/quickReply';
import useActionFormValidator from '@src/components/hooks/useActionFormValidator';
import quickTriggerModalSchema from '@src/lib/helpers/form_modal_schema/quickTriggerModalSchema';
import ActionSelector from '@src/components/molecules/ActionSelector';
import { AlertModalContext } from '@src/components/molecules/AlertModal';
import { NextPostback } from '@src/components/molecules';
import { isPostbackConnected } from '@src/lib/adHelpers';
import { colors } from '@src/colors';
import buttonTypes from '@src/lib/buttonTypes';
var QuickRepliesNameContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  justify-content: left;\n  margin-bottom: 15px;\n\n  PostbackConnectorGroup {\n    .adicon_nextblock::before {\n      color: ", ";\n    }\n  }\n"], ["\n  display: flex;\n  justify-content: left;\n  margin-bottom: 15px;\n\n  PostbackConnectorGroup {\n    .adicon_nextblock::before {\n      color: ", ";\n    }\n  }\n"])), colors.dark);
var MultiQuickTriggerContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  .TabbedContent--header-nav {\n    padding: 0px 0 12px 8px;\n  }\n\n  ", "\n"], ["\n  .TabbedContent--header-nav {\n    padding: 0px 0 12px 8px;\n  }\n\n  ",
    "\n"])), function (props) {
    return props.hasConnected
        ? "\n    .ActionSelector {\n      cursor: not-allowed;\n\n      .active {\n        color: #85e3c5 !important;\n      }\n\n      .TabbedContent--nav-item,\n      input, .block {\n        cursor: not-allowed;\n        pointer-events: none;\n      }\n\n      .block, .adicon_search::before,\n      .TabbedContent--nav-item:not(.active),\n      .section-name, .adicon_nextblock::before  {\n        color: " + colors.readonly + " !important;\n      }\n    }\n  "
        : '';
});
var ButtonReleaseAllConnection = styled(ButtonComponent)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  pointer-events: ", ";\n  padding: 8px 22px 8px 16px;\n  font-size: 12px;\n  font-weight: bold;\n\n  &:hover {\n    background-color: ", ";\n  }\n\n  .adicon_delete {\n    top: -1px;\n    margin-right: 10px;\n  }\n"], ["\n  pointer-events: ",
    ";\n  padding: 8px 22px 8px 16px;\n  font-size: 12px;\n  font-weight: bold;\n\n  &:hover {\n    background-color: ", ";\n  }\n\n  .adicon_delete {\n    top: -1px;\n    margin-right: 10px;\n  }\n"])), function (props) {
    return props.buttonType === buttonTypes.OFF_WHITE ? 'auto' : 'none';
}, colors.grey);
var PostbackContainer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  color: ", ";\n  margin: 0 10px;\n  background: ", ";\n  border-radius: 8px;\n  padding: 8px;\n  max-width: 400px;\n  ", "\n\n  .adicon_nextblock {\n    font-size: 10px;\n    line-height: 14px;\n    margin: 0px;\n    text-align: left;\n\n    &::before {\n      color: ", ";\n      padding: 0px;\n      font-size: 12px;\n    }\n  }\n"], ["\n  color: ", ";\n  margin: 0 10px;\n  background: ", ";\n  border-radius: 8px;\n  padding: 8px;\n  max-width: 400px;\n  ", "\n\n  .adicon_nextblock {\n    font-size: 10px;\n    line-height: 14px;\n    margin: 0px;\n    text-align: left;\n\n    &::before {\n      color: ", ";\n      padding: 0px;\n      font-size: 12px;\n    }\n  }\n"])), colors.text_navy, colors.background.off_white_background, centeredFlex, colors.text_navy);
var MultiQuickTriggerModal = function (_a) {
    var intl = _a.intl, platform = _a.platform, talkTrigger = _a.talkTrigger, selectedQuickReplies = _a.selectedQuickReplies, activeBlocks = _a.activeBlocks, chatbotIdParam = _a.chatbotIdParam, adScenarioIdParam = _a.adScenarioIdParam, blockIdParam = _a.blockIdParam, quickReplyId = _a.quickReplyId, rank = _a.rank, chatTypeParam = _a.chatTypeParam, createNewBlock = _a.createNewBlock, onCancel = _a.onCancel, scenarios = _a.scenarios, blocks = _a.blocks, quickReplyChoices = _a.quickReplyChoices;
    var dispatch = useDispatch();
    var handleModal = React.useContext(AlertModalContext).handleModal;
    var selectedChoices = function () {
        var selectedChoiceIds = selectedQuickReplies.map(function (choice) { return choice.id; });
        return quickReplyChoices.filter(function (quickReply) {
            return selectedChoiceIds.includes(quickReply.id);
        });
    };
    var _b = useActionFormValidator({
        intl: intl,
        platform: platform,
        object: {},
        schema: quickTriggerModalSchema,
    }), isValidating = _b.isValidating, initActionSelectorValue = _b.initActionSelectorValue, actionSelectorOnChange = _b.actionSelectorOnChange;
    var ignoreEffects = Object.values(omit(tabTypes, 'SCENARIO', 'BLOCK'));
    var connectElement = function (_a) {
        var _b = _a.blockId, blockId = _b === void 0 ? null : _b, _c = _a.nextScenarioId, nextScenarioId = _c === void 0 ? null : _c, _d = _a.isCancel, isCancel = _d === void 0 ? true : _d;
        var quickReplyIds = selectedQuickReplies.map(function (quickReply) { return quickReply.id; });
        dispatch(connectQuickReplyChoicesToBlock({
            chatbotIdParam: chatbotIdParam,
            adScenarioIdParam: adScenarioIdParam,
            blockId: blockIdParam,
            quickReplyId: quickReplyId,
            nextBlockId: blockId,
            nextScenarioId: nextScenarioId,
            quickReplyIds: quickReplyIds,
            rank: rank,
            deselectQuickReplyChoice: isCancel,
        }));
        if (isCancel)
            onCancel();
    };
    var connectElementsToNewBlock = function (blockName) { return __awaiter(void 0, void 0, void 0, function () {
        var _a, firstQuickId, otherQuickIds, newBlock, e_1;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _a = __read(selectedQuickReplies.map(function (quickReply) { return quickReply.id; })), firstQuickId = _a[0], otherQuickIds = _a.slice(1);
                    if (!firstQuickId)
                        return [2 /*return*/];
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 4, , 5]);
                    return [4 /*yield*/, createNewBlock({
                            chatTypeParam: chatTypeParam,
                            chatbotId: chatbotIdParam,
                            adScenarioId: adScenarioIdParam,
                            blockId: blockIdParam,
                            quickReplyChoiceId: firstQuickId,
                            label: 'label name',
                            name: blockName,
                            layer_rank: rank,
                            ignoreFetchingData: true,
                        })];
                case 2:
                    newBlock = _b.sent();
                    return [4 /*yield*/, dispatch(connectQuickReplyChoicesToBlock({
                            chatbotIdParam: chatbotIdParam,
                            adScenarioIdParam: adScenarioIdParam,
                            blockId: blockIdParam,
                            quickReplyId: quickReplyId,
                            nextBlockId: newBlock.id,
                            nextScenarioId: null,
                            quickReplyIds: otherQuickIds,
                            rank: rank,
                            deselectQuickReplyChoice: true,
                        }))];
                case 3:
                    _b.sent();
                    return [3 /*break*/, 5];
                case 4:
                    e_1 = _b.sent();
                    handleModal(e_1.message);
                    return [3 /*break*/, 5];
                case 5:
                    onCancel();
                    return [2 /*return*/];
            }
        });
    }); };
    var isExistPostbackAction = function () {
        var postback_action;
        var result = false;
        if (!selectedQuickReplies)
            return false;
        selectedChoices()
            .filter(function (quickReply) { return quickReply.postback_action; })
            .forEach(function (quickReplyChoice) {
            // eslint-disable-next-line
            postback_action = quickReplyChoice.postback_action;
            if (postback_action.block_id || postback_action.scenario_id)
                result = true;
        });
        return result;
    };
    var defaultObject = __assign({}, selectedQuickReplies[0]);
    // eslint-disable-next-line dot-notation
    delete defaultObject['postback_action'];
    return (React.createElement(ModalPortal, null,
        React.createElement(AutoScrollModalContainer, null,
            React.createElement(Modal, { "data-testid": "multi-quick-trigger-modal", className: "quick_trigger_modal_modal", type: talkTrigger ? 'TEST' : 'SELECT', modalType: modalTypes.TEST, onCancel: onCancel },
                React.createElement(MultiQuickTriggerContainer, { hasConnected: isExistPostbackAction() },
                    selectedChoices().map(function (quickReplyChoice) { return (React.createElement(QuickRepliesNameContainer, { key: quickReplyChoice.id },
                        React.createElement("div", { className: "quick-reply-choice-name" }, quickReplyChoice.name),
                        isPostbackConnected(quickReplyChoice.postback_action) && (React.createElement(PostbackContainer, null,
                            React.createElement(NextPostback, { connectedObject: quickReplyChoice, scenarios: scenarios, blocks: Object.values(blocks), forModal: false }))))); }),
                    React.createElement(ButtonReleaseAllConnection, { buttonType: isExistPostbackAction()
                            ? buttonTypes.OFF_WHITE
                            : buttonTypes.OFF_WHITE_DISABLED, width: "194px", borderRadius: "8px", text: intl.formatMessage({ id: "x9w7h1", defaultMessage: "Release all connections" }), iconClassName: "adicon_delete", "font-size": "12px", onClick: function () {
                            return connectElement({
                                blockId: null,
                                nextScenarioId: null,
                                isCancel: false,
                            });
                        }, className: "disconnect-button", dataTest: "MultiQuickTrigger_DisconnectButton" }),
                    React.createElement(HrLine, { margin: "16px 0" }),
                    React.createElement(ActionSelector, { initActionSelectorValue: initActionSelectorValue, onChange: actionSelectorOnChange({
                            newBlock: connectElementsToNewBlock,
                            selectBlock: function (blockId) { return connectElement({ blockId: blockId }); },
                            selectScenario: function (nextScenarioId) {
                                return connectElement({ nextScenarioId: nextScenarioId });
                            },
                        }), label: "label name", connectedObject: defaultObject, blocks: activeBlocks, ignoreEffects: ignoreEffects, isValidating: isValidating }))))));
};
export default MultiQuickTriggerModal;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
