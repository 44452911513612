import "core-js/modules/es.symbol";
import "core-js/modules/es.array.concat";
import "core-js/modules/es.array.filter";
import "core-js/modules/es.array.map";
import "core-js/modules/es.array.slice";
import "core-js/modules/es.object.get-own-property-descriptor";
import "core-js/modules/es.object.get-own-property-descriptors";
import "core-js/modules/es.object.keys";
import "core-js/modules/web.dom-collections.for-each";
import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { scenarioLayerBlockStateWithNewScenarioProperties } from '@src/lib/helpers';
export var addGenericButton = function addGenericButton(state, action) {
  var messageId = action.messageId,
      listIterator = action.listIterator,
      newButton = action.newButton,
      scenarioId = action.scenarioId;
  var message = state.messages[messageId];
  var layers = newButton.layers,
      undefinedBlocks = newButton.undefined_blocks;
  var newScenarioProperties = {
    layers: layers,
    undefined_blocks: undefinedBlocks
  };
  var newScenarioLayerBlockState = scenarioLayerBlockStateWithNewScenarioProperties(state, newScenarioProperties, scenarioId);
  return _objectSpread({
    messages: _objectSpread({}, state.messages, _defineProperty({}, messageId, _objectSpread({}, message, {
      generic_template: _objectSpread({}, message.generic_template, {
        generic_items: message.generic_template.generic_items.map(function (item, i) {
          if (i === listIterator) {
            return _objectSpread({}, item, {
              generic_item_buttons: [].concat(_toConsumableArray(item.generic_item_buttons), [newButton])
            });
          }

          return item;
        })
      })
    })))
  }, newScenarioLayerBlockState);
};
export var addTextButton = function addTextButton(state, action) {
  var messageId = action.messageId,
      newButton = action.newButton,
      scenarioId = action.scenarioId;
  var msg = state.messages[messageId];
  var isButtonTemplate = msg.text === null;
  var layers = newButton.layers,
      undefinedBlocks = newButton.undefined_blocks;
  var newScenarioProperties = {
    layers: layers,
    undefined_blocks: undefinedBlocks
  };
  var newScenarioLayerBlockState = scenarioLayerBlockStateWithNewScenarioProperties(state, newScenarioProperties, scenarioId);
  return _objectSpread({
    messages: _objectSpread({}, state.messages, _defineProperty({}, messageId, isButtonTemplate ? _objectSpread({}, msg, {
      button_template: _objectSpread({}, msg.button_template, {
        button_items: [].concat(_toConsumableArray(msg.button_template.button_items), [newButton])
      })
    }) : _objectSpread({}, msg, {
      text: null,
      button_template: _objectSpread({}, msg.button_template, {
        id: null,
        title: msg.text,
        button_items: [newButton]
      })
    })))
  }, newScenarioLayerBlockState);
};
export var addTag = function addTag(state, action) {
  var messageId = action.messageId,
      itemIterator = action.itemIterator,
      newTag = action.newTag;
  var msg = state.messages[messageId];
  var templateItemArr = msg.generic_template.generic_items;
  var templateItem = templateItemArr[itemIterator];
  var tags = templateItem.tags ? templateItem.tags : [];
  return {
    messages: _objectSpread({}, state.messages, _defineProperty({}, messageId, _objectSpread({}, msg, {
      generic_template: _objectSpread({}, msg.generic_template, {
        generic_items: [].concat(_toConsumableArray(templateItemArr.slice(0, itemIterator)), [_objectSpread({}, templateItem, {
          tags: [].concat(_toConsumableArray(tags), [newTag])
        })], _toConsumableArray(templateItemArr.slice(itemIterator + 1)))
      })
    })))
  };
};
export var pushGenericListItem = function pushGenericListItem(state, action) {
  var messages = state.messages;
  var messageId = action.messageId,
      newItem = action.newItem;
  var msg = messages[messageId];
  return {
    messages: _objectSpread({}, messages, _defineProperty({}, messageId, _objectSpread({}, msg, {
      generic_template: _objectSpread({}, msg.generic_template, {
        generic_items: [].concat(_toConsumableArray(msg.generic_template.generic_items), [newItem])
      })
    })))
  };
};
export var addQuickReply = function addQuickReply(state, action) {
  var blockId = action.blockId,
      quickReply = action.quickReply;
  var blocks = state.blocks;
  var targetBlock = state.blocks[blockId];
  return _objectSpread({}, state, {
    blocks: _objectSpread({}, blocks, _defineProperty({}, blockId, _objectSpread({}, targetBlock, {
      quick_reply: quickReply
    })))
  });
};
export var addFreeInput = function addFreeInput(state, action) {
  var blockId = action.blockId,
      talkTrigger = action.talkTrigger;
  var blocks = state.blocks;
  var targetBlock = state.blocks[blockId];
  return _objectSpread({}, state, {
    blocks: _objectSpread({}, blocks, _defineProperty({}, blockId, _objectSpread({}, targetBlock, {
      talk_trigger: talkTrigger
    })))
  });
};
export var addFreeInputFromField = function addFreeInputFromField(state, action) {
  var blockId = action.blockId,
      talkTrigger = action.talkTrigger;
  var blocks = state.blocks;
  var targetBlock = state.blocks[blockId];
  return _objectSpread({}, state, {
    blocks: _objectSpread({}, blocks, _defineProperty({}, blockId, _objectSpread({}, targetBlock, {
      talk_trigger: talkTrigger
    })))
  });
};