import "core-js/modules/es.array.filter";
import "core-js/modules/es.array.includes";
import "core-js/modules/es.array.map";
import "core-js/modules/es.function.name";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.regexp.to-string";
import "core-js/modules/es.string.includes";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { colors } from '@src/colors';
import { transitions } from '@src/lib/animations';
import { Checkbox } from '@zeals-co-ltd/washi-components';
import { Input } from '@src/components/atoms';
var MainContainer = styled.div.withConfig({
  displayName: "MainContainer",
  componentId: "sc-paf7cv-0"
})(["display:flex;.pick-area{flex:3;padding-right:1.5rem;border-right:2px solid ", ";.scenario-info{.item-property{font-size:14px;margin-top:0.5rem;span{margin-right:0.5rem;}}}}.edit-area{margin-left:1.5rem;flex:5;}"], colors.border.default);
var SectionContainer = styled.div.withConfig({
  displayName: "SectionContainer",
  componentId: "sc-paf7cv-1"
})(["margin:1rem 0 0.25rem;.label{font-weight:bold;padding-bottom:0.25rem;}"]);
var ItemWrapper = styled.ul.withConfig({
  displayName: "ItemWrapper",
  componentId: "sc-paf7cv-2"
})(["max-height:300px;margin-top:0.5rem;overflow:auto;"]);
var connectedItemOnLeftColumnCss = css(["background:", ";border:2px solid ", ";color:", ";.adicon_minus01{color:", ";}"], colors.custom_form_admin.hover, colors.custom_form_admin.hover, colors.custom_form_admin.body_pale, colors.custom_form_admin.body_pale);
var connectedItemOnRightColumnCss = css(["background:", ";border:2px solid ", ";color:", ";"], colors.custom_form_admin.gray_white, colors.custom_form_admin.gray_white, colors.custom_form_admin.main_color);
var unconnectedItemCss = css(["border:2px solid ", ";background:", ";color:", ";"], colors.custom_form_admin.hover, colors.custom_form_admin.body_pale, colors.custom_form_admin.main_color);

var showItemCss = function showItemCss(_ref) {
  var connected = _ref.connected,
      onRightColumn = _ref.onRightColumn;

  if (connected) {
    return onRightColumn ? connectedItemOnRightColumnCss : connectedItemOnLeftColumnCss;
  }

  return unconnectedItemCss;
};

var ItemContainer = styled.li.withConfig({
  displayName: "ItemContainer",
  componentId: "sc-paf7cv-3"
})(["display:flex;font-family:'Noto Sans JP','Roboto',Arial !important;margin-top:0.5rem;font-size:14px;padding:1rem 1.5rem;border-radius:100px;transition:", ";", ";div{flex:3;}button{flex:1;align-items:right;position:relative;}.update-value-quick-reply{margin:0 0.5rem;}&:hover{button{background:none;}}.flex-1{text-align:right;}.quick-reply-name > div{width:", ";white-space:nowrap;overflow:hidden;text-overflow:ellipsis;}"], transitions.normal, function (props) {
  return showItemCss(props);
}, function (props) {
  return props.onRightColumn ? '155px' : '250px';
});

var Section = function Section(_ref2) {
  var children = _ref2.children;
  return React.createElement(SectionContainer, null, React.createElement("div", {
    className: "body font-small"
  }, children));
};

var ChoiceItem = function ChoiceItem(_ref3) {
  var item = _ref3.item,
      currentChoice = _ref3.currentChoice,
      name = _ref3.name,
      blockName = _ref3.blockName,
      onClick = _ref3.onClick,
      connected = _ref3.connected,
      quickReplyValue = _ref3.quickReplyValue,
      handleRemove = _ref3.handleRemove,
      handleUpdateQuickReplyValue = _ref3.handleUpdateQuickReplyValue,
      handleSwitchClick = _ref3.handleSwitchClick,
      onRightColumn = _ref3.onRightColumn;

  var _useState = useState(quickReplyValue),
      _useState2 = _slicedToArray(_useState, 2),
      itemValue = _useState2[0],
      setItemValue = _useState2[1];

  return React.createElement(ItemContainer, {
    "data-testid": "QuickChoiceSection-ItemContainer",
    onClick: onClick,
    selected: item === currentChoice ? currentChoice : null,
    connected: connected,
    onRightColumn: onRightColumn,
    className: "font-small"
  }, React.createElement("div", {
    className: "flex-3 quick-reply-name"
  }, React.createElement("div", {
    className: "font-bold"
  }, name), React.createElement("div", {
    className: "font-xs"
  }, blockName)), connected && onRightColumn ? React.createElement("div", {
    className: "flex-3 update-value-quick-reply"
  }, React.createElement(Input, {
    placeholder: "value",
    onChange: function onChange(e) {
      setItemValue(e.target.value);
      handleUpdateQuickReplyValue(e.target.value);
    },
    value: itemValue
  })) : null, connected && onRightColumn ? React.createElement("div", {
    className: "flex-3"
  }, React.createElement("div", null, React.createElement("div", {
    className: "label font-xs"
  }, "Quick Reply Switch:"), React.createElement(Checkbox, {
    "data-testid": "QuickChoiceSection-Checkbox",
    color: "primary",
    checked: item.switch,
    size: "small",
    onClick: handleSwitchClick
  }))) : null, React.createElement("div", {
    className: "flex-1"
  }, connected ? React.createElement("button", {
    type: "button",
    onClick: handleRemove,
    tabIndex: "-1",
    "data-testid": "QuickChoiceSection-RemoveButton"
  }, React.createElement("span", {
    className: "adicon_minus01 font-xl"
  })) : React.createElement("button", {
    type: "button"
  }, React.createElement("span", {
    className: "adicon_plus01 font-xl"
  }))));
};

var currentChoiceConnected = function currentChoiceConnected(choice, currentConnectedChoices) {
  if (choice && currentConnectedChoices) {
    return currentConnectedChoices.some(function (connectedChoice) {
      return choice.id === connectedChoice.quick_reply_choice.id;
    });
  }

  return undefined;
};

var choiceIsBeingSearched = function choiceIsBeingSearched(choice, quickReplySearchTerm) {
  return choice.name.includes(quickReplySearchTerm) || choice.id.toString().includes(quickReplySearchTerm);
};

var QuickChoiceSection = function QuickChoiceSection(_ref4) {
  var selectedScenario = _ref4.selectedScenario,
      currentQuestion = _ref4.currentQuestion,
      quickReplies = _ref4.quickReplies,
      currentChoice = _ref4.currentChoice,
      chosenQuickReplyId = _ref4.chosenQuickReplyId,
      quickReplySearchTerm = _ref4.quickReplySearchTerm,
      quickReplySearchTermTouched = _ref4.quickReplySearchTermTouched,
      setCurrentChoice = _ref4.setCurrentChoice,
      _handleSwitchClick = _ref4.handleSwitchClick,
      handleSearchItemClick = _ref4.handleSearchItemClick,
      handleQuickReplyRemove = _ref4.handleQuickReplyRemove,
      _handleUpdateQuickReplyValue = _ref4.handleUpdateQuickReplyValue,
      handleSearchBlur = _ref4.handleSearchBlur,
      handleUpdateQuickReplySearchTerm = _ref4.handleUpdateQuickReplySearchTerm;
  quickReplies = quickReplies || [];
  var filteredQuickReplies = quickReplies.filter(function (choice) {
    return choiceIsBeingSearched(choice, quickReplySearchTerm);
  });
  return React.createElement(MainContainer, {
    "data-testid": "QuickChoiceSection"
  }, React.createElement("div", {
    className: "pick-area"
  }, selectedScenario ? React.createElement("div", {
    className: "scenario-info"
  }, React.createElement(React.Fragment, null, React.createElement("div", {
    className: "item-property"
  }, React.createElement("span", {
    className: "font-bold font-large"
  }, "Scenario: "), selectedScenario.name), React.createElement("div", {
    className: "item-property"
  }, React.createElement("span", {
    className: "font-bold font-large"
  }, "ID: "), selectedScenario.id))) : null, React.createElement(Section, null, React.createElement(React.Fragment, null, React.createElement("div", {
    className: "message-wrapper primary-label",
    style: {
      margin: '0 0 0 8px'
    }
  }, "Quick Reply List"), React.createElement(Input, {
    hasError: !quickReplySearchTerm && quickReplySearchTermTouched,
    placeholder: "Search",
    onChange: handleUpdateQuickReplySearchTerm,
    onBlur: function onBlur() {
      return handleSearchBlur();
    },
    value: quickReplySearchTerm
  }), React.createElement(ItemWrapper, null, filteredQuickReplies.map(function (quickReply) {
    var connected = currentChoiceConnected(quickReply, currentQuestion.choices);
    return React.createElement(ChoiceItem, {
      "data-testid": "QuickChoiceSection-ChoiceItem",
      key: "connected-".concat(quickReply.id),
      name: quickReply.name,
      quickReply: quickReply,
      blockName: quickReply.block_name,
      selected: chosenQuickReplyId === quickReply.id,
      connected: connected,
      onRightColumn: false,
      onClick: function onClick() {
        if (connected) {
          handleQuickReplyRemove(quickReply.choice_id);
        } else {
          handleSearchItemClick(quickReply.id, quickReply);
        }
      }
    });
  }))))), React.createElement("div", {
    className: "edit-area"
  }, currentQuestion && currentQuestion.choices ? React.createElement(Section, null, React.createElement("div", {
    className: "message-wrapper primary-label",
    style: {
      margin: '0 0 0 8px'
    }
  }, "Selected Quick Replies"), React.createElement(ItemWrapper, null, currentQuestion.choices.map(function (item) {
    return item && item.quick_reply_choice ? React.createElement(ChoiceItem, {
      "data-testid": "QuickChoiceSection-ChoiceItem",
      key: "item-".concat(item.id),
      item: item,
      name: item.quick_reply_choice.name,
      blockName: item.quick_reply_choice.block_name,
      currentChoice: currentChoice,
      connected: true,
      onClick: function onClick() {
        return setCurrentChoice(item);
      },
      handleRemove: function handleRemove() {
        return handleQuickReplyRemove(item.id);
      },
      handleUpdateQuickReplyValue: function handleUpdateQuickReplyValue(value) {
        return _handleUpdateQuickReplyValue(item, value);
      },
      handleSwitchClick: function handleSwitchClick() {
        return _handleSwitchClick(item);
      },
      onRightColumn: true,
      quickReplyValue: item.value
    }) : null;
  }))) : null));
};

QuickChoiceSection.propTypes = {
  selectedScenario: PropTypes.shape({}),
  currentQuestion: PropTypes.shape({}),
  quickReplies: PropTypes.shape([]),
  currentChoice: PropTypes.shape({}),
  chosenQuickReplyId: PropTypes.number,
  quickReplySearchTerm: PropTypes.string,
  quickReplySearchTermTouched: PropTypes.bool,
  quickReplyValue: PropTypes.string,
  setCurrentChoice: PropTypes.func,
  handleSwitchClick: PropTypes.func,
  handleSearchItemClick: PropTypes.func,
  handleQuickReplyRemove: PropTypes.func,
  handleUpdateQuickReplyValue: PropTypes.func,
  handleSearchBlur: PropTypes.func,
  handleUpdateQuickReplySearchTerm: PropTypes.func
};
export default QuickChoiceSection;