export var MockApiResponseStatus;
(function (MockApiResponseStatus) {
    MockApiResponseStatus[MockApiResponseStatus["Successful"] = 1] = "Successful";
    MockApiResponseStatus[MockApiResponseStatus["Failed"] = 1000] = "Failed";
    MockApiResponseStatus[MockApiResponseStatus["EmptyResponse"] = 999] = "EmptyResponse";
})(MockApiResponseStatus || (MockApiResponseStatus = {}));
export var MessageType;
(function (MessageType) {
    MessageType["Text"] = "text";
    MessageType["Medium"] = "medium";
    MessageType["Generic"] = "generic";
    MessageType["List"] = "list";
    MessageType["Flex"] = "flex";
    MessageType["ImageMap"] = "image_map";
    MessageType["RichMenu"] = "rich_menu";
})(MessageType || (MessageType = {}));
export var ChatType;
(function (ChatType) {
    ChatType["Initial"] = "initial";
    ChatType["Form"] = "form";
    ChatType["Pattern"] = "pattern";
    ChatType["Step"] = "step";
    ChatType["Shot"] = "shot";
    ChatType["Plain"] = "plain";
})(ChatType || (ChatType = {}));
export var ArticleName;
(function (ArticleName) {
    ArticleName["Title"] = "title";
    ArticleName["Subtitle"] = "subtitle";
    ArticleName["Url"] = "url";
})(ArticleName || (ArticleName = {}));
export var ImageMapAreaActionType;
(function (ImageMapAreaActionType) {
    ImageMapAreaActionType["Url"] = "url";
    ImageMapAreaActionType["Message"] = "message";
})(ImageMapAreaActionType || (ImageMapAreaActionType = {}));
export var MediumType;
(function (MediumType) {
    MediumType["Video"] = "video";
    MediumType["Audio"] = "audio";
    MediumType["Image"] = "image";
    MediumType["File"] = "file";
    MediumType["NoContent"] = "no_content";
})(MediumType || (MediumType = {}));
export var PostbackActionEffect;
(function (PostbackActionEffect) {
    PostbackActionEffect["Share"] = "share";
    PostbackActionEffect["Stock"] = "stock";
    PostbackActionEffect["CxenseLatestArticle"] = "cxense_latest_article";
    PostbackActionEffect["CxenseCategoryChoice"] = "cxense_category_choice";
    PostbackActionEffect["CxenseCategoryArticle"] = "cxense_category_article";
    PostbackActionEffect["CxenseRanking"] = "cxense_ranking";
    PostbackActionEffect["CxenseHotwordArticle"] = "cxense_hotword_article";
    PostbackActionEffect["CxenseHotwordKeyword"] = "cxense_hotword_keyword";
    PostbackActionEffect["CxenseFreewordArticle"] = "cxense_freeword_article";
    PostbackActionEffect["ReadStocks"] = "read_stocks";
    PostbackActionEffect["DeliveryLock"] = "delivery_lock";
    PostbackActionEffect["DeliveryUnlock"] = "delivery_unlock";
    PostbackActionEffect["BackToParagraph"] = "back_to_paragraph";
    PostbackActionEffect["PhoneNumber"] = "phone_number";
    PostbackActionEffect["UserEmail"] = "user_email";
    PostbackActionEffect["UserPhoneNumber"] = "user_phone_number";
    PostbackActionEffect["BlockSelectNo"] = "block_select_no";
    PostbackActionEffect["DeliveryLockSwitch"] = "delivery_lock_switch";
    PostbackActionEffect["DateAnswer"] = "date_answer";
    PostbackActionEffect["DatetimeAnswer"] = "datetime_answer";
    PostbackActionEffect["Block"] = "block";
    PostbackActionEffect["LocationAction"] = "location_action";
})(PostbackActionEffect || (PostbackActionEffect = {}));
export var SendType;
(function (SendType) {
    SendType["All"] = "all";
    SendType["Filtered"] = "filtered";
})(SendType || (SendType = {}));
export var DeliveryType;
(function (DeliveryType) {
    DeliveryType["NoDelivery"] = "no_delivery";
    DeliveryType["ImmediateDelivery"] = "immediate";
    DeliveryType["ScheduledDelivery"] = "scheduled";
})(DeliveryType || (DeliveryType = {}));
export var Gender;
(function (Gender) {
    Gender["Male"] = "male";
    Gender["Female"] = "female";
    Gender["Other"] = "other";
})(Gender || (Gender = {}));
export var Status;
(function (Status) {
    Status["Active"] = "active";
    Status["Deleted"] = "deleted";
    Status["Inactive"] = "inactive";
})(Status || (Status = {}));
export var Platform;
(function (Platform) {
    Platform["Messenger"] = "messenger";
    Platform["Line"] = "line";
    Platform["Webchat"] = "webchat";
    Platform["Instagram"] = "instagram";
})(Platform || (Platform = {}));
export var Type;
(function (Type) {
    Type["Media"] = "media";
    Type["Biz"] = "biz";
})(Type || (Type = {}));
export var Template;
(function (Template) {
    Template["Horizontal"] = "horizontal";
    Template["Vertical"] = "vertical";
})(Template || (Template = {}));
export var ScenarioSendType;
(function (ScenarioSendType) {
    ScenarioSendType["Auto"] = "auto";
    ScenarioSendType["Manual"] = "manual";
})(ScenarioSendType || (ScenarioSendType = {}));
export var ValidationType;
(function (ValidationType) {
    ValidationType["None"] = "none";
    ValidationType["Email"] = "email";
    ValidationType["FirstName"] = "first_name";
    ValidationType["LastName"] = "last_name";
    ValidationType["FirstNameKana"] = "first_name_kana";
    ValidationType["LastNameKana"] = "last_name_kana";
    ValidationType["PhoneNumber"] = "phone_number";
    ValidationType["ZipCode"] = "zip_code";
    ValidationType["DateOfBirth"] = "date_of_birth";
    ValidationType["Prefecture"] = "prefecture";
    ValidationType["Municipality"] = "municipality";
    ValidationType["Address"] = "address";
    ValidationType["PrefectureCode"] = "prefecture_code";
    ValidationType["ConfirmResponse"] = "confirm_response";
    ValidationType["JapaneseName"] = "japanese_name";
    ValidationType["StartData"] = "start_data";
    ValidationType["HiraganaName"] = "hiragana_name";
    ValidationType["TextAddress"] = "text_address";
    ValidationType["Gender"] = "gender";
    ValidationType["GreenUpdateApiDate"] = "green_update_api_date";
    ValidationType["BirthYear"] = "birth_year";
    ValidationType["BirthMonth"] = "birth_month";
    ValidationType["BirthDay"] = "birth_day";
    ValidationType["HalfCenturyYear"] = "half_century_year";
    ValidationType["LocationJpaddress"] = "location_jpaddress";
    ValidationType["Zenkaku"] = "zenkaku";
    ValidationType["ZenkakuKana"] = "zenkaku_kana";
    ValidationType["JoinedYear"] = "joined_year";
    ValidationType["JoinedMonth"] = "joined_month";
    ValidationType["LeftYear"] = "left_year";
    ValidationType["LeftMonth"] = "left_month";
    ValidationType["FullAddress"] = "full_address";
    ValidationType["DateOfBirthStr"] = "date_of_birth_str";
})(ValidationType || (ValidationType = {}));
export var SkipType;
(function (SkipType) {
    SkipType["WhenOne"] = "when_one";
    SkipType["WhenMultiple"] = "when_multiple";
    SkipType["Required"] = "required";
})(SkipType || (SkipType = {}));
export var CustomFormQuestionDataType;
(function (CustomFormQuestionDataType) {
    CustomFormQuestionDataType["Text"] = "text";
    CustomFormQuestionDataType["Integer"] = "integer";
    CustomFormQuestionDataType["Date"] = "date";
    CustomFormQuestionDataType["Time"] = "time";
    CustomFormQuestionDataType["PhoneNumber"] = "phone_number";
    CustomFormQuestionDataType["Password"] = "password";
    CustomFormQuestionDataType["Hidden"] = "hidden";
    CustomFormQuestionDataType["Email"] = "email";
})(CustomFormQuestionDataType || (CustomFormQuestionDataType = {}));
export var CustomFormQuestionDisplayed;
(function (CustomFormQuestionDisplayed) {
    CustomFormQuestionDisplayed["Indicate"] = "indicate";
    CustomFormQuestionDisplayed["HiddenPostNot"] = "hidden_post_not";
    CustomFormQuestionDisplayed["HiddenPost"] = "hidden_post";
})(CustomFormQuestionDisplayed || (CustomFormQuestionDisplayed = {}));
export var NeedsCallApi;
(function (NeedsCallApi) {
    NeedsCallApi["DoNothing"] = "do_nothing";
    NeedsCallApi["CallApi"] = "call_api";
    NeedsCallApi["InsertCvTask"] = "insert_cv_task";
    NeedsCallApi["CallDxAPI"] = "call_dx_api";
})(NeedsCallApi || (NeedsCallApi = {}));
export var LocalStorageItemKey;
(function (LocalStorageItemKey) {
    LocalStorageItemKey["LastPathname"] = "lastPathname";
    LocalStorageItemKey["Language"] = "language";
    LocalStorageItemKey["JWTToken"] = "jwtToken";
    LocalStorageItemKey["DebugMenu"] = "show-zeals-debug-menu";
})(LocalStorageItemKey || (LocalStorageItemKey = {}));
export var SelectedModal;
(function (SelectedModal) {
    SelectedModal[SelectedModal["CreateForm"] = 0] = "CreateForm";
    SelectedModal[SelectedModal["UpdateForm"] = 1] = "UpdateForm";
    SelectedModal[SelectedModal["DeleteForm"] = 2] = "DeleteForm";
    SelectedModal[SelectedModal["DuplicateForm"] = 3] = "DuplicateForm";
})(SelectedModal || (SelectedModal = {}));
export var FormAutomationMode;
(function (FormAutomationMode) {
    FormAutomationMode["Normal"] = "normal";
    FormAutomationMode["Date"] = "date";
})(FormAutomationMode || (FormAutomationMode = {}));
export var gRPCStatusCodes;
(function (gRPCStatusCodes) {
    // https://grpc.github.io/grpc/core/md_doc_statuscodes.html
    gRPCStatusCodes[gRPCStatusCodes["OK"] = 0] = "OK";
    gRPCStatusCodes[gRPCStatusCodes["CANCELLED"] = 1] = "CANCELLED";
    gRPCStatusCodes[gRPCStatusCodes["UNKNOWN"] = 2] = "UNKNOWN";
    gRPCStatusCodes[gRPCStatusCodes["INVALID_ARGUMENT"] = 3] = "INVALID_ARGUMENT";
    gRPCStatusCodes[gRPCStatusCodes["DEADLINE_EXCEEDED"] = 4] = "DEADLINE_EXCEEDED";
    gRPCStatusCodes[gRPCStatusCodes["NOT_FOUND"] = 5] = "NOT_FOUND";
    gRPCStatusCodes[gRPCStatusCodes["ALREADY_EXISTS"] = 6] = "ALREADY_EXISTS";
    gRPCStatusCodes[gRPCStatusCodes["PERMISSION_DENIED"] = 7] = "PERMISSION_DENIED";
    gRPCStatusCodes[gRPCStatusCodes["RESOURCE_EXHAUSTED"] = 8] = "RESOURCE_EXHAUSTED";
    gRPCStatusCodes[gRPCStatusCodes["FAILED_PRECONDITION"] = 9] = "FAILED_PRECONDITION";
    gRPCStatusCodes[gRPCStatusCodes["ABORTED"] = 10] = "ABORTED";
    gRPCStatusCodes[gRPCStatusCodes["OUT_OF_RANGE"] = 11] = "OUT_OF_RANGE";
    gRPCStatusCodes[gRPCStatusCodes["UNIMPLEMENTED"] = 12] = "UNIMPLEMENTED";
    gRPCStatusCodes[gRPCStatusCodes["INTERNAL"] = 13] = "INTERNAL";
    gRPCStatusCodes[gRPCStatusCodes["UNAVAILABLE"] = 14] = "UNAVAILABLE";
    gRPCStatusCodes[gRPCStatusCodes["DATA_LOSS"] = 15] = "DATA_LOSS";
    gRPCStatusCodes[gRPCStatusCodes["UNAUTHENTICATED"] = 16] = "UNAUTHENTICATED";
})(gRPCStatusCodes || (gRPCStatusCodes = {}));
