var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState } from 'react';
import styled from 'styled-components';
import { getConnectedIds } from '@src/lib/adHelpers';
import { colors } from '@src/colors';
import NextScenario from '@src/components/molecules/NextScenario';
import SearchInput from '@src/components/molecules/SearchInput';
import { useSelector } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Icon } from '@zeals-co-ltd/washi-components';
import ScenarioPostbackConnector from './ScenarioPostbackConnector';
var ScenarioSelectorWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  height: 260px;\n  position: relative;\n  margin: 0;\n  padding: 8px;\n  border: 1px solid ", ";\n  background-color: #fff;\n  border-radius: 5px;\n  overflow: auto;\n"], ["\n  width: 100%;\n  height: 260px;\n  position: relative;\n  margin: 0;\n  padding: 8px;\n  border: 1px solid ", ";\n  background-color: #fff;\n  border-radius: 5px;\n  overflow: auto;\n"])), colors.border.default);
// formerly TalkCustomizeConnectSelector
var ScenarioSelector = function (_a) {
    var isRichMenuArea = _a.isRichMenuArea, hasError = _a.hasError, connectedObject = _a.connectedObject, handleScenarioClick = _a.handleScenarioClick, label = _a.label, disconnectScenario = _a.disconnectScenario, 
    // TODO: where are these actually used?
    isFlexButton = _a.isFlexButton, isPersistentItem = _a.isPersistentItem, intl = _a.intl;
    var _b = __read(useState(''), 2), filterText = _b[0], setFilterText = _b[1];
    var scenarios = useSelector(function (state) { return state.adScenario.present.scenarios; });
    var filterTextOnChange = function (e) {
        return setFilterText(e.target.value);
    };
    function handlePostbackItemClick(nextScenarioId, _e) {
        if (!handleScenarioClick)
            return;
        if (label || (!label && isRichMenuArea)) {
            handleScenarioClick(nextScenarioId);
        }
        else {
            alert(intl.formatMessage({ id: "Ryol47", defaultMessage: "Button Label Required" }));
        }
    }
    var _c = getConnectedIds(connectedObject), scenarioId = _c.scenarioId, nextScenarioId = _c.nextScenarioId;
    if (!connectedObject)
        return null;
    return scenarioId || nextScenarioId ? (React.createElement(NextScenario, { connectedObject: connectedObject, scenarios: scenarios, forModal: true, disconnectScenario: disconnectScenario })) : (React.createElement(ScenarioSelectorWrapper, { className: "connect_selector_selector_wrapper" },
        React.createElement(SearchInput, { placeholder: intl.formatMessage({ id: "X2gswq", defaultMessage: "Search Scenario" }), InputProps: {
                startAdornment: (React.createElement(Icon, { name: "adicon_search", color: "primary", fontSize: "small" })),
            }, onChange: filterTextOnChange, error: hasError }),
        hasError && (React.createElement("div", { className: "message-wrapper error" }, intl.formatMessage({ id: "/Do/lE", defaultMessage: "Please configure your Scenario" }))),
        React.createElement(ScenarioPostbackConnector, { filterText: filterText, scenarios: scenarios, onPostbackItemClick: handlePostbackItemClick })));
};
export default injectIntl(ScenarioSelector);
var templateObject_1;
