import React from 'react';
import { NextPostbackText, DeleteIcon } from '@src/components/atoms';
import { getConnectedIds, getAdIconNextBlockType } from '@src/lib/adHelpers';
export function getScenarioText(scenarios, id) {
    if (!scenarios)
        return '';
    if (id) {
        return scenarios[id] && scenarios[id].name;
    }
    return '';
}
// Given some "ConnectedObject", this will display a scenario
// with the proper block icon and the scenario's name
export default function NextScenario(_a) {
    var connectedObject = _a.connectedObject, scenarios = _a.scenarios, _b = _a.forModal, forModal = _b === void 0 ? false : _b, isQuickReplyChoice = _a.isQuickReplyChoice, disconnectScenario = _a.disconnectScenario;
    if (!connectedObject)
        return null;
    var _c = getConnectedIds(connectedObject), scenarioId = _c.scenarioId, nextScenarioId = _c.nextScenarioId;
    var id = nextScenarioId || scenarioId;
    var scenarioName = getScenarioText(scenarios, id);
    return (React.createElement(NextPostbackText, { "data-testid": "next-postback-text", nextBlockType: getAdIconNextBlockType({
            adScenarios: scenarios,
            connectedObject: connectedObject,
        }), forModal: forModal, isQuickReplyChoice: isQuickReplyChoice },
        React.createElement("p", { className: "" + (id ? 'adicon_nextblock' : ''), "data-testid": "NextScenario_ScenarioName" }, scenarioName),
        forModal ? (React.createElement(DeleteIcon, { "data-testid": "NextScenario_DeleteIcon", onClick: function () { return disconnectScenario(); } })) : null));
}
