var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { centeredFlex } from '@src/lib/mixins';
import Moment from 'moment';
import { Input, Button } from '@src/components/atoms';
import buttonTypes from '@src/lib/buttonTypes';
import { sizes } from '@src/lib/sizes';
import StepDateSelector from './StepDateSelector';
var ContentContainer = styled.span(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  .footer-buttons {\n    margin-top: 24px;\n    margin-bottom: 30px;\n    ", ";\n    > button {\n      margin: 10px;\n    }\n  }\n"], ["\n  .footer-buttons {\n    margin-top: 24px;\n    margin-bottom: 30px;\n    ", ";\n    > button {\n      margin: 10px;\n    }\n  }\n"])), centeredFlex);
var StepCreate = /** @class */ (function (_super) {
    __extends(StepCreate, _super);
    function StepCreate(props) {
        var _this = _super.call(this, props) || this;
        _this.handleInput = function (e) {
            _this.setState({ name: e.target.value });
        };
        _this.handleStepDateChange = function (e) {
            var _a;
            var _b = e.target, name = _b.name, value = _b.value;
            _this.setState((_a = {},
                _a[name] = value === '' ? value : parseInt(value),
                _a));
        };
        _this.handleConfirm = function () {
            var onConfirm = _this.props.onConfirm;
            var _a = _this.state, name = _a.name, daysAfter = _a.daysAfter, hour = _a.hour, minute = _a.minute;
            onConfirm({
                name: name,
                daysAfter: daysAfter,
                hour: hour,
                minute: minute,
            });
        };
        var dateTimeNow = Moment();
        _this.state = {
            name: "\u30B9\u30C6\u30C3\u30D7\u914D\u4FE1" + dateTimeNow.format('YYYY/MM/DD HH:mm:ss'),
            daysAfter: '',
            hour: '',
            minute: '',
        };
        return _this;
    }
    StepCreate.prototype.render = function () {
        var onCancel = this.props.onCancel;
        var _a = this.state, name = _a.name, daysAfter = _a.daysAfter, hour = _a.hour, minute = _a.minute;
        var daysAfterExists = daysAfter !== '' && !isNaN(daysAfter) && daysAfter > 0;
        return (React.createElement(ContentContainer, null,
            React.createElement("div", { className: "message-wrapper primary-label" }, "\u30B7\u30CA\u30EA\u30AA\u540D"),
            React.createElement("div", null,
                React.createElement(Input, { type: "text", placeholder: "\u65B0\u3057\u3044\u521D\u671F\u4F1A\u8A71\u540D\u3092\u5165\u529B\u3057\u3066\u304F\u3060\u3055\u3044", value: name, onChange: this.handleInput })),
            React.createElement("div", { className: "message-wrapper primary-label" }, "\u914D\u4FE1\u8A2D\u5B9A"),
            React.createElement("div", { className: "message-wrapper sub-label" }, "(\u3042\u3068\u3067\u5909\u66F4\u3067\u304D\u307E\u3059)"),
            React.createElement("div", null,
                React.createElement(StepDateSelector, { handleStepDateChange: this.handleStepDateChange, daysAfter: daysAfter, hour: hour, minute: minute }),
                React.createElement("div", { className: "footer-buttons" },
                    React.createElement(Button, { width: sizes.button.content, buttonType: buttonTypes.CANCEL, fontSize: "12px", fontWeight: "bold", onClick: onCancel, text: "\u30AD\u30E3\u30F3\u30BB\u30EB" }),
                    React.createElement(Button, { className: daysAfterExists && hour !== '' && minute !== ''
                            ? ''
                            : 'readonly', text: "\u4F5C\u6210", fontWeight: "bold", width: sizes.button.content, fontSize: "12px", onClick: this.handleConfirm })))));
    };
    return StepCreate;
}(PureComponent));
export default StepCreate;
var templateObject_1;
