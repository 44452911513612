import "core-js/modules/es.array.concat";

var collection = function collection(chatbotId) {
  return "/chatbots/".concat(chatbotId, "/persistent_menu_items");
};

var member = function member(chatbotId, id) {
  return "".concat(collection(chatbotId), "/").concat(id);
};

export default {
  collection: collection,
  member: member
};