var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { handleAPIErrors, objToQuery } from '@src/lib/helpers';
import { chatTypes } from '@src/lib/chat-types';
import { adScenario } from '@src/redux/fanp/constants';
import * as scenarioDeliveryApi from '@src/services/api/scenarioDelivery';
import * as blockApi from '@src/services/api/block';
import * as scenarioApi from '@src/services/api/scenario';
import * as buttonItemApi from '@src/services/api/buttonItems';
import * as genericMessageApi from '@src/services/api/genericMessage';
import * as talkTriggerApi from '@src/services/api/talkTrigger';
import { deleteMessageMember } from '@src/services/api/message';
import { fetchAdScenario } from './getActions';
import { removeGenericTagAction } from './ajaxActions';
var DELETE_SCENARIO_DELIVERY = adScenario.DELETE_SCENARIO_DELIVERY, AD_DELETE_SCENARIO = adScenario.AD_DELETE_SCENARIO, AD_DELETE_SCENARIOS = adScenario.AD_DELETE_SCENARIOS, AD_REMOVE_BUTTON_FROM_GENERIC = adScenario.AD_REMOVE_BUTTON_FROM_GENERIC, AD_REMOVE_BUTTON_FROM_TEXT = adScenario.AD_REMOVE_BUTTON_FROM_TEXT, AD_REMOVE_MESSAGE = adScenario.AD_REMOVE_MESSAGE, AD_REMOVE_MESSAGE_ITEM = adScenario.AD_REMOVE_MESSAGE_ITEM, AD_REMOVE_FREE_INPUT = adScenario.AD_REMOVE_FREE_INPUT, AD_REMOVE_IDS_CHANGE = adScenario.AD_REMOVE_IDS_CHANGE, AD_REMOVE_BUBBLE = adScenario.AD_REMOVE_BUBBLE;
var deleteScenarioDelivery = function (scenarioId) { return ({
    type: DELETE_SCENARIO_DELIVERY,
    scenarioId: scenarioId,
}); };
export var handleDeleteScenarios = function (chatbotId, adScenarioIds) { return ({
    type: AD_DELETE_SCENARIOS,
    chatbotId: chatbotId,
    adScenarioIds: adScenarioIds,
}); };
var handleRemoveButtonFromGeneric = function (_a) {
    var listIterator = _a.listIterator, chatbotId = _a.chatbotId, scenarioId = _a.scenarioId, messageId = _a.messageId, itemId = _a.itemId, buttonItemId = _a.buttonItemId;
    return ({
        type: AD_REMOVE_BUTTON_FROM_GENERIC,
        listIterator: listIterator,
        chatbotId: chatbotId,
        scenarioId: scenarioId,
        messageId: messageId,
        itemId: itemId,
        buttonItemId: buttonItemId,
    });
};
var handleRemoveButtonFromText = function (_a) {
    var chatbotId = _a.chatbotId, scenarioId = _a.scenarioId, messageId = _a.messageId, buttonItemId = _a.buttonItemId;
    return ({
        type: AD_REMOVE_BUTTON_FROM_TEXT,
        chatbotId: chatbotId,
        scenarioId: scenarioId,
        messageId: messageId,
        buttonItemId: buttonItemId,
    });
};
export var removeMessageAction = function (_a) {
    var chatbotId = _a.chatbotId, scenarioId = _a.scenarioId, messageId = _a.messageId, blockId = _a.blockId, messageSectionId = _a.messageSectionId;
    return ({
        type: AD_REMOVE_MESSAGE,
        chatbotId: chatbotId,
        scenarioId: scenarioId,
        messageId: messageId,
        blockId: blockId,
        messageSectionId: messageSectionId,
    });
};
export var removeMessageItemAction = function (_a) {
    var chatbotId = _a.chatbotId, scenarioId = _a.scenarioId, messageId = _a.messageId, itemId = _a.itemId;
    return ({
        type: AD_REMOVE_MESSAGE_ITEM,
        chatbotId: chatbotId,
        scenarioId: scenarioId,
        messageId: messageId,
        itemId: itemId,
    });
};
export var removeFreeInputAction = function (_a) {
    var chatbotId = _a.chatbotId, adScenarioId = _a.adScenarioId, talkTriggerId = _a.talkTriggerId, rank = _a.rank, talkTrigger = _a.talkTrigger, chatTypeParam = _a.chatTypeParam, blockIdParam = _a.blockIdParam;
    return ({
        type: AD_REMOVE_FREE_INPUT,
        chatbotId: chatbotId,
        adScenarioId: adScenarioId,
        talkTriggerId: talkTriggerId,
        rank: rank,
        talkTrigger: talkTrigger,
        chatTypeParam: chatTypeParam,
        blockIdParam: blockIdParam,
    });
};
export var removeScenarioIdsChange = function (scenarioId) {
    return {
        type: AD_REMOVE_IDS_CHANGE,
        scenarioId: scenarioId,
    };
};
export var deleteScenario = function (chatTypeParam, chatbotId, adScenarioId) { return ({
    type: AD_DELETE_SCENARIO,
    chatTypeParam: chatTypeParam,
    chatbotId: chatbotId,
    adScenarioId: adScenarioId,
}); };
export var fetchDeleteScenario = function (chatTypeParam, chatbotId, adScenarioId) { return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
    var e_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, scenarioApi.deleteScenario(chatbotId, adScenarioId, chatTypeParam)];
            case 1:
                _a.sent();
                if (chatTypeParam === chatTypes.STEP) {
                    if (window.globalRouterHistory) {
                        window.globalRouterHistory.push("/dashboard/ad/" + chatTypeParam + "/chatbots/" + chatbotId + "/list");
                    }
                }
                dispatch(deleteScenario(chatTypeParam, chatbotId, adScenarioId));
                return [3 /*break*/, 3];
            case 2:
                e_1 = _a.sent();
                console.error(e_1);
                throw new Error(e_1.message);
            case 3: return [2 /*return*/];
        }
    });
}); }; };
export var removeButtonFromGeneric = function (_a) {
    var listIterator = _a.listIterator, chatbotId = _a.chatbotId, scenarioId = _a.scenarioId, messageId = _a.messageId, itemId = _a.itemId, buttonItemId = _a.buttonItemId, layerRank = _a.layerRank;
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        var e_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, genericMessageApi.removeButton({
                            chatbotId: chatbotId,
                            scenarioId: scenarioId,
                            messageId: messageId,
                            itemId: itemId,
                            buttonItemId: buttonItemId,
                            layerRank: layerRank,
                        })];
                case 1:
                    _a.sent();
                    dispatch(handleRemoveButtonFromGeneric({
                        listIterator: listIterator,
                        chatbotId: chatbotId,
                        scenarioId: scenarioId,
                        messageId: messageId,
                        itemId: itemId,
                        buttonItemId: buttonItemId,
                    }));
                    return [2 /*return*/, dispatch(fetchAdScenario({
                            chatbotId: chatbotId,
                            adScenarioId: scenarioId,
                        }))];
                case 2:
                    e_2 = _a.sent();
                    return [2 /*return*/, handleAPIErrors('ボタンを削除できませんでした。', e_2)];
                case 3: return [2 /*return*/];
            }
        });
    }); };
};
export var removeButtonFromText = function (_a) {
    var chatbotId = _a.chatbotId, scenarioId = _a.scenarioId, messageId = _a.messageId, buttonItemId = _a.buttonItemId, layerRank = _a.layerRank;
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        var query, res, e_3;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    query = {
                        layer_rank: layerRank,
                    };
                    return [4 /*yield*/, buttonItemApi.removeButtonFromTextMessage(chatbotId, scenarioId, messageId, buttonItemId, query)];
                case 1:
                    res = _a.sent();
                    if (res.status && res.status === 200) {
                        dispatch(handleRemoveButtonFromText({
                            chatbotId: chatbotId,
                            scenarioId: scenarioId,
                            messageId: messageId,
                            buttonItemId: buttonItemId,
                        }));
                        dispatch(fetchAdScenario({
                            chatbotId: chatbotId,
                            adScenarioId: scenarioId,
                        }));
                    }
                    else {
                        throw new Error("" + res.status);
                    }
                    return [3 /*break*/, 3];
                case 2:
                    e_3 = _a.sent();
                    console.error('ボタンを削除できませんでした: ', e_3);
                    handleAPIErrors('ボタンを削除できませんでした。', e_3);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
};
export var removeBlock = function (_a) {
    var chatbotId = _a.chatbotId, adScenarioId = _a.adScenarioId, blockId = _a.blockId;
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, blockApi.deleteBlock(chatbotId, adScenarioId, blockId)];
                case 1:
                    _a.sent();
                    return [4 /*yield*/, dispatch(fetchAdScenario({
                            chatbotId: chatbotId,
                            adScenarioId: adScenarioId,
                        }))];
                case 2:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
};
export var deleteScenarios = function (chatbotId, adScenarioIds) { return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
    var e_4;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, scenarioApi.deleteScenarios(chatbotId, adScenarioIds)];
            case 1:
                _a.sent();
                dispatch(handleDeleteScenarios(chatbotId, adScenarioIds));
                return [3 /*break*/, 3];
            case 2:
                e_4 = _a.sent();
                handleAPIErrors(e_4);
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); }; };
export var removeTag = function (_a) {
    var messageId = _a.messageId, chatbotId = _a.chatbotId, scenarioId = _a.scenarioId, itemId = _a.itemId, tagId = _a.tagId, name = _a.name;
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        var e_5;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, genericMessageApi.removeTag({
                            messageId: messageId,
                            chatbotId: chatbotId,
                            scenarioId: scenarioId,
                            itemId: itemId,
                            tagId: tagId,
                            name: name,
                        })];
                case 1:
                    _a.sent();
                    dispatch(removeGenericTagAction(messageId, itemId, tagId, name));
                    return [3 /*break*/, 3];
                case 2:
                    e_5 = _a.sent();
                    handleAPIErrors('タグを削除できませんでした。', e_5);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
};
export var removeFreeInput = function (_a) {
    var chatbotId = _a.chatbotId, adScenarioId = _a.adScenarioId, talkTriggerId = _a.talkTriggerId, rank = _a.rank, talkTrigger = _a.talkTrigger, chatTypeParam = _a.chatTypeParam, blockIdParam = _a.blockIdParam;
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, talkTriggerApi.deleteTalkTrigger(chatbotId, adScenarioId, blockIdParam, talkTriggerId, rank, talkTrigger)];
                case 1:
                    _a.sent();
                    dispatch(removeFreeInputAction({
                        chatbotId: chatbotId,
                        adScenarioId: adScenarioId,
                        talkTriggerId: talkTriggerId,
                        rank: rank,
                        talkTrigger: talkTrigger,
                        chatTypeParam: chatTypeParam,
                        blockIdParam: blockIdParam,
                    }));
                    return [2 /*return*/, dispatch(fetchAdScenario({
                            chatbotId: chatbotId,
                            adScenarioId: adScenarioId,
                        }))];
            }
        });
    }); };
};
// TODO: Maybe need to refactor this for flex message
export var removeMessage = function (_a) {
    var chatbotId = _a.chatbotId, scenarioId = _a.scenarioId, messageId = _a.messageId, blockId = _a.blockId, messageSectionId = _a.messageSectionId, layerRank = _a.layerRank;
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        var queryString, _a, message, status, e_6;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, , 3]);
                    queryString = objToQuery({
                        layer_rank: layerRank,
                    });
                    return [4 /*yield*/, deleteMessageMember(chatbotId, scenarioId, messageId, blockId, queryString)];
                case 1:
                    _a = _b.sent(), message = _a.message, status = _a.status;
                    if (status === 422) {
                        throw new Error(message);
                    }
                    dispatch(removeMessageAction({
                        chatbotId: chatbotId,
                        scenarioId: scenarioId,
                        messageId: messageId,
                        blockId: blockId,
                        messageSectionId: messageSectionId,
                    }));
                    return [2 /*return*/, dispatch(fetchAdScenario({
                            chatbotId: chatbotId,
                            adScenarioId: scenarioId,
                        }))];
                case 2:
                    e_6 = _b.sent();
                    return [2 /*return*/, handleAPIErrors('Request failed', e_6)];
                case 3: return [2 /*return*/];
            }
        });
    }); };
};
export var removeMessageItem = function (_a) {
    var chatbotId = _a.chatbotId, scenarioId = _a.scenarioId, messageId = _a.messageId, itemId = _a.itemId, layerRank = _a.layerRank;
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        var e_7;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, genericMessageApi.removeItem({
                            chatbotId: chatbotId,
                            scenarioId: scenarioId,
                            messageId: messageId,
                            itemId: itemId,
                            layerRank: layerRank,
                        })];
                case 1:
                    _a.sent();
                    dispatch(removeMessageItemAction({
                        chatbotId: chatbotId,
                        scenarioId: scenarioId,
                        messageId: messageId,
                        itemId: itemId,
                    }));
                    return [2 /*return*/, dispatch(fetchAdScenario({
                            chatbotId: chatbotId,
                            adScenarioId: scenarioId,
                        }))];
                case 2:
                    e_7 = _a.sent();
                    return [2 /*return*/, handleAPIErrors('アイテムを削除できませんでした。', e_7)];
                case 3: return [2 /*return*/];
            }
        });
    }); };
};
export var fetchDeleteScenarioDelivery = function (chatbotId, scenarioId) { return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, scenarioDeliveryApi.deleteScenarioDelivery(chatbotId, scenarioId)];
            case 1:
                _a.sent();
                dispatch(deleteScenarioDelivery(scenarioId));
                return [4 /*yield*/, dispatch(fetchAdScenario({
                        chatbotId: chatbotId,
                        adScenarioId: scenarioId,
                    }))];
            case 2:
                _a.sent();
                return [2 /*return*/];
        }
    });
}); }; };
export var removeBubbleFlexMessage = function (_a) {
    var messageId = _a.messageId, bubbleId = _a.bubbleId;
    return ({
        type: AD_REMOVE_BUBBLE,
        messageId: messageId,
        bubbleId: bubbleId,
    });
};
