// source: services/form.proto

/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

/* eslint-disable */
// @ts-nocheck
var jspb = require('google-protobuf');

var goog = jspb;
var global = Function('return this')();

var entities_common_pb = require('../entities/common_pb.js');

goog.object.extend(proto, entities_common_pb);

var entities_conversion_pb = require('../entities/conversion_pb.js');

goog.object.extend(proto, entities_conversion_pb);

var entities_entities_pb = require('../entities/entities_pb.js');

goog.object.extend(proto, entities_entities_pb);

var entities_form_pb = require('../entities/form_pb.js');

goog.object.extend(proto, entities_form_pb);

var entities_message_pb = require('../entities/message_pb.js');

goog.object.extend(proto, entities_message_pb);

var entities_user_dossier_pb = require('../entities/user_dossier_pb.js');

goog.object.extend(proto, entities_user_dossier_pb);

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');

goog.object.extend(proto, google_protobuf_timestamp_pb);

var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');

goog.object.extend(proto, google_protobuf_wrappers_pb);
goog.exportSymbol('proto.ConvertFormToFormBuildRequest', null, global);
goog.exportSymbol('proto.ConvertFormToFormBuildResponse', null, global);
goog.exportSymbol('proto.CreateFormBuildRequest', null, global);
goog.exportSymbol('proto.CreateFormElementTemplateRequest', null, global);
goog.exportSymbol('proto.CreateFormElementValidationRequest', null, global);
goog.exportSymbol('proto.CreateFormElementValidationRequest.ValidationCase', null, global);
goog.exportSymbol('proto.CreateFormRequest', null, global);
goog.exportSymbol('proto.CreateFormScenarioRequest', null, global);
goog.exportSymbol('proto.CreateFormTemplateRequest', null, global);
goog.exportSymbol('proto.DeleteFormBuildRequest', null, global);
goog.exportSymbol('proto.DeleteFormElementTemplateRequest', null, global);
goog.exportSymbol('proto.DeleteFormElementValidationRequest', null, global);
goog.exportSymbol('proto.DeleteFormRequest', null, global);
goog.exportSymbol('proto.DeleteFormScenarioRequest', null, global);
goog.exportSymbol('proto.DeleteFormTemplateRequest', null, global);
goog.exportSymbol('proto.GetEndUserFormRequest', null, global);
goog.exportSymbol('proto.GetFormBuildRequest', null, global);
goog.exportSymbol('proto.GetFormElementValidationRequest', null, global);
goog.exportSymbol('proto.GetFormQuestionListsRequest', null, global);
goog.exportSymbol('proto.GetFormQuestionListsResponse', null, global);
goog.exportSymbol('proto.GetFormQuestionRequest', null, global);
goog.exportSymbol('proto.GetFormRequest', null, global);
goog.exportSymbol('proto.GetFormScenarioRequest', null, global);
goog.exportSymbol('proto.ListConversionTasksRequest', null, global);
goog.exportSymbol('proto.ListConversionTasksResponse', null, global);
goog.exportSymbol('proto.ListFormBuildsRequest', null, global);
goog.exportSymbol('proto.ListFormBuildsResponse', null, global);
goog.exportSymbol('proto.ListFormElementTemplatesRequest', null, global);
goog.exportSymbol('proto.ListFormElementTemplatesResponse', null, global);
goog.exportSymbol('proto.ListFormElementValidationsRequest', null, global);
goog.exportSymbol('proto.ListFormElementValidationsResponse', null, global);
goog.exportSymbol('proto.ListFormIDsRequest', null, global);
goog.exportSymbol('proto.ListFormIDsResponse', null, global);
goog.exportSymbol('proto.ListFormTemplatesRequest', null, global);
goog.exportSymbol('proto.ListFormTemplatesResponse', null, global);
goog.exportSymbol('proto.ListFormsRequest', null, global);
goog.exportSymbol('proto.ListFormsResponse', null, global);
goog.exportSymbol('proto.LogConversionTaskRequest', null, global);
goog.exportSymbol('proto.ReceiveEndUserFormRequest', null, global);
goog.exportSymbol('proto.ReceiveEndUserMessageRequest', null, global);
goog.exportSymbol('proto.SetFormAutomationTriggerRequest', null, global);
goog.exportSymbol('proto.UpdateFormBuildRequest', null, global);
goog.exportSymbol('proto.UpdateFormBuildStatusRequest', null, global);
goog.exportSymbol('proto.UpdateFormElementTemplateRequest', null, global);
goog.exportSymbol('proto.UpdateFormElementValidationRequest', null, global);
goog.exportSymbol('proto.UpdateFormElementValidationRequest.ValidationCase', null, global);
goog.exportSymbol('proto.UpdateFormQuestionRequest', null, global);
goog.exportSymbol('proto.UpdateFormQuestionRequest.ValidationCase', null, global);
goog.exportSymbol('proto.UpdateFormRequest', null, global);
goog.exportSymbol('proto.UpdateFormScenarioRequest', null, global);
goog.exportSymbol('proto.UpdateFormTemplateRequest', null, global);
goog.exportSymbol('proto.UpsertFormQuestionsRequest', null, global);
goog.exportSymbol('proto.UpsertFormQuestionsResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */

proto.ListFormTemplatesRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.ListFormTemplatesRequest, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ListFormTemplatesRequest.displayName = 'proto.ListFormTemplatesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.ListFormTemplatesResponse = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ListFormTemplatesResponse.repeatedFields_, null);
};

goog.inherits(proto.ListFormTemplatesResponse, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ListFormTemplatesResponse.displayName = 'proto.ListFormTemplatesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.CreateFormTemplateRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.CreateFormTemplateRequest.repeatedFields_, null);
};

goog.inherits(proto.CreateFormTemplateRequest, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.CreateFormTemplateRequest.displayName = 'proto.CreateFormTemplateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.UpdateFormTemplateRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.UpdateFormTemplateRequest.repeatedFields_, null);
};

goog.inherits(proto.UpdateFormTemplateRequest, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UpdateFormTemplateRequest.displayName = 'proto.UpdateFormTemplateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.DeleteFormTemplateRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.DeleteFormTemplateRequest, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.DeleteFormTemplateRequest.displayName = 'proto.DeleteFormTemplateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.ListFormsRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.ListFormsRequest, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ListFormsRequest.displayName = 'proto.ListFormsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.ListFormsResponse = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ListFormsResponse.repeatedFields_, null);
};

goog.inherits(proto.ListFormsResponse, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ListFormsResponse.displayName = 'proto.ListFormsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.GetFormRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.GetFormRequest, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GetFormRequest.displayName = 'proto.GetFormRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.CreateFormRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.CreateFormRequest.repeatedFields_, null);
};

goog.inherits(proto.CreateFormRequest, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.CreateFormRequest.displayName = 'proto.CreateFormRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.UpdateFormRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.UpdateFormRequest.repeatedFields_, null);
};

goog.inherits(proto.UpdateFormRequest, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UpdateFormRequest.displayName = 'proto.UpdateFormRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.ConvertFormToFormBuildRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.ConvertFormToFormBuildRequest, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ConvertFormToFormBuildRequest.displayName = 'proto.ConvertFormToFormBuildRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.ConvertFormToFormBuildResponse = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.ConvertFormToFormBuildResponse, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ConvertFormToFormBuildResponse.displayName = 'proto.ConvertFormToFormBuildResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.DeleteFormRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.DeleteFormRequest, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.DeleteFormRequest.displayName = 'proto.DeleteFormRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.ListFormElementTemplatesRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.ListFormElementTemplatesRequest, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ListFormElementTemplatesRequest.displayName = 'proto.ListFormElementTemplatesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.ListFormElementTemplatesResponse = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ListFormElementTemplatesResponse.repeatedFields_, null);
};

goog.inherits(proto.ListFormElementTemplatesResponse, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ListFormElementTemplatesResponse.displayName = 'proto.ListFormElementTemplatesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.CreateFormElementTemplateRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.CreateFormElementTemplateRequest, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.CreateFormElementTemplateRequest.displayName = 'proto.CreateFormElementTemplateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.UpdateFormElementTemplateRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.UpdateFormElementTemplateRequest, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UpdateFormElementTemplateRequest.displayName = 'proto.UpdateFormElementTemplateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.DeleteFormElementTemplateRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.DeleteFormElementTemplateRequest, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.DeleteFormElementTemplateRequest.displayName = 'proto.DeleteFormElementTemplateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.ReceiveEndUserFormRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ReceiveEndUserFormRequest.repeatedFields_, null);
};

goog.inherits(proto.ReceiveEndUserFormRequest, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ReceiveEndUserFormRequest.displayName = 'proto.ReceiveEndUserFormRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.GetEndUserFormRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.GetEndUserFormRequest, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GetEndUserFormRequest.displayName = 'proto.GetEndUserFormRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.ListFormElementValidationsRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.ListFormElementValidationsRequest, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ListFormElementValidationsRequest.displayName = 'proto.ListFormElementValidationsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.ListFormElementValidationsResponse = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ListFormElementValidationsResponse.repeatedFields_, null);
};

goog.inherits(proto.ListFormElementValidationsResponse, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ListFormElementValidationsResponse.displayName = 'proto.ListFormElementValidationsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.GetFormElementValidationRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.GetFormElementValidationRequest, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GetFormElementValidationRequest.displayName = 'proto.GetFormElementValidationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.CreateFormElementValidationRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.CreateFormElementValidationRequest.oneofGroups_);
};

goog.inherits(proto.CreateFormElementValidationRequest, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.CreateFormElementValidationRequest.displayName = 'proto.CreateFormElementValidationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.UpdateFormElementValidationRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.UpdateFormElementValidationRequest.oneofGroups_);
};

goog.inherits(proto.UpdateFormElementValidationRequest, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UpdateFormElementValidationRequest.displayName = 'proto.UpdateFormElementValidationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.DeleteFormElementValidationRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.DeleteFormElementValidationRequest, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.DeleteFormElementValidationRequest.displayName = 'proto.DeleteFormElementValidationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.ListFormBuildsRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.ListFormBuildsRequest, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ListFormBuildsRequest.displayName = 'proto.ListFormBuildsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.ListFormBuildsResponse = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ListFormBuildsResponse.repeatedFields_, null);
};

goog.inherits(proto.ListFormBuildsResponse, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ListFormBuildsResponse.displayName = 'proto.ListFormBuildsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.GetFormBuildRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.GetFormBuildRequest, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GetFormBuildRequest.displayName = 'proto.GetFormBuildRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.CreateFormBuildRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.CreateFormBuildRequest, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.CreateFormBuildRequest.displayName = 'proto.CreateFormBuildRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.UpdateFormBuildRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.UpdateFormBuildRequest.repeatedFields_, null);
};

goog.inherits(proto.UpdateFormBuildRequest, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UpdateFormBuildRequest.displayName = 'proto.UpdateFormBuildRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.UpdateFormBuildStatusRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.UpdateFormBuildStatusRequest, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UpdateFormBuildStatusRequest.displayName = 'proto.UpdateFormBuildStatusRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.DeleteFormBuildRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.DeleteFormBuildRequest, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.DeleteFormBuildRequest.displayName = 'proto.DeleteFormBuildRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.GetFormScenarioRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.GetFormScenarioRequest, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GetFormScenarioRequest.displayName = 'proto.GetFormScenarioRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.CreateFormScenarioRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.CreateFormScenarioRequest.repeatedFields_, null);
};

goog.inherits(proto.CreateFormScenarioRequest, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.CreateFormScenarioRequest.displayName = 'proto.CreateFormScenarioRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.UpdateFormScenarioRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.UpdateFormScenarioRequest.repeatedFields_, null);
};

goog.inherits(proto.UpdateFormScenarioRequest, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UpdateFormScenarioRequest.displayName = 'proto.UpdateFormScenarioRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.DeleteFormScenarioRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.DeleteFormScenarioRequest, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.DeleteFormScenarioRequest.displayName = 'proto.DeleteFormScenarioRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.GetFormQuestionListsRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.GetFormQuestionListsRequest, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GetFormQuestionListsRequest.displayName = 'proto.GetFormQuestionListsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.GetFormQuestionListsResponse = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.GetFormQuestionListsResponse.repeatedFields_, null);
};

goog.inherits(proto.GetFormQuestionListsResponse, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GetFormQuestionListsResponse.displayName = 'proto.GetFormQuestionListsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.UpsertFormQuestionsRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.UpsertFormQuestionsRequest.repeatedFields_, null);
};

goog.inherits(proto.UpsertFormQuestionsRequest, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UpsertFormQuestionsRequest.displayName = 'proto.UpsertFormQuestionsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.UpsertFormQuestionsResponse = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.UpsertFormQuestionsResponse.repeatedFields_, null);
};

goog.inherits(proto.UpsertFormQuestionsResponse, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UpsertFormQuestionsResponse.displayName = 'proto.UpsertFormQuestionsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.UpdateFormQuestionRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.UpdateFormQuestionRequest.oneofGroups_);
};

goog.inherits(proto.UpdateFormQuestionRequest, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UpdateFormQuestionRequest.displayName = 'proto.UpdateFormQuestionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.GetFormQuestionRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.GetFormQuestionRequest, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GetFormQuestionRequest.displayName = 'proto.GetFormQuestionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.ReceiveEndUserMessageRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.ReceiveEndUserMessageRequest, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ReceiveEndUserMessageRequest.displayName = 'proto.ReceiveEndUserMessageRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.ListFormIDsRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.ListFormIDsRequest, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ListFormIDsRequest.displayName = 'proto.ListFormIDsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.ListFormIDsResponse = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ListFormIDsResponse.repeatedFields_, null);
};

goog.inherits(proto.ListFormIDsResponse, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ListFormIDsResponse.displayName = 'proto.ListFormIDsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.ListConversionTasksRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ListConversionTasksRequest.repeatedFields_, null);
};

goog.inherits(proto.ListConversionTasksRequest, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ListConversionTasksRequest.displayName = 'proto.ListConversionTasksRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.LogConversionTaskRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.LogConversionTaskRequest.repeatedFields_, null);
};

goog.inherits(proto.LogConversionTaskRequest, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.LogConversionTaskRequest.displayName = 'proto.LogConversionTaskRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.ListConversionTasksResponse = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ListConversionTasksResponse.repeatedFields_, null);
};

goog.inherits(proto.ListConversionTasksResponse, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ListConversionTasksResponse.displayName = 'proto.ListConversionTasksResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.SetFormAutomationTriggerRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.SetFormAutomationTriggerRequest, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.SetFormAutomationTriggerRequest.displayName = 'proto.SetFormAutomationTriggerRequest';
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.ListFormTemplatesRequest.prototype.toObject = function (opt_includeInstance) {
    return proto.ListFormTemplatesRequest.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.ListFormTemplatesRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.ListFormTemplatesRequest.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      pagination: (f = msg.getPagination()) && entities_common_pb.Pagination.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ListFormTemplatesRequest}
 */


proto.ListFormTemplatesRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ListFormTemplatesRequest();
  return proto.ListFormTemplatesRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ListFormTemplatesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ListFormTemplatesRequest}
 */


proto.ListFormTemplatesRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value = new entities_common_pb.Pagination();
        reader.readMessage(value, entities_common_pb.Pagination.deserializeBinaryFromReader);
        msg.setPagination(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.ListFormTemplatesRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.ListFormTemplatesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ListFormTemplatesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.ListFormTemplatesRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getPagination();

  if (f != null) {
    writer.writeMessage(1, f, entities_common_pb.Pagination.serializeBinaryToWriter);
  }
};
/**
 * optional e.Pagination pagination = 1;
 * @return {?proto.e.Pagination}
 */


proto.ListFormTemplatesRequest.prototype.getPagination = function () {
  return (
    /** @type{?proto.e.Pagination} */
    jspb.Message.getWrapperField(this, entities_common_pb.Pagination, 1)
  );
};
/**
 * @param {?proto.e.Pagination|undefined} value
 * @return {!proto.ListFormTemplatesRequest} returns this
*/


proto.ListFormTemplatesRequest.prototype.setPagination = function (value) {
  return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.ListFormTemplatesRequest} returns this
 */


proto.ListFormTemplatesRequest.prototype.clearPagination = function () {
  return this.setPagination(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.ListFormTemplatesRequest.prototype.hasPagination = function () {
  return jspb.Message.getField(this, 1) != null;
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */


proto.ListFormTemplatesResponse.repeatedFields_ = [1];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.ListFormTemplatesResponse.prototype.toObject = function (opt_includeInstance) {
    return proto.ListFormTemplatesResponse.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.ListFormTemplatesResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.ListFormTemplatesResponse.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      formTemplatesList: jspb.Message.toObjectList(msg.getFormTemplatesList(), entities_form_pb.FormTemplate.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ListFormTemplatesResponse}
 */


proto.ListFormTemplatesResponse.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ListFormTemplatesResponse();
  return proto.ListFormTemplatesResponse.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ListFormTemplatesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ListFormTemplatesResponse}
 */


proto.ListFormTemplatesResponse.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value = new entities_form_pb.FormTemplate();
        reader.readMessage(value, entities_form_pb.FormTemplate.deserializeBinaryFromReader);
        msg.addFormTemplates(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.ListFormTemplatesResponse.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.ListFormTemplatesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ListFormTemplatesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.ListFormTemplatesResponse.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getFormTemplatesList();

  if (f.length > 0) {
    writer.writeRepeatedMessage(1, f, entities_form_pb.FormTemplate.serializeBinaryToWriter);
  }
};
/**
 * repeated e.FormTemplate form_templates = 1;
 * @return {!Array<!proto.e.FormTemplate>}
 */


proto.ListFormTemplatesResponse.prototype.getFormTemplatesList = function () {
  return (
    /** @type{!Array<!proto.e.FormTemplate>} */
    jspb.Message.getRepeatedWrapperField(this, entities_form_pb.FormTemplate, 1)
  );
};
/**
 * @param {!Array<!proto.e.FormTemplate>} value
 * @return {!proto.ListFormTemplatesResponse} returns this
*/


proto.ListFormTemplatesResponse.prototype.setFormTemplatesList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};
/**
 * @param {!proto.e.FormTemplate=} opt_value
 * @param {number=} opt_index
 * @return {!proto.e.FormTemplate}
 */


proto.ListFormTemplatesResponse.prototype.addFormTemplates = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.e.FormTemplate, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ListFormTemplatesResponse} returns this
 */


proto.ListFormTemplatesResponse.prototype.clearFormTemplatesList = function () {
  return this.setFormTemplatesList([]);
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */


proto.CreateFormTemplateRequest.repeatedFields_ = [2];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.CreateFormTemplateRequest.prototype.toObject = function (opt_includeInstance) {
    return proto.CreateFormTemplateRequest.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.CreateFormTemplateRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.CreateFormTemplateRequest.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      name: jspb.Message.getFieldWithDefault(msg, 1, ""),
      pagesList: jspb.Message.toObjectList(msg.getPagesList(), entities_form_pb.FormPage.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.CreateFormTemplateRequest}
 */


proto.CreateFormTemplateRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.CreateFormTemplateRequest();
  return proto.CreateFormTemplateRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.CreateFormTemplateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.CreateFormTemplateRequest}
 */


proto.CreateFormTemplateRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setName(value);
        break;

      case 2:
        var value = new entities_form_pb.FormPage();
        reader.readMessage(value, entities_form_pb.FormPage.deserializeBinaryFromReader);
        msg.addPages(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.CreateFormTemplateRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.CreateFormTemplateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.CreateFormTemplateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.CreateFormTemplateRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getName();

  if (f.length > 0) {
    writer.writeString(1, f);
  }

  f = message.getPagesList();

  if (f.length > 0) {
    writer.writeRepeatedMessage(2, f, entities_form_pb.FormPage.serializeBinaryToWriter);
  }
};
/**
 * optional string name = 1;
 * @return {string}
 */


proto.CreateFormTemplateRequest.prototype.getName = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 1, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.CreateFormTemplateRequest} returns this
 */


proto.CreateFormTemplateRequest.prototype.setName = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * repeated e.FormPage pages = 2;
 * @return {!Array<!proto.e.FormPage>}
 */


proto.CreateFormTemplateRequest.prototype.getPagesList = function () {
  return (
    /** @type{!Array<!proto.e.FormPage>} */
    jspb.Message.getRepeatedWrapperField(this, entities_form_pb.FormPage, 2)
  );
};
/**
 * @param {!Array<!proto.e.FormPage>} value
 * @return {!proto.CreateFormTemplateRequest} returns this
*/


proto.CreateFormTemplateRequest.prototype.setPagesList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};
/**
 * @param {!proto.e.FormPage=} opt_value
 * @param {number=} opt_index
 * @return {!proto.e.FormPage}
 */


proto.CreateFormTemplateRequest.prototype.addPages = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.e.FormPage, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.CreateFormTemplateRequest} returns this
 */


proto.CreateFormTemplateRequest.prototype.clearPagesList = function () {
  return this.setPagesList([]);
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */


proto.UpdateFormTemplateRequest.repeatedFields_ = [3];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.UpdateFormTemplateRequest.prototype.toObject = function (opt_includeInstance) {
    return proto.UpdateFormTemplateRequest.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.UpdateFormTemplateRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.UpdateFormTemplateRequest.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, ""),
      name: jspb.Message.getFieldWithDefault(msg, 2, ""),
      pagesList: jspb.Message.toObjectList(msg.getPagesList(), entities_form_pb.FormPage.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UpdateFormTemplateRequest}
 */


proto.UpdateFormTemplateRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UpdateFormTemplateRequest();
  return proto.UpdateFormTemplateRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UpdateFormTemplateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UpdateFormTemplateRequest}
 */


proto.UpdateFormTemplateRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setId(value);
        break;

      case 2:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setName(value);
        break;

      case 3:
        var value = new entities_form_pb.FormPage();
        reader.readMessage(value, entities_form_pb.FormPage.deserializeBinaryFromReader);
        msg.addPages(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.UpdateFormTemplateRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.UpdateFormTemplateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UpdateFormTemplateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.UpdateFormTemplateRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getId();

  if (f.length > 0) {
    writer.writeString(1, f);
  }

  f = message.getName();

  if (f.length > 0) {
    writer.writeString(2, f);
  }

  f = message.getPagesList();

  if (f.length > 0) {
    writer.writeRepeatedMessage(3, f, entities_form_pb.FormPage.serializeBinaryToWriter);
  }
};
/**
 * optional string id = 1;
 * @return {string}
 */


proto.UpdateFormTemplateRequest.prototype.getId = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 1, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.UpdateFormTemplateRequest} returns this
 */


proto.UpdateFormTemplateRequest.prototype.setId = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional string name = 2;
 * @return {string}
 */


proto.UpdateFormTemplateRequest.prototype.getName = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 2, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.UpdateFormTemplateRequest} returns this
 */


proto.UpdateFormTemplateRequest.prototype.setName = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};
/**
 * repeated e.FormPage pages = 3;
 * @return {!Array<!proto.e.FormPage>}
 */


proto.UpdateFormTemplateRequest.prototype.getPagesList = function () {
  return (
    /** @type{!Array<!proto.e.FormPage>} */
    jspb.Message.getRepeatedWrapperField(this, entities_form_pb.FormPage, 3)
  );
};
/**
 * @param {!Array<!proto.e.FormPage>} value
 * @return {!proto.UpdateFormTemplateRequest} returns this
*/


proto.UpdateFormTemplateRequest.prototype.setPagesList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};
/**
 * @param {!proto.e.FormPage=} opt_value
 * @param {number=} opt_index
 * @return {!proto.e.FormPage}
 */


proto.UpdateFormTemplateRequest.prototype.addPages = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.e.FormPage, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.UpdateFormTemplateRequest} returns this
 */


proto.UpdateFormTemplateRequest.prototype.clearPagesList = function () {
  return this.setPagesList([]);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.DeleteFormTemplateRequest.prototype.toObject = function (opt_includeInstance) {
    return proto.DeleteFormTemplateRequest.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.DeleteFormTemplateRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.DeleteFormTemplateRequest.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.DeleteFormTemplateRequest}
 */


proto.DeleteFormTemplateRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.DeleteFormTemplateRequest();
  return proto.DeleteFormTemplateRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.DeleteFormTemplateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.DeleteFormTemplateRequest}
 */


proto.DeleteFormTemplateRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setId(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.DeleteFormTemplateRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.DeleteFormTemplateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.DeleteFormTemplateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.DeleteFormTemplateRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getId();

  if (f.length > 0) {
    writer.writeString(1, f);
  }
};
/**
 * optional string id = 1;
 * @return {string}
 */


proto.DeleteFormTemplateRequest.prototype.getId = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 1, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.DeleteFormTemplateRequest} returns this
 */


proto.DeleteFormTemplateRequest.prototype.setId = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.ListFormsRequest.prototype.toObject = function (opt_includeInstance) {
    return proto.ListFormsRequest.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.ListFormsRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.ListFormsRequest.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      pagination: (f = msg.getPagination()) && entities_common_pb.Pagination.toObject(includeInstance, f),
      formBuildId: jspb.Message.getFieldWithDefault(msg, 2, ""),
      searchKeyword: jspb.Message.getFieldWithDefault(msg, 17, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ListFormsRequest}
 */


proto.ListFormsRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ListFormsRequest();
  return proto.ListFormsRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ListFormsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ListFormsRequest}
 */


proto.ListFormsRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value = new entities_common_pb.Pagination();
        reader.readMessage(value, entities_common_pb.Pagination.deserializeBinaryFromReader);
        msg.setPagination(value);
        break;

      case 2:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setFormBuildId(value);
        break;

      case 17:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setSearchKeyword(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.ListFormsRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.ListFormsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ListFormsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.ListFormsRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getPagination();

  if (f != null) {
    writer.writeMessage(1, f, entities_common_pb.Pagination.serializeBinaryToWriter);
  }

  f = message.getFormBuildId();

  if (f.length > 0) {
    writer.writeString(2, f);
  }

  f = message.getSearchKeyword();

  if (f.length > 0) {
    writer.writeString(17, f);
  }
};
/**
 * optional e.Pagination pagination = 1;
 * @return {?proto.e.Pagination}
 */


proto.ListFormsRequest.prototype.getPagination = function () {
  return (
    /** @type{?proto.e.Pagination} */
    jspb.Message.getWrapperField(this, entities_common_pb.Pagination, 1)
  );
};
/**
 * @param {?proto.e.Pagination|undefined} value
 * @return {!proto.ListFormsRequest} returns this
*/


proto.ListFormsRequest.prototype.setPagination = function (value) {
  return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.ListFormsRequest} returns this
 */


proto.ListFormsRequest.prototype.clearPagination = function () {
  return this.setPagination(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.ListFormsRequest.prototype.hasPagination = function () {
  return jspb.Message.getField(this, 1) != null;
};
/**
 * optional string form_build_id = 2;
 * @return {string}
 */


proto.ListFormsRequest.prototype.getFormBuildId = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 2, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.ListFormsRequest} returns this
 */


proto.ListFormsRequest.prototype.setFormBuildId = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};
/**
 * optional string search_keyword = 17;
 * @return {string}
 */


proto.ListFormsRequest.prototype.getSearchKeyword = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 17, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.ListFormsRequest} returns this
 */


proto.ListFormsRequest.prototype.setSearchKeyword = function (value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */


proto.ListFormsResponse.repeatedFields_ = [1];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.ListFormsResponse.prototype.toObject = function (opt_includeInstance) {
    return proto.ListFormsResponse.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.ListFormsResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.ListFormsResponse.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      formsList: jspb.Message.toObjectList(msg.getFormsList(), entities_form_pb.Form.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ListFormsResponse}
 */


proto.ListFormsResponse.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ListFormsResponse();
  return proto.ListFormsResponse.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ListFormsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ListFormsResponse}
 */


proto.ListFormsResponse.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value = new entities_form_pb.Form();
        reader.readMessage(value, entities_form_pb.Form.deserializeBinaryFromReader);
        msg.addForms(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.ListFormsResponse.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.ListFormsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ListFormsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.ListFormsResponse.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getFormsList();

  if (f.length > 0) {
    writer.writeRepeatedMessage(1, f, entities_form_pb.Form.serializeBinaryToWriter);
  }
};
/**
 * repeated e.Form forms = 1;
 * @return {!Array<!proto.e.Form>}
 */


proto.ListFormsResponse.prototype.getFormsList = function () {
  return (
    /** @type{!Array<!proto.e.Form>} */
    jspb.Message.getRepeatedWrapperField(this, entities_form_pb.Form, 1)
  );
};
/**
 * @param {!Array<!proto.e.Form>} value
 * @return {!proto.ListFormsResponse} returns this
*/


proto.ListFormsResponse.prototype.setFormsList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};
/**
 * @param {!proto.e.Form=} opt_value
 * @param {number=} opt_index
 * @return {!proto.e.Form}
 */


proto.ListFormsResponse.prototype.addForms = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.e.Form, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ListFormsResponse} returns this
 */


proto.ListFormsResponse.prototype.clearFormsList = function () {
  return this.setFormsList([]);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.GetFormRequest.prototype.toObject = function (opt_includeInstance) {
    return proto.GetFormRequest.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.GetFormRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.GetFormRequest.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GetFormRequest}
 */


proto.GetFormRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GetFormRequest();
  return proto.GetFormRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GetFormRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GetFormRequest}
 */


proto.GetFormRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setId(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.GetFormRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.GetFormRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GetFormRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.GetFormRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getId();

  if (f.length > 0) {
    writer.writeString(1, f);
  }
};
/**
 * optional string id = 1;
 * @return {string}
 */


proto.GetFormRequest.prototype.getId = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 1, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.GetFormRequest} returns this
 */


proto.GetFormRequest.prototype.setId = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */


proto.CreateFormRequest.repeatedFields_ = [7];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.CreateFormRequest.prototype.toObject = function (opt_includeInstance) {
    return proto.CreateFormRequest.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.CreateFormRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.CreateFormRequest.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      name: jspb.Message.getFieldWithDefault(msg, 1, ""),
      formBuildId: jspb.Message.getFieldWithDefault(msg, 2, ""),
      logoUri: jspb.Message.getFieldWithDefault(msg, 5, ""),
      styles: (f = msg.getStyles()) && entities_form_pb.FormStyles.toObject(includeInstance, f),
      pagesList: jspb.Message.toObjectList(msg.getPagesList(), entities_form_pb.FormPage.toObject, includeInstance),
      completionStatus: jspb.Message.getFieldWithDefault(msg, 8, 0),
      editorName: jspb.Message.getFieldWithDefault(msg, 9, ""),
      editorEmail: jspb.Message.getFieldWithDefault(msg, 10, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.CreateFormRequest}
 */


proto.CreateFormRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.CreateFormRequest();
  return proto.CreateFormRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.CreateFormRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.CreateFormRequest}
 */


proto.CreateFormRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setName(value);
        break;

      case 2:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setFormBuildId(value);
        break;

      case 5:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setLogoUri(value);
        break;

      case 6:
        var value = new entities_form_pb.FormStyles();
        reader.readMessage(value, entities_form_pb.FormStyles.deserializeBinaryFromReader);
        msg.setStyles(value);
        break;

      case 7:
        var value = new entities_form_pb.FormPage();
        reader.readMessage(value, entities_form_pb.FormPage.deserializeBinaryFromReader);
        msg.addPages(value);
        break;

      case 8:
        var value =
        /** @type {!proto.e.CompletionStatus} */
        reader.readEnum();
        msg.setCompletionStatus(value);
        break;

      case 9:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setEditorName(value);
        break;

      case 10:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setEditorEmail(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.CreateFormRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.CreateFormRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.CreateFormRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.CreateFormRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getName();

  if (f.length > 0) {
    writer.writeString(1, f);
  }

  f = message.getFormBuildId();

  if (f.length > 0) {
    writer.writeString(2, f);
  }

  f = message.getLogoUri();

  if (f.length > 0) {
    writer.writeString(5, f);
  }

  f = message.getStyles();

  if (f != null) {
    writer.writeMessage(6, f, entities_form_pb.FormStyles.serializeBinaryToWriter);
  }

  f = message.getPagesList();

  if (f.length > 0) {
    writer.writeRepeatedMessage(7, f, entities_form_pb.FormPage.serializeBinaryToWriter);
  }

  f = message.getCompletionStatus();

  if (f !== 0.0) {
    writer.writeEnum(8, f);
  }

  f = message.getEditorName();

  if (f.length > 0) {
    writer.writeString(9, f);
  }

  f = message.getEditorEmail();

  if (f.length > 0) {
    writer.writeString(10, f);
  }
};
/**
 * optional string name = 1;
 * @return {string}
 */


proto.CreateFormRequest.prototype.getName = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 1, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.CreateFormRequest} returns this
 */


proto.CreateFormRequest.prototype.setName = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional string form_build_id = 2;
 * @return {string}
 */


proto.CreateFormRequest.prototype.getFormBuildId = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 2, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.CreateFormRequest} returns this
 */


proto.CreateFormRequest.prototype.setFormBuildId = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};
/**
 * optional string logo_uri = 5;
 * @return {string}
 */


proto.CreateFormRequest.prototype.getLogoUri = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 5, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.CreateFormRequest} returns this
 */


proto.CreateFormRequest.prototype.setLogoUri = function (value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};
/**
 * optional e.FormStyles styles = 6;
 * @return {?proto.e.FormStyles}
 */


proto.CreateFormRequest.prototype.getStyles = function () {
  return (
    /** @type{?proto.e.FormStyles} */
    jspb.Message.getWrapperField(this, entities_form_pb.FormStyles, 6)
  );
};
/**
 * @param {?proto.e.FormStyles|undefined} value
 * @return {!proto.CreateFormRequest} returns this
*/


proto.CreateFormRequest.prototype.setStyles = function (value) {
  return jspb.Message.setWrapperField(this, 6, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.CreateFormRequest} returns this
 */


proto.CreateFormRequest.prototype.clearStyles = function () {
  return this.setStyles(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.CreateFormRequest.prototype.hasStyles = function () {
  return jspb.Message.getField(this, 6) != null;
};
/**
 * repeated e.FormPage pages = 7;
 * @return {!Array<!proto.e.FormPage>}
 */


proto.CreateFormRequest.prototype.getPagesList = function () {
  return (
    /** @type{!Array<!proto.e.FormPage>} */
    jspb.Message.getRepeatedWrapperField(this, entities_form_pb.FormPage, 7)
  );
};
/**
 * @param {!Array<!proto.e.FormPage>} value
 * @return {!proto.CreateFormRequest} returns this
*/


proto.CreateFormRequest.prototype.setPagesList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};
/**
 * @param {!proto.e.FormPage=} opt_value
 * @param {number=} opt_index
 * @return {!proto.e.FormPage}
 */


proto.CreateFormRequest.prototype.addPages = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.e.FormPage, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.CreateFormRequest} returns this
 */


proto.CreateFormRequest.prototype.clearPagesList = function () {
  return this.setPagesList([]);
};
/**
 * optional e.CompletionStatus completion_status = 8;
 * @return {!proto.e.CompletionStatus}
 */


proto.CreateFormRequest.prototype.getCompletionStatus = function () {
  return (
    /** @type {!proto.e.CompletionStatus} */
    jspb.Message.getFieldWithDefault(this, 8, 0)
  );
};
/**
 * @param {!proto.e.CompletionStatus} value
 * @return {!proto.CreateFormRequest} returns this
 */


proto.CreateFormRequest.prototype.setCompletionStatus = function (value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};
/**
 * optional string editor_name = 9;
 * @return {string}
 */


proto.CreateFormRequest.prototype.getEditorName = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 9, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.CreateFormRequest} returns this
 */


proto.CreateFormRequest.prototype.setEditorName = function (value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};
/**
 * optional string editor_email = 10;
 * @return {string}
 */


proto.CreateFormRequest.prototype.getEditorEmail = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 10, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.CreateFormRequest} returns this
 */


proto.CreateFormRequest.prototype.setEditorEmail = function (value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */


proto.UpdateFormRequest.repeatedFields_ = [3];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.UpdateFormRequest.prototype.toObject = function (opt_includeInstance) {
    return proto.UpdateFormRequest.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.UpdateFormRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.UpdateFormRequest.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, ""),
      name: jspb.Message.getFieldWithDefault(msg, 2, ""),
      pagesList: jspb.Message.toObjectList(msg.getPagesList(), entities_form_pb.FormPage.toObject, includeInstance),
      logoUri: jspb.Message.getFieldWithDefault(msg, 4, ""),
      styles: (f = msg.getStyles()) && entities_form_pb.FormStyles.toObject(includeInstance, f),
      completionStatus: jspb.Message.getFieldWithDefault(msg, 6, 0),
      editorName: jspb.Message.getFieldWithDefault(msg, 7, ""),
      editorEmail: jspb.Message.getFieldWithDefault(msg, 8, ""),
      triggerFormAutomation: jspb.Message.getBooleanFieldWithDefault(msg, 9, false)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UpdateFormRequest}
 */


proto.UpdateFormRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UpdateFormRequest();
  return proto.UpdateFormRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UpdateFormRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UpdateFormRequest}
 */


proto.UpdateFormRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setId(value);
        break;

      case 2:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setName(value);
        break;

      case 3:
        var value = new entities_form_pb.FormPage();
        reader.readMessage(value, entities_form_pb.FormPage.deserializeBinaryFromReader);
        msg.addPages(value);
        break;

      case 4:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setLogoUri(value);
        break;

      case 5:
        var value = new entities_form_pb.FormStyles();
        reader.readMessage(value, entities_form_pb.FormStyles.deserializeBinaryFromReader);
        msg.setStyles(value);
        break;

      case 6:
        var value =
        /** @type {!proto.e.CompletionStatus} */
        reader.readEnum();
        msg.setCompletionStatus(value);
        break;

      case 7:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setEditorName(value);
        break;

      case 8:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setEditorEmail(value);
        break;

      case 9:
        var value =
        /** @type {boolean} */
        reader.readBool();
        msg.setTriggerFormAutomation(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.UpdateFormRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.UpdateFormRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UpdateFormRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.UpdateFormRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getId();

  if (f.length > 0) {
    writer.writeString(1, f);
  }

  f = message.getName();

  if (f.length > 0) {
    writer.writeString(2, f);
  }

  f = message.getPagesList();

  if (f.length > 0) {
    writer.writeRepeatedMessage(3, f, entities_form_pb.FormPage.serializeBinaryToWriter);
  }

  f = message.getLogoUri();

  if (f.length > 0) {
    writer.writeString(4, f);
  }

  f = message.getStyles();

  if (f != null) {
    writer.writeMessage(5, f, entities_form_pb.FormStyles.serializeBinaryToWriter);
  }

  f = message.getCompletionStatus();

  if (f !== 0.0) {
    writer.writeEnum(6, f);
  }

  f = message.getEditorName();

  if (f.length > 0) {
    writer.writeString(7, f);
  }

  f = message.getEditorEmail();

  if (f.length > 0) {
    writer.writeString(8, f);
  }

  f = message.getTriggerFormAutomation();

  if (f) {
    writer.writeBool(9, f);
  }
};
/**
 * optional string id = 1;
 * @return {string}
 */


proto.UpdateFormRequest.prototype.getId = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 1, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.UpdateFormRequest} returns this
 */


proto.UpdateFormRequest.prototype.setId = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional string name = 2;
 * @return {string}
 */


proto.UpdateFormRequest.prototype.getName = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 2, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.UpdateFormRequest} returns this
 */


proto.UpdateFormRequest.prototype.setName = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};
/**
 * repeated e.FormPage pages = 3;
 * @return {!Array<!proto.e.FormPage>}
 */


proto.UpdateFormRequest.prototype.getPagesList = function () {
  return (
    /** @type{!Array<!proto.e.FormPage>} */
    jspb.Message.getRepeatedWrapperField(this, entities_form_pb.FormPage, 3)
  );
};
/**
 * @param {!Array<!proto.e.FormPage>} value
 * @return {!proto.UpdateFormRequest} returns this
*/


proto.UpdateFormRequest.prototype.setPagesList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};
/**
 * @param {!proto.e.FormPage=} opt_value
 * @param {number=} opt_index
 * @return {!proto.e.FormPage}
 */


proto.UpdateFormRequest.prototype.addPages = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.e.FormPage, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.UpdateFormRequest} returns this
 */


proto.UpdateFormRequest.prototype.clearPagesList = function () {
  return this.setPagesList([]);
};
/**
 * optional string logo_uri = 4;
 * @return {string}
 */


proto.UpdateFormRequest.prototype.getLogoUri = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 4, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.UpdateFormRequest} returns this
 */


proto.UpdateFormRequest.prototype.setLogoUri = function (value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};
/**
 * optional e.FormStyles styles = 5;
 * @return {?proto.e.FormStyles}
 */


proto.UpdateFormRequest.prototype.getStyles = function () {
  return (
    /** @type{?proto.e.FormStyles} */
    jspb.Message.getWrapperField(this, entities_form_pb.FormStyles, 5)
  );
};
/**
 * @param {?proto.e.FormStyles|undefined} value
 * @return {!proto.UpdateFormRequest} returns this
*/


proto.UpdateFormRequest.prototype.setStyles = function (value) {
  return jspb.Message.setWrapperField(this, 5, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.UpdateFormRequest} returns this
 */


proto.UpdateFormRequest.prototype.clearStyles = function () {
  return this.setStyles(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.UpdateFormRequest.prototype.hasStyles = function () {
  return jspb.Message.getField(this, 5) != null;
};
/**
 * optional e.CompletionStatus completion_status = 6;
 * @return {!proto.e.CompletionStatus}
 */


proto.UpdateFormRequest.prototype.getCompletionStatus = function () {
  return (
    /** @type {!proto.e.CompletionStatus} */
    jspb.Message.getFieldWithDefault(this, 6, 0)
  );
};
/**
 * @param {!proto.e.CompletionStatus} value
 * @return {!proto.UpdateFormRequest} returns this
 */


proto.UpdateFormRequest.prototype.setCompletionStatus = function (value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};
/**
 * optional string editor_name = 7;
 * @return {string}
 */


proto.UpdateFormRequest.prototype.getEditorName = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 7, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.UpdateFormRequest} returns this
 */


proto.UpdateFormRequest.prototype.setEditorName = function (value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};
/**
 * optional string editor_email = 8;
 * @return {string}
 */


proto.UpdateFormRequest.prototype.getEditorEmail = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 8, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.UpdateFormRequest} returns this
 */


proto.UpdateFormRequest.prototype.setEditorEmail = function (value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};
/**
 * optional bool trigger_form_automation = 9;
 * @return {boolean}
 */


proto.UpdateFormRequest.prototype.getTriggerFormAutomation = function () {
  return (
    /** @type {boolean} */
    jspb.Message.getBooleanFieldWithDefault(this, 9, false)
  );
};
/**
 * @param {boolean} value
 * @return {!proto.UpdateFormRequest} returns this
 */


proto.UpdateFormRequest.prototype.setTriggerFormAutomation = function (value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.ConvertFormToFormBuildRequest.prototype.toObject = function (opt_includeInstance) {
    return proto.ConvertFormToFormBuildRequest.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.ConvertFormToFormBuildRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.ConvertFormToFormBuildRequest.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ConvertFormToFormBuildRequest}
 */


proto.ConvertFormToFormBuildRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ConvertFormToFormBuildRequest();
  return proto.ConvertFormToFormBuildRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ConvertFormToFormBuildRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ConvertFormToFormBuildRequest}
 */


proto.ConvertFormToFormBuildRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setId(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.ConvertFormToFormBuildRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.ConvertFormToFormBuildRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ConvertFormToFormBuildRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.ConvertFormToFormBuildRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getId();

  if (f.length > 0) {
    writer.writeString(1, f);
  }
};
/**
 * optional string id = 1;
 * @return {string}
 */


proto.ConvertFormToFormBuildRequest.prototype.getId = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 1, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.ConvertFormToFormBuildRequest} returns this
 */


proto.ConvertFormToFormBuildRequest.prototype.setId = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.ConvertFormToFormBuildResponse.prototype.toObject = function (opt_includeInstance) {
    return proto.ConvertFormToFormBuildResponse.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.ConvertFormToFormBuildResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.ConvertFormToFormBuildResponse.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      formBuild: (f = msg.getFormBuild()) && entities_form_pb.FormBuild.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ConvertFormToFormBuildResponse}
 */


proto.ConvertFormToFormBuildResponse.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ConvertFormToFormBuildResponse();
  return proto.ConvertFormToFormBuildResponse.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ConvertFormToFormBuildResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ConvertFormToFormBuildResponse}
 */


proto.ConvertFormToFormBuildResponse.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value = new entities_form_pb.FormBuild();
        reader.readMessage(value, entities_form_pb.FormBuild.deserializeBinaryFromReader);
        msg.setFormBuild(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.ConvertFormToFormBuildResponse.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.ConvertFormToFormBuildResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ConvertFormToFormBuildResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.ConvertFormToFormBuildResponse.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getFormBuild();

  if (f != null) {
    writer.writeMessage(1, f, entities_form_pb.FormBuild.serializeBinaryToWriter);
  }
};
/**
 * optional e.FormBuild form_build = 1;
 * @return {?proto.e.FormBuild}
 */


proto.ConvertFormToFormBuildResponse.prototype.getFormBuild = function () {
  return (
    /** @type{?proto.e.FormBuild} */
    jspb.Message.getWrapperField(this, entities_form_pb.FormBuild, 1)
  );
};
/**
 * @param {?proto.e.FormBuild|undefined} value
 * @return {!proto.ConvertFormToFormBuildResponse} returns this
*/


proto.ConvertFormToFormBuildResponse.prototype.setFormBuild = function (value) {
  return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.ConvertFormToFormBuildResponse} returns this
 */


proto.ConvertFormToFormBuildResponse.prototype.clearFormBuild = function () {
  return this.setFormBuild(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.ConvertFormToFormBuildResponse.prototype.hasFormBuild = function () {
  return jspb.Message.getField(this, 1) != null;
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.DeleteFormRequest.prototype.toObject = function (opt_includeInstance) {
    return proto.DeleteFormRequest.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.DeleteFormRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.DeleteFormRequest.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, ""),
      editorName: jspb.Message.getFieldWithDefault(msg, 2, ""),
      editorEmail: jspb.Message.getFieldWithDefault(msg, 3, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.DeleteFormRequest}
 */


proto.DeleteFormRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.DeleteFormRequest();
  return proto.DeleteFormRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.DeleteFormRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.DeleteFormRequest}
 */


proto.DeleteFormRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setId(value);
        break;

      case 2:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setEditorName(value);
        break;

      case 3:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setEditorEmail(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.DeleteFormRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.DeleteFormRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.DeleteFormRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.DeleteFormRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getId();

  if (f.length > 0) {
    writer.writeString(1, f);
  }

  f = message.getEditorName();

  if (f.length > 0) {
    writer.writeString(2, f);
  }

  f = message.getEditorEmail();

  if (f.length > 0) {
    writer.writeString(3, f);
  }
};
/**
 * optional string id = 1;
 * @return {string}
 */


proto.DeleteFormRequest.prototype.getId = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 1, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.DeleteFormRequest} returns this
 */


proto.DeleteFormRequest.prototype.setId = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional string editor_name = 2;
 * @return {string}
 */


proto.DeleteFormRequest.prototype.getEditorName = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 2, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.DeleteFormRequest} returns this
 */


proto.DeleteFormRequest.prototype.setEditorName = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};
/**
 * optional string editor_email = 3;
 * @return {string}
 */


proto.DeleteFormRequest.prototype.getEditorEmail = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 3, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.DeleteFormRequest} returns this
 */


proto.DeleteFormRequest.prototype.setEditorEmail = function (value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.ListFormElementTemplatesRequest.prototype.toObject = function (opt_includeInstance) {
    return proto.ListFormElementTemplatesRequest.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.ListFormElementTemplatesRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.ListFormElementTemplatesRequest.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      elementType: jspb.Message.getFieldWithDefault(msg, 1, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ListFormElementTemplatesRequest}
 */


proto.ListFormElementTemplatesRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ListFormElementTemplatesRequest();
  return proto.ListFormElementTemplatesRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ListFormElementTemplatesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ListFormElementTemplatesRequest}
 */


proto.ListFormElementTemplatesRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value =
        /** @type {!proto.e.FormElementType} */
        reader.readEnum();
        msg.setElementType(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.ListFormElementTemplatesRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.ListFormElementTemplatesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ListFormElementTemplatesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.ListFormElementTemplatesRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getElementType();

  if (f !== 0.0) {
    writer.writeEnum(1, f);
  }
};
/**
 * optional e.FormElementType element_type = 1;
 * @return {!proto.e.FormElementType}
 */


proto.ListFormElementTemplatesRequest.prototype.getElementType = function () {
  return (
    /** @type {!proto.e.FormElementType} */
    jspb.Message.getFieldWithDefault(this, 1, 0)
  );
};
/**
 * @param {!proto.e.FormElementType} value
 * @return {!proto.ListFormElementTemplatesRequest} returns this
 */


proto.ListFormElementTemplatesRequest.prototype.setElementType = function (value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */


proto.ListFormElementTemplatesResponse.repeatedFields_ = [1];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.ListFormElementTemplatesResponse.prototype.toObject = function (opt_includeInstance) {
    return proto.ListFormElementTemplatesResponse.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.ListFormElementTemplatesResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.ListFormElementTemplatesResponse.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      formElementTemplatesList: jspb.Message.toObjectList(msg.getFormElementTemplatesList(), entities_form_pb.FormElementTemplate.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ListFormElementTemplatesResponse}
 */


proto.ListFormElementTemplatesResponse.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ListFormElementTemplatesResponse();
  return proto.ListFormElementTemplatesResponse.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ListFormElementTemplatesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ListFormElementTemplatesResponse}
 */


proto.ListFormElementTemplatesResponse.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value = new entities_form_pb.FormElementTemplate();
        reader.readMessage(value, entities_form_pb.FormElementTemplate.deserializeBinaryFromReader);
        msg.addFormElementTemplates(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.ListFormElementTemplatesResponse.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.ListFormElementTemplatesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ListFormElementTemplatesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.ListFormElementTemplatesResponse.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getFormElementTemplatesList();

  if (f.length > 0) {
    writer.writeRepeatedMessage(1, f, entities_form_pb.FormElementTemplate.serializeBinaryToWriter);
  }
};
/**
 * repeated e.FormElementTemplate form_element_templates = 1;
 * @return {!Array<!proto.e.FormElementTemplate>}
 */


proto.ListFormElementTemplatesResponse.prototype.getFormElementTemplatesList = function () {
  return (
    /** @type{!Array<!proto.e.FormElementTemplate>} */
    jspb.Message.getRepeatedWrapperField(this, entities_form_pb.FormElementTemplate, 1)
  );
};
/**
 * @param {!Array<!proto.e.FormElementTemplate>} value
 * @return {!proto.ListFormElementTemplatesResponse} returns this
*/


proto.ListFormElementTemplatesResponse.prototype.setFormElementTemplatesList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};
/**
 * @param {!proto.e.FormElementTemplate=} opt_value
 * @param {number=} opt_index
 * @return {!proto.e.FormElementTemplate}
 */


proto.ListFormElementTemplatesResponse.prototype.addFormElementTemplates = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.e.FormElementTemplate, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ListFormElementTemplatesResponse} returns this
 */


proto.ListFormElementTemplatesResponse.prototype.clearFormElementTemplatesList = function () {
  return this.setFormElementTemplatesList([]);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.CreateFormElementTemplateRequest.prototype.toObject = function (opt_includeInstance) {
    return proto.CreateFormElementTemplateRequest.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.CreateFormElementTemplateRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.CreateFormElementTemplateRequest.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      name: jspb.Message.getFieldWithDefault(msg, 1, ""),
      description: jspb.Message.getFieldWithDefault(msg, 2, ""),
      formElement: (f = msg.getFormElement()) && entities_form_pb.FormElement.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.CreateFormElementTemplateRequest}
 */


proto.CreateFormElementTemplateRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.CreateFormElementTemplateRequest();
  return proto.CreateFormElementTemplateRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.CreateFormElementTemplateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.CreateFormElementTemplateRequest}
 */


proto.CreateFormElementTemplateRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setName(value);
        break;

      case 2:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setDescription(value);
        break;

      case 3:
        var value = new entities_form_pb.FormElement();
        reader.readMessage(value, entities_form_pb.FormElement.deserializeBinaryFromReader);
        msg.setFormElement(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.CreateFormElementTemplateRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.CreateFormElementTemplateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.CreateFormElementTemplateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.CreateFormElementTemplateRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getName();

  if (f.length > 0) {
    writer.writeString(1, f);
  }

  f = message.getDescription();

  if (f.length > 0) {
    writer.writeString(2, f);
  }

  f = message.getFormElement();

  if (f != null) {
    writer.writeMessage(3, f, entities_form_pb.FormElement.serializeBinaryToWriter);
  }
};
/**
 * optional string name = 1;
 * @return {string}
 */


proto.CreateFormElementTemplateRequest.prototype.getName = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 1, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.CreateFormElementTemplateRequest} returns this
 */


proto.CreateFormElementTemplateRequest.prototype.setName = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional string description = 2;
 * @return {string}
 */


proto.CreateFormElementTemplateRequest.prototype.getDescription = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 2, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.CreateFormElementTemplateRequest} returns this
 */


proto.CreateFormElementTemplateRequest.prototype.setDescription = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};
/**
 * optional e.FormElement form_element = 3;
 * @return {?proto.e.FormElement}
 */


proto.CreateFormElementTemplateRequest.prototype.getFormElement = function () {
  return (
    /** @type{?proto.e.FormElement} */
    jspb.Message.getWrapperField(this, entities_form_pb.FormElement, 3)
  );
};
/**
 * @param {?proto.e.FormElement|undefined} value
 * @return {!proto.CreateFormElementTemplateRequest} returns this
*/


proto.CreateFormElementTemplateRequest.prototype.setFormElement = function (value) {
  return jspb.Message.setWrapperField(this, 3, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.CreateFormElementTemplateRequest} returns this
 */


proto.CreateFormElementTemplateRequest.prototype.clearFormElement = function () {
  return this.setFormElement(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.CreateFormElementTemplateRequest.prototype.hasFormElement = function () {
  return jspb.Message.getField(this, 3) != null;
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.UpdateFormElementTemplateRequest.prototype.toObject = function (opt_includeInstance) {
    return proto.UpdateFormElementTemplateRequest.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.UpdateFormElementTemplateRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.UpdateFormElementTemplateRequest.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, ""),
      name: jspb.Message.getFieldWithDefault(msg, 2, ""),
      description: jspb.Message.getFieldWithDefault(msg, 3, ""),
      formElement: (f = msg.getFormElement()) && entities_form_pb.FormElement.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UpdateFormElementTemplateRequest}
 */


proto.UpdateFormElementTemplateRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UpdateFormElementTemplateRequest();
  return proto.UpdateFormElementTemplateRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UpdateFormElementTemplateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UpdateFormElementTemplateRequest}
 */


proto.UpdateFormElementTemplateRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setId(value);
        break;

      case 2:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setName(value);
        break;

      case 3:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setDescription(value);
        break;

      case 4:
        var value = new entities_form_pb.FormElement();
        reader.readMessage(value, entities_form_pb.FormElement.deserializeBinaryFromReader);
        msg.setFormElement(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.UpdateFormElementTemplateRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.UpdateFormElementTemplateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UpdateFormElementTemplateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.UpdateFormElementTemplateRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getId();

  if (f.length > 0) {
    writer.writeString(1, f);
  }

  f = message.getName();

  if (f.length > 0) {
    writer.writeString(2, f);
  }

  f = message.getDescription();

  if (f.length > 0) {
    writer.writeString(3, f);
  }

  f = message.getFormElement();

  if (f != null) {
    writer.writeMessage(4, f, entities_form_pb.FormElement.serializeBinaryToWriter);
  }
};
/**
 * optional string id = 1;
 * @return {string}
 */


proto.UpdateFormElementTemplateRequest.prototype.getId = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 1, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.UpdateFormElementTemplateRequest} returns this
 */


proto.UpdateFormElementTemplateRequest.prototype.setId = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional string name = 2;
 * @return {string}
 */


proto.UpdateFormElementTemplateRequest.prototype.getName = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 2, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.UpdateFormElementTemplateRequest} returns this
 */


proto.UpdateFormElementTemplateRequest.prototype.setName = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};
/**
 * optional string description = 3;
 * @return {string}
 */


proto.UpdateFormElementTemplateRequest.prototype.getDescription = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 3, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.UpdateFormElementTemplateRequest} returns this
 */


proto.UpdateFormElementTemplateRequest.prototype.setDescription = function (value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};
/**
 * optional e.FormElement form_element = 4;
 * @return {?proto.e.FormElement}
 */


proto.UpdateFormElementTemplateRequest.prototype.getFormElement = function () {
  return (
    /** @type{?proto.e.FormElement} */
    jspb.Message.getWrapperField(this, entities_form_pb.FormElement, 4)
  );
};
/**
 * @param {?proto.e.FormElement|undefined} value
 * @return {!proto.UpdateFormElementTemplateRequest} returns this
*/


proto.UpdateFormElementTemplateRequest.prototype.setFormElement = function (value) {
  return jspb.Message.setWrapperField(this, 4, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.UpdateFormElementTemplateRequest} returns this
 */


proto.UpdateFormElementTemplateRequest.prototype.clearFormElement = function () {
  return this.setFormElement(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.UpdateFormElementTemplateRequest.prototype.hasFormElement = function () {
  return jspb.Message.getField(this, 4) != null;
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.DeleteFormElementTemplateRequest.prototype.toObject = function (opt_includeInstance) {
    return proto.DeleteFormElementTemplateRequest.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.DeleteFormElementTemplateRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.DeleteFormElementTemplateRequest.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.DeleteFormElementTemplateRequest}
 */


proto.DeleteFormElementTemplateRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.DeleteFormElementTemplateRequest();
  return proto.DeleteFormElementTemplateRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.DeleteFormElementTemplateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.DeleteFormElementTemplateRequest}
 */


proto.DeleteFormElementTemplateRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setId(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.DeleteFormElementTemplateRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.DeleteFormElementTemplateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.DeleteFormElementTemplateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.DeleteFormElementTemplateRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getId();

  if (f.length > 0) {
    writer.writeString(1, f);
  }
};
/**
 * optional string id = 1;
 * @return {string}
 */


proto.DeleteFormElementTemplateRequest.prototype.getId = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 1, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.DeleteFormElementTemplateRequest} returns this
 */


proto.DeleteFormElementTemplateRequest.prototype.setId = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */


proto.ReceiveEndUserFormRequest.repeatedFields_ = [3];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.ReceiveEndUserFormRequest.prototype.toObject = function (opt_includeInstance) {
    return proto.ReceiveEndUserFormRequest.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.ReceiveEndUserFormRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.ReceiveEndUserFormRequest.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      formId: jspb.Message.getFieldWithDefault(msg, 1, ""),
      endUserId: jspb.Message.getFieldWithDefault(msg, 2, 0),
      rowsList: jspb.Message.toObjectList(msg.getRowsList(), entities_form_pb.FormRow.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ReceiveEndUserFormRequest}
 */


proto.ReceiveEndUserFormRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ReceiveEndUserFormRequest();
  return proto.ReceiveEndUserFormRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ReceiveEndUserFormRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ReceiveEndUserFormRequest}
 */


proto.ReceiveEndUserFormRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setFormId(value);
        break;

      case 2:
        var value =
        /** @type {number} */
        reader.readInt64();
        msg.setEndUserId(value);
        break;

      case 3:
        var value = new entities_form_pb.FormRow();
        reader.readMessage(value, entities_form_pb.FormRow.deserializeBinaryFromReader);
        msg.addRows(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.ReceiveEndUserFormRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.ReceiveEndUserFormRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ReceiveEndUserFormRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.ReceiveEndUserFormRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getFormId();

  if (f.length > 0) {
    writer.writeString(1, f);
  }

  f = message.getEndUserId();

  if (f !== 0) {
    writer.writeInt64(2, f);
  }

  f = message.getRowsList();

  if (f.length > 0) {
    writer.writeRepeatedMessage(3, f, entities_form_pb.FormRow.serializeBinaryToWriter);
  }
};
/**
 * optional string form_id = 1;
 * @return {string}
 */


proto.ReceiveEndUserFormRequest.prototype.getFormId = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 1, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.ReceiveEndUserFormRequest} returns this
 */


proto.ReceiveEndUserFormRequest.prototype.setFormId = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional int64 end_user_id = 2;
 * @return {number}
 */


proto.ReceiveEndUserFormRequest.prototype.getEndUserId = function () {
  return (
    /** @type {number} */
    jspb.Message.getFieldWithDefault(this, 2, 0)
  );
};
/**
 * @param {number} value
 * @return {!proto.ReceiveEndUserFormRequest} returns this
 */


proto.ReceiveEndUserFormRequest.prototype.setEndUserId = function (value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};
/**
 * repeated e.FormRow rows = 3;
 * @return {!Array<!proto.e.FormRow>}
 */


proto.ReceiveEndUserFormRequest.prototype.getRowsList = function () {
  return (
    /** @type{!Array<!proto.e.FormRow>} */
    jspb.Message.getRepeatedWrapperField(this, entities_form_pb.FormRow, 3)
  );
};
/**
 * @param {!Array<!proto.e.FormRow>} value
 * @return {!proto.ReceiveEndUserFormRequest} returns this
*/


proto.ReceiveEndUserFormRequest.prototype.setRowsList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};
/**
 * @param {!proto.e.FormRow=} opt_value
 * @param {number=} opt_index
 * @return {!proto.e.FormRow}
 */


proto.ReceiveEndUserFormRequest.prototype.addRows = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.e.FormRow, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ReceiveEndUserFormRequest} returns this
 */


proto.ReceiveEndUserFormRequest.prototype.clearRowsList = function () {
  return this.setRowsList([]);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.GetEndUserFormRequest.prototype.toObject = function (opt_includeInstance) {
    return proto.GetEndUserFormRequest.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.GetEndUserFormRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.GetEndUserFormRequest.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      formId: jspb.Message.getFieldWithDefault(msg, 1, ""),
      endUserId: jspb.Message.getFieldWithDefault(msg, 2, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GetEndUserFormRequest}
 */


proto.GetEndUserFormRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GetEndUserFormRequest();
  return proto.GetEndUserFormRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GetEndUserFormRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GetEndUserFormRequest}
 */


proto.GetEndUserFormRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setFormId(value);
        break;

      case 2:
        var value =
        /** @type {number} */
        reader.readInt64();
        msg.setEndUserId(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.GetEndUserFormRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.GetEndUserFormRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GetEndUserFormRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.GetEndUserFormRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getFormId();

  if (f.length > 0) {
    writer.writeString(1, f);
  }

  f = message.getEndUserId();

  if (f !== 0) {
    writer.writeInt64(2, f);
  }
};
/**
 * optional string form_id = 1;
 * @return {string}
 */


proto.GetEndUserFormRequest.prototype.getFormId = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 1, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.GetEndUserFormRequest} returns this
 */


proto.GetEndUserFormRequest.prototype.setFormId = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional int64 end_user_id = 2;
 * @return {number}
 */


proto.GetEndUserFormRequest.prototype.getEndUserId = function () {
  return (
    /** @type {number} */
    jspb.Message.getFieldWithDefault(this, 2, 0)
  );
};
/**
 * @param {number} value
 * @return {!proto.GetEndUserFormRequest} returns this
 */


proto.GetEndUserFormRequest.prototype.setEndUserId = function (value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.ListFormElementValidationsRequest.prototype.toObject = function (opt_includeInstance) {
    return proto.ListFormElementValidationsRequest.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.ListFormElementValidationsRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.ListFormElementValidationsRequest.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      type: jspb.Message.getFieldWithDefault(msg, 1, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ListFormElementValidationsRequest}
 */


proto.ListFormElementValidationsRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ListFormElementValidationsRequest();
  return proto.ListFormElementValidationsRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ListFormElementValidationsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ListFormElementValidationsRequest}
 */


proto.ListFormElementValidationsRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value =
        /** @type {!proto.e.FormElementType} */
        reader.readEnum();
        msg.setType(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.ListFormElementValidationsRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.ListFormElementValidationsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ListFormElementValidationsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.ListFormElementValidationsRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getType();

  if (f !== 0.0) {
    writer.writeEnum(1, f);
  }
};
/**
 * optional e.FormElementType type = 1;
 * @return {!proto.e.FormElementType}
 */


proto.ListFormElementValidationsRequest.prototype.getType = function () {
  return (
    /** @type {!proto.e.FormElementType} */
    jspb.Message.getFieldWithDefault(this, 1, 0)
  );
};
/**
 * @param {!proto.e.FormElementType} value
 * @return {!proto.ListFormElementValidationsRequest} returns this
 */


proto.ListFormElementValidationsRequest.prototype.setType = function (value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */


proto.ListFormElementValidationsResponse.repeatedFields_ = [1];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.ListFormElementValidationsResponse.prototype.toObject = function (opt_includeInstance) {
    return proto.ListFormElementValidationsResponse.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.ListFormElementValidationsResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.ListFormElementValidationsResponse.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      formElementValidationsList: jspb.Message.toObjectList(msg.getFormElementValidationsList(), entities_form_pb.FormElementValidation.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ListFormElementValidationsResponse}
 */


proto.ListFormElementValidationsResponse.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ListFormElementValidationsResponse();
  return proto.ListFormElementValidationsResponse.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ListFormElementValidationsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ListFormElementValidationsResponse}
 */


proto.ListFormElementValidationsResponse.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value = new entities_form_pb.FormElementValidation();
        reader.readMessage(value, entities_form_pb.FormElementValidation.deserializeBinaryFromReader);
        msg.addFormElementValidations(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.ListFormElementValidationsResponse.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.ListFormElementValidationsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ListFormElementValidationsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.ListFormElementValidationsResponse.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getFormElementValidationsList();

  if (f.length > 0) {
    writer.writeRepeatedMessage(1, f, entities_form_pb.FormElementValidation.serializeBinaryToWriter);
  }
};
/**
 * repeated e.FormElementValidation form_element_validations = 1;
 * @return {!Array<!proto.e.FormElementValidation>}
 */


proto.ListFormElementValidationsResponse.prototype.getFormElementValidationsList = function () {
  return (
    /** @type{!Array<!proto.e.FormElementValidation>} */
    jspb.Message.getRepeatedWrapperField(this, entities_form_pb.FormElementValidation, 1)
  );
};
/**
 * @param {!Array<!proto.e.FormElementValidation>} value
 * @return {!proto.ListFormElementValidationsResponse} returns this
*/


proto.ListFormElementValidationsResponse.prototype.setFormElementValidationsList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};
/**
 * @param {!proto.e.FormElementValidation=} opt_value
 * @param {number=} opt_index
 * @return {!proto.e.FormElementValidation}
 */


proto.ListFormElementValidationsResponse.prototype.addFormElementValidations = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.e.FormElementValidation, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ListFormElementValidationsResponse} returns this
 */


proto.ListFormElementValidationsResponse.prototype.clearFormElementValidationsList = function () {
  return this.setFormElementValidationsList([]);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.GetFormElementValidationRequest.prototype.toObject = function (opt_includeInstance) {
    return proto.GetFormElementValidationRequest.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.GetFormElementValidationRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.GetFormElementValidationRequest.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GetFormElementValidationRequest}
 */


proto.GetFormElementValidationRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GetFormElementValidationRequest();
  return proto.GetFormElementValidationRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GetFormElementValidationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GetFormElementValidationRequest}
 */


proto.GetFormElementValidationRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setId(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.GetFormElementValidationRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.GetFormElementValidationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GetFormElementValidationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.GetFormElementValidationRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getId();

  if (f.length > 0) {
    writer.writeString(1, f);
  }
};
/**
 * optional string id = 1;
 * @return {string}
 */


proto.GetFormElementValidationRequest.prototype.getId = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 1, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.GetFormElementValidationRequest} returns this
 */


proto.GetFormElementValidationRequest.prototype.setId = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */


proto.CreateFormElementValidationRequest.oneofGroups_ = [[16, 17]];
/**
 * @enum {number}
 */

proto.CreateFormElementValidationRequest.ValidationCase = {
  VALIDATION_NOT_SET: 0,
  TEXT_VALIDATION: 16,
  NUMERIC_VALIDATION: 17
};
/**
 * @return {proto.CreateFormElementValidationRequest.ValidationCase}
 */

proto.CreateFormElementValidationRequest.prototype.getValidationCase = function () {
  return (
    /** @type {proto.CreateFormElementValidationRequest.ValidationCase} */
    jspb.Message.computeOneofCase(this, proto.CreateFormElementValidationRequest.oneofGroups_[0])
  );
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.CreateFormElementValidationRequest.prototype.toObject = function (opt_includeInstance) {
    return proto.CreateFormElementValidationRequest.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.CreateFormElementValidationRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.CreateFormElementValidationRequest.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      type: jspb.Message.getFieldWithDefault(msg, 1, 0),
      name: jspb.Message.getFieldWithDefault(msg, 2, ""),
      textValidation: (f = msg.getTextValidation()) && entities_form_pb.FormTextValidation.toObject(includeInstance, f),
      numericValidation: (f = msg.getNumericValidation()) && entities_form_pb.FormNumericValidation.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.CreateFormElementValidationRequest}
 */


proto.CreateFormElementValidationRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.CreateFormElementValidationRequest();
  return proto.CreateFormElementValidationRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.CreateFormElementValidationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.CreateFormElementValidationRequest}
 */


proto.CreateFormElementValidationRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value =
        /** @type {!proto.e.FormElementType} */
        reader.readEnum();
        msg.setType(value);
        break;

      case 2:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setName(value);
        break;

      case 16:
        var value = new entities_form_pb.FormTextValidation();
        reader.readMessage(value, entities_form_pb.FormTextValidation.deserializeBinaryFromReader);
        msg.setTextValidation(value);
        break;

      case 17:
        var value = new entities_form_pb.FormNumericValidation();
        reader.readMessage(value, entities_form_pb.FormNumericValidation.deserializeBinaryFromReader);
        msg.setNumericValidation(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.CreateFormElementValidationRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.CreateFormElementValidationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.CreateFormElementValidationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.CreateFormElementValidationRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getType();

  if (f !== 0.0) {
    writer.writeEnum(1, f);
  }

  f = message.getName();

  if (f.length > 0) {
    writer.writeString(2, f);
  }

  f = message.getTextValidation();

  if (f != null) {
    writer.writeMessage(16, f, entities_form_pb.FormTextValidation.serializeBinaryToWriter);
  }

  f = message.getNumericValidation();

  if (f != null) {
    writer.writeMessage(17, f, entities_form_pb.FormNumericValidation.serializeBinaryToWriter);
  }
};
/**
 * optional e.FormElementType type = 1;
 * @return {!proto.e.FormElementType}
 */


proto.CreateFormElementValidationRequest.prototype.getType = function () {
  return (
    /** @type {!proto.e.FormElementType} */
    jspb.Message.getFieldWithDefault(this, 1, 0)
  );
};
/**
 * @param {!proto.e.FormElementType} value
 * @return {!proto.CreateFormElementValidationRequest} returns this
 */


proto.CreateFormElementValidationRequest.prototype.setType = function (value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};
/**
 * optional string name = 2;
 * @return {string}
 */


proto.CreateFormElementValidationRequest.prototype.getName = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 2, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.CreateFormElementValidationRequest} returns this
 */


proto.CreateFormElementValidationRequest.prototype.setName = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};
/**
 * optional e.FormTextValidation text_validation = 16;
 * @return {?proto.e.FormTextValidation}
 */


proto.CreateFormElementValidationRequest.prototype.getTextValidation = function () {
  return (
    /** @type{?proto.e.FormTextValidation} */
    jspb.Message.getWrapperField(this, entities_form_pb.FormTextValidation, 16)
  );
};
/**
 * @param {?proto.e.FormTextValidation|undefined} value
 * @return {!proto.CreateFormElementValidationRequest} returns this
*/


proto.CreateFormElementValidationRequest.prototype.setTextValidation = function (value) {
  return jspb.Message.setOneofWrapperField(this, 16, proto.CreateFormElementValidationRequest.oneofGroups_[0], value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.CreateFormElementValidationRequest} returns this
 */


proto.CreateFormElementValidationRequest.prototype.clearTextValidation = function () {
  return this.setTextValidation(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.CreateFormElementValidationRequest.prototype.hasTextValidation = function () {
  return jspb.Message.getField(this, 16) != null;
};
/**
 * optional e.FormNumericValidation numeric_validation = 17;
 * @return {?proto.e.FormNumericValidation}
 */


proto.CreateFormElementValidationRequest.prototype.getNumericValidation = function () {
  return (
    /** @type{?proto.e.FormNumericValidation} */
    jspb.Message.getWrapperField(this, entities_form_pb.FormNumericValidation, 17)
  );
};
/**
 * @param {?proto.e.FormNumericValidation|undefined} value
 * @return {!proto.CreateFormElementValidationRequest} returns this
*/


proto.CreateFormElementValidationRequest.prototype.setNumericValidation = function (value) {
  return jspb.Message.setOneofWrapperField(this, 17, proto.CreateFormElementValidationRequest.oneofGroups_[0], value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.CreateFormElementValidationRequest} returns this
 */


proto.CreateFormElementValidationRequest.prototype.clearNumericValidation = function () {
  return this.setNumericValidation(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.CreateFormElementValidationRequest.prototype.hasNumericValidation = function () {
  return jspb.Message.getField(this, 17) != null;
};
/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */


proto.UpdateFormElementValidationRequest.oneofGroups_ = [[16, 17]];
/**
 * @enum {number}
 */

proto.UpdateFormElementValidationRequest.ValidationCase = {
  VALIDATION_NOT_SET: 0,
  TEXT_VALIDATION: 16,
  NUMERIC_VALIDATION: 17
};
/**
 * @return {proto.UpdateFormElementValidationRequest.ValidationCase}
 */

proto.UpdateFormElementValidationRequest.prototype.getValidationCase = function () {
  return (
    /** @type {proto.UpdateFormElementValidationRequest.ValidationCase} */
    jspb.Message.computeOneofCase(this, proto.UpdateFormElementValidationRequest.oneofGroups_[0])
  );
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.UpdateFormElementValidationRequest.prototype.toObject = function (opt_includeInstance) {
    return proto.UpdateFormElementValidationRequest.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.UpdateFormElementValidationRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.UpdateFormElementValidationRequest.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, ""),
      name: jspb.Message.getFieldWithDefault(msg, 2, ""),
      type: jspb.Message.getFieldWithDefault(msg, 3, 0),
      textValidation: (f = msg.getTextValidation()) && entities_form_pb.FormTextValidation.toObject(includeInstance, f),
      numericValidation: (f = msg.getNumericValidation()) && entities_form_pb.FormNumericValidation.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UpdateFormElementValidationRequest}
 */


proto.UpdateFormElementValidationRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UpdateFormElementValidationRequest();
  return proto.UpdateFormElementValidationRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UpdateFormElementValidationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UpdateFormElementValidationRequest}
 */


proto.UpdateFormElementValidationRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setId(value);
        break;

      case 2:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setName(value);
        break;

      case 3:
        var value =
        /** @type {!proto.e.FormElementType} */
        reader.readEnum();
        msg.setType(value);
        break;

      case 16:
        var value = new entities_form_pb.FormTextValidation();
        reader.readMessage(value, entities_form_pb.FormTextValidation.deserializeBinaryFromReader);
        msg.setTextValidation(value);
        break;

      case 17:
        var value = new entities_form_pb.FormNumericValidation();
        reader.readMessage(value, entities_form_pb.FormNumericValidation.deserializeBinaryFromReader);
        msg.setNumericValidation(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.UpdateFormElementValidationRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.UpdateFormElementValidationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UpdateFormElementValidationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.UpdateFormElementValidationRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getId();

  if (f.length > 0) {
    writer.writeString(1, f);
  }

  f = message.getName();

  if (f.length > 0) {
    writer.writeString(2, f);
  }

  f = message.getType();

  if (f !== 0.0) {
    writer.writeEnum(3, f);
  }

  f = message.getTextValidation();

  if (f != null) {
    writer.writeMessage(16, f, entities_form_pb.FormTextValidation.serializeBinaryToWriter);
  }

  f = message.getNumericValidation();

  if (f != null) {
    writer.writeMessage(17, f, entities_form_pb.FormNumericValidation.serializeBinaryToWriter);
  }
};
/**
 * optional string id = 1;
 * @return {string}
 */


proto.UpdateFormElementValidationRequest.prototype.getId = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 1, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.UpdateFormElementValidationRequest} returns this
 */


proto.UpdateFormElementValidationRequest.prototype.setId = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional string name = 2;
 * @return {string}
 */


proto.UpdateFormElementValidationRequest.prototype.getName = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 2, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.UpdateFormElementValidationRequest} returns this
 */


proto.UpdateFormElementValidationRequest.prototype.setName = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};
/**
 * optional e.FormElementType type = 3;
 * @return {!proto.e.FormElementType}
 */


proto.UpdateFormElementValidationRequest.prototype.getType = function () {
  return (
    /** @type {!proto.e.FormElementType} */
    jspb.Message.getFieldWithDefault(this, 3, 0)
  );
};
/**
 * @param {!proto.e.FormElementType} value
 * @return {!proto.UpdateFormElementValidationRequest} returns this
 */


proto.UpdateFormElementValidationRequest.prototype.setType = function (value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};
/**
 * optional e.FormTextValidation text_validation = 16;
 * @return {?proto.e.FormTextValidation}
 */


proto.UpdateFormElementValidationRequest.prototype.getTextValidation = function () {
  return (
    /** @type{?proto.e.FormTextValidation} */
    jspb.Message.getWrapperField(this, entities_form_pb.FormTextValidation, 16)
  );
};
/**
 * @param {?proto.e.FormTextValidation|undefined} value
 * @return {!proto.UpdateFormElementValidationRequest} returns this
*/


proto.UpdateFormElementValidationRequest.prototype.setTextValidation = function (value) {
  return jspb.Message.setOneofWrapperField(this, 16, proto.UpdateFormElementValidationRequest.oneofGroups_[0], value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.UpdateFormElementValidationRequest} returns this
 */


proto.UpdateFormElementValidationRequest.prototype.clearTextValidation = function () {
  return this.setTextValidation(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.UpdateFormElementValidationRequest.prototype.hasTextValidation = function () {
  return jspb.Message.getField(this, 16) != null;
};
/**
 * optional e.FormNumericValidation numeric_validation = 17;
 * @return {?proto.e.FormNumericValidation}
 */


proto.UpdateFormElementValidationRequest.prototype.getNumericValidation = function () {
  return (
    /** @type{?proto.e.FormNumericValidation} */
    jspb.Message.getWrapperField(this, entities_form_pb.FormNumericValidation, 17)
  );
};
/**
 * @param {?proto.e.FormNumericValidation|undefined} value
 * @return {!proto.UpdateFormElementValidationRequest} returns this
*/


proto.UpdateFormElementValidationRequest.prototype.setNumericValidation = function (value) {
  return jspb.Message.setOneofWrapperField(this, 17, proto.UpdateFormElementValidationRequest.oneofGroups_[0], value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.UpdateFormElementValidationRequest} returns this
 */


proto.UpdateFormElementValidationRequest.prototype.clearNumericValidation = function () {
  return this.setNumericValidation(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.UpdateFormElementValidationRequest.prototype.hasNumericValidation = function () {
  return jspb.Message.getField(this, 17) != null;
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.DeleteFormElementValidationRequest.prototype.toObject = function (opt_includeInstance) {
    return proto.DeleteFormElementValidationRequest.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.DeleteFormElementValidationRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.DeleteFormElementValidationRequest.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.DeleteFormElementValidationRequest}
 */


proto.DeleteFormElementValidationRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.DeleteFormElementValidationRequest();
  return proto.DeleteFormElementValidationRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.DeleteFormElementValidationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.DeleteFormElementValidationRequest}
 */


proto.DeleteFormElementValidationRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setId(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.DeleteFormElementValidationRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.DeleteFormElementValidationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.DeleteFormElementValidationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.DeleteFormElementValidationRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getId();

  if (f.length > 0) {
    writer.writeString(1, f);
  }
};
/**
 * optional string id = 1;
 * @return {string}
 */


proto.DeleteFormElementValidationRequest.prototype.getId = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 1, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.DeleteFormElementValidationRequest} returns this
 */


proto.DeleteFormElementValidationRequest.prototype.setId = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.ListFormBuildsRequest.prototype.toObject = function (opt_includeInstance) {
    return proto.ListFormBuildsRequest.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.ListFormBuildsRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.ListFormBuildsRequest.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      pagination: (f = msg.getPagination()) && entities_common_pb.Pagination.toObject(includeInstance, f),
      chatbotId: jspb.Message.getFieldWithDefault(msg, 2, 0),
      completionStatus: jspb.Message.getFieldWithDefault(msg, 3, 0),
      searchKeyword: jspb.Message.getFieldWithDefault(msg, 17, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ListFormBuildsRequest}
 */


proto.ListFormBuildsRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ListFormBuildsRequest();
  return proto.ListFormBuildsRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ListFormBuildsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ListFormBuildsRequest}
 */


proto.ListFormBuildsRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value = new entities_common_pb.Pagination();
        reader.readMessage(value, entities_common_pb.Pagination.deserializeBinaryFromReader);
        msg.setPagination(value);
        break;

      case 2:
        var value =
        /** @type {number} */
        reader.readInt64();
        msg.setChatbotId(value);
        break;

      case 3:
        var value =
        /** @type {!proto.e.CompletionStatus} */
        reader.readEnum();
        msg.setCompletionStatus(value);
        break;

      case 17:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setSearchKeyword(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.ListFormBuildsRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.ListFormBuildsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ListFormBuildsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.ListFormBuildsRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getPagination();

  if (f != null) {
    writer.writeMessage(1, f, entities_common_pb.Pagination.serializeBinaryToWriter);
  }

  f = message.getChatbotId();

  if (f !== 0) {
    writer.writeInt64(2, f);
  }

  f = message.getCompletionStatus();

  if (f !== 0.0) {
    writer.writeEnum(3, f);
  }

  f = message.getSearchKeyword();

  if (f.length > 0) {
    writer.writeString(17, f);
  }
};
/**
 * optional e.Pagination pagination = 1;
 * @return {?proto.e.Pagination}
 */


proto.ListFormBuildsRequest.prototype.getPagination = function () {
  return (
    /** @type{?proto.e.Pagination} */
    jspb.Message.getWrapperField(this, entities_common_pb.Pagination, 1)
  );
};
/**
 * @param {?proto.e.Pagination|undefined} value
 * @return {!proto.ListFormBuildsRequest} returns this
*/


proto.ListFormBuildsRequest.prototype.setPagination = function (value) {
  return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.ListFormBuildsRequest} returns this
 */


proto.ListFormBuildsRequest.prototype.clearPagination = function () {
  return this.setPagination(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.ListFormBuildsRequest.prototype.hasPagination = function () {
  return jspb.Message.getField(this, 1) != null;
};
/**
 * optional int64 chatbot_id = 2;
 * @return {number}
 */


proto.ListFormBuildsRequest.prototype.getChatbotId = function () {
  return (
    /** @type {number} */
    jspb.Message.getFieldWithDefault(this, 2, 0)
  );
};
/**
 * @param {number} value
 * @return {!proto.ListFormBuildsRequest} returns this
 */


proto.ListFormBuildsRequest.prototype.setChatbotId = function (value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};
/**
 * optional e.CompletionStatus completion_status = 3;
 * @return {!proto.e.CompletionStatus}
 */


proto.ListFormBuildsRequest.prototype.getCompletionStatus = function () {
  return (
    /** @type {!proto.e.CompletionStatus} */
    jspb.Message.getFieldWithDefault(this, 3, 0)
  );
};
/**
 * @param {!proto.e.CompletionStatus} value
 * @return {!proto.ListFormBuildsRequest} returns this
 */


proto.ListFormBuildsRequest.prototype.setCompletionStatus = function (value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};
/**
 * optional string search_keyword = 17;
 * @return {string}
 */


proto.ListFormBuildsRequest.prototype.getSearchKeyword = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 17, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.ListFormBuildsRequest} returns this
 */


proto.ListFormBuildsRequest.prototype.setSearchKeyword = function (value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */


proto.ListFormBuildsResponse.repeatedFields_ = [1];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.ListFormBuildsResponse.prototype.toObject = function (opt_includeInstance) {
    return proto.ListFormBuildsResponse.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.ListFormBuildsResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.ListFormBuildsResponse.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      formBuildsList: jspb.Message.toObjectList(msg.getFormBuildsList(), entities_form_pb.FormBuild.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ListFormBuildsResponse}
 */


proto.ListFormBuildsResponse.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ListFormBuildsResponse();
  return proto.ListFormBuildsResponse.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ListFormBuildsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ListFormBuildsResponse}
 */


proto.ListFormBuildsResponse.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value = new entities_form_pb.FormBuild();
        reader.readMessage(value, entities_form_pb.FormBuild.deserializeBinaryFromReader);
        msg.addFormBuilds(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.ListFormBuildsResponse.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.ListFormBuildsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ListFormBuildsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.ListFormBuildsResponse.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getFormBuildsList();

  if (f.length > 0) {
    writer.writeRepeatedMessage(1, f, entities_form_pb.FormBuild.serializeBinaryToWriter);
  }
};
/**
 * repeated e.FormBuild form_builds = 1;
 * @return {!Array<!proto.e.FormBuild>}
 */


proto.ListFormBuildsResponse.prototype.getFormBuildsList = function () {
  return (
    /** @type{!Array<!proto.e.FormBuild>} */
    jspb.Message.getRepeatedWrapperField(this, entities_form_pb.FormBuild, 1)
  );
};
/**
 * @param {!Array<!proto.e.FormBuild>} value
 * @return {!proto.ListFormBuildsResponse} returns this
*/


proto.ListFormBuildsResponse.prototype.setFormBuildsList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};
/**
 * @param {!proto.e.FormBuild=} opt_value
 * @param {number=} opt_index
 * @return {!proto.e.FormBuild}
 */


proto.ListFormBuildsResponse.prototype.addFormBuilds = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.e.FormBuild, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ListFormBuildsResponse} returns this
 */


proto.ListFormBuildsResponse.prototype.clearFormBuildsList = function () {
  return this.setFormBuildsList([]);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.GetFormBuildRequest.prototype.toObject = function (opt_includeInstance) {
    return proto.GetFormBuildRequest.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.GetFormBuildRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.GetFormBuildRequest.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GetFormBuildRequest}
 */


proto.GetFormBuildRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GetFormBuildRequest();
  return proto.GetFormBuildRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GetFormBuildRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GetFormBuildRequest}
 */


proto.GetFormBuildRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setId(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.GetFormBuildRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.GetFormBuildRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GetFormBuildRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.GetFormBuildRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getId();

  if (f.length > 0) {
    writer.writeString(1, f);
  }
};
/**
 * optional string id = 1;
 * @return {string}
 */


proto.GetFormBuildRequest.prototype.getId = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 1, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.GetFormBuildRequest} returns this
 */


proto.GetFormBuildRequest.prototype.setId = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.CreateFormBuildRequest.prototype.toObject = function (opt_includeInstance) {
    return proto.CreateFormBuildRequest.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.CreateFormBuildRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.CreateFormBuildRequest.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      chatbotId: jspb.Message.getFieldWithDefault(msg, 1, 0),
      name: jspb.Message.getFieldWithDefault(msg, 2, ""),
      authorName: jspb.Message.getFieldWithDefault(msg, 3, ""),
      authorEmail: jspb.Message.getFieldWithDefault(msg, 4, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.CreateFormBuildRequest}
 */


proto.CreateFormBuildRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.CreateFormBuildRequest();
  return proto.CreateFormBuildRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.CreateFormBuildRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.CreateFormBuildRequest}
 */


proto.CreateFormBuildRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value =
        /** @type {number} */
        reader.readInt64();
        msg.setChatbotId(value);
        break;

      case 2:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setName(value);
        break;

      case 3:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setAuthorName(value);
        break;

      case 4:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setAuthorEmail(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.CreateFormBuildRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.CreateFormBuildRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.CreateFormBuildRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.CreateFormBuildRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getChatbotId();

  if (f !== 0) {
    writer.writeInt64(1, f);
  }

  f = message.getName();

  if (f.length > 0) {
    writer.writeString(2, f);
  }

  f = message.getAuthorName();

  if (f.length > 0) {
    writer.writeString(3, f);
  }

  f = message.getAuthorEmail();

  if (f.length > 0) {
    writer.writeString(4, f);
  }
};
/**
 * optional int64 chatbot_id = 1;
 * @return {number}
 */


proto.CreateFormBuildRequest.prototype.getChatbotId = function () {
  return (
    /** @type {number} */
    jspb.Message.getFieldWithDefault(this, 1, 0)
  );
};
/**
 * @param {number} value
 * @return {!proto.CreateFormBuildRequest} returns this
 */


proto.CreateFormBuildRequest.prototype.setChatbotId = function (value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};
/**
 * optional string name = 2;
 * @return {string}
 */


proto.CreateFormBuildRequest.prototype.getName = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 2, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.CreateFormBuildRequest} returns this
 */


proto.CreateFormBuildRequest.prototype.setName = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};
/**
 * optional string author_name = 3;
 * @return {string}
 */


proto.CreateFormBuildRequest.prototype.getAuthorName = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 3, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.CreateFormBuildRequest} returns this
 */


proto.CreateFormBuildRequest.prototype.setAuthorName = function (value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};
/**
 * optional string author_email = 4;
 * @return {string}
 */


proto.CreateFormBuildRequest.prototype.getAuthorEmail = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 4, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.CreateFormBuildRequest} returns this
 */


proto.CreateFormBuildRequest.prototype.setAuthorEmail = function (value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */


proto.UpdateFormBuildRequest.repeatedFields_ = [2];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.UpdateFormBuildRequest.prototype.toObject = function (opt_includeInstance) {
    return proto.UpdateFormBuildRequest.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.UpdateFormBuildRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.UpdateFormBuildRequest.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, ""),
      segmentsList: jspb.Message.toObjectList(msg.getSegmentsList(), entities_form_pb.FormBuildSegment.toObject, includeInstance),
      name: jspb.Message.getFieldWithDefault(msg, 3, ""),
      editorName: jspb.Message.getFieldWithDefault(msg, 4, ""),
      editorEmail: jspb.Message.getFieldWithDefault(msg, 5, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UpdateFormBuildRequest}
 */


proto.UpdateFormBuildRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UpdateFormBuildRequest();
  return proto.UpdateFormBuildRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UpdateFormBuildRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UpdateFormBuildRequest}
 */


proto.UpdateFormBuildRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setId(value);
        break;

      case 2:
        var value = new entities_form_pb.FormBuildSegment();
        reader.readMessage(value, entities_form_pb.FormBuildSegment.deserializeBinaryFromReader);
        msg.addSegments(value);
        break;

      case 3:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setName(value);
        break;

      case 4:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setEditorName(value);
        break;

      case 5:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setEditorEmail(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.UpdateFormBuildRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.UpdateFormBuildRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UpdateFormBuildRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.UpdateFormBuildRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getId();

  if (f.length > 0) {
    writer.writeString(1, f);
  }

  f = message.getSegmentsList();

  if (f.length > 0) {
    writer.writeRepeatedMessage(2, f, entities_form_pb.FormBuildSegment.serializeBinaryToWriter);
  }

  f = message.getName();

  if (f.length > 0) {
    writer.writeString(3, f);
  }

  f = message.getEditorName();

  if (f.length > 0) {
    writer.writeString(4, f);
  }

  f = message.getEditorEmail();

  if (f.length > 0) {
    writer.writeString(5, f);
  }
};
/**
 * optional string id = 1;
 * @return {string}
 */


proto.UpdateFormBuildRequest.prototype.getId = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 1, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.UpdateFormBuildRequest} returns this
 */


proto.UpdateFormBuildRequest.prototype.setId = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * repeated e.FormBuildSegment segments = 2;
 * @return {!Array<!proto.e.FormBuildSegment>}
 */


proto.UpdateFormBuildRequest.prototype.getSegmentsList = function () {
  return (
    /** @type{!Array<!proto.e.FormBuildSegment>} */
    jspb.Message.getRepeatedWrapperField(this, entities_form_pb.FormBuildSegment, 2)
  );
};
/**
 * @param {!Array<!proto.e.FormBuildSegment>} value
 * @return {!proto.UpdateFormBuildRequest} returns this
*/


proto.UpdateFormBuildRequest.prototype.setSegmentsList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};
/**
 * @param {!proto.e.FormBuildSegment=} opt_value
 * @param {number=} opt_index
 * @return {!proto.e.FormBuildSegment}
 */


proto.UpdateFormBuildRequest.prototype.addSegments = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.e.FormBuildSegment, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.UpdateFormBuildRequest} returns this
 */


proto.UpdateFormBuildRequest.prototype.clearSegmentsList = function () {
  return this.setSegmentsList([]);
};
/**
 * optional string name = 3;
 * @return {string}
 */


proto.UpdateFormBuildRequest.prototype.getName = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 3, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.UpdateFormBuildRequest} returns this
 */


proto.UpdateFormBuildRequest.prototype.setName = function (value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};
/**
 * optional string editor_name = 4;
 * @return {string}
 */


proto.UpdateFormBuildRequest.prototype.getEditorName = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 4, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.UpdateFormBuildRequest} returns this
 */


proto.UpdateFormBuildRequest.prototype.setEditorName = function (value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};
/**
 * optional string editor_email = 5;
 * @return {string}
 */


proto.UpdateFormBuildRequest.prototype.getEditorEmail = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 5, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.UpdateFormBuildRequest} returns this
 */


proto.UpdateFormBuildRequest.prototype.setEditorEmail = function (value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.UpdateFormBuildStatusRequest.prototype.toObject = function (opt_includeInstance) {
    return proto.UpdateFormBuildStatusRequest.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.UpdateFormBuildStatusRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.UpdateFormBuildStatusRequest.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, ""),
      editorName: jspb.Message.getFieldWithDefault(msg, 2, ""),
      editorEmail: jspb.Message.getFieldWithDefault(msg, 3, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UpdateFormBuildStatusRequest}
 */


proto.UpdateFormBuildStatusRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UpdateFormBuildStatusRequest();
  return proto.UpdateFormBuildStatusRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UpdateFormBuildStatusRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UpdateFormBuildStatusRequest}
 */


proto.UpdateFormBuildStatusRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setId(value);
        break;

      case 2:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setEditorName(value);
        break;

      case 3:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setEditorEmail(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.UpdateFormBuildStatusRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.UpdateFormBuildStatusRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UpdateFormBuildStatusRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.UpdateFormBuildStatusRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getId();

  if (f.length > 0) {
    writer.writeString(1, f);
  }

  f = message.getEditorName();

  if (f.length > 0) {
    writer.writeString(2, f);
  }

  f = message.getEditorEmail();

  if (f.length > 0) {
    writer.writeString(3, f);
  }
};
/**
 * optional string id = 1;
 * @return {string}
 */


proto.UpdateFormBuildStatusRequest.prototype.getId = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 1, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.UpdateFormBuildStatusRequest} returns this
 */


proto.UpdateFormBuildStatusRequest.prototype.setId = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional string editor_name = 2;
 * @return {string}
 */


proto.UpdateFormBuildStatusRequest.prototype.getEditorName = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 2, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.UpdateFormBuildStatusRequest} returns this
 */


proto.UpdateFormBuildStatusRequest.prototype.setEditorName = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};
/**
 * optional string editor_email = 3;
 * @return {string}
 */


proto.UpdateFormBuildStatusRequest.prototype.getEditorEmail = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 3, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.UpdateFormBuildStatusRequest} returns this
 */


proto.UpdateFormBuildStatusRequest.prototype.setEditorEmail = function (value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.DeleteFormBuildRequest.prototype.toObject = function (opt_includeInstance) {
    return proto.DeleteFormBuildRequest.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.DeleteFormBuildRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.DeleteFormBuildRequest.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.DeleteFormBuildRequest}
 */


proto.DeleteFormBuildRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.DeleteFormBuildRequest();
  return proto.DeleteFormBuildRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.DeleteFormBuildRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.DeleteFormBuildRequest}
 */


proto.DeleteFormBuildRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setId(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.DeleteFormBuildRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.DeleteFormBuildRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.DeleteFormBuildRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.DeleteFormBuildRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getId();

  if (f.length > 0) {
    writer.writeString(1, f);
  }
};
/**
 * optional string id = 1;
 * @return {string}
 */


proto.DeleteFormBuildRequest.prototype.getId = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 1, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.DeleteFormBuildRequest} returns this
 */


proto.DeleteFormBuildRequest.prototype.setId = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.GetFormScenarioRequest.prototype.toObject = function (opt_includeInstance) {
    return proto.GetFormScenarioRequest.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.GetFormScenarioRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.GetFormScenarioRequest.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GetFormScenarioRequest}
 */


proto.GetFormScenarioRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GetFormScenarioRequest();
  return proto.GetFormScenarioRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GetFormScenarioRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GetFormScenarioRequest}
 */


proto.GetFormScenarioRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setId(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.GetFormScenarioRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.GetFormScenarioRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GetFormScenarioRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.GetFormScenarioRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getId();

  if (f.length > 0) {
    writer.writeString(1, f);
  }
};
/**
 * optional string id = 1;
 * @return {string}
 */


proto.GetFormScenarioRequest.prototype.getId = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 1, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.GetFormScenarioRequest} returns this
 */


proto.GetFormScenarioRequest.prototype.setId = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */


proto.CreateFormScenarioRequest.repeatedFields_ = [3];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.CreateFormScenarioRequest.prototype.toObject = function (opt_includeInstance) {
    return proto.CreateFormScenarioRequest.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.CreateFormScenarioRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.CreateFormScenarioRequest.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      formBuildId: jspb.Message.getFieldWithDefault(msg, 1, ""),
      scenarioId: jspb.Message.getFieldWithDefault(msg, 2, 0),
      formQuestionsList: jspb.Message.toObjectList(msg.getFormQuestionsList(), entities_form_pb.FormQuestion.toObject, includeInstance),
      completionStatus: jspb.Message.getFieldWithDefault(msg, 4, 0),
      editorName: jspb.Message.getFieldWithDefault(msg, 5, ""),
      editorEmail: jspb.Message.getFieldWithDefault(msg, 6, ""),
      name: jspb.Message.getFieldWithDefault(msg, 7, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.CreateFormScenarioRequest}
 */


proto.CreateFormScenarioRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.CreateFormScenarioRequest();
  return proto.CreateFormScenarioRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.CreateFormScenarioRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.CreateFormScenarioRequest}
 */


proto.CreateFormScenarioRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setFormBuildId(value);
        break;

      case 2:
        var value =
        /** @type {number} */
        reader.readInt64();
        msg.setScenarioId(value);
        break;

      case 3:
        var value = new entities_form_pb.FormQuestion();
        reader.readMessage(value, entities_form_pb.FormQuestion.deserializeBinaryFromReader);
        msg.addFormQuestions(value);
        break;

      case 4:
        var value =
        /** @type {!proto.e.CompletionStatus} */
        reader.readEnum();
        msg.setCompletionStatus(value);
        break;

      case 5:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setEditorName(value);
        break;

      case 6:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setEditorEmail(value);
        break;

      case 7:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setName(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.CreateFormScenarioRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.CreateFormScenarioRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.CreateFormScenarioRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.CreateFormScenarioRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getFormBuildId();

  if (f.length > 0) {
    writer.writeString(1, f);
  }

  f = message.getScenarioId();

  if (f !== 0) {
    writer.writeInt64(2, f);
  }

  f = message.getFormQuestionsList();

  if (f.length > 0) {
    writer.writeRepeatedMessage(3, f, entities_form_pb.FormQuestion.serializeBinaryToWriter);
  }

  f = message.getCompletionStatus();

  if (f !== 0.0) {
    writer.writeEnum(4, f);
  }

  f = message.getEditorName();

  if (f.length > 0) {
    writer.writeString(5, f);
  }

  f = message.getEditorEmail();

  if (f.length > 0) {
    writer.writeString(6, f);
  }

  f = message.getName();

  if (f.length > 0) {
    writer.writeString(7, f);
  }
};
/**
 * optional string form_build_id = 1;
 * @return {string}
 */


proto.CreateFormScenarioRequest.prototype.getFormBuildId = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 1, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.CreateFormScenarioRequest} returns this
 */


proto.CreateFormScenarioRequest.prototype.setFormBuildId = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional int64 scenario_id = 2;
 * @return {number}
 */


proto.CreateFormScenarioRequest.prototype.getScenarioId = function () {
  return (
    /** @type {number} */
    jspb.Message.getFieldWithDefault(this, 2, 0)
  );
};
/**
 * @param {number} value
 * @return {!proto.CreateFormScenarioRequest} returns this
 */


proto.CreateFormScenarioRequest.prototype.setScenarioId = function (value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};
/**
 * repeated e.FormQuestion form_questions = 3;
 * @return {!Array<!proto.e.FormQuestion>}
 */


proto.CreateFormScenarioRequest.prototype.getFormQuestionsList = function () {
  return (
    /** @type{!Array<!proto.e.FormQuestion>} */
    jspb.Message.getRepeatedWrapperField(this, entities_form_pb.FormQuestion, 3)
  );
};
/**
 * @param {!Array<!proto.e.FormQuestion>} value
 * @return {!proto.CreateFormScenarioRequest} returns this
*/


proto.CreateFormScenarioRequest.prototype.setFormQuestionsList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};
/**
 * @param {!proto.e.FormQuestion=} opt_value
 * @param {number=} opt_index
 * @return {!proto.e.FormQuestion}
 */


proto.CreateFormScenarioRequest.prototype.addFormQuestions = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.e.FormQuestion, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.CreateFormScenarioRequest} returns this
 */


proto.CreateFormScenarioRequest.prototype.clearFormQuestionsList = function () {
  return this.setFormQuestionsList([]);
};
/**
 * optional e.CompletionStatus completion_status = 4;
 * @return {!proto.e.CompletionStatus}
 */


proto.CreateFormScenarioRequest.prototype.getCompletionStatus = function () {
  return (
    /** @type {!proto.e.CompletionStatus} */
    jspb.Message.getFieldWithDefault(this, 4, 0)
  );
};
/**
 * @param {!proto.e.CompletionStatus} value
 * @return {!proto.CreateFormScenarioRequest} returns this
 */


proto.CreateFormScenarioRequest.prototype.setCompletionStatus = function (value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};
/**
 * optional string editor_name = 5;
 * @return {string}
 */


proto.CreateFormScenarioRequest.prototype.getEditorName = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 5, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.CreateFormScenarioRequest} returns this
 */


proto.CreateFormScenarioRequest.prototype.setEditorName = function (value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};
/**
 * optional string editor_email = 6;
 * @return {string}
 */


proto.CreateFormScenarioRequest.prototype.getEditorEmail = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 6, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.CreateFormScenarioRequest} returns this
 */


proto.CreateFormScenarioRequest.prototype.setEditorEmail = function (value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};
/**
 * optional string name = 7;
 * @return {string}
 */


proto.CreateFormScenarioRequest.prototype.getName = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 7, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.CreateFormScenarioRequest} returns this
 */


proto.CreateFormScenarioRequest.prototype.setName = function (value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */


proto.UpdateFormScenarioRequest.repeatedFields_ = [2];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.UpdateFormScenarioRequest.prototype.toObject = function (opt_includeInstance) {
    return proto.UpdateFormScenarioRequest.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.UpdateFormScenarioRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.UpdateFormScenarioRequest.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, ""),
      formQuestionsList: jspb.Message.toObjectList(msg.getFormQuestionsList(), entities_form_pb.FormQuestion.toObject, includeInstance),
      completionStatus: jspb.Message.getFieldWithDefault(msg, 3, 0),
      editorName: jspb.Message.getFieldWithDefault(msg, 4, ""),
      editorEmail: jspb.Message.getFieldWithDefault(msg, 5, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UpdateFormScenarioRequest}
 */


proto.UpdateFormScenarioRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UpdateFormScenarioRequest();
  return proto.UpdateFormScenarioRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UpdateFormScenarioRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UpdateFormScenarioRequest}
 */


proto.UpdateFormScenarioRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setId(value);
        break;

      case 2:
        var value = new entities_form_pb.FormQuestion();
        reader.readMessage(value, entities_form_pb.FormQuestion.deserializeBinaryFromReader);
        msg.addFormQuestions(value);
        break;

      case 3:
        var value =
        /** @type {!proto.e.CompletionStatus} */
        reader.readEnum();
        msg.setCompletionStatus(value);
        break;

      case 4:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setEditorName(value);
        break;

      case 5:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setEditorEmail(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.UpdateFormScenarioRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.UpdateFormScenarioRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UpdateFormScenarioRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.UpdateFormScenarioRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getId();

  if (f.length > 0) {
    writer.writeString(1, f);
  }

  f = message.getFormQuestionsList();

  if (f.length > 0) {
    writer.writeRepeatedMessage(2, f, entities_form_pb.FormQuestion.serializeBinaryToWriter);
  }

  f = message.getCompletionStatus();

  if (f !== 0.0) {
    writer.writeEnum(3, f);
  }

  f = message.getEditorName();

  if (f.length > 0) {
    writer.writeString(4, f);
  }

  f = message.getEditorEmail();

  if (f.length > 0) {
    writer.writeString(5, f);
  }
};
/**
 * optional string id = 1;
 * @return {string}
 */


proto.UpdateFormScenarioRequest.prototype.getId = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 1, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.UpdateFormScenarioRequest} returns this
 */


proto.UpdateFormScenarioRequest.prototype.setId = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * repeated e.FormQuestion form_questions = 2;
 * @return {!Array<!proto.e.FormQuestion>}
 */


proto.UpdateFormScenarioRequest.prototype.getFormQuestionsList = function () {
  return (
    /** @type{!Array<!proto.e.FormQuestion>} */
    jspb.Message.getRepeatedWrapperField(this, entities_form_pb.FormQuestion, 2)
  );
};
/**
 * @param {!Array<!proto.e.FormQuestion>} value
 * @return {!proto.UpdateFormScenarioRequest} returns this
*/


proto.UpdateFormScenarioRequest.prototype.setFormQuestionsList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};
/**
 * @param {!proto.e.FormQuestion=} opt_value
 * @param {number=} opt_index
 * @return {!proto.e.FormQuestion}
 */


proto.UpdateFormScenarioRequest.prototype.addFormQuestions = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.e.FormQuestion, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.UpdateFormScenarioRequest} returns this
 */


proto.UpdateFormScenarioRequest.prototype.clearFormQuestionsList = function () {
  return this.setFormQuestionsList([]);
};
/**
 * optional e.CompletionStatus completion_status = 3;
 * @return {!proto.e.CompletionStatus}
 */


proto.UpdateFormScenarioRequest.prototype.getCompletionStatus = function () {
  return (
    /** @type {!proto.e.CompletionStatus} */
    jspb.Message.getFieldWithDefault(this, 3, 0)
  );
};
/**
 * @param {!proto.e.CompletionStatus} value
 * @return {!proto.UpdateFormScenarioRequest} returns this
 */


proto.UpdateFormScenarioRequest.prototype.setCompletionStatus = function (value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};
/**
 * optional string editor_name = 4;
 * @return {string}
 */


proto.UpdateFormScenarioRequest.prototype.getEditorName = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 4, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.UpdateFormScenarioRequest} returns this
 */


proto.UpdateFormScenarioRequest.prototype.setEditorName = function (value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};
/**
 * optional string editor_email = 5;
 * @return {string}
 */


proto.UpdateFormScenarioRequest.prototype.getEditorEmail = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 5, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.UpdateFormScenarioRequest} returns this
 */


proto.UpdateFormScenarioRequest.prototype.setEditorEmail = function (value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.DeleteFormScenarioRequest.prototype.toObject = function (opt_includeInstance) {
    return proto.DeleteFormScenarioRequest.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.DeleteFormScenarioRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.DeleteFormScenarioRequest.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, ""),
      editorName: jspb.Message.getFieldWithDefault(msg, 2, ""),
      editorEmail: jspb.Message.getFieldWithDefault(msg, 3, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.DeleteFormScenarioRequest}
 */


proto.DeleteFormScenarioRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.DeleteFormScenarioRequest();
  return proto.DeleteFormScenarioRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.DeleteFormScenarioRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.DeleteFormScenarioRequest}
 */


proto.DeleteFormScenarioRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setId(value);
        break;

      case 2:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setEditorName(value);
        break;

      case 3:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setEditorEmail(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.DeleteFormScenarioRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.DeleteFormScenarioRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.DeleteFormScenarioRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.DeleteFormScenarioRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getId();

  if (f.length > 0) {
    writer.writeString(1, f);
  }

  f = message.getEditorName();

  if (f.length > 0) {
    writer.writeString(2, f);
  }

  f = message.getEditorEmail();

  if (f.length > 0) {
    writer.writeString(3, f);
  }
};
/**
 * optional string id = 1;
 * @return {string}
 */


proto.DeleteFormScenarioRequest.prototype.getId = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 1, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.DeleteFormScenarioRequest} returns this
 */


proto.DeleteFormScenarioRequest.prototype.setId = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional string editor_name = 2;
 * @return {string}
 */


proto.DeleteFormScenarioRequest.prototype.getEditorName = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 2, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.DeleteFormScenarioRequest} returns this
 */


proto.DeleteFormScenarioRequest.prototype.setEditorName = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};
/**
 * optional string editor_email = 3;
 * @return {string}
 */


proto.DeleteFormScenarioRequest.prototype.getEditorEmail = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 3, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.DeleteFormScenarioRequest} returns this
 */


proto.DeleteFormScenarioRequest.prototype.setEditorEmail = function (value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.GetFormQuestionListsRequest.prototype.toObject = function (opt_includeInstance) {
    return proto.GetFormQuestionListsRequest.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.GetFormQuestionListsRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.GetFormQuestionListsRequest.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      formScenarioId: jspb.Message.getFieldWithDefault(msg, 1, ""),
      scenarioId: jspb.Message.getFieldWithDefault(msg, 2, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GetFormQuestionListsRequest}
 */


proto.GetFormQuestionListsRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GetFormQuestionListsRequest();
  return proto.GetFormQuestionListsRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GetFormQuestionListsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GetFormQuestionListsRequest}
 */


proto.GetFormQuestionListsRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setFormScenarioId(value);
        break;

      case 2:
        var value =
        /** @type {number} */
        reader.readInt64();
        msg.setScenarioId(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.GetFormQuestionListsRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.GetFormQuestionListsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GetFormQuestionListsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.GetFormQuestionListsRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getFormScenarioId();

  if (f.length > 0) {
    writer.writeString(1, f);
  }

  f = message.getScenarioId();

  if (f !== 0) {
    writer.writeInt64(2, f);
  }
};
/**
 * optional string form_scenario_id = 1;
 * @return {string}
 */


proto.GetFormQuestionListsRequest.prototype.getFormScenarioId = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 1, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.GetFormQuestionListsRequest} returns this
 */


proto.GetFormQuestionListsRequest.prototype.setFormScenarioId = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional int64 scenario_id = 2;
 * @return {number}
 */


proto.GetFormQuestionListsRequest.prototype.getScenarioId = function () {
  return (
    /** @type {number} */
    jspb.Message.getFieldWithDefault(this, 2, 0)
  );
};
/**
 * @param {number} value
 * @return {!proto.GetFormQuestionListsRequest} returns this
 */


proto.GetFormQuestionListsRequest.prototype.setScenarioId = function (value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */


proto.GetFormQuestionListsResponse.repeatedFields_ = [1, 2];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.GetFormQuestionListsResponse.prototype.toObject = function (opt_includeInstance) {
    return proto.GetFormQuestionListsResponse.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.GetFormQuestionListsResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.GetFormQuestionListsResponse.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      inUseQuestionsList: jspb.Message.toObjectList(msg.getInUseQuestionsList(), entities_form_pb.FormQuestion.toObject, includeInstance),
      unusedQuestionsList: jspb.Message.toObjectList(msg.getUnusedQuestionsList(), entities_form_pb.FormQuestion.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GetFormQuestionListsResponse}
 */


proto.GetFormQuestionListsResponse.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GetFormQuestionListsResponse();
  return proto.GetFormQuestionListsResponse.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GetFormQuestionListsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GetFormQuestionListsResponse}
 */


proto.GetFormQuestionListsResponse.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value = new entities_form_pb.FormQuestion();
        reader.readMessage(value, entities_form_pb.FormQuestion.deserializeBinaryFromReader);
        msg.addInUseQuestions(value);
        break;

      case 2:
        var value = new entities_form_pb.FormQuestion();
        reader.readMessage(value, entities_form_pb.FormQuestion.deserializeBinaryFromReader);
        msg.addUnusedQuestions(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.GetFormQuestionListsResponse.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.GetFormQuestionListsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GetFormQuestionListsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.GetFormQuestionListsResponse.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getInUseQuestionsList();

  if (f.length > 0) {
    writer.writeRepeatedMessage(1, f, entities_form_pb.FormQuestion.serializeBinaryToWriter);
  }

  f = message.getUnusedQuestionsList();

  if (f.length > 0) {
    writer.writeRepeatedMessage(2, f, entities_form_pb.FormQuestion.serializeBinaryToWriter);
  }
};
/**
 * repeated e.FormQuestion in_use_questions = 1;
 * @return {!Array<!proto.e.FormQuestion>}
 */


proto.GetFormQuestionListsResponse.prototype.getInUseQuestionsList = function () {
  return (
    /** @type{!Array<!proto.e.FormQuestion>} */
    jspb.Message.getRepeatedWrapperField(this, entities_form_pb.FormQuestion, 1)
  );
};
/**
 * @param {!Array<!proto.e.FormQuestion>} value
 * @return {!proto.GetFormQuestionListsResponse} returns this
*/


proto.GetFormQuestionListsResponse.prototype.setInUseQuestionsList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};
/**
 * @param {!proto.e.FormQuestion=} opt_value
 * @param {number=} opt_index
 * @return {!proto.e.FormQuestion}
 */


proto.GetFormQuestionListsResponse.prototype.addInUseQuestions = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.e.FormQuestion, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.GetFormQuestionListsResponse} returns this
 */


proto.GetFormQuestionListsResponse.prototype.clearInUseQuestionsList = function () {
  return this.setInUseQuestionsList([]);
};
/**
 * repeated e.FormQuestion unused_questions = 2;
 * @return {!Array<!proto.e.FormQuestion>}
 */


proto.GetFormQuestionListsResponse.prototype.getUnusedQuestionsList = function () {
  return (
    /** @type{!Array<!proto.e.FormQuestion>} */
    jspb.Message.getRepeatedWrapperField(this, entities_form_pb.FormQuestion, 2)
  );
};
/**
 * @param {!Array<!proto.e.FormQuestion>} value
 * @return {!proto.GetFormQuestionListsResponse} returns this
*/


proto.GetFormQuestionListsResponse.prototype.setUnusedQuestionsList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};
/**
 * @param {!proto.e.FormQuestion=} opt_value
 * @param {number=} opt_index
 * @return {!proto.e.FormQuestion}
 */


proto.GetFormQuestionListsResponse.prototype.addUnusedQuestions = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.e.FormQuestion, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.GetFormQuestionListsResponse} returns this
 */


proto.GetFormQuestionListsResponse.prototype.clearUnusedQuestionsList = function () {
  return this.setUnusedQuestionsList([]);
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */


proto.UpsertFormQuestionsRequest.repeatedFields_ = [1];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.UpsertFormQuestionsRequest.prototype.toObject = function (opt_includeInstance) {
    return proto.UpsertFormQuestionsRequest.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.UpsertFormQuestionsRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.UpsertFormQuestionsRequest.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      questionsList: jspb.Message.toObjectList(msg.getQuestionsList(), entities_message_pb.Question.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UpsertFormQuestionsRequest}
 */


proto.UpsertFormQuestionsRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UpsertFormQuestionsRequest();
  return proto.UpsertFormQuestionsRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UpsertFormQuestionsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UpsertFormQuestionsRequest}
 */


proto.UpsertFormQuestionsRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value = new entities_message_pb.Question();
        reader.readMessage(value, entities_message_pb.Question.deserializeBinaryFromReader);
        msg.addQuestions(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.UpsertFormQuestionsRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.UpsertFormQuestionsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UpsertFormQuestionsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.UpsertFormQuestionsRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getQuestionsList();

  if (f.length > 0) {
    writer.writeRepeatedMessage(1, f, entities_message_pb.Question.serializeBinaryToWriter);
  }
};
/**
 * repeated e.Question questions = 1;
 * @return {!Array<!proto.e.Question>}
 */


proto.UpsertFormQuestionsRequest.prototype.getQuestionsList = function () {
  return (
    /** @type{!Array<!proto.e.Question>} */
    jspb.Message.getRepeatedWrapperField(this, entities_message_pb.Question, 1)
  );
};
/**
 * @param {!Array<!proto.e.Question>} value
 * @return {!proto.UpsertFormQuestionsRequest} returns this
*/


proto.UpsertFormQuestionsRequest.prototype.setQuestionsList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};
/**
 * @param {!proto.e.Question=} opt_value
 * @param {number=} opt_index
 * @return {!proto.e.Question}
 */


proto.UpsertFormQuestionsRequest.prototype.addQuestions = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.e.Question, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.UpsertFormQuestionsRequest} returns this
 */


proto.UpsertFormQuestionsRequest.prototype.clearQuestionsList = function () {
  return this.setQuestionsList([]);
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */


proto.UpsertFormQuestionsResponse.repeatedFields_ = [1];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.UpsertFormQuestionsResponse.prototype.toObject = function (opt_includeInstance) {
    return proto.UpsertFormQuestionsResponse.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.UpsertFormQuestionsResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.UpsertFormQuestionsResponse.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      formQuestionsList: jspb.Message.toObjectList(msg.getFormQuestionsList(), entities_form_pb.FormQuestion.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UpsertFormQuestionsResponse}
 */


proto.UpsertFormQuestionsResponse.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UpsertFormQuestionsResponse();
  return proto.UpsertFormQuestionsResponse.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UpsertFormQuestionsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UpsertFormQuestionsResponse}
 */


proto.UpsertFormQuestionsResponse.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value = new entities_form_pb.FormQuestion();
        reader.readMessage(value, entities_form_pb.FormQuestion.deserializeBinaryFromReader);
        msg.addFormQuestions(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.UpsertFormQuestionsResponse.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.UpsertFormQuestionsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UpsertFormQuestionsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.UpsertFormQuestionsResponse.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getFormQuestionsList();

  if (f.length > 0) {
    writer.writeRepeatedMessage(1, f, entities_form_pb.FormQuestion.serializeBinaryToWriter);
  }
};
/**
 * repeated e.FormQuestion form_questions = 1;
 * @return {!Array<!proto.e.FormQuestion>}
 */


proto.UpsertFormQuestionsResponse.prototype.getFormQuestionsList = function () {
  return (
    /** @type{!Array<!proto.e.FormQuestion>} */
    jspb.Message.getRepeatedWrapperField(this, entities_form_pb.FormQuestion, 1)
  );
};
/**
 * @param {!Array<!proto.e.FormQuestion>} value
 * @return {!proto.UpsertFormQuestionsResponse} returns this
*/


proto.UpsertFormQuestionsResponse.prototype.setFormQuestionsList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};
/**
 * @param {!proto.e.FormQuestion=} opt_value
 * @param {number=} opt_index
 * @return {!proto.e.FormQuestion}
 */


proto.UpsertFormQuestionsResponse.prototype.addFormQuestions = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.e.FormQuestion, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.UpsertFormQuestionsResponse} returns this
 */


proto.UpsertFormQuestionsResponse.prototype.clearFormQuestionsList = function () {
  return this.setFormQuestionsList([]);
};
/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */


proto.UpdateFormQuestionRequest.oneofGroups_ = [[18, 19]];
/**
 * @enum {number}
 */

proto.UpdateFormQuestionRequest.ValidationCase = {
  VALIDATION_NOT_SET: 0,
  TEXT_VALIDATION: 18,
  NUMERIC_VALIDATION: 19
};
/**
 * @return {proto.UpdateFormQuestionRequest.ValidationCase}
 */

proto.UpdateFormQuestionRequest.prototype.getValidationCase = function () {
  return (
    /** @type {proto.UpdateFormQuestionRequest.ValidationCase} */
    jspb.Message.computeOneofCase(this, proto.UpdateFormQuestionRequest.oneofGroups_[0])
  );
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.UpdateFormQuestionRequest.prototype.toObject = function (opt_includeInstance) {
    return proto.UpdateFormQuestionRequest.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.UpdateFormQuestionRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.UpdateFormQuestionRequest.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, ""),
      triggerFormAutomation: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
      errorMessage: jspb.Message.getFieldWithDefault(msg, 17, ""),
      textValidation: (f = msg.getTextValidation()) && entities_form_pb.FormTextValidation.toObject(includeInstance, f),
      numericValidation: (f = msg.getNumericValidation()) && entities_form_pb.FormNumericValidation.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UpdateFormQuestionRequest}
 */


proto.UpdateFormQuestionRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UpdateFormQuestionRequest();
  return proto.UpdateFormQuestionRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UpdateFormQuestionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UpdateFormQuestionRequest}
 */


proto.UpdateFormQuestionRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setId(value);
        break;

      case 3:
        var value =
        /** @type {boolean} */
        reader.readBool();
        msg.setTriggerFormAutomation(value);
        break;

      case 17:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setErrorMessage(value);
        break;

      case 18:
        var value = new entities_form_pb.FormTextValidation();
        reader.readMessage(value, entities_form_pb.FormTextValidation.deserializeBinaryFromReader);
        msg.setTextValidation(value);
        break;

      case 19:
        var value = new entities_form_pb.FormNumericValidation();
        reader.readMessage(value, entities_form_pb.FormNumericValidation.deserializeBinaryFromReader);
        msg.setNumericValidation(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.UpdateFormQuestionRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.UpdateFormQuestionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UpdateFormQuestionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.UpdateFormQuestionRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getId();

  if (f.length > 0) {
    writer.writeString(1, f);
  }

  f = message.getTriggerFormAutomation();

  if (f) {
    writer.writeBool(3, f);
  }

  f = message.getErrorMessage();

  if (f.length > 0) {
    writer.writeString(17, f);
  }

  f = message.getTextValidation();

  if (f != null) {
    writer.writeMessage(18, f, entities_form_pb.FormTextValidation.serializeBinaryToWriter);
  }

  f = message.getNumericValidation();

  if (f != null) {
    writer.writeMessage(19, f, entities_form_pb.FormNumericValidation.serializeBinaryToWriter);
  }
};
/**
 * optional string id = 1;
 * @return {string}
 */


proto.UpdateFormQuestionRequest.prototype.getId = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 1, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.UpdateFormQuestionRequest} returns this
 */


proto.UpdateFormQuestionRequest.prototype.setId = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional bool trigger_form_automation = 3;
 * @return {boolean}
 */


proto.UpdateFormQuestionRequest.prototype.getTriggerFormAutomation = function () {
  return (
    /** @type {boolean} */
    jspb.Message.getBooleanFieldWithDefault(this, 3, false)
  );
};
/**
 * @param {boolean} value
 * @return {!proto.UpdateFormQuestionRequest} returns this
 */


proto.UpdateFormQuestionRequest.prototype.setTriggerFormAutomation = function (value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};
/**
 * optional string error_message = 17;
 * @return {string}
 */


proto.UpdateFormQuestionRequest.prototype.getErrorMessage = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 17, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.UpdateFormQuestionRequest} returns this
 */


proto.UpdateFormQuestionRequest.prototype.setErrorMessage = function (value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};
/**
 * optional e.FormTextValidation text_validation = 18;
 * @return {?proto.e.FormTextValidation}
 */


proto.UpdateFormQuestionRequest.prototype.getTextValidation = function () {
  return (
    /** @type{?proto.e.FormTextValidation} */
    jspb.Message.getWrapperField(this, entities_form_pb.FormTextValidation, 18)
  );
};
/**
 * @param {?proto.e.FormTextValidation|undefined} value
 * @return {!proto.UpdateFormQuestionRequest} returns this
*/


proto.UpdateFormQuestionRequest.prototype.setTextValidation = function (value) {
  return jspb.Message.setOneofWrapperField(this, 18, proto.UpdateFormQuestionRequest.oneofGroups_[0], value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.UpdateFormQuestionRequest} returns this
 */


proto.UpdateFormQuestionRequest.prototype.clearTextValidation = function () {
  return this.setTextValidation(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.UpdateFormQuestionRequest.prototype.hasTextValidation = function () {
  return jspb.Message.getField(this, 18) != null;
};
/**
 * optional e.FormNumericValidation numeric_validation = 19;
 * @return {?proto.e.FormNumericValidation}
 */


proto.UpdateFormQuestionRequest.prototype.getNumericValidation = function () {
  return (
    /** @type{?proto.e.FormNumericValidation} */
    jspb.Message.getWrapperField(this, entities_form_pb.FormNumericValidation, 19)
  );
};
/**
 * @param {?proto.e.FormNumericValidation|undefined} value
 * @return {!proto.UpdateFormQuestionRequest} returns this
*/


proto.UpdateFormQuestionRequest.prototype.setNumericValidation = function (value) {
  return jspb.Message.setOneofWrapperField(this, 19, proto.UpdateFormQuestionRequest.oneofGroups_[0], value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.UpdateFormQuestionRequest} returns this
 */


proto.UpdateFormQuestionRequest.prototype.clearNumericValidation = function () {
  return this.setNumericValidation(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.UpdateFormQuestionRequest.prototype.hasNumericValidation = function () {
  return jspb.Message.getField(this, 19) != null;
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.GetFormQuestionRequest.prototype.toObject = function (opt_includeInstance) {
    return proto.GetFormQuestionRequest.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.GetFormQuestionRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.GetFormQuestionRequest.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      questionId: jspb.Message.getFieldWithDefault(msg, 1, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GetFormQuestionRequest}
 */


proto.GetFormQuestionRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GetFormQuestionRequest();
  return proto.GetFormQuestionRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GetFormQuestionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GetFormQuestionRequest}
 */


proto.GetFormQuestionRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value =
        /** @type {number} */
        reader.readInt64();
        msg.setQuestionId(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.GetFormQuestionRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.GetFormQuestionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GetFormQuestionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.GetFormQuestionRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getQuestionId();

  if (f !== 0) {
    writer.writeInt64(1, f);
  }
};
/**
 * optional int64 question_id = 1;
 * @return {number}
 */


proto.GetFormQuestionRequest.prototype.getQuestionId = function () {
  return (
    /** @type {number} */
    jspb.Message.getFieldWithDefault(this, 1, 0)
  );
};
/**
 * @param {number} value
 * @return {!proto.GetFormQuestionRequest} returns this
 */


proto.GetFormQuestionRequest.prototype.setQuestionId = function (value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.ReceiveEndUserMessageRequest.prototype.toObject = function (opt_includeInstance) {
    return proto.ReceiveEndUserMessageRequest.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.ReceiveEndUserMessageRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.ReceiveEndUserMessageRequest.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      endUserId: jspb.Message.getFieldWithDefault(msg, 1, 0),
      answer: jspb.Message.getFieldWithDefault(msg, 2, ""),
      question: (f = msg.getQuestion()) && entities_message_pb.Question.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ReceiveEndUserMessageRequest}
 */


proto.ReceiveEndUserMessageRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ReceiveEndUserMessageRequest();
  return proto.ReceiveEndUserMessageRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ReceiveEndUserMessageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ReceiveEndUserMessageRequest}
 */


proto.ReceiveEndUserMessageRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value =
        /** @type {number} */
        reader.readInt64();
        msg.setEndUserId(value);
        break;

      case 2:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setAnswer(value);
        break;

      case 3:
        var value = new entities_message_pb.Question();
        reader.readMessage(value, entities_message_pb.Question.deserializeBinaryFromReader);
        msg.setQuestion(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.ReceiveEndUserMessageRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.ReceiveEndUserMessageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ReceiveEndUserMessageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.ReceiveEndUserMessageRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getEndUserId();

  if (f !== 0) {
    writer.writeInt64(1, f);
  }

  f = message.getAnswer();

  if (f.length > 0) {
    writer.writeString(2, f);
  }

  f = message.getQuestion();

  if (f != null) {
    writer.writeMessage(3, f, entities_message_pb.Question.serializeBinaryToWriter);
  }
};
/**
 * optional int64 end_user_id = 1;
 * @return {number}
 */


proto.ReceiveEndUserMessageRequest.prototype.getEndUserId = function () {
  return (
    /** @type {number} */
    jspb.Message.getFieldWithDefault(this, 1, 0)
  );
};
/**
 * @param {number} value
 * @return {!proto.ReceiveEndUserMessageRequest} returns this
 */


proto.ReceiveEndUserMessageRequest.prototype.setEndUserId = function (value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};
/**
 * optional string answer = 2;
 * @return {string}
 */


proto.ReceiveEndUserMessageRequest.prototype.getAnswer = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 2, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.ReceiveEndUserMessageRequest} returns this
 */


proto.ReceiveEndUserMessageRequest.prototype.setAnswer = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};
/**
 * optional e.Question question = 3;
 * @return {?proto.e.Question}
 */


proto.ReceiveEndUserMessageRequest.prototype.getQuestion = function () {
  return (
    /** @type{?proto.e.Question} */
    jspb.Message.getWrapperField(this, entities_message_pb.Question, 3)
  );
};
/**
 * @param {?proto.e.Question|undefined} value
 * @return {!proto.ReceiveEndUserMessageRequest} returns this
*/


proto.ReceiveEndUserMessageRequest.prototype.setQuestion = function (value) {
  return jspb.Message.setWrapperField(this, 3, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.ReceiveEndUserMessageRequest} returns this
 */


proto.ReceiveEndUserMessageRequest.prototype.clearQuestion = function () {
  return this.setQuestion(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.ReceiveEndUserMessageRequest.prototype.hasQuestion = function () {
  return jspb.Message.getField(this, 3) != null;
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.ListFormIDsRequest.prototype.toObject = function (opt_includeInstance) {
    return proto.ListFormIDsRequest.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.ListFormIDsRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.ListFormIDsRequest.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      pagination: (f = msg.getPagination()) && entities_common_pb.Pagination.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ListFormIDsRequest}
 */


proto.ListFormIDsRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ListFormIDsRequest();
  return proto.ListFormIDsRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ListFormIDsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ListFormIDsRequest}
 */


proto.ListFormIDsRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value = new entities_common_pb.Pagination();
        reader.readMessage(value, entities_common_pb.Pagination.deserializeBinaryFromReader);
        msg.setPagination(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.ListFormIDsRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.ListFormIDsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ListFormIDsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.ListFormIDsRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getPagination();

  if (f != null) {
    writer.writeMessage(1, f, entities_common_pb.Pagination.serializeBinaryToWriter);
  }
};
/**
 * optional e.Pagination pagination = 1;
 * @return {?proto.e.Pagination}
 */


proto.ListFormIDsRequest.prototype.getPagination = function () {
  return (
    /** @type{?proto.e.Pagination} */
    jspb.Message.getWrapperField(this, entities_common_pb.Pagination, 1)
  );
};
/**
 * @param {?proto.e.Pagination|undefined} value
 * @return {!proto.ListFormIDsRequest} returns this
*/


proto.ListFormIDsRequest.prototype.setPagination = function (value) {
  return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.ListFormIDsRequest} returns this
 */


proto.ListFormIDsRequest.prototype.clearPagination = function () {
  return this.setPagination(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.ListFormIDsRequest.prototype.hasPagination = function () {
  return jspb.Message.getField(this, 1) != null;
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */


proto.ListFormIDsResponse.repeatedFields_ = [1];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.ListFormIDsResponse.prototype.toObject = function (opt_includeInstance) {
    return proto.ListFormIDsResponse.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.ListFormIDsResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.ListFormIDsResponse.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      formIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ListFormIDsResponse}
 */


proto.ListFormIDsResponse.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ListFormIDsResponse();
  return proto.ListFormIDsResponse.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ListFormIDsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ListFormIDsResponse}
 */


proto.ListFormIDsResponse.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value =
        /** @type {string} */
        reader.readString();
        msg.addFormIds(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.ListFormIDsResponse.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.ListFormIDsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ListFormIDsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.ListFormIDsResponse.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getFormIdsList();

  if (f.length > 0) {
    writer.writeRepeatedString(1, f);
  }
};
/**
 * repeated string form_ids = 1;
 * @return {!Array<string>}
 */


proto.ListFormIDsResponse.prototype.getFormIdsList = function () {
  return (
    /** @type {!Array<string>} */
    jspb.Message.getRepeatedField(this, 1)
  );
};
/**
 * @param {!Array<string>} value
 * @return {!proto.ListFormIDsResponse} returns this
 */


proto.ListFormIDsResponse.prototype.setFormIdsList = function (value) {
  return jspb.Message.setField(this, 1, value || []);
};
/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.ListFormIDsResponse} returns this
 */


proto.ListFormIDsResponse.prototype.addFormIds = function (value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ListFormIDsResponse} returns this
 */


proto.ListFormIDsResponse.prototype.clearFormIdsList = function () {
  return this.setFormIdsList([]);
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */


proto.ListConversionTasksRequest.repeatedFields_ = [4];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.ListConversionTasksRequest.prototype.toObject = function (opt_includeInstance) {
    return proto.ListConversionTasksRequest.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.ListConversionTasksRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.ListConversionTasksRequest.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      pagination: (f = msg.getPagination()) && entities_common_pb.Pagination.toObject(includeInstance, f),
      formBuildId: jspb.Message.getFieldWithDefault(msg, 2, ""),
      endUserId: jspb.Message.getFieldWithDefault(msg, 3, 0),
      statusesList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
      startDate: (f = msg.getStartDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
      endDate: (f = msg.getEndDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
      reversedOrder: jspb.Message.getBooleanFieldWithDefault(msg, 7, false)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ListConversionTasksRequest}
 */


proto.ListConversionTasksRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ListConversionTasksRequest();
  return proto.ListConversionTasksRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ListConversionTasksRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ListConversionTasksRequest}
 */


proto.ListConversionTasksRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value = new entities_common_pb.Pagination();
        reader.readMessage(value, entities_common_pb.Pagination.deserializeBinaryFromReader);
        msg.setPagination(value);
        break;

      case 2:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setFormBuildId(value);
        break;

      case 3:
        var value =
        /** @type {number} */
        reader.readInt64();
        msg.setEndUserId(value);
        break;

      case 4:
        var values =
        /** @type {!Array<!proto.e.ConversionTaskStatus>} */
        reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()];

        for (var i = 0; i < values.length; i++) {
          msg.addStatuses(values[i]);
        }

        break;

      case 5:
        var value = new google_protobuf_timestamp_pb.Timestamp();
        reader.readMessage(value, google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
        msg.setStartDate(value);
        break;

      case 6:
        var value = new google_protobuf_timestamp_pb.Timestamp();
        reader.readMessage(value, google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
        msg.setEndDate(value);
        break;

      case 7:
        var value =
        /** @type {boolean} */
        reader.readBool();
        msg.setReversedOrder(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.ListConversionTasksRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.ListConversionTasksRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ListConversionTasksRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.ListConversionTasksRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getPagination();

  if (f != null) {
    writer.writeMessage(1, f, entities_common_pb.Pagination.serializeBinaryToWriter);
  }

  f = message.getFormBuildId();

  if (f.length > 0) {
    writer.writeString(2, f);
  }

  f = message.getEndUserId();

  if (f !== 0) {
    writer.writeInt64(3, f);
  }

  f = message.getStatusesList();

  if (f.length > 0) {
    writer.writePackedEnum(4, f);
  }

  f = message.getStartDate();

  if (f != null) {
    writer.writeMessage(5, f, google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter);
  }

  f = message.getEndDate();

  if (f != null) {
    writer.writeMessage(6, f, google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter);
  }

  f = message.getReversedOrder();

  if (f) {
    writer.writeBool(7, f);
  }
};
/**
 * optional e.Pagination pagination = 1;
 * @return {?proto.e.Pagination}
 */


proto.ListConversionTasksRequest.prototype.getPagination = function () {
  return (
    /** @type{?proto.e.Pagination} */
    jspb.Message.getWrapperField(this, entities_common_pb.Pagination, 1)
  );
};
/**
 * @param {?proto.e.Pagination|undefined} value
 * @return {!proto.ListConversionTasksRequest} returns this
*/


proto.ListConversionTasksRequest.prototype.setPagination = function (value) {
  return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.ListConversionTasksRequest} returns this
 */


proto.ListConversionTasksRequest.prototype.clearPagination = function () {
  return this.setPagination(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.ListConversionTasksRequest.prototype.hasPagination = function () {
  return jspb.Message.getField(this, 1) != null;
};
/**
 * optional string form_build_id = 2;
 * @return {string}
 */


proto.ListConversionTasksRequest.prototype.getFormBuildId = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 2, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.ListConversionTasksRequest} returns this
 */


proto.ListConversionTasksRequest.prototype.setFormBuildId = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};
/**
 * optional int64 end_user_id = 3;
 * @return {number}
 */


proto.ListConversionTasksRequest.prototype.getEndUserId = function () {
  return (
    /** @type {number} */
    jspb.Message.getFieldWithDefault(this, 3, 0)
  );
};
/**
 * @param {number} value
 * @return {!proto.ListConversionTasksRequest} returns this
 */


proto.ListConversionTasksRequest.prototype.setEndUserId = function (value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};
/**
 * repeated e.ConversionTaskStatus statuses = 4;
 * @return {!Array<!proto.e.ConversionTaskStatus>}
 */


proto.ListConversionTasksRequest.prototype.getStatusesList = function () {
  return (
    /** @type {!Array<!proto.e.ConversionTaskStatus>} */
    jspb.Message.getRepeatedField(this, 4)
  );
};
/**
 * @param {!Array<!proto.e.ConversionTaskStatus>} value
 * @return {!proto.ListConversionTasksRequest} returns this
 */


proto.ListConversionTasksRequest.prototype.setStatusesList = function (value) {
  return jspb.Message.setField(this, 4, value || []);
};
/**
 * @param {!proto.e.ConversionTaskStatus} value
 * @param {number=} opt_index
 * @return {!proto.ListConversionTasksRequest} returns this
 */


proto.ListConversionTasksRequest.prototype.addStatuses = function (value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ListConversionTasksRequest} returns this
 */


proto.ListConversionTasksRequest.prototype.clearStatusesList = function () {
  return this.setStatusesList([]);
};
/**
 * optional google.protobuf.Timestamp start_date = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */


proto.ListConversionTasksRequest.prototype.getStartDate = function () {
  return (
    /** @type{?proto.google.protobuf.Timestamp} */
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5)
  );
};
/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.ListConversionTasksRequest} returns this
*/


proto.ListConversionTasksRequest.prototype.setStartDate = function (value) {
  return jspb.Message.setWrapperField(this, 5, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.ListConversionTasksRequest} returns this
 */


proto.ListConversionTasksRequest.prototype.clearStartDate = function () {
  return this.setStartDate(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.ListConversionTasksRequest.prototype.hasStartDate = function () {
  return jspb.Message.getField(this, 5) != null;
};
/**
 * optional google.protobuf.Timestamp end_date = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */


proto.ListConversionTasksRequest.prototype.getEndDate = function () {
  return (
    /** @type{?proto.google.protobuf.Timestamp} */
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6)
  );
};
/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.ListConversionTasksRequest} returns this
*/


proto.ListConversionTasksRequest.prototype.setEndDate = function (value) {
  return jspb.Message.setWrapperField(this, 6, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.ListConversionTasksRequest} returns this
 */


proto.ListConversionTasksRequest.prototype.clearEndDate = function () {
  return this.setEndDate(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.ListConversionTasksRequest.prototype.hasEndDate = function () {
  return jspb.Message.getField(this, 6) != null;
};
/**
 * optional bool reversed_order = 7;
 * @return {boolean}
 */


proto.ListConversionTasksRequest.prototype.getReversedOrder = function () {
  return (
    /** @type {boolean} */
    jspb.Message.getBooleanFieldWithDefault(this, 7, false)
  );
};
/**
 * @param {boolean} value
 * @return {!proto.ListConversionTasksRequest} returns this
 */


proto.ListConversionTasksRequest.prototype.setReversedOrder = function (value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */


proto.LogConversionTaskRequest.repeatedFields_ = [4];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.LogConversionTaskRequest.prototype.toObject = function (opt_includeInstance) {
    return proto.LogConversionTaskRequest.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.LogConversionTaskRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.LogConversionTaskRequest.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      conversionTaskId: jspb.Message.getFieldWithDefault(msg, 1, ""),
      status: jspb.Message.getFieldWithDefault(msg, 2, 0),
      conversionError: (f = msg.getConversionError()) && entities_conversion_pb.ConversionError.toObject(includeInstance, f),
      errorScreenshotUrlsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
      logTime: (f = msg.getLogTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.LogConversionTaskRequest}
 */


proto.LogConversionTaskRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.LogConversionTaskRequest();
  return proto.LogConversionTaskRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.LogConversionTaskRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.LogConversionTaskRequest}
 */


proto.LogConversionTaskRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setConversionTaskId(value);
        break;

      case 2:
        var value =
        /** @type {!proto.e.ConversionTaskStatus} */
        reader.readEnum();
        msg.setStatus(value);
        break;

      case 3:
        var value = new entities_conversion_pb.ConversionError();
        reader.readMessage(value, entities_conversion_pb.ConversionError.deserializeBinaryFromReader);
        msg.setConversionError(value);
        break;

      case 4:
        var value =
        /** @type {string} */
        reader.readString();
        msg.addErrorScreenshotUrls(value);
        break;

      case 5:
        var value = new google_protobuf_timestamp_pb.Timestamp();
        reader.readMessage(value, google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
        msg.setLogTime(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.LogConversionTaskRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.LogConversionTaskRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.LogConversionTaskRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.LogConversionTaskRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getConversionTaskId();

  if (f.length > 0) {
    writer.writeString(1, f);
  }

  f = message.getStatus();

  if (f !== 0.0) {
    writer.writeEnum(2, f);
  }

  f = message.getConversionError();

  if (f != null) {
    writer.writeMessage(3, f, entities_conversion_pb.ConversionError.serializeBinaryToWriter);
  }

  f = message.getErrorScreenshotUrlsList();

  if (f.length > 0) {
    writer.writeRepeatedString(4, f);
  }

  f = message.getLogTime();

  if (f != null) {
    writer.writeMessage(5, f, google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter);
  }
};
/**
 * optional string conversion_task_id = 1;
 * @return {string}
 */


proto.LogConversionTaskRequest.prototype.getConversionTaskId = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 1, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.LogConversionTaskRequest} returns this
 */


proto.LogConversionTaskRequest.prototype.setConversionTaskId = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional e.ConversionTaskStatus status = 2;
 * @return {!proto.e.ConversionTaskStatus}
 */


proto.LogConversionTaskRequest.prototype.getStatus = function () {
  return (
    /** @type {!proto.e.ConversionTaskStatus} */
    jspb.Message.getFieldWithDefault(this, 2, 0)
  );
};
/**
 * @param {!proto.e.ConversionTaskStatus} value
 * @return {!proto.LogConversionTaskRequest} returns this
 */


proto.LogConversionTaskRequest.prototype.setStatus = function (value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};
/**
 * optional e.ConversionError conversion_error = 3;
 * @return {?proto.e.ConversionError}
 */


proto.LogConversionTaskRequest.prototype.getConversionError = function () {
  return (
    /** @type{?proto.e.ConversionError} */
    jspb.Message.getWrapperField(this, entities_conversion_pb.ConversionError, 3)
  );
};
/**
 * @param {?proto.e.ConversionError|undefined} value
 * @return {!proto.LogConversionTaskRequest} returns this
*/


proto.LogConversionTaskRequest.prototype.setConversionError = function (value) {
  return jspb.Message.setWrapperField(this, 3, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.LogConversionTaskRequest} returns this
 */


proto.LogConversionTaskRequest.prototype.clearConversionError = function () {
  return this.setConversionError(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.LogConversionTaskRequest.prototype.hasConversionError = function () {
  return jspb.Message.getField(this, 3) != null;
};
/**
 * repeated string error_screenshot_urls = 4;
 * @return {!Array<string>}
 */


proto.LogConversionTaskRequest.prototype.getErrorScreenshotUrlsList = function () {
  return (
    /** @type {!Array<string>} */
    jspb.Message.getRepeatedField(this, 4)
  );
};
/**
 * @param {!Array<string>} value
 * @return {!proto.LogConversionTaskRequest} returns this
 */


proto.LogConversionTaskRequest.prototype.setErrorScreenshotUrlsList = function (value) {
  return jspb.Message.setField(this, 4, value || []);
};
/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.LogConversionTaskRequest} returns this
 */


proto.LogConversionTaskRequest.prototype.addErrorScreenshotUrls = function (value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.LogConversionTaskRequest} returns this
 */


proto.LogConversionTaskRequest.prototype.clearErrorScreenshotUrlsList = function () {
  return this.setErrorScreenshotUrlsList([]);
};
/**
 * optional google.protobuf.Timestamp log_time = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */


proto.LogConversionTaskRequest.prototype.getLogTime = function () {
  return (
    /** @type{?proto.google.protobuf.Timestamp} */
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5)
  );
};
/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.LogConversionTaskRequest} returns this
*/


proto.LogConversionTaskRequest.prototype.setLogTime = function (value) {
  return jspb.Message.setWrapperField(this, 5, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.LogConversionTaskRequest} returns this
 */


proto.LogConversionTaskRequest.prototype.clearLogTime = function () {
  return this.setLogTime(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.LogConversionTaskRequest.prototype.hasLogTime = function () {
  return jspb.Message.getField(this, 5) != null;
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */


proto.ListConversionTasksResponse.repeatedFields_ = [1];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.ListConversionTasksResponse.prototype.toObject = function (opt_includeInstance) {
    return proto.ListConversionTasksResponse.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.ListConversionTasksResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.ListConversionTasksResponse.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      conversionTasksList: jspb.Message.toObjectList(msg.getConversionTasksList(), entities_conversion_pb.ConversionTask.toObject, includeInstance),
      total: jspb.Message.getFieldWithDefault(msg, 2, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ListConversionTasksResponse}
 */


proto.ListConversionTasksResponse.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ListConversionTasksResponse();
  return proto.ListConversionTasksResponse.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ListConversionTasksResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ListConversionTasksResponse}
 */


proto.ListConversionTasksResponse.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value = new entities_conversion_pb.ConversionTask();
        reader.readMessage(value, entities_conversion_pb.ConversionTask.deserializeBinaryFromReader);
        msg.addConversionTasks(value);
        break;

      case 2:
        var value =
        /** @type {number} */
        reader.readInt32();
        msg.setTotal(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.ListConversionTasksResponse.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.ListConversionTasksResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ListConversionTasksResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.ListConversionTasksResponse.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getConversionTasksList();

  if (f.length > 0) {
    writer.writeRepeatedMessage(1, f, entities_conversion_pb.ConversionTask.serializeBinaryToWriter);
  }

  f = message.getTotal();

  if (f !== 0) {
    writer.writeInt32(2, f);
  }
};
/**
 * repeated e.ConversionTask conversion_tasks = 1;
 * @return {!Array<!proto.e.ConversionTask>}
 */


proto.ListConversionTasksResponse.prototype.getConversionTasksList = function () {
  return (
    /** @type{!Array<!proto.e.ConversionTask>} */
    jspb.Message.getRepeatedWrapperField(this, entities_conversion_pb.ConversionTask, 1)
  );
};
/**
 * @param {!Array<!proto.e.ConversionTask>} value
 * @return {!proto.ListConversionTasksResponse} returns this
*/


proto.ListConversionTasksResponse.prototype.setConversionTasksList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};
/**
 * @param {!proto.e.ConversionTask=} opt_value
 * @param {number=} opt_index
 * @return {!proto.e.ConversionTask}
 */


proto.ListConversionTasksResponse.prototype.addConversionTasks = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.e.ConversionTask, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ListConversionTasksResponse} returns this
 */


proto.ListConversionTasksResponse.prototype.clearConversionTasksList = function () {
  return this.setConversionTasksList([]);
};
/**
 * optional int32 total = 2;
 * @return {number}
 */


proto.ListConversionTasksResponse.prototype.getTotal = function () {
  return (
    /** @type {number} */
    jspb.Message.getFieldWithDefault(this, 2, 0)
  );
};
/**
 * @param {number} value
 * @return {!proto.ListConversionTasksResponse} returns this
 */


proto.ListConversionTasksResponse.prototype.setTotal = function (value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.SetFormAutomationTriggerRequest.prototype.toObject = function (opt_includeInstance) {
    return proto.SetFormAutomationTriggerRequest.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.SetFormAutomationTriggerRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.SetFormAutomationTriggerRequest.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, ""),
      formAutomationTrigger: (f = msg.getFormAutomationTrigger()) && entities_form_pb.FormAutomationTrigger.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SetFormAutomationTriggerRequest}
 */


proto.SetFormAutomationTriggerRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.SetFormAutomationTriggerRequest();
  return proto.SetFormAutomationTriggerRequest.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SetFormAutomationTriggerRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SetFormAutomationTriggerRequest}
 */


proto.SetFormAutomationTriggerRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setId(value);
        break;

      case 2:
        var value = new entities_form_pb.FormAutomationTrigger();
        reader.readMessage(value, entities_form_pb.FormAutomationTrigger.deserializeBinaryFromReader);
        msg.setFormAutomationTrigger(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.SetFormAutomationTriggerRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.SetFormAutomationTriggerRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SetFormAutomationTriggerRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.SetFormAutomationTriggerRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getId();

  if (f.length > 0) {
    writer.writeString(1, f);
  }

  f = message.getFormAutomationTrigger();

  if (f != null) {
    writer.writeMessage(2, f, entities_form_pb.FormAutomationTrigger.serializeBinaryToWriter);
  }
};
/**
 * optional string id = 1;
 * @return {string}
 */


proto.SetFormAutomationTriggerRequest.prototype.getId = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 1, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.SetFormAutomationTriggerRequest} returns this
 */


proto.SetFormAutomationTriggerRequest.prototype.setId = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional e.FormAutomationTrigger form_automation_trigger = 2;
 * @return {?proto.e.FormAutomationTrigger}
 */


proto.SetFormAutomationTriggerRequest.prototype.getFormAutomationTrigger = function () {
  return (
    /** @type{?proto.e.FormAutomationTrigger} */
    jspb.Message.getWrapperField(this, entities_form_pb.FormAutomationTrigger, 2)
  );
};
/**
 * @param {?proto.e.FormAutomationTrigger|undefined} value
 * @return {!proto.SetFormAutomationTriggerRequest} returns this
*/


proto.SetFormAutomationTriggerRequest.prototype.setFormAutomationTrigger = function (value) {
  return jspb.Message.setWrapperField(this, 2, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.SetFormAutomationTriggerRequest} returns this
 */


proto.SetFormAutomationTriggerRequest.prototype.clearFormAutomationTrigger = function () {
  return this.setFormAutomationTrigger(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.SetFormAutomationTriggerRequest.prototype.hasFormAutomationTrigger = function () {
  return jspb.Message.getField(this, 2) != null;
};

goog.object.extend(exports, proto);