var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import camelCase from 'lodash/camelCase';
import get from 'lodash/get';
import { tabTypes } from '@src/lib/app-constants';
import generateEffect from '@src/lib/helpers/form_modal_schema/generateEffect';
export default function (_a) {
    var _b, _c;
    var _d;
    var intl = _a.intl, platform = _a.platform, object = _a.object, schema = _a.schema, defaultEffect = _a.defaultEffect, _e = _a.postbackActionPath, postbackActionPath = _e === void 0 ? 'postback_action' : _e;
    var _f = get(object, postbackActionPath) || {}, block_id = _f.block_id, scenario_id = _f.scenario_id, url = _f.url;
    // This flag to handle validate when mount action form, after any handle
    // change, it become false
    var _g = __read(useState(true), 2), validateOnMount = _g[0], setValidateOnMount = _g[1];
    /**
     * Init effect will be get from postback action or generate from
     * block_id, scenario_id and url, it all return null, it will be
     * set to default value is BLOCK
     */
    var initEffect = ((_d = get(object, postbackActionPath)) === null || _d === void 0 ? void 0 : _d.effect) ||
        generateEffect(block_id, scenario_id, url) ||
        defaultEffect ||
        tabTypes.BLOCK;
    var _h = __read(useState(initEffect), 2), effect = _h[0], setEffect = _h[1];
    var formValidator = useFormik({
        initialValues: __assign(__assign({}, object), (_b = {}, _b[postbackActionPath] = __assign(__assign({}, (get(object, postbackActionPath) || {})), { effect: initEffect }), _b)),
        validateOnMount: validateOnMount,
        validationSchema: schema(intl, platform, { effect: effect }),
        onSubmit: function () {
            /* do nothing */
        },
    });
    var setFieldValue = formValidator.setFieldValue, setFieldTouched = formValidator.setFieldTouched, validateForm = formValidator.validateForm;
    // This method will be set value and touched
    var handleChange = function (_a) {
        var _b = _a.target, name = _b.name, value = _b.value;
        setFieldValue(name, value, true);
        setFieldTouched(name, true);
        setValidateOnMount(false);
    };
    // This method will update postback action to values, it will call when select
    // disconnect, create block, scenario
    var handleChangePostbackAction = function (postbackAction) {
        var _a = postbackAction || {}, block_id = _a.block_id, scenario_id = _a.scenario_id, url = _a.url;
        setFieldValue(postbackActionPath, __assign(__assign({}, (postbackAction || {})), { effect: (postbackAction === null || postbackAction === void 0 ? void 0 : postbackAction.effect) ||
                generateEffect(block_id, scenario_id, url) ||
                tabTypes.BLOCK }));
    };
    useEffect(function () {
        return handleChange({
            target: {
                name: postbackActionPath + ".effect",
                value: effect,
            },
        });
    }, [effect]);
    /**
     * This method will be use for action seletor component
     * @params newBlock, selectBlock, disconnectBlock, selectScenario,
     * disconnectScenario is method that modal will pass to do depend on action
     */
    var actionSelectorOnChange = function (_a) {
        var newBlock = _a.newBlock, selectBlock = _a.selectBlock, disconnectBlock = _a.disconnectBlock, selectScenario = _a.selectScenario, disconnectScenario = _a.disconnectScenario;
        return function (action) {
            switch (Object.keys(action)[0]) {
                case 'effect':
                    // This step will change effect on this local state, after that will
                    // call handle change method to update postback action effect
                    return setEffect(action.effect);
                case 'blockName':
                    return newBlock && newBlock(action.blockName);
                case 'blockId':
                    return action.blockId
                        ? selectBlock && selectBlock(action.blockId)
                        : disconnectBlock && disconnectBlock();
                case 'scenarioId':
                    return action.scenarioId
                        ? selectScenario && selectScenario(action.scenarioId)
                        : disconnectScenario && disconnectScenario();
                case 'url':
                case 'email':
                case 'phoneNumber':
                    // this condition is prevent from bug that input
                    // 'tel:' or 'mailto:' in URL tab
                    if (Object.keys(action)[0] === 'url' &&
                        (action.url.startsWith('tel:') || action.url.startsWith('mailto:')))
                        return null;
                    return handleChange({
                        target: {
                            name: postbackActionPath + ".url",
                            value: Object.values(action)[0],
                        },
                    });
                default:
            }
            return null;
        };
    };
    var initActionSelectorValueEffect;
    switch (initEffect) {
        case tabTypes.EMAIL:
            initActionSelectorValueEffect = url === null || url === void 0 ? void 0 : url.replace('mailto:', '');
            break;
        case tabTypes.PHONE_NUMBER:
            initActionSelectorValueEffect = url === null || url === void 0 ? void 0 : url.replace('tel:', '');
            break;
        case tabTypes.URL:
            initActionSelectorValueEffect = url;
            break;
        default:
            break;
    }
    // This object will be use for action seletor init value
    var initActionSelectorValue = (_c = {
            effect: initEffect
        },
        _c[camelCase(effect)] = initActionSelectorValueEffect,
        _c);
    return __assign(__assign({}, formValidator), { handleChange: handleChange,
        handleChangePostbackAction: handleChangePostbackAction,
        actionSelectorOnChange: actionSelectorOnChange,
        initActionSelectorValue: initActionSelectorValue,
        validateForm: validateForm });
}
