import { object, string } from 'yup';
import { addMaxLength } from '@src/lib/helpers';
import { getErrorMessage } from '@src/lib/yupSchemaCreator';
import postbackActionSchema from './postbackActionSchema';
/**
 * This schema for RichMenuAreaModal, it will return errors object to
 * use for validate on this modal
 * this errors object will contain name and postback_action
 *
 * @param richMenuAreaId - id of rich menu area
 * @param richMenuAreaArr - array of rich menu area
 */
export default (function (richMenuAreaId, richMenuAreaArr) { return function (intl, platform, context) {
    return object({
        name: addMaxLength(string()
            .required(getErrorMessage(intl, intl.formatMessage({ id: "753yX5", defaultMessage: "Label" })))
            .notOneOf(richMenuAreaArr
            .filter(function (richMenuArea) { return richMenuArea.id !== richMenuAreaId; })
            .map(function (richMenuArea) { return richMenuArea.label; }), intl.formatMessage({ id: "CwbRFq", defaultMessage: "Label is already being used." })), intl, 'postback_action.label', platform),
        postback_action: postbackActionSchema(intl, platform, context, {}),
    });
}; });
