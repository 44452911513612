import { createRoot } from 'react-dom/client';
/**
 * This method will render element node on hidden div then remove it
 * created, it will store position and side for build elements data
 *
 * @params elementNode - is react element
 */
export default function draftRendering(elementNode) {
    var container = document.createElement('div');
    container.style.visibility = 'hidden';
    container.style.position = 'absolute';
    container.style.top = '0px';
    container.style.left = '0px';
    container.style.width = '100%';
    container.style.height = '100%';
    container.style.overflow = 'hidden';
    document.body.appendChild(container);
    var root = createRoot(container);
    root.render(elementNode);
    return container;
}
