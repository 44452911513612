import "core-js/modules/es.array.concat";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";

var accountCollection = function accountCollection() {
  return "/integration_plugins/account_modals";
};

var collection = function collection(_ref) {
  var chatbotId = _ref.chatbotId;
  return "/integration_plugins/chatbots/".concat(chatbotId, "/modals");
};

var member = function member(_ref2) {
  var modalId = _ref2.modalId,
      rest = _objectWithoutProperties(_ref2, ["modalId"]);

  return "".concat(collection(rest), "/").concat(modalId);
};

export default {
  accountCollection: accountCollection,
  collection: collection,
  member: member
};