import "core-js/modules/es.array.filter";
import "core-js/modules/es.array.map";
import "core-js/modules/es.function.name";
import React, { Fragment } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { colors } from '@src/colors';
import { transitions } from '@src/lib/animations';
var propTypes = {
  filter: PropTypes.object
};
var Container = styled.div.withConfig({
  displayName: "Container",
  componentId: "sc-1to84dy-0"
})(["display:flex;font-size:12px;margin-top:16px;padding:8px 0;border-top:1px solid ", ";align-items:center;span{color:", ";}"], colors.border.pale, colors.text_pale);
var UserTagContainer = styled.div.withConfig({
  displayName: "UserTagContainer",
  componentId: "sc-1to84dy-1"
})(["height:24px;font-size:12px;background:", ";border:", ";padding:", ";color:", ";border-radius:100px;display:flex;flex-shrink:0;align-items:center;margin:4px 8px 0 0;transition:", ";&:last-child{margin-right:0;}.adicon_close{margin-left:5px;visibility:hidden;color:", ";opacity:0.5;}&:hover{cursor:pointer;.adicon_close{visibility:visible;cursor:pointer;}}"], colors.background.tag, function (props) {
  return props.borderColor ? "1px solid ".concat(props.borderColor) : 'unset';
}, function (props) {
  return props.displayOnly ? '6px 1rem' : '6px 0.4rem 6px 1rem';
}, colors.text_pale, transitions.normal, colors.icon);
var Tag = styled(UserTagContainer).withConfig({
  displayName: "Tag",
  componentId: "sc-1to84dy-2"
})(["color:", ";margin:0;padding:6px 1rem;margin:0 2px;"], colors.text_pale);

var FilterInfo = function FilterInfo(_ref) {
  var filter = _ref.filter;
  var tags = filter.tags,
      user_response = filter.user_response;
  return tags.length > 0 || user_response.length > 0 ? React.createElement(Container, null, React.createElement("p", null, "\u7D5E\u308A\u8FBC\u307F\u306E\u30BF\u30A4\u30D7"), React.createElement("span", null, "\u5168\u3066\u306B\u4E00\u81F4,"), user_response.length > 0 ? React.createElement(Fragment, null, React.createElement("p", null, "\u30AF\u30A4\u30C3\u30AF\u30EA\u30D7\u30E9\u30A4\u306E\u56DE\u7B54"), user_response.map(function (response, i) {
    return React.createElement(Fragment, {
      key: "".concat(i, "-filter-quick-reply")
    }, React.createElement("span", null, "".concat(response.question_label, "\uFF1A")), React.createElement("span", null, "".concat(response.answer_label)), React.createElement("span", null, "\uFF1A\u56DE\u7B54\u3057\u305F, "));
  })) : null, tags.length > 0 ? React.createElement(Fragment, null, React.createElement("p", null, "\u30BF\u30B0 \u542B\u3080\uFF1A"), tags.map(function (tag, i) {
    return React.createElement(Tag, {
      key: "".concat(i, "-filter-include-tag")
    }, tag.name);
  })) : null) : null;
};

FilterInfo.propTypes = propTypes;
export default FilterInfo;