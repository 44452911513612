var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import styled from 'styled-components';
import ReactJSPagination from 'react-js-pagination';
import { colors } from '@src/colors';
import { centeredFlex } from '@src/lib/mixins';
var PaginationWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  height: 20%;\n  ", "\n\n  ul {\n    margin: unset;\n\n    li > .item {\n      border: 1px solid ", ";\n      width: 40px;\n      height: 40px;\n      color: inherit;\n      margin: 0 2px;\n      line-height: 1.5;\n      text-align: center;\n      border-radius: 50%;\n    }\n\n    li > .item-active {\n      background-color: ", ";\n      color: ", ";\n    }\n\n    li > .item-prev {\n      line-height: 20px;\n    }\n\n    li > .item-next {\n      line-height: 20px;\n    }\n  }\n"], ["\n  height: 20%;\n  ", "\n\n  ul {\n    margin: unset;\n\n    li > .item {\n      border: 1px solid ", ";\n      width: 40px;\n      height: 40px;\n      color: inherit;\n      margin: 0 2px;\n      line-height: 1.5;\n      text-align: center;\n      border-radius: 50%;\n    }\n\n    li > .item-active {\n      background-color: ", ";\n      color: ", ";\n    }\n\n    li > .item-prev {\n      line-height: 20px;\n    }\n\n    li > .item-next {\n      line-height: 20px;\n    }\n  }\n"])), centeredFlex, colors.default_text, colors.default_text, colors.white);
var Pagination = function (_a) {
    var itemsCountPerPage = _a.itemsCountPerPage, pageRangeDisplayed = _a.pageRangeDisplayed, rest = __rest(_a, ["itemsCountPerPage", "pageRangeDisplayed"]);
    return (React.createElement(PaginationWrapper, { "data-testid": "Pagination" },
        React.createElement(ReactJSPagination, __assign({ hideFirstLastPages: true, prevPageText: React.createElement("span", { className: "adicon_arrow_left" }), nextPageText: React.createElement("span", { className: "adicon_arrow_right" }), itemsCountPerPage: itemsCountPerPage || 10, pageRangeDisplayed: pageRangeDisplayed || 5, linkClass: "item", linkClassPrev: "item-prev", linkClassNext: "item-next", activeLinkClass: "item-active" }, rest))));
};
export default Pagination;
var templateObject_1;
