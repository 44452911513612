import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import TimeInput from '@src/components/molecules/TimeInput';
var propTypes = {
  i: PropTypes.number,
  time: PropTypes.object,
  handleOnTimeChange: PropTypes.func
};

var CampaignTime = function CampaignTime(_ref) {
  var i = _ref.i,
      time = _ref.time,
      handleOnTimeChange = _ref.handleOnTimeChange,
      intl = _ref.intl;
  return React.createElement("div", {
    className: "input_with_text_container"
  }, React.createElement("div", {
    className: "label-header"
  }, intl.formatMessage({
    id: "mnvhLU",
    defaultMessage: [{
      "type": 0,
      "value": "Time Specification"
    }]
  })), React.createElement("div", null, React.createElement(TimeInput, {
    value: time.start_at,
    onTimeChange: function onTimeChange(val) {
      return handleOnTimeChange(i, 'start_at', val);
    },
    onBlurHandler: function onBlurHandler(val) {
      return handleOnTimeChange(i, 'hasError', !val);
    }
  }), time.end_at !== null ? React.createElement(Fragment, null, React.createElement("span", {
    style: {
      margin: '8px'
    }
  }, "\u301C"), React.createElement(TimeInput, {
    value: time.end_at,
    onTimeChange: function onTimeChange(val) {
      return handleOnTimeChange(i, 'end_at', val);
    },
    onBlurHandler: function onBlurHandler(e) {
      return handleOnTimeChange(i, 'hasError', !e.target.value);
    }
  }), React.createElement("span", {
    className: "adicon_minus01",
    style: {
      cursor: 'pointer'
    },
    onClick: function onClick() {
      return handleOnTimeChange(i, 'end_at', null);
    }
  })) : React.createElement(Fragment, null, React.createElement("span", {
    onClick: function onClick() {
      return handleOnTimeChange(i, 'end_at', '');
    }
  }, intl.formatMessage({
    id: "3d/R7g",
    defaultMessage: [{
      "type": 0,
      "value": "Specify Range"
    }]
  })), React.createElement("span", {
    className: "adicon_minus01",
    style: {
      cursor: 'pointer'
    },
    onClick: function onClick() {
      return handleOnTimeChange(i, 'start_at', null);
    }
  }))));
};

CampaignTime.propTypes = propTypes;
export default CampaignTime;