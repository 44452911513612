export var WorkPanelType;
(function (WorkPanelType) {
    WorkPanelType["SET_CUSTOM_FORM"] = "SET_CUSTOM_FORM";
    WorkPanelType["REMOVE_FORM"] = "REMOVE_FORM";
    WorkPanelType["UPDATE_CUSTOM_FORM_PROPERTY"] = "UPDATE_CUSTOM_FORM_PROPERTY";
    WorkPanelType["ADD_CUSTOM_FORM"] = "ADD_CUSTOM_FORM";
    WorkPanelType["SET_QUESTION_GROUP"] = "SET_QUESTION_GROUP";
    WorkPanelType["MOVE_QUESTION_GROUP"] = "MOVE_QUESTION_GROUP";
    WorkPanelType["MOVE_QUESTION"] = "MOVE_QUESTION";
    WorkPanelType["ADD_QUESTION_GROUP"] = "ADD_QUESTION_GROUP";
    WorkPanelType["ADD_QUESTION"] = "ADD_QUESTION";
    WorkPanelType["REMOVE_QUESTION_GROUP"] = "REMOVE_QUESTION_GROUP";
    WorkPanelType["REMOVE_QUESTION"] = "REMOVE_QUESTION";
    WorkPanelType["UPDATE_QUESTION"] = "UPDATE_QUESTION";
    WorkPanelType["SET_END_USERS"] = "SET_END_USERS";
    WorkPanelType["SET_SCENARIO"] = "SET_SCENARIO";
    WorkPanelType["SET_SCENARIOS"] = "SET_SCENARIOS";
    WorkPanelType["HOVER_HEADLESS_STEP"] = "HOVER_HEADLESS_STEP";
    WorkPanelType["DISCONNECT_TALK_TRIGGER"] = "DISCONNECT_TALK_TRIGGER";
})(WorkPanelType || (WorkPanelType = {}));
