import "core-js/modules/es.array.find";
import React, { Fragment } from 'react';
import styled from 'styled-components';
import { colors } from '@src/colors';
import Select from 'react-select';
import { ModalPortal } from '@src/components/atoms';
import modalTypes from '@src/lib/modalTypes';
import Modal from '@src/components/molecules/Modal';
var InputContainer = styled.div.withConfig({
  displayName: "InputContainer",
  componentId: "sc-9e5f3u-0"
})(["padding:3px;height:400px;.input_content{}input{width:100%;border:1px solid ", ";border-radius:5px;padding:16px;}input[type='number']{width:30%;height:40px;}"], colors.border.default);
InputContainer.displayName = 'InputContainer';

var SwitchConditionInput = function SwitchConditionInput(_ref) {
  var strVal = _ref.strVal,
      intVal = _ref.intVal,
      condType = _ref.condType,
      handleStrCondition = _ref.handleStrCondition,
      handleIntCondition = _ref.handleIntCondition;

  switch (condType) {
    case 'str':
      return React.createElement(Fragment, null, React.createElement("div", {
        className: "message-wrapper primary-label",
        style: {
          margin: '24px 0 12px 8px'
        }
      }, "\u30C8\u30EA\u30AC\u30FC\u6761\u4EF6\uFF08\u6587\u5B57\uFF09"), React.createElement("input", {
        type: "text",
        placeholder: "\u6761\u4EF6\u3092\u5165\u529B",
        onChange: handleStrCondition,
        value: strVal
      }));

    case 'int':
      return React.createElement(Fragment, null, React.createElement("div", {
        className: "message-wrapper primary-label",
        style: {
          margin: '24px 0 12px 8px'
        }
      }, "\u30C8\u30EA\u30AC\u30FC\u6761\u4EF6\uFF08\u6570\u5B57\uFF09"), React.createElement("input", {
        type: "number",
        placeholder: "\u6761\u4EF6\u3092\u5165\u529B",
        min: "0",
        onChange: handleIntCondition,
        value: intVal
      }));

    default:
      return null;
  }
};

var PluginEventTriggerModal = function PluginEventTriggerModal(_ref2) {
  var onConfirm = _ref2.onConfirm,
      onCancel = _ref2.onCancel,
      handleStrCondition = _ref2.handleStrCondition,
      handleIntCondition = _ref2.handleIntCondition,
      strVal = _ref2.strVal,
      intVal = _ref2.intVal,
      selectTriggerType = _ref2.selectTriggerType,
      selectTriggerDeviceType = _ref2.selectTriggerDeviceType,
      triggerTypeOptions = _ref2.triggerTypeOptions,
      deviceTypeOptions = _ref2.deviceTypeOptions,
      condType = _ref2.condType,
      deviceType = _ref2.deviceType,
      triggerType = _ref2.triggerType;
  return React.createElement(ModalPortal, null, React.createElement(Modal, {
    "data-testid": "plugin-event-trigger-modal",
    text: "\u767A\u706B\u6761\u4EF6\u3068\u8868\u793A\u30C7\u30D0\u30A4\u30B9\u3092\u9078\u629E\u3057\u3066\u304F\u3060\u3055\u3044",
    type: modalTypes.SELECT,
    onConfirm: onConfirm,
    onCancel: onCancel
  }, React.createElement(InputContainer, null, React.createElement("div", {
    className: "input_content"
  }, React.createElement("div", {
    className: "message-wrapper primary-label",
    style: {
      margin: '24px 0 12px 8px'
    }
  }, "\u30C8\u30EA\u30AC\u30FC\u30BF\u30A4\u30D7"), React.createElement(Select, {
    value: triggerTypeOptions.find(function (option) {
      return option.value === triggerType;
    }),
    onChange: selectTriggerType,
    options: triggerTypeOptions,
    placeholder: "\u30C8\u30EA\u30AC\u30FC\u30BF\u30A4\u30D7\u3092\u9078\u629E\u3057\u3066\u304F\u3060\u3055\u3044",
    className: "selector"
  })), React.createElement("div", {
    className: "input_content"
  }, React.createElement(SwitchConditionInput, {
    strVal: strVal,
    intVal: intVal,
    condType: condType,
    handleStrCondition: handleStrCondition,
    handleIntCondition: handleIntCondition
  })), React.createElement("div", {
    className: "input_content"
  }, React.createElement("div", {
    className: "message-wrapper primary-label",
    style: {
      margin: '24px 0 12px 8px'
    }
  }, "\u30C7\u30D0\u30A4\u30B9\u30BF\u30A4\u30D7"), React.createElement(Select, {
    value: deviceTypeOptions.find(function (option) {
      return option.value === deviceType;
    }),
    onChange: selectTriggerDeviceType,
    options: deviceTypeOptions,
    placeholder: "\u8868\u793A\u3059\u308B\u30C7\u30D0\u30A4\u30B9\u3092\u9078\u629E\u3057\u3066\u304F\u3060\u3055\u3044",
    className: "selector"
  })))));
};

export default PluginEventTriggerModal;