/**
 * @fileoverview gRPC-Web generated client stub for
 * @enhanceable
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck
import * as grpcWeb from 'grpc-web';
import * as entities_entities_pb from '../entities/entities_pb';
import * as entities_form_pb from '../entities/form_pb';
import * as services_form_pb from '../services/form_pb';
var FormServiceClient = /** @class */ (function () {
    function FormServiceClient(hostname, credentials, options) {
        this.methodInfoListFormTemplates = new grpcWeb.AbstractClientBase.MethodInfo(services_form_pb.ListFormTemplatesResponse, function (request) {
            return request.serializeBinary();
        }, services_form_pb.ListFormTemplatesResponse.deserializeBinary);
        this.methodInfoCreateFormTemplate = new grpcWeb.AbstractClientBase.MethodInfo(entities_form_pb.FormTemplate, function (request) {
            return request.serializeBinary();
        }, entities_form_pb.FormTemplate.deserializeBinary);
        this.methodInfoUpdateFormTemplate = new grpcWeb.AbstractClientBase.MethodInfo(entities_form_pb.FormTemplate, function (request) {
            return request.serializeBinary();
        }, entities_form_pb.FormTemplate.deserializeBinary);
        this.methodInfoDeleteFormTemplate = new grpcWeb.AbstractClientBase.MethodInfo(entities_entities_pb.Empty, function (request) {
            return request.serializeBinary();
        }, entities_entities_pb.Empty.deserializeBinary);
        this.methodInfoListForms = new grpcWeb.AbstractClientBase.MethodInfo(services_form_pb.ListFormsResponse, function (request) {
            return request.serializeBinary();
        }, services_form_pb.ListFormsResponse.deserializeBinary);
        this.methodInfoGetForm = new grpcWeb.AbstractClientBase.MethodInfo(entities_form_pb.Form, function (request) {
            return request.serializeBinary();
        }, entities_form_pb.Form.deserializeBinary);
        this.methodInfoCreateForm = new grpcWeb.AbstractClientBase.MethodInfo(entities_form_pb.Form, function (request) {
            return request.serializeBinary();
        }, entities_form_pb.Form.deserializeBinary);
        this.methodInfoUpdateForm = new grpcWeb.AbstractClientBase.MethodInfo(entities_form_pb.Form, function (request) {
            return request.serializeBinary();
        }, entities_form_pb.Form.deserializeBinary);
        this.methodInfoListFormIDs = new grpcWeb.AbstractClientBase.MethodInfo(services_form_pb.ListFormIDsResponse, function (request) {
            return request.serializeBinary();
        }, services_form_pb.ListFormIDsResponse.deserializeBinary);
        this.methodInfoConvertFormToFormBuild = new grpcWeb.AbstractClientBase.MethodInfo(services_form_pb.ConvertFormToFormBuildResponse, function (request) {
            return request.serializeBinary();
        }, services_form_pb.ConvertFormToFormBuildResponse.deserializeBinary);
        this.methodInfoDeleteForm = new grpcWeb.AbstractClientBase.MethodInfo(entities_entities_pb.Empty, function (request) {
            return request.serializeBinary();
        }, entities_entities_pb.Empty.deserializeBinary);
        this.methodInfoListFormElementTemplates = new grpcWeb.AbstractClientBase.MethodInfo(services_form_pb.ListFormElementTemplatesResponse, function (request) {
            return request.serializeBinary();
        }, services_form_pb.ListFormElementTemplatesResponse.deserializeBinary);
        this.methodInfoCreateFormElementTemplate = new grpcWeb.AbstractClientBase.MethodInfo(entities_form_pb.FormElementTemplate, function (request) {
            return request.serializeBinary();
        }, entities_form_pb.FormElementTemplate.deserializeBinary);
        this.methodInfoUpdateFormElementTemplate = new grpcWeb.AbstractClientBase.MethodInfo(entities_form_pb.FormElementTemplate, function (request) {
            return request.serializeBinary();
        }, entities_form_pb.FormElementTemplate.deserializeBinary);
        this.methodInfoDeleteFormElementTemplate = new grpcWeb.AbstractClientBase.MethodInfo(entities_entities_pb.Empty, function (request) {
            return request.serializeBinary();
        }, entities_entities_pb.Empty.deserializeBinary);
        this.methodInfoReceiveEndUserForm = new grpcWeb.AbstractClientBase.MethodInfo(entities_entities_pb.Empty, function (request) {
            return request.serializeBinary();
        }, entities_entities_pb.Empty.deserializeBinary);
        this.methodInfoGetEndUserForm = new grpcWeb.AbstractClientBase.MethodInfo(entities_form_pb.EndUserForm, function (request) {
            return request.serializeBinary();
        }, entities_form_pb.EndUserForm.deserializeBinary);
        this.methodInfoListFormElementValidations = new grpcWeb.AbstractClientBase.MethodInfo(services_form_pb.ListFormElementValidationsResponse, function (request) {
            return request.serializeBinary();
        }, services_form_pb.ListFormElementValidationsResponse.deserializeBinary);
        this.methodInfoGetFormElementValidation = new grpcWeb.AbstractClientBase.MethodInfo(entities_form_pb.FormElementValidation, function (request) {
            return request.serializeBinary();
        }, entities_form_pb.FormElementValidation.deserializeBinary);
        this.methodInfoCreateFormElementValidation = new grpcWeb.AbstractClientBase.MethodInfo(entities_form_pb.FormElementValidation, function (request) {
            return request.serializeBinary();
        }, entities_form_pb.FormElementValidation.deserializeBinary);
        this.methodInfoUpdateFormElementValidation = new grpcWeb.AbstractClientBase.MethodInfo(entities_form_pb.FormElementValidation, function (request) {
            return request.serializeBinary();
        }, entities_form_pb.FormElementValidation.deserializeBinary);
        this.methodInfoDeleteFormElementValidation = new grpcWeb.AbstractClientBase.MethodInfo(entities_entities_pb.Empty, function (request) {
            return request.serializeBinary();
        }, entities_entities_pb.Empty.deserializeBinary);
        this.methodInfoListFormBuilds = new grpcWeb.AbstractClientBase.MethodInfo(services_form_pb.ListFormBuildsResponse, function (request) {
            return request.serializeBinary();
        }, services_form_pb.ListFormBuildsResponse.deserializeBinary);
        this.methodInfoGetFormBuild = new grpcWeb.AbstractClientBase.MethodInfo(entities_form_pb.FormBuild, function (request) {
            return request.serializeBinary();
        }, entities_form_pb.FormBuild.deserializeBinary);
        this.methodInfoCreateFormBuild = new grpcWeb.AbstractClientBase.MethodInfo(entities_form_pb.FormBuild, function (request) {
            return request.serializeBinary();
        }, entities_form_pb.FormBuild.deserializeBinary);
        this.methodInfoUpdateFormBuild = new grpcWeb.AbstractClientBase.MethodInfo(entities_form_pb.FormBuild, function (request) {
            return request.serializeBinary();
        }, entities_form_pb.FormBuild.deserializeBinary);
        this.methodInfoUpdateFormBuildStatus = new grpcWeb.AbstractClientBase.MethodInfo(entities_entities_pb.Empty, function (request) {
            return request.serializeBinary();
        }, entities_entities_pb.Empty.deserializeBinary);
        this.methodInfoDeleteFormBuild = new grpcWeb.AbstractClientBase.MethodInfo(entities_entities_pb.Empty, function (request) {
            return request.serializeBinary();
        }, entities_entities_pb.Empty.deserializeBinary);
        this.methodInfoGetFormScenario = new grpcWeb.AbstractClientBase.MethodInfo(entities_form_pb.FormScenario, function (request) {
            return request.serializeBinary();
        }, entities_form_pb.FormScenario.deserializeBinary);
        this.methodInfoCreateFormScenario = new grpcWeb.AbstractClientBase.MethodInfo(entities_form_pb.FormScenario, function (request) {
            return request.serializeBinary();
        }, entities_form_pb.FormScenario.deserializeBinary);
        this.methodInfoUpdateFormScenario = new grpcWeb.AbstractClientBase.MethodInfo(entities_form_pb.FormScenario, function (request) {
            return request.serializeBinary();
        }, entities_form_pb.FormScenario.deserializeBinary);
        this.methodInfoDeleteFormScenario = new grpcWeb.AbstractClientBase.MethodInfo(entities_entities_pb.Empty, function (request) {
            return request.serializeBinary();
        }, entities_entities_pb.Empty.deserializeBinary);
        this.methodInfoGetFormQuestionLists = new grpcWeb.AbstractClientBase.MethodInfo(services_form_pb.GetFormQuestionListsResponse, function (request) {
            return request.serializeBinary();
        }, services_form_pb.GetFormQuestionListsResponse.deserializeBinary);
        this.methodInfoUpsertFormQuestions = new grpcWeb.AbstractClientBase.MethodInfo(services_form_pb.UpsertFormQuestionsResponse, function (request) {
            return request.serializeBinary();
        }, services_form_pb.UpsertFormQuestionsResponse.deserializeBinary);
        this.methodInfoUpdateFormQuestion = new grpcWeb.AbstractClientBase.MethodInfo(entities_form_pb.FormQuestion, function (request) {
            return request.serializeBinary();
        }, entities_form_pb.FormQuestion.deserializeBinary);
        this.methodInfoGetFormQuestion = new grpcWeb.AbstractClientBase.MethodInfo(entities_form_pb.FormQuestion, function (request) {
            return request.serializeBinary();
        }, entities_form_pb.FormQuestion.deserializeBinary);
        this.methodInfoReceiveEndUserMessage = new grpcWeb.AbstractClientBase.MethodInfo(entities_entities_pb.Empty, function (request) {
            return request.serializeBinary();
        }, entities_entities_pb.Empty.deserializeBinary);
        this.methodInfoListConversionTasks = new grpcWeb.AbstractClientBase.MethodInfo(services_form_pb.ListConversionTasksResponse, function (request) {
            return request.serializeBinary();
        }, services_form_pb.ListConversionTasksResponse.deserializeBinary);
        this.methodInfoLogConversionTask = new grpcWeb.AbstractClientBase.MethodInfo(entities_entities_pb.Empty, function (request) {
            return request.serializeBinary();
        }, entities_entities_pb.Empty.deserializeBinary);
        this.methodInfoSetFormAutomationTrigger = new grpcWeb.AbstractClientBase.MethodInfo(entities_form_pb.FormBuild, function (request) {
            return request.serializeBinary();
        }, entities_form_pb.FormBuild.deserializeBinary);
        if (!options)
            options = {};
        if (!credentials)
            credentials = {};
        options['format'] = 'text';
        this.client_ = new grpcWeb.GrpcWebClientBase(options);
        this.hostname_ = hostname;
        this.credentials_ = credentials;
        this.options_ = options;
    }
    FormServiceClient.prototype.listFormTemplates = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/FormService/ListFormTemplates', request, metadata || {}, this.methodInfoListFormTemplates, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/FormService/ListFormTemplates', request, metadata || {}, this.methodInfoListFormTemplates);
    };
    FormServiceClient.prototype.createFormTemplate = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/FormService/CreateFormTemplate', request, metadata || {}, this.methodInfoCreateFormTemplate, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/FormService/CreateFormTemplate', request, metadata || {}, this.methodInfoCreateFormTemplate);
    };
    FormServiceClient.prototype.updateFormTemplate = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/FormService/UpdateFormTemplate', request, metadata || {}, this.methodInfoUpdateFormTemplate, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/FormService/UpdateFormTemplate', request, metadata || {}, this.methodInfoUpdateFormTemplate);
    };
    FormServiceClient.prototype.deleteFormTemplate = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/FormService/DeleteFormTemplate', request, metadata || {}, this.methodInfoDeleteFormTemplate, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/FormService/DeleteFormTemplate', request, metadata || {}, this.methodInfoDeleteFormTemplate);
    };
    FormServiceClient.prototype.listForms = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/FormService/ListForms', request, metadata || {}, this.methodInfoListForms, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/FormService/ListForms', request, metadata || {}, this.methodInfoListForms);
    };
    FormServiceClient.prototype.getForm = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/FormService/GetForm', request, metadata || {}, this.methodInfoGetForm, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/FormService/GetForm', request, metadata || {}, this.methodInfoGetForm);
    };
    FormServiceClient.prototype.createForm = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/FormService/CreateForm', request, metadata || {}, this.methodInfoCreateForm, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/FormService/CreateForm', request, metadata || {}, this.methodInfoCreateForm);
    };
    FormServiceClient.prototype.updateForm = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/FormService/UpdateForm', request, metadata || {}, this.methodInfoUpdateForm, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/FormService/UpdateForm', request, metadata || {}, this.methodInfoUpdateForm);
    };
    FormServiceClient.prototype.listFormIDs = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/FormService/ListFormIDs', request, metadata || {}, this.methodInfoListFormIDs, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/FormService/ListFormIDs', request, metadata || {}, this.methodInfoListFormIDs);
    };
    FormServiceClient.prototype.convertFormToFormBuild = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/FormService/ConvertFormToFormBuild', request, metadata || {}, this.methodInfoConvertFormToFormBuild, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/FormService/ConvertFormToFormBuild', request, metadata || {}, this.methodInfoConvertFormToFormBuild);
    };
    FormServiceClient.prototype.deleteForm = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/FormService/DeleteForm', request, metadata || {}, this.methodInfoDeleteForm, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/FormService/DeleteForm', request, metadata || {}, this.methodInfoDeleteForm);
    };
    FormServiceClient.prototype.listFormElementTemplates = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/FormService/ListFormElementTemplates', request, metadata || {}, this.methodInfoListFormElementTemplates, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/FormService/ListFormElementTemplates', request, metadata || {}, this.methodInfoListFormElementTemplates);
    };
    FormServiceClient.prototype.createFormElementTemplate = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/FormService/CreateFormElementTemplate', request, metadata || {}, this.methodInfoCreateFormElementTemplate, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/FormService/CreateFormElementTemplate', request, metadata || {}, this.methodInfoCreateFormElementTemplate);
    };
    FormServiceClient.prototype.updateFormElementTemplate = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/FormService/UpdateFormElementTemplate', request, metadata || {}, this.methodInfoUpdateFormElementTemplate, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/FormService/UpdateFormElementTemplate', request, metadata || {}, this.methodInfoUpdateFormElementTemplate);
    };
    FormServiceClient.prototype.deleteFormElementTemplate = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/FormService/DeleteFormElementTemplate', request, metadata || {}, this.methodInfoDeleteFormElementTemplate, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/FormService/DeleteFormElementTemplate', request, metadata || {}, this.methodInfoDeleteFormElementTemplate);
    };
    FormServiceClient.prototype.receiveEndUserForm = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/FormService/ReceiveEndUserForm', request, metadata || {}, this.methodInfoReceiveEndUserForm, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/FormService/ReceiveEndUserForm', request, metadata || {}, this.methodInfoReceiveEndUserForm);
    };
    FormServiceClient.prototype.getEndUserForm = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/FormService/GetEndUserForm', request, metadata || {}, this.methodInfoGetEndUserForm, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/FormService/GetEndUserForm', request, metadata || {}, this.methodInfoGetEndUserForm);
    };
    FormServiceClient.prototype.listFormElementValidations = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/FormService/ListFormElementValidations', request, metadata || {}, this.methodInfoListFormElementValidations, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/FormService/ListFormElementValidations', request, metadata || {}, this.methodInfoListFormElementValidations);
    };
    FormServiceClient.prototype.getFormElementValidation = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/FormService/GetFormElementValidation', request, metadata || {}, this.methodInfoGetFormElementValidation, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/FormService/GetFormElementValidation', request, metadata || {}, this.methodInfoGetFormElementValidation);
    };
    FormServiceClient.prototype.createFormElementValidation = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/FormService/CreateFormElementValidation', request, metadata || {}, this.methodInfoCreateFormElementValidation, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/FormService/CreateFormElementValidation', request, metadata || {}, this.methodInfoCreateFormElementValidation);
    };
    FormServiceClient.prototype.updateFormElementValidation = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/FormService/UpdateFormElementValidation', request, metadata || {}, this.methodInfoUpdateFormElementValidation, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/FormService/UpdateFormElementValidation', request, metadata || {}, this.methodInfoUpdateFormElementValidation);
    };
    FormServiceClient.prototype.deleteFormElementValidation = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/FormService/DeleteFormElementValidation', request, metadata || {}, this.methodInfoDeleteFormElementValidation, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/FormService/DeleteFormElementValidation', request, metadata || {}, this.methodInfoDeleteFormElementValidation);
    };
    FormServiceClient.prototype.listFormBuilds = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/FormService/ListFormBuilds', request, metadata || {}, this.methodInfoListFormBuilds, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/FormService/ListFormBuilds', request, metadata || {}, this.methodInfoListFormBuilds);
    };
    FormServiceClient.prototype.getFormBuild = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/FormService/GetFormBuild', request, metadata || {}, this.methodInfoGetFormBuild, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/FormService/GetFormBuild', request, metadata || {}, this.methodInfoGetFormBuild);
    };
    FormServiceClient.prototype.createFormBuild = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/FormService/CreateFormBuild', request, metadata || {}, this.methodInfoCreateFormBuild, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/FormService/CreateFormBuild', request, metadata || {}, this.methodInfoCreateFormBuild);
    };
    FormServiceClient.prototype.updateFormBuild = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/FormService/UpdateFormBuild', request, metadata || {}, this.methodInfoUpdateFormBuild, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/FormService/UpdateFormBuild', request, metadata || {}, this.methodInfoUpdateFormBuild);
    };
    FormServiceClient.prototype.updateFormBuildStatus = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/FormService/UpdateFormBuildStatus', request, metadata || {}, this.methodInfoUpdateFormBuildStatus, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/FormService/UpdateFormBuildStatus', request, metadata || {}, this.methodInfoUpdateFormBuildStatus);
    };
    FormServiceClient.prototype.deleteFormBuild = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/FormService/DeleteFormBuild', request, metadata || {}, this.methodInfoDeleteFormBuild, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/FormService/DeleteFormBuild', request, metadata || {}, this.methodInfoDeleteFormBuild);
    };
    FormServiceClient.prototype.getFormScenario = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/FormService/GetFormScenario', request, metadata || {}, this.methodInfoGetFormScenario, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/FormService/GetFormScenario', request, metadata || {}, this.methodInfoGetFormScenario);
    };
    FormServiceClient.prototype.createFormScenario = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/FormService/CreateFormScenario', request, metadata || {}, this.methodInfoCreateFormScenario, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/FormService/CreateFormScenario', request, metadata || {}, this.methodInfoCreateFormScenario);
    };
    FormServiceClient.prototype.updateFormScenario = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/FormService/UpdateFormScenario', request, metadata || {}, this.methodInfoUpdateFormScenario, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/FormService/UpdateFormScenario', request, metadata || {}, this.methodInfoUpdateFormScenario);
    };
    FormServiceClient.prototype.deleteFormScenario = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/FormService/DeleteFormScenario', request, metadata || {}, this.methodInfoDeleteFormScenario, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/FormService/DeleteFormScenario', request, metadata || {}, this.methodInfoDeleteFormScenario);
    };
    FormServiceClient.prototype.getFormQuestionLists = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/FormService/GetFormQuestionLists', request, metadata || {}, this.methodInfoGetFormQuestionLists, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/FormService/GetFormQuestionLists', request, metadata || {}, this.methodInfoGetFormQuestionLists);
    };
    FormServiceClient.prototype.upsertFormQuestions = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/FormService/UpsertFormQuestions', request, metadata || {}, this.methodInfoUpsertFormQuestions, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/FormService/UpsertFormQuestions', request, metadata || {}, this.methodInfoUpsertFormQuestions);
    };
    FormServiceClient.prototype.updateFormQuestion = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/FormService/UpdateFormQuestion', request, metadata || {}, this.methodInfoUpdateFormQuestion, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/FormService/UpdateFormQuestion', request, metadata || {}, this.methodInfoUpdateFormQuestion);
    };
    FormServiceClient.prototype.getFormQuestion = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/FormService/GetFormQuestion', request, metadata || {}, this.methodInfoGetFormQuestion, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/FormService/GetFormQuestion', request, metadata || {}, this.methodInfoGetFormQuestion);
    };
    FormServiceClient.prototype.receiveEndUserMessage = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/FormService/ReceiveEndUserMessage', request, metadata || {}, this.methodInfoReceiveEndUserMessage, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/FormService/ReceiveEndUserMessage', request, metadata || {}, this.methodInfoReceiveEndUserMessage);
    };
    FormServiceClient.prototype.listConversionTasks = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/FormService/ListConversionTasks', request, metadata || {}, this.methodInfoListConversionTasks, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/FormService/ListConversionTasks', request, metadata || {}, this.methodInfoListConversionTasks);
    };
    FormServiceClient.prototype.logConversionTask = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/FormService/LogConversionTask', request, metadata || {}, this.methodInfoLogConversionTask, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/FormService/LogConversionTask', request, metadata || {}, this.methodInfoLogConversionTask);
    };
    FormServiceClient.prototype.setFormAutomationTrigger = function (request, metadata, callback) {
        if (callback !== undefined) {
            return this.client_.rpcCall(this.hostname_ +
                '/FormService/SetFormAutomationTrigger', request, metadata || {}, this.methodInfoSetFormAutomationTrigger, callback);
        }
        return this.client_.unaryCall(this.hostname_ +
            '/FormService/SetFormAutomationTrigger', request, metadata || {}, this.methodInfoSetFormAutomationTrigger);
    };
    return FormServiceClient;
}());
export { FormServiceClient };
