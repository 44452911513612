var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { objToQuery } from '@src/lib/helpers';
import { mediaTypes, messageTypes } from '@src/lib/app-constants';
import transformResponse from './transformResponse';
import axiosInstance from './axiosInstance';
var messages = function (_a) {
    var chatbotId = _a.chatbotId, scenarioId = _a.scenarioId;
    return "/chatbots/" + chatbotId + "/scenarios/" + scenarioId + "/message_sections/messages";
};
var show = function (_a) {
    var chatbotId = _a.chatbotId, scenarioId = _a.scenarioId, messageId = _a.messageId;
    return messages({ chatbotId: chatbotId, scenarioId: scenarioId }) + "/" + messageId;
};
var member = function (_a) {
    var chatbotId = _a.chatbotId, scenarioId = _a.scenarioId;
    return "/chatbots/" + chatbotId + "/scenarios/" + scenarioId + "/message_sections/message_filters";
};
export var updateFilter = function (params) { return __awaiter(void 0, void 0, Promise, function () {
    var chatbotId, scenarioId, messageId, filterId, url, res;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                chatbotId = params.chatbotId, scenarioId = params.scenarioId, messageId = params.messageId, filterId = params.filterId;
                url = member({
                    chatbotId: chatbotId,
                    scenarioId: scenarioId,
                });
                return [4 /*yield*/, transformResponse(axiosInstance.put(url, {
                        chatbot_id: Number(chatbotId),
                        scenario_id: Number(scenarioId),
                        message_id: messageId,
                        filter_id: filterId,
                    }))];
            case 1:
                res = _a.sent();
                return [2 /*return*/, res];
        }
    });
}); };
export var showMessage = function (params) { return __awaiter(void 0, void 0, Promise, function () {
    var messageSectionId, chatbotId, scenarioId, messageId, query, url, res;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                messageSectionId = params.messageSectionId, chatbotId = params.chatbotId, scenarioId = params.scenarioId, messageId = params.messageId;
                query = { message_section_id: messageSectionId };
                url = show({ chatbotId: chatbotId, scenarioId: scenarioId, messageId: messageId });
                return [4 /*yield*/, transformResponse(axiosInstance.get(url + "?" + objToQuery(query)))];
            case 1:
                res = _a.sent();
                return [2 /*return*/, res];
        }
    });
}); };
export var createMessage = function (params) { return __awaiter(void 0, void 0, Promise, function () {
    var chatbotIdParam, adScenarioIdParam, messageSectionId, messageType, jsonData, templateId, body, url, res;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                chatbotIdParam = params.chatbotIdParam, adScenarioIdParam = params.adScenarioIdParam, messageSectionId = params.messageSectionId, messageType = params.messageType, jsonData = params.jsonData, templateId = params.templateId;
                body = { message_section_id: messageSectionId };
                switch (messageType) {
                    case mediaTypes.IMAGE:
                    case mediaTypes.AUDIO:
                    case mediaTypes.VIDEO:
                        body.message_type = messageType;
                        break;
                    case messageTypes.FLEX_MESSAGE:
                        body.message_type = 'line_flex_message';
                        if (jsonData)
                            body.json = jsonData;
                        if (templateId)
                            body.template_id = templateId;
                        break;
                    default:
                }
                url = messages({
                    chatbotId: chatbotIdParam,
                    scenarioId: adScenarioIdParam,
                });
                return [4 /*yield*/, transformResponse(axiosInstance.post(url, body))];
            case 1:
                res = _a.sent();
                return [2 /*return*/, res];
        }
    });
}); };
