var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import { colors } from '@src/colors';
import { hoverMenuBg } from '@src/lib/mixins';
// A styled-component which wraps list of Scenario or Blocks
// when choosing a block or scenario to connect to a postback action
var SelectorWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  position: absolute;\n  z-index: 10;\n  background: white;\n  padding: 8px;\n  font-size: 12px;\n  left: 0;\n\n  .new-block,\n  .select-block {\n    color: ", ";\n  }\n  .new-block {\n    padding: 6px;\n\n    ", ";\n    &.adicon_plus02 {\n      &::before {\n        font-size: 18px;\n      }\n    }\n    margin-bottom: 16px;\n  }\n  .select-block {\n    margin-top: 16px;\n  }\n  hr {\n    border: 0;\n    border-top: 1px solid ", ";\n    margin: 0;\n  }\n  .disable-click {\n    pointer-events: none;\n  }\n"], ["\n  width: 100%;\n  position: absolute;\n  z-index: 10;\n  background: white;\n  padding: 8px;\n  font-size: 12px;\n  left: 0;\n\n  .new-block,\n  .select-block {\n    color: ", ";\n  }\n  .new-block {\n    padding: 6px;\n\n    ", ";\n    &.adicon_plus02 {\n      &::before {\n        font-size: 18px;\n      }\n    }\n    margin-bottom: 16px;\n  }\n  .select-block {\n    margin-top: 16px;\n  }\n  hr {\n    border: 0;\n    border-top: 1px solid ", ";\n    margin: 0;\n  }\n  .disable-click {\n    pointer-events: none;\n  }\n"])), colors.text_pale, hoverMenuBg, colors.border.pale);
export default SelectorWrapper;
var templateObject_1;
