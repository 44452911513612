var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useCallback, useState, useMemo } from 'react';
import { PostbackConnectorGroupContainer } from '@src/components/atoms';
import Typography from '@mui/material/Typography';
import { Icon } from '@zeals-co-ltd/washi-components';
export var ConnectorType;
(function (ConnectorType) {
    ConnectorType["Block"] = "block";
    ConnectorType["Initial"] = "initial";
    ConnectorType["Form"] = "form";
    ConnectorType["Pattern"] = "pattern";
    ConnectorType["Step"] = "step";
    ConnectorType["Shot"] = "shot";
    ConnectorType["Plain"] = "plain";
})(ConnectorType || (ConnectorType = {}));
var PostbackConnectorGroup = function (_a) {
    var title = _a.title, postbackActionConnectors = _a.postbackActionConnectors, connectorType = _a.connectorType, connectorIconClassName = _a.connectorIconClassName, _b = _a.filterText, filterText = _b === void 0 ? '' : _b, handlePostbackItemClick = _a.handlePostbackItemClick;
    var _c = __read(useState(true), 2), isGroupShown = _c[0], setIsGroupShown = _c[1];
    var arrowName = useMemo(function () { return (isGroupShown ? 'adicon_up_chev' : 'adicon_down_chev'); }, [isGroupShown]);
    var onToggleArrow = useCallback(function () {
        setIsGroupShown(!isGroupShown);
    }, [isGroupShown]);
    return (React.createElement(PostbackConnectorGroupContainer, { "data-testid": "postback-connector-group-container" },
        React.createElement("div", { className: "section__text_header" },
            React.createElement("div", { className: "section__text_line section__header_" + connectorType + " " + connectorIconClassName, onClick: onToggleArrow, role: "button", tabIndex: -1 },
                React.createElement(Typography, { "data-testid": "postback-connector-title", className: "section-name", variant: "body2", component: "span" }, title),
                React.createElement("div", { className: "section__text_toggle-arrow", onClick: onToggleArrow, role: "button", tabIndex: -1 },
                    React.createElement(Icon, { name: arrowName, "data-testid": arrowName })))),
        postbackActionConnectors.length > 0 && isGroupShown && (React.createElement("div", { className: "section__text_contents" }, postbackActionConnectors.map(function (postbackActionConnector) { return (React.createElement("div", { "data-testid": "postbackAction-connector", key: "connector-item-selector-chat-" + postbackActionConnector.id, className: "section__text_line " + connectorType, onClick: function (e) {
                return handlePostbackItemClick(postbackActionConnector.id, e);
            }, role: "button", tabIndex: -1 }, postbackActionConnector.name)); }))),
        filterText && isGroupShown && (React.createElement("div", { className: "section__no_more" },
            React.createElement("hr", null),
            React.createElement("p", { className: "section__no_more_text" }, "No more results.")))));
};
export default PostbackConnectorGroup;
