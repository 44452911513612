var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import { Checkbox } from '@zeals-co-ltd/washi-components';
import MUIBox from '@mui/material/Box';
import styled from 'styled-components';
import { grey, navy, blue } from '@zeals-co-ltd/washi-styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import { alpha } from '@mui/material/styles';
import { truncateText } from '@src/lib/mixins';
var StyledFormControlLabel = styled(FormControlLabel)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin: 0px;\n  border-radius: 8px;\n  padding: 16px;\n  background: ", ";\n  &:hover {\n    background: ", ";\n    span:first-of-type {\n    }\n  }\n"], ["\n  margin: 0px;\n  border-radius: 8px;\n  padding: 16px;\n  background: ", ";\n  &:hover {\n    background: ", ";\n    span:first-of-type {\n    }\n  }\n"])), function (props) { return (props.checked ? alpha(blue[50], 0.7) : grey.A100); }, alpha(grey[100], 0.7));
var StyledTypography = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  max-width: 240px;\n  color: ", ";\n  div:first-child {\n    font-size: 14px;\n    font-weight: 400;\n    line-height: 20px;\n    letter-spacing: 0.25px;\n    ", "\n  }\n  div:last-child {\n    font-size: 12px;\n    font-weight: 400;\n    line-height: 17.52px;\n    letter-spacing: 0.4px;\n  }\n"], ["\n  max-width: 240px;\n  color: ", ";\n  div:first-child {\n    font-size: 14px;\n    font-weight: 400;\n    line-height: 20px;\n    letter-spacing: 0.25px;\n    ", "\n  }\n  div:last-child {\n    font-size: 12px;\n    font-weight: 400;\n    line-height: 17.52px;\n    letter-spacing: 0.4px;\n  }\n"])), function (props) { return (props.checked ? navy[600] : navy[300]); }, truncateText);
var StyledCheckbox = styled(Checkbox)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  &:hover {\n    background: transparent;\n  }\n  &.Mui-checked {\n    color: ", ";\n    & + .MuiTypography-root {\n      color: ", ";\n    }\n  }\n"], ["\n  &:hover {\n    background: transparent;\n  }\n  &.Mui-checked {\n    color: ", ";\n    & + .MuiTypography-root {\n      color: ", ";\n    }\n  }\n"])), blue[400], navy[600]);
var UserSegmentCard = function (_a) {
    var _b = _a.checked, checked = _b === void 0 ? false : _b, onChangeUserSegmentIds = _a.onChangeUserSegmentIds, userSegment = _a.userSegment;
    var userSegmentId = userSegment.id, userSegmentName = userSegment.name;
    var handleCheckbox = function (event, isChecked) {
        onChangeUserSegmentIds(event, isChecked, userSegmentId);
    };
    return (React.createElement(StyledFormControlLabel, { checked: checked, label: React.createElement(StyledTypography, { checked: checked },
            React.createElement(MUIBox, null, userSegmentName),
            React.createElement(MUIBox, null, "300 End Users in this segment")), control: React.createElement(StyledCheckbox, { checked: checked, value: userSegmentId, color: "primary", onChange: handleCheckbox }), "data-testid": "user-segment-item" }));
};
export default UserSegmentCard;
var templateObject_1, templateObject_2, templateObject_3;
