var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import styled from 'styled-components';
import { colors, shadows } from '@src/colors';
import { hoverDelete } from '@src/lib/mixins';
// Delete icon always display: none
var Icon = styled.span(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: absolute;\n  width: 32px;\n  height: 32px;\n  top: -10px;\n  right: -10px;\n  border-radius: 50%;\n  z-index: 15;\n  background: ", ";\n  border: 1px solid ", ";\n  color: ", ";\n  font-size: 20px;\n  padding-top: 2px;\n  text-align: center;\n  display: none;\n  ", ";\n  &:hover {\n    border: 1px solid ", ";\n    box-shadow: ", ";\n  }\n"], ["\n  position: absolute;\n  width: 32px;\n  height: 32px;\n  top: -10px;\n  right: -10px;\n  border-radius: 50%;\n  z-index: 15;\n  background: ", ";\n  border: 1px solid ", ";\n  color: ", ";\n  font-size: 20px;\n  padding-top: 2px;\n  text-align: center;\n  display: none;\n  ", ";\n  &:hover {\n    border: 1px solid ", ";\n    box-shadow: ", ";\n  }\n"])), colors.background.main_menu, colors.border.default, colors.icon, hoverDelete, colors.white, shadows.small.light);
var DeleteIcon = function (_a) {
    var onClick = _a.onClick, props = __rest(_a, ["onClick"]);
    return (React.createElement(Icon, __assign({ className: "adicon_delete", onClick: onClick, "data-testid": "DeleteIcon" }, props)));
};
export default DeleteIcon;
var templateObject_1;
