var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { handleAPIErrors } from '@src/lib/helpers';
import * as imageMapApi from '@src/services/api/imageMap';
import { pushMessage, setMessage, deleteMessage, updateMessage, } from '@src/redux/fanp/actions/talkCustomizeMessageImplementation';
import { fetchAdScenario } from '@src/redux/fanp/actions/ad-scenario/getActions';
import { uploadStatuses } from '@src/lib/app-constants';
import { setAdScenario } from './ad-scenario/setActions';
export var createImageMap = function (_a) {
    var blockId = _a.blockId, chatbotId = _a.chatbotId, scenarioId = _a.scenarioId, template = _a.template;
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        var body, message, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    body = {
                        template: template,
                        block_id: blockId,
                    };
                    return [4 /*yield*/, imageMapApi.createImageMap(chatbotId, scenarioId, body)];
                case 1:
                    message = _a.sent();
                    dispatch(pushMessage({ message: message }));
                    return [3 /*break*/, 3];
                case 2:
                    e_1 = _a.sent();
                    console.error('Can not create image map: ', e_1.message);
                    handleAPIErrors(e_1.message);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
};
export var updateImageMap = function (_a) {
    var chatbotId = _a.chatbotId, scenarioId = _a.scenarioId, messageId = _a.messageId, imageMapId = _a.imageMapId, alt_text = _a.alt_text;
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        var body, message, e_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    body = {
                        message_id: messageId,
                        alt_text: alt_text,
                    };
                    return [4 /*yield*/, imageMapApi.updateImageMapAltText(chatbotId, scenarioId, imageMapId, body)];
                case 1:
                    message = _a.sent();
                    dispatch(setMessage({ message: message }));
                    return [3 /*break*/, 3];
                case 2:
                    e_2 = _a.sent();
                    console.error('Can not update image map: ', e_2.message);
                    handleAPIErrors(e_2.message);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
};
export var uploadImageToImageMap = function (_a) {
    var chatbotId = _a.chatbotId, scenarioId = _a.scenarioId, messageId = _a.messageId, imageMapId = _a.imageMapId, blockId = _a.blockId, file = _a.file;
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        var body, res, e_3;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    body = file;
                    return [4 /*yield*/, imageMapApi.updateImageMapImage(chatbotId, scenarioId, imageMapId, body)];
                case 1:
                    res = _a.sent();
                    if ((res === null || res === void 0 ? void 0 : res.status) && (res.status === 413 || res.status === 422)) {
                        throw Error('画像が大きすぎます1MB以下にしてください');
                    }
                    dispatch(setMessage({
                        messageId: messageId,
                        message: res.message,
                    }));
                    return [3 /*break*/, 3];
                case 2:
                    e_3 = _a.sent();
                    console.error('Can not update image map: ', e_3.message);
                    handleAPIErrors(e_3.message);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
};
export var deleteImageMap = function (_a) {
    var chatbotId = _a.chatbotId, scenarioId = _a.scenarioId, messageId = _a.messageId, imageMapId = _a.imageMapId, blockId = _a.blockId, layer_rank = _a.layer_rank;
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        var body, res, e_4;
        var _a, _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    _c.trys.push([0, 2, , 3]);
                    body = {
                        message_id: messageId,
                        layer_rank: layer_rank,
                        block_id: blockId,
                    };
                    return [4 /*yield*/, imageMapApi.deleteImageMapImage(chatbotId, scenarioId, imageMapId, body)];
                case 1:
                    res = _c.sent();
                    if ((res === null || res === void 0 ? void 0 : res.status) && (res === null || res === void 0 ? void 0 : res.status) === 200) {
                        dispatch(deleteMessage({
                            blockId: (_a = res === null || res === void 0 ? void 0 : res.message) === null || _a === void 0 ? void 0 : _a.block_id,
                            messageSectionId: (_b = res === null || res === void 0 ? void 0 : res.message) === null || _b === void 0 ? void 0 : _b.message_section_id,
                            messageId: messageId,
                        }));
                        dispatch(fetchAdScenario({
                            chatbotId: chatbotId,
                            adScenarioId: scenarioId,
                        }));
                    }
                    else {
                        handleAPIErrors(res === null || res === void 0 ? void 0 : res.message);
                    }
                    return [3 /*break*/, 3];
                case 2:
                    e_4 = _c.sent();
                    console.error('Can not delete image map: ', e_4.message);
                    handleAPIErrors(e_4.message);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
};
export var updateImageMapAreas = function (_a) {
    var chatbotId = _a.chatbotId, scenarioId = _a.scenarioId, imageMapId = _a.imageMapId, imageMapAreaId = _a.imageMapAreaId, messageId = _a.messageId, label = _a.label, url = _a.url, nextScenarioId = _a.nextScenarioId, blockId = _a.blockId, text = _a.text, action_type = _a.action_type, layer_rank = _a.layer_rank, postback_action_id = _a.postback_action_id, attribute_answer_id = _a.attribute_answer_id, attribute_question_id = _a.attribute_question_id, disconnect_block = _a.disconnect_block;
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        var body, res, e_5;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    body = {
                        message_id: messageId,
                        label: label,
                        url: url,
                        next_scenario_id: nextScenarioId,
                        next_block_id: blockId,
                        layer_rank: layer_rank,
                        postback_action_id: postback_action_id,
                        text: text,
                        action_type: action_type,
                        attribute_question_id: attribute_question_id,
                        attribute_answer_id: attribute_answer_id,
                        disconnect_block: disconnect_block,
                    };
                    return [4 /*yield*/, imageMapApi.updateImageMapArea(chatbotId, scenarioId, imageMapId, imageMapAreaId, body)];
                case 1:
                    res = _a.sent();
                    if (res === null || res === void 0 ? void 0 : res.message) {
                        dispatch(setMessage({
                            messageId: messageId,
                            message: res.message,
                        }));
                    }
                    if (res === null || res === void 0 ? void 0 : res.scenario) {
                        dispatch(setAdScenario(res.scenario));
                    }
                    return [3 /*break*/, 3];
                case 2:
                    e_5 = _a.sent();
                    console.error('Can not update image map areas: ', e_5.message);
                    handleAPIErrors(e_5.message);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
};
export var uploadImageMapVideo = function (_a) {
    var chatbotId = _a.chatbotId, scenarioId = _a.scenarioId, imageMapId = _a.imageMapId, imageMapVideoId = _a.imageMapVideoId, messageId = _a.messageId, formData = _a.formData;
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        var message, message, e_6;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 5, , 6]);
                    if (formData === null || formData === void 0 ? void 0 : formData.get('video_file')) {
                        dispatch(updateMessage({
                            messageId: messageId,
                            property: 'uploadVideoFile',
                            value: uploadStatuses.UPLOADING,
                        }));
                    }
                    if (!imageMapVideoId) return [3 /*break*/, 2];
                    return [4 /*yield*/, imageMapApi.updateImageMapVideo(chatbotId, scenarioId, imageMapId, imageMapVideoId, formData)];
                case 1:
                    message = _a.sent();
                    if (!message) {
                        if (formData === null || formData === void 0 ? void 0 : formData.get('video_file')) {
                            dispatch(updateMessage({
                                messageId: messageId,
                                property: 'uploadVideoFile',
                                value: uploadStatuses.FAILED,
                            }));
                        }
                        throw Error('Can not upload video');
                    }
                    else {
                        dispatch(setMessage({
                            messageId: messageId,
                            message: __assign(__assign({}, message), { uploadVideoFile: (formData === null || formData === void 0 ? void 0 : formData.get('video_file'))
                                    ? uploadStatuses.SUCCESS
                                    : null }),
                        }));
                    }
                    return [3 /*break*/, 4];
                case 2: return [4 /*yield*/, imageMapApi.createImageMapVideo(chatbotId, scenarioId, imageMapId, formData)];
                case 3:
                    message = _a.sent();
                    if (!message) {
                        if (formData === null || formData === void 0 ? void 0 : formData.get('video_file')) {
                            dispatch(updateMessage({
                                messageId: messageId,
                                property: 'uploadVideoFile',
                                value: uploadStatuses.FAILED,
                            }));
                        }
                        throw Error('Can not upload video');
                    }
                    else {
                        dispatch(setMessage({
                            messageId: messageId,
                            message: __assign(__assign({}, message), { uploadVideoFile: (formData === null || formData === void 0 ? void 0 : formData.get('video_file'))
                                    ? uploadStatuses.SUCCESS
                                    : null }),
                        }));
                    }
                    _a.label = 4;
                case 4: return [3 /*break*/, 6];
                case 5:
                    e_6 = _a.sent();
                    console.error('Can not upload video', e_6.message);
                    handleAPIErrors(e_6.message);
                    return [3 /*break*/, 6];
                case 6: return [2 /*return*/];
            }
        });
    }); };
};
