import events from './events';
import paths from './paths';
import actions from './actions';
import modals from './modals';
import banners from './banners';
export default {
  paths: paths,
  modals: modals,
  banners: banners,
  events: events,
  actions: actions
};