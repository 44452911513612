function copyToClipboard(text, cb) {
    if (typeof text !== 'string')
        throw new Error('Cannot copy something that is not text');
    var textField = document.createElement('textarea');
    textField.innerText = text;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    textField.remove();
    if (cb)
        cb(text);
}
export default copyToClipboard;
