var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import { colors } from '@src/colors';
import { hoverActionBasic } from '@src/lib/mixins';
import whiteCheck from '@src/assets/images/check_white.svg';
var AgreementContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  text-align: left;\n  padding-left: 24px;\n  margin-bottom: 47px;\n  position: relative;\n  ", "\n\n  label {\n    color: ", ";\n    font-size: 16px;\n    line-height: 24px;\n    margin-bottom: 0;\n  }\n\n  input[type='checkbox'] {\n    height: 16px;\n    width: 16px;\n    display: block;\n    position: absolute;\n    left: 0;\n    top: 50%;\n    margin-top: -5px;\n    border: solid 1px ", ";\n    border-radius: 4px;\n    outline: none;\n    box-shadow: none;\n\n    &:checked {\n      border-color: ", ";\n      background: ", ";\n      background-repeat: no-repeat;\n      background-position: 2px;\n      background-size: 10px;\n      background-image: url(", ");\n    }\n  }\n"], ["\n  text-align: left;\n  padding-left: 24px;\n  margin-bottom: 47px;\n  position: relative;\n  ", "\n\n  label {\n    color: ", ";\n    font-size: 16px;\n    line-height: 24px;\n    margin-bottom: 0;\n  }\n\n  input[type='checkbox'] {\n    height: 16px;\n    width: 16px;\n    display: block;\n    position: absolute;\n    left: 0;\n    top: 50%;\n    margin-top: -5px;\n    border: solid 1px ", ";\n    border-radius: 4px;\n    outline: none;\n    box-shadow: none;\n\n    &:checked {\n      border-color: ", ";\n      background: ", ";\n      background-repeat: no-repeat;\n      background-position: 2px;\n      background-size: 10px;\n      background-image: url(", ");\n    }\n  }\n"])), hoverActionBasic, colors.default_text, colors.default_text, colors.accent_color, colors.accent_color, whiteCheck);
var Agreement = function (_a) {
    var id = _a.id, children = _a.children;
    return (React.createElement(AgreementContainer, null,
        React.createElement("input", { className: "", type: "checkbox", value: "", id: id }),
        React.createElement("label", { htmlFor: id }, children)));
};
export default Agreement;
var templateObject_1;
