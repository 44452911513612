var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import { injectIntl } from 'react-intl';
import * as washiColors from '@zeals-co-ltd/washi-styles';
var RequiredTextWrapper = styled.span(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  font-size: 10px;\n  font-weight: 500;\n  padding: 3px 4px;\n  color: ", ";\n  margin-left: 8px;\n  background-color: ", ";\n  text-transform: uppercase;\n  letter-spacing: 1.5px;\n  border-radius: 4px;\n"], ["\n  font-size: 10px;\n  font-weight: 500;\n  padding: 3px 4px;\n  color: ", ";\n  margin-left: 8px;\n  background-color: ", ";\n  text-transform: uppercase;\n  letter-spacing: 1.5px;\n  border-radius: 4px;\n"])), washiColors.red[900], washiColors.red[100]);
var RequiredText = function (_a) {
    var intl = _a.intl;
    return (React.createElement(RequiredTextWrapper, null, intl.formatMessage({ id: "Seanpx", defaultMessage: "Required" })));
};
export default injectIntl(RequiredText);
var templateObject_1;
