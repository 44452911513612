var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { validateEmailAddress, handleAPIErrors } from '@src/lib/helpers';
import { ActionType } from '@src/redux/fanp/types/account';
import * as accountApi from '@src/services/api/account';
import moment from 'moment';
export var setAccount = function (data) {
    return {
        type: ActionType.SET_ACCOUNT,
        data: data,
    };
};
export var setCurrentUser = function (currentUser) {
    return {
        type: ActionType.SET_CURRENT_USER,
        currentUser: currentUser,
    };
};
export var setUpdatedAccount = function (data) {
    return {
        type: ActionType.SET_UPDATED_ACCOUNT,
        data: data,
    };
};
export var toggleFetchingAccount = function (isFetching) { return ({
    type: ActionType.SET_FETCHING_ACCOUNT,
    isFetching: isFetching,
}); };
export var updateAccountProperty = function (_a) {
    var key = _a.key, value = _a.value;
    var validation = true;
    if (key === 'name') {
        validation = value !== '';
    }
    return {
        type: ActionType.UPDATE_ACCOUNT_PROPERTY,
        key: key,
        value: value,
        validation: validation,
    };
};
export var updateClientProperty = function (_a) {
    var key = _a.key, value = _a.value;
    var validation = true;
    if (key === 'name') {
        validation = value !== '';
    }
    if (key === 'email') {
        validation = validateEmailAddress(value);
    }
    return {
        type: ActionType.UPDATE_CLIENT_PROPERTY,
        key: key,
        value: value,
        validation: validation,
    };
};
export var updateLanguage = function (language) {
    moment.locale(language);
    return {
        type: ActionType.SET_LANGUAGE,
        language: language,
    };
};
/*
  ACCOUNT THUNKS
*/
export var fetchChangeAccount = function (id) { return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
    var body, account, e_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                dispatch(toggleFetchingAccount(true));
                body = { id: id };
                return [4 /*yield*/, accountApi.updateAccount(body)];
            case 1:
                account = _a.sent();
                dispatch(setAccount(account));
                dispatch(toggleFetchingAccount(false));
                return [3 /*break*/, 3];
            case 2:
                e_1 = _a.sent();
                handleAPIErrors(e_1);
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); }; };
export var fetchAccount = function () { return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
    var account;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                dispatch(toggleFetchingAccount(true));
                return [4 /*yield*/, accountApi.getAccount()];
            case 1:
                account = _a.sent();
                dispatch(setAccount(account));
                dispatch(toggleFetchingAccount(false));
                return [2 /*return*/, account];
        }
    });
}); }; };
export var submitAccountSave = function (_a) {
    var accountId = _a.accountId, saveObject = _a.saveObject, successMsg = _a.successMsg, errorMsg = _a.errorMsg;
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        var account, url_1, e_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, accountApi.saveAccount(accountId, saveObject)];
                case 1:
                    account = _a.sent();
                    successMsg();
                    dispatch(setUpdatedAccount(account));
                    url_1 = "/dashboard";
                    setTimeout(function () {
                        if (window.globalRouterHistory) {
                            window.globalRouterHistory.push(url_1);
                        }
                    }, 2000);
                    return [3 /*break*/, 3];
                case 2:
                    e_2 = _a.sent();
                    errorMsg();
                    handleAPIErrors(e_2);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
};
export var fetchAccountCreate = function (name, corporateUser) { return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
    var body, account, e_3;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                body = {
                    account: {
                        name: name,
                        corporate_user: corporateUser,
                    },
                };
                return [4 /*yield*/, accountApi.createAccount(body)];
            case 1:
                account = _a.sent();
                dispatch(setAccount(account));
                if (window.globalRouterHistory) {
                    window.globalRouterHistory.push('/dashboard');
                }
                return [3 /*break*/, 3];
            case 2:
                e_3 = _a.sent();
                handleAPIErrors('Could not create account', e_3);
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); }; };
