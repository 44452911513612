import React from 'react';
import { ModalPortal, Input, Button } from '@src/components/atoms';
import { Modal } from '@src/components/molecules';
import modalTypes from '@src/lib/modalTypes';
import featureFlags from '@src/lib/feature_flags/featureFlags';
import buttonTypes from '@src/lib/buttonTypes';
import { injectIntl } from 'react-intl';
var defaultProps = {
    value: 'ScenarioName',
    onCancel: function () { },
    onConfirm: function () { },
    onClickFromTemplate: function () { },
    onChange: function (e) { return console.log(e.target.value); },
};
var CreateScenarioModal = function (_a) {
    var onCancel = _a.onCancel, onConfirm = _a.onConfirm, onChange = _a.onChange, onClickFromTemplate = _a.onClickFromTemplate, value = _a.value, intl = _a.intl;
    return (React.createElement(ModalPortal, null,
        React.createElement(Modal, { "data-testid": "create-scenario-modal", text: intl.formatMessage({ id: "Zgjt8P", defaultMessage: "Scenario Settings" }), type: modalTypes.SELECT, onConfirm: onConfirm, onCancel: onCancel },
            featureFlags.NEW_SCENARIO_FROM_TEMPLATE && (React.createElement(Button, { text: intl.formatMessage({ id: "P3NrGJ", defaultMessage: "Create from Template" }), buttonType: buttonTypes.MAIN, margin: "16px 0px 0px 0px", onClick: onClickFromTemplate })),
            React.createElement("div", { className: "message-wrapper primary-label" }, intl.formatMessage({ id: "R+U9l6", defaultMessage: "Please enter a Scenario Name" })),
            React.createElement(Input, { dataTest: "scenario-name-input", type: "text", placeholder: intl.formatMessage({ id: "Seanpx", defaultMessage: "Required" }), onChange: onChange, value: value }))));
};
export default injectIntl(CreateScenarioModal);
