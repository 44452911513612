var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import { injectIntl } from 'react-intl';
import { colors } from '@src/colors';
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: 0.5rem;\n  color: ", ";\n  > .is-expanded {\n    font-size: 12px;\n    width: 120px;\n    font-weight: 500;\n    transition: 0.3s;\n    padding: 3px 0;\n    &:hover {\n      color: ", ";\n      border-radius: 8px;\n      background: ", ";\n      cursor: pointer;\n    }\n    > .icon-container {\n      position: relative;\n      display: inline-block;\n      width: 1.5rem;\n      height: 1.5rem;\n      > .icon {\n        position: absolute;\n        display: inline-block;\n        font-size: 1.5rem;\n        bottom: 0px;\n      }\n    }\n  }\n"], ["\n  padding: 0.5rem;\n  color: ", ";\n  > .is-expanded {\n    font-size: 12px;\n    width: 120px;\n    font-weight: 500;\n    transition: 0.3s;\n    padding: 3px 0;\n    &:hover {\n      color: ", ";\n      border-radius: 8px;\n      background: ", ";\n      cursor: pointer;\n    }\n    > .icon-container {\n      position: relative;\n      display: inline-block;\n      width: 1.5rem;\n      height: 1.5rem;\n      > .icon {\n        position: absolute;\n        display: inline-block;\n        font-size: 1.5rem;\n        bottom: 0px;\n      }\n    }\n  }\n"])), colors.border.edit, colors.old_navy, colors.background.off_white_background);
var ExpandButton = function (_a) {
    var allLayerVisible = _a.allLayerVisible, intl = _a.intl, onClick = _a.onClick;
    return (React.createElement(Container, { onClick: function () { return onClick(); }, "data-testid": "ExpandButton" },
        React.createElement("div", { className: "is-expanded" },
            React.createElement("div", { className: "icon-container" },
                React.createElement("span", { className: "icon " + (allLayerVisible ? 'adicon_arrow_up' : 'adicon_arrow_down') })),
            allLayerVisible
                ? intl.formatMessage({ id: "bz6UVJ", defaultMessage: "Collapse All" })
                : intl.formatMessage({ id: "EgZZHp", defaultMessage: "Expand All" }))));
};
export default injectIntl(ExpandButton);
var templateObject_1;
