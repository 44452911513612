var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
export function getFlex(flex, parentLayout) {
    if (flex !== undefined && flex !== null)
        return flex + " " + flex + " auto";
    return parentLayout === 'vertical' ? '0 0 auto' : '1 1 auto';
}
export function aspectRatioToPercent(aspectRatio) {
    if (!aspectRatio.includes(':'))
        throw new Error('Invalid aspectRatio, should contain ":" character');
    var _a = __read(aspectRatio.split(':'), 2), denominator = _a[0], numerator = _a[1];
    return Number((Number(numerator) / Number(denominator)).toFixed(4)) * 100 + "%";
}
