import "core-js/modules/es.function.name";
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
var DeliveryConfirmContainer = styled.div.withConfig({
  displayName: "DeliveryConfirmContainer",
  componentId: "sc-mlfbd8-0"
})(["text-align:center;justify-content:center;justify-items:center;"]);

var DeliveryConfirm = function DeliveryConfirm(_ref) {
  var filteredUsers = _ref.filteredUsers,
      text = _ref.text,
      isStep = _ref.isStep,
      scenario = _ref.scenario;
  var testingText = scenario.testing ? 'ON' : 'OFF';
  return React.createElement(DeliveryConfirmContainer, null, React.createElement("h3", null, scenario.name), !isStep && React.createElement("p", null, "\u3092", filteredUsers, "\u4EBA\u306B\u914D\u4FE1\u306B\u914D\u4FE1\u4E88\u5B9A\u3067\u3059"), React.createElement("p", null, text), React.createElement("p", null, "\u3088\u308D\u3057\u3044\u3067\u3059\u304B\uFF1F"), React.createElement("p", null, "\u30C6\u30B9\u30C8\u30E2\u30FC\u30C9\u306F", React.createElement("strong", null, testingText), "\u3067\u3059"));
};

DeliveryConfirm.propTypes = {
  filteredUsers: PropTypes.number.isRequired,
  isStep: PropTypes.bool.isRequired,
  scenario: PropTypes.object.isRequired,
  text: PropTypes.string
};
export default DeliveryConfirm;