var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Component, createRef } from 'react';
import styled from 'styled-components';
// @ts-ignore
import { FlexMessages } from '@src/components/molecules';
var ConversationContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin: 0px 40px 0px 40px;\n  position: relative;\n  bottom: 0;\n\n  .flex_line_container {\n    box-sizing: border-box;\n    margin: 0;\n    padding: 0;\n    border: 0;\n    font-size: 90%;\n    font-weight: normal;\n    font-style: normal;\n    font-family: -apple-system, 'BlinkMacSystemFont', Helvetica, Roboto,\n      Sans-Serif;\n    list-style: none;\n    line-height: 1.4;\n  }\n"], ["\n  margin: 0px 40px 0px 40px;\n  position: relative;\n  bottom: 0;\n\n  .flex_line_container {\n    box-sizing: border-box;\n    margin: 0;\n    padding: 0;\n    border: 0;\n    font-size: 90%;\n    font-weight: normal;\n    font-style: normal;\n    font-family: -apple-system, 'BlinkMacSystemFont', Helvetica, Roboto,\n      Sans-Serif;\n    list-style: none;\n    line-height: 1.4;\n  }\n"])));
var BottomSpacer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  height: 110px;\n  width: 100%;\n"], ["\n  height: 110px;\n  width: 100%;\n"])));
var WebchatConversation = /** @class */ (function (_super) {
    __extends(WebchatConversation, _super);
    function WebchatConversation() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.myRef = createRef();
        _this.onScrollToBottom = function () {
            return _this.myRef.current.scrollIntoView({ behavior: 'smooth' });
        };
        _this.onHandleAnswer = function (answerInfo) {
            var onHandleAnswer = _this.props.onHandleAnswer;
            onHandleAnswer(answerInfo);
        };
        return _this;
    }
    WebchatConversation.prototype.componentDidUpdate = function (prevProps) {
        var _a = this.props, displayedMessages = _a.displayedMessages, status = _a.status;
        if (prevProps.displayedMessages !== displayedMessages ||
            prevProps.status !== status) {
            this.onScrollToBottom();
        }
    };
    WebchatConversation.prototype.render = function () {
        var _a = this.props, displayedMessages = _a.displayedMessages, status = _a.status, webchatOpened = _a.webchatOpened, webchatApp = _a.webchatApp;
        return displayedMessages ? (React.createElement(ConversationContainer, null,
            React.createElement("div", { className: "flex_line_container" },
                React.createElement(FlexMessages, { messages: displayedMessages, status: status, onHandleAnswer: this.onHandleAnswer, webchatOpened: webchatOpened, webchatApp: webchatApp }),
                React.createElement(BottomSpacer, null)),
            React.createElement("div", { className: "myRef", ref: this.myRef }))) : null;
    };
    return WebchatConversation;
}(Component));
export default WebchatConversation;
var templateObject_1, templateObject_2;
