import "core-js/modules/es.array.concat";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";

var collection = function collection(_ref) {
  var chatbotId = _ref.chatbotId,
      scenarioId = _ref.scenarioId,
      messageId = _ref.messageId,
      itemId = _ref.itemId;
  return "/chatbots/".concat(chatbotId, "/scenarios/").concat(scenarioId, "/messages/").concat(messageId, "/generic_items/").concat(itemId, "/articles");
};

var member = function member(_ref2) {
  var id = _ref2.id,
      rest = _objectWithoutProperties(_ref2, ["id"]);

  return "".concat(collection(rest), "/").concat(id);
};

export default {
  collection: collection,
  member: member
};