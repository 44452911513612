import React from 'react';
import { PluginImageActionPreview, PluginModalPreview } from '@src/components/atoms';
import { pluginActionTypes } from '@src/lib/app-constants';
var ActionTypes = {
  MODAL: 'modal',
  BANNER: 'banner',
  CV: 'conversion',
  GET_ACTION_HISTORY: 'get_action_history',
  METATAG: 'meta_tag'
};

var ActionPreviewItem = function ActionPreviewItem(_ref) {
  var action = _ref.action,
      width = _ref.width;

  switch (action.action_type) {
    case ActionTypes.MODAL:
      if (action.modal && action.modal.modal_type === 'normal') {
        return React.createElement(PluginModalPreview, {
          modal: action.modal,
          width: width
        });
      } else {
        return React.createElement(PluginImageActionPreview, {
          modal: action.modal,
          width: width
        });
      }

    case ActionTypes.BANNER:
      return React.createElement(PluginImageActionPreview, {
        banner: action.banner,
        width: width
      });

    case ActionTypes.CV:
      return React.createElement("div", null, pluginActionTypes.conversion);

    case ActionTypes.GET_ACTION_HISTORY:
      return React.createElement("div", null, pluginActionTypes.get_action_history);

    case ActionTypes.METATAG:
      return React.createElement("div", null, pluginActionTypes.metatag);

    default:
      return null;
  }
};

export default ActionPreviewItem;