var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';
import { object, string, number } from 'yup';
import styled from 'styled-components';
import { colors } from '@src/colors';
import { ModalPortal } from '@src/components/atoms';
import { getDifferenceOfObjects } from '@src/lib/helpers';
import { transitions } from '@src/lib/animations';
import { createRichMenu, deleteRichMenu, updateRichMenu, } from '@src/redux/custom_form_admin/actions/workPanel';
import Modal from '@src/components/molecules/Modal';
import debounce from 'lodash/debounce';
import QuestionDetailForm from './QuestionDetailForm';
import QuickChoiceSection from './QuickChoiceSection';
import TalkTriggerSection from './TalkTriggerSection';
import RichMenuSection from './RichMenuSection';
var DEBOUNCE_TIME = 500;
var questionSchema = object().shape({
    label: string().required('必須'),
    description: string().required('必須'),
    data_type: string().required('必須'),
    validation_type: string(),
    max_answer_num: number()
        .moreThan(0, '0より大きくなければなりません')
        .required('必須'),
});
var FullModalOverlay = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: block;\n  position: fixed;\n  top: 0;\n  left: 0;\n  z-index: 99;\n  width: 100%;\n  height: 100%;\n  background-color: ", ";\n  opacity: 1;\n  transition: opacity ", " ease-in, z-index 0.6s ease-out;\n"], ["\n  display: block;\n  position: fixed;\n  top: 0;\n  left: 0;\n  z-index: 99;\n  width: 100%;\n  height: 100%;\n  background-color: ", ";\n  opacity: 1;\n  transition: opacity ", " ease-in, z-index 0.6s ease-out;\n"])), colors.area_mask, transitions.fast);
var QuestionModal = function (_a) {
    var id = _a.id, label = _a.label, description = _a.description, data_type = _a.data_type, validation_type = _a.validation_type, max_answer_num = _a.max_answer_num, placeholder = _a.placeholder, nullable = _a.nullable, talk_triggers = _a.talk_triggers, selectedForm = _a.selectedForm, validation_id = _a.validation_id, selectedScenario = _a.selectedScenario, questionGroup = _a.questionGroup, updateQuestion = _a.updateQuestion, deleteChoice = _a.deleteChoice, createChoice = _a.createChoice, updateChoice = _a.updateChoice, onCancel = _a.onCancel, props = __rest(_a, ["id", "label", "description", "data_type", "validation_type", "max_answer_num", "placeholder", "nullable", "talk_triggers", "selectedForm", "validation_id", "selectedScenario", "questionGroup", "updateQuestion", "deleteChoice", "createChoice", "updateChoice", "onCancel"]);
    var dispatch = useDispatch();
    var _b = __read(useState(null), 2), currentQuestion = _b[0], setCurrentQuestion = _b[1];
    var _c = __read(useState(null), 2), quickReplySearchTerm = _c[0], setQuickReplySearchTerm = _c[1];
    var _d = __read(useState(null), 2), quickReplies = _d[0], setQuickReplies = _d[1];
    var _e = __read(useState(null), 2), currentChoice = _e[0], setCurrentChoice = _e[1];
    var _f = __read(useState(null), 2), quickReplyValue = _f[0], setQuickReplyValue = _f[1];
    var _g = __read(useState(null), 2), quickReplySearchTermTouched = _g[0], setQuickReplySearchTermTouched = _g[1];
    var _h = __read(useState(null), 2), richMenuSearchTerm = _h[0], setRichMenuSearchTerm = _h[1];
    var _j = __read(useState(null), 2), richMenuItems = _j[0], setRichMenuItems = _j[1];
    var _k = __read(useState(null), 2), currentRichMenu = _k[0], setCurrentRichMenu = _k[1];
    var _l = __read(useState(null), 2), richMenuValue = _l[0], setRichMenuValue = _l[1];
    var _m = __read(useState(null), 2), richMenuSearchTermTouched = _m[0], setRichMenuSearchTermTouched = _m[1];
    var _o = __read(useState(null), 2), richMenuSwitch = _o[0], setRichMenuSwitch = _o[1];
    var _p = __read(useState(null), 2), chosenQuickReplyId = _p[0], setChosenQuickReplyId = _p[1];
    var _q = __read(useState(null), 2), chosenRichMenuId = _q[0], setChosenRichMenuId = _q[1];
    var handleSetCurrentQuestion = useCallback(function () {
        setCurrentQuestion(questionGroup.custom_form_questions.find(function (item) { return item.id === id; }));
    }, [id, questionGroup.custom_form_questions]);
    useEffect(function () {
        setQuickReplies(selectedScenario ? selectedScenario.quick_reply_choices : null);
        setRichMenuItems(selectedScenario ? selectedScenario.rich_menu_areas : null);
        handleSetCurrentQuestion();
    }, [handleSetCurrentQuestion, selectedScenario]);
    var prevSelectedScenario = useRef(selectedScenario);
    var prevQuestionGroup = useRef(questionGroup);
    useEffect(function () {
        if (selectedScenario && prevSelectedScenario.current) {
            var newQuickReplyChoices = selectedScenario.quick_reply_choices;
            var oldQuickReplyChoices = prevSelectedScenario.current.quick_reply_choices;
            if (newQuickReplyChoices !== oldQuickReplyChoices) {
                setQuickReplies(newQuickReplyChoices);
            }
        }
        if (questionGroup !== prevQuestionGroup.current) {
            handleSetCurrentQuestion();
            setCurrentChoice(null);
            setCurrentRichMenu(null);
        }
    }, [
        prevSelectedScenario,
        prevQuestionGroup,
        selectedScenario,
        questionGroup,
        handleSetCurrentQuestion,
    ]);
    // QUICK REPLY LOGIC
    var handleSetCurrentChoice = function (choiceItem) {
        setCurrentChoice(choiceItem);
        setQuickReplyValue(choiceItem.value);
    };
    var handleQuestionSubmission = function (initialValues, values) {
        var formValues = getDifferenceOfObjects(initialValues, values); // send to backend
        if (Object.keys(formValues).length) {
            // if there are differences in both objects
            updateQuestion({
                selectedFormId: selectedForm.id,
                questionGroupId: questionGroup === null || questionGroup === void 0 ? void 0 : questionGroup.id,
                questionId: id,
                formValues: formValues,
            });
        }
    };
    var handleSearchBlur = function () { return setQuickReplySearchTermTouched(true); };
    var handleQuickReplyRemove = function (choiceId) {
        deleteChoice({
            selectedFormId: selectedForm.id,
            questionGroupId: questionGroup === null || questionGroup === void 0 ? void 0 : questionGroup.id,
            questionId: id,
            body: {
                choice_id: choiceId,
            },
        });
        handleSetCurrentQuestion();
        setCurrentChoice(null);
    };
    var handleSearchItemClick = function (itemId, quickReply) {
        createChoice({
            selectedFormId: selectedForm.id,
            questionGroupId: questionGroup === null || questionGroup === void 0 ? void 0 : questionGroup.id,
            questionId: id,
            choiceData: {
                quick_reply_choice_id: quickReply.id,
                switch: false,
            },
            chatbotId: selectedScenario.chatbot_id,
            selectedScenarioId: selectedScenario.id,
        });
        var clickedChosen = chosenQuickReplyId === itemId;
        setChosenQuickReplyId(clickedChosen ? null : itemId);
        handleSetCurrentQuestion();
    };
    var handleUpdateQuickReplySearchTerm = function (e) {
        setQuickReplySearchTerm(e.target.value);
    };
    var updateQuickReplyChoice = debounce(function (item, quickReplyValue, quickReplySwitch) {
        updateChoice({
            selectedFormId: selectedForm.id,
            questionGroupId: questionGroup === null || questionGroup === void 0 ? void 0 : questionGroup.id,
            questionId: id,
            body: {
                choice_id: item.id,
                quick_reply_choice_id: item.quick_reply_choice.id,
                value: quickReplyValue !== null ? quickReplyValue : item.value,
                switch: quickReplySwitch !== null ? quickReplySwitch : item.switch,
            },
        });
    }, DEBOUNCE_TIME);
    var handleUpdateQuickReplyValue = function (item, quickReplyValue) {
        setQuickReplyValue(quickReplyValue);
        updateQuickReplyChoice(item, quickReplyValue, null);
    };
    var handleSwitchClick = function (item) {
        updateQuickReplyChoice(item, null, !item.switch);
    };
    // Rich Menu Logic
    var handleUpdateRichMenuSearchTerm = function (e) {
        setRichMenuSearchTerm(e.target.value);
    };
    var handleRichMenuSearchBlur = function () { return setRichMenuSearchTermTouched(true); };
    var handleRichMenuSearchItemClick = function (itemId, richItem) {
        var richMenuData = {
            rich_menu_area_id: richItem.id,
            id: id,
            value: null,
            switch: false,
        };
        dispatch(createRichMenu(selectedForm.id, questionGroup === null || questionGroup === void 0 ? void 0 : questionGroup.id, id, richMenuData));
        var clickedChosen = chosenRichMenuId === itemId;
        setChosenRichMenuId(clickedChosen ? null : itemId);
        handleSetCurrentQuestion();
    };
    var handleSetCurrentRichMenu = function (choiceItem) {
        setCurrentRichMenu(choiceItem);
        setRichMenuValue(choiceItem.value);
        setRichMenuSwitch(choiceItem.switch);
    };
    var handleRichMenuRemove = function (richMenu) {
        var body = {
            rich_menu_association_id: richMenu.id,
            rich_menu_area_id: richMenu.rich_menu_area.id,
            id: id,
            value: null,
            switch: false,
        };
        dispatch(deleteRichMenu(selectedForm.id, questionGroup === null || questionGroup === void 0 ? void 0 : questionGroup.id, id, body));
        handleSetCurrentQuestion();
        setCurrentRichMenu(null);
    };
    var handleUpdateRichMenuValue = function (e) {
        setRichMenuValue(e.target.value === '' ? '' : e.target.value);
    };
    var handleUpdateRichMenu = useCallback(function () {
        var body = {
            rich_menu_association_id: currentRichMenu === null || currentRichMenu === void 0 ? void 0 : currentRichMenu.id,
            rich_menu_area_id: currentRichMenu === null || currentRichMenu === void 0 ? void 0 : currentRichMenu.rich_menu_area.id,
            id: id,
            value: richMenuValue || null,
            switch: richMenuSwitch,
        };
        dispatch(updateRichMenu(selectedForm.id, questionGroup === null || questionGroup === void 0 ? void 0 : questionGroup.id, id, body));
    }, [
        currentRichMenu === null || currentRichMenu === void 0 ? void 0 : currentRichMenu.id,
        currentRichMenu === null || currentRichMenu === void 0 ? void 0 : currentRichMenu.rich_menu_area.id,
        dispatch,
        id,
        questionGroup === null || questionGroup === void 0 ? void 0 : questionGroup.id,
        richMenuSwitch,
        richMenuValue,
        selectedForm.id,
    ]);
    var handleKeyDownRichMenuValue = function (e) {
        if (e.key === 'Enter') {
            handleUpdateRichMenu();
        }
    };
    var handleRichMenuSwitchClick = useCallback(function () {
        setRichMenuSwitch(!richMenuSwitch);
        handleUpdateRichMenu();
    }, [handleUpdateRichMenu, richMenuSwitch]);
    var scenarioTalkTriggers = (selectedScenario && selectedScenario.talk_triggers) || [];
    return (React.createElement(ModalPortal, null,
        React.createElement(FullModalOverlay, null),
        React.createElement(Formik, { initialValues: {
                label: label || '',
                description: description || '',
                data_type: data_type || '',
                validation_type: validation_type || '',
                validation_id: validation_id,
                max_answer_num: max_answer_num || '',
                placeholder: placeholder || '',
                nullable: nullable,
            }, validationSchema: questionSchema, onSubmit: function () { return undefined; } }, function (_a) {
            var touched = _a.touched, errors = _a.errors, initialValues = _a.initialValues, values = _a.values;
            return (React.createElement(Modal, __assign({ "data-testid": "QuestionModal", type: "SELECT", text: "Question \u8A2D\u5B9A", width: "60%", minWidth: "800px", maxWidth: "1000px", hasError: !!Object.keys(errors).length, onConfirm: function () { return handleQuestionSubmission(initialValues, values); }, onCancel: onCancel, overFlow: true, withTabs: true }, props, { tabItems: [
                    {
                        name: 'Details',
                        content: (React.createElement(QuestionDetailForm, { touched: touched, errors: errors, values: values })),
                    },
                    {
                        name: 'Quick Reply Choices',
                        content: (React.createElement(QuickChoiceSection, { handleUpdateQuickReplySearchTerm: handleUpdateQuickReplySearchTerm, selectedScenario: selectedScenario, setCurrentChoice: handleSetCurrentChoice, setCurrentQuestion: handleSetCurrentQuestion, handleSearchBlur: handleSearchBlur, handleSearchItemClick: handleSearchItemClick, handleQuickReplyRemove: handleQuickReplyRemove, handleUpdateQuickReplyValue: handleUpdateQuickReplyValue, handleSwitchClick: handleSwitchClick, currentQuestion: currentQuestion, quickReplySearchTerm: quickReplySearchTerm, quickReplies: quickReplies, currentChoice: currentChoice, quickReplyValue: quickReplyValue, quickReplySearchTermTouched: quickReplySearchTermTouched, richMenuSearchTerm: richMenuSearchTerm, richMenuItems: richMenuItems, currentRichMenu: currentRichMenu, richMenuValue: richMenuValue, richMenuSearchTermTouched: richMenuSearchTermTouched, richMenuSwitch: richMenuSwitch })),
                    },
                    {
                        name: 'Rich Menu',
                        content: (React.createElement(RichMenuSection, { handleUpdateRichMenuSearchTerm: handleUpdateRichMenuSearchTerm, selectedScenario: selectedScenario, setCurrentRichMenu: handleSetCurrentRichMenu, setCurrentQuestion: setCurrentQuestion, handleRichMenuSearchBlur: handleRichMenuSearchBlur, handleRichMenuSearchItemClick: handleRichMenuSearchItemClick, handleRichMenuRemove: handleRichMenuRemove, handleKeyDownRichMenuValue: handleKeyDownRichMenuValue, handleUpdateRichMenuValue: handleUpdateRichMenuValue, handleRichMenuSwitchClick: handleRichMenuSwitchClick, currentQuestion: currentQuestion, quickReplySearchTerm: quickReplySearchTerm, quickReplies: quickReplies, currentChoice: currentChoice, quickReplyValue: quickReplyValue, quickReplySearchTermTouched: quickReplySearchTermTouched, richMenuSearchTerm: richMenuSearchTerm, richMenuItems: richMenuItems, currentRichMenu: currentRichMenu, richMenuValue: richMenuValue, richMenuSearchTermTouched: richMenuSearchTermTouched, richMenuSwitch: richMenuSwitch })),
                    },
                    {
                        name: 'Talk Triggers',
                        content: (React.createElement(TalkTriggerSection, __assign({ connectedTalkTriggers: talk_triggers, talkTriggers: scenarioTalkTriggers, selectedScenario: selectedScenario, selectedForm: selectedForm, questionGroup: questionGroup, id: id }, props))),
                    },
                ] })));
        })));
};
export default QuestionModal;
var templateObject_1;
