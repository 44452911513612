import "core-js/modules/es.symbol";
import "core-js/modules/es.array.concat";
import "core-js/modules/es.array.filter";
import "core-js/modules/es.array.map";
import "core-js/modules/es.object.get-own-property-descriptor";
import "core-js/modules/es.object.get-own-property-descriptors";
import "core-js/modules/es.object.keys";
import "core-js/modules/web.dom-collections.for-each";
import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { retentionDelivery } from '@src/redux/fanp/constants';
var SET_RETENTION_TAGS_AND_TOTAL = retentionDelivery.SET_RETENTION_TAGS_AND_TOTAL,
    SET_RETENTION_TARGETS = retentionDelivery.SET_RETENTION_TARGETS,
    ADD_RETENTION_TARGET = retentionDelivery.ADD_RETENTION_TARGET,
    UPDATE_RETENTION_TARGET = retentionDelivery.UPDATE_RETENTION_TARGET,
    DELETE_RETENTION_TARGET = retentionDelivery.DELETE_RETENTION_TARGET,
    SET_RETENTION_CAMPAIGNS = retentionDelivery.SET_RETENTION_CAMPAIGNS,
    ADD_RETENTION_CAMPAIGNS = retentionDelivery.ADD_RETENTION_CAMPAIGNS,
    UPDATE_RETENTION_CAMPAIGN = retentionDelivery.UPDATE_RETENTION_CAMPAIGN,
    DELETE_RETENTION_CAMPAIGN = retentionDelivery.DELETE_RETENTION_CAMPAIGN,
    SET_RETENTION_CONTENTS = retentionDelivery.SET_RETENTION_CONTENTS,
    SET_RETENTION_CONTENT = retentionDelivery.SET_RETENTION_CONTENT,
    SET_RETENTION_SCENARIOS = retentionDelivery.SET_RETENTION_SCENARIOS,
    ADD_RETENTION_CONTENT = retentionDelivery.ADD_RETENTION_CONTENT,
    DELETE_RETENTION_CONTENT = retentionDelivery.DELETE_RETENTION_CONTENT;
var initialState = {
  tags: [],
  total: 0,
  retentionTargets: [],
  retentionCampaigns: [],
  retentionContents: [],
  retentionContentReports: {},
  scenarios: []
};
export default (function () {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case SET_RETENTION_TAGS_AND_TOTAL:
      return _objectSpread({}, state, {
        tags: action.attribute_answers,
        total: action.total
      });

    case SET_RETENTION_TARGETS:
      return _objectSpread({}, state, {
        retentionTargets: action.retentionTargets
      });

    case ADD_RETENTION_TARGET:
      return _objectSpread({}, state, {
        retentionTargets: [].concat(_toConsumableArray(state.retentionTargets), [action.retentionTarget])
      });

    case UPDATE_RETENTION_TARGET:
      return _objectSpread({}, state, {
        retentionTargets: state.retentionTargets.map(function (target) {
          if (target.id === action.retentionTargetId) {
            return action.retentionTarget;
          }

          return target;
        })
      });

    case DELETE_RETENTION_TARGET:
      return _objectSpread({}, state, {
        retentionTargets: state.retentionTargets.filter(function (target) {
          return target.id !== action.retentionTargetId;
        })
      });

    case SET_RETENTION_CAMPAIGNS:
      return _objectSpread({}, state, {
        retentionCampaigns: action.retentionCampaigns
      });

    case ADD_RETENTION_CAMPAIGNS:
      return _objectSpread({}, state, {
        retentionCampaigns: [].concat(_toConsumableArray(state.retentionCampaigns), [action.retentionCampaign])
      });

    case UPDATE_RETENTION_CAMPAIGN:
      return _objectSpread({}, state, {
        retentionCampaigns: state.retentionCampaigns.map(function (campaign) {
          if (campaign.id === action.retentionCampaignId) {
            return action.retentionCampaign;
          }

          return campaign;
        })
      });

    case DELETE_RETENTION_CAMPAIGN:
      return _objectSpread({}, state, {
        retentionCampaigns: state.retentionCampaigns.filter(function (c) {
          return c.id !== action.campaignId;
        })
      });

    case SET_RETENTION_CONTENTS:
      return _objectSpread({}, state, {
        retentionContents: action.retentionContents,
        retentionContentReports: action.retentionContentReports
      });

    case SET_RETENTION_CONTENT:
      return _objectSpread({}, state, {
        retentionContents: state.retentionContents.map(function (content) {
          if (content.id === action.contentId) {
            return action.retentionContent;
          }

          return content;
        })
      });

    case SET_RETENTION_SCENARIOS:
      return _objectSpread({}, state, {
        scenarios: action.retentionScenarios
      });

    case ADD_RETENTION_CONTENT:
      return _objectSpread({}, state, {
        retentionContents: [].concat(_toConsumableArray(state.retentionContents), [action.retentionContent]),
        retentionContentReports: [].concat(_toConsumableArray(state.retentionContentReports), [action.retentionContentReport])
      });

    case DELETE_RETENTION_CONTENT:
      return _objectSpread({}, state, {
        retentionContents: state.retentionContents.filter(function (rc) {
          return rc.id !== action.contentId;
        })
      });

    default:
      return state;
  }
});