var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import transformResponse from './transformResponse';
import axiosInstance from './axiosInstance';
import { buttonTypes } from '@src/lib/app-constants';
import { objToQuery } from '@src/lib/helpers';
var base = function (_a) {
    var chatbotId = _a.chatbotId, scenarioId = _a.scenarioId;
    return "/chatbots/" + chatbotId + "/scenarios/" + scenarioId;
};
var buttons = function (genericItemId, chatbotId, scenarioId, messageId) {
    return items({
        chatbotId: chatbotId,
        scenarioId: scenarioId,
        messageId: messageId,
    }) + "/" + genericItemId + "/generic_item_buttons";
};
var buttonsMember = function (_a) {
    var id = _a.id, genericItemId = _a.genericItemId, chatbotId = _a.chatbotId, scenarioId = _a.scenarioId, messageId = _a.messageId;
    return buttons(genericItemId, chatbotId, scenarioId, messageId) + "/" + id;
};
var items = function (_a) {
    var chatbotId = _a.chatbotId, scenarioId = _a.scenarioId, messageId = _a.messageId;
    return base({
        chatbotId: chatbotId,
        scenarioId: scenarioId,
    }) + "/messages/" + messageId + "/generic_items";
};
var itemsMember = function (_a) {
    var id = _a.id, chatbotId = _a.chatbotId, scenarioId = _a.scenarioId, messageId = _a.messageId;
    return items({ chatbotId: chatbotId, scenarioId: scenarioId, messageId: messageId }) + "/" + id;
};
var tag = function (params) { return itemsMember(params) + "/tag_rules"; };
var swapRank = function (params) { return items(params) + "/swap_rank"; };
var templates = function (chatbotId, scenarioId) {
    return base({ chatbotId: chatbotId, scenarioId: scenarioId }) + "/generic_templates";
};
var templatesMember = function (_a) {
    var chatbotId = _a.chatbotId, scenarioId = _a.scenarioId, id = _a.id;
    return templates(chatbotId, scenarioId) + "/" + id;
};
export function createGenericMessage(chatbotId, scenarioId, blockId) {
    return __awaiter(this, void 0, void 0, function () {
        var url, body, res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    url = templates(chatbotId, scenarioId);
                    body = { block_id: blockId };
                    return [4 /*yield*/, transformResponse(axiosInstance.post(url, body))];
                case 1:
                    res = _a.sent();
                    return [2 /*return*/, res.message];
            }
        });
    });
}
export function connectBlockToButton(_a) {
    var chatbotId = _a.chatbotId, scenarioId = _a.scenarioId, messageId = _a.messageId, genericItemId = _a.genericItemId, layerRank = _a.layerRank, effect = _a.effect, name = _a.name;
    return __awaiter(this, void 0, void 0, function () {
        var url, body, res;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    url = buttons(genericItemId, chatbotId, scenarioId, messageId);
                    body = {
                        name: name,
                        effect: effect,
                        generic_item_id: genericItemId,
                        layer_rank: layerRank,
                    };
                    return [4 /*yield*/, transformResponse(axiosInstance.post(url, body))];
                case 1:
                    res = _b.sent();
                    return [2 /*return*/, res.generic_item_button];
            }
        });
    });
}
export function createNewButton(_a) {
    var chatbotId = _a.chatbotId, scenarioId = _a.scenarioId, messageId = _a.messageId, genericItemId = _a.genericItemId, buttonTemplate = _a.buttonTemplate, blockId = _a.blockId, nextScenarioId = _a.nextScenarioId, layerRank = _a.layerRank;
    return __awaiter(this, void 0, void 0, function () {
        var url, body, res;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    url = buttons(genericItemId, chatbotId, scenarioId, messageId);
                    body = {
                        name: buttonTemplate.effect === buttonTypes.SHARE
                            ? 'シェアする'
                            : buttonTemplate.name,
                        url: buttonTemplate.url,
                        effect: buttonTemplate.effect,
                    };
                    if (blockId) {
                        body.effect = null;
                        body.next_block_id = blockId;
                        body.layer_rank = layerRank;
                    }
                    else if (nextScenarioId) {
                        body.effect = null;
                        body.next_scenario_id = nextScenarioId;
                    }
                    return [4 /*yield*/, transformResponse(axiosInstance.post(url, body))];
                case 1:
                    res = _b.sent();
                    return [2 /*return*/, res.generic_item_button];
            }
        });
    });
}
export function removeButton(_a) {
    var chatbotId = _a.chatbotId, scenarioId = _a.scenarioId, messageId = _a.messageId, itemId = _a.itemId, buttonItemId = _a.buttonItemId, layerRank = _a.layerRank;
    return __awaiter(this, void 0, void 0, function () {
        var query, url;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    query = objToQuery({ layer_rank: layerRank });
                    url = buttonsMember({
                        id: buttonItemId,
                        genericItemId: itemId,
                        chatbotId: chatbotId,
                        scenarioId: scenarioId,
                        messageId: messageId,
                    }) + "?" + query;
                    return [4 /*yield*/, transformResponse(axiosInstance.delete(url))];
                case 1:
                    _b.sent();
                    return [2 /*return*/];
            }
        });
    });
}
export function updateButton(_a) {
    var chatbotId = _a.chatbotId, scenarioId = _a.scenarioId, messageId = _a.messageId, genericItemId = _a.genericItemId, id = _a.id, buttonTemplate = _a.buttonTemplate, nextScenarioId = _a.nextScenarioId;
    return __awaiter(this, void 0, void 0, function () {
        var url, body, res;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    url = buttonsMember({
                        chatbotId: chatbotId,
                        scenarioId: scenarioId,
                        messageId: messageId,
                        genericItemId: genericItemId,
                        id: id,
                    });
                    switch (buttonTemplate.effect) {
                        case buttonTypes.URL:
                        case buttonTypes.CALL:
                        case buttonTypes.EMAIL:
                            body = {
                                name: buttonTemplate.name,
                                url: buttonTemplate.url,
                            };
                            break;
                        case buttonTypes.STOCK:
                            body = {
                                name: buttonTemplate.name,
                                effect: buttonTemplate.effect,
                            };
                            break;
                        case buttonTypes.SHARE:
                            body = {
                                name: 'シェアする',
                                effect: buttonTemplate.effect,
                            };
                            break;
                        case buttonTypes.BLOCK:
                            if (buttonTemplate.blockId) {
                                body = {
                                    name: buttonTemplate.name,
                                    effect: null,
                                    next_block_id: buttonTemplate.blockId,
                                    layer_rank: buttonTemplate.layerRank,
                                };
                            }
                            else {
                                body = {
                                    name: buttonTemplate.name,
                                    effect: null,
                                    next_scenario_id: nextScenarioId,
                                    layer_rank: buttonTemplate.layerRank,
                                };
                            }
                            break;
                        default:
                            break;
                    }
                    return [4 /*yield*/, transformResponse(axiosInstance.put(url, body))];
                case 1:
                    res = _b.sent();
                    return [2 /*return*/, res.generic_item_button];
            }
        });
    });
}
export function createTag(_a) {
    var name = _a.name, chatbotId = _a.chatbotId, scenarioId = _a.scenarioId, messageId = _a.messageId, itemId = _a.itemId;
    return __awaiter(this, void 0, void 0, function () {
        var url, body, res;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    url = tag({ id: itemId, chatbotId: chatbotId, scenarioId: scenarioId, messageId: messageId });
                    body = { name: name };
                    return [4 /*yield*/, transformResponse(axiosInstance.post(url, body))];
                case 1:
                    res = _b.sent();
                    return [2 /*return*/, res.tag];
            }
        });
    });
}
export function removeTag(_a) {
    var messageId = _a.messageId, chatbotId = _a.chatbotId, scenarioId = _a.scenarioId, itemId = _a.itemId, tagId = _a.tagId, name = _a.name;
    return __awaiter(this, void 0, void 0, function () {
        var url, body;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    url = tag({ chatbotId: chatbotId, scenarioId: scenarioId, messageId: messageId, id: itemId });
                    body = { name: name, tag_id: tagId };
                    return [4 /*yield*/, transformResponse(axiosInstance.delete(url, { data: body }))];
                case 1:
                    _b.sent();
                    return [2 /*return*/];
            }
        });
    });
}
export function createItem(_a) {
    var chatbotId = _a.chatbotId, scenarioId = _a.scenarioId, messageId = _a.messageId;
    return __awaiter(this, void 0, void 0, function () {
        var url, res;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    url = items({ chatbotId: chatbotId, scenarioId: scenarioId, messageId: messageId });
                    return [4 /*yield*/, transformResponse(axiosInstance.post(url))];
                case 1:
                    res = _b.sent();
                    return [2 /*return*/, res.generic_item];
            }
        });
    });
}
export function removeItem(_a) {
    var chatbotId = _a.chatbotId, scenarioId = _a.scenarioId, messageId = _a.messageId, itemId = _a.itemId, layerRank = _a.layerRank;
    return __awaiter(this, void 0, void 0, function () {
        var query, url;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    query = objToQuery({ layer_rank: layerRank });
                    url = itemsMember({
                        chatbotId: chatbotId,
                        scenarioId: scenarioId,
                        messageId: messageId,
                        id: itemId,
                    }) + "?" + query;
                    return [4 /*yield*/, transformResponse(axiosInstance.delete(url))];
                case 1:
                    _b.sent();
                    return [2 /*return*/];
            }
        });
    });
}
export function updateItemRank(_a) {
    var chatbotId = _a.chatbotId, scenarioId = _a.scenarioId, messageId = _a.messageId, resourceItemId = _a.resourceItemId, resourceItemRank = _a.resourceItemRank, targetItemId = _a.targetItemId, targetItemRank = _a.targetItemRank;
    return __awaiter(this, void 0, void 0, function () {
        var url, body;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    url = swapRank({ chatbotId: chatbotId, scenarioId: scenarioId, messageId: messageId });
                    body = {
                        resource_item_id: resourceItemId,
                        resource_item_rank: resourceItemRank,
                        target_item_id: targetItemId,
                        target_item_rank: targetItemRank,
                    };
                    return [4 /*yield*/, transformResponse(axiosInstance.put(url, body))];
                case 1:
                    _b.sent();
                    return [2 /*return*/];
            }
        });
    });
}
export function updateImeage(_a) {
    var chatbotId = _a.chatbotId, adScenarioId = _a.adScenarioId, genericTemplateId = _a.genericTemplateId, imageAspectRatio = _a.imageAspectRatio;
    return __awaiter(this, void 0, void 0, function () {
        var url, body;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    url = templatesMember({
                        chatbotId: chatbotId,
                        scenarioId: adScenarioId,
                        id: genericTemplateId,
                    });
                    body = { image_aspect_ratio: imageAspectRatio };
                    return [4 /*yield*/, transformResponse(axiosInstance.put(url, body))];
                case 1:
                    _b.sent();
                    return [2 /*return*/];
            }
        });
    });
}
