import "core-js/modules/es.array.from";
import "core-js/modules/es.array.join";
import "core-js/modules/es.string.iterator";
import "core-js/modules/es.string.split";
import "core-js/modules/web.dom-collections.for-each";
import { select } from 'd3';
import { isJp, groupByLanguage, joinByLanguage } from '@src/lib/groupByLanguage';
export default function wrap(text, width) {
  var fill = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'black';
  text.each(function () {
    var text = select(this);
    var sentences = groupByLanguage(text.text()); // word,

    var line = [];
    var lineNumber = 0;
    var lineHeight = 1.1; // ems

    var x = text.attr('x');
    var y = text.attr('y');
    var dy = 0; // parseFloat(text.attr("dy")),

    var tspan = text.text(null).append('tspan').attr('x', x).attr('y', y).attr('fill', fill).attr('dy', "".concat(dy, "em"));

    function writeTextToScreen(txt) {
      var separator = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : ' ';
      line.push(txt);
      tspan.text(joinByLanguage(line).join(separator));

      if (tspan.node().getComputedTextLength() > width) {
        line.pop();

        if (joinByLanguage(line).join(separator)) {
          tspan.text(joinByLanguage(line).join(separator));
          line = [txt];
          tspan = text.append('tspan').attr('x', x).attr('y', y).attr('fill', fill).attr('dy', "".concat(++lineNumber * lineHeight + dy, "em")).text(txt);
        } else {
          tspan.text(txt);
        }

        if (tspan.node().getComputedTextLength() > width) {
          line.pop();
          var characters = Array.from(txt);

          for (var i = 0; i < characters.length; i++) {
            var char = characters[i];
            writeTextToScreen(char, '');
          }
        }
      }
    }

    function englishWrap(sentence) {
      sentence.split(' ').forEach(function (word) {
        return writeTextToScreen(word);
      });
    }

    function jpWrap(sentence) {
      // JP words should be pushed on one character at a time
      var characters = Array.from(sentence);

      for (var i = 0; i < characters.length; i++) {
        var char = characters[i];
        writeTextToScreen(char);
      }
    } // Write all words to screen


    for (var i = 0; i < sentences.length; i++) {
      var sentence = sentences[i];

      if (isJp(sentence)) {
        jpWrap(sentence);
      } else {
        englishWrap(sentence);
      }
    }
  });
}