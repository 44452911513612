import React from 'react';
import { NextPostbackText, DeleteIcon } from '@src/components/atoms';
import { getConnectedIds } from '@src/lib/adHelpers';
export var getBlockText = function (connectedObject, blocks, id) {
    if (!blocks)
        return '';
    var block = blocks.find(function (b) { return b.id === id; });
    if (block)
        return block.name;
    if (connectedObject.block)
        return connectedObject.block.name;
    return '';
};
// Given some "ConnectedObject", this will display a block
// with the proper block icon and the block's name
export default function NextBlock(_a) {
    var connectedObject = _a.connectedObject, blocks = _a.blocks, forModal = _a.forModal, isQuickReplyChoice = _a.isQuickReplyChoice, disconnectBlock = _a.disconnectBlock;
    var blockId = getConnectedIds(connectedObject).blockId; // for buttons
    var blockText = getBlockText(connectedObject, blocks, blockId);
    return (React.createElement(NextPostbackText, { "data-testid": "NextBlock-NextPostbackBlock-" + blockText, forModal: forModal, isQuickReplyChoice: isQuickReplyChoice },
        React.createElement("p", { className: "" + (connectedObject && blockId ? 'adicon_nextblock' : ''), "data-testid": "NextBlock_BlockName" }, blockText),
        forModal ? React.createElement(DeleteIcon, { onClick: disconnectBlock }) : null));
}
