var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import styled from 'styled-components';
import { Button, Icon, MUIChip } from '@zeals-co-ltd/washi-components';
import * as userDossierGrpc from '@src/services/zeals_grpc/userDossier';
import { handleAPIErrors } from '@src/lib/axios';
import { useParams, useHistory } from 'react-router-dom';
import { DataType, } from 'zeals-protobuf/zeals/web/entities/user_dossier_pb';
import { Spinner } from '@src/components/atoms/';
import * as pathLinks from '@src/routes/Fanp/pathLinks';
import { grey, blue, navy, shadows } from '@zeals-co-ltd/washi-styles';
import moment from 'moment';
import * as googleProtobufTimestampPb from 'google-protobuf/google/protobuf/timestamp_pb';
import { injectIntl } from 'react-intl';
import { centeredFlex, columnFlex, rowFlex } from '@src/lib/mixins';
var UserInsightContainer = styled(Box)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", "\n  height: 100%;\n  position: relative;\n  margin-left: 16px;\n  border-radius: 8px;\n  background: ", ";\n  box-shadow: ", ";\n"], ["\n  ", "\n  height: 100%;\n  position: relative;\n  margin-left: 16px;\n  border-radius: 8px;\n  background: ", ";\n  box-shadow: ", ";\n"])), columnFlex, grey[50], shadows[4]);
var InsightHeader = styled(Box)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", "\n  justify-content: space-between;\n  align-items: center;\n  padding: 8px;\n  height: 48px;\n  border: 1px solid ", ";\n  border-top-left-radius: 8px;\n  background: ", ";\n  border-top-right-radius: 8px;\n"], ["\n  ", "\n  justify-content: space-between;\n  align-items: center;\n  padding: 8px;\n  height: 48px;\n  border: 1px solid ", ";\n  border-top-left-radius: 8px;\n  background: ", ";\n  border-top-right-radius: 8px;\n"])), rowFlex, grey[300], grey.A100);
var InsightBody = styled(Box)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  ", "\n  height: calc(100% - 96px);\n  overflow: auto;\n  border-left: 1px solid ", ";\n  border-right: 1px solid ", ";\n  padding: 8px;\n"], ["\n  ", "\n  height: calc(100% - 96px);\n  overflow: auto;\n  border-left: 1px solid ", ";\n  border-right: 1px solid ", ";\n  padding: 8px;\n"])), columnFlex, grey[300], grey[300]);
var InsightFooter = styled(Box)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  ", "\n  height: 48px;\n  border: 1px solid ", ";\n  border-bottom-left-radius: 8px;\n  background: ", ";\n  border-bottom-right-radius: 8px;\n"], ["\n  ", "\n  height: 48px;\n  border: 1px solid ", ";\n  border-bottom-left-radius: 8px;\n  background: ", ";\n  border-bottom-right-radius: 8px;\n"])), centeredFlex, grey[300], grey.A100);
var UserInsightAvatar = styled(Avatar)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  height: 32px;\n  width: 32px;\n"], ["\n  height: 32px;\n  width: 32px;\n"])));
var UniqueDataLabel = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  width: auto;\n  display: block;\n  height: 14px;\n  color: ", ";\n  font-size: 10px;\n  margin: 2px 6px 8px 6px;\n"], ["\n  width: auto;\n  display: block;\n  height: 14px;\n  color: ", ";\n  font-size: 10px;\n  margin: 2px 6px 8px 6px;\n"])), navy[300]);
var CustomChip = styled(MUIChip)(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  height: 24px;\n  width: auto;\n  font-size: 10px;\n  font-weight: 500;\n  line-height: 14px;\n  letter-spacing: 1.5px;\n  text-transform: uppercase;\n  color: ", ";\n  background-color: ", ";\n  &.MuiChip-label {\n    padding: 5px 8px;\n    font-size: 10px;\n    font-weight: 500;\n    line-height: 14px;\n  }\n"], ["\n  height: 24px;\n  width: auto;\n  font-size: 10px;\n  font-weight: 500;\n  line-height: 14px;\n  letter-spacing: 1.5px;\n  text-transform: uppercase;\n  color: ", ";\n  background-color: ", ";\n  &.MuiChip-label {\n    padding: 5px 8px;\n    font-size: 10px;\n    font-weight: 500;\n    line-height: 14px;\n  }\n"])), blue[500], blue[50]);
var FieldAttributeCardContainer = styled(Stack)(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  width: auto;\n  height: 74px;\n  border-radius: 6px;\n  border: 1px solid ", ";\n  background: ", ";\n  padding: 8px;\n  ", ";\n  justify-content: space-between;\n"], ["\n  width: auto;\n  height: 74px;\n  border-radius: 6px;\n  border: 1px solid ", ";\n  background: ", ";\n  padding: 8px;\n  ", ";\n  justify-content: space-between;\n"])), grey[300], grey.A100, columnFlex);
var FieldAttributeCard = function (_a) {
    var fieldName = _a.fieldName, fieldValue = _a.fieldValue;
    return (React.createElement(FieldAttributeCardContainer, null,
        React.createElement(Box, { style: { fontSize: 12 } },
            React.createElement("span", { style: { color: navy[300] }, "data-testid": "unique-field-name-field" }, "Field:"),
            React.createElement("span", { style: { color: navy[600], marginLeft: 8 }, "data-testid": "unique-field-name-value" }, fieldName)),
        React.createElement(Box, null,
            React.createElement(CustomChip, { "data-testid": "unique-field-answer-value", label: fieldValue }))));
};
var useFetchUniqueFieldAttributes = function (chatbotId, endUserId) {
    var _a = __read(useState(true), 2), loading = _a[0], setLoading = _a[1];
    var _b = __read(useState(true), 2), hasUniqueFields = _b[0], setHasUniqueFields = _b[1];
    var _c = __read(useState([]), 2), uniqueAttributeMap = _c[0], setUniqueAttributeMap = _c[1];
    var timestamp = new googleProtobufTimestampPb.Timestamp();
    var getFieldValue = function (value) {
        switch (value === null || value === void 0 ? void 0 : value.type) {
            case DataType.DATA_TYPE_STRING:
                return value.stringValue;
            case DataType.DATA_TYPE_INTEGER:
                return value.integerValue;
            case DataType.DATA_TYPE_DATETIME:
                return moment(timestamp
                    .setSeconds(value.datetimeValue.seconds)
                    .setNanos(value.datetimeValue.nanos)
                    .toDate()).format('YYYY-MM-DDTHH:mm');
            case DataType.DATA_TYPE_DOUBLE:
                return value.doubleValue;
            case DataType.DATA_TYPE_BOOLEAN:
                return value.boolValue.toString();
            case DataType.DATA_TYPE_ENCRYPTED:
                return value.encryptedValue;
            default:
                return null;
        }
    };
    // TODO: adding useCallback
    var searchFieldCollection = function (chatbotId) { return __awaiter(void 0, void 0, void 0, function () {
        var error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setLoading(true);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, userDossierGrpc
                            .listFieldCollections(chatbotId)
                            .then(function (listFieldcollection) {
                            var uniqueFieldCollection = listFieldcollection.fieldCollectionsList.find(function (fieldCollection) { return fieldCollection.type === 1; });
                            fetchUniqueAttributes(endUserId, uniqueFieldCollection);
                        })];
                case 2:
                    _a.sent();
                    return [3 /*break*/, 4];
                case 3:
                    error_1 = _a.sent();
                    handleAPIErrors(error_1);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var fetchUniqueAttributes = function (endUserId, uniqueFieldCollection) { return __awaiter(void 0, void 0, void 0, function () {
        var error_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, userDossierGrpc
                            .getAttributeCollection(endUserId, uniqueFieldCollection.id)
                            .then(function (result) {
                            var _a;
                            var mappedValues = [];
                            (_a = result.attributesList) === null || _a === void 0 ? void 0 : _a.map(function (item) {
                                var uniqueField = uniqueFieldCollection.fieldsList.find(function (element) { return element.id === item.fieldId; });
                                if (uniqueField) {
                                    var dummyObj = {
                                        name: uniqueField.name,
                                        value: getFieldValue(item.value),
                                    };
                                    mappedValues.push(dummyObj);
                                }
                                return mappedValues;
                            });
                            setUniqueAttributeMap(mappedValues);
                            setHasUniqueFields(true);
                            setLoading(false);
                        })];
                case 1:
                    _a.sent();
                    return [3 /*break*/, 3];
                case 2:
                    error_2 = _a.sent();
                    setUniqueAttributeMap([]);
                    handleAPIErrors(error_2);
                    setHasUniqueFields(false);
                    setLoading(false);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        searchFieldCollection(chatbotId);
    }, [endUserId]);
    return {
        loading: loading,
        uniqueAttributeMap: uniqueAttributeMap,
        hasUniqueFields: hasUniqueFields,
    };
};
var UserInsight = function (props) {
    var selectedUser = props.selectedUser, intl = props.intl, setSelectedUserId = props.setSelectedUserId;
    var URLParams = useParams();
    var browserHistory = useHistory();
    var _a = useFetchUniqueFieldAttributes(URLParams.chatbotId, selectedUser === null || selectedUser === void 0 ? void 0 : selectedUser.id), loading = _a.loading, uniqueAttributeMap = _a.uniqueAttributeMap;
    var handleOnClick = function (endUserId, chatbotId) {
        browserHistory.push(pathLinks.dashboardAdChatbots_chatbotId_TalkEnd_Users_endUserId_({
            endUserId: endUserId,
            chatbotId: chatbotId,
        }));
    };
    var onCloseUserInsight = function () {
        setSelectedUserId(undefined);
    };
    return (React.createElement(UserInsightContainer, { "data-testid": "user-insights-box" },
        React.createElement(InsightHeader, null,
            React.createElement(Stack, { direction: "row", alignItems: "center", spacing: "8px" },
                React.createElement(UserInsightAvatar, { src: selectedUser.profile_photo_url, alt: selectedUser.first_name, className: "profilePhoto" }),
                React.createElement("span", { style: { lineHeight: 2 } }, selectedUser.first_name + " " + selectedUser.last_name)),
            React.createElement("div", null,
                React.createElement(Icon, { name: "adicon_close", onClick: onCloseUserInsight }))),
        React.createElement(InsightBody, null,
            React.createElement(UniqueDataLabel, { "data-testid": "unique-data-tag" }, intl.formatMessage({ id: "LU/jeU", defaultMessage: "UNIQUE DATA" })),
            loading ? (React.createElement(Spinner, null)) : (React.createElement(Stack, { direction: "column", spacing: "8px" }, uniqueAttributeMap.map(function (item) {
                return (React.createElement(FieldAttributeCard, { key: item.value, "data-testid": "unique-field-item", fieldName: item.name, fieldValue: item.value }));
            })))),
        React.createElement(InsightFooter, null,
            React.createElement(Button, { variant: "text", "data-testid": "user-insight-talk-room-button", onClick: function () { return handleOnClick(selectedUser.id, URLParams.chatbotId); } }, intl.formatMessage({ id: "uB9wNr", defaultMessage: "VIEW IN TALK ROOM" })))));
};
export default injectIntl(UserInsight);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8;
