import "core-js/modules/es.array.filter";
import "core-js/modules/es.array.includes";
import "core-js/modules/es.array.map";
import "core-js/modules/es.function.name";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.regexp.to-string";
import "core-js/modules/es.string.includes";
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { colors } from '@src/colors';
import { transitions } from '@src/lib/animations';
import { Checkbox } from '@zeals-co-ltd/washi-components';
import { Input } from '@src/components/atoms';
var MainContainer = styled.div.withConfig({
  displayName: "MainContainer",
  componentId: "sc-16a0u48-0"
})(["display:flex;.pick-area{flex:3;padding-right:1.5rem;border-right:2px solid ", ";}.edit-area{margin-left:1.5rem;flex:5;}"], colors.border.default);
var SectionContainer = styled.div.withConfig({
  displayName: "SectionContainer",
  componentId: "sc-16a0u48-1"
})(["margin:1rem 0 0.25rem;.label{font-weight:bold;padding-bottom:0.25rem;}.item-property{font-size:14px;margin-top:0.5rem;span{margin-right:0.5rem;}}"]);
var ItemWrapper = styled.ul.withConfig({
  displayName: "ItemWrapper",
  componentId: "sc-16a0u48-2"
})(["max-height:300px;margin-top:0.5rem;overflow:auto;"]);
var connectedItemCss = css(["transition:", ";background:", ";border:2px solid ", ";color:", ";&:hover{cursor:pointer;background:", ";color:", ";}"], transitions.normal, function (props) {
  return props.selected ? colors.custom_form_admin.hover : colors.custom_form_admin.body_pale;
}, function (props) {
  return props.selected ? colors.custom_form_admin.hover_secondary : colors.custom_form_admin.hover;
}, function (props) {
  return props.selected ? colors.background.main_menu : colors.custom_form_admin.main_color;
}, colors.custom_form_admin.hover, colors.background.main_menu);
var unconnectedItemCss = css(["transition:", ";border:1px solid ", ";background:", ";color:", ";&:hover{cursor:pointer;background:", ";color:", ";}"], transitions.normal, colors.custom_form_admin.hover_secondary, colors.custom_form_admin.body_pale, function (props) {
  return props.selected ? colors.custom_form_admin.main_color : colors.custom_form_admin.hover_secondary;
}, colors.custom_form_admin.body_pale, colors.custom_form_admin.main_color);
var ItemContainer = styled.li.withConfig({
  displayName: "ItemContainer",
  componentId: "sc-16a0u48-3"
})(["display:flex;font-family:'Noto Sans JP','Roboto',Arial !important;margin-top:0.5rem;font-size:14px;padding:0.5rem;border-radius:100px;", ";div{flex:3;}button{flex:1;align-items:right;position:relative;}.adicon_delete{font-size:1.5rem;color:", ";position:absolute;right:0px;top:-7px;height:20px;}"], function (props) {
  return props.connected ? connectedItemCss : unconnectedItemCss;
}, colors.custom_form_admin.body_pale);

var Section = function Section(_ref) {
  var children = _ref.children;
  return React.createElement(SectionContainer, {
    className: "font-sm"
  }, React.createElement("div", {
    className: "body"
  }, children));
};

var ChoiceItem = function ChoiceItem(_ref2) {
  var item = _ref2.item,
      currentRichMenu = _ref2.currentRichMenu,
      id = _ref2.id,
      name = _ref2.name,
      onClick = _ref2.onClick,
      connected = _ref2.connected,
      handleRemove = _ref2.handleRemove;
  return React.createElement(ItemContainer, {
    "data-testid": "RichMenuSection-ItemContainer",
    onClick: onClick,
    selected: item === currentRichMenu ? currentRichMenu : null,
    connected: connected
  }, React.createElement("div", {
    className: "font-sm"
  }, id), React.createElement("div", {
    className: "font-sm"
  }, name), connected ? React.createElement("button", {
    type: "button",
    onClick: handleRemove,
    "data-testid": "RichMenuSection-RemoveButton"
  }, React.createElement("span", {
    className: "adicon_delete"
  })) : null);
};

var currentRichMenuConnected = function currentRichMenuConnected(richItem, currentConnectedRichMenuItems) {
  if (richItem && currentConnectedRichMenuItems) {
    return currentConnectedRichMenuItems.some(function (connectRichItem) {
      return richItem.id === connectRichItem.rich_menu_area.id;
    });
  }

  return undefined;
};

var richMenuItemIsBeingSearched = function richMenuItemIsBeingSearched(richItem, richMenuSearchTerm) {
  return richItem.lable && richItem.label.includes(richMenuSearchTerm) || richItem.id.toString().includes(richMenuSearchTerm);
};

var RichMenuSection = function RichMenuSection(_ref3) {
  var selectedScenario = _ref3.selectedScenario,
      currentQuestion = _ref3.currentQuestion,
      richMenuItems = _ref3.richMenuItems,
      currentRichMenu = _ref3.currentRichMenu,
      chosenRichMenuId = _ref3.chosenRichMenuId,
      richMenuSwitch = _ref3.richMenuSwitch,
      richMenuSearchTerm = _ref3.richMenuSearchTerm,
      richMenuSearchTermTouched = _ref3.richMenuSearchTermTouched,
      richMenuValue = _ref3.richMenuValue,
      setCurrentRichMenu = _ref3.setCurrentRichMenu,
      handleRichMenuSwitchClick = _ref3.handleRichMenuSwitchClick,
      handleRichMenuSearchItemClick = _ref3.handleRichMenuSearchItemClick,
      handleRichMenuRemove = _ref3.handleRichMenuRemove,
      handleUpdateRichMenuValue = _ref3.handleUpdateRichMenuValue,
      handleKeyDownRichMenuValue = _ref3.handleKeyDownRichMenuValue,
      handleRichMenuSearchBlur = _ref3.handleRichMenuSearchBlur,
      handleUpdateRichMenuSearchTerm = _ref3.handleUpdateRichMenuSearchTerm;
  richMenuItems = richMenuItems || [];
  var filteredRichMenuItems = richMenuItems.filter(function (richItem) {
    return !currentRichMenuConnected(richItem, currentQuestion.rich_menu_areas);
  }).filter(function (richItem) {
    return richMenuItemIsBeingSearched(richItem, richMenuSearchTerm);
  });
  return React.createElement(MainContainer, {
    "data-testid": "RichMenuSection"
  }, React.createElement("div", {
    className: "pick-area"
  }, selectedScenario ? React.createElement(Section, {
    label: "Scenario"
  }, React.createElement(React.Fragment, null, React.createElement("div", {
    className: "item-property"
  }, React.createElement("span", {
    className: "font-bold font-large"
  }, "Name: "), selectedScenario.name), React.createElement("div", {
    className: "item-property"
  }, React.createElement("span", {
    className: "font-bold font-large"
  }, "ID: "), selectedScenario.id))) : null, currentQuestion && currentQuestion.rich_menu_areas ? React.createElement(Section, null, React.createElement("div", {
    className: "message-wrapper primary-label",
    style: {
      margin: '0 0 0 8px'
    }
  }, "Connected Rich Menu Items"), React.createElement(ItemWrapper, null, currentQuestion.rich_menu_areas.map(function (question) {
    return question.rich_menu_area ? React.createElement(ChoiceItem, {
      "data-testid": "RichMenuSection-ChoiceItem",
      key: "item-".concat(question.id),
      item: question,
      id: question.rich_menu_id,
      name: question.rich_menu_area.label || 'No Label',
      currentChoice: currentRichMenu,
      connected: true,
      onClick: function onClick() {
        return setCurrentRichMenu(question);
      },
      handleRemove: function handleRemove() {
        return handleRichMenuRemove(question);
      }
    }) : null;
  }))) : null, React.createElement(Section, null, React.createElement(React.Fragment, null, React.createElement("div", {
    className: "message-wrapper primary-label",
    style: {
      margin: '16px 0 0 8px'
    },
    margin: "16px 0 0 8px"
  }, "Rich Menu Items"), React.createElement(Input, {
    hasError: !richMenuSearchTerm && richMenuSearchTermTouched,
    placeholder: "Rich Menu Search",
    onChange: handleUpdateRichMenuSearchTerm,
    onBlur: function onBlur() {
      return handleRichMenuSearchBlur();
    },
    value: richMenuSearchTerm
  }), React.createElement(ItemWrapper, null, filteredRichMenuItems.map(function (richItem) {
    return React.createElement(ChoiceItem, {
      "data-testid": "RichMenuSection-ChoiceItem",
      key: "unconnected-".concat(richItem.id),
      id: richItem.id,
      name: richItem && richItem.label || 'No Label',
      selected: chosenRichMenuId === richItem.id,
      connected: false,
      onClick: function onClick() {
        return handleRichMenuSearchItemClick(richItem.id, richItem);
      }
    });
  }))))), React.createElement("div", {
    className: "edit-area"
  }, currentRichMenu && currentRichMenu.id ? React.createElement(React.Fragment, null, React.createElement(Section, {
    label: "Label"
  }, React.createElement("div", {
    className: "label"
  }, currentRichMenu.label ? currentRichMenu.label : 'No Label')), React.createElement(Section, {
    label: "Rich Menu Value"
  }, React.createElement(Input, {
    placeholder: "update value",
    onChange: handleUpdateRichMenuValue,
    onKeyDown: handleKeyDownRichMenuValue,
    value: richMenuValue || richMenuValue === '' ? richMenuValue : currentRichMenu.value
  })), React.createElement(Section, {
    label: "Quick Reply Switch"
  }, React.createElement(Checkbox, {
    "data-testid": "RichMenuSection-Checkbox",
    color: "primary",
    checked: richMenuSwitch,
    size: "small",
    onClick: handleRichMenuSwitchClick
  }))) : React.createElement("div", {
    className: "message-wrapper primary-label"
  }, "Select a Rich Menu Item")));
};

RichMenuSection.propTypes = {
  selectedScenario: PropTypes.shape({}),
  currentQuestion: PropTypes.shape({}),
  richMenuItems: PropTypes.shape([]),
  currentChoice: PropTypes.shape({}),
  chosenRichMenuId: PropTypes.number,
  richMenuSwitch: PropTypes.bool,
  quickReplySearchTerm: PropTypes.string,
  quickReplySearchTermTouched: PropTypes.bool,
  quickReplyValue: PropTypes.string,
  setCurrentChoice: PropTypes.func,
  handleSwitchClick: PropTypes.func,
  handleSearchItemClick: PropTypes.func,
  handleQuickReplyRemove: PropTypes.func,
  handleUpdateQuickReplyValue: PropTypes.func,
  handleSearchBlur: PropTypes.func,
  handleUpdateQuickReplySearchTerm: PropTypes.func
};
export default RichMenuSection;