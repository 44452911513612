import "core-js/modules/es.array.concat";
var zealsAdmin = {
  collection: function collection(customFormId) {
    return "/zeals_admin/api/custom_forms/".concat(customFormId, "/custom_form_headless_browser_logs");
  },
  member: function member(customFormId, browserLogId) {
    return "".concat(zealsAdmin.collection(customFormId), "/").concat(browserLogId);
  },
  fetchScreenShot: function fetchScreenShot(customFormId, browserLogId) {
    return "".concat(zealsAdmin.member(customFormId, browserLogId), "/show_screen_shot");
  }
};
export default {
  zealsAdmin: zealsAdmin
};