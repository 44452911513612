export var ActionType;
(function (ActionType) {
    ActionType["UNDO_AD_USER_FILTER"] = "UNDO_AD_USER_FILTER";
    ActionType["ATTRIBUTTE_QUESTIONS_AJAX_FULFILLED"] = "ATTRIBUTTE_QUESTIONS_AJAX_FULFILLED";
    ActionType["ATTRIBUTTE_ANSWERS_AJAX_FULFILLED"] = "ATTRIBUTTE_ANSWERS_AJAX_FULFILLED";
    ActionType["AD_GET_QUICK_FILTERS"] = "AD_GET_QUICK_FILTERS";
    // SET
    ActionType["AD_SET_SEARCHED_USERS"] = "AD_SET_SEARCHED_USERS";
    ActionType["SET_ERROR_GAP"] = "SET_ERROR_GAP";
    ActionType["SET_MIN_END_USERS"] = "SET_MIN_END_USERS";
    ActionType["SET_MAX_END_USERS"] = "SET_MAX_END_USERS";
    ActionType["SET_USAGE_NUMBER"] = "SET_USAGE_NUMBER";
    ActionType["SET_LIMIT_NUMBER"] = "SET_LIMIT_NUMBER";
    // ADD
    ActionType["AD_CREATE_NEW_USER_FILTER"] = "AD_CREATE_NEW_USER_FILTER";
    ActionType["AD_ADD_QUICK_FILTER"] = "AD_ADD_QUICK_FILTER";
    ActionType["AD_ADD_TAG_TO_FILTER"] = "AD_ADD_TAG_TO_FILTER";
    ActionType["AD_ADD_QUICK_FILTER_TO_FILTER"] = "AD_ADD_QUICK_FILTER_TO_FILTER";
    ActionType["UPDATE_ATTRIBUTE_ANSWER_OF_FILTER"] = "UPDATE_ATTRIBUTE_ANSWER_OF_FILTER";
    // HANDLE AJAX RESPONSE
    ActionType["AD_HANDLE_USER_FILTER"] = "AD_HANDLE_USER_FILTER";
    ActionType["AD_HANDLE_NEW_USER_FILTER"] = "AD_HANDLE_NEW_USER_FILTER";
    ActionType["AD_SET_FILTER_TAGS"] = "AD_SET_FILTER_TAGS";
    ActionType["AD_SET_ATTRIBUTE_QUESTIONS"] = "AD_SET_ATTRIBUTE_QUESTIONS";
    ActionType["AD_UPDATE_QUICK_FILTER"] = "AD_UPDATE_QUICK_FILTER";
    ActionType["AD_UPDATE_QUICK_FILTER_SETTINGS"] = "AD_UPDATE_QUICK_FILTER_SETTINGS";
    // EDIT
    ActionType["AD_UPDATE_FILTER_PROP"] = "AD_UPDATE_FILTER_PROP";
    ActionType["SET_UPDATED_FILTER"] = "SET_UPDATED_FILTER";
    // DELETE
    ActionType["AD_HANDLE_DELETE_FILTER_ACTION"] = "AD_HANDLE_DELETE_FILTER_ACTION";
    ActionType["AD_DELETE_QUICK_FILTER_FROM_FILTER"] = "AD_DELETE_QUICK_FILTER_FROM_FILTER";
})(ActionType || (ActionType = {}));
