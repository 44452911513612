var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { PureComponent } from 'react';
import { colors } from '@src/colors';
import styled from 'styled-components';
import { hoverCss } from '@src/lib/mixins';
import Block from './Block';
import { injectIntl } from 'react-intl';
var LayerContainer = styled.li(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  .adicon_attention {\n    &::before {\n      display: none;\n    }\n\n    &::after {\n      margin-left: 4px;\n      font-size: 1rem;\n      color: ", ";\n    }\n  }\n\n  > .title {\n    font-size: 0.75rem;\n    ", ";\n  }\n\n  .icon-container {\n    position: relative;\n    display: inline-block;\n    width: 1.5rem;\n    height: 1.5rem;\n    .icon {\n      position: absolute;\n      display: inline-block;\n      font-size: 1.5rem;\n      top: -4px;\n    }\n  }\n"], ["\n  .adicon_attention {\n    &::before {\n      display: none;\n    }\n\n    &::after {\n      margin-left: 4px;\n      font-size: 1rem;\n      color: ", ";\n    }\n  }\n\n  > .title {\n    font-size: 0.75rem;\n    ", ";\n  }\n\n  .icon-container {\n    position: relative;\n    display: inline-block;\n    width: 1.5rem;\n    height: 1.5rem;\n    .icon {\n      position: absolute;\n      display: inline-block;\n      font-size: 1.5rem;\n      top: -4px;\n    }\n  }\n"])), colors.error, hoverCss);
var Layer = /** @class */ (function (_super) {
    __extends(Layer, _super);
    function Layer() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Layer.prototype.componentDidMount = function () {
        var _a = this.props, layerIdParam = _a.layerIdParam, id = _a.id, openLayer = _a.openLayer;
        if (layerIdParam !== id)
            return;
        openLayer(id);
    };
    Layer.prototype.componentDidUpdate = function (prevProps) {
        var _a = this.props, blocks = _a.blocks, id = _a.id, openLayer = _a.openLayer, closeLayer = _a.closeLayer;
        if (prevProps.blocks.length < blocks.length) {
            openLayer(id);
        }
        else if (!prevProps.blocks.length &&
            prevProps.blocks.length !== blocks.length) {
            closeLayer(id);
        }
    };
    Layer.prototype.render = function () {
        var _a = this.props, rank = _a.rank, blocks = _a.blocks, iterator = _a.iterator, chatTypeParam = _a.chatTypeParam, chatbotIdParam = _a.chatbotIdParam, adScenarioIdParam = _a.adScenarioIdParam, id = _a.id, blockIdParam = _a.blockIdParam, disconnectedBlock = _a.disconnectedBlock, showBlockItemMenu = _a.showBlockItemMenu, layers = _a.layers, isExpanded = _a.isExpanded, onClick = _a.onClick, isHover = _a.isHover, intl = _a.intl;
        return (React.createElement(LayerContainer, null,
            React.createElement("div", { className: "title " + (disconnectedBlock ? 'adicon_attention' : ''), onClick: function () { return onClick(id); } },
                React.createElement("div", { className: "icon-container" },
                    React.createElement("span", { className: "icon " + (isExpanded ? 'adicon_down' : 'adicon_right') })),
                disconnectedBlock
                    ? intl.formatMessage({ id: "JpY+rH", defaultMessage: "Unconnected" })
                    : intl.formatMessage({ id: "9fv+qV", defaultMessage: "Layer" }) + " " + (iterator + 1)),
            isExpanded && blocks.length > 0 && (React.createElement("ul", { className: "blocks-container" }, blocks.map(function (block) {
                return (React.createElement(Block, __assign({}, block, { key: block.id + "-menu-block", layerRank: rank, chatTypeParam: chatTypeParam, chatbotIdParam: chatbotIdParam, adScenarioIdParam: adScenarioIdParam, layerIdParam: id, blockIdParam: blockIdParam, showBlockItemMenu: showBlockItemMenu, layers: layers, isHover: isHover })));
            })))));
    };
    return Layer;
}(PureComponent));
export default injectIntl(Layer);
var templateObject_1;
