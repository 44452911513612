var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import isEqual from 'lodash/isEqual';
import uniq from 'lodash/uniq';
import * as richMenuApi from '@src/services/api/richMenu';
import * as defaultRichMenuApi from '@src/services/api/defaultRichMenu';
import * as defaultRichMenuSchedulesApi from '@src/services/api/defaultRichMenuSchedules';
import { handleAPIErrors, convertToDefaultRichMenus, convertToLineRichMenus, } from '@src/lib/helpers';
export default function useDefaultRichMenu(accessToken) {
    var _this = this;
    var chatbotId = useParams().chatbotId;
    var _a = __read(useState(false), 2), isLoading = _a[0], setIsLoading = _a[1];
    var _b = __read(useState(null), 2), defaultRichMenuId = _b[0], setDefaultRichMenuId = _b[1];
    var _c = __read(useState([]), 2), richMenuAliases = _c[0], setRichMenuAliases = _c[1];
    var _d = __read(useState([]), 2), lineRichMenus = _d[0], setLineRichMenus = _d[1];
    var _e = __read(useState([]), 2), defaultRichMenus = _e[0], setDefaultRichMenus = _e[1];
    var _f = __read(useState([]), 2), templateGroups = _f[0], setTemplateGroups = _f[1];
    var _g = __read(useState([]), 2), schedules = _g[0], setSchedules = _g[1];
    var fetchData = function () {
        if (!accessToken)
            return;
        setIsLoading(true);
        Promise.all([
            defaultRichMenuApi.getRichMenus(accessToken),
            defaultRichMenuApi.getRichMenuAliases(accessToken),
            defaultRichMenuApi.getDefaultRichMenuId(accessToken),
            richMenuApi.getRichMenuTemplates(),
            defaultRichMenuSchedulesApi.getSchedules(chatbotId),
            defaultRichMenuSchedulesApi.getLineRichMenuIds(chatbotId),
        ])
            .then(function (values) {
            if (!values[0] || !values[1])
                return;
            setLineRichMenus(values[0]);
            setRichMenuAliases(values[1]);
            setDefaultRichMenuId(values[2]);
            setTemplateGroups(values[3]);
            setSchedules(values[4]);
            setDefaultRichMenus(convertToDefaultRichMenus(values[0], values[1], values[2], values[3], values[4], values[5]));
            setIsLoading(false);
        })
            .catch(function (e) {
            handleAPIErrors(e.message);
            setIsLoading(false);
        });
    };
    useEffect(fetchData, [accessToken]);
    useEffect(function () {
        setDefaultRichMenus(defaultRichMenus.map(function (defaultRichMenu) { return (__assign(__assign({}, defaultRichMenu), { isActive: defaultRichMenu.richMenus.some(function (_a) {
                var line_rich_menu_id = _a.line_rich_menu_id;
                return line_rich_menu_id === defaultRichMenuId;
            }) })); }));
    }, [defaultRichMenuId]);
    var activeDefaultRichMenu = function () {
        return defaultRichMenus.find(function (_a) {
            var isActive = _a.isActive;
            return isActive;
        });
    };
    var generateRichMenuAliasId = function () {
        var richMenuAliasIds = richMenuAliases.map(function (_a) {
            var richMenuAliasId = _a.richMenuAliasId;
            return richMenuAliasId.slice(0, 9);
        });
        var richMenuAliasId;
        var invalidId = true;
        // On this step, we created random string 9 character different from any
        // item on array richMenuAliasIds to set richMenuAliasId on area.
        // If default rich menu has 3 tabs, it will be `${richMenuAliasId}-tab1`,
        // `${richMenuAliasId}-tab2`, `${richMenuAliasId}-tab3`.
        while (invalidId) {
            richMenuAliasId = Math.random().toString(20).slice(2, 11);
            invalidId = richMenuAliasIds.includes(richMenuAliasId);
        }
        return richMenuAliasId;
    };
    var uploadToLine = function (defaultRichMenu, oldSchedule) { return __awaiter(_this, void 0, void 0, function () {
        var aliasId, lineRichMenusCoverted, richmenuIds, schedule, deletedSchedule, createdSchedule, results;
        var _this = this;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    aliasId = generateRichMenuAliasId();
                    lineRichMenusCoverted = convertToLineRichMenus(defaultRichMenu, templateGroups, aliasId);
                    return [4 /*yield*/, Promise.all(lineRichMenusCoverted.map(function (lineRichMenuCoverted) {
                            return defaultRichMenuApi.createRichMenu(accessToken, lineRichMenuCoverted);
                        }))];
                case 1:
                    richmenuIds = _a.sent();
                    if (richmenuIds.includes(undefined)) {
                        throw new Error('Can not create rich menu');
                    }
                    schedule = defaultRichMenu.schedule;
                    if (!(oldSchedule === null || oldSchedule === void 0 ? void 0 : oldSchedule.line_rich_menu_id)) return [3 /*break*/, 3];
                    return [4 /*yield*/, defaultRichMenuSchedulesApi.deleteSchedule(chatbotId, oldSchedule.line_rich_menu_id)];
                case 2:
                    deletedSchedule = _a.sent();
                    if (!deletedSchedule) {
                        throw new Error('Can not update schedule');
                    }
                    _a.label = 3;
                case 3:
                    if (!richmenuIds.length) return [3 /*break*/, 5];
                    return [4 /*yield*/, defaultRichMenuSchedulesApi.createSchedule(chatbotId, accessToken, {
                            line_rich_menu_id: richmenuIds[0],
                            start_time: (schedule === null || schedule === void 0 ? void 0 : schedule.start_time) || 0,
                            end_time: (schedule === null || schedule === void 0 ? void 0 : schedule.end_time) || 0,
                        })];
                case 4:
                    createdSchedule = _a.sent();
                    if (!createdSchedule) {
                        throw new Error('Can not create schedule');
                    }
                    _a.label = 5;
                case 5: return [4 /*yield*/, Promise.all(richmenuIds.map(function (richMenuId, index) { return __awaiter(_this, void 0, void 0, function () {
                        var response, data, file;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    if (!defaultRichMenu.richMenus[index].image_url.startsWith('http')) return [3 /*break*/, 3];
                                    return [4 /*yield*/, fetch(defaultRichMenu.richMenus[index].image_url, { headers: { Authorization: "Bearer " + accessToken } })];
                                case 1:
                                    response = _a.sent();
                                    return [4 /*yield*/, response.blob()];
                                case 2:
                                    data = _a.sent();
                                    file = new File([data], 'image.jpg', {
                                        type: 'image/png;image/jpg',
                                    });
                                    return [2 /*return*/, defaultRichMenuApi.uploadRichMenuImage(accessToken, richMenuId, file)];
                                case 3: return [2 /*return*/, defaultRichMenuApi.uploadRichMenuImage(accessToken, richMenuId, defaultRichMenu.richMenus[index].image_file_content)];
                            }
                        });
                    }); }))];
                case 6:
                    results = _a.sent();
                    if (!isEqual(uniq(results), [true])) {
                        throw new Error('Can not create rich menu');
                    }
                    if (!(richmenuIds.length > 1)) return [3 /*break*/, 8];
                    return [4 /*yield*/, Promise.all(richmenuIds.map(function (richMenuId, index) {
                            return defaultRichMenuApi.createRichMenuAlias(accessToken, aliasId + "-tab" + (index + 1), richMenuId);
                        }))];
                case 7:
                    results = _a.sent();
                    if (!isEqual(uniq(results), [true])) {
                        throw new Error('Can not create rich menu');
                    }
                    _a.label = 8;
                case 8: return [2 /*return*/, richmenuIds];
            }
        });
    }); };
    return {
        isLoading: isLoading,
        richMenuAliases: richMenuAliases,
        defaultRichMenus: defaultRichMenus,
        templateGroups: templateGroups,
        activeDefaultRichMenu: activeDefaultRichMenu,
        uploadToLine: uploadToLine,
        fetchData: fetchData,
        setDefaultRichMenuId: setDefaultRichMenuId,
        schedules: schedules,
    };
}
