var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Component } from 'react';
import styled from 'styled-components';
import onClickOutside from 'react-onclickoutside';
import { colors, shadows } from '@src/colors';
import { hoverMenuBg } from '@src/lib/mixins';
var BlockItemMenuContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: ", ";\n  position: fixed;\n  ", ";\n  left: ", ";\n  z-index: 1;\n  width: 120px;\n  background: ", ";\n  box-shadow: ", ";\n  border-radius: 5px;\n  div.desc {\n    font-size: 0.5rem;\n    padding: 0.5rem;\n  }\n  span {\n    display: block;\n    padding: 0.5rem;\n    font-size: 0.875rem;\n    ", ";\n  }\n"], ["\n  display: ", ";\n  position: fixed;\n  ",
    ";\n  left: ", ";\n  z-index: 1;\n  width: 120px;\n  background: ", ";\n  box-shadow: ", ";\n  border-radius: 5px;\n  div.desc {\n    font-size: 0.5rem;\n    padding: 0.5rem;\n  }\n  span {\n    display: block;\n    padding: 0.5rem;\n    font-size: 0.875rem;\n    ", ";\n  }\n"])), function (props) { return (props.top === 0 ? '' : 'inline-block'); }, function (props) {
    return props.top === 0
        ? 'bottom: ' + props.bottom + 'px'
        : 'top: ' + props.top + 'px';
}, function (props) { return props.left + 'px'; }, colors.background.main_menu, shadows.medium.light, hoverMenuBg);
var defaultProps = {
    top: 0,
    left: 0,
    bottom: 0,
    blockId: 0,
    layerId: 0,
    hideBlockItemMenu: function () { },
    handleMoveBlockToOtherLayer: function (layerId, blockId, targetLayerId) {
        console.log(layerId, blockId, targetLayerId);
    },
    handleBlockItemMenuOutside: function () { },
};
var BlockItemMenu = /** @class */ (function (_super) {
    __extends(BlockItemMenu, _super);
    function BlockItemMenu() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.handleClickLayer = function (targetLayerId) {
            var _a = _this.props, handleMoveBlockToOtherLayer = _a.handleMoveBlockToOtherLayer, layerId = _a.layerId, blockId = _a.blockId;
            handleMoveBlockToOtherLayer(layerId, blockId, targetLayerId);
        };
        return _this;
    }
    BlockItemMenu.prototype.handleClickOutside = function () {
        var hideBlockItemMenu = this.props.hideBlockItemMenu;
        hideBlockItemMenu();
    };
    BlockItemMenu.prototype.handleBottomPosition = function (e) {
        if (e && e.getBoundingClientRect().bottom > window.innerHeight)
            this.props.handleBlockItemMenuOutside();
    };
    BlockItemMenu.prototype.render = function () {
        var _this = this;
        var _a = this.props, top = _a.top, bottom = _a.bottom, left = _a.left, blockId = _a.blockId, layers = _a.layers, intl = _a.intl;
        return (React.createElement(BlockItemMenuContainer, { top: top, left: left, bottom: bottom },
            React.createElement("div", { className: "desc" }, intl.formatMessage({ id: "oGTj9e", defaultMessage: "Transfer to" })),
            layers.map(function (layer) {
                if (layer.rank === 1 || !layer.blocks.includes(blockId)) {
                    return (React.createElement("span", { key: layer.id, onClick: function () { return _this.handleClickLayer(layer.id); } },
                        intl.formatMessage({ id: "9fv+qV", defaultMessage: "Layer" }) + ' ',
                        layer.rank));
                }
                return null;
            }),
            React.createElement("div", { ref: function (e) { return _this.handleBottomPosition(e); } })));
    };
    BlockItemMenu.defaultProps = {
        top: 0,
        left: 0,
        bottom: 0,
    };
    return BlockItemMenu;
}(Component));
export default onClickOutside(BlockItemMenu);
var templateObject_1;
