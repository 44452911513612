var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import Moment from 'moment';
import { editFilterProp } from '@src/redux/fanp/lib/updates';
import { ActionType, } from '@src/redux/fanp/types/addUserFilter';
export var defaultState = {
    filter: {
        max_open_rate: '100',
        min_open_rate: '0',
        register_start_at: Moment(),
        register_end_at: '',
        max_matching_rate: '100',
        min_matching_rate: '0',
        aggregation: '',
        chatbot_id: null,
        created_at: '',
        cv_user: '',
        force: false,
        id: null,
        mark_as_read: '',
        name: '',
        updated_at: '',
    },
    error_gap: 0,
    message_usage: 0,
    message_limit: 0,
    min_end_user_num: 0,
    max_end_user_num: 0,
    attribute_questions: [],
    attribute_answers: [],
    quick_filters: [],
    include_quick_filter_ids: [],
    exclude_quick_filter_ids: [],
    filteredUsers: 0,
    tags: [],
    attached_tags: [],
    isLoadingAttributeAnswers: false,
    isLoadingAttributeQuestions: false,
};
export default (function (state, action) {
    if (state === void 0) { state = defaultState; }
    switch (action.type) {
        case ActionType.ATTRIBUTTE_ANSWERS_AJAX_FULFILLED: {
            return Object.assign({}, state, {
                isLoadingAttributeAnswers: action.value,
            });
        }
        case ActionType.ATTRIBUTTE_QUESTIONS_AJAX_FULFILLED: {
            return Object.assign({}, state, {
                isLoadingAttributeQuestions: action.value,
            });
        }
        // Set
        case ActionType.SET_USAGE_NUMBER: {
            return Object.assign({}, state, {
                message_usage: action.usageNumber,
            });
        }
        case ActionType.SET_LIMIT_NUMBER: {
            return Object.assign({}, state, {
                message_limit: action.limitNumber,
            });
        }
        case ActionType.SET_ERROR_GAP: {
            return Object.assign({}, state, {
                error_gap: action.errorGap,
            });
        }
        case ActionType.SET_MIN_END_USERS: {
            return Object.assign({}, state, {
                min_end_user_num: action.minEndUsers,
            });
        }
        case ActionType.SET_MAX_END_USERS: {
            return Object.assign({}, state, {
                max_end_user_num: action.maxEndUsers,
            });
        }
        case ActionType.AD_SET_SEARCHED_USERS:
            return Object.assign({}, state, {
                filteredUsers: action.endUserCount,
            });
        // Add
        case ActionType.AD_ADD_QUICK_FILTER_TO_FILTER: {
            var quickFilter = action.quickFilter;
            if (quickFilter.not) {
                return Object.assign({}, state, {
                    quick_filters: __spreadArray(__spreadArray([], __read(state.quick_filters)), [quickFilter]),
                    exclude_quick_filter_ids: __spreadArray(__spreadArray([], __read(state.exclude_quick_filter_ids)), [
                        quickFilter.id,
                    ]),
                });
            }
            return Object.assign({}, state, {
                quick_filters: __spreadArray(__spreadArray([], __read(state.quick_filters)), [quickFilter]),
                include_quick_filter_ids: __spreadArray(__spreadArray([], __read(state.include_quick_filter_ids)), [
                    quickFilter.id,
                ]),
            });
        }
        // Handle
        case ActionType.AD_HANDLE_DELETE_FILTER_ACTION: {
            return Object.assign({}, state, {
                filter: {},
                quick_filters: [],
                include_quick_filter_ids: [],
                exclude_quick_filter_ids: [],
            });
        }
        case ActionType.AD_HANDLE_USER_FILTER: {
            var filter = action.filter;
            return Object.assign({}, state, { filter: filter });
        }
        case ActionType.AD_HANDLE_NEW_USER_FILTER: {
            var filter = action.filter;
            return Object.assign({}, state, {
                filter: filter,
                quick_filters: [],
                include_quick_filter_ids: [],
                exclude_quick_filter_ids: [],
            });
        }
        case ActionType.AD_SET_ATTRIBUTE_QUESTIONS: {
            var attributeQuestions = action.attributeQuestions;
            var newAttributeQuestions = attributeQuestions &&
                attributeQuestions.map(function (attributeQuestion) {
                    if (attributeQuestion.label === null) {
                        attributeQuestion.label = 'NULL';
                    }
                    return attributeQuestion;
                });
            return Object.assign({}, state, {
                attribute_questions: newAttributeQuestions,
            });
        }
        case ActionType.AD_GET_QUICK_FILTERS: {
            var quickFilters = action.quickFilters;
            var includeQuickFilterIds_1 = [];
            var excludeQuickFilterIds_1 = [];
            quickFilters &&
                quickFilters.forEach(function (quickFilter) {
                    if (quickFilter.not === false) {
                        includeQuickFilterIds_1.push(quickFilter.id);
                    }
                    if (quickFilter.not === true) {
                        excludeQuickFilterIds_1.push(quickFilter.id);
                    }
                });
            return Object.assign({}, state, {
                quick_filters: quickFilters,
                include_quick_filter_ids: includeQuickFilterIds_1,
                exclude_quick_filter_ids: excludeQuickFilterIds_1,
            });
        }
        case ActionType.SET_UPDATED_FILTER: {
            return Object.assign({}, state, { filter: action.filter });
        }
        case ActionType.AD_UPDATE_QUICK_FILTER_SETTINGS: {
            var quickFilter_1 = action.quickFilter;
            var quick_filters = state.quick_filters;
            var newQuickFilters = quick_filters.map(function (oldQuickFilter) {
                if (oldQuickFilter.id === quickFilter_1.id) {
                    oldQuickFilter.not = quickFilter_1.not;
                    return oldQuickFilter;
                }
                return oldQuickFilter;
            });
            var includeQuickFilterIds_2 = [];
            var excludeQuickFilterIds_2 = [];
            newQuickFilters &&
                newQuickFilters.forEach(function (quickFilter) {
                    if (quickFilter.not === false) {
                        includeQuickFilterIds_2.push(quickFilter.id);
                    }
                    if (quickFilter.not === true) {
                        excludeQuickFilterIds_2.push(quickFilter.id);
                    }
                });
            return Object.assign({}, state, {
                quick_filters: newQuickFilters,
                include_quick_filter_ids: includeQuickFilterIds_2,
                exclude_quick_filter_ids: excludeQuickFilterIds_2,
            });
        }
        // Edit
        case ActionType.AD_UPDATE_FILTER_PROP:
            return editFilterProp(state, action);
        // Delete
        case ActionType.AD_DELETE_QUICK_FILTER_FROM_FILTER: {
            var quickFilter_2 = action.quickFilter;
            var quick_filters = state.quick_filters, include_quick_filter_ids = state.include_quick_filter_ids, exclude_quick_filter_ids = state.exclude_quick_filter_ids;
            var newIncludeQuickFilterIds = include_quick_filter_ids;
            var newExcludeQuickFilterIds = exclude_quick_filter_ids;
            var newQuickFilters = quick_filters.filter(function (oldQuickFilter) {
                return oldQuickFilter.id !== quickFilter_2.id;
            });
            if (quickFilter_2.not) {
                newExcludeQuickFilterIds.filter(function (excludeQuickFilterId) { return excludeQuickFilterId !== quickFilter_2.id; });
            }
            else {
                newIncludeQuickFilterIds.filter(function (includeQuickFilterId) { return includeQuickFilterId !== quickFilter_2.id; });
            }
            return Object.assign({}, state, {
                quick_filters: newQuickFilters,
                include_quick_filter_ids: newIncludeQuickFilterIds,
                exclude_quick_filter_ids: newExcludeQuickFilterIds,
            });
        }
        case ActionType.AD_SET_FILTER_TAGS: {
            var attributeAnswers = action.attributeAnswers;
            return Object.assign({}, state, {
                attribute_answers: attributeAnswers,
            });
        }
        default:
            return state;
    }
});
