import "core-js/modules/es.object.keys";

/* 
  getDifferenceOfObjects({ hello: 'hi' }, {hello: 'test'}) => { hello: 'test' }
*/
export default function getDifferenceOfObjects(initial, updated) {
  return Object.keys(initial).reduce(function (changesObject, key) {
    if (initial[key] !== updated[key]) changesObject[key] = updated[key];
    return changesObject;
  }, {});
}