import "core-js/modules/es.array.filter";
import "core-js/modules/es.array.map";
import "core-js/modules/es.array.slice";
import "core-js/modules/web.dom-collections.for-each";
import { select } from 'd3';
import { TextMessage, ButtonTemplateMessage, GenericTemplateMessage, FileMessage, ImageMap, RichMenu, FlexMessage } from './messages';
import { BLOCK_MSG_MARGIN, DEFAULT_X_POS } from './messages/constants';

var calcY = function calcY(prevMsgs) {
  return prevMsgs.reduce(function (totalY, msg) {
    totalY += msg.getHeight();
    return totalY;
  }, BLOCK_MSG_MARGIN);
};

var convertMsgs = function convertMsgs(d, block) {
  return d.messages.map(function (message, msgIterator) {
    if (message.text) {
      return new TextMessage(block, message, msgIterator);
    }

    if (message.button_template) {
      return new ButtonTemplateMessage(block, message, msgIterator);
    }

    if (message.generic_template) {
      return new GenericTemplateMessage(block, message, msgIterator);
    }

    if (message.medium) {
      return new FileMessage(block, message, msgIterator);
    }

    if (message.image_map) {
      return new ImageMap(block, message, msgIterator);
    }

    if (message.rich_menu) {
      return new RichMenu(block, message, msgIterator);
    }

    if (message.line_flex_message) {
      return new FlexMessage(block, message, msgIterator);
    }

    return false;
  }).filter(function (msg) {
    return msg;
  });
};

export function openBlockMessages(d, block, defaultBlockY) {
  var messages = convertMsgs(d, block);
  messages.forEach(function (message, msgIterator) {
    var prevMsgs = messages.slice(0, msgIterator);
    var x = DEFAULT_X_POS;
    var y = calcY(prevMsgs) + defaultBlockY;
    message.render(x, y);
  });
}
export function closeBlockMessages(block) {
  select(block).selectAll('.message').remove();
  select(block).selectAll('.messageText').remove();
  select(block).selectAll('.messageType').remove();
  select(block).selectAll('.messageImage').remove();
  select(block).selectAll('.generic-item').remove();
  select(block).selectAll('.list-item').remove();
  select(block).selectAll('.flex-message-preview').remove();
}