// source: entities/form.proto

/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

/* eslint-disable */
// @ts-nocheck
var jspb = require('google-protobuf');

var goog = jspb;
var global = Function('return this')();

var entities_common_pb = require('../entities/common_pb.js');

goog.object.extend(proto, entities_common_pb);

var entities_entities_pb = require('../entities/entities_pb.js');

goog.object.extend(proto, entities_entities_pb);

var entities_message_pb = require('../entities/message_pb.js');

goog.object.extend(proto, entities_message_pb);

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');

goog.object.extend(proto, google_protobuf_timestamp_pb);

var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');

goog.object.extend(proto, google_protobuf_wrappers_pb);
goog.exportSymbol('proto.e.CompletionStatus', null, global);
goog.exportSymbol('proto.e.EndUserForm', null, global);
goog.exportSymbol('proto.e.Form', null, global);
goog.exportSymbol('proto.e.FormAutomationTrigger', null, global);
goog.exportSymbol('proto.e.FormAutomationTrigger.SourceCase', null, global);
goog.exportSymbol('proto.e.FormAutomationTriggerType', null, global);
goog.exportSymbol('proto.e.FormBuild', null, global);
goog.exportSymbol('proto.e.FormBuildSegment', null, global);
goog.exportSymbol('proto.e.FormBuildSegment.SegmentCase', null, global);
goog.exportSymbol('proto.e.FormCalendarElement', null, global);
goog.exportSymbol('proto.e.FormCalendarElement.OptionsCase', null, global);
goog.exportSymbol('proto.e.FormCalendarOption', null, global);
goog.exportSymbol('proto.e.FormCalendarQuestionOption', null, global);
goog.exportSymbol('proto.e.FormCalendarRemote', null, global);
goog.exportSymbol('proto.e.FormCalendarRemote.OptionsCase', null, global);
goog.exportSymbol('proto.e.FormCalendarRemoteCacheOption', null, global);
goog.exportSymbol('proto.e.FormCalendarRemoteScrapeOption', null, global);
goog.exportSymbol('proto.e.FormCalendarRemoteType', null, global);
goog.exportSymbol('proto.e.FormCalendarType', null, global);
goog.exportSymbol('proto.e.FormCreditCardElement', null, global);
goog.exportSymbol('proto.e.FormDatePickerElement', null, global);
goog.exportSymbol('proto.e.FormDatetimePickerElement', null, global);
goog.exportSymbol('proto.e.FormElement', null, global);
goog.exportSymbol('proto.e.FormElement.ElementCase', null, global);
goog.exportSymbol('proto.e.FormElementTemplate', null, global);
goog.exportSymbol('proto.e.FormElementTextUsage', null, global);
goog.exportSymbol('proto.e.FormElementType', null, global);
goog.exportSymbol('proto.e.FormElementValidation', null, global);
goog.exportSymbol('proto.e.FormElementValidation.ValidationCase', null, global);
goog.exportSymbol('proto.e.FormNumericElement', null, global);
goog.exportSymbol('proto.e.FormNumericValidation', null, global);
goog.exportSymbol('proto.e.FormPage', null, global);
goog.exportSymbol('proto.e.FormQuestion', null, global);
goog.exportSymbol('proto.e.FormQuestion.ValidationCase', null, global);
goog.exportSymbol('proto.e.FormRow', null, global);
goog.exportSymbol('proto.e.FormRow.ValueCase', null, global);
goog.exportSymbol('proto.e.FormScenario', null, global);
goog.exportSymbol('proto.e.FormSelectAnswer', null, global);
goog.exportSymbol('proto.e.FormSelectElement', null, global);
goog.exportSymbol('proto.e.FormSelectOption', null, global);
goog.exportSymbol('proto.e.FormSelectRepresentationType', null, global);
goog.exportSymbol('proto.e.FormStyles', null, global);
goog.exportSymbol('proto.e.FormTemplate', null, global);
goog.exportSymbol('proto.e.FormTextElement', null, global);
goog.exportSymbol('proto.e.FormTextValidation', null, global);
goog.exportSymbol('proto.e.FormTimePickerElement', null, global);
goog.exportSymbol('proto.e.FormUsage', null, global);
goog.exportSymbol('proto.e.SegmentType', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */

proto.e.FormBuild = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.e.FormBuild.repeatedFields_, null);
};

goog.inherits(proto.e.FormBuild, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.e.FormBuild.displayName = 'proto.e.FormBuild';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.e.FormBuildSegment = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.e.FormBuildSegment.oneofGroups_);
};

goog.inherits(proto.e.FormBuildSegment, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.e.FormBuildSegment.displayName = 'proto.e.FormBuildSegment';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.e.FormScenario = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.e.FormScenario.repeatedFields_, null);
};

goog.inherits(proto.e.FormScenario, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.e.FormScenario.displayName = 'proto.e.FormScenario';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.e.FormQuestion = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.e.FormQuestion.oneofGroups_);
};

goog.inherits(proto.e.FormQuestion, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.e.FormQuestion.displayName = 'proto.e.FormQuestion';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.e.FormTemplate = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.e.FormTemplate.repeatedFields_, null);
};

goog.inherits(proto.e.FormTemplate, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.e.FormTemplate.displayName = 'proto.e.FormTemplate';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.e.Form = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.e.Form.repeatedFields_, null);
};

goog.inherits(proto.e.Form, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.e.Form.displayName = 'proto.e.Form';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.e.FormStyles = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.e.FormStyles, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.e.FormStyles.displayName = 'proto.e.FormStyles';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.e.FormPage = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.e.FormPage.repeatedFields_, null);
};

goog.inherits(proto.e.FormPage, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.e.FormPage.displayName = 'proto.e.FormPage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.e.FormElementTemplate = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.e.FormElementTemplate, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.e.FormElementTemplate.displayName = 'proto.e.FormElementTemplate';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.e.FormElement = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.e.FormElement.oneofGroups_);
};

goog.inherits(proto.e.FormElement, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.e.FormElement.displayName = 'proto.e.FormElement';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.e.FormTextValidation = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.e.FormTextValidation, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.e.FormTextValidation.displayName = 'proto.e.FormTextValidation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.e.FormTextElement = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.e.FormTextElement, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.e.FormTextElement.displayName = 'proto.e.FormTextElement';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.e.FormNumericValidation = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.e.FormNumericValidation, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.e.FormNumericValidation.displayName = 'proto.e.FormNumericValidation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.e.FormNumericElement = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.e.FormNumericElement, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.e.FormNumericElement.displayName = 'proto.e.FormNumericElement';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.e.FormSelectOption = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.e.FormSelectOption, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.e.FormSelectOption.displayName = 'proto.e.FormSelectOption';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.e.FormSelectElement = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.e.FormSelectElement.repeatedFields_, null);
};

goog.inherits(proto.e.FormSelectElement, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.e.FormSelectElement.displayName = 'proto.e.FormSelectElement';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.e.FormCreditCardElement = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.e.FormCreditCardElement.repeatedFields_, null);
};

goog.inherits(proto.e.FormCreditCardElement, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.e.FormCreditCardElement.displayName = 'proto.e.FormCreditCardElement';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.e.FormDatePickerElement = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.e.FormDatePickerElement.repeatedFields_, null);
};

goog.inherits(proto.e.FormDatePickerElement, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.e.FormDatePickerElement.displayName = 'proto.e.FormDatePickerElement';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.e.FormTimePickerElement = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.e.FormTimePickerElement, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.e.FormTimePickerElement.displayName = 'proto.e.FormTimePickerElement';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.e.FormDatetimePickerElement = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.e.FormDatetimePickerElement, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.e.FormDatetimePickerElement.displayName = 'proto.e.FormDatetimePickerElement';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.e.FormCalendarElement = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.e.FormCalendarElement.oneofGroups_);
};

goog.inherits(proto.e.FormCalendarElement, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.e.FormCalendarElement.displayName = 'proto.e.FormCalendarElement';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.e.FormCalendarRemote = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.e.FormCalendarRemote.oneofGroups_);
};

goog.inherits(proto.e.FormCalendarRemote, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.e.FormCalendarRemote.displayName = 'proto.e.FormCalendarRemote';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.e.FormCalendarRemoteCacheOption = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.e.FormCalendarRemoteCacheOption, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.e.FormCalendarRemoteCacheOption.displayName = 'proto.e.FormCalendarRemoteCacheOption';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.e.FormCalendarRemoteScrapeOption = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.e.FormCalendarRemoteScrapeOption, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.e.FormCalendarRemoteScrapeOption.displayName = 'proto.e.FormCalendarRemoteScrapeOption';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.e.FormCalendarOption = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.e.FormCalendarOption, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.e.FormCalendarOption.displayName = 'proto.e.FormCalendarOption';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.e.FormCalendarQuestionOption = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.e.FormCalendarQuestionOption, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.e.FormCalendarQuestionOption.displayName = 'proto.e.FormCalendarQuestionOption';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.e.FormSelectAnswer = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.e.FormSelectAnswer.repeatedFields_, null);
};

goog.inherits(proto.e.FormSelectAnswer, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.e.FormSelectAnswer.displayName = 'proto.e.FormSelectAnswer';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.e.FormRow = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.e.FormRow.oneofGroups_);
};

goog.inherits(proto.e.FormRow, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.e.FormRow.displayName = 'proto.e.FormRow';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.e.EndUserForm = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.e.EndUserForm.repeatedFields_, null);
};

goog.inherits(proto.e.EndUserForm, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.e.EndUserForm.displayName = 'proto.e.EndUserForm';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.e.FormElementValidation = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.e.FormElementValidation.oneofGroups_);
};

goog.inherits(proto.e.FormElementValidation, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.e.FormElementValidation.displayName = 'proto.e.FormElementValidation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.e.FormAutomationTrigger = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.e.FormAutomationTrigger.oneofGroups_);
};

goog.inherits(proto.e.FormAutomationTrigger, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.e.FormAutomationTrigger.displayName = 'proto.e.FormAutomationTrigger';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */


proto.e.FormBuild.repeatedFields_ = [3];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.e.FormBuild.prototype.toObject = function (opt_includeInstance) {
    return proto.e.FormBuild.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.e.FormBuild} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.e.FormBuild.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, ""),
      fieldCollectionId: jspb.Message.getFieldWithDefault(msg, 2, ""),
      segmentsList: jspb.Message.toObjectList(msg.getSegmentsList(), proto.e.FormBuildSegment.toObject, includeInstance),
      chatbotId: jspb.Message.getFieldWithDefault(msg, 4, 0),
      name: jspb.Message.getFieldWithDefault(msg, 5, ""),
      completionStatus: jspb.Message.getFieldWithDefault(msg, 6, 0),
      authorName: jspb.Message.getFieldWithDefault(msg, 7, ""),
      authorEmail: jspb.Message.getFieldWithDefault(msg, 8, ""),
      editorName: jspb.Message.getFieldWithDefault(msg, 9, ""),
      editorEmail: jspb.Message.getFieldWithDefault(msg, 10, ""),
      formAutomationTrigger: (f = msg.getFormAutomationTrigger()) && proto.e.FormAutomationTrigger.toObject(includeInstance, f),
      record: (f = msg.getRecord()) && entities_entities_pb.Record.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.e.FormBuild}
 */


proto.e.FormBuild.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.e.FormBuild();
  return proto.e.FormBuild.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.e.FormBuild} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.e.FormBuild}
 */


proto.e.FormBuild.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setId(value);
        break;

      case 2:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setFieldCollectionId(value);
        break;

      case 3:
        var value = new proto.e.FormBuildSegment();
        reader.readMessage(value, proto.e.FormBuildSegment.deserializeBinaryFromReader);
        msg.addSegments(value);
        break;

      case 4:
        var value =
        /** @type {number} */
        reader.readInt64();
        msg.setChatbotId(value);
        break;

      case 5:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setName(value);
        break;

      case 6:
        var value =
        /** @type {!proto.e.CompletionStatus} */
        reader.readEnum();
        msg.setCompletionStatus(value);
        break;

      case 7:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setAuthorName(value);
        break;

      case 8:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setAuthorEmail(value);
        break;

      case 9:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setEditorName(value);
        break;

      case 10:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setEditorEmail(value);
        break;

      case 11:
        var value = new proto.e.FormAutomationTrigger();
        reader.readMessage(value, proto.e.FormAutomationTrigger.deserializeBinaryFromReader);
        msg.setFormAutomationTrigger(value);
        break;

      case 15:
        var value = new entities_entities_pb.Record();
        reader.readMessage(value, entities_entities_pb.Record.deserializeBinaryFromReader);
        msg.setRecord(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.e.FormBuild.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.e.FormBuild.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.e.FormBuild} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.e.FormBuild.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getId();

  if (f.length > 0) {
    writer.writeString(1, f);
  }

  f = message.getFieldCollectionId();

  if (f.length > 0) {
    writer.writeString(2, f);
  }

  f = message.getSegmentsList();

  if (f.length > 0) {
    writer.writeRepeatedMessage(3, f, proto.e.FormBuildSegment.serializeBinaryToWriter);
  }

  f = message.getChatbotId();

  if (f !== 0) {
    writer.writeInt64(4, f);
  }

  f = message.getName();

  if (f.length > 0) {
    writer.writeString(5, f);
  }

  f = message.getCompletionStatus();

  if (f !== 0.0) {
    writer.writeEnum(6, f);
  }

  f = message.getAuthorName();

  if (f.length > 0) {
    writer.writeString(7, f);
  }

  f = message.getAuthorEmail();

  if (f.length > 0) {
    writer.writeString(8, f);
  }

  f = message.getEditorName();

  if (f.length > 0) {
    writer.writeString(9, f);
  }

  f = message.getEditorEmail();

  if (f.length > 0) {
    writer.writeString(10, f);
  }

  f = message.getFormAutomationTrigger();

  if (f != null) {
    writer.writeMessage(11, f, proto.e.FormAutomationTrigger.serializeBinaryToWriter);
  }

  f = message.getRecord();

  if (f != null) {
    writer.writeMessage(15, f, entities_entities_pb.Record.serializeBinaryToWriter);
  }
};
/**
 * optional string id = 1;
 * @return {string}
 */


proto.e.FormBuild.prototype.getId = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 1, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.e.FormBuild} returns this
 */


proto.e.FormBuild.prototype.setId = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional string field_collection_id = 2;
 * @return {string}
 */


proto.e.FormBuild.prototype.getFieldCollectionId = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 2, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.e.FormBuild} returns this
 */


proto.e.FormBuild.prototype.setFieldCollectionId = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};
/**
 * repeated FormBuildSegment segments = 3;
 * @return {!Array<!proto.e.FormBuildSegment>}
 */


proto.e.FormBuild.prototype.getSegmentsList = function () {
  return (
    /** @type{!Array<!proto.e.FormBuildSegment>} */
    jspb.Message.getRepeatedWrapperField(this, proto.e.FormBuildSegment, 3)
  );
};
/**
 * @param {!Array<!proto.e.FormBuildSegment>} value
 * @return {!proto.e.FormBuild} returns this
*/


proto.e.FormBuild.prototype.setSegmentsList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};
/**
 * @param {!proto.e.FormBuildSegment=} opt_value
 * @param {number=} opt_index
 * @return {!proto.e.FormBuildSegment}
 */


proto.e.FormBuild.prototype.addSegments = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.e.FormBuildSegment, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.e.FormBuild} returns this
 */


proto.e.FormBuild.prototype.clearSegmentsList = function () {
  return this.setSegmentsList([]);
};
/**
 * optional int64 chatbot_id = 4;
 * @return {number}
 */


proto.e.FormBuild.prototype.getChatbotId = function () {
  return (
    /** @type {number} */
    jspb.Message.getFieldWithDefault(this, 4, 0)
  );
};
/**
 * @param {number} value
 * @return {!proto.e.FormBuild} returns this
 */


proto.e.FormBuild.prototype.setChatbotId = function (value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};
/**
 * optional string name = 5;
 * @return {string}
 */


proto.e.FormBuild.prototype.getName = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 5, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.e.FormBuild} returns this
 */


proto.e.FormBuild.prototype.setName = function (value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};
/**
 * optional CompletionStatus completion_status = 6;
 * @return {!proto.e.CompletionStatus}
 */


proto.e.FormBuild.prototype.getCompletionStatus = function () {
  return (
    /** @type {!proto.e.CompletionStatus} */
    jspb.Message.getFieldWithDefault(this, 6, 0)
  );
};
/**
 * @param {!proto.e.CompletionStatus} value
 * @return {!proto.e.FormBuild} returns this
 */


proto.e.FormBuild.prototype.setCompletionStatus = function (value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};
/**
 * optional string author_name = 7;
 * @return {string}
 */


proto.e.FormBuild.prototype.getAuthorName = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 7, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.e.FormBuild} returns this
 */


proto.e.FormBuild.prototype.setAuthorName = function (value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};
/**
 * optional string author_email = 8;
 * @return {string}
 */


proto.e.FormBuild.prototype.getAuthorEmail = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 8, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.e.FormBuild} returns this
 */


proto.e.FormBuild.prototype.setAuthorEmail = function (value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};
/**
 * optional string editor_name = 9;
 * @return {string}
 */


proto.e.FormBuild.prototype.getEditorName = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 9, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.e.FormBuild} returns this
 */


proto.e.FormBuild.prototype.setEditorName = function (value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};
/**
 * optional string editor_email = 10;
 * @return {string}
 */


proto.e.FormBuild.prototype.getEditorEmail = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 10, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.e.FormBuild} returns this
 */


proto.e.FormBuild.prototype.setEditorEmail = function (value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};
/**
 * optional FormAutomationTrigger form_automation_trigger = 11;
 * @return {?proto.e.FormAutomationTrigger}
 */


proto.e.FormBuild.prototype.getFormAutomationTrigger = function () {
  return (
    /** @type{?proto.e.FormAutomationTrigger} */
    jspb.Message.getWrapperField(this, proto.e.FormAutomationTrigger, 11)
  );
};
/**
 * @param {?proto.e.FormAutomationTrigger|undefined} value
 * @return {!proto.e.FormBuild} returns this
*/


proto.e.FormBuild.prototype.setFormAutomationTrigger = function (value) {
  return jspb.Message.setWrapperField(this, 11, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.e.FormBuild} returns this
 */


proto.e.FormBuild.prototype.clearFormAutomationTrigger = function () {
  return this.setFormAutomationTrigger(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.e.FormBuild.prototype.hasFormAutomationTrigger = function () {
  return jspb.Message.getField(this, 11) != null;
};
/**
 * optional Record record = 15;
 * @return {?proto.e.Record}
 */


proto.e.FormBuild.prototype.getRecord = function () {
  return (
    /** @type{?proto.e.Record} */
    jspb.Message.getWrapperField(this, entities_entities_pb.Record, 15)
  );
};
/**
 * @param {?proto.e.Record|undefined} value
 * @return {!proto.e.FormBuild} returns this
*/


proto.e.FormBuild.prototype.setRecord = function (value) {
  return jspb.Message.setWrapperField(this, 15, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.e.FormBuild} returns this
 */


proto.e.FormBuild.prototype.clearRecord = function () {
  return this.setRecord(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.e.FormBuild.prototype.hasRecord = function () {
  return jspb.Message.getField(this, 15) != null;
};
/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */


proto.e.FormBuildSegment.oneofGroups_ = [[16, 17]];
/**
 * @enum {number}
 */

proto.e.FormBuildSegment.SegmentCase = {
  SEGMENT_NOT_SET: 0,
  WEB_FORM: 16,
  FORM_SCENARIO: 17
};
/**
 * @return {proto.e.FormBuildSegment.SegmentCase}
 */

proto.e.FormBuildSegment.prototype.getSegmentCase = function () {
  return (
    /** @type {proto.e.FormBuildSegment.SegmentCase} */
    jspb.Message.computeOneofCase(this, proto.e.FormBuildSegment.oneofGroups_[0])
  );
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.e.FormBuildSegment.prototype.toObject = function (opt_includeInstance) {
    return proto.e.FormBuildSegment.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.e.FormBuildSegment} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.e.FormBuildSegment.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      type: jspb.Message.getFieldWithDefault(msg, 1, 0),
      name: jspb.Message.getFieldWithDefault(msg, 2, ""),
      webForm: (f = msg.getWebForm()) && proto.e.Form.toObject(includeInstance, f),
      formScenario: (f = msg.getFormScenario()) && proto.e.FormScenario.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.e.FormBuildSegment}
 */


proto.e.FormBuildSegment.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.e.FormBuildSegment();
  return proto.e.FormBuildSegment.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.e.FormBuildSegment} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.e.FormBuildSegment}
 */


proto.e.FormBuildSegment.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value =
        /** @type {!proto.e.SegmentType} */
        reader.readEnum();
        msg.setType(value);
        break;

      case 2:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setName(value);
        break;

      case 16:
        var value = new proto.e.Form();
        reader.readMessage(value, proto.e.Form.deserializeBinaryFromReader);
        msg.setWebForm(value);
        break;

      case 17:
        var value = new proto.e.FormScenario();
        reader.readMessage(value, proto.e.FormScenario.deserializeBinaryFromReader);
        msg.setFormScenario(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.e.FormBuildSegment.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.e.FormBuildSegment.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.e.FormBuildSegment} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.e.FormBuildSegment.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getType();

  if (f !== 0.0) {
    writer.writeEnum(1, f);
  }

  f = message.getName();

  if (f.length > 0) {
    writer.writeString(2, f);
  }

  f = message.getWebForm();

  if (f != null) {
    writer.writeMessage(16, f, proto.e.Form.serializeBinaryToWriter);
  }

  f = message.getFormScenario();

  if (f != null) {
    writer.writeMessage(17, f, proto.e.FormScenario.serializeBinaryToWriter);
  }
};
/**
 * optional SegmentType type = 1;
 * @return {!proto.e.SegmentType}
 */


proto.e.FormBuildSegment.prototype.getType = function () {
  return (
    /** @type {!proto.e.SegmentType} */
    jspb.Message.getFieldWithDefault(this, 1, 0)
  );
};
/**
 * @param {!proto.e.SegmentType} value
 * @return {!proto.e.FormBuildSegment} returns this
 */


proto.e.FormBuildSegment.prototype.setType = function (value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};
/**
 * optional string name = 2;
 * @return {string}
 */


proto.e.FormBuildSegment.prototype.getName = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 2, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.e.FormBuildSegment} returns this
 */


proto.e.FormBuildSegment.prototype.setName = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};
/**
 * optional Form web_form = 16;
 * @return {?proto.e.Form}
 */


proto.e.FormBuildSegment.prototype.getWebForm = function () {
  return (
    /** @type{?proto.e.Form} */
    jspb.Message.getWrapperField(this, proto.e.Form, 16)
  );
};
/**
 * @param {?proto.e.Form|undefined} value
 * @return {!proto.e.FormBuildSegment} returns this
*/


proto.e.FormBuildSegment.prototype.setWebForm = function (value) {
  return jspb.Message.setOneofWrapperField(this, 16, proto.e.FormBuildSegment.oneofGroups_[0], value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.e.FormBuildSegment} returns this
 */


proto.e.FormBuildSegment.prototype.clearWebForm = function () {
  return this.setWebForm(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.e.FormBuildSegment.prototype.hasWebForm = function () {
  return jspb.Message.getField(this, 16) != null;
};
/**
 * optional FormScenario form_scenario = 17;
 * @return {?proto.e.FormScenario}
 */


proto.e.FormBuildSegment.prototype.getFormScenario = function () {
  return (
    /** @type{?proto.e.FormScenario} */
    jspb.Message.getWrapperField(this, proto.e.FormScenario, 17)
  );
};
/**
 * @param {?proto.e.FormScenario|undefined} value
 * @return {!proto.e.FormBuildSegment} returns this
*/


proto.e.FormBuildSegment.prototype.setFormScenario = function (value) {
  return jspb.Message.setOneofWrapperField(this, 17, proto.e.FormBuildSegment.oneofGroups_[0], value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.e.FormBuildSegment} returns this
 */


proto.e.FormBuildSegment.prototype.clearFormScenario = function () {
  return this.setFormScenario(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.e.FormBuildSegment.prototype.hasFormScenario = function () {
  return jspb.Message.getField(this, 17) != null;
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */


proto.e.FormScenario.repeatedFields_ = [4];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.e.FormScenario.prototype.toObject = function (opt_includeInstance) {
    return proto.e.FormScenario.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.e.FormScenario} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.e.FormScenario.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, ""),
      formBuildId: jspb.Message.getFieldWithDefault(msg, 2, ""),
      scenarioId: jspb.Message.getFieldWithDefault(msg, 3, 0),
      formQuestionsList: jspb.Message.toObjectList(msg.getFormQuestionsList(), proto.e.FormQuestion.toObject, includeInstance),
      completionStatus: jspb.Message.getFieldWithDefault(msg, 5, 0),
      name: jspb.Message.getFieldWithDefault(msg, 6, ""),
      record: (f = msg.getRecord()) && entities_entities_pb.Record.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.e.FormScenario}
 */


proto.e.FormScenario.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.e.FormScenario();
  return proto.e.FormScenario.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.e.FormScenario} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.e.FormScenario}
 */


proto.e.FormScenario.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setId(value);
        break;

      case 2:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setFormBuildId(value);
        break;

      case 3:
        var value =
        /** @type {number} */
        reader.readInt64();
        msg.setScenarioId(value);
        break;

      case 4:
        var value = new proto.e.FormQuestion();
        reader.readMessage(value, proto.e.FormQuestion.deserializeBinaryFromReader);
        msg.addFormQuestions(value);
        break;

      case 5:
        var value =
        /** @type {!proto.e.CompletionStatus} */
        reader.readEnum();
        msg.setCompletionStatus(value);
        break;

      case 6:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setName(value);
        break;

      case 7:
        var value = new entities_entities_pb.Record();
        reader.readMessage(value, entities_entities_pb.Record.deserializeBinaryFromReader);
        msg.setRecord(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.e.FormScenario.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.e.FormScenario.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.e.FormScenario} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.e.FormScenario.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getId();

  if (f.length > 0) {
    writer.writeString(1, f);
  }

  f = message.getFormBuildId();

  if (f.length > 0) {
    writer.writeString(2, f);
  }

  f = message.getScenarioId();

  if (f !== 0) {
    writer.writeInt64(3, f);
  }

  f = message.getFormQuestionsList();

  if (f.length > 0) {
    writer.writeRepeatedMessage(4, f, proto.e.FormQuestion.serializeBinaryToWriter);
  }

  f = message.getCompletionStatus();

  if (f !== 0.0) {
    writer.writeEnum(5, f);
  }

  f = message.getName();

  if (f.length > 0) {
    writer.writeString(6, f);
  }

  f = message.getRecord();

  if (f != null) {
    writer.writeMessage(7, f, entities_entities_pb.Record.serializeBinaryToWriter);
  }
};
/**
 * optional string id = 1;
 * @return {string}
 */


proto.e.FormScenario.prototype.getId = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 1, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.e.FormScenario} returns this
 */


proto.e.FormScenario.prototype.setId = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional string form_build_id = 2;
 * @return {string}
 */


proto.e.FormScenario.prototype.getFormBuildId = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 2, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.e.FormScenario} returns this
 */


proto.e.FormScenario.prototype.setFormBuildId = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};
/**
 * optional int64 scenario_id = 3;
 * @return {number}
 */


proto.e.FormScenario.prototype.getScenarioId = function () {
  return (
    /** @type {number} */
    jspb.Message.getFieldWithDefault(this, 3, 0)
  );
};
/**
 * @param {number} value
 * @return {!proto.e.FormScenario} returns this
 */


proto.e.FormScenario.prototype.setScenarioId = function (value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};
/**
 * repeated FormQuestion form_questions = 4;
 * @return {!Array<!proto.e.FormQuestion>}
 */


proto.e.FormScenario.prototype.getFormQuestionsList = function () {
  return (
    /** @type{!Array<!proto.e.FormQuestion>} */
    jspb.Message.getRepeatedWrapperField(this, proto.e.FormQuestion, 4)
  );
};
/**
 * @param {!Array<!proto.e.FormQuestion>} value
 * @return {!proto.e.FormScenario} returns this
*/


proto.e.FormScenario.prototype.setFormQuestionsList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};
/**
 * @param {!proto.e.FormQuestion=} opt_value
 * @param {number=} opt_index
 * @return {!proto.e.FormQuestion}
 */


proto.e.FormScenario.prototype.addFormQuestions = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.e.FormQuestion, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.e.FormScenario} returns this
 */


proto.e.FormScenario.prototype.clearFormQuestionsList = function () {
  return this.setFormQuestionsList([]);
};
/**
 * optional CompletionStatus completion_status = 5;
 * @return {!proto.e.CompletionStatus}
 */


proto.e.FormScenario.prototype.getCompletionStatus = function () {
  return (
    /** @type {!proto.e.CompletionStatus} */
    jspb.Message.getFieldWithDefault(this, 5, 0)
  );
};
/**
 * @param {!proto.e.CompletionStatus} value
 * @return {!proto.e.FormScenario} returns this
 */


proto.e.FormScenario.prototype.setCompletionStatus = function (value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};
/**
 * optional string name = 6;
 * @return {string}
 */


proto.e.FormScenario.prototype.getName = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 6, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.e.FormScenario} returns this
 */


proto.e.FormScenario.prototype.setName = function (value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};
/**
 * optional Record record = 7;
 * @return {?proto.e.Record}
 */


proto.e.FormScenario.prototype.getRecord = function () {
  return (
    /** @type{?proto.e.Record} */
    jspb.Message.getWrapperField(this, entities_entities_pb.Record, 7)
  );
};
/**
 * @param {?proto.e.Record|undefined} value
 * @return {!proto.e.FormScenario} returns this
*/


proto.e.FormScenario.prototype.setRecord = function (value) {
  return jspb.Message.setWrapperField(this, 7, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.e.FormScenario} returns this
 */


proto.e.FormScenario.prototype.clearRecord = function () {
  return this.setRecord(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.e.FormScenario.prototype.hasRecord = function () {
  return jspb.Message.getField(this, 7) != null;
};
/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */


proto.e.FormQuestion.oneofGroups_ = [[18, 19]];
/**
 * @enum {number}
 */

proto.e.FormQuestion.ValidationCase = {
  VALIDATION_NOT_SET: 0,
  TEXT_VALIDATION: 18,
  NUMERIC_VALIDATION: 19
};
/**
 * @return {proto.e.FormQuestion.ValidationCase}
 */

proto.e.FormQuestion.prototype.getValidationCase = function () {
  return (
    /** @type {proto.e.FormQuestion.ValidationCase} */
    jspb.Message.computeOneofCase(this, proto.e.FormQuestion.oneofGroups_[0])
  );
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.e.FormQuestion.prototype.toObject = function (opt_includeInstance) {
    return proto.e.FormQuestion.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.e.FormQuestion} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.e.FormQuestion.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, ""),
      formBuildId: jspb.Message.getFieldWithDefault(msg, 2, ""),
      triggerFormAutomation: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
      scenarioQuestion: (f = msg.getScenarioQuestion()) && entities_message_pb.Question.toObject(includeInstance, f),
      errorMessage: jspb.Message.getFieldWithDefault(msg, 17, ""),
      textValidation: (f = msg.getTextValidation()) && proto.e.FormTextValidation.toObject(includeInstance, f),
      numericValidation: (f = msg.getNumericValidation()) && proto.e.FormNumericValidation.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.e.FormQuestion}
 */


proto.e.FormQuestion.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.e.FormQuestion();
  return proto.e.FormQuestion.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.e.FormQuestion} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.e.FormQuestion}
 */


proto.e.FormQuestion.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setId(value);
        break;

      case 2:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setFormBuildId(value);
        break;

      case 3:
        var value =
        /** @type {boolean} */
        reader.readBool();
        msg.setTriggerFormAutomation(value);
        break;

      case 4:
        var value = new entities_message_pb.Question();
        reader.readMessage(value, entities_message_pb.Question.deserializeBinaryFromReader);
        msg.setScenarioQuestion(value);
        break;

      case 17:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setErrorMessage(value);
        break;

      case 18:
        var value = new proto.e.FormTextValidation();
        reader.readMessage(value, proto.e.FormTextValidation.deserializeBinaryFromReader);
        msg.setTextValidation(value);
        break;

      case 19:
        var value = new proto.e.FormNumericValidation();
        reader.readMessage(value, proto.e.FormNumericValidation.deserializeBinaryFromReader);
        msg.setNumericValidation(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.e.FormQuestion.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.e.FormQuestion.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.e.FormQuestion} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.e.FormQuestion.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getId();

  if (f.length > 0) {
    writer.writeString(1, f);
  }

  f = message.getFormBuildId();

  if (f.length > 0) {
    writer.writeString(2, f);
  }

  f = message.getTriggerFormAutomation();

  if (f) {
    writer.writeBool(3, f);
  }

  f = message.getScenarioQuestion();

  if (f != null) {
    writer.writeMessage(4, f, entities_message_pb.Question.serializeBinaryToWriter);
  }

  f = message.getErrorMessage();

  if (f.length > 0) {
    writer.writeString(17, f);
  }

  f = message.getTextValidation();

  if (f != null) {
    writer.writeMessage(18, f, proto.e.FormTextValidation.serializeBinaryToWriter);
  }

  f = message.getNumericValidation();

  if (f != null) {
    writer.writeMessage(19, f, proto.e.FormNumericValidation.serializeBinaryToWriter);
  }
};
/**
 * optional string id = 1;
 * @return {string}
 */


proto.e.FormQuestion.prototype.getId = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 1, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.e.FormQuestion} returns this
 */


proto.e.FormQuestion.prototype.setId = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional string form_build_id = 2;
 * @return {string}
 */


proto.e.FormQuestion.prototype.getFormBuildId = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 2, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.e.FormQuestion} returns this
 */


proto.e.FormQuestion.prototype.setFormBuildId = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};
/**
 * optional bool trigger_form_automation = 3;
 * @return {boolean}
 */


proto.e.FormQuestion.prototype.getTriggerFormAutomation = function () {
  return (
    /** @type {boolean} */
    jspb.Message.getBooleanFieldWithDefault(this, 3, false)
  );
};
/**
 * @param {boolean} value
 * @return {!proto.e.FormQuestion} returns this
 */


proto.e.FormQuestion.prototype.setTriggerFormAutomation = function (value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};
/**
 * optional Question scenario_question = 4;
 * @return {?proto.e.Question}
 */


proto.e.FormQuestion.prototype.getScenarioQuestion = function () {
  return (
    /** @type{?proto.e.Question} */
    jspb.Message.getWrapperField(this, entities_message_pb.Question, 4)
  );
};
/**
 * @param {?proto.e.Question|undefined} value
 * @return {!proto.e.FormQuestion} returns this
*/


proto.e.FormQuestion.prototype.setScenarioQuestion = function (value) {
  return jspb.Message.setWrapperField(this, 4, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.e.FormQuestion} returns this
 */


proto.e.FormQuestion.prototype.clearScenarioQuestion = function () {
  return this.setScenarioQuestion(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.e.FormQuestion.prototype.hasScenarioQuestion = function () {
  return jspb.Message.getField(this, 4) != null;
};
/**
 * optional string error_message = 17;
 * @return {string}
 */


proto.e.FormQuestion.prototype.getErrorMessage = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 17, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.e.FormQuestion} returns this
 */


proto.e.FormQuestion.prototype.setErrorMessage = function (value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};
/**
 * optional FormTextValidation text_validation = 18;
 * @return {?proto.e.FormTextValidation}
 */


proto.e.FormQuestion.prototype.getTextValidation = function () {
  return (
    /** @type{?proto.e.FormTextValidation} */
    jspb.Message.getWrapperField(this, proto.e.FormTextValidation, 18)
  );
};
/**
 * @param {?proto.e.FormTextValidation|undefined} value
 * @return {!proto.e.FormQuestion} returns this
*/


proto.e.FormQuestion.prototype.setTextValidation = function (value) {
  return jspb.Message.setOneofWrapperField(this, 18, proto.e.FormQuestion.oneofGroups_[0], value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.e.FormQuestion} returns this
 */


proto.e.FormQuestion.prototype.clearTextValidation = function () {
  return this.setTextValidation(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.e.FormQuestion.prototype.hasTextValidation = function () {
  return jspb.Message.getField(this, 18) != null;
};
/**
 * optional FormNumericValidation numeric_validation = 19;
 * @return {?proto.e.FormNumericValidation}
 */


proto.e.FormQuestion.prototype.getNumericValidation = function () {
  return (
    /** @type{?proto.e.FormNumericValidation} */
    jspb.Message.getWrapperField(this, proto.e.FormNumericValidation, 19)
  );
};
/**
 * @param {?proto.e.FormNumericValidation|undefined} value
 * @return {!proto.e.FormQuestion} returns this
*/


proto.e.FormQuestion.prototype.setNumericValidation = function (value) {
  return jspb.Message.setOneofWrapperField(this, 19, proto.e.FormQuestion.oneofGroups_[0], value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.e.FormQuestion} returns this
 */


proto.e.FormQuestion.prototype.clearNumericValidation = function () {
  return this.setNumericValidation(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.e.FormQuestion.prototype.hasNumericValidation = function () {
  return jspb.Message.getField(this, 19) != null;
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */


proto.e.FormTemplate.repeatedFields_ = [3];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.e.FormTemplate.prototype.toObject = function (opt_includeInstance) {
    return proto.e.FormTemplate.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.e.FormTemplate} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.e.FormTemplate.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, ""),
      name: jspb.Message.getFieldWithDefault(msg, 2, ""),
      pagesList: jspb.Message.toObjectList(msg.getPagesList(), proto.e.FormPage.toObject, includeInstance),
      usage: jspb.Message.getFieldWithDefault(msg, 4, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.e.FormTemplate}
 */


proto.e.FormTemplate.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.e.FormTemplate();
  return proto.e.FormTemplate.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.e.FormTemplate} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.e.FormTemplate}
 */


proto.e.FormTemplate.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setId(value);
        break;

      case 2:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setName(value);
        break;

      case 3:
        var value = new proto.e.FormPage();
        reader.readMessage(value, proto.e.FormPage.deserializeBinaryFromReader);
        msg.addPages(value);
        break;

      case 4:
        var value =
        /** @type {!proto.e.FormUsage} */
        reader.readEnum();
        msg.setUsage(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.e.FormTemplate.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.e.FormTemplate.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.e.FormTemplate} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.e.FormTemplate.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getId();

  if (f.length > 0) {
    writer.writeString(1, f);
  }

  f = message.getName();

  if (f.length > 0) {
    writer.writeString(2, f);
  }

  f = message.getPagesList();

  if (f.length > 0) {
    writer.writeRepeatedMessage(3, f, proto.e.FormPage.serializeBinaryToWriter);
  }

  f = message.getUsage();

  if (f !== 0.0) {
    writer.writeEnum(4, f);
  }
};
/**
 * optional string id = 1;
 * @return {string}
 */


proto.e.FormTemplate.prototype.getId = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 1, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.e.FormTemplate} returns this
 */


proto.e.FormTemplate.prototype.setId = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional string name = 2;
 * @return {string}
 */


proto.e.FormTemplate.prototype.getName = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 2, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.e.FormTemplate} returns this
 */


proto.e.FormTemplate.prototype.setName = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};
/**
 * repeated FormPage pages = 3;
 * @return {!Array<!proto.e.FormPage>}
 */


proto.e.FormTemplate.prototype.getPagesList = function () {
  return (
    /** @type{!Array<!proto.e.FormPage>} */
    jspb.Message.getRepeatedWrapperField(this, proto.e.FormPage, 3)
  );
};
/**
 * @param {!Array<!proto.e.FormPage>} value
 * @return {!proto.e.FormTemplate} returns this
*/


proto.e.FormTemplate.prototype.setPagesList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};
/**
 * @param {!proto.e.FormPage=} opt_value
 * @param {number=} opt_index
 * @return {!proto.e.FormPage}
 */


proto.e.FormTemplate.prototype.addPages = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.e.FormPage, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.e.FormTemplate} returns this
 */


proto.e.FormTemplate.prototype.clearPagesList = function () {
  return this.setPagesList([]);
};
/**
 * optional FormUsage usage = 4;
 * @return {!proto.e.FormUsage}
 */


proto.e.FormTemplate.prototype.getUsage = function () {
  return (
    /** @type {!proto.e.FormUsage} */
    jspb.Message.getFieldWithDefault(this, 4, 0)
  );
};
/**
 * @param {!proto.e.FormUsage} value
 * @return {!proto.e.FormTemplate} returns this
 */


proto.e.FormTemplate.prototype.setUsage = function (value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */


proto.e.Form.repeatedFields_ = [5];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.e.Form.prototype.toObject = function (opt_includeInstance) {
    return proto.e.Form.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.e.Form} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.e.Form.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, ""),
      name: jspb.Message.getFieldWithDefault(msg, 2, ""),
      formBuildId: jspb.Message.getFieldWithDefault(msg, 3, ""),
      pagesList: jspb.Message.toObjectList(msg.getPagesList(), proto.e.FormPage.toObject, includeInstance),
      logoUri: jspb.Message.getFieldWithDefault(msg, 6, ""),
      styles: (f = msg.getStyles()) && proto.e.FormStyles.toObject(includeInstance, f),
      templateId: jspb.Message.getFieldWithDefault(msg, 8, ""),
      usage: jspb.Message.getFieldWithDefault(msg, 9, 0),
      completionStatus: jspb.Message.getFieldWithDefault(msg, 10, 0),
      triggerFormAutomation: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
      record: (f = msg.getRecord()) && entities_entities_pb.Record.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.e.Form}
 */


proto.e.Form.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.e.Form();
  return proto.e.Form.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.e.Form} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.e.Form}
 */


proto.e.Form.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setId(value);
        break;

      case 2:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setName(value);
        break;

      case 3:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setFormBuildId(value);
        break;

      case 5:
        var value = new proto.e.FormPage();
        reader.readMessage(value, proto.e.FormPage.deserializeBinaryFromReader);
        msg.addPages(value);
        break;

      case 6:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setLogoUri(value);
        break;

      case 7:
        var value = new proto.e.FormStyles();
        reader.readMessage(value, proto.e.FormStyles.deserializeBinaryFromReader);
        msg.setStyles(value);
        break;

      case 8:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setTemplateId(value);
        break;

      case 9:
        var value =
        /** @type {!proto.e.FormUsage} */
        reader.readEnum();
        msg.setUsage(value);
        break;

      case 10:
        var value =
        /** @type {!proto.e.CompletionStatus} */
        reader.readEnum();
        msg.setCompletionStatus(value);
        break;

      case 11:
        var value =
        /** @type {boolean} */
        reader.readBool();
        msg.setTriggerFormAutomation(value);
        break;

      case 15:
        var value = new entities_entities_pb.Record();
        reader.readMessage(value, entities_entities_pb.Record.deserializeBinaryFromReader);
        msg.setRecord(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.e.Form.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.e.Form.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.e.Form} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.e.Form.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getId();

  if (f.length > 0) {
    writer.writeString(1, f);
  }

  f = message.getName();

  if (f.length > 0) {
    writer.writeString(2, f);
  }

  f = message.getFormBuildId();

  if (f.length > 0) {
    writer.writeString(3, f);
  }

  f = message.getPagesList();

  if (f.length > 0) {
    writer.writeRepeatedMessage(5, f, proto.e.FormPage.serializeBinaryToWriter);
  }

  f = message.getLogoUri();

  if (f.length > 0) {
    writer.writeString(6, f);
  }

  f = message.getStyles();

  if (f != null) {
    writer.writeMessage(7, f, proto.e.FormStyles.serializeBinaryToWriter);
  }

  f = message.getTemplateId();

  if (f.length > 0) {
    writer.writeString(8, f);
  }

  f = message.getUsage();

  if (f !== 0.0) {
    writer.writeEnum(9, f);
  }

  f = message.getCompletionStatus();

  if (f !== 0.0) {
    writer.writeEnum(10, f);
  }

  f = message.getTriggerFormAutomation();

  if (f) {
    writer.writeBool(11, f);
  }

  f = message.getRecord();

  if (f != null) {
    writer.writeMessage(15, f, entities_entities_pb.Record.serializeBinaryToWriter);
  }
};
/**
 * optional string id = 1;
 * @return {string}
 */


proto.e.Form.prototype.getId = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 1, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.e.Form} returns this
 */


proto.e.Form.prototype.setId = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional string name = 2;
 * @return {string}
 */


proto.e.Form.prototype.getName = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 2, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.e.Form} returns this
 */


proto.e.Form.prototype.setName = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};
/**
 * optional string form_build_id = 3;
 * @return {string}
 */


proto.e.Form.prototype.getFormBuildId = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 3, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.e.Form} returns this
 */


proto.e.Form.prototype.setFormBuildId = function (value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};
/**
 * repeated FormPage pages = 5;
 * @return {!Array<!proto.e.FormPage>}
 */


proto.e.Form.prototype.getPagesList = function () {
  return (
    /** @type{!Array<!proto.e.FormPage>} */
    jspb.Message.getRepeatedWrapperField(this, proto.e.FormPage, 5)
  );
};
/**
 * @param {!Array<!proto.e.FormPage>} value
 * @return {!proto.e.Form} returns this
*/


proto.e.Form.prototype.setPagesList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};
/**
 * @param {!proto.e.FormPage=} opt_value
 * @param {number=} opt_index
 * @return {!proto.e.FormPage}
 */


proto.e.Form.prototype.addPages = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.e.FormPage, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.e.Form} returns this
 */


proto.e.Form.prototype.clearPagesList = function () {
  return this.setPagesList([]);
};
/**
 * optional string logo_uri = 6;
 * @return {string}
 */


proto.e.Form.prototype.getLogoUri = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 6, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.e.Form} returns this
 */


proto.e.Form.prototype.setLogoUri = function (value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};
/**
 * optional FormStyles styles = 7;
 * @return {?proto.e.FormStyles}
 */


proto.e.Form.prototype.getStyles = function () {
  return (
    /** @type{?proto.e.FormStyles} */
    jspb.Message.getWrapperField(this, proto.e.FormStyles, 7)
  );
};
/**
 * @param {?proto.e.FormStyles|undefined} value
 * @return {!proto.e.Form} returns this
*/


proto.e.Form.prototype.setStyles = function (value) {
  return jspb.Message.setWrapperField(this, 7, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.e.Form} returns this
 */


proto.e.Form.prototype.clearStyles = function () {
  return this.setStyles(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.e.Form.prototype.hasStyles = function () {
  return jspb.Message.getField(this, 7) != null;
};
/**
 * optional string template_id = 8;
 * @return {string}
 */


proto.e.Form.prototype.getTemplateId = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 8, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.e.Form} returns this
 */


proto.e.Form.prototype.setTemplateId = function (value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};
/**
 * optional FormUsage usage = 9;
 * @return {!proto.e.FormUsage}
 */


proto.e.Form.prototype.getUsage = function () {
  return (
    /** @type {!proto.e.FormUsage} */
    jspb.Message.getFieldWithDefault(this, 9, 0)
  );
};
/**
 * @param {!proto.e.FormUsage} value
 * @return {!proto.e.Form} returns this
 */


proto.e.Form.prototype.setUsage = function (value) {
  return jspb.Message.setProto3EnumField(this, 9, value);
};
/**
 * optional CompletionStatus completion_status = 10;
 * @return {!proto.e.CompletionStatus}
 */


proto.e.Form.prototype.getCompletionStatus = function () {
  return (
    /** @type {!proto.e.CompletionStatus} */
    jspb.Message.getFieldWithDefault(this, 10, 0)
  );
};
/**
 * @param {!proto.e.CompletionStatus} value
 * @return {!proto.e.Form} returns this
 */


proto.e.Form.prototype.setCompletionStatus = function (value) {
  return jspb.Message.setProto3EnumField(this, 10, value);
};
/**
 * optional bool trigger_form_automation = 11;
 * @return {boolean}
 */


proto.e.Form.prototype.getTriggerFormAutomation = function () {
  return (
    /** @type {boolean} */
    jspb.Message.getBooleanFieldWithDefault(this, 11, false)
  );
};
/**
 * @param {boolean} value
 * @return {!proto.e.Form} returns this
 */


proto.e.Form.prototype.setTriggerFormAutomation = function (value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};
/**
 * optional Record record = 15;
 * @return {?proto.e.Record}
 */


proto.e.Form.prototype.getRecord = function () {
  return (
    /** @type{?proto.e.Record} */
    jspb.Message.getWrapperField(this, entities_entities_pb.Record, 15)
  );
};
/**
 * @param {?proto.e.Record|undefined} value
 * @return {!proto.e.Form} returns this
*/


proto.e.Form.prototype.setRecord = function (value) {
  return jspb.Message.setWrapperField(this, 15, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.e.Form} returns this
 */


proto.e.Form.prototype.clearRecord = function () {
  return this.setRecord(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.e.Form.prototype.hasRecord = function () {
  return jspb.Message.getField(this, 15) != null;
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.e.FormStyles.prototype.toObject = function (opt_includeInstance) {
    return proto.e.FormStyles.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.e.FormStyles} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.e.FormStyles.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      themeColor: jspb.Message.getFieldWithDefault(msg, 1, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.e.FormStyles}
 */


proto.e.FormStyles.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.e.FormStyles();
  return proto.e.FormStyles.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.e.FormStyles} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.e.FormStyles}
 */


proto.e.FormStyles.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setThemeColor(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.e.FormStyles.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.e.FormStyles.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.e.FormStyles} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.e.FormStyles.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getThemeColor();

  if (f.length > 0) {
    writer.writeString(1, f);
  }
};
/**
 * optional string theme_color = 1;
 * @return {string}
 */


proto.e.FormStyles.prototype.getThemeColor = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 1, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.e.FormStyles} returns this
 */


proto.e.FormStyles.prototype.setThemeColor = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */


proto.e.FormPage.repeatedFields_ = [1];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.e.FormPage.prototype.toObject = function (opt_includeInstance) {
    return proto.e.FormPage.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.e.FormPage} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.e.FormPage.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      elementsList: jspb.Message.toObjectList(msg.getElementsList(), proto.e.FormElement.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.e.FormPage}
 */


proto.e.FormPage.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.e.FormPage();
  return proto.e.FormPage.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.e.FormPage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.e.FormPage}
 */


proto.e.FormPage.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value = new proto.e.FormElement();
        reader.readMessage(value, proto.e.FormElement.deserializeBinaryFromReader);
        msg.addElements(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.e.FormPage.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.e.FormPage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.e.FormPage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.e.FormPage.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getElementsList();

  if (f.length > 0) {
    writer.writeRepeatedMessage(1, f, proto.e.FormElement.serializeBinaryToWriter);
  }
};
/**
 * repeated FormElement elements = 1;
 * @return {!Array<!proto.e.FormElement>}
 */


proto.e.FormPage.prototype.getElementsList = function () {
  return (
    /** @type{!Array<!proto.e.FormElement>} */
    jspb.Message.getRepeatedWrapperField(this, proto.e.FormElement, 1)
  );
};
/**
 * @param {!Array<!proto.e.FormElement>} value
 * @return {!proto.e.FormPage} returns this
*/


proto.e.FormPage.prototype.setElementsList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};
/**
 * @param {!proto.e.FormElement=} opt_value
 * @param {number=} opt_index
 * @return {!proto.e.FormElement}
 */


proto.e.FormPage.prototype.addElements = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.e.FormElement, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.e.FormPage} returns this
 */


proto.e.FormPage.prototype.clearElementsList = function () {
  return this.setElementsList([]);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.e.FormElementTemplate.prototype.toObject = function (opt_includeInstance) {
    return proto.e.FormElementTemplate.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.e.FormElementTemplate} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.e.FormElementTemplate.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, ""),
      name: jspb.Message.getFieldWithDefault(msg, 2, ""),
      description: jspb.Message.getFieldWithDefault(msg, 3, ""),
      formElement: (f = msg.getFormElement()) && proto.e.FormElement.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.e.FormElementTemplate}
 */


proto.e.FormElementTemplate.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.e.FormElementTemplate();
  return proto.e.FormElementTemplate.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.e.FormElementTemplate} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.e.FormElementTemplate}
 */


proto.e.FormElementTemplate.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setId(value);
        break;

      case 2:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setName(value);
        break;

      case 3:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setDescription(value);
        break;

      case 4:
        var value = new proto.e.FormElement();
        reader.readMessage(value, proto.e.FormElement.deserializeBinaryFromReader);
        msg.setFormElement(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.e.FormElementTemplate.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.e.FormElementTemplate.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.e.FormElementTemplate} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.e.FormElementTemplate.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getId();

  if (f.length > 0) {
    writer.writeString(1, f);
  }

  f = message.getName();

  if (f.length > 0) {
    writer.writeString(2, f);
  }

  f = message.getDescription();

  if (f.length > 0) {
    writer.writeString(3, f);
  }

  f = message.getFormElement();

  if (f != null) {
    writer.writeMessage(4, f, proto.e.FormElement.serializeBinaryToWriter);
  }
};
/**
 * optional string id = 1;
 * @return {string}
 */


proto.e.FormElementTemplate.prototype.getId = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 1, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.e.FormElementTemplate} returns this
 */


proto.e.FormElementTemplate.prototype.setId = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional string name = 2;
 * @return {string}
 */


proto.e.FormElementTemplate.prototype.getName = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 2, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.e.FormElementTemplate} returns this
 */


proto.e.FormElementTemplate.prototype.setName = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};
/**
 * optional string description = 3;
 * @return {string}
 */


proto.e.FormElementTemplate.prototype.getDescription = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 3, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.e.FormElementTemplate} returns this
 */


proto.e.FormElementTemplate.prototype.setDescription = function (value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};
/**
 * optional FormElement form_element = 4;
 * @return {?proto.e.FormElement}
 */


proto.e.FormElementTemplate.prototype.getFormElement = function () {
  return (
    /** @type{?proto.e.FormElement} */
    jspb.Message.getWrapperField(this, proto.e.FormElement, 4)
  );
};
/**
 * @param {?proto.e.FormElement|undefined} value
 * @return {!proto.e.FormElementTemplate} returns this
*/


proto.e.FormElementTemplate.prototype.setFormElement = function (value) {
  return jspb.Message.setWrapperField(this, 4, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.e.FormElementTemplate} returns this
 */


proto.e.FormElementTemplate.prototype.clearFormElement = function () {
  return this.setFormElement(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.e.FormElementTemplate.prototype.hasFormElement = function () {
  return jspb.Message.getField(this, 4) != null;
};
/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */


proto.e.FormElement.oneofGroups_ = [[16, 17, 18, 19, 20, 21, 22, 23]];
/**
 * @enum {number}
 */

proto.e.FormElement.ElementCase = {
  ELEMENT_NOT_SET: 0,
  TEXT: 16,
  NUMERIC: 17,
  SELECT: 18,
  CREDIT_CARD: 19,
  DATE_PICKER: 20,
  TIME_PICKER: 21,
  DATETIME_PICKER: 22,
  CALENDAR: 23
};
/**
 * @return {proto.e.FormElement.ElementCase}
 */

proto.e.FormElement.prototype.getElementCase = function () {
  return (
    /** @type {proto.e.FormElement.ElementCase} */
    jspb.Message.computeOneofCase(this, proto.e.FormElement.oneofGroups_[0])
  );
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.e.FormElement.prototype.toObject = function (opt_includeInstance) {
    return proto.e.FormElement.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.e.FormElement} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.e.FormElement.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, ""),
      name: jspb.Message.getFieldWithDefault(msg, 2, ""),
      description: jspb.Message.getFieldWithDefault(msg, 3, ""),
      type: jspb.Message.getFieldWithDefault(msg, 4, 0),
      fieldId: jspb.Message.getFieldWithDefault(msg, 5, ""),
      required: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
      templateId: jspb.Message.getFieldWithDefault(msg, 7, ""),
      text: (f = msg.getText()) && proto.e.FormTextElement.toObject(includeInstance, f),
      numeric: (f = msg.getNumeric()) && proto.e.FormNumericElement.toObject(includeInstance, f),
      select: (f = msg.getSelect()) && proto.e.FormSelectElement.toObject(includeInstance, f),
      creditCard: (f = msg.getCreditCard()) && proto.e.FormCreditCardElement.toObject(includeInstance, f),
      datePicker: (f = msg.getDatePicker()) && proto.e.FormDatePickerElement.toObject(includeInstance, f),
      timePicker: (f = msg.getTimePicker()) && proto.e.FormTimePickerElement.toObject(includeInstance, f),
      datetimePicker: (f = msg.getDatetimePicker()) && proto.e.FormDatetimePickerElement.toObject(includeInstance, f),
      calendar: (f = msg.getCalendar()) && proto.e.FormCalendarElement.toObject(includeInstance, f),
      errorMessage: jspb.Message.getFieldWithDefault(msg, 24, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.e.FormElement}
 */


proto.e.FormElement.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.e.FormElement();
  return proto.e.FormElement.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.e.FormElement} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.e.FormElement}
 */


proto.e.FormElement.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setId(value);
        break;

      case 2:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setName(value);
        break;

      case 3:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setDescription(value);
        break;

      case 4:
        var value =
        /** @type {!proto.e.FormElementType} */
        reader.readEnum();
        msg.setType(value);
        break;

      case 5:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setFieldId(value);
        break;

      case 6:
        var value =
        /** @type {boolean} */
        reader.readBool();
        msg.setRequired(value);
        break;

      case 7:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setTemplateId(value);
        break;

      case 16:
        var value = new proto.e.FormTextElement();
        reader.readMessage(value, proto.e.FormTextElement.deserializeBinaryFromReader);
        msg.setText(value);
        break;

      case 17:
        var value = new proto.e.FormNumericElement();
        reader.readMessage(value, proto.e.FormNumericElement.deserializeBinaryFromReader);
        msg.setNumeric(value);
        break;

      case 18:
        var value = new proto.e.FormSelectElement();
        reader.readMessage(value, proto.e.FormSelectElement.deserializeBinaryFromReader);
        msg.setSelect(value);
        break;

      case 19:
        var value = new proto.e.FormCreditCardElement();
        reader.readMessage(value, proto.e.FormCreditCardElement.deserializeBinaryFromReader);
        msg.setCreditCard(value);
        break;

      case 20:
        var value = new proto.e.FormDatePickerElement();
        reader.readMessage(value, proto.e.FormDatePickerElement.deserializeBinaryFromReader);
        msg.setDatePicker(value);
        break;

      case 21:
        var value = new proto.e.FormTimePickerElement();
        reader.readMessage(value, proto.e.FormTimePickerElement.deserializeBinaryFromReader);
        msg.setTimePicker(value);
        break;

      case 22:
        var value = new proto.e.FormDatetimePickerElement();
        reader.readMessage(value, proto.e.FormDatetimePickerElement.deserializeBinaryFromReader);
        msg.setDatetimePicker(value);
        break;

      case 23:
        var value = new proto.e.FormCalendarElement();
        reader.readMessage(value, proto.e.FormCalendarElement.deserializeBinaryFromReader);
        msg.setCalendar(value);
        break;

      case 24:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setErrorMessage(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.e.FormElement.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.e.FormElement.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.e.FormElement} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.e.FormElement.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getId();

  if (f.length > 0) {
    writer.writeString(1, f);
  }

  f = message.getName();

  if (f.length > 0) {
    writer.writeString(2, f);
  }

  f = message.getDescription();

  if (f.length > 0) {
    writer.writeString(3, f);
  }

  f = message.getType();

  if (f !== 0.0) {
    writer.writeEnum(4, f);
  }

  f = message.getFieldId();

  if (f.length > 0) {
    writer.writeString(5, f);
  }

  f = message.getRequired();

  if (f) {
    writer.writeBool(6, f);
  }

  f = message.getTemplateId();

  if (f.length > 0) {
    writer.writeString(7, f);
  }

  f = message.getText();

  if (f != null) {
    writer.writeMessage(16, f, proto.e.FormTextElement.serializeBinaryToWriter);
  }

  f = message.getNumeric();

  if (f != null) {
    writer.writeMessage(17, f, proto.e.FormNumericElement.serializeBinaryToWriter);
  }

  f = message.getSelect();

  if (f != null) {
    writer.writeMessage(18, f, proto.e.FormSelectElement.serializeBinaryToWriter);
  }

  f = message.getCreditCard();

  if (f != null) {
    writer.writeMessage(19, f, proto.e.FormCreditCardElement.serializeBinaryToWriter);
  }

  f = message.getDatePicker();

  if (f != null) {
    writer.writeMessage(20, f, proto.e.FormDatePickerElement.serializeBinaryToWriter);
  }

  f = message.getTimePicker();

  if (f != null) {
    writer.writeMessage(21, f, proto.e.FormTimePickerElement.serializeBinaryToWriter);
  }

  f = message.getDatetimePicker();

  if (f != null) {
    writer.writeMessage(22, f, proto.e.FormDatetimePickerElement.serializeBinaryToWriter);
  }

  f = message.getCalendar();

  if (f != null) {
    writer.writeMessage(23, f, proto.e.FormCalendarElement.serializeBinaryToWriter);
  }

  f = message.getErrorMessage();

  if (f.length > 0) {
    writer.writeString(24, f);
  }
};
/**
 * optional string id = 1;
 * @return {string}
 */


proto.e.FormElement.prototype.getId = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 1, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.e.FormElement} returns this
 */


proto.e.FormElement.prototype.setId = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional string name = 2;
 * @return {string}
 */


proto.e.FormElement.prototype.getName = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 2, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.e.FormElement} returns this
 */


proto.e.FormElement.prototype.setName = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};
/**
 * optional string description = 3;
 * @return {string}
 */


proto.e.FormElement.prototype.getDescription = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 3, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.e.FormElement} returns this
 */


proto.e.FormElement.prototype.setDescription = function (value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};
/**
 * optional FormElementType type = 4;
 * @return {!proto.e.FormElementType}
 */


proto.e.FormElement.prototype.getType = function () {
  return (
    /** @type {!proto.e.FormElementType} */
    jspb.Message.getFieldWithDefault(this, 4, 0)
  );
};
/**
 * @param {!proto.e.FormElementType} value
 * @return {!proto.e.FormElement} returns this
 */


proto.e.FormElement.prototype.setType = function (value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};
/**
 * optional string field_id = 5;
 * @return {string}
 */


proto.e.FormElement.prototype.getFieldId = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 5, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.e.FormElement} returns this
 */


proto.e.FormElement.prototype.setFieldId = function (value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};
/**
 * optional bool required = 6;
 * @return {boolean}
 */


proto.e.FormElement.prototype.getRequired = function () {
  return (
    /** @type {boolean} */
    jspb.Message.getBooleanFieldWithDefault(this, 6, false)
  );
};
/**
 * @param {boolean} value
 * @return {!proto.e.FormElement} returns this
 */


proto.e.FormElement.prototype.setRequired = function (value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};
/**
 * optional string template_id = 7;
 * @return {string}
 */


proto.e.FormElement.prototype.getTemplateId = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 7, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.e.FormElement} returns this
 */


proto.e.FormElement.prototype.setTemplateId = function (value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};
/**
 * optional FormTextElement text = 16;
 * @return {?proto.e.FormTextElement}
 */


proto.e.FormElement.prototype.getText = function () {
  return (
    /** @type{?proto.e.FormTextElement} */
    jspb.Message.getWrapperField(this, proto.e.FormTextElement, 16)
  );
};
/**
 * @param {?proto.e.FormTextElement|undefined} value
 * @return {!proto.e.FormElement} returns this
*/


proto.e.FormElement.prototype.setText = function (value) {
  return jspb.Message.setOneofWrapperField(this, 16, proto.e.FormElement.oneofGroups_[0], value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.e.FormElement} returns this
 */


proto.e.FormElement.prototype.clearText = function () {
  return this.setText(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.e.FormElement.prototype.hasText = function () {
  return jspb.Message.getField(this, 16) != null;
};
/**
 * optional FormNumericElement numeric = 17;
 * @return {?proto.e.FormNumericElement}
 */


proto.e.FormElement.prototype.getNumeric = function () {
  return (
    /** @type{?proto.e.FormNumericElement} */
    jspb.Message.getWrapperField(this, proto.e.FormNumericElement, 17)
  );
};
/**
 * @param {?proto.e.FormNumericElement|undefined} value
 * @return {!proto.e.FormElement} returns this
*/


proto.e.FormElement.prototype.setNumeric = function (value) {
  return jspb.Message.setOneofWrapperField(this, 17, proto.e.FormElement.oneofGroups_[0], value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.e.FormElement} returns this
 */


proto.e.FormElement.prototype.clearNumeric = function () {
  return this.setNumeric(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.e.FormElement.prototype.hasNumeric = function () {
  return jspb.Message.getField(this, 17) != null;
};
/**
 * optional FormSelectElement select = 18;
 * @return {?proto.e.FormSelectElement}
 */


proto.e.FormElement.prototype.getSelect = function () {
  return (
    /** @type{?proto.e.FormSelectElement} */
    jspb.Message.getWrapperField(this, proto.e.FormSelectElement, 18)
  );
};
/**
 * @param {?proto.e.FormSelectElement|undefined} value
 * @return {!proto.e.FormElement} returns this
*/


proto.e.FormElement.prototype.setSelect = function (value) {
  return jspb.Message.setOneofWrapperField(this, 18, proto.e.FormElement.oneofGroups_[0], value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.e.FormElement} returns this
 */


proto.e.FormElement.prototype.clearSelect = function () {
  return this.setSelect(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.e.FormElement.prototype.hasSelect = function () {
  return jspb.Message.getField(this, 18) != null;
};
/**
 * optional FormCreditCardElement credit_card = 19;
 * @return {?proto.e.FormCreditCardElement}
 */


proto.e.FormElement.prototype.getCreditCard = function () {
  return (
    /** @type{?proto.e.FormCreditCardElement} */
    jspb.Message.getWrapperField(this, proto.e.FormCreditCardElement, 19)
  );
};
/**
 * @param {?proto.e.FormCreditCardElement|undefined} value
 * @return {!proto.e.FormElement} returns this
*/


proto.e.FormElement.prototype.setCreditCard = function (value) {
  return jspb.Message.setOneofWrapperField(this, 19, proto.e.FormElement.oneofGroups_[0], value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.e.FormElement} returns this
 */


proto.e.FormElement.prototype.clearCreditCard = function () {
  return this.setCreditCard(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.e.FormElement.prototype.hasCreditCard = function () {
  return jspb.Message.getField(this, 19) != null;
};
/**
 * optional FormDatePickerElement date_picker = 20;
 * @return {?proto.e.FormDatePickerElement}
 */


proto.e.FormElement.prototype.getDatePicker = function () {
  return (
    /** @type{?proto.e.FormDatePickerElement} */
    jspb.Message.getWrapperField(this, proto.e.FormDatePickerElement, 20)
  );
};
/**
 * @param {?proto.e.FormDatePickerElement|undefined} value
 * @return {!proto.e.FormElement} returns this
*/


proto.e.FormElement.prototype.setDatePicker = function (value) {
  return jspb.Message.setOneofWrapperField(this, 20, proto.e.FormElement.oneofGroups_[0], value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.e.FormElement} returns this
 */


proto.e.FormElement.prototype.clearDatePicker = function () {
  return this.setDatePicker(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.e.FormElement.prototype.hasDatePicker = function () {
  return jspb.Message.getField(this, 20) != null;
};
/**
 * optional FormTimePickerElement time_picker = 21;
 * @return {?proto.e.FormTimePickerElement}
 */


proto.e.FormElement.prototype.getTimePicker = function () {
  return (
    /** @type{?proto.e.FormTimePickerElement} */
    jspb.Message.getWrapperField(this, proto.e.FormTimePickerElement, 21)
  );
};
/**
 * @param {?proto.e.FormTimePickerElement|undefined} value
 * @return {!proto.e.FormElement} returns this
*/


proto.e.FormElement.prototype.setTimePicker = function (value) {
  return jspb.Message.setOneofWrapperField(this, 21, proto.e.FormElement.oneofGroups_[0], value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.e.FormElement} returns this
 */


proto.e.FormElement.prototype.clearTimePicker = function () {
  return this.setTimePicker(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.e.FormElement.prototype.hasTimePicker = function () {
  return jspb.Message.getField(this, 21) != null;
};
/**
 * optional FormDatetimePickerElement datetime_picker = 22;
 * @return {?proto.e.FormDatetimePickerElement}
 */


proto.e.FormElement.prototype.getDatetimePicker = function () {
  return (
    /** @type{?proto.e.FormDatetimePickerElement} */
    jspb.Message.getWrapperField(this, proto.e.FormDatetimePickerElement, 22)
  );
};
/**
 * @param {?proto.e.FormDatetimePickerElement|undefined} value
 * @return {!proto.e.FormElement} returns this
*/


proto.e.FormElement.prototype.setDatetimePicker = function (value) {
  return jspb.Message.setOneofWrapperField(this, 22, proto.e.FormElement.oneofGroups_[0], value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.e.FormElement} returns this
 */


proto.e.FormElement.prototype.clearDatetimePicker = function () {
  return this.setDatetimePicker(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.e.FormElement.prototype.hasDatetimePicker = function () {
  return jspb.Message.getField(this, 22) != null;
};
/**
 * optional FormCalendarElement calendar = 23;
 * @return {?proto.e.FormCalendarElement}
 */


proto.e.FormElement.prototype.getCalendar = function () {
  return (
    /** @type{?proto.e.FormCalendarElement} */
    jspb.Message.getWrapperField(this, proto.e.FormCalendarElement, 23)
  );
};
/**
 * @param {?proto.e.FormCalendarElement|undefined} value
 * @return {!proto.e.FormElement} returns this
*/


proto.e.FormElement.prototype.setCalendar = function (value) {
  return jspb.Message.setOneofWrapperField(this, 23, proto.e.FormElement.oneofGroups_[0], value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.e.FormElement} returns this
 */


proto.e.FormElement.prototype.clearCalendar = function () {
  return this.setCalendar(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.e.FormElement.prototype.hasCalendar = function () {
  return jspb.Message.getField(this, 23) != null;
};
/**
 * optional string error_message = 24;
 * @return {string}
 */


proto.e.FormElement.prototype.getErrorMessage = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 24, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.e.FormElement} returns this
 */


proto.e.FormElement.prototype.setErrorMessage = function (value) {
  return jspb.Message.setProto3StringField(this, 24, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.e.FormTextValidation.prototype.toObject = function (opt_includeInstance) {
    return proto.e.FormTextValidation.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.e.FormTextValidation} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.e.FormTextValidation.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      regexp: jspb.Message.getFieldWithDefault(msg, 1, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.e.FormTextValidation}
 */


proto.e.FormTextValidation.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.e.FormTextValidation();
  return proto.e.FormTextValidation.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.e.FormTextValidation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.e.FormTextValidation}
 */


proto.e.FormTextValidation.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setRegexp(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.e.FormTextValidation.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.e.FormTextValidation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.e.FormTextValidation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.e.FormTextValidation.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getRegexp();

  if (f.length > 0) {
    writer.writeString(1, f);
  }
};
/**
 * optional string regexp = 1;
 * @return {string}
 */


proto.e.FormTextValidation.prototype.getRegexp = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 1, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.e.FormTextValidation} returns this
 */


proto.e.FormTextValidation.prototype.setRegexp = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.e.FormTextElement.prototype.toObject = function (opt_includeInstance) {
    return proto.e.FormTextElement.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.e.FormTextElement} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.e.FormTextElement.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      placeholder: jspb.Message.getFieldWithDefault(msg, 1, ""),
      usage: jspb.Message.getFieldWithDefault(msg, 2, 0),
      validation: (f = msg.getValidation()) && proto.e.FormTextValidation.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.e.FormTextElement}
 */


proto.e.FormTextElement.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.e.FormTextElement();
  return proto.e.FormTextElement.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.e.FormTextElement} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.e.FormTextElement}
 */


proto.e.FormTextElement.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setPlaceholder(value);
        break;

      case 2:
        var value =
        /** @type {!proto.e.FormElementTextUsage} */
        reader.readEnum();
        msg.setUsage(value);
        break;

      case 3:
        var value = new proto.e.FormTextValidation();
        reader.readMessage(value, proto.e.FormTextValidation.deserializeBinaryFromReader);
        msg.setValidation(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.e.FormTextElement.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.e.FormTextElement.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.e.FormTextElement} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.e.FormTextElement.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getPlaceholder();

  if (f.length > 0) {
    writer.writeString(1, f);
  }

  f = message.getUsage();

  if (f !== 0.0) {
    writer.writeEnum(2, f);
  }

  f = message.getValidation();

  if (f != null) {
    writer.writeMessage(3, f, proto.e.FormTextValidation.serializeBinaryToWriter);
  }
};
/**
 * optional string placeholder = 1;
 * @return {string}
 */


proto.e.FormTextElement.prototype.getPlaceholder = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 1, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.e.FormTextElement} returns this
 */


proto.e.FormTextElement.prototype.setPlaceholder = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional FormElementTextUsage usage = 2;
 * @return {!proto.e.FormElementTextUsage}
 */


proto.e.FormTextElement.prototype.getUsage = function () {
  return (
    /** @type {!proto.e.FormElementTextUsage} */
    jspb.Message.getFieldWithDefault(this, 2, 0)
  );
};
/**
 * @param {!proto.e.FormElementTextUsage} value
 * @return {!proto.e.FormTextElement} returns this
 */


proto.e.FormTextElement.prototype.setUsage = function (value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};
/**
 * optional FormTextValidation validation = 3;
 * @return {?proto.e.FormTextValidation}
 */


proto.e.FormTextElement.prototype.getValidation = function () {
  return (
    /** @type{?proto.e.FormTextValidation} */
    jspb.Message.getWrapperField(this, proto.e.FormTextValidation, 3)
  );
};
/**
 * @param {?proto.e.FormTextValidation|undefined} value
 * @return {!proto.e.FormTextElement} returns this
*/


proto.e.FormTextElement.prototype.setValidation = function (value) {
  return jspb.Message.setWrapperField(this, 3, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.e.FormTextElement} returns this
 */


proto.e.FormTextElement.prototype.clearValidation = function () {
  return this.setValidation(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.e.FormTextElement.prototype.hasValidation = function () {
  return jspb.Message.getField(this, 3) != null;
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.e.FormNumericValidation.prototype.toObject = function (opt_includeInstance) {
    return proto.e.FormNumericValidation.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.e.FormNumericValidation} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.e.FormNumericValidation.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      min: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
      max: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.e.FormNumericValidation}
 */


proto.e.FormNumericValidation.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.e.FormNumericValidation();
  return proto.e.FormNumericValidation.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.e.FormNumericValidation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.e.FormNumericValidation}
 */


proto.e.FormNumericValidation.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value =
        /** @type {number} */
        reader.readDouble();
        msg.setMin(value);
        break;

      case 2:
        var value =
        /** @type {number} */
        reader.readDouble();
        msg.setMax(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.e.FormNumericValidation.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.e.FormNumericValidation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.e.FormNumericValidation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.e.FormNumericValidation.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getMin();

  if (f !== 0.0) {
    writer.writeDouble(1, f);
  }

  f = message.getMax();

  if (f !== 0.0) {
    writer.writeDouble(2, f);
  }
};
/**
 * optional double min = 1;
 * @return {number}
 */


proto.e.FormNumericValidation.prototype.getMin = function () {
  return (
    /** @type {number} */
    jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0)
  );
};
/**
 * @param {number} value
 * @return {!proto.e.FormNumericValidation} returns this
 */


proto.e.FormNumericValidation.prototype.setMin = function (value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};
/**
 * optional double max = 2;
 * @return {number}
 */


proto.e.FormNumericValidation.prototype.getMax = function () {
  return (
    /** @type {number} */
    jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0)
  );
};
/**
 * @param {number} value
 * @return {!proto.e.FormNumericValidation} returns this
 */


proto.e.FormNumericValidation.prototype.setMax = function (value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.e.FormNumericElement.prototype.toObject = function (opt_includeInstance) {
    return proto.e.FormNumericElement.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.e.FormNumericElement} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.e.FormNumericElement.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      step: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
      pb_default: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
      validation: (f = msg.getValidation()) && proto.e.FormNumericValidation.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.e.FormNumericElement}
 */


proto.e.FormNumericElement.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.e.FormNumericElement();
  return proto.e.FormNumericElement.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.e.FormNumericElement} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.e.FormNumericElement}
 */


proto.e.FormNumericElement.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value =
        /** @type {number} */
        reader.readDouble();
        msg.setStep(value);
        break;

      case 2:
        var value =
        /** @type {number} */
        reader.readDouble();
        msg.setDefault(value);
        break;

      case 3:
        var value = new proto.e.FormNumericValidation();
        reader.readMessage(value, proto.e.FormNumericValidation.deserializeBinaryFromReader);
        msg.setValidation(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.e.FormNumericElement.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.e.FormNumericElement.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.e.FormNumericElement} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.e.FormNumericElement.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getStep();

  if (f !== 0.0) {
    writer.writeDouble(1, f);
  }

  f = message.getDefault();

  if (f !== 0.0) {
    writer.writeDouble(2, f);
  }

  f = message.getValidation();

  if (f != null) {
    writer.writeMessage(3, f, proto.e.FormNumericValidation.serializeBinaryToWriter);
  }
};
/**
 * optional double step = 1;
 * @return {number}
 */


proto.e.FormNumericElement.prototype.getStep = function () {
  return (
    /** @type {number} */
    jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0)
  );
};
/**
 * @param {number} value
 * @return {!proto.e.FormNumericElement} returns this
 */


proto.e.FormNumericElement.prototype.setStep = function (value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};
/**
 * optional double default = 2;
 * @return {number}
 */


proto.e.FormNumericElement.prototype.getDefault = function () {
  return (
    /** @type {number} */
    jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0)
  );
};
/**
 * @param {number} value
 * @return {!proto.e.FormNumericElement} returns this
 */


proto.e.FormNumericElement.prototype.setDefault = function (value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};
/**
 * optional FormNumericValidation validation = 3;
 * @return {?proto.e.FormNumericValidation}
 */


proto.e.FormNumericElement.prototype.getValidation = function () {
  return (
    /** @type{?proto.e.FormNumericValidation} */
    jspb.Message.getWrapperField(this, proto.e.FormNumericValidation, 3)
  );
};
/**
 * @param {?proto.e.FormNumericValidation|undefined} value
 * @return {!proto.e.FormNumericElement} returns this
*/


proto.e.FormNumericElement.prototype.setValidation = function (value) {
  return jspb.Message.setWrapperField(this, 3, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.e.FormNumericElement} returns this
 */


proto.e.FormNumericElement.prototype.clearValidation = function () {
  return this.setValidation(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.e.FormNumericElement.prototype.hasValidation = function () {
  return jspb.Message.getField(this, 3) != null;
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.e.FormSelectOption.prototype.toObject = function (opt_includeInstance) {
    return proto.e.FormSelectOption.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.e.FormSelectOption} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.e.FormSelectOption.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      label: jspb.Message.getFieldWithDefault(msg, 1, ""),
      value: jspb.Message.getFieldWithDefault(msg, 2, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.e.FormSelectOption}
 */


proto.e.FormSelectOption.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.e.FormSelectOption();
  return proto.e.FormSelectOption.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.e.FormSelectOption} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.e.FormSelectOption}
 */


proto.e.FormSelectOption.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setLabel(value);
        break;

      case 2:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setValue(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.e.FormSelectOption.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.e.FormSelectOption.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.e.FormSelectOption} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.e.FormSelectOption.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getLabel();

  if (f.length > 0) {
    writer.writeString(1, f);
  }

  f = message.getValue();

  if (f.length > 0) {
    writer.writeString(2, f);
  }
};
/**
 * optional string label = 1;
 * @return {string}
 */


proto.e.FormSelectOption.prototype.getLabel = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 1, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.e.FormSelectOption} returns this
 */


proto.e.FormSelectOption.prototype.setLabel = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional string value = 2;
 * @return {string}
 */


proto.e.FormSelectOption.prototype.getValue = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 2, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.e.FormSelectOption} returns this
 */


proto.e.FormSelectOption.prototype.setValue = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */


proto.e.FormSelectElement.repeatedFields_ = [2];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.e.FormSelectElement.prototype.toObject = function (opt_includeInstance) {
    return proto.e.FormSelectElement.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.e.FormSelectElement} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.e.FormSelectElement.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      representation: jspb.Message.getFieldWithDefault(msg, 1, 0),
      optionsList: jspb.Message.toObjectList(msg.getOptionsList(), proto.e.FormSelectOption.toObject, includeInstance),
      editable: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.e.FormSelectElement}
 */


proto.e.FormSelectElement.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.e.FormSelectElement();
  return proto.e.FormSelectElement.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.e.FormSelectElement} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.e.FormSelectElement}
 */


proto.e.FormSelectElement.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value =
        /** @type {!proto.e.FormSelectRepresentationType} */
        reader.readEnum();
        msg.setRepresentation(value);
        break;

      case 2:
        var value = new proto.e.FormSelectOption();
        reader.readMessage(value, proto.e.FormSelectOption.deserializeBinaryFromReader);
        msg.addOptions(value);
        break;

      case 3:
        var value =
        /** @type {boolean} */
        reader.readBool();
        msg.setEditable(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.e.FormSelectElement.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.e.FormSelectElement.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.e.FormSelectElement} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.e.FormSelectElement.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getRepresentation();

  if (f !== 0.0) {
    writer.writeEnum(1, f);
  }

  f = message.getOptionsList();

  if (f.length > 0) {
    writer.writeRepeatedMessage(2, f, proto.e.FormSelectOption.serializeBinaryToWriter);
  }

  f = message.getEditable();

  if (f) {
    writer.writeBool(3, f);
  }
};
/**
 * optional FormSelectRepresentationType representation = 1;
 * @return {!proto.e.FormSelectRepresentationType}
 */


proto.e.FormSelectElement.prototype.getRepresentation = function () {
  return (
    /** @type {!proto.e.FormSelectRepresentationType} */
    jspb.Message.getFieldWithDefault(this, 1, 0)
  );
};
/**
 * @param {!proto.e.FormSelectRepresentationType} value
 * @return {!proto.e.FormSelectElement} returns this
 */


proto.e.FormSelectElement.prototype.setRepresentation = function (value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};
/**
 * repeated FormSelectOption options = 2;
 * @return {!Array<!proto.e.FormSelectOption>}
 */


proto.e.FormSelectElement.prototype.getOptionsList = function () {
  return (
    /** @type{!Array<!proto.e.FormSelectOption>} */
    jspb.Message.getRepeatedWrapperField(this, proto.e.FormSelectOption, 2)
  );
};
/**
 * @param {!Array<!proto.e.FormSelectOption>} value
 * @return {!proto.e.FormSelectElement} returns this
*/


proto.e.FormSelectElement.prototype.setOptionsList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};
/**
 * @param {!proto.e.FormSelectOption=} opt_value
 * @param {number=} opt_index
 * @return {!proto.e.FormSelectOption}
 */


proto.e.FormSelectElement.prototype.addOptions = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.e.FormSelectOption, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.e.FormSelectElement} returns this
 */


proto.e.FormSelectElement.prototype.clearOptionsList = function () {
  return this.setOptionsList([]);
};
/**
 * optional bool editable = 3;
 * @return {boolean}
 */


proto.e.FormSelectElement.prototype.getEditable = function () {
  return (
    /** @type {boolean} */
    jspb.Message.getBooleanFieldWithDefault(this, 3, false)
  );
};
/**
 * @param {boolean} value
 * @return {!proto.e.FormSelectElement} returns this
 */


proto.e.FormSelectElement.prototype.setEditable = function (value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */


proto.e.FormCreditCardElement.repeatedFields_ = [1];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.e.FormCreditCardElement.prototype.toObject = function (opt_includeInstance) {
    return proto.e.FormCreditCardElement.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.e.FormCreditCardElement} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.e.FormCreditCardElement.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      providersList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.e.FormCreditCardElement}
 */


proto.e.FormCreditCardElement.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.e.FormCreditCardElement();
  return proto.e.FormCreditCardElement.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.e.FormCreditCardElement} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.e.FormCreditCardElement}
 */


proto.e.FormCreditCardElement.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value =
        /** @type {string} */
        reader.readString();
        msg.addProviders(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.e.FormCreditCardElement.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.e.FormCreditCardElement.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.e.FormCreditCardElement} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.e.FormCreditCardElement.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getProvidersList();

  if (f.length > 0) {
    writer.writeRepeatedString(1, f);
  }
};
/**
 * repeated string providers = 1;
 * @return {!Array<string>}
 */


proto.e.FormCreditCardElement.prototype.getProvidersList = function () {
  return (
    /** @type {!Array<string>} */
    jspb.Message.getRepeatedField(this, 1)
  );
};
/**
 * @param {!Array<string>} value
 * @return {!proto.e.FormCreditCardElement} returns this
 */


proto.e.FormCreditCardElement.prototype.setProvidersList = function (value) {
  return jspb.Message.setField(this, 1, value || []);
};
/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.e.FormCreditCardElement} returns this
 */


proto.e.FormCreditCardElement.prototype.addProviders = function (value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.e.FormCreditCardElement} returns this
 */


proto.e.FormCreditCardElement.prototype.clearProvidersList = function () {
  return this.setProvidersList([]);
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */


proto.e.FormDatePickerElement.repeatedFields_ = [3];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.e.FormDatePickerElement.prototype.toObject = function (opt_includeInstance) {
    return proto.e.FormDatePickerElement.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.e.FormDatePickerElement} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.e.FormDatePickerElement.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      disableBefore: (f = msg.getDisableBefore()) && entities_common_pb.Date.toObject(includeInstance, f),
      disableAfter: (f = msg.getDisableAfter()) && entities_common_pb.Date.toObject(includeInstance, f),
      openingDaysList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.e.FormDatePickerElement}
 */


proto.e.FormDatePickerElement.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.e.FormDatePickerElement();
  return proto.e.FormDatePickerElement.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.e.FormDatePickerElement} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.e.FormDatePickerElement}
 */


proto.e.FormDatePickerElement.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value = new entities_common_pb.Date();
        reader.readMessage(value, entities_common_pb.Date.deserializeBinaryFromReader);
        msg.setDisableBefore(value);
        break;

      case 2:
        var value = new entities_common_pb.Date();
        reader.readMessage(value, entities_common_pb.Date.deserializeBinaryFromReader);
        msg.setDisableAfter(value);
        break;

      case 3:
        var values =
        /** @type {!Array<!proto.e.DayOfWeek>} */
        reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()];

        for (var i = 0; i < values.length; i++) {
          msg.addOpeningDays(values[i]);
        }

        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.e.FormDatePickerElement.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.e.FormDatePickerElement.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.e.FormDatePickerElement} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.e.FormDatePickerElement.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getDisableBefore();

  if (f != null) {
    writer.writeMessage(1, f, entities_common_pb.Date.serializeBinaryToWriter);
  }

  f = message.getDisableAfter();

  if (f != null) {
    writer.writeMessage(2, f, entities_common_pb.Date.serializeBinaryToWriter);
  }

  f = message.getOpeningDaysList();

  if (f.length > 0) {
    writer.writePackedEnum(3, f);
  }
};
/**
 * optional Date disable_before = 1;
 * @return {?proto.e.Date}
 */


proto.e.FormDatePickerElement.prototype.getDisableBefore = function () {
  return (
    /** @type{?proto.e.Date} */
    jspb.Message.getWrapperField(this, entities_common_pb.Date, 1)
  );
};
/**
 * @param {?proto.e.Date|undefined} value
 * @return {!proto.e.FormDatePickerElement} returns this
*/


proto.e.FormDatePickerElement.prototype.setDisableBefore = function (value) {
  return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.e.FormDatePickerElement} returns this
 */


proto.e.FormDatePickerElement.prototype.clearDisableBefore = function () {
  return this.setDisableBefore(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.e.FormDatePickerElement.prototype.hasDisableBefore = function () {
  return jspb.Message.getField(this, 1) != null;
};
/**
 * optional Date disable_after = 2;
 * @return {?proto.e.Date}
 */


proto.e.FormDatePickerElement.prototype.getDisableAfter = function () {
  return (
    /** @type{?proto.e.Date} */
    jspb.Message.getWrapperField(this, entities_common_pb.Date, 2)
  );
};
/**
 * @param {?proto.e.Date|undefined} value
 * @return {!proto.e.FormDatePickerElement} returns this
*/


proto.e.FormDatePickerElement.prototype.setDisableAfter = function (value) {
  return jspb.Message.setWrapperField(this, 2, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.e.FormDatePickerElement} returns this
 */


proto.e.FormDatePickerElement.prototype.clearDisableAfter = function () {
  return this.setDisableAfter(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.e.FormDatePickerElement.prototype.hasDisableAfter = function () {
  return jspb.Message.getField(this, 2) != null;
};
/**
 * repeated DayOfWeek opening_days = 3;
 * @return {!Array<!proto.e.DayOfWeek>}
 */


proto.e.FormDatePickerElement.prototype.getOpeningDaysList = function () {
  return (
    /** @type {!Array<!proto.e.DayOfWeek>} */
    jspb.Message.getRepeatedField(this, 3)
  );
};
/**
 * @param {!Array<!proto.e.DayOfWeek>} value
 * @return {!proto.e.FormDatePickerElement} returns this
 */


proto.e.FormDatePickerElement.prototype.setOpeningDaysList = function (value) {
  return jspb.Message.setField(this, 3, value || []);
};
/**
 * @param {!proto.e.DayOfWeek} value
 * @param {number=} opt_index
 * @return {!proto.e.FormDatePickerElement} returns this
 */


proto.e.FormDatePickerElement.prototype.addOpeningDays = function (value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.e.FormDatePickerElement} returns this
 */


proto.e.FormDatePickerElement.prototype.clearOpeningDaysList = function () {
  return this.setOpeningDaysList([]);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.e.FormTimePickerElement.prototype.toObject = function (opt_includeInstance) {
    return proto.e.FormTimePickerElement.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.e.FormTimePickerElement} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.e.FormTimePickerElement.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      disableBefore: (f = msg.getDisableBefore()) && entities_common_pb.Time.toObject(includeInstance, f),
      disableAfter: (f = msg.getDisableAfter()) && entities_common_pb.Time.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.e.FormTimePickerElement}
 */


proto.e.FormTimePickerElement.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.e.FormTimePickerElement();
  return proto.e.FormTimePickerElement.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.e.FormTimePickerElement} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.e.FormTimePickerElement}
 */


proto.e.FormTimePickerElement.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value = new entities_common_pb.Time();
        reader.readMessage(value, entities_common_pb.Time.deserializeBinaryFromReader);
        msg.setDisableBefore(value);
        break;

      case 2:
        var value = new entities_common_pb.Time();
        reader.readMessage(value, entities_common_pb.Time.deserializeBinaryFromReader);
        msg.setDisableAfter(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.e.FormTimePickerElement.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.e.FormTimePickerElement.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.e.FormTimePickerElement} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.e.FormTimePickerElement.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getDisableBefore();

  if (f != null) {
    writer.writeMessage(1, f, entities_common_pb.Time.serializeBinaryToWriter);
  }

  f = message.getDisableAfter();

  if (f != null) {
    writer.writeMessage(2, f, entities_common_pb.Time.serializeBinaryToWriter);
  }
};
/**
 * optional Time disable_before = 1;
 * @return {?proto.e.Time}
 */


proto.e.FormTimePickerElement.prototype.getDisableBefore = function () {
  return (
    /** @type{?proto.e.Time} */
    jspb.Message.getWrapperField(this, entities_common_pb.Time, 1)
  );
};
/**
 * @param {?proto.e.Time|undefined} value
 * @return {!proto.e.FormTimePickerElement} returns this
*/


proto.e.FormTimePickerElement.prototype.setDisableBefore = function (value) {
  return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.e.FormTimePickerElement} returns this
 */


proto.e.FormTimePickerElement.prototype.clearDisableBefore = function () {
  return this.setDisableBefore(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.e.FormTimePickerElement.prototype.hasDisableBefore = function () {
  return jspb.Message.getField(this, 1) != null;
};
/**
 * optional Time disable_after = 2;
 * @return {?proto.e.Time}
 */


proto.e.FormTimePickerElement.prototype.getDisableAfter = function () {
  return (
    /** @type{?proto.e.Time} */
    jspb.Message.getWrapperField(this, entities_common_pb.Time, 2)
  );
};
/**
 * @param {?proto.e.Time|undefined} value
 * @return {!proto.e.FormTimePickerElement} returns this
*/


proto.e.FormTimePickerElement.prototype.setDisableAfter = function (value) {
  return jspb.Message.setWrapperField(this, 2, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.e.FormTimePickerElement} returns this
 */


proto.e.FormTimePickerElement.prototype.clearDisableAfter = function () {
  return this.setDisableAfter(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.e.FormTimePickerElement.prototype.hasDisableAfter = function () {
  return jspb.Message.getField(this, 2) != null;
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.e.FormDatetimePickerElement.prototype.toObject = function (opt_includeInstance) {
    return proto.e.FormDatetimePickerElement.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.e.FormDatetimePickerElement} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.e.FormDatetimePickerElement.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      disableBefore: (f = msg.getDisableBefore()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
      disableAfter: (f = msg.getDisableAfter()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.e.FormDatetimePickerElement}
 */


proto.e.FormDatetimePickerElement.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.e.FormDatetimePickerElement();
  return proto.e.FormDatetimePickerElement.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.e.FormDatetimePickerElement} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.e.FormDatetimePickerElement}
 */


proto.e.FormDatetimePickerElement.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value = new google_protobuf_timestamp_pb.Timestamp();
        reader.readMessage(value, google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
        msg.setDisableBefore(value);
        break;

      case 2:
        var value = new google_protobuf_timestamp_pb.Timestamp();
        reader.readMessage(value, google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
        msg.setDisableAfter(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.e.FormDatetimePickerElement.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.e.FormDatetimePickerElement.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.e.FormDatetimePickerElement} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.e.FormDatetimePickerElement.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getDisableBefore();

  if (f != null) {
    writer.writeMessage(1, f, google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter);
  }

  f = message.getDisableAfter();

  if (f != null) {
    writer.writeMessage(2, f, google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter);
  }
};
/**
 * optional google.protobuf.Timestamp disable_before = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */


proto.e.FormDatetimePickerElement.prototype.getDisableBefore = function () {
  return (
    /** @type{?proto.google.protobuf.Timestamp} */
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1)
  );
};
/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.e.FormDatetimePickerElement} returns this
*/


proto.e.FormDatetimePickerElement.prototype.setDisableBefore = function (value) {
  return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.e.FormDatetimePickerElement} returns this
 */


proto.e.FormDatetimePickerElement.prototype.clearDisableBefore = function () {
  return this.setDisableBefore(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.e.FormDatetimePickerElement.prototype.hasDisableBefore = function () {
  return jspb.Message.getField(this, 1) != null;
};
/**
 * optional google.protobuf.Timestamp disable_after = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */


proto.e.FormDatetimePickerElement.prototype.getDisableAfter = function () {
  return (
    /** @type{?proto.google.protobuf.Timestamp} */
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2)
  );
};
/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.e.FormDatetimePickerElement} returns this
*/


proto.e.FormDatetimePickerElement.prototype.setDisableAfter = function (value) {
  return jspb.Message.setWrapperField(this, 2, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.e.FormDatetimePickerElement} returns this
 */


proto.e.FormDatetimePickerElement.prototype.clearDisableAfter = function () {
  return this.setDisableAfter(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.e.FormDatetimePickerElement.prototype.hasDisableAfter = function () {
  return jspb.Message.getField(this, 2) != null;
};
/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */


proto.e.FormCalendarElement.oneofGroups_ = [[20, 21]];
/**
 * @enum {number}
 */

proto.e.FormCalendarElement.OptionsCase = {
  OPTIONS_NOT_SET: 0,
  CALENDAR: 20,
  QUESTION: 21
};
/**
 * @return {proto.e.FormCalendarElement.OptionsCase}
 */

proto.e.FormCalendarElement.prototype.getOptionsCase = function () {
  return (
    /** @type {proto.e.FormCalendarElement.OptionsCase} */
    jspb.Message.computeOneofCase(this, proto.e.FormCalendarElement.oneofGroups_[0])
  );
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.e.FormCalendarElement.prototype.toObject = function (opt_includeInstance) {
    return proto.e.FormCalendarElement.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.e.FormCalendarElement} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.e.FormCalendarElement.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      type: jspb.Message.getFieldWithDefault(msg, 1, 0),
      remote: (f = msg.getRemote()) && proto.e.FormCalendarRemote.toObject(includeInstance, f),
      parentElementId: (f = msg.getParentElementId()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
      calendar: (f = msg.getCalendar()) && proto.e.FormCalendarOption.toObject(includeInstance, f),
      question: (f = msg.getQuestion()) && proto.e.FormCalendarQuestionOption.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.e.FormCalendarElement}
 */


proto.e.FormCalendarElement.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.e.FormCalendarElement();
  return proto.e.FormCalendarElement.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.e.FormCalendarElement} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.e.FormCalendarElement}
 */


proto.e.FormCalendarElement.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value =
        /** @type {!proto.e.FormCalendarType} */
        reader.readEnum();
        msg.setType(value);
        break;

      case 2:
        var value = new proto.e.FormCalendarRemote();
        reader.readMessage(value, proto.e.FormCalendarRemote.deserializeBinaryFromReader);
        msg.setRemote(value);
        break;

      case 3:
        var value = new google_protobuf_wrappers_pb.StringValue();
        reader.readMessage(value, google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
        msg.setParentElementId(value);
        break;

      case 20:
        var value = new proto.e.FormCalendarOption();
        reader.readMessage(value, proto.e.FormCalendarOption.deserializeBinaryFromReader);
        msg.setCalendar(value);
        break;

      case 21:
        var value = new proto.e.FormCalendarQuestionOption();
        reader.readMessage(value, proto.e.FormCalendarQuestionOption.deserializeBinaryFromReader);
        msg.setQuestion(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.e.FormCalendarElement.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.e.FormCalendarElement.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.e.FormCalendarElement} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.e.FormCalendarElement.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getType();

  if (f !== 0.0) {
    writer.writeEnum(1, f);
  }

  f = message.getRemote();

  if (f != null) {
    writer.writeMessage(2, f, proto.e.FormCalendarRemote.serializeBinaryToWriter);
  }

  f = message.getParentElementId();

  if (f != null) {
    writer.writeMessage(3, f, google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter);
  }

  f = message.getCalendar();

  if (f != null) {
    writer.writeMessage(20, f, proto.e.FormCalendarOption.serializeBinaryToWriter);
  }

  f = message.getQuestion();

  if (f != null) {
    writer.writeMessage(21, f, proto.e.FormCalendarQuestionOption.serializeBinaryToWriter);
  }
};
/**
 * optional FormCalendarType type = 1;
 * @return {!proto.e.FormCalendarType}
 */


proto.e.FormCalendarElement.prototype.getType = function () {
  return (
    /** @type {!proto.e.FormCalendarType} */
    jspb.Message.getFieldWithDefault(this, 1, 0)
  );
};
/**
 * @param {!proto.e.FormCalendarType} value
 * @return {!proto.e.FormCalendarElement} returns this
 */


proto.e.FormCalendarElement.prototype.setType = function (value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};
/**
 * optional FormCalendarRemote remote = 2;
 * @return {?proto.e.FormCalendarRemote}
 */


proto.e.FormCalendarElement.prototype.getRemote = function () {
  return (
    /** @type{?proto.e.FormCalendarRemote} */
    jspb.Message.getWrapperField(this, proto.e.FormCalendarRemote, 2)
  );
};
/**
 * @param {?proto.e.FormCalendarRemote|undefined} value
 * @return {!proto.e.FormCalendarElement} returns this
*/


proto.e.FormCalendarElement.prototype.setRemote = function (value) {
  return jspb.Message.setWrapperField(this, 2, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.e.FormCalendarElement} returns this
 */


proto.e.FormCalendarElement.prototype.clearRemote = function () {
  return this.setRemote(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.e.FormCalendarElement.prototype.hasRemote = function () {
  return jspb.Message.getField(this, 2) != null;
};
/**
 * optional google.protobuf.StringValue parent_element_id = 3;
 * @return {?proto.google.protobuf.StringValue}
 */


proto.e.FormCalendarElement.prototype.getParentElementId = function () {
  return (
    /** @type{?proto.google.protobuf.StringValue} */
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3)
  );
};
/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.e.FormCalendarElement} returns this
*/


proto.e.FormCalendarElement.prototype.setParentElementId = function (value) {
  return jspb.Message.setWrapperField(this, 3, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.e.FormCalendarElement} returns this
 */


proto.e.FormCalendarElement.prototype.clearParentElementId = function () {
  return this.setParentElementId(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.e.FormCalendarElement.prototype.hasParentElementId = function () {
  return jspb.Message.getField(this, 3) != null;
};
/**
 * optional FormCalendarOption calendar = 20;
 * @return {?proto.e.FormCalendarOption}
 */


proto.e.FormCalendarElement.prototype.getCalendar = function () {
  return (
    /** @type{?proto.e.FormCalendarOption} */
    jspb.Message.getWrapperField(this, proto.e.FormCalendarOption, 20)
  );
};
/**
 * @param {?proto.e.FormCalendarOption|undefined} value
 * @return {!proto.e.FormCalendarElement} returns this
*/


proto.e.FormCalendarElement.prototype.setCalendar = function (value) {
  return jspb.Message.setOneofWrapperField(this, 20, proto.e.FormCalendarElement.oneofGroups_[0], value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.e.FormCalendarElement} returns this
 */


proto.e.FormCalendarElement.prototype.clearCalendar = function () {
  return this.setCalendar(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.e.FormCalendarElement.prototype.hasCalendar = function () {
  return jspb.Message.getField(this, 20) != null;
};
/**
 * optional FormCalendarQuestionOption question = 21;
 * @return {?proto.e.FormCalendarQuestionOption}
 */


proto.e.FormCalendarElement.prototype.getQuestion = function () {
  return (
    /** @type{?proto.e.FormCalendarQuestionOption} */
    jspb.Message.getWrapperField(this, proto.e.FormCalendarQuestionOption, 21)
  );
};
/**
 * @param {?proto.e.FormCalendarQuestionOption|undefined} value
 * @return {!proto.e.FormCalendarElement} returns this
*/


proto.e.FormCalendarElement.prototype.setQuestion = function (value) {
  return jspb.Message.setOneofWrapperField(this, 21, proto.e.FormCalendarElement.oneofGroups_[0], value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.e.FormCalendarElement} returns this
 */


proto.e.FormCalendarElement.prototype.clearQuestion = function () {
  return this.setQuestion(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.e.FormCalendarElement.prototype.hasQuestion = function () {
  return jspb.Message.getField(this, 21) != null;
};
/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */


proto.e.FormCalendarRemote.oneofGroups_ = [[10, 11]];
/**
 * @enum {number}
 */

proto.e.FormCalendarRemote.OptionsCase = {
  OPTIONS_NOT_SET: 0,
  CACHE: 10,
  SCRAPE: 11
};
/**
 * @return {proto.e.FormCalendarRemote.OptionsCase}
 */

proto.e.FormCalendarRemote.prototype.getOptionsCase = function () {
  return (
    /** @type {proto.e.FormCalendarRemote.OptionsCase} */
    jspb.Message.computeOneofCase(this, proto.e.FormCalendarRemote.oneofGroups_[0])
  );
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.e.FormCalendarRemote.prototype.toObject = function (opt_includeInstance) {
    return proto.e.FormCalendarRemote.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.e.FormCalendarRemote} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.e.FormCalendarRemote.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      type: jspb.Message.getFieldWithDefault(msg, 2, 0),
      cache: (f = msg.getCache()) && proto.e.FormCalendarRemoteCacheOption.toObject(includeInstance, f),
      scrape: (f = msg.getScrape()) && proto.e.FormCalendarRemoteScrapeOption.toObject(includeInstance, f),
      dateFormat: jspb.Message.getFieldWithDefault(msg, 16, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.e.FormCalendarRemote}
 */


proto.e.FormCalendarRemote.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.e.FormCalendarRemote();
  return proto.e.FormCalendarRemote.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.e.FormCalendarRemote} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.e.FormCalendarRemote}
 */


proto.e.FormCalendarRemote.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 2:
        var value =
        /** @type {!proto.e.FormCalendarRemoteType} */
        reader.readEnum();
        msg.setType(value);
        break;

      case 10:
        var value = new proto.e.FormCalendarRemoteCacheOption();
        reader.readMessage(value, proto.e.FormCalendarRemoteCacheOption.deserializeBinaryFromReader);
        msg.setCache(value);
        break;

      case 11:
        var value = new proto.e.FormCalendarRemoteScrapeOption();
        reader.readMessage(value, proto.e.FormCalendarRemoteScrapeOption.deserializeBinaryFromReader);
        msg.setScrape(value);
        break;

      case 16:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setDateFormat(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.e.FormCalendarRemote.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.e.FormCalendarRemote.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.e.FormCalendarRemote} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.e.FormCalendarRemote.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getType();

  if (f !== 0.0) {
    writer.writeEnum(2, f);
  }

  f = message.getCache();

  if (f != null) {
    writer.writeMessage(10, f, proto.e.FormCalendarRemoteCacheOption.serializeBinaryToWriter);
  }

  f = message.getScrape();

  if (f != null) {
    writer.writeMessage(11, f, proto.e.FormCalendarRemoteScrapeOption.serializeBinaryToWriter);
  }

  f = message.getDateFormat();

  if (f.length > 0) {
    writer.writeString(16, f);
  }
};
/**
 * optional FormCalendarRemoteType type = 2;
 * @return {!proto.e.FormCalendarRemoteType}
 */


proto.e.FormCalendarRemote.prototype.getType = function () {
  return (
    /** @type {!proto.e.FormCalendarRemoteType} */
    jspb.Message.getFieldWithDefault(this, 2, 0)
  );
};
/**
 * @param {!proto.e.FormCalendarRemoteType} value
 * @return {!proto.e.FormCalendarRemote} returns this
 */


proto.e.FormCalendarRemote.prototype.setType = function (value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};
/**
 * optional FormCalendarRemoteCacheOption cache = 10;
 * @return {?proto.e.FormCalendarRemoteCacheOption}
 */


proto.e.FormCalendarRemote.prototype.getCache = function () {
  return (
    /** @type{?proto.e.FormCalendarRemoteCacheOption} */
    jspb.Message.getWrapperField(this, proto.e.FormCalendarRemoteCacheOption, 10)
  );
};
/**
 * @param {?proto.e.FormCalendarRemoteCacheOption|undefined} value
 * @return {!proto.e.FormCalendarRemote} returns this
*/


proto.e.FormCalendarRemote.prototype.setCache = function (value) {
  return jspb.Message.setOneofWrapperField(this, 10, proto.e.FormCalendarRemote.oneofGroups_[0], value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.e.FormCalendarRemote} returns this
 */


proto.e.FormCalendarRemote.prototype.clearCache = function () {
  return this.setCache(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.e.FormCalendarRemote.prototype.hasCache = function () {
  return jspb.Message.getField(this, 10) != null;
};
/**
 * optional FormCalendarRemoteScrapeOption scrape = 11;
 * @return {?proto.e.FormCalendarRemoteScrapeOption}
 */


proto.e.FormCalendarRemote.prototype.getScrape = function () {
  return (
    /** @type{?proto.e.FormCalendarRemoteScrapeOption} */
    jspb.Message.getWrapperField(this, proto.e.FormCalendarRemoteScrapeOption, 11)
  );
};
/**
 * @param {?proto.e.FormCalendarRemoteScrapeOption|undefined} value
 * @return {!proto.e.FormCalendarRemote} returns this
*/


proto.e.FormCalendarRemote.prototype.setScrape = function (value) {
  return jspb.Message.setOneofWrapperField(this, 11, proto.e.FormCalendarRemote.oneofGroups_[0], value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.e.FormCalendarRemote} returns this
 */


proto.e.FormCalendarRemote.prototype.clearScrape = function () {
  return this.setScrape(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.e.FormCalendarRemote.prototype.hasScrape = function () {
  return jspb.Message.getField(this, 11) != null;
};
/**
 * optional string date_format = 16;
 * @return {string}
 */


proto.e.FormCalendarRemote.prototype.getDateFormat = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 16, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.e.FormCalendarRemote} returns this
 */


proto.e.FormCalendarRemote.prototype.setDateFormat = function (value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.e.FormCalendarRemoteCacheOption.prototype.toObject = function (opt_includeInstance) {
    return proto.e.FormCalendarRemoteCacheOption.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.e.FormCalendarRemoteCacheOption} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.e.FormCalendarRemoteCacheOption.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      chatbotId: jspb.Message.getFieldWithDefault(msg, 1, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.e.FormCalendarRemoteCacheOption}
 */


proto.e.FormCalendarRemoteCacheOption.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.e.FormCalendarRemoteCacheOption();
  return proto.e.FormCalendarRemoteCacheOption.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.e.FormCalendarRemoteCacheOption} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.e.FormCalendarRemoteCacheOption}
 */


proto.e.FormCalendarRemoteCacheOption.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value =
        /** @type {number} */
        reader.readInt32();
        msg.setChatbotId(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.e.FormCalendarRemoteCacheOption.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.e.FormCalendarRemoteCacheOption.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.e.FormCalendarRemoteCacheOption} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.e.FormCalendarRemoteCacheOption.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getChatbotId();

  if (f !== 0) {
    writer.writeInt32(1, f);
  }
};
/**
 * optional int32 chatbot_id = 1;
 * @return {number}
 */


proto.e.FormCalendarRemoteCacheOption.prototype.getChatbotId = function () {
  return (
    /** @type {number} */
    jspb.Message.getFieldWithDefault(this, 1, 0)
  );
};
/**
 * @param {number} value
 * @return {!proto.e.FormCalendarRemoteCacheOption} returns this
 */


proto.e.FormCalendarRemoteCacheOption.prototype.setChatbotId = function (value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.e.FormCalendarRemoteScrapeOption.prototype.toObject = function (opt_includeInstance) {
    return proto.e.FormCalendarRemoteScrapeOption.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.e.FormCalendarRemoteScrapeOption} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.e.FormCalendarRemoteScrapeOption.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      scrapePath: jspb.Message.getFieldWithDefault(msg, 1, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.e.FormCalendarRemoteScrapeOption}
 */


proto.e.FormCalendarRemoteScrapeOption.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.e.FormCalendarRemoteScrapeOption();
  return proto.e.FormCalendarRemoteScrapeOption.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.e.FormCalendarRemoteScrapeOption} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.e.FormCalendarRemoteScrapeOption}
 */


proto.e.FormCalendarRemoteScrapeOption.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setScrapePath(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.e.FormCalendarRemoteScrapeOption.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.e.FormCalendarRemoteScrapeOption.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.e.FormCalendarRemoteScrapeOption} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.e.FormCalendarRemoteScrapeOption.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getScrapePath();

  if (f.length > 0) {
    writer.writeString(1, f);
  }
};
/**
 * optional string scrape_path = 1;
 * @return {string}
 */


proto.e.FormCalendarRemoteScrapeOption.prototype.getScrapePath = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 1, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.e.FormCalendarRemoteScrapeOption} returns this
 */


proto.e.FormCalendarRemoteScrapeOption.prototype.setScrapePath = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.e.FormCalendarOption.prototype.toObject = function (opt_includeInstance) {
    return proto.e.FormCalendarOption.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.e.FormCalendarOption} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.e.FormCalendarOption.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      calendarDays: jspb.Message.getFieldWithDefault(msg, 1, 0),
      hasTimeSlot: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
      limitDays: jspb.Message.getFieldWithDefault(msg, 16, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.e.FormCalendarOption}
 */


proto.e.FormCalendarOption.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.e.FormCalendarOption();
  return proto.e.FormCalendarOption.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.e.FormCalendarOption} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.e.FormCalendarOption}
 */


proto.e.FormCalendarOption.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value =
        /** @type {number} */
        reader.readInt32();
        msg.setCalendarDays(value);
        break;

      case 2:
        var value =
        /** @type {boolean} */
        reader.readBool();
        msg.setHasTimeSlot(value);
        break;

      case 16:
        var value =
        /** @type {number} */
        reader.readInt32();
        msg.setLimitDays(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.e.FormCalendarOption.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.e.FormCalendarOption.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.e.FormCalendarOption} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.e.FormCalendarOption.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getCalendarDays();

  if (f !== 0) {
    writer.writeInt32(1, f);
  }

  f = message.getHasTimeSlot();

  if (f) {
    writer.writeBool(2, f);
  }

  f = message.getLimitDays();

  if (f !== 0) {
    writer.writeInt32(16, f);
  }
};
/**
 * optional int32 calendar_days = 1;
 * @return {number}
 */


proto.e.FormCalendarOption.prototype.getCalendarDays = function () {
  return (
    /** @type {number} */
    jspb.Message.getFieldWithDefault(this, 1, 0)
  );
};
/**
 * @param {number} value
 * @return {!proto.e.FormCalendarOption} returns this
 */


proto.e.FormCalendarOption.prototype.setCalendarDays = function (value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};
/**
 * optional bool has_time_slot = 2;
 * @return {boolean}
 */


proto.e.FormCalendarOption.prototype.getHasTimeSlot = function () {
  return (
    /** @type {boolean} */
    jspb.Message.getBooleanFieldWithDefault(this, 2, false)
  );
};
/**
 * @param {boolean} value
 * @return {!proto.e.FormCalendarOption} returns this
 */


proto.e.FormCalendarOption.prototype.setHasTimeSlot = function (value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};
/**
 * optional int32 limit_days = 16;
 * @return {number}
 */


proto.e.FormCalendarOption.prototype.getLimitDays = function () {
  return (
    /** @type {number} */
    jspb.Message.getFieldWithDefault(this, 16, 0)
  );
};
/**
 * @param {number} value
 * @return {!proto.e.FormCalendarOption} returns this
 */


proto.e.FormCalendarOption.prototype.setLimitDays = function (value) {
  return jspb.Message.setProto3IntField(this, 16, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.e.FormCalendarQuestionOption.prototype.toObject = function (opt_includeInstance) {
    return proto.e.FormCalendarQuestionOption.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.e.FormCalendarQuestionOption} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.e.FormCalendarQuestionOption.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      type: jspb.Message.getFieldWithDefault(msg, 1, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.e.FormCalendarQuestionOption}
 */


proto.e.FormCalendarQuestionOption.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.e.FormCalendarQuestionOption();
  return proto.e.FormCalendarQuestionOption.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.e.FormCalendarQuestionOption} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.e.FormCalendarQuestionOption}
 */


proto.e.FormCalendarQuestionOption.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value =
        /** @type {!proto.e.FormSelectRepresentationType} */
        reader.readEnum();
        msg.setType(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.e.FormCalendarQuestionOption.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.e.FormCalendarQuestionOption.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.e.FormCalendarQuestionOption} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.e.FormCalendarQuestionOption.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getType();

  if (f !== 0.0) {
    writer.writeEnum(1, f);
  }
};
/**
 * optional FormSelectRepresentationType type = 1;
 * @return {!proto.e.FormSelectRepresentationType}
 */


proto.e.FormCalendarQuestionOption.prototype.getType = function () {
  return (
    /** @type {!proto.e.FormSelectRepresentationType} */
    jspb.Message.getFieldWithDefault(this, 1, 0)
  );
};
/**
 * @param {!proto.e.FormSelectRepresentationType} value
 * @return {!proto.e.FormCalendarQuestionOption} returns this
 */


proto.e.FormCalendarQuestionOption.prototype.setType = function (value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */


proto.e.FormSelectAnswer.repeatedFields_ = [1];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.e.FormSelectAnswer.prototype.toObject = function (opt_includeInstance) {
    return proto.e.FormSelectAnswer.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.e.FormSelectAnswer} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.e.FormSelectAnswer.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      choicesList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.e.FormSelectAnswer}
 */


proto.e.FormSelectAnswer.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.e.FormSelectAnswer();
  return proto.e.FormSelectAnswer.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.e.FormSelectAnswer} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.e.FormSelectAnswer}
 */


proto.e.FormSelectAnswer.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value =
        /** @type {string} */
        reader.readString();
        msg.addChoices(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.e.FormSelectAnswer.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.e.FormSelectAnswer.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.e.FormSelectAnswer} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.e.FormSelectAnswer.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getChoicesList();

  if (f.length > 0) {
    writer.writeRepeatedString(1, f);
  }
};
/**
 * repeated string choices = 1;
 * @return {!Array<string>}
 */


proto.e.FormSelectAnswer.prototype.getChoicesList = function () {
  return (
    /** @type {!Array<string>} */
    jspb.Message.getRepeatedField(this, 1)
  );
};
/**
 * @param {!Array<string>} value
 * @return {!proto.e.FormSelectAnswer} returns this
 */


proto.e.FormSelectAnswer.prototype.setChoicesList = function (value) {
  return jspb.Message.setField(this, 1, value || []);
};
/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.e.FormSelectAnswer} returns this
 */


proto.e.FormSelectAnswer.prototype.addChoices = function (value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.e.FormSelectAnswer} returns this
 */


proto.e.FormSelectAnswer.prototype.clearChoicesList = function () {
  return this.setChoicesList([]);
};
/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */


proto.e.FormRow.oneofGroups_ = [[16, 17, 18, 19, 20, 21, 22]];
/**
 * @enum {number}
 */

proto.e.FormRow.ValueCase = {
  VALUE_NOT_SET: 0,
  TEXT: 16,
  NUMERIC: 17,
  SELECTIONS: 18,
  CREDIT_CARD: 19,
  DATE: 20,
  TIME: 21,
  DATETIME: 22
};
/**
 * @return {proto.e.FormRow.ValueCase}
 */

proto.e.FormRow.prototype.getValueCase = function () {
  return (
    /** @type {proto.e.FormRow.ValueCase} */
    jspb.Message.computeOneofCase(this, proto.e.FormRow.oneofGroups_[0])
  );
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.e.FormRow.prototype.toObject = function (opt_includeInstance) {
    return proto.e.FormRow.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.e.FormRow} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.e.FormRow.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      formElementId: jspb.Message.getFieldWithDefault(msg, 1, ""),
      type: jspb.Message.getFieldWithDefault(msg, 2, 0),
      text: (f = msg.getText()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
      numeric: (f = msg.getNumeric()) && google_protobuf_wrappers_pb.DoubleValue.toObject(includeInstance, f),
      selections: (f = msg.getSelections()) && proto.e.FormSelectAnswer.toObject(includeInstance, f),
      creditCard: (f = msg.getCreditCard()) && entities_entities_pb.CreditCard.toObject(includeInstance, f),
      date: (f = msg.getDate()) && entities_common_pb.Date.toObject(includeInstance, f),
      time: (f = msg.getTime()) && entities_common_pb.Time.toObject(includeInstance, f),
      datetime: (f = msg.getDatetime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.e.FormRow}
 */


proto.e.FormRow.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.e.FormRow();
  return proto.e.FormRow.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.e.FormRow} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.e.FormRow}
 */


proto.e.FormRow.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setFormElementId(value);
        break;

      case 2:
        var value =
        /** @type {!proto.e.FormElementType} */
        reader.readEnum();
        msg.setType(value);
        break;

      case 16:
        var value = new google_protobuf_wrappers_pb.StringValue();
        reader.readMessage(value, google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
        msg.setText(value);
        break;

      case 17:
        var value = new google_protobuf_wrappers_pb.DoubleValue();
        reader.readMessage(value, google_protobuf_wrappers_pb.DoubleValue.deserializeBinaryFromReader);
        msg.setNumeric(value);
        break;

      case 18:
        var value = new proto.e.FormSelectAnswer();
        reader.readMessage(value, proto.e.FormSelectAnswer.deserializeBinaryFromReader);
        msg.setSelections(value);
        break;

      case 19:
        var value = new entities_entities_pb.CreditCard();
        reader.readMessage(value, entities_entities_pb.CreditCard.deserializeBinaryFromReader);
        msg.setCreditCard(value);
        break;

      case 20:
        var value = new entities_common_pb.Date();
        reader.readMessage(value, entities_common_pb.Date.deserializeBinaryFromReader);
        msg.setDate(value);
        break;

      case 21:
        var value = new entities_common_pb.Time();
        reader.readMessage(value, entities_common_pb.Time.deserializeBinaryFromReader);
        msg.setTime(value);
        break;

      case 22:
        var value = new google_protobuf_timestamp_pb.Timestamp();
        reader.readMessage(value, google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
        msg.setDatetime(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.e.FormRow.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.e.FormRow.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.e.FormRow} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.e.FormRow.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getFormElementId();

  if (f.length > 0) {
    writer.writeString(1, f);
  }

  f = message.getType();

  if (f !== 0.0) {
    writer.writeEnum(2, f);
  }

  f = message.getText();

  if (f != null) {
    writer.writeMessage(16, f, google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter);
  }

  f = message.getNumeric();

  if (f != null) {
    writer.writeMessage(17, f, google_protobuf_wrappers_pb.DoubleValue.serializeBinaryToWriter);
  }

  f = message.getSelections();

  if (f != null) {
    writer.writeMessage(18, f, proto.e.FormSelectAnswer.serializeBinaryToWriter);
  }

  f = message.getCreditCard();

  if (f != null) {
    writer.writeMessage(19, f, entities_entities_pb.CreditCard.serializeBinaryToWriter);
  }

  f = message.getDate();

  if (f != null) {
    writer.writeMessage(20, f, entities_common_pb.Date.serializeBinaryToWriter);
  }

  f = message.getTime();

  if (f != null) {
    writer.writeMessage(21, f, entities_common_pb.Time.serializeBinaryToWriter);
  }

  f = message.getDatetime();

  if (f != null) {
    writer.writeMessage(22, f, google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter);
  }
};
/**
 * optional string form_element_id = 1;
 * @return {string}
 */


proto.e.FormRow.prototype.getFormElementId = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 1, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.e.FormRow} returns this
 */


proto.e.FormRow.prototype.setFormElementId = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional FormElementType type = 2;
 * @return {!proto.e.FormElementType}
 */


proto.e.FormRow.prototype.getType = function () {
  return (
    /** @type {!proto.e.FormElementType} */
    jspb.Message.getFieldWithDefault(this, 2, 0)
  );
};
/**
 * @param {!proto.e.FormElementType} value
 * @return {!proto.e.FormRow} returns this
 */


proto.e.FormRow.prototype.setType = function (value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};
/**
 * optional google.protobuf.StringValue text = 16;
 * @return {?proto.google.protobuf.StringValue}
 */


proto.e.FormRow.prototype.getText = function () {
  return (
    /** @type{?proto.google.protobuf.StringValue} */
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 16)
  );
};
/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.e.FormRow} returns this
*/


proto.e.FormRow.prototype.setText = function (value) {
  return jspb.Message.setOneofWrapperField(this, 16, proto.e.FormRow.oneofGroups_[0], value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.e.FormRow} returns this
 */


proto.e.FormRow.prototype.clearText = function () {
  return this.setText(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.e.FormRow.prototype.hasText = function () {
  return jspb.Message.getField(this, 16) != null;
};
/**
 * optional google.protobuf.DoubleValue numeric = 17;
 * @return {?proto.google.protobuf.DoubleValue}
 */


proto.e.FormRow.prototype.getNumeric = function () {
  return (
    /** @type{?proto.google.protobuf.DoubleValue} */
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.DoubleValue, 17)
  );
};
/**
 * @param {?proto.google.protobuf.DoubleValue|undefined} value
 * @return {!proto.e.FormRow} returns this
*/


proto.e.FormRow.prototype.setNumeric = function (value) {
  return jspb.Message.setOneofWrapperField(this, 17, proto.e.FormRow.oneofGroups_[0], value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.e.FormRow} returns this
 */


proto.e.FormRow.prototype.clearNumeric = function () {
  return this.setNumeric(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.e.FormRow.prototype.hasNumeric = function () {
  return jspb.Message.getField(this, 17) != null;
};
/**
 * optional FormSelectAnswer selections = 18;
 * @return {?proto.e.FormSelectAnswer}
 */


proto.e.FormRow.prototype.getSelections = function () {
  return (
    /** @type{?proto.e.FormSelectAnswer} */
    jspb.Message.getWrapperField(this, proto.e.FormSelectAnswer, 18)
  );
};
/**
 * @param {?proto.e.FormSelectAnswer|undefined} value
 * @return {!proto.e.FormRow} returns this
*/


proto.e.FormRow.prototype.setSelections = function (value) {
  return jspb.Message.setOneofWrapperField(this, 18, proto.e.FormRow.oneofGroups_[0], value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.e.FormRow} returns this
 */


proto.e.FormRow.prototype.clearSelections = function () {
  return this.setSelections(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.e.FormRow.prototype.hasSelections = function () {
  return jspb.Message.getField(this, 18) != null;
};
/**
 * optional CreditCard credit_card = 19;
 * @return {?proto.e.CreditCard}
 */


proto.e.FormRow.prototype.getCreditCard = function () {
  return (
    /** @type{?proto.e.CreditCard} */
    jspb.Message.getWrapperField(this, entities_entities_pb.CreditCard, 19)
  );
};
/**
 * @param {?proto.e.CreditCard|undefined} value
 * @return {!proto.e.FormRow} returns this
*/


proto.e.FormRow.prototype.setCreditCard = function (value) {
  return jspb.Message.setOneofWrapperField(this, 19, proto.e.FormRow.oneofGroups_[0], value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.e.FormRow} returns this
 */


proto.e.FormRow.prototype.clearCreditCard = function () {
  return this.setCreditCard(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.e.FormRow.prototype.hasCreditCard = function () {
  return jspb.Message.getField(this, 19) != null;
};
/**
 * optional Date date = 20;
 * @return {?proto.e.Date}
 */


proto.e.FormRow.prototype.getDate = function () {
  return (
    /** @type{?proto.e.Date} */
    jspb.Message.getWrapperField(this, entities_common_pb.Date, 20)
  );
};
/**
 * @param {?proto.e.Date|undefined} value
 * @return {!proto.e.FormRow} returns this
*/


proto.e.FormRow.prototype.setDate = function (value) {
  return jspb.Message.setOneofWrapperField(this, 20, proto.e.FormRow.oneofGroups_[0], value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.e.FormRow} returns this
 */


proto.e.FormRow.prototype.clearDate = function () {
  return this.setDate(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.e.FormRow.prototype.hasDate = function () {
  return jspb.Message.getField(this, 20) != null;
};
/**
 * optional Time time = 21;
 * @return {?proto.e.Time}
 */


proto.e.FormRow.prototype.getTime = function () {
  return (
    /** @type{?proto.e.Time} */
    jspb.Message.getWrapperField(this, entities_common_pb.Time, 21)
  );
};
/**
 * @param {?proto.e.Time|undefined} value
 * @return {!proto.e.FormRow} returns this
*/


proto.e.FormRow.prototype.setTime = function (value) {
  return jspb.Message.setOneofWrapperField(this, 21, proto.e.FormRow.oneofGroups_[0], value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.e.FormRow} returns this
 */


proto.e.FormRow.prototype.clearTime = function () {
  return this.setTime(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.e.FormRow.prototype.hasTime = function () {
  return jspb.Message.getField(this, 21) != null;
};
/**
 * optional google.protobuf.Timestamp datetime = 22;
 * @return {?proto.google.protobuf.Timestamp}
 */


proto.e.FormRow.prototype.getDatetime = function () {
  return (
    /** @type{?proto.google.protobuf.Timestamp} */
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 22)
  );
};
/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.e.FormRow} returns this
*/


proto.e.FormRow.prototype.setDatetime = function (value) {
  return jspb.Message.setOneofWrapperField(this, 22, proto.e.FormRow.oneofGroups_[0], value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.e.FormRow} returns this
 */


proto.e.FormRow.prototype.clearDatetime = function () {
  return this.setDatetime(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.e.FormRow.prototype.hasDatetime = function () {
  return jspb.Message.getField(this, 22) != null;
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */


proto.e.EndUserForm.repeatedFields_ = [3];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.e.EndUserForm.prototype.toObject = function (opt_includeInstance) {
    return proto.e.EndUserForm.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.e.EndUserForm} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.e.EndUserForm.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      formId: jspb.Message.getFieldWithDefault(msg, 1, ""),
      endUserId: jspb.Message.getFieldWithDefault(msg, 2, 0),
      rowsList: jspb.Message.toObjectList(msg.getRowsList(), proto.e.FormRow.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.e.EndUserForm}
 */


proto.e.EndUserForm.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.e.EndUserForm();
  return proto.e.EndUserForm.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.e.EndUserForm} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.e.EndUserForm}
 */


proto.e.EndUserForm.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setFormId(value);
        break;

      case 2:
        var value =
        /** @type {number} */
        reader.readInt64();
        msg.setEndUserId(value);
        break;

      case 3:
        var value = new proto.e.FormRow();
        reader.readMessage(value, proto.e.FormRow.deserializeBinaryFromReader);
        msg.addRows(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.e.EndUserForm.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.e.EndUserForm.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.e.EndUserForm} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.e.EndUserForm.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getFormId();

  if (f.length > 0) {
    writer.writeString(1, f);
  }

  f = message.getEndUserId();

  if (f !== 0) {
    writer.writeInt64(2, f);
  }

  f = message.getRowsList();

  if (f.length > 0) {
    writer.writeRepeatedMessage(3, f, proto.e.FormRow.serializeBinaryToWriter);
  }
};
/**
 * optional string form_id = 1;
 * @return {string}
 */


proto.e.EndUserForm.prototype.getFormId = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 1, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.e.EndUserForm} returns this
 */


proto.e.EndUserForm.prototype.setFormId = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional int64 end_user_id = 2;
 * @return {number}
 */


proto.e.EndUserForm.prototype.getEndUserId = function () {
  return (
    /** @type {number} */
    jspb.Message.getFieldWithDefault(this, 2, 0)
  );
};
/**
 * @param {number} value
 * @return {!proto.e.EndUserForm} returns this
 */


proto.e.EndUserForm.prototype.setEndUserId = function (value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};
/**
 * repeated FormRow rows = 3;
 * @return {!Array<!proto.e.FormRow>}
 */


proto.e.EndUserForm.prototype.getRowsList = function () {
  return (
    /** @type{!Array<!proto.e.FormRow>} */
    jspb.Message.getRepeatedWrapperField(this, proto.e.FormRow, 3)
  );
};
/**
 * @param {!Array<!proto.e.FormRow>} value
 * @return {!proto.e.EndUserForm} returns this
*/


proto.e.EndUserForm.prototype.setRowsList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};
/**
 * @param {!proto.e.FormRow=} opt_value
 * @param {number=} opt_index
 * @return {!proto.e.FormRow}
 */


proto.e.EndUserForm.prototype.addRows = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.e.FormRow, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.e.EndUserForm} returns this
 */


proto.e.EndUserForm.prototype.clearRowsList = function () {
  return this.setRowsList([]);
};
/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */


proto.e.FormElementValidation.oneofGroups_ = [[16, 17]];
/**
 * @enum {number}
 */

proto.e.FormElementValidation.ValidationCase = {
  VALIDATION_NOT_SET: 0,
  TEXT_VALIDATION: 16,
  NUMERIC_VALIDATION: 17
};
/**
 * @return {proto.e.FormElementValidation.ValidationCase}
 */

proto.e.FormElementValidation.prototype.getValidationCase = function () {
  return (
    /** @type {proto.e.FormElementValidation.ValidationCase} */
    jspb.Message.computeOneofCase(this, proto.e.FormElementValidation.oneofGroups_[0])
  );
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.e.FormElementValidation.prototype.toObject = function (opt_includeInstance) {
    return proto.e.FormElementValidation.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.e.FormElementValidation} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.e.FormElementValidation.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, ""),
      name: jspb.Message.getFieldWithDefault(msg, 2, ""),
      type: jspb.Message.getFieldWithDefault(msg, 3, 0),
      description: jspb.Message.getFieldWithDefault(msg, 4, ""),
      textValidation: (f = msg.getTextValidation()) && proto.e.FormTextValidation.toObject(includeInstance, f),
      numericValidation: (f = msg.getNumericValidation()) && proto.e.FormNumericValidation.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.e.FormElementValidation}
 */


proto.e.FormElementValidation.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.e.FormElementValidation();
  return proto.e.FormElementValidation.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.e.FormElementValidation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.e.FormElementValidation}
 */


proto.e.FormElementValidation.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setId(value);
        break;

      case 2:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setName(value);
        break;

      case 3:
        var value =
        /** @type {!proto.e.FormElementType} */
        reader.readEnum();
        msg.setType(value);
        break;

      case 4:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setDescription(value);
        break;

      case 16:
        var value = new proto.e.FormTextValidation();
        reader.readMessage(value, proto.e.FormTextValidation.deserializeBinaryFromReader);
        msg.setTextValidation(value);
        break;

      case 17:
        var value = new proto.e.FormNumericValidation();
        reader.readMessage(value, proto.e.FormNumericValidation.deserializeBinaryFromReader);
        msg.setNumericValidation(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.e.FormElementValidation.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.e.FormElementValidation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.e.FormElementValidation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.e.FormElementValidation.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getId();

  if (f.length > 0) {
    writer.writeString(1, f);
  }

  f = message.getName();

  if (f.length > 0) {
    writer.writeString(2, f);
  }

  f = message.getType();

  if (f !== 0.0) {
    writer.writeEnum(3, f);
  }

  f = message.getDescription();

  if (f.length > 0) {
    writer.writeString(4, f);
  }

  f = message.getTextValidation();

  if (f != null) {
    writer.writeMessage(16, f, proto.e.FormTextValidation.serializeBinaryToWriter);
  }

  f = message.getNumericValidation();

  if (f != null) {
    writer.writeMessage(17, f, proto.e.FormNumericValidation.serializeBinaryToWriter);
  }
};
/**
 * optional string id = 1;
 * @return {string}
 */


proto.e.FormElementValidation.prototype.getId = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 1, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.e.FormElementValidation} returns this
 */


proto.e.FormElementValidation.prototype.setId = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional string name = 2;
 * @return {string}
 */


proto.e.FormElementValidation.prototype.getName = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 2, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.e.FormElementValidation} returns this
 */


proto.e.FormElementValidation.prototype.setName = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};
/**
 * optional FormElementType type = 3;
 * @return {!proto.e.FormElementType}
 */


proto.e.FormElementValidation.prototype.getType = function () {
  return (
    /** @type {!proto.e.FormElementType} */
    jspb.Message.getFieldWithDefault(this, 3, 0)
  );
};
/**
 * @param {!proto.e.FormElementType} value
 * @return {!proto.e.FormElementValidation} returns this
 */


proto.e.FormElementValidation.prototype.setType = function (value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};
/**
 * optional string description = 4;
 * @return {string}
 */


proto.e.FormElementValidation.prototype.getDescription = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 4, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.e.FormElementValidation} returns this
 */


proto.e.FormElementValidation.prototype.setDescription = function (value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};
/**
 * optional FormTextValidation text_validation = 16;
 * @return {?proto.e.FormTextValidation}
 */


proto.e.FormElementValidation.prototype.getTextValidation = function () {
  return (
    /** @type{?proto.e.FormTextValidation} */
    jspb.Message.getWrapperField(this, proto.e.FormTextValidation, 16)
  );
};
/**
 * @param {?proto.e.FormTextValidation|undefined} value
 * @return {!proto.e.FormElementValidation} returns this
*/


proto.e.FormElementValidation.prototype.setTextValidation = function (value) {
  return jspb.Message.setOneofWrapperField(this, 16, proto.e.FormElementValidation.oneofGroups_[0], value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.e.FormElementValidation} returns this
 */


proto.e.FormElementValidation.prototype.clearTextValidation = function () {
  return this.setTextValidation(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.e.FormElementValidation.prototype.hasTextValidation = function () {
  return jspb.Message.getField(this, 16) != null;
};
/**
 * optional FormNumericValidation numeric_validation = 17;
 * @return {?proto.e.FormNumericValidation}
 */


proto.e.FormElementValidation.prototype.getNumericValidation = function () {
  return (
    /** @type{?proto.e.FormNumericValidation} */
    jspb.Message.getWrapperField(this, proto.e.FormNumericValidation, 17)
  );
};
/**
 * @param {?proto.e.FormNumericValidation|undefined} value
 * @return {!proto.e.FormElementValidation} returns this
*/


proto.e.FormElementValidation.prototype.setNumericValidation = function (value) {
  return jspb.Message.setOneofWrapperField(this, 17, proto.e.FormElementValidation.oneofGroups_[0], value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.e.FormElementValidation} returns this
 */


proto.e.FormElementValidation.prototype.clearNumericValidation = function () {
  return this.setNumericValidation(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.e.FormElementValidation.prototype.hasNumericValidation = function () {
  return jspb.Message.getField(this, 17) != null;
};
/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */


proto.e.FormAutomationTrigger.oneofGroups_ = [[16, 17]];
/**
 * @enum {number}
 */

proto.e.FormAutomationTrigger.SourceCase = {
  SOURCE_NOT_SET: 0,
  WEB_FORM_ID: 16,
  FORM_QUESTION_ID: 17
};
/**
 * @return {proto.e.FormAutomationTrigger.SourceCase}
 */

proto.e.FormAutomationTrigger.prototype.getSourceCase = function () {
  return (
    /** @type {proto.e.FormAutomationTrigger.SourceCase} */
    jspb.Message.computeOneofCase(this, proto.e.FormAutomationTrigger.oneofGroups_[0])
  );
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.e.FormAutomationTrigger.prototype.toObject = function (opt_includeInstance) {
    return proto.e.FormAutomationTrigger.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.e.FormAutomationTrigger} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.e.FormAutomationTrigger.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      type: jspb.Message.getFieldWithDefault(msg, 1, 0),
      webFormId: (f = msg.getWebFormId()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
      formQuestionId: (f = msg.getFormQuestionId()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.e.FormAutomationTrigger}
 */


proto.e.FormAutomationTrigger.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.e.FormAutomationTrigger();
  return proto.e.FormAutomationTrigger.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.e.FormAutomationTrigger} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.e.FormAutomationTrigger}
 */


proto.e.FormAutomationTrigger.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value =
        /** @type {!proto.e.FormAutomationTriggerType} */
        reader.readEnum();
        msg.setType(value);
        break;

      case 16:
        var value = new google_protobuf_wrappers_pb.StringValue();
        reader.readMessage(value, google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
        msg.setWebFormId(value);
        break;

      case 17:
        var value = new google_protobuf_wrappers_pb.StringValue();
        reader.readMessage(value, google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
        msg.setFormQuestionId(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.e.FormAutomationTrigger.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.e.FormAutomationTrigger.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.e.FormAutomationTrigger} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.e.FormAutomationTrigger.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getType();

  if (f !== 0.0) {
    writer.writeEnum(1, f);
  }

  f = message.getWebFormId();

  if (f != null) {
    writer.writeMessage(16, f, google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter);
  }

  f = message.getFormQuestionId();

  if (f != null) {
    writer.writeMessage(17, f, google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter);
  }
};
/**
 * optional FormAutomationTriggerType type = 1;
 * @return {!proto.e.FormAutomationTriggerType}
 */


proto.e.FormAutomationTrigger.prototype.getType = function () {
  return (
    /** @type {!proto.e.FormAutomationTriggerType} */
    jspb.Message.getFieldWithDefault(this, 1, 0)
  );
};
/**
 * @param {!proto.e.FormAutomationTriggerType} value
 * @return {!proto.e.FormAutomationTrigger} returns this
 */


proto.e.FormAutomationTrigger.prototype.setType = function (value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};
/**
 * optional google.protobuf.StringValue web_form_id = 16;
 * @return {?proto.google.protobuf.StringValue}
 */


proto.e.FormAutomationTrigger.prototype.getWebFormId = function () {
  return (
    /** @type{?proto.google.protobuf.StringValue} */
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 16)
  );
};
/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.e.FormAutomationTrigger} returns this
*/


proto.e.FormAutomationTrigger.prototype.setWebFormId = function (value) {
  return jspb.Message.setOneofWrapperField(this, 16, proto.e.FormAutomationTrigger.oneofGroups_[0], value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.e.FormAutomationTrigger} returns this
 */


proto.e.FormAutomationTrigger.prototype.clearWebFormId = function () {
  return this.setWebFormId(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.e.FormAutomationTrigger.prototype.hasWebFormId = function () {
  return jspb.Message.getField(this, 16) != null;
};
/**
 * optional google.protobuf.StringValue form_question_id = 17;
 * @return {?proto.google.protobuf.StringValue}
 */


proto.e.FormAutomationTrigger.prototype.getFormQuestionId = function () {
  return (
    /** @type{?proto.google.protobuf.StringValue} */
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 17)
  );
};
/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.e.FormAutomationTrigger} returns this
*/


proto.e.FormAutomationTrigger.prototype.setFormQuestionId = function (value) {
  return jspb.Message.setOneofWrapperField(this, 17, proto.e.FormAutomationTrigger.oneofGroups_[0], value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.e.FormAutomationTrigger} returns this
 */


proto.e.FormAutomationTrigger.prototype.clearFormQuestionId = function () {
  return this.setFormQuestionId(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.e.FormAutomationTrigger.prototype.hasFormQuestionId = function () {
  return jspb.Message.getField(this, 17) != null;
};
/**
 * @enum {number}
 */


proto.e.CompletionStatus = {
  COMPLETION_STATUS_UNSPECIFIED: 0,
  COMPLETION_STATUS_COMPLETE: 1,
  COMPLETION_STATUS_IN_PROGRESS: 2,
  COMPLETION_STATUS_ERROR: 3
};
/**
 * @enum {number}
 */

proto.e.SegmentType = {
  SEGMENT_TYPE_UNSPECIFIED: 0,
  SEGMENT_TYPE_WEB_FORM: 1,
  SEGMENT_TYPE_FORM_SCENARIO: 2
};
/**
 * @enum {number}
 */

proto.e.FormUsage = {
  FORM_USAGE_UNSPECIFIED: 0,
  FORM_USAGE_NORMAL: 1,
  FORM_USAGE_CALENDAR: 2,
  FORM_USAGE_PAYMENT: 3
};
/**
 * @enum {number}
 */

proto.e.FormElementType = {
  FORM_ELEMENT_TYPE_UNSPECIFIED: 0,
  FORM_ELEMENT_TYPE_TEXT: 1,
  FORM_ELEMENT_TYPE_NUMERIC: 2,
  FORM_ELEMENT_TYPE_SELECT: 3,
  FORM_ELEMENT_TYPE_CREDIT_CARD: 4,
  FORM_ELEMENT_TYPE_DATE_PICKER: 5,
  FORM_ELEMENT_TYPE_TIME_PICKER: 6,
  FORM_ELEMENT_TYPE_DATETIME_PICKER: 7,
  FORM_ELEMENT_TYPE_CALENDAR: 8
};
/**
 * @enum {number}
 */

proto.e.FormElementTextUsage = {
  FORM_ELEMENT_TEXT_USAGE_UNSPECIFIED: 0,
  FORM_ELEMENT_TEXT_USAGE_EMAIL: 1,
  FORM_ELEMENT_TEXT_USAGE_PASSWORD: 2,
  FORM_ELEMENT_TEXT_USAGE_POSTAL_CODE: 3,
  FORM_ELEMENT_TEXT_USAGE_PREFECTURE: 4,
  FORM_ELEMENT_TEXT_USAGE_MUNICIPALITIES: 5,
  FORM_ELEMENT_TEXT_USAGE_LAND_LOT: 6,
  FORM_ELEMENT_TEXT_USAGE_BUILDING: 7
};
/**
 * @enum {number}
 */

proto.e.FormSelectRepresentationType = {
  FORM_SELECT_REPRESENTATION_TYPE_UNSPECIFIED: 0,
  FORM_SELECT_REPRESENTATION_TYPE_SELECT: 1,
  FORM_SELECT_REPRESENTATION_TYPE_RADIO: 2,
  FORM_SELECT_REPRESENTATION_TYPE_CHECKBOX: 3
};
/**
 * @enum {number}
 */

proto.e.FormCalendarType = {
  FORM_CALENDAR_TYPE_UNSPECIFIED: 0,
  FORM_CALENDAR_TYPE_AVAILABLE: 1,
  FORM_CALENDAR_TYPE_TABLE: 2,
  FORM_CALENDAR_TYPE_QUESTION: 10
};
/**
 * @enum {number}
 */

proto.e.FormCalendarRemoteType = {
  FORM_CALENDAR_REMOTE_TYPE_UNSPECIFIED: 0,
  FORM_CALENDAR_REMOTE_TYPE_SCRAPE: 1,
  FORM_CALENDAR_REMOTE_TYPE_CACHE: 2
};
/**
 * @enum {number}
 */

proto.e.FormAutomationTriggerType = {
  FORM_AUTOMATION_TRIGGER_TYPE_UNSPECIFIED: 0,
  FORM_AUTOMATION_TRIGGER_TYPE_WEB_FORM: 1,
  FORM_AUTOMATION_TRIGGER_TYPE_FORM_QUESTION: 2
};
goog.object.extend(exports, proto.e);