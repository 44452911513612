var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useEffect } from 'react';
import { DataType, } from 'zeals-protobuf/zeals/web/entities/user_dossier_pb';
import FormAutomationTextValueMap from './FormAutomationTextValueMap';
import FormAutomationBooleanValueMap from './FormAutomationBooleanValueMap';
export default function FormAutomationValueMap(_a) {
    var field = _a.field, rest = __rest(_a, ["field"]);
    useEffect(function () {
        if (rest.valueMap.length === 0) {
            rest.onAddMapEntry();
        }
    }, []);
    if ((field === null || field === void 0 ? void 0 : field.dataType) === DataType.DATA_TYPE_BOOLEAN) {
        return React.createElement(FormAutomationBooleanValueMap, __assign({}, rest));
    }
    return React.createElement(FormAutomationTextValueMap, __assign({}, rest));
}
