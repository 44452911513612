import "core-js/modules/es.array.concat";
var talk = {
  collection: function collection(_ref) {
    var chatbotId = _ref.chatbotId,
        endUserId = _ref.endUserId;
    return "/chatbots/".concat(chatbotId, "/talk_room/end_users/").concat(endUserId, "/event_logs");
  },
  speeches: function speeches(params) {
    return "".concat(talk.collection(params), "/fetch_speeches");
  }
};
export default {
  talk: talk
};