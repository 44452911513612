import { tabTypes } from '@src/lib/app-constants';
/**
 * This method will generate effect from block_id, scenario_id, url
 *
 * @param block_id, scenario_id, url is get from postback action
 */
export default function generateEffect(block_id, scenario_id, url) {
    if (block_id)
        return tabTypes.BLOCK;
    if (scenario_id)
        return tabTypes.SCENARIO;
    if (url === null || url === void 0 ? void 0 : url.startsWith('tel:'))
        return tabTypes.PHONE_NUMBER;
    if (url === null || url === void 0 ? void 0 : url.startsWith('mailto:'))
        return tabTypes.EMAIL;
    if (url)
        return tabTypes.URL;
    return null;
}
