var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var _a;
import React from 'react';
import { Tooltip } from '@zeals-co-ltd/washi-components';
import styled from 'styled-components';
import onClickOutside from 'react-onclickoutside';
import { colors } from '@src/colors';
import { tooltipClasses } from '@mui/material';
var FilterDetailMenuWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: relative;\n\n  > div {\n    position: absolute;\n    background-color: ", ";\n    right: 5px;\n    margin-top: ", "px;\n    z-index: 1;\n    border-radius: 10px;\n    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1);\n\n    > div {\n      width: 160px;\n      height: 40px;\n      font-size: 12px;\n      color: rgba(9, 31, 108, 0.5);\n      padding: 11px 16px 0 16px;\n      cursor: pointer;\n\n      &:hover {\n        background-color: ", ";\n        color: ", ";\n      }\n    }\n\n    > div:nth-child(2),\n    > div:nth-child(3) {\n      color: ", ";\n\n      &:hover {\n        color: ", ";\n      }\n    }\n\n    > div:nth-child(3):hover {\n      color: ", ";\n    }\n  }\n"], ["\n  position: relative;\n\n  > div {\n    position: absolute;\n    background-color: ", ";\n    right: 5px;\n    margin-top: ", "px;\n    z-index: 1;\n    border-radius: 10px;\n    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1);\n\n    > div {\n      width: 160px;\n      height: 40px;\n      font-size: 12px;\n      color: rgba(9, 31, 108, 0.5);\n      padding: 11px 16px 0 16px;\n      cursor: pointer;\n\n      &:hover {\n        background-color: ", ";\n        color: ", ";\n      }\n    }\n\n    > div:nth-child(2),\n    > div:nth-child(3) {\n      color: ",
    ";\n\n      &:hover {\n        color: ",
    ";\n      }\n    }\n\n    > div:nth-child(3):hover {\n      color: ",
    ";\n    }\n  }\n"])), colors.white, function (props) { return (props.isBottom ? -160 : -10); }, colors.background.off_white_background, colors.icon, function (props) {
    return props.connected
        ? colors.background.tag_hover
        : 'rgba(9, 31, 108, 0.5)';
}, function (props) {
    return props.connected ? colors.background.tag_hover : colors.icon;
}, function (props) {
    return props.connected ? colors.background.tag_hover : colors.hover.red;
});
var CustomWidthTooltip = styled(function (_a) {
    var className = _a.className, props = __rest(_a, ["className"]);
    return (React.createElement(Tooltip, __assign({}, props, { classes: { popper: className } })));
})((_a = {},
    _a["& ." + tooltipClasses.tooltip] = {
        maxWidth: 155,
    },
    _a));
var FilterDetailMenu = function (_a) {
    var connected = _a.connected, isBottom = _a.isBottom, selectFilter = _a.selectFilter, setOpenDeleteConfirmModal = _a.setOpenDeleteConfirmModal, intl = _a.intl;
    var tooltipContent = function (actionText) { return (React.createElement("span", null,
        "\u3053\u306E\u30D5\u30A3\u30EB\u30BF\u30FC\u304C",
        React.createElement("br", null),
        "\u4F7F\u7528\u3055\u308C\u3066\u3044\u307E\u3059\u306E\u3067\u3001",
        React.createElement("br", null),
        actionText,
        "\u3067\u304D\u307E\u305B\u3093\u3002")); };
    return (React.createElement(FilterDetailMenuWrapper, { connected: connected, isBottom: isBottom, "data-testid": "FilterDetailMenu" },
        React.createElement("div", null,
            React.createElement("div", { className: "edit_filter_name", onClick: function () { return selectFilter(false); }, role: "button", tabIndex: 0 }, intl.formatMessage({ id: "9uN9z7", defaultMessage: "Edit Filter Name" })),
            connected ? (React.createElement(React.Fragment, null,
                React.createElement(CustomWidthTooltip, { placement: "bottom", arrow: true, title: tooltipContent(intl.formatMessage({ id: "wEQDC6", defaultMessage: "Edit" })) },
                    React.createElement("div", { className: "edit_filter_tag" }, intl.formatMessage({ id: "uJkv2X", defaultMessage: "Edit Tag" }))),
                React.createElement(CustomWidthTooltip, { placement: "bottom", arrow: true, title: tooltipContent(intl.formatMessage({ id: "K3r6DQ", defaultMessage: "Delete" })) },
                    React.createElement("div", { className: "delete_filter" }, intl.formatMessage({ id: "K3r6DQ", defaultMessage: "Delete" }))))) : (React.createElement(React.Fragment, null,
                React.createElement("div", { className: "edit_filter_tag", onClick: function () { return selectFilter(true); }, role: "button", tabIndex: 0 }, intl.formatMessage({ id: "uJkv2X", defaultMessage: "Edit Tag" })),
                React.createElement("div", { className: "delete_filter", onClick: setOpenDeleteConfirmModal, role: "button", tabIndex: 0 }, intl.formatMessage({ id: "K3r6DQ", defaultMessage: "Delete" })))))));
};
export default onClickOutside(FilterDetailMenu);
var templateObject_1;
