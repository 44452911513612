var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import { MessageFilterType, } from '../types/messageFilter';
export var defaultState = {
    isOpenFilterForm: false,
    isProcessing: false,
    done: '',
    // Tags
    searchTerm: '',
    attribute_answers: [],
    attributeAnswerIds: [],
    // message filters
    messageFilters: [],
    selectedFilterId: null,
    highlightFilterId: null,
    selectedAttributeQuestionGroupId: null,
};
export default (function (state, action) {
    if (state === void 0) { state = defaultState; }
    switch (action.type) {
        case MessageFilterType.RESET_SELECTED_FILTER:
            return Object.assign({}, state, {
                selectedFilterId: null,
                searchTerm: '',
            });
        case MessageFilterType.SELECT_TAG_GROUP_ID:
            return Object.assign({}, state, {
                selectedAttributeQuestionGroupId: action.selectedAttributeQuestionGroupId,
            });
        case MessageFilterType.RESET_SELECTED_TAGS:
            return Object.assign({}, state, {
                attributeAnswerIds: __spreadArray([], __read(action.initSelectedTagIds)),
                searchTerm: '',
            });
        case MessageFilterType.HANDLE_PROCESSING:
            return Object.assign({}, state, {
                isProcessing: action.boolValue,
            });
        case MessageFilterType.AD_SET_MESSAGE_FILTER_TAGS: {
            return Object.assign({}, state, { attribute_answers: action.tags });
        }
        case MessageFilterType.HANDLE_OPEN_FILTER_FORM:
            return Object.assign({}, state, {
                isOpenFilterForm: action.boolValue,
            });
        case MessageFilterType.SEARCH_TAG_ACTION:
            return Object.assign({}, state, { searchTerm: action.searchTerm });
        case MessageFilterType.SELECT_NEW_TAG:
            if (state.attributeAnswerIds.includes(action.newTagId))
                return state;
            return Object.assign({}, state, {
                attributeAnswerIds: __spreadArray(__spreadArray([], __read(state.attributeAnswerIds)), [action.newTagId]),
            });
        case MessageFilterType.REMOVE_SELECTED_TAG: {
            var TagIds = state.attributeAnswerIds;
            var indexOfWillRemoveTag = TagIds.indexOf(action.selectedTagId);
            if (indexOfWillRemoveTag >= 0) {
                TagIds.splice(indexOfWillRemoveTag, 1);
                return Object.assign({}, state, { attributeAnswerIds: TagIds });
            }
            return state;
        }
        case MessageFilterType.SET_MESSAGE_FILTERS:
            return Object.assign({}, state, { messageFilters: action.filters });
        case MessageFilterType.SELECT_MESSAGE_FILTER: {
            return Object.assign({}, state, {
                searchTerm: '',
                selectedFilterId: action.id,
                isOpenFilterForm: true,
            });
        }
        case MessageFilterType.CREATE_NEW_FILTER_LIST: {
            var tempMessageFilters = __spreadArray(__spreadArray([], __read(state.messageFilters)), [action.filter]);
            return Object.assign({}, state, {
                messageFilters: tempMessageFilters,
            });
        }
        case MessageFilterType.UPDATE_FILTER_LIST: {
            var updatedFilterIndex = state.messageFilters.findIndex(function (filter) { return filter.id === action.filter.id; });
            var tempMessageFilters = state.messageFilters;
            tempMessageFilters[updatedFilterIndex] = action.filter;
            return Object.assign({}, state, {
                messageFilters: tempMessageFilters,
            });
        }
        case MessageFilterType.SET_DONE_STATUS: {
            return Object.assign({}, state, { done: action.operation });
        }
        case MessageFilterType.RESET_DONE_STATUS: {
            return Object.assign({}, state, { done: '' });
        }
        case MessageFilterType.SET_HIGHLIGHT_FILTER_ID:
            return Object.assign({}, state, { highlightFilterId: action.filterId });
        default:
            return state;
    }
});
