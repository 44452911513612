import "core-js/modules/es.symbol";
import "core-js/modules/es.symbol.description";
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { colors, shadows } from '@src/colors';
import { transitions } from '@src/lib/animations';
var propTypes = {
  handleClick: PropTypes.func,
  deliveryType: PropTypes.string,
  description: PropTypes.string
};
var Circle = styled.div.withConfig({
  displayName: "Circle",
  componentId: "sc-kpm3gl-0"
})(["margin-top:90px;margin-left:90px;display:inline-block;width:250px;height:250px;border-radius:50%;box-shadow:", ";text-align:center;margin-right:70px;padding:30px 30px 30px 30px;transition:", ";p{margin-top:20px;}h1{margin-top:10px;}:hover{cursor:pointer;background-color:", ";color:", ";}"], shadows.medium.light, transitions.normal, colors.background.light_purple, colors.main_color);

var SelectScenarioType = function SelectScenarioType(props) {
  return React.createElement(Circle, {
    onClick: props.handleClick
  }, React.createElement("h1", null, props.deliveryType), React.createElement("p", null, props.description));
};

SelectScenarioType.propTypes = propTypes;
export default SelectScenarioType;