var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React, { useRef } from 'react';
import styled from 'styled-components';
import Input from '@src/components/atoms/Input';
import { colors } from '@src/colors';
var SearchBoxWrapper = styled(Input)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  border: unset;\n  border-radius: unset;\n  border-bottom: 1px solid rgba(23, 57, 165, 0.2);\n  > input {\n    padding: 0 ", " 0 20px;\n    font-family: 'Noto Sans JP';\n  }\n  &:hover {\n    background-color: ", ";\n  }\n  .adicon_close {\n    font-size: 12px !important;\n    position: absolute;\n    right: 10px;\n    background-color: ", ";\n    border-radius: 17px;\n    padding: 1px 1.5px;\n    &:before {\n      color: ", ";\n      padding-bottom: 4px;\n    }\n  }\n  &:before {\n    font-size: 17px;\n    position: absolute;\n    left: 14px;\n  }\n"], ["\n  border: unset;\n  border-radius: unset;\n  border-bottom: 1px solid rgba(23, 57, 165, 0.2);\n  > input {\n    padding: 0 ", " 0 20px;\n    font-family: 'Noto Sans JP';\n  }\n  &:hover {\n    background-color: ", ";\n  }\n  .adicon_close {\n    font-size: 12px !important;\n    position: absolute;\n    right: 10px;\n    background-color: ", ";\n    border-radius: 17px;\n    padding: 1px 1.5px;\n    &:before {\n      color: ", ";\n      padding-bottom: 4px;\n    }\n  }\n  &:before {\n    font-size: 17px;\n    position: absolute;\n    left: 14px;\n  }\n"])), function (props) { return (props.value ? '20px' : '0'); }, colors.background.light_purple, colors.background.hover_purple, colors.white);
var SearchBox = function (_a) {
    var _b = _a.searchText, searchText = _b === void 0 ? '' : _b, placeholder = _a.placeholder, _c = _a.disabled, disabled = _c === void 0 ? false : _c, _d = _a.autoFocus, autoFocus = _d === void 0 ? true : _d, onSearchTextChange = _a.onSearchTextChange, onKeyDown = _a.onKeyDown;
    var searchBoxElement = useRef(null);
    return (React.createElement(SearchBoxWrapper, { "data-testid": "SearchBox", iconClassName: "adicon_search", placeholder: placeholder, value: searchText, onChange: function (e) { return onSearchTextChange(e.target.value); }, onKeyDown: onKeyDown, ref: searchBoxElement, disabled: disabled, autoFocus: autoFocus }, searchText && (React.createElement("span", { role: "button", "aria-label": "close-button", tabIndex: 0, className: "adicon_close", onMouseUp: function () {
            onSearchTextChange('');
            if (searchBoxElement)
                searchBoxElement.current.focus();
        } }))));
};
export default SearchBox;
var templateObject_1;
