var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState } from 'react';
import styled from 'styled-components';
import { colors } from '@src/colors';
import { hoverTag, centeredFlex } from '@src/lib/mixins';
export var ComponentContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: contents;\n  .input_field {\n    background: ", ";\n    height: 32px;\n    border-radius: 100px;\n    padding: 0 1rem;\n    margin: 4px 8px 4px 0;\n  }\n  .btn--add-close {\n    width: 32px;\n    height: 32px;\n    border-radius: 50%;\n    background: ", ";\n    border-radius: 100px;\n    ", ";\n    color: ", ";\n    margin: 4px 8px 4px 0;\n    ", ";\n  }\n"], ["\n  display: contents;\n  .input_field {\n    background: ", ";\n    height: 32px;\n    border-radius: 100px;\n    padding: 0 1rem;\n    margin: 4px 8px 4px 0;\n  }\n  .btn--add-close {\n    width: 32px;\n    height: 32px;\n    border-radius: 50%;\n    background: ", ";\n    border-radius: 100px;\n    ", ";\n    color: ", ";\n    margin: 4px 8px 4px 0;\n    ", ";\n  }\n"])), colors.background.tag, colors.background.tag, centeredFlex, colors.text_pale, hoverTag);
var AddTag = function (_a) {
    var onSubmit = _a.onSubmit, _b = _a.defaultDisplay, defaultDisplay = _b === void 0 ? false : _b, _c = _a.defaultText, defaultText = _c === void 0 ? '' : _c;
    var _d = __read(useState(defaultDisplay), 2), inputDisplayed = _d[0], setInputDisplayed = _d[1];
    var _e = __read(useState(defaultText), 2), newTagText = _e[0], updateNewTagText = _e[1];
    return (React.createElement(ComponentContainer, { "data-testid": "AddTag" },
        inputDisplayed && (React.createElement("form", { action: "", onSubmit: function (e) {
                e.preventDefault();
                onSubmit(newTagText);
                updateNewTagText('');
                setInputDisplayed(false);
            } },
            React.createElement("input", { "data-testid": "createTag", className: "input_field", value: newTagText, onChange: function (e) { return updateNewTagText(e.target.value); }, onClick: function (e) { return e.stopPropagation(); } }))),
        React.createElement("div", { role: "button", tabIndex: 0, "data-testid": "displayCreateTagInput", className: "btn--add-close " + (inputDisplayed ? 'adicon_close' : 'adicon_plus02'), onClick: function (e) {
                e.stopPropagation();
                setInputDisplayed(!inputDisplayed);
            } })));
};
export default AddTag;
var templateObject_1;
