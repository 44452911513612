var mediaErrorMessage = function mediaErrorMessage(_ref) {
  var intl = _ref.intl,
      error = _ref.error;
  var code = error.code,
      MEDIA_ERR_ABORTED = error.MEDIA_ERR_ABORTED,
      MEDIA_ERR_DECODE = error.MEDIA_ERR_DECODE,
      MEDIA_ERR_NETWORK = error.MEDIA_ERR_NETWORK,
      MEDIA_ERR_SRC_NOT_SUPPORTED = error.MEDIA_ERR_SRC_NOT_SUPPORTED;

  switch (code) {
    case MEDIA_ERR_ABORTED:
      return intl.formatMessage({
        id: "Iure75",
        defaultMessage: [{
          "type": 0,
          "value": "Fetching process aborted by user."
        }]
      });

    case MEDIA_ERR_DECODE:
      return intl.formatMessage({
        id: "DfcHiz",
        defaultMessage: [{
          "type": 0,
          "value": "Error occurred when decoding."
        }]
      });

    case MEDIA_ERR_NETWORK:
      return intl.formatMessage({
        id: "jGYHlS",
        defaultMessage: [{
          "type": 0,
          "value": "Error occurred when downloading."
        }]
      });

    case MEDIA_ERR_SRC_NOT_SUPPORTED:
      return intl.formatMessage({
        id: "J3h7Fq",
        defaultMessage: [{
          "type": 0,
          "value": "Audio/video not supported."
        }]
      });

    default:
      return intl.formatMessage({
        id: "fLBhy7",
        defaultMessage: [{
          "type": 0,
          "value": "Unknown error."
        }]
      });
  }
};

export var mediaUploadValidation = function mediaUploadValidation(_ref2) {
  var intl = _ref2.intl,
      src = _ref2.src,
      onerror = _ref2.onerror,
      onloadedmetadata = _ref2.onloadedmetadata,
      limitations = _ref2.limitations,
      type = _ref2.type;
  var element = document.createElement(type);
  element.src = src;

  element.onerror = function (e) {
    return onerror(mediaErrorMessage({
      intl: intl,
      error: e.target.error
    }));
  };

  element.onloadedmetadata = function () {
    if (limitations.MAX_DURATION_ACCEPTABLE && element.duration > limitations.MAX_DURATION_ACCEPTABLE) return onerror(intl.formatMessage({
      id: "PJcXam",
      defaultMessage: [{
        "type": 0,
        "value": "Duration is over "
      }, {
        "type": 1,
        "value": "max_duration_acceptable"
      }, {
        "type": 0,
        "value": "."
      }]
    }, {
      max_duration_acceptable: String(limitations.MAX_DURATION_ACCEPTABLE)
    }));
    onloadedmetadata(element);
  };
};