var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import Accordion from '@src/components/atoms/Accordion';
import styled from 'styled-components';
import { colors } from '@src/colors';
import isEmpty from 'lodash/isEmpty';
import { centeredFlex } from '@src/lib/mixins';
var AccordionWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  grid-column: 1;\n  width: 100%;\n  height: ", ";\n  margin-top: ", ";\n"], ["\n  grid-column: 1;\n  width: 100%;\n  height: ", ";\n  margin-top: ", ";\n"])), function (props) { return props.tabContentHeight || 'none'; }, function (props) { return (props.isSubTab ? '0' : '1.5rem'); });
var ButtonWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  text-align: ", ";\n"], ["\n  display: flex;\n  text-align: ", ";\n"])), function (props) { return (props.isTabTextCenter ? 'center' : 'none'); });
var AccordionContent = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: ", ";\n  padding: ", ";\n  font-size: 14px;\n  overflow: ", ";\n  max-height: 90%;\n"], ["\n  display: ", ";\n  padding: ", ";\n  font-size: 14px;\n  overflow: ", ";\n  max-height: 90%;\n"])), function (props) { return (props.isOpen ? 'block' : 'none'); }, function (props) { return props.accordionPadding || '0.5rem'; }, function (props) { return props.accordionContentOverflow || 'auto'; });
var TabButton = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  position: relative;\n  flex: 1;\n  padding: 0.5rem 1rem;\n  margin-bottom: 1rem;\n  font-size: 14px;\n  cursor: pointer;\n  color: ", ";\n  text-align: unset;\n  font-weight: ", ";\n  border-bottom: ", ";\n  font-weight: ", ";\n  transition: 0.3s;\n  &:hover {\n    color: ", ";\n    border-bottom: ", ";\n  }\n  &.sub-tab-button {\n    flex: inherit;\n    border-bottom: none;\n    color: ", ";\n    margin-bottom: auto;\n    padding: 12px 8px;\n    &:hover {\n      color: ", ";\n    }\n  }\n  button {\n    z-index: 10;\n    border: none;\n    display: none;\n    background-color: ", ";\n    position: absolute;\n    right: 20px;\n    font-weight: normal;\n    :hover {\n      font-weight: bold;\n    }\n    .adicon_close_webform {\n      ", "\n      color: #d1d7ed;\n    }\n  }\n  :hover {\n    button {\n      display: inline-block;\n    }\n  }\n  .adicon_plus02 {\n    font-size: 24px;\n    margin-right: 5px;\n    display: inline-flex;\n  }\n"], ["\n  position: relative;\n  flex: 1;\n  padding: 0.5rem 1rem;\n  margin-bottom: 1rem;\n  font-size: 14px;\n  cursor: pointer;\n  color: ", ";\n  text-align: unset;\n  font-weight: ", ";\n  border-bottom: ",
    ";\n  font-weight: ", ";\n  transition: 0.3s;\n  &:hover {\n    color: ", ";\n    border-bottom: ",
    ";\n  }\n  &.sub-tab-button {\n    flex: inherit;\n    border-bottom: none;\n    color: ",
    ";\n    margin-bottom: auto;\n    padding: 12px 8px;\n    &:hover {\n      color: ",
    ";\n    }\n  }\n  button {\n    z-index: 10;\n    border: none;\n    display: none;\n    background-color: ", ";\n    position: absolute;\n    right: 20px;\n    font-weight: normal;\n    :hover {\n      font-weight: bold;\n    }\n    .adicon_close_webform {\n      ", "\n      color: #d1d7ed;\n    }\n  }\n  :hover {\n    button {\n      display: inline-block;\n    }\n  }\n  .adicon_plus02 {\n    font-size: 24px;\n    margin-right: 5px;\n    display: inline-flex;\n  }\n"])), function (props) { return (props.isOpen ? colors.default_text : colors.text_pale); }, function (props) { return (props.isOpen ? 'bold' : 'normal'); }, function (props) {
    return props.isOpen
        ? "3px solid " + (props.tabBorderColor || colors.accent_color)
        : "3px solid " + colors.border.pale;
}, function (props) { return (props.isOpen ? "bold" : ''); }, colors.default_text, function (props) {
    return props.isOpen ? "" : "3px solid " + colors.border.default;
}, function (props) {
    return props.isOpen ? props.tabButtonColor : colors.text_pale;
}, function (props) {
    return props.isOpen ? props.tabButtonColor : colors.default_text;
}, colors.white, centeredFlex);
function preventClose(state, changes) {
    // prevents close on items where the openIndexes' length is less than 2
    if (changes.type === 'closing' && state.openIndexes.length < 2) {
        return __assign(__assign({}, changes), { openIndexes: state.openIndexes });
    }
    return changes;
}
function single(state, changes) {
    // can only open one item at a time
    if (changes.type === 'opening') {
        return { openIndexes: changes.openIndexes.slice(-1) };
    }
    return changes;
}
function combineReducers() {
    var reducers = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        reducers[_i] = arguments[_i];
    }
    // changes the state based on the reducer functions that get passed
    return function (state, changes) {
        var newChanges = changes;
        reducers.forEach(function (reducer) {
            newChanges = reducer(state, newChanges);
        });
        return newChanges;
    };
}
var Tabs = function (_a) {
    var _b = _a.stateReducer, stateReducer = _b === void 0 ? function (state, changes) { return changes; } : _b, tabItems = _a.tabItems, newTab = _a.newTab, tabBorderColor = _a.tabBorderColor, accordionPadding = _a.accordionPadding, accordionContentOverflow = _a.accordionContentOverflow, tabContentHeight = _a.tabContentHeight, isTabTextCenter = _a.isTabTextCenter, isSubTab = _a.isSubTab, tabButtonColor = _a.tabButtonColor, initTabIndex = _a.initTabIndex, handleDeleteTab = _a.handleDeleteTab;
    var _c = newTab || {}, tabName = _c.tabName, handleCreateNewTab = _c.handleCreateNewTab;
    return (React.createElement(Accordion, { initTabIndex: initTabIndex, stateReducer: combineReducers(preventClose, single, stateReducer) }, function (_a) {
        var openIndexes = _a.openIndexes, handleItemClick = _a.handleItemClick;
        return (React.createElement(AccordionWrapper, { tabContentHeight: tabContentHeight, isSubTab: isSubTab },
            React.createElement(ButtonWrapper, { isTabTextCenter: isTabTextCenter },
                tabItems.map(function (tab, i) { return (React.createElement(TabButton, { className: isSubTab ? 'sub-tab-button' : '', key: tab.name, "data-testid": "tabButton", isOpen: openIndexes === null || openIndexes === void 0 ? void 0 : openIndexes.includes(i), onClick: function () { return handleItemClick(i); }, tabBorderColor: tabBorderColor, tabButtonColor: tabButtonColor },
                    tab.name,
                    handleDeleteTab && (React.createElement("button", { type: "button", "data-testid": "deleteIcon", onClick: function (e) {
                            e.stopPropagation();
                            handleDeleteTab(tab);
                        } },
                        React.createElement("span", { className: "adicon_close_webform" }))))); }),
                !isEmpty(newTab) && (React.createElement(TabButton, { "data-testid": "newTab", onClick: function () {
                        handleCreateNewTab();
                        handleItemClick(tabItems.length - 1 || 0);
                    } },
                    React.createElement("span", { className: "adicon_plus02" }),
                    tabName))),
            tabItems.map(function (tab, i) {
                if (!(openIndexes === null || openIndexes === void 0 ? void 0 : openIndexes.includes(i)))
                    return null;
                return (React.createElement(AccordionContent, { key: tab.name, isOpen: openIndexes.includes(i), accordionPadding: accordionPadding, accordionContentOverflow: accordionContentOverflow, tabBorderColor: tabBorderColor, "data-testid": "accordionContent" }, openIndexes.includes(i) && tab.content));
            })));
    }));
};
export default Tabs;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
