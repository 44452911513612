import "core-js/modules/es.array.includes";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
import React from 'react';
import styled from 'styled-components';
import { injectIntl } from 'react-intl';
import { Button } from '@src/components/atoms';
import buttonTypes from '@src/lib/buttonTypes';
import { colors, shadows } from '@src/colors';
import { deviceTypeMessages, triggerTypeMessages } from '@src/i18n/translations';
var Container = styled.div.withConfig({
  displayName: "Container",
  componentId: "sc-10ea4px-0"
})(["background:", ";margin:24px 0;border-radius:10px;box-shadow:", ";padding:24px;"], colors.white, shadows.medium.light);
var TriggerInfoContainer = styled.div.withConfig({
  displayName: "TriggerInfoContainer",
  componentId: "sc-10ea4px-1"
})(["display:flex;justify-content:space-between;align-items:center;padding-top:24px;.trigger_info_item{min-width:25%;padding:16px;box-shadow:", ";border-radius:5px;display:flex;align-items:center;.trigger_info_label{color:", ";padding-right:12px;font-size:12px;}}"], shadows.medium.light, colors.label.font_dark);

var TriggerInfo = function TriggerInfo(_ref) {
  var intl = _ref.intl,
      onEdit = _ref.onEdit,
      trigger = _objectWithoutProperties(_ref, ["intl", "onEdit"]);

  return React.createElement(Container, null, React.createElement("h3", null, "\u30C8\u30EA\u30AC\u30FC"), React.createElement(TriggerInfoContainer, null, React.createElement("div", {
    className: "trigger_info_item"
  }, React.createElement("div", {
    className: "trigger_info_label"
  }, "\u30C7\u30D0\u30A4\u30B9\u30BF\u30A4\u30D7:"), trigger.device_type && intl.formatMessage(deviceTypeMessages[trigger.device_type.toUpperCase()])), React.createElement("div", {
    className: "trigger_info_item"
  }, React.createElement("div", {
    className: "trigger_info_label"
  }, "\u30C8\u30EA\u30AC\u30FC\u30BF\u30A4\u30D7:"), trigger.trigger_type && intl.formatMessage(triggerTypeMessages[trigger.trigger_type.toUpperCase()])), ['page_load', 'browser_back', 'click'].includes(trigger.trigger_type) ? '' : React.createElement("div", {
    className: "trigger_info_item"
  }, React.createElement("div", {
    className: "trigger_info_label"
  }, "\u30C8\u30EA\u30AC\u30FC\u6761\u4EF6:"), !trigger.string_condition ? trigger.integer_condition : trigger.string_condition), React.createElement(Button, {
    width: 120,
    onClick: onEdit,
    text: "\u7DE8\u96C6",
    buttonType: buttonTypes.SETTING
  })));
};

export default injectIntl(TriggerInfo);