import "core-js/modules/es.array.concat";
import "core-js/modules/es.object.assign";

var collection = function collection(_ref) {
  var pluginId = _ref.pluginId,
      pathId = _ref.pathId;
  return "/integration_plugins/plugins/".concat(pluginId, "/paths/").concat(pathId, "/events");
};

var member = function member(_ref2) {
  var eventId = _ref2.eventId;
  return "/integration_plugins/events/".concat(eventId);
};

var triggerUpdate = function triggerUpdate(_ref3) {
  var rest = Object.assign({}, _ref3);
  return "".concat(member(rest), "/trigger_update");
};

export default {
  collection: collection,
  member: member,
  triggerUpdate: triggerUpdate
};