var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import { colors } from '@src/colors';
import { centeredFlex, hoverDelete, hoverTag } from '@src/lib/mixins';
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  height: 32px;\n  font-size: 12px;\n  background: ", ";\n  color: ", ";\n  padding: ", ";\n  ", ";\n  width: fit-content;\n  align-items: center;\n  border-radius: 5px;\n  margin: 4px 8px 4px 0;\n  word-break: keep-all;\n  white-space: nowrap;\n  cursor: ", ";\n  ", "\n\n  .adicon_tag {\n    display: inline-flex;\n    &:before {\n      font-size: 14px;\n    }\n  }\n\n  .tag-name {\n    margin-left: 4px;\n    margin-right: 16px;\n    color: ", ";\n  }\n\n  .adicon_close {\n    margin-left: 4px;\n    padding-bottom: 4px;\n    ", ";\n    &:before {\n      font-size: 12px;\n    }\n  }\n"], ["\n  height: 32px;\n  font-size: 12px;\n  background: ", ";\n  color: ", ";\n  padding: ", ";\n  ", ";\n  width: fit-content;\n  align-items: center;\n  border-radius: 5px;\n  margin: 4px 8px 4px 0;\n  word-break: keep-all;\n  white-space: nowrap;\n  cursor: ", ";\n  ", "\n\n  .adicon_tag {\n    display: inline-flex;\n    &:before {\n      font-size: 14px;\n    }\n  }\n\n  .tag-name {\n    margin-left: 4px;\n    margin-right: 16px;\n    color: ", ";\n  }\n\n  .adicon_close {\n    margin-left: 4px;\n    padding-bottom: 4px;\n    ", ";\n    &:before {\n      font-size: 12px;\n    }\n  }\n"])), colors.background.tag, colors.text_pale, function (props) { return (props.iconClass ? '8px' : '8px 16px'); }, centeredFlex, function (props) { return (props.onClick ? 'pointer' : 'none'); }, hoverTag, colors.default_text, hoverDelete);
var Tag = function (_a) {
    var className = _a.className, name = _a.name, onClick = _a.onClick, iconClass = _a.iconClass, children = _a.children, isTagGroup = _a.isTagGroup, onRemove = _a.onRemove;
    return (React.createElement(Container, { "data-testid": "tag", className: className, iconClass: iconClass, onClick: onClick },
        children,
        !isTagGroup && React.createElement("span", { className: "adicon_tag", "data-testid": "Tag_AdiconTag" }),
        React.createElement("div", { className: "tag-name" }, name),
        iconClass && (
        // eslint-disable-next-line jsx-a11y/control-has-associated-label
        React.createElement("span", { className: iconClass, "data-testid": "removeTag", onClick: onRemove, tabIndex: 0, role: "button" }))));
};
export default Tag;
var templateObject_1;
