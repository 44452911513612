import React from 'react';
import { Composition } from 'atomic-layout';
import HeaderTalkCustomize from '@src/components/organisms/fanp/HeaderTalkCustomize';
var areas = "\n  header\n  body\n";
var TalkCustomize = function (_a) {
    var children = _a.children;
    var childrenWithProps = React.Children.map(children, function (child) {
        if (React.isValidElement(child))
            return React.cloneElement(child, {});
        return null;
    });
    return (React.createElement(Composition, { height: "100vh", areas: areas, templateRows: "56px auto" }, function (_a) {
        var Header = _a.Header, Body = _a.Body;
        return (React.createElement(React.Fragment, null,
            React.createElement(Header, null,
                React.createElement(HeaderTalkCustomize, null)),
            React.createElement(Body, null, childrenWithProps)));
    }));
};
export default TalkCustomize;
