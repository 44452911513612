export var settings = {
  UNDO_SETTINGS: 'UNDO_SETTINGS',
  TOGGLE_WELCOME_BOARD: 'TOGGLE_WELCOME_BOARD',
  TOGGLE_MAIN_MENU: 'TOGGLE_MAIN_MENU',
  TOGGLE_DEFAULT_MESSAGE: 'TOGGLE_DEFAULT_MESSAGE',
  TOGGLE_DISCONNECT_MESSAGE: 'TOGGLE_DISCONNECT_MESSAGE',
  TOGGLE_FETCHING_SETTINGS: 'TOGGLE_FETCHING_SETTINGS',
  GET_WELCOME_MESSAGE_TEXT: 'GET_WELCOME_MESSAGE_TEXT',
  GET_DEFAULT_MSG_TEXT: 'GET_DEFAULT_MSG_TEXT',
  CHANGE_DEFAULT_OPTION: 'CHANGE_DEFAULT_OPTION',
  FETCH_PERSISTENT_MENU_LIST: 'FETCH_PERSISTENT_MENU_LIST',
  FETCH_PAGE_LIST: 'FETCH_PAGE_LIST',
  SET_PAGE_LIST: 'SET_PAGE_LIST',
  SET_DEFAULT_OPTION_SELECTED: 'SET_DEFAULT_OPTION_SELECTED',
  HANDLE_CHATBOT_UPDATE: 'HANDLE_CHATBOT_UPDATE',
  UPDATE_PERSISTENT_MENU: 'UPDATE_PERSISTENT_MENU',
  UPDATE_CHATBOT_STATUS: 'UPDATE_CHATBOT_STATUS',
  UPDATE_FULFILLED: 'UPDATE_FULFILLED',
  REMOVE_FULFILLED: 'REMOVE_FULFILLED',
  SUBMIT_DEFAULT_MESSAGE: 'SUBMIT_DEFAULT_MESSAGE',
  CONNECT_PAGE: 'CONNECT_PAGE',
  API_FAILED: 'API_FAILED',
  UPDATE_PLUGIN_MESSAGE: 'UPDATE_PLUGIN_MESSAGE',
  UPDATE_PLUGIN_TITLE: 'UPDATE_PLUGIN_TITLE',
  UPDATE_PLUGIN_BUTTON: 'UPDATE_PLUGIN_BUTTON',
  UNDO_AD_SETTINGS: 'UNDO_AD_SETTINGS',
  // Settings
  AD_HANDLE_NEW_PERSISTENT_MENU_ITEM: 'AD_HANDLE_NEW_PERSISTENT_MENU_ITEM',
  AD_SET_PERSISTENT_MENU_ITEMS: 'AD_SET_PERSISTENT_MENU_ITEMS',
  AD_UPDATE_PERSISTENT_MENU_ITEM: 'AD_UPDATE_PERSISTENT_MENU_ITEM',
  AD_DELETE_PERSISTENT_MENU_ITEM: 'AD_DELETE_PERSISTENT_MENU_ITEM'
};