var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import { colors } from '@src/colors';
import { Spinner, Flash } from '@src/components/atoms';
/**
 * This atom will show dialog depend on status pass into
 * if status is success or failed status, dialog will apprear in 3 seconds
 * if status is uploading, dialog will appear until upload file or failed
 * during this time, user can not do any thing on screen
 *
 * @param status is string define type of dialog
 * @param message is string set message to show
 * @param width is number set dialog width
 */
var SECONDS_VISIBLE = 3;
var BaseUploadedVideoFileMessageWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: fixed;\n  bottom: 100px;\n  z-index: 1;\n  width: 100%;\n  left: 0px;\n  pointer-events: none;\n\n  .Flash {\n    display: flex;\n    text-align: center;\n    padding-top: 24px;\n    ", "\n    margin: auto;\n    min-height: 72px;\n    border-radius: 10px;\n    color: white;\n    pointer-events: none;\n\n    > div {\n      width: unset;\n    }\n\n    > div:last-of-type {\n      font-weight: 400;\n      padding-left: 47px;\n    }\n  }\n"], ["\n  position: fixed;\n  bottom: 100px;\n  z-index: 1;\n  width: 100%;\n  left: 0px;\n  pointer-events: none;\n\n  .Flash {\n    display: flex;\n    text-align: center;\n    padding-top: 24px;\n    ", "\n    margin: auto;\n    min-height: 72px;\n    border-radius: 10px;\n    color: white;\n    pointer-events: none;\n\n    > div {\n      width: unset;\n    }\n\n    > div:last-of-type {\n      font-weight: 400;\n      padding-left: 47px;\n    }\n  }\n"])), function (props) { return props.width && 'width: ' + props.width + 'px;'; });
var SuccessUploadedVideoFileMessageWrapper = styled(BaseUploadedVideoFileMessageWrapper)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  .Flash {\n    > div:last-of-type {\n      padding-left: 47px;\n    }\n\n    .spinner {\n      top: 12px;\n      width: 17px;\n      left: 35px;\n\n      & .path {\n        animation: unset;\n        stroke: ", ";\n      }\n    }\n  }\n"], ["\n  .Flash {\n    > div:last-of-type {\n      padding-left: 47px;\n    }\n\n    .spinner {\n      top: 12px;\n      width: 17px;\n      left: 35px;\n\n      & .path {\n        animation: unset;\n        stroke: ", ";\n      }\n    }\n  }\n"])), colors.white);
var FailedUploadedVideoFileMessageWrapper = styled(BaseUploadedVideoFileMessageWrapper)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  .Flash {\n    position: relative;\n    padding-top: 13px;\n\n    > div:last-of-type {\n      padding-left: 50px;\n    }\n\n    .adicon_attention::after {\n      display: none;\n    }\n\n    .adicon_attention::before {\n      position: absolute;\n      top: 37%;\n      font-size: 22px;\n      left: 25px;\n    }\n  }\n"], ["\n  .Flash {\n    position: relative;\n    padding-top: 13px;\n\n    > div:last-of-type {\n      padding-left: 50px;\n    }\n\n    .adicon_attention::after {\n      display: none;\n    }\n\n    .adicon_attention::before {\n      position: absolute;\n      top: 37%;\n      font-size: 22px;\n      left: 25px;\n    }\n  }\n"])));
var UploadingVideoFileMessageWrapper = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  z-index: 101;\n  position: fixed;\n  width: 100%;\n  height: 100%;\n  top: 0px;\n  left: 0px;\n  cursor: not-allowed;\n\n  > div {\n    position: fixed;\n    bottom: 100px;\n    z-index: 1;\n    width: 100%;\n    left: 0px;\n\n    > div {\n      display: flex;\n      text-align: center;\n      padding-top: 24px;\n      ", "\n      margin: auto;\n      height: 72px;\n      background-color: ", ";\n      border-radius: 10px;\n      color: white;\n\n      > div {\n        width: unset;\n      }\n\n      > div:last-of-type {\n        font-weight: 400;\n        padding-left: 70px;\n      }\n\n      .spinner {\n        top: 11px;\n        width: 25px;\n        left: 48px;\n\n        & .path {\n          stroke: ", ";\n        }\n      }\n    }\n  }\n"], ["\n  z-index: 101;\n  position: fixed;\n  width: 100%;\n  height: 100%;\n  top: 0px;\n  left: 0px;\n  cursor: not-allowed;\n\n  > div {\n    position: fixed;\n    bottom: 100px;\n    z-index: 1;\n    width: 100%;\n    left: 0px;\n\n    > div {\n      display: flex;\n      text-align: center;\n      padding-top: 24px;\n      ", "\n      margin: auto;\n      height: 72px;\n      background-color: ", ";\n      border-radius: 10px;\n      color: white;\n\n      > div {\n        width: unset;\n      }\n\n      > div:last-of-type {\n        font-weight: 400;\n        padding-left: 70px;\n      }\n\n      .spinner {\n        top: 11px;\n        width: 25px;\n        left: 48px;\n\n        & .path {\n          stroke: ", ";\n        }\n      }\n    }\n  }\n"])), function (props) { return props.width && 'width: ' + props.width + 'px;'; }, colors.background.uploading, colors.success);
var UploadingVideoFileMessage = function (_a) {
    var message = _a.message, width = _a.width;
    return (React.createElement(UploadingVideoFileMessageWrapper, { width: width },
        React.createElement("div", null,
            React.createElement("div", null,
                React.createElement(Spinner, { spinnerOnly: true, strokeWidth: 4 }),
                React.createElement("div", null, message)))));
};
var SuccessUploadedVideoFileMessage = function (_a) {
    var message = _a.message, width = _a.width;
    return (React.createElement(SuccessUploadedVideoFileMessageWrapper, { width: width },
        React.createElement(Flash, { type: "success", secondsVisible: SECONDS_VISIBLE },
            React.createElement(Spinner, { spinnerOnly: true, strokeWidth: 7 }),
            React.createElement("div", null, message))));
};
var FailedUploadedVideoFileMessage = function (_a) {
    var message = _a.message, width = _a.width;
    return (React.createElement(FailedUploadedVideoFileMessageWrapper, { width: width },
        React.createElement(Flash, { type: "error", secondsVisible: SECONDS_VISIBLE },
            React.createElement("div", { className: "adicon_attention" }),
            React.createElement("div", null, message))));
};
var UploadDialog = function (_a) {
    var status = _a.status, message = _a.message, width = _a.width;
    switch (status) {
        case 'uploading':
            return React.createElement(UploadingVideoFileMessage, { message: message, width: width });
        case 'success':
            return (React.createElement(SuccessUploadedVideoFileMessage, { message: message, width: width }));
        case 'failed':
            return React.createElement(FailedUploadedVideoFileMessage, { message: message, width: width });
        default:
            return null;
    }
};
export default UploadDialog;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
