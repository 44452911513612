var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import { colors } from '@src/colors';
import { Link } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import featureFlags from '@src/lib/feature_flags/featureFlags';
import LIMITS from '@src/lib/platforms/limitations';
var SubMenuContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: 1rem 0 1rem 1rem;\n  ul.menu-group {\n    margin-bottom: 1rem;\n    li {\n      padding-bottom: 1rem;\n      &.title {\n        color: ", ";\n        font-size: 10px;\n      }\n      &.active {\n        a {\n          color: ", ";\n          font-weight: bold;\n        }\n      }\n      a {\n        color: ", ";\n        display: block;\n        cursor: pointer;\n        &:hover {\n          color: ", " !important;\n          font-weight: bold;\n        }\n      }\n    }\n  }\n"], ["\n  padding: 1rem 0 1rem 1rem;\n  ul.menu-group {\n    margin-bottom: 1rem;\n    li {\n      padding-bottom: 1rem;\n      &.title {\n        color: ", ";\n        font-size: 10px;\n      }\n      &.active {\n        a {\n          color: ", ";\n          font-weight: bold;\n        }\n      }\n      a {\n        color: ", ";\n        display: block;\n        cursor: pointer;\n        &:hover {\n          color: ", " !important;\n          font-weight: bold;\n        }\n      }\n    }\n  }\n"])), colors.grey, colors.main_color, colors.text_pale, colors.main_color);
function SubMenu(_a) {
    var intl = _a.intl, route = _a.route, chatbotId = _a.chatbotId, settings = _a.settings;
    var platform = settings.activeChatbot.platform;
    var limits = LIMITS[platform];
    return (React.createElement(SubMenuContainer, null,
        React.createElement("ul", { className: "menu-group" },
            React.createElement("li", { className: "title" }, intl.formatMessage({ id: "yuiyES", defaultMessage: "General Settings" })),
            React.createElement("li", { className: "sub-menu__item " + (route === 'settings' || route === '' ? 'active' : '') },
                React.createElement(Link, { to: "/dashboard/ad/chatbots/" + chatbotId + "/settings" }, intl.formatMessage({ id: "R1XcWk", defaultMessage: "Bot Settings" }))),
            featureFlags.PRIVACY_POLICY && limits.PRIVACY_POLICY_ENABLED && (React.createElement("li", { className: "sub-menu__item " + (route === 'privacy-policies' ? 'active' : '') },
                React.createElement(Link, { to: "/dashboard/ad/chatbots/" + chatbotId + "/settings/privacy-policies" }, intl.formatMessage({ id: "vx0nkZ", defaultMessage: "Privacy Policy" }))))),
        React.createElement("ul", { className: "menu-group" },
            React.createElement("li", { className: "title" }, intl.formatMessage({ id: "zHxWb3", defaultMessage: "Site Embeds" })),
            limits.AGGREGATION_CODE_ENABLED && (React.createElement("li", { className: route === 'aggregate' ? 'active' : '' },
                React.createElement(Link, { to: "/dashboard/ad/chatbots/" + chatbotId + "/settings/aggregate" }, intl.formatMessage({ id: "C92nHw", defaultMessage: "Aggregation Code" })))),
            settings.activeChatbot.page.webchat_app_id ? (React.createElement("li", { className: route === 'webchat_setting' ? 'active' : '' },
                React.createElement(Link, { to: "/dashboard/ad/chatbots/" + chatbotId + "/settings/webchat_setting" }, intl.formatMessage({ id: "gYYyBx", defaultMessage: "WEBCHAT SETTINGS" })))) : null)));
}
export default injectIntl(SubMenu);
var templateObject_1;
