import { Tooltip } from '@zeals-co-ltd/washi-components';
import React from 'react';
import { NotRequiredField } from './FormAutomationStepItem';
import { OptionsText } from './FormAutomationStepItem.styles';
export default function NavigationWaitStep(_a) {
    var step = _a.step, intl = _a.intl, handleModal = _a.handleModal;
    var navigationWaitAction = step.navigationWaitAction;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "field not-required" },
            React.createElement(NotRequiredField, null)),
        React.createElement("div", { className: "css-selector not-required" },
            React.createElement(NotRequiredField, null)),
        React.createElement("div", { className: "type not-required" },
            React.createElement(NotRequiredField, null)),
        React.createElement("div", { "data-testid": "FormAutomationStepItem-OptionsButton", role: "button", tabIndex: 0, className: "options", onClick: function () { return handleModal(true, step, null, navigationWaitAction); } },
            React.createElement(Tooltip, { arrow: true, title: navigationWaitAction.waitS + " " + intl.formatMessage({ id: "8TQoLR", defaultMessage: "seconds" }) },
                React.createElement(OptionsText, null, navigationWaitAction.waitS + " " + intl.formatMessage({ id: "8TQoLR", defaultMessage: "seconds" }))),
            React.createElement("span", { className: "adicon_edit_after" }))));
}
