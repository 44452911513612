import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
/**
 * This is a null component to inject react-router-dom history as a global object on window
 * Why:
 *    a: to be able to make navigation outside of react-router-dom context for example in redux action
 *    b: in react-router-dom v5 custom BrowserHitory not working outside of context
 *    c: to remove  ''import {browerHistory} from "react-router"'' in code
 */
var GlobalBrowserRouterHistory = function () {
    var history = useHistory();
    useEffect(function () {
        window.globalRouterHistory = history;
    }, [history]);
    return null;
};
export default GlobalBrowserRouterHistory;
