import "core-js/modules/es.symbol";
import "core-js/modules/es.array.concat";
import "core-js/modules/es.array.filter";
import "core-js/modules/es.array.map";
import "core-js/modules/es.array.slice";
import "core-js/modules/es.function.name";
import "core-js/modules/es.object.get-own-property-descriptor";
import "core-js/modules/es.object.get-own-property-descriptors";
import "core-js/modules/es.object.keys";
import "core-js/modules/web.dom-collections.for-each";
import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { scenarioLayerBlockStateWithNewScenarioProperties } from '@src/lib/helpers';
export var updateButtonItem = function updateButtonItem(state, action) {
  var messageId = action.messageId,
      buttonItemId = action.buttonItemId,
      ajaxButtonItem = action.ajaxButtonItem,
      scenarioId = action.scenarioId;
  var message = state.messages[messageId];
  var layers = ajaxButtonItem.layers,
      undefinedBlocks = ajaxButtonItem.undefined_blocks;
  var newScenarioProperties = {
    layers: layers,
    undefined_blocks: undefinedBlocks
  };
  var newScenarioLayerBlockState = scenarioLayerBlockStateWithNewScenarioProperties(state, newScenarioProperties, scenarioId);
  return _objectSpread({
    messages: _objectSpread({}, state.messages, _defineProperty({}, messageId, _objectSpread({}, message, {
      button_template: _objectSpread({}, message.button_template, {
        button_items: message.button_template.button_items.map(function (buttonItem) {
          return buttonItem.id === buttonItemId ? ajaxButtonItem : buttonItem;
        })
      })
    })))
  }, newScenarioLayerBlockState);
};
export var handleNewItem = function handleNewItem(state, action) {
  var messageIterator = action.messageIterator,
      itemIterator = action.itemIterator,
      ajaxItem = action.ajaxItem,
      messageType = action.messageType;
  var messages = state.messages;
  var template = messages[messageIterator]["".concat(messageType, "_template")];
  var itemArr = template["".concat(messageType, "_items")];
  return {
    messages: [].concat(_toConsumableArray(messages.slice(0, messageIterator)), [_objectSpread({}, messages[messageIterator], _defineProperty({}, "".concat(messageType, "_template"), _objectSpread({}, template, _defineProperty({}, "".concat(messageType, "_items"), [].concat(_toConsumableArray(itemArr.slice(0, itemIterator)), [ajaxItem], _toConsumableArray(itemArr.slice(itemIterator + 1)))))))], _toConsumableArray(messages.slice(messageIterator + 1)))
  };
};
export var updateScenarioDelivery = function updateScenarioDelivery(state, action) {
  var scenarioId = action.scenarioId,
      scenarioDelivery = action.scenarioDelivery;
  return {
    scenarios: _objectSpread({}, state.scenarios, _defineProperty({}, scenarioId, _objectSpread({}, state.scenarios[scenarioId], {
      draft: false,
      scenario_delivery: _objectSpread({}, state.scenarios[scenarioId].scenario_delivery, {}, scenarioDelivery)
    })))
  };
};
export var updateScenarioCampaignContent = function updateScenarioCampaignContent(state, action) {
  var scenarioId = action.scenarioId,
      campaignContent = action.campaignContent;
  return _objectSpread({}, state, {
    scenarios: _objectSpread({}, state.scenarios, _defineProperty({}, scenarioId, _objectSpread({}, state.scenarios[scenarioId], {
      campaign_content: _objectSpread({}, state.scenarios[scenarioId].campaign_content, {}, campaignContent)
    })))
  });
};
export var updateFreeInput = function updateFreeInput(state, action) {
  var blockId = action.blockId,
      ajaxResponse = action.ajaxResponse;
  var blocks = state.blocks;
  var targetBlock = state.blocks[blockId];
  return _objectSpread({}, state, {
    blocks: _objectSpread({}, blocks, _defineProperty({}, blockId, _objectSpread({}, targetBlock, {
      talk_trigger: ajaxResponse
    })))
  });
};
export var updateButton = function updateButton(state, action) {
  var messageId = action.messageId,
      buttonId = action.buttonId,
      blockId = action.blockId,
      name = action.name;
  var msg = state.messages[messageId];
  return _objectSpread({}, state, {
    messages: _objectSpread({}, state.messages, _defineProperty({}, messageId, _objectSpread({}, msg, {
      button_template: _objectSpread({}, msg.button_template, {
        button_items: msg.button_template.button_items.map(function (bItem) {
          if (bItem.button.id === buttonId) {
            return _objectSpread({}, bItem, {
              button: _objectSpread({}, bItem.button, {
                url: null,
                name: name,
                postback_action: _objectSpread({}, bItem.button.postback_action, {
                  block_id: blockId
                })
              })
            });
          }

          return bItem;
        })
      })
    })))
  });
};
export var updateGenericButton = function updateGenericButton(state, action) {
  var blockId = action.blockId,
      messageId = action.messageId,
      buttonId = action.buttonId,
      itemIterator = action.itemIterator,
      name = action.name;
  var msg = state.messages[messageId];
  var items = msg.generic_template.generic_items;
  var itemToUpdate = items[itemIterator];
  var itemButtonsArr = itemToUpdate.generic_item_buttons;
  return _objectSpread({}, state, {
    messages: _objectSpread({}, state.messages, _defineProperty({}, messageId, _objectSpread({}, msg, {
      generic_template: _objectSpread({}, msg.generic_template, {
        generic_items: [].concat(_toConsumableArray(items.slice(0, itemIterator)), [_objectSpread({}, itemToUpdate, {
          generic_item_buttons: itemButtonsArr.map(function (itemButton) {
            return itemButton.button.id === buttonId ? _objectSpread({}, itemButton, {
              button: _objectSpread({}, itemButton.button, {
                url: null,
                name: name,
                postback_action: _objectSpread({}, itemButton.button.postback_action, {
                  effect: 'block',
                  block_id: blockId
                })
              })
            }) : itemButton;
          })
        })], _toConsumableArray(items.slice(itemIterator + 1)))
      })
    })))
  });
};