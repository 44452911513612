var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import { colors } from '@src/colors';
import { injectIntl } from 'react-intl';
import DefaultRichMenuImagePreview from './DefaultRichMenuImagePreview';
var ContainerWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  background: ", ";\n  border: 1px solid ", ";\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n  padding: 24px;\n  position: static;\n  box-sizing: border-box;\n  border-radius: 8px;\n  flex: none;\n  order: 1;\n  align-self: stretch;\n  flex-grow: 0;\n  margin: 16px 0px;\n\n  &:hover {\n    border: 1px solid ", ";\n  }\n\n  > div:first-of-type > div:first-of-type:not([color='status_green']) {\n    margin-top: unset;\n  }\n"], ["\n  background: ", ";\n  border: 1px solid ", ";\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n  padding: 24px;\n  position: static;\n  box-sizing: border-box;\n  border-radius: 8px;\n  flex: none;\n  order: 1;\n  align-self: stretch;\n  flex-grow: 0;\n  margin: 16px 0px;\n\n  &:hover {\n    border: 1px solid ", ";\n  }\n\n  > div:first-of-type > div:first-of-type:not([color='status_green']) {\n    margin-top: unset;\n  }\n"])), colors.off_white_border, colors.border.off_white, colors.border.off_dark);
var LabelStatus = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-weight: 700;\n\n  &::before {\n    display: inline-block;\n    font-size: 0.75rem;\n    vertical-align: baseline;\n    content: '';\n    display: inline-block;\n    width: 12px;\n    height: 12px;\n    border-radius: 6px;\n    margin: 6px 8px 0 0;\n    background: ", ";\n  }\n"], ["\n  font-weight: 700;\n\n  &::before {\n    display: inline-block;\n    font-size: 0.75rem;\n    vertical-align: baseline;\n    content: '';\n    display: inline-block;\n    width: 12px;\n    height: 12px;\n    border-radius: 6px;\n    margin: 6px 8px 0 0;\n    background: ", ";\n  }\n"])), function (props) { return (props.color ? colors[props.color] : 'none'); });
var DefaultRichMenuPreview = function (_a) {
    var defaultRichMenu = _a.defaultRichMenu, intl = _a.intl;
    return (React.createElement(ContainerWrapper, { className: defaultRichMenu && defaultRichMenu.isActive ? null : 'default' }, defaultRichMenu ? (React.createElement("div", null,
        defaultRichMenu.isActive ? (React.createElement(LabelStatus, { color: "status_green", "data-testid": "DefaultRichMenuPreview_LabelStatusActive" }, intl.formatMessage({ id: "kO9qoM", defaultMessage: "Active Rich Menu" }))) : null,
        React.createElement(DefaultRichMenuImagePreview, { defaultRichMenu: defaultRichMenu }))) : (React.createElement(LabelStatus, { color: "status_grey", "data-testid": "DefaultRichMenuPreview_LabelStatusInactive" }, intl.formatMessage({ id: "EYf548", defaultMessage: "No Active Rich Menu" })))));
};
export default injectIntl(DefaultRichMenuPreview);
var templateObject_1, templateObject_2;
