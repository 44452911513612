var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState, useRef } from 'react';
import { Button, SelectFilterDropdown } from '@src/components/atoms';
import DeleteConfirmModal from '@src/components/molecules/DeleteConfirmModal';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { colors } from '@src/colors';
import { borderHighlight, centeredFlex, hoverDelete } from '@src/lib/mixins';
import { handleOpenFilterForm } from '@src/redux/fanp/actions/messageFilter';
import { mediaTypes } from '@src/lib/app-constants';
import { SelectedTab } from '@src/redux/fanp/types/rightMenu';
import { injectIntl } from 'react-intl';
var MessageFilterOptionWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin: 0 0 0 24px;\n  .row-wrapper {\n    display: flex;\n    button {\n      &:hover {\n        background-color: ", ";\n        color: ", ";\n      }\n    }\n  }\n\n  .filter-wrapper {\n    ", "\n    margin: 0 5px 0 0;\n    padding: 8px;\n    border-radius: 50px;\n    ", ";\n    border: ", ";\n    background-color: ", ";\n    .adicon_filter {\n      color: ", ";\n    }\n    &:hover {\n      ", "\n      option {\n        color: ", ";\n      }\n      .adicon_filter {\n        color: ", ";\n      }\n    }\n  }\n\n  .adicon_delete {\n    ", "\n    background-color: ", ";\n    color: ", ";\n    border-radius: 50%;\n    width: 40px;\n    ", "\n  }\n\n  .warning-container {\n    display: flex;\n    padding: 4px 8px;\n    color: ", ";\n    font-size: 12px;\n  }\n\n  .notice-message-area {\n    height: 30px;\n  }\n"], ["\n  margin: 0 0 0 24px;\n  .row-wrapper {\n    display: flex;\n    button {\n      &:hover {\n        background-color: ",
    ";\n        color: ",
    ";\n      }\n    }\n  }\n\n  .filter-wrapper {\n    ", "\n    margin: 0 5px 0 0;\n    padding: 8px;\n    border-radius: 50px;\n    ", ";\n    border: ",
    ";\n    background-color: ", ";\n    .adicon_filter {\n      color: ", ";\n    }\n    &:hover {\n      ",
    "\n      option {\n        color: ",
    ";\n      }\n      .adicon_filter {\n        color: ",
    ";\n      }\n    }\n  }\n\n  .adicon_delete {\n    ", "\n    background-color: ", ";\n    color: ", ";\n    border-radius: 50%;\n    width: 40px;\n    ", "\n  }\n\n  .warning-container {\n    display: flex;\n    padding: 4px 8px;\n    color: ", ";\n    font-size: 12px;\n  }\n\n  .notice-message-area {\n    height: 30px;\n  }\n"])), function (props) {
    return props.isDisplay
        ? colors.background.setting
        : colors.background.hover_purple;
}, function (props) {
    return props.isDisplay ? colors.white : colors.background.setting;
}, centeredFlex, function (props) { return props.highlight && borderHighlight; }, function (props) {
    return props.hasError ? "1px solid " + colors.error : 'unset';
}, colors.background.off_white_background, colors.text_navy, function (props) {
    return props.isDisplay && !props.readOnly
        ? "background-color: " + colors.background.active + ";"
        : '';
}, function (props) {
    return props.isDisplay && !props.readOnly ? colors.default_text + ";" : '';
}, function (props) {
    return props.isDisplay && !props.readOnly ? "" + colors.default_text : '';
}, centeredFlex, colors.background.off_white_background, colors.text_navy, hoverDelete, colors.error);
var DropDownContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  .message-bottom-dropdown {\n    height: 18px;\n    border: none;\n    ul {\n      width: 260px;\n      height: ", ";\n      top: 30px;\n      right: -8px;\n    }\n    li {\n      &:nth-child(2) {\n        padding: 4px;\n      }\n    }\n  }\n  .adicon_arrow_down_after {\n    top: 3px;\n    right: 8px;\n  }\n  .message_bottom_input {\n    border: none;\n    height: 35px;\n  }\n"], ["\n  display: flex;\n  align-items: center;\n  .message-bottom-dropdown {\n    height: 18px;\n    border: none;\n    ul {\n      width: 260px;\n      height: ", ";\n      top: 30px;\n      right: -8px;\n    }\n    li {\n      &:nth-child(2) {\n        padding: 4px;\n      }\n    }\n  }\n  .adicon_arrow_down_after {\n    top: 3px;\n    right: 8px;\n  }\n  .message_bottom_input {\n    border: none;\n    height: 35px;\n  }\n"])), function (props) { return (props.hasFilters ? '150px' : '100px'); });
var MessageFilterOption = function (_a) {
    var _b;
    var messageSectionId = _a.messageSectionId, chatbotId = _a.chatbotId, scenarioId = _a.scenarioId, readOnly = _a.readOnly, messageId = _a.messageId, message = _a.message, messageFilter = _a.messageFilter, setFilterToMessage = _a.setFilterToMessage, handleBottomTabClick = _a.handleBottomTabClick, handleOpenFilterForm = _a.handleOpenFilterForm, highlight = _a.highlight, onRemove = _a.onRemove, isDisplay = _a.isDisplay, showMessageSectionMessage = _a.showMessageSectionMessage, hideMessageAction = _a.hideMessageAction, intl = _a.intl;
    var _c = __read(useState(false), 2), isDeleteMessageMoalOpen = _c[0], setIsDeleteMessageMoalOpen = _c[1];
    var showHideMessage = function () {
        if (!isDisplay) {
            showMessageSectionMessage(chatbotId, scenarioId, messageId, messageSectionId);
        }
        else {
            hideMessageAction(message);
        }
    };
    var dropdownRef = useRef();
    var handleNoneFilterButtonClick = function () {
        setFilterToMessage(null, null, messageId);
        dropdownRef.current.toggleMenuCallback();
    };
    var handleCreateFilterButtonClick = function () {
        handleBottomTabClick(SelectedTab.MessageFilter);
        handleOpenFilterForm(true);
        dropdownRef.current.toggleMenuCallback();
    };
    var messageFilters = messageFilter.messageFilters;
    var _d = message.medium || {}, type = _d.type, url = _d.url, preview_url = _d.preview_url;
    var hasError = !readOnly && !message.filter && isDisplay;
    return (React.createElement(MessageFilterOptionWrapper, { highlight: highlight, hasError: hasError, isDisplay: isDisplay, readOnly: readOnly },
        React.createElement("div", { className: "row-wrapper" },
            React.createElement(Button, { margin: "0 5px 0 0", className: "" + (isDisplay ? 'adicon_arrow_up' : 'adicon_arrow_down'), text: "" + (isDisplay
                    ? intl.formatMessage({ id: "VA/Z1S", defaultMessage: "Hide" })
                    : intl.formatMessage({ id: "64NAin", defaultMessage: "Display" })), backgroundColor: "" + (isDisplay
                    ? colors.background.main_color
                    : colors.background.off_white_background), color: isDisplay ? colors.white : colors.text_navy, minWidth: 80, width: 80, onClick: showHideMessage }),
            React.createElement("div", { className: "filter-wrapper" },
                React.createElement(DropDownContainer, { hasFilters: Boolean(messageFilters.length) },
                    React.createElement("div", { className: "adicon_filter" }),
                    React.createElement(SelectFilterDropdown, { className: "message-bottom-dropdown", value: ((_b = message.filter) === null || _b === void 0 ? void 0 : _b.id) || message.filter_id, items: messageFilters.map(function (_a) {
                            var name = _a.name, id = _a.id;
                            return ({
                                value: id,
                                label: name,
                            });
                        }), onChange: function (_a) {
                            var value = _a.value, label = _a.label;
                            return setFilterToMessage(value, label, messageId);
                        }, itemType: intl.formatMessage({ id: "axD4o8", defaultMessage: "No Filter" }), ref: dropdownRef, readOnly: readOnly || !isDisplay, showNoSearchResults: true, searchBox: { placeholder: 'Search' } },
                        React.createElement("button", { type: "button", className: "adicon_plus02 above_search_box", onClick: handleCreateFilterButtonClick }, intl.formatMessage({ id: "EWtLqR", defaultMessage: "Create a new Filter" })),
                        React.createElement("button", { type: "button", className: "filter-data-list-item", onClick: handleNoneFilterButtonClick }, intl.formatMessage({ id: "axD4o8", defaultMessage: "No Filter" }))))),
            React.createElement("span", { role: "button", tabIndex: 0, "aria-label": "Delete", className: "adicon_delete", onClick: function () { return setIsDeleteMessageMoalOpen(!isDeleteMessageMoalOpen); }, "data-testid": "MessageFilterOption_DeleteMessageModalButton" })),
        React.createElement("div", { className: "notice-message-area" },
            hasError && (React.createElement("span", { className: "message-wrapper error" },
                React.createElement("span", null, intl.formatMessage({ id: "i7rO1A", defaultMessage: "This Filter is already being used" })))),
            type === mediaTypes.VIDEO && (!url || !preview_url) && (React.createElement("span", { className: "message-wrapper error" },
                React.createElement("span", null, intl.formatMessage({ id: "CFR8NT", defaultMessage: "Video and Preview are required" })))),
            isDeleteMessageMoalOpen && (React.createElement(DeleteConfirmModal, { text: intl.formatMessage({ id: "usNPpx", defaultMessage: "Are you sure you want to delete this item ?" }), onCancel: function () {
                    return setIsDeleteMessageMoalOpen(!isDeleteMessageMoalOpen);
                }, onConfirm: function () { return onRemove && onRemove(message); } })))));
};
export default connect(function () { return ({}); }, {
    handleOpenFilterForm: handleOpenFilterForm,
})(injectIntl(MessageFilterOption));
var templateObject_1, templateObject_2;
