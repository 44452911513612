import React from 'react';
import { Provider, ErrorBoundary } from '@rollbar/react';
import ErrorScreen from '@src/components/atoms/ErrorScreen';
var rollbarConfig = {
    accessToken: process.env.ROllBAR_ACCESS_TOKEN,
    environment: process.env.NODE_ENV,
    enabled: process.env.NODE_ENV !== 'development',
};
var Rollbar = function (props) {
    var children = props.children, _a = props.fallbackUI, fallbackUI = _a === void 0 ? ErrorScreen : _a;
    return (React.createElement(Provider, { config: rollbarConfig },
        React.createElement(ErrorBoundary, { fallbackUI: fallbackUI }, children)));
};
export default Rollbar;
