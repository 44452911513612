import _extends from "@babel/runtime/helpers/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { Switch, ProfilePic } from '@src/components/atoms';
import { colors } from '@src/colors';
import { transitions } from '@src/lib/animations';
import featureFlags from '@src/lib/feature_flags/featureFlags';
import PropTypes from 'prop-types';
import { hoverCss } from '@src/lib/mixins';
var UserInputSwitch = styled(Switch).withConfig({
  displayName: "UserInputSwitch",
  componentId: "sc-1e47d79-0"
})(["margin:0 !important;cursor:pointer;"]);
var UserWrapper = styled.div.withConfig({
  displayName: "UserWrapper",
  componentId: "sc-1e47d79-1"
})(["font-size:14px;min-height:70px;display:flex;align-items:stretch;justify-content:space-between;padding:10px;transition:", ";background:", ";border-bottom:1px solid ", ";.new-message{font-size:0.7rem;color:", ";}.message-details{margin:0 auto 0 10px;display:flex;flex-direction:column;padding-bottom:10px;.last-message{font-size:9px;word-break:break-all;max-height:4.6em;overflow:auto;color:", ";}.important{padding:4px 8px;background-color:", ";border-radius:5px;font-size:10px;color:", ";font-weight:bold;width:fit-content;margin-top:auto;}}.last-message-details{margin-left:10px;font-size:11px;color:", ";display:flex;flex-direction:column;align-items:flex-end;.adicon_replay{font-size:24px;color:", ";margin:10px 0;", "}}&:hover{cursor:pointer;background:", ";}"], transitions.normal, function (props) {
  return props.isSelected ? colors.background.off_white_background : props.client_read === true ? colors.background.hover_purple : 'none';
}, colors.border.default, colors.quick_reply, colors.default_text, colors.accent_color, colors.white, colors.icon, colors.default_text, hoverCss, colors.background.light_purple);

var User = function User(_ref) {
  var last_name = _ref.last_name,
      first_name = _ref.first_name,
      profile_photo_url = _ref.profile_photo_url,
      last_message = _ref.last_message,
      important = _ref.important,
      auto_reply = _ref.auto_reply,
      isSelected = _ref.isSelected,
      handleClick = _ref.handleClick,
      rest = _objectWithoutProperties(_ref, ["last_name", "first_name", "profile_photo_url", "last_message", "important", "auto_reply", "isSelected", "handleClick"]);

  return React.createElement(UserWrapper, {
    client_read: !(last_message === null || last_message === void 0 ? void 0 : last_message.client_read),
    isSelected: isSelected,
    onClick: handleClick
  }, React.createElement(ProfilePic, _extends({
    imageUrl: profile_photo_url,
    width: "50px",
    minWidth: "50px",
    height: "50px",
    style: {
      alignSelf: 'center'
    }
  }, rest)), React.createElement("div", {
    className: "message-details"
  }, last_name, " ", first_name, React.createElement("div", {
    className: "new-message"
  }, (last_message === null || last_message === void 0 ? void 0 : last_message.client_read) ? null : 'New Message'), React.createElement("div", {
    className: "last-message"
  }, last_message === null || last_message === void 0 ? void 0 : last_message.content), important ? React.createElement("div", {
    className: "important"
  }, "\u91CD\u8981") : null), last_message && React.createElement("div", {
    className: "last-message-details"
  }, moment(last_message.created_at).format('MM/DD HH:mm'), last_message.end_user_read ? React.createElement(ProfilePic, _extends({
    imageUrl: profile_photo_url || '/images/not_connect.png',
    width: "25px",
    height: "25px",
    borderWidth: "2px",
    margin: "10px 0"
  }, rest)) : React.createElement("div", {
    className: "adicon_replay"
  }), featureFlags.TALK_ROOM_AUTO_REPLY_SHOWN && React.createElement(UserInputSwitch, {
    isActive: auto_reply
  })));
};

User.propTypes = {
  last_name: PropTypes.string,
  first_name: PropTypes.string,
  profile_photo_url: PropTypes.string,
  last_message: PropTypes.object,
  important: PropTypes.bool,
  auto_reply: PropTypes.bool,
  isSelected: PropTypes.bool,
  handleClick: PropTypes.func
};
export default User;