var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Input } from '@src/components/atoms';
import buttonTypes from '@src/lib/buttonTypes';
import { fetchEmailPassword } from '@src/lib/adHelpers';
import { LoginWrapper } from './Login';
import * as pathLinks from '@src/routes/Fanp/pathLinks';
var LoginResetPassword = function () {
    var _a = __read(useState(''), 2), email = _a[0], setEmail = _a[1];
    var _b = __read(useState(''), 2), errorMsg = _b[0], setErrorMsg = _b[1];
    var _c = __read(useState(''), 2), successMsg = _c[0], setSuccessMsg = _c[1];
    var browserHistory = useHistory();
    function handleSubmit(e) {
        e.preventDefault();
        fetchEmailPassword(email)
            .then(function (_a) {
            var ok = _a.ok, msg = _a.msg;
            if (ok) {
                setSuccessMsg('メールは送信されました！');
                setErrorMsg('');
                setTimeout(function () { return browserHistory.push(pathLinks.dashboardLogin); }, 3000);
            }
            else {
                setSuccessMsg('');
                setErrorMsg(msg || 'エラーが発生しました');
            }
        })
            .catch(function (e) { return console.error(e); });
    }
    var hasError = Boolean(errorMsg);
    return (React.createElement(LoginWrapper, null,
        React.createElement("div", { className: "login-panel-container" },
            React.createElement("h2", null,
                "\u30D1\u30B9\u30EF\u30FC\u30C9\u3092\u518D\u8A2D\u5B9A\u3057\u307E\u3059\u304B\uFF1F",
                React.createElement("br", null),
                "Reset password?"),
            React.createElement("form", { onSubmit: handleSubmit },
                React.createElement("p", null,
                    "\u304A\u5BA2\u69D8\u306E\u30E1\u30FC\u30EB\u30A2\u30C9\u30EC\u30B9\u3092\u5165\u529B\u3057\u3066\u4E0B\u3055\u3044\u3002",
                    React.createElement("br", null),
                    "\u30D1\u30B9\u30EF\u30FC\u30C9\u3092\u30EA\u30BB\u30C3\u30C8\u3067\u304D\u308B\u30E1\u30FC\u30EB\u304C\u304A\u5BA2\u69D8\u306E\u5143\u306B\u9001\u4FE1\u3055\u308C\u307E\u3059\u3002"),
                React.createElement("p", null,
                    "Please enter your email address.",
                    React.createElement("br", null),
                    "You will receive a link to reset your password."),
                React.createElement("div", { className: "form-body" },
                    React.createElement("label", null, "\u30E1\u30FC\u30EB\u30A2\u30C9\u30EC\u30B9 / email address"),
                    React.createElement(Input, { className: "login_form", placeholder: "\u30E1\u30FC\u30EB\u30A2\u30C9\u30EC\u30B9", hasError: hasError, value: email, onChange: function (e) { return setEmail(e.target.value); } })),
                React.createElement("div", { className: "submit-body" },
                    React.createElement(Button, { buttonType: buttonTypes.MAIN, text: "\u78BA\u8A8D\u30E1\u30FC\u30EB\u3092\u9001\u4FE1 / Send confirmation email", height: 70 })),
                errorMsg && React.createElement("div", { className: "error" }, errorMsg),
                successMsg && React.createElement("div", { className: "success" }, successMsg)))));
};
export default LoginResetPassword;
