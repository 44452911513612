var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React from 'react';
import { injectIntl } from 'react-intl';
import { string } from 'yup';
import { ModalPortal, Input, CharacterCount } from '@src/components/atoms';
import { Modal } from '@src/components/molecules';
import { urlReg } from '@src/lib/helpers/validate';
import modalTypes from '@src/lib/modalTypes';
import { addMaxLength } from '@src/lib/helpers';
var urlSchema = function (intl, platform, nullable) {
    return addMaxLength(string().matches(urlReg, {
        message: intl.formatMessage({ id: "aiDaR5", defaultMessage: "Please enter a valid URL." }),
        excludeEmptyString: nullable,
    }), intl, 'common.url', platform);
};
var ChangeUrlModal = function (_a) {
    var platform = _a.platform, intl = _a.intl, onConfirm = _a.onConfirm, initialUrl = _a.initialUrl, _b = _a.modalTitle, modalTitle = _b === void 0 ? intl.formatMessage({ id: "B9mB91", defaultMessage: "Image URL" }) : _b, onCancel = _a.onCancel, _c = _a.nullable, nullable = _c === void 0 ? false : _c;
    var _d = __read(React.useState({
        url: initialUrl || '',
        errorMessage: null,
    }), 2), state = _d[0], setState = _d[1];
    React.useEffect(function () {
        urlSchema(intl, platform, nullable)
            .validate(state.url)
            .then(function () { return setState(__assign(__assign({}, state), { errorMessage: '' })); })
            .catch(function (e) { return setState(__assign(__assign({}, state), { errorMessage: e.message })); });
    }, [state.url]);
    var handleInput = function (e) {
        return setState(__assign(__assign({}, state), { url: e.target.value }));
    };
    var handleConfirm = function () {
        var url = state.url;
        if (onConfirm) {
            onConfirm(url);
        }
    };
    return (React.createElement(ModalPortal, null,
        React.createElement(Modal, { "data-testid": "change-url-modal", text: modalTitle, onCancel: onCancel, onConfirm: handleConfirm, type: modalTypes.SELECT, hasError: state.errorMessage },
            React.createElement("div", { className: "message-wrapper primary-label", style: { marginBottom: '12px' } }, intl.formatMessage({ id: "s7ZM0P", defaultMessage: "Please enter a URL" })),
            React.createElement(Input, { type: "text", placeholder: "https://...", onChange: handleInput, value: state.url, "data-testid": "ChangeUrlModal-UrlInput" },
                React.createElement(CharacterCount, { value: state.url, platform: platform, path: "common.url", paddingTop: "2px" })),
            state.errorMessage && (React.createElement("div", { className: "message-wrapper error", style: { margin: '5px 0' } }, state.errorMessage)))));
};
export default injectIntl(ChangeUrlModal);
