import { scenario } from '@src/redux/fanp/constants';
var REMOVE_FULFILLED = scenario.REMOVE_FULFILLED,
    UPDATE_FULFILLED = scenario.UPDATE_FULFILLED;
var initialState = {};
export default (function () {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    // REMOVE REDUCERS
    case REMOVE_FULFILLED:
      return state;
    // UPDATE MESSAGE TEMPLATE

    case UPDATE_FULFILLED:
      return state;
    // EDIT SCENARIO REDUCER CASES

    default:
      return state;
  }
});