import en from './compiled_lang/en.json';
import ja from './compiled_lang/ja.json';
export default function loadLocaleData(locale) {
    switch (locale) {
        case 'ja':
            return ja;
        default:
            return en;
    }
}
