var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import { colors } from '@src/colors';
import { injectIntl } from 'react-intl';
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin-top: 12px;\n  border: 1px solid ", ";\n  border-radius: 5px;\n  width: 100%;\n  min-height: 40px;\n  max-height: 160px;\n  overflow: scroll;\n  padding: 0 8px;\n  .notice-message {\n    color: ", ";\n    font-size: 12px;\n    font-weight: bold;\n    margin: 16px 0 16px 8px;\n  }\n"], ["\n  margin-top: 12px;\n  border: 1px solid ", ";\n  border-radius: 5px;\n  width: 100%;\n  min-height: 40px;\n  max-height: 160px;\n  overflow: scroll;\n  padding: 0 8px;\n  .notice-message {\n    color: ", ";\n    font-size: 12px;\n    font-weight: bold;\n    margin: 16px 0 16px 8px;\n  }\n"])), colors.border.default, colors.placeholder);
var SelectedTagContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: inline-flex;\n  margin: 8px 0;\n  &:empty {\n    display: none;\n  }\n  .tag-label {\n    margin: 0px 8px;\n  }\n  .adicon_close,\n  .adicon_tag {\n    font-size: 18px;\n    color: ", ";\n  }\n  .condition-label {\n    margin-right: 8px;\n  }\n"], ["\n  display: inline-flex;\n  margin: 8px 0;\n  &:empty {\n    display: none;\n  }\n  .tag-label {\n    margin: 0px 8px;\n  }\n  .adicon_close,\n  .adicon_tag {\n    font-size: 18px;\n    color: ", ";\n  }\n  .condition-label {\n    margin-right: 8px;\n  }\n"])), colors.white);
var SelectedTag = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  background: ", ";\n  border-radius: 5px;\n  margin-right: 8px;\n  padding: 0 8px;\n  color: #fff;\n  font-size: 12px;\n  cursor: pointer;\n  width: fit-content;\n  max-height: 40px;\n  white-space: nowrap;\n"], ["\n  background: ", ";\n  border-radius: 5px;\n  margin-right: 8px;\n  padding: 0 8px;\n  color: #fff;\n  font-size: 12px;\n  cursor: pointer;\n  width: fit-content;\n  max-height: 40px;\n  white-space: nowrap;\n"])), function (props) { return props.bgColor; });
var SelectedDeliveryTagArea = function (_a) {
    var attributeQuestions = _a.attributeQuestions, searchFilter = _a.searchFilter, selectedTags = _a.selectedTags, handleDeleteSelectTag = _a.handleDeleteSelectTag, intl = _a.intl;
    var findTagGroupLabel = function (tag) {
        var attributeQuestionId = tag.attribute_question_id;
        var targetQuestion = attributeQuestions === null || attributeQuestions === void 0 ? void 0 : attributeQuestions.find(function (attributeQuestion) { return attributeQuestion.id === attributeQuestionId; });
        if (targetQuestion) {
            var label = targetQuestion.label;
            return label || 'None';
        }
        return 'None';
    };
    return (React.createElement(Container, null, selectedTags.length ? (React.createElement(SelectedTagContainer, null, selectedTags.map(function (tag, i) {
        var filterCondition = i + 1 === selectedTags.length ? '' : searchFilter;
        return (React.createElement(React.Fragment, null,
            React.createElement(SelectedTag, { key: "tag-" + tag.id, className: "selected-tag", bgColor: tag.not ? colors.error : colors.accent_color },
                React.createElement("div", null, findTagGroupLabel(tag)),
                React.createElement("span", { className: "adicon_tag" }),
                React.createElement("span", { className: "tag-label" }, tag.label),
                React.createElement("span", { className: "adicon_close", role: "button", tabIndex: 0, onClick: function () { return handleDeleteSelectTag(tag.id); } })),
            React.createElement("span", { className: "condition-label" }, filterCondition)));
    }))) : (React.createElement("div", { className: "notice-message" }, intl.formatMessage({ id: "/zonxm", defaultMessage: "No Tag is being selected." })))));
};
export default injectIntl(SelectedDeliveryTagArea);
var templateObject_1, templateObject_2, templateObject_3;
