export default {
    BOT_DEACTIVATION: false,
    NEW_SCENARIO_FROM_TEMPLATE: false,
    PERSISTENT_MENU_SHOWN: false,
    PRIVACY_POLICY: true,
    SCENARIO_MAP_V1_SHOWN: false,
    SCENARIO_MAP_V2_SHOWN: true,
    TALK_ROOM_AUTO_REPLY_SHOWN: false,
    TALK_ROOM_SEND_MESSAGES: false,
    TEL_MAILTO_UPDATE_BUTTON: true,
    TRACK_PUSH_V1_SHOWN: false,
    TRACK_PUSH_V2_SHOWN: true,
    RPA_V1: true,
    RPA_CONFIG_MOCK_API: true,
    RPA_FORM_BUILDER: true,
    UUD_FIELD_ATTRIBUTES: true,
    UUD_USER_SEGMENT: true,
    UUD_USER_LABEL: true,
    UUD_USER_DATABASE_SEGMENT: false,
};
