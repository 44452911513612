import "core-js/modules/es.array.iterator";
import "core-js/modules/es.array.map";
import "core-js/modules/es.object.to-string";
import "core-js/modules/web.dom-collections.iterator";
import React from 'react';
import { InputWrapper, Switch, FormWrapper } from '@src/components/atoms';
import { Field } from 'formik';
import CustomField from '@src/components/molecules/CustomField';

var PluginBannerForm = function PluginBannerForm(_ref) {
  var handleChange = _ref.handleChange,
      handleChatbotIdChange = _ref.handleChatbotIdChange,
      selectChatbotOptions = _ref.selectChatbotOptions,
      errors = _ref.errors,
      touched = _ref.touched,
      values = _ref.values;
  return React.createElement(FormWrapper, null, React.createElement("section", null, "Chatbot", React.createElement("div", {
    className: "field"
  }, React.createElement("p", {
    className: "label"
  }, "Chatbot\u3092\u9078\u629E"), React.createElement(InputWrapper, {
    hasError: touched.chatbot_id && errors.chatbot_id
  }, React.createElement(Field, {
    name: "chatbot_id",
    component: "select",
    onChange: handleChatbotIdChange
  }, React.createElement("option", {
    value: ""
  }, "\u30C1\u30E3\u30C3\u30C8\u30DC\u30C3\u30C8\u3092\u9078\u629E\u3057\u3066\u304F\u3060\u3055\u3044"), selectChatbotOptions.map(function (chatbotOption) {
    return React.createElement("option", {
      key: chatbotOption.id,
      value: chatbotOption.value
    }, chatbotOption.value, ": ", chatbotOption.label);
  }))), touched.chatbot_id && errors.chatbot_id && React.createElement("div", {
    className: "message-wrapper error"
  }, errors.chatbot_id))), React.createElement("section", null, "Basic", React.createElement(CustomField, {
    fieldLabel: "\u30D0\u30CA\u30FC\u540D",
    type: "text",
    name: "title",
    placeholder: "\u30D0\u30CA\u30FC\u540D",
    hasError: touched.title && errors.title,
    onChange: handleChange
  })), React.createElement("section", null, "URL", React.createElement(CustomField, {
    fieldLabel: "\u753B\u50CFURL",
    name: "image_url",
    placeholder: "\u753B\u50CFURL",
    hasError: touched.image_url && errors.image_url,
    onChange: handleChange
  }), React.createElement(CustomField, {
    fieldLabel: "\u9077\u79FB\u5148URL",
    name: "url",
    placeholder: "\u9077\u79FB\u5148URL",
    hasError: touched.url && errors.url,
    onChange: handleChange
  })), React.createElement("section", null, "Setting", React.createElement("div", {
    className: "plugin-banner-switch-field"
  }, React.createElement("p", {
    className: "label"
  }, "\u8868\u793A\u306E\u9593\u9694\u5236\u5FA1"), React.createElement(Switch, {
    className: "switch",
    name: "display_interval_control_flag",
    isActive: values.display_interval_control_flag,
    onChange: handleChange
  }))));
};

export default PluginBannerForm;