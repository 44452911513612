import "core-js/modules/es.symbol";
import "core-js/modules/es.array.concat";
import "core-js/modules/es.array.fill";
import "core-js/modules/es.array.filter";
import "core-js/modules/es.array.map";
import "core-js/modules/es.number.constructor";
import "core-js/modules/es.object.get-own-property-descriptor";
import "core-js/modules/es.object.get-own-property-descriptors";
import "core-js/modules/es.object.keys";
import "core-js/modules/web.dom-collections.for-each";
import _extends from "@babel/runtime/helpers/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import React, { Fragment } from 'react';
import styled from 'styled-components';
import omit from 'lodash/omit';
import { colors } from '@src/colors';
import imageMapTemplates from '@src/lib/ImageMapTemplates';
import { hoverActionBasic } from '@src/lib/mixins';
import { mediaPlaceholderIcons, mediaTypes, messageTypes, TAB_0_COLOR, IMAGE_MAP_TEMPLATE_NUMBER_NO_ACTION, IMAGE_MAP_TEMPLATE_NUMBER_NO_ACTION_GROUP_INDEX, IMAGE_MAP_TEMPLATE_NUMBER_COUNTRY_MAP } from '@src/lib/app-constants';
import { transitions } from '@src/lib/animations';
import { styledTemplate, customImageMapTemplateFrame } from '@src/lib/helpers';
import ImageMapArea from '@src/components/molecules/ImageMapArea'; // This template grid has same logic with rich menu, all of it move to
// styledTemplate method on helper

var TemplateGrid = styled.div.withConfig({
  displayName: "TemplateGrid",
  componentId: "sc-6hnusp-0"
})(["position:relative;width:340px;height:244px;background:", ";background-repeat:no-repeat;background-position:center;background-size:contain;> div:not(.adicon_delete):not(.", "):not(.video_text).not(.no-action){font-size:0.8rem;padding:0 5px 5px;color:", ";display:flex;flex-direction:column;justify-content:flex-end;}.no-action{font-size:14px;font-weight:700;position:absolute;left:12px;top:12px;}", " .template-3{.area-info{width:23px;left:145px;white-space:break-spaces !important;}}.template-4{.area-1,.area-2,.area-3{.area-info{font-size:8px;}}}"], function (props) {
  return "url(".concat(props.imageMapVideo && props.imageMapVideo.preview_image_url || props.imageUrl, ")") || 'unset';
}, mediaPlaceholderIcons[mediaTypes.VIDEO], colors.white, function (props) {
  return styledTemplate(_objectSpread({}, props, {
    messageType: messageTypes.IMAGE_MAP,
    scaleByWidth: !props.isPreview,
    customTemplateFrame: customImageMapTemplateFrame(props)
  }));
});
var ImageMapVideoIcon = styled.div.attrs({
  className: mediaPlaceholderIcons[mediaTypes.VIDEO]
}).withConfig({
  displayName: "ImageMapVideoIcon",
  componentId: "sc-6hnusp-1"
})(["position:absolute;font-size:1.5rem;top:0px;width:25px;height:25px;color:", ";background:", ";margin:8px;border-radius:5px;&:before{position:absolute;}"], colors.white, colors.background.pale_white);
var ImageMapVideoText = styled.div.attrs({
  className: 'video_text'
}).withConfig({
  displayName: "ImageMapVideoText",
  componentId: "sc-6hnusp-2"
})(["width:100%;bottom:0px;font-size:0.8rem;padding:0 5px 5px;color:", ";position:absolute;background:transparent;> div{padding-top:5px;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;}"], colors.white);

var ImageMapTemplate = function ImageMapTemplate(_ref) {
  var _imageMapTemplates$IM, _imageMapTemplates$IM2;

  var templateNum = _ref.templateNum,
      templateWidth = _ref.templateWidth,
      templateHeight = _ref.templateHeight,
      imageMapAreas = _ref.imageMapAreas,
      isPreview = _ref.isPreview,
      onSubmitAreaUpdate = _ref.onSubmitAreaUpdate,
      onSubmitVideoUpdate = _ref.onSubmitVideoUpdate,
      platform = _ref.platform,
      rest = _objectWithoutProperties(_ref, ["templateNum", "templateWidth", "templateHeight", "imageMapAreas", "isPreview", "onSubmitAreaUpdate", "onSubmitVideoUpdate", "platform"]);

  var ImageMapVideo = function ImageMapVideo(props) {
    return React.createElement(React.Fragment, null, React.createElement(ImageMapVideoIcon, null), React.createElement(ImageMapVideoText, null, React.createElement("div", null, props.label), React.createElement("div", null, props.link_uri)));
  };

  var template = imageMapTemplates.reduce(function (arr, cur) {
    return _objectSpread({}, arr, {}, cur.templates);
  }, {})[templateNum];
  var previewImageMapAreaArr = Array(template.areas.length).fill(0);
  var noActionImageMapAreaArr = ((_imageMapTemplates$IM = imageMapTemplates[IMAGE_MAP_TEMPLATE_NUMBER_NO_ACTION_GROUP_INDEX]) === null || _imageMapTemplates$IM === void 0 ? void 0 : (_imageMapTemplates$IM2 = _imageMapTemplates$IM.templates[templateNum]) === null || _imageMapTemplates$IM2 === void 0 ? void 0 : _imageMapTemplates$IM2.areas) || previewImageMapAreaArr;
  var isNoAction = templateNum === IMAGE_MAP_TEMPLATE_NUMBER_NO_ACTION;
  var imageMapAreaArr = isPreview ? previewImageMapAreaArr : isNoAction ? noActionImageMapAreaArr : imageMapAreas;
  return React.createElement(TemplateGrid, _extends({
    templateWidth: templateWidth,
    templateHeight: templateHeight,
    areas: template.areas,
    isPreview: isPreview,
    templateNum: templateNum
  }, omit(rest, 'onSubmitUploadImage')), isPreview && Number(templateNum) === IMAGE_MAP_TEMPLATE_NUMBER_NO_ACTION && React.createElement("div", {
    className: "no-action"
  }, "No Action"), React.createElement("div", {
    className: "template-areas ".concat(isPreview ? "template-".concat(templateNum) : '')
  }, imageMapAreaArr.map(function (ima, i) {
    return React.createElement(ImageMapArea, _extends({
      areaIndex: i,
      key: "image-map-template-".concat(ima.id || templateNum, "-").concat(i),
      area: template.areas[i],
      imageMapArea: ima,
      templateNum: templateNum,
      isPreview: isPreview,
      imageMapAreaArr: imageMapAreaArr,
      onSubmitAreaUpdate: onSubmitAreaUpdate,
      onSubmitVideoUpdate: onSubmitVideoUpdate,
      imageMapVideo: rest.imageMapVideo,
      platform: platform
    }, rest));
  }), templateNum === 1 && rest.imageMapVideo && React.createElement(ImageMapVideo, rest.imageMapVideo)));
};

export default ImageMapTemplate;