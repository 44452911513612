import "core-js/modules/es.array.concat";

var collection = function collection(_ref) {
  var chatbotId = _ref.chatbotId;
  return "/chatbots/".concat(chatbotId, "/line_flex_messages/templates");
};

var member = function member(_ref2) {
  var chatbotId = _ref2.chatbotId,
      id = _ref2.id;
  return "/chatbots/".concat(chatbotId, "/line_flex_messages/templates/").concat(id);
};

var insertTemplate = function insertTemplate(chatbotId, id) {
  return "".concat(member(chatbotId, id), "/flex_messages");
};

export default {
  collection: collection,
  member: member,
  insertTemplate: insertTemplate
};