var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
var FlashModalContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: block;\n  position: fixed;\n  top: 1rem;\n  right: 1rem;\n  z-index: ", ";\n"], ["\n  display: block;\n  position: fixed;\n  top: 1rem;\n  right: 1rem;\n  z-index: ", ";\n"])), function (props) { return props.zIndex || 98; });
/**
 * A React portal for Flashes. To be used in conjunction with the atoms/Flash component.
 * Flashes will appear to the top-right on top of the view
 * @param {React.ReactNode} [children] - ReactNode or Array ReactNode
 * @param {number} [zIndex=99] - The z-index of this component
 */
var FlashPortal = /** @class */ (function (_super) {
    __extends(FlashPortal, _super);
    function FlashPortal() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.appRoot = document.getElementById('root');
        _this.el = document.createElement('div');
        return _this;
    }
    FlashPortal.prototype.componentDidMount = function () {
        this.appRoot.appendChild(this.el);
    };
    FlashPortal.prototype.componentWillUnmount = function () {
        this.appRoot.removeChild(this.el);
    };
    FlashPortal.parentIsPortal = function (node) {
        if (!node.parentNode)
            return false;
        if (node.parentNode.id === 'flash-portal')
            return true;
        return FlashPortal.parentIsPortal(node.parentNode);
    };
    FlashPortal.prototype.render = function () {
        var _a = this.props, children = _a.children, zIndex = _a.zIndex;
        return ReactDOM.createPortal(React.createElement("span", { id: "flash-portal" },
            React.createElement(FlashModalContainer, { zIndex: zIndex }, children)), this.el);
    };
    return FlashPortal;
}(Component));
export default FlashPortal;
var templateObject_1;
