var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useCallback, useEffect, useState, } from 'react';
import { injectIntl } from 'react-intl';
import styled from 'styled-components';
import { Composition } from 'atomic-layout';
import { Droppable, Draggable, DragDropContext, } from 'react-beautiful-dnd';
import { colors } from '@src/colors';
import FormAutomationStepItem, { StepItemsHeader, } from '@src/components/molecules/FormAutomationStepItems/FormAutomationStepItem';
import { createStyles, makeStyles } from '@mui/styles';
import { FormAutomationStepAction } from 'zeals-protobuf/zeals/web/entities/form_automation_pb';
import FormAutomationTextInputActionModal from '@src/components/organisms/fanp/FormAutomationTextInputActionModal';
import FormAutomationSelectActionModal from '@src/components/organisms/fanp/FormAutomationSelectActionModal';
import FormAutomationClickByValueActionModal from '@src/components/organisms/fanp/FormAutomationClickByValueActionModal';
import FormAutomationNavigationActionModal from '@src/components/organisms/fanp/FormAutomationNavigationActionModal';
import FormAutomationTimeActionModal from '@src/components/organisms/fanp/FormAutomationTimeActionModal';
import FormAutomationRadioActionModal from '@src/components/organisms/fanp/FormAutomationRadioActionModal';
import FormAutomationCheckboxInputActionModal from '@src/components/organisms/fanp/FormAutomationCheckboxInputActionModal';
import { TextField, Button } from '@zeals-co-ltd/washi-components';
import FormAutomationSidebar from './FormAutomationSidebar';
var useTextFieldStyles = makeStyles(function () {
    return createStyles({
        root: {
            '& .MuiOutlinedInput-root': {
                height: '52px',
                padding: 0,
            },
            '& .MuiInputBase-root': {
                '& fieldset': {
                    borderColor: colors.silver_lightning,
                },
            },
            '& .MuiInputBase-input': {
                padding: '16px',
                fontSize: '14px',
                lineHeight: '20px',
                '&::placeholder': {
                    fontSize: '14px',
                    lineHeight: '20px',
                    fontWeight: '400',
                },
            },
            background: '#fff',
            borderRadius: '8px',
        },
    });
});
var styledContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  justify-content: center;\n  .menu-area {\n    padding: 16px;\n  }\n  .main-page {\n    padding: 16px 16px 16px 0;\n  }\n"], ["\n  justify-content: center;\n  .menu-area {\n    padding: 16px;\n  }\n  .main-page {\n    padding: 16px 16px 16px 0;\n  }\n"])));
var FlowRow = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  position: relative;\n\n  &:last-of-type {\n    .line {\n      display: none;\n    }\n  }\n  &:hover {\n    .flow-icon,\n    .table-row {\n      cursor: grab;\n      border: 1px solid ", ";\n    }\n  }\n"], ["\n  position: relative;\n\n  &:last-of-type {\n    .line {\n      display: none;\n    }\n  }\n  &:hover {\n    .flow-icon,\n    .table-row {\n      cursor: grab;\n      border: 1px solid ", ";\n    }\n  }\n"])), colors.border.highlight);
var UrlContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  max-width: 598px;\n  margin-bottom: 32px;\n  h5 {\n    font-weight: 700;\n    font-size: 16px;\n    line-height: 24px;\n    margin-bottom: 16px;\n  }\n"], ["\n  max-width: 598px;\n  margin-bottom: 32px;\n  h5 {\n    font-weight: 700;\n    font-size: 16px;\n    line-height: 24px;\n    margin-bottom: 16px;\n  }\n"])));
var ItemsContainer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  overflow: auto;\n  height: 500px;\n"], ["\n  overflow: auto;\n  height: 500px;\n"])));
var MenuTitleContainer = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  h5 {\n    font-weight: bold;\n    font-size: 19px;\n    line-height: 28px;\n    letter-spacing: 0.15px;\n    margin-bottom: 8px;\n    color: ", ";\n  }\n  p {\n    font-size: 16px;\n    line-height: 24px;\n    margin-bottom: 16px;\n    color: ", ";\n  }\n"], ["\n  h5 {\n    font-weight: bold;\n    font-size: 19px;\n    line-height: 28px;\n    letter-spacing: 0.15px;\n    margin-bottom: 8px;\n    color: ", ";\n  }\n  p {\n    font-size: 16px;\n    line-height: 24px;\n    margin-bottom: 16px;\n    color: ", ";\n  }\n"])), colors.rpa_settings.old_navy, colors.midnight);
var ButtonRow = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: flex;\n  justify-content: flex-end;\n  width: 100%;\n  padding: 16px 0 0 0;\n  gap: 16px;\n  isolation: isolate;\n"], ["\n  display: flex;\n  justify-content: flex-end;\n  width: 100%;\n  padding: 16px 0 0 0;\n  gap: 16px;\n  isolation: isolate;\n"])));
function FormAutomationSetting(_a) {
    var formAutomationSteps = _a.formAutomationSteps, url = _a.url, fields = _a.fields, stepErrors = _a.stepErrors, onConfigUrlChange = _a.onConfigUrlChange, onDeleteClick = _a.onDeleteClick, onStepChange = _a.onStepChange, handleAddStepToConfig = _a.handleAddStepToConfig, formAutomationConfig = _a.formAutomationConfig, onDragEnd = _a.onDragEnd, onSaveClick = _a.onSaveClick, onClickCompleteButton = _a.onClickCompleteButton, hasAddedItem = _a.hasAddedItem, setHasAddedItem = _a.setHasAddedItem, intl = _a.intl;
    var textFieldStyles = useTextFieldStyles();
    var _b = __read(useState(false), 2), isModalShown = _b[0], setIsModalShown = _b[1];
    var _c = __read(useState(null), 2), targetStep = _c[0], setTargetStep = _c[1];
    var _d = __read(useState(null), 2), targetField = _d[0], setTargetField = _d[1];
    var _e = __read(useState(null), 2), targetStepAction = _e[0], setTargetStepAction = _e[1];
    // Get the ref of a new row in order to scroll to it
    var _f = __read(useState(null), 2), item = _f[0], setItem = _f[1];
    var itemRef = useCallback(function (item) {
        if (item) {
            setItem(item);
        }
    }, []);
    useEffect(function () {
        // Scroll to the new step item
        if (item && hasAddedItem) {
            item.scrollIntoView();
            setHasAddedItem(false);
        }
    }, [hasAddedItem, item]);
    function handleModal(isModalShow, step, field, stepAction) {
        setIsModalShown(isModalShow);
        setTargetStep(step);
        setTargetField(field);
        setTargetStepAction(stepAction);
    }
    function renderModalByActionType(step, field, action) {
        var actionType = step.actionType;
        switch (actionType) {
            case FormAutomationStepAction.FORM_AUTOMATION_STEP_ACTION_TEXT_INPUT: {
                return (React.createElement(FormAutomationTextInputActionModal, { initialStep: step, field: field, onCancel: function () { return setIsModalShown(false); }, onChange: onStepChange }));
            }
            case FormAutomationStepAction.FORM_AUTOMATION_STEP_ACTION_SELECT_INPUT: {
                return (React.createElement(FormAutomationSelectActionModal, { initialSelectStep: action, field: field, onCancel: function () { return setIsModalShown(false); }, onChange: function (action) {
                        onStepChange(__assign(__assign({}, step), { selectAction: action }));
                    } }));
            }
            case FormAutomationStepAction.FORM_AUTOMATION_STEP_ACTION_RADIO_INPUT: {
                return (React.createElement(FormAutomationRadioActionModal, { initialRadioInputStep: action, field: field, onCancel: function () { return setIsModalShown(false); }, onChange: function (action) {
                        return onStepChange(__assign(__assign({}, step), { radioAction: action }));
                    } }));
            }
            case FormAutomationStepAction.FORM_AUTOMATION_STEP_ACTION_TIME_WAIT: {
                var newStep_1 = function (selectKey, selectValue) {
                    var _a;
                    return __assign(__assign({}, step), { timeWaitAction: __assign(__assign({}, step.timeWaitAction), (_a = {}, _a[selectKey] = selectValue, _a)) });
                };
                return (React.createElement(FormAutomationTimeActionModal, { initialWaitS: action === null || action === void 0 ? void 0 : action.waitS, onCancel: function () { return setIsModalShown(false); }, onUpdateWaitTime: function (waitS) { return onStepChange(newStep_1('waitS', waitS)); } }));
            }
            case FormAutomationStepAction.FORM_AUTOMATION_STEP_ACTION_NAVIGATION_WAIT: {
                var newStep_2 = function (selectKey, selectValue) {
                    var _a;
                    return __assign(__assign({}, step), { navigationWaitAction: __assign(__assign({}, action), (_a = {}, _a[selectKey] = selectValue, _a)) });
                };
                return (React.createElement(FormAutomationNavigationActionModal, { initialWaitS: action === null || action === void 0 ? void 0 : action.waitS, onCancel: function () { return setIsModalShown(false); }, onUpdateWaitTime: function (waitS) { return onStepChange(newStep_2('waitS', waitS)); } }));
            }
            case FormAutomationStepAction.FORM_AUTOMATION_STEP_ACTION_CHECKBOX_INPUT: {
                return (React.createElement(FormAutomationCheckboxInputActionModal, { initialCheckboxInputStep: action, field: field, onCancel: function () { return setIsModalShown(false); }, onChange: function (action) {
                        return onStepChange(__assign(__assign({}, step), { checkboxAction: action }));
                    } }));
            }
            default: {
                return (React.createElement(FormAutomationClickByValueActionModal, { initialClickByValueStep: action, onCancel: function () { return setIsModalShown(false); }, onChange: function (action) {
                        onStepChange(__assign(__assign({}, step), { clickByValueAction: action }));
                    } }));
            }
        }
    }
    return (React.createElement(Composition, { areas: "menu setting", templateCols: "332px auto", gap: "16px", height: "100%", as: styledContainer, "data-testid": "form-automation-setting" }, function (Areas) { return (React.createElement(DragDropContext, { onDragEnd: onDragEnd },
        React.createElement(Areas.Menu, { className: "menu-area" },
            React.createElement(MenuTitleContainer, null,
                React.createElement("h5", null, intl.formatMessage({ id: "tDpTIY", defaultMessage: "ACTION\u3092\u8FFD\u52A0" })),
                React.createElement("p", null, intl.formatMessage({ id: "2CsEpf", defaultMessage: "\u5B9F\u884C\u3057\u305F\u3044ACTION\u3092\u8FFD\u52A0\u3057\u3066\u304F\u3060\u3055\u3044" }))),
            React.createElement(FormAutomationSidebar, { onClickListItem: function (action) {
                    return handleAddStepToConfig(formAutomationConfig, action);
                } })),
        React.createElement(Areas.Setting, { className: "main-page" },
            isModalShown &&
                renderModalByActionType(targetStep, targetField, targetStepAction),
            React.createElement(UrlContainer, null,
                React.createElement("h5", null, intl.formatMessage({ id: "8Oa0SK", defaultMessage: "\u30C7\u30FC\u30BF\u30DD\u30B9\u30C8\u5148FormURL" })),
                React.createElement(TextField, { "data-testid": "FormAutomationSetting-UrlTextField", fullWidth: true, size: "medium", className: textFieldStyles.root, name: "name", value: url || '', placeholder: "" + intl.formatMessage({ id: "b0PzRf", defaultMessage: "URL\u3092\u5165\u529B\u3057\u3066\u304F\u3060\u3055\u3044" }), onChange: function (e) { return onConfigUrlChange(e.target.value); }, autoComplete: "off" })),
            React.createElement(ItemsContainer, { "data-testid": "FormAutomationSetting-ItemsContainer" },
                React.createElement(StepItemsHeader, null),
                React.createElement(Droppable, { droppableId: "step-list" }, function (provided) { return (React.createElement("div", __assign({ className: "draggable-container", ref: provided.innerRef }, provided.droppableProps),
                    formAutomationSteps.map(function (step, i) { return (React.createElement(Draggable, { draggableId: step.id, index: i }, function (provided) { return (React.createElement(FlowRow, __assign({}, provided.draggableProps, provided.dragHandleProps, { ref: provided.innerRef, key: step.id + "-form-row" }),
                        React.createElement(FormAutomationStepItem, { step: step, fields: fields, errorMsg: stepErrors[step.id], onChange: onStepChange, onDeleteClick: onDeleteClick, handleModal: handleModal, itemRef: itemRef }))); })); }),
                    provided.placeholder)); })),
            React.createElement(ButtonRow, null,
                React.createElement(Button, { "data-testid": "FormAutomationSetting-TemporarySaveButton", disabled: !formAutomationSteps.length, variant: "text", onClick: function () { return onSaveClick(true); } }, intl.formatMessage({ id: "duK/5+", defaultMessage: "\u4E00\u6642\u4FDD\u5B58" })),
                React.createElement(Button, { "data-testid": "FormAutomationSetting-CompleteButton", color: "success", variant: "contained", disabled: !formAutomationSteps.length, onClick: function () { return onClickCompleteButton(true); } }, intl.formatMessage({ id: "U78NhE", defaultMessage: "Complete" })))))); }));
}
export default injectIntl(FormAutomationSetting);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
