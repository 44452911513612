var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import { colors } from '@src/colors';
import { bubbleChildTypes } from '@src/lib/app-constants';
import ImageNode from './line_flex_items/ImageNode';
import BoxNode from './line_flex_items/BoxNode';
import TextNode from './line_flex_items/TextNode';
import ButtonNode from './line_flex_items/ButtonNode';
import IconNode from './line_flex_items/IconNode';
import SpacerNode from './line_flex_items/SpacerNode';
import SeparatorNode from './line_flex_items/SeparatorNode';
import FillerNode from './line_flex_items/FillerNode';
var LineFlexMessageContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin: 20px 0px;\n  display: flex;\n"], ["\n  margin: 20px 0px;\n  display: flex;\n"])));
var BubbleWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 260px;\n  min-height: 200px;\n  margin: 20px 10px;\n  border-radius: 16px;\n  border: 1px solid ", ";\n  overflow: hidden;\n\n  > div.header {\n    padding: 20px;\n  }\n\n  > div.footer {\n    padding: 10px;\n  }\n\n  > div.body {\n    padding: 10px;\n  }\n"], ["\n  width: 260px;\n  min-height: 200px;\n  margin: 20px 10px;\n  border-radius: 16px;\n  border: 1px solid ", ";\n  overflow: hidden;\n\n  > div.header {\n    padding: 20px;\n  }\n\n  > div.footer {\n    padding: 10px;\n  }\n\n  > div.body {\n    padding: 10px;\n  }\n"])), colors.border.default);
var contentRenderer = function (content, index, parentLayout, updateBoundaryNode) {
    switch (content.type) {
        case 'image':
            return (React.createElement(ImageNode, { key: index, lineImage: content, parentLayout: parentLayout }));
        case 'box':
            return (React.createElement(BoxNode, { key: index, parentLayout: parentLayout, lineBox: content, contentsRenderer: contentsRenderer, updateBoundaryNode: updateBoundaryNode }));
        case 'text':
            return (React.createElement(TextNode, { key: index, parentLayout: parentLayout, lineText: content }));
        case 'button':
            return (React.createElement(ButtonNode, { key: index, lineButton: content, updateBoundaryNode: updateBoundaryNode }));
        case 'icon':
            return (React.createElement(IconNode, { key: index, lineIcon: content, parentLayout: parentLayout }));
        case 'spacer':
            return React.createElement(SpacerNode, { key: index });
        case 'separator':
            return (React.createElement(SeparatorNode, { key: index, lineSeparator: content }));
        case 'filler':
            return React.createElement(FillerNode, { key: index });
        default:
            return React.createElement("div", { key: index }, content.type);
    }
};
var contentsRenderer = function (contents, parentLayout, updateBoundaryNode) {
    return contents.map(function (content, index) {
        return contentRenderer(content, index, parentLayout, updateBoundaryNode);
    });
};
var LineFlexNode = function (_a) {
    var _b = _a.data, _c = _b.message.line_flex_message, id = _c.id, contents = _c.contents, updateBoundaryNode = _b.updateBoundaryNode;
    return (React.createElement(LineFlexMessageContainer, { key: "line-flex-" + id, "data-testid": "LineFlexNode_LineFlexMessageContainer" }, contents.contents.map(function (bubble) {
        return (React.createElement(BubbleWrapper, { key: "bubble-" + bubble.id, "data-testid": "LineFlexNode-BubbleWrapper" }, Object.values(bubbleChildTypes)
            .map(function (type) {
            if (!bubble[type])
                return null;
            return (React.createElement("div", { className: type, key: type }, contentRenderer(bubble[type], null, '', updateBoundaryNode)));
        })
            .filter(Boolean)));
    })));
};
export default LineFlexNode;
var templateObject_1, templateObject_2;
