var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { chatTypes } from '@src/lib/chat-types';
import styled from 'styled-components';
import { mediaTypes } from '@src/lib/app-constants';
import rightMenuTabs from '@src/lib/rightMenuTabs';
import { ModalPortal } from '@src/components/atoms';
import modalTypes from '@src/lib/modalTypes';
import { transitions } from '@src/lib/animations';
import { toggleRightMenu, changeRightMenuTab, } from '@src/redux/fanp/actions/rightMenu';
import { fetchUserFilter } from '@src/redux/fanp/actions/userFilter';
import { AlertModalContext } from '@src/components/molecules/AlertModal';
import { modalCancel, showModal } from '@src/redux/fanp/actions/modal';
import { colors, shadows } from '@src/colors';
import { hoverMenuBg, centeredFlex } from '@src/lib/mixins';
import { createNewScenarioDelivery, adEditScheduledAt, isValidTriggerTime, updateCampaignContentDelivery, adSetStepDaysAfter, adSetStepDeliveryTime, adSetScenarioTesting, fetchDeleteScenarioDelivery, } from '@src/redux/fanp/actions/ad-scenario';
import { Preview, Flow, SendDelivery, Maintenance, UploadedFiles, FlexMessageTemplates, } from '@src/fanp/enterprise/talk_customize/components/right_menu_tab_content';
import ScenarioMapV2 from '@src/components/organisms/fanp/ScenarioMapV2';
import { singleScenarioDenormalizer } from '@src/lib/dataNormalizer';
import CustomizedAlert from '@src/components/molecules/CustomizedAlert';
import Modal from '@src/components/molecules/Modal';
import ScenarioComments from './ScenarioComments';
import FilterSideArea from './FilterSideArea';
import { SelectedTab } from '@src/redux/fanp/types/rightMenu';
import * as scenarioApi from '@src/services/api/scenario';
import { handleAPIErrors } from '@src/lib/helpers/ajaxFuncs';
import moment from 'moment';
import HOCWithRouter from '@src/components/hoc/HOCWithRouter';
// TODO: this file not finished Farrhad
var RightMenuContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  transition: ", " all;\n  margin: 16px 0 16px 8px;\n  order: 3;\n"], ["\n  display: flex;\n  transition: ", " all;\n  margin: 16px 0 16px 8px;\n  order: 3;\n"])), transitions.normal);
var RightSideBar = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  width: 44px;\n  max-height: 600px;\n  overflow: auto;\n  box-shadow: ", ";\n  background: ", ";\n  border-radius: 10px 0 0 10px;\n  color: ", ";\n  font-size: 24px;\n"], ["\n  display: flex;\n  flex-direction: column;\n  width: 44px;\n  max-height: 600px;\n  overflow: auto;\n  box-shadow: ", ";\n  background: ", ";\n  border-radius: 10px 0 0 10px;\n  color: ", ";\n  font-size: 24px;\n"])), shadows.small.light, colors.background.main_menu, colors.icon);
// ここでRightMenuTabはスタイリングされている
var RightMenuTab = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  transition: ", " all;\n  min-height: 60px;\n  ", ";\n  box-shadow: ", ";\n  flex: 1;\n  color: ", ";\n\n  &:first-child {\n    border-radius: 10px 0 0 0;\n  }\n  &:last-child {\n    border-radius: 0 0 0 10px;\n  }\n\n  &:hover {\n    ", ";\n  }\n\n  .adicon_comment {\n    &:before {\n      font-size: 21px;\n    }\n  }\n"], ["\n  transition: ", " all;\n  min-height: 60px;\n  ", ";\n  box-shadow: ", ";\n  flex: 1;\n  color: ", ";\n\n  &:first-child {\n    border-radius: 10px 0 0 0;\n  }\n  &:last-child {\n    border-radius: 0 0 0 10px;\n  }\n\n  &:hover {\n    ", ";\n  }\n\n  .adicon_comment {\n    &:before {\n      font-size: 21px;\n    }\n  }\n"])), transitions.normal, centeredFlex, function (props) { return (props.active ? shadows.medium.mid : ""); }, colors.icon, function (props) { return (props.active ? "cursor: pointer" : hoverMenuBg); });
var RightMenuArea = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  min-width: 400px;\n  background: ", ";\n  box-shadow: ", ";\n  padding: 24px;\n  overflow: auto;\n  .message {\n    font-size: 14px;\n    color: ", ";\n    font-weight: bold;\n    margin: 0 8px 12px;\n  }\n"], ["\n  min-width: 400px;\n  background: ", ";\n  box-shadow: ", ";\n  padding: 24px;\n  overflow: auto;\n  .message {\n    font-size: 14px;\n    color: ", ";\n    font-weight: bold;\n    margin: 0 8px 12px;\n  }\n"])), colors.background.main_menu, shadows.small.light, colors.default_text);
var RightMenu = /** @class */ (function (_super) {
    __extends(RightMenu, _super);
    function RightMenu() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            cantEditDeliveredModalOpen: false,
            editScenarioScheduleModalOpen: false,
            shareToken: '',
        };
        _this.handleExpandClick = function () {
            var _a = _this.props, toggleRightMenu = _a.toggleRightMenu, changeRightMenuTab = _a.changeRightMenuTab, rightMenu = _a.rightMenu;
            toggleRightMenu(!rightMenu.isRightMenuExpanded);
            changeRightMenuTab('');
        };
        _this.handleTabClick = function (tabName) {
            var _a = _this.props, toggleRightMenu = _a.toggleRightMenu, changeRightMenuTab = _a.changeRightMenuTab, rightMenuSelectedTab = _a.rightMenu.rightMenuSelectedTab;
            return function () {
                if (rightMenuSelectedTab === tabName) {
                    toggleRightMenu(false);
                    changeRightMenuTab('');
                }
                else {
                    toggleRightMenu(true);
                    changeRightMenuTab(tabName);
                }
            };
        };
        _this.handleDeleteScenarioDelivery = function () { return __awaiter(_this, void 0, void 0, function () {
            var _a, adScenario, chatbotIdParam, adScenarioIdParam, fetchDeleteScenarioDelivery, scenarios, currentScenario, scenario_delivery, e_1;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this.props, adScenario = _a.adScenario, chatbotIdParam = _a.chatbotIdParam, adScenarioIdParam = _a.adScenarioIdParam, fetchDeleteScenarioDelivery = _a.fetchDeleteScenarioDelivery;
                        scenarios = adScenario.scenarios;
                        currentScenario = scenarios[adScenarioIdParam];
                        scenario_delivery = currentScenario.scenario_delivery;
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, fetchDeleteScenarioDelivery(chatbotIdParam, adScenarioIdParam)];
                    case 2:
                        _b.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        e_1 = _b.sent();
                        // TODO: handle with modal
                        alert("\u30B7\u30CA\u30EA\u30AA\u914D\u4FE1\u3092\u524A\u9664\u3067\u304D\u307E\u305B\u3093\u3067\u3057\u305F");
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        }); };
        /**
         * Will only execute call back if scenario is not readonly
         */
        _this.handleReadOnlyClick = function (callback) {
            if (_this.scenarioIsReadOnly()) {
                var _a = _this.props, adScenario = _a.adScenario, adScenarioIdParam = _a.adScenarioIdParam;
                var scenarios = adScenario.scenarios;
                var currentScenario = scenarios[adScenarioIdParam];
                // scenarioDeliveryDelivered when status delivered or
                // scheduled_at before time now (when user does not reload page to get status delivered)
                var scenarioDeliveryDelivered = currentScenario &&
                    currentScenario.scenario_delivery &&
                    (currentScenario.scenario_delivery.delivered ||
                        moment(currentScenario.scenario_delivery.scheduled_at).isBefore(moment()));
                if (scenarioDeliveryDelivered) {
                    _this.toggleCantEditDeliveredModal();
                }
                else {
                    _this.toggleEditScenarioScheduleModal();
                }
                return;
            }
            callback();
        };
        _this.toggleCantEditDeliveredModal = function () {
            return _this.setState({
                cantEditDeliveredModalOpen: !_this.state.cantEditDeliveredModalOpen,
            });
        };
        _this.toggleEditScenarioScheduleModal = function () {
            return _this.setState({
                editScenarioScheduleModalOpen: !_this.state.editScenarioScheduleModalOpen,
            });
        };
        _this.fetchToken = function () { return __awaiter(_this, void 0, void 0, function () {
            var _a, chatbotIdParam, adScenarioIdParam, res, scenarioMapUrl, e_2;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this.props, chatbotIdParam = _a.chatbotIdParam, adScenarioIdParam = _a.adScenarioIdParam;
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, scenarioApi.getShareToken(chatbotIdParam, adScenarioIdParam)];
                    case 2:
                        res = _b.sent();
                        scenarioMapUrl = res
                            ? "/scenario_map/chatbots/" + res.chatbotId + "/scenarios/" + res.scenarioId
                            : '';
                        this.setState({
                            scenarioMapUrl: scenarioMapUrl,
                            shareToken: res.token,
                        });
                        return [2 /*return*/, res.token];
                    case 3:
                        e_2 = _b.sent();
                        handleAPIErrors(e_2.message);
                        return [2 /*return*/, ''];
                    case 4: return [2 /*return*/];
                }
            });
        }); };
        _this.scenarioIsReadOnly = function () {
            var _a = _this.props, scenarios = _a.adScenario.scenarios, adScenarioIdParam = _a.adScenarioIdParam, chatTypeParam = _a.chatTypeParam;
            if (!scenarios || !adScenarioIdParam)
                return false;
            var currentScenario = scenarios[adScenarioIdParam];
            if (!currentScenario)
                return false;
            return (chatTypeParam === chatTypes.SHOT &&
                currentScenario.scenario_delivery &&
                Boolean(currentScenario.scenario_delivery.id));
        };
        _this.getRightMenuContent = function () {
            var _a;
            var _b = _this.props, chatTypeParam = _b.chatTypeParam, rightMenu = _b.rightMenu, adScenario = _b.adScenario, modalCancel = _b.modalCancel, showModal = _b.showModal, chatbotIdParam = _b.chatbotIdParam, adScenarioIdParam = _b.adScenarioIdParam, createNewScenarioDelivery = _b.createNewScenarioDelivery, adEditScheduledAt = _b.adEditScheduledAt, isValidTriggerTime = _b.isValidTriggerTime, updateCampaignContentDelivery = _b.updateCampaignContentDelivery, adSetStepDaysAfter = _b.adSetStepDaysAfter, adSetStepDeliveryTime = _b.adSetStepDeliveryTime, adSetScenarioTesting = _b.adSetScenarioTesting, fetchUserFilter = _b.fetchUserFilter, blockIdParam = _b.blockIdParam, settings = _b.settings, chatbotPlatform = _b.chatbotPlatform;
            var rightMenuSelectedTab = rightMenu.rightMenuSelectedTab, deliveryOption = rightMenu.deliveryOption;
            var scenarios = adScenario.scenarios, blocks = adScenario.blocks, layers = adScenario.layers;
            var currentScenario = scenarios[adScenarioIdParam];
            var platform = settings.activeChatbot.platform;
            var shareToken = _this.state.shareToken;
            switch (rightMenuSelectedTab) {
                case SelectedTab.Preview:
                    if (platform !== 'webchat') {
                        return (React.createElement("div", { className: "message" }, "Preview is currently only available in the webchat platform."));
                    }
                    return (React.createElement(Preview, { adScenarioIdParam: adScenarioIdParam, chatbotIdParam: chatbotIdParam }));
                case SelectedTab.ScenarioMapV1:
                    return (React.createElement(Flow, { adScenarioIdParam: adScenarioIdParam, chatbotIdParam: chatbotIdParam, adScenario: adScenario, blockIdParam: blockIdParam, nodeLocations: 
                        // @ts-expect-error
                        (_a = _this.props.browserLocation.query) === null || _a === void 0 ? void 0 : _a.nodeLocations, location: _this.props.browserLocation, token: shareToken, fetchToken: _this.fetchToken }));
                case SelectedTab.ScenarioMapV2:
                    return (React.createElement(ScenarioMapV2, { adScenarioIdParam: adScenarioIdParam, chatbotIdParam: chatbotIdParam, token: shareToken, clientView: false, fetchToken: _this.fetchToken }));
                case SelectedTab.UploadedImages:
                    return (React.createElement(UploadedFiles, { key: "uploaded_images", chatbotIdParam: chatbotIdParam, mediaType: mediaTypes.IMAGE }));
                case SelectedTab.UploadedVideos:
                    return (React.createElement(UploadedFiles, { key: "uploaded_videos", chatbotIdParam: chatbotIdParam, mediaType: mediaTypes.VIDEO }));
                case SelectedTab.UploadedAudio:
                    return (React.createElement(UploadedFiles, { key: "uploaded_audios", chatbotIdParam: chatbotIdParam, mediaType: mediaTypes.AUDIO }));
                case SelectedTab.FlexMessageTemplate:
                    return React.createElement(FlexMessageTemplates, { chatbotIdParam: chatbotIdParam });
                case SelectedTab.Send:
                    return (React.createElement(AlertModalContext.Consumer, null, function (_a) {
                        var handleModal = _a.handleModal;
                        return (React.createElement(SendDelivery, { chatbotIdParam: chatbotIdParam, adScenarioIdParam: adScenarioIdParam, chatTypeParam: chatTypeParam, modalCancel: modalCancel, showModal: showModal, deliveryOption: deliveryOption, scenarioErrors: adScenario.errors, currentScenario: scenarios[adScenarioIdParam], createNewScenarioDelivery: createNewScenarioDelivery, adEditScheduledAt: adEditScheduledAt, isValidTriggerTime: isValidTriggerTime, updateCampaignContentDelivery: updateCampaignContentDelivery, adSetStepDaysAfter: adSetStepDaysAfter, adSetStepDeliveryTime: adSetStepDeliveryTime, adSetScenarioTesting: adSetScenarioTesting, fetchUserFilter: fetchUserFilter, handleModal: handleModal, chatbotPlatform: chatbotPlatform, onReadOnlyClick: _this.handleReadOnlyClick, readOnly: _this.scenarioIsReadOnly() }));
                    }));
                case SelectedTab.MessageFilter:
                    return (React.createElement(FilterSideArea, { chatTypeParam: chatTypeParam, chatbotId: chatbotIdParam }));
                case SelectedTab.ScenarioComments:
                    return (React.createElement(ScenarioComments, { chatTypeParam: chatTypeParam, chatbotId: chatbotIdParam, adScenarioId: adScenarioIdParam, blockIdParam: blockIdParam, scenario: singleScenarioDenormalizer([currentScenario], layers, blocks) }));
                case SelectedTab.None:
                    // Coming soon!
                    return React.createElement(Maintenance, null);
                default:
                    return null;
            }
        };
        return _this;
    }
    RightMenu.prototype.componentDidMount = function () {
        if (this.props.adScenarioIdParam)
            this.fetchToken();
    };
    RightMenu.prototype.componentDidUpdate = function (prevProps) {
        if (this.props.adScenarioIdParam &&
            this.props.adScenarioIdParam !== prevProps.adScenarioIdParam) {
            this.fetchToken();
        }
    };
    RightMenu.prototype.componentWillUnmount = function () {
        var _a = this.props, toggleRightMenu = _a.toggleRightMenu, changeRightMenuTab = _a.changeRightMenuTab, isRightMenuExpanded = _a.rightMenu.isRightMenuExpanded;
        if (!isRightMenuExpanded)
            return;
        toggleRightMenu(false);
        changeRightMenuTab('');
    };
    RightMenu.prototype.render = function () {
        var _this = this;
        var rightMenu = this.props.rightMenu;
        // if scenario is not a draft make the components readonly
        var isRightMenuExpanded = rightMenu.isRightMenuExpanded, rightMenuSelectedTab = rightMenu.rightMenuSelectedTab;
        return (React.createElement(RightMenuContainer, null,
            this.state.cantEditDeliveredModalOpen && (React.createElement(ModalPortal, null,
                React.createElement(Modal, { "data-testid": "talk-customize-right-can-edit-delivered-modal", type: modalTypes.TEST, text: "\u914D\u4FE1\u6E08\u307F\u306E\u30B7\u30CA\u30EA\u30AA\u306F\u7DE8\u96C6\u3067\u304D\u307E\u305B\u3093", onCancel: this.toggleCantEditDeliveredModal }))),
            this.state.editScenarioScheduleModalOpen && (React.createElement(CustomizedAlert, { alertMessage: "\u4E88\u7D04\u914D\u4FE1\u306E\u8A2D\u5B9A\u304C\u89E3\u9664\u3055\u308C\u307E\u3059\u3002\u7DE8\u96C6\u3057\u307E\u3059\u304B\uFF1F", onCancel: this.toggleEditScenarioScheduleModal, onConfirm: this.handleDeleteScenarioDelivery })),
            React.createElement(RightSideBar, null,
                React.createElement(RightMenuTab, { onClick: this.handleExpandClick, "data-testid": "right-menu-tab-toggle-close" }, isRightMenuExpanded ? (React.createElement("span", { className: "adicon_page_close" })) : (React.createElement("span", { className: "adicon_page_open" }))),
                rightMenuTabs.map(function (tab, i) {
                    return (React.createElement(RightMenuTab, { key: i + "-rightMenuTab", onClick: _this.handleTabClick(tab.tabName), active: rightMenuSelectedTab === tab.tabName, "data-testid": tab.testId },
                        React.createElement("span", { className: tab.icon })));
                })),
            isRightMenuExpanded ? (React.createElement(RightMenuArea, null, this.getRightMenuContent())) : null));
    };
    return RightMenu;
}(Component));
var mapStateToProps = function (state) { return ({
    rightMenu: state.rightMenu.present,
    modal: state.modal.present,
    adScenario: state.adScenario.present,
    settings: state.settings.present,
}); };
export default connect(mapStateToProps, {
    toggleRightMenu: toggleRightMenu,
    changeRightMenuTab: changeRightMenuTab,
    modalCancel: modalCancel,
    showModal: showModal,
    createNewScenarioDelivery: createNewScenarioDelivery,
    adEditScheduledAt: adEditScheduledAt,
    isValidTriggerTime: isValidTriggerTime,
    updateCampaignContentDelivery: updateCampaignContentDelivery,
    adSetStepDaysAfter: adSetStepDaysAfter,
    adSetStepDeliveryTime: adSetStepDeliveryTime,
    adSetScenarioTesting: adSetScenarioTesting,
    fetchUserFilter: fetchUserFilter,
    fetchDeleteScenarioDelivery: fetchDeleteScenarioDelivery,
})(HOCWithRouter(RightMenu));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
