import "core-js/modules/es.array.concat";
import "core-js/modules/es.array.map";
import "core-js/modules/es.object.entries";
import "core-js/modules/es.string.small";
import _extends from "@babel/runtime/helpers/extends";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
import React from 'react';
import styled from 'styled-components';
import { colors, shadows } from '@src/colors';
import ChatlinkForm from './ChatlinkForm';
var SettingsContainer = styled.div.withConfig({
  displayName: "SettingsContainer",
  componentId: "sc-nahvl5-0"
})(["background:white;overflow:auto;padding:2rem 2rem;border-radius:8px 0 0 8px;box-shadow:", ";.adicon_menu_setting{color:", ";&:before{font-size:20px;}}"], shadows.small.mid, colors.icon);
var ErrorContainer = styled.div.withConfig({
  displayName: "ErrorContainer",
  componentId: "sc-nahvl5-1"
})(["font-size:0.625rem;margin-top:1rem;color:", ";"], colors.error);

var ChatlinkSettings = function ChatlinkSettings(_ref) {
  var handleColorCodeChange = _ref.handleColorCodeChange,
      handlePoweredColorCodeChange = _ref.handlePoweredColorCodeChange,
      handleModalTypeChange = _ref.handleModalTypeChange,
      formikProps = _objectWithoutProperties(_ref, ["handleColorCodeChange", "handlePoweredColorCodeChange", "handleModalTypeChange"]);

  return React.createElement(SettingsContainer, null, React.createElement("p", {
    className: "adicon_menu_setting"
  }, "\u8A2D\u5B9A"), React.createElement(ErrorContainer, null, Object.entries(formikProps.errors).map(function (_ref2, i) {
    var _ref3 = _slicedToArray(_ref2, 2),
        key = _ref3[0],
        val = _ref3[1];

    return React.createElement("p", {
      key: "chatlink-form-error-".concat(i)
    }, "".concat(key, ": ").concat(val));
  })), React.createElement(ChatlinkForm, _extends({}, formikProps, {
    handleColorCodeChange: handleColorCodeChange,
    handlePoweredColorCodeChange: handlePoweredColorCodeChange,
    handleModalTypeChange: handleModalTypeChange
  })));
};

export default ChatlinkSettings;