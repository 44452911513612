import * as pathLinks from '@src/routes/Fanp/pathLinks';
export var makeAjaxHeaders = function () {
    // Send json body, only if that arg is not supplied as false
    return {
        'Content-Type': 'application/json; charset=UTF-8',
        Accept: 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
    };
};
export var makeFetchInit = function (method, body, json, formData) {
    if (method === void 0) { method = 'get'; }
    if (body === void 0) { body = ''; }
    if (json === void 0) { json = true; }
    if (formData === void 0) { formData = false; }
    var headers = new Headers();
    headers.append('Cookie', document.cookie);
    headers.append('Accept', 'application/json');
    headers.append('X-Requested-With', 'XMLHttpRequest');
    // Send json body, only if that arg is not supplied as false
    if (json) {
        headers.append('Content-Type', 'application/json; charset=UTF-8');
    }
    var fetchObject = {
        method: method.toUpperCase(),
        credentials: 'include',
        headers: headers,
    };
    // Include anybody to go along with non GET requests
    if (body) {
        fetchObject.body = formData ? body : JSON.stringify(body);
    }
    return fetchObject;
};
export var makeFetchWebChat = function (method, body, json) {
    if (method === void 0) { method = 'get'; }
    if (body === void 0) { body = false; }
    if (json === void 0) { json = true; }
    var headers = new Headers();
    if (json) {
        headers.append('Content-Type', 'application/json');
    }
    var fetchObject = {
        method: method.toUpperCase(),
        mode: 'cors',
        headers: headers,
    };
    if (body) {
        fetchObject.body = JSON.stringify(body);
    }
    return fetchObject;
};
export var handleAPIErrors = function (defaultMessage, err, onlyReturnsMsg) {
    // always log error to the console
    if (err)
        console.log(err);
    /**
     * if backend is a good boy, err.response.message will be printed
     * if backend is a bad boy, defaultMessage will be printed
     */
    if (!onlyReturnsMsg) {
        if (err && err.response && err.response.message) {
            alert(err.response.message);
        }
        else if (err && err.message) {
            alert(err.message);
        }
        else if (err) {
            alert(err);
        }
        else {
            alert(defaultMessage);
        }
    }
    else {
        if (err && err.response && err.response.message) {
            return err.response.message;
        }
        else if (err && err.message) {
            return err.message;
        }
        else if (err) {
            return err;
        }
        else {
            return defaultMessage;
        }
    }
};
export function fanpFetch(url, options) {
    if (options === void 0) { options = {}; }
    return fetch(url, options).then(function (res) {
        // Any requests that return a 403 (Unauthorized), should route the user to the login page
        // TODO should handle errors here
        if (res.status === 403) {
            console.error("Request is " + res.statusText + ", rerouting to login");
            if (window.globalRouterHistory) {
                window.globalRouterHistory.push(pathLinks.dashboardLogin);
            }
        }
        return res.json();
    });
}
export var getCookie = function (cname) {
    var name = cname + '=';
    var decodedCookie = decodeURIComponent(document.cookie);
    var cookieArr = decodedCookie.split(';');
    var cookie;
    for (var i = 0; i < cookieArr.length; i++) {
        cookie = cookieArr[i];
        while (cookie.charAt(0) === ' ') {
            cookie = cookie.substring(1);
        }
        if (cookie.indexOf(name) === 0) {
            return cookie.substring(name.length, cookie.length);
        }
    }
    return '';
};
export var generalApiFn = function (url, httpRequest, body, handlerFn) {
    return fetch(url, makeFetchInit(httpRequest, body))
        .then(function (res) { return res.json(); })
        .then(handlerFn)
        .catch(function (e) { return handleAPIErrors(e); });
};
