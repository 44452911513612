var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import { colors } from '@src/colors';
import { injectIntl } from 'react-intl';
var MenuButtonListContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  .menu-button-list-item-container {\n    overflow: auto;\n    max-width: 260px;\n  }\n  .menu-button-list-item {\n    padding: 8px;\n    margin-bottom: 8px;\n    cursor: pointer;\n    border-radius: 5px;\n    overflow: hidden;\n    text-overflow: ellipsis;\n    white-space: nowrap;\n    font-size: 12px;\n    &:hover {\n      background-color: ", ";\n    }\n  }\n  .active {\n    background-color: ", ";\n    font-weight: bold;\n  }\n"], ["\n  .menu-button-list-item-container {\n    overflow: auto;\n    max-width: 260px;\n  }\n  .menu-button-list-item {\n    padding: 8px;\n    margin-bottom: 8px;\n    cursor: pointer;\n    border-radius: 5px;\n    overflow: hidden;\n    text-overflow: ellipsis;\n    white-space: nowrap;\n    font-size: 12px;\n    &:hover {\n      background-color: ", ";\n    }\n  }\n  .active {\n    background-color: ", ";\n    font-weight: bold;\n  }\n"])), colors.background.light_purple, colors.background.active);
var MenuButtonList = function (_a) {
    var attributeQuestions = _a.attributeQuestions, handleSelectAttributeQuestion = _a.handleSelectAttributeQuestion, selectedAttributeQuestion = _a.selectedAttributeQuestion, searchTerm = _a.searchTerm, intl = _a.intl;
    var searchAttributeQuestionData = function () {
        return attributeQuestions.filter(function (attributeQuestion) {
            return (attributeQuestion.label && attributeQuestion.label.includes(searchTerm));
        });
    };
    var getAttributeQuestionName = function (attributeQuestion) {
        if (attributeQuestion.label) {
            return attributeQuestion.label === 'NULL'
                ? intl.formatMessage({ id: "DOKekl", defaultMessage: "Import Tag" })
                : attributeQuestion.label;
        }
        return intl.formatMessage({ id: "68Xf5n", defaultMessage: "No Label" });
    };
    return (React.createElement(MenuButtonListContainer, null,
        React.createElement("div", { className: "menu-button-list-item-container" },
            React.createElement("li", { className: "menu-button-list-item " + (selectedAttributeQuestion === 'all' ? 'active' : ''), key: -9999, onClick: function () { return handleSelectAttributeQuestion('all'); } },
                React.createElement("span", { className: "select-all" }, intl.formatMessage({ id: "zQvVDJ", defaultMessage: "All" }))),
            attributeQuestions &&
                searchAttributeQuestionData().map(function (attributeQuestion) {
                    return (React.createElement("li", { title: getAttributeQuestionName(attributeQuestion), className: "menu-button-list-item " + (selectedAttributeQuestion === attributeQuestion.id
                            ? 'active'
                            : ''), key: attributeQuestion.id, onClick: function () {
                            return handleSelectAttributeQuestion(attributeQuestion.id);
                        } }, getAttributeQuestionName(attributeQuestion)));
                }))));
};
export default injectIntl(MenuButtonList);
var templateObject_1;
