import "core-js/modules/es.array.map";
import React from 'react';
import PropTypes from 'prop-types';
import { eventLogShape } from '@src/lib/talkConstants';
import EventLog from './EventLog';

var EventLogList = function EventLogList(_ref) {
  var chatbotId = _ref.chatbotId,
      eventLogs = _ref.eventLogs,
      limits = _ref.limits,
      scenarios = _ref.scenarios,
      layers = _ref.layers,
      blocks = _ref.blocks;
  return React.createElement("ul", null, eventLogs.map(function (eventLog, i) {
    return React.createElement(EventLog, {
      key: "".concat(i, "-end_user_message"),
      chatbotId: chatbotId,
      eventLog: eventLog,
      limits: limits,
      scenarios: scenarios,
      layers: layers,
      blocks: blocks
    });
  }));
};

EventLogList.propTypes = {
  eventLogs: PropTypes.arrayOf(PropTypes.shape(eventLogShape)),
  chatbotId: PropTypes.number,
  limits: PropTypes.object,
  scenarios: PropTypes.object,
  layers: PropTypes.object,
  blocks: PropTypes.object
};
export default EventLogList;