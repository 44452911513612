var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
export var ABTestingStatus;
(function (ABTestingStatus) {
    ABTestingStatus[ABTestingStatus["UNSPECIFIED"] = 0] = "UNSPECIFIED";
    ABTestingStatus[ABTestingStatus["DRAFT"] = 1] = "DRAFT";
    ABTestingStatus[ABTestingStatus["ACTIVE"] = 2] = "ACTIVE";
    ABTestingStatus[ABTestingStatus["INACTIVE"] = 3] = "INACTIVE";
})(ABTestingStatus || (ABTestingStatus = {}));
export var getABTestDetails = function (activeABTesting, allAvailableScenario) {
    var ABTestScenarios = [];
    if (activeABTesting) {
        var firstActiveABTestingScenarios = activeABTesting[0];
        var scenarioVariantGroup = firstActiveABTestingScenarios.scenarioVariantGroup, activateTime = firstActiveABTestingScenarios.activateTime;
        var variants = scenarioVariantGroup.variants;
        var ABTestScenarios_1 = [];
        variants === null || variants === void 0 ? void 0 : variants.forEach(function (variant) {
            var weightScenarioVariant = variant.weightScenarioVariant, scenario = variant.scenario;
            var availableScenario = (allAvailableScenario || []).find(function (availableScenario) { return (availableScenario === null || availableScenario === void 0 ? void 0 : availableScenario.id) === parseInt(scenario === null || scenario === void 0 ? void 0 : scenario.id); });
            if (availableScenario) {
                var scenarioWithWeight = __assign(__assign({}, availableScenario), { weight: weightScenarioVariant.weight * 100 });
                ABTestScenarios_1.push(scenarioWithWeight);
            }
        });
        var functionType = scenarioVariantGroup.weightScenarioVariantGroup.functionType;
        return {
            ABTestScenarios: ABTestScenarios_1,
            activateTime: activateTime,
            scenarioVariantGroup: scenarioVariantGroup,
            functionType: functionType,
        };
    }
    return {
        ABTestScenarios: ABTestScenarios,
        activateTime: null,
        scenarioVariantGroup: null,
        functionType: null,
    };
};
export var getIsNotSameWeightValue = function (scenarios) {
    var _a;
    var firstScenarioWeight = (_a = scenarios[0]) === null || _a === void 0 ? void 0 : _a.weight;
    return scenarios.some(function (s) { return s.weight !== firstScenarioWeight; });
};
export var newFormatWeight = function (weight) {
    return Number(weight.toFixed(1));
};
export var getDraftFunctionType = function (initialConversationState) {
    var _a, _b, _c, _d;
    var draft = (_a = initialConversationState === null || initialConversationState === void 0 ? void 0 : initialConversationState.ABTesting) === null || _a === void 0 ? void 0 : _a.draft;
    return draft
        ? (_d = (_c = (_b = draft[0]) === null || _b === void 0 ? void 0 : _b.scenarioVariantGroup) === null || _c === void 0 ? void 0 : _c.weightScenarioVariantGroup) === null || _d === void 0 ? void 0 : _d.functionType
        : undefined;
};
