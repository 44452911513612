var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import { colors } from '@src/colors';
import { transitions } from '@src/lib/animations';
var RecordList = styled.ul(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin: 1rem 0;\n\n  li {\n    display: flex;\n    line-height: 32px;\n    border: 1px solid ", ";\n    user-select: none;\n    -moz-user-select: none;\n    -webkit-user-select: none;\n\n    transition: ", ";\n    cursor: pointer;\n\n    &:first-child {\n      border-radius: 5px 5px 0 0;\n    }\n\n    &:last-child {\n      border-radius: 0 0 5px 5px;\n    }\n\n    &.active {\n      border: 1px solid ", ";\n    }\n\n    &:hover {\n      opacity: 0.8;\n    }\n\n    span.id {\n      background: ", ";\n      flex: 1;\n    }\n\n    span.name {\n      flex: 4;\n    }\n  }\n"], ["\n  margin: 1rem 0;\n\n  li {\n    display: flex;\n    line-height: 32px;\n    border: 1px solid ", ";\n    user-select: none;\n    -moz-user-select: none;\n    -webkit-user-select: none;\n\n    transition: ", ";\n    cursor: pointer;\n\n    &:first-child {\n      border-radius: 5px 5px 0 0;\n    }\n\n    &:last-child {\n      border-radius: 0 0 5px 5px;\n    }\n\n    &.active {\n      border: 1px solid ", ";\n    }\n\n    &:hover {\n      opacity: 0.8;\n    }\n\n    span.id {\n      background: ", ";\n      flex: 1;\n    }\n\n    span.name {\n      flex: 4;\n    }\n  }\n"])), colors.border.table, transitions.normal, colors.border.confirm, colors.background.content);
export default RecordList;
var templateObject_1;
