var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchAccountCreate } from '@src/redux/fanp/actions/account';
import { Input } from '@src/components/atoms';
var AccountNew = /** @class */ (function (_super) {
    __extends(AccountNew, _super);
    function AccountNew() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            name: '',
            corporateUser: true,
            errorMsg: '',
        };
        _this.handleNameUpdate = function (e) {
            _this.setState({ name: e.target.value }, function () {
                _this.state.name && _this.setState({ errorMsg: '' });
            });
        };
        _this.corporateUserUpdate = function (corporateUser) {
            _this.setState({ corporateUser: corporateUser });
        };
        _this.handleSubmit = function (e) {
            e.preventDefault();
            var _a = _this.state, name = _a.name, corporateUser = _a.corporateUser;
            if (!name) {
                return _this.setState({ errorMsg: '会社名 / 事業者名をご入力ください' });
            }
            _this.props.fetchAccountCreate(name, corporateUser);
        };
        return _this;
    }
    AccountNew.prototype.render = function () {
        var _this = this;
        var _a = this.props, userName = _a.userName, email = _a.email;
        var _b = this.state, name = _b.name, corporateUser = _b.corporateUser, errorMsg = _b.errorMsg;
        return (React.createElement("div", { className: "initial_group_content_area" },
            React.createElement("p", { className: "heading_text" },
                "Zeals\u306B\u3054\u767B\u9332\u3044\u305F\u3060\u304D\u3042\u308A\u304C\u3068\u3046\u3054\u3056\u3044\u307E\u3059\uFF01",
                React.createElement("br", null),
                "\u3042\u306A\u305F\u306E\u4F1A\u793E/\u4E8B\u696D\u306B\u3064\u3044\u3066\u6559\u3048\u3066\u304F\u3060\u3055\u3044",
                React.createElement("br", null),
                React.createElement("br", null),
                "Thank you for registering with Zeals!",
                React.createElement("br", null),
                "Tell us about your company / business"),
            React.createElement("div", { className: "initial_group_container" },
                React.createElement("form", { onSubmit: this.handleSubmit },
                    React.createElement("ul", { className: "initial_group_input_list" },
                        React.createElement("li", { className: "form_default corporate_name_input_li" },
                            React.createElement("p", { className: "form_default_label" },
                                "\u4F1A\u793E\u540D / \u4E8B\u696D\u8005\u540D",
                                React.createElement("br", null),
                                "Company name / business name"),
                            React.createElement(Input, { type: "text", className: "form_default_input", hasError: !name, name: "username", value: name, onChange: this.handleNameUpdate }),
                            errorMsg && (React.createElement("div", { style: {
                                    fontSize: '0.8rem',
                                    padding: '5px 0 0 0',
                                    color: '#E5004B',
                                } }, errorMsg))),
                        React.createElement("li", { className: "form_default radio_input_li" },
                            React.createElement("p", { className: "form_default_label" },
                                "\u6CD5\u4EBA / \u500B\u4EBA",
                                React.createElement("br", null),
                                "Corporation / Individual"),
                            React.createElement("span", { className: "form_radio corporate" },
                                React.createElement("input", { name: "corporate_user", className: "form_radio_input", id: "id_group_form-is_corporate_use_1", type: "radio", checked: corporateUser, onClick: function () { return _this.corporateUserUpdate(true); } }),
                                React.createElement("label", { className: "form_label", htmlFor: "id_group_form-is_corporate_use_1" }, "\u6CD5\u4EBA (corporation)")),
                            React.createElement("span", { className: "form_radio individual" },
                                React.createElement("input", { className: "form_radio_input", id: "id_group_form-is_corporate_use_0", type: "radio", name: "corporate_user", checked: !corporateUser, onClick: function () { return _this.corporateUserUpdate(false); } }),
                                React.createElement("label", { className: "form_label", htmlFor: "id_group_form-is_corporate_use_0" }, "\u500B\u4EBA (individual)"))),
                        userName && (React.createElement("li", { className: "form_default" },
                            React.createElement("p", { className: "form_default_label" },
                                "\u62C5\u5F53\u8005\u306E\u304A\u540D\u524D",
                                React.createElement("br", null),
                                "Name"),
                            userName)),
                        email && (React.createElement("li", { className: "form_default" },
                            React.createElement("p", { className: "form_default_label", id: "form_email_facus" },
                                "\u30E1\u30FC\u30EB\u30A2\u30C9\u30EC\u30B9 ",
                                React.createElement("br", null),
                                "Email address"),
                            email))),
                    React.createElement("div", { className: "submit_button_area", style: { paddingBottom: '200px' } },
                        React.createElement("input", { type: "submit", className: "button_submit_l", value: "\u6B21\u3078 / Next" })))),
            React.createElement("div", { className: "character_area" })));
    };
    return AccountNew;
}(Component));
export default connect(function (state) { return ({
    email: state.account.current_user.email,
}); }, { fetchAccountCreate: fetchAccountCreate })(AccountNew);
