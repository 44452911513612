import _extends from "@babel/runtime/helpers/extends";
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
var margins = {
  none: 'none',
  xs: '12px',
  sm: '4px',
  md: '8px',
  lg: '22px',
  xl: '22px',
  xxl: '22px'
};
var Container = styled.span.withConfig({
  displayName: "Icon__Container",
  componentId: "sc-mhftyt-0"
})(["background-image:", ";margin-left:", ";margin-top:", ";"], function (props) {
  return "url(".concat(props.url, ")");
}, function (props) {
  return props.boxLayout === 'vertical' ? undefined : margins[props.margin];
}, function (props) {
  return props.boxLayout === 'vertical' ? margins[props.margin] : undefined;
});

var Icon = function Icon(props) {
  return React.createElement("div", {
    className: "component lfm-icon"
  }, React.createElement(Container, _extends({
    className: "lfm-container"
  }, props)));
};

Icon.propTypes = {
  content: PropTypes.shape({
    url: PropTypes.string.isRequired,
    margin: PropTypes.string,
    size: PropTypes.string,
    aspectRatio: PropTypes.string
  })
};
Icon.defaultProps = {
  size: 'md',
  aspectRatio: '1:1'
};
export default Icon;