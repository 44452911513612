var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { Switch, Icon } from '@zeals-co-ltd/washi-components';
import { makeStyles } from '@mui/styles';
import styled from 'styled-components';
import { injectIntl } from 'react-intl';
import * as washiColors from '@zeals-co-ltd/washi-styles';
import DateTimeRange from './DateTimeRange';
var useStyles = makeStyles(function () { return ({
    root: {
        padding: '9px',
        '& .Mui-checked': {
            color: washiColors.grey.A100,
        },
        '& .Mui-checked+.MuiSwitch-track, .MuiSwitch-track': {
            opacity: 1,
        },
    },
    track: {
        borderRadius: '10px',
    },
}); });
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n"], ["\n  display: flex;\n"])));
var ScheduledContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  padding: 7px 16px;\n  border: 1px solid ", ";\n  color: ", ";\n  border-radius: 8px;\n  min-width: 40%;\n  width: min-content;\n  height: auto;\n\n  > div:first-child {\n    pointer-events: ", ";\n  }\n\n  > div:nth-child(2) {\n    margin-top: 7px;\n    border-top: 1px solid ", ";\n    padding-top: 10px;\n  }\n\n  .Mui-checked + .MuiSwitch-track {\n    background-color: ", ";\n  }\n\n  .MuiSwitch-track {\n    background-color: ", ";\n  }\n"], ["\n  padding: 7px 16px;\n  border: 1px solid ", ";\n  color: ", ";\n  border-radius: 8px;\n  min-width: 40%;\n  width: min-content;\n  height: auto;\n\n  > div:first-child {\n    pointer-events: ", ";\n  }\n\n  > div:nth-child(2) {\n    margin-top: 7px;\n    border-top: 1px solid ", ";\n    padding-top: 10px;\n  }\n\n  .Mui-checked + .MuiSwitch-track {\n    background-color: ",
    ";\n  }\n\n  .MuiSwitch-track {\n    background-color: ",
    ";\n  }\n"])), washiColors.navy[100], washiColors.navy[900], function (props) { return (props.showOnly ? 'none' : 'auto'); }, washiColors.navy[100], function (props) {
    return props.showOnly ? washiColors.blue[300] : washiColors.blue[500];
}, function (props) {
    return props.showOnly ? washiColors.grey[300] : washiColors.grey[200];
});
var SelectedContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  padding: 7px 26px;\n  color: ", ";\n  min-width: 40%;\n  width: min-content;\n  height: auto;\n  pointer-events: ", ";\n\n  .Mui-checked + .MuiSwitch-track {\n    background-color: ", ";\n  }\n\n  .MuiSwitch-track {\n    background-color: ", ";\n  }\n"], ["\n  padding: 7px 26px;\n  color: ", ";\n  min-width: 40%;\n  width: min-content;\n  height: auto;\n  pointer-events: ", ";\n\n  .Mui-checked + .MuiSwitch-track {\n    background-color: ",
    ";\n  }\n\n  .MuiSwitch-track {\n    background-color: ",
    ";\n  }\n"])), washiColors.navy[900], function (props) { return (props.showOnly ? 'none' : 'auto'); }, function (props) {
    return props.showOnly ? washiColors.blue[300] : washiColors.blue[500];
}, function (props) {
    return props.showOnly ? washiColors.grey[300] : washiColors.grey[200];
});
var WaringMessageContainer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  margin: 16px 0px 9px 0;\n\n  > div {\n    display: flex;\n  }\n\n  .MuiSvgIcon-root {\n    margin-right: 8px;\n  }\n\n  p {\n    display: contents;\n    font-size: 12px;\n  }\n"], ["\n  margin: 16px 0px 9px 0;\n\n  > div {\n    display: flex;\n  }\n\n  .MuiSvgIcon-root {\n    margin-right: 8px;\n  }\n\n  p {\n    display: contents;\n    font-size: 12px;\n  }\n"])));
var ChangeType;
(function (ChangeType) {
    ChangeType["Selected"] = "selected";
    ChangeType["Schedule"] = "schedule";
})(ChangeType || (ChangeType = {}));
var WarningMessage = function (_a) {
    var errors = _a.errors, showOnly = _a.showOnly;
    return (React.createElement(WaringMessageContainer, null, (errors === null || errors === void 0 ? void 0 : errors.schedule) && !showOnly && (React.createElement("div", null,
        React.createElement(Icon, { name: "adicon_alert", color: "error" }),
        React.createElement("p", null, Object.values(errors.schedule)[0])))));
};
var ScheduleDateTime = function (_a) {
    var showOnly = _a.showOnly, defaultRichMenu = _a.defaultRichMenu, handleChange = _a.handleChange, errors = _a.errors, values = _a.values, intl = _a.intl;
    var classes = useStyles();
    var handleEdit = function (name, value) {
        handleChange({
            target: { name: name, value: value },
        });
    };
    var handleToggleSchedule = function () {
        var _a, _b;
        if (!values.schedule) {
            handleEdit(ChangeType.Schedule, __assign(__assign({}, defaultRichMenu.schedule), { start_time: ((_a = defaultRichMenu.schedule) === null || _a === void 0 ? void 0 : _a.start_time) || null, end_time: ((_b = defaultRichMenu.schedule) === null || _b === void 0 ? void 0 : _b.end_time) || null }));
        }
        else {
            handleEdit(ChangeType.Schedule, null);
        }
    };
    var handleToggleSeleted = function () {
        handleEdit(ChangeType.Selected, !values.selected);
    };
    return (React.createElement(Container, { "data-testid": "DefaultRichMenuForm_ScheduleDateTime" },
        React.createElement(ScheduledContainer, { showOnly: showOnly },
            React.createElement("div", null,
                React.createElement(Switch, { checked: Boolean(values.schedule), color: "primary", label: intl.formatMessage({ id: "FpBhdZ", defaultMessage: "Schedule Default Rich Menu" }), classes: classes, onChange: function () { return (showOnly ? {} : handleToggleSchedule()); }, readOnly: showOnly })),
            Boolean(values.schedule) && (React.createElement("div", null,
                React.createElement(DateTimeRange, { showOnly: showOnly, handleChangeSchedule: handleEdit, schedule: defaultRichMenu.schedule }),
                React.createElement(WarningMessage, { errors: errors, showOnly: showOnly })))),
        React.createElement(SelectedContainer, { showOnly: showOnly },
            React.createElement(Switch, { checked: Boolean(values.selected), color: "primary", label: intl.formatMessage({ id: "aBEJ+C", defaultMessage: "Display when bot is opened" }), classes: classes, onChange: function () { return (showOnly ? {} : handleToggleSeleted()); }, readOnly: showOnly }))));
};
export default injectIntl(ScheduleDateTime);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
