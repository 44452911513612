var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { injectIntl } from 'react-intl';
import { colors } from '@src/colors';
import fanpCharacter from '@src/assets/images/character.png';
var NotFoundContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  height: 100vh;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  text-align: center;\n  resize: vertical;\n\n  div {\n    display: block;\n    margin: 0 auto 1rem;\n    width: 80%;\n    font-size: 1.25rem;\n    font-weight: 600;\n\n    &:nth-child(1) {\n      margin: -", "px auto 1rem;\n      background: ", ";\n      border: 1px solid ", ";\n      border-radius: 10px;\n      padding: 2rem;\n      cursor: default;\n    }\n\n    img {\n      max-width: 120px;\n    }\n  }\n"], ["\n  width: 100%;\n  height: 100vh;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  text-align: center;\n  resize: vertical;\n\n  div {\n    display: block;\n    margin: 0 auto 1rem;\n    width: 80%;\n    font-size: 1.25rem;\n    font-weight: 600;\n\n    &:nth-child(1) {\n      margin: -", "px auto 1rem;\n      background: ", ";\n      border: 1px solid ", ";\n      border-radius: 10px;\n      padding: 2rem;\n      cursor: default;\n    }\n\n    img {\n      max-width: 120px;\n    }\n  }\n"])), 96 / 2, colors.white, colors.gray_lighter);
// Default redirect route is chatbot so the noLink is mean no redirect no where
var NotFound = function (_a) {
    var intl = _a.intl, noLink = _a.noLink;
    var txt = intl.formatMessage({ id: "C7zbxT", defaultMessage: "404 - Page not found" });
    var toChatbots = intl.formatMessage({ id: "0VTLUW", defaultMessage: "To Chatbot" });
    return (React.createElement(NotFoundContainer, { "data-testid": "NotFoundContainer" },
        React.createElement("div", null,
            React.createElement("h3", { "data-testid": "NotFoundContainer-Text" }, txt),
            !noLink && (React.createElement(Link, { to: "/dashboard/accounts/chatbots", "data-testid": "NotFoundContainer-Link" }, toChatbots))),
        React.createElement("div", null,
            React.createElement("img", { src: fanpCharacter, alt: "fanp character", "data-testid": "NotFoundContainer-Img" }))));
};
NotFound.defaultProps = {
    noLink: false,
};
export default injectIntl(NotFound);
var templateObject_1;
