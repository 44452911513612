var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import { lineFlexMessageStyles } from '@src/lib/app-constants';
var IconContainer = styled.span(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 1em;\n  display: inline-block;\n  overflow: hidden;\n  background-size: contain;\n  height: 1em;\n  font-size: 11px;\n  vertical-align: baseline;\n\n  background-image: ", ";\n  margin-left: ", ";\n  margin-top: ", ";\n"], ["\n  width: 1em;\n  display: inline-block;\n  overflow: hidden;\n  background-size: contain;\n  height: 1em;\n  font-size: 11px;\n  vertical-align: baseline;\n\n  background-image: ", ";\n  margin-left: ",
    ";\n  margin-top: ",
    ";\n"])), function (props) { return "url(" + props.url + ")"; }, function (props) {
    return props.parentLayout === 'vertical'
        ? 'unset'
        : lineFlexMessageStyles.margins[props.margin];
}, function (props) {
    return props.parentLayout === 'vertical'
        ? lineFlexMessageStyles.margins[props.margin]
        : 'unset';
});
var IconNode = function (_a) {
    var lineIcon = _a.lineIcon, parentLayout = _a.parentLayout;
    return (React.createElement(IconContainer, { url: lineIcon.proxy_image_url || lineIcon.url, margin: lineIcon.margin, parentLayout: parentLayout, "data-testid": "IconNode_IconContainer" }));
};
export default IconNode;
var templateObject_1;
