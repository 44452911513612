var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import { SendType } from '@src/typings/enums';
import { colors } from '@src/colors';
import { Button } from '@src/components/atoms';
import buttonTypes from '@src/lib/buttonTypes';
import { SelectComponent } from '@src/lib/HtmlElements';
import { getSendType } from '@src/lib/adHelpers';
import { StepDeliveryBtn } from './DeliveryBtns';
import TestingSelect from './TestingSelect';
import StepTimeSelector from './StepTimeSelector';
var OptionHeader = styled.span(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  font-size: 14px;\n  margin-bottom: 16px;\n"], ["\n  font-size: 14px;\n  margin-bottom: 16px;\n"])));
var StepDeliveryWindow = function (_a) {
    var currentScenario = _a.currentScenario, step = _a.step, scenarioErrors = _a.scenarioErrors, onFilterSelectChange = _a.onFilterSelectChange, handleUserFilterClick = _a.handleUserFilterClick, handleTestingChange = _a.handleTestingChange, handleStepScheduleDelivery = _a.handleStepScheduleDelivery, handleStepDateChange = _a.handleStepDateChange, intl = _a.intl;
    var sendType = getSendType(currentScenario);
    return (React.createElement(React.Fragment, null,
        React.createElement(OptionHeader, null, "\u30E6\u30FC\u30B6\u30FC\u306E\u7D5E\u308A\u8FBC\u307F\u3092\u9078\u629E"),
        React.createElement(SelectComponent, { margin: "0 0 16px 0", color: colors.default_text },
            React.createElement("select", { onChange: onFilterSelectChange, value: sendType },
                React.createElement("option", { value: SendType.All }, "\u5168\u54E1"),
                React.createElement("option", { value: SendType.Filtered }, "\u30E6\u30FC\u30B6\u30FC\u3092\u7D5E\u308A\u8FBC\u3080")),
            React.createElement("span", { className: "adicon_down" })),
        sendType === SendType.Filtered ? (React.createElement(Button, { onClick: handleUserFilterClick, buttonType: buttonTypes.SETTING, text: "\u7D5E\u308A\u8FBC\u307F\u6761\u4EF6\u3092\u8A2D\u5B9A", width: 200, margin: "0 0 16px 0" })) : null,
        React.createElement(OptionHeader, null, "\u914D\u4FE1\u8A2D\u5B9A"),
        React.createElement(TestingSelect, { handleTestingChange: handleTestingChange, testing: currentScenario.testing, intl: intl }),
        React.createElement(StepTimeSelector, { step: step, handleStepDateChange: handleStepDateChange }),
        React.createElement(StepDeliveryBtn, { scenarioErrors: scenarioErrors, currentScenario: currentScenario, step: step, handleStepScheduleDelivery: handleStepScheduleDelivery })));
};
export default StepDeliveryWindow;
var templateObject_1;
