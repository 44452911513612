import "core-js/modules/es.array.filter";
import "core-js/modules/es.array.map";
import "core-js/modules/es.object.values";
import _extends from "@babel/runtime/helpers/extends";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useDrag, useDrop } from 'react-dnd';
import { flexMessageTypes } from '@src/redux/fanp/constants/otherConstants';
import { bubbleChildTypes } from '@src/lib/app-constants';
import Block from './Block';

var Bubble = function Bubble(_ref) {
  var content = _ref.content,
      chatbotIdParam = _ref.chatbotIdParam,
      readOnly = _ref.readOnly,
      initials = _ref.initials,
      swapBubbleRanks = _ref.swapBubbleRanks,
      setLineFlexMessageComponent = _ref.setLineFlexMessageComponent,
      rest = _objectWithoutProperties(_ref, ["content", "chatbotIdParam", "readOnly", "initials", "swapBubbleRanks", "setLineFlexMessageComponent"]);

  var ref;

  if (!readOnly) {
    ref = useRef(null);

    var _useDrop = useDrop({
      accept: flexMessageTypes.BUBBLE,
      drop: function drop(dragItem) {
        if (!ref.current) return;
        var sourceItemRank = dragItem.sourceItemRank,
            sourceItemId = dragItem.sourceItemId;
        var targetItemId = content.id;
        var targetItemRank = content.rank;
        if (sourceItemId === targetItemId) return;
        var hoverBoundingRect = ref.current.getBoundingClientRect();
        var hoverMiddleX = (hoverBoundingRect.right + hoverBoundingRect.left) / 2;
        if (sourceItemRank < targetItemRank && hoverBoundingRect.right < hoverMiddleX) return;
        if (sourceItemRank > targetItemRank && hoverBoundingRect.left > hoverMiddleX) return;
        swapBubbleRanks(sourceItemId, sourceItemRank, targetItemId, targetItemRank);
      }
    }),
        _useDrop2 = _slicedToArray(_useDrop, 2),
        drop = _useDrop2[1];

    var _useDrag = useDrag({
      item: {
        type: flexMessageTypes.BUBBLE,
        sourceItemId: content.id,
        sourceItemRank: content.rank
      }
    }),
        _useDrag2 = _slicedToArray(_useDrag, 2),
        drag = _useDrag2[1];

    drag(drop(ref));
  }

  var types = Object.values(bubbleChildTypes).filter(function (type) {
    return content[type];
  });
  return (// It add lfm-bubble-editing when contain text editing, it will be effect to
    // allow color picker modal show out side bubble
    React.createElement("div", {
      className: "lfm-bubble ".concat(rest.editing ? 'lfm-bubble-editing' : ''),
      ref: ref
    }, types.map(function (type, i) {
      var style = content.styles && content.styles[type] || {};
      var bubbleContent = content[type];
      return React.createElement(Block, _extends({
        key: i,
        type: type,
        content: bubbleContent,
        style: style,
        chatbotIdParam: chatbotIdParam,
        readOnly: readOnly,
        initials: initials,
        isFirstComponent: i === 0,
        isLastComponent: i === types.length - 1,
        setLineFlexMessageComponent: setLineFlexMessageComponent
      }, rest));
    }))
  );
};

Bubble.propTypes = {
  chatbotIdParam: PropTypes.number,
  readOnly: PropTypes.bool,
  initials: PropTypes.arrayOf(PropTypes.object),
  optionDisable: PropTypes.bool,
  content: PropTypes.shape({
    header: PropTypes.object,
    hero: PropTypes.object,
    body: PropTypes.object,
    footer: PropTypes.object,
    styles: PropTypes.object
  }).isRequired
};
export default Bubble;