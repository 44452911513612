import "core-js/modules/es.array.filter";
import "core-js/modules/es.array.join";
import "core-js/modules/es.regexp.constructor";
import "core-js/modules/es.regexp.to-string";
import "core-js/modules/es.string.split";
import escapeAllSpecialChars from './escapeAllSpecialChars';
export default function filterObjectsByText(objects, attribute, searchText) {
  var regStr = escapeAllSpecialChars(searchText).split(' ').filter(Boolean).join('|');
  return objects === null || objects === void 0 ? void 0 : objects.filter(function (object) {
    return new RegExp(regStr, 'gi').exec(object[attribute]);
  });
}