var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { makeFetchInit, handleAPIErrors } from '@src/lib/helpers';
import { singleAdScenarioNormalizer } from '@src/lib/dataNormalizer';
import { makeAdUrl } from '@src/lib/adHelpers';
import endpoints from '@src/endpoints';
import { chatTypes } from '@src/lib/chat-types';
import { adScenario } from '@src/redux/fanp/constants';
import { buttonTypes, mediaTypes } from '@src/lib/app-constants';
import * as blockApi from '@src/services/api/block';
import * as scenarioApi from '@src/services/api/scenario';
import * as userTagApi from '@src/services/api/userTag';
import * as buttonItemApi from '@src/services/api/buttonItems';
import * as genericMessageApi from '@src/services/api/genericMessage';
import * as talkTriggerApi from '@src/services/api/talkTrigger';
import * as mediaApi from '@src/services/api/media';
import { createNewBlock } from './otherActions';
import { addCreatedTag, adHandleAddNewTextButton, adHandleAddNewGenericButton, handleStepCampaignContent, addNewItem, } from './ajaxActions';
import { setAdScenario, updateSelectedUserTagGroup, updateQuickReplyUserTagGroup, setSelectedUserTagGroupId, } from './setActions';
import { editScenario } from './editActions';
var SET_USER_TAG_GROUP = adScenario.SET_USER_TAG_GROUP, ADD_AD_SCENARIO = adScenario.ADD_AD_SCENARIO, AD_CREATE_NEW_SCENARIO_DELIVERY = adScenario.AD_CREATE_NEW_SCENARIO_DELIVERY, AD_ADD_FREE_INPUT = adScenario.AD_ADD_FREE_INPUT, AD_ADD_FREE_INPUT_FROM_FIELD = adScenario.AD_ADD_FREE_INPUT_FROM_FIELD, UNDO_AD_SCENARIO = adScenario.UNDO_AD_SCENARIO, ADD_NEW_MESSAGE_SECTION = adScenario.ADD_NEW_MESSAGE_SECTION, 
// LOADING
LOADING_ITEM = adScenario.LOADING_ITEM;
export var setUserTagGroup = function (userTagGroup) { return ({
    type: SET_USER_TAG_GROUP,
    userTagGroup: userTagGroup,
}); };
export var addAdScenario = function (_a) {
    var adScenario = _a.adScenario, chatTypeParam = _a.chatTypeParam;
    // serialize scenario, send scenario, layers, blocks down to reducer
    var normalizedData = singleAdScenarioNormalizer(adScenario);
    var scenarios = normalizedData.scenarios, layers = normalizedData.layers, blocks = normalizedData.blocks;
    return {
        type: ADD_AD_SCENARIO,
        scenarios: scenarios,
        layers: layers,
        blocks: blocks,
        chatTypeParam: chatTypeParam,
    };
};
export var createNewScenarioDelivery = function (_a) {
    var chatbotId = _a.chatbotId, scenarioId = _a.scenarioId, scheduledAt = _a.scheduledAt;
    return ({
        type: AD_CREATE_NEW_SCENARIO_DELIVERY,
        chatbotId: chatbotId,
        scenarioId: scenarioId,
        scheduledAt: scheduledAt,
    });
};
export var addFreeInput = function (blockId, talkTrigger) { return ({
    type: AD_ADD_FREE_INPUT,
    blockId: blockId,
    talkTrigger: talkTrigger,
}); };
export var addFreeInputFromField = function (_a) {
    var blockId = _a.blockId, talkTrigger = _a.talkTrigger;
    return ({
        type: AD_ADD_FREE_INPUT_FROM_FIELD,
        blockId: blockId,
        talkTrigger: talkTrigger,
    });
};
export var itemLoading = function (isLoading) {
    return {
        type: LOADING_ITEM,
        isLoading: isLoading,
    };
};
export var addNewMessageSection = function (message) { return ({
    type: ADD_NEW_MESSAGE_SECTION,
    message: message,
}); };
export var adValidateScenario = function (_a) {
    var chatbotId = _a.chatbotId, adScenarioId = _a.adScenarioId;
    return function () {
        var url = endpoints.scenarios.validate(chatbotId, adScenarioId);
        fetch(url, makeFetchInit('POST', {}))
            .then(function (res) { return res.json(); })
            .then(function (payload) {
            if (payload.status && payload.status >= 400) {
                handleAPIErrors("\u4E0D\u6B63\u306A\u30B7\u30CA\u30EA\u30AA\u3067\u3059: " + payload.message);
            }
            else {
                alert('正常なシナリオです');
            }
        });
    };
};
export var addBlockToButtonTemplate = function (_a) {
    var chatbotId = _a.chatbotId, adScenarioId = _a.adScenarioId, messageId = _a.messageId, itemId = _a.itemId, itemIterator = _a.itemIterator, layerRank = _a.layer_rank, effect = _a.effect, name = _a.name, blockName = _a.blockName;
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        var body, buttonItem, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    body = {
                        name: name,
                        effect: effect,
                        generic_item_id: itemId,
                    };
                    return [4 /*yield*/, buttonItemApi.addBlockToButtonTemplate(chatbotId, adScenarioId, messageId, body)];
                case 1:
                    buttonItem = _a.sent();
                    dispatch(adHandleAddNewTextButton({
                        messageId: messageId,
                        newButton: buttonItem,
                        scenarioId: adScenarioId,
                    }));
                    dispatch(createNewBlock({
                        chatbotId: chatbotId,
                        adScenarioId: adScenarioId,
                        messageId: messageId,
                        buttonId: buttonItem.button.id,
                        itemIterator: itemIterator,
                        label: name,
                        name: blockName,
                        layer_rank: layerRank,
                    }));
                    return [3 /*break*/, 3];
                case 2:
                    e_1 = _a.sent();
                    console.error('Could not create button: ', e_1);
                    handleAPIErrors('Could not create button', e_1);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
};
export var addBlockToGenericButton = function (_a) {
    var chatbotId = _a.chatbotId, adScenarioId = _a.adScenarioId, messageId = _a.messageId, itemId = _a.itemId, itemIterator = _a.itemIterator, layerRank = _a.layer_rank, effect = _a.effect, name = _a.name, blockName = _a.blockName;
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        var genericItemButton, e_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, genericMessageApi.connectBlockToButton({
                            chatbotId: chatbotId,
                            scenarioId: adScenarioId,
                            messageId: messageId,
                            genericItemId: itemId,
                            layerRank: layerRank,
                            effect: effect,
                            name: name,
                        })];
                case 1:
                    genericItemButton = _a.sent();
                    dispatch(adHandleAddNewGenericButton({
                        messageId: messageId,
                        listIterator: itemIterator,
                        newButton: genericItemButton,
                        scenarioId: adScenarioId,
                    }));
                    dispatch(createNewBlock({
                        chatbotId: chatbotId,
                        adScenarioId: adScenarioId,
                        messageId: messageId,
                        label: name,
                        name: blockName,
                        layer_rank: layerRank,
                        buttonId: genericItemButton.button.id,
                        itemIterator: itemIterator,
                        listType: 'generic',
                    }));
                    return [3 /*break*/, 3];
                case 2:
                    e_2 = _a.sent();
                    handleAPIErrors('Could not create button', e_2);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
};
export var addNewButtonToText = function (_a) {
    var chatbotId = _a.chatbotId, scenarioId = _a.scenarioId, messageId = _a.messageId, buttonTemplate = _a.buttonTemplate, blockId = _a.blockId, layerRank = _a.layerRank, nextScenarioId = _a.nextScenarioId;
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        var body, buttonItem, e_3;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    body = {
                        name: buttonTemplate.effect === buttonTypes.SHARE
                            ? 'シェアする'
                            : buttonTemplate.name,
                        url: buttonTemplate.url,
                        effect: buttonTemplate.effect,
                        next_block_id: null,
                        next_scenario_id: null,
                        layer_rank: null,
                    };
                    if (blockId) {
                        body.effect = null;
                        body.next_block_id = blockId;
                        body.layer_rank = layerRank;
                    }
                    if (nextScenarioId) {
                        body.effect = null;
                        body.next_scenario_id = nextScenarioId;
                    }
                    return [4 /*yield*/, buttonItemApi.addButtonToTextMessage(chatbotId, scenarioId, messageId, body)];
                case 1:
                    buttonItem = _a.sent();
                    dispatch(adHandleAddNewTextButton({
                        messageId: messageId,
                        scenarioId: scenarioId,
                        newButton: buttonItem,
                    }));
                    return [3 /*break*/, 3];
                case 2:
                    e_3 = _a.sent();
                    console.error('Could not create button: ', e_3);
                    handleAPIErrors('Could not create button', e_3);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
};
export var addNewButtonToGeneric = function (_a) {
    var listIterator = _a.listIterator, chatbotId = _a.chatbotId, scenarioId = _a.scenarioId, messageId = _a.messageId, itemId = _a.itemId, buttonTemplate = _a.buttonTemplate, blockId = _a.blockId, nextScenarioId = _a.nextScenarioId, layerRank = _a.layerRank;
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        var genericItemButton, e_4;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, genericMessageApi.createNewButton({
                            chatbotId: chatbotId,
                            scenarioId: scenarioId,
                            messageId: messageId,
                            genericItemId: itemId,
                            buttonTemplate: buttonTemplate,
                            blockId: blockId,
                            nextScenarioId: nextScenarioId,
                            layerRank: layerRank,
                        })];
                case 1:
                    genericItemButton = _a.sent();
                    dispatch(adHandleAddNewGenericButton({
                        messageId: messageId,
                        listIterator: listIterator,
                        newButton: genericItemButton,
                        scenarioId: scenarioId,
                    }));
                    return [3 /*break*/, 3];
                case 2:
                    e_4 = _a.sent();
                    handleAPIErrors('ボタンを作成できませんでした。', e_4);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
};
export var addNewTag = function (_a) {
    var name = _a.name, itemIterator = _a.itemIterator, chatbotId = _a.chatbotId, scenarioId = _a.scenarioId, messageId = _a.messageId, itemId = _a.itemId, tagsLength = _a.tagsLength, errMsg = _a.errMsg;
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        var tag, e_5;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, genericMessageApi.createTag({
                            name: name,
                            chatbotId: chatbotId,
                            scenarioId: scenarioId,
                            messageId: messageId,
                            itemId: itemId,
                        })];
                case 1:
                    tag = _a.sent();
                    dispatch(addCreatedTag({
                        messageId: messageId,
                        itemIterator: itemIterator,
                        tagIterator: tagsLength,
                        newTag: tag,
                    }));
                    return [3 /*break*/, 3];
                case 2:
                    e_5 = _a.sent();
                    if (e_5.status === 404) {
                        handleAPIErrors('タグの作成に必要なidを見つかりません。', e_5);
                    }
                    else {
                        handleAPIErrors('同じ名前のタグは作成できません。', errMsg);
                    }
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
};
export var createNewFreeInput = function (_a) {
    var chatbotId = _a.chatbotId, adScenarioId = _a.adScenarioId, blockId = _a.blockId;
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        var talkTrigger;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, talkTriggerApi.createTalkTrigger(chatbotId, adScenarioId, blockId)];
                case 1:
                    talkTrigger = _a.sent();
                    dispatch(addFreeInput(blockId, talkTrigger));
                    return [2 /*return*/];
            }
        });
    }); };
};
export var createNewFreeInputFromField = function (_a) {
    var chatbotId = _a.chatbotId, blockId = _a.blockId, fieldId = _a.fieldId;
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        var talkTriggerRes;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, talkTriggerApi.createTalkTriggerFromField(chatbotId, fieldId, blockId)];
                case 1:
                    talkTriggerRes = _a.sent();
                    dispatch(addFreeInputFromField({ blockId: blockId, talkTrigger: talkTriggerRes.talk_trigger }));
                    return [2 /*return*/, talkTriggerRes];
            }
        });
    }); };
};
export var createNewMedia = function (_a) {
    var chatbotId = _a.chatbotId, scenarioId = _a.scenarioId, blockId = _a.blockId, _b = _a.mediaType, mediaType = _b === void 0 ? mediaTypes.IMAGE : _b;
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        var message, e_6;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, mediaApi.createMedia(chatbotId, scenarioId, blockId, mediaType)];
                case 1:
                    message = _a.sent();
                    dispatch(addNewMessageSection(message));
                    return [3 /*break*/, 3];
                case 2:
                    e_6 = _a.sent();
                    handleAPIErrors('Request failed', e_6);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
};
export var createNewGeneric = function (_a) {
    var chatbotId = _a.chatbotId, scenarioId = _a.scenarioId, blockId = _a.blockId;
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        var message, e_7;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, genericMessageApi.createGenericMessage(chatbotId, scenarioId, blockId)];
                case 1:
                    message = _a.sent();
                    dispatch(addNewMessageSection(message));
                    return [3 /*break*/, 3];
                case 2:
                    e_7 = _a.sent();
                    handleAPIErrors('Request failed', e_7);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
};
export var createMessageSection = function (_a) {
    var chatbotId = _a.chatbotId, scenarioId = _a.scenarioId, blockId = _a.blockId, messageType = _a.messageType, _b = _a.mediaType, mediaType = _b === void 0 ? mediaTypes.IMAGE : _b;
    return function (dispatch) {
        var messageDict = {
            text: 'messages',
            media: 'media',
            generic: 'genericTemplates',
        };
        var url = endpoints[messageDict[messageType]].collection(parseInt(chatbotId), parseInt(scenarioId));
        var body = {
            block_id: blockId,
            type: mediaType,
        };
        fetch(url, makeFetchInit('POST', body))
            .then(function (res) { return res.json(); })
            .then(function (_a) {
            var message = _a.message;
            dispatch(addNewMessageSection(message));
        })
            .catch(function (e) { return handleAPIErrors('Request failed', e); });
    };
};
export var adCreateCampaignContent = function (_a) {
    var chatbotId = _a.chatbotId, adScenarioId = _a.adScenarioId, campaignContent = _a.campaignContent;
    return function (dispatch) {
        var url = endpoints.campaignContents.collection({
            chatbotId: chatbotId,
            adScenarioId: adScenarioId,
        });
        var daysAfter = campaignContent.daysAfter, deliveryTime = campaignContent.deliveryTime, lowestCampaignId = campaignContent.lowestCampaignId;
        var body = {
            days_after: daysAfter,
            delivery_time: deliveryTime,
            chatbot_id: chatbotId,
            ad_scenario_id: adScenarioId,
            campaign_id: lowestCampaignId || null,
        };
        fetch(url, makeFetchInit('POST', body))
            .then(function (res) { return res.json(); })
            .then(function (data) {
            dispatch(handleStepCampaignContent(adScenarioId, data));
        })
            .catch(function (e) {
            handleAPIErrors('キャンペーンコンテンツを作成できませんでした。', e);
            dispatch({ type: UNDO_AD_SCENARIO });
        });
    };
};
export var pushMessageItem = function (_a) {
    var chatbotId = _a.chatbotId, scenarioId = _a.scenarioId, messageId = _a.messageId, itemsLength = _a.itemsLength;
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        var itemIterator, genericItem, e_8;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    itemIterator = itemsLength;
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, 4, 5]);
                    return [4 /*yield*/, genericMessageApi.createItem({
                            chatbotId: chatbotId,
                            scenarioId: scenarioId,
                            messageId: messageId,
                        })];
                case 2:
                    genericItem = _a.sent();
                    dispatch(addNewItem({
                        messageId: messageId,
                        itemIterator: itemIterator,
                        newItem: genericItem,
                    }));
                    return [3 /*break*/, 5];
                case 3:
                    e_8 = _a.sent();
                    handleAPIErrors('アイテムを作成できませんでした。', e_8);
                    dispatch({ type: UNDO_AD_SCENARIO });
                    return [3 /*break*/, 5];
                case 4:
                    dispatch(itemLoading(false));
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/];
            }
        });
    }); };
};
export var createTemplateScenario = function (_a) {
    var chatTypeParam = _a.chatTypeParam, chatbotId = _a.chatbotId, templateId = _a.templateId, newScenarioName = _a.newScenarioName;
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        var scenario;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, scenarioApi.createScenarioFromTemplate(chatbotId, {
                        chatType: chatTypeParam,
                        templateId: templateId,
                        name: newScenarioName,
                    })];
                case 1:
                    scenario = _a.sent();
                    dispatch(setAdScenario(scenario));
                    return [2 /*return*/];
            }
        });
    }); };
};
export var createNewAdScenario = function (_a) {
    var chatTypeParam = _a.chatTypeParam, chatbotId = _a.chatbotId, newAdScenarioName = _a.newAdScenarioName, campaignContent = _a.campaignContent;
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        var scenario, redirectUrl;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, scenarioApi.createScenario(chatbotId, {
                        chatType: chatTypeParam,
                    })];
                case 1:
                    scenario = _a.sent();
                    redirectUrl = makeAdUrl({
                        chatTypeParam: chatTypeParam,
                        chatbotId: chatbotId,
                        adScenarioId: scenario.id,
                        layerId: scenario.layers[0].id,
                        blockId: scenario.layers[0].blocks[0].id,
                        rank: scenario.layers[0].rank,
                    });
                    if (window.globalRouterHistory) {
                        window.globalRouterHistory.push(redirectUrl);
                    }
                    if (!(chatTypeParam === chatTypes.STEP)) return [3 /*break*/, 3];
                    return [4 /*yield*/, dispatch(adCreateCampaignContent({
                            chatbotId: chatbotId,
                            adScenarioId: scenario.id,
                            campaignContent: campaignContent,
                        }))];
                case 2:
                    _a.sent();
                    _a.label = 3;
                case 3: return [4 /*yield*/, dispatch(addAdScenario({
                        adScenario: scenario,
                        chatTypeParam: chatTypeParam,
                    }))];
                case 4:
                    _a.sent();
                    return [4 /*yield*/, dispatch(editScenario(chatbotId, scenario.id, { name: newAdScenarioName }))];
                case 5:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
};
export var adDuplicateBlock = function (_a) {
    var chatbotId = _a.chatbotId, adScenarioId = _a.adScenarioId, blockId = _a.blockId;
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        var scenario;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, blockApi.copyBlock(chatbotId, adScenarioId, blockId)];
                case 1:
                    scenario = _a.sent();
                    dispatch(setAdScenario(scenario));
                    return [2 /*return*/];
            }
        });
    }); };
};
export var createUserTagGroup = function (inputQuestionValue, chatbotIdParam) { return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
    var userTagGroup, e_9;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                if (!inputQuestionValue)
                    throw new Error('labelを入力してください');
                return [4 /*yield*/, userTagApi.createUserTagGroup(chatbotIdParam, inputQuestionValue)];
            case 1:
                userTagGroup = _a.sent();
                dispatch(setUserTagGroup(userTagGroup));
                dispatch(setSelectedUserTagGroupId(userTagGroup.id)); // TODO: Need to refactor this action
                return [3 /*break*/, 3];
            case 2:
                e_9 = _a.sent();
                handleAPIErrors('error', e_9);
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); }; };
export var createUserTag = function (inputAnswerValue, chatbotIdParam, selectedGroupId) { return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
    var userTag, e_10;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                if (!inputAnswerValue)
                    throw new Error('labelを入力してください');
                if (!selectedGroupId)
                    throw new Error('Groupを選択してください');
                return [4 /*yield*/, userTagApi.createUserTag(chatbotIdParam, selectedGroupId, inputAnswerValue)];
            case 1:
                userTag = _a.sent();
                dispatch(updateSelectedUserTagGroup(userTag));
                return [3 /*break*/, 3];
            case 2:
                e_10 = _a.sent();
                handleAPIErrors(e_10.message);
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); }; };
export var createUserTagInQuickReplyChoice = function (inputAnswerValue, chatbotIdParam, selectedGroupId, blockId) { return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
    var userTag, e_11;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                if (!inputAnswerValue)
                    throw new Error('labelを入力してください');
                if (!selectedGroupId)
                    throw new Error('Groupを選択してください');
                return [4 /*yield*/, userTagApi.createUserTag(chatbotIdParam, selectedGroupId, inputAnswerValue)];
            case 1:
                userTag = _a.sent();
                dispatch(updateQuickReplyUserTagGroup(blockId, userTag));
                return [3 /*break*/, 3];
            case 2:
                e_11 = _a.sent();
                handleAPIErrors('Failed to create user tag', e_11);
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); }; };
