var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import React, { useCallback, useState, useEffect, useMemo, } from 'react';
import { injectIntl } from 'react-intl';
import { blue, navy } from '@zeals-co-ltd/washi-styles';
import UserDataBaseTable from '@src/components/organisms/fanp/UserDataBase/UserDataBaseTable';
import UserInsight from '@src/components/organisms/fanp/UserDataBase/UserInsight';
import UserSegmentFilters from '@src/components/organisms/fanp/UserDataBase/UserSegmentFilters';
import featureFlags from '@src/lib/feature_flags/featureFlags';
import styled from 'styled-components';
import Grid from '@mui/material/Grid';
import { getUserDataBaseList, searchUser, } from '@src/services/api/userDatabase';
import { useParams } from 'react-router-dom';
import { LoadingScreen } from '@src/components/atoms/';
import { TextField, Icon, Snackbar } from '@zeals-co-ltd/washi-components';
import useSnackbarNotify from '@src/components/hooks/useSnackbarNotify';
import { handleAPIErrors } from '@src/lib/helpers/ajaxFuncs';
import { Platform } from '@src/typings/enums';
import { useSelector } from 'react-redux';
import * as userDossierGrpc from '@src/services/zeals_grpc/userDossier';
var UserDataBaseContainer = styled(Grid)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  padding: 32px;\n  height: calc(100vh - 56px);\n  flex-direction: column;\n"], ["\n  display: flex;\n  padding: 32px;\n  height: calc(100vh - 56px);\n  flex-direction: column;\n"])));
var UserDataBaseHeader = styled(Grid)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  align-items: flex-end;\n  h1 {\n    font-size: 24px;\n    font-weight: 500;\n    line-height: 35px;\n    letter-spacing: 0.15px;\n    text-align: left;\n    color: ", ";\n    margin-bottom: 12px;\n  }\n  h2 {\n    font-size: 16px;\n    font-weight: 400;\n    line-height: 23px;\n    letter-spacing: 0.5px;\n    color: black;\n  }\n\n  .autocompleteInput {\n    & .MuiOutlinedInput-root {\n      height: 32px;\n    }\n\n    & .MuiInputBase-input {\n      padding: 6px;\n      &::placeholder {\n        font-size: 14px;\n        line-height: 20px;\n        font-weight: 400;\n      }\n    }\n\n    & .MuiSvgIcon-root {\n      width: 15px;\n      height: 15px;\n      color: ", ";\n    }\n\n    margin-left: auto;\n    width: 308px;\n    margin-right: 0px;\n    display: flex;\n    align-self: flex-end;\n  }\n"], ["\n  display: flex;\n  flex-direction: row;\n  align-items: flex-end;\n  h1 {\n    font-size: 24px;\n    font-weight: 500;\n    line-height: 35px;\n    letter-spacing: 0.15px;\n    text-align: left;\n    color: ", ";\n    margin-bottom: 12px;\n  }\n  h2 {\n    font-size: 16px;\n    font-weight: 400;\n    line-height: 23px;\n    letter-spacing: 0.5px;\n    color: black;\n  }\n\n  .autocompleteInput {\n    & .MuiOutlinedInput-root {\n      height: 32px;\n    }\n\n    & .MuiInputBase-input {\n      padding: 6px;\n      &::placeholder {\n        font-size: 14px;\n        line-height: 20px;\n        font-weight: 400;\n      }\n    }\n\n    & .MuiSvgIcon-root {\n      width: 15px;\n      height: 15px;\n      color: ", ";\n    }\n\n    margin-left: auto;\n    width: 308px;\n    margin-right: 0px;\n    display: flex;\n    align-self: flex-end;\n  }\n"])), blue[900], navy[300]);
var UserDataBaseTableContainer = styled(Grid)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  height: 100%;\n  min-height: 0;\n  margin-top: 16px;\n  .gridHeight {\n    height: 100%;\n  }\n"], ["\n  display: flex;\n  height: 100%;\n  min-height: 0;\n  margin-top: 16px;\n  .gridHeight {\n    height: 100%;\n  }\n"])));
export var useEndusersByChatbotId = function (chatbotId) {
    var _a = __read(useState({
        end_users: [],
        end_user_number: undefined,
        last_page: undefined,
    }), 2), usersData = _a[0], setUsersData = _a[1];
    var _b = __read(useState(false), 2), loading = _b[0], setLoading = _b[1];
    var _c = __read(useState(50), 2), perPage = _c[0], setPerPage = _c[1];
    var _d = __read(useState(1), 2), page = _d[0], setPage = _d[1];
    var getUsersByChatbotId = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var responseUsers, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, 3, 4]);
                    setLoading(true);
                    return [4 /*yield*/, getUserDataBaseList(chatbotId, page, perPage)];
                case 1:
                    responseUsers = _a.sent();
                    setUsersData(responseUsers);
                    return [3 /*break*/, 4];
                case 2:
                    e_1 = _a.sent();
                    handleAPIErrors('No users found', e_1, true);
                    return [3 /*break*/, 4];
                case 3:
                    setLoading(false);
                    return [7 /*endfinally*/];
                case 4: return [2 /*return*/];
            }
        });
    }); }, [chatbotId, page, perPage]);
    useEffect(function () {
        getUsersByChatbotId();
    }, [chatbotId, getUsersByChatbotId, perPage, page]);
    return {
        usersData: usersData,
        setUsersData: setUsersData,
        loading: loading,
        setLoading: setLoading,
        perPage: perPage,
        setPerPage: setPerPage,
        page: page,
        setPage: setPage,
    };
};
var UserList = function (_a) {
    var intl = _a.intl;
    var params = useParams();
    var chatbotId = Number(params.chatbotId);
    // states
    var _b = __read(useState(), 2), selectedUserId = _b[0], setSelectedUserId = _b[1];
    var _c = __read(useState(''), 2), searchTerm = _c[0], setSearchTerm = _c[1];
    var _d = __read(useState([]), 2), filteredUsers = _d[0], setFilteredUsers = _d[1];
    var _e = __read(useState(new Set()), 2), selectedUserSegmentIds = _e[0], setSelectedUserSegmentIds = _e[1];
    var _f = __read(useState(''), 2), searchUserSegmentName = _f[0], setSearchUserSegmentName = _f[1];
    var _g = __read(useState([]), 2), userSegments = _g[0], setUserSegments = _g[1];
    var _h = __read(useState(false), 2), isFetchingUserSegments = _h[0], setIsFetchingUserSegments = _h[1];
    // hooks
    var _j = useEndusersByChatbotId(chatbotId), usersData = _j.usersData, loading = _j.loading, page = _j.page, perPage = _j.perPage, setPage = _j.setPage, setPerPage = _j.setPerPage;
    var selectedUser = useMemo(function () { return ((usersData === null || usersData === void 0 ? void 0 : usersData.end_users) || []).find(function (u) { return u.id === selectedUserId; }); }, [selectedUserId, usersData === null || usersData === void 0 ? void 0 : usersData.end_users]);
    var _k = useSnackbarNotify(), notify = _k.notify, clearNotify = _k.clearNotify, open = _k.open;
    var filteredUserSegments = useMemo(function () {
        var regex = new RegExp(searchUserSegmentName.toLowerCase(), 'i'); // Create a regex object (a constructor).
        return userSegments.filter(function (us) { return regex.test(us.name.toLowerCase()); });
    }, [userSegments, searchUserSegmentName]);
    var getUserSegments = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var userSegmentRes, e_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, 3, 4]);
                    setIsFetchingUserSegments(true);
                    return [4 /*yield*/, userDossierGrpc.searchUserSegment('', chatbotId)];
                case 1:
                    userSegmentRes = _a.sent();
                    setUserSegments(userSegmentRes);
                    return [3 /*break*/, 4];
                case 2:
                    e_2 = _a.sent();
                    handleAPIErrors(e_2);
                    return [3 /*break*/, 4];
                case 3:
                    setIsFetchingUserSegments(false);
                    return [7 /*endfinally*/];
                case 4: return [2 /*return*/];
            }
        });
    }); }, [chatbotId]);
    var onChangeUserSegmentIds = useCallback(function (event, isChecked, userSegmentId) {
        setSelectedUserSegmentIds(function (prev) {
            if (prev.has(userSegmentId)) {
                return new Set(__spreadArray([], __read(prev)).filter(function (x) { return x !== userSegmentId; }));
            }
            return new Set(__spreadArray(__spreadArray([], __read(prev)), [userSegmentId]));
        });
    }, []);
    var resetUserSegmentIds = function () {
        setSelectedUserSegmentIds(new Set());
    };
    var handleSearchTermEnterPress = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var ENTER_KEY, BACKSPACE_KEY, searchedUser, e_3;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    ENTER_KEY = 'Enter';
                    BACKSPACE_KEY = 'Backspace';
                    if (e.key === BACKSPACE_KEY) {
                        setFilteredUsers([]);
                        return [2 /*return*/];
                    }
                    if (!(e.key === ENTER_KEY && searchTerm !== '')) return [3 /*break*/, 4];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, searchUser(chatbotId, searchTerm)];
                case 2:
                    searchedUser = _a.sent();
                    setFilteredUsers([searchedUser]);
                    return [3 /*break*/, 4];
                case 3:
                    e_3 = _a.sent();
                    handleAPIErrors(intl.formatMessage({ id: "JvZ8cD", defaultMessage: "There are no users who meet this condition" }));
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var platformName = useSelector(function (state) { return state.settings.present.activeChatbot.platform; });
    var platformType = function () {
        switch (platformName) {
            case Platform.Line:
                return intl.formatMessage({ id: "u5yg+y", defaultMessage: "Search Zeals ID or Line ID" });
            case Platform.Messenger:
                return intl.formatMessage({ id: "+63cfm", defaultMessage: "Search Zeals ID or Messenger ID" });
            case Platform.Instagram:
                return intl.formatMessage({ id: "lTl5HI", defaultMessage: "Search Zeals ID or Instagram ID" });
            default:
                return '';
        }
    };
    return (usersData === null || usersData === void 0 ? void 0 : usersData.end_users) && !loading ? (React.createElement(UserDataBaseContainer, null,
        React.createElement(UserDataBaseHeader, { container: true },
            React.createElement(Grid, { item: true, md: 6 },
                React.createElement("h1", null, intl.formatMessage({ id: "pMMPed", defaultMessage: "UserDatabase" })),
                React.createElement("h2", null, intl.formatMessage({ id: "96Rpuv", defaultMessage: "{totalUsers} Users in the database" }, {
                    totalUsers: usersData.end_user_number || 0,
                }))),
            React.createElement(Grid, { item: true, md: 6 },
                React.createElement(TextField, { className: "autocompleteInput", placeholder: platformType(), value: searchTerm, onChange: function (e) {
                        setSearchTerm(e.target.value);
                    }, InputProps: {
                        startAdornment: React.createElement(Icon, { name: "adicon_search" }),
                        endAdornment: searchTerm && (React.createElement(Icon, { name: "adicon_close_circle", style: { cursor: 'pointer' }, "data-testid": "user-database-search-cancel-button", onClick: function () {
                                setSearchTerm('');
                                setFilteredUsers([]);
                            } })),
                    }, onKeyDown: handleSearchTermEnterPress, "data-testid": "user-database-search-bar" }))),
        React.createElement(UserDataBaseTableContainer, { container: true },
            featureFlags.UUD_USER_DATABASE_SEGMENT ? (React.createElement(Grid, { item: true, md: 2 },
                React.createElement(UserSegmentFilters, { isFetching: isFetchingUserSegments, searchTerm: searchUserSegmentName, setSearchTerm: setSearchUserSegmentName, resetUserSegmentIds: resetUserSegmentIds, selectedUserSegmentIds: selectedUserSegmentIds, userSegments: filteredUserSegments, onChangeUserSegmentIds: onChangeUserSegmentIds }))) : undefined,
            React.createElement(Grid, { item: true, className: "gridHeight", md: selectedUser ? 9 : 12 },
                React.createElement(UserDataBaseTable, { setSelectedUserId: setSelectedUserId, selectedUserId: selectedUserId, usersData: usersData, filteredUsers: filteredUsers, page: page, setPage: setPage, perPage: perPage, setPerPage: setPerPage })),
            selectedUser && (React.createElement(Grid, { item: true, className: "gridHeight", md: 3 },
                React.createElement(UserInsight, { setSelectedUserId: setSelectedUserId, selectedUser: selectedUser })))),
        React.createElement(Snackbar, { open: open, alertProps: { severity: notify.severity }, onClose: clearNotify, autoHideDuration: 3000 },
            React.createElement("span", null, notify.message)))) : (React.createElement(LoadingScreen, null));
};
export default injectIntl(UserList);
var templateObject_1, templateObject_2, templateObject_3;
