import "core-js/modules/es.array.concat";
var zealsAdmin = {
  collection: function collection() {
    return "/zeals_admin/api/custom_form_answers";
  },
  member: function member(id) {
    return "".concat(zealsAdmin.collection(), "/").concat(id);
  },
  destroyAll: function destroyAll() {
    return "".concat(zealsAdmin.collection(), "/destroy_all");
  }
};
export default {
  zealsAdmin: zealsAdmin
};