import "core-js/modules/es.array.concat";
import "core-js/modules/es.array.map";
import "core-js/modules/es.function.name";
import "core-js/modules/es.string.bold";
import React, { Fragment } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { adScenarioTypes } from '@src/lib/app-constants';
import { colors } from '@src/colors';
import EventLogList from '@src/components/organisms/fanp/EventLogList';
var propTypes = {
  adScenarios: PropTypes.array,
  quickReplies: PropTypes.array,
  conversationMessages: PropTypes.array
};
var Container = styled.div.withConfig({
  displayName: "Container",
  componentId: "sc-1jnx7f0-0"
})(["width:100%;display:grid;grid-template-columns:1fr 1fr;grid-template-rows:1fr 1fr;padding:16px;min-height:0;min-width:0;"]);
var ScenarioContent = styled.div.withConfig({
  displayName: "ScenarioContent",
  componentId: "sc-1jnx7f0-1"
})(["background:", ";display:grid;grid-template-columns:repeat(3,1fr);grid-auto-rows:min-content;border-radius:8px;padding:16px;font-size:12px;margin-right:16px;overflow:auto;.title{font-size:16px;}.table-headers{grid-row:2;color:", ";padding:8px 0;border-bottom:1px solid ", ";width:100%;text-align:center;}"], colors.background.main_menu, colors.text_pale, colors.border.pale);
var SecondColumnContent = styled.span.withConfig({
  displayName: "SecondColumnContent",
  componentId: "sc-1jnx7f0-2"
})(["width:100%;grid-column:2/3;padding:16px 0;text-align:center;border-bottom:1px solid ", ";p{border-left:1px solid ", ";&.user-quick-choices{width:18rem;padding-left:10px;overflow:auto;&::-webkit-scrollbar{display:none;}}}"], colors.border.pale, colors.border.pale);
var FirstColumnContent = styled(SecondColumnContent).withConfig({
  displayName: "FirstColumnContent",
  componentId: "sc-1jnx7f0-3"
})(["grid-column:1/2;text-align:left;p{border-left:none;}"]);
var ThirdColumnContent = styled(SecondColumnContent).withConfig({
  displayName: "ThirdColumnContent",
  componentId: "sc-1jnx7f0-4"
})(["grid-column:3/4;"]);
var QuickReplyContent = styled(ScenarioContent).withConfig({
  displayName: "QuickReplyContent",
  componentId: "sc-1jnx7f0-5"
})(["grid-row:2;grid-template-columns:1fr 2fr;margin-top:16px;"]);
var ConversationContent = styled.div.withConfig({
  displayName: "ConversationContent",
  componentId: "sc-1jnx7f0-6"
})(["border-radius:8px;padding:16px;grid-row:1 / end;background:", ";overflow:auto;"], colors.background.main_menu);
var Choice = styled.span.withConfig({
  displayName: "Choice",
  componentId: "sc-1jnx7f0-7"
})(["font-weight:", ";"], function (props) {
  return props.bold ? 'bold' : 'none';
});

var UserContent = function UserContent(_ref) {
  var adScenarios = _ref.adScenarios,
      quickReplies = _ref.quickReplies,
      conversationMessages = _ref.conversationMessages;
  var hasAdScenarios = adScenarios.length;
  var hasQuickReplies = quickReplies.length;
  var hasConversationMessages = conversationMessages.length;
  return React.createElement(Container, null, React.createElement(ScenarioContent, null, React.createElement("span", {
    className: "title"
  }, "\u30B7\u30CA\u30EA\u30AA"), React.createElement("span", {
    className: "table-headers"
  }, "\u30B7\u30CA\u30EA\u30AA\u540D"), React.createElement("span", {
    className: "table-headers"
  }, "\u30BF\u30A4\u30D7"), React.createElement("span", {
    className: "table-headers"
  }, "\u72B6\u614B"), hasAdScenarios ? adScenarios.map(function (scenario, i) {
    return React.createElement(Fragment, {
      key: "".concat(i, "-user-adScenario")
    }, React.createElement(FirstColumnContent, null, React.createElement("p", null, scenario.name)), React.createElement(SecondColumnContent, null, React.createElement("p", null, adScenarioTypes[scenario.type])), React.createElement(ThirdColumnContent, null, React.createElement("p", null, "\u901A\u904E")));
  }) : null), React.createElement(QuickReplyContent, null, React.createElement("span", {
    className: "title"
  }, "\u30AF\u30A4\u30C3\u30AF\u30EA\u30D7\u30E9\u30A4"), React.createElement("span", {
    className: "table-headers"
  }, "\u30E9\u30D9\u30EB"), React.createElement("span", {
    className: "table-headers"
  }, "\u30AF\u30A4\u30C3\u30AF\u30EA\u30D7\u30E9\u30A4"), hasQuickReplies ? quickReplies.map(function (quickReply, i) {
    return React.createElement(Fragment, {
      key: "".concat(i, "-user-adScenario")
    }, React.createElement(FirstColumnContent, null, React.createElement("p", null, quickReply.label)), React.createElement(SecondColumnContent, null, React.createElement("p", {
      className: "user-quick-choices"
    }, quickReply.quick_reply_choices.map(function (choice, i) {
      return React.createElement(Choice, {
        key: "".concat(i, "-user-quick-replies"),
        bold: choice.clicked
      }, "".concat(choice.name).concat(i === quickReply.quick_reply_choices.length - 1 ? '' : ','));
    }))));
  }) : null), React.createElement(ConversationContent, null, React.createElement("span", {
    className: "title"
  }, "\u4F1A\u8A71"), React.createElement(EventLogList, {
    eventLogs: hasConversationMessages ? conversationMessages : []
  })));
};

UserContent.propTypes = propTypes;
export default UserContent;