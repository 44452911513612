var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import axiosInstance from './axiosInstance';
import transformResponse from './transformResponse';
var collection = function (_a) {
    var chatbotId = _a.chatbotId, scenarioId = _a.scenarioId;
    return "/chatbots/" + chatbotId + "/ad_scenarios/" + scenarioId + "/rich_menus";
};
var member = function (_a) {
    var chatbotId = _a.chatbotId, scenarioId = _a.scenarioId, richMenuId = _a.richMenuId;
    return "/chatbots/" + chatbotId + "/ad_scenarios/" + scenarioId + "/rich_menus/" + richMenuId;
};
var uploadToLineURL = function (_a) {
    var chatbotId = _a.chatbotId, scenarioId = _a.scenarioId, richMenuId = _a.richMenuId;
    return member({ chatbotId: chatbotId, scenarioId: scenarioId, richMenuId: richMenuId }) + "/upload_rich_menu";
};
var richMenuAreaCollection = function (_a) {
    var chatbotId = _a.chatbotId, scenarioId = _a.scenarioId, richMenuId = _a.richMenuId;
    return "/chatbots/" + chatbotId + "/ad_scenarios/" + scenarioId + "/rich_menus/" + richMenuId + "/rich_menu_areas";
};
var richMenuAreaMember = function (_a) {
    var richMenuAreaId = _a.richMenuAreaId, chatbotId = _a.chatbotId, scenarioId = _a.scenarioId, richMenuId = _a.richMenuId;
    return richMenuAreaCollection({
        chatbotId: chatbotId,
        scenarioId: scenarioId,
        richMenuId: richMenuId,
    }) + "/" + richMenuAreaId;
};
var richMenuTemplatesCollection = function () { return '/rich_menu_templates'; };
var richMenuTemplatesMember = function (id) {
    return richMenuTemplatesCollection() + "/" + id;
};
export function createRichMenu(chatbotId, scenarioId, body) {
    return __awaiter(this, void 0, void 0, function () {
        var url, res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    url = collection({ chatbotId: chatbotId, scenarioId: scenarioId });
                    return [4 /*yield*/, transformResponse(axiosInstance.post(url, body))];
                case 1:
                    res = _a.sent();
                    return [2 /*return*/, res.message];
            }
        });
    });
}
export function uploadRichMenuToLine(chatbotId, scenarioId, richMenuId, body) {
    return __awaiter(this, void 0, void 0, function () {
        var url, res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    url = uploadToLineURL({ chatbotId: chatbotId, scenarioId: scenarioId, richMenuId: richMenuId });
                    return [4 /*yield*/, transformResponse(axiosInstance.post(url, body))];
                case 1:
                    res = _a.sent();
                    return [2 /*return*/, res.message];
            }
        });
    });
}
export function updateRichMenu(chatbotId, scenarioId, richMenuId, body) {
    return __awaiter(this, void 0, void 0, function () {
        var url, res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    url = member({ chatbotId: chatbotId, scenarioId: scenarioId, richMenuId: richMenuId });
                    return [4 /*yield*/, transformResponse(axiosInstance.put(url, body))];
                case 1:
                    res = _a.sent();
                    return [2 /*return*/, res.message];
            }
        });
    });
}
export function updateRichImageMenu(chatbotId, scenarioId, richMenuId, body) {
    return __awaiter(this, void 0, void 0, function () {
        var url, res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    url = member({ chatbotId: chatbotId, scenarioId: scenarioId, richMenuId: richMenuId }) + "/update_image";
                    return [4 /*yield*/, transformResponse(axiosInstance.put(url, body))];
                case 1:
                    res = _a.sent();
                    return [2 /*return*/, res.message];
            }
        });
    });
}
export function deleteRichMenu(chatbotId, scenarioId, richMenuId, body) {
    return __awaiter(this, void 0, void 0, function () {
        var url, res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    url = member({ chatbotId: chatbotId, scenarioId: scenarioId, richMenuId: richMenuId });
                    return [4 /*yield*/, transformResponse(axiosInstance.delete(url, {
                            data: body,
                        }))];
                case 1:
                    res = _a.sent();
                    return [2 /*return*/, res.message];
            }
        });
    });
}
export function updateRichMenuArea(chatbotId, scenarioId, richMenuAreaId, richMenuId, body) {
    return __awaiter(this, void 0, void 0, function () {
        var url, res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    url = richMenuAreaMember({
                        richMenuAreaId: richMenuAreaId,
                        chatbotId: chatbotId,
                        scenarioId: scenarioId,
                        richMenuId: richMenuId,
                    });
                    return [4 /*yield*/, transformResponse(axiosInstance.put(url, body))];
                case 1:
                    res = _a.sent();
                    return [2 /*return*/, res];
            }
        });
    });
}
export function getRichMenuTemplates() {
    return __awaiter(this, void 0, void 0, function () {
        var url, res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    url = richMenuTemplatesCollection();
                    return [4 /*yield*/, transformResponse(axiosInstance.get(url))];
                case 1:
                    res = _a.sent();
                    return [2 /*return*/, res.templates];
            }
        });
    });
}
export function getRichMenuTemplate(templateId) {
    return __awaiter(this, void 0, void 0, function () {
        var url, res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    url = richMenuTemplatesMember(templateId);
                    return [4 /*yield*/, transformResponse(axiosInstance.get(url))];
                case 1:
                    res = _a.sent();
                    return [2 /*return*/, res.template];
            }
        });
    });
}
