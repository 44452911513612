import "core-js/modules/es.array.concat";
import "core-js/modules/es.function.name";
import "core-js/modules/es.string.search";
import { makeFetchInit, handleAPIErrors, fanpFetch, objToQuery } from '@src/lib/helpers';
import endpoints from '@src/endpoints';
import { retentionDelivery } from '@src/redux/fanp/constants';
var SET_RETENTION_TAGS_AND_TOTAL = retentionDelivery.SET_RETENTION_TAGS_AND_TOTAL,
    SET_RETENTION_TARGETS = retentionDelivery.SET_RETENTION_TARGETS,
    ADD_RETENTION_TARGET = retentionDelivery.ADD_RETENTION_TARGET,
    UPDATE_RETENTION_TARGET = retentionDelivery.UPDATE_RETENTION_TARGET,
    DELETE_RETENTION_TARGET = retentionDelivery.DELETE_RETENTION_TARGET,
    SET_RETENTION_CAMPAIGNS = retentionDelivery.SET_RETENTION_CAMPAIGNS,
    ADD_RETENTION_CAMPAIGNS = retentionDelivery.ADD_RETENTION_CAMPAIGNS,
    UPDATE_RETENTION_CAMPAIGN = retentionDelivery.UPDATE_RETENTION_CAMPAIGN,
    DELETE_RETENTION_CAMPAIGN = retentionDelivery.DELETE_RETENTION_CAMPAIGN,
    SET_RETENTION_CONTENTS = retentionDelivery.SET_RETENTION_CONTENTS,
    SET_RETENTION_CONTENT = retentionDelivery.SET_RETENTION_CONTENT,
    SET_RETENTION_SCENARIOS = retentionDelivery.SET_RETENTION_SCENARIOS,
    ADD_RETENTION_CONTENT = retentionDelivery.ADD_RETENTION_CONTENT,
    DELETE_RETENTION_CONTENT = retentionDelivery.DELETE_RETENTION_CONTENT; // TAGS

export var setRetentionTagsAndTotal = function setRetentionTagsAndTotal(_ref) {
  var data = _ref.data,
      total = _ref.total;
  return {
    type: SET_RETENTION_TAGS_AND_TOTAL,
    attribute_answers: data.attribute_answers,
    total: total
  };
};
export var fetchSearchTags = function fetchSearchTags(_ref2) {
  var chatbotId = _ref2.chatbotId,
      label = _ref2.label,
      page = _ref2.page;
  return function (dispatch) {
    var url = "".concat(endpoints.tags.search(chatbotId), "?").concat(objToQuery({
      label: label,
      page: page
    }));
    fanpFetch(url, makeFetchInit()).then(function (payload) {
      return dispatch(setRetentionTagsAndTotal(payload));
    }).catch(function (e) {
      return handleAPIErrors('There was a problem getting the tags.', e);
    });
  };
}; // RETENTION TARGETS

export var setRetentionTargets = function setRetentionTargets(retentionTargets) {
  return {
    type: SET_RETENTION_TARGETS,
    retentionTargets: retentionTargets
  };
};
export var addRetentionTarget = function addRetentionTarget(retentionTarget) {
  return {
    type: ADD_RETENTION_TARGET,
    retentionTarget: retentionTarget
  };
};
export var updateRetentionTarget = function updateRetentionTarget(retentionTargetId, retentionTarget) {
  return {
    type: UPDATE_RETENTION_TARGET,
    retentionTargetId: parseInt(retentionTargetId),
    retentionTarget: retentionTarget
  };
};
export var deleteRetentionTarget = function deleteRetentionTarget(retentionTargetId) {
  return {
    type: DELETE_RETENTION_TARGET,
    retentionTargetId: parseInt(retentionTargetId)
  };
};
export var fetchRetentionTargets = function fetchRetentionTargets(chatbotId) {
  return function (dispatch) {
    var url = endpoints.retentionTargets.collection(chatbotId);
    fanpFetch(url, makeFetchInit()).then(function (payload) {
      return dispatch(setRetentionTargets(payload.retention_targets));
    }).catch(function (e) {
      return handleAPIErrors('Retention Target error', e);
    });
  };
};
export var fetchCreateRetentionTarget = function fetchCreateRetentionTarget(_ref3) {
  var chatbotId = _ref3.chatbotId,
      name = _ref3.name,
      tags = _ref3.tags,
      aggregation = _ref3.aggregation;
  return function (dispatch) {
    var url = endpoints.retentionTargets.collection(chatbotId);
    var body = {
      name: name,
      tags: tags,
      aggregation: aggregation
    };
    fanpFetch(url, makeFetchInit('POST', body)).then(function (payload) {
      if (payload.status === 422) {
        throw new Error(payload.message);
      }

      dispatch(addRetentionTarget(payload.retention_target));
    }).catch(function (e) {
      return handleAPIErrors('Error adding retention target', e);
    });
  };
};
export var fetchRetentionTargetUpdate = function fetchRetentionTargetUpdate(_ref4) {
  var chatbotId = _ref4.chatbotId,
      retentionTargetId = _ref4.retentionTargetId,
      update = _ref4.update;
  return function (dispatch) {
    var url = endpoints.retentionTargets.member(chatbotId, retentionTargetId);
    fanpFetch(url, makeFetchInit('PUT', update)).then(function (payload) {
      return dispatch(updateRetentionTarget(retentionTargetId, payload.retention_target));
    }).catch(function (e) {
      return handleAPIErrors('Retention Target error', e);
    });
  };
};
export var fetchDeleteTarget = function fetchDeleteTarget(chatbotId, retentionTargetId) {
  return function (dispatch) {
    var url = endpoints.retentionTargets.member(chatbotId, retentionTargetId);
    fanpFetch(url, makeFetchInit('DELETE')).then(function (payload) {
      // TODO: change the parameter of the current retention target ID
      alert(payload.message);
      dispatch(deleteRetentionTarget(retentionTargetId));
    }).catch(function (e) {
      return handleAPIErrors('Retention Target error', e);
    });
  };
}; // RETENTION CAMPAIGNS

export var setRetentionCampaigns = function setRetentionCampaigns(retentionCampaigns) {
  return {
    type: SET_RETENTION_CAMPAIGNS,
    retentionCampaigns: retentionCampaigns
  };
};
export var addRetentionCampaign = function addRetentionCampaign(retentionCampaign) {
  return {
    type: ADD_RETENTION_CAMPAIGNS,
    retentionCampaign: retentionCampaign
  };
};
export var updateRetentionCampaign = function updateRetentionCampaign(retentionCampaignId, retentionCampaign) {
  return {
    type: UPDATE_RETENTION_CAMPAIGN,
    retentionCampaignId: parseInt(retentionCampaignId),
    retentionCampaign: retentionCampaign
  };
};
export var deleteRetentionCampaign = function deleteRetentionCampaign(campaignId) {
  return {
    type: DELETE_RETENTION_CAMPAIGN,
    campaignId: parseInt(campaignId)
  };
};
export var fetchRetentionCampaigns = function fetchRetentionCampaigns(chatbotId, targetId) {
  return function (dispatch) {
    var url = endpoints.retentionCampaigns.collection(chatbotId, targetId);
    fanpFetch(url, makeFetchInit()).then(function (payload) {
      return dispatch(setRetentionCampaigns(payload.retention_campaigns));
    }).catch(function (e) {
      return handleAPIErrors('Retention Campaign error', e);
    });
  };
};
export var fetchRetentionCampaignCreate = function fetchRetentionCampaignCreate(_ref5) {
  var chatbotId = _ref5.chatbotId,
      targetId = _ref5.targetId,
      createParams = _ref5.createParams;
  return function (dispatch) {
    var url = endpoints.retentionCampaigns.collection(chatbotId, targetId);
    fanpFetch(url, makeFetchInit('POST', createParams)).then(function (payload) {
      return dispatch(addRetentionCampaign(payload.retention_campaign));
    }).catch(function (e) {
      return handleAPIErrors('Retention Campaign error', e);
    });
  };
};
export var fetchRetentionCampaignUpdate = function fetchRetentionCampaignUpdate(_ref6) {
  var chatbotId = _ref6.chatbotId,
      targetId = _ref6.targetId,
      campaignId = _ref6.campaignId,
      update = _ref6.update;
  return function (dispatch) {
    var url = endpoints.retentionCampaigns.member(chatbotId, targetId, campaignId);
    fanpFetch(url, makeFetchInit('PUT', update)).then(function (payload) {
      return dispatch(updateRetentionCampaign(campaignId, payload.retention_campaign));
    }).catch(function (e) {
      return handleAPIErrors('Retention Content error', e);
    });
  };
};
export var fetchRetentionCampaignDelete = function fetchRetentionCampaignDelete(_ref7) {
  var chatbotId = _ref7.chatbotId,
      targetId = _ref7.targetId,
      campaignId = _ref7.campaignId;
  return function (dispatch) {
    var url = endpoints.retentionCampaigns.member(chatbotId, targetId, campaignId);
    fanpFetch(url, makeFetchInit('DELETE')).then(function () {
      return dispatch(deleteRetentionCampaign(campaignId));
    }).catch(function (e) {
      return handleAPIErrors('Retention Campaign error', e);
    });
  };
}; // RETENTION CONTENTS

export var setRetentionContents = function setRetentionContents(_ref8) {
  var retentionContents = _ref8.retentionContents,
      retentionContentReports = _ref8.retentionContentReports;
  var sorted = retentionContents.sort(function (a, b) {
    return a.rank > b.rank;
  });
  return {
    type: SET_RETENTION_CONTENTS,
    retentionContents: sorted,
    retentionContentReports: retentionContentReports
  };
};
export var setRetentionContent = function setRetentionContent(contentId, retentionContent) {
  return {
    type: SET_RETENTION_CONTENT,
    contentId: parseInt(contentId),
    retentionContent: retentionContent
  };
};
export var setRetentionScenarios = function setRetentionScenarios(retentionScenarios) {
  return {
    type: SET_RETENTION_SCENARIOS,
    retentionScenarios: retentionScenarios
  };
};
export var addRetentionContent = function addRetentionContent(retentionContent, retentionContentReport) {
  return {
    type: ADD_RETENTION_CONTENT,
    retentionContent: retentionContent,
    retentionContentReport: retentionContentReport
  };
};
export var deleteRetentionContent = function deleteRetentionContent(contentId) {
  return {
    type: DELETE_RETENTION_CONTENT,
    contentId: parseInt(contentId)
  };
};
export var fetchRetentionContents = function fetchRetentionContents(chatbotId, targetId, campaignId) {
  return function (dispatch) {
    var url = endpoints.retentionContents.collection(chatbotId, targetId, campaignId);
    fanpFetch(url, makeFetchInit()).then(function (payload) {
      dispatch(setRetentionContents({
        retentionContents: payload.retention_contents,
        retentionContentReports: payload.retention_content_reports
      }));
      dispatch(setRetentionScenarios(payload.scenarios));
    }).catch(function (e) {
      return handleAPIErrors('Retention Content error', e);
    });
  };
};
export var fetchRetentionContentCreate = function fetchRetentionContentCreate(_ref9) {
  var chatbotId = _ref9.chatbotId,
      targetId = _ref9.targetId,
      campaignId = _ref9.campaignId,
      params = _ref9.params;
  return function (dispatch) {
    var url = endpoints.retentionContents.collection(chatbotId, targetId, campaignId);
    fanpFetch(url, makeFetchInit('POST', params)).then(function (payload) {
      return dispatch(addRetentionContent(payload.retention_content, payload.retention_content_report));
    }).catch(function (e) {
      return handleAPIErrors('Retention Content error', e);
    });
  };
};
export var fetchRetentionContentUpdate = function fetchRetentionContentUpdate(_ref10) {
  var chatbotId = _ref10.chatbotId,
      targetId = _ref10.targetId,
      campaignId = _ref10.campaignId,
      contentId = _ref10.contentId,
      update = _ref10.update;
  return function (dispatch) {
    var url = endpoints.retentionContents.member(chatbotId, targetId, campaignId, contentId);
    fanpFetch(url, makeFetchInit('PUT', update)).then(function (payload) {
      return dispatch(setRetentionContent(contentId, payload.retention_content));
    }).catch(function (e) {
      console.log(e);
      handleAPIErrors('Retention Content error', e);
    });
  };
};
export var fetchRetentionContentDelete = function fetchRetentionContentDelete(_ref11) {
  var chatbotId = _ref11.chatbotId,
      targetId = _ref11.targetId,
      campaignId = _ref11.campaignId,
      contentId = _ref11.contentId;
  return function (dispatch) {
    var url = endpoints.retentionContents.member(chatbotId, targetId, campaignId, contentId);
    fanpFetch(url, makeFetchInit('DELETE')).then(function () {
      return dispatch(deleteRetentionContent(contentId));
    }).catch(function (e) {
      return handleAPIErrors('Retention Content error', e);
    });
  };
};
export var fetchUpdateCampaignContentRanks = function fetchUpdateCampaignContentRanks(_ref12) {
  var chatbotId = _ref12.chatbotId,
      targetId = _ref12.targetId,
      campaignId = _ref12.campaignId,
      contents = _ref12.contents;
  return function (dispatch) {
    var url = endpoints.retentionCampaigns.contentRanks(chatbotId, targetId, campaignId);
    fanpFetch(url, makeFetchInit('PUT', contents)).then(function (payload) {
      return dispatch(setRetentionContents({
        retentionContents: payload.retention_contents,
        retentionContentReports: payload.retention_content_reports
      }));
    }).catch(function (e) {
      return handleAPIErrors('Retention Content error', e);
    });
  };
};