var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import styled from 'styled-components';
import { colors } from '@src/colors';
import { Spinner } from '@src/components/atoms';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Button } from '@zeals-co-ltd/washi-components';
import { createNewFreeInputFromField } from '@src/redux/fanp/actions/ad-scenario/createActions';
import { setFields } from '@src/redux/fanp/actions/fieldValue';
import { useSearchFields } from '@src/components/hooks/fieldAttributes';
import { InputType } from 'zeals-protobuf/zeals/web/entities/user_dossier_pb';
import SelectableList from '@src/components/molecules/SelectableList';
var FreeInputFromFieldContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  width: 100%;\n  height: 100%;\n  &:nth-child(1) {\n    flex: 1;\n  }\n  &:nth-child(2) {\n    flex: 2;\n  }\n  .error-message {\n    display: flex;\n    align-items: center;\n    margin-top: 20px;\n    font-weight: bold;\n    color: ", ";\n  }\n"], ["\n  display: flex;\n  width: 100%;\n  height: 100%;\n  &:nth-child(1) {\n    flex: 1;\n  }\n  &:nth-child(2) {\n    flex: 2;\n  }\n  .error-message {\n    display: flex;\n    align-items: center;\n    margin-top: 20px;\n    font-weight: bold;\n    color: ", ";\n  }\n"])), colors.error);
var FreeInputFromUserFieldModal = function (props) {
    var chatbotIdParam = props.chatbotIdParam, intl = props.intl, blockIdParam = props.blockIdParam, toggleFreeInputFromUserFieldModal = props.toggleFreeInputFromUserFieldModal, modalOpen = props.modalOpen, sendSnackBarNotify = props.sendSnackBarNotify;
    var _a = __read(useState(), 2), searchTerm = _a[0], setSearchTerm = _a[1];
    var _b = __read(useState(), 2), selectedField = _b[0], setSelectedField = _b[1];
    var _c = __read(useState(false), 2), modalIsValidated = _c[0], setModalIsValidated = _c[1];
    var dispatch = useDispatch();
    // Gets Fields, formats and passes them to SelectableList
    var fieldList = useSearchFields(chatbotIdParam).fieldList;
    useEffect(function () {
        dispatch(setFields(fieldList));
    }, [fieldList]);
    var userFieldListFromState = useSelector(function (state) { return state.fieldValue.fields; });
    var userFieldList = userFieldListFromState
        ? userFieldListFromState.filter(function (c) { return c.inputType === InputType.INPUT_TYPE_FREE_INPUT; })
        : undefined;
    // Recieves selected field from SelectableList
    var sendSelectedField = function (selectedField) {
        setSelectedField(selectedField);
    };
    // Validates and creates Free input from attribute field
    var handleValidation = function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        var resolved, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!modalIsValidated) return [3 /*break*/, 5];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, 4, 5]);
                    resolved = createNewFreeInputFromField({
                        chatbotId: chatbotIdParam,
                        blockId: blockIdParam,
                        fieldId: selectedField.id,
                    });
                    return [4 /*yield*/, resolved(dispatch)];
                case 2:
                    _a.sent();
                    return [3 /*break*/, 5];
                case 3:
                    error_1 = _a.sent();
                    sendSnackBarNotify({
                        severity: 'error',
                        message: error_1.message,
                    });
                    return [3 /*break*/, 5];
                case 4:
                    toggleFreeInputFromUserFieldModal(false);
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        if (selectedField) {
            setModalIsValidated(true);
        }
    }, [selectedField]);
    return (React.createElement(Modal, { title: intl.formatMessage({ id: "NWmvdS", defaultMessage: "Connect Field with Free Input" }), fullWidth: true, maxWidth: "xs", className: "user-field-modal", id: "field-attribute-global-modal", "data-testid": "FreeInputFromUserFieldModal", hideCloseIcon: true, hideDividers: false, open: modalOpen, onClose: function () {
            toggleFreeInputFromUserFieldModal(false);
        }, actions: React.createElement(React.Fragment, null,
            React.createElement(Button, { variant: "outlined", color: "primary", onClick: function () { return toggleFreeInputFromUserFieldModal(false); } }, intl.formatMessage({ id: "47FYwb", defaultMessage: "Cancel" })),
            React.createElement(Button, { color: "success", variant: "contained", disabled: !modalIsValidated, onClick: function () { return handleValidation(dispatch); } }, intl.formatMessage({ id: "VzzYJk", defaultMessage: "Create" }))) }, userFieldList ? (React.createElement(FreeInputFromFieldContainer, null,
        React.createElement(SelectableList, { itemList: userFieldList, setSearchTerm: setSearchTerm, searchTerm: searchTerm, style: { width: '100%' }, title: intl.formatMessage({ id: "VIi87V", defaultMessage: "Select a Field" }), searchPlaceholder: intl.formatMessage({ id: "VXDQBw", defaultMessage: "Search for a field" }), sendSelectedItem: sendSelectedField }))) : (React.createElement(Spinner, null))));
};
export default injectIntl(FreeInputFromUserFieldModal);
var templateObject_1;
