var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import modalTypes from '@src/lib/modalTypes';
import { colors, shadows } from '@src/colors';
import { hoverMenuBg } from '@src/lib/mixins';
import { useLocation } from 'react-router-dom';
import { ModalPortal, FlashPortal, AlertMessage } from '@src/components/atoms';
import Flash from '@src/components/atoms/Flash';
import onClickOutside from 'react-onclickoutside';
import { Modal, EditScenarioName } from '@src/components/molecules';
import { RetrieveJson } from '@src/components/pages/fanp';
import { deliveryTypes } from '@src/lib/app-constants';
import { fetchDeleteScenario } from '@src/redux/fanp/actions/ad-scenario/deleteActions';
import { editScenario } from '@src/redux/fanp/actions/ad-scenario/editActions';
import { fetchAdScenarios } from '@src/redux/fanp/actions/ad-scenario';
import * as scenarioApi from '@src/services/api/scenario';
import { copyToClipboard, handleAPIErrors } from '@src/lib/helpers';
import { AlertModalContext } from '@src/components/molecules/AlertModal';
import { injectIntl } from 'react-intl';
var ItemMenuContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: ", ";\n  position: fixed;\n  ", ";\n  left: ", ";\n  z-index: 1;\n  width: 120px;\n  background: ", ";\n  box-shadow: ", ";\n  border-radius: 5px;\n\n  span {\n    display: block;\n    padding: 0.5rem;\n    font-size: 0.875rem;\n    user-select: none;\n    ", ";\n  }\n"], ["\n  display: ", ";\n  position: fixed;\n  ",
    ";\n  left: ", ";\n  z-index: 1;\n  width: 120px;\n  background: ", ";\n  box-shadow: ", ";\n  border-radius: 5px;\n\n  span {\n    display: block;\n    padding: 0.5rem;\n    font-size: 0.875rem;\n    user-select: none;\n    ", ";\n  }\n"])), function (props) { return (props.top === 0 ? '' : 'inline-block'); }, function (props) {
    return props.top === 0 ? "bottom: " + props.bottom + "px" : "top: " + props.top + "px";
}, function (props) { return props.left + "px"; }, colors.background.main_menu, shadows.medium.light, hoverMenuBg);
var AlertMessageContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  position: fixed;\n  bottom: 95px;\n  left: 47%;\n  box-shadow: 0 1px 20px rgba(0, 0, 0, 0.2);\n\n  .json-alert-message {\n    border-radius: 5px;\n    background: ", ";\n\n    .icon-wrapper {\n      display: none;\n    }\n\n    .message-wrapper {\n      padding: 8px 16px;\n      margin-right: 0px;\n      font-size: 12px;\n      color: white;\n    }\n  }\n"], ["\n  position: fixed;\n  bottom: 95px;\n  left: 47%;\n  box-shadow: 0 1px 20px rgba(0, 0, 0, 0.2);\n\n  .json-alert-message {\n    border-radius: 5px;\n    background: ", ";\n\n    .icon-wrapper {\n      display: none;\n    }\n\n    .message-wrapper {\n      padding: 8px 16px;\n      margin-right: 0px;\n      font-size: 12px;\n      color: white;\n    }\n  }\n"])), colors.success);
var DuplicateScenarioModal = function (_a) {
    var onCancel = _a.onCancel, intl = _a.intl;
    return (React.createElement(ModalPortal, null,
        React.createElement(Modal, { "data-testid": "scenario-item-menu-delete-modal", text: intl.formatMessage({ id: "lbYafu", defaultMessage: "Duplication in progress. Please wait." }), type: modalTypes.TEST, onCancel: onCancel })));
};
var RetrieveJsonModal = function (_a) {
    var chatbotIdParam = _a.chatbotIdParam, adScenarioIdParam = _a.adScenarioIdParam, onCancel = _a.onCancel, intl = _a.intl;
    return (React.createElement(ModalPortal, null,
        React.createElement(Modal, { "data-testid": "scenario-item-menu-retrive-json-modal", type: modalTypes.NONE, text: intl.formatMessage({ id: "RiCYmq", defaultMessage: "Get JSON Code" }), onCancel: onCancel },
            React.createElement(RetrieveJson, { chatbotIdParam: chatbotIdParam, adScenarioIdParam: adScenarioIdParam }))));
};
var DeleteScenarioModal = function (_a) {
    var onCancel = _a.onCancel, onConfirm = _a.onConfirm, intl = _a.intl;
    return (React.createElement(ModalPortal, null,
        React.createElement(Modal, { "data-testid": "scenario-item-menu-delete-scneario-modal", text: intl.formatMessage({ id: "usNPpx", defaultMessage: "Are you sure you want to delete this item ?" }), type: modalTypes.DELETE, onCancel: onCancel, onConfirm: onConfirm })));
};
var ScenarioMainSettingModal = function (_a) {
    var onCancel = _a.onCancel, onConfirm = _a.onConfirm, intl = _a.intl;
    return (React.createElement(ModalPortal, null,
        React.createElement(Modal, { "data-testid": "scenario-item-menu-main-setting-modal", text: intl.formatMessage({ id: "DRsaCJ", defaultMessage: "Edit the main settings ?" }), type: modalTypes.EDIT, onCancel: onCancel, onConfirm: onConfirm })));
};
var ScenarioItemMenu = function (_a) {
    var adScenarioIdParam = _a.adScenarioIdParam, chatbotIdParam = _a.chatbotIdParam, bottom = _a.bottom, top = _a.top, left = _a.left, chatTypeParam = _a.chatTypeParam, scenarioStatus = _a.scenarioStatus, scenarios = _a.scenarios, editScenarioMainSetting = _a.editScenarioMainSetting, hidePopupMenu = _a.hidePopupMenu, handlePopupMenuOutside = _a.handlePopupMenuOutside, intl = _a.intl;
    var scenario = scenarios[adScenarioIdParam];
    var adScenario = useSelector(function (state) { return state; }).adScenario;
    var dispatch = useDispatch();
    var location = useLocation();
    var _b = __read(useState(false), 2), isDuplicateScenarioModalOpened = _b[0], setIsDuplicateScenarioModalOpened = _b[1];
    var _c = __read(useState(false), 2), retrieveJsonModalOpen = _c[0], setRetrieveJsonModalOpen = _c[1];
    var _d = __read(useState(false), 2), deleteScenarioModalOpen = _d[0], setDeleteScenarioModalOpen = _d[1];
    var _e = __read(useState(false), 2), scenarioMainSettingModalOpen = _e[0], setScenarioMainSettingModalOpen = _e[1];
    var _f = __read(useState(false), 2), editScenarioNameModalOpen = _f[0], setEditScenarioNameModalOpen = _f[1];
    var _g = __read(useState((scenario === null || scenario === void 0 ? void 0 : scenario.name) || ''), 2), newName = _g[0], setNewName = _g[1];
    var _h = __read(useState(false), 2), validationSuccessShown = _h[0], setValidationSuccessShown = _h[1];
    var _j = __read(useState({
        isShow: false,
        content: '',
    }), 2), alertMessage = _j[0], setAlertMessage = _j[1];
    var handleModal = React.useContext(AlertModalContext).handleModal;
    var toggleScenarioMainSettingModal = function () {
        return setScenarioMainSettingModalOpen(!scenarioMainSettingModalOpen);
    };
    var toggleRetrieveJsonModal = function () {
        return setRetrieveJsonModalOpen(!retrieveJsonModalOpen);
    };
    var toggleDeleteScenarioModal = function () {
        return setDeleteScenarioModalOpen(!deleteScenarioModalOpen);
    };
    var toggleEditScenarioNameModal = function () {
        return setEditScenarioNameModalOpen(!editScenarioNameModalOpen);
    };
    function handleScenarioDeleteConfirm() {
        return __awaiter(this, void 0, void 0, function () {
            var page, per, search, chatbotId, adScenarioId, e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        page = adScenario.page, per = adScenario.per, search = adScenario.search;
                        chatbotId = Number(chatbotIdParam);
                        adScenarioId = Number(adScenarioIdParam);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 4, , 5]);
                        return [4 /*yield*/, dispatch(fetchDeleteScenario(chatTypeParam, chatbotId, adScenarioId))];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, dispatch(fetchAdScenarios({
                                chatTypeParam: chatTypeParam,
                                chatbotId: chatbotIdParam,
                                page: page,
                                per: per,
                                search: search,
                                location: location,
                            }))];
                    case 3:
                        _a.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        e_1 = _a.sent();
                        handleModal(e_1.message);
                        return [3 /*break*/, 5];
                    case 5:
                        hidePopupMenu();
                        return [2 /*return*/];
                }
            });
        });
    }
    function handleSetMainScenario() {
        editScenarioMainSetting({
            chatbotId: chatbotIdParam,
            adScenarioId: adScenarioIdParam,
        });
        hidePopupMenu();
    }
    var toggleDuplicateScenarioModal = function () {
        return setIsDuplicateScenarioModalOpened(!isDuplicateScenarioModalOpened);
    };
    function handleScenarioDuplication() {
        return __awaiter(this, void 0, void 0, function () {
            var page, per, search, e_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        page = adScenario.page, per = adScenario.per, search = adScenario.search;
                        toggleDuplicateScenarioModal();
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 4, , 5]);
                        return [4 /*yield*/, scenarioApi.duplicateScenario(chatbotIdParam, adScenarioIdParam)];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, dispatch(fetchAdScenarios({
                                chatTypeParam: chatTypeParam,
                                chatbotId: chatbotIdParam,
                                page: page,
                                per: per,
                                search: search,
                                location: location,
                            }))];
                    case 3:
                        _a.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        e_2 = _a.sent();
                        handleAPIErrors(e_2.message);
                        return [3 /*break*/, 5];
                    case 5:
                        hidePopupMenu();
                        return [2 /*return*/];
                }
            });
        });
    }
    function handleScenarioValidation() {
        return __awaiter(this, void 0, void 0, function () {
            var e_3;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, scenarioApi.validateScenario(chatbotIdParam, adScenarioIdParam)];
                    case 1:
                        _a.sent();
                        setValidationSuccessShown(true);
                        return [3 /*break*/, 3];
                    case 2:
                        e_3 = _a.sent();
                        handleModal(intl.formatMessage({ id: "ftXct1", defaultMessage: "Invalid Scenario :" }) + e_3.message);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    }
    function handleCopyScenarioID() {
        copyToClipboard("" + adScenarioIdParam);
        setAlertMessage({
            isShow: true,
            content: intl.formatMessage({ id: "vJP8CR", defaultMessage: "Scenario ID copied in clipboard" }),
        });
    }
    function onRemoveCopyScenarioIDAlert() {
        setAlertMessage({ isShow: false, content: '' });
    }
    handleClickOutside = function (e) {
        if (ModalPortal.parentIsPortal(e.target))
            return;
        hidePopupMenu();
    };
    function handleBottomPosition(e) {
        if (e && e.getBoundingClientRect().bottom > window.innerHeight)
            handlePopupMenuOutside();
    }
    function handleChangeNewName(e) {
        setNewName(e.target.value);
    }
    function handleSubmitNewName() {
        return __awaiter(this, void 0, void 0, function () {
            var e_4;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, dispatch(editScenario(chatbotIdParam, adScenarioIdParam, {
                                name: newName,
                            }))];
                    case 1:
                        _a.sent();
                        hidePopupMenu();
                        return [3 /*break*/, 3];
                    case 2:
                        e_4 = _a.sent();
                        handleModal(e_4.message);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    }
    var hasDelete = scenarioStatus !== deliveryTypes.DELIVERED;
    return (React.createElement("div", { "data-testid": "scenario-item-menu" },
        validationSuccessShown && (React.createElement(FlashPortal, null,
            React.createElement(Flash, { type: "success", secondsVisible: 3 }, intl.formatMessage({ id: "+0VSaF", defaultMessage: "This scenario is valid" })))),
        alertMessage.isShow && (React.createElement(AlertMessageContainer, null,
            React.createElement(AlertMessage, { time: 3000, message: alertMessage.content, onRemoveAlert: onRemoveCopyScenarioIDAlert, className: "json-alert-message" }))),
        React.createElement(ItemMenuContainer, { top: top, left: left, bottom: bottom },
            isDuplicateScenarioModalOpened && (React.createElement(DuplicateScenarioModal, { onCancel: toggleDuplicateScenarioModal, intl: intl })),
            deleteScenarioModalOpen && (React.createElement(DeleteScenarioModal, { onCancel: toggleDeleteScenarioModal, onConfirm: handleScenarioDeleteConfirm, intl: intl })),
            retrieveJsonModalOpen && (React.createElement(RetrieveJsonModal, { chatbotIdParam: chatbotIdParam, adScenarioIdParam: adScenarioIdParam, onCancel: toggleRetrieveJsonModal, intl: intl })),
            scenarioMainSettingModalOpen && (React.createElement(ScenarioMainSettingModal, { onCancel: toggleScenarioMainSettingModal, onConfirm: handleSetMainScenario, intl: intl })),
            editScenarioNameModalOpen && (React.createElement(EditScenarioName, { onCancel: toggleEditScenarioNameModal, value: newName, onChange: handleChangeNewName, onConfirm: handleSubmitNewName })),
            React.createElement("span", { role: "button", tabIndex: -1, onClick: toggleEditScenarioNameModal, "data-testid": "edit-scenario-name-menu-item" }, intl.formatMessage({ id: "sDv/7f", defaultMessage: "Scenario Name" })),
            React.createElement("span", { role: "button", tabIndex: -2, onClick: handleScenarioDuplication, "data-testid": "scenario-duplication-menu-item" }, intl.formatMessage({ id: "4fHiNl", defaultMessage: "Duplicate" })),
            React.createElement("span", { role: "button", tabIndex: 0, onClick: handleScenarioValidation, "data-testid": "scenario-validation-menu-item" }, intl.formatMessage({ id: "uXm916", defaultMessage: "Inspection" })),
            hasDelete && (React.createElement("span", { role: "button", tabIndex: -3, onClick: toggleDeleteScenarioModal, "data-testid": "delete-scenario-menu-item" }, intl.formatMessage({ id: "K3r6DQ", defaultMessage: "Delete" }))),
            React.createElement("span", { role: "button", tabIndex: -4, onClick: handleCopyScenarioID, "data-testid": "copy-scenario-id-menu-item" }, intl.formatMessage({ id: "0P+ASV", defaultMessage: "Get ID" })),
            React.createElement("span", { role: "button", tabIndex: -5, onClick: toggleRetrieveJsonModal, "data-testid": "scenario-retrieve-json-menu-item" }, intl.formatMessage({ id: "rIcY7I", defaultMessage: "Get JSON" })),
            React.createElement("div", { ref: function (e) { return handleBottomPosition(e); } }))));
};
var handleClickOutside;
var clickOutsideConfig = {
    handleClickOutside: function () { return handleClickOutside; },
};
export default onClickOutside(injectIntl(ScenarioItemMenu), clickOutsideConfig);
var templateObject_1, templateObject_2;
