import "core-js/modules/es.array.concat";
export var collection = function collection(chatbotId) {
  return "/chatbots/".concat(chatbotId, "/retention_targets");
};
export var member = function member(chatbotId, retentionTargetId) {
  return "".concat(collection(chatbotId), "/").concat(retentionTargetId);
};
export var trackCollection = function trackCollection(chatbotId) {
  return "/chatbots/".concat(chatbotId, "/track_push/retention_targets");
};
export var trackMember = function trackMember(chatbotId, retentionTargetId) {
  return "".concat(trackCollection(chatbotId), "/").concat(retentionTargetId);
};
export default {
  collection: collection,
  member: member,
  trackPush: {
    collection: trackCollection,
    member: trackMember
  }
};