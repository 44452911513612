var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import { rest } from 'msw';
import featureFlags from '@src/lib/feature_flags/featureFlags';
import { mockForm, mockFormList } from '../../tests/dummyData/form';
export var handlers = __spreadArray([], __read((featureFlags.RPA_CONFIG_MOCK_API
    ? [
        // This is not in the API, but has been requested to the backend team
        // The API should take some references to the chatbotId as well
        rest.get("/v1/forms", function (_req, res, ctx) {
            return res(ctx.status(200), ctx.json(mockFormList(3)));
        }),
        rest.get("/v1/forms/:id", function (req, res, ctx) {
            return res(ctx.status(200), ctx.json(mockForm(req.params.id)));
        }),
    ]
    : [])));
