var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { css } from 'styled-components';
import { colors } from '@src/colors';
import { templateFrame, imageMapVerticalTemplateFrame, imageMapCountryMapTemplateFrame, messageTypes, TAB_0_COLOR, IMAGE_MAP_TEMPLATE_NUMBER_COUNTRY_MAP, COUNTRY_MAP_IMAGE_URL, } from '@src/lib/app-constants';
import { transitions } from '@src/lib/animations';
// when color <= TAB_0_COLOR is mean that box on tab0
// when color > TAB_0_COLOR is mean that box on top tab2 or tab3
var colorConverted = function (color, isPreview) {
    var opacity = isPreview ? 1 : 0.5;
    if (color <= TAB_0_COLOR)
        return "rgba(9, 31, 108, " + (1 - color / 10) * opacity + ")";
    return "rgba(23, 57, 165, " + ((color - 8) / 10) * opacity + ")";
};
var styledTemplateModel = function (props) {
    var _a;
    var chosenStyled = "\n    border-color: " + colors.accent_color + " !important;\n  ";
    return css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    height: ", "px;\n    background-color: ", ";\n    border: ", "px solid transparent;\n    border-radius: 10px;\n    cursor: pointer;\n    transition: ", ";\n\n    ", "\n\n    &:hover {\n      background-color: ", ";\n    }\n\n    .template-areas {\n      background-image: url(", ");\n    }\n  "], ["\n    height: ", "px;\n    background-color: ", ";\n    border: ", "px solid transparent;\n    border-radius: 10px;\n    cursor: pointer;\n    transition: ", ";\n\n    ", "\n\n    &:hover {\n      background-color: ", ";\n    }\n\n    .template-areas {\n      background-image: url(",
        ");\n    }\n  "])), ((_a = props.customTemplateFrame) === null || _a === void 0 ? void 0 : _a.height) || 200, colors.background.light_purple, templateFrame.borderWidth, transitions.normal, props.chosen ? chosenStyled : '', colors.background.hover_purple, props.templateNum ===
        IMAGE_MAP_TEMPLATE_NUMBER_COUNTRY_MAP && COUNTRY_MAP_IMAGE_URL);
};
// from area data: x, y, width, height, this styled calculate css for
// each box area, first calculate scale
// (width of box show on modal or message / template width)
// then multiplication
var calcStyledData = function (props) {
    var messageType = props.messageType, isPreview = props.isPreview, templateWidth = props.templateWidth, templateHeight = props.templateHeight, customTemplateFrame = props.customTemplateFrame;
    var scaleByWidth = (customTemplateFrame === null || customTemplateFrame === void 0 ? void 0 : customTemplateFrame.scaleByWidth) || props.scaleByWidth;
    var _a = customTemplateFrame || templateFrame, width = _a.width, height = _a.height, offsetWidth = _a.offsetWidth, offsetHeight = _a.offsetHeight, borderWidth = _a.borderWidth;
    var scale, offsetX = -borderWidth, offsetY = -borderWidth;
    scale = scaleByWidth
        ? (scale = width / templateWidth)
        : (scale = height / templateHeight);
    if (!isPreview)
        return { scale: scale, offsetX: offsetX, offsetY: offsetY };
    // Some template on image map need scale by width (1000x131, 1910x1000,
    // 2000x500) it follow scaleByWidth condition flag
    if (messageType === messageTypes.IMAGE_MAP && !scaleByWidth) {
        scale = (height - 2 * offsetHeight) / templateHeight;
        offsetX = (width - 2 * borderWidth - scale * templateWidth) / 2;
        offsetY = offsetHeight - borderWidth;
    }
    else {
        scale = (width - 2 * offsetWidth) / templateWidth;
        offsetX = offsetWidth - borderWidth;
        offsetY = (height - 2 * borderWidth - scale * templateHeight) / 2;
    }
    return { scale: scale, offsetX: offsetX, offsetY: offsetY };
};
export var styledTemplate = function (props) {
    var areas = props.areas, isPreview = props.isPreview, templateWidth = props.templateWidth, templateHeight = props.templateHeight, customTemplateFrame = props.customTemplateFrame, templateNum = props.templateNum;
    var _a = calcStyledData(props), scale = _a.scale, offsetX = _a.offsetX, offsetY = _a.offsetY;
    var customAreaScale = ((customTemplateFrame === null || customTemplateFrame === void 0 ? void 0 : customTemplateFrame.messageWidth) || templateFrame.width) /
        templateFrame.width;
    var areaScale = isPreview ? 1 : customAreaScale * scale;
    var styledAreas = areas.map(function (_a, index) {
        var x = _a.x, y = _a.y, width = _a.width, height = _a.height, color = _a.color;
        return css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n        .area-", " {\n          position: absolute;\n          left: ", "px;\n          top: ", "px;\n          width: ", "px;\n          height: ", "px;\n          background-color: ", " !important;\n          border: 5px solid rgba(0, 0, 0, 0);\n          border-radius: ", ";\n          padding: 5px;\n          overflow: hidden;\n\n          .block-info > div,\n          .adicon_nextblock,\n          .area-info {\n            text-overflow: ellipsis;\n            white-space: nowrap;\n            overflow: hidden;\n            margin-bottom: 0px;\n          }\n\n          .adicon_nextblock {\n            width: 100%;\n            text-align: left;\n            color: rgb(255, 255, 252, 0.8);\n            margin-top: 0px;\n            margin-bottom: -2px;\n          }\n\n          .area-info {\n            color: ", ";\n          }\n        }\n      "], ["\n        .area-", " {\n          position: absolute;\n          left: ", "px;\n          top: ", "px;\n          width: ", "px;\n          height: ", "px;\n          background-color: ",
            " !important;\n          border: 5px solid rgba(0, 0, 0, 0);\n          border-radius: ",
            ";\n          padding: 5px;\n          overflow: hidden;\n\n          .block-info > div,\n          .adicon_nextblock,\n          .area-info {\n            text-overflow: ellipsis;\n            white-space: nowrap;\n            overflow: hidden;\n            margin-bottom: 0px;\n          }\n\n          .adicon_nextblock {\n            width: 100%;\n            text-align: left;\n            color: rgb(255, 255, 252, 0.8);\n            margin-top: 0px;\n            margin-bottom: -2px;\n          }\n\n          .area-info {\n            color: ",
            ";\n          }\n        }\n      "])), index, x * areaScale, y * areaScale, width * areaScale, height * areaScale, templateNum ===
            IMAGE_MAP_TEMPLATE_NUMBER_COUNTRY_MAP && isPreview
            ? 'unset'
            : colorConverted(color, isPreview), templateNum ===
            IMAGE_MAP_TEMPLATE_NUMBER_COUNTRY_MAP && !isPreview
            ? '5px'
            : 'unset', color <= TAB_0_COLOR
            ? 'rgb(255, 255, 252, .8)'
            : 'rgb(0, 16, 74, .8)');
    });
    var styledTemplateAreas = css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    ", "\n\n    .template-areas {\n      position: absolute;\n      left: ", "px;\n      top: ", "px;\n      width: ", "px;\n      height: ", "px;\n      transform: scale(", ");\n      transform-origin: top left;\n      border-radius: ", "px;\n      overflow: hidden;\n      background-color: unset !important;\n\n      .area-info {\n        font-size: 10px;\n        transform: scale(", ");\n        position: absolute;\n        top: calc(50% - 7px);\n        text-align: center;\n        width: 100%;\n        left: 0px;\n      }\n    }\n  "], ["\n    ", "\n\n    .template-areas {\n      position: absolute;\n      left: ", "px;\n      top: ", "px;\n      width: ", "px;\n      height: ", "px;\n      transform: scale(", ");\n      transform-origin: top left;\n      border-radius: ", "px;\n      overflow: hidden;\n      background-color: unset !important;\n\n      .area-info {\n        font-size: 10px;\n        transform: scale(", ");\n        position: absolute;\n        top: calc(50% - 7px);\n        text-align: center;\n        width: 100%;\n        left: 0px;\n      }\n    }\n  "])), styledTemplateModel(props), offsetX, offsetY, templateWidth, templateHeight, scale, 5 / scale, 1 / scale);
    var styledTemplateGrid = css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    height: ", "px;\n    width: ", "px;\n\n    div[class^='area-']:not(.area-info) {\n      cursor: pointer;\n\n      .block-info {\n        color: rgb(255, 255, 252, 0.8);\n        background-color: rgb(0, 0, 0, 0.5);\n        max-width: 100%;\n        position: absolute;\n        padding: 5px;\n        border-radius: 2px;\n        margin-left: -5px;\n        bottom: 0px;\n\n        .adicon_big_link:before {\n          font-size: 12px;\n          margin-right: 4px;\n          font-weight: bold;\n        }\n      }\n    }\n\n    .area-info {\n      font-size: 8px;\n    }\n\n    .hover-area {\n      border-color: ", " !important;\n    }\n  "], ["\n    height: ", "px;\n    width: ", "px;\n\n    div[class^='area-']:not(.area-info) {\n      cursor: pointer;\n\n      .block-info {\n        color: rgb(255, 255, 252, 0.8);\n        background-color: rgb(0, 0, 0, 0.5);\n        max-width: 100%;\n        position: absolute;\n        padding: 5px;\n        border-radius: 2px;\n        margin-left: -5px;\n        bottom: 0px;\n\n        .adicon_big_link:before {\n          font-size: 12px;\n          margin-right: 4px;\n          font-weight: bold;\n        }\n      }\n    }\n\n    .area-info {\n      font-size: 8px;\n    }\n\n    .hover-area {\n      border-color: ", " !important;\n    }\n  "])), scale * customAreaScale * templateHeight, scale * customAreaScale * templateWidth, colors.accent_color);
    return css(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    .template-areas {\n      ", "\n    }\n\n    ", "\n  "], ["\n    .template-areas {\n      ", "\n    }\n\n    ", "\n  "])), styledAreas, isPreview ? styledTemplateAreas : styledTemplateGrid);
};
// This method will return null or custom template frame, because some template
// need vertical render on modal, some need custom width, height frame on modal
export var customImageMapTemplateFrame = function (props) {
    var templateWidth = props.templateWidth, templateHeight = props.templateHeight, templateNum = props.templateNum;
    var width = templateFrame.width, height = templateFrame.height;
    if (templateNum === IMAGE_MAP_TEMPLATE_NUMBER_COUNTRY_MAP)
        return imageMapCountryMapTemplateFrame;
    if (templateWidth / templateHeight > width / height + 0.25)
        return imageMapVerticalTemplateFrame;
    return null;
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
