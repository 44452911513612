import "core-js/modules/es.string.small";
import React from 'react';
import styled from 'styled-components';
import { centeredFlex, hoverCss } from '@src/lib/mixins';
import { colors, shadows } from '@src/colors';
import { CloseIcon } from '@src/components/atoms';
var PreviewContainer = styled.div.withConfig({
  displayName: "PreviewContainer",
  componentId: "sc-lupcmr-0"
})(["background:", ";border-radius:0 8px 8px 0;box-shadow:", ";display:flex;"], colors.area_mask, shadows.small.mid);
var PreviewModal = styled.div.withConfig({
  displayName: "PreviewModal",
  componentId: "sc-lupcmr-1"
})(["background:", ";border-radius:8px;width:340px;height:310px;margin:auto;", ";flex-direction:column;position:relative;.preview-modal-inner{", ";flex-direction:column;}img{height:48px;}> .preview-modal-inner > p{width:100%;overflow:auto;&.title{font-size:1.2rem;margin-bottom:5px;text-align:center;color:", ";white-space:nowrap;}&.body{font-size:14px;max-height:65px;}&.remark{font-size:0.6rem;color:", ";text-align:center;max-height:20px;}}.button{padding:8px;border-radius:5px;background:", ";margin:20px 0 5px;font-size:0.9rem;color:", ";max-width:100%;max-height:40px;overflow:auto;}.fanp-modal-close{position:absolute;right:-25px;top:-25px;color:", ";", ";}.modal-powered-by{width:100%;background:", ";border-radius:0 0 8px 8px;text-align:center;color:#fff;position:absolute;bottom:0;font-size:8px;}.powered-top-radius{border-radius:8px 8px 0 0;}"], colors.background.main_menu, centeredFlex, centeredFlex, function (props) {
  return props.modalColor || 'unset';
}, colors.text_pale, function (props) {
  return props.modalColor || 'unset';
}, colors.background.main_menu, colors.chat_link.close.normal, hoverCss, function (props) {
  return props.poweredColor || 'unset';
});

var ChatlinkPreview = function ChatlinkPreview(_ref) {
  var chatlink = _ref.chatlink;
  return React.createElement(PreviewContainer, null, React.createElement(PreviewModal, {
    modalColor: chatlink.color_code,
    poweredColor: chatlink.powered_color_code
  }, React.createElement(CloseIcon, null), React.createElement("div", {
    className: "preview-modal-inner"
  }, React.createElement("img", {
    src: chatlink.image_url
  }), React.createElement("p", {
    className: "title"
  }, chatlink.title), React.createElement("p", {
    className: "body"
  }, chatlink.body), React.createElement("a", {
    className: "button"
  }, chatlink.button_text), React.createElement("p", {
    className: "remark"
  }, chatlink.remark)), chatlink.powered == 1 && React.createElement("div", {
    className: "modal-powered-by"
  }, "Powered by Zeals")));
};

export default ChatlinkPreview;