import "core-js/modules/es.array.iterator";
import "core-js/modules/es.function.name";
import "core-js/modules/es.object.to-string";
import "core-js/modules/web.dom-collections.iterator";
import React from 'react';
import { Switch, InputWrapper, FormWrapper } from '@src/components/atoms';
import { Field } from 'formik';
import { ChromePicker } from 'react-color';
import CustomField from '@src/components/molecules/CustomField';

var ChatlinkForm = function ChatlinkForm(_ref) {
  var handleChange = _ref.handleChange,
      handleColorCodeChange = _ref.handleColorCodeChange,
      handlePoweredColorCodeChange = _ref.handlePoweredColorCodeChange,
      handleModalTypeChange = _ref.handleModalTypeChange,
      errors = _ref.errors,
      touched = _ref.touched,
      values = _ref.values;
  return React.createElement(FormWrapper, null, React.createElement("section", null, "Basic", React.createElement("div", {
    className: "field"
  }, React.createElement("p", {
    className: "label"
  }, "\u30C1\u30E3\u30C3\u30C8\u30EA\u30F3\u30AF\u30BF\u30A4\u30D7"), React.createElement(InputWrapper, {
    hasError: !!touched.modal_type && !!errors.modal_type
  }, React.createElement(Field, {
    name: "modal_type",
    component: "select",
    onChange: handleModalTypeChange
  }, React.createElement("option", {
    value: "",
    disabled: true
  }, "\u30E2\u30FC\u30C0\u30EB\u306E\u30BF\u30A4\u30D7\u3092\u9078\u629E\u3057\u3066\u304F\u3060\u3055\u3044"), React.createElement("option", {
    value: "normal"
  }, "\u901A\u5E38"), React.createElement("option", {
    value: "image"
  }, "\u753B\u50CF\u30E2\u30FC\u30C0\u30EB"), React.createElement("option", {
    value: "banner"
  }, "\u30D0\u30CA\u30FC"))), !!touched.modal_type && !!errors.modal_type && React.createElement("div", {
    className: "message-wrapper error"
  }, errors.modal_type)), React.createElement(CustomField, {
    fieldLabel: "\u30C1\u30E3\u30C3\u30C8\u30EA\u30F3\u30AF\u540D",
    type: "text",
    name: "name",
    placeholder: "\u30C1\u30E3\u30C3\u30C8\u30EA\u30F3\u30AF\u540D",
    hasError: !!touched.name && !!errors.name,
    onChange: handleChange
  }), React.createElement(CustomField, {
    fieldLabel: "\u30BF\u30A4\u30C8\u30EB",
    name: "title",
    placeholder: "\u30BF\u30A4\u30C8\u30EB",
    hasError: !!touched.title && !!errors.title,
    onChange: handleChange
  }), React.createElement(CustomField, {
    fieldLabel: "\u30AB\u30E9\u30FC",
    name: "color_code",
    placeholder: "\u30AB\u30E9\u30FC",
    hasError: !!touched.color_code && !!errors.color_code,
    onChange: handleChange
  }, React.createElement(ChromePicker, {
    color: values.color_code || '',
    onChangeComplete: handleColorCodeChange
  })), React.createElement(CustomField, {
    fieldLabel: "\u30DC\u30BF\u30F3\u306E\u30C6\u30AD\u30B9\u30C8",
    name: "button_text",
    placeholder: "\u30DC\u30BF\u30F3\u306E\u30C6\u30AD\u30B9\u30C8",
    hasError: !!touched.button_text && !!errors.button_text,
    onChange: handleChange
  })), React.createElement("section", null, "Information", React.createElement(CustomField, {
    fieldLabel: "\u672C\u6587",
    name: "body",
    placeholder: "\u672C\u6587",
    componentType: "textarea",
    hasError: !!touched.body && !!errors.body,
    onChange: handleChange
  }), React.createElement(CustomField, {
    fieldLabel: "\u6CE8\u610F\u6587",
    name: "remark",
    placeholder: "\u6CE8\u610F\u6587",
    componentType: "textarea",
    hasError: !!touched.remark && !!errors.remark,
    onChange: handleChange
  })), React.createElement("section", null, "URL", React.createElement(CustomField, {
    fieldLabel: "\u753B\u50CFURL",
    name: "image_url",
    placeholder: "\u753B\u50CFURL",
    hasError: !!touched.image_url && !!errors.image_url,
    onChange: handleChange
  }), React.createElement(CustomField, {
    fieldLabel: "\u30DC\u30C3\u30C8\u306E\u30EA\u30F3\u30AFURL",
    name: "bot_url",
    placeholder: "\u30DC\u30C3\u30C8\u306E\u30EA\u30F3\u30AFURL",
    hasError: !!touched.bot_url && !!errors.bot_url,
    onChange: handleChange
  }), React.createElement(CustomField, {
    fieldLabel: "Referrer URL",
    name: "referrer_url",
    placeholder: "Referrer URL",
    onChange: handleChange
  })), React.createElement("section", null, "Settings", React.createElement("div", {
    className: "switch-field"
  }, React.createElement("p", {
    className: "label"
  }, "Active"), React.createElement(Switch, {
    className: "switch",
    name: "active",
    isActive: !!values.active,
    onChange: handleChange
  })), React.createElement("div", {
    className: "switch-field"
  }, React.createElement("p", {
    className: "label"
  }, "\u8868\u793A\u30BF\u30A4\u30DF\u30F3\u30B0\u306E\u9593\u9694\u5236\u5FA1"), React.createElement(Switch, {
    className: "switch",
    name: "display_interval_control_flag",
    isActive: !!values.display_interval_control_flag,
    onChange: handleChange
  })), React.createElement("div", {
    className: "field"
  }, React.createElement("p", {
    className: "label"
  }, "\u30C8\u30EA\u30AC\u30FC"), React.createElement(InputWrapper, {
    hasError: !!touched.trigger && !!errors.trigger
  }, React.createElement(Field, {
    name: "trigger",
    component: "select",
    onChange: handleChange
  }, React.createElement("option", {
    value: "",
    disabled: true
  }, "\u30C8\u30EA\u30AC\u30FC\u3092\u9078\u629E\u3057\u3066\u304F\u3060\u3055\u3044"), React.createElement("option", {
    value: "back"
  }, "\u30D6\u30E9\u30A6\u30B6\u30D0\u30C3\u30AF"), React.createElement("option", {
    value: "stay"
  }, "60\u79D2\u6EDE\u5728"), React.createElement("option", {
    value: "page_load"
  }, "\u30DA\u30FC\u30B8\u30ED\u30FC\u30C9\u6642"), React.createElement("option", {
    value: "come_back"
  }, "Come Back"))), !!touched.trigger && !!errors.trigger && React.createElement("div", {
    className: "message-wrapper error"
  }, errors.trigger)), React.createElement("div", {
    className: "field"
  }, React.createElement("p", {
    className: "label"
  }, "\u8868\u793A\u30C7\u30D0\u30A4\u30B9"), React.createElement(InputWrapper, {
    hasError: !!touched.device_type && !!errors.device_type
  }, React.createElement(Field, {
    name: "device_type",
    component: "select",
    onChange: handleChange
  }, React.createElement("option", {
    value: "",
    disabled: true
  }, "\u8868\u793A\u30C7\u30D0\u30A4\u30B9\u3092\u9078\u629E\u3057\u3066\u304F\u3060\u3055\u3044"), React.createElement("option", {
    value: "both"
  }, "PC\u30FB\u30B9\u30DE\u30DB"), React.createElement("option", {
    value: "pc"
  }, "PC\u306E\u307F"), React.createElement("option", {
    value: "smartphone"
  }, "\u30B9\u30DE\u30DB\u306E\u307F"))), !!touched.device_type && !!errors.device_type && React.createElement("div", {
    className: "message-wrapper error"
  }, errors.device_type)), React.createElement("div", {
    className: "switch-field"
  }, React.createElement("p", {
    className: "label"
  }, "Powered by Zeals \u306E\u8868\u793A"), React.createElement(Switch, {
    className: "switch",
    name: "powered",
    isActive: !!values.powered,
    onChange: handleChange
  })), React.createElement(CustomField, {
    fieldLabel: "Powered by Zeals \u30AB\u30E9\u30FC",
    name: "powered_color_code",
    placeholder: "Powered by Zeals \u306E\u80CC\u666F\u8272",
    hasError: !!touched.powered_color_code && !!errors.powered_color_code,
    onChange: handleChange
  }, React.createElement(ChromePicker, {
    color: values.powered_color_code || '',
    onChangeComplete: handlePoweredColorCodeChange
  }))));
};

export default ChatlinkForm;