var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { adScenariosNormalizer, singleAdScenarioNormalizer, } from '@src/lib/dataNormalizer';
import { adScenario } from '@src/redux/fanp/constants';
import * as scenarioApi from '@src/services/api/scenario';
var SET_AD_SCENARIOS_FETCHING = adScenario.SET_AD_SCENARIOS_FETCHING, SET_AD_SCENARIOS = adScenario.SET_AD_SCENARIOS, SET_AD_SCENARIO = adScenario.SET_AD_SCENARIO, SET_MAIN_SCENARIO = adScenario.SET_MAIN_SCENARIO, AD_SET_BLOCK_MESSAGES = adScenario.AD_SET_BLOCK_MESSAGES, SET_BLOCK_NAME = adScenario.SET_BLOCK_NAME, AD_IS_VALID_TRIGGER_TIME = adScenario.AD_IS_VALID_TRIGGER_TIME, AD_SET_STEP_DAYS_AFTER = adScenario.AD_SET_STEP_DAYS_AFTER, AD_SET_STEP_DELIVERY_TIME = adScenario.AD_SET_STEP_DELIVERY_TIME, AD_TOGGLE_IMAGE_LOADING = adScenario.AD_TOGGLE_IMAGE_LOADING, AD_SET_PREV_BLOCKS = adScenario.AD_SET_PREV_BLOCKS, AD_SET_SCENARIO_TESTING = adScenario.AD_SET_SCENARIO_TESTING, AD_SET_GENERIC_LIST_BTN = adScenario.AD_SET_GENERIC_LIST_BTN, TOGGLE_QUICK_REPLY_SELECT_MODE = adScenario.TOGGLE_QUICK_REPLY_SELECT_MODE, AD_SET_LAYER_FIELD = adScenario.AD_SET_LAYER_FIELD, AD_TOGGLE_LAYER_FIELD = adScenario.AD_TOGGLE_LAYER_FIELD, AD_SET_UNDEFINED_BLOCKS_LAYER_FIELD = adScenario.AD_SET_UNDEFINED_BLOCKS_LAYER_FIELD, AD_TOGGLE_UNDEFINED_BLOCKS_LAYER_FIELD = adScenario.AD_TOGGLE_UNDEFINED_BLOCKS_LAYER_FIELD, AD_OPEN_ALL_LAYERS = adScenario.AD_OPEN_ALL_LAYERS, AD_TOGGLE_ALL_LAYERS_FIELD = adScenario.AD_TOGGLE_ALL_LAYERS_FIELD, SET_GENERIC_ITEM = adScenario.SET_GENERIC_ITEM, UNDO_AD_SCENARIO = adScenario.UNDO_AD_SCENARIO, TOGGLE_CHOICE_SELECTED = adScenario.TOGGLE_CHOICE_SELECTED, SELECT_ALL_CHOICES = adScenario.SELECT_ALL_CHOICES, SET_SELECTED_USER_TAG_GROUP_ID = adScenario.SET_SELECTED_USER_TAG_GROUP_ID, UPDATE_SELECTED_USER_TAG_GROUP = adScenario.UPDATE_SELECTED_USER_TAG_GROUP, UPDATE_QUICK_REPLY_USER_TAG_GROUP = adScenario.UPDATE_QUICK_REPLY_USER_TAG_GROUP, SET_AD_SCENARIOS_LIST = adScenario.SET_AD_SCENARIOS_LIST, AD_SET_MESSAGE = adScenario.AD_SET_MESSAGE, SET_PAGINATED_SCENARIO_IDS = adScenario.SET_PAGINATED_SCENARIO_IDS, SET_LINE_FLEX_MESSAGE_COMPONENT = adScenario.SET_LINE_FLEX_MESSAGE_COMPONENT;
export var setAdScenariosFetching = function (isFetchingScenarios) {
    return {
        type: SET_AD_SCENARIOS_FETCHING,
        isFetchingScenarios: isFetchingScenarios,
    };
};
export var setAdScenarios = function (scenariosData, preventScenarioDeliveryUpdate) {
    var normalizedData = adScenariosNormalizer(scenariosData);
    var scenarios = normalizedData.scenarios, layers = normalizedData.layers, blocks = normalizedData.blocks;
    return {
        type: SET_AD_SCENARIOS,
        scenarios: scenarios,
        layers: layers,
        blocks: blocks,
        preventScenarioDeliveryUpdate: preventScenarioDeliveryUpdate,
    };
};
export var setAdScenario = function (adScenarioParam) {
    // serialize scenario, send scenario, layers, blocks down to reducer
    var normalizedData = singleAdScenarioNormalizer(adScenarioParam);
    var scenarios = normalizedData.scenarios, layers = normalizedData.layers, blocks = normalizedData.blocks;
    return {
        type: SET_AD_SCENARIO,
        scenarios: scenarios,
        layers: layers,
        blocks: blocks,
    };
};
export var setMainScenario = function (scenarioId) { return ({
    type: SET_MAIN_SCENARIO,
    scenarioId: scenarioId,
}); };
export var setBlockMessages = function (block) { return ({
    type: AD_SET_BLOCK_MESSAGES,
    block: block,
}); };
export var setBlockName = function (block) { return ({
    type: SET_BLOCK_NAME,
    block: block,
}); };
export var isValidTriggerTime = function (scenarioId, value) { return ({
    type: AD_IS_VALID_TRIGGER_TIME,
    scenarioId: scenarioId,
    value: value,
}); };
export var adSetStepDaysAfter = function (daysAfter) { return ({
    type: AD_SET_STEP_DAYS_AFTER,
    daysAfter: daysAfter,
}); };
export var adSetStepDeliveryTime = function (prop, value) { return ({
    type: AD_SET_STEP_DELIVERY_TIME,
    prop: prop,
    value: value,
}); };
export var adToggleImageLoading = function (_a) {
    var messageId = _a.messageId, itemId = _a.itemId, isImageLoading = _a.isImageLoading;
    return ({
        type: AD_TOGGLE_IMAGE_LOADING,
        messageId: messageId,
        itemId: itemId,
        isImageLoading: isImageLoading,
    });
};
export var setPrevBlocks = function (prevBlocks) { return ({
    type: AD_SET_PREV_BLOCKS,
    prevBlocks: prevBlocks,
}); };
export var adSetScenarioTestingAction = function (chatbotId, scenarioId, testing) { return ({
    type: AD_SET_SCENARIO_TESTING,
    chatbotId: chatbotId,
    scenarioId: scenarioId,
    testing: testing,
}); };
export var adSetGenericListButton = function (_a) {
    var messageId = _a.messageId, itemId = _a.itemId, buttonItemId = _a.buttonItemId, listType = _a.listType, updatedButton = _a.updatedButton, scenarioId = _a.scenarioId;
    return ({
        type: AD_SET_GENERIC_LIST_BTN,
        messageId: messageId,
        itemId: itemId,
        buttonItemId: buttonItemId,
        listType: listType,
        updatedButton: updatedButton,
        scenarioId: scenarioId,
    });
};
export var toggleQuickReplySelectMode = function () { return ({
    type: TOGGLE_QUICK_REPLY_SELECT_MODE,
}); };
export var adSetLayerField = function (layerId, field) { return ({
    type: AD_SET_LAYER_FIELD,
    layerId: layerId,
    field: field,
}); };
export var adToggleLayerField = function (layerId, key) { return ({
    type: AD_TOGGLE_LAYER_FIELD,
    layerId: layerId,
    key: key,
}); };
export var adSetUndefinedBlocksLayerField = function (scenarioId, field) { return ({
    type: AD_SET_UNDEFINED_BLOCKS_LAYER_FIELD,
    scenarioId: scenarioId,
    field: field,
}); };
export var adToggleUndefinedBlocksLayerField = function (scenarioId, key) { return ({
    type: AD_TOGGLE_UNDEFINED_BLOCKS_LAYER_FIELD,
    scenarioId: scenarioId,
    key: key,
}); };
export var adOpenAllLayers = function (scenarioId) { return ({
    type: AD_OPEN_ALL_LAYERS,
    scenarioId: scenarioId,
}); };
export var adToggleAllLayersField = function (scenarioId, field) { return ({
    type: AD_TOGGLE_ALL_LAYERS_FIELD,
    scenarioId: scenarioId,
    field: field,
}); };
export var setGenericItem = function (messageId, itemId, item) { return ({
    type: SET_GENERIC_ITEM,
    messageId: messageId,
    itemId: itemId,
    item: item,
}); };
export var toggleChoiceSelected = function (blockId, choiceId) { return ({
    type: TOGGLE_CHOICE_SELECTED,
    blockId: blockId,
    choiceId: choiceId,
}); };
export var selectAllChoices = function (blockId) { return ({
    type: SELECT_ALL_CHOICES,
    blockId: blockId,
}); };
export var adSetScenarioTesting = function (chatbotId, scenarioId, testing) { return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, scenarioApi.updateScenario(chatbotId, scenarioId, { testing: testing })];
            case 1:
                _a.sent();
                dispatch(adSetScenarioTestingAction(chatbotId, scenarioId, testing));
                return [2 /*return*/];
        }
    });
}); }; };
export var updateSelectedUserTagGroup = function (userTag) {
    return {
        type: UPDATE_SELECTED_USER_TAG_GROUP,
        userTag: userTag,
    };
};
export var setSelectedUserTagGroupId = function (id) {
    return {
        type: SET_SELECTED_USER_TAG_GROUP_ID,
        id: id,
    };
};
export var updateQuickReplyUserTagGroup = function (blockId, userTag) {
    return {
        type: UPDATE_QUICK_REPLY_USER_TAG_GROUP,
        blockId: blockId,
        userTag: userTag,
    };
};
export function setScenariosList(scenarios) {
    return {
        type: SET_AD_SCENARIOS_LIST,
        scenarios: scenarios,
    };
}
export function setMessage(message) {
    return {
        type: AD_SET_MESSAGE,
        message: message,
    };
}
export function setPaginatedScenarioIds(_a) {
    var ids = _a.ids, totalScenarios = _a.totalScenarios, search = _a.search, page = _a.page, per = _a.per;
    return {
        type: SET_PAGINATED_SCENARIO_IDS,
        ids: ids,
        totalScenarios: totalScenarios,
        search: search,
        page: page,
        per: per,
    };
}
export function setLineFlexMessageComponent(_a) {
    var messageId = _a.messageId, blockType = _a.blockType, componentId = _a.componentId, componentType = _a.componentType, values = _a.values;
    return {
        type: SET_LINE_FLEX_MESSAGE_COMPONENT,
        messageId: messageId,
        blockType: blockType,
        componentId: componentId,
        componentType: componentType,
        values: values,
    };
}
