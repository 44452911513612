var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
/* eslint-disable camelcase */
import React from 'react';
import styled from 'styled-components';
import { colors } from '@src/colors';
import { convertHexToEmoji } from '@src/lib/helpers';
import { hoverHighlight } from '@src/lib/mixins';
import line from '@src/lib/platforms/limitations/line';
import lineEmoji from '@src/assets/images/line_emoji.png';
import { Picker } from 'emoji-mart';
import 'emoji-mart/css/emoji-mart.css';
// It just styled custom line emoji icon by index
// Line emoji large image data contain 175 icon in 4 row and 44 column
// It is on constant LINE_EMOJI_DATA_COLUMN, LINE_EMOJI_DATA_ROW
// To show icon by index, this styled calculate background position,
// first calculate row, column position on large image then multiplication
var lineEmojiStyled = function () {
    var LINE_EMOJI_DATA_COLUMN = line.LINE_EMOJI_DATA_COLUMN, LINE_EMOJI_DATA_ROW = line.LINE_EMOJI_DATA_ROW, LINE_EMOJI_DATA = line.LINE_EMOJI_DATA;
    return __spreadArray([], __read(Array(LINE_EMOJI_DATA.length).keys())).map(function (index) {
        var backgroundXPosition = (index / LINE_EMOJI_DATA_COLUMN -
            Math.floor(index / LINE_EMOJI_DATA_COLUMN)) *
            100;
        var backgroundYPosition = Math.floor(index / LINE_EMOJI_DATA_COLUMN) *
            (100 / (LINE_EMOJI_DATA_ROW - 1));
        return "\n      span[style*='background-image: url(\"line_emoji_" + index + "\");'] {\n        background-position: " + backgroundXPosition + "% " + backgroundYPosition + "% !important;\n      }\n    ";
    })
        .join();
};
var EmojiWrapper = styled.span(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: absolute;\n  z-index: 1000;\n  left: 0;\n  text-align: left;\n\n  .emoji-mart-emoji-custom {\n    ", "\n\n    span {\n      background-size: ", "00% auto !important;\n      display: inline-block !important;\n      background-image: url(", ") !important;\n    }\n  }\n"], ["\n  position: absolute;\n  z-index: 1000;\n  left: 0;\n  text-align: left;\n\n  .emoji-mart-emoji-custom {\n    ", "\n\n    span {\n      background-size: ", "00% auto !important;\n      display: inline-block !important;\n      background-image: url(", ") !important;\n    }\n  }\n"])), lineEmojiStyled(), line.LINE_EMOJI_DATA_COLUMN + 1, lineEmoji);
var EmojiButton = styled.span(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-size: 1.2rem;\n  color: ", ";\n  vertical-align: baseline;\n  ", ";\n"], ["\n  font-size: 1.2rem;\n  color: ", ";\n  vertical-align: baseline;\n  ", ";\n"])), colors.placeholder, hoverHighlight);
var EmojiSelector = function (props) {
    var emojiPicker = React.useRef();
    var _a = __read(React.useState(false), 2), areEmojisShown = _a[0], setAreEmojisShown = _a[1];
    var showEmojis = function () {
        setAreEmojisShown(true);
        document.addEventListener('click', closeMenu, { capture: true });
    };
    var closeMenu = function (e) {
        if (emojiPicker.current !== null &&
            !emojiPicker.current.contains(e.target)) {
            setAreEmojisShown(false);
            document.removeEventListener('click', closeMenu, { capture: true });
        }
    };
    var addEmoji = function (emoji) {
        var text = props.text, name = props.name, onChange = props.onChange, cursorPosition = props.cursorPosition, withCustomEmoji = props.withCustomEmoji;
        var position = Number.isNaN(cursorPosition)
            ? text.length
            : cursorPosition;
        var isCustomEmoji = function (emoji) {
            return (Object.prototype.hasOwnProperty.call(emoji, 'custom') &&
                Object.prototype.hasOwnProperty.call(emoji, 'imageUrl'));
        };
        if (isCustomEmoji(emoji)) {
            if (!withCustomEmoji)
                alert('LINE絵文字はテキストメッセージ以外では使用できません');
            var index = emoji.imageUrl.replace('line_emoji_', '');
            var code = line.LINE_EMOJI_DATA[index].code;
            // Custom emoji has empty native property -- assign the LINE emoji to it
            emoji.native = convertHexToEmoji(code);
        }
        // Add emoji to cursor position
        onChange("" + text.slice(0, position) + emoji.native + text.slice(position), name, emoji);
        document.removeEventListener('click', closeMenu);
        setAreEmojisShown(false);
        return undefined;
    };
    var className = props.className, withCustomEmoji = props.withCustomEmoji;
    var customEmojis = line.LINE_EMOJI_DATA.map(function (_a, index) {
        var tag_en = _a.tag_en, tag_jp = _a.tag_jp;
        return ({
            name: "line " + tag_en,
            short_names: [tag_jp],
            imageUrl: "line_emoji_" + index,
        });
    });
    return (React.createElement("div", { className: className }, areEmojisShown ? (React.createElement(EmojiWrapper, { className: "emoji_wrapper", ref: emojiPicker },
        React.createElement(Picker, { set: "apple", title: "Pick your emoji\u2026", emoji: "point_up", color: colors.main_color, emojiSize: 24, onClick: addEmoji, custom: withCustomEmoji && customEmojis }))) : (React.createElement(EmojiButton, { className: "adicon_emoji", onClick: function () { return showEmojis(); }, "data-testid": "emoji-button" }))));
};
export default EmojiSelector;
var templateObject_1, templateObject_2;
