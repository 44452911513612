// source: entities/element.proto

/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

/* eslint-disable */
// @ts-nocheck
var jspb = require('google-protobuf');

var goog = jspb;
var global = Function('return this')();

var entities_style_pb = require('../entities/style_pb.js');

goog.object.extend(proto, entities_style_pb);
goog.exportSymbol('proto.e.Audio', null, global);
goog.exportSymbol('proto.e.Audio.Metadata', null, global);
goog.exportSymbol('proto.e.Button', null, global);
goog.exportSymbol('proto.e.Chip', null, global);
goog.exportSymbol('proto.e.CoordinateArea', null, global);
goog.exportSymbol('proto.e.Div', null, global);
goog.exportSymbol('proto.e.Element', null, global);
goog.exportSymbol('proto.e.Element.ElementCase', null, global);
goog.exportSymbol('proto.e.ElementType', null, global);
goog.exportSymbol('proto.e.HR', null, global);
goog.exportSymbol('proto.e.Icon', null, global);
goog.exportSymbol('proto.e.Image', null, global);
goog.exportSymbol('proto.e.ImageMap', null, global);
goog.exportSymbol('proto.e.ImageMapVideo', null, global);
goog.exportSymbol('proto.e.OnPress', null, global);
goog.exportSymbol('proto.e.OnPress.ActionCase', null, global);
goog.exportSymbol('proto.e.OnPress.Message', null, global);
goog.exportSymbol('proto.e.OnPress.Postback', null, global);
goog.exportSymbol('proto.e.OnPress.Uri', null, global);
goog.exportSymbol('proto.e.OnPressType', null, global);
goog.exportSymbol('proto.e.Span', null, global);
goog.exportSymbol('proto.e.Video', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */

proto.e.Element = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.e.Element.oneofGroups_);
};

goog.inherits(proto.e.Element, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.e.Element.displayName = 'proto.e.Element';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.e.Div = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.e.Div.repeatedFields_, null);
};

goog.inherits(proto.e.Div, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.e.Div.displayName = 'proto.e.Div';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.e.Span = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.e.Span, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.e.Span.displayName = 'proto.e.Span';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.e.Image = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.e.Image, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.e.Image.displayName = 'proto.e.Image';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.e.Button = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.e.Button, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.e.Button.displayName = 'proto.e.Button';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.e.Audio = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.e.Audio, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.e.Audio.displayName = 'proto.e.Audio';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.e.Audio.Metadata = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.e.Audio.Metadata, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.e.Audio.Metadata.displayName = 'proto.e.Audio.Metadata';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.e.Video = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.e.Video, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.e.Video.displayName = 'proto.e.Video';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.e.HR = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.e.HR, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.e.HR.displayName = 'proto.e.HR';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.e.Chip = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.e.Chip, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.e.Chip.displayName = 'proto.e.Chip';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.e.CoordinateArea = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.e.CoordinateArea, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.e.CoordinateArea.displayName = 'proto.e.CoordinateArea';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.e.ImageMap = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.e.ImageMap.repeatedFields_, null);
};

goog.inherits(proto.e.ImageMap, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.e.ImageMap.displayName = 'proto.e.ImageMap';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.e.ImageMapVideo = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.e.ImageMapVideo.repeatedFields_, null);
};

goog.inherits(proto.e.ImageMapVideo, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.e.ImageMapVideo.displayName = 'proto.e.ImageMapVideo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.e.Icon = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.e.Icon, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.e.Icon.displayName = 'proto.e.Icon';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.e.OnPress = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.e.OnPress.oneofGroups_);
};

goog.inherits(proto.e.OnPress, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.e.OnPress.displayName = 'proto.e.OnPress';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.e.OnPress.Postback = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.e.OnPress.Postback, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.e.OnPress.Postback.displayName = 'proto.e.OnPress.Postback';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.e.OnPress.Message = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.e.OnPress.Message, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.e.OnPress.Message.displayName = 'proto.e.OnPress.Message';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.e.OnPress.Uri = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.e.OnPress.Uri, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.e.OnPress.Uri.displayName = 'proto.e.OnPress.Uri';
}
/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */


proto.e.Element.oneofGroups_ = [[3, 4, 5, 6, 7, 8, 9, 16, 17, 18, 19]];
/**
 * @enum {number}
 */

proto.e.Element.ElementCase = {
  ELEMENT_NOT_SET: 0,
  DIV: 3,
  SPAN: 4,
  IMAGE: 5,
  BUTTON: 6,
  AUDIO: 7,
  VIDEO: 8,
  HR: 9,
  CHIP: 16,
  IMAGE_MAP: 17,
  IMAGE_MAP_VIDEO: 18,
  ICON: 19
};
/**
 * @return {proto.e.Element.ElementCase}
 */

proto.e.Element.prototype.getElementCase = function () {
  return (
    /** @type {proto.e.Element.ElementCase} */
    jspb.Message.computeOneofCase(this, proto.e.Element.oneofGroups_[0])
  );
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.e.Element.prototype.toObject = function (opt_includeInstance) {
    return proto.e.Element.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.e.Element} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.e.Element.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      id: jspb.Message.getFieldWithDefault(msg, 10, 0),
      type: jspb.Message.getFieldWithDefault(msg, 1, 0),
      style: (f = msg.getStyle()) && entities_style_pb.CSSProperties.toObject(includeInstance, f),
      div: (f = msg.getDiv()) && proto.e.Div.toObject(includeInstance, f),
      span: (f = msg.getSpan()) && proto.e.Span.toObject(includeInstance, f),
      image: (f = msg.getImage()) && proto.e.Image.toObject(includeInstance, f),
      button: (f = msg.getButton()) && proto.e.Button.toObject(includeInstance, f),
      audio: (f = msg.getAudio()) && proto.e.Audio.toObject(includeInstance, f),
      video: (f = msg.getVideo()) && proto.e.Video.toObject(includeInstance, f),
      hr: (f = msg.getHr()) && proto.e.HR.toObject(includeInstance, f),
      chip: (f = msg.getChip()) && proto.e.Chip.toObject(includeInstance, f),
      imageMap: (f = msg.getImageMap()) && proto.e.ImageMap.toObject(includeInstance, f),
      imageMapVideo: (f = msg.getImageMapVideo()) && proto.e.ImageMapVideo.toObject(includeInstance, f),
      icon: (f = msg.getIcon()) && proto.e.Icon.toObject(includeInstance, f),
      name: jspb.Message.getFieldWithDefault(msg, 20, ""),
      alt: jspb.Message.getFieldWithDefault(msg, 21, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.e.Element}
 */


proto.e.Element.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.e.Element();
  return proto.e.Element.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.e.Element} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.e.Element}
 */


proto.e.Element.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 10:
        var value =
        /** @type {number} */
        reader.readInt64();
        msg.setId(value);
        break;

      case 1:
        var value =
        /** @type {!proto.e.ElementType} */
        reader.readEnum();
        msg.setType(value);
        break;

      case 2:
        var value = new entities_style_pb.CSSProperties();
        reader.readMessage(value, entities_style_pb.CSSProperties.deserializeBinaryFromReader);
        msg.setStyle(value);
        break;

      case 3:
        var value = new proto.e.Div();
        reader.readMessage(value, proto.e.Div.deserializeBinaryFromReader);
        msg.setDiv(value);
        break;

      case 4:
        var value = new proto.e.Span();
        reader.readMessage(value, proto.e.Span.deserializeBinaryFromReader);
        msg.setSpan(value);
        break;

      case 5:
        var value = new proto.e.Image();
        reader.readMessage(value, proto.e.Image.deserializeBinaryFromReader);
        msg.setImage(value);
        break;

      case 6:
        var value = new proto.e.Button();
        reader.readMessage(value, proto.e.Button.deserializeBinaryFromReader);
        msg.setButton(value);
        break;

      case 7:
        var value = new proto.e.Audio();
        reader.readMessage(value, proto.e.Audio.deserializeBinaryFromReader);
        msg.setAudio(value);
        break;

      case 8:
        var value = new proto.e.Video();
        reader.readMessage(value, proto.e.Video.deserializeBinaryFromReader);
        msg.setVideo(value);
        break;

      case 9:
        var value = new proto.e.HR();
        reader.readMessage(value, proto.e.HR.deserializeBinaryFromReader);
        msg.setHr(value);
        break;

      case 16:
        var value = new proto.e.Chip();
        reader.readMessage(value, proto.e.Chip.deserializeBinaryFromReader);
        msg.setChip(value);
        break;

      case 17:
        var value = new proto.e.ImageMap();
        reader.readMessage(value, proto.e.ImageMap.deserializeBinaryFromReader);
        msg.setImageMap(value);
        break;

      case 18:
        var value = new proto.e.ImageMapVideo();
        reader.readMessage(value, proto.e.ImageMapVideo.deserializeBinaryFromReader);
        msg.setImageMapVideo(value);
        break;

      case 19:
        var value = new proto.e.Icon();
        reader.readMessage(value, proto.e.Icon.deserializeBinaryFromReader);
        msg.setIcon(value);
        break;

      case 20:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setName(value);
        break;

      case 21:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setAlt(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.e.Element.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.e.Element.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.e.Element} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.e.Element.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getId();

  if (f !== 0) {
    writer.writeInt64(10, f);
  }

  f = message.getType();

  if (f !== 0.0) {
    writer.writeEnum(1, f);
  }

  f = message.getStyle();

  if (f != null) {
    writer.writeMessage(2, f, entities_style_pb.CSSProperties.serializeBinaryToWriter);
  }

  f = message.getDiv();

  if (f != null) {
    writer.writeMessage(3, f, proto.e.Div.serializeBinaryToWriter);
  }

  f = message.getSpan();

  if (f != null) {
    writer.writeMessage(4, f, proto.e.Span.serializeBinaryToWriter);
  }

  f = message.getImage();

  if (f != null) {
    writer.writeMessage(5, f, proto.e.Image.serializeBinaryToWriter);
  }

  f = message.getButton();

  if (f != null) {
    writer.writeMessage(6, f, proto.e.Button.serializeBinaryToWriter);
  }

  f = message.getAudio();

  if (f != null) {
    writer.writeMessage(7, f, proto.e.Audio.serializeBinaryToWriter);
  }

  f = message.getVideo();

  if (f != null) {
    writer.writeMessage(8, f, proto.e.Video.serializeBinaryToWriter);
  }

  f = message.getHr();

  if (f != null) {
    writer.writeMessage(9, f, proto.e.HR.serializeBinaryToWriter);
  }

  f = message.getChip();

  if (f != null) {
    writer.writeMessage(16, f, proto.e.Chip.serializeBinaryToWriter);
  }

  f = message.getImageMap();

  if (f != null) {
    writer.writeMessage(17, f, proto.e.ImageMap.serializeBinaryToWriter);
  }

  f = message.getImageMapVideo();

  if (f != null) {
    writer.writeMessage(18, f, proto.e.ImageMapVideo.serializeBinaryToWriter);
  }

  f = message.getIcon();

  if (f != null) {
    writer.writeMessage(19, f, proto.e.Icon.serializeBinaryToWriter);
  }

  f = message.getName();

  if (f.length > 0) {
    writer.writeString(20, f);
  }

  f = message.getAlt();

  if (f.length > 0) {
    writer.writeString(21, f);
  }
};
/**
 * optional int64 id = 10;
 * @return {number}
 */


proto.e.Element.prototype.getId = function () {
  return (
    /** @type {number} */
    jspb.Message.getFieldWithDefault(this, 10, 0)
  );
};
/**
 * @param {number} value
 * @return {!proto.e.Element} returns this
 */


proto.e.Element.prototype.setId = function (value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};
/**
 * optional ElementType type = 1;
 * @return {!proto.e.ElementType}
 */


proto.e.Element.prototype.getType = function () {
  return (
    /** @type {!proto.e.ElementType} */
    jspb.Message.getFieldWithDefault(this, 1, 0)
  );
};
/**
 * @param {!proto.e.ElementType} value
 * @return {!proto.e.Element} returns this
 */


proto.e.Element.prototype.setType = function (value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};
/**
 * optional CSSProperties style = 2;
 * @return {?proto.e.CSSProperties}
 */


proto.e.Element.prototype.getStyle = function () {
  return (
    /** @type{?proto.e.CSSProperties} */
    jspb.Message.getWrapperField(this, entities_style_pb.CSSProperties, 2)
  );
};
/**
 * @param {?proto.e.CSSProperties|undefined} value
 * @return {!proto.e.Element} returns this
*/


proto.e.Element.prototype.setStyle = function (value) {
  return jspb.Message.setWrapperField(this, 2, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.e.Element} returns this
 */


proto.e.Element.prototype.clearStyle = function () {
  return this.setStyle(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.e.Element.prototype.hasStyle = function () {
  return jspb.Message.getField(this, 2) != null;
};
/**
 * optional Div div = 3;
 * @return {?proto.e.Div}
 */


proto.e.Element.prototype.getDiv = function () {
  return (
    /** @type{?proto.e.Div} */
    jspb.Message.getWrapperField(this, proto.e.Div, 3)
  );
};
/**
 * @param {?proto.e.Div|undefined} value
 * @return {!proto.e.Element} returns this
*/


proto.e.Element.prototype.setDiv = function (value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.e.Element.oneofGroups_[0], value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.e.Element} returns this
 */


proto.e.Element.prototype.clearDiv = function () {
  return this.setDiv(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.e.Element.prototype.hasDiv = function () {
  return jspb.Message.getField(this, 3) != null;
};
/**
 * optional Span span = 4;
 * @return {?proto.e.Span}
 */


proto.e.Element.prototype.getSpan = function () {
  return (
    /** @type{?proto.e.Span} */
    jspb.Message.getWrapperField(this, proto.e.Span, 4)
  );
};
/**
 * @param {?proto.e.Span|undefined} value
 * @return {!proto.e.Element} returns this
*/


proto.e.Element.prototype.setSpan = function (value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.e.Element.oneofGroups_[0], value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.e.Element} returns this
 */


proto.e.Element.prototype.clearSpan = function () {
  return this.setSpan(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.e.Element.prototype.hasSpan = function () {
  return jspb.Message.getField(this, 4) != null;
};
/**
 * optional Image image = 5;
 * @return {?proto.e.Image}
 */


proto.e.Element.prototype.getImage = function () {
  return (
    /** @type{?proto.e.Image} */
    jspb.Message.getWrapperField(this, proto.e.Image, 5)
  );
};
/**
 * @param {?proto.e.Image|undefined} value
 * @return {!proto.e.Element} returns this
*/


proto.e.Element.prototype.setImage = function (value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.e.Element.oneofGroups_[0], value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.e.Element} returns this
 */


proto.e.Element.prototype.clearImage = function () {
  return this.setImage(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.e.Element.prototype.hasImage = function () {
  return jspb.Message.getField(this, 5) != null;
};
/**
 * optional Button button = 6;
 * @return {?proto.e.Button}
 */


proto.e.Element.prototype.getButton = function () {
  return (
    /** @type{?proto.e.Button} */
    jspb.Message.getWrapperField(this, proto.e.Button, 6)
  );
};
/**
 * @param {?proto.e.Button|undefined} value
 * @return {!proto.e.Element} returns this
*/


proto.e.Element.prototype.setButton = function (value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.e.Element.oneofGroups_[0], value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.e.Element} returns this
 */


proto.e.Element.prototype.clearButton = function () {
  return this.setButton(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.e.Element.prototype.hasButton = function () {
  return jspb.Message.getField(this, 6) != null;
};
/**
 * optional Audio audio = 7;
 * @return {?proto.e.Audio}
 */


proto.e.Element.prototype.getAudio = function () {
  return (
    /** @type{?proto.e.Audio} */
    jspb.Message.getWrapperField(this, proto.e.Audio, 7)
  );
};
/**
 * @param {?proto.e.Audio|undefined} value
 * @return {!proto.e.Element} returns this
*/


proto.e.Element.prototype.setAudio = function (value) {
  return jspb.Message.setOneofWrapperField(this, 7, proto.e.Element.oneofGroups_[0], value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.e.Element} returns this
 */


proto.e.Element.prototype.clearAudio = function () {
  return this.setAudio(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.e.Element.prototype.hasAudio = function () {
  return jspb.Message.getField(this, 7) != null;
};
/**
 * optional Video video = 8;
 * @return {?proto.e.Video}
 */


proto.e.Element.prototype.getVideo = function () {
  return (
    /** @type{?proto.e.Video} */
    jspb.Message.getWrapperField(this, proto.e.Video, 8)
  );
};
/**
 * @param {?proto.e.Video|undefined} value
 * @return {!proto.e.Element} returns this
*/


proto.e.Element.prototype.setVideo = function (value) {
  return jspb.Message.setOneofWrapperField(this, 8, proto.e.Element.oneofGroups_[0], value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.e.Element} returns this
 */


proto.e.Element.prototype.clearVideo = function () {
  return this.setVideo(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.e.Element.prototype.hasVideo = function () {
  return jspb.Message.getField(this, 8) != null;
};
/**
 * optional HR hr = 9;
 * @return {?proto.e.HR}
 */


proto.e.Element.prototype.getHr = function () {
  return (
    /** @type{?proto.e.HR} */
    jspb.Message.getWrapperField(this, proto.e.HR, 9)
  );
};
/**
 * @param {?proto.e.HR|undefined} value
 * @return {!proto.e.Element} returns this
*/


proto.e.Element.prototype.setHr = function (value) {
  return jspb.Message.setOneofWrapperField(this, 9, proto.e.Element.oneofGroups_[0], value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.e.Element} returns this
 */


proto.e.Element.prototype.clearHr = function () {
  return this.setHr(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.e.Element.prototype.hasHr = function () {
  return jspb.Message.getField(this, 9) != null;
};
/**
 * optional Chip chip = 16;
 * @return {?proto.e.Chip}
 */


proto.e.Element.prototype.getChip = function () {
  return (
    /** @type{?proto.e.Chip} */
    jspb.Message.getWrapperField(this, proto.e.Chip, 16)
  );
};
/**
 * @param {?proto.e.Chip|undefined} value
 * @return {!proto.e.Element} returns this
*/


proto.e.Element.prototype.setChip = function (value) {
  return jspb.Message.setOneofWrapperField(this, 16, proto.e.Element.oneofGroups_[0], value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.e.Element} returns this
 */


proto.e.Element.prototype.clearChip = function () {
  return this.setChip(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.e.Element.prototype.hasChip = function () {
  return jspb.Message.getField(this, 16) != null;
};
/**
 * optional ImageMap image_map = 17;
 * @return {?proto.e.ImageMap}
 */


proto.e.Element.prototype.getImageMap = function () {
  return (
    /** @type{?proto.e.ImageMap} */
    jspb.Message.getWrapperField(this, proto.e.ImageMap, 17)
  );
};
/**
 * @param {?proto.e.ImageMap|undefined} value
 * @return {!proto.e.Element} returns this
*/


proto.e.Element.prototype.setImageMap = function (value) {
  return jspb.Message.setOneofWrapperField(this, 17, proto.e.Element.oneofGroups_[0], value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.e.Element} returns this
 */


proto.e.Element.prototype.clearImageMap = function () {
  return this.setImageMap(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.e.Element.prototype.hasImageMap = function () {
  return jspb.Message.getField(this, 17) != null;
};
/**
 * optional ImageMapVideo image_map_video = 18;
 * @return {?proto.e.ImageMapVideo}
 */


proto.e.Element.prototype.getImageMapVideo = function () {
  return (
    /** @type{?proto.e.ImageMapVideo} */
    jspb.Message.getWrapperField(this, proto.e.ImageMapVideo, 18)
  );
};
/**
 * @param {?proto.e.ImageMapVideo|undefined} value
 * @return {!proto.e.Element} returns this
*/


proto.e.Element.prototype.setImageMapVideo = function (value) {
  return jspb.Message.setOneofWrapperField(this, 18, proto.e.Element.oneofGroups_[0], value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.e.Element} returns this
 */


proto.e.Element.prototype.clearImageMapVideo = function () {
  return this.setImageMapVideo(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.e.Element.prototype.hasImageMapVideo = function () {
  return jspb.Message.getField(this, 18) != null;
};
/**
 * optional Icon icon = 19;
 * @return {?proto.e.Icon}
 */


proto.e.Element.prototype.getIcon = function () {
  return (
    /** @type{?proto.e.Icon} */
    jspb.Message.getWrapperField(this, proto.e.Icon, 19)
  );
};
/**
 * @param {?proto.e.Icon|undefined} value
 * @return {!proto.e.Element} returns this
*/


proto.e.Element.prototype.setIcon = function (value) {
  return jspb.Message.setOneofWrapperField(this, 19, proto.e.Element.oneofGroups_[0], value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.e.Element} returns this
 */


proto.e.Element.prototype.clearIcon = function () {
  return this.setIcon(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.e.Element.prototype.hasIcon = function () {
  return jspb.Message.getField(this, 19) != null;
};
/**
 * optional string name = 20;
 * @return {string}
 */


proto.e.Element.prototype.getName = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 20, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.e.Element} returns this
 */


proto.e.Element.prototype.setName = function (value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};
/**
 * optional string alt = 21;
 * @return {string}
 */


proto.e.Element.prototype.getAlt = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 21, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.e.Element} returns this
 */


proto.e.Element.prototype.setAlt = function (value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */


proto.e.Div.repeatedFields_ = [1];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.e.Div.prototype.toObject = function (opt_includeInstance) {
    return proto.e.Div.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.e.Div} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.e.Div.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      childrenList: jspb.Message.toObjectList(msg.getChildrenList(), proto.e.Element.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.e.Div}
 */


proto.e.Div.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.e.Div();
  return proto.e.Div.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.e.Div} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.e.Div}
 */


proto.e.Div.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value = new proto.e.Element();
        reader.readMessage(value, proto.e.Element.deserializeBinaryFromReader);
        msg.addChildren(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.e.Div.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.e.Div.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.e.Div} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.e.Div.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getChildrenList();

  if (f.length > 0) {
    writer.writeRepeatedMessage(1, f, proto.e.Element.serializeBinaryToWriter);
  }
};
/**
 * repeated Element children = 1;
 * @return {!Array<!proto.e.Element>}
 */


proto.e.Div.prototype.getChildrenList = function () {
  return (
    /** @type{!Array<!proto.e.Element>} */
    jspb.Message.getRepeatedWrapperField(this, proto.e.Element, 1)
  );
};
/**
 * @param {!Array<!proto.e.Element>} value
 * @return {!proto.e.Div} returns this
*/


proto.e.Div.prototype.setChildrenList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};
/**
 * @param {!proto.e.Element=} opt_value
 * @param {number=} opt_index
 * @return {!proto.e.Element}
 */


proto.e.Div.prototype.addChildren = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.e.Element, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.e.Div} returns this
 */


proto.e.Div.prototype.clearChildrenList = function () {
  return this.setChildrenList([]);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.e.Span.prototype.toObject = function (opt_includeInstance) {
    return proto.e.Span.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.e.Span} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.e.Span.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      text: jspb.Message.getFieldWithDefault(msg, 1, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.e.Span}
 */


proto.e.Span.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.e.Span();
  return proto.e.Span.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.e.Span} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.e.Span}
 */


proto.e.Span.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setText(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.e.Span.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.e.Span.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.e.Span} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.e.Span.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getText();

  if (f.length > 0) {
    writer.writeString(1, f);
  }
};
/**
 * optional string text = 1;
 * @return {string}
 */


proto.e.Span.prototype.getText = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 1, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.e.Span} returns this
 */


proto.e.Span.prototype.setText = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.e.Image.prototype.toObject = function (opt_includeInstance) {
    return proto.e.Image.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.e.Image} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.e.Image.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      src: jspb.Message.getFieldWithDefault(msg, 1, ""),
      alt: jspb.Message.getFieldWithDefault(msg, 2, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.e.Image}
 */


proto.e.Image.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.e.Image();
  return proto.e.Image.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.e.Image} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.e.Image}
 */


proto.e.Image.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setSrc(value);
        break;

      case 2:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setAlt(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.e.Image.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.e.Image.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.e.Image} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.e.Image.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getSrc();

  if (f.length > 0) {
    writer.writeString(1, f);
  }

  f = message.getAlt();

  if (f.length > 0) {
    writer.writeString(2, f);
  }
};
/**
 * optional string src = 1;
 * @return {string}
 */


proto.e.Image.prototype.getSrc = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 1, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.e.Image} returns this
 */


proto.e.Image.prototype.setSrc = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional string alt = 2;
 * @return {string}
 */


proto.e.Image.prototype.getAlt = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 2, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.e.Image} returns this
 */


proto.e.Image.prototype.setAlt = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.e.Button.prototype.toObject = function (opt_includeInstance) {
    return proto.e.Button.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.e.Button} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.e.Button.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      text: jspb.Message.getFieldWithDefault(msg, 1, ""),
      onPress: (f = msg.getOnPress()) && proto.e.OnPress.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.e.Button}
 */


proto.e.Button.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.e.Button();
  return proto.e.Button.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.e.Button} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.e.Button}
 */


proto.e.Button.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setText(value);
        break;

      case 2:
        var value = new proto.e.OnPress();
        reader.readMessage(value, proto.e.OnPress.deserializeBinaryFromReader);
        msg.setOnPress(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.e.Button.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.e.Button.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.e.Button} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.e.Button.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getText();

  if (f.length > 0) {
    writer.writeString(1, f);
  }

  f = message.getOnPress();

  if (f != null) {
    writer.writeMessage(2, f, proto.e.OnPress.serializeBinaryToWriter);
  }
};
/**
 * optional string text = 1;
 * @return {string}
 */


proto.e.Button.prototype.getText = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 1, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.e.Button} returns this
 */


proto.e.Button.prototype.setText = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional OnPress on_press = 2;
 * @return {?proto.e.OnPress}
 */


proto.e.Button.prototype.getOnPress = function () {
  return (
    /** @type{?proto.e.OnPress} */
    jspb.Message.getWrapperField(this, proto.e.OnPress, 2)
  );
};
/**
 * @param {?proto.e.OnPress|undefined} value
 * @return {!proto.e.Button} returns this
*/


proto.e.Button.prototype.setOnPress = function (value) {
  return jspb.Message.setWrapperField(this, 2, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.e.Button} returns this
 */


proto.e.Button.prototype.clearOnPress = function () {
  return this.setOnPress(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.e.Button.prototype.hasOnPress = function () {
  return jspb.Message.getField(this, 2) != null;
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.e.Audio.prototype.toObject = function (opt_includeInstance) {
    return proto.e.Audio.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.e.Audio} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.e.Audio.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      src: jspb.Message.getFieldWithDefault(msg, 1, ""),
      metadata: (f = msg.getMetadata()) && proto.e.Audio.Metadata.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.e.Audio}
 */


proto.e.Audio.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.e.Audio();
  return proto.e.Audio.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.e.Audio} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.e.Audio}
 */


proto.e.Audio.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setSrc(value);
        break;

      case 2:
        var value = new proto.e.Audio.Metadata();
        reader.readMessage(value, proto.e.Audio.Metadata.deserializeBinaryFromReader);
        msg.setMetadata(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.e.Audio.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.e.Audio.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.e.Audio} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.e.Audio.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getSrc();

  if (f.length > 0) {
    writer.writeString(1, f);
  }

  f = message.getMetadata();

  if (f != null) {
    writer.writeMessage(2, f, proto.e.Audio.Metadata.serializeBinaryToWriter);
  }
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.e.Audio.Metadata.prototype.toObject = function (opt_includeInstance) {
    return proto.e.Audio.Metadata.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.e.Audio.Metadata} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.e.Audio.Metadata.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      duration: jspb.Message.getFieldWithDefault(msg, 1, 0)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.e.Audio.Metadata}
 */


proto.e.Audio.Metadata.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.e.Audio.Metadata();
  return proto.e.Audio.Metadata.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.e.Audio.Metadata} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.e.Audio.Metadata}
 */


proto.e.Audio.Metadata.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value =
        /** @type {number} */
        reader.readInt64();
        msg.setDuration(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.e.Audio.Metadata.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.e.Audio.Metadata.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.e.Audio.Metadata} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.e.Audio.Metadata.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getDuration();

  if (f !== 0) {
    writer.writeInt64(1, f);
  }
};
/**
 * optional int64 duration = 1;
 * @return {number}
 */


proto.e.Audio.Metadata.prototype.getDuration = function () {
  return (
    /** @type {number} */
    jspb.Message.getFieldWithDefault(this, 1, 0)
  );
};
/**
 * @param {number} value
 * @return {!proto.e.Audio.Metadata} returns this
 */


proto.e.Audio.Metadata.prototype.setDuration = function (value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};
/**
 * optional string src = 1;
 * @return {string}
 */


proto.e.Audio.prototype.getSrc = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 1, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.e.Audio} returns this
 */


proto.e.Audio.prototype.setSrc = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional Metadata metadata = 2;
 * @return {?proto.e.Audio.Metadata}
 */


proto.e.Audio.prototype.getMetadata = function () {
  return (
    /** @type{?proto.e.Audio.Metadata} */
    jspb.Message.getWrapperField(this, proto.e.Audio.Metadata, 2)
  );
};
/**
 * @param {?proto.e.Audio.Metadata|undefined} value
 * @return {!proto.e.Audio} returns this
*/


proto.e.Audio.prototype.setMetadata = function (value) {
  return jspb.Message.setWrapperField(this, 2, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.e.Audio} returns this
 */


proto.e.Audio.prototype.clearMetadata = function () {
  return this.setMetadata(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.e.Audio.prototype.hasMetadata = function () {
  return jspb.Message.getField(this, 2) != null;
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.e.Video.prototype.toObject = function (opt_includeInstance) {
    return proto.e.Video.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.e.Video} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.e.Video.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      src: jspb.Message.getFieldWithDefault(msg, 1, ""),
      poster: jspb.Message.getFieldWithDefault(msg, 2, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.e.Video}
 */


proto.e.Video.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.e.Video();
  return proto.e.Video.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.e.Video} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.e.Video}
 */


proto.e.Video.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setSrc(value);
        break;

      case 2:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setPoster(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.e.Video.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.e.Video.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.e.Video} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.e.Video.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getSrc();

  if (f.length > 0) {
    writer.writeString(1, f);
  }

  f = message.getPoster();

  if (f.length > 0) {
    writer.writeString(2, f);
  }
};
/**
 * optional string src = 1;
 * @return {string}
 */


proto.e.Video.prototype.getSrc = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 1, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.e.Video} returns this
 */


proto.e.Video.prototype.setSrc = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional string poster = 2;
 * @return {string}
 */


proto.e.Video.prototype.getPoster = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 2, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.e.Video} returns this
 */


proto.e.Video.prototype.setPoster = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.e.HR.prototype.toObject = function (opt_includeInstance) {
    return proto.e.HR.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.e.HR} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.e.HR.toObject = function (includeInstance, msg) {
    var f,
        obj = {};

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.e.HR}
 */


proto.e.HR.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.e.HR();
  return proto.e.HR.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.e.HR} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.e.HR}
 */


proto.e.HR.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.e.HR.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.e.HR.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.e.HR} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.e.HR.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.e.Chip.prototype.toObject = function (opt_includeInstance) {
    return proto.e.Chip.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.e.Chip} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.e.Chip.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      text: jspb.Message.getFieldWithDefault(msg, 1, ""),
      onPress: (f = msg.getOnPress()) && proto.e.OnPress.toObject(includeInstance, f),
      iconUri: jspb.Message.getFieldWithDefault(msg, 16, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.e.Chip}
 */


proto.e.Chip.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.e.Chip();
  return proto.e.Chip.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.e.Chip} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.e.Chip}
 */


proto.e.Chip.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setText(value);
        break;

      case 2:
        var value = new proto.e.OnPress();
        reader.readMessage(value, proto.e.OnPress.deserializeBinaryFromReader);
        msg.setOnPress(value);
        break;

      case 16:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setIconUri(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.e.Chip.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.e.Chip.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.e.Chip} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.e.Chip.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getText();

  if (f.length > 0) {
    writer.writeString(1, f);
  }

  f = message.getOnPress();

  if (f != null) {
    writer.writeMessage(2, f, proto.e.OnPress.serializeBinaryToWriter);
  }

  f = message.getIconUri();

  if (f.length > 0) {
    writer.writeString(16, f);
  }
};
/**
 * optional string text = 1;
 * @return {string}
 */


proto.e.Chip.prototype.getText = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 1, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.e.Chip} returns this
 */


proto.e.Chip.prototype.setText = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional OnPress on_press = 2;
 * @return {?proto.e.OnPress}
 */


proto.e.Chip.prototype.getOnPress = function () {
  return (
    /** @type{?proto.e.OnPress} */
    jspb.Message.getWrapperField(this, proto.e.OnPress, 2)
  );
};
/**
 * @param {?proto.e.OnPress|undefined} value
 * @return {!proto.e.Chip} returns this
*/


proto.e.Chip.prototype.setOnPress = function (value) {
  return jspb.Message.setWrapperField(this, 2, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.e.Chip} returns this
 */


proto.e.Chip.prototype.clearOnPress = function () {
  return this.setOnPress(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.e.Chip.prototype.hasOnPress = function () {
  return jspb.Message.getField(this, 2) != null;
};
/**
 * optional string icon_uri = 16;
 * @return {string}
 */


proto.e.Chip.prototype.getIconUri = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 16, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.e.Chip} returns this
 */


proto.e.Chip.prototype.setIconUri = function (value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.e.CoordinateArea.prototype.toObject = function (opt_includeInstance) {
    return proto.e.CoordinateArea.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.e.CoordinateArea} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.e.CoordinateArea.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      x: jspb.Message.getFieldWithDefault(msg, 1, 0),
      y: jspb.Message.getFieldWithDefault(msg, 2, 0),
      height: jspb.Message.getFieldWithDefault(msg, 3, 0),
      width: jspb.Message.getFieldWithDefault(msg, 4, 0),
      onPress: (f = msg.getOnPress()) && proto.e.OnPress.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.e.CoordinateArea}
 */


proto.e.CoordinateArea.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.e.CoordinateArea();
  return proto.e.CoordinateArea.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.e.CoordinateArea} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.e.CoordinateArea}
 */


proto.e.CoordinateArea.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value =
        /** @type {number} */
        reader.readUint32();
        msg.setX(value);
        break;

      case 2:
        var value =
        /** @type {number} */
        reader.readUint32();
        msg.setY(value);
        break;

      case 3:
        var value =
        /** @type {number} */
        reader.readUint32();
        msg.setHeight(value);
        break;

      case 4:
        var value =
        /** @type {number} */
        reader.readUint32();
        msg.setWidth(value);
        break;

      case 5:
        var value = new proto.e.OnPress();
        reader.readMessage(value, proto.e.OnPress.deserializeBinaryFromReader);
        msg.setOnPress(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.e.CoordinateArea.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.e.CoordinateArea.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.e.CoordinateArea} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.e.CoordinateArea.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getX();

  if (f !== 0) {
    writer.writeUint32(1, f);
  }

  f = message.getY();

  if (f !== 0) {
    writer.writeUint32(2, f);
  }

  f = message.getHeight();

  if (f !== 0) {
    writer.writeUint32(3, f);
  }

  f = message.getWidth();

  if (f !== 0) {
    writer.writeUint32(4, f);
  }

  f = message.getOnPress();

  if (f != null) {
    writer.writeMessage(5, f, proto.e.OnPress.serializeBinaryToWriter);
  }
};
/**
 * optional uint32 x = 1;
 * @return {number}
 */


proto.e.CoordinateArea.prototype.getX = function () {
  return (
    /** @type {number} */
    jspb.Message.getFieldWithDefault(this, 1, 0)
  );
};
/**
 * @param {number} value
 * @return {!proto.e.CoordinateArea} returns this
 */


proto.e.CoordinateArea.prototype.setX = function (value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};
/**
 * optional uint32 y = 2;
 * @return {number}
 */


proto.e.CoordinateArea.prototype.getY = function () {
  return (
    /** @type {number} */
    jspb.Message.getFieldWithDefault(this, 2, 0)
  );
};
/**
 * @param {number} value
 * @return {!proto.e.CoordinateArea} returns this
 */


proto.e.CoordinateArea.prototype.setY = function (value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};
/**
 * optional uint32 height = 3;
 * @return {number}
 */


proto.e.CoordinateArea.prototype.getHeight = function () {
  return (
    /** @type {number} */
    jspb.Message.getFieldWithDefault(this, 3, 0)
  );
};
/**
 * @param {number} value
 * @return {!proto.e.CoordinateArea} returns this
 */


proto.e.CoordinateArea.prototype.setHeight = function (value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};
/**
 * optional uint32 width = 4;
 * @return {number}
 */


proto.e.CoordinateArea.prototype.getWidth = function () {
  return (
    /** @type {number} */
    jspb.Message.getFieldWithDefault(this, 4, 0)
  );
};
/**
 * @param {number} value
 * @return {!proto.e.CoordinateArea} returns this
 */


proto.e.CoordinateArea.prototype.setWidth = function (value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};
/**
 * optional OnPress on_press = 5;
 * @return {?proto.e.OnPress}
 */


proto.e.CoordinateArea.prototype.getOnPress = function () {
  return (
    /** @type{?proto.e.OnPress} */
    jspb.Message.getWrapperField(this, proto.e.OnPress, 5)
  );
};
/**
 * @param {?proto.e.OnPress|undefined} value
 * @return {!proto.e.CoordinateArea} returns this
*/


proto.e.CoordinateArea.prototype.setOnPress = function (value) {
  return jspb.Message.setWrapperField(this, 5, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.e.CoordinateArea} returns this
 */


proto.e.CoordinateArea.prototype.clearOnPress = function () {
  return this.setOnPress(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.e.CoordinateArea.prototype.hasOnPress = function () {
  return jspb.Message.getField(this, 5) != null;
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */


proto.e.ImageMap.repeatedFields_ = [3];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.e.ImageMap.prototype.toObject = function (opt_includeInstance) {
    return proto.e.ImageMap.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.e.ImageMap} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.e.ImageMap.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      src: jspb.Message.getFieldWithDefault(msg, 1, ""),
      alt: jspb.Message.getFieldWithDefault(msg, 2, ""),
      areasList: jspb.Message.toObjectList(msg.getAreasList(), proto.e.CoordinateArea.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.e.ImageMap}
 */


proto.e.ImageMap.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.e.ImageMap();
  return proto.e.ImageMap.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.e.ImageMap} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.e.ImageMap}
 */


proto.e.ImageMap.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setSrc(value);
        break;

      case 2:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setAlt(value);
        break;

      case 3:
        var value = new proto.e.CoordinateArea();
        reader.readMessage(value, proto.e.CoordinateArea.deserializeBinaryFromReader);
        msg.addAreas(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.e.ImageMap.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.e.ImageMap.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.e.ImageMap} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.e.ImageMap.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getSrc();

  if (f.length > 0) {
    writer.writeString(1, f);
  }

  f = message.getAlt();

  if (f.length > 0) {
    writer.writeString(2, f);
  }

  f = message.getAreasList();

  if (f.length > 0) {
    writer.writeRepeatedMessage(3, f, proto.e.CoordinateArea.serializeBinaryToWriter);
  }
};
/**
 * optional string src = 1;
 * @return {string}
 */


proto.e.ImageMap.prototype.getSrc = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 1, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.e.ImageMap} returns this
 */


proto.e.ImageMap.prototype.setSrc = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional string alt = 2;
 * @return {string}
 */


proto.e.ImageMap.prototype.getAlt = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 2, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.e.ImageMap} returns this
 */


proto.e.ImageMap.prototype.setAlt = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};
/**
 * repeated CoordinateArea areas = 3;
 * @return {!Array<!proto.e.CoordinateArea>}
 */


proto.e.ImageMap.prototype.getAreasList = function () {
  return (
    /** @type{!Array<!proto.e.CoordinateArea>} */
    jspb.Message.getRepeatedWrapperField(this, proto.e.CoordinateArea, 3)
  );
};
/**
 * @param {!Array<!proto.e.CoordinateArea>} value
 * @return {!proto.e.ImageMap} returns this
*/


proto.e.ImageMap.prototype.setAreasList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};
/**
 * @param {!proto.e.CoordinateArea=} opt_value
 * @param {number=} opt_index
 * @return {!proto.e.CoordinateArea}
 */


proto.e.ImageMap.prototype.addAreas = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.e.CoordinateArea, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.e.ImageMap} returns this
 */


proto.e.ImageMap.prototype.clearAreasList = function () {
  return this.setAreasList([]);
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */


proto.e.ImageMapVideo.repeatedFields_ = [3];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.e.ImageMapVideo.prototype.toObject = function (opt_includeInstance) {
    return proto.e.ImageMapVideo.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.e.ImageMapVideo} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.e.ImageMapVideo.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      src: jspb.Message.getFieldWithDefault(msg, 1, ""),
      poster: jspb.Message.getFieldWithDefault(msg, 2, ""),
      areasList: jspb.Message.toObjectList(msg.getAreasList(), proto.e.CoordinateArea.toObject, includeInstance)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.e.ImageMapVideo}
 */


proto.e.ImageMapVideo.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.e.ImageMapVideo();
  return proto.e.ImageMapVideo.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.e.ImageMapVideo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.e.ImageMapVideo}
 */


proto.e.ImageMapVideo.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setSrc(value);
        break;

      case 2:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setPoster(value);
        break;

      case 3:
        var value = new proto.e.CoordinateArea();
        reader.readMessage(value, proto.e.CoordinateArea.deserializeBinaryFromReader);
        msg.addAreas(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.e.ImageMapVideo.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.e.ImageMapVideo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.e.ImageMapVideo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.e.ImageMapVideo.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getSrc();

  if (f.length > 0) {
    writer.writeString(1, f);
  }

  f = message.getPoster();

  if (f.length > 0) {
    writer.writeString(2, f);
  }

  f = message.getAreasList();

  if (f.length > 0) {
    writer.writeRepeatedMessage(3, f, proto.e.CoordinateArea.serializeBinaryToWriter);
  }
};
/**
 * optional string src = 1;
 * @return {string}
 */


proto.e.ImageMapVideo.prototype.getSrc = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 1, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.e.ImageMapVideo} returns this
 */


proto.e.ImageMapVideo.prototype.setSrc = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional string poster = 2;
 * @return {string}
 */


proto.e.ImageMapVideo.prototype.getPoster = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 2, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.e.ImageMapVideo} returns this
 */


proto.e.ImageMapVideo.prototype.setPoster = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};
/**
 * repeated CoordinateArea areas = 3;
 * @return {!Array<!proto.e.CoordinateArea>}
 */


proto.e.ImageMapVideo.prototype.getAreasList = function () {
  return (
    /** @type{!Array<!proto.e.CoordinateArea>} */
    jspb.Message.getRepeatedWrapperField(this, proto.e.CoordinateArea, 3)
  );
};
/**
 * @param {!Array<!proto.e.CoordinateArea>} value
 * @return {!proto.e.ImageMapVideo} returns this
*/


proto.e.ImageMapVideo.prototype.setAreasList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};
/**
 * @param {!proto.e.CoordinateArea=} opt_value
 * @param {number=} opt_index
 * @return {!proto.e.CoordinateArea}
 */


proto.e.ImageMapVideo.prototype.addAreas = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.e.CoordinateArea, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.e.ImageMapVideo} returns this
 */


proto.e.ImageMapVideo.prototype.clearAreasList = function () {
  return this.setAreasList([]);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.e.Icon.prototype.toObject = function (opt_includeInstance) {
    return proto.e.Icon.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.e.Icon} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.e.Icon.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      src: jspb.Message.getFieldWithDefault(msg, 1, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.e.Icon}
 */


proto.e.Icon.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.e.Icon();
  return proto.e.Icon.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.e.Icon} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.e.Icon}
 */


proto.e.Icon.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setSrc(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.e.Icon.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.e.Icon.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.e.Icon} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.e.Icon.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getSrc();

  if (f.length > 0) {
    writer.writeString(1, f);
  }
};
/**
 * optional string src = 1;
 * @return {string}
 */


proto.e.Icon.prototype.getSrc = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 1, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.e.Icon} returns this
 */


proto.e.Icon.prototype.setSrc = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */


proto.e.OnPress.oneofGroups_ = [[2, 3, 4]];
/**
 * @enum {number}
 */

proto.e.OnPress.ActionCase = {
  ACTION_NOT_SET: 0,
  POSTBACK: 2,
  MESSAGE: 3,
  URI: 4
};
/**
 * @return {proto.e.OnPress.ActionCase}
 */

proto.e.OnPress.prototype.getActionCase = function () {
  return (
    /** @type {proto.e.OnPress.ActionCase} */
    jspb.Message.computeOneofCase(this, proto.e.OnPress.oneofGroups_[0])
  );
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.e.OnPress.prototype.toObject = function (opt_includeInstance) {
    return proto.e.OnPress.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.e.OnPress} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.e.OnPress.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      type: jspb.Message.getFieldWithDefault(msg, 1, 0),
      postback: (f = msg.getPostback()) && proto.e.OnPress.Postback.toObject(includeInstance, f),
      message: (f = msg.getMessage()) && proto.e.OnPress.Message.toObject(includeInstance, f),
      uri: (f = msg.getUri()) && proto.e.OnPress.Uri.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.e.OnPress}
 */


proto.e.OnPress.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.e.OnPress();
  return proto.e.OnPress.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.e.OnPress} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.e.OnPress}
 */


proto.e.OnPress.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value =
        /** @type {!proto.e.OnPressType} */
        reader.readEnum();
        msg.setType(value);
        break;

      case 2:
        var value = new proto.e.OnPress.Postback();
        reader.readMessage(value, proto.e.OnPress.Postback.deserializeBinaryFromReader);
        msg.setPostback(value);
        break;

      case 3:
        var value = new proto.e.OnPress.Message();
        reader.readMessage(value, proto.e.OnPress.Message.deserializeBinaryFromReader);
        msg.setMessage(value);
        break;

      case 4:
        var value = new proto.e.OnPress.Uri();
        reader.readMessage(value, proto.e.OnPress.Uri.deserializeBinaryFromReader);
        msg.setUri(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.e.OnPress.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.e.OnPress.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.e.OnPress} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.e.OnPress.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getType();

  if (f !== 0.0) {
    writer.writeEnum(1, f);
  }

  f = message.getPostback();

  if (f != null) {
    writer.writeMessage(2, f, proto.e.OnPress.Postback.serializeBinaryToWriter);
  }

  f = message.getMessage();

  if (f != null) {
    writer.writeMessage(3, f, proto.e.OnPress.Message.serializeBinaryToWriter);
  }

  f = message.getUri();

  if (f != null) {
    writer.writeMessage(4, f, proto.e.OnPress.Uri.serializeBinaryToWriter);
  }
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.e.OnPress.Postback.prototype.toObject = function (opt_includeInstance) {
    return proto.e.OnPress.Postback.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.e.OnPress.Postback} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.e.OnPress.Postback.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      paramsMap: (f = msg.getParamsMap()) ? f.toObject(includeInstance, undefined) : [],
      dataMap: (f = msg.getDataMap()) ? f.toObject(includeInstance, undefined) : []
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.e.OnPress.Postback}
 */


proto.e.OnPress.Postback.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.e.OnPress.Postback();
  return proto.e.OnPress.Postback.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.e.OnPress.Postback} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.e.OnPress.Postback}
 */


proto.e.OnPress.Postback.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value = msg.getParamsMap();
        reader.readMessage(value, function (message, reader) {
          jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
        });
        break;

      case 2:
        var value = msg.getDataMap();
        reader.readMessage(value, function (message, reader) {
          jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
        });
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.e.OnPress.Postback.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.e.OnPress.Postback.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.e.OnPress.Postback} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.e.OnPress.Postback.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getParamsMap(true);

  if (f && f.getLength() > 0) {
    f.serializeBinary(1, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }

  f = message.getDataMap(true);

  if (f && f.getLength() > 0) {
    f.serializeBinary(2, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
};
/**
 * map<string, string> params = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */


proto.e.OnPress.Postback.prototype.getParamsMap = function (opt_noLazyCreate) {
  return (
    /** @type {!jspb.Map<string,string>} */
    jspb.Message.getMapField(this, 1, opt_noLazyCreate, null)
  );
};
/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.e.OnPress.Postback} returns this
 */


proto.e.OnPress.Postback.prototype.clearParamsMap = function () {
  this.getParamsMap().clear();
  return this;
};
/**
 * map<string, string> data = 2;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */


proto.e.OnPress.Postback.prototype.getDataMap = function (opt_noLazyCreate) {
  return (
    /** @type {!jspb.Map<string,string>} */
    jspb.Message.getMapField(this, 2, opt_noLazyCreate, null)
  );
};
/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.e.OnPress.Postback} returns this
 */


proto.e.OnPress.Postback.prototype.clearDataMap = function () {
  this.getDataMap().clear();
  return this;
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.e.OnPress.Message.prototype.toObject = function (opt_includeInstance) {
    return proto.e.OnPress.Message.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.e.OnPress.Message} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.e.OnPress.Message.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      element: (f = msg.getElement()) && proto.e.Element.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.e.OnPress.Message}
 */


proto.e.OnPress.Message.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.e.OnPress.Message();
  return proto.e.OnPress.Message.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.e.OnPress.Message} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.e.OnPress.Message}
 */


proto.e.OnPress.Message.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value = new proto.e.Element();
        reader.readMessage(value, proto.e.Element.deserializeBinaryFromReader);
        msg.setElement(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.e.OnPress.Message.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.e.OnPress.Message.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.e.OnPress.Message} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.e.OnPress.Message.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getElement();

  if (f != null) {
    writer.writeMessage(1, f, proto.e.Element.serializeBinaryToWriter);
  }
};
/**
 * optional Element element = 1;
 * @return {?proto.e.Element}
 */


proto.e.OnPress.Message.prototype.getElement = function () {
  return (
    /** @type{?proto.e.Element} */
    jspb.Message.getWrapperField(this, proto.e.Element, 1)
  );
};
/**
 * @param {?proto.e.Element|undefined} value
 * @return {!proto.e.OnPress.Message} returns this
*/


proto.e.OnPress.Message.prototype.setElement = function (value) {
  return jspb.Message.setWrapperField(this, 1, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.e.OnPress.Message} returns this
 */


proto.e.OnPress.Message.prototype.clearElement = function () {
  return this.setElement(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.e.OnPress.Message.prototype.hasElement = function () {
  return jspb.Message.getField(this, 1) != null;
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.e.OnPress.Uri.prototype.toObject = function (opt_includeInstance) {
    return proto.e.OnPress.Uri.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.e.OnPress.Uri} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.e.OnPress.Uri.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      uri: jspb.Message.getFieldWithDefault(msg, 1, ""),
      dataMap: (f = msg.getDataMap()) ? f.toObject(includeInstance, undefined) : []
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.e.OnPress.Uri}
 */


proto.e.OnPress.Uri.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.e.OnPress.Uri();
  return proto.e.OnPress.Uri.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.e.OnPress.Uri} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.e.OnPress.Uri}
 */


proto.e.OnPress.Uri.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setUri(value);
        break;

      case 2:
        var value = msg.getDataMap();
        reader.readMessage(value, function (message, reader) {
          jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
        });
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.e.OnPress.Uri.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.e.OnPress.Uri.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.e.OnPress.Uri} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.e.OnPress.Uri.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getUri();

  if (f.length > 0) {
    writer.writeString(1, f);
  }

  f = message.getDataMap(true);

  if (f && f.getLength() > 0) {
    f.serializeBinary(2, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
};
/**
 * optional string uri = 1;
 * @return {string}
 */


proto.e.OnPress.Uri.prototype.getUri = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 1, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.e.OnPress.Uri} returns this
 */


proto.e.OnPress.Uri.prototype.setUri = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * map<string, string> data = 2;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */


proto.e.OnPress.Uri.prototype.getDataMap = function (opt_noLazyCreate) {
  return (
    /** @type {!jspb.Map<string,string>} */
    jspb.Message.getMapField(this, 2, opt_noLazyCreate, null)
  );
};
/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.e.OnPress.Uri} returns this
 */


proto.e.OnPress.Uri.prototype.clearDataMap = function () {
  this.getDataMap().clear();
  return this;
};
/**
 * optional OnPressType type = 1;
 * @return {!proto.e.OnPressType}
 */


proto.e.OnPress.prototype.getType = function () {
  return (
    /** @type {!proto.e.OnPressType} */
    jspb.Message.getFieldWithDefault(this, 1, 0)
  );
};
/**
 * @param {!proto.e.OnPressType} value
 * @return {!proto.e.OnPress} returns this
 */


proto.e.OnPress.prototype.setType = function (value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};
/**
 * optional Postback postback = 2;
 * @return {?proto.e.OnPress.Postback}
 */


proto.e.OnPress.prototype.getPostback = function () {
  return (
    /** @type{?proto.e.OnPress.Postback} */
    jspb.Message.getWrapperField(this, proto.e.OnPress.Postback, 2)
  );
};
/**
 * @param {?proto.e.OnPress.Postback|undefined} value
 * @return {!proto.e.OnPress} returns this
*/


proto.e.OnPress.prototype.setPostback = function (value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.e.OnPress.oneofGroups_[0], value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.e.OnPress} returns this
 */


proto.e.OnPress.prototype.clearPostback = function () {
  return this.setPostback(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.e.OnPress.prototype.hasPostback = function () {
  return jspb.Message.getField(this, 2) != null;
};
/**
 * optional Message message = 3;
 * @return {?proto.e.OnPress.Message}
 */


proto.e.OnPress.prototype.getMessage = function () {
  return (
    /** @type{?proto.e.OnPress.Message} */
    jspb.Message.getWrapperField(this, proto.e.OnPress.Message, 3)
  );
};
/**
 * @param {?proto.e.OnPress.Message|undefined} value
 * @return {!proto.e.OnPress} returns this
*/


proto.e.OnPress.prototype.setMessage = function (value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.e.OnPress.oneofGroups_[0], value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.e.OnPress} returns this
 */


proto.e.OnPress.prototype.clearMessage = function () {
  return this.setMessage(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.e.OnPress.prototype.hasMessage = function () {
  return jspb.Message.getField(this, 3) != null;
};
/**
 * optional Uri uri = 4;
 * @return {?proto.e.OnPress.Uri}
 */


proto.e.OnPress.prototype.getUri = function () {
  return (
    /** @type{?proto.e.OnPress.Uri} */
    jspb.Message.getWrapperField(this, proto.e.OnPress.Uri, 4)
  );
};
/**
 * @param {?proto.e.OnPress.Uri|undefined} value
 * @return {!proto.e.OnPress} returns this
*/


proto.e.OnPress.prototype.setUri = function (value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.e.OnPress.oneofGroups_[0], value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.e.OnPress} returns this
 */


proto.e.OnPress.prototype.clearUri = function () {
  return this.setUri(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.e.OnPress.prototype.hasUri = function () {
  return jspb.Message.getField(this, 4) != null;
};
/**
 * @enum {number}
 */


proto.e.ElementType = {
  ELEMENT_TYPE_UNSPECIFIED: 0,
  ELEMENT_TYPE_DIV: 1,
  ELEMENT_TYPE_SPAN: 2,
  ELEMENT_TYPE_IMAGE: 3,
  ELEMENT_TYPE_BUTTON: 4,
  ELEMENT_TYPE_AUDIO: 5,
  ELEMENT_TYPE_VIDEO: 6,
  ELEMENT_TYPE_HR: 7,
  ELEMENT_TYPE_CHIP: 8,
  ELEMENT_TYPE_IMAGE_MAP: 9,
  ELEMENT_TYPE_IMAGE_MAP_VIDEO: 10,
  ELEMENT_TYPE_ICON: 11,
  ELEMENT_TYPE_SPACER: 12,
  ELEMENT_TYPE_FILLER: 13
};
/**
 * @enum {number}
 */

proto.e.OnPressType = {
  ON_PRESS_TYPE_UNSPECIFIED: 0,
  ON_PRESS_TYPE_POSTBACK: 1,
  ON_PRESS_TYPE_MESSAGE: 2,
  ON_PRESS_TYPE_URI: 3
};
goog.object.extend(exports, proto.e);