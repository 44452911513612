var modalTypes = {
    DELETE: 'DELETE',
    SELECT: 'SELECT',
    INPUT: 'INPUT',
    JSON: 'JSON',
    DELIVERY: 'DELIVERY',
    ERROR: 'ERROR',
    TEST: 'TEST',
    CUSTOM: 'CUSTOM',
    ARCHIVE: 'ARCHIVE',
    NONE: 'NONE',
    CONFIRM: 'CONFIRM',
    EDIT: 'EDIT',
    CREATE: 'CREATE',
    SEND_MESSAGE: 'SEND_MESSAGE',
    DUPLICATE: 'DUPLICATE',
    INFO: 'INFO',
    UPDATE: 'UPDATE',
};
export default modalTypes;
