import React from 'react';
import useModal from '@src/components/hooks/useModal';
import ModalPortal from '../atoms/ModalPortal';
import Modal from './Modal';
var AlertModalContext = React.createContext({
    modal: null,
    handleModal: null,
    modalContent: null,
});
var Provider = AlertModalContext.Provider;
function AlertModalProvider(_a) {
    var children = _a.children;
    var _b = useModal(), modal = _b.modal, handleModal = _b.handleModal, modalContent = _b.modalContent;
    return (React.createElement(Provider, { value: { modal: modal, handleModal: handleModal, modalContent: modalContent } },
        modal && (React.createElement(ModalPortal, null,
            React.createElement(Modal, { onCancel: function () { return handleModal(''); }, "data-testid": "alert-modal" }, modalContent))),
        children));
}
export { AlertModalContext, AlertModalProvider };
