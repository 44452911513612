var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import { MembersList } from '@src/components/organisms/fanp';
import { colors } from '@src/colors';
var MembersContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  height: 100%;\n  background: ", ";\n  padding: 2.5rem;\n  min-height: 80vh;\n  background: ", ";\n  margin: 0;\n  flash {\n    display: inherit;\n    text-align: center;\n    color: ", ";\n  }\n  .zeals_flame {\n    position: fixed;\n    bottom: 0;\n    left: 0;\n    right: 0;\n    text-align: center;\n    height: 100px;\n    background: rgba(#fff, 0.95);\n    &:before {\n      content: '';\n      display: inline-block;\n      margin: -4rem auto 0;\n      background-image: url(", ");\n      background-size: contain;\n      background-repeat: no-repeat;\n      width: 120px;\n      height: 126px;\n    }\n  }\n"], ["\n  height: 100%;\n  background: ", ";\n  padding: 2.5rem;\n  min-height: 80vh;\n  background: ", ";\n  margin: 0;\n  flash {\n    display: inherit;\n    text-align: center;\n    color: ", ";\n  }\n  .zeals_flame {\n    position: fixed;\n    bottom: 0;\n    left: 0;\n    right: 0;\n    text-align: center;\n    height: 100px;\n    background: rgba(#fff, 0.95);\n    &:before {\n      content: '';\n      display: inline-block;\n      margin: -4rem auto 0;\n      background-image: url(", ");\n      background-size: contain;\n      background-repeat: no-repeat;\n      width: 120px;\n      height: 126px;\n    }\n  }\n"])), colors.background.off_white_background, colors.background.off_white_background, colors.error, require('@src/assets/images/flame.png'));
function Members() {
    return (React.createElement(MembersContainer, null,
        React.createElement(MembersList, null),
        React.createElement("div", { className: "zeals_flame" })));
}
export default Members;
var templateObject_1;
