export var sizes = {
    button: {
        content: 'fit-content',
        circle: 40,
        extra_small: '80',
        small: '120',
        normal: '160',
        medium: '240',
        large: '320',
        extra_large: '500',
        default: '',
    },
};
