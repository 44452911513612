import _extends from "@babel/runtime/helpers/extends";
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
var margins = {
  none: 'none',
  xs: '12px',
  sm: '4px',
  md: '8px',
  lg: '22px',
  xl: '22px',
  xxl: '22px'
};
var Wrapper = styled.div.withConfig({
  displayName: "Separator__Wrapper",
  componentId: "sc-11i7ny0-0"
})(["border:0 solid ", ";margin-top:", " !important;"], function (props) {
  return props.color || '#D4D6DA';
}, function (props) {
  return margins[props.margin];
});

var Separator = function Separator(props) {
  return React.createElement(Wrapper, _extends({
    className: "component separator"
  }, props));
};

Separator.propTypes = {
  content: PropTypes.shape({
    margin: PropTypes.string,
    color: PropTypes.string
  })
};
export default Separator;