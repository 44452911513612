import { select } from 'd3';
import { colors } from '@src/colors';
import wrap from './wrap';
import { MSG_PADDING } from './constants';
export function writeWrappedText(text, x, y) {
  return select(this.block).append('text').on('mousedown', function (event) {
    event.stopPropagation();
  }).classed('messageText', true).text(function () {
    return text;
  }).attr('fill', colors.default_text).attr('x', this.getContentX(x)).attr('y', this.getContentY(y)).call(wrap, this.getWidth() - MSG_PADDING * 2);
}