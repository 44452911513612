var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState } from 'react';
import styled from 'styled-components';
import { colors, shadows } from '@src/colors';
import { MessageSelector } from '@src/components/atoms';
import { isAllowAddMessage, messageSectionsInBlockCount, } from '@src/lib/helpers';
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 0;\n  height: 20px;\n  position: relative;\n  margin: 10px 0 10px 0;\n\n  > span {\n    position: absolute;\n    padding: 10px;\n    background: ", ";\n    border-radius: 10px;\n    color: ", ";\n    transition: 0.3s;\n    &:hover {\n      cursor: ", ";\n      box-shadow: ", ";\n    }\n\n    &::after {\n      content: '';\n      position: absolute;\n      right: 0;\n      top: 100%;\n      border: 7px solid;\n      border-top-color: transparent;\n      border-right-color: ", ";\n      border-left: 0;\n      margin-top: -26px;\n      margin-right: -7px;\n      transform: rotate(180deg);\n    }\n  }\n"], ["\n  width: 0;\n  height: 20px;\n  position: relative;\n  margin: 10px 0 10px 0;\n\n  > span {\n    position: absolute;\n    padding: 10px;\n    background: ",
    ";\n    border-radius: 10px;\n    color: ", ";\n    transition: 0.3s;\n    &:hover {\n      cursor: ", ";\n      box-shadow: ", ";\n    }\n\n    &::after {\n      content: '';\n      position: absolute;\n      right: 0;\n      top: 100%;\n      border: 7px solid;\n      border-top-color: transparent;\n      border-right-color: ",
    ";\n      border-left: 0;\n      margin-top: -26px;\n      margin-right: -7px;\n      transform: rotate(180deg);\n    }\n  }\n"])), function (props) {
    return props.readonly ? colors.readonly : colors.main_color;
}, colors.white, function (props) { return (props.readonly ? 'auto' : 'pointer'); }, function (props) { return (props.readonly ? '' : shadows.medium.dark); }, function (props) {
    return props.readonly ? colors.readonly : colors.main_color;
});
var MessageInsert = function (_a) {
    var platform = _a.platform, activeBlock = _a.activeBlock, handleMessageInsertFlex = _a.handleMessageInsertFlex, handleMessageInsertImageMap = _a.handleMessageInsertImageMap, message = _a.message, isTop = _a.isTop, nextMessageId = _a.nextMessageId, rank = _a.rank, insertMessageHandler = _a.insertMessageHandler, chatbotIdParam = _a.chatbotIdParam, adScenarioIdParam = _a.adScenarioIdParam;
    var _b = __read(useState(false), 2), isMessageSelectorOpened = _b[0], setIsMessageSelectorOpened = _b[1];
    var openMessageSelector = function (isOpened, e) {
        if (e && e.target !== e.currentTarget)
            return;
        setIsMessageSelectorOpened(isOpened);
    };
    var handleMessageInsert = function (insertMessageType, media_type) {
        if (media_type === void 0) { media_type = null; }
        openMessageSelector(false);
        insertMessageHandler({
            chatbotId: chatbotIdParam,
            scenarioId: adScenarioIdParam,
            messageId: nextMessageId || message.id,
            prevMessageId: isTop ? null : message.id,
            message_type: insertMessageType,
            media_type: media_type,
            rank: rank,
        });
    };
    var messagesLength = messageSectionsInBlockCount(activeBlock);
    var readonly = !isAllowAddMessage(messagesLength, platform);
    return (React.createElement("div", null,
        React.createElement(Container, { readonly: readonly },
            React.createElement("span", { "data-testid": "message-insert-add-button", className: "adicon_plus02", onClick: function (e) {
                    return readonly ? null : openMessageSelector(!isMessageSelectorOpened, e);
                }, role: "button", tabIndex: 0 }, isMessageSelectorOpened && (React.createElement(MessageSelector, { "data-testid": "message-insert-message-selector", platform: platform, onClickOutside: function () { return setIsMessageSelectorOpened(false); }, handleMessageInsert: handleMessageInsert, handleMessageInsertFlex: function () {
                    return handleMessageInsertFlex(message, isTop);
                }, handleMessageInsertImageMap: function () {
                    return handleMessageInsertImageMap(message, isTop);
                } }))))));
};
export default MessageInsert;
var templateObject_1;
