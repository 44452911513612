import { object, string } from 'yup';
import { tabTypes } from '@src/lib/app-constants';
import imageMapAreaSchema from './imageMapAreaSchema';
import imageMapVideoSchema from './imageMapVideoSchema';
/**
 * This schema for imageMapAreaModal, it will return errors object to
 * use for validate on this modal
 * this errors object will contain activeTab, imageMapArea, imageMapVide
 *
 * @param imageMapAreaId - id of image map area
 * @param imageMapAreaArr - all area of image map
 */
export default (function (imageMapAreaId, imageMapAreaArr) { return function (intl, platform, context) {
    return object({
        activeTab: string().required(),
        imageMapArea: object()
            .nullable()
            .when(['activeTab'], function (activeTab, schema) {
            return [tabTypes.AREA_SETTING, tabTypes.AREA_SETTING_WITH_USER_TAG].includes(activeTab)
                ? imageMapAreaSchema(imageMapAreaId, imageMapAreaArr, intl, platform, context, activeTab)
                : schema;
        }),
        imageMapVideo: object()
            .nullable()
            .when(['activeTab'], function (activeTab, schema) {
            return activeTab !== tabTypes.VIDEO_SETTING
                ? schema
                : imageMapVideoSchema(intl, platform);
        }),
    });
}; });
