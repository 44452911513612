var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { PureComponent } from 'react';
import { injectIntl } from 'react-intl';
import styled from 'styled-components';
import { colors } from '@src/colors';
import { Button, NotificationMessage } from '@src/components/atoms';
import buttonTypes from '@src/lib/buttonTypes';
var SettingsSectionCodeArea = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  min-width: 990px;\n  padding: 3.5rem;\n  background-color: ", ";\n  border-radius: 8px;\n  border: 1px solid ", ";\n  text-align: center;\n\n  .explain_text {\n    font-size: 1.25rem;\n    margin: 0 0 3.5rem;\n  }\n\n  .supplementary {\n    font-size: 14px;\n    color: ", ";\n    margin: 40px 0 0;\n  }\n"], ["\n  min-width: 990px;\n  padding: 3.5rem;\n  background-color: ", ";\n  border-radius: 8px;\n  border: 1px solid ", ";\n  text-align: center;\n\n  .explain_text {\n    font-size: 1.25rem;\n    margin: 0 0 3.5rem;\n  }\n\n  .supplementary {\n    font-size: 14px;\n    color: ", ";\n    margin: 40px 0 0;\n  }\n"])), colors.white, colors.border_setting_section, colors.gray_light);
var TotalCodeTextAreaBox = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 490px;\n  height: 300px;\n  border: 1px solid ", ";\n  border-radius: 10px;\n  padding: 1rem;\n  margin: 0 auto 1rem;\n\n  textarea {\n    width: 100%;\n    height: 100%;\n    font-size: 14px;\n    border: none;\n  }\n"], ["\n  width: 490px;\n  height: 300px;\n  border: 1px solid ", ";\n  border-radius: 10px;\n  padding: 1rem;\n  margin: 0 auto 1rem;\n\n  textarea {\n    width: 100%;\n    height: 100%;\n    font-size: 14px;\n    border: none;\n  }\n"])), colors.border.default);
var SettingsAggregate = /** @class */ (function (_super) {
    __extends(SettingsAggregate, _super);
    function SettingsAggregate(props) {
        var _this = _super.call(this, props) || this;
        _this.updateClipboard = function (e) {
            _this.setState({ clipboard: e.target.value });
        };
        _this.state = {
            clipboard: "<script src=\"https://fanp.me/plugin/fanp_aggregate.js\"></script>",
        };
        return _this;
    }
    SettingsAggregate.prototype.render = function () {
        var _a = this.props, copyCodeToClipboard = _a.copyCodeToClipboard, isCodeCopyNotificationVisible = _a.isCodeCopyNotificationVisible, intl = _a.intl;
        var clipboard = this.state.clipboard;
        var itemName = intl.formatMessage({ id: "9vh17O", defaultMessage: "Introductory Plugin" });
        var copyNotificationMsg = intl.formatMessage({ id: "ixChTw", defaultMessage: "The {copiedItem} was copied." }, { copiedItem: itemName });
        return (React.createElement("div", { className: "setting-area" },
            isCodeCopyNotificationVisible && (React.createElement(NotificationMessage, { className: "code-copy-notification", intlMessageId: "settings.INTRO_PLUGIN", iconName: "adicon_safe" }, copyNotificationMsg)),
            React.createElement(SettingsSectionCodeArea, null,
                React.createElement("p", { className: "explain_text" },
                    "\u4EE5\u4E0B\u306E\u30B3\u30FC\u30C9\u3092\u3001\u30A6\u30A7\u30D6\u30B5\u30A4\u30C8\u5185\u306E\u4F1A\u54E1\u767B\u9332\u30DC\u30BF\u30F3\u3084\u5FDC\u52DF\u30DC\u30BF\u30F3\u306B\u306A\u3069\u57CB\u3081\u8FBC\u3080\u3068\u3001",
                    React.createElement("br", null),
                    "Messenger\u7D4C\u7531\u3067\u305D\u306E\u30DC\u30BF\u30F3\u3092\u62BC\u3057\u305F\u30E6\u30FC\u30B6\u30FC\u3092\u96C6\u8A08\u3059\u308B\u3053\u3068\u304C\u3067\u304D\u307E\u3059\u3002"),
                React.createElement(TotalCodeTextAreaBox, null,
                    React.createElement("textarea", { value: clipboard, onChange: this.updateClipboard })),
                React.createElement(Button, { width: 170, buttonType: buttonTypes.SETTING, text: "HTML\u3092\u30B3\u30D4\u30FC\u3059\u308B", onClick: function () { return copyCodeToClipboard(clipboard); }, margin: "0 auto" }),
                React.createElement("p", { className: "supplementary" },
                    "\u4E0A\u8A18\u306E\u4ED6\u306B\u3001\u30E1\u30FC\u30EB\u914D\u4FE1\u5F8C\u306EThanks\u30DA\u30FC\u30B8\u306A\u3069\u300C\u30B3\u30F3\u30D0\u30FC\u30B8\u30E7\u30F3\u300D\u3068\u3057\u3066\u96C6\u8A08\u3057\u305F\u3044\u30DA\u30FC\u30B8\u306B\u57CB\u3081\u8FBC\u3080\u3053\u3068\u3082\u3067\u304D\u307E\u3059\u3002",
                    React.createElement("br", null),
                    "\u96C6\u8A08\u7D50\u679C\u306F\u300C\u30EC\u30DD\u30FC\u30C8\u300D\u306E\u30C1\u30E3\u30C3\u30C8\u9805\u76EE\u306E\u30DA\u30FC\u30B8\u4E0B\u90E8\u306B\u3042\u308B\u300C\u30B3\u30F3\u30D0\u30FC\u30B8\u30E7\u30F3\u300D\u3068\u3044\u3046\u9805\u76EE\u3067\u78BA\u8A8D\u3044\u305F\u3060\u3051\u307E\u3059\u3002"))));
    };
    return SettingsAggregate;
}(PureComponent));
export default injectIntl(SettingsAggregate);
var templateObject_1, templateObject_2;
