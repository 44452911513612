var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import buttonTypes from '@src/lib/buttonTypes';
import { Button } from '@src/components/atoms';
import { injectIntl } from 'react-intl';
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  user-select: none;\n  display: inline-flex;\n  float: ", ";\n  width: fit-content;\n  margin: ", ";\n  > button:not(:first-child) {\n    margin-left: 1rem;\n  }\n"], ["\n  user-select: none;\n  display: inline-flex;\n  float: ", ";\n  width: fit-content;\n  margin: ", ";\n  > button:not(:first-child) {\n    margin-left: 1rem;\n  }\n"])), function (props) { return props.float || 'right'; }, function (props) { return props.margin || '2.5rem 0 0'; });
/*
  buttonProps & button2Props: { // cancel button & confirm button
    text: cancel text
    type: type of button: 'SAVE', // green/blue gradient bg, 'NONE', // no bg, 'DISABLED', // grey bg, 'DELETE', // red bg
  }
  buttonsWidth: button set width,
  onClick: click function for the confirm button,
  onCancel: click function for the cancel button,
  preventOnCancel: prevents cancelFn being run for confirm,
*/
var ButtonSet = function (_a) {
    var buttonProps = _a.buttonProps, button2Props = _a.button2Props, buttonsWidth = _a.buttonsWidth, buttonsHeight = _a.buttonsHeight, onConfirm = _a.onConfirm, onCancel = _a.onCancel, hasError = _a.hasError, preventOnCancel = _a.preventOnCancel;
    return (React.createElement(Container, { "data-testid": "ButtonSet" },
        buttonProps ? (React.createElement(Button, { dataTest: "ButtonCancel", buttonType: buttonProps.type, text: buttonProps.text, width: buttonsWidth, height: buttonsHeight, onClick: onCancel, className: "button-set-btn-cancel" })) : null,
        button2Props ? (React.createElement(Button, { dataTest: "ButtonConfirm", buttonType: hasError ? buttonTypes.DISABLED : button2Props.type, text: button2Props.text, width: buttonsWidth, height: buttonsHeight, onClick: function () {
                onConfirm();
                if (!preventOnCancel) {
                    onCancel();
                }
            }, hasError: hasError, className: "button-set-btn-confirm" })) : null));
};
export default injectIntl(ButtonSet);
var templateObject_1;
