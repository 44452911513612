import React from 'react';
import PropTypes from 'prop-types';
import HideableContainer from '@src/components/molecules/HideableContainer';
import InputSearchBox from './InputSearchBox';
/*
  Resource search creates a search box
  Pagination requires that you pass the total_items 
  (not the paginated results, but the amount in the DB)
*/

var ResourceSearch = function ResourceSearch(_ref) {
  var resourceName = _ref.resourceName,
      resourcesTotalItems = _ref.resourcesTotalItems,
      resourcesActivePage = _ref.resourcesActivePage,
      activeResourceId = _ref.activeResourceId,
      resourceSearchTerm = _ref.resourceSearchTerm,
      handleResourceSearchChange = _ref.handleResourceSearchChange,
      resourceSearchIsHidden = _ref.resourceSearchIsHidden,
      updateResourceIsHidden = _ref.updateResourceIsHidden,
      searchResource = _ref.searchResource,
      children = _ref.children;
  return React.createElement(HideableContainer, {
    activeResourceId: activeResourceId,
    isHidden: resourceSearchIsHidden,
    updateIsHidden: updateResourceIsHidden,
    title: activeResourceId === null ? "".concat(resourceName, "\u9078\u629E") : "".concat(resourceName, " OK!"),
    inlineBlock: true
  }, React.createElement(InputSearchBox, {
    totalItems: resourcesTotalItems,
    activePage: resourcesActivePage,
    placeholder: "".concat(resourceName, "ID\u30FB\u540D\u3092\u691C\u7D22"),
    searchTerm: resourceSearchTerm,
    searchTermChangeFunc: handleResourceSearchChange,
    searchFunc: searchResource,
    totalPages: Math.floor(resourcesTotalItems / 20)
  }, children));
};

ResourceSearch.propTypes = {
  resourceName: PropTypes.string,
  // The name you want to display
  resourcesTotalItems: PropTypes.number,
  // The amount in the DB
  resourcesActivePage: PropTypes.number,
  // The current page for pagination
  activeResourceId: PropTypes.number,
  // Currently selected in list
  resourceSearchTerm: PropTypes.string,
  // The searchbox val
  resourceSearchIsHidden: PropTypes.bool,
  // Opened/closed
  handleResourceSearchChange: PropTypes.func,
  // Func to open/close
  searchResource: PropTypes.func,
  // The func run when you click search
  updateResourceIsHidden: PropTypes.func // Opened/closed func

};
export default ResourceSearch;