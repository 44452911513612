import _defineProperty from "@babel/runtime/helpers/defineProperty";

var _CAN_SEND, _MEDIA_CAN_SEND, _TALK_CAN_SEND;

import { mediaTypes, messageTypes } from '@src/lib/app-constants';
export default {
  MAX_TEXT_BUTTON_AMOUNT: 3,
  MAX_CAROUSEL_BUTTON_AMOUNT: 3,
  MAX_DEFAULT_REPLY_TEXT_LENGTH: 2000,
  MAX_CAROUSEL_ITEM: 10,
  MAX_QUICK_REPLY_CHOICE: 11,
  CAN_SEND: (_CAN_SEND = {}, _defineProperty(_CAN_SEND, messageTypes.TEXT, true), _defineProperty(_CAN_SEND, messageTypes.GENERIC, true), _defineProperty(_CAN_SEND, messageTypes.RICH_MENU, false), _defineProperty(_CAN_SEND, messageTypes.IMAGE_MAP, false), _defineProperty(_CAN_SEND, messageTypes.QUICK_REPLY, true), _defineProperty(_CAN_SEND, messageTypes.MEDIA, true), _defineProperty(_CAN_SEND, messageTypes.LIST, false), _defineProperty(_CAN_SEND, messageTypes.FREE_INPUT, true), _CAN_SEND),
  CAN_PHONE_NUMBER: false,
  CAN_SHOW_MESSAGE_USAGE: false,
  CAN_REQUEST_QUOTA_API: false,
  CAN_EMAIL: false,
  MEDIA_CAN_SEND: (_MEDIA_CAN_SEND = {}, _defineProperty(_MEDIA_CAN_SEND, mediaTypes.AUDIO, false), _defineProperty(_MEDIA_CAN_SEND, mediaTypes.VIDEO, false), _defineProperty(_MEDIA_CAN_SEND, mediaTypes.IMAGE, true), _defineProperty(_MEDIA_CAN_SEND, mediaTypes.FILE, false), _MEDIA_CAN_SEND),
  TALK_CAN_SEND: (_TALK_CAN_SEND = {}, _defineProperty(_TALK_CAN_SEND, messageTypes.TEXT, true), _defineProperty(_TALK_CAN_SEND, messageTypes.GENERIC, true), _defineProperty(_TALK_CAN_SEND, messageTypes.MEDIA, false), _defineProperty(_TALK_CAN_SEND, messageTypes.QUICK_REPLY, true), _defineProperty(_TALK_CAN_SEND, messageTypes.BUTTON_TEMPLATE, true), _defineProperty(_TALK_CAN_SEND, "SCENARIO", false), _TALK_CAN_SEND),
  // https://github.com/zeals-co-ltd/jupiter/issues/11093
  TALK_DISABLE_SENDING_MESSAGES_AFTER_MS: 24 * 60 * 60 * 1000,
  MIN_AMOUNT_OF_MESSAGES: 1,
  MIN_AMOUNT_OF_QUICK_REPLY_CHOICES: 1,
  MESSAGE_SECTION_ENABLED: true,
  INITIAL_CONVERSATION_ENABLED: true,
  FORM_CONVERSATION_ENABLED: true,
  PATTERN_CONVERSATION_ENABLED: true,
  SHOT_CONVERSATION_ENABLED: true,
  TRACK_PUSH_V1_ENABLED: true,
  TRACK_PUSH_V2_ENABLED: true,
  USER_DB_ENABLED: true,
  TALKROOM_ENABLED: true,
  FIELD_ATTRIBUTES: true,
  USER_SEGMENT: true,
  USER_LABEL: true,
  CHATLINKS_ENABLED: true,
  SETTINGS_WELCOME_BOARD_ENABLED: true,
  SETTINGS_DEFAULT_MESSAGE_ENABLED: true,
  SETTINGS_DEFAULT_RICH_MENU: false,
  PRIVACY_POLICY_ENABLED: true,
  AGGREGATION_CODE_ENABLED: true
};