var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState } from 'react';
import styled from 'styled-components';
import modalTypes from '@src/lib/modalTypes';
import { JSONDisplayer, ModalPortal } from '@src/components/atoms';
import { Modal } from '@src/components/molecules';
import { colors } from '@src/colors';
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  .placeholder-message-inner {\n    width: 260px;\n    padding: 16px;\n    margin: 40px auto auto 24px;\n    border-radius: 16px;\n    border: 1px solid ", ";\n    cursor: pointer;\n    transition: 0.3s;\n\n    .hovered {\n      display: none;\n    }\n    &:hover {\n      .hovered {\n        display: block;\n      }\n      .unhovered {\n        display: none;\n      }\n    }\n  }\n"], ["\n  .placeholder-message-inner {\n    width: 260px;\n    padding: 16px;\n    margin: 40px auto auto 24px;\n    border-radius: 16px;\n    border: 1px solid ", ";\n    cursor: pointer;\n    transition: 0.3s;\n\n    .hovered {\n      display: none;\n    }\n    &:hover {\n      .hovered {\n        display: block;\n      }\n      .unhovered {\n        display: none;\n      }\n    }\n  }\n"])), colors.border.default);
var PlaceholderMessage = function (_a) {
    var message = _a.message, children = _a.children;
    var _b = __read(useState(false), 2), isModalOpen = _b[0], updateIsModalOpen = _b[1];
    var toggleIsModalOpen = function () { return updateIsModalOpen(!isModalOpen); };
    return (React.createElement(Container, null,
        React.createElement("div", { className: "placeholder-message-inner", onClick: toggleIsModalOpen },
            isModalOpen && (React.createElement(ModalPortal, null,
                React.createElement(Modal, { "data-testid": "placeholder-message-modal", type: modalTypes.NONE, onCancel: toggleIsModalOpen },
                    React.createElement(JSONDisplayer, { data: message, rows: "15" })))),
            React.createElement("p", { className: "unhovered" }, "\u73FE\u5728\u3053\u306E\u30E1\u30C3\u30BB\u30FC\u30B8\u306E\u7A2E\u985E\u306F\u5BFE\u5FDC\u3057\u3066\u3044\u306A\u3044\u3067\u3059\u3002"),
            React.createElement("p", { className: "hovered" }, "\u30E1\u30C3\u30BB\u30FC\u30B8\u306EJSON\u3092\u8868\u793A\u3059\u308B")),
        children));
};
export default PlaceholderMessage;
var templateObject_1;
