import { FormElementType, } from 'zeals-protobuf/zeals/web/entities/form_pb';
import { DataType, InputType, FieldCollectionType, } from 'zeals-protobuf/zeals/web/entities/user_dossier_pb';
import { v4 as uuid } from 'uuid';
export function dataTypeToString(dataType) {
    switch (dataType) {
        case DataType.DATA_TYPE_STRING: // 1
            return 'テキスト (String)';
        case DataType.DATA_TYPE_INTEGER: // 2
            return '数字 (Number e.g. 1)';
        case DataType.DATA_TYPE_DATETIME: // 3
            return '期間 (DatePicker)';
        case DataType.DATA_TYPE_DOUBLE: // 4
            return '数字 (Number e.g. 1.1)';
        case DataType.DATA_TYPE_BOOLEAN: // 5
            return '選択 (Boolean)';
        case DataType.DATA_TYPE_ENCRYPTED: // 6
            return '暗号化 (Encrypted)';
        case DataType.DATA_TYPE_UNSPECIFIED: // 0
            // TODO: is this correct?
            return 'Unspecified';
        default:
            return '';
    }
}
function fieldDataTypeFromElementDataType(type) {
    switch (type) {
        case FormElementType.FORM_ELEMENT_TYPE_TEXT:
            return DataType.DATA_TYPE_STRING;
        case FormElementType.FORM_ELEMENT_TYPE_NUMERIC:
            return DataType.DATA_TYPE_INTEGER;
        case FormElementType.FORM_ELEMENT_TYPE_SELECT:
            return DataType.DATA_TYPE_BOOLEAN;
        case FormElementType.FORM_ELEMENT_TYPE_CREDIT_CARD:
            return DataType.DATA_TYPE_ENCRYPTED;
        case FormElementType.FORM_ELEMENT_TYPE_DATE_PICKER:
            return DataType.DATA_TYPE_DATETIME;
        case FormElementType.FORM_ELEMENT_TYPE_DATETIME_PICKER:
            return DataType.DATA_TYPE_DATETIME;
        case FormElementType.FORM_ELEMENT_TYPE_TIME_PICKER:
            return DataType.DATA_TYPE_DATETIME;
        case FormElementType.FORM_ELEMENT_TYPE_CALENDAR:
            return DataType.DATA_TYPE_DATETIME;
        default:
            return DataType.DATA_TYPE_UNSPECIFIED;
    }
}
// ID is non-deterministic
// TODO: update FormTemplate to FormTemplate.AsObject
export function fieldsFromFormTemplate(formElements, chatbotId) {
    return formElements.map(function (element) { return ({
        id: uuid(),
        name: element.name,
        dataType: fieldDataTypeFromElementDataType(element.type),
        chatbotId: chatbotId,
        inputType: InputType.INPUT_TYPE_UNSPECIFIED,
        fieldCollectionType: FieldCollectionType.FIELD_COLLECTION_TYPE_UNSPECIFIED,
    }); });
}
export var PROFILE_FIELD_COLLECTION_NAME = 'Profile';
export function findProfileFieldCollection(fieldCollectionsList) {
    return fieldCollectionsList.find(function (fieldCollection) {
        return fieldCollection.name === PROFILE_FIELD_COLLECTION_NAME &&
            fieldCollection.type === FieldCollectionType.FIELD_COLLECTION_TYPE_UNIQUE;
    });
}
