var collection = function (headlessConfigId) {
    return "/zeals_admin/api/headless_config/" + headlessConfigId + "/headless_error_checks";
};
var member = function (headlessConfigId, id) {
    return collection(headlessConfigId) + "/" + id;
};
export default {
    collection: collection,
    member: member,
};
