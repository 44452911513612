import "core-js/modules/es.array.concat";

var collection = function collection(chatbotId) {
  return "/chatbots/".concat(chatbotId, "/message_sections/filters");
};

var member = function member(chatbotId, id) {
  return "/chatbots/".concat(chatbotId, "/message_sections/filters/").concat(id);
};

export default {
  collection: collection,
  member: member
};