import React from 'react';
import { ModalPortal, Input } from '@src/components/atoms';
import Modal from '@src/components/molecules/Modal';
import modalTypes from '@src/lib/modalTypes';
import { injectIntl } from 'react-intl';
var EditScenarioName = function (_a) {
    var onCancel = _a.onCancel, onConfirm = _a.onConfirm, onChange = _a.onChange, _b = _a.value, value = _b === void 0 ? 'ScenarioName' : _b, intl = _a.intl;
    return (React.createElement(ModalPortal, null,
        React.createElement(Modal, { "data-testid": "edit-scenario-modal", text: intl.formatMessage({ id: "MxyEJI", defaultMessage: "Edit Scenario Name" }), type: modalTypes.SELECT, onConfirm: onConfirm, onCancel: onCancel },
            React.createElement("div", { className: "message-wrapper primary-label" }, intl.formatMessage({ id: "R+U9l6", defaultMessage: "Please enter a Scenario Name" })),
            React.createElement(Input, { dataTest: "scenario-name-input", type: "text", placeholder: intl.formatMessage({ id: "Seanpx", defaultMessage: "Required" }), onChange: onChange, value: value }))));
};
export default injectIntl(EditScenarioName);
