import "core-js/modules/es.array.map";
import React from 'react';
import styled from 'styled-components';
import { SortableContainer } from 'react-sortable-hoc';
import QuickReplyChoice from './QuickReplyChoice';
var ListWrapper = styled.div.withConfig({
  displayName: "QuickReplyChoiceList__ListWrapper",
  componentId: "sc-czv53o-0"
})(["display:flex;"]);

var QuickReplyChoiceList = function QuickReplyChoiceList(_ref) {
  var messageStruct = _ref.messageStruct,
      quickReplyChoices = _ref.quickReplyChoices,
      undefinedBlock = _ref.undefinedBlock,
      toggleQuickReplyDeleteModal = _ref.toggleQuickReplyDeleteModal,
      quickReplyInterface = _ref.quickReplyInterface,
      errors = _ref.errors,
      intl = _ref.intl,
      scenarios = _ref.scenarios;
  return React.createElement(ListWrapper, null, quickReplyChoices.map(function (quickReplyChoice, i) {
    return React.createElement(QuickReplyChoice, {
      key: "".concat(quickReplyChoice.id, "-quick_reply_choice"),
      messageStruct: messageStruct,
      quickReplyChoice: quickReplyChoice,
      choicesLength: quickReplyChoices.length,
      toggleQuickReplyDeleteModal: undefinedBlock ? null : toggleQuickReplyDeleteModal,
      errors: errors,
      index: i,
      quickReplyInterface: quickReplyInterface,
      intl: intl,
      scenarios: scenarios
    });
  }));
};

export default SortableContainer(QuickReplyChoiceList);