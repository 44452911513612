import { ActionsType } from '@src/redux/fanp/constants/initialConversation';
export var setSelectedScenarios = function (payload) { return ({
    type: ActionsType.SET_SELECTED_SCENARIOS,
    payload: payload,
}); };
export var setSelectedScenariosWeightToEquality = function () { return ({
    type: ActionsType.SET_SELECTED_SCENARIOS_WEIGHT_TO_EQUALITY,
}); };
export var setSelectedScenariosWeightToCustom = function () { return ({
    type: ActionsType.SET_SELECTED_SCENARIOS_WEIGHT_TO_CUSTOM,
}); };
export var setInitialScenarios = function (payload) { return ({
    type: ActionsType.SET_SCENARIOS,
    payload: payload,
}); };
export var setActiveInitialConversation = function (payload) { return ({
    type: ActionsType.SET_ACTIVE_INITIAL_CONVERSATION,
    payload: payload,
}); };
export var setActiveABTesting = function (payload) { return ({
    type: ActionsType.SET_ACTIVE_AB_TESTING,
    payload: payload,
}); };
export var setDraftABTesting = function (payload) { return ({
    type: ActionsType.SET_DRAFT_AB_TESTING,
    payload: payload,
}); };
export var setDraftInitialConversation = function (payload) { return ({
    type: ActionsType.SET_DRAFT_INITIAL_CONVERSATION,
    payload: payload,
}); };
