import { adScenario } from '@src/redux/fanp/constants';
var ADD_NEW_ITEM = adScenario.ADD_NEW_ITEM,
    ADD_CREATED_TAG = adScenario.ADD_CREATED_TAG,
    AD_HANDLE_ADD_NEW_TEXT_BUTTON = adScenario.AD_HANDLE_ADD_NEW_TEXT_BUTTON,
    AD_HANDLE_ADD_NEW_GENERIC_BUTTON = adScenario.AD_HANDLE_ADD_NEW_GENERIC_BUTTON,
    AD_HANDLE_NEW_SCENARIO_DELIVERY = adScenario.AD_HANDLE_NEW_SCENARIO_DELIVERY,
    AD_HANDLE_STEP_CAMPAIGN_CONTENT = adScenario.AD_HANDLE_STEP_CAMPAIGN_CONTENT,
    AD_HANDLE_UPDATED_SCENARIO = adScenario.AD_HANDLE_UPDATED_SCENARIO,
    AD_HANDLE_AJAX_FREE_INPUT = adScenario.AD_HANDLE_AJAX_FREE_INPUT,
    AD_REMOVE_TAG = adScenario.AD_REMOVE_TAG;
export var addNewItem = function addNewItem(_ref) {
  var messageId = _ref.messageId,
      itemIterator = _ref.itemIterator,
      newItem = _ref.newItem;
  return {
    type: ADD_NEW_ITEM,
    messageId: messageId,
    itemIterator: itemIterator,
    newItem: newItem
  };
};
export var addCreatedTag = function addCreatedTag(_ref2) {
  var messageId = _ref2.messageId,
      itemIterator = _ref2.itemIterator,
      tagIterator = _ref2.tagIterator,
      newTag = _ref2.newTag;
  return {
    type: ADD_CREATED_TAG,
    messageId: messageId,
    itemIterator: itemIterator,
    tagIterator: tagIterator,
    newTag: newTag
  };
};
export var adHandleAddNewTextButton = function adHandleAddNewTextButton(_ref3) {
  var messageId = _ref3.messageId,
      newButton = _ref3.newButton,
      scenarioId = _ref3.scenarioId;
  return {
    type: AD_HANDLE_ADD_NEW_TEXT_BUTTON,
    messageId: messageId,
    scenarioId: scenarioId,
    newButton: newButton
  };
};
export var adHandleAddNewGenericButton = function adHandleAddNewGenericButton(_ref4) {
  var messageId = _ref4.messageId,
      listIterator = _ref4.listIterator,
      newButton = _ref4.newButton,
      scenarioId = _ref4.scenarioId;
  return {
    type: AD_HANDLE_ADD_NEW_GENERIC_BUTTON,
    messageId: messageId,
    listIterator: listIterator,
    newButton: newButton,
    scenarioId: scenarioId
  };
};
export var handleNewScenarioDelivery = function handleNewScenarioDelivery(scenarioId, scenarioDelivery) {
  return {
    type: AD_HANDLE_NEW_SCENARIO_DELIVERY,
    scenarioId: scenarioId,
    scenarioDelivery: scenarioDelivery
  };
};
export var handleStepCampaignContent = function handleStepCampaignContent(scenarioId, campaignContent) {
  return {
    type: AD_HANDLE_STEP_CAMPAIGN_CONTENT,
    scenarioId: scenarioId,
    campaignContent: campaignContent
  };
};
export var handleUpdatedScenario = function handleUpdatedScenario(adScenarioId, updatedScenario) {
  return {
    type: AD_HANDLE_UPDATED_SCENARIO,
    adScenarioId: adScenarioId,
    updatedScenario: updatedScenario
  };
};
export var handleAjaxFreeInput = function handleAjaxFreeInput(ajaxResponse, blockId) {
  return {
    type: AD_HANDLE_AJAX_FREE_INPUT,
    ajaxResponse: ajaxResponse,
    blockId: blockId
  };
};
export var removeGenericTagAction = function removeGenericTagAction(messageId, itemId, tagId, name) {
  return {
    type: AD_REMOVE_TAG,
    messageId: messageId,
    itemId: itemId,
    tagId: tagId,
    name: name
  };
};