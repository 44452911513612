export var modalNames = {
  userListFilter: 'userListFilter',
  userListInfoModal: 'userListInfoModal',
  talkTriggerDelete: 'talkTriggerDelete'
};
export var itemTypes = {
  GENERIC_ITEM: 'GENERIC_ITEM'
};
export var flexMessageTypes = {
  BUBBLE: 'BUBBLE'
};