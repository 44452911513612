import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import React, { useState } from 'react';
import styled from 'styled-components';
import { colors } from '@src/colors';
import { transitions } from '@src/lib/animations';
import { Button, Switch, DeleteIcon } from '@src/components/atoms';
import buttonTypes from '@src/lib/buttonTypes';
import ActionPreviewItem from '@src/components/organisms/fanp/plugins/ActionPreviewItem';
import { pluginActionTypes } from '@src/lib/app-constants';
var Container = styled.div.withConfig({
  displayName: "Container",
  componentId: "sc-1tj86wk-0"
})(["display:flex;align-items:center;justify-content:space-between;padding:16px 0;border-bottom:1px solid ", ";position:relative;transition:", ";&:hover{background:", ";.adicon_delete{display:block;}}&:last-child{margin-bottom:0;border-bottom:none;}.action_score{input{font-size:1rem;width:60px;padding:5px 0px;border:1px solid ", ";border-radius:3px;margin-right:8px;text-align:center;}}"], colors.background.off_white_background, transitions.normal, colors.background.content, colors.border.default);
var ActionPreviewWrapper = styled.div.withConfig({
  displayName: "ActionPreviewWrapper",
  componentId: "sc-1tj86wk-1"
})(["width:35%;display:flex;align-items:center;.button_wrapper{margin-left:16px;}"]);

var PluginActionListItem = function PluginActionListItem(_ref) {
  var action = _ref.action,
      _onClick = _ref.onClick,
      updateActionScore = _ref.updateActionScore,
      onDelete = _ref.onDelete;

  var _useState = useState(action.score),
      _useState2 = _slicedToArray(_useState, 2),
      scoreState = _useState2[0],
      setState = _useState2[1];

  return React.createElement(Container, null, React.createElement(DeleteIcon, {
    onClick: function onClick() {
      return onDelete(action.id);
    }
  }), React.createElement(ActionPreviewWrapper, null, React.createElement(ActionPreviewItem, {
    action: action,
    width: "100%"
  }), ['conversion', 'get_action_history', 'connection_client_member'].indexOf(action.action_type) === -1 && React.createElement("div", {
    className: "button_wrapper"
  }, React.createElement(Button, {
    width: 100,
    onClick: function onClick() {
      return _onClick(action);
    },
    text: "\u7DE8\u96C6",
    buttonType: buttonTypes.SETTING
  }))), React.createElement("div", {
    className: "action_type"
  }, pluginActionTypes[action.action_type]), ['conversion', 'get_action_history', 'connection_client_member'].indexOf(action.action_type) === -1 ? React.createElement("div", {
    className: "action_score"
  }, React.createElement("input", {
    width: "70",
    type: "number",
    placeholder: "\u5272\u5408\u3092\u5165\u529B",
    min: "0",
    onChange: function onChange(e) {
      updateActionScore(action.id, e.target.value);
      setState(e.target.value);
    },
    value: scoreState
  }), "\uFF05") : React.createElement("div", {
    className: "action_score"
  }, " - "), React.createElement(Switch, {
    isActive: action.active
  }));
};

export default PluginActionListItem;