var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { objToQuery } from '@src/lib/helpers';
import { compile } from 'react-router-dom/node_modules/path-to-regexp';
import transformResponse from './transformResponse';
import axiosInstance from './axiosInstance';
// endpoints
var collection = function (_a) {
    var chatbotId = _a.chatbotId, scenarioId = _a.scenarioId, blockId = _a.blockId;
    return "/chatbots/" + chatbotId + "/scenarios/" + scenarioId + "/blocks/" + blockId + "/quick_replies";
};
var member = function (_a) {
    var chatbotId = _a.chatbotId, scenarioId = _a.scenarioId, blockId = _a.blockId, quickReplyId = _a.quickReplyId;
    return collection({
        chatbotId: chatbotId,
        scenarioId: scenarioId,
        blockId: blockId,
    }) + "/" + quickReplyId;
};
var ranks = function (_a) {
    var chatbotId = _a.chatbotId, scenarioId = _a.scenarioId, blockId = _a.blockId, quickReplyId = _a.quickReplyId;
    return member({
        chatbotId: chatbotId,
        scenarioId: scenarioId,
        blockId: blockId,
        quickReplyId: quickReplyId,
    }) + "/choice_ranks";
};
var quickReplyChoicesCollection = function (_a) {
    var chatbotId = _a.chatbotId, scenarioId = _a.scenarioId, blockId = _a.blockId, quickReplyId = _a.quickReplyId;
    return "/chatbots/" + chatbotId + "/scenarios/" + scenarioId + "/blocks/" + blockId + "/quick_replies/" + quickReplyId + "/quick_reply_choices";
};
var quickReplyChoicesMember = function (_a) {
    var id = _a.id, chatbotId = _a.chatbotId, scenarioId = _a.scenarioId, blockId = _a.blockId, quickReplyId = _a.quickReplyId;
    return quickReplyChoicesCollection({
        chatbotId: chatbotId,
        scenarioId: scenarioId,
        blockId: blockId,
        quickReplyId: quickReplyId,
    }) + "/" + id;
};
var updateMultiQuickReplyChoices = function (_a) {
    var chatbotId = _a.chatbotId, scenarioId = _a.scenarioId, blockId = _a.blockId, quickReplyId = _a.quickReplyId;
    return "/chatbots/" + chatbotId + "/scenarios/" + scenarioId + "/blocks/" + blockId + "/quick_replies/" + quickReplyId + "/quick_reply_choices/update_multi_quick_reply_choices";
};
export var quickReplyChoice = function (_a) {
    var chatbotId = _a.chatbotId, scenarioId = _a.scenarioId, blockId = _a.blockId, quickReplyId = _a.quickReplyId, quickReplyChoiceId = _a.quickReplyChoiceId;
    return quickReplyChoicesCollection({
        chatbotId: chatbotId,
        scenarioId: scenarioId,
        blockId: blockId,
        quickReplyId: quickReplyId,
    }) + "/" + quickReplyChoiceId;
};
export function createQuickReplyWithTags(chatbotId, scenarioId, blockId, body) {
    return __awaiter(this, void 0, void 0, function () {
        var url, res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    url = collection({ chatbotId: chatbotId, scenarioId: scenarioId, blockId: blockId });
                    return [4 /*yield*/, transformResponse(axiosInstance.post(url, body))];
                case 1:
                    res = _a.sent();
                    return [2 /*return*/, res.quick_reply];
            }
        });
    });
}
export function createQuickReply(chatbotId, scenarioId, blockId, body) {
    return __awaiter(this, void 0, void 0, function () {
        var url, res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    url = collection({ chatbotId: chatbotId, scenarioId: scenarioId, blockId: blockId });
                    return [4 /*yield*/, transformResponse(axiosInstance.post(url, body))];
                case 1:
                    res = _a.sent();
                    return [2 /*return*/, res.quick_reply];
            }
        });
    });
}
export function removeQuickReply(chatbotId, scenarioId, quickReplyId, blockId, layerId) {
    return __awaiter(this, void 0, void 0, function () {
        var query, url, res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    query = { layer_id: layerId };
                    url = member({ chatbotId: chatbotId, scenarioId: scenarioId, blockId: blockId, quickReplyId: quickReplyId });
                    return [4 /*yield*/, transformResponse(axiosInstance.delete(url + "?" + objToQuery(query)))];
                case 1:
                    res = _a.sent();
                    return [2 /*return*/, res];
            }
        });
    });
}
export function updateQuickReplyChoiceRank(chatbotId, scenarioId, blockId, quickReplyId, body) {
    return __awaiter(this, void 0, void 0, function () {
        var url, res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    url = ranks({ chatbotId: chatbotId, scenarioId: scenarioId, blockId: blockId, quickReplyId: quickReplyId });
                    return [4 /*yield*/, transformResponse(axiosInstance.put(url, body))];
                case 1:
                    res = _a.sent();
                    return [2 /*return*/, res.quick_reply_choices];
            }
        });
    });
}
// Quick reply choices requests
export function addQuickReplyChoice(chatbotId, scenarioId, blockId, quickReplyId, body) {
    return __awaiter(this, void 0, void 0, function () {
        var url, res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    url = quickReplyChoicesCollection({
                        chatbotId: chatbotId,
                        scenarioId: scenarioId,
                        blockId: blockId,
                        quickReplyId: quickReplyId,
                    });
                    return [4 /*yield*/, transformResponse(axiosInstance.post(url, body))];
                case 1:
                    res = _a.sent();
                    return [2 /*return*/, res.quick_reply_choices];
            }
        });
    });
}
export function removeQuickReplyChoice(chatbotId, scenarioId, blockId, quickReplyId, id, query) {
    return __awaiter(this, void 0, void 0, function () {
        var url, res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    url = quickReplyChoicesMember({
                        id: id,
                        chatbotId: chatbotId,
                        scenarioId: scenarioId,
                        blockId: blockId,
                        quickReplyId: quickReplyId,
                    });
                    return [4 /*yield*/, transformResponse(axiosInstance.delete(url + "?" + objToQuery(query)))];
                case 1:
                    res = _a.sent();
                    return [2 /*return*/, res];
            }
        });
    });
}
export function connectQuickReplyChoices(chatbotId, scenarioId, blockId, quickReplyId, body) {
    return __awaiter(this, void 0, void 0, function () {
        var url, res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    url = updateMultiQuickReplyChoices({
                        chatbotId: chatbotId,
                        scenarioId: scenarioId,
                        blockId: blockId,
                        quickReplyId: quickReplyId,
                    });
                    return [4 /*yield*/, transformResponse(axiosInstance.put(url, body))];
                case 1:
                    res = _a.sent();
                    return [2 /*return*/, res.quick_reply_choices];
            }
        });
    });
}
export function connectQuickReplyChoice(chatbotId, scenarioId, blockId, quickReplyId, quickReplyChoiceId, body) {
    return __awaiter(this, void 0, void 0, function () {
        var url, res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    url = quickReplyChoice({
                        chatbotId: chatbotId,
                        scenarioId: scenarioId,
                        blockId: blockId,
                        quickReplyId: quickReplyId,
                        quickReplyChoiceId: quickReplyChoiceId,
                    });
                    return [4 /*yield*/, transformResponse(axiosInstance.put(url, body))];
                case 1:
                    res = _a.sent();
                    return [2 /*return*/, res.quick_reply_choice];
            }
        });
    });
}
export function editQuickReplyChoice(chatbotId, scenarioId, blockId, quickReplyId, quickReplyChoiceId, body) {
    return __awaiter(this, void 0, void 0, function () {
        var url, res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    url = quickReplyChoice({
                        chatbotId: chatbotId,
                        scenarioId: scenarioId,
                        blockId: blockId,
                        quickReplyId: quickReplyId,
                        quickReplyChoiceId: quickReplyChoiceId,
                    });
                    return [4 /*yield*/, transformResponse(axiosInstance.put(url, body))];
                case 1:
                    res = _a.sent();
                    return [2 /*return*/, res.quick_reply_choice];
            }
        });
    });
}
export function editQuickReplyChoiceUserTag(chatbotId, scenarioId, blockId, quickReplyId, quickReplyChoiceId, body) {
    return __awaiter(this, void 0, void 0, function () {
        var url, res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    url = quickReplyChoice({
                        chatbotId: chatbotId,
                        scenarioId: scenarioId,
                        blockId: blockId,
                        quickReplyId: quickReplyId,
                        quickReplyChoiceId: quickReplyChoiceId,
                    });
                    return [4 /*yield*/, transformResponse(axiosInstance.put(url, body))];
                case 1:
                    res = _a.sent();
                    return [2 /*return*/, res.quick_reply_choice];
            }
        });
    });
}
var quickReplyChoiceFieldSelectableOptionAssociationsUrl = function (chatbotId, fieldId) {
    return compile('/chatbots/:chatbot_id/fields/:field_id/quick_reply_choice_selectable_option_associations')({
        field_id: fieldId,
        chatbot_id: chatbotId,
    });
};
export var quickReplyChoiceFieldSelectableOptionAssociations = function (data) { return __awaiter(void 0, void 0, void 0, function () {
    var chatbotId, fieldId, body, url, res;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                chatbotId = data.chatbotId, fieldId = data.fieldId, body = __rest(data, ["chatbotId", "fieldId"]);
                url = quickReplyChoiceFieldSelectableOptionAssociationsUrl(chatbotId, fieldId);
                return [4 /*yield*/, transformResponse(axiosInstance.post(url, body))];
            case 1:
                res = _a.sent();
                return [2 /*return*/, res.quick_reply_choices];
        }
    });
}); };
export var getListSelectableOptionsQuickRepliesId = function (data) { return __awaiter(void 0, void 0, void 0, function () {
    var chatbotId, scenarioId, blockId, quickReplyId, path, url, res;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                chatbotId = data.chatbotId, scenarioId = data.scenarioId, blockId = data.blockId, quickReplyId = data.quickReplyId;
                path = '/chatbots/:chatbot_id/scenarios/:scenario_id/blocks/:block_id/quick_replies/:id/list_selectable_options';
                url = compile(path)({
                    block_id: blockId,
                    id: quickReplyId,
                    scenario_id: scenarioId,
                    chatbot_id: chatbotId,
                });
                return [4 /*yield*/, transformResponse(axiosInstance.get(url))];
            case 1:
                res = _a.sent();
                return [2 /*return*/, res.selectable_options];
        }
    });
}); };
export var updateInformationAssociatedWithQuickReplyChoice = function (payload) { return __awaiter(void 0, void 0, Promise, function () {
    var name, nextScenarioId, nextBlockId, layerRank, scenarioId, postbackActionId, userTagId, selectableOptionId, chatbotId, blockId, quickReplyId, quickReplyChoiceId, pathParameters, body, path, url, res;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                name = payload.name, nextScenarioId = payload.nextScenarioId, nextBlockId = payload.nextBlockId, layerRank = payload.layerRank, scenarioId = payload.scenarioId, postbackActionId = payload.postbackActionId, userTagId = payload.userTagId, selectableOptionId = payload.selectableOptionId, chatbotId = payload.chatbotId, blockId = payload.blockId, quickReplyId = payload.quickReplyId, quickReplyChoiceId = payload.quickReplyChoiceId;
                pathParameters = {
                    chatbot_id: chatbotId,
                    scenario_id: scenarioId,
                    block_id: blockId,
                    quick_reply_id: quickReplyId,
                    // quick_reply_choiceId: quickReplyChoiceId,
                    id: quickReplyChoiceId,
                };
                body = {
                    name: name,
                    next_scenario_id: nextScenarioId,
                    next_block_id: nextBlockId,
                    layer_rank: layerRank,
                    scenario_id: scenarioId,
                    postback_action_id: postbackActionId,
                    user_tag_id: userTagId,
                    selectable_option_id: selectableOptionId,
                    chatbot_id: chatbotId,
                };
                path = '/chatbots/:chatbot_id/scenarios/:scenario_id/blocks/:block_id/quick_replies/:quick_reply_id/quick_reply_choices/:id';
                url = compile(path)(pathParameters);
                return [4 /*yield*/, transformResponse(axiosInstance.patch(url, body))];
            case 1:
                res = _a.sent();
                return [2 /*return*/, res.quick_reply_choice];
        }
    });
}); };
