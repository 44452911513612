export var endUsers = [{
  id: 1,
  pinned: false,
  important: false,
  first_name: '太郎',
  last_name: '田中',
  profile_photo_url: '',
  gender: 'male',
  blocked: false,
  auto_reply: false,
  last_message: {
    id: 1,
    created_at: '2017-12-04T14:25:52.000+09:00',
    content: 'こんにちは',
    end_user_read: true
  }
}, {
  id: 2,
  pinned: true,
  important: true,
  first_name: '花子',
  last_name: '山田',
  profile_photo_url: '',
  gender: 'female',
  blocked: false,
  auto_reply: true,
  last_message: {
    id: 2,
    created_at: '2017-12-03T20:10:52.000+09:00',
    content: 'こんばんは',
    end_user_read: false
  }
}];