var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Button } from '@src/components/atoms';
import buttonTypes from '@src/lib/buttonTypes';
import { injectIntl } from 'react-intl';
import * as pathLinks from '@src/routes/Fanp/pathLinks';
var ListContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 250px;\n  text-align: center;\n  border-right: 1px ridge;\n  .inline-c-flexbox {\n    display: flex;\n    justify-content: center;\n    border-bottom: 1px ridge;\n    padding: 10px;\n  }\n"], ["\n  width: 250px;\n  text-align: center;\n  border-right: 1px ridge;\n  .inline-c-flexbox {\n    display: flex;\n    justify-content: center;\n    border-bottom: 1px ridge;\n    padding: 10px;\n  }\n"])));
var SideMenu = function (_a) {
    var targetList = _a.targetList, chatbotId = _a.chatbotId, renderItem = _a.renderItem, intl = _a.intl;
    var browserHistory = useHistory();
    return (React.createElement(ListContainer, null,
        React.createElement("div", { className: "inline-c-flexbox" },
            React.createElement(Button, { text: intl.formatMessage({ id: "CVm3PB", defaultMessage: "Create New" }), buttonType: buttonTypes.SETTING, onClick: function () {
                    browserHistory.push(pathLinks.dashboardAdChatbots_chatbotId_TracksNew({
                        chatbotId: chatbotId,
                    }));
                } })),
        targetList.map(renderItem)));
};
export default injectIntl(SideMenu);
var templateObject_1;
