var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { useFormik } from 'formik';
import buttonTypes from '@src/lib/buttonTypes';
import { colors } from '@src/colors';
import { Input, Button, Spinner, Tag } from '@src/components/atoms';
import { filterSchema } from '@src/lib/helpers/formSchema';
import { selectNewTag, removeSelectedTag, resetSelectedTags, resetSelectedFilter, resetDoneStatus, handleOpenFilterForm, updateNewMessageFilter, createNewMessageFilter, selectTagGroupId, } from '@src/redux/fanp/actions/messageFilter';
import { injectIntl } from 'react-intl';
import NewFilterTagSelector from './NewFilterTagSelector';
var ModifyFilterFormContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n\n  .filter_form_spinner {\n    background: #fff;\n    height: 240px;\n  }\n\n  > div.message-wrapper {\n    padding-top: 5px;\n  }\n\n  .filter_name {\n    display: block;\n\n    input {\n      display: block;\n    }\n  }\n\n  .item_area {\n    .search-bar > input {\n      width: 100%;\n    }\n\n    .selected-tag-area {\n      min-height: 40px;\n      height: unset;\n\n      > div {\n        max-height: 224px;\n        display: flex;\n        flex-flow: wrap;\n        overflow-y: auto;\n      }\n    }\n\n    .selected-filter-tag,\n    .filter-tag {\n      .tag-name {\n        margin-right: 4px;\n      }\n\n      span.adicon_plus02,\n      span.adicon_close {\n        position: relative;\n        width: 10px;\n\n        &:before {\n          position: absolute;\n          top: -5px;\n        }\n      }\n\n      span.adicon_close::before {\n        top: -3px;\n      }\n    }\n  }\n"], ["\n  width: 100%;\n\n  .filter_form_spinner {\n    background: #fff;\n    height: 240px;\n  }\n\n  > div.message-wrapper {\n    padding-top: 5px;\n  }\n\n  .filter_name {\n    display: block;\n\n    input {\n      display: block;\n    }\n  }\n\n  .item_area {\n    .search-bar > input {\n      width: 100%;\n    }\n\n    .selected-tag-area {\n      min-height: 40px;\n      height: unset;\n\n      > div {\n        max-height: 224px;\n        display: flex;\n        flex-flow: wrap;\n        overflow-y: auto;\n      }\n    }\n\n    .selected-filter-tag,\n    .filter-tag {\n      .tag-name {\n        margin-right: 4px;\n      }\n\n      span.adicon_plus02,\n      span.adicon_close {\n        position: relative;\n        width: 10px;\n\n        &:before {\n          position: absolute;\n          top: -5px;\n        }\n      }\n\n      span.adicon_close::before {\n        top: -3px;\n      }\n    }\n  }\n"])));
var ButtonsWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  float: right;\n  display: flex;\n  margin: 40px 0;\n\n  .create-update-filter-button {\n    margin-left: 8px;\n  }\n"], ["\n  float: right;\n  display: flex;\n  margin: 40px 0;\n\n  .create-update-filter-button {\n    margin-left: 8px;\n  }\n"])));
var StepTitle = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-size: 14px;\n  color: ", ";\n  font-weight: bold;\n  margin: 24px 8px 13px;\n  opacity: ", ";\n\n  .adicon_tag {\n    font-size: 20px;\n    padding-bottom: 9px;\n    padding-right: 3px;\n    position: relative;\n    width: 25px;\n    display: inline-block;\n\n    &:before {\n      position: absolute;\n      top: -7.5px;\n    }\n  }\n"], ["\n  font-size: 14px;\n  color: ",
    ";\n  font-weight: bold;\n  margin: 24px 8px 13px;\n  opacity: ", ";\n\n  .adicon_tag {\n    font-size: 20px;\n    padding-bottom: 9px;\n    padding-right: 3px;\n    position: relative;\n    width: 25px;\n    display: inline-block;\n\n    &:before {\n      position: absolute;\n      top: -7.5px;\n    }\n  }\n"])), function (props) {
    return props.isReadonly ? colors.placeholder : colors.default_text;
}, function (props) { return (props.isReadonly ? '0.3' : '1'); });
var ModifyFilterForm = function (_a) {
    var _b;
    var chatbotId = _a.chatbotId, isFocusTag = _a.isFocusTag, setIsFocusTag = _a.setIsFocusTag, attributeQuestions = _a.attributeQuestions, intl = _a.intl;
    var _c = useSelector(function (_a) {
        var messageFilter = _a.messageFilter;
        return messageFilter;
    }), messageFilters = _c.messageFilters, selectedFilterId = _c.selectedFilterId, attributeAnswerIds = _c.attributeAnswerIds, attribute_answers = _c.attribute_answers, isProcessing = _c.isProcessing, isOpenFilterForm = _c.isOpenFilterForm, selectedAttributeQuestionGroupId = _c.selectedAttributeQuestionGroupId;
    var dispatch = useDispatch();
    // This flag is condition NewFilterTagSelector touched or not, releated to show errors
    var _d = __read(useState(false), 2), itemAreaTouched = _d[0], setItemAreaTouched = _d[1];
    var selectedFilter = messageFilters &&
        selectedFilterId &&
        messageFilters.find(function (mf) { return mf.id === selectedFilterId; });
    var initAttributeAnswerIds = ((selectedFilter === null || selectedFilter === void 0 ? void 0 : selectedFilter.tag_filters) || []).map(function (_a) {
        var tag_id = _a.tag_id;
        return tag_id;
    });
    var otherFilterNames = (messageFilters || [])
        .map(function (_a) {
        var id = _a.id, name = _a.name;
        return (id === selectedFilterId ? null : name);
    })
        .filter(Boolean);
    // Use formik to handle validate for form input, it contain name and attributeAnswerIds attributes
    var _e = useFormik({
        initialValues: {
            name: selectedFilter === null || selectedFilter === void 0 ? void 0 : selectedFilter.name,
            attributeAnswerIds: initAttributeAnswerIds,
        },
        validateOnMount: true,
        validationSchema: filterSchema({ otherFilterNames: otherFilterNames, intl: intl }),
        onSubmit: function () { },
    }), values = _e.values, errors = _e.errors, touched = _e.touched, setFieldValue = _e.setFieldValue, setFieldTouched = _e.setFieldTouched;
    useEffect(function () {
        // Reset attribute answers when did mount component
        dispatch(resetSelectedTags(initAttributeAnswerIds));
    }, []);
    useEffect(function () {
        // It prevent formik validate when reset attributeAnswerIds,
        // it only need on mount validate
        if (!touched.attributeAnswerIds) {
            setFieldTouched('attributeAnswerIds', true);
            return;
        }
        setFieldValue('attributeAnswerIds', attributeAnswerIds, true);
    }, [JSON.stringify(attributeAnswerIds)]); // it need json stringify array when use effect
    var isReadOnly = selectedFilterId;
    var TagReadOnly = selectedFilter && ((_b = selectedFilter.block_filters) === null || _b === void 0 ? void 0 : _b.length);
    var handleCancel = function () {
        setIsFocusTag(false);
        setItemAreaTouched(false);
        // Close editing field
        dispatch(handleOpenFilterForm(false));
        // Reset Selected tags and inputed filter name
        dispatch(resetSelectedFilter());
        dispatch(resetSelectedTags());
        dispatch(resetDoneStatus());
    };
    var creatingNewMessageFilter = function (filterName) {
        setIsFocusTag(false);
        setItemAreaTouched(false);
        dispatch(createNewMessageFilter(chatbotId, filterName, attributeAnswerIds));
        dispatch(handleOpenFilterForm(false));
        // Reset Selected tags and inputed filter name
        dispatch(resetSelectedTags());
        dispatch(resetDoneStatus());
    };
    var updatingNewMessageFilter = function (newFilterName) {
        setIsFocusTag(false);
        setItemAreaTouched(false);
        dispatch(updateNewMessageFilter(chatbotId, newFilterName, selectedFilterId, attributeAnswerIds));
        dispatch(handleOpenFilterForm(false));
    };
    // render necessary buttons
    var renderButtons = function (errors) {
        var isDisabled = Boolean(Object.keys(errors).length);
        return (React.createElement(ButtonsWrapper, null,
            React.createElement(Button, { className: "cancel-create-update-filter-button", buttonType: buttonTypes.CANCEL, text: intl.formatMessage({ id: "47FYwb", defaultMessage: "Cancel" }), width: 120, onClick: handleCancel }),
            React.createElement(Button, { className: "create-update-filter-button", buttonType: isDisabled
                    ? buttonTypes.DISABLED
                    : selectedFilter
                        ? buttonTypes.SETTING
                        : buttonTypes.SETTING_GREEN, width: 76, minWidth: 76, fontWeight: 700, text: selectedFilterId
                    ? intl.formatMessage({ id: "jvo0vs", defaultMessage: "Save" })
                    : intl.formatMessage({ id: "VzzYJk", defaultMessage: "Create" }), disabled: isDisabled, onClick: function () {
                    return selectedFilterId
                        ? updatingNewMessageFilter(values.name)
                        : creatingNewMessageFilter(values.name);
                } })));
    };
    return (React.createElement(ModifyFilterFormContainer, null,
        React.createElement(StepTitle, null, intl.formatMessage({ id: "WmDJ/g", defaultMessage: "Filter Name" })),
        React.createElement(Input, { hasError: Boolean(touched.name && errors.name), className: "filter_name", name: "name", value: values.name || '', placeholder: intl.formatMessage({ id: "HGJGjB", defaultMessage: "Please enter a Filter Name" }), onChange: function (_a) {
                var _b = _a.target, name = _b.name, value = _b.value;
                setFieldValue(name, value, true);
                setFieldTouched(name, true);
            }, onBlur: function () { return setFieldTouched('name', true); }, autoFocus: !isFocusTag }),
        touched.name && errors.name && (React.createElement("div", { className: "message-wrapper error" }, errors.name)),
        React.createElement(StepTitle, { isReadonly: TagReadOnly },
            React.createElement("span", { className: "adicon_tag" }),
            intl.formatMessage({ id: "npEBoK", defaultMessage: "Select Tag" })),
        isProcessing ? (React.createElement(Spinner, { className: "filter_form_spinner" })) : (React.createElement("div", { className: "item_area", onBlur: function () { return setItemAreaTouched(true); } },
            React.createElement(NewFilterTagSelector, { multipleChoice: true, chatbotId: chatbotId.toString(), selectedAttributeQuestionGroupId: selectedAttributeQuestionGroupId, ItemComponent: Tag, items: attribute_answers, isReadonly: TagReadOnly, attributeQuestions: attributeQuestions, selectedItemIds: attributeAnswerIds, onAddItem: function (tagId) {
                    setItemAreaTouched(true);
                    dispatch(selectNewTag(tagId));
                }, onRemoveItem: function (tagId) {
                    setItemAreaTouched(true);
                    dispatch(removeSelectedTag(tagId));
                }, onSelectTagGroup: function (tagGroup) {
                    setItemAreaTouched(true);
                    dispatch(selectTagGroupId(tagGroup.value));
                }, error: itemAreaTouched && errors.attributeAnswerIds, inputPlaceholder: intl.formatMessage({ id: "3DV02d", defaultMessage: "Search Tag" }), selectedTagPlaceholder: intl.formatMessage({ id: "0xs1F4", defaultMessage: "No Tag selected" }), height: "270px", maxWidth: "380px", margin: "0 0 8px", autoFocus: isFocusTag }))),
        renderButtons(errors)));
};
export default injectIntl(ModifyFilterForm);
var templateObject_1, templateObject_2, templateObject_3;
