var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import Wave from 'react-wavify';
import styled from 'styled-components';
import { centeredFlex } from '@src/lib/mixins';
var LoadingContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: relative;\n  width: 100%;\n  height: 100vh;\n  ", ";\n"], ["\n  position: relative;\n  width: 100%;\n  height: 100vh;\n  ", ";\n"])), centeredFlex);
var FlameContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 100px;\n  height: 180px;\n\n  svg {\n    width: 100%;\n    height: 100%;\n  }\n"], ["\n  width: 100px;\n  height: 180px;\n\n  svg {\n    width: 100%;\n    height: 100%;\n  }\n"])));
var LoadingScreen = function (_a) {
    var className = _a.className;
    return (React.createElement(LoadingContainer, { "data-testid": "loading-screen", "aria-label": "Loading", "aria-busy": "true", "aria-live": "polite", className: className, role: "status" },
        React.createElement(FlameContainer, null,
            React.createElement(Wave, { fill: "url(#bluegradient)", mask: "url(#mask)", options: {
                    height: 15,
                    points: 5,
                    speed: 0.5,
                    amplitude: 15,
                }, style: { height: '180px' } },
                React.createElement("defs", null,
                    React.createElement("style", null,
                        ".cls-1",
                        '{fill:none;}',
                        ".cls-2",
                        '{fill:url(#bluegradient);}'),
                    React.createElement("linearGradient", { id: "bluegradient", x1: "12.7", y1: "165.53", x2: "101.12", y2: "34.45", gradientUnits: "userSpaceOnUse" },
                        React.createElement("stop", { offset: "0.08", stopColor: "#2073ef" }),
                        React.createElement("stop", { offset: "0.32", stopColor: "#1e30d6" }),
                        React.createElement("stop", { offset: "0.77", stopColor: "#0501bf" }))),
                React.createElement("mask", { id: "mask", height: "180px" },
                    React.createElement("path", { d: "M59.39,180a24.93,24.93,0,0,0,9.5-6.91,51.79,51.79,0,0,0,9.81-17.4,55.94,55.94,0,0,0,3.55-19.92,33.59,33.59,0,0,0-2.64-12.3L50.72,55.09c-.17-.41-.42-.78-.77-1.42-1.31,3.08-2.5,5.87-3.67,8.66q-12.22,29-24.45,58a50,50,0,0,0-3.48,10.21,35.15,35.15,0,0,0-.25,11.3,62.82,62.82,0,0,0,7,21.59c2.9,5.42,6.4,10.37,11.48,14,1.16.82,2.43,1.49,3.8,2.32a45.13,45.13,0,0,1-12.3-4.31A50.58,50.58,0,0,1,2.23,143.72a48.22,48.22,0,0,1-2.13-17A59.08,59.08,0,0,1,5,106.83c3.18-7.2,6.24-14.44,9.29-21.7Q28.9,50.35,43.48,15.52q2.93-7,5.87-13.94c.18-.43.38-.86.7-1.58l2.64,6.2q14.4,34.2,28.79,68.38C86,85.32,90.35,96.13,95.11,106.76a53.64,53.64,0,0,1,4.19,31c-2.74,17.11-11.49,30.2-27.17,38.21A43.52,43.52,0,0,1,59.39,180Z", fill: "white" }))))));
};
export default LoadingScreen;
var templateObject_1, templateObject_2;
