var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React from 'react';
export default (function () {
    var _a = __read(React.useState(false), 2), modal = _a[0], setModal = _a[1];
    var _b = __read(React.useState(''), 2), modalContent = _b[0], setModalContent = _b[1];
    function handleModal(content) {
        if (content === void 0) { content = ''; }
        setModal(Boolean(content));
        setModalContent(content);
    }
    return { modal: modal, handleModal: handleModal, modalContent: modalContent };
});
