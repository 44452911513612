var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { Component } from 'react';
import styled from 'styled-components';
import { colors } from '@src/colors';
import { handleAPIErrors } from '@src/lib/helpers';
import { JSONDisplayer } from '@src/components/atoms';
import { MetaHash } from '@src/components/organisms/fanp';
import * as scenarioApi from '@src/services/api/scenario';
import { injectIntl } from 'react-intl';
var ContentContainer = styled.span(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  .body {\n    display: block;\n    textarea {\n      padding: 1rem;\n      margin-top: 12px;\n      color: ", ";\n      width: 100%;\n      min-height: 200px;\n      border: 1px solid ", ";\n      border-radius: 5px;\n      box-sizing: border-box;\n      -moz-box-sizing: border-box;\n      -webkit-box-sizing: border-box;\n    }\n    pre {\n      text-align: left;\n      margin: 12px 0;\n    }\n  }\n"], ["\n  .body {\n    display: block;\n    textarea {\n      padding: 1rem;\n      margin-top: 12px;\n      color: ", ";\n      width: 100%;\n      min-height: 200px;\n      border: 1px solid ", ";\n      border-radius: 5px;\n      box-sizing: border-box;\n      -moz-box-sizing: border-box;\n      -webkit-box-sizing: border-box;\n    }\n    pre {\n      text-align: left;\n      margin: 12px 0;\n    }\n  }\n"])), colors.text_pale, colors.border.default);
var RetrieveJson = /** @class */ (function (_super) {
    __extends(RetrieveJson, _super);
    function RetrieveJson() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            jsonData: '',
            metaHash: {},
        };
        _this.getJson = function () { return __awaiter(_this, void 0, void 0, function () {
            var _a, chatbotIdParam, adScenarioIdParam, metaHash, jsonData, e_1;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this.props, chatbotIdParam = _a.chatbotIdParam, adScenarioIdParam = _a.adScenarioIdParam;
                        metaHash = Object.keys(this.state.metaHash).length
                            ? this.state.metaHash
                            : null;
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, scenarioApi.getScenarioJson(chatbotIdParam, adScenarioIdParam, metaHash)];
                    case 2:
                        jsonData = _b.sent();
                        this.setState({ jsonData: jsonData });
                        return [3 /*break*/, 4];
                    case 3:
                        e_1 = _b.sent();
                        handleAPIErrors('JSONを取得できませんでした', e_1);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        }); };
        _this.setKeyVal = function (metaKeyEdit, metaValueEdit) {
            var _a;
            var metaHash = _this.state.metaHash;
            if (metaKeyEdit && metaValueEdit) {
                _this.setState({
                    metaHash: __assign(__assign({}, metaHash), (_a = {}, _a[metaKeyEdit] = metaValueEdit, _a)),
                }, function () { return _this.getJson(); });
            }
        };
        return _this;
    }
    RetrieveJson.prototype.componentDidMount = function () {
        this.getJson();
    };
    RetrieveJson.prototype.render = function () {
        var _a = this.state, jsonData = _a.jsonData, metaHash = _a.metaHash;
        var intl = this.props.intl;
        return (React.createElement(ContentContainer, null,
            React.createElement("div", { className: "message-wrapper primary-label" }, intl.formatMessage({ id: "9y9QQh", defaultMessage: "JSON" })),
            React.createElement("div", { className: "body" },
                React.createElement(JSONDisplayer, { data: jsonData }),
                React.createElement("div", { className: "message-wrapper primary-label" },
                    ' ',
                    intl.formatMessage({ id: "4z8G4g", defaultMessage: "Add META" })),
                Object.keys(metaHash).length ? (React.createElement("pre", null, JSON.stringify(metaHash, null, 4))) : null,
                React.createElement(MetaHash, { onSubmit: this.setKeyVal }))));
    };
    return RetrieveJson;
}(Component));
export default injectIntl(RetrieveJson);
var templateObject_1;
