import "core-js/modules/es.array.concat";

var member = function member(_ref) {
  var chatbotId = _ref.chatbotId,
      flexMessageId = _ref.flexMessageId,
      id = _ref.id;
  return "/chatbots/".concat(chatbotId, "/line_flex_messages/").concat(flexMessageId, "/bubbles/").concat(id);
};

export default {
  member: member
};