var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import TextareaAutosize from 'react-textarea-autosize';
import { ProfilePic, Spinner, Button } from '@src/components/atoms';
import buttonTypes from '@src/lib/buttonTypes';
import { centeredFlex } from '@src/lib/mixins';
import { transitions } from '@src/lib/animations';
import { colors } from '@src/colors';
import TagList from '@src/components/molecules/TagList';
import capitalize from 'lodash/capitalize';
import { usePrevious } from '@src/components/hooks/usePrevious';
import { ButtonSet } from '@src/components/molecules';
import { handleAPIErrors } from '../../../lib/helpers/ajaxFuncs';
import * as memoApi from '@src/services/api/memo';
import { injectIntl } from 'react-intl';
var UserStatsBottom = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  min-height: 300px;\n  max-height: 30%;\n  overflow-x: auto;\n  padding: 1rem;\n"], ["\n  min-height: 300px;\n  max-height: 30%;\n  overflow-x: auto;\n  padding: 1rem;\n"])));
var UserInfo = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin-bottom: 10px;\n  .blocked {\n    font-size: 0.6rem;\n    padding: 10px;\n  }\n  .platform {\n    font-size: 1rem;\n    padding: 10px;\n  }\n"], ["\n  margin-bottom: 10px;\n  .blocked {\n    font-size: 0.6rem;\n    padding: 10px;\n  }\n  .platform {\n    font-size: 1rem;\n    padding: 10px;\n  }\n"])));
var ProfileNameWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  flex: 1;\n  text-align: left;\n  overflow: scroll;\n  margin-right: 10px;\n  overflow: hidden;\n"], ["\n  flex: 1;\n  text-align: left;\n  overflow: scroll;\n  margin-right: 10px;\n  overflow: hidden;\n"])));
var ImportantLabel = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: inline-block;\n  padding: 4px 8px;\n  background-color: ", ";\n  border-radius: 5px;\n  font-size: 10px;\n  color: ", ";\n  font-weight: bold;\n  border: 2px solid\n    ", ";\n  margin-left: auto;\n  transition: ", ";\n\n  &:hover {\n    cursor: pointer;\n    color: ", ";\n  }\n"], ["\n  display: inline-block;\n  padding: 4px 8px;\n  background-color: ",
    ";\n  border-radius: 5px;\n  font-size: 10px;\n  color: ",
    ";\n  font-weight: bold;\n  border: 2px solid\n    ", ";\n  margin-left: auto;\n  transition: ", ";\n\n  &:hover {\n    cursor: pointer;\n    color: ", ";\n  }\n"])), function (props) {
    return props.hasColor ? colors.accent_color : 'none';
}, function (props) {
    return props.hasColor ? colors.white : colors.off_white_border;
}, function (props) { return (props.hasColor ? 'none' : colors.off_white_border); }, transitions.normal, function (props) { return (props.hasColor ? '' : colors.accent_color); });
var SettingBox = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  padding-bottom: 10px;\n"], ["\n  padding-bottom: 10px;\n"])));
var ContentText = styled.p(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  font-size: ", ";\n  color: ", ";\n  word-wrap: break-word;\n"], ["\n  font-size: ", ";\n  color: ", ";\n  word-wrap: break-word;\n"])), function (props) { return (props.fontSize ? props.fontSize + "px" : 0); }, function (props) { return props.color; });
var ProfileName = styled(ContentText)(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  align-self: flex-start;\n  padding-top: 5px;\n"], ["\n  align-self: flex-start;\n  padding-top: 5px;\n"])));
var InboxContainer = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  border-right: 1px solid ", ";\n  text-align: center;\n  color: ", ";\n  width: 20%;\n  height: 100%;\n"], ["\n  border-right: 1px solid ", ";\n  text-align: center;\n  color: ", ";\n  width: 20%;\n  height: 100%;\n"])), colors.off_white_border, colors.header_font);
var UserStatsContainer = styled(InboxContainer)(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  overflow: auto;\n  width: 22%;\n  border-right: 0;\n  border-left: 1px solid ", ";\n  display: flex;\n  flex-direction: column;\n"], ["\n  overflow: auto;\n  width: 22%;\n  border-right: 0;\n  border-left: 1px solid ", ";\n  display: flex;\n  flex-direction: column;\n"])), colors.off_white_border);
var UserInformationWrapper = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  padding: 0 10px;\n  border-bottom: 1px solid ", ";\n"], ["\n  padding: 0 10px;\n  border-bottom: 1px solid ", ";\n"])), colors.off_white_border);
var UserInformationContainer = styled.div(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  ", ";\n\n  span {\n    display: flex;\n    align-items: baseline;\n    justify-content: space-between;\n  }\n"], ["\n  ", ";\n\n  span {\n    display: flex;\n    align-items: baseline;\n    justify-content: space-between;\n  }\n"])), centeredFlex);
var MemoWrapper = styled.div(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  width: 100%;\n  padding: 16px;\n  border-top: 1px solid #d1d7ed;\n  .memoTitle {\n    margin-bottom: 8px;\n    text-align: start;\n  }\n  .memoTextArea {\n    width: 100%;\n    color: #000;\n    padding: 16px;\n    background: ", ";\n    border-radius: 8px;\n    border: ", ";\n  }\n"], ["\n  width: 100%;\n  padding: 16px;\n  border-top: 1px solid #d1d7ed;\n  .memoTitle {\n    margin-bottom: 8px;\n    text-align: start;\n  }\n  .memoTextArea {\n    width: 100%;\n    color: #000;\n    padding: 16px;\n    background: ", ";\n    border-radius: 8px;\n    border: ", ";\n  }\n"])), colors.background.off_white_background, function (props) { return (!props.isEditing ? 'unset' : '1px solid #d1d7ed'); });
var InflowDate = styled.div(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  padding: 1rem 1rem 0;\n  text-align: left;\n  font-size: 0.875rem;\n\n  .inflow-label {\n    color: ", ";\n  }\n\n  .inflow-date {\n    color: ", ";\n  }\n"], ["\n  padding: 1rem 1rem 0;\n  text-align: left;\n  font-size: 0.875rem;\n\n  .inflow-label {\n    color: ", ";\n  }\n\n  .inflow-date {\n    color: ", ";\n  }\n"])), colors.text_pale, colors.default_text);
var UserStats = function (_a) {
    var isSampleData = _a.isSampleData, isLoadingUser = _a.isLoadingUser, chatbotId = _a.chatbotId, endUserId = _a.endUserId, activeEndUser = _a.activeEndUser, updateEndUser = _a.updateEndUser, addUserTag = _a.addUserTag, deleteUserTag = _a.deleteUserTag, updateEndUserDetails = _a.updateEndUserDetails;
    var _b = __read(useState(activeEndUser === null || activeEndUser === void 0 ? void 0 : activeEndUser.memo), 2), memoValue = _b[0], setMemoValue = _b[1];
    var _c = __read(useState(false), 2), isEditing = _c[0], setIsEditing = _c[1];
    var _d = __read(useState(false), 2), isMemoFetching = _d[0], setIsMemoFetching = _d[1];
    var prevMemoValue = usePrevious((activeEndUser === null || activeEndUser === void 0 ? void 0 : activeEndUser.memo) || '');
    useEffect(function () {
        if (!isEmpty(activeEndUser)) {
            setMemoValue(activeEndUser.memo);
        }
    }, [activeEndUser]);
    var handleCreationOfTag = function (name) {
        var userId = activeEndUser === null || activeEndUser === void 0 ? void 0 : activeEndUser.id;
        if (!userId)
            return null;
        var isNameBlank = name === '';
        var isNameTaken = activeEndUser.tags.map(function (t) { return t.name; }).indexOf(name) > -1;
        if (!isNameBlank && !isNameTaken) {
            addUserTag({
                userId: userId,
                chatbotId: chatbotId,
                name: name,
            });
        }
    };
    var handleDeleteTag = function (e, tag) {
        e.stopPropagation();
        deleteUserTag({
            userId: activeEndUser.id,
            chatbotId: chatbotId,
            tag: tag,
        });
    };
    var handleSubmitMemo = function () { return __awaiter(void 0, void 0, void 0, function () {
        var memo, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, 3, 4]);
                    setIsMemoFetching(true);
                    return [4 /*yield*/, memoApi.updateMemo({
                            chatbotId: chatbotId,
                            endUserId: endUserId,
                            text: memoValue,
                        })];
                case 1:
                    memo = _a.sent();
                    updateEndUserDetails(__assign(__assign({}, activeEndUser), { memo: memo }));
                    return [3 /*break*/, 4];
                case 2:
                    e_1 = _a.sent();
                    handleAPIErrors(e_1);
                    return [3 /*break*/, 4];
                case 3:
                    setIsMemoFetching(false);
                    return [7 /*endfinally*/];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var renderMemo = function () {
        if (isMemoFetching)
            return React.createElement(Spinner, null);
        return (React.createElement(MemoWrapper, { "data-testid": "memoWrapper", isEditing: isEditing },
            React.createElement("div", { className: "memoTitle" }, "\u30E1\u30E2"),
            React.createElement(TextareaAutosize, { "data-testid": "memoTextArea", className: "memoTextArea", minRows: 8, maxRows: 10, disabled: !isEditing, value: memoValue || '', onChange: function (e) { return setMemoValue(e.target.value); } }),
            isEditing ? (React.createElement(ButtonSet, { margin: "16px 0 0 auto", buttonProps: {
                    text: 'キャンセル',
                    type: buttonTypes.CANCEL,
                }, button2Props: {
                    text: '保存',
                    type: buttonTypes.SAVE,
                }, onConfirm: function () { return handleSubmitMemo(); }, onCancel: function () {
                    setMemoValue(prevMemoValue);
                    setIsEditing(false);
                } })) : (React.createElement(Button, { text: "\u30E1\u30E2\u7DE8\u96C6", width: 120, buttonType: buttonTypes.MAIN, margin: "16px 0 0 auto", onClick: function () { return setIsEditing(true); } }))));
    };
    return (React.createElement(UserStatsContainer, null, Object.keys(activeEndUser).length === 0 || isLoadingUser ? (React.createElement(Spinner, null)) : (React.createElement("div", { style: { height: '100%' } },
        React.createElement(UserInformationWrapper, null,
            React.createElement(UserInformationContainer, null,
                React.createElement(ProfilePic, { "data-testid": "userPicture", imageUrl: activeEndUser.profile_photo_url, gender: activeEndUser.gender, height: "60px", width: "60px", minWidth: "60px", margin: "25px 25px 0 0" }),
                React.createElement(ProfileNameWrapper, null,
                    React.createElement(ProfileName, { "data-testid": "userName", fontSize: 20, color: colors.main_color }, activeEndUser.last_name + " " + activeEndUser.first_name)),
                React.createElement(ImportantLabel, { hasColor: activeEndUser.important, onClick: function () {
                        return updateEndUser({
                            important: !activeEndUser.important,
                        }, chatbotId, activeEndUser.id);
                    }, "data-testid": "importantTicket" }, "\u91CD\u8981"),
                activeEndUser.blocked_at && (React.createElement(ImportantLabel, { "data-testid": "userBlockedAt", hasColor: true, onClick: function () {
                        return alert("\u30E6\u30FC\u30B6\u30FC\u304C" + activeEndUser.blocked_at + "\u306B\u30D6\u30ED\u30C3\u30AF\u3057\u307E\u3057\u305F\u3002");
                    } }, "\u30D6\u30ED\u30C3\u30AF"))),
            React.createElement(UserInformationContainer, null,
                React.createElement(SettingBox, null,
                    React.createElement(ContentText, { "data-testid": "autoReply", fontSize: 12, color: colors.text_pale }, "\u81EA\u52D5\u8FD4\u7B54"),
                    React.createElement("div", null,
                        React.createElement("input", { "data-testid": "autoReplyInput", className: activeEndUser.auto_reply ? '' : 'off', type: "range", min: "0", max: "1", style: { cursor: 'pointer' }, value: activeEndUser.auto_reply ? 1 : 0, onChange: function (e) {
                                isSampleData
                                    ? null
                                    : updateEndUser({
                                        auto_reply: !activeEndUser.auto_reply,
                                    }, chatbotId, activeEndUser.id);
                            } })))),
            React.createElement(UserInfo, null,
                React.createElement("span", { className: "platform", "data-testid": "userPlatform" }, "Platform: " + (activeEndUser.platform && capitalize(activeEndUser.platform))),
                activeEndUser.blocked_at ? (React.createElement("span", { "data-testid": "userBlockedAtTime", className: "blocked" }, "Blocked At: " + activeEndUser.blocked_at)) : null)),
        React.createElement(InflowDate, null,
            React.createElement("span", { className: "inflow-label" }, "\u6D41\u5165\u65E5\uFF1A"),
            React.createElement("span", { "data-testid": "userCreatedAt", className: "inflow-date" }, moment(activeEndUser.created_at).format('YYYY/MM/DD'))),
        React.createElement(UserStatsBottom, null,
            React.createElement(TagList, { user: activeEndUser, handleCreationOfTag: handleCreationOfTag, handleDeleteTag: handleDeleteTag, disableMaxHeight: true })),
        renderMemo()))));
};
export default injectIntl(UserStats);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13;
