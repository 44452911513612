var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import keyBy from 'lodash/keyBy';
import { colors } from '@src/colors';
import { filterObjectsByText } from '@src/lib/helpers';
import SearchBox from '@src/components/atoms/SearchBox';
import FilterDetail from '@src/components/molecules/FilterDetail';
import { deleteMessageFilter, handleOpenFilterForm, selectMessageFilter, resetDoneStatus, } from '@src/redux/fanp/actions/messageFilter';
import { injectIntl } from 'react-intl';
var FilterListWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  padding-top: 20px;\n\n  .adicon_search {\n    height: 40px;\n    border-bottom-width: 0px;\n    background-color: ", ";\n    border-radius: 5px;\n  }\n\n  .filter_list {\n    margin-top: 5px;\n    height: calc(100vh - 350px);\n    overflow-y: auto;\n\n    > div {\n      position: relative;\n\n      > div:first-of-type {\n        position: absolute;\n        height: 52px;\n        width: 60%;\n        cursor: pointer;\n        z-index: 1;\n      }\n\n      &:hover {\n        > div:nth-child(2) {\n          background-color: ", ";\n\n          .adicon_arrow_down,\n          .adicon_arrow_up,\n          .adicon_other {\n            &:before {\n              color: ", " !important;\n            }\n          }\n        }\n      }\n    }\n  }\n\n  .no_results {\n    color: rgb(9, 31, 108, 0.5);\n    font-size: 14px;\n  }\n\n  div {\n    outline: none;\n  }\n"], ["\n  width: 100%;\n  padding-top: 20px;\n\n  .adicon_search {\n    height: 40px;\n    border-bottom-width: 0px;\n    background-color: ", ";\n    border-radius: 5px;\n  }\n\n  .filter_list {\n    margin-top: 5px;\n    height: calc(100vh - 350px);\n    overflow-y: auto;\n\n    > div {\n      position: relative;\n\n      > div:first-of-type {\n        position: absolute;\n        height: 52px;\n        width: 60%;\n        cursor: pointer;\n        z-index: 1;\n      }\n\n      &:hover {\n        > div:nth-child(2) {\n          background-color: ", ";\n\n          .adicon_arrow_down,\n          .adicon_arrow_up,\n          .adicon_other {\n            &:before {\n              color: ", " !important;\n            }\n          }\n        }\n      }\n    }\n  }\n\n  .no_results {\n    color: rgb(9, 31, 108, 0.5);\n    font-size: 14px;\n  }\n\n  div {\n    outline: none;\n  }\n"])), colors.background.light_purple, colors.background.off_white_background, colors.default_text);
var EmptyListWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  padding-top: 40px;\n  color: ", ";\n  text-align: center;\n  width: 100%;\n\n  .adicon_filter {\n    font-size: 80px;\n  }\n\n  > div:nth-child(2) {\n    padding-top: 20px;\n    font-size: 14px;\n    line-height: 28px;\n    font-weight: 700;\n  }\n"], ["\n  padding-top: 40px;\n  color: ", ";\n  text-align: center;\n  width: 100%;\n\n  .adicon_filter {\n    font-size: 80px;\n  }\n\n  > div:nth-child(2) {\n    padding-top: 20px;\n    font-size: 14px;\n    line-height: 28px;\n    font-weight: 700;\n  }\n"])), colors.background.hover_purple);
var FilterList = function (_a) {
    var chatTypeParam = _a.chatTypeParam, chatbotId = _a.chatbotId, setIsFocusTag = _a.setIsFocusTag, lastSuccessMessage = _a.lastSuccessMessage, lastSelectedFilterId = _a.lastSelectedFilterId, intl = _a.intl;
    var messageFilters = useSelector(function (_a) {
        var messageFilter = _a.messageFilter;
        return messageFilter.messageFilters;
    });
    var attribute_answers = useSelector(function (_a) {
        var messageFilter = _a.messageFilter;
        return messageFilter.attribute_answers;
    });
    var attributeQuestions = messageFilters &&
        keyBy(messageFilters
            .map(function (_a) {
            var quick_filters = _a.quick_filters;
            return quick_filters === null || quick_filters === void 0 ? void 0 : quick_filters.map(function (_a) {
                var attribute_question_id = _a.attribute_question_id, attribute_question_label = _a.attribute_question_label;
                return ({
                    id: attribute_question_id,
                    label: attribute_question_label,
                });
            });
        })
            .flat(), 'id');
    var blocks = useSelector(function (_a) {
        var present = _a.adScenario.present;
        return present.blocks;
    });
    var scenarios = useSelector(function (_a) {
        var present = _a.adScenario.present;
        return present.scenarios;
    });
    var dispatch = useDispatch();
    var selectFilter = function (id, isFocusTag) {
        setIsFocusTag(isFocusTag || false);
        dispatch(selectMessageFilter(id));
        dispatch(resetDoneStatus());
    };
    var deleteFilter = function (filterId) {
        dispatch(deleteMessageFilter(chatbotId, filterId));
        dispatch(handleOpenFilterForm(false));
    };
    var _b = __read(useState(''), 2), searchText = _b[0], setSearchText = _b[1];
    var _c = __read(useState(false), 2), changeSearchText = _c[0], setChangeSearchText = _c[1];
    // this flag for focus filter after created or update, if any change on search
    // text, no need to focus
    var _d = __read(useState(0), 2), openMenuFilterId = _d[0], setOpenMenuFilterId = _d[1];
    useEffect(function () { return setChangeSearchText(true); }, [searchText]);
    var filterListRef = useRef(null);
    var filteredFilters = filterObjectsByText(messageFilters, 'name', searchText || '');
    // eslint-disable-next-line react/no-unstable-nested-components
    var EmptyList = function () { return (React.createElement(EmptyListWrapper, { "data-testid": "FilterList_EmptyList" },
        React.createElement("div", { className: "adicon_filter" }),
        React.createElement("div", null,
            intl.formatMessage({ id: "l1njOA", defaultMessage: "No Filter !" }),
            React.createElement("br", null),
            intl.formatMessage({ id: "y5zDGQ", defaultMessage: "Please add a Filter with the button above." })))); };
    if (!(messageFilters === null || messageFilters === void 0 ? void 0 : messageFilters.length))
        return React.createElement(EmptyList, null);
    return (React.createElement(FilterListWrapper, { "data-testid": "FilterList_FilterListWrapper" },
        React.createElement(SearchBox, { searchText: searchText, placeholder: intl.formatMessage({ id: "nEe21B", defaultMessage: "Search Filter Name" }), onSearchTextChange: setSearchText }),
        React.createElement("div", { className: "filter_list", ref: filterListRef, "data-testid": "FilterList_filterList" }, filteredFilters.length ? (filteredFilters === null || filteredFilters === void 0 ? void 0 : filteredFilters.map(function (filter, index) { return (React.createElement(FilterDetail, { needFocus: 
            // it will be true if just created and index is the last
            // or just updated and filter id is lastSelectedFilterId
            !changeSearchText &&
                ((lastSuccessMessage === 'create' &&
                    index === filteredFilters.length - 1) ||
                    (lastSuccessMessage === 'update' &&
                        filter.id === lastSelectedFilterId)), chatTypeParam: chatTypeParam, chatbotId: chatbotId, key: filter.id, filter: filter, blocks: blocks, scenarios: scenarios, attributeAnswers: attribute_answers, attributeQuestions: attributeQuestions, openMenuFilterId: openMenuFilterId, setOpenMenuFilterId: setOpenMenuFilterId, selectFilter: function (isFocusTag) { return selectFilter(filter.id, isFocusTag); }, deleteFilter: function () { return deleteFilter(filter.id); }, filterListRef: filterListRef === null || filterListRef === void 0 ? void 0 : filterListRef.current })); })) : (React.createElement("div", { className: "no_results" }, intl.formatMessage({ id: "i8CMOk", defaultMessage: "No Results were found" }))))));
};
export default injectIntl(FilterList);
var templateObject_1, templateObject_2;
