export var MSG_MARGIN = 3;
export var MSG_PADDING = 6;
export var MESSAGE_CONTAINER_DEFAULT_X = -54;
export var GENERIC_ITEM_IMAGE_HEIGHT = 60;
export var GENERIC_ITEM_WIDTH = 100;
export var GENERIC_ITEM_MARGIN = 10;
export var LIST_ITEM_IMAGE_HEIGHT = 60;
export var LIST_ITEM_WIDTH = 100;
export var LIST_ITEM_MARGIN = 10;
export var FILE_HEIGHT = 100;
export var BLOCK_WIDTH = 100;
export var BLOCK_MSG_MARGIN = 12;
export var BUTTON_PADDING = 6;
export var BUTTON_HEIGHT = 15;
export var MSG_QUICK_MARGIN = 10;
export var CHOICE_HEIGHT = 15;
export var CHOICE_WIDTH = 80;
export var CHOICE_MARGIN = 3;
export var CHOICE_PADDING = 6;
export var DEFAULT_X_POS = -(BLOCK_WIDTH / 2);
export var BORDER_RADIUS = 8;
export var FONT_SIZE = 9;
export var STANDARD_NAME_LENGTH = 10;
export var NODE_DISTANCE_Y = 600;
export var LINK_DISTANCE_Y_MOD = 100;
export var CHOICE_DISTANCE = 80;
export var QUICK_LINK_OPACITY = 0.8;
export var BUTTON_LINK_OPACITY = 0.4;
export var MAX_TITLE_SIZE = 17;
export var MESSAGE_CONTAINER_OPACITY = 0.2;
export var BASE_COLLIDE_DISTANCE = 50;
export var BASE_BLOCK_DISTANCE = 360;
export var PADDING_FOR_BUTTON_LINKS = -4;
export var DEFAULT_STRENGTH = 1.2;
export var TALK_TRIGGER_MARGIN = 20;
export var BASE_URL_SIZE = 23; // image map - rich menu - line flex

export var DEFAULT_IMAGE_SIZE = 100;
export var DEFAULT_IMAGE_MARGIN_X = 10;
export var DEFAULT_IMAGE_MARGIN_Y = 4;
export var IMAGE_TEXT_PADDING_X = 4;
export var IMAGE_TEXT_PADDING_Y = 4;
export var IMAGE_TEXT_BOX_HEIGHT = 12;
export var DEFAULT_IMAGE_TEXT_LENGTH = 20;
export var DEFAULT_TEXT_BOX_MARGIN = 12;
export var LINE_FLEX_TEXT_Y = 25;
export var LINE_FLEX_CONTAINER_HEIGHT = 30;
export var LINE_FLEX_ITEM_WIDTH = 100;
export var LINE_FLEX_ITEM_MARGIN = 10;
export var LINE_FLEX_ITEM_IMAGE_HEIGHT = 60;
export var LINE_FLEX_BUTTON_RADIUS = 3;
export var LINE_FLEX_PREVIEW_RATIOS = 3;
export var LINE_FLEX_MSG_PADDING = 2;
export var LINE_FLEX_BUTTON_PADDING = 4;
export var LINE_FLEX_MSG_HEIGHT = 10;
export var MEDIA_NAME_LENGTH = 70;
export var VIDEO_ICON_SIZE = 10;
export var VIDEO_ICON_PADDING = 7;
export var AUDIO_ICON_SIZE = 30;
export var RICH_MENU_BLOCK_SIZE = 150;
export var IMAGE_MAP_BLOCK_SIZE = 150;
export var IMAGE_MAP_MAP_TEMPLATE_ID = 34;