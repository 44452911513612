var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import { InputWrapper } from '@src/components/atoms';
import { colors } from '@src/colors';
var GeneralPropertyContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  margin: 20px 0;\n  label {\n    flex: 1;\n    font-weight: bold;\n    font-size: 16px;\n    color: ", ";\n  }\n  > div {\n    flex: 3;\n  }\n  .fix-top {\n    padding-top: 8px;\n    display: flex;\n    align-self: flex-start;\n  }\n\n  .adicon_arrow_down_after::after {\n    margin-right: 5px;\n  }\n"], ["\n  display: flex;\n  align-items: center;\n  margin: 20px 0;\n  label {\n    flex: 1;\n    font-weight: bold;\n    font-size: 16px;\n    color: ", ";\n  }\n  > div {\n    flex: 3;\n  }\n  .fix-top {\n    padding-top: 8px;\n    display: flex;\n    align-self: flex-start;\n  }\n\n  .adicon_arrow_down_after::after {\n    margin-right: 5px;\n  }\n"])), colors.custom_form_admin.main_color);
var TwoColumnWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  justify-content: space-between;\n"], ["\n  display: flex;\n  justify-content: space-between;\n"])));
var StepQuestionWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  width: 48%;\n  display: flex;\n  flex-direction: column;\n"], ["\n  width: 48%;\n  display: flex;\n  flex-direction: column;\n"])));
var StepQuestion = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  height: 38px;\n  margin-bottom: 10px;\n  padding-left: 10px;\n  display: flex;\n  align-items: center;\n  font-size: 14px;\n  justify-content: space-between;\n  background-color: rgba(58, 69, 81, 0.2);\n  border-radius: 5px;\n  p {\n    display: flex;\n    align-items: center;\n  }\n  div {\n    display: flex;\n    align-items: center;\n  }\n"], ["\n  height: 38px;\n  margin-bottom: 10px;\n  padding-left: 10px;\n  display: flex;\n  align-items: center;\n  font-size: 14px;\n  justify-content: space-between;\n  background-color: rgba(58, 69, 81, 0.2);\n  border-radius: 5px;\n  p {\n    display: flex;\n    align-items: center;\n  }\n  div {\n    display: flex;\n    align-items: center;\n  }\n"])));
var StepQuestionButton = styled.button(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  width: 20px;\n  height: 20px;\n  margin-right: 10px;\n  border-radius: 50%;\n  text-align: center;\n  color: ", ";\n  background-color: rgba(58, 69, 81, 0.2);\n  cursor: pointer;\n"], ["\n  width: 20px;\n  height: 20px;\n  margin-right: 10px;\n  border-radius: 50%;\n  text-align: center;\n  color: ", ";\n  background-color: rgba(58, 69, 81, 0.2);\n  cursor: pointer;\n"])), colors.custom_form_admin.main_color);
var GeneralSelect = function (_a) {
    var name = _a.name, id = _a.id, options = _a.options, value = _a.value, onChange = _a.onChange, isConnectQuestion = _a.isConnectQuestion, removeStepQuestion = _a.removeStepQuestion, custom_form_questions = _a.custom_form_questions, headlessPage = _a.headlessPage, onFocus = _a.onFocus;
    return (React.createElement(GeneralPropertyContainer, null, isConnectQuestion ? (React.createElement(React.Fragment, null,
        React.createElement("label", { className: "fix-top", htmlFor: "general-select" }, name),
        React.createElement(TwoColumnWrapper, null,
            React.createElement(InputWrapper, { width: "48%", margin: "0", borderRadius: "5px", className: "adicon_arrow_down_after" },
                React.createElement("select", { value: value || '', onChange: onChange, onFocus: onFocus, "data-testid": "GeneralSelect-select" },
                    React.createElement("option", { value: "", label: " " }),
                    options.map(function (option) {
                        return typeof option === 'string' ? (React.createElement("option", { key: id + "-" + option }, option)) : (React.createElement("option", { key: id + "-" + option[0], value: option[0] }, option[1]));
                    }))),
            React.createElement(StepQuestionWrapper, null, custom_form_questions.length ? (custom_form_questions.map(function (question) { return (React.createElement(StepQuestion, { key: question.id },
                React.createElement("p", null,
                    question.id,
                    "\uFF1A ",
                    question.label),
                React.createElement("div", null,
                    React.createElement(StepQuestionButton, { className: "adicon_minus02", onClick: function () {
                            return removeStepQuestion(id, headlessPage, {
                                custom_form_question_id: question.id,
                            });
                        } })))); })) : (React.createElement(StepQuestion, null,
                React.createElement("p", null, "Question\u304C\u63A5\u7D9A\u3057\u3066\u3044\u307E\u305B\u3093"))))))) : (React.createElement(React.Fragment, null,
        React.createElement("label", { htmlFor: "option" }, name),
        React.createElement(InputWrapper, { borderRadius: "5px", className: "adicon_arrow_down_after" },
            React.createElement("select", { value: value || '', onChange: onChange, "data-testid": "GeneralSelect-select" },
                React.createElement("option", { label: " ", value: "" }),
                options.map(function (option) {
                    return typeof option === 'string' ? (React.createElement("option", { key: id + "-" + option }, option)) : (React.createElement("option", { key: id + "-" + option[0], value: option[0] }, option[1]));
                })))))));
};
export default GeneralSelect;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
