var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import { centeredFlex, columnFlex } from '@src/lib/mixins';
var pngAlert = require('@src/assets/images/password-alert.svg');
var ErrorContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  height: 50vh;\n  img {\n    width: 10%;\n  }\n"], ["\n  ", ";\n  ", ";\n  height: 50vh;\n  img {\n    width: 10%;\n  }\n"])), centeredFlex, columnFlex);
var ErrorScreen = function () { return (React.createElement(ErrorContainer, null,
    React.createElement("img", { src: pngAlert, className: "alert-img" }),
    React.createElement("p", null, "An unhandled exception occured.. Please refresh the browser."))); };
export default ErrorScreen;
var templateObject_1;
