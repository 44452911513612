import "core-js/modules/es.string.replace";
import doesScenarioHaveErrors from './doesScenarioHaveErrors';
import getSelectedBlock from './getSelectedBlock';
import arrToObj from './arrToObj';
import copyToClipboard from './copyToClipboard';
import escapeAllSpecialChars from './escapeAllSpecialChars';
import getDifferenceOfObjects from './getDifferenceOfObjects';
import messagesFromBlock from './messagesFromBlock';
import objToQuery from './objToQuery';
import { makeJpFormattedDateStr } from './dateFuncs';
import { makeAjaxHeaders, makeFetchWebChat, makeFetchInit, handleAPIErrors, fanpFetch, getCookie, generalApiFn } from './ajaxFuncs';
import { getCurrentTarget, getInitialGroups, getCurrentCampaign, getCurrentContent, getCurrentQuickFilter, removeHasError, filterRecordsBySearch } from './retentionHelpers';
import { validateEmailAddress, validatePassword } from './validate';
import { getNextConnections } from './getNextConnections';
import getErrorList from './getErrorList';
import getDataTypes from './getDataTypes';
import { convertToSpans, convertFromSpans, retrieveContentLineFromBlock } from './draftJs';
import { getSpanStyle, defaultStyles, fontSizes } from './getSpanStyle';
import { createInitialUrlValue } from './createInitialUrlValue';
import { mediaSchema, mediaFileSchema, mediaFilesSchema, addMaxLength } from './formSchema';
import { toDoubleDigits, ONE_DAY_FOR_MINUTES, ONE_DAY, MINUTES } from './trackPushes';
import { mediaUploadValidation } from './mediaUploadValidation';
import camelToSpaced from './camelToSpaced';
import { convertHexToEmoji } from './emojiConvert';
import { isAllowAddMessage, messageSectionsInBlockCount, isExceedMaximumMessageCount } from './numberMessagesInBlockCaculator';
import scenarioLayerBlockStateWithNewScenarioProperties from './scenarioLayerBlockStateWithNewScenarioProperties';
import filterObjectsByText from './filterObjectsByText';
import { removeSmartReplaceFromString, splice } from './smartReplace';
import { styledTemplate, customImageMapTemplateFrame } from './styledTemplate';
import { sortAreaTemplate } from './sortAreaTemplate';
import getInitTimeShotDelivery from './getInitTimeShotDelivery';
import { displayFlash } from './flash';
import buildElements from './buildElements';
import changeItem from './changeItem';
import draftRendering from './draftRendering';
import removeDuplicateBlocks from './removeDuplicateBlocks';
import convertToScenarioData from './convertToScenarioData';
import { getFlex, aspectRatioToPercent } from './styledLineFlexMessage';
import getLineConnectionIds from './getLineConnectionIds';
import snakeToCamel from './snakeToCamel';
import { convertToDefaultRichMenus, convertToLineRichMenus, buildDefaultRichMenu, fetchImageWithAuthorization, getRichMenuId, getNewDefaultRichMenu } from './defaultRichMenu';

var snakeToLower = function snakeToLower(s) {
  return s.replace(/_/g, ' ').toLowerCase();
};

export { camelToSpaced, snakeToCamel, copyToClipboard, displayFlash, makeFetchInit, makeFetchWebChat, fanpFetch, getCookie, generalApiFn, getDifferenceOfObjects, doesScenarioHaveErrors, getSelectedBlock, getNextConnections, getErrorList, handleAPIErrors, arrToObj, escapeAllSpecialChars, makeAjaxHeaders, makeJpFormattedDateStr, objToQuery, getDataTypes, convertToSpans, convertFromSpans, retrieveContentLineFromBlock, getSpanStyle, defaultStyles, fontSizes, messagesFromBlock, validateEmailAddress, validatePassword, mediaSchema, mediaFileSchema, mediaFilesSchema, mediaUploadValidation, addMaxLength, convertHexToEmoji, scenarioLayerBlockStateWithNewScenarioProperties, toDoubleDigits, ONE_DAY_FOR_MINUTES, ONE_DAY, MINUTES, getCurrentTarget, getInitialGroups, getCurrentCampaign, getCurrentContent, getCurrentQuickFilter, removeHasError, filterRecordsBySearch, isAllowAddMessage, messageSectionsInBlockCount, isExceedMaximumMessageCount, filterObjectsByText, removeSmartReplaceFromString, splice, styledTemplate, sortAreaTemplate, customImageMapTemplateFrame, createInitialUrlValue, getInitTimeShotDelivery, buildElements, changeItem, draftRendering, removeDuplicateBlocks, convertToScenarioData, getFlex, aspectRatioToPercent, getLineConnectionIds, snakeToLower, convertToDefaultRichMenus, convertToLineRichMenus, buildDefaultRichMenu, fetchImageWithAuthorization, getRichMenuId, getNewDefaultRichMenu };