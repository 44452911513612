var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import { colors } from '@src/colors';
var TalkTriggerContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin: 20px 10px;\n  border-radius: 16px;\n  width: 160px;\n  padding: 8px;\n  text-align: center;\n  color: ", ";\n  background: ", ";\n"], ["\n  margin: 20px 10px;\n  border-radius: 16px;\n  width: 160px;\n  padding: 8px;\n  text-align: center;\n  color: ", ";\n  background: ", ";\n"])), colors.white, colors.background.setting);
var TalkTriggerNode = function (_a) {
    var _b = _a.data, talkTrigger = _b.talkTrigger, updateBoundaryNode = _b.updateBoundaryNode;
    return (React.createElement(TalkTriggerContainer, { ref: function (ref) {
            if (!ref || !updateBoundaryNode || !talkTrigger.postback_action)
                return;
            updateBoundaryNode('postbackActions', talkTrigger.postback_action.id, ref.getBoundingClientRect());
        } }, "\u30E6\u30FC\u30B6\u30FC\u81EA\u7531\u5165\u529B"));
};
export default TalkTriggerNode;
var templateObject_1;
