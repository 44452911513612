import axios from 'axios';
import isObject from 'lodash/isObject';
var instance = axios.create({
    baseURL: process.env.FANP_DOMAIN,
    withCredentials: true,
});
instance.defaults.headers.common['Accept'] = 'application/json';
instance.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
instance.interceptors.response.use(function (response) {
    var contentType = response.headers['content-type'];
    // Handle non-json serializable responses
    if (!(contentType && contentType.toLowerCase().includes('application/json'))) {
        throw new Error('Response not processable');
    }
    return response;
}, function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
});
export function handleAPIErrors(e, cb) {
    var _a, _b, _c, _d, _e;
    console.error(e);
    var hasMessage = ((_b = (_a = e === null || e === void 0 ? void 0 : e.response) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.message) && typeof e.response.data.message === 'string';
    var hasErrors = ((_d = (_c = e === null || e === void 0 ? void 0 : e.response) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d.errors) && isObject(e.response.data.errors);
    var apiError = hasMessage && hasErrors
        ? (_e = e === null || e === void 0 ? void 0 : e.response) === null || _e === void 0 ? void 0 : _e.data
        : { message: 'エラーが発生しました', errors: {} };
    if (cb)
        cb(apiError);
    return apiError;
}
export default instance;
