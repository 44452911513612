var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React, { Fragment, memo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Handle, Position } from 'react-flow-renderer';
import styled from 'styled-components';
import { handleChangeHoveredPostbackAction } from '@src/redux/fanp/actions/scenarioMap';
var PostbackActionContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: ", ";\n  height: ", ";\n  cursor: ", ";\n\n  > div {\n    z-index: 1001;\n    position: absolute;\n    width: ", ";\n    height: ", ";\n  }\n"], ["\n  width: ", ";\n  height: ", ";\n  cursor: ", ";\n\n  > div {\n    z-index: 1001;\n    position: absolute;\n    width: ", ";\n    height: ", ";\n  }\n"])), function (props) { return props.width || 'unset'; }, function (props) { return props.height || 'unset'; }, function (props) { return (props.clickable ? 'pointer' : 'unset'); }, function (props) { return props.width || 'unset'; }, function (props) { return props.height || 'unset'; });
var PostbackActionNode = function (_a) {
    var _b = _a.data, postbackAction = _b.postbackAction, _c = _b.style, style = _c === void 0 ? {} : _c;
    var dispatch = useDispatch();
    var hoveredPostbackAction = useSelector(function (_a) {
        var scenarioMap = _a.scenarioMap;
        return scenarioMap.hoveredPostbackAction;
    });
    var hasConnection = Boolean((postbackAction === null || postbackAction === void 0 ? void 0 : postbackAction.scenario_id) || (postbackAction === null || postbackAction === void 0 ? void 0 : postbackAction.block_id));
    return (React.createElement(Fragment, { key: "postback-action-" + postbackAction.id },
        React.createElement(PostbackActionContainer, { width: style.width, height: style.height, clickable: Boolean(!hasConnection && postbackAction.url), onClick: function () {
                if (!hasConnection && postbackAction.url)
                    window.open(postbackAction.url, '_blank');
            }, "data-testid": "PostbackActionNode_PostbackActionContainer" },
            React.createElement("div", { "data-testid": "PostbackActionNode_HoverTarget", onMouseEnter: function () {
                    if (!hasConnection)
                        return;
                    dispatch(handleChangeHoveredPostbackAction(postbackAction));
                }, onMouseLeave: function () {
                    if (!hasConnection ||
                        postbackAction.id !== (hoveredPostbackAction === null || hoveredPostbackAction === void 0 ? void 0 : hoveredPostbackAction.id))
                        return;
                    dispatch(handleChangeHoveredPostbackAction(null));
                } })),
        React.createElement(Handle, { type: "source", id: "bottom", position: Position.Bottom, style: { bottom: -3 } })));
};
export default memo(PostbackActionNode);
var templateObject_1;
