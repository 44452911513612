var collection = function () { return "/users"; };
var emailUser = function () { return collection() + "/login"; };
var fb = function () { return collection() + "/login/facebook"; };
var password = function () { return collection() + "/password"; };
export default {
    collection: collection,
    emailUser: emailUser,
    fb: fb,
    password: password,
};
