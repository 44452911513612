var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useMemo } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import queryStringToQueryObj from '@src/lib/queryStringToQueryObj';
export var useHOCWithRouterLocation = function () {
    var location = useLocation();
    var query = queryStringToQueryObj(location.search);
    var browserLocation = useMemo(function () {
        return __assign(__assign({}, location), { query: query });
    }, [location, query]);
    return browserLocation;
};
var HOCWithRouter = function (OldClassComponent) {
    return function (props) {
        if (props === void 0) { props = {}; }
        var params = useParams();
        var browserHistory = useHistory();
        var browserLocation = useHOCWithRouterLocation();
        var allProps = {
            params: params,
            browserHistory: browserHistory,
            location: browserLocation,
        };
        return React.createElement(OldClassComponent, __assign({}, allProps, props));
    };
};
export default HOCWithRouter;
