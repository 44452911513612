import "core-js/modules/es.symbol";
import "core-js/modules/es.array.concat";
import "core-js/modules/es.array.filter";
import "core-js/modules/es.array.find";
import "core-js/modules/es.array.iterator";
import "core-js/modules/es.array.map";
import "core-js/modules/es.array.slice";
import "core-js/modules/es.function.name";
import "core-js/modules/es.object.get-own-property-descriptor";
import "core-js/modules/es.object.get-own-property-descriptors";
import "core-js/modules/es.object.keys";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.object.values";
import "core-js/modules/web.dom-collections.for-each";
import "core-js/modules/web.dom-collections.iterator";
import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import sortBy from 'lodash/sortBy';

var getBlockFromScenarioId = function getBlockFromScenarioId(state, scenarioId) {
  var blocks = state.blocks;
  var block = Object.values(blocks).find(function (block) {
    return block.scenario_id === scenarioId;
  });
  return block.id;
};

var updateGenericItemRank = function updateGenericItemRank(genericItem, action) {
  if (genericItem.id === action.resourceItemId) {
    return _objectSpread({}, genericItem, {
      rank: action.resourceItemRank
    });
  }

  if (genericItem.id === action.targetItemId) {
    return _objectSpread({}, genericItem, {
      rank: action.targetItemRank
    });
  }

  return genericItem;
};

export var updateTextButton = function updateTextButton(state, action) {
  var messageIterator = action.messageIterator,
      buttonIterator = action.buttonIterator,
      buttonTemplate = action.buttonTemplate,
      nextScenarioId = action.nextScenarioId;
  var messages = state.messages;
  var buttonTemplateObject = messages[messageIterator].button_template;
  var buttonItemArr = buttonTemplateObject.button_items;
  var buttonObject = buttonItemArr[buttonIterator].button;
  var blockId = nextScenarioId ? getBlockFromScenarioId(state, nextScenarioId) : null;
  return _objectSpread({}, state, {
    messages: [].concat(_toConsumableArray(messages.slice(0, messageIterator)), [_objectSpread({}, messages[messageIterator], {
      button_template: _objectSpread({}, buttonTemplateObject, {
        button_items: [].concat(_toConsumableArray(buttonItemArr.slice(0, buttonIterator)), [_objectSpread({}, buttonItemArr[buttonIterator], {
          button: _objectSpread({}, buttonObject, {
            name: buttonTemplate.name,
            url: buttonTemplate.url,
            effect: buttonTemplate.effect,
            block_id: buttonTemplate.blockId || blockId
          })
        })], _toConsumableArray(buttonItemArr.slice(buttonIterator + 1)))
      })
    })], _toConsumableArray(messages.slice(messageIterator + 1)))
  });
};
export var updateText = function updateText(state, action) {
  var messageId = action.messageId,
      value = action.value;
  var messages = state.messages;
  var msg = messages[messageId];
  return _objectSpread({}, state, {
    messages: _objectSpread({}, messages, _defineProperty({}, messageId, _objectSpread({}, msg, {}, msg.button_template ? {
      button_template: _objectSpread({}, msg.button_template, {
        title: value
      })
    } : {
      text: value
    })))
  });
};
export var updateButton = function updateButton(state, action) {
  var value = action.value,
      messageIterator = action.messageIterator,
      buttons = action.buttons;
  return !buttons ? {
    messages: [].concat(_toConsumableArray(state.messages.slice(0, messageIterator)), [_objectSpread({}, state.messages[messageIterator], {
      text: value
    })], _toConsumableArray(state.messages.slice(messageIterator + 1)))
  } : {
    messages: [].concat(_toConsumableArray(state.messages.slice(0, messageIterator)), [_objectSpread({}, state.messages[messageIterator], {
      text: null,
      button_template: _objectSpread({}, state.messages[messageIterator].button_template || {}, {
        title: value
      })
    })], _toConsumableArray(state.messages.slice(messageIterator + 1)))
  };
};
export var updateArticle = function updateArticle(state, action) {
  var messageId = action.messageId,
      itemId = action.itemId,
      article = action.article;
  var msg = state.messages[messageId];
  return {
    messages: _objectSpread({}, state.messages, _defineProperty({}, messageId, _objectSpread({}, msg, {
      generic_template: _objectSpread({}, msg.generic_template, {
        generic_items: msg.generic_template.generic_items.map(function (item) {
          if (item.id === itemId) {
            return _objectSpread({}, item, {
              article: article
            });
          }

          return item;
        })
      })
    })))
  };
};
export var editQuickReplyChoice = function editQuickReplyChoice(state, action) {
  var messageIterator = action.messageIterator,
      quickReplyChoiceIterator = action.quickReplyChoiceIterator,
      name = action.name;
  var messages = state.messages;
  var quickReplyChoicesArr = messages[messageIterator].quick_reply.quick_reply_choices;
  return {
    messages: [].concat(_toConsumableArray(messages.slice(0, messageIterator)), [_objectSpread({}, messages[messageIterator], {
      quick_reply: _objectSpread({}, messages[messageIterator].quick_reply, {
        quick_reply_choices: [].concat(_toConsumableArray(quickReplyChoicesArr.slice(0, quickReplyChoiceIterator)), [_objectSpread({}, quickReplyChoicesArr[quickReplyChoiceIterator], {
          name: name
        })], _toConsumableArray(quickReplyChoicesArr.slice(quickReplyChoiceIterator + 1)))
      })
    })], _toConsumableArray(messages.slice(messageIterator + 1)))
  };
};
export var updateTag = function updateTag(state, action) {
  var template = action.template,
      messageIterator = action.messageIterator,
      itemIterator = action.itemIterator,
      tagIterator = action.tagIterator,
      tagId = action.tagId;
  var messages = state.messages;
  var templateObject = messages[messageIterator]["".concat(template, "_template")];
  var templateItemArr = templateObject["".concat(template, "_items")];
  var tagArr = templateItemArr[itemIterator].tags;
  return _objectSpread({}, state, {
    messages: [].concat(_toConsumableArray(messages.slice(0, messageIterator)), [_objectSpread({}, messages[messageIterator], _defineProperty({}, "".concat(template, "_template"), _objectSpread({}, templateObject, _defineProperty({}, "".concat(template, "_items"), [].concat(_toConsumableArray(templateItemArr.slice(0, itemIterator)), [_objectSpread({}, templateItemArr[itemIterator], {
      tags: [].concat(_toConsumableArray(tagArr.slice(0, tagIterator)), [_objectSpread({}, tagArr[tagIterator], {
        id: tagId
      })], _toConsumableArray(tagArr.slice(tagIterator + 1)))
    })], _toConsumableArray(templateItemArr.slice(itemIterator + 1)))))))], _toConsumableArray(messages.slice(messageIterator + 1)))
  });
};
export var editFilterProp = function editFilterProp(state, action) {
  var updatedPropObject = action.updatedPropObject,
      prop = action.prop;
  return _objectSpread({}, state, {
    filter: _objectSpread({}, state.filter, _defineProperty({}, prop, updatedPropObject[prop]))
  });
};
export var updateScheduledAt = function updateScheduledAt(state, action) {
  var scenarioId = action.scenarioId,
      scheduledAt = action.scheduledAt;
  return _objectSpread({}, state, {
    scenarios: _objectSpread({}, state.scenarios, _defineProperty({}, scenarioId, _objectSpread({}, state.scenarios[scenarioId], {
      scenario_delivery: _objectSpread({}, state.scenarios[scenarioId].scenario_delivery, {
        scheduled_at: scheduledAt
      })
    })))
  });
};
export var updateIsValidTriggerTime = function updateIsValidTriggerTime(state, action) {
  var scenarioId = action.scenarioId,
      value = action.value;
  return _objectSpread({}, state, {
    scenarios: _objectSpread({}, state.scenarios, _defineProperty({}, scenarioId, _objectSpread({}, state.scenarios[scenarioId], {
      isValidTriggerTime: value
    })))
  });
};
export var updateStepDeliveryTime = function updateStepDeliveryTime(state, action) {
  var step = state.step;
  var prop = action.prop,
      value = action.value;
  return _objectSpread({}, state, {
    step: _objectSpread({}, step, {
      deliveryTime: _objectSpread({}, step.deliveryTime, _defineProperty({}, prop, value))
    })
  });
};
export var updateScenarioMainSetting = function updateScenarioMainSetting(state, action) {
  var scenarios = state.scenarios;
  var adScenarioId = action.adScenarioId,
      updatedScenario = action.updatedScenario;
  var currentMainSettingScenario = Object.values(scenarios).find(function (scenario) {
    return scenario.main_setting === true;
  });

  var newScenario = _objectSpread({}, scenarios, _defineProperty({}, adScenarioId, _objectSpread({}, scenarios[adScenarioId], {
    main_setting: updatedScenario.main_setting
  })));

  if (currentMainSettingScenario) {
    var currentMainSettingScenarioId = currentMainSettingScenario.id;
    newScenario[currentMainSettingScenarioId] = _objectSpread({}, scenarios[currentMainSettingScenarioId], {
      main_setting: false
    });
  }

  return _objectSpread({}, state, {
    scenarios: newScenario
  });
};
export var updateTalkTrigger = function updateTalkTrigger(state, action) {
  var messages = state.messages;
  var nextScenarioId = action.nextScenarioId,
      nextBlockId = action.nextBlockId;
  var lastMessage = messages.slice(-1)[0];
  var updatedBlock = nextScenarioId ? {
    scenario_id: nextScenarioId
  } : {
    id: nextBlockId
  };
  return _objectSpread({}, state, {
    messages: [].concat(_toConsumableArray(messages.slice(0, -1)), [_objectSpread({}, lastMessage, {
      talk_triggers: [_objectSpread({}, lastMessage.talk_triggers[0], {
        block: _objectSpread({}, lastMessage.talk_triggers[0].block, {}, updatedBlock)
      })]
    })])
  });
};
export var updateMessageItem = function updateMessageItem(state, action) {
  var messages = state.messages;
  var messageId = action.messageId,
      itemId = action.itemId,
      isImageLoading = action.isImageLoading;
  var msg = messages[messageId];
  return _objectSpread({}, state, {
    messages: _objectSpread({}, messages, _defineProperty({}, messageId, _objectSpread({}, msg, {
      generic_template: _objectSpread({}, msg.generic_template, {
        generic_items: msg.generic_template.generic_items.map(function (item) {
          if (item.id === itemId) {
            return _objectSpread({}, item, {
              isImageLoading: isImageLoading
            });
          }

          return item;
        })
      })
    })))
  });
};
export var updateImageAspectRatio = function updateImageAspectRatio(state, action) {
  var messages = state.messages;
  var messageId = action.messageId,
      imageAspectRatio = action.imageAspectRatio;
  var m = messages[messageId];
  return _objectSpread({}, state, {
    messages: _objectSpread({}, messages, _defineProperty({}, messageId, _objectSpread({}, m, {
      generic_template: _objectSpread({}, m.generic_template, {
        image_aspect_ratio: imageAspectRatio
      })
    })))
  });
};
export var updateListGenericItem = function updateListGenericItem(state, action) {
  var messageId = action.messageId;
  var msg = state.messages[messageId];
  var newGenericItems = msg.generic_template.generic_items.map(function (item) {
    return updateGenericItemRank(item, action);
  });

  var newGenericTemplate = _objectSpread({}, msg.generic_template, {
    generic_items: sortBy(newGenericItems, ['rank'])
  });

  var newMessage = _objectSpread({}, msg, {
    generic_template: newGenericTemplate
  });

  return {
    messages: _objectSpread({}, state.messages, _defineProperty({}, messageId, newMessage))
  };
};

var fetchRecursion = function fetchRecursion(component, action) {
  var componentId = action.componentId,
      componentType = action.componentType,
      values = action.values;

  if (component.type === 'box') {
    var updatedContents = component.contents.map(function (content) {
      return fetchRecursion(content, action);
    });
    return _objectSpread({}, component, {
      contents: updatedContents
    });
  }

  if (component.type === componentType && component.id !== componentId) return component;

  switch (componentType) {
    case 'text':
      {
        return _objectSpread({}, component, {
          contents: values.contents
        });
      }

    case 'button':
      {
        return _objectSpread({}, component, {
          action: _objectSpread({}, component.action, {}, values)
        });
      }

    case 'image':
      {
        return _objectSpread({}, component, {
          url: values.url,
          action: _objectSpread({}, component.action, {}, values.action)
        });
      }
  }

  return component;
};

export var updateLineFlexMessageComponent = function updateLineFlexMessageComponent(message, action) {
  var blockType = action.blockType,
      componentId = action.componentId,
      componentType = action.componentType,
      values = action.values;
  var lineFlexMessage = message.line_flex_message;
  var updatedBubbles = lineFlexMessage.contents.contents.map(function (bubble) {
    var block = bubble[blockType];
    if (!block) return bubble;

    if (blockType === 'hero') {
      if (block.id === componentId && block.type === componentType) {
        return _objectSpread({}, bubble, _defineProperty({}, blockType, _objectSpread({}, block, {
          url: values.url,
          action: values.action || block.action
        })));
      }

      return bubble;
    }

    var updatedBlock = fetchRecursion(block, action);
    return _objectSpread({}, bubble, _defineProperty({}, blockType, updatedBlock));
  });
  return _objectSpread({}, lineFlexMessage, {
    contents: _objectSpread({}, lineFlexMessage.contents, {
      contents: _toConsumableArray(updatedBubbles)
    })
  });
};
export var updateLineFlexMessage = function updateLineFlexMessage(state, messageId, lineFlexMessage) {
  var message = state.messages[messageId];
  return _objectSpread({}, state, {
    messages: _objectSpread({}, state.messages, _defineProperty({}, messageId, _objectSpread({}, message, {
      line_flex_message: lineFlexMessage
    })))
  });
};