var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React, { memo } from 'react';
import { useDispatch } from 'react-redux';
import { Handle, Position } from 'react-flow-renderer';
import styled from 'styled-components';
import { colors, shadows } from '@src/colors';
import { handleChangeHoverNode } from '@src/redux/fanp/actions/scenarioMap';
var ConnectedOtherScenarioContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 350px;\n  margin-top: 40px;\n  border-radius: 10px;\n  background: ", ";\n  box-shadow: ", ";\n  border: 2px solid ", ";\n  padding: 10px;\n  font-size: 20px;\n  text-align: center;\n"], ["\n  width: 350px;\n  margin-top: 40px;\n  border-radius: 10px;\n  background: ", ";\n  box-shadow: ", ";\n  border: 2px solid ", ";\n  padding: 10px;\n  font-size: 20px;\n  text-align: center;\n"])), colors.background.white, shadows.medium.mid, colors.border.green);
var ConnectedOtherScenarioNode = function (_a) {
    var _b = _a.data, connectedOtherScenario = _b.connectedOtherScenario, updateBoundaryNode = _b.updateBoundaryNode;
    var dispatch = null;
    if (!updateBoundaryNode) {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        dispatch = useDispatch();
    }
    var handleMouseEvent = function (hoveredNode) {
        if (updateBoundaryNode)
            return;
        dispatch(handleChangeHoverNode(hoveredNode));
    };
    return (React.createElement(React.Fragment, null,
        !updateBoundaryNode && (React.createElement(Handle, { type: "target", position: Position.Top, style: { top: 37 }, "data-testid": "ConnectedOtherScenarioNode_Handle" })),
        React.createElement(ConnectedOtherScenarioContainer, { ref: function (ref) {
                if (!ref || !updateBoundaryNode)
                    return;
                updateBoundaryNode('connectedOtherScenarios', connectedOtherScenario.id, ref.getBoundingClientRect());
            }, onMouseEnter: function () {
                return handleMouseEvent({ scenarioId: connectedOtherScenario.id });
            }, onMouseLeave: function () { return handleMouseEvent({ scenarioId: null }); }, "data-testid": "ConnectedOtherScenarioNode_ConnectedOtherScenarioContainer" }, connectedOtherScenario === null || connectedOtherScenario === void 0 ? void 0 : connectedOtherScenario.name)));
};
export default memo(ConnectedOtherScenarioNode);
var templateObject_1;
