var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { updateValidation, updateValidations, } from '@src/redux/fanp/actions/ad-scenario';
var Validator = function (_a) {
    var name = _a.name, initialValue = _a.initialValue, schema = _a.schema, children = _a.children, onValid = _a.onValid, attrName = _a.attrName;
    var _b = __read(useState(null), 2), errorName = _b[0], setErrorName = _b[1];
    var _c = __read(useState([]), 2), errors = _c[0], setErrors = _c[1];
    var _d = __read(useState(false), 2), touched = _d[0], setTouched = _d[1];
    var _e = __read(useState(initialValue), 2), value = _e[0], setValue = _e[1];
    var dispatch = useDispatch();
    useEffect(function () {
        checkError();
    }, []);
    useEffect(function () {
        dispatch(updateValidations(name));
        checkError();
    }, [name]);
    useEffect(function () {
        checkError();
    }, [value]);
    useEffect(function () {
        return function () {
            removeError();
        };
    }, []);
    var checkError = function () {
        var _a;
        if (!schema)
            return;
        schema
            .validate((_a = {}, _a[name] = value, _a), { abortEarly: false })
            .then(function () {
            removeError();
            if (onValid) {
                onValid({
                    target: {
                        value: value,
                        name: attrName,
                    },
                });
            }
        })
            .catch(function (err) {
            var errorDetail = err.inner.find(function (detail) { return detail.params.path === name; });
            if (errorDetail)
                addError(errorDetail);
            else
                removeError();
        });
    };
    var addError = function (err) {
        setErrorName(err.name);
        setErrors(err.errors);
        dispatch(updateValidation(name, err.errors.join(', ')));
    };
    var removeError = function () {
        setErrorName(null);
        setErrors([]);
        dispatch(updateValidations(name));
    };
    var getValidatorProps = function (_a) {
        var onChange = _a.onChange, onBlur = _a.onBlur, props = __rest(_a, ["onChange", "onBlur"]);
        // fuses user made [onChange, onBlur] with this component's
        return __assign({ onChange: function (e) {
                var rest = [];
                for (var _i = 1; _i < arguments.length; _i++) {
                    rest[_i - 1] = arguments[_i];
                }
                if (onChange)
                    onChange.apply(void 0, __spreadArray([e], __read(rest)));
                var value = typeof e === 'string' ? e : e.target.value;
                validateInput(value);
            }, onBlur: function (e) {
                if (onBlur)
                    onBlur(e);
                validateInput(e);
            } }, props);
    };
    var validateInput = function (e) {
        var value = typeof e === 'string' ? e : e.target.value;
        setValue(value);
        if (!touched)
            setTouched(true);
    };
    return children({
        name: name,
        errorName: errorName,
        errors: errors,
        getValidatorProps: getValidatorProps,
        touched: touched,
    });
};
export default Validator;
