var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { injectIntl } from 'react-intl';
import { transitions } from '@src/lib/animations';
import { colors } from '@src/colors';
import platformLimitations from '@src/lib/platforms/limitations';
import modalTypes from '@src/lib/modalTypes';
import { ModalPortal } from '@src/components/atoms';
import { fetchDefaultMessage } from '@src/redux/fanp/actions/settings';
import { Modal } from '@src/components/molecules';
import DefaultMessageContent from '@src/components/molecules/DefaultMessageContent';
var UserSettingArea = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: inline-block;\n  width: 400px;\n  margin: 0 50px 0 0;\n  height: 500px;\n\n  .modal_heading {\n    font-size: 1.5rem;\n    margin: 0 0 2rem;\n  }\n\n  .modal_bot_setting_sub_title {\n    margin: 0 0 1rem;\n  }\n"], ["\n  display: inline-block;\n  width: 400px;\n  margin: 0 50px 0 0;\n  height: 500px;\n\n  .modal_heading {\n    font-size: 1.5rem;\n    margin: 0 0 2rem;\n  }\n\n  .modal_bot_setting_sub_title {\n    margin: 0 0 1rem;\n  }\n"])));
var BotSettingSampleWelcome = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: inline-block;\n  width: 320px;\n  height: 570px;\n  background-repeat: no-repeat;\n  background-size: 100%;\n  background-image: url(/images/img_phone_welcome_board.png);\n"], ["\n  display: inline-block;\n  width: 320px;\n  height: 570px;\n  background-repeat: no-repeat;\n  background-size: 100%;\n  background-image: url(/images/img_phone_welcome_board.png);\n"])));
var BotSettingSampleDefault = styled(BotSettingSampleWelcome)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  position: relative;\n  background-image: url(/images/phone.png);\n"], ["\n  position: relative;\n  background-image: url(/images/phone.png);\n"])));
var DefaultLabelWrapper = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  margin: 10px 0;\n  label {\n    position: relative;\n    margin: 5px;\n    color: ", ";\n    transition: ", ";\n    display: inline-flex;\n    align-items: center;\n\n    &:hover {\n      cursor: pointer;\n    }\n\n    input {\n      margin: 0 8px 0 0;\n      -webkit-appearance: checkbox;\n    }\n  }\n"], ["\n  margin: 10px 0;\n  label {\n    position: relative;\n    margin: 5px;\n    color: ", ";\n    transition: ", ";\n    display: inline-flex;\n    align-items: center;\n\n    &:hover {\n      cursor: pointer;\n    }\n\n    input {\n      margin: 0 8px 0 0;\n      -webkit-appearance: checkbox;\n    }\n  }\n"])), colors.gray_light, transitions.normal);
var Default = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  position: relative;\n  margin: 0 0 0.5rem;\n  padding: 0.5rem;\n  border-radius: 4px;\n  transition: border-color 0.1s ease-out;\n  border: 1px solid rgba(58, 57, 86, 0.15);\n"], ["\n  position: relative;\n  margin: 0 0 0.5rem;\n  padding: 0.5rem;\n  border-radius: 4px;\n  transition: border-color 0.1s ease-out;\n  border: 1px solid rgba(58, 57, 86, 0.15);\n"])));
var DefaultFormWrapper = styled(Default)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  height: 200px;\n  padding: 0;\n\n  textarea {\n    width: 100%;\n    height: 100%;\n    padding: 10px;\n    border: none;\n    font-size: 16px;\n\n    &.readonly {\n      background: ", ";\n    }\n\n    &:focus {\n      + .form_count {\n        opacity: 1;\n        transition: opacity 0.1s ease-out;\n        .readonly & {\n          opacity: 0;\n        }\n      }\n    }\n  }\n"], ["\n  height: 200px;\n  padding: 0;\n\n  textarea {\n    width: 100%;\n    height: 100%;\n    padding: 10px;\n    border: none;\n    font-size: 16px;\n\n    &.readonly {\n      background: ", ";\n    }\n\n    &:focus {\n      + .form_count {\n        opacity: 1;\n        transition: opacity 0.1s ease-out;\n        .readonly & {\n          opacity: 0;\n        }\n      }\n    }\n  }\n"])), colors.gray_lightest);
var FormCount = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  opacity: 0;\n  position: absolute;\n  bottom: 0;\n  right: 0;\n  color: ", ";\n  background: ", ";\n  padding: 0.3rem 0.5rem;\n  border-radius: 2px 0 4px 0;\n  letter-spacing: 0.05em;\n  line-height: 1;\n  transition: opacity ", " ease-out;\n  font-size: 0.625rem;\n  &.over {\n    background: ", ";\n  }\n  .quick & {\n    border-radius: 2px 0 18px 0;\n  }\n"], ["\n  opacity: 0;\n  position: absolute;\n  bottom: 0;\n  right: 0;\n  color: ", ";\n  background: ", ";\n  padding: 0.3rem 0.5rem;\n  border-radius: 2px 0 4px 0;\n  letter-spacing: 0.05em;\n  line-height: 1;\n  transition: opacity ", " ease-out;\n  font-size: 0.625rem;\n  &.over {\n    background: ", ";\n  }\n  .quick & {\n    border-radius: 2px 0 18px 0;\n  }\n"])), colors.white, colors.gray_lighter, transitions.fast, colors.error);
var DefaultMessageModal = function (_a) {
    var intl = _a.intl, fetchSettings = _a.fetchSettings, changeDefaultOption = _a.changeDefaultOption, toggleDefaultMessage = _a.toggleDefaultMessage, chatbotId = _a.chatbotId, showUpdateSuccessAlert = _a.showUpdateSuccessAlert, showErrorAlert = _a.showErrorAlert, defaultOptionSelected = _a.defaultOptionSelected, activeChatbot = _a.activeChatbot, showDefaultMessage = _a.showDefaultMessage, getDefaultMessageText = _a.getDefaultMessageText;
    var defaultAction = useSelector(function (state) { return state.settings.present.defaultAction; });
    var dispatch = useDispatch();
    useEffect(function () {
        fetchSettings();
    }, []);
    var handleChangeCheckInput = function (e) {
        changeDefaultOption(e.target.value);
    };
    var handleCancel = function () {
        fetchSettings();
        toggleDefaultMessage();
    };
    var handleConfirm = function () {
        return dispatch(fetchDefaultMessage({
            chatbotId: chatbotId,
            successMessage: showUpdateSuccessAlert,
            errorMessage: showErrorAlert,
            defaultOptionSelected: defaultOptionSelected,
            defaultAction: defaultAction,
            activeChatbot: activeChatbot,
        }));
    };
    var limitTextMaxLength = platformLimitations[activeChatbot.platform].MAX_DEFAULT_REPLY_TEXT_LENGTH;
    return (React.createElement("div", null, showDefaultMessage && (React.createElement(ModalPortal, null,
        React.createElement(Modal, { "data-testid": "default-message-modal", width: "960px", height: "730px", maxWidth: "1000px", type: modalTypes.CUSTOM, onCancel: handleCancel, onConfirm: handleConfirm, overFlow: true, btnText: intl.formatMessage({ id: "jvo0vs", defaultMessage: "Save" }), disableOnClickOutside: true },
            React.createElement(UserSettingArea, null,
                React.createElement("p", { className: "modal_heading" }, intl.formatMessage({ id: "4AY6/l", defaultMessage: "Default Message" })),
                React.createElement("p", { className: "modal_bot_setting_sub_title" }, intl.formatMessage({ id: "dBNY4M", defaultMessage: "You can set the text to automatically reply when a free comment comes from the user. Please set within {limitTextMaxLength} characters." }, { limitTextMaxLength: limitTextMaxLength })),
                React.createElement(DefaultLabelWrapper, null,
                    React.createElement("label", { htmlFor: "defaultMesOption" },
                        React.createElement("input", { type: "radio", value: "0", checked: defaultOptionSelected === '0', onChange: handleChangeCheckInput, id: "defaultMesOption", "data-testid": "defaultOptionSelected_radio" }),
                        intl.formatMessage({ id: "5f/DLf", defaultMessage: "Form contents" })),
                    React.createElement("br", null)),
                React.createElement(DefaultFormWrapper, null,
                    React.createElement("textarea", { className: defaultOptionSelected === '1' ||
                            defaultOptionSelected === '2'
                            ? 'readonly'
                            : '', defaultValue: activeChatbot && activeChatbot.default_text, onChange: function (e) { return getDefaultMessageText(e.target.value); }, maxLength: limitTextMaxLength, "data-testid": "defaultOptionSelected_textarea" }),
                    React.createElement(FormCount, { className: "form_count" }, limitTextMaxLength -
                        (activeChatbot.default_text &&
                            activeChatbot.default_text.length))),
                React.createElement(DefaultLabelWrapper, null,
                    React.createElement("label", { htmlFor: "defaultMesOption_1" },
                        React.createElement("input", { type: "radio", value: "1", checked: defaultOptionSelected === '1', onChange: handleChangeCheckInput, id: "defaultMesOption_1", "data-testid": "defaultOptionSelected1_radio" }),
                        intl.formatMessage({ id: "CjGPCv", defaultMessage: "Previous content" })),
                    React.createElement("br", null)),
                React.createElement(DefaultLabelWrapper, null,
                    React.createElement("label", { htmlFor: "defaultMesOption_2" },
                        React.createElement("input", { type: "radio", value: "2", checked: defaultOptionSelected === '2', onChange: handleChangeCheckInput, id: "defaultMesOption_2", "data-testid": "defaultOptionSelected2_radio" }),
                        intl.formatMessage({ id: "LIXFeb", defaultMessage: "Do not reply anything" })),
                    React.createElement("br", null))),
            React.createElement(BotSettingSampleDefault, null,
                React.createElement(DefaultMessageContent, { selectedOption: defaultOptionSelected, textAreaValue: activeChatbot && activeChatbot.default_text, imgSrc: activeChatbot.page && activeChatbot.page.thumbnail_url })))))));
};
export default injectIntl(DefaultMessageModal);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
