var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var _a;
import { v4 as uuid } from 'uuid';
import { FormAutomationStep, FormAutomationStepAction, FormAutomationStepValueType, TextReplacementType, DatePart, DateFormat, MagicalClickType, } from 'zeals-protobuf/zeals/web/entities/form_automation_pb';
import { CompletionStatus, SegmentType, } from 'zeals-protobuf/zeals/web/entities/form_pb';
import * as formAutomationPbEntities from 'zeals-protobuf/zeals/web/entities/form_automation_pb';
import validateSteps from './validateSteps';
export { validateSteps };
// Backend does not provide a way to add a FormAutomationStep
// You must add the steps on the frontend, and then sync to each config at some point
// This function exhaustively takes some step.action type and then returns a blank version
// of the step
export function createBlankStep(action) {
    var id = uuid();
    switch (action) {
        case FormAutomationStepAction.FORM_AUTOMATION_STEP_ACTION_CLICK: {
            var action_1 = {
                querySelector: '.selector',
            };
            var step = {
                id: id,
                actionType: FormAutomationStepAction.FORM_AUTOMATION_STEP_ACTION_CLICK,
                skippable: false,
                clickAction: action_1,
                description: '',
                errorMessage: '',
            };
            return step;
        }
        case FormAutomationStepAction.FORM_AUTOMATION_STEP_ACTION_CLICK_BY_VALUE: {
            var action_2 = {
                querySelector: '.selector',
                valueType: FormAutomationStepValueType.FORM_AUTOMATION_STEP_VALUE_TYPE_FIELD,
                valueMapMap: [],
                fieldId: null,
                fixedValue: '',
                htmlAttr: null,
                source: SegmentType.SEGMENT_TYPE_UNSPECIFIED,
            };
            var step = {
                id: id,
                actionType: FormAutomationStepAction.FORM_AUTOMATION_STEP_ACTION_CLICK_BY_VALUE,
                skippable: false,
                clickByValueAction: action_2,
                description: '',
                errorMessage: '',
            };
            return step;
        }
        case FormAutomationStepAction.FORM_AUTOMATION_STEP_ACTION_MAGICAL_CLICK: {
            var action_3 = {
                fieldId: null,
                magicalClickType: MagicalClickType.MAGICAL_CLICK_TYPE_HONDA,
                source: SegmentType.SEGMENT_TYPE_UNSPECIFIED,
            };
            var step = {
                id: id,
                actionType: FormAutomationStepAction.FORM_AUTOMATION_STEP_ACTION_MAGICAL_CLICK,
                skippable: false,
                magicalClickAction: action_3,
                description: '',
                errorMessage: '',
            };
            return step;
        }
        case FormAutomationStepAction.FORM_AUTOMATION_STEP_ACTION_ELEMENT_WAIT: {
            var action_4 = {
                querySelector: '.selector',
            };
            var step = {
                id: id,
                actionType: FormAutomationStepAction.FORM_AUTOMATION_STEP_ACTION_ELEMENT_WAIT,
                skippable: false,
                elementWaitAction: action_4,
                description: '',
                errorMessage: '',
            };
            return step;
        }
        case FormAutomationStepAction.FORM_AUTOMATION_STEP_ACTION_SELECT_INPUT: {
            var action_5 = {
                querySelector: '.selector',
                valueType: FormAutomationStepValueType.FORM_AUTOMATION_STEP_VALUE_TYPE_FIELD,
                valueMapMap: [],
                fieldId: null,
                fixedValue: '',
                dateFormat: DateFormat.DATE_FORMAT_UNSPECIFIED,
                source: SegmentType.SEGMENT_TYPE_UNSPECIFIED,
            };
            var step = {
                id: id,
                actionType: FormAutomationStepAction.FORM_AUTOMATION_STEP_ACTION_SELECT_INPUT,
                skippable: false,
                selectAction: action_5,
                description: '',
                errorMessage: '',
            };
            return step;
        }
        case FormAutomationStepAction.FORM_AUTOMATION_STEP_ACTION_RADIO_INPUT: {
            var action_6 = {
                querySelector: '.selector',
                valueType: FormAutomationStepValueType.FORM_AUTOMATION_STEP_VALUE_TYPE_FIELD,
                valueMapMap: [],
                fieldId: null,
                fixedValue: 'Default',
                source: SegmentType.SEGMENT_TYPE_UNSPECIFIED,
            };
            var step = {
                id: id,
                actionType: FormAutomationStepAction.FORM_AUTOMATION_STEP_ACTION_RADIO_INPUT,
                skippable: false,
                radioAction: action_6,
                description: '',
                errorMessage: '',
            };
            return step;
        }
        case FormAutomationStepAction.FORM_AUTOMATION_STEP_ACTION_TEXT_INPUT: {
            var action_7 = {
                querySelector: '.selector',
                valueType: FormAutomationStepValueType.FORM_AUTOMATION_STEP_VALUE_TYPE_FIELD,
                regexp: null,
                replacementGroupsList: [],
                fieldId: null,
                fixedValue: '',
                dateFormat: null,
                source: SegmentType.SEGMENT_TYPE_UNSPECIFIED,
            };
            var step = {
                id: id,
                actionType: FormAutomationStepAction.FORM_AUTOMATION_STEP_ACTION_TEXT_INPUT,
                skippable: false,
                inputAction: action_7,
                description: '',
                errorMessage: '',
            };
            return step;
        }
        case FormAutomationStepAction.FORM_AUTOMATION_STEP_ACTION_TIME_WAIT: {
            var action_8 = {
                waitS: 5,
            };
            var step = {
                id: id,
                actionType: FormAutomationStepAction.FORM_AUTOMATION_STEP_ACTION_TIME_WAIT,
                skippable: false,
                timeWaitAction: action_8,
                description: '',
                errorMessage: '',
            };
            return step;
        }
        case FormAutomationStepAction.FORM_AUTOMATION_STEP_ACTION_NAVIGATION_WAIT: {
            var action_9 = {
                waitS: 30,
            };
            var step = {
                id: id,
                actionType: FormAutomationStepAction.FORM_AUTOMATION_STEP_ACTION_NAVIGATION_WAIT,
                skippable: false,
                navigationWaitAction: action_9,
                description: '',
                errorMessage: '',
            };
            return step;
        }
        case FormAutomationStepAction.FORM_AUTOMATION_STEP_ACTION_CHECKBOX_INPUT: {
            var action_10 = {
                querySelector: '.selector',
                valueType: FormAutomationStepValueType.FORM_AUTOMATION_STEP_VALUE_TYPE_FIELD,
                valueMapMap: [],
                fieldId: '',
                fixedValue: '',
                source: SegmentType.SEGMENT_TYPE_UNSPECIFIED,
            };
            var step = {
                id: id,
                actionType: FormAutomationStepAction.FORM_AUTOMATION_STEP_ACTION_CHECKBOX_INPUT,
                skippable: false,
                checkboxAction: action_10,
                description: '',
                errorMessage: '',
            };
            return step;
        }
        case FormAutomationStepAction.FORM_AUTOMATION_STEP_ACTION_UNSPECIFIED:
            return null;
        default:
            return null;
    }
}
export function automationActionToString(action) {
    switch (action) {
        case FormAutomationStepAction.FORM_AUTOMATION_STEP_ACTION_CLICK:
            return 'click';
        case FormAutomationStepAction.FORM_AUTOMATION_STEP_ACTION_CLICK_BY_VALUE:
            return 'click_by_value';
        case FormAutomationStepAction.FORM_AUTOMATION_STEP_ACTION_MAGICAL_CLICK:
            return 'magical_click';
        case FormAutomationStepAction.FORM_AUTOMATION_STEP_ACTION_RADIO_INPUT:
            return 'radio';
        case FormAutomationStepAction.FORM_AUTOMATION_STEP_ACTION_SELECT_INPUT:
            return 'select_input';
        case FormAutomationStepAction.FORM_AUTOMATION_STEP_ACTION_TEXT_INPUT:
            return 'text_input';
        case FormAutomationStepAction.FORM_AUTOMATION_STEP_ACTION_ELEMENT_WAIT:
            return 'element_wait';
        case FormAutomationStepAction.FORM_AUTOMATION_STEP_ACTION_TIME_WAIT:
            return 'time_wait';
        case FormAutomationStepAction.FORM_AUTOMATION_STEP_ACTION_NAVIGATION_WAIT:
            return 'navigation_wait';
        case FormAutomationStepAction.FORM_AUTOMATION_STEP_ACTION_CHECKBOX_INPUT:
            return 'checkbox_input';
        case FormAutomationStepAction.FORM_AUTOMATION_STEP_ACTION_UNSPECIFIED:
            return 'unspecified';
        default:
            return '';
    }
}
export function valueTypeToString(valueType) {
    switch (valueType) {
        case FormAutomationStepValueType.FORM_AUTOMATION_STEP_VALUE_TYPE_FIELD:
            return 'field';
        case FormAutomationStepValueType.FORM_AUTOMATION_STEP_VALUE_TYPE_FIXED:
            return 'fixed';
        case FormAutomationStepValueType.FORM_AUTOMATION_STEP_VALUE_TYPE_UNSPECIFIED:
            return 'unspecified';
        default:
            return '';
    }
}
export function textReplacementTypeToString(type) {
    switch (type) {
        case TextReplacementType.TEXT_REPLACEMENT_UUID_V4:
            return 'uuid_v4';
        case TextReplacementType.TEXT_REPLACEMENT_FIXED:
            return 'fixed';
        case TextReplacementType.TEXT_REPLACEMENT_UNSPECIFIED:
            return 'unspecified';
        default:
            return '';
    }
}
export function valueMapValidation(valueMap, context, field) {
    switch (context.parent.valueType) {
        case FormAutomationStepValueType.FORM_AUTOMATION_STEP_VALUE_TYPE_FIXED:
            return Boolean(context.parent.fixedValue);
        case FormAutomationStepValueType.FORM_AUTOMATION_STEP_VALUE_TYPE_FIELD: {
            // We retrieve the field values only
            var valueMapValues_1 = valueMap.map(function (_a) {
                var _b = __read(_a, 2), value = _b[0], _ = _b[1];
                return value;
            });
            // Returns false if two values are duplicate
            return !valueMapValues_1.some(function (value, i) { return valueMapValues_1.indexOf(value) !== i; });
        }
        default:
            return true;
    }
}
// Sorts the Form automation steps used in the Sidebar to display them in the same order as the design
export function sortedFormAutomationSteps() {
    var formAutomationSteps = Object.values(FormAutomationStepAction)
        .filter(function (a) {
        return a !== FormAutomationStepAction.FORM_AUTOMATION_STEP_ACTION_UNSPECIFIED;
    })
        .slice(0, 9);
    // Insert Checkbox input at the 6th index
    formAutomationSteps.splice(6, 0, FormAutomationStepAction.FORM_AUTOMATION_STEP_ACTION_CHECKBOX_INPUT);
    return formAutomationSteps;
}
export function formAutomationClickStepFromObj(obj) {
    var formAutomationClickStep = new formAutomationPbEntities.FormAutomationClickStep();
    formAutomationClickStep.setQuerySelector(obj.querySelector);
    return formAutomationClickStep;
}
export function formAutomationElementWaitStepFromObj(obj) {
    var formAutomationElementWaitStep = new formAutomationPbEntities.FormAutomationElementWaitStep();
    formAutomationElementWaitStep.setQuerySelector(obj.querySelector);
    return formAutomationElementWaitStep;
}
export function textReplacementGroupFromObj(obj) {
    var textReplacementGroup = new formAutomationPbEntities.TextReplacementGroup();
    textReplacementGroup.setRegexp(obj.regexp);
    textReplacementGroup.setFixedString(obj.fixedString);
    textReplacementGroup.setReplacement(obj.replacement);
    return textReplacementGroup;
}
export function formAutomationTextInputStepFromObj(obj) {
    var formAutomationTextInputStep = new formAutomationPbEntities.FormAutomationTextInputStep();
    formAutomationTextInputStep.setQuerySelector(obj.querySelector);
    formAutomationTextInputStep.setRegexp(obj.regexp);
    formAutomationTextInputStep.setDateFormat(obj.dateFormat);
    formAutomationTextInputStep.setReplacementGroupsList(obj.replacementGroupsList.map(textReplacementGroupFromObj));
    formAutomationTextInputStep.setValueType(obj.valueType);
    formAutomationTextInputStep.setSource(obj.source);
    // oneof
    switch (obj.valueType) {
        case FormAutomationStepValueType.FORM_AUTOMATION_STEP_VALUE_TYPE_FIXED:
            formAutomationTextInputStep.setFixedValue(obj.fixedValue);
            break;
        case FormAutomationStepValueType.FORM_AUTOMATION_STEP_VALUE_TYPE_FIELD:
            formAutomationTextInputStep.setFieldId(obj.fieldId);
            break;
        case FormAutomationStepValueType.FORM_AUTOMATION_STEP_VALUE_TYPE_UNSPECIFIED:
            break;
        default:
            return undefined;
    }
    return formAutomationTextInputStep;
}
export function formAutomationRadioInputStepFromObj(obj) {
    var formAutomationRadioInputStep = new formAutomationPbEntities.FormAutomationRadioInputStep();
    formAutomationRadioInputStep.setQuerySelector(obj.querySelector);
    formAutomationRadioInputStep.setValueType(obj.valueType);
    formAutomationRadioInputStep.setSource(obj.source);
    Object.values(obj.valueMapMap.reverse()).forEach(function (value) {
        formAutomationRadioInputStep.getValueMapMap().set(value[0], value[1]);
    });
    // oneof
    switch (obj.valueType) {
        case FormAutomationStepValueType.FORM_AUTOMATION_STEP_VALUE_TYPE_FIXED:
            formAutomationRadioInputStep.setFixedValue(obj.fixedValue);
            break;
        case FormAutomationStepValueType.FORM_AUTOMATION_STEP_VALUE_TYPE_FIELD:
            formAutomationRadioInputStep.setFieldId(obj.fieldId);
            break;
        case FormAutomationStepValueType.FORM_AUTOMATION_STEP_VALUE_TYPE_UNSPECIFIED:
            break;
        default:
            return undefined;
    }
    return formAutomationRadioInputStep;
}
export function formAutomationTimeWaitStepFromObj(obj) {
    var formAutomationTimeWaitStep = new formAutomationPbEntities.FormAutomationTimeWaitStep();
    formAutomationTimeWaitStep.setWaitS(obj.waitS);
    return formAutomationTimeWaitStep;
}
export function formAutomationSelectInputStepFromObj(obj) {
    var formAutomationSelectInputStep = new formAutomationPbEntities.FormAutomationSelectInputStep();
    formAutomationSelectInputStep.setQuerySelector(obj.querySelector);
    formAutomationSelectInputStep.setValueType(obj.valueType);
    formAutomationSelectInputStep.setDateFormat(obj.dateFormat);
    formAutomationSelectInputStep.setSource(obj.source);
    Object.values(obj.valueMapMap.reverse()).forEach(function (value) {
        formAutomationSelectInputStep.getValueMapMap().set(value[0], value[1]);
    });
    // oneof
    switch (obj.valueType) {
        case FormAutomationStepValueType.FORM_AUTOMATION_STEP_VALUE_TYPE_FIXED:
            formAutomationSelectInputStep.setFixedValue(obj.fixedValue);
            break;
        case FormAutomationStepValueType.FORM_AUTOMATION_STEP_VALUE_TYPE_FIELD:
            formAutomationSelectInputStep.setFieldId(obj.fieldId);
            break;
        case FormAutomationStepValueType.FORM_AUTOMATION_STEP_VALUE_TYPE_UNSPECIFIED:
            break;
        default:
            return undefined;
    }
    return formAutomationSelectInputStep;
}
export function formAutomationNavigationWaitStepFromObj(obj) {
    var formAutomationNavigationWaitStep = new formAutomationPbEntities.FormAutomationNavigationWaitStep();
    formAutomationNavigationWaitStep.setWaitS(obj.waitS);
    return formAutomationNavigationWaitStep;
}
export function formAutomationMagicalClickStepFromObj(obj) {
    var formAutomationMagicalClickStep = new formAutomationPbEntities.FormAutomationMagicalClickStep();
    formAutomationMagicalClickStep.setFieldId(obj.fieldId);
    formAutomationMagicalClickStep.setMagicalClickType(obj.magicalClickType);
    formAutomationMagicalClickStep.setSource(obj.source);
    return formAutomationMagicalClickStep;
}
export function formAutomationClickByValueStepFromObj(obj) {
    var formAutomationClickByValueStep = new formAutomationPbEntities.FormAutomationClickByValueStep();
    formAutomationClickByValueStep.setQuerySelector(obj.querySelector);
    formAutomationClickByValueStep.setValueType(obj.valueType);
    formAutomationClickByValueStep.setSource(obj.source);
    Object.values(obj.valueMapMap.reverse()).forEach(function (value) {
        formAutomationClickByValueStep.getValueMapMap().set(value[0], value[1]);
    });
    if (obj.htmlAttr)
        formAutomationClickByValueStep.setHtmlAttr(obj.htmlAttr);
    // oneof
    switch (obj.valueType) {
        case FormAutomationStepValueType.FORM_AUTOMATION_STEP_VALUE_TYPE_FIXED:
            formAutomationClickByValueStep.setFixedValue(obj.fixedValue);
            break;
        case FormAutomationStepValueType.FORM_AUTOMATION_STEP_VALUE_TYPE_FIELD:
            formAutomationClickByValueStep.setFieldId(obj.fieldId);
            break;
        case FormAutomationStepValueType.FORM_AUTOMATION_STEP_VALUE_TYPE_UNSPECIFIED:
            break;
        default:
            return undefined;
    }
    return formAutomationClickByValueStep;
}
export function formAutomationCheckboxInputStepFromObj(obj) {
    var formAutomationCheckboxInputStep = new formAutomationPbEntities.FormAutomationCheckboxInputStep();
    formAutomationCheckboxInputStep.setQuerySelector(obj.querySelector);
    formAutomationCheckboxInputStep.setValueType(obj.valueType);
    formAutomationCheckboxInputStep.setSource(obj.source);
    Object.values(obj.valueMapMap.reverse()).forEach(function (value) {
        formAutomationCheckboxInputStep.getValueMapMap().set(value[0], value[1]);
    });
    // oneof
    switch (obj.valueType) {
        case FormAutomationStepValueType.FORM_AUTOMATION_STEP_VALUE_TYPE_FIXED:
            formAutomationCheckboxInputStep.setFixedValue(obj.fixedValue);
            break;
        case FormAutomationStepValueType.FORM_AUTOMATION_STEP_VALUE_TYPE_FIELD:
            formAutomationCheckboxInputStep.setFieldId(obj.fieldId);
            break;
        case FormAutomationStepValueType.FORM_AUTOMATION_STEP_VALUE_TYPE_UNSPECIFIED:
            break;
        default:
            return undefined;
    }
    return formAutomationCheckboxInputStep;
}
export function formAutomationStepFromObj(obj) {
    var formAutomationStep = new FormAutomationStep();
    formAutomationStep.setActionType(obj.actionType);
    formAutomationStep.setSkippable(obj.skippable);
    if (obj.clickAction) {
        formAutomationStep.setClickAction(formAutomationClickStepFromObj(obj.clickAction));
    }
    if (obj.elementWaitAction) {
        formAutomationStep.setElementWaitAction(formAutomationElementWaitStepFromObj(obj.elementWaitAction));
    }
    if (obj.inputAction) {
        formAutomationStep.setInputAction(formAutomationTextInputStepFromObj(obj.inputAction));
    }
    if (obj.radioAction) {
        formAutomationStep.setRadioAction(formAutomationRadioInputStepFromObj(obj.radioAction));
    }
    if (obj.timeWaitAction) {
        formAutomationStep.setTimeWaitAction(formAutomationTimeWaitStepFromObj(obj.timeWaitAction));
    }
    if (obj.selectAction) {
        formAutomationStep.setSelectAction(formAutomationSelectInputStepFromObj(obj.selectAction));
    }
    if (obj.navigationWaitAction) {
        formAutomationStep.setNavigationWaitAction(formAutomationNavigationWaitStepFromObj(obj.navigationWaitAction));
    }
    if (obj.magicalClickAction) {
        formAutomationStep.setMagicalClickAction(formAutomationMagicalClickStepFromObj(obj.magicalClickAction));
    }
    if (obj.clickByValueAction) {
        formAutomationStep.setClickByValueAction(formAutomationClickByValueStepFromObj(obj.clickByValueAction));
    }
    if (obj.checkboxAction) {
        formAutomationStep.setCheckboxAction(formAutomationCheckboxInputStepFromObj(obj.checkboxAction));
    }
    return formAutomationStep;
}
export function datePartToString(datePart) {
    switch (datePart) {
        case DatePart.DATE_PART_YEAR:
            return 'year';
        case DatePart.DATE_PART_MONTH:
            return 'month';
        case DatePart.DATE_PART_DAY:
            return 'day';
        default:
            return '';
    }
}
export function magicalClickTypeToString(type) {
    switch (type) {
        case MagicalClickType.MAGICAL_CLICK_TYPE_HONDA:
            return 'honda';
        case MagicalClickType.MAGICAL_CLICK_TYPE_YOYAKU:
            return 'yoyaku';
        case MagicalClickType.MAGICAL_CLICK_TYPE_UNSPECIFIED:
            return '';
        default:
            return '';
    }
}
export var dateFormatExampleMap = (_a = {},
    _a[DateFormat.DATE_FORMAT_DEFAULT_YEAR] = '2016',
    _a[DateFormat.DATE_FORMAT_DEFAULT_MONTH] = '1',
    _a[DateFormat.DATE_FORMAT_DEFAULT_DAY] = '2',
    _a[DateFormat.DATE_FORMAT_DEFAULT_MONTH_DAY] = '1/2',
    _a[DateFormat.DATE_FORMAT_DEFAULT_YEAR_MONTH_DAY] = '2016/1/2',
    _a[DateFormat.DATE_FORMAT_DEFAULT_HOUR] = '14',
    _a[DateFormat.DATE_FORMAT_DEFAULT_MINUTE] = '00',
    _a[DateFormat.DATE_FORMAT_DEFAULT_SECOND] = '00',
    _a[DateFormat.DATE_FORMAT_DEFAULT_HOUR_MINUTE] = '14:00',
    _a[DateFormat.DATE_FORMAT_DEFAULT_HOUR_MINUTE_SECOND] = '14:00:00',
    _a[DateFormat.DATE_FORMAT_ZERO_PADDED_MONTH] = '01',
    _a[DateFormat.DATE_FORMAT_ZERO_PADDED_DAY] = '02',
    _a[DateFormat.DATE_FORMAT_HYPHEN_ZERO_PADDED_YEAR_MONTH_DAY] = '2016-01-02',
    _a[DateFormat.DATE_FORMAT_LACOCO_YEAR] = '-16',
    _a[DateFormat.DATE_FORMAT_CURVES_YEAR_MONTH_DAY] = '2016年01月01日(金)',
    _a[DateFormat.DATE_FORMAT_CURVES_TIME] = '18時～',
    _a);
export var hasSegment = function (formBuild) {
    return formBuild === null || formBuild === void 0 ? void 0 : formBuild.segmentsList.some(function (segment) {
        var _a = segment || {}, webForm = _a.webForm, formScenario = _a.formScenario;
        return (webForm === null || webForm === void 0 ? void 0 : webForm.id) || (formScenario === null || formScenario === void 0 ? void 0 : formScenario.id);
    });
};
export var hasCompletedSegment = function (formBuild) {
    return formBuild === null || formBuild === void 0 ? void 0 : formBuild.segmentsList.some(function (segment) {
        var _a = segment || {}, webForm = _a.webForm, formScenario = _a.formScenario;
        return (((webForm === null || webForm === void 0 ? void 0 : webForm.completionStatus) || (formScenario === null || formScenario === void 0 ? void 0 : formScenario.completionStatus)) ===
            (CompletionStatus === null || CompletionStatus === void 0 ? void 0 : CompletionStatus.COMPLETION_STATUS_COMPLETE));
    });
};
