var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import styled from 'styled-components';
import { colors } from '@src/colors';
import ComponentDescription from '@src/components/atoms/ComponentDescription';
var ListContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  min-width: 300px;\n  min-height: 605px;\n  text-align: center;\n  position: relative;\n  box-shadow: 0px 1px 8px rgba(5, 1, 191, 0.16);\n  border-radius: 16px;\n  background-color: #fff;\n  .inline-c-flexbox {\n    display: flex;\n    border-bottom: 1px solid ", ";\n    height: 46px;\n    p {\n      padding: 24px 24px 8px 24px;\n      font-size: 10px;\n      font-style: normal;\n      font-weight: 700;\n      line-height: 15px;\n      letter-spacing: 0.03em;\n      text-align: left;\n      color: ", ";\n    }\n  }\n"], ["\n  min-width: 300px;\n  min-height: 605px;\n  text-align: center;\n  position: relative;\n  box-shadow: 0px 1px 8px rgba(5, 1, 191, 0.16);\n  border-radius: 16px;\n  background-color: #fff;\n  .inline-c-flexbox {\n    display: flex;\n    border-bottom: 1px solid ", ";\n    height: 46px;\n    p {\n      padding: 24px 24px 8px 24px;\n      font-size: 10px;\n      font-style: normal;\n      font-weight: 700;\n      line-height: 15px;\n      letter-spacing: 0.03em;\n      text-align: left;\n      color: ", ";\n    }\n  }\n"])), colors.rpa_settings.border_line, colors.rpa_settings.mid_night);
function SideNavigationBar(_a) {
    var className = _a.className, title = _a.title, description = _a.description, children = _a.children, provided = _a.provided;
    return (React.createElement(ListContainer, __assign({ ref: provided === null || provided === void 0 ? void 0 : provided.innerRef }, provided === null || provided === void 0 ? void 0 : provided.droppableProps, { className: className, "data-testid": "SideNavigationBar" }),
        React.createElement("div", { className: "inline-c-flexbox" },
            React.createElement("p", null, title)),
        children,
        description && React.createElement(ComponentDescription, { description: description })));
}
export default SideNavigationBar;
var templateObject_1;
