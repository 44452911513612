export var sampleUsers = [{
  id: 1,
  auto_reply: true,
  first_name: '太郎',
  last_name: '山田',
  status: 'inactive',
  profile_photo_url: '',
  gender: 'male',
  open_rate: [10, 20, 30, 40, 50, 60, 70],
  matching_rate: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
  tags: [{
    name: '関東在住'
  }, {
    name: 'テクノロジー'
  }]
}, {
  id: 2,
  auto_reply: false,
  first_name: '花子',
  last_name: '山田',
  status: 'active',
  profile_photo_url: '',
  gender: 'female',
  open_rate: [10, 20, 30, 40, 50, 60, 70],
  matching_rate: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
  tags: [{
    name: 'タグ名'
  }, {
    name: 'タグ名'
  }]
}, {
  id: 3,
  auto_reply: true,
  first_name: '太郎',
  last_name: '山田',
  status: 'active',
  profile_photo_url: '',
  gender: 'male',
  open_rate: [10, 20, 30, 40, 50, 60, 70],
  matching_rate: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
  tags: [{
    name: '関東在住'
  }, {
    name: 'テクノロジー'
  }]
}, {
  id: 4,
  auto_reply: false,
  first_name: '花子',
  last_name: '山田',
  status: 'active',
  profile_photo_url: '',
  gender: 'female',
  open_rate: [10, 20, 30, 40, 50, 60, 70],
  matching_rate: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
  tags: [{
    name: 'タグ名'
  }, {
    name: 'タグ名'
  }]
}, {
  id: 5,
  auto_reply: true,
  first_name: '太郎',
  last_name: '山田',
  status: 'inactive',
  profile_photo_url: '',
  gender: 'male',
  open_rate: [10, 20, 30, 40, 50, 60, 70],
  matching_rate: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
  tags: [{
    name: '関東在住'
  }, {
    name: 'テクノロジー'
  }]
}, {
  id: 6,
  auto_reply: false,
  first_name: '花子',
  last_name: '山田',
  status: 'inactive',
  profile_photo_url: '',
  gender: 'female',
  open_rate: [10, 20, 30, 40, 50, 60, 70],
  matching_rate: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
  tags: [{
    name: 'タグ名'
  }, {
    name: 'タグ名'
  }]
}, {
  id: 7,
  auto_reply: true,
  first_name: '太郎',
  last_name: '山田',
  status: 'inactive',
  profile_photo_url: '',
  gender: 'male',
  open_rate: [10, 20, 30, 40, 50, 60, 70],
  matching_rate: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
  tags: [{
    name: '関東在住'
  }, {
    name: 'テクノロジー'
  }]
}, {
  id: 8,
  auto_reply: false,
  first_name: '花子',
  last_name: '山田',
  status: 'inactive',
  profile_photo_url: '',
  gender: 'female',
  open_rate: [10, 20, 30, 40, 50, 60, 70],
  matching_rate: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
  tags: [{
    name: 'タグ名'
  }, {
    name: 'タグ名'
  }]
}, {
  id: 9,
  auto_reply: true,
  first_name: '太郎',
  last_name: '山田',
  status: 'inactive',
  profile_photo_url: '',
  gender: 'male',
  open_rate: [10, 20, 30, 40, 50, 60, 70],
  matching_rate: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
  tags: [{
    name: '関東在住'
  }, {
    name: 'テクノロジー'
  }]
}, {
  id: 10,
  auto_reply: false,
  first_name: '花子',
  last_name: '山田',
  status: 'inactive',
  profile_photo_url: '',
  gender: 'female',
  open_rate: [10, 20, 30, 40, 50, 60, 70],
  matching_rate: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
  tags: [{
    name: 'タグ名'
  }, {
    name: 'タグ名'
  }]
}];