var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import { lineFlexMessageStyles } from '@src/lib/app-constants';
var SeparatorContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  border-bottom: 1px solid ", ";\n  margin-top: ", " !important;\n"], ["\n  border-bottom: 1px solid ", ";\n  margin-top: ",
    " !important;\n"])), function (props) { return props.color || '#D4D6DA'; }, function (props) {
    return lineFlexMessageStyles.margins[props.margin];
});
var SeparatorNode = function (_a) {
    var lineSeparator = _a.lineSeparator;
    return (React.createElement(SeparatorContainer, { color: lineSeparator.color, margin: lineSeparator.margin, "data-testid": "SeparatorNode_SeparatorContainer" }));
};
export default SeparatorNode;
var templateObject_1;
