var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import get from 'lodash/get';
import { colors } from '@src/colors';
import limitations from '@src/lib/platforms/limitations';
var CharacterCountWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  padding-top: ", ";\n  padding-left: 5px;\n  .count-info {\n    margin-left: auto;\n    margin-right: 0;\n    font-size: 10px;\n    color: ", ";\n  }\n"], ["\n  display: flex;\n  padding-top: ", ";\n  padding-left: 5px;\n  .count-info {\n    margin-left: auto;\n    margin-right: 0;\n    font-size: 10px;\n    color: ", ";\n  }\n"])), function (props) { return props.paddingTop || '3px'; }, function (props) { return (props.warning ? 'red' : colors.placeholder); });
var CharacterCount = function (_a) {
    var value = _a.value, platform = _a.platform, path = _a.path, paddingTop = _a.paddingTop, className = _a.className, children = _a.children, fixedMaxLength = _a.fixedMaxLength, dataTestId = _a.dataTestId;
    var platformMaxLength = get(limitations[platform], "MAX_LENGTH_INPUT_FIELD." + path);
    var maxLength = platformMaxLength || fixedMaxLength;
    if (!maxLength)
        return null;
    var length = Array.from(value || '').length;
    var errorClassName = length > maxLength ? ' error' : '';
    return (React.createElement(CharacterCountWrapper, { warning: maxLength < length, className: className || className + " " + errorClassName, paddingTop: paddingTop },
        children,
        React.createElement("div", { className: "count-info", "data-testid": dataTestId }, maxLength - length)));
};
export default CharacterCount;
var templateObject_1;
