// source: entities/conversion.proto

/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

/* eslint-disable */
// @ts-nocheck
var jspb = require('google-protobuf');

var goog = jspb;
var global = Function('return this')();

var entities_entities_pb = require('../entities/entities_pb.js');

goog.object.extend(proto, entities_entities_pb);

var entities_form_automation_pb = require('../entities/form_automation_pb.js');

goog.object.extend(proto, entities_form_automation_pb);

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');

goog.object.extend(proto, google_protobuf_timestamp_pb);
goog.exportSymbol('proto.e.ConversionAttempt', null, global);
goog.exportSymbol('proto.e.ConversionError', null, global);
goog.exportSymbol('proto.e.ConversionErrorType', null, global);
goog.exportSymbol('proto.e.ConversionTask', null, global);
goog.exportSymbol('proto.e.ConversionTaskAnswer', null, global);
goog.exportSymbol('proto.e.ConversionTaskStatus', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */

proto.e.ConversionTask = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.e.ConversionTask.repeatedFields_, null);
};

goog.inherits(proto.e.ConversionTask, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.e.ConversionTask.displayName = 'proto.e.ConversionTask';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.e.ConversionError = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.e.ConversionError, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.e.ConversionError.displayName = 'proto.e.ConversionError';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.e.ConversionAttempt = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.e.ConversionAttempt.repeatedFields_, null);
};

goog.inherits(proto.e.ConversionAttempt, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.e.ConversionAttempt.displayName = 'proto.e.ConversionAttempt';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */


proto.e.ConversionTaskAnswer = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};

goog.inherits(proto.e.ConversionTaskAnswer, jspb.Message);

if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.e.ConversionTaskAnswer.displayName = 'proto.e.ConversionTaskAnswer';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */


proto.e.ConversionTask.repeatedFields_ = [5, 6];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.e.ConversionTask.prototype.toObject = function (opt_includeInstance) {
    return proto.e.ConversionTask.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.e.ConversionTask} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.e.ConversionTask.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      id: jspb.Message.getFieldWithDefault(msg, 1, ""),
      endUserId: jspb.Message.getFieldWithDefault(msg, 2, 0),
      formBuildId: jspb.Message.getFieldWithDefault(msg, 3, ""),
      status: jspb.Message.getFieldWithDefault(msg, 4, 0),
      conversionAttemptsList: jspb.Message.toObjectList(msg.getConversionAttemptsList(), proto.e.ConversionAttempt.toObject, includeInstance),
      answersList: jspb.Message.toObjectList(msg.getAnswersList(), proto.e.ConversionTaskAnswer.toObject, includeInstance),
      record: (f = msg.getRecord()) && entities_entities_pb.Record.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.e.ConversionTask}
 */


proto.e.ConversionTask.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.e.ConversionTask();
  return proto.e.ConversionTask.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.e.ConversionTask} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.e.ConversionTask}
 */


proto.e.ConversionTask.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setId(value);
        break;

      case 2:
        var value =
        /** @type {number} */
        reader.readInt64();
        msg.setEndUserId(value);
        break;

      case 3:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setFormBuildId(value);
        break;

      case 4:
        var value =
        /** @type {!proto.e.ConversionTaskStatus} */
        reader.readEnum();
        msg.setStatus(value);
        break;

      case 5:
        var value = new proto.e.ConversionAttempt();
        reader.readMessage(value, proto.e.ConversionAttempt.deserializeBinaryFromReader);
        msg.addConversionAttempts(value);
        break;

      case 6:
        var value = new proto.e.ConversionTaskAnswer();
        reader.readMessage(value, proto.e.ConversionTaskAnswer.deserializeBinaryFromReader);
        msg.addAnswers(value);
        break;

      case 15:
        var value = new entities_entities_pb.Record();
        reader.readMessage(value, entities_entities_pb.Record.deserializeBinaryFromReader);
        msg.setRecord(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.e.ConversionTask.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.e.ConversionTask.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.e.ConversionTask} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.e.ConversionTask.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getId();

  if (f.length > 0) {
    writer.writeString(1, f);
  }

  f = message.getEndUserId();

  if (f !== 0) {
    writer.writeInt64(2, f);
  }

  f = message.getFormBuildId();

  if (f.length > 0) {
    writer.writeString(3, f);
  }

  f = message.getStatus();

  if (f !== 0.0) {
    writer.writeEnum(4, f);
  }

  f = message.getConversionAttemptsList();

  if (f.length > 0) {
    writer.writeRepeatedMessage(5, f, proto.e.ConversionAttempt.serializeBinaryToWriter);
  }

  f = message.getAnswersList();

  if (f.length > 0) {
    writer.writeRepeatedMessage(6, f, proto.e.ConversionTaskAnswer.serializeBinaryToWriter);
  }

  f = message.getRecord();

  if (f != null) {
    writer.writeMessage(15, f, entities_entities_pb.Record.serializeBinaryToWriter);
  }
};
/**
 * optional string id = 1;
 * @return {string}
 */


proto.e.ConversionTask.prototype.getId = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 1, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.e.ConversionTask} returns this
 */


proto.e.ConversionTask.prototype.setId = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional int64 end_user_id = 2;
 * @return {number}
 */


proto.e.ConversionTask.prototype.getEndUserId = function () {
  return (
    /** @type {number} */
    jspb.Message.getFieldWithDefault(this, 2, 0)
  );
};
/**
 * @param {number} value
 * @return {!proto.e.ConversionTask} returns this
 */


proto.e.ConversionTask.prototype.setEndUserId = function (value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};
/**
 * optional string form_build_id = 3;
 * @return {string}
 */


proto.e.ConversionTask.prototype.getFormBuildId = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 3, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.e.ConversionTask} returns this
 */


proto.e.ConversionTask.prototype.setFormBuildId = function (value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};
/**
 * optional ConversionTaskStatus status = 4;
 * @return {!proto.e.ConversionTaskStatus}
 */


proto.e.ConversionTask.prototype.getStatus = function () {
  return (
    /** @type {!proto.e.ConversionTaskStatus} */
    jspb.Message.getFieldWithDefault(this, 4, 0)
  );
};
/**
 * @param {!proto.e.ConversionTaskStatus} value
 * @return {!proto.e.ConversionTask} returns this
 */


proto.e.ConversionTask.prototype.setStatus = function (value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};
/**
 * repeated ConversionAttempt conversion_attempts = 5;
 * @return {!Array<!proto.e.ConversionAttempt>}
 */


proto.e.ConversionTask.prototype.getConversionAttemptsList = function () {
  return (
    /** @type{!Array<!proto.e.ConversionAttempt>} */
    jspb.Message.getRepeatedWrapperField(this, proto.e.ConversionAttempt, 5)
  );
};
/**
 * @param {!Array<!proto.e.ConversionAttempt>} value
 * @return {!proto.e.ConversionTask} returns this
*/


proto.e.ConversionTask.prototype.setConversionAttemptsList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};
/**
 * @param {!proto.e.ConversionAttempt=} opt_value
 * @param {number=} opt_index
 * @return {!proto.e.ConversionAttempt}
 */


proto.e.ConversionTask.prototype.addConversionAttempts = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.e.ConversionAttempt, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.e.ConversionTask} returns this
 */


proto.e.ConversionTask.prototype.clearConversionAttemptsList = function () {
  return this.setConversionAttemptsList([]);
};
/**
 * repeated ConversionTaskAnswer answers = 6;
 * @return {!Array<!proto.e.ConversionTaskAnswer>}
 */


proto.e.ConversionTask.prototype.getAnswersList = function () {
  return (
    /** @type{!Array<!proto.e.ConversionTaskAnswer>} */
    jspb.Message.getRepeatedWrapperField(this, proto.e.ConversionTaskAnswer, 6)
  );
};
/**
 * @param {!Array<!proto.e.ConversionTaskAnswer>} value
 * @return {!proto.e.ConversionTask} returns this
*/


proto.e.ConversionTask.prototype.setAnswersList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};
/**
 * @param {!proto.e.ConversionTaskAnswer=} opt_value
 * @param {number=} opt_index
 * @return {!proto.e.ConversionTaskAnswer}
 */


proto.e.ConversionTask.prototype.addAnswers = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.e.ConversionTaskAnswer, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.e.ConversionTask} returns this
 */


proto.e.ConversionTask.prototype.clearAnswersList = function () {
  return this.setAnswersList([]);
};
/**
 * optional Record record = 15;
 * @return {?proto.e.Record}
 */


proto.e.ConversionTask.prototype.getRecord = function () {
  return (
    /** @type{?proto.e.Record} */
    jspb.Message.getWrapperField(this, entities_entities_pb.Record, 15)
  );
};
/**
 * @param {?proto.e.Record|undefined} value
 * @return {!proto.e.ConversionTask} returns this
*/


proto.e.ConversionTask.prototype.setRecord = function (value) {
  return jspb.Message.setWrapperField(this, 15, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.e.ConversionTask} returns this
 */


proto.e.ConversionTask.prototype.clearRecord = function () {
  return this.setRecord(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.e.ConversionTask.prototype.hasRecord = function () {
  return jspb.Message.getField(this, 15) != null;
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.e.ConversionError.prototype.toObject = function (opt_includeInstance) {
    return proto.e.ConversionError.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.e.ConversionError} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.e.ConversionError.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      conversionErrorType: jspb.Message.getFieldWithDefault(msg, 1, 0),
      stepType: jspb.Message.getFieldWithDefault(msg, 2, 0),
      cssSelector: jspb.Message.getFieldWithDefault(msg, 3, ""),
      conversionErrorDetail: jspb.Message.getFieldWithDefault(msg, 4, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.e.ConversionError}
 */


proto.e.ConversionError.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.e.ConversionError();
  return proto.e.ConversionError.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.e.ConversionError} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.e.ConversionError}
 */


proto.e.ConversionError.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value =
        /** @type {!proto.e.ConversionErrorType} */
        reader.readEnum();
        msg.setConversionErrorType(value);
        break;

      case 2:
        var value =
        /** @type {!proto.e.FormAutomationStepAction} */
        reader.readEnum();
        msg.setStepType(value);
        break;

      case 3:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setCssSelector(value);
        break;

      case 4:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setConversionErrorDetail(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.e.ConversionError.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.e.ConversionError.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.e.ConversionError} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.e.ConversionError.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getConversionErrorType();

  if (f !== 0.0) {
    writer.writeEnum(1, f);
  }

  f = message.getStepType();

  if (f !== 0.0) {
    writer.writeEnum(2, f);
  }

  f = message.getCssSelector();

  if (f.length > 0) {
    writer.writeString(3, f);
  }

  f = message.getConversionErrorDetail();

  if (f.length > 0) {
    writer.writeString(4, f);
  }
};
/**
 * optional ConversionErrorType conversion_error_type = 1;
 * @return {!proto.e.ConversionErrorType}
 */


proto.e.ConversionError.prototype.getConversionErrorType = function () {
  return (
    /** @type {!proto.e.ConversionErrorType} */
    jspb.Message.getFieldWithDefault(this, 1, 0)
  );
};
/**
 * @param {!proto.e.ConversionErrorType} value
 * @return {!proto.e.ConversionError} returns this
 */


proto.e.ConversionError.prototype.setConversionErrorType = function (value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};
/**
 * optional FormAutomationStepAction step_type = 2;
 * @return {!proto.e.FormAutomationStepAction}
 */


proto.e.ConversionError.prototype.getStepType = function () {
  return (
    /** @type {!proto.e.FormAutomationStepAction} */
    jspb.Message.getFieldWithDefault(this, 2, 0)
  );
};
/**
 * @param {!proto.e.FormAutomationStepAction} value
 * @return {!proto.e.ConversionError} returns this
 */


proto.e.ConversionError.prototype.setStepType = function (value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};
/**
 * optional string css_selector = 3;
 * @return {string}
 */


proto.e.ConversionError.prototype.getCssSelector = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 3, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.e.ConversionError} returns this
 */


proto.e.ConversionError.prototype.setCssSelector = function (value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};
/**
 * optional string conversion_error_detail = 4;
 * @return {string}
 */


proto.e.ConversionError.prototype.getConversionErrorDetail = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 4, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.e.ConversionError} returns this
 */


proto.e.ConversionError.prototype.setConversionErrorDetail = function (value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */


proto.e.ConversionAttempt.repeatedFields_ = [3];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.e.ConversionAttempt.prototype.toObject = function (opt_includeInstance) {
    return proto.e.ConversionAttempt.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.e.ConversionAttempt} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.e.ConversionAttempt.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
      conversionError: (f = msg.getConversionError()) && proto.e.ConversionError.toObject(includeInstance, f),
      errorScreenshotUrlsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
      completedTime: (f = msg.getCompletedTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.e.ConversionAttempt}
 */


proto.e.ConversionAttempt.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.e.ConversionAttempt();
  return proto.e.ConversionAttempt.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.e.ConversionAttempt} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.e.ConversionAttempt}
 */


proto.e.ConversionAttempt.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value =
        /** @type {boolean} */
        reader.readBool();
        msg.setSuccess(value);
        break;

      case 2:
        var value = new proto.e.ConversionError();
        reader.readMessage(value, proto.e.ConversionError.deserializeBinaryFromReader);
        msg.setConversionError(value);
        break;

      case 3:
        var value =
        /** @type {string} */
        reader.readString();
        msg.addErrorScreenshotUrls(value);
        break;

      case 4:
        var value = new google_protobuf_timestamp_pb.Timestamp();
        reader.readMessage(value, google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
        msg.setCompletedTime(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.e.ConversionAttempt.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.e.ConversionAttempt.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.e.ConversionAttempt} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.e.ConversionAttempt.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getSuccess();

  if (f) {
    writer.writeBool(1, f);
  }

  f = message.getConversionError();

  if (f != null) {
    writer.writeMessage(2, f, proto.e.ConversionError.serializeBinaryToWriter);
  }

  f = message.getErrorScreenshotUrlsList();

  if (f.length > 0) {
    writer.writeRepeatedString(3, f);
  }

  f = message.getCompletedTime();

  if (f != null) {
    writer.writeMessage(4, f, google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter);
  }
};
/**
 * optional bool success = 1;
 * @return {boolean}
 */


proto.e.ConversionAttempt.prototype.getSuccess = function () {
  return (
    /** @type {boolean} */
    jspb.Message.getBooleanFieldWithDefault(this, 1, false)
  );
};
/**
 * @param {boolean} value
 * @return {!proto.e.ConversionAttempt} returns this
 */


proto.e.ConversionAttempt.prototype.setSuccess = function (value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};
/**
 * optional ConversionError conversion_error = 2;
 * @return {?proto.e.ConversionError}
 */


proto.e.ConversionAttempt.prototype.getConversionError = function () {
  return (
    /** @type{?proto.e.ConversionError} */
    jspb.Message.getWrapperField(this, proto.e.ConversionError, 2)
  );
};
/**
 * @param {?proto.e.ConversionError|undefined} value
 * @return {!proto.e.ConversionAttempt} returns this
*/


proto.e.ConversionAttempt.prototype.setConversionError = function (value) {
  return jspb.Message.setWrapperField(this, 2, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.e.ConversionAttempt} returns this
 */


proto.e.ConversionAttempt.prototype.clearConversionError = function () {
  return this.setConversionError(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.e.ConversionAttempt.prototype.hasConversionError = function () {
  return jspb.Message.getField(this, 2) != null;
};
/**
 * repeated string error_screenshot_urls = 3;
 * @return {!Array<string>}
 */


proto.e.ConversionAttempt.prototype.getErrorScreenshotUrlsList = function () {
  return (
    /** @type {!Array<string>} */
    jspb.Message.getRepeatedField(this, 3)
  );
};
/**
 * @param {!Array<string>} value
 * @return {!proto.e.ConversionAttempt} returns this
 */


proto.e.ConversionAttempt.prototype.setErrorScreenshotUrlsList = function (value) {
  return jspb.Message.setField(this, 3, value || []);
};
/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.e.ConversionAttempt} returns this
 */


proto.e.ConversionAttempt.prototype.addErrorScreenshotUrls = function (value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};
/**
 * Clears the list making it empty but non-null.
 * @return {!proto.e.ConversionAttempt} returns this
 */


proto.e.ConversionAttempt.prototype.clearErrorScreenshotUrlsList = function () {
  return this.setErrorScreenshotUrlsList([]);
};
/**
 * optional google.protobuf.Timestamp completed_time = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */


proto.e.ConversionAttempt.prototype.getCompletedTime = function () {
  return (
    /** @type{?proto.google.protobuf.Timestamp} */
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4)
  );
};
/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.e.ConversionAttempt} returns this
*/


proto.e.ConversionAttempt.prototype.setCompletedTime = function (value) {
  return jspb.Message.setWrapperField(this, 4, value);
};
/**
 * Clears the message field making it undefined.
 * @return {!proto.e.ConversionAttempt} returns this
 */


proto.e.ConversionAttempt.prototype.clearCompletedTime = function () {
  return this.setCompletedTime(undefined);
};
/**
 * Returns whether this field is set.
 * @return {boolean}
 */


proto.e.ConversionAttempt.prototype.hasCompletedTime = function () {
  return jspb.Message.getField(this, 4) != null;
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.e.ConversionTaskAnswer.prototype.toObject = function (opt_includeInstance) {
    return proto.e.ConversionTaskAnswer.toObject(opt_includeInstance, this);
  };
  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.e.ConversionTaskAnswer} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */


  proto.e.ConversionTaskAnswer.toObject = function (includeInstance, msg) {
    var f,
        obj = {
      question: jspb.Message.getFieldWithDefault(msg, 1, ""),
      answer: jspb.Message.getFieldWithDefault(msg, 2, "")
    };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }

    return obj;
  };
}
/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.e.ConversionTaskAnswer}
 */


proto.e.ConversionTaskAnswer.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.e.ConversionTaskAnswer();
  return proto.e.ConversionTaskAnswer.deserializeBinaryFromReader(msg, reader);
};
/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.e.ConversionTaskAnswer} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.e.ConversionTaskAnswer}
 */


proto.e.ConversionTaskAnswer.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }

    var field = reader.getFieldNumber();

    switch (field) {
      case 1:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setQuestion(value);
        break;

      case 2:
        var value =
        /** @type {string} */
        reader.readString();
        msg.setAnswer(value);
        break;

      default:
        reader.skipField();
        break;
    }
  }

  return msg;
};
/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */


proto.e.ConversionTaskAnswer.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.e.ConversionTaskAnswer.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};
/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.e.ConversionTaskAnswer} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */


proto.e.ConversionTaskAnswer.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getQuestion();

  if (f.length > 0) {
    writer.writeString(1, f);
  }

  f = message.getAnswer();

  if (f.length > 0) {
    writer.writeString(2, f);
  }
};
/**
 * optional string question = 1;
 * @return {string}
 */


proto.e.ConversionTaskAnswer.prototype.getQuestion = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 1, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.e.ConversionTaskAnswer} returns this
 */


proto.e.ConversionTaskAnswer.prototype.setQuestion = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};
/**
 * optional string answer = 2;
 * @return {string}
 */


proto.e.ConversionTaskAnswer.prototype.getAnswer = function () {
  return (
    /** @type {string} */
    jspb.Message.getFieldWithDefault(this, 2, "")
  );
};
/**
 * @param {string} value
 * @return {!proto.e.ConversionTaskAnswer} returns this
 */


proto.e.ConversionTaskAnswer.prototype.setAnswer = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};
/**
 * @enum {number}
 */


proto.e.ConversionTaskStatus = {
  CONVERSION_TASK_STATUS_UNSPECIFIED: 0,
  CONVERSION_TASK_STATUS_PREPARING: 1,
  CONVERSION_TASK_STATUS_IN_PROGRESS: 2,
  CONVERSION_TASK_STATUS_SUCCESS: 3,
  CONVERSION_TASK_STATUS_ERROR: 4
};
/**
 * @enum {number}
 */

proto.e.ConversionErrorType = {
  CONVERSION_ERROR_TYPE_UNSPECIFIED: 0,
  CONVERSION_ERROR_TYPE_SUBMISSION: 1,
  CONVERSION_ERROR_TYPE_AUTOMATION_STEP: 2
};
goog.object.extend(exports, proto.e);