var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import RichMenuAreaModal from '@src/components/molecules/RichMenuAreaModal';
import { TAB_0_COLOR } from '@src/lib/app-constants';
var RichMenuArea = /** @class */ (function (_super) {
    __extends(RichMenuArea, _super);
    function RichMenuArea() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    RichMenuArea.prototype.render = function () {
        var _a = this.props, isPreview = _a.isPreview, index = _a.index, richMenuArea = _a.richMenuArea, hoverAreaId = _a.hoverAreaId, editModalOpened = _a.editModalOpened, setHoverAreaId = _a.setHoverAreaId, setEditModalOpened = _a.setEditModalOpened, default_message_id = _a.default_message_id, intl = _a.intl, _b = _a.area, width = _b.width, height = _b.height, color = _b.color, sort = _b.sort;
        var titleText = color <= TAB_0_COLOR
            ? intl.formatMessage({ id: "8o8Qs2", defaultMessage: "Area" }) + " " + (sort + 1)
            : "\u30BF\u30D6" + (color - TAB_0_COLOR);
        var hoverAreaClassName = !isPreview && hoverAreaId === richMenuArea.id ? 'hover-area' : '';
        var onMouseOver = function () {
            return !isPreview && setHoverAreaId && setHoverAreaId(richMenuArea.id);
        };
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { onClick: function () {
                    return !richMenuArea.isTabArea &&
                        setEditModalOpened &&
                        setEditModalOpened(true);
                }, onMouseOver: onMouseOver, onFocus: function () { return undefined; }, className: "area-" + index + " " + hoverAreaClassName, role: "button", tabIndex: 0 },
                React.createElement("div", { className: "area-info" }, isPreview
                    ? width + "x" + height
                    : titleText + " (" + width + "x" + height + ")")),
            editModalOpened && !isPreview && hoverAreaId === richMenuArea.id && (React.createElement(RichMenuAreaModal, __assign({ defaultMessageId: default_message_id, onCancel: function () { return setEditModalOpened(false); } }, this.props)))));
    };
    return RichMenuArea;
}(Component));
export default injectIntl(RichMenuArea);
