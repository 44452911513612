import "core-js/modules/es.array.concat";
var zealsAdmin = {
  collection: function collection() {
    return "/zeals_admin/api/validations";
  },
  member: function member(validationId) {
    return "".concat(zealsAdmin.collection(), "/").concat(validationId);
  },
  createValidationAssociation: function createValidationAssociation() {
    return "".concat(zealsAdmin.collection(), "/create_validation_association");
  },
  deleteValidationAssociation: function deleteValidationAssociation() {
    return "".concat(zealsAdmin.collection(), "/delete_validation_association");
  },
  updateValidationArgument: function updateValidationArgument() {
    return "".concat(zealsAdmin.collection(), "/update_validation_argument");
  }
};
export default {
  zealsAdmin: zealsAdmin
};