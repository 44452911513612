import "core-js/modules/es.array.concat";

var collection = function collection(_ref) {
  var chatbotId = _ref.chatbotId,
      adScenarioId = _ref.adScenarioId;
  return "/chatbots/".concat(chatbotId, "/ad_scenarios/").concat(adScenarioId, "/ad_campaign_contents");
};

var member = function member(_ref2) {
  var chatbotId = _ref2.chatbotId,
      adScenarioId = _ref2.adScenarioId,
      campaignContentsId = _ref2.campaignContentsId;
  return "".concat(collection({
    chatbotId: chatbotId,
    adScenarioId: adScenarioId
  }), "/").concat(campaignContentsId);
};

var updateSetting = function updateSetting(chatbotId, adScenarioId) {
  return "".concat(collection({
    chatbotId: chatbotId,
    adScenarioId: adScenarioId
  }), "/update_setting");
};

export default {
  collection: collection,
  member: member,
  updateSetting: updateSetting
};