var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import styled from 'styled-components';
import { renderInputImage } from '@src/lib/adHelpers';
import radioboxEmpty from '@src/assets/images/icon_radiobox_empty.png';
import radioboxDisabled from '@src/assets/images/icon_radiobox_disabled.png';
import radioboxChecked from '@src/assets/images/icon_radiobox_checked.png';
var Label = styled.label(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  font-size: ", ";\n  margin: ", ";\n  input {\n    opacity: 0;\n  }\n  img {\n    width: 24px;\n  }\n"], ["\n  font-size: ", ";\n  margin: ", ";\n  input {\n    opacity: 0;\n  }\n  img {\n    width: 24px;\n  }\n"])), function (props) { return props.fontSize || '14px'; }, function (props) { return props.margin || '0 16px 0 0'; });
var defaultProps = {
    text: 'ラベル',
    checked: false,
};
var InputRadioBox = function (_a) {
    var text = _a.text, value = _a.value, onChange = _a.onChange, checked = _a.checked, disabled = _a.disabled, rest = __rest(_a, ["text", "value", "onChange", "checked", "disabled"]);
    return (React.createElement(Label, { fontSize: rest === null || rest === void 0 ? void 0 : rest.fontSize, margin: rest === null || rest === void 0 ? void 0 : rest.margin, className: rest === null || rest === void 0 ? void 0 : rest.className },
        React.createElement("img", { src: renderInputImage({
                checked: checked,
                disabled: disabled,
                checkedImg: radioboxChecked,
                emptyImg: radioboxEmpty,
                disabledImg: radioboxDisabled,
            }) }),
        text,
        React.createElement("input", __assign({ type: "radio", value: value, checked: checked, onChange: onChange }, rest))));
};
InputRadioBox.defaultProps = defaultProps;
export default InputRadioBox;
var templateObject_1;
